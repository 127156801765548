import { axiosSweepInstance as axiosInstance } from "../configs";

class OkraWebhook{

    testWebhook(){
        return axiosInstance.get(`/OkraWebhook/TestWebhook`);
    }
    retrieveAll(){
        return axiosInstance.get(`/OkraWebhook/RetrieveAll`);
    }
    retrieveById(id:string){
        return axiosInstance.get(`/OkraWebhook/RetrieveById/${id}`);
    }
    retrieveByInstitutionCode(code:string){
        return axiosInstance.get(`/OkraWebhook/RetrieveByInstitutionCode/${code}`);
    }
}

const okraWebhookService = new OkraWebhook();
export default okraWebhookService;