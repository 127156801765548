import React, { Fragment, Component } from "react";
import { toast } from "react-toastify";
import BaseFormComponent, { IBaseFormState } from "../../../../../../components/BaseFormComponent";
import FormInputComponent from "../../../../../../components/formInputComponent";
import SelectInputComponent, { ISelectItems } from "../../../../../../components/formSelectComponent";
import { ActionTypes } from "../../../../../../enums/actionTypes";
import loanRequestService from "../../../../../../services/hybridAppraisal/loanRequestService";
import { countries } from "./../../../../../../services/utility/countries";
import _ from "lodash";
import hybridAppraisalValidationService from "../../../../../../services/hybridAppraisal/hybridAppraisalValidationService";
import { getCurrentUsersRoles } from "../../../../../../services/auth/authService";

interface ContactDetailsFields {
  addressType: string;
  propertyStatus: string;
  address: string;
  city: string;
  state: string;
  country: string;
  phonenumber: string;
  phonenumber2: string;
  customerEmail: string;
  yearsOfStayAtCurrentAddress: string;
  monthsOfStayAtCurrentAddress: string;
  yearsOfStayAtCurrentCity: string;
  monthsOfStayAtCurrentCity: string;
  twitterHandle: string;
  instagramID: string;
  facebookID: string;
  location: string;
}

class ContactDetailsForm extends BaseFormComponent<ContactDetailsFields> { }
interface ContactDetailsProps {
  values: any;
  reloadItem: any;
  mandatoryFields: any;
}

interface ContactDetailsState {
  country: string;
  ownUpdate: boolean;
  item: any;
}

class ContactDetails extends Component<ContactDetailsProps, IBaseFormState & ContactDetailsState> {
  constructor(props: ContactDetailsProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      country: this.props.values.country,
      item: {},
      ownUpdate: false
    };
  }

  static getDerivedStateFromProps(
    nextProps: ContactDetailsProps,
    prevState: ContactDetailsState
  ) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isNull(nextProps.values)) {
      return null;
    }
    return {
      item: nextProps.values
    };
  }

  onFormSubmit(fields: ContactDetailsFields, onReloadFieldsCB: any): boolean {
    const { mandatoryFields } = this.props;
    try {
      this.setState(
        { errors: hybridAppraisalValidationService.validateContactDetailsForm(fields, mandatoryFields), ownUpdate: true },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: ContactDetailsFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const loanRequest = await loanRequestService.retrieveById(this.props.values.id);
      const loanRequestDetail = loanRequest.data;
      const payload: any = { ...loanRequestDetail };
      payload.addressType = fields.addressType;
      payload.propertyStatus = fields.propertyStatus;
      payload.address = fields.address;
      payload.city = fields.city;
      payload.state = fields.state;
      payload.country = this.state.country;
      payload.phonenumber = fields.phonenumber;
      payload.phonenumber2 = fields.phonenumber2;
      payload.customerEmail = fields.customerEmail;
      payload.yearsOfStayAtCurrentAddress = Number(fields.yearsOfStayAtCurrentAddress);
      payload.monthsOfStayAtCurrentAddress = Number(fields.monthsOfStayAtCurrentAddress);
      payload.yearsOfStayAtCurrentCity = Number(fields.yearsOfStayAtCurrentCity);
      payload.monthsOfStayAtCurrentCity = Number(fields.monthsOfStayAtCurrentCity);
      payload.twitterHandle = fields.twitterHandle;
      payload.instagramID = fields.instagramID;
      payload.facebookID = fields.facebookID;
      payload.locationCoordinates = fields.location;

      const response = await loanRequestService.updateLoanRequest(payload);
      if (response.status >= 200 && response.status <= 300) {
        toast.info(`Contact Details saved successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false, ownUpdate: true });
      this.resetFields();
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false, ownUpdate: true });
      return false;
    } finally {
      return true;
    }
  }

  getRequiredFields(title): boolean {
    const { mandatoryFields } = this.props;
    var required = mandatoryFields?.inputFields?.filter(x => x.fieldName === title)[0]?.mandatory;
    if (required === undefined) {
      return false;
    }
    return required;
  }


  resetFields() {
    this.setState({ validated: false, submitting: false, errors: [], ownUpdate: true });
  }

  render() {
    const { values } = this.props;
    const { errors, validated } = this.state;

    let statesInputData: ISelectItems[] = [];
    let countriesInputData: ISelectItems[] = [];
    countries.forEach((country) => {
      countriesInputData.push({ name: country.name, value: country.name });
    });
    countries
      .filter((c) => c.name === String(this.state.country))[0]
      ?.states.forEach((state) => {
        statesInputData.push({ name: state.name, value: state.name });
      });

    let addressTypeInputData: ISelectItems[] = [
      { name: "Current Address", value: "Current Address" },
      { name: "Previous Address", value: "Previous Address" },
    ];

    let propertyStatusInputData: ISelectItems[] = [
      { name: "Owned", value: "Owned" },
      { name: "Rented", value: "Rented" },
      { name: "Others", value: "Others" },
    ];

    return (
      <ContactDetailsForm
        initialValues={{
          addressType: values.addressType,
          propertyStatus: values.propertyStatus,
          address: values.address,
          city: values.city,
          state: values.state,
          country: values.country,
          phonenumber: values.phonenumber,
          phonenumber2: values.phonenumber2,
          customerEmail: values.customerEmail,
          yearsOfStayAtCurrentAddress: values.yearsOfStayAtCurrentAddress.toString(),
          monthsOfStayAtCurrentAddress: values.monthsOfStayAtCurrentAddress.toString(),
          yearsOfStayAtCurrentCity: values.yearsOfStayAtCurrentCity.toString(),
          monthsOfStayAtCurrentCity: values.monthsOfStayAtCurrentCity.toString(),
          twitterHandle: values.twitterHandle,
          instagramID: values.instagramID,
          facebookID: values.facebookID,
          location: values.locationCoordinates
        }}
        FormComponent={({
          fields: {
            addressType,
            propertyStatus,
            address,
            city,
            state,
            country,
            phonenumber,
            phonenumber2,
            customerEmail,
            yearsOfStayAtCurrentAddress,
            monthsOfStayAtCurrentAddress,
            yearsOfStayAtCurrentCity,
            monthsOfStayAtCurrentCity,
            twitterHandle,
            instagramID,
            facebookID,
            location
          },
          onChange,
          onReloadFields,
          onHandleSubmit,
        }) => (
          <Fragment>
            <div className="form-row">
              <div className="col-md-12">
                <div className="form-row">
                  <SelectInputComponent
                    id="addressType"
                    name="addressType"
                    divClass={6}
                    value={addressType}
                    items={addressTypeInputData}
                    required={this.getRequiredFields("addressType")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <SelectInputComponent
                    id="propertyStatus"
                    name="propertyStatus"
                    divClass={6}
                    value={propertyStatus}
                    items={propertyStatusInputData}
                    required={this.getRequiredFields("propertyStatus")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    id="address"
                    name="address"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={address}
                    required={this.getRequiredFields("address")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    label="City/Town"
                    id="city"
                    name="city"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={city}
                    required={this.getRequiredFields("city")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <SelectInputComponent
                    id="country"
                    name="country"
                    divClass={6}
                    value={this.state.country}
                    items={countriesInputData}
                    required={this.getRequiredFields("country")}
                    validated={validated}
                    errors={errors}
                    onChange={async (id, value) => {
                      await onChange(id, value);
                      this.setState({ country: value, ownUpdate: true });
                    }}
                  />
                  <SelectInputComponent
                    label="State/Region"
                    id="state"
                    name="state"
                    divClass={6}
                    value={state}
                    items={statesInputData}
                    required={this.getRequiredFields("state")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <div className="col-md-6">
                    <div className="form-row">
                      <FormInputComponent
                        label="Length of Year(s) at Current Address"
                        id="yearsOfStayAtCurrentAddress"
                        name="yearsOfStayAtCurrentAddress"
                        type="number"
                        placeholder=""
                        divClass={6}
                        value={yearsOfStayAtCurrentAddress}
                        required={this.getRequiredFields("lengthOfYearAtCurrentAddress")}
                        validated={validated}
                        errors={errors}
                        onChange={onChange}
                      />
                      <FormInputComponent
                        label="Length of Month(s) at Current Address"
                        id="monthsOfStayAtCurrentAddress"
                        name="monthsOfStayAtCurrentAddress"
                        type="number"
                        placeholder=""
                        divClass={6}
                        value={monthsOfStayAtCurrentAddress}
                        required={this.getRequiredFields("lengthOfMonthAtCurrentAddress")}
                        validated={validated}
                        errors={errors}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-row">
                      <FormInputComponent
                        label="Length of Year(s) at Current City"
                        id="yearsOfStayAtCurrentCity"
                        name="yearsOfStayAtCurrentCity"
                        type="number"
                        placeholder=""
                        divClass={6}
                        value={yearsOfStayAtCurrentCity}
                        required={this.getRequiredFields("lengthOfYearAtCurrentCity")}
                        validated={validated}
                        errors={errors}
                        onChange={onChange}
                      />
                      <FormInputComponent
                        label="Length of Month(s) at Current City"
                        id="monthsOfStayAtCurrentCity"
                        name="monthsOfStayAtCurrentCity"
                        type="number"
                        placeholder=""
                        divClass={6}
                        value={monthsOfStayAtCurrentCity}
                        required={this.getRequiredFields("lengthOfMonthAtCurrentCity")}
                        validated={validated}
                        errors={errors}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                  <FormInputComponent
                    label="Phone Number 1"
                    id="phonenumber"
                    name="phonenumber"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={phonenumber}
                    required={this.getRequiredFields("phoneNumber1")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    label="Phone Number 2"
                    id="phonenumber2"
                    name="phonenumber2"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={phonenumber2}
                    required={this.getRequiredFields("phoneNumber2")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    label="Email Address"
                    id="customerEmail"
                    name="customerEmail"
                    type="email"
                    placeholder=""
                    divClass={6}
                    value={customerEmail}
                    required={this.getRequiredFields("emailAddress")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    label="Facebook ID"
                    id="facebookID"
                    name="facebookID"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={facebookID}
                    required={this.getRequiredFields("facebookId")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    id="twitterHandle"
                    name="twitterHandle"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={twitterHandle}
                    required={this.getRequiredFields("twitterHandle")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    label="Instagram ID"
                    id="instagramID"
                    name="instagramID"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={instagramID}
                    required={this.getRequiredFields("instagramId")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    label="Location Co-ordinates"
                    id="location"
                    name="location"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={location}
                    required={this.getRequiredFields("locationCoordinates")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                </div>
              </div>
            </ div>
            <hr />
            <div className="float-right">
              {(getCurrentUsersRoles().includes("updatedataentry") && (
                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  disabled={this.state.submitting}
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.SAVE,
                      "Contact Details",
                      () => {
                        this.onFormSubmit(
                          {
                            addressType,
                            propertyStatus,
                            address,
                            city,
                            state,
                            country,
                            phonenumber,
                            phonenumber2,
                            customerEmail,
                            yearsOfStayAtCurrentAddress,
                            monthsOfStayAtCurrentAddress,
                            yearsOfStayAtCurrentCity,
                            monthsOfStayAtCurrentCity,
                            twitterHandle,
                            instagramID,
                            facebookID,
                            location
                          },
                          onReloadFields
                        );
                      }
                    );
                  }}
                >
                  {this.state.submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                      Please wait...
                    </React.Fragment>
                  )}
                </button>
              ))}
            </div>
          </Fragment>
        )}
      ></ContactDetailsForm >
    );
  }
}

export default ContactDetails;