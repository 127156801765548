import React, { ComponentClass } from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import { getCurrentUserSession } from "../services/auth/authService";
// import { getCurrentUserSession } from "../../services/recovaAuth/authService";
// interface TestProps {
//   path: string;
//   component: ComponentClass;
// }

// const ProtectedRoute: React.SFC<TestProps> = (props) => {
//   return <Route path={props.path} component={props.component} />;
// };

export interface ProtectedRouteProps {
  path: string;
  component: any;
  currentUserSession?: any;
  currentUserRoles?: any;
  exact?: boolean;
}

class ProtectedRoute extends React.Component<ProtectedRouteProps> {
  static defaultProps = {
    exact: false,
  };
  render() {
    if (getCurrentUserSession().sessionId === undefined)
      return <Redirect to="/login" />;
    return (
      <Route
        path={this.props.path}
        exact={this.props.exact}
        component={this.props.component}
        currentUserSession={this.props.currentUserSession}
        currentUserRoles={this.props.currentUserRoles}
      />
    );
  }
}

export default ProtectedRoute;
