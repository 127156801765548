import React, { Component } from "react";
import resume from "../../images/icons/icons8-new-resume-template-48.png";
import cash from "../../images/icons/icons8-cash-48.png";
import stack_of_money from "../../images/icons/icons8-stack-of-money-48.png";
import { Line, Bar, Pie } from "react-chartjs-2";
import { generateRandomRGBA } from "../../services/utility/textFormatService";
// import CountUp from "react-countup";
import mandateAgentService from "../../services/influxagent/mandateagentService";
import textFormatService from "../../services/utility/textFormatService";
import bvnAgentService from "../../services/influxagent/bvnAgentService";
import mandateStatsService from "../../services/influxagent/mandateStatsService";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../services/auth/authService";
import DetailItemSelectComponent from "../detailItemSelectBox";
import sweepAgentService from "../../services/influxagent/sweepAgentService";
import creditAssessmentService from "../../services/influxagent/creditAssessmentService";
import ShareModal from "./shareModal";
import * as htmlToImage from "html-to-image";
import loanRequestService from "../../services/creditAssessment/loanRequestService";
import collectionService from "../../services/influxagent/collectionService";

export interface IOtherInstitutionDashboardProps { }

export interface IOtherInstitutionDashboardState {
  activeMandatesCount: number;
  activeMandatesValue: number;
  cancelledMandatesCount: number;
  cancelledMandatesValue: number;
  linkedBVNCount: number;
  recoveryTransactionCount: number;
  amountAttemptedValue: number;
  amountRecoveredValue: number;
  recoveryEfficiency: number;
  pendingSettlementValue: number;
  successfulSettlementValue: number;
  linkedBVNAccountCount: number;
  linkedAccountCount: number;
  blackboxLoanRequestCount: number;
  blackboxLoanRequestAmount: number;
  // barChartColor: any;
  fetchingData: boolean;
  fetchingMandateStats: boolean;
  fetchingRecoveryStats: boolean;
  fetchingLinkedAccountStats: boolean;
  fetchingBlackboxLoanRequests: boolean;
  creditassessmentLoanRequests: any,
  creditassessmentDisbursedloans: any,
  creditassessmentDisbursedVolume: any,
  creditassessmentCQefficiency: any,
  collectionDue: any,
  amountCollected: any,
  collectionChannel: any,
  recoverySwitch: any,
  rangeInDays: string;
  mandateStats: any;
  recoveryStats: any;
  linkedAccountStats: any;
  items: { [name: string]: string };
  showShareModal: boolean;
  dashboard: string;
}

class OtherInstitutionDashboard extends Component<
  IOtherInstitutionDashboardProps,
  IOtherInstitutionDashboardState
> {
  constructor(props: IOtherInstitutionDashboardProps) {
    super(props);
    this.state = {
      activeMandatesCount: 0,
      activeMandatesValue: 0,
      cancelledMandatesCount: 0,
      cancelledMandatesValue: 0,
      linkedBVNCount: 0,
      recoveryTransactionCount: 0,
      amountAttemptedValue: 0,
      amountRecoveredValue: 0,
      recoveryEfficiency: 0,
      pendingSettlementValue: 0,
      successfulSettlementValue: 0,
      linkedBVNAccountCount: 0,
      linkedAccountCount: 0,
      blackboxLoanRequestCount: 0,
      blackboxLoanRequestAmount: 0,
      // barChartColor: [],
      fetchingData: true,
      fetchingMandateStats: true,
      fetchingRecoveryStats: true,
      fetchingLinkedAccountStats: true,
      fetchingBlackboxLoanRequests: true,
      rangeInDays: "30",
      mandateStats: [],
      recoveryStats: [],
      linkedAccountStats: [],
      items: {},
      showShareModal: false,
      dashboard: "",
      creditassessmentLoanRequests: [],
      creditassessmentDisbursedloans: [],
      creditassessmentDisbursedVolume: [],
      creditassessmentCQefficiency: [],
      collectionDue: [],
      amountCollected: [],
      collectionChannel: [],
      recoverySwitch: [],
    };
  }

  async componentDidMount() {
    const decodedToken = decodeToken(getCurrentUserSession().token || "");

    const response = await Promise.allSettled([
      mandateAgentService.retrieveInstitutionsMandateDetailsTickers(),
      sweepAgentService.retrieveInstitutionRecoveryDetailsTickers(decodedToken?.["InstitutionCode"] || ""),
      sweepAgentService.retrieveInstitutionRecoveryStats(this.state.rangeInDays, decodedToken?.["InstitutionCode"] || ""),
      mandateStatsService.retrieveAllMandateStatResponsePerInstitution(decodedToken?.["InstitutionId"] || "", this.state.rangeInDays),
      bvnAgentService.retrieveInstitutionLinkedAccountStats(this.state.rangeInDays, decodedToken?.["InstitutionId"] || ""),
      bvnAgentService.retrieveLinkedBVNsCount(decodedToken?.["InstitutionCode"] || ""),
      sweepAgentService.retrieveInstitutionPendingSettlement(decodedToken?.["InstitutionCode"] || ""),
      sweepAgentService.retrieveInstitutionSuccessfulSettlement(decodedToken?.["InstitutionCode"] || ""),
      bvnAgentService.retrieveLinkedAccountCount(decodedToken?.["InstitutionCode"] || ""),
      loanRequestService.retrieveInstitutionBlackboxRequestsCount(decodedToken?.["InstitutionCode"] || ""),
      loanRequestService.retrieveInstitutionBlackboxRequestsAmount(decodedToken?.["InstitutionCode"] || ""),
      creditAssessmentService.retrieveCQefficiency(decodedToken?.["InstitutionCode"] || ""),
      creditAssessmentService.retrieveDisbursedLoanCount(decodedToken?.["InstitutionCode"] || ""),
      creditAssessmentService.retrieveDisbursedLoanVolume(decodedToken?.["InstitutionCode"] || ""),
      creditAssessmentService.retrieveLoanRequestCount(decodedToken?.["InstitutionCode"] || ""),
      collectionService.retrieveInstitutionCollectionDue(decodedToken?.["InstitutionCode"] || ""),
      collectionService.retrieveInstitutionAmountCollected(decodedToken?.["InstitutionCode"] || ""),
      collectionService.retrieveInstitutionCollectionChannel(decodedToken?.["InstitutionCode"] || ""),
      collectionService.retrieveInstitutionRecoverySwitch(decodedToken?.["InstitutionCode"] || ""),
    ])
    const allStats = response[0];
    const allRecoveryDetails = response[1];
    const recoveryStats = response[2];
    const mandateStats = response[3];
    const linkedAccountStats = response[4];
    const linkedBVNCount = response[5];
    const pendingSettlementValue = response[6];
    const successfulSettlementValue = response[7];
    const allLinkedAccountStats = response[8];
    const blackboxLoanRequestCount = response[9];
    const blackboxLoanRequestAmount = response[10];
    const creditassessmentCQefficiency = response[11];
    const creditassessmentDisbursedloans = response[12];
    const creditassessmentDisbursedVolume = response[13];
    const creditassessmentLoanRequests = response[14];
    const collectionDue = response[15];
    const amountCollected = response[16];
    const collectionChannel = response[17];
    const recoverySwitch = response[18];
    // const CQefficiency 


    const activeMandatesCount = allStats.status == "fulfilled" ? allStats.value.data.activeMandatesCount : 0;
    const activeMandatesValue = allStats.status == "fulfilled" ? allStats.value.data.activeMandatesValue : 0;
    const cancelledMandatesCount = allStats.status == "fulfilled" ? allStats.value.data.cancelledMandatesCount : 0;
    const cancelledMandatesValue = allStats.status == "fulfilled" ? allStats.value.data.cancelledMandatesValue : 0;

    const recoveryTransactionCount = allRecoveryDetails.status == "fulfilled" ? allRecoveryDetails.value.data.recoveryTransactionCount : 0;
    const amountAttemptedValue = allRecoveryDetails.status == "fulfilled" ? allRecoveryDetails.value.data.amountAttemptedValue : 0;
    const amountRecoveredValue = allRecoveryDetails.status == "fulfilled" ? allRecoveryDetails.value.data.amountRecoveredValue : 0;
    const recoveryEfficiency = allRecoveryDetails.status == "fulfilled" ? allRecoveryDetails.value.data.recoveryEfficiency : 0;

    const linkedBVNAccountCount = allLinkedAccountStats.status == "fulfilled" ? allLinkedAccountStats.value.data.noOfBvns : 0;
    const linkedAccountCount = allLinkedAccountStats.status == "fulfilled" ? allLinkedAccountStats.value.data.noOfAccounts : 0;
    


    // const barChartColor = [generateRandomRGBA(0.35)];
    this.setState({
      activeMandatesCount,
      activeMandatesValue,
      cancelledMandatesCount,
      cancelledMandatesValue,
      recoveryTransactionCount,
      amountAttemptedValue,
      amountRecoveredValue,
      recoveryEfficiency,
      pendingSettlementValue: pendingSettlementValue.status == "fulfilled" ? pendingSettlementValue.value.data : 0,
      successfulSettlementValue: successfulSettlementValue.status == "fulfilled" ? successfulSettlementValue.value.data : 0,
      linkedBVNCount: linkedBVNCount.status == "fulfilled" ? linkedBVNCount.value.data : 0,
      // barChartColor: barChartColor,
      linkedBVNAccountCount,
      linkedAccountCount,
      mandateStats: (mandateStats.status == "fulfilled" ? mandateStats.value.data : []).reverse(),
      fetchingData: false,
      fetchingMandateStats: false,
      recoveryStats: recoveryStats.status == "fulfilled" ? recoveryStats.value.data : [],
      fetchingRecoveryStats: false,
      linkedAccountStats: linkedAccountStats.status == "fulfilled" ? linkedAccountStats.value.data : [],
      fetchingLinkedAccountStats: false,
      blackboxLoanRequestCount: blackboxLoanRequestCount.status == "fulfilled" ? blackboxLoanRequestCount.value.data : 0,
      blackboxLoanRequestAmount: blackboxLoanRequestAmount.status == "fulfilled" ? blackboxLoanRequestAmount.value.data : 0,
      creditassessmentLoanRequests: creditassessmentLoanRequests.status == "fulfilled" ? creditassessmentLoanRequests.value.data : 0,
      creditassessmentCQefficiency: creditassessmentCQefficiency.status == "fulfilled" ? creditassessmentCQefficiency.value.data : 0,
      creditassessmentDisbursedloans: creditassessmentDisbursedloans.status == "fulfilled" ? creditassessmentDisbursedloans.value.data : 0,
      creditassessmentDisbursedVolume: creditassessmentDisbursedVolume.status == "fulfilled" ? creditassessmentDisbursedVolume.value.data : 0,
      collectionDue: collectionDue.status == "fulfilled" ? collectionDue.value.data : 0,
      amountCollected: amountCollected.status == "fulfilled" ? amountCollected.value.data : 0,
      collectionChannel: collectionChannel.status == "fulfilled" ? collectionChannel.value.data : 0,
      recoverySwitch: recoverySwitch.status == "fulfilled" ? recoverySwitch.value.data : 0,
      fetchingBlackboxLoanRequests: false
    });
  }

  handleOnRangeInputChange = async (value) => {
    if (value === "") {
      return;
    }
    // console.log(value);
    this.setState({
      rangeInDays: value,
      fetchingMandateStats: true,
      fetchingRecoveryStats: true,
      fetchingLinkedAccountStats: true,
    });

    const decodedToken = decodeToken(getCurrentUserSession().token || "");

    const response = await Promise.all([
      mandateStatsService.retrieveAllMandateStatResponsePerInstitution(decodedToken?.["InstitutionId"] || "", value),
      sweepAgentService.retrieveInstitutionRecoveryStats(value, decodedToken?.["InstitutionId"] || ""),
      bvnAgentService.retrieveInstitutionLinkedAccountStats(value, decodedToken?.["InstitutionId"] || ""),
      // creditAssessmentService.retrieveLoanRequestCount(decodedToken?.["InstitutionCode"] || ""),
      // creditAssessmentService.retrieveDisbursedLoanCount(decodedToken?.["InstitutionCode"] || ""),
      // creditAssessmentService.retrieveDisbursedLoanVolume(decodedToken?.["InstitutionCode"] || ""),
      // creditAssessmentService.retrieveCQefficiency(decodedToken?.["InstitutionCode"] || ""),
      // collectionService.retrieveInstitutionCollectionDue(decodedToken?.["InstitutionCode"] || ""),
      // collectionService.retrieveInstitutionAmountCollected(decodedToken?.["InstitutionCode"] || ""),
      // collectionService.retrieveInstitutionCollectionChannel(decodedToken?.["InstitutionCode"] || ""),
      // collectionService.retrieveInstitutionRecoverySwitch(decodedToken?.["InstitutionCode"] || ""),
      
    ])
    const mandateStats = response[0];
    const recoveryStats = response[1];
    const linkedAccountStats = response[2];
    // const creditassessmentLoanRequests = response[3];
    // const creditassessmentDisbursedloans = response[4];
    // const creditassessmentDisbursedVolume = response[5];
    // const collectionDue = response[6];
    // const amountCollected = response[7];
    // const collectionChannel = response[8];
    // const recoverySwitch = response[9];
    

    this.setState({
      mandateStats: mandateStats.data.reverse(),
      recoveryStats: recoveryStats.data,
      linkedAccountStats: linkedAccountStats.data,
      fetchingMandateStats: false,
      fetchingRecoveryStats: false,
      fetchingLinkedAccountStats: false,
      // creditassessmentLoanRequests: creditassessmentLoanRequests.data,
      // creditassessmentDisbursedloans: creditassessmentDisbursedloans.data,
      // creditassessmentDisbursedVolume: creditassessmentDisbursedVolume.data,
      // collectionDue: collectionDue.data,
      // amountCollected: amountCollected.data,
      // collectionChannel: collectionChannel.data,
      // recoverySwitch: recoverySwitch.data
    });
  };

  toggleShowShareModal = async () => {
    const items = this.state.items;
    items.pngAttachment = await htmlToImage.toJpeg(
      document.getElementById("v-pills-tabContent1")!
    );
    items.mandatesHeaders = await htmlToImage.toPng(
      document.getElementById("mandatesHeaders")!
    );
    items.mandatesChart1 = await htmlToImage.toPng(
      document.getElementById("mandatesChart1")!
    );
    items.mandatesChart2 = await htmlToImage.toPng(
      document.getElementById("mandatesChart2")!
    );
    items.linkingHeaders = await htmlToImage.toPng(
      document.getElementById("linkingHeaders")!
    );
    items.linkedAccountsHeaders = await htmlToImage.toPng(
      document.getElementById("linkedAccountsHeaders")!
    );
    items.linkedAccountsTrends = await htmlToImage.toPng(
      document.getElementById("linkedAccountsTrends")!
    );
    items.recoveryHeaders = await htmlToImage.toPng(
      document.getElementById("recoveryHeaders")!
    );
    items.recoveryTrends = await htmlToImage.toPng(
      document.getElementById("recoveryTrends")!
    );
    items.settlementHeaders = await htmlToImage.toPng(
      document.getElementById("settlementHeaders")!
    );
    // items.institutionsHeaders = await htmlToImage.toPng(
    //   document.getElementById("institutionsHeaders")!
    // );
    // items.institutionsChart = await htmlToImage.toPng(
    //   document.getElementById("institutionsChart")!
    // );
    // items.settlementHeaders = await htmlToImage.toPng(
    //   document.getElementById("settlementHeaders")!
    // );
    this.setState({
      // items,
      showShareModal: !this.state.showShareModal,
    });
  };

  render() {
    const {
      mandateStats,
      fetchingMandateStats,
      rangeInDays,
      recoveryStats,
      fetchingRecoveryStats,
      linkedAccountStats,
      fetchingLinkedAccountStats,
      blackboxLoanRequestAmount,
      blackboxLoanRequestCount,
      fetchingBlackboxLoanRequests,
      showShareModal,
      items,
      dashboard,
      fetchingData,
      // creditassessmentLoanRequests,
      // creditassessmentDisbursedloans,
      // creditassessmentDisbursedVolume,
      // collectionDue,
      // amountCollected,
      // collectionChannel,
      // recoverySwitch

    } = this.state;

    const lineChartOptions = {
      animation: {
        duration: 30,
      },
      scales: {
        y: {
          ticks: {
            beginAtZero: true,
          },
        },
      },
    };

    const rangeInDaysInputData = [
      { name: "7 Days", value: "7" },
      { name: "14 Days", value: "14" },
      { name: "30 Days", value: "30" },
    ];

    // console.log("logging data", this.state.activeMandatesValue);
    // console.log("logging data", this.state.mandateStats);
    const lineChartCountList = mandateStats.map((item) => item.count);
    const lineChartValueList = mandateStats.map((item) => item.value / 100);
    const lineChartLabels = mandateStats.map((item) => item.label);

    // const creditassessmentLoanRequestsItem = creditassessmentLoanRequests.map(
    //   (item) => item.value
    // );
    // const creditassessmentDisbursedloansItem = creditassessmentDisbursedloans.map(
    //   (item) => item.value
    // );
    // const creditassessmentDisbursedVolumeItem = creditassessmentDisbursedVolume.map(
    //   (item) => item.value
    //  );
    //  const collectionDueItem = collectionDue.map(
    //   (item) => item.value
    // );
    // const amountCollectedItem = amountCollected.map(
    //   (item) => item.value
    // );
     // const collectionChannelItem = collectionChannel.map(
    //   (item) => item.value
    //  );
    //  const recoverySwitchItem = recoverySwitch.map(
    //   (item) => item.value
    //  );
    const recoveryLineChartAttemptedList = recoveryStats.map(
      (item) => item.amountAttempted / 100
    );
    const recoveryLineChartRecoveredList = recoveryStats.map(
      (item) => item.amountRecovered / 100
    );
    const recoveryLineChartEfficiencyList = recoveryStats.map(
      (item) =>
        ((item.amountRecovered / 100) / (item.amountAttempted / 100) == 0 ? 1 : (item.amountAttempted / 100)) * 100
    );
    const recoveryLineChartLabels = recoveryStats.map((item) =>
      new Date(item.timeSwept).toDateString()
    );
    const linkedAccountCountList = linkedAccountStats.map(
      (item) => item.noOfAccounts
    );
    const linkedBvnAccountCountList = linkedAccountStats.map(
      (item) => item.noOfBvns
    );
    const linkedAccountLineChartLabels = linkedAccountStats.map((item) =>
      new Date(item.dateLinked).toDateString()
    );

    return (
      <React.Fragment>
        <ShareModal
          showShareModal={showShareModal}
          items={items}
          toggleShowShareModal={this.toggleShowShareModal}
          dashboard={dashboard}
        />
        <div className="row" style={{ padding: "10px" }}>
          <div className="col-12 col-xl-12">
            <div className="d-flex justify-content-between">
              {/* <div className='align-self-center'>
                <strong>
                  <i className='icon icon-bar-chart s-18 mr-2'></i>
                  OtherInstitutionDashboards
                </strong>
              </div> */}
              <div className="align-self-end float-right">
                <ul
                  className="nav nav-tabs card-header-tabs nav-material"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active show"
                      id="w2--tab1"
                      data-toggle="tab"
                      href="#w2-tab1"
                      role="tab"
                      aria-controls="tab1"
                      aria-expanded="true"
                      aria-selected="true"
                    >
                      <i className="icon icon-certificate s-18 ml-3"></i>
                      Mandates
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="w2--tab4"
                      data-toggle="tab"
                      href="#w2-tab4"
                      role="tab"
                      aria-controls="tab4"
                      aria-selected="false"
                    >
                      <i className="icon icon-certificate2 s-18"></i>Bvn Linking
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="w2--tab6"
                      data-toggle="tab"
                      href="#w2-tab6"
                      role="tab"
                      aria-controls="tab6"
                      aria-selected="false"
                    >
                      <i className="icon icon-link2 s-18"></i>Linked Accounts
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a
                      className="nav-link"
                      id="w2--tab2"
                      data-toggle="tab"
                      href="#w2-tab2"
                      role="tab"
                      aria-controls="tab2"
                      aria-selected="false"
                    >
                      <i className="icon icon-money s-18"></i>Loans
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="w2--tab3"
                      data-toggle="tab"
                      href="#w2-tab3"
                      role="tab"
                      aria-controls="tab3"
                      aria-selected="false"
                    >
                      <i className="icon icon-repeat s-18"></i>Recovery
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="w2--tab5"
                      data-toggle="tab"
                      href="#w2-tab5"
                      role="tab"
                      aria-controls="tab5"
                      aria-selected="false"
                    >
                      <i className="icon icon-balance-scale s-18"></i>Settlement
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="w2--tab9"
                      data-toggle="tab"
                      href="#w2-tab9"
                      role="tab"
                      aria-controls="tab9"
                      aria-selected="false"
                    >
                      <i className="icon icon-money s-18"></i>Credit Assessment
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="w2--tab10"
                      data-toggle="tab"
                      href="#w2-tab10"
                      role="tab"
                      aria-controls="tab10"
                      aria-selected="false"
                    >
                      <i className="icon icon-money s-18"></i>Collections
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="w2--tab7"
                      data-toggle="tab"
                      href="#w2-tab7"
                      role="tab"
                      aria-controls="tab7"
                      aria-selected="false"
                    >
                      <i className="icon icon-box s-18"></i>Blackbox
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="tab-content" id="v-pills-tabContent1">
              <div
                className="tab-pane fade show active"
                id="w2-tab1"
                role="tabpanel"
                aria-labelledby="w2-tab1"
              >
                <br />
                <div className="row pb-3 col my-3">
                  <button
                    type="button"
                    disabled={fetchingData}
                    className="btn btn-outline-primary btn-xs float-right mr-2"
                    onClick={() => {
                      this.setState({ dashboard: "Mandates Dashboard " });
                      this.toggleShowShareModal();
                    }}
                  >
                    {fetchingData === false ? (
                      <React.Fragment>
                        <i className="icon-share2 mr-2"></i>
                        Share
                      </React.Fragment>
                    ) : (
                      <i className="fas fa-spinner fa-spin" />
                    )}
                  </button>
                </div>
                <div className="my-3">
                  <div
                    className="row text-white no-gutters no-m shadow"
                    id="mandatesHeaders"
                  >
                    <div className="col-lg-3">
                      <div className="green counter-box p-40">
                        <h6 className="counter-title">
                          Active Mandates (Amount)
                        </h6>
                        <div className="float-right">
                          <img src={cash} alt="mandate" />
                        </div>
                        <div className="sc-counter s-36 counter-animated">
                          {this.state.fetchingData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenMoney(
                              this.state.activeMandatesValue / 100,
                              0
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="blue1 counter-box p-40">
                        <h6 className="counter-title">
                          Active Mandates (Count)
                        </h6>
                        <div className="float-right">
                          <img src={resume} alt="mandate" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenNumber(
                              this.state.activeMandatesCount,
                              0
                            )
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="sunfollower counter-box p-40">
                        <h6 className="counter-title">
                          Cancelled Mandates (Amount)
                        </h6>
                        <div className="float-right">
                          <img src={cash} alt="mandate" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenMoney(
                              this.state.cancelledMandatesValue / 100,
                              0
                            )
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="strawberry counter-box p-40">
                        <h6 className="counter-title">
                          Cancelled Mandates (Count)
                        </h6>
                        <div className="float-right">
                          <img src={resume} alt="mandate" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenNumber(
                              this.state.cancelledMandatesCount,
                              0
                            )
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row pb-3" id="mandatesChart1">
                    <div className="col">
                      <div className="card no-b">
                        <div className="card-header white">
                          <div className="d-flex justify-content-between">
                            <div className="align-self-center">
                              <strong>Mandate Trend</strong>
                            </div>
                            <div className="align-self-end float-right">
                              <ul
                                className="nav nav-tabs card-header-tabs nav-material"
                                role="tablist"
                              >
                                <li className="nav-item pr-3">
                                  <DetailItemSelectComponent
                                    id="rangeInDays"
                                    name="rangeInDays"
                                    value={rangeInDays}
                                    items={rangeInDaysInputData}
                                    validated={false}
                                    errors={[]}
                                    onChange={(id, value) =>
                                      this.handleOnRangeInputChange(value)
                                    }
                                    disabled={fetchingMandateStats}
                                  />
                                </li>
                                <li className="nav-item">
                                  <a
                                    className="nav-link active show"
                                    id="w2--tab1b"
                                    data-toggle="tab"
                                    href="#w2-tab1b"
                                    role="tab"
                                    aria-controls="tab1b"
                                    aria-expanded="true"
                                    aria-selected="true"
                                  >
                                    <i className="icon icon-certificate s-18 ml-3"></i>
                                    Counts
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a
                                    className="nav-link"
                                    id="w2--tab2b"
                                    data-toggle="tab"
                                    href="#w2-tab2b"
                                    role="tab"
                                    aria-controls="tab2b"
                                    aria-selected="false"
                                  >
                                    <i className="icon icon-money s-18"></i>
                                    Value
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="card-body no-p">
                          <div className="tab-content" id="v-pills-tabContent1">
                            <div
                              className="tab-pane fade show active"
                              id="w2-tab1b"
                              role="tabpanel"
                              aria-labelledby="w2-tab1b"
                            >
                              <div className="card p-3">
                                <div>
                                  <Line
                                    height={120}
                                    data={{
                                      labels: lineChartLabels,
                                      datasets: [
                                        {
                                          label: "No. of Mandates",
                                          data: lineChartCountList,
                                          fill: false,
                                          backgroundColor: "rgb(54, 162, 235)",
                                          borderColor:
                                            "rgba(54, 162, 235, 0.6)",
                                        },
                                      ],
                                    }}
                                    options={lineChartOptions}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="w2-tab2b"
                              role="tabpanel"
                              aria-labelledby="w2-tab2b"
                            >
                              <div className="card p-3">
                                <div>
                                  <Line
                                    height={120}
                                    data={{
                                      labels: lineChartLabels,
                                      datasets: [
                                        {
                                          label: "Mandate Value",
                                          data: lineChartValueList,
                                          fill: false,
                                          backgroundColor: "rgb(76, 175, 80)",
                                          borderColor: "rgba(76, 175, 80, 0.6)",
                                        },
                                      ],
                                    }}
                                    options={lineChartOptions}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row pb-3" id="mandatesChart2">
                    <div className="col-md-8"></div>
                    <div className="col-md-4">
                      <div className="card p-3">
                        <p style={{ fontSize: "16px" }}>Count Statistics</p>
                        <br />
                        <div className="card-body pt-0">
                          <Pie
                            data={{
                              maintainAspectRatio: false,
                              responsive: false,
                              labels: ["Active Mandates", "Cancelled Mandates"],
                              datasets: [
                                {
                                  data: [
                                    this.state.activeMandatesCount,
                                    this.state.cancelledMandatesCount,
                                  ],
                                  backgroundColor: [
                                    "rgb(54, 162, 235)",
                                    "#ed5564",
                                  ],
                                  hoverBackgroundColor: [
                                    "rgb(54, 162, 235,0.5)",
                                    "#ed7780",
                                  ],
                                },
                              ],
                            }}
                            options={
                              {
                                legend: {
                                  display: true,
                                  position: "right",
                                },
                                elements: {
                                  arc: {
                                    borderWidth: 0,
                                  },
                                },
                              }
                              // ref={input => {
                              //   chartInstance = input;
                              // }
                            }
                          />
                        </div>
                      </div>
                      <br />
                      {/* <div className="card">
                        <div className="card-header white">
                          <strong> Progress </strong>
                        </div>
                        <div className="card-body pt-0">
                          <div className="my-3">
                            <small>25% Complete</small>
                            <div className="progress" style={{ height: "3px" }}>
                              <div
                                className="progress-bar bg-success"
                                role="progressbar"
                                style={{ width: "25%" }}
                                aria-valuenow={25}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                          <div className="my-3">
                            <small>45% Complete</small>
                            <div className="progress" style={{ height: "3px" }}>
                              <div
                                className="progress-bar bg-info"
                                role="progressbar"
                                style={{ width: "45%" }}
                                aria-valuenow={25}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                          <div className="my-3">
                            <small>60% Complete</small>
                            <div className="progress" style={{ height: "3px" }}>
                              <div
                                className="progress-bar bg-warning"
                                role="progressbar"
                                style={{ width: "60%" }}
                                aria-valuenow={25}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                          <div className="my-3">
                            <small>75% Complete</small>
                            <div className="progress" style={{ height: "3px" }}>
                              <div
                                className="progress-bar bg-danger"
                                role="progressbar"
                                style={{ width: "75%" }}
                                aria-valuenow={25}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                          <div className="my-3">
                            <small>100% Complete</small>
                            <div className="progress" style={{ height: "3px" }}>
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: "100%" }}
                                aria-valuenow={25}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <br />
                      {/* <div className='card'>
                        <div className='card-header white'>
                          <strong> Visitors </strong>
                        </div>
                        <div className='card-body'>
                          <div className='circular-progressbar '>
                            <div className='circular-progressbar-inner'>
                              <div
                                style={{
                                  display: 'inline',
                                  width: '120px',
                                  height: '120px'
                                }}
                              >
                                <canvas
                                  // ref={React.useRef<HTMLCanvasElement>(null)}
                                  width={240}
                                  height={240}
                                  style={{
                                    width: '120px',
                                    height: '120px'
                                  }}
                                ></canvas>
                                <input
                                  type='text'
                                  value='50'
                                  data-thickness='0.2'
                                  data-width='100'
                                  data-bgcolor='#eee'
                                  data-fgcolor='#F25E5E'
                                  className='knob-animated'
                                  style={{
                                    display: 'none',
                                    width: '0px',
                                    visibility: 'hidden'
                                  }}
                                />
                              </div>
                              <span className='knob-percent'>
                                50<span>%</span>
                              </span>
                            </div>
                            <div className='mt-3 text-center'>
                              <h5 className='heading'>Traffic</h5>
                              <span>Direct Visitors</span>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="w2-tab2"
                role="tabpanel"
                aria-labelledby="w2-tab2"
              >
                <div className="my-3">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="counter-box white r-3">
                        <div className="p-4">
                          <div className="float-right">
                            <img src={stack_of_money} alt="total_mandates" />
                            {/* <span className='icon icon-note-list text-light-blue s-48'></span> */}
                          </div>
                          <div className="counter-title">Loan Value</div>
                          <h3 className="sc-counter mt-3">
                            {/* <CountUp end={1228} duration={5} /> */}
                          </h3>
                        </div>
                        {/* <div className='progress progress-xs r-0'>
                        <div
                          className='progress-bar'
                          role='progressbar'
                          style={{ width: '25%' }}
                          aaria-valuenow={25}
                          aria-valuemin={0}
                          aria-valuemax={128}
                        ></div>
                      </div> */}
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="counter-box white r-3">
                        <div className="p-4">
                          <div className="float-right">
                            <span className="icon icon-mail-envelope-open s-48"></span>
                          </div>
                          <div className="counter-title ">Premium Themes</div>
                          <h3 className="sc-counter mt-3">
                            {/* <CountUp end={1228} duration={5} /> */}
                          </h3>
                        </div>
                        {/* <div className='progress progress-xs r-0'>
                        <div
                          className='progress-bar'
                          role='progressbar'
                          style={{ width: '50%' }}
                          aria-valuenow={25}
                          aria-valuemin={0}
                          aria-valuemax={128}
                        ></div>
                      </div> */}
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="white counter-box">
                        <div className="p-4">
                          <div className="float-right">
                            <span className="icon icon-stop-watch3 s-48"></span>
                          </div>
                          <div className="counter-title">Support Requests</div>
                          <h3 className="sc-counter mt-3">
                            {/* <CountUp end={1228} duration={5} /> */}
                          </h3>
                        </div>
                        {/* <div className='progress progress-xs r-0'>
                        <div
                          className='progress-bar'
                          role='progressbar'
                          style={{ width: '75%' }}
                          aria-valuenow={25}
                          aria-valuemin={0}
                          aria-valuemax={128}
                        ></div>
                      </div> */}
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="white counter-box">
                        <div className="p-4">
                          <div className="float-right">
                            <span className="icon icon-inbox-document-text s-48"></span>
                          </div>
                          <div className="counter-title">Support Requests</div>
                          <h3 className="sc-counter mt-3">
                            {/* <CountUp end={1228} duration={5} /> */}
                          </h3>
                        </div>
                        {/* <div className='progress progress-xs r-0'>
                        <div
                          className='progress-bar'
                          role='progressbar'
                          style={{ width: '25%' }}
                          aria-valuenow={25}
                          aria-valuemin={0}
                          aria-valuemax={128}
                        ></div>
                      </div> */}
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row pb-3">
                    <div className="col-md-8">
                      <div className="card p-3">
                        <p style={{ fontSize: "16px" }}>Loan Booking Trend</p>
                        <br />
                        <div>
                          <Bar
                            data={{
                              labels: [
                                "Sunday",
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday",
                                "Saturday",
                              ],
                              datasets: [
                                {
                                  label: "Loans",
                                  fill: false,
                                  tension: 0.5,
                                  backgroundColor: [
                                    generateRandomRGBA(0.2),
                                    generateRandomRGBA(0.2),
                                    generateRandomRGBA(0.2),
                                    generateRandomRGBA(0.2),
                                    generateRandomRGBA(0.2),
                                    generateRandomRGBA(0.2),
                                    generateRandomRGBA(0.2),
                                  ],
                                  // borderColor: [
                                  //   generateRandomRGBA(1),
                                  //   generateRandomRGBA(1),
                                  //   generateRandomRGBA(1),
                                  //   generateRandomRGBA(1),
                                  //   generateRandomRGBA(1),
                                  //   generateRandomRGBA(1),
                                  //   generateRandomRGBA(1)
                                  // ],
                                  borderWidth: 2,
                                  data: [400, 528, 228, 728, 528, 162, 350],
                                },
                              ],
                            }}
                            options={{
                              title: {
                                display: false,
                                text: "Loans Booked Daily",
                                fontSize: 10,
                              },
                              legend: {
                                display: true,
                                position: "bottom",
                              },
                            }}
                          ></Bar>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card">
                        <div className="card-header white">
                          <strong> Progress </strong>
                        </div>
                        <div className="card-body pt-0">
                          <div className="my-3">
                            <small>25% Complete</small>
                            <div className="progress" style={{ height: "3px" }}>
                              <div
                                className="progress-bar bg-success"
                                role="progressbar"
                                style={{ width: "25%" }}
                                aria-valuenow={25}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                          <div className="my-3">
                            <small>45% Complete</small>
                            <div className="progress" style={{ height: "3px" }}>
                              <div
                                className="progress-bar bg-info"
                                role="progressbar"
                                style={{ width: "45%" }}
                                aria-valuenow={25}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                          <div className="my-3">
                            <small>60% Complete</small>
                            <div className="progress" style={{ height: "3px" }}>
                              <div
                                className="progress-bar bg-warning"
                                role="progressbar"
                                style={{ width: "60%" }}
                                aria-valuenow={25}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                          <div className="my-3">
                            <small>75% Complete</small>
                            <div className="progress" style={{ height: "3px" }}>
                              <div
                                className="progress-bar bg-danger"
                                role="progressbar"
                                style={{ width: "75%" }}
                                aria-valuenow={25}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                          <div className="my-3">
                            <small>100% Complete</small>
                            <div className="progress" style={{ height: "3px" }}>
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: "100%" }}
                                aria-valuenow={25}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="w2-tab3"
                role="tabpanel"
                aria-labelledby="w2-tab3"
              >
                <br />
                <div className="row pb-3 col my-3">
                  <button
                    type="button"
                    disabled={fetchingData}
                    className="btn btn-outline-primary btn-xs float-right mr-2"
                    onClick={() => {
                      this.setState({ dashboard: "Recovery Dashboard " });
                      this.toggleShowShareModal();
                    }}
                  >
                    {fetchingData === false ? (
                      <React.Fragment>
                        <i className="icon-share2 mr-2"></i>
                        Share
                      </React.Fragment>
                    ) : (
                      <i className="fas fa-spinner fa-spin" />
                    )}
                  </button>
                </div>
                <div className="my-3">
                  <div
                    className="row text-white no-gutters no-m shadow"
                    id="recoveryHeaders"
                  >
                    <div className="col-lg-3">
                      <div className="green counter-box p-40">
                        <h6 className="counter-title">Recovery Count</h6>
                        <div className="float-right">
                          <img src={resume} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenNumber(
                              this.state.recoveryTransactionCount,
                              0
                            )
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <div className="blue1 counter-box p-40">
                        <h6 className="counter-title">Amount Attempted</h6>
                        <div className="float-right">
                          <img src={cash} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenMoney(
                              this.state.amountAttemptedValue / 100,
                              0
                            )
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="sunfollower counter-box p-40">
                        <h6 className="counter-title">Amount Recovered</h6>
                        <div className="float-right">
                          <img src={cash} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenMoney(
                              this.state.amountRecoveredValue / 100,
                              0
                            )
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="strawberry counter-box p-40">
                        <h6 className="counter-title">Recovery Efficiency</h6>
                        <div className="float-right">
                          <img src={resume} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              {this.state.recoveryEfficiency.toFixed(2)}%
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row pb-3" id="recoveryTrends">
                    <div className="col">
                      <div className="card no-b">
                        <div className="card-header white">
                          <div className="d-flex justify-content-between">
                            <div className="align-self-center">
                              <strong>Recovery Trend</strong>
                            </div>
                            <div className="align-self-end float-right">
                              <ul
                                className="nav nav-tabs card-header-tabs nav-material"
                                role="tablist"
                              >
                                <li className="nav-item pr-3">
                                  <DetailItemSelectComponent
                                    id="rangeInDays"
                                    name="rangeInDays"
                                    value={rangeInDays}
                                    items={rangeInDaysInputData}
                                    validated={false}
                                    errors={[]}
                                    onChange={(id, value) =>
                                      this.handleOnRangeInputChange(value)
                                    }
                                    disabled={fetchingRecoveryStats}
                                  />
                                </li>
                                <li className="nav-item">
                                  <a
                                    className="nav-link active show"
                                    id="w2--tabr1c"
                                    data-toggle="tab"
                                    href="#w2-tabr1c"
                                    role="tab"
                                    aria-controls="tabr1c"
                                    aria-expanded="true"
                                    aria-selected="true"
                                  >
                                    <i className="icon icon-money s-18 ml-3"></i>
                                    Amount
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a
                                    className="nav-link"
                                    id="w2--tabr2c"
                                    data-toggle="tab"
                                    href="#w2-tabr2c"
                                    role="tab"
                                    aria-controls="tabr2c"
                                    aria-selected="false"
                                  >
                                    <i className="icon icon-certificate s-18"></i>
                                    Efficiency
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="card-body no-p">
                          <div className="tab-content" id="v-pills-tabContent1">
                            <div
                              className="tab-pane fade show active"
                              id="w2-tabr1c"
                              role="tabpanel"
                              aria-labelledby="w2-tabr1c"
                            >
                              <div className="card p-3">
                                <div>
                                  <Line
                                    height={120}
                                    data={{
                                      labels: recoveryLineChartLabels,
                                      datasets: [
                                        {
                                          label: "Amount Attempted",
                                          data: recoveryLineChartAttemptedList,
                                          fill: false,
                                          backgroundColor: "rgb(245, 66, 108)",
                                          borderColor:
                                            "rgba(245, 66, 108, 0.6)",
                                        },
                                        {
                                          label: "Amount Recovered",
                                          data: recoveryLineChartRecoveredList,
                                          fill: false,
                                          backgroundColor: "rgb(42, 235, 28)",
                                          borderColor: "rgba(42, 235, 28, 0.6)",
                                        },
                                      ],
                                    }}
                                    options={lineChartOptions}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="w2-tabr2c"
                              role="tabpanel"
                              aria-labelledby="w2-tabr2c"
                            >
                              <div className="card p-3">
                                <div>
                                  <Bar
                                    height={120}
                                    data={{
                                      labels: recoveryLineChartLabels,
                                      datasets: [
                                        {
                                          label: "Recovery Efficiency",
                                          fill: false,
                                          tension: 0.5,
                                          backgroundColor: [
                                            generateRandomRGBA(0.2),
                                            generateRandomRGBA(0.2),
                                            generateRandomRGBA(0.2),
                                          ],
                                          borderColor: "rgba(76, 175, 80, 0.2)",
                                          borderWidth: 2,
                                          data: recoveryLineChartEfficiencyList,
                                        },
                                      ],
                                    }}
                                    options={lineChartOptions}
                                  ></Bar>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="w2-tab4"
                role="tabpanel"
                aria-labelledby="w2-tab4"
              >
                <br />
                <div className="row pb-3 col my-3">
                  <button
                    type="button"
                    disabled={fetchingData}
                    className="btn btn-outline-primary btn-xs float-right mr-2"
                    onClick={() => {
                      this.setState({ dashboard: "BVN Linking Dashboard " });
                      this.toggleShowShareModal();
                    }}
                  >
                    {fetchingData === false ? (
                      <React.Fragment>
                        <i className="icon-share2 mr-2"></i>
                        Share
                      </React.Fragment>
                    ) : (
                      <i className="fas fa-spinner fa-spin" />
                    )}
                  </button>
                </div>
                <div className="my-3">
                  <div
                    className="row text-white no-gutters no-m shadow"
                    id="linkingHeaders"
                  >
                    <div className="col-lg-3">
                      <div className="green counter-box p-40">
                        <h6 className="counter-title">Linked BVNs</h6>
                        <div className="float-right">
                          <img src={resume} alt="mandate" />
                        </div>
                        <div className="sc-counter s-36 counter-animated">
                          {this.state.fetchingData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenNumber(
                              this.state.linkedBVNCount,
                              0
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row pb-3"></div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="w2-tab5"
                role="tabpanel"
                aria-labelledby="w2-tab5"
              >
                <br />
                <div className="row pb-3 col my-3">
                  <button
                    type="button"
                    disabled={fetchingData}
                    className="btn btn-outline-primary btn-xs float-right mr-2"
                    onClick={() => {
                      this.setState({ dashboard: "Settlement Dashboard " });
                      this.toggleShowShareModal();
                    }}
                  >
                    {fetchingData === false ? (
                      <React.Fragment>
                        <i className="icon-share2 mr-2"></i>
                        Share
                      </React.Fragment>
                    ) : (
                      <i className="fas fa-spinner fa-spin" />
                    )}
                  </button>
                </div>
                <div className="my-3">
                  <div
                    className="row text-white no-gutters no-m shadow"
                    id="settlementHeaders"
                  >
                    <div className="col-lg-3">
                      <div className="green counter-box p-40">
                        <h6 className="counter-title">
                          Successful Settlement (Amount)
                        </h6>
                        <div className="float-right">
                          <img src={cash} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenMoney(
                              this.state.successfulSettlementValue / 100,
                              0
                            )
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="red counter-box p-40">
                        <h6 className="counter-title">
                          Pending Settlement (Amount)
                        </h6>
                        <div className="float-right">
                          <img src={cash} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenMoney(
                              this.state.pendingSettlementValue / 100,
                              0
                            )
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="w2-tab9"
                role="tabpanel"
                aria-labelledby="w2-tab9"
              >
                <br />
                <div className="row pb-3 col my-3">
                  <button
                    type="button"
                    disabled={fetchingData}
                    className="btn btn-outline-primary btn-xs float-right mr-2"
                    onClick={() => {
                      this.setState({ dashboard: "Credit Assessment Dashboard" });
                      this.toggleShowShareModal();
                    }}
                  >
                    {fetchingData === false ? (
                      <React.Fragment>
                        <i className="icon-share2 mr-2"></i>
                        Share
                      </React.Fragment>
                    ) : (
                      <i className="fas fa-spinner fa-spin" />
                    )}
                  </button>
                </div>
                <div className="my-3">
                  <div
                    className="row text-white no-gutters no-m shadow"
                    id="settlementHeaders"
                  >
                    <div className="col-lg-3">
                      <div className="green counter-box p-40">
                        <h6 className="counter-title">
                          Loan requests
                        </h6>
                        <div className="float-right">
                          <img src={resume} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenNumber(
                              this.state.creditassessmentLoanRequests,
                              0
                            )
                            
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="blue1 counter-box p-40">
                        <h6 className="counter-title">
                          Disbursed loans
                        </h6>
                        <div className="float-right">
                          <img src={resume} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenNumber(
                              this.state.creditassessmentDisbursedloans,
                              0
                            )
                            // 0
                          )}
                        </span>
                      </div>
                      
                    </div>
                    <div className="col-lg-3">
                      <div className="sunfollower counter-box p-40">
                        <h6 className="counter-title">
                          Disbursed loans (Amount)
                        </h6>
                        <div className="float-right">
                          <img src={cash} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenMoney(
                              this.state.creditassessmentDisbursedVolume / 100,
                              0
                            )
                            // 0
                          )}
                        </span>
                      </div>
                      
                    </div>
                    <div className="col-lg-3">
                      <div className="strawberry counter-box p-40">
                        <h6 className="counter-title">
                        CQ efficiency
                        </h6>
                        <div className="float-right">
                          <img src={resume} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            // textFormatService.shortenNumber(
                            //   this.state.creditassessmentCQefficiency,
                            //   0
                            // )
                            0
                          )}
                        </span>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="w2-tab10"
                role="tabpanel"
                aria-labelledby="w2-tab10"
              >
                <br />
                <div className="row pb-3 col my-3">
                  <button
                    type="button"
                    disabled={fetchingData}
                    className="btn btn-outline-primary btn-xs float-right mr-2"
                    onClick={() => {
                      this.setState({ dashboard: "Collection Dashboard" });
                      this.toggleShowShareModal();
                    }}
                  >
                    {fetchingData === false ? (
                      <React.Fragment>
                        <i className="icon-share2 mr-2"></i>
                        Share
                      </React.Fragment>
                    ) : (
                      <i className="fas fa-spinner fa-spin" />
                    )}
                  </button>
                </div>
                <div className="my-3">
                  <div
                    className="row text-white no-gutters no-m shadow"
                    id="settlementHeaders"
                  >
                    <div className="col-lg-3">
                      <div className="green counter-box p-40">
                        <h6 className="counter-title">
                          Collection Due
                        </h6>
                        <div className="float-right">
                          <img src={resume} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenMoney(
                              this.state.collectionDue,
                              0
                            )
                            
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="blue1 counter-box p-40">
                        <h6 className="counter-title">
                          Amount Collected
                        </h6>
                        <div className="float-right">
                          <img src={resume} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenMoney(
                              this.state.amountCollected,
                              0
                            )
                            // 0
                          )}
                        </span>
                      </div>
                      
                    </div>
                    <div className="col-lg-3">
                      <div className="sunfollower counter-box p-40">
                        <h6 className="counter-title">
                          Collection Channel
                        </h6>
                        <div className="float-right">
                          <img src={cash} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            // textFormatService.shortenMoney(
                            //   creditassessmentDisbursedVolumeItem / 100,
                            //   0
                            // )
                            0
                          )}
                        </span>
                      </div>
                      
                    </div>
                    <div className="col-lg-3">
                      <div className="strawberry counter-box p-40">
                        <h6 className="counter-title">
                        Switch to Recovery
                        </h6>
                        <div className="float-right">
                          <img src={resume} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            // textFormatService.shortenNumber(
                            //   this.state.creditassessmentCQefficiency,
                            //   0
                            // )
                            0
                          )}
                        </span>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
              
              <div
                className="tab-pane fade"
                id="w2-tab6"
                role="tabpanel"
                aria-labelledby="w2-tab6"
              >
                <br />
                <div className="row pb-3 col my-3">
                  <button
                    type="button"
                    disabled={fetchingData}
                    className="btn btn-outline-primary btn-xs float-right mr-2"
                    onClick={() => {
                      this.setState({
                        dashboard: "Linked Accounts Dashboard ",
                      });
                      this.toggleShowShareModal();
                    }}
                  >
                    {fetchingData === false ? (
                      <React.Fragment>
                        <i className="icon-share2 mr-2"></i>
                        Share
                      </React.Fragment>
                    ) : (
                      <i className="fas fa-spinner fa-spin" />
                    )}
                  </button>
                </div>
                <div className="my-3">
                  <div
                    className="row text-white no-gutters no-m shadow"
                    id="linkedAccountsHeaders"
                  >
                    <div className="col-lg-3">
                      <div className="green counter-box p-40">
                        <h6 className="counter-title">Linked Accounts</h6>
                        <div className="float-right">
                          <img src={resume} alt="mandate" />
                        </div>
                        <div className="sc-counter s-36 counter-animated">
                          {this.state.fetchingData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenNumber(
                              this.state.linkedAccountCount,
                              0
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="blue1 counter-box p-40">
                        <h6 className="counter-title">Linked BVN Account</h6>
                        <div className="float-right">
                          <img src={resume} alt="mandate" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenNumber(
                              this.state.linkedBVNAccountCount,
                              0
                            )
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row pb-3"></div>
                </div>
                <br />
                <div className="row pb-3" id="linkedAccountsTrends">
                  <div className="col">
                    <div className="card no-b">
                      <div className="card-header white">
                        <div className="d-flex justify-content-between">
                          <div className="align-self-center">
                            <strong>Linked Account Trend</strong>
                          </div>
                          <div className="align-self-end float-right">
                            <ul
                              className="nav nav-tabs card-header-tabs nav-material"
                              role="tablist"
                            >
                              <li className="nav-item pr-3">
                                <DetailItemSelectComponent
                                  id="rangeInDays"
                                  name="rangeInDays"
                                  value={rangeInDays}
                                  items={rangeInDaysInputData}
                                  validated={false}
                                  errors={[]}
                                  onChange={(id, value) =>
                                    this.handleOnRangeInputChange(value)
                                  }
                                  disabled={fetchingLinkedAccountStats}
                                />
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link active show"
                                  id="w2--tabl1c"
                                  data-toggle="tab"
                                  href="#w2-tabl1c"
                                  role="tab"
                                  aria-controls="tabl1c"
                                  aria-expanded="true"
                                  aria-selected="true"
                                >
                                  <i className="icon icon-certificate s-18 ml-3"></i>
                                  Account
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  id="w2--tabl2c"
                                  data-toggle="tab"
                                  href="#w2-tabl2c"
                                  role="tab"
                                  aria-controls="tabl2c"
                                  aria-selected="false"
                                >
                                  <i className="icon icon-certificate s-18"></i>
                                  BVN
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="card-body no-p">
                        <div className="tab-content" id="v-pills-tabContent1">
                          <div
                            className="tab-pane fade show active"
                            id="w2-tabl1c"
                            role="tabpanel"
                            aria-labelledby="w2-tabl1c"
                          >
                            <div className="card p-3">
                              <div>
                                <Line
                                  height={120}
                                  data={{
                                    labels: linkedAccountLineChartLabels,
                                    datasets: [
                                      {
                                        label: "Linked Accounts",
                                        data: linkedAccountCountList,
                                        fill: false,
                                        backgroundColor: "rgb(42, 235, 28)",
                                        borderColor: "rgba(42, 235, 28, 0.6)",
                                      },
                                    ],
                                  }}
                                  options={lineChartOptions}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="w2-tabl2c"
                            role="tabpanel"
                            aria-labelledby="w2-tabl2c"
                          >
                            <div className="card p-3">
                              <div>
                                <Line
                                  height={120}
                                  data={{
                                    labels: linkedAccountLineChartLabels,
                                    datasets: [
                                      {
                                        label: "Linked BVN Accounts",
                                        data: linkedBvnAccountCountList,
                                        fill: false,
                                        backgroundColor: "rgb(54, 162, 235)",
                                        borderColor: "rgba(54, 162, 235, 0.6)",
                                      },
                                    ],
                                  }}
                                  options={lineChartOptions}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="w2-tab7"
                role="tabpanel"
                aria-labelledby="w2-tab7"
              >
                <br />
                <div className="row pb-3 col my-3">
                  {/* <button
                    type="button"
                    disabled={fetching}
                    className="btn btn-outline-primary btn-xs float-right mr-2"
                    onClick={() => {
                      this.setState({ dashboard: "Linked Accounts Dashboard" });
                      this.toggleShowShareModal();
                    }}
                  >
                    {fetching === false ? (
                      <React.Fragment>
                        <i className="icon-share2 mr-2"></i>
                        Share
                      </React.Fragment>
                    ) : (
                      <i className="fas fa-spinner fa-spin" />
                    )}
                  </button> */}
                </div>
                <div className="my-3">
                  <div
                    className="row text-white no-gutters no-m shadow"
                    id="blackboxHeaders"
                  >
                    <div className="col-lg-3">
                      <div className="green counter-box p-40">
                        <h6 className="counter-title">Loan Requests (Amount)</h6>
                        <div className="float-right">
                          <img src={cash} alt="mandate" />
                        </div>
                        <div className="sc-counter s-36 counter-animated">
                          {fetchingBlackboxLoanRequests ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenMoney(
                              blackboxLoanRequestAmount,
                              0
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="blue1 counter-box p-40">
                        <h6 className="counter-title">Loan Requests (Count)</h6>
                        <div className="float-right">
                          <img src={resume} alt="mandate" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {fetchingBlackboxLoanRequests ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenNumber(
                              blackboxLoanRequestCount,
                              0
                            )
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default OtherInstitutionDashboard;
