import React from "react";
import _ from "lodash";

export interface IDetailItemProps {
  label: string;
  value: any;
  labelSize?: number;
  valueSize?: number;
  colorIndicator?: string;
  extraValue?: any;
  extraValueSize?: number;
  required?: boolean;
}

const DetailItem: React.SFC<IDetailItemProps> = ({
  label,
  value,
  labelSize,
  valueSize,
  colorIndicator,
  extraValue,
  extraValueSize,
  required
}) => {
  return (
    <React.Fragment>
      <dt
        className={`col-md-${labelSize ? labelSize.toString() : "4"}`}
        style={{ fontWeight: 600, fontSize: "14px" }}
      >
        {required && (
            <span aria-hidden="true" className="mr-2" style={{ color: "#ed5564" }}>
                *
            </span>
        )}
        {label}:
      </dt>
      {extraValue ? (
        <dd className="row">
          <div
            className={`col-md-${
              valueSize ? valueSize.toString() : "4"
            } blue-grey-text`}
            style={
              colorIndicator
                ? { fontSize: "14px", backgroundColor: `${colorIndicator}` }
                : { fontSize: "14px" }
            }
          >
            {value}
          </div>
          <div
            className={`col-md-${
              extraValueSize ? extraValueSize.toString() : "4"
            } blue-grey-text`}
            style={
              colorIndicator
                ? { fontSize: "14px", backgroundColor: `${colorIndicator}` }
                : { fontSize: "14px" }
            }
          >
            {extraValue}
          </div>
        </dd>
      ) : (
        <dd
          className={`col-md-${
            valueSize ? valueSize.toString() : "8"
          } blue-grey-text`}
          style={
            colorIndicator
              ? { fontSize: "14px", backgroundColor: `${colorIndicator}` }
              : { fontSize: "14px" }
          }
        >
          {value}
        </dd>
      )}
    </React.Fragment>
  );
};

export default DetailItem;
