import http from "../httpService";

const billingBaseAddress = process.env.REACT_APP_BILLING_BASEADDRESS;


export function retrieveFeePayableWalletByInstitutionCode(institutionCode: string) {
  return http.get(
    billingBaseAddress + `FeePayableWallet/institutions/${institutionCode}`
  );
}

export function retrieveAppzoneFeePayableWallet() {
  return http.get(
    billingBaseAddress + `FeePayableWallet/GetAppzoneWalletValue`
  );
}
export function generateInvoice(payload) {
  return http.post(
    billingBaseAddress + `FeePayableWallet/GenerateInvoice`,
    payload
  );
}
export function completeGatewayPayment(payload) {
  return http.post(
    billingBaseAddress + `FeePayableWallet/CompleteGatewayPayment`,
    payload
  );
}
export function PayWithFundsTransfer(payload) {
  return http.post(
    billingBaseAddress + `FeePayableWallet/PayWithFundsTransfer`,
    payload
  );
}
export function CheckHybridAppraisalBillingStatus(institutionCode) {
  return http.post(
    billingBaseAddress + `FeePayableWallet/CheckHybridAppraisalBillingStatus/` + institutionCode
  );
}

export default {
  retrieveFeePayableWalletByInstitutionCode,
  retrieveAppzoneFeePayableWallet,
  generateInvoice,
  completeGatewayPayment,
  PayWithFundsTransfer,
  CheckHybridAppraisalBillingStatus
};
