import React, { Fragment } from "react";
import _ from "lodash";
import { Formik } from "formik";
import ReactWizard from "react-bootstrap-wizard";
import GroupContactDetails from "./wizardComponents/viewDetailedDataEntry/contactDetails";
import GroupDocumentCollection from "./wizardComponents/viewDetailedDataEntry/documentCollection";
import GroupFinancialAnalysis from "./wizardComponents/viewDetailedDataEntry/financialAnalysis";
import GroupNotepadDetails from "./wizardComponents/viewDetailedDataEntry/notepad";
import GroupLoanDetails from "./wizardComponents/viewDetailedDataEntry/loanDetails";
import GroupPersonalDetails from "./wizardComponents/viewDetailedDataEntry/personalDetails";
import { getCurrentUserSession } from "../../../../services/auth/authService";
// import DetailedDataEntry from "./viewDetailedDataEntry";
import BaseFormComponent, { IBaseFormState } from "../../../../components/BaseFormComponent";
import appraisalActionService from "../../../../services/hybridAppraisal/appraisalActionService";
import activityTrailService from "../../../../services/auditTrail/activityTrailService";
import { toast } from "react-toastify";
import { ActionTypes } from "../../../../enums/actionTypes";
import FormTextAreaComponent from "../../../../components/formTextAreaComponent";
import GroupDetailedDataEntry from "./editDetailedDataEntry";

interface IDetailedDataEntryProps {
    item: any;
}

interface IDetailedDataEntryState {
    item: any;
}

interface DataValidationFields {
    comment: string;
}

class DataValidationForm extends BaseFormComponent<DataValidationFields> { }

class ViewGroupDetailedDataEntry extends React.Component<IDetailedDataEntryProps, IBaseFormState & IDetailedDataEntryState> {
    formRef: HTMLFormElement;
    constructor(props: IDetailedDataEntryProps) {
        super(props);
        this.state = {
            item: {},
            validated: false,
            submitting: false,
            errors: {},
        };
    }

    static getDerivedStateFromProps(nextProps: IDetailedDataEntryProps, prevState) {
        return {
            item: nextProps.item,
        };
    }

    onFormSubmit(fields: DataValidationFields, onReloadFieldsCB: any, alt?): boolean {
        try {
            this.setState(
                { errors: [] },
                () => {
                    if (alt == "pushback") {
                        return this.submit(fields, onReloadFieldsCB, alt);
                    }
                    if (Object.keys(this.state.errors).length === 0) {
                        return this.submit(fields, onReloadFieldsCB);
                    }
                }
            );
            this.setState({ validated: true });
            return false;
        } catch (error) {
            return false;
        }
    }

    async submit(
        fields: DataValidationFields,
        onReloadFieldsCB: any,
        alt?
    ): Promise<boolean> {
        try {
            this.setState({ submitting: true });
            const { item } = this.props;

            // toast.info("fetched successfully!")
            // console.log("ended")
            // return false
            if (alt == "pushback") {
                // console.log("progressing action...");
                const currentUserSession = getCurrentUserSession();
                var payload: any = {};
                payload.loanRequestId = item.id
                payload.action = "PushBack"
                payload.itemLevel = 0
                payload.userID = currentUserSession.userId
                payload.roleID = currentUserSession.userRoleId
                payload.systemProcessID = 0
                payload.errorMessage = ""
                payload.logType = "UserType"
                payload.comment = fields.comment
                payload.effectiveStatus = "Valid"
                payload.processResponse = null
                payload.IsGroupLoan = true
                // console.log("Payload: ", payload)
                const response = await appraisalActionService.createAppraisalAction(payload)
                if (response.status >= 200 && response.status <= 300) {
                    toast.info(`Loan Request Pushed Back to Data Entry!`, {
                        autoClose: 6000,
                        type: toast.TYPE.INFO,
                        hideProgressBar: false,
                    });
                    activityTrailService.saveActivityTrail({
                        actionType: "Add Appraisal Action",
                        description: `Added new Appraisal Action`,
                    });
                } else {
                    return false;
                }
            } else {
                // console.log("progressing action...");
                const currentUserSession = getCurrentUserSession();
                var payload: any = {};
                payload.loanRequestId = item.id
                payload.action = "Submit"
                payload.itemLevel = 0
                payload.userID = currentUserSession.userId
                payload.roleID = currentUserSession.userRoleId
                payload.systemProcessID = 0
                payload.errorMessage = ""
                payload.logType = "UserType"
                payload.comment = fields.comment == "" ? "Done with data validation" : fields.comment
                payload.effectiveStatus = "Valid"
                payload.processResponse = null
                payload.IsGroupLoan = true
                // console.log("Payload: ", payload)
                const response = await appraisalActionService.createAppraisalAction(payload)
                if (response.status >= 200 && response.status <= 300) {
                    // console.log(response)
                    toast.info(`Loan Request Appraisal Process would start shortly!`, {
                        autoClose: 6000,
                        type: toast.TYPE.INFO,
                        hideProgressBar: false,
                    });

                    activityTrailService.saveActivityTrail({
                        actionType: "Add Appraisal Action",
                        description: `Added new Appraisal Action`,
                    });
                } else {
                    return false;
                }
            }
            this.setState({ submitting: false });
            this.resetFields();
            // onReloadFieldsCB();
        } catch (error) {
            if (error.response.status === 409) {
                toast.error(error.response.data.detail, {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            } else if (error.response.status === 400) {
                toast.error(error.response.data.errors[0], {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            }
            this.setState({ submitting: false });
            return false;
        } finally {
            return true;
        }
    }

    resetFields() {
        this.setState({ validated: false, submitting: false, errors: [] });
    }

    render() {
        const { item } = this.props;
        // console.log("View Item :: ", item)

        return (
            <Fragment>
                <div className="container-fluid relative animatedParent animateOnce">
                    <div className="animated fadeInUpShort go">
                        <div className="row my-3 mx-2">
                            <h3>
                                <b>Data Validation</b>
                            </h3>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Formik
                                    initialValues={{
                                        id: item.id,
                                        requestIdentifier: item.requestIdentifier,
                                        institutionCode: item.institutionCode,
                                        externalInstitutionCode: item.externalInstitutionCode,
                                        description: item.description,
                                        currentLevel: item.currentLevel,
                                        bvn: item.bvn,
                                        linkedAccountNumber: item.linkedAccountNumber,
                                        requestStatus: item.requestStatus,
                                        obligorLimit: item.obligorLimit,
                                        totalIncome: item.totalIncome,
                                        productCode: item.productCode,
                                        approvalDate: item.approvalDate,
                                        appraisalActions: item.appraisalActions,
                                        loanPurpose: item.loanPurpose,
                                        relationshipManager: item.relationshipManager,
                                        loanType: item.loanType,
                                        loanAmount: item.loanAmount,
                                        tenureInDays: item.tenureInDays,
                                        branch: item.branch,
                                        financialGroup: item.financialGroup,
                                        jsonData: JSON.parse(item.jsonData),

                                        // personal details
                                        customerName: item.customerName,
                                        customerFirstName: item.customerFirstName,
                                        customerLastName: item.customerLastName,
                                        customerMiddleName: item.customerMiddleName,
                                        gender: item.gender,
                                        maritalStatus: item.maritalStatus,
                                        identificationType: item.identificationType,
                                        identificationNumber: item.identificationNumber,
                                        identificationExpiryDate: item.identificationExpiryDate,
                                        dateOfBirth: item.dateOfBirth,
                                        title: item.title,
                                        educationalQualification: item.educationalQualification,
                                        motherMaidenName: item.motherMaidenName,
                                        age: item.age,
                                        numberOfDependent: item.numberOfDependent,
                                        accountType: item.accountType,
                                        ageOfAccount: item.ageOfAccount,
                                        domiciliationOfSalary: item.domiciliationOfSalary,
                                        accountNumber: item.accountNumber,
                                        nationality: item.nationality,
                                        tin: item.tin,
                                        repaymentAccountCreationDate: item.repaymentAccountCreationDate,
                                        locationCoordinates: item.locationCoordinates,

                                        // contact details
                                        addressType: item.addressType,
                                        propertyStatus: item.propertyStatus,
                                        address: item.address,
                                        city: item.city,
                                        country: item.country,
                                        state: item.state,
                                        phonenumber: item.phonenumber,
                                        phonenumber2: item.phonenumber2,
                                        customerEmail: item.customerEmail,
                                        yearsOfStayAtCurrentAddress: item.yearsOfStayAtCurrentAddress,
                                        monthsOfStayAtCurrentAddress: item.monthsOfStayAtCurrentAddress,
                                        yearsOfStayAtCurrentCity: item.yearsOfStayAtCurrentCity,
                                        monthsOfStayAtCurrentCity: item.monthsOfStayAtCurrentCity,
                                        twitterHandle: item.twitterHandle,
                                        instagramID: item.instagramID,
                                        facebookID: item.facebookID,
                                        documents: item.documents,

                                        // work details
                                        employmentIndustry: item.employmentIndustry,
                                        employmentDesignation: item.employmentDesignation,
                                        employmentStatus: item.employmentStatus,
                                        employerName: item.employerName,
                                        employerCategory: item.employerCategory,
                                        officePhoneNumber: item.officePhoneNumber,
                                        yearsInCurrentJob: item.yearsInCurrentJob,
                                        monthsInCurrentJob: item.monthsInCurrentJob,
                                        jobAddress: item.jobAddress,
                                        jobCity: item.jobCity,
                                        jobCountry: item.jobCountry,
                                        jobState: item.jobState,

                                        // corporate details
                                        companyName: item.companyName,
                                        registrationNumber: item.registrationNumber,
                                        constitution: item.constitution,
                                        industry: item.industry,
                                        incorporationDate: item.incorporationDate,
                                        contactPerson: item.contactPerson,
                                        designation: item.designation,
                                        lengthOfYearsInBusiness: item.lengthOfYearsInBusiness,
                                        domiciliationOfBusinessProceed: item.domiciliationOfBusinessProceed,
                                        typeOfProductOffered: item.typeOfProductOffered,
                                        productDurability: item.productDurability,
                                        threatOfCompetition: item.threatOfCompetition,
                                        availabilityOfTradingStock: item.availabilityOfTradingStock,
                                        complexityOfProduct: item.complexityOfProduct,
                                        operationalStructure: item.operationalStructure,
                                        numberOfYearsOperatingInTheSameLocation: item.numberOfYearsOperatingInTheSameLocation,
                                        foreignExchangeRisk: item.foreignExchangeRisk,
                                        membershipOfAnOrganizedBody: item.membershipOfAnOrganizedBody,

                                        //others
                                        guarantors: item.guarantors,
                                        stakeholders: item.stakeholders,
                                        collaterals: item.collaterals,
                                        notepads: item.notepads,
                                        assets: item.assets,
                                        loanDetails: item.loanDetails,
                                        incomeDetails: item.incomeDetails,
                                        solidarityGroups: item.solidarityGroups
                                    }}
                                    validate={() => { }}
                                    onSubmit={(values, { setSubmitting }) => { }}
                                >
                                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                        <form onSubmit={handleSubmit} ref={(ref) => (this.formRef = ref)}>
                                            <ReactWizard
                                                className="row my-3 mx-2 font-italic font-weight-bold"
                                                style={{ fontSize: '120px' }}
                                                steps={
                                                    [
                                                        {
                                                            stepName: "Personal Details",
                                                            component: GroupPersonalDetails,
                                                            stepProps: {
                                                                values: values,
                                                                handleChange: handleChange,
                                                                handleBlur: handleBlur,
                                                            },
                                                        },
                                                        {
                                                            stepName: "Contact Details",
                                                            component: GroupContactDetails,
                                                            stepProps: {
                                                                values: values,
                                                                handleChange: handleChange,
                                                                handleBlur: handleBlur,
                                                            },
                                                        },
                                                        {
                                                            stepName: "Document Collection",
                                                            component: GroupDocumentCollection,
                                                            stepProps: {
                                                                values: values,
                                                                handleChange: handleChange,
                                                                handleBlur: handleBlur,
                                                            },
                                                        },
                                                        // {
                                                        //     stepName: "Financial Analysis",
                                                        //     component: GroupFinancialAnalysis,
                                                        //     stepProps: {
                                                        //         values: values,
                                                        //         handleChange: handleChange,
                                                        //         handleBlur: handleBlur,
                                                        //     },
                                                        // },
                                                        {
                                                            stepName: "Notepad Details",
                                                            component: GroupNotepadDetails,
                                                            stepProps: {
                                                                values: values,
                                                                handleChange: handleChange,
                                                                handleBlur: handleBlur,
                                                            },
                                                        },
                                                        {
                                                            stepName: "Loan Details",
                                                            component: GroupLoanDetails,
                                                            stepProps: {
                                                                values: values,
                                                                handleChange: handleChange,
                                                                handleBlur: handleBlur,
                                                            },
                                                        },
                                                    ]
                                                }
                                                previousButtonClasses={isSubmitting ? "hide" : ""}
                                                finishButtonClasses={isSubmitting ? "hide" : ""}
                                                nextButtonClasses={isSubmitting ? "hide" : ""}
                                                validate
                                                finishButtonText="Proceed"
                                                navSteps
                                                color="blue"
                                                finishButtonClick={() => {
                                                    this.formRef.dispatchEvent(new Event("submit"));
                                                }}
                                            />
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                        <DataValidationForm
                            initialValues={{
                                comment: ""
                            }}
                            FormComponent={({
                                fields: { comment },
                                onChange,
                                onReloadFields,
                                onHandleSubmit,
                            }) => (
                                <div className="card no-b">
                                    <div className="card-body">
                                        <dl className="row">
                                            <FormTextAreaComponent
                                                id="comment"
                                                type="text"
                                                name="comment"
                                                placeholder=""
                                                divClass={12}
                                                value={comment}
                                                required={false}
                                                validated={true}
                                                errors={{}}
                                                onChange={onChange}
                                                disabled={false} />
                                        </dl>
                                        <button
                                            type="submit"
                                            className="btn btn-sm btn-secondary"
                                            disabled={this.state.submitting}
                                            onClick={(e) => {
                                                onHandleSubmit(
                                                    e,
                                                    ActionTypes.PUSHBACK,
                                                    "Loan Application",
                                                    () => {
                                                        this.onFormSubmit({ comment }, onReloadFields, "pushback");
                                                    }
                                                );
                                            }}
                                        >
                                            {this.state.submitting === false ? (
                                                <React.Fragment>
                                                    {/* <i className="fas fa-lg fa-save mr-3" />  */}
                                                    Refer Back
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                                    Please wait...
                                                </React.Fragment>
                                            )}
                                        </button>
                                        <div className="float-right">
                                            <button
                                                type="submit"
                                                className="btn btn-sm btn-primary"
                                                disabled={this.state.submitting}
                                                onClick={(e) => {
                                                    onHandleSubmit(
                                                        e,
                                                        ActionTypes.SAVE,
                                                        "Loan Application",
                                                        () => {
                                                            this.onFormSubmit({ comment }, onReloadFields);
                                                        }
                                                    );
                                                }}
                                            >
                                                {this.state.submitting === false ? (
                                                    <React.Fragment>
                                                        <i className="fas fa-lg fa-save mr-3" /> Save & Proceed
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                                        Please wait...
                                                    </React.Fragment>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        ></DataValidationForm>
                    </div>
                </div>
            </Fragment >
        );
    }
}

export default ViewGroupDetailedDataEntry;
