import React from "react";
import Skeleton from "react-loading-skeleton";

export default function Loader() {
  return (
    <div className="row">
      <div className="12">
        <Skeleton width={1250} height={20} />
      </div>
      <div className="12">
        <Skeleton width={1250} height={20} />
      </div>
      <div className="12">
        <Skeleton width={1250} height={20} />
      </div>
      <div className="12">
        <Skeleton width={1250} height={20} />
      </div>
      <div className="12">
        <Skeleton width={1250} height={20} />
      </div>
    </div>
  );
}
