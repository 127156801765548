import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../components/detailItem";
import textFormatService from "../../services/utility/textFormatService";
import DetailItemsTable from "../../components/tableComponents/detailItemsTable";
import { mapEntityList } from "../../services/utility/autoMapperService";
import reportsService from "../../services/sweep/reportsService";
import ThirdPartyImageView from "../../components/thirdPartyImageView";

interface IThirdPartyBillingTransactionsReportDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  item: any;
}

interface IThirdPartyBillingTransactionsReportDetailState {
  //   similarTransactions: any;
  //   similarTransactionsTotalCount: number;
  //   similarTransactionsCurrentPage: number;
  //   fetchingSimilarTransactions: boolean;
}

class ThirdPartyBillingTransactionsReportDetail extends Component<
  IThirdPartyBillingTransactionsReportDetailProps,
  IThirdPartyBillingTransactionsReportDetailState
> {
  constructor(props: IThirdPartyBillingTransactionsReportDetailProps) {
    super(props);
    this.state = {
      //   fetchingSimilarTransactions: false,
      //   similarTransactions: [],
      //   similarTransactionsCurrentPage: 1,
      //   similarTransactionsTotalCount: 0,
    };
  }

  //   handleOnSimilarTransactionsPageChange = async (
  //     page: number,
  //     transactionId
  //   ) => {
  //     this.setState({ fetchingSimilarTransactions: true });
  //     // console.log(
  //     //   "about to fetch request mandates: ",
  //     //   this.state.fetchingSimilarTransactions
  //     // );
  //     try {
  //       const similarTrx = await reportsService.retrieveSimilarTransactionsReport(
  //         transactionId,
  //         Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
  //         page
  //       );
  //       this.setState({
  //         similarTransactions: mapEntityList({
  //           entityList: similarTrx.data.item2,
  //           properties: [
  //             {
  //               oldName: "linkedAccountInstitution",
  //               newName: "Inflow Institution",
  //             },
  //             { oldName: "amountAttempted", newName: "amountAttempted" },
  //             { oldName: "amountDebited", newName: "amountDebited" },
  //             { oldName: "timeSwept", newName: "timeSwept" },
  //             { oldName: "customerType", newName: "customerType" },
  //             { oldName: "retrievalReferenceNo", newName: "retrievalReference" },
  //           ],
  //         }),
  //         similarTransactionsCurrentPage: page,
  //         similarTransactionsTotalCount: similarTrx.data.item1,
  //         fetchingSimilarTransactions: false,
  //       });
  //     } catch (error) {
  //       // console.log("error fetching similar transactions: ", error);
  //       this.setState({ fetchingSimilarTransactions: false });
  //     } finally {
  //       this.setState({ fetchingSimilarTransactions: false });
  //     }
  //   };

  //   async componentDidMount() {
  //     try {
  //       if (this.props.item.id) {
  //         // console.log(
  //         //   'about to fetch similar transactions...',
  //         //   this.props.item.id
  //         // )
  //         this.handleOnSimilarTransactionsPageChange(
  //           this.state.similarTransactionsCurrentPage,
  //           this.props.item.id
  //         );
  //       }
  //     } catch (error) {
  //       this.setState({ fetchingSimilarTransactions: false });
  //     }
  //   }

  render() {
    const { showDetailsModal, toggleDetailsModal, item } = this.props;
    // const {
    //   similarTransactions,
    //   similarTransactionsTotalCount,
    //   similarTransactionsCurrentPage,
    //   fetchingSimilarTransactions,
    // } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="fas fa-receipt fa-sm s-18 mr-3" />
                Third-Party Settlement Details
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={8} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Loan Reference"
                        value={item.loanAccountNo}
                      />
                      <DetailItem
                        label="Loan Account Institution"
                        value={item.loanAccountInstitution}
                      />
                      <DetailItem
                        label="Linked Account No."
                        value={item.linkedAccountNo}
                      />
                      <DetailItem label="Unique ID" value={item.uniqueId} />
                      <DetailItem
                        label="Retrieval Reference"
                        value={item.retrievalReferenceNo}
                      />
                      <DetailItem
                        label="Institution Sweep Round Id"
                        value={item.institutionSweepRoundId}
                      />
                      {/* <DetailItem
                        label="Settlement Status"
                        value={
                          (item ? item.settlementStatus : "Sent") === "Sent" ? (
                            <span className="badge badge-success">
                              {item ? item.settlementStatus : ""}
                            </span>
                          ) : (
                            <span className="badge badge-primary">
                              {item ? item.settlementStatus : ""}
                            </span>
                          )
                        }
                      /> */}
                      <DetailItem
                        label="Third Party Sweep Service"
                        value={
                          <ThirdPartyImageView
                            thirdPartyName={item.thirdPartySweepService}
                          />
                        }
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={8} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem label="Bvn" value={item.bvn} />
                      <DetailItem
                        label="Amount"
                        value={textFormatService.convertKoboToNaira(
                          item.amount,
                          2
                        )}
                      />
                      <DetailItem
                        label="Sweep Episode Id"
                        value={item.sweepEpisodeId}
                      />
                      <DetailItem
                        label="Sweep Cycle Id"
                        value={item.sweepCycleId}
                      />
                      <DetailItem
                        label="Time Swept"
                        value={textFormatService.formatTimeString(
                          item.timeSwept
                        )}
                      />
                      <DetailItem label="Narration" value={item.narration} />
                      <DetailItem
                        label="Designation"
                        value={item.designation}
                      />
                      <DetailItem
                        label="Billing Status"
                        value={item.billingStatus}
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ThirdPartyBillingTransactionsReportDetail;
