import http from "../httpService";
import { IFilterData } from "../approval/approvalItemService";
import { getCurrentUserSession } from "../auth/authService";

const recovaMandateBaseAddress = process.env.REACT_APP_MANDATE_BASEADDRESS;

export function filterStatusMandatesReport(
  payload: IFilterData,
  status: string,
  pageSize: number,
  currentPage: number
) {
  var url =
    recovaMandateBaseAddress +
    `Report/FilterStatusMandatesReport?status=${status}&pageSize=${pageSize}&pageNumber=${currentPage}`;
  return http.post(url, payload, {
    url: url,
    method: "post",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function retrieveStatusReportItemById(status: string, institutionId: number) {
  var url = recovaMandateBaseAddress + `Report/RetrieveStatusReportItemById/?status=${status}`;
  return http.get(url, {
    url: url,
    method: "get",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
      Inst: institutionId
    },
  });
}
// export function filterActiveMandatesReport(
//   payload: IFilterData,
//   pageSize: number,
//   currentPage: number
// ) {
//   var url =
//     recovaMandateBaseAddress +
//     `Report/FilterActiveMandatesReport?pageSize=${pageSize}&pageNumber=${currentPage}`;
//   return http.post(url, payload, {
//     url: url,
//     method: "post",
//     headers: {
//       Authorization: `Bearer ${getCurrentUserSession().token}`,
//     },
//   });
// }
// export function filterCancelledMandatesReport(
//   payload: IFilterData,
//   pageSize: number,
//   currentPage: number
// ) {
//   var url =
//     recovaMandateBaseAddress +
//     `Report/FilterCancelledMandatesReport?pageSize=${pageSize}&pageNumber=${currentPage}`;
//   return http.post(url, payload, {
//     url: url,
//     method: "post",
//     headers: {
//       Authorization: `Bearer ${getCurrentUserSession().token}`,
//     },
//   });
// }
// export function filterRecoveryModeMandatesReport(
//   payload: IFilterData,
//   pageSize: number,
//   currentPage: number
// ) {
//   var url =
//     recovaMandateBaseAddress +
//     `Report/FilterRecoveryModeMandatesReport?pageSize=${pageSize}&pageNumber=${currentPage}`;
//   return http.post(url, payload, {
//     url: url,
//     method: "post",
//     headers: {
//       Authorization: `Bearer ${getCurrentUserSession().token}`,
//     },
//   });
// }
// export function retrieveReportById(institutionId: number) {
//   var url = recovaMandateBaseAddress + `Report/${institutionId}`;
//   return http.get(url, {
//     url: url,
//     method: "get",
//     headers: {
//       Authorization: `Bearer ${getCurrentUserSession().token}`,
//     },
//   });
// }


export default {
  filterStatusMandatesReport,
  retrieveStatusReportItemById
  // filterActiveMandatesReport,
  // filterCancelledMandatesReport,
  // filterRecoveryModeMandatesReport,
  // retrieveReportById,
};
