import React, { Component } from "react";

export interface IEnableUserApprovalDetailProps {
  item: any;
}

export interface IEnableUserApprovalDetailState {
  item: any;
}

class EnableUserApprovalDetail extends Component<
  IEnableUserApprovalDetailProps,
  IEnableUserApprovalDetailState
> {
  constructor(props: IEnableUserApprovalDetailProps) {
    super(props);
    this.state = {
      item: {},
    };
  }

  static getDerivedStateFromProps(
    nextProps: IEnableUserApprovalDetailProps,
    prevState
  ) {
    if (nextProps.item) {
      return { item: JSON.parse(nextProps.item) };
    }
    return null;
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <h6>Enable User Approval Detail</h6>
        </div>
      </React.Fragment>
    );
  }
}

export default EnableUserApprovalDetail;
