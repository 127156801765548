import React, { Fragment, useContext } from "react";
import {
  Modal,
  Button,
  Card,
  useAccordionToggle,
  AccordionContext,
  Accordion,
} from "react-bootstrap";
import MultiSelectComponent, {
    IMultiSelectItems,
  } from "../../../components/formMultiSelectComponent";
import { toast } from "react-toastify";
import _ from "lodash";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import FormTextAreaComponent from "../../../components/formTextAreaComponent";
import SelectInputComponent, {
  ISelectItems,
} from "../../../components/formSelectComponent";
import DetailItem from "../../../components/detailItem";
import { ActionTypes } from "../../../enums/actionTypes";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import Skeleton from "react-loading-skeleton";
import offerLetterService from "../../../services/hybridAppraisal/offerLetterService";
import FormInputComponent from "../../../components/formInputComponent";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../../services/auth/authService";
import { ContextAwareToggle } from "../appraisalPlan/appraisalPlanDetail";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

interface EditOfferLetterFields {
    institutionCategory: string;
  institutionName: string;
    Date: string;
    BranchDetails: any;
    CustomerDetails: any;
    LoanDetails: any;
    AddClause: string;
    Body: string;
  logoPosition: string;
  signatory: string;
  signatoryDesignation: string;
}

interface IDetails {
  label: string;
  value: string;
}

class EditOfferLetterForm extends BaseFormComponent<EditOfferLetterFields> { }

interface IEditOfferLetterProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}
interface IEditOfferLetterState {
    ownUpdate: boolean;
    institutionCategory: any;
    BranchDetailsInputData: any;
    CustomerDetailsInputData: any;
    LoanDetailsInputData: any;
    selectedBranchDetails: IDetails[];
    selectedCustomerDetails: IDetails[];
    selectedLoanDetails: IDetails[];
}

class EditOfferLetter extends React.Component<
  IEditOfferLetterProps,
  IBaseFormState & IEditOfferLetterState
> {
  _isMounted = false;
  constructor(props: IEditOfferLetterProps) {
    super(props);
    this.state = {
        validated: false,
        submitting: false,
        errors: {},
        institutionCategory: "",
        BranchDetailsInputData: [],
        CustomerDetailsInputData: [],
        LoanDetailsInputData: [],
        selectedBranchDetails: [],
        selectedCustomerDetails: [],
        selectedLoanDetails: [],
        ownUpdate: false
    };
  }

  static getDerivedStateFromProps(nextProps: IEditOfferLetterProps, prevState) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isEmpty(nextProps.item)) {
      return null;
    }
    return {
      selectedBranchDetails: nextProps.item.branchDetails,
      selectedCustomerDetails: nextProps.item.customerDetails,
      selectedLoanDetails: nextProps.item.loanDetails
    };
  };


  async componentDidMount() {
    this._isMounted = true;
    const { errors } = this.state;
    try {
      if (this._isMounted) {
        this.setState({ownUpdate: true });
      }
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      let logoPostionInputData: ISelectItems [] = [
        {name: "Left", value: "Left"},
        {name: "Right", value: "Right"},
        {name: "Centre", value: "Centre"}
      ]
  
      let BranchDetailsInput: IMultiSelectItems[] = [
          { label: "Branch name", value: "Branch name" },
          { label: "Branch Address", value: "Branch Address" },
          { label: "Account officer", value: "Account officer" },
        ];
        let CustomerDetailsInput: IMultiSelectItems[] = [
          { label: "CustomerName", value: "CustomerName" },
        { label: "Address", value: "Address" },
        { label: "IdentificationType", value: "IdentificationType" },
        { label: "IdentificationNumber", value: "IdentificationNumber" },
        { label: "IdentificationExpiryDate", value: "IdentificationExpiryDate" },
        { label: "BVN", value: "BVN" },
        { label: "State", value: "State" },
        { label: "Country", value: "Country" },
        { label: "Phonenumber", value: "Phonenumber" },
        { label: "CustomerEmail", value:"CustomerEmail"},
        { label: "EmployerName", value:"EmployerName"},
        { label: "JobAddress", value: "JobAddress" },
        { label: "JobState", value: "JobState"}
        ];
        let LoanDetailsInput: IMultiSelectItems[] = [
          { label: "Nuban", value: "Nuban" },
          { label: "ProductCode", value: "ProductCode" },
          { label: "LoanAmount ", value: "LoanAmount" },
          { label: "Loan fee", value:"Loan fee"},
          { label: "Management fee", value: "Management fee" },
          { label: "Insurance fee", value:"Insurance fee"},
          { label: "Interest percentage", value: "Interest percentage" },
          { label: "Interest Amount", value: "Interest Amount" },
          { label: "Interest per annum (%)", value: "Interest per annum (%)" },
          { label: "Default charges", value: "Default charges" },
          { label: "Penalty charges", value: "Penalty charges" },
          { label: "ObligorLimit", value: "ObligorLimit" },
          {label:"TenureInDays", value:"TenureInDays"}
        ];

      this.setState({
          BranchDetailsInputData: BranchDetailsInput,
          CustomerDetailsInputData: CustomerDetailsInput,
          LoanDetailsInputData: LoanDetailsInput,
          ownUpdate: true
      })
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        errors.response = "Internal server error.";
        this.setState({ errors, ownUpdate: true });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onFormSubmit(fields: EditOfferLetterFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          ownUpdate: true
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true , ownUpdate: true});
      return false;
    } catch (error) {
      return false;
    }
  }

  submit = async (
    fields: EditOfferLetterFields,
    onReloadFieldsCB: any
  ): Promise<boolean> => {
    try {
      this.setState({ submitting: true, ownUpdate: true });
    //   this.setState({selectedBranchDetails: selectedBranchDetails})
      const payload = { ...this.props.item };

      const { selectedBranchDetails,selectedCustomerDetails,selectedLoanDetails } = this.state;

      var selectedBranchDetail = JSON.stringify(selectedBranchDetails).replaceAll('"',"'");
      var selectedCustomerDetail = JSON.stringify(selectedCustomerDetails).replaceAll('"',"'");
      var selectedLoanDetail = JSON.stringify(selectedLoanDetails).replaceAll('"',"'");

      payload.branchDetails = selectedBranchDetail;
      payload.customerDetails = selectedCustomerDetail;
      payload.loanDetails = selectedLoanDetail;
      payload.clause = fields.AddClause;
      payload.body = fields.Body;
      payload.signatory = fields.signatory;
      payload.signatoryDesignation = fields.signatoryDesignation;
      
      const response = await offerLetterService.editOfferLetter(payload);
      if (response.status >= 200 && response.status <= 300) {
      await activityTrailService.saveActivityTrail({
        actionType: "Edit Offer Letter",
        description: `Edited Offer Letter details for Institution : ${fields.institutionName}`,
      });
      toast.info(`Offer Letter for Institution: ${fields.institutionName} Edited successfully! `, {
        autoClose: 6000,
        type: toast.TYPE.DEFAULT,
        hideProgressBar: false,
      });
    }
      onReloadFieldsCB();
      this.setState({ submitting: false }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      toast.error(error.response.data, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      this.setState({ submitting: false ,ownUpdate: true});
    } finally {
      return true;
    }
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false
    });
  }

  handleOnChangeOfferLetterBranchDetails = (_item: any) => {
    var item2: IDetails = { label: _item.label, value: _item.value };
    const BranchDetail = [...this.state.selectedBranchDetails];
    let selectedBranchDetails = [...this.state.selectedBranchDetails]
    if (BranchDetail.findIndex(object => { return object.label === item2.label }) !== -1) {
      BranchDetail.splice(BranchDetail.findIndex(object => { return object.label === item2.label }), 1);
    } else {
      BranchDetail.push(item2);
    }
    this.setState({selectedBranchDetails: BranchDetail, ownUpdate: true });
  }

  handleOnChangeOfferLetterCustomerDetails = (_item: IDetails) => {
    var item2: IDetails = { label: _item.label, value: _item.value };
    const CustomerDetail = [...this.state.selectedCustomerDetails];
    if (CustomerDetail.findIndex(object => { return object.label === item2.label }) !== -1) {
      CustomerDetail.splice(CustomerDetail.findIndex(object => { return object.label === item2.label }), 1);
    } else {
      CustomerDetail.push(item2);
    }
    this.setState({ selectedCustomerDetails: CustomerDetail, ownUpdate: true });
  }

  handleOnChangeOfferLetterLoanDetails = (_item: IDetails) => {
    var item2: IDetails = { label: _item.label, value: _item.value };
    const LoanDetail = [...this.state.selectedLoanDetails];
    
    if (LoanDetail.findIndex(object => { return object.label === item2.label }) !== -1) {
      LoanDetail.splice(LoanDetail.findIndex(object => { return object.label === item2.label }), 1);
    } else {
      LoanDetail.push(item2);
    }
    this.setState({ selectedLoanDetails: LoanDetail, ownUpdate: true });
  }


  render() {
    const { item, showEditModal, toggleEditModal } = this.props;
    const {
      validated,
      submitting,
      errors,
      BranchDetailsInputData,
      CustomerDetailsInputData,
      LoanDetailsInputData,
      selectedBranchDetails,
      selectedCustomerDetails,
      selectedLoanDetails

    } = this.state;


    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-user-shield fa-sm cyan-text mr-3" />
              Edit Offer Letter -{" "}
              <span className="font-weight-bold">{item.institutionName}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditOfferLetterForm
          initialValues={{
            institutionCategory: item.institutionCategory,
            institutionName: item.institutionName,
            Date: item.Date,
            BranchDetails: item.branchDetails,
            CustomerDetails: item.customerDetails,
            LoanDetails: item.loanDetails,
            AddClause: item.clause,
            Body: item.body,
            logoPosition: item.logoPosition,
            signatory: item.signatory,
            signatoryDesignation: item.signatoryDesignation

          }}
          FormComponent={({
            fields: {
                institutionCategory,
                institutionName,
                Date,
                BranchDetails,
                CustomerDetails,
                LoanDetails,
                AddClause,
                Body,
                logoPosition,
                signatory,
                signatoryDesignation

            },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    <h6
                      className="card-title float-left"
                      style={{ marginBottom: "0px" }}
                    >
                        Offer Letter Details
                    </h6>
                  </div>

                  {/* General Information */}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={3} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Institution Category"
                              value={institutionCategory}
                            />
                            <DetailItem
                              label="Institution"
                              value={institutionName}
                            />
                             {/* <FormInputComponent
                                  id="logoPosition"
                                  type="text"
                                  name={"logoPosition"}
                                  placeholder=""
                                  divClass={6}
                                  value={logoPosition}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                  disabled={false}
                                /> */}
                             <FormTextAreaComponent
                                  id="Body"
                                  type="text"
                                  name={"Body"}
                                  placeholder=""
                                  divClass={6}
                                  value={Body}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                  disabled={false}
                                />
                              <FormTextAreaComponent
                                  id="AddClause"
                                  type="text"
                                  name={"AddClause"}
                                  placeholder=""
                                  divClass={6}
                                  value={AddClause}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                  disabled={false}
                                />
                                <FormTextAreaComponent
                                  id="signatory"
                                  type="text"
                                  name={"signatory"}
                                  placeholder=""
                                  divClass={6}
                                  value={signatory}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                  disabled={false}
                                />
                                <FormTextAreaComponent
                                  id="signatoryDesignation"
                                  type="text"
                                  name={"signatoryDesignation"}
                                  placeholder=""
                                  divClass={6}
                                  value={signatoryDesignation}
                                  required={false}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                  disabled={false}
                                />
                          </dl>
                        )}
                      </div>
                    </div>
                  </div>

                {/* BranchDetails */}
                  <Accordion defaultActiveKey="0">
                    <div>
                      <Card>
                        <ContextAwareToggle
                          eventKey={`0`}
                          header={
                            <React.Fragment>
                              <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                                style={{ color: "#878a8a" }}
                              ></i>{" "}
                              Branch Details
                            </React.Fragment>
                          }
                        ></ContextAwareToggle>
                        <Accordion.Collapse eventKey={`0`}>
                          <Card.Body>
                            <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                              <div className="row">
                                <div className="col-md-12">
                                    <Fragment>
                                      {BranchDetailsInputData.map((item) => (
                                        <div key={item.value} style={{ marginTop: '3px' }}>
                                          <div
                                            key={item.value}
                                            className="custom-control custom-checkbox"
                                          >
                                            <input
                                              id={item.value}
                                              name={item.value}
                                              value={item.value}
                                              className="custom-control-input"
                                              type="checkbox"
                                              checked={
                                                _.isEmpty(
                                                    this.state.selectedBranchDetails.filter(
                                                      (x) => x.value === item.value
                                                    ))
                                                    ? false
                                                    : true
                                                }
                                              onChange={() =>
                                                this.handleOnChangeOfferLetterBranchDetails(
                                                  item
                                                )
                                              }
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor={item.value}
                                            >
                                              {item.value}
                                            </label>
                                          </div>
                                        </div> 
                                      )
                                      )}
                                    </Fragment>
                                  
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </div>
                  </Accordion>
                {/* CustomerDetails */}
                  <Accordion defaultActiveKey="0">
                    <div>
                      <Card>
                        <ContextAwareToggle
                          eventKey={`0`}
                          header={
                            <React.Fragment>
                              <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                                style={{ color: "#878a8a" }}
                              ></i>{" "}
                              Customer Details
                            </React.Fragment>
                          }
                        ></ContextAwareToggle>
                        <Accordion.Collapse eventKey={`0`}>
                          <Card.Body>
                            <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                              <div className="row">
                                <div className="col-md-12">
                                 
                                    <Fragment>
                                      {CustomerDetailsInputData.map((item) => (
                                        <div key={item.value} style={{ marginTop: '3px' }}>
                                          <div
                                            key={item.value}
                                            className="custom-control custom-checkbox"
                                          >
                                            <input
                                              id={item.value}
                                              name={item.value}
                                              value={item.value}
                                              className="custom-control-input"
                                              type="checkbox"
                                              checked={
                                                _.isEmpty(
                                                    this.state.selectedCustomerDetails.filter(
                                                    (x) => x.value === item.value
                                                  ))
                                                  ? false
                                                  : true
                                              }
                                              onChange={() =>
                                                this.handleOnChangeOfferLetterCustomerDetails(
                                                  item
                                                )
                                              }
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor={item.value}
                                            >
                                              {item.value}
                                            </label>
                                          </div>
                                        </div>
                                      )
                                      )} 
                                    </Fragment>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </div>
                  </Accordion>
                {/* LoanDetails */}
                  <Accordion defaultActiveKey="0">
                    <div>
                      <Card>
                        <ContextAwareToggle
                          eventKey={`0`}
                          header={
                            <React.Fragment>
                              <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                                style={{ color: "#878a8a" }}
                              ></i>{" "}
                              Loan Details
                            </React.Fragment>
                          }
                        ></ContextAwareToggle>
                        <Accordion.Collapse eventKey={`0`}>
                          <Card.Body>
                            <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                              <div className="row">
                                <div className="col-md-12">
                                    <Fragment>
                                      {LoanDetailsInputData.map((item) => (
                                        <div key={item.value} style={{ marginTop: '3px' }}>
                                          <div
                                            key={item.value}
                                            className="custom-control custom-checkbox"
                                          >
                                            <input
                                              id={item.value}
                                              name={item.value}
                                              value={item.value}
                                              className="custom-control-input"
                                              type="checkbox"
                                              checked={
                                                _.isEmpty(
                                                    this.state.selectedLoanDetails.filter(
                                                      (x) => x.value === item.value
                                                    ))
                                                    ? false
                                                    : true
                                                }
                                              onChange={() =>
                                                this.handleOnChangeOfferLetterLoanDetails(
                                                  item
                                                )
                                              }
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor={item.value}
                                            >
                                              {item.value}
                                            </label>
                                          </div>
                                        </div>
                                      )
                                      )}
                                    </Fragment>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </div>
                  </Accordion>

                 
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(e, ActionTypes.UPDATE, "Offer Letter", () => {
                      this.onFormSubmit({
                        institutionCategory,
                        Date,
                        institutionName,
                        BranchDetails,
                        CustomerDetails,
                        LoanDetails,
                        AddClause,
                        Body,
                        logoPosition,
                        signatory,
                        signatoryDesignation
                      },
                        onReloadFields);
                    });
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></EditOfferLetterForm>
      </Modal>
    );
  }
}

export default EditOfferLetter;
