import * as React from "react";
import _ from "lodash";
import { Fragment } from "react";
import {
    IFilterData,
    BaseListComponentState,
} from "../../components/BaseListComponent";
import {
    createFilterData,
    mapEntityList,
} from "../../services/utility/autoMapperService";
import BaseListComponent from "../../components/BaseListComponent";
import ItemsTable, {
    filterFieldData,
} from "../../components/tableComponents/itemsTable";
import TableFilterPane from "../../components/tableComponents/tableFilterPane";
import reportService from "../../services/report/reportService";
import settlementService from "../../services/sweep/settlementService";
import FailedNIPReportDetail from "./faileNIPReportDetail";
import institutionsService from "../../services/auth/institutionsService";

export interface FailedNIPReportProps { }

export interface FailedNIPReportState
    extends BaseListComponentState {
    data: IFilterData;
    currentPage: number;
    pageSize: number;
    tableItems: any;
    totalSize: number;
    fetchingDetail: boolean;
    filterValueInput: filterFieldData;
    item: any;
    institution: any;
}

class FailedNIPReportList extends BaseListComponent<FailedNIPReportState> { }

class FailedNIPReport extends React.Component<
    FailedNIPReportProps,
    FailedNIPReportState
> {
    constructor(props: FailedNIPReportProps) {
        super(props);
        this.state = {
            data: createFilterData([
                "settlementEntryId",
                "dateCreated",
                "manualSettlementStatus",
                "batch"
            ]) as IFilterData,
            currentPage: 1,
            pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
            fetching: false,
            fetchingDetail: false,
            tableItems: [],
            totalSize: 0,
            filterValueInput: [
                { name: "settlementEntryId", fieldType: "text", value: "" },
                { name: "dateCreated", fieldType: "date", value: "" },
                {
                    name: "manualSettlementStatus",
                    fieldType: "select",
                    options: ["Pending", "Treated"],
                    value: ""
                },
                { name: "batch", fieldType: "text", value: "" },
            ],
            item: {},
            showDetailsModal: false,
            showEditModal: false,
            showTableFilterModal: false,
            showTrailModal: false,
            institution: []
        };
    }

    handlePageChange = async (currentPage: number, pageSize: number, data?: IFilterData) => {
        currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
        pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
        let dataClone: IFilterData = typeof data === "undefined" ? { ...this.state.data } : { ...data };
        // dataClone["settlementEntryType"] = { item1: "=", item2: "NIP2" }
        // var dataFilter = this.getFilteredItems(data)
        this.setState({ fetching: true });
        try {
            const reportItems = await settlementService.filterManualNIPSettlement(
                dataClone,
                pageSize,
                currentPage,
            );
            this.setState({
                tableItems: mapEntityList({
                    entityList: reportItems.data.item2,
                    properties: [
                        { oldName: "settlementEntryId", newName: "settlementEntryId" },
                        { oldName: "batch", newName: "batchId" },
                        { oldName: "transactionId", newName: "transactionId" },
                        { oldName: "amount", newName: "settlementAmount" },
                        { oldName: "debitAccount", newName: "debitAccount" },
                        { oldName: "creditAccount", newName: "creditAccount" },
                        { oldName: "dateCreated", newName: "dateCreated" },
                        { oldName: "dateUpdated", newName: "dateUpdated" },
                        { oldName: "manualSettlementStatus", newName: "manualSettlementStatus" }
                    ],
                }),
                currentPage,
                pageSize,
                totalSize: reportItems.data.item1,
                fetching: false,
            });
        } catch (ex) {
            if (
                ex.response &&
                ex.response.status >= 400 &&
                ex.response.status <= 499
            ) {
            } else {
            }
        } finally {
            this.setState({ fetching: false });
        }
    };

    handleOnExport = async (type: string) => { };

    fetchItemById = async (item: any) => {
        this.setState({ fetchingDetail: true });
        const report = await settlementService.RetrieveManualNIPSettlementById(item.id);
        const reportDetail = report.data;

        // const institution = await institutionsService.retrieveAllInstitutions();
        // const institutionDetails = institution.data
        // console.log(institutionDetails)


        this.setState({ item: reportDetail, fetchingDetail: false });
    };

    getFilteredItems = (data: IFilterData): IFilterData => {
        let filteredItems: IFilterData = {};
        Object.keys(data).forEach((key) => {
            if (data[key].item2 !== "") {
                filteredItems[key] = data[key];
            }
        });
        return filteredItems;
    };

    // getFilteredItems = (data: IFilterData): IFilterData => {
    //     let filteredItems: IFilterData = {};
    //     Object.keys(data).forEach((key) => {
    //         if (data[key].item2 !== "") {
    //             filteredItems[key] = data[key];
    //         }
    //     });
    //     return filteredItems;
    // };

    // getFilteredItemsText = (data: IFilterData): IFilterData => {
    //     let filteredItems: IFilterData = {};
    //     Object.keys(data).forEach((key) => {
    //         if (data[key].item2 !== "") {
    //             filteredItems[key] = data[key];
    //         }
    //     });
    //     return filteredItems;
    // };

    async componentDidMount() {
        this.handlePageChange(this.state.currentPage, this.state.pageSize);
        const institution = await institutionsService.retrieveAllInstitutions();
        const institutionDetails = institution.data
        this.setState({ institution: institutionDetails });
    }

    render() {
        const {
            currentPage,
            pageSize,
            tableItems,
            totalSize,
            fetching,
            item,
            fetchingDetail,
        } = this.state;
        return (
            <Fragment>
                <div className="container-fluid relative animatedParent animateOnce">
                    <div className="animated fadeInUpShort go">
                        <div className="row my-3 mx-2">
                            <h3>
                                <b>Failed Manual NIP Settlement Report</b>
                            </h3>
                        </div>
                        <FailedNIPReportList
                            pageChangeHandler={this.handlePageChange}
                            fetchingDetail={fetchingDetail}
                            initialValues={{
                                data: this.state.data,
                                fetching: false,
                                showDetailsModal: this.state.showDetailsModal,
                                showEditModal: false,
                                showTableFilterModal: false,
                                showTrailModal: false,
                                tableItems: [],
                                filterValueInput: this.state.filterValueInput,
                                item: {},
                            }}
                            tableItems={tableItems}
                            item={item}
                            DetailsModal={({ item, onToggleDetailsModal, showDetailsModal }) => (
                                <Fragment>
                                    <FailedNIPReportDetail
                                        showDetailsModal={showDetailsModal}
                                        toggleDetailsModal={onToggleDetailsModal}
                                        item={item}
                                        institution={this.state.institution}
                                    />
                                </Fragment>
                            )}
                            ListPayLoad={({
                                data,
                                showTableFilterModal,
                                tableItems,
                                onToggleDetailsModal,
                                onToggleTableFilterModal,
                                onHandleFilterItemRemove,
                                filterValueInput,
                            }) => (
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card no-b">
                                            <div className="card-body">
                                                <div className="card-title">
                                                    <div id="list-filters" className="ml-4 mr-4">
                                                        <TableFilterPane
                                                            filteredItems={this.getFilteredItems(this.state.data)}
                                                            handleFilterRemove={onHandleFilterItemRemove}
                                                        />
                                                    </div>
                                                    <div id="list-table">
                                                        <ItemsTable
                                                            data={data}
                                                            items={tableItems}
                                                            filterValueInput={filterValueInput}
                                                            showTableFiltermodal={showTableFilterModal}
                                                            toggleTableFiltermodal={onToggleTableFilterModal}
                                                            name="FailedNIPReport"
                                                            currentPage={currentPage}
                                                            pageSize={pageSize}
                                                            totalSize={totalSize}
                                                            fetching={fetching}
                                                            handlePageChange={this.handlePageChange}
                                                            onViewDetails={(item: any) => {
                                                                this.fetchItemById(item);
                                                                onToggleDetailsModal();
                                                            }}
                                                            handleOnExport={this.handleOnExport}
                                                            amountColumn={["settlementAmount"]}
                                                            amountInNaira={false}
                                                            timeColumn={["dateCreated", "dateUpdated"]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        ></FailedNIPReportList>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default FailedNIPReport;
