import React, { Fragment } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import BaseFormComponent, {
    IBaseFormState,
} from "../../../components/BaseFormComponent";
import SelectInputComponent, {
    ISelectItems,
} from "../../../components/formSelectComponent";
import { getCurrentUserSession } from "../../../services/auth/authService";
import { ActionTypes } from "../../../enums/actionTypes";
import { decodeToken } from "react-jwt";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import loanProductService from "../../../services/hybridAppraisal/loanProductService";
import financialGroupService from "../../../services/hybridAppraisal/financialGroupService";
import loanCategoryService from "../../../services/hybridAppraisal/loanCategoryService";
import appraisalValidationService from "../../../services/hybridAppraisal/appraisalValidationService";
import FormInputComponent from "../../../components/formInputComponent";
import textFormatService from "../../../services/utility/textFormatService";
import MultiSelectComponent, { IMultiSelectItems } from "../../../components/formMultiSelectComponent";
import { Button, Modal } from "react-bootstrap";
import hybridAppraisalValidationService from "../../../services/hybridAppraisal/hybridAppraisalValidationService";

interface EditLoanCategoryFields {
    Name: string;
    loanProductCode: string;
    loanLimit: string;
    loanTenor: string;
    interestRate: string;
    knockOff: any;
}

class EditLoanCategoryForm extends BaseFormComponent<EditLoanCategoryFields> { }

interface IEditLoanCategoryProps {
    item: any;
    showEditModal: boolean;
    toggleEditModal: any;
}
interface IKnockOffRuleParamData {
    rule: string;
    type: 'DropDown' | 'Value';
    options: any
    value: string;
}
interface IEditLoanCategoryState {
    fetchingRequiredItems: boolean;
    prices: any;
    knockOffRules: any;
    classificationRules: any;
    loanProducts: any;
    selectedLoanClassification: any;
    ownUpdate: boolean;
}

class EditLoanCategory extends React.Component<
    IEditLoanCategoryProps,
    IBaseFormState & IEditLoanCategoryState
> {
    _isMounted = false;
    constructor(props: IEditLoanCategoryProps) {
        super(props);
        this.state = {
            validated: false,
            submitting: false,
            errors: {},
            prices: [],
            fetchingRequiredItems: false,
            knockOffRules: [],
            classificationRules: [],
            loanProducts: [],
            selectedLoanClassification: [],
            ownUpdate: false
        };
    }

    async componentDidMount() {
        this._isMounted = true;
        const { errors } = this.state;

        try {
            const decodedToken = decodeToken(getCurrentUserSession().token || "");
            if (this._isMounted) {
                this.setState({ fetchingRequiredItems: true })
            }
            const response = await Promise.allSettled([
                loanProductService.retrieveByInstitutionCode(decodedToken?.["InstitutionCode"]),
                financialGroupService.retrieveByInstitutionCode(decodedToken?.["InstitutionCode"]),
            ])
            const loanProducts = response[0].status == "fulfilled" ? response[0].value.data : [];
            const financialGroups = response[1].status == "fulfilled" ? response[1].value.data : [];


            this.setState({ loanProducts: loanProducts, selectedLoanClassification: this.props.item.classificationRules })
        } catch (ex) {
            if (ex.response && ex.response.status === 404) {
                errors.response = "Internal server error.";
                this.setState({ errors });
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    static getDerivedStateFromProps(
        nextProps: IEditLoanCategoryProps,
        prevState: IEditLoanCategoryState
    ) {
        if (prevState.ownUpdate) {
            return {
                ownUpdate: false,
            };
        }
        if (_.isEmpty(nextProps.item)) {
            return null;
        }
        return {
            // selectedLoanClassification: nextProps.item.classificationRules,
            knockOffRules: nextProps.item.knockOffRules
        };
    }

    onFormSubmit(fields: EditLoanCategoryFields, onReloadFieldsCB: any): boolean {
        try {
            const decodedToken = decodeToken(getCurrentUserSession().token || "");
            this.setState(
                { errors: hybridAppraisalValidationService.validateLoanCategoryForm(fields), ownUpdate: true },
                () => {
                    if (Object.keys(this.state.errors).length === 0) {
                        return this.submit(onReloadFieldsCB, fields);
                    }
                }
            );
            this.setState({ validated: true });
            return false;
        } catch (error) {
            return false;
        }
    }

    async submit(
        onReloadFieldsCB: any,
        fields: EditLoanCategoryFields
    ): Promise<boolean> {
        try {
            this.setState({ submitting: true, ownUpdate: true });
            const decodedToken = decodeToken(getCurrentUserSession().token || "");
            const payload: any = { ...this.props.item };

            // console.log("Payload: ", payload)
            const { classificationRules, knockOffRules
            } = this.state
            // console.log(knockOffRules)

            payload.Name = fields.Name
            payload.institutionCode = decodedToken?.["InstitutionCode"]
            payload.loanProductCode = fields.loanProductCode
            payload.loanProductName = this.state.loanProducts?.filter(x => x.productCode === fields.loanProductCode)[0]?.name
            payload.interestRate = Number(fields.interestRate)
            payload.loanLimit = Number(fields.loanLimit)
            payload.loanTenor = Number(fields.loanTenor)
            payload.knockOffRules = knockOffRules
            payload.classificationRules = classificationRules

            // console.log(payload);

            const response = await loanCategoryService.updateLoanCategory(payload);
            if (response.status >= 200 && response.status <= 300) {
                await activityTrailService.saveActivityTrail({
                    actionType: "Edit Loan Category",
                    description: `Edited new Loan Category`,
                });
                toast.info(`Loan Category Edited successfully!`, {
                    autoClose: 6000,
                    type: toast.TYPE.DEFAULT,
                    hideProgressBar: false,
                });
            } else {
                return false;
            }
            // onReloadFieldsCB();
            this.setState({ submitting: false, ownUpdate: true });
            this.resetFields();
            // onReloadFieldsCB();
        } catch (error) {
            toast.error(error.response.data, {
                autoClose: 6000,
                type: toast.TYPE.ERROR,
                hideProgressBar: false,
            });
            this.setState({ submitting: false });
            return false;
        } finally {
            return true;
        }
    }

    handleOnKnockOffRulesChange = (name, value, type) => {
        const knockOffRules = [...this.state.knockOffRules];
        console.log(knockOffRules)
        // console.log("val :: ", value);
        // console.log("data :: ", data);

        if (type === "DropDowns") {
            knockOffRules.filter(rule => rule.rule === name)[0].value = value
            this.setState({ knockOffRules, ownUpdate: true })
        }
        else {

            knockOffRules.every(option => {
                if (option.rule == name) {

                    option.value = value;
                    // this.setState({ submitting: false, ownUpdate: true });
                    return false;
                }
                // this.setState({flatPerApprovedLoan:flatPerApprovedLoan_, ownUpdate: true})
                // Make sure you return true. If you don't return a value, `every()` will stop.
                return true;
            });
            // console.log("getting object",validationOption.find(option => option.validationPricingOptionId == id).fee);
            //  console.log(signatoryOption);

            return knockOffRules;
        }
    }

    handleOnClassificationRulesChange = (selected) => {

        const selectedLoanClassification = [...this.state.selectedLoanClassification];
        if (selectedLoanClassification.indexOf(selected) !== -1) {
            selectedLoanClassification.splice(selectedLoanClassification.indexOf(selected), 1);
        } else {
            selectedLoanClassification.push(selected);
        }
        this.setState({ selectedLoanClassification, classificationRules: selectedLoanClassification, ownUpdate: true });
    };

    resetFields() {
        this.setState({
            validated: false,
            submitting: false,
        });
    }

    render() {
        const {
            errors,
            validated,
            fetchingRequiredItems,
            prices,
            loanProducts,
            selectedLoanClassification,
            knockOffRules
        } = this.state;
        const { item, showEditModal, toggleEditModal } = this.props

        const loanProductInputData: ISelectItems[] = loanProducts.map(item => {
            return { name: item.name, value: item.productCode }
        });

        const classificationRulesInputData: IMultiSelectItems[] = [
            "Loan outstanding balance is zero(0)",
            "Date of last payment for last loan disbursed was on or before Loan expiry date"
        ].map(item => {
            return { label: item, value: item }
        });


        const loanPerformanceStatusInputData: ISelectItems[] = [
            "Performing",
            "NonPerforming"
        ].map(item => {
            return { name: item, value: item }
        });

        return (
            <Modal
                size="xl"
                backdrop="static"
                show={showEditModal}
                onHide={toggleEditModal}
            >
                <Modal.Header>
                    <Modal.Title>
                        <h5>
                            <i className="fas fa-user-shield fa-sm cyan-text mr-3" />
                            Edit Loan Category -{" "}
                            <span className="font-weight-bold">{item.institutionName}</span>
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <React.Fragment>
                    <div className="container-fluid relative animatedParent animateOnce">
                        <div className="animated fadeInUpShort go">
                            <div className="row my-3 mx-2">
                                <h3>
                                    <b>Edit Loan Category</b>
                                </h3>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <EditLoanCategoryForm
                                        initialValues={{
                                            Name: item.name,
                                            loanProductCode: item.loanProductCode,
                                            knockOff: this.state.knockOffRules,
                                            loanLimit: item.loanLimit,
                                            loanTenor: item.loanTenor,
                                            interestRate: item.interestRate
                                        }}
                                        FormComponent={({
                                            fields: {
                                                Name,
                                                loanProductCode,
                                                knockOff,
                                                loanLimit,
                                                loanTenor,
                                                interestRate
                                            },
                                            onChange,
                                            onReloadFields,
                                            onHandleSubmit,
                                        }) => (
                                            <><Modal.Body>
                                                <div className="card no-b">
                                                    <div className="card-body">
                                                        <h5 className="card-title">

                                                            Edit Loan Category
                                                        </h5>

                                                        <Fragment>
                                                            <div>
                                                                <Fragment>
                                                                    <div className="form-row">
                                                                        <div className="col-md-12">
                                                                            <div className="card">
                                                                                <h5 className="card-title">


                                                                                </h5>
                                                                                <div className="form-row">
                                                                                    <FormInputComponent
                                                                                        id="Name"
                                                                                        name="Name"
                                                                                        type="text"
                                                                                        divClass={6}
                                                                                        placeholder=""
                                                                                        value={Name}
                                                                                        required={true}
                                                                                        validated={validated}
                                                                                        errors={errors}
                                                                                        onChange={onChange}
                                                                                    />
                                                                                    <SelectInputComponent
                                                                                        label="Loan Product"
                                                                                        id="loanProductCode"
                                                                                        name="loanProductCode"
                                                                                        divClass={6}
                                                                                        value={loanProductCode}
                                                                                        items={loanProductInputData}
                                                                                        required={true}
                                                                                        validated={validated}
                                                                                        errors={errors}
                                                                                        onChange={onChange}
                                                                                    />
                                                                                    <FormInputComponent
                                                                                        id="loanLimit"
                                                                                        name="loanLimit"
                                                                                        type="text"
                                                                                        divClass={6}
                                                                                        placeholder=""
                                                                                        value={loanLimit}
                                                                                        required={true}
                                                                                        validated={validated}
                                                                                        errors={errors}
                                                                                        onChange={onChange}
                                                                                    />
                                                                                    <FormInputComponent
                                                                                        id="loanTenor"
                                                                                        name="loanTenor"
                                                                                        type="text"
                                                                                        divClass={6}
                                                                                        placeholder=""
                                                                                        value={loanTenor}
                                                                                        required={true}
                                                                                        validated={validated}
                                                                                        errors={errors}
                                                                                        onChange={onChange}
                                                                                    />
                                                                                    <FormInputComponent
                                                                                        id="interestRate"
                                                                                        name="interestRate"
                                                                                        type="text"
                                                                                        divClass={6}
                                                                                        placeholder=""
                                                                                        value={interestRate}
                                                                                        required={true}
                                                                                        validated={validated}
                                                                                        errors={errors}
                                                                                        onChange={onChange}
                                                                                    />


                                                                                </div>


                                                                            </div>

                                                                            <div className="card mt-3">
                                                                                <h5 className="card-title">

                                                                                    Edit KnockOff Rules
                                                                                </h5>
                                                                                {knockOffRules?.length === 0 ? ("") : knockOffRules?.map(rule => (
                                                                                    rule.type === "Dropdown" ?
                                                                                        (
                                                                                            <SelectInputComponent
                                                                                                // label={rule.rule}
                                                                                                key={rule.rule}
                                                                                                id={rule.rule}
                                                                                                name={rule.rule}
                                                                                                divClass={6}
                                                                                                value={rule.value}
                                                                                                items={rule?.options?.map(x => { return { name: x, value: x } })}
                                                                                                required={false}
                                                                                                validated={validated}
                                                                                                errors={errors}
                                                                                                onChange={(id, value) => this.handleOnKnockOffRulesChange(id, value, "DropDowns")}
                                                                                            />
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            <FormInputComponent
                                                                                                key={rule.rule}
                                                                                                id={rule.rule}
                                                                                                name={rule.rule}
                                                                                                type="text"
                                                                                                divClass={6}
                                                                                                placeholder=""
                                                                                                value={rule.value ? rule.value : ""}
                                                                                                required={false}
                                                                                                validated={validated}
                                                                                                errors={errors}
                                                                                                onChange={(id, value) => onChange('knockOff', this.handleOnKnockOffRulesChange(id, value, "value"))}
                                                                                            // onBlur={(e) => { this.handleOnKnockOffRulesChange(rule.name, e.target.value) }}
                                                                                            />

                                                                                        )


                                                                                ))}

                                                                            </div>
                                                                            <div className="card mt-3">
                                                                                <h5 className="card-title">
                                                                                    Edit Category Rules
                                                                                </h5>

                                                                                {classificationRulesInputData?.map(
                                                                                    (tab) => (
                                                                                        <div
                                                                                            key={tab.value}
                                                                                            style={{ border: '2px #84d4fc solid', borderRadius: '4px', marginTop: '5px', inlineSize: "100%" }}
                                                                                        >
                                                                                            <div
                                                                                                className="custom-control custom-checkbox"
                                                                                                style={{ display: 'inline-block' }}
                                                                                                key={tab.value}
                                                                                            >
                                                                                                <input
                                                                                                    id={tab.value}
                                                                                                    name={tab.value}
                                                                                                    className="custom-control-input"
                                                                                                    type="checkbox"
                                                                                                    onChange={() => this.handleOnClassificationRulesChange(tab.value)}
                                                                                                    checked={selectedLoanClassification.includes(tab.value)}
                                                                                                />
                                                                                                <label
                                                                                                    className="custom-control-label pt-1"
                                                                                                    htmlFor={tab.value}
                                                                                                >
                                                                                                    {tab.label}
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                )}

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    {/* </div> */}
                                                                </Fragment>
                                                            </div>
                                                        </Fragment>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                                <Modal.Footer>
                                                    <Button
                                                        size="sm"
                                                        variant="secondary"
                                                        onClick={(e) => {
                                                            onHandleSubmit(
                                                                e,
                                                                ActionTypes.SAVE,
                                                                "Edit Loan Category",
                                                                () => {
                                                                    this.onFormSubmit(
                                                                        {
                                                                            Name,
                                                                            loanProductCode,
                                                                            knockOff,
                                                                            interestRate,
                                                                            loanLimit,
                                                                            loanTenor
                                                                        },
                                                                        onReloadFields
                                                                    );
                                                                }
                                                            );
                                                        }}
                                                        disabled={this.state.submitting}
                                                    >
                                                        {this.state.submitting === false ? (
                                                            <React.Fragment>
                                                                <i className="fas fa-lg fa-save mr-3" /> Save
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
                                                                <i className="fas fa-spin fa-circle-notch" />
                                                            </React.Fragment>
                                                        )}
                                                    </Button>
                                                    <Button
                                                        size="sm"
                                                        variant="outline-danger"
                                                        onClick={() => toggleEditModal(false, this.props.item)}
                                                        disabled={this.state.submitting}
                                                    >
                                                        <i className="fas fa-times mr-3" />
                                                        Close
                                                    </Button>
                                                </Modal.Footer></>
                                        )}
                                    ></EditLoanCategoryForm>
                                </div>
                            </div>
                        </div >
                    </div >
                </React.Fragment >
            </Modal >
        );
    }
}

export default EditLoanCategory;
