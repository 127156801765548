import http from "../httpService";

const recovaCreditAssessmentBaseAddress =
  process.env.REACT_APP_CREDITASSESSMENT_BASEADDRESS;

export function saveWorkFlow(workflow) {
  return http.post(recovaCreditAssessmentBaseAddress + `Workflow`, workflow);
}

export function updateWorkFlow(workflow, id) {
  return http.put(
    recovaCreditAssessmentBaseAddress + `Workflow/${id}`,
    workflow
  );
}

// export function retrieveAllByInstitutionCode(institutionCode: string) {
//   return http.get(
//     recovaCreditAssessmentBaseAddress +
//       `WorkFlow/RetrieveAllByInstitutionCode/${institutionCode}`
//   );
// }

export function retrieveById(id) {
  return http.get(recovaCreditAssessmentBaseAddress + `WorkFlow/${id}`);
}

export function retrieveAll() {
  return http.get(recovaCreditAssessmentBaseAddress + `Workflow/workflows`);
}

export function retrieveAllByInstitution(institutionCode) {
  return http.get(
    recovaCreditAssessmentBaseAddress + `Workflow/workflows/${institutionCode}`
  );
}

export function addWorkFlowTransitions(workflowId, payload) {
  return http.put(
    recovaCreditAssessmentBaseAddress +
      `Workflow/add-workflow-transitions/${workflowId}`,
    payload
  );
}

export function createWorkFlowState(workFlowState) {
  return http.post(
    recovaCreditAssessmentBaseAddress + `Workflow/workflowStates/create`,
    workFlowState
  );
}

export function retrieveWorkFlowStateById(id) {
  return http.get(
    recovaCreditAssessmentBaseAddress + `WorkFlow/workflowStates/${id}`
  );
}

export function retrieveAllWorkFlowStates() {
  return http.get(
    recovaCreditAssessmentBaseAddress + `Workflow/workflowStates`
  );
}

export function retrieveAllWorkFlowItems() {
  return http.get(recovaCreditAssessmentBaseAddress + `Workflow/workflowItems`);
}

export function retrieveHistoryOfWorkFlowTransition(loanRequestId) {
  return http.get(
    recovaCreditAssessmentBaseAddress +
      `Workflow/workflowItems/history/${loanRequestId}`
  );
}

export function filterPagedWorkFlows(
  data: any,
  pageSize: number,
  pageNumber: number
) {
  return http.post(
    recovaCreditAssessmentBaseAddress +
      `WorkFlow/FilterWorkFlows?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    data
  );
}

export default {
  retrieveAll,
  saveWorkFlow,
  updateWorkFlow,
  retrieveById,
  retrieveAllByInstitution,
  filterPagedWorkFlows,
  addWorkFlowTransitions,
  createWorkFlowState,
  retrieveWorkFlowStateById,
  retrieveAllWorkFlowStates,
  retrieveAllWorkFlowItems,
  retrieveHistoryOfWorkFlowTransition,
};
