import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import authService, {
  getCurrentUserSession,
  getCurrentUsersRoles,
} from "../../services/auth/authService";
import activityTrailService from "../../services/auditTrail/activityTrailService";
import remoteSessionService from "../../services/auth/remoteSessionService";

export interface ILogoutProps extends RouteComponentProps {}

class Logout extends React.PureComponent<ILogoutProps> {
  // constructor(props: LogoutProps) {
  //     super(props);
  //     // this.state = { :  };
  // }
  async componentDidMount() {
    try {
      await activityTrailService.saveActivityTrail({
        actionType: "Logout",
        description: "User Logged Out",
      });
    } catch (error) {}

    try {
      if (getCurrentUsersRoles().includes("remotesession")) {
        const currentSession = getCurrentUserSession();

        let payload: any = {};
        payload.id = currentSession.id;
        payload.sessionEndTime = new Date();
        await remoteSessionService.endRemoteSession(payload);
      }
    } catch (error) {
    } finally {
      window.location.replace("/");
      authService.logout();
    }

    // alert("running others")
    // this.props.history.push("/");
  }
  render() {
    // return <p>Please wait...</p>;
    return (
      <div id="loader" className="loader">
        <div className="plane-container">
          <div className="preloader-wrapper small active">
            <div className="spinner-layer spinner-blue">
              <div className="circle-clipper left">
                <div className="circle"></div>
              </div>
              <div className="gap-patch">
                <div className="circle"></div>
              </div>
              <div className="circle-clipper right">
                <div className="circle"></div>
              </div>
            </div>

            <div className="spinner-layer spinner-red">
              <div className="circle-clipper left">
                <div className="circle"></div>
              </div>
              <div className="gap-patch">
                <div className="circle"></div>
              </div>
              <div className="circle-clipper right">
                <div className="circle"></div>
              </div>
            </div>

            <div className="spinner-layer spinner-yellow">
              <div className="circle-clipper left">
                <div className="circle"></div>
              </div>
              <div className="gap-patch">
                <div className="circle"></div>
              </div>
              <div className="circle-clipper right">
                <div className="circle"></div>
              </div>
            </div>

            <div className="spinner-layer spinner-green">
              <div className="circle-clipper left">
                <div className="circle"></div>
              </div>
              <div className="gap-patch">
                <div className="circle"></div>
              </div>
              <div className="circle-clipper right">
                <div className="circle"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Logout;
