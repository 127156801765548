import * as React from "react";
import { Fragment } from "react";
import BaseListComponent, {
  BaseListComponentState,
  IFilterData,
} from "../../../components/BaseListComponent";
import ItemsTable, {
  filterFieldData,
} from "../../../components/tableComponents/itemsTable";
import {
  createFilterData,
  mapEntityList,
} from "../../../services/utility/autoMapperService";
import reportService from "../../../services/sweep/reportsService";
import SettlementsAndPayoutsReportDetail from "./settlementsAndPayoutsReportDetail";
import TableFilterPane from "./../../../components/tableComponents/tableFilterPane";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../../services/auth/authService";
import { printPDF } from "../../../services/utility/textFormatService";

export interface SettlementsAndPayoutsReportProps { }

export interface SettlementsAndPayoutsReportState
  extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  allFetchedItems: any;
  item: any;
}
class SettlementsAndPayoutsReportList extends BaseListComponent<SettlementsAndPayoutsReportState> { }

class SettlementsAndPayoutsReport extends React.Component<
  SettlementsAndPayoutsReportProps,
  SettlementsAndPayoutsReportState
> {
  constructor(props: SettlementsAndPayoutsReportProps) {
    super(props);
    this.state = {
      data: createFilterData(["AmountDebited", "SettlementDate", "SettlementAmount", "SettlementReference",
        "RetrievalReference", "Status"
      ]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      allFetchedItems: false,
      totalSize: 0,
      filterValueInput: [
        { name: "AmountDebited", fieldType: "number", value: "" },
        { name: "SettlementDate", fieldType: "date", value: "" },
        { name: "SettlementAmount", fieldType: "number", value: "" },
        { name: "SettlementReference", fieldType: "text", value: "" },
        { name: "RetrievalReference", fieldType: "text", value: "" },
        { name: "Status", fieldType: "text", value: "" },
        // { name: "SettlementType", fieldType: "text", value: "" },

      ],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
    };
  }

  handlePageChange = async (
    currentPage: number,
    pageSize: number,
    data?: IFilterData
  ) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true });
    data = typeof data === "undefined" ? this.state.data : data;
    try {
      const reportItems = await reportService.filterSettlementsAndPayoutsReport(
        data,
        pageSize,
        currentPage
      );
      console.log("report items: ", data);
      this.setState({
        tableItems: mapEntityList({
          entityList: reportItems.data.item2,
          properties: [
            { oldName: "debitedInstitution", newName: "debitedInstitution" },
            { oldName: "amountDebited", newName: "amountDebited" },
            { oldName: "settlementDate", newName: "settlementDate" },
            { oldName: "settlementAmount", newName: "settlementAmount" },
            { oldName: "settlementReference", newName: "settlementReference" },
            { oldName: "retrievalReference", newName: "retrievalReference" },
            { oldName: "narration", newName: "narration" },
            { oldName: "serviceFee", newName: "serviceFee" },
            // { oldName: "settlementType", newName: "settlementType" },
            { oldName: "reversalStatus", newName: "reversalStatus" },
            { oldName: "dateReversed", newName: "dateReversed" },
            {
              isTag: true,
              oldName: "status",
              newName: "status",
              success: "Successful",
              danger: "Failed",
              default: "default",
              primary: "PushedAndAwaitingResponse",
              secondary: "secondary",
              warning: "Pending",
              light: "light",
              dark: "Unknown",
            },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: reportItems.data.item1,
        allFetchedItems: reportItems.data.item2,
        fetching: false,
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
        // this.setState({ errors, fetching: false });
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        // this.setState({ errors, fetching: false });
      }
    } finally {
      this.setState({ fetching: false });
    }
  };

  handleOnExport = async (type: string) => {
    try {
      console.log("exporting reports...");
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      switch (type) {
        case "xlsx":
        case "csv":
          return await reportService.downloadSettlementsAndPayoutsReport(type);
        case "pdf":
          const response =
            await reportService.downloadSettlementsAndPayoutsReport(type);
          printPDF(response?.data);
          break;
        default:
          break;
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        //this.setState({ errors, fetching: false });
      }
    }
  };

  fetchItemById = async (item: any) => {
    try {
      this.setState({ fetchingDetail: true });
      const itemDetails =
        await reportService.retrieveSettlementsAndPayoutsReportDetail(
          item.id,
          item.SweepReference,
          item.Channel.props.children
        );
      this.setState({ item: itemDetails.data, fetchingDetail: false });
      console.log("response: ", itemDetails);
    } catch (error) {
    } finally {
      this.setState({ fetchingDetail: false });
    }
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }
  render() {
    const {
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;
    let filterValueInput = [];
    filterValueInput = [
      { name: "AmountDebited", fieldType: "number", value: "" },
      { name: "SettlementDate", fieldType: "date", value: "" },
      { name: "SettlementAmount", fieldType: "number", value: "" },
      { name: "SettlementReference", fieldType: "text", value: "" },
      { name: "RetrievalReference", fieldType: "text", value: "" },
      { name: "Status", fieldType: "text", value: "" },
      // { name: "SettlementType", fieldType: "text", value: "" },
    ];
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Pre-Settlement Report</b>
              </h3>
            </div>
            <SettlementsAndPayoutsReportList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              initialValues={{
                data: createFilterData([]) as IFilterData,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: filterValueInput,
                item: {},
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                showDetailsModal,
              }) => (
                <Fragment>
                  <SettlementsAndPayoutsReportDetail
                    showDetailsModal={showDetailsModal}
                    toggleDetailsModal={() => {
                      if (showDetailsModal)
                        this.handlePageChange(
                          this.state.currentPage,
                          this.state.pageSize
                        );
                      onToggleDetailsModal();
                    }}
                    item={item}
                  />
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="Settlements & Payouts Report"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              useCustomExport={true}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                              amountColumn={[
                                "settlementAmount",
                                "amountDebited",
                                "serviceFee"
                              ]}
                              timeColumn={["settlementDate"]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></SettlementsAndPayoutsReportList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SettlementsAndPayoutsReport;
