import React from "react";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../components/detailItem";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import collectionValidationService from "../../../services/collection/collectionValidationService";
import collectionSettingService from "../../../services/collection/collectionSettingService";

interface EditCollectionSettingFields {
  maxCollectionRetryPerDay: string;
  retryPeriodInterval: string;
  maxCollectionPausesPerSchedule: string;
}

class EditCollectionSettingForm extends BaseFormComponent<EditCollectionSettingFields> { }

interface IEditCollectionSettingProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}
interface IEditCollectionSettingState { }

class EditCollectionSetting extends React.Component<
  IEditCollectionSettingProps,
  IBaseFormState & IEditCollectionSettingState
> {
  constructor(props: IEditCollectionSettingProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
    };
  }

  onFormSubmit(fields: EditCollectionSettingFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors:
            collectionValidationService.validateEditCollectionSettingForm(
              fields
            ),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (fields: EditCollectionSettingFields, onReloadFieldsCB: any) => {
    try {
      this.setState({ submitting: true });
      const payload: any = {};
      payload.maxCollectionRetryPerDay = Number(fields.maxCollectionRetryPerDay);
      payload.retryPeriodInterval = Number(fields.retryPeriodInterval);
      payload.maxCollectionPausesPerSchedule = Number(fields.maxCollectionPausesPerSchedule);

      if (payload.maxCollectionRetryPerDay < 0 || payload.retryPeriodInterval < 0 || payload.maxCollectionPausesPerSchedule < 0) {
        toast.info(`Value cannot be negative `, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
        this.setState({ submitting: false });
      } else {
        //   console.log("For checks::", payload);
        const response = await collectionSettingService.editCollectionsSettings(
          payload,
          this.props.item.id
        );
        if (response.status >= 200 && response.status <= 300) {
          await activityTrailService.saveActivityTrail({
            actionType: "Edit Collection Setting",
            description: `Edited details for Collection Setting ${this.props.item.institutionCategory}`,
          });

          toast.info(`Collection Setting for ${this.props.item.institutionCategory} Edited successfully! `, {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          });
        }
        onReloadFieldsCB();
        this.setState({ submitting: false }, () =>
          this.props.toggleEditModal(true, this.props.item)
        );
      }
    } catch (error) {
      //   console.log("submission error: ", error);
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // console.log("submission error: ", error.response);
      }
      this.setState({ submitting: false });
    }
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      errors: {},
    });
  }

  render() {
    const { item, showEditModal, toggleEditModal } = this.props;
    const { validated, submitting, errors } = this.state;

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-columns fa-sm cyan-text mr-3" />
              Edit Collection Setting -{" "}
              <span className="font-weight-bold">{item.institutionCategory}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditCollectionSettingForm
          initialValues={{
            maxCollectionRetryPerDay: item.maxCollectionRetryPerDay?.toString(),
            retryPeriodInterval: item.retryPeriodInterval?.toString(),
            maxCollectionPausesPerSchedule: item.maxCollectionPausesPerSchedule?.toString()
          }}
          FormComponent={({
            fields: { maxCollectionPausesPerSchedule, maxCollectionRetryPerDay, retryPeriodInterval },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    {_.isEmpty(item) ? (
                      <Skeleton width={200} />
                    ) : (
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        <b>General Information</b>
                      </h6>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={2} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Category"
                              value={item.institutionCategory}
                              labelSize={5}
                              valueSize={7}
                            />
                            <DetailItem
                              label="Retry Period Interval"
                              value={
                                <DetailItemInputComponent
                                  id="retryPeriodInterval"
                                  type="number"
                                  name="retryPeriodInterval"
                                  placeholder=""
                                  value={retryPeriodInterval}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={5}
                              valueSize={7}
                            />
                          </dl>
                        )}
                      </div>
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={3} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Max. Collection Retry Per Day"
                              value={
                                <DetailItemInputComponent
                                  id="maxCollectionRetryPerDay"
                                  type="number"
                                  name="maxCollectionRetryPerDay"
                                  placeholder=""
                                  value={maxCollectionRetryPerDay}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={5}
                              valueSize={7}
                            />
                            <DetailItem
                              label="Max. Collection Pauses Per Schedule"
                              value={
                                <DetailItemInputComponent
                                  id="maxCollectionPausesPerSchedule"
                                  type="number"
                                  name="maxCollectionPausesPerSchedule"
                                  placeholder=""
                                  value={maxCollectionPausesPerSchedule}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={5}
                              valueSize={7}
                            />
                          </dl>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.UPDATE,
                      "Collection Setting",
                      () => {
                        this.onFormSubmit(
                          {
                            retryPeriodInterval,
                            maxCollectionRetryPerDay,
                            maxCollectionPausesPerSchedule,
                          },
                          onReloadFields
                        );
                      }
                    );
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></EditCollectionSettingForm>
      </Modal>
    );
  }
}

export default EditCollectionSetting;
