import React from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import authValidationService from "../../../services/auth/authValidationService";
import rolesService from "../../../services/auth/rolesService";
import DetailItem from "../../../components/detailItem";
import { ActionTypes } from "../../../enums/actionTypes";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import CheckboxTree from "react-checkbox-tree";
import Skeleton from "react-loading-skeleton";
import SelectInputComponent, { ISelectItems } from "../../../components/formSelectComponent";
import DetailItemSelectComponent from "../../../components/detailItemSelectBox";

interface EditUserRoleFields {
  name: string;
  description: string;
  roleVisibility: string;
}

class EditUserRoleForm extends BaseFormComponent<EditUserRoleFields> { }

interface IEditUserRoleProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
  functions: any;
}
interface IEditUserRoleState {
  checked: any;
  expanded: any;
  functions: any;
  parentFunctions: any;
  ownUpdate: boolean;
}

class EditUserRole extends React.Component<
  IEditUserRoleProps,
  IBaseFormState & IEditUserRoleState
> {
  constructor(props: IEditUserRoleProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      checked: [],
      expanded: [],
      parentFunctions: [],
      functions: [],
      ownUpdate: false,
    };
  }

  static getDerivedStateFromProps(nextProps: IEditUserRoleProps, prevState) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (!_.isEmpty(nextProps.item)) {
      // console.log("current props edit roles: ", nextProps.item);
      const functions = nextProps.functions;
      let parentFunctions = functions.filter((func) => func.parentId === 0);
      // console.log(parentFunctions);
      let treeCollection: any = [];
      treeCollection = parentFunctions.map((pFunc) => {
        let newSet: any = {};
        newSet = {
          value: pFunc.displayName,
          label: pFunc.displayName,
          // showCheckbox: false,
          children: functions
            .filter((func) => func.parentId === pFunc.id)
            .map((filtered) => {
              const child: any = {};
              child.value = filtered.description;
              child.label = filtered.displayName;
              return child;
            }),
        };
        return newSet;
        // console.log("New set of nodes", newSet);
      });

      let checked: any = [];
      const itemFunctions = nextProps.item.functions;
      if (itemFunctions) {
        let pFunctions = itemFunctions.filter((func) => func.parentId === 0);
        pFunctions.map((pFunc) => {
          itemFunctions
            .filter((func) => func.parentId === pFunc.id)
            .map((filtered) => {
              checked.push(filtered.description);
            });
        });
      }
      // console.log("Checked gotten:::", checked);
      return {
        functions: treeCollection,
        checked: checked,
        parentFunctions: parentFunctions,
      };
    }
    return null;
  }

  onFormSubmit(fields: EditUserRoleFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors: authValidationService.validateEditUserRoleForm(fields),
          ownUpdate: true,
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            // console.log("state testing: ", this.state);
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  submit = async (
    fields: EditUserRoleFields,
    onReloadFieldsCB: any
  ): Promise<boolean> => {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const payload = this.props.item;
      payload.name = fields.name;
      payload.description = fields.description;
      payload.roleVisibility = fields.roleVisibility;

      let checkedFunctions = this.state.checked.map((value) => {
        return this.props.functions.find((func) => func.description === value);
      });
      payload.assignedFunctions = checkedFunctions.map((value) => value.id);
      this.state.parentFunctions.map((elem) => {
        if (checkedFunctions.some((func) => func.parentId === elem.id)) {
          payload.assignedFunctions.push(elem.id);
        }
      });
      // console.log("about to update: ", payload);

      const response = await rolesService.updateRole(payload, payload.id);

      await activityTrailService.saveActivityTrail({
        actionType: "Edit UserRole",
        description: `Edited details for UserRole  ${fields.name}`,
      });

      if (response.data.isLoggedForApproval === true) {
        toast.warn("Action has been successfully logged for Approval!", {
          autoClose: 6000,
          type: toast.TYPE.WARNING,
          position: "top-center",
          hideProgressBar: false,
        });
      } else {
        toast.info(`User Role ${fields.name} Edited successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      }
      onReloadFieldsCB();
      this.setState({ submitting: false, ownUpdate: true }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      this.setState({ submitting: false, ownUpdate: true });
      // console.log("submission error: ", error);
      return false;
    } finally {
      return true;
    }
  };

  validateField = (fieldName: string, type: string, validated: boolean) => {
    let defaultClass = "";
    if (type === "select") {
      defaultClass =
        "custom-select select2 mr-sm-2 form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    } else {
      defaultClass = "form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    }
    return !validated
      ? defaultClass
      : this.state.errors[fieldName]
        ? `${defaultClass} is-invalid`
        : `${defaultClass} is-valid`;
  };

  checkItems = (checked: any) => { };

  render() {
    const { item, showEditModal, toggleEditModal } = this.props;
    const { validated, submitting, errors, functions } = this.state;
    // console.log("fetched", branches);
    // console.log("fetched", userRoles);
    const roleVisibilityInputData: ISelectItems[] = ["Branch", "Regional", "Global"]
    .map(item => {
      return { name: item, value: item }
    });
    
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-user-shield fa-sm cyan-text mr-3" />
              Edit User Role -{" "}
              <span className="font-weight-bold">{item.name}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditUserRoleForm
          initialValues={{
            name: item.name,
            description: item.description,
            roleVisibility: item.roleVisibility
          }}
          FormComponent={({
            fields: { name, description, roleVisibility },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-header clear-fix">
                        <h6
                          className="card-title float-left"
                          style={{ marginBottom: "0px" }}
                        >
                          General Information
                        </h6>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="form-group col-md-12 row">
                            <DetailItem
                              label="Name"
                              value={
                                <React.Fragment>
                                  <input
                                    type="text"
                                    className={this.validateField(
                                      "name",
                                      "text",
                                      validated
                                    )}
                                    id="name"
                                    name="name"
                                    value={name}
                                    onChange={(e) =>
                                      onChange("name", e.currentTarget.value)
                                    }
                                  />
                                  <div
                                    className="invalid-feedback"
                                    style={{ visibility: errors.name }}
                                  >
                                    {errors.name}
                                  </div>
                                </React.Fragment>
                              }
                            />
                          </div>
                          <div className="form-group col-md-12 row">
                            <DetailItem label="Code" value={item.code} />
                          </div>
                          <div className="form-group col-md-12 row">
                            <DetailItem
                              label="Role Visibility"
                              value={
                                <DetailItemSelectComponent
                                  id="roleVisibility"
                                  name="roleVisibility"
                                  value={roleVisibility}
                                  items={roleVisibilityInputData}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                            />
                          </div>
                          <div className="form-group col-md-12 row">
                            <DetailItem
                              label="Description"
                              value={
                                <React.Fragment>
                                  <input
                                    type="text"
                                    className={this.validateField(
                                      "description",
                                      "text",
                                      validated
                                    )}
                                    id="description"
                                    name="description"
                                    value={description}
                                    onChange={(e) =>
                                      onChange(
                                        "description",
                                        e.currentTarget.value
                                      )
                                    }
                                  />
                                  <div
                                    className="invalid-feedback"
                                    style={{ visibility: errors.description }}
                                  >
                                    {errors.description}
                                  </div>
                                </React.Fragment>
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-header clear-fix">
                        <h6
                          className="card-title float-left"
                          style={{ marginBottom: "0px" }}
                        >
                          Other Information
                        </h6>
                      </div>
                      <div className="card-body">
                        {_.isEmpty(item) ? (
                          <Skeleton count={5} />
                        ) : (
                          <div className="form-group row">
                            <label
                              htmlFor="inputFunctions"
                              className="col-md-3 col-form-label"
                            >
                              <b>Functions:</b>
                            </label>
                            <CheckboxTree
                              icons={{
                                parentClose: (
                                  <i className="icon-folder2 orange-text"></i>
                                ),
                                parentOpen: (
                                  <i className="icon-folder-open orange-text"></i>
                                ),
                                leaf: (
                                  <i className="icon-file-text-o blue-text"></i>
                                ),
                                halfCheck: <i className="icon-square"></i>,
                                check: (
                                  <i className="icon-check_box green-text"></i>
                                ),
                              }}
                              nodes={functions}
                              checked={this.state.checked}
                              expanded={this.state.expanded}
                              onCheck={(checked) =>
                                this.setState({ checked, ownUpdate: true })
                              }
                              // onCheck={this.checkItems(checked)}
                              onExpand={(expanded) =>
                                this.setState({ expanded, ownUpdate: true })
                              }
                              iconsClass="fa5"
                              showExpandAll={true}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(e, ActionTypes.UPDATE, "User Role", () => {
                      this.onFormSubmit({ name, description, roleVisibility }, onReloadFields);
                    });
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></EditUserRoleForm>
      </Modal>
    );
  }
}

export default EditUserRole;
