import React, { Component } from "react";

interface ITextProps {
  id: string;
  type: "password" | "text" | "number";
  name: string;
  placeholder: string;
  value: string;
  required: boolean;
  classNameString: string;
  onChange: any;
}
class TextBox extends Component<ITextProps, {}> {
  state = {};
  static defaultProps = {
    classNameString: "form-control form-control-sm",
    value: "",
  };
  render() {
    const {
      id,
      type,
      name,
      placeholder,
      value,
      required,
      classNameString,
      onChange,
    } = this.props;
    return (
      <input
        type={type}
        className={classNameString}
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        required={required === true ? true : false}
        onChange={(e) => onChange(e.currentTarget.id, e.currentTarget.value)}
      />
    );
  }
}

export default TextBox;
