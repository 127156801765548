import React from "react";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import authService, {
  getCurrentUsersRoles,
} from "../../services/auth/authService";
import { decodeToken } from "react-jwt";

export interface LockProps {
  showLock: boolean;
  currentUser: any;
  handleUnlockUser: any;
}
export interface LockState {
  data: {
    password: string;
  };
  submitting: boolean;
  errors: string;
}

interface LockFields {
  password: string;
}

class Lock extends React.Component<LockProps, LockState> {
  constructor(props: LockProps) {
    super(props);
    this.state = {
      data: {
        password: "",
      },
      errors: "",
      submitting: false,
    };
  }

  handleOnInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...this.state.data };
    if (_.isEmpty(e.currentTarget.min)) {
      // console.log("no min value");
      // console.log("testing: ", e.currentTarget.min);
      data[e.currentTarget.name] = e.currentTarget.value;
      this.setState({ data });
    } else {
      if (e.currentTarget.value >= e.currentTarget.min) {
        data[e.currentTarget.name] = e.currentTarget.value;
        this.setState({ data });
      }
    }
  };

  handleOnSubmit = async () => {
    if (this.state?.data.password === "") {
      return;
    }

    const { handleUnlockUser, currentUser } = this.props;
    const { errors, data } = this.state;
    try {
      this.setState({ submitting: true });
      if (getCurrentUsersRoles().includes("remotesession")) {
        const currentSession = JSON.parse(
          localStorage.getItem("remoteUserSession") || ""
        );
        const decodedToken = decodeToken(currentSession.token || "");

        await authService.retrieveUser(
          decodedToken?.["InstitutionCode"],
          decodedToken?.["UserName"],
          data.password
        );
      } else {
        await authService.retrieveUser(
          currentUser.institution.code,
          currentUser.username,
          data.password
        );
      }
      data.password = "";
      this.setState({ data, errors: "", submitting: false });
      handleUnlockUser();
    } catch (ex) {
      this.setState({ submitting: false });
      if (
        ex.response &&
        (ex.response.status === 404 || ex.response.status === 500)
      ) {
        // console.log(ex);
        // console.log(ex.response);
        // console.log("testing ", ex.response.data);
        // errors.response = ex.response.data;
      } else {
        this.setState({ submitting: false });
        // console.log(ex);
        // console.log(ex.response);
        // errors.response = ex.response.data;
        // console.log("testing: ", ex.response.data);
      }
      // console.log(errors);
      this.setState({
        errors:
          "Something went wrong. Please, ensure your credentials are correct",
      });
    }
  };

  render() {
    const { data, submitting, errors } = this.state;
    const { showLock, currentUser } = this.props;
    return (
      <Modal backdrop="static" show={showLock}>
        <form action="#">
          <div className="modal-header text-center">
            <h4 className="modal-title w-100">
              <b>Unlock User</b>
            </h4>
          </div>
          <Modal.Body>
            <div className="text-center">
              <i className="fas fa-user-circle fa-6x" />
              <br />
              <br />
              <h4>
                <b>
                  {_.isEmpty(currentUser)
                    ? ""
                    : `${currentUser.firstName} ${currentUser.lastName}`}
                </b>
              </h4>
              <h6 style={{ color: "red" }}>Please enter your password</h6>
            </div>
            <div className="modal-body mx-3">
              <input
                type="password"
                style={{ textAlign: "center" }}
                id="password"
                value={data.password}
                name="password"
                className="form-control r-0 light s-12 mb-4"
                onChange={this.handleOnInputChange}
              />
            </div>
            <h6 className="text-center">
              <i style={{ color: "red" }}>{errors}</i>
            </h6>
            <br />
            <div className="text-center">
              {_.isEmpty(currentUser) ? (
                ""
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  variant="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleOnSubmit();
                  }}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-unlock-alt mr-2"></i>Unlock
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
              )}
            </div>
          </Modal.Body>
          {/* <div className="modal-footer justify-content-center">
          
        </div> */}
          <div className="modal-footer justify-content-center">
            <Link to="/logout" id="logout">
              <i className="fas fa-sign-in-alt ml-4 mr-2" />
              Back To Login
            </Link>
          </div>
          {/* <Modal.Footer>
          <Button size="sm" variant="primary">
            Unlock
          </Button>
        </Modal.Footer> */}
        </form>
      </Modal>
    );
  }
}

export default Lock;
