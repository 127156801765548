import http from "../httpService";

export interface ISaveFeeConfiguration {
  feeEntryType: "Generic" | "Repayment Prediction";
  flatRatePerRequest: number;
  institutionCode: string;
  flatRatePerLoanDisbursed: number;
  percentageOfLoanAmount: number;
  minimumPerTransaction: number;
  maximumPerTransaction: number;
  feeIncomeGLAccountNumber: string;
  expenseGLAccountNumber: string;
  flatRatePerSuccessfulRequest: number;
}

export interface IUpdateFeeConfiguration {
  feeEntryType: "Generic" | "Repayment Prediction";
  flatRatePerRequest: number;
  flatRatePerLoanDisbursed: number;
  percentageOfLoanAmount: number;
  minimumPerTransaction: number;
  maximumPerTransaction: number;
  feeIncomeGLAccountNumber: string;
  expenseGLAccountNumber: string;
  flatRatePerSuccessfulRequest: number;
}

const recovaCreditAssessmentBaseAddress =
  process.env.REACT_APP_CREDITASSESSMENT_BASEADDRESS;

export function saveFeeConfiguration(feeConfiguration: ISaveFeeConfiguration) {
  return http.post(
    recovaCreditAssessmentBaseAddress + `FeeConfiguration`,
    feeConfiguration
  );
}

export function updateFeeConfiguration(
  feeConfiguration: IUpdateFeeConfiguration,
  id: number
) {
  return http.put(
    recovaCreditAssessmentBaseAddress + `FeeConfiguration/${id}`,
    feeConfiguration
  );
}

export function retrieveAll() {
  return http.get(recovaCreditAssessmentBaseAddress + `FeeConfiguration`);
}

export function retrieveAllByInstitution(institutionCode: string) {
  return http.get(
    recovaCreditAssessmentBaseAddress +
      `FeeConfiguration/getbyinstitution/${institutionCode}`
  );
}

// export function filterPagedFeeConfigurations(
//   data: any,
//   pageSize: number,
//   pageNumber: number
// ) {
//   return http.post(
//     recovaCreditAssessmentBaseAddress +
//       `FeeConfiguration/FilterFeeConfigurations?pageNumber=${pageNumber}&pageSize=${pageSize}`,
//     data
//   );
// }

export function retrieveById(id: number) {
  return http.get(recovaCreditAssessmentBaseAddress + `FeeConfiguration/${id}`);
}

export function removeById(id: number) {
  return http.delete(
    recovaCreditAssessmentBaseAddress + `FeeConfiguration/${id}`
  );
}

export function Enable(id: number) {
  return http.patch(
    recovaCreditAssessmentBaseAddress + `FeeConfiguration/${id}/enable`
  );
}

export function Disable(id: number) {
  return http.patch(
    recovaCreditAssessmentBaseAddress + `FeeConfiguration/${id}/disable`
  );
}

export default {
  saveFeeConfiguration,
  updateFeeConfiguration,
  retrieveById,
  retrieveAllByInstitution,
  retrieveAll,
  removeById,
  Enable,
  Disable
  //   filterPagedFeeConfigurations,
  //   addQualificationRuleToFeeConfiguration,
};
