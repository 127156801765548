import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../components/detailItem";
import textFormatService from "../../services/utility/textFormatService";
import DetailItemsTable from "../../components/tableComponents/detailItemsTable";
import { mapEntityList } from "../../services/utility/autoMapperService";
import loanUpdateService from "../../services/sweep/loanUpdateService";
import swal from "sweetalert";
import { toast } from "react-toastify";
import ThirdPartyImageView from "../../components/thirdPartyImageView";

interface ILoanUpdateTransactionsReportDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  item: any;
}

interface ILoanUpdateTransactionsReportDetailState {
  requestTransactions: any;
  requestTransactionsTotalCount: number;
  requestTransactionsCurrentPage: number;
  fetchingRequestTransactions: boolean;
}

class LoanUpdateTransactionsReportDetail extends Component<
  ILoanUpdateTransactionsReportDetailProps,
  ILoanUpdateTransactionsReportDetailState
> {
  constructor(props: ILoanUpdateTransactionsReportDetailProps) {
    super(props);
    this.state = {
      requestTransactions: [],
      requestTransactionsTotalCount: 0,
      requestTransactionsCurrentPage: 1,
      fetchingRequestTransactions: false,
    };
  }

  handleOnRequestTransactionsPageChange = async () => {
    try {
      this.setState({ fetchingRequestTransactions: true });
      const requestTrx = this.props.item.loanUpdateRequestTransactions;
      this.setState({
        requestTransactions: mapEntityList({
          entityList: requestTrx,
          properties: [
            { oldName: "accountNumber", newName: "creditAccountNumber" },
            { oldName: "glAccountNumber", newName: "debitAccountNumber" },
            { oldName: "amount", newName: "amount" },
            { oldName: "settlementAmount", newName: "settlementAmount" },
            {
              isBoolean: true,
              oldName: "isSuccessful",
              newName: "status",
            },
            { oldName: "responseDescription", newName: "responseDescription" },
            { oldName: "narration", newName: "narration" },
            {
              oldName: "transactionReference",
              newName: "transactionReference",
            },
            { oldName: "dateModified", newName: "transactionDate" },
          ],
        }),
        requestTransactionsCurrentPage: 1,
        requestTransactionsTotalCount: requestTrx.length,
        fetchingRequestTransactions: false,
      });
    } catch (error) {
      this.setState({ fetchingRequestTransactions: false });
    } finally {
      this.setState({ fetchingRequestTransactions: false });
    }
  };

  async retryTransaction() {
    try {
      swal({
        title: "Confirm!",
        text: `Are you sure you want to Retry this Loan Update Transaction?`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode: true,
      }).then(async (resp) => {
        if (resp) {
        } else {
          await loanUpdateService.RetryLoanBalanceUpdateProcess(
            this.props.item.loanUpdateTransaction.id
          );
          toast.info(`Loan Update retry in progress!`, {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          });
        }
      });
    } catch (error) {
      toast.error(`Unable to retry Loan Update`, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
    }
  }

  async componentDidMount() {
    this.handleOnRequestTransactionsPageChange();
  }

  render() {
    const { showDetailsModal, toggleDetailsModal, item } = this.props;
    const {
      requestTransactions,
      requestTransactionsTotalCount,
      requestTransactionsCurrentPage,
      fetchingRequestTransactions,
    } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item.loanUpdateTransaction) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="fas fa-receipt fa-sm s-18 mr-3" />
                Loan Update Transactions Detail
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item.loanUpdateTransaction) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item.loanUpdateTransaction) ? (
                    <Skeleton count={5} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Credit Account Number"
                        value={item.loanUpdateTransaction.loanRepaymentAccount}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Debit Account Number"
                        value={item.loanUpdateTransaction.debitAccount}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Customer Name"
                        value={item.loanUpdateTransaction.customerName}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Transaction Amount"
                        value={textFormatService.convertKoboToNaira(
                          item.loanUpdateTransaction.amount,
                          2
                        )}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Customer Type"
                        value={item.loanUpdateTransaction.customerType}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Attempts"
                        value={item.loanUpdateTransaction.attempts}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Status"
                        value={(() => {
                          switch (item.loanUpdateTransaction.loanUpdateStatus) {
                            case "Pending":
                              return (
                                <span className="badge badge-warning">
                                  {item
                                    ? item.loanUpdateTransaction
                                        .loanUpdateStatus
                                    : ""}
                                </span>
                              );
                            case "Processing":
                              return (
                                <span className="badge badge-info">
                                  {item
                                    ? item.loanUpdateTransaction
                                        .loanUpdateStatus
                                    : ""}
                                </span>
                              );
                            case "Successful":
                              return (
                                <span className="badge badge-success">
                                  {item
                                    ? item.loanUpdateTransaction
                                        .loanUpdateStatus
                                    : ""}
                                </span>
                              );
                            case "Failed":
                              return (
                                <span className="badge badge-danger">
                                  {item
                                    ? item.loanUpdateTransaction
                                        .loanUpdateStatus
                                    : ""}
                                </span>
                              );
                            default:
                              return null;
                          }
                        })()}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Sweep Service"
                        value={
                          <ThirdPartyImageView
                            thirdPartyName={
                              item.directDebitTransaction.thirdPartySweepService
                            }
                          />
                        }
                        labelSize={5}
                        valueSize={7}
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={5} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Recova Reference"
                        value={item.loanUpdateTransaction.recovaReferenceNo}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Retrieval Reference"
                        value={item.loanUpdateTransaction.retrievalReferenceNo}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Response"
                        value={item.loanUpdateTransaction.responseDescription}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Time Swept"
                        value={textFormatService.formatTimeString(
                          item.loanUpdateTransaction.occuredOn
                        )}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Transaction Id"
                        value={item.loanUpdateTransaction.transactionUniqueId}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Narration"
                        value={item.loanUpdateTransaction.narration}
                        labelSize={5}
                        valueSize={7}
                      />
                      {item.loanUpdateTransaction.hasError && (
                        <DetailItem
                          label="Error Message"
                          value={item.loanUpdateTransaction.errorMessage}
                          labelSize={5}
                          valueSize={7}
                        />
                      )}
                    </dl>
                  )}
                </div>
              </div>
            </div>

            <DetailItemsTable
              title="Loan Update Attempts"
              item={item.loanUpdateRequestTransactions}
              handleOnPageChange={this.handleOnRequestTransactionsPageChange}
              content={requestTransactions}
              contentCount={requestTransactionsTotalCount}
              page={requestTransactionsCurrentPage}
              fetching={fetchingRequestTransactions}
              amountColumn={["amount", "recoveryFee", "settlementAmount"]}
              timeColumn={["dateModified"]}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {(item.loanUpdateTransaction?.loanUpdateStatus === "Failed" ||
            item.loanUpdateTransaction?.loanUpdateStatus === "Pending") && (
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => {
                this.retryTransaction();
                toggleDetailsModal();
              }}
            >
              <i className="fas fa-undo mr-3" />
              Retry
            </Button>
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default LoanUpdateTransactionsReportDetail;
