import React from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { BillingType } from "../../../enums/billingType";
import { PaymentMode } from "../../../enums/paymentMode";
import authValidationService from "../../../services/auth/authValidationService";
import DetailItem from "../../../components/detailItem";
import { ActionTypes } from "../../../enums/actionTypes";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import DetailItemSelectComponent, { ISelectItems } from "../../../components/detailItemSelectBox";
import walletConfigService from "../../../services/billing/walletConfigService";

interface EditInstitutionWalletConfigurationFields {
  billingType: string;
  paymentMode: string;
}

class EditInstitutionWalletConfigurationForm extends BaseFormComponent<EditInstitutionWalletConfigurationFields> { }

interface IEditInstitutionWalletConfigurationProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}

interface IEditInstitutionWalletConfigurationState {
  ownUpdate: boolean;
}

class EditInstitutionWalletConfiguration extends React.Component<
  IEditInstitutionWalletConfigurationProps,
  IBaseFormState & IEditInstitutionWalletConfigurationState
> {
  constructor(props: IEditInstitutionWalletConfigurationProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      ownUpdate: false,
    };
  }

  static getDerivedStateFromProps(nextProps: IEditInstitutionWalletConfigurationProps, prevState) {
    return {
      item: nextProps.item,
    };
  }

  onFormSubmit(
    fields: EditInstitutionWalletConfigurationFields,
    onReloadFieldsCB: any
  ): boolean {
    try {
      this.setState(
        {
          errors: authValidationService.validateEditWalletConfigurationForm(fields),
          ownUpdate: true,

        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (
    fields: EditInstitutionWalletConfigurationFields,
    onReloadFieldsCB: any
  ) => {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const payload = { ...this.props.item };
      payload.billingType = fields.billingType;
      payload.paymentMode = fields.paymentMode;

      // console.log("Payload: ", payload)
      const response = await walletConfigService.updateWalletConfig(payload.walletConfigurationId, payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit Wallet Configuration",
          description: `Edited details for Wallet Configuration`,
        });
        toast.info(`Wallet Configuration for Edited successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      }
      onReloadFieldsCB();
      this.setState({ submitting: false, ownUpdate: true }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
    }
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false
    });
  }

  render() {
    const {
      item,
      showEditModal,
      toggleEditModal,
    } = this.props;
    const {
      validated,
      submitting,
      errors,
    } = this.state;

    let billingTypeInputData: ISelectItems[] = Object.keys(BillingType)
      .filter((value) => isNaN(Number(value)) === false)
      .map((key) => ({
        name: BillingType[key],
        value: BillingType[key],
      }));

    let paymentModeInputData: ISelectItems[] = Object.keys(PaymentMode)
      .filter((value) => isNaN(Number(value)) === false)
      .map((key) => ({
        name: PaymentMode[key],
        value: PaymentMode[key],
      }));

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-tools fa-sm cyan-text mr-3" />
              Edit Wallet Configuration -{" "}
              <span className="font-weight-bold">{item.serviceOffering}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditInstitutionWalletConfigurationForm
          initialValues={{
            billingType: item.billingType,
            paymentMode: item.paymentMode
          }}
          FormComponent={({
            fields: {
              billingType,
              paymentMode
            },
            onChange,
            onReloadFields,
            onHandleSubmit
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    <h6
                      className="card-title float-left"
                      style={{ marginBottom: "0px" }}
                    >
                      General Information
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <dl className="row">
                          <DetailItem
                            label="Institution Code"
                            value={item.institutionCode}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Billing Type"
                            value={
                              <DetailItemSelectComponent
                                id="billingType"
                                name="billingType"
                                items={billingTypeInputData}
                                value={billingType}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                              />
                            }
                            labelSize={6}
                            valueSize={6}
                          />
                        </dl>
                      </div>
                      <div className="col-md-6">
                        <dl className="row">
                          <DetailItem
                            label="Service Offering"
                            value={item.serviceOffering}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Payment Mode"
                            value={
                              <DetailItemSelectComponent
                                id="paymentMode"
                                name="paymentMode"
                                items={paymentModeInputData}
                                value={paymentMode}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                              />
                            }
                            labelSize={6}
                            valueSize={6}
                          />
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.UPDATE,
                      "Wallet Configuration",
                      () => {
                        this.onFormSubmit(
                          {
                            billingType,
                            paymentMode
                          },
                          onReloadFields
                        );
                      }
                    );
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )
          }
        ></EditInstitutionWalletConfigurationForm >
      </Modal>
    );
  }
}

export default EditInstitutionWalletConfiguration;
