import axios from "axios";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../auth/authService";

const reportBaseAddress = process.env.REACT_APP_REPORTSCENTER_BASEADDRESS
const sweepBaseAddress = process.env.REACT_APP_SWEEP_BASEADDRESS
const decodedToken = decodeToken(getCurrentUserSession().token || "");

export const axiosReportInstance = axios.create({
  baseURL: `${reportBaseAddress}v1/`,
  headers: {
    "userId": decodedToken?.["UserId"],
    "Authorization": `Bearer ${getCurrentUserSession().token}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Accept: "/",
  },
});

export const axiosSweepInstance = axios.create({
  baseURL: sweepBaseAddress,
  headers: {
    "userId": decodedToken?.["UserId"],
    "Authorization": `Bearer ${getCurrentUserSession().token}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Accept: "/",
  },
});
