import React, { Fragment, useContext } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import {
  Modal,
  Button,
  Card,
  useAccordionToggle,
  AccordionContext,
  Accordion,
} from "react-bootstrap";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import swal from "sweetalert";
import authValidationService from "../../../services/auth/authValidationService";
import DetailItem from "../../../components/detailItem";
import { ActionTypes } from "../../../enums/actionTypes";
import sweepConfigService from "../../../services/sweep/sweepConfigService";
import textFormatService from "../../../services/utility/textFormatService";
import AddSettlementAccount from "../institution/components/addSettlementAccount";
import EditInstitutionRecovaFeeConfiguration from "./editInstitutionRecovaFeeConfiguration";
import EditInstitutionThirdPartyFeeConfiguration from "./editInstitutionThirdPartyFeeConfiguration";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

function ContextAwareToggle({ eventKey, header }) {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      <b>{header}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
}

interface EditInstitutionSweepConfigurationFields {
  name?: string;
  code?: string;
  shortName?: string;
  externalInstitutionCode?: string;
  integrationCode?: string;
  category?: string;
  feeIncomeAccount?: string;
  feeExpenseAccount?: string;
  settlementMirrorAccount?: string;
  receivableAccount?: string;
  loanCollectionsAccount?: string;
  suspenseAccount?: string;
  settlementInstitutionCode?: string;
  nipBankCode?: string;
  appZoneNIPAccountName?: string;
  appZoneNIPAccountNo?: string;
  sweepInitiationType?: string;
  threshold?: string;
  thresholdBaseLine?: string;
  settlementAccountId?: string;
  guarantorSweepInitiationType?: string;
  guarantorMoratoriumDays?: string;
  maxNoOfSweepsPerDay?: string;
  sweepable?: boolean;
  chargeCustomer?: boolean;
}

class EditInstitutionSweepConfigurationForm extends BaseFormComponent<EditInstitutionSweepConfigurationFields> {}

interface IEditInstitutionSweepConfigurationProps {
  item: any;
  recovaRecoveryFeeConfigItem: any;
  recovaCollectionFeeConfigItem: any;
  thirdPartyRecoveryFeeConfigItem: any;
  thirdPartyCollectionFeeConfigItem: any;
  showEditModal: boolean;
  toggleEditModal: any;
  allInstitutions: any;
  allCommercialBanks: any;
}

interface IEditInstitutionSweepConfigurationState {
  settlementAccountId: string;
  settlementAccounts: any;
  showAddConfigModal: boolean;
  chargeCustomer?: boolean;
}

class EditInstitutionSweepConfiguration extends React.Component<
  IEditInstitutionSweepConfigurationProps,
  IBaseFormState & IEditInstitutionSweepConfigurationState
> {
  constructor(props: IEditInstitutionSweepConfigurationProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      settlementAccountId: "",
      settlementAccounts: [],
      showAddConfigModal: false,
      chargeCustomer: _.isUndefined(this.props.item)
        ? false
        : this.props.item.chargeCustomer,
    };
  }

  onFormSubmit(
    fields: EditInstitutionSweepConfigurationFields,
    onReloadFieldsCB: any
  ): boolean {
    try {
      fields.sweepable = this.props.item.sweepable;
      fields.chargeCustomer = this.state.chargeCustomer;
      this.setState(
        {
          errors:
            authValidationService.validateEditSweepConfigurationForm(fields),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.item)) {
      if (this.props.item.settlementAccounts?.length > 0) {
        let defaultAccount = this.props.item.settlementAccounts.find(
          (x) => x.isDefault === true
        );
        if (defaultAccount !== undefined) {
          this.setState({
            settlementAccountId: defaultAccount.id,
          });
        }
        this.setState({
          settlementAccounts: this.props.item.settlementAccounts,
        });
      }
    }
  }

  submit = async (
    fields: EditInstitutionSweepConfigurationFields,
    onReloadFieldsCB: any
  ) => {
    try {
      this.setState({ submitting: true });
      const payload = this.props.item;

      payload.name = fields.name;
      payload.shortName = fields.shortName;
      payload.code = fields.code;
      payload.externalInstitutionCode = fields.externalInstitutionCode;
      payload.integrationCode = fields.integrationCode;
      payload.feeIncomeAccount = fields.feeIncomeAccount;
      payload.feeExpenseAccount = fields.feeExpenseAccount;
      payload.settlementMirrorAccount = fields.settlementMirrorAccount;
      payload.receivableAccount = fields.receivableAccount;
      payload.loanCollectionsAccount = fields.loanCollectionsAccount;
      payload.suspenseAccount = fields.suspenseAccount;
      payload.settlementInstitutionCode = fields.settlementInstitutionCode;
      payload.nipBankCode = fields.nipBankCode;
      payload.appZoneNIPAccountName = fields.appZoneNIPAccountName;
      payload.appZoneNIPAccountNo = fields.appZoneNIPAccountNo;
      payload.sweepInitiationType = fields.sweepInitiationType;
      payload.threshold = fields.threshold;
      payload.chargeCustomer = fields.chargeCustomer;
      payload.thresholdBaseLine = fields.thresholdBaseLine;
      payload.maxNoOfSweepsPerDay = Number(fields.maxNoOfSweepsPerDay);
      payload.guarantorMoratoriumDays = Number(fields.guarantorMoratoriumDays);
      payload.guarantorSweepInitiationType =
        fields.guarantorSweepInitiationType;
      // console.log("about to set default settlement account");

      if (fields.category !== "CommercialBank") {
        if (this.state.settlementAccountId !== "") {
          await sweepConfigService.setDefaultSettlementAccount(
            fields.code,
            Number(
              this.state.settlementAccounts.find((x) => x.isDefault === true).id
            )
          );
        }
      }

      const response = await sweepConfigService.updateSweepConfig(payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit Sweep Configuration",
          description: `Edited details for Sweep Configuration  ${fields.name}`,
        });
        toast.info(
          `Sweep Configuration for ${fields.name} Edited successfully! `,
          {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          }
        );
      }
      onReloadFieldsCB();
      this.setState({ submitting: false }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      // console.log("submission error: ", error);
    } finally {
      this.setState({ submitting: false });
    }
  };

  handleOnToggleModal = () => {
    this.setState({ showAddConfigModal: !this.state.showAddConfigModal });
  };

  toggleChargeCustomer = () => {
    this.setState({ chargeCustomer: !this.state.chargeCustomer });
  };

  handleOnDeleteSettlementAccount = async (id: number) => {
    swal({
      title: "Confirm!",
      text: `Are you sure you want to delete this Settlement Account?`,
      icon: "warning",
      buttons: {
        confirm: {
          text: "Yes",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: "Cancel",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      closeOnClickOutside: false,
      dangerMode: true,
    }).then(async (resp) => {
      if (resp) {
        // console.log("cancelling action...");
        // this.setState({ updatingStatus: false });
      } else {
        const test = [...this.state.settlementAccounts];
        let finalList: any[] = [];
        test.forEach((element) => {
          if (element.id !== id) {
            finalList.push(element);
          }
        });
        const ofi = this.props.item.code;
        var response = await sweepConfigService.removeSettlementAccount(
          id,
          ofi
        );
        if (response.status >= 200 && response.status <= 200) {
          toast.info(`Sweep Configuration Deleted successfully! `, {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          });
          this.setState({ settlementAccounts: finalList });
        }
        // console.log("response ", response);
      }
    });

    // debugger;
    // let settlementAccounts = [...this.state.settlementAccounts];
    // const account = settlementAccounts.filter((x) => x.id === id)[0];
    // const index = settlementAccounts.indexOf(account);
    // if (index >= 0) {
    //   settlementAccounts = settlementAccounts.splice(0, 1);
    //   await sweepConfigService.removeSettlementAccount(
    //     id,
    //     this.props.item.code
    //   );

    // }
  };

  handleOnSaveSettlementAccount = (payload: any) => {
    let settlementAccounts = this.state.settlementAccounts;
    settlementAccounts.push(payload);
    this.setState({ settlementAccounts });
  };

  handleOnSwitchDefaultSettlementAccount = (id: number) => {
    const settlementAccounts = [
      ...this.state.settlementAccounts.map((x) => {
        x.isDefault = false;
        return x;
      }),
    ];
    const settlementAccount = settlementAccounts.find((x) => x.id === id);
    const index = settlementAccounts.indexOf(settlementAccount);
    settlementAccount.isDefault = true;
    settlementAccounts[index] = settlementAccount;
    this.setState({ settlementAccounts });
  };

  validateField = (fieldName: string, type: string, validated: boolean) => {
    let defaultClass = "";
    if (type === "select") {
      defaultClass =
        "custom-select select2 mr-sm-2 form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    } else {
      defaultClass = "form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    }
    return !validated
      ? defaultClass
      : this.state.errors[fieldName]
      ? `${defaultClass} is-invalid`
      : `${defaultClass} is-valid`;
  };

  render() {
    const {
      item,
      showEditModal,
      toggleEditModal,
      allInstitutions,
      allCommercialBanks,
      recovaRecoveryFeeConfigItem,
      recovaCollectionFeeConfigItem,
      thirdPartyRecoveryFeeConfigItem,
      thirdPartyCollectionFeeConfigItem,
    } = this.props;
    const {
      validated,
      submitting,
      errors,
      settlementAccounts,
      showAddConfigModal,
      chargeCustomer,
    } = this.state;

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-tools fa-sm cyan-text mr-3" />
              Edit Sweep Configuration -{" "}
              <span className="font-weight-bold">{item.name}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditInstitutionSweepConfigurationForm
          initialValues={{
            name: item.name,
            code: item.code,
            shortName: item.shortName,
            externalInstitutionCode: item.externalInstitutionCode,
            integrationCode: item.integrationCode,
            category:
              item.integrationCode === "CommercialBankIntegration"
                ? "CommercialBank"
                : "OFI",
            feeIncomeAccount: item.feeIncomeAccount,
            feeExpenseAccount: item.feeExpenseAccount,
            settlementMirrorAccount: item.settlementMirrorAccount,
            receivableAccount: item.receivableAccount,
            loanCollectionsAccount: item.loanCollectionsAccount,
            suspenseAccount: item.suspenseAccount,
            settlementInstitutionCode: item.settlementInstitutionCode,
            nipBankCode: item.nipBankCode,
            appZoneNIPAccountName: item.appZoneNIPAccountName,
            appZoneNIPAccountNo: item.appZoneNIPAccountNo,
            sweepInitiationType: item.sweepInitiationType,
            threshold: item.threshold,
            thresholdBaseLine: item.thresholdBaseLine,
            settlementAccountId: this.state.settlementAccountId,
            maxNoOfSweepsPerDay: item.maxNoOfSweepsPerDay,
            guarantorSweepInitiationType: item.guarantorSweepInitiationType,
            guarantorMoratoriumDays: item.guarantorMoratoriumDays,
            chargeCustomer: item.chargeCustomer,
          }}
          FormComponent={({
            fields: {
              name,
              code,
              appZoneNIPAccountName,
              appZoneNIPAccountNo,
              category,
              externalInstitutionCode,
              feeExpenseAccount,
              feeIncomeAccount,
              integrationCode,
              loanCollectionsAccount,
              nipBankCode,
              receivableAccount,
              settlementInstitutionCode,
              settlementMirrorAccount,
              shortName,
              suspenseAccount,
              sweepInitiationType,
              threshold,
              thresholdBaseLine,
              settlementAccountId,
              maxNoOfSweepsPerDay,
              guarantorSweepInitiationType,
              guarantorMoratoriumDays,
              chargeCustomer,
            },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              {item.integrationCode !== "CommercialBankIntegration" && (
                <AddSettlementAccount
                  allCommercialBanks={allCommercialBanks}
                  allInstitutions={allInstitutions}
                  institution={item}
                  toggleModal={this.handleOnToggleModal}
                  showModal={showAddConfigModal}
                  saveSettlementAccount={this.handleOnSaveSettlementAccount}
                />
              )}
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    <h6
                      className="card-title float-left"
                      style={{ marginBottom: "0px" }}
                    >
                      General Information
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <dl className="row">
                          <DetailItem
                            label="Institution Name"
                            labelSize={7}
                            valueSize={5}
                            value={item.name}
                          />
                          <DetailItem
                            label="Short Name"
                            labelSize={7}
                            valueSize={5}
                            value={item.shortName}
                          />
                          <DetailItem
                            label="Code"
                            labelSize={7}
                            valueSize={5}
                            value={item.code}
                          />
                          {item &&
                          item.integrationCode &&
                          item.integrationCode.includes("BankOne") ? (
                            <DetailItem
                              label="External Institution Code"
                              labelSize={7}
                              valueSize={5}
                              value={item.externalInstitutionCode}
                            />
                          ) : (
                            ""
                          )}
                          <div className="form-group col-md-6 m-0">
                            <label
                              htmlFor="chargeCustomer"
                              className="col-form-label s-12"
                              style={{ fontWeight: 600, fontSize: "14px" }}
                            >
                              Charge Customer For Sweep Transactions
                            </label>
                            <br />
                            <span className="material-switch ml-2">
                              <input
                                id="chargeCustomer"
                                name="chargeCustomer"
                                type="checkbox"
                                onChange={() => this.toggleChargeCustomer()}
                                checked={this.state.chargeCustomer}
                              />
                              <label
                                htmlFor="chargeCustomer"
                                className="bg-primary"
                              ></label>
                            </span>
                          </div>
                        </dl>
                      </div>
                      <div className="col-md-6">
                        <dl className="row">
                          <DetailItem
                            label="Status"
                            labelSize={7}
                            valueSize={5}
                            value={item.status}
                          />
                          <DetailItem
                            label="Sweep Initiation Type"
                            labelSize={7}
                            valueSize={5}
                            value={item.sweepInitiationType}
                          />
                          <DetailItem
                            label="Integration Code"
                            labelSize={7}
                            valueSize={5}
                            value={item.integrationCode}
                          />
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-header card-footer clear-fix"
                    style={{ padding: "8px" }}
                  >
                    <h6
                      className="card-title float-left"
                      style={{ marginBottom: "0px" }}
                    >
                      Transactional GL
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            htmlFor="sweepInitiationType"
                            className="textbox-label"
                          >
                            Sweep Initiation Type
                          </label>
                          <select
                            className={this.validateField(
                              "sweepInitiationType",
                              "select",
                              validated
                            )}
                            onChange={(e) =>
                              onChange(
                                "sweepInitiationType",
                                e.currentTarget.value
                              )
                            }
                            value={sweepInitiationType}
                            name="sweepInitiationType"
                          >
                            <option value="Reactive">Reactive</option>
                            <option value="Proactive">Proactive</option>
                            <option value="Both">Both</option>
                          </select>
                        </div>
                        <div
                          className="invalid-feedback"
                          style={{ visibility: errors.sweepInitiationType }}
                        >
                          {errors.sweepInitiationType}
                        </div>

                        {item.sweepable ? (
                          ""
                        ) : (
                          <Fragment>
                            <div className="form-group">
                              <label
                                htmlFor="feeIncomeAccount"
                                className="textbox-label"
                              >
                                Bank's Fee Income Account
                              </label>
                              <input
                                type="text"
                                name="feeIncomeAccount"
                                className={this.validateField(
                                  "feeIncomeAccount",
                                  "text",
                                  validated
                                )}
                                onChange={(e) =>
                                  onChange(
                                    "feeIncomeAccount",
                                    e.currentTarget.value
                                  )
                                }
                                value={feeIncomeAccount}
                                id="feeIncomeAccount"
                              />
                              <div
                                className="invalid-feedback"
                                style={{ visibility: errors.feeIncomeAccount }}
                              >
                                {errors.feeIncomeAccount}
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="feeExpenseAccount"
                                className="textbox-label"
                              >
                                Recova Fee Expense Account
                              </label>
                              <input
                                type="text"
                                name="feeExpenseAccount"
                                className={this.validateField(
                                  "feeExpenseAccount",
                                  "text",
                                  validated
                                )}
                                onChange={(e) =>
                                  onChange(
                                    "feeExpenseAccount",
                                    e.currentTarget.value
                                  )
                                }
                                value={feeExpenseAccount}
                                id="feeExpenseAccount"
                              />
                              <div
                                className="invalid-feedback"
                                style={{ visibility: errors.feeExpenseAccount }}
                              >
                                {errors.feeExpenseAccount}
                              </div>
                            </div>
                            <div
                              className={
                                category === "CommercialBank"
                                  ? "form-group hide"
                                  : "form-group"
                              }
                            >
                              <label
                                htmlFor="settlementMirrorAccount"
                                className="textbox-label"
                              >
                                Recova Settlement Mirror Account
                              </label>
                              <input
                                type="text"
                                name="settlementMirrorAccount"
                                className={this.validateField(
                                  "settlementMirrorAccount",
                                  "text",
                                  validated
                                )}
                                onChange={(e) =>
                                  onChange(
                                    "settlementMirrorAccount",
                                    e.currentTarget.value
                                  )
                                }
                                value={settlementMirrorAccount}
                                id="settlementMirrorAccount"
                              />
                              <div
                                className="invalid-feedback"
                                style={{
                                  visibility: errors.settlementMirrorAccount,
                                }}
                              >
                                {errors.settlementMirrorAccount}
                              </div>
                            </div>
                            <div
                              className={
                                category === "CommercialBank"
                                  ? "form-group hide"
                                  : "form-group"
                              }
                            >
                              <label
                                htmlFor="receivableAccount"
                                className="textbox-label"
                              >
                                Receivable Account
                              </label>
                              <input
                                type="text"
                                name="receivableAccount"
                                className={this.validateField(
                                  "receivableAccount",
                                  "text",
                                  validated
                                )}
                                onChange={(e) =>
                                  onChange(
                                    "receivableAccount",
                                    e.currentTarget.value
                                  )
                                }
                                value={receivableAccount}
                                id="receivableAccount"
                              />
                              <div
                                className="invalid-feedback"
                                style={{ visibility: errors.receivableAccount }}
                              >
                                {errors.receivableAccount}
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="loanCollectionsAccount"
                                className="textbox-label"
                              >
                                Recova Loan Collections Account
                              </label>
                              <input
                                type="text"
                                name="loanCollectionsAccount"
                                className={this.validateField(
                                  "loanCollectionsAccount",
                                  "text",
                                  validated
                                )}
                                onChange={(e) =>
                                  onChange(
                                    "loanCollectionsAccount",
                                    e.currentTarget.value
                                  )
                                }
                                value={loanCollectionsAccount}
                                id="loanCollectionsAccount"
                              />
                              <div
                                className="invalid-feedback"
                                style={{
                                  visibility: errors.loanCollectionsAccount,
                                }}
                              >
                                {errors.loanCollectionsAccount}
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="suspenseAccount"
                                className="textbox-label"
                              >
                                Recova Suspense Account
                              </label>
                              <input
                                type="text"
                                name="suspenseAccount"
                                className={this.validateField(
                                  "suspenseAccount",
                                  "text",
                                  validated
                                )}
                                onChange={(e) =>
                                  onChange(
                                    "suspenseAccount",
                                    e.currentTarget.value
                                  )
                                }
                                value={suspenseAccount}
                                id="suspenseAccount"
                              />
                              <div
                                className="invalid-feedback"
                                style={{ visibility: errors.suspenseAccount }}
                              >
                                {errors.suspenseAccount}
                              </div>
                            </div>
                          </Fragment>
                        )}
                        <div className="form-group">
                          <label htmlFor="threshold" className="textbox-label">
                            Threshold
                          </label>
                          <input
                            type="text"
                            name="threshold"
                            className={this.validateField(
                              "threshold",
                              "text",
                              validated
                            )}
                            onChange={(e) =>
                              onChange("threshold", e.currentTarget.value)
                            }
                            value={threshold}
                            id="threshold"
                          />
                          <div
                            className="invalid-feedback"
                            style={{
                              visibility: errors.threshold,
                            }}
                          >
                            {errors.threshold}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {/* <div className="form-group">
                    <label htmlFor="status" className="textbox-label">
                      STATUS
                    </label>
                    <select
                      className="browser-default custom-select"
                      onChange={this.handleOnInputChange}
                      value={status}
                      name="status"
                    >
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                  </div> */}
                        <div
                          className={
                            category === "CommercialBank"
                              ? "form-group"
                              : "form-group hide"
                          }
                        >
                          <label
                            htmlFor="nipBankCode"
                            className="textbox-label"
                          >
                            NIP Bank Code
                          </label>
                          <input
                            type="text"
                            name="nipBankCode"
                            className={this.validateField(
                              "nipBankCode",
                              "text",
                              validated
                            )}
                            onChange={(e) =>
                              onChange("nipBankCode", e.currentTarget.value)
                            }
                            value={nipBankCode}
                            id="nipBankCode"
                          />
                          <div
                            className="invalid-feedback"
                            style={{ visibility: errors.nipBankCode }}
                          >
                            {errors.nipBankCode}
                          </div>
                        </div>
                        {/* <div
                          className={
                            category === "CommercialBank"
                              ? "form-group"
                              : "form-group hide"
                          }
                        >
                          <label
                            htmlFor="appZoneNIPAccountName"
                            className="textbox-label"
                          >
                            APPZONE NIP ACCOUNT NAME
                          </label>
                          <input
                            type="text"
                            name="appZoneNIPAccountName"
                            className={this.validateField(
                              "appZoneNIPAccountName",
                              "text",
                              validated
                            )}
                            onChange={(e) =>
                              onChange(
                                "appZoneNIPAccountName",
                                e.currentTarget.value
                              )
                            }
                            value={appZoneNIPAccountName}
                            id="appZoneNIPAccountName"
                          />
                          <div
                            className="invalid-feedback"
                            style={{ visibility: errors.appZoneNIPAccountName }}
                          >
                            {errors.appZoneNIPAccountName}
                          </div>
                        </div> */}
                        <div
                          className={
                            category === "CommercialBank"
                              ? "form-group"
                              : "form-group hide"
                          }
                        >
                          <label
                            htmlFor="appZoneNIPAccountNo"
                            className="textbox-label"
                          >
                            AppZone Income Account Number
                          </label>
                          <input
                            type="text"
                            name="appZoneNIPAccountNo"
                            className={this.validateField(
                              "appZoneNIPAccountNo",
                              "text",
                              validated
                            )}
                            onChange={(e) =>
                              onChange(
                                "appZoneNIPAccountNo",
                                e.currentTarget.value
                              )
                            }
                            value={appZoneNIPAccountNo}
                            id="appZoneNIPAccountNo"
                          />
                          <div
                            className="invalid-feedback"
                            style={{ visibility: errors.appZoneNIPAccountNo }}
                          >
                            {errors.appZoneNIPAccountNo}
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="thresholdBaseLine"
                            className="textbox-label"
                          >
                            Threshold BaseLine
                          </label>
                          <input
                            type="text"
                            name="thresholdBaseLine"
                            className={this.validateField(
                              "thresholdBaseLine",
                              "text",
                              validated
                            )}
                            onChange={(e) => {
                              // console.log();
                              if (
                                !isNaN(
                                  Number(
                                    e.currentTarget.value
                                      .replace(",", "")
                                      .replace(".", "")
                                  )
                                )
                              ) {
                                onChange(
                                  "thresholdBaseLine",
                                  e.currentTarget.value
                                    .replace(",", "")
                                    .replace(".", "")
                                );
                              }
                            }}
                            value={textFormatService.convertKoboToNaira(
                              Number(thresholdBaseLine),
                              2
                            )}
                            id="thresholdBaseLine"
                          />
                          <div
                            className="invalid-feedback"
                            style={{
                              visibility: errors.thresholdBaseLine,
                            }}
                          >
                            {errors.thresholdBaseLine}
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="maxNoOfSweepsPerDay"
                            className="textbox-label"
                          >
                            Max. No Of Sweeps Per Day
                          </label>
                          <input
                            type="number"
                            name="maxNoOfSweepsPerDay"
                            className={this.validateField(
                              "maxNoOfSweepsPerDay",
                              "number",
                              validated
                            )}
                            onChange={(e) =>
                              onChange(
                                "maxNoOfSweepsPerDay",
                                e.currentTarget.value
                              )
                            }
                            value={maxNoOfSweepsPerDay}
                            id="maxNoOfSweepsPerDay"
                          />
                          <div
                            className="invalid-feedback"
                            style={{
                              visibility: errors.maxNoOfSweepsPerDay,
                            }}
                          >
                            {errors.maxNoOfSweepsPerDay}
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="guarantorMoratoriumDays"
                            className="textbox-label"
                          >
                            Guarantor Moratorium Days
                          </label>
                          <input
                            type="number"
                            name="guarantorMoratoriumDays"
                            className={this.validateField(
                              "guarantorMoratoriumDays",
                              "number",
                              validated
                            )}
                            onChange={(e) =>
                              onChange(
                                "guarantorMoratoriumDays",
                                e.currentTarget.value
                              )
                            }
                            value={guarantorMoratoriumDays}
                            id="guarantorMoratoriumDays"
                          />
                          <div
                            className="invalid-feedback"
                            style={{
                              visibility: errors.guarantorMoratoriumDays,
                            }}
                          >
                            {errors.guarantorMoratoriumDays}
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="guarantorSweepInitiationType"
                            className="textbox-label"
                          >
                            Guarantor Sweep Initiation Type
                          </label>
                          <select
                            className={this.validateField(
                              "guarantorSweepInitiationType",
                              "select",
                              validated
                            )}
                            onChange={(e) =>
                              onChange(
                                "guarantorSweepInitiationType",
                                e.currentTarget.value
                              )
                            }
                            value={guarantorSweepInitiationType}
                            name="guarantorSweepInitiationType"
                          >
                            <option value="BeforeLoanExpiration">
                              Before Loan Expiration
                            </option>
                            <option value="AfterLoanExpiration">
                              After Loan Expiration
                            </option>
                          </select>
                        </div>
                        <div
                          className="invalid-feedback"
                          style={{
                            visibility: errors.guarantorSweepInitiationType,
                          }}
                        >
                          {errors.guarantorSweepInitiationType}
                        </div>
                      </div>
                    </div>
                  </div>
                  {item.integrationCode !== "CommercialBankIntegration" ? (
                    <React.Fragment>
                      <div
                        className="card-header card-footer clear-fix"
                        style={{ padding: "8px" }}
                      >
                        <h6
                          className="card-title float-left"
                          style={{ marginBottom: "0px" }}
                        >
                          Settlement Account
                        </h6>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-12">
                            {/* <div className="box"> */}
                            <div className="box-header with-border">
                              <h3 className="box-title">Settlement Accounts</h3>
                            </div>
                            <div
                              className="no-padding table-responzive"
                              style={{ overflowY: "scroll" }}
                            >
                              {/* <br /> */}
                              <button
                                type="button"
                                className="btn btn-outline-primary btn-xs my-4"
                                onClick={this.handleOnToggleModal}
                              >
                                <i className="icon-plus mr-2"></i>Add Settlement
                                Account
                              </button>
                              {/* <br /> */}
                              <table className="table table-hover table-content table-sm table-bordered table-striped mb-0">
                                <tbody>
                                  <tr>
                                    <th
                                      style={{
                                        // width: "5px",
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                        fontSize: "small",
                                      }}
                                    >
                                      #
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      Institution
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      Account Number
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      Days After Settlement
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      Default Account
                                    </th>
                                  </tr>
                                  {settlementAccounts.length === 0 ? (
                                    <tr>
                                      <td
                                        className="font-weight-normal font-italic text-truncate"
                                        colSpan={5}
                                        style={{
                                          textAlign: "center",
                                          color: "#FE825E",
                                        }}
                                      >
                                        No settlement accounts configured
                                      </td>
                                    </tr>
                                  ) : (
                                    <React.Fragment>
                                      {settlementAccounts.map((account) => (
                                        <tr key={account.id}>
                                          <td
                                            style={{
                                              width: "5px",
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                              fontSize: "small",
                                            }}
                                          >
                                            {settlementAccounts.indexOf(
                                              account
                                            ) + 1}
                                          </td>
                                          <td
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                              fontSize: "small",
                                            }}
                                          >
                                            {
                                              allCommercialBanks.find(
                                                (x) =>
                                                  x.bankCode === account.cbnCode
                                              )?.bankName
                                            }
                                          </td>
                                          <td
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                              fontSize: "small",
                                            }}
                                          >
                                            {account.accountNo}
                                          </td>
                                          <td
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                              fontSize: "small",
                                            }}
                                          >
                                            {
                                              account.noOfDaysAfterFinalSettlement
                                            }
                                          </td>
                                          <td>
                                            {!account.isDefault && (
                                              <button
                                                type="button"
                                                className="btn btn-outline-danger btn-xs mr-2"
                                                onClick={() =>
                                                  this.handleOnDeleteSettlementAccount(
                                                    account.id
                                                  )
                                                }
                                              >
                                                <i
                                                  className="icon-delete mr-2"
                                                  style={{ cursor: "pointer" }}
                                                ></i>{" "}
                                                Remove
                                              </button>
                                            )}
                                            <label
                                              className="switch"
                                              style={{ height: "22px" }}
                                            >
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="defaultSettlementAccount"
                                                name="defaultSettlementAccount"
                                                defaultChecked={
                                                  account.isDefault
                                                }
                                                disabled={account.isDefault}
                                                onChange={() =>
                                                  this.handleOnSwitchDefaultSettlementAccount(
                                                    account.id
                                                  )
                                                }
                                              />
                                              <span className="slider round"></span>
                                            </label>
                                          </td>
                                        </tr>
                                      ))}
                                    </React.Fragment>
                                  )}
                                </tbody>
                              </table>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                  <div
                    className="card-header card-footer clear-fix"
                    style={{ padding: "8px" }}
                  >
                    {_.isEmpty(item) ? (
                      <Skeleton width={200} />
                    ) : (
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        Sweep Fee
                      </h6>
                    )}
                  </div>
                  <div>
                    <Accordion defaultActiveKey="">
                      <div>
                        <Card>
                          <ContextAwareToggle
                            eventKey={`0`}
                            header={
                              <Fragment>
                                <i
                                  className="fa fa-info-circle"
                                  aria-hidden="true"
                                  style={{ color: "#878a8a" }}
                                ></i>{" "}
                                Recova
                              </Fragment>
                            }
                          ></ContextAwareToggle>
                          <Accordion.Collapse eventKey={`0`}>
                            <Fragment>
                              <EditInstitutionRecovaFeeConfiguration
                                toggleEditModal={toggleEditModal}
                                item={item}
                                recovaFeeConfigItem={
                                  recovaRecoveryFeeConfigItem
                                }
                              />
                            </Fragment>
                          </Accordion.Collapse>
                        </Card>
                      </div>
                    </Accordion>
                    {Object.keys(thirdPartyRecoveryFeeConfigItem).map(function (
                      key,
                      index
                    ) {
                      return (
                        <Accordion defaultActiveKey="">
                          <div key={key}>
                            <Card>
                              <ContextAwareToggle
                                eventKey={`0`}
                                header={
                                  <Fragment>
                                    <i
                                      className="fa fa-info-circle"
                                      aria-hidden="true"
                                      style={{ color: "#878a8a" }}
                                    ></i>{" "}
                                    {
                                      thirdPartyRecoveryFeeConfigItem[key]
                                        .thirdParty
                                    }
                                  </Fragment>
                                }
                              ></ContextAwareToggle>
                              <Accordion.Collapse eventKey={`0`}>
                                <Fragment>
                                  <EditInstitutionThirdPartyFeeConfiguration
                                    toggleEditModal={toggleEditModal}
                                    item={item}
                                    thirdPartyFeeConfigItem={
                                      thirdPartyRecoveryFeeConfigItem[key]
                                    }
                                  />
                                </Fragment>
                              </Accordion.Collapse>
                            </Card>
                          </div>
                        </Accordion>
                      );
                    })}
                  </div>
                  <hr />
                  <div
                    className="card-header card-footer clear-fix"
                    style={{ padding: "8px" }}
                  >
                    {_.isEmpty(item) ? (
                      <Skeleton width={200} />
                    ) : (
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        Collections Fee
                      </h6>
                    )}
                  </div>
                  <div>
                    <Accordion defaultActiveKey="">
                      <div>
                        <Card>
                          <ContextAwareToggle
                            eventKey={`0`}
                            header={
                              <Fragment>
                                <i
                                  className="fa fa-info-circle"
                                  aria-hidden="true"
                                  style={{ color: "#878a8a" }}
                                ></i>{" "}
                                Recova
                              </Fragment>
                            }
                          ></ContextAwareToggle>
                          <Accordion.Collapse eventKey={`0`}>
                            <Fragment>
                              <EditInstitutionRecovaFeeConfiguration
                                toggleEditModal={toggleEditModal}
                                item={item}
                                recovaFeeConfigItem={
                                  recovaCollectionFeeConfigItem
                                }
                              />
                            </Fragment>
                          </Accordion.Collapse>
                        </Card>
                      </div>
                    </Accordion>
                    {Object.keys(thirdPartyCollectionFeeConfigItem).map(
                      function (key, index) {
                        return (
                          <Accordion defaultActiveKey="">
                            <div key={key}>
                              <Card>
                                <ContextAwareToggle
                                  eventKey={`0`}
                                  header={
                                    <Fragment>
                                      <i
                                        className="fa fa-info-circle"
                                        aria-hidden="true"
                                        style={{ color: "#878a8a" }}
                                      ></i>{" "}
                                      {
                                        thirdPartyCollectionFeeConfigItem[key]
                                          .thirdParty
                                      }
                                    </Fragment>
                                  }
                                ></ContextAwareToggle>
                                <Accordion.Collapse eventKey={`0`}>
                                  <Fragment>
                                    <EditInstitutionThirdPartyFeeConfiguration
                                      toggleEditModal={toggleEditModal}
                                      item={item}
                                      thirdPartyFeeConfigItem={
                                        thirdPartyCollectionFeeConfigItem[key]
                                      }
                                    />
                                  </Fragment>
                                </Accordion.Collapse>
                              </Card>
                            </div>
                          </Accordion>
                        );
                      }
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.UPDATE,
                      "Sweep Configuration",
                      () => {
                        this.onFormSubmit(
                          {
                            name,
                            code,
                            appZoneNIPAccountName,
                            appZoneNIPAccountNo,
                            category,
                            externalInstitutionCode,
                            feeExpenseAccount,
                            feeIncomeAccount,
                            integrationCode,
                            loanCollectionsAccount,
                            nipBankCode,
                            receivableAccount,
                            settlementInstitutionCode,
                            settlementMirrorAccount,
                            shortName,
                            suspenseAccount,
                            sweepInitiationType,
                            threshold,
                            thresholdBaseLine,
                            settlementAccountId,
                            maxNoOfSweepsPerDay,
                            guarantorSweepInitiationType,
                            guarantorMoratoriumDays,
                          },
                          onReloadFields
                        );
                      }
                    );
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></EditInstitutionSweepConfigurationForm>
      </Modal>
    );
  }
}

export default EditInstitutionSweepConfiguration;
