import React, { useContext, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import swal from "sweetalert";
import _ from "lodash";
import {
  Modal,
  Card,
  useAccordionToggle,
  AccordionContext,
  Accordion,
  Button,
  OverlayTrigger,
  Tooltip,
  Dropdown,
} from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import textFormatService, {
  generateGUID,
} from "../../../services/utility/textFormatService";
import settlementService from "../../../services/sweep/settlementService";
import { toast } from "react-toastify";

function ContextAwareToggle({ eventKey, header }) {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      <b>{header}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
}

// const CustomToggle = React.forwardRef(({ children }, ref) => (
//   <i
//     style={{
//       cursor: "pointer",
//     }}
//     // onClick={() => {
//     //   console.log("just got clicked");
//     //   this.getSettlementEntryAction(item);
//     // }}
//     className="icon icon-more_horiz"
//   ></i>
// ));

interface SettlementsAndPayoutsReportDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  item: any;
}

interface SettlementsAndPayoutsReportDetailState {}

class SettlementsAndPayoutsReportDetail extends React.Component<
  SettlementsAndPayoutsReportDetailProps,
  SettlementsAndPayoutsReportDetailState
> {
  constructor(props: SettlementsAndPayoutsReportDetailProps) {
    super(props);
    this.state = {};
  }

  togglePushSettlementEntryModal = async () => {};

  async pushFoManualSettlement() {
    const { item } = this.props;
    try {
      swal({
        title: "Confirm!",
        text: `Are you sure you want to Push this transaction for Manual Settlement?`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode: true,
      }).then(async (resp) => {
        if (resp) {
          // console.log("not gonna cancel the mandate");
        } else {
          console.log(
            "pushing item: ",
            this.props.item,
            "for manual settlement"
          );
          const payload: any = {};
          payload.institution = item.settlementPayout.lenderInstitutionName;
          payload.settlementEntryId = item.settlementPayout.id;
          payload.orderId = item.settlementEntries.map(
            (x) =>
              x.transactionId === item.settlementPayout.transactionReference
          )[0].orderId;
          payload.transactionReference =
            item.settlementPayout.transactionReference;
          payload.paymentDueDate = item.settlementPayout.dateCreated;
          payload.amountDue = item.settlementPayout.settlementAmount;
          payload.sourceInstitution = "source institution";
          payload.sourceAccount = "source account";
          payload.destinationInstitution = "destination institution";
          payload.destinationAccount = "destination account";

          await settlementService.pushForManualSettlement(payload);

          toast.info(
            `Transaction ${item.settlementPayout.transactionReference} successfully pushed for manual settlement! `,
            {
              autoClose: 6000,
              type: toast.TYPE.DEFAULT,
              hideProgressBar: false,
            }
          );
          this.props.toggleDetailsModal();
        }
      });
    } catch (error) {
      // console.log("error: ", error);
      toast.error(`Unable to cancel mandate. `, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
    }
  }

  render() {
    const { showDetailsModal, toggleDetailsModal, item } = this.props;
    type CustomToggleProps = {
      children?: React.ReactNode;
      onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {};
    };

    // The forwardRef is important!!
    // Dropdown needs access to the DOM node in order to position the Menu
    const CustomToggle = React.forwardRef(
      (props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => (
        <a
          href="#!"
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            props.onClick(e);
          }}
        >
          {props.children}
          <i
            style={{
              cursor: "pointer",
              paddingLeft: "5px",
            }}
            // onClick={() => {
            //   console.log(
            //     "just got clicked"
            //   );
            //   this.getSettlementEntryAction(
            //     item
            //   );
            // }}
            className="icon icon-more_horiz"
          ></i>
          {/* <span style={{ paddingLeft: "5px" }}>&#x25bc;</span> */}
        </a>
      )
    );

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="fas fa-receipt fa-sm s-18 mr-3" />
                Pre-Settlement Report Detail
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Fragment>
            <div className="card">
              {/* General Information */}
              <Accordion defaultActiveKey="0">
                <div>
                  <Card>
                    <ContextAwareToggle
                      eventKey={`0`}
                      header={
                        <React.Fragment>
                          <i
                            className="fa fa-info-circle"
                            aria-hidden="true"
                            style={{ color: "#878a8a" }}
                          ></i>{" "}
                          General Information
                        </React.Fragment>
                      }
                    ></ContextAwareToggle>
                    <Accordion.Collapse eventKey={`0`}>
                      <Card.Body>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              {_.isEmpty(item) ? (
                                <Skeleton count={5} width={300} />
                              ) : (
                                <dl className="row">
                                  <DetailItem
                                    label="Lender Institution"
                                    labelSize={7}
                                    valueSize={5}
                                    value={
                                      item.settlementPayout
                                        .lenderInstitutionName
                                    }
                                  />
                                  <DetailItem
                                    label="Settlement Institution"
                                    labelSize={7}
                                    valueSize={5}
                                    value={
                                      item.settlementPayout
                                        .settlementInstitution
                                    }
                                  />
                                  <DetailItem
                                    label="Service Fee"
                                    labelSize={7}
                                    valueSize={5}
                                    value={textFormatService.convertKoboToNaira(
                                      item.settlementPayout.serviceFee,
                                      2
                                    )}
                                  />
                                  <DetailItem
                                    label="Total Amount Attempted"
                                    labelSize={7}
                                    valueSize={5}
                                    value={textFormatService.convertKoboToNaira(
                                      item.settlementPayout
                                        .totalAmountAttempted,
                                      2
                                    )}
                                  />
                                  <DetailItem
                                    label="Total Amount Debited"
                                    labelSize={7}
                                    valueSize={5}
                                    value={textFormatService.convertKoboToNaira(
                                      item.settlementPayout.totalAmountDebited,
                                      2
                                    )}
                                  />
                                  <DetailItem
                                    label="Settlement Amount"
                                    labelSize={7}
                                    valueSize={5}
                                    value={textFormatService.convertKoboToNaira(
                                      item.settlementPayout.settlementAmount,
                                      2
                                    )}
                                  />
                                  <DetailItem
                                    label="Settlement Account"
                                    labelSize={7}
                                    valueSize={5}
                                    value={
                                      item.settlementPayout.settlementAccount
                                    }
                                  />
                                </dl>
                              )}
                            </div>
                            <div className="col-md-6">
                              {_.isEmpty(item) ? (
                                <Skeleton count={5} width={300} />
                              ) : (
                                <dl className="row">
                                  {/* <DetailItem
                                    label="Transactions Count"
                                    value={
                                      item.settlementPayout.transactionsCount
                                    }
                                  /> */}
                                  <DetailItem
                                    label="Date Generated"
                                    labelSize={7}
                                    valueSize={5}
                                    value={textFormatService.getDateForTable(
                                      item.settlementPayout.dateCreated
                                    )}
                                  />
                                  <DetailItem
                                    label="Channel"
                                    labelSize={7}
                                    valueSize={5}
                                    value={item.settlementPayout.channel}
                                  />
                                  <DetailItem
                                    label="Sweep Episode UniqueId"
                                    labelSize={7}
                                    valueSize={5}
                                    value={
                                      item.settlementPayout.sweepEpisodeUniqueId
                                    }
                                  />
                                  <DetailItem
                                    label="Transaction Reference"
                                    labelSize={7}
                                    valueSize={5}
                                    value={
                                      item.settlementPayout.transactionReference
                                    }
                                  />
                                  <DetailItem
                                    label="Status"
                                    labelSize={7}
                                    valueSize={5}
                                    value={(() => {
                                      switch (item.settlementPayout.status) {
                                        case "Pending":
                                          return (
                                            <span className="badge badge-light">
                                              {item
                                                ? item.settlementPayout.status
                                                : ""}
                                            </span>
                                          );
                                        case "PushedAndAwaitingResponse":
                                          return (
                                            <span className="badge badge-info">
                                              {item ? "Push" : ""}
                                            </span>
                                          );
                                        case "Successful":
                                          return (
                                            <span className="badge badge-success">
                                              {item
                                                ? item.settlementPayout.status
                                                : ""}
                                            </span>
                                          );
                                        case "PushedForManualSettlement":
                                          return (
                                            <span className="badge badge-dark">
                                              {item
                                                ? item.settlementPayout.status
                                                : ""}
                                            </span>
                                          );
                                        case "Failed":
                                          return (
                                            <span className="badge badge-danger">
                                              {item
                                                ? item.settlementPayout.status
                                                : ""}
                                            </span>
                                          );
                                        default:
                                          return null;
                                      }
                                    })()}
                                  />
                                </dl>
                              )}
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </div>
              </Accordion>
              {/* Direct Debit Transactions */}
              <Accordion defaultActiveKey="">
                <div>
                  <Card>
                    <ContextAwareToggle
                      eventKey={`0`}
                      header={
                        <React.Fragment>
                          <i
                            className="fa fa-info-circle"
                            aria-hidden="true"
                            style={{ color: "#878a8a" }}
                          ></i>{" "}
                          Direct Debit Transactions
                          <span className="badge badge-success ml-2">
                            {showDetailsModal &&
                              `${item.transactions.length} transactions`}
                          </span>
                        </React.Fragment>
                      }
                    ></ContextAwareToggle>
                    <Accordion.Collapse eventKey={`0`}>
                      <Card.Body>
                        <div className="table-responsive box-body no-padding text-nowrap">
                          <table className="table table-sm table-bordered table-striped table-hover">
                            <tbody>
                              <tr>
                                <th>#</th>
                                <th>Customer Name</th>
                                <th>Loan Reference</th>
                                <th>Debited Institution</th>
                                <th>Amount Attempted</th>
                                <th>Amount Debited</th>
                                <th>Transaction Time</th>
                                <th>Repayment</th>
                              </tr>
                              {showDetailsModal &&
                                item?.transactions.map((item, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}.</td>
                                    <td>{item.mandate.customerName}</td>
                                    <td>{item.loanAccountNo}</td>
                                    <td>{item.debitedInstitution}</td>
                                    <td align="right">
                                      {textFormatService.convertKoboToNaira(
                                        item.amountAttempted,
                                        2
                                      )}
                                    </td>
                                    <td align="right">
                                      {textFormatService.convertKoboToNaira(
                                        item.amountDebited,
                                        2
                                      )}
                                    </td>
                                    <td>
                                      {textFormatService.getDateForTable(
                                        item.timeSwept
                                      )}
                                    </td>
                                    <td>
                                      {/* {item.repaymentType} */}
                                      {(() => {
                                        switch (item.repaymentType) {
                                          case "Recovery":
                                            console.log("check 2");
                                            return (
                                              <span className="badge badge-default">
                                                {item.repaymentType}
                                              </span>
                                            );
                                          case "Collection":
                                            console.log("check 1");
                                            return (
                                              <span className="badge badge-light">
                                                {item.repaymentType}
                                              </span>
                                            );
                                          default:
                                            console.log("check 3");
                                            return (
                                              <span className="badge text-white bg-red">
                                                {item.repaymentType}
                                              </span>
                                            );
                                        }
                                      })()}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </div>
              </Accordion>
              {/* Settlement Entries */}
              <Accordion defaultActiveKey="0">
                <div>
                  <Card>
                    <ContextAwareToggle
                      eventKey={`0`}
                      header={
                        <React.Fragment>
                          <i
                            className="fa fa-info-circle"
                            aria-hidden="true"
                            style={{ color: "#878a8a" }}
                          ></i>{" "}
                          Settlement Entries
                          <span className="badge badge-success ml-2">
                            {showDetailsModal &&
                              `${item.settlementEntries.length} transactions`}
                          </span>
                        </React.Fragment>
                      }
                    ></ContextAwareToggle>
                    <Accordion.Collapse eventKey={`0`}>
                      <Card.Body>
                        <div className="table-responsive box-body no-padding text-nowrap">
                          <table className="table table-sm table-bordered table-striped table-hover">
                            <tbody>
                              <tr>
                                <th>#</th>
                                <th>Type</th>
                                <th>Dr.</th>
                                <th>Cr.</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Transaction Id</th>
                                <th>Attempts</th>
                                <th>Generated On</th>
                                <th>Settlement Time</th>
                                <th>Actions</th>
                              </tr>
                              {showDetailsModal &&
                                [...item?.settlementEntries]
                                  .reverse()
                                  .map((item, index) => (
                                    <tr key={index}>
                                      <td>{index + 1}.</td>
                                      <td>{item.settlementEntryType}</td>
                                      <td>{item.debitAccount}</td>
                                      <td>{item.creditAccount}</td>
                                      <td align="right">
                                        {textFormatService.convertKoboToNaira(
                                          item.amount,
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {(() => {
                                          switch (item.status) {
                                            case "Pending":
                                              return (
                                                <span className="badge badge-light">
                                                  {item ? item.status : ""}
                                                </span>
                                              );
                                            case "PushedAndAwaitingResponse":
                                              return (
                                                <span className="badge badge-info">
                                                  {item ? "Pushed" : ""}
                                                </span>
                                              );
                                            case "PushedForManualSettlement":
                                              return (
                                                <span className="badge badge-daark">
                                                  {item
                                                    ? "PushedForManualSettlement"
                                                    : ""}
                                                </span>
                                              );
                                            case "Successful":
                                              return (
                                                <span className="badge badge-success">
                                                  {item ? item.status : ""}
                                                </span>
                                              );
                                            case "Failed":
                                              return (
                                                <span className="badge badge-danger">
                                                  {item ? item.status : ""}
                                                </span>
                                              );
                                            default:
                                              return null;
                                          }
                                        })()}
                                      </td>
                                      <td>{item.transactionId}</td>
                                      <td>{item.retryCounter}</td>
                                      <td>
                                        {textFormatService.getDateForTable(
                                          item.generatedOn
                                        )}
                                      </td>
                                      <td>
                                        {textFormatService.getDateForTable(
                                          item.settlementTime
                                        )}
                                      </td>
                                      <td align="center">
                                        <OverlayTrigger
                                          key={generateGUID()}
                                          placement={"right"}
                                          overlay={
                                            <Tooltip id={generateGUID()}>
                                              {"View Details"}
                                            </Tooltip>
                                          }
                                        >
                                          <Dropdown>
                                            <Dropdown.Toggle
                                              as={CustomToggle}
                                              id="dropdown-custom-components"
                                            ></Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                Action
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-2">
                                                Another action
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-3">
                                                Something else
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </OverlayTrigger>
                                      </td>
                                    </tr>
                                  ))}
                            </tbody>
                          </table>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </div>
              </Accordion>
            </div>
          </Fragment>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
              {item.settlementPayout.status === "Failed" ? (
                <Button
                  size="sm"
                  variant="outline-primary"
                  onClick={() => {
                    this.pushFoManualSettlement();
                    // this.props.toggleDetailsModal();
                  }}
                >
                  <i className="icon-send-o mr-3" />
                  Push For Manual Settlement
                </Button>
              ) : (
                ""
              )}
            </React.Fragment>
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  private getSettlementEntryAction(item) {
    switch (item.status) {
      case "Pending":
        return "Pending";
      case "PushedAndAwaitingResponse":
        return "Push";
      case "Successful":
        return "Successful";
      case "Failed":
        return "Failed";
      default:
        return "Default";
    }
  }
}

export default SettlementsAndPayoutsReportDetail;
