import React from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import activityTrailService from "../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../components/BaseFormComponent";
import disputeValidationService from "../../services/dispute/disputeValidationService";
import { getCurrentUserSession } from "../../services/auth/authService";
import disputeConditionService from "../../services/dispute/disputeConditionService";
import { ActionTypes } from "../../enums/actionTypes";
import { decodeToken } from "react-jwt";

interface EditDisputeConditionFields {
  name: string;
  description: string;
  code: string;
  disputeFilingTimeLimit: string;
  maxResolutionTATInterval: string;
  maxResolutionTAT: string;
}

class EditDisputeConditionForm extends BaseFormComponent<EditDisputeConditionFields> { }

interface IEditDisputeConditionProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
  documents: any;
}
interface IEditDisputeConditionState {
  disputeConditionDocuments: any;
  ownUpdate: boolean;
}

class EditDisputeCondition extends React.Component<
  IEditDisputeConditionProps,
  IBaseFormState & IEditDisputeConditionState
> {
  constructor(props: IEditDisputeConditionProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      disputeConditionDocuments: [],
      ownUpdate: false,
    };
  }

  static getDerivedStateFromProps(
    nextProps: IEditDisputeConditionProps,
    prevState
  ) {
    if (prevState.ownUpdate) {
      return {
        disputeConditionDocuments: prevState.disputeConditionDocuments,
        ownUpdate: false,
      };
    }
    let disputeConditionDocuments: any = [];
    if (!_.isEmpty(nextProps.item)) {
      disputeConditionDocuments = _.map(
        nextProps.item.disputeConditionDocuments,
        "id"
      );
    }
    return {
      disputeConditionDocuments: disputeConditionDocuments,
    };
  }

  onFormSubmit(
    fields: EditDisputeConditionFields,
    onReloadFieldsCB: any
  ): boolean {
    try {
      this.setState(
        {
          errors:
            disputeValidationService.validateEditDisputeConditionForm(fields),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (
    fields: EditDisputeConditionFields,
    onReloadFieldsCB: any
  ) => {
    try {
      this.setState({ submitting: true });
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const payload = this.props.item;
      //   console.log("For checks::", payload);
      payload.Id = payload.id;
      payload.name = fields.name;
      payload.description = fields.description;
      payload.code = fields.code;
      payload.institutionId = decodedToken?.["InstitutionId"];
      payload.assignedDisputeConditionDocuments =
        this.state.disputeConditionDocuments;
      payload.maxResolutionTAT = fields.maxResolutionTAT;
      payload.description = fields.description;
      payload.disputeFilingTimeLimit = fields.disputeFilingTimeLimit;

      const response = await disputeConditionService.updateDisputeCondition(
        payload
      );

      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit Dispute Condition",
          description: `Edited details for Dispute Condition ${fields.name}`,
        });

        if (response.data.isLoggedForApproval === true) {
          toast.warn("Action has been successfully logged for Approval!", {
            autoClose: 6000,
            type: toast.TYPE.WARNING,
            position: "top-center",
            hideProgressBar: false,
          });
        } else {
          toast.info(`Dispute Condition ${fields.name} Edited successfully! `, {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          });
        }
      }
      onReloadFieldsCB();
      this.setState({ submitting: false }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      // console.log("submission error: ", error);
      // this.setState({
      //   errors: "Invalid Username or Password",
      // });
      this.setState({ submitting: false });
    }
  };

  handleOnDisputeConditionDocumentChange = (e, item) => {
    let disputeConditionDocuments = [...this.state.disputeConditionDocuments];
    // console.log("before", disputeConditionDocuments);
    if (disputeConditionDocuments.indexOf(item.id) !== -1) {
      disputeConditionDocuments.splice(
        disputeConditionDocuments.indexOf(item.id),
        1
      );
    } else {
      disputeConditionDocuments.push(item.id);
    }
    // console.log("after", disputeConditionDocuments);
    this.setState({ disputeConditionDocuments, ownUpdate: true });
  };

  validateField = (fieldName: string, type: string, validated: boolean) => {
    let defaultClass = "";
    if (type === "select") {
      defaultClass =
        "custom-select select2 mr-sm-2 form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    } else {
      defaultClass = "form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    }
    return !validated
      ? defaultClass
      : this.state.errors[fieldName]
        ? `${defaultClass} is-invalid`
        : `${defaultClass} is-valid`;
  };

  render() {
    const { item, documents, showEditModal, toggleEditModal } = this.props;
    const { validated, submitting, errors, disputeConditionDocuments } =
      this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fab fa-first-order-alt fa-sm cyan-text mr-3" />
              Edit Dispute Condition -{" "}
              <span className="font-weight-bold">{item.name}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditDisputeConditionForm
          initialValues={{
            name: item.name,
            description: item.description,
            code: item.code,
            disputeFilingTimeLimit: item.disputeFilingTimeLimit,
            maxResolutionTATInterval: item.maxResolutionTATInterval,
            maxResolutionTAT: item.maxResolutionTAT,
          }}
          FormComponent={({
            fields: {
              name,
              description,
              code,
              maxResolutionTAT,
              maxResolutionTATInterval,
              disputeFilingTimeLimit,
            },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    <h6
                      className="card-title float-left"
                      style={{ marginBottom: "0px" }}
                    >
                      General Information
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="form-group row">
                          <label
                            htmlFor="name"
                            className="col-md-3 col-form-label"
                          >
                            Name:
                          </label>
                          <div className="col-md-9">
                            <input
                              autoFocus
                              type="text"
                              className={this.validateField(
                                "name",
                                "text",
                                validated
                              )}
                              id="name"
                              name="name"
                              value={name}
                              onChange={(e) =>
                                onChange("name", e.currentTarget.value)
                              }
                            />
                            <div
                              className="invalid-feedback"
                              style={{ visibility: errors.name }}
                            >
                              {errors.name}
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="code"
                            className="col-md-3 col-form-label"
                          >
                            Code:
                          </label>
                          <div className="col-md-9">
                            <input
                              type="text"
                              className={this.validateField(
                                "code",
                                "text",
                                validated
                              )}
                              id="code"
                              name="code"
                              value={code}
                              onChange={(e) =>
                                onChange("code", e.currentTarget.value)
                              }
                            />
                            <div
                              className="invalid-feedback"
                              style={{ visibility: errors.code }}
                            >
                              {errors.code}
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="disputeFilingTimeLimit"
                            className="col-md-3 col-form-label"
                          >
                            Dispute Filing Time Limit:
                          </label>
                          <div className="col-md-9">
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                className="custom-control-input"
                                id="_3_Days"
                                name="disputeFilingTimeLimit"
                                onChange={(e) =>
                                  onChange(
                                    "disputeFilingTimeLimit",
                                    e.currentTarget.id
                                  )
                                }
                                checked={disputeFilingTimeLimit === "_3_Days"}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="_3_Days"
                              >
                                3 Days
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                className="custom-control-input"
                                id="_180_Days"
                                name="disputeFilingTimeLimit"
                                onChange={(e) =>
                                  onChange(
                                    "disputeFilingTimeLimit",
                                    e.currentTarget.id
                                  )
                                }
                                checked={disputeFilingTimeLimit === "_180_Days"}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="_180_Days"
                              >
                                180 Days
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                className="custom-control-input"
                                id="_1_Year"
                                name="disputeFilingTimeLimit"
                                onChange={(e) =>
                                  onChange(
                                    "disputeFilingTimeLimit",
                                    e.currentTarget.id
                                  )
                                }
                                checked={disputeFilingTimeLimit === "_1_Year"}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="_1_Year"
                              >
                                1 Year
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                className="custom-control-input"
                                id="Unlimited"
                                name="disputeFilingTimeLimit"
                                onChange={(e) =>
                                  onChange(
                                    "disputeFilingTimeLimit",
                                    e.currentTarget.id
                                  )
                                }
                                checked={disputeFilingTimeLimit === "Unlimited"}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="Unlimited"
                              >
                                Unlimited
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="maxResolutionTAT"
                            className="col-md-3 col-form-label"
                          >
                            Max TAT Resolution (Hours):
                          </label>
                          <div className="col-md-9">
                            <input
                              type="text"
                              className={this.validateField(
                                "maxResolutionTAT",
                                "number",
                                validated
                              )}
                              id="maxResolutionTAT"
                              name="maxResolutionTAT"
                              value={maxResolutionTAT}
                              onChange={(e) =>
                                onChange(
                                  "maxResolutionTAT",
                                  e.currentTarget.value
                                )
                              }
                            />
                            <div
                              className="invalid-feedback"
                              style={{ visibility: errors.maxResolutionTAT }}
                            >
                              {errors.maxResolutionTAT}
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="description"
                            className="col-md-3 col-form-label"
                          >
                            Description:{" "}
                          </label>
                          <div className="col-md-9">
                            <textarea
                              rows={2}
                              className={this.validateField(
                                "description",
                                "text",
                                validated
                              )}
                              id="description"
                              name="description"
                              value={description}
                              onChange={(e) =>
                                onChange("description", e.currentTarget.value)
                              }
                            />
                            <div
                              className="invalid-feedback"
                              style={{ visibility: errors.description }}
                            >
                              {errors.description}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-header card-footer clear-fix"
                    style={{
                      padding: "0px",
                      height: "35px",
                      paddingTop: "5px",
                    }}
                  >
                    <i className="fas fa-list-alt mr-3 ml-3" /> Dispute
                    Condition Documents
                  </div>
                  <div className="card-body">
                    <div className="card-content">
                      <ul className="list-unstyled row">
                        {documents.map((item) => (
                          <li key={item.id} className="col-md-4">
                            <div className="custom-control custom-checkbox">
                              <input
                                id={item.name}
                                name={item.name}
                                className="custom-control-input"
                                type="checkbox"
                                onChange={(e) =>
                                  this.handleOnDisputeConditionDocumentChange(
                                    e,
                                    item
                                  )
                                }
                                // checked={true}
                                checked={
                                  _.isEmpty(
                                    disputeConditionDocuments.filter(
                                      (x) => x === item.id
                                    )
                                  )
                                    ? false
                                    : true
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={item.name}
                              >
                                <b>{item.name}</b>
                                <p>
                                  <i style={{ color: "red" }}>
                                    {item.description}
                                  </i>
                                </p>
                              </label>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.UPDATE,
                      "Dispute Condition",
                      () => {
                        this.onFormSubmit(
                          {
                            name,
                            description,
                            maxResolutionTATInterval,
                            maxResolutionTAT,
                            code,
                            disputeFilingTimeLimit,
                          },
                          onReloadFields
                        );
                      }
                    );
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Update
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></EditDisputeConditionForm>
      </Modal>
    );
  }
}

export default EditDisputeCondition;
