import http from "../httpService";
// import axios from "axios";
import { getCurrentUserSession } from "../auth/authService";
import { IFilterData } from "../approval/approvalItemService";
import { decodeToken } from "react-jwt";

const recovaBankOneHandShakeBaseAddress = process.env.REACT_APP_RECOVA_BANKONE_HANDSHAKE_BASEADDRESS;

export function getDisbursementRequestById(requestId: number) {
    var url = recovaBankOneHandShakeBaseAddress + `Disbursement/RetrieveById/${requestId}`;
    return http.get(url, {
        url: url,
        method: "get",
        headers: {
            Authorization: `Bearer ${getCurrentUserSession().token}`,
        },
    });
}

export function filterDisbursementRequests(
    payload: IFilterData,
    pageSize: number,
    pageNumber: number
) {
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    payload = { ...payload };
    payload.institutionId = {
        item1: "=",
        item2: decodedToken?.["InstitutionId"]?.toString() || "break",
    };
    // console.log("payload: ", payload);
    var url =
        recovaBankOneHandShakeBaseAddress +
        `Disbursement/FilterDisbursementRequests?pageSize=${pageSize}&pageNumber=${pageNumber}`;

    return http.post(url, payload, {
        url: url,
        data: payload,
        method: "post",
        headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
}

export default {
    getDisbursementRequestById,
    filterDisbursementRequests
}