import { axiosSweepInstance as axiosInstance } from "../configs";

class Bank {
    
    retrieveAll(){
        return axiosInstance.get("/CommercialBank/RetrieveAll")
    }
    retrieveByCBNCode(code:string){
        return axiosInstance.get(`/CommercialBank/RetrieveByCBNCode/${code}`);
    }
    retrieveBySortCode(code:string){
        return axiosInstance.get(`/CommercialBank/RetrieveBySortCode/${code}`);
    }
}

const bankService = new Bank();
export default bankService;