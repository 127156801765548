import http from "../httpService";

const billingBaseAddress = process.env.REACT_APP_BILLING_BASEADDRESS;

export function createWalletConfig(walletConfig) {
  return http.post(
    billingBaseAddress + `WalletConfiguration`,
    walletConfig
  );
}

export function updateWalletConfig(id, walletConfig) {
  return http.put(
    billingBaseAddress + `WalletConfiguration/Edit/${id}`,
    walletConfig
  );
}

export function retrieveAllWalletConfigs() {
  return http.get(
    billingBaseAddress + `WalletConfiguration`
  );
}

export function retrieveWalletConfigById(id: string) {
  return http.get(
    billingBaseAddress + `WalletConfiguration/${id}`
  );
}

export function pagedWalletConfiguration() {
  return http.get(
    billingBaseAddress +
    `WalletConfiguration/paged`
  );
}

export function filterWalletConfigurationByServiceOfferingAndInstitutionCode(serviceOffering, institutionCode) {
  return http.get(
    billingBaseAddress +
    `WalletConfiguration/filter?ServiceOffering=${serviceOffering}&InstitutionCode=${institutionCode}`
  );
}


export default {
  createWalletConfig,
  updateWalletConfig,
  retrieveAllWalletConfigs,
  retrieveWalletConfigById,
  pagedWalletConfiguration,
  filterWalletConfigurationByServiceOfferingAndInstitutionCode
};
