import React, { Component } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import "./activityTrailItemsList.css";
import TableFiltermodal from "./tableFilterModal";
import { IFilterData } from "../../services/approval/approvalItemService";
import { filterFieldData } from "./itemsTable";

// export type inputFieldTypes = "text" | "select" | "number" | "date";
// export type filterFieldData = IFilterField[];
// export interface IFilterField {
//   name: string;
//   fieldType: inputFieldTypes;
//   options?: string[];
//   value: string | number;
// }

export interface IActivityTrailItemsListProps {
  items: any;
  filterValueInput: filterFieldData;
  //   name: string;
  currentPage: number;
  pageSize: number;
  totalSize: number;
  fetching: boolean;
  handlePageChange: any;
  //   onViewDetails: any;
  //   handleOnExport: any;
  //   amountColumn?: string[];
  //   timeColumn?: string[];
  showTableFiltermodal: boolean;
  toggleTableFiltermodal: any;
  data: IFilterData;
}

export interface IActivityTrailItemsListState {}

class ActivityTrailItemsList extends Component<
  IActivityTrailItemsListProps,
  IActivityTrailItemsListState
> {
  state = {};

  componentDidMount() {}

  render() {
    const {
      data,
      items,
      filterValueInput,
      currentPage,
      pageSize,
      totalSize,
      fetching,
      handlePageChange,
      showTableFiltermodal,
      toggleTableFiltermodal,
    } = this.props;
    const headers =
      items.length > 0
        ? Object.keys(items[0])
        : `There are no Activity Trails available`;

    // const pageSize = Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 1;
    const maxPage = Math.ceil(totalSize / pageSize);
    const pageOptionItems = _.range(1, maxPage+1, 1)
    const rowPerPageOptionItems: number[] = [10, 25, 50, 100]

    return (
      <React.Fragment>
        <nav aria-label="Page navigation example clearfix">
          <ul
            className="pagination float-left"
            style={{ marginBottom: "10px", height: "32px" }}
          >
            <li
              className={currentPage === 1 ? "page-item disabled" : "page-item"}
            >
              <button
                type="button"
                onClick={() => handlePageChange(1, pageSize, data)}
                className="page-link"
              >
                <i className="fas fa-step-backward" />
              </button>
            </li>
            <li
              className={currentPage === 1 ? "page-item disabled" : "page-item"}
            >
              <button
                type="button"
                onClick={() => handlePageChange(currentPage - 1, pageSize, data)}
                className="page-link"
              >
                <i className="fas fa-chevron-left" />
              </button>
            </li>
            <li className="page-item">
              <p className="page-link" style={{ height: "30px" }}>
                Page
              </p>
            </li>
            <li className="page-item">
              <select
                  id="currentPage"
                  name="currentPage"
                  className="custom-select select2 form-control form-control-sm r-0 light s-12 page-link"
                  style={{ width: "55px" }}
                  value={currentPage}
                  onChange={(e) => handlePageChange(e.currentTarget.value, pageSize, data)}
              >
                  {pageOptionItems.map((item) => (
                      <option key={item} value={item}>
                          {item}
                      </option>
                  ))}
              </select>
              {/* <input
                style={{ width: "35px" }}
                className="form-control form-control-sm"
                id="currentPage"
                type="number"
                value={currentPage}
                onChange={() => handlePageChange}
              /> */}
            </li>
            <li className="page-item">
              <p className="page-link" style={{ height: "30px" }}>
                of
              </p>
            </li>
            <li className="page-item">
              <p className="page-link" style={{ height: "30px" }}>
                {items.length > 0 ? maxPage : ""}
              </p>
            </li>
            <li
              className={
                maxPage === currentPage
                  ? "page-item disabled"
                  : "page-item"
              }
            >
              <button
                type="button"
                onClick={() => handlePageChange(currentPage + 1, pageSize, data)}
                className="page-link"
              >
                <i className="fas fa-chevron-right" />
              </button>
            </li>
            <li
              className={
                maxPage === currentPage
                  ? "page-item disabled"
                  : "page-item"
              }
            >
              <button
                type="button"
                onClick={() =>
                  handlePageChange(maxPage, pageSize, data)
                }
                className="page-link"
              >
                <i className="fas fa-step-forward" />
              </button>
            </li>
            <li className="page-item disabled">
              <a href="#!" className="page-link">
                <i className="fas fa-grip-lines-vertical" />
              </a>
            </li>
            {/* <li className="page-item">
                {fetching === true ? (
                  <React.Fragment>
                    <Skeleton width={50} height={20} /> <br />
                  </React.Fragment>
                ) : (
                  <button
                    type="button"
                    className="btn btn-sm btn-success waves-effect"
                    style={{ margin: "5px", padding: "2px 10px 2px 10px" }}
                  >
                    <i className="fas fa-file-excel mr-2" />
                    XLSX
                  </button>
                )}
              </li>
              <li className="page-item">
                {fetching === true ? (
                  <React.Fragment>
                    <Skeleton width={50} height={20} /> <br />
                  </React.Fragment>
                ) : (
                  <button
                    type="button"
                    className="btn btn-sm btn-danger waves-effect"
                    style={{ margin: "5px", padding: "2px 10px 2px 10px" }}
                  >
                    <i className="fas fa-file-pdf mr-2" />
                    PDF
                  </button>
                )}
              </li>
              <li className="page-item">
                {fetching === true ? (
                  <React.Fragment>
                    <Skeleton width={50} height={20} /> <br />
                  </React.Fragment>
                ) : (
                  <button
                    type="button"
                    className="btn btn-sm btn-primary waves-effect"
                    style={{ margin: "5px", padding: "2px 10px 2px 10px" }}
                  >
                    <i className="fas fa-file-csv mr-2" />
                    CSV
                  </button>
                )}
              </li>
             */}
          </ul>
          <div className="float-right" style={{ height: "30px" }}>
            {/* <span className="mr-4">
              {items.length > 0
                ? `Showing ${(currentPage - 1) * pageSize + 1} - ${
                    (currentPage - 1) * pageSize + items.length
                  } of ${totalSize}`
                : ""}
            </span> */}
            <button
              type="button"
              className="btn btn-sm btn-primary"
              data-toggle="modal"
              data-target="#staticBackdrop"
              onClick={() => toggleTableFiltermodal([], false)}
            >
              <i className="fas fa-filter mr-2"></i>Filter
            </button>
          </div>
        </nav>
        <div className="table-responsive text-nowrap">
          {fetching === true ? (
            <React.Fragment>
              <Skeleton height={25} width={200} />
              <br />
              <br />
              <Skeleton height={15} width={200} />
              <br />
              <Skeleton height={10} />
              <br />
              <Skeleton height={10} />
              <br />
              <br />
              <Skeleton height={15} width={200} />
              <br />
              <Skeleton height={10} />
              <br />
              <Skeleton height={10} />
              <br />
              <br />
              <Skeleton height={15} width={200} />
              <br />
              <Skeleton height={10} />
              <br />
              <Skeleton height={10} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* <h5>{name}</h5> */}
              <div className="row">
                <div className="col-md-11">
                  <ul className="timeline">
                    {items.map((trail) => (
                      <li key={trail.uniqueId}>
                        <span className="clearfix">
                          <h6 className="deep-purple-text float-left ml-5">
                            {/* <i className="far fa-user mr-2" /> */}
                            <span className="deep-orange-text">
                              {trail.actionType}
                            </span>{" "}
                            • {trail.initiatingUser}
                          </h6>
                          <p className="float-right mr-4">
                            <i className="fas fa-calendar-alt indigo-text mr-2" />{" "}
                            {trail.occuredAt}
                          </p>
                        </span>
                        <p
                          className="row col-md-10 text-wrap font-weight-bolder ml-4"
                          style={{ marginBottom: "0px" }}
                        >
                          <i className="fas fa-bolt amber-text mr-2" />
                          {trail.actionType}
                        </p>
                        <p
                          className="row col-md-10 text-wrap ml-4"
                          style={{ marginBottom: "0px" }}
                        >
                          <i className="fas fa-street-view mr-2" />
                          {trail.initiatingUser}
                        </p>
                        <p
                          className="row col-md-10 text-wrap ml-4"
                          style={{ marginBottom: "0px" }}
                        >
                          <i className="fas fa-comment green-text mr-2" />
                          {trail.description}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </React.Fragment>
          )}
          <div className="float-right">
            <span className="mr-4">
              Rows per page:
              <select
                id="pageSize"
                name="pageSize"
                className="custom-select select2 form-control form-control-sm r-0 light s-12 ml-2"
                style={{ width: "55px", cursor: "pointer", border: "none" }}
                value={pageSize}
                onChange={(e) => handlePageChange(currentPage, e.currentTarget.value, data)}
              >
                {rowPerPageOptionItems.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </span>
            <span className="mr-4">
              {items.length > 0
                ? `Showing ${(currentPage - 1) * pageSize + 1} - ${(currentPage - 1) * pageSize + items.length} of ${totalSize}`
                : ""}
            </span>
          </div>
        </div>
        <TableFiltermodal
          filterFields={filterValueInput}
          showTableFiltermodal={showTableFiltermodal}
          toggleTableFiltermodal={toggleTableFiltermodal}
        />
      </React.Fragment>
    );
  }
}

export default ActivityTrailItemsList;
