import React, { Fragment } from "react";
import {
    Modal,
    Button,
    Card,
    Accordion,
} from "react-bootstrap";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import BaseFormComponent, { IBaseFormState } from "../../../../../../components/BaseFormComponent";
import ItemsTable, { filterFieldData } from "../../../../../../components/tableComponents/itemsTable";
import TableFilterPane from "../../../../../../components/tableComponents/tableFilterPane";
import BaseListComponent, { IFilterData } from "../../../../../../components/BaseListComponent";
import { mapEntityList } from "../../../../../../services/utility/autoMapperService";
import textFormatService from "../../../../../../services/utility/textFormatService";
import DetailItemsTable, { TableContext } from "../../../../../../components/tableComponents/detailItemsTable";

interface PersonalDetailsModalFields { }

class RepaymentScheduleForm extends BaseFormComponent<PersonalDetailsModalFields> { }

interface IPersonalDetailsModalProps {
    item: any;
    showPatchModal: boolean;
    togglePatchModal: any;
}

interface IPersonalDetailsModalState {
    item: any;
    updatingStatus: boolean;
    submitting: boolean;
    data: IFilterData;
    currentPage: number;
    pageSize: number;
    tableItems: any;
    fetching: boolean;
    fetchingDetail: boolean;
    totalSize: number;
    filterValueInput: filterFieldData;
    userRoles: any;
    systemProcess: any;
    applicableRules: any;
    appraisalActions: any;
    updateComponent: boolean;
    documentsToUpload: any;
    documentsToDelete: number[];
    canEditItem: boolean;
    updateAvailable: boolean;
    processResponse: any;
    specialConditions: any;
    ownUpdate: boolean;
    showPersonalDetails: boolean;
    firstLoad: boolean;
}

class PersonalDetailsList extends BaseListComponent<IPersonalDetailsModalState> { }

class PersonalDetailsModal extends React.Component<
    IPersonalDetailsModalProps,
    IBaseFormState & IPersonalDetailsModalState
> {
    _isMounted = false;
    constructor(props: IPersonalDetailsModalProps) {
        super(props);
        this.state = {
            validated: false,
            submitting: false,
            errors: {},
            item: {},
            updatingStatus: false,
            data: {},
            currentPage: 1,
            pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
            fetching: false,
            fetchingDetail: false,
            tableItems: [],
            totalSize: 0,
            filterValueInput: [
            ],
            userRoles: [],
            systemProcess: [],
            applicableRules: [],
            appraisalActions: [],
            documentsToUpload: [],
            documentsToDelete: [],
            updateComponent: false,
            canEditItem: false,
            updateAvailable: false,
            specialConditions: [],
            processResponse: {
                actionId: 0,
                score: 0,
                response: ""
            },
            ownUpdate: false,
            showPersonalDetails: false,
            firstLoad: true
        };
    }

    resetFields() {
        this.setState({
            validated: false,
            submitting: false
        });
    }

    componentDidMount() {
        this._isMounted = true;
        this.handlePageChange(this.state.currentPage, this.state.pageSize);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handlePageChange = async (currentPage: number, pageSize: number, data?: IFilterData) => {
        currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
        console.log(currentPage)
        pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
        console.log(pageSize)
        if (this._isMounted) {
            this.setState({ fetching: true, ownUpdate: true });
        }
        try {
            const members = [...this.props.item.members]
            members.forEach(
                x => x.dateOfBirth = textFormatService.formatShortTimeString(x.dateOfBirth)
            )

            const membersPaginated: any = [];
            const totalLength = members.length;
            const initial = (currentPage - 1) * 10;
            for (let i = initial; i < initial + 10; i++) {
                membersPaginated.push(members[i]);
                if (i === totalLength - 1) {
                    break;
                }
            }

            // console.log(members.length)
            if (this._isMounted) {
                this.setState({
                    tableItems: mapEntityList({
                        entityList: membersPaginated,
                        properties: [
                            { oldName: "title", newName: "title" },
                            { oldName: "firstName", newName: "firstName" },
                            { oldName: "lastName", newName: "lastName" },
                            { oldName: "gender", newName: "gender" },
                            { oldName: "maritalStatus", newName: "maritalStatus" },
                            { oldName: "phoneNumber", newName: "phoneNumber" },
                            { oldName: "dateOfBirth", newName: "dateOfBirth" },
                            { oldName: "personalTIN", newName: "personalTIN" },
                            { oldName: "address", newName: "address" },
                            { oldName: "town", newName: "town" },
                            { oldName: "region", newName: "region" },
                            { oldName: "country", newName: "country" },
                            { oldName: "locationCoordinate", newName: "locationCoordinate" },
                            { oldName: "ownershipOfAddress", newName: "ownershipOfAddress" },
                            { oldName: "lengthOfStayAtAddress", newName: "lengthOfStayAtAddress" },
                            { oldName: "accountNumber", newName: "accountNumber" },
                            { oldName: "solidarityGroupName", newName: "solidarityGroupName" },
                            { oldName: "solidarityGroupAccountNumber", newName: "solidarityGroupAccountNumber" },
                            { oldName: "loanAmount", newName: "loanAmount" },
                            { oldName: "previousLoanAmount", newName: "previousLoanAmount" },
                            { oldName: "customerSavingsAmount", newName: "customerSavingsAmount" },
                            // { oldName: "cycleNo", newName: "cycleNo" },
                            { oldName: "dateCreated", newName: "dateCreated" },
                        ],
                    }),
                    currentPage,
                    pageSize,
                    totalSize: members.length,
                    fetching: false,
                    firstLoad: false,
                    // ownUpdate: true
                });
            }

            // console.log(this.state.totalSize)
        } catch (ex) {
            if (ex.response && ex.response.status >= 400 && ex.response.status <= 499) {
                if (this._isMounted) {
                    this.setState({ fetching: false, ownUpdate: true, firstLoad: false });
                }
            } else {
                if (this._isMounted) {
                    this.setState({ fetching: false, ownUpdate: true, firstLoad: false });
                }
            }
        } finally {
            if (this._isMounted) {
                this.setState({ fetching: false, ownUpdate: true, firstLoad: false });
            }
        }
    };
    getFilteredItems = (data: IFilterData): IFilterData => {
        let filteredItems: IFilterData = {};
        Object.keys(data).forEach((key) => {
            if (data[key].item2 !== "") {
                filteredItems[key] = data[key];
            }
        });
        return filteredItems;
    };

    static getDerivedStateFromProps(nextProps: IPersonalDetailsModalProps, prevState) {
        if (prevState.ownUpdate) {
            return {
                ownUpdate: false,
            };
        }
        if (nextProps.item && nextProps.item.id != prevState.Item?.id) {
            return { item: nextProps.item, firstLoad: true };
        }
        return null;
    }

    handleOnExport = async (type: string) => { };

    render() {
        const { item, showPatchModal, togglePatchModal } = this.props;
        const {
            currentPage,
            pageSize,
            tableItems,
            totalSize,
            fetching,
            fetchingDetail,
            firstLoad,
            submitting
        } = this.state;

        console.log(totalSize)

        const tableContext: TableContext = {
            name: "",
            primaryValue: "",
            successValue: "Successful",
            dangerValue: "Failed",

        };

        if (_.isEmpty(item)) {
            return null;
        }
        if (firstLoad) {
            this.handlePageChange(currentPage, pageSize);
        }
        return (
            <Modal
                size="xl"
                backdrop="static"
                show={showPatchModal}
                onHide={togglePatchModal}
            >
                <Modal.Header>
                    <Modal.Title>

                        <h5>
                            <i className="fas fa-edit fa-sm cyan-text mr-3" />
                            Individual Personal Details
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card">
                        <div className="card-header clear-fix">
                            {_.isEmpty(item) ? (
                                <Skeleton width={200} />
                            ) : (
                                <h6
                                    className="card-title float-left"
                                    style={{ marginBottom: "0px" }}
                                >
                                    <b>Personal Details</b>
                                </h6>
                            )}
                        </div>
                        <div className="card">
                            <Accordion defaultActiveKey="0">
                                <div>
                                    <Card>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card no-b">
                                                    <div className="card-body">
                                                        <div className="card-title">
                                                            {/* <div id="list-filters" className="ml-4 mr-4">
                                                                                <TableFilterPane
                                                                                    filteredItems={this.getFilteredItems(data)}
                                                                                    handleFilterRemove={onHandleFilterItemRemove}
                                                                                />
                                                                            </div> */}
                                                            {/* <div id="list-table"> */}
                                                            {/* <ItemsTable
                                                                                    data={data}
                                                                                    items={tableItems}
                                                                                    filterValueInput={filterValueInput}
                                                                                    showTableFiltermodal={false}
                                                                                    toggleTableFiltermodal={onToggleTableFilterModal}
                                                                                    name="Personal Details"
                                                                                    currentPage={currentPage}
                                                                                    pageSize={pageSize}
                                                                                    totalSize={totalSize}
                                                                                    fetching={fetching}
                                                                                    handlePageChange={this.handlePageChange}
                                                                                    onViewDetails={(item: any) => {
                                                                                        onToggleDetailsModal();
                                                                                    }}
                                                                                    handleOnExport={this.handleOnExport}
                                                                                    timeColumn={["dateCreated"]}
                                                                                    amountColumn={["loanAmount"]}
                                                                                    disableViewDetails
                                                                                /> */}
                                                            <DetailItemsTable
                                                                title="Personal Details"
                                                                item={item}
                                                                handleOnPageChange={
                                                                    this.handlePageChange
                                                                }
                                                                content={tableItems}
                                                                contentCount={totalSize}
                                                                page={currentPage}

                                                                fetching={fetching}
                                                                amountColumn={["loanAmount", "previousLoanAmount"]}
                                                                timeColumn={["dateCreated"]}
                                                                contextualColumn={tableContext}
                                                                emptyTitle="Personal Details"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* </div> */}
                                        </div>
                                    </Card>
                                </div>
                            </Accordion>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={() => togglePatchModal(false, {}, {})}
                        disabled={submitting}
                    >
                        <i className="fas fa-times mr-3" />
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default PersonalDetailsModal;
