import React from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import { IBaseFormState } from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import creditAssessmentValidationService from "../../../services/creditAssessment/creditAssessmentValidationService";
import { ISelectItems } from "../../../components/detailItemSelectBox";
import feeConfigService from "../../../services/creditAssessment/feeConfigService";
import { FeeEntryType } from "../../../enums/feeEntryType";
import swal from "sweetalert";
import SelectInputComponent from "../../../components/formSelectComponent";
import FormInputComponent from "../../../components/formInputComponent";

interface IEditFeeConfigurationProps {
  item: any;
  // showEditModal: boolean;
  // toggleEditModal: any;
}
interface IEditFeeConfigurationState {
  data: {
    feeEntryType: string;
    flatRatePerRequest: string;
    flatRatePerLoanDisbursed: string;
    percentageOfLoanAmount: string;
    minimumPerTransaction: string;
    maximumPerTransaction: string;
    // feeIncomeGLAccountNumber: string;
    // expenseGLAccountNumber: string;
    flatRatePerSuccessfulRequest: string;
  };
  updateMade: boolean;
}

class EditFeeConfiguration extends React.Component<
  IEditFeeConfigurationProps,
  IBaseFormState & IEditFeeConfigurationState
> {
  constructor(props: IEditFeeConfigurationProps) {
    super(props);
    const { item } = this.props;
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      data: {
        feeEntryType: item.feeEntryType,
        flatRatePerRequest: item.flatRatePerRequest?.toString(),
        flatRatePerLoanDisbursed: item.flatRatePerLoanDisbursed?.toString(),
        percentageOfLoanAmount: item.percentageOfLoanAmount?.toString(),
        minimumPerTransaction: item.minimumPerTransaction?.toString(),
        maximumPerTransaction: item.maximumPerTransaction?.toString(),
        // feeIncomeGLAccountNumber: item.feeIncomeGLAccountNumber,
        // expenseGLAccountNumber: item.expenseGLAccountNumber,
        flatRatePerSuccessfulRequest:
          item.flatRatePerSuccessfulRequest?.toString(),
      },
      updateMade: false,
    };
  }

  async isValidated() {
    // console.log("We're checking now ... ");
    if (this.state.submitting) {
      toast.info(`Already attempting to update this fee configuration `, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      return false;
    }

    try {
      if (!this.state.updateMade) {
        return true;
      }

      return this.onHandleSubmit(
        ActionTypes.UPDATE,
        "Fee Configuration",
        () => {
          this.onFormSubmit(this.state.data);
        }
      );
    } catch (error) { }
    return false;
  }

  onHandleSubmit = (
    actionType: ActionTypes | null,
    entityName: string,
    cb: any
  ) => {
    if (!actionType) {
      return cb();
    } else {
      swal({
        title: "Confirm!",
        text: `Are you sure you want to proceed to ${ActionTypes[actionType]} this ${entityName}? This action is Irreversible`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode:
          actionType === 2 ||
            actionType === 3 ||
            actionType === 7 ||
            actionType === 8
            ? true
            : false,
      }).then((resp) => {
        if (resp) {
          // console.log("cancelling action...");
        } else {
          return cb();
        }
      });
    }
  };

  onFormSubmit(fields: IEditFeeConfigurationState["data"]): boolean {
    // console.log("Checking:: ", fields);
    try {
      this.setState(
        {
          errors:
            creditAssessmentValidationService.validateEditFeeConfigurationForm(
              fields
            ),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  submit = async (
    fields: IEditFeeConfigurationState["data"]
  ): Promise<boolean> => {
    try {
      this.setState({ submitting: true });
      const payload = { ...this.props.item };
      payload.feeEntryType = fields.feeEntryType;
      payload.flatRatePerRequest = Number(fields.flatRatePerRequest);
      payload.flatRatePerLoanDisbursed = Number(
        fields.flatRatePerLoanDisbursed
      );
      payload.percentageOfLoanAmount = Number(fields.percentageOfLoanAmount);
      payload.minimumPerTransaction = Number(fields.minimumPerTransaction);
      payload.maximumPerTransaction = Number(fields.maximumPerTransaction);
      // payload.feeIncomeGLAccountNumber = fields.feeIncomeGLAccountNumber;
      // payload.expenseGLAccountNumber = fields.expenseGLAccountNumber;
      payload.flatRatePerSuccessfulRequest = Number(
        fields.flatRatePerSuccessfulRequest
      );

      //   console.log("For checks::", payload);
      const response = await feeConfigService.updateFeeConfiguration(
        payload,
        payload.id
      );
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit Fee Configuration",
          description: `Edited details for Fee Configuration ${fields.feeEntryType}`,
        });

        toast.info(`Fee Configuration Edited successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false });
      this.resetFields();
    } catch (error) {
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      //   console.log("submission error: ", error);
      this.setState({ submitting: false });
      return false;
    } finally {
      return true;
    }
  };

  onChangeHandler = (id, value) => {
    const data = { ...this.state.data };
    data[id] = value;
    this.setState({ data, updateMade: true });
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      errors: {},
    });
  }

  render() {
    const { item } = this.props;
    const { validated, submitting, errors } = this.state;
    // const { , fetchingConfig, existingFeeConfig } = this.state;
    const {
      feeEntryType,
      flatRatePerRequest,
      flatRatePerLoanDisbursed,
      percentageOfLoanAmount,
      minimumPerTransaction,
      maximumPerTransaction,
      // feeIncomeGLAccountNumber,
      // expenseGLAccountNumber,
      flatRatePerSuccessfulRequest,
    } = this.state.data;

    // Turn enum into array
    let feeEntryTypeInputData: ISelectItems[] = Object.keys(FeeEntryType)
      .filter((value) => isNaN(Number(value)) === false)
      .map((key) => ({
        name: FeeEntryType[key],
        value: FeeEntryType[key],
      }));

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            <form action="#">
              <div className="card-body">
                <h5 className="card-title">
                  <i className="fas fa-cogs fa-sm cyan-text mr-3" />
                  Edit Fee Configuration
                </h5>
                <div className="form-row">
                  <div className="col-md-12">
                    <React.Fragment>
                      <div className="form-row">
                        <SelectInputComponent
                          id="feeEntryType"
                          name="feeEntryType"
                          divClass={6}
                          value={feeEntryType}
                          items={feeEntryTypeInputData}
                          required={true}
                          validated={validated}
                          errors={errors}
                          onChange={(id, value) => {
                            this.onChangeHandler(id, value);
                          }}
                        />
                        <FormInputComponent
                          id="flatRatePerRequest"
                          type="number"
                          name="flatRatePerRequest"
                          placeholder=""
                          divClass={6}
                          value={flatRatePerRequest}
                          required={true}
                          validated={validated}
                          errors={errors}
                          onChange={(id, value) => {
                            this.onChangeHandler(id, value);
                          }}
                        />
                      </div>
                      <div className="form-row">
                        <FormInputComponent
                          id="flatRatePerLoanDisbursed"
                          type="number"
                          name={"flatRatePerLoanDisbursed"}
                          placeholder=""
                          divClass={6}
                          value={flatRatePerLoanDisbursed}
                          required={true}
                          validated={validated}
                          errors={errors}
                          onChange={(id, value) => {
                            this.onChangeHandler(id, value);
                          }}
                        />
                        <FormInputComponent
                          id="percentageOfLoanAmount"
                          type="number"
                          name={"percentageOfLoanAmount"}
                          placeholder=""
                          divClass={6}
                          value={percentageOfLoanAmount}
                          required={true}
                          validated={validated}
                          errors={errors}
                          onChange={(id, value) => {
                            this.onChangeHandler(id, value);
                          }}
                        />
                      </div>
                      <div className="form-row">
                        <FormInputComponent
                          id="minimumPerTransaction"
                          type="number"
                          name={"minimumPerTransaction"}
                          placeholder=""
                          divClass={6}
                          value={minimumPerTransaction}
                          required={true}
                          validated={validated}
                          errors={errors}
                          onChange={(id, value) => {
                            this.onChangeHandler(id, value);
                          }}
                        />
                        <FormInputComponent
                          id="maximumPerTransaction"
                          type="number"
                          name={"maximumPerTransaction"}
                          placeholder=""
                          divClass={6}
                          value={maximumPerTransaction}
                          required={true}
                          validated={validated}
                          errors={errors}
                          onChange={(id, value) => {
                            this.onChangeHandler(id, value);
                          }}
                        />
                      </div>
                      {/* <div className="form-row">
                        <FormInputComponent
                          id="feeIncomeGLAccountNumber"
                          type="text"
                          name={"feeIncomeGLAccountNumber"}
                          placeholder=""
                          divClass={6}
                          value={feeIncomeGLAccountNumber}
                          required={true}
                          validated={validated}
                          errors={errors}
                          onChange={(id, value) => {
                            this.onChangeHandler(id, value);
                          }}
                        />
                        <FormInputComponent
                          id="expenseGLAccountNumber"
                          type="text"
                          name={"expenseGLAccountNumber"}
                          placeholder=""
                          divClass={6}
                          value={expenseGLAccountNumber}
                          required={true}
                          validated={validated}
                          errors={errors}
                          onChange={(id, value) => {
                            this.onChangeHandler(id, value);
                          }}
                        />
                      </div> */}
                      <div className="form-row">
                        <FormInputComponent
                          id="flatRatePerSuccessfulRequest"
                          type="number"
                          name={"flatRatePerSuccessfulRequest"}
                          placeholder=""
                          divClass={6}
                          value={flatRatePerSuccessfulRequest}
                          required={true}
                          validated={validated}
                          errors={errors}
                          onChange={(id, value) => {
                            this.onChangeHandler(id, value);
                          }}
                        />
                      </div>
                    </React.Fragment>
                  </div>
                </div>
                <hr />
                {submitting === false ? (
                  <React.Fragment>
                    {/* <i className="fas fa-lg fa-save mr-3" /> Save */}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <i className="fas fa-spin fa-circle-notch mr-3" /> Saving...
                  </React.Fragment>
                )}
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default EditFeeConfiguration;
