import http from "../httpService";

export interface ISaveLoanProduct {
  name: string;
  institutionCode: string;
  productCode: string;
  interestRate: number;
  minimumAmount: number;
  maximumAmount: number;
  tenure: number;
  moratorium?: number;
}
export interface IUpdateLoanProduct {
  name: string;
  productCode: string;
  interestRate: number;
  minimumAmount: number;
  maximumAmount: number;
  tenure: number;
}

const recovaCreditAssessmentBaseAddress =
  process.env.REACT_APP_CREDITASSESSMENT_BASEADDRESS;
// const recovaCreditAssessmentBaseAddress = "http://localhost:5001/api/";

export function saveLoanProduct(loanProduct: ISaveLoanProduct) {
  return http.post(
    recovaCreditAssessmentBaseAddress + `LoanProduct`,
    loanProduct
  );
}

export function updateLoanProduct(loanProduct: IUpdateLoanProduct, id: number) {
  return http.put(
    recovaCreditAssessmentBaseAddress + `LoanProduct/${id}`,
    loanProduct
  );
}

export function retrieveAll() {
  return http.get(recovaCreditAssessmentBaseAddress + `LoanProduct`);
}

export function retrieveAllByInstitutionCode(institutionCode: string) {
  return http.get(
    recovaCreditAssessmentBaseAddress +
    `LoanProduct/getbyinstitution/${institutionCode}`
  );
}

export function filterPagedLoanProducts(
  data: any,
  pageSize: number,
  pageNumber: number
) {
  return http.post(
    recovaCreditAssessmentBaseAddress +
    `LoanProduct/FilterLoanProducts?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    data
  );
}

export function retrieveById(id: number) {
  return http.get(recovaCreditAssessmentBaseAddress + `LoanProduct/${id}`);
}

export function addQualificationRuleToLoanProduct(id, payload) {
  return http.patch(
    recovaCreditAssessmentBaseAddress + `LoanProduct/${id}`,
    payload
  );
}

export function removeQualificationRuleFromLoanProduct(id, payload) {
  return http.patch(
    recovaCreditAssessmentBaseAddress + `LoanProduct/creditqualificationrules/remove/${id}`,
    payload
  );
}

export function retrieveByProductCode(productCode: string) {
  return http.get(
    recovaCreditAssessmentBaseAddress +
    `LoanProduct/getbyproductcode/${productCode}`
  );
}

export function retrieveLoanOffer(productCode: string, loanAmount: number) {
  return http.get(
    recovaCreditAssessmentBaseAddress +
    `LoanProduct/loanoffer/${productCode}/${loanAmount}`
  );
}

export function retrieveLoanProductByCreditScore(
  productCode: string,
  creditScore: number
) {
  return http.get(
    recovaCreditAssessmentBaseAddress +
    `LoanProduct/${creditScore}/${productCode}`
  );
}
export function retrieveProductPricingById(Id: number) {
  return http.get(
    recovaCreditAssessmentBaseAddress +
    `LoanProduct/getProductPricingById/${Id}`
  );
}
export function retrieveProductPricingByInstitutionCode(institutionCode: string, productCode: string) {
  return http.get(
    recovaCreditAssessmentBaseAddress +
    `LoanProduct/getProductPricingByInstitutionCode/${institutionCode}/${productCode}`
  );
}

export function saveLoanProductPricing(loanProduct: any) {
  return http.post(
    recovaCreditAssessmentBaseAddress + `LoanProduct/createProductPricing`,
    loanProduct
  );
}

export function updateLoanProductPricing(loanProduct: any, institutionCode: string, productCode: string) {
  return http.put(
    recovaCreditAssessmentBaseAddress + `LoanProduct/updateProductPricing/${institutionCode}/${productCode}`,
    loanProduct
  );
}

export default {
  saveLoanProduct,
  updateLoanProduct,
  retrieveById,
  retrieveAllByInstitutionCode,
  retrieveAll,
  filterPagedLoanProducts,
  addQualificationRuleToLoanProduct,
  removeQualificationRuleFromLoanProduct,
  retrieveByProductCode,
  retrieveLoanOffer,
  retrieveLoanProductByCreditScore,
  retrieveProductPricingById,
  retrieveProductPricingByInstitutionCode,
  saveLoanProductPricing,
  updateLoanProductPricing
};
