import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import textFormatService from "../../../services/utility/textFormatService";

interface ILinkedAccountsDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  item: any;
}

interface ILinkedAccountsDetailState {}

class LinkedAccountsDetail extends Component<
  ILinkedAccountsDetailProps,
  ILinkedAccountsDetailState
> {
  constructor(props: ILinkedAccountsDetailProps) {
    super(props);
    this.state = { };
  }

  render() {
    const { showDetailsModal, toggleDetailsModal, item } = this.props;
    // console.log(item)
    return (
      <React.Fragment>
        <Modal
          size="xl"
          backdrop="static"
          show={showDetailsModal}
          onHide={toggleDetailsModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {_.isEmpty(item) ? (
                <Skeleton width={500} />
              ) : (
                <h5>
                  <i className="fas fa-receipt fa-sm s-18 mr-3" />
                  Linked Account Details
                </h5>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card">
              <div className="card-header clear-fix">
                {_.isEmpty(item) ? (
                  <Skeleton width={200} />
                ) : (
                  <h6
                    className="card-title float-left"
                    style={{ marginBottom: "0px" }}
                  >
                    <b>General Information</b>
                  </h6>
                )}
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    {_.isEmpty(item) ? (
                      <Skeleton count={6} width={300} />
                    ) : (
                      <dl className="row">
                        <DetailItem
                          label="BVN"
                          labelSize={6}
                          valueSize={6}
                          value={item.bvn}
                        />
                        <DetailItem
                          label="First Linked Date"
                          labelSize={6}
                          valueSize={6}
                          value={textFormatService.formatTimeString(item.firstDateLinked)}
                        />
                      </dl>
                    )}
                  </div>
                  <div className="col-md-6">
                    {_.isEmpty(item) ? (
                      <Skeleton count={6} width={300} />
                    ) : (
                      <dl className="row">
                        <DetailItem
                          label="Number of Linked Accounts"
                          labelSize={6}
                          valueSize={6}
                          value={item.noOfAccounts}
                        />
                        <DetailItem
                          label="Last Linked Date"
                          labelSize={6}
                          valueSize={6}
                          value={textFormatService.formatTimeString(item.lastDateLinked)}
                        />
                      </dl>
                    )}
                  </div>
                </div>
              </div>              
              <div
                className="card-content table-responsive text-nowrap"
                style={{ height: "150px", overflowY: "scroll" }}
              >
                <table className="table table-hover table-content table-sm table-bordered table-striped mb-0">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        style={{
                          width: "5px",
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                        }}
                      >
                        <strong>#</strong>
                      </th>
                      <th
                        scope="col"
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                        }}
                      >
                        <strong>Institution Name</strong>
                      </th>
                      <th
                        scope="col"
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                        }}
                      >
                        <strong>Bank Code</strong>
                      </th>
                      <th
                        scope="col"
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                        }}
                      >
                        <strong>Account Number</strong>
                      </th>
                      <th
                        scope="col"
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                        }}
                      >
                        <strong>First Date Linked</strong>
                      </th>
                      <th
                        scope="col"
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                        }}
                      >
                        <strong>Last Date Linked</strong>
                      </th>
                      <th
                        scope="col"
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                        }}
                      >
                        <strong>Linking Status</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.isEmpty(item.accounts) ? (
                      <tr>
                        <td
                          className="font-weight-normal font-italic text-truncate"
                          colSpan={7}
                          style={{
                            textAlign: "center",
                            color: "#FE825E",
                          }}
                        >
                          ...No Linked Accounts for this BVN.
                        </td>
                      </tr>
                    ) : (
                      item.accounts.map((_item) => (
                        <React.Fragment
                          key={
                            item.accounts.indexOf(_item) + 1
                          }
                        >
                          <tr>
                            <td
                              style={{
                                paddingTop: "0.2rem",
                                paddingBottom: "0.2rem",
                                fontSize: "small",
                              }}
                            >
                              {item.accounts.indexOf(_item) +
                                1}
                            </td>
                            <td
                              style={{
                                paddingTop: "0.2rem",
                                paddingBottom: "0.2rem",
                                fontSize: "small",
                              }}
                            >
                              {_item.institutionName}
                            </td>
                            <td
                              style={{
                                paddingTop: "0.2rem",
                                paddingBottom: "0.2rem",
                                fontSize: "small",
                              }}
                            >
                              {_item.institutionCode}
                            </td>
                            <td
                              style={{
                                paddingTop: "0.2rem",
                                paddingBottom: "0.2rem",
                                fontSize: "small",
                              }}
                            >
                              {_item.accountNumber}
                            </td>
                            <td
                              style={{
                                paddingTop: "0.2rem",
                                paddingBottom: "0.2rem",
                                fontSize: "small",
                              }}
                            >
                              {textFormatService.formatTimeString(
                                _item.firstDateLinked
                              )}
                            </td>
                            <td
                              style={{
                                paddingTop: "0.2rem",
                                paddingBottom: "0.2rem",
                                fontSize: "small",
                              }}
                            >
                              {_.isEmpty(_item.lastDateLinked)
                                ? ""
                                : textFormatService.formatTimeString(
                                    _item.dateLinked
                                  )}
                            </td>
                            <td
                              style={{
                                paddingTop: "0.2rem",
                                paddingBottom: "0.2rem",
                                fontSize: "small",
                              }}
                            >
                              {_item.linkingStatus ===
                              "Validated" ? (
                                <span className="badge badge-light">
                                  Validated
                                </span>
                              ) : (
                                <span className="badge badge-secondary">
                                  Linked
                                </span>
                              )}
                            </td>
                          </tr>
                        </React.Fragment>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              variant="outline-danger"
              onClick={toggleDetailsModal}
            >
              <i className="fas fa-times mr-3" />
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default LinkedAccountsDetail;
