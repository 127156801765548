import http from "../httpService";
import { IFilterData } from "../approval/approvalItemService";
import { getCurrentUserSession } from "../auth/authService";
import { decodeToken } from "react-jwt";

const sweepBaseAddress = process.env.REACT_APP_SWEEP_BASEADDRESS;
export function retrieveById(id: number) {
  var url = sweepBaseAddress + `SweepPlan/RetrieveById/${id}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveOldPlanById(id: number) {
  var url = sweepBaseAddress + `SweepPlan/RetrieveOldPlanById/${id}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function filterSweepPlans(
  payload: IFilterData,
  pageSize: number,
  currentPage: number
) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");

  payload = { ...payload };
  
  if(Number(decodedToken?.["InstitutionId"]) !== 1){
    payload.loanAccountInstitution = {
      item1: "=",
      item2: decodedToken?.["InstitutionCode"]?.toString() || "break",
    };
  }

  var url = sweepBaseAddress +
    `SweepPlan/FilterSweepPlans?pageNum=${currentPage}&maxSize=${pageSize}`;

  return http.post(url, payload, {
    url: url,
    data: payload,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function filterOldSweepPlans(
  payload: IFilterData,
  pageSize: number,
  currentPage: number
) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");

  payload = { ...payload };
  
  if(Number(decodedToken?.["InstitutionId"]) !== 1){
    payload.loanAccountInstitution = {
      item1: "=",
      item2: decodedToken?.["InstitutionCode"]?.toString() || "break",
    };
  }

  var url = sweepBaseAddress +
    `SweepPlan/FilterOldSweepPlans?pageNum=${currentPage}&maxSize=${pageSize}`;

  return http.post(url, payload, {
    url: url,
    data: payload,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export default {
  retrieveById,
  retrieveOldPlanById,
  filterSweepPlans,
  filterOldSweepPlans
};
