import * as React from "react";
import { ActionTypes } from "../enums/actionTypes";
import swal from "sweetalert";

export interface IBaseFormState {
  validated: boolean;
  submitting: boolean;
  errors: any;
}
export type FormComponentProps<FormFields> = {
  fields: FormFields;
} & Handlers<FormFields>;

export interface Handlers<FormFields> {
  onChange: OnChangeHandler<FormFields>;
  onChange2: OnChange2Handler<FormFields>;
  onReloadFields: () => void;
  onHandleSubmit: (
    e: any,
    actionType: ActionTypes | null,
    entityName: string,
    cb: any
  ) => void;
}

// export type OnReloadEvenHandler = (s: boolean) => void;
export type OnChangeHandler<FormFields> = <K extends keyof FormFields>(
  s: K,
  a: FormFields[K]
) => void;

export type OnChange2Handler<FormFields> = <K extends keyof FormFields>(
  s: K[],
  a: FormFields[K][]
) => void;

export interface BaseFormComponentState<FormFields> {
  fields: FormFields;
}

export interface BaseFormComponentProps<FormFields> {
  initialValues: FormFields;
  FormComponent: React.ComponentType<FormComponentProps<FormFields>>;
}

class BaseFormComponent<FormFields> extends React.Component<
  BaseFormComponentProps<FormFields>,
  BaseFormComponentState<FormFields>
> {
  constructor(props: BaseFormComponentProps<FormFields>) {
    super(props);
    this.state = { fields: props.initialValues };
  }
  merge = (field: any, value: any) => {
    return { ...field, ...value };
  };
  public onChange: OnChangeHandler<FormFields> = (field, value) => {
    // Use your favorite method to merge objects
    this.setState({
      fields: this.merge(this.state.fields, { [field]: value }),
    });
  };

  public onChange2: OnChange2Handler<FormFields> = (field, value) => {
    // Use your favorite method to merge objects
    var result = this.state.fields;
    for (let index = 0; index < field.length; index++) {
      result = this.merge(result, { [field[index]]: value[index] });
    }
    this.setState({
      fields: result,
    });
  };

  public onReloadFields = () => {
    this.setState({ fields: this.props.initialValues });
  };

  public onHandleSubmit = (
    e: any,
    actionType: ActionTypes | null,
    entityName: string,
    cb: any
  ) => {
    e.preventDefault();

    if (!actionType) {
      cb();
    } else {
      swal({
        title: "Confirm!",
        text: `Are you sure you want to proceed to ${ActionTypes[actionType]} this ${entityName}?`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode:
          actionType === 2 ||
          actionType === 3 ||
          actionType === 7 ||
          actionType === 8
            ? true
            : false,
      }).then((resp) => {
        if (resp) {
          // console.log("cancelling action...");
        } else {
          cb();
        }
      });
    }
  };

  public render() {
    const { FormComponent } = this.props;
    const { fields } = this.state;

    return (
      <FormComponent
        onChange={this.onChange}
        onChange2={this.onChange2}
        onReloadFields={this.onReloadFields}
        onHandleSubmit={this.onHandleSubmit}
        fields={fields}
      />
    );
  }
}

export default BaseFormComponent;
