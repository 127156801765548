import React, { Fragment } from "react";
import { toast } from "react-toastify";
import activityTrailService from "../../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../../components/BaseFormComponent";
import { ActionTypes } from "../../../../enums/actionTypes";
import _ from "lodash";
import { Button } from "react-bootstrap";
import DetailItem from "../../../../components/detailItem";
import Skeleton from "react-loading-skeleton";
import DetailItemInputComponent from "../../../../components/detailItemInputBox";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../../../services/auth/authService";
import institutionConfigService from "../../../../services/creditAssessment/institutionConfigService";
import creditAssessmentValidationService from "../../../../services/creditAssessment/creditAssessmentValidationService";
import TopUpBalanceModal from "./topUpBalanceModal";

interface EditInstitutionConfigFields {
  minimumCreditDroughtDays: string;
  maximumAvailableFund: string;
  availableFundLimit: string;
}

class EditInstitutionConfigForm extends BaseFormComponent<EditInstitutionConfigFields> { }

interface IEditInstitutionConfigProps { }

interface IEditInstitutionConfigState {
  institutionConfig: any;
  editing: boolean;
  fetching: boolean;
  showModal: boolean;
}

class EditInstitutionConfig extends React.Component<
  IEditInstitutionConfigProps,
  IBaseFormState & IEditInstitutionConfigState
> {
  constructor(props: IEditInstitutionConfigProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      institutionConfig: {},
      // formChangeFunction: null,
      fetching: false,
      editing: false,
      showModal: false
    };
  }

  async componentDidMount() {
    this.fetchItem();
  }

  fetchItem = async () => {
    try {
      this.setState({ fetching: true });
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const institutionConfig = await institutionConfigService.retrieveInstitutionConfig(decodedToken?.["InstitutionCode"]);

      this.setState({
        institutionConfig: institutionConfig.data,
        fetching: false
      });
    } catch (error) {
      // console.log("error: ", error);
      // console.log("error: ", error.response);
      if (error.response.status === 400 || error.response.status === 409) {
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // return false;
      }
      this.setState({ fetching: false });
    }
  };

  onToggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  onFormSubmit(fields: EditInstitutionConfigFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors: creditAssessmentValidationService.validateEditBlackboxInstitutionConfigForm(fields),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: EditInstitutionConfigFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      // console.log("starting submission...");
      this.setState({ submitting: true });
      const payload = this.state.institutionConfig;

      payload.minimumCreditDroughtDays = Number(fields.minimumCreditDroughtDays);
      payload.maximumAvailableFund = Number(fields.maximumAvailableFund);
      payload.availableFundLimit = Number(fields.availableFundLimit);

      const response = await institutionConfigService.updateInstitutionConfig(payload.institutionCode, payload);

      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Modify OCM Institution Configuration",
          description: `Modified OCM Institution Configuration`,
        });

        toast.info(`Institution Configuration modified successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false, editing: false });
      onReloadFieldsCB();
    } catch (error) {
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      // console.log("error: ", error.response.data);
      return false;
    } finally {
      return true;
    }
  }

  toggleEditMode = () => {
    this.setState({ editing: !this.state.editing })
  }

  render() {
    const { institutionConfig, editing, submitting, fetching, validated, errors } = this.state;

    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Collection Settings</b>
              </h3>
            </div>
            {_.isEmpty(institutionConfig) ? (
              <Skeleton count={4} height={30} />
            ) : (
              <EditInstitutionConfigForm
                initialValues={{
                  minimumCreditDroughtDays: institutionConfig?.minimumCreditDroughtInDays.toString(),
                  maximumAvailableFund: institutionConfig?.maximumAvailableFund.toString(),
                  availableFundLimit: institutionConfig?.availableFundLimit.toString()
                }}
                FormComponent={({
                  fields: { availableFundLimit, maximumAvailableFund, minimumCreditDroughtDays },
                  onChange,
                  onReloadFields,
                  onHandleSubmit,
                }) => (
                  <form action="#">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="form-row">
                          <div className="col-md-12">
                            {_.isEmpty(institutionConfig) ? (
                              <Skeleton count={4} height={30} />
                            ) : (
                              <div className="form-row">
                                <div className="col-md-6">
                                  {!_.isEmpty(institutionConfig) && fetching ? (
                                    <Skeleton count={2} width={300} />
                                  ) : (
                                    <dl className="row">
                                      {editing ? (
                                        <Fragment>
                                          <DetailItem
                                            label="Minimum Credit Drought In Days"
                                            value={
                                              <DetailItemInputComponent
                                                id="minimumCreditDroughtInDays"
                                                type="number"
                                                name="minimumCreditDroughtInDays"
                                                placeholder=""
                                                value={minimumCreditDroughtDays}
                                                required={true}
                                                validated={validated}
                                                errors={errors}
                                                onChange={onChange}
                                              />
                                            }
                                            labelSize={7}
                                            valueSize={5}
                                          />
                                          <DetailItem
                                            label="Maximum Available Fund"
                                            value={
                                              <DetailItemInputComponent
                                                id="maximumAvailableFund"
                                                type="number"
                                                name="maximumAvailableFund"
                                                placeholder=""
                                                value={maximumAvailableFund}
                                                required={true}
                                                validated={validated}
                                                errors={errors}
                                                onChange={onChange}
                                              />
                                            }
                                            labelSize={7}
                                            valueSize={5}
                                          />
                                        </Fragment>
                                      ) : (
                                        <Fragment>
                                          <DetailItem
                                            label="Minimum Credit Drought In Days"
                                            value={minimumCreditDroughtDays}
                                            labelSize={7}
                                            valueSize={5}
                                          />
                                          <DetailItem
                                            label="Maximum Available Fund"
                                            value={maximumAvailableFund}
                                            labelSize={7}
                                            valueSize={5}
                                          />
                                        </Fragment>
                                      )}
                                    </dl>
                                  )}
                                </div>
                                <div className="col-md-6">
                                  {!_.isEmpty(institutionConfig) && fetching ? (
                                    <Skeleton count={2} width={300} />
                                  ) : (
                                    <dl className="row">
                                      {editing ? (
                                        <DetailItem
                                          label="Available Fund Limit"
                                          value={
                                            <DetailItemInputComponent
                                              id="availableFundLimit"
                                              type="number"
                                              name="availableFundLimit"
                                              placeholder=""
                                              value={availableFundLimit}
                                              required={true}
                                              validated={validated}
                                              errors={errors}
                                              onChange={onChange}
                                            />
                                          }
                                          labelSize={7}
                                          valueSize={5}
                                        />
                                      ) : (
                                        <DetailItem
                                          label="Available Fund Limit"
                                          value={availableFundLimit}
                                          labelSize={7}
                                          valueSize={5}
                                        />
                                      )}
                                      <DetailItem
                                        label="Avaliable Balance"
                                        value={institutionConfig.avaliableBalance}
                                        labelSize={7}
                                        valueSize={5}
                                      />
                                    </dl>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <hr />
                      {editing ? (
                        <React.Fragment>
                          <div className="card-body">
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                              disabled={this.state.submitting}
                              onClick={(e) => {
                                onHandleSubmit(
                                  e,
                                  ActionTypes.UPDATE,
                                  "Institution Collection Setting",
                                  () => {
                                    this.onFormSubmit(
                                      { availableFundLimit, maximumAvailableFund, minimumCreditDroughtDays },
                                      onReloadFields
                                    );
                                  }
                                );
                              }}
                            >
                              {this.state.submitting === false ? (
                                <React.Fragment>
                                  <i className="fas fa-lg fa-save mr-3" /> Save
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                  Please wait...
                                </React.Fragment>
                              )}
                            </button>
                          </div>
                        </React.Fragment>
                      ) : (
                        !_.isEmpty(institutionConfig) && (
                          <div className="card-body">
                            <Button
                              size="sm"
                              variant="primary"
                              disabled={submitting}
                              onClick={this.toggleEditMode}
                            >
                              <i className="far fa-edit mr-3" />
                              Edit
                            </Button>
                            <Button
                              size="sm"
                              variant="secondary"
                              className="ml-3"
                              disabled={submitting}
                              onClick={this.onToggleModal}
                            >
                              <i className="fas fa-plus mr-3" />
                              Top Up
                            </Button>
                          </div>
                        )
                      )}
                    </div>
                  </form>
                )}
              ></EditInstitutionConfigForm>
            )}
          </div>
          <TopUpBalanceModal
            showPatchModal={this.state.showModal}
            togglePatchModal={(reloadDetails: boolean, item: any) => {
              this.onToggleModal();
              if (reloadDetails === true) {
                this.fetchItem();
              }
            }}
            item={institutionConfig}
          />
        </div>
      </Fragment>
    );
  }
}

export default EditInstitutionConfig;
