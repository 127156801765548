import React, { Fragment, memo } from "react";
import { toast } from "react-toastify";
import { Modal, Button, Accordion, Card } from "react-bootstrap";
import _, { isEmpty } from "lodash";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
    IBaseFormState,
} from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import workFlowConfigService from "../../../services/hybridAppraisal/workFlowConfigService";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../components/detailItem";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import { IMultiSelectItems } from "../../../components/formMultiSelectComponent";
import EditMandatoryFieldConfig from "./editMandatoryFieldConfig";
import { ContextAwareToggle } from "../appraisalPlan/appraisalPlanDetail";
import supplierService from "../../../services/hybridAppraisal/supplierService";
import SelectInputComponent, { ISelectItems } from "../../../components/formSelectComponent";
import DetailItemSelectBox from "../../../components/detailItemSelectBox";
import FormTextAreaComponent from "../../../components/formTextAreaComponent";
import FormInputComponent from "../../../components/formInputComponent";
import hybridAppraisalValidationService from "../../../services/hybridAppraisal/hybridAppraisalValidationService";
import nameEnquiryService from "../../../services/handshake/nameEnquiryService";
import sweepValidationService from "../../../services/sweep/sweepValidationService";
import sweepConfigService from "../../../services/sweep/sweepConfigService";
import commercialBankService from "../../../services/sweep/commercialBankService";
import { getCurrentUserSession } from "../../../services/auth/authService";
import { decodeToken } from "react-jwt";

interface AddSingleSupplierDetailsFields {
    name: string;
    bankName: string;
    accountNumber: string;
    phoneNumber: string;
    address: string;
}

class AddSingleSupplierDetailsForm extends BaseFormComponent<AddSingleSupplierDetailsFields> { }

interface IAddSingleSupplierDetailsProps {
    item: any;
    showEditModal: boolean;
    toggleEditModal: any;
}

interface IAddSingleSupplierDetailsState {
    ownUpdate: boolean;
    fetchingRequiredItems: boolean;
    selectedWorkFlowSteps: any;
    selectedDataEntryTabs: any;
    dataEntrySections: any;
    showMandatoryFieldModal: boolean;
    configuredMandatoryFields: boolean;
    bankCode: string;
    accountNumber: string;
    accountName: string;
    commercialBanks: any;
}

class AddSingleSupplierDetails extends React.Component<
    IAddSingleSupplierDetailsProps,
    IBaseFormState & IAddSingleSupplierDetailsState
> {
    _isMounted = false;
    constructor(props: IAddSingleSupplierDetailsProps) {
        super(props);
        this.state = {
            validated: false,
            submitting: false,
            errors: {},
            ownUpdate: false,
            fetchingRequiredItems: false,
            selectedWorkFlowSteps: [],
            selectedDataEntryTabs: [],
            dataEntrySections: [],
            showMandatoryFieldModal: false,
            configuredMandatoryFields: false,
            bankCode: "",
            accountNumber: "",
            accountName: "",
            commercialBanks: []
        };
    }

    onFormSubmit(fields: AddSingleSupplierDetailsFields, onReloadFieldsCB: any): boolean {
        try {
            this.setState(
                { errors: hybridAppraisalValidationService.validateAddSupplierDetailsForm(fields), ownUpdate: true }, () => {
                    console.log(this.state.errors)
                    if (Object.keys(this.state.errors).length === 0) {
                        return this.submit(fields, onReloadFieldsCB);
                    }
                });
            this.setState({ validated: true, ownUpdate: true });
            return false;
        } catch (error) {
            return false;
        }
    }

    submit = async (
        fields: AddSingleSupplierDetailsFields,
        onReloadFieldsCB: any
    ) => {
        try {
            this.setState({ submitting: true, ownUpdate: true });
            const { selectedWorkFlowSteps, selectedDataEntryTabs } = this.state;
            const { dataEntrySections } = this.state;
            const payload: any = {};
            const decodedToken = decodeToken(getCurrentUserSession().token || "");

            payload.institutionCode = decodedToken?.["InstitutionCode"]
            payload.name = fields.name;
            payload.bankName = this.state.commercialBanks.filter(x => x.cbnCode === fields.bankName)[0].institutionName;
            payload.accountNumber = fields.accountNumber;
            payload.phoneNumber = fields.phoneNumber;
            payload.address = fields.address;

            const response = await supplierService.createSupplier(payload);
            if (response.status >= 200 && response.status <= 300) {
                await activityTrailService.saveActivityTrail({
                    actionType: "Add Supplier Details",
                    description: `Added details for Supplier ${fields.name}`,
                });
                toast.info(`Supplier ${fields.name} Details Added successfully!`, {
                    autoClose: 6000,
                    type: toast.TYPE.DEFAULT,
                    hideProgressBar: false,
                });
            } else {
                return false;
            }
            this.setState({ submitting: false });
            this.resetFields();
            onReloadFieldsCB();
        } catch (error) {
            toast.error(error.response.data, {
                autoClose: 6000,
                type: toast.TYPE.ERROR,
                hideProgressBar: false,
            });
            this.setState({ submitting: false, ownUpdate: true });
        } finally {
            return true;
        }
    }

    async componentDidMount() {
        this._isMounted = true

        var commercialBanks = await commercialBankService.retrieveAll();
        var commercialBanksDetails = commercialBanks.data;

        this.setState({ commercialBanks: commercialBanksDetails })
    }

    fetchAccountName = async (value, channel) => {
        console.log("Channel: ", channel + " value: ", value)
        let { bankCode, accountNumber } = this.state;
        if (channel === "bankName") {
            bankCode = value
        }

        else if (channel === "accountNumber") {
            accountNumber = value
        }

        this.setState({ bankCode, accountNumber }, async () => {
            if (this.state.bankCode != "" && this.state.accountNumber != "") {
                var accountName = await nameEnquiryService.nameEnquiry(this.state.accountNumber, this.state.bankCode)
                this.setState({ accountName: accountName.data })
            }
        })

    }

    resetFields() {
        this.setState({
            submitting: false,
            ownUpdate: true,
            selectedWorkFlowSteps: [],
            selectedDataEntryTabs: [],
            dataEntrySections: []
        });
    }

    resetAllFields() {
        this.setState({
            submitting: false,
            ownUpdate: true
        })
    }

    render() {
        const { item, showEditModal, toggleEditModal } = this.props;
        const {
            validated,
            submitting,
            errors,
            selectedWorkFlowSteps,
            selectedDataEntryTabs,
            dataEntrySections,
            configuredMandatoryFields,
            commercialBanks
        } = this.state;

        let workflowStepsInputData: IMultiSelectItems[] = [
            { label: "Data Validation", value: "DataValidation" },
            { label: "Document Generation", value: "DocumentGeneration" },
            { label: "Document Collection", value: "DocumentCollection" },
            { label: "Pre Disbursement", value: "PreDisbursement" },
        ];

        const supplierBankNameInputType: ISelectItems[] = ["Access", "Citibank", "Ecobank", "Fidelity", "FirstBank", "GTB", "Heritage",
            "Keystone", "Polaris", "Providus", "StanbicIBTC", "StandardChattered", "Sterling", "SunTrust", "Union", "Unity", "Wema", "Zenith"].map(item => {
                return { name: item, value: item }
            });

        let commercialBanksList: ISelectItems[] = commercialBanks?.map((item) => ({
            name: item.institutionName,
            value: item.cbnCode,
        }));

        let dataEntryInputData: IMultiSelectItems[] = [
            { label: "Personal Details", value: "PersonalDetails" },
            { label: "Contact Details", value: "ContactDetails" },
            { label: "Work Details", value: "WorkDetails" },
            { label: "Reference Details", value: "ReferenceDetails" },
            { label: "Collateral Details", value: "CollateralDetails" },
            { label: "Document Collection", value: "DocumentCollection" },
            { label: "Notepad Details", value: "NotepadDetails" },
            { label: "Income Details", value: "IncomeDetails" },
            { label: "Asset Details", value: "AssetDetails" },
            { label: "Property Details", value: "PropertyDetails" },
            { label: "Loan Details", value: "LoanDetails" },
            { label: "Corporate Details", value: "CorporateDetails" },
            { label: "Stakeholder Details", value: "StakeholderDetails" }
        ];

        // if (_.isEmpty(item)) {
        //     return null
        // }

        return (
            <React.Fragment>
                <div className="container-fluid relative animatedParent animateOnce">
                    <div className="animated fadeInUpShort go">
                        <div className="row my-3 mx-2">
                            <h3>
                                <b>Add Supplier Details</b>
                            </h3>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <AddSingleSupplierDetailsForm
                                    initialValues={{
                                        name: "",
                                        bankName: "",
                                        accountNumber: this.state.accountNumber,
                                        phoneNumber: "",
                                        address: ""
                                    }}
                                    FormComponent={({
                                        fields: { name, bankName, accountNumber, phoneNumber, address },
                                        onChange,
                                        onReloadFields,
                                        onHandleSubmit,
                                    }) => (
                                        <div className="card no-b">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-12">

                                                        <div className="form-row">
                                                            <FormInputComponent
                                                                id="name"
                                                                type="text"
                                                                name="name"
                                                                placeholder=""
                                                                value={name}
                                                                divClass={6}
                                                                required={true}
                                                                validated={validated}
                                                                errors={errors}
                                                                onChange={onChange} />
                                                            <SelectInputComponent
                                                                id="bankName"
                                                                name="bankName"
                                                                items={commercialBanksList}
                                                                value={bankName}
                                                                divClass={6}
                                                                required={true}
                                                                validated={validated}
                                                                errors={errors}
                                                                onChange={async (id, value) => {
                                                                    await onChange(id, value)
                                                                    // this.setState({ bankCode: value, ownUpdate: true });
                                                                    await this.fetchAccountName(value, "bankName")
                                                                }} />
                                                            <FormInputComponent
                                                                id="accountNumber"
                                                                type="text"
                                                                name="accountNumber"
                                                                placeholder=""
                                                                value={accountNumber}
                                                                divClass={6}
                                                                required={true}
                                                                validated={validated}
                                                                errors={errors}
                                                                onChange={onChange}
                                                                onBlur={(e) => { this.fetchAccountName(e.target.value, "accountNumber") }}
                                                            />
                                                            <FormInputComponent
                                                                id="accountName"
                                                                type="text"
                                                                name="accountName"
                                                                placeholder=""
                                                                value={this.state.accountName}
                                                                divClass={6}
                                                                required={false}
                                                                disabled
                                                                validated={validated}
                                                                errors={errors}
                                                                onChange={onChange} />
                                                            <FormInputComponent
                                                                id="phoneNumber"
                                                                type="number"
                                                                name="phoneNumber"
                                                                placeholder=""
                                                                value={phoneNumber}
                                                                divClass={6}
                                                                required={true}
                                                                validated={validated}
                                                                errors={errors}
                                                                onChange={onChange} />
                                                            <FormTextAreaComponent
                                                                id="address"
                                                                type="textarea"
                                                                name="address"
                                                                placeholder=""
                                                                divClass={6}
                                                                value={address}
                                                                required={true}
                                                                validated={validated}
                                                                errors={errors}
                                                                onChange={onChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />

                                            </div>

                                            <div className="card-body">
                                                <button
                                                    type="submit"
                                                    className="btn btn-sm btn-primary"
                                                    disabled={this.state.submitting}
                                                    onClick={(e) => {
                                                        onHandleSubmit(e, ActionTypes.SAVE, "Supplier Details", () => {
                                                            this.onFormSubmit(
                                                                { name, phoneNumber, accountNumber, bankName, address },
                                                                onReloadFields
                                                            );
                                                        });
                                                    }}
                                                >
                                                    {submitting === false ? (
                                                        <React.Fragment>
                                                            <i className="fas fa-lg fa-save mr-3" /> Save
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <i className="fas fa-spin fa-circle-notch" />
                                                        </React.Fragment>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                ></AddSingleSupplierDetailsForm>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default AddSingleSupplierDetails;
