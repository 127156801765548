import React, { Fragment, useContext } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { Accordion, Card } from "react-bootstrap";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import { getCurrentUserSession } from "../../../services/auth/authService";
import FormInputComponent from "../../../components/formInputComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import financialGroupService from "../../../services/hybridAppraisal/financialGroupService";
import appraisalValidationService from "../../../services/hybridAppraisal/appraisalValidationService";
import { decodeToken } from "react-jwt";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import rolesService from "../../../services/auth/rolesService";
import { Rule } from "./financialGroupComponents/Rule";
import NewItemComponent from "./financialGroupComponents/newItemComponent";
import AddRuleModal from "./financialGroupComponents/addRuleModal";
import './financialGroupComponents/financialGroupConfig.css'
import appraisalInstitutionService from "../../../services/hybridAppraisal/appraisalInstitutionService";
import appraisalPlanService from "../../../services/hybridAppraisal/appraisalPlanService";
import EditLevelDetailsModal from "./financialGroupComponents/editLevelDetailsModal";

export function ContextAwareToggle({ eventKey, levelName, privilegeScreens, approverPage, levelID, editLevelFunction }) {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
  // console.log(privilegeScreens)
  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      {/* <b>Level {level}</b> */}
      <b>{levelName} </b>
      {privilegeScreens?.length > 0 ? (<React.Fragment><b>(Privilege Screens: </b> {privilegeScreens.map(x => x).join(', ')})</React.Fragment>) : ("")}

      {/* 
      <button
        className="right"
        > */}

      <i
        className="fas fa-pencil-alt"
        aria-hidden="true"
        style={{ float: "right" }}

        onClick={() => editLevelFunction(
          "Level",
          levelID,
          levelName,
          privilegeScreens?.map(x => { return { label: x } }),
          approverPage,
          false,
        )}
      ></i>
      {/* Edit */}
      {/* </button> */}
    </Card.Header >
  );
}

interface AddFinancialGroupFields {
  name: string;
  minimumAmount: string;
  maximumAmount: string;
}

class AddFinancialGroupForm extends BaseFormComponent<AddFinancialGroupFields> { }

interface IAddFinancialGroupProps { }
interface IAddFinancialGroupState {
  rules: any;
  generatedRules: any;
  financialGroupRules: any;
  data: any;
  editData: any;
  levels: any;
  userRoles: any;
  systemProcess: any;
  maxLevel: number;
  showAddRuleModal: boolean;
  showEditLevelDetailsModal: boolean;
  functionNames: any;
}

class AddFinancialGroup extends React.Component<
  IAddFinancialGroupProps,
  IBaseFormState & IAddFinancialGroupState
> {
  _isMounted = false;
  constructor(props: IAddFinancialGroupProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      rules: [],
      levels: [],
      generatedRules: [],
      financialGroupRules: [],
      data: {
        level: 1,
        combinationNo: 0,
        approverType: "",
        roleID: 0,
        systemProcessID: 0,
        acceptanceScore: 0,
        acceptanceString: "",
        roleName: "",
        processName: "",
        levelName: "",
        userCount: 0,
        privilegeScreens: [],
        approverPage: ""
      },
      editData: {
        level: 1,
        levelName: "",
        privilegeScreens: [],
        approverPage: ""
      },
      maxLevel: 1,
      userRoles: [],
      systemProcess: [],
      showAddRuleModal: false,
      showEditLevelDetailsModal: false,
      functionNames: []
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { errors } = this.state;
    try {
      const decodedToken = decodeToken(getCurrentUserSession().token || "");

      // retrieve all user roles created and appraisal institution details
      const response = await Promise.allSettled([
        rolesService.retrieveAllRoles(),
        appraisalInstitutionService.retrieveByInstitutionCode(decodedToken?.["InstitutionCode"])
      ])
      const userRoles = response[0].status === "fulfilled" ? response[0].value.data : [];
      const appraisalInstitution = response[1].status === "fulfilled" ? response[1].value.data : [];
      const appraisalPlan = await appraisalPlanService.retrieveById(appraisalInstitution.planId);

      // retrieve report and validation assigned to the appraisal plan institution
      let reports = appraisalPlan.data.reports
      reports = reports.map(obj => ({ ...obj, processType: 'Report' }))
      let validations = appraisalPlan.data.validations
      validations = validations.map(obj => ({ ...obj, processType: 'Validation' }))
      const systemProcess = [...reports, ...validations];

      // set state of user roles and system process
      if (this._isMounted) {
        this.setState({
          userRoles: userRoles,
          systemProcess: systemProcess
        });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        errors.response = "Internal server error.";
        this.setState({ errors });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onFormSubmit(fields: AddFinancialGroupFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors: appraisalValidationService.validateAddFinancialGroupForm(fields),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddFinancialGroupFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true });
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const payload: any = {};
      payload.name = fields.name
      payload.institutionCode = decodedToken?.["InstitutionCode"];
      payload.minimumAmount = Number(fields.minimumAmount);
      payload.maximumAmount = Number(fields.maximumAmount);
      payload.rules = this.state.financialGroupRules;
      payload.levels = this.state.levels;

      // console.log("Payload: ", payload)
      const response = await financialGroupService.saveFinancialGroup(payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Add Financial Group",
          description: `Added new Financial Group" ${fields.name}`,
        });
        toast.info(`Work Flow ${payload.name} created successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false });
      this.resetFields();
      onReloadFieldsCB();
    } catch (error) {
      toast.error(error.response.data, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      this.setState({ submitting: false });
      return false;
    } finally {
      return true;
    }
  }

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      rules: [],
    });
  }

  // method to check for duplicate rules on the same level
  hasDuplicate = (newRule) => {
    var duplicates =
      newRule.approverType === "User"
        ? this.state.generatedRules.filter((x) => {
          return (
            (newRule.level === x.level &&
              x.roleID === parseInt(newRule.roleID))
          );
        }) : newRule.approverType === "PrivilegePages" ? []
          : this.state.generatedRules.filter((x) => {
            return (
              (newRule.level === x.level &&
                x.systemProcessID === parseInt(newRule.systemProcessID))
            );
          })
    return duplicates.length > 0;
  };

  newLevel = (newRule) => {
    const maxLevel = Math.max(...this.state.generatedRules.map((o) => o.level), 0);
    return newRule.level > maxLevel
  }

  // method to validate that no rule is stand alone on a level
  onlyRuleOnLevel = () => { }

  // method to handle the removal of a rule
  handleOnRuleDelete = async (rule) => {
    let rules = [...this.state.rules];
    let generatedRules = this.state.generatedRules;
    let financialGroupRules = this.state.financialGroupRules;
    rules = rules.map((k) => k.filter((x) => x !== rule));
    generatedRules = generatedRules.filter((x) => x !== rule);
    financialGroupRules = financialGroupRules.filter((x) => x !== rule);
    if (_.isEmpty(rules[0])) {
      rules = [];
    }
    this.setState({ rules, generatedRules, financialGroupRules }, () => this.arrangeRules(generatedRules));
  };

  // method to add a new rule to the config 
  toggleAddRuleModal = (
    title,
    level,
    combinationNo,
    approverType,
    roleID,
    systemProcessID,
    acceptanceScore,
    acceptanceString,
    roleName,
    processName,
    levelName,
    userCount,
    privilegeScreens,
    approverPage,
    performSave,
  ) => {
    // console.log(approverPage)
    let item = { ...this.state.data };
    item.title = title
    item.level = level;
    item.combinationNo = combinationNo;
    item.approverType = approverType
    item.roleID = roleID;
    item.systemProcessID = systemProcessID;
    item.acceptanceScore = acceptanceScore;
    item.acceptanceString = acceptanceString;
    item.roleName = roleName;
    item.levelName = levelName;
    item.userCount = Number(userCount);
    item.functionName = privilegeScreens?.map(x => x.label);
    item.processName = processName;
    item.approverPage = approverPage;
    this.setState({ data: item, showAddRuleModal: !this.state.showAddRuleModal });
    if (performSave) {
      if (this.hasDuplicate(item)) {
        toast.error("Similar rule already exists", {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
        return false;
      }
      else {
        const rules = [...this.state.rules];
        const generatedRules = [...this.state.generatedRules];
        const financialGroupRules = [...this.state.financialGroupRules];
        const levels = [...this.state.levels];
        rules.push(rules)
        generatedRules.push(item)
        financialGroupRules.push({
          level: item.level,
          combinationNo: item.combinationNo,
          approverType: item.approverType,
          roleID: item.roleID,
          systemProcessID: item.systemProcessID,
          acceptanceScore: item.acceptanceScore,
          acceptanceString: item.acceptanceString,
          userCount: item.approverType === "SystemProcess" ? 1 : item.approverType === "PrivilegePages" ? 0 : Number(item.userCount),
          // functionName: item.functionName
        })
        this.setState({ rules, generatedRules, financialGroupRules });

        if (this.newLevel(item)) {
          levels.push({
            level: item.level,
            name: item.levelName,
            functionNames: item.functionName,
            isApprovalPage: item.approverPage === "Yes" ? true : false
          })
          // console.log(levels)
          this.setState({ levels })
        }
        this.arrangeRules(generatedRules);
      }
    }
  };

  toggleEditLevelDetailsModal = (
    title,
    level,
    // combinationNo,
    // approverType,
    // roleID,
    // systemProcessID,
    // acceptanceScore,
    // acceptanceString,
    // roleName,
    // processName,
    levelName,
    // userCount,
    privilegeScreens,
    approverPage,
    performSave,
  ) => {
    // console.log(approverPage)
    // console.log(levelName)
    // console.log(level)
    let item = { ...this.state.editData };
    item.title = title
    item.level = level;
    // item.combinationNo = combinationNo;
    // item.approverType = approverType
    // item.roleID = roleID;
    // item.systemProcessID = systemProcessID;
    // item.acceptanceScore = acceptanceScore;
    // item.acceptanceString = acceptanceString;
    // item.roleName = roleName;
    item.levelName = levelName;
    // item.userCount = Number(userCount);
    item.privilegeScreens = privilegeScreens?.map(x => x.label);
    item.approverPage = approverPage;
    // item.processName = processName;
    this.setState({ editData: item, showEditLevelDetailsModal: !this.state.showEditLevelDetailsModal });
    // console.log(item.level)
    // console.log(item.privilegeScreens)
    if (performSave) {
      // if (this.hasDuplicate(item)) {
      //   toast.error("Similar rule already exists", {
      //     autoClose: 6000,
      //     type: toast.TYPE.ERROR,
      //     hideProgressBar: false,
      //   });
      //   return false;
      // }
      // else {
      // const rules = [...this.state.rules];
      // const generatedRules = [...this.state.generatedRules];
      // const financialGroupRules = [...this.state.financialGroupRules];
      const levels = [...this.state.levels];
      // rules.push(rules)
      // generatedRules.push(item)
      // financialGroupRules.push({
      //   level: item.level,
      //   combinationNo: item.combinationNo,
      //   approverType: item.approverType,
      //   roleID: item.roleID,
      //   systemProcessID: item.systemProcessID,
      //   acceptanceScore: item.acceptanceScore,
      //   acceptanceString: item.acceptanceString,
      //   userCount: item.approverType === "SystemProcess" ? 1 : item.approverType === "PrivilegePages" ? 0 : Number(item.userCount),
      //   // functionName: item.functionName
      // })
      // this.setState({ rules, generatedRules, financialGroupRules });
      // console.log(levels)

      levels.forEach(x => {
        if (x.level === item.level) {
          x.name = item.levelName
          x.functionNames = item.privilegeScreens
          x.isApprovalPage = item.approverPage === "Yes" ? true : false
        }
      })
      // console.log(levels)
      this.setState({ levels })



      // this.arrangeRules(generatedRules);
      // }
    }
  };

  // method to arrange generated rules by level and combinationNo
  arrangeRules = (allRules) => {
    const maxLevel = Math.max(...allRules.map((o) => o.level), 0);
    let rules: any = [];
    for (let i = 1; i <= maxLevel; i++) {
      const rulesSet = allRules.filter((x) => x.level === i);
      rulesSet.sort(function (a, b) {
        if (a.combinationNo < b.combinationNo) return -1;
        if (a.combinationNo > b.combinationNo) return 1;
        return 0;
      });
      rules.push(rulesSet);
    }
    this.setState({ maxLevel, rules });
    return rules;
  };

  itemEvents = {
    addNewRule: this.toggleAddRuleModal,
    // editLevelDetails: this.toggleEditLevelDetailsModal,
    handleOnRuleDelete: this.handleOnRuleDelete,
  }

  render() {
    const {
      errors,
      validated,
      rules,
      levels,
      userRoles,
      systemProcess,
      functionNames
    } = this.state;

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add Workflow Config</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddFinancialGroupForm
                  initialValues={{
                    name: "",
                    minimumAmount: "",
                    maximumAmount: ""
                  }}
                  FormComponent={({
                    fields: {
                      name,
                      maximumAmount,
                      minimumAmount
                    },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <h5 className="card-title">
                            <i className="fas fa-university mr-3" />
                            Workflow Details
                          </h5>
                          <div className="form-row">
                            <div className="col-md-12">
                              <div className="form-row">
                                <FormInputComponent
                                  id="name"
                                  type="text"
                                  name="name"
                                  placeholder=""
                                  divClass={6}
                                  value={name}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-row">
                                <FormInputComponent
                                  id="minimumAmount"
                                  type="number"
                                  name={"Minimum Amount (₦)"}
                                  placeholder=""
                                  divClass={6}
                                  value={minimumAmount}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="maximumAmount"
                                  type="number"
                                  name={"Maximum Amount (₦)"}
                                  placeholder=""
                                  divClass={6}
                                  value={maximumAmount}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div>
                            <h5 className="card-title">
                              <i className="fas fa-university mr-3" />
                              {`Role Relationships & Validation`}
                            </h5>
                            <div>
                              {/* {console.log(rules)}
                              {console.log(levels)} */}
                              {!_.isEmpty(rules) ? (

                                <div className="card">
                                  <div className="approvable">
                                    {/* {console.log(rules)} */}
                                    {rules.map((step, idx) => (
                                      <div key={idx}>
                                        <Accordion defaultActiveKey={`${idx}`}>
                                          <div key={idx}>
                                            <Card>
                                              <ContextAwareToggle
                                                eventKey={`${idx}`}
                                                levelName={levels?.filter(y => y.level === step?.filter(x => x.level === idx + 1)[0]?.level)[0]?.name}
                                                privilegeScreens={levels?.filter(y => y.level === step?.filter(x => x.level === idx + 1)[0]?.level)[0]?.functionNames}
                                                approverPage={levels?.filter(y => y.level === step?.filter(x => x.level === idx + 1)[0]?.level)[0]?.isApprovalPage}
                                                levelID={idx + 1}
                                                editLevelFunction={this.toggleEditLevelDetailsModal}
                                              ></ContextAwareToggle>
                                              <Accordion.Collapse eventKey={`${idx}`}>
                                                <Card.Body className={"rule-card-body"}>
                                                  {" "}
                                                  <Rule
                                                    step={step}
                                                    key={idx}
                                                    level={idx + 1}
                                                    events={this.itemEvents}
                                                    viewing={false}
                                                  />
                                                </Card.Body>
                                              </Accordion.Collapse>
                                            </Card>
                                          </div>
                                        </Accordion>
                                      </div>

                                    ))}
                                  </div>
                                </div>
                              ) : ("")}
                              <NewItemComponent
                                name="new-level"
                                content="Click to add new level"
                                type="level"
                                events={this.itemEvents}
                                eventData={{
                                  title: "Level",
                                  level: rules.length + 1,
                                  combinationNo: 0,
                                  approverType: "",
                                  roleID: 0,
                                  systemProcessID: 0,
                                  acceptanceScore: 0,
                                  acceptanceString: "",
                                  levelName: "",
                                  userCount: 0,
                                  privilegeScreens: "",
                                  approverPage: ""
                                }}
                              />
                            </div>
                          </div>
                          <hr />
                          <div className="card-body">
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                              disabled={this.state.submitting}
                              onClick={(e) => {
                                onHandleSubmit(
                                  e,
                                  ActionTypes.SAVE,
                                  "Work Flow",
                                  () => {
                                    this.onFormSubmit(
                                      {
                                        name,
                                        minimumAmount,
                                        maximumAmount
                                      },
                                      onReloadFields
                                    );
                                  }
                                );
                              }}
                            >
                              {this.state.submitting === false ? (
                                <React.Fragment>
                                  <i className="fas fa-lg fa-save mr-3" /> Save
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                  Please wait...
                                </React.Fragment>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                ></AddFinancialGroupForm>
              </div>
            </div>
          </div>
        </div >
        <AddRuleModal
          userRoles={userRoles}
          systemProcess={systemProcess}
          data={this.state.data}
          showAddRuleModal={this.state.showAddRuleModal}
          toggleAddRuleModal={this.toggleAddRuleModal}
        />
        <EditLevelDetailsModal
          userRoles={userRoles}
          systemProcess={systemProcess}
          data={this.state.editData}
          showEditLevelDetailsModal={this.state.showEditLevelDetailsModal}
          toggleEditLevelDetailsModal={this.toggleEditLevelDetailsModal}
        />
      </React.Fragment >
    );
  }
}

export default AddFinancialGroup;
