import http from "../httpService";

const reportBaseAddress = process.env.REACT_APP_REPORTSCENTER_BASEADDRESS

export function filterAppzoneSettlementAndPayoutReport(
  pageSize: number,
  pageNumber: number,
  data: any
) {
  var lenderInstitutionCode = typeof data.lenderInstitutionCode === "undefined" ? "" : `&LenderInstitutionCode=${data.lenderInstitutionCode}`
  var lenderInstitutionName = typeof data.lenderInstitutionName === "undefined" ? "" : `&LenderInstitutionName=${data.lenderInstitutionName}`
  var debitedInstitution = typeof data.debitedInstitution === "undefined" ? "" : `&DebitedInstitution=${data.debitedInstitution}`
  var sweepChannel = typeof data.thirdPartySweepChannel === "undefined" ? "" : `&SweepChannel=${data.thirdPartySweepChannel}`
  return http.get(
    reportBaseAddress +
    `v1/lend-settlement-payout-report?PageNumber=${pageNumber
    }&PageSize=${pageSize
    }${lenderInstitutionCode
    }${lenderInstitutionName
    }${debitedInstitution
    }${sweepChannel}`,
  );
}

export function filterSettlementAndPayoutReport(
  insitutionCode: string,
  pageSize: number,
  pageNumber: number,
  data: any
) {
  var settlementAccount = typeof data.settlementAccount === "undefined" ? "" : `&SettlementAccount=${data.settlementAccount}`
  var settlementTransactionRef = typeof data.settlementTransactionRef === "undefined" ? "" : `&SettlementTransactionRef=${data.settlementTransactionRef}`
  var settlementStatus = typeof data.settlementStatus === "undefined" ? "" : `&SettlementStatus=${data.settlementStatus}`
  var sweepChannel = typeof data.thirdPartySweepChannel === "undefined" ? "" : `&SweepChannel=${data.thirdPartySweepChannel}`
  return http.get(
    reportBaseAddress +
    `v1/ofi-settlement-payout-report?PageNumber=${pageNumber
    }&PageSize=${pageSize
    }&InstitutionCode=${insitutionCode
    }${settlementAccount
    }${settlementTransactionRef
    }${settlementStatus
    }${sweepChannel}`,
  );
}

export default {
  filterAppzoneSettlementAndPayoutReport,
  filterSettlementAndPayoutReport
};