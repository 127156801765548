import * as React from "react";
import { IFilterData } from "../../services/approval/approvalItemService";
import {
  generateGUID,
  splitAtUpper,
  firstCharToUpper,
} from "./../../services/utility/textFormatService";

export interface ITableFilterPaneProps {
  filteredItems: IFilterData;
  handleFilterRemove: any;
}

export interface ITableFilterPaneState { }

class TableFilterPane extends React.Component<
  ITableFilterPaneProps,
  ITableFilterPaneState
> {
  state = {};
  render() {
    const { filteredItems, handleFilterRemove } = this.props;
    const renderAny = Object.keys(filteredItems).length > 0;
    // console.log(filteredItems)
    return (
      <React.Fragment>
        {renderAny ? (
          <React.Fragment>
            <h5 className="card-title">Filters</h5>
            <div className="row filters-container pt-2 pb-3 pl-1">
              {Object.keys(filteredItems).map((_key) => (
                <div key={generateGUID()} className="filter-list-div mt-2">
                  <div
                    id={_key}
                    className="filter-list-group-item btn-primary pl-2"
                  >
                    <b>{splitAtUpper(firstCharToUpper(_key))}</b>{" "}
                    {filteredItems[_key].item1 === "="
                      ? "IS"
                      : filteredItems[_key].item1 === "like"
                        ? "LIKE"
                        : "BETWEEN"}{" "}
                    <b style={{ fontStyle: "italic" }}>
                      {filteredItems[_key].item2}
                    </b>
                    <i
                      className="fa fa-times float-right mt-1 mr-1 ml-3"
                      onClick={() => handleFilterRemove(_key)}
                    ></i>
                  </div>
                </div>
              ))}
            </div>
            <hr />
          </React.Fragment>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default TableFilterPane;
