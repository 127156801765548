import React, { Component } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import BaseFormComponent, { IBaseFormState } from "../../../../../../components/BaseFormComponent";
import FormInputComponent from "../../../../../../components/formInputComponent";
import SelectInputComponent, { ISelectItems } from "../../../../../../components/formSelectComponent";
import { ActionTypes } from "../../../../../../enums/actionTypes";
import loanRequestService from "../../../../../../services/hybridAppraisal/loanRequestService";
import { countries } from "./../../../../../../services/utility/countries";
import hybridAppraisalValidationService from "../../../../../../services/hybridAppraisal/hybridAppraisalValidationService";
import { getCurrentUserSession, getCurrentUsersRoles } from "../../../../../../services/auth/authService";
import DetailItem from "../../../../../../components/detailItem";
import textFormatService, { generateGUID } from "../../../../../../services/utility/textFormatService";
import { decodeToken } from "react-jwt";
import { bvnEnquiry } from "../../../../../../services/ofiHandshake/bvnEnquiry";
import institutionsService from "../../../../../../services/auth/institutionsService";

interface GuarantorDetailsFields {
  title: string;
  relationship: string;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  address: string;
  city: string;
  state: string;
  country: string;
  phoneNumber: string;
  phoneNumber2: string;
  email: string;
  locationCoordinates: string;
  monthlyIncome: string;
  otherAssets: string;
  liabilities: string;
  maritalStatus: string;
  guarantorsBVN: string;
  occupation: string;
  stateOfOrigin: string;
  lga: string;
  officeAddress: string;
  designation: string;
  loanAmountGuaranteed: string;
  employerName: string;
  identificationType: string;
  identificationNumber: string;
  identificationExpiryDate: string;
}

class GuarantorDetailsForm extends BaseFormComponent<GuarantorDetailsFields> { }

interface GuarantorDetailsProps {
  values: any;
  reloadItem: any;
  mandatoryFields: any;
}

interface GuarantorDetailsState {
  guarantors: GuarantorDetailsFields[];
  ownUpdate: boolean;
  country: any;
  bvnResponses: any;
  institution: any;
  fetchingBVN: boolean;
}

class GuarantorDetails extends Component<
  GuarantorDetailsProps,
  IBaseFormState & GuarantorDetailsState
> {
  _isMounted = false;
  constructor(props: GuarantorDetailsProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      guarantors: [],
      ownUpdate: false,
      country: "",
      bvnResponses: [],
      institution: {},
      fetchingBVN: false
    };
  }

  static getDerivedStateFromProps(
    nextProps: GuarantorDetailsProps,
    prevState: GuarantorDetailsState
  ) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isEmpty(nextProps.values.guarantors)) {
      return null;
    }
    return {
      guarantors: nextProps.values.guarantors
    };
  }

  onFormSubmit(fields: GuarantorDetailsFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        { errors: {}, ownUpdate: true },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: GuarantorDetailsFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const loanRequest = await loanRequestService.retrieveById(this.props.values.id);
      const loanRequestDetail = loanRequest.data;
      const payload: any = { ...loanRequestDetail };
      payload.guarantors = this.state.guarantors

      const response = await loanRequestService.updateLoanRequest(payload);
      if (response.status >= 200 && response.status <= 300) {
        toast.info(`Guarantor Details saved successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false, ownUpdate: true });
      this.resetFields();
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false, ownUpdate: true });
      return false;
    } finally {
      return true;
    }
  }

  handleOnAddGuarantor = async (item: GuarantorDetailsFields, onReloadFieldsCB: any) => {
    const { mandatoryFields } = this.props;
    try {
      this.setState(
        { errors: hybridAppraisalValidationService.validateGuarantorDetailsForm(item, mandatoryFields), ownUpdate: true },
        async () => {
          if (Object.keys(this.state.errors).length === 0) {
            if (this.state.institution?.shortName === "NextPayDay") {
              this.setState({ fetchingBVN: true })
              const bvnResponses = [...this.state.bvnResponses];
              const bvnResponse = await bvnEnquiry(this.state.institution?.code, item.guarantorsBVN)

              bvnResponses.push(bvnResponse.data)

              this.setState({ bvnResponses: bvnResponses })
            }
            const guarantors = [...this.state.guarantors];
            guarantors.push(item);
            this.setState({ guarantors, ownUpdate: true });
            this.resetFields();
            // onReloadFieldsCB();
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
    } catch (error) {
      return false;
    }
  }

  handleOnRemoveGuarantor = (item: GuarantorDetailsFields) => {
    const guarantors = [...this.state.guarantors];
    if (guarantors.includes(item)) {
      guarantors.splice(
        guarantors.indexOf(item), 1
      );
    }
    this.setState({ guarantors, ownUpdate: true });
  };

  async componentDidMount() {
    this._isMounted = true;
    var decodedToken = decodeToken(getCurrentUserSession().token || "")
    let institutionDetails = await institutionsService.retrieveInstitutionByCode(decodedToken?.["InstitutionCode"])
    console.log(institutionDetails.data)
    this.setState({ institution: institutionDetails.data })
  }

  async componentWillUnmount() {
    this._isMounted = false;
  }

  getRequiredFields(title): boolean {
    const { mandatoryFields } = this.props;
    var required = mandatoryFields?.inputFields?.filter(x => x.fieldName === title)[0]?.mandatory;
    if (required === undefined) {
      return false;
    }
    return required;
  }

  resetFields() {
    this.setState({ validated: false, submitting: false, errors: [], country: "", ownUpdate: true, fetchingBVN: false });
  }

  render() {
    const { errors, validated, guarantors } = this.state;
    const { values } = this.props;

    let statesInputData: ISelectItems[] = [];
    let countriesInputData: ISelectItems[] = [];
    countries.forEach((country) => {
      countriesInputData.push({ name: country.name, value: country.name });
    });
    countries
      .filter((c) => c.name === this.state.country)[0]
      ?.states.forEach((state) => {
        statesInputData.push({ name: state.name, value: state.name });
      });

    let titleInputData: ISelectItems[] = [
      { name: "Mr.", value: "Mr." },
      { name: "Mrs.", value: "Mrs." },
      { name: "Miss", value: "Miss" },
      { name: "Chf.", value: "Chf." },
      { name: "Dr.", value: "Dr." },
      { name: "Prof.", value: "Prof." },
    ];

    let relationshipInputData: ISelectItems[] = [
      "Father",
      "Mother",
      "Sister",
      "Brother",
      "Uncle",
      "Aunty",
      "Cousin",
      "Niece",
      "Nephew",
      "Friend",
      "Colleague",
      "Business Partner",
    ].map(item => {
      return { name: item, value: item }
    });

    let genderInputData: ISelectItems[] = [
      { name: "Male", value: "Male" },
      { name: "Female", value: "Female" },
    ];

    let maritalStatusInputData: ISelectItems[] = [
      { name: "Married", value: "Married" },
      { name: "Widowed", value: "Widowed" },
      { name: "Divorced", value: "Divorced" },
      { name: "Single", value: "Single" },
      // { name: "Other", value: "Other" },
    ];

    let identificationTypeInputData: ISelectItems[] = [
      { name: "International Passport", value: "InternationalPassport" },
      { name: "Drivers License", value: "DriversLicense" },
      { name: "Voters' Card", value: "VotersCard" },
      { name: "Work ID", value: "WorkID" },
      { name: "Ecowas Passport", value: "EcowasPassport" },
      { name: "Residential Permit", value: "ResidentialPermit" },
      { name: "National ID", value: "NationalID" },
    ];

    return (
      <GuarantorDetailsForm
        initialValues={{
          title: "",
          relationship: "",
          firstName: "",
          middleName: "",
          lastName: "",
          gender: "",
          dateOfBirth: "",
          address: "",
          city: "",
          state: "",
          country: "",
          phoneNumber: "",
          phoneNumber2: "",
          email: "",
          locationCoordinates: "",
          monthlyIncome: "",
          otherAssets: "",
          liabilities: "",
          maritalStatus: "",
          guarantorsBVN: "",
          occupation: "",
          stateOfOrigin: "",
          lga: "",
          officeAddress: "",
          designation: "",
          loanAmountGuaranteed: "",
          employerName: "",

          identificationType: "",
          identificationNumber: "",
          identificationExpiryDate: "",
        }}
        FormComponent={({
          fields: {
            title,
            relationship,
            firstName,
            middleName,
            lastName,
            gender,
            dateOfBirth,
            address,
            city,
            state,
            country,
            phoneNumber,
            phoneNumber2,
            email,
            locationCoordinates,
            monthlyIncome,
            otherAssets,
            liabilities,
            maritalStatus,
            guarantorsBVN,
            occupation,
            stateOfOrigin,
            lga,
            officeAddress,
            designation,
            loanAmountGuaranteed,
            employerName,
            identificationType,
            identificationNumber,
            identificationExpiryDate,
          },
          onChange,
          onReloadFields,
          onHandleSubmit,
        }) => (
          <div className="form-row">
            <div className="col-md-12">

              {/* Form Details */}
              <div className="form-row">
                <SelectInputComponent
                  id="title"
                  name="title"
                  divClass={6}
                  value={title}
                  items={titleInputData}
                  required={this.getRequiredFields("referenceTitle")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <SelectInputComponent
                  id="relationship"
                  name="relationship"
                  divClass={6}
                  value={relationship}
                  items={relationshipInputData}
                  required={this.getRequiredFields("referenceRelationship")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  id="firstName"
                  name="firstName"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={firstName}
                  required={this.getRequiredFields("referenceFirstName")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  id="middleName"
                  name="middleName"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={middleName}
                  required={this.getRequiredFields("referenceMiddleName")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  id="lastName"
                  name="lastName"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={lastName}
                  required={this.getRequiredFields("referenceLastName")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <SelectInputComponent
                  id="gender"
                  name="gender"
                  divClass={6}
                  value={gender}
                  items={genderInputData}
                  required={this.getRequiredFields("referenceGender")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <SelectInputComponent
                  id="maritalStatus"
                  name="maritalStatus"
                  divClass={6}
                  value={maritalStatus}
                  items={maritalStatusInputData}
                  required={this.getRequiredFields("referenceMaritalStatus")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  id="dateOfBirth"
                  name="dateOfBirth"
                  type="date"
                  placeholder=""
                  divClass={6}
                  value={dateOfBirth}
                  required={this.getRequiredFields("referenceDateOfBirth")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  id="address"
                  name="address"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={address}
                  required={this.getRequiredFields("referenceAddress")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  id="city"
                  name="city"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={city}
                  required={this.getRequiredFields("referenceCity")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <SelectInputComponent
                  id="country"
                  name="country"
                  divClass={6}
                  value={this.state.country}
                  items={countriesInputData}
                  required={this.getRequiredFields("referenceCountry")}
                  validated={validated}
                  errors={errors}
                  onChange={(id, value) => {
                    onChange(id, value);
                    this.setState({ country: value, ownUpdate: true })
                  }}
                />
                <SelectInputComponent
                  id="state"
                  name="state"
                  divClass={6}
                  value={state}
                  items={statesInputData}
                  required={this.getRequiredFields("referenceState")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Phone Number 1"
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={phoneNumber}
                  required={this.getRequiredFields("referencePhoneNumber1")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Phone Number 2"
                  id="phoneNumber2"
                  name="phoneNumber2"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={phoneNumber2}
                  required={this.getRequiredFields("referencePhoneNumber2")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <SelectInputComponent
                  label="Identification Type"
                  id="identificationType"
                  name="identificationType"
                  divClass={6}
                  value={identificationType}
                  items={identificationTypeInputData}
                  required={this.getRequiredFields("identificationType")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Identification Number"
                  id="identificationNumber"
                  name="identificationNumber"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={identificationNumber}
                  required={this.getRequiredFields("identificationNumber")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Identification Expiry Date"
                  id="identificationExpiryDate"
                  name="identificationExpiryDate"
                  type="date"
                  placeholder=""
                  divClass={6}
                  value={identificationExpiryDate}
                  required={this.getRequiredFields("identificationExpiryDate")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Email Address"
                  id="email"
                  name="email"
                  type="email"
                  placeholder=""
                  divClass={6}
                  value={email}
                  required={this.getRequiredFields("referenceEmailAddress")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Occupation"
                  id="occupation"
                  name="occupation"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={occupation}
                  required={this.getRequiredFields("referenceOccupation")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="State of Origin"
                  id="stateOfOrigin"
                  name="stateOfOrigin"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={stateOfOrigin}
                  required={this.getRequiredFields("referenceStateOfOrigin")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="LGA"
                  id="lga"
                  name="lga"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={lga}
                  required={this.getRequiredFields("referenceLga")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Name of Employer/Business"
                  id="employerName"
                  name="employerName"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={employerName}
                  required={this.getRequiredFields("referenceEmployerName")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Designation/Position"
                  id="designation"
                  name="designation"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={designation}
                  required={this.getRequiredFields("referenceDesignation")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Office Address"
                  id="officeAddress"
                  name="officeAddress"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={officeAddress}
                  required={this.getRequiredFields("referenceOfficeAddress")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Guarantor BVN"
                  id="guarantorsBVN"
                  name="guarantorsBVN"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={guarantorsBVN}
                  required={this.getRequiredFields("referenceBvn")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Guarantor's Monthly Income"
                  id="monthlyIncome"
                  name="monthlyIncome"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={monthlyIncome}
                  required={this.getRequiredFields("referenceMonthlyIncome")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Guarantor's Other Assets Value/Cash Position"
                  id="otherAssets"
                  name="otherAssets"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={otherAssets}
                  required={this.getRequiredFields("referenceOtherAssetsValue")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Guarantor's Liabilities"
                  id="liabilities"
                  name="liabilities"
                  type="email"
                  placeholder=""
                  divClass={6}
                  value={liabilities}
                  required={this.getRequiredFields("referenceLiabilities")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Loan Amount Guaranteed"
                  id="loanAmountGuaranteed"
                  name="loanAmountGuaranteed"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={loanAmountGuaranteed}
                  required={this.getRequiredFields("loanAmountGuaranteed")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Location Co-ordinates"
                  id="locationCoordinates"
                  name="locationCoordinates"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={locationCoordinates}
                  required={this.getRequiredFields("referenceLocationCoordinates")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
              </div>

              {/* Add Guarantor */}
              <div className="form-row">
                <div className="my-1 mx-2">
                  {(getCurrentUsersRoles().includes("updatedataentry") && (
                    <button
                      className="btn btn-sm btn-outline-primary my-4"
                      style={{ cursor: "pointer" }}
                      type="button"
                      onClick={() =>
                        this.handleOnAddGuarantor(
                          {
                            title,
                            relationship,
                            firstName,
                            middleName,
                            lastName,
                            gender,
                            dateOfBirth,
                            address,
                            city,
                            state,
                            country,
                            phoneNumber,
                            phoneNumber2,
                            email,
                            locationCoordinates,
                            monthlyIncome,
                            otherAssets,
                            liabilities,
                            maritalStatus,
                            guarantorsBVN,
                            occupation,
                            stateOfOrigin,
                            lga,
                            officeAddress,
                            designation,
                            loanAmountGuaranteed,
                            employerName,
                            identificationType,
                            identificationNumber,
                            identificationExpiryDate,
                          },
                          onReloadFields
                        )
                      }
                    >

                      {this.state.fetchingBVN === false ? (
                        <React.Fragment>
                          <i className="far fa-save fa-lg mr-2" />{" "}
                          Add
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <i className="far fa-save fa-lg mr-2" />{" "}
                          Please wait...
                        </React.Fragment>
                      )}
                    </button>
                  ))}
                </div>
              </div>

              {/* Guarantor Table */}
              <div className=" form-row col-md-12">
                <div
                  className="table-responsive text-nowrap"
                  style={{
                    maxHeight: "150px",
                    overflowY: "scroll",
                  }}
                >
                  <table className="table table-hover table-content table-sm table-striped mb-0">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>#</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Referee/Guarantor Name</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Phone Number</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Email</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Monthly Income</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Other Assets</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Liabilities</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Location Coordinates</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {guarantors.map((item) => (
                        <tr key={guarantors.indexOf(item)}>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {guarantors.indexOf(item) + 1}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.firstName} {" "} {item.middleName} {" "} {item.lastName}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.phoneNumber}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.email}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.monthlyIncome}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.otherAssets}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.liabilities}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.locationCoordinates}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            <i
                              className="far fa-trash-alt ml-5 red-text fa-lg"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                this.handleOnRemoveGuarantor(
                                  item
                                )
                              }
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {this.state.bvnResponses.length === 0 ? ("") : (
                  <div className="card mt-3" key={generateGUID()}>
                    <div className="card-header clear-fix">
                      <h6
                        className="float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        <b>Guarantor Validation</b>
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="form-row">
                        {this.state.bvnResponses.map((item, idx) => (
                          <><DetailItem
                            label={"Guarantor " + (idx + 1) + " BVN"}
                            labelSize={7}
                            valueSize={5}
                            value={item.bvn} />
                            <DetailItem
                              label={"Guarantor " + (idx + 1) + " BVN Name"}
                              labelSize={7}
                              valueSize={5}
                              value={item.fullname} />
                            {/* <DetailItem
                              label="Guarantor BVN Phone Number"
                              labelSize={7}
                              valueSize={5}
                              value={item.firstName + " " + item.lastName} /> */}
                            <DetailItem
                              label={"Guarantor " + (idx + 1) + " BVN Date of Birth"}
                              labelSize={7}
                              valueSize={5}
                              value={textFormatService.formatShortTimeString(item.dob)} /></>
                        )
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* Save Guarantor */}
              <div className="card-body float-right">
                {(getCurrentUsersRoles().includes("updatedataentry") && (
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary"
                    disabled={this.state.submitting}
                    onClick={(e) => {
                      onHandleSubmit(
                        e,
                        ActionTypes.SAVE,
                        "Guarantor Details",
                        () => {
                          this.onFormSubmit(
                            {
                              title,
                              relationship,
                              firstName,
                              middleName,
                              lastName,
                              gender,
                              dateOfBirth,
                              address,
                              city,
                              state,
                              country,
                              phoneNumber,
                              phoneNumber2,
                              email,
                              locationCoordinates,
                              otherAssets,
                              liabilities,
                              monthlyIncome,
                              maritalStatus,
                              guarantorsBVN,
                              occupation,
                              stateOfOrigin,
                              lga,
                              officeAddress,
                              designation,
                              loanAmountGuaranteed,
                              employerName,
                              identificationType,
                              identificationNumber,
                              identificationExpiryDate,
                            },
                            onReloadFields
                          );
                        }
                      );
                    }}
                  >
                    {this.state.submitting === false ? (
                      <React.Fragment>
                        <i className="fas fa-lg fa-save mr-3" /> Save
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                        Please wait...
                      </React.Fragment>
                    )}
                  </button>
                ))}
              </div>

            </div>
          </div>
        )}
      ></GuarantorDetailsForm>
    );
  }
}

export default GuarantorDetails;
