import React, { Fragment } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import {
  Modal,
  Button,
  Card,
} from "react-bootstrap";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import authValidationService from "../../../services/auth/authValidationService";
import DetailItem from "../../../components/detailItem";
import { ActionTypes } from "../../../enums/actionTypes";
import feeConfigService from "../../../services/sweep/feeConfigService";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import institutionsService from "../../../services/auth/institutionsService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

interface EditInstitutionRecovaFeeConfigurationFields {
  activeLoansPercentage?: string;
  lostLoansPercentage?: string;
  flatAmount?: string;
  minimumFee?: string;
  maximumFee?: string;
  debitedBankCommission?: string;
  sponsorBankCommission?: string;
}

class EditInstitutionRecovaFeeConfigurationForm extends BaseFormComponent<EditInstitutionRecovaFeeConfigurationFields> { }

interface IEditInstitutionRecovaFeeConfigurationProps {
  item: any;
  recovaFeeConfigItem: any;
  toggleEditModal: any;
}

interface IEditInstitutionRecovaFeeConfigurationState { }
class EditInstitutionRecovaFeeConfiguration extends React.Component<
  IEditInstitutionRecovaFeeConfigurationProps,
  IBaseFormState & IEditInstitutionRecovaFeeConfigurationState
> {
  constructor(props: IEditInstitutionRecovaFeeConfigurationProps) {
    super(props);
    this.state = {
      validated: false,
      errors: {},
      submitting: false
    };
  }

  onFormSubmit(
    fields: EditInstitutionRecovaFeeConfigurationFields,
    onReloadFieldsCB: any
  ): boolean {
    try {
      this.setState(
        {
          errors:
            authValidationService.validateAddRecovaFeeConfigurationForm(fields),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (
    fields: EditInstitutionRecovaFeeConfigurationFields,
    onReloadFieldsCB: any
  ) => {
    try {
      this.setState({ submitting: true });
      const payload = this.props.recovaFeeConfigItem;

      var institution = await institutionsService.retrieveInstitutionByCode(this.props.item.code);
      payload.activeLoansPercentage = Number(fields.activeLoansPercentage);
      payload.lostLoansPercentage = Number(fields.lostLoansPercentage);
      payload.flatAmount = Number(fields.flatAmount);
      payload.minimumFee = Number(fields.minimumFee);
      payload.maximumFee = Number(fields.maximumFee);
      payload.debitedBankCommission = Number(fields.debitedBankCommission);
      payload.sponsorBankCommission = Number(fields.sponsorBankCommission);
      payload.institutionId = institution.data.id;
      payload.institutionCode = institution.data.code;
      payload.repaymentType = payload.repaymentType;

      var response = await feeConfigService.saveFeeConfig(payload);

      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit Recova Fee Configuration",
          description: `Edited details for Recova Fee Configuration ${institution.data.name}`,
        });
        toast.info(
          `Recova Fee Configuration Edited successfully!`,
          {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          }
        );
      }
      onReloadFieldsCB();
      this.setState({ submitting: false },
        () => this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      // console.log("submission error: ", error.message);
      return false
    } finally {
      this.setState({ submitting: false });
    }
  };

  render() {
    const {
      item,
      recovaFeeConfigItem
    } = this.props;
    const {
      validated,
      submitting,
      errors
    } = this.state;

    return (
      <EditInstitutionRecovaFeeConfigurationForm
        initialValues={{
          activeLoansPercentage: recovaFeeConfigItem.activeLoansPercentage,
          lostLoansPercentage: recovaFeeConfigItem.lostLoansPercentage,
          flatAmount: recovaFeeConfigItem.flatAmount,
          minimumFee: recovaFeeConfigItem.minimumFee,
          maximumFee: recovaFeeConfigItem.maximumFee,
          debitedBankCommission: recovaFeeConfigItem.debitedBankCommission,
          sponsorBankCommission: recovaFeeConfigItem.sponsorBankCommission,
        }}
        FormComponent={({
          fields: {
            activeLoansPercentage,
            lostLoansPercentage,
            flatAmount,
            minimumFee,
            maximumFee,
            debitedBankCommission,
            sponsorBankCommission
          },
          onChange,
          onReloadFields,
          onHandleSubmit
        }) => (
          <Card.Body>
            <Fragment>
              {_.isEmpty(recovaFeeConfigItem) ? (
                "Central Fee Configuration for Recova is not available"
              ) : (
                <Fragment>
                  <div className="card">
                    <div className="card-header clear-fix">
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        Fee
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <dl className="row">
                            <DetailItem
                              label="Active Loan Percentage"
                              labelSize={8}
                              valueSize={4}
                              value={
                                <DetailItemInputComponent
                                  id="activeLoansPercentage"
                                  type="text"
                                  name="activeLoansPercentage"
                                  placeholder=""
                                  value={activeLoansPercentage}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                            />
                            <DetailItem
                              label="Lost Loan Percentage"
                              labelSize={8}
                              valueSize={4}
                              value={
                                <DetailItemInputComponent
                                  id="lostLoansPercentage"
                                  type="text"
                                  name="lostLoansPercentage"
                                  placeholder=""
                                  value={lostLoansPercentage}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                            />
                            <DetailItem
                              label="Flat Amount Per Transaction"
                              labelSize={8}
                              valueSize={4}
                              value={
                                <DetailItemInputComponent
                                  id="flatAmount"
                                  type="text"
                                  name="flatAmount"
                                  placeholder=""
                                  value={flatAmount}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                            />
                          </dl>
                        </div>
                        <div className="col-md-6">
                          <dl className="row">
                            <DetailItem
                              label="Minimum Fee Per Transaction"
                              labelSize={8}
                              valueSize={4}
                              value={
                                <DetailItemInputComponent
                                  id="minimumFee"
                                  type="text"
                                  name="minimumFee"
                                  placeholder=""
                                  value={minimumFee}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                            />
                            <DetailItem
                              label="Maximum Fee Per Transaction"
                              labelSize={8}
                              valueSize={4}
                              value={
                                <DetailItemInputComponent
                                  id="maximumFee"
                                  type="text"
                                  name="maximumFee"
                                  placeholder=""
                                  value={maximumFee}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                            />
                          </dl>
                        </div>
                      </div>
                    </div>
                    <div className="card-header clear-fix">
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        Commission
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <dl className="row">
                            <DetailItem
                              label="Debited Bank Commission"
                              labelSize={8}
                              valueSize={4}
                              value={
                                <DetailItemInputComponent
                                  id="debitedBankCommission"
                                  type="text"
                                  name="debitedBankCommission"
                                  placeholder=""
                                  value={debitedBankCommission}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                            />
                          </dl>
                        </div>
                        <div className="col-md-6">
                          <dl className="row">
                            {item.integrationCode == "CommercialBankIntegration" ? (
                              <DetailItem
                                label="Sponsor Bank Commission"
                                labelSize={8}
                                valueSize={4}
                                value={
                                  <DetailItemInputComponent
                                    id="sponsorBankCommission"
                                    type="text"
                                    name="sponsorBankCommission"
                                    placeholder=""
                                    value={sponsorBankCommission}
                                    required={true}
                                    validated={validated}
                                    errors={errors}
                                    onChange={onChange}
                                  />
                                }
                              />
                            ) : (
                              ''
                            )}
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
            </Fragment>
            {_.isEmpty(recovaFeeConfigItem) ?
              <></> :
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.UPDATE,
                      "Recova Fee Configuration",
                      () => {
                        this.onFormSubmit(
                          {
                            activeLoansPercentage,
                            lostLoansPercentage,
                            flatAmount,
                            minimumFee,
                            maximumFee,
                            debitedBankCommission,
                            sponsorBankCommission
                          },
                          onReloadFields
                        );
                      }
                    );
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
              </Modal.Footer>
            }
          </Card.Body>
        )}
      ></EditInstitutionRecovaFeeConfigurationForm>
    );
  }
}

export default EditInstitutionRecovaFeeConfiguration;
