import React, { Component, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import {
  Modal,
  Button,
  Card,
  Accordion,
} from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import { ActionTypes } from "../../../enums/actionTypes";
import swal from "sweetalert";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import { toast } from "react-toastify";
import { getCurrentUserSession, getCurrentUsersRoles } from "../../../services/auth/authService";
import { splitAtUpper } from "../../../services/utility/textFormatService";
import { ContextAwareToggle } from "./creditQualificationRuleComponents/appzoneAddCQR";
import scoringCardService from "../../../services/creditAssessment/scoringCardService";
import { decodeToken } from "react-jwt";

interface ICreditQualificationRuleDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  showTrail: any;
  currentUserRoles: any;
  item: any;
}

interface ICreditQualificationRuleDetailState {
  item: any;
  updatingStatus: boolean;
  scoreCardList: any;
}
class CreditQualificationRuleDetail extends Component<
  ICreditQualificationRuleDetailProps,
  ICreditQualificationRuleDetailState
> {
  _isMounted = false;
  constructor(props: ICreditQualificationRuleDetailProps) {

    super(props);
    this.state = {
      item: {},
      updatingStatus: false,
      scoreCardList: []
    };
  }

  static getDerivedStateFromProps(
    nextProps: ICreditQualificationRuleDetailProps,
    prevState
  ) {
    return {
      item: nextProps.item,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    const scoreCard = await scoringCardService.retrieveScoreCardByInstitutionCode(decodedToken?.["InstitutionCode"] || "")
    const scoreCardList = await scoreCard.data

    if (this._isMounted) {
      this.setState({
        scoreCardList: scoreCardList.data,
      });
    }
  }

  onHandleRemove = (e: any, actionType: ActionTypes, entityName: string) => {
    this.setState({ updatingStatus: true });
    if (!actionType) {
      this.handleRemove(this.props.item);
    } else {
      swal({
        title: "Confirm!",
        text: `Are you sure you want to proceed to ${ActionTypes[actionType]} this ${entityName}?`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode: true,
      }).then((resp) => {
        if (resp) {
          // console.log("cancelling action...");
          this.setState({ updatingStatus: false });
        } else {
          this.handleRemove(this.props.item);
        }
      });
    }
  };

  handleRemove = async (_item: any) => {
    try {
      let response: any = {};
      this.setState({ updatingStatus: true });
      await activityTrailService.saveActivityTrail({
        actionType: `Remove Credit Qualification Rule`,
        description: `Clicked on Remove to Remove Credit Qualification Rule for ${_item.loanProductName}`,
      });
      // response =
      //   await creditQualificationRuleService.removeCreditQualificationIndicatorsFromInstitution(
      //     "",
      //     []
      //   );

      toast.info(
        `Credit Qualification Rule for ${_item.loanProductName} Removed successfully! `,
        {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        }
      );
      // console.log("response received", response);
      this.setState({ updatingStatus: false, item: _item });
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // return false;
      }
      // console.log(ex);
      // console.log(ex.response.data);
      this.setState({ updatingStatus: false, item: _item });
    }
  };
  // {
  //   "name": "Payday Rule 3",
  //   "supportedLoanOriginationChannels": [
  //     {
  //       "loanOriginationChannelId": "27cc3778-319a-4deb-bbc9-6035eec7cff9",
  //       "channelName": "Agent"
  //     }
  //   ],
  //   "creditQualificationRuleIndicators": [
  //     {
  //       "creditQualificationIndicatorId": "1847cedd-8fc5-42bc-b98a-47e5c101646b",
  //       "indicatorName": "Customer meets loan amount maximum percentage of net available salary requirement",
  //       "indicatorParams": [
  //         {
  //           "creditQualificationIndicatorId": "1847cedd-8fc5-42bc-b98a-47e5c101646b",
  //           "paramName": "CustomerMeetsLoanAmountMaximumPercentageNetAvailableSalary.PercentageValue",
  //           "paramValue": "50"
  //         }
  //       ]
  //     }
  //   ],
  //   "loanProducts": [],
  //   "loanOriginationWorkflowId": null,
  //   "loanOriginationWorkflow": null,
  //   "repeatLoanOriginationWorkflowId": null,
  //   "repeatLoanOriginationWorkflow": null,
  //   "priorityLevel": 3,
  //   "loanProductCategory": "Blackbox",
  //   "institutionCode": "000"
  // }
  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      showTrail,
      currentUserRoles,
      item,
    } = this.props;
    const { updatingStatus, scoreCardList } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="fab fa-first-order-alt fa-sm mr-3" />
                Credit Qualification Rule Details
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Accordion defaultActiveKey="0">
            <div>
              <Card>
                <ContextAwareToggle
                  eventKey={`0`}
                  isHeader={true}
                  header={
                    <Fragment>
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        style={{ color: "#878a8a" }}
                      ></i>{" "}
                      General Information
                    </Fragment>
                  }
                ></ContextAwareToggle>
                <Accordion.Collapse eventKey={`0`}>
                  <Card.Body>
                    <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                      <div className="row">
                        <div className="col-md-6">
                          {_.isEmpty(item) ? (
                            <Skeleton count={1} width={300} />
                          ) : (
                            <dl className="row" style={{ marginBottom: "0px" }}>
                              <DetailItem
                                label="Name"
                                value={item.name}
                                labelSize={5}
                                valueSize={7}
                              />
                            </dl>
                          )}
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </div>
          </Accordion>
          <Accordion defaultActiveKey="0">
            <div>
              <Card>
                <ContextAwareToggle
                  eventKey={`0`}
                  isHeader={true}
                  header={
                    <React.Fragment>
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        style={{ color: "#878a8a" }}
                      ></i>{" "}
                      Standard Parameters
                    </React.Fragment>
                  }
                ></ContextAwareToggle>
                <Accordion.Collapse eventKey={`0`}>
                  <Card.Body>
                    <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                      <div className="row">
                        <div className="col-md-6">
                          {_.isEmpty(item) ? (
                            <Skeleton count={5} width={300} />
                          ) : (
                            <dl className="row">
                              <DetailItem
                                label="Minimum Score"
                                value={item.minimumScore}
                                labelSize={5}
                                valueSize={7}
                              />
                              <DetailItem
                                label="Score Card"
                                value={item.scoreCard}
                                labelSize={5}
                                valueSize={7}
                              />
                              {/* <DetailItem
                                label="Loan Type"
                                value={item.loanType}
                                labelSize={5}
                                valueSize={7}
                              /> */}
                              {/* <DetailItem
                                label="Require Positive Repayment Prediction"
                                value={
                                  <React.Fragment>
                                    {_.isEmpty(item) ? (
                                      ""
                                    ) : item.requirePositiveRepaymentPrediction ===
                                      false ? (
                                      <span className="badge badge-danger">
                                        False
                                      </span>
                                    ) : (
                                      <span className="badge badge-success">
                                        True
                                      </span>
                                    )}
                                  </React.Fragment>
                                }
                                labelSize={5}
                                valueSize={7}
                              />
                              <DetailItem
                                label="Apply Pre-Qualification Assessment"
                                value={
                                  <React.Fragment>
                                    {_.isEmpty(item) ? (
                                      ""
                                    ) : item.applyPreQualificationAssessment ===
                                      false ? (
                                      <span className="badge badge-danger">
                                        False
                                      </span>
                                    ) : (
                                      <span className="badge badge-success">
                                        True
                                      </span>
                                    )}
                                  </React.Fragment>
                                }
                                labelSize={5}
                                valueSize={7}
                              /> */}
                            </dl>
                          )}
                        </div>
                        <div className="col-md-6">
                          {_.isEmpty(item) ? (
                            <Skeleton count={5} width={300} />
                          ) : (
                            <dl className="row">
                              {/* <DetailItem
                                label="Max. (%) Amount Of Loan Salary"
                                value={item.maxPercentageLoanAmountSalary}
                                labelSize={5}
                                valueSize={7}
                              /> */}
                              {/* <DetailItem
                                label="Loan Origination Channels"
                                value={
                                  <React.Fragment>
                                    {item.supportedLoanOriginationChannels.map(
                                      (channel) => (
                                        <div
                                          key={channel.loanOriginationChannelId}
                                          className="custom-control custom-checkbox custom-control-inline"
                                        >
                                          <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id={
                                              channel.loanOriginationChannelId
                                            }
                                            checked={true}
                                            disabled={true}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={
                                              channel.loanOriginationChannelId
                                            }
                                          >
                                            {channel.channelName}
                                          </label>
                                        </div>
                                      )
                                    )}
                                  </React.Fragment>
                                }
                                labelSize={5}
                                valueSize={7}
                              /> */}
                            </dl>
                          )}
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </div>
          </Accordion>
          <Accordion defaultActiveKey="0">
            <div>
              <Card>
                <ContextAwareToggle
                  eventKey={`0`}
                  isHeader={true}
                  header={
                    <React.Fragment>
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        style={{ color: "#878a8a" }}
                      ></i>{" "}
                      Knock-Off Basis
                    </React.Fragment>
                  }
                ></ContextAwareToggle>
                <Accordion.Collapse eventKey={`0`}>
                  <Card.Body>
                    <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                      <div className="row">
                        <div className="col-md-12">
                          {_.isEmpty(item) ? (
                            <Skeleton count={5} width={300} />
                          ) : (
                            <Fragment>
                              <h5>
                                <b>Permissible Knock-Off Basis</b>
                              </h5>
                              <br />
                              {item.creditQualificationRuleIndicators?.map(
                                (indicator) => (
                                  indicator.indicatorParams?.length > 0 ? (
                                    <Accordion key={indicator.creditQualificationIndicatorId}>
                                      <div style={{ border: '2px #84d4fc solid', borderRadius: '4px', backgroundColor: '#e4f4fc', marginTop: '5px' }}>
                                        <ContextAwareToggle
                                          eventKey={`0`}
                                          isHeader={false}
                                          header={
                                            <div
                                              className="custom-control custom-checkbox"
                                              style={{ display: 'inline-block' }}
                                            >
                                              <input
                                                id={
                                                  indicator.creditQualificationIndicatorId
                                                }
                                                name={
                                                  indicator.creditQualificationIndicatorId
                                                }
                                                className="custom-control-input"
                                                type="checkbox"
                                                checked={true}
                                                disabled={true}
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor={
                                                  indicator.creditQualificationIndicatorId
                                                }
                                              >
                                                {indicator.indicatorName}
                                              </label>
                                            </div>
                                          }
                                        ></ContextAwareToggle>
                                      </div>
                                      <Accordion.Collapse eventKey={`0`}>
                                        <Card.Body style={{ padding: "5px", backgroundColor: '#e4f4fc' }}>
                                          <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                            <div className="row">
                                              <div className="col-md-1">
                                              </div>
                                              <div className="col-md-8">
                                                {indicator.indicatorParams.map(indicatorParam => (
                                                  <dl className="row" style={{ marginBottom: "0px" }} key={indicatorParam.paramName}>
                                                    <DetailItem
                                                      label={splitAtUpper(indicatorParam.paramName.split(".")[1])}
                                                      value={indicatorParam.paramValue}
                                                      labelSize={5}
                                                      valueSize={7}
                                                    />
                                                  </dl>
                                                ))}
                                              </div>
                                            </div>
                                          </div>
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Accordion>
                                  ) : (
                                    <div key={indicator.creditQualificationIndicatorId} style={{ border: '2px #84d4fc solid', borderRadius: '4px', backgroundColor: '#e4f4fc', marginTop: '5px' }}>
                                      <div
                                        className="custom-control custom-checkbox"
                                        key={
                                          indicator.creditQualificationIndicatorId
                                        }
                                      >
                                        <input
                                          id={
                                            indicator.creditQualificationIndicatorId
                                          }
                                          name={
                                            indicator.creditQualificationIndicatorId
                                          }
                                          className="custom-control-input"
                                          type="checkbox"
                                          checked={true}
                                          disabled={true}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={
                                            indicator.creditQualificationIndicatorId
                                          }
                                        >
                                          {indicator.indicatorName}
                                        </label>
                                      </div>
                                    </div>
                                  )
                                )
                              )}
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </div>
          </Accordion>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
              {/* {(getCurrentUsersRoles().includes("removecreditqualificationrule") || getCurrentUsersRoles().includes("removecreditqualificationrule(ocm)")) && (
                <Button
                  size="sm"
                  variant="danger"
                  disabled={updatingStatus}
                  onClick={(e) =>
                    this.onHandleRemove(
                      e,
                      ActionTypes.REMOVE,
                      "Credit Qualification Rule"
                    )
                  }
                >
                  <i className="far fa-edit mr-3" />
                  Remove
                </Button>
              )} */}

              {(getCurrentUsersRoles().includes("editcreditqualificationrule") || getCurrentUsersRoles().includes("editcreditqualificationrule(ocm)")) && (
                <Button
                  size="sm"
                  variant="primary"
                  disabled={updatingStatus}
                  onClick={toggleEditModal}
                >
                  <i className="far fa-edit mr-3" />
                  Edit
                </Button>
              )}
            </React.Fragment>
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CreditQualificationRuleDetail;
