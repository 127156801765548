import React, { Fragment } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import DetailItem from "../../../components/detailItem";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import billingValidationService from "../../../services/billing/billingValidationService";
import HybridAppraisalBillingService from "../../../services/billing/HybridAppraisalBillingService";
import recovaFeeConfigService from "../../../services/billing/recovaFeeConfigService";
import thirdPartyFeeConfigService from "../../../services/billing/thirdPartyFeeConfigService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import ItemsTable from "../../../components/tableComponents/itemsTable";

interface EditHybridAppraisalTranFeeConfigurationFields {
    institutionCategory: string;
    feeName: string;
    flatPerRequest: string;
    flatPerApprovedLoan: string;
    percentageOfApprovedAmount: string;
    minimumFee: string;
    maximumFee: string;
    validationData: any;
}

class EditHybridAppraisalTranFeeConfigurationForm extends BaseFormComponent<EditHybridAppraisalTranFeeConfigurationFields> { }

interface IEditHybridAppraisalTranFeeConfigurationProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}

interface IValidationOption {
  validationPricingOptionId: string;
  fee: string;
}
interface IEditHybridAppraisalTranFeeConfigurationState {
  ownUpdate: boolean;
  validationOption: IValidationOption[];
  validationItems: any;
  flatPerApprovedLoan: any;
}

class EditHybridAppraisalTranFeeConfiguration extends React.Component<
  IEditHybridAppraisalTranFeeConfigurationProps,
  IBaseFormState & IEditHybridAppraisalTranFeeConfigurationState
> {
  _isMounted = true
  constructor(props: IEditHybridAppraisalTranFeeConfigurationProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      ownUpdate: false,
      validationOption: [],
      validationItems: [],
      flatPerApprovedLoan: 0
    };
  }

  static getDerivedStateFromProps(prevState) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    return null;
  }

  async componentDidMount() {
    this._isMounted = true;
    const { errors, validationOption } = this.state;
    const {item} = this.props;

    const validationOptions = item.validationOptions

    if(validationOptions != null){
    validationOptions.map(option => {
      validationOption.push({validationPricingOptionId: option.pricingOptionId, fee: option.fee})
    });
      if (this._isMounted) {
        this.setState({
          validationItems: validationOptions,
          validationOption,
          flatPerApprovedLoan: item.flatPerApprovedLoan
        })
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onRecovaFormSubmit(
    fields: EditHybridAppraisalTranFeeConfigurationFields,
    onReloadFieldsCB: any
  ): boolean {
    try {
      let sum_fee = Number(fields.flatPerRequest);
      fields.validationData.forEach((item)=>{return sum_fee += Number(item.fee);});
      console.log(sum_fee);
      this.setState(
        {
          ownUpdate: true,
          flatPerApprovedLoan: sum_fee
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.Submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  handleValidationChange = (id,value) =>{
    const validationOption = [...this.state.validationOption];
    var item2: IValidationOption = { validationPricingOptionId: id, fee: value };
    

    let length = Object.keys(validationOption).length;
    if(length === 0){
      validationOption.push(item2);
    }
    else{
    validationOption.every(option => {
      if(option.validationPricingOptionId == id){
        option.fee = value;
        return false;
      }
      else if(option.validationPricingOptionId == ""){
        validationOption.push(item2);
        return false;
      }
    
    return true;
    
  });
}
  // this.setState({validationOption: validationOption});
  // console.log(validationOption);
  return validationOption;
  }

 

  Submit = async (
    fields: EditHybridAppraisalTranFeeConfigurationFields,
    onReloadFieldsCB: any
  ) => {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const payload = this.props.item;
      payload.feeName = fields.feeName
      payload.id = payload.hybridAppraisalConfigurationId;
      // payload.institutionCategory = fields.institutionCategory;
      payload.flatPerApprovedLoan = Number(this.state.flatPerApprovedLoan);
      payload.flatPerRequest = Number(fields.flatPerRequest);
      payload.validationOptions = this.state.validationOption;
      // console.log(payload)

      const response = await HybridAppraisalBillingService.editTransactionFee(payload.id, payload);
      if (response.status >= 200 && response.status <= 300) {
        toast.info(
          `Hybrid Appraisal Transaction Fee for ${payload.feeName} Edited successfully!`,
          {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          }
        );
      }
      onReloadFieldsCB();
      this.setState({ submitting: false, ownUpdate: true }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
    } finally {
      this.setState({ submitting: false, ownUpdate: true });
    }
  };


  render() {
    const { item, showEditModal, toggleEditModal } = this.props;
    const {
      validated,
      submitting,
      errors,
      validationOption,
      validationItems
    } = this.state;

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        {_.isEmpty(item) ? (
          <Skeleton width={500} />
        ) : (
          <Fragment>
            <Modal.Header>
              <Modal.Title>
                <h5>
                  <i className="fas fa-tools fa-sm cyan-text mr-3" />
                  Edit Hybrid Appraisal Transaction Fee Configuration -{" "}
                  <span className="font-weight-bold">{item.institutionCategory} ({item.serviceOffering})</span>
                </h5>
              </Modal.Title>
            </Modal.Header>
            <EditHybridAppraisalTranFeeConfigurationForm
              initialValues={{
                    institutionCategory: item.institutionCategory,
                    feeName: item.feeName,
                    flatPerRequest: item.flatPerRequest,
                    flatPerApprovedLoan:item.flatPerApprovedLoan,
                    percentageOfApprovedAmount:item.percentageOfApprovedAmount,
                    minimumFee:item.minimumFee,
                    maximumFee:item.maximumFee,
                    validationData: item.validationOptions
              }}
              FormComponent={({
                fields: {
                    institutionCategory,
                    feeName,
                    flatPerApprovedLoan,
                    flatPerRequest,
                    maximumFee,
                    minimumFee,
                    percentageOfApprovedAmount,
                    validationData
                },
                onChange,
                onReloadFields,
                onHandleSubmit,
              }) => (
                <React.Fragment>
                    <Fragment>
                      <Modal.Body>
                        <div className="card">
                          <div className="card-header clear-fix">
                            <h6
                              className="card-title float-left"
                              style={{ marginBottom: "0px" }}
                            >
                              Fee
                            </h6>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-6">
                                <dl className="row">
                                <DetailItem
                                    label="Fee Name"
                                    labelSize={8}
                                    valueSize={4}
                                    value={
                                      <DetailItemInputComponent
                                        id="feeName"
                                        type="text"
                                        name="feeName"
                                        placeholder=""
                                        value={feeName}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    }
                                  />
                                <DetailItem
                                    label="Flat Per Approved Loan"
                                    labelSize={8}
                                    valueSize={4}
                                    value={
                                      <DetailItemInputComponent
                                        id="flatPerApprovedLoan"
                                        type="number"
                                        name="flatPerApprovedLoan"
                                        placeholder=""
                                        value={this.state.flatPerApprovedLoan}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        disabled={true}
                                        onChange={onChange}
                                      />
                                    }
                                  />
                                  <DetailItem
                                  label="Flat Per Request"
                                  labelSize={8}
                                  valueSize={4}
                                  value={
                                    <DetailItemInputComponent
                                      id="flatPerRequest"
                                      type="number"
                                      name="flatPerRequest"
                                      placeholder=""
                                      value={flatPerRequest}
                                      required={true}
                                      validated={validated}
                                      errors={errors}
                                      onChange={onChange}
                                    />
                                  }
                                />
                                </dl>
                              </div>
                              <div className="col-md-6">
                                <dl className="row">
                                {/* <DetailItem
                                    label="Percentage Of Approved Amount"
                                    labelSize={8}
                                    valueSize={4}
                                    value={
                                      <DetailItemInputComponent
                                        id="percentageOfApprovedAmount"
                                        type="number"
                                        name="percentageOfApprovedAmount"
                                        placeholder=""
                                        value={percentageOfApprovedAmount}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    }
                                  /> */}
                                  {/* <DetailItem
                                    label="Minimum Fee"
                                    labelSize={8}
                                    valueSize={4}
                                    value={
                                      <DetailItemInputComponent
                                        id="minimumFee"
                                        type="number"
                                        name="minimumFee"
                                        placeholder=""
                                        value={minimumFee}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    }
                                  />
                                  <DetailItem
                                    label="Maximum Fee"
                                    labelSize={8}
                                    valueSize={4}
                                    value={
                                      <DetailItemInputComponent
                                        id="maximumFee"
                                        type="number"
                                        name="maximumFee"
                                        placeholder=""
                                        value={maximumFee}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    }
                                  /> */}
                                </dl>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header clear-fix">
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        Validation Fees
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <dl className="row">
                             {validationItems.map((option)=>(
                               <DetailItem
                               label={option.pricingOption.code}
                               labelSize={9}
                               valueSize={3}
                               value={
                                <DetailItemInputComponent
                                  id={option.pricingOptionId}
                                  type="number"
                                  name={option.pricingOption.code}
                                  placeholder=""
                                  value={validationOption.find((options)=>options.validationPricingOptionId == option.pricingOptionId).fee}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  // onChange={this.handleValidationChange}
                                  onChange={(id, value) => {
                                    onChange('validationData', this.handleValidationChange(id, value))
                                    // this.handleValidationChange
                                  }}
                                />
                              }
                             />
                             ))
                            }
                          </dl>
                        </div>
                      </div>
                    </div>
                    </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          size="sm"
                          variant="secondary"
                          onClick={(e) => {
                            onHandleSubmit(
                              e,
                              ActionTypes.UPDATE,
                              "Recova Central Fee Configuration",
                              () => {
                                this.onRecovaFormSubmit(
                                  {
                                    institutionCategory,
                                    feeName,
                                    flatPerApprovedLoan,
                                    flatPerRequest,
                                    maximumFee,
                                    minimumFee,
                                    percentageOfApprovedAmount,
                                    validationData
                                  },
                                  onReloadFields
                                );
                              }
                            );
                          }}
                          disabled={submitting}
                        >
                          {submitting === false ? (
                            <React.Fragment>
                              <i className="fas fa-lg fa-save mr-3" /> Save
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <i className="fas fa-spin fa-circle-notch" />
                            </React.Fragment>
                          )}
                        </Button>
                        <Button
                          size="sm"
                          variant="outline-danger"
                          onClick={() => toggleEditModal(false, this.props.item)}
                          disabled={submitting}
                        >
                          <i className="fas fa-times mr-3" />
                          Close
                        </Button>
                      </Modal.Footer>
                    </Fragment>
                </React.Fragment>
              )}
            ></EditHybridAppraisalTranFeeConfigurationForm>
             
          </Fragment>
        )}
      </Modal>
    );
  }
}

export default EditHybridAppraisalTranFeeConfiguration;
