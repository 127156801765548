import * as React from "react";
// import _ from "lodash";
import { Fragment } from "react";
import BaseListComponent, { IFilterData, BaseListComponentState } from "../../components/BaseListComponent";
import ItemsTable, { filterFieldData } from "../../components/tableComponents/itemsTable";
import { createFilterData, mapEntityList } from "../../services/utility/autoMapperService";
// import { decodeToken } from "react-jwt";
// import { getCurrentUserSession } from "../../services/auth/authService";
import payoutRequestService from "../../services/handshake/payoutRequestService";
import TableFilterPane from "../../components/tableComponents/tableFilterPane";
import FundsTransferRequestDetail from "./fundsTransferRequestDetail";

export interface FundsTransferRequestsProps {}

export interface FundsTransferRequestsState extends BaseListComponentState {
    data: IFilterData;
    currentPage: number;
    pageSize: number;
    tableItems: any;
    totalSize: number;
    fetchingDetail: boolean;
    filterValueInput: filterFieldData;
    item: any;
}

class FundsTransferRequestsList extends BaseListComponent<FundsTransferRequestsState> {}

class FundsTransferRequests extends React.Component<FundsTransferRequestsProps, FundsTransferRequestsState> {
    constructor(props: FundsTransferRequestsProps) {
        super(props);
        this.state = {
            data: createFilterData([]) as IFilterData,
            currentPage: 1,
            pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
            fetching: false,
            fetchingDetail: false,
            tableItems: [],
            totalSize: 0,
            filterValueInput: [],
            item: {},
            showDetailsModal: false,
            showEditModal: false,
            showTableFilterModal: false,
            showTrailModal: false,
        };
    }

    handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
        currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
        pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
        this.setState({ fetching: true });
        data = typeof data === "undefined" ? this.state.data : data;
        try {
            const payouts = await payoutRequestService.filterPayoutRequests(data, pageSize, currentPage);
            payouts.data.item2.forEach((item) => {
                item["successful"] = !item.hasError;
            });
            this.setState({
                tableItems: mapEntityList({
                    entityList: payouts.data.item2,
                    properties: [
                        { oldName: "payeeInstitution", newName: "institution" },
                        {
                            oldName: "receiverName",
                            newName: "customerName",
                        },
                        { isBoolean: true, oldName: "successful", newName: "successful" },
                        { oldName: "receiverInstitution", newName: "destinationBank" },
                        { oldName: "amountInKobo", newName: "amount" },
                        { oldName: "phoneNumber", newName: "phoneNumber" },
                        { oldName: "payoutStage", newName: "Stage" },
                        {
                            isTag: true,
                            oldName: "payoutStage",
                            newName: "Stage",
                            success: "Stage100CompletedPayout",
                            danger: "danger",
                            default: "CommercialBank",
                            primary: "Stage50BankOneAgentFundsTransfer",
                            secondary: "secondary",
                            warning: "",
                            light: "Stage10DebitAndCreditGLAccounts",
                            dark: "dark",
                        },
                        {
                            isTag: true,
                            oldName: "payoutTransactionType",
                            newName: "transactionType",
                            success: "success",
                            danger: "danger",
                            default: "DebitGLCreditGLPosting",
                            primary: "DebitAccountCreditGLPosting",
                            secondary: "secondary",
                            warning: "OFI",
                            light: "BankOneOFI",
                            dark: "dark",
                        },
                        { oldName: "dateCreated", newName: "dateCreated" },
                    ],
                }),
                currentPage,
                pageSize,
                totalSize: payouts.data.item1,
                fetching: false,
            });
        } catch (ex) {
            // console.log("caught exception", ex.response);
            if (ex.response && ex.response.status >= 400 && ex.response.status <= 499) {
                // toast.error("Bad Request");
                // console.log(ex.response);
                // errors["response"] = "Internal server error.";
                // console.log(errors);
                // this.setState({ errors, fetching: false });
            } else {
                // toast.error("Internal server error. Please contact the admin.");
                // this.setState({ errors, fetching: false });
            }
        } finally {
            this.setState({ fetching: false });
        }
    };

    handleOnExport = async (type: string) => {};

    handleOnRetryPayout = async (item: any) => {
        // console.log("about to retry payout: ", item.id);
        await payoutRequestService.retryPayoutRequest(item.id);
        this.handlePageChange(this.state.currentPage, this.state.pageSize);
        // await this.fetchItemById(item);
        // payoutRequestService
        //     .retryPayoutRequest(item.id)
        //     .then(async (res) => {
        //         await this.fetchItemById(item.id);
        //     })
        //     .catch((err) => {
        //         console.log("retry payout error: ", err.response);
        //     });
    };

    fetchItemById = async (item: any) => {
        try {
            this.setState({ fetchingDetail: true });
            var payoutRequest = await payoutRequestService.getPayoutRequestById(item.id);
            console.log("result: ", payoutRequest);
            this.setState({ fetchingDetail: false, item: payoutRequest.data });
        } catch (ex) {
        } finally {
            this.setState({
                fetchingDetail: false,
            });
        }
    };

    getFilteredItems = (data: IFilterData): IFilterData => {
        let filteredItems: IFilterData = {};
        Object.keys(data).forEach((key) => {
            if (data[key].item2 !== "" && key !== "institutionId") {
                filteredItems[key] = data[key];
            }
        });
        return filteredItems;
    };

    async componentDidMount() {
        this.handlePageChange(this.state.currentPage, this.state.pageSize);
    }

    render() {
        const { currentPage, pageSize, tableItems, totalSize, fetching, item, fetchingDetail } = this.state;
        return (
            <Fragment>
                <div className="container-fluid relative animatedParent animateOnce">
                    <div className="animated fadeInUpShort go">
                        <div className="row my-3 mx-2">
                            <h3>
                                <b>Payouts Requests</b>
                            </h3>
                        </div>
                        <FundsTransferRequestsList
                            pageChangeHandler={this.handlePageChange}
                            fetchingDetail={fetchingDetail}
                            initialValues={{
                                data: createFilterData([]) as IFilterData,
                                fetching: false,
                                showDetailsModal: false,
                                showEditModal: false,
                                showTableFilterModal: false,
                                showTrailModal: false,
                                tableItems: [],
                                filterValueInput: [],
                                item: {},
                            }}
                            tableItems={tableItems}
                            item={item}
                            ListPayLoad={({
                                data,
                                showTableFilterModal,
                                tableItems,
                                onToggleDetailsModal,
                                onToggleTableFilterModal,
                                onHandleFilterItemRemove,
                                filterValueInput,
                            }) => (
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card no-b">
                                            <div className="card-body">
                                                <div className="card-title">
                                                    <div id="list-filters" className="ml-4 mr-4">
                                                        <TableFilterPane
                                                            filteredItems={this.getFilteredItems(data)}
                                                            handleFilterRemove={onHandleFilterItemRemove}
                                                        />
                                                    </div>
                                                    <div id="list-table">
                                                        <ItemsTable
                                                            data={data}
                                                            items={tableItems}
                                                            filterValueInput={filterValueInput}
                                                            showTableFiltermodal={showTableFilterModal}
                                                            toggleTableFiltermodal={onToggleTableFilterModal}
                                                            name="Payout Requests"
                                                            currentPage={currentPage}
                                                            pageSize={pageSize}
                                                            totalSize={totalSize}
                                                            fetching={fetching}
                                                            handlePageChange={this.handlePageChange}
                                                            onViewDetails={(item: any) => {
                                                                this.fetchItemById(item);
                                                                onToggleDetailsModal();
                                                            }}
                                                            handleOnExport={this.handleOnExport}
                                                            amountColumn={["amount"]}
                                                            timeColumn={["dateCreated"]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            DetailsModal={({ item, onToggleDetailsModal, showDetailsModal }) => (
                                <Fragment>
                                    <FundsTransferRequestDetail
                                        showDetailsModal={showDetailsModal}
                                        handleOnRetryPayout={(e) => {
                                            onToggleDetailsModal();
                                            this.handleOnRetryPayout(e);
                                        }}
                                        toggleDetailsModal={onToggleDetailsModal}
                                        // reloadItem={(item: any) => {
                                        //     this.fetchItemById(item);
                                        // }}
                                        item={item}
                                    />
                                </Fragment>
                            )}
                        />
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default FundsTransferRequests;
