import { IFilterData } from "../../components/BaseListComponent";
import http from "../httpService";

const appraisalBaseAddress = process.env.REACT_APP_HYBRIDAPPRAISAL_BASEADDRESS;

export function addWorkFlowConfig(payload) {
    console.log(appraisalBaseAddress);
    return http.post(
        appraisalBaseAddress + `Workflow/CreateWorkflow`,
        payload
    );
}

export function editWorkFlowConfig(payload) {
    return http.put(
        appraisalBaseAddress + `Workflow/EditWorkflow`,
        payload
    );
}

export function retrieveByInstitutionCode(institutionCode) {
    return http.get(
        appraisalBaseAddress + `Workflow/RetrieveByInstitutionCode/${institutionCode}`
    );
}

export function retrieveById(id) {
    return http.get(
        appraisalBaseAddress + `Workflow/RetrieveById/${id}`
    );
}

export function retrieveAll() {
    return http.get(
        appraisalBaseAddress + `Workflow/RetrieveAll`
    );
}

export function filterWorkFlowConfig(
    payload: IFilterData,
    maxSize: number,
    pageNum: number
) {
    payload = { ...payload };
    return http.post(
        appraisalBaseAddress + `Workflow/FilterWorkflows?pageNum=${pageNum}&maxSize=${maxSize}`,
        payload
    );
}


export default {
    addWorkFlowConfig,
    editWorkFlowConfig,
    retrieveByInstitutionCode,
    retrieveById,
    retrieveAll,
    filterWorkFlowConfig,
};
