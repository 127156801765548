import http from "../httpService";

const billingBaseAddress = process.env.REACT_APP_BILLING_BASEADDRESS;

export function createFeeConfig(feeConfig) {
  return http.post(
    billingBaseAddress + `FeeConfiguration`,
    feeConfig
  );
}

export function retrieveAllFeeConfigs() {
  return http.get(
    billingBaseAddress + `FeeConfiguration`
  );
}

export function retrieveFeeConfigById(id: string) {
  return http.get(
    billingBaseAddress + `FeeConfiguration/${id}`
  );
}

export function updateBlackboxFeeConfig(id, feeConfig) {
  return http.put(
    billingBaseAddress + `FeeConfiguration/configuration/blackbox/fees/Edit/${id}`,
    feeConfig
  );
}

export function updateMandateFeeConfig(id, feeConfig) {
  return http.put(
    billingBaseAddress + `FeeConfiguration/configuration/mandate/fees/Edit/${id}`,
    feeConfig
  );
}

export function retrieveAllBlackboxFees() {
  return http.get(
    billingBaseAddress + `FeeConfiguration/fees/blackbox`
  );
}

export function retrieveAllCreditAssessmentFees() {
  return http.get(
    billingBaseAddress + `FeeConfiguration/fees/creditassessment`
  );
}

export function retrieveAllMandateFees() {
  return http.get(
    billingBaseAddress + `FeeConfiguration/fees/mandate`
  );
}

export function retrieveAllSweepFees() {
  return http.get(
    billingBaseAddress + `FeeConfiguration/fees/sweep`
  );
}

export function retrieveAllCollectionFees() {
  return http.get(
    billingBaseAddress + `FeeConfiguration/fees/collection`
  );
}

export function retrieveFeeConfigByServiceOfferingAndInstitutionCategory(serviceOffering: string, institutionCategory: string,) {
  return http.get(
    billingBaseAddress + `FeeConfiguration/configuration/fees/${serviceOffering}/${institutionCategory}`
  );
}

export function retrieveFeeConfigByServiceOffering(serviceOffering: string) {
  return http.get(
    billingBaseAddress +
    `FeeConfiguration/configuration/fees/getbyserviceoffering/${serviceOffering}`
  );
}

export function retrieveFeeConfigByInstitutionCategory(institutionCategory: string,) {
  return http.get(
    billingBaseAddress +
    `FeeConfiguration/configuration/fees/getbyinstitutioncategory/${institutionCategory}`
  );
}

export function retrieveTransactionFeeConfigByInstitutionCategory(institutionCategory: string) {
  return http.get(
    billingBaseAddress +
    `FeeConfiguration/hybridAppraisal/TransactionFee/FilterWithPaging?InstitutionCategory=${institutionCategory}`
  );
}

export function retrieveSubscriptionFeeConfigByInstitutionCategory(institutionCategory: string) {
  return http.get(
    billingBaseAddress +
    `FeeConfiguration/hybridAppraisal/SubscriptionFee/FilterWithPaging?InstitutionCategory=${institutionCategory}`
  );
}


export default {
  createFeeConfig,
  retrieveAllFeeConfigs,
  retrieveFeeConfigById,
  updateBlackboxFeeConfig,
  updateMandateFeeConfig,
  retrieveAllBlackboxFees,
  retrieveAllCreditAssessmentFees,
  retrieveAllMandateFees,
  retrieveAllSweepFees,
  retrieveAllCollectionFees,
  retrieveFeeConfigByServiceOfferingAndInstitutionCategory,
  retrieveFeeConfigByServiceOffering,
  retrieveFeeConfigByInstitutionCategory,
  retrieveTransactionFeeConfigByInstitutionCategory,
  retrieveSubscriptionFeeConfigByInstitutionCategory
};
