import React, { Fragment } from "react";
import _ from "lodash";
import SelectInputComponent, { ISelectItems } from "../../../components/formSelectComponent";
import BaseFormComponent, { IBaseFormState } from "../../../components/BaseFormComponent";
import BaseListComponent, { IFilterData, BaseListComponentState } from "../../../components/BaseListComponent";
import { createFilterData, mapEntityList } from "./../../../services/utility/autoMapperService";
import ItemsTable, { filterFieldData } from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "./../../../components/tableComponents/tableFilterPane";
import { getCurrentUsersRoles } from "../../../services/auth/authService";
import recovaFeeConfigService from "../../../services/billing/recovaFeeConfigService";
import thirdPartyFeeConfigService from "../../../services/billing/thirdPartyFeeConfigService";
import HybridAppraisalBillingService from "../../../services/billing/HybridAppraisalBillingService";
import HybridAppraisalConfigurationDetail from "./HybridAppraisalFeeConfigDetail";
import EditHybridAppraisalFeeConfiguration from "./editHybridAppraisalFeeConfig";
import CentralFeeConfigurationDetail from "./centralFeeConfigurationDetail";
import EditCentralFeeConfiguration from './editCentralFeeConfiguration';

interface HybridAppraisalFeeConfigurationFields {
  institutionCategory: string;
}

class HybridAppraisalFeeConfigurationForm extends BaseFormComponent<HybridAppraisalFeeConfigurationFields> {}

interface HybridAppraisalFeeConfigurationProps {}

interface HybridAppraisalFeeConfigurationState extends BaseListComponentState {
  institutionCategory: string;
  feeType: string;
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  sortOrder: any;
  sortColumn: any;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
}

class HybridAppraisalFeeConfigurationList extends BaseListComponent<HybridAppraisalFeeConfigurationState> {}

class HybridAppraisalFeeConfiguration extends React.Component<HybridAppraisalFeeConfigurationProps, IBaseFormState & HybridAppraisalFeeConfigurationState> {
  constructor(props: HybridAppraisalFeeConfigurationProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      institutionCategory: "",
      feeType: "",
      data: createFilterData([]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [],
      sortColumn: 0,
      sortOrder: "",
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
    };
  }

  handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true });
    try {
      const subscriptionFeeList = (await HybridAppraisalBillingService.
        filterSubscriptionFee(this.state.institutionCategory,currentPage-1,pageSize,'','')).data;
        // this.state.currentPage, this.state.totalSize,this.state.sortColumn,this.state.sortOrder);
        
      var mapsubscriptionFeeList = subscriptionFeeList.data.map(({
        hybridAppraisalConfigurationId,
        institutionCategory,
        volumeTier,
        subscriptionFrequency,
        requestCount,
        price,
        payAsYouGoPrice
      }) => ({
        id: hybridAppraisalConfigurationId,
        payAsYouGoPrice: payAsYouGoPrice,
        price: price,
        requestCount: requestCount,
        subscriptionFrequency: subscriptionFrequency,
        feeName: volumeTier,
        institutionCategory: institutionCategory
      }));
      const subscriptionFeeConfigs: any = [];
      const initial = (currentPage - 1) * pageSize;
      const subscriptionTotalLength = subscriptionFeeList.data.length;
      for (let i = initial; i < initial + pageSize; i++) {
        if (i === subscriptionTotalLength) {
          break;
        }
        subscriptionFeeConfigs.push(subscriptionFeeList.data[i]);
      }

      this.setState({
        tableItems: mapEntityList({
          entityList: subscriptionFeeConfigs,
          properties: [
              {oldName: "hybridAppraisalConfigurationId", newName: "id"},
            { oldName: "institutionCategory", newName: "institutionCategory" },
            // { oldName: "subscriptionFrequency", newName: "subscriptionFrequency" },
            { oldName: "volumeTier", newName: "feeName" },
            { oldName: "requestCount", newName: "requestCount" },
            { oldName: "price", newName: "price" },
            { oldName: "payAsYouGoPrice", newName: "payAsYouGoPrice" },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: subscriptionTotalLength,
        fetching: false
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
        this.setState({ fetching: false });
      } else {
        this.setState({ fetching: false });
      }
    } finally {
      this.setState({ fetching: false });
    }
  };

  handleOnExport = async () => {};

  fetchItem = async (item: any) => {
    this.setState({ fetchingDetail: true });
      var subscriptionFeeConfig = (await HybridAppraisalBillingService.getSubscriptionFeeById(item.id)).data;
    
    this.setState({ item: subscriptionFeeConfig, fetchingDetail: false });
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  handleOnInstitutionCategoryChange = async (id, institutionCategory: string) => {
    this.setState({
      institutionCategory
    }, () => { this.handlePageChange(this.state.currentPage, this.state.pageSize) })
  };

  render() {
    const {
      errors,
      validated,
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
      institutionCategory,
    } = this.state;

    let institutionCategoryInputData: ISelectItems[] = [
      { name: "Commercial Bank", value: "CommercialBank" },
      { name: "BankOne OFI", value: "BankOneOFI" },
      { name: "OFI", value: "OFI" },
    ];

    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Hybrid Appraisal Subscription Fee Configuration</b>
              </h3>
            </div>
            <div className="card no-b">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <HybridAppraisalFeeConfigurationForm
                      initialValues={{
                        institutionCategory: institutionCategoryInputData[0].value
                      }}
                      FormComponent={({
                        fields: {},
                      }) => (
                        <form action="#">
                          <div className="form-row">
                            <div className="col-md-6">
                              <SelectInputComponent
                                id="institutionCategory"
                                name="institutionCategory"
                                divClass={12}
                                value={institutionCategory}
                                items={institutionCategoryInputData}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={
                                  this.handleOnInstitutionCategoryChange
                                }
                              />
                            </div>
                          </div>
                        </form>
                      )}
                    ></HybridAppraisalFeeConfigurationForm>

                    {institutionCategory != "" ? (
                      <Fragment>
                        <div className="mt-3">
                          <HybridAppraisalFeeConfigurationList
                            pageChangeHandler={this.handlePageChange}
                            fetchingDetail={fetchingDetail}
                            initialValues={{
                              data: createFilterData([]) as IFilterData,
                              fetching: false,
                              showDetailsModal: false,
                              showEditModal: false,
                              showTableFilterModal: false,
                              showTrailModal: false,
                              tableItems: [],
                              filterValueInput: [],
                              item: {},
                              jsonBefore: {},
                              jsonAfter: {},
                              fetchingTrail: false,
                            }}
                            tableItems={tableItems}
                            item={item}
                            DetailsModal={({
                              item,
                              onToggleDetailsModal,
                              onToggleEditModal,
                              showDetailsModal,
                              showEditModal,
                              onToggleTrailModal,
                            }) => (
                              <Fragment>
                                <HybridAppraisalConfigurationDetail
                                  showDetailsModal={showDetailsModal}
                                  toggleDetailsModal={onToggleDetailsModal}
                                  toggleEditModal={onToggleEditModal}
                                  showTrail={() => onToggleTrailModal(item)}
                                  currentUserRoles={getCurrentUsersRoles()}
                                  item={item}
                                />
                                <EditHybridAppraisalFeeConfiguration
                                  showEditModal={showEditModal}
                                  toggleEditModal={(reloadDetails: boolean, item: any) => {
                                    onToggleEditModal();
                                    if (reloadDetails === true) {
                                      this.fetchItem(item);
                                    }
                                    this.setState({
                                      showDetailsModal: !this.state.showDetailsModal,
                                    });
                                  }}
                                  item={item}
                                />
                              </Fragment>
                            )}
                            ListPayLoad={({
                              data,
                              showTableFilterModal,
                              tableItems,
                              onToggleDetailsModal,
                              onToggleTableFilterModal,
                              onHandleFilterItemRemove,
                              filterValueInput,
                            }) => (
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="card no-b">
                                    <div className="card-body">
                                      <div className="card-title">
                                        <div id="list-filters" className="ml-4 mr-4">
                                          <TableFilterPane
                                            filteredItems={this.getFilteredItems(data)}
                                            handleFilterRemove={onHandleFilterItemRemove}
                                          />
                                        </div>
                                        <div id="list-table">
                                          <ItemsTable
                                            data={data}
                                            items={tableItems}
                                            filterValueInput={filterValueInput}
                                            showTableFiltermodal={showTableFilterModal}
                                            toggleTableFiltermodal={onToggleTableFilterModal}
                                            name="Hybrid Appraisal Subscription Fee Configuration"
                                            currentPage={currentPage}
                                            pageSize={pageSize}
                                            totalSize={totalSize}
                                            fetching={fetching}
                                            handlePageChange={this.handlePageChange}
                                            onViewDetails={(item: any) => {
                                              this.fetchItem(item);
                                              onToggleDetailsModal();
                                            }}
                                            handleOnExport={this.handleOnExport}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          ></HybridAppraisalFeeConfigurationList>
                        </div>
                      </Fragment>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default HybridAppraisalFeeConfiguration;
