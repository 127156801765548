import * as React from "react";
// import _ from "lodash";
import { Fragment } from "react";
import {
  IFilterData,
  BaseListComponentState,
} from "../../components/BaseListComponent";
import {
  createFilterData,
  mapEntityList,
} from "./../../services/utility/autoMapperService";
import BaseListComponent from "./../../components/BaseListComponent";
import auditTrailService from "../../services/auditTrail/auditTrailService";
import ItemsTable, {
  filterFieldData,
} from "../../components/tableComponents/itemsTable";
import TableFilterPane from "./../../components/tableComponents/tableFilterPane";
import auditTrailMapperService from "../../services/auditTrail/auditTrailMapperService";
import { getCurrentUserSession } from "./../../services/auth/authService";
import { TrailType } from "../../enums/trailType";
import usersService from "../../services/auth/usersService";
import AuditTrailDetail from "./auditTrailDetail";
import { printPDF } from "../../services/utility/textFormatService";
import { decodeToken } from "react-jwt";

export interface AuditTrailsProps {}

export interface AuditTrailsState extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
  jsonAfter: any;
  jsonBefore: any;
  trailMapper: any;
}

class AuditTrailsList extends BaseListComponent<AuditTrailsState> {}

class AuditTrails extends React.Component<AuditTrailsProps, AuditTrailsState> {
  constructor(props: AuditTrailsProps) {
    super(props);
    this.state = {
      data: createFilterData([
        "initiatingUser",
        "informationType",
        "timeProcessed",
        "timeLogged",
      ]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [
        { name: "initiatingUser", fieldType: "text", value: "" },
        {
          name: "informationType",
          fieldType: "select",
          options: ["CREATE", "UPDATE", "DELETE"],
          value: "",
        },
        { name: "timeProcessed", fieldType: "date", value: "" },
        { name: "timeLogged", fieldType: "date", value: "" },
      ],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
      jsonAfter: {},
      jsonBefore: {},
      trailMapper: {},
    };
  }

  handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true });
    let dataClone =
      typeof data === "undefined" ? { ...this.state.data } : { ...data };
    const newDataArray = [
      "initiatingUser",
      "informationType",
      "occuredAt",
      "loggedAt",
    ];
    let filterData: any = {};
    newDataArray.forEach((header) => {
      if (header === "occuredAt") {
        filterData[header] = dataClone["timeProcessed"];
      } else if (header === "loggedAt") {
        filterData[header] = dataClone["timeLogged"];
      } else {
        filterData[header] = dataClone[header];
      }
    });

    // console.log("Filter Data :: ", filterData);
    try {
      const auditTrails = await auditTrailService.filterAuditTrails(
        filterData,
        pageSize,
        currentPage
      );
      this.setState({
        tableItems: mapEntityList({
          entityList: auditTrails.data.item2,
          properties: [
            { oldName: "informationType", newName: "informationType" },
            // { oldName: "initiatingUser", newName: "initiatingUser" },
            { oldName: "informationId", newName: "informationId" },
            { oldName: "occuredAt", newName: "timeProcessed" },
            { oldName: "loggedAt", newName: "timeLogged" },
            { oldName: "count", newName: "count" },
            { oldName: "entityName", newName: "entityName" },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: auditTrails.data.item1,
        fetching: false,
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
        // this.setState({ errors, fetching: false });
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        // this.setState({ errors, fetching: false });
      }
    }
  };

  handleOnExport = async (type: String) => {
    try {
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      switch (type) {
        case "XLSX":
        case "CSV":
          return await auditTrailService.downloadAuditTrailReport(
            decodedToken?.["InstitutionId"],
            type
          );
        case "PDF":
          const response = await auditTrailService.downloadAuditTrailReport(
            decodedToken?.["InstitutionId"],
            type
          );
          printPDF(response?.data);
          break;
        default:
          break;
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
      }
    }
  };

  fetchItemById = async (item: any) => {
    this.setState({ fetchingDetail: true });
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    const currentInstId = decodedToken?.["InstitutionId"] || -1;
    const mapper =
      await auditTrailMapperService.retrieveByInformationIdAndEntityName(
        currentInstId,
        item.informationId,
        item.entityName,
        TrailType[TrailType.Entity]
      );
    const trailItem = await auditTrailService.retrieveAuditTrailItemByCount(
      currentInstId,
      item.count,
      mapper.data.informationID,
      item.entityName
    );
    let jsonBefore = trailItem.data.jsonBefore;
    if (trailItem.data.jsonBefore) {
      jsonBefore = JSON.parse(trailItem.data.jsonBefore);
      delete jsonBefore["JsonData"];
      Object.keys(jsonBefore).forEach((element) => {
        if (typeof jsonBefore[element] === "object") {
          delete jsonBefore[element];
        }
      });
    }
    let jsonAfter = trailItem.data.jsonAfter;
    jsonAfter = JSON.parse(trailItem.data.jsonAfter);
    delete jsonAfter["JsonData"];
    Object.keys(jsonAfter).forEach((element) => {
      if (typeof jsonAfter[element] === "object") {
        delete jsonAfter[element];
      }
    });

    try {
      const initiator = await usersService.retrieveUserById(
        trailItem.data.initiatingUser
      );
      trailItem.data.initiator = initiator.data;
    } catch (error) {}

    // console.log("trail item: ", trailItem);
    this.setState({
      trailMapper: mapper.data,
      item: trailItem.data,
      jsonBefore,
      jsonAfter,
      fetchingDetail: false,
    });
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }

  render() {
    const {
      // data,
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
      jsonAfter,
      jsonBefore,
      trailMapper,
    } = this.state;
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Audit Trail Items</b>
              </h3>
            </div>
            <AuditTrailsList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              initialValues={{
                data: createFilterData([
                  "initiatingUser",
                  "informationType",
                  "timeProcessed",
                  "timeLogged",
                ]) as IFilterData,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: [
                  { name: "initiatingUser", fieldType: "text", value: "" },
                  {
                    name: "informationType",
                    fieldType: "select",
                    options: ["CREATE", "UPDATE", "DELETE"],
                    value: "",
                  },
                  { name: "timeProcessed", fieldType: "date", value: "" },
                  { name: "timeLogged", fieldType: "date", value: "" },
                ],

                item: {},
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                showDetailsModal,
              }) => (
                <Fragment>
                  <AuditTrailDetail
                    fetchNextTrail={this.fetchItemById}
                    fetchingDetail={fetchingDetail}
                    item={item}
                    jsonAfter={jsonAfter}
                    jsonBefore={jsonBefore}
                    showDetailsModal={showDetailsModal}
                    toggleDetailsModal={onToggleDetailsModal}
                    trailMapper={trailMapper}
                  />
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="AuditTrails"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                              timeColumn={["timeProcessed", "timeLogged"]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></AuditTrailsList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AuditTrails;
