import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";

const influxagentBaseAddress =
  process.env.REACT_APP_INFLUXAGENT_BASEADDRESS + `api/`;

export function retrieveAllGeneralMandateStatResponse(rangeInDays) {
  var url =
    influxagentBaseAddress +
    `MandateStats/RetrieveAllGeneralMandateStatResponse?rangeInDays=${rangeInDays}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveAllMandateStatResponsePerInstitution(
  institutionId,
  rangeInDays
) {
  var url =
    influxagentBaseAddress +
    `MandateStats/RetrieveAllMandateStatResponsePerInstitution/${institutionId}?rangeInDays=${rangeInDays}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export default {
  retrieveAllGeneralMandateStatResponse,
  retrieveAllMandateStatResponsePerInstitution,
};
