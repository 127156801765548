import * as React from "react";
import _ from "lodash";
import { Fragment } from "react";
import {
  IFilterData,
  BaseListComponentState,
} from "../../components/BaseListComponent";
import {
  createFilterData,
  mapEntityList,
} from "./../../services/utility/autoMapperService";
import BaseListComponent from "./../../components/BaseListComponent";
import reportsService from "../../services/sweep/reportsService";
import loanUpdateService from "../../services/sweep/loanUpdateService";
import ItemsTable, {
  filterFieldData,
} from "../../components/tableComponents/itemsTable";
import TableFilterPane from "./../../components/tableComponents/tableFilterPane";
import { getCurrentUserSession } from "../../services/auth/authService";
import institutionsService from "../../services/auth/institutionsService";
import LoanUpdateTransactionsReportDetail from "./loanUpdateTransactionsReportDetail";
import { printPDF } from "../../services/utility/textFormatService";
import { decodeToken } from "react-jwt";

export interface LoanUpdateTransactionsReportProps {}

export interface LoanUpdateTransactionsReportState
  extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
}

class LoanUpdateTransactionsReportList extends BaseListComponent<LoanUpdateTransactionsReportState> {}

class LoanUpdateTransactionsReport extends React.Component<
  LoanUpdateTransactionsReportProps,
  LoanUpdateTransactionsReportState
> {
  constructor(props: LoanUpdateTransactionsReportProps) {
    super(props);
    this.state = {
      data: createFilterData([
        "mandateReference",
        "transactionReference",
        "transactionDate",
        "status",
        "repaymentType",
      ]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [
        {
          name: "status",
          fieldType: "select",
          options: ["Pending", "Processing", "Successful", "Failed"],
          value: "",
        },
        {
          name: "repaymentType",
          fieldType: "select",
          options: ["Recovery", "Collection"],
          value: "",
        },
        { name: "mandateReference", fieldType: "text", value: "" },
        { name: "transactionReference", fieldType: "text", value: "" },
        { name: "transactionDate", fieldType: "date", value: "" },
      ],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
    };
  }

  handlePageChange = async (
    currentPage: number,
    pageSize: number,
    data?: IFilterData
  ) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true });
    data = typeof data === "undefined" ? this.state.data : data;
    try {
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const institution = await institutionsService.retrieveInstitutionById(
        decodedToken?.["InstitutionId"]
      );
      data = JSON.parse(
        JSON.stringify(data).replace(
          new RegExp("mandateReference", "g"),
          "m.LoanAccountNo"
        )
      );
      data = JSON.parse(
        JSON.stringify(data).replace(
          new RegExp("transactionReference", "g"),
          "transactionUniqueId"
        )
      );
      data = JSON.parse(
        JSON.stringify(data).replace(
          new RegExp("transactionDate", "g"),
          "occuredOn"
        )
      );
      data = JSON.parse(
        JSON.stringify(data).replace(
          new RegExp("status", "g"),
          "loanUpdateStatus"
        )
      );
      // if (data.hasOwnProperty("repaymentType")) {
      //   let _value = data["repaymentType"];
      //   if (_value["item2"] === "Recovery") {
      //     _value["item1"] = "!=";
      //   } else {
      //     _value["item1"] = "=";
      //   }
      //   _value["item2"] = "0";
      // }
      // data = JSON.parse(
      //   JSON.stringify(data).replace(
      //     new RegExp("repaymentType", "g"),
      //     "sweepEpisodeId"
      //   )
      // );

      console.log("inspect lbu filters: ", data);
      const reportItems =
        await reportsService.filterLoanUpdateTransactionsReport(
          data,
          institution.data.code,
          pageSize,
          currentPage
        );
      reportItems.data.item2.forEach((element) => {
        // if (element.completionDate === "2000-01-01T00:00:00") {
        //   element.completionDate = "";
        // }
        if (element.sweepEpisodeId === 0) {
          element["repaymentType"] = "Collection";
        } else {
          element["repaymentType"] = "Recovery";
        }
      });
      console.log("Report Items", reportItems);
      this.setState({
        tableItems: mapEntityList({
          entityList: reportItems.data.item2,
          properties: [
            { oldName: "customerName", newName: "customerName" },
            { oldName: "loanAccountNo", newName: "mandateReference" },
            { oldName: "amount", newName: "amount" },
            {
              isTag: true,
              oldName: "repaymentType",
              newName: "repaymentType",
              success: "Successful",
              danger: "Failed",
              default: "Recovery",
              warning: "Collection",
            },
            {
              oldName: "loanRepaymentAccount",
              newName: "loanRepaymentAccount",
            },
            {
              oldName: "debitAccount",
              newName: "debitAccountNumber",
            },
            { oldName: "occuredOn", newName: "lbuDate" },
            { oldName: "completionDate", newName: "completionDate" },
            { oldName: "retrievalReferenceNo", newName: "retrievalReference" },
            // { oldName: "recovaReferenceNo", newName:  "recovaReference" },
            {
              isTag: true,
              oldName: "loanUpdateStatus",
              newName: "lbustatus",
              success: "Successful",
              danger: "Failed",
              default: "Processing",
              warning: "Pending",
            },
            { oldName: "transactionUniqueId", newName: "transactionReference" },
            { oldName: "customerType", newName: "customerType" },
            { oldName: "narration", newName: "narration" },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: reportItems.data.item1,
        fetching: false,
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
        // this.setState({ errors, fetching: false });
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        // this.setState({ errors, fetching: false });
      }
    } finally {
      this.setState({ fetching: false });
    }
  };

  handleOnExport = async (type: string) => {
    try {
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      switch (type) {
        case "XLSX":
        case "CSV":
          return await reportsService.downloadLoanUpdateTransactionsReport(
            decodedToken?.["InstitutionCode"],
            type
          );
        case "PDF":
          const response =
            await reportsService.downloadLoanUpdateTransactionsReport(
              decodedToken?.["InstitutionCode"],
              type
            );
          printPDF(response?.data);
          break;
        default:
          break;
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        //this.setState({ errors, fetching: false });
      }
    }
  };

  fetchItemById = async (item: any) => {
    // await activityTrailService.saveActivityTrail({
    //   actionType: "View Details",
    //   description: `Viewed details of Branch ${item.name}`
    // });
    this.setState({ fetchingDetail: true });
    // console.log("fetching branch with id: ", item.id);
    const report = await loanUpdateService.RetrieveLBUTransactionById(item.id);
    this.setState({ item: report.data, fetchingDetail: false });
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }

  render() {
    const {
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Loan Update Transactions Report</b>
              </h3>
            </div>
            <LoanUpdateTransactionsReportList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              initialValues={{
                data: this.state.data,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: this.state.filterValueInput,
                item: {},
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                showDetailsModal,
              }) => (
                <Fragment>
                  <LoanUpdateTransactionsReportDetail
                    showDetailsModal={showDetailsModal}
                    toggleDetailsModal={onToggleDetailsModal}
                    item={item}
                  />
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="LoanUpdateTransactionsReport"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                              amountColumn={["amount"]}
                              timeColumn={["completionDate"]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></LoanUpdateTransactionsReportList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default LoanUpdateTransactionsReport;
