import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import { ActionTypes } from "../../../enums/actionTypes";
import swal from "sweetalert";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import usersService from "../../../services/auth/usersService";
import { toast } from "react-toastify";
import { getCurrentUsersRoles } from "../../../services/auth/authService";

interface IUserDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  showTrail: any;
  currentUserRoles: any;
  item: any;
}

interface IUserDetailState {
  item: any;
  updatingStatus: boolean;
}

class UserDetail extends Component<IUserDetailProps, IUserDetailState> {
  state = {
    item: {},
    updatingStatus: false,
  };

  static getDerivedStateFromProps(nextProps: IUserDetailProps, prevState) {
    return {
      item: nextProps.item,
    };
  }

  onHandleEnableOrDisable = (
    e: any,
    actionType: ActionTypes,
    entityName: string
  ) => {
    this.setState({ updatingStatus: true });
    if (!actionType) {
      this.handleEnableOrDisableItem(this.props.item);
    } else {
      swal({
        title: "Confirm!",
        text: `Are you sure you want to proceed to ${ActionTypes[actionType]} this ${entityName}?`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode: true,
      }).then((resp) => {
        if (resp) {
          // console.log("cancelling action...");
          this.setState({ updatingStatus: false });
        } else {
          this.handleEnableOrDisableItem(this.state.item);
        }
      });
    }
  };

  handleEnableOrDisableItem = async (_item: any) => {
    try {
      let approvalResponse: any = {};
      this.setState({ updatingStatus: true });
      await activityTrailService.saveActivityTrail({
        actionType: `${
          _item.status === "Inactive" ? "Enable" : "Disable"
        } User`,
        description: `Clicked on ${
          _item.status === "Inactive" ? "Enable" : "Disable"
        } to ${_item.status === "Inactive" ? "Enable" : "Disable"} User ${
          _item.firstName
        } ${_item.lastName}`,
      });
      switch (_item.status) {
        case "Inactive":
          approvalResponse = await usersService.enableUser(_item.id);
          if (approvalResponse.data.isLoggedForApproval === false) {
            _item.status = "Active";
          }
          break;
        case "Active":
          approvalResponse = await usersService.disableUser(_item.id);
          if (approvalResponse.data.isLoggedForApproval === false) {
            _item.status = "Inactive";
          }
          break;
        default:
          approvalResponse = await usersService.enableUser(_item.id);
          if (approvalResponse.data.isLoggedForApproval === false) {
            _item.status = "Active";
          }
          break;
      }
      if (approvalResponse.data.isLoggedForApproval === true) {
        toast.warn(approvalResponse.data.message, {
          autoClose: 6000,
          type: toast.TYPE.WARNING,
          position: "top-center",
          hideProgressBar: false,
        });
      } else {
        toast.info(
          `User ${_item.username} ${
            _item.status === "Active" ? "Enabled" : "Disabled"
          } successfully! `,
          {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          }
        );
      }
      this.setState({ updatingStatus: false, item: _item });
    } catch (ex) {
      this.setState({ updatingStatus: false, item: _item });
    }
  };

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      showTrail,
      currentUserRoles,
      item,
    } = this.props;
    const { updatingStatus } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="fas fa-user fa-sm cyan-text s-18 mr-3" />
                User Details -{" "}
                <span className="font-weight-bold">
                  {item.firstName} {item.lastName}
                </span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
              <div className="float-right">
                {_.isEmpty(item) ? (
                  <Skeleton width={100} />
                ) : (
                  <span
                    className="badge btn-primary"
                    onClick={showTrail}
                    style={{
                      cursor: "pointer",
                      visibility: currentUserRoles.includes(
                        "viewaudittrailitems"
                      )
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    View Trail
                  </span>
                )}
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={4} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem label="First Name" value={item.firstName} />
                      <DetailItem label="Other Name" value={item.otherName} />
                      <DetailItem label="Email" value={item.email} />
                      <DetailItem label="Staff Id" value={item.staffId} />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem label="Last Name" value={item.lastName} />
                      <DetailItem label="User Name" value={item.username} />
                      <DetailItem label="Gender" value={item.gender} />
                    </dl>
                  )}
                </div>
              </div>
            </div>
          </div>

          <br />
          <div className="card">
            <div className="card-header">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6 className="card-title" style={{ marginBottom: "0px" }}>
                  Other Information
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem label="Address" value={item.address} />
                      <DetailItem
                        label="User Role"
                        value={!_.isEmpty(item) ? item.userRole.name : ""}
                      />
                      <DetailItem
                        label="Branch"
                        value={!_.isEmpty(item) ? item.branch.name : ""}
                      />
                      <DetailItem
                        label="Branch Status"
                        value={
                          (!_.isEmpty(item) ? item.branch.status : "Active") ===
                          "Active" ? (
                            <span className="badge badge-success">
                              {!_.isEmpty(item) ? item.branch.status : ""}
                            </span>
                          ) : (
                            <span className="badge badge-danger">
                              {!_.isEmpty(item) ? item.branch.status : ""}
                            </span>
                          )
                        }
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem label="Phone Number" value={item.phoneNo} />
                      <DetailItem
                        label="User Status"
                        value={
                          item.status === "Active" ? (
                            <span className="badge badge-success">
                              {item.status}
                            </span>
                          ) : (
                            <span className="badge badge-danger">
                              {item.status}
                            </span>
                          )
                        }
                      />
                      <DetailItem
                        label="User Role Status"
                        value={
                          (!_.isEmpty(item)
                            ? item.userRole.status
                            : "Active") === "Active" ? (
                            <span className="badge badge-success">
                              {!_.isEmpty(item) ? item.userRole.status : ""}
                            </span>
                          ) : (
                            <span className="badge badge-danger">
                              {!_.isEmpty(item) ? item.userRole.status : ""}
                            </span>
                          )
                        }
                      />
                      <DetailItem
                        label="Has Approval Rights"
                        value={
                          <React.Fragment>
                            {_.isEmpty(item) ? (
                              ""
                            ) : item.hasApprovalRights === false ? (
                              <span className="badge badge-danger">False</span>
                            ) : (
                              <span className="badge badge-success">True</span>
                            )}
                          </React.Fragment>
                        }
                        // value={
                        //   (!_.isEmpty(item) ? item.branch.status : "Active") ===
                        //   "Active" ? (
                        //     <span className="badge badge-success">
                        //       {!_.isEmpty(item) ? item.branch.status : ""}
                        //     </span>
                        //   ) : (
                        //     <span className="badge badge-danger">
                        //       {!_.isEmpty(item) ? item.branch.status : ""}
                        //     </span>
                        //   )
                        // }
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
              {item.status === "Inactive" &&
                (getCurrentUsersRoles().includes("enableuser") ||
                  item.isSuperAsmin) && (
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    disabled={updatingStatus}
                    onClick={(e) =>
                      this.onHandleEnableOrDisable(
                        e,
                        item.status === "Inactive"
                          ? ActionTypes.ENABLE
                          : ActionTypes.DISABLE,
                        "User"
                      )
                    }
                  >
                    <i className="far fa-edit mr-3" />
                    Enable
                  </Button>
                )}
              {item.status === "Active" &&
                getCurrentUsersRoles().includes("disableuser") && (
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    disabled={updatingStatus}
                    onClick={(e) =>
                      this.onHandleEnableOrDisable(
                        e,
                        item.status === "Inactive"
                          ? ActionTypes.ENABLE
                          : ActionTypes.DISABLE,
                        "User"
                      )
                    }
                  >
                    <i className="far fa-edit mr-3" />
                    Disable
                  </Button>
                )}
              {getCurrentUsersRoles().includes("edituser") && (
                <Button
                  size="sm"
                  variant="primary"
                  disabled={updatingStatus}
                  onClick={toggleEditModal}
                >
                  <i className="far fa-edit mr-3" />
                  Edit
                </Button>
              )}
            </React.Fragment>
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default UserDetail;
