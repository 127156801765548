import React from "react";
import _ from "lodash";
import { Link, RouteComponentProps } from "react-router-dom";
import "./login.css";
import recova_logo from "../../images/recova-logo.svg";
import recova_image from "../../images/recova-image.jpg";
import BaseFormComponent, {
  IBaseFormState,
} from "../../components/BaseFormComponent";
import authValidationService from "../../services/auth/authValidationService";
import authService, {
  getCurrentUserSession,
  saveUserSessionDetails,
} from "../../services/auth/authService";
import activityTrailService from "../../services/auditTrail/activityTrailService";
import SideBanner from "./../../components/sideBanner";
import TwoFa from "./TwoFa";
import institutionsService from "../../services/auth/institutionsService";
import systemSettingsService from "../../services/auth/systemSettingsService";
// const { JWT, JWK } = require('jose')

interface LoginFields {
  institutionCode: string;
  username: string;
  password: string;
}

class LoginForm extends BaseFormComponent<LoginFields> {}

interface ILoginProps extends RouteComponentProps {}
interface ILoginState {
  showTwoFa: boolean;
  userSession: any;
  username: string;
  password: string;
  institution: any;
  userId: any;
}

class Login extends React.Component<ILoginProps, IBaseFormState & ILoginState> {
  constructor(props: ILoginProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      showTwoFa: false,
      userSession: {},
      username: "",
      password: "",
      institution: {},
      userId: 0,
    };
  }

  onFormSubmit(fields: LoginFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        { errors: authValidationService.validateLoginForm(fields) },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(fields: LoginFields, onReloadFieldsCB: any): Promise<boolean> {
    let response: any;
    try {
      // console.log("starting submission...");
      this.setState({ submitting: true });
      const payload: any = {};
      payload.institutionCode = fields.institutionCode;
      payload.userName = fields.username;
      payload.password = fields.password;
      response = await authService.retrieveUser2(
        payload.institutionCode,
        payload.userName,
        payload.password
      );
      // console.log("retrieve user response: ", response);
      if (response.status >= 200 && response.status <= 300) {
        let institution = await institutionsService.retrieveInstitutionByCode(
          fields.institutionCode
        );
        let systemSettings =
          await systemSettingsService.retrieveSystemSettingsByInstitutionId(
            institution.data.id
          );
        // console.log("inspect institution: ", institution.data);
        // console.log("system settings: ", systemSettings);
        // console.log("inspect user details: ", response.data);
        institution.data.systemSettings = systemSettings.data;
        this.setState({
          institution: institution.data,
          userId: response.data.id,
        });
        if (institution.data.systemSettings.enable2FA) {
          console.log("proceeding to 2FA");
          this.setState({
            userSession: response.data,
            showTwoFa: true,
            username: fields.username,
            password: fields.password,
          });
        } else {
          var response2 = await authService.loginUser(payload);
          if (response2.status >= 200 && response2.status <= 300) {
            await authService.setRoleFunctionsForUser(
              response2.data.userId,
              response2.data.token
            );
            await saveUserSessionDetails(response2.data);
            await activityTrailService.saveActivityTrail({
              actionType: "Login",
              description: `User Logged In`,
            });
            this.props.history.push("/home");
          }
        }
      } else {
        return false;
      }
      this.setState({ submitting: false });
      onReloadFieldsCB();
      return true;
    } catch (error) {
      // console.log('ërror: ', error.response)
      this.setState({ errors: { loginError: error.response?.data.Message } });
      // if (!_.isEmpty(error.response.data.message)) {
      //   this.setState({ errors: { loginError: error.response.data.message } });
      // } else if (error?.response?.data) {
      //   this.setState({
      //     errors: { loginError: error.response.data },
      //   });
      // } else {
      //   this.setState({
      //     errors: { loginError: "Error Authenticating User" },
      //   });
      // }
      this.setState({ submitting: false });
      return false;
    } finally {
      this.setState({ submitting: false });
    }
  }

  toggle2FA = async (signInUser: boolean, userSession: any) => {
    if (signInUser) {
      this.props.history.push("/home");
    }
    this.setState({ showTwoFa: !this.state.showTwoFa });
  };

  componentDidMount() {
    // var privateKey =
    //   '6B8EbGdJgNjQmTqVsXv2x4A7C9EcHeKgPkRnTrWtYw3y5A8vaFcJfMhPmSpU'

    // const key = JWK.asKey({
    //   kty: 'oct',
    //   k: '6B8EbGdJgNjQmTqVsXv2x4A7C9EcHeKgPkRnTrWtYw3y5A8vaFcJfMhPmSpU'
    // })

    // const payload = {
    //   'urn:example:claim': 'foo'
    // }

    // const token = JWT.sign(payload, key, {
    //   audience: ['urn:example:client'],
    //   issuer: 'staging.recova.ng',
    //   expiresIn: '2 hours',
    //   header: {
    //     typ: 'JWT'
    //   }
    // })

    // var token = jwt.sign(
    //   { institutionId: '001', username: 'rodu', password: 'rimi' },
    //   privateKey,
    //   { algorithm: 'RS256' }
    // )
    // console.log('token: ', token)

    // console.log("check: ", getCurrentUserSession().sessionId === undefined);
    if (getCurrentUserSession().sessionId !== undefined) {
      // console.log("pushing to home");
      this.props.history.push("/home");
    }
  }

  render() {
    return (
      <React.Fragment>
        <LoginForm
          initialValues={{ institutionCode: "", username: "", password: "" }}
          FormComponent={({
            fields: { institutionCode, username, password },
            onChange,
            onReloadFields,
          }) => (
            <div className="container-fluid">
              <div
                id="primary"
                className="height-full responsive-phone row"
                style={{
                  backgroundImage: `url(${recova_image})`,
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="col-md-5">
                  <SideBanner />
                </div>
                <div className="col-md-7" style={{ backgroundColor: "white" }}>
                  <div className="row">
                    <div className="col text-right brand pt-3 pr-5">
                      <img
                        src={recova_logo}
                        width="145"
                        alt=""
                        className="image"
                      />
                    </div>
                  </div>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-3">
                        {/* <img src={recova_image} height="100vh" alt="" /> */}
                      </div>
                      <div className="col-lg-9 p-t-100">
                        <div className="text-black">
                          <h1>Log In</h1>
                          <p className="s-18 font-weight-lighter">
                            Enter your details in the fields below
                          </p>
                        </div>
                        <form action="#">
                          <div className="row">
                            <div className="col-lg-8">
                              <div className="form-group has-icon">
                                <i className="icon-account_balance"></i>
                                <input
                                  autoFocus
                                  type="number"
                                  id="institutionCode"
                                  name="institutionCode"
                                  value={institutionCode}
                                  onChange={(e) =>
                                    onChange(
                                      "institutionCode",
                                      e.currentTarget.value
                                    )
                                  }
                                  className="form-control form-control-lg"
                                  placeholder="Institution Code"
                                />
                              </div>
                            </div>
                            <div className="col-lg-8">
                              <div className="form-group has-icon">
                                <i className="icon-user"></i>
                                <input
                                  type="text"
                                  id="username"
                                  name="username"
                                  value={username}
                                  onChange={(e) =>
                                    onChange("username", e.currentTarget.value)
                                  }
                                  className="form-control form-control-lg"
                                  placeholder="Username"
                                />
                              </div>
                            </div>
                            <div className="col-lg-8">
                              <div className="form-group has-icon">
                                <i className="icon-lock"></i>
                                <input
                                  type="password"
                                  id="password"
                                  name="password"
                                  value={password}
                                  onChange={(e) =>
                                    onChange("password", e.currentTarget.value)
                                  }
                                  className="form-control form-control-lg"
                                  placeholder="Password"
                                />
                              </div>
                            </div>
                            {Object.keys(this.state.errors).length > 0 && (
                              <div className="col-lg-8">
                                <div
                                  className="alert alert-danger alert-dismissible"
                                  role="alert"
                                >
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">×</span>
                                  </button>
                                  <strong>
                                    {
                                      this.state.errors[
                                        Object.keys(this.state.errors)[0]
                                      ]
                                    }
                                  </strong>
                                </div>
                              </div>
                            )}
                            <div className="col-lg-8">
                              <button
                                id="login"
                                type="submit"
                                data-wait="Please wait..."
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.onFormSubmit(
                                    {
                                      institutionCode,
                                      username,
                                      password,
                                    },
                                    onReloadFields
                                  );
                                }}
                                disabled={this.state.submitting}
                                className={
                                  this.state.submitting === false
                                    ? "btn btn-primary btn-block btn-lg"
                                    : "btn btn-primary btn-block btn-lg disabled"
                                }
                              >
                                {this.state.submitting === false ? (
                                  <React.Fragment>
                                    <i className="fas fa-sign-in-alt mr-3"></i>
                                    Login
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <i className="fas fa-spin fa-circle-notch" />
                                  </React.Fragment>
                                )}
                              </button>
                              {/* <input
                                type="submit"
                                onClick={(e) => {
                                  e.preventDefault();
                                  onReloadFields(
                                    this.onFormSubmit({
                                      institutionCode,
                                      username,
                                      password,
                                    })
                                  );
                                }}
                                className="btn btn-primary btn-block"
                                value="Sign In"
                              /> */}
                              <p className="forget-pass text-orange">
                                <Link to="/forgotpassword">
                                  Forgot your password?
                                </Link>
                              </p>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        ></LoginForm>
        <TwoFa
          currentUser={this.state.userSession}
          toggle2FA={this.toggle2FA}
          showModal={this.state.showTwoFa}
          userId={this.state.userId}
          username={this.state.username}
          password={this.state.password}
          institution={this.state.institution}
        />
      </React.Fragment>
    );
  }
}

export default Login;
