import http from "../httpService";

const appraisalBaseAddress = process.env.REACT_APP_HYBRIDAPPRAISAL_BASEADDRESS;

export function retrieveByProcessType(processType) {
  return http.get(
    appraisalBaseAddress + `SystemProcess/RetrieveByProcessType/${processType}`
  );
}

export function retrieveById(id: number) {
  return http.get(
    appraisalBaseAddress + `SystemProcess/RetrieveById/${id}`
  );
}

export function retrieveAll() {
  return http.get(
    appraisalBaseAddress + `SystemProcess/RetrieveAll`
  );
}

export default {
  retrieveByProcessType,
  retrieveById,
  retrieveAll
};
