import React, { Fragment, Component } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../../../../components/detailItem";

interface ContactDetailsProps {
    values: any;
    handleChange: any;
    handleBlur: any;
}

interface ContactDetailsState {
    item: any;
}

class GroupContactDetails extends Component<ContactDetailsProps, ContactDetailsState> {
    constructor(props: ContactDetailsProps) {
        super(props);
        this.state = {
            item: {},
        };
    }

    static getDerivedStateFromProps(nextProps: ContactDetailsProps, prevState) {
        return {
            item: nextProps.values,
        };
    }

    render() {
        const { values } = this.props;

        return (
            <Fragment>
                <div className="card">
                    <div className="card-header clear-fix">
                        {_.isEmpty(values) ? (
                            <Skeleton width={200} />
                        ) : (
                            <h6
                                className="card-title float-left"
                                style={{ marginBottom: "0px" }}
                            >
                                <b>Contact Details</b>
                            </h6>
                        )}
                    </div>

                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                {_.isEmpty(values) ? (
                                    <Skeleton count={3} width={300} />
                                ) : (
                                    <dl className="row">
                                        {/* <DetailItem
                                            label="Address Type"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.addressType}
                                        /> */}
                                        <DetailItem
                                            label="Address"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.address}
                                        />
                                        <DetailItem
                                            label="State/Region"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.state}
                                        />
                                        <DetailItem
                                            label="Phone Number"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.phonenumber}
                                        />
                                        <DetailItem
                                            label="Email Address"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.customerEmail}
                                        />
                                        {/* <DetailItem
                                            label="Months of Stay at Current Address"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.monthsOfStayAtCurrentAddress}
                                        />
                                        <DetailItem
                                            label="Months of Stay at Current City"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.monthsOfStayAtCurrentCity}
                                        />
                                        <DetailItem
                                            label="Instagram ID"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.instagramID}
                                        /> */}

                                    </dl>
                                )}
                            </div>
                            <div className="col-md-6">
                                {_.isEmpty(values) ? (
                                    <Skeleton count={3} width={300} />
                                ) : (
                                    <dl className="row">
                                        {/* <DetailItem
                                            label="Property Status"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.propertyStatus}
                                        /> */}
                                        <DetailItem
                                            label="City/Town"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.city}
                                        />
                                        <DetailItem
                                            label="Country"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.country}
                                        />
                                        <DetailItem
                                            label="Phone Number 2"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.phonenumber2}
                                        />
                                        <DetailItem
                                            label="Location Co-ordinates"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.locationCoordinates}
                                        />
                                        {/* <DetailItem
                                            label="Years of Stay at Current Address"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.yearsOfStayAtCurrentAddress}
                                        />
                                        <DetailItem
                                            label="Years of Stay at Current City"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.yearsOfStayAtCurrentCity}
                                        />
                                        <DetailItem
                                            label="Facebook ID"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.facebookID}
                                        />
                                        <DetailItem
                                            label="Twitter Handle"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.twitterHandle}
                                        /> */}
                                    </dl>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default GroupContactDetails;