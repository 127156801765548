import React from "react";
import _ from "lodash";
import http from "../../services/httpService";
import speakeasy from "speakeasy";
import { Modal, Button } from "react-bootstrap";
// import { Link } from "react-router-dom";
import authService, {
  saveUserSessionDetails,
} from "../../services/auth/authService";
import activityTrailService from "../../services/auditTrail/activityTrailService";
import { toast } from "react-toastify";
import systemSettingsService from "../../services/auth/systemSettingsService";
import usersService from "../../services/auth/usersService";

export interface TwoFaProps {
  showModal: boolean;
  currentUser: any;
  toggle2FA: any;
  userId: number;
  username: string;
  password: string;
  institution: any;
}

export interface TwoFaState {
  data: {
    token: string;
  };
  submitting: boolean;
  errors: string;
}

interface TwoFaFields {
  token: string;
}

class TwoFa extends React.Component<TwoFaProps, TwoFaState> {
  constructor(props: TwoFaProps) {
    super(props);
    this.state = {
      data: {
        token: "",
      },
      errors: "",
      submitting: false,
    };
  }

  handleOnInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...this.state.data };
    if (_.isEmpty(e.currentTarget.min)) {
      // console.log("no min value");
      // console.log("testing: ", e.currentTarget.min);
      data[e.currentTarget.name] = e.currentTarget.value;
      this.setState({ data });
    } else {
      if (e.currentTarget.value >= e.currentTarget.min) {
        data[e.currentTarget.name] = e.currentTarget.value;
        this.setState({ data });
      }
    }
  };

  handleOnVerifyGoogle = (
    token: string,
    systemSettings: any
  ): { verified: boolean; errorMessage: string } => {
    var secret = JSON.parse(systemSettings.google2FASecret);
    // console.log("secret: ", secret);
    // console.log("secret.ascii", secret.ascii);
    var verified = speakeasy.totp.verify({
      secret: secret.ascii,
      encoding: "ascii",
      token: token,
    });
    let errorMessage = "";
    if (verified === true) {
      errorMessage = "Success";
    } else {
      errorMessage = "Invalid Token";
    }
    return { verified, errorMessage };
  };

  handleOnVerifyEntrust = async (token: string, systemSettings: any) => {
    // console.log("institution: ", this.props.institution);
    try {
      var response = await systemSettingsService.validateEntrust2FAToken(
        systemSettings.institutionId,
        this.props.username,
        token
      );

      // console.log("entrust response: ", response);
      if (response.status >= 200 && response.status < 300) {
        return {
          verified: response.data.isSuccessful,
          errorMessage: response.data.errorResponseMessage,
        };
      } else {
        return { verified: false, errorMessage: "An error occurred!" };
      }
    } catch (error) {
      // console.log("error: ", error);
      return { verified: false, errorMessage: error.message };
    }
  };

  handleOnSubmit = async () => {
    this.setState({ submitting: true });
    if (this.state?.data.token === "") {
      return;
    }
    var institution = this.props.institution;

    let _2faresponse = null;
    switch (institution.systemSettings.twoFAOption) {
      case "Google":
        console.log("verifying via google");
        _2faresponse = this.handleOnVerifyGoogle(
          this.state.data.token,
          institution.systemSettings
        );
        break;
      case "Entrust":
        console.log("verifying via entrust");
        _2faresponse = await this.handleOnVerifyEntrust(
          this.state.data.token,
          institution.systemSettings
        );
        break;
      default:
        console.log("verifying via default");
        _2faresponse = this.handleOnVerifyGoogle(
          this.state.data.token,
          institution.systemSettings
        );
        break;
    }

    // console.log("the final response: ", _2faresponse);
    if (_2faresponse.verified === false || _2faresponse.verified === "false") {
      try {
        // console.log("check: ", this.props.userId);
        var user = await usersService.incrementPasswordFailureCounter(
          this.props.userId
        );

        if (user.status >= 200 && user.status < 300) {
          if (user.data.passwordRetries >= 5) {
            toast.error("Account has been disabled", {
              autoClose: 6000,
              type: toast.TYPE.ERROR,
              hideProgressBar: false,
            });
            this.props.toggle2FA();
          }
          console.log("increment status response: ", user.data);
        }
      } catch (error) {
        console.log(error);
      }

      toast.error(_2faresponse.errorMessage, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      this.setState({ submitting: false });
    } else {
      const { currentUser, userId, username, password } = this.props;
      const { data } = this.state;
      try {
        // console.log('the currentuser: ', currentUser)
        // let user = await usersService.retrieveUserById(currentUser.userId);
        const payload: any = {};
        payload.institutionCode = currentUser.institution.code;
        payload.userName = username;
        payload.password = password;
        payload.token = data.token;
        let response = await authService.loginUser(payload);
        //console.log('2fa verify: ', response)
        // console.log("user:: ", currentUser);
        // console.log("token:: ", data.token);
        if (response.status >= 200 && response.status <= 300) {
          await authService.setRoleFunctionsForUser(
            response.data.userId,
            response.data.token
          );
          await saveUserSessionDetails(response.data);
          await activityTrailService.saveActivityTrail({
            actionType: "Login",
            description: `User Logged In`,
          });

          this.props.toggle2FA(true, response.data);
          this.setState({ submitting: false });
          return;
        } else {
          toast.error(`Failed to Login`, {
            autoClose: 6000,
            hideProgressBar: false,
          });
          this.setState({ submitting: false });
          return;
        }
      } catch (ex) {
        this.setState({ submitting: false });
        toast.error(`Something went wrong. Please, try again`, {
          autoClose: 6000,
          hideProgressBar: false,
        });
        return;
      }
    }
  };

  render() {
    const { data, submitting, errors } = this.state;
    const { showModal } = this.props;
    return (
      <Modal backdrop="static" show={showModal}>
        <form action="#">
          <div className="modal-header text-center">
            <h4 className="modal-title w-100">
              <b>Two-Factor Authentication</b>
            </h4>
          </div>
          <Modal.Body>
            <div className="text-center">
              <i className="fas fa-user-circle fa-6x" />
              <br />
              <br />
              {/* <h4>
                <b>
                  {_.isEmpty(currentUser)
                    ? ""
                    : `${currentUser.firstName} ${currentUser.lastName}`}
                </b>
              </h4> */}
              <h6 style={{ color: "red" }}>Please enter your 2FA Token</h6>
            </div>
            <div className="modal-body mx-3">
              <input
                type="password"
                style={{ textAlign: "center" }}
                id="token"
                value={data.token}
                name="token"
                className="form-control r-0 light s-12 mb-4"
                onChange={this.handleOnInputChange}
              />
            </div>
            <h6 className="text-center">
              <i style={{ color: "red" }}>{errors}</i>
            </h6>
            <br />
            <div className="text-center">
              <Button
                size="sm"
                type="submit"
                variant="primary"
                disabled={submitting}
                onClick={(e) => {
                  e.preventDefault();
                  this.handleOnSubmit();
                }}
              >
                {submitting === false ? (
                  <React.Fragment>
                    <i className="fas fa-unlock-alt mr-2"></i>Unlock
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <i className="fas fa-spin fa-circle-notch" />
                  </React.Fragment>
                )}
              </Button>
            </div>
          </Modal.Body>
          <div
            className="modal-footer justify-content-center"
            onClick={() => {
              this.props.toggle2FA(false, this.props.currentUser);
            }}
            style={{ cursor: "pointer" }}
          >
            <i className="fas fa-sign-in-alt ml-4 mr-2" />
            Back To Login
          </div>
        </form>
      </Modal>
    );
  }
}

export default TwoFa;
