import React from "react";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import regionsService from "../../../services/auth/regionsService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import authValidationService from "../../../services/auth/authValidationService";
import { ActionTypes } from "../../../enums/actionTypes";
import { ISelectItems } from "../../../components/detailItemSelectBox";
import _ from "lodash";
import branchesService from "../../../services/auth/branchesService";
import { generateGUID } from "../../../services/utility/textFormatService";

interface EditRegionFields {
  name: string;
  description: string;
  address: string;
}

class EditRegionForm extends BaseFormComponent<EditRegionFields> { }

interface IEditRegionProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}
interface IEditRegionState { 
  ownUpdate: boolean;
  assignedBranches: number[];
  branchesData: ISelectItems[];
  fetchingRequiredItems: boolean;
}

class EditRegion extends React.Component<
  IEditRegionProps,
  IBaseFormState & IEditRegionState
> {
  _isMounted = false;
  constructor(props: IEditRegionProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      ownUpdate: false,
      assignedBranches: [],
      branchesData: [],
      fetchingRequiredItems: false,
    };
  }

  static getDerivedStateFromProps(nextProps: IEditRegionProps, prevState) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isEmpty(nextProps.item)) {
      return null;
    }
    return {
      assignedBranches: nextProps.item.branches ? nextProps.item.branches.map(item => {
        return item.id
      }) : [],
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    const { errors } = this.state;
    try {
      if (this._isMounted) {
        this.setState({ fetchingRequiredItems: true, ownUpdate: true });
      }

      const response = await Promise.allSettled([
        branchesService.retrieveAllBranches()
      ])
      const branches = response[0].status == "fulfilled" ? response[0].value.data : [];
      if (this._isMounted) {
        this.setState({
          fetchingRequiredItems: false,
          branchesData: branches.map(item => {
            return { name: item.name, value: item.id }
          }),
          ownUpdate: true
        });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        errors.response = "Internal server error.";
        this.setState({ errors, ownUpdate: true });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onFormSubmit(fields: EditRegionFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        { 
          errors: authValidationService.validateEditRegionForm(fields),
          ownUpdate: true
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (fields: EditRegionFields, onReloadFieldsCB: any) => {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const payload = this.props.item;
      // console.log("For checks::", payload);
      payload.Id = payload.id;
      payload.name = fields.name;
      payload.description = fields.description;
      payload.address = fields.address;
      payload.assignedBranches = this.state.assignedBranches;
      const response = await regionsService.updateRegion(payload, payload.Id);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit Region",
          description: `Edited details for Region  ${fields.name}`,
        });

        if (response.data.isLoggedForApproval === true) {
          toast.warn("Action has been successfully logged for Approval!", {
            autoClose: 6000,
            type: toast.TYPE.WARNING,
            position: "top-center",
            hideProgressBar: false,
          });
        } else {
          toast.info(`Region ${fields.name} Edited successfully! `, {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          });
        }
      }
      onReloadFieldsCB();
      this.setState({ submitting: false, ownUpdate: true }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      // console.log("submission error: ", error);
      // this.setState({
      //   errors: "Invalid Username or Password",
      // });
      this.setState({ submitting: false, ownUpdate: true });
    }
  };

  handleOnChangeBranches = async (item) => {
    const assignedBranches = [...this.state.assignedBranches];
    if (assignedBranches.findIndex(ab => { return ab === item.value }) !== -1) {
        assignedBranches.splice(assignedBranches.findIndex(ab => { return ab === item.value }), 1);
    } 
    else {
      assignedBranches.push(item.value);
    }
    this.setState({ assignedBranches, ownUpdate: true });
  };

  render() {
    const { item, showEditModal, toggleEditModal } = this.props;
    const { validated, submitting, errors, assignedBranches, branchesData, fetchingRequiredItems } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-gopuram fa-sm cyan-text mr-3" />
              Edit Region -{" "}
              <span className="font-weight-bold">{item.name}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditRegionForm
          initialValues={{
            name: item.name,
            description: item.description,
            address: item.address,
          }}
          FormComponent={({
            fields: { name, description, address },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    <h6
                      className="card-title float-left"
                      style={{ marginBottom: "0px" }}
                    >
                      General Information
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label
                            htmlFor="name"
                            className="col-md-4 col-form-label"
                          >
                            Name:
                          </label>
                          <div className="col-md-8">
                            <input
                              autoFocus
                              type="text"
                              className="form-control form-control-sm"
                              id="name"
                              name="name"
                              value={name}
                              onChange={(e) =>
                                onChange("name", e.currentTarget.value)
                              }
                            />
                            <div
                              className="invalid-feedback"
                              style={{ visibility: errors.name }}
                            >
                              {errors.name}
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="code"
                            className="col-md-4 col-form-label"
                          >
                            Code:
                          </label>
                          <div className="col-md-8 col-form-label blue-grey-text">
                            {item.code}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="status"
                            className="col-md-4 col-form-label"
                          >
                            Status:
                          </label>
                          <div className="col-md-8 col-form-label blue-grey-text">
                            {(item ? item.status : "Active") === "Active" ? (
                              <span className="badge badge-success">
                                {item ? item.status : ""}
                              </span>
                            ) : (
                              <span className="badge badge-danger">
                                {item ? item.status : ""}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label
                            htmlFor="description"
                            className="col-md-4 col-form-label"
                          >
                            Description:
                          </label>
                          <div className="col-md-8">
                            <input
                              autoFocus
                              type="text"
                              className="form-control form-control-sm"
                              id="description"
                              name="description"
                              value={description}
                              onChange={(e) =>
                                onChange("description", e.currentTarget.value)
                              }
                            />
                            <div
                              className="invalid-feedback"
                              style={{ visibility: errors.description }}
                            >
                              {errors.description}
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="address"
                            className="col-md-4 col-form-label"
                          >
                            Address:
                          </label>
                          <div className="col-md-8">
                            <input
                              autoFocus
                              type="text"
                              className="form-control form-control-sm"
                              id="address"
                              name="address"
                              value={address}
                              onChange={(e) =>
                                onChange("address", e.currentTarget.value)
                              }
                            />
                            <div
                              className="invalid-feedback"
                              style={{ visibility: errors.address }}
                            >
                              {errors.address}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <label
                          htmlFor="branches"
                          className="control-label textbox-label pl-2"
                        >
                          Branches
                        </label>
                        <div
                          className="block-example border"
                          style={{
                            width: "100%",
                            maxWidth: "100%",
                            overflowX: "hidden",
                            height: "200px",
                            overflowY: "scroll",
                          }}
                        >
                          {branchesData.length > 0 ? (
                            <React.Fragment>
                              {branchesData.map(
                                (item) => (
                                  <div key={generateGUID()}
                                    style={{ border: '2px #84d4fc solid', borderRadius: '4px', backgroundColor: '#e4f4fc', marginTop: '5px', inlineSize: "100%" }}>
                                    <div
                                      className="custom-control custom-checkbox"
                                      style={{ display: 'inline-block' }}
                                      key={branchesData.indexOf(item)}
                                    >
                                      <input
                                        id={item.name}
                                        name={item.name}
                                        className="custom-control-input"
                                        type="checkbox"
                                        onChange={() =>
                                          this.handleOnChangeBranches(
                                            item
                                          )
                                        }
                                        checked={
                                          _.isEmpty(
                                            assignedBranches.filter(
                                              (x) => x === item.value
                                            )
                                          )
                                            ? false
                                            : true
                                        }
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={item.name}
                                      >
                                        {item.name}
                                      </label>
                                    </div>
                                  </div>
                                )
                              )}
                            </React.Fragment>
                          ) : (
                            "No Branch has been assigned to Region"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(e, ActionTypes.UPDATE, "Region", () => {
                      this.onFormSubmit(
                        { name, description, address },
                        onReloadFields
                      );
                    });
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></EditRegionForm>
      </Modal>
    );
  }
}

export default EditRegion;
