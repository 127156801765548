import React from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../components/detailItem";
import textFormatService, { printPDF } from "../../services/utility/textFormatService";
import DetailItemsTable from "../../components/tableComponents/detailItemsTable";
import { decodeToken } from "react-jwt";
import { mapEntityList } from "../../services/utility/autoMapperService";
import { getCurrentUserSession } from "../../services/auth/authService";
import institutionsService from "../../services/auth/institutionsService";
import sweepRunService from "../../services/sweep/sweepRunService";
import swal from "sweetalert";
import { toast } from "react-toastify";
import settlementService from "../../services/sweep/settlementService";
import reportsService from "../../services/sweep/reportsService";
import SettlementTransactionReportDetail from "./settlementTransactionDetail";
import ItemsTable from "../../components/tableComponents/itemsTable";

export interface FailedNIPReportDetailProps {
    showDetailsModal: boolean;
    toggleDetailsModal: any;
    item: any;
    institution: any;
}

export interface FailedNIPReportDetailState {
    pageSize: number;
    values: any;
    fetchingDetail: boolean;
    onToggleTranModal: boolean;
    showTranModal: boolean;
    item_: any;
}

class FailedNIPReportDetail extends React.Component<FailedNIPReportDetailProps, FailedNIPReportDetailState> {
    constructor(props: FailedNIPReportDetailProps) {
        super(props);
        this.state = {
            pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
            values: [],
            fetchingDetail: false,
            onToggleTranModal: false,
            showTranModal: false,
            item_: []
        };
    }

    toggleTransModal = () => {
        this.setState({ showTranModal: !this.state.showTranModal })
    }
    // handleOnExport = async (type: string) => {
    //     try {
    //         const decodedToken = decodeToken(getCurrentUserSession().token || "");
    //         switch (type) {
    //             case "XLSX":
    //             case "CSV":
    //                 return await reportsService.downloadFailedNIPReportDetail(decodedToken?.["InstitutionCode"], type);
    //             case "PDF":
    //                 const response = await reportsService.downloadFailedNIPReportDetail(decodedToken?.["InstitutionCode"], type);
    //                 printPDF(response?.data);
    //                 break;
    //             default:
    //                 break;
    //         }
    //     } catch (ex) {
    //         if (ex.response && ex.response.status >= 400 && ex.response.status <= 499) {
    //             // toast.error("Bad Request");
    //             // console.log(ex.response);
    //             // errors["response"] = "Internal server error.";
    //             // console.log(errors);
    //         } else {
    //             // toast.error("Internal server error. Please contact the admin.");
    //             //this.setState({ errors, fetching: false });
    //         }
    //     }
    // };


    async completeTransaction() {
        try {
            swal({
                title: "Confirm!",
                text: `Are you sure you want to Mark this Transaction as treated?`,
                icon: "warning",
                buttons: {
                    confirm: {
                        text: "Yes",
                        value: null,
                        visible: true,
                        className: "",
                        closeModal: true,
                    },
                    cancel: {
                        text: "Cancel",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true,
                    },
                },
                closeOnClickOutside: false,
                dangerMode: true,
            }).then(async (resp) => {
                if (resp) {
                    // console.log("not gonna cancel the mandate");
                } else {
                    const currentDate = new Date();
                    const payload: any = { ...this.props.item };
                    payload.manualSettlementStatus = "Treated"
                    payload.dateUpdated = currentDate;
                    var result = await settlementService.editManualNIPSettlement(payload);
                    // console.log(result);
                    toast.info(`Funding completed `, {
                        autoClose: 6000,
                        type: toast.TYPE.DEFAULT,
                        hideProgressBar: false,
                    });
                }
            });
        } catch (error) {
            // console.log("error: ", error);
            toast.error(`Unable to Complete Funding. `, {
                autoClose: 6000,
                type: toast.TYPE.ERROR,
                hideProgressBar: false,
            });
        }
    }

    render() {
        const { showDetailsModal, toggleDetailsModal, item, institution } = this.props;
        const { onToggleTranModal, pageSize, showTranModal, item_ } =
            this.state;

        return (
            <Modal size="xl" backdrop="static" show={showDetailsModal} onHide={toggleDetailsModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {_.isEmpty(item) ? (
                            <Skeleton width={500} />
                        ) : (
                            <h5>
                                <i className="fas fa-receipt fa-sm s-18 mr-3" />
                                Failed NIP Settlement Entry Detail
                            </h5>
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card">
                        {/* {console.log(repaymentTransactions)} */}
                        <div className="card-header clear-fix">
                            {_.isEmpty(item) ? (
                                <Skeleton width={200} />
                            ) : (
                                <h6 className="card-title float-left" style={{ marginBottom: "0px" }}>
                                    <b>Settlement Details</b>
                                </h6>
                            )}
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-8">
                                    {_.isEmpty(item) ? (
                                        <Skeleton count={5} width={300} />
                                    ) : (
                                        <dl className="row">
                                            <DetailItem
                                                label="Settlement Entry Flow"
                                                value={textFormatService.splitAtUpper(item.settlementEntryFlow?.replace("to", "To"))}
                                            />
                                            <DetailItem label="Sweep Cycle" value={item.sweepCycle} />
                                            <DetailItem label="Settlement Time" value={textFormatService.formatTimeString(item.settlementTime)} />
                                            <DetailItem label="Sweep Reference" value={item.sweepReference} />
                                            {/* {!_.isEmpty(item.settlementTime) && <DetailItem label="Setlement Time" value={item.settlementTime} />} */}
                                            <DetailItem label="Debited Institution" value={institution?.filter(x => x.code === item.debitedInstitution)[0]?.name} />
                                            <DetailItem label="Lender Institution" value={institution?.filter(x => x.code === item.lenderInstitution)[0]?.name} />
                                            <DetailItem label="Lender Sponsor Institution" value={institution?.filter(x => x.code === item.lenderSponsorInstitution)[0]?.name} />
                                            {/* <DetailItem label="No. Of Transactions" value={item.transactionCount} /> */}
                                            <DetailItem label="Settlement Amount" value={textFormatService.convertKoboToNaira(item.amount, 2)} />
                                            <DetailItem label="Debit Account" value={item.debitAccount} />
                                            <DetailItem label="Credit Account" value={item.creditAccount} />

                                            <DetailItem label="Transfer Date" value={item.settlementTime} />
                                            <DetailItem label="Amount Transferred" value={textFormatService.convertKoboToNaira(item.amount, 2)} />
                                            <DetailItem label="Transaction Status" value={item.status} />
                                        </dl>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {item.manualSettlementStatus === "Pending" && (
                        <Button
                            size="sm"
                            variant="outline-warning"
                            onClick={() => {
                                this.completeTransaction();
                                toggleDetailsModal();
                            }}
                        >
                            <i className="fas fa-ban mr-3"></i>
                            Funding completed
                        </Button>
                    )}
                    <Button size="sm" variant="outline-danger" onClick={toggleDetailsModal}>
                        <i className="fas fa-times mr-3" />
                        Close
                    </Button>
                    <SettlementTransactionReportDetail
                        showDetailsModal={showTranModal}
                        toggleDetailsModal={() => this.toggleTransModal()}
                        item={item_}
                    />
                </Modal.Footer>
            </Modal>
        );
    }
}

export default FailedNIPReportDetail;
