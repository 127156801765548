import React, { Fragment, Component, useContext } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../../../../components/detailItem";
import { Accordion, Card } from "react-bootstrap";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import textFormatService from "../../../../../../services/utility/textFormatService";

export function ContextAwareToggle({ eventKey, level }) {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      <b>Reference {level}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
}
interface GuarantorDetailsProps {
  values: any;
  handleChange: any;
  handleBlur: any;
}

interface GuarantorDetailsState {
  item: any;
}

class GuarantorDetails extends Component<GuarantorDetailsProps, GuarantorDetailsState> {
  constructor(props: GuarantorDetailsProps) {
    super(props);
    this.state = {
      item: {},
    };
  }

  static getDerivedStateFromProps(nextProps: GuarantorDetailsProps, prevState) {
    return {
      item: nextProps.values.guarantors,
    };
  }

  render() {
    const { values } = this.props;

    return (
      <Fragment>
        <div className="card">
          <div className="card-header clear-fix">
            {_.isEmpty(values) ? (
              <Skeleton width={200} />
            ) : (
              <h6
                className="card-title float-left"
                style={{ marginBottom: "0px" }}
              >
                <b>Reference Details</b>
              </h6>
            )}
          </div>

          <Accordion defaultActiveKey="0">
            <Card>
              <Accordion.Collapse eventKey={`0`}>
                <Card.Body>
                  <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                    <div className="row">
                      <div className="col-md-12">
                        {_.isEmpty(values.guarantors) ? (
                          <div style={{ textAlign: "center", color: "#FE825E" }}>
                            No Reference has been assigned to Loan Request
                          </div>
                        ) : (
                          <div>
                            {values.guarantors.map((item, idx) => (
                              <div key={idx}>
                                <Accordion defaultActiveKey={`${idx}`}>
                                  <Card>
                                    <ContextAwareToggle
                                      eventKey={`${idx}`}
                                      level={idx + 1}
                                    ></ContextAwareToggle>
                                    <Accordion.Collapse eventKey={`${idx}`}>
                                      <Card.Body className={"rule-card-body"}>
                                        <div className="card-body">
                                          <div className="row">
                                            <div className="col-md-6">
                                              <dl className="row">
                                                <DetailItem
                                                  label="Title"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.title}
                                                />
                                                <DetailItem
                                                  label="First Name"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.firstName}
                                                />
                                                <DetailItem
                                                  label="Last Name"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.lastName}
                                                />
                                                <DetailItem
                                                  label="Date of Birth"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item?.dateOfBirth ? item?.dateOfBirth.split("T")[0] : ""}
                                                />
                                                <DetailItem
                                                  label="City"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.city}
                                                />
                                                <DetailItem
                                                  label="Country"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.country}
                                                />
                                                <DetailItem
                                                  label="Phone Number 2"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.phoneNumber2}
                                                />
                                                <DetailItem
                                                  label="Marital Status"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.maritalStatus}
                                                />
                                                <DetailItem
                                                  label="State of Origin"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.stateOfOrigin}
                                                />
                                                <DetailItem
                                                  label="Guarantor BVN"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.guarantorsBVN}
                                                />
                                                <DetailItem
                                                  label="Occupation"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.occupation}
                                                />
                                                <DetailItem
                                                  label="Guarantor's Monthly Income"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={textFormatService.formatCurrency(Number(item.monthlyIncome), 2)}
                                                />
                                                <DetailItem
                                                  label="Guarantor's Other Assets Value/Cash Position"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.otherAssets}
                                                />

                                              </dl>
                                            </div>
                                            <div className="col-md-6">
                                              <dl className="row">
                                                <DetailItem
                                                  label="Relationship"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.relationship}
                                                />
                                                <DetailItem
                                                  label="Middle Name"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.middleName}
                                                />
                                                <DetailItem
                                                  label="Gender"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.gender}
                                                />
                                                <DetailItem
                                                  label="Address"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.address}
                                                />
                                                <DetailItem
                                                  label="State"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.state}
                                                />
                                                <DetailItem
                                                  label="LGA"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.lga}
                                                />
                                                <DetailItem
                                                  label="Phone Number"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.phoneNumber}
                                                />
                                                <DetailItem
                                                  label="Email Address"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.email}
                                                />
                                                <DetailItem
                                                  label="Name of Business/Employer"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.employerName}
                                                />
                                                <DetailItem
                                                  label="Office Address"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.officeAddress}
                                                />
                                                <DetailItem
                                                  label="Designation/Position"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.designation}
                                                />
                                                <DetailItem
                                                  label="Guarantor's Liabilities"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.liabilities}
                                                />


                                                <DetailItem
                                                  label="Loan Amount Guaranteed"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.loanAmountGuaranteed}
                                                />
                                                <DetailItem
                                                  label="Location Co-ordinates"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.locationCoordinates}
                                                />
                                              </dl>
                                            </div>
                                          </div>
                                          <div className="card mt-3">
                                            <div className="card-header clear-fix">
                                              <h6
                                                className="float-left"
                                                style={{ marginBottom: "0px" }}
                                              >
                                                <b>Customer Validation</b>
                                              </h6>
                                            </div>
                                            <div className="card-body">
                                              <div className="form-row">
                                                <DetailItem
                                                  label="BVN"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.guarantorsBVN}
                                                />
                                                <DetailItem
                                                  label="Customer Name"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.firstName + " " + item.lastName}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>


        </div>
      </Fragment>
    );
  }
}

export default GuarantorDetails;