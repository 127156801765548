import * as React from "react";
import _ from "lodash";
import { Fragment } from "react";
import {
  IFilterData,
  BaseListComponentState,
} from "../../../components/BaseListComponent";
import {
  createFilterData,
  mapEntityList,
} from "./../../../services/utility/autoMapperService";
import BaseListComponent from "./../../../components/BaseListComponent";
import ItemsTable, {
  filterFieldData,
} from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "./../../../components/tableComponents/tableFilterPane";
import { toast } from "react-toastify";
import scoringIndicatorService from "../../../services/creditAssessment/scoringIndicatorService";
import ScoreIndicatorDetail from "./scoreIndicatorDetail";
import EditScoreIndicator from "./editScoreIndicator";

export interface ScoreIndicatorsProps { }

export interface ScoreIndicatorsState extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
  updateBaseListState: boolean;
  toUpdate: boolean;
  allItems: any;
}

class ScoreIndicatorsList extends BaseListComponent<ScoreIndicatorsState> { }

class ScoreIndicators extends React.Component<
  ScoreIndicatorsProps,
  ScoreIndicatorsState
> {
  constructor(props: ScoreIndicatorsProps) {
    super(props);
    this.state = {
      data: createFilterData([]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
      updateBaseListState: false,
      toUpdate: true,
      allItems: [],
    };
  }

  handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true, updateBaseListState: false });
    try {
      const scoringIndicators =
        await scoringIndicatorService.retrieveAllScoreIndicators();

      const indicators: any = [];
      const totalLength = scoringIndicators.data.length;
      const initial = (currentPage - 1) * pageSize;
      for (let i = initial; i < initial + pageSize; i++) {
        if (i === totalLength) {
          break;
        }
        indicators.push(scoringIndicators.data[i]);
      }
      this.setState({
        tableItems: mapEntityList({
          // entityList: indicators.data.item2,
          entityList: indicators,
          properties: [
            { oldName: "indicatorName", newName: "indicatorName" },
            { oldName: "indicatorType", newName: "indicatorType" },
            { oldName: "entryType", newName: "entryType" },
            { oldName: "dateCreated", newName: "dateCreated" },
          ],
        }),
        currentPage,
        pageSize,
        // totalSize: indicators.data.item1,
        totalSize: totalLength,
        fetching: false,
        allItems: indicators.data,
      });
    } catch (error) {
      //   console.log("caught exception", error);
      //   console.log("caught exception", error.response);
      if (error.response.status === 400 || error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
        this.setState({ fetching: false });
      } else {
        // return false;
        this.setState({ fetching: false });
      }
    }
  };

  handleOnExport = async (type: string) => { };

  fetchItemById = async (item: any) => {
    this.setState({ fetchingDetail: true, updateBaseListState: false });
    const indicator =
      await scoringIndicatorService.retrieveScoreIndicatorById(
        item.id
      );
    // console.log("indicator :: ", indicator.data);
    this.setState({ item: indicator.data, fetchingDetail: false });
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    // console.log("filtered Data :: ", data);
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }

  render() {
    const {
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Score Indicators</b>
              </h3>
            </div>
            <ScoreIndicatorsList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              updateState={this.state.updateBaseListState}
              initialValues={{
                data: createFilterData([]) as IFilterData,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: this.state.filterValueInput,
                item: {},
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                onToggleEditModal,
                showDetailsModal,
                showEditModal,
              }) => (
                <Fragment>
                  <ScoreIndicatorDetail
                    showDetailsModal={showDetailsModal}
                    toggleDetailsModal={onToggleDetailsModal}
                    toggleEditModal={onToggleEditModal}
                    showTrail={null}
                    currentUserRoles={{}}
                    item={item}
                  />
                  <EditScoreIndicator
                    showEditModal={showEditModal}
                    toggleEditModal={(reloadDetails: boolean, item: any) => {
                      onToggleEditModal();
                      if (reloadDetails === true) {
                        this.fetchItemById(item);
                      }
                      this.setState({
                        showDetailsModal: !this.state.showDetailsModal,
                      });
                    }}
                    item={item}
                  />
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="Score Indicators"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                              timeColumn={["dateCreated"]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></ScoreIndicatorsList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ScoreIndicators;
