import React from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import FormInputComponent from "../../../components/formInputComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import SelectInputComponent, {
  ISelectItems,
} from "../../../components/formSelectComponent";
import appraisalValidationService from "../../../services/hybridAppraisal/appraisalValidationService";
import appraisalPlanService from "../../../services/hybridAppraisal/appraisalPlanService";
import systemProcessService from "../../../services/hybridAppraisal/systemProcessService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

interface AddAppraisalPlanFields {
  institutionCategory: string;
  name: string;
  reportIds: any;
  validationIds: any;
}

interface ISystemProcess {
  id: number;
  name: string;
  description: string;
}

class AddAppraisalPlanForm extends BaseFormComponent<AddAppraisalPlanFields> { }

interface IAddAppraisalPlanProps { }
interface IAddAppraisalPlanState {
  fetchingRequiredItems: boolean;
  reports: ISystemProcess[];
  reportItems: any;
  validations: ISystemProcess[];
  validationItems: any;
}

class AddAppraisalPlan extends React.Component<
  IAddAppraisalPlanProps,
  IBaseFormState & IAddAppraisalPlanState
> {
  _isMounted = false;
  constructor(props: IAddAppraisalPlanProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      fetchingRequiredItems: true,
      reports: [],
      reportItems: [],
      validations: [],
      validationItems: []
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { errors } = this.state;
    try {
      if (this._isMounted) {
        this.setState({ fetchingRequiredItems: true });
      }
      const reportItems = await systemProcessService.retrieveByProcessType("Report");
      const validationItems = await systemProcessService.retrieveByProcessType("Validation");
      this.setState({
        reportItems: reportItems.data,
        validationItems: validationItems.data,
        fetchingRequiredItems: false,
      })
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        errors.response = "Internal server error.";
        this.setState({ errors });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onFormSubmit(fields: AddAppraisalPlanFields, onReloadFieldsCB: any): boolean {
    try {
      fields.reportIds = this.state.reports;
      fields.validationIds = this.state.validations;
      this.setState(
        {
          errors: appraisalValidationService.validateAddAppraisalPlanForm(fields),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddAppraisalPlanFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true });
      const payload: any = {};
      payload.institutionCategory = fields.institutionCategory;
      payload.name = fields.name;
      payload.reports = this.state.reports;
      payload.validations = this.state.validations;

      // console.log("Appraisal Plan", payload)
      const response = await appraisalPlanService.saveAppraisalPlan(payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Add Appraisal Plan",
          description: `Added new Appraisal Plan ${fields.name}`,
        });
        toast.info(`Appraisal Plan ${fields.name} created successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false });
      this.resetFields();
      onReloadFieldsCB();
    } catch (error) {
      toast.error(error.response.data, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      this.setState({ submitting: false });
    } finally {
      return true;
    }
  }

  handleOnChangeAppraisalPlanReport = (item: ISystemProcess) => {
    var item: ISystemProcess = { id: item.id, name: item.name, description: item.description };
    const reports = [...this.state.reports];
    if (reports.findIndex(object => { return object.id === item.id }) !== -1) {
      reports.splice(reports.findIndex(object => { return object.id === item.id }), 1);
    } else {
      reports.push(item);
    }
    this.setState({ reports });
  }

  handleOnChangeAppraisalPlanValidation = (item: ISystemProcess) => {
    var item: ISystemProcess = { id: item.id, name: item.name, description: item.description };
    const validations = [...this.state.validations];
    if (validations.findIndex(object => { return object.id === item.id }) !== -1) {
      validations.splice(validations.findIndex(object => { return object.id === item.id }), 1);
    } else {
      validations.push(item);
    }
    this.setState({ validations });
  }

  validateField = (fieldName: string, type: string, validated: boolean) => {
    let defaultClass = "";
    if (type === "checkbox") {
      defaultClass = "custom-control-input";
      if (validated === false) return defaultClass;
    } else {
      defaultClass = "form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    }
    return !validated
      ? defaultClass
      : this.state.errors[fieldName]
        ? `${defaultClass} is-invalid`
        : `${defaultClass} is-valid`;
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      reports: [],
      validations: [],
    });
  }

  render() {
    const {
      errors,
      validated,
      fetchingRequiredItems,
      reports,
      reportItems,
      validations,
      validationItems
    } = this.state;

    let institutionCategoryInputData: ISelectItems[] = [
      { name: "Commercial Bank", value: "CommercialBank" },
      { name: "BankOne FI", value: "BankOneOFI" },
      { name: "OFI", value: "OFI" },
    ];

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add Appraisal Plan</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddAppraisalPlanForm
                  initialValues={{
                    institutionCategory: "",
                    name: "",
                    reportIds: [],
                    validationIds: []
                  }}
                  FormComponent={({
                    fields: {
                      institutionCategory,
                      name,
                      reportIds,
                      validationIds
                    },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">

                              {/* General Information */}
                              <div className="form-row">
                                <SelectInputComponent
                                  id="institutionCategory"
                                  name="institutionCategory"
                                  divClass={6}
                                  value={institutionCategory}
                                  items={institutionCategoryInputData}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="name"
                                  type="text"
                                  name="name"
                                  divClass={6}
                                  placeholder=""
                                  value={name}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>

                              {/* Reports */}
                              <div className="form-row">
                                <div className="form-group col-md-12 mt-2">
                                  <label
                                    htmlFor="reports"
                                    className="col-form-label s-10"
                                  >
                                    Reports
                                  </label>
                                  <br />
                                  {fetchingRequiredItems ? (
                                    <i className="fas fa-spinner fa-spin mr-2" />
                                  ) : (
                                    reportItems.map((report) => (
                                      <div
                                        key={report.id}
                                        className="custom-control custom-checkbox custom-control-inline"
                                      >
                                        <input
                                          type="checkbox"
                                          className={this.validateField(
                                            "reportIds",
                                            "checkbox",
                                            validated
                                          )}
                                          id={report.id}
                                          checked={
                                            _.isEmpty(
                                              reports.filter(
                                                (x) => x.id === report.id
                                              ))
                                              ? false
                                              : true
                                          }
                                          onChange={() =>
                                            this.handleOnChangeAppraisalPlanReport(
                                              report
                                            )
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={report.id}
                                        >
                                          {report.name}
                                        </label>
                                      </div>
                                    ))
                                  )}
                                  <div
                                    className="invalid-feedback"
                                    style={{
                                      visibility: errors.reportIds ? "visible" : "hidden",
                                      display: errors.reportIds ? "block" : "none",
                                    }}
                                  >
                                    {errors.reportIds}
                                  </div>
                                </div>
                              </div>

                              {/* Validations */}
                              <div className="form-row">
                                <div className="form-group col-md-12 mt-2">
                                  <label
                                    htmlFor="validations"
                                    className="col-form-label s-10"
                                  >
                                    Validations
                                  </label>
                                  <br />
                                  {fetchingRequiredItems ? (
                                    <i className="fas fa-spinner fa-spin mr-2" />
                                  ) : (
                                    validationItems.map((validation) => (
                                      <div
                                        key={validation.id}
                                        className="custom-control custom-checkbox custom-control-inline"
                                      >
                                        <input
                                          type="checkbox"
                                          className={this.validateField(
                                            "validationIds",
                                            "checkbox",
                                            validated
                                          )}
                                          id={validation.id}
                                          checked={
                                            _.isEmpty(
                                              validations.filter(
                                                (x) => x.id === validation.id
                                              ))
                                              ? false
                                              : true
                                          }
                                          onChange={() =>
                                            this.handleOnChangeAppraisalPlanValidation(
                                              validation
                                            )
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={validation.id}
                                        >
                                          {validation.name}
                                        </label>
                                      </div>
                                    ))
                                  )}
                                  <div
                                    className="invalid-feedback"
                                    style={{
                                      visibility: errors.validationIds ? "visible" : "hidden",
                                      display: errors.validationIds ? "block" : "none",
                                    }}
                                  >
                                    {errors.validationIds}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary"
                          disabled={this.state.submitting}
                          onClick={(e) => {
                            onHandleSubmit(
                              e,
                              ActionTypes.SAVE,
                              "Appraisal Plan",
                              () => {
                                this.onFormSubmit(
                                  {
                                    institutionCategory,
                                    name,
                                    reportIds,
                                    validationIds
                                  },
                                  onReloadFields
                                );
                              }
                            );
                          }}
                        >
                          {this.state.submitting === false ? (
                            <React.Fragment>
                              <i className="fas fa-lg fa-save mr-3" /> Save
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                              Please wait...
                            </React.Fragment>
                          )}
                        </button>
                      </div>
                    </form>
                  )}
                ></AddAppraisalPlanForm>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddAppraisalPlan;
