import React, { Fragment, Component } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../../../../components/detailItem";
import { formatShortTimeString, getAgeInMonthsFromPastDate } from "../../../../../../services/utility/textFormatService";

interface CorporateDetailsProps {
  values: any;
  handleChange: any;
  handleBlur: any;
}

interface CorporateDetailsState {
  item: any;
}

class CorporateDetails extends Component<CorporateDetailsProps, CorporateDetailsState> {
  constructor(props: CorporateDetailsProps) {
    super(props);
    this.state = {
      item: {},
    };
  }

  static getDerivedStateFromProps(nextProps: CorporateDetailsProps, prevState) {
    return {
      item: nextProps.values,
    };
  }

  render() {
    const { values } = this.props;

    return (
      <Fragment>
        <div className="card">
          {console.log(values)}
          <div className="card-header clear-fix">
            {_.isEmpty(values) ? (
              <Skeleton width={200} />
            ) : (
              <h6
                className="card-title float-left"
                style={{ marginBottom: "0px" }}
              >
                <b>Corporate Details</b>
              </h6>
            )}
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                {_.isEmpty(values) ? (
                  <Skeleton count={3} width={300} />
                ) : (
                  <dl className="row">
                    <DetailItem
                      label="Company Name"
                      labelSize={7}
                      valueSize={5}
                      value={values.customerName}
                    />
                    <DetailItem
                      label="Constitution"
                      labelSize={7}
                      valueSize={5}
                      value={values.constitution}
                    />
                    <DetailItem
                      label="Incorporation Date"
                      labelSize={7}
                      valueSize={5}
                      value={formatShortTimeString(values?.incorporationDate)}
                    />
                    <DetailItem
                      label="Designation"
                      labelSize={7}
                      valueSize={5}
                      value={values.designation}
                    />
                    <DetailItem
                      label="Account Type"
                      labelSize={7}
                      valueSize={5}
                      value={values.accountType}
                    />
                    <DetailItem
                      label="Age of Account"
                      labelSize={7}
                      valueSize={5}
                      value={values.repaymentAccountCreationDate ? getAgeInMonthsFromPastDate(values.repaymentAccountCreationDate).toString() : values.ageOfAccount}
                    />
                    <DetailItem
                      label="Type of Product/Service offered"
                      labelSize={7}
                      valueSize={5}
                      value={values.typeOfProductOffered}
                    />
                    <DetailItem
                      label="Threat of Substitute/Competition"
                      labelSize={7}
                      valueSize={5}
                      value={values.threatOfCompetition}
                    />
                    <DetailItem
                      label="Complexity of Product/Service"
                      labelSize={7}
                      valueSize={5}
                      value={values.complexityOfProduct}
                    />
                    <DetailItem
                      label="Number of Years Operating in the Same Location"
                      labelSize={7}
                      valueSize={5}
                      value={values.numberOfYearsOperatingInTheSameLocation}
                    />
                    <DetailItem
                      label="Membership of an Organized Body"
                      labelSize={7}
                      valueSize={5}
                      value={values.membershipOfAnOrganizedBody}
                    />
                  </dl>
                )}
              </div>
              <div className="col-md-6">
                {_.isEmpty(values) ? (
                  <Skeleton count={3} width={300} />
                ) : (
                  <dl className="row">
                    <DetailItem
                      label="Registration Number"
                      labelSize={7}
                      valueSize={5}
                      value={values.bvn}
                    />
                    <DetailItem
                      label="Industry"
                      labelSize={7}
                      valueSize={5}
                      value={values.industry}
                    />
                    <DetailItem
                      label="Contact Person"
                      labelSize={7}
                      valueSize={5}
                      value={values.contactPerson}
                    />
                    <DetailItem
                      label="Length of Years in Business"
                      labelSize={7}
                      valueSize={5}
                      value={values.lengthOfYearsInBusiness}
                    />
                    <DetailItem
                      label="Account Number"
                      labelSize={7}
                      valueSize={5}
                      value={values.linkedAccountNumber}
                    />
                    <DetailItem
                      label="Domiciliation Of Business Proceeds"
                      labelSize={7}
                      valueSize={5}
                      value={values.domiciliationOfBusinessProceeds}
                    />
                    <DetailItem
                      label="Product Durability"
                      labelSize={7}
                      valueSize={5}
                      value={values.productDurability}
                    />
                    <DetailItem
                      label="Availability of Input material/Trading stock"
                      labelSize={7}
                      valueSize={5}
                      value={values.availabilityOfTradingStock}
                    />
                    <DetailItem
                      label="Operational Structure"
                      labelSize={7}
                      valueSize={5}
                      value={values.operationalStructure}
                    />
                    <DetailItem
                      label="Foreign Exchange Risk"
                      labelSize={7}
                      valueSize={5}
                      value={values.foreignExchangeRisk}
                    />
                  </dl>
                )}
              </div>

            </div>

          </div>
          <div className="card mt-3">
            <div className="card-header clear-fix">
              <h6
                className="float-left"
                style={{ marginBottom: "0px" }}
              >
                <b>Customer Validation</b>
              </h6>
            </div>
            <div className="card-body">
              <div className="form-row">
                <DetailItem
                  label="BVN"
                  labelSize={7}
                  valueSize={5}
                  value={values.bvn}
                />
                <DetailItem
                  label="Customer Name"
                  labelSize={7}
                  valueSize={5}
                  value={values.customerName}
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default CorporateDetails;