import _ from "lodash";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import BaseFormComponent, { IBaseFormState } from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import authValidationService from "../../../services/auth/authValidationService";
import institutionsService from "../../../services/auth/institutionsService";
import sweepConfigService from "../../../services/sweep/sweepConfigService";
import FormInputComponent from "../../../components/formInputComponent";
import SelectInputComponent from "../../../components/formSelectComponent";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import feeConfigService from "../../../services/sweep/feeConfigService";
import recovaFeeConfigService from "../../../services/billing/recovaFeeConfigService";
import thirdPartyFeeConfigService from "../../../services/billing/thirdPartyFeeConfigService";
import institutionFeeConfigService from "../../../services/billing/institutionFeeConfigService";

interface AddSweepConfigurationFields {
    name: string;
    code: string;
    status: string;
    shortName: string;
    externalInstitutionCode: string;
    integrationCode: string;
    category: string;
    feeIncomeAccount: string;
    feeExpenseAccount: string;
    settlementMirrorAccount: string;
    receivableAccount: string;
    loanCollectionsAccount: string;
    suspenseAccount: string;
    settlementInstitutionCode: string;
    nipBankCode: string;
    appZoneNIPAccountName: string;
    appZoneIncomeAccountNo: string;
    sweepInitiationType: string;
    sweepable: boolean;
    chargeCustomer: boolean;
}

class AddSweepConfigurationForm extends BaseFormComponent<AddSweepConfigurationFields> {}

export interface IAddSweepConfigurationProps {
    showAddSweepConfigModal: boolean;
    toggleAddSweepConfigModal: any;
    institution: any;
}

export interface IAddSweepConfigurationState {
    sweepable: boolean;
    chargeCustomer: boolean;
}

class AddSweepConfiguration extends React.Component<IAddSweepConfigurationProps, IBaseFormState & IAddSweepConfigurationState> {
    constructor(props: IAddSweepConfigurationProps) {
        super(props);
        this.state = {
            validated: false,
            submitting: false,
            errors: {},
            sweepable: false,
            chargeCustomer: false,
        };
    }

    toggleSweepable = () => {
        this.setState({ sweepable: !this.state.sweepable });
    };

    toggleChargeCustomer = () => {
        this.setState({ chargeCustomer: !this.state.chargeCustomer });
    };

    onFormSubmit(fields: AddSweepConfigurationFields, onReloadFieldsCB: any): boolean {
        try {
            // console.log("about to run validations...");
            fields.sweepable = this.state.sweepable;
            fields.chargeCustomer = this.state.chargeCustomer;
            this.setState(
                {
                    errors: authValidationService.validateAddSweepConfigurationForm(fields),
                },
                () => {
                    // console.log("sweep errors: ", this.state.errors);
                    if (Object.keys(this.state.errors).length === 0) {
                        return this.submit(fields, onReloadFieldsCB);
                    }
                }
            );
            this.setState({ validated: true });
            return false;
        } catch (error) {
            return false;
        }
    }

    async submit(fields: AddSweepConfigurationFields, onReloadFieldsCB: any): Promise<boolean> {
        try {
            this.setState({ submitting: true });
            // console.log("commencing submission...");
            const { institution } = this.props;
            // console.log("institution: ", institution);
            const payload: any = {};
            payload.settlementInstitutionCode = "";
            if (!_.isEmpty(institution.clearingBank)) {
                // console.log("fetching settlement institutions..");
                const settlementInstitution = await institutionsService.retrieveInstitutionById(institution.clearingBankId);
                payload.settlementInstitutionCode = settlementInstitution.data.code;
            }

            payload.name = institution.name;
            payload.code = institution.code;
            payload.shortName = institution.shortName;
            payload.externalInstitutionCode = institution.externalInstitutionCode;
            payload.status = fields.status;
            payload.sweepInitiationType = fields.sweepInitiationType;

            switch (institution.category) {
                case "CommercialBank":
                    payload.integrationCode = "CommercialBankIntegration";
                    break;
                case "BankOneOFI":
                    payload.integrationCode = "BankOneIntegration";
                    break;
                case "OFI":
                    payload.integrationCOde = "OFIIntegration";
                    break;
                default:
                    payload.integrationCOde = "OFIIntegration";
                    break;
            }

            // payload.integrationCode =
            //   institution.category === "CommercialBank"
            //     ? "CommercialBankIntegration"
            //     : "BankOneIntegration";
            payload.feeIncomeAccount = fields.feeIncomeAccount;
            payload.feeExpenseAccount = fields.feeExpenseAccount;
            payload.settlementMirrorAccount = fields.settlementMirrorAccount;
            payload.receivableAccount = fields.receivableAccount;
            payload.loanCollectionsAccount = fields.loanCollectionsAccount;
            payload.suspenseAccount = fields.suspenseAccount;
            payload.nipBankCode = fields.nipBankCode;
            payload.appZoneNIPAccountName = fields.appZoneNIPAccountName;
            payload.appZoneNIPAccountNo = fields.appZoneIncomeAccountNo;
            payload.sweepInitiationType = fields.sweepInitiationType;
            payload.dmb = institution.dmb;
            payload.chargeCustomer = this.state.chargeCustomer;
            payload.sweepable = institution.category === "CommercialBank" || institution.dmb ? true : this.state.sweepable;

            this.setState({ submitting: true });
            // console.log("payload: ", payload);
            const response = await sweepConfigService.saveSweepConfig(payload);

            if (response.status >= 200 && response.status <= 300) {
                //saving recova and thirdParty fee configuraton
                var serviceOffering = ["Sweep", "Collections"];
                var recovaSweepFeeConfig = (await recovaFeeConfigService.filterRecovaFeeConfig(serviceOffering[0], institution.category)).data[0];
                var recovaCollectionsFeeConfig = (await recovaFeeConfigService.filterRecovaFeeConfig(serviceOffering[1], institution.category))
                    .data[0];

                const sweepFeeConfigPayload: any = {};
                sweepFeeConfigPayload.institutionId = institution.id;
                sweepFeeConfigPayload.institutionCode = institution.code;
                sweepFeeConfigPayload.activeLoansPercentage = recovaSweepFeeConfig.percentageAmountPerActiveLoan;
                sweepFeeConfigPayload.lostLoansPercentage = recovaSweepFeeConfig.percentageAmountPerLostLoan;
                sweepFeeConfigPayload.flatAmount = recovaSweepFeeConfig.amountPerSuccessfulTrx;
                sweepFeeConfigPayload.minimumFee = recovaSweepFeeConfig.minimumFeePerSuccessfulTrx;
                sweepFeeConfigPayload.maximumFee = recovaSweepFeeConfig.maximumFeePerSuccessfulTrx;
                sweepFeeConfigPayload.sponsorBankCommission = recovaSweepFeeConfig.sponsorBankCommission;
                sweepFeeConfigPayload.debitedBankCommission = recovaSweepFeeConfig.debitedBankCommission;
                sweepFeeConfigPayload.repaymentType = "Recovery";

                const collectionsFeeConfigPayload: any = {};
                collectionsFeeConfigPayload.institutionId = institution.id;
                collectionsFeeConfigPayload.institutionCode = institution.code;
                collectionsFeeConfigPayload.activeLoansPercentage = recovaCollectionsFeeConfig.percentageAmountPerActiveLoan;
                collectionsFeeConfigPayload.lostLoansPercentage = recovaCollectionsFeeConfig.percentageAmountPerLostLoan;
                collectionsFeeConfigPayload.flatAmount = recovaCollectionsFeeConfig.amountPerSuccessfulTrx;
                collectionsFeeConfigPayload.minimumFee = recovaCollectionsFeeConfig.minimumFeePerSuccessfulTrx;
                collectionsFeeConfigPayload.maximumFee = recovaCollectionsFeeConfig.maximumFeePerSuccessfulTrx;
                collectionsFeeConfigPayload.sponsorBankCommission = recovaCollectionsFeeConfig.sponsorBankCommission;
                collectionsFeeConfigPayload.debitedBankCommission = recovaCollectionsFeeConfig.debitedBankCommission;
                collectionsFeeConfigPayload.repaymentType = "Collection";

                await feeConfigService.saveFeeConfig(sweepFeeConfigPayload);
                await feeConfigService.saveFeeConfig(collectionsFeeConfigPayload);

                var thirdPartySweepFeeConfig = (
                    await thirdPartyFeeConfigService.filterThirdPartyFeeConfig(serviceOffering[0], institution.category, "")
                ).data;
                var thirdPartyCollectionsFeeConfig = (
                    await thirdPartyFeeConfigService.filterThirdPartyFeeConfig(serviceOffering[1], institution.category, "")
                ).data;
                for (let i = 0; i < thirdPartySweepFeeConfig.length; i++) {
                    const sweepFeeConfigPayload: any = {};
                    sweepFeeConfigPayload.serviceOffering = thirdPartySweepFeeConfig[i].serviceOffering;
                    sweepFeeConfigPayload.institutionCode = institution.code;
                    sweepFeeConfigPayload.appzonePercentageAmountPerActiveLoan = thirdPartySweepFeeConfig[i].appzonePercentageAmountPerActiveLoan;
                    sweepFeeConfigPayload.appzonePercentageAmountPerLostLoan = thirdPartySweepFeeConfig[i].appzonePercentageAmountPerLostLoan;
                    sweepFeeConfigPayload.appzoneFlatAmountPerSuccessfulTrx = thirdPartySweepFeeConfig[i].appzoneFlatAmountPerSuccessfulTrx;
                    sweepFeeConfigPayload.appzoneMinimumFeePerSuccessfulTrx = thirdPartySweepFeeConfig[i].appzoneMinimumFeePerSuccessfulTrx;
                    sweepFeeConfigPayload.appzoneMaximumFeePerSuccessfulTrx = thirdPartySweepFeeConfig[i].appzoneMaximumFeePerSuccessfulTrx;
                    sweepFeeConfigPayload.thirdPartyPercentageAmountSwept = thirdPartySweepFeeConfig[i].thirdPartyPercentageAmountSwept;
                    sweepFeeConfigPayload.thirdParty = thirdPartySweepFeeConfig[i].thirdParty;
                    sweepFeeConfigPayload.thirdPartyFlatAmountPerSuccessfulTrx = thirdPartySweepFeeConfig[i].thirdPartyFlatAmountPerSuccessfulTrx;
                    sweepFeeConfigPayload.thirdPartyMinimumFeePerSuccessfulTrx = thirdPartySweepFeeConfig[i].thirdPartyMinimumFeePerSuccessfulTrx;
                    sweepFeeConfigPayload.thirdPartyMaximumFeePerSuccessfulTrx = thirdPartySweepFeeConfig[i].thirdPartyMaximumFeePerSuccessfulTrx;
                    institutionFeeConfigService.saveInstitutionFeeConfig(sweepFeeConfigPayload);
                }
                for (let i = 0; i < thirdPartyCollectionsFeeConfig.length; i++) {
                    const collectionsFeeConfigPayload: any = {};
                    collectionsFeeConfigPayload.serviceOffering = thirdPartyCollectionsFeeConfig[i].serviceOffering;
                    collectionsFeeConfigPayload.institutionCode = institution.code;
                    collectionsFeeConfigPayload.appzonePercentageAmountPerActiveLoan =
                        thirdPartyCollectionsFeeConfig[i].appzonePercentageAmountPerActiveLoan;
                    collectionsFeeConfigPayload.appzonePercentageAmountPerLostLoan =
                        thirdPartyCollectionsFeeConfig[i].appzonePercentageAmountPerLostLoan;
                    collectionsFeeConfigPayload.appzoneFlatAmountPerSuccessfulTrx =
                        thirdPartyCollectionsFeeConfig[i].appzoneFlatAmountPerSuccessfulTrx;
                    collectionsFeeConfigPayload.appzoneMinimumFeePerSuccessfulTrx =
                        thirdPartyCollectionsFeeConfig[i].appzoneMinimumFeePerSuccessfulTrx;
                    collectionsFeeConfigPayload.appzoneMaximumFeePerSuccessfulTrx =
                        thirdPartyCollectionsFeeConfig[i].appzoneMaximumFeePerSuccessfulTrx;
                    collectionsFeeConfigPayload.thirdPartyPercentageAmountSwept = thirdPartyCollectionsFeeConfig[i].thirdPartyPercentageAmountSwept;
                    collectionsFeeConfigPayload.thirdParty = thirdPartyCollectionsFeeConfig[i].thirdParty;
                    collectionsFeeConfigPayload.thirdPartyFlatAmountPerSuccessfulTrx =
                        thirdPartyCollectionsFeeConfig[i].thirdPartyFlatAmountPerSuccessfulTrx;
                    collectionsFeeConfigPayload.thirdPartyMinimumFeePerSuccessfulTrx =
                        thirdPartyCollectionsFeeConfig[i].thirdPartyMinimumFeePerSuccessfulTrx;
                    collectionsFeeConfigPayload.thirdPartyMaximumFeePerSuccessfulTrx =
                        thirdPartyCollectionsFeeConfig[i].thirdPartyMaximumFeePerSuccessfulTrx;
                    institutionFeeConfigService.saveInstitutionFeeConfig(collectionsFeeConfigPayload);
                }
                await activityTrailService.saveActivityTrail({
                    actionType: "Add Sweep Configuration",
                    description: `Added new Sweep Configuration for ${institution.name}`,
                });
                toast.info(`Sweep Configuration created successfully for ${institution.name} `, {
                    autoClose: 6000,
                    type: toast.TYPE.DEFAULT,
                    hideProgressBar: false,
                });
                this.setState({ submitting: false }, () => this.props.toggleAddSweepConfigModal(true, institution));
            } else {
                return false;
            }
            onReloadFieldsCB();
        } catch (error) {
            this.setState({ submitting: false });
            // console.log("submission error: ", error);
            return false;
        } finally {
            return true;
        }
    }

    validateField = (fieldName: string, type: string, validated: boolean) => {
        let defaultClass = "";
        if (type === "select") {
            defaultClass = "custom-select select2 mr-sm-2 form-control r-0 light s-12";
            if (validated === false) return defaultClass;
        } else {
            defaultClass = "form-control r-0 light s-12";
            if (validated === false) return defaultClass;
        }
        return !validated ? defaultClass : this.state.errors[fieldName] ? `${defaultClass} is-invalid` : `${defaultClass} is-valid`;
    };

    onHideModal = () => {
        this.setState({ validated: false, errors: {} });
        this.props.toggleAddSweepConfigModal(false, {});
    };

    render() {
        const { showAddSweepConfigModal, institution } = this.props;
        const { validated, submitting, errors, sweepable, chargeCustomer } = this.state;
        return (
            <Modal size="xl" backdrop="static" show={showAddSweepConfigModal} onHide={this.onHideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>
                            <i className="fas fa-tools fa-sm cyan-text mr-3" />
                            Add Sweep Configuration - <span className="font-weight-bold">{institution.name}</span>
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <AddSweepConfigurationForm
                    initialValues={{
                        name: "",
                        code: "",
                        status: "Active",
                        shortName: "",
                        externalInstitutionCode: "",
                        integrationCode: "",
                        category: institution.category,
                        feeIncomeAccount: "",
                        feeExpenseAccount: "",
                        settlementMirrorAccount: "",
                        receivableAccount: "",
                        loanCollectionsAccount: "",
                        suspenseAccount: "",
                        settlementInstitutionCode: "",
                        nipBankCode: "",
                        appZoneNIPAccountName: "",
                        appZoneIncomeAccountNo: "",
                        sweepInitiationType: "",
                        sweepable: this.state.sweepable,
                        chargeCustomer: this.state.chargeCustomer,
                    }}
                    FormComponent={({
                        fields: {
                            name,
                            code,
                            status,
                            appZoneNIPAccountName,
                            appZoneIncomeAccountNo,
                            category,
                            externalInstitutionCode,
                            feeExpenseAccount,
                            feeIncomeAccount,
                            integrationCode,
                            loanCollectionsAccount,
                            nipBankCode,
                            receivableAccount,
                            settlementInstitutionCode,
                            settlementMirrorAccount,
                            shortName,
                            suspenseAccount,
                            sweepInitiationType,
                        },
                        onChange,
                        onReloadFields,
                        onHandleSubmit,
                    }) => (
                        <React.Fragment>
                            <Modal.Body>
                                <form action="#">
                                    <div className="card no-b">
                                        <div className="card-body">
                                            <h5 className="card-title">REPAYMENT CONFIGURATION</h5>
                                            <div className="form-row">
                                                <div className="col-md-6">
                                                    <SelectInputComponent
                                                        id="sweepInitiationType"
                                                        name="sweepInitiationType"
                                                        value={sweepInitiationType}
                                                        items={[
                                                            { name: "Reactive", value: "Reactive" },
                                                            { name: "Proactive", value: "Proactive" },
                                                            { name: "Both", value: "Both" },
                                                        ]}
                                                        required={true}
                                                        validated={validated}
                                                        errors={errors}
                                                        onChange={onChange}
                                                    />
                                                    {!sweepable && (
                                                        <>
                                                            <FormInputComponent
                                                                id="feeIncomeAccount"
                                                                type="text"
                                                                name={"Bank's Fee Income Account"}
                                                                placeholder=""
                                                                value={feeIncomeAccount}
                                                                required={true}
                                                                validated={validated}
                                                                errors={errors}
                                                                onChange={onChange}
                                                            />
                                                            <FormInputComponent
                                                                id="feeExpenseAccount"
                                                                type="text"
                                                                name={"Recova Fee Expense Account"}
                                                                placeholder=""
                                                                value={feeExpenseAccount}
                                                                required={true}
                                                                validated={validated}
                                                                errors={errors}
                                                                onChange={onChange}
                                                            />
                                                            {institution.category !== "CommercialBank" && (
                                                                <FormInputComponent
                                                                    id="receivableAccount"
                                                                    type="text"
                                                                    name={"receivableAccount"}
                                                                    placeholder=""
                                                                    value={receivableAccount}
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                />
                                                            )}
                                                        </>
                                                    )}
                                                    {institution.category === "BankOneOFI" && (
                                                        <FormInputComponent
                                                            id="loanCollectionsAccount"
                                                            type="text"
                                                            name={"recovaLoanCollectionsAccount"}
                                                            placeholder=""
                                                            value={loanCollectionsAccount}
                                                            required={true}
                                                            validated={validated}
                                                            errors={errors}
                                                            onChange={onChange}
                                                        />
                                                    )}
                                                </div>
                                                <div className="col-md-6">
                                                    {!sweepable && (
                                                        <>
                                                            <FormInputComponent
                                                                id="suspenseAccount"
                                                                type="text"
                                                                name={"recovaSuspenseAccount"}
                                                                placeholder=""
                                                                value={suspenseAccount}
                                                                required={true}
                                                                validated={validated}
                                                                errors={errors}
                                                                onChange={onChange}
                                                            />
                                                            {institution.category !== "CommercialBank" && (
                                                                <FormInputComponent
                                                                    id="settlementMirrorAccount"
                                                                    type="text"
                                                                    name={"recovaSettlementAccountMirror"}
                                                                    placeholder=""
                                                                    value={settlementMirrorAccount}
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                />
                                                            )}
                                                            {institution.category === "CommercialBank" && (
                                                                <FormInputComponent
                                                                    id="nipBankCode"
                                                                    type="text"
                                                                    name={"NIP BankCode"}
                                                                    placeholder=""
                                                                    value={nipBankCode}
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                />
                                                            )}
                                                            {/* {institution.category === "CommercialBank" && (
                            <FormInputComponent
                              id="appZoneNIPAccountName"
                              type="text"
                              name={"appZoneNIPAccountName"}
                              placeholder=""
                              value={appZoneNIPAccountName}
                              required={true}
                              validated={validated}
                              errors={errors}
                              onChange={onChange}
                              />
                          )} */}
                                                            {institution.category === "CommercialBank" && (
                                                                <FormInputComponent
                                                                    id="appZoneIncomeAccountNo"
                                                                    type="text"
                                                                    label="AppZone Income Account No"
                                                                    name={"appZoneIncomeAccountNo"}
                                                                    placeholder=""
                                                                    value={appZoneIncomeAccountNo}
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                />
                                                            )}
                                                        </>
                                                    )}
                                                    <SelectInputComponent
                                                        id="status"
                                                        name="status"
                                                        value={status}
                                                        items={[
                                                            { name: "Active", value: "Active" },
                                                            { name: "Inactive", value: "Inactive" },
                                                        ]}
                                                        required={true}
                                                        validated={validated}
                                                        errors={errors}
                                                        onChange={onChange}
                                                    />
                                                    <div className="form-group col-md-6 m-0">
                                                        <label htmlFor="sweepable" className="col-form-label s-12">
                                                            Disable Sweep Postings
                                                        </label>
                                                        <br />
                                                        <span className="material-switch ml-2">
                                                            <input
                                                                id="sweepable"
                                                                name="sweepable"
                                                                type="checkbox"
                                                                onChange={this.toggleSweepable}
                                                                checked={sweepable}
                                                            />
                                                            <label htmlFor="sweepable" className="bg-primary"></label>
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="form-group col-md-6 m-0">
                                                        <label htmlFor="chargeCustomer" className="col-form-label s-12">
                                                            Charge Customer For Sweep Postings
                                                        </label>
                                                        <br />
                                                        <span className="material-switch ml-2">
                                                            <input
                                                                id="chargeCustomer"
                                                                name="chargeCustomer"
                                                                type="checkbox"
                                                                onChange={this.toggleChargeCustomer}
                                                                checked={chargeCustomer}
                                                            />
                                                            <label htmlFor="chargeCustomer" className="bg-primary"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <br />
                                <br />
                                <br />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    size="sm"
                                    variant="secondary"
                                    onClick={(e) => {
                                        onHandleSubmit(e, ActionTypes.SAVE, "Sweep Configuration", () => {
                                            this.onFormSubmit(
                                                {
                                                    name,
                                                    code,
                                                    status,
                                                    appZoneNIPAccountName,
                                                    appZoneIncomeAccountNo,
                                                    category,
                                                    externalInstitutionCode,
                                                    feeExpenseAccount,
                                                    feeIncomeAccount,
                                                    integrationCode,
                                                    loanCollectionsAccount,
                                                    nipBankCode,
                                                    receivableAccount,
                                                    settlementInstitutionCode,
                                                    settlementMirrorAccount,
                                                    shortName,
                                                    suspenseAccount,
                                                    sweepInitiationType,
                                                    sweepable,
                                                    chargeCustomer,
                                                },
                                                onReloadFields
                                            );
                                        });
                                    }}
                                    disabled={submitting}
                                >
                                    {submitting === false ? (
                                        <React.Fragment>
                                            <i className="fas fa-lg fa-save mr-3" /> Save
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <i className="fas fa-spin fa-circle-notch" />
                                        </React.Fragment>
                                    )}
                                </Button>
                                <Button size="sm" variant="outline-danger" onClick={this.onHideModal} disabled={submitting}>
                                    <i className="fas fa-times mr-3" />
                                    Close
                                </Button>
                            </Modal.Footer>
                        </React.Fragment>
                    )}
                ></AddSweepConfigurationForm>
            </Modal>
        );
    }
}

export default AddSweepConfiguration;
