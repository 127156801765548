import * as React from "react";
import _ from "lodash";
import { Fragment } from "react";
import {
  IFilterData,
  BaseListComponentState,
} from "../../../components/BaseListComponent";
import {
  createFilterData,
  mapEntityList,
} from "../../../services/utility/autoMapperService";
import BaseListComponent from "../../../components/BaseListComponent";
import ItemsTable, {
  filterFieldData,
} from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "../../../components/tableComponents/tableFilterPane";
import appraisalLoanProductService from "../../../services/hybridAppraisal/loanProductService";
import scoreCardService from "../../../services/creditAssessment/scoringCardService";
import ProductConfigurationDetail from "./productConfigurationDetail";
import EditProductConfiguration from "./editProductConfiguration";
import appraisalInstitutionService from "../../../services/hybridAppraisal/appraisalInstitutionService";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../../services/auth/authService";

export interface LoanProductsProps { }

export interface LoanProductsState extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
  allItems: any;
  showPatchModal: boolean;
}

class LoanProductsList extends BaseListComponent<LoanProductsState> { }

class LoanProducts extends React.Component<
  LoanProductsProps,
  LoanProductsState
> {
  _isMounted = false;
  constructor(props: LoanProductsProps) {
    super(props);
    this.state = {
      data: createFilterData([]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
      showPatchModal: false,
      allItems: [],
    };
  }

  handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true });
    let dataClone =
      typeof data === "undefined" ? { ...this.state.data } : { ...data };
    try {
      const loanProds = await appraisalLoanProductService.filterLoanProductConfig(
        dataClone,
        pageSize,
        currentPage
      );
      this.setState({
        tableItems: mapEntityList({
          entityList: loanProds.data.item2,
          properties: [
            { oldName: "name", newName: "productName" },
            { oldName: "productCode", newName: "productCode" },
            { oldName: "productCategory", newName: "productCategory" },
            { oldName: "tenure", newName: "tenure" },
            { oldName: "minimumAmount", newName: "minimumAmount" },
            { oldName: "maximumAmount", newName: "maximumAmount" },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: loanProds.data.item1,
        fetching: false,
      });
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        this.setState({ fetching: false });
      } else {
        this.setState({ fetching: false });
      }
    }
  };

  handleOnExport = async (type: string) => { };

  fetchItemById = async (item: any) => {
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    this.setState({ fetchingDetail: true });
    const loanProduct = await appraisalLoanProductService.retrieveById(item.id);
    let productConfig = loanProduct.data;
    const scoreCard = await scoreCardService.retrieveScoreCardById(productConfig.scoreCardId);
    const institution = await appraisalInstitutionService.retrieveByInstitutionCode(decodedToken?.["InstitutionCode"]);
    productConfig["scoreCardName"] = scoreCard.data.name;
    productConfig["institution"] = institution.data;
    this.setState({ item: productConfig, fetchingDetail: false });
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;

    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Product Configurations</b>
              </h3>
            </div>
            <LoanProductsList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              initialValues={{
                data: createFilterData([]) as IFilterData,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: this.state.filterValueInput,
                item: {},
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                onToggleEditModal,
                showDetailsModal,
                showEditModal,
              }) => (
                <Fragment>
                  <ProductConfigurationDetail
                    showDetailsModal={showDetailsModal}
                    toggleDetailsModal={onToggleDetailsModal}
                    toggleEditModal={onToggleEditModal}
                    item={item}
                  />
                  <EditProductConfiguration
                    showEditModal={showEditModal}
                    toggleEditModal={(reloadDetails: boolean, item: any) => {
                      onToggleEditModal();
                      if (reloadDetails === true) {
                        this.fetchItemById(item);
                      }
                      this.setState({
                        showDetailsModal: !this.state.showDetailsModal,
                      });
                    }}
                    item={item}
                  />
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="Product Configurations"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                              amountColumn={["minimumAmount", "maximumAmount"]}
                              amountInNaira={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></LoanProductsList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default LoanProducts;
