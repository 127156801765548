import http from "../httpService";
import { IFilterData } from "../approval/approvalItemService";

const appraisalBaseAddress = process.env.REACT_APP_HYBRIDAPPRAISAL_BASEADDRESS;
const thirdpartyBaseAddress = process.env.REACT_APP_REMITA_INTEGRATION_BASEADDRESS;

export function InitateBankStatementRequest(payload) {
    return http.post(
        appraisalBaseAddress + `ThirdParty/InitiateBankStatementRequest`,
        payload
    );
}


export default {
    InitateBankStatementRequest,
}