import React, { Fragment } from "react";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import creditAssessmentValidationService from "../../../services/creditAssessment/creditAssessmentValidationService";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../components/detailItem";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import messageTemplateService from "../../../services/creditAssessment/messageTemplateService";

interface EditMessageTemplateFields {
  messageType: "SMS" | "Email";
  subject: string;
  body: string;
  bccEmailAddresses: string;
}

class EditMessageTemplateForm extends BaseFormComponent<EditMessageTemplateFields> { }

interface IEditMessageTemplateProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}
interface IEditMessageTemplateState { }

class EditMessageTemplate extends React.Component<
  IEditMessageTemplateProps,
  IBaseFormState & IEditMessageTemplateState
> {
  constructor(props: IEditMessageTemplateProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
    };
  }

  onFormSubmit(fields: EditMessageTemplateFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors: creditAssessmentValidationService.validateEditMessageTemplateForm(
            fields
          ),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (fields: EditMessageTemplateFields, onReloadFieldsCB: any) => {
    try {
      this.setState({ submitting: true });
      const { item } = this.props;
      const payload: any = {};
      payload.subject = fields.subject;
      payload.body = fields.body;
      payload.bccEmailAddresses = fields.bccEmailAddresses;

      //   console.log("For checks::", payload);
      const response = await messageTemplateService.updateMessageTemplate(
        item.id,
        payload
      );
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit Message Template",
          description: `Edited details for Message Template ${item.name}`,
        });

        toast.info(`Message Template for ${item.name} Edited successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      }
      onReloadFieldsCB();
      this.setState({ submitting: false }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      //   console.log("submission error: ", error);
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // console.log("submission error: ", error.response);
      }
      this.setState({ submitting: false });
    }
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      errors: {},
    });
  }

  render() {
    const { item, showEditModal, toggleEditModal } = this.props;
    const { validated, submitting, errors } = this.state;

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-columns fa-sm cyan-text mr-3" />
              Edit Message Template -{" "}
              <span className="font-weight-bold">{item.name}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditMessageTemplateForm
          initialValues={{
            subject: item.subject,
            body: item.body,
            bccEmailAddresses: item.bccEmailAddresses,
            messageType: item.messageType,
          }}
          FormComponent={({
            fields: { subject, messageType, bccEmailAddresses, body },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    {_.isEmpty(item) ? (
                      <Skeleton width={200} />
                    ) : (
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        <b>General Information</b>
                      </h6>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={2} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Subject"
                              value={
                                <DetailItemInputComponent
                                  id="subject"
                                  type="text"
                                  name="subject"
                                  placeholder=""
                                  value={subject}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={5}
                              valueSize={7}
                            />
                            <DetailItem
                              label="Body"
                              value={
                                <Fragment>
                                  <textarea
                                    id="body"
                                    name="body"
                                    placeholder=""
                                    className={
                                      !validated
                                        ? "form-control r-0 light s-12"
                                        : errors.body
                                          ? "form-control r-0 light s-12 is-invalid"
                                          : "form-control r-0 light s-12 is-valid"
                                    }
                                    rows={6}
                                    value={body}
                                    onChange={(e) =>
                                      // this.handleOnChangeMessage(
                                      //   e.currentTarget.value
                                      // )
                                      onChange("body", e.currentTarget.value)
                                    }
                                  ></textarea>
                                  <div
                                    className="invalid-feedback"
                                    style={{
                                      visibility: errors.body,
                                    }}
                                  >
                                    {errors.body}
                                  </div>
                                </Fragment>
                              }
                              labelSize={5}
                              valueSize={7}
                            />
                          </dl>
                        )}
                      </div>
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={2} width={300} />
                        ) : (
                          <dl className="row">
                            {messageType == "SMS" ? ("") : (
                              <DetailItem
                                label="Bcc Email Addresses"
                                value={
                                  <DetailItemInputComponent
                                    id="bccEmailAddresses"
                                    type="text"
                                    name="bccEmailAddresses"
                                    placeholder=""
                                    value={bccEmailAddresses}
                                    required={true}
                                    validated={validated}
                                    errors={errors}
                                    onChange={onChange}
                                  />
                                }
                                labelSize={5}
                                valueSize={7}
                              />
                            )}
                          </dl>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.UPDATE,
                      "Message Template",
                      () => {
                        this.onFormSubmit(
                          { body, bccEmailAddresses, messageType, subject },
                          onReloadFields
                        );
                      }
                    );
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></EditMessageTemplateForm>
      </Modal>
    );
  }
}

export default EditMessageTemplate;
