import http from "../httpService";
import { IFilterData } from "../approval/approvalItemService";

const appraisalBaseAddress = process.env.REACT_APP_HYBRIDAPPRAISAL_BASEADDRESS;

export function retrieveById(id) {
    return http.get(
        appraisalBaseAddress + `Supplier/RetrieveById/${id}`
    );
}

export function retrieveAll() {
    return http.get(
        appraisalBaseAddress + `Supplier/RetrieveAll`
    );
}

export function updateSupplier(payload) {
    return http.put(
        appraisalBaseAddress + `Supplier/EditSupplier`,
        payload
    );
}

export function createSupplier(payload) {
    return http.post(
        appraisalBaseAddress + `Supplier/CreateSupplier`,
        payload
    );
}

export function uploadSuppliers(payload) {
    return http.post(
        appraisalBaseAddress + `Supplier/UploadSuppliers`,
        payload
    );
}

export function validateSupplierRecords(payload) {
    return http.post(
        appraisalBaseAddress + `supplier/ValidateSupplierRecords`,
        payload
    );
}

export function downloadBulkSuppliersTemplate(payload) {
    return http.get(
        appraisalBaseAddress + `Supplier/DownloadBulkSuppliersTemplate`,
        payload
    );
}

export function filterSuppliers(
    payload: IFilterData,
    maxSize: number,
    pageNum: number
) {
    payload = { ...payload };
    return http.post(
        appraisalBaseAddress +
        `Supplier/FilterSuppliers?pageNum=${pageNum}&maxSize=${maxSize}`,
        payload
    );
}

export default {
    retrieveById,
    retrieveAll,
    createSupplier,
    updateSupplier,
    filterSuppliers,
    validateSupplierRecords,
    uploadSuppliers,
    downloadBulkSuppliersTemplate
}