import React, { Fragment, Component } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../../../../components/detailItem";
import { formatShortTimeString, getAgeInMonthsFromPastDate } from "../../../../../../services/utility/textFormatService";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../../../../../services/auth/authService";
import appraisalInstitutionService from "../../../../../../services/hybridAppraisal/appraisalInstitutionService";

interface PersonalDetailsProps {
  values: any;
  handleChange: any;
  handleBlur: any;
}

interface PersonalDetailsState {
  item: any;
  localClient: boolean;
}

class PersonalDetails extends Component<PersonalDetailsProps, PersonalDetailsState> {
  _isMounted: boolean;
  constructor(props: PersonalDetailsProps) {
    super(props);
    this.state = {
      item: {},
      localClient: true
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const decodedToken = decodeToken(getCurrentUserSession().token || "");

    const institution = await appraisalInstitutionService.retrieveByInstitutionCode(decodedToken?.["InstitutionCode"]);
    const institutionDetails = institution.data;
    if (institutionDetails.institutionType === "Local") {
      this.setState({ localClient: true })
    }
    else {
      this.setState({ localClient: false })
    }

  }

  static getDerivedStateFromProps(nextProps: PersonalDetailsProps, prevState) {
    return {
      item: nextProps.values,
    };
  }

  render() {
    const { values } = this.props;
    // console.log("in here", values)
    // console.log("in here j", values)
    // console.log("in here j", values.ageOfAccount ? 144: 12)
    // console.log("age of acct", values.ageOfAccount ? values.ageOfAccount: getAgeInMonthsFromPastDate(values.repaymentAccountCreationDate).toString())

    // let acctNo = ""
    // if (values?.linkedAccountNo === "" || values?.linkedAccountNo === null) {
    //   const cbaItem = JSON.parse(JSON.parse(JSON.parse(values?.jsonData)))
    //   acctNo = cbaItem.LinkedAccountNumber
    // } else {
    //   acctNo = values?.linkedAccountNo
    // }
    return (
      <Fragment>
        <div className="card">
          <div className="card-header clear-fix">
            {_.isEmpty(values) ? (
              <Skeleton width={200} />
            ) : (
              <h6
                className="card-title float-left"
                style={{ marginBottom: "0px" }}
              >
                <b>Personal Details</b>
              </h6>
            )}
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                {_.isEmpty(values) ? (
                  <Skeleton count={7} width={300} />
                ) : (
                  <dl className="row">
                    <DetailItem
                      label="Title"
                      labelSize={7}
                      valueSize={5}
                      value={values.title}
                    />
                    <DetailItem
                      label="Middle Name"
                      labelSize={7}
                      valueSize={5}
                      value={values.customerMiddleName}
                    />
                    <DetailItem
                      label="Gender"
                      labelSize={7}
                      valueSize={5}
                      value={values.gender}
                    />
                    <DetailItem
                      label="Marital Status"
                      labelSize={7}
                      valueSize={5}
                      value={values.maritalStatus}
                    />
                    <DetailItem
                      label="Age"
                      labelSize={7}
                      valueSize={5}
                      value={values.age}
                    />
                    <DetailItem
                      label="Nationality"
                      labelSize={7}
                      valueSize={5}
                      value={values.nationality}
                    />
                    <DetailItem
                      label="Account Type"
                      labelSize={7}
                      valueSize={5}
                      value={values.accountType}
                    />
                    <DetailItem
                      label="Account Number"
                      labelSize={7}
                      valueSize={5}
                      value={values.linkedAccountNumber}
                    />
                    <DetailItem
                      label="Identification Type"
                      labelSize={7}
                      valueSize={5}
                      value={values.identificationType}
                    />
                    <DetailItem
                      label="Identification Expiry Date"
                      labelSize={7}
                      valueSize={5}
                      value={formatShortTimeString(values?.identificationExpiryDate)}
                    />
                    {this.state.localClient ? (
                      <DetailItem
                        label="Customer BVN"
                        labelSize={7}
                        valueSize={5}
                        value={values.bvn}
                      />
                    ) : (
                      <DetailItem
                        label="Customer TIN"
                        labelSize={7}
                        valueSize={5}
                        value={values.tin}
                      />
                    )}
                  </dl>
                )}
              </div>
              <div className="col-md-6">
                {_.isEmpty(values) ? (
                  <Skeleton count={7} width={300} />
                ) : (
                  <dl className="row">
                    <DetailItem
                      label="First Name"
                      labelSize={7}
                      valueSize={5}
                      value={values.customerFirstName}
                    />
                    <DetailItem
                      label="Last Name"
                      labelSize={7}
                      valueSize={5}
                      value={values.customerLastName}
                    />
                    <DetailItem
                      label="Date of Birth"
                      labelSize={7}
                      valueSize={5}
                      value={formatShortTimeString(values?.dateOfBirth)}
                    />
                    <DetailItem
                      label="Mother Maiden's Name"
                      labelSize={7}
                      valueSize={5}
                      value={values.motherMaidenName}
                    />
                    <DetailItem
                      label="Educational Qualification"
                      labelSize={7}
                      valueSize={5}
                      value={values.educationalQualification}
                    />
                    <DetailItem
                      label="Number of Dependent(s)"
                      labelSize={7}
                      valueSize={5}
                      value={values.numberOfDependent}
                    />
                    <DetailItem
                      label="Age of Account"
                      labelSize={7}
                      valueSize={5}
                      value={values.repaymentAccountCreationDate ? getAgeInMonthsFromPastDate(values.repaymentAccountCreationDate).toString() : values.ageOfAccount}
                    />
                    <DetailItem
                      label="Domiciliation of Salary"
                      labelSize={7}
                      valueSize={5}
                      value={values.domiciliationOfSalary}
                    />
                    <DetailItem
                      label="Identification Number"
                      labelSize={7}
                      valueSize={5}
                      value={values.identificationNumber}
                    />
                  </dl>
                )}
              </div>
            </div>
            <div className="card mt-3">
              <div className="card-header clear-fix">
                <h6
                  className="float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>Customer Validation</b>
                </h6>
              </div>
              <div className="card-body">
                <div className="form-row">
                  <DetailItem
                    label="BVN"
                    labelSize={7}
                    valueSize={5}
                    value={values.bvn}
                  />
                  <DetailItem
                    label="Customer Name"
                    labelSize={7}
                    valueSize={5}
                    value={values.customerFirstName + " " + values.customerLastName}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default PersonalDetails;