import React, { Fragment } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import SelectInputComponent, {
  ISelectItems,
} from "../../../components/formSelectComponent";
import { getCurrentUserSession } from "../../../services/auth/authService";
import { ActionTypes } from "../../../enums/actionTypes";
import { decodeToken } from "react-jwt";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import loanProductService from "../../../services/hybridAppraisal/loanProductService";
import financialGroupService from "../../../services/hybridAppraisal/financialGroupService";
import loanProductMapService from "../../../services/hybridAppraisal/loanProductMapService";
import appraisalValidationService from "../../../services/hybridAppraisal/appraisalValidationService";
import workFlowConfigService from "../../../services/hybridAppraisal/workFlowConfigService";

interface AddLoanProductMapFields {
  loanProduct: string;
  workFlowConfig: string;
  dataEntryConfig: string;
}

interface IMapLoanProduct {
  loanProductConfigId: number;
  loanProductName: string;
  financialGroupId: number;
  financialGroupName: string;
  institutionCode?: string;
  workflowId: number;
  workflowName: string;
}

class AddLoanProductMapForm extends BaseFormComponent<AddLoanProductMapFields> { }

interface IAddLoanProductMapProps { }
interface IAddLoanProductMapState {
  loanProducts: any;
  financialGroups: any;
  workFlowConfigs: any;
  fetchingRequiredItems: boolean;
  mapLoanProduct: IMapLoanProduct[];
  loanProduct: string;
}

class AddLoanProductMap extends React.Component<
  IAddLoanProductMapProps,
  IBaseFormState & IAddLoanProductMapState
> {
  _isMounted = false;
  constructor(props: IAddLoanProductMapProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      loanProducts: [],
      financialGroups: [],
      fetchingRequiredItems: false,
      mapLoanProduct: [],
      workFlowConfigs: [],
      loanProduct: ""
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { errors } = this.state;
    try {
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      if (this._isMounted) {
        this.setState({ fetchingRequiredItems: true })
      }
      const response = await Promise.allSettled([
        loanProductService.retrieveByInstitutionCode(decodedToken?.["InstitutionCode"]),
        financialGroupService.retrieveByInstitutionCode(decodedToken?.["InstitutionCode"]),
        loanProductMapService.retrieveAllByInstitution(decodedToken?.["InstitutionCode"]),
        workFlowConfigService.retrieveByInstitutionCode(decodedToken?.["InstitutionCode"])
      ])
      const loanProducts = response[0].status == "fulfilled" ? response[0].value.data : [];
      const financialGroups = response[1].status == "fulfilled" ? response[1].value.data : [];
      const loanProductMaps = response[2].status == "fulfilled" ? response[2].value.data : [];
      const workFlowConfigs = response[3].status == "fulfilled" ? response[3].value.data : [];

      const mapLoanProduct: IMapLoanProduct[] = [];
      console.log(loanProductMaps)
      loanProductMaps.forEach(productMap => {
        const metric: any = {
          loanProductConfigId: productMap.loanProductConfigId,
          financialGroupId: productMap.financialGroupId,
          loanProductName: productMap.loanProductName,
          financialGroupName: productMap.financialGroupName,
          workflowName: productMap.workflowName

        };

        mapLoanProduct.push(metric);
        this.setState({ mapLoanProduct });
      });

      this.setState({
        loanProducts: loanProducts,
        financialGroups: financialGroups,
        workFlowConfigs: workFlowConfigs,
        fetchingRequiredItems: false
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        errors.response = "Internal server error.";
        this.setState({ errors });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onFormSubmit(onReloadFieldsCB: any): boolean {
    try {
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      let mapLoanProduct = this.state.mapLoanProduct;
      mapLoanProduct.map(x => x.institutionCode = decodedToken?.["InstitutionCode"])
      this.setState(
        { mapLoanProduct },
        () => {
          if (Object.keys(this.state.mapLoanProduct).length > 0) {
            return this.submit(onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true });
      const payload = [...this.state.mapLoanProduct];

      // console.log("Payload: ", payload)
      const response = await loanProductMapService.mapMultipleLoanProduct(payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Add Loan Product Map",
          description: `Added new Loan Product Map`,
        });
        toast.info(`Loan Product Map created successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false });
      this.resetFields();
      onReloadFieldsCB();
    } catch (error) {
      toast.error(error.response.data, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      this.setState({ submitting: false });
      return false;
    } finally {
      return true;
    }
  }

  processMetricIntoProperObject = (map: AddLoanProductMapFields): IMapLoanProduct => {
    return {
      loanProductConfigId: Number(map.loanProduct),
      financialGroupId: Number(map.workFlowConfig),
      loanProductName: this.state.loanProducts.filter((x) => x.id === Number(map.loanProduct))[0]?.name,
      financialGroupName: this.state.financialGroups.filter((x) => x.id === Number(map.workFlowConfig))[0]?.name,
      workflowId: Number(map.dataEntryConfig),
      workflowName: this.state.workFlowConfigs.filter((x) => x.id === Number(map.dataEntryConfig))[0]?.name,

    };
  }

  handleOnAddMetricToCollection = (map: AddLoanProductMapFields) => {
    this.setState(
      {
        errors:
          appraisalValidationService.validateAddLoanProductMapForm(map),
        validated: true
      },
      () => {
        if (Object.keys(this.state.errors).length === 0) {
          const metric = this.processMetricIntoProperObject(map);
          const mapLoanProduct = [...this.state.mapLoanProduct];
          console.log(map)
          console.log(mapLoanProduct)
          let checker: any = {}
          checker = mapLoanProduct.filter((x) => (
            (
              x.loanProductConfigId === metric.loanProductConfigId
              && x.financialGroupId === metric.financialGroupId
            )
          ));
          if (!_.isEmpty(checker)) {
            toast.info(`Mapping already exists with same Loan Product and Work Flow.`, {
              autoClose: 6000,
              type: toast.TYPE.ERROR,
              hideProgressBar: false,
            });
          } else {
            mapLoanProduct.push(metric);
          }
          this.setState({ mapLoanProduct });
        }
      }
    );
  };

  handleOnRemoveMetricFromCollection = (map: IMapLoanProduct) => {
    const mapLoanProduct = [...this.state.mapLoanProduct];
    if (mapLoanProduct.includes(map)) {
      mapLoanProduct.splice(
        mapLoanProduct.indexOf(map),
        1
      );
    }
    this.setState({ mapLoanProduct });
  };

  handleLoanProductChange = (value: string) => {
    // console.log(value)
    // console.log(this.state.loanProducts)
    var productName = this.state.loanProducts?.filter(x => x.id === Number(value))[0]?.name
    // console.log(productName)
    this.setState({ loanProduct: productName })
  }

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
    });
  }

  render() {
    const {
      errors,
      validated,
      loanProducts,
      financialGroups,
      workFlowConfigs,
      fetchingRequiredItems,
      mapLoanProduct,
    } = this.state;

    console.log(loanProducts)
    console.log(financialGroups)

    let loanProductInputData: ISelectItems[] = loanProducts.map((item) => ({
      name: item.name + " (" + item.minimumAmount + " - " + item.maximumAmount + " )",
      value: item.id,
    }));

    let financialGroupInputData: ISelectItems[] = financialGroups.map((item) => ({
      name: item.name + " (" + item.minimumAmount + " - " + item.maximumAmount + " )",
      value: item.id,
    }));

    let workFlowConfigInputData: ISelectItems[] = workFlowConfigs.map((item) => ({
      name: item.name,
      value: item.id,
    }));

    // console.log(mapLoanProduct)

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Map Loan Product</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddLoanProductMapForm
                  initialValues={{
                    loanProduct: "",
                    workFlowConfig: "",
                    dataEntryConfig: ""
                  }}
                  FormComponent={({
                    fields: {
                      loanProduct,
                      workFlowConfig,
                      dataEntryConfig
                    },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <h5 className="card-title">
                            <i className="fas fa-university mr-3" />
                            Loan Product Map Details
                          </h5>

                          <Fragment>
                            <div>
                              <Fragment>
                                <div className="form-row">
                                  <div className="col-md-12">
                                    <div className="form-row">
                                      <SelectInputComponent
                                        id="loanProduct"
                                        name="loanProduct"
                                        divClass={6}
                                        value={loanProduct}
                                        items={loanProductInputData}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={async (id, value) => {
                                          await onChange(id, value);
                                          await this.handleLoanProductChange(value)
                                        }}
                                        labelIconClass={fetchingRequiredItems === true
                                          ? "fas fa-spinner fa-spin orange-text mr-2"
                                          : ""
                                        }
                                      />
                                      <SelectInputComponent
                                        id="workFlowConfig"
                                        name="workFlowConfig"
                                        divClass={6}
                                        value={workFlowConfig}
                                        items={financialGroupInputData}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                        labelIconClass={fetchingRequiredItems === true
                                          ? "fas fa-spinner fa-spin orange-text mr-2"
                                          : ""
                                        }
                                      />

                                      <SelectInputComponent
                                        label="Data Entry Config"
                                        id="dataEntryConfig"
                                        name="dataEntryConfig"
                                        divClass={6}
                                        value={dataEntryConfig}
                                        items={workFlowConfigInputData}
                                        required={false}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                        labelIconClass={fetchingRequiredItems === true
                                          ? "fas fa-spinner fa-spin orange-text mr-2"
                                          : ""
                                        }
                                      />
                                      <div className="my-1 mx-2">
                                        <button
                                          className="btn btn-sm btn-outline-primary float-right my-4"
                                          style={{ cursor: "pointer" }}
                                          type="button"
                                          onClick={() =>
                                            this.handleOnAddMetricToCollection(
                                              { loanProduct, workFlowConfig, dataEntryConfig }
                                            )
                                          }
                                        >
                                          <React.Fragment>
                                            <i className="far fa-save fa-lg mr-2" />{" "}
                                            Save Map
                                          </React.Fragment>
                                        </button>
                                      </div>
                                    </div>

                                    <div className="form-row col-md-10">
                                      <div
                                        className="table-responsive text-nowrap"
                                        style={{
                                          maxHeight: "150px",
                                          overflowY: "scroll",
                                        }}
                                      >
                                        <table className="table table-hover table-content table-sm table-striped mb-0">
                                          <thead>
                                            <tr>
                                              <th
                                                scope="col"
                                                style={{
                                                  paddingTop: "0.2rem",
                                                  paddingBottom: "0.2rem",
                                                }}
                                              >
                                                <strong>#</strong>
                                              </th>
                                              <Fragment>
                                                <th
                                                  scope="col"
                                                  style={{
                                                    paddingTop: "0.2rem",
                                                    paddingBottom: "0.2rem",
                                                  }}
                                                >
                                                  <strong>Loan Product</strong>
                                                </th>
                                                <th
                                                  scope="col"
                                                  style={{
                                                    paddingTop: "0.2rem",
                                                    paddingBottom: "0.2rem",
                                                  }}
                                                >
                                                  <strong>Work Flow</strong>
                                                </th>
                                                <th
                                                  scope="col"
                                                  style={{
                                                    paddingTop: "0.2rem",
                                                    paddingBottom: "0.2rem",
                                                  }}
                                                >
                                                  <strong>Data Entry</strong>
                                                </th>
                                              </Fragment>
                                              <th
                                                scope="col"
                                                style={{
                                                  paddingTop: "0.2rem",
                                                  paddingBottom: "0.2rem",
                                                }}
                                              ></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <React.Fragment>
                                              {mapLoanProduct.filter(x => x.loanProductName === this.state.loanProduct).map((item) => (
                                                <tr key={mapLoanProduct.indexOf(item)}>
                                                  <td
                                                    style={{
                                                      paddingTop: "0.2rem",
                                                      paddingBottom: "0.2rem",
                                                      fontSize: "small",
                                                    }}
                                                  >
                                                    {mapLoanProduct.indexOf(item) + 1}
                                                  </td>
                                                  <Fragment>
                                                    <td
                                                      style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                        fontSize: "small",
                                                      }}
                                                    >
                                                      {item.loanProductName}
                                                    </td>
                                                    <td
                                                      style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                        fontSize: "small",
                                                      }}
                                                    >
                                                      {item.financialGroupName + " (" + financialGroups?.filter(x => x.name === item.financialGroupName)[0]?.minimumAmount + " - " + financialGroups?.filter(x => x.name === item.financialGroupName)[0]?.maximumAmount + ")"}
                                                    </td>
                                                    <td
                                                      style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                        fontSize: "small",
                                                      }}
                                                    >
                                                      {item.workflowName}
                                                    </td>
                                                  </Fragment>
                                                  <td
                                                    style={{
                                                      paddingTop: "0.2rem",
                                                      paddingBottom: "0.2rem",
                                                      fontSize: "small",
                                                    }}
                                                  >
                                                    <i
                                                      className="far fa-trash-alt ml-5 red-text fa-lg"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.handleOnRemoveMetricFromCollection(
                                                          item
                                                        )
                                                      }
                                                    ></i>
                                                  </td>
                                                </tr>
                                              ))}
                                            </React.Fragment>
                                          </tbody>
                                        </table>
                                        <div
                                          style={{
                                            visibility: errors.ScoreIndicatorSteps,
                                            fontSize: "80%",
                                            color: "#dc3545",
                                          }}
                                        >
                                          {errors.ScoreIndicatorSteps}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Fragment>
                            </div>
                          </Fragment>

                          <hr />
                          <div className="card-body">
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                              disabled={this.state.submitting}
                              onClick={(e) => {
                                onHandleSubmit(
                                  e,
                                  ActionTypes.SAVE,
                                  "Loan Product Map",
                                  () => {
                                    this.onFormSubmit(
                                      onReloadFields
                                    );
                                  }
                                );
                              }}
                            >
                              {this.state.submitting === false ? (
                                <React.Fragment>
                                  <i className="fas fa-lg fa-save mr-3" /> Save
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                  Please wait...
                                </React.Fragment>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                ></AddLoanProductMapForm>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddLoanProductMap;
