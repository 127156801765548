import * as React from "react";
import institutionsService from "../../services/auth/institutionsService";
import { ISelectItems } from "../../components/inputComponents/selectBox";
import _ from "lodash";
import SelectInputComponent from "../../components/formSelectComponent";
import { toast } from "react-toastify";
import { IFilterData } from "../../services/approval/approvalItemService";
import ItemsTable, {
  filterFieldData,
} from "../../components/tableComponents/itemsTable";
import BaseListComponent, {
  BaseListComponentState,
} from "../../components/BaseListComponent";
import {
  createFilterData,
  mapEntityList,
} from "../../services/utility/autoMapperService";
import remoteSessionService from "../../services/auth/remoteSessionService";
import TableFilterPane from "../../components/tableComponents/tableFilterPane";
import RemoteSessionDetail from "./remoteSessionDetail";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../services/auth/authService";
import usersService from "../../services/auth/usersService";
import swal from "sweetalert";

// interface RemoteInstitutionFields {
//   institutionId: number;
// }

// class RemoteInstitutionForm extends BaseFormComponent<
//   RemoteInstitutionFields
// > {}

export interface IRemoteViewProps {}

export interface IRemoteViewState extends BaseListComponentState {
  institution: any;
  category: string;
  institutions: any;
  fetchingInstitutions: boolean;
  submitting: boolean;

  //list state items
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
}

class RemoteSessionsList extends BaseListComponent<IRemoteViewState> {}

class RemoteView extends React.Component<IRemoteViewProps, IRemoteViewState> {
  constructor(props: IRemoteViewProps) {
    super(props);
    this.state = {
      institution: {},
      institutions: [],
      submitting: false,
      category: "",
      fetchingInstitutions: false,

      //list items
      data: createFilterData([
        "username",
        "sessionStartTime",
        "sessionEndTime",
      ]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [
        { name: "username", fieldType: "text", value: "" },
        { name: "sessionStartTime", fieldType: "date", value: "" },
        { name: "sessionEndTime", fieldType: "date", value: "" },
      ],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
    };
  }

  removeSessionEndTime(headers, currentData) {
    let filterData = {};
    headers.forEach((header) => {
      filterData[header] = currentData[header];
    });
    return filterData;
  }

  handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true });
    data = typeof data === "undefined" ? this.state.data : data;
    try {
      let dataClone = { ...data };
      dataClone.institutionId = {
        item1: "=",
        item2: this.state.institution.id,
      };
      if (dataClone.sessionStartTime.item2 !== "") {
        // "'2021-08-13' and '2021-08-13'" extract end
        let filterString = dataClone.sessionStartTime.item2.substring(0, 18);
        let endDate = new Date(
          dataClone.sessionStartTime.item2.substring(18, 28)
        );
        dataClone.sessionStartTime = {
          item1: "between",
          item2: filterString + this.incrementDateForFilter(endDate),
        };
        // dataClone.sessionStartTime.item2 = filterString+this.incrementDateForFilter(endDate);
      }
      if (dataClone.sessionEndTime.item2 !== "") {
        let filterString = dataClone.sessionEndTime.item2.substring(0, 18);
        let endDate = new Date(
          dataClone.sessionEndTime.item2.substring(18, 28)
        );
        dataClone.sessionEndTime = {
          item1: "between",
          item2: filterString + this.incrementDateForFilter(endDate),
        };
        // dataClone.sessionEndTime.item2 = filterString+this.incrementDateForFilter(endDate);
      } else {
        dataClone = this.removeSessionEndTime(
          ["username", "sessionStartTime", "institutionId"],
          dataClone
        );
      }
      // console.log(dataClone)
      // console.log(this.state.institution);
      const sessions = await remoteSessionService.filterRemoteSession(
        dataClone,
        pageSize,
        currentPage
      );
      // console.log(sessions.data);
      this.setState({
        tableItems: mapEntityList({
          entityList: sessions.data.item2,
          properties: [
            { oldName: "username", newName: "username" },
            { oldName: "sessionStartTime", newName: "sessionStartTime" },
            { oldName: "sessionEndTime", newName: "sessionEndTime" },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: sessions.data.item1,
        fetching: false,
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      // console.log("caught exception", ex.response);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
        this.setState({ fetching: false });
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        this.setState({ fetching: false });
      }
    } finally {
      this.setState({ fetching: false });
    }
  };

  incrementDateForFilter = (currentDate: Date): string => {
    currentDate.setDate(currentDate.getDate() + 1);
    let dd = currentDate.getDate();
    let mm = currentDate.getMonth() + 1; //As January is 0.
    let yyyy = currentDate.getFullYear();
    let response: string = `${yyyy}-`;
    response += mm < 10 ? `0${mm}-` : `${mm}-`;
    response += dd < 10 ? `0${dd}` : `${dd}'`;
    return response;
  };

  handleOnExport = async (type: String) => {};

  fetchItemById = async (item: any) => {
    this.setState({ fetchingDetail: true });
    const session = await remoteSessionService.retrieveById(item.id);
    this.setState({ item: session.data, fetchingDetail: false });
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  handleOnSelectInstitution = (id, value) => {
    try {
      const institution = this.state.institutions.filter(
        (x) => x.id === parseInt(value)
      )[0];
      this.setState({ institution: institution }, () => {
        this.handlePageChange(this.state.currentPage, this.state.pageSize);
      });
    } catch (error) {
      toast.info("Something went wrong.", {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      toast.info(error.response.data.errors[0], {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
    }
  };

  handleOnSelectCategory = async (id, value) => {
    try {
      this.setState({
        category: value,
        fetchingInstitutions: true,
        institution: {},
      });
      let inst = await institutionsService.retrieveAllByCategory(value);
      this.setState({ institutions: inst.data, fetchingInstitutions: false });
    } catch (error) {
      toast.info("Something went wrong.", {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      toast.info(error.response.data.errors[0], {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
    }
  };

  handleOnClickRemote = async (e) => {
    try {
      e.preventDefault();
      this.setState({ submitting: true });

      swal({
        title: "Confirm!",
        text: `Are you sure you want to proceed to Remote into this Institution?`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode: true,
      }).then(async (resp) => {
        if (resp) {
          // console.log("cancelling action...");
          // this.setState({ updatingStatus: false });
          this.setState({ submitting: false });
        } else {
          //push to save new session
          const currentSession = getCurrentUserSession();
          const decodedToken = decodeToken(currentSession.token || "");
          // console.log("decoded token", decodedToken)
          const user = await usersService.retrieveUserById(
            decodedToken?.["UserId"]
          );
          // dateCreated: "06-Aug-2021 12:23:07 PM"
          // expirationDate: "06-Aug-2021 5:23:07 PM"
          // id: 0
          // institutionCode: "000"
          // institutionId: 1
          // sessionId: "4gqv9vnyigha97mi4sb21ta40l5xo0lu2u7rirxe"
          // status: "Active"
          // token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiIxMSIsIlVzZXJOYW1lIjoibW9iZXkiLCJJbnN0aXR1dGlvbklkIjoiMSIsIkluc3RpdHV0aW9uQ29kZSI6IjAwMCIsIlVzZXJSb2xlSWQiOiIxIiwianRpIjoiMDkwZDRmYjMtYjY5Yi00NWRkLWI4ODEtMmQ2NTM3OTdmYWIwIiwiZXhwIjoxNjI4MjU2MTg3LCJpc3MiOiJzdGFnaW5nLnJlY292YS5uZyIsImF1ZCI6InN0YWdpbmcucmVjb3ZhLm5nIn0.VfrARHmaepypQB1SJB8c34Y8HPolGcmtEnnzSbqOYEQ"
          // userId: 11
          // userRoleId: 1
          let payload: any = {};
          payload.userId = decodedToken?.["UserId"];
          payload.username = user.data.username;
          payload.institutionId = this.state.institution.id;
          payload.sessionStartTime = new Date();

          // console.log("payload for session",payload)
          let remoteSession = await remoteSessionService.saveRemoteSession(
            payload
          );
          // console.log("remote token", decodeToken(remoteSession.data.remoteToken || ""))
          //extract remoteToken, and set into local storage. Also backup previous token
          //do the same for userrole functions
          let newSession = currentSession;
          newSession.dateCreated = remoteSession.data.dateCreated;
          newSession.id = remoteSession.data.id;
          newSession.sessionId = remoteSession.data.sessionId;
          newSession.token = remoteSession.data.remoteToken;
          // newSession.sessionStartTime = remoteSession.data.sessionStartTime;

          localStorage.setItem(
            "remoteUserSession",
            localStorage.getItem("userSession") || ""
          );
          localStorage.setItem(
            "remoteUserRole",
            localStorage.getItem("userRoles") || ""
          );
          localStorage.setItem("userSession", JSON.stringify(newSession));
          localStorage.setItem("userRoles", `["remotesession"]`);
          localStorage.setItem(
            "sessionStartTime",
            remoteSession.data.sessionStartTime
          );
          // {
          //   "userId": 0,
          //   "username": "string",
          //   "institutionId": 0,
          //   "sessionStartTime": "2021-08-06T15:02:36.629Z",
          //   "sessionEndTime": "2021-08-06T15:02:36.629Z",
          //   "remoteToken": "string",
          //   "sessionId": "string",
          //   "id": 0,
          //   "status": "Inactive",
          //   "dateCreated": "2021-08-06T15:02:36.629Z",
          //   "clientIPAddress": "string"
          // }

          this.setState({ submitting: false });

          window.location.replace("/");
        }
      });
    } catch (error) {
      toast.info("Something went wrong.", {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      toast.info(error.response.data.errors[0], {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
    }
  };

  render() {
    const {
      institutions,
      institution,
      submitting,
      fetchingInstitutions,
      category,
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      item,
      fetching,
      fetchingDetail,
    } = this.state;
    let institutionsInputData: ISelectItems[] = institutions.map((item) => ({
      name: item.name,
      value: item.id,
    }));
    // order by institution name
    institutionsInputData.sort((a, b) => a.name.localeCompare(b.name))
    let categoryInputData: ISelectItems[] = [
      "CommercialBank",
      "BankOneOFI",
      "OFI",
    ].map((item) => ({
      name: item,
      value: item,
    }));

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Remote Access</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <form action="#">
                  <div className="form-row">
                    <SelectInputComponent
                      id="category"
                      name="category"
                      divClass={4}
                      value={category}
                      items={categoryInputData}
                      required={false}
                      validated={false}
                      errors={[]}
                      onChange={this.handleOnSelectCategory}
                    />
                    {category == "" ? (
                      ""
                    ) : (
                      <SelectInputComponent
                        id="institution"
                        name="institution"
                        divClass={4}
                        value={institution.id}
                        items={institutionsInputData}
                        required={false}
                        validated={false}
                        errors={[]}
                        labelIconClass={fetchingInstitutions === true
                          ? "fas fa-spinner fa-spin orange-text mr-2"
                          : ""
                        }
                        onChange={this.handleOnSelectInstitution}
                      />
                    )}
                    <div className="col-md-4">
                      {_.isEmpty(institution) ? (
                        ""
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-sm btn-secondary float-right"
                          style={{ marginTop: "29px" }}
                          disabled={submitting}
                          onClick={(e) => {
                            this.handleOnClickRemote(e);
                          }}
                        >
                          {this.state.submitting === false ? (
                            <React.Fragment>
                              <i className="icon-desktop2" /> Remote
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                              Please wait...
                            </React.Fragment>
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                  <br />
                  {_.isEmpty(institution) ? (
                    ""
                  ) : (
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="row my-3 mx-2">
                          <h5>
                            Remote Sessions -{" "}
                            <span className="font-weight-bold">
                              {institution.name}
                            </span>
                          </h5>
                        </div>
                        <RemoteSessionsList
                          pageChangeHandler={this.handlePageChange}
                          fetchingDetail={fetchingDetail}
                          initialValues={{
                            data: createFilterData([
                              "username",
                              "sessionStartTime",
                              "sessionEndTime",
                            ]) as IFilterData,
                            fetching: false,
                            showDetailsModal: false,
                            showEditModal: false,
                            showTableFilterModal: false,
                            showTrailModal: false,
                            tableItems: [],
                            filterValueInput: [
                              {
                                name: "username",
                                fieldType: "text",
                                value: "",
                              },
                              {
                                name: "sessionStartTime",
                                fieldType: "date",
                                value: "",
                              },
                              {
                                name: "sessionEndTime",
                                fieldType: "date",
                                value: "",
                              },
                            ],
                            item: {},
                          }}
                          tableItems={tableItems}
                          item={item}
                          DetailsModal={({
                            item,
                            onToggleDetailsModal,
                            onToggleEditModal,
                            showDetailsModal,
                          }) => (
                            <React.Fragment>
                              <RemoteSessionDetail
                                showDetailsModal={showDetailsModal}
                                toggleDetailsModal={onToggleDetailsModal}
                                toggleEditModal={onToggleEditModal}
                                showTrail={() => {}}
                                currentUserRoles={() => {}}
                                item={item}
                                institution={institution}
                              />
                            </React.Fragment>
                          )}
                          ListPayLoad={({
                            data,
                            showTableFilterModal,
                            tableItems,
                            onToggleDetailsModal,
                            onToggleTableFilterModal,
                            onHandleFilterItemRemove,
                            filterValueInput,
                          }) => (
                            <div className="row">
                              <div className="col-md-12">
                                <div className="card no-b">
                                  <div className="card-body">
                                    <div className="card-title">
                                      <div
                                        id="list-filters"
                                        className="ml-4 mr-4"
                                      >
                                        <TableFilterPane
                                          filteredItems={this.getFilteredItems(
                                            data
                                          )}
                                          handleFilterRemove={
                                            onHandleFilterItemRemove
                                          }
                                        />
                                      </div>
                                      <div id="list-table">
                                        <ItemsTable
                                          data={data}
                                          items={tableItems}
                                          filterValueInput={filterValueInput}
                                          showTableFiltermodal={
                                            showTableFilterModal
                                          }
                                          toggleTableFiltermodal={
                                            onToggleTableFilterModal
                                          }
                                          name="Remote Sessions"
                                          currentPage={currentPage}
                                          pageSize={pageSize}
                                          totalSize={totalSize}
                                          fetching={fetching}
                                          handlePageChange={
                                            this.handlePageChange
                                          }
                                          onViewDetails={(item: any) => {
                                            this.fetchItemById(item);
                                            onToggleDetailsModal();
                                          }}
                                          handleOnExport={this.handleOnExport}
                                          timeColumn={[
                                            "sessionStartTime",
                                            "sessionEndTime",
                                          ]}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        ></RemoteSessionsList>
                      </div>
                      {/* <p>The main content lies here</p> */}
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RemoteView;
