import React, { Fragment } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import BaseFormComponent, { IBaseFormState } from "../../../components/BaseFormComponent";
import SelectInputComponent, { ISelectItems } from "../../../components/formSelectComponent";
import FormInputComponent from "../../../components/formInputComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import institutionsService from "../../../services/auth/institutionsService";
import collectionValidationService from "../../../services/collection/collectionValidationService";
import collectionInstitutionService from "../../../services/collection/collectionInstitutionService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

interface AddCollectionsInstitutionFields {
  emailAddress: string;
}

class AddCollectionsInstitutionForm extends BaseFormComponent<AddCollectionsInstitutionFields> { }

interface IAddCollectionsInstitutionProps { }
interface IAddCollectionsInstitutionState {
  institution: any;
  category: string;
  institutions: any;
  fetchingInstitutions: boolean;
  updatingStatus: boolean;
}

class AddCollectionsInstitution extends React.Component<
  IAddCollectionsInstitutionProps,
  IBaseFormState & IAddCollectionsInstitutionState
> {
  constructor(props: IAddCollectionsInstitutionProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      institution: {},
      institutions: [],
      category: "",
      fetchingInstitutions: false,
      updatingStatus: false,
    };
  }

  onFormSubmit(fields: AddCollectionsInstitutionFields, onReloadFieldsCB: any): boolean {
    try {
      // console.log("Fields for submitting:: ", fields)
      this.setState(
        {
          errors: collectionValidationService.validateAddCollectionsInstitutionForm(fields),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddCollectionsInstitutionFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    let response: any;
    try {
      this.setState({ submitting: true });
      const payload: any = {};

      const { institution } = this.state

      payload.institutionCode = institution.code;
      payload.institutionName = institution.name;
      payload.externalInstitutionId = institution.id?.toString();
      payload.externalInstitutionCode = institution.category == "OFI" ? institution.id?.toString() : institution.externalInstitutionCode;
      payload.institutionCategory = institution.category;
      payload.emailAddress = fields.emailAddress;

      // console.log(payload)
      response = await collectionInstitutionService.createCollectionsInstitution(payload);
      // console.log("testing log:: ", response);

      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Create Institution Collection",
          description: `Create new Collection for ${institution.name}`,
        });
        toast.info(`Collections Institution ${payload.institutionName} created successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });

        this.setState({ submitting: false });
      } else {
        this.setState({ submitting: false });
        return false;
      }
    } catch (error) {
      if (error.response.status == 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status == 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // console.log("error: ", error.response);
        // return false;
      }
      // console.log("error: ", error.response);
      this.setState({ submitting: false });
      return false;
    } finally {
      return true;
    }
  }

  handleOnSelectCategory = async (id, value) => {
    try {
      this.setState({
        category: value,
        fetchingInstitutions: true,
        institution: {},
      });
      let inst = await institutionsService.retrieveAllByCategory(value);
      this.setState({ institutions: inst.data, fetchingInstitutions: false });
    } catch (error) {
      toast.info("Something went wrong.", {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      toast.info(error.response.data.errors[0], {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      this.setState({
        category: "",
        fetchingInstitutions: false,
      });
    }
  };

  handleOnSelectInstitution = async (id, value) => {
    const institution = this.state.institutions.filter(
      (x) => x.id === parseInt(value)
    )[0];
    this.setState({ institution: institution });
  };

  resetFields() {
    this.setState({ validated: false, submitting: false });
  }

  render() {
    const { errors, validated, category, fetchingInstitutions, institution, institutions } = this.state;

    let institutionsInputData: ISelectItems[] = institutions.map((item) => ({
      name: item.name,
      value: item.id,
    }));
    // order by institution name
    institutionsInputData.sort((a, b) => a.name.localeCompare(b.name))

    let categoryInputData: ISelectItems[] = [
      "CommercialBank",
      "BankOneOFI",
      "OFI",
    ].map((item) => ({
      name: item,
      value: item,
    }));

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add Institution</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddCollectionsInstitutionForm
                  initialValues={{ emailAddress: "" }}
                  FormComponent={({
                    fields: { emailAddress },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <h5 className="card-title">
                            <i className="fas fa-university mr-3" />
                            Institution Details
                          </h5>
                          <div className="form-row">
                            <SelectInputComponent
                              id="category"
                              name="institutionCategory"
                              divClass={6}
                              value={category}
                              items={categoryInputData}
                              required={true}
                              validated={false}
                              errors={[]}
                              onChange={this.handleOnSelectCategory}
                            />
                            {category == "" ? (
                              ""
                            ) : (
                              <Fragment>
                                <SelectInputComponent
                                  id="institution"
                                  name="institution"
                                  divClass={6}
                                  value={institution.id}
                                  items={institutionsInputData}
                                  required={true}
                                  validated={false}
                                  errors={[]}
                                  labelIconClass={fetchingInstitutions === true
                                    ? "fas fa-spinner fa-spin orange-text mr-2"
                                    : ""
                                  }
                                  onChange={this.handleOnSelectInstitution}
                                />
                                {_.isEmpty(institution) ? (
                                  ""
                                ) : (
                                  <Fragment>
                                    <FormInputComponent
                                      id="emailAddress"
                                      type="email"
                                      name="emailAddress"
                                      placeholder=""
                                      divClass={6}
                                      value={emailAddress}
                                      required={true}
                                      validated={validated}
                                      errors={errors}
                                      onChange={onChange}
                                    />
                                  </Fragment>
                                )}
                              </Fragment>
                            )}
                          </div>
                        </div>
                        <hr />
                        <div className="card-body">
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                            disabled={this.state.submitting}
                            onClick={(e) => {
                              onHandleSubmit(
                                e,
                                ActionTypes.SAVE,
                                "Institution",
                                () => {
                                  this.onFormSubmit(
                                    { emailAddress },
                                    onReloadFields
                                  );
                                }
                              );
                            }}
                          >
                            {this.state.submitting === false ? (
                              <React.Fragment>
                                <i className="fas fa-lg fa-save mr-3" /> Save
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                Please wait...
                              </React.Fragment>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                ></AddCollectionsInstitutionForm>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddCollectionsInstitution;
