import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import textFormatService from "../../../services/utility/textFormatService";

export interface CustomerBvnReportDetalProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  item: any;
}

export interface CustomerBvnReportDetalState {}

class CustomerBvnReportDetal extends React.Component<
  CustomerBvnReportDetalProps,
  CustomerBvnReportDetalState
> {
  //   constructor(props: CustomerBvnReportDetalProps) {
  //     super(props);
  //     this.state = { :  };
  //   }
  render() {
    const { showDetailsModal, toggleDetailsModal, item } = this.props;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="fas fa-receipt fa-sm s-18 mr-3" />
                Customer BVN Report Details
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={6} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Customer Name"
                        labelSize={6}
                        valueSize={6}
                        value={
                          !_.isEmpty(item.customerName)
                            ? item.customerName
                            : "Unknown"
                        }
                      />
                      <DetailItem
                        label="Phone Number"
                        labelSize={6}
                        valueSize={6}
                        value={item.phoneNumber}
                      />
                      <DetailItem
                        label="Total Mandates"
                        labelSize={6}
                        valueSize={6}
                        value={item.totalMandates}
                      />
                      <DetailItem
                        label="Total Accounts Linked"
                        labelSize={6}
                        valueSize={6}
                        value={item.totalAccountsLinked}
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>
            <React.Fragment>
              <div
                className="card-header card-footer"
                style={{
                  paddingTop: "0.75rem",
                  paddingBottom: "2.0rem",
                  height: "37px",
                }}
              >
                <h5>
                  <i className="fa fa-list-alt fa-sm s-18 mr-3" />
                  Linked Accounts
                </h5>
              </div>
              <div className="card-body">
                <div
                  className="card-content table-responsive text-nowrap"
                  style={{ height: "150px", overflowY: "scroll" }}
                >
                  <table className="table table-hover table-content table-sm table-bordered table-striped mb-0">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          style={{
                            width: "5px",
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>#</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Institution Name</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Bank Code</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Account Number</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>First Time Linked</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Last Time Linked</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.isEmpty(item.bvnAccounts) ? (
                        <React.Fragment>
                          <tr>
                            <td
                              className="font-weight-normal font-italic text-truncate"
                              colSpan={6}
                              style={{
                                textAlign: "center",
                                color: "#FE825E",
                              }}
                            >
                              <React.Fragment>
                                <i className="fas fa-spin fa-circle-notch"></i>
                              </React.Fragment>
                            </td>
                          </tr>
                        </React.Fragment>
                      ) : item.bvnAccounts.length === 0 ? (
                        <tr>
                          <td
                            className="font-weight-normal font-italic text-truncate"
                            colSpan={6}
                            style={{
                              textAlign: "center",
                              color: "#FE825E",
                            }}
                          >
                            ...No Linked Institutions for this Mandate.
                          </td>
                        </tr>
                      ) : (
                        item.bvnAccounts.map((_item) => (
                          <React.Fragment
                            key={item.bvnAccounts.indexOf(_item) + 1}
                          >
                            <tr>
                              <td
                                style={{
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                  fontSize: "small",
                                }}
                              >
                                {item.bvnAccounts.indexOf(_item) + 1}
                              </td>
                              <td
                                style={{
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                  fontSize: "small",
                                }}
                              >
                                {_item.institutionName}
                              </td>
                              <td
                                style={{
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                  fontSize: "small",
                                }}
                              >
                                {_item.institutionCode}
                              </td>
                              <td
                                style={{
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                  fontSize: "small",
                                }}
                              >
                                {_item.accountNumber}
                              </td>
                              <td
                                style={{
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                  fontSize: "small",
                                }}
                              >
                                {textFormatService.formatTimeString(
                                  _item.dateCreated
                                )}
                              </td>
                              <td
                                style={{
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                  fontSize: "small",
                                }}
                              >
                                {_.isEmpty(_item.dateLinked)
                                  ? ""
                                  : textFormatService.formatTimeString(
                                      _item.dateLinked
                                    )}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </React.Fragment>
            {!_.isEmpty(item.greyList) && (
              <React.Fragment>
                <div
                  className="card-header card-footer"
                  style={{
                    paddingTop: "0.75rem",
                    paddingBottom: "2.0rem",
                    height: "37px",
                  }}
                >
                  <h5>
                    <i className="fa fa-list-alt fa-sm s-18 mr-3" />
                    Grey List
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      {_.isEmpty(item.greyList) ? (
                        <Skeleton count={3} width={300} />
                      ) : (
                        <dl className="row">
                          <DetailItem
                            label="BVN"
                            value={item.greyList.bvn}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Customer Name"
                            value={item.greyList.customerName}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Customer Phone Number"
                            value={item.greyList.customerPhoneNumber}
                            labelSize={6}
                            valueSize={6}
                          />
                        </dl>
                      )}
                    </div>
                    <div className="col-md-6">
                      {_.isEmpty(item.greyList) ? (
                        <Skeleton count={3} width={300} />
                      ) : (
                        <dl className="row">
                          <DetailItem
                            label="Institution"
                            value={item.greyList.institutionName}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Date GreyListed"
                            value={item.greyList.dateCreated}
                            labelSize={6}
                            valueSize={6}
                          />
                        </dl>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="card-header card-footer"
                  style={{ padding: "0px", height: "35px", paddingTop: "5px" }}
                >
                  <i className="fas fa-list-alt mr-3 ml-3" /> Other Grey Listing
                  Institutions
                </div>
                <div className="card-body">
                  <div
                    className="card-content table-responsive text-nowrap"
                    style={{ height: "250px", overflowY: "scroll" }}
                  >
                    <table className="table table-hover table-content table-sm table-bordered table-striped mb-0">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            style={{
                              width: "5px",
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                            }}
                          >
                            <strong>#</strong>
                          </th>
                          <th
                            scope="col"
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                            }}
                          >
                            <strong>Institution Name</strong>
                          </th>
                          <th
                            scope="col"
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                            }}
                          >
                            <strong>Institution Type</strong>
                          </th>
                          <th
                            scope="col"
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                            }}
                          >
                            <strong>Date GreyListed</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.isEmpty(item.greyList) ? (
                          <tr>
                            <td
                              className="font-weight-normal font-italic text-truncate"
                              colSpan={4}
                              style={{ textAlign: "center", color: "#FE825E" }}
                            >
                              ...fetching institutions. Please Wait!
                            </td>
                          </tr>
                        ) : (
                          item.greyList.otherGreyListingInstitutions.map(
                            (entity) => (
                              <tr key={entity.institutionName}>
                                <td
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                    fontSize: "small",
                                  }}
                                >
                                  {item.greyList.otherGreyListingInstitutions.indexOf(
                                    entity
                                  ) + 1}
                                </td>
                                <td
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                    fontSize: "small",
                                  }}
                                >
                                  {entity.institutionName}
                                </td>
                                <td
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                    fontSize: "small",
                                  }}
                                >
                                  {entity.institutionType}
                                </td>
                                <td
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                    fontSize: "small",
                                  }}
                                >
                                  {entity.dateGreyListed}
                                </td>
                              </tr>
                            )
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default CustomerBvnReportDetal;
