import React from "react";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import BaseFormComponent, { IBaseFormState } from "../../../components/BaseFormComponent";
import scoringIndicatorService from "../../../services/creditAssessment/scoringIndicatorService";
import swal from "sweetalert";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

interface ModifyScoreIndicatorsFields {
  //   interestRateMarkup:  string
}

class ModifyScoreIndicatorsForm extends BaseFormComponent<ModifyScoreIndicatorsFields> { }

interface IModifyScoreIndicatorsProps {
  item: any;
  showPatchModal: boolean;
  togglePatchModal: any;
}
interface IModifyScoreIndicatorsState {
  ownUpdate: boolean;
  rePopulateCheckList: boolean;
  scoreIndicatorIds: any;
  scoreIndicators: any;
  fetchingRequiredItems: boolean;
}

class ModifyScoreIndicators extends React.Component<
  IModifyScoreIndicatorsProps,
  IBaseFormState & IModifyScoreIndicatorsState
> {
  _isMounted = false;
  constructor(props: IModifyScoreIndicatorsProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      ownUpdate: false,
      fetchingRequiredItems: true,
      rePopulateCheckList: false,
      scoreIndicatorIds: [],
      scoreIndicators: [],
      errors: {}
    };
  }

  static getDerivedStateFromProps(
    nextProps: IModifyScoreIndicatorsProps,
    prevState
  ) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isEmpty(nextProps.item)) {
      return null;
    }
    return {
      rePopulateCheckList: true,
      scoreIndicatorIds: nextProps.item.scoreIndicators ? nextProps.item.scoreIndicators?.map(
        (item) => item.scoreIndicatorId
      ) : []
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { errors } = this.state;
    try {
      const scoreIndicators = await scoringIndicatorService.retrieveAllScoreIndicators();

      if (this._isMounted) {
        this.setState({
          scoreIndicators: scoreIndicators.data,
          fetchingRequiredItems: false,
          ownUpdate: true
        });
      }
    } catch (error) {
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // return false;
      }
    }
  }

  // componentDidUpdate(
  //   prevProps: IModifyScoreIndicatorsProps,
  //   prevState: IModifyScoreIndicatorsState
  // ) {
  //   if (this.state.rePopulateCheckList) {
  //     let scoreIndicatorIds: any = [];
  //     if (this.state.scoreIndicatorIds) {
  //       scoreIndicatorIds = [...this.state.scoreIndicatorIds];
  //     }

  //     scoreIndicatorIds = scoreIndicatorIds.map(
  //       (item) =>
  //         this.props.item.scoreIndicators?.filter(
  //           (x) => x.creditQualificationIndicatorId === item
  //         )[0].creditQualificationIndicatorId
  //     );

  //     this.setState({
  //       rePopulateCheckList: false,
  //       ownUpdate: true,
  //       scoreIndicatorIds,
  //     });
  //     // this.loadCreditQualRule("loanProductID", this.props.item.loanProductID);
  //   }
  // }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onHandleSubmit(e): boolean {
    e.preventDefault();
    try {
      swal({
        title: "Confirm!",
        text: `Are you sure you want to proceed to Update these indicators for this Institution?`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode: true,
      }).then(async (resp) => {
        if (resp) {
          // this.setState({ updatingStatus: false });
          this.setState({ submitting: false, ownUpdate: true });
        } else {
          //push to assign
          return this.submit();
        }
      });
      this.setState({ validated: true, ownUpdate: true });
      return false;
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // return false;
      }
      return false;
    }
  }

  submit = async () => {
    try {
      this.setState({ submitting: true, ownUpdate: true });

      //run add and remove
      const { item } = this.props;
      let existingIndicators: any = []
      existingIndicators = item.scoreIndicators ? item.scoreIndicators : [];

      const removedIndicatorIds: any = [];
      const maintainedIndicatorIds: any = [];

      for (var i = 0; i < existingIndicators.length; i++) {
        if (this.state.scoreIndicatorIds.some(indicatorId => indicatorId == existingIndicators[i].scoreIndicatorId)) {
          //add to edited
          // console.log("edited", existingIndicators[i].scoreIndicatorId)
          maintainedIndicatorIds.push(existingIndicators[i].scoreIndicatorId)
        } else {
          //add to removed
          // console.log("removed", existingIndicators[i].scoreIndicatorId)
          removedIndicatorIds.push(existingIndicators[i].scoreIndicatorId)
        }
      }
      //find new ids
      var newRules = this.state.scoreIndicatorIds.filter((scoreIndicatorId) => !maintainedIndicatorIds.some(maintained => maintained === scoreIndicatorId));

      const removePayload: any = {};
      removePayload.scoreIndicatorIds = removedIndicatorIds;

      const newPayload: any = {};
      newPayload.scoreIndicatorIds = newRules;
      // console.log("new :: ", newPayload)
      if (removedIndicatorIds.length > 0) {
        // console.log("removed :: ", removePayload)
        const removeResponse = await scoringIndicatorService.removeScoreIndicatorsFromInstitution(this.props.item.institutionCode, removePayload);
      }
      const addResponse = await scoringIndicatorService.addScoreIndicatorsToInstitution(this.props.item.institutionCode, newPayload);
      if (addResponse.status >= 200 && addResponse.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Modify Score Indicators",
          description: `Modified Score Indicators for ${this.props.item.name}`,
        });
        toast.info(
          `Score Indicators modified successfully! `,
          {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          }
        );
      }
      // onReloadFieldsCB();
      this.setState({ submitting: false, ownUpdate: true }, () =>
        this.props.togglePatchModal(true, this.props.item)
      );
    } catch (error) {
      //   console.log("submission error: ", error);
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false, ownUpdate: true });
    }
  };

  handleOnChangeScoreIndicator = (item) => {
    // console.log("clicked Indicator: ", item);
    const scoreIndicatorIds = [...this.state.scoreIndicatorIds];
    // console.log("Indicatorss: ", scoreIndicatorIds);

    if (scoreIndicatorIds.indexOf(item) !== -1) {
      // console.log("1111 ");
      scoreIndicatorIds.splice(scoreIndicatorIds.indexOf(item), 1);
    } else {
      // console.log("22222 ");
      scoreIndicatorIds.push(item);
    }

    this.setState({ scoreIndicatorIds, ownUpdate: true });
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      ownUpdate: true
    });
  }

  render() {
    const { item, showPatchModal, togglePatchModal } = this.props;
    const { validated, submitting, errors, scoreIndicators, scoreIndicatorIds } = this.state;

    if (_.isEmpty(item)) {
      return null;
    }
    // console.log(scoreIndicators)

    return (
      <Modal
        size="lg"
        backdrop="static"
        show={showPatchModal}
        onHide={togglePatchModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-edit fa-sm cyan-text mr-3" />
              Edit Assigned Score Indicators
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {_.isEmpty(item) ? (
            ""
          ) : (
            <div className="form-row">
              <div className="col-md-12">
                <div className="form-row">
                  <div className="col-md-12">
                    <label
                      htmlFor="permissibleKnockOffBasis"
                      className="control-label textbox-label"
                    >
                      Score Indicators
                    </label>
                    <div
                      className="text-nowrap block-example border"
                      style={{
                        width: "100%",
                        height: "200px",
                        overflowY: "auto",
                        padding: "3px"
                      }}
                    >
                      <React.Fragment>
                        {scoreIndicators.length === 0 ? (
                          <div
                            style={{
                              backgroundColor: "#F3F3F3",
                            }}
                          >
                            <p className="text-center">
                              no score indicators
                            </p>
                          </div>
                        ) : (
                          <React.Fragment>
                            {scoreIndicators.map(
                              (item) => (
                                <div
                                  className="custom-control custom-checkbox"
                                  key={scoreIndicators.indexOf(
                                    item
                                  )}
                                >
                                  <input
                                    id={item.id}
                                    name={item.id}
                                    className="custom-control-input"
                                    type="checkbox"
                                    onChange={() =>
                                      this.handleOnChangeScoreIndicator(
                                        item.id
                                      )
                                    }
                                    checked={
                                      _.isEmpty(
                                        scoreIndicatorIds?.filter(
                                          (x) => x === item.id
                                        )
                                      )
                                        ? false
                                        : true
                                    }
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={item.id}
                                  >
                                    {item.indicatorName}
                                  </label>
                                </div>
                              )
                            )}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            className="btn btn-sm btn-primary"
            disabled={this.state.submitting}
            onClick={(e) => { this.onHandleSubmit(e) }}
          >
            {this.state.submitting === false ? (
              <React.Fragment>
                <i className="fas fa-lg fa-save mr-3" /> Save
              </React.Fragment>
            ) : (
              <React.Fragment>
                <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                Please wait...
              </React.Fragment>
            )}
          </button>
          <Button
            size="sm"
            variant="outline-danger"
            onClick={() => this.props.togglePatchModal(true, this.props.item)}
            disabled={submitting}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModifyScoreIndicators;
