import React from "react";
import { toast } from "react-toastify";
import _, { isEmpty } from "lodash";
import BaseFormComponent, { IBaseFormState } from "../../../components/BaseFormComponent";
import FormInputComponent from "../../../components/formInputComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import MultiSelectComponent, {
	IMultiSelectItems,
} from "../../../components/formMultiSelectComponent";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../../services/auth/authService";
import workFlowConfigService from "../../../services/hybridAppraisal/workFlowConfigService";
import MandatoryFieldConfig from "./mandatoryFieldConfig";

interface AddWorkFlowConfigFields {
	name: string;
}

interface IDetails {
	label: string;
	value: string;
}

class AddWorkFlowConfigForm extends BaseFormComponent<AddWorkFlowConfigFields> { }

interface IAddWorkFlowConfigProps { }

interface IAddWorkFlowConfigState {
	selectedworkflowSteps: IDetails[];
	selectedDataEntry: any;
	fetchingRequiredItems: boolean;
	dataEntrySections: any;
	showMandatoryFieldModal: boolean;
}

class AddWorkFlowConfig extends React.Component<
	IAddWorkFlowConfigProps,
	IBaseFormState & IAddWorkFlowConfigState
> {
	constructor(props: IAddWorkFlowConfigProps) {
		super(props);
		this.state = {
			validated: false,
			submitting: false,
			errors: {},
			fetchingRequiredItems: false,
			selectedworkflowSteps: [],
			selectedDataEntry: [],
			dataEntrySections: [],
			showMandatoryFieldModal: false
		};
	}

	onFormSubmit(fields: AddWorkFlowConfigFields, onReloadFieldsCB: any): boolean {
		try {
			this.setState(
				{}, () => {
					if (Object.keys(this.state.errors).length === 0) {
						return this.submit(fields, onReloadFieldsCB);
					}
				});
			this.setState({ validated: true });
			return false;
		} catch (error) {
			return false;
		}
	}

	async submit(
		fields: AddWorkFlowConfigFields,
		onReloadFieldsCB: any
	): Promise<boolean> {
		try {
			this.setState({ submitting: true });
			const payload: any = {};
			const { selectedworkflowSteps } = this.state;
			const { selectedDataEntry } = this.state;
			const { dataEntrySections } = this.state;
			const decodedToken = decodeToken(getCurrentUserSession().token || "");
			payload.name = fields.name;
			payload.institutionCode = decodedToken?.["InstitutionCode"];
			payload.workflowSteps = selectedworkflowSteps.map(x => x.value);
			payload.dataEntryTabs = selectedDataEntry.map(x => x.value);
			payload.dataEntrySections = dataEntrySections;

			const response = await workFlowConfigService.addWorkFlowConfig(payload);
			if (response.status >= 200 && response.status <= 300) {
				await activityTrailService.saveActivityTrail({
					actionType: "Add WorkFlow Config",
					description: `Added New WorkFlow Config ${fields.name}`,
				});
				toast.info(`Added New WorkFlow Config`, {
					autoClose: 6000,
					type: toast.TYPE.DEFAULT,
					hideProgressBar: false,
				});
			} else {
				return false;
			}
			this.setState({ submitting: false });
			this.resetFields();
			onReloadFieldsCB();
		} catch (error) {
			toast.error(error.response.data, {
				autoClose: 6000,
				type: toast.TYPE.ERROR,
				hideProgressBar: false,
			});
			this.setState({ submitting: false });
		} finally {
			return true;
		}
	}

	handleOnWorkFlowStepsChange = (selected) => {
		this.setState({ selectedworkflowSteps: selected });
	};

	handleOnDataEntryChange = (selected) => {
		this.setState({ selectedDataEntry: selected });
	};

	toggleMandatoryFieldModal = () => {
		this.setState({ showMandatoryFieldModal: !this.state.showMandatoryFieldModal });
	};

	handleOnMandatoryFieldSave = (toSave: boolean, payload) => {
		if (toSave) {
			this.setState({ dataEntrySections: payload, showMandatoryFieldModal: false });
		} else {
			this.setState({ showMandatoryFieldModal: false });
		}
	};

	resetFields() {
		this.setState({
			submitting: false,
			selectedworkflowSteps: [],
			selectedDataEntry: []
		});
	}

	resetAllFields() {
		this.setState({
			submitting: false
		})
	}

	render() {
		const {
			errors,
			validated,
			selectedDataEntry,
			dataEntrySections
		} = this.state;

		let workflowStepsInputData: IMultiSelectItems[] = [
			{ label: "Data Validation", value: "DataValidation" },
			{ label: "Document Generation", value: "DocumentGeneration" },
			{ label: "Document Collection", value: "DocumentCollection" },
			{ label: "Pre Disbursement", value: "PreDisbursement" },
		];

		let dataEntryInputData: IMultiSelectItems[] = [
			{ label: "Personal Details", value: "PersonalDetails" },
			{ label: "Contact Details", value: "ContactDetails" },
			{ label: "Work Details", value: "WorkDetails" },
			{ label: "Reference Details", value: "ReferenceDetails" },
			{ label: "Collateral Details", value: "CollateralDetails" },
			{ label: "Document Collection", value: "DocumentCollection" },
			{ label: "Notepad Details", value: "NotepadDetails" },
			{ label: "Income Details", value: "IncomeDetails" },
			{ label: "Asset Details", value: "AssetDetails" },
			{ label: "Property Details", value: "PropertyDetails" },
			{ label: "Loan Details", value: "LoanDetails" },
			{ label: "Corporate Details", value: "CorporateDetails" },
			{ label: "Corporate Analysis", value: "CorporateAnalysis" },
			{ label: "Stakeholder Details", value: "StakeholderDetails" },
			{ label: "Bank Statement", value: "BankStatement" },
			{ label: "Other Details", value: "OtherDetails" },
			{ label: "Verify", value: "Verify" }
		];

		return (
			<React.Fragment>
				<div className="container-fluid relative animatedParent animateOnce">
					<div className="animated fadeInUpShort go">
						<div className="row my-3 mx-2">
							<h3>
								<b>Configure Data Entry Fields</b>
							</h3>
						</div>
						<div className="row">
							<div className="col-md-12">
								<AddWorkFlowConfigForm
									initialValues={{
										name: ""
									}}
									FormComponent={({
										fields: {
											name
										},
										onChange,
										onReloadFields,
										onHandleSubmit,
									}) => (
										<form action="#">
											<div className="card no-b">
												<div className="card-body">
													<div className="row">
														<div className="col-md-12">

															{/* General Information */}
															<div className="form-row">
																<FormInputComponent
																	id="name"
																	type="text"
																	name="name"
																	divClass={6}
																	placeholder=""
																	value={name}
																	required={true}
																	validated={validated}
																	errors={errors}
																	onChange={onChange}
																	disabled={false}
																/>
															</div>

															{/* WorkFlow Steps */}
															{/* <div className="form-row">
																<div className="form-group col-md-12 mt-3">
																	<label
																		htmlFor="reports"
																		className="col-form-label s-10"
																	>
																		WorkFlow Steps
																	</label>
																	<br />
																	<div className="form-row mt-0">
																		<MultiSelectComponent
																			id="workflowSteps"
																			name="workflowSteps"
																			divClass={6}
																			value={this.state.selectedworkflowSteps}
																			options={workflowStepsInputData}
																			validated={validated}
																			errors={errors}
																			hideSelectedOptions={false}
																			onChange={this.handleOnWorkFlowStepsChange}
																			allowSelectAll={true}
																			required={true}
																		// disabled={false}
																		/>
																	</div>
																</div>
															</div> */}

															{/* Data Entry Tabs */}
															<div className="form-row">
																<div className="form-group col-md-12 mt-3">
																	<label
																		htmlFor="reports"
																		className="col-form-label s-10"
																	>
																		Data Entry Tabs
																	</label>
																	<br />
																	<div className="form-row mt-0">
																		<MultiSelectComponent
																			id="dataEntry"
																			name="dataEntry"
																			divClass={6}
																			value={selectedDataEntry}
																			options={dataEntryInputData}
																			validated={validated}
																			errors={errors}
																			hideSelectedOptions={false}
																			onChange={this.handleOnDataEntryChange}
																			allowSelectAll={true}
																		// required={true}
																		// disabled={true}
																		/>
																	</div>
																</div>
															</div>

															{/* Mandatory Fields */}
															<div className="form-row">
																<div className="form-group col-md-12 mt-3">
																	<label
																		htmlFor="reports"
																		className="col-form-label s-10"
																	>
																		Mandatory Fields
																	</label>
																	<br />
																	<div className="form-row mt-0">
																		<button
																			className={_.isEmpty(dataEntrySections) ?
																				"btn btn-sm btn-primary" : "btn btn-sm btn-success"}
																			onClick={() => this.toggleMandatoryFieldModal()}
																			disabled={(isEmpty(selectedDataEntry))}
																		>
																			<i className="far fa-edit mr-3" />
																			Configure Mandatory Fields
																		</button>
																	</div>
																</div>
															</div>
															<MandatoryFieldConfig
																showPatchModal={this.state.showMandatoryFieldModal}
																togglePatchModal={(toSave: boolean, payload: any) => {
																	this.handleOnMandatoryFieldSave(toSave, payload);
																}}
																item={selectedDataEntry}
															/>
														</div>
													</div>
												</div>
											</div>

											<div className="card-body">
												<button
													type="submit"
													className="btn btn-sm btn-primary"
													disabled={this.state.submitting}
													onClick={(e) => {
														onHandleSubmit(
															e,
															ActionTypes.SAVE,
															"Workflow Config",
															() => {
																this.onFormSubmit(
																	{
																		name
																	},
																	onReloadFields
																);
															}
														);
													}}
												>
													{this.state.submitting === false ? (
														<React.Fragment>
															<i className="fas fa-lg fa-save mr-3" /> Save
														</React.Fragment>
													) : (
														<React.Fragment>
															<i className="fas fa-spin fa-circle-notch mr-3" />{" "}
															Please wait...
														</React.Fragment>
													)}
												</button>
											</div>
										</form>
									)}
								></AddWorkFlowConfigForm>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default AddWorkFlowConfig;
