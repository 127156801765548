import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import reportsService from "../../../services/_axiosServices/reports-center";
import { getDateForTable } from "../../../services/utility/textFormatService";
import { Loader, Error } from "../components";

const mockData = {
  data: [
    {
      loanReference: "--",
      createdOn: "--",
      customerName: "--",
      collectionAttempts: 0,
      successfulCollectionAttempts: 0,
      mandateSetUpDate: "--",
      repaymentDate: "--",
      expectedRepayment: 0,
      amountCollected: 0,
      repaymentMode: "--",
    },
  ],
  currentPage: 0,
  totalPages: 0,
  totalCount: 0,
  pageSize: 0,
  hasPreviousPage: false,
  hasNextPage: true,
  messages: null,
  succeeded: true,
};

const initialParams = {
  LoanReference: "",
  CustomerName: "",
  MandateSetUpStartDate: "",
  MandateSetUpEndDate: "",
  CreationStartDate: "",
  CreationEndDate: "",
  PageNumber: "",
  PageSize: "",
} as any;

const fields = {
  LoanReference: {
    type: "text",
    label: "Loan Reference",
  },
  CustomerName: {
    type: "text",
    label: "Customer Name",
  },
  MandateSetUpStartDate: {
    type: "date",
    label: "Mandate SetUp Start Date",
  },
  MandateSetUpEndDate: {
    type: "date",
    label: "Mandate SetUp End Date",
  },
  CreationStartDate: {
    type: "date",
    label: "Creation Start Date",
  },
  CreationEndDate: {
    type: "date",
    label: "Creation End Date",
  },
  PageNumber: {
    type: "number",
    label: "PageNumber",
  },
  PageSize: {
    type: "number",
    label: "PageSize",
  },
};

export default function Report() {
  const [showModal, setShowModal] = useState(false);
  const [filterParams, setFilterParams] = useState(initialParams);
  const [tempParams, setTempFilterParams] = useState(initialParams);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(mockData);
  const [error, setError] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    await reportsService
      .getCollectionsSummaryReport({
        LoanReference: filterParams.LoanReference || undefined,
        CustomerName: filterParams.CustomerName || undefined,
        MandateSetUpStartDate: filterParams.MandateSetUpStartDate || undefined,
        MandateSetUpEndDate: filterParams.MandateSetUpEndDate || undefined,
        CreationStartDate: filterParams.CreationStartDate || undefined,
        CreationEndDate: filterParams.CreationEndDate || undefined,
        PageNumber: filterParams.PageNumber || undefined,
        PageSize: filterParams.PageSize || undefined,
      })
      .then((res: any) => {
        // console.log(res);
        setLoading(false);
        setData({
          ...data,
          ...res.data,
        });
      })
      .catch((err: any) => {
        setError(true);
        setLoading(false);
        console.log(err.message);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAction = (action: string) => {
    if (loading) return;
    let newPageNum = 0;

    switch (action) {
      case "first":
        setFilterParams({
          ...filterParams,
          PageNumber: "1",
        });
        break;

      case "previous":
        newPageNum = Number(data.currentPage - 1);
        setFilterParams({
          ...filterParams,
          PageNumber: `${newPageNum}`,
        });
        break;

      case "next":
        newPageNum = Number(data.currentPage + 1);
        setFilterParams({
          ...filterParams,
          PageNumber: `${newPageNum}`,
        });
        break;

      case "last":
        setFilterParams({
          ...filterParams,
          PageNumber: `${data.totalPages}`,
        });
        break;

      case "reload":
        fetchData();
        break;

      case "filter":
        setFilterParams(tempParams);
        setShowModal(false);
        break;

      default:
        break;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTempFilterParams((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  return (
    <div className="container-fluid relative animatedParent animateOnce">
      <div className="animated fadeInUpShort go">
        <div className="row my-3 mx-2">
          <h3>
            <b>Collection Summary Report</b>
          </h3>
        </div>
        <div className="card no-b">
          <div className="card-body">
            <div className="card-title">
              <div className="page">
                <div className="page-inner">
                  <div className="btn-toolbar justify-content-between">
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn btn-sm"
                        onClick={() => handleAction("first")}
                      >
                        <i className="fas fa-step-backward"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm"
                        disabled={!data.hasPreviousPage}
                        onClick={() => handleAction("previous")}
                      >
                        <i className="fas fa-chevron-left"></i>
                      </button>
                      <button type="button" disabled className="btn btn-sm">
                        page {data.currentPage || "1"} of {data.totalPages}
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm"
                        disabled={!data.hasNextPage}
                        onClick={() => handleAction("next")}
                      >
                        <i className="fas fa-chevron-right"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm"
                        disabled={!data.hasNextPage}
                        onClick={() => handleAction("last")}
                      >
                        <i className="fas fa-step-forward"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm"
                        onClick={() => handleAction("reload")}
                      >
                        <i className="fas fa-sync"></i>
                      </button>
                      {/* <button type="button" className="btn btn-sm action xls">
                        XLSX
                      </button>
                      <button type="button" className="btn btn-sm action pdf">
                        PDF
                      </button>
                      <button type="button" className="btn btn-sm action csv">
                        CSV
                      </button> */}
                    </div>

                    <div className="btn-group">
                      <button type="button" disabled className="btn btn-sm">
                        {data.data.length > 0
                          ? `Showing ${
                              (data.currentPage - 1) * data.pageSize + 1
                            } - ${
                              (data.currentPage - 1) * data.pageSize +
                              data.data.length
                            } of ${data.totalCount}`
                          : ""}
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm"
                        onClick={() => setShowModal(true)}
                      >
                        <i className="fas fa-filter mr-2"></i> filter
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive text-nowrap">
                    {loading && <Loader />}

                    {!loading && !error && (
                      <table className="table table-striped table-bordered table-hover">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Loan Reference</th>
                            <th>Created On</th>
                            <th>Customer Name</th>
                            <th>Collection Attempts</th>
                            <th>Successful Collection Attempts</th>
                            <th>Mandate SetUp Date</th>
                            <th>Repayment Date</th>
                            <th>Expected Repayment</th>
                            <th>Amount Collected</th>
                            <th>Repayment Mode</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.data.map((d: any, i: number) => (
                            <tr key={i}>
                              <th scope="row">{i + 1}</th>
                              <td>{d.loanReference}</td>
                              <td>{getDateForTable(d.createdOn)}</td>
                              <td>{d.customerName}</td>
                              <td>{d.collectionAttempts}</td>
                              <td>{d.successfulCollectionAttempts}</td>
                              <td>{getDateForTable(d.mandateSetUpDate)}</td>
                              <td>{getDateForTable(d.repaymentDate)}</td>
                              <td>{d.expectedRepayment}</td>
                              <td>{d.amountCollected}</td>
                              <td>{d.repaymentMode}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}

                    {error && !loading && <Error />}
                    <Modal
                      size="lg"
                      show={showModal}
                      onHide={() => setShowModal(false)}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Filter List</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <form>
                          <div className="row">
                            {Object.entries(fields).map((e) => (
                              <div key={e[0]} className="col-md-4 mb-3 mt-3">
                                <label htmlFor={e[0]} className="form-label">
                                  {e[1].label}
                                </label>

                                <input
                                  value={tempParams[e[0]]}
                                  type={e[1].type}
                                  name={e[0]}
                                  className="form-control"
                                  onChange={handleChange}
                                  id={e[0]}
                                  placeholder={e[1].label}
                                />
                              </div>
                            ))}
                          </div>
                        </form>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          className="btn btn-"
                          onClick={() => handleAction("filter")}
                        >
                          Filter
                        </button>
                        <button
                          className="btn btn-"
                          onClick={() => setShowModal(false)}
                        >
                          Close
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
