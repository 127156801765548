import * as React from "react";
// import _ from "lodash";
import { Fragment } from "react";
import approvableService from "../../services/approval/approvableService";
import rolesService from "../../services/auth/rolesService";
import Skeleton from "react-loading-skeleton";
import ApprovalConfigGroupItem from "./approvalConfigGroupItem";
import ApprovalRuleView from "./approvalRuleView";
import ApprovalRuleConfig from "./approvalRuleConfig";
import "./approvalConfig.css";
import { AxiosResponse } from "axios";
// import { getCurrentUserSession } from "../../services/auth/authService";

export interface ApprovalConfigProps {}

export interface ApprovalConfigState {
  fetching: boolean;
  approvables: any;
  item: any;
  showDetailsModal: boolean;
  showEditModal: boolean;
}

class ApprovalConfig extends React.Component<
  ApprovalConfigProps,
  ApprovalConfigState
> {
  constructor(props: ApprovalConfigProps) {
    super(props);
    this.state = {
      fetching: true,
      approvables: [],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
    };
  }

  async componentWillMount() {
    try {
      let approvables = await approvableService.retrieveApprovables();
      // var test = approvables.data.filter((x) =>
      //   x.functionID.includes("Recova")
      // );
      // console.log("test: ", test);
      // console.log(
      //   "sorted items: ",
      //   approvables.data.filter((x) => x.functionID.includes("Recova")).length
      // );
      this.setState({ approvables: approvables.data, fetching: false });
      // this.state.approvables.forEach(approvable => {
      //   console.log("found: ", approvable.functionName);
      // });
    } catch (ex) {
      this.setState({ fetching: false });
    }
  }

  toggleDetailsModal = (item: any) => {
    this.setState({ showDetailsModal: !this.state.showDetailsModal });
    if (this.state.showDetailsModal === false) {
      this.fetchItemById(item);
    } else {
      this.setState({ item: {} });
    }
  };

  toggleEditModal = (reloadDetails: boolean, item: any) => {
    this.setState({
      showEditModal: !this.state.showEditModal,
    });
    //debugger;
    if (reloadDetails === true) {
      this.fetchItemById(item);
    }
    this.setState({ showDetailsModal: !this.state.showDetailsModal });
  };

  fetchItemById = async (item: any) => {
    const approvableItem = await approvableService.retrieveApprovable(item.id);
    const approvable = approvableItem.data;
    const roleTasks: Promise<AxiosResponse<any>>[] = [];
    for (let index = 0; index < approvable.rules.length; index++) {
      // console.log("setting role: ", approvable.rules[index]);
      // gather all the async tasks
      roleTasks.push(rolesService.retrieveRoleById(approvable.rules[index].roleID));
    }

    //Run tasks
    const response = await Promise.all(roleTasks);

    // Insert responses to appropriate rules
    for (let index = 0; index < approvable.rules.length; index++) {
      approvable.rules[index].role = response[index].data;
    }
    this.setState({ item: approvable });
  };

  render() {
    const { approvables, fetching } = this.state;
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Approval Configuration Management</b>
              </h3>
            </div>

            <div className="card">
              <div
                className="card-header"
                style={{ padding: "0px", height: "35px", paddingTop: "5px" }}
              >
                <div className="clearfix">
                  <div className="float-left">
                    <i className="fas fa-cogs mr-3 ml-2"></i> User Approval
                    Configuration Settings
                  </div>
                </div>
              </div>
              <div className="card-body collapse show" id="collapseUserConfig">
                <div className="card-content">
                  {fetching === true ? (
                    <Skeleton height={50} count={5} />
                  ) : (
                    <ApprovalConfigGroupItem
                      toggleDetailsModal={this.toggleDetailsModal}
                      items={approvables.filter((x) =>
                        x.functionID.includes("Recova.Auth.User")
                      )}
                    />
                  )}
                </div>
              </div>
              <div
                className="card-header card-footer"
                style={{ padding: "0px", height: "35px", paddingTop: "5px" }}
              >
                <div className="clearfix">
                  <div className="float-left">
                    <i className="fas fa-cogs mr-3 ml-2"></i> Branch Approval
                    Configuration Settings
                  </div>
                </div>
              </div>
              <div
                className="card-body collapse show"
                id="collapseBranchConfig"
              >
                <div className="card-content">
                  {fetching === true ? (
                    <Skeleton height={50} count={4} />
                  ) : (
                    <ApprovalConfigGroupItem
                      toggleDetailsModal={this.toggleDetailsModal}
                      items={approvables.filter((x) =>
                        x.functionID.includes("Recova.Auth.Branch")
                      )}
                    />
                  )}
                </div>
              </div>
              <div
                className="card-header card-footer"
                style={{ padding: "0px", height: "35px", paddingTop: "5px" }}
              >
                <div className="clearfix">
                  <div className="float-left">
                    <i className="fas fa-cogs mr-3 ml-2"></i> Roles Approval
                    Configuration Settings
                  </div>
                </div>
              </div>
              <div className="card-body collapse show" id="collapseRoleConfig">
                <div className="card-content">
                  {fetching === true ? (
                    <Skeleton height={50} count={4} />
                  ) : (
                    <ApprovalConfigGroupItem
                      toggleDetailsModal={this.toggleDetailsModal}
                      items={approvables.filter((x) =>
                        x.functionID.includes("Recova.Auth.Role")
                      )}
                    />
                  )}
                </div>
              </div>
              <div
                className="card-header card-footer"
                style={{ padding: "0px", height: "35px", paddingTop: "5px" }}
              >
                <div className="clearfix">
                  <div className="float-left">
                    <i className="fas fa-cogs mr-3 ml-2"></i> Mandate Approval
                    Configuration Settings
                  </div>
                </div>
              </div>
              <div
                className="card-body collapse show"
                id="collapseMandateConfig"
              >
                <div className="card-content">
                  {fetching === true ? (
                    <Skeleton height={50} count={5} />
                  ) : (
                    <ApprovalConfigGroupItem
                      toggleDetailsModal={this.toggleDetailsModal}
                      items={approvables.filter(
                        (x) =>
                          x.functionID.includes("Recova.Mandate") &&
                          !x.functionID.includes(
                            "Recova.Mandate.MandateServiceType"
                          )
                      )}
                    />
                  )}
                </div>
              </div>
              <div
                className="card-header card-footer"
                style={{ padding: "0px", height: "35px", paddingTop: "5px" }}
              >
                <div className="clearfix">
                  <div className="float-left">
                    <i className="fas fa-cogs mr-3 ml-2"></i> Dispute Approval
                    Configuration Settings
                  </div>
                </div>
              </div>
              <div
                className="card-body collapse show"
                id="collapseDisputeConfig"
              >
                <div className="card-content">
                  {fetching === true ? (
                    <Skeleton height={50} count={2} />
                  ) : (
                    <ApprovalConfigGroupItem
                      toggleDetailsModal={this.toggleDetailsModal}
                      items={approvables.filter((x) =>
                        x.functionID.includes("Recova.Dispute")
                      )}
                    />
                  )}
                </div>
              </div>
              {approvables.filter((x) => x.functionID.includes("IRestrict"))
                .length > 0 ? (
                <React.Fragment>
                  <div
                    className="card-header card-footer"
                    style={{
                      padding: "0px",
                      height: "35px",
                      paddingTop: "5px",
                    }}
                  >
                    <div className="clearfix">
                      <div className="float-left">
                        <i className="fas fa-cogs mr-3 ml-2"></i> IRestrict
                        Configuration Settings
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-body collapse show"
                    id="collapseCBAConfig"
                  >
                    <div className="card-content">
                      {fetching === true ? (
                        <Skeleton height={50} count={1} />
                      ) : (
                        <ApprovalConfigGroupItem
                          toggleDetailsModal={this.toggleDetailsModal}
                          items={approvables.filter((x) =>
                            x.functionID.includes("IRestrict")
                          )}
                        />
                      )}
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}
              <div
                className="card-header card-footer"
                style={{ padding: "0px", height: "35px", paddingTop: "5px" }}
              >
                <div className="clearfix">
                  <div className="float-left">
                    <i className="fas fa-cogs mr-3 ml-2"></i> Other Approval
                    Configuration Settings
                  </div>
                </div>
              </div>
              <div className="card-body collapse show" id="collapseCBAConfig">
                <div className="card-content">
                  {fetching === true ? (
                    <Skeleton height={50} count={1} />
                  ) : (
                    <ApprovalConfigGroupItem
                      toggleDetailsModal={this.toggleDetailsModal}
                      items={approvables.filter(
                        (x) =>
                          x.functionID.includes("RecovaLoan") ||
                          x.functionID.includes("LoanRequestApproval")
                      )}
                    />
                  )}
                </div>
              </div>
              <div className="card-footer">
                {/* <div className="form-group clearfix">
              <button
                className="btn btn-sm btn-primary float-right"
                type="button"
              >
                <i className="fas fa-lg fa-search mr-4" /> Update
              </button>
            </div> */}
              </div>

              <ApprovalRuleView
                item={this.state.item}
                showDetailsModal={this.state.showDetailsModal}
                toggleDetailsModal={this.toggleDetailsModal}
                toggleEditModal={this.toggleEditModal}
                // reloadItem={this.fetchItemById}
              />

              <ApprovalRuleConfig
                item={this.state.item}
                showEditModal={this.state.showEditModal}
                toggleEditModal={this.toggleEditModal}
                reloadItem={(item) => this.fetchItemById(item)}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ApprovalConfig;
