import React from "react";
import { toast } from "react-toastify";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import billingValidationService from "../../../services/billing/billingValidationService";
import { ISelectItems } from "../../../components/detailItemSelectBox";
import FormInputComponent from "../../../components/formInputComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import SelectInputComponent from "../../../components/formSelectComponent";
import thirdPartyAccountConfigService from "../../../services/billing/thirdPartyAccountConfigService";
import institutionService from "../../../services/auth/institutionsService";
import { ThirdParty } from "../../../enums/thirdParty";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

interface AddThirdPartyAccountConfigurationFields {
  thirdParty: string;
  bank: string;
  bankCode: string;
  accountNumber: string;
}

class AddThirdPartyAccountConfigurationForm extends BaseFormComponent<AddThirdPartyAccountConfigurationFields> { }

interface IAddThirdPartyAccountConfigurationProps { }
interface IAddThirdPartyAccountConfigurationState {
  category: string;
  institution: any;
  institutions: any;
  fetchingInstitutions: boolean;
}

class AddThirdPartyAccountConfiguration extends React.Component<
  IAddThirdPartyAccountConfigurationProps,
  IBaseFormState & IAddThirdPartyAccountConfigurationState
> {
  constructor(props: IAddThirdPartyAccountConfigurationProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      category: "",
      institution: {},
      institutions: [],
      fetchingInstitutions: false,
    };
  }

  onFormSubmit(fields: AddThirdPartyAccountConfigurationFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        //
        { errors: billingValidationService.validateAddThirdPartyAccountConfigurationForm(fields) },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddThirdPartyAccountConfigurationFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true });
      const payload: any = {};

      payload.institutionCode = this.state.institution.code;
      payload.thirdParty = fields.thirdParty;
      payload.bank = fields.bank;
      payload.bankCode = fields.bankCode;
      payload.accountNumber = fields.accountNumber;

      var thirdPartyAccountConfig = await thirdPartyAccountConfigService.filterThirdPartyAccountConfig(this.state.institution.code, fields.thirdParty);
      if ((thirdPartyAccountConfig.data).length != 0) {
        toast.error(`Third Party Account Configuration for ${this.state.institution.name} ${fields.thirdParty} already exist `, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      else {
        const response = await thirdPartyAccountConfigService.saveThirdPartyAccountConfig(payload);
        if (response.status >= 200 && response.status <= 300) {
          await activityTrailService.saveActivityTrail({
            actionType: "Add ThirdParty Account Configuration",
            description: `Added new ThirdParty Account Configuration ${this.state.institution.name} ${fields.thirdParty}`,
          });
          toast.info(`Third Party Account Configuration for ${this.state.institution.name} ${fields.thirdParty} created successfully! `, {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          });
        }
        else {
          return false;
        }
        this.setState({ submitting: false });
        onReloadFieldsCB();
      }
    } catch (error) {
      // console.log("error: ", error.response.data);
      return false;
    } finally {
      this.setState({ submitting: false });
      return true;
    }
  }

  handleOnSelectCategory = async (id, value) => {
    try {
      this.setState({
        category: value,
        fetchingInstitutions: true,
        institution: {},
      });
      let inst = await institutionService.retrieveAllByCategory(value);
      this.setState({ institutions: inst.data, fetchingInstitutions: false });
    } catch (error) {
      toast.info("Something went wrong.", {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      toast.info(error.response.data.errors[0], {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      this.setState({
        category: "",
        fetchingInstitutions: false,
      });
    }
  };

  handleOnSelectInstitution = async (id, value) => {
    try {
      const institution = this.state.institutions.filter(
        (x) => x.id === parseInt(value)
      )[0];
      this.setState({ institution: institution })
    } catch (error) {
      toast.info("Something went wrong.", {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      toast.info(error.response.data.errors[0], {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
    }
  };

  resetFields() {
    this.setState({
      category: "",
      institution: "",
    });
  }

  render() {
    const {
      errors,
      validated,
      category,
      institution,
      fetchingInstitutions,
      institutions
    } = this.state;

    let institutionCategoryInputData: ISelectItems[] = [
      { name: "Commercial Bank", value: "CommercialBank" },
      { name: "BankOne OFI", value: "BankOneOFI" },
      { name: "OFI", value: "OFI" },
    ];

    let institutionsInputData: ISelectItems[] = institutions.map((item) => ({
      name: item.name,
      value: item.id,
    }));
    // order by institution name
    institutionsInputData.sort((a, b) => a.name.localeCompare(b.name))

    // Turn enum into array
    let thirdPartyInputData: ISelectItems[] = Object.keys(
      ThirdParty
    )
      .filter((value) => isNaN(Number(value)) === false)
      .map((key) => ({
        name: ThirdParty[key],
        value: ThirdParty[key],
      }));

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add Third Party Account Configuration</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddThirdPartyAccountConfigurationForm
                  initialValues={{
                    thirdParty: "",
                    bank: "",
                    bankCode: "",
                    accountNumber: "",
                  }}
                  FormComponent={({
                    fields: {
                      thirdParty,
                      bank,
                      bankCode,
                      accountNumber,
                    },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-row">
                                <SelectInputComponent
                                  id="institutionCategory"
                                  name="institutionCategory"
                                  divClass={6}
                                  value={category}
                                  items={institutionCategoryInputData}
                                  required={true}
                                  validated={false}
                                  errors={[]}
                                  onChange={this.handleOnSelectCategory}
                                />
                                <SelectInputComponent
                                  id="institution"
                                  name="institution"
                                  divClass={6}
                                  value={institution.id}
                                  items={institutionsInputData}
                                  required={true}
                                  validated={false}
                                  errors={[]}
                                  labelIconClass={fetchingInstitutions === true
                                    ? "fas fa-spinner fa-spin orange-text mr-2"
                                    : ""
                                  }
                                  onChange={this.handleOnSelectInstitution}
                                />
                              </div>
                              <div className="form-row">
                                <SelectInputComponent
                                  id="thirdParty"
                                  name="thirdParty"
                                  divClass={6}
                                  value={thirdParty}
                                  items={thirdPartyInputData}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="bank"
                                  type="text"
                                  name="bank"
                                  placeholder=""
                                  divClass={6}
                                  value={bank}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-row">
                                <FormInputComponent
                                  id="bankCode"
                                  type="text"
                                  name="bankCode"
                                  placeholder=""
                                  divClass={6}
                                  value={bankCode}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="accountNumber"
                                  type="text"
                                  name="accountNumber"
                                  placeholder=""
                                  divClass={6}
                                  value={accountNumber}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="card-body">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary"
                          disabled={this.state.submitting}
                          onClick={(e) => {
                            onHandleSubmit(
                              e,
                              ActionTypes.SAVE,
                              "Third Party Account Configuration",
                              () => {
                                this.onFormSubmit(
                                  {
                                    thirdParty,
                                    bank,
                                    bankCode,
                                    accountNumber,
                                  },
                                  onReloadFields
                                );
                              }
                            );
                          }}
                        >
                          {this.state.submitting === false ? (
                            <React.Fragment>
                              <i className="fas fa-lg fa-save mr-3" /> Save
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                              Please wait...
                            </React.Fragment>
                          )}
                        </button>
                      </div>
                    </form>
                  )}
                ></AddThirdPartyAccountConfigurationForm>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment >
    );
  }
}

export default AddThirdPartyAccountConfiguration;
