import React, { Fragment, Component, useContext } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../../../../components/detailItem";
import { Accordion, Card } from "react-bootstrap";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import textFormatService from "../../../../../../services/utility/textFormatService";

export function ContextAwareToggle({ eventKey, level }) {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      <b>Stakeholder {level}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
}

interface PersonalDetailsProps {
  values: any;
  handleChange: any;
  handleBlur: any;
}

interface PersonalDetailsState {
  item: any;
}

class PersonalDetails extends Component<PersonalDetailsProps, PersonalDetailsState> {
  constructor(props: PersonalDetailsProps) {
    super(props);
    this.state = {
      item: {},
    };
  }

  static getDerivedStateFromProps(nextProps: PersonalDetailsProps, prevState) {
    return {
      item: nextProps.values.stakeholders,
    };
  }

  render() {
    const { values } = this.props;

    return (
      <Fragment>
        <div className="card">
          <div className="card-header clear-fix">
            {_.isEmpty(values) ? (
              <Skeleton width={200} />
            ) : (
              <h6
                className="card-title float-left"
                style={{ marginBottom: "0px" }}
              >
                <b>Stakeholder Details</b>
              </h6>
            )}
          </div>

          <Accordion defaultActiveKey="0">
            <Card>
              <Accordion.Collapse eventKey={`0`}>
                <Card.Body>
                  <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                    <div className="row">
                      <div className="col-md-12">
                        {_.isEmpty(values.stakeholders) ? (
                          <div style={{ textAlign: "center", color: "#FE825E" }}>
                            No Stakeholder has been assigned to Loan Request
                          </div>
                        ) : (
                          <div>
                            {values.stakeholders.map((item, idx) => (
                              <div key={idx}>
                                <Accordion defaultActiveKey={`${idx}`}>
                                  <Card>
                                    <ContextAwareToggle
                                      eventKey={`${idx}`}
                                      level={idx + 1}
                                    ></ContextAwareToggle>
                                    <Accordion.Collapse eventKey={`${idx}`}>
                                      <Card.Body className={"rule-card-body"}>
                                        <div className="card-body">
                                          <div className="row">
                                            <div className="col-md-6">
                                              <dl className="row">
                                                <DetailItem
                                                  label="Title"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.title}
                                                />
                                                <DetailItem
                                                  label="First Name"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.firstName}
                                                />
                                                <DetailItem
                                                  label="Last Name"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.lastName}
                                                />
                                                <DetailItem
                                                  label="Date of Birth"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  // value={item?.dateOfBirth.split("T")[0]}
                                                  value={textFormatService.formatShortTimeString(item?.dateOfBirth)}
                                                />
                                                <DetailItem
                                                  label="City"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.city}
                                                />
                                                <DetailItem
                                                  label="Country"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.country}
                                                />
                                                <DetailItem
                                                  label="Phone Number 2"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.phoneNumber2}
                                                />
                                                <DetailItem
                                                  label="Educational Qualification"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.educationalQualification}
                                                />
                                                <DetailItem
                                                  label="Length of Years in Company"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.lengthOfYearsInCompany}
                                                />
                                                <DetailItem
                                                  label="Identification Number"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.idNumber}
                                                />
                                              </dl>
                                            </div>
                                            <div className="col-md-6">
                                              <dl className="row">
                                                <DetailItem
                                                  label="Position"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.position}
                                                />
                                                <DetailItem
                                                  label="Middle Name"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.middleName}
                                                />
                                                <DetailItem
                                                  label="Gender"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.gender}
                                                />
                                                <DetailItem
                                                  label="Address"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.address}
                                                />
                                                <DetailItem
                                                  label="State"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.state}
                                                />
                                                <DetailItem
                                                  label="Phone Number"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.phoneNumber}
                                                />
                                                <DetailItem
                                                  label="Stake Percentage"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.stakePercentage}
                                                />
                                                <DetailItem
                                                  label="Length of Months in Company"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.monthsInCompany}
                                                />
                                                <DetailItem
                                                  label="Identification Type"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.idType}
                                                />
                                                <DetailItem
                                                  label="Identification Expiry Date"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.idExpiry}
                                                />
                                              </dl>
                                            </div>
                                          </div>
                                        </div>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

        </div>
      </Fragment>
    );
  }
}

export default PersonalDetails;