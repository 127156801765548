import React, { Fragment, Component } from "react";
import { toast } from "react-toastify";
import BaseFormComponent, { IBaseFormState } from "../../../../../../components/BaseFormComponent";
import FormInputComponent from "../../../../../../components/formInputComponent";
import SelectInputComponent, { ISelectItems } from "../../../../../../components/formSelectComponent";
import { ActionTypes } from "../../../../../../enums/actionTypes";
import loanRequestService from "../../../../../../services/hybridAppraisal/loanRequestService";
import _ from "lodash";
import { getAgeInMonthsFromPastDate } from "../../../../../../services/utility/textFormatService";
import hybridAppraisalValidationService from "../../../../../../services/hybridAppraisal/hybridAppraisalValidationService";
import { getCurrentUserSession, getCurrentUsersRoles } from "../../../../../../services/auth/authService";
import DetailItem from "../../../../../../components/detailItem";
import appraisalInstitutionService from "../../../../../../services/hybridAppraisal/appraisalInstitutionService";
import { decodeToken } from "react-jwt";

interface PersonalDetailsFields {
  title: string;
  customerFirstName: string;
  customerMiddleName: string;
  customerLastName: string;
  gender: string;
  dateOfBirth: string;
  maritalStatus: string;
  motherMaidenName: string;
  age: string;
  educationalQualification: string;
  nationality: string;
  numberOfDependent: string;
  accountType: string;
  ageOfAccount: string;
  accountNumber: string;
  domiciliationOfSalary: string;
  identificationType: string;
  identificationNumber: string;
  identificationExpiryDate: string;
  tin: string;
}

class PersonalDetailsForm extends BaseFormComponent<PersonalDetailsFields> { }

interface PersonalDetailsProps {
  values: any;
  reloadItem: any;
  mandatoryFields: any;
}

interface PersonalDetailsState {
  ownUpdate: boolean;
  item: any;
  localClient: boolean;
}

class PersonalDetails extends Component<PersonalDetailsProps, IBaseFormState & PersonalDetailsState> {
  _isMounted: boolean;
  constructor(props: PersonalDetailsProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      item: {},
      ownUpdate: false,
      localClient: true
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    const institution = await appraisalInstitutionService.retrieveByInstitutionCode(decodedToken?.["InstitutionCode"]);
    const institutionDetails = institution.data;
    if (institutionDetails.institutionType === "Local") {
      this.setState({ localClient: true })
    }
    else {
      this.setState({ localClient: false })
    }
  }

  static getDerivedStateFromProps(
    nextProps: PersonalDetailsProps,
    prevState: PersonalDetailsState
  ) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isNull(nextProps.values)) {
      return null;
    }
    return {
      item: nextProps.values
    };
  }

  onFormSubmit(fields: PersonalDetailsFields, onReloadFieldsCB: any): boolean {
    const { mandatoryFields } = this.props;
    const { localClient } = this.state;
    try {
      this.setState(
        { errors: hybridAppraisalValidationService.validatePersonalDetailsForm(fields, localClient, mandatoryFields), ownUpdate: true },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: PersonalDetailsFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const loanRequest = await loanRequestService.retrieveById(this.props.values.id);
      const loanRequestDetail = loanRequest.data;
      const payload: any = { ...loanRequestDetail };
      payload.customerFirstName = fields.customerFirstName;
      payload.customerMiddleName = fields.customerMiddleName;
      payload.customerLastName = fields.customerLastName;
      payload.title = fields.title;
      payload.gender = fields.gender;
      payload.dateOfBirth = fields.dateOfBirth;
      payload.maritalStatus = fields.maritalStatus;
      payload.motherMaidenName = fields.motherMaidenName;
      payload.age = Number(fields.age);
      payload.educationalQualification = fields.educationalQualification;
      payload.nationality = fields.nationality;
      payload.numberOfDependent = Number(fields.numberOfDependent);
      payload.accountType = fields.accountType;
      payload.ageOfAccount = Number(fields.ageOfAccount);
      payload.accountNumber = fields.accountNumber;
      payload.domiciliationOfSalary = fields.domiciliationOfSalary;
      payload.identificationType = fields.identificationType;
      payload.identificationNumber = fields.identificationNumber;
      payload.identificationExpiryDate = fields.identificationExpiryDate;
      payload.TIN = fields.tin;

      const response = await loanRequestService.updateLoanRequest(payload);
      if (response.status >= 200 && response.status <= 300) {
        toast.info(`Personal Details saved successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false, ownUpdate: true });
      this.resetFields();
      this.props.reloadItem(payload);
      // onReloadFieldsCB();
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false, ownUpdate: true });
      return false;
    } finally {
      return true;
    }
  }

  resetFields() {
    this.setState({ validated: false, submitting: false, errors: [], ownUpdate: true });
  }

  getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  getTitle(gender, maritalStatus) {
    if (gender === "Male")
      return "Mr."
    else if (gender === "Female" && maritalStatus === "Single")
      return "Miss"
    else
      return "Mrs."
  }

  getRequiredFields(title): boolean {
    const { mandatoryFields } = this.props;
    var required = mandatoryFields?.inputFields?.filter(x => x.fieldName === title)[0]?.mandatory;
    if (required === undefined) {
      return false;
    }
    return required;
  }

  render() {
    const { values } = this.props;
    const { errors, validated } = this.state;

    const dropdownItems = values.institution.dropDownItems;
    const accountTypes: any = dropdownItems == null ? [] : dropdownItems?.filter(ddItem => ddItem.fieldName == "Account Types")[0]?.values;

    let titleInputData: ISelectItems[] = [
      { name: "Mr.", value: "Mr." },
      { name: "Mrs.", value: "Mrs." },
      { name: "Miss", value: "Miss" },
      { name: "Chf.", value: "Chf." },
      { name: "Dr.", value: "Dr." },
      { name: "Prof.", value: "Prof." },
    ];

    let genderInputData: ISelectItems[] = [
      { name: "Male", value: "Male" },
      { name: "Female", value: "Female" },
    ];

    let educationalQualificationInputData: ISelectItems[] = [
      { name: "Professional Qualification", value: "Professional Qualification" },
      { name: "Post Graduate (Masters, Doctorate etc)", value: "Post Graduate (Masters, Doctorate etc)" },
      { name: "Graduate (University Degree or equivalent)", value: "Graduate (University Degree or equivalent)" },
      { name: "Less than Graduate", value: "Less than Graduate" },
      { name: "Others", value: "Others" },
    ];

    let maritalStatusInputData: ISelectItems[] = [
      { name: "Married", value: "Married" },
      { name: "Widowed", value: "Widowed" },
      { name: "Divorced", value: "Divorced" },
      { name: "Single", value: "Single" },
      // { name: "Other", value: "Other" },
    ];

    // let accountTypeInputData: ISelectItems[] = accountTypes.map((item) => ({
    //   name: `${item.value}`,
    //   value: `${item.value}`
    // }));

    let accountTypeInputData: ISelectItems[] = [
      { name: "Savings", value: "Savings" },
      { name: "Current", value: "Current" },
      { name: "Jula", value: "Jula" },
      { name: "Premium", value: "Premium" },
      { name: "Enterprise", value: "Enterprise" },
    ];

    let identificationTypeInputData: ISelectItems[] = [
      { name: "International Passport", value: "InternationalPassport" },
      { name: "Drivers License", value: "DriversLicense" },
      { name: "Voters' Card", value: "VotersCard" },
      { name: "Work ID", value: "WorkID" },
      { name: "Ecowas Passport", value: "EcowasPassport" },
      { name: "Residential Permit", value: "ResidentialPermit" },
      { name: "National ID", value: "NationalID" },
    ];

    let domiciliationOfSalaryInputData: ISelectItems[] = [
      { name: "Yes", value: "Yes" },
      { name: "No", value: "No" },
    ];

    let acctNo = ""
    if (values.linkedAccountNo == "" || values.linkedAccountNo == null) {
      const cbaItem = JSON.parse(JSON.parse(values.jsonData))
      acctNo = cbaItem.LinkedAccountNumber
    } else {
      acctNo = values.linkedAccountNo
    }

    // console.log(values)

    return (
      <PersonalDetailsForm
        initialValues={{
          customerFirstName: values.customerFirstName ? values.customerFirstName : values.customerName.split(" ")[1],
          customerLastName: values.customerLastName ? values.customerLastName : values.customerName.split(",")[0],
          customerMiddleName: values.customerMiddleName,
          title: this.getTitle(values.gender, values.maritalStatus),
          gender: values.gender,
          dateOfBirth: values.dateOfBirth ? values.dateOfBirth?.split("T")[0] : "",
          maritalStatus: values.maritalStatus,
          motherMaidenName: values.motherMaidenName,
          age: this.getAge(values.dateOfBirth).toString(),
          educationalQualification: values.educationalQualification,
          nationality: values.nationality,
          numberOfDependent: values.numberOfDependent.toString(),
          accountType: values.accountType,
          ageOfAccount: values.repaymentAccountCreationDate ? getAgeInMonthsFromPastDate(values.repaymentAccountCreationDate).toString() : values.ageOfAccount.toString(),
          accountNumber: acctNo,
          domiciliationOfSalary: values.domiciliationOfSalary,
          identificationType: values.identificationType,
          identificationNumber: values.identificationNumber,
          identificationExpiryDate: values.identificationExpiryDate === null ? "" : values.identificationExpiryDate.split("T")[0],
          tin: values.tin
        }}
        FormComponent={({
          fields: {
            customerFirstName,
            customerLastName,
            customerMiddleName,
            title,
            gender,
            dateOfBirth,
            maritalStatus,
            motherMaidenName,
            age,
            educationalQualification,
            nationality,
            numberOfDependent,
            accountType,
            ageOfAccount,
            accountNumber,
            domiciliationOfSalary,
            identificationType,
            identificationNumber,
            identificationExpiryDate,
            tin
          },
          onChange,
          onReloadFields,
          onHandleSubmit,
        }) => (
          <Fragment>
            <div className="form-row">
              <div className="col-md-12">
                <div className="form-row">
                  <SelectInputComponent
                    id="title"
                    name="title"
                    divClass={6}
                    value={title}
                    items={titleInputData}
                    required={this.getRequiredFields("title")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    label="First Name"
                    id="customerFirstName"
                    name="customerFirstName"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={customerFirstName}
                    required={this.getRequiredFields("firstName")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    label="Middle Name"
                    id="customerMiddleName"
                    name="customerMiddleName"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={customerMiddleName}
                    required={this.getRequiredFields("middleName")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    label="Last Name"
                    id="customerLastName"
                    name="customerLastName"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={customerLastName}
                    required={this.getRequiredFields("lastName")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <SelectInputComponent
                    id="gender"
                    name="gender"
                    divClass={6}
                    value={gender}
                    items={genderInputData}
                    required={this.getRequiredFields("gender")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <SelectInputComponent
                    id="maritalStatus"
                    name="maritalStatus"
                    divClass={6}
                    value={maritalStatus}
                    items={maritalStatusInputData}
                    required={this.getRequiredFields("maritalStatus")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    id="dateOfBirth"
                    name="dateOfBirth"
                    type="date"
                    placeholder=""
                    divClass={6}
                    value={dateOfBirth}
                    required={this.getRequiredFields("dateOfBirth")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    id="age"
                    name="age"
                    type="number"
                    placeholder=""
                    divClass={6}
                    value={age}
                    required={this.getRequiredFields("age")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    label="Mother's Maiden Name"
                    id="motherMaidenName"
                    name="motherMaidenName"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={motherMaidenName}
                    required={this.getRequiredFields("motherMaidenName")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    label="Number of Dependent(s)"
                    id="numberOfDependent"
                    name="numberOfDependent"
                    type="number"
                    placeholder=""
                    divClass={6}
                    value={numberOfDependent}
                    required={this.getRequiredFields("numberOfDependent")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    id="nationality"
                    name="nationality"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={nationality}
                    required={this.getRequiredFields("nationality")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <SelectInputComponent
                    id="educationalQualification"
                    name="educationalQualification"
                    divClass={6}
                    value={educationalQualification}
                    items={educationalQualificationInputData}
                    required={this.getRequiredFields("educationalQualification")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <SelectInputComponent
                    id="accountType"
                    name="accountType"
                    divClass={6}
                    value={accountType}
                    items={accountTypeInputData}
                    required={this.getRequiredFields("accountType")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    id="accountNumber"
                    name="accountNumber"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={accountNumber}
                    required={this.getRequiredFields("accountNumber")}
                    disabled
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    label="Age of Account (In months)"
                    id="ageOfAccount"
                    name="ageOfAccount"
                    type="number"
                    placeholder=""
                    divClass={6}
                    value={ageOfAccount}
                    required={this.getRequiredFields("ageOfAccount")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                    disabled
                  />
                  <SelectInputComponent
                    label="Domiciliation of Salary"
                    id="domiciliationOfSalary"
                    name="domiciliationOfSalary"
                    divClass={6}
                    value={domiciliationOfSalary}
                    items={domiciliationOfSalaryInputData}
                    required={this.getRequiredFields("domiciliationOfSalary")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <SelectInputComponent
                    label="Identification Type"
                    id="identificationType"
                    name="identificationType"
                    divClass={6}
                    value={identificationType}
                    items={identificationTypeInputData}
                    required={this.getRequiredFields("identificationType")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    label="Identification Number"
                    id="identificationNumber"
                    name="identificationNumber"
                    type="text"
                    placeholder=""
                    divClass={6}
                    value={identificationNumber}
                    required={this.getRequiredFields("identificationNumber")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    label="Identification Expiry Date"
                    id="identificationExpiryDate"
                    name="identificationExpiryDate"
                    type="date"
                    placeholder=""
                    divClass={6}
                    value={identificationExpiryDate}
                    required={this.getRequiredFields("identificationExpiryDate")}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  {this.state.localClient ? (
                    <FormInputComponent
                      label="Customer BVN"
                      id="BVN"
                      name="BVN"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={values.bvn}
                      required={true}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled={true}
                    />
                  ) : (
                    <FormInputComponent
                      label="Customer TIN"
                      id="tin"
                      name="tin"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={tin}
                      required={this.getRequiredFields("tin")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                    />
                  )}
                </div>
              </div>

              <div className="card mt-3">
                <div className="card-header clear-fix">
                  <h6
                    className="float-left"
                    style={{ marginBottom: "0px" }}
                  >
                    <b>Customer Validation</b>
                  </h6>
                </div>
                <div className="card-body">
                  <div className="form-row">
                    {/* {console.log(values.bvnResponse)} */}
                    {values.bvnResponse !== null ? (
                      <>
                        <DetailItem
                          label="BVN"
                          labelSize={7}
                          valueSize={5}
                          value={values?.bvnResponse?.bvn} />
                        <DetailItem
                          label="Customer Name"
                          labelSize={7}
                          valueSize={5}
                          value={values?.bvnResponse?.fullName} />
                      </>
                    ) : (
                      <><DetailItem
                        label="BVN"
                        labelSize={7}
                        valueSize={5}
                        value={values.bvn} />
                        <DetailItem
                          label="Customer Name"
                          labelSize={7}
                          valueSize={5}
                          value={values.customerName} /></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="float-right">
              {(getCurrentUsersRoles().includes("updatedataentry") && (
                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  disabled={this.state.submitting}
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.SAVE,
                      "Personal Details",
                      () => {
                        this.onFormSubmit(
                          {
                            customerFirstName,
                            customerLastName,
                            customerMiddleName,
                            title,
                            gender,
                            dateOfBirth,
                            maritalStatus,
                            motherMaidenName,
                            age,
                            educationalQualification,
                            nationality,
                            numberOfDependent,
                            accountType,
                            ageOfAccount,
                            accountNumber,
                            domiciliationOfSalary,
                            identificationType,
                            identificationNumber,
                            identificationExpiryDate,
                            tin
                          },
                          onReloadFields
                        );
                      }
                    );
                  }}
                >
                  {this.state.submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                      Please wait...
                    </React.Fragment>
                  )}
                </button>
              ))}
            </div>
          </Fragment>
        )}
      ></PersonalDetailsForm>
    );
  }
}

export default PersonalDetails;