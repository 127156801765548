import React, { Fragment } from "react";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import BaseFormComponent, { IBaseFormState } from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import FormInputComponent from "../../../components/formInputComponent";
import SelectInputComponent, {
  ISelectItems,
} from "../../../components/formSelectComponent";
import appraisalValidationService from "../../../services/hybridAppraisal/appraisalValidationService";
import appraisalInstitutionService from "../../../services/hybridAppraisal/appraisalInstitutionService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import { toast } from "react-toastify";
import walletConfigService from "../../../services/billing/walletConfigService";
import { BillingType } from "../../../enums/billingType";
import institutionFeeConfigService from "../../../services/billing/institutionFeeConfigService";
import feeConfigService from "../../../services/billing/feeConfigService";
import DetailItem from "../../../components/detailItem";
import HybridAppraisalBillingService from "../../../services/billing/HybridAppraisalBillingService";

interface ActivateAppraisalInstitutionFields {
  planId: string;
  billingType?: string;
  billingPlan?: string;
  institutionType?: string;
  createConsent: boolean;
  institutionCategory: string;

  // TransactionFeeFields
  flatPerRequest?: string;
  flatPerApprovedLoan?: string;
  validationData?: any;

  // SubscriptionFeeFields
  volumeTier?: string;
  subscriptionFrequency?: string;
  price?: string;
  payAsYouGoPrice?: string;
  requestLimit?: string;
}
interface IValidationOption {
  validationPricingOptionId: string;
  fee: string;
}

class ActivateAppraisalInstitutionForm extends BaseFormComponent<ActivateAppraisalInstitutionFields> { }

interface IActivateAppraisalInstitutionProps {
  item: any;
  appraisalPlans: any;
  showPatchModal: boolean;
  togglePatchModal: any;
}

interface IActivateAppraisalInstitutionState {
  fetchingRequiredItems: boolean;
  walletConfig: any;
  billingType: string;
  createConsent: boolean;
  billingPlans: any;
  validationItems: any;
  validationOptions: IValidationOption[];
}

class ActivateAppraisalInstitution extends React.Component<
  IActivateAppraisalInstitutionProps,
  IBaseFormState & IActivateAppraisalInstitutionState
> {
  _isMounted = false;
  constructor(props: IActivateAppraisalInstitutionProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      fetchingRequiredItems: false,
      walletConfig: {},
      billingType: "",
      billingPlans: [],
      validationItems: [],
      validationOptions: [],
      createConsent: false
    };
  }

  static getDerivedStateFromProps(nextProps: IActivateAppraisalInstitutionProps, prevState) {
    if (_.isEmpty(nextProps.item)) {
      return null;
    }
    return {
      item: nextProps.item
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { errors, validationOptions } = this.state;
    try {
      if (this._isMounted) {
        this.setState({ fetchingRequiredItems: true });
      }
      const response = await Promise.allSettled([
        walletConfigService.filterWalletConfigurationByServiceOfferingAndInstitutionCode("Hybrid Appraisal", this.props.item.code),
        feeConfigService.retrieveTransactionFeeConfigByInstitutionCategory(this.props.item.category),
        feeConfigService.retrieveSubscriptionFeeConfigByInstitutionCategory(this.props.item.category),
        HybridAppraisalBillingService.getValidationPricing()
      ])
      const walletConfig = response[0].status === "fulfilled" ? response[0].value.data[0] : [];
      const transactionFeePlans = response[1].status === "fulfilled" ? response[1].value.data.data : [];
      const subscriptionFeePlans = response[2].status === "fulfilled" ? response[2].value.data.data : [];
      const validations = response[3].status === "fulfilled" ? response[3].value.data : [];

      // console.log(validations)
      validations.forEach(option => {
        validationOptions.push({ validationPricingOptionId: option.pricingOptionId, fee: "" })
      });
      this.setState({
        walletConfig: walletConfig,
        billingType: walletConfig.billingType,
        billingPlans: (walletConfig.billingType === BillingType[BillingType.PAYG]) ? transactionFeePlans : subscriptionFeePlans,
        validationOptions,
        validationItems: validations,
        fetchingRequiredItems: false,
      })
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        errors.response = "Internal server error.";
        this.setState({ errors });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onFormSubmit(fields: ActivateAppraisalInstitutionFields, onReloadFieldsCB: any): boolean {
    try {
      fields.billingType = this.state.billingType
      this.setState(
        {
          errors: appraisalValidationService.validateActivateAppraisalInstitutionForm(fields)
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  submit = async (
    fields: ActivateAppraisalInstitutionFields,
    onReloadFieldsCB: any
  ): Promise<boolean> => {
    try {
      this.setState({ submitting: true });
      const payload: any = {};
      const institution = this.props.item;
      payload.name = institution.name;
      payload.planId = Number(fields.planId);
      payload.shortName = institution.shortName;
      payload.code = institution.code;
      payload.institutionId = institution.id;
      payload.externalInstitutionCode = institution.externalInstitutionCode;
      payload.cbnCode = institution.cbnCode;
      payload.billingPlan = fields.billingPlan;
      payload.institutionType = fields.institutionType;
      payload.createConsent = fields.createConsent;
      payload.category = fields.institutionCategory;

      if (this.state.billingType === BillingType[BillingType.PAYG]) {
        const feePayload: any = {};
        feePayload.institutionCode = institution.code;
        feePayload.flatPerRequest = Number(fields.flatPerRequest);
        feePayload.flatPerApprovedLoan = Number(fields.flatPerApprovedLoan);
        feePayload.validationOptions = this.state.validationOptions;
        // console.log("feePayload: ", feePayload)
        await institutionFeeConfigService.createTransactionFeeConfig(feePayload);
      }
      else if (this.state.billingType === BillingType[BillingType.Subscription]) {
        const feePayload: any = {};
        feePayload.institutionCode = institution.code;
        feePayload.volumeTier = fields.volumeTier;
        feePayload.subscriptionFrequency = fields.subscriptionFrequency;
        feePayload.requestCount = Number(fields.requestLimit);
        feePayload.price = Number(fields.price);
        feePayload.payAsYouGoPrice = Number(fields.payAsYouGoPrice);
        await institutionFeeConfigService.createSubscriptionFeeConfig(feePayload);
      }
      // console.log("about to activate: ", payload);
      const response = await appraisalInstitutionService.activateInstitution(payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Activate Appraisal Institution",
          description: `Activate Institution ${institution.name} for Hybrid Appraisal`,
        });
      }
      toast.info(`${institution.name} activated for hybrid appraisal successfully!`, {
        autoClose: 6000,
        type: toast.TYPE.DEFAULT,
        hideProgressBar: false,
      });
      onReloadFieldsCB();
      this.setState({ submitting: false }, () =>
        this.props.togglePatchModal(true, this.props.item)
      )
    } catch (error) {
      toast.error(error.response.data, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      this.setState({ submitting: false });
    } finally {
      return true;
    }
  };
  handleValidationChange = (id, value) => {
    const validationOption = [...this.state.validationOptions];
    validationOption.every(option => {
      if (option.validationPricingOptionId === id) {
        option.fee = value;
        return false;
      }
      return true;
    });
    return validationOption;
  }

  handleUpdateCreateConsent = () => {
    this.setState({
      createConsent: !this.state.createConsent
    });
  };
  handleBillingPlanChange = (value) => {
    const billingPlans = [...this.state.billingPlans]
    const validationOptions = [];
    const validationItems = [];
    billingPlans
      .filter((item) => item.hybridAppraisalConfigurationId === value)[0]
      .validationOptions.forEach(option => {
        validationOptions.push({ validationPricingOptionId: option.pricingOptionId, fee: option.fee })
        validationItems.push({ name: option.pricingOption.name, pricingOptionId: option.pricingOptionId })
      })
    this.setState({ validationOptions, validationItems })
  }
  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
    });
  }

  render() {
    const { item, showPatchModal, togglePatchModal, appraisalPlans } = this.props;
    const {
      validated,
      submitting,
      errors,
      billingType,
      billingPlans,
      walletConfig,
      validationItems,
      validationOptions
    } = this.state;

    if (_.isEmpty(item)) {
      return null;
    }
    let appraisalPlanInputData: ISelectItems[] = (appraisalPlans.length > 0) ? appraisalPlans.map((item) => ({
      name: item.name,
      value: item.id,
    })) : [];
    //console.log("input data:: ", appraisalPlanInputData);
    let billingPlanInputData: ISelectItems[] = billingPlans.map((item) => ({
      name: item.feeName ? item.feeName : item.volumeTier,
      value: item.hybridAppraisalConfigurationId,
    }));

    let subscriptionFrequencyInputData: ISelectItems[] = [
      { name: "Monthly", value: "Monthly" },
      { name: "Quarterly", value: "Quarterly" },
      { name: "Annually", value: "Annually" },
    ];
    let institutionTypeInputData: ISelectItems[] = [
      { name: "Local", value: "Local" },
      { name: "Foreign", value: "Foreign" }
    ];

    let institutionCategoryInputData: ISelectItems[] = [
      { name: "CommercialBank", value: "CommercialBank" },
      { name: "BankOneOFI", value: "BankOneOFI" },
      { name: "OFI", value: "OFI" }
    ]

    return (
      <Modal
        size="lg"
        backdrop="static"
        show={showPatchModal}
        onHide={togglePatchModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-edit fa-sm cyan-text mr-3" />
              Activate Institution
            </h5>
          </Modal.Title>
        </Modal.Header>
        <ActivateAppraisalInstitutionForm
          initialValues={{
            planId: "",
            billingPlan: "",
            flatPerRequest: "",
            flatPerApprovedLoan: "",
            validationData: [],
            volumeTier: "",
            subscriptionFrequency: "",
            price: "",
            payAsYouGoPrice: "",
            requestLimit: "",
            institutionType: "",
            institutionCategory: "",
            createConsent: false
          }}
          FormComponent={({
            fields: {
              planId,
              billingPlan,
              flatPerRequest,
              flatPerApprovedLoan,
              validationData,
              volumeTier,
              subscriptionFrequency,
              price,
              payAsYouGoPrice,
              requestLimit,
              institutionType,
              institutionCategory,
              createConsent
            },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                {_.isEmpty(walletConfig) ? (
                  <div
                    className="font-weight-normal text-truncate"
                    style={{
                      textAlign: "center",
                      color: "#FE825E",
                      fontSize: "small",
                    }}
                  >
                    ...Institution Wallet has not being configured for Hybrid Appraisal
                  </div>
                ) : (
                  <div className="card">
                    <div className="card-header clear-fix">
                      {_.isEmpty(item) ? (
                        <Skeleton width={200} />
                      ) : (
                        <h6
                          className="card-title float-left"
                          style={{ marginBottom: "0px" }}
                        >
                          <b>General Information</b>
                        </h6>
                      )}
                    </div>
                    <div className="card-body">
                      <div className="form-row">
                        <SelectInputComponent
                          id="planId"
                          name="appraisalPlan"
                          divClass={6}
                          value={planId}
                          items={appraisalPlanInputData}
                          required={true}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        <SelectInputComponent
                          id="institutionCategory"
                          name="institutionCategory"
                          divClass={6}
                          value={institutionCategory}
                          items={institutionCategoryInputData}
                          required={true}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        <SelectInputComponent
                          id="institutionType"
                          name="institutionType"
                          divClass={6}
                          value={institutionType}
                          items={institutionTypeInputData}
                          required={true}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        {/* </div>
                      <div className="form-row"> */}
                        <div
                          className="custom-control custom-checkbox mt-5">
                          <input
                            id="createConsent"
                            name="createConsent"
                            type="checkbox"
                            onChange={this.handleUpdateCreateConsent}
                            defaultChecked={this.state.createConsent}
                          />
                          <label
                          // className="custom-control-label"
                          // htmlFor={item.id}
                          >
                            Create Consent
                          </label>
                        </div>
                        {billingType === BillingType[BillingType.PAYG] ? (
                          <SelectInputComponent
                            id="billingPlan"
                            name="billingPlan"
                            divClass={6}
                            value={billingPlan}
                            items={billingPlanInputData}
                            required={true}
                            validated={validated}
                            errors={errors}
                            onChange={async (id, value) => {
                              if (value !== "") {
                                await onChange(id, value);
                                await onChange(
                                  "flatPerRequest",
                                  billingPlans.filter(
                                    (item) => item.hybridAppraisalConfigurationId === value
                                  )[0].flatPerRequest.toString()
                                );
                                await onChange(
                                  "flatPerApprovedLoan",
                                  billingPlans
                                    .filter(
                                      (item) => item.hybridAppraisalConfigurationId === value
                                    )[0]
                                    .flatPerApprovedLoan.toString()
                                );
                                await onChange(
                                  "validationData",
                                  this.handleBillingPlanChange(value)
                                );
                              }
                            }}
                          />
                        ) : (
                          <SelectInputComponent
                            id="billingPlan"
                            name="billingPlan"
                            divClass={6}
                            value={billingPlan}
                            items={billingPlanInputData}
                            required={true}
                            validated={validated}
                            errors={errors}
                            onChange={async (id, value) => {
                              if (value !== "") {
                                await onChange(id, value);
                                await onChange(
                                  "volumeTier",
                                  billingPlans
                                    .filter(
                                      (item) => item.hybridAppraisalConfigurationId === value
                                    )[0]
                                    .volumeTier.toString()
                                );
                                await onChange(
                                  "subscriptionFrequency",
                                  billingPlans
                                    .filter(
                                      (item) => item.hybridAppraisalConfigurationId === value
                                    )[0]
                                    .subscriptionFrequency.toString()
                                );
                                await onChange(
                                  "price",
                                  billingPlans
                                    .filter(
                                      (item) => item.hybridAppraisalConfigurationId === value
                                    )[0]
                                    .price.toString()
                                );
                                await onChange(
                                  "payAsYouGoPrice",
                                  billingPlans
                                    .filter(
                                      (item) => item.hybridAppraisalConfigurationId === value
                                    )[0]
                                    .payAsYouGoPrice.toString()
                                );
                                await onChange(
                                  "requestLimit",
                                  billingPlans
                                    .filter(
                                      (item) => item.hybridAppraisalConfigurationId === value
                                    )[0]
                                    .requestCount.toString()
                                );
                              }
                            }}
                          />
                        )}
                      </div>
                      {billingType === BillingType[BillingType.PAYG] ? (
                        <Fragment>
                          <div className="form-row">
                            <FormInputComponent
                              id="flatPerRequest"
                              type="number"
                              name="flatPerRequest"
                              divClass={6}
                              placeholder=""
                              value={flatPerRequest}
                              required={true}
                              validated={validated}
                              errors={errors}
                              onChange={onChange}
                              disabled={true}
                            />
                            <FormInputComponent
                              id="flatPerApprovedLoan"
                              type="number"
                              name="flatPerApprovedLoan"
                              divClass={6}
                              placeholder=""
                              value={flatPerApprovedLoan}
                              required={true}
                              validated={validated}
                              errors={errors}
                              onChange={onChange}
                              disabled={true}
                            />
                          </div>
                          <div className="form-row">
                            <label
                              htmlFor="validation"
                              className="col-form-label s-10"
                            >
                              Validation Fees
                            </label>
                            <br />
                            <div className="form-row">
                              {validationOptions.map((data) => (
                                <FormInputComponent
                                  key={data.validationPricingOptionId}
                                  id={data.validationPricingOptionId}
                                  type="number"
                                  name={validationItems.find(option => option.pricingOptionId === data.validationPricingOptionId).name}
                                  placeholder=""
                                  divClass={6}
                                  value={data.fee}
                                  required={true}
                                  validated={validated}
                                  errors={this.state.errors}
                                  onChange={(id, value) => {
                                    onChange('validationData', this.handleValidationChange(id, value))
                                  }}
                                  disabled={true}
                                />
                              ))}
                            </div>
                          </div>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <div className="form-row">
                            <SelectInputComponent
                              id="subscriptionFrequency"
                              name="subscriptionFrequency"
                              divClass={6}
                              value={subscriptionFrequency}
                              items={subscriptionFrequencyInputData}
                              required={true}
                              validated={false}
                              errors={errors}
                              onChange={onChange}
                              disabled={true}
                            />
                            <FormInputComponent
                              id="price"
                              type="number"
                              name="price"
                              divClass={6}
                              placeholder=""
                              value={price}
                              required={true}
                              validated={validated}
                              errors={errors}
                              onChange={onChange}
                              disabled={true}
                            />
                          </div>
                          <div className="form-row">
                            <FormInputComponent
                              id="payAsYouGoPrice"
                              type="number"
                              name="payAsYouGoPrice"
                              divClass={6}
                              placeholder=""
                              value={payAsYouGoPrice}
                              required={true}
                              validated={validated}
                              errors={errors}
                              onChange={onChange}
                              disabled={true}
                            />
                            <FormInputComponent
                              id="requestLimit"
                              type="number"
                              name="requestLimit"
                              divClass={6}
                              placeholder=""
                              value={requestLimit}
                              required={true}
                              validated={validated}
                              errors={errors}
                              onChange={onChange}
                              disabled={true}
                            />
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                {_.isEmpty(walletConfig) ? ("") : (
                  <Button
                    size="sm"
                    variant="secondary"
                    onClick={(e) => {
                      onHandleSubmit(e, ActionTypes.UPDATE, "Activate Institution", () => {
                        this.onFormSubmit({
                          planId,
                          billingPlan,
                          flatPerRequest,
                          flatPerApprovedLoan,
                          validationData,
                          volumeTier,
                          subscriptionFrequency,
                          price,
                          payAsYouGoPrice,
                          requestLimit,
                          institutionType,
                          institutionCategory,
                          createConsent
                        },
                          onReloadFields
                        );
                      });
                    }}
                    disabled={submitting}
                  >
                    {submitting === false ? (
                      <React.Fragment>
                        <i className="fas fa-lg fa-save mr-3" /> Save
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <i className="fas fa-spin fa-circle-notch" />
                      </React.Fragment>
                    )}
                  </Button>
                )}
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => togglePatchModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></ActivateAppraisalInstitutionForm>
      </Modal>
    );
  }
}

export default ActivateAppraisalInstitution;
