import React, { Fragment, useState } from "react";
import institutionsService from "../../../../services/_axiosServices/sweep/institutions";
import { Loader, Error } from "../../components";

const initialData = {
  name: "--",
  shortName: "--",
  code: "--",
  status: "--",
  externalInstitutionCode: "--",
  integrationCode: "--",
  cbnCode: null,
  dmb: false,
  feeIncomeAccount: "--",
  feeExpenseAccount: "--",
  settlementMirrorAccount: "--",
  receivableAccount: "--",
  loanCollectionsAccount: "--",
  suspenseAccount: "--",
  chargeCustomer: false,
  nipBankCode: "--",
  appZoneNIPAccountName: "--",
  appZoneNIPAccountNo: "--",
  sweepInitiationType: "--",
  guarantorSweepInitiationType: "--",
  guarantorMoratoriumDays: 0,
  sweepable: false,
  lostLoanFeePercentage: 0,
  feeChargedPercentage: 0,
  sponsorBankCommission: 0,
  debitedBankCommission: 0,
  allowIncomingLoanUpdateCall: false,
  allowIncomingCostRecognitionCall: false,
  settlementAccounts: [],
  disableNIPSettlement: true,
  totalInflow: 0,
  totalOutflow: 0,
  thresholdBaseLine: 0,
  threshold: 0,
  feeConfigs: [],
  id: 1,
};

export default function Report() {
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [data, setData] = useState(initialData);
  const [error, setError] = useState(false);

  const fetchData = async () => {
    if (id.length < 1) { 
      return;
    }
    setLoading(true);
    await institutionsService
      .retrieveById(id)
      .then((res: any) => {
        // console.log(res);
        setData(res.data);
        setLoading(false);
      })
      .catch((err: any) => {
        setError(true);
        setLoading(false);
        console.log(err.message);
      });
  };

  return (
    <div className="container-fluid relative animatedParent animateOnce">
      <div className="animated fadeInUpShort go">
        <div className="row my-3 mx-2">
          <h3>
            <b>Institutions -  Find By ID</b>
          </h3>
        </div>
        <div className="card no-b">
          <div className="card-body">
            <div className="card-title">
              <div className="page">
                <div className="page-inner">
                  <div className="btn-toolbar justify-content-between">
                    <div className="btn-group">
                      <input
                        name="id"
                        onChange={(e) => setId(e.target.value)}
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="ID"
                        required
                      />
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={fetchData}
                      >
                        Find
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive text-nowrap">
                    {loading && <Loader />}

                    {!loading && !error && (
                      <table className="table table-hover table-content table-striped table-bordered table-sm paged-output">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Short Name</th>
                            <th>Code</th>
                            <th>Status</th>
                            <th>External Institution Code</th>
                            <th>Integration Code</th>
                            <th>CBN Code</th>
                            <th>DMB</th>
                            <th>Fee Income Account</th>
                            <th>Fee Expense Account</th>
                            <th>Settlement Mirror Account</th>
                            <th>Receivable Account</th>
                            <th>Loan Collections Account</th>
                            <th>Suspense Account</th>
                            <th>Charge Customer</th>
                            <th>NIP Bank Code</th>
                            <th>AppZone NIP Account Name</th>
                            <th>AppZone NIP Account No</th>
                            <th>Sweep Initiation Type</th>
                            <th>Guarantor Sweep Initiation Type</th>
                            <th>Guarantor Moratorium Days</th>
                            <th>Sweepable</th>
                            <th>Lost Loan Fee Percentage</th>
                            <th>Fee Charged Percentage</th>
                            <th>Sponsor Bank Commission</th>
                            <th>Debited Bank Commission</th>
                            <th>Allow Incoming Loan Update Call</th>
                            <th>Allow Incoming Cost Recognition Call</th>
                            <th>Disable NIP Settlement</th>
                            <th>Total Inflow</th>
                            <th>Total Outflow</th>
                            <th>Threshold BaseLine</th>
                            <th>Threshold</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {/* <th scope="row">{i + 1}</th> */}
                            <td>{data.id}</td>
                            <td>{data.name}</td>
                            <td>{data.shortName}</td>
                            <td>{data.code}</td>
                            <td>{data.status}</td>
                            <td>{data.externalInstitutionCode}</td>
                            <td>{data.integrationCode}</td>
                            <td>{data.cbnCode}</td>
                            <td>{data.dmb ? "Yes" : "No"}</td>
                            <td>{data.feeIncomeAccount}</td>
                            <td>{data.feeExpenseAccount}</td>
                            <td>{data.settlementMirrorAccount}</td>
                            <td>{data.receivableAccount}</td>
                            <td>{data.loanCollectionsAccount}</td>
                            <td>{data.suspenseAccount}</td>
                            <td>{data.chargeCustomer ? "Yes" : "No"}</td>
                            <td>{data.nipBankCode}</td>
                            <td>{data.appZoneNIPAccountName}</td>
                            <td>{data.appZoneNIPAccountNo}</td>
                            <td>{data.sweepInitiationType}</td>
                            <td>{data.guarantorSweepInitiationType}</td>
                            <td>{data.guarantorMoratoriumDays}</td>
                            <td>{data.sweepable}</td>
                            <td>{data.lostLoanFeePercentage}</td>
                            <td>{data.feeChargedPercentage}</td>
                            <td>{data.sponsorBankCommission}</td>
                            <td>{data.debitedBankCommission}</td>
                            <td>
                              {data.allowIncomingLoanUpdateCall ? "Yes" : "No"}
                            </td>
                            <td>
                              {data.allowIncomingCostRecognitionCall
                                ? "Yes"
                                : "No"}
                            </td>
                            <td>{data.disableNIPSettlement ? "Yes" : "No"}</td>
                            <td>{data.totalInflow}</td>
                            <td>{data.totalOutflow}</td>
                            <td>{data.thresholdBaseLine}</td>
                            <td>{data.threshold}</td>
                          </tr>
                        </tbody>
                      </table>
                    )}

                    {error && !loading && <Error />}
                  </div>

                  {!loading && !error && (
                    <Fragment>
                      <br />
                      <h4>Settlement Accounts</h4>
                      <div className="table-responsive text-nowrap">
                        <table className="table table-hover table-content table-striped table-bordered table-sm paged-output">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>id</th>
                              <th>OFI Institution</th>
                              <th>Settlement Institution</th>
                              <th>Account No</th>
                              <th>CBN Code</th>
                              <th>No Of Days After Final Settlement</th>
                              <th>Default</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.settlementAccounts?.map(
                              (a: any, i: number) => (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>{a.ofiInstitution}</td>
                                  <td>{a.settlementInstitution}</td>
                                  <td>{a.accountNo}</td>
                                  <td>{a.cbnCode}</td>
                                  <td>{a.noOfDaysAfterFinalSettlement}</td>
                                  <td>{a.isDefault}</td>
                                  <td>{a.id}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>

                      <br />
                      <h4>Fee Configurations</h4>
                      <div className="table-responsive text-nowrap">
                        <table className="table table-hover table-content table-striped table-bordered table-sm paged-output">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>id</th>
                              <th>Institution Id</th>
                              <th>Institution Code</th>
                              <th>Active Loans Percentage</th>
                              <th>Lost Loans Percentage</th>
                              <th>Flat Amount</th>
                              <th>Minimum Fee</th>
                              <th>Maximum Fee</th>
                              <th>Sponsor Bank Commission</th>
                              <th>Debited Bank Commission</th>
                              <th>Repayment Type</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.feeConfigs?.map((c: any, i: number) => (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{c.id}</td>
                                <td>{c.institutionId}</td>
                                <td>{c.institutionCode}</td>
                                <td>{c.activeLoansPercentage}</td>
                                <td>{c.lostLoansPercentage}</td>
                                <td>{c.flatAmount}</td>
                                <td>{c.minimumFee}</td>
                                <td>{c.maximumFee}</td>
                                <td>{c.sponsorBankCommission}</td>
                                <td>{c.debitedBankCommission}</td>
                                <td>{c.repaymentType}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
