import React, { Fragment, Component } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../../../../components/detailItem";
import textFormatService from "../../../../../../services/utility/textFormatService";

interface WorkDetailsProps {
  values: any;
  handleChange: any;
  handleBlur: any;
}

interface WorkDetailsState {
  item: any;
}

class WorkDetails extends Component<WorkDetailsProps, WorkDetailsState> {
  constructor(props: WorkDetailsProps) {
    super(props);
    this.state = {
      item: {},
    };
  }

  static getDerivedStateFromProps(nextProps: WorkDetailsProps, prevState) {
    return {
      item: nextProps.values,
    };
  }

  render() {
    const { values } = this.props;

    return (
      <Fragment>
        <div className="card">
          <div className="card-header clear-fix">
            {_.isEmpty(values) ? (
              <Skeleton width={200} />
            ) : (
              <h6
                className="card-title float-left"
                style={{ marginBottom: "0px" }}
              >
                <b>Work Details</b>
              </h6>
            )}
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                {_.isEmpty(values) ? (
                  <Skeleton count={3} width={300} />
                ) : (
                  <dl className="row">
                    <DetailItem
                      label="Employment Industry"
                      labelSize={7}
                      valueSize={5}
                      value={values.employmentIndustry}
                    />
                    <DetailItem
                      label="Employment Status"
                      labelSize={7}
                      valueSize={5}
                      value={values.employmentStatus}
                    />
                    {values.employmentStatus === "SelfEmployed" ? (
                      <DetailItem
                        label="Business Name"
                        labelSize={7}
                        valueSize={5}
                        value={values.businessName}
                      />
                    ) : (<><DetailItem
                      label="Employee Status"
                      labelSize={7}
                      valueSize={5}
                      value={values.employeeStatus} /><DetailItem
                        label="Employer Category"
                        labelSize={7}
                        valueSize={5}
                        value={values.employerCategory} /></>)}


                    <DetailItem
                      label="Years In Current Job/Business"
                      labelSize={7}
                      valueSize={5}
                      value={values.yearsInCurrentJob}
                    />
                    <DetailItem
                      label="Work Address"
                      labelSize={7}
                      valueSize={5}
                      value={values.jobAddress}
                    />
                    <DetailItem
                      label="Work State/Region"
                      labelSize={7}
                      valueSize={5}
                      value={values.jobState}
                    />
                  </dl>
                )}
              </div>
              <div className="col-md-6">
                {_.isEmpty(values) ? (
                  <Skeleton count={3} width={300} />
                ) : (
                  <dl className="row">
                    <DetailItem
                      label="Employment Designation"
                      labelSize={7}
                      valueSize={5}
                      value={values.employmentDesignation}
                    />

                    {values.employmentStatus === "SelfEmployed" ? (
                      <DetailItem
                        label="Business Registration"
                        labelSize={7}
                        valueSize={5}
                        value={values.businessRegistration} />
                    ) : (<DetailItem
                      label="Employer Name"
                      labelSize={7}
                      valueSize={5}
                      value={values.employerName}
                    />)}

                    <DetailItem
                      label="Office Phone Number"
                      labelSize={7}
                      valueSize={5}
                      value={values.officePhoneNumber}
                    />
                    <DetailItem
                      label="Months in Current Job/Business"
                      labelSize={7}
                      valueSize={5}
                      value={values.monthsInCurrentJob}
                    />
                    <DetailItem
                      label="Work City/Town"
                      labelSize={7}
                      valueSize={5}
                      value={values.jobCity}
                    />
                    <DetailItem
                      label="Work Country"
                      labelSize={7}
                      valueSize={5}
                      value={values.jobCountry}
                    />
                  </dl>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default WorkDetails;