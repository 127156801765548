import React, { useContext } from "react";
import "../fam.css";
import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionToggle,
} from "react-bootstrap";
import DetailItem from "../../../../../../../../components/detailItem";
import _, { isEmpty } from "lodash";
import { formatCurrency } from "../../../../../../../../services/utility/textFormatService";

export const FamSummaryExecution = ({
  loan,
  famSummary,
}: {
  loan: any;
  famSummary: any;
}) => {
  console.log("famSummary", famSummary);
  const facilitySummary = famSummary?.facilitySummary;
  const businessSummary = famSummary?.businessSummary;
  const previousBalanceSheet = famSummary?.previousBalanceSheet;
  const currencyRisk = famSummary?.currencyRisk;
  const others = famSummary?.others;

  const period = (n: number) => {
    return n > 1 ? " days" : " day";
  };

  return (
    <div className="col-md-12 mb-4">
      <div className="card no-b">
        <div className="row">
          <div className="col-md-12">
            <Container
              title="Facility Summary"
              body={
                <>
                  {!isEmpty(facilitySummary) && (
                    <div
                      className="table-responsive text-nowrap"
                      style={{ height: "300px", overflowY: "scroll" }}
                    >
                      <div>
                        <dl
                          className="col-grid-2"
                          style={{ marginBottom: "0px", gap: 1 }}
                        >
                          <DetailItem
                            label="Loan Officer"
                            value={facilitySummary?.loanOfficer}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Loan Number"
                            value={facilitySummary?.loanNumber}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Preparation Date"
                            value={facilitySummary?.preparationDate}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Interview Date"
                            value={facilitySummary?.interviewDate}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Site Visit Date"
                            value={facilitySummary?.siteVisitDate}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Disbursal Month"
                            value={facilitySummary?.disbursalMonth}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Loan Currency"
                            value={facilitySummary?.loanCurrency}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Requested Grace Period"
                            value={facilitySummary?.requestedGracePeriod}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Previous No Of Institution Loans"
                            value={
                              facilitySummary?.previousNoOfInstitutionLoans
                            }
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Loan Purpose"
                            value={facilitySummary?.loanPurpose}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Equity Contribution"
                            value={formatCurrency(
                              facilitySummary?.equityContribution,
                              2
                            )}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Net Risk Exposure"
                            value={formatCurrency(
                              facilitySummary?.netRiskExposure,
                              2
                            )}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Requested Loan Amount"
                            value={formatCurrency(
                              facilitySummary?.requestedLoanAmount,
                              2
                            )}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Proposed Loan Amount"
                            value={formatCurrency(
                              facilitySummary?.proposedLoanAmount,
                              2
                            )}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Proposed Tenor"
                            value={
                              facilitySummary?.proposedTenor +
                              period(facilitySummary?.proposedTenor)
                            }
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Requested Loan Tenor"
                            value={
                              facilitySummary?.requestedLoanTenor +
                              period(facilitySummary?.requestedLoanTenor)
                            }
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Recommended Grace Period"
                            value={
                              facilitySummary?.recommendedGracePeriod +
                              period(facilitySummary?.recommendedGracePeriod)
                            }
                            labelSize={6}
                            valueSize={6}
                          />
                          {/* <DetailItem
                            label="Proposed Grace Period"
                            value={
                              facilitySummary?.proposedGracePeriod +
                              period(facilitySummary?.proposedGracePeriod)
                            }
                            labelSize={6}
                            valueSize={6}
                          /> */}
                          <DetailItem
                            label="Monthly Repayment"
                            value={formatCurrency(
                              facilitySummary?.monthlyRepayment,
                              2
                            )}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Interest Rate"
                            value={loan?.loanDetails?.interestRate + " %"}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Recommended Interest Rate"
                            value={
                              facilitySummary?.recommendedInterestRate + " %"
                            }
                            labelSize={6}
                            valueSize={6}
                          />
                        </dl>
                        <br />
                      </div>
                    </div>
                  )}
                </>
              }
            />
            <br />
            <Container
              title="Business Summary"
              body={
                <>
                  {!isEmpty(businessSummary) && (
                    <div
                      className="table-responsive text-nowrap"
                      style={{ height: "250px", overflowY: "scroll" }}
                    >
                      <div>
                        <dl
                          className="col-grid-2"
                          style={{ marginBottom: "0px", gap: 1 }}
                        >
                          <DetailItem
                            label="Business Name"
                            value={loan?.companyName}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Client Name"
                            value={businessSummary?.clientName}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Business Address"
                            value={loan?.address}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Main Activity"
                            value={businessSummary?.mainActivity}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Experience In Years"
                            value={businessSummary?.experienceInYears?.year}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Experience In Months"
                            value={businessSummary?.experienceInMonths}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Number Of Staff"
                            value={businessSummary?.numberOfStaff}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Number Of Dependents"
                            value={businessSummary?.numberOfDependents}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Ownership Structure"
                            value={businessSummary?.ownershipStructure}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Manager Name"
                            value={businessSummary?.managerName}
                            labelSize={6}
                            valueSize={6}
                          />
                        </dl>
                        <br />
                      </div>
                    </div>
                  )}
                </>
              }
            />
            <br />
            <Container
              title="Founders"
              body={
                <div
                  className="table-responsive text-nowrap"
                  style={{ height: "100px", overflowY: "scroll" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    {businessSummary?.founders?.map(({ name, stake }) => (
                      <dl
                        className="col-grid-2"
                        style={{ marginBottom: "0px", gap: 1 }}
                      >
                        <DetailItem
                          label="Name"
                          value={name}
                          labelSize={6}
                          valueSize={6}
                        />
                        <DetailItem
                          label="Stake"
                          value={stake}
                          labelSize={6}
                          valueSize={6}
                        />
                      </dl>
                    ))}
                    <br />
                  </div>
                </div>
              }
            />
            <br />
            <Container
              title="Currency Risk"
              body={
                <div
                  className="table-responsive text-nowrap"
                  style={{ height: "100px", overflowY: "scroll" }}
                >
                  <div>
                    <dl
                      className="col-grid-2"
                      style={{ marginBottom: "0px", gap: 1 }}
                    >
                      <DetailItem
                        label="Risk"
                        value={currencyRisk?.risk}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Category"
                        value={currencyRisk?.category}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Rating"
                        value={currencyRisk?.rating}
                        labelSize={6}
                        valueSize={6}
                      />
                    </dl>
                    <br />
                  </div>
                </div>
              }
            />
            <br />
            <Container
              title="Swot Analysis"
              body={
                <>
                  {!!others?.strengthsAndOpportunities ||
                  !!others?.threatsAndWeaknesses ? (
                    <div
                      className="table-responsive text-nowrap"
                      style={{ height: "85px", overflowY: "scroll" }}
                    >
                      <div>
                        <dl
                          className="row"
                          style={{
                            marginBottom: "0px",
                            marginLeft: 20,
                            marginRight: 20,
                            gap: 1,
                          }}
                        >
                          <div>
                            <b style={{ fontWeight: 600 }}>
                              Strength & Opportunities
                            </b>
                            <p className="text-wrap">
                              {others?.strengthsAndOpportunities}
                            </p>
                          </div>
                          <div>
                            <b style={{ fontWeight: 600 }}>
                              Weakness & Threats
                            </b>
                            <p className="text-wrap">
                              {others?.threatsAndWeaknesses}
                            </p>
                          </div>
                        </dl>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        color: "red",
                      }}
                      className="py-2"
                    >
                      <i>
                        No Strength, Opportunities, Weakness or Threats
                        available
                      </i>
                    </div>
                  )}
                </>
              }
            />
            <br />
            <Container
              title="Comments and Conclusions"
              body={
                <>
                  {!!others?.commentsAndConclusions ? (
                    <div>
                      <p style={{ paddingLeft: 20, paddingRight: 20 }}>
                        {others?.commentsAndConclusions}
                      </p>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        color: "red",
                      }}
                      className="py-2"
                    >
                      <i>No Comments or Conclusions available</i>
                    </div>
                  )}
                </>
              }
            />
            <br />

            <Container
              title="Loan officer assessment based on applicant's character
                          and subjective analysis of the business"
              body={
                <>
                  {!!others?.assessmentRating || !!others?.assessmentComment ? (
                    <div style={{ height: "100px", overflowY: "scroll" }}>
                      <div>
                        <dl style={{ marginBottom: "0px", gap: 1 }}>
                          <div
                            style={{
                              display: "flex",
                              marginBottom: 10,
                            }}
                          >
                            <b
                              style={{
                                marginLeft: 16,
                                fontWeight: 600,
                                paddingRight: 10,
                              }}
                            >
                              Rating (1-10):
                            </b>
                            <p
                              style={{ lineHeight: "25px" }}
                              className="text-wrap"
                            >
                              {others?.assessmentRating}
                            </p>
                          </div>
                          <div style={{ display: "flex" }}>
                            <b
                              style={{
                                marginLeft: 16,
                                fontWeight: 600,
                                paddingRight: 10,
                              }}
                            >
                              Comments:
                            </b>
                            <p
                              style={{ lineHeight: "25px" }}
                              className="text-wrap"
                            >
                              {others?.assessmentComment}
                            </p>
                          </div>
                        </dl>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        color: "red",
                      }}
                      className="py-2"
                    >
                      <i>No Assessment rating or comments available</i>
                    </div>
                  )}
                </>
              }
            />
            <br />
            <Container
              title="Previous Balance Sheet"
              body={
                <>
                  {!_.isEmpty(previousBalanceSheet) ? (
                    <div style={{ width: "100%" }} className="clearfix">
                      <div className=" form-row col-md-12">
                        <div
                          className="table-responsive text-nowrap"
                          style={{
                            marginTop: 20,
                            overflowY: "scroll",
                            border: "0.5px solid #ccc",
                          }}
                        >
                          <table className="table table-hover table-content table-sm table-striped mb-0">
                            <thead>
                              <tr>
                                {tHead([
                                  "#",
                                  "Year",
                                  "Month",
                                  "Loan Amount",
                                  "Tenor",
                                  "Owner Equity",
                                ])}
                              </tr>
                            </thead>
                            <tbody>
                              {previousBalanceSheet?.map((item, index) => (
                                <tr key={index}>
                                  {tData([
                                    index + 1,
                                    item?.year,
                                    item?.month,
                                    formatCurrency(item?.loanAmount, 2),
                                    item?.tenor,
                                    formatCurrency(item?.ownerEquity, 2),
                                  ])}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        color: "red",
                      }}
                      className="py-2"
                    >
                      <i>No Balance Sheet available</i>
                    </div>
                  )}
                </>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const tHead = (texts: (string | number)[]) => (
  <>
    {texts?.map((text: any) => (
      <th
        key={text}
        scope="col"
        style={{
          paddingTop: "0.2rem",
          paddingBottom: "0.2rem",
        }}
      >
        <strong>{text}</strong>
      </th>
    ))}
  </>
);
const tData = (texts: (string | number)[]) => (
  <>
    {texts?.map((text: any) => (
      <td
        style={{
          paddingTop: "0.2rem",
          paddingBottom: "0.2rem",
          fontSize: "small",
        }}
      >
        {text}
      </td>
    ))}
  </>
);

const ContextAwareToggle = ({ eventKey, header }) => {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      <b>{header}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
};

const Container = ({
  title,
  body,
}: {
  title: string;
  body: React.ReactNode;
}) => {
  return (
    <div className="card">
      <Accordion defaultActiveKey="0">
        <div>
          <Card>
            <ContextAwareToggle
              eventKey={`0`}
              header={
                <React.Fragment>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    style={{ color: "#878a8a" }}
                  ></i>{" "}
                  {title}
                </React.Fragment>
              }
            ></ContextAwareToggle>
            <Accordion.Collapse eventKey={`0`}>
              <Card.Body>
                <div
                  style={{
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  <div className="row">{body}</div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </div>
      </Accordion>
    </div>
  );
};
