import axios from "axios";
import _ from "lodash";
import { toast } from "react-toastify";
import { getCurrentUserSession, logout } from "./auth/authService";
import { decodeToken } from "react-jwt";

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status <= 500;

  if (!expectedError) {
    // console.log("an unexpected error occured", error);
    // alert("An unexpected error occured");
  } else {
    if (error.response.status === 401) {
      // console.log("Unauthorized Request");
      logout();
      window.location.reload();
      // window.location.replace("/");
      // toast.error("Unauthorized Request");
    }
    // console.log("error: ", error.response.data);
    if (
      error.response.data.Message === "Expired User Session." ||
      error.response.data.message === "Expired User Session." ||
      (error.response.data.item1 === false &&
        error.response.data.item2 === "Expired User Session.") ||
      (error.response.data.item1 === false &&
        error.response.data.item2 === "Session Not Found.")
    ) {
      // console.log("pushing user out...");
      logout();
      // window.reload();
      // window.location.replace("/");
    }
    if (_.has(error.response.data, "Message")) {
      // console.log("check1");
      toast.error(error.response.data.Message);
    } else {
      // console.log("check2", error);
      // toast.error(error.response.data.message);
    }
    // switch (error.response.status) {
    //   case 400:
    //   case 404:
    //     // toast.error(error.response.data);
    //     break;
    //   case 500:
    //     // toast.error(error.response.data.Message);
    //     break;
    //   default:
    //     toast.error(error.response.data.Message);
    //     break;
    // }
  }
  return Promise.reject(error);
});

export function getCurrentUserHeader() {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  let config = {
    headers: {
      userId: decodedToken?.["UserId"],
      Authorization: `Bearer ${getCurrentUserSession().token}`,
      // userRoleId: authService.getCurrentUser.userRoleId,
      // institutionId: authService.getCurrentUser.institutionId
    },
  };
  return config;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
  getCurrentUserHeader,
};
