import React, { Component } from "react";
import resume from "../../images/icons/icons8-new-resume-template-48.png";
import cash from "../../images/icons/icons8-cash-48.png";
import stack_of_money from "../../images/icons/icons8-stack-of-money-48.png";
import { Line, Bar, Pie } from "react-chartjs-2";
import { generateRandomRGBA, shortenNumber } from "../../services/utility/textFormatService";
// import CountUp from "react-countup";
import mandateAgentService from "../../services/influxagent/mandateagentService";
import textFormatService from "../../services/utility/textFormatService";
import PaginatedBarChart from "./paginatedBarChart";
import bvnAgentService from "../../services/influxagent/bvnAgentService";
import mandateStatsService from "../../services/influxagent/mandateStatsService";
// import { decodeToken } from "react-jwt";
// import dashboardService from "../../services/email/dashboardService";
import DetailItemSelectComponent from "../detailItemSelectBox";
import DetailItemInputComponent from "../detailItemInputBox";
import sweepAgentService from "../../services/influxagent/sweepAgentService";
import creditAssessmentService from "../../services/influxagent/creditAssessmentService";
import collectionService from "../../services/influxagent/collectionService";
import institutionsService from "../../services/auth/institutionsService";
import ShareModal from "./shareModal";
import * as htmlToImage from "html-to-image";
import loanRequestService from "../../services/creditAssessment/loanRequestService";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../services/auth/authService";
import { date, required } from "joi";

export interface IAppzoneDashboardProps {
  // checker: any;
}

export interface IAppzoneDashboardState {
  activeMandatesCount: number;
  activeMandatesValue: number;
  cancelledMandatesCount: number;
  cancelledMandatesValue: number;
  allMandatesDetails: any;
  allCollectionDueDetails: any;
  amountCollectedStats: any;
  allLinkedBVNs: any;
  linkedBVNCount: number;
  recoveryTransactionCount: number;
  amountAttemptedValue: number;
  amountRecoveredValue: number;
  recoveryEfficiency: number;
  allRecoveryDetails: any;
  allLinkedAccountDetails: any;
  linkedBVNAccountCount: number;
  linkedAccountCount: number;
  fetchingMandateData: any;
  fetchingRecoveryData: any;
  fetching: boolean;
  creditassessmentLoanRequests: any;
  creditassessmentDisbursedloans: any;
  creditassessmentDisbursedVolume: any;
  creditassessmentCQefficiency: any;
  collectionDue: any,
  amountCollected: any,
  collectionChannel: any,
  recoverySwitch: any,
  currentPage: number;
  totalSize: number;
  fetchingMandateStats: boolean;
  fetchingRecoveryStats: boolean;
  fetchingLinkedAccountStats: boolean;
  institutions: any;
  fretchingInstitutions: boolean;
  pendingSettlementValue: number;
  successfulSettlementValue: number;
  rangeInDays: string;
  datePicked: string;
  datePicked_: string;
  mandateStats: any;
  recoveryStats: any;
  linkedAccountStats: any;
  allBlackboxLoanRequests: any;
  blackboxLoanRequestCount: number;
  blackboxLoanRequestAmount: number;
  fetchingBlackboxLoanRequests: boolean;
  items: { [name: string]: string };
  showShareModal: boolean;
  dashboard: string;
}
var today = new Date();
var dd = today.getDate();
var mm = today.getMonth()+1; 
var yyyy = today.getFullYear();
if(dd<10) 
{
    dd=0+dd;
} 
if(mm<10) 
{
    mm=0+mm;
} 

var formattedToday = yyyy+'-'+mm+'-'+dd;
class AppzoneDashboard extends Component<
  IAppzoneDashboardProps,
  IAppzoneDashboardState
> {
  
  constructor(props: IAppzoneDashboardProps) {
    super(props);
    this.state = {
      activeMandatesCount: 0,
      activeMandatesValue: 0,
      cancelledMandatesCount: 0,
      cancelledMandatesValue: 0,
      allMandatesDetails: [],
      allCollectionDueDetails: [],
      amountCollectedStats: [],
      allLinkedBVNs: [],
      linkedBVNCount: 0,
      recoveryTransactionCount: 0,
      amountAttemptedValue: 0,
      amountRecoveredValue: 0,
      recoveryEfficiency: 0,
      allRecoveryDetails: [],
      allLinkedAccountDetails: [],
      linkedBVNAccountCount: 0,
      linkedAccountCount: 0,
      fetchingMandateData: true,
      fetchingRecoveryData: true,
      fetching: true,
      currentPage: 1,
      totalSize: 0,
      creditassessmentLoanRequests: [],
      creditassessmentDisbursedloans: [],
      creditassessmentDisbursedVolume: [],
      creditassessmentCQefficiency: [],
      collectionDue: [],
      amountCollected: [],
      collectionChannel: [],
      recoverySwitch: [],
      fetchingMandateStats: true,
      fetchingRecoveryStats: true,
      fetchingLinkedAccountStats: true,
      institutions: [],
      fretchingInstitutions: true,
      pendingSettlementValue: 0,
      successfulSettlementValue: 0,
      rangeInDays: "30",
      datePicked: formattedToday,
      datePicked_: formattedToday,
      mandateStats: [],
      recoveryStats: [],
      linkedAccountStats: [],
      allBlackboxLoanRequests: [],
      blackboxLoanRequestCount: 0,
      blackboxLoanRequestAmount: 0,
      fetchingBlackboxLoanRequests: true,
      items: {},
      showShareModal: false,
      dashboard: "",
    };
  }

  async componentDidMount() {
    // retrieveMandateDetailsTickers
    // const allMandatesDetails = await mandateAgentService.retrieveAllInstitutionsMandateDetails();

    const decodedToken = decodeToken(getCurrentUserSession().token || "");

    const response = await Promise.allSettled([
      institutionsService.retrieveAllInstitutions(),
      mandateAgentService.retrieveMandateDetailsTickers(),
      mandateStatsService.retrieveAllGeneralMandateStatResponse(this.state.rangeInDays),
      bvnAgentService.retrieveAllInstitutionsBVNLinkingDetails(),
      sweepAgentService.retrieveRecoveryDetailsTickers(),
      sweepAgentService.retrieveRecoveryStats(this.state.rangeInDays),
      sweepAgentService.retrievePendingSettlement(),
      sweepAgentService.retrieveSuccessfulSettlement(),
      bvnAgentService.retrieveLinkedAccountStats(this.state.rangeInDays),
      bvnAgentService.retrieveAllInstitutionsLinkedAccountCount(),
      loanRequestService.retrieveAllBlackboxRequests(),
      loanRequestService.retrieveInstitutionBlackboxRequestsCount(decodedToken?.["InstitutionCode"] || ""),
      loanRequestService.retrieveInstitutionBlackboxRequestsAmount(decodedToken?.["InstitutionCode"] || ""),
      creditAssessmentService.retrieveAllCQefficiency(),
      creditAssessmentService.retrieveAllDisbursedLoanCount(),
      creditAssessmentService.retrieveAllDisbursedLoanVolume(),
      creditAssessmentService.retrieveAllLoanRequestCount(),
      collectionService.retrieveAllCollectionDue(),
      collectionService.retrieveAllAmountCollected(),
      collectionService.retrieveAllCollectionChannel(),
      collectionService.retrieveAllRecoverySwitch(),
      collectionService.retrieveAllCollectionDueStats(this.state.datePicked_),
      collectionService.retrieveAmountCollectedStats(this.state.datePicked)
    ])
    const institutions = response[0];
    const allStats = response[1];
    const mandateStats = response[2];
    const allLinkedBVNs = response[3];
    const allRecoveryDetails = response[4];
    const recoveryStats = response[5];
    const pendingSettlementValue = response[6];
    const successfulSettlementValue = response[7];
    const linkedAccountStats = response[8];
    const allLinkedAccountStats = response[9];
    const allBlackboxLoanRequests = response[10];
    const blackboxLoanRequestCount = response[11];
    const blackboxLoanRequestAmount = response[12];
    const creditassessmentCQefficiency = response[13];
    const creditassessmentDisbursedloans = response[14];
    const creditassessmentDisbursedVolume = response[15];
    const creditassessmentLoanRequests = response[16];
    const collectionDue = response[17];
    const amountCollected = response[18];
    const collectionChannel = response[19];
    const recoverySwitch = response[20];
    const allCollectionDueDetails = response[21];
    const amountCollectedStats = response[22];

    // console.log(formattedToday);

    // const linkedBVNCount = await bvnAgentService.retrieveLinkedBVNsCount()

    const activeMandatesCount = allStats.status == "fulfilled" ? allStats.value.data.activeMandatesCount : 0;
    const activeMandatesValue = allStats.status == "fulfilled" ? allStats.value.data.activeMandatesValue : 0;
    const cancelledMandatesCount = allStats.status == "fulfilled" ? allStats.value.data.cancelledMandatesCount : 0;
    const cancelledMandatesValue = allStats.status == "fulfilled" ? allStats.value.data.cancelledMandatesValue : 0;

    const recoveryTransactionCount = allRecoveryDetails.status == "fulfilled" ? allRecoveryDetails.value.data.recoveryTransactionCount : 0;
    const amountAttemptedValue = allRecoveryDetails.status == "fulfilled" ? allRecoveryDetails.value.data.amountAttemptedValue : 0;
    const amountRecoveredValue = allRecoveryDetails.status == "fulfilled" ? allRecoveryDetails.value.data.amountRecoveredValue : 0;
    const recoveryEfficiency = allRecoveryDetails.status == "fulfilled" ? allRecoveryDetails.value.data.recoveryEfficiency : 0;

    const linkedBVNAccountCount = allLinkedAccountStats.status == "fulfilled" ? allLinkedAccountStats.value.data.noOfBvns : 0;
    const linkedAccountCount = allLinkedAccountStats.status == "fulfilled" ? allLinkedAccountStats.value.data.noOfAccounts : 0;
   

    this.setState({
      institutions: institutions.status == "fulfilled" ? institutions.value.data : [],
      activeMandatesCount,
      activeMandatesValue,
      cancelledMandatesCount,
      cancelledMandatesValue,
      recoveryTransactionCount,
      amountAttemptedValue,
      amountRecoveredValue,
      recoveryEfficiency,
      // allMandatesDetails: allMandatesDetails.data,
      allLinkedBVNs: (allLinkedBVNs.status == "fulfilled" ? allLinkedBVNs.value.data : []).filter(
        (item) => item.institutionId !== 1
      ),
      // allRecoveryDetails: allRecoveryDetails.data,
      // linkedBVNCount: linkedBVNCount.data,
      pendingSettlementValue: pendingSettlementValue.status == "fulfilled" ? pendingSettlementValue.value.data : 0,
      successfulSettlementValue: successfulSettlementValue.status == "fulfilled" ? successfulSettlementValue.value.data : 0,
      linkedBVNAccountCount,
      linkedAccountCount,
      fetchingMandateData: false,
      fetchingRecoveryData: false,
      mandateStats: (mandateStats.status == "fulfilled" ? mandateStats.value.data : []).reverse(),
      recoveryStats: recoveryStats.status == "fulfilled" ? recoveryStats.value.data : [],
      linkedAccountStats: linkedAccountStats.status == "fulfilled" ? linkedAccountStats.value.data : [],
      allBlackboxLoanRequests: allBlackboxLoanRequests.status == "fulfilled" ? allBlackboxLoanRequests.value.data : [],
      blackboxLoanRequestCount: blackboxLoanRequestCount.status == "fulfilled" ? blackboxLoanRequestCount.value.data : 0,
      blackboxLoanRequestAmount: blackboxLoanRequestAmount.status == "fulfilled" ? blackboxLoanRequestAmount.value.data : 0,
      creditassessmentLoanRequests: creditassessmentLoanRequests.status == "fulfilled" ? creditassessmentLoanRequests.value.data : 0,
      creditassessmentCQefficiency: creditassessmentCQefficiency.status == "fulfilled" ? creditassessmentCQefficiency.value.data : 0,
      creditassessmentDisbursedloans: creditassessmentDisbursedloans.status == "fulfilled" ? creditassessmentDisbursedloans.value.data : 0,
      creditassessmentDisbursedVolume: creditassessmentDisbursedVolume.status == "fulfilled" ? creditassessmentDisbursedVolume.value.data : 0,
      collectionDue: collectionDue.status == "fulfilled" ? collectionDue.value.data : 0,
      amountCollected: amountCollected.status == "fulfilled" ? amountCollected.value.data : 0,
      collectionChannel: collectionChannel.status == "fulfilled" ? collectionChannel.value.data : 0,
      recoverySwitch: recoverySwitch.status == "fulfilled" ? recoverySwitch.value.data : 0,
      allCollectionDueDetails: allCollectionDueDetails.status == "fulfilled" ? allCollectionDueDetails.value.data : [],
      amountCollectedStats: amountCollectedStats.status == "fulfilled" ? amountCollectedStats.value.data : [],
      fetchingMandateStats: false,
      fetchingRecoveryStats: false,
      fretchingInstitutions: false,
      fetchingLinkedAccountStats: false,
      fetchingBlackboxLoanRequests: false


      
    });
    
    // console.log(allLinkedBVNs);
    this.handlePageChange(this.state.currentPage);
  }

  handleOnRangeInputChange = async (value) => {
    if (value === "") {
      return;
    }
  
  
    // console.log(value);
    this.setState({
      rangeInDays: value,
      fetchingMandateStats: true,
      fetchingRecoveryStats: true,
      fetchingLinkedAccountStats: true,
    });

  

    // const decodedToken = decodeToken(getCurrentUserSession().token || "");
    const response = await Promise.all([
      mandateStatsService.retrieveAllGeneralMandateStatResponse(value),
      sweepAgentService.retrieveRecoveryStats(value),
      bvnAgentService.retrieveLinkedAccountStats(value)
    ])
    const mandateStats = response[0];
    const recoveryStats = response[1];
    const linkedAccountStats = response[2]

    // const loanrequestStats = creditassessmentLoanRequests.filter((item) => item.value);

    this.setState({
      mandateStats: mandateStats.data.reverse(),
      recoveryStats: recoveryStats.data,
      linkedAccountStats: linkedAccountStats.data,
      fetchingMandateStats: false,
      fetchingRecoveryStats: false,
      fetchingLinkedAccountStats: false
    });
  };

  handlePageChange = async (currentPage: number) => {
    // console.log("Before:  ", `page->${currentPage}; data->${data}`);
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    this.setState({ fetching: true });
    try {
      const allMandatesDetails =
        await mandateAgentService.filterPagedInstitutionsMandateDetails(
          20,
          currentPage
        );
      this.setState({
        allMandatesDetails: allMandatesDetails.data.item2,
        currentPage,
        totalSize: allMandatesDetails.data.item1,
        fetching: false,
      });
    } catch (ex) {
      // if (
      //   ex.response &&
      //   ex.response.status >= 400 &&
      //   ex.response.status <= 499
      // ) {
      //   this.setState({ fetching: false });
      // } else {
      //   // toast.error("Internal server error. Please contact the admin.");
      //   this.setState({ fetching: false });
      // }
    } finally {
      this.setState({ fetching: false });
    }
  };

  handledatePickedInputChange = async (datePicked: string) => {
    // console.log("Before:  ", `page->${currentPage}; data->${data}`);
    // currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    this.setState({ fetching: true });
    try {
      const amountCollectedStats =
        await collectionService.retrieveAmountCollectedStats(
          datePicked
        );
      this.setState({
        amountCollectedStats: amountCollectedStats.data
      });
    } catch (ex) {
      // if (
      //   ex.response &&
      //   ex.response.status >= 400 &&
      //   ex.response.status <= 499
      // ) {
      //   this.setState({ fetching: false });
      // } else {
      //   // toast.error("Internal server error. Please contact the admin.");
      //   this.setState({ fetching: false });
      // }
    } finally {
      this.setState({ datePicked: datePicked });
    }
  };

  handledatePickedInputChange_ = async (datePicked_: string) => {
    // console.log("Before:  ", `page->${currentPage}; data->${data}`);
    // currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    this.setState({ fetching: true });
    try {
      const allCollectionDueDetails =
        await collectionService.retrieveAllCollectionDueStats(
          datePicked_
        );
      this.setState({
        allCollectionDueDetails: allCollectionDueDetails.data
      });
    } catch (ex) {
      // if (
      //   ex.response &&
      //   ex.response.status >= 400 &&
      //   ex.response.status <= 499
      // ) {
      //   this.setState({ fetching: false });
      // } else {
      //   // toast.error("Internal server error. Please contact the admin.");
      //   this.setState({ fetching: false });
      // }
    } finally {
      this.setState({ datePicked_: datePicked_ });
    }
  };
  

  toggleShowShareModal = async () => {
    const items = this.state.items;

    const response = await Promise.all([
      htmlToImage.toJpeg(document.getElementById("v-pills-tabContent1")!),
      htmlToImage.toPng(document.getElementById("mandateHeaders")!),
      htmlToImage.toPng(document.getElementById("pieCountStatistics")!),
      htmlToImage.toPng(document.getElementById("dailyMandatesChart")!),
      htmlToImage.toPng(document.getElementById("dailyMandatesTrend")!),
      htmlToImage.toPng(document.getElementById("linkingImage1")!),
      htmlToImage.toPng(document.getElementById("linkedAccountsHeaders")!),
      htmlToImage.toPng(document.getElementById("linkedAccountsTrends")!),
      htmlToImage.toPng(document.getElementById("recoveryHeaders")!),
      htmlToImage.toPng(document.getElementById("recoveryTrends")!),
      htmlToImage.toPng(document.getElementById("institutionsHeaders")!),
      htmlToImage.toPng(document.getElementById("institutionsChart")!),
      htmlToImage.toPng(document.getElementById("settlementHeaders")!)
    ])
    items.pngAttachment = response[0];
    items.mandateHeaders = response[1];
    items.pieCountStatistics = response[2];
    items.dailyMandatesChart = response[3];
    items.dailyMandatesTrend = response[4];
    items.linkingImage1 = response[5];
    items.linkedAccountsHeaders = response[6];
    items.linkedAccountsTrends = response[7];
    items.recoveryHeaders = response[8];
    items.recoveryTrends = response[9];
    items.institutionsHeaders = response[10];
    items.institutionsChart = response[11];
    items.settlementHeaders = response[12];

    this.setState({
      // items,
      showShareModal: !this.state.showShareModal,
    });
  };

  render() {
    // const [image, setImage] = useState(null);
    // const ref = useRef();
    // const chartRef = useRef(null);
    const {
      allMandatesDetails,
      allCollectionDueDetails,
      amountCollectedStats,
      allLinkedBVNs,
      mandateStats,
      recoveryStats,
      fetchingMandateStats,
      fetchingRecoveryStats,
      linkedAccountStats,
      fetchingLinkedAccountStats,
      rangeInDays,
      fretchingInstitutions,
      institutions,
      showShareModal,
      items,
      dashboard,
      fetching,
      allBlackboxLoanRequests,
      blackboxLoanRequestAmount,
      blackboxLoanRequestCount,
      fetchingBlackboxLoanRequests
    } = this.state;
    // console.log("all Institutions:: ", institutions);
    // console.log("all mandates:: ", allCollectionDueDetails);
    // console.log("all bvns:: ", allLinkedBVNs);
    const dmbInsts: any = [];
    const otherInsts: any = [];
    institutions.map((inst) => {
      inst.dmb ? dmbInsts.push(inst) : otherInsts.push(inst);
    });
    const dmbCount = dmbInsts.length;
    const otherCount = otherInsts.length;
    // console.log("all dmbs:: ", dmbs);
    // console.log("all others:: ", otherInsts);

    const currentSize = allMandatesDetails.length;
    const collectionSize = allCollectionDueDetails.length;

    const activeMandateCountList = allMandatesDetails.map(
      (item) => item.activeMandatesCount
    );
    const cancelledMandateCountList = allMandatesDetails.map(
      (item) => item.cancelledMandatesCount
    );
    const activeMandateValueList = allMandatesDetails.map(
      (item) => item.activeMandatesValue / 100
    );
    const cancelledMandateValueList = allMandatesDetails.map(
      (item) => item.cancelledMandatesValue / 100
    );
    const institutionNames = allMandatesDetails.map(
      (item) => item.institutionName
    );

    const linkedBVNsCountList = allLinkedBVNs.map(
      (item) => item.noOfLinkedBVNs
    );
    const bvnInstitutionNames = allLinkedBVNs.map(
      (item) => item.institutionName
    );
    const allCollectionInstitutionName = allCollectionDueDetails.map(
      (item) => item.institutionName
    );
    const allCollectionDueValue = allCollectionDueDetails.map(
      (item) => item.collectionDue
    );
    const amountCollectedInstitution = amountCollectedStats.map(
      (item) => item.institutionName
    );
    const amount_Collected = amountCollectedStats.map(
      (item) => item.amountCollected
    );
    const amountNotCollected = amountCollectedStats.map(
      (item) => item.amountNotCollected
    );
    const lineChartOptions = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    const rangeInDaysInputData = [
      { name: "7 Days", value: "7" },
      { name: "14 Days", value: "14" },
      { name: "30 Days", value: "30" },
    ];

    // console.log("logging data", this.state.mandateStats);
    const lineChartCountList = mandateStats.map((item) => item.count);
    const lineChartValueList = mandateStats.map((item) => item.value / 100);
    const lineChartLabels = mandateStats.map((item) => item.label);

    const recoveryLineChartAttemptedList = recoveryStats.map(
      (item) => item.amountAttempted / 100
    );
    const recoveryLineChartRecoveredList = recoveryStats.map(
      (item) => item.amountRecovered / 100
    );
    const recoveryLineChartEfficiencyList = recoveryStats.map(
      (item) =>
        ((item.amountRecovered / 100) / (item.amountAttempted / 100) == 0 ? 1 : (item.amountAttempted / 100)) * 100
    );
    const recoveryLineChartLabels = recoveryStats.map((item) =>
      new Date(item.timeSwept).toDateString()
    );

    const linkedAccountCountList = linkedAccountStats.map(
      (item) => item.noOfAccounts
    );
    const linkedBvnAccountCountList = linkedAccountStats.map(
      (item) => item.noOfBvns
    );
    const linkedAccountLineChartLabels = linkedAccountStats.map((item) =>
      new Date(item.dateLinked).toDateString()
    );

    // console.log(allBlackboxLoanRequests)
    const totalLoanRequestCount = allBlackboxLoanRequests.length;
    // console.log(totalLoanRequestCount)
    const totalLoanRequestVolume = totalLoanRequestCount > 0 ? allBlackboxLoanRequests.reduce((accumulator, current) => accumulator + current.amount, 0) : 0;


    const institutionCodesLoanRequests = allLinkedBVNs.map(
      (item) => item.institutionCode
    );
    institutionCodesLoanRequests.push("000");
    // console.log("cifedws", institutionCodesLoanRequests)
    const institutionNamesLoanRequests = allLinkedBVNs.map(
      (item) => item.institutionName
    )
    institutionNamesLoanRequests.push("AppZone");
    // console.log("cifedws", institutionNamesLoanRequests)

    let blackboxLoanRequestCountList: any = []
    let blackboxLoanRequestAmountList: any = []
    if (institutionCodesLoanRequests.length > 0) {
      institutionCodesLoanRequests.forEach(code => {
        let comp = allBlackboxLoanRequests.filter(lr => lr.institutionCode == code)
        blackboxLoanRequestCountList.push(comp.length)
        blackboxLoanRequestAmountList.push(comp.reduce((accumulator, current) => accumulator + current.amount, 0))
      })

      // console.log("counts", blackboxLoanRequestCountList)
      // console.log("amounts", blackboxLoanRequestAmountList)
    }

    return (
      <React.Fragment>
        <ShareModal
          showShareModal={showShareModal}
          items={items}
          toggleShowShareModal={this.toggleShowShareModal}
          dashboard={dashboard}
        />
        <div className="row" style={{ padding: "10px" }}>
          <div className="col-12 col-xl-12">
            <div className="d-flex justify-content-between">
              {/* <div className='align-self-center'>
                <strong>
                  <i className='icon icon-bar-chart s-18 mr-2'></i>
                  AppzoneDashboards
                </strong>
              </div> */}
              <div className="align-self-end float-right">
                <ul
                  className="nav nav-tabs card-header-tabs nav-material"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active show"
                      id="w2--tab1"
                      data-toggle="tab"
                      href="#w2-tab1"
                      role="tab"
                      aria-controls="tab1"
                      aria-expanded="true"
                      aria-selected="true"
                    >
                      <i className="icon icon-certificate s-18 ml-3"></i>
                      Mandates
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="w2--tab4"
                      data-toggle="tab"
                      href="#w2-tab4"
                      role="tab"
                      aria-controls="tab4"
                      aria-selected="false"
                    >
                      <i className="icon icon-certificate2 s-18"></i>Bvn Linking
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="w2--tab7"
                      data-toggle="tab"
                      href="#w2-tab7"
                      role="tab"
                      aria-controls="tab7"
                      aria-selected="false"
                    >
                      <i className="icon icon-link2 s-18"></i>Linked Accounts
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a
                      className="nav-link"
                      id="w2--tab2"
                      data-toggle="tab"
                      href="#w2-tab2"
                      role="tab"
                      aria-controls="tab2"
                      aria-selected="false"
                    >
                      <i className="icon icon-money s-18"></i>Loans
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="w2--tab3"
                      data-toggle="tab"
                      href="#w2-tab3"
                      role="tab"
                      aria-controls="tab3"
                      aria-selected="false"
                    >
                      <i className="icon icon-repeat s-18"></i>Recovery
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="w2--tab5"
                      data-toggle="tab"
                      href="#w2-tab5"
                      role="tab"
                      aria-controls="tab5"
                      aria-selected="false"
                    >
                      <i className="icon icon-university s-18"></i>Institutions
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="w2--tab6"
                      data-toggle="tab"
                      href="#w2-tab6"
                      role="tab"
                      aria-controls="tab6"
                      aria-selected="false"
                    >
                      <i className="icon icon-money s-18"></i>Settlement
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="w2--tab9"
                      data-toggle="tab"
                      href="#w2-tab9"
                      role="tab"
                      aria-controls="tab9"
                      aria-selected="false"
                    >
                      <i className="icon icon-money s-18"></i>Credit Assessment
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="w2--tab10"
                      data-toggle="tab"
                      href="#w2-tab10"
                      role="tab"
                      aria-controls="tab10"
                      aria-selected="false"
                    >
                      <i className="icon icon-money s-18"></i>Collections
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="w2--tab8"
                      data-toggle="tab"
                      href="#w2-tab8"
                      role="tab"
                      aria-controls="tab8"
                      aria-selected="false"
                    >
                      <i className="icon icon-box s-18"></i>Blackbox
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="tab-content" id="v-pills-tabContent1">
              <div
                className="tab-pane fade show active"
                id="w2-tab1"
                role="tabpanel"
                aria-labelledby="w2-tab1"
              >
                <div className="row pb-3 col my-3">
                  <button
                    type="button"
                    disabled={fetching}
                    className="btn btn-outline-primary btn-xs float-right mr-2"
                    onClick={() => {
                      this.setState({ dashboard: "Mandates Dashboard" });
                      this.toggleShowShareModal();
                    }}
                  >
                    {fetching === false ? (
                      <React.Fragment>
                        <i className="icon-share2 mr-2"></i>
                        Share
                      </React.Fragment>
                    ) : (
                      <i className="fas fa-spinner fa-spin" />
                    )}
                  </button>
                </div>
                <div className="my-3">
                  <div
                    className="row text-white no-gutters no-m shadow"
                    id="mandateHeaders"
                  >
                    <div className="col-lg-3">
                      <div className="green counter-box p-40">
                        <h6 className="counter-title">
                          Active Mandates (Amount)
                        </h6>
                        <div className="float-right">
                          <img src={cash} alt="mandate" />
                        </div>
                        <div className="sc-counter s-36 counter-animated">
                          {this.state.fetchingMandateData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenMoney(
                              this.state.activeMandatesValue / 100,
                              0
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="blue1 counter-box p-40">
                        <h6 className="counter-title">
                          Active Mandates (Count)
                        </h6>
                        <div className="float-right">
                          <img src={resume} alt="mandate" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingMandateData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenNumber(
                              this.state.activeMandatesCount,
                              0
                            )
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="sunfollower counter-box p-40">
                        <h6 className="counter-title">
                          Canceled Mandates (Amount)
                        </h6>
                        <div className="float-right">
                          <img src={cash} alt="mandate" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingMandateData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenMoney(
                              this.state.cancelledMandatesValue / 100,
                              0
                            )
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="strawberry counter-box p-40">
                        <h6 className="counter-title">
                          Cancelled Mandates (Count)
                        </h6>
                        <div className="float-right">
                          <img src={resume} alt="mandate" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingMandateData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenNumber(
                              this.state.cancelledMandatesCount,
                              0
                            )
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <br />

                  <div className="row pb-3" id="dailyMandatesTrend">
                    <div className="col">
                      <div className="card no-b">
                        <div className="card-header white">
                          <div className="d-flex justify-content-between">
                            <div className="align-self-center">
                              <strong>Mandate Trend</strong>
                            </div>
                            <div className="align-self-end float-right">
                              <ul
                                className="nav nav-tabs card-header-tabs nav-material"
                                role="tablist"
                              >
                                <li className="nav-item pr-3">
                                  <DetailItemSelectComponent
                                    id="rangeInDays"
                                    name="rangeInDays"
                                    value={rangeInDays}
                                    items={rangeInDaysInputData}
                                    validated={false}
                                    errors={[]}
                                    onChange={(id, value) =>
                                      this.handleOnRangeInputChange(value)
                                    }
                                    disabled={fetchingMandateStats}
                                  />
                                </li>
                                <li className="nav-item">
                                  <a
                                    className="nav-link active show"
                                    id="w2--tab1c"
                                    data-toggle="tab"
                                    href="#w2-tab1c"
                                    role="tab"
                                    aria-controls="tab1c"
                                    aria-expanded="true"
                                    aria-selected="true"
                                  >
                                    <i className="icon icon-certificate s-18 ml-3"></i>
                                    Counts
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a
                                    className="nav-link"
                                    id="w2--tab2c"
                                    data-toggle="tab"
                                    href="#w2-tab2c"
                                    role="tab"
                                    aria-controls="tab2c"
                                    aria-selected="false"
                                  >
                                    <i className="icon icon-money s-18"></i>
                                    Value
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="card-body no-p">
                          <div className="tab-content" id="v-pills-tabContent1">
                            <div
                              className="tab-pane fade show active"
                              id="w2-tab1c"
                              role="tabpanel"
                              aria-labelledby="w2-tab1c"
                            >
                              <div className="card p-3">
                                <div>
                                  <Line
                                    height={120}
                                    data={{
                                      labels: lineChartLabels,
                                      datasets: [
                                        {
                                          label: "No. of Mandates",
                                          data: lineChartCountList,
                                          fill: false,
                                          tension: 0.5,
                                          backgroundColor: "rgb(54, 162, 235)",
                                          borderColor:
                                            "rgba(54, 162, 235, 0.6)",
                                        },
                                      ],
                                    }}
                                    options={{
                                      animation: {
                                        // onComplete: (e) => {
                                        //   let items = this.state.items;
                                        //   var chartname =
                                        //     "dailyMandatesCountTrend";
                                        //   items[chartname] =
                                        //     e.chart.toBase64Image();
                                        //   if (items !== this.state.items)
                                        //     this.setState({ items });
                                        // },
                                      },
                                      plugins: {
                                        title: {
                                          display: true,
                                          text: "Daily Mandates Count Trend",
                                        },
                                      },
                                      scales: {
                                        x: {
                                          title: {
                                            display: true,
                                            text: "Dates",
                                          },
                                        },
                                        y: {
                                          beginAtZero: true,
                                          title: {
                                            display: true,
                                            text: "Mandate Counts",
                                          },
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="w2-tab2c"
                              role="tabpanel"
                              aria-labelledby="w2-tab2c"
                            >
                              <div className="card p-3">
                                <div>
                                  <Line
                                    height={120}
                                    data={{
                                      labels: lineChartLabels,
                                      datasets: [
                                        {
                                          label: "Mandate Value",
                                          data: lineChartValueList,
                                          fill: false,
                                          tension: 0.5,
                                          backgroundColor: "rgb(76, 175, 80)",
                                          borderColor: "rgba(76, 175, 80, 0.6)",
                                        },
                                      ],
                                    }}
                                    options={{
                                      animation: {
                                        // onComplete: (e) => {
                                        //   let items = this.state.items;
                                        //   var chartname =
                                        //     "dailyMandatesValueTrend";
                                        //   items[chartname] =
                                        //     e.chart.toBase64Image();
                                        //   if (items !== this.state.items)
                                        //     this.setState({ items });
                                        // },
                                      },
                                      plugins: {
                                        title: {
                                          display: true,
                                          text: "Daily Mandates Value Trend",
                                        },
                                      },
                                      scales: {
                                        x: {
                                          title: {
                                            display: true,
                                            text: "Dates",
                                          },
                                        },
                                        y: {
                                          beginAtZero: true,
                                          title: {
                                            display: true,
                                            text: "Mandate Values",
                                          },
                                          ticks: {
                                            // Include a dollar sign in the ticks
                                            callback: function (
                                              value,
                                              index,
                                              values
                                            ) {
                                              return "₦" + value;
                                              // return (
                                              //   "₦" +
                                              //   textFormatService.convertKoboToNaira(
                                              //     value,
                                              //     0
                                              //   )
                                              // );
                                            },
                                          },
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row pb-3">
                    <div className="col-md-8">
                      <div className="card no-b" id="dailyMandatesChart">
                        <div className="card-header white">
                          <div className="d-flex justify-content-between">
                            <div className="align-self-center">
                              <strong>Mandate Statistics</strong>
                            </div>
                            <div className="align-self-end float-right">
                              <ul
                                className="nav nav-tabs card-header-tabs nav-material"
                                role="tablist"
                              >
                                <li className="nav-item">
                                  <a
                                    className="nav-link active show"
                                    id="w2--tab1b"
                                    data-toggle="tab"
                                    href="#w2-tab1b"
                                    role="tab"
                                    aria-controls="tab1b"
                                    aria-expanded="true"
                                    aria-selected="true"
                                  >
                                    <i className="icon icon-certificate s-18 ml-3"></i>
                                    Counts
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a
                                    className="nav-link"
                                    id="w2--tab2b"
                                    data-toggle="tab"
                                    href="#w2-tab2b"
                                    role="tab"
                                    aria-controls="tab2b"
                                    aria-selected="false"
                                  >
                                    <i className="icon icon-money s-18"></i>
                                    Value
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="card-body no-p">
                          <div className="tab-content" id="v-pills-tabContent1">
                            <div
                              className="tab-pane fade show active"
                              id="w2-tab1b"
                              role="tabpanel"
                              aria-labelledby="w2-tab1b"
                            >
                              <div className="card p-3">
                                <div>
                                  <PaginatedBarChart
                                    currentItemSize={currentSize}
                                    currentPage={this.state.currentPage}
                                    fetching={this.state.fetching}
                                    handlePageChange={this.handlePageChange}
                                    totalSize={this.state.totalSize}
                                    chartItem={
                                      <Bar
                                        id="sample"
                                        height={200}
                                        data={{
                                          labels: institutionNames,
                                          datasets: [
                                            {
                                              label: "Active Mandates Count",
                                              data: activeMandateCountList,
                                              backgroundColor:
                                                "rgb(54, 162, 235,0.75)",
                                              fill: false,
                                              tension: 0.5,
                                              borderWidth: 1,
                                              width: 3,
                                            },
                                            {
                                              label: "Cancelled Mandates Count",
                                              data: cancelledMandateCountList,
                                              backgroundColor:
                                                "rgb(255, 99, 132,0.75)",
                                              fill: false,
                                              tension: 0.5,
                                              borderWidth: 1,
                                              width: 3,
                                            },
                                          ],
                                        }}
                                        options={{
                                          animation: {
                                            // onComplete: (e) => {
                                            //   let items = this.state.items;
                                            //   var chartname =
                                            //     "dailyMandatesCountChart";
                                            //   items[chartname] =
                                            //     e.chart.toBase64Image();
                                            //   if (items !== this.state.items)
                                            //     this.setState({ items });
                                            // },
                                          },
                                          plugins: {
                                            title: {
                                              display: true,
                                              text: "Active Mandates Count Vs Cancelled Mandates Count",
                                            },
                                          },
                                          indexAxis: "y",
                                          legend: {
                                            display: true,
                                            position: "top",
                                          },
                                          scales: {
                                            y: {
                                              stacked: true,
                                              title: {
                                                display: true,
                                                text: "Institutions",
                                              },
                                            },
                                            x: {
                                              stacked: true,
                                              title: {
                                                display: true,
                                                text: "Mandate Counts",
                                              },
                                            },
                                          },
                                        }}
                                      />
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="w2-tab2b"
                              role="tabpanel"
                              aria-labelledby="w2-tab2b"
                            >
                              <div className="card p-3">
                                <div>
                                  <PaginatedBarChart
                                    currentItemSize={currentSize}
                                    currentPage={this.state.currentPage}
                                    fetching={this.state.fetching}
                                    handlePageChange={this.handlePageChange}
                                    totalSize={this.state.totalSize}
                                    chartItem={
                                      <Bar
                                        height={200}
                                        data={{
                                          labels: institutionNames,
                                          datasets: [
                                            {
                                              label: "Active Mandates Value",
                                              data: activeMandateValueList,
                                              backgroundColor:
                                                "rgba(76,175,80,0.75)",
                                              fill: false,
                                              tension: 0.5,
                                              borderWidth: 1,
                                              width: 3,
                                            },
                                            {
                                              label: "Cancelled Mandates Value",
                                              data: cancelledMandateValueList,
                                              backgroundColor:
                                                "rgba(252,206,84,0.75)",
                                              fill: false,
                                              tension: 0.5,
                                              borderWidth: 1,
                                              width: 3,
                                            },
                                          ],
                                        }}
                                        options={{
                                          animation: {
                                            // onComplete: (e) => {
                                            //   let items = this.state.items;
                                            //   var chartname =
                                            //     "dailyMandatesValueChart";
                                            //   items[chartname] =
                                            //     e.chart.toBase64Image();
                                            //   if (items !== this.state.items)
                                            //     this.setState({ items });
                                            // },
                                          },
                                          plugins: {
                                            title: {
                                              display: true,
                                              text: "Active Mandates Value Vs Cancelled Mandates Value",
                                            },
                                          },
                                          indexAxis: "y",
                                          legend: {
                                            display: true,
                                            position: "top",
                                          },
                                          scales: {
                                            y: {
                                              stacked: true,
                                              title: {
                                                display: true,
                                                text: "Institutions",
                                              },
                                            },
                                            x: {
                                              stacked: true,
                                              title: {
                                                display: true,
                                                text: "Mandate Values",
                                              },
                                              ticks: {
                                                // Include a dollar sign in the ticks
                                                callback: function (
                                                  value,
                                                  index,
                                                  values
                                                ) {
                                                  return "₦" + value;
                                                  // return (
                                                  //   "₦" +
                                                  //   textFormatService.convertKoboToNaira(
                                                  //     value,
                                                  //     0
                                                  //   )
                                                  // );
                                                },
                                              },
                                            },
                                          },
                                        }}
                                      />
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card p-3" id="pieCountStatistics">
                        <p style={{ fontSize: "16px" }}>Count Statistics</p>
                        <br />
                        <div className="card-body pt-0">
                          <Pie
                            data={{
                              maintainAspectRatio: false,
                              responsive: false,
                              labels: ["Active Mandates", "Cancelled Mandates"],
                              datasets: [
                                {
                                  data: [
                                    this.state.activeMandatesCount,
                                    this.state.cancelledMandatesCount,
                                  ],
                                  backgroundColor: [
                                    "rgb(54, 162, 235)",
                                    "#ed5564",
                                  ],
                                  hoverBackgroundColor: [
                                    "rgb(54, 162, 235,0.7)",
                                    "#ed7780",
                                  ],
                                },
                              ],
                            }}
                            options={{
                              animation: {
                                duration: 30,
                                // onComplete: (e) => {
                                //   let items = this.state.items;
                                //   var chartname = "generalMandatesCountPie";
                                //   items[chartname] = e.chart.toBase64Image();
                                //   if (items !== this.state.items)
                                //     this.setState({ items });
                                // },
                              },
                              legend: {
                                display: true,
                                position: "right",
                              },
                              elements: {
                                arc: {
                                  borderWidth: 0,
                                },
                              },
                            }}
                          />
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="w2-tab2"
                role="tabpanel"
                aria-labelledby="w2-tab2"
              >
                <div className="my-3">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="counter-box white r-3">
                        <div className="p-4">
                          <div className="float-right">
                            <img src={stack_of_money} alt="total_mandates" />
                            {/* <span className='icon icon-note-list text-light-blue s-48'></span> */}
                          </div>
                          <div className="counter-title">Loan Value</div>
                          <h3 className="sc-counter mt-3">
                            {/* <CountUp end={1228} duration={5} /> */}
                          </h3>
                        </div>
                        {/* <div className='progress progress-xs r-0'>
                        <div
                          className='progress-bar'
                          role='progressbar'
                          style={{ width: '25%' }}
                          aaria-valuenow={25}
                          aria-valuemin={0}
                          aria-valuemax={128}
                        ></div>
                      </div> */}
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="counter-box white r-3">
                        <div className="p-4">
                          <div className="float-right">
                            <span className="icon icon-mail-envelope-open s-48"></span>
                          </div>
                          <div className="counter-title ">Premium Themes</div>
                          <h3 className="sc-counter mt-3">
                            {/* <CountUp end={1228} duration={5} /> */}
                          </h3>
                        </div>
                        {/* <div className='progress progress-xs r-0'>
                        <div
                          className='progress-bar'
                          role='progressbar'
                          style={{ width: '50%' }}
                          aria-valuenow={25}
                          aria-valuemin={0}
                          aria-valuemax={128}
                        ></div>
                      </div> */}
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="white counter-box">
                        <div className="p-4">
                          <div className="float-right">
                            <span className="icon icon-stop-watch3 s-48"></span>
                          </div>
                          <div className="counter-title">Support Requests</div>
                          <h3 className="sc-counter mt-3">
                            {/* <CountUp end={1228} duration={5} /> */}
                          </h3>
                        </div>
                        {/* <div className='progress progress-xs r-0'>
                        <div
                          className='progress-bar'
                          role='progressbar'
                          style={{ width: '75%' }}
                          aria-valuenow={25}
                          aria-valuemin={0}
                          aria-valuemax={128}
                        ></div>
                      </div> */}
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="white counter-box">
                        <div className="p-4">
                          <div className="float-right">
                            <span className="icon icon-inbox-document-text s-48"></span>
                          </div>
                          <div className="counter-title">Support Requests</div>
                          <h3 className="sc-counter mt-3">
                            {/* <CountUp end={1228} duration={5} /> */}
                          </h3>
                        </div>
                        {/* <div className='progress progress-xs r-0'>
                        <div
                          className='progress-bar'
                          role='progressbar'
                          style={{ width: '25%' }}
                          aria-valuenow={25}
                          aria-valuemin={0}
                          aria-valuemax={128}
                        ></div>
                      </div> */}
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row pb-3">
                    <div className="col-md-8">
                      <div className="card p-3">
                        <p style={{ fontSize: "16px" }}>Loan Booking Trend</p>
                        <br />
                        <div>
                          <Bar
                            data={{
                              labels: [
                                "Sunday",
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday",
                                "Saturday",
                              ],
                              datasets: [
                                {
                                  label: "Loans",
                                  fill: false,
                                  tension: 0.5,
                                  backgroundColor: [
                                    generateRandomRGBA(0.2),
                                    generateRandomRGBA(0.2),
                                    generateRandomRGBA(0.2),
                                    generateRandomRGBA(0.2),
                                    generateRandomRGBA(0.2),
                                    generateRandomRGBA(0.2),
                                    generateRandomRGBA(0.2),
                                  ],
                                  // borderColor: [
                                  //   generateRandomRGBA(1),
                                  //   generateRandomRGBA(1),
                                  //   generateRandomRGBA(1),
                                  //   generateRandomRGBA(1),
                                  //   generateRandomRGBA(1),
                                  //   generateRandomRGBA(1),
                                  //   generateRandomRGBA(1)
                                  // ],
                                  borderWidth: 2,
                                  data: [400, 528, 228, 728, 528, 162, 350],
                                },
                              ],
                            }}
                            options={{
                              title: {
                                display: false,
                                text: "Loans Booked Daily",
                                fontSize: 10,
                              },
                              legend: {
                                display: true,
                                position: "bottom",
                              },
                            }}
                          ></Bar>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card">
                        <div className="card-header white">
                          <strong> Progress </strong>
                        </div>
                        <div className="card-body pt-0">
                          <div className="my-3">
                            <small>25% Complete</small>
                            <div className="progress" style={{ height: "3px" }}>
                              <div
                                className="progress-bar bg-success"
                                role="progressbar"
                                style={{ width: "25%" }}
                                aria-valuenow={25}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                          <div className="my-3">
                            <small>45% Complete</small>
                            <div className="progress" style={{ height: "3px" }}>
                              <div
                                className="progress-bar bg-info"
                                role="progressbar"
                                style={{ width: "45%" }}
                                aria-valuenow={25}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                          <div className="my-3">
                            <small>60% Complete</small>
                            <div className="progress" style={{ height: "3px" }}>
                              <div
                                className="progress-bar bg-warning"
                                role="progressbar"
                                style={{ width: "60%" }}
                                aria-valuenow={25}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                          <div className="my-3">
                            <small>75% Complete</small>
                            <div className="progress" style={{ height: "3px" }}>
                              <div
                                className="progress-bar bg-danger"
                                role="progressbar"
                                style={{ width: "75%" }}
                                aria-valuenow={25}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                          <div className="my-3">
                            <small>100% Complete</small>
                            <div className="progress" style={{ height: "3px" }}>
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: "100%" }}
                                aria-valuenow={25}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="w2-tab3"
                role="tabpanel"
                aria-labelledby="w2-tab3"
              >
                <br />
                <div className="row pb-3 col my-3">
                  <button
                    type="button"
                    disabled={fetching}
                    className="btn btn-outline-primary btn-xs float-right mr-2"
                    onClick={() => {
                      this.setState({ dashboard: "Recovery Dashboard" });
                      this.toggleShowShareModal();
                    }}
                  >
                    {fetching === false ? (
                      <React.Fragment>
                        <i className="icon-share2 mr-2"></i>
                        Share
                      </React.Fragment>
                    ) : (
                      <i className="fas fa-spinner fa-spin" />
                    )}
                  </button>
                </div>
                <div className="my-3">
                  <div
                    className="row text-white no-gutters no-m shadow"
                    id="recoveryHeaders"
                  >
                    <div className="col-lg-3">
                      <div className="green counter-box p-40">
                        <h6 className="counter-title">Recovery Count</h6>
                        <div className="float-right">
                          <img src={resume} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingRecoveryData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenNumber(
                              this.state.recoveryTransactionCount,
                              0
                            )
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <div className="blue1 counter-box p-40">
                        <h6 className="counter-title">Amount Attempted</h6>
                        <div className="float-right">
                          <img src={cash} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingRecoveryData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenMoney(
                              this.state.amountAttemptedValue / 100,
                              0
                            )
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="sunfollower counter-box p-40">
                        <h6 className="counter-title">Amount Recovered</h6>
                        <div className="float-right">
                          <img src={cash} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingRecoveryData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenMoney(
                              this.state.amountRecoveredValue / 100,
                              0
                            )
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="strawberry counter-box p-40">
                        <h6 className="counter-title">Recovery Efficiency</h6>
                        <div className="float-right">
                          <img src={resume} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingRecoveryData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              {this.state.recoveryEfficiency.toFixed(2)}%
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <br />

                  <div className="row pb-3" id="recoveryTrends">
                    <div className="col">
                      <div className="card no-b">
                        <div className="card-header white">
                          <div className="d-flex justify-content-between">
                            <div className="align-self-center">
                              <strong>Recovery Trend</strong>
                            </div>
                            <div className="align-self-end float-right">
                              <ul
                                className="nav nav-tabs card-header-tabs nav-material"
                                role="tablist"
                              >
                                <li className="nav-item pr-3">
                                  <DetailItemSelectComponent
                                    id="rangeInDays"
                                    name="rangeInDays"
                                    value={rangeInDays}
                                    items={rangeInDaysInputData}
                                    validated={false}
                                    errors={[]}
                                    onChange={(id, value) =>
                                      this.handleOnRangeInputChange(value)
                                    }
                                    disabled={fetchingRecoveryStats}
                                  />
                                </li>
                                <li className="nav-item">
                                  <a
                                    className="nav-link active show"
                                    id="w2--tabr1c"
                                    data-toggle="tab"
                                    href="#w2-tabr1c"
                                    role="tab"
                                    aria-controls="tabr1c"
                                    aria-expanded="true"
                                    aria-selected="true"
                                  >
                                    <i className="icon icon-money s-18 ml-3"></i>
                                    Amount
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a
                                    className="nav-link"
                                    id="w2--tabr2c"
                                    data-toggle="tab"
                                    href="#w2-tabr2c"
                                    role="tab"
                                    aria-controls="tabr2c"
                                    aria-selected="false"
                                  >
                                    <i className="icon icon-certificate s-18"></i>
                                    Efficiency
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="card-body no-p">
                          <div className="tab-content" id="v-pills-tabContent1">
                            <div
                              className="tab-pane fade show active"
                              id="w2-tabr1c"
                              role="tabpanel"
                              aria-labelledby="w2-tabr1c"
                            >
                              <div className="card p-3">
                                <div>
                                  <Line
                                    height={120}
                                    data={{
                                      labels: recoveryLineChartLabels,
                                      datasets: [
                                        {
                                          label: "Amount Attempted",
                                          data: recoveryLineChartAttemptedList,
                                          fill: false,
                                          backgroundColor: "rgb(245, 66, 108)",
                                          borderColor:
                                            "rgba(245, 66, 108, 0.6)",
                                        },
                                        {
                                          label: "Amount Recovered",
                                          data: recoveryLineChartRecoveredList,
                                          fill: false,
                                          backgroundColor: "rgb(42, 235, 28)",
                                          borderColor: "rgba(42, 235, 28, 0.6)",
                                        },
                                      ],
                                    }}
                                    options={{
                                      plugins: {
                                        title: {
                                          display: true,
                                          text: "Daily Recovery Trend",
                                        },
                                      },
                                      scales: {
                                        x: {
                                          title: {
                                            display: true,
                                            text: "Dates",
                                          },
                                        },
                                        y: {
                                          beginAtZero: true,
                                          title: {
                                            display: true,
                                            text: "Amount",
                                          },
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="w2-tabr2c"
                              role="tabpanel"
                              aria-labelledby="w2-tabr2c"
                            >
                              <div className="card p-3">
                                <div>
                                  <Bar
                                    height={120}
                                    data={{
                                      labels: recoveryLineChartLabels,
                                      datasets: [
                                        {
                                          label: "Recovery Efficiency",
                                          fill: false,
                                          tension: 0.5,
                                          backgroundColor:
                                            "rgba(63, 217, 61, 0.2)",
                                          borderColor: "rgba(76, 175, 80, 0.2)",
                                          borderWidth: 2,
                                          data: recoveryLineChartEfficiencyList,
                                        },
                                      ],
                                    }}
                                    options={lineChartOptions}
                                  ></Bar>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="w2-tab4"
                role="tabpanel"
                aria-labelledby="w2-tab4"
              >
                <br />
                <div className="row pb-3 col my-3">
                  <button
                    type="button"
                    disabled={fetching}
                    className="btn btn-outline-primary btn-xs float-right mr-2"
                    onClick={() => {
                      this.setState({
                        dashboard: "BVN Linking Dashboard",
                      });
                      this.toggleShowShareModal();
                    }}
                  >
                    {fetching === false ? (
                      <React.Fragment>
                        <i className="icon-share2 mr-2"></i>
                        Share
                      </React.Fragment>
                    ) : (
                      <i className="fas fa-spinner fa-spin" />
                    )}
                  </button>
                </div>
                <br />
                <div className="my-3">
                  <div className="col-md-8" id="linkingImage1">
                    <div className="card p-3">
                      <p style={{ fontSize: "16px" }}>
                        Linked BVN Statistics For All Institutions
                      </p>
                      <br />
                      <div>
                        <Bar
                          height={200}
                          data={{
                            labels: bvnInstitutionNames,
                            datasets: [
                              {
                                label: "Number of Linked BVNs",
                                data: linkedBVNsCountList,
                                backgroundColor: "rgba(76,175,80,0.75)",
                                fill: false,
                                tension: 0.5,
                                borderWidth: 1,
                                width: 3,
                              },
                            ],
                          }}
                          options={{
                            plugins: {
                              title: {
                                display: true,
                                text: "Linked BVN Statistics For All Institutions",
                              },
                            },
                            indexAxis: "y",
                            legend: {
                              display: true,
                              position: "top",
                            },
                            scales: {
                              y: {
                                stacked: true,
                              },
                              x: {
                                stacked: true,
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/*                     
                  <div className="row text-white no-gutters no-m shadow">
                    <div className="col-lg-3">
                      <div className="green counter-box p-40">
                        <h6 className="counter-title">Linked BVNs</h6>
                        <div className="float-right">
                          <img src={resume} alt="mandate" />
                        </div>
                        <div className="sc-counter s-36 counter-animated">
                          {this.state.fetchingMandateData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenNumber(
                              this.state.linkedBVNCount,
                              0
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  */}
                  <br />
                  <div className="row pb-3"></div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="w2-tab5"
                role="tabpanel"
                aria-labelledby="w2-tab5"
              >
                <br />
                <div className="row pb-3 col my-3">
                  <button
                    type="button"
                    disabled={fetching}
                    className="btn btn-outline-primary btn-xs float-right mr-2"
                    onClick={() => {
                      this.setState({ dashboard: "Institutions Dashboard" });
                      this.toggleShowShareModal();
                    }}
                  >
                    {fetching === false ? (
                      <React.Fragment>
                        <i className="icon-share2 mr-2"></i>
                        Share
                      </React.Fragment>
                    ) : (
                      <i className="fas fa-spinner fa-spin" />
                    )}
                  </button>
                </div>
                <div className="my-3">
                  <div
                    className="row text-white no-gutters no-m shadow"
                    id="institutionsHeaders"
                  >
                    <div className="col-lg-3">
                      <div className="green counter-box p-40">
                        <h6 className="counter-title">Banks</h6>
                        <div className="float-right">
                          <img src={resume} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {fretchingInstitutions ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenNumber(dmbCount, 0)
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="blue1 counter-box p-40">
                        <h6 className="counter-title">Other FIs</h6>
                        <div className="float-right">
                          <img src={cash} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {fretchingInstitutions ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenNumber(otherCount, 0)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <br />

                  <div className="row pb-3" id="institutionsChart">
                    <div className="col-md-6">
                      <div className="card p-3">
                        <p style={{ fontSize: "16px" }}>Count Statistics</p>
                        <br />
                        <div className="card-body pt-0">
                          <Pie
                            data={{
                              maintainAspectRatio: false,
                              responsive: false,
                              labels: ["Banks", "Other Financial Institutions"],
                              datasets: [
                                {
                                  data: [dmbCount, otherCount],
                                  backgroundColor: [
                                    "rgba(76,175,80)",
                                    "rgba(120,188,238)",
                                  ],
                                  hoverBackgroundColor: [
                                    "rgba(76,175,80,0.7)",
                                    "rgb(120,188,238,0.7)",
                                  ],
                                },
                              ],
                            }}
                            options={{
                              animation: { duration: 30 },
                              legend: {
                                display: true,
                                position: "right",
                              },
                              elements: {
                                arc: {
                                  borderWidth: 0,
                                },
                              },
                            }}
                          />
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="w2-tab6"
                role="tabpanel"
                aria-labelledby="w2-tab6"
              >
                <br />
                <div className="row pb-3 col my-3">
                  <button
                    type="button"
                    disabled={fetching}
                    className="btn btn-outline-primary btn-xs float-right mr-2"
                    onClick={() => {
                      this.setState({ dashboard: "Settlement Dashboard" });
                      this.toggleShowShareModal();
                    }}
                  >
                    {fetching === false ? (
                      <React.Fragment>
                        <i className="icon-share2 mr-2"></i>
                        Share
                      </React.Fragment>
                    ) : (
                      <i className="fas fa-spinner fa-spin" />
                    )}
                  </button>
                </div>
                <div className="my-3">
                  <div
                    className="row text-white no-gutters no-m shadow"
                    id="settlementHeaders"
                  >
                    <div className="col-lg-3">
                      <div className="green counter-box p-40">
                        <h6 className="counter-title">
                          Successful Settlement (Amount)
                        </h6>
                        <div className="float-right">
                          <img src={cash} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingRecoveryData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenMoney(
                              this.state.successfulSettlementValue / 100,
                              0
                            )
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="red counter-box p-40">
                        <h6 className="counter-title">
                          Pending Settlement (Amount)
                        </h6>
                        <div className="float-right">
                          <img src={cash} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingRecoveryData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenMoney(
                              this.state.pendingSettlementValue / 100,
                              0
                            )
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="w2-tab9"
                role="tabpanel"
                aria-labelledby="w2-tab9"
              >
                <br />
                <div className="row pb-3 col my-3">
                  <button
                    type="button"
                    disabled={fetching}
                    className="btn btn-outline-primary btn-xs float-right mr-2"
                    onClick={() => {
                      this.setState({ dashboard: "Credit Assessment Dashboard" });
                      this.toggleShowShareModal();
                    }}
                  >
                    {fetching === false ? (
                      <React.Fragment>
                        <i className="icon-share2 mr-2"></i>
                        Share
                      </React.Fragment>
                    ) : (
                      <i className="fas fa-spinner fa-spin" />
                    )}
                  </button>
                </div>
                <div className="my-3">
                  <div
                    className="row text-white no-gutters no-m shadow"
                    id="settlementHeaders"
                  >
                    <div className="col-lg-3">
                      <div className="green counter-box p-40">
                        <h6 className="counter-title">
                          Loan requests
                        </h6>
                        <div className="float-right">
                          <img src={resume} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingRecoveryData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenNumber(
                              this.state.creditassessmentLoanRequests,
                              0
                            )
                            // 0
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="blue1 counter-box p-40">
                        <h6 className="counter-title">
                          Disbursed loans
                        </h6>
                        <div className="float-right">
                          <img src={resume} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingRecoveryData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenNumber(
                              this.state.creditassessmentDisbursedloans,
                              0
                            )
                            // 0 
                          )}
                        </span>
                      </div>
                      
                    </div>
                    <div className="col-lg-3">
                      <div className="sunfollower counter-box p-40">
                        <h6 className="counter-title">
                          Disbursed loans (Amount)
                        </h6>
                        <div className="float-right">
                          <img src={cash} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingRecoveryData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenMoney(
                              this.state.creditassessmentDisbursedVolume / 100,
                              0
                            )
                            // 0
                          )}
                        </span>
                      </div>
                      
                    </div>
                    <div className="col-lg-3">
                      <div className="strawberry counter-box p-40">
                        <h6 className="counter-title">
                        CQ efficiency
                        </h6>
                        <div className="float-right">
                          <img src={resume} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingRecoveryData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenNumber(
                              this.state.creditassessmentCQefficiency,
                              0
                            )
                            // 0
                          )}
                        </span>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="w2-tab10"
                role="tabpanel"
                aria-labelledby="w2-tab10"
              >
                <br />
                <div className="row pb-3 col my-3">
                  <button
                    type="button"
                    disabled={fetching}
                    className="btn btn-outline-primary btn-xs float-right mr-2"
                    onClick={() => {
                      this.setState({ dashboard: "Collection Dashboard" });
                      this.toggleShowShareModal();
                    }}
                  >
                    {fetching === false ? (
                      <React.Fragment>
                        <i className="icon-share2 mr-2"></i>
                        Share
                      </React.Fragment>
                    ) : (
                      <i className="fas fa-spinner fa-spin" />
                    )}
                  </button>
                </div>
                <div className="my-3">
                  <div
                    className="row text-white no-gutters no-m shadow"
                    id="settlementHeaders"
                  >
                    <div className="col-lg-3">
                      <div className="green counter-box p-40">
                        <h6 className="counter-title">
                          Collection Due
                        </h6>
                        <div className="float-right">
                          <img src={cash} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingRecoveryData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenMoney(
                              this.state.collectionDue,
                              0
                            )
                            
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="blue1 counter-box p-40">
                        <h6 className="counter-title">
                          Amount Collected
                        </h6>
                        <div className="float-right">
                          <img src={cash} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingRecoveryData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenMoney(
                              this.state.amountCollected,
                              0
                            )
                            // 0
                          )}
                        </span>
                      </div>
                      
                    </div>
                    <div className="col-lg-3">
                      <div className="sunfollower counter-box p-40">
                        <h6 className="counter-title">
                          Collection Channel
                        </h6>
                        <div className="float-right">
                          <img src={cash} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingRecoveryData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            // textFormatService.shortenMoney(
                            //   this.state.collectionChannel / 100,
                            //   0
                            // )
                            0
                          )}
                        </span>
                      </div>
                      
                    </div>
                    <div className="col-lg-3">
                      <div className="strawberry counter-box p-40">
                        <h6 className="counter-title">
                        Switch to Recovery
                        </h6>
                        <div className="float-right">
                          <img src={resume} alt="recovery" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingRecoveryData ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            // textFormatService.shortenNumber(
                            //   this.state.creditassessmentCQefficiency,
                            //   0
                            // )
                            0
                          )}
                        </span>
                      </div>
                      
                    </div>
                  </div>
                  <br />

                  <div className="row pb-3">
                    <div className="col-md-8">
                      <div className="card no-b" id="">
                        <div className="card-header white">
                          <div className="d-flex justify-content-between">
                            <div className="align-self-center">
                              <strong>Collection Due Statistics</strong>
                            </div>
                            <div className="align-self-end float-right">
                            <ul
                                className="nav nav-tabs card-header-tabs nav-material"
                                role="tablist"
                              >
                                <li className="nav-item pr-3">
                                  <DetailItemInputComponent
                                    id="datepicker"
                                    name="datepicker"
                                    value={this.state.datePicked_}
                                    placeholder="Pick Date"
                                    type="text"
                                    validated={false}
                                    errors={[]}
                                    onChange={(id, value) =>
                                      this.handledatePickedInputChange_(value)
                                    }
                                  />
                                </li>
                                <li className="nav-item">
                                  <a
                                    className="nav-link active show"
                                    id="w2--tab1c"
                                    data-toggle="tab"
                                    href="#w2-tab1c"
                                    role="tab"
                                    aria-controls="tab1c"
                                    aria-expanded="true"
                                    aria-selected="true"
                                  >
                                    <i className="icon icon-certificate s-18 ml-3"></i>
                                    Date
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="card-body no-p">
                          <div className="tab-content" id="v-pills-tabContent1">
                            <div
                              className="tab-pane fade show active"
                              id="w2-tab2b"
                              role="tabpanel"
                              aria-labelledby="w2-tab2b"
                            >
                              <div className="card p-3">
                                <div>
                                  <Bar
                                    height={200}
                                    data={{
                                      labels: allCollectionInstitutionName,
                                      datasets: [
                                        {
                                          label: "Collection Due Value",
                                          data: allCollectionDueValue,
                                          backgroundColor:
                                            "rgba(76,175,80,0.75)",
                                          fill: false,
                                          tension: 0.5,
                                          borderWidth: 1,
                                          width: 3,
                                        }
                                      ],
                                    }}
                                    options={{
                                      animation: {
                                        // onComplete: (e) => {
                                        //   let items = this.state.items;
                                        //   var chartname =
                                        //     "dailyMandatesValueChart";
                                        //   items[chartname] =
                                        //     e.chart.toBase64Image();
                                        //   if (items !== this.state.items)
                                        //     this.setState({ items });
                                        // },
                                      },
                                      plugins: {
                                        title: {
                                          display: true,
                                          text: "Collection Due for all Institutions",
                                        },
                                      },
                                      indexAxis: "y",
                                      legend: {
                                        display: true,
                                        position: "top",
                                      },
                                      scales: {
                                        y: {
                                          stacked: true,
                                          title: {
                                            display: true,
                                            text: "Institutions",
                                          },
                                        },
                                        x: {
                                          stacked: true,
                                          title: {
                                            display: true,
                                            text: "Collection Due",
                                          },
                                          ticks: {
                                            // Include a dollar sign in the ticks
                                            callback: function (
                                              value,
                                              index,
                                              values
                                            ) {
                                              return "₦" + value;
                                              // return (
                                              //   "₦" +
                                              //   textFormatService.convertKoboToNaira(
                                              //     value,
                                              //     0
                                              //   )
                                              // );
                                            },
                                          },
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row pb-3">
                    <div className="col-md-8">
                      <div className="card no-b" id="">
                        <div className="card-header white">
                          <div className="d-flex justify-content-between">
                            <div className="align-self-center">
                              <strong>Amount Collected Statistics</strong>
                            </div>
                            <div className="align-self-end float-right">
                              <ul
                                className="nav nav-tabs card-header-tabs nav-material"
                                role="tablist"
                              >
                                <li className="nav-item pr-3">
                                  <DetailItemInputComponent
                                    id="datepicker"
                                    name="datepicker"
                                    value={this.state.datePicked}
                                    placeholder="Pick Date"
                                    type="text"
                                    validated={false}
                                    errors={[]}
                                    onChange={(id, value) =>
                                      this.handledatePickedInputChange(value)
                                    }
                                  />
                                </li>
                                <li className="nav-item">
                                  <a
                                    className="nav-link active show"
                                    id="w2--tab1c"
                                    data-toggle="tab"
                                    href="#w2-tab1c"
                                    role="tab"
                                    aria-controls="tab1c"
                                    aria-expanded="true"
                                    aria-selected="true"
                                  >
                                    <i className="icon icon-certificate s-18 ml-3"></i>
                                    Date
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="card-body no-p">
                          <div className="tab-content" id="v-pills-tabContent1">
                            <div
                              className="tab-pane fade show active"
                              id="w2-tab2b"
                              role="tabpanel"
                              aria-labelledby="w2-tab2b"
                            >
                              <div className="card p-3">
                                <div>
                                  <Bar
                                    height={200}
                                    data={{
                                      labels: amountCollectedInstitution,
                                      datasets: [
                                        {
                                          label: "Amount Collected",
                                          data: amount_Collected,
                                          backgroundColor:
                                            "rgba(76,175,80,0.75)",
                                          fill: false,
                                          tension: 0.5,
                                          borderWidth: 1,
                                          width: 3,
                                        },
                                        {
                                          label: "Amount not Collected",
                                          data: amountNotCollected,
                                          backgroundColor:
                                          "rgba(252,206,84,0.75)",
                                          fill: false,
                                          tension: 0.5,
                                          borderWidth: 1,
                                          width: 3,
                                        }
                                      ],
                                    }}
                                    options={{
                                      animation: {
                                        // onComplete: (e) => {
                                        //   let items = this.state.items;
                                        //   var chartname =
                                        //     "dailyMandatesValueChart";
                                        //   items[chartname] =
                                        //     e.chart.toBase64Image();
                                        //   if (items !== this.state.items)
                                        //     this.setState({ items });
                                        // },
                                      },
                                      plugins: {
                                        title: {
                                          display: true,
                                          text: "Amount Collected Vs Amount not Collected",
                                        },
                                      },
                                      indexAxis: "y",
                                      legend: {
                                        display: true,
                                        position: "top",
                                      },
                                      scales: {
                                        y: {
                                          stacked: true,
                                          title: {
                                            display: true,
                                            text: "Institutions",
                                          },
                                        },
                                        x: {
                                          stacked: true,
                                          title: {
                                            display: true,
                                            text: "Collection Values",
                                          },
                                          ticks: {
                                            // Include a dollar sign in the ticks
                                            callback: function (
                                              value,
                                              index,
                                              values
                                            ) {
                                              return "₦" + value;
                                              // return (
                                              //   "₦" +
                                              //   textFormatService.convertKoboToNaira(
                                              //     value,
                                              //     0
                                              //   )
                                              // );
                                            },
                                          },
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>                
              </div>

              <div
                className="tab-pane fade"
                id="w2-tab7"
                role="tabpanel"
                aria-labelledby="w2-tab7"
              >
                <br />
                <div className="row pb-3 col my-3">
                  <button
                    type="button"
                    disabled={fetching}
                    className="btn btn-outline-primary btn-xs float-right mr-2"
                    onClick={() => {
                      this.setState({ dashboard: "Linked Accounts Dashboard" });
                      this.toggleShowShareModal();
                    }}
                  >
                    {fetching === false ? (
                      <React.Fragment>
                        <i className="icon-share2 mr-2"></i>
                        Share
                      </React.Fragment>
                    ) : (
                      <i className="fas fa-spinner fa-spin" />
                    )}
                  </button>
                </div>
                <div className="my-3">
                  <div
                    className="row text-white no-gutters no-m shadow"
                    id="linkedAccountsHeaders"
                  >
                    <div className="col-lg-3">
                      <div className="green counter-box p-40">
                        <h6 className="counter-title">Linked Accounts</h6>
                        <div className="float-right">
                          <img src={resume} alt="mandate" />
                        </div>
                        <div className="sc-counter s-36 counter-animated">
                          {this.state.fetchingLinkedAccountStats ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenNumber(
                              this.state.linkedAccountCount,
                              0
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="blue1 counter-box p-40">
                        <h6 className="counter-title">Linked BVN Account</h6>
                        <div className="float-right">
                          <img src={resume} alt="mandate" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {this.state.fetchingLinkedAccountStats ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenNumber(
                              this.state.linkedBVNAccountCount,
                              0
                            )
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
                <br />
                <div className="row pb-3" id="linkedAccountsTrends">
                  <div className="col">
                    <div className="card no-b">
                      <div className="card-header white">
                        <div className="d-flex justify-content-between">
                          <div className="align-self-center">
                            <strong>Linked Account Trend</strong>
                          </div>
                          <div className="align-self-end float-right">
                            <ul
                              className="nav nav-tabs card-header-tabs nav-material"
                              role="tablist"
                            >
                              <li className="nav-item pr-3">
                                <DetailItemSelectComponent
                                  id="rangeInDays"
                                  name="rangeInDays"
                                  value={rangeInDays}
                                  items={rangeInDaysInputData}
                                  validated={false}
                                  errors={[]}
                                  onChange={(id, value) =>
                                    this.handleOnRangeInputChange(value)
                                  }
                                  disabled={fetchingLinkedAccountStats}
                                />
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link active show"
                                  id="w2--tabl1c"
                                  data-toggle="tab"
                                  href="#w2-tabl1c"
                                  role="tab"
                                  aria-controls="tabl1c"
                                  aria-expanded="true"
                                  aria-selected="true"
                                >
                                  <i className="icon icon-certificate s-18 ml-3"></i>
                                  Account
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  id="w2--tabl2c"
                                  data-toggle="tab"
                                  href="#w2-tabl2c"
                                  role="tab"
                                  aria-controls="tabl2c"
                                  aria-selected="false"
                                >
                                  <i className="icon icon-certificate s-18"></i>
                                  BVN
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="card-body no-p">
                        <div className="tab-content" id="v-pills-tabContent1">
                          <div
                            className="tab-pane fade show active"
                            id="w2-tabl1c"
                            role="tabpanel"
                            aria-labelledby="w2-tabl1c"
                          >
                            <div className="card p-3">
                              <div>
                                <Line
                                  height={120}
                                  data={{
                                    labels: linkedAccountLineChartLabels,
                                    datasets: [
                                      {
                                        label: "Linked Accounts",
                                        data: linkedAccountCountList,
                                        fill: false,
                                        tension: 0.5,
                                        backgroundColor: "rgb(42, 235, 28)",
                                        borderColor: "rgba(42, 235, 28, 0.6)",
                                      },
                                    ],
                                  }}
                                  options={{
                                    plugins: {
                                      title: {
                                        display: true,
                                        text: "Daily Linked Accounts Trend",
                                      },
                                    },
                                    scales: {
                                      x: {
                                        title: {
                                          display: true,
                                          text: "Dates",
                                        },
                                      },
                                      y: {
                                        beginAtZero: true,
                                        title: {
                                          display: true,
                                          text: "Linked Accounts Count",
                                        },
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="w2-tabl2c"
                            role="tabpanel"
                            aria-labelledby="w2-tabl2c"
                          >
                            <div className="card p-3">
                              <div>
                                <Line
                                  height={120}
                                  data={{
                                    labels: linkedAccountLineChartLabels,
                                    datasets: [
                                      {
                                        label: "Linked BVN Accounts",
                                        data: linkedBvnAccountCountList,
                                        fill: false,
                                        tension: 0.5,
                                        backgroundColor: "rgb(54, 162, 235)",
                                        borderColor: "rgba(54, 162, 235, 0.6)",
                                      },
                                    ],
                                  }}
                                  options={{
                                    plugins: {
                                      title: {
                                        display: true,
                                        text: "Daily Linked BVNs Trend",
                                      },
                                    },
                                    scales: {
                                      x: {
                                        title: {
                                          display: true,
                                          text: "Dates",
                                        },
                                      },
                                      y: {
                                        beginAtZero: true,
                                        title: {
                                          display: true,
                                          text: "Linked BVNs Count",
                                        },
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="w2-tab8"
                role="tabpanel"
                aria-labelledby="w2-tab8"
              >
                <br />
                <div className="row pb-3 col my-3">
                  {/* <button
                    type="button"
                    disabled={fetching}
                    className="btn btn-outline-primary btn-xs float-right mr-2"
                    onClick={() => {
                      this.setState({ dashboard: "Linked Accounts Dashboard" });
                      this.toggleShowShareModal();
                    }}
                  >
                    {fetching === false ? (
                      <React.Fragment>
                        <i className="icon-share2 mr-2"></i>
                        Share
                      </React.Fragment>
                    ) : (
                      <i className="fas fa-spinner fa-spin" />
                    )}
                  </button> */}
                </div>
                <div className="my-3">
                  <div
                    className="row text-white no-gutters no-m shadow"
                    id="blackboxHeaders"
                  >
                    <div className="col-lg-3">
                      <div className="green counter-box p-40">
                        <h6 className="counter-title">Total Loan Requests (Amount)</h6>
                        <div className="float-right">
                          <img src={cash} alt="mandate" />
                        </div>
                        <div className="sc-counter s-36 counter-animated">
                          {fetchingBlackboxLoanRequests ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenMoney(
                              totalLoanRequestVolume,
                              0
                            )
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <div className="blue1 counter-box p-40">
                        <h6 className="counter-title">Total Loan Requests (Count)</h6>
                        <div className="float-right">
                          <img src={resume} alt="mandate" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {fetchingBlackboxLoanRequests ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenNumber(
                              totalLoanRequestCount,
                              0
                            )
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="green counter-box p-40">
                        <h6 className="counter-title">Loan Requests (Amount)</h6>
                        <div className="float-right">
                          <img src={cash} alt="mandate" />
                        </div>
                        <div className="sc-counter s-36 counter-animated">
                          {fetchingBlackboxLoanRequests ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenMoney(
                              blackboxLoanRequestAmount,
                              0
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="blue1 counter-box p-40">
                        <h6 className="counter-title">Loan Requests (Count)</h6>
                        <div className="float-right">
                          <img src={resume} alt="mandate" />
                        </div>
                        <span className="sc-counter s-36 counter-animated">
                          {fetchingBlackboxLoanRequests ? (
                            <div className="ml-5 mt-2 preloader-wrapper small active">
                              <div className="spinner-layer spinner-yellow-only">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                  <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            textFormatService.shortenNumber(
                              blackboxLoanRequestCount,
                              0
                            )
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="row pb-3">
                  <div className="col-md-8">
                    <div className="card no-b" id="blackboxLoanRequestCharts">
                      <div className="card-header white">
                        <div className="d-flex justify-content-between">
                          <div className="align-self-center">
                            <strong>Blackbox Loan Requests Statistics</strong>
                          </div>
                          <div className="align-self-end float-right">
                            <ul
                              className="nav nav-tabs card-header-tabs nav-material"
                              role="tablist"
                            >
                              <li className="nav-item">
                                <a
                                  className="nav-link active show"
                                  id="w2--tab81c"
                                  data-toggle="tab"
                                  href="#w2-tab81c"
                                  role="tab"
                                  aria-controls="tab81c"
                                  aria-expanded="true"
                                  aria-selected="true"
                                >
                                  <i className="icon icon-certificate s-18 ml-3"></i>
                                  Counts
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  id="w2--tab82c"
                                  data-toggle="tab"
                                  href="#w2-tab82c"
                                  role="tab"
                                  aria-controls="tab82c"
                                  aria-selected="false"
                                >
                                  <i className="icon icon-money s-18"></i>
                                  Value
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="card-body no-p">
                        <div className="tab-content" id="v-pills-tabContent1">
                          <div
                            className="tab-pane fade show active"
                            id="w2-tab81c"
                            role="tabpanel"
                            aria-labelledby="w2-tab81c"
                          >
                            <div className="card p-3">
                              counts
                              <div>
                                <Bar
                                  height={200}
                                  data={{
                                    labels: institutionNamesLoanRequests,
                                    datasets: [
                                      {
                                        label: "Number of Loan Requests",
                                        data: blackboxLoanRequestCountList,
                                        backgroundColor: "rgb(54, 162, 235,0.75)",
                                        fill: false,
                                        tension: 0.5,
                                        borderWidth: 1,
                                        width: 3,
                                      },
                                    ],
                                  }}
                                  options={{
                                    plugins: {
                                      title: {
                                        display: true,
                                        text: "Blackbox Loan Requests Counts For All Institutions",
                                      },
                                    },
                                    indexAxis: "y",
                                    legend: {
                                      display: true,
                                      position: "top",
                                    },
                                    scales: {
                                      y: {
                                        stacked: true,
                                      },
                                      x: {
                                        stacked: true,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="w2-tab82c"
                            role="tabpanel"
                            aria-labelledby="w2-tab82c"
                          >
                            <div className="card p-3">
                              Amoiuntrs
                              <div>
                                <Bar
                                  height={200}
                                  data={{
                                    labels: institutionNamesLoanRequests,
                                    datasets: [
                                      {
                                        label: "Loan Requests Volume (₦)",
                                        data: blackboxLoanRequestAmountList,
                                        backgroundColor: "rgba(76,175,80,0.75)",
                                        fill: false,
                                        tension: 0.5,
                                        borderWidth: 1,
                                        width: 3,
                                      },
                                    ],
                                  }}
                                  options={{
                                    plugins: {
                                      title: {
                                        display: true,
                                        text: "Blackbox Loan Requests Volume (₦) For All Institutions",
                                      },
                                    },
                                    indexAxis: "y",
                                    legend: {
                                      display: true,
                                      position: "top",
                                    },
                                    scales: {
                                      y: {
                                        stacked: true,
                                      },
                                      x: {
                                        stacked: true,
                                        ticks: {
                                          // Include a dollar sign in the ticks
                                          callback: function (
                                            value,
                                            index,
                                            values
                                          ) {
                                            return "₦" + value;
                                          },
                                        },
                                      },
                                    },
                                    // scales: {
                                    //   y: {
                                    //     stacked: true,
                                    //     title: {
                                    //       display: true,
                                    //       text: "Institutions",
                                    //     },
                                    //   },
                                    //   x: {
                                    //     stacked: true,
                                    //     title: {
                                    //       display: true,
                                    //       text: "Mandate Values",
                                    //     },
                                    //     ticks: {
                                    //       // Include a dollar sign in the ticks
                                    //       callback: function (
                                    //         value,
                                    //         index,
                                    //         values
                                    //       ) {
                                    //         return "₦" + value;
                                    //         // return (
                                    //         //   "₦" +
                                    //         //   textFormatService.convertKoboToNaira(
                                    //         //     value,
                                    //         //     0
                                    //         //   )
                                    //         // );
                                    //       },
                                    //     },
                                    //   },
                                    // }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AppzoneDashboard;
