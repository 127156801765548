import React, { Component, Fragment, useContext } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button, Accordion, Card, AccordionContext, useAccordionToggle } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import { getCurrentUsersRoles } from "../../../services/auth/authService";
import { ContextAwareToggle } from "./addFinancialGroup";
import { Rule } from "./financialGroupComponents/Rule";

export function ContextAwareToggle2({ eventKey, header }) {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      <b>{header}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
}

interface IFinancialGroupDetailProps {
  item: any;
  userRoles: any;
  systemProcess: any
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
}

interface IFinancialGroupDetailState {
  item: any;
  rules: any;
  maxLevel: number;
  updatingStatus: boolean;
}

class FinancialGroupDetail extends Component<
  IFinancialGroupDetailProps,
  IFinancialGroupDetailState
> {
  state = {
    item: {},
    rules: [],
    maxLevel: 1,
    updatingStatus: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.item !== prevState.item) {
      let rules: any = [],
        maxLevel = 1;
      if (nextProps.item["id"]) {
        maxLevel = Math.max(...nextProps.item.rules.map((o) => o.level), 0);
        for (let i = 1; i <= maxLevel; i++) {
          const rulesSet = nextProps.item.rules.filter((x) => x.level === i);
          rulesSet.sort(function (a, b) {
            if (a.combinationNo < b.combinationNo) return -1;
            if (a.combinationNo > b.combinationNo) return 1;
            return 0;
          });
          rules.push(rulesSet);
        }
      }
      return { maxLevel: maxLevel, rules: rules, item: nextProps.item };
    } else return null;
  }

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      item,
      userRoles,
      systemProcess
    } = this.props;
    const { updatingStatus, rules } = this.state;

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="icon icon--columns s-18 mr-3" />
                Workflow Config Details -{" "}
                <span className="font-weight-bold">{item.name}</span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Name"
                        value={item.name}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Maximum Amount"
                        value={item.maximumAmount}
                        labelSize={5}
                        valueSize={7}
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Minimum Amount"
                        value={item.minimumAmount}
                        labelSize={5}
                        valueSize={7}
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>
            <div>
              <Accordion defaultActiveKey="0">
                <div>
                  <Card>
                    <ContextAwareToggle2
                      eventKey={`0`}
                      header={
                        <React.Fragment>
                          <i
                            className="fa fa-info-circle"
                            aria-hidden="true"
                            style={{ color: "#878a8a" }}
                          ></i>{" "}
                          Role Relationships & Validation
                        </React.Fragment>
                      }
                    ></ContextAwareToggle2>
                    <Accordion.Collapse eventKey={`0`}>
                      <Card.Body>
                        <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              {_.isEmpty(item.rules) ? (
                                <div style={{ textAlign: "center", color: "#FE825E" }}>
                                  No Role Relationship has been assigned to Financial Group
                                </div>
                              ) : (
                                <div className="card">
                                  <div className="approvable">
                                    {/* {console.log(item.levels)} */}
                                    {rules.map((step, idx) => (

                                      <div key={idx}>
                                        <Accordion defaultActiveKey={`${idx}`}>
                                          <Card>
                                            <ContextAwareToggle
                                              eventKey={`${idx}`}
                                              levelName={item.levels?.filter(y => y.level == step?.filter(x => x.level == idx + 1)[0]?.level)[0]?.name}
                                              privilegeScreens={item.levels?.filter(y => y.level == step?.filter(x => x.level == idx + 1)[0]?.level)[0]?.functionNames}
                                              approverPage={item.levels?.filter(y => y.level == step?.filter(x => x.level == idx + 1)[0]?.level)[0]?.isApprovalPage}
                                              levelID={idx}
                                              editLevelFunction={() => { }}
                                            ></ContextAwareToggle>
                                            <Accordion.Collapse eventKey={`${idx}`}>
                                              <Card.Body className={"rule-card-body"}>
                                                {" "}
                                                <Rule
                                                  step={step}
                                                  key={idx}
                                                  level={idx + 1}
                                                  viewing={true}
                                                  userRoles={userRoles}
                                                  systemProcess={systemProcess}
                                                />
                                              </Card.Body>
                                            </Accordion.Collapse>
                                          </Card>
                                        </Accordion>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </div>
              </Accordion>
            </div>
          </div>
        </Modal.Body >
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
              {getCurrentUsersRoles().includes("editfinancialgroup") && (
                <Button
                  size="sm"
                  variant="primary"
                  disabled={updatingStatus}
                  onClick={toggleEditModal}
                >
                  <i className="far fa-edit mr-3" />
                  Edit
                </Button>
              )}
            </React.Fragment>
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal >
    );
  }
}

export default FinancialGroupDetail;
