import React, { Component, Fragment, useContext } from "react";
import {
    Modal,
    Button,
    Card,
    useAccordionToggle,
    AccordionContext,
    Accordion,
} from "react-bootstrap";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import BaseFormComponent, { IBaseFormState } from "../../../../components/BaseFormComponent";
import textFormatService, { generateGUID } from "../../../../services/utility/textFormatService";
import ItemsTable, { filterFieldData } from "../../../../components/tableComponents/itemsTable";
import TableFilterPane from "../../../../components/tableComponents/tableFilterPane";
import { getCurrentUserSession, getCurrentUsersRoles } from "../../../../services/auth/authService";
// import TinRequestDetails from "../../../../loanAppraisal/appraisalDetailPages/TinRequestDetails";
// import { ContextAwareToggle } from "./documentCollection";
import { decodeToken } from "react-jwt";
import BaseListComponent, { IFilterData } from "../../../../components/BaseListComponent";
import loanProductService from "../../../../services/hybridAppraisal/loanProductService";
import loanRequestService from "../../../../services/hybridAppraisal/loanRequestService";
import { createFilterData, mapEntityList } from "../../../../services/utility/autoMapperService";
import TinValidationListModal from "./tinValidationList";
import PolicyScoringListModal from "./policyScroingList";
import { ContextAwareToggle } from "./creditSummary";
import { symbol } from "joi";

interface PolicyScoringFields { }

class PolicyScoringForm extends BaseFormComponent<PolicyScoringFields> { }

interface IPolicyScoringModalProps {
    item: any;
    userRoles: any;
    systemProcess: any;
    currentUserSession: any;
}

interface IPolicyScoringModalState {
    item: any;
    updatingStatus: boolean;
    submitting: boolean;
    data: IFilterData;
    currentPage: number;
    pageSize: number;
    tableItems: any;
    fetching: boolean;
    fetchingDetail: boolean;
    totalSize: number;
    filterValueInput: filterFieldData;
    userRoles: any;
    systemProcess: any;
    applicableRules: any;
    appraisalActions: any;
    updateComponent: boolean;
    documentsToUpload: any;
    documentsToDelete: number[];
    canEditItem: boolean;
    updateAvailable: boolean;
    processResponse: any;
    specialConditions: any;
    ownUpdate: boolean;
    showPolicyScoringModal: boolean;
    solidarityGroups: any;
    members: any;
}

class PolicyScoringList extends BaseListComponent<IPolicyScoringModalState> { }

class PolicyScoring extends React.Component<
    IPolicyScoringModalProps,
    IBaseFormState & IPolicyScoringModalState
> {
    _isMounted: boolean;
    constructor(props: IPolicyScoringModalProps) {
        super(props);
        this.state = {
            validated: false,
            submitting: false,
            errors: {},
            item: {},
            updatingStatus: false,
            data: createFilterData(["customerName", "loanType"]) as IFilterData,
            currentPage: 1,
            pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
            fetching: false,
            fetchingDetail: false,
            tableItems: [],
            totalSize: 0,
            filterValueInput: [
                { name: "customerName", fieldType: "text", value: "" },
                {
                    name: "loanType",
                    fieldType: "select",
                    options: ["Individual", "Corporate"],
                    value: "",
                }
            ],
            userRoles: [],
            systemProcess: [],
            applicableRules: [],
            appraisalActions: [],
            documentsToUpload: [],
            documentsToDelete: [],
            updateComponent: false,
            canEditItem: false,
            updateAvailable: false,
            specialConditions: [],
            processResponse: {
                actionId: 0,
                score: 0,
                response: ""
            },
            ownUpdate: false,
            showPolicyScoringModal: false,
            solidarityGroups: [],
            members: []
        };
    }

    async componentDidMount() {
        this._isMounted = true;
        let applicableRules = this.props.item.financialGroup.rules.filter(
            (rule) =>
                rule.level === this.props.item.currentLevel &&
                rule.roleID === this.props.currentUserSession.userRoleId
        );
        const appraisalActions = this.props.item?.appraisalActions;
        const solidarityGroups = this.props.item?.solidarityGroups;
        const scoreCardMembers = appraisalActions?.filter(x => x.systemProcessID === Number(5) && x.effectiveStatus === "Valid")?.reduce((prev, current) => {
            return current.dateCreated > prev.dateCreated ? current : prev;
        }).memberProcessResponses
        const policyMembers = appraisalActions?.filter(x => x.systemProcessID === Number(2) && x.effectiveStatus === "Valid")?.reduce((prev, current) => {
            return current.dateCreated > prev.dateCreated ? current : prev;
        }).memberProcessResponses
        const policyScoringMembers: any = []
        appraisalActions?.filter(x => x.systemProcessID === Number(2) || x.systemProcessID === Number(5))

        scoreCardMembers.forEach(x => {
            const policyScoringMember: any = {}
            policyScoringMember.status = policyMembers.filter(y => (
                x.memberId === y.memberId && x.result === "Pass" && y.result === "Pass"
            )).length > 0 ? "Pass" : "Fail"
            policyScoringMember.solidarityGroupId = x.solidarityGroupId

            policyScoringMembers.push(policyScoringMember)
        })

        console.log(scoreCardMembers)
        console.log(policyScoringMembers)


        if (solidarityGroups) {
            for (let index = 0; index < solidarityGroups.length; index++) {
                if (solidarityGroups[index].logType !== "SystemType") {
                    solidarityGroups[index].memberCount = scoreCardMembers?.filter(x => x.solidarityGroupId === solidarityGroups[index].id)?.length;
                    console.log(policyScoringMembers?.filter(x => x.solidarityGroupId === solidarityGroups[index].id && x.status === "Pass")?.length)
                    solidarityGroups[index].memberPolicyScoringPassCount = policyScoringMembers ? policyScoringMembers?.filter(x => x.solidarityGroupId === solidarityGroups[index].id && x.status === "Pass")?.length : 0
                    solidarityGroups[index].memberScoringPassCount = scoreCardMembers ? scoreCardMembers?.filter(x => x.solidarityGroupId === solidarityGroups[index].id && x.result === "Pass")?.length : 0
                    solidarityGroups[index].memberPolicyPassCount = policyMembers ? policyMembers?.filter(x => x.solidarityGroupId === solidarityGroups[index].id && x.result === "Pass")?.length : 0
                }
            }


            this.setState({ solidarityGroups, ownUpdate: true });

        }

        // console.log(solidarityGroups)
    }

    resetFields() {
        this.setState({
            validated: false,
            submitting: false
        });
    }

    fetchItemById = (id) => {
        var members = this.props.item.solidarityGroups.filter(x => x.id === id)[0]?.members;
        this.setState({ members: members });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleShowPolicyScoringModal = () => {
        this.setState({ showPolicyScoringModal: !this.state.showPolicyScoringModal });
    };

    handleOnExport = async (type: string) => { };

    render() {
        const { item, userRoles, systemProcess, currentUserSession } = this.props;
        const {
            currentPage,
            pageSize,
            tableItems,
            totalSize,
            fetching,
            fetchingDetail,
            submitting,
            showPolicyScoringModal,
            members
        } = this.state;

        if (_.isEmpty(item)) {
            return null;
        }

        console.log(item)

        return (
            <PolicyScoringForm
                initialValues={{}}
                FormComponent={({
                    fields: { }, onChange, onReloadFields, onHandleSubmit,
                }) => (
                    <div className="card">
                        <div className="card-header clear-fix">
                            {_.isEmpty(item) ? (
                                <Skeleton width={200} />
                            ) : (
                                <h6
                                    className="card-title float-left"
                                    style={{ marginBottom: "0px" }}
                                >
                                    <b>Policy & Scoring</b>
                                </h6>
                            )}
                        </div>
                        <div className="card">
                            <Accordion defaultActiveKey="0">
                                <div>
                                    <Card>
                                        <ContextAwareToggle
                                            eventKey={`0`}
                                            header={<React.Fragment>
                                                <i
                                                    className="fa fa-info-circle"
                                                    aria-hidden="true"
                                                    style={{ color: "#878a8a" }}
                                                ></i>{" "}
                                                Policy & Scoring Result
                                            </React.Fragment>}
                                        ></ContextAwareToggle>
                                        <Accordion.Collapse eventKey={`0`}>
                                            <Card.Body>
                                                <div
                                                    className="table-responsive text-nowrap"
                                                    style={{
                                                        height: "300px",
                                                        overflowY: "scroll",
                                                    }}
                                                >

                                                    {/* <h6 className="center">Appraisal Actions</h6> */}
                                                    <table className="table table-hover table-content table-sm table-striped mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th
                                                                    scope="col"
                                                                    style={{
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                    }}
                                                                >
                                                                    <strong>Total Members</strong>
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    style={{
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                    }}
                                                                >
                                                                    <strong>Policy/Scoring Pass</strong>
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    style={{
                                                                        // width: "5px",
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                    }}
                                                                >
                                                                    <strong>Policy Pass</strong>
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    style={{
                                                                        // width: "5px",
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                    }}
                                                                >
                                                                    <strong>Scoring Pass</strong>
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    style={{
                                                                        // width: "5px",
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                    }}
                                                                >
                                                                    <strong>Policy/Scoring Fail</strong>
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    style={{
                                                                        // width: "5px",
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                    }}
                                                                >
                                                                    <strong></strong>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {/* {recipients.length === 0 ? ( */}
                                                            {item.appraisalActions.length === 0 ? (
                                                                <React.Fragment>
                                                                    <tr>
                                                                        No Decisions Made yet
                                                                    </tr>
                                                                </React.Fragment>
                                                            ) : (
                                                                item.solidarityGroups.map((groups) => (
                                                                    <React.Fragment key={generateGUID()}>
                                                                        <tr>
                                                                            <td
                                                                                style={{
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                    textAlign: "center"
                                                                                }}
                                                                            >
                                                                                {groups.memberCount}
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                    textAlign: "center"
                                                                                }}
                                                                            >
                                                                                {Number(groups.memberPolicyScoringPassCount)}
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                    textAlign: "center"
                                                                                }}
                                                                            >
                                                                                {Number(groups.memberPolicyPassCount)}
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                    textAlign: "center"
                                                                                }}
                                                                            >
                                                                                {Number(groups.memberScoringPassCount)}
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                    textAlign: "center"
                                                                                }}
                                                                            >
                                                                                {Number(groups.memberCount - groups.memberPolicyScoringPassCount)}
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                }}
                                                                            >
                                                                                <button
                                                                                    key={groups.id}
                                                                                    className="btn btn-sm btn-outline-primary my-2 mx-3"
                                                                                    style={{ cursor: "pointer" }}
                                                                                    type="button"
                                                                                    // onClick={this.handleUploadDocument}
                                                                                    onClick={(e) => {
                                                                                        this.fetchItemById(groups.id);
                                                                                        this.toggleShowPolicyScoringModal();
                                                                                    }}
                                                                                >
                                                                                    <React.Fragment>
                                                                                        <i className="fas fa-users fa-lg mr-2" />{" "}
                                                                                        {groups.solidarityGroupName}
                                                                                    </React.Fragment>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                        {/* ))} */}
                                                                    </React.Fragment>
                                                                ))
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </div>
                            </Accordion>
                        </div>
                        <PolicyScoringListModal
                            item={item}
                            members={members}
                            userRoles={userRoles}
                            systemProcess={systemProcess}
                            showPatchModal={this.state.showPolicyScoringModal}
                            togglePatchModal={this.toggleShowPolicyScoringModal}
                            currentUserSession={getCurrentUserSession}
                        />
                    </div>

                )}
            ></PolicyScoringForm>
        );
    }
}

export default PolicyScoring;
