import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import { ActionTypes } from "../../../enums/actionTypes";
import swal from "sweetalert";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
// import usersService from '../../../services/auth/usersService'
import { toast } from "react-toastify";
import { getCurrentUsersRoles } from "../../../services/auth/authService";
import rolesService from "../../../services/auth/rolesService";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import CheckboxTree from "react-checkbox-tree";
import { ReportItems } from "../../../enums/reportItems";

interface IMailingListDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
//   showTrail: any;
  item: any;
}

interface IMailingListDetailState {
  item: any;
  updatingStatus: boolean;
  availableReportItems: any;
}

class MailingListDetail extends Component<
  IMailingListDetailProps,
  IMailingListDetailState
> {
  constructor(props: IMailingListDetailProps) {
    super(props);
    this.state = {
      item: {},
      updatingStatus: false,
      availableReportItems: Object.keys(ReportItems)
      .filter((value) => isNaN(Number(value)) === false)
      .map((key) => ({
        id: key,
        description: ReportItems[key],
      }))
    };
  }

  static getDerivedStateFromProps(nextProps: IMailingListDetailProps, prevState) {
    // console.log("current props: ", nextProps.item);
    return {
      item: nextProps.item,
    };
  }

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      item,
    } = this.props;
    const { updatingStatus, availableReportItems } = this.state;

    var reportItemsArray: any = [];
    var recipientsArray: any = [];

    if(!_.isEmpty(item)){
        // console.log(item)
        reportItemsArray = JSON.parse( item.reportItems );
        recipientsArray = JSON.parse( item.recepients );
    }

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="fas fa-user-shield fa-sm cyan-text mr-3" />
                Mailing List Details -{" "}
                <span className="font-weight-bold">{item.name}</span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-header clear-fix">
                  {_.isEmpty(item) ? (
                    <Skeleton width={200} />
                  ) : (
                    <h6
                      className="card-title float-left"
                      style={{ marginBottom: "0px" }}
                    >
                      <b>General Information</b>
                    </h6>
                  )}
                  {/* <div className="float-right">
                    {_.isEmpty(item) ? (
                      <Skeleton width={100} />
                    ) : (
                      <span
                        className="badge btn-primary"
                        onClick={showTrail}
                        style={{
                          cursor: "pointer",
                          visibility: getCurrentUsersRoles().includes(
                            "viewaudittrailitems"
                          )
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        View Trail
                      </span>
                    )}
                  </div> */}
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="form-group col-md-12 row">
                      {_.isEmpty(item) ? (
                        <Skeleton count={1} width={300} />
                      ) : (
                        <DetailItem label="Name" value={item.name} />
                      )}
                    </div>
                    <div className="form-group col-md-12 row">
                      {_.isEmpty(item) ? (
                        <Skeleton count={1} width={300} />
                      ) : (
                        <DetailItem label="Frequency" value={item.frequency} />
                      )}
                    </div>
                    <div className="form-group col-md-12 row">
                      {_.isEmpty(item) ? (
                        <Skeleton count={1} width={300} />
                      ) : (
                        <DetailItem
                          label="Report Format"
                          value={item.reportFormat}
                        />
                      )}
                    </div>
                    <div className="form-group col-md-12 row">
                      {_.isEmpty(item) ? (
                        <Skeleton count={1} width={300} height={200} />
                      ) : (
                          <React.Fragment>
                            <label
                            htmlFor="reportItems"
                            className="control-label textbox-label"
                            >
                            {"  "} Report Items
                            </label>
                            <div
                            className="text-nowrap block-example border"
                            style={{
                                width: "100%",
                                height: "200px",
                                overflowY: "scroll",
                            }}
                            >
                            <React.Fragment>
                                {availableReportItems.length ===
                                0 ? (
                                <div
                                    style={{
                                    backgroundColor: "#F3F3F3",
                                    }}
                                >
                                    <p className="text-center">
                                    No Available Report Items Found
                                    </p>
                                </div>
                                ) : (
                                <React.Fragment>
                                    {reportItemsArray?.map(
                                    (reportItem) => (
                                        <div
                                        className="custom-control custom-checkbox"
                                        key={reportItemsArray.indexOf(reportItem)}
                                        >
                                        <input
                                            id={reportItem}
                                            name={reportItem}
                                            className="custom-control-input"
                                            type="checkbox"
                                            checked={true}
                                            disabled={true}
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor={reportItem}
                                        >
                                            {reportItem}
                                        </label>
                                        </div>
                                    )
                                    )}
                                </React.Fragment>
                                )}
                            </React.Fragment>
                            </div>
                        
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  {_.isEmpty(item) ? (
                    <Skeleton width={200} />
                  ) : (
                    <h6 className="card-title" style={{ marginBottom: "0px" }}>
                      Recipients Information
                    </h6>
                  )}
                </div>

                <div className="card-body">
                  {_.isEmpty(this.state.item) ? (
                    <Skeleton count={1} height={500} />
                  ) : (
                    <div
                      className="table-responsive text-nowrap"
                      style={{
                        maxHeight: "400px",
                        overflowY: "scroll",
                      }}
                    >
                      <table className="table table-hover table-content table-sm table-striped mb-0">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              style={{
                                width: "5px",
                                paddingTop: "0.2rem",
                                paddingBottom: "0.2rem",
                              }}
                            >
                              <strong>#</strong>
                            </th>
                            <th
                              scope="col"
                              style={{
                                paddingTop: "0.2rem",
                                paddingBottom: "0.2rem",
                              }}
                            >
                              <strong>Recipient Name</strong>
                            </th>
                            <th
                              scope="col"
                              style={{
                                // width: "5px",
                                paddingTop: "0.2rem",
                                paddingBottom: "0.2rem",
                              }}
                            >
                              <strong>Recipient Email</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {recipientsArray.length === 0 ? (
                            <React.Fragment>
                              <tr>
                                No contacts added yet
                              </tr>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              {recipientsArray.map((item) => (
                                <tr key={recipientsArray.indexOf(item)}>
                                  <td
                                    style={{
                                      width: "5px",
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {recipientsArray.indexOf(
                                      item
                                    ) + 1}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item.Name}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item.Email}
                                  </td>
                                </tr>
                              ))}
                            </React.Fragment>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
            {getCurrentUsersRoles().includes("editmailinglist") && (
              <Button
                size="sm"
                variant="primary"
                disabled={updatingStatus}
                onClick={toggleEditModal}
              >
                <i className="far fa-edit mr-3" />
                Edit
              </Button>
            )}
            </React.Fragment>
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default MailingListDetail;
