import Joi from "joi-browser";
import _ from "lodash";

export function validateEditDisputeConditionForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      name: Joi.string()
        .required()
        .error((errors) => {
          // console.log("name joi-error ", errors);
          return {
            message: "Name is required.",
          };
        }),
      code: Joi.string()
        .required()
        .error((errors) => {
          // console.log("code joi-error ", errors);
          return {
            message: "Code is required.",
          };
        }),
      disputeFilingTimeLimit: Joi.string()
        .required()
        .error((errors) => {
          // console.log("disputeFilingTimeLimit joi-error ", errors);
          return {
            message: "Dispute Filing Time Limit is required.",
          };
        }),
      maxResolutionTAT: Joi.number()
        .required()
        .error((errors) => {
          // console.log("maxResolutionTAT joi-error ", errors);
          return {
            message: "MaxResolutionTAT is required.",
          };
        }),
      description: Joi.string()
        .required()
        .error((errors) => {
          // console.log("description joi-error ", errors);
          return {
            message: "Description is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddDisputeConditionForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      name: Joi.string()
        .required()
        .error((errors) => {
          // console.log("name joi-error ", errors);
          return {
            message: "Name is required.",
          };
        }),
      disputeFilingTimeLimit: Joi.string()
        .required()
        .error((errors) => {
          return { message: "DisputeFilingTimeLimit is required. " };
        }),
      description: Joi.string()
        .required()
        .error((errors) => {
          // console.log("name joi-error ", errors);
          return {
            message: "Description is required.",
          };
        }),
      code: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Code is required. " };
        }),
      // categoryGroupId: Joi.number()
      //   .required()
      //   .error(errors => {
      //     // console.log("categoryGroupId joi-error ", errors);
      //     return {
      //       message: "Category Group Id is required."
      //     };
      //   }),
      // maxResolutionTATInterval: Joi.string()
      //   .required()
      //   .error(errors => {
      //     // console.log("maxResolutionTATInterval joi-error ", errors);
      //     return {
      //       message: "MaxResolutionTATInterval is required."
      //     };
      //   }),
      maxResolutionTAT: Joi.string()
        .required()
        .error((errors) => {
          // console.log("maxResolutionTAT joi-error ", errors);
          return {
            message: "maxResolutionTAT is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateLogDisputeForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      comment: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Comment is requred." };
        }),
      disputeCondition: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Dispute Condition is requred." };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateDisputeDetailForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      comment: Joi.string()
        .required()
        .error((errors) => {
          // console.log("comment joi-error ", errors);
          return {
            message: "Comment is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export default {
  validateEditDisputeConditionForm,
  validateAddDisputeConditionForm,
  validateLogDisputeForm,
  validateDisputeDetailForm,
};
