import React, { useCallback, useEffect, useState } from "react";
import institutionsService from "../../../../services/_axiosServices/sweep/institutions";
import { Loader, Error } from "../../components";

const mockData = [
  {
    name: "--",
    shortName: "--",
    code: "--",
    status: "--",
    externalInstitutionCode: "--",
    integrationCode: "",
    cbnCode: "--",
    dmb: false,
    feeIncomeAccount: "",
    feeExpenseAccount: "",
    settlementMirrorAccount: "--",
    receivableAccount: "",
    loanCollectionsAccount: "",
    suspenseAccount: "",
    chargeCustomer: false,
    nipBankCode: "",
    appZoneNIPAccountName: "",
    appZoneNIPAccountNo: "",
    sweepInitiationType: "--",
    guarantorSweepInitiationType: "--",
    guarantorMoratoriumDays: 0,
    sweepable: false,
    lostLoanFeePercentage: 0,
    feeChargedPercentage: 1,
    sponsorBankCommission: 20,
    debitedBankCommission: 25,
    allowIncomingLoanUpdateCall: false,
    allowIncomingCostRecognitionCall: false,
    settlementAccounts: null,
    disableNIPSettlement: true,
    totalInflow: 0,
    totalOutflow: 0,
    thresholdBaseLine: 0,
    threshold: 0,
    feeConfigs: null,
    id: 1,
  },
];

export default function Report() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(mockData);
  const [error, setError] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    await institutionsService
      .getAll()
      .then((res: any) => {
        // console.log(res);
        setLoading(false);
        setData(res.data);
      })
      .catch((err: any) => {
        setLoading(false);
        setError(true);
        console.log(err.message);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="container-fluid relative animatedParent animateOnce">
      <div className="animated fadeInUpShort go">
        <div className="row my-3 mx-2">
          <h3>
            <b>Institutions</b>
          </h3>
        </div>
        <div className="card no-b">
          <div className="card-body">
            <div className="card-title">
              <div className="page">
                <div className="page-inner">
                  <div className="btn-toolbar justify-content-between">
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn btn-sm"
                        onClick={fetchData}
                      >
                        <i className="fas fa-sync"></i>
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive text-nowrap">
                    {loading && <Loader />}

                    {!loading && !error && (
                      <table className="table table-hover table-content table-striped table-bordered table-sm paged-output">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Short Name</th>
                            <th>Code</th>
                            <th>Status</th>
                            <th>External Institution Code</th>
                            <th>Integration Code</th>
                            <th>CBN Code</th>
                            <th>DMB</th>
                            <th>Fee Income Account</th>
                            <th>Fee Expense Account</th>
                            <th>Settlement Mirror Account</th>
                            <th>Receivable Account</th>
                            <th>Loan Collections Account</th>
                            <th>Suspense Account</th>
                            <th>Charge Customer</th>
                            <th>NIP Bank Code</th>
                            <th>AppZone NIP Account Name</th>
                            <th>AppZone NIP Account No</th>
                            <th>Sweep InitiationType</th>
                            <th>GuarantorSweep Initiation Type</th>
                            <th>Guarantor Moratorium Days</th>
                            <th>Sweepable</th>
                            <th>Lost Loan Fee Percentage</th>
                            <th>Fee Charged Percentage</th>
                            <th>Sponsor Bank Commission</th>
                            <th>Debited Bank Commission</th>
                            <th>Allow Incoming LoanUpdate Call</th>
                            <th>Allow Incoming Cost Recognition Call</th>
                            <th>Settlement Accounts</th>
                            <th>Disable NIP Settlement</th>
                            <th>Total Inflow</th>
                            <th>Total Outflow</th>
                            <th>Threshold BaseLine</th>
                            <th>Threshold</th>
                            <th>Fee Configs</th>
                            <th>id</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((d: any, i: number) => (
                            <tr key={i}>
                              <th scope="row">{i + 1}</th>
                              <th>{d.name}</th>
                              <th>{d.shortName}</th>
                              <th>{d.code}</th>
                              <th>{d.status}</th>
                              <th>{d.externalInstitutionCode}</th>
                              <th>{d.integrationCode}</th>
                              <th>{d.cbnCode}</th>
                              <th>{d.dmb}</th>
                              <th>{d.feeIncomeAccount}</th>
                              <th>{d.feeExpenseAccount}</th>
                              <th>{d.settlementMirrorAccount}</th>
                              <th>{d.receivableAccount}</th>
                              <th>{d.loanCollectionsAccount}</th>
                              <th>{d.suspenseAccount}</th>
                              <th>{d.chargeCustomer}</th>
                              <th>{d.nipBankCode}</th>
                              <th>{d.appZoneNIPAccountName}</th>
                              <th>{d.appZoneNIPAccountNo}</th>
                              <th>{d.sweepInitiationType}</th>
                              <th>{d.guarantorSweepInitiationType}</th>
                              <th>{d.guarantorMoratoriumDays}</th>
                              <th>{d.sweepable}</th>
                              <th>{d.lostLoanFeePercentage}</th>
                              <th>{d.feeChargedPercentage}</th>
                              <th>{d.sponsorBankCommission}</th>
                              <th>{d.debitedBankCommission}</th>
                              <th>{d.allowIncomingLoanUpdateCall}</th>
                              <th>{d.allowIncomingCostRecognitionCall}</th>
                              <th>{d.settlementAccounts}</th>
                              <th>{d.disableNIPSettlement}</th>
                              <th>{d.totalInflow}</th>
                              <th>{d.totalOutflow}</th>
                              <th>{d.thresholdBaseLine}</th>
                              <th>{d.threshold}</th>
                              <th>{d.feeConfigs}</th>
                              <th>{d.id}</th>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}

                    {error && !loading && <Error />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
