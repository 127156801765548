import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import corporateAnalysisService from "../../../../../../../../services/hybridAppraisal/corporateAnalysisService";
import { Button } from "react-bootstrap";
import _ from "lodash";

export default function CollateralValuation({ loan }: any) {
  const [loading, setLoading] = useState(false);
  const [collateralValuation, setCollateralValuation] =
    useState<CollateralValuation>();
  const [collateralValuationRecords, setCollateralValuationRecords] = useState<
    CollateralValuation[]
  >([]);

  const usedItemNames = collateralValuationRecords?.map(({ itemName }) =>
    itemName.toLowerCase()
  );

  const [isUploaded, setIsUploaded] = useState<any>();

  useEffect(() => {
    const getUploadStatus = async () => {
      const response = await corporateAnalysisService.checkUploaded({
        loanRequestId: loan?.id,
      });
      if (response?.status === 200) {
        setIsUploaded(response?.data);
      }
    };
    getUploadStatus();
  }, [loan]);

  useEffect(() => {
    if (!!isUploaded?.cvtData) {
      const getCollateralRecords = async () => {
        const response = await corporateAnalysisService.previewCVTRecords({
          loanRequestId: loan?.id,
        });
        if (response?.status === 200) {
          setCollateralValuationRecords(response?.data?.length!>0 ? response?.data : []);
        }
      };
      getCollateralRecords();
    }
  }, [loan, isUploaded]);

  const cvtOptions: Options[] = [
    {
      label: "Item Name",
      value: "itemName",
      type: "select",
      selectOptions: [
        "-Select Option-",
        ...collateralItemNames
          ?.filter(({ name }) => !usedItemNames?.includes(name.toLowerCase()))
          ?.map(({ name }) => name),
      ],
    },
    {
      label: "Collateral Type",
      value: "collateralType",
      type: collateralValuation?.itemName === "Property" ? "select" : "text",
      selectOptions:
        collateralValuation?.itemName === "Property"
          ? [
              "-Select Option-",
              ...(collateralItemNames.find(
                ({ name }) => name === collateralValuation?.itemName
              )?.collateralType as string[]),
            ]
          : [],
      disabled: collateralValuation?.itemName !== "Property",
    },
    {
      label: "Item Description",
      value: "itemDescription",
      disabled: collateralValuation?.itemName !== "Property",
    },
    {
      label: "Serial Number",
      value: "serialNumber",
      disabled: collateralValuation?.itemName !== "Property",
    },
    { label: "Original Value", value: "originalValue", type: "number" },
    {
      label: "Assessed Condition",
      value: "assessedCondition",
      type: "select",
      selectOptions: ["-Select Option-", "Good", "Fair", "Poor"],
      disabled: collateralValuation?.collateralType === "Equity",
    },
  ];

  const handleAddCVTRecord = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    const singleRecord = {
      ...collateralValuation,
      assessedCondition:
        collateralValuation?.itemName !== "Equity"
          ? collateralValuation?.assessedCondition
          : "Good",
    };
    setCollateralValuationRecords(prev=>[
      ...prev,
      singleRecord,
    ]);
    setCollateralValuation(undefined);
  };
  const loanCollaterals: CollateralValuation[] = loan?.collaterals
    ?.filter(({ category }) => category === "Property")
    ?.map(
      ({
        description,
        serialNo,
        value,
        assessedCondition,
        propLocation,
        type,
        ownershipStructure,
      }) => ({
        itemName: "Property",
        itemDescription: description,
        collateralType: type,
        assessedCondition: !!assessedCondition ? assessedCondition : "N/A",
        serialNumber: serialNo,
        location: propLocation,
        weight: getWeight(type),
        originalValue: value,
        estimatedForcedSaleValue: value,
        ownershipStructure,
      })
    );

  const combinedCollateral = useMemo(() => {
    switch (true) {
      case loanCollaterals?.length > 0 &&
        collateralValuationRecords?.length > 0:
        return [...loanCollaterals, ...collateralValuationRecords];
      case loanCollaterals?.length > 0 &&
        collateralValuationRecords?.length < 1:
        return loanCollaterals;
      case loanCollaterals?.length < 1 &&
        collateralValuationRecords?.length > 0:
        return collateralValuationRecords;
      default:
        return collateralValuationRecords;
    }
  }, [loanCollaterals, collateralValuationRecords]);

  const handleSubmitCVTRecords = useCallback(async () => {
    try {
      setLoading(true);
      await corporateAnalysisService.submitCVTRecords(
        {
          cvtData: combinedCollateral?.filter((x)=>x.itemName !== 'Property'),
          dateCreated: new Date()?.toISOString(),
          dateUpdated: new Date()?.toISOString(),
        },
        loan?.institutionCode,
        loan?.id
      );
      toast.success("Collateral Valuation submitted successfully");
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      toast.error(error?.response?.data?.Message);
      setLoading(false);
    }
  }, [loan, combinedCollateral]);

  const setWeight = (value: number) => {
    setCollateralValuation((prev) => ({ ...prev, weight: Number(value) }));
  };

  const handleChangeCVTFields = (e: any, type: Options["type"]) => {
    const { name, value } = e.target;
    const selectedCollateral = collateralItemNames?.find(
      ({ name }) => name === value
    )?.collateralType as string;

    if (name === "collateralType") {
      switch (value) {
        case "Residential space":
          setWeight(15);
          break;
        case "Non-Residential space":
        case "Production space":
          setWeight(14);
          break;
        case "Land":
          setWeight(9);
          break;
        default:
          setWeight(0);
          break;
      }
    }
    if (name === "itemName") {
      switch (selectedCollateral) {
        case "Gold":
          setWeight(20);
          break;
        case "Vehicle":
          setWeight(5);
          break;
        case "Equipment and machinery":
          setWeight(4);
          break;
        case "Domestic appliances":
          setWeight(3);
          break;
        case "Furniture":
          setWeight(4);
          break;
        case "Antiques":
          setWeight(4);
          break;
        case "Inventory":
          setWeight(6);
          break;
        case "Guarantee":
          setWeight(2);
          break;
        default:
          setWeight(0);
          break;
      }
    }
    if (name === "itemName") {
      setCollateralValuation((prev) => ({
        ...prev,
        collateralType:
          value !== "Property"
            ? selectedCollateral
            : collateralValuation?.collateralType,
      }));
    }
    if (
      name === "originalValue" &&
      collateralValuation?.collateralType === "Equity"
    ) {
      setCollateralValuation((prev) => ({
        ...prev,
        collateralValue: Number(value),
      }));
    }
    setCollateralValuation((prev) => ({
      ...prev,
      [name]: formatType(type, value),
    }));
  };

  const cvtDataType = (index: number) => {
    switch (cvtOptions[index].type) {
      case "number":
        return "number";
      case "date":
        return "date";
      case "text":
        return "text";
      default:
        return "text";
    }
  };

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.stopPropagation();
          e.preventDefault();
          !_.isEmpty(collateralValuation) && handleAddCVTRecord(e);
        }}
      >
        <p style={{ marginTop: 20, fontSize: 16, fontWeight: "bold" }}>
          Collateral Valuation Tool
        </p>
        <div className="col-grid-2">
          {cvtOptions?.map((item, index) => (
            <div key={index}>
              <label htmlFor={item?.value}>{item?.label}</label>
              {item?.type !== "select" ? (
                <input
                  required={!_.isEmpty(collateralValuation)}
                  name={item?.value}
                  className="basic-input"
                  value={collateralValuation?.[item?.value] || ""}
                  type={cvtDataType(index)}
                  defaultValue={""}
                  disabled={item?.disabled}
                  onChange={(e) => handleChangeCVTFields(e, item?.type)}
                />
              ) : (
                <select
                  required={!_.isEmpty(collateralValuation)}
                  name={item?.value}
                  className="basic-select"
                  value={collateralValuation?.[item?.value] || ""}
                  defaultValue={""}
                  disabled={item?.disabled}
                  onChange={(e) => handleChangeCVTFields(e, item?.type)}
                >
                  {item?.selectOptions?.map((option) => (
                    <option key={option}>{option}</option>
                  ))}
                </select>
              )}
            </div>
          ))}
        </div>
        <button
          className="btn btn-sm btn-outline-primary my-4"
          style={{ cursor: "pointer" }}
          type="submit"
          disabled={false}
        >
          <React.Fragment>
            <i className="fas fa-plus-circle mr-2" /> Add
          </React.Fragment>
        </button>
        <div className="row clearfix">
          <div style={{ marginBottom: 30 }} className=" form-row col-md-12">
            <div
              className="table-responsive text-nowrap"
              style={{
                overflowY: "scroll",
              }}
            >
              <table className="table table-hover table-content table-sm table-striped mb-0">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                      }}
                    >
                      <strong>#</strong>
                    </th>
                    <th
                      scope="col"
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                      }}
                    >
                      <strong>Item Name</strong>
                    </th>
                    <th
                      scope="col"
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                      }}
                    >
                      <strong>Collateral Type</strong>
                    </th>
                    <th
                      scope="col"
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                      }}
                    >
                      <strong>Weight</strong>
                    </th>
                    <th
                      scope="col"
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                      }}
                    >
                      <strong>Original Value</strong>
                    </th>
                    <th
                      scope="col"
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                      }}
                    >
                      <strong>Assessed Condition</strong>
                    </th>
                    <th
                      scope="col"
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                      }}
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {combinedCollateral?.map((item, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                        }}
                      >
                        {item?.itemName}
                      </td>
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                        }}
                      >
                        {item?.collateralType}
                      </td>
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                        }}
                      >
                        {item?.weight}
                      </td>
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                        }}
                      >
                        {item?.originalValue}
                      </td>
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                        }}
                      >
                        {item?.assessedCondition}
                      </td>
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                        }}
                      >
                        {item?.collateralType !== "Property" && (
                          <i
                            className="far fa-trash-alt ml-5 red-text fa-lg"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              const newCollateralVal =
                                collateralValuationRecords?.filter(
                                  (prevItem) => prevItem !== item
                                );
                              setCollateralValuationRecords(newCollateralVal);
                            }}
                          ></i>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Button
          onClick={handleSubmitCVTRecords}
          style={{ marginTop: 20 }}
          size="sm"
          variant="primary"
        >
          {!loading ? (
            <React.Fragment>
              <i className="fas fa-lg fa-save mr-3" />
              Save
            </React.Fragment>
          ) : (
            <React.Fragment>
              <i className="fas fa-spin fa-circle-notch" />
            </React.Fragment>
          )}
        </Button>
      </form>
    </div>
  );
}

type Options = {
  label: string;
  value: string;
  type?: "date" | "text" | "number" | "select";
  selectOptions?: string[];
  disabled?: boolean;
};

const formatType = (type: Options["type"], value: any) => {
  switch (type) {
    case "number":
      return Number(value);
    case "date":
      return value;
    case "text":
      return value;
    default:
      return value;
  }
};

export type CollateralSummary = {
  id: number;
  collateralType: string;
  isLandOrEquity: string;
  weight: number;
  count: number;
  assignedWeights: number;
  dateCreated: string;
  dateUpdated: string;
};
export type CollateralValuation = {
  id: number;
  serialNumber: string;
  location: string;
  weight: number;
  itemName: string;
  itemDescription: string;
  collateralType: string;
  originalValue: number;
  collateralValue: number;
  assessedCondition: string;
  estimatedForcedSaleValue: number;
  dateCreated: string;
  dateUpdated: string;
};

export const collateralItemNames: {
  name: string;
  collateralType: string | string[];
}[] = [
  { name: "Deep freezers", collateralType: "Domestic appliances" },
  {
    name: "Voltage Regulators/stabilizer",
    collateralType: "Domestic appliances",
  },
  { name: "Microwaves", collateralType: "Domestic appliances" },
  { name: "Gas cylinders", collateralType: "Domestic appliances" },
  { name: "Gas Cookers", collateralType: "Domestic appliances" },
  { name: "Electric Cookers", collateralType: "Domestic appliances" },
  { name: "Water heaters/boilers", collateralType: "Domestic appliances" },
  { name: "Weighing scales", collateralType: "Domestic appliances" },
  { name: "Television screen", collateralType: "Domestic appliances" },
  { name: "Radios", collateralType: "Domestic appliances" },
  { name: "Fans", collateralType: "Domestic appliances" },
  { name: "Air conditioner", collateralType: "Domestic appliances" },
  { name: "Video deck", collateralType: "Domestic appliances" },
  { name: "HiFi System", collateralType: "Domestic appliances" },
  { name: "Radio Cassette Player", collateralType: "Domestic appliances" },
  { name: "DVD/VCD/CD Player", collateralType: "Domestic appliances" },
  { name: "Photocopy machine", collateralType: "Equipment and machinery" },
  { name: "Posho Mills", collateralType: "Equipment and machinery" },
  { name: "Welding Machines", collateralType: "Equipment and machinery" },
  {
    name: "Portable/small generators",
    collateralType: "Equipment and machinery",
  },
  { name: "Fax machine", collateralType: "Equipment and machinery" },
  { name: "Sewing Machines", collateralType: "Equipment and machinery" },
  {
    name: "Other business machines",
    collateralType: "Equipment and machinery",
  },
  { name: "Minibus", collateralType: "Vehicle" },
  { name: "Saloon Car", collateralType: "Vehicle" },
  { name: "Four Wheel drives (SUVs)", collateralType: "Vehicle" },
  { name: "Trucks", collateralType: "Vehicle" },
  { name: "Fishing boats", collateralType: "Vehicle" },
  { name: "Gold", collateralType: "Gold" },
  { name: "Silver", collateralType: "Gold" },
  { name: "Bedroom sets", collateralType: "Furniture" },
  { name: "Cupboards", collateralType: "Furniture" },
  { name: "Equity", collateralType: "Equity" },
  // {
  //   name: "Property",
  //   collateralType: [
  //     "Residential space",
  //     "Non-Residential space",
  //     "Production space",
  //     "Land",
  //   ],
  // },
];
const getWeight = (type: string) => {
  switch (type) {
    case "Residential space":
      return 15;
    case "Non-Residential space":
    case "Non-residential space":
    case "Production space":
      return 14;
    case "Land":
      return 9;
    case "Gold":
      return 20;
    case "Vehicle":
      return 5;
    case "Equipment and machinery":
      return 4;
    case "Domestic appliances":
      return 3;
    case "Furniture":
      return 4;
    case "Antiques":
      return 4;
    case "Inventory":
      return 6;
    case "Guarantee":
      return 2;
    default:
      return 0;
  }
};
