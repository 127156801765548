import * as React from "react";
// import _ from "lodash";
import { Fragment } from "react";
import {
  IFilterData,
  BaseListComponentState,
} from "../../components/BaseListComponent";
import {
  createFilterData,
  mapEntityList,
} from "./../../services/utility/autoMapperService";
import BaseListComponent from "../../components/BaseListComponent";
import ItemsTable, {
  filterFieldData,
} from "../../components/tableComponents/itemsTable";
import TableFilterPane from "../../components/tableComponents/tableFilterPane";
import disputeConditionService from "../../services/dispute/disputeConditionService";
import DisputeConditionDetail from "./disputeConditionDetail";
import { getCurrentUsersRoles } from "../../services/auth/authService";
import AuditTrailDetail from "../auditTrail/auditTrailDetail";
import EditDisputeCondition from "./editDisputeCondition";

export interface DisputeConditionsProps {}

export interface DisputeConditionsState extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
  documents: any;
}

class DisputeConditionsList extends BaseListComponent<DisputeConditionsState> {}

class DisputeConditions extends React.Component<
  DisputeConditionsProps,
  DisputeConditionsState
> {
  constructor(props: DisputeConditionsProps) {
    super(props);
    this.state = {
      data: createFilterData(["name", "disputeFilingTimeLimit"]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      documents: [],
      totalSize: 0,
      filterValueInput: [
        { name: "name", fieldType: "text", value: "" },
        {
          name: "disputeFilingTimeLimit",
          fieldType: "select",
          options: [
            "Hourly",
            "Daily",
            "Weekly",
            "BiMonthly",
            "Monthly",
            "Quaterly",
          ],
          value: "",
        },
      ],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
    };
  }

  handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true });
    data = typeof data === "undefined" ? this.state.data : data;
    try {
      const disputeConditions =
        await disputeConditionService.filterDisputeConditions(
          data,
          pageSize,
          currentPage
        );
      const documents =
        await disputeConditionService.retrieveAllDisputeConditionDocuments();
      this.setState({
        documents: documents.data,
        tableItems: mapEntityList({
          entityList: disputeConditions.data.item2,
          properties: [
            { oldName: "name", newName: "Name" },
            { oldName: "code", newName: "Code" },
            {
              oldName: "maxResolutionTAT",
              newName: "maxResolutionTAT (Hours)",
            },
            {
              isTag: true,
              oldName: "disputeFilingTimeLimit",
              newName: "DisputeFilingTimeLimit",
              success: "Active",
              danger: "Hourly",
              default: "Unlimited",
              primary: "_1_Year",
              secondary: "BiMonthly",
              warning: "_3_Days",
              light: "_180_Days",
              dark: "Quaterly",
              split: false,
            },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: disputeConditions.data.item1,
        fetching: false,
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
        // this.setState({ errors, fetching: false });
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        // this.setState({ errors, fetching: false });
      }
    } finally {
      this.setState({ fetching: false });
    }
  };

  handleOnExport = async (type: string) => {};

  fetchItemById = async (item: any) => {
    this.setState({ fetchingDetail: true });
    // console.log("fetching branch with id: ", item.id);
    const disputeCondition =
      await disputeConditionService.retrieveDisputeConditionById(item.id);
    // console.log("branch: ", branch.data);
    this.setState({ item: disputeCondition.data, fetchingDetail: false });
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }

  render() {
    const {
      // data,
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Dispute Conditions</b>
              </h3>
            </div>
            <DisputeConditionsList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              initialValues={{
                data: createFilterData([
                  "name",
                  "disputeFilingTimeLimit",
                ]) as IFilterData,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: [
                  { name: "name", fieldType: "text", value: "" },
                  {
                    name: "disputeFilingTimeLimit",
                    fieldType: "select",
                    options: [
                      "Hourly",
                      "Daily",
                      "Weekly",
                      "BiMonthly",
                      "Monthly",
                      "Quaterly",
                    ],
                    value: "",
                  },
                ],
                item: {},
                jsonAfter: {},
                fetchingTrail: false,
                entityName: "Recova.Dispute.DisputeCondition",
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                onToggleEditModal,
                showDetailsModal,
                showEditModal,
                fetchTrailItem,
                fetchingTrail,
                jsonAfter,
                jsonBefore,
                onToggleTrailModal,
                showTrailModal,
                trailItem,
                trailMapper,
              }) => (
                <Fragment>
                  <DisputeConditionDetail
                    showDetailsModal={showDetailsModal}
                    toggleDetailsModal={onToggleDetailsModal}
                    toggleEditModal={onToggleEditModal}
                    showTrail={() => onToggleTrailModal(item)}
                    currentUserRoles={getCurrentUsersRoles()}
                    item={item}
                  />
                  <EditDisputeCondition
                    showEditModal={showEditModal}
                    toggleEditModal={(reloadDetails: boolean, item: any) => {
                      onToggleEditModal();
                      if (reloadDetails === true) {
                        this.fetchItemById(item);
                      }
                      this.setState({
                        showDetailsModal: !this.state.showDetailsModal,
                      });
                    }}
                    item={item}
                    documents={this.state.documents}
                  />
                  <AuditTrailDetail
                    showDetailsModal={showTrailModal}
                    toggleDetailsModal={onToggleTrailModal}
                    fetchNextTrail={fetchTrailItem}
                    item={trailItem}
                    trailMapper={trailMapper}
                    fetchingDetail={fetchingTrail}
                    jsonBefore={jsonBefore}
                    jsonAfter={jsonAfter}
                  />
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="DisputeConditions"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></DisputeConditionsList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default DisputeConditions;
