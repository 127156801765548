import * as React from "react";
import _ from "lodash";
import { Fragment } from "react";
import {
  IFilterData,
  BaseListComponentState,
} from "../../../components/BaseListComponent";
import {
  createFilterData,
  mapEntityList,
} from "./../../../services/utility/autoMapperService";
import BaseListComponent from "./../../../components/BaseListComponent";
import ItemsTable, {
  filterFieldData,
} from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "./../../../components/tableComponents/tableFilterPane";
import {
  getCurrentUserSession,
  getCurrentUsersRoles,
} from "../../../services/auth/authService";
import workflowService from "../../../services/creditAssessment/workflowService";
import WorkFlowDetail from "./workFlowDetail";
import EditWorkFlow from "./editWorkFlow";
import { decodeToken } from "react-jwt";
import { filterList } from "../../../services/utility/textFormatService";
// import AddWorkFlowTransition from "./addWorkFlowTransition";

export interface WorkFlowsProps { }

export interface WorkFlowsState extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
  allItems: any;
  // showTransitionModal: boolean;
}

class WorkFlowsList extends BaseListComponent<WorkFlowsState> { }

class WorkFlows extends React.Component<WorkFlowsProps, WorkFlowsState> {
  constructor(props: WorkFlowsProps) {
    super(props);
    this.state = {
      data: createFilterData([
        "workflowName",
        "description"
      ]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [
        { name: "workflowName", fieldType: "text", value: "" },
        { name: "description", fieldType: "text", value: "" }
      ],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      // showTransitionModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
      allItems: [],
    };
  }

  handlePageChange = async (currentPage: number, pageSize: number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true });
    let dataClone = typeof data === "undefined" ? { ...this.state.data } : { ...data };
    try {
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const wFlows = await workflowService.retrieveAllByInstitution(
        decodedToken?.["InstitutionCode"]
      );
      const workFlows: any = [];
      const filteredWFlows = filterList(wFlows.data, dataClone);
      const totalLength = filteredWFlows.length;
      const initial = (currentPage - 1) * pageSize;
      for (let i = initial; i < initial + pageSize; i++) {
        if (i === totalLength) {
          break;
        }
        workFlows.push(filteredWFlows[i]);
      }

      this.setState({
        tableItems: mapEntityList({
          entityList: workFlows,
          properties: [
            { oldName: "workflowName", newName: "name" },
            { oldName: "description", newName: "description" },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: totalLength,
        fetching: false,
        allItems: wFlows.data,
      });
    } catch (ex) {
      //   console.log("caught exception", ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
        this.setState({ fetching: false });
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        this.setState({ fetching: false });
      }
    }
  };

  handleOnExport = async (type: string) => { };

  // onToggleTransitionModal = () => {
  //   this.setState({
  //     showTransitionModal: !this.state.showTransitionModal,
  //     showDetailsModal: !this.state.showDetailsModal,
  //   });
  // };

  fetchItemById = async (item: any) => {
    if (!_.isEmpty(item)) {
      this.setState({ fetchingDetail: true });
      const flow = await workflowService.retrieveById(item.id);
      this.setState({ item: flow.data, fetchingDetail: false });
    }
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }

  render() {
    const {
      // data,
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Work Flows</b>
              </h3>
            </div>
            <WorkFlowsList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              //   updateState={this.state.updateBaseListState}
              initialValues={{
                data: this.state.data,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: this.state.filterValueInput,
                item: {},
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                onToggleEditModal,
                showDetailsModal,
                showEditModal,
              }) => (
                <Fragment>
                  <WorkFlowDetail
                    showDetailsModal={showDetailsModal}
                    toggleDetailsModal={onToggleDetailsModal}
                    toggleEditModal={onToggleEditModal}
                    // toggleTransitionModal={() => {
                    //   this.onToggleTransitionModal();
                    //   onToggleDetailsModal();
                    // }}
                    showTrail={null}
                    currentUserRoles={getCurrentUsersRoles()}
                    item={item}
                  />
                  <EditWorkFlow
                    showEditModal={showEditModal}
                    toggleEditModal={(reloadDetails: boolean, item: any) => {
                      onToggleEditModal();
                      if (reloadDetails === true) {
                        this.fetchItemById(item);
                      }
                    }}
                    item={item}
                  />
                  {/* <AddWorkFlowTransition
                    showTransitionModal={this.state.showTransitionModal}
                    toggleTransitionModal={(
                      reloadDetails: boolean,
                      item: any
                    ) => {
                      this.onToggleTransitionModal();
                      if (reloadDetails === true) {
                        this.fetchItemById(item);
                      }
                      onToggleDetailsModal();
                    }}
                    item={item}
                  /> */}
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="Work Flows"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></WorkFlowsList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default WorkFlows;
