import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";

interface ISaveDisputeAction {
  institutionId: number;
  initiatingUser: string;
  disputeId: number;
  dateCreated: Date;
  comment: string;
  action: string;
}

const recovaDisputeBaseAddress = process.env.REACT_APP_DISPUTE_BASEADDRESS;

export function saveDisputeAction(disputeAction: ISaveDisputeAction) {
  var url =
    recovaDisputeBaseAddress +
    `DisputeAction`

  return http.post(url, disputeAction, {
    url: url,
    method: "post",
    data: disputeAction,
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function retrieveDisputeActionById(id: number) {
  var url = recovaDisputeBaseAddress + `DisputeAction/${id}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveAllDisputeActions(institutionId: number) {
  var url = recovaDisputeBaseAddress + `DisputeAction/RetrieveAll?institutionId=${institutionId}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveAllDisputeActionsByDisputeId(disputeId: number) {
  var url =
    recovaDisputeBaseAddress + `DisputeAction/RetrieveByDisputeId/${disputeId}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export default {
  saveDisputeAction,
  retrieveDisputeActionById,
  retrieveAllDisputeActions,
  retrieveAllDisputeActionsByDisputeId,
};
