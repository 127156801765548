import React, { Component } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import {
  firstCharToUpper,
  splitAtUpper,
  generateGUID,
  convertKoboToNaira,
  formatTimeString,
  formatCurrency,
} from "../../services/utility/textFormatService";
import "./itemsTable.css";

interface IColorColumn {
  columnName: string;
  textColor: string;
}

export interface TableContext {
  name: string;
  primaryValue?: string;
  successValue?: string;
  dangerValue?: string;
}

export interface IDetailItemsTableProps {
  title: string;
  handleOnPageChange: any;
  content: any;
  contentCount: number;
  page: number;
  fetching: boolean;
  item: any;
  amountColumn?: string[];
  amountInNaira?: boolean;
  timeColumn?: string[];
  contextualColumn?: TableContext;
  colorColumn?: IColorColumn[];
  emptyTitle?: string;
  hasDetailModal?: boolean;
  onViewDetails?: any;
}

export interface IDetailItemsTableState { }
class DetailItemsTable extends Component<
  IDetailItemsTableProps,
  IDetailItemsTableState
> {
  state = {};

  static defaultProps = {
    hasDetailModal: false,
  };

  componentDidMount() { }
  //   getTableItemObjects = item => {
  //     const test = item;
  //     delete test.id;
  //     return test;
  //   };
  formatCells = (key: string, item): string => {
    const { amountColumn, timeColumn, amountInNaira } = this.props;
    if (!_.isNil(amountColumn)) {
      if (amountColumn.includes(key)) {
        if (amountInNaira) {
          return formatCurrency(item[key], 2);
        }
        return convertKoboToNaira(item[key], 2);
      } else if (!_.isNil(timeColumn)) {
        if (timeColumn.includes(key)) {
          return formatTimeString(item[key]);
        }
      }
    } else if (!_.isNil(timeColumn)) {
      if (timeColumn.includes(key)) {
        return formatTimeString(item[key]);
      }
    }
    return item[key];
  };

  formatCellColor = (key: string): string => {
    const { colorColumn } = this.props;
    if (!_.isNil(colorColumn)) {
      if (colorColumn.some((item) => item.columnName === key)) {
        return colorColumn.filter((item) => item.columnName === key)[0]
          .textColor;
      }
    }
    return "inherit";
  };

  render() {
    const {
      title,
      handleOnPageChange,
      content,
      contentCount,
      page,
      fetching,
      item,
      amountColumn,
      emptyTitle,
      contextualColumn,
      hasDetailModal,
      onViewDetails,
    } = this.props;

    const headers = content.length > 0 ? Object.keys(content[0]) : "";
    const notFound = title === "" ? emptyTitle : title;
    const pageSize = Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10;

    return (
      <React.Fragment>
        <div
          className="card-header card-footer"
          style={{ padding: "0px", height: "37px" }}
        >
          <nav aria-label="Page navigation example clearfix">
            <div className="float-left" style={{ paddingTop: "5px" }}>
              {title === "" ? (
                ""
              ) : (
                <i className="fas fa-layer-group mr-3 ml-3" />
              )}
              {title}
            </div>
            <div className="float-right">
              {fetching === true ? (
                <Skeleton width={300} />
              ) : (
                <React.Fragment>
                  <ul
                    className="pagination float-left"
                    style={{ height: "20px" }}
                  >
                    <li
                      className={
                        _.isEmpty(content) || page === 1
                          ? "page-item disabled"
                          : "page-item"
                      }
                    >
                      <button
                        type="button"
                        onClick={() => handleOnPageChange(1, item.id)}
                        className="page-link"
                      >
                        <i className="fas fa-step-backward fa-sm" />
                      </button>
                    </li>
                    <li
                      className={
                        _.isEmpty(content) || page === 1
                          ? "page-item disabled"
                          : "page-item"
                      }
                    >
                      <button
                        type="button"
                        onClick={() => handleOnPageChange(page - 1, item.id)}
                        className="page-link"
                      >
                        <i className="fas fa-chevron-left fa-sm" />
                      </button>
                    </li>
                    <li
                      className={
                        Math.ceil(contentCount / pageSize) === page
                          ? "page-item disabled"
                          : "page-item"
                      }
                    >
                      <button
                        type="button"
                        onClick={() => handleOnPageChange(page + 1, item.id)}
                        className="page-link"
                      >
                        <i className="fas fa-chevron-right" />
                      </button>
                    </li>
                    <li
                      className={
                        Math.ceil(contentCount / pageSize) === page
                          ? "page-item disabled"
                          : "page-item"
                      }
                    >
                      <button
                        type="button"
                        onClick={() =>
                          handleOnPageChange(
                            Math.ceil(contentCount / pageSize),
                            item.id
                          )
                        }
                        className="page-link"
                      >
                        <i className="fas fa-step-forward" />
                      </button>
                    </li>
                  </ul>
                  <div
                    className="float-right"
                    style={{
                      marginTop: "5px",
                      marginRight: "25px",
                      marginBottom: "0px",
                      marginLeft: "10px",
                      height: "30px",
                    }}
                  >
                    <p>
                      {content.length > 0
                        ? `Showing ${(page - 1) * pageSize + 1} - ${(page - 1) * pageSize + content.length
                        } of ${contentCount}`
                        : ""}
                    </p>
                  </div>
                </React.Fragment>
              )}
            </div>
          </nav>
        </div>
        <div className="card-body">
          <div
            className="card-content table-responsive text-nowrap"
            style={{ height: "300px", overflowY: "scroll" }}
          >
            <table className="table table-hover table-content table-sm table-bordered table-striped mb-0">
              <thead>
                <tr>
                  <th
                    scope="col"
                    style={{
                      width: "5px",
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                    }}
                  >
                    <strong>#</strong>
                  </th>
                  {!Array.isArray(headers) ? (
                    <th
                      className="font-weight-lighter font-italic text-truncate"
                      style={{
                        textAlign: "center",
                        color: "#FE825E",
                      }}
                    >
                      <h6>{headers}...</h6>
                    </th>
                  ) : (
                    headers.map((header) => (
                      <th
                        key={header}
                        className="text-truncate"
                        scope="col"
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontWeight: 500,
                          display: header === "id" ? "none" : "",
                        }}
                      >
                        <strong>
                          {splitAtUpper(firstCharToUpper(header))}
                        </strong>
                      </th>
                    ))
                  )}
                  <th
                    scope="col"
                    style={{
                      width: "5px",
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                    }}
                  >
                    <strong></strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {fetching || _.isEmpty(item) ? (
                  <React.Fragment>
                    <tr>
                      <td
                        className="font-weight-normal font-italic text-truncate"
                        colSpan={Object.keys(item).length - 1}
                        style={{ textAlign: "center", color: "#FE825E" }}
                      >
                        <React.Fragment>
                          <i className="fas fa-spin fa-circle-notch"></i>
                        </React.Fragment>
                      </td>
                    </tr>
                  </React.Fragment>
                ) : contentCount === 0 ? (
                  <tr>
                    <td
                      className="font-weight-normal font-italic text-truncate"
                      colSpan={Object.keys(item).length - 1}
                      style={{ textAlign: "center", color: "#FE825E" }}
                    >
                      ...No {notFound} found
                    </td>
                  </tr>
                ) : !_.isEmpty(content) ? (
                  content.map((item) => (
                    <React.Fragment key={content.indexOf(item) + 1}>
                      <tr
                        className={this.getTableRowContext(
                          item,
                          contextualColumn
                        )}
                        onDoubleClick={() => {
                          if (hasDetailModal) {
                            onViewDetails(item);
                          }
                        }}
                      >
                        <td
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                            fontSize: "small",
                          }}
                        >
                          {content.indexOf(item) + ((page - 1) * pageSize + 1)}
                        </td>
                        {Object.keys(item).map((_key) => (
                          <td
                            className="text-truncate"
                            key={generateGUID()}
                            align={
                              !_.isNil(amountColumn)
                                ? amountColumn.includes(_key)
                                  ? "right"
                                  : "left"
                                : "left"
                            }
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                              fontWeight: 400,
                              cursor: "pointer",
                              display: _key === "id" ? "none" : "",
                              color: this.formatCellColor(_key),
                            }}
                          >
                            {this.formatCells(_key, item)}
                          </td>
                        ))}
                        {hasDetailModal ? (
                          <td
                            style={{
                              width: "50px",
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                            }}
                          >
                            <span
                              style={{
                                display: "inline-block",
                                cursor: "pointer",
                              }}
                            >
                              <i
                                className="fas fa-eye mr-2"
                                onClick={() => onViewDetails(item)}
                              >
                                {" "}
                              </i>
                            </span>
                          </td>
                        ) : (
                          <td></td>
                        )}
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  ""
                )}
              </tbody>
            </table>
          </div>
        </div>
      </React.Fragment>
    );
  }
  getTableRowContext(item: any, contextualColumn: TableContext): string {
    if (contextualColumn) {
      switch (item[contextualColumn.name]) {
        case contextualColumn.dangerValue:
          return "table-danger";
        case contextualColumn.successValue:
          return "table-success";
        case contextualColumn.primaryValue:
          return "table-primary";
        default:
          return "table-default";
      }
    } else return "table-default";
  }
}

export default DetailItemsTable;
