import http from "../httpService";
import axios from "axios";
import { getCurrentUserSession } from "../auth/authService";
import { IFilterData } from "../approval/approvalItemService";
import { decodeToken } from "react-jwt";

const recovaMandateBaseAddress = process.env.REACT_APP_MANDATE_BASEADDRESS;

export function filterMandateCreationCharges(payload: IFilterData,
    pageSize: number,
    pageNumber: number) {
    const decodedToken = decodeToken(getCurrentUserSession().token || "");

    payload.institutionId = { item1: "=", item2: decodedToken?.["InstitutionId"]?.toString() || "break" };
    return http.post(recovaMandateBaseAddress + `MandateCreationCharge/FilterMandateCreationCharges?pageSize=${pageSize}&pageNumber=${pageNumber}`, payload);
}

export default {
    filterMandateCreationCharges
}