import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";

const auditTrailBaseAddress = process.env.REACT_APP_AUDITTRAIL_BASEADDRESS;
export function retrieveByInformationIdAndEntityName(
  institutionId: number,
  informationId: number,
  entityName: string,
  trailType: string
) {
  var url = auditTrailBaseAddress +
    `audittrailmappers/RetrieveByInformationIdAndEntityName?informationId=${informationId}&entityName=${entityName}&trailType=${trailType}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export default {
  retrieveByInformationIdAndEntityName,
};
