import * as React from "react";

export interface IDetailItemInputComponentProps {
  id: string;
  type: "password" | "text" | "number" | "email";
  name: string;
  placeholder: string;
  value: string;
  required?: boolean;
  disabled?: boolean;
  onBlur?: any;
  validated: boolean;
  onChange: any;
  errors: any;
  htmlBeforeError?: any;
}

export interface IDetailItemInputComponentState {}

class DetailItemInputComponent extends React.Component<
  IDetailItemInputComponentProps,
  IDetailItemInputComponentState
> {
  static defaultProps = {
    required: false,
  };
  render() {
    const {
      id,
      type,
      name,
      placeholder,
      value,
      required,
      validated,
      errors,
      onChange,
      disabled,
      onBlur,
      htmlBeforeError,
    } = this.props;
    return (
      <React.Fragment>
        <input
          required={required}
          id={id}
          name={name}
          placeholder={placeholder}
          className={
            !validated
              ? "form-control r-0 light s-12"
              : errors[id]
              ? "form-control r-0 light s-12 is-invalid"
              : "form-control r-0 light s-12 is-valid"
          }
          type={type}
          value={value}
          disabled={disabled}
          onChange={(e) => onChange(id, e.currentTarget.value)}
          onBlur={onBlur ? () => onBlur(value) : onBlur}
        />
        {htmlBeforeError}
        <div
          className="invalid-feedback"
          style={{
            visibility: errors[id] ? "visible" : "hidden",
            display: errors[id] ? "block" : "none",
          }}
        >
          {errors[id]}​​​
        </div>
      </React.Fragment>
    );
  }
}

export default DetailItemInputComponent;
