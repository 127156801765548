import React, { Fragment } from "react";
import _ from "lodash";
import ReactWizard from "react-bootstrap-wizard";
import CreditAnalysisCheck from "./creditAnalysisCheck";
import CreditBureauCheck from "./CreditBureauCheck";
import BankOneCreditCheck from "./BankOneCreditCheck";
import FirstCentralCreditBureauCheck from "./FirstCentralCreditBureau";

interface ICreditAnalysisProps {
	item: any;
	title: string;
}

interface ICreditAnalysisState {
	item: any;
}

class CreditAnalysis extends React.Component<ICreditAnalysisProps & ICreditAnalysisState> {
	formRef: HTMLFormElement;
	constructor(props: ICreditAnalysisProps) {
		super(props);
		this.state = {
			item: {}
		};
	}

	static getDerivedStateFromProps(nextProps: ICreditAnalysisProps, prevState) {
		return {
			item: nextProps.item,
		};
	}

	resetFields() {
		this.setState({ validated: false, submitting: false, errors: [] });
	}

	render() {
		const { item } = this.props;

		return (
			<Fragment>
				<div className="container-fluid relative animatedParent animateOnce">
					<div className="animated fadeInUpShort go">
						<div className="row my-3 mx-2">
							<h3>
								<b>Credit Analysis</b>
							</h3>
						</div>
						<div className="row">
							<div className="col-md-12">
								<ReactWizard
									className="row my-3 mx-2 font-italic font-weight-bold"
									style={{ fontSize: '120px' }}
									steps={this.props.title === "Credit Analysis (Internal)" ? (([
										{
											stepName: "Internal",
											component: CreditAnalysisCheck,
											stepProps: {
												item: item
											},
										},
										{
											stepName: "External(CRC)",
											component: CreditBureauCheck,
											stepProps: {
												item: item
											},
										},
										{
											stepName: "External(FCCB)",
											component: FirstCentralCreditBureauCheck,
											stepProps: {
												item: item
											},
										},
										{
											stepName: "BankOne",
											component: BankOneCreditCheck,
											stepProps: {
												item: item
											},
										}
									])) : this.props.title === "Credit Analysis (CRC)" ? (([

										{
											stepName: "External(CRC)",
											component: CreditBureauCheck,
											stepProps: {
												item: item
											},
										},
										{
											stepName: "External(FCCB)",
											component: FirstCentralCreditBureauCheck,
											stepProps: {
												item: item
											},
										},
										{
											stepName: "Internal",
											component: CreditAnalysisCheck,
											stepProps: {
												item: item
											},
										},
										{
											stepName: "BankOne",
											component: BankOneCreditCheck,
											stepProps: {
												item: item
											},
										}
									]))
										: this.props.title === "Credit Analysis (FCCB)" ? (([

											{
												stepName: "External(FCCB)",
												component: FirstCentralCreditBureauCheck,
												stepProps: {
													item: item
												},
											},
											{
												stepName: "External(CRC)",
												component: CreditBureauCheck,
												stepProps: {
													item: item
												},
											},
											{
												stepName: "Internal",
												component: CreditAnalysisCheck,
												stepProps: {
													item: item
												},
											},
											{
												stepName: "BankOne",
												component: BankOneCreditCheck,
												stepProps: {
													item: item
												},
											}
										]))
											: this.props.title === "Credit Analysis (BankOne)" ? (([
												{
													stepName: "BankOne",
													component: BankOneCreditCheck,
													stepProps: {
														item: item
													},
												},
												{
													stepName: "Internal",
													component: CreditAnalysisCheck,
													stepProps: {
														item: item
													},
												},
												{
													stepName: "External(CRC)",
													component: CreditBureauCheck,
													stepProps: {
														item: item
													},
												},
												{
													stepName: "External(FCCB)",
													component: FirstCentralCreditBureauCheck,
													stepProps: {
														item: item
													},
												},
											])) : (([
												{
													stepName: "Internal",
													component: CreditAnalysisCheck,
													stepProps: {
														item: item
													},
												},
												{
													stepName: "External(CRC)",
													component: CreditBureauCheck,
													stepProps: {
														item: item
													},
												},
												{
													stepName: "External(FCCB)",
													component: FirstCentralCreditBureauCheck,
													stepProps: {
														item: item
													},
												},
												{
													stepName: "BankOne",
													component: BankOneCreditCheck,
													stepProps: {
														item: item
													},
												}
											]))}
									navSteps
								/>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default CreditAnalysis;
