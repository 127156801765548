import React from "react";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import BaseFormComponent, { IBaseFormState } from "../../../../../components/BaseFormComponent";
import { ActionTypes } from "../../../../../enums/actionTypes";
import approvalValidationService from "../../../../../services/approval/approvalValidationService";
import textFormatService from "../../../../../services/utility/textFormatService";
import DetailItem from "../../../../../components/detailItem";
import DetailItemInputComponent from "../../../../../components/detailItemInputBox";
import { toast } from "react-toastify";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../../../../services/auth/authService";

interface AddSupportingDocumentFields {
  name: string;
}

class AddSupportingDocumentForm extends BaseFormComponent<AddSupportingDocumentFields> {}

interface IAddSupportingDocumentProps {
  item: any;
  showPatchModal: boolean;
  togglePatchModal: any;
}
interface IAddSupportingDocumentState {
  docToUpload: any;
}

class AddSupportingDocument extends React.Component<
  IAddSupportingDocumentProps,
  IBaseFormState & IAddSupportingDocumentState
> {
  constructor(props: IAddSupportingDocumentProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      docToUpload: {},
      errors: {}
    };
  }

  onFormSubmit(fields: AddSupportingDocumentFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors: approvalValidationService.validateAddSupportingDocumentForm(fields),
          validated: true,
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (fields: AddSupportingDocumentFields, onReloadFieldsCB: any) => {
    try {
      this.setState({ submitting: true });
      
      const payload = this.props.item;
      const loanDetails = JSON.parse(payload.LoanDetails);

      const docPayload: any = {};
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      docPayload.institutionId = decodedToken?.["InstitutionId"];
      docPayload.document = await textFormatService.getBase64StringFromFile(this.state.docToUpload);
      docPayload.name = fields.name;
      docPayload.loanReference = loanDetails.AccountNumber;
      docPayload.customerID = loanDetails.CustomerID;
      docPayload.disputeId = 0;
      docPayload.supportingDocumentType = "Others";
      docPayload.disputeConditionDocumentType = null;
      docPayload.extensionType = this.state.docToUpload.name.split(".")[1].toUpperCase();
      

      // // Update Loan Details 
      // loanDetails.SupportingDocuments.push(docPayload);

      // Update Item next
      // payload.LoanDetails = JSON.stringify(loanDetails);

      // console.log("For checks::", payload);
      onReloadFieldsCB();
      this.setState({ submitting: false }, () =>
        this.props.togglePatchModal(true, docPayload)
      );



      // this.setState({ uploadingDocument: true, submitting: true });
      // try {
      //   var response = await supportingDocumentService.saveSupportingDocument(
      //     docPayload
      //   );
      // } catch (error) {
      //   // console.log("error in supporting doc", error.response.data.Message);
      //   throw error;
      // }
      // console.log("response1: ", response);
      // await activityTrailService.saveActivityTrail({
      //   actionType: "Upload Supporting Document",
      //   description: `Upload Supporting Document on dispute ${this.props.item.id}`,
      // });

      // this.setState({ uploadingDocument: false, submitting: false });
    } catch (error) {
      this.setState({ submitting: false });
    }
  };

  OnDocumentChange = (e) => {
    e.preventDefault();
    // console.log("document: ", e.target.files[0]);
    // check the file size
    if (e.target.files[0] === undefined) {
      return;
    }
    if (e.target.files[0].size > 100000) {
      toast.error(
        "Please confirm that the size of the document is less than 100kb"
      );
    } else if (
      !["docx", "doc", "pdf", "jpg", "png"].includes(
        e.target.files[0].name.split(".")[1]
      )
    ) {
      // console.log("check: ", e.target.files[0].name.includes("docx"));
      toast.error(
        "Please confirm that the document is a valid word document, pdf or image"
      );
    } else {
      this.setState({ docToUpload: e.target.files[0] });
    }
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false
    });
  }

  render() {
    const { item, showPatchModal, togglePatchModal } = this.props;
    const { validated, submitting, errors, docToUpload } = this.state;

    if (_.isEmpty(item)) {
      return null;
    }

    const loanDetails = JSON.parse(item.LoanDetails);
    // console.log("Loan det :: ", loanDetails)

    return (
      <Modal
        size="lg"
        backdrop="static"
        show={showPatchModal}
        onHide={togglePatchModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-edit fa-sm cyan-text mr-3" />
              Modify Loan Parameters
            </h5>
          </Modal.Title>
        </Modal.Header>
        <AddSupportingDocumentForm
          initialValues={{ 
            name: ""
           }}
          FormComponent={({
            fields: { name },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    {_.isEmpty(item) ? (
                      <Skeleton width={200} />
                    ) : (
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        <b>General Information</b>
                      </h6>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={1} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Document Name"
                              value={
                                <DetailItemInputComponent
                                  id="name"
                                  type="text"
                                  name="name"
                                  placeholder=""
                                  value={name}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={5}
                              valueSize={7}
                            />
                          </dl>
                        )}
                      </div>
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={1} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Doc. to Upload"
                              value={
                                <div className="input-group">
                                  <div className="custom-file">
                                    <input
                                      type="file"
                                      className="custom-file-input"
                                      id="docToUpload"
                                      name="docToUpload"
                                      aria-describedby="inputGroupFileAddon01"
                                      onChange={(e) => this.OnDocumentChange(e)}
                                    />
                                    <label
                                      className="custom-file-label"
                                      htmlFor="docToUpload"
                                    >
                                      {_.isEmpty(docToUpload.name)
                                        ? "Choose File"
                                        : docToUpload.name}
                                    </label>
                                  </div>
                                </div>
                              }
                              labelSize={5}
                              valueSize={7}
                            />
                          </dl>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.SAVE,
                      "Supporting Document",
                      () => {
                        this.onFormSubmit({name}, onReloadFields);
                      }
                    );
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => togglePatchModal(false, {}, {})}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></AddSupportingDocumentForm>
      </Modal>
    );
  }
}

export default AddSupportingDocument;
