import moment from "moment";
import React from "react";
// import { useStopwatch, useTimer } from 'react-timer-hook';

export interface ITimerProps {}

export interface ITimerState {
  timer: any;
  // currentTime: any;
  startTime: any;
}

class Timer extends React.Component<ITimerProps, ITimerState> {
  constructor(props: ITimerProps) {
    super(props);
    this.state = { timer: null, startTime: moment(new Date(), "hh:mm:ss") };
  }

  componentDidMount() {
    let sessionStartTime = localStorage.getItem("sessionStartTime")
      ? new Date(localStorage.getItem("sessionStartTime") || "")
      : new Date();
    // console.log("start", sessionStartTime);
    let now = moment();
    // let offsetTimestamp = +now-(+sessionStartTime);

    // let hours = Math.floor(offsetTimestamp / 3600000);
    // offsetTimestamp -= (hours*3600000);
    // // console.log("start", hours);
    // let minutes = Math.floor(offsetTimestamp / 60000);
    // offsetTimestamp -= (minutes*60000);
    // // console.log("start", minutes);
    // let seconds = Math.floor(offsetTimestamp / 1000);
    // // console.log("secon", seconds);
    // console.log(moment().toString())

    // let currentTime: any = 0;
    // let timeDiff: any = 0;

    // timeCount = () => {
    // currentTime = document.getElementById("timer").innerHTML;

    let startTime = moment(sessionStartTime, "hh:mm:ss");

    now.subtract(startTime.hours(), "hours");
    now.subtract(startTime.minutes(), "minutes");
    now.subtract(startTime.seconds(), "seconds");

    // console.log(now.toString())

    // endTime = moment(document.getElementById("timeEnd").value, "hh:mm:ss");

    // timeDiff = endTime.diff(startTime);

    const timer = setInterval(this.countDown, 1000);

    this.setState({ startTime: now, timer: timer });
  }

  countDown = () => {
    this.setState({ startTime: this.state.startTime.add(1, "second") });

    // document.getElementById("timer").innerHTML = startTime.format("HH:mm:ss");

    // timeDiff = endTime.diff(startTime);
    // if (timeDiff === 0) {
    //     stopCount();
    // }
  };

  stopCount = () => {
    clearInterval(this.state.timer);
  };

  componentWillUnmount() {
    this.stopCount();
  }

  render() {
    return <span>{this.state.startTime.format("HH:mm:ss")}</span>;
  }
}

export default Timer;

// let timer: any = null;
// export function Clock() {

//     // let startTime = 0;
//     // let endTime = 0;
//     // }

//     // console.log("inside", offsetTimestamp)
//     // const {
//     //   seconds,
//     //   minutes,
//     //   hours,
//     //   days,
//     //   isRunning,
//     //   start,
//     //   pause,
//     //   reset
//     // } = useStopwatch({autoStart: true, offsetTimestamp: 27875453});

//     return (
//     );
// }

// function MyTimer({ expiryTimestamp }) {
//     const {
//       seconds,
//       minutes,
//       hours,
//       days,
//       isRunning,
//       start,
//       pause,
//       resume,
//       restart,
//     } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });

//     return (
//       <div style={{textAlign: 'center'}}>
//         <h1>react-timer-hook </h1>
//         <p>Timer Demo</p>
//         <div style={{fontSize: '100px'}}>
//           <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
//         </div>
//         <p>{isRunning ? 'Running' : 'Not running'}</p>
//         <button onClick={start}>Start</button>
//         <button onClick={pause}>Pause</button>
//         <button onClick={resume}>Resume</button>
//         <button onClick={() => {
//           // Restarts to 5 minutes timer
//           // .valueOf()
//           const time: any = new Date();
//           time.setSeconds(time.getSeconds() + 300);
//           restart(time)
//         }}>Restart</button>
//       </div>
//     );
// }

// export function Timer() {
//   const time = new Date();
//   time.setSeconds(time.getSeconds() + 600); // 10 minutes timer
//   return (
//     <div>
//       <MyTimer expiryTimestamp={time} />
//     </div>
//   );
// }
