import React from "react";
import _ from "lodash";
import { getCurrentUserSession } from "../../../services/auth/authService";
import { decodeToken } from "react-jwt";
import AddCreditQualificationRuleOther from "./creditQualificationRuleComponents/otherInstitutionAddCQR";
import AddCreditQualificationRuleAppzone from "./creditQualificationRuleComponents/appzoneAddCQR";

interface IAddCreditQualificationRuleProps {}
interface IAddCreditQualificationRuleState {}

class AddCreditQualificationRule extends React.Component<
  IAddCreditQualificationRuleProps,
  IAddCreditQualificationRuleState
> {
  constructor(props: IAddCreditQualificationRuleProps) {
    super(props);
    this.state = {};
  }

  render() {
    const decodedToken = decodeToken(getCurrentUserSession().token || "");

    return (
      <React.Fragment>
        {Number(decodedToken?.["InstitutionId"]) === 1 ? (
          <AddCreditQualificationRuleAppzone />
        ) : (
          <AddCreditQualificationRuleOther />
        )}
      </React.Fragment>
    );
  }
}

export default AddCreditQualificationRule;
