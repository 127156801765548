import http from "../httpService";

const appraisalBaseAddress = process.env.REACT_APP_HYBRIDAPPRAISAL_BASEADDRESS;

export function addOfferLetter(payload) {
  console.log(appraisalBaseAddress);
  return http.post(
    appraisalBaseAddress + `OfferLetter/addOfferLetter`,
    payload
  );
}

export function editOfferLetter(payload) {
  return http.post(
    appraisalBaseAddress + `OfferLetter/editOfferLetter`,
    payload
  );
}

export function retrieveInstitutionOfferLetter(institution) {
  return http.post(
    appraisalBaseAddress + `OfferLetter/retrieveInstitutionOfferLetter?Institution=${institution}`
  );
}

export function fetchAllInstitution() {
  return http.post(
    appraisalBaseAddress + `OfferLetter/fetchAllInstitution`
  );
}

export function RetrieveOfferLetterTemplate(institution) {
  return http.get(
    appraisalBaseAddress + `OfferLetter/RetrieveOfferLetterTemplate?Institution=${institution}`
  );
}

export function GenerateOfferLetter(institution, requestId, isGroupLoan) {
  return http.post(
    appraisalBaseAddress + `OfferLetter/generateOfferLetter/${institution}?requestid=${requestId}&isGroupLoan=${isGroupLoan}`
  );
}

export function GenerateGuarantorLetter(payload) {
  return http.post(
    appraisalBaseAddress + `OfferLetter/generateGuarantorLetter`,
    payload
  );
}
export function GenerateApprovedMembersList(requestId) {
  return http.get(
    appraisalBaseAddress + `OfferLetter/generateApprovedMembersList/${requestId}`
  );
}


export default {
  addOfferLetter,
  editOfferLetter,
  retrieveInstitutionOfferLetter,
  fetchAllInstitution,
  RetrieveOfferLetterTemplate,
  GenerateOfferLetter,
  GenerateGuarantorLetter,
  GenerateApprovedMembersList
};
