import * as React from "react";
import _ from "lodash";
import { Fragment } from "react";
import {
  IFilterData,
  BaseListComponentState,
} from "../../components/BaseListComponent";
import {
  createFilterData,
  mapEntityList,
} from "../../services/utility/autoMapperService";
import BaseListComponent from "../../components/BaseListComponent";
import reportsService from "../../services/sweep/reportsService";
import ItemsTable, {
  filterFieldData,
} from "../../components/tableComponents/itemsTable";
import TableFilterPane from "../../components/tableComponents/tableFilterPane";
import { getCurrentUserSession } from "../../services/auth/authService";
import institutionsService from "../../services/auth/institutionsService";
import { printPDF } from "../../services/utility/textFormatService";
import { decodeToken } from "react-jwt";
import thirdPartyBillingTransactionService from "../../services/sweep/thirdPartyBillingTransactionService";
import ThirdPartyBillingTransactionsReportDetail from "./thirdPartyBillingTransactionsReportDetail";

export interface ThirdPartyBillingTransactionsReportProps {}

export interface ThirdPartyBillingTransactionsReportState
  extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
}

class ThirdPartyBillingTransactionsReportList extends BaseListComponent<ThirdPartyBillingTransactionsReportState> {}

class ThirdPartyBillingTransactionsReport extends React.Component<
  ThirdPartyBillingTransactionsReportProps,
  ThirdPartyBillingTransactionsReportState
> {
  constructor(props: ThirdPartyBillingTransactionsReportProps) {
    super(props);
    this.state = {
      data: createFilterData([]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
    };
  }

  handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true });
    data = typeof data === "undefined" ? this.state.data : data;
    try {
      const reportItems =
        await thirdPartyBillingTransactionService.filterThirdPartySettlements(
          data,
          pageSize,
          currentPage
        );
      this.setState({
        tableItems: mapEntityList({
          entityList: reportItems.data.item2,
          properties: [
            { oldName: "loanAccountNo", newName: "loanReference" },
            { oldName: "linkedAccountNo", newName: "inflowAccount" },
            { oldName: "amount", newName: "amount" },
            { oldName: "timeSwept", newName: "timeSwept" },
            {
              isTag: true,
              oldName: "billingStatus",
              newName: "status",
              success: "Sent",
              danger: "Failed",
              default: "default",
              primary: "Confirmed",
              secondary: "secondary",
              warning: "Pending",
              light: "AwaitingConfirmation",
              dark: "dark",
            },
            { oldName: "retrievalReferenceNo", newName: "retrievalReference" },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: reportItems.data.item1,
        fetching: false,
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
        // this.setState({ errors, fetching: false });
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        // this.setState({ errors, fetching: false });
      }
    } finally {
      this.setState({ fetching: false });
    }
  };

  handleOnExport = async (type: string) => {
    // try {
    //   const decodedToken = decodeToken(getCurrentUserSession().token || "");
    //   switch (type) {
    //     case "XLSX":
    //     case "CSV":
    //       return await reportsService.downloadFailedRepaymentsReport(
    //         decodedToken?.["InstitutionCode"],
    //         type
    //       );
    //     case "PDF":
    //       const response = await reportsService.downloadFailedRepaymentsReport(
    //         decodedToken?.["InstitutionCode"],
    //         type
    //       );
    //       printPDF(response?.data);
    //       break;
    //     default:
    //       break;
    //   }
    // } catch (ex) {
    //   if (
    //     ex.response &&
    //     ex.response.status >= 400 &&
    //     ex.response.status <= 499
    //   ) {
    //     // toast.error("Bad Request");
    //     // console.log(ex.response);
    //     // errors["response"] = "Internal server error.";
    //     // console.log(errors);
    //   } else {
    //     // toast.error("Internal server error. Please contact the admin.");
    //     //this.setState({ errors, fetching: false });
    //   }
    // }
  };

  fetchItemById = async (item: any) => {
    // await activityTrailService.saveActivityTrail({
    //   actionType: "View Details",
    //   description: `Viewed details of Branch ${item.name}`
    // });
    this.setState({ fetchingDetail: true });
    // console.log("fetching branch with id: ", item.id);
    const report = await thirdPartyBillingTransactionService.retrieveById(
      item.id
    );
    // console.log("Response", report);
    this.setState({ item: report.data, fetchingDetail: false });
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }

  render() {
    const {
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Third-Party Billing Transactions Report</b>
              </h3>
            </div>
            <ThirdPartyBillingTransactionsReportList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              initialValues={{
                data: createFilterData([]) as IFilterData,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: [],
                item: {},
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                showDetailsModal,
              }) => (
                <Fragment>
                  <ThirdPartyBillingTransactionsReportDetail
                    showDetailsModal={showDetailsModal}
                    toggleDetailsModal={onToggleDetailsModal}
                    item={item}
                  />
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="ThirdPartyBillingTransactionsReport"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                              amountColumn={["amount"]}
                              timeColumn={["timeSwept"]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></ThirdPartyBillingTransactionsReportList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ThirdPartyBillingTransactionsReport;
