import React, { Component, Fragment, useContext } from "react";
import {
    Button,
    Card,
    useAccordionToggle,
    AccordionContext,
    Accordion,
    Modal,
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { getCurrentUsersRoles } from "../../../../services/auth/authService";
import BaseFormComponent from "../../../../components/BaseFormComponent";
import FormTextAreaComponent from "../../../../components/formTextAreaComponent";
import LoanRequestItemDetail from "../../loanRequest/appraisalProcessComponents/loanRequestItem";
import { generateGUID } from "../../../../services/utility/textFormatService";

export function ContextAwareToggle({ eventKey, header }) {
    const currentEventKey = useContext(AccordionContext);
    const toggleOnClick = useAccordionToggle(eventKey);
    const isCurrentEventKey = currentEventKey === eventKey;
    const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
    return (
        <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
            <b>{header}</b>
            <i
                className={angleType}
                aria-hidden="true"
                style={{ float: "right" }}
            ></i>
        </Card.Header>
    );
}

interface TinRequestDetailsFields {
    comment: string;
    condition: any;
}

class TinRequestDetailsForm extends BaseFormComponent<TinRequestDetailsFields> { }

interface ITinRequestDetailsProps {
    item: any;
    systemProcess: any;
    userRoles: any;
    currentUserSession: any;
    toggleDetailsModal: any;
    showDetailsModal: boolean;
}

interface ITinRequestDetailsState {
    item: any;
    userRoles: any;
    systemProcess: any;
    applicableRules: any;
    appraisalActions: any;
    updateComponent: boolean;
    documentsToUpload: any;
    documentsToDelete: number[];
    canEditItem: boolean;
    updateAvailable: boolean;
    processResponse: any;
    specialConditions: any;
    ownUpdate: boolean;
}

class TinRequestDetails extends Component<ITinRequestDetailsProps, ITinRequestDetailsState> {
    _isMounted: boolean;
    constructor(props: ITinRequestDetailsProps) {
        super(props);
        this.state = {
            item: {},
            userRoles: [],
            systemProcess: [],
            applicableRules: [],
            appraisalActions: [],
            documentsToUpload: [],
            documentsToDelete: [],
            updateComponent: false,
            canEditItem: false,
            updateAvailable: false,
            specialConditions: [],
            processResponse: {
                actionId: 0,
                score: 0,
                response: ""
            },
            ownUpdate: false
        };
    }

    static getDerivedStateFromProps(nextProps: ITinRequestDetailsProps, prevState) {
        if (prevState.ownUpdate) {
          return {
            ownUpdate: false,
          };
        }
        if (_.isEmpty(nextProps.item)) {
          return null;
        }
        return {
            item: nextProps.item,
        };
    }

    async componentDidMount() {
        this._isMounted = true;
        let applicableRules = this.props.item.financialGroup?.rules.filter(
            (rule) =>
                rule.level === this.props.item.currentLevel &&
                rule.roleID === this.props.currentUserSession.userRoleId
        );
        const appraisalActions = this.props.item.appraisalActions;
        if (appraisalActions) {
            // const userPromises: any = [];
            // for (let index = 0; index < appraisalActions.length; index++) {
            //   if (appraisalActions[index].logType !== "SystemType") {
            //     const userPromise = usersService.retrieveUserById(appraisalActions[index].userID);
            //     userPromises.push(userPromise);
            //   }
            // }
        
            // // const users = await Promise.all(userPromises);
            // const users = await Promise.allSettled(userPromises);
            // // console.log(users)
            // let userIndex = 0;
            // for (let index = 0; index < appraisalActions.length; index++) {
            //   if (appraisalActions[index].logType !== "SystemType") {
            //     const user = users[userIndex];
            //     appraisalActions[index]["user"] = user.status == "fulfilled" ? user.value.data : null;
            //     userIndex++;
            //   }
            // }
      
            // Check if any user role on the current level has approved
            const checkActions = appraisalActions.filter(action => (action.itemLevel == this.props.item.currentLevel && action.action == "Approve"));
            // console.log("approval actions:", checkActions);
            const canEdit = applicableRules.length !== 0 && this.props.item.status !== "Rejected" && (!getCurrentUsersRoles().includes("remotesession")) && checkActions.length == 0
            const specialConditions = this.props.item.specialConditions != null ? this.props.item.specialConditions : [];
            if (this._isMounted) {
                this.setState({ appraisalActions, applicableRules, canEditItem: canEdit, specialConditions: specialConditions, ownUpdate: true });
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loanRequestDetailsPage = (item) => {
        if (_.isEmpty(item)) return "";
        return <LoanRequestItemDetail
            values={item}
            item={item.jsonData}
            financialGroup={{ lowerLimit: this.state.applicableRules[0]?.applicableAmountLower, upperLimit: this.state.applicableRules[0]?.applicableAmountUpper }}
            updateItem={(jsonData: string, amount: number) => {}}
            updateItemDocs={(document: any) => {}}
            deleteItemDocs={(document: any, documentId: number) => {}}
            tempDocs={this.state.documentsToUpload}
            tempDeletedDocs={this.state.documentsToDelete}
            canEditItem={this.state.canEditItem}
        />;
    };

    render() {
        const {
            item,
            systemProcess,
            userRoles,
            toggleDetailsModal,
            showDetailsModal
        } = this.props;

        return (
            <Modal
                size="xl"
                backdrop="static"
                show={showDetailsModal}
                onHide={toggleDetailsModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {_.isEmpty(item) ? (
                            <Skeleton width={500} />
                        ) : (
                            <h6
                                className="card-title float-left"
                                style={{ marginBottom: "0px" }}
                            >
                                <b>Credit Summary Review</b>
                            </h6>
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card">
                        {/* {console.log(item)} */}
                        <div className="row">
                            {/* {console.log(item.appraisalActions)} */}
                            <div className="col-md-12">
                                <div className="card no-b">
                                    <div className="row">

                                        <div className="col-md-12">
                                            <div className="card">
                                                <Accordion defaultActiveKey="0">
                                                    <div>
                                                        <Card>
                                                            <ContextAwareToggle
                                                                eventKey={`0`}
                                                                header={<React.Fragment>
                                                                    <i
                                                                        className="fa fa-info-circle"
                                                                        aria-hidden="true"
                                                                        style={{ color: "#878a8a" }}
                                                                    ></i>{" "}
                                                                    Applicant Details
                                                                </React.Fragment>}
                                                            ></ContextAwareToggle>
                                                            <Accordion.Collapse eventKey={`0`}>
                                                                <Card.Body>
                                                                    <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                                        <div className="row">

                                                                            {this.loanRequestDetailsPage(item)}
                                                                        </div>
                                                                    </div>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </div>
                                                </Accordion>
                                            </div>
                                            <br />

                                            <Fragment>
                                                <Accordion defaultActiveKey="0">
                                                    <div>
                                                        <Card>
                                                            <ContextAwareToggle
                                                                eventKey={`0`}
                                                                header={<React.Fragment>
                                                                    <i
                                                                        className="fa fa-info-circle"
                                                                        aria-hidden="true"
                                                                        style={{ color: "#878a8a" }}
                                                                    ></i>{" "}
                                                                    Appraisal Actions
                                                                </React.Fragment>}
                                                            ></ContextAwareToggle>
                                                            <Accordion.Collapse eventKey={`0`}>
                                                                <Card.Body>
                                                                    <div
                                                                        className="table-responsive text-nowrap"
                                                                        style={{
                                                                            height: "300px",
                                                                            overflowY: "scroll",
                                                                        }}
                                                                    >

                                                                        {/* <h6 className="center">Appraisal Actions</h6> */}
                                                                        <table className="table table-hover table-content table-sm table-striped mb-0">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th
                                                                                        scope="col"
                                                                                        style={{
                                                                                            width: "5px",
                                                                                            paddingTop: "0.2rem",
                                                                                            paddingBottom: "0.2rem",
                                                                                        }}
                                                                                    >
                                                                                        <strong>#</strong>
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        style={{
                                                                                            paddingTop: "0.2rem",
                                                                                            paddingBottom: "0.2rem",
                                                                                        }}
                                                                                    >
                                                                                        <strong>Decision</strong>
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        style={{
                                                                                            // width: "5px",
                                                                                            paddingTop: "0.2rem",
                                                                                            paddingBottom: "0.2rem",
                                                                                        }}
                                                                                    >
                                                                                        <strong>Comment</strong>
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        style={{
                                                                                            // width: "5px",
                                                                                            paddingTop: "0.2rem",
                                                                                            paddingBottom: "0.2rem",
                                                                                        }}
                                                                                    >
                                                                                        <strong>Username</strong>
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        style={{
                                                                                            // width: "5px",
                                                                                            paddingTop: "0.2rem",
                                                                                            paddingBottom: "0.2rem",
                                                                                        }}
                                                                                    >
                                                                                        <strong>Date</strong>
                                                                                    </th>

                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {/* {recipients.length === 0 ? ( */}
                                                                                {item.appraisalActions.length === 0 ? (
                                                                                    <React.Fragment>
                                                                                        <tr>
                                                                                            No Decisions Made yet
                                                                                        </tr>
                                                                                    </React.Fragment>
                                                                                ) : (
                                                                                    item.appraisalActions.map((action) => (
                                                                                        <React.Fragment key={generateGUID()}>
                                                                                            <tr>
                                                                                                <td
                                                                                                    style={{
                                                                                                        width: "5px",
                                                                                                        paddingTop: "0.2rem",
                                                                                                        paddingBottom: "0.2rem",
                                                                                                        fontSize: "small",
                                                                                                    }}
                                                                                                >
                                                                                                </td>
                                                                                                <td
                                                                                                    style={{
                                                                                                        paddingTop: "0.2rem",
                                                                                                        paddingBottom: "0.2rem",
                                                                                                        fontSize: "small",
                                                                                                    }}
                                                                                                >
                                                                                                    {action.action}
                                                                                                </td>
                                                                                                <td
                                                                                                    style={{
                                                                                                        paddingTop: "0.2rem",
                                                                                                        paddingBottom: "0.2rem",
                                                                                                        fontSize: "small",
                                                                                                    }}
                                                                                                >
                                                                                                    {/* {console.log(systemProcess?.filter(x => x.id === Number(action.systemProcessID)))[0]?.name} */}
                                                                                                    {action.comment}
                                                                                                </td>
                                                                                                <td
                                                                                                    style={{
                                                                                                        paddingTop: "0.2rem",
                                                                                                        paddingBottom: "0.2rem",
                                                                                                        fontSize: "small",
                                                                                                    }}
                                                                                                >
                                                                                                    {action.logType === "SystemType" ? (systemProcess?.filter(x => x.id === Number(action.systemProcessID))[0]?.name) :
                                                                                                        (userRoles.filter(x => x.id === Number(action.roleID))[0]?.name)}
                                                                                                </td>
                                                                                                <td
                                                                                                    style={{
                                                                                                        paddingTop: "0.2rem",
                                                                                                        paddingBottom: "0.2rem",
                                                                                                        fontSize: "small",
                                                                                                    }}
                                                                                                >
                                                                                                    {action.dateCreated}
                                                                                                </td>
                                                                                            </tr>
                                                                                            {/* ))} */}
                                                                                        </React.Fragment>
                                                                                    ))
                                                                                )}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </div>
                                                </Accordion>
                                            </Fragment>

                                            <TinRequestDetailsForm
                                                initialValues={{ comment: "", condition: this.state.specialConditions }}
                                                FormComponent={({
                                                    fields: { comment, condition },
                                                    onChange,
                                                    onReloadFields,
                                                    onHandleSubmit,
                                                }) => (
                                                    <><div className="card">
                                                        <Accordion defaultActiveKey="0">
                                                            <div>
                                                                <Card>
                                                                    <ContextAwareToggle
                                                                        eventKey={`0`}
                                                                        header={<React.Fragment>
                                                                            <i
                                                                                className="fa fa-info-circle"
                                                                                aria-hidden="true"
                                                                                style={{ color: "#878a8a" }}
                                                                            ></i>{" "}
                                                                            Special Conditions
                                                                        </React.Fragment>}
                                                                    ></ContextAwareToggle>
                                                                    <Accordion.Collapse eventKey={`0`}>
                                                                        <Card.Body>
                                                                            <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                                                <div className="row">
                                                                                    <div className="col-md-10">
                                                                                        <dl className="row">
                                                                                            {_.isEmpty(this.state.specialConditions) ? (
                                                                                                ""
                                                                                            ) :
                                                                                                (
                                                                                                    this.state.specialConditions.map((condition, idx) => (
                                                                                                        <FormTextAreaComponent
                                                                                                            key={idx}
                                                                                                            id={condition.id}
                                                                                                            type="text"
                                                                                                            name={condition.id}
                                                                                                            placeholder=""
                                                                                                            divClass={6}
                                                                                                            value={condition.value}
                                                                                                            required={false}
                                                                                                            validated={true}
                                                                                                            errors={{}}
                                                                                                            onChange={(id, value) => {
                                                                                                                onChange('condition', ()=>{});
                                                                                                            }}
                                                                                                            disabled={true} />
                                                                                                    ))
                                                                                                )}</dl>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </Card.Body>
                                                                    </Accordion.Collapse>
                                                                </Card>
                                                            </div>
                                                        </Accordion>
                                                    </div></>
                                                )}
                                            >
                                            </TinRequestDetailsForm>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={toggleDetailsModal}
                    >
                        <i className="fas fa-times mr-3" />
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default TinRequestDetails;
