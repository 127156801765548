import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../components/detailItem";
// import "../../components/tableComponents/itemsTable.css";
import textFormatService, {
  getIpAddress,
} from "../../services/utility/textFormatService";
import { retrieveCurrentUsersIpAddress } from "../../services/auth/authService";

interface IAuditTrailDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  fetchNextTrail: any;
  item: any;
  trailMapper: any;
  fetchingDetail: boolean;
  jsonBefore: any;
  jsonAfter: any;
}

interface IAuditTrailDetailState {
  item: any;
  test: any;
}

class AuditTrailDetail extends Component<
  IAuditTrailDetailProps,
  IAuditTrailDetailState
> {
  state = {
    item: {},
    test: {},
  };

  handleOnTrailChange = (count: number) => {
    let item = this.props.item;
    item.count = count;
    item.objectId = item.informationId;
    this.props.fetchNextTrail(item);
  };

  static getDerivedStateFromProps(
    nextProps: IAuditTrailDetailProps,
    prevState
  ) {
    // console.log("current props: ", nextProps.item);
    return {
      item: nextProps.item,
    };
  }

  async componentDidMount() {
    var response = retrieveCurrentUsersIpAddress();
    this.setState({ test: response });
  }

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      item,
      trailMapper,
      fetchingDetail,
      jsonBefore,
      jsonAfter,
    } = this.props;
    const { test } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Audit Trail Details</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div
              className="card-header clear-fix"
              style={{ paddingBottom: "2px", paddingTop: "2px" }}
            >
              <nav aria-label="Page navigation example clearfix">
                <div className="float-right">
                  {_.isEmpty(item) || fetchingDetail === true ? (
                    <Skeleton width={300} />
                  ) : (
                    <ul
                      className="pagination float-left"
                      style={{ marginBottom: "0px", height: "32px" }}
                    >
                      <li
                        className={
                          _.isEmpty(item) || item.count === 1
                            ? "page-item disabled"
                            : "page-item"
                        }
                      >
                        <button
                          type="button"
                          onClick={() => this.handleOnTrailChange(1)}
                          className="page-link"
                        >
                          <i className="fas fa-step-backward" />
                        </button>
                      </li>
                      <li
                        className={
                          _.isEmpty(item) || item.count === 1
                            ? "page-item disabled"
                            : "page-item"
                        }
                      >
                        <button
                          type="button"
                          onClick={() =>
                            this.handleOnTrailChange(item.count - 1)
                          }
                          className="page-link"
                        >
                          <i className="fas fa-chevron-left" />
                        </button>
                      </li>
                      <li className="page-item">
                        <a
                          href="#!"
                          className="page-link"
                          style={{ height: "30px", paddingTop: "9px" }}
                        >
                          {_.isEmpty(item)
                            ? ""
                            : item.count + " of " + trailMapper.count}
                        </a>
                      </li>
                      <li
                        className={
                          _.isEmpty(item) || item.count === trailMapper.count
                            ? "page-item disabled"
                            : "page-item"
                        }
                      >
                        <button
                          type="button"
                          onClick={() =>
                            this.handleOnTrailChange(item.count + 1)
                          }
                          className="page-link"
                        >
                          <i className="fas fa-chevron-right" />
                        </button>
                      </li>
                      <li
                        className={
                          _.isEmpty(item) || item.count === trailMapper.count
                            ? "page-item disabled"
                            : "page-item"
                        }
                      >
                        <button
                          type="button"
                          onClick={() =>
                            this.handleOnTrailChange(trailMapper.count)
                          }
                          className="page-link"
                        >
                          <i className="fas fa-step-forward" />
                        </button>
                      </li>
                    </ul>
                  )}
                </div>
              </nav>
            </div>
            <div className="card-body" style={{ paddingBottom: "0" }}>
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) || fetchingDetail === true ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem label="Action Date" value={item.loggedAt} />
                      <DetailItem
                        label="Action Initiator"
                        value={
                          _.has(item, "initiator") &&
                          item.initiatingUser !== "0"
                            ? `${item.initiator.lastName.toUpperCase()}, ${
                                item.initiator.firstName
                              }`
                            : ""
                        }
                      />
                      <DetailItem
                        label="Action Type"
                        value={textFormatService.splitAtUpper(
                          item.informationType
                        )}
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) || fetchingDetail === true ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      {!_.isEmpty(item.clientIPAddress) && (
                        <DetailItem
                          label="Client IP Address"
                          value={item.clientIPAddress}
                        />
                      )}
                      <DetailItem label="Data Type" value={item.entityName} />
                      <DetailItem label="Unique Id" value={item.uniqueId} />
                    </dl>
                  )}
                </div>
              </div>
            </div>
            <div
              className="card-header card-footer"
              style={{ padding: "0px", height: "35px", paddingTop: "5px" }}
            >
              <i className="fas fa-list-alt mr-3 ml-3" />
              <b> User (Test user) Details</b>
            </div>
            <div className="card-body">
              <div
                className="card-content table-responsive"
                style={{ height: "250px", overflowY: "scroll" }}
              >
                <table className="table table-hover table-content table-sm table-bordered table-striped mb-0">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        style={{
                          width: "5px",
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                        }}
                      >
                        <strong>#</strong>
                      </th>
                      <th
                        scope="col"
                        style={{
                          width: "200px",
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                        }}
                      >
                        <strong>Field Name</strong>
                      </th>
                      <th
                        scope="col"
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                        }}
                      >
                        <strong>Value Before Request</strong>
                      </th>
                      <th
                        scope="col"
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                        }}
                      >
                        <strong>Value After Request</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.isEmpty(item) || fetchingDetail === true ? (
                      <tr>
                        <td
                          className="font-weight-normal font-italic text-truncate"
                          colSpan={4}
                          style={{ textAlign: "center", color: "#FE825E" }}
                        >
                          ...fetching trails. Please Wait!
                        </td>
                      </tr>
                    ) : (
                      Object.keys(jsonAfter).map((entity) => (
                        <tr key={entity}>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {Object.keys(jsonAfter).indexOf(entity) + 1}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {textFormatService.splitAtUpper(entity)}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                              backgroundColor: !jsonBefore
                                ? "none"
                                : jsonBefore[entity] !== jsonAfter[entity]
                                ? "#FAB39B"
                                : "none",
                            }}
                          >
                            {!jsonBefore ? " " : jsonBefore[entity]}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                              backgroundColor: !jsonBefore
                                ? "none"
                                : jsonBefore[entity] !== jsonAfter[entity]
                                ? "#BAFEB7"
                                : "none",
                            }}
                          >
                            {jsonAfter[entity]}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AuditTrailDetail;
