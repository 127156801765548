import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import textFormatService from "../../../services/utility/textFormatService";
// import textFormatService from "../../../services/utility/textFormatService";

interface IDisbursementRequestDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  // toggleEditModal: any;
  // showTrail: any;
  // currentUserRoles: any;
  item: any;
}

interface IDisbursementRequestDetailState {
  item: any;
  updatingStatus: boolean;
}

class DisbursementRequestDetail extends Component<
  IDisbursementRequestDetailProps,
  IDisbursementRequestDetailState
> {
  constructor(props: IDisbursementRequestDetailProps) {
    super(props);
    this.state = {
      item: {},
      updatingStatus: false,
    };
  }

  static getDerivedStateFromProps(
    nextProps: IDisbursementRequestDetailProps,
    prevState
  ) {
    // console.log("current props: ", nextProps.item);
    if (prevState.item !== nextProps.item) {
      return {
        item: nextProps.item,
        // updateProduct: true,
      };
    }
  }

  render() {
    const { showDetailsModal, toggleDetailsModal, item } = this.props;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="icon icon-building-o s-18 mr-3" />
                Disbursement Request Details
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={5} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Customer BVN"
                        value={item.customerBvn}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Repayment Account"
                        value={item.linkedAccountNumber}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Tenure"
                        value={item.tenure}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Loan Amount"
                        value={textFormatService.convertKoboToNaira(item.loanAmount, 2)}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Linked Account Number"
                        value={item.linkedAccountNumber}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Date Created"
                        value={textFormatService.formatTimeString(item.dateCreated)}
                        labelSize={6}
                        valueSize={6}
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={5} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Gender"
                        value={item.gender}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Loan Account Number"
                        value={item.loanAccountNumber}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Disbursement Stage"
                        value={item.disbursementStage}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Total Repayment Expected"
                        value={textFormatService.convertKoboToNaira(item.totalRepaymentExpected, 2)}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Date Finished"
                        value={textFormatService.formatTimeString(item.dateFinished)}
                        labelSize={6}
                        valueSize={6}
                      />
                      {_.isEmpty(item.errorMessage) ? (
                        <></>
                      ) : (
                        <DetailItem
                          label="Error Message"
                          value={item.errorMessage}
                          labelSize={6}
                          valueSize={6}
                        />
                      )}
                    </dl>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default DisbursementRequestDetail;
