import React, { Fragment } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { ActionTypes } from "../../enums/actionTypes";
import { Modal, Button } from "react-bootstrap";
import EnableBranchApprovalDetail from "./approvalDetailPages/auth/branch/enableBranch";
import DisableBranchApprovalDetail from "./approvalDetailPages/auth/branch/disableBranch";
import approvalActionService from "../../services/approval/approvalActionService";
import approvalItemService from "../../services/approval/approvalItemService";
import activityTrailService from "../../services/auditTrail/activityTrailService";
import usersService from "../../services/auth/usersService";
import textFormatService from "../../services/utility/textFormatService";
import SaveBranchApprovalDetail from "./approvalDetailPages/auth/branch/saveBranch";
import UpdateBranchApprovalDetail from "./approvalDetailPages/auth/branch/updateBranch";
import EnableUserApprovalDetail from "./approvalDetailPages/auth/user/enableUser";
import DisableUserApprovalDetail from "./approvalDetailPages/auth/user/disableUser";
import UpdateUserApprovalDetail from "./approvalDetailPages/auth/user/updateUser";
import SaveUserApprovalDetail from "./approvalDetailPages/auth/user/saveUser";
import EnableUserRoleApprovalDetail from "./approvalDetailPages/auth/userRole/enableUserRole";
import DisableUserRoleApprovalDetail from "./approvalDetailPages/auth/userRole/disableUserRole";
import UpdateUserRoleApprovalDetail from "./approvalDetailPages/auth/userRole/updateUserRole";
import SaveUserRoleApprovalDetail from "./approvalDetailPages/auth/userRole/saveUserRole";
import BankOneLoanItemApprovalDetail from "./approvalDetailPages/mandate/consentRequest/bankoneLoanItem";
import BaseFormComponent, {
  IBaseFormState,
} from "../../components/BaseFormComponent";
import approvalValidationService from "../../services/approval/approvalValidationService";
import { getCurrentUsersRoles } from "../../services/auth/authService";

interface ApprovalDetailFields {
  comment: string;
}

class ApprovalDetailForm extends BaseFormComponent<ApprovalDetailFields> { }

interface IApprovalDetailProps {
  item: any;
  currentUserSession: any;
  reloadItem: any;
  refreshList: any;
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleRuleDetailsModal: any;
  isApprovalRequest: boolean;
}
interface IApprovalDetailState {
  applicableRules: any;
  approvalActions: any;
  updateComponent: boolean;
  documentsToUpload: any;
  documentsToDelete: number[];
  canEditItem: boolean;
  updateAvailable: boolean;
}

class ApprovalDetail extends React.Component<
  IApprovalDetailProps,
  IBaseFormState & IApprovalDetailState
> {
  constructor(props: IApprovalDetailProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      applicableRules: [],
      approvalActions: [],
      documentsToUpload: [],
      documentsToDelete: [],
      updateComponent: false,
      canEditItem: false,
      updateAvailable: false
    };
  }

  //   static async getDerivedStateFromProps(
  //     nextProps: IApprovalDetailProps,
  //     prevState
  //   ) {
  //     console.log("Reached getDerivedState");
  //     // if (nextProps.item != prevState.item) {
  //     //   if (_.isEmpty(nextProps.item)) {
  //     //     return { approvalActions: [] };
  //     //   }
  //     console.log("getDerivedState about to update Component");
  //     return { updateComponent: !prevState.updateComponent };
  //     // }
  //     // return null;
  //   }

  //   async componentDidUpdate(prevProps, prevState) {
  //     console.log("Reached ComponentDidUpdate");
  //     if (this.props.item != prevProps.item) {
  //       if (!_.isEmpty(this.props.item)) {
  //         let applicableRules = this.props.item.approvable.rules.filter(
  //           (rule) =>
  //             rule.level === this.props.item.currentLevel &&
  //             rule.roleID === this.props.currentUserSession.userRoleId
  //         );
  //         if (
  //           this.props.item.approvableAmount !== 0 &&
  //           !_.isEmpty(applicableRules)
  //         ) {
  //           console.log("filtering further: ", applicableRules);
  //           applicableRules.filter(
  //             (rule) =>
  //               rule.applicableAmountLower <= this.props.item.approvableAmount &&
  //               rule.applicableAmountHigher >= this.props.item.approvableAmount
  //           );
  //           console.log("final result: ", applicableRules);
  //         }
  //         console.log("applicableRules: ", applicableRules);

  //         const approvalActions = this.props.item.approvalActions;
  //         for (let index = 0; index < approvalActions.length; index++) {
  //           const user = await usersService.retrieveUserById(
  //             approvalActions[index].userID
  //           );
  //           // const role = await rolesService.getRoleById(
  //           //   approvalActions[index].roleID
  //           // );
  //           approvalActions[index]["user"] = user.data;
  //           //approvalActions[index]["role"] = role.data;
  //         }
  //         approvalActions.reverse();
  //         console.log("approval actions:", approvalActions);
  //         this.setState({ approvalActions, applicableRules });
  //       }
  //     }
  //   }

  renderActionType = (actionType) => {
    switch (actionType) {
      case "Approve":
        return <span className="badge badge-success">{actionType}</span>;
      case "Cancelled":
      case "Decline":
        return <span className="badge badge-danger">{actionType}</span>;
      case "Created":
        return <span className="badge badge-default">{actionType}</span>;
      case "Submit":
        return <span className="badge badge-primary">{actionType}</span>;
      case "PushedBack":
        return <span className="badge badge-warning">{actionType}</span>;
      case "Update":
        return <span className="badge badge-info">{actionType}</span>;
      default:
        return <span className="badge badge-light">{actionType}</span>;
    }
  };

  renderItemStatus = (status) => {
    switch (status) {
      case "Completed":
        return <span className="badge badge-success">{status}</span>;
      case "Rejected":
        return <span className="badge badge-danger">{status}</span>;
      case "Created":
        return <span className="badge badge-default">{status}</span>;
      case "Approved":
        return <span className="badge badge-primary">{status}</span>;
      case "Pending":
        return <span className="badge badge-warning">{status}</span>;
      default:
        return <span className="badge badge-light">{status}</span>;
    }
  };

  getActionTypeString = (actionType) => {
    switch (actionType) {
      case 9:
        return "Recall";
      case 8:
        return "PushedBack";
      case 7:
        return "Decline";
      case 6:
        return "Approve";
      case 2:
        return "Update";
      default:
        return "";
    }
  };

  onFormSubmit(
    fields: ApprovalDetailFields,
    actionType,
    onReloadFieldsCB: any
  ): boolean {
    try {
      this.setState(
        {
          errors: approvalValidationService.validateApprovalDetailForm(fields),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, actionType, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: ApprovalDetailFields,
    actionType,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      // console.log("submitting approval action...", actionType);
      const approvalItem = { ...this.props.item };
      const currentUserSession = { ...this.props.currentUserSession };
      const actionPayLoad: any = {};
      actionPayLoad.functionID = approvalItem.functionID;
      actionPayLoad.itemID = approvalItem.id;
      actionPayLoad.itemLevel = approvalItem.currentLevel;
      actionPayLoad.userID = currentUserSession.userId;
      actionPayLoad.roleID = currentUserSession.userRoleId;
      actionPayLoad.institutionId = currentUserSession.institutionId;
      actionPayLoad.comment = fields.comment;
      actionPayLoad.actionType = this.getActionTypeString(actionType);

      // console.log("actionPayLoad: ", actionPayLoad);
      // console.log("Submitting...");
      this.setState({ submitting: true });
      let response;
      if (actionType === 9) {
        response = await approvalItemService.recallApprovalItem(
          approvalItem,
          this.props.currentUserSession.userId
        );
      } else if (actionType === 2) {
        if (this.state.documentsToUpload.length > 0) {
          //upload docs
        }
        // documents to remove

        const updatePayload: any = {};
        updatePayload.itemData = this.props.item;
        updatePayload.actionData = actionPayLoad;
        updatePayload.docsToSave = this.state.documentsToUpload;
        updatePayload.docsToDelete = this.state.documentsToDelete;

        // console.log("up payload", updatePayload)
        response = await approvalActionService.performUpdateAction(updatePayload);
      } else {
        response = await approvalActionService.saveApprovalAction(
          actionPayLoad
        );
      }
      //this.setState({ submitting: false });
      await activityTrailService.saveActivityTrail({
        actionType: "Add Approval Action",
        description: `Added new Approval Action [${response.data.uuid}]`,
      });
      toast.info(`Approval action saved successfully! `, {
        autoClose: 6000,
        type: toast.TYPE.DEFAULT,
        hideProgressBar: false,
      });

      this.props.reloadItem(this.props.item);
      this.props.refreshList();
      this.setState({ validated: false, submitting: false });
      onReloadFieldsCB();
      return true;
    } catch (error) {
      toast.error(error.response.data.Message);
      this.setState({ submitting: false });
      return false;
    }
  }

  approvalDetailsPage = (item) => {
    if (_.isEmpty(item)) return "";

    //console.log(item.jsonData);
    var title = item.approvable.functionName;

    //console.log(title);
    switch (title) {
      case "Enable Branch":
        return <EnableBranchApprovalDetail item={item.jsonData} />;
      case "Disable Branch":
        return <DisableBranchApprovalDetail item={item.jsonData} />;
      case "Save Branch":
        return <SaveBranchApprovalDetail item={item.jsonData} />;
      case "Update Branch":
        return (
          <UpdateBranchApprovalDetail item={item.jsonData} title={title} />
        );
      case "Enable User":
        return <EnableUserApprovalDetail item={item.jsonData} />;
      case "Disable User":
        return <DisableUserApprovalDetail item={item.jsonData} />;
      case "Update User":
        return <UpdateUserApprovalDetail item={item.jsonData} />;
      case "Save User":
        return <SaveUserApprovalDetail item={item.jsonData} title={title} />;
      case "Enable Role":
        return <EnableUserRoleApprovalDetail item={item.jsonData} />;
      case "Disable Role":
        return <DisableUserRoleApprovalDetail item={item.jsonData} />;
      case "Update Role":
        return <UpdateUserRoleApprovalDetail item={item.jsonData} />;
      case "Save Role":
        return ((item) => {
          // console.log("approval item:", this.state.approvalActions);
          return <SaveUserRoleApprovalDetail item={item} title={title} />;
        })(JSON.parse(item.jsonData));
      case "Create BankOne Recova Loan":
      case "BankOne Recova Loan":
        return <BankOneLoanItemApprovalDetail
          item={item.jsonData}
          financialGroup={{ lowerLimit: this.state.applicableRules[0]?.applicableAmountLower, upperLimit: this.state.applicableRules[0]?.applicableAmountUpper }}
          updateItem={(jsonData: string, amount: number) => this.updateApprovalItem(jsonData, amount)}
          updateItemDocs={(document: any) => this.updateApprovalItemDocs(document)}
          deleteItemDocs={(document: any, documentId: number) => this.deleteApprovalItemDocs(document, documentId)}
          tempDocs={this.state.documentsToUpload}
          tempDeletedDocs={this.state.documentsToDelete}
          canEditItem={this.state.canEditItem}
        />;
      default:
        return (
          <div>
            <h6 className="font-weight-normal text-center font-italic text-warning">
              ... no approval details page available for item.
            </h6>
          </div>
        );
    }
  };

  updateApprovalItem = async (itemJSON: string, applicableAmount: number) => {
    try {
      const payload = this.props.item;
      payload.applicableAmount = applicableAmount;
      payload.jsonData = itemJSON;
      this.setState({ updateAvailable: true })
      return true;
    } catch (error) {
      toast.error(error.response.data.Message);
      this.setState({ submitting: false });
      return false;
    }
  };

  updateApprovalItemDocs = async (document: any) => {
    try {
      const docs = [...this.state.documentsToUpload];
      docs.push(document);
      this.setState({ documentsToUpload: docs, updateAvailable: true });
    } catch (error) {
      // log
    }
  };

  deleteApprovalItemDocs = async (document: any, documentId: number) => {
    try {
      if (document != null) {
        const docs = [...this.state.documentsToUpload];
        let selectedDoc = docs.filter(doc => doc.document === document.document && doc.name === document.name)[0]
        docs.splice(docs.indexOf(selectedDoc), 1);
        this.setState({ documentsToUpload: docs, updateAvailable: true });
      } else {
        const docs = [...this.state.documentsToDelete];
        docs.push(documentId);
        this.setState({ documentsToDelete: docs, updateAvailable: true });
      }
    } catch (error) {
      // log
    }
  };

  validateField = (fieldName: string, type: string, validated: boolean) => {
    let defaultClass = "";
    if (type === "select") {
      defaultClass =
        "custom-select select2 mr-sm-2 form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    } else {
      defaultClass = "form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    }
    return !validated
      ? defaultClass
      : this.state.errors[fieldName]
        ? `${defaultClass} is-invalid`
        : `${defaultClass} is-valid`;
  };

  async showModal() {
    // console.log("Showing modal");
    const { item } = this.props;
    if (_.isEmpty(item)) {
      //dont move if item is not set
      this.setState({ approvalActions: [] });
      return;
    }
    // console.log("checker: ", item.approvable.rules);
    // fetch all the relevant roles for the item wrt the current level.
    let applicableRules = item.approvable.rules.filter(
      (rule) =>
        rule.level === item.currentLevel &&
        rule.roleID === this.props.currentUserSession.userRoleId
    );
    if (item.approvableAmount !== 0 && !_.isEmpty(applicableRules)) {
      // console.log("filtering further: ", applicableRules);
      applicableRules = applicableRules.filter(
        (rule) =>
          rule.applicableAmountLower <= item.applicableAmount &&
          rule.applicableAmountUpper >= item.applicableAmount
      );
      // console.log("final result: ", applicableRules);
    }
    // console.log("applicableRules: ", applicableRules);
    //this.setState({ applicableRules });

    const approvalActions = item.approvalActions;

    for (let index = 0; index < approvalActions.length; index++) {
      if (approvalActions[index].logType !== "ApplicationType") {
        const user = await usersService.retrieveUserById(
          approvalActions[index].userID
        );
        // const role = await rolesService.getRoleById(
        //   approvalActions[index].roleID
        // );

        approvalActions[index]["user"] = user.data;
        //approvalActions[index]["role"] = role.data;
      }
    }
    approvalActions.reverse();
    // console.log("approval actions:", approvalActions);

    // Check if any user role on the current level has approved
    const checkActions = approvalActions.filter(action => (action.itemLevel == item.currentLevel && action.actionType == "Approve"));
    // console.log("approval actions:", checkActions);
    const canEdit = this.props.isApprovalRequest === false && applicableRules.length !== 0 && item.status !== "Rejected" && (!getCurrentUsersRoles().includes("remotesession")) && checkActions.length == 0

    this.setState({ approvalActions, applicableRules, canEditItem: canEdit });
  }
  render() {
    const {
      item,
      showDetailsModal,
      toggleDetailsModal,
      toggleRuleDetailsModal,
      isApprovalRequest,
    } = this.props;
    const { approvalActions, validated, errors, applicableRules, submitting, updateAvailable } =
      this.state;

    // console.log(item)
    // console.log("approval actiions in render function", approvalActions);
    // console.log("applicable rules in render function", applicableRules);

    return (
      <Modal
        size="xl"
        show={showDetailsModal}
        onShow={() => this.showModal()}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <h5>Approval Details</h5>
        </Modal.Header>
        <ApprovalDetailForm
          initialValues={{ comment: "" }}
          FormComponent={({
            fields: { comment },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <Fragment>
              <Modal.Body>
                <div className="card">
                  <div
                    className="card-header clearfix"
                    style={{ padding: "0px", height: "35px" }}
                  >
                    <div className="float-right">
                      {_.isEmpty(item) ? (
                        <Skeleton width={120} />
                      ) : (
                        <span
                          className="badge badge-light"
                          onClick={toggleRuleDetailsModal}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fas fa-network-wired mr-3 red-text" />
                          Approval Steps
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-content">
                      <div className="row">
                        <div className="col-md-7">
                          <div className="row detail">
                            <div className="col-md-3">
                              <p>Name: </p>
                            </div>
                            <div className="col-md-8">
                              <strong>
                                {_.isEmpty(item) ? (
                                  <Skeleton width={150} height={10} />
                                ) : (
                                  <p>{item.functionName}</p>
                                )}
                              </strong>
                            </div>
                          </div>
                          <div className="row detail">
                            <div className="col-md-3">
                              <p>Initiated By: </p>
                            </div>
                            <div className="col-md-8">
                              <strong>
                                {_.isEmpty(item) ? (
                                  <Skeleton width={150} height={10} />
                                ) : (
                                  <p>
                                    {item.logType === "ApplicationType" ? (
                                      <React.Fragment>
                                        {
                                          JSON.parse(
                                            item.applicationDetails
                                          ).UserName.split(":")[0]
                                        }
                                        [
                                        <span className="deep-orange-text">
                                          {
                                            JSON.parse(item.applicationDetails)
                                              .ApplicationName
                                          }
                                        </span>
                                        ]
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        {item.user.firstName},{" "}
                                        {item.user.lastName} [
                                        <span className="deep-orange-text">
                                          {item.role.name}
                                        </span>
                                        ]
                                      </React.Fragment>
                                    )}
                                  </p>
                                )}
                              </strong>
                            </div>
                          </div>
                          {/* <div className="row detail">
                      <div className="col-md-3">
                        <p>Function Name: </p>
                      </div>
                      <div className="col-md-8">
                        <strong>
                          {_.isEmpty(item) ? (
                            <Skeleton width={150} height={10} />
                          ) : (
                            <p>{item.functionName}</p>
                          )}
                        </strong>
                      </div>
                    </div> */}
                        </div>
                        <div className="col-md-5">
                          <div className="row detail">
                            <div className="col-md-5">
                              <p>Current Level: </p>
                            </div>
                            <div className="col-md-7">
                              <strong>
                                {_.isEmpty(item) ? (
                                  <Skeleton width={150} height={10} />
                                ) : (
                                  <React.Fragment>
                                    {this.renderItemStatus(item.status)}
                                    <span className="badge badge-dark ml-2">
                                      {item.currentLevel}
                                    </span>
                                  </React.Fragment>
                                )}
                              </strong>
                            </div>
                          </div>
                          <div className="row detail">
                            <div className="col-md-5">
                              <p>Request Date: </p>
                            </div>
                            <div className="col-md-7">
                              <strong>
                                {_.isEmpty(item) ? (
                                  <Skeleton width={150} height={10} />
                                ) : (
                                  <p>{item.createdDate}</p>
                                )}
                              </strong>
                            </div>
                          </div>
                          {/* <div className="row detail">
                      <div className="col-md-3">
                        <p>Approved By: </p>
                      </div>
                      <div className="col-md-8">
                        <strong>
                          {_.isEmpty(item) ? (
                            <Skeleton width={150} height={10} />
                          ) : (
                            <p>Testing...</p>
                          )}
                        </strong>
                      </div>
                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-header card-footer"
                    style={{
                      padding: "0px",
                      height: "35px",
                      paddingTop: "5px",
                    }}
                  >
                    <i className="fas fa-list-alt mr-3 ml-3" /> Items Details
                  </div>
                  <div className="card-body">
                    <div className="card-content row">
                      <div className="col-md-7">
                        {this.approvalDetailsPage(item)}
                      </div>
                      <div className="col-md-5 border border-light border-right-0 border-left-0">
                        <div className="text-center">
                          <h6
                            className="font-weight-normal"
                            style={{ marginTop: "0.5rem", display: "inline" }}
                          >
                            Approval Actions
                          </h6>
                          <span
                            className="badge badge-pill badge-light ml-3"
                            style={{
                              display: "inline",
                              verticalAlign: "text-top",
                            }}
                          >
                            {approvalActions.length}
                          </span>
                        </div>
                        <hr style={{ marginTop: "0rem" }} />
                        <div
                          className="table-responsize text-nowrap"
                          style={{ height: "500px", overflowY: "scroll" }}
                        >
                          {approvalActions.length === 0 ? (
                            <React.Fragment>
                              <div>
                                <Skeleton height={50} />
                              </div>
                              <div>
                                <Skeleton height={50} />
                              </div>
                              <div>
                                <Skeleton height={50} />
                              </div>
                              <div>
                                <Skeleton height={50} />
                              </div>
                            </React.Fragment>
                          ) : (
                            approvalActions.map((action) => (
                              <React.Fragment
                                key={textFormatService.generateGUID()}
                              >
                                <div
                                  className="clearfix"
                                  style={{
                                    backgroundColor: "#FDFDFD",
                                    borderRadius: "5px",
                                    marginRight: "0.3rem",
                                  }}
                                >
                                  <p className="float-right">
                                    <i className="far fa-clock mr-2 green-text"></i>
                                    {textFormatService.getWhileAgo(
                                      action.createdDate
                                    )}
                                  </p>
                                  <div className="float-left ml-2">
                                    <h6
                                      className="font-weight-normal"
                                      style={{ margin: "0px" }}
                                    >
                                      <i className="fas fa-user-circle indigo-text mr-2"></i>
                                      {action.logType === "ApplicationType" ? (
                                        <React.Fragment>
                                          {
                                            JSON.parse(
                                              item.applicationDetails
                                            ).UserName.split(":")[0]
                                          }
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                          {/* {item.actionType} */}
                                          {action.user.firstName},{" "}
                                          {action.user.lastName}
                                        </React.Fragment>
                                      )}
                                      {/* {item.logType === "ApplicationType"
                                  ? `${
                                      JSON.parse(item.applicationDetails)
                                        .applicationName
                                    }`
                                  : _.isEmpty(item)
                                  ? ""
                                  : `${item.user.firstName}, ${item.user.lastName}`} */}
                                    </h6>
                                    {/* [${item.role.name}]` */}
                                    {/* {item.user.firstName}, {item.user.lastName} [
                                {item.role.name}] */}
                                    <p style={{ margin: "0px" }}>
                                      {this.renderActionType(action.actionType)}
                                    </p>
                                    <p
                                      className="font-italic deep-orange-text"
                                      style={{ margin: "0px" }}
                                    >
                                      {action.comment}
                                    </p>
                                  </div>
                                </div>
                                <hr
                                  style={{
                                    marginTop: "0.2rem",
                                    marginBottom: "0.2rem",
                                  }}
                                />
                              </React.Fragment>
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                    <form>
                      <div className="form-group row">
                        <label
                          htmlFor="comment"
                          className="col-md-2 col-form-label"
                        >
                          Comment:
                        </label>
                        <div className="col-md-10">
                          <textarea
                            rows={3}
                            className={this.validateField(
                              "comment",
                              "text",
                              validated
                            )}
                            id="comment"
                            name="comment"
                            value={comment}
                            onChange={(e) =>
                              onChange("comment", e.currentTarget.value)
                            }
                          />
                          <div
                            className="invalid-feedback"
                            style={{ visibility: errors.comment }}
                          >
                            {errors.comment}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                {isApprovalRequest === false &&
                  applicableRules.length !== 0 &&
                  item.status !== "Rejected" && (!getCurrentUsersRoles().includes("remotesession")) ? (
                  <Button
                    size="sm"
                    variant="light"
                    disabled={
                      applicableRules.length === 0 ||
                      item.currentLevel === 1 ||
                      this.state.submitting
                    }
                    onClick={(e) => {
                      onHandleSubmit(e, ActionTypes.PUSHBACK, !updateAvailable ? "Item" : "Item. \n\t  Please, note that you have a pending update, and performing this action will discard it!\n\t  Proceed", () => {
                        this.onFormSubmit(
                          { comment },
                          ActionTypes.PUSHBACK,
                          onReloadFields
                        );
                      });
                    }}
                  >
                    {this.state.submitting === true ? (
                      <React.Fragment>
                        <i className="fas fa-spinner fa-spin mr-3"></i>
                        Please Wait...
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <i className="fas fa-history mr-3"></i>
                        Push Back
                      </React.Fragment>
                    )}
                  </Button>
                ) : (
                  ""
                )}
                {isApprovalRequest === true && (!getCurrentUsersRoles().includes("remotesession")) ? (
                  <Button
                    size="sm"
                    variant="danger"
                    disabled={applicableRules.length === 0 || submitting}
                    onClick={(e) => {
                      onHandleSubmit(e, ActionTypes.RECALL, "Item", () => {
                        this.onFormSubmit(
                          { comment },
                          ActionTypes.RECALL,
                          onReloadFields
                        );
                      });
                    }}
                  >
                    {this.state.submitting === true ? (
                      <React.Fragment>
                        <i className="fas fa-spinner fa-spin mr-3"></i>
                        Please Wait...
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <i className="fas fa-times-circle mr-3" />
                        Recall
                      </React.Fragment>
                    )}
                  </Button>
                ) : (
                  ""
                )}
                {isApprovalRequest === false &&
                  applicableRules.length !== 0 &&
                  item.status !== "Rejected" && (!getCurrentUsersRoles().includes("remotesession")) ? (
                  <Fragment>
                    {updateAvailable ? (
                      <Button
                        size="sm"
                        variant="primary"
                        disabled={this.state.submitting}
                        onClick={(e) => {
                          onHandleSubmit(e, ActionTypes.UPDATE, "Item", () => {
                            this.onFormSubmit(
                              { comment },
                              ActionTypes.UPDATE,
                              onReloadFields
                            );
                          });
                        }}
                      >
                        {this.state.submitting === true ? (
                          <React.Fragment>
                            <i className="fas fa-spinner fa-spin mr-3"></i>
                            Please Wait...
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <i className="fas fa-check-circle mr-3" />
                            Update
                          </React.Fragment>
                        )}
                      </Button>
                    ) : (
                      ""
                    )}
                    <Button
                      size="sm"
                      variant="success"
                      disabled={
                        applicableRules.length === 0 || this.state.submitting
                      }
                      onClick={(e) => {
                        onHandleSubmit(e, ActionTypes.APPROVE, !updateAvailable ? "Item" : "Item. \n\t  Please, note that you have a pending update, and performing this action will discard it!\n\t  Proceed", () => {
                          this.onFormSubmit(
                            { comment },
                            ActionTypes.APPROVE,
                            onReloadFields
                          );
                        });
                      }}
                    >
                      {this.state.submitting === true ? (
                        <React.Fragment>
                          <i className="fas fa-spinner fa-spin mr-3"></i>
                          Please Wait...
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <i className="fas fa-check-circle mr-3" />
                          Approve
                        </React.Fragment>
                      )}
                    </Button>
                    <Button
                      size="sm"
                      variant="danger"
                      disabled={
                        applicableRules.length === 0 || this.state.submitting
                      }
                      onClick={(e) => {
                        onHandleSubmit(e, ActionTypes.DECLINE, "Item", () => {
                          this.onFormSubmit(
                            { comment },
                            ActionTypes.DECLINE,
                            onReloadFields
                          );
                        });
                      }}
                    >
                      {this.state.submitting === true ? (
                        <React.Fragment>
                          <i className="fas fa-spinner fa-spin mr-3"></i>
                          Please Wait...
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <i className="fas fa-times-circle mr-3" />
                          Decline
                        </React.Fragment>
                      )}
                    </Button>
                  </Fragment>
                ) : (
                  ""
                )}
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={toggleDetailsModal}
                  className="mb-2 mt-2"
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </Fragment>
          )}
        ></ApprovalDetailForm>
      </Modal>
    );
  }
}

export default ApprovalDetail;
