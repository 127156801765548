import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";

const influxagentBaseAddress =
  process.env.REACT_APP_INFLUXAGENT_BASEADDRESS + `api/`;

export function retrieveLinkedBVNsCount(institutionCode: string) {
  var url =
    influxagentBaseAddress +
    `LinkingAgent/RetrieveLinkedBVNCount?institutionCode=${institutionCode}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveAllInstitutionsBVNLinkingDetails() {
  var url =
    influxagentBaseAddress +
    `LinkingAgent/RetrieveAllInstitutionsBVNLinkingDetails`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveLinkedAccountCount(institutionCode: string) {
  var url =
    influxagentBaseAddress +
    `LinkingAgent/RetrieveLinkedAccountCountPerInstitution?institutionCode=${institutionCode}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveAllInstitutionsLinkedAccountCount() {
  var url =
    influxagentBaseAddress +
    `LinkingAgent/RetrieveAllInstitutionsLinkedAccountCount`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveInstitutionLinkedAccountStats(
  rangeInDays,
  institutionId
) {
  var url =
    influxagentBaseAddress +
    `LinkingAgent/RetrieveInstitutionLinkedAccountStats?rangeInDays=${rangeInDays}&institutionId=${institutionId}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveLinkedAccountStats(rangeInDays) {
  var url =
    influxagentBaseAddress +
    `LinkingAgent/RetrieveLinkedAccountStats?rangeInDays=${rangeInDays}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export default {
  retrieveLinkedBVNsCount,
  retrieveAllInstitutionsBVNLinkingDetails,
  retrieveLinkedAccountCount,
  retrieveAllInstitutionsLinkedAccountCount,
  retrieveInstitutionLinkedAccountStats,
  retrieveLinkedAccountStats,
};
