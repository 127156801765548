import React from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../components/detailItem";
import textFormatService from "../../services/utility/textFormatService";
import DetailItemsTable from "../../components/tableComponents/detailItemsTable";
import { decodeToken } from "react-jwt";
import { mapEntityList } from "../../services/utility/autoMapperService";
import { getCurrentUserSession } from "../../services/auth/authService";
import institutionsService from "../../services/auth/institutionsService";
import sweepRunService from "../../services/sweep/sweepRunService";
import swal from "sweetalert";
import { toast } from "react-toastify";
import settlementService from "../../services/sweep/settlementService";
import reportsService from "../../services/sweep/reportsService";

export interface SettlementReportProps {
    showDetailsModal: boolean;
    toggleDetailsModal: any;
    item: any;
}

export interface SettlementReportState {
    repaymentTransactions: any;
    repaymentTransactionsTotalCount: number;
    repaymentTransactionsCurrentPage: number;
    fetchingRepaymentTransactions: boolean;
    values: any;
    fetchingDetail: boolean;
}

class SettlementTransactionReportDetail extends React.Component<SettlementReportProps, SettlementReportState> {
    constructor(props: SettlementReportProps) {
        super(props);
        this.state = {
            fetchingRepaymentTransactions: false,
            repaymentTransactions: [],
            repaymentTransactionsCurrentPage: 1,
            repaymentTransactionsTotalCount: 0,
            values: [],
            fetchingDetail: false
        };
    }

    handleOnRepaymentTransactionsPageChange = async (page: number, transactionId) => {
        const decodedToken = decodeToken(getCurrentUserSession().token || "");
        this.setState({ fetchingRepaymentTransactions: true });
        try {
            const institution = await institutionsService.retrieveInstitutionById(decodedToken?.["InstitutionId"]);
            const repaymentTrx = await sweepRunService.retrieveRepaymentsBySweepEpisodeId(
                institution.data.code,
                transactionId,
                Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
                page
            );
            this.setState({
                repaymentTransactions: mapEntityList({
                    entityList: repaymentTrx.data.item2,
                    properties: [
                        {
                            oldName: "linkedAccountInstitution",
                            newName: "Inflow Institution",
                        },
                        { oldName: "amountAttempted", newName: "amountAttempted" },
                        { oldName: "amountDebited", newName: "amountDebited" },
                        { oldName: "timeSwept", newName: "timeSwept" },
                        { oldName: "retrievalReferenceNo", newName: "retrievalReference" },
                    ],
                }),
                repaymentTransactionsCurrentPage: page,
                repaymentTransactionsTotalCount: repaymentTrx.data.item1,
                fetchingRepaymentTransactions: false,
            });
        } catch (error) {
            // console.log("error fetching repayment transactions: ", error);
            this.setState({ fetchingRepaymentTransactions: false });
        } finally {
            this.setState({ fetchingRepaymentTransactions: false });
        }
    };

    async retryTransaction() {
        try {
            swal({
                title: "Confirm!",
                text: `Are you sure you want to Retry this ${this.props.item.settlementEntryType} Transaction?`,
                icon: "warning",
                buttons: {
                    confirm: {
                        text: "Yes",
                        value: null,
                        visible: true,
                        className: "",
                        closeModal: true,
                    },
                    cancel: {
                        text: "Cancel",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true,
                    },
                },
                closeOnClickOutside: false,
                dangerMode: true,
            }).then(async (resp) => {
                if (resp) {
                    // console.log("not gonna cancel the mandate");
                } else {
                    var result = await settlementService.retryProcessingSettlementEntry(this.props.item.id);
                    console.log(result);
                    toast.info(`Settlement retry in progress! `, {
                        autoClose: 6000,
                        type: toast.TYPE.DEFAULT,
                        hideProgressBar: false,
                    });
                }
            });
        } catch (error) {
            // console.log("error: ", error);
            toast.error(`Unable to retry settlement entry. `, {
                autoClose: 6000,
                type: toast.TYPE.ERROR,
                hideProgressBar: false,
            });
        }
    }

    render() {
        const { showDetailsModal, toggleDetailsModal, item } = this.props;
        const { repaymentTransactions, repaymentTransactionsTotalCount, repaymentTransactionsCurrentPage, fetchingRepaymentTransactions } =
            this.state;

        return (
            <Modal size="lg" backdrop="static" show={showDetailsModal} onHide={toggleDetailsModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {_.isEmpty(item) ? (
                            <Skeleton width={500} />
                        ) : (
                            <h5>
                                <i className="fas fa-receipt fa-sm s-18 mr-3" />
                                Settlement Transactions Detail
                            </h5>
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card">
                        {console.log(item)}
                        <div className="card-header clear-fix">
                            {_.isEmpty(item) ? (
                                <Skeleton width={200} />
                            ) : (
                                <h6 className="card-title float-left" style={{ marginBottom: "0px" }}>
                                    <b>Details</b>
                                </h6>
                            )}
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-8">
                                    {_.isEmpty(item) ? (
                                        <Skeleton count={5} width={300} />
                                    ) : (
                                        <dl className="row">
                                            <DetailItem label="Customer name" value={item.customerName} labelSize={7}
                                                valueSize={5} />
                                            <DetailItem label="BVN" value={item.bvn} labelSize={7}
                                                valueSize={5} />
                                            <DetailItem label="Mandate Reference" value={item.mandateReference} labelSize={7}
                                                valueSize={5} />
                                            <DetailItem label="Lender Bank" value={item.lenderBank} labelSize={7}
                                                valueSize={5} />
                                            <DetailItem label="Repayment Account" value={item.repaymentAccount} labelSize={7}
                                                valueSize={5} />
                                            <DetailItem label="Expected repapyment" value={textFormatService.formatCurrency(Number(item.expectedRepayment), 2)} labelSize={7}
                                                valueSize={5} />
                                            <DetailItem label="Amount repaid" value={textFormatService.formatCurrency(Number(item.amountRepaid), 2)} labelSize={7}
                                                valueSize={5} />
                                            <DetailItem label="Transaction reference" value={item.transactionReference} labelSize={7}
                                                valueSize={5} />
                                            <DetailItem label="Debited bank" value={item.debitedBank} labelSize={7}
                                                valueSize={5} />
                                            <DetailItem label="Fee" value={textFormatService.formatCurrency(Number(item.fee), 2)} labelSize={7}
                                                valueSize={5} />
                                            <DetailItem label="Fee Basis" value={item.feeBasis} labelSize={7}
                                                valueSize={5} />
                                        </dl>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="card-header clear-fix">
                            <h6 className="card-title float-left" style={{ marginBottom: "0px" }}>
                                <b>Repayment History</b>
                            </h6>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-8">
                                    {_.isEmpty(item) ? (
                                        <Skeleton count={5} width={300} />
                                    ) : (
                                        <dl className="row">
                                            <DetailItem label="Total Deductions" value={textFormatService.formatCurrency(Number(item.totalDeductions), 2)} labelSize={7}
                                                valueSize={5} />
                                            <DetailItem label="Total Amount Repaid" value={textFormatService.formatCurrency(Number(item.amountRepaid), 2)} labelSize={7}
                                                valueSize={5} />

                                        </dl>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="outline-danger" onClick={() => { toggleDetailsModal() }}>
                        <i className="fas fa-times mr-3" />
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        );
    }
}

export default SettlementTransactionReportDetail;
