import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import approvalValidationService from "../../../services/approval/approvalValidationService";

interface AddRuleModalFields {
  userRoleId: string;
}

class AddRuleModalForm extends BaseFormComponent<AddRuleModalFields> {}

export interface IAddRuleModalProps {
  data: any;
  userRoles: any;
  showAddRuleModal: boolean;
  toggleAddRuleModal: any;
}

export interface IAddRuleModalState {
  data: any;
  ownUpdate: boolean;
}

class AddRuleModal extends React.Component<
  IAddRuleModalProps,
  IBaseFormState & IAddRuleModalState
> {
  constructor(props: IAddRuleModalProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      data: {},
      ownUpdate: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.ownUpdate) {
      // console.log("dataa before update: ", nextProps.data);
      return { data: nextProps.data };
    }
    return null;
  }

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  //   componentWillReceiveProps(nextProps) {
  //     this.setState({ data: nextProps.data });
  //   }

  onFormSubmit(fields: AddRuleModalFields, onReloadFieldsCB: any): boolean {
    try {
      const { data } = this.state;
      data.userRoleId = fields.userRoleId;
      this.setState({ data, ownUpdate: true }, () => {
        this.setState(
          {
            errors: approvalValidationService.validateApprovalRuleConfigForm(
              data
            ),
          },
          () => {
            if (Object.keys(this.state.errors).length === 0) {
              this.submit(data, onReloadFieldsCB);
            }
          }
        );
      });
      this.setState({ validated: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (data: any, onReloadFieldsCB: any) => {
    this.props.toggleAddRuleModal(
      "",
      data.userRoleId,
      data.combinationNo,
      data.level,
      data.applicableAmount,
      true
    );
    onReloadFieldsCB();
  };

  validateField = (fieldName: string, type: string, validated: boolean) => {
    let defaultClass = "";
    if (type === "select") {
      defaultClass =
        "custom-select select2 mr-sm-2 form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    } else {
      defaultClass = "form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    }
    return !validated
      ? defaultClass
      : this.state.errors[fieldName]
      ? `${defaultClass} is-invalid`
      : `${defaultClass} is-valid`;
  };

  render() {
    var { userRoles, showAddRuleModal, toggleAddRuleModal } = this.props;
    var { data, errors, submitting, validated } = this.state;
    return (
      <Modal
        size="lg"
        backdrop="static"
        show={showAddRuleModal}
        onHide={toggleAddRuleModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-tasks mr-3"></i>
              {data.title}
            </h5>
          </Modal.Title>
        </Modal.Header>
        <AddRuleModalForm
          initialValues={{ userRoleId: data.userRoleId }}
          FormComponent={({
            fields: { userRoleId },
            onChange,
            onReloadFields,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="form-group row">
                  <label
                    htmlFor="userRoleId"
                    className="col-md-4 col-form-label"
                  >
                    User Role:
                  </label>
                  <div className="col-md-8">
                    <select
                      className={this.validateField(
                        "userRoleId",
                        "select",
                        validated
                      )}
                      onChange={(e) =>
                        onChange("userRoleId", e.currentTarget.value)
                      }
                      value={userRoleId}
                      name="userRoleId"
                    >
                      <option value="">-Select User Role-</option>
                      {!_.isEmpty(userRoles)
                        ? userRoles.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))
                        : ""}
                    </select>
                    <div
                      className="invalid-feedback"
                      style={{ visibility: errors.userRoleId }}
                    >
                      {errors.userRoleId}
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="primary"
                  onClick={(e) => {
                    this.onFormSubmit({ userRoleId }, onReloadFields);
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fa fa-plus mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() =>
                    toggleAddRuleModal(
                      "",
                      data.userRoleId,
                      data.combinationNo,
                      data.level,
                      data.applicableAmount,
                      false
                    )
                  }
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></AddRuleModalForm>
      </Modal>
    );
  }
}

export default AddRuleModal;
