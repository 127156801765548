import Joi from "joi-browser";
import _ from "lodash";

export function validateAddConfigurationForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      minimumCreditDroughtInDays: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Minimum Credit Drough is required";
                break;
              case "number.min":
                err.message = `Minimum Credit Drough should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      maximumAvailableFund: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Maximum Available Fund is required";
                break;
              case "number.min":
                err.message = `Maximum Available Fund should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      availableFundLimit: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Available Fund Limit is required";
                break;
              case "number.min":
                err.message = `Available Fund Limit should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      // loanAgeInDays: Joi.number()
      //   .required()
      //   .min(0)
      //   .error((errors) => {
      //     errors.forEach(err => {
      //       switch (err.type) {
      //         case "number.base":
      //           err.message = "Loan Age is required";
      //           break;
      //         case "number.min":
      //           err.message = `Loan Age should not be negative`;
      //           break;
      //         default:
      //           break;
      //       }
      //     });
      //     return errors;
      //   }),
      // accountAgeInDays: Joi.number()
      //   .required()
      //   .min(0)
      //   .error((errors) => {
      //     errors.forEach(err => {
      //       switch (err.type) {
      //         case "number.base":
      //           err.message = "Account Age is required";
      //           break;
      //         case "number.min":
      //           err.message = `Account Age should not be negative`;
      //           break;
      //         default:
      //           break;
      //       }
      //     });
      //     return errors;
      //   }),
      // minDepositAmount: Joi.number()
      //   .required()
      //   .min(0)
      //   .error((errors) => {
      //     errors.forEach(err => {
      //       switch (err.type) {
      //         case "number.base":
      //           err.message = "Min Deposit Amount is required";
      //           break;
      //         case "number.min":
      //           err.message = `Min Deposit Amount should not be negative`;
      //           break;
      //         default:
      //           break;
      //       }
      //     });
      //     return errors;
      //   }),
      // maxDepositAmount: Joi.number()
      //   .required()
      //   .min(0)
      //   .greater(Number(data.minDepositAmount))
      //   .error((errors) => {
      //     errors.forEach(err => {
      //       switch (err.type) {
      //         case "number.greater":
      //           err.message = "Max Deposit Amount must be greater than Min Deposit Amount";
      //           break;
      //         case "number.base":
      //           err.message = "Max Deposit Amount is required";
      //           break;
      //         case "number.min":
      //           err.message = `Max Deposit Amount should not be negative`;
      //           break;
      //         default:
      //           break;
      //       }
      //     });
      //     return errors;
      //   }),
      // minFixedDepositAmount: Joi.number()
      //   .required()
      //   .min(0)
      //   .error((errors) => {
      //     errors.forEach(err => {
      //       switch (err.type) {
      //         case "number.base":
      //           err.message = "Min Fixed Deposit Amount is required";
      //           break;
      //         case "number.min":
      //           err.message = `Min Fixed Deposit Amount should not be negative`;
      //           break;
      //         default:
      //           break;
      //       }
      //     });
      //     return errors;
      //   }),
      // maxFixedDepositAmount: Joi.number()
      //   .required()
      //   .min(0)
      //   .greater(Number(data.minFixedDepositAmount))
      //   .error((errors) => {
      //     errors.forEach(err => {
      //       switch (err.type) {
      //         case "number.greater":
      //           err.message = "Max Fixed Deposit Amount must be greater than Min Fixed Deposit";
      //           break;
      //         case "number.base":
      //           err.message = "Max Fixed Deposit Amount is required";
      //           break;
      //         case "number.min":
      //           err.message = `Max Fixed Deposit Amount should not be negative`;
      //           break;
      //         default:
      //           break;
      //       }
      //     });
      //     return errors;
      //   }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddConfigurationPQAForm(data) {

  const result = Joi.validate(
    data,
    Joi.object().keys({
      minimumCreditDroughtInDays: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Minimum Credit Drough is required";
                break;
              case "number.min":
                err.message = `Minimum Credit Drough should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      maximumAvailableFund: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Maximum Available Fund is required";
                break;
              case "number.min":
                err.message = `Maximum Available Fund should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      availableFundLimit: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Available Fund Limit is required";
                break;
              case "number.min":
                err.message = `Available Fund Limit should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      loanAgeInDays: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Loan Age is required";
                break;
              case "number.min":
                err.message = `Loan Age should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      accountAgeInDays: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Account Age is required";
                break;
              case "number.min":
                err.message = `Account Age should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      minDepositAmount: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Min Deposit Amount is required";
                break;
              case "number.min":
                err.message = `Min Deposit Amount should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      maxDepositAmount: Joi.number()
        .required()
        .min(0)
        .greater(Number(data.minDepositAmount))
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.greater":
                err.message = "Max Deposit Amount must be greater than Min Deposit Amount";
                break;
              case "number.base":
                err.message = "Max Deposit Amount is required";
                break;
              case "number.min":
                err.message = `Max Deposit Amount should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      minFixedDepositAmount: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Min Fixed Deposit Amount is required";
                break;
              case "number.min":
                err.message = `Min Fixed Deposit Amount should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      maxFixedDepositAmount: Joi.number()
        .required()
        .min(0)
        .greater(Number(data.minFixedDepositAmount))
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.greater":
                err.message = "Max Fixed Deposit Amount must be greater than Min Fixed Deposit";
                break;
              case "number.base":
                err.message = "Max Fixed Deposit Amount is required";
                break;
              case "number.min":
                err.message = `Max Fixed Deposit Amount should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      frequencyPeriod: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Frequency Period is required.",
          };
        }),
      pqaFrequency: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "PQA Frequency is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddCreditQualificationRuleOtherForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      // loanProductId: Joi.string()
      //   .required()
      //   .error((errors) => {
      //     return { message: "Loan Product is Required" };
      //   }),
      minimumScore: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Minimum Score is Required" };
        }),
      // maxPercentageLoanAmountSalary: Joi.string()
      //   .required()
      //   .error((errors) => {
      //     return { message: "Max. (%) Amount of Loan Salary is Required" };
      //   }),
      scoreCard: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Score Card is Required" };
        }),
      name: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Name is Required" };
        }),
      // priorityLevel: Joi.string()
      //   .required()
      //   .error((errors) => {
      //     // console.log("joi error: ", errors);
      //     return { message: "Priority Level is Required" };
      //   }),
      // loanType: Joi.string()
      //   .required()
      //   .error((errors) => {
      //     return { message: "Loan Type is Required" };
      //   }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddCreditQualificationRuleAppzoneForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      name: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Name is Required" };
        }),
      // minimumScore: Joi.string()
      //   .required()
      //   .error((errors) => {
      //     return { message: "Minimum Static Score is Required" };
      //   }),
      // priorityLevel: Joi.string()
      //   .required()
      //   .error((errors) => {
      //     // console.log("joi error: ", errors);
      //     return { message: "Priority Level is Required" };
      //   }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddCreditAssessmentInstitutionForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      predictionServiceConfigModel: Joi.string()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Prediction Service Config Model is required",
          };
        }),
      genericFeeIncomeAccount: Joi.string()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Generic Fee Income Account is required",
          };
        }),
      predictionFeeIncomeAccount: Joi.string()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Prediction Fee Income Account is required",
          };
        }),
      genericExpenseGLAccount: Joi.string()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Generic Expense GL Account is required",
          };
        }),
      predictionExpenseGLAccount: Joi.string()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Prediction Expense GL Account is required",
          };
        }),
      genericPercentageOfLoanAmount: Joi.number()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Generic Percentage of Loan Amount is required",
          };
        }),
      predictionPercentageOfLoanAmount: Joi.number()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Prediction Percentage of Loan Amount is required",
          };
        }),
      genericMaximumPerRequest: Joi.number()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Generic Max. per Request is required",
          };
        }),
      predictionMaximumPerRequest: Joi.number()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Prediction Max. per Request is required",
          };
        }),
      genericMinimumPerRequest: Joi.number()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Generic Min. per Request is required",
          };
        }),
      predictionMinimumPerRequest: Joi.number()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Prediction Min. per Request is required",
          };
        }),
      genericFlatRatePerSuccess: Joi.number()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Generic Flat rate per successful request is required",
          };
        }),
      predictionFlatRatePerSuccess: Joi.number()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Prediction Flat rate per successful request is required",
          };
        }),
      genericFlatRatePerRequest: Joi.number()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Generic Flat rate per request is required",
          };
        }),
      predictionFlatRatePerRequest: Joi.number()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Prediction Flat rate per request is required",
          };
        }),
      genericFlatRatePerLoanDisbursed: Joi.number()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Generic Flat rate per loan disbursed is required",
          };
        }),
      predictionFlatRatePerLoanDisbursed: Joi.number()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Prediction Flat rate per loan disbursed is required",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddLoanProductForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      productName: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Loan Product Name is required" };
        }),
      productCode: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Product Code is required" };
        }),
      minimumAmount: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Minimum Amount is required" };
        }),
      maximumAmount: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Maximum Amount is required" };
        }),
      tenure: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Tenure is required" };
        }),
      // minimumScore: Joi.string()
      //   .required()
      //   .error((errors) => {
      //     return { message: "Minimum Score is required" };
      //   }),
      // repaymentSchedule: Joi.string()
      //   .required()
      //   .error((errors) => {
      //     return { message: "Repayment Schedule is required" };
      //   }),
      moratorium: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Moratorium is required" };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddWorkFlowForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      workflowName: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Work Flow Name is Required" };
        }),
      description: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Description is Required" };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddWorkFlowStateForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      name: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Work Flow Name is Required" };
        }),
      actionMethodName: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Action Method Name is Required" };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddFeeConfigurationForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      feeEntryType: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Fee Entry Type is Required" };
        }),
      flatRatePerRequest: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Flat Rate Per Request is Required" };
        }),
      flatRatePerLoanDisbursed: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Flat Rate Per Loan Disbursed is Required" };
        }),
      percentageOfLoanAmount: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Percentage Of Loan Amount is Required" };
        }),
      minimumPerTransaction: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Minimum Per Transaction is Required" };
        }),
      maximumPerTransaction: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Maximum Per Transaction is Required" };
        }),
      // feeIncomeGLAccountNumber: Joi.string()
      //   .required()
      //   .error((errors) => {
      //     return { message: "Fee Income GL Account Number is Required" };
      //   }),
      // expenseGLAccountNumber: Joi.string()
      //   .required()
      //   .error((errors) => {
      //     return { message: "Expense GL Account Number is Required" };
      //   }),
      flatRatePerSuccessfulRequest: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Flat Rate Per Successful Request is Required" };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddFeeConfigToOCMForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      flatRatePerRequest: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Flat Rate Per Request is Required" };
        }),
      flatRatePerLoanDisbursed: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Flat Rate Per Loan Disbursed is Required" };
        }),
      percentageOfLoanAmount: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Percentage Of Loan Amount is Required" };
        }),
      minimumPerTransaction: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Minimum Per Transaction is Required" };
        }),
      maximumPerTransaction: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Maximum Per Transaction is Required" };
        }),
      flatRatePerSuccessfulRequest: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Flat Rate Per Successful Request is Required" };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddOCMInstitutionForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      ussdCode: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Ussd Code is Required" };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddWorkFlowTransitionForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      sourceWorflowStateId: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Source Work Flow is Required" };
        }),
      nextWorkflowStateId: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Next Work Flow is Required" };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddScoreIndicatorForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      indicatorName: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Indicator Name is Required" };
        }),
      type: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Type is Required" };
        }),
      entryType: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Entry Type is Required" };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddMessageTemplateForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      name: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Name is Required" };
        }),
      messageType: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Message Type is Required" };
        }),
      subject: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Subject is Required" };
        }),
      body: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Body is Required" };
        }),
      bccEmailAddresses: Joi.when('messageType', { is: "Email", then: Joi.string().required() })
        .error((errors) => {
          return { message: "Bcc Email Addresses is Required" };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validatePointMetricInputFinite(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      exactValue: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Exact Value is Required" };
        }),
      // points: Joi.string()
      //   .required()
      //   .error((errors) => {
      //     return { message: "Points is Required" };
      //   }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validatePointMetricInputRange(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      minValue: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Min Value is Required" };
        }),
      maxValue: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Max Value is Required" };
        }),
      // points: Joi.string()
      //   .required()
      //   .error((errors) => {
      //     return { message: "Points is Required" };
      //   }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateTopUpAvailableBalanceForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      amountToTopUp: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Amount To Top Up is Required" };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditCreditAssessmentInstitutionForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      predictionServiceConfigModel: Joi.string()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Prediction Service Config Model is required",
          };
        }),
      genericFeeIncomeAccount: Joi.string()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Generic Fee Income Account is required",
          };
        }),
      predictionFeeIncomeAccount: Joi.string()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Prediction Fee Income Account is required",
          };
        }),
      genericExpenseGLAccount: Joi.string()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Generic Expense GL Account is required",
          };
        }),
      predictionExpenseGLAccount: Joi.string()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Prediction Expense GL Account is required",
          };
        }),
      genericPercentageOfLoanAmount: Joi.number()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Generic Percentage of Loan Amount is required",
          };
        }),
      predictionPercentageOfLoanAmount: Joi.number()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Prediction Percentage of Loan Amount is required",
          };
        }),
      genericMaximumPerRequest: Joi.number()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Generic Max. per Request is required",
          };
        }),
      predictionMaximumPerRequest: Joi.number()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Prediction Max. per Request is required",
          };
        }),
      genericMinimumPerRequest: Joi.number()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Generic Min. per Request is required",
          };
        }),
      predictionMinimumPerRequest: Joi.number()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Prediction Min. per Request is required",
          };
        }),
      genericFlatRatePerSuccess: Joi.number()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Generic Flat rate per successful request is required",
          };
        }),
      predictionFlatRatePerSuccess: Joi.number()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Prediction Flat rate per successful request is required",
          };
        }),
      genericFlatRatePerRequest: Joi.number()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Generic Flat rate per request is required",
          };
        }),
      predictionFlatRatePerRequest: Joi.number()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Prediction Flat rate per request is required",
          };
        }),
      genericFlatRatePerLoanDisbursed: Joi.number()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Generic Flat rate per loan disbursed is required",
          };
        }),
      predictionFlatRatePerLoanDisbursed: Joi.number()
        .required()
        .error((errors) => {
          // console.log("adminGender joi-error", errors)
          return {
            message: "Prediction Flat rate per loan disbursed is required",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditCreditQualificationRuleOtherForm(data) {
  return validateAddCreditQualificationRuleOtherForm(data);
}

export function validateEditCreditQualificationRuleAppzoneForm(data) {
  return validateAddCreditQualificationRuleAppzoneForm(data);
}

export function validateEditWorkFlowForm(data) {
  return validateAddWorkFlowForm(data);
}

export function validateEditLoanProductForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      productName: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Loan Product Name is required" };
        }),
      productCode: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Product Code is required" };
        }),
      minimumAmount: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Minimum Amount is required" };
        }),
      maximumAmount: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Maximum Amount is required" };
        }),
      // tenure: Joi.string()
      //   .required()
      //   .error((errors) => {
      //     return { message: "Tenure is required" };
      //   }),
      // minimumScore: Joi.string()
      //   .required()
      //   .error((errors) => {
      //     return { message: "Minimum Score is required" };
      //   }),
      // repaymentSchedule: Joi.string()
      //   .required()
      //   .error((errors) => {
      //     return { message: "Repayment Schedule is required" };
      //   }),
      // moratorium: Joi.string()
      //   .required()
      //   .error((errors) => {
      //     return { message: "Moratorium is required" };
      //   }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditOcmInstitutionForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      ussdCode: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Ussd Code is Required" };
        }),
      flatRatePerRequest: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Flat Rate Per Request is Required" };
        }),
      flatRatePerLoanDisbursed: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Flat Rate Per Loan Disbursed is Required" };
        }),
      percentageOfLoanAmount: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Percentage Of Loan Amount is Required" };
        }),
      minimumPerTransaction: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Minimum Per Transaction is Required" };
        }),
      maximumPerTransaction: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Maximum Per Transaction is Required" };
        }),
      flatRatePerSuccessfulRequest: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Flat Rate Per Successful Request is Required" };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditBlackboxInstitutionConfigForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      minimumCreditDroughtDays: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Minimum Credit Drought In Days is Required" };
        }),
      availableFundLimit: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Available Fund Limit is Required" };
        }),
      maximumAvailableFund: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Maximum Available Fund is Required" };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditLoanPricingConfigForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      interestRateMarkup: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Interest Rate Markup is Required" };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditBlackBoxConfigForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      cutOffInterestRate: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Cut Off Interest Rate is Required" };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditMessageTemplateForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      // messageType: Joi.string()
      //   .required()
      //   .error((errors) => {
      //     return { message: "Message Type is Required" };
      //   }),
      subject: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Subject is Required" };
        }),
      body: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Body is Required" };
        }),
      bccEmailAddresses: Joi.when('messageType', { is: "Email", then: Joi.string().required() })
        .error((errors) => {
          return { message: "Bcc Email Addresses is Required" };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditConfigurationForm(data) {
  return validateAddConfigurationForm(data);
}

export function validateEditFeeConfigurationForm(data) {
  return validateAddFeeConfigurationForm(data);
}

export function validateAddScoreCardForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      name: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Score Card Name is Required" };
        }),
      description: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Description is Required" };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditScoreCardForm(data) {
  return validateAddScoreCardForm(data);
}

export default {
  validateAddConfigurationForm,
  validateAddConfigurationPQAForm,
  validateAddCreditQualificationRuleOtherForm,
  validateAddCreditQualificationRuleAppzoneForm,
  validateAddCreditAssessmentInstitutionForm,
  validateAddLoanProductForm,
  validateAddWorkFlowForm,
  validateAddWorkFlowStateForm,
  validateAddFeeConfigurationForm,
  validateAddFeeConfigToOCMForm,
  validateAddOCMInstitutionForm,
  validateAddWorkFlowTransitionForm,
  validateAddScoreIndicatorForm,
  validateAddMessageTemplateForm,
  validatePointMetricInputFinite,
  validatePointMetricInputRange,
  validateTopUpAvailableBalanceForm,
  validateEditCreditAssessmentInstitutionForm,
  validateEditCreditQualificationRuleOtherForm,
  validateEditCreditQualificationRuleAppzoneForm,
  validateEditWorkFlowForm,
  validateEditLoanProductForm,
  validateEditOcmInstitutionForm,
  validateEditBlackboxInstitutionConfigForm,
  validateEditLoanPricingConfigForm,
  validateEditBlackBoxConfigForm,
  validateEditMessageTemplateForm,
  validateEditConfigurationForm,
  validateEditFeeConfigurationForm,
  validateAddScoreCardForm,
  validateEditScoreCardForm
};
