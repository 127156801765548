import React, { useCallback, useEffect, useState } from "react";
import generalSettingsService from "../../../../services/_axiosServices/sweep/general-settings";
import { Loader, Error } from "../../components";

const initialData = {
  lastInstitutionSync: "--",
  lastProactiveSweepRun: "-",
  lastPayoutDate: "--",
  commercialProactiveSweepInterval: "--",
  commercialMaxProactiveSweeps: "--",
  ofiProactiveSweepInterval: "--",
  ofiMaxProactiveSweeps: "--",
  bankOneProactiveSweepInterval: "--",
  bankOneMaxProactiveSweeps: "--",
  processingPayouts: "--",
  processingSettlements: "--",
  processingSweepActivities: "--",
  id: "--",
};

export default function Report() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(initialData);
  const [error, setError] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    await generalSettingsService
      .retrieveGeneralSettings()
      .then((res: any) => {
        // console.log(res);
        setLoading(false);
        setData(res.data);
      })
      .catch((err: any) => {
        setError(true);
        setLoading(false);
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="container-fluid relative animatedParent animateOnce">
      <div className="animated fadeInUpShort go">
        <div className="row my-3 mx-2">
          <h3>
            <b>Fee - Retrieve Settings</b>
          </h3>
        </div>
        <div className="card no-b">
          <div className="card-body">
            <div className="card-title">
              <div className="page">
                <div className="page-inner">
                  <div className="btn-toolbar justify-content-between">
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn btn-sm"
                        onClick={fetchData}
                      >
                        <i className="fas fa-sync"></i>
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive text-nowrap">
                    {loading && <Loader />}

                    {!loading && !error && (
                      <table className="table table-hover table-content table-striped table-bordered table-sm paged-output">
                        <thead>
                          <tr>
                            <th>Last Institution Sync</th>
                            <th>Last Proactive SweepRun</th>
                            <th>Last Payout Date</th>
                            <th>Commercial Proactive Sweep Interval</th>
                            <th>Commercial Max Proactive Sweeps</th>
                            <th>OFI ProactiveSweepInterval</th>
                            <th>OFI Max Proactive Sweeps</th>
                            <th>BankOne Proactive Sweep Interval</th>
                            <th>BankOne Max Proactive Sweeps</th>
                            <th>Processing Payouts</th>
                            <th>Processing Settlements</th>
                            <th>Processing Sweep Activities</th>
                            <th>id</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{data.lastInstitutionSync}</td>
                            <td>{data.lastProactiveSweepRun}</td>
                            <td>{data.lastPayoutDate}</td>
                            <td>{data.commercialProactiveSweepInterval}</td>
                            <td>{data.commercialMaxProactiveSweeps}</td>
                            <td>{data.ofiProactiveSweepInterval}</td>
                            <td>{data.ofiMaxProactiveSweeps}</td>
                            <td>{data.bankOneProactiveSweepInterval}</td>
                            <td>{data.bankOneMaxProactiveSweeps}</td>
                            <td>{data.processingPayouts ? "yes" : "no"}</td>
                            <td>{data.processingSettlements ? "yes" : "no"}</td>
                            <td>
                              {data.processingSweepActivities ? "yes" : "no"}
                            </td>
                            <td>{data.id}</td>
                          </tr>
                        </tbody>
                      </table>
                    )}

                    {error && !loading && <Error />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
