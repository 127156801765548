import MailingLists from "./../auth/mailingList/mailingLists";
import AddDisputeCondition from "../disputeCondition/addDisputeCondition";
import DisputeConditions from "../disputeCondition/disputeConditionList";
import LogDispute from "../dispute/logDispute";
import MyDisputes from "../dispute/myDisputes";
import OtherInstitutionsDisputes from "../dispute/otherInstitutionsDisputes";
import UnResolvedDisputes from "../dispute/unresolvedDisputes";
import SuccessfulIncomingRepaymentsReport from "../reports/successfulIncomingRepaymentReport";
import SuccessfulOutgoingRepaymentsReport from "../reports/successfulOutgoingRepaymentsReport";
import FailedRepaymentReport from "../reports/failedRepaymentReport";
import LoanUpdateTransactionsReport from "../reports/loanUpdateTransactionsReport";
import SettlementReport from "../reports/settlementReport";
import AppzoneSettlementReport from "../reports/appzoneSettlementReport";
import SettlementsAndPayoutsReport from "../reports/transactionsReports/settlementsAndPayoutsReport";
import SweepEpisode from "../reports/sweepEpisodes";
import ActiveMandatesReport from "../reports/activeMandatesReport";
import CancelledMandatesReport from "../reports/cancelledMandateReport";
import RecoveryModeMandatesReport from "../reports/recoveryModeMandatesReport";
import DataProviderReport from "../reports/ocm/dataProviderReport";
import ConsentRequests from "../mandate/consentRequest/consentRequests";
import ConsentRequestsOCM from "../mandate/consentRequest/consentRequestsOCM";
import Mandates from "../mandate/mandate/mandates";
import MandatesOCM from "../mandate/mandate/mandatesOCM";
import UploadMandates from "../mandate/mandate/uploadMandates";
import MandateUploadRequests from "../mandate/mandate/mandateUploadRequests";
import MandateServiceTypes from "../mandate/mandateServiceType/mandateServiceTypes";
import AddMandateServiceType from "../mandate/mandateServiceType/addMandateServiceType";
import BVNGreyList from "../mandate/bvnGreyList/bvnGreyList";
import AddBvnGreyList from "./../mandate/bvnGreyList/addBvnGreyList";
import ViewLinkedAccounts from "../bvn/viewLinkedAccounts";
import AddInstitution from "../auth/institution/addInstitution";
import Institutions from "./../auth/institution/institutionList";
import AddLoanProduct from "./../creditAssessment/loanProductManagement/addLoanProduct";
import LoanProducts from "./../creditAssessment/loanProductManagement/loanProducts";
import AddWorkFlow from "./../creditAssessment/workFlowManagement/addWorkFlow";
import WorkFlows from "./../creditAssessment/workFlowManagement/workFlows";
import AddCreditQualificationRule from "../creditAssessment/creditQualificationRuleManagement/addCreditQualificationRule";
import CreditQualificationRules from "../creditAssessment/creditQualificationRuleManagement/creditQualificationRules";
import LoanRequests from "./../creditAssessment/loanRequestManagement/loanRequests";
import LoanRequestsOCM from "./../creditAssessment/loanRequestManagement/loanRequestsOCM";
import CentralFeeConfigurations from "./../auth/billingConfig/centralFeeConfigurations";
import AddThirdPartyCentralFeeConfiguration from "./../auth/billingConfig/addThirdPartyCentralFeeConfiguration";
import AddPaymentConfiguration from "./../auth/billingConfig/addPaymentConfiguration";
import PaymentConfigurations from "./../auth/billingConfig/paymentConfigurations";
import AddThirdPartyAccountConfiguration from "./../auth/billingConfig/addThirdPartyAccountConfiguration";
import ThirdPartyAccountConfigurations from "./../auth/billingConfig/thirdPartyAccountConfigurations";
import MandateCategoryFeeConfigurations from "../auth/billingConfig/mandateFeeConfigurations";
import InstitutionCentralSweepConfigurations from "./../auth/sweepConfig/institutionSweepConfigurations";
import Branches from "./../auth/branch/branchList";
import AddBranch from "../auth/branch/addBranch";
import Users from "./../auth/user/userList";
import AddUser from "./../auth/user/addUser";
import UserRoles from "../auth/userRole/userRoleList";
import AddUserRole from "./../auth/userRole/addUserRole";
import AddUserRoleFunction from "./../auth/userRole/addUserRoleFunction";
import AuditTrails from "./../auditTrail/auditTrails";
import ActivityTrail from "./../auditTrail/activityTrail";
import ApprovalConfig from "./../approval/approvalConfig";
import Approvals from "./../approval/approvalList";
import ApprovalRequests from "../approval/approvalRequestList";
import SystemLoanProducts from "../auth/loanProduct/systemLoanProducts";
import BvnReport from "../reports/bvnReports/bvnReport";
import LinkBvn from "./../bvn/linkBvn";
import InitiateSweep from "./../sweep/initiateSweep";
import InstitutionSetup from "./../creditAssessment/institutionManagement/institutionSetup";
import AddConfiguration from "../creditAssessment/configurationsManagement/addConfiguration";
import InstitutionSetups from "./../creditAssessment/institutionManagement/institutionSetupList";
import DisbursementRequests from "./../creditAssessment/loanRequestManagement/disbursementRequests";
import AddMailingList from "../auth/mailingList/addMailingList";
import AddOcmInstitution from "../creditAssessment/OCM/InstitutionManagement/createOcmInstitution";
import OcmInstitutions from "../creditAssessment/OCM/InstitutionManagement/ocmInstitutions";
import AddCollectionsInstitution from "../collection/collectionsInstitution/createCollectionsInstitution";
import AddMessageTemplate from "../creditAssessment/messageTemplate/addMessageTemplate";
import MessageTemplates from "../creditAssessment/messageTemplate/messageTemplates";
import AddScoreIndicator from "../creditAssessment/scoreManagement/addScoreIndicator";
import BVNGreyListAppzone from "../mandate/bvnGreyList/bvnGreyListAppzone";
import SuccessfulIncomingReversalsReport from "./../reports/successfulIncomingReversalsReport";
import SuccessfulOutgoingReversalsReport from "../reports/successfulOutgoingReversalsReport";
import AddServiceOfferingConfig from "./../serviceOffering/addServiceOfferingConfig";
import ScoreIndicators from "../creditAssessment/scoreManagement/scoreIndicators";
import AssignScoreIndicator from "../creditAssessment/scoreManagement/assignScoreIndicators";
import AddScoreCard from "../creditAssessment/scoreManagement/addScoreCard";
import AddScoreMetrics from "../creditAssessment/scoreManagement/addScoreMetrics";
import ScoreCards from "../creditAssessment/scoreManagement/scoreCards";
import ConfigureDefaultModule from "./../serviceOffering/configureDefaultModules";
import ServiceOfferingConfigList from "../serviceOffering/serviceOfferingConfigList";
import CollectionConsentConfig from "../mandate/collections/collectionConsentConfig";
import ConfigureCollectionMessage from "../mandate/collections/configureCollectionConsentMessage";
import AddCollectionConsentConfig from "../mandate/collections/addCollectionConsentConfig";
import CollectionInstitutions from "../collection/collectionsInstitution/collectionInstitutions";
import CollectionSettings from "../collection/collectionsSetting/collectionSettings";
import ThirdPartyBillingTransactionsReport from "../reports/thirdPartyBillingTransactionsReport";
import AppzoneFeePayableReport from "../reports/appzoneFeePayableReport";
import AppzoneFeePaymentReport from "../reports/appzoneFeePaymentReport";
import InstitutionFeePayableReport from "../reports/institutionFeePayableReport";
import InstitutionFeePaymentReport from "../reports/institutionFeePaymentReport";
import CollectionSchedules from "../collection/collectionSchedule/collectionSchedules";
import OldCollectionSchedules from "../collection/collectionSchedule/oldCollectionSchedules";
import InstitutionCollectionSetting from "../collection/collectionsSetting/institutionCollectionSetting";
import AddCreditAssessmentFeeConfig from "../creditAssessment/feeConfiguration/addCreditAssessmentFeeConfiguration";
import CreditAssessmentFeeConfig from "../creditAssessment/feeConfiguration/creditAssessmentFeeConfigurations";
import AddOcmCategoryFeeConfig from "../creditAssessment/OCM/feeConfiguration/addOCMFeeConfiguration";
import OcmCategoryFeeConfig from "../creditAssessment/OCM/feeConfiguration/ocmFeeConfigurations";
import MandateFeeConfigurations from "../mandate/feeConfiguration/mandateFeeConfigurations";
import MandateBillingStatus from "../mandate/feeConfiguration/mandateBillingStatus";
import SweepPlans from "../sweep/sweepPlans/sweepPlans";
import OldSweepPlans from "../sweep/sweepPlans/oldSweepPlans";
import ModifyGeneralSweepSettings from "../auth/sweepConfig/modifyGeneralSweepSettings";
import PauseCollectionSchedule from "../collection/collectionSchedule/pauseCollectionSchedule";
import ResumeCollectionSchedule from "../collection/collectionSchedule/resumeCollectionSchedule";
import EditInstitutionConfig from "../creditAssessment/OCM/ConfigurationManagement/editInstitutionConfig";
import UploadInstitutionLogo from "../auth/institution/uploadInstitutionLogo";
import SettlementRequestsReport from "./../reports/settlementRequestsReport";
import LinkingReport from "../reports/linkingReports/linkingReport";
import ViewCustomerDetails from "../bvn/viewCustomerDetails";
import AddAppraisalPlan from "../hybridAppraisal/appraisalPlan/addAppraisalPlan";
import ViewAppraisalPlan from "../hybridAppraisal/appraisalPlan/appraisalPlans";
import ViewAppraisalInstituition from "../hybridAppraisal/appraisalInstitution/appraisalInstitutions";
import AddProductConfiguration from "./../hybridAppraisal/productConfiguration/addProductConfiguration";
import ViewProductConfigurations from "./../hybridAppraisal/productConfiguration/productConfigurations";
import AddOfferLetter from "../hybridAppraisal/OfferLetter/addOfferLetter";
import ViewOfferLetter from "../hybridAppraisal/OfferLetter/viewOfferLetter";
import AddFinancialGroup from "./../hybridAppraisal/financialGroup/addFinancialGroup";
import ViewFinancialGroups from "./../hybridAppraisal/financialGroup/financialGroups";
import MapLoanProduct from "./../hybridAppraisal/loanProductMap/addLoanProductMap";
import AddHybridAppraisalSubConfig from "../auth/billingConfig/addHybridAppraisalSubConfig";
import AddHybridAppraisalTranConfig from "../auth/billingConfig/addHybridAppraisalTranConfig";
import HybridAppraisalFeeConfiguration from "../auth/billingConfig/HybridAppraisalFeeConfig";
import HybridAppraisalTranFeeConfiguration from "../auth/billingConfig/HybridAppraisalTranFeeConfig";
import ViewLoanProductMaps from "./../hybridAppraisal/loanProductMap/loanProductMaps";
// import ViewLoanRequests from './../hybridAppraisal/loanRequest/loanRequests';
import WalletConfigurations from "./../auth/walletConfig/institutionWalletConfigurations";
import AssignCreditPolicyParameter from "../hybridAppraisal/creditPolicy/assignCreditPolicyParameters";
import CommissionsReport from "./../reports/commissionsReport";
import SettlementReportLender from "./../reports/settlementReportLender";
import SettlementReportAppzone from "./../reports/settlementReportAppzone";
import CommissionsReportAppzone from "../reports/commissionsReportAppzone";
import MandateCreationCharges from "../mandate/mandate/mandatesCreationCharges";
import ViewLoanRequests from "./../hybridAppraisal/loanAppraisal/loanRequests";
import ViewRejectedLoanRequests from "./../hybridAppraisal/loanAppraisal/rejectedLoanRequests";
import FundsTransferRequests from "./../reports/fundsTransferRequests";
import CollectionReport from "./../reports/collectionReport";
import CollectionScheduleReport from "./../reports/collectionScheduleReport";
import CollectionSummaryReport from "./../reports/collectionSummaryReport";
import RecoveryReport from "./../reports/recoveryReport";
import RecoverySummaryReport from "./../reports/recoverySummaryReport";
import SweepActivities from "../sweep/sweepActivities";
import PayoutReport from './../reports/payoutReport';
import feePayment from "../hybridAppraisal/feePayment/feePayment";
import recoveryMandates from "../mandate/mandate/recoveryMandates";
import collectionMandates from "../mandate/mandate/collectionMandates";
import mandatefeePayment from "../mandate/feeConfiguration/mandateFeePayment";
import SettlementPayoutReport from "../reports/settlementPayoutReport";
import AddWorkFlowConfigNew from "../hybridAppraisal/WorkflowConfiguration/addWorkFlowConfig";
import ViewGroupLoanRequestsNew from "../hybridAppraisal/groupLending/groupLoanRequests";
import AddLoanClassification from "../hybridAppraisal/loanClassification/addLoanClassification";
import ViewLoanClassification from "../hybridAppraisal/loanClassification/loanClassification";
import AppzoneSettlementAndPaymentReport from "../reports/appzoneSettlementAndPayoutReport";
import InstitutionSettlementAndPaymentReport from "../reports/institutionSettlementAndPayoutReport";
import LASLoanRequestReport from "../reports/lasLoanRequestReport";
import LASAppraisalFailureReport from "../reports/lasAppraisalFailureReport";
import LASDisbursedLoanReport from "../reports/lasDisbursedLoanReport";
import CollectionsReportNew from "../reports/collectionsReport";

import {
  GetAllInstitutions,
  RetrieveAllBank,
  RetrieveAllFee,
  RetrieveByCbnCodeBank,
  RetrieveFeeByID,
  RetrieveFeeByInstitutionCode,
  RetrieveFeeByInstitutionId,
  RetrieveBySortCodeBank,
  RetrieveSettings,
  RetrieveInstitutionById,
  RetrieveInstitutionByCode,
  RetrieveInstitutionByCBNCode,
  RepushLoan,
  ValidateLoan,
  GetMandateInfo,
  GetSecondaryMandateInfo,
  RetrieveByInstitutionCode,
  TestMono,
  RetrieveMonoWebhookAll,
  RetrieveMonoWebhookByID,
  RetrieveMonoWebhookByICode,
  TestOkra,
  RetrieveOkraWebhookAll,
  RetrieveOkraWebhookByID,
  RetrieveOkraWebhookByICode,
} from "../_recovaReports/sweep-reports";

import {
  CollectionScheduleReport2,
  CollectionsReport,
  CollectionSummary,
  RecoveryReport2,
} from "../_recovaReports/reports-centre";
import ViewGroupLoanRequests from "../hybridAppraisal/groupLending/groupLoanRequests";
import AddWorkFlowConfig from "../hybridAppraisal/WorkflowConfiguration/addWorkFlowConfig";
import ViewWorkFlowConfig from "../hybridAppraisal/WorkflowConfiguration/workFlowConfigs";
import DropDownConfig from "../hybridAppraisal/WorkflowConfiguration/dropDownConfig";
import SupplierDetailsConfig from "../hybridAppraisal/WorkflowConfiguration/manageEmployerList";
import EmployerListConfig from "../hybridAppraisal/WorkflowConfiguration/manageEmployerList";
import NewViewLoanRequests from "../hybridAppraisal/loanAppraisal/newLoanRequest";
import SupplierDetail from "../hybridAppraisal/WorkflowConfiguration/supplierDetails";
import AddSupplierDetails from "../hybridAppraisal/WorkflowConfiguration/addSupplierDetails";
import Suppliers from "../hybridAppraisal/WorkflowConfiguration/suppliers";
import FailedNIPReport from "../reports/failedNIPReport";
import AddCreditQualificationRuleAppzone from "../creditAssessment/creditQualificationRuleManagement/creditQualificationRuleComponents/appzoneAddCQR";
import ViewAllLoanRequests from "../hybridAppraisal/loanAppraisal/viewAllLoanRequests";
import AddSingleSupplierDetails from "../hybridAppraisal/WorkflowConfiguration/addSingleSupplierDetails";
import AddSingleEmployer from "../hybridAppraisal/WorkflowConfiguration/addSingleEmployer";
import NewGroupLoanRequests from "../hybridAppraisal/groupLending/newGroupLoanRequest";
import ViewAllGroupLoanRequests from "../hybridAppraisal/groupLending/viewAllGroupLoanRequests";
import EmployerList from "../hybridAppraisal/WorkflowConfiguration/employerList";
import AddLoanCategory from "../hybridAppraisal/loanCategory/addLoanCategory";
import ViewLoanCategory from "../hybridAppraisal/loanCategory/loanCategory";
import LASGroupDisbursementReport from "../hybridAppraisal/report/GroupDisbursementReport";
import SettlementWithdrawalReport from "../reports/settlementWithdrawalReport";
import WalletTransactions from "../reports/settlementCredit";
import WalletTransactionsAppzone from "../reports/settlementCreditAppzone";
import AppzoneCollectionsReport from "../reports/appzoneCollectionsReport";
import Regions from "../auth/region/regionList";
import AddRegion from "../auth/region/addRegion";

const REPORT_ROLES = [
  "inflowrepayments",
  "outflowrepayments",
  "failedrepayments",
  "loanupdatetransactions",
  "settlementreport",
  "commissionsreport",
  "sweepepisodes",
  "addmandateservicetypes",
  "viewpaymentconfiguration",
  "appzonefeepayable",
  "appzonefeepayment",
  "appzonesettlementsreport",
  "appzonecommissionsreport",
  "appzonefeepayment",
  "remotesession",
];

const REPORT_ROLES_Restricted = [
  "viewinstitutions",
];

interface ISideNavContentItemChild {
  name: string;
  url: string;
  icon: string;
  roleFunctions: string[];
  component: any;
}
interface ISideNavContentItem {
  title: boolean;
  name: string;
  url: string;
  icon: string;
  roleFunctions: string[];
  children?: ISideNavContentItemChild[];
  component?: any;
}
interface ISideNavContentServiceOfferings {
  displayName: boolean;
  name: string;
  icon: string;
  roleFunctions: string[];
  children: ISideNavContentItem[];
}
interface ISideNavContent {
  items: ISideNavContentServiceOfferings[];
}

export function retrieveSideNavContent(): ISideNavContent {
  let content: ISideNavContent = {
    items: [
      {
        displayName: true,
        name: "RECOVA REPORTS",
        icon: "icon-folders2",
        roleFunctions: REPORT_ROLES_Restricted,
        children: [
          {
            title: true,
            name: "RECOVA REPORTS",
            url: "",
            icon: "",
            roleFunctions: REPORT_ROLES_Restricted,
          },
          {
            title: false,
            name: "Reports Centre",
            url: "#",
            icon: "icon-folders2",
            roleFunctions: REPORT_ROLES_Restricted,
            children: [
              {
                name: "Collection Schedule",
                url: "/home/collection-schedule-report",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: CollectionScheduleReport2,
              },
              {
                name: "Collections",
                url: "/home/collections-report",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: CollectionsReport,
              },
              {
                name: "Collection Summary",
                url: "/home/collection-summary-report",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: CollectionSummary,
              },
              {
                name: "Recovery",
                url: "/home/recovery-report",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: RecoveryReport2,
              },
            ],
          },

          {
            title: false,
            name: "Sweep Commercial Banks",
            url: "#",
            icon: "icon-folders2",
            roleFunctions: REPORT_ROLES_Restricted,
            children: [
              {
                name: "Retrieve All",
                url: "/home/sweep/bank/retrieve-all",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: RetrieveAllBank,
              },
              {
                name: "Retrieve By CBN Code",
                url: "/home/sweep/bank/retrieve-by-cbn-code",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: RetrieveByCbnCodeBank,
              },
              {
                name: "Retrieve By Sort Code",
                url: "/home/sweep/bank/retrieve-by-sort-code",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: RetrieveBySortCodeBank,
              },
            ],
          },

          {
            title: false,
            name: "Sweep Fee",
            url: "#",
            icon: "icon-folders2",
            roleFunctions: REPORT_ROLES_Restricted,
            children: [
              {
                name: "Retrieve All",
                url: "/home/sweep/fee/retrieve-all",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: RetrieveAllFee,
              },
              {
                name: "Retrieve By ID",
                url: "/home/sweep/fee/retrieve-by-id",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: RetrieveFeeByID,
              },
              {
                name: "Retrieve By Institution Code",
                url: "/home/sweep/fee/retrieve-inst-code",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: RetrieveFeeByInstitutionCode,
              },
              {
                name: "Retrieve By Institution ID",
                url: "/home/sweep/fee/retrieve-inst-id",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: RetrieveFeeByInstitutionId,
              },
            ],
          },

          {
            title: false,
            name: "Sweep General Settings",
            url: "#",
            icon: "icon-folders2",
            roleFunctions: REPORT_ROLES_Restricted,
            children: [
              {
                name: "Retrieve General Settings",
                url: "/home/sweep/general-settings/retrieve",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: RetrieveSettings,
              },
            ],
          },

          {
            title: false,
            name: "Sweep Institutions",
            url: "#",
            icon: "icon-folders2",
            roleFunctions: REPORT_ROLES_Restricted,
            children: [
              {
                name: "Get All",
                url: "/home/sweep/institutions/retrieve",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: GetAllInstitutions,
              },
              {
                name: "Retrieve By ID",
                url: "/home/sweep/institutions/retrieve-by-id",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: RetrieveInstitutionById,
              },
              {
                name: "Retrieve By Bank Code",
                url: "/home/sweep/institutions/retrieve-by-code",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: RetrieveInstitutionByCode,
              },
              {
                name: "Retrieve By CBN Code",
                url: "/home/sweep/institutions/retrieve-by-cbn-code",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: RetrieveInstitutionByCBNCode,
              },
            ],
          },

          {
            title: false,
            name: "Sweep Loan Updates",
            url: "#",
            icon: "icon-folders2",
            roleFunctions: REPORT_ROLES_Restricted,
            children: [
              {
                name: "Repush Loan Balance",
                url: "/home/sweep/loan/repush",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: RepushLoan,
              },
              {
                name: "Validate Balance",
                url: "/home/sweep/loan/validate",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: ValidateLoan,
              },
            ],
          },

          {
            title: false,
            name: "Sweep Mandates",
            url: "#",
            icon: "icon-folders2",
            roleFunctions: REPORT_ROLES_Restricted,
            children: [
              {
                name: "Get Mandate Info",
                url: "/home/sweep/mandate/get-info",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: GetMandateInfo,
              },
              {
                name: "Get Secondary Mandate Info",
                url: "/home/sweep/mandate/get-sec-mandate-info",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: GetSecondaryMandateInfo,
              },
              {
                name: "Retrieve By Institution Code",
                url: "/home/sweep/mandate/retrieve-by-inst-code",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: RetrieveByInstitutionCode,
              },
            ],
          },

          {
            title: false,
            name: "Mono Webhook",
            url: "#",
            icon: "icon-folders2",
            roleFunctions: REPORT_ROLES_Restricted,
            children: [
              {
                name: "Test Webhook",
                url: "/home/sweep/mono-webhook/test",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: TestMono,
              },
              {
                name: "Retrieve All",
                url: "/home/sweep/mono-webhook/retrieve-all",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: RetrieveMonoWebhookAll,
              },
              {
                name: "Retrieve By ID",
                url: "/home/sweep/mono-webhook/retrieve-by-id",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: RetrieveMonoWebhookByID,
              },
              {
                name: "Retrieve By Institution Code",
                url: "/home/sweep/mono-webhook/retrieve-by-inst-code",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: RetrieveMonoWebhookByICode,
              },
            ],
          },

          {
            title: false,
            name: "Okra Webhook",
            url: "#",
            icon: "icon-folders2",
            roleFunctions: REPORT_ROLES_Restricted,
            children: [
              {
                name: "Test Webhook",
                url: "/home/sweep/okra-webhook/test",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: TestOkra,
              },
              {
                name: "Retrieve All",
                url: "/home/sweep/okra-webhook/retrieve-all",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: RetrieveOkraWebhookAll,
              },
              {
                name: "Retrieve By ID",
                url: "/home/sweep/okra-webhook/retrieve-by-id",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: RetrieveOkraWebhookByID,
              },
              {
                name: "Retrieve By Institution Code",
                url: "/home/sweep/okra-webhook/retrieve-by-inst-code",
                icon: "icon-document-list",
                roleFunctions: REPORT_ROLES_Restricted,
                component: RetrieveOkraWebhookByICode,
              },
            ],
          },
        ],
      },
      {
        displayName: true,
        name: "RECOVA",
        icon: "icon-certificate",
        roleFunctions: [],
        children: [
          {
            title: true,
            name: "MAIN",
            url: "",
            icon: "",
            roleFunctions: [],
          },
          {
            title: false,
            name: "BVN Management",
            url: "#",
            icon: "icon-certificate2",
            roleFunctions: [
              "addtogreylist",
              "viewlinkedaccounts",
              "linkbvn",
              "remotesession",
            ],
            children: [
              {
                name: "View Grey-List",
                url: "/home/bvngreylist",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewlinkedaccounts", "remotesession"],
                component: BVNGreyList,
              },
              {
                name: "View Grey-List",
                url: "/home/bvngreylist",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["linkbvn"],
                component: BVNGreyListAppzone,
              },
              {
                name: "Add To Grey-List",
                url: "/home/addbvntogreylist",
                icon: "icon-add green-text",
                roleFunctions: ["addtogreylist"],
                component: AddBvnGreyList,
              },
              {
                name: "View Customer Details",
                url: "/home/viewCustomerDetails",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["adddisputecondition"],
                component: ViewCustomerDetails,
              },
              {
                name: "View Linked Accounts",
                url: "/home/viewLinkedAccounts",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewlinkedaccounts", "remotesession"],
                component: ViewLinkedAccounts,
              },
              // {
              //   name: "Link BVN",
              //   url: "/home/linkBvn",
              //   icon: "icon-add green-text",
              //   roleFunctions: ["linkbvn"],
              //   component: LinkBvn,
              // },
              // {
              //   name: "View Grey-Listed BVNs",
              //   url: "/home/individualbvngreylist",
              //   icon: "icon-document-graph green-text",
              //   roleFunctions: ["viewlinkedaccounts", "remotesession"],
              //   component: IndividualBVNGreyListReport,
              // },

            ],
          },
          {
            title: false,
            name: "Dispute",
            url: "#",
            icon: "icon-stop-watch2",
            roleFunctions: [
              "logdispute",
              "mydisputecases",
              "otherinstitutionscases",
              "viewunresolveddisputes",
              "remotesession",
            ],
            children: [
              {
                name: "Log Dispute",
                url: "/home/logdispute",
                icon: "icon-add green-text",
                roleFunctions: ["logdispute"],
                component: LogDispute,
              },
              {
                name: "My Cases",
                url: "/home/mydisputecases",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["mydisputecases", "remotesession"],
                component: MyDisputes,
              },
              {
                name: "Other Institutions' Cases",
                url: "/home/otherinstitutionsdisputecases",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["otherinstitutionscases", "remotesession"],
                component: OtherInstitutionsDisputes,
              },
              {
                name: "Unresolved Cases",
                url: "/home/unresolveddisputes",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewunresolveddisputes"],
                component: UnResolvedDisputes,
              },
            ],
          },
          {
            title: false,
            name: "Dispute Condition",
            url: "#",
            icon: "icon-fire",
            roleFunctions: [
              "viewdisputeconditions",
              "adddisputecondition",
              "remotesession",
            ],
            children: [
              {
                name: "Dispute Conditions",
                url: "/home/disputeconditions",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewdisputeconditions", "remotesession"],
                component: DisputeConditions,
              },
              {
                name: "Add Dispute Condition",
                url: "/home/adddisputecondition",
                icon: "icon-add green-text",
                roleFunctions: ["adddisputecondition"],
                component: AddDisputeCondition,
              },
            ],
          },
          {
            title: false,
            name: "Mandate",
            url: "#",
            icon: "icon-certificate",
            roleFunctions: [
              "viewconsentrequests",
              "viewmandates",
              "uploadmandates",
              "viewbulkmandaterequests",
              "viewmandateservicetypes",
              "addmandateservicetypes",
              "remotesession",
            ],
            children: [
              {
                name: "Consent Requests",
                url: "/home/consentrequests",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewconsentrequests", "remotesession"],
                component: ConsentRequests,
              },
              {
                name: "Mandates",
                url: "/home/mandates",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewmandates", "remotesession"],
                component: Mandates,
              },
              {
                name: "Collection Mandates",
                url: "/home/collectionmandates",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewmandates", "remotesession"],
                component: collectionMandates,
              },
              {
                name: "Recovery Mandates",
                url: "/home/recoverymandates",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewmandates", "remotesession"],
                component: recoveryMandates,
              },
              {
                name: "Mandate Fee Configuration",
                url: "/home/mandatefeeconfiguration",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewpaymentconfiguration"],
                component: MandateFeeConfigurations,
              },
              {
                name: "Mandate Fee Payment",
                url: "/home/mandatefeePayment",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewmandates", "remotesession"],
                component: mandatefeePayment,
              },
              {
                name: "Mandate Billing Status",
                url: "/home/mandatebillingstatus",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewpaymentconfiguration"],
                component: MandateBillingStatus,
              },
              {
                name: "Upload Mandates",
                url: "/home/uploadmandates",
                icon: "icon-add green-text",
                roleFunctions: ["viewmandateservicetypes"],
                component: UploadMandates,
              },
              {
                name: "Bulk Mandate Requests",
                url: "/home/bulkmandaterequests",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewbulkmandaterequests", "remotesession"],
                component: MandateUploadRequests,
              },
              {
                name: "Mandate Creation Charges",
                url: "/home/mandatecreationcharges",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewmandates", "remotesession"],
                component: MandateCreationCharges,
              },
              {
                name: "Mandate Service Types",
                url: "/home/mandateservicetypes",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewmandateservicetypes", "remotesession"],
                component: MandateServiceTypes,
              },
              {
                name: "Add Mandate Service Types",
                url: "/home/addmandateservicetype",
                icon: "icon-add green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: AddMandateServiceType,
              },
              {
                name: "Initiate Sweep",
                url: "/home/initiatesweep",
                icon: "icon-add green-text",
                roleFunctions: ["viewmandateservicetypes"],
                component: InitiateSweep,
              },
            ],
          },
          {
            title: false,
            name: "Settlement Wallet",
            url: "#",
            icon: "icon-fire",
            roleFunctions: [
              "addmandateservicetypes",
              "remotesession",
              "viewmandates"
            ],
            children: [
              {
                name: "Settlements",
                url: "/home/settlementWithdrawalHistoryAppzone",
                icon: "icon-document-graph green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: WalletTransactionsAppzone,
              },
              {
                name: "Withdrawal History",
                url: "/home/settlementWithdrawalHistory",
                icon: "icon-document-graph green-text",
                roleFunctions: ["viewmandates", "remotesession"],
                component: SettlementWithdrawalReport,
              },
              {
                name: "Settlements",
                url: "/home/settlementTransactions",
                icon: "icon-document-graph green-text",
                roleFunctions: ["viewmandates", "remotesession"],
                component: WalletTransactions,
              }
            ],
          },
          {
            title: false,
            name: "Reversal Reports",
            url: "#",
            icon: "icon-folders",
            roleFunctions: [
              "inflowrepayments",
              "outflowrepayments",
              "remotesession",
            ],
            children: [
              {
                name: "Inflow Reversals",
                url: "/home/inflowreversals",
                icon: "icon-rotate-left red-text",
                roleFunctions: ["inflowrepayments", "remotesession"],
                component: SuccessfulIncomingReversalsReport,
              },
              {
                name: "Outflow Reversals",
                url: "/home/outflowreversals",
                icon: "icon-rotate-left red-text",
                roleFunctions: ["outflowrepayments", "remotesession"],
                component: SuccessfulOutgoingReversalsReport,
              },
            ],
          },
          {
            title: false,
            name: "Reports",
            url: "#",
            icon: "icon-folders2",
            roleFunctions: [
              "inflowrepayments",
              "outflowrepayments",
              "failedrepayments",
              "loanupdatetransactions",
              "settlementreport",
              "commissionsreport",
              "collectionReports",
              "sweepepisodes",
              "addmandateservicetypes",
              "viewpaymentconfiguration",
              "appzonefeepayable",
              "appzonefeepayment",
              "appzonesettlementsreport",
              "appzonecommissionsreport",
              "appzonefeepayment",
              "failednips",
              "remotesession",
            ],
            children: [
              {
                name: "Appzone Fee Payable",
                url: "/home/feepayablereport",
                icon: "icon-document-graph green-text",
                roleFunctions: ["viewpaymentconfiguration"],
                component: AppzoneFeePayableReport,
              },
              {
                name: "Appzone Fee Payment",
                url: "/home/feepaymentreport",
                icon: "icon-document-graph green-text",
                roleFunctions: ["viewpaymentconfiguration"],
                component: AppzoneFeePaymentReport,
              },
              {
                name: "Appzone Fee Payable",
                url: "/home/appzonefeepayablereport",
                icon: "icon-document-graph green-text",
                roleFunctions: ["appzonefeepayable", "remotesession"],
                component: InstitutionFeePayableReport,
              },

              {
                name: "Appzone Fee Payment",
                url: "/home/appzonefeepaymentreport",
                icon: "icon-document-graph green-text",
                roleFunctions: ["appzonefeepayment", "remotesession"],
                component: InstitutionFeePaymentReport,
              },
              {
                name: "Inflow Recoveries",
                url: "/home/inflowrepayments",
                icon: "icon-document-download green-text",
                roleFunctions: ["inflowrepayments", "remotesession"],
                component: SuccessfulIncomingRepaymentsReport,
              },
              {
                name: "Outflow Recoveries",
                url: "/home/outflowrepayments",
                icon: "icon-document-upload orange-text",
                roleFunctions: ["outflowrepayments", "remotesession"],
                component: SuccessfulOutgoingRepaymentsReport,
              },
              {
                name: "Failed Inflow Recoveries",
                url: "/home/failedrepayment",
                icon: "icon-document-error red-text",
                roleFunctions: ["failedrepayments", "remotesession"],
                component: FailedRepaymentReport,
              },
              {
                name: "Collection Inflow Report",
                url: "/home/collectionsReport",
                icon: "icon-document-download green-text",
                roleFunctions: ["inflowrepayments", "remotesession"],
                component: CollectionsReportNew,
              },
              {
                name: "Appzone Collection Inflow Report",
                url: "/home/appzoneCollectionsReport",
                icon: "icon-document-download green-text",
                roleFunctions: ["viewpaymentconfiguration",],
                component: AppzoneCollectionsReport,
              },
              {
                name: "Failed Inflow Repayments",
                url: "/home/failedrepayment",
                icon: "icon-document-error red-text",
                roleFunctions: ["failedrepayments", "remotesession"],
                component: FailedRepaymentReport,
              },
              {
                name: "Outflow Repayments",
                url: "/home/outflowrepayments",
                icon: "icon-document-upload orange-text",
                roleFunctions: ["outflowrepayments", "remotesession"],
                component: SuccessfulOutgoingRepaymentsReport,
              },
              {
                name: "Loan Update Transactions",
                url: "/home/loanupdatetransactions",
                icon: "icon-document-checked green-text",
                roleFunctions: ["loanupdatetransactions", "remotesession"],
                component: LoanUpdateTransactionsReport,
              },
              // {
              //   name: "Internal Settlement Report",
              //   url: "/home/appzonesettlementreport",
              //   icon: "icon-document-list green-text",
              //   roleFunctions: ["remotesession"],
              //   component: AppzoneSettlementReport,
              // },
              // {
              //   name: "Settlement Report",
              //   url: "/home/settlementReport",
              //   icon: "icon-document-list green-text",
              //   roleFunctions: ["settlementreport", "remotesession"],
              //   component: SettlementReport,
              // },
              {
                name: "Commissions Report",
                url: "/home/commissionsreport",
                icon: "icon-document-list green-text",
                roleFunctions: ["commissionsreport", "remotesession"],
                component: CommissionsReport,
              },
              {
                name: "Appzone Settlement Report",
                url: "/home/appzonesettlementreport",
                icon: "icon-document-list green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: SettlementReportAppzone,
              },
              {
                name: "Appzone Commissions Report",
                url: "/home/commissionsreportappzone",
                icon: "icon-document-list green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: CommissionsReportAppzone,
              },
              {
                name: "Lender Sponsor Settlement Report",
                url: "/home/lendersettlementreport",
                icon: "icon-document-list green-text",
                roleFunctions: ["commissionsreport", "remotesession"],
                component: SettlementReportLender,
              },
              {
                name: "Sweep Episodes",
                url: "/home/sweepepisodes",
                icon: "icon-document-graph green-text",
                roleFunctions: ["sweepepisodes", "remotesession"],
                component: SweepEpisode,
              },
              {
                name: "Settlement & Payout Report",
                url: "/home/ofisettlementspayoutsreport",
                icon: "icon-document-graph green-text",
                roleFunctions: ["settlementreport", "remotesession"],
                component: InstitutionSettlementAndPaymentReport,
              },
              {
                name: "Mandates",
                url: "/home/mandates",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["addmandateservicetypes"],
                component: Mandates,
              },
              {
                name: "Collection Mandates",
                url: "/home/collectionmandates",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["addmandateservicetypes"],
                component: collectionMandates,
              },
              {
                name: "Recovery Mandates",
                url: "/home/recoverymandates",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["addmandateservicetypes"],
                component: recoveryMandates,
              },
              {
                name: "Active Mandates",
                url: "/home/activeMandates",
                icon: "icon-document-graph green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: ActiveMandatesReport,
              },
              {
                name: "Cancelled Mandates",
                url: "/home/cancelledMandates",
                icon: "icon-document-graph green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: CancelledMandatesReport,
              },
              {
                name: "Recovery Mode Mandates",
                url: "/home/recoveryModeMandates",
                icon: "icon-document-graph green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: RecoveryModeMandatesReport,
              },
              {
                name: "BVN Report",
                url: "/home/bvnreport",
                icon: "icon-document-graph green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: BvnReport,
              },
              {
                name: "Linking Report",
                url: "/home/linkingreport",
                icon: "icon-document-graph green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: LinkingReport,
              },
              {
                name: "Funds Transfer Requests",
                url: "/home/fundstransferrequests",
                icon: "icon-document-graph green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: FundsTransferRequests,
              },
              {
                name: "Sweep Activities",
                url: "/home/sweepActivities",
                icon: "icon-document-graph green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: SweepActivities,
              },
              {
                name: "Third-Party Transactions Report",
                url: "/home/ThirdPartyBillingTransactionsReport",
                icon: "icon-document-graph green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: ThirdPartyBillingTransactionsReport,
              },
              {
                name: "Settlement Requests Report",
                url: "/home/SettlementRequestsReport",
                icon: "icon-document-graph green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: SettlementRequestsReport,
              },
              {
                name: "Collection Report",
                url: "/home/collectionreport",
                icon: "icon-document-graph green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: CollectionReport,
              },
              {
                name: "Collection Schedule Report",
                url: "/home/collectionschedulereport",
                icon: "icon-document-graph green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: CollectionScheduleReport,
              },
              {
                name: "Collection Summary Report",
                url: "/home/collectionsummaryreport",
                icon: "icon-document-graph green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: CollectionSummaryReport,
              },
              {
                name: "Recovery Report",
                url: "/home/recoveryreport",
                icon: "icon-document-graph green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: RecoveryReport,
              },
              {
                name: "Recovery Summary Report",
                url: "/home/recoverysummaryreport",
                icon: "icon-document-graph green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: RecoverySummaryReport,
              },
              {
                name: "Payout Request Report",
                url: "/home/payoutrequestreport",
                icon: "icon-document-graph green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: PayoutReport,
              },
              {
                name: "Pre-Settlements Report",
                url: "/home/settlementspayoutsreport",
                icon: "icon-document-graph green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: SettlementsAndPayoutsReport,
              },
              {
                name: "Failed NIP Settlement Report",
                url: "/home/failedNIPSettlement",
                icon: "icon-document-graph green-text",
                roleFunctions: ["addmandateservicetypes", "failednips"],
                component: FailedNIPReport,
              },
              {
                name: "Settlement & Payout Report",
                url: "/home/appzonesettlementspayoutsreport",
                icon: "icon-document-graph green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: AppzoneSettlementAndPaymentReport,
              },
              {
                name: "LAS Loan Request Report",
                url: "/home/lasloanrequestreport",
                icon: "icon-document-graph green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: LASLoanRequestReport,
              },
              {
                name: "LAS Appraisal Failure Report",
                url: "/home/lasappraisalfailurereport",
                icon: "icon-document-graph green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: LASAppraisalFailureReport,
              },
              {
                name: "LAS Disbursed Loan Report",
                url: "/home/lasdisbursedloanreport",
                icon: "icon-document-graph green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: LASDisbursedLoanReport,
              }
              // {
              //   name: "Institutions Grey-List",
              //   url: "/home/institutionsgreylist",
              //   icon: "icon-document-graph green-text",
              //   roleFunctions: ["sweepepisodes"],
              //   component: InstitutionsGreyListReport,
              // },
            ],
          },
          {
            title: false,
            name: "Sweep Plan",
            url: "#",
            icon: "icon-schedule",
            roleFunctions: ["viewdisputeconditions", "remotesession"],
            children: [
              {
                name: "Sweep Plans",
                url: "/home/sweepplans",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewdisputeconditions", "remotesession"],
                component: SweepPlans,
              },
              {
                name: "Old Sweep Plans",
                url: "/home/oldsweepplans",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewdisputeconditions", "remotesession"],
                component: OldSweepPlans,
              },
            ],
          },
          // {
          //   title: false,
          //   name: "Third Party Integration",
          //   url: "#",
          //   icon: "icon-settings",
          //   roleFunctions: ["viewdisputeconditions"],
          //   children: [
          //     {
          //       name: "Add Integration Information",
          //       url: "/home/addintegrationinfo",
          //       icon: "icon-add green-text",
          //       roleFunctions: ["viewdisputeconditions"],
          //       component: CreateIntegrationInfo,
          //     },
          //   ],
          // },
        ],
      },
      {
        displayName: true,
        name: "CREDIT APPRAISAL",
        icon: "icon-check_circle",
        roleFunctions: [],
        children: [
          {
            title: true,
            name: "CREDIT APPRAISAL",
            url: "",
            icon: "",
            roleFunctions: [
              "createinstitution",
              // "viewinstitution",
              "addconfiguration",
              "viewconfiguration",
              "addloanproduct",
              "viewloanproduct",
              "addworkflow",
              "viewworkflow",
              "addcreditqualificationrule",
              "viewcreditqualificationrule",
              "viewloanrequest",
              "viewloanrequests(ocm)",
              "viewscorecardindicator",
              "remotesession",
            ],
          },
          {
            title: false,
            name: "Configuration",
            url: "#",
            icon: "icon-build",
            roleFunctions: ["addconfiguration", "viewconfiguration"],
            children: [
              {
                name: "Edit Configuration",
                url: "/home/editConfiguration",
                icon: "icon-add green-text",
                roleFunctions: ["editconfiguration"],
                component: AddConfiguration,
              },
              // {
              //   name: "Configurations",
              //   url: "/home/configurations",
              //   icon: "icon-circle-o orange-text",
              //   roleFunctions: ["viewconfiguration"],
              //   component: Configurations,
              // },
            ],
          },
          {
            title: false,
            name: "Credit Qualification Rule",
            url: "#",
            icon: "icon-cubes",
            roleFunctions: [
              "addcreditqualificationrule",
              "viewcreditqualificationrule",
              "remotesession",
              "addappzonecreditqualificationrule",
              "viewappzonecreditqualificationrule"
            ],
            children: [
              {
                name: "Add Credit Qualification Rule",
                url: "/home/addcreditqualificationruleconfig",
                icon: "icon-add green-text",
                roleFunctions: ["addcreditqualificationrule"],
                component: AddCreditQualificationRule,
              },
              {
                name: "Credit Qualification Rules",
                url: "/home/creditqualificationrules",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewcreditqualificationrule", "remotesession"],
                component: CreditQualificationRules,
              },
              {
                name: "Add Credit Qualification Rule",
                url: "/home/addappzonecreditqualificationruleconfig",
                icon: "icon-add green-text",
                roleFunctions: ["addappzonecreditqualificationrule"],
                component: AddCreditQualificationRuleAppzone,
              },
              {
                name: "Credit Qualification Rules",
                url: "/home/creditqualificationrules",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewappzonecreditqualificationrule"],
                component: CreditQualificationRules,
              },
            ],
          },
          {
            title: false,
            name: "Fee Configuration",
            url: "#",
            icon: "icon-build",
            roleFunctions: ["createinstitution", "viewinstitution"],
            children: [
              {
                name: "Add Fee Configuration",
                url: "/home/addcreditassessmentfeeconfiguration",
                icon: "icon-add green-text",
                roleFunctions: ["createinstitution"],
                component: AddCreditAssessmentFeeConfig,
              },
              {
                name: "Fee Configurations",
                url: "/home/creditassessmentfeeconfigurations",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewinstitution"],
                component: CreditAssessmentFeeConfig,
              },
            ],
          },
          {
            title: false,
            name: "Institution Management",
            url: "#",
            icon: "icon-university",
            roleFunctions: ["createinstitution", "viewinstitution"],
            children: [
              {
                name: "Add Institution",
                url: "/home/addcreditassessmentinstitution",
                icon: "icon-add green-text",
                roleFunctions: ["createinstitution"],
                component: InstitutionSetup,
              },
              {
                name: "Institutions",
                url: "/home/creditassessmentinstitutions",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewinstitution"],
                component: InstitutionSetups,
              },
            ],
          },
          {
            title: false,
            name: "Loan Product",
            url: "#",
            icon: "icon-gg-circle",
            roleFunctions: [
              "addloanproduct",
              "viewloanproduct",
              "remotesession",
            ],
            children: [
              {
                name: "Add Loan Product",
                url: "/home/addLoanProduct",
                icon: "icon-add green-text",
                roleFunctions: ["addloanproduct"],
                component: AddLoanProduct,
              },
              {
                name: "Loan Products",
                url: "/home/loanProducts",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewloanproduct", "remotesession"],
                component: LoanProducts,
              },
            ],
          },
          {
            title: false,
            name: "Loan Requests",
            url: "#",
            icon: "icon-equalizer",
            roleFunctions: ["viewloanrequest", "remotesession"],
            children: [
              {
                name: "All Loan Requests",
                url: "/home/loanrequests",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewloanrequest", "remotesession"],
                component: LoanRequests,
              },
            ],
          },
          {
            title: false,
            name: "Message Template",
            url: "#",
            icon: "icon-message",
            roleFunctions: ["createinstitution"],
            children: [
              {
                name: "Add Message Template",
                url: "/home/addmessagetemplate",
                icon: "icon-add green-text",
                roleFunctions: ["createinstitution"],
                component: AddMessageTemplate,
              },
              {
                name: "Message Templates",
                url: "/home/messagetemplates",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["createinstitution"],
                component: MessageTemplates,
              },
            ],
          },
          {
            title: false,
            name: "Scoring Management",
            url: "#",
            icon: "icon-check_circle",
            roleFunctions: [
              "createinstitution",
              "viewloanrequest",
              "remotesession",
              "addscorecardappzone",
              "viewscorecardappzone"
            ],
            children: [
              {
                name: "Add Score Indicator",
                url: "/home/addscoreindicator",
                icon: "icon-add green-text",
                roleFunctions: ["createinstitution"],
                component: AddScoreIndicator,
              },
              {
                name: "Score Indicators",
                url: "/home/scoreindicators",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["createinstitution"],
                component: ScoreIndicators,
              },
              {
                name: "Assign Score Indicators",
                url: "/home/assignscoreindicators",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["createinstitution"],
                component: AssignScoreIndicator,
              },
              {
                name: "Add Score Card",
                url: "/home/addscorecard",
                icon: "icon-add green-text",
                roleFunctions: ["viewloanrequest", "remotesession", "addscorecardappzone"],
                component: AddScoreCard,
              },
              // {
              //   name: "Add Score Metrics",
              //   url: "/home/addscoremetrics",
              //   icon: "icon-add green-text",
              //   roleFunctions: ["viewloanrequest"],
              //   component: AddScoreMetrics,
              // },
              {
                name: "Score Cards",
                url: "/home/scorecards",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewloanrequest", "remotesession", "viewscorecardappzone"],
                component: ScoreCards,
              },
            ],
          },
          {
            title: false,
            name: "Work Flows",
            url: "#",
            icon: "icon-columns",
            roleFunctions: ["addworkflow", "viewworkflow", "remotesession"],
            children: [
              {
                name: "Add Work FLow",
                url: "/home/addworkflow",
                icon: "icon-add green-text",
                roleFunctions: ["addworkflow"],
                component: AddWorkFlow,
              },
              {
                name: "View Work Flows",
                url: "/home/workflows",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewworkflow", "remotesession"],
                component: WorkFlows,
              },
              // {
              //   name: "Add Work FLow State",
              //   url: "/home/addworkflowstate",
              //   icon: "icon-add green-text",
              //   roleFunctions: ["addworkflow"],
              //   component: AddWorkFlowState,
              // },
              // {
              //   name: "View Work Flow States",
              //   url: "/home/workflowstates",
              //   icon: "icon-circle-o orange-text",
              //   roleFunctions: ["viewworkflow"],
              //   component: WorkFlowStates,
              // },
              // {
              //   name: "View Work Flow Items",
              //   url: "/home/workflowitems",
              //   icon: "icon-circle-o orange-text",
              //   roleFunctions: ["viewworkflow"],
              //   component: WorkFlowItems,
              // },
            ],
          },
        ],
      },
      {
        displayName: true,
        name: "BLACKBOX(OCM)",
        icon: "icon-box6",
        roleFunctions: [],
        children: [
          {
            title: true,
            name: "OCM",
            url: "",
            icon: "",
            roleFunctions: [
              "addocminstitution",
              "viewocminstitution",
              "addcreditqualificationrule(ocm)",
              "viewcreditqualificationrule(ocm)",
              "viewloanrequest",
              "remotesession",
            ],
          },
          {
            title: false,
            name: "Credit Qualification Rule",
            url: "#",
            icon: "icon-cubes",
            roleFunctions: [
              "addcreditqualificationrule(ocm)",
              "viewcreditqualificationrule(ocm)",
            ],
            children: [
              {
                name: "Add Credit Qualification Rule",
                url: "/home/addcreditqualificationruleconfig",
                icon: "icon-add green-text",
                roleFunctions: ["addcreditqualificationrule(ocm)"],
                component: AddCreditQualificationRule,
              },
              {
                name: "Credit Qualification Rules",
                url: "/home/creditqualificationrules",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewcreditqualificationrule(ocm)"],
                component: CreditQualificationRules,
              },
            ],
          },
          {
            title: false,
            name: "Fee Configuration",
            url: "#",
            icon: "icon-build",
            roleFunctions: ["createinstitution"],
            children: [
              {
                name: "Category Fee Configuration",
                url: "/home/addocmcategoryfeeconfiguration",
                icon: "icon-add green-text",
                roleFunctions: ["createinstitution"],
                component: AddOcmCategoryFeeConfig,
              },
              {
                name: "Category Fee Configuration",
                url: "/home/ocmcategoryfeeconfiguration",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["createinstitution"],
                component: OcmCategoryFeeConfig,
              },
            ],
          },
          {
            title: false,
            name: "Institution Management",
            url: "#",
            icon: "icon-university",
            roleFunctions: ["addocminstitution", "viewocminstitution"],
            children: [
              {
                name: "Add Institution",
                url: "/home/addocminstitution",
                icon: "icon-add green-text",
                roleFunctions: ["addocminstitution"],
                component: AddOcmInstitution,
              },
              {
                name: "Institutions",
                url: "/home/ocminstitutions",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewocminstitution"],
                component: OcmInstitutions,
              },
            ],
          },
          {
            title: false,
            name: "Loan Product",
            url: "#",
            icon: "icon-gg-circle",
            roleFunctions: ["addloanproduct(ocm)", "viewloanproduct(ocm)"],
            children: [
              {
                name: "Add Loan Product",
                url: "/home/addLoanProduct",
                icon: "icon-add green-text",
                roleFunctions: ["addloanproduct(ocm)"],
                component: AddLoanProduct,
              },
              {
                name: "Loan Products",
                url: "/home/loanProducts",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewloanproduct(ocm)"],
                component: LoanProducts,
              },
            ],
          },
          {
            title: false,
            name: "Loan Requests",
            url: "#",
            icon: "icon-equalizer",
            roleFunctions: [
              "viewloanrequests(ocm)",
              "viewloanrequest",
              "remotesession",
            ],
            children: [
              {
                name: "Loan Requests (OCM)",
                url: "/home/loanrequestsocm",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewloanrequests(ocm)"],
                component: LoanRequestsOCM,
              },
              {
                name: "Disbursement Requests",
                url: "/home/disbursementrequests",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewloanrequest", "remotesession"],
                component: DisbursementRequests,
              },
            ],
          },
          {
            title: false,
            name: "Mandate",
            url: "#",
            icon: "icon-certificate",
            roleFunctions: ["viewconsentrequests(ocm)", "viewmandates(ocm)"],
            children: [
              {
                name: "Consent Requests (OCM)",
                url: "/home/consentrequestsocm",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewconsentrequests(ocm)"],
                component: ConsentRequestsOCM,
              },
              {
                name: "Mandates (OCM)",
                url: "/home/mandatesocm",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewmandates(ocm)"],
                component: MandatesOCM,
              },
            ],
          },
          {
            title: false,
            name: "Reports",
            url: "#",
            icon: "icon-folders2",
            roleFunctions: ["viewocmdataprovider"],
            children: [
              {
                name: "Data Provider",
                url: "/home/viewocmdataprovider",
                icon: "icon-document-text green-text",
                roleFunctions: ["viewocmdataprovider"],
                component: DataProviderReport,
              },
            ],
          },
          {
            title: false,
            name: "Configuration",
            url: "#",
            icon: "icon-build",
            roleFunctions: ["addconfiguration", "viewconfiguration"],
            children: [
              {
                name: "Edit Configuration",
                url: "/home/editocminstitutionconfig",
                icon: "icon-add green-text",
                roleFunctions: ["addconfiguration"],
                component: EditInstitutionConfig,
              },
              // {
              //   name: "Configurations",
              //   url: "/home/configurations",
              //   icon: "icon-circle-o orange-text",
              //   roleFunctions: ["viewconfiguration"],
              //   component: Configurations,
              // },
            ],
          },
        ],
      },
      {
        displayName: true,
        name: "COLLECTIONS",
        icon: "icon-collections_bookmark",
        roleFunctions: [],
        children: [
          {
            title: true,
            name: "COLLECTION",
            url: "",
            icon: "",
            roleFunctions: [
              "addocminstitution",
              "viewocminstitution",
              "viewcollectionschedule",
              "viewcollectionsetting",
              "remotesession",
            ],
          },
          {
            title: false,
            name: "Collection Consent",
            url: "#",
            icon: "icon-collections_bookmark",
            roleFunctions: ["addmandateservicetypes"],
            children: [
              {
                name: "Add Collection Consent Config",
                url: "/home/addcollectionconsentconfig",
                icon: "icon-add green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: AddCollectionConsentConfig,
              },
              {
                name: "Collection Consent Config",
                url: "/home/collectionconsentconfig",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["addmandateservicetypes"],
                component: CollectionConsentConfig,
              },
              {
                name: "Configure Consent Message",
                url: "/home/collectionconsentmessageconfig",
                icon: "icon-all_inclusive green-text",
                roleFunctions: ["addmandateservicetypes"],
                component: ConfigureCollectionMessage,
              },
            ],
          },
          {
            title: false,
            name: "Collection Schedules",
            url: "#",
            icon: "icon-equalizer",
            roleFunctions: ["viewcollectionschedule", "remotesession"],
            children: [
              {
                name: "All Collection Schedules",
                url: "/home/collectionschedules",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewcollectionschedule", "remotesession"],
                component: CollectionSchedules,
              },
              {
                name: "Pause Collection Schedules",
                url: "/home/pausecollectionschedules",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewcollectionschedule"],
                component: PauseCollectionSchedule,
              },
              {
                name: "Resume Collection Schedules",
                url: "/home/resumecollectionschedules",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewcollectionschedule"],
                component: ResumeCollectionSchedule,
              },
              {
                name: "Old Collection Schedules",
                url: "/home/oldcollectionschedules",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewcollectionschedule", "remotesession"],
                component: OldCollectionSchedules,
              },
            ],
          },
          {
            title: false,
            name: "Collection Settings",
            url: "#",
            icon: "icon-settings",
            roleFunctions: ["addocminstitution", "viewocminstitution"],
            children: [
              // {
              //   name: "Add Institution",
              //   url: "/home/addcollectioninstitution",
              //   icon: "icon-add green-text",
              //   roleFunctions: ["addocminstitution"],
              //   component: AddCollectionsInstitution,
              // },
              {
                name: "Settings",
                url: "/home/collectionsettings",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewocminstitution"],
                component: CollectionSettings,
              },
            ],
          },
          {
            title: false,
            name: "Collection Settings",
            url: "#",
            icon: "icon-settings",
            roleFunctions: ["viewcollectionsetting"],
            children: [
              {
                name: "Settings",
                url: "/home/instcollectionsettings",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewcollectionsetting"],
                component: InstitutionCollectionSetting,
              },
            ],
          },
          {
            title: false,
            name: "Institution Management",
            url: "#",
            icon: "icon-university",
            roleFunctions: ["addocminstitution", "viewocminstitution"],
            children: [
              {
                name: "Add Institution",
                url: "/home/addcollectioninstitution",
                icon: "icon-add green-text",
                roleFunctions: ["addocminstitution"],
                component: AddCollectionsInstitution,
              },
              {
                name: "Institutions",
                url: "/home/collectioninstitutions",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewocminstitution"],
                component: CollectionInstitutions,
              },
            ],
          },
        ],
      },
      {
        displayName: true,
        name: "LENDING AUTOMATION",
        icon: "icon-domain",
        roleFunctions: [],
        children: [
          {
            title: true,
            name: "LENDING AUTOMATION",
            url: "",
            icon: "",
            roleFunctions: [
              "remotesession",
              "addappraisalplan",
              "viewappraisalplan",
              "addproductconfiguration",
              "viewproductconfiguration",
              "addfinancialgroup",
              "viewfinancialgroup",
              "addofferletter",
              "viewofferletter",
              "editofferletter",
              "viewloanrequest",
              "createinstitution",
              "viewlasreport",
              "remotesession"
            ],
          },
          {
            title: false,
            name: "Appraisal Plan",
            url: "#",
            icon: "icon-tasks",
            roleFunctions: ["addappraisalplan", "viewappraisalplan"],
            children: [
              {
                name: "Add Appraisal Plan",
                url: "/home/addappraisalplan",
                icon: "icon-add green-text",
                roleFunctions: ["addappraisalplan"],
                component: AddAppraisalPlan,
              },
              {
                name: "Appraisal Plan",
                url: "/home/viewappraisalplan",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewappraisalplan"],
                component: ViewAppraisalPlan,
              },
            ],
          },
          {
            title: false,
            name: "Appraisal Institution",
            url: "#",
            icon: "icon-tasks",
            roleFunctions: ["addappraisalplan", "viewappraisalplan"],
            children: [
              {
                name: "Appraisal Institution",
                url: "/home/viewappraisalinstitution",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewappraisalplan"],
                component: ViewAppraisalInstituition,
              },
              {
                name: "Assign Credit Policy",
                url: "/home/assigncreditpolicy",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewappraisalplan"],
                component: AssignCreditPolicyParameter,
              },
            ],
          },
          {
            title: false,
            name: "Offer Letter",
            url: "#",
            icon: "icon-tasks",
            roleFunctions: [
              "addofferletter",
              "viewOfferLetter",
              "editofferletter",
            ],
            children: [
              {
                name: "Add Offer Letter",
                url: "/home/addofferletter",
                icon: "icon-add green-text",
                roleFunctions: ["addofferletter"],
                component: AddOfferLetter,
              },
              {
                name: "View Offer Letter",
                url: "/home/viewofferletter",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewofferletter"],
                component: ViewOfferLetter,
              },
            ],
          },
          // {
          //   title: false,
          //   name: "Loan Request",
          //   url: "#",
          //   icon: "icon-tasks",
          //   roleFunctions: [
          //     "viewloanrequest"
          //   ],
          //   children: [
          //     {
          //       name: "Loan Requests",
          //       url: "/home/viewloanrequests",
          //       icon: "icon-circle-o orange-text",
          //       roleFunctions: ["viewloanrequest"],
          //       component: ViewLoanRequests,
          //     }
          //   ]
          // },
          {
            title: false,
            name: "Loan Request",
            url: "#",
            icon: "icon-tasks",
            roleFunctions: ["viewloanrequest", "remotesession"],
            children: [
              {
                name: "New Loan Requests",
                url: "/home/newloanrequests",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewnewapplication", "updatenewapplication", "updatedataentry", "viewdataentry", "remotesession"],
                component: NewViewLoanRequests,
              },
              {
                name: "Manage Loan Requests",
                url: "/home/viewloanrequests",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewloanrequest", "remotesession"],
                component: ViewLoanRequests,
              },
              {
                name: "View All Loan Requests",
                url: "/home/viewallloanrequests",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewallloanrequests", "remotesession"],
                component: ViewAllLoanRequests,
              },
              {
                name: "New Group Loan Requests",
                url: "/home/newgrouploanrequests",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewnewapplication", "updatenewapplication", "updatedataentry", "viewdataentry", "remotesession"],
                component: NewGroupLoanRequests,
              },
              {
                name: "Manage Group Loan Requests",
                url: "/home/managegrouploanrequests",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewloanrequest", "remotesession"],
                component: ViewGroupLoanRequests,
              },
              {
                name: "View All Group Loan Requests",
                url: "/home/viewAllgrouploanrequests",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewallloanrequests", "remotesession"],
                component: ViewAllGroupLoanRequests,
              },
              {
                name: "Rejected Loan Requests",
                url: "/home/viewrejectedloanrequests",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewloanrequest", "remotesession"],
                component: ViewRejectedLoanRequests,
              },
            ],
          },
          {
            title: false,
            name: "Configuration",
            url: "#",
            icon: "icon-build",
            roleFunctions: [
              "addfinancialgroup",
              "viewfinancialgroup",
              "addproductconfiguration",
              "viewproductconfiguration",
              "maploanproduct",
              "remotesession",
            ],
            children: [
              {
                name: "Add Product Configuration",
                url: "/home/addproductconfiguration",
                icon: "icon-add green-text",
                roleFunctions: ["addproductconfiguration"],
                component: AddProductConfiguration,
              },
              {
                name: "Product Configurations",
                url: "/home/productconfigurations",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewproductconfiguration", "remotesession"],
                component: ViewProductConfigurations,
              },
              {
                name: "Add Workflow Config",
                url: "/home/addfinancialgroup",
                icon: "icon-add green-text",
                roleFunctions: ["addfinancialgroup"],
                component: AddFinancialGroup,
              },
              {
                name: "Workflow Configs",
                url: "/home/financialgroups",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewfinancialgroup", "remotesession"],
                component: ViewFinancialGroups,
              },
              {
                name: "Map Loan Product",
                url: "/home/maploanproduct",
                icon: "icon-add green-text",
                roleFunctions: ["maploanproduct"],
                component: MapLoanProduct,
              },
              {
                name: "Loan Product Maps",
                url: "/home/loanproductmaps",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["maploanproduct", "remotesession"],
                component: ViewLoanProductMaps,
              },
              // {
              //   name: "Fee Payment",
              //   url: "/home/feepayment",
              //   icon: "icon-add green-text",
              //   roleFunctions: ["feepayment", "remotesession"],
              //   component: feePayment,
              // },
              {
                name: "Configure Data Entry",
                url: "/home/addworkflowconfig",
                icon: "icon-add green-text",
                roleFunctions: ["addworkflowconfig", "remotesession"],
                component: AddWorkFlowConfig,
              },
              {
                name: "View Data Entry Config",
                url: "/home/viewworkflowconfig",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewworkflow", "remotesession"],
                component: ViewWorkFlowConfig,
              },
              // {
              //   name: "Add DropDown Values",
              //   url: "/home/addDropDownValues",
              //   icon: "icon-add green-text",
              //   roleFunctions: ["addworkflowconfig", "remotesession"],
              //   component: DropDownConfig,
              // },
              {
                name: "Add Approved Employer",
                url: "/home/AddApprovedEmployers",
                icon: "icon-add green-text",
                roleFunctions: ["addfinancialgroup", "remotesession"],
                component: AddSingleEmployer,
              },
              {
                name: "Bulk Upload Approved Employers",
                url: "/home/manageApprovedEmployers",
                icon: "icon-add green-text",
                roleFunctions: ["addfinancialgroup", "remotesession"],
                component: EmployerListConfig,
              },
              {
                name: "Employer Details",
                url: "/home/employerDetails",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["addfinancialgroup", "remotesession"],
                component: EmployerList,
              },
              {
                name: "Add Supplier Details",
                url: "/home/addSingleSupplierDetails",
                icon: "icon-add green-text",
                roleFunctions: ["addfinancialgroup", "remotesession"],
                component: AddSingleSupplierDetails,
              },
              {
                name: "Bulk Upload Supplier Details",
                url: "/home/addSupplierDetails",
                icon: "icon-add green-text",
                roleFunctions: ["addfinancialgroup", "remotesession"],
                component: AddSupplierDetails,
              },
              {
                name: "Supplier Details",
                url: "/home/supplierDetails",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["addfinancialgroup", "remotesession"],
                component: Suppliers,
              },
            ]

          },
          // {
          //   title: false,
          //   name: "WorkFlow Config",
          //   url: "#",
          //   icon: "icon-columns",
          //   roleFunctions: ["addworkflowconfig", "viewworkflow", "remotesession", "addofferletter"],
          //   children: [
          // {
          //   name: "Configure Data Entry",
          //   url: "/home/addworkflowconfig",
          //   icon: "icon-add green-text",
          //   roleFunctions: ["addworkflowconfig", "remotesession"],
          //   component: AddWorkFlowConfig,
          // },
          // {
          //   name: "View Data Entry Config",
          //   url: "/home/viewworkflowconfig",
          //   icon: "icon-circle-o orange-text",
          //   roleFunctions: ["viewworkflow", "remotesession"],
          //   component: ViewWorkFlowConfig,
          // },
          // {
          //   name: "Add DropDown Values",
          //   url: "/home/addDropDownValues",
          //   icon: "icon-add green-text",
          //   roleFunctions: ["addworkflowconfig", "remotesession"],
          //   component: DropDownConfig,
          // },
          // {
          //   name: "Upload Supplier Details",
          //   url: "/home/addSupplierDetails",
          //   icon: "icon-add green-text",
          //   roleFunctions: ["addworkflowconfig", "remotesession"],
          //   component: SupplierDetailsConfig,
          // },
          // {
          //   name: "Add Work FLow State",
          //   url: "/home/addworkflowstate",
          //   icon: "icon-add green-text",
          //   roleFunctions: ["addworkflow"],
          //   component: AddWorkFlowState,
          // },
          // {
          //   name: "View Work Flow States",
          //   url: "/home/workflowstates",
          //   icon: "icon-circle-o orange-text",
          //   roleFunctions: ["viewworkflow"],
          //   component: WorkFlowStates,
          // },
          // {
          //   name: "View Work Flow Items",
          //   url: "/home/workflowitems",
          //   icon: "icon-circle-o orange-text",
          //   roleFunctions: ["viewworkflow"],
          //   component: WorkFlowItems,
          // },
          //   ],
          // },
          {
            title: false,
            name: "Scoring Management",
            url: "#",
            icon: "icon-check_circle",
            roleFunctions: ["createinstitution", "viewloanrequest", "remotesession"],
            children: [
              {
                name: "Add Score Indicator",
                url: "/home/addscoreindicator",
                icon: "icon-add green-text",
                roleFunctions: ["createinstitution"],
                component: AddScoreIndicator,
              },
              {
                name: "Score Indicators",
                url: "/home/scoreindicators",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["createinstitution"],
                component: ScoreIndicators,
              },
              {
                name: "Assign Score Indicators",
                url: "/home/assignscoreindicators",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["createinstitution"],
                component: AssignScoreIndicator,
              },
              {
                name: "Add Score Card",
                url: "/home/addscorecard",
                icon: "icon-add green-text",
                roleFunctions: ["viewloanrequest", "remotesession"],
                component: AddScoreCard,
              },
              // {
              //   name: "Add Score Metrics",
              //   url: "/home/addscoremetrics",
              //   icon: "icon-add green-text",
              //   roleFunctions: ["viewloanrequest"],
              //   component: AddScoreMetrics,
              // },
              {
                name: "Score Cards",
                url: "/home/scorecards",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewloanrequest", "remotesession"],
                component: ScoreCards,
              },
            ],
          },

          {
            title: false,
            name: "Loan Category",
            url: "#",
            icon: "icon-check_circle",
            roleFunctions: ["addproductconfiguration", "viewloanrequest", "remotesession"],
            children: [
              {
                name: "Add Loan Category",
                url: "/home/addloancategory",
                icon: "icon-add green-text",
                roleFunctions: ["addproductconfiguration"],
                component: AddLoanCategory,
              },
              {
                name: "Loan Category",
                url: "/home/loancategory",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["addproductconfiguration"],
                component: ViewLoanCategory,
              }
            ],
          },
          {
            title: false,
            name: "Report",
            url: "#",
            icon: "icon-check_circle",
            roleFunctions: ["viewlasreport", "remoteseesion"],
            children: [
              {
                name: "Group Loan Disbursement Report",
                url: "/home/grouploandisbursementReport",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewlasreport", "remoteseesion"],
                component: LASGroupDisbursementReport,
              }
            ],
          }
        ],
      },
      {
        displayName: false,
        name: "AUTH",
        icon: "",
        roleFunctions: [],
        children: [
          {
            title: true,
            name: "AUTH",
            url: "",
            icon: "",
            roleFunctions: [],
          },
          {
            title: false,
            name: "Approval",
            url: "#",
            icon: "icon-tasks",
            roleFunctions: [
              "viewapprovalconfigurationitems",
              "viewapprovalitems",
              "viewmyapprovalrequests",
              "remotesession",
            ],
            children: [
              {
                name: "Configuration",
                url: "/home/approvalconfig",
                icon: "icon-settings blue-text",
                roleFunctions: ["viewapprovalconfigurationitems"],
                component: ApprovalConfig,
              },
              {
                name: "Approvable Items",
                url: "/home/approvals",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewapprovalitems", "remotesession"],
                component: Approvals,
              },
              {
                name: "My Approval Requests",
                url: "/home/approvalrequestitems",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewmyapprovalrequests"],
                component: ApprovalRequests,
              },
            ],
          },
          {
            title: false,
            name: "Audit Trail",
            url: "#",
            icon: "icon-checkered-flag",
            roleFunctions: [
              "viewaudittrails",
              "viewactivitytrails",
              "remotesession",
            ],
            children: [
              {
                name: "Audit Trails",
                url: "/home/audittrails",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewaudittrails", "remotesession"],
                component: AuditTrails,
              },
              {
                name: "Activity Trails",
                url: "/home/activitytrails",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewactivitytrails", "remotesession"],
                component: ActivityTrail,
              },
            ],
          },
          {
            title: false,
            name: "Branches",
            url: "#",
            icon: "icon-building-o",
            roleFunctions: ["viewbranches", "addbranch", "remotesession"],
            children: [
              {
                name: "Branch List",
                url: "/home/branches",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewbranches", "remotesession"],
                component: Branches,
              },
              {
                name: "Add Branch",
                url: "/home/addbranch",
                icon: "icon-add green-text",
                roleFunctions: ["addbranch"],
                component: AddBranch,
              },
            ],
          },
          {
            title: false,
            name: "Institutions",
            url: "#",
            icon: "icon-account_balance",
            roleFunctions: [
              "viewinstitutions",
              "addinstitution",
              "viewsweepconfigurations",
              "addbranch",
              "feepayment"
            ],
            children: [
              {
                name: "Institutions",
                url: "/home/institutions",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewinstitutions"],
                component: Institutions,
              },
              {
                name: "Add Institution",
                url: "/home/addinstitution",
                icon: "icon-add green-text",
                roleFunctions: ["addinstitution"],
                component: AddInstitution,
              },
              {
                name: "Upload Logo",
                url: "/home/uploadinstitutionlogo",
                icon: "icon-upload green-text",
                roleFunctions: ["addbranch"],
                component: UploadInstitutionLogo,
              },
              {
                name: "Fee Payment",
                url: "/home/feepayment",
                icon: "icon-add green-text",
                roleFunctions: ["feepayment", "remotesession"],
                component: feePayment,
              },
            ],
          },
          {
            title: false,
            name: "Sweep Configuration",
            url: "#",
            icon: "icon icon-settings_backup_restore",
            roleFunctions: ["viewsweepconfigurations"],
            children: [
              {
                name: "Institution Fees & GL",
                url: "/home/institutionsweepconfiguration",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewsweepconfigurations"],
                component: InstitutionCentralSweepConfigurations,
              },
              {
                name: "General Sweep Settings",
                url: "/home/generalsweepsettings",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewsweepconfigurations"],
                component: ModifyGeneralSweepSettings,
              },
            ],
          },
          {
            title: false,
            name: "Wallet Configuration",
            url: "#",
            icon: "icon icon-settings_backup_restore",
            roleFunctions: ["viewsweepconfigurations"],
            children: [
              {
                name: "Wallet Configuration",
                url: "/home/walletconfiguration",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewsweepconfigurations"],
                component: WalletConfigurations,
              },
            ],
          },
          {
            title: false,
            name: "Billing Configuration",
            url: "#",
            icon: "icon icon-money",
            roleFunctions: [
              "addpaymentconfiguration",
              "viewpaymentconfiguration",
            ],
            children: [
              {
                name: "Central Fee Configuration",
                url: "/home/centralfeeconfiguration",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewpaymentconfiguration"],
                component: CentralFeeConfigurations,
              },
              {
                name: "Add Third Party Fee Config",
                url: "/home/addthirdpartycentralfeeconfiguration",
                icon: "icon-add green-text",
                roleFunctions: ["viewpaymentconfiguration"],
                component: AddThirdPartyCentralFeeConfiguration,
              },
              {
                name: "Mandate Fee Configuration",
                url: "/home/mandatecategoryfeeconfiguration",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewpaymentconfiguration"],
                component: MandateCategoryFeeConfigurations,
              },
              {
                name: "Add Third Party Account Config",
                url: "/home/addthirdpartyaccountconfiguration",
                icon: "icon-add green-text",
                roleFunctions: ["addpaymentconfiguration"],
                component: AddThirdPartyAccountConfiguration,
              },
              {
                name: "Third Party Account Configs",
                url: "/home/thirdpartyaccountconfiguration",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewpaymentconfiguration"],
                component: ThirdPartyAccountConfigurations,
              },
              {
                name: "Add Payment Configuration",
                url: "/home/addpaymentconfiguration",
                icon: "icon-add green-text",
                roleFunctions: ["addpaymentconfiguration"],
                component: AddPaymentConfiguration,
              },
              {
                name: "Payment Configurations",
                url: "/home/paymentconfiguration",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewpaymentconfiguration"],
                component: PaymentConfigurations,
              },
              {
                name: "Add Hybrid Appraisal Sub Fee Config",
                url: "/home/addHybridAppraisalSubConfig",
                icon: "icon-add green-text",
                roleFunctions: ["addpaymentconfiguration"],
                component: AddHybridAppraisalSubConfig,
              },
              {
                name: "View Hybrid Appraisal Sub Fee Config",
                url: "/home/HybridAppraisalFeeConfig",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewpaymentconfiguration"],
                component: HybridAppraisalFeeConfiguration,
              },
              {
                name: "Add Hybrid Appraisal TranFee Config",
                url: "/home/addHybridAppraisalTranConfig",
                icon: "icon-add green-text",
                roleFunctions: ["addpaymentconfiguration"],
                component: AddHybridAppraisalTranConfig,
              },
              {
                name: "View Hybrid Appraisal TranFee Config",
                url: "/home/HybridAppraisalTranFeeConfig",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewpaymentconfiguration"],
                component: HybridAppraisalTranFeeConfiguration,
              },
            ],
          },
          {
            title: false,
            name: "Mailing List",
            url: "#",
            icon: "icon-list",
            roleFunctions: ["addmailinglist", "viewmailinglist"],
            children: [
              {
                name: "Add Mailing List",
                url: "/home/addmailinglist",
                icon: "icon-add green-text",
                roleFunctions: ["addmailinglist"],
                component: AddMailingList,
              },
              {
                name: "View Mailing Lists",
                url: "/home/viewmailinglists",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewmailinglist", "remotesession"],
                component: MailingLists,
              },
            ],
          },
          {
            title: false,
            name: "Regions",
            url: "#",
            icon: "icon-map-o",
            roleFunctions: ["viewbranches", "addbranch", "remotesession"],
            children: [
              {
                name: "Region List",
                url: "/home/regions",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewbranches", "remotesession"],
                component: Regions,
              },
              {
                name: "Add Region",
                url: "/home/addregion",
                icon: "icon-add green-text",
                roleFunctions: ["addbranch"],
                component: AddRegion,
              },
            ],
          },
          {
            title: false,
            name: "Service Offering",
            url: "#",
            icon: "icon-line_style",
            roleFunctions: ["serviceoffering", "addserviceofferingconfig"],
            children: [
              {
                name: "Add Service Offering Config",
                url: "/home/addserviceofferingconfig",
                icon: "icon-add green-text",
                roleFunctions: ["addserviceofferingconfig"],
                component: AddServiceOfferingConfig,
              },
              {
                name: "Configure Default Modules",
                url: "/home/configuredefaultmodule",
                icon: "icon-all_inclusive green-text",
                roleFunctions: ["configuredefaultmodules"],
                component: ConfigureDefaultModule,
              },
              {
                name: "Service Offering Config List",
                url: "/home/serviceofferingconfigs",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["addserviceofferingconfig"],
                component: ServiceOfferingConfigList,
              },
            ],
          },
          {
            title: false,
            name: "System Loan Products",
            url: "/home/systemloanproducts",
            icon: "icon-box-filled",
            roleFunctions: ["viewsystemloanproducts"],
            component: SystemLoanProducts,
          },
          {
            title: false,
            name: "User Roles",
            url: "#",
            icon: "icon-medal9",
            roleFunctions: [
              "viewuserroles",
              "adduserrole",
              "adduserrolefunction",
              "remotesession",
            ],
            children: [
              {
                name: "User Role List",
                url: "/home/userroles",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewuserroles", "remotesession"],
                component: UserRoles,
              },
              {
                name: "Add User Role",
                url: "/home/adduserrole",
                icon: "icon-add green-text",
                roleFunctions: ["adduserrole"],
                component: AddUserRole,
              },
              {
                name: "Add User Role Function",
                url: "/home/adduserrolefunction",
                icon: "icon-add green-text",
                roleFunctions: ["adduserrolefunction"],
                component: AddUserRoleFunction,
              },
            ],
          },
          {
            title: false,
            name: "Users",
            url: "#",
            icon: "icon-account_box",
            roleFunctions: ["viewusers", "adduser", "remotesession"],
            children: [
              {
                name: "Users List",
                url: "/home/users",
                icon: "icon-circle-o orange-text",
                roleFunctions: ["viewusers", "remotesession"],
                component: Users,
              },
              {
                name: "Add User",
                url: "/home/adduser",
                icon: "icon-add green-text",
                roleFunctions: ["adduser"],
                component: AddUser,
              },
            ],
          },
        ],
      },
    ],
  };
  return content;
}
