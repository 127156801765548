import React, { Component } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import { Button } from "react-bootstrap";
import textFormatService from "../../../services/utility/textFormatService";
import DetailItem from "../../../components/detailItem";
import ModifyLoanParams from "../loanRequest/appraisalProcessComponents/modifyLoanParams";
import sweepConfigService from "../../../services/sweep/sweepConfigService";
import SupportingDocuments from "../loanRequest/appraisalProcessComponents/supportingDocuments";
import RepaymentSchedules from "../loanRequest/appraisalProcessComponents/repaymentSchedules";
import GroupRepayment from "./appraisalDetailPages/wizardComponents/editDetailedDataEntry/groupRepayment";

export interface ILoanRequestItemDetailProps {
    values: any;
    item: any;
    financialGroup: any;
    updateItem: any;
    updateItemDocs: any;
    deleteItemDocs: any;
    tempDocs: any;
    tempDeletedDocs: number[];
    canEditItem: boolean;
}

export interface ILoanRequestItemDetailState {
    item: any;
    document: any;
    showPatchModal: boolean;
    showDocPatchModal: boolean;
    showScheduleModal: boolean;
    loanDetails: any;
    repaymentBankName: string;
    fetchingDetails: boolean;
    ownUpdate: boolean;
}

class GroupLoanRequestItemDetail extends Component<
    ILoanRequestItemDetailProps,
    ILoanRequestItemDetailState
> {
    _isMounted = false;
    constructor(props: ILoanRequestItemDetailProps) {
        super(props);
        this.state = {
            item: {},
            document: {},
            showPatchModal: false,
            showDocPatchModal: false,
            showScheduleModal: false,
            loanDetails: {},
            repaymentBankName: "",
            fetchingDetails: true,
            ownUpdate: false
        };
    }

    static getDerivedStateFromProps(
        nextProps: ILoanRequestItemDetailProps,
        prevState
    ) {
        if (prevState.ownUpdate) {
            return {
                ownUpdate: false,
            };
        }
        if (nextProps.item && prevState.item?.CustomerID != JSON.parse(JSON.parse(nextProps.item)).CustomerID) {
            return { item: JSON.parse(JSON.parse(nextProps.item)) };
        }
        return null;
    }

    async componentDidMount() {
        this._isMounted = true;
        this.loadDocs(this.props);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async loadDocs(nextProps: ILoanRequestItemDetailProps) {
        const stateItem = { ...this.state.item }
        let item: any = {};
        if (!_.isEmpty(stateItem)) {
            item = stateItem
        } else {
            item = JSON.parse(JSON.parse(nextProps.item))
        }
        let institutionName = "";
        if (!_.isEmpty(item.PreferredRepaymentBankCBNCode)) {
            try {
                institutionName = (await sweepConfigService.retrieveBankNameByCBNCode(item.PreferredRepaymentBankCBNCode)).data;
            } catch (error) { }
        }
        const loanDetails = JSON.parse(
            JSON.parse(JSON.parse(nextProps.item)).LoanDetails
        );
        if (this._isMounted) {
            this.setState({ repaymentBankName: institutionName, loanDetails, fetchingDetails: false, ownUpdate: true })
        }
    }

    toggleShowDocModal = () => {
        this.setState({ showDocPatchModal: !this.state.showDocPatchModal, ownUpdate: true });
    };

    toggleShowScheduleModal = () => {
        this.setState({ showScheduleModal: !this.state.showScheduleModal, ownUpdate: true });
    };

    render() {
        const {
            item,
            loanDetails,
            showPatchModal,
            showDocPatchModal,
            showScheduleModal,
            repaymentBankName,
            fetchingDetails
        } = this.state;
        const {
            values
        } = this.props

        // console.log(values)
        return (
            <React.Fragment>
                <div
                    className="table-responsive text-nowrap"
                    style={{ height: "250px", overflowY: "scroll" }}
                >
                    {_.isEmpty(item) || _.isEmpty(loanDetails) ? (
                        <div className="row">
                            <Skeleton width={450} height={20} />
                            <Skeleton width={450} height={20} />
                        </div>
                    ) : (
                        <dl className="row" style={{ marginBottom: "0px" }}>
                            <DetailItem
                                label="Product Name"
                                value={loanDetails.Product.Name}
                                labelSize={6}
                                valueSize={6}
                            />
                        </dl>
                    )}
                    {_.isEmpty(item) ? (
                        <div className="row">
                            <Skeleton width={450} height={20} />
                            <Skeleton width={450} height={20} />
                            <Skeleton width={450} height={20} />
                            <Skeleton width={450} height={20} />
                            <Skeleton width={450} height={20} />
                        </div>
                    ) : (
                        <div>
                            <dl className="row" style={{ marginBottom: "0px" }}>
                                <DetailItem
                                    label="Group Account Name"
                                    value={loanDetails.Name}
                                    labelSize={6}
                                    valueSize={6}
                                />
                                <DetailItem
                                    label="Group Account Number"
                                    value={values.linkedAccountNo}
                                    labelSize={6}
                                    valueSize={6}
                                />
                                <DetailItem
                                    label="Group Loan Account Number"
                                    value={loanDetails.Number}
                                    labelSize={6}
                                    valueSize={6}
                                />
                                <DetailItem
                                    label="Customer Name"
                                    value={loanDetails.CustomerName}
                                    labelSize={6}
                                    valueSize={6}
                                />
                                <DetailItem
                                    label="Account Officer"
                                    value={loanDetails.AccountOfficer}
                                    labelSize={6}
                                    valueSize={6}
                                />
                                <DetailItem
                                    label="Bank Verification Number"
                                    value={item.BVN}
                                    labelSize={6}
                                    valueSize={6}
                                />
                                <DetailItem
                                    label="Branch"
                                    value={loanDetails.Branch}
                                    labelSize={6}
                                    valueSize={6}
                                />
                                <DetailItem
                                    label="Moratorium"
                                    value={loanDetails.Moratarium + " Days"}
                                    labelSize={6}
                                    valueSize={6}
                                />
                                <DetailItem
                                    label="Tenure"
                                    value={loanDetails.LoanCycle + " Days"}
                                    labelSize={6}
                                    valueSize={6}
                                />
                                <DetailItem
                                    label="Requested Loan Amount"
                                    value={textFormatService.formatCurrency(Number(values.loanAmount / 100), 2)}
                                    labelSize={6}
                                    valueSize={6}
                                />
                                <DetailItem
                                    label="New Loan Amount"
                                    value={loanDetails.LoanAmountToNaira}
                                    labelSize={6}
                                    valueSize={6}
                                />
                                <DetailItem
                                    label="Interest Rate"
                                    value={loanDetails.InterestRate + "%"}
                                    labelSize={6}
                                    valueSize={6}
                                />
                                <DetailItem
                                    label="Group Contact Number"
                                    value={item.PhoneNumber}
                                    labelSize={6}
                                    valueSize={6}
                                />
                                <DetailItem
                                    label="Group Previous Loan Amount"
                                    value={item?.groupPreviousLoan}
                                    labelSize={6}
                                    valueSize={6}
                                />
                                <DetailItem
                                    label="Group Total Savings"
                                    value={item.groupTotalSavings}
                                    labelSize={6}
                                    valueSize={6}
                                />
                                <DetailItem
                                    label="Preferred Repayment Account"
                                    value={item.PreferredRepaymentAccount}
                                    labelSize={6}
                                    valueSize={6}
                                />

                                <DetailItem
                                    label="Preferred Repayment Bank"
                                    value={repaymentBankName}
                                    labelSize={6}
                                    valueSize={6}
                                />
                                <DetailItem
                                    label="Repayment Schedule"
                                    value={
                                        <Button
                                            size="sm"
                                            variant="primary"
                                            onClick={() => this.toggleShowScheduleModal()}
                                        >
                                            <i className="fas fa-sm fa-eye mr-3" /> View Repayment Schedule
                                        </Button>
                                    }
                                    labelSize={6}
                                    valueSize={6}
                                />
                            </dl>
                            <br />
                        </div>
                    )}
                </div>
                <hr />
                <br />
                <br />
                <div style={{ width: "40%", margin: "20px auto" }}>
                    <h6
                        className="font-weight-normal"
                    >
                        <Button
                            size="sm"
                            variant="primary"
                            onClick={() => this.toggleShowDocModal()}
                        >
                            <i className="fas fa-sm fa-eye mr-3" /> View Supporting Documents
                        </Button>
                    </h6>
                </div>
                {/* <RepaymentSchedules
                    item={item}
                    showScheduleModal={showScheduleModal}
                    toggleScheduleModal={(reloadDetails: boolean, document: any) => {
                        this.toggleShowScheduleModal();
                    }}
                /> */}
                <GroupRepayment
                    item={values}
                    showPatchModal={this.state.showScheduleModal}
                    togglePatchModal={this.toggleShowScheduleModal}
                    values={values.loanDetails}
                />
                <SupportingDocuments
                    item={item}
                    values={this.props.values}
                    updateItemDocs={(document: any) => this.props.updateItemDocs(document)}
                    deleteItemDocs={(document: any, documentId: number) => this.props.deleteItemDocs(document, documentId)}
                    tempDocs={this.props.tempDocs}
                    tempDeletedDocs={this.props.tempDeletedDocs}
                    showDocumentsModal={showDocPatchModal}
                    toggleDocumentsModal={(reloadDetails: boolean, document: any) => {
                        this.toggleShowDocModal();
                    }}
                />
                {/* <ModifyLoanParams
          item={item}
          financialGroupUpperLimit={this.props.financialGroup.upperLimit / 100}
          financialGroupLowerLimit={this.props.financialGroup.lowerLimit / 100}
          showPatchModal={showPatchModal}
          togglePatchModal={(reloadDetails: boolean, item: any, amount: number) => {
            this.toggleShowModal();
            if (reloadDetails === true) {
              this.props.updateItem(item, amount);
            }
          }}
        /> */}
            </React.Fragment>
        );
    }
}

export default GroupLoanRequestItemDetail;
