import React from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { getCurrentUserSession } from "../../../services/auth/authService";
import FormInputComponent from "../../../components/formInputComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import SelectInputComponent, {
  ISelectItems,
} from "../../../components/formSelectComponent";
import { decodeToken } from "react-jwt";
import { Frequency } from "../../../enums/frequency";
import { ReportFormat } from "../../../enums/reportFormat";
import { ReportItems } from "../../../enums/reportItems";
import authValidationService from "../../../services/auth/authValidationService";
import mailingListService from "../../../services/auth/mailingListService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

interface AddMailingListFields {
  name: string;
  frequency: string;
  reportFormat: string;
}

class AddMailingListForm extends BaseFormComponent<AddMailingListFields> { }

interface IAddMailingListProps { }
interface IAddMailingListState {
  availableReportItems: any;
  reportItems: any;
  data: any;
  recipients: any;
  recipientError: any;
}

class AddMailingList extends React.Component<
  IAddMailingListProps,
  IBaseFormState & IAddMailingListState
> {
  constructor(props: IAddMailingListProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      availableReportItems: Object.keys(ReportItems)
        .filter((value) => isNaN(Number(value)) === false)
        .map((key) => ({
          id: key,
          description: ReportItems[key],
        })),
      reportItems: [],
      data: {
        name: "",
        email: ""
      },
      recipients: [],
      recipientError: {}
    };
  }

  onFormSubmit(fields: AddMailingListFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          // errors: []
          errors: authValidationService.validateAddMailingListForm(
            fields
          ),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddMailingListFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      if (this.state.recipients.length === 0) {
        toast.info(`No recipients have been added to this mailing ! `, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
        return false;
      }

      this.setState({ submitting: true });
      const payload: any = {};
      const decodedToken = decodeToken(getCurrentUserSession().token || "");

      //change report items from id to name
      let reportItems = [...this.state.reportItems]
      reportItems = reportItems.map(item => ReportItems[item]);

      payload.name = fields.name;
      payload.frequency = fields.frequency;
      payload.reportFormat = fields.reportFormat;
      // payload.workFlowSteps = workFlowSteps;
      payload.reportItems = reportItems;
      payload.recepients = this.state.recipients;
      payload.institutionId = decodedToken?.["InstitutionId"];
      // console.log(payload)

      // return false
      const response = await mailingListService.saveMailingList(payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Add Mailing List",
          description: `Added new Mailing List ${fields.name}`,
        });
        toast.info(`Mailing List ${fields.name} created successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false });
      this.resetFields();
      onReloadFieldsCB();
    } catch (error) {
      this.setState({ submitting: false });
      return false;
    } finally {
      return true;
    }
  }

  handleOnRecipientInputChange = (id, value) => {
    // console.log(id)
    // if(id == "name"){
    //   this.setState({ name: value });
    // }else{
    //   this.setState({ email: value });
    // }

    let data = { ...this.state.data };
    data[id] = value;
    this.setState({ data });
  };

  handleOnAddMailingListItem = (item) => {
    this.setState(
      {
        // errors: []
        recipientError: authValidationService.validateMailingListRecipientForm(
          item
        ),
      },
      () => {
        if (Object.keys(this.state.recipientError).length === 0) {
          // console.log(item)
          const { data, recipients } = this.state;

          var checker = recipients.filter((x) => x.email === item.email);
          if (_.isEmpty(checker)) {
            recipients.push(item);
          } else {
            toast.error(`Recipient with email ${item.email} has already been added`)
          }
          data.name = "";
          data.email = "";
          this.setState({ recipients, data });
        }
      }
    );
  };

  handleOnRemoveMailingListItem = (item) => {
    // console.log(item)
    const { recipients } = this.state;

    // var checker = recipients.filter((x) => x.email === item.email);
    _.remove(recipients, function (n: any) { return n.email === item.email; })
    // if (!_.isEmpty(checker)) {
    //   recipients.pop(item);
    // }
    this.setState({ recipients });
  };


  handleOnReportItemChange = (item) => {
    // console.log("clicked Credit QUalification Rule Indicator: ", item);
    const reportItems = [...this.state.reportItems];
    if (reportItems.indexOf(item) !== -1) {
      reportItems.splice(reportItems.indexOf(item), 1);
    } else {
      reportItems.push(item);
    }
    // console.log("Credit QUalification Rule Indicators: ", reportItems);
    this.setState({ reportItems });
  };

  resetFields() {
    const { data } = this.state;
    data.name = "";
    data.email = "";
    this.setState({
      data,
      validated: false,
      submitting: false
    });
  }
  render() {
    const {
      errors,
      validated,
      data,
      availableReportItems,
      recipients
    } = this.state;

    let frequencyInputData: ISelectItems[] = Object.keys(Frequency)
      .filter((value) => isNaN(Number(value)) === false)
      .map((key) => ({
        name: Frequency[key],
        value: Frequency[key],
      }));

    let reportFormatInputData: ISelectItems[] = Object.keys(ReportFormat)
      .filter((value) => isNaN(Number(value)) === false)
      .map((key) => ({
        name: ReportFormat[key],
        value: ReportFormat[key],
      }));


    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add Mailing List</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <form action="#">
                  <div className="card no-b">
                    <div className="card-body">
                      <h5 className="card-title">
                        <i className="fas fa-columns mr-3" />
                        Mailing List Details
                      </h5>
                      <div className="row">
                        <AddMailingListForm
                          initialValues={{ name: "", frequency: "", reportFormat: "" }}
                          FormComponent={({
                            fields: { name, frequency, reportFormat },
                            onChange,
                            onReloadFields,
                            onHandleSubmit,
                          }) => (
                            <div className="col-md-6">
                              <div className="form-row">
                                <FormInputComponent
                                  id="name"
                                  type="text"
                                  name="name"
                                  divClass={12}
                                  placeholder=""
                                  value={name}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <SelectInputComponent
                                  id="frequency"
                                  name="frequency"
                                  divClass={12}
                                  value={frequency}
                                  items={frequencyInputData}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <SelectInputComponent
                                  id="reportFormat"
                                  name="reportFormat"
                                  divClass={12}
                                  value={reportFormat}
                                  items={reportFormatInputData}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <br />
                                <br />
                                <br />
                                <br />


                                <label
                                  htmlFor="reportItems"
                                  className="control-label textbox-label"
                                >
                                  {"  "} Report Items
                                </label>
                                <div
                                  className="text-nowrap block-example border"
                                  style={{
                                    width: "100%",
                                    height: "200px",
                                    overflowY: "scroll",
                                  }}
                                >
                                  <React.Fragment>
                                    {availableReportItems.length ===
                                      0 ? (
                                      <div
                                        style={{
                                          backgroundColor: "#F3F3F3",
                                        }}
                                      >
                                        <p className="text-center">
                                          No Available Report Items Found
                                        </p>
                                      </div>
                                    ) : (
                                      <React.Fragment>
                                        {availableReportItems.map(
                                          (item) => (
                                            <div
                                              className="custom-control custom-checkbox"
                                              key={availableReportItems.indexOf(
                                                item
                                              )}
                                            >
                                              <input
                                                id={item.id}
                                                name={item.id}
                                                className="custom-control-input"
                                                type="checkbox"
                                                onChange={() =>
                                                  this.handleOnReportItemChange(
                                                    item.id
                                                  )
                                                }
                                                checked={
                                                  _.isEmpty(
                                                    this.state.reportItems.filter(
                                                      (x) => x === item.id
                                                    )
                                                  )
                                                    ? false
                                                    : true
                                                }
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor={item.id}
                                              >
                                                {item.description}
                                              </label>
                                            </div>
                                          )
                                        )}
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                </div>


                              </div>
                              <hr />
                              <div className="card-body">
                                <button
                                  type="submit"
                                  className="btn btn-sm btn-primary"
                                  disabled={this.state.submitting}
                                  onClick={(e) => {
                                    onHandleSubmit(
                                      e,
                                      ActionTypes.SAVE,
                                      "Mailing List",
                                      () => {
                                        this.onFormSubmit({ name, reportFormat, frequency }, onReloadFields);
                                      }
                                    );
                                  }}
                                >
                                  {this.state.submitting === false ? (
                                    <React.Fragment>
                                      <i className="fas fa-lg fa-save mr-3" /> Save
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                      Please wait...
                                    </React.Fragment>
                                  )}
                                </button>
                              </div>
                            </div>

                          )}
                        ></AddMailingListForm>
                        <div className="col-md-6" style={{ borderLeft: "double" }}>
                          <FormInputComponent
                            id="name"
                            type="text"
                            name="recipientName"
                            placeholder=""
                            value={data.name}
                            validated={validated}
                            errors={this.state.recipientError}
                            onChange={this.handleOnRecipientInputChange}
                          />
                          <FormInputComponent
                            id="email"
                            type="email"
                            name="recipientEmail"
                            placeholder=""
                            value={data.email}
                            validated={validated}
                            errors={this.state.recipientError}
                            onChange={this.handleOnRecipientInputChange}
                          />
                          <button
                            className="btn btn-sm btn-outline-primary float-right my-3"
                            type="button"
                            disabled={_.isEmpty(data.name) || _.isEmpty(data.email)}
                            onClick={() =>
                              this.handleOnAddMailingListItem({ name: data.name, email: data.email })
                            }
                          >
                            <React.Fragment>
                              <i className="far fa-save fa-lg mr-4" /> Save
                            </React.Fragment>
                          </button>
                          <br />
                          <h5 className="text-center mt-5">Recipient List</h5>
                          <div
                            className="table-responsive text-nowrap"
                            style={{
                              height: "300px",
                              overflowY: "scroll",
                            }}
                          >
                            <table className="table table-hover table-content table-sm table-striped mb-0">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    style={{
                                      width: "5px",
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                    }}
                                  >
                                    <strong>#</strong>
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                    }}
                                  >
                                    <strong>Recipient Name</strong>
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      // width: "5px",
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                    }}
                                  >
                                    <strong>Recipient Email</strong>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {recipients.length === 0 ? (
                                  <React.Fragment>
                                    <tr>
                                      No contacts added yet
                                    </tr>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    {recipients.map((item) => (
                                      <tr key={recipients.indexOf(item)}>
                                        <td
                                          style={{
                                            width: "5px",
                                            paddingTop: "0.2rem",
                                            paddingBottom: "0.2rem",
                                            fontSize: "small",
                                          }}
                                        >
                                          {recipients.indexOf(
                                            item
                                          ) + 1}
                                        </td>
                                        <td
                                          style={{
                                            paddingTop: "0.2rem",
                                            paddingBottom: "0.2rem",
                                            fontSize: "small",
                                          }}
                                        >
                                          {item.name}
                                        </td>
                                        <td
                                          style={{
                                            paddingTop: "0.2rem",
                                            paddingBottom: "0.2rem",
                                            fontSize: "small",
                                          }}
                                        >
                                          {item.email}
                                        </td>
                                        <td
                                          style={{
                                            paddingTop: "0.2rem",
                                            paddingBottom: "0.2rem",
                                            fontSize: "small",
                                          }}
                                        >
                                          <i
                                            className="far fa-trash-alt ml-5 red-text fa-lg"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              this.handleOnRemoveMailingListItem(
                                                item
                                              )
                                            }
                                          ></i>
                                        </td>
                                      </tr>
                                    ))}
                                  </React.Fragment>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddMailingList;
