import React, { Fragment, Component } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../../../../components/detailItem";
import textFormatService from "../../../../../../services/utility/textFormatService";

interface PersonalDetailsProps {
    values: any;
    handleChange: any;
    handleBlur: any;
}

interface PersonalDetailsState {
    item: any;
}

class GroupLoanDetails extends Component<PersonalDetailsProps, PersonalDetailsState> {
    constructor(props: PersonalDetailsProps) {
        super(props);
        this.state = {
            item: {},
        };
    }

    static getDerivedStateFromProps(nextProps: PersonalDetailsProps, prevState) {
        return {
            item: nextProps.values,
        };
    }

    render() {
        const { values } = this.props;

        const jsonLoanDetails = JSON.parse(JSON.parse(values.jsonData).LoanDetails);
        console.log(jsonLoanDetails)
        // console.log(JSON.parse(JSON.parse(values.jsonData).LoanDetails))

        return (
            <Fragment>
                <div className="card">
                    <div className="card-header clear-fix">
                        {_.isEmpty(values) ? (
                            <Skeleton width={200} />
                        ) : (
                            <h6
                                className="card-title float-left"
                                style={{ marginBottom: "0px" }}
                            >
                                <b>Loan Information</b>
                            </h6>
                        )}
                    </div>

                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                {_.isEmpty(values) ? (
                                    <Skeleton count={3} width={300} />
                                ) : (
                                    <dl className="row">
                                        <DetailItem
                                            label="Loan Amount"
                                            labelSize={7}
                                            valueSize={5}
                                            value={textFormatService.convertKoboToNaira(Number(values.loanAmount), 2)}
                                        />
                                        <DetailItem
                                            label="Loan Purpose"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.loanPurpose}
                                        />
                                        <DetailItem
                                            label="Repayment Frequency (Principal)"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.loanDetails?.repaymentFrequencyPrincipal}
                                        />
                                        <DetailItem
                                            label="Due Day"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.loanDetails?.dueDate}
                                        />
                                    </dl>
                                )}
                            </div>
                            <div className="col-md-6">
                                {_.isEmpty(values) ? (
                                    <Skeleton count={3} width={300} />
                                ) : (
                                    <dl className="row">
                                        <DetailItem
                                            label="Loan Tenor"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.tenureInDays + " Days"}
                                        />
                                        {values.loanDetails?.interestRate ? (
                                            <DetailItem
                                                label="Interest Rate"
                                                labelSize={7}
                                                valueSize={5}
                                                value={values.loanDetails?.interestRate + " %"}
                                            />
                                        ) : (
                                            <DetailItem
                                                label="Interest Rate"
                                                labelSize={7}
                                                valueSize={5}
                                                value={JSON.parse(JSON.parse(values.jsonData).LoanDetails).InterestRate + " %"}
                                            />
                                        )}
                                        <DetailItem
                                            label="Repayment Frequency (Interest)"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.loanDetails?.repaymentFrequencyInterest}
                                        />
                                    </dl>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card mt-3">
                    <div className="card-header clear-fix">
                        {_.isEmpty(values) ? (
                            <Skeleton width={200} />
                        ) : (
                            <h6
                                className="card-title float-left"
                                style={{ marginBottom: "0px" }}
                            >
                                <b>Moratorium Details</b>
                            </h6>
                        )}
                    </div>

                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                {_.isEmpty(values) ? (
                                    <Skeleton count={3} width={300} />
                                ) : (
                                    <dl className="row">
                                        <DetailItem
                                            label="Moratorium"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.loanDetails?.moratorium}
                                        />
                                        <DetailItem
                                            label="Impact on Tenure"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.loanDetails?.impactOnTenor}
                                        />
                                        {/* <DetailItem
                                            label="Interest Treatment"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.loanDetails?.spreadInterestAcrossBalanceTenor}
                                        /> */}
                                    </dl>
                                )}
                            </div>
                            <div className="col-md-6">
                                {_.isEmpty(values) ? (
                                    <Skeleton count={3} width={300} />
                                ) : (
                                    <dl className="row">
                                        <DetailItem
                                            label="Moratorium Period (In Months)"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.loanDetails?.moratoriumPeriod}
                                        />
                                        <DetailItem
                                            label="Charge Interest during Moratorium"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.chargeInterest}
                                        />
                                    </dl>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mt-3">
                    <div className="card-header clear-fix">
                        {_.isEmpty(values) ? (
                            <Skeleton width={200} />
                        ) : (
                            <h6
                                className="card-title float-left"
                                style={{ marginBottom: "0px" }}
                            >
                                <b>Product Information</b>
                            </h6>
                        )}
                    </div>

                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                {_.isEmpty(values) ? (
                                    <Skeleton count={3} width={300} />
                                ) : (
                                    <dl className="row">
                                        <DetailItem
                                            label="Product Name"
                                            labelSize={7}
                                            valueSize={5}
                                            value={JSON.parse(JSON.parse(values.jsonData).LoanDetails).Product.Name}
                                        />
                                        <DetailItem
                                            label="Product Type"
                                            labelSize={7}
                                            valueSize={5}
                                            value={JSON.parse(JSON.parse(values.jsonData).LoanDetails).Product.ProductType}
                                        />
                                    </dl>
                                )}
                            </div>
                            <div className="col-md-6">
                                {_.isEmpty(values) ? (
                                    <Skeleton count={3} width={300} />
                                ) : (
                                    <dl className="row">
                                        <DetailItem
                                            label="Product Code"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.productCode}
                                        />
                                        <DetailItem
                                            label="Financial Group"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.financialGroup.name}
                                        />
                                    </dl>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mt-3">
                    <div className="card-header clear-fix">
                        {_.isEmpty(values) ? (
                            <Skeleton width={200} />
                        ) : (
                            <h6
                                className="card-title float-left"
                                style={{ marginBottom: "0px" }}
                            >
                                <b>Group Account Information</b>
                            </h6>
                        )}
                    </div>

                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                {_.isEmpty(values) ? (
                                    <Skeleton count={3} width={300} />
                                ) : (
                                    <dl className="row">
                                        <DetailItem
                                            label="Group Account Name"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.loanDetails?.accountName}
                                        />
                                        <DetailItem
                                            label="Account Officer"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.loanDetails?.accountName}
                                        />
                                        <DetailItem
                                            label="Branch"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.loanDetails?.branch}
                                        />
                                        <DetailItem
                                            label="Preferred Repayment Bank"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.loanDetails?.preferredRepaymentBank}
                                        />
                                    </dl>
                                )}
                            </div>
                            <div className="col-md-6">
                                {_.isEmpty(values) ? (
                                    <Skeleton count={3} width={300} />
                                ) : (
                                    <dl className="row">
                                        <DetailItem
                                            label="Group Account Number"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.loanDetails?.branch}
                                        />
                                        <DetailItem
                                            label="Bank Verification Number"
                                            labelSize={7}
                                            valueSize={5}
                                            value={
                                                values.loanDetails?.bankVerificationNumber ?
                                                    values.loanDetails.bankVerificationNumber :
                                                    values.bvn
                                            }
                                        />
                                        <DetailItem
                                            label="Defaulting Loan Interest"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.loanDetails?.defaultingLoanInterest}
                                        />
                                        <DetailItem
                                            label="Preferred Repayment Account"
                                            labelSize={7}
                                            valueSize={5}
                                            value={values.loanDetails?.preferredRepaymentAccount}
                                        />
                                    </dl>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mt-3">
                    <div className="card-header clear-fix">
                        <h6
                            className="float-left"
                            style={{ marginBottom: "0px" }}
                        >
                            <b>Charges</b>
                        </h6>
                    </div>
                    <div className="card-body">
                        <div className="form-row">
                            <div className="col-md-12">
                                <div
                                    className="table-responsize text-nowrap"
                                    style={{ height: "150px", overflowY: "scroll" }}
                                >
                                    <table className="table table-hover table-content table-sm table-striped mb-0">
                                        <thead>
                                            <tr>
                                                <th
                                                    scope="col"
                                                    style={{
                                                        width: "5px",
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                    }}
                                                >
                                                    <strong>#</strong>
                                                </th>
                                                <th
                                                    scope="col"
                                                    style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                    }}
                                                >
                                                    <strong>Name</strong>
                                                </th>
                                                <th
                                                    scope="col"
                                                    style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                    }}
                                                >
                                                    <strong>Amount</strong>
                                                </th>
                                                <th
                                                    scope="col"
                                                    style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                    }}
                                                ></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {_.isEmpty(jsonLoanDetails.LoanFees) ||
                                                jsonLoanDetails.LoanFees.length === 0 ? (
                                                <tr>
                                                    <td
                                                        className="font-weight-normal text-truncate"
                                                        colSpan={4}
                                                        style={{
                                                            textAlign: "center",
                                                            color: "#FE825E",
                                                            fontSize: "small",
                                                        }}
                                                    >
                                                        ...No Charge available
                                                    </td>
                                                </tr>
                                            ) : (
                                                jsonLoanDetails?.LoanFees.map((charge, id) => (
                                                    <tr key={id}>
                                                        <td
                                                            style={{
                                                                width: "5px",
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                                fontSize: "small",
                                                            }}
                                                        >
                                                            {id + 1}
                                                        </td>
                                                        <td
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                                fontSize: "small",
                                                            }}
                                                        >
                                                            {charge.Name}
                                                        </td>
                                                        <td
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                                fontSize: "small",
                                                            }}
                                                        >
                                                            {textFormatService.formatCurrency(charge.Amount, 2)}
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>

        );
    }
}

export default GroupLoanDetails;