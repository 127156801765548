import * as React from "react";
// import _ from "lodash";
import { Fragment } from "react";
import {
  IFilterData,
  BaseListComponentState,
} from "../../../components/BaseListComponent";
import {
  createFilterData,
  mapEntityList,
} from "./../../../services/utility/autoMapperService";
import BaseListComponent from "./../../../components/BaseListComponent";
import ItemsTable, {
  filterFieldData,
} from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "./../../../components/tableComponents/tableFilterPane";
import institutionManagementService from "../../../services/creditAssessment/institutionManagementService";
import EditInstitutionSetup from "./editInstitutionSetup";
import feeConfigService from "../../../services/creditAssessment/feeConfigService";
import creditQualificationRuleService from "../../../services/creditAssessment/creditQualificationRuleService";
import InstitutionSetupDetail from "./institutionSetupDetail";
import { getCurrentUsersRoles } from "../../../services/auth/authService";
import ModifyScoreIndicators from "./modifyScoreIndicators";

export interface InstitutionSetupsProps { }

export interface InstitutionSetupsState extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
  allItems: any;
  institutionFeeConfig: any;
  institutionIndicators: any;
  showPatchModal: boolean;
}

class InstitutionSetupsList extends BaseListComponent<InstitutionSetupsState> { }

class InstitutionSetups extends React.Component<
  InstitutionSetupsProps,
  InstitutionSetupsState
> {
  constructor(props: InstitutionSetupsProps) {
    super(props);
    this.state = {
      data: createFilterData([
        // "name", "status"
      ]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [
        // { name: "name", fieldType: "text", value: "" },
        // {
        //   name: "status",
        //   fieldType: "select",
        //   options: ["Active", "Inactive"],
        //   value: "",
        // },
      ],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
      showPatchModal: false,
      allItems: [],
      institutionFeeConfig: {},
      institutionIndicators: {},
    };
  }

  handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true });
    // const decodedToken = decodeToken(getCurrentUserSession().token || "");
    // let dataClone =
    //   typeof data === "undefined" ? { ...this.state.data } : { ...data };
    try {
      const inst = await institutionManagementService.retrieveAllInstitutions();
      // console.log("fetched", institutions);
      const processedInstitution = inst.data;
      processedInstitution.forEach((inst) => {
        if (inst.isActive) {
          inst["isActive"] = "Active";
        } else {
          inst["isActive"] = "Inactive";
        }
      });
      // dataClone["institutionId"] = {
      //   item1: "=",
      //   item2: decodedToken?.["InstitutionId"]?.toString() || "",
      // };
      // const institutions = await institutionservice.filterPagedinstitutions(
      //   dataClone,
      //   Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      //   currentPage
      // );
      const institutions: any = [];
      const totalLength = processedInstitution.length;
      const initial = (currentPage - 1) * pageSize;
      for (let i = initial; i < initial + pageSize; i++) {
        if (i === totalLength) {
          break;
        }
        institutions.push(processedInstitution[i]);
      }
      //   console.log("gotten:: ", institutions);
      this.setState({
        tableItems: mapEntityList({
          // entityList: institutions.data.item2,
          entityList: institutions,
          properties: [
            { oldName: "institutionCode", newName: "institutionCode" },
            { oldName: "institutionName", newName: "institutionName" },
            {
              isTag: true,
              oldName: "isActive",
              newName: "status",
              success: "true",
              danger: "false",
              default: "default",
              primary: "primary",
              secondary: "secondary",
              warning: "warning",
              light: "light",
              dark: "dark",
            },
          ],
        }),
        currentPage,
        pageSize,
        // totalSize: institutions.data.item1,
        allItems: processedInstitution,
        totalSize: totalLength,
        fetching: false,
      });
    } catch (ex) {
      //   console.log("caught exception", ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
        this.setState({ fetching: false });
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        this.setState({ fetching: false });
      }
    }
  };

  handleOnExport = async (type: string) => { };

  fetchItemById = async (item: any) => {
    // console.log("item from table click:: ", item);
    try {
      this.setState({ fetchingDetail: true });
      const feeConfig = await feeConfigService.retrieveAllByInstitution(
        item.institutionCode
      );
      const indicators =
        await creditQualificationRuleService.retrieveCreditQualificationIndicatorsByInstitution(
          item.institutionCode
        );
      const institution = await institutionManagementService.retrieveInstitutionById(item.id);

      this.setState({
        item: institution.data,
        institutionFeeConfig: feeConfig.data.filter(x => x.feeCategory === "Credit Assessment")[0],
        institutionIndicators: indicators.data,
      });
    } catch (error) {
    } finally {
      this.setState({
        fetchingDetail: false,
      });
    }
  };

  onTogglePatchModal = () => {
    this.setState({
      showPatchModal: !this.state.showPatchModal,
      showDetailsModal: !this.state.showDetailsModal,
    });
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "" && key !== "institutionId") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }

  render() {
    const {
      // data,
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
      institutionFeeConfig,
      institutionIndicators,
    } = this.state;
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Institution Setups</b>
              </h3>
            </div>
            <InstitutionSetupsList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              initialValues={{
                data: createFilterData([
                  // "name", "status"
                ]) as IFilterData,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: [
                  // { name: "name", fieldType: "text", value: "" },
                  // {
                  //   name: "status",
                  //   fieldType: "select",
                  //   options: ["Active", "Inactive"],
                  //   value: "",
                  // },
                ],
                item: {},
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                onToggleEditModal,
                showDetailsModal,
                showEditModal,
              }) => (
                <Fragment>
                  <InstitutionSetupDetail
                    showDetailsModal={showDetailsModal}
                    toggleDetailsModal={onToggleDetailsModal}
                    toggleEditModal={onToggleEditModal}
                    showTrail={null}
                    currentUserRoles={getCurrentUsersRoles()}
                    institution={item}
                    feeConfig={institutionFeeConfig}
                    indicators={institutionIndicators}
                    togglePatchModal={async () => {
                      await this.fetchItemById(item);
                      this.onTogglePatchModal();
                      onToggleDetailsModal();
                    }}
                  />
                  <EditInstitutionSetup
                    showEditModal={showEditModal}
                    toggleEditModal={(reloadDetails: boolean, item: any) => {
                      onToggleEditModal();
                      if (reloadDetails === true) {
                        this.fetchItemById(item);
                      }
                      this.setState({
                        showDetailsModal: !this.state.showDetailsModal,
                      });
                    }}
                    institution={item}
                    feeConfig={institutionFeeConfig}
                    indicators={institutionIndicators}
                  // item={item}
                  />
                  <ModifyScoreIndicators
                    showPatchModal={this.state.showPatchModal}
                    togglePatchModal={(reloadDetails: boolean, item: any) => {
                      this.onTogglePatchModal();
                      // if (reloadDetails === true) {
                      //   this.fetchItemById(item);
                      // }
                      onToggleDetailsModal();
                    }}
                    item={item}
                  />
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="Institution Setups"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                            //   amountColumn={["loanAmount"]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></InstitutionSetupsList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default InstitutionSetups;
