import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../components/detailItem";
import mandateService from "../../services/mandate/mandateService";
import textFormatService from "../../services/utility/textFormatService";
import { mapEntityList } from "../../services/utility/autoMapperService";
import DetailItemsTable from "../../components/tableComponents/detailItemsTable";
import institutionsService from "../../services/auth/institutionsService";
import mandateSweepService from "../../services/sweep/mandateSweepService";
import MandateDetail from "./../mandate/mandate/mandateDetail";
import { retrieveAccountsByBVN } from "./../../services/mandate/bvnService";

interface IMandateReportDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  item: any;
  status: string;
}

interface IMandateReportDetailState {
  mandatesCurrentPage: number;
  mandatesFetching: boolean;
  mandatesCount: number;
  mandates: any;
  showMandateInfoModal: boolean;
  mandate: any;
  mandateItemFetching: boolean;
}

class MandateReportDetail extends Component<
  IMandateReportDetailProps,
  IMandateReportDetailState
> {
  constructor(props: IMandateReportDetailProps) {
    super(props);
    this.state = {
      mandatesCurrentPage: 1,
      mandatesFetching: false,
      mandatesCount: 0,
      mandates: [],
      showMandateInfoModal: false,
      mandate: {},
      mandateItemFetching: false,
    };
  }

  fetchMandateInfoById = async (item) => {
    try {
      this.setState({ mandateItemFetching: true });
      const mandate = await mandateService.retrieveMandateById2(
        this.props.item.id,
        item.id
      );
      this.setState({ mandate: mandate.data });
      const institution = await institutionsService.retrieveInstitutionById(
        this.props.item.id
      );
      const sweepMandateInfo = await mandateSweepService.retrieveMandateInfo(
        institution.data.code,
        mandate.data.loanReference
      );
      const _mandate = { ...this.state.mandate };
      _mandate.totalAmountDeducted = sweepMandateInfo.data.totalAmountDeducted;
      _mandate.totalNoOfTimesDeducted =
        sweepMandateInfo.data.totalNoOfTimesDeducted;
      _mandate.lastAmountDeducted = sweepMandateInfo.data.lastAmountDeducted;
      _mandate.lastDeductionDate = sweepMandateInfo.data.lastDeductionDate;
      var _bvnaccounts;
      _mandate.bvnAccounts = [];
      try {
        _bvnaccounts = await retrieveAccountsByBVN(_mandate.bvn);
        // console.log("bvnaccounts: ", _bvnaccounts);
      } catch (error) {}
      _mandate.bvnAccounts = _bvnaccounts?.data[0].linkedAccounts;
      // console.log("mandate: ", _mandate);
      this.setState({ mandate: _mandate });
      // return _mandate;
    } catch (error) {
      // return {};
    } finally {
      this.setState({ mandateItemFetching: false });
      // return {};
    }
  };

  handleOnToggleMandateInfoModal = () => {
    this.setState({ showMandateInfoModal: !this.state.showMandateInfoModal });
    if (this.state.showMandateInfoModal === true) {
      this.setState({ mandate: {} });
    }
  };

  handleOnDetailPageChange = async (currentPage: number) => {
    this.setState({ mandatesFetching: true });
    try {
      const payload: any = {};
      payload["institutionId"] = {
        item1: "=",
        item2: this.props.item.id,
      };
      payload["status"] = {
        item1: "=",
        item2: this.props.status,
      };
      const mandates = await mandateService.filterMandates(
        payload,
        Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
        currentPage
      );
      this.setState({
        mandates: mapEntityList({
          entityList: mandates.data.item2,
          properties: [
            { oldName: "customerName", newName: "Name" },
            { oldName: "bvn", newName: "BVN" },
            { oldName: "phoneNumber", newName: "phoneNumber" },
            { oldName: "loanReference", newName: "loanReference" },
            { oldName: "loanAmount", newName: "loanAmount" },
            {
              isTag: true,
              oldName: "status",
              newName: "status",
              success: "Active",
              danger: "Inactive",
              default: "default",
              primary: "Confirmed",
              secondary: "secondary",
              warning: "Cancelled",
              light: "AwaitingConfirmation",
              dark: "dark",
            },
            {
              isTag: true,
              oldName: "requestStatus",
              newName: "requestStatus",
              success: "Disbursed",
              danger: "Failed",
              default: "AwaitingDisbursement",
              primary: "Confirmed",
              secondary: "secondary",
              warning: "Cancelled",
              light: "AwaitingConfirmation",
              dark: "dark",
            },
          ],
        }),
        mandatesCurrentPage: currentPage,
        mandatesCount: mandates.data.item1,
        mandatesFetching: false,
      });
    } catch (error) {
    } finally {
      this.setState({ mandatesFetching: false });
    }
  };

  async componentDidMount() {
    this.handleOnDetailPageChange(this.state.mandatesCurrentPage);
  }

  render() {
    const { showDetailsModal, toggleDetailsModal, item } = this.props;
    return (
      <React.Fragment>
        <MandateDetail
          showDetailsModal={this.state.showMandateInfoModal}
          toggleDetailsModal={this.handleOnToggleMandateInfoModal}
          item={this.state.mandate}
          switchRecoveryMode={false}
        />
        <Modal
          size="xl"
          backdrop="static"
          show={showDetailsModal}
          onHide={toggleDetailsModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {_.isEmpty(item) ? (
                <Skeleton width={500} />
              ) : (
                <h5>
                  <i className="fas fa-receipt fa-sm s-18 mr-3" />
                  Mandate Report Details
                </h5>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card">
              <div className="card-header clear-fix">
                {_.isEmpty(item) ? (
                  <Skeleton width={200} />
                ) : (
                  <h6
                    className="card-title float-left"
                    style={{ marginBottom: "0px" }}
                  >
                    <b>General Information</b>
                  </h6>
                )}
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    {_.isEmpty(item) ? (
                      <Skeleton count={6} width={300} />
                    ) : (
                      <dl className="row">
                        <DetailItem
                          label="Institution"
                          labelSize={6}
                          valueSize={6}
                          value={item.name}
                        />
                        <DetailItem
                          label="Mandate Limit"
                          labelSize={6}
                          valueSize={6}
                          value={textFormatService.splitAtUpper(
                            item.mandateLimit
                          )}
                        />
                        <DetailItem
                          label={"Total " + this.props.status + " Mandate(s)"}
                          labelSize={6}
                          valueSize={6}
                          value={item.mandateStatusCount}
                        />
                        {item.mandateLimit !== "Unlimited" && (
                          <DetailItem
                            label="Mandate Balance"
                            labelSize={6}
                            valueSize={6}
                            value={item.mandateBalance}
                          />
                        )}
                        <DetailItem
                          label="Mandate Value"
                          labelSize={6}
                          valueSize={6}
                          value={textFormatService.convertKoboToNaira(
                            item.mandateValue,
                            2
                          )}
                        />
                      </dl>
                    )}
                  </div>
                  <div className="col-md-6">
                    {_.isEmpty(item) ? (
                      <Skeleton count={6} width={300} />
                    ) : (
                      <dl className="row">
                        <DetailItem
                          label="Category"
                          labelSize={6}
                          valueSize={6}
                          value={item.category}
                        />
                        <DetailItem
                          label="Total Mandates"
                          labelSize={6}
                          valueSize={6}
                          value={item.totalMandates}
                        />
                        <DetailItem
                          label="Total Accounts Linked"
                          labelSize={6}
                          valueSize={6}
                          value={item.totalAccountsLinked}
                        />
                        <DetailItem
                          label="Total BVN"
                          labelSize={6}
                          valueSize={6}
                          value={item.totalBVN}
                        />
                        {/* <DetailItem
                          label="Total Linked Banks"
                          labelSize={6}
                          valueSize={6}
                          value={item.totalLinkedBanks}
                        /> */}
                      </dl>
                    )}
                  </div>
                </div>
              </div>
              <DetailItemsTable
                title="Mandates"
                item={item}
                handleOnPageChange={this.handleOnDetailPageChange}
                content={this.state.mandates}
                contentCount={this.state.mandatesCount}
                page={this.state.mandatesCurrentPage}
                fetching={this.state.mandatesFetching}
                amountColumn={["loanAmount"]}
                hasDetailModal={true}
                onViewDetails={(mandate) => {
                  this.fetchMandateInfoById(mandate);
                  this.handleOnToggleMandateInfoModal();
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              variant="outline-danger"
              onClick={toggleDetailsModal}
            >
              <i className="fas fa-times mr-3" />
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default MandateReportDetail;
