import React from "react";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import BaseFormComponent, { IBaseFormState } from "../../components/BaseFormComponent";
import SelectInputComponent from "../../components/formSelectComponent";
import { ActionTypes } from "../../enums/actionTypes";
import authValidationService from "../../services/auth/authValidationService";
import loanProductService from "../../services/auth/loanProductService";
import textFormatService from "../../services/utility/textFormatService";

export interface ILoanProductConfigModalProps {
    showLoanProductConfigModal: boolean;
    toggleLoanProductConfigModal: any;
    institutions: any;
}

export interface ILoanProductConfigModalState {
    selectedAll: boolean;
    fetchingLoanProducts: boolean;
    loanProducts: any;
    productUseCases: any;
}

interface AddLoanProductFields {
    institution: string;
}
class AddLoanProductConfigForm extends BaseFormComponent<AddLoanProductFields> {}

class LoanProductConfigModal extends React.Component<ILoanProductConfigModalProps, IBaseFormState & ILoanProductConfigModalState> {
    constructor(props: ILoanProductConfigModalProps) {
        super(props);
        this.state = {
            validated: false,
            submitting: false,
            errors: {},
            selectedAll: false,
            fetchingLoanProducts: false,
            loanProducts: [],
            productUseCases: [
                { key: "InBranch", value: "InBranch" },
                { key: "BlackBox", value: "BlackBox" },
                { key: "CreditAssessment", value: "CreditAssessment" },
                {
                    key: "InBranchAndCreditAssessment",
                    value: "InBranchAndCreditAssessment",
                },
            ],
        };
    }

    onFormSubmit(fields: AddLoanProductFields, onReloadFieldsCB: any): boolean {
        try {
            this.setState(
                {
                    errors: authValidationService.validateAddLoanProductForm(fields),
                },
                () => {
                    if (Object.keys(this.state.errors).length === 0) {
                        return this.submit(fields, onReloadFieldsCB);
                    }
                }
            );
            this.setState({ validated: true });
            return false;
        } catch (error) {
            return false;
        }
    }

    async submit(field: AddLoanProductFields, onReloadFieldsCB: any): Promise<boolean> {
        if (this.state.loanProducts.filter((x) => x.isSelected === true).length === 0) {
            toast.info("It looks like you haven't selected any Loan Product!", {
                autoClose: 6000,
                type: toast.TYPE.DEFAULT,
                hideProgressBar: false,
            });
            return false;
        } else {
            try {
                // console.log("starting submission...");
                this.setState({ submitting: true });
                const productsPayLoad = this.state.loanProducts; //.filter((x) => x.isSelected === true || x.availableOnBankOne === true);
                // console.log("payload ::", productsPayLoad);
                productsPayLoad.forEach((prod) => {
                    prod.institutionId = field.institution;
                });
                await loanProductService.saveBulkLoanProducts(productsPayLoad);
                this.setState({ submitting: false });
                toast.info("Loan Products saved successfully!", {
                    autoClose: 6000,
                    type: toast.TYPE.INFO,
                    hideProgressBar: false,
                });
                onReloadFieldsCB();
                this.onHideModal();
                return true;
            } catch (error) {
                // console.log("error: ", error.response.data);
                this.setState({ submitting: false });
                return false;
            }
        }
    }

    handleOnSelectInstitution = async (id: string, value: string) => {
        const externalInstitutionCode = this.props.institutions.filter((x) => x.id === parseInt(value))[0].externalInstitutionCode;
        try {
            this.setState({ fetchingLoanProducts: true });
            // console.log("externalInstitutionCode:: ", externalInstitutionCode)
            const products = await loanProductService.retrieveAllBankOneLoanProducts(externalInstitutionCode);
            const existingProducts = await loanProductService.retrieveAllByInstitutionId(parseInt(value));

            // console.log("products: ", products.data);
            // console.log("ëxisting products: ", existingProducts.data);

            products.data.forEach((prod) => {
                if (existingProducts.data.filter((x) => x.code === prod.code).length > 0) {
                    prod.isSelected = true;
                    prod.availableOnBankOne = existingProducts.data.filter((x) => x.code === prod.code)[0].availableOnBankOne;
                    prod.productUseCase = existingProducts.data.filter((x) => x.code === prod.code)[0].productUseCase;
                } else {
                    prod.isSelected = false;
                }
            });
            this.setState({
                loanProducts: products.data,
                fetchingLoanProducts: false,
            });
        } catch (error) {
        } finally {
            this.setState({ fetchingLoanProducts: false });
        }
    };

    handleOnSelectAll = () => {
        const { selectedAll, loanProducts } = this.state;
        loanProducts.forEach((prod) => {
            prod.isSelected = !selectedAll;
        });
        this.setState({ selectedAll: !selectedAll, loanProducts });
    };

    handleOnSelectProduct = (code: string, isSelected: boolean) => {
        const { loanProducts } = this.state;
        const check = loanProducts.filter((x) => x.code === code)[0];
        const index = loanProducts.indexOf(check);
        check.isSelected = !isSelected;
        loanProducts[index] = check;
        if (loanProducts.filter((x) => x.isSelected === false).length === 0) {
            this.setState({ selectedAll: true, loanProducts });
        } else {
            this.setState({ selectedAll: false, loanProducts });
        }
    };

    handleOnSelectAvailableOnBankOne = (code: string, availableOnBankOne: boolean) => {
        // console.log("selected available on bankone: ", code);
        const { loanProducts } = this.state;
        const check = loanProducts.filter((x) => x.code === code)[0];
        const index = loanProducts.indexOf(check);
        check.availableOnBankOne = !availableOnBankOne;
        loanProducts[index] = check;
        if (loanProducts.filter((x) => x.availableOnBankOne === false).length === 0) {
            this.setState({ loanProducts });
        } else {
            this.setState({ loanProducts });
        }
    };

    handleOnSelectUseCase = (e: React.ChangeEvent<HTMLSelectElement>, code: string) => {
        const { loanProducts } = this.state;
        const check = loanProducts.filter((x) => x.code === code)[0];
        const index = loanProducts.indexOf(check);
        check.productUseCase = e.currentTarget.value;
        loanProducts[index] = check;
        this.setState({ loanProducts });
    };

    onHideModal = () => {
        this.setState({ loanProducts: [] });
        this.props.toggleLoanProductConfigModal();
    };

    render() {
        const { showLoanProductConfigModal, institutions } = this.props;
        const { errors, validated, submitting, selectedAll, fetchingLoanProducts, loanProducts, productUseCases } = this.state;
        return (
            <Modal size="xl" backdrop="static" show={showLoanProductConfigModal} onHide={() => this.onHideModal()}>
                <AddLoanProductConfigForm
                    initialValues={{ institution: "" }}
                    FormComponent={({ fields: { institution }, onChange, onReloadFields, onHandleSubmit }) => (
                        <React.Fragment>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <h5>
                                        <i className="icon-box-filled2 mr-3"></i> Configure Institutions' Loan Product
                                    </h5>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="card no-b">
                                    <div className="card-body">
                                        <h5 className="card-title">CONFIGURATION</h5>
                                        <div className="form-row">
                                            <div className="col-md-12">
                                                <div className="form-row">
                                                    <SelectInputComponent
                                                        id="institution"
                                                        name="institution"
                                                        divClass={6}
                                                        value={institution}
                                                        items={institutions.map((inst) => ({
                                                            name: inst.name,
                                                            value: inst.id,
                                                        }))}
                                                        required={true}
                                                        validated={validated}
                                                        errors={errors}
                                                        onChange={onChange}
                                                        onChangeCallBack={this.handleOnSelectInstitution}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <h6 className="card-title">LOAN PRODUCTS</h6>
                                        <br />
                                        <div className="table-responsize text-nowrap" style={{ height: "300px", overflowY: "scroll" }}>
                                            <table className="table table-hover table-content table-sm table-striped mb-0">
                                                <thead>
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                width: "5px",
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>#</strong>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                width: "5px",
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            {/* <i class="far fa-check-square"></i> */}
                                                            <strong>
                                                                <i
                                                                    className={selectedAll === true ? "far fa-check-square" : "far fa-square"}
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => this.handleOnSelectAll()}
                                                                ></i>
                                                            </strong>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                // width: "5px",
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>Product Name</strong>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                // width: "5px",
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>Product Code</strong>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>Product Type</strong>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>Available On BankOne</strong>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                // width: "5px",
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>Use Case</strong>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {fetchingLoanProducts ? (
                                                        <React.Fragment>
                                                            <tr>
                                                                <td
                                                                    className="font-weight-normal text-truncate"
                                                                    colSpan={7}
                                                                    style={{
                                                                        textAlign: "center",
                                                                        color: "#FE825E",
                                                                        fontSize: "small",
                                                                    }}
                                                                >
                                                                    <i className="fas fa-spinner fa-spin mr-2" />
                                                                </td>
                                                            </tr>
                                                            {/* <Skeleton height={10} width={50} count={4} /> */}
                                                        </React.Fragment>
                                                    ) : loanProducts.length === 0 ? (
                                                        <tr>
                                                            <td
                                                                className="font-weight-normal text-truncate"
                                                                colSpan={6}
                                                                style={{
                                                                    textAlign: "center",
                                                                    color: "#FE825E",
                                                                    fontSize: "small",
                                                                }}
                                                            >
                                                                ...No Products Available
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        loanProducts.map((product) => (
                                                            <tr key={product.code}>
                                                                <td
                                                                    style={{
                                                                        width: "5px",
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                        fontSize: "small",
                                                                    }}
                                                                >
                                                                    {loanProducts.indexOf(product) + 1}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: "5px",
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                        fontSize: "small",
                                                                    }}
                                                                >
                                                                    <i
                                                                        className={product.isSelected ? "far fa-check-square" : "far fa-square"}
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => this.handleOnSelectProduct(product.code, product.isSelected)}
                                                                    ></i>
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                        fontSize: "small",
                                                                    }}
                                                                >
                                                                    {product.name}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                        fontSize: "small",
                                                                    }}
                                                                >
                                                                    {product.code}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                        fontSize: "small",
                                                                    }}
                                                                >
                                                                    {product.productType}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                        fontSize: "small",
                                                                    }}
                                                                >
                                                                    <i
                                                                        className={
                                                                            product.availableOnBankOne === true
                                                                                ? "far fa-check-square"
                                                                                : "far fa-square"
                                                                        }
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() =>
                                                                            this.handleOnSelectAvailableOnBankOne(
                                                                                product.code,
                                                                                product.availableOnBankOne
                                                                            )
                                                                        }
                                                                    ></i>
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                        fontSize: "small",
                                                                    }}
                                                                >
                                                                    <select
                                                                        className="browser-default custom-select form-control-sm"
                                                                        onChange={(e) => this.handleOnSelectUseCase(e, product.code)}
                                                                        value={product.productUseCase}
                                                                        name="institution"
                                                                        style={{
                                                                            height: "32px",
                                                                            fontSize: "0.8rem",
                                                                        }}
                                                                    >
                                                                        {productUseCases.map((usecase) => (
                                                                            <option key={usecase.key} value={usecase.value}>
                                                                                {textFormatService.splitAtUpper(usecase.value)}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    size="sm"
                                    variant="success"
                                    disabled={submitting}
                                    onClick={(e) => {
                                        onHandleSubmit(e, ActionTypes.SAVE, "Loan Product", () => {
                                            this.onFormSubmit({ institution }, onReloadFields);
                                        });
                                    }}
                                >
                                    {submitting === false ? (
                                        <React.Fragment>
                                            <i className="fas fa-lg fa-save mr-3" />
                                            Save
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <i className="fas fa-spin fa-circle-notch" />
                                        </React.Fragment>
                                    )}
                                </Button>
                                <Button size="sm" variant="outline-danger" onClick={() => this.onHideModal()}>
                                    <i className="fas fa-times mr-3" />
                                    Close
                                </Button>
                            </Modal.Footer>
                        </React.Fragment>
                    )}
                ></AddLoanProductConfigForm>
            </Modal>
        );
    }
}

export default LoanProductConfigModal;
