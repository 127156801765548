import { axiosSweepInstance as axiosInstance } from "../configs";

class Fee {
    
    retrieveAll(){
        return axiosInstance.get("/FeeConfig//RetrieveAll")
    }
    retrieveByIdCode(Id:string){
        return axiosInstance.get(`/FeeConfig//RetrieveById/${Id}`);
    } 
    retrieveByInstitutionID(Id:string){
        return axiosInstance.get(`/FeeConfig/RetrieveByInstitutionId/${Id}`);
    } 
    retrieveByInstitutionCode(code:string){
        return axiosInstance.get(`/FeeConfig/RetrieveByInstitutionCode/${code}`);
    } 
}

const feeService = new Fee();
export default feeService;