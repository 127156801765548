import React, { Component, Fragment, useContext } from "react";
import {
    Modal,
    Button,
    Card,
    useAccordionToggle,
    AccordionContext,
    Accordion,
} from "react-bootstrap";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import BaseFormComponent, { IBaseFormState } from "../../../../components/BaseFormComponent";
import textFormatService, { generateGUID } from "../../../../services/utility/textFormatService";
import ItemsTable, { filterFieldData } from "../../../../components/tableComponents/itemsTable";
import TableFilterPane from "../../../../components/tableComponents/tableFilterPane";
import { getCurrentUserSession, getCurrentUsersRoles } from "../../../../services/auth/authService";
// import TinRequestDetails from "../../../../loanAppraisal/appraisalDetailPages/TinRequestDetails";
// import { ContextAwareToggle } from "./documentCollection";
import { decodeToken } from "react-jwt";
import BaseListComponent, { IFilterData } from "../../../../components/BaseListComponent";
import loanProductService from "../../../../services/hybridAppraisal/loanProductService";
import loanRequestService from "../../../../services/hybridAppraisal/loanRequestService";
import { createFilterData, mapEntityList } from "../../../../services/utility/autoMapperService";
import TinValidationListModal from "./tinValidationList";
import PolicyScoringListModal from "./policyScroingList";
import { ContextAwareToggle } from "./creditSummary";
import { symbol } from "joi";
import CreditAnalysisListModal from "./creditAnalysisList";

interface CreditAnalysisFields { }

class CreditAnalysisForm extends BaseFormComponent<CreditAnalysisFields> { }

interface ICreditAnalysisModalProps {
    item: any;
    userRoles: any;
    systemProcess: any;
    currentUserSession: any;
}

interface ICreditAnalysisModalState {
    item: any;
    updatingStatus: boolean;
    submitting: boolean;
    data: IFilterData;
    currentPage: number;
    pageSize: number;
    tableItems: any;
    fetching: boolean;
    fetchingDetail: boolean;
    totalSize: number;
    filterValueInput: filterFieldData;
    userRoles: any;
    systemProcess: any;
    applicableRules: any;
    appraisalActions: any;
    updateComponent: boolean;
    documentsToUpload: any;
    documentsToDelete: number[];
    canEditItem: boolean;
    updateAvailable: boolean;
    processResponse: any;
    specialConditions: any;
    ownUpdate: boolean;
    showCreditAnalysisModal: boolean;
    solidarityGroups: any;
    members: any;
}

class CreditAnalysisList extends BaseListComponent<ICreditAnalysisModalState> { }

class CreditAnalysis extends React.Component<
    ICreditAnalysisModalProps,
    IBaseFormState & ICreditAnalysisModalState
> {
    _isMounted: boolean;
    constructor(props: ICreditAnalysisModalProps) {
        super(props);
        this.state = {
            validated: false,
            submitting: false,
            errors: {},
            item: {},
            updatingStatus: false,
            data: createFilterData(["customerName", "loanType"]) as IFilterData,
            currentPage: 1,
            pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
            fetching: false,
            fetchingDetail: false,
            tableItems: [],
            totalSize: 0,
            filterValueInput: [
                { name: "customerName", fieldType: "text", value: "" },
                {
                    name: "loanType",
                    fieldType: "select",
                    options: ["Individual", "Corporate"],
                    value: "",
                }
            ],
            userRoles: [],
            systemProcess: [],
            applicableRules: [],
            appraisalActions: [],
            documentsToUpload: [],
            documentsToDelete: [],
            updateComponent: false,
            canEditItem: false,
            updateAvailable: false,
            specialConditions: [],
            processResponse: {
                actionId: 0,
                score: 0,
                response: ""
            },
            ownUpdate: false,
            showCreditAnalysisModal: false,
            solidarityGroups: [],
            members: []
        };
    }

    async componentDidMount() {
        this._isMounted = true;
        let applicableRules = this.props.item.financialGroup.rules.filter(
            (rule) =>
                rule.level === this.props.item.currentLevel &&
                rule.roleID === this.props.currentUserSession.userRoleId
        );
        const appraisalActions = this.props.item.appraisalActions;
        const solidarityGroups = this.props.item.solidarityGroups;
        const creditAnalysisMembers = appraisalActions.filter(x => x.systemProcessID === Number(10))[0]?.memberProcessResponses


        if (solidarityGroups) {
            for (let index = 0; index < solidarityGroups.length; index++) {
                if (solidarityGroups[index].logType !== "SystemType") {
                    solidarityGroups[index].memberCount = creditAnalysisMembers.filter(x => x.solidarityGroupId === solidarityGroups[index].id).length;
                    solidarityGroups[index].memberCreditAnalysisPassCount = creditAnalysisMembers ? creditAnalysisMembers.filter(x => x.solidarityGroupId === solidarityGroups[index].id && x.result === "Pass").length : 0;
                    solidarityGroups[index].memberCreditAnalysisFailCount = creditAnalysisMembers ? Number(solidarityGroups[index].memberCount - solidarityGroups[index].memberCreditAnalysisPassCount) : solidarityGroups[index].members.length;
                }
            }


            this.setState({ solidarityGroups, ownUpdate: true });

            // console.log(solidarityGroups)
        }
    }

    resetFields() {
        this.setState({
            validated: false,
            submitting: false
        });
    }

    fetchItemById = (id) => {
        var members = this.props.item.solidarityGroups.filter(x => x.id === id)[0]?.members;
        this.setState({ members: members });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleShowCreditAnalysisModal = () => {
        this.setState({ showCreditAnalysisModal: !this.state.showCreditAnalysisModal });
    };

    handleOnExport = async (type: string) => { };

    render() {
        const { item, userRoles, systemProcess, currentUserSession } = this.props;
        const {
            currentPage,
            pageSize,
            tableItems,
            totalSize,
            fetching,
            fetchingDetail,
            submitting,
            showCreditAnalysisModal,
            members
        } = this.state;

        if (_.isEmpty(item)) {
            return null;
        }

        return (
            <CreditAnalysisForm
                initialValues={{}}
                FormComponent={({
                    fields: { }, onChange, onReloadFields, onHandleSubmit,
                }) => (
                    <div className="card">
                        <div className="card-header clear-fix">
                            {_.isEmpty(item) ? (
                                <Skeleton width={200} />
                            ) : (
                                <h6
                                    className="card-title float-left"
                                    style={{ marginBottom: "0px" }}
                                >
                                    <b>Credit Analysis</b>
                                </h6>
                            )}
                        </div>
                        <div className="card">
                            <Accordion defaultActiveKey="0">
                                <div>
                                    <Card>
                                        <ContextAwareToggle
                                            eventKey={`0`}
                                            header={<React.Fragment>
                                                <i
                                                    className="fa fa-info-circle"
                                                    aria-hidden="true"
                                                    style={{ color: "#878a8a" }}
                                                ></i>{" "}
                                                Credit Analysis Result
                                            </React.Fragment>}
                                        ></ContextAwareToggle>
                                        <Accordion.Collapse eventKey={`0`}>
                                            <Card.Body>
                                                <div
                                                    className="table-responsive text-nowrap"
                                                    style={{
                                                        height: "300px",
                                                        overflowY: "scroll",
                                                    }}
                                                >

                                                    {/* <h6 className="center">Appraisal Actions</h6> */}
                                                    <table className="table table-hover table-content table-sm table-striped mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th
                                                                    scope="col"
                                                                    style={{
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                    }}
                                                                >
                                                                    <strong>Total Members</strong>
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    style={{
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                    }}
                                                                >
                                                                    <strong>Credit Analysis Pass</strong>
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    style={{
                                                                        // width: "5px",
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                    }}
                                                                >
                                                                    <strong>Credit Analysis Fail</strong>
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    style={{
                                                                        // width: "5px",
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                    }}
                                                                >
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {/* {recipients.length === 0 ? ( */}
                                                            {item.appraisalActions.length === 0 ? (
                                                                <React.Fragment>
                                                                    <tr>
                                                                        No Decisions Made yet
                                                                    </tr>
                                                                </React.Fragment>
                                                            ) : (
                                                                item.solidarityGroups.map((groups) => (
                                                                    <React.Fragment key={generateGUID()}>
                                                                        <tr>
                                                                            <td
                                                                                style={{
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                    textAlign: "center"
                                                                                }}
                                                                            >
                                                                                {groups.memberCount}
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                    textAlign: "center"
                                                                                }}
                                                                            >
                                                                                {Number(groups.memberCreditAnalysisPassCount)}
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                    textAlign: "center"
                                                                                }}
                                                                            >
                                                                                {Number(groups.memberCreditAnalysisFailCount)}
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                }}
                                                                            >
                                                                                <button
                                                                                    key={groups.id}
                                                                                    className="btn btn-sm btn-outline-primary my-2 mx-3"
                                                                                    style={{ cursor: "pointer" }}
                                                                                    type="button"
                                                                                    // onClick={this.handleUploadDocument}
                                                                                    onClick={(e) => {
                                                                                        this.fetchItemById(groups.id);
                                                                                        this.toggleShowCreditAnalysisModal();
                                                                                    }}
                                                                                >
                                                                                    <React.Fragment>
                                                                                        <i className="fas fa-users fa-lg mr-2" />{" "}
                                                                                        {groups.solidarityGroupName}
                                                                                    </React.Fragment>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                        {/* ))} */}
                                                                    </React.Fragment>
                                                                ))
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </div>
                            </Accordion>
                        </div>
                        <CreditAnalysisListModal
                            item={item}
                            members={members}
                            userRoles={userRoles}
                            systemProcess={systemProcess}
                            showPatchModal={this.state.showCreditAnalysisModal}
                            togglePatchModal={this.toggleShowCreditAnalysisModal}
                            currentUserSession={getCurrentUserSession}
                        />
                    </div>

                )}
            ></CreditAnalysisForm>
        );
    }
}

export default CreditAnalysis;
