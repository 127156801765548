import { axiosReportInstance as axiosInstance } from "./configs";

class ReportsCenter {
    getCollectionScheduleReport(params:any){
        return axiosInstance.get("/collection-schedule-report",{
            params
        })
    }
    getCollectionsReport(params:any){
        return axiosInstance.get("/collections-report",{
            params
        })
    }
    getCollectionsSummaryReport(params:any){
        return axiosInstance.get("/collection-summary-report",{
            params
        })
    }
    getgetRecoveryReport(params:any){
        return axiosInstance.get("/recovery-report",{
            params
        })
    }
    getgetRecoverySummaryReport(params:any){
        return axiosInstance.get("/recovery-summary-report",{
            params
        })
    }
}

const reportsService = new ReportsCenter();
export default reportsService;