import React from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import swal from "sweetalert";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import DetailItemsTable from "../../../components/tableComponents/detailItemsTable";
import mandateService from "../../../services/mandate/mandateService";
import { mapEntityList } from "../../../services/utility/autoMapperService";
import textFormatService from "../../../services/utility/textFormatService";
import { ActionTypes } from "../../../enums/actionTypes";
import { getCurrentUserSession } from "../../../services/auth/authService";
import { decodeToken } from "react-jwt";

export interface IMandateUploadRequestDetailProps {
    showDetailsModal: boolean;
    toggleDetailsModal: any;
    currentUserRoles: any;
    item: any;
    reloadItem: any;
}

export interface IMandateUploadRequestDetailState {
    updatingStatus: boolean;
    requestMandates: any;
    requestMandatesCurrentPage: number;
    requestMandatesFetching: boolean;
    requestMandatesCount: number;
    showMandateInfoModal: boolean;
    mandate: any;
    mandateLoanApplicationForm: any;
    mandateRightOfSetOff: any;
    downloadLink: string;
}

class MandateUploadRequestDetail extends React.Component<IMandateUploadRequestDetailProps, IMandateUploadRequestDetailState> {
    constructor(props: IMandateUploadRequestDetailProps) {
        super(props);
        this.state = {
            updatingStatus: false,
            requestMandates: [],
            requestMandatesCurrentPage: 1,
            requestMandatesCount: 0,
            requestMandatesFetching: false,
            showMandateInfoModal: false,
            mandate: {},
            mandateLoanApplicationForm: {},
            mandateRightOfSetOff: {},
            downloadLink: "",
        };
    }

    renderRequestStatus = (status: string) => {
        switch (status) {
            case "Approved":
                return <span className="badge badge-success">{status}</span>;
            case "Pending":
                return <span className="badge badge-warning">{status}</span>;
            case "Cancelled":
                return <span className="badge badge-danger">{status}</span>;
            default:
                return <span className="badge badge-light">{status}</span>;
        }
    };

    retrieveROSPayload = (mandate: any) => {
        let data;
        switch (mandate.data.rightOfSetOffExtensionType) {
            case "PNG":
            case "JPG":
            case "GIF":
                data = `data:image/jpg;base64,${mandate.data.rightOfSetOff}`;
                return <img src={data} alt={mandate.data.customerName} width="100%" height="100%" className="image" />;
            case "DOCX":
                data = `application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${mandate.data.rightOfSetOff}`;
                return (
                    <object
                        width="100%"
                        height="500px"
                        data={data}
                        type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        className="internal"
                    >
                        <embed src={data} type="application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                    </object>
                );
            case "PDF":
                data = `data:application/pdf;base64,${mandate.data.rightOfSetOff}`;
                return (
                    <object width="100%" height="500px" data={data} type="application/pdf" className="internal">
                        <embed src={data} type="application/pdf" />
                    </object>
                );
            case "HTML":
                return (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: atob(mandate.data.rightOfSetOff),
                        }}
                    />
                );
            default:
                return "";
        }
    };

    retrieveLAFPayload = (mandate: any) => {
        let data;
        // let payload1;
        switch (mandate.data.loanApplicationFormExtensionType) {
            case "PNG":
            case "JPG":
            case "GIF":
                data = `data:image/jpg;base64,${mandate.data.loanApplicationForm}`;
                return <img src={data} alt={mandate.data.customerName} width="100%" height="100%" className="image" />;
            case "DOCX":
                data = `application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${mandate.data.loanApplicationForm}`;
                return (
                    <object
                        width="100%"
                        height="500px"
                        data={data}
                        type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        className="internal"
                    >
                        <embed src={data} type="application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                    </object>
                );
            case "PDF":
                data = `data:application/pdf;base64,${mandate.data.loanApplicationForm}`;
                return (
                    <object width="100%" height="500px" data={data} type="application/pdf" className="internal">
                        <embed src={data} type="application/pdf" />
                    </object>
                );
            case "HTML":
                return (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: atob(mandate.data.loanApplicationForm),
                        }}
                    />
                );
            default:
                return "";
        }
    };

    fetchMandateInfoById = async (item) => {
        const mandate = await mandateService.retrieveBulkMandateById(item.id);

        const mandateLoanApplicationForm = {
            document: mandate.data.loanApplicationForm,
            extensionType: mandate.data.loanApplicationFormExtensionType,
            payload: this.retrieveLAFPayload(mandate),
        };

        const mandateRightOfSetOff = {
            document: mandate.data.rightOfSetOff,
            extensionType: mandate.data.rightOfSetOffExtensionType,
            payload: this.retrieveROSPayload(mandate),
        };

        this.setState({
            mandate: mandate.data,
            mandateLoanApplicationForm,
            mandateRightOfSetOff,
        });
    };

    handleOnToggleMandateInfoModal = async (item) => {
        this.setState({ showMandateInfoModal: !this.state.showMandateInfoModal });
        if (this.state.showMandateInfoModal === false) {
            this.fetchMandateInfoById(item);
        } else {
            this.setState({ mandate: {} });
        }
    };

    handleOnDetailPageChange = async (currentPage: number) => {
        this.setState({ requestMandatesFetching: true });
        try {
            // console.log(
            //   "about to fetch request mandates: ",
            //   this.state.requestMandatesFetching
            // );
            const request: any = {};
            // request["institutionId"] = {
            //   item1: "=",
            //   item2: getCurrentUserSession().institutionId.toString(),
            // };
            request["requestId"] = {
                item1: "=",
                item2: this.props.item.requestId,
            };
            const mandates = await mandateService.filterUploadRequestMandates(
                request,
                Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
                currentPage
            );
            // console.log("mandates: ", mandates);
            this.setState({
                requestMandates: mapEntityList({
                    entityList: mandates.data.item2,
                    properties: [
                        { oldName: "customerName", newName: "Name" },
                        { oldName: "bvn", newName: "BVN" },
                        { oldName: "loanTenure", newName: "Tenure" },
                        { oldName: "loanAmount", newName: "loanAmount" },
                        { oldName: "loanReference", newName: "loanReference" },
                    ],
                }),
                requestMandatesCurrentPage: currentPage,
                requestMandatesCount: mandates.data.item1,
                requestMandatesFetching: false,
            });
        } catch (error) {
            // this.setState({ requestMandatesFetching: true });
        } finally {
            this.setState({ requestMandatesFetching: false });
        }
    };

    handleOnConfirmApproveOrDeclineRequest = (actionType: ActionTypes, item: any) => {
        swal({
            title: "Confirm!",
            text: `Are you sure you want to proceed to ${ActionTypes[actionType]} this Request?`,
            icon: "warning",
            buttons: {
                confirm: {
                    text: "Yes",
                    value: null,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                cancel: {
                    text: "Cancel",
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
            },
            closeOnClickOutside: false,
            dangerMode: true,
        }).then((resp) => {
            if (resp) {
                // console.log("cancelling action...");
            } else {
                this.setState({ updatingStatus: true });
                this.handleOnApproveOrDeclineRequest(actionType, item);
                this.setState({ updatingStatus: false });
            }
        });
    };

    handleOnApproveOrDeclineRequest = async (actionType: ActionTypes, item: any) => {
        this.setState({ updatingStatus: true });
        try {
            switch (actionType) {
                case ActionTypes.APPROVE:
                    item.status = "Approved";
                    await mandateService.updateBulkMandateRequest(item.id, item);
                    break;
                case ActionTypes.DECLINE:
                    item.status = "Cancelled";
                    await mandateService.updateBulkMandateRequest(item.id, item);
                    break;
                default:
                    item.status = "Approved";
                    await mandateService.updateBulkMandateRequest(item.id, item);
                    break;
            }
            this.setState({ updatingStatus: false });
            this.props.reloadItem(item);
        } catch (ex) {
            this.setState({ updatingStatus: false });
        }
    };

    async componentDidMount() {
        try {
            this.handleOnDetailPageChange(this.state.requestMandatesCurrentPage);
        } catch (error) {
            this.setState({ requestMandatesFetching: false });
        }
    }

    render() {
        const { showDetailsModal, toggleDetailsModal, item } = this.props;
        const decodedToken = decodeToken(getCurrentUserSession().token || "");
        const {
            updatingStatus,
            requestMandates,
            requestMandatesCurrentPage,
            requestMandatesFetching,
            requestMandatesCount,
            showMandateInfoModal,
            mandate,
            mandateLoanApplicationForm,
            mandateRightOfSetOff,
            downloadLink,
        } = this.state;
        const theDownloadLink = process.env.REACT_APP_MANDATE_BASEADDRESS + `Mandate/DownloadBulkMandateRequestDocument/${item.id}`;
        return (
            <React.Fragment>
                <Modal
                    size="xl"
                    backdrop="static"
                    show={showDetailsModal}
                    // onShow={() =>
                    //   this.handleOnDetailPageChange(this.state.requestMandatesCurrentPage)
                    // }
                    onHide={toggleDetailsModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {_.isEmpty(item) ? (
                                <Skeleton width={500} />
                            ) : (
                                <h5>
                                    <i className="fab fa-first-order-alt fa-sm cyan-text mr-3" />
                                    Mandate Upload Request Details - <span className="font-weight-bold">{item.requestId}</span>
                                </h5>
                            )}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="card">
                            <div className="card-header clear-fix">
                                {_.isEmpty(item) ? (
                                    <Skeleton width={200} />
                                ) : (
                                    <h6 className="card-title float-left" style={{ marginBottom: "0px" }}>
                                        General Information
                                    </h6>
                                )}
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        {_.isEmpty(item) ? (
                                            <Skeleton count={4} width={300} />
                                        ) : (
                                            <dl className="row">
                                                <DetailItem
                                                    label="Institution Name"
                                                    // labelSize="7"
                                                    // valueSize="5"
                                                    value={item.institutionName}
                                                />
                                                <DetailItem label="Request Initiator" value={item.requestInitiator} />
                                                <DetailItem
                                                    label="Request Id"
                                                    // labelSize="7"
                                                    // valueSize="5"
                                                    value={item.requestId}
                                                />
                                                <DetailItem
                                                    label="Total Number Of Mandates"
                                                    // labelSize="7"
                                                    // valueSize="5"
                                                    value={item.noOfMandates}
                                                />
                                                <DetailItem
                                                    label="Date Created"
                                                    // labelSize="7"
                                                    // valueSize="5"
                                                    value={item.dateCreated}
                                                />
                                                <DetailItem
                                                    label="Status"
                                                    // labelSize="7"
                                                    // valueSize="5"
                                                    value={this.renderRequestStatus(item.status)}
                                                />
                                                <a href={theDownloadLink} target="_blank" rel="noopener noreferrer">
                                                    <i
                                                        className="icon-cloud-download mr-3"
                                                        style={{ marginTop: "3px", marginLeft: "18px", marginRight: "10px" }}
                                                    />
                                                    Download uploaded document [{item.documentName}]
                                                </a>
                                            </dl>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <DetailItemsTable
                                title="Mandates"
                                item={item}
                                handleOnPageChange={this.handleOnDetailPageChange}
                                content={requestMandates}
                                contentCount={requestMandatesCount}
                                page={requestMandatesCurrentPage}
                                fetching={requestMandatesFetching}
                                amountColumn={["loanAmount", "loanAmount"]}
                                hasDetailModal={true}
                                onViewDetails={this.handleOnToggleMandateInfoModal}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {_.isEmpty(item) ? (
                            <Skeleton width={100} count={2} height={30} />
                        ) : item.status === "Pending" ? (
                            decodedToken?.["InstitutionId"] === "1" ? (
                                <React.Fragment>
                                    {requestMandates.length > 0 && (
                                        <>
                                            <Button
                                                size="sm"
                                                variant="outline-success"
                                                disabled={updatingStatus}
                                                onClick={(e) => this.handleOnConfirmApproveOrDeclineRequest(ActionTypes.APPROVE, item)}
                                            >
                                                {updatingStatus ? (
                                                    <i className="fas fa-spinner fa-spin" />
                                                ) : (
                                                    <React.Fragment>
                                                        <i className="fas fa-check-circle mr-3" />
                                                        Approve
                                                    </React.Fragment>
                                                )}
                                            </Button>
                                            <Button
                                                size="sm"
                                                variant="outline-warning"
                                                disabled={updatingStatus}
                                                onClick={(e) => this.handleOnConfirmApproveOrDeclineRequest(ActionTypes.DECLINE, item)}
                                            >
                                                {updatingStatus ? (
                                                    <i className="fas fa-spinner fa-spin" />
                                                ) : (
                                                    <React.Fragment>
                                                        <i className="fas fa-times-circle mr-3" />
                                                        Decline
                                                    </React.Fragment>
                                                )}
                                            </Button>
                                        </>
                                    )}
                                </React.Fragment>
                            ) : (
                                ""
                            )
                        ) : (
                            ""
                        )}
                        <Button size="sm" variant="outline-danger" onClick={toggleDetailsModal}>
                            <i className="fas fa-times mr-3" />
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal size="xl" backdrop="static" show={showMandateInfoModal} onHide={this.handleOnToggleMandateInfoModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {_.isEmpty(mandate) ? (
                                <Skeleton width={500} />
                            ) : (
                                <h5>
                                    <i className="fab fa-first-order-alt fa-sm cyan-text mr-3" />
                                    Mandate Request Item Details - <span className="font-weight-bold">{mandate.customerName}</span>
                                </h5>
                            )}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="card">
                            <div className="card-header clear-fix">
                                {_.isEmpty(mandate) ? (
                                    <Skeleton width={200} />
                                ) : (
                                    <h6 className="card-title float-left" style={{ marginBottom: "0px" }}>
                                        General Information
                                    </h6>
                                )}
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        {_.isEmpty(mandate) ? (
                                            <Skeleton count={4} width={300} />
                                        ) : (
                                            <dl className="row">
                                                <DetailItem label="Name" value={mandate.customerName} />
                                                <DetailItem label="BVN" value={mandate.bvn} />
                                                <DetailItem label="Loan Reference" value={mandate.loanReference} />
                                                <DetailItem label="Loan Amount" value={textFormatService.convertKoboToNaira(mandate.loanAmount, 2)} />
                                            </dl>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        {_.isEmpty(mandate) ? (
                                            <Skeleton count={4} width={300} />
                                        ) : (
                                            <dl className="row">
                                                <DetailItem label="Phone Number" value={mandate.phoneNumber} />
                                                <DetailItem label="Identifier Type" value={mandate.identifierType} />
                                                <DetailItem label="Repayment Account" value={mandate.linkedAccountNumber} />
                                                <DetailItem label="Mandate Type" value={mandate.mandateType} />
                                            </dl>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="card">
                            <div className="card-header clear-fix">
                                {_.isEmpty(mandate) ? (
                                    <Skeleton width={200} />
                                ) : (
                                    <h6 className="card-title float-left" style={{ marginBottom: "0px" }}>
                                        Loan Application Form
                                    </h6>
                                )}
                            </div>
                            <div className="card-body">
                                <br />
                                {mandateLoanApplicationForm.payload}
                            </div>
                        </div>
                        <br />
                        <div className="card">
                            <div className="card-header clear-fix">
                                {_.isEmpty(mandate) ? (
                                    <Skeleton width={200} />
                                ) : (
                                    <h6 className="card-title float-left" style={{ marginBottom: "0px" }}>
                                        Right Of Set Off
                                    </h6>
                                )}
                            </div>
                            <div className="card-body">
                                <br />
                                {mandateRightOfSetOff.payload}
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default MandateUploadRequestDetail;
