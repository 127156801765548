import React, { Component } from "react";

export interface IDisableUserApprovalDetailProps {
  item: any;
}

export interface IDisableUserApprovalDetailState {
  item: any;
}

class DisableUserApprovalDetail extends Component<
  IDisableUserApprovalDetailProps,
  IDisableUserApprovalDetailState
> {
  constructor(props: IDisableUserApprovalDetailProps) {
    super(props);
    this.state = {
      item: {},
    };
  }

  static getDerivedStateFromProps(
    nextProps: IDisableUserApprovalDetailProps,
    prevState
  ) {
    if (nextProps.item) {
      return { item: JSON.parse(nextProps.item) };
    }
    return null;
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <h6>Disable User Approval Detail</h6>
        </div>
      </React.Fragment>
    );
  }
}

export default DisableUserApprovalDetail;
