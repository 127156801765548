import http from "../httpService";

const billingBaseAddress = process.env.REACT_APP_BILLING_BASEADDRESS;

export function saveThirdPartyFeeConfig(feeConfig) {
  return http.post(
    billingBaseAddress + `ThirdPartyFeeConfiguration`,
    feeConfig
  );
}

export function retrieveThirdPartyFeeConfigById(id: string) {
  return http.get(
    billingBaseAddress + `ThirdPartyFeeConfiguration/${id}`
  );
}

export function updateThirdPartyFeeConfig(id, feeConfig) {
  return http.put(
    billingBaseAddress + `ThirdPartyFeeConfiguration/configuration/Edit/${id}`,
    feeConfig
  );
}

export function filterThirdPartyFeeConfig(serviceOffering: string, institutionCategory: string, thirdParty: string) {
  return http.get(
    billingBaseAddress +
    `ThirdPartyFeeConfiguration/fees/filter?ServiceOffering=${serviceOffering}&InstitutionCategory=${institutionCategory}&ThirdParty=${thirdParty}`
  );
}


export default {
  saveThirdPartyFeeConfig,
  retrieveThirdPartyFeeConfigById,
  updateThirdPartyFeeConfig,
  filterThirdPartyFeeConfig,
};
