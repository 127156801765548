import React, { Component, useContext } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import { convertKoboToNaira } from "../../../../../services/utility/textFormatService";
import {
  getSupportingDocumentsByLoanReference,
  retrieveById,
} from "./../../../../../services/mandate/supportingDocumentService";
import { Accordion, Card } from "react-bootstrap";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import textFormatService from "../../../../../services/utility/textFormatService";
import DetailItem from "../../../../../components/detailItem";
import DocumentPreviewer from "../../../../dispute/documentPreviewer";
import { ProductType } from "../../../../../enums/productType";
import { LoanComputationMode } from "../../../../../enums/loanComputationMode";
import { EconomicSector } from "../../../../../enums/economicSector";
import { LoanPaymentScheduleType } from "../../../../../enums/loanPaymentScheduleType";
import ModifyLoanParams from "./modifyLoanParams";
import AddSupportingDocument from "./addSupportingDocument";
import sweepConfigService from "../../../../../services/sweep/sweepConfigService";

function ContextAwareToggle({ eventKey, header }) {
  const currentEventKey = useContext(AccordionContext);

  const toggleOnClick = useAccordionToggle(eventKey);

  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";

  return (
    <Card.Header
      onClick={toggleOnClick}
      style={{ cursor: "pointer", padding: "4px" }}
    >
      <b>{header}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
}

export interface IBankOneLoanItemApprovalDetailProps {
  item: any;
  financialGroup: any;
  updateItem: any;
  updateItemDocs: any;
  deleteItemDocs: any;
  tempDocs: any;
  tempDeletedDocs: number[];
  canEditItem: boolean;
}

export interface IBankOneLoanItemApprovalDetailState {
  item: any;
  document: any;
  showPreview: boolean;
  showPatchModal: boolean;
  showDocPatchModal: boolean;
  loanDetails: any;
  supportingDocuments: any;
  fetchingSupportingDocuments: boolean;
  repaymentBankName: string;
}

class BankOneLoanItemApprovalDetail extends Component<
  IBankOneLoanItemApprovalDetailProps,
  IBankOneLoanItemApprovalDetailState
> {
  constructor(props: IBankOneLoanItemApprovalDetailProps) {
    super(props);
    this.state = {
      item: {},
      document: {},
      showPreview: false,
      showPatchModal: false,
      showDocPatchModal: false,
      loanDetails: {},
      supportingDocuments: [],
      fetchingSupportingDocuments: true,
      repaymentBankName: ""
    };
  }

  // static async getDerivedStateFromProps(
  //   nextProps: IBankOneLoanItemApprovalDetailProps,
  //   prevState
  // ) {
  //   if (nextProps.item) {
  //     // console.log("props payload: ", nextProps.item);
  //     const item = JSON.parse(JSON.parse(nextProps.item));
  //     const loanDetails = JSON.parse(
  //       JSON.parse(JSON.parse(nextProps.item)).LoanDetails
  //     );
  //     console.log("loan details: ", loanDetails);

  //     if (!_.isEmpty(loanDetails.AccountNumber)) {
  //       try {
  //         // console.log("loan reference: ", loanDetails.AccountNumber);
  //         const supportingDocuments = await getSupportingDocumentsByLoanReference(
  //           loanDetails.AccountNumber,
  //           0
  //         );
  //         console.log("supportingDocuments: ", supportingDocuments);
  //         return {
  //           supportingDocuments: supportingDocuments.data,
  //           fetchingSupportingDocuments: false,
  //           item: item,
  //           loanDetails: loanDetails,
  //         };
  //       } catch (error) {
  //         console.log("error fetching supporting documents");
  //         return {
  //           fetchingSupportingDocuments: false,
  //           item: item,
  //           loanDetails: loanDetails,
  //         };
  //       }
  //     }
  //   }
  //   return null;
  // }

  static getDerivedStateFromProps(
    nextProps: IBankOneLoanItemApprovalDetailProps,
    prevState
  ) {
    if (nextProps.item) {
      // console.log("raw :: ", nextProps.item);
      // console.log("partially processed :: ", JSON.parse(nextProps.item));
      // console.log("Fully processed :: ", JSON.parse(JSON.parse(nextProps.item)));
      return { item: JSON.parse(JSON.parse(nextProps.item)) };
    }
    return null;
  }

  async loadDocs(nextProps: IBankOneLoanItemApprovalDetailProps) {
    // console.log("props payload: ", nextProps.item);
    // const item = JSON.parse(JSON.parse(nextProps.item));

    const stateItem = {...this.state.item}
    // console.log("state Item :: ", stateItem)
    let institutionName = "";
    if(!_.isEmpty(stateItem.PreferredRepaymentBankCBNCode)){
      try {
        institutionName = await (await sweepConfigService.retrieveBankNameByCBNCode(stateItem.PreferredRepaymentBankCBNCode)).data;
      } catch (error) {}
    }
    // console.log(institutionName)

    const loanDetails = JSON.parse(
      JSON.parse(JSON.parse(nextProps.item)).LoanDetails
    );
    // console.log("loan details: ", loanDetails);

    if (!_.isEmpty(loanDetails.AccountNumber)) {
      try {
        // console.log("loan reference: ", loanDetails.AccountNumber);
        const supportingDocuments = await getSupportingDocumentsByLoanReference(
          loanDetails.AccountNumber,
          0
        );
        // console.log("supportingDocuments: ", supportingDocuments);

        //add new docs to this array
        let supportingDocs = supportingDocuments.data
        if(nextProps.tempDocs.length > 0){
          nextProps.tempDocs.forEach(doc => supportingDocs.push(doc));
        }

        //remove docs from array if they have been deleted temporarily
        if(nextProps.tempDeletedDocs.length > 0){
          supportingDocs = supportingDocs.filter((doc) => {
            return !nextProps.tempDeletedDocs.some((deletedDocId) => {
              return doc.id === deletedDocId;
            });
          });          
        }

        this.setState({
          supportingDocuments: supportingDocs,
          fetchingSupportingDocuments: false,
          loanDetails,
          repaymentBankName: institutionName
        });
      } catch (error) {
        // console.log("error fetching supporting documents");
        this.setState({ fetchingSupportingDocuments: false, loanDetails });
      }
    }else{
      this.setState({repaymentBankName: institutionName})
    }
  }

  toggleShowPreview = () => {
    this.setState({ showPreview: !this.state.showPreview });
  };

  toggleShowModal = () => {
    this.setState({ showPatchModal: !this.state.showPatchModal });
  };

  toggleShowDocModal = () => {
    this.setState({ showDocPatchModal: !this.state.showDocPatchModal });
  };

  handleDeleteDocument = (document) => {
    // const payload = this.state.item;
    // const loanDetails = JSON.parse(payload.LoanDetails);

    // Update Loan Details 
    // if(document.id === undefined){
    //   let selectedDoc = loanDetails.SupportingDocuments.filter(doc=> doc.document===document.document && doc.name===document.name)[0]
    //   loanDetails.SupportingDocuments.splice(loanDetails.SupportingDocuments.indexOf(selectedDoc), 1);
    // }else{
    //   let selectedDoc = loanDetails.SupportingDocuments.filter(doc=> doc.name===document.name)[0]
    //   loanDetails.SupportingDocuments.splice(loanDetails.SupportingDocuments.indexOf(selectedDoc), 1);
    // }
    
    // Update Item next
    // payload.LoanDetails = JSON.stringify(loanDetails);
    
    // console.log("For checks::", payload);
    
    if(document.id === undefined){
      this.props.deleteItemDocs(document, -1);
    }else{
      this.props.deleteItemDocs(null, document.id);
    }
  };
  
  handleOnDocumentSelect = async (document) => {
    try {
      // console.log("document ",document)
      if(document.document == null){
        var doc = await retrieveById(document.id);
        document["document"] = doc.data.document;
      }
      switch (document.extensionType) {
        case "PNG":
        case "JPG":
        case "GIF":
          document[
            "downloadname"
          ] = `${document.name}_${document.loanReference}.jpg`;
          document["data"] = `data:image/jpg;base64,${document.document}`;
          document["payload"] = (
            <img
              src={document.data}
              alt={document.name}
              width="100%"
              height="100%"
              className="image"
            />
          );
          break;
        case "DOCX":
        case "PDF":
          var data = `data:application/pdf;base64,${document.document}`;
          document["payload"] = (
            <object
              width="100%"
              height="500px"
              data={data}
              type="application/pdf"
              className="internal"
            >
              <embed src={data} type="application/pdf" />
            </object>
          );
          break;
        case "HTML":
          document["payload"] = (
            <div
              dangerouslySetInnerHTML={{
                __html: atob(document.document),
              }}
            />
          );
          break;
        default:
          break;
      }
      this.setState({ document, showPreview: true });
    } catch (error) {
      // console.log(error);
    }
  };

  generateExtensionIcon = (document) => {
    switch (document.extensionType) {
      case "PNG":
      case "JPG":
      case "GIF":
        return (
          <i
            className="fas fa-file-image cyan-text fa-lg"
            style={{ cursor: "pointer" }}
            onClick={(e) => this.handleOnDocumentSelect(document)}
          ></i>
        );
      case "PDF":
        return (
          <i
            className="fas fa-file-pdf red-text fa-lg"
            style={{ cursor: "pointer" }}
            onClick={(e) => this.handleOnDocumentSelect(document)}
          ></i>
        );
      case "DOC":
      case "DOCX":
        return (
          <i
            className="fas fa-file-word blue-text fa-lg"
            style={{ cursor: "pointer" }}
            onClick={(e) => this.handleOnDocumentSelect(document)}
          ></i>
        );
      default:
        return (
          <i
            className="fas fa-file-pdf red-text fa-lg"
            style={{ cursor: "pointer" }}
            onClick={(e) => this.handleOnDocumentSelect(document)}
          ></i>
        );
    }
  };

  render() {
    const {
      item,
      loanDetails,
      fetchingSupportingDocuments,
      supportingDocuments,
      showPatchModal,
      showDocPatchModal,
      repaymentBankName
    } = this.state;

    if (fetchingSupportingDocuments && !_.isEmpty(item)) {
      this.loadDocs(this.props);
    }
    // console.log("Props :: ", item)

    return (
      <React.Fragment>
        <div
          className="table-responsive text-nowrap"
          style={{ height: "250px", overflowY: "scroll" }}
        >
          <Accordion defaultActiveKey="0">
            <div>
              <Card>
                <ContextAwareToggle
                  eventKey={"0"}
                  header={
                    <React.Fragment>
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        style={{ color: "#878a8a" }}
                      ></i>{" "}
                      Product Information
                    </React.Fragment>
                  }
                ></ContextAwareToggle>
                <Accordion.Collapse eventKey={"0"}>
                  <Card.Body>
                    <div
                      className="card-body"
                      style={{ paddingTop: "0px", paddingBottom: "0px" }}
                    >
                      {_.isEmpty(item) ||
                        fetchingSupportingDocuments ||
                        fetchingSupportingDocuments ? (
                        <div className="row">
                          <Skeleton width={450} height={20} />
                          <Skeleton width={450} height={20} />
                        </div>
                      ) : (
                        <dl className="row" style={{ marginBottom: "0px" }}>
                          <DetailItem
                            label="Product Name"
                            value={loanDetails.Product.Name}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Product Code"
                            value={loanDetails.Product.Code}
                            labelSize={6}
                            valueSize={6}
                          />
                          <DetailItem
                            label="Product Type"
                            value={textFormatService.splitAtUpper(
                              ProductType[loanDetails.Product.ProductType]
                            )}
                            labelSize={6}
                            valueSize={6}
                          />
                        </dl>
                      )}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </div>
          </Accordion>
          <Accordion defaultActiveKey="0">
            <div>
              <Card>
                <ContextAwareToggle
                  eventKey={"0"}
                  header={
                    <React.Fragment>
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        style={{ color: "#878a8a" }}
                      ></i>{" "}
                      Account Information 
                      <button
                        type="button"
                        className="btn btn-sm btn-primary waves-effect sm-doc"
                        disabled={!this.props.canEditItem}
                        style={{ margin: "5px", padding: "2px 10px 2px 10px" }}
                        onClick={() => this.toggleShowModal()}
                      >
                        <i className="fas fa-edit mr-2" />
                        Edit
                      </button>
                    </React.Fragment>
                  }
                ></ContextAwareToggle>
                <Accordion.Collapse eventKey={"0"}>
                  <Card.Body>
                    <div
                      className="card-body"
                      style={{ paddingTop: "0px", paddingBottom: "0px" }}
                    >
                      {_.isEmpty(item) || fetchingSupportingDocuments ? (
                        <div className="row">
                          <Skeleton width={450} height={20} />
                          <Skeleton width={450} height={20} />
                          <Skeleton width={450} height={20} />
                          <Skeleton width={450} height={20} />
                          <Skeleton width={450} height={20} />
                        </div>
                      ) : (
                        <div>
                          <dl className="row" style={{ marginBottom: "0px" }}>
                            <DetailItem
                              label="Account Name"
                              value={loanDetails.Name}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Account Number"
                              value={loanDetails.Number}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Customer Name"
                              value={loanDetails.CustomerName}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Account Officer"
                              value={loanDetails.AccountOfficer}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Bank Verification Number"
                              value={item.BVN}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Branch"
                              value={loanDetails.Branch}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Moratorium"
                              value={loanDetails.Moratarium + " Days"}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Defaulting Interest"
                              value={loanDetails.DefaultingLoanInterest + "%"}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Type Of Loan"
                              value={textFormatService.splitAtUpper(
                                LoanComputationMode[loanDetails.ComputationMode]
                              )}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Tenure"
                              value={loanDetails.LoanCycle + " Days"}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Loan Amount"
                              value={loanDetails.LoanAmountToNaira}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Interest Rate"
                              value={loanDetails.InterestRate + "%"}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Security Deposit"
                              value={loanDetails.SecurityDeposit}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Phone Number"
                              value={item.PhoneNumber}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Economic Sector"
                              value={textFormatService.splitAtUpper(
                                EconomicSector[loanDetails.EconomicSector]
                              )}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Loan Repayment Schedule Type"
                              value={textFormatService.splitAtUpper(
                                LoanPaymentScheduleType[
                                loanDetails.LoanPaymentScheduleType
                                ]
                              )}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Disbursement Mode"
                              value={item.DisbursementMode}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Preferred Repayment Account"
                              value={item.PreferredRepaymentAccount}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Preferred Repayment Bank"
                              value={repaymentBankName}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Repayment Type"
                              value={item.RepaymentType=="Collection"? "Collection & Repayment" : item.RepaymentType}
                              labelSize={6}
                              valueSize={6}
                            />
                          </dl>
                          <br />
                          <div>
                            <h6
                              className="font-weight-normal"
                              style={{ marginTop: "0.5rem", display: "inline" }}
                            >
                              <strong>Collection Payment Schedule</strong>
                            </h6>
                          </div>
                          <div
                            className="table-responsize text-nowrap"
                            style={{ height: "150px", overflowY: "scroll" }}
                          >
                            <table className="table table-hover table-content table-sm table-striped mb-0">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    style={{
                                      width: "5px",
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                    }}
                                  >
                                    <strong>#</strong>
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                    }}
                                  >
                                    <strong>Repayment Date</strong>
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                    }}
                                  >
                                    <strong>Repayment Amount</strong>
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      // width: "5px",
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                    }}
                                  ></th>
                                </tr>
                              </thead>
                              <tbody>
                                {_.isEmpty(item) ||
                                  item.CollectionPaymentSchedules.length === 0 ? (
                                  <tr>
                                    <td
                                      className="font-weight-normal text-truncate"
                                      colSpan={4}
                                      style={{
                                        textAlign: "center",
                                        color: "#FE825E",
                                        fontSize: "small",
                                      }}
                                    >
                                      ...No Collection Payment Schedule available
                                    </td>
                                  </tr>
                                ) : (
                                  item.CollectionPaymentSchedules.map((schedule, id) => (
                                    <tr key={id}>
                                      <td
                                        style={{
                                          width: "5px",
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {id + 1}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {textFormatService.formatTimeString(
                                          new Date(parseInt((schedule.RepaymentDate).substring(6, 19))).toString()
                                        )}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {textFormatService.formatCurrency(schedule.RepaymentAmountInNaira, 2)}
                                      </td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </div>
          </Accordion>
        </div>
        <hr />
        <br />
        <div>
          <h6
            className="font-weight-normal"
            style={{ marginTop: "0.5rem", display: "inline" }}
          >
            Supporting Documents
            
            <button
              type="button"
              className="btn btn-sm btn-primary waves-effect sm-doc"
              disabled={!this.props.canEditItem}
              style={{ margin: "5px", padding: "2px 10px 2px 10px" }}
              onClick={() => this.toggleShowDocModal()}
            >
              <i className="fas fa-plus mr-2" />
              Add
            </button>
          </h6>
        </div>
        <div
          className="table-responsive text-nowrap"
          style={{ height: "150px", overflowY: "scroll" }}
        >
          <table className="table table-hover table-content table-sm table-striped mb-0">
            <thead>
              <tr>
                <th
                  scope="col"
                  style={{
                    width: "5px",
                    paddingTop: "0.2rem",
                    paddingBottom: "0.2rem",
                  }}
                >
                  <strong>#</strong>
                </th>
                <th
                  scope="col"
                  style={{
                    paddingTop: "0.2rem",
                    paddingBottom: "0.2rem",
                  }}
                >
                  <strong>Name</strong>
                </th>
                <th
                  scope="col"
                  style={{
                    paddingTop: "0.2rem",
                    paddingBottom: "0.2rem",
                  }}
                >
                  <strong>Document Type</strong>
                </th>
                <th
                  scope="col"
                  style={{
                    // width: "5px",
                    paddingTop: "0.2rem",
                    paddingBottom: "0.2rem",
                  }}
                ></th>
                <th
                  scope="col"
                  style={{
                    // width: "5px",
                    paddingTop: "0.2rem",
                    paddingBottom: "0.2rem",
                  }}
                ></th>
              </tr>
            </thead>
            <tbody>
              {fetchingSupportingDocuments || _.isEmpty(supportingDocuments) ? (
                <tr>
                  <td
                    className="font-weight-normal text-truncate"
                    colSpan={4}
                    style={{
                      textAlign: "center",
                      color: "#FE825E",
                      fontSize: "small",
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                    }}
                  >
                    {fetchingSupportingDocuments ? (
                      <React.Fragment>
                        <i className="fas fa-spin fa-sm fa-circle-notch"></i>
                      </React.Fragment>
                    ) : (
                      "...No documents available"
                    )}
                  </td>
                </tr>
              ) : (
                supportingDocuments.map((document) => (
                  <tr key={document.id}>
                    <td
                      style={{
                        width: "5px",
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                        fontSize: "small",
                      }}
                    >
                      {supportingDocuments.indexOf(document) + 1}
                    </td>
                    <td
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                        fontSize: "small",
                      }}
                    >
                      {document.name}
                    </td>
                    <td
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                        fontSize: "small",
                      }}
                    >
                      {document.disputeConditionDocumentType}
                    </td>
                    <td
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                        fontSize: "small",
                      }}
                    >
                      {this.generateExtensionIcon(document)}
                    </td>
                    <td
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                        fontSize: "small",
                      }}
                    >
                    { this.props.canEditItem ? (
                      <i
                        className="far fa-trash-alt ml-5 red-text fa-lg"
                        style={{ cursor: "pointer" }}
                        onClick={(e) =>
                          this.handleDeleteDocument(document)
                        }
                      ></i>
                    ) : (
                      ""
                    )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <DocumentPreviewer
            document={this.state.document}
            showPreview={this.state.showPreview}
            toggleShowPreview={this.toggleShowPreview}
          />
          <ModifyLoanParams 
            item={item}
            financialGroupUpperLimit={this.props.financialGroup.upperLimit/100}
            financialGroupLowerLimit={this.props.financialGroup.lowerLimit/100}
            showPatchModal={showPatchModal}
            togglePatchModal={(reloadDetails: boolean, item: any, amount: number) => {
              this.toggleShowModal(); 
              if (reloadDetails === true) {
                this.props.updateItem(item, amount);
              }
            }}
          />
          <AddSupportingDocument 
            item={item}
            showPatchModal={showDocPatchModal}
            togglePatchModal={(reloadDetails: boolean, document: any) => {
              this.toggleShowDocModal(); 
              if (reloadDetails === true) {
                this.props.updateItemDocs(document);
              }
            }}
          />
        </div>

        {/* <div className="text-center">
          <h6
            className="font-weight-normal"
            stylle={{ marginTop: "0.5rem", display: "inline" }}
          >
            Loan Details
          </h6>
        </div>
        <div
          className="table-responsive text-nowrap"
          style={{ height: "250px", overflowY: "scroll" }}
        >
          <table className="table table-hover table-content table-sm">
            <tbody>
              <tr>
                <td
                  className="font-weight-normal"
                  style={{
                    textAlign: "left",
                    paddingTop: "0.2rem",
                    paddingBottom: "0.2rem",
                  }}
                >
                  <b>Customer Name: </b>
                </td>
                <td
                  className="font-weight-normal"
                  style={{
                    textAlign: "right",
                    paddingTop: "0.2rem",
                    paddingBottom: "0.2rem",
                  }}
                >
                  {_.isEmpty(item) || fetchingSupportingDocuments ? (
                    <Skeleton width={150} height={10} />
                  ) : (
                    loanDetails.CustomerName
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="font-weight-normal"
                  style={{
                    textAlign: "left",
                    paddingTop: "0.2rem",
                    paddingBottom: "0.2rem",
                  }}
                >
                  <b>Account Number: </b>
                </td>
                <td
                  className="font-weight-normal"
                  style={{
                    textAlign: "right",
                    paddingTop: "0.2rem",
                    paddingBottom: "0.2rem",
                  }}
                >
                  {_.isEmpty(item) || fetchingSupportingDocuments ? (
                    <Skeleton width={150} height={10} />
                  ) : (
                    loanDetails.AccountNumber
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="font-weight-normal"
                  style={{
                    textAlign: "left",
                    paddingTop: "0.2rem",
                    paddingBottom: "0.2rem",
                  }}
                >
                  <b>Bank Verification Number: </b>
                </td>
                <td
                  className="font-weight-normal"
                  style={{
                    textAlign: "right",
                    paddingTop: "0.2rem",
                    paddingBottom: "0.2rem",
                  }}
                >
                  {_.isEmpty(item) || fetchingSupportingDocuments ? (
                    <Skeleton width={150} height={10} />
                  ) : (
                    item.BVN
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="font-weight-normal"
                  style={{
                    textAlign: "left",
                    paddingTop: "0.2rem",
                    paddingBottom: "0.2rem",
                  }}
                >
                  <b>Phone Number: </b>
                </td>
                <td
                  className="font-weight-normal"
                  style={{
                    textAlign: "right",
                    paddingTop: "0.2rem",
                    paddingBottom: "0.2rem",
                  }}
                >
                  {_.isEmpty(item) || fetchingSupportingDocuments ? (
                    <Skeleton width={150} height={10} />
                  ) : (
                    item.PhoneNumber
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="font-weight-normal"
                  style={{
                    textAlign: "left",
                    paddingTop: "0.2rem",
                    paddingBottom: "0.2rem",
                  }}
                >
                  <b>Loan Amount: </b>
                </td>
                <td
                  className="font-weight-normal"
                  style={{
                    textAlign: "right",
                    paddingTop: "0.2rem",
                    paddingBottom: "0.2rem",
                  }}
                >
                  {_.isEmpty(item) || fetchingSupportingDocuments ? (
                    <Skeleton width={150} height={10} />
                  ) : (
                    loanDetails.LoanAmountToNaira
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="font-weight-normal"
                  style={{
                    textAlign: "left",
                    paddingTop: "0.2rem",
                    paddingBottom: "0.2rem",
                  }}
                >
                  <b>Total Expected Repayment: </b>
                </td>
                <td
                  className="font-weight-normal"
                  style={{
                    textAlign: "right",
                    paddingTop: "0.2rem",
                    paddingBottom: "0.2rem",
                  }}
                >
                  {_.isEmpty(item) || fetchingSupportingDocuments ? (
                    <Skeleton width={150} height={10} />
                  ) : (
                    convertKoboToNaira(item.TotalRepaymentExpected, 2)
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="font-weight-normal"
                  style={{
                    textAlign: "left",
                    paddingTop: "0.2rem",
                    paddingBottom: "0.2rem",
                  }}
                >
                  <b>Loan Duration: </b>
                </td>
                <td
                  className="font-weight-normal"
                  style={{
                    textAlign: "right",
                    paddingTop: "0.2rem",
                    paddingBottom: "0.2rem",
                  }}
                >
                  {_.isEmpty(item) || fetchingSupportingDocuments ? (
                    <Skeleton width={150} height={10} />
                  ) : (
                    loanDetails.LoanCycle + " days."
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
       */}
      </React.Fragment>
    );
  }
}

export default BankOneLoanItemApprovalDetail;
