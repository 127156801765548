import React from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import usersService from "../../../services/auth/usersService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import authValidationService from "../../../services/auth/authValidationService";
import branchesService from "../../../services/auth/branchesService";
import rolesService from "../../../services/auth/rolesService";
import { getCurrentUserSession } from "./../../../services/auth/authService";
import DetailItem from "../../../components/detailItem";
import { ActionTypes } from "../../../enums/actionTypes";
import { decodeToken } from "react-jwt";

interface EditUserFields {
  firstName: string;
  lastName: string;
  userName: string;
  otherNames: string;
  phoneNumber: string;
  email: string;
  userRoleId: string;
  branchId: string;
  address: string;
  gender: string;
}

class EditUserForm extends BaseFormComponent<EditUserFields> { }

interface IEditUserProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}
interface IEditUserState {
  branches: any;
  userRoles: any;
  hasApprovalRights: boolean;
  ownUpdate: boolean;
}

class EditUser extends React.Component<
  IEditUserProps,
  IBaseFormState & IEditUserState
> {
  _isMounted = false;
  constructor(props: IEditUserProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      branches: [],
      userRoles: [],
      hasApprovalRights: false,
      ownUpdate: false,
    };
  }

  static getDerivedStateFromProps(nextProps: IEditUserProps, prevState) {
    if (prevState.ownUpdate) {
      return {
        hasApprovalRights: prevState.hasApprovalRights,
        ownUpdate: false,
      };
    }
    return {
      hasApprovalRights: nextProps.item.hasApprovalRights,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { errors } = this.state;
    try {
      const branches = await branchesService.retrieveAllBranches();
      const userRoles = await rolesService.retrieveAllRoles();
      if (this._isMounted) {
        this.setState({
          branches: branches.data,
          userRoles: userRoles.data,
        });
      }
    } catch (ex) {
      // console.log("caught exception", ex);
      if (ex.response && ex.response.status === 404) {
        // console.log(ex.response);
        errors.response = "Internal server error.";
        // console.log(errors);
        this.setState({ errors });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onFormSubmit(fields: EditUserFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors: authValidationService.validateEditUserForm(fields),
          ownUpdate: true
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (fields: EditUserFields, onReloadFieldsCB: any) => {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const payload = this.props.item;
      // console.log("For checks::", payload);
      payload.id = payload.id;
      payload.firstName = fields.firstName;
      payload.hasApprovalRights = this.state.hasApprovalRights;
      payload.lastName = fields.lastName;
      payload.otherName = fields.otherNames;
      payload.userName = fields.userName;
      payload.phoneNo = fields.phoneNumber;
      payload.institutionId = decodedToken?.["InstitutionId"];
      payload.branchId = fields.branchId;
      payload.userRoleId = fields.userRoleId;
      payload.email = fields.email;
      payload.address = fields.address;
      payload.dateOfBirth = this.props.item.dateOfBirth;
      payload.gender = fields.gender;

      const response = await usersService.updateUser(payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit User",
          description: `Edited details for User ${fields.firstName} ${fields.lastName}`,
        });

        if (response.data.isLoggedForApproval === true) {
          toast.warn("Action has been successfully logged for Approval!", {
            autoClose: 6000,
            type: toast.TYPE.WARNING,
            position: "top-center",
            hideProgressBar: false,
          });
        } else {
          // await authService.setRoleFunctionsForUser(
          //   Number(getCurrentUserSession().userId)
          // );
          toast.info(
            `User ${fields.firstName} ${fields.lastName} Edited successfully! `,
            {
              autoClose: 6000,
              type: toast.TYPE.DEFAULT,
              hideProgressBar: false,
            }
          );
        }
      }
      onReloadFieldsCB();
      this.setState({ submitting: false, ownUpdate: true }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      // console.log("submission error: ", error);
      // this.setState({
      //   errors: "Invalid Username or Password",
      // });
      this.setState({ submitting: false, ownUpdate: true });
    }
  };

  handleUpdateHasApprovalRights = () => {
    this.setState({
      hasApprovalRights: !this.state.hasApprovalRights,
      ownUpdate: true,
    });
  };

  validateField = (fieldName: string, type: string, validated: boolean) => {
    let defaultClass = "";
    if (type === "select") {
      defaultClass =
        "custom-select select2 mr-sm-2 form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    } else {
      defaultClass = "form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    }
    return !validated
      ? defaultClass
      : this.state.errors[fieldName]
        ? `${defaultClass} is-invalid`
        : `${defaultClass} is-valid`;
  };

  render() {
    const { item, showEditModal, toggleEditModal } = this.props;
    const {
      validated,
      submitting,
      errors,
      hasApprovalRights,
      branches,
      userRoles,
    } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-user-edit fa-sm cyan-text mr-3" />
              Edit User -{" "}
              <span className="font-weight-bold">
                {item.firstName} {item.lastName}
              </span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditUserForm
          initialValues={{
            firstName: item.firstName,
            lastName: item.lastName,
            address: item.address,
            branchId: item.branchId,
            email: item.email,
            otherNames: item.otherName,
            phoneNumber: item.phoneNo,
            userName: item.username,
            userRoleId: item.userRoleId,
            gender: item.gender,
          }}
          FormComponent={({
            fields: {
              firstName,
              userRoleId,
              userName,
              phoneNumber,
              otherNames,
              email,
              branchId,
              address,
              lastName,
              gender,
            },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    <h6
                      className="card-title float-left"
                      style={{ marginBottom: "0px" }}
                    >
                      General Information
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <DetailItem
                            label="First Name"
                            value={
                              <React.Fragment>
                                <input
                                  autoFocus
                                  type="text"
                                  className={this.validateField(
                                    "firstName",
                                    "text",
                                    validated
                                  )}
                                  id="firstName"
                                  name="firstName"
                                  value={firstName}
                                  onChange={(e) =>
                                    onChange("firstName", e.currentTarget.value)
                                  }
                                />
                                <div
                                  className="invalid-feedback"
                                  style={{ visibility: errors.firstName }}
                                >
                                  {errors.firstName}
                                </div>
                              </React.Fragment>
                            }
                          />
                        </div>
                        <div className="form-group row">
                          <DetailItem
                            label="Other Names"
                            value={
                              <React.Fragment>
                                <input
                                  autoFocus
                                  type="text"
                                  className={this.validateField(
                                    "otherNames",
                                    "text",
                                    validated
                                  )}
                                  id="otherNames"
                                  name="otherNames"
                                  value={otherNames}
                                  onChange={(e) =>
                                    onChange(
                                      "otherNames",
                                      e.currentTarget.value
                                    )
                                  }
                                />
                                <div
                                  className="invalid-feedback"
                                  style={{ visibility: errors.otherNames }}
                                >
                                  {errors.otherNames}
                                </div>
                              </React.Fragment>
                            }
                          />
                        </div>
                        <div className="form-group row">
                          <DetailItem
                            label="Email"
                            value={
                              <React.Fragment>
                                <input
                                  autoFocus
                                  type="email"
                                  className={this.validateField(
                                    "email",
                                    "email",
                                    validated
                                  )}
                                  id="email"
                                  name="email"
                                  value={email}
                                  onChange={(e) =>
                                    onChange("email", e.currentTarget.value)
                                  }
                                />
                                <div
                                  className="invalid-feedback"
                                  style={{ visibility: errors.email }}
                                >
                                  {errors.email}
                                </div>
                              </React.Fragment>
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <DetailItem
                            label="Last Name"
                            value={
                              <React.Fragment>
                                <input
                                  autoFocus
                                  type="text"
                                  className={this.validateField(
                                    "lastName",
                                    "text",
                                    validated
                                  )}
                                  id="lastName"
                                  name="lastName"
                                  value={lastName}
                                  onChange={(e) =>
                                    onChange("lastName", e.currentTarget.value)
                                  }
                                />
                                <div
                                  className="invalid-feedback"
                                  style={{ visibility: errors.lastName }}
                                >
                                  {errors.lastName}
                                </div>
                              </React.Fragment>
                            }
                          />
                        </div>

                        <div className="form-group row">
                          <DetailItem
                            label="User Name"
                            value={
                              <React.Fragment>
                                <input
                                  autoFocus
                                  type="text"
                                  className={this.validateField(
                                    "userName",
                                    "text",
                                    validated
                                  )}
                                  id="userName"
                                  name="userName"
                                  value={userName}
                                  onChange={(e) =>
                                    onChange("userName", e.currentTarget.value)
                                  }
                                />
                                <div
                                  className="invalid-feedback"
                                  style={{ visibility: errors.userName }}
                                >
                                  {errors.userName}
                                </div>
                              </React.Fragment>
                            }
                          />
                        </div>

                        <div className="form-group row">
                          <DetailItem
                            label="Gender"
                            value={
                              <React.Fragment>
                                <select
                                  className={this.validateField(
                                    "gender",
                                    "select",
                                    validated
                                  )}
                                  onChange={(e) =>
                                    onChange("gender", e.currentTarget.value)
                                  }
                                  value={gender}
                                  name="gender"
                                >
                                  <option value="">-Select Gender-</option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                </select>
                                <div
                                  className="invalid-feedback"
                                  style={{ visibility: errors.gender }}
                                >
                                  {errors.gender}
                                </div>
                              </React.Fragment>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header clear-fix">
                    <h6
                      className="card-title float-left"
                      style={{ marginBottom: "0px" }}
                    >
                      Other Information
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <DetailItem
                            label="Address"
                            value={
                              <React.Fragment>
                                <input
                                  autoFocus
                                  type="text"
                                  className={this.validateField(
                                    "address",
                                    "text",
                                    validated
                                  )}
                                  id="address"
                                  name="address"
                                  value={address}
                                  onChange={(e) =>
                                    onChange("address", e.currentTarget.value)
                                  }
                                />
                                <div
                                  className="invalid-feedback"
                                  style={{ visibility: errors.address }}
                                >
                                  {errors.address}
                                </div>
                              </React.Fragment>
                            }
                          />
                        </div>
                        <div className="form-group row">
                          <DetailItem
                            label="User Role"
                            value={
                              <React.Fragment>
                                <select
                                  className={this.validateField(
                                    "userRoleId",
                                    "select",
                                    validated
                                  )}
                                  onChange={(e) =>
                                    onChange(
                                      "userRoleId",
                                      e.currentTarget.value
                                    )
                                  }
                                  value={userRoleId}
                                  name="userRoleId"
                                >
                                  <option value="">-Select User Role-</option>
                                  {!_.isEmpty(userRoles)
                                    ? userRoles.map((item) => (
                                      <option key={item.id} value={item.id}>
                                        {item.name}
                                      </option>
                                    ))
                                    : ""}
                                </select>
                                <div
                                  className="invalid-feedback"
                                  style={{ visibility: errors.userRoleId }}
                                >
                                  {errors.userRoleId}
                                </div>
                              </React.Fragment>
                            }
                          />
                        </div>
                        <div className="form-group row">
                          <DetailItem
                            label="Has Approval Rights"
                            value={
                              <span className="material-switch ml-2">
                                <input
                                  id="hasApprovalRights"
                                  name="hasApprovalRights"
                                  type="checkbox"
                                  onChange={this.handleUpdateHasApprovalRights}
                                  checked={hasApprovalRights}
                                />
                                <label
                                  htmlFor="hasApprovalRights"
                                  className="bg-primary"
                                ></label>
                              </span>
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <DetailItem
                            label="Phone Number"
                            value={
                              <React.Fragment>
                                <input
                                  autoFocus
                                  type="text"
                                  className={this.validateField(
                                    "phoneNumber",
                                    "text",
                                    validated
                                  )}
                                  id="phoneNumber"
                                  name="phoneNumber"
                                  value={phoneNumber}
                                  onChange={(e) =>
                                    onChange(
                                      "phoneNumber",
                                      e.currentTarget.value
                                    )
                                  }
                                />
                                <div
                                  className="invalid-feedback"
                                  style={{ visibility: errors.phoneNumber }}
                                >
                                  {errors.phoneNumber}
                                </div>
                              </React.Fragment>
                            }
                          />
                        </div>

                        <div className="form-group row">
                          <DetailItem
                            label="Branch"
                            value={
                              <React.Fragment>
                                <select
                                  className={this.validateField(
                                    "branchId",
                                    "select",
                                    validated
                                  )}
                                  onChange={(e) =>
                                    onChange("branchId", e.currentTarget.value)
                                  }
                                  value={branchId}
                                  name="branchId"
                                >
                                  <option value="">-Select Branch-</option>
                                  {!_.isEmpty(branches)
                                    ? branches.map((item) => (
                                      <option key={item.id} value={item.id}>
                                        {item.name}
                                      </option>
                                    ))
                                    : ""}
                                </select>
                                <div
                                  className="invalid-feedback"
                                  style={{ visibility: errors.branchId }}
                                >
                                  {errors.branchId}
                                </div>
                              </React.Fragment>
                            }
                          />
                        </div>

                        <div className="form-group row">
                          <DetailItem label="Status" value={item.status} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(e, ActionTypes.UPDATE, "User", () => {
                      this.onFormSubmit(
                        {
                          lastName,
                          address,
                          branchId,
                          email,
                          otherNames,
                          phoneNumber,
                          userName,
                          userRoleId,
                          firstName,
                          gender,
                        },
                        onReloadFields
                      );
                    });
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></EditUserForm>
      </Modal>
    );
  }
}

export default EditUser;
