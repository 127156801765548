import React, { Component } from "react";
import _ from "lodash";
import { NavLink, Link } from "react-router-dom";
import { retrieveSideNavContent } from "./sideNavContent";
// import "./home.css";
import sweepRunService from "../../services/sweep/sweepRunService";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { generateGUID } from "./../../services/utility/textFormatService";
import SweepRunStatusModal from "../reports/sweepRunStatusModal";
import usersService from "../../services/auth/usersService";
import { getCurrentUserSession, getCurrentUsersRoles } from "../../services/auth/authService";
import institutionsService from "../../services/auth/institutionsService";
import LoanProductConfigModal from "./loanProductConfigModal";
import { decodeToken } from "react-jwt";
import remoteSessionService from "../../services/auth/remoteSessionService";
import { toast } from "react-toastify";
import swal from "sweetalert";
import InstitutionLoanProductConfigModal from "./institutionLoanProductConfidModal";

interface ISideNavProps {
    activePath: string;
}

interface ISideNavState {
    showModal: boolean;
    sweepStatus: any;
    currentUser: any;
    institutions: any;
    showLoanProductConfigModal: boolean;
    showInstitutionLoanProductConfigModal: boolean;
    activePath: string;
    endingRemoteSession: boolean;
    selectedServiceOffering: string;
}

// const CustomLink = (props) => {
//   const history = useHistory();
//   return (
//     <NavLink
//       to={props.itemChild.url}
//       style={{ textDecoration: "none" }}
//       onClick={(e) => {
//         e.preventDefault();
//         history.push(props.itemChild.url);
//       }}
//     >
//       <i className={`icon ${props.itemChild.icon}`}></i>
//       {props.itemChild.name}
//     </NavLink>
//   );
// };
class SideNav extends Component<ISideNavProps, ISideNavState> {
    constructor(props: ISideNavProps) {
        super(props);
        this.state = {
            showModal: false,
            sweepStatus: {},
            currentUser: {},
            institutions: [],
            showLoanProductConfigModal: false,
            showInstitutionLoanProductConfigModal: false,
            activePath: "",
            endingRemoteSession: false,
            selectedServiceOffering: "RECOVA",
        };
    }

    async componentDidMount() {
        const decodedToken = decodeToken(getCurrentUserSession().token || "");
        const user = await usersService.retrieveUserById(decodedToken?.["UserId"]);

        let institutions = await institutionsService.retrieveAllInstitutions();
        institutions = institutions.data.filter((x) => x.category === "BankOneOFI");
        this.setState({ institutions, currentUser: user.data });
    }

    fetchItemByCode = async (institutionCode) => {
        const report = await sweepRunService.retrieveCurrentSweepRunStatus(institutionCode);
        // console.log("report: ", report.data);
        this.setState({ sweepStatus: report.data });
    };

    toggleModal = (institutionCode) => {
        this.setState({ showModal: !this.state.showModal });
        if (this.state.showModal === false) {
            this.fetchItemByCode(institutionCode);
        } else {
            this.setState({ sweepStatus: {} });
        }
    };

    toggleLoanProductModal = () => {
        this.setState({
            showLoanProductConfigModal: !this.state.showLoanProductConfigModal,
        });
    };

    toggleInstitutionLoanProductModal = () => {
        this.setState({
            showInstitutionLoanProductConfigModal: !this.state.showInstitutionLoanProductConfigModal,
        });
    };

    onChangeSelectedServiceOffering = (serviceOffering: string) => {
        this.setState({
            selectedServiceOffering: serviceOffering,
        });
    };

    static async getDerivedStateFromProps(nextProps: ISideNavProps, prevState: ISideNavState) {
        if (nextProps.activePath !== prevState.activePath) {
            return {
                activePath: nextProps.activePath,
            };
        }
        return null;
    }

    handleOnClickLogoutRemote = async () => {
        try {
            this.setState({ endingRemoteSession: true });

            swal({
                title: "Confirm!",
                text: `Are you sure you want to proceed to End this Remote session?`,
                icon: "warning",
                buttons: {
                    confirm: {
                        text: "Yes",
                        value: null,
                        visible: true,
                        className: "",
                        closeModal: true,
                    },
                    cancel: {
                        text: "Cancel",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true,
                    },
                },
                closeOnClickOutside: false,
                dangerMode: true,
            }).then(async (resp) => {
                if (resp) {
                    // console.log("cancelling action...");
                    this.setState({ endingRemoteSession: false });
                } else {
                    //push to end current session
                    const currentSession = getCurrentUserSession();
                    const decodedToken = decodeToken(currentSession.token || "");
                    // console.log("decoded token", decodedToken)

                    let payload: any = {};
                    payload.id = currentSession.id;
                    payload.sessionEndTime = new Date();

                    // console.log("payload for session",payload)
                    let remoteSession = await remoteSessionService.endRemoteSession(payload);

                    //return normal session
                    //do the same for userrole functions
                    localStorage.setItem("userSession", localStorage.getItem("remoteUserSession") || "");
                    localStorage.setItem("userRoles", localStorage.getItem("remoteUserRole") || "");
                    localStorage.removeItem("remoteUserSession");
                    localStorage.removeItem("remoteUserRole");
                    localStorage.removeItem("sessionStartTime");

                    this.setState({ endingRemoteSession: false });
                    window.location.replace("/");
                }
            });
        } catch (error) {
            toast.info("Something went wrong.", {
                autoClose: 6000,
                type: toast.TYPE.ERROR,
                hideProgressBar: false,
            });
            toast.info(error.response.data.errors[0], {
                autoClose: 6000,
                type: toast.TYPE.ERROR,
                hideProgressBar: false,
            });
        }
    };

    render() {
        // const { isSideBarOpened, currentUserRoles, currentUser } = this.props;
        const { currentUser, selectedServiceOffering } = this.state;
        let currentUserSession = getCurrentUserSession();

        const decodedToken = decodeToken(currentUserSession.token || "");

        let instCode = decodedToken?.["InstitutionCode"];

        const allNavContent = retrieveSideNavContent();
        const selectedNavContent = allNavContent.items
            .filter((serviceOffering) => serviceOffering.name == "AUTH" || serviceOffering.name == selectedServiceOffering)
            .map((serviceOffering) => serviceOffering.children);
        const navContent = selectedNavContent.reduce(function (prev, next) {
            return prev.concat(next);
        });
        // console.log(navContent)
        // const click = useHistory();
        // const systemloanproductsclick = () =>
        //   click.push("/home/systemloanproducts");
        // console.log("go test", this.state.activePath);
        // console.log("go test2", this.props.activePath);
        return (
            <React.Fragment>
                <aside className="main-sidebar fixed offcanvas b-r sidebar-tabs" data-toggle="offcanvas">
                    <div className="sidebar">
                        <div className="d-flex align-items-stretch">
                            <div
                                className={getCurrentUsersRoles().includes("remotesession") ? "text-white" : "indigo text-white"}
                                style={getCurrentUsersRoles().includes("remotesession") ? { backgroundColor: "#61320c" } : {}}
                            >
                                <div
                                    className="nav flex-column nav-pills"
                                    id="v-pills-tab"
                                    role="tablist"
                                    aria-orientation="vertical"
                                    style={{ marginTop: "80px" }}
                                >
                                    <OverlayTrigger placement={"top"} overlay={<Tooltip id={generateGUID()}>Home</Tooltip>}>
                                        <NavLink id="v-pills-settings-tab" to={"/home"} style={{ textDecoration: "none" }} replace>
                                            <i className="icon-home"></i>
                                        </NavLink>
                                    </OverlayTrigger>
                                    {allNavContent.items.map((contentItem) =>
                                        contentItem.displayName === true ? (
                                            <OverlayTrigger
                                                key={generateGUID()}
                                                placement={"top"}
                                                overlay={<Tooltip id={generateGUID()}>{contentItem.name}</Tooltip>}
                                            >
                                                <a
                                                    className="nav-link"
                                                    id="v-pills-home-tab"
                                                    data-toggle="pill"
                                                    href="#!"
                                                    role="tab"
                                                    aria-controls="v-pills-home"
                                                    aria-selected="true"
                                                    onClick={() => this.onChangeSelectedServiceOffering(contentItem.name)}
                                                >
                                                    <i className={`${contentItem.icon}`}></i>
                                                </a>
                                            </OverlayTrigger>
                                        ) : (
                                            ""
                                        )
                                    )}
                                </div>

                                <div
                                    className="nav flex-column nav-pills"
                                    id="v-pills-tab"
                                    role="tablist"
                                    aria-orientation="vertical"
                                    style={{ marginTop: "60px" }}
                                >
                                    {(getCurrentUsersRoles().includes("viewsweeprunstatus") || getCurrentUsersRoles().includes("remotesession")) && (
                                        <OverlayTrigger placement={"top"} overlay={<Tooltip id={generateGUID()}>Sweep Run Status</Tooltip>}>
                                            <a
                                                className="nav-link blink"
                                                id="v-pills-home-tab"
                                                data-toggle="pill"
                                                href="#!"
                                                role="tab"
                                                aria-controls="v-pills-home"
                                                aria-selected="true"
                                                onClick={() => this.toggleModal(instCode)}
                                            >
                                                <i className="icon-information4"></i>
                                            </a>
                                        </OverlayTrigger>
                                    )}
                                    {/* <a
                    className="nav-link"
                    id="v-pills-profile-tab"
                    data-toggle="pill"
                    href="#v-pills-profile"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected="false"
                  >
                    <i className="icon-add green-text"></i>
                  </a> */}
                                    {/* <a
                    className="nav-link blink skin_handle"
                    href="user-roles.html#"
                  >
                    <i className="icon-lightbulb_outline"></i>
                  </a> */}
                                    {getCurrentUsersRoles().includes("configuresystemloanproducts") && (
                                        <OverlayTrigger placement={"top"} overlay={<Tooltip id={generateGUID()}>Configure Loan Products</Tooltip>}>
                                            <a
                                                className="nav-link"
                                                id="v-pills-home-tab"
                                                data-toggle="pill"
                                                href="#!"
                                                role="tab"
                                                aria-controls="v-pills-home"
                                                aria-selected="true"
                                                onClick={() => this.toggleLoanProductModal()}
                                            >
                                                <i className="icon-box-filled2"></i>
                                            </a>
                                        </OverlayTrigger>
                                    )}

                                    {getCurrentUsersRoles().includes("configureinstitutionsystemloanproducts") && (
                                        <OverlayTrigger placement={"top"} overlay={<Tooltip id={generateGUID()}>Configure Loan Products</Tooltip>}>
                                            <a
                                                className="nav-link"
                                                id="v-pills-home-tab"
                                                data-toggle="pill"
                                                href="#!"
                                                role="tab"
                                                aria-controls="v-pills-home"
                                                aria-selected="true"
                                                onClick={() => this.toggleInstitutionLoanProductModal()}
                                            >
                                                <i className="icon-box-filled2"></i>
                                            </a>
                                        </OverlayTrigger>
                                    )}

                                    {getCurrentUsersRoles().includes("viewsystemsettings") && (
                                        <OverlayTrigger placement={"top"} overlay={<Tooltip id={generateGUID()}>System Settings</Tooltip>}>
                                            <NavLink id="v-pills-settings-tab" to={"/home/settings"} style={{ textDecoration: "none" }} replace>
                                                <i className="icon-settings"></i>
                                            </NavLink>
                                        </OverlayTrigger>
                                    )}
                                    {getCurrentUsersRoles().includes("remoteview") && (
                                        <OverlayTrigger placement={"top"} overlay={<Tooltip id={generateGUID()}>Remote Access</Tooltip>}>
                                            <NavLink id="v-pills-remote-tab" to={"/home/remoteview"} style={{ textDecoration: "none" }} replace>
                                                <i className="icon-desktop2"></i>
                                            </NavLink>
                                        </OverlayTrigger>
                                    )}
                                    {getCurrentUsersRoles().includes("remotesession") && (
                                        <OverlayTrigger placement={"top"} overlay={<Tooltip id={generateGUID()}>End Remote Session</Tooltip>}>
                                            <a
                                                className="nav-link blink"
                                                id="v-pills-home-tab"
                                                data-toggle="pill"
                                                href="#!"
                                                role="tab"
                                                aria-controls="v-pills-home"
                                                aria-selected="true"
                                                onClick={() => {
                                                    if (!this.state.endingRemoteSession) {
                                                        this.handleOnClickLogoutRemote();
                                                    }
                                                }}
                                            // style={{color: "#0a6095"}}
                                            >
                                                {this.state.endingRemoteSession ? (
                                                    <i className="fas fa-spin fa-circle-notch" />
                                                ) : (
                                                    <i className="icon-sign-out"></i>
                                                )}
                                            </a>
                                        </OverlayTrigger>
                                    )}
                                    {/* <a href="#!">
                    <figure className="avatar">
                      <img src="assets/img/dummy/u3.png" alt="" />
                      <span className="avatar-badge online"></span>
                    </figure>
                  </a> */}
                                </div>
                            </div>

                            {/* <!-- Side Nav Begins --> */}
                            <div className="tab-content flex-grow-1" id="v-pills-tabContent">
                                <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                    <div className="relative brand-wrapper sticky b-b">
                                        <div className="d-flex justify-content-between align-items-center p-3">
                                            <div className="text-xs-center">
                                                <img src="assets/img/basic/recova-logo.svg" width="100" alt="" className="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ height: "90vh", overflowY: "scroll" }}>
                                        <ul className="sidebar-menu">
                                            {navContent.map((contentItem) =>
                                                contentItem.title === true ? (
                                                    _.isEmpty(contentItem.roleFunctions) ? (
                                                        <li
                                                            key={generateGUID()}
                                                            id={contentItem.name.replace(/\s/g, "").toLowerCase()}
                                                            className="treeview"
                                                        >
                                                            <a href="!#" style={{ textDecoration: "none" }}>
                                                                <span>{contentItem.name}</span>
                                                            </a>
                                                        </li>
                                                    ) : (
                                                        contentItem.roleFunctions.some((r) => getCurrentUsersRoles().includes(r)) && (
                                                            <li
                                                                key={generateGUID()}
                                                                id={contentItem.name.replace(/\s/g, "").toLowerCase()}
                                                                className="treeview"
                                                            >
                                                                <a href="!#" style={{ textDecoration: "none" }}>
                                                                    <span>{contentItem.name}</span>
                                                                </a>
                                                            </li>
                                                        )
                                                    )
                                                ) : (
                                                    contentItem.roleFunctions.some((r) => getCurrentUsersRoles().includes(r)) && (
                                                        <li
                                                            key={generateGUID()}
                                                            id={contentItem.name.replace(/\s/g, "").toLowerCase()}
                                                            className={
                                                                contentItem.children?.some((child) => child.url === this.props.activePath)
                                                                    ? "treeview active"
                                                                    : "treeview"
                                                            }
                                                        >
                                                            <Link
                                                                id={contentItem.name.replace(/\s/g, "").toLowerCase() + `link`}
                                                                to={contentItem.url}
                                                                style={{ textDecoration: "none" }}
                                                                replace
                                                            >
                                                                <i className={`icon ${contentItem.icon} s-18`}></i>
                                                                {contentItem.name}
                                                                {Number(contentItem.children?.length) > 0 && (
                                                                    <i className=" icon-angle-left pull-right"></i>
                                                                )}
                                                            </Link>
                                                            {Number(contentItem.children?.length) > 0 && (
                                                                <ul
                                                                    className={
                                                                        contentItem.children?.some((child) => child.url === this.props.activePath)
                                                                            ? "treeview-menu menu-open"
                                                                            : "treeview-menu"
                                                                    }
                                                                    style={{
                                                                        display: contentItem.children?.some(
                                                                            (child) => child.url === this.props.activePath
                                                                        )
                                                                            ? "block"
                                                                            : "none",
                                                                    }}
                                                                >
                                                                    {contentItem.children?.map(
                                                                        (itemChild) =>
                                                                            itemChild.roleFunctions.some((r) =>
                                                                                getCurrentUsersRoles().includes(r)
                                                                            ) && (
                                                                                <li
                                                                                    key={generateGUID()}
                                                                                    id={itemChild.name.replace(/\s/g, "").toLowerCase()}
                                                                                >
                                                                                    <NavLink
                                                                                        id={itemChild.name.replace(/\s/g, "").toLowerCase() + `link`}
                                                                                        activeClassName="treeview active"
                                                                                        to={itemChild.url}
                                                                                        style={{ textDecoration: "none" }}
                                                                                        onClick={(e) => {
                                                                                            // e.preventDefault();
                                                                                            // this.history.push(itemChild.url);
                                                                                        }}
                                                                                        replace
                                                                                    >
                                                                                        <i className={`icon ${itemChild.icon}`}></i>
                                                                                        {itemChild.name}
                                                                                    </NavLink>
                                                                                    {/* <CustomLink itemChild={itemChild} /> */}
                                                                                </li>
                                                                            )
                                                                    )}
                                                                </ul>
                                                            )}
                                                        </li>
                                                    )
                                                )
                                            )}
                                        </ul>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </div>
                                </div>
                            </div>

                            <SweepRunStatusModal showModal={this.state.showModal} toggleModal={this.toggleModal} item={this.state.sweepStatus} />

                            <LoanProductConfigModal
                                toggleLoanProductConfigModal={this.toggleLoanProductModal}
                                showLoanProductConfigModal={this.state.showLoanProductConfigModal}
                                institutions={this.state.institutions}
                            />

                            <InstitutionLoanProductConfigModal
                                toggleInstitutionLoanProductConfigModal={this.toggleInstitutionLoanProductModal}
                                showInstitutionLoanProductConfigModal={this.state.showInstitutionLoanProductConfigModal}
                                institutions={this.state.institutions}
                            />
                            {/* <!-- Side nav Ends --> */}
                        </div>
                    </div>
                </aside>

                <div className="has-sidebar-left">
                    <div className="pos-f-t">
                        <div className="collapse" id="navbarToggleExternalContent">
                            <div className="bg-dark pt-2 pb-2 pl-4 pr-2">
                                <div className="search-bar">
                                    <input
                                        className="transparent s-18 text-white b-0 font-weight-lighter w-128 height-50"
                                        type="text"
                                        placeholder="start typing..."
                                    />
                                </div>
                                <a
                                    href="index.html#"
                                    data-toggle="collapse"
                                    data-target="#navbarToggleExternalContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    className="paper-nav-toggle paper-nav-white active "
                                >
                                    <i></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#!" data-toggle="push-menu" className="paper-nav-toggle left ml-2 fixed">
                    <i></i>
                </a>
            </React.Fragment>
        );
    }
}

export default SideNav;
