import React, { Component } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import financialGroupService from "../../../services/approval/financialGroupService";
import { NonFinancial } from "./NonFinancial";
import { RuleTabs } from "./RuleTabs";

export interface IFinancialProps {
  rules: any;
  item: any;
  events: any;
  viewing: boolean;
}

export interface IFinancialState {
  item: any;
  rules: any;
}
export class Financial extends Component<IFinancialProps, IFinancialState> {
  constructor(props: IFinancialProps) {
    super(props);
    this.state = {
      rules: props.rules,
      item: props.item,
    };
  }

  componentDidMount() {
    this.setState({ rules: this.props.rules, item: this.props.item });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.item !== prevState.item) {
      return { rules: nextProps.rules, item: nextProps.item };
    } else return null;
  }

  handleNewGroup(nextGrp) {
    var last = this.state.item.financialGroups.length;
    this.state.item.financialGroups.splice(last, 0, nextGrp);
    this.setState({ item: this.state.item });
  }

  handleGroupDelete = (grp) => {
    var doDelete = () => {
      var toDelete = -1;
      var grps: any = [].concat(this.state.item.financialGroups);
      if (grps.length <= 1) {
        return;
      }
      grps.map((x, idx) => {
        if (x.id === grp.id) {
          toDelete = idx;
        }
      });
      if (toDelete === -1) {
        return;
      }
      grps.splice(toDelete, 1);
      var nextGrp = grps.length > 0 ? grps[0] : null;
      this.state.item.financialGroups = grps;
      this.setState({});
      toast.info(`Financial group removed successfully! `, {
        autoClose: 6000,
        type: toast.TYPE.DEFAULT,
        hideProgressBar: false,
      });
    };
    swal({
      text: `Are you Sure you want to delete Financial Group ${grp.id}? All rules will be deleted`,
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancel",
          value: false,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: { text: "Next", value: null, visible: true, className: "" },
      },
    })
      .then(async (resp) => {
        if (resp === false) return;
        if (parseInt(grp.id) === 0) throw "Group selected is not valid";
        await financialGroupService.removeFinancialGroup(grp.id);
        doDelete();
      })
      .catch((e) => {
        // console.log(e);
        swal({
          text: e.toString(),
          icon: "error",
        });
      });
  };

  render() {
    var { rules, item } = this.state;
    // console.log(this.props);
    //console.log(this.props.item.financialGroups)
    return [
      <RuleTabs
        groups={item.financialGroups}
        key={"rtabs_" + 0}
        // handleNewGroup={this.handleNewGroup}
        handleGroupDelete={this.handleGroupDelete}
        item={this.props.item}
        events={this.props.events}
        viewing={this.props.viewing}
      />,
      <div
        className="tab-content card"
        id="FinContentMD"
        key={"tab_content" + 0}
      >
        {this.props.item.financialGroups.map((x, idx) => (
          <div
            className={
              "tab-pane show fade" +
              (idx === this.props.item.financialGroups.length - 1
                ? " active"
                : "")
            }
            id={"fingrp_" + idx}
            role="tabpanel"
            aria-labelledby="home-tab-md"
            key={idx}
          >
            <h5 className="p-2 text-muted">
              <strong>Financial Group {x.id}: </strong>
              Minimum Amount: &nbsp;
              <span className="badge badge-primary">
                {" "}
                {x.lowerLimit.toLocaleString()}{" "}
              </span>{" "}
              &nbsp; &nbsp; Maximum Amount: &nbsp;
              <span className="badge badge-primary">
                {" "}
                {x.upperLimit.toLocaleString()}{" "}
              </span>
            </h5>
            <NonFinancial
              rules={rules}
              item={item}
              group={x}
              child={true}
              key={"rtb_" + idx}
              events={this.props.events}
              viewing={this.props.viewing}
            />
          </div>
        ))}
      </div>,
    ];
  }
}
