import React from "react";
import { toast } from "react-toastify";
import regionsService from "../../../services/auth/regionsService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import authValidationService from "../../../services/auth/authValidationService";
import { getCurrentUserSession } from "../../../services/auth/authService";
import FormInputComponent from "../../../components/formInputComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import { getIpAddress } from "../../../services/utility/textFormatService";
import { decodeToken } from "react-jwt";
import { ISelectItems } from "../../../components/detailItemSelectBox";
import branchesService from "../../../services/auth/branchesService";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";

interface AddRegionFields {
  name: string;
  code: string;
  description: string;
  address: string;
}

class AddRegionForm extends BaseFormComponent<AddRegionFields> { }

interface IAddRegionProps { }
interface IAddRegionState {
  clientip: any;
  assignedBranches: number[];
  branchesData: ISelectItems[];
  fetchingRequiredItems: boolean;
}

class AddRegion extends React.Component<
  IAddRegionProps,
  IBaseFormState & IAddRegionState
> {
  constructor(props: IAddRegionProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      clientip: {},
      assignedBranches: [],
      branchesData: [],
      fetchingRequiredItems: false,
    };
  }

  async componentDidMount() {
    // var response = await getIpAddress();
    // // console.log("test: ", response);
    // this.setState({ clientip: response.data });
    const { errors } = this.state;
    try {
      //   const decodedToken = decodeToken(getCurrentUserSession().token || "");
      this.setState({ fetchingRequiredItems: true })
      const response = await Promise.allSettled([
        getIpAddress(),
        branchesService.retrieveAllBranches()
      ])
      const clientipData = response[0].status == "fulfilled" ? response[0].value.data : {};
      const branches = response[1].status == "fulfilled" ? response[1].value.data : [];

      console.log(clientipData)
      this.setState({
        clientip: clientipData,
        fetchingRequiredItems: false,
        branchesData: branches.map(item => {
          return { name: item.name, value: item.id }
        })
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        errors.response = "Internal server error.";
        this.setState({ errors });
      }
    }
  }

  onFormSubmit(fields: AddRegionFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        { errors: authValidationService.validateAddRegionForm(fields) },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddRegionFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      const { clientip } = this.state;
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      // console.log("starting submission...");
      this.setState({ submitting: true });
      const payload: any = {};
      payload.name = fields.name;
      payload.code = fields.code;
      payload.description = fields.description;
      payload.address = fields.address;
      payload.clientIPAddress = clientip.geoplugin_request;
      payload.institutionId = decodedToken?.["InstitutionId"];
      payload.assignedBranches = this.state.assignedBranches;
      // console.log("payload: ", payload);
      const response = await regionsService.saveRegion(payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Add Region",
          description: `Added new Region ${fields.name}`,
        });

        if (response.data.isLoggedForApproval === true) {
          toast.warn("Action has been successfully logged for Approval!", {
            autoClose: 6000,
            type: toast.TYPE.WARNING,
            position: "top-center",
            hideProgressBar: false,
          });
        } else {
          toast.info(`Region ${fields.name} created successfully! `, {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          });
        }
      } else {
        return false;
      }
      this.setState({ submitting: false });
      onReloadFieldsCB();
    } catch (error) {
      // console.log("error: ", error.response.data);
      return false;
    } finally {
      this.setState({ submitting: false });
      return true;
    }
  }

  handleOnChangeBranches = async (item) => {
    const assignedBranches = [...this.state.assignedBranches];
    if (assignedBranches.findIndex(ab => { return ab === item.value }) !== -1) {
      assignedBranches.splice(assignedBranches.findIndex(ab => { return ab === item.value }), 1);
    }
    else {
      assignedBranches.push(item.value);
    }
    this.setState({ assignedBranches });
  };

  render() {
    const { errors, validated, branchesData, assignedBranches, fetchingRequiredItems } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add Region</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddRegionForm
                  initialValues={{
                    name: "",
                    code: "",
                    description: "",
                    address: "",
                  }}
                  FormComponent={({
                    fields: { name, code, description, address },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <h5 className="card-title">GENERAL INFORMATION</h5>
                          <div className="form-row">
                            <div className="col-md-12">
                              <div className="form-row">
                                <FormInputComponent
                                  id="name"
                                  type="text"
                                  name={"name"}
                                  placeholder=""
                                  divClass={6}
                                  value={name}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="code"
                                  type="text"
                                  name={"code"}
                                  placeholder=""
                                  divClass={6}
                                  value={code}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-6 m-0">
                                  <label
                                    htmlFor="description"
                                    className="col-form-label s-12"
                                  >
                                    <span
                                      aria-hidden="true"
                                      className="mr-2"
                                      style={{ color: "#ed5564" }}
                                    >
                                      *
                                    </span>
                                    Description
                                  </label>
                                  <textarea
                                    id="description"
                                    name="description"
                                    className={
                                      !validated
                                        ? "form-control r-0 light s-12"
                                        : errors.description
                                          ? "form-control r-0 light s-12 is-invalid"
                                          : "form-control r-0 light s-12 is-valid"
                                    }
                                    value={description}
                                    onChange={(e) =>
                                      onChange(
                                        "description",
                                        e.currentTarget.value
                                      )
                                    }
                                  ></textarea>
                                  <div
                                    className="invalid-feedback"
                                    style={{
                                      visibility: errors.description,
                                    }}
                                  >
                                    {errors.description}
                                  </div>
                                </div>
                                <div className="form-group col-md-6 m-0">
                                  <label
                                    htmlFor="address"
                                    className="col-form-label s-12"
                                  >
                                    <span
                                      aria-hidden="true"
                                      className="mr-2"
                                      style={{ color: "#ed5564" }}
                                    >
                                      *
                                    </span>
                                    Address
                                  </label>
                                  <textarea
                                    id="address"
                                    name="address"
                                    className={
                                      !validated
                                        ? "form-control r-0 light s-12"
                                        : errors.address
                                          ? "form-control r-0 light s-12 is-invalid"
                                          : "form-control r-0 light s-12 is-valid"
                                    }
                                    value={address}
                                    onChange={(e) =>
                                      onChange("address", e.currentTarget.value)
                                    }
                                  ></textarea>
                                  <div
                                    className="invalid-feedback"
                                    style={{
                                      visibility: errors.address,
                                    }}
                                  >
                                    {errors.address}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <h5 className="card-title">REGION BRANCHES</h5>
                          <div className="form-row">
                            <div className="col-md-12">
                              <label
                                htmlFor="branches"
                                className="control-label textbox-label pl-2"
                              >
                                Branches
                              </label>
                              <div
                                className="block-example border"
                                style={{
                                  width: "100%",
                                  maxWidth: "100%",
                                  overflowX: "hidden",
                                  height: "300px",
                                  overflowY: "scroll",
                                }}
                              >
                                {fetchingRequiredItems ? (
                                  <Skeleton count={6} width={300} />
                                ) : (
                                  branchesData.length > 0 ? (
                                    <React.Fragment>
                                      {branchesData.map(
                                        (item) => (
                                          <div style={{ border: '2px #84d4fc solid', borderRadius: '4px', backgroundColor: '#e4f4fc', marginTop: '5px', inlineSize: "100%" }}>
                                            <div
                                              className="custom-control custom-checkbox"
                                              style={{ display: 'inline-block' }}
                                              key={branchesData.indexOf(item)}
                                            >
                                              <input
                                                id={item.name}
                                                name={item.name}
                                                className="custom-control-input"
                                                type="checkbox"
                                                onChange={() =>
                                                  this.handleOnChangeBranches(
                                                    item
                                                  )
                                                }
                                                checked={
                                                  _.isEmpty(
                                                    assignedBranches.filter(
                                                      (x) => x === item.value
                                                    )
                                                  )
                                                    ? false
                                                    : true
                                                }
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor={item.name}
                                              >
                                                {item.name}
                                              </label>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    "No Branch has been assigned to Region"
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="card-body">
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                            disabled={this.state.submitting}
                            onClick={(e) => {
                              onHandleSubmit(
                                e,
                                ActionTypes.SAVE,
                                "Region",
                                () => {
                                  this.onFormSubmit(
                                    {
                                      name,
                                      code,
                                      description,
                                      address,
                                    },
                                    onReloadFields
                                  );
                                }
                              );
                            }}
                          >
                            {this.state.submitting === false ? (
                              <React.Fragment>
                                <i className="fas fa-lg fa-save mr-3" /> Save
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                Please wait...
                              </React.Fragment>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                ></AddRegionForm>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddRegion;
