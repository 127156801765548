import React, { Component } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import BaseFormComponent, { IBaseFormState } from "../../../../../../components/BaseFormComponent";
import { ActionTypes } from "../../../../../../enums/actionTypes";
import { getCurrentUserSession, getCurrentUsersRoles } from "../../../../../../services/auth/authService";
import loanRequestService from "../../../../../../services/hybridAppraisal/loanRequestService";
import userService from "../../../../../../services/auth/usersService";
import { formatTimeString } from "../../../../../../services/utility/textFormatService";
import hybridAppraisalValidationService from "../../../../../../services/hybridAppraisal/hybridAppraisalValidationService";

interface NotepadFields {
  username: string;
  date: string;
  noteDetails: string;
}

class NotepadForm extends BaseFormComponent<NotepadFields> { }

interface NotepadProps {
  values: any;
  reloadItem: any;
  mandatoryFields: any;
}

interface NotepadState {
  notepads: NotepadFields[];
  currentUser: any;
  ownUpdate: boolean;
}

class Notepad extends Component<
  NotepadProps,
  IBaseFormState & NotepadState
> {
  constructor(props: NotepadProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      notepads: [],
      currentUser: {},
      ownUpdate: false
    };
  }

  static getDerivedStateFromProps(
    nextProps: NotepadProps,
    prevState: NotepadState
  ) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isEmpty(nextProps.values.notepads)) {
      return null;
    }
    return {
      notepads: nextProps.values.notepads
    };
  }

  async componentDidMount() {
    const currentUser = await userService.retrieveUserById(getCurrentUserSession().userId)
    this.setState({ currentUser: currentUser.data, ownUpdate: true })
  }

  onFormSubmit(fields: NotepadFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        { errors: {}, ownUpdate: true },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: NotepadFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const loanRequest = await loanRequestService.retrieveById(this.props.values.id);
      const loanRequestDetail = loanRequest.data;
      const payload: any = { ...loanRequestDetail };
      payload.notepads = this.state.notepads
      const response = await loanRequestService.updateLoanRequest(payload);
      if (response.status >= 200 && response.status <= 300) {
        toast.info(`Notepad Details saved successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false, ownUpdate: true });
      this.resetFields();
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false, ownUpdate: true });
      return false;
    } finally {
      return true;
    }
  }

  handleOnAddNotepad = async (item: NotepadFields, onReloadFieldsCB: any) => {
    const { mandatoryFields } = this.props;
    try {
      this.setState(
        { errors: hybridAppraisalValidationService.validateAddNotepadForm(item, mandatoryFields), ownUpdate: true },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.setState({ validated: true, ownUpdate: true });
            const notepads = [...this.state.notepads];
            const { currentUser } = this.state;
            item.username = currentUser.firstName + " " + currentUser.lastName
            notepads.push(item);
            this.setState({ notepads, ownUpdate: true });
            this.resetFields();
            onReloadFieldsCB();
          }
        }
      )
    } catch (error) {
      return false;
    }
  };

  handleOnRemoveNotepad = (item: NotepadFields) => {
    const notepads = [...this.state.notepads];
    if (notepads.includes(item)) {
      notepads.splice(
        notepads.indexOf(item), 1
      );
    }
    this.setState({ notepads, ownUpdate: true });
  };

  getRequiredFields(title): boolean {
    const { mandatoryFields } = this.props;
    var required = mandatoryFields?.inputFields?.filter(x => x.fieldName === title)[0]?.mandatory;
    if (required === undefined) {
      return false;
    }
    return required;
  }

  resetFields() {
    this.setState({ validated: false, submitting: false, errors: [], ownUpdate: true });
  }

  render() {
    const { values } = this.props;
    const { errors, validated, notepads } = this.state;

    return (
      <NotepadForm
        initialValues={{
          username: "",
          date: new Date().toJSON(),
          noteDetails: ""
        }}
        FormComponent={({
          fields: {
            username,
            date,
            noteDetails
          },
          onChange,
          onReloadFields,
          onHandleSubmit,
        }) => (
          <div className="form-row">
            <div className="col-md-12">

              {/* Note Details */}
              <div className="form-row">
                <div className="form-group col-md-12 m-0">
                  <label
                    htmlFor="noteDetails"
                    className="col-form-label s-12"
                  >
                    {this.getRequiredFields("noteDetails") ? (
                      <span
                        aria-hidden="true"
                        className="mr-2"
                        style={{ color: "#ed5564" }}
                      >
                        *
                      </span>
                    ) : ""}
                    Note Details
                  </label>
                  <textarea
                    id="noteDetails"
                    name="noteDetails"
                    className={
                      !validated
                        ? "form-control r-0 light s-12"
                        : errors.noteDetails
                          ? "form-control r-0 light s-12 is-invalid"
                          : "form-control r-0 light s-12 is-valid"
                    }
                    value={noteDetails}
                    onChange={(e) =>
                      onChange(
                        "noteDetails",
                        e.currentTarget.value
                      )
                    }
                  ></textarea>
                  <div
                    // className="invalid-feedback"
                    style={{
                      visibility: errors.noteDetails,
                    }}
                  >
                    {errors.noteDetails}
                  </div>
                </div>
              </div>

              {/* Add Notepad */}
              <div className="form-row">
                <div className="my-1 mx-2">
                  {(getCurrentUsersRoles().includes("updatedataentry") && (
                    <button
                      className="btn btn-sm btn-outline-primary my-4"
                      style={{ cursor: "pointer" }}
                      type="button"
                      onClick={() =>
                        this.handleOnAddNotepad(
                          {
                            username,
                            date,
                            noteDetails
                          },
                          onReloadFields
                        )
                      }
                    >
                      <React.Fragment>
                        <i className="far fa-save fa-lg mr-2" />{" "}
                        Add
                      </React.Fragment>
                    </button>
                  ))}
                </div>
              </div>

              {/* Notpad Table */}
              <div className=" form-row col-md-12">
                <div
                  className="table-responsive text-nowrap"
                  style={{
                    maxHeight: "150px",
                    overflowY: "scroll",
                  }}
                >
                  <table className="table table-hover table-content table-sm table-striped mb-0">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>#</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Username</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Date/Time</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Details</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {notepads.map((item) => (
                        <tr key={notepads.indexOf(item)}>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {notepads.indexOf(item) + 1}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.username}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {formatTimeString(item.date)}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.noteDetails}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            <i
                              className="far fa-trash-alt ml-5 red-text fa-lg"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                this.handleOnRemoveNotepad(
                                  item
                                )
                              }
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Save Notepad */}
              <div className="card-body float-right">
                {(getCurrentUsersRoles().includes("updatedataentry") && (
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary"
                    disabled={this.state.submitting}
                    onClick={(e) => {
                      onHandleSubmit(
                        e,
                        ActionTypes.SAVE,
                        "Notepad",
                        () => {
                          this.onFormSubmit(
                            {
                              username,
                              date,
                              noteDetails
                            },
                            onReloadFields
                          )
                        }
                      );
                    }}
                  >
                    {this.state.submitting === false ? (
                      <React.Fragment>
                        <i className="fas fa-lg fa-save mr-3" /> Save
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                        Please wait...
                      </React.Fragment>
                    )}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
      ></NotepadForm>
    );
  }
}

export default Notepad;