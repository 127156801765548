import React from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import BaseFormComponent, {
    IBaseFormState,
} from "../../../components/BaseFormComponent";
import billingValidationService from "../../../services/billing/billingValidationService";
import { ISelectItems } from "../../../components/detailItemSelectBox";
import FormInputComponent from "../../../components/formInputComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import SelectInputComponent from "../../../components/formSelectComponent";
import thirdPartyFeeConfigService from "../../../services/billing/thirdPartyFeeConfigService";
import HybridAppraisalBillingService from "../../../services/billing/HybridAppraisalBillingService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import institutionFeeConfigService from "../../../services/billing/institutionFeeConfigService";
import sweepConfigService from "../../../services/sweep/sweepConfigService";
import institutionsService from "../../../services/auth/institutionsService";
import feePayableWalletService from "../../../services/billing/feePayableWalletService";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../../services/auth/authService";
import rolesService from "../../../services/auth/rolesService";
import appraisalInstitutionService from "../../../services/hybridAppraisal/appraisalInstitutionService";
import appraisalPlanService from "../../../services/hybridAppraisal/appraisalPlanService";
import { usePaystackPayment } from "react-paystack";

interface feePaymentFields {
    institutionCategory: string;
    paymentType: string;
}

class FeePaymentForm extends BaseFormComponent<feePaymentFields> { }

interface IfeePaymentProps { }
interface IfeePaymentState {
    isInvoiceGenerated: boolean;
    institutionCode: string;
    configurationId: string;
    invoice: any;
    institutionCategory: string;
    config: any;
    paymentType: string;
}

class mandatefeePayment extends React.Component<
    IfeePaymentProps,
    IBaseFormState & IfeePaymentState
> {
    _isMounted: boolean;
    constructor(props: IfeePaymentProps) {
        super(props);
        this.state = {
            validated: false,
            submitting: false,
            errors: {},
            isInvoiceGenerated: false,
            institutionCode: "",
            configurationId: "",
            invoice: [],
            institutionCategory: "",
            config: {},
            paymentType: ""
        };
    }

    async componentDidMount() {
        this._isMounted = true;
        const decodedToken = decodeToken(getCurrentUserSession().token || "");
        const response = await Promise.allSettled([
            institutionFeeConfigService.filterInstitutionSubscriptionFeeConfig(decodedToken?.["InstitutionCode"])
        ])
        const institutionConfig_ = response[0].status == "fulfilled" ? response[0].value.data.data : [];
        let institutionConfig = institutionConfig_.filter(x => x.serviceOffering === "Mandate")
        if (this._isMounted) {
            this.setState({
                configurationId: institutionConfig[0]?.institutionHybridFeeConfigurationId,
                institutionCode: institutionConfig[0]?.institutionCode
            });
        }


    }
    onClose = () => {
        // alert('Payment window closed.');
        toast.info(`Payment window closed.`, {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
        });
    };

    onSuccess = () => {
        // Implementation for whatever you want to do with reference and after success call.
        // console.log(reference);
        this.callBilling();

    };

    callBilling = async () => {
        const payload: any = {};
        payload.invoiceNumber = this.state.invoice.invoiceNumber;
        // console.log(payload);
        // exit
        try {
            const response = await feePayableWalletService.completeGatewayPayment(payload);
            if (response.status >= 200 && response.status <= 300) {
                await activityTrailService.saveActivityTrail({
                    actionType: "Fee Payment",
                    description: `Successful Hybrid Appraisal Fee Payment for ${this.state.institutionCode}`,
                });
                toast.info(`Fee Payment successful! `, {
                    autoClose: 6000,
                    type: toast.TYPE.DEFAULT,
                    hideProgressBar: false,
                });
            }
            else {
                toast.info(`Fee Payment Update Unsuccessful, Please contact Appzone with Invoice Number`, {
                    autoClose: 6000,
                    type: toast.TYPE.DEFAULT,
                    hideProgressBar: false,
                });
                return false;
            }
        }
        catch {
            toast.info(`Fee Payment Update Unsuccessful, Please contact Appzone with Invoice Number`, {
                autoClose: 6000,
                type: toast.TYPE.DEFAULT,
                hideProgressBar: false,
            });
        }
    }

    PaystackHookExample = () => {
        const initializePayment = usePaystackPayment(this.state.config);
        return (
            <div>
                <button
                    type="submit"
                    className="btn btn-sm btn-primary"
                    // disabled={this.state.submitting}
                    onClick={() => {
                        initializePayment(this.onSuccess, this.onClose)
                    }}><i className="fas fa-lg fa-save mr-3" /> Pay   N{this.state.config.amount / 100}</button>
            </div>
        );
    };

    onGenerateInvoice(institutionCategory: any, onReloadFieldsCB: any): boolean {
        try {
            this.setState(
                {},
                () => {
                    if (Object.keys(this.state.errors).length === 0) {
                        return this.generateInvoice(institutionCategory, onReloadFieldsCB);
                    }
                }
            );
            this.setState({ validated: true });
            return false;
        } catch (error) {
            return false;
        }
    }

    async generateInvoice(
        institutionCategory: any,
        onReloadFieldsCB: any
    ): Promise<boolean> {
        try {

            this.setState({ submitting: true });
            let paymentType: string = ""
            if (institutionCategory.institutionCategory === "BankOneFI") {
                paymentType = "FundsTransfer"
            }
            else {
                paymentType = "ThirdParty"
            }
            const payload: any = {};

            payload.configurationId = this.state.configurationId;
            payload.institutionCode = this.state.institutionCode;
            // console.log("payload: ", payload);

            const invoice = await feePayableWalletService.generateInvoice(payload);
            // console.log(invoice);
            if (invoice.status >= 200 && invoice.status <= 300) {
                var invoiceDetails = invoice.data
                this.setState({ isInvoiceGenerated: true, invoice: invoiceDetails });
            }
            const config = {
                reference: "AZL" + this.state.invoice.invoiceNumber + (new Date()).getTime().toString(),
                email: "user@example.com",
                amount: this.state.invoice?.grandTotal * 100,
                // amount: 20000,
                publicKey: "pk_test_ee2219d4e71af7553e712a4e6bfb9dfeb2169c51",
            };
            this.setState({ submitting: false, config: config });


            onReloadFieldsCB();
        }
        catch (error) {
            if (error.response.status === 409) {
                toast.error(error.response.data.detail, {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            } else if (error.response.status === 400) {
                toast.error(error.response.data.errors[0], {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            }
        } finally {
            this.setState({ submitting: false });
            return true;
        }
    }

    onFormSubmit(fields: feePaymentFields, onReloadFieldsCB: any): boolean {
        try {
            this.setState(
                {},
                () => {
                    if (Object.keys(this.state.errors).length === 0) {
                        return this.submit(fields, onReloadFieldsCB);
                    }
                }
            );
            this.setState({ validated: true });
            return false;
        } catch (error) {
            return false;
        }
    }

    async submit(
        fields: feePaymentFields,
        onReloadFieldsCB: any
    ): Promise<boolean> {
        try {
            this.setState({ submitting: true });
            const payload: any = {};
            payload.invoiceNumber = this.state.invoice.invoiceNumber;
            payload.institutionCode = this.state.institutionCode;
            const response = await feePayableWalletService.PayWithFundsTransfer(payload);
            if (response.status >= 200 && response.status <= 300) {
                await activityTrailService.saveActivityTrail({
                    actionType: "Mandate Fee Payment",
                    description: `Successful Mandate Fee Payment for ${this.state.institutionCode}`,
                });
                toast.info(`Fee Payment successful! `, {
                    autoClose: 6000,
                    type: toast.TYPE.DEFAULT,
                    hideProgressBar: false,
                });
            }
            this.setState({ submitting: false });
            onReloadFieldsCB();
        }
        catch (error) {
            if (error.response.status === 409) {
                toast.error(error.response.data.detail, {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            } else if (error.response.status === 400) {
                toast.error(error.response.data.errors[0], {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            }
        } finally {
            this.setState({ submitting: false });
            return true;
        }
    }

    handleOnInstitutionCategoryChange = async (id, institutionCategory: string) => {
        this.setState({
            institutionCategory
        })
    };

    render() {
        const { errors, validated } = this.state;

        let institutionCategoryInputData: ISelectItems[] = [
            { name: "Commercial Bank", value: "CommercialBank" },
            { name: "BankOne FI", value: "BankOneFI" },
            { name: "OFI", value: "OFI" },
        ];

        let paymentTypeInputData: ISelectItems[] = [
            { name: "Funds Transfer", value: "FundsTransfer" },
            { name: "Paystack", value: "Paystack" },
        ];

        return (
            <React.Fragment>
                <div className="container-fluid relative animatedParent animateOnce">
                    <div className="animated fadeInUpShort go">
                        <div className="row my-3 mx-2">
                            <h3>
                                <b>Mandate Fee Payment</b>
                            </h3>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <FeePaymentForm
                                    initialValues={{
                                        institutionCategory: "",
                                        paymentType: ""
                                    }}
                                    FormComponent={({
                                        fields: {
                                            institutionCategory,
                                            paymentType
                                        },
                                        onChange,
                                        onReloadFields,
                                        onHandleSubmit,
                                    }) => (
                                        <form action="#">
                                            <div className="card no-b">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-row">
                                                                <SelectInputComponent
                                                                    id="institutionCategory"
                                                                    name="institutionCategory"
                                                                    divClass={6}
                                                                    value={institutionCategory}
                                                                    items={institutionCategoryInputData}
                                                                    required={true}
                                                                    validated={true}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                />

                                                            </div>
                                                            <div className="card-body">
                                                                {this.state.configurationId === undefined || this.state.configurationId === null || this.state.configurationId === "" ? (
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-sm btn-primary"
                                                                        disabled={true}
                                                                        onClick={(e) => { }}
                                                                    >
                                                                        <React.Fragment>
                                                                            <i className="fas fa-lg fa-save mr-3" /> No Mandate Fee Configured
                                                                        </React.Fragment>

                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-sm btn-primary"
                                                                        disabled={this.state.submitting}
                                                                        onClick={(e) => {
                                                                            onHandleSubmit(
                                                                                e,
                                                                                ActionTypes.SAVE,
                                                                                "Mandate Fee Configuration",
                                                                                () => {
                                                                                    this.onGenerateInvoice(
                                                                                        {
                                                                                            institutionCategory
                                                                                        },
                                                                                        onReloadFields
                                                                                    );
                                                                                }
                                                                            );
                                                                        }}
                                                                    >
                                                                        {this.state.submitting === false ? (
                                                                            <React.Fragment>
                                                                                <i className="fas fa-lg fa-save mr-3" /> Generate Invoice
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                                                                Please wait...
                                                                            </React.Fragment>
                                                                        )}
                                                                    </button>
                                                                )}
                                                            </div>
                                                            {!this.state.isInvoiceGenerated ? ("") : (
                                                                <div className="form-row">
                                                                    <FormInputComponent
                                                                        id="invoiceNumber"
                                                                        type="text"
                                                                        name="invoiceNumber"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={this.state.invoice.invoiceNumber}
                                                                        required={false}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                        disabled={true}
                                                                    />
                                                                    <FormInputComponent
                                                                        id="subscriptionPrice"
                                                                        type="number"
                                                                        name="subscriptionPrice"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={this.state.invoice.subscriptionPrice}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                        disabled={true}
                                                                    />
                                                                    <FormInputComponent
                                                                        id="outstantingPaymentRequestCount"
                                                                        type="number"
                                                                        name="outstantingPaymentRequestCount"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={this.state.invoice.outstantingPaymentRequestCount}
                                                                        required={false}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                        disabled={true}
                                                                    />
                                                                    <FormInputComponent
                                                                        id="outstandingPaymentRequestPrice"
                                                                        type="number"
                                                                        name="outstandingPaymentRequestPrice"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={this.state.invoice.outstandingPaymentRequestPrice}
                                                                        required={false}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                        disabled={true}
                                                                    />
                                                                    <FormInputComponent
                                                                        id="totalAmount"
                                                                        type="number"
                                                                        name="totalAmount"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={this.state.invoice.totalAmount}
                                                                        required={false}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                        disabled={true}
                                                                    />
                                                                    <FormInputComponent
                                                                        id="vatAmount"
                                                                        type="number"
                                                                        name="vatAmount"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={this.state.invoice.vatAmount}
                                                                        required={false}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                        disabled={true}
                                                                    />
                                                                    <FormInputComponent
                                                                        id="AmountToBePaid"
                                                                        type="number"
                                                                        name="AmountToBePaid"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={this.state.invoice.grandTotal}
                                                                        required={false}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                        disabled={true}
                                                                    />
                                                                </div>

                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            {!this.state.isInvoiceGenerated ? ("") : (
                                                this.state.paymentType === "FundsTransfer" ? (
                                                    <div className="card-body">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-sm btn-primary"
                                                            disabled={this.state.submitting}
                                                            onClick={(e) => {
                                                                onHandleSubmit(
                                                                    e,
                                                                    ActionTypes.SAVE,
                                                                    "Hybrid Appraisal Subscription Fee Configuration",
                                                                    () => {
                                                                        this.onFormSubmit(
                                                                            {
                                                                                institutionCategory,
                                                                                paymentType
                                                                            },
                                                                            onReloadFields
                                                                        );
                                                                    }
                                                                );
                                                            }}
                                                        // onClick={() => {
                                                        //     initializePayment(this.onSuccess, this.onClose)
                                                        // }}
                                                        >
                                                            {this.state.submitting === false ? (
                                                                <React.Fragment>
                                                                    <i className="fas fa-lg fa-save mr-3" /> Save
                                                                </React.Fragment>
                                                            ) : (
                                                                <React.Fragment>
                                                                    <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                                                    Please wait...
                                                                </React.Fragment>
                                                            )}
                                                        </button>


                                                        {/* <this.PaystackHookExample /> */}
                                                    </div>
                                                ) : (
                                                    <div className="card-body">
                                                        <this.PaystackHookExample />
                                                    </div>
                                                )
                                            )}
                                        </form>
                                    )}
                                ></FeePaymentForm>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default mandatefeePayment;