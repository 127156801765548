import http from "../httpService";

const reportBaseAddress = process.env.REACT_APP_REPORTSCENTER_BASEADDRESS

export function filterCollectionScheduleReport(
  pageSize: number,
  pageNumber: number,
  data: any
) {
  var customerName = typeof data.customerName === "undefined" ? "" : data.customerName
  var loanReference = typeof data.loanReference === "undefined" ? "" : data.loanReference
  var creationStartDate = typeof data.scheduleCreationDate === "undefined" ? "" : data.scheduleCreationDate.slice(1, 11)
  var creationEndDate = typeof data.scheduleCreationDate === "undefined" ? "" : data.scheduleCreationDate.slice(18, 28)
  var expectedStartDate = typeof data.expectedDate === "undefined" ? "" : data.expectedDate.slice(1, 11)
  var expectedEndDate = typeof data.expectedDate === "undefined" ? "" : data.expectedDate.slice(18, 28)
  return http.get(
    reportBaseAddress +
    `v1/collection-schedule-report?PageNumber=${pageNumber}&PageSize=${pageSize
    }&CustomerName=${customerName}&LoanReference=${loanReference
    }&CreationStartDate=${creationStartDate}&CreationEndDate=${creationEndDate
    }&ExpectedStartDate=${expectedStartDate}&ExpectedEndDate=${expectedEndDate}`,

  );
}

export function filterCollectionReport(
  pageSize: number,
  pageNumber: number,
  data: any
) {
  var customerName = typeof data.customerName === "undefined" ? "" : data.customerName
  var mandateReference = typeof data.mandateReference === "undefined" ? "" : data.mandateReference
  var loanReference = typeof data.loanReference === "undefined" ? "" : data.loanReference
  var transactionStartDate = typeof data.transactionDateTime === "undefined" ? "" : data.transactionDateTime.slice(1, 11)
  var transactionEndDate = typeof data.transactionDateTime === "undefined" ? "" : data.transactionDateTime.slice(18, 28)
  var mandateSetUpStartDate = typeof data.mandateSetUpDate === "undefined" ? "" : data.mandateSetUpDate.slice(1, 11)
  var mandateSetUpEndDate = typeof data.mandateSetUpDate === "undefined" ? "" : data.mandateSetUpDate.slice(18, 28)
  var expectedStartDate = typeof data.expectedDate === "undefined" ? "" : data.expectedDate.slice(1, 11)
  var expectedEndDate = typeof data.expectedDate === "undefined" ? "" : data.expectedDate.slice(18, 28)
  var transactionStatus = typeof data.transactionStatus === "undefined" ? "Success" : data.transactionStatus
  return http.get(
    reportBaseAddress +
    `v1/collections-report?PageNumber=${pageNumber}&PageSize=${pageSize
    }&CustomerName=${customerName}&MandateReference=${mandateReference
    }&LoanReference=${loanReference}&TransactionStartDate=${transactionStartDate
    }&TransactionEndDate=${transactionEndDate}&MandateSetUpStartDate=${mandateSetUpStartDate
    }&MandateSetUpEndDate=${mandateSetUpEndDate}&ExpectedStartDate=${expectedStartDate
    }&ExpectedEndDate=${expectedEndDate}&TransactionStatus=${transactionStatus}`,
  );
}

export function filterCollectionSummaryReport(
  pageSize: number,
  pageNumber: number,
  data: any
) {
  var customerName = typeof data.customerName === "undefined" ? "" : data.customerName
  var loanReference = typeof data.loanReference === "undefined" ? "" : data.loanReference
  var mandateSetUpStartDate = typeof data.mandateSetUpDate === "undefined" ? "" : data.mandateSetUpDate.slice(1, 11)
  var mandateSetUpEndDate = typeof data.mandateSetUpDate === "undefined" ? "" : data.mandateSetUpDate.slice(18, 28)
  var creationStartDate = typeof data.dateCreated === "undefined" ? "" : data.dateCreated.slice(1, 11)
  var creationEndDate = typeof data.dateCreated === "undefined" ? "" : data.dateCreated.slice(18, 28)
  return http.get(
    reportBaseAddress +
    `v1/collection-summary-report?PageNumber=${pageNumber}&PageSize=${pageSize
    }&CustomerName=${customerName}&LoanReference=${loanReference
    }&MandateSetUpStartDate=${mandateSetUpStartDate}&MandateSetUpEndDate=${mandateSetUpEndDate
    }&CreationStartDate=${creationStartDate}&CreationEndDate=${creationEndDate}`,
  );
}

export function filterCollectionsReport(
  institutionCode: string,
  data: any,
  currentPage: number,
  pageSize: number
) {
  // console.log(data)
  var institutionAppend = institutionCode === "" || institutionCode === null || institutionCode === "000" ? "?" : `?institutionCode=${institutionCode}&`

  var customerName = typeof data.customerName === "undefined" ? "" : data.customerName.item2
  var institution = typeof data.institutionCode === "undefined" ? "" : data.institutionCode.item2
  var institutionAppend2 = institution === "" || institution === null || institution === "000" ? "" : `&institutionCode=${institution}`
  var transactionReference = typeof data.transactionReference === "undefined" ? "" : data.transactionReference.item2
  var mandateReference = typeof data.mandateReference === "undefined" ? "" : data.mandateReference.item2
  var transactionStatus = typeof data.transactionStatus === "undefined" ? "Success" : data.transactionStatus.item2

  var status = transactionStatus === "Successful" ? true : false
  // var searchTerm = typeof data.searchTerm === "undefined" ? "" : data.searchTerm.item2
  var startDate = typeof data.transactionDate === "undefined" ? "" : data.transactionDate.item2.slice(1, 11)
  var endDate = typeof data.transactionDate === "undefined" ? "" : data.transactionDate.item2.slice(18, 28)
  var settlementStatus = typeof data.settlementStatus === "undefined" ? "" : data.settlementStatus.item2
  var settlementStatusAppend = settlementStatus === "" || settlementStatus === null ? "" : `&settlementStatus=${settlementStatus}`
  return http.get(
    reportBaseAddress +
    `v1/inflow/collections/report${institutionAppend}customerName=${customerName}&transactionReference=${transactionReference
    }&mandateReference=${mandateReference
    }&startDate=${startDate
    }&endDate=${endDate}${settlementStatusAppend}&PageNumber=${currentPage}&PageSize=${pageSize}${institutionAppend2}&LoadSuccessfulTransactionsOnly=${status}&TransactionStatus=${transactionStatus}`,
  );
}

export function filterRecoveryReport(
  pageSize: number,
  pageNumber: number,
  data: any
) {
  var customerName = typeof data.customerName === "undefined" ? "" : data.customerName
  var loanReference = typeof data.loanReference === "undefined" ? "" : data.loanReference
  var mandateReference = typeof data.mandateReference === "undefined" ? "" : data.mandateReference
  var mandateSetUpStartDate = typeof data.mandateSetUpDate === "undefined" ? "" : data.mandateSetUpDate.slice(1, 11)
  var mandateSetUpEndDate = typeof data.mandateSetUpDate === "undefined" ? "" : data.mandateSetUpDate.slice(18, 28)
  var transactionStartDate = typeof data.transactionDateTime === "undefined" ? "" : data.transactionDateTime.slice(1, 11)
  var transactionEndDate = typeof data.transactionDateTime === "undefined" ? "" : data.transactionDateTime.slice(18, 28)
  var transactionStatus = typeof data.transactionStatus === "undefined" ? "" : data.transactionStatus
  return http.get(
    reportBaseAddress +
    `v1/recovery-report?PageNumber=${pageNumber}&PageSize=${pageSize
    }&CustomerName=${customerName}&LoanReference=${loanReference
    }&MandateReference=${mandateReference}&MandateSetUpStartDate=${mandateSetUpStartDate
    }&MandateSetUpEndDate=${mandateSetUpEndDate}&TransactionStartDate=${transactionStartDate
    }&TransactionEndDate=${transactionEndDate}&TransactionStatus=${transactionStatus}`
  );
}

export function filterRecoverySummaryReport(
  pageSize: number,
  pageNumber: number,
  data: any
) {

  var customerName = typeof data.customerName === "undefined" ? "" : data.customerName
  var loanReference = typeof data.loanReference === "undefined" ? "" : data.loanReference
  var mandateRequestReference = typeof data.mandateReference === "undefined" ? "" : data.mandateReference
  var mandateSetUpStartDate = typeof data.mandateSetUpDate === "undefined" ? "" : data.mandateSetUpDate.slice(1, 11)
  var mandateSetUpEndDate = typeof data.mandateSetUpDate === "undefined" ? "" : data.mandateSetUpDate.slice(18, 28)
  return http.get(
    reportBaseAddress +
    `v1/recovery-summary-report?PageNumber=${pageNumber}&PageSize=${pageSize
    }&CustomerName=${customerName}&LoanReference=${loanReference
    }&MandateRequestReference=${mandateRequestReference}&MandateSetUpStartDate=${mandateSetUpStartDate
    }&MandateSetUpEndDate=${mandateSetUpEndDate}`,
  );
}

export default {
  filterCollectionScheduleReport,
  filterCollectionReport,
  filterCollectionsReport,
  filterCollectionSummaryReport,
  filterRecoveryReport,
  filterRecoverySummaryReport
};
