import React, { Fragment } from "react";
import BaseListComponent, {
  BaseListComponentState,
  IFilterData,
} from "../../../components/BaseListComponent";
import ItemsTable, {
  filterFieldData,
} from "../../../components/tableComponents/itemsTable";
import loanProductService from "../../../services/auth/loanProductService";
import TableFilterPane from "../../../components/tableComponents/tableFilterPane";
import {
  createFilterData,
  mapEntityList,
} from "./../../../services/utility/autoMapperService";
import SystemLoanProductDetail from "./systemLoanProductDetail";
import { getCurrentUsersRoles } from "../../../services/auth/authService";

export interface ISystemLoanProductsProps { }

export interface ISystemLoanProductsState extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
}

class SystemLoanProductsList extends BaseListComponent<
  ISystemLoanProductsState
> { }

class SystemLoanProducts extends React.Component<
  ISystemLoanProductsProps,
  ISystemLoanProductsState
> {
  constructor(props: ISystemLoanProductsProps) {
    super(props);
    this.state = {
      data: createFilterData(["name", "code"]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [
        { name: "name", fieldType: "text", value: "" },
        { name: "code", fieldType: "text", value: "" },
        // {
        //   name: "status",
        //   fieldType: "select",
        //   options: ["Active", "Inactive"],
        //   value: "",
        // },
      ],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
    };
  }

  handlePageChange = async (currentPage: number, pageSize: number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true });
    data = typeof data === "undefined" ? this.state.data : data;
    try {
      const products = await loanProductService.filterLoanProducts(
        data,
        pageSize,
        currentPage
      );
      this.setState({
        tableItems: mapEntityList({
          entityList: products.data.item2,
          properties: [
            { oldName: "code", newName: "productCode" },
            { oldName: "name", newName: "productName" },
            // {
            //   oldName: "productDiscriminator",
            //   newName: "productDiscriminator",
            // },
            { oldName: "productUseCase", newName: "loanProductUseCase" },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: products.data.item1,
        fetching: false,
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
        // this.setState({ errors, fetching: false });
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        // this.setState({ errors, fetching: false });
      }
    } finally {
      this.setState({ fetching: false });
    }
  };

  handleOnExport = async (type: string) => { };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  fetchItemById = async (item: any) => {
    this.setState({ fetchingDetail: true });
    const product = await loanProductService.retrieveById(item.id);
    this.setState({ item: product.data, fetchingDetail: false });
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }

  render() {
    const {
      // data,
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>System Loan Products</b>
              </h3>
            </div>
            <SystemLoanProductsList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              initialValues={{
                data: createFilterData([
                  "code",
                  "name",
                ]) as IFilterData,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: [
                  { name: "name", fieldType: "text", value: "" },
                  { name: "code", fieldType: "text", value: "" },
                ],
                item: {},
                jsonBefore: {},
                jsonAfter: {},
                fetchingTrail: false,
                entityName: "Recova.Auth.LoanProduct",
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                onToggleEditModal,
                showDetailsModal,
                showEditModal,
                fetchTrailItem,
                fetchingTrail,
                jsonAfter,
                jsonBefore,
                onToggleTrailModal,
                showTrailModal,
                trailItem,
                trailMapper,
              }) => (
                <Fragment>
                  <SystemLoanProductDetail
                    item={this.state.item}
                    toggleDetailsModal={onToggleDetailsModal}
                    currentUserRoles={getCurrentUsersRoles()}
                    showDetailsModal={showDetailsModal}
                  />
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="System Loan Products"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></SystemLoanProductsList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SystemLoanProducts;
