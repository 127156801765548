import http from "../httpService";
import axios from "axios";
import { getCurrentUserSession } from "../auth/authService";
import { IFilterData } from "../approval/approvalItemService";
import { decodeToken } from "react-jwt";

interface ISearchMandatesData {
  mandateType: string;
  bvn: string;
  loanAmount: string;
  phoneNumber: string;
}
interface IValidateMandateRecords {
  mandateRecords: string;
}
interface IValidateLoanApplicationFormsDocument
  extends IValidateMandateRecords {
  loanApplicationForms: {
    document: string;
    archiveType: string;
  };
}
interface IValidateRightOfSetOffsDocument extends IValidateMandateRecords {
  rightOfSetOffs: {
    document: string;
    archiveType: string;
  };
}
interface IUploadMandatesDocument extends IValidateMandateRecords {
  institutionId: any;
  rightOfSetOffs: {
    document: string;
    archiveType: string;
  };
  loanApplicationForms: {
    document: string;
    archiveType: string;
  };
}

const recovaMandateBaseAddress = process.env.REACT_APP_MANDATE_BASEADDRESS;
// export function saveMandate(mandate) {
//     return http.post(recovaMandateBaseAddress + "Mandate", mandate);
// }

export function getMandateById(mandateId: number) {
  var url = recovaMandateBaseAddress + `Mandate/${mandateId}`;
  return http.get(url, {
    url: url,
    method: "get",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function enableMandate(id: number) {
  var url = recovaMandateBaseAddress + `Mandate/Enable/${id}`;
  return http.put(url, {
    url: url,
    method: "put",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function disableMandate(id: number) {
  var url = recovaMandateBaseAddress + `Mandate/Disable/${id}`;
  return http.put(url, {
    url: url,
    method: "put",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function recoveryModeSwitch(id: number) {
  var url = recovaMandateBaseAddress + `Mandate/RecoveryModeSwitch/${id}`;
  return http.post(url, null, {
    url: url,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function cancelMandate(id: number, comment: string) {
  var url = recovaMandateBaseAddress + `Mandate/CancelMandate/${id}?comment=${comment}`;
  return http.post(url, null, {
    url: url,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveMandateById(mandateId: number) {
  var url = recovaMandateBaseAddress + `Mandate/GetById?id=${mandateId}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function retrieveOCMMandateById(mandateId: number) {
  var url = recovaMandateBaseAddress + `Mandate/GetOCMMandateById?id=${mandateId}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function retrieveMandateById2(institutionId: number, mandateId: number) {
  var url = recovaMandateBaseAddress + `Mandate/GetById?id=${mandateId}`;
  return http.get(url, {
    url: url,
    method: "get",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
      Inst: institutionId,
    },
  });
}

export function getMandateByLoanReference(
  institutionId: number,
  loanReference: string
) {
  var url =
    recovaMandateBaseAddress +
    `Mandate/GetByLoanReference?loanReference=${loanReference}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function retrieveMandateByLoanReferenceOnly(loanReference: string) {
  var url =
    recovaMandateBaseAddress +
    `Mandate/RetrieveMandateByLoanReference?loanReference=${loanReference}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function retrieveAllMandates() {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  return http.get(
    recovaMandateBaseAddress +
    `Mandate/GetAll?institutionId=${decodedToken?.["InstitutionId"]}`
  );
}

// export function searchMandates(
//   data: ISearchMandatesData,
//   pageSize: number,
//   pageNumber: number
// ) {
//   return http.get(
//     recovaMandateBaseAddress +
//     `Mandate/SearchMandates?institutionId=${getCurrentUserSession().institutionId
//     }&bvn=${data.bvn}&phoneNumber=${data.phoneNumber}&mandateType=${data.mandateType
//     }&pageSize=${pageSize}&pageNumber=${pageNumber}`
//   );
// }

export function filterMandates(
  payload: IFilterData,
  pageSize: number,
  pageNumber: number
) {
  var url =
    recovaMandateBaseAddress +
    `Mandate/FilterMandates?pageSize=${pageSize}&pageNumber=${pageNumber}`;

  return http.post(url, payload, {
    url: url,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function filterMandatesOCM(
  payload: IFilterData,
  pageSize: number,
  pageNumber: number
) {
  var url =
    recovaMandateBaseAddress +
    `Mandate/FilterMandates?pageSize=${pageSize}&pageNumber=${pageNumber}`;

  return http.post(url, payload, {
    url: url,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function downloadReport(type: string) {
  var url = recovaMandateBaseAddress + `mandate/downloadreport?type=${type}`;

  axios({
    url: url,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Bulk Mandate Template.xlsx`); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
}

export function downloadBulkMandateTemplate() {
  var url = recovaMandateBaseAddress + `Mandate/DownloadBulkMandateTemplate`;

  axios({
    url: url,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Bulk Mandate Template.xlsx`); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
}

export function downloadReportPDF() {
  var url = recovaMandateBaseAddress + `mandate/DownloadReportPDF`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function validateMandateRecords(document: IValidateMandateRecords) {
  var url = recovaMandateBaseAddress + `mandate/ValidateMandateRecords`;

  return http.post(url, document, {
    url: url,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function validateLoanApplicationForms(
  document: IValidateLoanApplicationFormsDocument
) {
  var url = recovaMandateBaseAddress + `mandate/ValidateLoanApplicationForms`;

  return http.post(url, document, {
    url: url,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    data: document,
  });
}

export function validateRightOfSetOffs(
  document: IValidateRightOfSetOffsDocument
) {
  var url = recovaMandateBaseAddress + `mandate/ValidateRightOfSetOffs`;

  return http.post(url, document, {
    url: url,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    data: document,
  });
}

export function uploadMandates(document: IUploadMandatesDocument, skipValidations: boolean) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  var url =
    recovaMandateBaseAddress +
    `mandate/UploadMandates/${document.institutionId}?skipValidations${skipValidations}`;
  return http.post(url, document, {
    url: url,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    data: document,
  });
}

export function validateSweepInitiationRecords(document) {
  return http.post(
    recovaMandateBaseAddress + `mandate/ValidateSweepInitiationRecords`,
    document
  );
}

export function filterUploadRequestMandates(payload, pageSize, pageNumber) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  payload = { ...payload };
  if (decodedToken?.["InstitutionId"]?.toString() !== "1") {
    payload.institutionId = {
      item1: "=",
      item2: decodedToken?.["InstitutionId"]?.toString() || "break",
    };
  }
  var url =
    recovaMandateBaseAddress +
    `Mandate/FilterUploadRequestMandates?pageSize=${pageSize}&pageNumber=${pageNumber}`;

  return http.post(url, payload, {
    url: url,
    method: "post",
    data: payload,
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function filterMandateUploadRequests(payload, pageSize, pageNumber) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  payload = { ...payload };
  if (Number(decodedToken?.["InstitutionId"]?.toString()) !== 1) {
    payload.institutionId = {
      item1: "=",
      item2: decodedToken?.["InstitutionId"]?.toString() || "break",
    };
  }
  var url =
    recovaMandateBaseAddress +
    `Mandate/FilterMandateUploadRequests?pageSize=${pageSize}&pageNumber=${pageNumber}`;

  return http.post(url, payload, {
    url: url,
    method: "post",
    data: payload,
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveBulkMandateUploadRequestById(id) {
  var url =
    recovaMandateBaseAddress + `Mandate/RetrieveMandateUploadRequestById/${id}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveBulkMandateById(id) {
  var url = recovaMandateBaseAddress + `Mandate/RetrieveBulkMandateById/${id}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function updateBulkMandateRequest(id, data) {
  var url = recovaMandateBaseAddress + `Mandate/UpdateBulkMandateRequest/${id}`;

  return http.post(url, data, {
    url: url,
    method: "post",
    data: data,
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function calculateLoanPaymentSchedule(mfbCode, data) {
  var url = recovaMandateBaseAddress + `Mandate/CalculateLoanPaymentSchedule/${mfbCode}`;

  return http.post(url, data, {
    url: url,
    method: "post",
    data: data
  });
}

export function downloadBulkMandateRequestsReport(institutionId, type: String) {
  if (type.toLowerCase() === "pdf") {
    var url =
      recovaMandateBaseAddress + `Mandate/DownloadBulkMandateRequestsReportPDF`;

    return http.get(url, {
      url: url,
      method: "get",
      headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
  } else {
    var url2 =
      recovaMandateBaseAddress +
      `Mandate/DownloadBulkMandateRequestsReport?type=${type}`;

    axios({
      url: url2,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${getCurrentUserSession().token}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Users.${type}`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }
}

export default {
  filterMandateUploadRequests,
  filterUploadRequestMandates,
  getMandateById,
  cancelMandate,
  retrieveMandateById,
  retrieveMandateById2,
  retrieveOCMMandateById,
  getMandateByLoanReference,
  retrieveMandateByLoanReferenceOnly,
  retrieveAllMandates,
  // searchMandates,
  filterMandates,
  filterMandatesOCM,
  recoveryModeSwitch,
  enableMandate,
  disableMandate,
  downloadReport,
  downloadReportPDF,
  downloadBulkMandateTemplate,
  validateMandateRecords,
  validateLoanApplicationForms,
  validateRightOfSetOffs,
  uploadMandates,
  validateSweepInitiationRecords,
  retrieveBulkMandateUploadRequestById,
  retrieveBulkMandateById,
  updateBulkMandateRequest,
  downloadBulkMandateRequestsReport,
  calculateLoanPaymentSchedule
};
