import React, { Component } from "react";

export interface INewItemComponentProps {
  name: string;
  content: string;
  type: string;
  events: any;
  eventData: any;
}

export interface INewItemComponentState { }
class NewItemComponent extends Component<
  INewItemComponentProps,
  INewItemComponentState
> {
  render() {
    const { name, content, type } = this.props;
    const {
      title,
      level,
      combinationNo,
      approverType,
      roleID,
      systemProcessID,
      acceptanceScore,
      acceptanceString,
      levelName,
      userCount,
      privilegeScreens,
    } = this.props.eventData;
    return (
      <React.Fragment>
        {type === "rule" ? (
          <div
            className={"plus-item " + name}
            onClick={() =>
              this.props.events.addNewRule(
                title,
                level,
                combinationNo,
                approverType,
                roleID,
                systemProcessID,
                acceptanceScore,
                acceptanceString,
                levelName,
                userCount,
                privilegeScreens,
                false
              )
            }
          >
            <b>{content}</b>
          </div>
        ) : (
          <div
            className={"plus-item " + name}
            onClick={() =>
              this.props.events.addNewRule(
                title,
                level,
                combinationNo,
                approverType,
                roleID,
                systemProcessID,
                acceptanceScore,
                acceptanceString,
                levelName,
                userCount,
                privilegeScreens,
                false
              )
            }
          >
            <b>{content}</b>
          </div>
        )
        }
      </React.Fragment>
    );
  }
}

export default NewItemComponent;
