import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import { getCurrentUsersRoles } from "../../../services/auth/authService";

interface IPaymentConfigurationDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  showTrail: any;
  currentUserRoles: any;
  item: any;
}

interface IPaymentConfigurationDetailState {
  item: any;
  updatingStatus: boolean;
}

class PaymentConfigurationDetail extends Component<IPaymentConfigurationDetailProps, IPaymentConfigurationDetailState> {
  state = {
    item: {},
    updatingStatus: false,
  };

  static getDerivedStateFromProps(nextProps: IPaymentConfigurationDetailProps, prevState) {
    // console.log("current props: ", nextProps.item);
    return {
      item: nextProps.item,
    };
  }

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      item,
    } = this.props;
    const { updatingStatus } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="icon icon-building-o s-18 mr-3" />
                Payment Configuration Details -{" "}
                <span className="font-weight-bold">{item.institutionCategory} ({item.serviceOffering})</span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Institution Catgory"
                        labelSize={7}
                        valueSize={5}
                        value={item.institutionCategory}
                      />
                      <DetailItem
                        label="Fee Payable Limit"
                        labelSize={7}
                        valueSize={5}
                        value={item.feePayableLimit}
                      />
                      <DetailItem
                        label="Appzone Income Bank Code"
                        labelSize={7}
                        valueSize={5}
                        value={item.appzoneIncomeBankCode}
                      />
                      <DetailItem
                        label="Unsuccessful Payment Retry"
                        labelSize={7}
                        valueSize={5}
                        value={item.unsuccessfulPaymentRetry}
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={2} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Service Offering"
                        labelSize={7}
                        valueSize={5}
                        value={item.serviceOffering}
                      />
                      <DetailItem
                        label="Appzone Income Bank Name"
                        labelSize={7}
                        valueSize={5}
                        value={item.appzoneIncomeBankName}
                      />
                      <DetailItem
                        label="Appzone Income Account Number"
                        labelSize={7}
                        valueSize={5}
                        value={item.appzoneIncomeAccountNumber}
                      />
                      <DetailItem
                        label="Narration"
                        labelSize={7}
                        valueSize={5}
                        value={item.narration}
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
              {getCurrentUsersRoles().includes("editpaymentconfiguration") && (
                <Button
                  size="sm"
                  variant="primary"
                  disabled={updatingStatus}
                  onClick={toggleEditModal}
                >
                  <i className="far fa-edit mr-3" />
                  Edit
                </Button>
              )}
            </React.Fragment>
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default PaymentConfigurationDetail;