import React, { Fragment } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import FormInputComponent from "../../../components/formInputComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import creditAssessmentValidationService from "../../../services/creditAssessment/creditAssessmentValidationService";
import SelectInputComponent, {
  ISelectItems,
} from "../../../components/formSelectComponent";
import { EntryType } from "../../../enums/scoreIndicator/entryType";
import { Type } from "../../../enums/scoreIndicator/type";
import scoringIndicatorService from "../../../services/creditAssessment/scoringIndicatorService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

interface AddScoreIndicatorFields {
  indicatorName: string;
  type: string;
  entryType: string;
  exactValue: string;
}

interface IMetric {
  exactValue?: string;
}

class AddScoreIndicatorForm extends BaseFormComponent<AddScoreIndicatorFields> { }

interface IAddScoreIndicatorProps { }
interface IAddScoreIndicatorState {
  metricsError: any;
  pointMetricsCollection: IMetric[];
  validatedMetrics: boolean;
}

class AddScoreIndicator extends React.Component<
  IAddScoreIndicatorProps,
  IBaseFormState & IAddScoreIndicatorState
> {
  constructor(props: IAddScoreIndicatorProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      metricsError: {},
      validatedMetrics: false,
      pointMetricsCollection: [],
      errors: {},
    };
  }

  onFormSubmit(fields: AddScoreIndicatorFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors:
            creditAssessmentValidationService.validateAddScoreIndicatorForm(fields),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddScoreIndicatorFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({
        submitting: true,
      });
      const payload: any = {};
      payload.indicatorName = fields.indicatorName;
      payload.type = fields.type;
      payload.entryType = fields.entryType;
      payload.scoreIndicatorOptions = this.state.pointMetricsCollection.map(x => x.exactValue);

      console.log(payload)

      const response = await scoringIndicatorService.createScoreIndicator(payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Add Score Indicator",
          description: `Added new Score Indicator ${fields.indicatorName}`,
        });
        toast.info(`Score Indicator ${fields.indicatorName} created successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false });
      this.resetFields();
      onReloadFieldsCB();
    } catch (error) {
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false });
      return false;
    } finally {
      return true;
    }
  }

  processMetricIntoProperObject = (metric: AddScoreIndicatorFields): IMetric => {
    return {
      exactValue: metric.exactValue,
    };
  }

  handleOnAddMetricToCollection = (metricInput: AddScoreIndicatorFields) => {
    this.setState(
      {
        errors: {},
        metricsError: creditAssessmentValidationService.validatePointMetricInputFinite(metricInput),
        validatedMetrics: true
      },
      () => {
        if (Object.keys(this.state.metricsError).length === 0) {
          const metric = this.processMetricIntoProperObject(metricInput);
          const pointMetricsCollection = [...this.state.pointMetricsCollection];

          let checker: any = {}
          // run alphaNumeric checks
          checker = pointMetricsCollection.filter((x) => x.exactValue === metric.exactValue);
          if (!_.isEmpty(checker)) {
            toast.info(`Score Indicator metric already exists for this "exact value"`, {
              autoClose: 6000,
              type: toast.TYPE.ERROR,
              hideProgressBar: false,
            });
          } else {
            pointMetricsCollection.push(metric);
          }
          this.setState({ pointMetricsCollection });
        }
      }
    );
  };

  handleOnRemoveMetricFromCollection = (metric: IMetric) => {
    const pointMetricsCollection = [...this.state.pointMetricsCollection];
    if (pointMetricsCollection.includes(metric)) {
      pointMetricsCollection.splice(
        pointMetricsCollection.indexOf(metric), 1
      );
    }
    this.setState({ pointMetricsCollection });
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      validatedMetrics: false,
      pointMetricsCollection: []
    });
  }

  render() {
    const {
      errors,
      validated,
      metricsError,
      validatedMetrics,
      pointMetricsCollection,
      submitting
    } = this.state;
    // console.log("Validation resp", validationResponse);

    // Turn enum into array
    let typeInputData: ISelectItems[] = Object.keys(Type)
      .filter((value) => isNaN(Number(value)) === false)
      .map((key) => ({
        name: Type[key],
        value: Type[key],
      }));

    let entryTypeInputData: ISelectItems[] = Object.keys(EntryType)
      .filter((value) => isNaN(Number(value)) === false)
      .map((key) => ({
        name: EntryType[key],
        value: EntryType[key],
      }));

    let entryTypeRangeInputData: ISelectItems[] = [{
      name: "Numeric",
      value: "Numeric",
    }];

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add Score Indicator</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddScoreIndicatorForm
                  initialValues={{ indicatorName: "", type: "", entryType: "", exactValue: "" }}
                  FormComponent={({
                    fields: { indicatorName, type, entryType, exactValue },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <h5 className="card-title ml-3">
                            Details
                          </h5>
                          <div className="form-row">
                            <div className="col-md-6">
                              <FormInputComponent
                                id="indicatorName"
                                type="text"
                                name="indicatorName"
                                placeholder=""
                                value={indicatorName}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                              />
                            </div>
                            <div className="col-md-6">
                              <SelectInputComponent
                                id="type"
                                name="indicatorType"
                                value={type}
                                items={typeInputData}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                                onChangeCallBack={() => this.setState({ pointMetricsCollection: [] })}
                              />
                            </div>
                            {type === "" ? ("") : (
                              <div className="col-md-6">
                                <SelectInputComponent
                                  id="entryType"
                                  name="entryType"
                                  value={entryType}
                                  items={type === "Range" ? entryTypeRangeInputData : entryTypeInputData}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                  onChangeCallBack={() => this.setState({ pointMetricsCollection: [] })}
                                />
                              </div>
                            )}
                          </div>

                          {type === "Finite" && entryType !== "" ? (
                            <div>
                              <hr />
                              <h5 className="ml-3">
                                Metrics
                              </h5>
                              <Fragment>
                                <div className="form-row">
                                  <div className="col-md-12">
                                    <div className="form-row">
                                      <FormInputComponent
                                        id="exactValue"
                                        type={entryType == "Numeric" ? "number" : "text"}
                                        name="exactValue"
                                        placeholder=""
                                        divClass={6}
                                        value={exactValue}
                                        required={true}
                                        validated={validatedMetrics}
                                        errors={metricsError}
                                        onChange={onChange}
                                      />
                                      <div className="my-1 mx-2">
                                        <button
                                          className="btn btn-sm btn-outline-primary float-right my-4"
                                          style={{ cursor: "pointer" }}
                                          type="button"
                                          onClick={() =>
                                            this.handleOnAddMetricToCollection(
                                              { exactValue, indicatorName, entryType, type }
                                            )
                                          }
                                        >
                                          <React.Fragment>
                                            <i className="far fa-save fa-lg mr-2" />{" "}
                                            Save Metric
                                          </React.Fragment>
                                        </button>
                                      </div>
                                    </div>

                                    <div className="form-row col-md-10">
                                      <div
                                        className="table-responsive text-nowrap"
                                        style={{
                                          maxHeight: "150px",
                                          overflowY: "scroll",
                                        }}
                                      >
                                        <table className="table table-hover table-content table-sm table-striped mb-0">
                                          <thead>
                                            <tr>
                                              <th
                                                scope="col"
                                                style={{
                                                  paddingTop: "0.2rem",
                                                  paddingBottom: "0.2rem",
                                                }}
                                              >
                                                <strong>#</strong>
                                              </th>
                                              <Fragment>
                                                <th
                                                  scope="col"
                                                  style={{
                                                    paddingTop: "0.2rem",
                                                    paddingBottom: "0.2rem",
                                                  }}
                                                >
                                                  <strong>Exact Value</strong>
                                                </th>
                                              </Fragment>
                                              <th
                                                scope="col"
                                                style={{
                                                  paddingTop: "0.2rem",
                                                  paddingBottom: "0.2rem",
                                                }}
                                              ></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <React.Fragment>
                                              {pointMetricsCollection.map((item) => (
                                                <tr key={pointMetricsCollection.indexOf(item)}>
                                                  <td
                                                    style={{
                                                      paddingTop: "0.2rem",
                                                      paddingBottom: "0.2rem",
                                                      fontSize: "small",
                                                    }}
                                                  >
                                                    {pointMetricsCollection.indexOf(item) + 1}
                                                  </td>
                                                  <Fragment>
                                                    <td
                                                      style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                        fontSize: "small",
                                                      }}
                                                    >
                                                      {item.exactValue}
                                                    </td>
                                                  </Fragment>
                                                  <td
                                                    style={{
                                                      paddingTop: "0.2rem",
                                                      paddingBottom: "0.2rem",
                                                      fontSize: "small",
                                                    }}
                                                  >
                                                    <i
                                                      className="far fa-trash-alt ml-5 red-text fa-lg"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.handleOnRemoveMetricFromCollection(
                                                          item
                                                        )
                                                      }
                                                    ></i>
                                                  </td>
                                                </tr>
                                              ))}
                                            </React.Fragment>
                                          </tbody>
                                        </table>
                                        <div
                                          // className="invalid-feedback"
                                          style={{
                                            visibility: errors.ScoreIndicatorSteps,
                                            fontSize: "80%",
                                            color: "#dc3545",
                                          }}
                                        >
                                          {errors.ScoreIndicatorSteps}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Fragment>
                            </div>
                          ) : ("")}

                          <hr />
                          <div className="card-body">
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                              disabled={submitting}
                              onClick={(e) => {
                                onHandleSubmit(
                                  e,
                                  ActionTypes.SAVE,
                                  "Score Indicator",
                                  () => {
                                    this.onFormSubmit(
                                      { indicatorName, type, entryType, exactValue },
                                      onReloadFields
                                    );
                                  }
                                );
                              }}
                            >
                              {submitting === false ? (
                                <React.Fragment>
                                  <i className="fas fa-lg fa-save mr-3" /> Save
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                  Please wait...
                                </React.Fragment>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                ></AddScoreIndicatorForm>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddScoreIndicator;
