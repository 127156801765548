import React, { Fragment } from "react";
import { ISelectItems } from "../../../components/inputComponents/selectBox";
import _ from "lodash";
import SelectInputComponent from "../../../components/formSelectComponent";
import { toast } from "react-toastify";
import BaseFormComponent, { IBaseFormState } from "../../../components/BaseFormComponent";
import billingValidationService from "../../../services/billing/billingValidationService";
import feeConfigService from "../../../services/billing/feeConfigService";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../components/detailItem";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import { ActionTypes } from "../../../enums/actionTypes";
import { Button } from "react-bootstrap";
import institutionsService from "../../../services/auth/institutionsService";
import institutionFeeConfigService from "../../../services/billing/institutionFeeConfigService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

interface MandateFeeConfigurationFields {
  guarantorMandateFee: string;
  minimumMandateFeePerAnnum: string;
}

class MandateFeeConfigurationForm extends BaseFormComponent<MandateFeeConfigurationFields> { }

export interface IMandateFeeConfigurationProps { }

export interface IMandateFeeConfigurationState {
  editing: boolean;
  category: string;
  institution: any;
  institutions: any;
  feeConfig: any;
  fetchingFeeConfig: boolean;
  fetchingInstitutions: boolean;
}

class MandateFeeConfiguration extends React.Component<IMandateFeeConfigurationProps, IBaseFormState & IMandateFeeConfigurationState> {
  constructor(props: IMandateFeeConfigurationProps) {
    super(props);
    this.state = {
      editing: false,
      errors: [],
      validated: false,
      submitting: false,
      category: "",
      institution: {},
      institutions: [],
      feeConfig: {},
      fetchingFeeConfig: false,
      fetchingInstitutions: false,
    };
  }

  onFormSubmit(fields: MandateFeeConfigurationFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors:
            billingValidationService.validateEditMandateCategoryFeeConfigurationForm(
              fields
            ),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(fields: MandateFeeConfigurationFields, onReloadFieldsCB: any): Promise<boolean> {
    let response: any;
    try {
      this.setState({ submitting: true });
      const payload: any = {};

      payload.guarantorMandateFee = Number(fields.guarantorMandateFee);
      payload.minimumMandateFeePerAnnum = Number(fields.minimumMandateFeePerAnnum);
      payload.institutionCode = this.state.institution.code;

      let fee = await institutionFeeConfigService.filterInstitutionFeeConfig("Mandate", this.state.institution.code, "");
      if ((fee.data).length == 0) {
        response = await institutionFeeConfigService.saveMandateInstitutionFeeConfig(payload);
      }
      else {
        response = await institutionFeeConfigService.updateMandateInstitutionFeeConfig(fee.data[0].institutionFeeConfigurationId, payload);
      }

      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit Insitution Mandate Fee Configuration",
          description: `Edited deatils of Mandate Fee Configuration ${this.state.institution.name}`,
        });
        toast.info(`Mandate Fee Config for ${this.state.institution.name} updated successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false });
      this.resetFields();
      // onReloadFieldsCB();
    } catch (error) {
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // console.log("error: ", error.response);
        // return false;
      }
      // console.log("testing log:: ", response);
      // console.log("error log:: ", error);
      // console.log("error: ", error.response.status);
      // console.log("error: ", error.response);
      this.setState({ submitting: false });
      return false;
    } finally {
      return true;
    }
  }

  toggleEditMode = async (changeHandler) => {
    const { feeConfig } = this.state;
    await changeHandler("guarantorMandateFee", feeConfig.guarantorMandateFee?.toString());
    await changeHandler("minimumMandateFeePerAnnum", feeConfig.minimumMandateFeePerAnnum?.toString());
    this.setState({ editing: !this.state.editing })
  }

  resetFields() {
    this.setState({ validated: false, submitting: false });
  }

  handleOnSelectCategory = async (id, value) => {
    try {
      this.setState({
        category: value,
        fetchingInstitutions: true,
        institution: {},
      });
      let inst = await institutionsService.retrieveAllByCategory(value);
      this.setState({ institutions: inst.data, fetchingInstitutions: false });
    } catch (error) {
      toast.info("Something went wrong.", {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      toast.info(error.response.data.errors[0], {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      this.setState({
        category: "",
        fetchingInstitutions: false,
      });
    }
  };

  handleOnSelectInstitution = async (id, value) => {
    try {
      const institution = this.state.institutions.filter(
        (x) => x.id === parseInt(value)
      )[0];
      this.setState({ institution: institution, fetchingFeeConfig: true });

      let fee = await institutionFeeConfigService.filterInstitutionFeeConfig("Mandate", institution.code, "");
      if ((fee.data).length == 0) {
        fee = await feeConfigService.retrieveFeeConfigByServiceOfferingAndInstitutionCategory("Mandate", institution.category);
        this.setState({ feeConfig: fee.data, fetchingFeeConfig: false });
      }
      else {
        this.setState({ feeConfig: fee.data[0], fetchingFeeConfig: false });
      }
    } catch (error) {
      toast.info("Something went wrong.", {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      toast.info(error.response.data.errors[0], {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      this.setState({ feeConfig: [], fetchingFeeConfig: false });
    }
  };

  render() {
    const {
      editing,
      errors,
      feeConfig,
      fetchingFeeConfig,
      validated,
      submitting,
      category,
      institution,
      fetchingInstitutions,
      institutions,
    } = this.state;

    let institutionCategoryInputData: ISelectItems[] = [
      { name: "Commercial Bank", value: "CommercialBank" },
      { name: "BankOne OFI", value: "BankOneOFI" },
      { name: "OFI", value: "OFI" },
    ];

    let institutionsInputData: ISelectItems[] = institutions.map((item) => ({
      name: item.name,
      value: item.id,
    }));
    // order by institution name
    institutionsInputData.sort((a, b) => a.name.localeCompare(b.name))

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Mandate Fee Configuration</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <form action="#">
                  <div className="form-row">
                    <SelectInputComponent
                      id="institutionCategory"
                      name="institutionCategory"
                      divClass={6}
                      value={category}
                      items={institutionCategoryInputData}
                      required={true}
                      validated={false}
                      errors={[]}
                      onChange={this.handleOnSelectCategory}
                    />
                    {category == "" ? (
                      ""
                    ) : (
                      <SelectInputComponent
                        id="institution"
                        name="institution"
                        divClass={6}
                        value={institution.id}
                        items={institutionsInputData}
                        required={true}
                        validated={false}
                        errors={[]}
                        labelIconClass={fetchingInstitutions === true
                          ? "fas fa-spinner fa-spin orange-text mr-2"
                          : ""
                        }
                        onChange={this.handleOnSelectInstitution}
                      />
                    )}
                  </div>
                  <br />
                  {this.state.category != "" && !_.isEmpty(this.state.institution) ? (
                    <Fragment>
                      {fetchingFeeConfig ? (
                        <Skeleton count={4} height={30} />
                      ) : _.isEmpty(feeConfig) ? (
                        "There is no Mandate Fee Configuration available"
                      ) : (
                        <div className="card no-b">
                          <div className="card-body">
                            <div className="row my-3 mx-2">
                              <h5>
                                Fee Configuration -{" "}
                                <span className="font-weight-bold">
                                  {institution.name}
                                </span>
                              </h5>
                            </div>
                            <MandateFeeConfigurationForm
                              initialValues={{
                                guarantorMandateFee: feeConfig.guarantorMandateFee,
                                minimumMandateFeePerAnnum: feeConfig.minimumMandateFeePerAnnum,
                              }}
                              FormComponent={({
                                fields: { guarantorMandateFee, minimumMandateFeePerAnnum },
                                onChange,
                                onReloadFields,
                                onHandleSubmit,
                              }) => (
                                <form action="#">
                                  <div className="card no-b">
                                    <div className="card-body">
                                      <div className="form-row">
                                        <div className="col-md-12">
                                          {_.isEmpty(feeConfig) ? (
                                            <Skeleton count={4} height={30} />
                                          ) : (
                                            <div className="form-row">
                                              <div className="col-md-6">
                                                {!_.isEmpty(feeConfig) && fetchingFeeConfig ? (
                                                  <Skeleton count={5} width={300} />
                                                ) : (
                                                  <dl className="row">
                                                    <DetailItem
                                                      label="Service Offering"
                                                      value={feeConfig.serviceOffering}
                                                      labelSize={7}
                                                      valueSize={5}
                                                    />
                                                    {editing ? (
                                                      <React.Fragment>
                                                        <DetailItem
                                                          label="Guarantor Mandate Fee Per Annum"
                                                          value={
                                                            <DetailItemInputComponent
                                                              id="guarantorMandateFee"
                                                              type="number"
                                                              name="guarantorMandateFee"
                                                              placeholder=""
                                                              value={guarantorMandateFee}
                                                              required={true}
                                                              validated={validated}
                                                              errors={errors}
                                                              onChange={onChange}
                                                            />
                                                          }
                                                          labelSize={7}
                                                          valueSize={5}
                                                        />
                                                      </React.Fragment>
                                                    ) : (
                                                      <React.Fragment>
                                                        <DetailItem
                                                          label="Guarantor Mandate Fee Per Annum"
                                                          value={guarantorMandateFee}
                                                          labelSize={7}
                                                          valueSize={5}
                                                        />
                                                      </React.Fragment>
                                                    )}
                                                  </dl>
                                                )}
                                              </div>
                                              <div className="col-md-6">
                                                {!_.isEmpty(feeConfig) && fetchingFeeConfig ? (
                                                  <Skeleton count={5} width={30} />
                                                ) : (
                                                  <dl className="row">
                                                    <DetailItem
                                                      label="Institution Category"
                                                      value={category}
                                                      labelSize={7}
                                                      valueSize={5}
                                                    />
                                                    {editing ? (
                                                      <React.Fragment>
                                                        <DetailItem
                                                          label="Minimum Mandate Fee Per Annum"
                                                          value={
                                                            <DetailItemInputComponent
                                                              id="minimumMandateFeePerAnnum"
                                                              type="number"
                                                              name="minimumMandateFeePerAnnum"
                                                              placeholder=""
                                                              value={minimumMandateFeePerAnnum}
                                                              required={true}
                                                              validated={validated}
                                                              errors={errors}
                                                              onChange={onChange}
                                                            />
                                                          }
                                                          labelSize={7}
                                                          valueSize={5}
                                                        />
                                                      </React.Fragment>
                                                    ) : (
                                                      <React.Fragment>
                                                        <DetailItem
                                                          label="Minimum Mandate Fee Per Annum"
                                                          value={minimumMandateFeePerAnnum}
                                                          labelSize={7}
                                                          valueSize={5}
                                                        />
                                                      </React.Fragment>
                                                    )}
                                                  </dl>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <hr />
                                    {editing ? (
                                      <React.Fragment>
                                        <div className="card-body">
                                          <button
                                            type="submit"
                                            className="btn btn-sm btn-primary"
                                            disabled={this.state.submitting}
                                            onClick={(e) => {
                                              onHandleSubmit(
                                                e,
                                                ActionTypes.UPDATE,
                                                "Fee Configuration",
                                                () => {
                                                  this.onFormSubmit(
                                                    { guarantorMandateFee, minimumMandateFeePerAnnum },
                                                    onReloadFields
                                                  );
                                                }
                                              );
                                            }}
                                          >
                                            {this.state.submitting === false ? (
                                              <React.Fragment>
                                                <i className="fas fa-lg fa-save mr-3" /> Save
                                              </React.Fragment>
                                            ) : (
                                              <React.Fragment>
                                                <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                                Please wait...
                                              </React.Fragment>
                                            )}
                                          </button>
                                        </div>
                                      </React.Fragment>
                                    ) : (
                                      !_.isEmpty(feeConfig) && (
                                        <div className="card-body">
                                          <Button
                                            size="sm"
                                            variant="primary"
                                            disabled={submitting}
                                            onClick={() => this.toggleEditMode(onChange)}
                                          >
                                            <i className="far fa-edit mr-3" />
                                            Edit
                                          </Button>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </form>
                              )}
                            ></MandateFeeConfigurationForm>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ) : (
                    ""
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MandateFeeConfiguration;
