import React, { Fragment, Component, useContext } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../../../../components/detailItem";
import { Accordion, Card } from "react-bootstrap";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import { getSupportingDocumentsByLoanReference, retrieveById } from "./../../../../../../services/mandate/supportingDocumentService";
import DocumentPreviewer from "../../../../../dispute/documentPreviewer";

// export function ContextAwareToggle({ eventKey, level }) {
//   const currentEventKey = useContext(AccordionContext);
//   const toggleOnClick = useAccordionToggle(eventKey);
//   const isCurrentEventKey = currentEventKey === eventKey;
//   const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
//   return (

//   );
// }

interface DocumentCollectionProps {
  values: any;
  handleChange: any;
  handleBlur: any;
}

interface DocumentCollectionState {
  item: any;
  supportingDocuments: any;
  fetchingSupportingDocuments: boolean;
  showPreview: boolean;
  document: any;
  ownUpdate: boolean;
}

class DocumentCollection extends Component<DocumentCollectionProps, DocumentCollectionState> {
  constructor(props: DocumentCollectionProps) {
    super(props);
    this.state = {
      item: {},
      supportingDocuments: [],
      fetchingSupportingDocuments: false,
      showPreview: false,
      document: {},
      ownUpdate: false
    };
  }

  static getDerivedStateFromProps(
    nextProps: DocumentCollectionProps,
    prevState: DocumentCollectionState
  ) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isNull(nextProps.values)) {
      return null;
    }
    return {
      item: nextProps.values
    };
  }

  async fetchSupportingDocument() {
    const { values } = this.props;
    // console.log(JSON.parse(JSON.parse(this.props.values.jsonData).LoanDetails).AccountNumber);
    // const supportingDocuments = await getSupportingDocumentsByLoanReference(
    //   JSON.parse(JSON.parse(this.props.values.jsonData).LoanDetails).AccountNumber,
    //   this.props.values.id
    // );
    let reference = `hyb_pre_${values.requestIdentifier}`
    let reference2 = `hyb_post_${values.requestIdentifier}`
    let reference3 = `hyb_bs_${values.requestIdentifier}`
    let reference4 = `hyb_sys_${values.requestIdentifier}`
    const response = await Promise.allSettled([
      getSupportingDocumentsByLoanReference(reference, values.id),
      getSupportingDocumentsByLoanReference(reference2, values.id),
      getSupportingDocumentsByLoanReference(reference3, values.id),
      getSupportingDocumentsByLoanReference(reference4, values.id)
    ])
    const supportingDocumentsPre = response[0].status == "fulfilled" ? response[0].value.data : [];
    const supportingDocumentsPost = response[1].status == "fulfilled" ? response[1].value.data : [];
    const supportingDocumentsBs = response[2].status == "fulfilled" ? response[2].value.data : [];
    const supportingDocumentsSys = response[3].status == "fulfilled" ? response[3].value.data : [];

    const supportingDocumentNonBs = supportingDocumentsPre.concat(supportingDocumentsPost)
    const supportingDocuments = supportingDocumentNonBs.concat(supportingDocumentsBs).concat(supportingDocumentsSys)
    // console.log(supportingDocuments)

    this.setState({ supportingDocuments: supportingDocuments, ownUpdate: true })
  }

  // async loadDocs(nextProps: DocumentCollectionProps) {
  //   // console.log(this.props.values.requestIdentifier)
  //   if (!_.isEmpty(this.props.values.requestIdentifier)) {
  //     try {
  //       const supportingDocuments = await getSupportingDocumentsByLoanReference(
  //         JSON.parse(JSON.parse(JSON.parse(this.props.values.jsonData)).LoanDetails).AccountNumber,
  //         this.props.values.id
  //       );
  //       let supportingDocs = supportingDocuments.data
  //       console.log(supportingDocs)

  //       this.setState({
  //         supportingDocuments: supportingDocs,
  //         fetchingSupportingDocuments: false, 
  //         ownUpdate: true
  //       });
  //     } catch (error) {
  //       // console.log("error fetching supporting documents");
  //       this.setState({ fetchingSupportingDocuments: false, ownUpdate: true });
  //     }
  //   }
  // }
  async componentDidMount() {
    await this.fetchSupportingDocument()
  }

  toggleShowPreview = () => {
    this.setState({ showPreview: !this.state.showPreview, ownUpdate: true });
  };

  handleOnDocumentSelect = async (document) => {
    try {
      // console.log("document ",document)
      if (document.document == null) {
        var doc = await retrieveById(document.id);
        document["document"] = doc.data.document;
      }
      switch (document.extensionType) {
        case "PNG":
        case "JPG":
        case "GIF":
          document[
            "downloadname"
          ] = `${document.name}_${document.loanReference}.jpg`;
          document["data"] = `data:image/jpg;base64,${document.document}`;
          document["payload"] = (
            <img
              src={document.data}
              alt={document.name}
              width="100%"
              height="100%"
              className="image"
            />
          );
          break;
        case "DOCX":
        case "PDF":
          var data = `data:application/pdf;base64,${document.document}`;
          document["payload"] = (
            <object
              width="100%"
              height="500px"
              data={data}
              type="application/pdf"
              className="internal"
            >
              <embed src={data} type="application/pdf" />
            </object>
          );
          break;
        case "HTML":
          document["payload"] = (
            <div
              dangerouslySetInnerHTML={{
                __html: atob(document.document),
              }}
            />
          );
          break;
        default:
          break;
      }
      this.setState({ document, showPreview: true, ownUpdate: true });
    } catch (error) {
      // console.log(error);
    }
  };

  generateExtensionIcon = (document) => {
    switch (document.extensionType) {
      case "PNG":
      case "JPG":
      case "GIF":
        return (
          <i
            className="fas fa-file-image cyan-text fa-lg"
            style={{ cursor: "pointer" }}
            onClick={(e) => this.handleOnDocumentSelect(document)}
          ></i>
        );
      case "PDF":
        return (
          <i
            className="fas fa-file-pdf red-text fa-lg"
            style={{ cursor: "pointer" }}
            onClick={(e) => this.handleOnDocumentSelect(document)}
          ></i>
        );
      case "DOC":
      case "DOCX":
        return (
          <i
            className="fas fa-file-word blue-text fa-lg"
            style={{ cursor: "pointer" }}
            onClick={(e) => this.handleOnDocumentSelect(document)}
          ></i>
        );
      default:
        return (
          <i
            className="fas fa-file-pdf red-text fa-lg"
            style={{ cursor: "pointer" }}
            onClick={(e) => this.handleOnDocumentSelect(document)}
          ></i>
        );
    }
  };

  render() {
    const { values } = this.props;
    const { fetchingSupportingDocuments, supportingDocuments } = this.state;
    const allDocuments = values.documents ? values.documents : []

    return (
      <Fragment>
        <div className="card">
          <div className="card-header clear-fix">
            {_.isEmpty(values) ? (
              <Skeleton width={200} />
            ) : (
              <h6
                className="card-title float-left"
                style={{ marginBottom: "0px" }}
              >
                <b>Document</b>
              </h6>
            )}
          </div>

          <Accordion defaultActiveKey="0">
            <Card>
              <Accordion.Collapse eventKey={`0`}>
                <Card.Body>
                  {/* {console.log(values)} */}
                  <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                    <div className="row">
                      <div className="col-md-12">
                        {fetchingSupportingDocuments || _.isEmpty(supportingDocuments) ? (
                          <div style={{ textAlign: "center", color: "#FE825E" }}>
                            No Document has been assigned to Loan Request
                          </div>
                        ) : (
                          <div>
                            {supportingDocuments?.map((item, idx) => (
                              <div key={idx}>
                                <Accordion defaultActiveKey={`${idx}`}>
                                  <Card>
                                    <Card.Header >
                                      <b>{allDocuments?.filter(x => Number(x.documentId) === item.id)[0]?.name} </b>
                                      <i
                                        className={""}
                                        aria-hidden="true"
                                        style={{ float: "right" }}
                                      ></i>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={`${idx}`}>
                                      <Card.Body className={"rule-card-body"}>
                                        <div className="card-body">
                                          <div className="row">
                                            <div className="col-md-6">
                                              <dl className="row">
                                                <DetailItem
                                                  label="Document Name"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={item.name}
                                                />
                                                <DetailItem
                                                  label="Document Category"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={allDocuments?.filter(x => Number(x.documentId) === item.id)[0]?.category}
                                                />
                                                <DetailItem
                                                  label="Preview Document"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={this.generateExtensionIcon(item)}
                                                />
                                              </dl>
                                            </div>
                                            <div className="col-md-6">
                                              <dl className="row">
                                                <DetailItem
                                                  label="Document Expiry Date"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={allDocuments?.filter(x => Number(x.documentId) === item.id)[0]?.expiryDate}
                                                />
                                                <DetailItem
                                                  label="Document Status"
                                                  labelSize={7}
                                                  valueSize={5}
                                                  value={allDocuments?.filter(x => Number(x.documentId) === item.id)[0]?.status}
                                                />
                                              </dl>
                                            </div>
                                          </div>
                                        </div>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                                <DocumentPreviewer
                                  document={item}
                                  showPreview={this.state.showPreview}
                                  toggleShowPreview={this.toggleShowPreview}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </Fragment>
    );
  }
}

export default DocumentCollection;