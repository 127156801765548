import * as React from "react";
import _ from "lodash";
import { Fragment } from "react";
import {
  IFilterData,
  BaseListComponentState,
} from "../../../components/BaseListComponent";
import {
  createFilterData,
  mapEntityList,
} from "./../../../services/utility/autoMapperService";
import BaseListComponent from "./../../../components/BaseListComponent";
import ItemsTable, {
  filterFieldData,
} from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "./../../../components/tableComponents/tableFilterPane";
import { getCurrentUserSession } from "../../../services/auth/authService";
import { decodeToken } from "react-jwt";
import collectionScheduleService from "../../../services/collection/collectionScheduleService";
import OldCollectionScheduleDetail from "./oldCollectionScheduleDetail";

export interface OldCollectionSchedulesProps {}

export interface OldCollectionSchedulesState extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
  allItems: any;
}

class OldCollectionSchedulesList extends BaseListComponent<OldCollectionSchedulesState> {}

class OldCollectionSchedules extends React.Component<
  OldCollectionSchedulesProps,
  OldCollectionSchedulesState
> {
  constructor(props: OldCollectionSchedulesProps) {
    super(props);
    this.state = {
      data: createFilterData([]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
      allItems: [],
    };
  }

  handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    // const decodedToken = decodeToken(getCurrentUserSession().token || "");
    // let dataClone =
    //   typeof data === "undefined" ? { ...this.state.data } : { ...data };
    try {
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const collSchedules = await collectionScheduleService.retrieveOldCollectionSchedulesByInstitutionCode(decodedToken?.["InstitutionCode"]?.toString() || "");

      // dataClone["institutionId"] = {
      //   item1: "=",
      //   item2: decodedToken?.["InstitutionId"]?.toString() || "",
      // };
      // const collectionSchedules = await collectionScheduleservice.filterPagedOldCollectionSchedules(
      //   dataClone,
      //   Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      //   currentPage
      // );
      const oldCollectionSchedules: any = [];
      const totalLength = collSchedules.data.length;
      const initial = (currentPage - 1) * pageSize;
      for (let i = initial; i < initial + pageSize; i++) {
        oldCollectionSchedules.push(collSchedules.data[i]);
        if (i === totalLength - 1) {
          break;
        }
      }

      this.setState({
        tableItems: mapEntityList({
          // entityList: oldCollectionSchedules.data.item2,
          entityList: oldCollectionSchedules,
          properties: [
            { oldName: "customerName", newName: "name" },
            { oldName: "bvn", newName: "bvn" },
            { oldName: "retryPeriodInterval", newName: "retryPeriodInterval" },
            { oldName: "maxCollectionPausesPerSchedule", newName: "maxCollectionPausesPerSchedule" },
            // { oldName: "dateCreated", newName: "dateCreated" },
            // { oldName: "dateUpdated", newName: "dateUpdated" },
          ],
        }),
        currentPage,
        pageSize,
        // totalSize: oldCollectionSchedules.data.item1,
        totalSize: totalLength,
        fetching: false,
        allItems: collSchedules.data,
      });

    //   public string CustomerName { get; set; }
    //   public string PhoneNumber { get; set; }
    //   public string Bvn { get; set; }
    //   public string MandateReference { get; set; }
    //   public string ExternalMandateReference { get; set; }
    //   public decimal TotalExpectedRepayment { get; set; }
    //   public DateTime LoanExpiryDate { get; set; }
    //   public int LoanTenure { get; set; }
    //   public string LoanStatus { get; set; }
    //   public string InstitutionCode { get; set; }
    //   public DateTime DateCreated { get; set; }
    //   public List<CollectionScheduleRepaymentArchiveDto> ScheduleRepayments { get; set; }
    } catch (ex) {
      //   console.log("caught exception", ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
        this.setState({ fetching: false });
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        this.setState({ fetching: false });
      }
    }
  };

  handleOnExport = async (type: string) => {};

  fetchItemById = async (item: any) => {
    this.setState({ fetchingDetail: true });
    try {
      const oldCollectionSchedule = await collectionScheduleService.retrieveOldCollectionScheduleById(item.id);
      this.setState({ item: oldCollectionSchedule.data, fetchingDetail: false });
    } catch (error) {
      // console.log(error.response);
      this.setState({ fetchingDetail: false });
    }
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }

  render() {
    const {
      // data,
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;
    // console.log("asfsdf", this.state.filterValueInput);
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Old Collection Schedules</b>
              </h3>
            </div>
            <OldCollectionSchedulesList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              //   updateState={this.state.updateBaseListState}
              initialValues={{
                data: createFilterData([]) as IFilterData,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: this.state.filterValueInput,
                item: {},
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                onToggleEditModal,
                showDetailsModal,
                showEditModal,
              }) => (
                <Fragment>
                  <OldCollectionScheduleDetail
                    showDetailsModal={showDetailsModal}
                    toggleDetailsModal={onToggleDetailsModal}
                    toggleEditModal={onToggleEditModal}
                    showTrail={null}
                    // currentUserRoles={null}
                    item={item}
                  />
                  {/* <EditOldCollectionSetting
                    showEditModal={showEditModal}
                    toggleEditModal={(reloadDetails: boolean, item: any) => {
                      onToggleEditModal();
                      if (reloadDetails === true) {
                        this.fetchItemById(item);
                      }
                      this.setState({
                        showDetailsModal: !this.state.showDetailsModal,
                      });
                    }}
                    item={item}
                  /> */}
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="Old Collection Schedules"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                              // amountColumn={["Amount"]}
                              // amountInNaira={true}
                              // timeColumn={["requestDate"]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></OldCollectionSchedulesList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default OldCollectionSchedules;
