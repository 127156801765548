import React from "react";
import { Modal } from "react-bootstrap";
import { firstCharToUpper } from "../../../../../../../../../services/utility/textFormatService";
import _ from "lodash";

export const CashflowModal = ({ cashflow }: { cashflow: any }) => {
  const tableMonthKeys = Object.keys(
    !!cashflow ? cashflow?.[0]?.data?.[0]?.months : [""]
  );
  return (
    <Modal.Body>
      <br />
      <div className="row clearfix">
        {cashflow?.map((abc) => (
          <div
            style={{ marginBottom: 30 }}
            key={abc}
            className=" form-row col-md-12"
          >
            <div
              className="table-responsive text-nowrap"
              style={{
                overflowY: "scroll",
              }}
            >
              <table className="table table-hover table-content table-sm table-striped mb-0">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                      }}
                    >
                      <strong># {abc?.statementType}</strong>
                    </th>
                    {tableMonthKeys?.map((key) => (
                      <th
                        key={key}
                        scope="col"
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                        }}
                      >
                        <strong>{firstCharToUpper(key)}</strong>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {abc?.data?.map((item, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                          width: "50%",
                        }}
                      >
                        {item.itemName}
                      </td>
                      {tableMonthKeys?.map((key) => (
                        <td
                          key={key}
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                            fontSize: "small",
                          }}
                        >
                          {_.isNumber(item?.months?.[key])
                            ? item?.months?.[key]?.toFixed(2)
                            : item?.months?.[key]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    </Modal.Body>
  );
};
