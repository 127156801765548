import React, { Component } from "react";
// import _ from "lodash";
import "./forgotPassword.css";
import recova_logo from "../../images/recova-logo.svg";
import recova_image from "../../images/recova-image.jpg";
import { Link, RouteComponentProps } from "react-router-dom";
import SideBanner from "../../components/sideBanner";
import BaseFormComponent from "../../components/BaseFormComponent";
import { IBaseFormState } from "./../../components/BaseFormComponent";
import authValidationService from "../../services/auth/authValidationService";
import {
  getCurrentUserSession,
  setPasswordByEmail,
} from "../../services/auth/authService";

interface ForgotPasswordFields {
  institutionCode: string;
  email: string;
}

export class ForgotPasswordForm extends BaseFormComponent<
  ForgotPasswordFields
> {}

interface IForgotPasswordProps extends RouteComponentProps {}
interface IForgotPasswordState {
  sent: boolean;
  message: string;
}

class ForgotPassword extends Component<
  IForgotPasswordProps,
  IBaseFormState & IForgotPasswordState
> {
  constructor(props: IForgotPasswordProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      sent: false,
      message: "",
      errors: {},
      // data: {
      //   institutionCode: "",
      //   email: "",
      // },
    };
  }

  onFormSubmit(fields: ForgotPasswordFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        { errors: authValidationService.validateForgotPasswordForm(fields) },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (fields: ForgotPasswordFields, onReloadFieldsCB: any) => {
    try {
      // console.log("starting submission...");
      this.setState({ submitting: true });
      const payload: any = {};
      payload.institutionCode = fields.institutionCode;
      payload.email = fields.email;
      const response = await setPasswordByEmail(payload);
      // console.log("response: ", response);
      if (response.status >= 200 && response.status <= 300) {
        this.setState({
          submitting: false,
          sent: true,
          message:
            "Please follow the link sent to your email to complete the set password process.",
        });
      }
      onReloadFieldsCB();
    } catch (error) {
      this.setState({ submitting: false });
      let errors: any = {};
      if (
        error.response &&
        (error.response.status === 404 || error.response.status === 500)
      ) {
        errors.response = error & error.response.data.Message;
      } else {
        this.setState({ submitting: false });
        errors.response = error.response.data;
      }
      // console.log(errors);
      this.setState({ errors });
    }
  };

  componentDidMount() {
    // console.log("check: ", getCurrentUserSession().sessionId === undefined);
    if (getCurrentUserSession().sessionId !== undefined) {
      // console.log("pushing to home");
      this.props.history.push("/home");
    }
  }

  render() {
    return (
      <React.Fragment>
        <ForgotPasswordForm
          initialValues={{ institutionCode: "", email: "" }}
          FormComponent={({
            fields: { institutionCode, email },
            onChange,
            onReloadFields,
          }) => (
            <div className="container-fluid">
              <div
                id="primary"
                className="height-full responsive-phone row"
                style={{
                  backgroundImage: `url(${recova_image})`,
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="col-md-5">
                  <SideBanner />
                </div>
                <div className="col-md-7" style={{ backgroundColor: "white" }}>
                  <div className="row">
                    <div className="col text-right brand pt-3 pr-5">
                      <img
                        src={recova_logo}
                        width="145"
                        alt=""
                        className="image"
                      />
                    </div>
                  </div>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-3">
                        {/* <img src={recova_image} height="100vh" alt="" /> */}
                      </div>
                      <div className="col-lg-9 p-t-100">
                        <div className="text-black">
                          <h1>Forgot Password</h1>
                          <p className="s-18 font-weight-lighter">
                            {this.state.sent === true
                              ? ""
                              : "Enter your details in the fields below."}
                          </p>
                        </div>
                        <form action="#">
                          <div className="row">
                            {this.state.sent === true ? (
                              <React.Fragment>
                                <h5
                                  className="text-muted text-center"
                                  style={{ fontWeight: 300 }}
                                >
                                  {this.state.message}
                                </h5>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <div className="col-lg-8">
                                  <div className="form-group has-icon">
                                    <i className="icon-account_balance"></i>
                                    <input
                                      autoFocus
                                      type="number"
                                      className="form-control form-control-lg"
                                      placeholder="Institution Code"
                                      id="institutionCode"
                                      name="institutionCode"
                                      value={institutionCode}
                                      onChange={(e) =>
                                        onChange(
                                          "institutionCode",
                                          e.currentTarget.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-8">
                                  <div className="form-group has-icon">
                                    <i className="icon-envelope-o"></i>
                                    <input
                                      type="email"
                                      className="form-control form-control-lg"
                                      placeholder="Email"
                                      id="email"
                                      name="email"
                                      value={email}
                                      onChange={(e) =>
                                        onChange("email", e.currentTarget.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </React.Fragment>
                            )}
                            {Object.keys(this.state.errors).length > 0 && (
                              <div className="col-lg-8">
                                <div
                                  className="alert alert-danger alert-dismissible"
                                  role="alert"
                                >
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">×</span>
                                  </button>
                                  <strong>
                                    {
                                      this.state.errors[
                                        Object.keys(this.state.errors)[0]
                                      ]
                                    }
                                  </strong>
                                </div>
                              </div>
                            )}
                            <div className="col-lg-8">
                              {this.state.sent === true ? (
                                ""
                              ) : (
                                <button
                                  type="submit"
                                  data-wait="Please wait..."
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.onFormSubmit(
                                      {
                                        institutionCode,
                                        email,
                                      },
                                      onReloadFields
                                    );
                                  }}
                                  className={
                                    this.state.submitting === false
                                      ? "btn btn-primary btn-block btn-lg"
                                      : "btn btn-primary btn-block btn-lg disabled"
                                  }
                                >
                                  {this.state.submitting === false ? (
                                    <React.Fragment>
                                      <i className="far fa-paper-plane mr-3"></i>
                                      Send
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <i className="fas fa-spin fa-circle-notch" />
                                    </React.Fragment>
                                  )}
                                </button>
                              )}
                              <p className="forget-pass text-orange">
                                <Link to="/login">
                                  <i className="icon-sign-in mr-2" />
                                  Back To Login
                                </Link>
                              </p>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        ></ForgotPasswordForm>
      </React.Fragment>
    );
  }
}

export default ForgotPassword;
