import { axiosSweepInstance as axiosInstance } from "../configs";

class GeneralSettings{

    getAll(){
        return axiosInstance.get("/Institutions/GetAll");
    }
    retrieveById(Id:string){
        return axiosInstance.get(`/Institutions/RetrieveById/${Id}`);
    }
    retrieveByCBNCode(code:string){
        return axiosInstance.get(`/Institutions/RetrieveByCBNCode/${code}`);
    }
    retrieveByBankCode(code:string){
        return axiosInstance.get(`/Institutions/GetByCode?code=${code}`);
    }
    getNameByCBNCode(code:string){
        return axiosInstance.get(`/Institutions/GetNameByCBNCode/${code}`);
    }
    chargeCustomer(institutionCode:string){
        return axiosInstance.get(`/Institutions/ChargeCustomer/${institutionCode}`);
    }
}

const generalSettingsService = new GeneralSettings();

export default generalSettingsService;