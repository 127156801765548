import React, { CSSProperties, useContext, useEffect, useState } from "react";
import corporateAnalysisService from "../../../../../../../../services/hybridAppraisal/corporateAnalysisService";
import _ from "lodash";
import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionToggle,
} from "react-bootstrap";
import { formatCurrency } from "../../../../../../../../services/utility/textFormatService";
import DetailItem from "../../../../../../../../components/detailItem";
export const BalanceSheetOutput = ({ loan }: any) => {
  const [balanceSheetOutput, setBalanceSheetOutput] = useState<any>();

  const equity = balanceSheetOutput?.ownersEquity;
  const retainedEarnings = balanceSheetOutput?.retainedEarnings;
  const capital = balanceSheetOutput?.capital;
  const totalAsset =
    balanceSheetOutput?.summary?.[0]?.total +
    balanceSheetOutput?.summary?.[1]?.total;
  const totalLiabilities =
    balanceSheetOutput?.summary?.[2]?.total +
    balanceSheetOutput?.summary?.[3]?.total;
  const ownersEquity = equity;
  const maximumLoanAmount = equity / 2;
  const equityAndLiability = equity + totalLiabilities;

  useEffect(() => {
    const getBalanceSheetOutput = async () => {
      const response =
        await corporateAnalysisService.retrieveBalanceSheetOutput({
          loanRequestId: loan?.id,
        });
      if (response?.status === 200) {
        setBalanceSheetOutput(response?.data);
      }
    };
    getBalanceSheetOutput();
  }, [loan]);
  const tableFormat: CSSProperties[] = [
    { textAlign: "left", width: "auto" },
    { textAlign: "right", width: 300, paddingRight: 200 },
  ];
  return (
    <div className="mb-5">
      <Container
        title={`Balance Sheet as at ${balanceSheetOutput?.date}`}
        body={
          <div style={{ width: "100%" }} className="clearfix">
            {!_.isEmpty(balanceSheetOutput?.summary) ? (
              <>
                {_.take(balanceSheetOutput?.summary, 2)?.map(
                  (abc: any, index) => (
                    <div key={abc} className=" form-row col-md-12">
                      <div
                        className="table-responsive text-nowrap"
                        style={{
                          overflowY: "scroll",
                          border: "0.5px solid #ccc",
                          borderTop: index === 0 ? "0.5px solid #ccc" : "0px",
                        }}
                      >
                        <table className="table table-hover table-content table-sm table-striped mb-0">
                          {index === 0 && (
                            <thead>
                              <tr>
                                {tHead(
                                  [`Assets`, `Amount`],
                                  {
                                    fontSize: 15,
                                  },
                                  tableFormat
                                )}
                              </tr>
                            </thead>
                          )}
                          <tbody>
                            {abc?.data
                              ?.filter(
                                (item) =>
                                  [
                                    "receivable",
                                    "receivables",
                                    "cash",
                                  ]?.includes(item?.type?.toLowerCase()) ||
                                  item?.type === null
                              )
                              ?.map(({ item, amount }, index) => (
                                <tr key={index}>
                                  {tData(
                                    [item, formatCurrency(amount, 2)],
                                    {},
                                    tableFormat
                                  )}
                                </tr>
                              ))}
                            {abc?.statementType === "Current Assets" && (
                              <tr>
                                {tData(
                                  [
                                    `Total Cash & Receivables`,
                                    formatCurrency(
                                      abc?.total -
                                        abc?.data
                                          ?.filter((item) =>
                                            ["inventory"]?.includes(
                                              item?.type?.toLowerCase()
                                            )
                                          )
                                          ?.map((item) => Number(item?.amount))
                                          ?.reduce(
                                            (acc, curr) => acc + curr,
                                            0
                                          ),
                                      2
                                    ),
                                  ],
                                  {
                                    fontWeight: "bold",
                                    isItalic: true,
                                    color: "#437ba7",
                                  },
                                  tableFormat
                                )}
                              </tr>
                            )}
                            {abc?.data
                              ?.filter((item) =>
                                ["inventory"]?.includes(
                                  item?.type?.toLowerCase()
                                )
                              )
                              ?.map(({ item, amount }, index) => (
                                <tr key={index}>
                                  {tData(
                                    [item, formatCurrency(amount, 2)],
                                    {},
                                    tableFormat
                                  )}
                                </tr>
                              ))}
                            {abc?.statementType === "Current Assets" && (
                              <tr>
                                {tData(
                                  [
                                    `Total Inventory`,
                                    formatCurrency(
                                      abc?.data
                                        ?.filter((item) =>
                                          ["inventory"]?.includes(
                                            item?.type?.toLowerCase()
                                          )
                                        )
                                        ?.map((item) => Number(item?.amount))
                                        ?.reduce((acc, curr) => acc + curr, 0),
                                      2
                                    ),
                                  ],
                                  {
                                    fontWeight: "bold",
                                    isItalic: true,
                                    color: "#437ba7",
                                  },
                                  tableFormat
                                )}
                              </tr>
                            )}
                            <tr>
                              {tData(
                                [
                                  `Total ${
                                    abc?.statementType === "Current Assets"
                                      ? "Current Assets"
                                      : "Fixed Assets"
                                  }`,
                                  formatCurrency(abc?.total, 2),
                                ],
                                {
                                  fontWeight: "bold",
                                  color: "#86939e",
                                },
                                tableFormat
                              )}
                            </tr>

                            {index === 1 && (
                              <tr>
                                {tData(
                                  [
                                    `Total Assets`,
                                    formatCurrency(totalAsset, 2),
                                  ],
                                  {
                                    fontWeight: "bold",
                                    fontSize: 15,
                                    color: "#86939e",
                                  },
                                  tableFormat
                                )}
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )
                )}
                {[
                  balanceSheetOutput?.summary[2],
                  balanceSheetOutput?.summary[3],
                ]?.map((abc: any, index) => (
                  <div
                    key={abc}
                    style={{ marginTop: index === 0 ? 25 : 0 }}
                    className=" form-row col-md-12"
                  >
                    <div
                      className="table-responsive text-nowrap"
                      style={{
                        overflowY: "scroll",
                        border: "0.5px solid #ccc",
                        borderTop: index === 0 ? "0.5px solid #ccc" : "0px",
                      }}
                    >
                      <table className="table table-hover table-content table-sm table-striped mb-0">
                        {index === 0 && (
                          <thead>
                            <tr>
                              {tHead(
                                [`Liabilities`, `Amount`],
                                {
                                  fontSize: 15,
                                },
                                tableFormat
                              )}
                            </tr>
                          </thead>
                        )}
                        <tbody>
                          {abc?.data?.map(({ item, amount }, index) => (
                            <tr key={index}>
                              {tData(
                                [item, formatCurrency(amount, 2)],
                                {},
                                tableFormat
                              )}
                            </tr>
                          ))}
                          <tr>
                            {tData(
                              [
                                `Total ${
                                  abc?.statementType === "Current Liabilities"
                                    ? "Current Liabilities"
                                    : abc?.statementType ===
                                      "Long Term Liabilities"
                                    ? "Long Term Liabilities"
                                    : "Liabilities"
                                }`,
                                formatCurrency(abc?.total, 2),
                              ],
                              {
                                fontWeight: "bold",
                                isItalic: true,
                                color: "#437ba7",
                              },
                              tableFormat
                            )}
                          </tr>
                          {index === 1 && (
                            <>
                              <tr>
                                {tData(
                                  [
                                    "Total Liabilities",
                                    formatCurrency(totalLiabilities, 2),
                                  ],
                                  {
                                    fontWeight: "bold",
                                    color: "#86939e",
                                  },
                                  tableFormat
                                )}
                              </tr>
                            </>
                          )}
                          {index === 1 && (
                            <>
                              <tr>
                                {tData(
                                  [
                                    "Retained Earnings",
                                    formatCurrency(retainedEarnings, 2),
                                  ],
                                  {},
                                  tableFormat
                                )}
                              </tr>
                              <tr>
                                {tData(
                                  ["Capital", formatCurrency(capital, 2)],
                                  {},
                                  tableFormat
                                )}
                              </tr>
                              <tr>
                                {tData(
                                  [
                                    "Owners Equity",
                                    formatCurrency(ownersEquity, 2),
                                  ],
                                  {
                                    fontWeight: "bold",
                                    color: "#86939e",
                                  },
                                  tableFormat
                                )}
                              </tr>
                            </>
                          )}
                          {index === 1 && (
                            <tr>
                              {tData(
                                [
                                  `Total Equity and Liabilities`,
                                  formatCurrency(equityAndLiability, 2),
                                ],
                                {
                                  fontWeight: "bold",
                                  fontSize: 15,
                                  color: "#86939e",
                                },
                                tableFormat
                              )}
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  color: "red",
                  marginTop: 20,
                }}
                className="py-2"
              >
                <i>No Balance Sheet Output available</i>
              </div>
            )}
          </div>
        }
      />
      <br />
      <Container
        title="Summary"
        body={
          <div
            className="table-responsive text-nowrap"
            style={{ height: "30px", overflowY: "scroll" }}
          >
            <div>
              <dl
                className="col-grid-2"
                style={{ marginBottom: "0px", gap: 1 }}
              >
                {detailItem(
                  "Maximum Loan Amount",
                  formatCurrency(maximumLoanAmount, 2)
                )}
              </dl>
              <br />
            </div>
          </div>
        }
      />
    </div>
  );
};

const detailItem = (label: string, value: string | number) => (
  <DetailItem label={label} value={value} labelSize={6} valueSize={6} />
);

const tHead = (
  texts: (string | number)[],
  { width, fontSize }: { width?: any; fontSize?: any },
  style?: CSSProperties[]
) => (
  <>
    {texts?.map((text: any, index: number) => (
      <th
        key={text}
        scope="col"
        style={{
          ...style?.[index],
          paddingTop: "0.2rem",
          paddingBottom: "0.2rem",
          width,
          fontSize,
        }}
      >
        <strong>{text}</strong>
      </th>
    ))}
  </>
);
const tData = (
  texts: (string | number)[],
  {
    fontWeight,
    width,
    isItalic,
    fontSize,
    color,
  }: {
    fontWeight?: any;
    width?: any;
    isItalic?: boolean;
    fontSize?: any;
    color?: any;
  },
  style?: CSSProperties[]
) => (
  <>
    {texts?.map((text: any, index: number) => (
      <td
        style={{
          ...style?.[index],
          paddingTop: "0.2rem",
          paddingBottom: "0.2rem",
          fontSize: fontSize ? fontSize : "small",
          fontWeight,
          width,
          color,
        }}
      >
        {isItalic ? <em>{text}</em> : text}
      </td>
    ))}
  </>
);

const ContextAwareToggle = ({ eventKey, header }) => {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      <b>{header}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
};

const Container = ({
  title,
  body,
}: {
  title: string;
  body: React.ReactNode;
}) => {
  return (
    <div className="card">
      <Accordion defaultActiveKey="0">
        <div>
          <Card>
            <ContextAwareToggle
              eventKey={`0`}
              header={
                <React.Fragment>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    style={{ color: "#878a8a" }}
                  ></i>{" "}
                  {title}
                </React.Fragment>
              }
            ></ContextAwareToggle>
            <Accordion.Collapse eventKey={`0`}>
              <Card.Body>
                <div
                  style={{
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  <div className="row">{body}</div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </div>
      </Accordion>
    </div>
  );
};
