import React, { Component, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Button, Modal } from "react-bootstrap";
import DetailItem from "../../components/detailItem";
import textFormatService from "../../services/utility/textFormatService";
import { retrieveConsentRequestById } from "../../services/mandate/consentRequestService";

interface IFundsTransferRequestDetailProps {
    showDetailsModal: boolean;
    toggleDetailsModal: any;
    handleOnRetryPayout: any;
    item: any;
}

interface IFundsTransferRequestDetailState {
    item: any;
    updatingStatus: boolean;
}

class FundsTransferRequestDetail extends Component<IFundsTransferRequestDetailProps, IFundsTransferRequestDetailState> {
    constructor(props: IFundsTransferRequestDetailProps) {
        super(props);
        this.state = {
            item: {},
            updatingStatus: false,
        };
    }

    static getDerivedStateFromProps(nextProps: IFundsTransferRequestDetailProps, prevState) {
        if (prevState.item !== nextProps.item) {
            return {
                item: nextProps.item,
                // updateProduct: true,
            };
        }
    }

    render() {
        const {
            showDetailsModal,
            toggleDetailsModal,
            handleOnRetryPayout,
            // toggleEditModal,
            // showTrail,
            // currentUserRoles,
            item,
        } = this.props;

        return (
            <Fragment>
                <Modal size="xl" backdrop="static" show={showDetailsModal} onHide={toggleDetailsModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {_.isEmpty(item) ? (
                                <Skeleton width={500} />
                            ) : (
                                <h5>
                                    <i className="icon icon-building-o s-18 mr-3" />
                                    Payout Details - <span className="font-weight-bold">{item.name}</span>
                                </h5>
                            )}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="card">
                            <div className="card-header clear-fix">
                                {_.isEmpty(item) ? (
                                    <Skeleton width={200} />
                                ) : (
                                    <h6 className="card-title float-left" style={{ marginBottom: "0px" }}>
                                        <b>General Information</b>
                                    </h6>
                                )}
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        {_.isEmpty(item) ? (
                                            <Skeleton count={4} width={300} />
                                        ) : (
                                            <dl className="row">
                                                <DetailItem label="Customer Name" value={item.receiverName} />
                                                <DetailItem label="Debit Account Number" value={item.debitAccountNumber} />
                                                <DetailItem label="Credit Account Number" value={item.creditAccountNumber} />
                                                <DetailItem label="Amount" value={textFormatService.convertKoboToNaira(item.amountInKobo, 2)} />
                                                <DetailItem label="Destination Bank" value={item.receiverInstitution} />
                                                <DetailItem label="Destination Account Number" value={item.receiverAccountNumber} />
                                                <DetailItem label="Narration" value={item.narration} />
                                                <DetailItem label="Phone Number" value={item.phoneNumber} />
                                                <DetailItem label="Transaction Reference" value={item.transactionReference} />
                                                <DetailItem label="Stage" value={item.payoutStage} />
                                                <DetailItem label="Date Started" value={textFormatService.formatTimeString(item.dateCreated)} />
                                                <DetailItem
                                                    label="Date Finished"
                                                    value={item.dateFinished && textFormatService.formatTimeString(item.dateFinished)}
                                                />
                                                {item.hasError && <DetailItem label="Error" value={item.errorMessage} />}
                                            </dl>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {_.isEmpty(item) ? (
                            <Skeleton width={100} count={2} height={30} />
                        ) : (
                            <Fragment>
                                {item.hasError && (
                                    <Button size="sm" variant="outline-primary" onClick={(e) => handleOnRetryPayout(item)}>
                                        <i className="far fa-edit mr-3" />
                                        Retry
                                    </Button>
                                )}
                            </Fragment>
                        )}
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}

export default FundsTransferRequestDetail;
