import React from "react";
import { toast } from "react-toastify";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import billingValidationService from "../../../services/billing/billingValidationService";
import { ISelectItems } from "../../../components/detailItemSelectBox";
import FormInputComponent from "../../../components/formInputComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import SelectInputComponent from "../../../components/formSelectComponent";
import paymentConfigService from "../../../services/billing/paymentConfigService";
import { ServiceOffering } from "../../../enums/serviceOffering";
import activityTrailService from "../../../services/auditTrail/activityTrailService";


interface AddPaymentConfigurationFields {
  serviceOffering: string;
  institutionCategory: string;
  feePayableLimit: string;
  appzoneIncomeBankName: string;
  appzoneIncomeBankCode: string;
  appzoneIncomeAccountNumber: string;
  unsuccessfulPaymentRetry: string;
  narration: string;
}

class AddPaymentConfigurationForm extends BaseFormComponent<AddPaymentConfigurationFields> { }

interface IAddPaymentConfigurationProps { }
interface IAddPaymentConfigurationState { }

class AddPaymentConfiguration extends React.Component<
  IAddPaymentConfigurationProps,
  IBaseFormState & IAddPaymentConfigurationState
> {
  constructor(props: IAddPaymentConfigurationProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
    };
  }

  onFormSubmit(fields: AddPaymentConfigurationFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        { errors: billingValidationService.validateAddPaymentConfigurationForm(fields) },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddPaymentConfigurationFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true });
      const payload: any = {};

      payload.serviceOffering = fields.serviceOffering;
      payload.institutionCategory = fields.institutionCategory;
      payload.feePayableLimit = Number(fields.feePayableLimit);
      payload.appzoneIncomeBankName = fields.appzoneIncomeBankName;
      payload.appzoneIncomeBankCode = fields.appzoneIncomeBankCode;
      payload.appzoneIncomeAccountNumber = fields.appzoneIncomeAccountNumber;
      payload.unsuccessfulPaymentRetry = fields.unsuccessfulPaymentRetry;
      payload.narration = fields.narration;
      // console.log("payload: ", payload);

      var paymentConfig = await paymentConfigService.filterPaymentConfig(fields.serviceOffering, fields.institutionCategory);
      if ((paymentConfig.data).length != 0) {
        toast.error(`Payment Configuration for ${fields.institutionCategory} (${fields.serviceOffering}) already exist `, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      else {
        const response = await paymentConfigService.savePaymentConfig(payload);
        if (response.status >= 200 && response.status <= 300) {
          await activityTrailService.saveActivityTrail({
            actionType: "Add Payment Configuration",
            description: `Added new Payment Configuration ${fields.institutionCategory} - ${fields.serviceOffering}`,
          });
          toast.info(`Payment Configuration for ${fields.institutionCategory} - ${fields.serviceOffering} created successfully! `, {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          });
        }
        else {
          return false;
        }
        this.setState({ submitting: false });
        onReloadFieldsCB();
      }
    } catch (error) {
      // console.log("error: ", error.response.data);
      return false;
    } finally {
      this.setState({ submitting: false });
      return true;
    }
  }

  render() {
    const { errors, validated } = this.state;

    let institutionCategoryInputData: ISelectItems[] = [
      { name: "Commercial Bank", value: "CommercialBank" },
      { name: "BankOne OFI", value: "BankOneOFI" },
      { name: "OFI", value: "OFI" },
    ];

    // Turn enum into array
    let serviceOfferingInputData: ISelectItems[] = Object.keys(
      ServiceOffering
    )
      .filter((value) => isNaN(Number(value)) === false)
      .map((key) => ({
        name: ServiceOffering[key],
        value: ServiceOffering[key],
      }));

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add Payment Configuration</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddPaymentConfigurationForm
                  initialValues={{
                    serviceOffering: "",
                    institutionCategory: "",
                    feePayableLimit: "",
                    appzoneIncomeBankName: "",
                    appzoneIncomeBankCode: "",
                    appzoneIncomeAccountNumber: "",
                    unsuccessfulPaymentRetry: "",
                    narration: "",
                  }}
                  FormComponent={({
                    fields: {
                      serviceOffering,
                      institutionCategory,
                      feePayableLimit,
                      appzoneIncomeBankName,
                      appzoneIncomeBankCode,
                      appzoneIncomeAccountNumber,
                      unsuccessfulPaymentRetry,
                      narration,
                    },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-row">
                                <SelectInputComponent
                                  id="institutionCategory"
                                  name="institutionCategory"
                                  divClass={6}
                                  value={institutionCategory}
                                  items={institutionCategoryInputData}
                                  required={true}
                                  validated={false}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <SelectInputComponent
                                  id="serviceOffering"
                                  name="serviceOffering"
                                  divClass={6}
                                  value={serviceOffering}
                                  items={serviceOfferingInputData}
                                  required={true}
                                  validated={false}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-row">
                                <FormInputComponent
                                  id="feePayableLimit"
                                  type="number"
                                  name="feePayableLimit"
                                  placeholder=""
                                  divClass={6}
                                  value={feePayableLimit}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="appzoneIncomeBankName"
                                  type="text"
                                  name="appzoneIncomeBankName"
                                  placeholder=""
                                  divClass={6}
                                  value={appzoneIncomeBankName}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-row">
                                <FormInputComponent
                                  id="appzoneIncomeBankCode"
                                  type="text"
                                  name="appzoneIncomeBankCode"
                                  placeholder=""
                                  divClass={6}
                                  value={appzoneIncomeBankCode}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="appzoneIncomeAccountNumber"
                                  type="text"
                                  name="appzoneIncomeAccountNumber"
                                  placeholder=""
                                  divClass={6}
                                  value={appzoneIncomeAccountNumber}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-row">
                                <FormInputComponent
                                  id="unsuccessfulPaymentRetry"
                                  type="text"
                                  name="unsuccessfulPaymentRetry"
                                  placeholder=""
                                  divClass={6}
                                  value={unsuccessfulPaymentRetry}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="narration"
                                  type="text"
                                  name="narration"
                                  placeholder=""
                                  divClass={6}
                                  value={narration}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="card-body">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary"
                          disabled={this.state.submitting}
                          onClick={(e) => {
                            onHandleSubmit(
                              e,
                              ActionTypes.SAVE,
                              "Payment Configuration",
                              () => {
                                this.onFormSubmit(
                                  {
                                    serviceOffering,
                                    institutionCategory,
                                    feePayableLimit,
                                    appzoneIncomeBankName,
                                    appzoneIncomeBankCode,
                                    appzoneIncomeAccountNumber,
                                    unsuccessfulPaymentRetry,
                                    narration,
                                  },
                                  onReloadFields
                                );
                              }
                            );
                          }}
                        >
                          {this.state.submitting === false ? (
                            <React.Fragment>
                              <i className="fas fa-lg fa-save mr-3" /> Save
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                              Please wait...
                            </React.Fragment>
                          )}
                        </button>
                      </div>
                    </form>
                  )}
                ></AddPaymentConfigurationForm>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment >
    );
  }
}

export default AddPaymentConfiguration;
