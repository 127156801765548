import React, { useCallback, useEffect, useState } from "react";
import monoWebhookService from "../../../../services/_axiosServices/sweep/mono-webhook";
import { Loader, Error } from "../../components";

export default function Report() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState("--");
  const [error, setError] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchData = useCallback(async () => {
    setLoading(true);
    await monoWebhookService
      .testWebhook()
      .then((res: any) => {
        // console.log(res);
        setLoading(false);
        setData(res.data);
      })
      .catch((err: any) => {
        setError(false);
        setLoading(false);
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="container-fluid relative animatedParent animateOnce">
      <div className="animated fadeInUpShort go">
        <div className="row my-3 mx-2">
          <h3>
            <b>Mono Webhook - Test</b>
          </h3>
        </div>
        <div className="card no-b">
          <div className="card-body">
            <div className="card-title">
              <div className="page">
                <div className="page-inner">
                  <div className="btn-toolbar justify-content-between">
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={fetchData}
                      >
                        <i className="fas fa-sync"></i>
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive text-nowrap">
                    {loading && <Loader />}

                    {!loading && !error && (
                      <table className="table table-hover table-content table-striped table-bordered table-sm paged-output">
                        <thead>
                          <tr>
                            <th>Webhook status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{data}</td>
                          </tr>
                        </tbody>
                      </table>
                    )}

                    {error && !loading && <Error />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
