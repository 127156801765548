import React from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../components/detailItem";
import textFormatService, { printPDF } from "../../services/utility/textFormatService";
import DetailItemsTable from "../../components/tableComponents/detailItemsTable";
import { decodeToken } from "react-jwt";
import { mapEntityList } from "../../services/utility/autoMapperService";
import { getCurrentUserSession } from "../../services/auth/authService";
import institutionsService from "../../services/auth/institutionsService";
import sweepRunService from "../../services/sweep/sweepRunService";
import swal from "sweetalert";
import { toast } from "react-toastify";
import settlementService from "../../services/sweep/settlementService";
import reportsService from "../../services/sweep/reportsService";
import SettlementTransactionReportDetail from "./settlementTransactionDetail";
import ItemsTable from "../../components/tableComponents/itemsTable";

export interface SettlementReportProps {
    showDetailsModal: boolean;
    toggleDetailsModal: any;
    item: any;
}

export interface SettlementReportState {
    repaymentTransactions: any;
    repaymentTransactionsTotalCount: number;
    repaymentTransactionsCurrentPage: number;
    pageSize: number;
    fetchingRepaymentTransactions: boolean;
    values: any;
    fetchingDetail: boolean;
    onToggleTranModal: boolean;
    showTranModal: boolean;
    item_: any;
}

class SettlementReportDetail extends React.Component<SettlementReportProps, SettlementReportState> {
    constructor(props: SettlementReportProps) {
        super(props);
        this.state = {
            fetchingRepaymentTransactions: false,
            repaymentTransactions: [],
            repaymentTransactionsCurrentPage: 1,
            pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
            repaymentTransactionsTotalCount: 0,
            values: [],
            fetchingDetail: false,
            onToggleTranModal: false,
            showTranModal: false,
            item_: []
        };
    }

    handleOnRepaymentTransactionsPageChange = async (page: number, pageSize: number, transactionId) => {
        const decodedToken = decodeToken(getCurrentUserSession().token || "");
        this.setState({ fetchingRepaymentTransactions: true });
        try {
            const institution = await institutionsService.retrieveInstitutionById(decodedToken?.["InstitutionId"]);
            const repaymentTrx = await sweepRunService.retrieveRepaymentsBySweepEpisodeId(
                institution.data.code,
                transactionId,
                pageSize,
                page
            );
            this.setState({
                repaymentTransactions: mapEntityList({
                    entityList: repaymentTrx.data.item2,
                    properties: [
                        {
                            oldName: "linkedAccountInstitution",
                            newName: "Inflow Institution",
                        },
                        { oldName: "amountAttempted", newName: "amountAttempted" },
                        { oldName: "amountDebited", newName: "amountDebited" },
                        { oldName: "timeSwept", newName: "timeSwept" },
                        { oldName: "retrievalReferenceNo", newName: "retrievalReference" },
                    ],
                }),
                repaymentTransactionsCurrentPage: page,
                pageSize,
                repaymentTransactionsTotalCount: repaymentTrx.data.item1,
                fetchingRepaymentTransactions: false,
            });
        } catch (error) {
            // console.log("error fetching repayment transactions: ", error);
            this.setState({ fetchingRepaymentTransactions: false });
        } finally {
            this.setState({ fetchingRepaymentTransactions: false });
        }
    };

    fetchItemById = async (item: any) => {
        // await activityTrailService.saveActivityTrail({
        //   actionType: "View Details",
        //   description: `Viewed details of Branch ${item.name}`
        // });
        console.log(item)
        this.setState({ fetchingDetail: true });
        const report = await reportsService.retrieveSettlementTransactionRequestByReference(item[0]?.loanAccountNo);
        console.log(report)
        this.setState({ item_: report.data[0], fetchingDetail: false });
    };

    toggleTransModal = () => {
        this.setState({ showTranModal: !this.state.showTranModal })
    }
    handleOnExport = async (type: string) => {
        try {
            const decodedToken = decodeToken(getCurrentUserSession().token || "");
            switch (type) {
                case "XLSX":
                case "CSV":
                    return await reportsService.downloadSettlementReport(decodedToken?.["InstitutionCode"], type);
                case "PDF":
                    const response = await reportsService.downloadSettlementReport(decodedToken?.["InstitutionCode"], type);
                    printPDF(response?.data);
                    break;
                default:
                    break;
            }
        } catch (ex) {
            if (ex.response && ex.response.status >= 400 && ex.response.status <= 499) {
                // toast.error("Bad Request");
                // console.log(ex.response);
                // errors["response"] = "Internal server error.";
                // console.log(errors);
            } else {
                // toast.error("Internal server error. Please contact the admin.");
                //this.setState({ errors, fetching: false });
            }
        }
    };


    async retryTransaction() {
        try {
            swal({
                title: "Confirm!",
                text: `Are you sure you want to Retry this ${this.props.item.settlementEntryType} Transaction?`,
                icon: "warning",
                buttons: {
                    confirm: {
                        text: "Yes",
                        value: null,
                        visible: true,
                        className: "",
                        closeModal: true,
                    },
                    cancel: {
                        text: "Cancel",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true,
                    },
                },
                closeOnClickOutside: false,
                dangerMode: true,
            }).then(async (resp) => {
                if (resp) {
                    // console.log("not gonna cancel the mandate");
                } else {
                    var result = await settlementService.retryProcessingSettlementEntry(this.props.item.id);
                    // console.log(result);
                    toast.info(`Settlement retry in progress! `, {
                        autoClose: 6000,
                        type: toast.TYPE.DEFAULT,
                        hideProgressBar: false,
                    });
                }
            });
        } catch (error) {
            // console.log("error: ", error);
            toast.error(`Unable to retry settlement entry. `, {
                autoClose: 6000,
                type: toast.TYPE.ERROR,
                hideProgressBar: false,
            });
        }
    }

    render() {
        const { showDetailsModal, toggleDetailsModal, item } = this.props;
        const { repaymentTransactions, repaymentTransactionsTotalCount, repaymentTransactionsCurrentPage, fetchingRepaymentTransactions, onToggleTranModal, pageSize, showTranModal, item_ } =
            this.state;

        return (
            <Modal size="xl" backdrop="static" show={showDetailsModal} onHide={toggleDetailsModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {_.isEmpty(item) ? (
                            <Skeleton width={500} />
                        ) : (
                            <h5>
                                <i className="fas fa-receipt fa-sm s-18 mr-3" />
                                Settlement Entry Detail
                            </h5>
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card">
                        {console.log(repaymentTransactions)}
                        <div className="card-header clear-fix">
                            {_.isEmpty(item) ? (
                                <Skeleton width={200} />
                            ) : (
                                <h6 className="card-title float-left" style={{ marginBottom: "0px" }}>
                                    <b>Sweep Details</b>
                                </h6>
                            )}
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-8">
                                    {_.isEmpty(item) ? (
                                        <Skeleton count={5} width={300} />
                                    ) : (
                                        <dl className="row">
                                            <DetailItem
                                                label="Settlement Entry Flow"
                                                value={textFormatService.splitAtUpper(item.settlementEntryFlow?.replace("to", "To"))}
                                            />
                                            <DetailItem label="Sweep Cycle" value={item.sweepCycle} />
                                            <DetailItem label="Sweep Date" value={item.generatedOn} />
                                            <DetailItem label="Sweep Reference" value={item.sweepReference} />
                                            {/* {!_.isEmpty(item.settlementTime) && <DetailItem label="Setlement Time" value={item.settlementTime} />} */}
                                            <DetailItem label="Debited Institution" value={item.debitedInstitutionName} />
                                            <DetailItem label="Lender Institution" value={item.lenderInstitutionName} />
                                            {/* <DetailItem label="No. Of Transactions" value={item.transactionCount} /> */}
                                            <DetailItem label="Repayment Amount" value={textFormatService.convertKoboToNaira(item.repaymentAmount, 2)} />
                                            <DetailItem label="Settlement Amount" value={textFormatService.convertKoboToNaira(item.settlementAmount, 2)} />
                                        </dl>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="card-header clear-fix">
                            <h6 className="card-title float-left" style={{ marginBottom: "0px" }}>
                                <b>Settlement Details</b>
                            </h6>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-8">
                                    {_.isEmpty(item) ? (
                                        <Skeleton count={5} width={300} />
                                    ) : (
                                        <dl className="row">
                                            <DetailItem
                                                label="Entry Type"
                                                value={textFormatService.splitAtUpper(item.settlementEntryType)}
                                            />
                                            {/* <DetailItem label="Credit Account" value={item.creditAccount} /> */}
                                            <DetailItem label="Debit Account" value={item.debitAccount} />

                                            <DetailItem label="Transfer Date" value={item.settlementTime} />
                                            <DetailItem label="Amount Transferred" value={textFormatService.convertKoboToNaira(item.amountTransferred, 2)} />
                                            <DetailItem label="Transaction Status" value={item.status} />
                                            <DetailItem label="Commission earned" value={item.commissionEarned} />
                                            {!_.isEmpty(item.retrievalReferenceNo) && (
                                                <DetailItem label="Transaction Reference" value={item.retrievalReferenceNo} />
                                            )}
                                        </dl>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="card-header clear-fix">
                            <h6 className="card-title float-left" style={{ marginBottom: "0px" }}>
                                <b>Lender Sponsor Bank</b>
                            </h6>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-8">
                                    {_.isEmpty(item) ? (
                                        <Skeleton count={5} width={300} />
                                    ) : (
                                        <dl className="row">
                                            <DetailItem
                                                label="SettlementEntry Type"
                                                value={textFormatService.splitAtUpper(item.settlementEntryType)}
                                            />
                                            <DetailItem label="Amount Received" value={textFormatService.convertKoboToNaira(item.amountReceived, 2)} />
                                            <DetailItem label="Credit Account" value={item.creditAccount} />
                                            <DetailItem label="Transfer Date" value={item.transferDate} />
                                            <DetailItem label="Transaction Status" value={item.status} />
                                            <DetailItem label="Commission earned" value={item.commissionEarned} />
                                            {/* <DetailItem label="Debit Account" value={item.debitAccount} /> */}
                                            {!_.isEmpty(item.retrievalReferenceNo) && (
                                                <DetailItem label="Transaction Reference" value={item.transactionReference} />
                                            )}


                                        </dl>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="card-header clear-fix">
                            <h6 className="card-title float-left" style={{ marginBottom: "0px" }}>
                                <b>OFI Settlement Details</b>
                            </h6>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-8">
                                    {_.isEmpty(item) ? (
                                        <Skeleton count={5} width={300} />
                                    ) : (
                                        <dl className="row">
                                            {/* <DetailItem
                                                label="SettlementEntry Type"
                                                value={textFormatService.splitAtUpper(item.settlementEntryType)}
                                            /> */}
                                            <DetailItem label="Settlement By" value={item.settlementBy} />
                                            <DetailItem label="Settlement Amount" value={textFormatService.convertKoboToNaira(item.settlementAmount, 2)} />
                                            <DetailItem label="Settlement Date" value={item.settlementTime} />
                                            <DetailItem label="Settlement Account" value={item.settlementAcct} />
                                            <DetailItem label="Settlement Status" value={item.status} />
                                            {!_.isEmpty(item.retrievalReferenceNo) && (
                                                <DetailItem label="Transaction Reference" value={item.transactionReference} />
                                            )}


                                        </dl>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="card-header clear-fix">
                            <h6 className="card-title float-left" style={{ marginBottom: "0px" }}>
                                <b>Fee Details</b>
                            </h6>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-8">
                                    {_.isEmpty(item) ? (
                                        <Skeleton count={5} width={300} />
                                    ) : (
                                        <dl className="row">
                                            <DetailItem label="Fee Paid" value={textFormatService.convertKoboToNaira(item.feePaid, 2)} />
                                            <DetailItem
                                                label="Entry Type"
                                                value={textFormatService.splitAtUpper(item.settlementEntryType)}
                                            />
                                            <DetailItem label="Debit Account (Fee Expense)" value={item.feeExpenseAccount} />
                                            <DetailItem label="Credit Account (Collection account)" value={item.collectionAccount} />
                                            {!_.isEmpty(item.retrievalReferenceNo) && (
                                                <DetailItem label="Transaction Reference" value={item.transactionReference} />
                                            )}
                                        </dl>
                                    )}
                                </div>
                            </div>
                        </div>
                        <ItemsTable
                            name="Debited Accounts"
                            items={item.transactions}
                            data={{}}
                            handlePageChange={this.handleOnRepaymentTransactionsPageChange}
                            filterValueInput={[]}
                            showTableFiltermodal={false}
                            toggleTableFiltermodal={false}
                            // content={item.transactions}
                            // contentCount={item.transactions?.length}
                            totalSize={item.transactions?.length}
                            currentPage={repaymentTransactionsCurrentPage}
                            pageSize={pageSize}
                            fetching={fetchingRepaymentTransactions}
                            amountColumn={["amountDebited", "amountAttempted", "amountOutstanding"]}
                            timeColumn={["timeSwept"]}
                            onViewDetails={() => {
                                this.fetchItemById(item.transactions);
                                this.toggleTransModal();
                            }}
                            handleOnExport={this.handleOnExport}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {item.status === "Failed" && (
                        <Button
                            size="sm"
                            variant="outline-warning"
                            onClick={() => {
                                this.retryTransaction();
                                toggleDetailsModal();
                            }}
                        >
                            <i className="fas fa-ban mr-3"></i>
                            Retry
                        </Button>
                    )}
                    <Button size="sm" variant="outline-danger" onClick={toggleDetailsModal}>
                        <i className="fas fa-times mr-3" />
                        Close
                    </Button>
                    <SettlementTransactionReportDetail
                        showDetailsModal={showTranModal}
                        toggleDetailsModal={() => this.toggleTransModal()}
                        item={item_}
                    />
                </Modal.Footer>
            </Modal>
        );
    }
}

export default SettlementReportDetail;
