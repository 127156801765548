import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";
import { decodeToken } from "react-jwt";

interface IFinApprovalRule {
  institutionId: number;
  approvableId: number;
  lowerLimit: number;
  upperLimit: number;
}

const approvalServiceBaseAddress = process.env.REACT_APP_APPROVAL_BASEADDRESS;
export function retrieveFinancialGroup(id: number) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  return http.get(
    approvalServiceBaseAddress +
    `financialGroup?institutionId=${decodedToken?.["InstitutionId"]}&id=${id}`
  );
}

export function createFinancialGroup(approvalRule: any) {
  return http.post(approvalServiceBaseAddress + `financialGroup`, approvalRule);
}

export function updateFinancialGroup(approvalRule: IFinApprovalRule) {
  return http.put(approvalServiceBaseAddress + `financialGroup`, approvalRule);
}

export function removeFinancialGroup(id: number) {
  return http.post(approvalServiceBaseAddress + `financialGroup/RemoveFinancialGroup/${id}`);
}

export default {
  retrieveFinancialGroup,
  createFinancialGroup,
  updateFinancialGroup,
  removeFinancialGroup,
};
