import { axiosSweepInstance as axiosInstance } from "../configs";

class MonoWebhook{

    testWebhook(){
        return axiosInstance.get(`/MonoWebhook/TestWebhook`);
    }
    retrieveAll(){
        return axiosInstance.get(`/MonoWebhook/RetrieveAll`);
    }
    retrieveById(id:string){
        return axiosInstance.get(`/MonoWebhook/RetrieveById/${id}`);
    }
    retrieveByInstitutionCode(code:string){
        return axiosInstance.get(`/MonoWebhook/RetrieveByInstitutionCode/${code}`);
    }
}

const monoWebhookService = new MonoWebhook();
export default monoWebhookService;