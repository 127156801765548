import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";


const emailBaseAddress = process.env.REACT_APP_EMAIL_BASEADDRESS;
export function sendDashboardMail(items: { [name: string]: string }, emails: string[], dashboard: string, selectedFormat: "both" | "pdf" | "jpeg") {
    var url = emailBaseAddress + `Dashboard/SendDashboardMail?Dashboard=${dashboard}&selectedFormat=${selectedFormat}`;
    let payload: any = {};
    payload.items = items;
    payload.emails = emails;
    return http.post(url, payload, {
        url: url,
        method: "post",
        headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
}

export default {
    sendDashboardMail,
}