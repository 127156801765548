import React from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { ISelectItems } from "../../../components/detailItemSelectBox";
import SelectInputComponent from "../../../components/formSelectComponent";
import swal from "sweetalert";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import creditPolicyParameterService from "../../../services/hybridAppraisal/creditPolicyParameterService";
import appraisalInstitutionService from "../../../services/hybridAppraisal/appraisalInstitutionService";

interface IAssignCreditPolicyParameterProps { }
interface IAssignCreditPolicyParameterState {
  appraisalInstitutions: any;
  institution: any;
  creditPolicyParameters: any;
  parameterIds: any;
  existingCreditPolicy: any;
  fetchingRequiredItems: boolean;
}

class AssignCreditPolicyParameter extends React.Component<
  IAssignCreditPolicyParameterProps,
  IBaseFormState & IAssignCreditPolicyParameterState
> {
  constructor(props: IAssignCreditPolicyParameterProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      fetchingRequiredItems: false,
      appraisalInstitutions: [],
      institution: {},
      creditPolicyParameters: [],
      parameterIds: [],
      existingCreditPolicy: []
    };
  }

  async componentWillMount() {
    const { errors } = this.state;
    try {
      this.setState({ fetchingRequiredItems: true });
      const response = await Promise.allSettled([
        appraisalInstitutionService.retrieveAll(),
        creditPolicyParameterService.retrieveAll()
      ])
      const appraisalInstitutions = response[0].status == "fulfilled" ? response[0].value.data : [];
      const creditPolicyParameters = response[1].status == "fulfilled" ? response[1].value.data : [];
      this.setState({
        appraisalInstitutions,
        creditPolicyParameters,
        fetchingRequiredItems: false,
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (ex.response && ex.response.status === 404) {
        // console.log(ex.response);
        errors.response = "Internal server error.";
        // console.log(errors);
        this.setState({ errors });
      }
    }
  }

  onHandleSubmit(e): boolean {
    e.preventDefault();
    try {
      swal({
        title: "Confirm!",
        text: `Are you sure you want to proceed to Assign these Credit Policy to this Institution?`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode: true,
      }).then(async (resp) => {
        if (resp) {
          // this.setState({ updatingStatus: false });
          this.setState({ submitting: false });
        } else {
          //push to assign
          return this.submit();
        }
      });
      this.setState({ validated: true });
      return false;
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // return false;
      }
      return false;
    }
  }

  async submit(): Promise<boolean> {
    try {
      this.setState({ submitting: true });
      const payload: any = this.state.parameterIds;
      const response = await appraisalInstitutionService.profileCreditPolicyParametersToInstitution(
        this.state.institution.code,
        payload
      );
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Assign Credit Policy Parameters",
          description: `Assigned new Credit Policy Parameters`,
        });
        toast.info(`Credit Policy Parameters assigned successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false });
      this.resetFields();
    } catch (error) {
      toast.error(error.response.data, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      return false;
    } finally {
      this.setState({ submitting: false });
      return true;
    }
  }

  handleOnSelectInstitution = async (id, value) => {
    try {
      this.setState({ parameterIds: [] });
      const institution = this.state.appraisalInstitutions.filter((x) => x.id === parseInt(value))[0];
      const appraisalInstitution = await appraisalInstitutionService.retrieveByInstitutionCode(institution.code);
      this.setState({ institution, existingCreditPolicy: appraisalInstitution.data.creditPolicyParameters });
      if ((this.state.existingCreditPolicy).length > 0 && institution) {
        const creditPolicy = this.state.existingCreditPolicy
        creditPolicy.forEach((element) => {
          this.handleOnChangeCreditPolicyParameter(element.id)
        })
      }
      // this.setState({ institution })
    } catch (error) {
      toast.info("Something went wrong.", {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      toast.info(error.response.data.errors[0], {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
    }
  };

  handleOnChangeCreditPolicyParameter = (item) => {
    const parameterIds = [...this.state.parameterIds];
    if (parameterIds.indexOf(item) !== -1) {
      parameterIds.splice(parameterIds.indexOf(item), 1);
    } else {
      parameterIds.push(item);
    }
    this.setState({ parameterIds });
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      parameterIds: [],
    });
  }

  render() {
    const {
      appraisalInstitutions,
      institution,
      creditPolicyParameters,
      parameterIds,
      submitting,
      fetchingRequiredItems,
    } = this.state;

    let institutionsInputData: ISelectItems[] = appraisalInstitutions.map((item) => ({
      name: item.name,
      value: item.id,
    }));

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Assign Credit Policy Parameters</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <form action="#">
                  <div className="card no-b">
                    <div className="card-body">
                      <div className="form-row">
                        <div className="col-md-12">
                          <div className="form-row">
                            <SelectInputComponent
                              id="institution"
                              name="institution"
                              divClass={6}
                              value={institution.id}
                              items={institutionsInputData}
                              required={true}
                              validated={false}
                              errors={[]}
                              labelIconClass={fetchingRequiredItems === true
                                ? "fas fa-spinner fa-spin orange-text mr-2"
                                : ""
                              }
                              onChange={this.handleOnSelectInstitution}
                            />
                          </div>
                        </div>
                      </div>

                      <hr />
                      {_.isEmpty(institution) ? (
                        ""
                      ) : (
                        <div className="form-row">
                          <div className="col-md-6">
                            <label
                              htmlFor="scoreIndicatorIds"
                              className="control-label textbox-label"
                            >
                              Credit Policy Parameters
                            </label>
                            <div
                              className="text-nowrap block-example border"
                              style={{
                                width: "100%",
                                maxHeight: "200px",
                                overflowY: "scroll",
                              }}
                            >
                              <React.Fragment>
                                {creditPolicyParameters.length ===
                                  0 ? (
                                  <div
                                    style={{
                                      backgroundColor: "#F3F3F3",
                                    }}
                                  >
                                    <p className="text-center">
                                      no credit policy parameters
                                    </p>
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    {creditPolicyParameters.map(
                                      (item) => (
                                        <div
                                          className="custom-control custom-checkbox"
                                          key={creditPolicyParameters.indexOf(
                                            item
                                          )}
                                        >
                                          <input
                                            id={item.id}
                                            name={item.id}
                                            className="custom-control-input"
                                            type="checkbox"
                                            onChange={() =>
                                              this.handleOnChangeCreditPolicyParameter(
                                                item.id
                                              )
                                            }
                                            checked={
                                              _.isEmpty(
                                                this.state.parameterIds.filter(
                                                  (x) => x === item.id
                                                )
                                              )
                                                ? false
                                                : true
                                            }
                                            required={false}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={item.id}
                                          >
                                            {item.name}
                                          </label>
                                        </div>
                                      )
                                    )}
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <hr />
                    <div className="card-body">
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        disabled={submitting || parameterIds.length == 0}
                        onClick={(e) => { this.onHandleSubmit(e) }}
                      >
                        {submitting === false ? (
                          <React.Fragment>
                            <i className="fas fa-lg fa-save mr-3" /> Assign
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                            Please wait...
                          </React.Fragment>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AssignCreditPolicyParameter;
