import http from "../httpService";

const sweepBaseAddress = process.env.REACT_APP_SWEEP_BASEADDRESS;
export function retrieveMandateInfo(
  loanInstitutionCode: string,
  loanRef: string
) {
  return http.get(
    sweepBaseAddress +
    `Mandates/GetMandateInfo?institutionCode=${loanInstitutionCode}&loanReference=${loanRef}`
  );
}

export function retrieveSecondaryMandateInfo(
  loanInstitutionCode: string,
  loanRef: string,
  bvn: string
) {
  return http.get(
    sweepBaseAddress +
    `Mandates/GetSecondaryMandateInfo?institutionCode=${loanInstitutionCode}&loanReference=${loanRef}&bvn=${bvn}`
  );
}

export default {
  retrieveMandateInfo,
  retrieveSecondaryMandateInfo,
};
