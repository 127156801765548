import React from "react";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../../components/BaseFormComponent";
import appraisalValidationService from "../../../../services/hybridAppraisal/appraisalValidationService";
import { ISelectItems } from "../../../../components/detailItemSelectBox";
import { ApproverType } from "../../../../enums/approverType";
import SelectInputComponent from "../../../../components/formSelectComponent";
import FormInputComponent from "../../../../components/formInputComponent";
import MultiSelectComponent from "../../../../components/formMultiSelectComponent";

interface AddRuleModalFields {
  levelName?: string;
  approverType: string;
  roleID?: string;
  systemProcessID?: string;
  systemProcessType?: string;
  acceptanceScore?: string;
  acceptanceString?: string;
  userCount?: number;
  privilegeScreens?: string;
  approverPage: string;
}

class AddRuleModalForm extends BaseFormComponent<AddRuleModalFields> { }

export interface IAddRuleModalProps {
  data: any;
  userRoles: any;
  systemProcess: any;
  showAddRuleModal: boolean;
  toggleAddRuleModal: any;
  // levels: any;
}

export interface IAddRuleModalState {
  data: any;
  ownUpdate: boolean;
  levelName: string;
  privilegeScreens: any;
  approverPage: string;
}

class AddRuleModal extends React.Component<
  IAddRuleModalProps,
  IBaseFormState & IAddRuleModalState
> {
  constructor(props: IAddRuleModalProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      data: {},
      ownUpdate: false,
      levelName: "",
      privilegeScreens: [],
      approverPage: ""
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      data: nextProps.data,
    };
  }

  onFormSubmit(fields: AddRuleModalFields, onReloadFieldsCB: any): boolean {
    try {
      // console.log(fields);
      const { data } = this.state;
      data.approverType = fields.approverType;
      data.roleID = fields.roleID;
      data.systemProcessID = fields.systemProcessID;
      data.acceptanceScore = fields.acceptanceScore;
      data.acceptanceString = "";
      data.roleName = (fields.roleID !== "")
        ? (this.props.userRoles.filter(item => item.id === Number(fields.roleID))[0].name) : ""
      data.processName = (fields.systemProcessID !== "")
        ? (this.props.systemProcess.filter(item => item.id === Number(fields.systemProcessID))[0].name) : ""
      data.levelName = fields.levelName
      data.userCount = Number(fields.systemProcessID) !== 0 ? 1 : fields.userCount;
      data.privilegeScreens = this.state.privilegeScreens
      data.approverPage = fields.approverPage //=== "Yes" ? true : false
      this.setState({ data, ownUpdate: true }, () => {
        this.setState(
          {
            levelName: fields.levelName,
            errors: appraisalValidationService.validateFinancialGroupConfigForm(
              fields, this.state.data.title === "Level"
            ),
            ownUpdate: true
          },
          () => {
            // console.log(this.state.errors)
            if (Object.keys(this.state.errors).length === 0) {
              this.submit(data, onReloadFieldsCB);
            }
          }
        );
      });
      this.setState({ validated: true, ownUpdate: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (data: any, onReloadFieldsCB: any) => {
    this.props.toggleAddRuleModal(
      data.title,
      data.level,
      data.combinationNo,
      data.approverType,
      Number(data.roleID),
      Number(data.systemProcessID),
      Number(data.acceptanceScore),
      data.acceptanceString,
      data.roleName,
      data.processName,
      data.levelName,
      data.userCount,
      data.privilegeScreens,
      data.approverPage,
      true
    );
    onReloadFieldsCB();
  };

  onChangeLevelName = (value) => {
    // this.setState({ levelName: value })
    let levelName = value

    return value
  }

  onChangePrivilegeScreens = (selected) => {
    this.setState({ privilegeScreens: selected });
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      ownUpdate: true
    });
  }

  render() {
    var { userRoles, systemProcess, showAddRuleModal, toggleAddRuleModal } = this.props;
    var { data, errors, submitting, validated } = this.state;

    // console.log(systemProcess)

    let approverTypeInputData: ISelectItems[] = Object.keys(ApproverType)
      .filter((value) => isNaN(Number(value)) === false)
      .map((key) => ({
        name: ApproverType[key],
        value: ApproverType[key],
      }));

    let userRoleInputData: ISelectItems[] = userRoles.map((item) => ({
      name: item.name,
      value: item.id,
    }));

    let systemProcessValidationInputData: ISelectItems[] =
      systemProcess.filter((x) => x.processType === 'Validation').map((item) => ({
        name: item.name,
        value: item.id,
      }));

    let systemProcessReportInputData: ISelectItems[] =
      systemProcess.filter((x) => x.processType === 'Report').map((item) => ({
        name: item.name,
        value: item.id,
      }));

    let privilegePageInputData: any[] = [
      "Data Validation",
      // "Special Conditions",
      "Document Management",
      "Document Review",
      "Financial Analysis",
      "Policy and Scoring",
      "Credit Analysis",
      // "Credit Analysis (Internal)",
      // "Credit Analysis (FCCB)",
      // "Credit Analysis (CRC)",
      // "Credit Analysis (BankOne)",
      "Corporate Analysis",
      "Disbursement"
    ].map(item => {
      return { label: item, value: item }
    })

    let systemProcessTypeInputData: ISelectItems[] = [
      { name: "Validation", value: "Validation" },
      { name: "Report", value: "Report" },
    ];

    let approverPageTypeInputData: ISelectItems[] = [
      { name: "Yes", value: "Yes" },
      { name: "No", value: "No" },
    ];

    return (
      <Modal
        size="lg"
        backdrop="static"
        show={showAddRuleModal}
        onHide={toggleAddRuleModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-tasks mr-3"></i>
              Add New {" "} {data.title}
            </h5>
          </Modal.Title>
        </Modal.Header>
        <AddRuleModalForm
          initialValues={{
            levelName: "",
            approverType: "",
            roleID: "",
            systemProcessID: "",
            systemProcessType: "",
            acceptanceScore: "",
            userCount: 0,
            privilegeScreens: "",
            approverPage: ""
          }}
          FormComponent={({
            fields: {
              levelName,
              approverType,
              roleID,
              systemProcessID,
              systemProcessType,
              acceptanceScore,
              userCount,
              privilegeScreens,
              approverPage
            },
            onChange,
            onReloadFields,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="form-row">
                  {data.title === "Level" ? (
                    <><FormInputComponent
                      id="levelName"
                      type="text"
                      name="levelName"
                      divClass={6}
                      placeholder=""
                      value={levelName}
                      required={true}
                      validated={false}
                      errors={errors}
                      onChange={async (id, value) => {
                        await onChange(id, value);
                        await onChange('levelName', this.onChangeLevelName(value));
                      }} /><MultiSelectComponent
                        id="privilegeViews"
                        name="privilegeViews"
                        divClass={6}
                        value={this.state.privilegeScreens}
                        options={privilegePageInputData}
                        validated={validated}
                        errors={errors}
                        hideSelectedOptions={false}
                        onChange={this.onChangePrivilegeScreens}
                        allowSelectAll={true} />
                      <SelectInputComponent
                        id="approverPage"
                        name="approverPage"
                        divClass={6}
                        value={approverPage}
                        items={approverPageTypeInputData}
                        validated={validated}
                        errors={errors}
                        onChange={onChange}
                      />
                    </>
                  ) : ("")}
                  <SelectInputComponent
                    id="approverType"
                    name="approverType"
                    divClass={6}
                    value={approverType}
                    items={approverTypeInputData}
                    validated={validated}
                    required={true}
                    errors={errors}
                    onChange={onChange}
                  />
                  {(() => {
                    switch (approverType) {
                      case ApproverType[ApproverType.User]:
                        return (
                          <><SelectInputComponent
                            id="roleID"
                            name="userRole"
                            divClass={6}
                            value={roleID}
                            items={userRoleInputData}
                            validated={validated}
                            required={true}
                            errors={errors}
                            onChange={onChange} />

                            <FormInputComponent
                              id="userCount"
                              type="number"
                              name="userCount"
                              divClass={6}
                              placeholder=""
                              value={userCount.toString()}
                              required={true}
                              validated={false}
                              errors={errors}
                              onChange={onChange} /></>
                        )
                      case ApproverType[ApproverType.SystemProcess]:
                        return (
                          <>
                            <SelectInputComponent
                              id="systemProcessType"
                              name="systemProcessType"
                              divClass={6}
                              value={systemProcessType}
                              items={systemProcessTypeInputData}
                              validated={validated}
                              errors={errors}
                              onChange={onChange}
                            />
                            {systemProcessType === "Validation" ? (
                              <SelectInputComponent
                                id="systemProcessID"
                                name="systemProcess"
                                divClass={6}
                                value={systemProcessID}
                                items={systemProcessValidationInputData}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                              />
                            ) : ("")}
                            {systemProcessType === "Report" ? (
                              <SelectInputComponent
                                id="systemProcessID"
                                name="systemProcess"
                                divClass={6}
                                value={systemProcessID}
                                items={systemProcessReportInputData}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                              />
                            ) : ("")}
                            {systemProcessID !== "" && systemProcessType === "Validation" &&
                              systemProcess.find(x => x.id === Number(systemProcessID)).processType === 'Validation' ? (
                              <FormInputComponent
                                id="acceptanceScore"
                                type="number"
                                name="passCriteria"
                                divClass={6}
                                placeholder=""
                                value={acceptanceScore}
                                required={true}
                                validated={false}
                                errors={errors}
                                onChange={onChange}
                              />
                            ) : ("")}
                          </>
                        )
                      // case ApproverType[ApproverType.PrivilegePages]:
                      //   return (
                      //     <>
                      //       <SelectInputComponent
                      //         id="functionName"
                      //         name="functionName"
                      //         divClass={6}
                      //         value={functionName}
                      //         items={privilegePageInputData}
                      //         validated={validated}
                      //         errors={errors}
                      //         onChange={onChange} />
                      //     </>
                      //   )
                      default:
                        return null
                    }
                  })()}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="primary"
                  onClick={(e) => {
                    this.onFormSubmit({
                      levelName,
                      approverType,
                      roleID,
                      userCount,
                      systemProcessID,
                      systemProcessType,
                      acceptanceScore,
                      privilegeScreens,
                      approverPage
                    }, onReloadFields);
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fa fa-plus mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() =>
                    toggleAddRuleModal(
                      data.title,
                      data.level,
                      data.combinationNo,
                      data.approverType,
                      data.roleID,
                      data.systemProcessID,
                      data.acceptanceScore,
                      data.acceptanceString,
                      data.roleName,
                      data.processName,
                      data.levelName,
                      data.userCount,
                      data.privilegeScreens,
                      data.approverPage,
                      false
                    )
                  }
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></AddRuleModalForm >
      </Modal >
    );
  }
}

export default AddRuleModal;
