import React from "react";
import { Modal } from "react-bootstrap";

export const CogsModal = ({ cogs }: { cogs: any }) => {
  return (
    <Modal.Body>
      <br />
      <div className="row clearfix">
        <div className=" form-row col-md-12">
          <div
            className="table-responsive text-nowrap"
            style={{
              marginTop: 30,
              overflowY: "scroll",
            }}
          >
            <table className="table table-hover table-content table-sm table-striped mb-0">
              <thead>
                <tr>
                  <th
                    scope="col"
                    style={{
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                    }}
                  >
                    <strong>#</strong>
                  </th>
                  <th
                    scope="col"
                    style={{
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                    }}
                  >
                    <strong>Month</strong>
                  </th>
                  <th
                    scope="col"
                    style={{
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                    }}
                  >
                    <strong>Product Name</strong>
                  </th>
                  <th
                    scope="col"
                    style={{
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                    }}
                  >
                    <strong>Purchase Price</strong>
                  </th>
                  <th
                    scope="col"
                    style={{
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                    }}
                  >
                    <strong>Quantity</strong>
                  </th>
                  <th
                    scope="col"
                    style={{
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                    }}
                  >
                    <strong>Total</strong>
                  </th>
                  <th
                    scope="col"
                    style={{
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                    }}
                  >
                    <strong>Sale Price</strong>
                  </th>
                  <th
                    scope="col"
                    style={{
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                    }}
                  >
                    <strong>Gross Profit</strong>
                  </th>
                  <th
                    scope="col"
                    style={{
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                    }}
                  >
                    <strong>Gross Margin</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {cogs?.map((item, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                        fontSize: "small",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                        fontSize: "small",
                      }}
                    >
                      {item?.month}
                    </td>
                    <td
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                        fontSize: "small",
                      }}
                    >
                      {item?.productName}
                    </td>
                    <td
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                        fontSize: "small",
                      }}
                    >
                      {item?.purchasePrice}
                    </td>
                    <td
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                        fontSize: "small",
                      }}
                    >
                      {item?.quantity}
                    </td>
                    <td
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                        fontSize: "small",
                      }}
                    >
                      {item?.total}
                    </td>
                    <td
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                        fontSize: "small",
                      }}
                    >
                      {item?.salePrice}
                    </td>
                    <td
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                        fontSize: "small",
                      }}
                    >
                      {item?.grossProfit.toFixed(2)}
                    </td>
                    <td
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                        fontSize: "small",
                      }}
                    >
                      {item?.grossMargin.toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Modal.Body>
  );
};
