import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../components/detailItem";
import textFormatService from "../../services/utility/textFormatService";
// import swal from "sweetalert";
// import { toast } from "react-toastify";

interface IRemoteSessionDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  showTrail: any;
  currentUserRoles: any;
  item: any;
  institution: any;
}

interface IRemoteSessionDetailState {
  item: any;
  institution: any;
//   updatingStatus: boolean;
}

class RemoteSessionDetail extends Component<IRemoteSessionDetailProps, IRemoteSessionDetailState> {
    constructor(props: IRemoteSessionDetailProps) {
      super(props);
      this.state = {
        item: {},institution:{}
      };
    }
  static getDerivedStateFromProps(nextProps: IRemoteSessionDetailProps, prevState) {
    // console.log("current props: ", nextProps.item);
    return {
      item: nextProps.item,institution: nextProps.institution
    };
  }

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      showTrail,
      currentUserRoles,
      item,
      institution
    } = this.props;
    // const { updatingStatus } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="icon icon-building-o s-18 mr-3" />
                Remote Session Details -{" "}
                <span className="font-weight-bold">{institution.name}</span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
              {/* <div className="float-right">
                {_.isEmpty(item) ? (
                  <Skeleton width={100} />
                ) : (
                  <span
                    className="badge btn-primary"
                    onClick={showTrail}
                    style={{
                      cursor: "pointer",
                      visibility: currentUserRoles.includes(
                        "viewaudittrailitems"
                      )
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    View Trail
                  </span>
                )}
              </div> */}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                    <DetailItem label="Remote User" value={item.username} />
                      <DetailItem label="session Start" value={textFormatService.formatTimeString(item.sessionStartTime)} />
                      <DetailItem label="Duration" value={item.duration} />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={2} width={300} />
                  ) : (
                    <dl className="row">
                    <DetailItem label="Session Id" value={item.sessionId} />
                      <DetailItem label="session End" value={textFormatService.formatTimeString(item.sessionEndTime)} />
                    </dl>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default RemoteSessionDetail;
