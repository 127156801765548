import http from "../httpService";
import axios from "axios";
import {
  getCurrentUserSession,
  retrieveCurrentUsersIpAddress,
} from "./authService";
import { Status } from "../../enums/status";
import { IFilterData } from "../approval/approvalItemService";
import { decodeToken } from "react-jwt";

interface searchBranchesData {
  name: string;
  code: string;
  status: Status;
}

interface ISaveBranch {
  name: string;
  description: string;
  code: string;
  institutionId: number;
  clientIPAddress: string;
}
const authBaseAddress = process.env.REACT_APP_AUTH_BASEADDRESS;
export async function saveBranch(branch: ISaveBranch) {
  branch.clientIPAddress = String(retrieveCurrentUsersIpAddress());
  var url = authBaseAddress + `branches`;
  return http.post(url, branch, {
    url: url,
    method: "post",
    data: branch,
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export async function updateBranch(branch: any, branchId: number) {
  branch.clientIPAddress = retrieveCurrentUsersIpAddress();
  var url = authBaseAddress + `branches/${branchId}`;
  return http.post(url, branch, {
    url: url,
    method: "post",
    data: branch,
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveBranchById(branchId: number) {
  var url = authBaseAddress + `branches?id=${branchId}`;
  return http.get(url, {
    url: url,
    method: "get",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function retrieveAllBranches() {
  var url = authBaseAddress + `branches/GetAll`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveBranchByCode(code: string) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  var url =
    authBaseAddress +
    `branches/GetByCode?institutionId=${decodedToken?.["InstitutionId"]}&Code=${code}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export async function enableBranch(id: number) {
  var url = authBaseAddress + `branches/Enable/${id}`;
  return http.post(url, null, {
    url: url,
    method: "post",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
      ClientIPAddress: retrieveCurrentUsersIpAddress(),
    },
  });
}

export async function disableBranch(id: number) {
  var url = authBaseAddress + `branches/Disable/${id}`;
  return http.post(url, null, {
    url: url,
    method: "post",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
      ClientIPAddress: retrieveCurrentUsersIpAddress(),
    },
  });
}

export function searchBranches(
  data: searchBranchesData,
  pageSize: number,
  pageNumber: number
) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  var url =
    authBaseAddress +
    `branches/SearchBranches?institutionId=${decodedToken?.["InstitutionId"]}&name=${data.name
    }&code=${data.code
    }&status=${data.status.toString()}&pageSize=${pageSize}&pageNumber=${pageNumber}`;
  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function filterBranches(
  payload: IFilterData,
  pageSize: number,
  pageNumber: number
) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  payload = { ...payload };
  payload.institutionId = {
    item1: "=",
    item2: decodedToken?.["InstitutionId"]?.toString() || "break",
  };
  var url =
    authBaseAddress +
    `branches/FilterBranches?pageSize=${pageSize}&pageNumber=${pageNumber}`;
  return http.post(url, payload, {
    url: url,
    method: "post",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function downloadReport(type: string) {
  var url = authBaseAddress + `branches/downloadreport?type=${type}`;

  axios({
    url: url,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Branches.${type}`); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
}

export function downloadReportPDF() {
  var url = authBaseAddress + `branches/DownloadReportPDF`;
  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export default {
  saveBranch,
  updateBranch,
  retrieveBranchById,
  retrieveAllBranches,
  retrieveBranchByCode,
  enableBranch,
  disableBranch,
  searchBranches,
  filterBranches,
  downloadReport,
  downloadReportPDF,
};
