import React from "react";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import billingValidationService from "../../../services/billing/billingValidationService";
import paymentConfigService from "../../../services/billing/paymentConfigService";
import { ActionTypes } from "../../../enums/actionTypes";
import DetailItem from "../../../components/detailItem";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

interface EditPaymentConfigurationFields {
  feePayableLimit: string;
  appzoneIncomeBankName: string;
  appzoneIncomeBankCode: string;
  appzoneIncomeAccountNumber: string;
  unsuccessfulPaymentRetry: string;
  narration: string;
}

class EditPaymentConfigurationForm extends BaseFormComponent<EditPaymentConfigurationFields> { }

interface IEditPaymentConfigurationProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}
interface IEditPaymentConfigurationState {
  ownUpdate: boolean;
}

class EditPaymentConfiguration extends React.Component<
  IEditPaymentConfigurationProps,
  IBaseFormState & IEditPaymentConfigurationState
> {
  constructor(props: IEditPaymentConfigurationProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      ownUpdate: false,
    };
  }

  static getDerivedStateFromProps(prevState) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    return null;
  }

  onFormSubmit(fields: EditPaymentConfigurationFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors: billingValidationService.validateEditPaymentConfigurationForm(fields),
          ownUpdate: true,
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (fields: EditPaymentConfigurationFields, onReloadFieldsCB: any) => {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const payload = this.props.item;

      // console.log("For checks::", payload);
      payload.feePayableLimit = Number(fields.feePayableLimit);
      payload.appzoneIncomeBankName = fields.appzoneIncomeBankName;
      payload.appzoneIncomeBankCode = fields.appzoneIncomeBankCode;
      payload.appzoneIncomeAccountNumber = fields.appzoneIncomeAccountNumber;
      payload.unsuccessfulPaymentRetry = fields.unsuccessfulPaymentRetry;
      payload.narration = fields.narration;

      const response = await paymentConfigService.updatePaymentConfig(payload.paymentConfigurationId, payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit Payment Configuration",
          description: `Edited details of Payment Configuration ${payload.serviceOffering}`,
        });
        toast.info(`Payment Configuration for ${payload.serviceOffering} Edited successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      }
      onReloadFieldsCB();
      this.setState({ submitting: false, ownUpdate: true }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
    }
  };

  render() {
    const { item, showEditModal, toggleEditModal } = this.props;
    const { validated, submitting, errors } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-gopuram fa-sm cyan-text mr-3" />
              Edit Payment Configuration -{" "}
              <span className="font-weight-bold">{item.institutionCategory} ({item.serviceOffering})</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditPaymentConfigurationForm
          initialValues={{
            feePayableLimit: item.feePayableLimit,
            appzoneIncomeBankName: item.appzoneIncomeBankName,
            appzoneIncomeBankCode: item.appzoneIncomeBankCode,
            appzoneIncomeAccountNumber: item.appzoneIncomeAccountNumber,
            unsuccessfulPaymentRetry: item.unsuccessfulPaymentRetry,
            narration: item.narration,
          }}
          FormComponent={({
            fields: {
              feePayableLimit,
              appzoneIncomeBankName,
              appzoneIncomeBankCode,
              appzoneIncomeAccountNumber,
              unsuccessfulPaymentRetry,
              narration,
            },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    <h6
                      className="card-title float-left"
                      style={{ marginBottom: "0px" }}
                    >
                      General Information
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <dl className="row">
                          <DetailItem
                            label="Institution Category"
                            labelSize={7}
                            valueSize={5}
                            value={item.institutionCategory}
                          />
                          <DetailItem
                            label="Fee Payable Limit"
                            labelSize={7}
                            valueSize={5}
                            value={
                              <DetailItemInputComponent
                                id="feePayableLimit"
                                type="text"
                                name="feePayableLimit"
                                placeholder=""
                                value={feePayableLimit}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                              />
                            }
                          />
                          <DetailItem
                            label="Appzone Income Bank Code"
                            labelSize={7}
                            valueSize={5}
                            value={
                              <DetailItemInputComponent
                                id="appzoneIncomeBankCode"
                                type="text"
                                name="appzoneIncomeBankCode"
                                placeholder=""
                                value={appzoneIncomeBankCode}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                              />
                            }
                          />
                          <DetailItem
                            label="Unsuccessful Payment Retry"
                            labelSize={7}
                            valueSize={5}
                            value={
                              <DetailItemInputComponent
                                id="unsuccessfulPaymentRetry"
                                type="text"
                                name="unsuccessfulPaymentRetry"
                                placeholder=""
                                value={unsuccessfulPaymentRetry}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                              />
                            }
                          />
                        </dl>
                      </div>
                      <div className="col-md-6">
                        <dl className="row">
                          <DetailItem
                            label="Service Offering"
                            labelSize={7}
                            valueSize={5}
                            value={item.serviceOffering}
                          />
                          <DetailItem
                            label="Appzone Income Bank Name"
                            labelSize={7}
                            valueSize={5}
                            value={
                              <DetailItemInputComponent
                                id="appzoneIncomeBankName"
                                type="text"
                                name="appzoneIncomeBankName"
                                placeholder=""
                                value={appzoneIncomeBankName}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                              />
                            }
                          />
                          <DetailItem
                            label="Appzone Income Account Number"
                            labelSize={7}
                            valueSize={5}
                            value={
                              <DetailItemInputComponent
                                id="appzoneIncomeAccountNumber"
                                type="text"
                                name="appzoneIncomeAccountNumber"
                                placeholder=""
                                value={appzoneIncomeAccountNumber}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                              />
                            }
                          />
                          <DetailItem
                            label="Narration"
                            labelSize={7}
                            valueSize={5}
                            value={
                              <DetailItemInputComponent
                                id="narration"
                                type="text"
                                name="narration"
                                placeholder=""
                                value={narration}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                              />
                            }
                          />
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(e, ActionTypes.UPDATE, "Payment Configuration", () => {
                      this.onFormSubmit(
                        {
                          feePayableLimit,
                          appzoneIncomeBankName,
                          appzoneIncomeBankCode,
                          appzoneIncomeAccountNumber,
                          unsuccessfulPaymentRetry,
                          narration
                        },
                        onReloadFields
                      );
                    });
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></EditPaymentConfigurationForm>
      </Modal>
    );
  }
}

export default EditPaymentConfiguration;
