import React from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import FormInputComponent from "../../../components/formInputComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import creditAssessmentValidationService from "../../../services/creditAssessment/creditAssessmentValidationService";
import SelectInputComponent, {
  ISelectItems,
} from "../../../components/formSelectComponent";
import messageTemplateService from "../../../services/creditAssessment/messageTemplateService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

interface AddMessageTemplateFields {
  name: string;
  messageType: "SMS" | "Email";
  subject: string;
  body: string;
  bccEmailAddresses: string;
}

class AddMessageTemplateForm extends BaseFormComponent<AddMessageTemplateFields> { }

interface IAddMessageTemplateProps { }
interface IAddMessageTemplateState { }

class AddMessageTemplate extends React.Component<
  IAddMessageTemplateProps,
  IBaseFormState & IAddMessageTemplateState
> {
  constructor(props: IAddMessageTemplateProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
    };
  }

  onFormSubmit(fields: AddMessageTemplateFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors:
            creditAssessmentValidationService.validateAddMessageTemplateForm(fields),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            // console.log("val resp:: ", validationResponse);
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddMessageTemplateFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({
        submitting: true,
      });
      const payload: any = {};
      // const decodedToken = decodeToken(getCurrentUserSession().token || "");
      payload.name = fields.name;
      payload.messageType = fields.messageType;
      payload.subject = fields.subject;
      payload.body = fields.body;
      payload.bccEmailAddresses = fields.bccEmailAddresses;

      const response = await messageTemplateService.saveMessageTemplate(payload);
      await activityTrailService.saveActivityTrail({
        actionType: "Add Message Template",
        description: `Added new Message Template ${fields.name}`,
      });
      if (response.status >= 200 && response.status <= 300) {
        toast.info(`Message Template ${fields.name} created successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false });
      this.resetFields();
      onReloadFieldsCB();
    } catch (error) {
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false });
      return false;
    } finally {
      return true;
    }
  }

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
    });
  }
  render() {
    const {
      errors,
      validated,
      submitting
    } = this.state;

    let messageTypeInputData: ISelectItems[] = ["Email", "SMS"].map((item) => ({
      name: item,
      value: item,
    }));

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add Message Template</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddMessageTemplateForm
                  initialValues={{ name: "", messageType: "Email", subject: "", body: "", bccEmailAddresses: "" }}
                  FormComponent={({
                    fields: { name, messageType, subject, body, bccEmailAddresses },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <h5 className="card-title ml-3">
                            {/* <i className="fas fa-columns mr-3" /> */}
                            Details
                          </h5>
                          <div className="form-row">
                            <div className="col-md-6">
                              <FormInputComponent
                                id="name"
                                type="text"
                                name="name"
                                placeholder=""
                                value={name}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                              />
                            </div>
                            <div className="col-md-6">
                              <SelectInputComponent
                                id="messageType"
                                name="indicatorType"
                                // divClass={4}
                                value={messageType}
                                items={messageTypeInputData}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                                onChangeCallBack={() => onChange("bccEmailAddresses", "")}
                              />
                            </div>
                            <div className="col-md-6">
                              <FormInputComponent
                                id="subject"
                                type="text"
                                name="subject"
                                placeholder=""
                                value={subject}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                              />
                            </div>
                            {messageType == "SMS" ? ("") : (
                              <div className="col-md-6">
                                <FormInputComponent
                                  id="bccEmailAddresses"
                                  type="text"
                                  name="bccEmailAddresses"
                                  placeholder=""
                                  value={bccEmailAddresses}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                            )}
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-6 m-3 pr-4">
                              <label
                                htmlFor="body"
                                className="col-form-label s-12"
                              >
                                <span
                                  aria-hidden="true"
                                  className="mr-2"
                                  style={{ color: "#ed5564" }}
                                >
                                  *
                                </span>
                                Body
                              </label>
                              <textarea
                                id="body"
                                name="body"
                                placeholder=""
                                className={
                                  !validated
                                    ? "form-control r-0 light s-12"
                                    : errors.body
                                      ? "form-control r-0 light s-12 is-invalid"
                                      : "form-control r-0 light s-12 is-valid"
                                }
                                rows={6}
                                value={body}
                                onChange={(e) =>
                                  // this.handleOnChangeMessage(
                                  //   e.currentTarget.value
                                  // )
                                  onChange("body", e.currentTarget.value)
                                }
                              ></textarea>
                              <div
                                className="invalid-feedback"
                                style={{
                                  visibility: errors.body,
                                }}
                              >
                                {errors.body}
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="card-body">
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                              disabled={this.state.submitting}
                              onClick={(e) => {
                                onHandleSubmit(
                                  e,
                                  ActionTypes.SAVE,
                                  "Message Template",
                                  () => {
                                    this.onFormSubmit(
                                      { name, bccEmailAddresses, body, subject, messageType },
                                      onReloadFields
                                    );
                                  }
                                );
                              }}
                            >
                              {this.state.submitting === false ? (
                                <React.Fragment>
                                  <i className="fas fa-lg fa-save mr-3" /> Save
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                  Please wait...
                                </React.Fragment>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                ></AddMessageTemplateForm>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddMessageTemplate;
