import http from "../httpService";
import { status } from "../approval/approvableService";
import { getIpAddress, cryptoAESEncrypt } from "../utility/textFormatService";
import { decodeToken } from "react-jwt";
import * as CryptoJS from "crypto-js";
import _ from "lodash";
import remoteSessionService from "./remoteSessionService";

interface ISetPasswordByEmailData {
  email: string;
  institutionCode: string;
}
interface ILoginPayload {
  institutionCode: string;
  userName: string;
  password: string;
  token?: string;
}
export interface IUserSession {
  [x: string]: any;
  dateCreated?: Date;
  expirationDate?: Date;
  id?: number;
  // institutionId?: number;
  sessionId?: string;
  // institutionCode?: string;
  status?: status;
  token?: string;
  userId?: number;
  userRoleId?: number;
  locked?: boolean;

  
}

const user = "user";
const userRoles = "userRoles";
const userSession = "userSession";

const authBaseAddress = process.env.REACT_APP_AUTH_BASEADDRESS;
export function retrieveUser(
  institutionCode: string,
  username: string,
  password: string
) {
  const payload: any = {};
  payload.institutionCode = institutionCode;
  payload.userName = username;
  payload.password = cryptoAESEncrypt(password);

  let currentUserSession: any = {}
  if (getCurrentUsersRoles().includes("remotesession")) {
    currentUserSession = JSON.parse(localStorage.getItem("remoteUserSession") || "")
  } else {
    currentUserSession = getCurrentUserSession();
  }
  var url = authBaseAddress + `auth/GetUser`;
  return http.post(url, payload, {
    url: url,
    method: "post",
    data: payload,
    headers: {
      Authorization: `Bearer ${currentUserSession.token}`,
      ClientIPAddress: retrieveCurrentUsersIpAddress(),
    },
  });
}

export function retrieveUser2(
  institutionCode: string,
  username: string,
  password: string
) {
  const payload: any = {};
  payload.institutionCode = institutionCode;
  payload.userName = username;
  payload.password = cryptoAESEncrypt(password);

  var url = authBaseAddress + `auth/GetUser2`;
  return http.post(url, payload, {
    url: url,
    method: "post",
    data: payload,
    headers: {
      // Authorization: `Bearer ${getCurrentUserSession().token}`,
      ClientIPAddress: retrieveCurrentUsersIpAddress(),
    },
  });
}

export async function loginUser(payload: ILoginPayload) {
  // console.log("logging in...");
  var clientip: any = await getIpAddress();
  var value = String(clientip.data).split("ts")[0].split("ip=")[1];

  // var key = CryptoJS.enc.Utf8.parse("8080808080808088");
  // var iv = CryptoJS.enc.Utf8.parse("8080808080808088");

  var url = authBaseAddress + `auth/LoginUser`;
  payload.password = cryptoAESEncrypt(payload.password);
  if (!_.isEmpty(payload.token)) {
    payload.token = cryptoAESEncrypt(payload.token);
  }
  // payload.password = CryptoJS.AES.encrypt(
  //   CryptoJS.enc.Utf8.parse(payload.password),
  //   key,
  //   {
  //     keySize: 128 / 8,
  //     iv: iv,
  //     mode: CryptoJS.mode.CBC,
  //     padding: CryptoJS.pad.Pkcs7,
  //   }
  // ).toString();
  // console.log("check1: ", payload);
  // console.log("url: ", url);
  const response = await http.post(url, payload, {
    url: url,
    method: "post",
    data: payload,
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
      ClientIPAddress: value,
    },
  });
  return response;
}

export function retrieveCurrentUsersIpAddress() {
  return localStorage.getItem("clientip");
}

export async function saveUserSessionDetails(details: any) {
  var clientip: any = await getIpAddress();
  var value = String(clientip.data).split("ts")[0].split("ip=")[1];
  // console.log("clientip: ", clientip.data);
  // console.log("about to save userSession: ", details);
  localStorage.setItem(userSession, JSON.stringify(details));
  localStorage.setItem("clientip", value);
}

export async function retrieveRolesForUser(
  institutionId: number,
  username: string
) {
  const response = await http.get(
    authBaseAddress +
    `auth/GetRolesForUser?institutionId=${institutionId}&username=${username}`
  );
  localStorage.setItem(userRoles, JSON.stringify(response.data));
  return response;
}

export async function setPasswordByEmail(data: ISetPasswordByEmailData) {
  return http.get(
    authBaseAddress +
    `auth/setPasswordByEmail?email=${data.email}&institutionCode=${data.institutionCode}`
  );
}

export async function setRoleFunctionsForUser(userId: number, token: string) {
  var url = authBaseAddress + `auth/getRoleFunctionsForUser`;

  const response = await http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${token}` },
  });
  localStorage.setItem(userRoles, JSON.stringify(response.data));
  //return response;
}

export function retrieveRoleFunctionsForUser() {
  return JSON.parse(localStorage.getItem(userRoles) || "");
}

export function isUserInRole(
  institutionId: number,
  username: string,
  roleName: string
) {
  return http.get(
    authBaseAddress +
    `auth/IsUserInRole?institutionId=${institutionId}&username=${username}&roleName=${roleName}`
  );
}

export function retrieveUsersInRole(institutionId: number, roleId: number) {
  return http.get(
    authBaseAddress +
    `auth/GetUsersInRole?institutionId=${institutionId}&RoleID=${roleId}`
  );
}

export function generate2faToken(institutionId: number, username: string) {
  return http.post(
    authBaseAddress +
    `auth/Generate2FAToken?username=${username}&institutionId=${institutionId}`
  );
}

export function verify2faToken(
  token: string,
  userId: number,
  institutionId: number
) {
  const payload: any = {};
  payload.token = cryptoAESEncrypt(token);
  payload.userId = userId;
  payload.institutionId = institutionId;
  return http.post(authBaseAddress + `auth/Verify2FAToken`, payload);
}

export async function logout() {
  // const decodedToken = decodeToken(getCurrentUserSession().token || "");
  let currentUserSession: any = {}
  if (getCurrentUsersRoles().includes("remotesession")) {
    currentUserSession = JSON.parse(localStorage.getItem("remoteUserSession") || "")
  } else {
    currentUserSession = getCurrentUserSession();
  }
  var url = authBaseAddress + `auth/Logout`;
  http.get(url, {
    url: url,
    method: "get",
    headers: {
      Authorization: `Bearer ${currentUserSession.token}`,
    },
  });

  // localStorage.removeItem(user);
  // localStorage.removeItem(userRoles);
  // localStorage.removeItem(userSession);
  // localStorage.removeItem("clientip");
  localStorage.clear();

  // window.location.replace("/");
}

export function getCurrentUsersRoles() {
  return JSON.parse(localStorage.getItem(userRoles) || "");
}

export function getCurrentUserSession(): IUserSession {
  if (localStorage.getItem(userSession)?.toString() === undefined) {
    return {};
  } else return JSON.parse(localStorage.getItem(userSession) || "");
  // let session: IUserSession = JSON.parse(
  //   localStorage.getItem(userSession) || ""
  // );
  // console.log("");
  // return session;
}

export function setCurrentUserSession(_userSession: IUserSession) {
  localStorage.setItem(userSession, JSON.stringify(_userSession));
  return getCurrentUserSession;
}

export function getSessionId() {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  return `${decodedToken?.["UserId"]}-${decodedToken?.["UserRoleId"]}`;
}

export default {
  retrieveUser,
  retrieveUser2,
  loginUser,
  retrieveRolesForUser,
  setRoleFunctionsForUser,
  isUserInRole,
  retrieveUsersInRole,
  verify2faToken,
  generate2faToken,
  logout,
  // getCurrentUser,
  getCurrentUsersRoles,
  // getSessionId,
  retrieveRoleFunctionsForUser,
};
