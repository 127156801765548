import React, { Component } from "react";
import { Modal } from "react-bootstrap";

interface IDocumentPreviewerProps {
  document: any;
  showPreview: boolean;
  toggleShowPreview: any;
}

interface IDocumentPreviewerState { }

class DocumentPreviewer extends Component<
  IDocumentPreviewerProps,
  IDocumentPreviewerState
> {
  state = {};

  render() {
    const { document, showPreview, toggleShowPreview } = this.props;

    return (
      <Modal size="xl" show={showPreview} onHide={toggleShowPreview}>
        <Modal.Header closeButton>
          <h5>Document Preview</h5>
        </Modal.Header>
        <Modal.Body>
          {document.extensionType === "PNG" ||
            document.extensionType === "JPG" ||
            document.extensionType === "GIF" ? (
            <div style={{ float: "right" }}>
              <a download={document.downloadname} href={document.data}>
                <i className="fas fa-download fa-lg my-2"></i>
              </a>
            </div>
          ) : (
            ""
          )}
          {/* <div style={{ float: "right" }}>
            <a download="FILENAME.EXT" href="data:image/png;base64,asdasd...">
              <i className="fas fa-download fa-lg my-2"></i>
            </a>
          </div> */}
          <br />
          <div>{document.payload}</div>
          {/* <img src={document.image} alt={document.name} className="image" /> */}
        </Modal.Body>
      </Modal>
    );
  }
}

export default DocumentPreviewer;
