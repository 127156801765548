import React, { Fragment, Component } from "react";
import { toast } from "react-toastify";
import BaseFormComponent, { IBaseFormState } from "../../../../../../components/BaseFormComponent";
import FormInputComponent from "../../../../../../components/formInputComponent";
import SelectInputComponent, { ISelectItems } from "../../../../../../components/formSelectComponent";
import { ActionTypes } from "../../../../../../enums/actionTypes";
import loanRequestService from "../../../../../../services/hybridAppraisal/loanRequestService";
import { countries } from "./../../../../../../services/utility/countries";
import _ from "lodash";
import hybridAppraisalValidationService from "../../../../../../services/hybridAppraisal/hybridAppraisalValidationService";
import groupLoanRequestService from "../../../../../../services/hybridAppraisal/groupLoanRequestService";
import groupLendingValidationService from "../../../../../../services/hybridAppraisal/groupLendingValidationService";

interface ContactDetailsFields {
    addressType: string;
    propertyStatus: string;
    address: string;
    city: string;
    state: string;
    country: string;
    phonenumber: string;
    phoneNumber2: string;
    customerEmail: string;
    yearsOfStayAtCurrentAddress: string;
    monthsOfStayAtCurrentAddress: string;
    yearsOfStayAtCurrentCity: string;
    monthsOfStayAtCurrentCity: string;
    twitterHandle: string;
    instagramID: string;
    facebookID: string;
    location: string;
}

class ContactDetailsForm extends BaseFormComponent<ContactDetailsFields> { }
interface ContactDetailsProps {
    values: any;
    handleChange: any;
    handleBlur: any;
    reloadItem: any;
}

interface ContactDetailsState {
    country: string;
    ownUpdate: boolean;
    item: any;
}

class GroupContactDetails extends Component<ContactDetailsProps, IBaseFormState & ContactDetailsState> {
    constructor(props: ContactDetailsProps) {
        super(props);
        this.state = {
            validated: false,
            submitting: false,
            errors: {},
            country: this.props.values.country,
            item: {},
            ownUpdate: false
        };
    }

    static getDerivedStateFromProps(
        nextProps: ContactDetailsProps,
        prevState: ContactDetailsState
    ) {
        if (prevState.ownUpdate) {
            return {
                ownUpdate: false,
            };
        }
        if (_.isNull(nextProps.values)) {
            return null;
        }
        return {
            item: nextProps.values
        };
    }

    onFormSubmit(fields: ContactDetailsFields, onReloadFieldsCB: any): boolean {
        try {
            this.setState(
                {
                    // errors: groupLendingValidationService.validateContactDetailsForm(fields), ownUpdate: true
                },
                () => {
                    // if (Object.keys(this.state.errors).length === 0) {
                    return this.submit(fields, onReloadFieldsCB);
                    // }
                }
            );
            this.setState({ validated: true, ownUpdate: true });
            return false;
        } catch (error) {
            return false;
        }
    }

    async submit(
        fields: ContactDetailsFields,
        onReloadFieldsCB: any
    ): Promise<boolean> {
        try {
            this.setState({ submitting: true, ownUpdate: true });
            const loanRequest = await groupLoanRequestService.retrieveById(this.props.values.id);
            const loanRequestDetail = loanRequest.data;
            const payload: any = { ...loanRequestDetail };
            // payload.addressType = fields.addressType;
            console.log(fields)
            payload.address = fields.address;
            payload.city = fields.city;
            payload.state = fields.state;
            payload.country = this.state.country;
            payload.phonenumber = fields.phonenumber;
            payload.phoneNumber2 = fields.phoneNumber2;
            payload.customerEmail = fields.customerEmail;
            payload.locationCoordinates = fields.location;

            console.log(payload)

            const response = await groupLoanRequestService.updateLoanRequest(payload);
            if (response.status >= 200 && response.status <= 300) {
                toast.info(`Contact Details saved successfully!`, {
                    autoClose: 6000,
                    type: toast.TYPE.DEFAULT,
                    hideProgressBar: false,
                });
            } else {
                return false;
            }
            this.setState({ submitting: false, ownUpdate: true });
            this.resetFields();
            // console.log("Contact Payload: ", payload)
            // this.props.reloadItem(payload);
            // onReloadFieldsCB();
        } catch (error) {
            if (error.response.status === 409) {
                toast.error(error.response.data.detail, {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            } else if (error.response.status === 400) {
                toast.error(error.response.data.errors[0], {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            }
            this.setState({ submitting: false, ownUpdate: true });
            return false;
        } finally {
            return true;
        }
    }

    resetFields() {
        this.setState({ validated: false, submitting: false, errors: [], ownUpdate: true });
    }

    render() {
        const { values, handleChange, handleBlur } = this.props;
        const { errors, validated } = this.state;

        let statesInputData: ISelectItems[] = [];
        let countriesInputData: ISelectItems[] = [];
        countries.forEach((country) => {
            countriesInputData.push({ name: country.name, value: country.name });
        });
        countries
            .filter((c) => c.name === String(this.state.country))[0]
            ?.states.forEach((state) => {
                statesInputData.push({ name: state.name, value: state.name });
            });

        let addressTypeInputData: ISelectItems[] = [
            { name: " Current Address", value: " Current Address" },
            { name: "Previous Address", value: "Previous Address" },
        ];

        let propertyStatusInputData: ISelectItems[] = [
            { name: "Owned", value: "Owned" },
            { name: "Rented", value: "Rented" },
            { name: "Others", value: "Others" },
        ];

        return (
            <ContactDetailsForm
                initialValues={{
                    addressType: values.addressType,
                    propertyStatus: values.propertyStatus,
                    address: values.address,
                    city: values.city,
                    state: values.state,
                    country: values.country,
                    phonenumber: values.phonenumber,
                    phoneNumber2: values.phoneNumber2,
                    customerEmail: values.customerEmail,
                    yearsOfStayAtCurrentAddress: values.yearsOfStayAtCurrentAddress,
                    monthsOfStayAtCurrentAddress: values.monthsOfStayAtCurrentAddress,
                    yearsOfStayAtCurrentCity: values.yearsOfStayAtCurrentCity,
                    monthsOfStayAtCurrentCity: values.monthsOfStayAtCurrentCity,
                    twitterHandle: values.twitterHandle,
                    instagramID: values.instagramID,
                    facebookID: values.facebookID,
                    location: values.locationCoordinates
                }}
                FormComponent={({
                    fields: {
                        addressType,
                        propertyStatus,
                        address,
                        city,
                        state,
                        country,
                        phonenumber,
                        phoneNumber2,
                        customerEmail,
                        yearsOfStayAtCurrentAddress,
                        monthsOfStayAtCurrentAddress,
                        yearsOfStayAtCurrentCity,
                        monthsOfStayAtCurrentCity,
                        twitterHandle,
                        instagramID,
                        facebookID,
                        location
                    },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                }) => (
                    <Fragment>
                        <div className="form-row">
                            <div className="col-md-12">
                                <div className="form-row">
                                    {/* <SelectInputComponent
                                        id="addressType"
                                        name="addressType"
                                        divClass={6}
                                        value={addressType}
                                        items={addressTypeInputData}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                    />
                                    <SelectInputComponent
                                        id="propertyStatus"
                                        name="propertyStatus"
                                        divClass={6}
                                        value={propertyStatus}
                                        items={propertyStatusInputData}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                    /> */}
                                    <FormInputComponent
                                        label="Group Contact Address"
                                        id="address"
                                        name="address"
                                        type="text"
                                        placeholder=""
                                        divClass={6}
                                        value={address}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                    />
                                    <FormInputComponent
                                        label="City/Town"
                                        id="city"
                                        name="city"
                                        type="text"
                                        placeholder=""
                                        divClass={6}
                                        value={city}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                    />
                                    <SelectInputComponent
                                        id="country"
                                        name="country"
                                        divClass={6}
                                        value={this.state.country}
                                        items={countriesInputData}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={async (id, value) => {
                                            await onChange(id, value);
                                            this.setState({ country: value, ownUpdate: true });
                                        }}
                                    />
                                    <SelectInputComponent
                                        label="State/Region"
                                        id="state"
                                        name="state"
                                        divClass={6}
                                        value={state}
                                        items={statesInputData}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                    />
                                    {/* <div className="col-md-6"> */}
                                    {/* <label style={{ paddingBottom: "calc(0.115rem + 1px)" }} className="col-form-label s-12">
                                            Length of stay at Current Address
                                        </label>
                                        <div className="form-row">
                                            <FormInputComponent
                                                label="Years"
                                                id="yearsOfStayAtCurrentAddress"
                                                name="yearsOfStayAtCurrentAddress"
                                                type="number"
                                                placeholder=""
                                                divClass={6}
                                                value={yearsOfStayAtCurrentAddress}
                                                required={true}
                                                validated={validated}
                                                errors={errors}
                                                onChange={onChange}
                                            />
                                            <FormInputComponent
                                                label="Months"
                                                id="monthsOfStayAtCurrentAddress"
                                                name="monthsOfStayAtCurrentAddress"
                                                type="number"
                                                placeholder=""
                                                divClass={6}
                                                value={monthsOfStayAtCurrentAddress}
                                                required={true}
                                                validated={validated}
                                                errors={errors}
                                                onChange={onChange}
                                            />
                                        </div> */}
                                    {/* </div> */}
                                    {/* <div className="col-md-6">
                                        <label style={{ paddingBottom: "calc(0.115rem + 1px)" }} className="col-form-label s-12">
                                            Length of stay at Current City
                                        </label>
                                        <div className="form-row">
                                            <FormInputComponent
                                                label="Years"
                                                id="yearsOfStayAtCurrentCity"
                                                name="yearsOfStayAtCurrentCity"
                                                type="number"
                                                placeholder=""
                                                divClass={6}
                                                value={yearsOfStayAtCurrentCity}
                                                required={true}
                                                validated={validated}
                                                errors={errors}
                                                onChange={onChange}
                                            />
                                            <FormInputComponent
                                                label="Months"
                                                id="monthsOfStayAtCurrentCity"
                                                name="monthsOfStayAtCurrentCity"
                                                type="number"
                                                placeholder=""
                                                divClass={6}
                                                value={monthsOfStayAtCurrentCity}
                                                required={true}
                                                validated={validated}
                                                errors={errors}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div> */}
                                    <FormInputComponent
                                        label="Phone Number 1"
                                        id="phonenumber"
                                        name="phonenumber"
                                        type="text"
                                        placeholder=""
                                        divClass={6}
                                        value={phonenumber}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                    />
                                    <FormInputComponent
                                        label="Phone Number 2"
                                        id="phoneNumber2"
                                        name="phoneNumber2"
                                        type="number"
                                        placeholder=""
                                        divClass={6}
                                        value={phoneNumber2}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                    />
                                    <FormInputComponent
                                        label="Email Address"
                                        id="customerEmail"
                                        name="customerEmail"
                                        type="email"
                                        placeholder=""
                                        divClass={6}
                                        value={customerEmail}
                                        required={false}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                    />
                                    {/* <FormInputComponent
                                        label="Facebook ID"
                                        id="facebookID"
                                        name="facebookID"
                                        type="text"
                                        placeholder=""
                                        divClass={6}
                                        value={facebookID}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                    /> */}
                                    {/* <FormInputComponent
                                        id="twitterHandle"
                                        name="twitterHandle"
                                        type="text"
                                        placeholder=""
                                        divClass={6}
                                        value={twitterHandle}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                    /> */}
                                    {/* <FormInputComponent
                                        label="Instagram ID"
                                        id="instagramID"
                                        name="instagramID"
                                        type="text"
                                        placeholder=""
                                        divClass={6}
                                        value={instagramID}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                    /> */}
                                    <FormInputComponent
                                        label="Location Co-ordinates"
                                        id="location"
                                        name="location"
                                        type="text"
                                        placeholder=""
                                        divClass={6}
                                        value={location}
                                        required={false}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </ div>
                        <hr />
                        <div className="float-right">
                            <button
                                type="submit"
                                className="btn btn-sm btn-primary"
                                disabled={this.state.submitting}
                                onClick={(e) => {
                                    onHandleSubmit(
                                        e,
                                        ActionTypes.SAVE,
                                        "Contact Details",
                                        () => {
                                            this.onFormSubmit(
                                                {
                                                    addressType,
                                                    propertyStatus,
                                                    address,
                                                    city,
                                                    state,
                                                    country,
                                                    phonenumber,
                                                    phoneNumber2,
                                                    customerEmail,
                                                    yearsOfStayAtCurrentAddress,
                                                    monthsOfStayAtCurrentAddress,
                                                    yearsOfStayAtCurrentCity,
                                                    monthsOfStayAtCurrentCity,
                                                    twitterHandle,
                                                    instagramID,
                                                    facebookID,
                                                    location
                                                },
                                                onReloadFields
                                            );
                                        }
                                    );
                                }}
                            >
                                {this.state.submitting === false ? (
                                    <React.Fragment>
                                        <i className="fas fa-lg fa-save mr-3" /> Save
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                        Please wait...
                                    </React.Fragment>
                                )}
                            </button>
                        </div>
                    </Fragment>
                )}
            ></ContactDetailsForm >
        );
    }
}

export default GroupContactDetails;