import http from "../httpService";
import { IFilterData } from "../approval/approvalItemService";
import { getCurrentUserSession } from "../auth/authService";

const sweepBaseAddress = process.env.REACT_APP_SWEEP_BASEADDRESS;
export function retrieveById(id: number) {
  var url =
    sweepBaseAddress +
    `ThirdPartyBillingTransaction/RetrieveById/${id}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function filterThirdPartySettlements(
  payload: IFilterData,
  pageSize: number,
  currentPage: number
) {
  var url =
    sweepBaseAddress +
    `ThirdPartyBillingTransaction/FilterThirdPartyBillingTransactions?pageNum=${currentPage}&maxSize=${pageSize}`;

  return http.post(url, payload, {
    url: url,
    data: payload,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export default {
  retrieveById,
  filterThirdPartySettlements
};
