import React, { Fragment, useState } from "react";
import bankService from "../../../../services/_axiosServices/sweep/bank";
import { Loader, Error } from "../../components";

const initialData = {
  cbnCode: "---",
  institutionName: "---",
  shortName: "---",
  sortCode: "---",
  id: "---",
};

export default function Report() {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [data, setData] = useState(initialData);
  const [error, setError] = useState(false);

  const fetchData = async () => {
    if (code.length < 1) {
      setData(initialData);
      return;
    }
    setLoading(true);
    await bankService
      .retrieveBySortCode(code)
      .then((res: any) => {
        // console.log(res);
        setLoading(false);
        setData(res.data);
      })
      .catch((err: any) => {
        setError(true);
        setLoading(false);
        console.log(err.message);
      });
  };

  return (
    <div className="container-fluid relative animatedParent animateOnce">
      <div className="animated fadeInUpShort go">
        <div className="row my-3 mx-2">
          <h3>
            <b>Commercial Banks - Retrieve By Sort Code</b>
          </h3>
        </div>
        <div className="card no-b">
          <div className="card-body">
            <div className="card-title">
              <div className="page">
                <div className="page-inner">
                  <div className="btn-toolbar justify-content-between">
                    <div className="btn-group">
                      <input
                        name="code"
                        onChange={(e) => setCode(e.target.value)}
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Sort Code"
                        required
                      />
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={fetchData}
                      >
                        Find
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive text-nowrap">
                    {loading && <Loader />}

                    {!loading && !error && (
                      <Fragment>
                        <table className="table table-hover table-content table-striped table-bordered table-sm paged-output">
                          <thead>
                            <tr>
                              <th>#</th>
                              {/* <th>id</th> */}
                              <th>CBN Code</th>
                              <th>Institution Name</th>
                              <th>Short Name</th>
                              <th>Sort Code</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {/* <th scope="row">{i + 1}</th> */}
                              <td>{data.id}</td>
                              <td>{data.cbnCode}</td>
                              <td>{data.institutionName}</td>
                              <td>{data.shortName}</td>
                              <td>{data.sortCode}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Fragment>
                    )}

                    {error && !loading && <Error />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
