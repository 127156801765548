import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import approvalValidationService from "../../../services/approval/approvalValidationService";
import FormInputComponent from "../../../components/formInputComponent";

interface AddNewGroupModalFields {
  minimumAmount: string;
  maximumAmount: string;
}

class AddNewGroupModalForm extends BaseFormComponent<AddNewGroupModalFields> {}

export interface IAddNewGroupModalProps {
  showAddNewGroupModal: boolean;
  toggleAddNewGroupModal: any;
}

export interface IAddNewGroupModalState {}

class AddNewGroupModal extends React.Component<
  IAddNewGroupModalProps,
  IBaseFormState & IAddNewGroupModalState
> {
  constructor(props: IAddNewGroupModalProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
    };
  }

  onFormSubmit(fields: AddNewGroupModalFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors: approvalValidationService.validateAddNewGroupForm(fields),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (fields: AddNewGroupModalFields, onReloadFieldsCB: any) => {
    this.props.toggleAddNewGroupModal(fields, true);
    onReloadFieldsCB();
  };

  //   validateField = (fieldName: string, type: string, validated: boolean) => {
  //     let defaultClass = "";
  //     if (type === "select") {
  //       defaultClass =
  //         "custom-select select2 mr-sm-2 form-control r-0 light s-12";
  //       if (validated === false) return defaultClass;
  //     } else {
  //       defaultClass = "form-control r-0 light s-12";
  //       if (validated === false) return defaultClass;
  //     }
  //     return !validated
  //       ? defaultClass
  //       : this.state.errors[fieldName]
  //       ? `${defaultClass} is-invalid`
  //       : `${defaultClass} is-valid`;
  //   };

  render() {
    var { showAddNewGroupModal, toggleAddNewGroupModal } = this.props;
    var { errors, submitting, validated } = this.state;
    return (
      <Modal
        size="lg"
        backdrop="static"
        show={showAddNewGroupModal}
        onHide={toggleAddNewGroupModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>
              <i className="fas fa-tasks mr-3"></i>
              Add New Financial Group
            </h5>
          </Modal.Title>
        </Modal.Header>
        <AddNewGroupModalForm
          initialValues={{ maximumAmount: "", minimumAmount: "" }}
          FormComponent={({
            fields: { minimumAmount, maximumAmount },
            onChange,
            onReloadFields,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="form-group row">
                  <FormInputComponent
                    id="minimumAmount"
                    type="number"
                    name="minimumAmount"
                    placeholder=""
                    divClass={6}
                    value={minimumAmount}
                    required={true}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                  <FormInputComponent
                    id="maximumAmount"
                    type="number"
                    name="maximumAmount"
                    placeholder=""
                    divClass={6}
                    value={maximumAmount}
                    required={true}
                    validated={validated}
                    errors={errors}
                    onChange={onChange}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="primary"
                  onClick={(e) => {
                    this.onFormSubmit(
                      { minimumAmount, maximumAmount },
                      onReloadFields
                    );
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fa fa-plus mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleAddNewGroupModal({}, false)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></AddNewGroupModalForm>
      </Modal>
    );
  }
}

export default AddNewGroupModal;
