import React, { useContext } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../../components/BaseFormComponent";
import { getCurrentUserSession } from "../../../../services/auth/authService";
import FormInputComponent from "../../../../components/formInputComponent";
import { ActionTypes } from "../../../../enums/actionTypes";
import creditAssessmentValidationService from "../../../../services/creditAssessment/creditAssessmentValidationService";
import creditQualificationRuleService from "../../../../services/creditAssessment/creditQualificationRuleService";
import { decodeToken } from "react-jwt";
import activityTrailService from "../../../../services/auditTrail/activityTrailService";
import { Accordion, AccordionContext, Card, useAccordionToggle } from "react-bootstrap";
import DetailItem from "../../../../components/detailItem";
import { splitAtUpper } from "../../../../services/utility/textFormatService";
import ModifyIndicatorParams from "./modifyIndicatorParams";
import scoringCardService from "../../../../services/creditAssessment/scoringCardService";
import SelectInputComponent, { ISelectItems } from "../../../../components/formSelectComponent";

interface AddCreditQualificationRuleAppzoneFields {
  name: string;
  minimumScore: string;
  scoreCard: string;
  // maxPercentageLoanAmountSalary: string;
  // priorityLevel: string;
  // loanType: string;
}

class AddCreditQualificationRuleAppzoneForm extends BaseFormComponent<AddCreditQualificationRuleAppzoneFields> { }

interface IAddCreditQualificationRuleAppzoneProps { }
interface IAddCreditQualificationRuleAppzoneState {
  // applyPreQualificationAssessment: boolean;
  loanOriginationChannelIds: any;
  indicatorIds: any;
  // requirePositiveRepaymentPrediction: boolean;
  creditQualificationIndicators: any;
  loanOriginationChannels: any;

  fetchingRequiredItems: boolean;
  fetchingExistingRules: boolean;

  showSetIndicatorParamsModal: boolean;
  indicatorItem: any;
  existingCreditQualificationRules: any;
  scoreCardsList: any;
}

export function ContextAwareToggle({ isHeader, eventKey, header }) {
  const currentEventKey = useContext(AccordionContext);

  const toggleOnClick = useAccordionToggle(eventKey);

  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";

  return (
    <>
      {isHeader ? (
        <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
          <b>{header}</b>
          <i
            className={angleType}
            aria-hidden="true"
            style={{ float: "right" }}
          ></i>
        </Card.Header>
      ) : (
        <div onClick={toggleOnClick} style={{ cursor: "pointer", display: "flex", justifyContent: "space-between" }}>
          <div>
            <b>{header}</b>
          </div>
          <div style={{ float: "right", paddingRight: "3px", paddingTop: "1px" }}>
            <i
              className={angleType}
              aria-hidden="true"
            ></i>
          </div>
        </div>
      )}
    </>
  );
}

class AddCreditQualificationRuleAppzone extends React.Component<
  IAddCreditQualificationRuleAppzoneProps,
  IBaseFormState & IAddCreditQualificationRuleAppzoneState
> {
  _isMounted = false;
  constructor(props: IAddCreditQualificationRuleAppzoneProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      fetchingRequiredItems: true,
      creditQualificationIndicators: [],
      loanOriginationChannels: [],
      indicatorIds: [],
      loanOriginationChannelIds: [],
      showSetIndicatorParamsModal: false,
      indicatorItem: {},
      scoreCardsList: [],
      existingCreditQualificationRules: [],
      fetchingExistingRules: false
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    const { errors } = this.state;
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    try {
      // const creditQualificationIndicators =
      //   await creditQualificationRuleService.retrieveCreditQualificationIndicators();

      // const allLoanOriginationChannels =
      //   await creditQualificationRuleService.retrieveLoanOriginationChannels();

      // var loanOriginationChannels = allLoanOriginationChannels.data.filter((value, index, self) =>
      //   index === self.findIndex((t) => (
      //     t.name === value.name
      //   )))
      const response = await Promise.allSettled([
        creditQualificationRuleService.retrieveCreditQualificationIndicators(),
        creditQualificationRuleService.retrieveCreditQualificationRulesByInstitution(decodedToken?.["InstitutionCode"] || ""),
        scoringCardService.retrieveScoreCardByInstitutionCode(decodedToken?.["InstitutionCode"] || "")
      ])

      const creditQualificationIndicators = response[0].status == "fulfilled" ? response[0].value.data : [];
      const CreditQualificationRules = response[1].status == "fulfilled" ? response[1].value.data : [];
      const scoreCards = response[2].status == "fulfilled" ? response[2].value.data : [];

      console.log(creditQualificationIndicators)

      creditQualificationIndicators.filter(indicator => indicator.indicatorName === "RequireGuarantyFromGuarantorRule")[0].creditQualificationIndicatorParams = [
        {
          "paramName": "RequireGuarantyFromGuarantorRule.GuarantorCount",
          "paramType": "Numeric"
        },
        {
          "paramName": "RequireGuarantyFromGuarantorRule.PercentageGuaranty",
          "paramType": "Numeric"
        },
        {
          "paramName": "RequireGuarantyFromGuarantorRule.ShouldVerify",
          "paramType": "Boolean"
        }
      ];
      if (this._isMounted) {
        this.setState({
          existingCreditQualificationRules: CreditQualificationRules,
          scoreCardsList: scoreCards,
          // loanProducts: loanProducts,
          creditQualificationIndicators: creditQualificationIndicators,
          // loanOriginationChannels: loanOriginationChannels,
          fetchingRequiredItems: false,
        });
      }
    } catch (ex) {
      // console.log("caught exception", ex);
      if (ex.response && ex.response.status === 404) {
        // console.log(ex.response);
        errors.response = "Internal server error.";
        // console.log(errors);
        this.setState({ errors });
      }
    }
  }

  onFormSubmit(
    fields: AddCreditQualificationRuleAppzoneFields,
    onReloadFieldsCB: any
  ): boolean {
    try {
      this.setState(
        {
          errors:
            creditAssessmentValidationService.validateAddCreditQualificationRuleAppzoneForm(
              fields
            ),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // return false;
      }
      return false;
    }
  }

  async submit(
    fields: AddCreditQualificationRuleAppzoneFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      // console.log("starting submission...");
      this.setState({ submitting: true });

      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const { indicatorIds, creditQualificationIndicators, loanOriginationChannelIds } = this.state;

      const payload: any = {};
      payload.name = fields.name;
      payload.minimumScore = Number(fields.minimumScore);
      payload.scoreCardId = fields.scoreCard;
      // payload.loanOriginationChannelIds = loanOriginationChannelIds;
      // payload.indicatorIds = indicatorIds;
      payload.indicatorDataCollection = indicatorIds.map(indicatorId => {
        let paramData = creditQualificationIndicators.filter(indicator => indicator.id === indicatorId)[0].creditQualificationIndicatorParams;
        paramData = paramData.map(data => ({
          paramName: data.paramName,
          paramValue: data.paramValue,
        }))
        return {
          indicatorId: indicatorId,
          paramData: paramData
        }
      });
      // payload.priorityLevel = Number(fields.priorityLevel);
      payload.institutionCode = decodedToken["InstitutionCode"];
      // payload.loanProductCategory = "Blackbox";

      // console.log("Payload :: ", payload)
      const response =
        await creditQualificationRuleService.saveCreditQualificationRule(
          payload
        );
      if (response.status >= 200 && response.status <= 300) {
        activityTrailService.saveActivityTrail({
          actionType: "Add Appzone Credit Qualification Rule",
          description: `Added new Appzone Credit Qualification Rule ${fields.name}`,
        });
        toast.info(`Credit Qualification Rule created successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false });
      onReloadFieldsCB();
    } catch (error) {
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      // console.log("error: ", error.response.data);
      return false;
    } finally {
      this.setState({ submitting: false });
      return true;
    }
  }

  handleOnChangeLoanOriginationChannel = (item) => {
    // console.log("clicked loan origination channel: ", item);
    const loanOriginationChannelIds = [...this.state.loanOriginationChannelIds];

    if (loanOriginationChannelIds.indexOf(item) !== -1) {
      loanOriginationChannelIds.splice(
        loanOriginationChannelIds.indexOf(item),
        1
      );
    } else {
      loanOriginationChannelIds.push(item);
    }
    // console.log("loan origination channel: ", loanOriginationChannelIds);
    this.setState({ loanOriginationChannelIds });
  };

  // loadCreditQualRule = async (id, value) => {
  //   this.setState({ fetchingExistingRules: true });
  //   const decodedToken = decodeToken(getCurrentUserSession().token || "");
  //   try {
  //     var response = await creditQualificationRuleService.retrieveCreditQualificationRulesByInstitution(decodedToken?.["InstitutionCode"]||"");
  //     this.setState({
  //       existingCreditQualificationRules: response.data,
  //     });
  //   } catch (error) {
  //     // console.log(error);
  //   }
  //   this.setState({ fetchingExistingRules: false });
  // };

  handleOnCreditQualificationRuleChange = (item) => {
    // console.log("clicked Credit QUalification Rule Indicator: ", item);
    const indicatorIds = [...this.state.indicatorIds];
    if (indicatorIds.indexOf(item) !== -1) {
      indicatorIds.splice(indicatorIds.indexOf(item), 1);
      this.setState({ indicatorIds });
    } else {
      const creditQualificationIndicators = [...this.state.creditQualificationIndicators];
      if (creditQualificationIndicators.filter(indicator => indicator.id === item)[0].creditQualificationIndicatorParams.length === 0) {
        indicatorIds.push(item);
        this.setState({ indicatorIds });
      } else {
        this.setState({ indicatorItem: item, showSetIndicatorParamsModal: true });
      }
    }
    // console.log("Credit QUalification Rule Indicators: ", indicatorIds);
  };

  handleOnIndicatorParamsSave = (toSave: boolean, indicatorId, payload) => {
    if (toSave) {
      // console.log("payload :: ", payload);
      const indicatorIds = [...this.state.indicatorIds];
      const creditQualificationIndicators = [...this.state.creditQualificationIndicators];
      indicatorIds.push(indicatorId);
      const credQualIndicator = creditQualificationIndicators.filter(indicator => indicator.id === indicatorId)[0];
      credQualIndicator.creditQualificationIndicatorParams = payload
      // console.log("Credit QUalification Rule Indicators: ", indicatorIds);
      this.setState({ indicatorIds, creditQualificationIndicators, showSetIndicatorParamsModal: false });
    } else {
      this.setState({ showSetIndicatorParamsModal: false });
    }
  };

  render() {
    const {
      errors,
      //   workFlows,
      validated,
      // loanProducts,
      fetchingRequiredItems,
      loanOriginationChannelIds,
      loanOriginationChannels,
      creditQualificationIndicators,
      indicatorItem,
      scoreCardsList,
      existingCreditQualificationRules,
      fetchingExistingRules
    } = this.state;

    // let loanTypeInputData: ISelectItems[] = Object.keys(LoanType)
    //   .filter((value) => isNaN(Number(value)) === false)
    //   .map((key) => ({
    //     name: LoanType[key],
    //     value: LoanType[key],
    //   }));

    let scoreCardItems: ISelectItems[] = scoreCardsList?.map((item) => ({
      name: item.name,
      value: item.id,
    }));
    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add Credit Qualification Rule</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddCreditQualificationRuleAppzoneForm
                  initialValues={{
                    name: "",
                    minimumScore: "",
                    scoreCard: ""
                    // priorityLevel: "",
                  }}
                  FormComponent={({
                    fields: { scoreCard, name, minimumScore },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <h5 className="card-title">Standard Parameters</h5>
                          <div className="form-row">
                            <div className="col-md-12">
                              <div className="form-row">
                                <FormInputComponent
                                  id="name"
                                  type="text"
                                  name={"name"}
                                  placeholder=""
                                  divClass={6}
                                  value={name}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="minimumScore"
                                  type="number"
                                  name={"minimumScore"}
                                  placeholder=""
                                  divClass={6}
                                  value={minimumScore}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <SelectInputComponent
                                  id="scoreCard"
                                  name="scoreCard"
                                  divClass={6}
                                  value={scoreCard}
                                  items={scoreCardItems}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                {/* <FormInputComponent
                                  id="priorityLevel"
                                  type="number"
                                  name={"priorityLevel"}
                                  placeholder=""
                                  divClass={6}
                                  value={priorityLevel}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                /> */}
                              </div>
                              {/* <div className="form-row"> */}
                              {/* <FormInputComponent
                                  id="maxPercentageLoanAmountSalary"
                                  type="number"
                                  name={"Max. (%) Amount Of Loan Salary"}
                                  placeholder=""
                                  divClass={6}
                                  value={maxPercentageLoanAmountSalary}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                /> */}
                              {/* <div className="form-group col-md-6 m-0">
                                  <label
                                    htmlFor="loanOriginationChannels"
                                    className="col-form-label s-12"
                                  >
                                    Loan Origination Channels
                                  </label>
                                  <br />
                                  {fetchingRequiredItems ? (
                                    <i className="fas fa-spinner fa-spin mr-2" />
                                  ) : (
                                    loanOriginationChannels.map((channel) => (
                                      <div
                                        key={channel.id}
                                        className="custom-control custom-checkbox custom-control-inline"
                                      >
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id={channel.id}
                                          checked={loanOriginationChannelIds.includes(
                                            channel.id
                                          )}
                                          onChange={() =>
                                            // console.log("Clicked this guy")
                                            this.handleOnChangeLoanOriginationChannel(
                                              channel.id
                                            )
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={channel.id}
                                        >
                                          {channel.name}
                                        </label>
                                      </div>
                                    ))
                                  )}
                                </div> */}

                              {/* <SelectInputComponent
                                  id="priorityLevel"
                                  name="priorityLevel"
                                  divClass={6}
                                  value={priorityLevel}
                                  items={availablePriorityLevels}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                  disabledOption={(item) =>
                                    existingCreditQualificationRules
                                      .map((x) => x.priorityLevel)
                                      .includes(item)
                                  }
                                /> */}
                              {/* </div> */}
                              <div className="form-row">
                                {/* <SelectInputComponent
                                  id="loanType"
                                  name="loanType"
                                  divClass={6}
                                  value={loanType}
                                  items={loanTypeInputData}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                /> */}
                              </div>
                            </div>
                          </div>

                          <hr />
                          <h5 className="card-title">Knock-Off Basis</h5>
                          <div className="form-row">
                            <div className="col-md-12">
                              <div className="form-row">
                                <div className="col-md-12">
                                  <label
                                    htmlFor="indicatorIds"
                                    className="control-label textbox-label"
                                  >
                                    Credit Qualification Indicators
                                  </label>
                                  <div
                                    className="block-example border"
                                    style={{
                                      width: "100%",
                                      maxWidth: "100%",
                                      overflowX: "hidden",
                                      height: "300px",
                                      overflowY: "scroll",
                                    }}
                                  >
                                    <React.Fragment>
                                      {creditQualificationIndicators.length ===
                                        0 ? (
                                        <div
                                          style={{
                                            backgroundColor: "#F3F3F3",
                                          }}
                                        >
                                          <p className="text-center">
                                            no credit qualification rule
                                            indicators
                                          </p>
                                        </div>
                                      ) : (
                                        <React.Fragment>
                                          {creditQualificationIndicators.map(
                                            (item) => (
                                              item.creditQualificationIndicatorParams?.length > 0 ? (
                                                <Accordion key={creditQualificationIndicators.indexOf(item)}>
                                                  <div style={{ border: '2px #84d4fc solid', borderRadius: '4px', backgroundColor: '#e4f4fc', marginTop: '5px', inlineSize: "100%" }}>
                                                    <ContextAwareToggle
                                                      eventKey={`0`}
                                                      isHeader={false}
                                                      header={
                                                        <div
                                                          className="custom-control custom-checkbox"
                                                          style={{ display: 'inline-block' }}
                                                        >
                                                          <input
                                                            id={item.id}
                                                            name={item.id}
                                                            className="custom-control-input"
                                                            type="checkbox"
                                                            onChange={() =>
                                                              this.handleOnCreditQualificationRuleChange(
                                                                item.id
                                                              )
                                                            }
                                                            checked={
                                                              _.isEmpty(
                                                                this.state.indicatorIds.filter(
                                                                  (x) => x === item.id
                                                                )
                                                              )
                                                                ? false
                                                                : true
                                                            }
                                                          />
                                                          <label
                                                            className="custom-control-label"
                                                            htmlFor={item.id}
                                                          >
                                                            {item.description}
                                                          </label>
                                                        </div>
                                                      }
                                                    ></ContextAwareToggle>
                                                  </div>
                                                  <Accordion.Collapse eventKey={`0`}>
                                                    <Card.Body style={{ padding: "5px", backgroundColor: '#e4f4fc' }}>
                                                      <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                        <div className="row">
                                                          <div className="col-md-1">
                                                          </div>
                                                          <div className="col-md-8">
                                                            {item.creditQualificationIndicatorParams.map(indicatorParam => (
                                                              <dl className="row" style={{ marginBottom: "0px" }} key={indicatorParam.paramName}>
                                                                <DetailItem
                                                                  label={splitAtUpper(indicatorParam.paramName.split(".")[1])}
                                                                  value={indicatorParam.paramValue}
                                                                  labelSize={5}
                                                                  valueSize={7}
                                                                />
                                                              </dl>
                                                            ))}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </Card.Body>
                                                  </Accordion.Collapse>
                                                </Accordion>
                                              ) : (
                                                <div key={creditQualificationIndicators.indexOf(item)} style={{ border: '2px #84d4fc solid', borderRadius: '4px', backgroundColor: '#e4f4fc', marginTop: '5px' }}>
                                                  <div
                                                    className="custom-control custom-checkbox"
                                                    key={creditQualificationIndicators.indexOf(
                                                      item
                                                    )}
                                                  >
                                                    <input
                                                      id={item.id}
                                                      name={item.id}
                                                      className="custom-control-input"
                                                      type="checkbox"
                                                      onChange={() =>
                                                        this.handleOnCreditQualificationRuleChange(
                                                          item.id
                                                        )
                                                      }
                                                      checked={
                                                        _.isEmpty(
                                                          this.state.indicatorIds.filter(
                                                            (x) => x === item.id
                                                          )
                                                        )
                                                          ? false
                                                          : true
                                                      }
                                                    />
                                                    <label
                                                      className="custom-control-label"
                                                      htmlFor={item.id}
                                                    >
                                                      {item.description}
                                                    </label>
                                                  </div>
                                                </div>
                                              )

                                              // creditQualificationIndicatorParams: null
                                              // dateCreated: "2022-03-16T18:38:07.3608746"
                                              // description: "Customer does not have an active loan with any other bank"
                                              // id: "c097a3c1-26b2-4de1-8053-9961153d3719"
                                              // indicatorName: "CustomerDoesNotHaveActiveLoansAnywhereRule"
                                            )
                                          )}
                                        </React.Fragment>
                                      )}
                                    </React.Fragment>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <label
                                    htmlFor="existingCreditQualificationRules"
                                    className="control-label textbox-label"
                                  >
                                    {fetchingExistingRules ? (
                                      <i className="fas fa-spinner fa-spin mr-2" />
                                    ) : (
                                      ""
                                    )}
                                    Existing Credit Qualification Rules
                                  </label>
                                  <div
                                    className="table-responsive text-nowrap"
                                    style={{
                                      height: "150px",
                                      overflowY: "scroll",
                                    }}
                                  >
                                    <table className="table table-hover table-content table-sm table-striped mb-0">
                                      <thead>
                                        <tr>
                                          <th
                                            scope="col"
                                            style={{
                                              width: "5px",
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                            }}
                                          >
                                            <strong>#</strong>
                                          </th>
                                          <th
                                            scope="col"
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                            }}
                                          >
                                            <strong>Name</strong>
                                          </th>
                                          <th
                                            scope="col"
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                            }}
                                          >
                                            <strong>Minimum Score</strong>
                                          </th>
                                          {/* <th
                                            scope="col"
                                            style={{
                                              // width: "5px",
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                            }}
                                          ></th> */}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {existingCreditQualificationRules.length ===
                                          0 ? (
                                          <React.Fragment>
                                            <tr>
                                              <td
                                                colSpan={4}
                                                className="font-weight-lighter font-italic text-truncate"
                                                style={{
                                                  textAlign: "center",
                                                  color: "#FE825E",
                                                }}
                                              >
                                                There are no Existing Credit
                                                Qualification Rules
                                              </td>
                                            </tr>
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            {existingCreditQualificationRules?.map(
                                              (item) => (
                                                <tr
                                                  key={
                                                    item.creditQualificationIndicatorId
                                                  }
                                                >
                                                  <td
                                                    style={{
                                                      width: "5px",
                                                      paddingTop: "0.2rem",
                                                      paddingBottom: "0.2rem",
                                                      fontSize: "small",
                                                    }}
                                                  >
                                                    {existingCreditQualificationRules?.indexOf(
                                                      item
                                                    ) + 1}
                                                  </td>
                                                  <td
                                                    style={{
                                                      paddingTop: "0.2rem",
                                                      paddingBottom: "0.2rem",
                                                      fontSize: "small",
                                                    }}
                                                  >
                                                    {item.name}
                                                  </td>
                                                  <td
                                                    style={{
                                                      paddingTop: "0.2rem",
                                                      paddingBottom: "0.2rem",
                                                      fontSize: "small",
                                                    }}
                                                  >
                                                    {item.minimumScore}
                                                  </td>
                                                  {/* <td
                                                    style={{
                                                      paddingTop: "0.2rem",
                                                      paddingBottom: "0.2rem",
                                                      fontSize: "small",
                                                    }}
                                                  >
                                                    <i
                                                      className="far fa-eye ml-5 fa-lg"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      // onClick={() =>
                                                      //   this.handleOnAddWorkFlowItem(item)
                                                      // }
                                                    ></i>
                                                  </td> */}
                                                </tr>
                                              )
                                            )}
                                          </React.Fragment>
                                        )}
                                      </tbody>
                                    </table>
                                    <div
                                      // className="invalid-feedback"
                                      style={{
                                        visibility: errors.workFlowSteps,
                                        fontSize: "80%",
                                        color: "#dc3545",
                                      }}
                                    >
                                      {errors.workFlowSteps}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <ModifyIndicatorParams
                            showPatchModal={this.state.showSetIndicatorParamsModal}
                            togglePatchModal={(toSave: boolean, indicatorId: string, payload: any) => {
                              this.handleOnIndicatorParamsSave(toSave, indicatorId, payload);
                            }}
                            item={indicatorItem}
                            allIndicators={creditQualificationIndicators}
                          />
                        </div>
                        <hr />
                        <div className="card-body">
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                            disabled={this.state.submitting}
                            onClick={(e) => {
                              onHandleSubmit(
                                e,
                                ActionTypes.SAVE,
                                "Credit Qualification Rule",
                                () => {
                                  this.onFormSubmit(
                                    {
                                      minimumScore,
                                      scoreCard,
                                      name,
                                    },
                                    onReloadFields
                                  );
                                }
                              );
                            }}
                          >
                            {this.state.submitting === false ? (
                              <React.Fragment>
                                <i className="fas fa-lg fa-save mr-3" /> Save
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                Please wait...
                              </React.Fragment>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                ></AddCreditQualificationRuleAppzoneForm>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddCreditQualificationRuleAppzone;
