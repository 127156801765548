import React, { Fragment } from "react";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import mandateServiceTypeService from "../../../services/mandate/mandateServiceTypeService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { TransactionAmountSettings } from "../../../enums/recovaMandate/transactionAmountSettings";
import { CummulativeDeductionConfig } from "../../../enums/recovaMandate/cummulativeDeductionConfig";
import { PermissibleDeductionConfig } from "../../../enums/recovaMandate/permissibleDeductionConfig";
import { MandateDurationConfig } from "../../../enums/recovaMandate/mandateDuration";
import mandateValidationService from "../../../services/mandate/mandateValidationService";
import usersService from "../../../services/auth/usersService";
import { getCurrentUserSession } from "../../../services/auth/authService";
import DetailItem from "../../../components/detailItem";
import { ActionTypes } from "../../../enums/actionTypes";
import { decodeToken } from "react-jwt";

interface EditMandateServiceTypeFields {
  name: string;
  description: string;
  status: string;
  transactionAmount: number;
  permissibleDeductionCount: number;
  cumulativeDeductionCount: number;
  mandateDuration: number;
}

class EditMandateServiceTypeForm extends BaseFormComponent<EditMandateServiceTypeFields> { }

interface IEditMandateServiceTypeProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}
interface IEditMandateServiceTypeState {
  transactionAmountSettings: TransactionAmountSettings;
  cummulativeDeductionConfig: CummulativeDeductionConfig;
  permissibleDeductionConfig: PermissibleDeductionConfig;
  mandateDurationConfig: MandateDurationConfig;
  ownUpdate: boolean;
}

class EditMandateServiceType extends React.Component<
  IEditMandateServiceTypeProps,
  IBaseFormState & IEditMandateServiceTypeState
> {
  constructor(props: IEditMandateServiceTypeProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      transactionAmountSettings: TransactionAmountSettings.Fixed,
      cummulativeDeductionConfig: CummulativeDeductionConfig.Fixed,
      permissibleDeductionConfig: PermissibleDeductionConfig.Limited,
      mandateDurationConfig: MandateDurationConfig.Limited,
      ownUpdate: false,
    };
  }

  static getDerivedStateFromProps(
    nextProps: IEditMandateServiceTypeProps,
    prevState
  ) {
    // console.log("test 0", nextProps.item);
    // console.log("test 1", nextProps.item.TransactionAmountSettings);
    // console.log(
    //   "test 2",
    //   TransactionAmountSettings[nextProps.item.TransactionAmountSettings]
    // );
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    return {
      transactionAmountSettings:
        TransactionAmountSettings[nextProps.item.transactionAmountCategory],
      cummulativeDeductionConfig:
        CummulativeDeductionConfig[nextProps.item.cumulativeDeductionConfig],
      permissibleDeductionConfig:
        PermissibleDeductionConfig[nextProps.item.permissibleDeductionConfig],
      mandateDurationConfig:
        MandateDurationConfig[nextProps.item.mandateDurationConfig],
    };
  }

  onFormSubmit(
    fields: EditMandateServiceTypeFields,
    onReloadFieldsCB: any
  ): boolean {
    try {
      this.setState(
        {
          errors:
            mandateValidationService.validateEditMandateServiceTypeForm(fields),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (
    fields: EditMandateServiceTypeFields,
    onReloadFieldsCB: any
  ) => {
    try {
      this.setState({ submitting: true });
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const payload = this.props.item;
      // console.log("For checks::", payload);
      payload.Id = payload.id;
      payload.Initiator = (
        await usersService.retrieveUserById(decodedToken?.["UserId"])
      ).data.username;
      payload.name = fields.name;
      payload.description = fields.description;
      payload.transactionAmount = fields.transactionAmount;
      payload.permissibleDeductionCount = fields.permissibleDeductionCount;
      payload.cumulativeDeductionCount = fields.cumulativeDeductionCount;
      payload.mandateDuration = fields.mandateDuration;
      payload.permissibleDeductionConfig =
        PermissibleDeductionConfig[this.state.permissibleDeductionConfig];
      payload.transactionAmountCategory =
        TransactionAmountSettings[this.state.transactionAmountSettings];
      payload.cumulativeDeductionConfig =
        CummulativeDeductionConfig[this.state.cummulativeDeductionConfig];
      payload.mandateDurationConfig =
        MandateDurationConfig[this.state.mandateDurationConfig];

      const response = await mandateServiceTypeService.updateMandateServiceType(
        payload,
        payload.Id
      );
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit MandateServiceType",
          description: `Edited details for Mandate Service Type ${fields.name}`,
        });

        if (response.data.isLoggedForApproval === true) {
          toast.warn("Action has been successfully logged for Approval!", {
            autoClose: 6000,
            type: toast.TYPE.WARNING,
            position: "top-center",
            hideProgressBar: false,
          });
        } else {
          toast.info(
            `Mandate Service Type ${fields.name} Edited successfully! `,
            {
              autoClose: 6000,
              type: toast.TYPE.DEFAULT,
              hideProgressBar: false,
            }
          );
        }
      }
      onReloadFieldsCB();
      this.setState({ submitting: false }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      // console.log("submission error: ", error);
      // this.setState({
      //   errors: "Invalid Username or Password",
      // });
      this.setState({ submitting: false });
    }
  };

  handleOnSwitchUpdate = (name, { trueValue, falseValue }) => {
    const { item } = this.props;

    switch (name) {
      case "transactionAmountSettings":
        let transactionAmountSettings = this.state.transactionAmountSettings;
        transactionAmountSettings =
          transactionAmountSettings === trueValue ? falseValue : trueValue;
        // if (transactionAmountSettings === TransactionAmountSettings.Fixed) {
        //   data.transactionAmount = item.transactionAmount;
        // } else {
        //   data.transactionAmount = 0;
        // }
        // data.transactionAmount = 0;
        this.setState({ transactionAmountSettings, ownUpdate: true });
        break;
      case "permissibleDeductionConfig":
        let permissibleDeductionConfig = this.state.permissibleDeductionConfig;
        permissibleDeductionConfig =
          permissibleDeductionConfig === trueValue ? falseValue : trueValue;
        // if (
        //   data.permissibleDeductionConfig === PermissibleDeductionConfig.Limited
        // ) {
        //   data.permissibleDeductionCount = item.permissibleDeductionCount;
        // } else {
        //   data.permissibleDeductionCount = 0;
        // }
        // data.permissibleDeductionCount = 0;
        this.setState({ permissibleDeductionConfig, ownUpdate: true });
        break;
      case "mandateDurationConfig":
        let mandateDurationConfig = this.state.mandateDurationConfig;
        mandateDurationConfig =
          mandateDurationConfig === trueValue ? falseValue : trueValue;
        // if (data.mandateDurationConfig === MandateDurationConfig.Limited) {
        //   data.mandateDuration = item.mandateDuration;
        // } else {
        //   data.mandateDuration = 0;
        // }
        this.setState({ mandateDurationConfig, ownUpdate: true });
        break;
      case "cummulativeDeductionConfig":
        let cummulativeDeductionConfig = this.state.cummulativeDeductionConfig;
        cummulativeDeductionConfig =
          cummulativeDeductionConfig === trueValue ? falseValue : trueValue;
        // if (
        //   data.cummulativeDeductionConfig === CummulativeDeductionConfig.Fixed
        // ) {
        //   data.cumulativeDeductionCount = item.cumulativeDeductionCount;
        // } else {
        //   data.cumulativeDeductionCount = 0;
        // }
        this.setState({ cummulativeDeductionConfig, ownUpdate: true });
        break;
      default:
        break;
    }
  };

  validateField = (fieldName: string, type: string, validated: boolean) => {
    let defaultClass = "";
    if (type === "select") {
      defaultClass =
        "custom-select select2 mr-sm-2 form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    } else {
      defaultClass = "form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    }
    return !validated
      ? defaultClass
      : this.state.errors[fieldName]
        ? `${defaultClass} is-invalid`
        : `${defaultClass} is-valid`;
  };

  render() {
    const { item, showEditModal, toggleEditModal } = this.props;
    const {
      validated,
      submitting,
      errors,
      permissibleDeductionConfig,
      transactionAmountSettings,
      cummulativeDeductionConfig,
      mandateDurationConfig,
    } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-gopuram fa-sm cyan-text mr-3" />
              Edit Mandate Service Type -{" "}
              <span className="font-weight-bold">{item.name}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>

        <EditMandateServiceTypeForm
          initialValues={{
            name: item.name,
            description: item.description,
            status: item.status,
            transactionAmount: item.transactionAmount,
            cumulativeDeductionCount: item.cumulativeDeductionCount,
            permissibleDeductionCount: item.permissibleDeductionCount,
            mandateDuration: item.mandateDuration,
          }}
          FormComponent={({
            fields: {
              name,
              description,
              mandateDuration,
              permissibleDeductionCount,
              cumulativeDeductionCount,
              transactionAmount,
              status,
            },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    <h6
                      className="card-title float-left"
                      style={{ marginBottom: "0px" }}
                    >
                      General Information
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-7">
                        <div className="form-group row">
                          <label
                            htmlFor="name"
                            className="col-md-4 col-form-label"
                          >
                            Name:
                          </label>
                          <div className="col-md-8">
                            <input
                              autoFocus
                              type="text"
                              className={this.validateField(
                                "name",
                                "text",
                                validated
                              )}
                              id="name"
                              name="name"
                              value={name}
                              onChange={(e) =>
                                onChange("name", e.currentTarget.value)
                              }
                            />
                            <div
                              className="invalid-feedback"
                              style={{ visibility: errors.name }}
                            >
                              {errors.name}
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="transactionAmount"
                            className="col-md-4 col-form-label"
                          >
                            Transaction Amount:
                          </label>
                          <div className="col-md-8">
                            <input
                              type="number"
                              className={this.validateField(
                                "transactionAmount",
                                "number",
                                validated
                              )}
                              id="transactionAmount"
                              name="transactionAmount"
                              value={
                                transactionAmountSettings ===
                                  TransactionAmountSettings.Fixed
                                  ? 0
                                  : transactionAmount
                              }
                              onChange={(e) =>
                                onChange(
                                  "transactionAmount",
                                  Number(e.currentTarget.value)
                                )
                              }
                              disabled={
                                transactionAmountSettings ===
                                TransactionAmountSettings.Fixed
                              }
                            />
                            <div
                              className="invalid-feedback"
                              style={{ visibility: errors.transactionAmount }}
                            >
                              {errors.transactionAmount}
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="cumulativeDeductionCount"
                            className="col-md-4 col-form-label"
                          >
                            Cumulative Deduction Count:
                          </label>
                          <div className="col-md-8">
                            <input
                              type="number"
                              className={this.validateField(
                                "cumulativeDeductionCount",
                                "number",
                                validated
                              )}
                              id="cumulativeDeductionCount"
                              name="cumulativeDeductionCount"
                              value={
                                cummulativeDeductionConfig ===
                                  CummulativeDeductionConfig.Fixed
                                  ? 0
                                  : cumulativeDeductionCount
                              }
                              onChange={(e) =>
                                onChange(
                                  "cumulativeDeductionCount",
                                  Number(e.currentTarget.value)
                                )
                              }
                              disabled={
                                cummulativeDeductionConfig ===
                                CummulativeDeductionConfig.Fixed
                              }
                            />
                            <div
                              className="invalid-feedback"
                              style={{
                                visibility: errors.cumulativeDeductionCount,
                              }}
                            >
                              {errors.cumulativeDeductionCount}
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="permissibleDeductionCount"
                            className="col-md-4 col-form-label"
                          >
                            Permissible Deduction Count:
                          </label>
                          <div className="col-md-8">
                            <input
                              type="number"
                              className={this.validateField(
                                "permissibleDeductionCount",
                                "number",
                                validated
                              )}
                              id="permissibleDeductionCount"
                              name="permissibleDeductionCount"
                              value={
                                permissibleDeductionConfig ===
                                  PermissibleDeductionConfig.Limited
                                  ? 0
                                  : permissibleDeductionCount
                              }
                              onChange={(e) =>
                                onChange(
                                  "permissibleDeductionCount",
                                  Number(e.currentTarget.value)
                                )
                              }
                              disabled={
                                permissibleDeductionConfig ===
                                PermissibleDeductionConfig.Limited
                              }
                            />
                            <div
                              className="invalid-feedback"
                              style={{
                                visibility: errors.permissibleDeductionCount,
                              }}
                            >
                              {errors.permissibleDeductionCount}
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="mandateDuration"
                            className="col-md-4 col-form-label"
                          >
                            Mandate Duration:
                          </label>
                          <div className="col-md-8">
                            <input
                              type="number"
                              className={this.validateField(
                                "mandateDuration",
                                "number",
                                validated
                              )}
                              id="mandateDuration"
                              name="mandateDuration"
                              value={
                                mandateDurationConfig ===
                                  MandateDurationConfig.Limited
                                  ? 0
                                  : mandateDuration
                              }
                              onChange={(e) =>
                                onChange(
                                  "mandateDuration",
                                  Number(e.currentTarget.value)
                                )
                              }
                              disabled={
                                mandateDurationConfig ===
                                MandateDurationConfig.Limited
                              }
                            />
                            <div
                              className="invalid-feedback"
                              style={{ visibility: errors.mandateDuration }}
                            >
                              {errors.mandateDuration}
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="description"
                            className="col-md-4 col-form-label"
                          >
                            Description:
                          </label>
                          <div className="col-md-8">
                            <textarea
                              rows={2}
                              className={this.validateField(
                                "description",
                                "text",
                                validated
                              )}
                              id="description"
                              name="description"
                              value={description}
                              onChange={(e) =>
                                onChange("description", e.currentTarget.value)
                              }
                            />
                            <div
                              className="invalid-feedback"
                              style={{ visibility: errors.description }}
                            >
                              {errors.description}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="form-group row">
                          <label
                            htmlFor="status"
                            className="col-md-4 col-form-label"
                          >
                            Status:
                          </label>
                          <div className="col-md-8 col-form-label blue-grey-text">
                            {(item ? item.status : "Active") === "Active" ? (
                              <span className="badge badge-success">
                                {item ? item.status : ""}
                              </span>
                            ) : (
                              <span className="badge badge-danger">
                                {item ? item.status : ""}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="form-group row">
                          <DetailItem
                            label="Configuration"
                            value={
                              <Fragment>
                                <span className="material-switch">
                                  <input
                                    id="transactionAmountSettings"
                                    name="transactionAmountSettings"
                                    type="checkbox"
                                    onChange={() =>
                                      this.handleOnSwitchUpdate(
                                        "transactionAmountSettings",
                                        {
                                          trueValue:
                                            TransactionAmountSettings.Variable,
                                          falseValue:
                                            TransactionAmountSettings.Fixed,
                                        }
                                      )
                                    }
                                    checked={
                                      transactionAmountSettings ===
                                      TransactionAmountSettings.Variable
                                    }
                                  />
                                  <label
                                    htmlFor="transactionAmountSettings"
                                    className="bg-primary"
                                  ></label>
                                </span>
                                <label>
                                  &nbsp;&nbsp;{"   "}
                                  {
                                    TransactionAmountSettings[
                                    transactionAmountSettings
                                    ]
                                  }
                                </label>
                              </Fragment>
                            }
                          />
                        </div>
                        <div className="form-group row">
                          <DetailItem
                            label="Configuration"
                            value={
                              <Fragment>
                                <span className="material-switch">
                                  <input
                                    id="permissibleDeductionConfig"
                                    name="permissibleDeductionConfig"
                                    type="checkbox"
                                    onChange={() =>
                                      this.handleOnSwitchUpdate(
                                        "permissibleDeductionConfig",
                                        {
                                          trueValue:
                                            PermissibleDeductionConfig.Limited,
                                          falseValue:
                                            PermissibleDeductionConfig.Unlimited,
                                        }
                                      )
                                    }
                                    checked={
                                      permissibleDeductionConfig ===
                                      PermissibleDeductionConfig.Unlimited
                                    }
                                  />
                                  <label
                                    htmlFor="permissibleDeductionConfig"
                                    className="bg-primary"
                                  ></label>
                                </span>
                                <label>
                                  &nbsp;&nbsp;{"   "}
                                  {
                                    PermissibleDeductionConfig[
                                    permissibleDeductionConfig
                                    ]
                                  }
                                </label>
                              </Fragment>
                            }
                          />
                        </div>
                        <div className="form-group row">
                          <DetailItem
                            label="Configuration"
                            value={
                              <Fragment>
                                <span className="material-switch">
                                  <input
                                    id="cummulativeDeductionConfig"
                                    name="cummulativeDeductionConfig"
                                    type="checkbox"
                                    onChange={() =>
                                      this.handleOnSwitchUpdate(
                                        "cummulativeDeductionConfig",
                                        {
                                          trueValue:
                                            CummulativeDeductionConfig.Variable,
                                          falseValue:
                                            CummulativeDeductionConfig.Fixed,
                                        }
                                      )
                                    }
                                    checked={
                                      cummulativeDeductionConfig ===
                                      CummulativeDeductionConfig.Variable
                                    }
                                  />
                                  <label
                                    htmlFor="cummulativeDeductionConfig"
                                    className="bg-primary"
                                  ></label>
                                </span>
                                <label>
                                  &nbsp;&nbsp;{"   "}
                                  {
                                    CummulativeDeductionConfig[
                                    cummulativeDeductionConfig
                                    ]
                                  }
                                  {"  "}
                                </label>
                              </Fragment>
                            }
                          />
                        </div>
                        <div className="form-group row">
                          <DetailItem
                            label="Configuration"
                            value={
                              <Fragment>
                                <span className="material-switch">
                                  <input
                                    id="mandateDurationConfig"
                                    name="mandateDurationConfig"
                                    type="checkbox"
                                    onChange={() =>
                                      this.handleOnSwitchUpdate(
                                        "mandateDurationConfig",
                                        {
                                          trueValue:
                                            MandateDurationConfig.Limited,
                                          falseValue:
                                            MandateDurationConfig.Unlimited,
                                        }
                                      )
                                    }
                                    checked={
                                      mandateDurationConfig ===
                                      MandateDurationConfig.Unlimited
                                    }
                                  />
                                  <label
                                    htmlFor="mandateDurationConfig"
                                    className="bg-primary"
                                  ></label>
                                </span>
                                <label>
                                  &nbsp;&nbsp;{"   "}
                                  {MandateDurationConfig[mandateDurationConfig]}
                                  {"  "}
                                </label>
                              </Fragment>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.UPDATE,
                      "Mandate Service Type",
                      () => {
                        this.onFormSubmit(
                          {
                            name,
                            description,
                            status,
                            transactionAmount,
                            cumulativeDeductionCount,
                            permissibleDeductionCount,
                            mandateDuration,
                          },
                          onReloadFields
                        );
                      }
                    );
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></EditMandateServiceTypeForm>
      </Modal>
    );
  }
}

export default EditMandateServiceType;
