import React from "react";
import { toast } from "react-toastify";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../../components/BaseFormComponent";
import billingValidationService from "../../../../services/billing/billingValidationService";
import { ISelectItems } from "../../../../components/detailItemSelectBox";
import FormInputComponent from "../../../../components/formInputComponent";
import { ActionTypes } from "../../../../enums/actionTypes";
import SelectInputComponent from "../../../../components/formSelectComponent";
import feeConfigService from "../../../../services/billing/feeConfigService";
import activityTrailService from "../../../../services/auditTrail/activityTrailService";


interface AddOCMFeeConfigurationFields {
  institutionCategory: string;
  flatRatePerRequest: string;
  flatRatePerLoanDisbursed: string;
  percentageOfLoanAmount: string;
  flatRatePerSuccessfulRequest: string;
  minimumPerTransaction: string;
  maximumPerTransaction: string;
}

class AddOCMFeeConfigurationForm extends BaseFormComponent<AddOCMFeeConfigurationFields> { }

interface IAddOCMFeeConfigurationProps { }
interface IAddOCMFeeConfigurationState { }

class AddOCMFeeConfiguration extends React.Component<
  IAddOCMFeeConfigurationProps,
  IBaseFormState & IAddOCMFeeConfigurationState
> {
  constructor(props: IAddOCMFeeConfigurationProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
    };
  }

  onFormSubmit(fields: AddOCMFeeConfigurationFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        { errors: billingValidationService.validateAddOCMCategoryFeeConfigurationForm(fields) },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddOCMFeeConfigurationFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    if (Number(fields.minimumPerTransaction) > Number(fields.maximumPerTransaction)) {
      toast.info(`Minimum Amount cannot be greater than Maximum!`, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      return false;
    }
    try {
      this.setState({ submitting: true });
      const payload: any = {};

      payload.serviceOffering = 'Blackbox';
      payload.institutionCategory = fields.institutionCategory;
      payload.flatRatePerRequest = Number(fields.flatRatePerRequest);
      payload.flatRatePerLoanDisbursed = Number(fields.flatRatePerLoanDisbursed);
      payload.percentageOfLoanAmount = Number(fields.percentageOfLoanAmount);
      payload.flatRatePerSuccessfulRequest = Number(fields.flatRatePerSuccessfulRequest);
      payload.minimumPerTransaction = Number(fields.minimumPerTransaction);
      payload.maximumPerTransaction = Number(fields.maximumPerTransaction);
      // console.log("payload: ", payload);

      var paymentConfig = await feeConfigService.retrieveFeeConfigByServiceOfferingAndInstitutionCategory('Blackbox', fields.institutionCategory);
      if ((paymentConfig.data).length != 0) {
        toast.error(`OCM Fee Configuration for ${fields.institutionCategory} already exist `, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      else {
        const response = await feeConfigService.createFeeConfig(payload);
        if (response.status >= 200 && response.status <= 300) {
          await activityTrailService.saveActivityTrail({
            actionType: "Add OCM Fee Configuration",
            description: `Added new OCM Fee Configuration ${fields.institutionCategory}`,
          });
          toast.info(`OCM Fee Configuration for ${fields.institutionCategory} created successfully! `, {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          });
        }
        else {
          return false;
        }
        this.setState({ submitting: false });
        onReloadFieldsCB();
      }
    } catch (error) {
      // console.log("error: ", error.response.data);
      return false;
    } finally {
      this.setState({ submitting: false });
      return true;
    }
  }

  render() {
    const { errors, validated } = this.state;

    let institutionCategoryInputData: ISelectItems[] = [
      { name: "Commercial Bank", value: "CommercialBank" },
      { name: "BankOne OFI", value: "BankOneOFI" },
      { name: "OFI", value: "OFI" },
    ];

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add OCM Fee Configuration</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddOCMFeeConfigurationForm
                  initialValues={{
                    institutionCategory: "",
                    flatRatePerRequest: "",
                    flatRatePerLoanDisbursed: "",
                    percentageOfLoanAmount: "",
                    flatRatePerSuccessfulRequest: "",
                    minimumPerTransaction: "",
                    maximumPerTransaction: ""
                  }}
                  FormComponent={({
                    fields: {
                      institutionCategory,
                      flatRatePerRequest,
                      flatRatePerLoanDisbursed,
                      percentageOfLoanAmount,
                      flatRatePerSuccessfulRequest,
                      minimumPerTransaction,
                      maximumPerTransaction
                    },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-row">
                                <SelectInputComponent
                                  id="institutionCategory"
                                  name="institutionCategory"
                                  divClass={6}
                                  value={institutionCategory}
                                  items={institutionCategoryInputData}
                                  required={true}
                                  validated={false}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="flatRatePerRequest"
                                  type="number"
                                  name="flatRatePerRequest"
                                  placeholder=""
                                  divClass={6}
                                  value={flatRatePerRequest}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-row">
                                <FormInputComponent
                                  id="flatRatePerLoanDisbursed"
                                  type="number"
                                  name="flatRatePerLoanDisbursed"
                                  placeholder=""
                                  divClass={6}
                                  value={flatRatePerLoanDisbursed}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="percentageOfLoanAmount"
                                  type="text"
                                  name="percentageOfLoanAmount"
                                  placeholder=""
                                  divClass={6}
                                  value={percentageOfLoanAmount}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-row">
                                <FormInputComponent
                                  id="flatRatePerSuccessfulRequest"
                                  type="text"
                                  name="flatRatePerSuccessfulRequest"
                                  placeholder=""
                                  divClass={6}
                                  value={flatRatePerSuccessfulRequest}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="minimumPerTransaction"
                                  type="text"
                                  name="minimumPerTransaction"
                                  placeholder=""
                                  divClass={6}
                                  value={minimumPerTransaction}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-row">
                                <FormInputComponent
                                  id="maximumPerTransaction"
                                  type="text"
                                  name="maximumPerTransaction"
                                  placeholder=""
                                  divClass={6}
                                  value={maximumPerTransaction}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="card-body">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary"
                          disabled={this.state.submitting}
                          onClick={(e) => {
                            onHandleSubmit(
                              e,
                              ActionTypes.SAVE,
                              "OCM Fee Configuration",
                              () => {
                                this.onFormSubmit(
                                  {
                                    institutionCategory,
                                    flatRatePerRequest,
                                    flatRatePerLoanDisbursed,
                                    percentageOfLoanAmount,
                                    flatRatePerSuccessfulRequest,
                                    minimumPerTransaction,
                                    maximumPerTransaction
                                  },
                                  onReloadFields
                                );
                              }
                            );
                          }}
                        >
                          {this.state.submitting === false ? (
                            <React.Fragment>
                              <i className="fas fa-lg fa-save mr-3" /> Save
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                              Please wait...
                            </React.Fragment>
                          )}
                        </button>
                      </div>
                    </form>
                  )}
                ></AddOCMFeeConfigurationForm>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment >
    );
  }
}

export default AddOCMFeeConfiguration;
