import * as React from "react";
import _ from "lodash";
import { Fragment } from "react";
import {
  IFilterData,
  BaseListComponentState,
} from "../../../components/BaseListComponent";
import {
  createFilterData,
  mapEntityList,
} from "./../../../services/utility/autoMapperService";
import BaseListComponent from "./../../../components/BaseListComponent";
import ItemsTable, {
  filterFieldData,
} from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "./../../../components/tableComponents/tableFilterPane";
import {
  getCurrentUserSession,
  getCurrentUsersRoles,
} from "../../../services/auth/authService";
import creditQualificationRuleService from "../../../services/creditAssessment/creditQualificationRuleService";
import CreditQualificationRuleDetail from "./creditQualificationRuleDetail";
import EditCreditQualificationRule from "./editCreditQualificationRule";
import { decodeToken } from "react-jwt";
import { toast } from "react-toastify";
import { filterList } from "../../../services/utility/textFormatService";
import scoringCardService from "../../../services/creditAssessment/scoringCardService";

export interface CreditQualificationRulesProps { }

export interface CreditQualificationRulesState extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
  updateBaseListState: boolean;
  toUpdate: boolean;
  allItems: any;
  institutionCode: string
}

class CreditQualificationRulesList extends BaseListComponent<CreditQualificationRulesState> { }

class CreditQualificationRules extends React.Component<
  CreditQualificationRulesProps,
  CreditQualificationRulesState
> {
  constructor(props: CreditQualificationRulesProps) {
    super(props);
    this.state = {
      data: createFilterData([
        "name",
        // "priorityLevel",
        // "loanProductCategory",
      ]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [
        { name: "name", fieldType: "text", value: "" },
        // { name: "priorityLevel", fieldType: "number", value: "" },
        // { name: "loanProductCategory", fieldType: "text", value: "" },
      ],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
      updateBaseListState: false,
      toUpdate: true,
      allItems: [],
      institutionCode: ""
    };
  }

  handlePageChange = async (currentPage: number, pageSize: number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true, updateBaseListState: false });
    let dataClone = typeof data === "undefined" ? { ...this.state.data } : { ...data };
    try {
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const credQualRules = await creditQualificationRuleService.retrieveCreditQualificationRulesByInstitution(
        decodedToken?.["InstitutionCode"]
      );
      const scoreCard = await scoringCardService.retrieveScoreCardByInstitutionCode(decodedToken?.["InstitutionCode"])
      const scoreCardDetails = scoreCard.data
      // console.log("Fetched ScoreCard::", scoreCardDetails);
      //       let credQualRulesList = credQualRules.data.forEach(x=>{
      // x.scoreCard = 
      //       })
      const filteredCQRs = filterList(credQualRules.data, dataClone);

      // const loanProducts = await loanProductService.retrieveAllByInstitutionCode(
      //   decodedToken?.["InstitutionCode"] || ""
      // );
      // if (currentPage === 1 && this.state.toUpdate === true) {
      //   const loanProductNames = !_.isEmpty(loanProducts)
      //     ? loanProducts.data.map((item) => item.name)
      //     : [];
      //   let filterValueInput = [...this.state.filterValueInput];
      //   filterValueInput = filterValueInput.map((filterField) =>
      //     filterField.name === "product"
      //       ? { ...filterField, options: loanProductNames }
      //       : filterField
      //   );
      //   this.setState({
      //     filterValueInput: filterValueInput,
      //     updateBaseListState: true,
      //     toUpdate: false,
      //   });
      // }
      // const loanProductObj = !_.isEmpty(loanProducts)
      //   ? loanProducts.data.map((item) => {
      //       return { name: item.name, id: item.id };
      //     })
      //   : [];

      // const selectedLoanProductObj = !_.isEmpty(loanProductObj)
      //   ? loanProductObj.filter(
      //       (item) => item.name === dataClone["product"].item2
      //     )[0]
      //   : {};

      // // console.log("Former Data Clone :: ", dataClone);
      // const newDataArray = [
      //   "loanProductID",
      //   "priorityLevel",
      //   "minimumScore",
      //   "supportedChannels",
      //   "loanType",
      // ];
      // let filterData: any = {};
      // newDataArray.forEach((header) => {
      //   if (header === "loanProductID") {
      //     filterData[header] = !_.isEmpty(selectedLoanProductObj)
      //       ? {
      //           item1: dataClone["product"].item1,
      //           item2: selectedLoanProductObj.id.toString(),
      //         }
      //       : { item1: "like", item2: "" };
      //   } else {
      //     filterData[header] = dataClone[header];
      //   }
      // });

      // filterData["institutionId"] = {
      //   item1: "=",
      //   item2: decodedToken?.["InstitutionId"]?.toString() || "",
      // };
      // // console.log("filter Data ::", filterData);
      // const cqRules = await creditQualificationRuleService.filterPagedCreditQualificationRules(
      //   filterData,
      //   Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      //   currentPage
      // );

      const cqRules: any = [];
      const totalLength = filteredCQRs.length;
      const initial = (currentPage - 1) * pageSize;
      for (let i = initial; i < initial + pageSize; i++) {
        if (i === totalLength) {
          break;
        }
        cqRules.push(filteredCQRs[i]);
      }

      cqRules.forEach(x => {
        x.channel = x.institutionCode === "000" ? "Qore" : "Client";
      })
      // console.log("credQualRules:: ",credQualRules)
      // console.log("cqRules:: ",cqRules)
      this.setState({
        tableItems: mapEntityList({
          // entityList: cqRules.data.item2,
          entityList: cqRules,
          properties: [
            { oldName: "name", newName: "name" },
            { oldName: "scoreCard", newName: "scoreCard" },
            { oldName: "minimumScore", newName: "Min. Static Score" },
            { oldName: "channel", newName: "channel" },
            // { oldName: "loanProductCategory", newName: "loanProductCategory" },
          ],
        }),
        currentPage,
        pageSize,
        // totalSize: cqRules.data.item1,
        totalSize: totalLength,
        fetching: false,
        allItems: filteredCQRs,
      });
    } catch (error) {
      // console.log("caught exception", error);
      // console.log("caught exception", error.response);
      if (error.response.status === 400 || error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
        this.setState({ fetching: false });
      } else {
        // return false;
        this.setState({ fetching: false });
      }
    }
  };

  handleOnExport = async (type: string) => { };

  fetchItemById = async (item: any) => {
    this.setState({ fetchingDetail: true, updateBaseListState: false });
    const cqr =
      await creditQualificationRuleService.retrieveCreditQualificationRuleById(
        item.id
      );
    // console.log("cqr :: ", cqr.data);
    this.setState({ item: cqr.data, fetchingDetail: false });
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    // console.log("filtered Data :: ", data);
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    // const cqRules = await creditQualificationRuleService.retrieveCreditQualificationRulesByInstitution(
    //   decodedToken?.["InstitutionCode"]
    // );
    // console.log("Fetcched rules::", cqRules);

    // this.setState(
    //   {
    //     allItems: cqRules.data,
    //   },
    //   () => {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);

    this.setState({ institutionCode: decodedToken?.["InstitutionCode"] })
    //   }
    // );
  }

  render() {
    const {
      // data,
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    // console.log("asfsdf", this.state.filterValueInput);
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Credit Qualification Rules</b>
              </h3>
            </div>
            <CreditQualificationRulesList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              updateState={this.state.updateBaseListState}
              initialValues={{
                data: this.state.data,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: this.state.filterValueInput,
                item: {},
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                onToggleEditModal,
                showDetailsModal,
                showEditModal,
              }) => (
                <Fragment>
                  <CreditQualificationRuleDetail
                    showDetailsModal={showDetailsModal}
                    toggleDetailsModal={onToggleDetailsModal}
                    toggleEditModal={onToggleEditModal}
                    showTrail={null}
                    currentUserRoles={getCurrentUsersRoles()}
                    item={item}
                  />
                  <EditCreditQualificationRule
                    showEditModal={showEditModal}
                    toggleEditModal={(reloadDetails: boolean, item: any) => {
                      onToggleEditModal();
                      if (reloadDetails === true) {
                        this.fetchItemById(item);
                      }
                      this.setState({
                        showDetailsModal: !this.state.showDetailsModal,
                      });
                    }}
                    item={item}
                  />
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="Credit Qualification Rules"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                if (decodedToken?.["InstitutionCode"] === "000") {
                                  onToggleDetailsModal()
                                }
                                else {
                                  if (item.channel !== "Qore") { onToggleDetailsModal() };
                                }
                              }}
                              handleOnExport={this.handleOnExport}
                            // disableViewDetails={item.institutionCode !== "000" ? false : true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></CreditQualificationRulesList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default CreditQualificationRules;
