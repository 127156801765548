import React from "react";
import _ from "lodash";
import { Link, RouteComponentProps } from "react-router-dom";
import BaseFormComponent, {
  IBaseFormState,
} from "../../components/BaseFormComponent";
import SideBanner from "../../components/sideBanner";
import recova_image from "../../images/recova-image.jpg";
import recova_logo from "../../images/recova-logo.svg";
import authValidationService from "../../services/auth/authValidationService";
import usersService from "../../services/auth/usersService";

export interface UserSetupProps
  extends RouteComponentProps<{
    token: string;
  }> { }

interface UserSetupFields {
  institutionCode: string;
  username: string;
  password: string;
  confirmPassword: string;
  score: string;
}

export class UserSetupForm extends BaseFormComponent<UserSetupFields> { }

export interface UserSetupState {
  institutionCode: string;
  username: string;
  user: any;
  retrievingUser: boolean;
}

class UserSetup extends React.Component<
  UserSetupProps,
  UserSetupState & IBaseFormState
> {
  constructor(props: UserSetupProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      user: {},
      institutionCode: "",
      username: "",
      retrievingUser: true,
    };
  }

  onFormSubmit(fields: UserSetupFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        { errors: authValidationService.validateUserSetupForm(fields) },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          } else {
            return false;
          }
        }
      );
      this.setState({ validated: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (fields: UserSetupFields, onReloadFieldsCB: any) => {
    try {
      const { user } = this.state;
      this.setState({ submitting: true });
      const response = await usersService.setUserPassword(
        user.institution.code,
        user.username,
        fields.password
      );
      if (response.status >= 200 && response.status < 300) {
        this.setState({ submitting: false });
        this.props.history.push("/home");
      }
      onReloadFieldsCB();
    } catch (error) {
    } finally {
      this.setState({ submitting: false });
    }
  };

  retrievePasswordStrengthScore(value: string): number {
    let score = 0;
    //score if value has an uppercase letter
    if (value.match(/[A-Z]/)) {
      score = score + 20;
    }
    //score if value has a lowercase letter
    if (value.match(/[a-z]/)) {
      score = score + 20;
    }
    //score if value has a number
    if (value.match(/\d/)) {
      score = score + 20;
    }
    //score if value has a special character
    if (value.match(/[@$!%*?&#_()]/)) {
      score = score + 20;
    }
    //score if value has a minumum of 8 characters
    if (value.match(/^[A-Za-z\d@$!%*?&#_()]{8,}$/)) {
      score = score + 20;
    }
    // console.log("current score: ", score);
    return score;
    // this.setState({ score });
  }

  retrieveScoreStrengthColor(score: number): string {
    if (score < 1) {
      return "";
    } else if (score <= 20) {
      return "#EF350B";
    } else if (score <= 40) {
      return "#F38B48";
    } else if (score <= 60) {
      return "#F3C748";
    } else if (score <= 80) {
      return "#DAF547";
    } else {
      return "#40A72C";
    }
  }

  async componentDidMount() {
    try {
      var user = await usersService.retrieveUserByToken(
        this.props.match.params.token
      );
      this.setState({
        username: user.data.username,
        institutionCode: user.data.institution.code,
        user: user.data,
      });
    } catch (error) {
    } finally {
      this.setState({ retrievingUser: false });
    }
  }

  render() {
    return (
      <React.Fragment>
        <UserSetupForm
          initialValues={{
            institutionCode: "",
            username: "",
            password: "",
            confirmPassword: "",
            score: "",
          }}
          FormComponent={({
            fields: {
              institutionCode,
              username,
              password,
              confirmPassword,
              score,
            },
            onChange,
            onChange2,
            onReloadFields,
          }) => (
            <div className="container-fluid">
              <div
                id="primary"
                className="height-full responsive-phone row"
                style={{
                  backgroundImage: `url(${recova_image})`,
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="col-md-5">
                  <SideBanner />
                </div>
                <div className="col-md-7" style={{ backgroundColor: "white" }}>
                  <div className="row">
                    <div className="col text-right brand pt-3 pr-5">
                      <img
                        src={recova_logo}
                        width="145"
                        alt=""
                        className="image"
                      />
                    </div>
                  </div>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-3"></div>
                      <div className="col-lg-9 p-t-100">
                        <div className="text-black">
                          <h1>Configure Password</h1>
                          <p className="s-18 font-weight-lighter">
                            {_.isEmpty(this.state.user)
                              ? ""
                              : "Enter your details in the fields below."}
                          </p>
                        </div>
                        <form action="#">
                          <div className="row">
                            {_.isEmpty(this.state.user) ? (
                              this.state.retrievingUser ? (
                                <React.Fragment>
                                  <div className="col-lg-8">
                                    <i className="fas icon-spinner s-18 fa-spin"></i>
                                  </div>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <div className="col-lg-8">
                                    <p className="s-18 font-weight-lighter">
                                      This password link has expired.
                                    </p>
                                    <p className="forget-pass text-orange">
                                      <Link to="/login">
                                        <i className="icon-sign-in mr-2" />
                                        Back To Login
                                      </Link>
                                    </p>
                                  </div>
                                </React.Fragment>
                              )
                            ) : (
                              <React.Fragment>
                                <div className="col-lg-8">
                                  <div className="form-group has-icon">
                                    <i className="icon-account_balance"></i>
                                    <input
                                      autoFocus
                                      type="text"
                                      className="form-control form-control-lg"
                                      placeholder="Institution Code"
                                      id="institutionCode"
                                      name="institutionCode"
                                      disabled={true}
                                      value={this.state.institutionCode}
                                      onChange={(e) =>
                                        onChange(
                                          "institutionCode",
                                          e.currentTarget.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-8">
                                  <div className="form-group has-icon">
                                    <i className="icon-user"></i>
                                    <input
                                      autoFocus
                                      type="text"
                                      className="form-control form-control-lg"
                                      placeholder="Username"
                                      id="username"
                                      name="username"
                                      disabled={true}
                                      value={this.state.username}
                                      onChange={(e) =>
                                        onChange(
                                          "username",
                                          e.currentTarget.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-8">
                                  <br />
                                  <br />
                                  <span>
                                    <p
                                      className="s-12 text-red"
                                      style={{
                                        // fontStyle: "italic",
                                        lineHeight: "15px",
                                      }}
                                    >
                                      <b>NOTE: </b>The password must have
                                      minimum of 8 characters, an uppercase
                                      letter, a lowercase letter, a number and a
                                      special character.
                                    </p>
                                  </span>
                                  {/* <p className="s-16">Password</p> */}
                                </div>
                                <div className="col-lg-8">
                                  <div className="form-group has-icon">
                                    <i className="icon-lock"></i>
                                    <input
                                      autoFocus
                                      type="password"
                                      className="form-control form-control-lg"
                                      placeholder="Password"
                                      id="password"
                                      name="password"
                                      value={password}
                                      autoComplete="new-password"
                                      onChange={(e) => {
                                        var strengthScore = String(
                                          this.retrievePasswordStrengthScore(
                                            e.currentTarget.value
                                          )
                                        );
                                        onChange2(
                                          ["password", "score"],
                                          [e.currentTarget.value, strengthScore]
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-8">
                                  {/* <p className="s-12">Password Strength</p> */}
                                  <div className="progress progress-xs mb-3">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{
                                        width: `${score}%`,
                                        backgroundColor: `${this.retrieveScoreStrengthColor(
                                          Number(score)
                                        )}`,
                                      }}
                                      aria-valuenow={Number(score)}
                                      aria-valuemin={0}
                                      aria-valuemax={100}
                                    ></div>
                                  </div>
                                </div>
                                <div className="col-lg-8">
                                  <div className="form-group has-icon">
                                    <i className="icon-lock"></i>
                                    <input
                                      type="password"
                                      className="form-control form-control-lg"
                                      placeholder="Confirm Password"
                                      id="confirmPassword"
                                      name="confirmPassword"
                                      value={confirmPassword}
                                      onChange={(e) =>
                                        onChange(
                                          "confirmPassword",
                                          e.currentTarget.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                {Object.keys(this.state.errors).length > 0 && (
                                  <div className="col-lg-8">
                                    <div
                                      className="alert alert-danger alert-dismissible"
                                      role="alert"
                                    >
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">×</span>
                                      </button>
                                      <strong>
                                        {
                                          this.state.errors[
                                          Object.keys(this.state.errors)[0]
                                          ]
                                        }
                                      </strong>
                                    </div>
                                  </div>
                                )}

                                <div className="col-lg-8">
                                  <button
                                    type="submit"
                                    data-wait="Please wait..."
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.onFormSubmit(
                                        {
                                          institutionCode,
                                          username,
                                          password,
                                          confirmPassword,
                                          score,
                                        },
                                        onReloadFields
                                      );
                                    }}
                                    className={
                                      this.state.submitting === false
                                        ? "btn btn-primary btn-block btn-lg"
                                        : "btn btn-primary btn-block btn-lg disabled"
                                    }
                                  >
                                    {this.state.submitting === false ? (
                                      <React.Fragment>
                                        <i className="far fa-paper-plane mr-3"></i>
                                        Send
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        <i className="fas fa-spin fa-circle-notch" />
                                      </React.Fragment>
                                    )}
                                  </button>
                                </div>
                              </React.Fragment>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        ></UserSetupForm>
      </React.Fragment>
    );
  }
}

export default UserSetup;
