import React, { Component, Fragment } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import BaseFormComponent, { IBaseFormState } from "../../../../components/BaseFormComponent";
import FormInputComponent from "../../../../components/formInputComponent";
import SelectInputComponent, { ISelectItems } from "../../../../components/formSelectComponent";
import { EconomicSector } from "../../../../enums/economicSector";
import { LoanComputationMode } from "../../../../enums/loanComputationMode";
import { ProductType } from "../../../../enums/productType";
import textFormatService, { generateGUID } from "../../../../services/utility/textFormatService";
import RepaymentSchedule from "./wizardComponents/editDetailedDataEntry/repaymentSchedule";
import loanRequestService from "../../../../services/hybridAppraisal/loanRequestService";
import { ActionTypes } from "../../../../enums/actionTypes";
import SupportingDocuments from "../../loanRequest/appraisalProcessComponents/supportingDocuments";
import { getCurrentUserSession } from "../../../../services/auth/authService";
import appraisalActionService from "../../../../services/hybridAppraisal/appraisalActionService";
import activityTrailService from "../../../../services/auditTrail/activityTrailService";
import FormTextAreaComponent from "../../../../components/formTextAreaComponent";
import { Accordion, Card } from "react-bootstrap";
import { ContextAwareToggle } from "../../loanAppraisal/appraisalDetailPages/BankAnalysisCheck";
import Skeleton from "react-loading-skeleton";
import ValidateFinalAmountModal from "./validateFinalAmountModal";
import ValidateAmountModal from "./validateAmountModal";
import usersService from "../../../../services/auth/usersService";
import Repayment from "../../loanAppraisal/appraisalDetailPages/wizardComponents/editDetailedDataEntry/repayment";
import GroupRepayment from "./wizardComponents/editDetailedDataEntry/groupRepayment";
import loanProductService from "../../../../services/hybridAppraisal/loanProductService";

interface loanDisbursementFields {
    loanAmount: string;
    tenureInDays: string;
    loanPurpose: string;
    interestRate: string;
    repaymentFrequencyPrincipal: string;
    repaymentFrequencyInterest: string;
    dueDate: string;
    moratorium: string;
    moratoriumPeriod: string;
    impactOnTenor: string;
    chargeInterest: string;
    spreadInterestAcrossBalanceTenor: string;
    productCode: string;
    accountName: string;
    accountNumber: string;
    accountOfficer: string;
    bankVerificationNumber: string;
    branch: string;
    defaultingLoanInterest: string;
    economicSector: string;
    securityDeposit: string;
    disbursementMode: string;
    typeOfLoan: string;
    preferredRepaymentBank: string;
    preferredRepaymentAccount: string;
    charges: any;
    comment: string;
    newLoanAmount: string;
}

class LoanDisbursementForm extends BaseFormComponent<loanDisbursementFields> { }

interface loanDisbursementProps {
    values: any;
    item: any;
    systemProcess: any;
    userRoles: any;
    tempDocs: any;
    tempDeletedDocs: number[];
    updateItemDocs: any;
    deleteItemDocs: any;
}
interface loanDisbursementState {
    showRepaymentSchedule: boolean;
    showDocPatchModal: boolean;
    applicableRules: any;
    appraisalActions: any;
    updateComponent: boolean;
    documentsToUpload: any;
    documentsToDelete: number[];
    updateAvailable: boolean;
    item: any;
    ownUpdate: boolean;
    showValidateAmount: boolean;
    members: any
    productPricing: any;
}

class LoanDisbursement extends Component<
    loanDisbursementProps,
    IBaseFormState & loanDisbursementState
> {
    _isMounted = false;
    constructor(props: loanDisbursementProps) {
        super(props);
        this.state = {
            item: {},
            validated: false,
            submitting: false,
            errors: {},
            showRepaymentSchedule: false,
            showDocPatchModal: false,
            applicableRules: [],
            appraisalActions: [],
            documentsToUpload: [],
            documentsToDelete: [],
            updateAvailable: false,
            updateComponent: false,
            ownUpdate: false,
            showValidateAmount: false,
            members: {},
            productPricing: []
        };
    }

    async componentDidMount() {
        this._isMounted = true;
        // this.handlePageChange(this.state.currentPage, this.state.pageSize);
        // let applicableRules = this.props.item.financialGroup.rules.filter(
        //   (rule) =>
        //     rule.level === this.props.item.currentLevel &&
        //     rule.roleID === this.props.currentUserSession.userRoleId
        // );
        const appraisalActions = this.props.values.appraisalActions;

        if (appraisalActions) {
            const userPromises: any = [];
            for (let index = 0; index < appraisalActions.length; index++) {
                if (appraisalActions[index].logType !== "SystemType") {
                    const userPromise = usersService.retrieveUserById(appraisalActions[index].userID);
                    userPromises.push(userPromise);
                }
            }

            // const users = await Promise.all(userPromises);
            const users = await Promise.allSettled(userPromises);
            // console.log(users)
            let userIndex = 0;
            for (let index = 0; index < appraisalActions.length; index++) {
                if (appraisalActions[index].logType !== "SystemType") {
                    const user = users[userIndex];
                    appraisalActions[index]["user"] = user.status == "fulfilled" ? user.value.data : null;
                    userIndex++;
                }
            }

            let productPricing: any = []
            const loanProduct = await loanProductService.retrieveById(this.props.values.loanProduct?.id);
            const loanProductDetails = loanProduct.data
            productPricing = loanProductDetails.productPricing
            const jsonLoanDetails = JSON.parse(JSON.parse(JSON.parse(this.props.values.jsonData)).LoanDetails);
            // console.log(jsonLoanDetails)
            if (!_.isEmpty(productPricing)) {
                // console.log(jsonLoanDetails.LoanFees)
                if (jsonLoanDetails.LoanFees.length > 0) {
                    jsonLoanDetails.LoanFees.forEach(x => {
                        const loanFee: any = {};
                        loanFee.name = x.Name
                        loanFee.value = x.Amount / 100
                        loanFee.feeType = "Value"

                        productPricing?.push(loanFee)
                    })
                    // productPricing.push(jsonLoanDetails.LoanFees)
                }
            }
            else {
                const loanFees: any = [];
                if (jsonLoanDetails.LoanFees.length > 0) {
                    try {
                        jsonLoanDetails?.LoanFees?.forEach(x => {
                            const loanFee: any = {};
                            loanFee.name = x.Name
                            loanFee.value = x.Amount / 100
                            loanFee.feeType = "Value"
                            loanFees.push(loanFee)
                        })
                    } catch {
                        const loanFee: any = {};
                        loanFees.push(loanFee)
                    }
                }

                productPricing = loanFees
            }
            // console.log(productPricing)
            this.setState({ productPricing: productPricing })

            // this.props.item.appraisalActions = appraisalActions;


            // for (let index = 0; index < appraisalActions.length; index++) {
            //   if (appraisalActions[index].logType !== "SystemType") {
            //     const user = await usersService.retrieveUserById(
            //       appraisalActions[index].userID
            //     );
            //     appraisalActions[index]["user"] = user.data;
            //   }
            // }

            // appraisalActions.reverse();
            // Check if any user role on the current level has approved
            // const checkActions = appraisalActions.filter(action => (action.itemLevel == this.props.item.currentLevel && action.action == "Approve"));
            // console.log("approval actions:", checkActions);
            // const canEdit = applicableRules.length !== 0 && this.props.item.status !== "Rejected" && (!getCurrentUsersRoles().includes("remotesession")) && checkActions.length == 0
            // const specialConditions = this.props.item.specialConditions != null ? this.props.item.specialConditions : [];
            this.setState({ ownUpdate: true });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    static getDerivedStateFromProps(
        nextProps: loanDisbursementProps,
        prevState
    ) {
        if (prevState.ownUpdate) {
            return {
                ownUpdate: false,
            };
        }
        if (nextProps.item.jsonData && prevState.item?.CustomerID != JSON.parse(JSON.parse(nextProps.item.jsonData)).CustomerID) {
            // console.log("Updating state afresh")
            // console.log(JSON.parse(JSON.parse(nextProps.item)))
            return { item: JSON.parse(JSON.parse(nextProps.item.jsonData)) };
        }
        return null;
    }

    toggleShowRepaymentSchedule = () => {
        this.setState({ showRepaymentSchedule: !this.state.showRepaymentSchedule, ownUpdate: true });
    };
    toggleShowDocModal = () => {
        this.setState({ showDocPatchModal: !this.state.showDocPatchModal, ownUpdate: true });
    };

    onFormSubmit(fields: loanDisbursementFields, onReloadFieldsCB: any, alt?): boolean {
        try {
            this.setState(
                { errors: [], ownUpdate: true },
                () => {
                    if (alt == "pushback") {
                        return this.submit(fields, onReloadFieldsCB, alt);
                    }
                    if (Object.keys(this.state.errors).length === 0) {
                        return this.submit(fields, onReloadFieldsCB);
                    }
                }
            );
            this.setState({ validated: true, ownUpdate: true });
            return false;
        } catch (error) {
            return false;
        }
    }

    updateApprovalItem = async (itemJSON: string, applicableAmount: number) => {
        try {
            const payload = this.props.item;
            payload.applicableAmount = applicableAmount;
            payload.jsonData = itemJSON;
            this.setState({ updateAvailable: true, ownUpdate: true })
            return true;
        } catch (error) {
            toast.error(error.response.data.Message);
            this.setState({ submitting: false, ownUpdate: true });
            return false;
        }
    };

    updateApprovalItemDocs = async (document: any) => {
        try {
            const docs = [...this.state.documentsToUpload];
            docs.push(document);
            this.setState({ documentsToUpload: docs, updateAvailable: true, ownUpdate: true });
        } catch (error) {
            // log
        }
    };

    toggleShowValidateAmountModal = (id) => {
        var members = this.props.values.solidarityGroups.filter(x => x.id === id)[0]?.members;
        this.setState({ showValidateAmount: !this.state.showValidateAmount, members: members });
    };

    deleteApprovalItemDocs = async (document: any, documentId: number) => {
        try {
            if (document != null) {
                const docs = [...this.state.documentsToUpload];
                let selectedDoc = docs.filter(doc => doc.document === document.document && doc.name === document.name)[0]
                docs.splice(docs.indexOf(selectedDoc), 1);
                this.setState({ documentsToUpload: docs, updateAvailable: true, ownUpdate: true });
            } else {
                const docs = [...this.state.documentsToDelete];
                docs.push(documentId);
                this.setState({ documentsToDelete: docs, updateAvailable: true, ownUpdate: true });
            }
        } catch (error) {
            // log
        }
    };

    async submit(
        fields: loanDisbursementFields,
        onReloadFieldsCB: any,
        alt?
    ): Promise<boolean> {
        try {
            this.setState({ submitting: true, ownUpdate: true });
            // const loanRequest = await loanRequestService.retrieveById(this.props.values.id);
            // const loanRequestDetail = loanRequest.data;
            // const payload: any = { ...loanRequestDetail };

            // const loanDetails: any = {};
            // payload.loanAmount = fields.loanAmount;
            // payload.tenureInDays = fields.tenureInDays;
            // payload.loanPurpose = fields.loanPurpose;
            // loanDetails.interestRate = Number(fields.interestRate);
            // loanDetails.repaymentFrequencyPrincipal = fields.repaymentFrequencyPrincipal;
            // loanDetails.repaymentFrequencyInterest = fields.repaymentFrequencyInterest;
            // loanDetails.dueDate = fields.dueDate;
            // loanDetails.moratorium = Number(fields.moratorium);
            // loanDetails.moratoriumPeriod = Number(fields.moratoriumPeriod);
            // loanDetails.impactOnTenor = fields.impactOnTenor;
            // loanDetails.chargeInterest = Number(fields.chargeInterest);
            // loanDetails.spreadInterestAcrossBalanceTenor = fields.spreadInterestAcrossBalanceTenor;
            // loanDetails.accountName = fields.accountName;
            // loanDetails.accountNumber = fields.accountNumber;
            // loanDetails.accountOfficer = fields.accountOfficer;
            // loanDetails.bankVerificationNumber = fields.bankVerificationNumber;
            // loanDetails.branch = fields.branch;
            // loanDetails.defaultingLoanInterest = Number(fields.defaultingLoanInterest);
            // loanDetails.economicSector = fields.economicSector;
            // loanDetails.securityDeposit = fields.securityDeposit;
            // loanDetails.disbursementMode = fields.disbursementMode;
            // loanDetails.typeOfLoan = fields.typeOfLoan;
            // loanDetails.preferredRepaymentBank = fields.preferredRepaymentBank;
            // loanDetails.preferredRepaymentAccount = fields.preferredRepaymentAccount;
            // loanDetails.charges = fields.charges;
            // payload.loanDetails = loanDetails;

            // const response = await loanRequestService.updateLoanRequest(payload);
            // if (response.status >= 200 && response.status <= 300) {
            //     toast.info(`Loan Details saved successfully!`, {
            //         autoClose: 6000,
            //         type: toast.TYPE.DEFAULT,
            //         hideProgressBar: false,
            //     });
            // } else {
            //     return false;
            // }

            const { item } = this.props;

            if (alt == "pushback") {
                // console.log("progressing action...");
                const currentUserSession = getCurrentUserSession();
                var payload: any = {};
                payload.loanRequestId = item.id
                payload.action = "PushBack"
                payload.itemLevel = item.currentLevel
                payload.userID = currentUserSession.userId
                payload.roleID = currentUserSession.userRoleId
                payload.systemProcessID = 0
                payload.errorMessage = ""
                payload.logType = "UserType"
                payload.comment = fields.comment
                payload.effectiveStatus = "Valid"
                payload.processResponse = null
                payload.IsGroupLoan = true;
                // console.log("Payload: ", payload)
                const response = await appraisalActionService.createAppraisalAction(payload)
                if (response.status >= 200 && response.status <= 300) {
                    // toggleAppraisalProcess()
                    activityTrailService.saveActivityTrail({
                        actionType: "Add Appraisal Action",
                        description: `Added new Appraisal Action`,
                    });
                    toast.info(`Loan Request Pushed Back to Offer Letter Screen!`, {
                        autoClose: 6000,
                        type: toast.TYPE.INFO,
                        hideProgressBar: false,
                    });
                } else {
                    return false;
                }
            } else {
                // console.log("progressing action...");
                const currentUserSession = getCurrentUserSession();
                var payload: any = {};
                payload.loanRequestId = item.id
                payload.action = "Approve"
                payload.itemLevel = item.currentLevel
                payload.userID = currentUserSession.userId
                payload.roleID = currentUserSession.userRoleId
                payload.systemProcessID = 0
                payload.errorMessage = ""
                payload.logType = "UserType"
                payload.comment = fields.comment == "" ? "Approved for Disbursement" : fields.comment
                payload.effectiveStatus = "Valid"
                payload.processResponse = null
                payload.IsGroupLoan = true;
                // console.log("Payload: ", payload)
                const response = await appraisalActionService.createAppraisalAction(payload)
                if (response.status >= 200 && response.status <= 300) {
                    // toggleAppraisalProcess()
                    activityTrailService.saveActivityTrail({
                        actionType: "Add Appraisal Action",
                        description: `Added new Appraisal Action`,
                    });
                    toast.info(`Loan has been Disbursed!`, {
                        autoClose: 6000,
                        type: toast.TYPE.INFO,
                        hideProgressBar: false,
                    });
                } else {
                    return false;
                }
            }


            this.setState({ submitting: false, ownUpdate: true });
            this.resetFields();
            // onReloadFieldsCB();
        } catch (error) {
            if (error.response.status === 409) {
                toast.error(error.response.data.detail, {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            } else if (error.response.status === 400) {
                toast.error(error.response.data.errors[0], {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            }
            this.setState({ submitting: false, ownUpdate: true });
            return false;
        } finally {
            return true;
        }
    }

    resetFields() {
        this.setState({ validated: false, submitting: false, errors: [], ownUpdate: true });
    }

    render() {
        const { values, systemProcess, userRoles } = this.props;
        const { errors, validated, item, productPricing } = this.state;
        const jsonLoanDetails = JSON.parse(JSON.parse(JSON.parse(values.jsonData)).LoanDetails);
        // console.log(values)
        // console.log(jsonLoanDetails)

        return (
            <LoanDisbursementForm
                initialValues={{
                    comment: "",
                    loanAmount: values.loanAmount,
                    tenureInDays: values.tenureInDays,
                    loanPurpose: values.loanPurpose,
                    interestRate: jsonLoanDetails?.InterestRate ? jsonLoanDetails.InterestRate.toString : 0,
                    repaymentFrequencyPrincipal: values.loanDetails?.repaymentFrequencyPrincipal ? values.loanDetails.repaymentFrequencyPrincipal : jsonLoanDetails.PrincipalPaymentFrequency,
                    repaymentFrequencyInterest: values.loanDetails?.repaymentFrequencyInterest ? values.loanDetails.repaymentFrequencyInterest : jsonLoanDetails.InterestPaymentFrequency,
                    dueDate: values.loanDetails?.dueDate ? values.loanDetails.dueDate : "",
                    moratorium: values.loanDetails?.moratorium ? values.loanDetails.moratorium : jsonLoanDetails.Moratarium,
                    moratoriumPeriod: values.loanDetails?.moratoriumPeriod ? values.loanDetails.moratoriumPeriod : "",
                    impactOnTenor: values.loanDetails?.impactOnTenor ? values.loanDetails.impactOnTenor : "",
                    chargeInterest: values.loanDetails?.chargeInterest ? values.loanDetails.chargeInterest : "",
                    spreadInterestAcrossBalanceTenor: values.loanDetails?.spreadInterestAcrossBalanceTenor ? values.loanDetails.spreadInterestAcrossBalanceTenor : "",
                    productCode: values.loanDetails?.productCode ? values.loanDetails.productCode : jsonLoanDetails.Product.Code,
                    accountName: values.loanDetails?.accountName ? values.loanDetails.accountName : jsonLoanDetails.Name,
                    accountNumber: values.loanDetails?.accountNumber ? values.loanDetails.accountNumber : jsonLoanDetails.AccountNumber,
                    accountOfficer: values.loanDetails?.accountOfficer ? values.loanDetails.accountOfficer : jsonLoanDetails.AccountOfficer,
                    bankVerificationNumber: values.loanDetails?.bankVerificationNumber ? values.loanDetails.bankVerificationNumber : jsonLoanDetails.BVN,
                    branch: values.loanDetails?.branch ? values.loanDetails.branch : jsonLoanDetails.Branch,
                    defaultingLoanInterest: values.loanDetails?.defaultingLoanInterest ? values.loanDetails.defaultingLoanInterest : jsonLoanDetails.DefaultingLoanInterest,
                    economicSector: textFormatService.splitAtUpper(
                        EconomicSector[jsonLoanDetails.EconomicSector]
                    ),
                    securityDeposit: values.loanDetails?.securityDeposit ? values.loanDetails.securityDeposit : jsonLoanDetails.SecurityDeposit,
                    disbursementMode: values.loanDetails?.disbursementMode ? values.loanDetails.disbursementMode : jsonLoanDetails.DisbursementMode,
                    typeOfLoan: textFormatService.splitAtUpper(
                        LoanComputationMode[jsonLoanDetails.ComputationMode]
                    ),
                    preferredRepaymentBank: values.loanDetails?.preferredRepaymentBank ? values.loanDetails.preferredRepaymentBank : jsonLoanDetails.PreferredRepaymentBankCBNCode,
                    preferredRepaymentAccount: values.loanDetails?.preferredRepaymentAccount ? values.loanDetails?.preferredRepaymentAccount : jsonLoanDetails.PreferredRepaymentAccount,
                    charges: values.loanDetails?.charges ? values.loanDetails.charges : [],
                    newLoanAmount: jsonLoanDetails?.LoanAmount
                }}
                FormComponent={({
                    fields: {
                        loanAmount,
                        tenureInDays,
                        loanPurpose,
                        interestRate,
                        repaymentFrequencyPrincipal,
                        repaymentFrequencyInterest,
                        dueDate,
                        moratorium,
                        moratoriumPeriod,
                        impactOnTenor,
                        chargeInterest,
                        spreadInterestAcrossBalanceTenor,
                        productCode,
                        accountName,
                        accountNumber,
                        accountOfficer,
                        bankVerificationNumber,
                        branch,
                        defaultingLoanInterest,
                        economicSector,
                        securityDeposit,
                        disbursementMode,
                        typeOfLoan,
                        preferredRepaymentBank,
                        preferredRepaymentAccount,
                        charges,
                        comment,
                        newLoanAmount
                    },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                }) => (
                    <div className="form-row">
                        <div className="card-header clear-fix">
                            <h6
                                className="card-title float-left"
                                style={{ marginBottom: "0px" }}
                            >
                                <b>Loan Disbursement Approval</b>
                            </h6>
                        </div>
                        <div className="col-md-12">

                            {/* Loan Information */}
                            <div className="card">
                                <div className="card-header clear-fix">
                                    <h6
                                        className="float-left"
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <b>Loan Information</b>
                                    </h6>
                                </div>
                                <div className="card-body">
                                    <div className="form-row">
                                        <FormInputComponent
                                            id="loanAmount"
                                            name="loanAmount"
                                            type="amount"
                                            amountDecimalPlaces={2}
                                            placeholder=""
                                            divClass={6}
                                            value={loanAmount}
                                            required={true}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                            disabled={true}
                                        />
                                        <FormInputComponent
                                            id="newLoanAmount"
                                            name="newLoanAmount"
                                            type="amount"
                                            amountDecimalPlaces={2}
                                            placeholder=""
                                            divClass={6}
                                            value={newLoanAmount}
                                            required={true}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                            disabled={true}
                                        />
                                        <FormInputComponent
                                            label="Loan Tenor (In Days)"
                                            id="tenureInDays"
                                            name="tenureInDays"
                                            type="number"
                                            placeholder=""
                                            divClass={6}
                                            value={tenureInDays}
                                            required={true}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Product Information */}
                            <div className="card mt-3">
                                <div className="card-header clear-fix">
                                    <h6
                                        className="float-left"
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <b>Product Information</b>
                                    </h6>
                                </div>
                                <div className="card-body">
                                    <div className="form-row">
                                        <FormInputComponent
                                            id="productName"
                                            name="productName"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={jsonLoanDetails.Product.Name}
                                            required={true}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                            disabled={true}
                                        />
                                        <FormInputComponent
                                            id="productCode"
                                            name="productCode"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={productCode}
                                            required={true}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                            disabled={true}
                                        />
                                        <FormInputComponent
                                            id="productType"
                                            name="productType"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={textFormatService.splitAtUpper(
                                                ProductType[jsonLoanDetails.Product.ProductType]
                                            )}
                                            required={true}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                            disabled={true}
                                        />
                                        <FormInputComponent
                                            id="financialGroup"
                                            name="financialGroup"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={values.financialGroup.name}
                                            required={true}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Account Information */}
                            <div className="card mt-3">
                                <div className="card-header clear-fix">
                                    <h6
                                        className="float-left"
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <b>Account Information</b>
                                    </h6>
                                </div>
                                <div className="card-body">
                                    <div className="form-row">
                                        <FormInputComponent
                                            label="Group Account Name"
                                            id="accountName"
                                            name="accountName"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={accountName}
                                            required={true}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                            disabled={true}
                                        />
                                        <FormInputComponent
                                            label="Group Account Number"
                                            id="accountNumber"
                                            name="accountNumber"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={accountNumber}
                                            required={true}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                            disabled={true}
                                        />
                                        <FormInputComponent
                                            id="accountOfficer"
                                            name="accountOfficer"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={accountOfficer}
                                            required={true}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                            disabled={true}
                                        />
                                        <FormInputComponent
                                            id="bankVerificationNumber"
                                            name="bankVerificationNumber"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={bankVerificationNumber}
                                            required={true}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                            disabled={true}
                                        />
                                        <FormInputComponent
                                            id="branch"
                                            name="branch"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={branch}
                                            required={true}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                            disabled={true}
                                        />
                                        <FormInputComponent
                                            id="defaultingLoanInterest"
                                            name="defaultingLoanInterest"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={defaultingLoanInterest}
                                            required={true}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                            disabled={true}
                                        />
                                        <FormInputComponent
                                            id="preferredRepaymentBank"
                                            name="preferredRepaymentBank"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={preferredRepaymentBank}
                                            required={true}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                            disabled={true}
                                        />
                                        <FormInputComponent
                                            id="preferredRepaymentAccount"
                                            name="preferredRepaymentAccount"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={preferredRepaymentAccount}
                                            required={true}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Charges */}
                            <div className="card mt-3">
                                <div className="card-header clear-fix">
                                    <h6
                                        className="float-left"
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <b>Charges</b>
                                    </h6>
                                </div>
                                <div className="card-body">
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <div
                                                className="table-responsize text-nowrap"
                                                style={{ height: "150px", overflowY: "scroll" }}
                                            >
                                                <table className="table table-hover table-content table-sm table-striped mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                style={{
                                                                    width: "5px",
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                }}
                                                            >
                                                                <strong>#</strong>
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                }}
                                                            >
                                                                <strong>Name</strong>
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                }}
                                                            >
                                                                <strong>Amount</strong>
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                }}
                                                            ></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* {console.log(productPricing)} */}
                                                        {_.isEmpty(productPricing) ||
                                                            productPricing.length === 0 ? (
                                                            <tr>
                                                                <td
                                                                    className="font-weight-normal text-truncate"
                                                                    colSpan={4}
                                                                    style={{
                                                                        textAlign: "center",
                                                                        color: "#FE825E",
                                                                        fontSize: "small",
                                                                    }}
                                                                >
                                                                    ...No Charge available
                                                                </td>
                                                            </tr>
                                                        ) : (

                                                            productPricing?.map((charge, id) => (
                                                                <tr key={id}>
                                                                    <td
                                                                        style={{
                                                                            width: "5px",
                                                                            paddingTop: "0.2rem",
                                                                            paddingBottom: "0.2rem",
                                                                            fontSize: "small",
                                                                        }}
                                                                    >
                                                                        {id + 1}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            paddingTop: "0.2rem",
                                                                            paddingBottom: "0.2rem",
                                                                            fontSize: "small",
                                                                        }}
                                                                    >
                                                                        {charge.name}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            paddingTop: "0.2rem",
                                                                            paddingBottom: "0.2rem",
                                                                            fontSize: "small",
                                                                        }}
                                                                    >
                                                                        {
                                                                            charge.feeType === "Percentage" ? textFormatService.formatCurrency(((charge.value / 100) * (this.props.values.loanAmount / 100)), 2) :
                                                                                textFormatService.formatCurrency(charge.value, 2)}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Fragment>
                                <Accordion defaultActiveKey="0">
                                    <div>
                                        <Card>
                                            <ContextAwareToggle
                                                eventKey={`0`}
                                                header={<React.Fragment>
                                                    <i
                                                        className="fa fa-info-circle"
                                                        aria-hidden="true"
                                                        style={{ color: "#878a8a" }}
                                                    ></i>{" "}
                                                    Appraisal Actions
                                                </React.Fragment>}
                                            ></ContextAwareToggle>
                                            <Accordion.Collapse eventKey={`0`}>
                                                <Card.Body>
                                                    <div
                                                        className="table-responsive text-nowrap"
                                                        style={{
                                                            height: "300px",
                                                            overflowY: "scroll",
                                                        }}
                                                    >

                                                        {/* <h6 className="center">Appraisal Actions</h6> */}
                                                        <table className="table table-hover table-content table-sm table-striped mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th
                                                                        scope="col"
                                                                        style={{
                                                                            width: "5px",
                                                                            paddingTop: "0.2rem",
                                                                            paddingBottom: "0.2rem",
                                                                        }}
                                                                    >
                                                                        <strong>Stage</strong>
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        style={{
                                                                            paddingTop: "0.2rem",
                                                                            paddingBottom: "0.2rem",
                                                                        }}
                                                                    >
                                                                        <strong>Decision</strong>
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        style={{
                                                                            // width: "5px",
                                                                            paddingTop: "0.2rem",
                                                                            paddingBottom: "0.2rem",
                                                                        }}
                                                                    >
                                                                        <strong>Comment</strong>
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        style={{
                                                                            // width: "5px",
                                                                            paddingTop: "0.2rem",
                                                                            paddingBottom: "0.2rem",
                                                                        }}
                                                                    >
                                                                        <strong>User</strong>
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        style={{
                                                                            // width: "5px",
                                                                            paddingTop: "0.2rem",
                                                                            paddingBottom: "0.2rem",
                                                                        }}
                                                                    >
                                                                        <strong>Date</strong>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {/* {recipients.length === 0 ? ( */}
                                                                {values?.appraisalActions?.length === 0 ? (
                                                                    <React.Fragment>
                                                                        <tr>
                                                                            No Decisions Made yet
                                                                        </tr>
                                                                    </React.Fragment>
                                                                ) : (
                                                                    values?.appraisalActions?.map((action) => (
                                                                        <React.Fragment key={generateGUID()}>
                                                                            <tr>
                                                                                <td
                                                                                    style={{
                                                                                        width: "5px",
                                                                                        paddingTop: "0.2rem",
                                                                                        paddingBottom: "0.2rem",
                                                                                        fontSize: "small",
                                                                                    }}
                                                                                >
                                                                                    {action.itemLevel === Number(0) ? "Detailed Data Entry" :
                                                                                        (action.logType === "UserType" ? item?.financialGroup?.levels?.filter(x => x.level === action.itemLevel)[0]?.name :

                                                                                            //   action.itemLevel === Number(0) ? "Data Validation" :
                                                                                            //     action.itemLevel === Number(100) ? "Document Generation" :
                                                                                            //       action.itemLevel === Number(101) ? "Post Approval Document Collection" :
                                                                                            //         action.itemLevel === Number(102) ? "Loan Disbursement Initiation" :
                                                                                            //           action.itemLevel === Number(103) ? "Loan Disbursement Approval" 

                                                                                            action.logType === "SystemProcess" ? systemProcess?.filter(x => x.id === Number(item?.financialGroup?.rules?.filter(x => x.level === action.itemLevel && x.approverType === "SystemProcess")[0]?.systemProcessID))[0]?.name
                                                                                                :
                                                                                                action.systemProcessID === Number(2) ? "Credit Policy" : action.systemProcessID === Number(5) ? "Credit Score Card" : systemProcess?.filter(x => x.id === Number(action.systemProcessID))[0]?.name)
                                                                                    }
                                                                                </td>
                                                                                <td
                                                                                    style={{
                                                                                        paddingTop: "0.2rem",
                                                                                        paddingBottom: "0.2rem",
                                                                                        fontSize: "small",
                                                                                    }}
                                                                                >
                                                                                    {action.action === "Submit" || action.action === "Processed" || action.action === "Approve" ? "Completed" : action.action}
                                                                                </td>
                                                                                <td
                                                                                    style={{
                                                                                        paddingTop: "0.2rem",
                                                                                        paddingBottom: "0.2rem",
                                                                                        fontSize: "small",
                                                                                    }}
                                                                                >
                                                                                    {/* {action.comment === "Done with data validation" || action.comment === "Ok to proceed" || action.comment === "Done with data entry" ? "Completed" : action.comment} */}
                                                                                    {action.comment}
                                                                                </td>
                                                                                <td
                                                                                    style={{
                                                                                        paddingTop: "0.2rem",
                                                                                        paddingBottom: "0.2rem",
                                                                                        fontSize: "small",
                                                                                    }}
                                                                                >
                                                                                    {action.logType === "SystemType" ? "System" : action?.user?.firstName}{" "}{action?.user?.lastName}
                                                                                </td>
                                                                                <td
                                                                                    style={{
                                                                                        paddingTop: "0.2rem",
                                                                                        paddingBottom: "0.2rem",
                                                                                        fontSize: "small",
                                                                                    }}
                                                                                >
                                                                                    {textFormatService.formatTimeString(action.dateCreated)}
                                                                                </td>
                                                                            </tr>
                                                                            {/* ))} */}
                                                                        </React.Fragment>
                                                                    ))
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </div>
                                </Accordion>
                            </Fragment>

                            <div className="card mt-3">
                                <div className="card-header clear-fix">
                                    <h6
                                        className="float-left"
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <b>Loan Details - Group Members</b>
                                    </h6>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        {/* <div className="col-md-12"> */}
                                        {_.isEmpty(values) ? (
                                            <Skeleton count={7} width={300} />
                                        ) : (
                                            values?.solidarityGroups?.map((groups) => (
                                                <React.Fragment>
                                                    <button
                                                        className="btn btn-sm btn-outline-primary my-2 mx-3"
                                                        style={{ cursor: "pointer" }}
                                                        type="button"
                                                        // onClick={this.handleUploadDocument}
                                                        onClick={(e) => { this.toggleShowValidateAmountModal(groups.id); }}
                                                    >
                                                        <React.Fragment>
                                                            <i className="fas fa-users fa-lg mr-2" />{" "}
                                                            {groups.solidarityGroupName}
                                                        </React.Fragment>
                                                    </button>
                                                </React.Fragment>
                                            ))
                                        )}
                                        {/* </div> */}
                                    </div>

                                </div>
                            </div>

                            {/* Loan Details Action */}
                            <div className="form-row">
                                <div className="my-1 mx-2">
                                    {/* <button
                                        type="submit"
                                        className="btn btn-sm btn-primary"
                                        disabled={this.state.submitting}
                                        onClick={(e) => {
                                            onHandleSubmit(
                                                e,
                                                ActionTypes.SAVE,
                                                "Loan Details",
                                                () => {
                                                    this.onFormSubmit(
                                                        {
                                                            loanAmount,
                                                            tenureInDays,
                                                            loanPurpose,
                                                            interestRate,
                                                            repaymentFrequencyPrincipal,
                                                            repaymentFrequencyInterest,
                                                            dueDate,
                                                            moratorium,
                                                            moratoriumPeriod,
                                                            impactOnTenor,
                                                            chargeInterest,
                                                            spreadInterestAcrossBalanceTenor,
                                                            productCode,
                                                            accountName,
                                                            accountNumber,
                                                            accountOfficer,
                                                            bankVerificationNumber,
                                                            branch,
                                                            defaultingLoanInterest,
                                                            economicSector,
                                                            securityDeposit,
                                                            disbursementMode,
                                                            typeOfLoan,
                                                            preferredRepaymentBank,
                                                            preferredRepaymentAccount,
                                                            charges
                                                        },
                                                        onReloadFields
                                                    );
                                                }
                                            );
                                        }}
                                    > */}
                                    {/* {this.state.submitting === false ? (
                                            <React.Fragment>
                                                <i className="fas fa-lg fa-save mr-3" /> Save
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                                Please wait...
                                            </React.Fragment>
                                        )}
                                    </button> */}
                                    <button
                                        className="btn btn-sm btn-outline-primary my-2 mx-2"
                                        style={{ cursor: "pointer" }}
                                        type="button"
                                        onClick={() => { this.toggleShowRepaymentSchedule() }}
                                    >
                                        <React.Fragment>
                                            <i className="fas fa-receipt fa-lg mr-2" />{" "}
                                            View Repayment Schedule
                                        </React.Fragment>
                                    </button>
                                    <button
                                        className="btn btn-sm btn-outline-primary my-2 mx-2"
                                        style={{ cursor: "pointer" }}
                                        type="button"
                                        onClick={() => { this.toggleShowDocModal() }}
                                    >
                                        <React.Fragment>
                                            <i className="fas fa-receipt fa-lg mr-2" />{" "}
                                            View Supporting Document
                                        </React.Fragment>
                                    </button>
                                    {/* <button
                                        className="btn btn-sm btn-outline-primary my-2 mx-2"
                                        style={{ cursor: "pointer" }}
                                        type="button"
                                        onClick={() => { this.toggleShowRepaymentSchedule() }}
                                    >
                                        <React.Fragment>
                                            <i className="fas fa-receipt fa-lg mr-2" />{" "}
                                            Approve Disbursement
                                        </React.Fragment>
                                    </button> */}
                                </div>
                            </div>
                            {/* <div className="card-body"> */}
                            {/* <div className="my-5">
                                <dl className="row">
                                    <FormTextAreaComponent
                                        id="comment"
                                        type="text"
                                        name="comment"
                                        placeholder=""
                                        divClass={12}
                                        value={comment}
                                        required={false}
                                        validated={true}
                                        errors={{}}
                                        onChange={onChange}
                                        disabled={false} />
                                </dl>
                                <button
                                    type="submit"
                                    className="btn btn-sm btn-secondary"
                                    disabled={this.state.submitting}
                                    onClick={(e) => {
                                        onHandleSubmit(
                                            e,
                                            ActionTypes.PUSHBACK,
                                            "Loan Application",
                                            () => {
                                                this.onFormSubmit({
                                                    comment, loanAmount, tenureInDays, loanPurpose, interestRate, repaymentFrequencyPrincipal,
                                                    repaymentFrequencyInterest, dueDate, moratorium, moratoriumPeriod, impactOnTenor, chargeInterest,
                                                    spreadInterestAcrossBalanceTenor, productCode, accountName, accountNumber, accountOfficer, bankVerificationNumber,
                                                    branch, defaultingLoanInterest, economicSector, securityDeposit, disbursementMode, typeOfLoan,
                                                    preferredRepaymentBank, preferredRepaymentAccount, charges, newLoanAmount
                                                }, onReloadFields, "pushback");
                                            }
                                        );
                                    }}
                                >
                                    {this.state.submitting === false ? (
                                        <React.Fragment>
                                            Refer Back
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                            Please wait...
                                        </React.Fragment>
                                    )}
                                </button>
                                <div className="float-right">
                                    <button
                                        type="submit"
                                        className="btn btn-sm btn-primary"
                                        disabled={this.state.submitting}
                                        onClick={(e) => {
                                            onHandleSubmit(
                                                e,
                                                ActionTypes.APPROVE,
                                                "DISBURSEMENT",
                                                () => {
                                                    this.onFormSubmit({
                                                        comment, loanAmount, tenureInDays, loanPurpose, interestRate, repaymentFrequencyPrincipal,
                                                        repaymentFrequencyInterest, dueDate, moratorium, moratoriumPeriod, impactOnTenor, chargeInterest,
                                                        spreadInterestAcrossBalanceTenor, productCode, accountName, accountNumber, accountOfficer, bankVerificationNumber,
                                                        branch, defaultingLoanInterest, economicSector, securityDeposit, disbursementMode, typeOfLoan,
                                                        preferredRepaymentBank, preferredRepaymentAccount, charges, newLoanAmount
                                                    }, onReloadFields);
                                                }
                                            );
                                        }}
                                    >
                                        {this.state.submitting === false ? (
                                            <React.Fragment>
                                                <i className="fas fa-lg fa-save mr-3" /> Approve Disbursement
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                                Please wait...
                                            </React.Fragment>
                                        )}
                                    </button>
                                </div>
                            </div> */}

                            {/* <RepaymentSchedule
                                item={(JSON.parse(JSON.parse(values.jsonData)))}
                                showPatchModal={this.state.showRepaymentSchedule}
                                togglePatchModal={this.toggleShowRepaymentSchedule}
                            /> */}
                            <GroupRepayment
                                item={values}
                                showPatchModal={this.state.showRepaymentSchedule}
                                togglePatchModal={this.toggleShowRepaymentSchedule}
                                values={values.loanDetails}
                            />
                            <SupportingDocuments
                                item={item}
                                values={this.props.values}
                                updateItemDocs={(document: any) => this.props.updateItemDocs(document)}
                                deleteItemDocs={(document: any, documentId: number) => this.props.deleteItemDocs(document, documentId)}
                                tempDocs={this.props.tempDocs}
                                tempDeletedDocs={this.props.tempDeletedDocs}
                                showDocumentsModal={this.state.showDocPatchModal}
                                toggleDocumentsModal={(reloadDetails: boolean, document: any) => {
                                    this.toggleShowDocModal();
                                }}
                            />
                            <ValidateAmountModal
                                item={this.state.item}
                                members={this.state.members}
                                showPatchModal={this.state.showValidateAmount}
                                togglePatchModal={this.toggleShowValidateAmountModal}
                            />

                        </div>
                    </div>
                )}
            ></LoanDisbursementForm>
        );
    }
}

export default LoanDisbursement;

