import React, { Component, Fragment, useContext } from "react";
import {
  Button,
  Card,
  useAccordionToggle,
  AccordionContext,
  Accordion,
} from "react-bootstrap";
import _ from "lodash";
import { getCurrentUserSession } from "../../../../services/auth/authService";
import DetailItem from "../../../../components/detailItem";
import { toast } from "react-toastify";
import BaseFormComponent from "../../../../components/BaseFormComponent";
import { ActionTypes } from "../../../../enums/actionTypes";
import loanRequestService from "../../../../services/hybridAppraisal/loanRequestService";
import BaseListComponent, { IFilterData } from "../../../../components/BaseListComponent";
import { createFilterData, mapEntityList } from "../../../../services/utility/autoMapperService";
import { filterFieldData } from "../../../../components/tableComponents/itemsTable";
import FormTextAreaComponent from "../../../../components/formTextAreaComponent";
import appraisalActionService from "../../../../services/hybridAppraisal/appraisalActionService";

export function ContextAwareToggle({ eventKey, header }) {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      <b>{header}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
}

interface ExternalCreditAssessmentReviewFields {
  comment: string;
}

class ExternalCreditAssessmentReviewForm extends BaseFormComponent<ExternalCreditAssessmentReviewFields> { }

interface IExternalCreditAssessmentReviewProps {
  item: any;
  systemProcess: any;
  userRoles: any;
}

interface IExternalCreditAssessmentReviewState {
  item: any;
  updatingStatus: boolean;
  submitting: boolean;
  data: IFilterData;
  currentPage: number;
  tableItems: any;
  fetching: boolean;
  fetchingDetail: boolean;
  totalSize: number;
  filterValueInput: filterFieldData;
}

class ExternalCreditAssessmentList extends BaseListComponent<IExternalCreditAssessmentReviewState> { }

class ExternalCreditAssessmentReview extends Component<IExternalCreditAssessmentReviewProps, IExternalCreditAssessmentReviewState> {
  constructor(props: IExternalCreditAssessmentReviewProps) {
    super(props);
    this.state = {
      item: {},
      updatingStatus: false,
      submitting: false,
      data: createFilterData([]) as IFilterData,
      currentPage: 1,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: []
    };
  }

  getActionTypeString = (actionType) => {
    switch (actionType) {
      case 2:
        return "Update";
      case 6:
        return "Approve";
      case 7:
        return "Decline";
      case 8:
        return "PushBack";
      case 9:
        return "Recall";
      default:
        return "";
    }
  };

  onFormSubmit(fields: ExternalCreditAssessmentReviewFields, actionType): boolean {
    try {
      var action = this.getActionTypeString(actionType)
      this.setState(
        {},
        () => {
          return this.submit(fields, action);
        }
      );
      this.setState({});
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: ExternalCreditAssessmentReviewFields,
    action
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true });
      const payload: any = {};
      var curDT = new Date().toLocaleString()

      payload.action = action;
      payload.loanRequestId = this.props.item.id
      payload.systemProcessID = Number(0);
      payload.userID = getCurrentUserSession().userId;
      payload.roleID = getCurrentUserSession().userRoleId;
      payload.itemLevel = this.props.item.currentLevel;
      payload.errorMessage = "";
      payload.logType = "UserType";
      payload.comment = fields.comment;
      payload.effectiveStatus = "Valid";
      payload.id = Number(0);
      payload.processResponse = {
        "actionId": 0,
        "score": 0,
        "result": "string",
        "response": "string",
        "id": 0,
        "dateCreated": curDT,
        "dateUpdated": curDT
      };
      payload.dateCreated = curDT;
      payload.dateUpdated = curDT;

      // console.log("payload: ", payload);
      const response = await appraisalActionService.createAppraisalAction(payload);
      if (response.status >= 200 && response.status <= 300) {
        toast.info(`Validation Initiated successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false });
    }
    catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
    } finally {
      this.setState({ submitting: false });
      return true;
    }
  }

  handlePageChange = async (currentPage: number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    this.setState({ fetching: true });
    let dataClone = typeof data === "undefined" ? { ...this.state.data } : { ...data };
    try {
      const loanRequests = await loanRequestService.filterLoanRequests(
        {filters: dataClone, accessibleBranchCodes: null},
        Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
        currentPage
      );
      this.setState({
        tableItems: mapEntityList({
          entityList: loanRequests.data.item2,
          properties: [
            { oldName: "customerName", newName: "Name" },
            { oldName: "phonenumber", newName: "phoneNumber" },
            { oldName: "gender", newName: "gender" },
            { oldName: "gender", newName: "gender" },
          ],
        }),
        currentPage,
        totalSize: loanRequests.data.item1,
        fetching: false,
      });
    } catch (ex) {
      if (ex.response && ex.response.status >= 400 && ex.response.status <= 499) {
        this.setState({ fetching: false });
      } else {
        this.setState({ fetching: false });
      }
    } finally {
      this.setState({ fetching: false });
    }
  };
  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  handleOnExport = async (type: string) => { };

  static getDerivedStateFromProps(nextProps: IExternalCreditAssessmentReviewProps, prevState) {
    return {
      item: nextProps.item,
    };
  }

  render() {
    const {
      item,
      userRoles,
      systemProcess
    } = this.props;

    return (
      <div className="container-fluid relative animatedParent animateOnce">
        <div className="animated fadeInUpShort go">
          <div className="row">
            <div className="col-md-12">
              <div className="form-row">
                <div className="col-md-12">
                  <div className="row my-3 mx-2">
                    <h3>
                      <b>External Credit Assessment Review</b>
                    </h3>
                  </div>
                  <div className="card">
                    <Accordion defaultActiveKey="0">
                      <div>
                        <Card>
                          <ContextAwareToggle
                            eventKey={`0`}
                            header={<React.Fragment>
                              <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                                style={{ color: "#878a8a" }}
                              ></i>{" "}
                              Applicant Details
                            </React.Fragment>}
                          ></ContextAwareToggle>
                          <Accordion.Collapse eventKey={`0`}>
                            <Card.Body>
                              <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className="col-md-6">
                                    <dl className="row">
                                      <DetailItem
                                        label="Customer Name"
                                        labelSize={5}
                                        valueSize={7}
                                        value={item.customerName} />
                                      <DetailItem
                                        label="Date of Receipt"
                                        labelSize={5}
                                        valueSize={7}
                                        value={item.dateCreated} />
                                      <DetailItem
                                        label="Loan Amount"
                                        labelSize={5}
                                        valueSize={7}
                                        value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).LoanAmountToNaira} />
                                      <DetailItem
                                        label="Loan Purpose"
                                        labelSize={5}
                                        valueSize={7}
                                        value="House Rent" />
                                    </dl>
                                  </div>
                                  <div className="col-md-6">
                                    <dl className="row">
                                      <DetailItem
                                        label="Loan ID"
                                        labelSize={5}
                                        valueSize={7}
                                        value={item.id} />
                                      <DetailItem
                                        label="Loan Product"
                                        labelSize={5}
                                        valueSize={7}
                                        // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                        value="Good Migo Borrower" />
                                      <DetailItem
                                        label="Loan Tenor"
                                        labelSize={5}
                                        valueSize={7}
                                        value={item.tenureInDays} />
                                      <DetailItem
                                        label="Account Officer"
                                        labelSize={5}
                                        valueSize={7}
                                        value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).AccountOfficer} />
                                    </dl>
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </div>
                    </Accordion>
                  </div>

                  <div className="card mt-3">
                    <Accordion defaultActiveKey="0">
                      <div>
                        <Card>
                          <ContextAwareToggle
                            eventKey={`0`}
                            header={<React.Fragment>
                              <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                                style={{ color: "#878a8a" }}
                              ></i>{" "}
                              Credit Assessment Result
                            </React.Fragment>}
                          ></ContextAwareToggle>
                          <Accordion.Collapse eventKey={`0`}>
                            <Card.Body>
                              <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className="col-md-6">
                                    <dl className="row">
                                      <DetailItem
                                        label="Scoring Status"
                                        labelSize={7}
                                        valueSize={5}
                                        value={item.appraisalActions.filter(x => x.systemProcessID === Number(5))[0]?.comment}
                                      />
                                    </dl>
                                  </div>
                                  <div className="col-md-6">
                                    <dl className="row">
                                      <DetailItem
                                        label="Application Score"
                                        labelSize={7}
                                        valueSize={3}
                                        value={item.appraisalActions.filter(x => x.systemProcessID === Number(1))[0]?.processResponse?.score}
                                      />
                                    </dl>
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </div>
                    </Accordion>
                  </div>

                  <div className="card mt-3">
                    <ExternalCreditAssessmentReviewForm
                      initialValues={{ comment: "" }}
                      FormComponent={({
                        fields: { comment }, onChange, onReloadFields, onHandleSubmit,
                      }) => (
                        <div className="card">
                          <Accordion defaultActiveKey="0">
                            <div>
                              <Card>
                                <ContextAwareToggle
                                  eventKey={`0`}
                                  header={<React.Fragment>
                                    <i
                                      className="fa fa-info-circle"
                                      aria-hidden="true"
                                      style={{ color: "#878a8a" }}
                                    ></i>{" "}
                                    Decision
                                  </React.Fragment>}
                                ></ContextAwareToggle>
                                <Accordion.Collapse eventKey={`0`}>
                                  <Card.Body>
                                    <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                      <div className="row">
                                        <div className="col-md-10">
                                          <dl className="row">
                                            <FormTextAreaComponent
                                              id="comment"
                                              type="text"
                                              name="comment"
                                              placeholder=""
                                              divClass={6}
                                              value={comment}
                                              required={false}
                                              validated={true}
                                              errors={{}}
                                              onChange={onChange}
                                              disabled={false} />
                                          </dl>
                                          <h6> Decision</h6>
                                          <React.Fragment>
                                            <><Button
                                              className="mr-3"
                                              size="sm"
                                              variant="success"
                                              onClick={(ActionTypes.APPROVE, () => {
                                                this.onFormSubmit(
                                                  { comment },
                                                  ActionTypes.APPROVE
                                                );
                                              }
                                              )}
                                            >
                                              {this.state.submitting === true ? (
                                                <React.Fragment>
                                                  <i className="fas fa-spinner fa-spin mr-3"></i>
                                                  Please Wait...
                                                </React.Fragment>
                                              ) : (
                                                <React.Fragment>
                                                  <i className="fas fa-check mr-3" />
                                                  Approve
                                                </React.Fragment>
                                              )}
                                            </Button>
                                              <Button
                                                className="mr-3"
                                                size="sm"
                                                variant="danger"
                                                onClick={(ActionTypes.DECLINE, () => {
                                                  this.onFormSubmit(
                                                    { comment },
                                                    ActionTypes.DECLINE
                                                  );
                                                }
                                                )}
                                              >
                                                {this.state.submitting === true ? (
                                                  <React.Fragment>
                                                    <i className="fas fa-spinner fa-spin mr-3"></i>
                                                    Please Wait...
                                                  </React.Fragment>
                                                ) : (
                                                  <React.Fragment>
                                                    <i className="fas fa-times mr-3" />
                                                    Decline
                                                  </React.Fragment>
                                                )}
                                              </Button>
                                              <Button
                                                className="mr-3"
                                                size="sm"
                                                variant="primary"
                                                onClick={(ActionTypes.PUSHBACK, () => {
                                                  this.onFormSubmit(
                                                    { comment },
                                                    ActionTypes.PUSHBACK
                                                  );
                                                }
                                                )}
                                              >
                                                {this.state.submitting === true ? (
                                                  <React.Fragment>
                                                    <i className="fas fa-spinner fa-spin mr-3"></i>
                                                    Please Wait...
                                                  </React.Fragment>
                                                ) : (
                                                  <React.Fragment>
                                                    <i className="fas fa-undo mr-3" />
                                                    Push Back
                                                  </React.Fragment>
                                                )}
                                              </Button>
                                            </>
                                          </React.Fragment>
                                        </div>
                                      </div>
                                    </div>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            </div>
                          </Accordion>
                        </div>
                      )}
                    >
                    </ExternalCreditAssessmentReviewForm>
                  </div>

                  <div className="card mt-3">
                    <Fragment>
                      <Accordion defaultActiveKey="0">
                        <div>
                          <Card>
                            <ContextAwareToggle
                              eventKey={`0`}
                              header={<React.Fragment>
                                <i
                                  className="fa fa-info-circle"
                                  aria-hidden="true"
                                  style={{ color: "#878a8a" }}
                                ></i>{" "}
                                Appraisal Actions
                              </React.Fragment>}
                            ></ContextAwareToggle>
                            <Accordion.Collapse eventKey={`0`}>
                              <Card.Body>
                                <div
                                  className="table-responsive text-nowrap"
                                  style={{
                                    height: "300px",
                                    overflowY: "scroll",
                                  }}
                                >

                                  <table className="table table-hover table-content table-sm table-striped mb-0">
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          style={{
                                            width: "5px",
                                            paddingTop: "0.2rem",
                                            paddingBottom: "0.2rem",
                                          }}
                                        >
                                          <strong>#</strong>
                                        </th>
                                        <th
                                          scope="col"
                                          style={{
                                            paddingTop: "0.2rem",
                                            paddingBottom: "0.2rem",
                                          }}
                                        >
                                          <strong>Decision</strong>
                                        </th>
                                        <th
                                          scope="col"
                                          style={{
                                            paddingTop: "0.2rem",
                                            paddingBottom: "0.2rem",
                                          }}
                                        >
                                          <strong>Comment</strong>
                                        </th>
                                        <th
                                          scope="col"
                                          style={{
                                            paddingTop: "0.2rem",
                                            paddingBottom: "0.2rem",
                                          }}
                                        >
                                          <strong>Username</strong>
                                        </th>
                                        <th
                                          scope="col"
                                          style={{
                                            paddingTop: "0.2rem",
                                            paddingBottom: "0.2rem",
                                          }}
                                        >
                                          <strong>Date</strong>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item.appraisalActions.length === 0 ? (
                                        <React.Fragment>
                                          <tr>
                                            No Decisions Made yet
                                          </tr>
                                        </React.Fragment>
                                      ) : (
                                        item.appraisalActions.map((action) => (
                                          <React.Fragment>
                                            <tr>
                                              <td
                                                style={{
                                                  width: "5px",
                                                  paddingTop: "0.2rem",
                                                  paddingBottom: "0.2rem",
                                                  fontSize: "small",
                                                }}
                                              >
                                              </td>
                                              <td
                                                style={{
                                                  paddingTop: "0.2rem",
                                                  paddingBottom: "0.2rem",
                                                  fontSize: "small",
                                                }}
                                              >
                                                {action.action}
                                              </td>
                                              <td
                                                style={{
                                                  paddingTop: "0.2rem",
                                                  paddingBottom: "0.2rem",
                                                  fontSize: "small",
                                                }}
                                              >
                                                {/* {console.log(systemProcess?.filter(x => x.id === Number(action.systemProcessID)))[0]?.name} */}
                                                {action.comment}
                                              </td>
                                              <td
                                                style={{
                                                  paddingTop: "0.2rem",
                                                  paddingBottom: "0.2rem",
                                                  fontSize: "small",
                                                }}
                                              >
                                                {action.logType === "SystemType" ? (systemProcess?.filter(x => x.id === Number(action.systemProcessID))[0]?.name) :
                                                  (userRoles.filter(x => x.id === Number(action.roleID))[0]?.name)}
                                              </td>
                                              <td
                                                style={{
                                                  paddingTop: "0.2rem",
                                                  paddingBottom: "0.2rem",
                                                  fontSize: "small",
                                                }}
                                              >
                                                {action.dateCreated}
                                              </td>
                                            </tr>
                                          </React.Fragment>
                                        ))
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </div>
                      </Accordion>
                    </Fragment>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ExternalCreditAssessmentReview;
