import React, { Component } from "react";

export interface ISaveUserApprovalDetailProps {
  item: any;
  title: string;
}

export interface ISaveUserApprovalDetailState {
  item: any;
}

class SaveUserApprovalDetail extends Component<
  ISaveUserApprovalDetailProps,
  ISaveUserApprovalDetailState
> {
  constructor(props: ISaveUserApprovalDetailProps) {
    super(props);
    this.state = {
      item: {},
    };
  }

  static getDerivedStateFromProps(
    nextProps: ISaveUserApprovalDetailProps,
    prevState
  ) {
    if (nextProps.item) {
      return { item: JSON.parse(nextProps.item) };
    }
    return null;
  }

  camelToWords = (str) =>
    str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
      return str.toUpperCase();
    });

  render() {
    var ShowFields = [
      //'Institution'
      //,'UserRole'
      //,'UserRoleId'
      // ,'Branch'
      //,'BranchId'
      "Username",
      "FirstName",
      "LastName",
      "OtherName",
      // "Title",
      // "DateOfBirth",
      "PhoneNo",
      "Address",
      "Email",
      "Gender",
      //'Token',
      //,'Scope'
      //,'PasswordRetries'
      //,'ActivationCount'
      //,'HasApprovalRights'
      //,'Id'
      "InstitutionId",
      "Status",
    ];
    var pwr = {
      paddingTop: "0.2rem",
      paddingBottom: "0.2rem",
      fontWeight: 500,
    };
    var light = { paddingTop: "0.2rem", paddingBottom: "0.2rem" };
    return (
      <React.Fragment>
        <div>
          <h6>{this.props.title} Approval Detail</h6>
        </div>
        <table className="table table-borderless">
          <tbody>
            {ShowFields.map((itm) => {
              return (
                <tr>
                  <th style={pwr} scope="row">
                    {this.camelToWords(itm)}
                  </th>
                  <td style={light}>{this.state.item[itm]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

export default SaveUserApprovalDetail;
