import * as React from "react";
import _ from "lodash";
import { Fragment } from "react";
import {
  IFilterData,
  BaseListComponentState,
} from "../../../components/BaseListComponent";
import {
  createFilterData,
  mapEntityList,
} from "../../../services/utility/autoMapperService";
import BaseListComponent from "../../../components/BaseListComponent";
import ItemsTable, {
  filterFieldData,
} from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "../../../components/tableComponents/tableFilterPane";
import financialGroupService from "../../../services/hybridAppraisal/financialGroupService";
import FinancialGroupDetail from "./financialGroupDetail";
import EditFinancialGroup from "./editFinancialGroup";
import rolesService from "../../../services/auth/rolesService";
import appraisalInstitutionService from "../../../services/hybridAppraisal/appraisalInstitutionService";
import appraisalPlanService from "../../../services/hybridAppraisal/appraisalPlanService";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../../services/auth/authService";

export interface FinancialGroupsProps { }

export interface FinancialGroupsState extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
  allItems: any;
  showPatchModal: boolean;
  userRoles: any;
  systemProcess: any;
}

class FinancialGroupsList extends BaseListComponent<FinancialGroupsState> { }

class FinancialGroups extends React.Component<
  FinancialGroupsProps,
  FinancialGroupsState
> {
  _isMounted = false;
  constructor(props: FinancialGroupsProps) {
    super(props);
    this.state = {
      data: createFilterData([]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
      showPatchModal: false,
      allItems: [],
      userRoles: [],
      systemProcess: []
    };
  }

  handlePageChange = async (currentPage: number, pageSize: number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true });
    let dataClone =
      typeof data === "undefined" ? { ...this.state.data } : { ...data };
    try {
      const financialGroups = await financialGroupService.filterFinancialGroup(
        dataClone,
        pageSize,
        currentPage
      );
      this.setState({
        tableItems: mapEntityList({
          entityList: financialGroups.data.item2,
          properties: [
            { oldName: "name", newName: "workFlowConfigName" },
            { oldName: "minimumAmount", newName: "minimumAmount" },
            { oldName: "maximumAmount", newName: "maximumAmount" },
            { oldName: "dateCreated", newName: "dateCreated" },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: financialGroups.data.item1,
        fetching: false,
      });
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        this.setState({ fetching: false });
      } else {
        this.setState({ fetching: false });
      }
    }
  };

  handleOnExport = async (type: string) => { };

  fetchItemById = async (item: any) => {
    this.setState({ fetchingDetail: true });
    const financialGroup = await financialGroupService.retrieveById(item.id);
    // console.log(financialGroup.data)
    this.setState({ item: financialGroup.data, fetchingDetail: false });
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this._isMounted = true;
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    const response = await Promise.allSettled([
      rolesService.retrieveAllRoles(),
      appraisalInstitutionService.retrieveByInstitutionCode(decodedToken?.["InstitutionCode"])
    ])
    const userRoles = response[0].status == "fulfilled" ? response[0].value.data : [];
    const appraisalInstitution = response[1].status == "fulfilled" ? response[1].value.data : [];
    const appraisalPlan = await appraisalPlanService.retrieveById(appraisalInstitution.planId);

    // retrieve report and validation assigned to the appraisal plan institution
    let reports = appraisalPlan.data.reports
    reports = reports.map(obj => ({ ...obj, processType: 'Report' }))
    let validations = appraisalPlan.data.validations
    validations = validations.map(obj => ({ ...obj, processType: 'Validation' }))
    const systemProcess = [...reports, ...validations];
    if (this._isMounted) {
      this.setState({
        userRoles: userRoles,
        systemProcess: systemProcess
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Workflow Configs</b>
              </h3>
            </div>
            <FinancialGroupsList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              initialValues={{
                data: createFilterData([]) as IFilterData,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: this.state.filterValueInput,
                item: {},
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                onToggleEditModal,
                showDetailsModal,
                showEditModal,
              }) => (
                <Fragment>
                  <FinancialGroupDetail
                    showDetailsModal={showDetailsModal}
                    toggleDetailsModal={onToggleDetailsModal}
                    toggleEditModal={onToggleEditModal}
                    item={item}
                    userRoles={this.state.userRoles}
                    systemProcess={this.state.systemProcess}
                  />
                  <EditFinancialGroup
                    showEditModal={showEditModal}
                    toggleEditModal={(reloadDetails: boolean, item: any) => {
                      onToggleEditModal();
                      if (reloadDetails === true) {
                        this.fetchItemById(item);
                      }
                      this.setState({
                        showDetailsModal: !this.state.showDetailsModal,
                      });
                    }}
                    item={item}
                    userRoles={this.state.userRoles}
                    systemProcess={this.state.systemProcess}
                  />
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="Financial Group"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                              timeColumn={["dateCreated"]}
                              amountColumn={["minimumAmount", "maximumAmount"]}
                              amountInNaira={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></FinancialGroupsList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default FinancialGroups;
