import React, { Component, useContext, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import {
  Modal,
  Button,
  AccordionContext,
  useAccordionToggle,
  Card,
  Accordion,
} from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import { ActionTypes } from "../../../enums/actionTypes";
import swal from "sweetalert";
import { toast } from "react-toastify";
import institutionManagementService from "../../../services/creditAssessment/institutionManagementService";

interface IInstitutionSetupDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  togglePatchModal: any;
  showTrail: any;
  currentUserRoles: any;
  institution: any;
  feeConfig: any;
  indicators: any;
}

interface IInstitutionSetupDetailState {
  institution: any;
  feeConfig: any;
  indicators: any;
  updatingStatus: boolean;
}

function ContextAwareToggle({ eventKey, header }) {
  const currentEventKey = useContext(AccordionContext);

  const toggleOnClick = useAccordionToggle(eventKey);

  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";

  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      <b>{header}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
}

class InstitutionSetupDetail extends Component<
  IInstitutionSetupDetailProps,
  IInstitutionSetupDetailState
> {
  constructor(props: IInstitutionSetupDetailProps) {
    super(props);
    this.state = {
      institution: {},
      feeConfig: {},
      indicators: [],
      updatingStatus: false,
    };
  }

  static getDerivedStateFromProps(
    nextProps: IInstitutionSetupDetailProps,
    prevState
  ) {
    return {
      institution: nextProps.institution,
      feeConfig: nextProps.feeConfig,
      indicators: nextProps.indicators,
    };
  }

  onHandleEnableOrDisable = (
    e: any,
    actionType: ActionTypes,
    entityName: string
  ) => {
    this.setState({ updatingStatus: true });
    if (!actionType) {
      this.handleEnableOrDisableItem(this.props.institution);
    } else {
      swal({
        title: "Confirm!",
        text: `Are you sure you want to proceed to ${ActionTypes[actionType]} this ${entityName}?`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode: true,
      }).then((resp) => {
        if (resp) {
          // console.log("cancelling action...");
          this.setState({ updatingStatus: false });
        } else {
          this.handleEnableOrDisableItem(this.state.institution);
        }
      });
    }
  };

  handleEnableOrDisableItem = async (_item: any) => {
    try {
      let approvalResponse: any = {};
      this.setState({ updatingStatus: true });
      //   await activityTrailService.saveActivityTrail({
      //     actionType: `${
      //       _item.isActive === "Inactive" ? "Enable" : "Disable"
      //     } Institution Configu`,
      //     description: `Clicked on ${
      //       _item.isActive === "Inactive" ? "Enable" : "Disable"
      //     } to ${
      //       _item.isActive === "Inactive" ? "Enable" : "Disable"
      //     } Institution Config ${_item.name}`,
      //   });
      switch (_item.isActive) {
        case "Inactive":
          approvalResponse = await institutionManagementService.activateInstitution(
            _item.institutionCode
          );
          if (approvalResponse.data.isLoggedForApproval === false) {
            _item.isActive = "Active";
          }
          break;
        case "Active":
          approvalResponse = await institutionManagementService.deactivateInstitution(
            _item.institutionCode
          );
          if (approvalResponse.data.isLoggedForApproval === false) {
            _item.isActive = "Inactive";
          }
          break;
        default:
          approvalResponse = await institutionManagementService.activateInstitution(
            _item.institutionCode
          );
          if (approvalResponse.data.isLoggedForApproval === false) {
            _item.isActive = "Active";
          }
          break;
      }
      if (approvalResponse.data.isLoggedForApproval === true) {
        toast.warn(approvalResponse.data.message, {
          autoClose: 6000,
          type: toast.TYPE.WARNING,
          position: "top-center",
          hideProgressBar: false,
        });
      } else {
        toast.info(
          `Institution ${_item.institutionName} ${_item.isActive === "Active" ? "Actived" : "Deactivated"
          } successfully! `,
          {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          }
        );
      }
      this.setState({ updatingStatus: false, institution: _item });
    } catch (ex) {
      this.setState({ updatingStatus: false, institution: _item });
    }
  };

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      togglePatchModal,
      showTrail,
      currentUserRoles,
      institution,
      feeConfig,
      indicators,
    } = this.props;
    const { updatingStatus } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(institution) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="fab fa-first-order-alt fa-sm mr-3" />
                Institution Setup Details -{" "}
                <span className="font-weight-bold">
                  {institution.institutionName}
                </span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Accordion defaultActiveKey="0">
            <div>
              <Card>
                <ContextAwareToggle
                  eventKey={`0`}
                  header={
                    <Fragment>
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        style={{ color: "#878a8a" }}
                      ></i>{" "}
                      General Information
                    </Fragment>
                  }
                ></ContextAwareToggle>
                <Accordion.Collapse eventKey={`0`}>
                  <Card.Body>
                    <div
                      className="card-body"
                      style={{ paddingTop: "0px", paddingBottom: "0px" }}
                    >
                      <div className="row">
                        <div className="col-md-6">
                          {_.isEmpty(institution) ? (
                            <Skeleton count={2} width={300} />
                          ) : (
                            <dl className="row" style={{ marginBottom: "0px" }}>
                              <DetailItem
                                label="Name"
                                value={institution.institutionName}
                                labelSize={5}
                                valueSize={7}
                              />
                              <DetailItem
                                label="Status"
                                labelSize={5}
                                valueSize={7}
                                value={
                                  (institution
                                    ? institution.isActive
                                    : "Active") === "Active" ? (
                                    <span className="badge badge-success">
                                      {institution ? institution.isActive : ""}
                                    </span>
                                  ) : (
                                    <span className="badge badge-danger">
                                      {institution ? institution.isActive : ""}
                                    </span>
                                  )
                                }
                              />
                            </dl>
                          )}
                        </div>
                        <div className="col-md-6">
                          {_.isEmpty(institution) ? (
                            <Skeleton count={2} width={300} />
                          ) : (
                            <dl className="row" style={{ marginBottom: "0px" }}>
                              <DetailItem
                                label="Code"
                                value={institution.institutionCode}
                                labelSize={5}
                                valueSize={7}
                              />
                            </dl>
                          )}
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </div>
          </Accordion>
          <Accordion defaultActiveKey="0">
            <div>
              <Card>
                <ContextAwareToggle
                  eventKey={`0`}
                  header={
                    <React.Fragment>
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        style={{ color: "#878a8a" }}
                      ></i>{" "}
                      Fee Configurations
                    </React.Fragment>
                  }
                ></ContextAwareToggle>
                <Accordion.Collapse eventKey={`0`}>
                  <Card.Body>
                    <div
                      className="card-body"
                      style={{ paddingTop: "0px", paddingBottom: "0px" }}
                    >
                      <div className="row">
                        <div className="col-md-6">
                          {_.isEmpty(feeConfig) ? (
                            <Skeleton count={4} width={300} />
                          ) : (
                            <Fragment>
                              <dl
                                className="row"
                                style={{ marginBottom: "0px" }}
                              >
                                <DetailItem
                                  label="Fee Entry Type"
                                  value={feeConfig.feeEntryType}
                                  labelSize={6}
                                  valueSize={6}
                                />
                                <DetailItem
                                  label="Flat Rate Per Request"
                                  value={feeConfig.flatRatePerRequest}
                                  labelSize={6}
                                  valueSize={6}
                                />
                                <DetailItem
                                  label="Flat Rate Per Loan Disbursed"
                                  value={feeConfig.flatRatePerLoanDisbursed}
                                  labelSize={6}
                                  valueSize={6}
                                />
                                <DetailItem
                                  label="Percentage Of Loan Amount"
                                  value={feeConfig.percentageOfLoanAmount}
                                  labelSize={6}
                                  valueSize={6}
                                />
                              </dl>
                            </Fragment>
                          )}
                        </div>
                        <div className="col-md-6">
                          {_.isEmpty(feeConfig) ? (
                            <Skeleton count={3} width={300} />
                          ) : (
                            <Fragment>
                              <dl
                                className="row"
                                style={{ marginBottom: "0px" }}
                              >
                                <DetailItem
                                  label="Minimum Per Transaction"
                                  value={feeConfig.minimumPerTransaction}
                                  labelSize={6}
                                  valueSize={6}
                                />
                                <DetailItem
                                  label="Maximum Per Transaction"
                                  value={feeConfig.maximumPerTransaction}
                                  labelSize={6}
                                  valueSize={6}
                                />
                                {/* <DetailItem
                                  label="Fee Income GL AccountNumber"
                                  value={feeConfig.feeIncomeGLAccountNumber}
                                  labelSize={6}
                                  valueSize={6}
                                />
                                <DetailItem
                                  label="Expense GL AccountNumber"
                                  value={feeConfig.expenseGLAccountNumber}
                                  labelSize={6}
                                  valueSize={6}
                                /> */}
                                <DetailItem
                                  label="Flat Rate Per Successful Request"
                                  value={feeConfig.flatRatePerSuccessfulRequest}
                                  labelSize={6}
                                  valueSize={6}
                                />
                              </dl>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </div>
          </Accordion>
          <Accordion defaultActiveKey="0">
            <div>
              <Card>
                <ContextAwareToggle
                  eventKey={`0`}
                  header={
                    <React.Fragment>
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        style={{ color: "#878a8a" }}
                      ></i>{" "}
                      Indicators
                    </React.Fragment>
                  }
                ></ContextAwareToggle>
                <Accordion.Collapse eventKey={`0`}>
                  <Card.Body>
                    <div
                      className="card-body"
                      style={{ paddingTop: "0px", paddingBottom: "0px" }}
                    >
                      <div className="row">
                        {/* <div className="col-md-6">
                          {_.isEmpty(indicators) ? (
                            <Skeleton count={5} width={300} />
                          ) : (
                            <Fragment>
                              <h5 className="text-center">
                                <b>
                                  Permissible Credit Qualification Rule
                                  Indicators
                                </b>
                              </h5>
                              <br />
                              {indicators.map((indicator) => (
                                <div
                                  className="custom-control custom-checkbox"
                                  key={indicator.id}
                                >
                                  <input
                                    id={indicator.id}
                                    name={indicator.indicatorName}
                                    className="custom-control-input"
                                    type="checkbox"
                                    checked={true}
                                    disabled={true}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={indicator.indicatorName}
                                  >
                                    {indicator.description}
                                  </label>
                                </div>
                              ))}
                            </Fragment>
                          )}
                        </div> */}
                        <div className="col-md-8">
                          {" "}
                          {/* set to 6 once cqI joins */}
                          {_.isEmpty(institution) ? (
                            <Skeleton count={5} width={300} />
                          ) : (
                            _.isEmpty(institution.scoreIndicators) ? (
                              <p>No score Indicators have been assigned to this institution</p>
                            ) : (
                              <Fragment>
                                {/* <h5 className="text-center"> */}
                                <h5>
                                  <b>Score Indicators</b>
                                </h5>
                                <br />
                                {institution.scoreIndicators?.map((indicator) => (
                                  <div
                                    className="custom-control custom-checkbox"
                                    key={indicator.scoreIndicatorId}
                                  >
                                    <input
                                      id={indicator.scoreIndicatorId}
                                      name={indicator.scoreIndicatorId}
                                      className="custom-control-input"
                                      type="checkbox"
                                      checked={true}
                                      disabled={true}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={indicator.scoreIndicatorId}
                                    >
                                      {indicator.scoreIndicator}
                                    </label>
                                  </div>
                                ))}
                              </Fragment>
                            )
                          )}
                        </div>
                        <div className="col-md-2">
                        </div>
                        <div className="col-md-2">
                          {_.isEmpty(institution) ? (
                            <Skeleton count={1} width={100} />
                          ) : (
                            <dl className="row">
                              {/* {getCurrentUsersRoles().includes("editloanpricingconfiguration") && ( */}
                              <Button
                                size="sm"
                                variant="primary"
                                disabled={updatingStatus}
                                onClick={togglePatchModal}
                              >
                                <i className="far fa-edit mr-3" />
                                Edit Indicators
                              </Button>
                              {/* )} */}
                            </dl>
                          )}
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </div>
          </Accordion>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(institution) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
              <Button
                size="sm"
                variant="outline-secondary"
                disabled={updatingStatus}
                onClick={(e) =>
                  this.onHandleEnableOrDisable(
                    e,
                    institution.isActive === "Inactive"
                      ? ActionTypes.ENABLE
                      : ActionTypes.DISABLE,
                    "Institution Config"
                  )
                }
              >
                <i className="far fa-edit mr-3" />
                {institution.isActive === "Inactive"
                  ? "Activate"
                  : "Deactivate"}
              </Button>
              <Button
                size="sm"
                variant="primary"
                disabled={updatingStatus}
                onClick={toggleEditModal}
              >
                <i className="far fa-edit mr-3" />
                Edit
              </Button>
            </React.Fragment>
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default InstitutionSetupDetail;
