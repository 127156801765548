import React, { Fragment, useContext } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import FormInputComponent from "../../../components/formInputComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import creditAssessmentValidationService from "../../../services/creditAssessment/creditAssessmentValidationService";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../../services/auth/authService";
import scoringCardService from "../../../services/creditAssessment/scoringCardService";
import scoringIndicatorService from "../../../services/creditAssessment/scoringIndicatorService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import { Accordion, AccordionContext, Card, useAccordionToggle } from "react-bootstrap";
import ModifyPointMetrics from "./modifyPointMetrics";
import DetailItem from "../../../components/detailItem";

interface AddScoreCardFields {
  name: string;
  description: string;
}

class AddScoreCardForm extends BaseFormComponent<AddScoreCardFields> { }

interface IAddScoreCardProps { }
interface IAddScoreCardState {
  scoreIndicatorIds: any;
  scoreIndicators: any;
  fetchingScoreIndicators: boolean;
  showSetIndicatorParamsModal: boolean;
  indicatorItem: any;
  scoreMetrics: any;
  IScoreCardPointsMetrics: any;
  indicatorOption: any;
}

export function ContextAwareToggle({ isHeader, eventKey, header }) {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";

  return (
    <>
      {isHeader ? (
        <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
          <b>{header}</b>
          <i
            className={angleType}
            aria-hidden="true"
            style={{ float: "right" }}
          ></i>
        </Card.Header>
      ) : (
        <div onClick={toggleOnClick} style={{ cursor: "pointer", display: "flex", justifyContent: "space-between" }}>
          <div>
            <b>{header}</b>
          </div>
          <div style={{ float: "right", paddingRight: "3px", paddingTop: "1px" }}>
            <i
              className={angleType}
              aria-hidden="true"
            ></i>
          </div>
        </div>
      )}
    </>
  );
}
class AddScoreCard extends React.Component<
  IAddScoreCardProps,
  IBaseFormState & IAddScoreCardState
> {
  _isMounted = false;
  constructor(props: IAddScoreCardProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      scoreIndicatorIds: [],
      scoreIndicators: [],
      fetchingScoreIndicators: false,
      showSetIndicatorParamsModal: false,
      indicatorItem: {},
      scoreMetrics: {},
      IScoreCardPointsMetrics: [],
      indicatorOption: []
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    const { errors } = this.state;
    const decodedToken = decodeToken(getCurrentUserSession().token || "")
    try {
      if (this._isMounted) {
        this.setState({
          fetchingScoreIndicators: true
        });
      }
      const scoreIndicators = await scoringIndicatorService.retrieveScoreIndicatorByInstitutionCode(decodedToken?.["InstitutionCode"]);
      this.setState({
        scoreIndicators: scoreIndicators.data,
        fetchingScoreIndicators: false,
      })
    } catch (ex) {
      // console.log("caught exception", ex);
      if (ex.response && ex.response.status === 404) {
        // console.log(ex.response);
        errors.response = "Internal server error.";
        // console.log(errors);
        this.setState({ errors });
      }
    }
  }

  onFormSubmit(fields: AddScoreCardFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          // errors: creditAssessmentValidationService.validateAddScoreCardForm(fields),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddScoreCardFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({
        submitting: true,
      });
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const { scoreIndicatorIds, scoreIndicators } = this.state;
      const payload: any = {};
      payload.name = fields.name;
      payload.description = fields.description;
      payload.institutionCode = decodedToken?.["InstitutionCode"];
      // payload.scoreCardPointsMetrics = scoreIndicatorIds.map(scoreIndicatorId => {
      //   let indicatorMetrics = scoreIndicators.filter(indicator => indicator.scoreIndicatorId === scoreIndicatorId)[0];
      //   if (indicatorMetrics.scoreMetrics) {
      //     indicatorMetrics = indicatorMetrics.scoreMetrics.map(data => ({
      //       pointMetricId: data.metricId,
      //       points: data.metricPoint,
      //     }))
      //     return {
      //       scoreIndicatorId: scoreIndicatorId,
      //       indicatorMetrics: indicatorMetrics
      //     }
      //   }
      //   else {
      //     return {}
      //   }
      // });
      payload.scoreCardPointsMetrics = this.state.IScoreCardPointsMetrics;

      console.log("payload: ", payload)
      const response = await scoringCardService.createScoreCard(payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Add Score Card",
          description: `Added new Score Card ${fields.name}`,
        });
        toast.info(`Score Card ${fields.name} created successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false, IScoreCardPointsMetrics: [] });
      this.resetFields();
      onReloadFieldsCB();
    } catch (error) {
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false });
      return false;
    } finally {
      return true;
    }
  }

  handleOnChangeScoreIndicator = async (item, indicatorType) => {
    let scoreMetrics;
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    const scoreIndicatorIds = [...this.state.scoreIndicatorIds];
    if (scoreIndicatorIds.indexOf(item) !== -1) {
      scoreIndicatorIds.splice(scoreIndicatorIds.indexOf(item), 1);
      this.setState({ scoreIndicatorIds });
    } else {
      // finite
      console.log("Finite item :: ", (await scoringIndicatorService.retrieveScoreIndicatorById(item)).data)
      if (indicatorType === 'Finite') {
        scoreMetrics = (await scoringIndicatorService.retrieveScoreIndicatorById(item)).data;
      } else {
        scoreMetrics = (await scoringIndicatorService.retrieveScoreIndicatorByIdAndInstitutionCode(
          item,
          decodedToken?.["InstitutionCode"]
        )).data;
      }
      console.log("scoreMetrics :: ", scoreMetrics)
      console.log("Item :: ", item)
      if (scoreMetrics.length === 0) {
        scoreIndicatorIds.push(item);
        this.setState({ scoreIndicatorIds });
      } else {
        this.setState({ indicatorItem: item, showSetIndicatorParamsModal: true, scoreMetrics });
      }
    }
  };

  handleOnIndicatorParamsSave = (toSave: boolean, scoreIndicatorId, payload, IScoreCardPointsMetrics, IIndicatorOptions) => {
    if (toSave) {
      // console.log("payload :: ", payload);
      const scoreIndicatorIds = [...this.state.scoreIndicatorIds];
      let indicatorOptions = [...this.state.indicatorOption];
      const scoreIndicators = [...this.state.scoreIndicators];
      let ScoreCardPointsMetrics = [...this.state.IScoreCardPointsMetrics];
      scoreIndicatorIds.push(scoreIndicatorId);
      let scoreIndicator = scoreIndicators.filter(indicator => indicator.scoreIndicatorId === scoreIndicatorId)[0];
      scoreIndicator.scoreMetrics = payload
      ScoreCardPointsMetrics = IScoreCardPointsMetrics;
      indicatorOptions = IIndicatorOptions;
      this.setState({ scoreIndicatorIds, scoreIndicators, showSetIndicatorParamsModal: false, IScoreCardPointsMetrics: ScoreCardPointsMetrics, indicatorOption: indicatorOptions });
    } else {
      this.setState({ showSetIndicatorParamsModal: false });
    }
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      scoreIndicatorIds: [],
    });
  }

  render() {
    const {
      errors,
      validated,
      submitting,
      scoreIndicatorIds,
      scoreIndicators,
      fetchingScoreIndicators,
      indicatorItem,
      scoreMetrics,
      showSetIndicatorParamsModal,
      IScoreCardPointsMetrics,
      indicatorOption
    } = this.state;

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add Score Card</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddScoreCardForm
                  initialValues={{
                    name: "",
                    description: "",
                  }}
                  FormComponent={({
                    fields: {
                      name,
                      description,
                    },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <div className="form-row">
                            <div className="col-md-12">
                              {_.isEmpty(scoreIndicators) && fetchingScoreIndicators === false ? (
                                "There is no scoring indicator assigned to this institution"
                              ) : (
                                <Fragment>
                                  <h5 className="card-title ml-3">
                                    Details
                                  </h5>
                                  <div className="form-row">
                                    <div className="col-md-6">
                                      <FormInputComponent
                                        id="name"
                                        type="text"
                                        name="name"
                                        placeholder=""
                                        value={name}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <FormInputComponent
                                        id="description"
                                        type="text"
                                        name="description"
                                        placeholder=""
                                        value={description}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    </div>
                                  </div>
                                  <hr />
                                  <div className="form-row">
                                    <div className="col-md-12">
                                      <div className="form-row">
                                        <div className="col-md-12">
                                          <label
                                            htmlFor="indicatorIds"
                                            className="control-label textbox-label pl-2"
                                          >
                                            Score Indicators
                                          </label>
                                          <div
                                            className="block-example border"
                                            style={{
                                              width: "100%",
                                              maxWidth: "100%",
                                              overflowX: "hidden",
                                              height: "200px",
                                              overflowY: "scroll",
                                            }}
                                          >
                                            <React.Fragment>
                                              {scoreIndicators.map(
                                                (item) => (
                                                  item.scoreMetrics?.length > 0 ? (
                                                    <Accordion key={scoreIndicators.indexOf(item)}>
                                                      <div style={{ border: '2px #84d4fc solid', borderRadius: '4px', backgroundColor: '#e4f4fc', marginTop: '5px', inlineSize: "100%" }}>
                                                        <ContextAwareToggle
                                                          eventKey={`0`}
                                                          isHeader={false}
                                                          header={
                                                            <div
                                                              className="custom-control custom-checkbox"
                                                              style={{ display: 'inline-block' }}
                                                              key={scoreIndicators.indexOf(item)}
                                                            >
                                                              <input
                                                                id={item.scoreIndicatorId}
                                                                name={item.scoreIndicatorId}
                                                                className="custom-control-input"
                                                                type="checkbox"
                                                                onChange={() =>
                                                                  this.handleOnChangeScoreIndicator(
                                                                    item.scoreIndicatorId,
                                                                    item.indicatorType
                                                                  )
                                                                }
                                                                checked={
                                                                  _.isEmpty(
                                                                    this.state.scoreIndicatorIds.filter(
                                                                      (x) => x === item.scoreIndicatorId
                                                                    )
                                                                  )
                                                                    ? false
                                                                    : true
                                                                }
                                                              />
                                                              <label
                                                                className="custom-control-label"
                                                                htmlFor={item.scoreIndicatorId}
                                                              >
                                                                {item.indicatorName}
                                                              </label>
                                                            </div>
                                                          }
                                                        ></ContextAwareToggle>
                                                      </div>
                                                      <Accordion.Collapse eventKey={`0`}>
                                                        <Card.Body style={{ padding: "5px", backgroundColor: '#e4f4fc' }}>
                                                          <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                            <div className="row">
                                                              <div className="col-md-1">
                                                              </div>
                                                              <div className="col-md-8">
                                                                {item.scoreMetrics.map(metric => (
                                                                  <dl className="row" style={{ marginBottom: "0px" }} key={metric.metricId}>
                                                                    <DetailItem
                                                                      label={(metric.metricName.split("_")[0])}
                                                                      value={metric.metricPoint}
                                                                      labelSize={5}
                                                                      valueSize={7}
                                                                    />
                                                                  </dl>
                                                                ))}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </Card.Body>
                                                      </Accordion.Collapse>
                                                    </Accordion>
                                                  ) : (
                                                    <div
                                                      key={scoreIndicators.indexOf(item)}
                                                      style={{ border: '2px #84d4fc solid', borderRadius: '4px', backgroundColor: '#e4f4fc', marginTop: '5px' }}>
                                                      <div className="custom-control custom-checkbox" key={scoreIndicators.indexOf(item)}>
                                                        <input
                                                          id={item.scoreIndicatorId}
                                                          name={item.scoreIndicatorId}
                                                          className="custom-control-input"
                                                          type="checkbox"
                                                          onChange={() =>
                                                            this.handleOnChangeScoreIndicator(
                                                              item.scoreIndicatorId,
                                                              item.indicatorType
                                                            )
                                                          }
                                                          checked={
                                                            _.isEmpty(
                                                              this.state.scoreIndicatorIds.filter(
                                                                (x) => x === item.scoreIndicatorId
                                                              )
                                                            )
                                                              ? false
                                                              : true
                                                          }
                                                        />
                                                        <label
                                                          className="custom-control-label"
                                                          htmlFor={item.scoreIndicatorId}
                                                        >
                                                          {item.indicatorName}
                                                        </label>
                                                      </div>
                                                    </div>
                                                  )
                                                )
                                              )}
                                            </React.Fragment>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/*  */}
                                  <ModifyPointMetrics
                                    showPatchModal={showSetIndicatorParamsModal}
                                    togglePatchModal={(toSave: boolean, indicatorId: string, payload: any, IScoreCardPointsMetrics: any, indicatorOption: any) => {
                                      this.handleOnIndicatorParamsSave(toSave, indicatorId, payload, IScoreCardPointsMetrics, indicatorOption);
                                    }}
                                    item={indicatorItem}
                                    allIndicators={scoreMetrics}
                                    IScoreCardPointsMetrics={IScoreCardPointsMetrics}
                                    indicatorOption={indicatorOption} /><hr /><div className="card-body">
                                    <button
                                      type="submit"
                                      className="btn btn-sm btn-primary"
                                      disabled={submitting || scoreIndicatorIds.length == 0}
                                      onClick={(e) => {
                                        onHandleSubmit(
                                          e,
                                          ActionTypes.SAVE,
                                          "Score Card",
                                          () => {
                                            this.onFormSubmit(
                                              {
                                                name,
                                                description,
                                              },
                                              onReloadFields
                                            );
                                          }
                                        );
                                      }}
                                    >
                                      {this.state.submitting === false ? (
                                        <React.Fragment>
                                          <i className="fas fa-lg fa-save mr-3" /> Save
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                          <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                          Please wait...
                                        </React.Fragment>
                                      )}
                                    </button>
                                  </div>
                                </Fragment>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                ></AddScoreCardForm>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddScoreCard;
