import React, { Component } from "react";
import _, { identity } from "lodash";
import { toast } from "react-toastify";
import BaseFormComponent, { IBaseFormState } from "../../../../../../components/BaseFormComponent";
import FormInputComponent from "../../../../../../components/formInputComponent";
import SelectInputComponent, { ISelectItems } from "../../../../../../components/formSelectComponent";
import { EconomicSector } from "../../../../../../enums/economicSector";
import { LoanComputationMode } from "../../../../../../enums/loanComputationMode";
import { ProductType } from "../../../../../../enums/productType";
import textFormatService from "../../../../../../services/utility/textFormatService";
import loanRequestService from "../../../../../../services/hybridAppraisal/loanRequestService";
import { ActionTypes } from "../../../../../../enums/actionTypes";
import hybridAppraisalValidationService from "../../../../../../services/hybridAppraisal/hybridAppraisalValidationService";
import mandateService from "../../../../../../services/mandate/mandateService";
import Repayment from "./repayment";
import { getCurrentUserSession, getCurrentUsersRoles } from "../../../../../../services/auth/authService";
import supplierService from "../../../../../../services/hybridAppraisal/supplierService";
import { decodeToken } from "react-jwt";
import loanProductService from "../../../../../../services/hybridAppraisal/loanProductService";
import RepaymentSchedule from "./repaymentSchedule";
import loanCategoryService from "../../../../../../services/hybridAppraisal/loanCategoryService";
import verificationServices from "../../../../../../services/verificationService/verificationServices";
import { object } from "joi";
import moment from "moment";

interface IdVerificationDetailsFields {

}

class IdVerificationDetailsForm extends BaseFormComponent<IdVerificationDetailsFields> { }

interface IdVerificationDetailsProps {
    values: any;
    reloadItem: any;
    mandatoryFields: any;
    preferredRepaymentBank: string;
}
interface IdVerificationDetailsState {
    showRepaymentSchedule: boolean;
    ownUpdate: boolean;
    item: any;
    repaymentGenerated: boolean;
    repaymentDetails: any;
    repaymentFrequency: string;
    editLoanCategory: boolean;
    hideEditButton: boolean;
    disburseTo: string;
    supplierProvided: boolean;
    suppliers: any;
    selectedSupplier: any;
    loanCommission: string;
    productPricing: any;
    loanCategory: any;
    selectedLoanCategory: any;
    vin: any;
    bvn: any;
    vnin: any;
    driverslicense: any;
    passport: any;
    cac: any;
    tin: any;
    verified: boolean
}

class IdVerificationDetails extends Component<
    IdVerificationDetailsProps,
    IBaseFormState & IdVerificationDetailsState
> {
    _isMounted: boolean;
    constructor(props: IdVerificationDetailsProps) {
        super(props);
        this.state = {
            validated: false,
            submitting: false,
            errors: {},
            showRepaymentSchedule: false,
            item: {},
            ownUpdate: false,
            repaymentGenerated: false,
            repaymentDetails: {},
            repaymentFrequency: "",
            editLoanCategory: false,
            hideEditButton: false,
            disburseTo: "",
            supplierProvided: false,
            suppliers: [],
            selectedSupplier: {},
            loanCommission: "",
            productPricing: [],
            loanCategory: [],
            selectedLoanCategory: {},
            vnin: null,
            bvn: null,
            vin: null,
            driverslicense: null,
            cac: null,
            passport: null,
            tin: null,
            verified: false
        };
    }

    async componentDidMount() {
        this._isMounted = true;
        const decodedToken = decodeToken(getCurrentUserSession()?.token || "")
        // console.log(jsonLoanDetails)
        // console.log(loanProductDetails)


        // const decodedToken = decodeToken(getCurrentUserSession().token || "");
        const verify = await verificationServices.getVerificationDetails(this.props.values.id);
        const verifyDetails = verify.data

        // console.log(verifyDetails)

        if (verifyDetails !== null && verifyDetails != "") {
            this.setState({
                verified: true,
                vnin: verifyDetails.vninDataResponse,
                bvn: verifyDetails.bvnDataResponse,
                vin: verifyDetails.vinDataResponse,
                driverslicense: verifyDetails.driversLicenseDataResponse,
                passport: verifyDetails.passportDataResponse,
                cac: verifyDetails.cacDataResponse,
                tin: verifyDetails.tinDataResponse,
            })
        }
        else {
            this.setState({ verified: false })
        }

    }
    static getDerivedStateFromProps(
        nextProps: IdVerificationDetailsProps,
        prevState: IdVerificationDetailsState
    ) {
        if (prevState.ownUpdate) {
            return {
                ownUpdate: false,
            };
        }
        if (_.isNull(nextProps.values)) {
            return null;
        }
        return {
            item: nextProps.values
        };
    }

    async submit(
    ): Promise<boolean> {
        try {
            this.setState({ submitting: true, ownUpdate: true });
            const loanRequest = await loanRequestService.retrieveById(this.props.values.id);
            const loanRequestDetail = loanRequest.data;
            const time = moment().format('HH:mm:ss')
            const dates = moment().format('YYYY-MM-DD')
            // console.log(x)
            // const d = new Date();
            // const year = d.getFullYear();
            // const month = (`0${d.getMonth() + 1}`).slice(-2); // months are zero-indexed
            // const day = (`0${d.getDate()}`).slice(-2);
            // var dates = `${year}-${month}-${day}`;
            // // var dates = new Date().toLocaleDateString("YYYY-")

            // var time = new Date().toLocaleTimeString()
            var date = dates + "T" + time
            var response: any = {}
            var CACResponse: any = {}
            var TINResponse: any = {}
            var verificationResponse: any = {}
            var BVNResponse: any = {}

            const payload: any = {
                BVNDataResponse: null,
                VinDataResponse: null,
                VNINDataResponse: null,
                DriversLicenseDataResponse: null,
                PassportDataResponse: null,
                CACDataResponse: null,
                TINDataResponse: null
            };
            // console.log(payload)
            const verifyPayloadCAC: any = {};
            const verifyPayloadTIN: any = {};
            const verifyPayloadBVN: any = {};
            var verifyPayload = this.getIdentificationType(loanRequestDetail.identificationType, loanRequestDetail.identificationNumber);
            // console.log(verifyPayload)
            verifyPayload.firstname = loanRequestDetail.customerFirstName
            verifyPayload.lastname = loanRequestDetail.customerLastName
            verifyPayload.dob = loanRequestDetail.dateOfBirth === null ? date : loanRequestDetail.dateOfBirth
            verifyPayload.phone = loanRequestDetail.phonenumber
            verifyPayload.email = loanRequestDetail.customerEmail
            verifyPayload.gender = loanRequestDetail.gender

            verifyPayloadCAC.validations = "CAC"
            verifyPayloadCAC.idNumber = loanRequestDetail.registrationNumber === null ? null : loanRequestDetail.registrationNumber
            verifyPayloadCAC.firstname = loanRequestDetail.customerFirstName
            verifyPayloadCAC.lastname = loanRequestDetail.customerLastName
            verifyPayloadCAC.dob = loanRequestDetail.dateOfBirth === null ? date : loanRequestDetail.dateOfBirth
            verifyPayloadCAC.phone = loanRequestDetail.phonenumber
            verifyPayloadCAC.email = loanRequestDetail.customerEmail
            verifyPayloadCAC.gender = loanRequestDetail.gender

            verifyPayloadTIN.validations = "TIN"
            verifyPayloadTIN.idNumber = loanRequestDetail.tin
            verifyPayloadTIN.firstname = loanRequestDetail.customerFirstName
            verifyPayloadTIN.lastname = loanRequestDetail.customerLastName
            verifyPayloadTIN.dob = loanRequestDetail.dateOfBirth === null ? date : loanRequestDetail.dateOfBirth
            verifyPayloadTIN.phone = loanRequestDetail.phonenumber
            verifyPayloadTIN.email = loanRequestDetail.customerEmail
            verifyPayloadTIN.gender = loanRequestDetail.gender

            verifyPayloadBVN.validations = "BVN"
            verifyPayloadBVN.idNumber = loanRequestDetail.bvn
            verifyPayloadBVN.firstname = loanRequestDetail.customerFirstName
            verifyPayloadBVN.lastname = loanRequestDetail.customerLastName
            verifyPayloadBVN.dob = loanRequestDetail.dateOfBirth === null ? date : loanRequestDetail.dateOfBirth
            verifyPayloadBVN.phone = loanRequestDetail.phonenumber
            verifyPayloadBVN.email = loanRequestDetail.customerEmail
            verifyPayloadBVN.gender = loanRequestDetail.gender

            try {
                if (this.props.values.loanType === "Corporate") {
                    response = await Promise.allSettled([
                        verificationServices.verifyId(verifyPayloadCAC),
                        verificationServices.verifyId(verifyPayloadTIN),
                    ])
                    CACResponse = response[0].status == "fulfilled" ? response[0].value.data : null;
                    TINResponse = response[1].status == "fulfilled" ? response[1].value.data : null;
                }
                else {
                    response = await Promise.allSettled([
                        verificationServices.verifyId(verifyPayload),
                        verificationServices.verifyId(verifyPayloadBVN)
                    ])
                    verificationResponse = response[0].status == "fulfilled" ? response[0].value.data : null;
                    BVNResponse = response[1].status == "fulfilled" ? response[1].value.data : null;
                }
            } catch {

            }

            switch (loanRequestDetail.identificationType) {
                case "InternationalPassport":
                    payload.PassportDataResponse = verificationResponse !== null ? verificationResponse?.passport : null;
                    break;
                case "DriversLicense":
                    if (verificationResponse !== null) {
                        var driversLicense = verificationResponse?.drivers_license
                        driversLicense.issuedDate = driversLicense.issued_date
                        driversLicense.expiryDate = driversLicense.expiry_date
                        driversLicense.stateOfIssue = driversLicense.state_of_issue

                        payload.DriversLicenseDataResponse = driversLicense;
                    }
                    else {
                        payload.DriversLicenseDataResponse = null
                    }
                    break;
                case "VotersCard":

                    payload.VinDataResponse = verificationResponse !== null ? verificationResponse?.voters_card : null;
                    break;
                case "NationalID":

                    payload.VNINDataResponse = verificationResponse !== null ? verificationResponse?.v_nin : null;
                    break;
                default:
                    break;
            }
            // console.log(payload)
            // console.log(BVNResponse)

            if (BVNResponse !== null && Object.keys(BVNResponse).length > 0) {
                var bvnResp = BVNResponse?.bvn
                if (bvnResp !== null) {
                    bvnResp.lgaOfOrigin = bvnResp.lga_of_origin
                    bvnResp.maritalStatus = bvnResp.marital_status
                    bvnResp.residentialAddress = bvnResp.residential_address
                    bvnResp.stateOfOrigin = bvnResp.state_of_origin
                    bvnResp.stateOfResidence = bvnResp.state_of_residence
                    bvnResp.enrollmentBank = bvnResp.enrollment_bank
                    bvnResp.nameOnCard = bvnResp.name_on_card
                    bvnResp.levelOfAccount = bvnResp.level_of_account
                    bvnResp.registrationDate = bvnResp.registration_date
                    bvnResp.watch_listed = bvnResp.watch_listed
                }
                payload.BVNDataResponse = bvnResp;
            }
            else {
                payload.BVNDataResponse = null
            }

            payload.LoanRequestId = this.props.values.id
            payload.CACDataResponse = CACResponse !== null ? CACResponse?.cac : null;
            payload.TINDataResponse = TINResponse !== null ? TINResponse?.tin : null;
            console.log(payload)

            const SaveVerification = await verificationServices.saveVerification(payload);
            if (SaveVerification.status >= 200 && SaveVerification.status <= 300) {

                this.setState({
                    cac: payload.CACDataResponse,
                    tin: payload.TINDataResponse,
                    bvn: payload.BVNDataResponse,
                    passport: payload.PassportDataResponse,
                    vin: payload.VinDataResponse,
                    vnin: payload.VNINDataResponse,
                    driverslicense: payload.DriversLicenseDataResponse,
                    verified: true
                })
            } else {
                return false;
            }
        } catch (error) {
            if (error.response.status === 409) {
                toast.error(error.response.data.detail, {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            } else if (error.response.status === 400) {
                toast.error(error.response.data.errors[0], {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            }
            this.setState({ submitting: false, ownUpdate: true });
            return false;
        } finally {
            return true;
        }
    }

    getIdentificationType = (identificationType: string, identificationNumber: string) => {
        const verifyPayload: any = {};
        switch (identificationType) {
            case "InternationalPassport":
                verifyPayload.validations = "PASSPORT"
                verifyPayload.idNumber = identificationNumber
                return verifyPayload;
            case "DriversLicense":
                verifyPayload.validations = "DRIVERSLICENCE"
                verifyPayload.idNumber = identificationNumber
                return verifyPayload;
            case "VotersCard":
                verifyPayload.validations = "VOTERSCARD"
                verifyPayload.idNumber = identificationNumber
                return verifyPayload;
            case "NationalID":
                verifyPayload.validations = "VNIN"
                verifyPayload.idNumber = identificationNumber
                return verifyPayload;
            default:
                return verifyPayload;
        }
    }

    fetchLoanDetails = async () => {
        const loanRequest = await loanRequestService.retrieveById(this.props.values.id);
        const loanRequestDetail = loanRequest.data;

        this.setState({ repaymentDetails: loanRequestDetail })
    }

    getRepaymentFrequencyCode = (frequency: string) => {
        var code =
            frequency === "Daily" ? 0 :
                frequency === "Weekly" ? 1 :
                    frequency === "Monthly" ? 2 :
                        frequency === "Quarterly" ? 3 :
                            frequency === "Annually" ? 4 :
                                frequency === "BiMonthly" ? 5 :
                                    frequency === "HalfYearly" ? 6 :
                                        frequency === "Termly" ? 8 : 0

        return code;
    }

    getInterestAccrualCode = (accrual: string) => {
        var code =
            accrual === "Fixed" ? 0 :
                accrual === "Reducing" ? 1 :
                    accrual === "ReducingButEqual" ? 2 : 0

        return code;
    }

    onChangeDisburseTo = async (id, value) => {
        await this.setState({ disburseTo: value, ownUpdate: true });
    }


    getRequiredFields(title): boolean {
        const { mandatoryFields } = this.props;
        var required = mandatoryFields?.inputFields?.filter(x => x.fieldName === title)[0]?.mandatory;
        if (required === undefined) {
            return false;
        }
        return required;
    }

    resetFields() {
        this.setState({ validated: false, submitting: false, errors: [], ownUpdate: true });
    }

    render() {
        const { values } = this.props;
        const { errors, validated, tin, vin, vnin, passport, driverslicense, bvn, cac, verified } = this.state;


        return (
            <>
                {/* {!verified ? ( */}
                <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "33px",
                        fontSize: "12px",
                        borderRadius: "8px",
                        color: "rgb(255, 255, 255)",
                        borderColor: "rgb(105, 65, 198)",
                        backgroundColor: "rgb(105, 65, 198)"
                    }}
                    onClick={() =>
                        this.submit()
                    }
                >
                    {/* <span className="ant-btn-icon"><span role="img" aria-label="plus"
                                        className="anticon anticon-plus"><svg viewBox="64 64 896 896" focusable="false" data-icon="plus" width="1em" height="1em" fill="currentColor" aria-hidden="true"><defs>
                                            <style></style></defs><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path><path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path></svg></span></span> */}
                    <span>Run Verification</span></button>
                {/* ) : ( */}
                <div className="form-row">
                    <div className="col-md-12">


                        {vnin == null ? ("") : (
                            <div className="card mt-2">
                                <div className="card-header clear-fix">
                                    <h6
                                        className="float-left"
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <b>Verification Details - VNIN</b>
                                    </h6>
                                </div>
                                <div className="card-body">
                                    <div className="form-row">
                                        <FormInputComponent
                                            id="firstname"
                                            name="firstname"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={vnin.firstname}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="lastname"
                                            name="lastname"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={vnin.lastname}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />

                                        <FormInputComponent
                                            id="middlename"
                                            name="middlename"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={vnin.middlename}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="birthdate"
                                            name="birthdate"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={vnin.birthdate}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="gender"
                                            name="gender"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={vnin.gender}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="phone"
                                            name="phone"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={vnin.phone}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="vNin"
                                            name="vNin"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={vnin.vNin}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
    {!!vnin?.photo && <img style={{marginLeft:'auto', marginRight:5, marginTop:20, borderRadius: 5, }} 
                                        width={250} height={300} alt="Passport_Photo" src={`data:image/png;base64,${vnin.photo}`} />}
                                    </div>
                                </div>
                            </div>
                        )}
                        {vin == null ? ("") : (
                            <div className="card mt-2">
                                <div className="card-header clear-fix">
                                    <h6
                                        className="float-left"
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <b>Verification Details - Voters' Card</b>
                                    </h6>
                                </div>
                                <div className="card-body">
                                    <div className="form-row">
                                        <FormInputComponent
                                            id="firstName"
                                            name="firstName"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={vin.firstName}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="lastName"
                                            name="lastName"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={vin.lastName}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />

                                        <FormInputComponent
                                            id="occupation"
                                            name="occupation"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={vin.occupation}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="pollingUnitCode"
                                            name="pollingUnitCode"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={vin.pollingUnitCode}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="gender"
                                            name="gender"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={vin.gender}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="vin"
                                            name="vin"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={vin.vin}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />

                                    </div>
                                </div>
                            </div>
                        )}
                        {driverslicense == null ? ("") : (
                            <div className="card mt-2">
                                <div className="card-header clear-fix">
                                    <h6
                                        className="float-left"
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <b>Verification Details - Drivers' License</b>
                                    </h6>
                                </div>
                                <div className="card-body">
                                    <div className="form-row">
                                        <FormInputComponent
                                            id="firstname"
                                            name="firstname"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={driverslicense.firstname}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="lastname"
                                            name="lastname"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={driverslicense.lastname}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />

                                        <FormInputComponent
                                            id="birthdate"
                                            name="birthdate"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={driverslicense.birthdate}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="message"
                                            name="message"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={driverslicense.message}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="gender"
                                            name="gender"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={driverslicense.gender}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="stateOfIssue"
                                            name="stateOfIssue"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={driverslicense.stateOfIssue}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="issuedDate"
                                            name="issuedDate"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={driverslicense.issuedDate}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="expiryDate"
                                            name="expiryDate"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={driverslicense.expiryDate}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="driversLicense"
                                            name="driversLicense"
                                            type="number"
                                            placeholder=""
                                            divClass={6}
                                            value={driverslicense.driversLicense}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
    {!!driverslicense?.photo && <img style={{marginLeft:'auto', marginRight:5, marginTop:20, borderRadius: 5, }} 
                                        width={250} height={300} alt="Passport_Photo" src={`data:image/png;base64,${driverslicense.photo}`} />}
                                    </div>
                                </div>
                            </div>
                        )}
                        {passport == null ? ("") : (
                            <div className="card mt-2">
                                <div className="card-header clear-fix">
                                    <h6
                                        className="float-left"
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <b>Verification Details - INT'L PASSPORT</b>
                                    </h6>
                                </div>
                                <div className="card-body">
                                    <div className="form-row">
                                        <FormInputComponent
                                            id="firstname"
                                            name="firstname"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={passport.firstname}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="lastname"
                                            name="lastname"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={passport.lastname}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />

                                        <FormInputComponent
                                            id="middlename"
                                            name="middlename"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={passport.middlename}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                        />
                                        <FormInputComponent
                                            id="birthdate"
                                            name="birthdate"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={passport.birthdate}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                        />
                                        <FormInputComponent
                                            id="gender"
                                            name="gender"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={passport.gender}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="phone"
                                            name="phone"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={passport.phone}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="issuedAt"
                                            name="issuedAt"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={passport.issuedAt}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                        />
                                        <FormInputComponent
                                            id="issuedDate"
                                            name="issuedDate"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={passport.issuedDate}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="expiryDate"
                                            name="expiryDate"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={passport.expiryDate}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="passportNo"
                                            name="passportNo"
                                            type="number"
                                            placeholder=""
                                            divClass={6}
                                            value={passport.passportNo}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
    {!!passport?.photo && <img style={{marginLeft:'auto', marginRight:5, marginTop:20, borderRadius: 5, }} 
                                        width={250} height={300} alt="Passport_Photo" src={`data:image/png;base64,${passport.photo}`} />}
                                    </div>
                                </div>
                            </div>

                        )}
                        {bvn == null ? ("") : (
                            <div className="card mt-2">
                                <div className="card-header clear-fix">
                                    <h6
                                        className="float-left"
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <b>Verification Details - BVN</b>
                                    </h6>
                                </div>
                                <div className="card-body">
                                    <div className="form-row">
                                        <FormInputComponent
                                            id="firstname"
                                            name="firstname"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={bvn.firstname}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="lastname"
                                            name="lastname"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={bvn.lastname}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />

                                        <FormInputComponent
                                            id="middlename"
                                            name="middlename"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={bvn.middlename}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="birthdate"
                                            name="birthdate"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={bvn.birthdate}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="gender"
                                            name="gender"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={bvn.gender}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="phone"
                                            name="phone"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={bvn.phone}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="lgaOfOrigin"
                                            name="lgaOfOrigin"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={bvn.lgaOfOrigin}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="stateOfOrigin"
                                            name="stateOfOrigin"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={bvn.stateOfOrigin}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="maritalStatus"
                                            name="maritalStatus"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={bvn.maritalStatus}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="nationality"
                                            name="nationality"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={bvn.nationality}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="residentialAddress"
                                            name="residentialAddress"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={bvn.residentialAddress}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="stateOfResidence"
                                            name="stateOfResidence"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={bvn.stateOfResidence}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="enrollmentBank"
                                            name="enrollmentBank"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={bvn.enrollmentBank}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="email"
                                            name="email"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={bvn.email}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="bvn"
                                            name="bvn"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={bvn.bvn}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                            {!!bvn?.photo && <img style={{marginLeft:'auto', marginRight:5, marginTop:20, borderRadius: 5, }} 
                                        width={250} height={300} alt="Passport_Photo" src={`data:image/png;base64,${bvn.photo}`} />}

                                    </div>
                                    
                                </div>
                            </div>
                        )}
                        {cac == null ? ("") : (
                            <div className="card mt-2">
                                <div className="card-header clear-fix">
                                    <h6
                                        className="float-left"
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <b>Verification Details - CAC</b>
                                    </h6>
                                </div>
                                <div className="card-body">
                                    <div className="form-row">
                                        <FormInputComponent
                                            id="companyName"
                                            name="companyName"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={cac.companyName}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="rcNumber"
                                            name="rcNumber"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={cac.rcNumber}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />

                                        <FormInputComponent
                                            id="registrationDate"
                                            name="registrationDate"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={cac.registrationDate}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="companyEmail"
                                            name="companyEmail"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={cac.companyEmail}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="companyType"
                                            name="companyType"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={cac.companyType}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="headOfficeAddress"
                                            name="headOfficeAddress"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={cac.headOfficeAddress}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="shareCapital"
                                            name="shareCapital"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={cac.shareCapital}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="shareCapitalInWords"
                                            name="shareCapitalInWords"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={cac.shareCapitalInWords}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="affiliates"
                                            name="affiliates"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={cac.affiliates}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {tin == null ? ("") : (
                            <div className="card mt-2">
                                <div className="card-header clear-fix">
                                    <h6
                                        className="float-left"
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <b>Verification Details - TIN</b>
                                    </h6>
                                </div>
                                <div className="card-body">
                                    <div className="form-row">
                                        <FormInputComponent
                                            id="tin"
                                            name="tin"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={tin.tin}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="taxpayerName"
                                            name="taxpayerName"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={tin.taxpayerName}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />

                                        <FormInputComponent
                                            id="cacRegNo"
                                            name="cacRegNo"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={tin.cacRegNo}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="entityType"
                                            name="entityType"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={tin.entityType}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="jittin"
                                            name="jittin"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={tin.jittin}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="taxOffice"
                                            name="taxOffice"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={tin.taxOffice}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="phone"
                                            name="phone"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={tin.phone}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="email"
                                            name="email"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={tin.email}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={null}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        )}


                    </div>
                </div>
                {/* )} */}
            </>
        );
    }
}

export default IdVerificationDetails;

