import React from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import activityTrailService from "../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../components/BaseFormComponent";
import { getCurrentUserSession } from "../../services/auth/authService";
import FormInputComponent from "../../components/formInputComponent";
import disputeValidationService from "../../services/dispute/disputeValidationService";
import disputeConditionService from "../../services/dispute/disputeConditionService";
import { ActionTypes } from "../../enums/actionTypes";
import { decodeToken } from "react-jwt";

interface AddDisputeConditionFields {
  name: string;
  description: string;
  code: string;
  disputeFilingTimeLimit: string;
  maxResolutionTAT: string;
}

class AddDisputeConditionForm extends BaseFormComponent<AddDisputeConditionFields> { }

interface IAddDisputeConditionProps { }
interface IAddDisputeConditionState {
  disputeConditionDocuments: any;
  assignedDisputeConditionDocuments: any;
  fetchingDisputeConditionDocuments: boolean;
}

class AddDisputeCondition extends React.Component<
  IAddDisputeConditionProps,
  IBaseFormState & IAddDisputeConditionState
> {
  constructor(props: IAddDisputeConditionProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      disputeConditionDocuments: [],
      assignedDisputeConditionDocuments: [],
      fetchingDisputeConditionDocuments: false,
    };
  }

  async componentDidMount() {
    const { errors, submitting } = this.state;
    this.setState({ fetchingDisputeConditionDocuments: true });
    try {
      const disputeConditionDocuments =
        await disputeConditionService.retrieveAllDisputeConditionDocuments();
      //   console.log(
      //     "disputeConditionDocuments: ",
      //     disputeConditionDocuments.data
      //   );
      this.setState({
        disputeConditionDocuments: disputeConditionDocuments.data,
        fetchingDisputeConditionDocuments: false,
      });
    } catch (ex) {
      this.setState({ fetchingDisputeConditionDocuments: false });
      // console.log("caught exception", ex);
      if (ex.response && ex.response.status === 404) {
        this.setState({ submitting: !submitting });
        // console.log(ex.response);
        errors.response = "Internal server error.";
        // console.log(errors);
        this.setState({ errors });
      }
    }
  }

  onFormSubmit(
    fields: AddDisputeConditionFields,
    onReloadFieldsCB: any
  ): boolean {
    try {
      this.setState(
        {
          errors:
            disputeValidationService.validateAddDisputeConditionForm(fields),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddDisputeConditionFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      // console.log("starting submission...");
      this.setState({ submitting: true });
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const payload: any = {};
      payload.name = fields.name;
      payload.code = fields.code;
      payload.institutionId = decodedToken?.["InstitutionId"];
      payload.assignedDisputeConditionDocuments =
        this.state.assignedDisputeConditionDocuments;
      payload.maxResolutionTAT = fields.maxResolutionTAT;
      payload.description = fields.description;
      payload.disputeFilingTimeLimit = fields.disputeFilingTimeLimit;

      const response = await disputeConditionService.saveDisputeCondition(
        payload
      );
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Add Dispute Condition",
          description: `Added new Dispute Condition ${payload.name}`,
        });

        if (response.data.isLoggedForApproval === true) {
          toast.warn("Action has been successfully logged for Approval!", {
            autoClose: 6000,
            type: toast.TYPE.WARNING,
            position: "top-center",
            hideProgressBar: false,
          });
        } else {
          toast.info(`Dispute Condition ${fields.name} created successfully! `, {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          });
        }
      } else {
        return false;
      }
      this.setState({ submitting: false });
      onReloadFieldsCB();
      return true;
    } catch (error) {
      // console.log("error: ", error.response.data);
      this.setState({ submitting: false });
      return false;
    }
  }

  handleOnDisputeConditionDocumentChange = (e, item) => {
    let assignedDisputeConditionDocuments = [
      ...this.state.assignedDisputeConditionDocuments,
    ];
    // console.log("before", disputeConditionDocuments);
    if (assignedDisputeConditionDocuments.indexOf(item.id) !== -1) {
      assignedDisputeConditionDocuments.splice(
        assignedDisputeConditionDocuments.indexOf(item.id),
        1
      );
    } else {
      assignedDisputeConditionDocuments.push(item.id);
    }
    // console.log("after", disputeConditionDocuments);
    this.setState({ assignedDisputeConditionDocuments });
  };

  validateField = (fieldName: string, type: string, validated: boolean) => {
    let defaultClass = "";
    if (type === "select") {
      defaultClass =
        "custom-select select2 mr-sm-2 form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    } else {
      defaultClass = "form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    }
    return !validated
      ? defaultClass
      : this.state.errors[fieldName]
        ? `${defaultClass} is-invalid`
        : `${defaultClass} is-valid`;
  };

  render() {
    const {
      errors,
      validated,
      submitting,
      disputeConditionDocuments,
      fetchingDisputeConditionDocuments,
    } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add Dispute Condition</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddDisputeConditionForm
                  initialValues={{
                    name: "",
                    code: "",
                    disputeFilingTimeLimit: "Unlimited",
                    maxResolutionTAT: "",
                    description: "",
                  }}
                  FormComponent={({
                    fields: {
                      name,
                      code,
                      disputeFilingTimeLimit,
                      maxResolutionTAT,
                      description,
                    },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <h5 className="card-title">GENERAL INFORMATION</h5>
                          <div className="form-row">
                            <div className="col-md-12">
                              <div className="form-row">
                                <FormInputComponent
                                  id="name"
                                  type="text"
                                  name="name"
                                  placeholder=""
                                  divClass={6}
                                  value={name}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="code"
                                  type="text"
                                  name="code"
                                  placeholder=""
                                  divClass={6}
                                  value={code}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-row">
                                <FormInputComponent
                                  id="maxResolutionTAT"
                                  type="number"
                                  name="maxResolutionTAT (hours)"
                                  placeholder=""
                                  divClass={6}
                                  value={maxResolutionTAT}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <div className="form-group col-md-6 m-0">
                                  <label
                                    htmlFor="disputeFilingTimeLimit"
                                    className="col-form-label s-12"
                                  >
                                    <span
                                      aria-hidden="true"
                                      className="mr-2"
                                      style={{ color: "#ed5564" }}
                                    >
                                      *
                                    </span>
                                    Dispute Filing Time Limit
                                  </label>
                                  <div className="col">
                                    <div className="custom-control custom-radio custom-control-inline">
                                      <input
                                        type="radio"
                                        className="custom-control-input"
                                        id="_180_Days"
                                        name="disputeFilingTimeLimit"
                                        onChange={(e) =>
                                          onChange(
                                            "disputeFilingTimeLimit",
                                            e.currentTarget.id
                                          )
                                        }
                                        checked={
                                          disputeFilingTimeLimit === "_180_Days"
                                        }
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="_180_Days"
                                      >
                                        180 Days
                                      </label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                      <input
                                        type="radio"
                                        className="custom-control-input"
                                        id="_1_Year"
                                        name="disputeFilingTimeLimit"
                                        onChange={(e) =>
                                          onChange(
                                            "disputeFilingTimeLimit",
                                            e.currentTarget.id
                                          )
                                        }
                                        checked={
                                          disputeFilingTimeLimit === "_1_Year"
                                        }
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="_1_Year"
                                      >
                                        1 Year
                                      </label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                      <input
                                        type="radio"
                                        className="custom-control-input"
                                        id="Unlimited"
                                        name="disputeFilingTimeLimit"
                                        onChange={(e) =>
                                          onChange(
                                            "disputeFilingTimeLimit",
                                            e.currentTarget.id
                                          )
                                        }
                                        checked={
                                          disputeFilingTimeLimit === "Unlimited"
                                        }
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="Unlimited"
                                      >
                                        Unlimited
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group row">
                                <div className="form-group col m-0">
                                  <label
                                    htmlFor="description"
                                    className="col-form-label"
                                  >
                                    <span
                                      aria-hidden="true"
                                      className="mr-2"
                                      style={{ color: "#ed5564" }}
                                    >
                                      *
                                    </span>
                                    Description
                                  </label>
                                  <div>
                                    <textarea
                                      rows={2}
                                      className={this.validateField(
                                        "description",
                                        "text",
                                        validated
                                      )}
                                      id="description"
                                      name="description"
                                      value={description}
                                      onChange={(e) =>
                                        onChange(
                                          "description",
                                          e.currentTarget.value
                                        )
                                      }
                                    />
                                    <div
                                      className="invalid-feedback"
                                      style={{ visibility: errors.description }}
                                    >
                                      {errors.description}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="inputDisputeConditionDocuments"
                                  className="col-md-3 col-form-label"
                                >
                                  Dispute Condition Documents:
                                </label>
                                <div className="col-md-9 co-form-label">
                                  {fetchingDisputeConditionDocuments ? (
                                    <React.Fragment>
                                      <div
                                        className="text-center"
                                        style={{ textAlign: "center" }}
                                      >
                                        <i className="fas fa-spin fa-2x fa-circle-notch"></i>
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <ul className="list-unstyled row">
                                        {disputeConditionDocuments.map(
                                          (item) => (
                                            <li
                                              key={item.name}
                                              className="col-md-4"
                                            >
                                              <div className="custom-control custom-checkbox">
                                                <input
                                                  id={item.name}
                                                  name={item.name}
                                                  className="custom-control-input"
                                                  type="checkbox"
                                                  onChange={(e) =>
                                                    this.handleOnDisputeConditionDocumentChange(
                                                      e,
                                                      item
                                                    )
                                                  }
                                                  checked={
                                                    _.isEmpty(
                                                      this.state.assignedDisputeConditionDocuments.filter(
                                                        (x) => x === item.id
                                                      )
                                                    )
                                                      ? false
                                                      : true
                                                  }
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor={item.name}
                                                >
                                                  <b>
                                                    {item.name}
                                                    {/* <span style={{ color: "blue" }}>
                                      [{splitAtUpper(item.type)}]
                                    </span> */}
                                                  </b>
                                                  <p>
                                                    <i style={{ color: "red" }}>
                                                      {item.description}
                                                    </i>
                                                  </p>
                                                </label>
                                              </div>
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </React.Fragment>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="card-body">
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                            disabled={submitting}
                            onClick={(e) => {
                              onHandleSubmit(
                                e,
                                ActionTypes.SAVE,
                                "Dispute Condition",
                                () => {
                                  this.onFormSubmit(
                                    {
                                      maxResolutionTAT,
                                      disputeFilingTimeLimit,
                                      code,
                                      name,
                                      description,
                                    },
                                    onReloadFields
                                  );
                                }
                              );
                            }}
                          >
                            {submitting === false ? (
                              <React.Fragment>
                                <i className="fas fa-lg fa-save mr-3" /> Save
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                Please wait...
                              </React.Fragment>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                ></AddDisputeConditionForm>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddDisputeCondition;
