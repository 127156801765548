import React, { Fragment, useCallback, useEffect, useState } from "react";
import "../fam.css";
import DetailItem from "../../../../../../../../components/detailItem";
import { toast } from "react-toastify";
import corporateAnalysisService from "../../../../../../../../services/hybridAppraisal/corporateAnalysisService";
import {
  downloadBlobAsFile,
  getBase64StringFromFile,
} from "../../../../../../../../services/utility/textFormatService";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import { CogsModal } from "./modals/cogs";
import { IncomeStatementModal } from "./modals/incomeStatement";
import { BalanceSheetModal } from "./modals/balanceSheet";
import { CashflowModal } from "./modals/cashflow";

export default function FinancialStatement({ loan }: { loan: any }) {
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [activeModalProps, setActiveModalProps] = useState<{
    modalKey: DownloadKey;
    label: string;
  }>({ modalKey: "income statement", label: "Income Statement" });
  // const [loading, setLoading] = useState<Record<DownloadKey, boolean>>({
  //   "income statement": false,
  //   cogs: false,
  //   "balance sheet": false,
  //   cashflow: false,
  // });
  const [allLoading, setAllLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState<
    Record<DownloadKey, boolean>
  >({
    "income statement": false,
    cogs: false,
    "balance sheet": false,
    cashflow: false,
  });
  const [downloadLoading, setDownloadLoading] = useState<
    Record<DownloadKey, boolean>
  >({
    "income statement": false,
    cogs: false,
    "balance sheet": false,
    cashflow: false,
  });
  const [isValidated, setIsValidated] = useState<Record<
    DownloadKey,
    { value: boolean; loading: boolean }
  > | null>({
    "income statement": { value: false, loading: false },
    cogs: { value: false, loading: false },
    "balance sheet": { value: false, loading: false },
    cashflow: { value: false, loading: false },
  });

  let years = [];
  const base_year = 2015;
  const current_year = Number(new Date().getFullYear());
  const [startYear, setStartYear] = useState(current_year.toString());
  const [startMonth, setStartMonth] = useState("January");
  const [endYear, setEndYear] = useState(current_year.toString());
  const [endMonth, setEndMonth] = useState("March");
  const [file, setFile] = useState<Record<DownloadKey, File> | null>({
    "income statement": null,
    cogs: null,
    "balance sheet": null,
    cashflow: null,
  });
  const [cogs, setCogs] = useState<any>();
  const [incomeStatement, setIncomeStatement] = useState<any>();
  const [balanceSheet, setBalanceSheet] = useState<any>();
  const [cashFlow, setCashFlow] = useState<any>();
  const [isUploaded, setIsUploaded] = useState<any>();

  useEffect(() => {
    const getUploadStatus = async () => {
      const response = await corporateAnalysisService.checkUploaded({
        loanRequestId: loan?.id,
      });
      if (response?.status === 200) {
        setIsUploaded(response?.data);
      }
    };
    getUploadStatus();
  }, [loan]);

  useEffect(() => {
    if (!!isUploaded?.incomeData) {
      const getIncomeStatement = async () => {
        const response =
          await corporateAnalysisService.previewIncomeStatementRecords({
            loanRequestId: loan?.id,
          });
        if (response?.status === 200) {
          setIncomeStatement(response?.data);
          setIsValidated((prev) => ({
            ...prev,
            "income statement": { value: true, loading: false },
          }));
        }
      };
      getIncomeStatement();
    }
    if (!!isUploaded?.cogsData) {
      const getCogs = async () => {
        const response = await corporateAnalysisService.previewCogsRecords({
          loanRequestId: loan?.id,
        });
        if (response?.status === 200) {
          setCogs(response?.data);
          setIsValidated((prev) => ({
            ...prev,
            cogs: { value: true, loading: false },
          }));
        }
      };
      getCogs();
    }
    if (!!isUploaded?.balanceSheetData) {
      const getBalanceSheet = async () => {
        const response =
          await corporateAnalysisService.previewBalanceSheetRecords({
            loanRequestId: loan?.id,
          });
        if (response?.status === 200) {
          setBalanceSheet(response?.data);
          setIsValidated((prev) => ({
            ...prev,
            "balance sheet": { value: true, loading: false },
          }));
        }
      };
      getBalanceSheet();
    }
    if (!!isUploaded?.cashFlowData) {
      const getCashflow = async () => {
        const response = await corporateAnalysisService.previewCashFlowRecords({
          loanRequestId: loan?.id,
        });
        if (response?.status === 200) {
          setCashFlow(response?.data);
          setIsValidated((prev) => ({
            ...prev,
            cashflow: { value: true, loading: false },
          }));
        }
      };
      getCashflow();
    }
  }, [loan, isUploaded]);

  const canBulkUpload = Object.values(isValidated).every(
    ({ value }) => value === true
  );

  for (let i = 0; i < current_year - base_year + 2; i++) {
    years[i + 1] = (current_year - i).toString();
  }

  const handleDownloadTemplate = useCallback(
    async (type: DownloadKey) => {
      const timeFrame = {
        endMonth,
        endYear,
        startMonth,
        startYear,
      };
      try {
        setDownloadLoading((prev) => ({ ...prev, [type]: true }));
        if (type === "income statement") {
          const downloadable =
            await corporateAnalysisService.downloadIncomeStatementTemplate(
              timeFrame
            );
          if (downloadable?.statusText === "OK") {
            await downloadBlobAsFile(
              downloadable?.data,
              "IncomeStatementUploadTemplate.xlsx"
            );
          }
        }
        if (type === "cogs") {
          const downloadable =
            await corporateAnalysisService.downloadCogsTemplate(timeFrame);
          if (downloadable?.statusText === "OK") {
            await downloadBlobAsFile(
              downloadable?.data,
              "CogsInputUploadTemplate.xlsx"
            );
          }
        }
        if (type === "balance sheet") {
          const downloadable =
            await corporateAnalysisService.downloadBalanceSheetTemplate(
              timeFrame
            );
          if (downloadable?.statusText === "OK") {
            await downloadBlobAsFile(
              downloadable?.data,
              "BalanceSheetUploadTemplate.xlsx"
            );
          }
        }
        if (type === "cashflow") {
          const downloadable =
            await corporateAnalysisService.downloadCashFlowTemplate(timeFrame);
          if (downloadable?.statusText === "OK") {
            await downloadBlobAsFile(
              downloadable?.data,
              "CashFlowUploadTemplate.xlsx"
            );
          }
        }
        setDownloadLoading((prev) => ({ ...prev, [type]: false }));
      } catch (error) {
        setDownloadLoading((prev) => ({ ...prev, [type]: false }));
      }
    },
    [endMonth, endYear, startMonth, startYear]
  );

  const onDocumentChange = useCallback(
    async (
      e: React.ChangeEvent<HTMLInputElement>,
      uploadKey: DownloadKey,
      label
    ) => {
      e.preventDefault();
      setIsValidated((prev) => ({
        ...prev,
        [uploadKey]: { value: false, loading: true },
      }));
      if (e.target.files[0] === undefined) {
        return;
      }
      if (e.target.files[0].size > 1000000) {
        toast.error(
          "Please confirm that the size of the document is less than 1MB"
        );
      } else if (
        !["xlsx", "xls"].includes(e.target.files[0].name.split(".")[1])
      ) {
        toast.error(
          "Please confirm that the document is a valid excel document"
        );
      } else {
        try {
          const newFile = { ...file, [uploadKey]: e?.target?.files?.[0] };
          setFile(newFile);
          const payload = {
            institutionCode: loan?.institutionCode,
            loanRequestId: loan?.id,
            famInput: await getBase64StringFromFile(e.target.files[0]),
          };
          const response =
            uploadKey === "income statement"
              ? await corporateAnalysisService.validateIncomeStatementRecords(
                  payload
                )
              : uploadKey === "cogs"
              ? await corporateAnalysisService.validateCogsRecords(payload)
              : uploadKey === "balance sheet"
              ? await corporateAnalysisService.validateBalanceSheetRecords(
                  payload
                )
              : uploadKey === "cashflow"
              ? await corporateAnalysisService.validateCashFlowRecords(payload)
              : null;

          if (!!response?.data?.item1?.entriesAreValid) {
            toast.success(`${label} file validated successfully`);
            setIsValidated((prev) => ({
              ...prev,
              [uploadKey]: { value: true, loading: false },
            }));
          } else {
            toast.error(response?.data?.item2?.[0]);
            setIsValidated((prev) => ({
              ...prev,
              [uploadKey]: { value: false, loading: false },
            }));
            setFile((prev) => ({ ...prev, [uploadKey]: null }));
          }
        } catch (error) {
          setIsValidated((prev) => ({
            ...prev,
            [uploadKey]: { value: false, loading: false },
          }));
          setFile((prev) => ({ ...prev, [uploadKey]: null }));
        }
      }
    },
    [loan, file]
  );

  const handlePreview = useCallback(
    async (previewKey: DownloadKey, label: string) => {
      try {
        setPreviewLoading((prev) => ({ ...prev, [previewKey]: true }));
        const payload = {
          loanRequestId: loan?.id,
        };
        const response =
          previewKey === "income statement"
            ? await corporateAnalysisService.previewIncomeStatementRecords(
                payload
              )
            : previewKey === "cogs"
            ? await corporateAnalysisService.previewCogsRecords(payload)
            : previewKey === "balance sheet"
            ? await corporateAnalysisService.previewBalanceSheetRecords(payload)
            : previewKey === "cashflow"
            ? await corporateAnalysisService.previewCashFlowRecords(payload)
            : null;

        if (response?.status === 200) {
          if (previewKey === "income statement") {
            setIncomeStatement(response?.data);
          }
          if (previewKey === "cogs") {
            setCogs(response?.data);
          }
          if (previewKey === "balance sheet") {
            setBalanceSheet(response?.data);
          }
          if (previewKey === "cashflow") {
            setCashFlow(response?.data);
          }
          setPreviewLoading((prev) => ({ ...prev, [previewKey]: false }));
          setActiveModalProps({ modalKey: previewKey, label });
          setOpenPreviewModal(true);
        }
      } catch (error) {
        setPreviewLoading((prev) => ({ ...prev, [previewKey]: false }));
      }
    },
    [loan]
  );

  // const handleUploadFile = useCallback(
  //   async (uploadKey: DownloadKey, label: string) => {
  //     if (isValidated?.[uploadKey]?.value) {
  //       const payload = {
  //         institutionCode: loan?.institutionCode,
  //         loanRequestId: loan?.id,
  //         famInput: await getBase64StringFromFile(file?.[uploadKey]),
  //       };
  //       try {
  //         setLoading((prev) => ({ ...prev, [uploadKey]: true }));
  //         if (uploadKey === "income statement") {
  //           await corporateAnalysisService.uploadIncomeStatementRecords(
  //             payload
  //           );
  //         }
  //         if (uploadKey === "cogs") {
  //           await corporateAnalysisService.uploadCogsRecords(payload);
  //         }
  //         if (uploadKey === "balance sheet") {
  //           await corporateAnalysisService.uploadBalanceSheetRecords(payload);
  //         }
  //         if (uploadKey === "cashflow") {
  //           await corporateAnalysisService.uploadCashFlowRecords(payload);
  //         }
  //         toast.success(`${label} uploaded successfully`);
  //         setLoading((prev) => ({ ...prev, [uploadKey]: false }));
  //       } catch (error) {
  //         setLoading((prev) => ({ ...prev, [uploadKey]: false }));
  //       }
  //     }
  //   },
  //   [loan, file, isValidated]
  // );

  const handleUploadAllFiles = useCallback(async () => {
    const payload = {
      institutionCode: loan?.institutionCode,
      loanRequestId: loan?.id,
    };
    try {
      setAllLoading(true);
      if (!!file?.cogs) {
        await corporateAnalysisService.uploadCogsRecords({
          ...payload,
          famInput: await getBase64StringFromFile(file?.cogs),
        });
      }
      if (!!file?.["income statement"]) {
        await corporateAnalysisService.uploadIncomeStatementRecords({
          ...payload,
          famInput: await getBase64StringFromFile(file?.["income statement"]),
        });
      }
      if (!!file?.["balance sheet"]) {
        await corporateAnalysisService.uploadBalanceSheetRecords({
          ...payload,
          famInput: await getBase64StringFromFile(file?.["balance sheet"]),
        });
      }
      if (!!file?.cashflow) {
        await corporateAnalysisService.uploadCashFlowRecords({
          ...payload,
          famInput: await getBase64StringFromFile(file?.cashflow),
        });
      }
      toast.success(`Financial Statement uploaded successfully`);
      setAllLoading(false);
    } catch (error) {
      setAllLoading(false);
    }
  }, [loan, file]);

  const downloads: {
    key: DownloadKey;
    label: string;
  }[] = [
    {
      key: "income statement",
      label: "Income Statement",
    },
    {
      key: "cogs",
      label: "COGS",
    },
    {
      key: "balance sheet",
      label: "Balance Sheet",
    },
    {
      key: "cashflow",
      label: "Cashflow",
    },
  ];

  return (
    <div>
      <form>
        <div className="col-grid-2">
          <div>
            <label htmlFor="year">Start Year</label>
            <select
              required={true}
              name="startYear"
              className="basic-select"
              value={startYear}
              defaultValue={""}
              onChange={(e) => setStartYear(e.target.value)}
            >
              {years?.map((year) => (
                <option key={year}>{year}</option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="month">Start Month</label>
            <select
              required={true}
              name="startMonth"
              className="basic-select"
              value={startMonth}
              defaultValue={""}
              onChange={(e) => setStartMonth(e.target.value)}
            >
              {months?.map((month) => (
                <option key={month}>{month}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-grid-2">
          <div>
            <label className="mt-1" htmlFor="year">
              End Year
            </label>
            <select
              required={true}
              name="EndYear"
              className="basic-select"
              value={endYear}
              defaultValue={""}
              onChange={(e) => setEndYear(e.target.value)}
            >
              {years?.map((year) => (
                <option key={year}>{year}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="mt-1" htmlFor="month">
              End Month
            </label>
            <select
              required={true}
              name="endMonth"
              className="basic-select"
              value={endMonth}
              defaultValue={""}
              onChange={(e) => setEndMonth(e.target.value)}
            >
              {months?.map((month) => (
                <option key={month}>{month}</option>
              ))}
            </select>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {downloads?.map(({ label, key: downloadKey }) => (
            <button
              key={downloadKey}
              className="btn btn-sm btn-outline-primary my-4"
              style={{ cursor: "pointer" }}
              type="button"
              disabled={false}
              onClick={() => handleDownloadTemplate(downloadKey)}
            >
              {!downloadLoading?.[downloadKey] ? (
                <React.Fragment>
                  <i className="far fa-save fa-lg mr-2" />{" "}
                  {`Download ${label} Template`}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <i className="fas fa-spinner fa-spin" />
                </React.Fragment>
              )}
            </button>
          ))}
        </div>
        {downloads.map(({ key: uploadKey, label }) => (
          <Fragment key={uploadKey}>
            <div className="form-row mt-5">
              <DetailItem
                label={`Upload ${label} file`}
                value={
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        aria-describedby="inputGroupFileAddon01"
                        onChange={(e) => onDocumentChange(e, uploadKey, label)}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="docToUpload"
                      >
                        {!file ? "Choose File" : file?.[uploadKey]?.name}
                      </label>
                    </div>
                  </div>
                }
                labelSize={3}
                valueSize={9}
              />
            </div>
            <div
              style={{ display: "flex", alignItems: "center" }}
              className="form-row"
            >
              {/* <div className="mx-2">
                {!file ? (
                  ""
                ) : (
                  <>
                    {!!isValidated?.[uploadKey]?.value && (
                      <button
                        className="btn btn-sm btn-outline-primary my-4"
                        style={{ cursor: "pointer" }}
                        type="button"
                        onClick={() => handleUploadFile(uploadKey, label)}
                      >
                        {!loading?.[uploadKey] ? (
                          <React.Fragment>
                            <i className="fas fa-cloud-upload-alt fa-lg mr-2" />{" "}
                            Upload
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <i className="fas fa-spinner fa-spin" />
                          </React.Fragment>
                        )}
                      </button>
                    )}
                    {!!isValidated?.[uploadKey]?.loading && (
                      <React.Fragment>
                        <i className="fas fa-spinner fa-spin" />
                      </React.Fragment>
                    )}
                  </>
                )}
              </div> */}
              {!!isValidated?.[uploadKey]?.value && (
                <button
                  className="btn btn-sm btn-outline-primary my-4"
                  style={{ cursor: "pointer", height: 33 }}
                  type="button"
                  onClick={() => {
                    const data =
                      uploadKey === "income statement"
                        ? incomeStatement
                        : uploadKey === "cogs"
                        ? cogs
                        : uploadKey === "balance sheet"
                        ? balanceSheet
                        : uploadKey === "cashflow"
                        ? cashFlow
                        : [];
                    if (_.isEmpty(data)) {
                      handlePreview(uploadKey, label);
                    } else {
                      setOpenPreviewModal(true);
                      setActiveModalProps({ modalKey: uploadKey, label });
                    }
                  }}
                >
                  {!previewLoading?.[uploadKey] ? (
                    <React.Fragment>
                      <i className="fas fa-eye fa-lg mr-2" /> Preview
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spinner fa-spin" />
                    </React.Fragment>
                  )}
                </button>
              )}
            </div>
          </Fragment>
        ))}
        {!!canBulkUpload && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="btn btn-sm btn-outline-primary my-4"
              style={{ cursor: "pointer" }}
              type="button"
              onClick={handleUploadAllFiles}
            >
              {!allLoading ? (
                <React.Fragment>
                  <i className="fas fa-cloud-upload-alt fa-lg mr-2" /> Upload
                  Financial Statement
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <i className="fas fa-spinner fa-spin" />
                </React.Fragment>
              )}
            </button>
          </div>
        )}
        <Modal
          size="lg"
          backdrop="static"
          show={openPreviewModal}
          onHide={() => setOpenPreviewModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h5>
                <i className="icon-box-filled2 mr-3"></i>{" "}
                {activeModalProps?.label}
              </h5>
            </Modal.Title>
          </Modal.Header>
          {activeModalProps?.modalKey === "income statement" && (
            <IncomeStatementModal incomeStatement={incomeStatement} />
          )}
          {activeModalProps?.modalKey === "cogs" && <CogsModal cogs={cogs} />}
          {activeModalProps?.modalKey === "balance sheet" && (
            <BalanceSheetModal balanceSheet={balanceSheet} />
          )}
          {activeModalProps?.modalKey === "cashflow" && (
            <CashflowModal cashflow={cashFlow} />
          )}
        </Modal>
      </form>
    </div>
  );
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
type DownloadKey = "income statement" | "cogs" | "balance sheet" | "cashflow";
