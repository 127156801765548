import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../components/detailItem";

interface IDisputeConditionDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  showTrail: any;
  item: any;
  currentUserRoles: any;
}

interface IDisputeConditionDetailState {}

class DisputeConditionDetail extends Component<
  IDisputeConditionDetailProps,
  IDisputeConditionDetailState
> {
  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      showTrail,
      currentUserRoles,
      item,
    } = this.props;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="fab fa-first-order-alt s-18 mr-3" />
                Dispute Condition Details -{" "}
                <span className="font-weight-bold">{item.name}</span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
              <div className="float-right">
                {_.isEmpty(item) ? (
                  <Skeleton width={100} />
                ) : (
                  <span
                    className="badge btn-primary"
                    onClick={showTrail}
                    style={{
                      cursor: "pointer",
                      visibility: currentUserRoles.includes(
                        "viewaudittrailitems"
                      )
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    View Trail
                  </span>
                )}
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={2} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Name"
                        labelSize={6}
                        valueSize={6}
                        value={item.name}
                      />
                      <DetailItem
                        label="Dispute Filing Time Limit"
                        labelSize={6}
                        valueSize={6}
                        value={item.disputeFilingTimeLimit}
                      />
                      <DetailItem
                        label="Description"
                        labelSize={6}
                        valueSize={6}
                        value={item.description}
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={2} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Code"
                        labelSize={6}
                        valueSize={6}
                        value={item.code}
                      />
                      <DetailItem
                        label="Max Resolution TAT (Hours)"
                        labelSize={6}
                        valueSize={6}
                        value={item.maxResolutionTAT}
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>
            <div
              className="card-header card-footer clear-fix"
              style={{ padding: "0px", height: "35px", paddingTop: "5px" }}
            >
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <React.Fragment>
                  <i className="fas fa-list-alt mr-3 ml-3" /> Dispute Condition
                  Documents
                </React.Fragment>
              )}
            </div>
            <div className="card-body">
              <div className="card-content">
                {_.isEmpty(item) ? (
                  <Skeleton count={5} height={20} width={300} />
                ) : (
                  <ul className="list-unstyled row">
                    {item.disputeConditionDocuments.map((document) => (
                      <li key={document.id} className="col-md-4">
                        <div className="custom-control custom-checkbox">
                          <input
                            id={document.name}
                            name={document.name}
                            className="custom-control-input"
                            type="checkbox"
                            // onChange={() => {}}
                            checked={true}
                            disabled
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={document.name}
                          >
                            <b>
                              {document.name}
                              {/* <span style={{ color: "blue" }}>
                                      [{splitAtUpper(document.type)}]
                                    </span> */}
                            </b>
                            <p>
                              <i style={{ color: "red" }}>
                                {document.description}
                              </i>
                            </p>
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={1} height={30} />
          ) : (
            <React.Fragment>
              <Button size="sm" variant="primary" onClick={toggleEditModal}>
                <i className="far fa-edit mr-3" />
                Edit
              </Button>
            </React.Fragment>
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DisputeConditionDetail;
