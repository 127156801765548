import React, { Component, useContext } from "react";
import {
    Button,
    Card,
    useAccordionToggle,
    AccordionContext,
    Accordion,
    Modal,
} from "react-bootstrap";
import _ from "lodash";
import DetailItem from "../../../../components/detailItem";
import BaseFormComponent from "../../../../components/BaseFormComponent";
import BaseListComponent from "../../../../components/BaseListComponent";
import { generateGUID } from "../../../../services/utility/textFormatService";
import textFormatService from "../../../../services/utility/textFormatService";
import groupLoanRequestMembersService from "../../../../services/hybridAppraisal/groupLoanRequestMembersService";
import { toast } from "react-toastify";
import GroupPolicyScoringResult from "../../loanAppraisal/appraisalDetailPages/scoringReportModal";

export function ContextAwareToggle({ eventKey, header }) {
    const currentEventKey = useContext(AccordionContext);
    const toggleOnClick = useAccordionToggle(eventKey);
    const isCurrentEventKey = currentEventKey === eventKey;
    const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
    return (
        <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
            <b>{header}</b>
            <i
                className={angleType}
                aria-hidden="true"
                style={{ float: "right" }}
            ></i>
        </Card.Header>
    );
}

interface PolicyScoringCheckFields {
    accountNumber: string;
    statementType: string;
    bankName: string;
}

class PolicyScoringCheckForm extends BaseFormComponent<PolicyScoringCheckFields> { }

interface PolicyScoringCheckProps {
    item: any;
    member: any;
    systemProcess: any;
    userRoles: any;
    currentUserSession: any;
    togglePatchModal: any;
    showPatchModal: boolean;
}

interface PolicyScoringCheckState {
    item: any;
    userRoles: any;
    systemProcess: any;
    policyResponse: any;
    scoreResponse: any;
    ownUpdate: boolean;
    submitting: boolean;
    member: any;
    groupMember: any;
    acceptanceScore: string;
    showScoringResult: boolean;
}

class PolicyScoringList extends BaseListComponent<PolicyScoringCheckState> { }

class GroupPolicyScoringCheck extends Component<PolicyScoringCheckProps, PolicyScoringCheckState> {
    _isMounted = false;
    constructor(props: PolicyScoringCheckProps) {
        super(props);
        this.state = {
            item: {},
            userRoles: [],
            systemProcess: [],
            policyResponse: [],
            scoreResponse: [],
            ownUpdate: false,
            submitting: false,
            member: {},
            groupMember: {},
            acceptanceScore: "",
            showScoringResult: false
        };
    }

    static getDerivedStateFromProps(nextProps: PolicyScoringCheckProps, prevState) {
        if (prevState.ownUpdate) {
            return {
                ownUpdate: false,
            };
        }
        if (_.isNull(nextProps.item)) {
            return null;
        }
        return {
            item: nextProps.item,
            member: nextProps.member,
            userRoles: nextProps.userRoles,
            systemProcess: nextProps.systemProcess
        };
    }

    approveAppraisal = async (id) => {
        // console.log(id);
        const memberDetails = await groupLoanRequestMembersService.retrieveById(id)
        const memberDetail = memberDetails.data;

        const payload: any = { ...memberDetail };

        payload.memberStatus = "Eligible";

        const response = await groupLoanRequestMembersService.updateLoanRequest(payload);
        if (response.status >= 200 && response.status <= 300) {
            toast.info(`Member Approved!`, {
                autoClose: 6000,
                type: toast.TYPE.DEFAULT,
                hideProgressBar: false,
            });
        } else {
            return false;
        }


    };

    toggleShowScoringModal = () => {
        this.setState({ showScoringResult: !this.state.showScoringResult });
    };

    async componentDidMount() {
        this._isMounted = true;


        const acceptanceScore = this.props.item.financialGroup.rules.filter(
            (rule) =>
                rule.level === this.props.item.currentLevel &&
                rule.systemProcessID === Number(8)
        )[0]?.acceptanceScore;

        const policyResponse = this.props.member[0]?.policyResponse;
        const scoreResponse = this.props.member[0]?.scoringResponse;

        console.log(scoreResponse)

        const groupMember = await groupLoanRequestMembersService.retrieveById(this.props.member[0]?.id)
        const groupMemberDetail = groupMember.data
        // console.log(this.props.item.appraisalActions.filter(x => x.systemProcessID == 2)[0]?.processResponse?.response)
        // console.log(policyResponse)
        if (this._isMounted) {
            this.setState({ policyResponse: policyResponse, scoreResponse: scoreResponse, groupMember: groupMemberDetail, acceptanceScore: acceptanceScore, ownUpdate: true });
        }

    }
    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        const {
            item,
            systemProcess,
            userRoles,
            showPatchModal,
            togglePatchModal,
            member
        } = this.props;
        const { policyResponse, scoreResponse, groupMember } = this.state;

        return (
            <Modal
                size="lg"
                backdrop="static"
                show={showPatchModal}
                onHide={togglePatchModal}
            >
                <Modal.Header>
                    <Modal.Title>
                        <h5>
                            <i className="fas fa-edit fa-sm cyan-text mr-3" />
                            Policy & Scoring Result
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Reports */}
                    <div className="card mt-3">
                        <Accordion defaultActiveKey="0">
                            <div>
                                <Card>
                                    <ContextAwareToggle
                                        eventKey={`0`}
                                        header={<React.Fragment>
                                            <i
                                                className="fa fa-info-circle"
                                                aria-hidden="true"
                                                style={{ color: "#878a8a" }}
                                            ></i>{" "}
                                            Policy Result
                                        </React.Fragment>}
                                    ></ContextAwareToggle>
                                    <Accordion.Collapse eventKey={`0`}>
                                        <Card.Body>
                                            <table className="table table-hover table-content table-sm table-striped mb-0">
                                                <thead>
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>Rule Description</strong>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                // width: "5px",
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>Application Value</strong>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                // width: "5px",
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>Application Result</strong>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                // width: "5px",
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>Application String</strong>
                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* {console.log(this.props.member)}
                                                    {console.log(scoreResponse)} */}
                                                    {policyResponse?.length === 0 ? (
                                                        <React.Fragment>
                                                            <tr>
                                                                <td>
                                                                    No Decisions Made yet
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ) : (
                                                        policyResponse?.map((action) => (
                                                            <React.Fragment key={generateGUID()}>
                                                                <tr>
                                                                    <td
                                                                        style={{
                                                                            paddingTop: "0.2rem",
                                                                            paddingBottom: "0.2rem",
                                                                            fontSize: "small",
                                                                        }}
                                                                    >
                                                                        {action.ruleDescription}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            // width: "5px",
                                                                            paddingTop: "0.2rem",
                                                                            paddingBottom: "0.2rem",
                                                                            fontSize: "small",
                                                                        }}
                                                                    >
                                                                        {action.ruleDescription === "OBLIGOR LIMIT" ? textFormatService.formatCurrency(Number(action.loanParameter), 2) : action.loanParameter}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            paddingTop: "0.2rem",
                                                                            paddingBottom: "0.2rem",
                                                                            fontSize: "small",
                                                                        }}
                                                                    >
                                                                        {/* {console.log(systemProcess?.filter(x => x.id === Number(action.systemProcessID)))[0]?.name} */}
                                                                        {action.applicationResult}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            paddingTop: "0.2rem",
                                                                            paddingBottom: "0.2rem",
                                                                            fontSize: "small",
                                                                        }}
                                                                    >
                                                                        {/* {action.logType === "SystemType" ? (systemProcess?.filter(x => x.id === Number(action.systemProcessID))[0]?.name) :
                                                                                            (userRoles.filter(x => x.id === Number(action.roleID))[0]?.name)}
                                                                                             */}
                                                                        {action.applicationString}
                                                                    </td>

                                                                </tr>
                                                                {/* ))} */}
                                                            </React.Fragment>
                                                        ))
                                                    )}
                                                </tbody>
                                            </table>
                                            <div className="card-body">
                                                <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                    <div className="row">

                                                        <div className="col-md-6">
                                                            <dl className="row">
                                                                <DetailItem
                                                                    label="Policy Result"
                                                                    labelSize={7}
                                                                    valueSize={5}
                                                                    value={member[0]?.policyResult}
                                                                />
                                                            </dl>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </div>
                        </Accordion>
                    </div>

                    <div className="card mt-3">
                        <Accordion defaultActiveKey="0">
                            <div>
                                <Card>
                                    <ContextAwareToggle
                                        eventKey={`0`}
                                        header={<React.Fragment>
                                            <i
                                                className="fa fa-info-circle"
                                                aria-hidden="true"
                                                style={{ color: "#878a8a" }}
                                            ></i>{" "}
                                            Scoring Result
                                        </React.Fragment>}
                                    ></ContextAwareToggle>
                                    <Accordion.Collapse eventKey={`0`}>
                                        <Card.Body>
                                            <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <dl className="row">
                                                            <DetailItem
                                                                label="Application Score"
                                                                labelSize={7}
                                                                valueSize={3}
                                                                value={scoreResponse?.TotalScore}
                                                            />
                                                        </dl>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <dl className="row">
                                                            <DetailItem
                                                                label="Product Pass Score"
                                                                labelSize={8}
                                                                valueSize={3}
                                                                // value={item.appraisalActions.filter(x => x.systemProcessID === Number(5))[0]?.processResponse?.score}
                                                                // value="70"
                                                                value={this.state.acceptanceScore}
                                                            />
                                                        </dl>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <dl className="row">
                                                            <DetailItem
                                                                label="Scoring Result"
                                                                labelSize={7}
                                                                valueSize={5}
                                                                value={member[0]?.scoringResult}
                                                            />
                                                        </dl>
                                                    </div>
                                                    <div className="col-md-3 float-right">
                                                        <Button
                                                            size="sm"
                                                            variant="primary"
                                                            onClick={() => {
                                                                this.toggleShowScoringModal();
                                                            }}
                                                        >
                                                            View Scoring Report
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </div>
                        </Accordion>
                    </div>
                    <GroupPolicyScoringResult
                        item={this.state.item}
                        values={scoreResponse?.ScoreDetails}
                        showPatchModal={this.state.showScoringResult}
                        togglePatchModal={this.toggleShowScoringModal} systemProcess={systemProcess} userRoles={userRoles} />

                    <div className="form-row">
                        <div className="my-1 mx-2">
                            {groupMember.memberStatus === "Ineligible" ? (
                                <Button
                                    className="btn btn-sm btn-outline-primary my-2 mx-3"
                                    style={{ cursor: "pointer" }}
                                    type="button"
                                    // onClick={this.handleUploadDocument}
                                    onClick={(e) => { this.approveAppraisal(member[0]?.id) }}
                                >
                                    <React.Fragment>
                                        <i className="fas fa-check fa-lg mr-2" />{" "}
                                        Approve
                                    </React.Fragment>
                                </Button>
                            ) : ("")}
                        </div>
                    </div>

                </Modal.Body >

                <Modal.Footer>
                    <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={() => togglePatchModal(false, {}, {})}
                        disabled={this.state.submitting}
                    >
                        <i className="fas fa-times mr-3" />
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default GroupPolicyScoringCheck;



