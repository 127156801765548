import * as React from "react";
import _ from "lodash";
import { Fragment } from "react";
import {
  IFilterData,
  BaseListComponentState,
} from "../../components/BaseListComponent";
import {
  createFilterData,
  mapEntityList,
} from "../../services/utility/autoMapperService";
import BaseListComponent from "../../components/BaseListComponent";
import ItemsTable, {
  filterFieldData,
} from "../../components/tableComponents/itemsTable";
import TableFilterPane from "../../components/tableComponents/tableFilterPane";
import reportService from "../../services/report/reportService";

export interface CollectionReportProps { }

export interface CollectionReportState
  extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
}

class CollectionReportList extends BaseListComponent<CollectionReportState> { }

class CollectionReport extends React.Component<
  CollectionReportProps,
  CollectionReportState
> {
  constructor(props: CollectionReportProps) {
    super(props);
    this.state = {
      data: createFilterData([
        "customerName",
        "mandateReference",
        "loanReference",
        "transactionDateTime",
        "mandateSetUpDate",
        "expectedDate",
        "transactionStatus"
      ]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [
        { name: "customerName", fieldType: "text", value: "" },
        { name: "mandateReference", fieldType: "text", value: "" },
        { name: "loanReference", fieldType: "text", value: "" },
        { name: "transactionDateTime", fieldType: "date", value: "" },
        { name: "mandateSetUpDate", fieldType: "date", value: "" },
        { name: "expectedDate", fieldType: "date", value: "" },
        {
          name: "transactionStatus",
          fieldType: "select",
          options: ["Successful", "Pending", "Failed"],
          value: ""
        }
      ],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
    };
  }

  handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    data = typeof data === "undefined" ? this.state.data : data;
    var dataFilter = this.getFilteredItems(data)
    this.setState({ fetching: true });
    try {
      const reportItems = await reportService.filterCollectionReport(
        pageSize,
        currentPage,
        dataFilter
      );
      this.setState({
        tableItems: mapEntityList({
          entityList: reportItems.data.data,
          properties: [
            { oldName: "customerName", newName: "customerName" },
            { oldName: "mandateReference", newName: "mandateReference" },
            { oldName: "loanReference", newName: "loanReference" },
            { oldName: "mandateSetUpDate", newName: "mandateSetUpDate" },
            { oldName: "transactionDateTime", newName: "transactionDateTime" },
            { oldName: "originalRepaymentAmount", newName: "originalRepaymentAmount" },
            { oldName: "amountAttempted", newName: "amountAttempted" },
            { oldName: "amountCollected", newName: "amountCollected" },
            { oldName: "expectedDate", newName: "expectedDate" },
            { oldName: "transactionStatus", newName: "transactionStatus" },
            {
              isTag: true,
              oldName: "transactionStatus",
              newName: "transactionStatus",
              success: "Successful",
              danger: "Failed",
              warning: "Pending",
            },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: reportItems.data.totalCount,
        fetching: false,
      });
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
      } else {
      }
    } finally {
      this.setState({ fetching: false });
    }
  };

  handleOnExport = async (type: string) => { };

  fetchItemById = async (item: any) => { };

  getFilteredItems = (data: IFilterData) => {
    let filteredItems = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key].item2;
      }
    });
    return filteredItems;
  };

  getFilteredItemsText = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }

  render() {
    const {
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Collection Report</b>
              </h3>
            </div>
            <CollectionReportList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              initialValues={{
                data: createFilterData([
                  "customerName",
                  "mandateReference",
                  "loanReference",
                  "transactionDateTime",
                  "mandateSetUpDate",
                  "expectedDate",
                  "transactionStatus"
                ]) as IFilterData,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: [
                  { name: "customerName", fieldType: "text", value: "" },
                  { name: "mandateReference", fieldType: "text", value: "" },
                  { name: "loanReference", fieldType: "text", value: "" },
                  { name: "transactionDateTime", fieldType: "date", value: "" },
                  { name: "mandateSetUpDate", fieldType: "date", value: "" },
                  { name: "expectedDate", fieldType: "date", value: "" },
                  {
                    name: "transactionStatus",
                    fieldType: "select",
                    options: ["Successful", "Pending", "Failed"],
                    value: ""
                  }
                ],
                item: {},
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
              }) => (
                <></>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItemsText(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="CollectionReport"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                              amountColumn={["originalRepaymentAmount", "amountAttempted", "amountCollected"]}
                              amountInNaira={true}
                              timeColumn={["mandateSetUpDate", "expectedDate", "transactionDateTime"]}
                              disableViewDetails={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></CollectionReportList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default CollectionReport;
