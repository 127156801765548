import * as React from "react";
import {
  firstCharToUpper,
  splitAtUpper,
} from "../services/utility/textFormatService";

export interface ISelectItems {
  name: string;
  value: any;
}
export interface IDetailItemSelectComponentProps {
  id: string;
  name: string;
  items: ISelectItems[];
  value: string;
  required?: boolean;
  validated: boolean;
  onChange: any;
  onChangeCallBack?: any;
  errors: any;
  disabledOption?: any;
  disabled?: boolean;
}

export interface IDetailItemSelectComponentState {}

class DetailItemSelectComponent extends React.Component<
  IDetailItemSelectComponentProps,
  IDetailItemSelectComponentState
> {
  static defaultProps = {
    required: false,
    disabled: false,
  };
  render() {
    const {
      id,
      items,
      name,
      value,
      required,
      validated,
      errors,
      onChange,
      onChangeCallBack,
      disabledOption,
      disabled,
    } = this.props;
    return (
      <React.Fragment>
        <select
          id={id}
          name={name}
          className={
            !validated
              ? "custom-select select2 mr-sm-2 form-control r-0 light s-12"
              : errors[id]
              ? "custom-select select2 mr-sm-2 form-control r-0 light s-12 is-invalid"
              : "custom-select select2 mr-sm-2 form-control r-0 light s-12 is-valid"
          }
          required={required}
          value={value}
          onChange={(e) => {
            onChange(id, e.currentTarget.value);
            if (onChangeCallBack) {
              onChangeCallBack(id, e.currentTarget.value);
            }
          }}
          disabled={disabled}
        >
          <option value="">
            {"-Select " + firstCharToUpper(splitAtUpper(name)) + "-"}
          </option>

          {disabledOption
            ? items.map((item) => (
                <option
                  key={item.value}
                  value={item.value}
                  disabled={disabledOption(item.value)}
                >
                  {item.name}
                </option>
              ))
            : items.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.name}
                </option>
              ))}
        </select>
        <div
          className="invalid-feedback"
          style={{
            visibility: errors[id] ? "visible" : "hidden",
            display: errors[id] ? "block" : "none",
          }}
        >
          {errors[id]}​​​
        </div>
      </React.Fragment>
    );
  }
}

export default DetailItemSelectComponent;
