import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../components/detailItem";
import textFormatService from "../../services/utility/textFormatService";
import ThirdPartyImageView from "../../components/thirdPartyImageView";
// import remita_orange_logo from "../../images/remita_orange_logo.svg";

interface IFailedRepaymentReportDetailProps {
    showDetailsModal: boolean;
    toggleDetailsModal: any;
    item: any;
}

interface IFailedRepaymentReportDetailState { }

class FailedRepaymentReportDetail extends Component<IFailedRepaymentReportDetailProps, IFailedRepaymentReportDetailState> {
    render() {
        const { showDetailsModal, toggleDetailsModal, item } = this.props;
        return (
            <Modal size="xl" backdrop="static" show={showDetailsModal} onHide={toggleDetailsModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {_.isEmpty(item) ? (
                            <Skeleton width={500} />
                        ) : (
                            <h5>
                                <i className="fas fa-receipt fa-sm s-18 mr-3" />
                                Failed Repayment Details
                            </h5>
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card">
                        <div className="card-header clear-fix">
                            {_.isEmpty(item) ? (
                                <Skeleton width={200} />
                            ) : (
                                <h6 className="card-title float-left" style={{ marginBottom: "0px" }}>
                                    <b>General Information</b>
                                </h6>
                            )}
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    {_.isEmpty(item) ? (
                                        <Skeleton count={8} width={300} />
                                    ) : (
                                        <dl className="row">
                                            {/* <DetailItem
                        label="Amount Sent"
                        value={textFormatService.convertKoboToNaira(
                          item.amountSent,
                          2
                        )}
                      /> */}
                                            <DetailItem
                                                label="Failure Description"
                                                value={item.failureDescription}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem
                                                label="Loan Institution"
                                                value={item.loanAccountInstitution}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem
                                                label="Loan Reference"
                                                value={item.loanAccountNo}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem
                                                label="Recovery Institution"
                                                value={item.linkedAccountInstitution}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem
                                                label="Recovery Account No."
                                                value={item.linkedAccountNo}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem
                                                label="Customer Name"
                                                value={item.customerName}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem
                                                label="Credit Account"
                                                value={item.creditAccount}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem
                                                label="Sweep Service"
                                                value={<ThirdPartyImageView thirdPartyName={item.thirdPartySweepService} />}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                        </dl>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    {_.isEmpty(item) ? (
                                        <Skeleton count={8} width={300} />
                                    ) : (
                                        <dl className="row">
                                            <DetailItem
                                                label="Amount Attempted"
                                                value={textFormatService.convertKoboToNaira(item.amountAttempted, 2)}
                                                colorIndicator={item.amountAttempted === item.amountDebited ? "#BAFEB7" : "#FAB39B"}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem
                                                label="Amount Debited"
                                                value={textFormatService.convertKoboToNaira(item.amountDebited, 2)}
                                                colorIndicator={item.amountAttempted === item.amountDebited ? "#BAFEB7" : "#FAB39B"}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem
                                                label="Unique ID"
                                                value={item.uniqueId}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem
                                                label="Credit Account Number"
                                                value={item.accountCreditedNumber}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem
                                                label="Debit Account Number"
                                                value={item.accountDebitedNumber}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem label="Retrieval Reference"
                                                value={item.retrievalReferenceNo}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem
                                                label="Time Swept"
                                                value={textFormatService.formatTimeString(item.timeSwept)}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem
                                                label="Narration"
                                                value={item.narration}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem
                                                label="Repayment Account"
                                                value={item.loanRepaymentAccount}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem
                                                label="Designation"
                                                value={item.designation}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                        </dl>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="outline-danger" onClick={toggleDetailsModal}>
                        <i className="fas fa-times mr-3" />
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default FailedRepaymentReportDetail;
