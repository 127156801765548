import React, { Fragment } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { getCurrentUserSession } from "../../../services/auth/authService";
import FormInputComponent from "../../../components/formInputComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import loanProductService from "../../../services/creditAssessment/loanProductService";
import creditAssessmentValidationService from "../../../services/creditAssessment/creditAssessmentValidationService";
import { decodeToken } from "react-jwt";
import SelectInputComponent, {
  ISelectItems,
} from "../../../components/formSelectComponent";
import { RepaymentSchedule } from "../../../enums/repaymentSchedule";
import authLoanProductService from "../../../services/auth/loanProductService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import scoringCardService from "../../../services/creditAssessment/scoringCardService";
import textFormatService from "../../../services/utility/textFormatService";
import institutionsService from "../../../services/auth/institutionsService";

interface AddLoanProductFields {
  productName: string;
  productCode: string;
  minimumAmount: string;
  maximumAmount: string;
  tenure: string;
  moratorium: string;
  // minimumScore: string;
  // scoreCard: string;
  // repaymentSchedule: string;
  guarantorCount?: string;
  minScore: string;
  maxScore: string;
  limit: string;
  tenor: string;
  pricing: string;
  repaymentFrequency: string;
}

class AddLoanProductForm extends BaseFormComponent<AddLoanProductFields> { }

interface IAddLoanProductProps { }
interface IAddLoanProductState {
  loanProducts: any;
  requireGuarantor: boolean;
  scoreCardList: any;
  prices: any;
  institutionCategory: any;
}

class AddLoanProduct extends React.Component<
  IAddLoanProductProps,
  IBaseFormState & IAddLoanProductState
> {
  constructor(props: IAddLoanProductProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      loanProducts: [],
      requireGuarantor: false,
      scoreCardList: [],
      prices: [],
      institutionCategory: {}
    };
  }

  async componentDidMount() {
    const { errors } = this.state;
    try {
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      let loanProducts =
        await authLoanProductService.retrieveAllCreditAssessmentProducts(
          decodedToken?.["InstitutionId"]
        );
      let institutionDetails = (await institutionsService.retrieveInstitutionByCode(decodedToken?.["InstitutionCode"])).data;
      // let scoreCard = await scoringCardService.retrieveScoreCardByInstitutionCode(decodedToken?.["InstitutionCode"])
      // let scoreCardDetails = scoreCard.data
      this.setState({
        loanProducts: loanProducts.data,
        institutionCategory: institutionDetails.category
        // scoreCardList: scoreCardDetails
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (ex.response && ex.response.status === 404) {
        // console.log(ex.response);
        errors.response = "Internal server error.";
        // console.log(errors);
        this.setState({ errors });
      }
    }
  }

  onFormSubmit(fields: AddLoanProductFields, onReloadFieldsCB: any): boolean {
    try {
      console.log("Fields for submitting:: ", fields)
      this.setState(
        {
          errors:
            creditAssessmentValidationService.validateAddLoanProductForm(
              fields
            ),
        },
        () => {
          console.log(this.state.errors)
          if (Object.keys(this.state.errors).length === 0) {
            // console.log("passed Validation")
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddLoanProductFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    let response: any;
    try {
      this.setState({ submitting: true });
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const payload: any = {};
      payload.name =
        Number(decodedToken?.["InstitutionId"]) === 1 || this.state.institutionCategory !== "BankOneOFI"
          ? fields.productName
          : this.state.loanProducts.filter(
            (item) => item.id === Number(fields.productName)
          )[0].name;
      payload.institutionCode = decodedToken?.["InstitutionCode"];
      payload.productCode = fields.productCode;
      payload.category =
        Number(decodedToken?.["InstitutionId"]) === 1 || this.state.institutionCategory !== "BankOneOFI"
          ? "Blackbox"
          : "CreditAssessment";
      payload.minimumAmount = Number(fields.minimumAmount);
      payload.maximumAmount = Number(fields.maximumAmount);
      // payload.minimumScore = Number(fields.minimumScore);
      payload.tenure = Number(fields.tenure);
      payload.repaymentSchedule = "Weekly";
      payload.moratorium = Number(fields.moratorium);
      payload.requireGuarantor = this.state.requireGuarantor;
      // payload.scoreCardId = fields.scoreCard;
      payload.guarantorCount = this.state.requireGuarantor ? Number(fields.guarantorCount) : 0;
      console.log("Reached Here!!!!!")
      const loanPricings: any = []
      const loanPricing = [...this.state.prices];
      // console.log(loanPricing)
      // console.log(payload)
      loanPricing.forEach(price => {
        const pricing: any = {};
        pricing.productName = payload.name
        pricing.productCode = payload.productCode
        pricing.institutionCode = payload.institutionCode
        pricing.maxScore = Number(price.maxScore)
        pricing.minScore = Number(price.minScore)
        pricing.limit = Number(price.limit)
        pricing.tenor = Number(price.tenor)
        pricing.pricing = Number(price.pricing)
        pricing.repaymentFrequency = price.repaymentFrequency
        loanPricings.push(pricing)

      });

      // console.log(loanPricings)
      var createLoanPricing = await loanProductService.saveLoanProductPricing(loanPricings);
      // console.log(createLoanPricing);
      if (createLoanPricing.status >= 200 && createLoanPricing.status <= 300) {
        response = await loanProductService.saveLoanProduct(payload);
        // console.log("testing log:: ", response);
        if (response.status >= 200 && response.status <= 300) {
          await activityTrailService.saveActivityTrail({
            actionType: "Add Loan Product",
            description: `Added new Loan Product ${fields.productName}`,
          });
          toast.info(`Loan Product ${payload.name} created successfully! `, {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          });
        } else {
          return false;
        }
      }
      else {
        toast.info(`Loan Product Pricing couldn't be saved! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false });
      this.resetFields();
      onReloadFieldsCB();
    } catch (error) {
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // console.log("error: ", error.response);
        return false;
      }
      this.setState({ submitting: false });
      return false;
    } finally {
      return true;
    }
  }

  toggleRequireGuarantor = () => {
    this.setState({ requireGuarantor: !this.state.requireGuarantor });
  };

  handleOnAddPricing = (item: any) => {
    if (Number(item.minScore) >= Number(item.maxScore)) {
      toast.info(`Maximum Score must be greater than Minimum Score`, {
        autoClose: 6000,
        type: toast.TYPE.DEFAULT,
        hideProgressBar: false,
      });
      return false
    }

    if (Number(item.minScore) < 0) {
      toast.info(`Minimum Score must be 0 or greater`, {
        autoClose: 6000,
        type: toast.TYPE.DEFAULT,
        hideProgressBar: false,
      });
      return false
    }
    if (Number(item.maxScore) > 100) {
      toast.info(`Maximum Score must be less than 100`, {
        autoClose: 6000,
        type: toast.TYPE.DEFAULT,
        hideProgressBar: false,
      });
      return false
    }

    const prices = [...this.state.prices];
    if (this.validateRanges(item, prices)) {
      prices.push({
        minScore: item.minScore,
        maxScore: item.maxScore,
        limit: item.limit,
        tenor: item.tenor,
        pricing: item.pricing,
        repaymentFrequency: item.repaymentFrequency
      }

      );

      this.setState({ prices });
    }
    else {
      toast.info(`Range Overlap, Please Check Entries`, {
        autoClose: 6000,
        type: toast.TYPE.DEFAULT,
        hideProgressBar: false,
      });
    }
  };

  rangesOverlap = (a, b) => {
    return (Number(b.minScore) >= Number(a.minScore) && Number(b.minScore) <= Number(a.maxScore)) || (Number(b.maxScore) >= Number(a.minScore) && Number(b.maxScore) <= Number(a.maxScore));
    ;
  };

  validateRanges = (item, prices) => {
    // const prices = [...this.state.prices];
    for (let price of prices) {
      // console.log(price)
      // console.log(item)
      // console.log((item.minScore >= price.minScore && item.minScore <= price.maxScore) || (item.maxScore >= price.minScore && item.maxScore <= price.maxScore))
      if (this.rangesOverlap(price, item)) {
        return false; // New range overlaps with existing, don't add it
      }
    }
    return true; // No overlapping ranges
  };

  // handleReloadFields = (fields: AddProductConfigurationFields) => {
  //   fields.feeName = ""
  // }

  handleOnRemovePricing = (item: any) => {
    const prices = [...this.state.prices];
    if (prices.includes(item)) {
      prices.splice(
        prices.indexOf(item), 1
      );
    }
    this.setState({ prices });
  };

  resetFields() {
    this.setState({ validated: false, submitting: false });
  }
  render() {
    const { errors, validated, loanProducts, requireGuarantor, scoreCardList, prices, institutionCategory } = this.state;

    const decodedToken = decodeToken(getCurrentUserSession().token || "");

    let productNamesInputData: ISelectItems[] = loanProducts.map((item) => ({
      name: item.name,
      value: item.id,
    }));

    let repaymentFrequencyInputData: ISelectItems[] = [
      { name: "One Off", value: "OneOff" },
      { name: "Daily", value: "Daily" },
      { name: "Weekly", value: "Weekly" },
      { name: "Monthly", value: "Monthly" },
      { name: "Quarterly", value: "Quarterly" },
      { name: "BiAnnually", value: "BiAnnually" },
      { name: "Yearly", value: "Yearly" },
    ];

    // let scoreCardItems: ISelectItems[] = scoreCardList?.map((item) => ({
    //   name: item.name,
    //   value: item.id,
    // }));
    // Turn enum into array
    let repaymentScheduleInputData: ISelectItems[] = Object.keys(
      RepaymentSchedule
    )
      .filter((value) => isNaN(Number(value)) === false)
      .map((key) => ({
        name: RepaymentSchedule[key],
        value: RepaymentSchedule[key],
      }));

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add Loan Product</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddLoanProductForm
                  initialValues={{
                    productName: "",
                    productCode: "",
                    minimumAmount: "",
                    maximumAmount: "",
                    tenure: "",
                    moratorium: "",
                    // minimumScore: "",
                    // repaymentSchedule: "",
                    guarantorCount: "",
                    minScore: "",
                    maxScore: "",
                    limit: "",
                    tenor: "",
                    pricing: "",
                    repaymentFrequency: ""
                    // scoreCard: ""
                  }}
                  FormComponent={({
                    fields: {
                      maximumAmount,
                      minimumAmount,
                      productName,
                      tenure,
                      productCode,
                      moratorium,
                      // minimumScore,
                      // repaymentSchedule,
                      guarantorCount,
                      maxScore,
                      minScore,
                      limit,
                      tenor,
                      pricing,
                      repaymentFrequency
                      // scoreCard
                    },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <h5 className="card-title">
                            <i className="fas fa-university mr-3" />
                            Loan Product Details
                          </h5>
                          <div className="form-row">
                            <div className="col-md-12">
                              <div className="form-row">
                                {Number(decodedToken?.["InstitutionId"]) ===
                                  1 || institutionCategory !== "BankOneOFI" ? (
                                  <FormInputComponent
                                    id="productName"
                                    type="text"
                                    name="productName"
                                    placeholder=""
                                    divClass={6}
                                    value={productName}
                                    required={true}
                                    validated={validated}
                                    errors={errors}
                                    onChange={onChange}
                                  />
                                ) : (
                                  <SelectInputComponent
                                    id="productName"
                                    name="productName"
                                    divClass={6}
                                    value={productName}
                                    items={productNamesInputData}
                                    required={true}
                                    validated={validated}
                                    errors={errors}
                                    onChange={async (id, value) => {
                                      await onChange(id, value);
                                      await onChange(
                                        "productCode",
                                        loanProducts.filter(
                                          (item) => item.id === Number(value)
                                        )[0].code
                                      );
                                      await onChange(
                                        "tenure",
                                        loanProducts
                                          .filter(
                                            (item) => item.id === Number(value)
                                          )[0]
                                          .loanCycle.toString()
                                      );
                                      await onChange(
                                        "moratorium",
                                        loanProducts
                                          .filter(
                                            (item) => item.id === Number(value)
                                          )[0]
                                          .moratorium.toString()
                                      );
                                      await onChange(
                                        "minimumAmount",
                                        loanProducts
                                          .filter(
                                            (item) => item.id === Number(value)
                                          )[0]
                                          .minimumAmount.toString()
                                      );
                                      await onChange(
                                        "maximumAmount",
                                        loanProducts
                                          .filter(
                                            (item) => item.id === Number(value)
                                          )[0]
                                          .maximumAmount.toString()
                                      );
                                    }}
                                  />
                                )}
                                <FormInputComponent
                                  id="tenure"
                                  type="number"
                                  name="tenureInDays"
                                  placeholder=""
                                  divClass={6}
                                  value={tenure}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                  disabled={institutionCategory === "BankOneOFI"}
                                />
                              </div>
                              <div className="form-row">
                                <FormInputComponent
                                  id="productCode"
                                  type="text"
                                  name={"loanProductCode"}
                                  placeholder=""
                                  divClass={6}
                                  value={productCode}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                  disabled={institutionCategory === "BankOneOFI"}
                                />
                                <FormInputComponent
                                  id="minimumAmount"
                                  type="number"
                                  name={"Minimum Amount"}
                                  placeholder=""
                                  divClass={6}
                                  value={minimumAmount}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                  disabled={institutionCategory === "BankOneOFI"}
                                />
                              </div>
                              <div className="form-row">
                                <FormInputComponent
                                  id="moratorium"
                                  type="number"
                                  name={"moratorium"}
                                  placeholder=""
                                  divClass={6}
                                  value={moratorium}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                  disabled={institutionCategory === "BankOneOFI"}
                                />
                                <FormInputComponent
                                  id="maximumAmount"
                                  type="number"
                                  name={"Maximum Amount"}
                                  placeholder=""
                                  divClass={6}
                                  value={maximumAmount}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                  disabled={institutionCategory === "BankOneOFI"}
                                />
                              </div>
                              <div className="form-row">
                                {/* <FormInputComponent
                                  id="minimumScore"
                                  type="number"
                                  name={"minimumScore"}
                                  placeholder=""
                                  divClass={6}
                                  value={minimumScore}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                /> */}
                                {/* <SelectInputComponent
                                  id="repaymentSchedule"
                                  name="repaymentSchedule"
                                  divClass={6}
                                  value={repaymentSchedule}
                                  items={repaymentScheduleInputData}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                /> */}
                                {/* <SelectInputComponent
                                  id="scoreCard"
                                  name="scoreCard"
                                  divClass={6}
                                  value={scoreCard}
                                  items={scoreCardItems}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                /> */}
                                <div className="form-row col-md-6">
                                  <div className="col-md-4">
                                    <label
                                      htmlFor="requireGuarantor"
                                      className="col-form-label s-12 mb-2"
                                    >
                                      Require Guarantor
                                    </label>
                                    <br />
                                    <span className="material-switch ml-2">
                                      <input
                                        id="requireGuarantor"
                                        name="requireGuarantor"
                                        type="checkbox"
                                        onChange={this.toggleRequireGuarantor}
                                        checked={requireGuarantor}
                                      />
                                      <label
                                        htmlFor="requireGuarantor"
                                        className="bg-primary"
                                      ></label>
                                    </span>
                                  </div>
                                  {requireGuarantor ? (
                                    <FormInputComponent
                                      id="guarantorCount"
                                      type="number"
                                      name={"Number of Guarantors"}
                                      placeholder=""
                                      divClass={8}
                                      value={guarantorCount}
                                      required={true}
                                      validated={validated}
                                      errors={errors}
                                      onChange={onChange}

                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              <div className="card mt-3">
                                <div className="card-header clear-fix">
                                  <h6
                                    className="float-left"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <b>Product Pricing</b>
                                  </h6>
                                </div>
                                <div className="card-body">
                                  <div className="form-row">
                                    <FormInputComponent
                                      label="Mininimum Score"
                                      id="minScore"
                                      name="minScore"
                                      type="number"
                                      placeholder=""
                                      divClass={6}
                                      value={minScore}
                                      required={false}
                                      validated={validated}
                                      errors={errors}
                                      onChange={onChange}
                                    />
                                    <FormInputComponent
                                      label="Maximum Score"
                                      id="maxScore"
                                      name="maxScore"
                                      type="number"
                                      placeholder=""
                                      divClass={6}
                                      value={maxScore}
                                      required={false}
                                      validated={validated}
                                      errors={errors}
                                      onChange={onChange}
                                    />
                                    <FormInputComponent
                                      label="Limit"
                                      id="limit"
                                      name="limit"
                                      type="number"
                                      placeholder=""
                                      divClass={6}
                                      value={limit}
                                      required={false}
                                      validated={validated}
                                      errors={errors}
                                      onChange={onChange}
                                    />
                                    <FormInputComponent
                                      label="Tenor"
                                      id="tenor"
                                      name="tenor"
                                      type="number"
                                      placeholder=""
                                      divClass={6}
                                      value={tenor}
                                      required={false}
                                      validated={validated}
                                      errors={errors}
                                      onChange={onChange}
                                    />
                                    <FormInputComponent
                                      label="Interest Rate"
                                      id="pricing"
                                      name="pricing"
                                      type="number"
                                      placeholder=""
                                      divClass={6}
                                      value={pricing}
                                      required={false}
                                      validated={validated}
                                      errors={errors}
                                      onChange={onChange}
                                    />
                                    <SelectInputComponent
                                      id="repaymentFrequency"
                                      name="repaymentFrequency"
                                      divClass={6}
                                      value={repaymentFrequency}
                                      items={repaymentFrequencyInputData}
                                      required={false}
                                      validated={false}
                                      errors={errors}
                                      onChange={onChange}
                                    />

                                    {/* Add Pricing */}
                                    <div className="form-row">
                                      <div className="my-1 mx-2">
                                        <button
                                          className="btn btn-sm btn-outline-primary my-4"
                                          style={{ cursor: "pointer" }}
                                          type="button"
                                          onClick={() =>
                                            this.handleOnAddPricing(
                                              {
                                                maxScore,
                                                minScore,
                                                limit,
                                                tenor,
                                                pricing,
                                                repaymentFrequency
                                              },
                                            )
                                          }
                                        >
                                          <React.Fragment>
                                            <i className="far fa-save fa-lg mr-2" />{" "}
                                            Add
                                          </React.Fragment>
                                        </button>
                                      </div>
                                    </div>
                                    <div className=" form-row col-md-12">
                                      <div
                                        className="table-responsive text-nowrap"
                                        style={{
                                          maxHeight: "150px",
                                          overflowY: "scroll",
                                        }}
                                      >
                                        <table className="table table-hover table-content table-sm table-striped mb-0">
                                          <thead>
                                            <tr>
                                              <th
                                                scope="col"
                                                style={{
                                                  paddingTop: "0.2rem",
                                                  paddingBottom: "0.2rem",
                                                }}
                                              >
                                                <strong>#</strong>
                                              </th>
                                              <th
                                                scope="col"
                                                style={{
                                                  paddingTop: "0.2rem",
                                                  paddingBottom: "0.2rem",
                                                }}
                                              >
                                                <strong>Min Score</strong>
                                              </th>
                                              <th
                                                scope="col"
                                                style={{
                                                  paddingTop: "0.2rem",
                                                  paddingBottom: "0.2rem",
                                                }}
                                              >
                                                <strong>Max Score</strong>
                                              </th>
                                              <th
                                                scope="col"
                                                style={{
                                                  paddingTop: "0.2rem",
                                                  paddingBottom: "0.2rem",
                                                }}
                                              >
                                                <strong>Limit</strong>
                                              </th>
                                              <th
                                                scope="col"
                                                style={{
                                                  paddingTop: "0.2rem",
                                                  paddingBottom: "0.2rem",
                                                }}
                                              >
                                                <strong>Tenor</strong>
                                              </th>
                                              <th
                                                scope="col"
                                                style={{
                                                  paddingTop: "0.2rem",
                                                  paddingBottom: "0.2rem",
                                                }}
                                              >
                                                <strong>Interest Rate</strong>
                                              </th>
                                              <th
                                                scope="col"
                                                style={{
                                                  paddingTop: "0.2rem",
                                                  paddingBottom: "0.2rem",
                                                }}
                                              >
                                                <strong>Repayment Frequency</strong>
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {prices.map((item) => (
                                              <tr key={prices.indexOf(item)}>
                                                <td
                                                  style={{
                                                    paddingTop: "0.2rem",
                                                    paddingBottom: "0.2rem",
                                                    fontSize: "small",
                                                  }}
                                                >
                                                  {prices.indexOf(item) + 1}
                                                </td>
                                                <td
                                                  style={{
                                                    paddingTop: "0.2rem",
                                                    paddingBottom: "0.2rem",
                                                    fontSize: "small",
                                                  }}
                                                >
                                                  {item.minScore}
                                                </td>
                                                <td
                                                  style={{
                                                    paddingTop: "0.2rem",
                                                    paddingBottom: "0.2rem",
                                                    fontSize: "small",
                                                  }}
                                                >
                                                  {item.maxScore}
                                                </td>
                                                <td
                                                  style={{
                                                    paddingTop: "0.2rem",
                                                    paddingBottom: "0.2rem",
                                                    fontSize: "small",
                                                  }}
                                                >
                                                  {textFormatService.formatCurrency(Number(item.limit), 2)}
                                                </td>
                                                <td
                                                  style={{
                                                    paddingTop: "0.2rem",
                                                    paddingBottom: "0.2rem",
                                                    fontSize: "small",
                                                  }}
                                                >
                                                  {item.tenor}
                                                </td>
                                                <td
                                                  style={{
                                                    paddingTop: "0.2rem",
                                                    paddingBottom: "0.2rem",
                                                    fontSize: "small",
                                                  }}
                                                >
                                                  {item.pricing}
                                                </td>
                                                <td
                                                  style={{
                                                    paddingTop: "0.2rem",
                                                    paddingBottom: "0.2rem",
                                                    fontSize: "small",
                                                  }}
                                                >
                                                  {item.repaymentFrequency}
                                                </td>
                                                <td
                                                  style={{
                                                    paddingTop: "0.2rem",
                                                    paddingBottom: "0.2rem",
                                                    fontSize: "small",
                                                  }}
                                                >
                                                  <i
                                                    className="far fa-trash-alt ml-5 red-text fa-lg"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      this.handleOnRemovePricing(
                                                        item
                                                      )
                                                    }
                                                  ></i>
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="card-body">
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                              disabled={this.state.submitting}
                              onClick={(e) => {
                                onHandleSubmit(
                                  e,
                                  ActionTypes.SAVE,
                                  "Loan Product",
                                  () => {
                                    this.onFormSubmit(
                                      {
                                        productCode,
                                        tenure,
                                        productName,
                                        minimumAmount,
                                        maximumAmount,
                                        moratorium,
                                        // repaymentSchedule,
                                        // minimumScore,
                                        guarantorCount,
                                        // scoreCard
                                        maxScore,
                                        minScore,
                                        limit,
                                        tenor,
                                        pricing,
                                        repaymentFrequency
                                      },
                                      onReloadFields
                                    );
                                  }
                                );
                              }}
                            >
                              {this.state.submitting === false ? (
                                <React.Fragment>
                                  <i className="fas fa-lg fa-save mr-3" /> Save
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                  Please wait...
                                </React.Fragment>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                ></AddLoanProductForm>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddLoanProduct;
