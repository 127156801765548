import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";
import { IFilterData } from "../approval/approvalItemService";
import { getIpAddress } from "../utility/textFormatService";
import { decodeToken } from "react-jwt";

interface ISearchBVNGreyList {
  bvn: string;
  greylistinginst: string;
  status: string;
}
interface ISaveBVNGreyList {
  institutionName: string;
  bvn: string;
  customerName: string;
  customerPhoneNumber: string;
  institutionId: number;
  clientIPAddress: string;
}
interface IUpdateBVNGreyList {
  greyListingInstitution: string;
  bvn: string;
  clientIPAddress: string;
}

const mandateBaseAddress = process.env.REACT_APP_MANDATE_BASEADDRESS;
export async function saveBVNGreyList(bvnGreyList: ISaveBVNGreyList) {
  bvnGreyList.clientIPAddress = await getIpAddress().geoplugin_request;
  var url = mandateBaseAddress + `BVNGreyList/SaveBvn`;
  return http.post(url, bvnGreyList, {
    url: url,
    method: "post",
    data: bvnGreyList,
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export async function updateBVNGreyList(
  bvnGreyList: IUpdateBVNGreyList,
  bvnGreyListId: number
) {
  bvnGreyList.clientIPAddress = await getIpAddress().geoplugin_request;
  return http.put(
    mandateBaseAddress +
    `BVNGreyList/${bvnGreyListId}/${getCurrentUserSession().sessionId}`,
    bvnGreyList
  );
}

export function retrieveBVNGreyListById(bvnGreyListId: number) {
  return http.get(mandateBaseAddress + `BVNGreyList/${bvnGreyListId}`);
}

export function retrieveAllBVNGreyList() {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  return http.get(
    mandateBaseAddress +
    `BVNGreyList/GetAll?InstitutionId=${decodedToken?.["InstitutionId"]}`
  );
}

export function retrieveByBvn(bvn: String) {
  return http.get(mandateBaseAddress + `BVNGreyList/RetrieveByBvn/${bvn}`);
}

export function enableBVNGreyList(bvnGreyListId: number) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  return http.post(
    mandateBaseAddress +
    `BVNGreyList/Enable/${getCurrentUserSession().sessionId}?institutionId=${decodedToken?.["InstitutionId"]
    }&id=${bvnGreyListId}`
  );
}

export function disableBVNGreyList(bvnGreyListId: number) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  return http.post(
    mandateBaseAddress +
    `BVNGreyList/Disable/${getCurrentUserSession().sessionId}?institutionId=${decodedToken?.["InstitutionId"]
    }&id=${bvnGreyListId}`
  );
}

export function searchBVNGreyList(
  data: ISearchBVNGreyList,
  pageSize: number,
  pageNumber: number
) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  return http.get(
    mandateBaseAddress +
    `BVNGreyList/SearchBVNGreyList?institutionId=${decodedToken?.["InstitutionId"]}&bvn=${data.bvn}&graylistinginst=${data.greylistinginst}&status=${data.status}&pageSize=${pageSize}&pageNumber=${pageNumber}`
  );
}

export function filterBVNGreyLists(
  payload: IFilterData,
  pageSize: number,
  pageNumber: number, 
  adminInstitution?: boolean
) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  if(adminInstitution !== true){
    // console.log("reached here")
    payload = { ...payload };
    payload.institutionId = {
      item1: "=",
      item2: decodedToken?.["InstitutionId"]?.toString() || "break",
    };
  }
  return http.post(
    mandateBaseAddress +
    `BVNGreyList/FilterBVNGreyLists?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    payload
  );
}

export function removeFromGreyList(id: number) {
  return http.delete(mandateBaseAddress + `BVNGreyList/${id}`);
}

export function validateBVN(bvn: string) {
  return http.get(mandateBaseAddress + `BVNGreyList/ValidateBVN?bvn=${bvn}`);
}

export default {
  saveBVNGreyList,
  updateBVNGreyList,
  retrieveBVNGreyListById,
  retrieveAllBVNGreyList,
  enableBVNGreyList,
  disableBVNGreyList,
  searchBVNGreyList,
  filterBVNGreyLists,
  validateBVN,
  removeFromGreyList,
  retrieveByBvn,
};
