import React from "react";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import BaseFormComponent, { IBaseFormState } from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import FormInputComponent from "../../../components/formInputComponent";
import textFormatService, { splitAtUpper } from "../../../services/utility/textFormatService";
import SelectInputComponent from "../../../components/formSelectComponent";
import { toast } from "react-toastify";

interface ModifyIndicatorParamsFields {
  scoreIndicatorOptionId: string;
  maxValue?: string;
  minValue?: string;
  points?: string;
  indicatorOptions: any
}

class ModifyIndicatorParamsForm extends BaseFormComponent<ModifyIndicatorParamsFields> { }

interface IModifyIndicatorParamsProps {
  item: any;
  allIndicators: any;
  showPatchModal: boolean;
  togglePatchModal: any;
  IScoreCardPointsMetrics: any;
  indicatorOption: any;
}

interface IIndicatorParamData {
  metricId: string
  metricName: string;
  metricPoint: string;
}
interface IIndicatorOptions {
  scoreIndicatorOptionId: string
  minValue: string;
  maxValue: string;
  points: string;
}


interface IModifyIndicatorParamsState {
  ownUpdate: boolean;
  data: IIndicatorOptions[];
  IScoreCardPointsMetrics: IScoreCardPointsMetrics[];
  indicatorOption: IIndicatorOptions[];
  metrics: any;
}

interface IScoreCardPointsMetrics {
  scoreIndicatorId: string;
  indicatorMetrics: IIndicatorOptions[];
}

class ModifyPointMetrics extends React.Component<
  IModifyIndicatorParamsProps,
  IBaseFormState & IModifyIndicatorParamsState
> {
  constructor(props: IModifyIndicatorParamsProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      ownUpdate: false,
      data: [],
      errors: {},
      IScoreCardPointsMetrics: [],
      indicatorOption: [],
      metrics: []
    };
  }

  static getDerivedStateFromProps(
    nextProps: IModifyIndicatorParamsProps,
    prevState: IModifyIndicatorParamsState
  ) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isEmpty(nextProps.item)) {
      return null;
    }
    const indicatorOption: IIndicatorOptions[] = [];
    // console.log(nextProps.allIndicators)
    if (nextProps.allIndicators.indicatorOptions.length > 0) {
      nextProps.allIndicators.indicatorOptions.forEach((indicator) => (
        indicatorOption.push({
          scoreIndicatorOptionId: indicator.id,
          points: "",
          minValue: null,
          maxValue: null
        })))
    };
    return {
      indicatorOption
    };
  }


  // handleOnInputChange = (name, value) => {
  //   const data = [...this.state.data];
  //   data.filter(indicator => indicator.metricName == name)[0].metricPoint = value
  //   this.setState({ data, ownUpdate: true })
  // }

  onFormSubmit(fields, onReloadFieldsCB: any): boolean {
    try {
      const error: any = [];
      this.setState(
        {
          validated: true,
          ownUpdate: true,
          errors: error
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (fields: any, onReloadFieldsCB: any) => {
    try {
      this.setState({ submitting: true, ownUpdate: true });

      // console.log(fields)

      onReloadFieldsCB();
      if (fields.maxValue < fields.minValue) {
        toast.info(`Min value cannot be greater than Max value`, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
        this.setState({ submitting: false, ownUpdate: true });
        return;
      }

      const ScoreCardPointsMetrics = [...this.props.IScoreCardPointsMetrics];
      const indicatorOptions = [...this.state.indicatorOption]
      // console.log("Options :: ", indicatorOptions);
      let field: IIndicatorOptions[];
      let field_ = [...this.state.data]
      field_ = this.state.metrics;
      // field_.push({
      //   scoreIndicatorOptionId: fields.scoreIndicatorOptionId, maxValue: null,
      //   minValue: null,
      //   points: null
      // })
      // console.log(field_)

      field = indicatorOptions.length > 0 ? indicatorOptions : field_

      // console.log(field)

      ScoreCardPointsMetrics.push({
        scoreIndicatorId: this.props.item,
        indicatorMetrics: field
      })
      // console.log("data :: ", ScoreCardPointsMetrics);
      this.setState({ submitting: false, ownUpdate: true, IScoreCardPointsMetrics: ScoreCardPointsMetrics }, () =>
        this.props.togglePatchModal(true, this.props.item, this.state.data, this.state.IScoreCardPointsMetrics, this.state.indicatorOption)
      );
    } catch (error) {
      this.setState({ submitting: false, ownUpdate: true });
    }
  };

  handleOnAddMetrics = (item: IIndicatorOptions, onReloadFieldsCB: any) => {

    const metrics = [...this.state.metrics];
    metrics.push(item);
    // console.log(metrics)
    this.setState({ metrics, ownUpdate: true });
    this.resetFields();
    onReloadFieldsCB();
    this.setState({ validated: true, ownUpdate: true });
  };

  handleOnRemoveMetrics = (item: IIndicatorOptions) => {
    const metrics = [...this.state.metrics];
    if (metrics.includes(item)) {
      metrics.splice(
        metrics.indexOf(item), 1
      );
    }
    this.setState({ metrics, ownUpdate: true });
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      ownUpdate: true
    });
  }

  handleIndicatorOptionChange = (id, value) => {
    const indicatorOption = [...this.state.indicatorOption];
    // this.setState({flatPerApprovedLoan:flatPerApprovedLoan_});

    indicatorOption.every(option => {
      if (option.scoreIndicatorOptionId == id) {

        option.points = value;
        // this.setState({ submitting: false });
        return false;
      }
      // this.setState({flatPerApprovedLoan:flatPerApprovedLoan_})
      // Make sure you return true. If you don't return a value, `every()` will stop.
      return true;
    });
    // console.log("getting object",validationOption.find(option => option.validationPricingOptionId == id).fee);
    //  console.log(signatoryOption);

    return indicatorOption;
    // this.setState({validationOption: validationOption});
  }

  render() {
    const { item, showPatchModal, togglePatchModal, allIndicators } = this.props;
    const { validated, submitting, errors, data, metrics } = this.state;

    if (_.isEmpty(item)) {
      return null;
    }

    return (
      <Modal
        size="lg"
        backdrop="static"
        show={showPatchModal}
        onHide={togglePatchModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-edit fa-sm cyan-text mr-3" />
              Assign Point Metrics
            </h5>
          </Modal.Title>
        </Modal.Header>
        <ModifyIndicatorParamsForm
          initialValues={{
            scoreIndicatorOptionId: null,
            points: "",
            maxValue: "",
            minValue: "",
            indicatorOptions: this.state.indicatorOption
          }}
          FormComponent={({
            fields: {
              scoreIndicatorOptionId,
              points,
              maxValue,
              minValue,
              indicatorOptions
            },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    {_.isEmpty(item) ? (
                      <Skeleton width={200} />
                    ) : (
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        <b>General Information</b>
                      </h6>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="form-row">
                      {/* {console.log("Inside: ", allIndicators)} */}
                      {allIndicators.indicatorOptions.length > 0 ? (
                        // allIndicators.indicatorType != "Range" ? (
                        indicatorOptions.map((indicator) => (
                          <FormInputComponent
                            key={indicator.scoreIndicatorOptionId}
                            id={indicator.scoreIndicatorOptionId}
                            type="number"
                            name={allIndicators.indicatorOptions.filter((option) => option.id == indicator.scoreIndicatorOptionId)[0].optionName}
                            divClass={6}
                            placeholder=""
                            value={indicator.points}
                            required={true}
                            validated={validated}
                            errors={errors}
                            onChange={(id, value) => {
                              onChange('indicatorOptions', this.handleIndicatorOptionChange(id, value))
                              // this.handleValidationChange
                            }}
                          />
                        )

                        )
                        // ) : (
                        //   indicatorOptions.map((option) => (
                        //     <FormInputComponent
                        //       key={option.minimum + " - " + option.maximum}
                        //       id={option.minimum + " - " + option.maximum}
                        //       type="number"
                        //       name={option.minimum + " - " + option.maximum + " " + item.valueTypeQuantifier}
                        //       divClass={6}
                        //       placeholder=""
                        //       value={(option.score).toString()}
                        //       required={true}
                        //       validated={validated}
                        //       errors={errors}
                        //       onChange={(id, value) => {
                        //         onChange('indicatorOptions', this.handleIndicatorOptionChange(id, value))
                        //         // this.handleValidationChange
                        //       }}
                        //     />
                        //   )
                        //   )
                      )
                        : (allIndicators.indicatorType === "Range" ? (
                          <React.Fragment>
                            <FormInputComponent
                              key={item.metricName}
                              label="Minimum"
                              id="minValue"
                              name="minValue"
                              type="number"
                              placeholder=""
                              divClass={6}
                              value={minValue}
                              required={true}
                              validated={validated}
                              errors={errors}
                              onChange={onChange}
                            />
                            <FormInputComponent
                              key={item.indicatorName}
                              label="Maximum"
                              id="maxValue"
                              name="maxValue"
                              type="number"
                              placeholder=""
                              divClass={6}
                              value={maxValue}
                              required={true}
                              validated={validated}
                              errors={errors}
                              onChange={onChange}
                            />
                            <FormInputComponent
                              key={item.metricName}
                              label="Weight"
                              id="points"
                              name="points"
                              type="number"
                              placeholder=""
                              divClass={6}
                              value={points}
                              required={true}
                              validated={validated}
                              errors={errors}
                              onChange={onChange}
                            />
                            <div className="form-row">
                              <div className="my-1 mx-2">
                                <button
                                  className="btn btn-sm btn-outline-primary my-4"
                                  style={{ cursor: "pointer" }}
                                  type="button"
                                  onClick={() =>
                                    this.handleOnAddMetrics(
                                      {
                                        minValue,
                                        maxValue,
                                        points,
                                        scoreIndicatorOptionId
                                      },
                                      onReloadFields
                                    )
                                  }
                                >
                                  <React.Fragment>
                                    <i className="far fa-save fa-lg mr-2" />{" "}
                                    Add
                                  </React.Fragment>
                                </button>
                              </div>
                            </div>
                            <div
                              className="table-responsive text-nowrap"
                              style={{
                                maxHeight: "150px",
                                overflowY: "scroll",
                              }}
                            >
                              <table className="table table-hover table-content table-sm table-striped mb-0">
                                <thead>
                                  <tr>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>#</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Minimum Value</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Maximum Value</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Weight</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong></strong>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {metrics.map((item) => (
                                    <tr key={metrics.indexOf(item)}>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {metrics.indexOf(item) + 1}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {item.minValue}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {item.maxValue}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {item.points}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        <i
                                          className="far fa-trash-alt ml-5 red-text fa-lg"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            this.handleOnRemoveMetrics(
                                              item
                                            )
                                          }
                                        ></i>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </React.Fragment>
                        ) : (
                          <FormInputComponent
                            key={item.metricName}
                            id="points"
                            name="points"
                            type="number"
                            placeholder=""
                            divClass={6}
                            value={points}
                            required={true}
                            validated={validated}
                            errors={errors}
                            onChange={onChange}
                          />
                        )
                        )}

                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.UPDATE,
                      "Point Metrics Parameter",
                      () => {
                        this.onFormSubmit({
                          scoreIndicatorOptionId,
                          points,
                          maxValue,
                          minValue,
                          // indicatorOptions
                        }, onReloadFields);
                      }
                    );
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => togglePatchModal(false, {}, {})}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        > </ModifyIndicatorParamsForm>
      </Modal>
    );
  }
}

export default ModifyPointMetrics;
