import React, { Component } from "react";

export interface IUpdateUserApprovalDetailProps {
  item: any;
}

export interface IUpdateUserApprovalDetailState {
  item: any;
}

class UpdateUserApprovalDetail extends Component<
  IUpdateUserApprovalDetailProps,
  IUpdateUserApprovalDetailState
> {
  constructor(props: IUpdateUserApprovalDetailProps) {
    super(props);
    this.state = {
      item: {},
    };
  }

  static getDerivedStateFromProps(
    nextProps: IUpdateUserApprovalDetailProps,
    prevState
  ) {
    if (nextProps.item) {
      return { item: JSON.parse(nextProps.item) };
    }
    return null;
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <h6>Update User Approval Detail</h6>
        </div>
      </React.Fragment>
    );
  }
}

export default UpdateUserApprovalDetail;
