import React from "react";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import billingValidationService from "../../../services/billing/billingValidationService";
import thirdPartyAccountConfigService from "../../../services/billing/thirdPartyAccountConfigService";
import { ActionTypes } from "../../../enums/actionTypes";
import DetailItem from "../../../components/detailItem";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

interface EditThirdPartyAccountConfigurationFields {
  institutionCode: string;
  thirdParty: string;
  bank: string;
  bankCode: string;
  accountNumber: string;
}

class EditThirdPartyAccountConfigurationForm extends BaseFormComponent<EditThirdPartyAccountConfigurationFields> { }

interface IEditThirdPartyAccountConfigurationProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}
interface IEditThirdPartyAccountConfigurationState {
  ownUpdate: boolean;
}

class EditThirdPartyAccountConfiguration extends React.Component<
  IEditThirdPartyAccountConfigurationProps,
  IBaseFormState & IEditThirdPartyAccountConfigurationState
> {
  constructor(props: IEditThirdPartyAccountConfigurationProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      ownUpdate: false,
    };
  }

  static getDerivedStateFromProps(prevState) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    return null;
  }

  onFormSubmit(fields: EditThirdPartyAccountConfigurationFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors: billingValidationService.validateEditThirdPartyAccountConfigurationForm(fields),
          ownUpdate: true,
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (fields: EditThirdPartyAccountConfigurationFields, onReloadFieldsCB: any) => {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const payload = this.props.item;

      payload.bank = fields.bank;
      payload.bankCode = fields.bankCode;
      payload.accountNumber = fields.accountNumber;

      const response = await thirdPartyAccountConfigService.updateThirdPartyAccountConfig(payload.thirdPartyAccountConfigurationId, payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit ThirdParty Account Configuration",
          description: `Edited details of ThirdParty Account Configuration ${payload.thirdParty}`,
        });
        toast.info(`Third Party Account Configuration ${payload.thirdParty} Edited successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      }
      onReloadFieldsCB();
      this.setState({ submitting: false, ownUpdate: true }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
    }
  };

  render() {
    const { item, showEditModal, toggleEditModal } = this.props;
    const { validated, submitting, errors } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-gopuram fa-sm cyan-text mr-3" />
              Edit Third Party Account Configuration -{" "}
              <span className="font-weight-bold">{item.institutionName}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditThirdPartyAccountConfigurationForm
          initialValues={{
            institutionCode: item.institutionCode,
            thirdParty: item.thirdParty,
            bank: item.bank,
            bankCode: item.bankCode,
            accountNumber: item.accountNumber,
          }}
          FormComponent={({
            fields: {
              institutionCode,
              thirdParty,
              bank,
              bankCode,
              accountNumber,
            },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    <h6
                      className="card-title float-left"
                      style={{ marginBottom: "0px" }}
                    >
                      General Information
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <dl className="row">
                          <DetailItem
                            label="Institution Name"
                            labelSize={7}
                            valueSize={5}
                            value={item.institutionName}
                          />
                          <DetailItem
                            label="Bank"
                            labelSize={7}
                            valueSize={5}
                            value={
                              <DetailItemInputComponent
                                id="bank"
                                type="text"
                                name="bank"
                                placeholder=""
                                value={bank}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                              />
                            }
                          /><DetailItem
                            label="Bank Code"
                            labelSize={7}
                            valueSize={5}
                            value={
                              <DetailItemInputComponent
                                id="bankCode"
                                type="text"
                                name="bankCode"
                                placeholder=""
                                value={bankCode}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                              />
                            }
                          />
                        </dl>
                      </div>
                      <div className="col-md-6">
                        <dl className="row">
                          <DetailItem
                            label="Institution Code"
                            labelSize={7}
                            valueSize={5}
                            value={item.institutionCode}
                          />
                          <DetailItem
                            label="Third Party"
                            labelSize={7}
                            valueSize={5}
                            value={item.thirdParty}
                          />
                          <DetailItem
                            label="Account Number"
                            labelSize={7}
                            valueSize={5}
                            value={
                              <DetailItemInputComponent
                                id="accountNumber"
                                type="text"
                                name="accountNumber"
                                placeholder=""
                                value={accountNumber}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                              />
                            }
                          />
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(e, ActionTypes.UPDATE, "Third Party Account Configuration", () => {
                      this.onFormSubmit(
                        {
                          institutionCode,
                          bank,
                          bankCode,
                          thirdParty,
                          accountNumber,
                        },
                        onReloadFields
                      );
                    });
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></EditThirdPartyAccountConfigurationForm>
      </Modal >
    );
  }
}

export default EditThirdPartyAccountConfiguration;
