import React, { Component } from "react";
// import _ from "lodash";
import NavBar from "./navbar";
import Footer from "./footer";
import SideNav from "./sidenav";
import IdleTimer from "react-idle-timer";
import usersService from "../../services/auth/usersService";
import authService, { getCurrentUserSession, getCurrentUsersRoles, setCurrentUserSession } from "./../../services/auth/authService";
import Lock from "./lock";
import { HubConnectionBuilder } from "@microsoft/signalr";
import Content from "./content";
import { decodeToken } from "react-jwt";
import sweepConfigService from "../../services/sweep/sweepConfigService";

interface IHomeState {
    currentUser: any;
    showLock: boolean;
    activePath: string;
    showSweepConfigReminder: boolean;
}

class Home extends Component<{}, IHomeState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            currentUser: {},
            showLock: false,
            activePath: "",
            showSweepConfigReminder: false,
        };
    }

    handleOnActive = () => {
        // console.log("your session is now active...");
    };

    handleOnIdle = async () => {
        // console.log("application is idle");
        const currentUserSession = getCurrentUserSession();
        if (!currentUserSession.hasOwnProperty("locked") || currentUserSession.locked === false) {
            currentUserSession.locked = true;
            setCurrentUserSession(currentUserSession);
            // console.log("you have been idle for 5 minutes now...");
            try {
                // const user = await usersService.retrieveUserById(
                //   currentUserSession.userId
                // );

                this.setState({
                    showLock: true,
                    // currentUser: user.data,
                });
            } catch (error) {}
        }
    };

    handleUnlockUser = () => {
        // console.log("about to unlock user...");
        const currentUserSession = getCurrentUserSession();
        currentUserSession.locked = false;
        setCurrentUserSession(currentUserSession);
        this.setState({ showLock: false });
    };

    async componentDidMount() {
        let currentUserSession: any = {};
        currentUserSession = getCurrentUserSession();
        const sweepInstCode = decodeToken(currentUserSession.token || "")?.["InstitutionCode"];
        if (getCurrentUsersRoles().includes("remotesession")) {
            currentUserSession = JSON.parse(localStorage.getItem("remoteUserSession") || "");
        }
        const decodedToken = decodeToken(currentUserSession.token || "");
        // console.log(currentUserSession)
        // console.log(currentUserSession.token)
        // console.log(decodedToken)
        this.setState({ showLock: Boolean(getCurrentUserSession().locked) });
        // await userSessionService.validateUserSession();
        // console.log("about to fetch user");

        // console.log(sweepInstCode)
        const response = await Promise.all([
            usersService.retrieveUserById(decodedToken?.["UserId"]),
            sweepConfigService.retrieveSweepConfigByInstitutionCode(sweepInstCode),
        ]);
        const user = response[0];
        const sweepConfig = response[1];
        localStorage.setItem("current user", `${user.data.firstName} ${user.data.lastName}`);
        // console.log("Config :: ", sweepConfig);
        // const user = await usersService.retrieveUserById(decodedToken?.["UserId"]);
        this.setState({
            currentUser: user.data,
            showSweepConfigReminder: sweepConfig.status === 204 ? true : false,
        });
        // console.log('gotten user: ', user.data)

        const authBaseAddress = process.env.REACT_APP_AUTH_BASEADDRESS?.toString().split("api/")[0];
        const connection = new HubConnectionBuilder().withUrl(`${authBaseAddress}sessionhub`).withAutomaticReconnect().build();

        connection
            .start()
            .then((result) => {
                // console.log("Connected to Session!", connection);

                if (connection.connectionId && connection.state === "Connected") {
                    // console.log("about to check...");
                    connection.invoke("LogOutOtherUsers", Number(decodedToken?.["UserId"]), Number(decodedToken?.["InstitutionId"]));
                }

                connection.on("LogOutOtherUsers", (userId, institutionId) => {
                    if (decodedToken?.["InstitutionId"] === institutionId && decodedToken?.["UserId"] === userId) {
                        authService.logout();
                    }
                });
            })
            .catch((e) => console.log("Connection failed: ", e));
    }

    setPathname = (pathname) => {
        // console.log("**", pathname);
        this.setState({ activePath: pathname });
    };

    render() {
        return (
            <React.Fragment>
                <IdleTimer
                    element={document}
                    onActive={this.handleOnActive}
                    onIdle={this.handleOnIdle}
                    // onAction={this.handleOnAction}
                    debounce={250}
                    timeout={Number(process.env.REACT_APP_TIMEOUT) * 60 * 1000}
                    // timeout={1000 * 6}
                />
                <Lock showLock={this.state.showLock} currentUser={this.state.currentUser} handleUnlockUser={this.handleUnlockUser} />
                <SideNav activePath={this.state.activePath} />
                <div className="has-sidebar-left has-sidebar-tabs" style={{ position: "relative" }}>
                    <NavBar user={this.state.currentUser} />
                    {this.state.showSweepConfigReminder ? (
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            <strong>Sweep has not been configured for your institution! Please contact Appzone Lend. </strong>
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    ) : (
                        ""
                    )}
                    <Content setPathname={this.setPathname} />
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

export default Home;
