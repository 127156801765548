import React, { Fragment } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import FormInputComponent from "../../../components/formInputComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import creditAssessmentValidationService from "../../../services/creditAssessment/creditAssessmentValidationService";
import scoringIndicatorService from "../../../services/creditAssessment/scoringIndicatorService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import { Button, Modal } from "react-bootstrap";

interface EditScoreIndicatorFields {
  exactValue: string;
}

interface IMetric {
  optionName?: string;
}

class EditScoreIndicatorForm extends BaseFormComponent<EditScoreIndicatorFields> { }

interface IEditScoreIndicatorProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}

interface IEditScoreIndicatorState {
  scoreMetrics: IMetric[];
}

class EditScoreIndicator extends React.Component<
  IEditScoreIndicatorProps,
  IBaseFormState & IEditScoreIndicatorState
> {
  constructor(props: IEditScoreIndicatorProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      scoreMetrics: [],
      errors: {},
    };
  }

  submit = async (
    onReloadFieldsCB: any
  ) => {
    try {
      this.setState({
        submitting: true,
      });
      const { scoreMetrics } = this.state;
      // let scoreMetric = scoreMetrics.map(x => x.exactValue);
      console.log(scoreMetrics)
      const { item } = this.props;
      const response = await scoringIndicatorService.addMetricsToScoreIndicator(item.id, scoreMetrics);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Add Score Indicator",
          description: `Added new Score Indicator`,
        });
        toast.info(`Score Indicator created successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false });
      this.resetFields();
      onReloadFieldsCB();
      this.setState({ submitting: false }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false });
      return false;
    } finally {
      return true;
    }
  }

  processMetricIntoProperObject = (metric: EditScoreIndicatorFields): IMetric => {
    return {
      optionName: metric.exactValue,
    };
  }

  handleOnAddMetricToCollection = (metricInput: EditScoreIndicatorFields) => {
    this.setState(
      {
        errors:
          creditAssessmentValidationService.validatePointMetricInputFinite(metricInput),
        validated: true
      },
      () => {
        if (Object.keys(this.state.errors).length === 0) {
          const metric = this.processMetricIntoProperObject(metricInput);
          const scoreMetrics = [...this.state.scoreMetrics];
          let checker: any = {}
          checker = scoreMetrics.filter((x) => x.optionName === metric.optionName);
          if (!_.isEmpty(checker)) {
            toast.info(`Score Indicator metric already exists for this "exact value"`, {
              autoClose: 6000,
              type: toast.TYPE.ERROR,
              hideProgressBar: false,
            });
          } else {
            scoreMetrics.push(metric);
          }
          this.setState({ scoreMetrics });
        }
      }
    );

  };

  handleOnRemoveMetricFromCollection = (metric: IMetric) => {
    const scoreMetrics = [...this.state.scoreMetrics];
    if (scoreMetrics.includes(metric)) {
      scoreMetrics.splice(
        scoreMetrics.indexOf(metric),
        1
      );
    }
    this.setState({ scoreMetrics });
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      scoreMetrics: []
    });
  }

  render() {
    const {
      item,
      showEditModal,
      toggleEditModal
    } = this.props;
    const {
      errors,
      validated,
      scoreMetrics,
      submitting
    } = this.state;

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-gopuram fa-sm cyan-text mr-3" />
              Add Score Metrics
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditScoreIndicatorForm
          initialValues={{ exactValue: "" }}
          FormComponent={({
            fields: { exactValue },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    <h6
                      className="card-title float-left"
                      style={{ marginBottom: "0px" }}
                    >
                      Score Metrics
                    </h6>
                  </div>
                  <div className="card-body">
                    <Fragment>
                      <div className="form-row">
                        <div className="col-md-12">
                          <div className="form-row">
                            <Fragment>
                              <FormInputComponent
                                id="exactValue"
                                type="text"
                                name="exactValue"
                                placeholder=""
                                divClass={6}
                                value={exactValue}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                              />
                            </Fragment>
                            <div className="my-1 mx-2">
                              <button
                                className="btn btn-sm btn-outline-primary float-right my-4"
                                style={{ cursor: "pointer" }}
                                type="button"
                                onClick={() =>
                                  this.handleOnAddMetricToCollection(
                                    { exactValue }
                                  )
                                }
                              >
                                <React.Fragment>
                                  <i className="far fa-save fa-lg mr-2" />{" "}
                                  Save Metric
                                </React.Fragment>
                              </button>
                            </div>
                          </div>

                          <div className="form-row col-md-10">
                            <div
                              className="table-responsive text-nowrap"
                              style={{
                                maxHeight: "150px",
                                overflowY: "scroll",
                              }}
                            >
                              <table className="table table-hover table-content table-sm table-striped mb-0">
                                <thead>
                                  <tr>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>#</strong>
                                    </th>
                                    <Fragment>
                                      <th
                                        scope="col"
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                        }}
                                      >
                                        <strong>Exact Value</strong>
                                      </th>
                                    </Fragment>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    ></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <React.Fragment>
                                    {scoreMetrics.map((item) => (
                                      <tr key={scoreMetrics.indexOf(item)}>
                                        <td
                                          style={{
                                            paddingTop: "0.2rem",
                                            paddingBottom: "0.2rem",
                                            fontSize: "small",
                                          }}
                                        >
                                          {scoreMetrics.indexOf(item) + 1}
                                        </td>
                                        <Fragment>
                                          <td
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                              fontSize: "small",
                                            }}
                                          >
                                            {item.optionName}
                                          </td>
                                        </Fragment>
                                        <td
                                          style={{
                                            paddingTop: "0.2rem",
                                            paddingBottom: "0.2rem",
                                            fontSize: "small",
                                          }}
                                        >
                                          <i
                                            className="far fa-trash-alt ml-5 red-text fa-lg"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              this.handleOnRemoveMetricFromCollection(
                                                item
                                              )
                                            }
                                          ></i>
                                        </td>
                                      </tr>
                                    ))}
                                  </React.Fragment>
                                </tbody>
                              </table>
                              <div
                                style={{
                                  visibility: errors.ScoreIndicatorSteps,
                                  fontSize: "80%",
                                  color: "#dc3545",
                                }}
                              >
                                {errors.ScoreIndicatorSteps}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(e, ActionTypes.UPDATE, "Score Metrics", () => {
                      this.submit(onReloadFields)
                    });
                  }}
                  disabled={submitting || scoreMetrics.length == 0}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>

          )}
        ></EditScoreIndicatorForm>
      </Modal>
    );
  }
}

export default EditScoreIndicator;
