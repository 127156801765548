import http from "../httpService";

const recovaCollectionBaseAddress = process.env.REACT_APP_COLLECTIONS_BASEADDRESS;
// const recovaCollectionBaseAddress = "https://localhost:44309/api/";

export function createCollectionsInstitution(payload) {
  return http.post(
      recovaCollectionBaseAddress +
      `collections/institution`,
      payload
  );
}

export function retrieveAllCollectionsInstitutions() {
  return http.get(
    recovaCollectionBaseAddress +
      `collections/institution`
  );
}

export function retrieveCollectionsInstitutionById(id) {
  return http.get(
    recovaCollectionBaseAddress +
      `collections/institution/${id}`
  );
}

export function retrieveCollectionsInstitutionByInstitutionCode(institutionCode) {
  return http.get(
    recovaCollectionBaseAddress +
      `collections/institution/getbycode/${institutionCode}`
  );
}

export function changeEmailAddressForInstitution(collectionInstitutionId, emailAddress) {
  return http.put(
    recovaCollectionBaseAddress +
      `collections/institution/${collectionInstitutionId}/changeEmailAddress/${emailAddress}`
  );
}

// export function filterPagedCreditQualificationRules(
//   data: any,
//   pageSize: number,
//   pageNumber: number
// ) {
//   return http.post(
//     recovaCollectionBaseAddress +
//       `CreditQualificationRule/FilterCreditQualificationRules?pageSize=${pageSize}&pageNumber=${pageNumber}`,
//     data
//   );
// }

export default {
  createCollectionsInstitution,
  retrieveAllCollectionsInstitutions,
  retrieveCollectionsInstitutionById,
  retrieveCollectionsInstitutionByInstitutionCode,
  changeEmailAddressForInstitution
};
