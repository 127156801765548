import http from "../httpService";

const billingBaseAddress = process.env.REACT_APP_BILLING_BASEADDRESS;

export function createSubscriptionFee(accountConfig) {
    return http.post(
        billingBaseAddress + `FeeConfiguration/hybridAppraisal/CreateSubscriptionFee`,
        accountConfig
    );
}

export function getSubscriptionFeeById(id: string) {
    return http.get(
        billingBaseAddress + `FeeConfiguration/hybridAppraisal/SubscriptionFee/${id}`
    );
}

export function getAllSubscriptionFee() {
    return http.get(
        billingBaseAddress + `FeeConfiguration/hybridAppraisal/SubscriptionFee/GetAll`
    );
}

export function filterSubscriptionFee(institutionCategory: string,pageNumber: number, pageSize: number,sortColumn: string, sortOrder: string) {
    return http.get(
        billingBaseAddress +
        `FeeConfiguration/hybridAppraisal/SubscriptionFee/FilterWithPaging?InstitutionCategory=${institutionCategory}&PageNumber=${pageNumber}&PageSize=${pageSize}&SortColumn=${sortColumn}&SortOrder=${sortOrder}`
    );
}
export function editSubscriptionFee(id,accountConfig) {
    return http.put(
        billingBaseAddress + `FeeConfiguration/hybridAppraisal/SubscriptionFee/Edit/${id}`,
        accountConfig
    );
}

export function createTransactionFee(accountConfig) {
    return http.post(
        billingBaseAddress + `FeeConfiguration/hybridAppraisal/CreateTransactionFee`,
        accountConfig
    );
}
export function getValidationPricing() {
    return http.get(
        billingBaseAddress + `FeeConfiguration/hybridAppraisal/GetValidationPricingOptions`
    );
}

export function getTransactionFeeById(id: string) {
    return http.get(
        billingBaseAddress + `FeeConfiguration/hybridAppraisal/TransactionFee/${id}`
    );
}

export function getAllTransactionFee() {
    return http.get(
        billingBaseAddress + `FeeConfiguration/hybridAppraisal/TransactionFee/GetAll`
    );
}

export function filterTransactionFee(institutionCategory: string,pageNumber: number, pageSize: number,sortColumn: string, sortOrder: string) {
    return http.get(
        billingBaseAddress +
        `FeeConfiguration/hybridAppraisal/TransactionFee/FilterWithPaging?InstitutionCategory=${institutionCategory}&PageNumber=${pageNumber}&PageSize=${pageSize}&SortColumn=${sortColumn}&SortOrder=${sortOrder}`
    );
}
export function editTransactionFee(id,accountConfig) {
    return http.put(
        billingBaseAddress + `FeeConfiguration/hybridAppraisal/TransactionFee/Edit/${id}`,
        accountConfig
    );
}


export default {
    getValidationPricing,
    createSubscriptionFee,
    getSubscriptionFeeById,
    getAllSubscriptionFee,
    filterSubscriptionFee,
    editSubscriptionFee,
    createTransactionFee,
    getTransactionFeeById,
    getAllTransactionFee,
    filterTransactionFee,
    editTransactionFee,
};
