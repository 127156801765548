import React, { Component, useContext } from "react";
import { Rule } from "./Rule";
import { Accordion, Card } from "react-bootstrap";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import textFormatService from "../../../services/utility/textFormatService";
import NewItemComponent from "./newItemComponent";

function ContextAwareToggle({ eventKey, level }) {
  const currentEventKey = useContext(AccordionContext);

  const toggleOnClick = useAccordionToggle(eventKey);

  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";

  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      <b>Level {level}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
}

export interface INonFinancialProps {
  rules: any;
  item: any;
  events: any;
  viewing: boolean;
  child: boolean;
  group?: any;
}

export interface INonFinancialState {
  item: any;
  rules: any;
  child: boolean;
}

export class NonFinancial extends Component<
  INonFinancialProps,
  INonFinancialState
> {
  constructor(props: INonFinancialProps) {
    super(props);
    this.state = {
      rules: props.rules,
      item: props.item,
      child: props.child,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // if (nextProps.item !== prevState.item) {
    // console.log("New props received ", nextProps);
    // console.log("Previous state", prevState);
    return { rules: nextProps.rules, item: nextProps.item };
    // } else return null;
  }

  render() {
    // console.log(this.state.rules, this.props.rules);
    // console.log(this.props);
    //Possibly problematic
    if (this.state.rules.length === 0 && this.props.rules.length > 0) {
      this.setState({ rules: this.props.rules });
    }
    var rules = this.state.rules;
    if (this.state.child === true) {
      var rs = this.state.rules.map((x) =>
        x.filter((y) => y.financialGroupId === this.props.group.id)
      );
      rules = rs.filter((x) => x.length > 0);
      var n = rules.map((x) => x[0].level);
      // recreate missing indexes (Do not skip empty levels)
      n.map((x, idx) => {
        if (idx === n.length - 1) return;
        if (x + 1 !== n[idx + 1]) {
          rules.splice(x, 0, []);
        }
      });
      //console.log("missing index",missingIndexes)
    }
    // console.log(
    //   rules.map((step, idx) => "Step - " + step + "... idx - " + idx + "\n")
    // );
    return (
      <React.Fragment key={textFormatService.generateGUID()}>
        <div className="card">
          <div className="approvable">
            <Accordion defaultActiveKey="0">
              {rules.map((step, idx) => (
                //Set the level accordion
                //encapsulate rule in content box.
                <div key={idx}>
                  <Card>
                    <ContextAwareToggle
                      eventKey={`${idx}`}
                      level={idx + 1}
                    ></ContextAwareToggle>
                    <Accordion.Collapse eventKey={`${idx}`}>
                      <Card.Body className={"rule-card-body"}>
                        {" "}
                        <Rule
                          step={step}
                          key={idx}
                          level={idx + 1}
                          events={this.props.events}
                          viewing={this.props.viewing}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </div>
              ))}
            </Accordion>
          </div>
        </div>
        {!this.props.viewing ? (
          <NewItemComponent
            name="new-level"
            content="Click to add new level"
            events={this.props.events}
            eventData={{
              title: "Add New Level",
              userRoleId: "",
              applicableAmount: 0,
              combinationNo: 0,
              level: rules.length + 1,
            }}
          />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}
