import Joi from "joi-browser";
import _ from "lodash";

export function validateOnDemandReactiveSweepForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      externalReference: Joi.string()
        .required()
        .error((errors) => {
          // console.log("externalReference joi-error", errors);
          return {
            message: "External Reference is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateSweepInitiationConfigModalForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      noOfSweepsDaily: Joi.string()
        .required()
        .error((errors) => {
          // console.log("externalReference joi-error", errors);
          return {
            message: "No Of Sweeps Daily is required.",
          };
        }),
      interval: Joi.string()
        .required()
        .error((errors) => {
          // console.log("externalReference joi-error", errors);
          return {
            message: "Interval is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateModifyGeneralSweepSettingsForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      proactiveSweepInterval: Joi.string()
        .required()
        .error((errors) => {
          // console.log("externalReference joi-error", errors);
          return {
            message: "Proactive Sweep Interval is required.",
          };
        }),
      maxProactiveSweeps: Joi.string()
        .required()
        .error((errors) => {
          // console.log("externalReference joi-error", errors);
          return {
            message: "Max Proactive Sweeps is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export default {
  validateOnDemandReactiveSweepForm,
  validateSweepInitiationConfigModalForm,
  validateModifyGeneralSweepSettingsForm
};
