import * as React from "react";
import _ from "lodash";
import { Fragment } from "react";
import {
  IFilterData,
  BaseListComponentState,
} from "../../../components/BaseListComponent";
import { printPDF } from "../../../services/utility/textFormatService";
import {
  createFilterData,
  mapEntityList,
} from "./../../../services/utility/autoMapperService";
import BaseListComponent from "./../../../components/BaseListComponent";
import institutionsService from "../../../services/auth/institutionsService";
import ItemsTable, {
  filterFieldData,
} from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "./../../../components/tableComponents/tableFilterPane";
import { getCurrentUsersRoles } from "../../../services/auth/authService";
import { MandateLimit } from "../../../enums/mandateLimits";
import { retrieveAllSuperAdmins } from "../../../services/auth/usersService";
import sweepConfigService from "../../../services/sweep/sweepConfigService";
import appraisalInstitutionService from "../../../services/hybridAppraisal/appraisalInstitutionService";
import InstitutionDetail from "./institutionDetail";
import EditInstitution from "./editInstitution";
import AuditTrailDetail from "../../auditTrail/auditTrailDetail";

export interface InstitutionsProps {}

export interface InstitutionsState extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
}

class InstitutionsList extends BaseListComponent<InstitutionsState> {}

class Institutions extends React.Component<
  InstitutionsProps,
  InstitutionsState
> {
  constructor(props: InstitutionsProps) {
    super(props);
    this.state = {
      data: createFilterData([
        "name",
        "status",
        "category",
        // "dmb",
      ]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [
        { name: "name", fieldType: "text", value: "" },
        {
          name: "status",
          fieldType: "select",
          options: ["Active", "Inactive"],
          value: "",
        },
        {
          name: "dmb",
          fieldType: "select",
          options: ["1", "0"],
          value: "",
        },
        {
          name: "category",
          fieldType: "select",
          options: ["CommercialBank", "BankOneOFI", "OFI"],
          value: "",
        },
      ],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
    };
  }

  handlePageChange = async (
    currentPage: number,
    pageSize: number,
    data?: IFilterData
  ) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true });
    data = typeof data === "undefined" ? this.state.data : data;
    try {
      const institutions = await institutionsService.filterInstitutions(
        data,
        pageSize,
        currentPage
      );
      this.setState({
        tableItems: mapEntityList({
          entityList: institutions.data.item2,
          properties: [
            { oldName: "name", newName: "name" },
            { oldName: "code", newName: "code" },
            {
              isTag: true,
              oldName: "category",
              newName: "category",
              success: "success",
              danger: "danger",
              default: "CommercialBank",
              primary: "primary",
              secondary: "secondary",
              warning: "OFI",
              light: "BankOneOFI",
              dark: "dark",
            },
            { oldName: "disputeContactEmail", newName: "disputeContactEmail" },
            { oldName: "disputeContactPhone", newName: "disputeContactPhone" },
            {
              oldName: "mandateLimit",
              newName: "mandateLimit",
              enum: MandateLimit,
              split: true,
            },
            {
              isTag: true,
              oldName: "status",
              newName: "status",
              success: "Active",
              danger: "Inactive",
              default: "default",
              primary: "primary",
              secondary: "secondary",
              warning: "warning",
              light: "light",
              dark: "dark",
            },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: institutions.data.item1,
        fetching: false,
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        // this.setState({ errors, fetching: false });
      }
    } finally {
      this.setState({ fetching: false });
    }
  };

  handleOnExport = async (type: string) => {
    try {
      switch (type) {
        case "XLSX":
        case "CSV":
          return await institutionsService.downloadReport(type);
        case "PDF":
          const response = await institutionsService.downloadReportPDF();
          printPDF(response.data);
          break;
        default:
          break;
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        //this.setState({ errors, fetching: false });
      }
    }
  };

  // toggleAddSweepConfigModal = (reloadDetails, item: any) => {
  //   console.log("about to toggle add sweep config modal...");
  //   this.setState({
  //     showAddSweepConfigModal: !this.state.showAddSweepConfigModal,
  //   });
  //   if (reloadDetails === true) {
  //     this.fetchItemById(item);
  //   }
  //   this.setState({ showDetailsModal: !this.state.showDetailsModal });
  // };

  fetchItemById = async (item: any) => {
    // await activityTrailService.saveActivityTrail({
    //   actionType: "View Details",
    //   description: `Viewed details of Branch ${item.name}`
    // });
    this.setState({ fetchingDetail: true });
    const inst = await institutionsService.retrieveInstitutionById(item.id);
    const institution = inst.data;
    if (!_.isEmpty(institution.systemSettings)) {
      institution.enableMandatesByDefault =
        institution.systemSettings.enableMandatesByDefault;
      institution.enableAdVerification =
        institution.systemSettings.enableAdVerification;
    }

    const response = await Promise.allSettled([
      retrieveAllSuperAdmins(institution.id),
      sweepConfigService.retrieveSweepConfigByInstitutionCode(institution.code),
      appraisalInstitutionService.retrieveByInstitutionCode(institution.code),
    ]);
    const adminUsers =
      response[0].status === "fulfilled" ? response[0].value.data : [];
    const sweepConfig =
      response[1].status === "fulfilled" ? response[1].value.data : [];
    const appraisalInstitution =
      response[2].status === "fulfilled" ? response[2].value.data : {};

    // let appraisalInstitution;
    // try {
    //     appraisalInstitution = await appraisalInstitutionService.retrieveByInstitutionCode(item.code);
    //     if (appraisalInstitution.data === undefined) {
    //         throw new Error("Appraisal Institution Not Found")
    //     }
    // } catch (error) {
    //     appraisalInstitution = {};
    // }

    institution["adminUsers"] = adminUsers;
    institution["sweepConfig"] = sweepConfig;
    institution["appraisalInstitution"] = !_.isEmpty(appraisalInstitution)
      ? appraisalInstitution.data
      : appraisalInstitution;
    institution.enableHybridAppraisal = !_.isEmpty(appraisalInstitution)
      ? true
      : false;
    console.log("check this guy: ", institution);
    this.setState({ item: institution, fetchingDetail: false });
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }

  render() {
    const {
      // data,
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Institutions</b>
              </h3>
            </div>
            <InstitutionsList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              initialValues={{
                data: createFilterData([
                  "name",
                  "status",
                  "category",
                  "dmb",
                ]) as IFilterData,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: [
                  { name: "name", fieldType: "text", value: "" },
                  {
                    name: "status",
                    fieldType: "select",
                    options: ["Active", "Inactive"],
                    value: "",
                  },
                  {
                    name: "dmb",
                    fieldType: "select",
                    options: ["true", "false"],
                    value: "",
                  },
                  {
                    name: "category",
                    fieldType: "select",
                    options: ["CommercialBank", "BankOneOFI", "OFI"],
                    value: "",
                  },
                ],
                item: {},
                jsonBefore: {},
                jsonAfter: {},
                fetchingTrail: false,
                entityName: "Recova.Auth.Institution",
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                onToggleEditModal,
                showDetailsModal,
                showEditModal,
                fetchTrailItem,
                fetchingTrail,
                jsonAfter,
                jsonBefore,
                onToggleTrailModal,
                showTrailModal,
                trailItem,
                trailMapper,
              }) => (
                <Fragment>
                  <InstitutionDetail
                    showDetailsModal={showDetailsModal}
                    toggleDetailsModal={onToggleDetailsModal}
                    toggleEditModal={onToggleEditModal}
                    showTrail={() => onToggleTrailModal(item)}
                    currentUserRoles={getCurrentUsersRoles()}
                    reloadItem={(item: any) => {
                      this.fetchItemById(item);
                    }}
                    item={item}
                  />
                  <EditInstitution
                    showEditModal={showEditModal}
                    toggleEditModal={(reloadDetails: boolean, item: any) => {
                      onToggleEditModal();
                      if (reloadDetails === true) {
                        this.fetchItemById(item);
                      }
                    }}
                    item={item}
                  />
                  <AuditTrailDetail
                    showDetailsModal={showTrailModal}
                    toggleDetailsModal={onToggleTrailModal}
                    fetchNextTrail={fetchTrailItem}
                    item={trailItem}
                    trailMapper={trailMapper}
                    fetchingDetail={fetchingTrail}
                    jsonBefore={jsonBefore}
                    jsonAfter={jsonAfter}
                  />
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="Institutions"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></InstitutionsList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Institutions;
