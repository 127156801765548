import React from "react";

export default function Error() {
  return (
    <table className="table table-hover table-content table-striped table-bordered table-sm paged-output">
      <thead>
        <tr>
          <th className="text-center ">
            <h5>Unable to load data. Refresh or try again later.</h5>
          </th>
        </tr>
      </thead>
    </table>
  );
}
