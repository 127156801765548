import React, { Fragment } from "react";
import { ISelectItems } from "../../../components/inputComponents/selectBox";
import _ from "lodash";
import SelectInputComponent from "../../../components/formSelectComponent";
import { toast } from "react-toastify";
import BaseFormComponent, { IBaseFormState } from "../../../components/BaseFormComponent";
import billingValidationService from "../../../services/billing/billingValidationService";
import feeConfigService from "../../../services/billing/feeConfigService";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../components/detailItem";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import { ActionTypes } from "../../../enums/actionTypes";
import { Button } from "react-bootstrap";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

interface CreditAssessmentFeeConfigurationFields {
  flatRatePerRequest: string;
  flatRatePerLoanDisbursed: string;
  percentageOfLoanAmount: string;
  flatRatePerSuccessfulRequest: string;
  minimumPerTransaction: string;
  maximumPerTransaction: string;
}

class CreditAssessmentFeeConfigurationForm extends BaseFormComponent<CreditAssessmentFeeConfigurationFields> { }

export interface ICreditAssessmentFeeConfigurationProps { }

export interface ICreditAssessmentFeeConfigurationState {
  institutionCategory: string;
  feeConfig: any;
  fetchingFees: boolean;
  editing: boolean;
}

class CreditAssessmentFeeConfiguration extends React.Component<ICreditAssessmentFeeConfigurationProps, IBaseFormState & ICreditAssessmentFeeConfigurationState> {
  constructor(props: ICreditAssessmentFeeConfigurationProps) {
    super(props);
    this.state = {
      feeConfig: {},
      submitting: false,
      institutionCategory: "",
      fetchingFees: false,
      editing: false,
      errors: [],
      validated: false
    };
  }

  onFormSubmit(fields: CreditAssessmentFeeConfigurationFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors:
            billingValidationService.validateEditCreditAssessmentCategoryFeeConfigurationForm(
              fields
            ),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(fields: CreditAssessmentFeeConfigurationFields, onReloadFieldsCB: any): Promise<boolean> {
    let response: any;
    if (Number(fields.minimumPerTransaction) > Number(fields.maximumPerTransaction)) {
      toast.info(`Minimum Amount cannot be greater than Maximum!`, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      return false;
    }
    try {
      this.setState({ submitting: true });
      const payload: any = {};
      payload.flatRatePerRequest = Number(fields.flatRatePerRequest);
      payload.flatRatePerLoanDisbursed = Number(fields.flatRatePerLoanDisbursed);
      payload.percentageOfLoanAmount = Number(fields.percentageOfLoanAmount);
      payload.flatRatePerSuccessfulRequest = Number(fields.flatRatePerSuccessfulRequest);
      payload.minimumPerTransaction = Number(fields.minimumPerTransaction);
      payload.maximumPerTransaction = Number(fields.maximumPerTransaction);

      response = await feeConfigService.updateBlackboxFeeConfig(this.state.feeConfig.feeConfigurationId, payload);
      // console.log("testing log:: ", response);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit Credit Assessment Fee Configuration",
          description: `Edited details of Credit Assessment Fee Configuration ${this.state.institutionCategory}`,
        });
        toast.info(`Credit Assessment Fee Config for ${this.state.institutionCategory} updated successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false });
      this.resetFields();
      // onReloadFieldsCB();
    } catch (error) {
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        //console.log("error: ", error.response);
        // return false;
      }
      // console.log("testing log:: ", response);
      // console.log("error log:: ", error);
      // console.log("error: ", error.response.status);
      // console.log("error: ", error.response);
      this.setState({ submitting: false });
      return false;
    } finally {
      return true;
    }
  }

  toggleEditMode = async (changeHandler) => {
    const { feeConfig } = this.state;
    await changeHandler("flatRatePerRequest", feeConfig.flatRatePerRequest?.toString());
    await changeHandler("flatRatePerLoanDisbursed", feeConfig.flatRatePerLoanDisbursed?.toString());
    await changeHandler("percentageOfLoanAmount", feeConfig.percentageOfLoanAmount?.toString());
    await changeHandler("flatRatePerSuccessfulRequest", feeConfig.flatRatePerSuccessfulRequest?.toString());
    await changeHandler("minimumPerTransaction", feeConfig.minimumPerTransaction?.toString());
    await changeHandler("minimumPerTransaction", feeConfig.minimumPerTransaction?.toString());
    this.setState({ editing: !this.state.editing })
  }

  resetFields() {
    this.setState({ validated: false, submitting: false });
  }

  handleOnSelectInstitutionCategory = async (id, value) => {
    try {
      this.setState({ institutionCategory: value, feeConfig: {}, editing: false, fetchingFees: true });
      if (value == "") {
        this.setState({ fetchingFees: false });
        return;
      }
      const response = await feeConfigService.retrieveFeeConfigByServiceOfferingAndInstitutionCategory("CreditAssessment", value)
      if (response.data !== undefined) {
        this.setState({ feeConfig: response.data, fetchingFees: false });
      }
      else {
        this.setState({ fetchingFees: false });
      }
    } catch (error) {
      toast.info("Something went wrong.", {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      toast.info(error.response.data.errors[0], {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
    }
  };

  render() {
    const {
      editing,
      errors,
      feeConfig,
      fetchingFees,
      validated,
      submitting,
      institutionCategory,
    } = this.state;

    let institutionCategoryInputData: ISelectItems[] = [
      { name: "Commercial Bank", value: "CommercialBank" },
      { name: "BankOne OFI", value: "BankOneOFI" },
      { name: "OFI", value: "OFI" },
    ];

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Credit Assessmet Fee Configuration</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <form action="#">
                  <div className="form-row">
                    <SelectInputComponent
                      id="institutionCategory"
                      name="institutionCategory"
                      divClass={4}
                      value={institutionCategory}
                      items={institutionCategoryInputData}
                      required={false}
                      validated={false}
                      errors={[]}
                      onChange={this.handleOnSelectInstitutionCategory}
                    />
                  </div>
                  <br />
                  {this.state.institutionCategory != "" ? (
                    <Fragment>
                      {fetchingFees ? (
                        <Skeleton count={4} height={30} />
                      ) : _.isEmpty(feeConfig) ? (
                        "There is no Credit Assessment Fee Configuration available"
                      ) : (
                        <div className="card no-b">
                          <div className="card-body">
                            <div className="row my-3 mx-2">
                              <h5>
                                Fee Configuration -{" "}
                                <span className="font-weight-bold">
                                  {feeConfig.institutionCategory}
                                </span>
                              </h5>
                            </div>
                            <CreditAssessmentFeeConfigurationForm
                              initialValues={{
                                flatRatePerRequest: feeConfig.flatRatePerRequest,
                                flatRatePerLoanDisbursed: feeConfig.flatRatePerLoanDisbursed,
                                percentageOfLoanAmount: feeConfig.percentageOfLoanAmount,
                                flatRatePerSuccessfulRequest: feeConfig.flatRatePerSuccessfulRequest,
                                minimumPerTransaction: feeConfig.minimumPerTransaction,
                                maximumPerTransaction: feeConfig.maximumPerTransaction
                              }}
                              FormComponent={({
                                fields: {
                                  flatRatePerRequest,
                                  flatRatePerLoanDisbursed,
                                  percentageOfLoanAmount,
                                  flatRatePerSuccessfulRequest,
                                  minimumPerTransaction,
                                  maximumPerTransaction
                                },
                                onChange,
                                onReloadFields,
                                onHandleSubmit,
                              }) => (
                                <form action="#">
                                  <div className="card no-b">
                                    <div className="card-body">
                                      <div className="form-row">
                                        <div className="col-md-12">
                                          {_.isEmpty(feeConfig) ? (
                                            <Skeleton count={4} height={30} />
                                          ) : (
                                            <div className="form-row">
                                              <div className="col-md-6">
                                                {!_.isEmpty(feeConfig) && fetchingFees ? (
                                                  <Skeleton count={5} width={30} />
                                                ) : (
                                                  <dl className="row">
                                                    <DetailItem
                                                      label="Service Offering"
                                                      value={feeConfig.serviceOffering}
                                                      labelSize={7}
                                                      valueSize={5}
                                                    />
                                                    {editing ? (
                                                      <React.Fragment>
                                                        <DetailItem
                                                          label="Flat Rate Per Request"
                                                          value={
                                                            <DetailItemInputComponent
                                                              id="flatRatePerRequest"
                                                              type="number"
                                                              name="flatRatePerRequest"
                                                              placeholder=""
                                                              value={flatRatePerRequest}
                                                              required={true}
                                                              validated={validated}
                                                              errors={errors}
                                                              onChange={onChange}
                                                            />
                                                          }
                                                          labelSize={7}
                                                          valueSize={5}
                                                        />
                                                      </React.Fragment>
                                                    ) : (
                                                      <React.Fragment>
                                                        <DetailItem
                                                          label="Flat Rate Per Request"
                                                          value={flatRatePerRequest}
                                                          labelSize={7}
                                                          valueSize={5}
                                                        />
                                                      </React.Fragment>
                                                    )}
                                                  </dl>
                                                )}
                                              </div>
                                              <div className="col-md-6">
                                                {!_.isEmpty(feeConfig) && fetchingFees ? (
                                                  <Skeleton count={5} width={30} />
                                                ) : (
                                                  <dl className="row">
                                                    <DetailItem
                                                      label="Institution Category"
                                                      value={feeConfig.institutionCategory}
                                                      labelSize={7}
                                                      valueSize={5}
                                                    />
                                                    {editing ? (
                                                      <React.Fragment>
                                                        <DetailItem
                                                          label="Flat Rate Per Loan Disbursed"
                                                          value={
                                                            <DetailItemInputComponent
                                                              id="flatRatePerLoanDisbursed"
                                                              type="number"
                                                              name="flatRatePerLoanDisbursed"
                                                              placeholder=""
                                                              value={flatRatePerLoanDisbursed}
                                                              required={true}
                                                              validated={validated}
                                                              errors={errors}
                                                              onChange={onChange}
                                                            />
                                                          }
                                                          labelSize={7}
                                                          valueSize={5}
                                                        />
                                                      </React.Fragment>
                                                    ) : (
                                                      <React.Fragment>
                                                        <DetailItem
                                                          label="Flat Rate Per Loan Disbursed"
                                                          value={flatRatePerLoanDisbursed}
                                                          labelSize={7}
                                                          valueSize={5}
                                                        />
                                                      </React.Fragment>
                                                    )}
                                                  </dl>
                                                )}
                                              </div>
                                              <div className="col-md-6">
                                                {!_.isEmpty(feeConfig) && fetchingFees ? (
                                                  <Skeleton count={5} width={30} />
                                                ) : (
                                                  <dl className="row">
                                                    {editing ? (
                                                      <React.Fragment>
                                                        <DetailItem
                                                          label="Percentage of Loan Amount"
                                                          value={
                                                            <DetailItemInputComponent
                                                              id="percentageOfLoanAmount"
                                                              type="number"
                                                              name="percentageOfLoanAmount"
                                                              placeholder=""
                                                              value={percentageOfLoanAmount}
                                                              required={true}
                                                              validated={validated}
                                                              errors={errors}
                                                              onChange={onChange}
                                                            />
                                                          }
                                                          labelSize={7}
                                                          valueSize={5}
                                                        />
                                                        <DetailItem
                                                          label="Flat Rate Per Successful Request"
                                                          value={
                                                            <DetailItemInputComponent
                                                              id="flatRatePerSuccessfulRequest"
                                                              type="number"
                                                              name="flatRatePerSuccessfulRequest"
                                                              placeholder=""
                                                              value={flatRatePerSuccessfulRequest}
                                                              required={true}
                                                              validated={validated}
                                                              errors={errors}
                                                              onChange={onChange}
                                                            />
                                                          }
                                                          labelSize={7}
                                                          valueSize={5}
                                                        />
                                                      </React.Fragment>
                                                    ) : (
                                                      <React.Fragment>
                                                        <DetailItem
                                                          label="Percentage of Loan Amount"
                                                          value={percentageOfLoanAmount}
                                                          labelSize={7}
                                                          valueSize={5}
                                                        />
                                                        <DetailItem
                                                          label="Flat Rate Per Successful Request"
                                                          value={flatRatePerSuccessfulRequest}
                                                          labelSize={7}
                                                          valueSize={5}
                                                        />
                                                      </React.Fragment>
                                                    )}
                                                  </dl>
                                                )}
                                              </div>
                                              <div className="col-md-6">
                                                {!_.isEmpty(feeConfig) && fetchingFees ? (
                                                  <Skeleton count={5} width={30} />
                                                ) : (
                                                  <dl className="row">
                                                    {editing ? (
                                                      <React.Fragment>
                                                        <DetailItem
                                                          label="Minimum Per Transaction"
                                                          value={
                                                            <DetailItemInputComponent
                                                              id="minimumPerTransaction"
                                                              type="number"
                                                              name="minimumPerTransaction"
                                                              placeholder=""
                                                              value={minimumPerTransaction}
                                                              required={true}
                                                              validated={validated}
                                                              errors={errors}
                                                              onChange={onChange}
                                                            />
                                                          }
                                                          labelSize={7}
                                                          valueSize={5}
                                                        />
                                                        <DetailItem
                                                          label="Maximum Per Transaction"
                                                          value={
                                                            <DetailItemInputComponent
                                                              id="maximumPerTransaction"
                                                              type="number"
                                                              name="maximumPerTransaction"
                                                              placeholder=""
                                                              value={maximumPerTransaction}
                                                              required={true}
                                                              validated={validated}
                                                              errors={errors}
                                                              onChange={onChange}
                                                            />
                                                          }
                                                          labelSize={7}
                                                          valueSize={5}
                                                        />
                                                      </React.Fragment>
                                                    ) : (
                                                      <React.Fragment>
                                                        <DetailItem
                                                          label="Minimum Per Transaction"
                                                          value={minimumPerTransaction}
                                                          labelSize={7}
                                                          valueSize={5}
                                                        />
                                                        <DetailItem
                                                          label="Maximum Per Transaction"
                                                          value={maximumPerTransaction}
                                                          labelSize={7}
                                                          valueSize={5}
                                                        />
                                                      </React.Fragment>
                                                    )}
                                                  </dl>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <hr />
                                    {editing ? (
                                      <React.Fragment>
                                        <div className="card-body">
                                          <button
                                            type="submit"
                                            className="btn btn-sm btn-primary"
                                            disabled={this.state.submitting}
                                            onClick={(e) => {
                                              onHandleSubmit(
                                                e,
                                                ActionTypes.UPDATE,
                                                "Fee Configuration",
                                                () => {
                                                  this.onFormSubmit(
                                                    {
                                                      flatRatePerRequest,
                                                      flatRatePerLoanDisbursed,
                                                      percentageOfLoanAmount,
                                                      flatRatePerSuccessfulRequest,
                                                      minimumPerTransaction,
                                                      maximumPerTransaction
                                                    },
                                                    onReloadFields
                                                  );
                                                }
                                              );
                                            }}
                                          >
                                            {this.state.submitting === false ? (
                                              <React.Fragment>
                                                <i className="fas fa-lg fa-save mr-3" /> Save
                                              </React.Fragment>
                                            ) : (
                                              <React.Fragment>
                                                <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                                Please wait...
                                              </React.Fragment>
                                            )}
                                          </button>
                                        </div>
                                      </React.Fragment>
                                    ) : (
                                      !_.isEmpty(feeConfig) && (
                                        <div className="card-body">
                                          <Button
                                            size="sm"
                                            variant="primary"
                                            disabled={submitting}
                                            onClick={() => this.toggleEditMode(onChange)}
                                          >
                                            <i className="far fa-edit mr-3" />
                                            Edit
                                          </Button>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </form>
                              )}
                            ></CreditAssessmentFeeConfigurationForm>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ) : (
                    ""
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment >
    );
  }
}

export default CreditAssessmentFeeConfiguration;
