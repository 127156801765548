import React from "react";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import BaseFormComponent, { IBaseFormState } from "../../../../../../components/BaseFormComponent";
import textFormatService from "../../../../../../services/utility/textFormatService";
import { toast } from "react-toastify";
import mandateService from "../../../../../../services/mandate/mandateService";
import loanRequestService from "../../../../../../services/hybridAppraisal/loanRequestService";

interface RepaymentScheduleFields { }

class RepaymentScheduleForm extends BaseFormComponent<RepaymentScheduleFields> { }

interface IRepaymentScheduleProps {
    item: any;
    showPatchModal: boolean;
    togglePatchModal: any;
    values: any;
}

interface IRepaymentScheduleState {
    ownUpdate: boolean;
    repaymentDetails: any;
}

class Repayment extends React.Component<
    IRepaymentScheduleProps,
    IBaseFormState & IRepaymentScheduleState
> {
    _isMounted = false;
    constructor(props: IRepaymentScheduleProps) {
        super(props);
        this.state = {
            validated: false,
            submitting: false,
            errors: {},
            ownUpdate: false,
            repaymentDetails: []
        };
    }

    // componentDidMount() {
    //     this._isMounted = true;
    //     // this.generateRepaymentSchedule();
    // }

    // componentWillUnmount() {
    //     this._isMounted = false;
    // }

    async generateRepaymentSchedule(
    ): Promise<boolean> {
        try {
            this.setState({ submitting: true, ownUpdate: true });
            const payload: any = {};
            const account: any = {};
            const config: any = {};
            const publicHolidays: any = [];
            const loanRequestDetail = await loanRequestService.retrieveById(this.props.item.id);
            const loanRequest = loanRequestDetail.data;
            // const loanRequest = { ...this.props.item }
            // console.log(loanRequest)
            const fields = loanRequest.loanDetails
            const jsonLoanDetails = JSON.parse(JSON.parse(JSON.parse(loanRequest.jsonData)).LoanDetails);
            // console.log(jsonLoanDetails)

            var d = new Date();
            var day = d.getDay();
            var tenure = 0
            var subString = 0

            if (fields.repaymentFrequencyPrincipal === "Daily") {
                tenure = Math.round(Number(loanRequest.tenureInDays));
                subString = 10
            }
            else if (fields.repaymentFrequencyPrincipal === "Weekly") {
                tenure = Math.round(Number(loanRequest.tenureInDays / 7));
                subString = 10
            }
            else if (fields.repaymentFrequencyPrincipal === "Monthly") {
                tenure = Math.round(Number(loanRequest.tenureInDays / 30));
                if (fields.repaymentFrequencyInterest === "Weekly") {
                    subString = 10
                }
                else {
                    subString = 7
                }
            }
            else if (fields.repaymentFrequencyPrincipal === "Quarterly") {
                tenure = Math.round(Number(loanRequest.tenureInDays / 91));
                if (fields.repaymentFrequencyInterest === "Weekly") {
                    subString = 10
                }
                else {
                    subString = 7
                }
            }
            else if (fields.repaymentFrequencyPrincipal === "Semi-Annually") {
                tenure = Math.round(Number(loanRequest.tenureInDays / 183));
                if (fields.repaymentFrequencyInterest === "Weekly") {
                    subString = 10
                }
                else {
                    subString = 7
                }

            }
            else if (fields.repaymentFrequencyPrincipal === "Annually") {
                tenure = Math.round(Number(loanRequest.tenureInDays / 365));
                if (fields.repaymentFrequencyInterest === "Weekly") {
                    subString = 10
                }
                else {
                    subString = 7
                }
            }

            var targetDay = fields.repaymentDay === "Sunday" ? 0 : fields.repaymentDay === "Monday" ? 1 : fields.repaymentDay === "Tuesday" ? 2 :
                fields.repaymentDay === "Wednesday" ? 3 : fields.repaymentDay === "Thursday" ? 4 : fields.repaymentDay === "Friday" ? 5 :
                    fields.repaymentDay === "Saturday" ? 6 : 0

            // console.log(tenure)
            account.interestAccrualMode = fields.accrual ? fields.accrual : "Fixed";
            account.id = 0
            account.dateCreatedFinancial = loanRequest.dateCreated
            account.moratarium = fields.moratorium === "Yes" || fields.moratorium !== null ? Number(fields.moratoriumPeriod) : 0
            account.loanCycle = tenure === 0 ? 1 : tenure;
            account.computationMode = "Simple"
            account.computationModeMultiple = 0;
            account.loanAmount = Number(loanRequest.loanAmount / 100)
            account.principalPaymentFrequency = fields.repaymentFrequencyPrincipal === "Semi-Annually" ? "HalfYearly" : fields.repaymentFrequencyPrincipal
            account.principalRepaymentType = "Proratedrepayment"
            account.interestAccrualCommenceDate = loanRequest.dateCreated
            account.interestRate = fields.interestRate;
            account.discountAmount = 0
            account.currentFinancialDate = loanRequest.dateCreated
            account.ledgerBalance = 0
            account.number = ""
            account.interestRepaymentType = "Proratedrepayment"
            account.interestPaymentFrequency = fields.repaymentFrequencyInterest === "Semi-Annually" ? "HalfYearly" : fields.repaymentFrequencyInterest

            config.applyMoratoriumOnPrincipal = fields.moratorium === "Yes" || fields.moratorium !== null ? true : false
            config.applyMoratoriumOnInterest = fields.moratorium === "Yes" || fields.moratorium !== null ? true : false
            config.daysInAYear = 365
            config.isMonday = day === 1 ? true : false
            config.isWednesday = day === 3 ? true : false
            config.isFriday = day === 5 ? true : false
            config.isSunday = day === 0 ? true : false
            config.isTuesday = day === 2 ? true : false
            config.isThursday = day === 4 ? true : false
            config.isSaturday = day === 6 ? true : false
            config.allowSameLoanRepaymentDate = fields.dueDate != "" ? true : false
            config.includeMoratoriumAsPartOfTenure = fields.moratorium === "Yes" || fields.moratorium !== null ? true : false
            config.skipWeekendsAndPublicHolidaysForLoans = true

            payload.account = account
            payload.config = config
            payload.publicHolidays = publicHolidays

            const response = await mandateService.calculateLoanPaymentSchedule("100317", payload);
            if (response.status >= 200 && response.status <= 300) {

                // var collectionSchedule = response.data.reduce((acc, obj) => {
                //     // console.log(obj.paymentDueDate)
                //     // console.log(obj.paymentDueDate.substring(0, 7))
                //     var existingDate = acc.find(item => item.paymentDueDate.substring(0, 7) === obj.paymentDueDate.substring(0, 7));
                //     if (existingDate) {
                //         existingDate.amount = existingDate.amount + obj.amount;
                //         return acc;
                //     }
                //     acc.push(obj);
                //     return acc;
                // }, []);
                // console.log(response.data)
                var prinInterestRepayment = account.principalPaymentFrequency !== account.interestPaymentFrequency ? response.data.reduce((acc, obj) => {
                    var existingDate = acc.find(item =>
                        // console.log(item.paymentDueDate.substring(0, 7))
                        item.paymentDueDate.substring(0, subString) === obj.paymentDueDate.substring(0, subString));
                    // console.log(existingDate)
                    if (existingDate) {
                        existingDate.amount = existingDate.amount + obj.amount;
                        existingDate.paymentType = "PrinTerestPayment"
                        return acc;
                    }
                    acc.push(obj);
                    return acc;
                }, []) : response.data.filter(x => x.paymentType === "InterestPayment")
                var InterestAmount = response.data.filter(x => x.paymentType === "InterestPayment")[0]?.amount;
                var principalAmount = response.data.filter(x => x.paymentType === "PrincipalPayment")[0]?.amount;
                var TenuredAmount = Number(Number(principalAmount) / tenure).toFixed(2)

                var cumulativePrincipal = 0
                var cumulativeTotal = 0
                var outstandingPricipal = Number(loanRequest.loanAmount / 100).toFixed(2)
                var testDate = new Date(prinInterestRepayment[0].paymentDueDate);
                // console.log(prinInterestRepayment)
                // console.log(account.interestPaymentFrequency)

                // console.log(new Date(testDate.getFullYear(), testDate.getMonth(), fields.dueDate, testDate.getHours(), testDate.getMinutes(), testDate.getSeconds(), testDate.getMilliseconds()))


                prinInterestRepayment.forEach(x => {
                    if (x.isApplied === true) {
                        return;
                    }
                    else {
                        var total = account.principalPaymentFrequency === account.interestPaymentFrequency ? Number(Number(x.amount) + Number(TenuredAmount)).toFixed(2) : x.paymentType === "InterestPayment" ? Number(x.amount).toFixed(2) : Number(Number(TenuredAmount) + Number(x.amount)).toFixed(2)
                        var newCumulativePrincipal = account.principalPaymentFrequency === account.interestPaymentFrequency ? Number(Number(TenuredAmount) + Number(cumulativePrincipal)).toFixed(2) : x.paymentType === "InterestPayment" ? Number(cumulativePrincipal).toFixed(2) : Number(Number(TenuredAmount) + Number(cumulativePrincipal)).toFixed(2)
                        var newCumulativeTotal = Number(Number(cumulativeTotal) + Number(total)).toFixed(2)
                        var newOutstandingPrincipal = account.principalPaymentFrequency === account.interestPaymentFrequency ? Number(Number(outstandingPricipal) - Number(TenuredAmount)).toFixed(2) : x.paymentType === "InterestPayment" ? Number(outstandingPricipal).toFixed(2) : Number(Number(outstandingPricipal) - Number(TenuredAmount)).toFixed(2)

                        // if (x.paymentType === "PrincipalPayment" || account.principalPaymentFrequency === account.interestPaymentFrequency) {
                        if (x.paymentType === "PrincipalPayment" || x.paymentType === "PrinTerestPayment") {
                            if (account.principalPaymentFrequency !== "Weekly" && account.principalPaymentFrequency !== "Daily") {
                                var dueDate = new Date(x.paymentDueDate)
                                x.paymentDueDate = new Date(dueDate.getFullYear(), dueDate.getMonth(), fields.dueDate, dueDate.getHours(), dueDate.getMinutes(), dueDate.getSeconds(), dueDate.getMilliseconds());
                            }
                            else if (account.principalPaymentFrequency === "Weekly" && !_.isEmpty(fields.repaymentDay)) {
                                var dueDate = new Date(x.paymentDueDate)
                                const currentDay = dueDate.getDay();
                                const diff = (targetDay - currentDay + 7) % 7; // Calculate the difference to the target day
                                x.paymentDueDate = dueDate.setDate(dueDate.getDate() + diff);

                            }
                        }
                        if (x.paymentType === "InterestPayment") {
                            if (account.interestPaymentFrequency !== "Weekly" && account.interestPaymentFrequency !== "Daily") {
                                var dueDate = new Date(x.paymentDueDate)
                                x.paymentDueDate = new Date(dueDate.getFullYear(), dueDate.getMonth(), fields.dueDate, dueDate.getHours(), dueDate.getMinutes(), dueDate.getSeconds(), dueDate.getMilliseconds());
                            }
                            else if (account.principalPaymentFrequency === "Weekly" && !_.isEmpty(fields.repaymentDay)) {
                                var dueDate = new Date(x.paymentDueDate)
                                const currentDay = dueDate.getDay();
                                const diff = (targetDay - currentDay + 7) % 7; // Calculate the difference to the target day
                                x.paymentDueDate = dueDate.setDate(dueDate.getDate() + diff);

                            }

                        }
                        // }
                        // x.amount = Number(x.amount) + Number(TenuredAmount)
                        x.principal = account.principalPaymentFrequency === account.interestPaymentFrequency ? Number(TenuredAmount).toFixed(2) : x.paymentType === "InterestPayment" ? 0 : Number(TenuredAmount).toFixed(2)
                        x.interest = account.principalPaymentFrequency === account.interestPaymentFrequency ? Number(InterestAmount).toFixed(2) : x.paymentType === "InterestPayment" ? Number(x.amount).toFixed(2) : x.paymentType === "PrinTerestPayment" ? Number(x.amount).toFixed(2) : 0
                        x.cumulativePrincipal = newCumulativePrincipal
                        x.cumulativeTotal = newCumulativeTotal
                        x.total = total
                        x.outstandingPricipal = newOutstandingPrincipal
                        cumulativePrincipal = Number(newCumulativePrincipal)
                        cumulativeTotal = Number(newCumulativeTotal)
                        outstandingPricipal = newOutstandingPrincipal
                    }

                });
            } else {
                return false;
            }
            prinInterestRepayment = prinInterestRepayment.filter(x => x.isApplied === false)

            var newRepayment = prinInterestRepayment.map(x => {
                return { RepaymentDate: x.paymentDueDate, RepaymentAmountInNaira: x.total }

            })

            var firstParse = JSON.parse(JSON.parse(loanRequest.jsonData));
            firstParse.CollectionPaymentSchedules = newRepayment
            var JSONData = JSON.stringify(firstParse);
            loanRequest.jsonData = JSONData;

            const resp = await loanRequestService.updateLoanRequest(loanRequest);
            this.setState({ submitting: false, ownUpdate: true, repaymentDetails: prinInterestRepayment });
            this.resetFields();


        } catch (error) {
            if (error.response.status === 409) {
                toast.error(error.response.data.detail, {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            } else if (error.response.status === 400) {
                toast.error(error.response.data.errors[0], {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            }
            this.setState({ submitting: false, ownUpdate: true });
            return false;
        } finally {
            return true;
        }
    }

    static getDerivedStateFromProps(
        nextProps: IRepaymentScheduleProps,
        prevState: IRepaymentScheduleState
    ) {
        if (prevState.ownUpdate) {
            return {
                ownUpdate: false,
            };
        }
        if (_.isNull(nextProps.item)) {
            return null;
        }
        return {
            item: nextProps.item
        };
    }

    resetFields() {
        this.setState({
            validated: false,
            submitting: false
        });
    }


    render() {
        const { item, showPatchModal, togglePatchModal, values } = this.props;
        const { submitting, repaymentDetails } = this.state;

        if (_.isEmpty(item)) {
            return null;
        }

        var repaymentDetail = JSON.parse(JSON.parse(item.jsonData)).CollectionPaymentSchedules;

        // var collectionSchedule = item.CollectionPaymentSchedules.reduce((acc, obj) => {
        //     var existingDate = acc.find(item => item.RepaymentDate.substring(6, 15) === obj.RepaymentDate.substring(6, 15));
        //     if (existingDate) {
        //         existingDate.RepaymentAmountInNaira = existingDate.RepaymentAmountInNaira + obj.RepaymentAmountInNaira;
        //         return acc;
        //     }
        //     acc.push(obj);
        //     return acc;
        // }, []);

        return (
            <Modal
                size="xl"
                backdrop="static"
                show={showPatchModal}
                onHide={togglePatchModal}
            >
                <Modal.Header>
                    <Modal.Title>
                        <h5>
                            <i className="fas fa-edit fa-sm cyan-text mr-3" />
                            Collection Repayment Schedule
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <RepaymentScheduleForm
                    initialValues={{}}
                    FormComponent={({
                        fields: { },
                        onChange,
                        onReloadFields,
                        onHandleSubmit,
                    }) => (
                        <React.Fragment>
                            <Modal.Body>
                                <div className="card">
                                    <div className="card-header clear-fix">
                                        {_.isEmpty(item) ? (
                                            <Skeleton width={200} />
                                        ) : (
                                            <h6
                                                className="card-title float-left"
                                                style={{ marginBottom: "0px" }}
                                            >
                                                <b>Repayment Schedule</b>
                                            </h6>
                                        )}
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                {_.isEmpty(item) ? (
                                                    <Skeleton count={1} width={300} />
                                                ) : (
                                                    <div
                                                        className="table-responsize text-nowrap"
                                                        style={{ height: "350px", overflowY: "scroll" }}
                                                    >
                                                        <table className="table table-hover table-content table-sm table-striped mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th
                                                                        scope="col"
                                                                        style={{
                                                                            width: "5px",
                                                                            paddingTop: "0.2rem",
                                                                            paddingBottom: "0.2rem",
                                                                        }}
                                                                    >
                                                                        <strong>#</strong>
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        style={{
                                                                            paddingTop: "0.2rem",
                                                                            paddingBottom: "0.2rem",
                                                                        }}
                                                                    >
                                                                        <strong>Due Date</strong>
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        style={{
                                                                            paddingTop: "0.2rem",
                                                                            paddingBottom: "0.2rem",
                                                                        }}
                                                                    >
                                                                        <strong>Principal</strong>
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        style={{
                                                                            paddingTop: "0.2rem",
                                                                            paddingBottom: "0.2rem",
                                                                        }}
                                                                    >
                                                                        <strong>Interest</strong>
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        style={{
                                                                            paddingTop: "0.2rem",
                                                                            paddingBottom: "0.2rem",
                                                                        }}
                                                                    >
                                                                        <strong>Fee</strong>
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        style={{
                                                                            paddingTop: "0.2rem",
                                                                            paddingBottom: "0.2rem",
                                                                        }}
                                                                    >
                                                                        <strong>Total</strong>
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        style={{
                                                                            paddingTop: "0.2rem",
                                                                            paddingBottom: "0.2rem",
                                                                        }}
                                                                    >
                                                                        <strong>Cumulative Principal</strong>
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        style={{
                                                                            paddingTop: "0.2rem",
                                                                            paddingBottom: "0.2rem",
                                                                        }}
                                                                    >
                                                                        <strong>Cumulative Total</strong>
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        style={{
                                                                            paddingTop: "0.2rem",
                                                                            paddingBottom: "0.2rem",
                                                                        }}
                                                                    >
                                                                        <strong>Outstanding Principal</strong>
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        style={{
                                                                            paddingTop: "0.2rem",
                                                                            paddingBottom: "0.2rem",
                                                                        }}
                                                                    ></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {_.isEmpty(item) ||
                                                                    repaymentDetail?.length === 0 ? (
                                                                    <tr>
                                                                        <td
                                                                            className="font-weight-normal text-truncate"
                                                                            colSpan={4}
                                                                            style={{
                                                                                textAlign: "center",
                                                                                color: "#FE825E",
                                                                                fontSize: "small",
                                                                            }}
                                                                        >
                                                                            ...No Collection Payment Schedule available
                                                                        </td>
                                                                    </tr>
                                                                ) : (
                                                                    repaymentDetail?.map((schedule, id) => (
                                                                        <tr key={id}>
                                                                            <td
                                                                                style={{
                                                                                    width: "5px",
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                }}
                                                                            >
                                                                                {id + 1}
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                }}
                                                                            >
                                                                                {textFormatService.formatShortTimeString((schedule.paymentDueDate))}
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                }}
                                                                            >
                                                                                {textFormatService.formatCurrency(Number(schedule.principal), 2)}
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                }}
                                                                            >
                                                                                {textFormatService.formatCurrency(Number(schedule.interest), 2)}
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                }}
                                                                            >
                                                                                0.00
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                }}
                                                                            >
                                                                                {textFormatService.formatCurrency(Number(schedule.total), 2)}
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                }}
                                                                            >
                                                                                {textFormatService.formatCurrency(Number(schedule.cumulativePrincipal), 2)}
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                }}
                                                                            >
                                                                                {textFormatService.formatCurrency(Number(schedule.cumulativeTotal), 2)}
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                }}
                                                                            >
                                                                                {textFormatService.formatCurrency(Number(schedule.outstandingPricipal), 2)}
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    size="sm"
                                    variant="outline-danger"
                                    onClick={() => togglePatchModal(false, {}, {})}
                                // disabled={submitting}
                                >
                                    <i className="fas fa-times mr-3" />
                                    Close
                                </Button>
                            </Modal.Footer>
                        </React.Fragment>
                    )}
                ></RepaymentScheduleForm>
            </Modal>
        );
    }
}

export default Repayment;
