import React from "react";
import "./navigation.css";
import classNames from "classnames";
import _ from "lodash";

type Options =
  | "Income Statement"
  | "COGS"
  | "Balance Sheet"
  | "Cash Flow"
  | "Financial Statement"
  | "Collateral Valuation"
  | "Financial Ratio"
  | "FAM Summary";
export default function FAMNavigation({
  index,
  setIndex,
  small = false,
  customOptions,
}: {
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  small?: boolean;
  customOptions?: Options[];
}) {
  const defaultOptions: Options[] = [
    "Income Statement",
    "COGS",
    "Balance Sheet",
    "Cash Flow",
    "Collateral Valuation",
    "Financial Ratio",
    "FAM Summary",
  ];
  const options: Options[] = !_.isEmpty(customOptions)
    ? customOptions
    : defaultOptions;

  return (
    <div
      className={classNames(
        !small ? "navigation-body" : "navigation-small-body"
      )}
    >
      {options.map((opt, idx) => (
        <div
          key={opt}
          onClick={() => setIndex(idx)}
          className={classNames(
            !small ? "navigation" : "navigation-small",
            index === idx && "nav-active"
          )}
        >
          {opt}
        </div>
      ))}
    </div>
  );
}
