import { axiosSweepInstance as axiosInstance } from "../configs";

class Mandate{

    getMandateInfo(institutionCode:string, loanReference:string){
        return axiosInstance.get(`/Mandates/GetMandateInfo`, {
            params:{ 
                institutionCode,
                loanReference,
            }
        });
    } 
    getSecondaryMandateInfo(institutionCode:string, loanReference:string, bvn:String){
        return axiosInstance.get(`/Mandates/GetSecondaryMandateInfo`, {
            params:{ 
                institutionCode,
                loanReference,
                bvn,
            }
        });
    }
    retrieveByInstitutionCode(institutionCode:string){
        return axiosInstance.get(`/Mandates/RetrieveByInstitutionCode/${institutionCode}`);
    }  
}

const mandateService = new Mandate();

export default mandateService;