import React from "react";
import { toast } from "react-toastify";
import BaseFormComponent, { IBaseFormState } from "../../components/BaseFormComponent";
import { getCurrentUserSession } from "../../services/auth/authService";
import textFormatService from "../../services/utility/textFormatService";
import swal from "sweetalert";
import _ from "lodash";
import mandateService from "../../services/mandate/mandateService";
import sweepRunService from "../../services/sweep/sweepRunService";
import { decodeToken } from "react-jwt";
import institutionsService from "../../services/auth/institutionsService";
import { ISelectItems } from "../../components/inputComponents/selectBox";
import SelectInputComponent from "../../components/formSelectComponent";
import { retrieveAllInstitutions } from "../../services/creditAssessment/institutionManagementService";

interface InitiateSweepFields {}

class InitiateSweepForm extends BaseFormComponent<InitiateSweepFields> {}

interface IInitiateSweepProps {}
interface IInitiateSweepState {
    sweepInitiationDoc: any;
    sweepInitiationDocKey: any;
    sweepInitiationDocErrors: any;
    validatingSweepInitiationDoc: boolean;
    sweepInitiationDocValidations: any;
    submitting: boolean;
    fetchingInstitutions: boolean;
    templateUri: string;
    allInstitutions: any;
    institution: any;
}

class InitiateSweep extends React.Component<IInitiateSweepProps, IBaseFormState & IInitiateSweepState> {
    constructor(props: IInitiateSweepProps) {
        super(props);
        this.state = {
            errors: {},
            validated: false,
            sweepInitiationDoc: {},
            sweepInitiationDocKey: Date.now(),
            sweepInitiationDocErrors: [],
            validatingSweepInitiationDoc: false,
            sweepInitiationDocValidations: {},
            submitting: false,
            allInstitutions: [],
            institution: {},
            fetchingInstitutions: false,
            templateUri: process.env.REACT_APP_MANDATE_BASEADDRESS + `Mandate/DownloadBulkSweepInitiationTempate`,
        };
    }

    handleOnSweepInitiationDocumentChange = async (e) => {
        // console.log("sweep initiation docs: ", e.target.files[0]);
        e.preventDefault();
        if (!e.target.files[0]) {
            return;
        }
        if (!["xlsx"].includes(e.target.files[0].name.split(".").slice(-1)[0])) {
            toast.error("Please confirm that the document is a valid Excel Document");
        } else {
            this.setState({
                sweepInitiationDoc: e.target.files[0],
                validatingSweepInitiationDoc: true,
            });
            const payload: any = {};
            payload.requests = await textFormatService.getBase64StringFromFile(e.target.files[0]);
            // console.log("payload", payload);
            try {
                let response = await mandateService.validateSweepInitiationRecords(payload);
                // console.log("response: ", response);
                if (response !== undefined) {
                    this.setState({
                        sweepInitiationDocValidations: response.data.item1,
                        sweepInitiationDocErrors: response.data.item2,
                        validatingSweepInitiationDoc: false,
                    });
                }
            } catch (error) {
                // console.log("failed");
                this.setState({ validatingSweepInitiationDoc: false });
            }
            // console.log("final check: ", this.state.sweepInitiationDoc);
        }
    };

    onFormSubmit(fields: InitiateSweepFields, onReloadFieldsCB: any): boolean {
        try {
            this.setState({ errors: {} }, () => {
                if (Object.keys(this.state.errors).length === 0) {
                    return this.submit(fields, onReloadFieldsCB);
                }
            });
            this.setState({ validated: true });
            return false;
        } catch (error) {
            return false;
        }
    }

    async submit(fields: InitiateSweepFields, onReloadFieldsCB: any): Promise<boolean> {
        const decodedToken = decodeToken(getCurrentUserSession().token || "");
        swal({
            title: "Confirm!",
            text: `Are you sure you want to proceed to upload these Sweep Initiation Requests?`,
            icon: "warning",
            buttons: {
                confirm: {
                    text: "Yes",
                    value: null,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                cancel: {
                    text: "Cancel",
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
            },
            closeOnClickOutside: false,
            dangerMode: false,
        }).then(async (resp) => {
            if (resp) {
                // console.log("cancelling action...");
            } else {
                try {
                    this.setState({ submitting: true });
                    const { sweepInitiationDoc } = this.state;
                    const payload: any = {};
                    payload.requestRecords = await textFormatService.getBase64StringFromFile(sweepInitiationDoc);
                    let code = decodedToken?.["InstitutionCode"];
                    console.log("checking code: ", code);
                    if (decodedToken?.["InstitutionCode"] === "000") {
                        console.log("checking the institution: ", this.state.institution);
                        code = this.state.institution.code;
                    }
                    var response = await sweepRunService.processReactiveSweep2(payload, code);

                    // console.log("response: ", response);
                    toast.info("Sweep requests have been initiated successfully.", {
                        autoClose: 6000,
                        type: toast.TYPE.INFO,
                        hideProgressBar: false,
                    });
                    this.resetAllFields();
                    onReloadFieldsCB();
                    return true;
                } catch (error) {
                    // console.log(error);
                    this.setState({ submitting: false });
                    return false;
                }
                // this.props.handleOnEnableOrDisable(this.props.item);
                //this.submit({ actionType: actionType, entityName: entityName });
            }
        });
        return false;
    }

    handleOnSelectInstitution = (id, value) => {
        try {
            const institution = this.state.allInstitutions.filter((x) => x.code === value)[0];
            this.setState({ institution });
        } catch (error) {
            toast.info("Something went wrong.", {
                autoClose: 6000,
                type: toast.TYPE.ERROR,
                hideProgressBar: false,
            });
            toast.info(error.response.data.errors[0], {
                autoClose: 6000,
                type: toast.TYPE.ERROR,
                hideProgressBar: false,
            });
        }
    };

    async componentDidMount() {
        console.log("testing: ", decodeToken(getCurrentUserSession().token || "")?.["InstitutionId"]);
        let allInsts = institutionsService
            .retrieveAllInstitutions2()
            .then((response) => {
                // var theData = response.data.sort(function (a, b) {
                //     return a.name.localeCompare(b.name);
                // });
                const decodedToken = decodeToken(getCurrentUserSession().token || "");
                this.setState({
                    allInstitutions: response.data,
                });
            })
            .catch((err) => {
                console.log("error retrieving institutions: ", err);
            });
    }

    resetAllFields() {
        // console.log("about to reset all fields...");
        this.setState({
            sweepInitiationDoc: {},
            sweepInitiationDocKey: Date.now(),
            sweepInitiationDocErrors: [],
            validatingSweepInitiationDoc: false,
            sweepInitiationDocValidations: {},
            submitting: false,
        });
    }

    render() {
        const decodedToken = decodeToken(getCurrentUserSession().token || "");
        const institutionId = decodedToken?.["institutionId"];

        const {
            sweepInitiationDoc,
            sweepInitiationDocKey,
            sweepInitiationDocErrors,
            validatingSweepInitiationDoc,
            sweepInitiationDocValidations,
            submitting,
            allInstitutions,
            institution,
            fetchingInstitutions,
        } = this.state;
        let institutionsInputData: ISelectItems[] = allInstitutions.map((item) => ({
            name: item.name,
            value: item.code,
        }));
        institutionsInputData.sort((a, b) => a.name.localeCompare(b.name));
        return (
            <React.Fragment>
                <div className="container-fluid relative animatedParent animateOnce">
                    <div className="animated fadeInUpShort go">
                        <div className="row my-3 mx-2">
                            <h3>
                                <b>Sweep Initiation</b>
                            </h3>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <InitiateSweepForm
                                    initialValues={{}}
                                    FormComponent={({ fields: {}, onChange, onReloadFields }) => (
                                        <form action="#">
                                            <div className="card no-b">
                                                <div className="card-body">
                                                    <h5 className="card-title">Document Upload Guide</h5>
                                                    <div className="form-row">
                                                        <div className="col-md-12">
                                                            <div className="form-row">
                                                                <div className="col-md-6">
                                                                    <h6>Rules</h6>
                                                                    <ul>
                                                                        {/* <li className="brown-text small">
                      All Loan References <b>MUST</b> be distinct for every
                      record on the list.
                    </li> */}
                                                                        <li className="brown-text small">
                                                                            Valid document format - <b style={{ color: "red" }}>xlsx</b>.
                                                                        </li>
                                                                        <li className="brown-text small">
                                                                            Maximum number of rows for the sweep initiation document -
                                                                            <b style={{ color: "red" }}>2000</b>.
                                                                        </li>
                                                                        <li className="brown-text small">
                                                                            Click{" "}
                                                                            <b>
                                                                                <a
                                                                                    href={this.state.templateUri}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                >
                                                                                    Here
                                                                                </a>
                                                                            </b>{" "}
                                                                            to download a sample template for bulk sweep initiation.
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-md-6 col-sm-6">
                                                                    <h6>Record Template</h6>
                                                                    <ul>
                                                                        <li className="brown-text small">Loan Account No. or Loan Reference</li>
                                                                        <li className="brown-text small">Total Outstanding Amount</li>
                                                                        {/* <li className="brown-text small">
                                      Total Outstanding Amount
                                    </li> */}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />

                                                <div className="card-header card-footer" style={{ padding: "8px" }}>
                                                    <i className="fas fa-upload mr-3" />
                                                    Document Upload
                                                </div>
                                                <div className="card-content" style={{ padding: "8px" }}>
                                                    <div className="row">
                                                        <div className="col-md-4 col-sm-4">
                                                            {/* <h5 className="text-center" style={{ fontWeight: "400" }}>
                  Documents
                </h5> */}
                                                            <div className="form-group">
                                                                <label htmlFor="sweepInitiationDoc" className="textbox-label">
                                                                    SWEEP INITIATION DOC.
                                                                </label>

                                                                <div className="input-group">
                                                                    <div className="custom-file">
                                                                        <input
                                                                            type="file"
                                                                            key={sweepInitiationDocKey}
                                                                            className="custom-file-input"
                                                                            id="sweepInitiationDoc"
                                                                            name="sweepInitiationDoc"
                                                                            aria-describedby="inputGroupFileAddon01"
                                                                            onChange={(e) => this.handleOnSweepInitiationDocumentChange(e)}
                                                                        />
                                                                        <label className="custom-file-label" htmlFor="sweepInitiationDoc">
                                                                            {_.isEmpty(sweepInitiationDoc.name)
                                                                                ? "Choose File"
                                                                                : sweepInitiationDoc.name}
                                                                        </label>
                                                                    </div>
                                                                    {_.isEmpty(sweepInitiationDoc.name) ? (
                                                                        ""
                                                                    ) : (
                                                                        <React.Fragment>
                                                                            {validatingSweepInitiationDoc === true ? (
                                                                                <span style={{ float: "right" }}>
                                                                                    <i className="fas fa-spinner fa-spin m-3"></i>
                                                                                </span>
                                                                            ) : (
                                                                                <span style={{ float: "right" }}>
                                                                                    <i
                                                                                        className="far fa-trash-alt red-text m-3"
                                                                                        style={{ cursor: "pointer" }}
                                                                                        onClick={() => this.resetAllFields()}
                                                                                    ></i>
                                                                                </span>
                                                                            )}
                                                                        </React.Fragment>
                                                                    )}
                                                                </div>
                                                                <br />
                                                                {decodeToken(getCurrentUserSession().token || "")?.["InstitutionId"] === "1" && (
                                                                    <SelectInputComponent
                                                                        id="institution"
                                                                        name="institution"
                                                                        divClass={12}
                                                                        value={institution.code}
                                                                        items={institutionsInputData}
                                                                        required={false}
                                                                        validated={false}
                                                                        errors={[]}
                                                                        labelIconClass={
                                                                            fetchingInstitutions === true
                                                                                ? "fas fa-spinner fa-spin orange-text mr-2"
                                                                                : ""
                                                                        }
                                                                        onChange={this.handleOnSelectInstitution}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 col-sm-8">
                                                            <h5 className="text-center" style={{ fontWeight: 400 }}>
                                                                <i className="fas fa-tasks mr-2"></i>Validations
                                                            </h5>
                                                            <hr />
                                                            <div className="row">
                                                                <div className="col-md-4 col-sm-4">
                                                                    <ul style={{ listStyle: "none" }}>
                                                                        <li className="brown-text small">
                                                                            {_.isEmpty(
                                                                                JSON.stringify(
                                                                                    sweepInitiationDocValidations.maximumSweepInitiationRequests
                                                                                )
                                                                            ) ? (
                                                                                <i className="far fa-circle mr-2"></i>
                                                                            ) : JSON.stringify(
                                                                                  sweepInitiationDocValidations.maximumSweepInitiationRequests
                                                                              ) === "true" ? (
                                                                                <i className="fas fa-check-circle green-text mr-2"></i>
                                                                            ) : (
                                                                                <i className="fas fa-times-circle red-text mr-2"></i>
                                                                            )}
                                                                            Rows are not more than 2000
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-md-4 col-sm-4">
                                                                    <ul style={{ listStyle: "none" }}>
                                                                        <li className="brown-text small">
                                                                            {_.isEmpty(
                                                                                JSON.stringify(
                                                                                    sweepInitiationDocValidations.allLoanReferencesHaveMandates
                                                                                )
                                                                            ) ? (
                                                                                <i className="far fa-circle mr-2"></i>
                                                                            ) : JSON.stringify(
                                                                                  sweepInitiationDocValidations.allLoanReferencesHaveMandates
                                                                              ) === "true" ? (
                                                                                <i className="fas fa-check-circle green-text mr-2"></i>
                                                                            ) : (
                                                                                <i className="fas fa-times-circle red-text mr-2"></i>
                                                                            )}
                                                                            All Loan References are Valid.
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-md-4 col-sm-4">
                                                                    <ul style={{ listStyle: "none" }}>
                                                                        <li className="brown-text small">
                                                                            {_.isEmpty(
                                                                                JSON.stringify(sweepInitiationDocValidations.allAmountsAreValid)
                                                                            ) ? (
                                                                                <i className="far fa-circle mr-2"></i>
                                                                            ) : JSON.stringify(sweepInitiationDocValidations.allAmountsAreValid) ===
                                                                              "true" ? (
                                                                                <i className="fas fa-check-circle green-text mr-2"></i>
                                                                            ) : (
                                                                                <i className="fas fa-times-circle red-text mr-2"></i>
                                                                            )}
                                                                            All Amounts are Valid.
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                {/* <div className="col-md-4 col-sm-4">
                                  <ul style={{ listStyle: "none" }}>
                                    <li className="brown-text small">
                                      {_.isEmpty(
                                        JSON.stringify(
                                          sweepInitiationDocValidations.allRecordsHaveValiOutstandingdAmounts
                                        )
                                      ) ? (
                                        <i className="far fa-circle mr-2"></i>
                                      ) : JSON.stringify(
                                          sweepInitiationDocValidations.allRecordsHaveValiOutstandingdAmounts
                                        ) === "true" ? (
                                        <i className="fas fa-check-circle green-text mr-2"></i>
                                      ) : (
                                        <i className="fas fa-times-circle red-text mr-2"></i>
                                      )}
                                      All Total Amounts Outstanding are Valid.
                                    </li>
                                  </ul>
                                </div> */}
                                                            </div>
                                                            {sweepInitiationDocErrors.length === 0 ? (
                                                                ""
                                                            ) : (
                                                                <div className="row">
                                                                    <h6 style={{ fontWeight: 400 }}>Validation Details</h6>
                                                                    <div
                                                                        className="table-responsive text-nowrap"
                                                                        style={{
                                                                            height: "150px",
                                                                            overflowY: "scroll",
                                                                        }}
                                                                    >
                                                                        <table className="table table-hover table-content table-sm table-striped mb-0">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th
                                                                                        scope="col"
                                                                                        style={{
                                                                                            width: "5px",
                                                                                            paddingTop: "0.2rem",
                                                                                            paddingBottom: "0.2rem",
                                                                                        }}
                                                                                    >
                                                                                        #
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        style={{
                                                                                            paddingTop: "0.2rem",
                                                                                            paddingBottom: "0.2rem",
                                                                                        }}
                                                                                    >
                                                                                        Description
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {sweepInitiationDocErrors.map((error) => (
                                                                                    <tr key={textFormatService.generateGUID()}>
                                                                                        <td
                                                                                            style={{
                                                                                                width: "5px",
                                                                                                paddingTop: "0.2rem",
                                                                                                paddingBottom: "0.2rem",
                                                                                                fontSize: "small",
                                                                                            }}
                                                                                        >
                                                                                            {sweepInitiationDocErrors.indexOf(error) + 1}
                                                                                        </td>
                                                                                        <td
                                                                                            style={{
                                                                                                paddingTop: "0.2rem",
                                                                                                paddingBottom: "0.2rem",
                                                                                                fontSize: "small",
                                                                                            }}
                                                                                        >
                                                                                            {error}
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {Object.values(sweepInitiationDocValidations).filter((x) => x === false).length === 0 &&
                                                !_.isEmpty(sweepInitiationDocValidations) ? (
                                                    <div className="card-body">
                                                        <button
                                                            type="submit"
                                                            disabled={submitting}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.onFormSubmit({}, onReloadFields);
                                                            }}
                                                            className="btn btn-sm btn-primary"
                                                        >
                                                            {submitting === false ? (
                                                                <React.Fragment>
                                                                    <i className="fas fa-lg fa-save mr-3" /> Initiate Sweep
                                                                </React.Fragment>
                                                            ) : (
                                                                <React.Fragment>
                                                                    <i className="fas fa-spin fa-circle-notch mr-3" /> Please wait...
                                                                </React.Fragment>
                                                            )}
                                                        </button>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </form>
                                    )}
                                ></InitiateSweepForm>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default InitiateSweep;
