import Joi from "joi-browser";
import _ from "lodash";
import { TransactionAmountSettings } from "../../enums/recovaMandate/transactionAmountSettings";
import { PermissibleDeductionConfig } from "../../enums/recovaMandate/permissibleDeductionConfig";
import { CummulativeDeductionConfig } from "../../enums/recovaMandate/cummulativeDeductionConfig";
import { MandateDurationConfig } from "../../enums/recovaMandate/mandateDuration";

export function validateEditMandateServiceTypeForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      name: Joi.string()
        .required()
        .error((errors) => {
          // console.log("name joi-error", errors);
          return {
            message: "Name is required",
          };
        }),
      description: Joi.string()
        .required()
        .error((errors) => {
          // console.log("description joi-error", errors);
          return {
            message: "Description is required",
          };
        }),
      transactionAmount: Joi.number().when("transactionAmountSettings", {
        is: TransactionAmountSettings.Fixed,
        then: Joi.any().valid(0),
        otherwise: Joi.number()
          .min(1)
          .required()
          .error((errors) => {
            // console.log("transactionAmount joi-error", errors)
            return {
              message: "Invalid Transaction Amount.",
            };
          }),
      }),
      permissibleDeductionCount: Joi.number().when(
        "permissibleDeductionConfig",
        {
          is: PermissibleDeductionConfig.Limited,
          then: Joi.any().valid(0),
          otherwise: Joi.number()
            .min(1)
            .required()
            .error((errors) => {
              // console.log("permissibleDeductionCount joi-error", errors);
              return {
                message: "Invalid Permissible Deduction Count",
              };
            }),
        }
      ),
      cumulativeDeductionCount: Joi.number().when(
        "cummulativeDeductionConfig",
        {
          is: CummulativeDeductionConfig.Fixed,
          then: Joi.any().valid(0),
          otherwise: Joi.number()
            .min(1)
            .required()
            .error((errors) => {
              // console.log("cumulativeDeductionCount joi-error", errors);
              return {
                message: "Invalid Cumulative Deduction Count",
              };
            }),
        }
      ),
      mandateDuration: Joi.number().when("mandateDurationConfig", {
        is: MandateDurationConfig.Limited,
        then: Joi.any().valid(0),
        otherwise: Joi.number()
          .min(1)
          .required()
          .error((errors) => {
            // console.log("mandateDuration joi-error", errors);
            return {
              message: "Invalid Mandate Duration",
            };
          }),
      }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddMandateServiceTypeForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      name: Joi.string()
        .required()
        .error((errors) => {
          // console.log("name joi-error", errors);
          return {
            message: "Name is required",
          };
        }),
      description: Joi.string()
        .required()
        .error((errors) => {
          // console.log("description joi-error", errors);
          return {
            message: "Description is required",
          };
        }),
      transactionAmount: Joi.number().when("transactionAmountSettings", {
        is: TransactionAmountSettings.Fixed,
        then: Joi.any().valid(0),
        otherwise: Joi.number()
          .min(1)
          .required()
          .error((errors) => {
            // console.log("transactionAmount joi-error", errors)
            return {
              message: "Invalid Transaction Amount.",
            };
          }),
      }),
      permissibleDeductionCount: Joi.number().when(
        "permissibleDeductionConfig",
        {
          is: PermissibleDeductionConfig.Unlimited,
          then: Joi.any().valid(0),
          otherwise: Joi.number()
            .min(1)
            .required()
            .error((errors) => {
              // console.log("permissibleDeductionCount joi-error", errors);
              return {
                message: "Invalid Permissible Deduction Count",
              };
            }),
        }
      ),
      cumulativeDeductionCount: Joi.number().when(
        "cummulativeDeductionConfig",
        {
          is: CummulativeDeductionConfig.Fixed,
          then: Joi.any().valid(0),
          otherwise: Joi.number()
            .min(1)
            .required()
            .error((errors) => {
              // console.log("cumulativeDeductionCount joi-error", errors);
              return {
                message: "Invalid Cumulative Deduction Count",
              };
            }),
        }
      ),
      mandateDuration: Joi.number().when("mandateDurationConfig", {
        is: MandateDurationConfig.Unlimited,
        then: Joi.any().valid(0),
        otherwise: Joi.number()
          .min(1)
          .required()
          .error((errors) => {
            // console.log("mandateDuration joi-error", errors);
            return {
              message: "Invalid Mandate Duration",
            };
          }),
      }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddBvnGreyListForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      bvn: Joi.string()
        .required()
        .error((errors) => {
          // console.log("bvn joi-error", errors);
          return {
            message: "bvn is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditCollectionConsentConfigForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      repaymentBank: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Repayment Bank is required.",
          };
        }
      ),
      bankCode: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Bank Code is required.",
          };
        }
      ),  
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddCollectionConsentConfigForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      repaymentBank: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Repayment Bank is required.",
          };
        }
      ),
      bankCode: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Bank Code is required.",
          };
        }
      ),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export default {
  validateEditMandateServiceTypeForm,
  validateAddMandateServiceTypeForm,
  validateAddBvnGreyListForm,
  validateAddCollectionConsentConfigForm,
  validateEditCollectionConsentConfigForm,
};
