import React, { Component, Fragment, useContext } from "react";
import {
  Button,
  Card,
  useAccordionToggle,
  AccordionContext,
  Accordion,
} from "react-bootstrap";
import _ from "lodash";
import DetailItem from "../../../../components/detailItem";
import FormInputComponent from "../../../../components/formInputComponent";
import { toast } from "react-toastify";
import BaseFormComponent from "../../../../components/BaseFormComponent";
import loanRequestService from "../../../../services/hybridAppraisal/loanRequestService";
import BaseListComponent, { IFilterData } from "../../../../components/BaseListComponent";
import { createFilterData, mapEntityList } from "../../../../services/utility/autoMapperService";
import { filterFieldData } from "../../../../components/tableComponents/itemsTable";
import appraisalActionService from "../../../../services/hybridAppraisal/appraisalActionService";

export function ContextAwareToggle({ eventKey, header }) {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      <b>{header}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
}

interface BankAnalysisCheckFields {
  accountNumber: string;
  statementType: string;
  bankName: string;
}

class BankAnalysisCheckForm extends BaseFormComponent<BankAnalysisCheckFields> { }

interface BankAnalysisCheckProps {
  item: any;
}

interface BankAnalysisCheckState {
  item: any;
  updatingStatus: boolean;
  submitting: boolean;
  data: IFilterData;
  currentPage: number;
  tableItems: any;
  fetching: boolean;
  fetchingDetail: boolean;
  totalSize: number;
  filterValueInput: filterFieldData;
}

class BankAnalysisList extends BaseListComponent<BankAnalysisCheckState> { }

class BankAnalysisCheck extends Component<BankAnalysisCheckProps, BankAnalysisCheckState> {
  constructor(props: BankAnalysisCheckProps) {
    super(props);
    this.state = {
      item: {},
      updatingStatus: false,
      submitting: false,
      data: createFilterData([]) as IFilterData,
      currentPage: 1,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: []
    };
  }

  getActionTypeString = (actionType) => {
    switch (actionType) {
      case 2:
        return "Update";
      case 6:
        return "Approve";
      case 7:
        return "Decline";
      case 8:
        return "PushBack";
      case 9:
        return "Recall";
      default:
        return "";
    }
  };

  onFormSubmit(
    actionType,
  ): boolean {
    try {
      var action = this.getActionTypeString(actionType)
      this.setState(
        {
          // errors: appraisalValidationService.validateAppraisalProcessDetailForm(fields),
        },
        () => {
          return this.submit(action);
        }
      );
      this.setState({});
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    action
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true });
      const payload: any = {};
      var curDT = new Date().toLocaleString()
      payload.action = action;
      payload.loanRequestId = this.props.item.id
      payload.systemProcessID = Number(3);
      payload.itemLevel = this.props.item.currentLevel;
      payload.errorMessage = "";
      payload.logType = "SystemType";
      payload.comment = "";
      payload.effectiveStatus = "Valid";
      payload.id = Number(0);
      payload.processResponse = {
        "actionId": 0,
        "score": 0,
        "result": "string",
        "response": "string",
        "id": 0,
        "dateCreated": curDT,
        "dateUpdated": curDT
      };
      payload.dateCreated = curDT;
      payload.dateUpdated = curDT;

      // console.log("payload: ", payload);
      const response = await appraisalActionService.RunSystemProcess(payload);
      if (response.status >= 200 && response.status <= 300) {
        toast.info(`Validation Initiated successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false });
    }
    catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
    } finally {
      this.setState({ submitting: false });
      return true;
    }
  }

  handlePageChange = async (currentPage: number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    this.setState({ fetching: true });
    let dataClone = typeof data === "undefined" ? { ...this.state.data } : { ...data };
    try {
      const loanRequests = await loanRequestService.filterLoanRequests(
        {filters: dataClone, accessibleBranchCodes: null},
        Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
        currentPage
      );
      this.setState({
        tableItems: mapEntityList({
          entityList: loanRequests.data.item2,
          properties: [
            { oldName: "customerName", newName: "Name" },
            { oldName: "phonenumber", newName: "phoneNumber" },
            { oldName: "gender", newName: "gender" },
            { oldName: "gender", newName: "gender" },
          ],
        }),
        currentPage,
        totalSize: loanRequests.data.item1,
        fetching: false,
      });
    } catch (ex) {
      if (ex.response && ex.response.status >= 400 && ex.response.status <= 499) {
        this.setState({ fetching: false });
      } else {
        this.setState({ fetching: false });
      }
    } finally {
      this.setState({ fetching: false });
    }
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  handleOnExport = async (type: string) => { };

  static getDerivedStateFromProps(nextProps: BankAnalysisCheckProps, prevState) {
    return {
      item: nextProps.item,
    };
  }

  render() {
    const { item } = this.props;

    return (
      <div className="container-fluid relative animatedParent animateOnce">
        <div className="animated fadeInUpShort go">
          <div className="row">
            <div className="col-md-12">
              <div className="form-row">
                <div className="col-md-12">
                  <div className="row my-3 mx-2">
                    <h3>
                      <b>Bank Analysis</b>
                    </h3>
                  </div>
                  <div className="card">
                    <BankAnalysisCheckForm
                      initialValues={{ statementType: "", bankName: "", accountNumber: "" }}
                      FormComponent={({
                        fields: { statementType, bankName, accountNumber },
                        onChange,
                      }) => (
                        <Accordion defaultActiveKey="0">
                          <div>
                            <Card>
                              <ContextAwareToggle
                                eventKey={`0`}
                                header={<React.Fragment>
                                  <i
                                    className="fa fa-info-circle"
                                    aria-hidden="true"
                                    style={{ color: "#878a8a" }}
                                  ></i>{" "}
                                  Generate Bank Statement
                                </React.Fragment>}
                              ></ContextAwareToggle>
                              <Accordion.Collapse eventKey={`0`}>
                                <Card.Body>
                                  <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                    <div className="row">
                                      <div className="col-md-10">
                                        <dl className="row">
                                          <FormInputComponent
                                            id="statementType"
                                            type="text"
                                            name="statementType"
                                            placeholder=""
                                            divClass={6}
                                            value={statementType}
                                            required={false}
                                            validated={true}
                                            errors={{}}
                                            onChange={onChange}
                                            disabled={false}
                                          />
                                          <FormInputComponent
                                            id="bankName"
                                            type="text"
                                            name="bankName"
                                            placeholder=""
                                            divClass={6}
                                            value={bankName}
                                            required={false}
                                            validated={true}
                                            errors={{}}
                                            onChange={onChange}
                                            disabled={false}
                                          />
                                        </dl>
                                        <dl className="row">
                                          <FormInputComponent
                                            id="accountNumber"
                                            type="text"
                                            name="accountNumber"
                                            placeholder=""
                                            divClass={6}
                                            value={accountNumber}
                                            required={false}
                                            validated={true}
                                            errors={{}}
                                            onChange={onChange}
                                            disabled={false}
                                          />
                                          <Button
                                            className="mr-3"
                                            size="sm"
                                            variant="success"
                                            onClick={() => { }}
                                          >
                                            Generate Statement
                                          </Button>
                                          <Button
                                            className="mr-3"
                                            size="sm"
                                            variant="primary"
                                            onClick={() => { }}
                                          >
                                            Add Bank
                                          </Button>
                                        </dl>
                                      </div>
                                    </div>
                                  </div>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          </div>
                        </Accordion>
                      )}
                    ></BankAnalysisCheckForm>
                  </div>

                  {/* Reports */}
                  <div className="card mt-3">
                    <Accordion defaultActiveKey="0">
                      <div>
                        <Card>
                          <ContextAwareToggle
                            eventKey={`0`}
                            header={<React.Fragment>
                              <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                                style={{ color: "#878a8a" }}
                              ></i>{" "}
                              Financial Analysis Result
                            </React.Fragment>}
                          ></ContextAwareToggle>
                          <Accordion.Collapse eventKey={`0`}>
                            <Card.Body>
                              <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className="col-md-5">
                                    <dl className="row">
                                      <DetailItem
                                        label="Financial Status"
                                        labelSize={7}
                                        valueSize={3}
                                        value={item.appraisalActions.filter(x => x.systemProcessID === Number(1))[0]?.comment}
                                      />
                                    </dl>
                                  </div>
                                  <div className="col-md-5">
                                    <dl className="row">
                                      <DetailItem
                                        label="Financial Analysis Score"
                                        labelSize={9}
                                        valueSize={2}
                                        value={item.appraisalActions.filter(x => x.systemProcessID === Number(1))[0]?.processResponse?.score}
                                      />
                                    </dl>
                                  </div>
                                  <div className="col-md-2 float-right">
                                    <Button
                                      size="sm"
                                      variant="primary"
                                      onClick={() => { }}
                                    >
                                      View Report
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </div>
                    </Accordion>
                  </div>

                  <div className="card mt-3">
                    <Fragment>

                      <div
                        className="table-responsive text-nowrap"
                        style={{
                          height: "300px",
                          overflowY: "scroll",
                        }}
                      >
                        <table className="table table-hover table-content table-sm table-striped mb-0">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                style={{
                                  width: "5px",
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                }}
                              >
                                <strong>#</strong>
                              </th>
                              <th
                                scope="col"
                                style={{
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                }}
                              >
                                <strong>Bank</strong>
                              </th>
                              <th
                                scope="col"
                                style={{
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                }}
                              >
                                <strong>Account Number</strong>
                              </th>
                              <th
                                scope="col"
                                style={{
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                }}
                              >
                                <strong>Financial Score</strong>
                              </th>
                              <th
                                scope="col"
                                style={{
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                }}
                              >
                                <strong>Action</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <React.Fragment>
                              <tr>
                                No Bank Statements Generated yet
                              </tr>
                            </React.Fragment>
                            <React.Fragment>
                              <tr>
                                <td
                                  style={{
                                    width: "5px",
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                    fontSize: "small",
                                  }}
                                >
                                </td>
                                <td
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                    fontSize: "small",
                                  }}
                                >
                                  {item.name}
                                </td>
                                <td
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                    fontSize: "small",
                                  }}
                                >
                                  {item.email}
                                </td>
                                <td
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                    fontSize: "small",
                                  }}
                                >
                                  {item.email}
                                </td>
                                <td
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                    fontSize: "small",
                                  }}
                                >
                                  <i
                                    className="far fa-trash-alt ml-5 red-text fa-lg"
                                    style={{
                                      cursor: "pointer",
                                    }}

                                  ></i>
                                </td>
                              </tr>
                            </React.Fragment>
                          </tbody>
                        </table>
                      </div>
                    </Fragment>
                  </div>

                  <div className="form-row">
                    <div className="my-1 mx-2">
                      <button
                        className="btn btn-sm btn-outline-primary my-2"
                        style={{ cursor: "pointer" }}
                        type="button"
                        onClick={() => { }}
                      >
                        <React.Fragment>
                          <i className="far fa-save fa-lg mr-2" />{" "}
                          Save
                        </React.Fragment>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BankAnalysisCheck;
