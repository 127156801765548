import React, { Component } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import branchesService from "../../../../../services/auth/branchesService";

export interface IUpdateBranchApprovalDetailProps {
  item: any;
  title: string;
}

export interface IUpdateBranchApprovalDetailState {
  branch: any;
  item: any;
}

class UpdateBranchApprovalDetail extends Component<
  IUpdateBranchApprovalDetailProps,
  IUpdateBranchApprovalDetailState
> {
  constructor(props: IUpdateBranchApprovalDetailProps) {
    super(props);
    this.state = {
      branch: {},
      item: {},
    };
  }

  static getDerivedStateFromProps(
    nextProps: IUpdateBranchApprovalDetailProps,
    prevState
  ) {
    if (nextProps.item) {
      return { item: JSON.parse(nextProps.item) };
    }
    return null;
  }

  async loadBranches() {
    if (_.isEmpty(this.state.branch)) {
      const branch = await branchesService.retrieveBranchById(
        JSON.parse(this.props.item).Id
      );
      this.setState({ branch: branch.data });
    }
  }

  render() {
    const { item, branch } = this.state;
    if (item) {
      this.loadBranches();
    }
    return (
      <React.Fragment>
        <div className="text-center">
          <h5
            className="font-weight-normal"
            style={{ marginTop: "0.5rem", display: "inline" }}
          >
            {this.props.title} Details
          </h5>
        </div>
        <hr style={{ marginTop: "0rem" }} />
        {_.isEmpty(branch) ? (
          ""
        ) : (
          <div
            className="table-responsive text-nowrap"
            style={{
              height: "250px",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <table className="table table-hover table-content table-sm">
              <tbody>
                <tr>
                  <td
                    className="font-weight-normal"
                    style={{ textAlign: "left" }}
                  >
                    <b>Status: </b>
                  </td>
                  <td
                    className="font-weight-light"
                    colSpan={2}
                    style={{ textAlign: "center" }}
                  >
                    {_.isEmpty(item) ? (
                      <Skeleton width={150} height={10} />
                    ) : (
                      branch.status
                    )}
                  </td>
                </tr>
                <tr>
                  <td
                    className="font-weight-normal"
                    style={{ textAlign: "left" }}
                  >
                    <b>Description: </b>
                  </td>
                  {item.Description === branch.description ? (
                    <td
                      className="font-weight-light"
                      colSpan={2}
                      style={{ textAlign: "center" }}
                    >
                      {_.isEmpty(item) ? (
                        <Skeleton width={150} height={10} />
                      ) : (
                        item.Description
                      )}
                    </td>
                  ) : (
                    <React.Fragment>
                      <td
                        style={{
                          textAlign: "center",
                          backgroundColor: "#FAB39B",
                        }}
                      >
                        {_.isEmpty(item) ? (
                          <Skeleton width={150} height={10} />
                        ) : (
                          branch.description
                        )}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          backgroundColor: "#BAFEB7",
                        }}
                      >
                        {_.isEmpty(item) ? (
                          <Skeleton width={150} height={10} />
                        ) : (
                          item.Description
                        )}
                      </td>
                    </React.Fragment>
                  )}
                </tr>
                <tr>
                  <td
                    className="font-weight-normal"
                    style={{ textAlign: "left" }}
                  >
                    <b>Name: </b>
                  </td>
                  {item.Name === branch.name ? (
                    <td
                      className="font-weight-light"
                      colSpan={2}
                      style={{ textAlign: "center" }}
                    >
                      {_.isEmpty(item) ? (
                        <Skeleton width={150} height={10} />
                      ) : (
                        item.Name
                      )}
                    </td>
                  ) : (
                    <React.Fragment>
                      <td
                        style={{
                          textAlign: "center",
                          backgroundColor: "#FAB39B",
                        }}
                      >
                        {_.isEmpty(item) ? (
                          <Skeleton width={150} height={10} />
                        ) : (
                          branch.name
                        )}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          backgroundColor: "#BAFEB7",
                        }}
                      >
                        {_.isEmpty(item) ? (
                          <Skeleton width={150} height={10} />
                        ) : (
                          item.Name
                        )}
                      </td>
                    </React.Fragment>
                  )}
                </tr>
                <tr>
                  <td
                    className="font-weight-normal"
                    style={{ textAlign: "left" }}
                  >
                    <b>Address: </b>
                  </td>
                  {item.Address === branch.address ? (
                    <td
                      className="font-weight-light"
                      colSpan={2}
                      style={{ textAlign: "center" }}
                    >
                      {_.isEmpty(item) ? (
                        <Skeleton width={150} height={10} />
                      ) : (
                        item.address
                      )}
                    </td>
                  ) : (
                    <React.Fragment>
                      <td
                        style={{
                          textAlign: "center",
                          backgroundColor: "#FAB39B",
                        }}
                      >
                        {_.isEmpty(item) ? (
                          <Skeleton width={150} height={10} />
                        ) : (
                          branch.address
                        )}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          backgroundColor: "#BAFEB7",
                        }}
                      >
                        {_.isEmpty(item) ? (
                          <Skeleton width={150} height={10} />
                        ) : (
                          item.Address
                        )}
                      </td>
                    </React.Fragment>
                  )}
                </tr>
                <tr>
                  <td
                    className="font-weight-normal"
                    style={{ textAlign: "left" }}
                  >
                    <b>Code: </b>
                  </td>
                  <td
                    className="font-weight-light"
                    colSpan={2}
                    style={{ textAlign: "center" }}
                  >
                    {_.isEmpty(item) ? (
                      <Skeleton width={150} height={10} />
                    ) : (
                      item.Code
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default UpdateBranchApprovalDetail;
