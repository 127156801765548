import React from "react";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import branchesService from "../../../services/auth/branchesService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import authValidationService from "../../../services/auth/authValidationService";
import { ActionTypes } from "../../../enums/actionTypes";

interface EditBranchFields {
  name: string;
  description: string;
  address: string;
}

class EditBranchForm extends BaseFormComponent<EditBranchFields> { }

interface IEditBranchProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}
interface IEditBranchState { }

class EditBranch extends React.Component<
  IEditBranchProps,
  IBaseFormState & IEditBranchState
> {
  constructor(props: IEditBranchProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
    };
  }

  onFormSubmit(fields: EditBranchFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        { errors: authValidationService.validateEditBranchForm(fields) },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (fields: EditBranchFields, onReloadFieldsCB: any) => {
    try {
      this.setState({ submitting: true });
      const payload = this.props.item;
      // console.log("For checks::", payload);
      payload.Id = payload.id;
      payload.name = fields.name;
      payload.description = fields.description;
      payload.address = fields.address;
      const response = await branchesService.updateBranch(payload, payload.Id);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit Branch",
          description: `Edited details for Branch  ${fields.name}`,
        });

        if (response.data.isLoggedForApproval === true) {
          toast.warn("Action has been successfully logged for Approval!", {
            autoClose: 6000,
            type: toast.TYPE.WARNING,
            position: "top-center",
            hideProgressBar: false,
          });
        } else {
          toast.info(`Branch ${fields.name} Edited successfully! `, {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          });
        }
      }
      onReloadFieldsCB();
      this.setState({ submitting: false }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      // console.log("submission error: ", error);
      // this.setState({
      //   errors: "Invalid Username or Password",
      // });
      this.setState({ submitting: false });
    }
  };

  render() {
    const { item, showEditModal, toggleEditModal } = this.props;
    const { validated, submitting, errors } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-gopuram fa-sm cyan-text mr-3" />
              Edit Branch -{" "}
              <span className="font-weight-bold">{item.name}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditBranchForm
          initialValues={{
            name: item.name,
            description: item.description,
            address: item.address,
          }}
          FormComponent={({
            fields: { name, description, address },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    <h6
                      className="card-title float-left"
                      style={{ marginBottom: "0px" }}
                    >
                      General Information
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label
                            htmlFor="name"
                            className="col-md-4 col-form-label"
                          >
                            Name:
                          </label>
                          <div className="col-md-8">
                            <input
                              autoFocus
                              type="text"
                              className="form-control form-control-sm"
                              id="name"
                              name="name"
                              value={name}
                              onChange={(e) =>
                                onChange("name", e.currentTarget.value)
                              }
                            />
                            <div
                              className="invalid-feedback"
                              style={{ visibility: errors.name }}
                            >
                              {errors.name}
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="code"
                            className="col-md-4 col-form-label"
                          >
                            Code:
                          </label>
                          <div className="col-md-8 col-form-label blue-grey-text">
                            {item.code}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="status"
                            className="col-md-4 col-form-label"
                          >
                            Status:
                          </label>
                          <div className="col-md-8 col-form-label blue-grey-text">
                            {(item ? item.status : "Active") === "Active" ? (
                              <span className="badge badge-success">
                                {item ? item.status : ""}
                              </span>
                            ) : (
                              <span className="badge badge-danger">
                                {item ? item.status : ""}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label
                            htmlFor="description"
                            className="col-md-4 col-form-label"
                          >
                            Description:
                          </label>
                          <div className="col-md-8">
                            <input
                              autoFocus
                              type="text"
                              className="form-control form-control-sm"
                              id="description"
                              name="description"
                              value={description}
                              onChange={(e) =>
                                onChange("description", e.currentTarget.value)
                              }
                            />
                            <div
                              className="invalid-feedback"
                              style={{ visibility: errors.description }}
                            >
                              {errors.description}
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="address"
                            className="col-md-4 col-form-label"
                          >
                            Address:
                          </label>
                          <div className="col-md-8">
                            <input
                              autoFocus
                              type="text"
                              className="form-control form-control-sm"
                              id="address"
                              name="address"
                              value={address}
                              onChange={(e) =>
                                onChange("address", e.currentTarget.value)
                              }
                            />
                            <div
                              className="invalid-feedback"
                              style={{ visibility: errors.address }}
                            >
                              {errors.address}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(e, ActionTypes.UPDATE, "Branch", () => {
                      this.onFormSubmit(
                        { name, description, address },
                        onReloadFields
                      );
                    });
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></EditBranchForm>
      </Modal>
    );
  }
}

export default EditBranch;
