import http from "../httpService";
import { IFilterData } from "../approval/approvalItemService";
import axios from "axios";
import { getCurrentUserSession } from "../auth/authService";

const sweepBaseAddress = process.env.REACT_APP_SWEEP_BASEADDRESS;
export function retrieveRepaymentsReport(
  loanInstitutionCode: string,
  recoveryInstitutionCode: string,
  pageSize: number,
  currentPage: number
) {
  var url =
    sweepBaseAddress +
    `Reports/GetRepaymentsReport?loanInstitution=${loanInstitutionCode}&pageSize=${pageSize}&pageNumber=${currentPage - 1
    }`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}
export function retrieveFailedRepaymentsReport(
  loanInstitutionCode: string,
  recoveryInstitutionCode: string,
  pageSize: number,
  currentPage: number
) {
  var url =
    sweepBaseAddress +
    `Reports/GetFailedRepaymentsReport?loanInstitution=${loanInstitutionCode}&pageSize=${pageSize}&pageNumber=${currentPage - 1
    }`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}
export function retrieveSuccessfulIncomingRepaymentsReport(
  loanInstitutionCode: string,
  recoveryInstitutionCode: string,
  pageSize: number,
  currentPage: number
) {
  var url =
    sweepBaseAddress +
    `Reports/GetSuccessfulIncomingRepaymentsReport?loanInstitution=${loanInstitutionCode}&pageSize=${pageSize}&pageNumber=${currentPage - 1
    }`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}
export function retrieveSuccessfulOutgoingRepaymentsReport(
  loanInstitutionCode: string,
  recoveryInstitutionCode: string,
  pageSize: number,
  currentPage: number
) {
  var url =
    sweepBaseAddress +
    `Reports/GetSuccessfulOutgoingRepaymentsReport?loanInstitution=${loanInstitutionCode}&pageSize=${pageSize}&pageNumber=${currentPage - 1
    }`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}
export function retrieveLoanUpdateTransactionsReport(
  loanInstitutionCode: string,
  recoveryInstitutionCode: string,
  pageSize: number,
  currentPage: number
) {
  var url =
    sweepBaseAddress +
    `Reports/GetLoanUpdateTransactionsReport?loanInstitution=${loanInstitutionCode}&pageSize=${pageSize}&pageNumber=${currentPage - 1
    }`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveFailedRepaymentsReportById(id: number) {
  var url = sweepBaseAddress + `Reports/GetFailedRepaymentsReportById?Id=${id}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveRepaymentsReportById(id: number) {
  var url =
    sweepBaseAddress +
    `Reports/GetRepaymentsReportById?Id=${id}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveReversalsReportById(id: number) {
  var url =
    sweepBaseAddress +
    `Reports/GetReversalsReportById?Id=${id}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveLoanUpdateTransactionsReportById(id: number) {
  var url =
    sweepBaseAddress + `Reports/GetLoanUpdateTransactionsReportById?Id=${id}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveRepaymentsByCustomerLoanReference(
  institutionCode: string,
  loanReference: string,
  pageSize: number,
  pageNumber: number
) {
  var url =
    sweepBaseAddress +
    `Reports/GetRepaymentsByCustomerLoanReference?loanInstitution=${institutionCode}&loanReference=${loanReference}&pageSize=${pageSize}&pageNumber=${pageNumber - 1}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveGuarantorRepaymentsByCustomerLoanReference(
  institutionCode: string,
  loanReference: string,
  pageSize: number,
  pageNumber: number
) {
  var url =
    sweepBaseAddress +
    `Reports/GetGuarantorRepaymentsByCustomerLoanReference?loanInstitution=${institutionCode}&loanReference=${loanReference}&pageSize=${pageSize}&pageNumber=${pageNumber - 1}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveSimilarTransactionsReport(
  transactionId: number,
  pageSize: number,
  pageNumber: number
) {
  var url =
    sweepBaseAddress +
    `Reports/GetSimilarTransactionsReport?transactionId=${transactionId}&pageSize=${pageSize}&pageNumber=${pageNumber - 1
    }`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function filterFailedRepaymentsReport(
  payload: IFilterData,
  loanInstitutionCode: string,
  // recoveryInstitutionCode: string,
  pageSize: number,
  currentPage: number
) {
  var url =
    sweepBaseAddress +
    `Reports/FilterPagedFailedRepaymentsReport?loanInstitution=${loanInstitutionCode}&pageSize=${pageSize}&pageNumber=${currentPage}`;

  return http.post(url, payload, {
    url: url,
    data: payload,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}
export function filterSuccessfulIncomingRepaymentsReport(
  payload: IFilterData,
  loanInstitutionCode: string,
  // recoveryInstitutionCode: string,
  pageSize: number,
  currentPage: number
) {
  var url =
    sweepBaseAddress +
    `Reports/FilterPagedIncomingRepaymentsReport?loanInstitution=${loanInstitutionCode}&pageSize=${pageSize}&pageNumber=${currentPage}`;

  return http.post(url, payload, {
    url: url,
    data: payload,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function filterSuccessfulIncomingReversalsReport(
  payload: IFilterData,
  loanInstitutionCode: string,
  // recoveryInstitutionCode: string,
  pageSize: number,
  currentPage: number
) {
  var url =
    sweepBaseAddress +
    `Reports/FilterPagedIncomingReversalsReport?loanInstitution=${loanInstitutionCode}&pageSize=${pageSize}&pageNumber=${currentPage}`;

  return http.post(url, payload, {
    url: url,
    data: payload,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function filterSuccessfulOutgoingRepaymentsReport(
  payload: IFilterData,
  loanInstitutionCode: string,
  // recoveryInstitutionCode: string,
  pageSize: number,
  currentPage: number
) {
  var url =
    sweepBaseAddress +
    `Reports/FilterPagedOutgoingRepaymentsReport?loanInstitution=${loanInstitutionCode}&pageSize=${pageSize}&pageNumber=${currentPage}`;

  return http.post(url, payload, {
    url: url,
    data: payload,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function filterSuccessfulOutgoingReversalsReport(
  payload: IFilterData,
  loanInstitutionCode: string,
  // recoveryInstitutionCode: string,
  pageSize: number,
  currentPage: number
) {
  var url =
    sweepBaseAddress +
    `Reports/FilterPagedOutgoingReversalsReport?loanInstitution=${loanInstitutionCode}&pageSize=${pageSize}&pageNumber=${currentPage}`;

  return http.post(url, payload, {
    url: url,
    data: payload,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function filterLoanUpdateTransactionsReport(
  payload: IFilterData,
  loanInstitutionCode: string,
  // recoveryInstitutionCode: string,
  pageSize: number,
  currentPage: number
) {
  var url =
    sweepBaseAddress +
    `Reports/FilterPagedLoanUpdateTransactionsReport?loanInstitution=${loanInstitutionCode}&pageSize=${pageSize}&pageNumber=${currentPage}`;

  return http.post(url, payload, {
    url: url,
    data: payload,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function filterRepaymentsByCustomerLoanReference(
  payload: IFilterData,
  institutionCode: string,
  loanReference: string,
  pageSize: number,
  pageNumber: number
) {
  var url =
    sweepBaseAddress +
    `Reports/FilterPagedRepaymentsByCustomerLoanReferenceReport?loanInstitution=${institutionCode}&loanReference=${loanReference}&pageSize=${pageSize}&pageNumber=${pageNumber}`;

  return http.post(url, payload, {
    url: url,
    data: payload,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

// export function filterRepaymentsBySweepEpisodesReport(
//   payload: IFilterData,
//   loanInstitutionCode: string,
//   loanReference: string,
//   id: number,
//   pageSize: number,
//   pageNumber: number
// ) {
//   return http.post(
//     sweepBaseAddress +
//     `Reports/FilterPagedRepaymentsBySweepEpisodesReport?loanInstitution=${loanInstitutionCode}&loanReference=${loanReference}&sweepEpisodeId=${id}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
//     payload
//   );
// }

export function retrieveSettlementReport(
  loanInstitutionCode: string,
  reportType: string,
  pageSize: number,
  pageNumber: number
) {
  var url =
    sweepBaseAddress +
    `Settlement/RetrieveSettlementReportV2/${reportType}/${loanInstitutionCode}/v2?pageSize=${pageSize}&pageNumber=${pageNumber}`;

  return http.post(url, {}, {
    url: url,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveAllSettlementReport(
  pageSize: number,
  pageNumber: number
) {
  var url =
    sweepBaseAddress +
    `Settlement/GetAllEntries?pageSize=${pageSize}&pageNumber=${pageNumber}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveSettlementReportItemById(id: Number) {
  var url = sweepBaseAddress + `Settlement/RetrieveSettlementReportItemById/Settlement/${id}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveSettlementRequestsReport(payload: IFilterData, pageSize: number, pageNumber) {
  var url = sweepBaseAddress + `ThirdPartySettlementRequest/FilterThirdPartySettlementRequests?maxSize=${pageSize}&pageNum=${pageNumber}`;

  return http.post(url, payload, { url: url, data: payload, method: "post", headers: { Authorization: `Bearer ${getCurrentUserSession().token}` } })
}

export function retrieveSettlementRequestById(id: number) {
  var url = sweepBaseAddress + `ThirdPartySettlementRequest/RetrieveById/${id}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveSettlementTransactionRequestByReference(reference: string) {
  var url = sweepBaseAddress + `Settlement/SettlementDetailsReport//${reference}`;

  return http.post(url, {
    url: url,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function filterPayoutRequestReport(
  payload: IFilterData,
  pageSize: number,
  pageNumber: number
) {
  var url =
    sweepBaseAddress +
    `Payout/FilterPagedPayoutRequests?pageSize=${pageSize}&pageNumber=${pageNumber}`;
  return http.post(url, payload, {
    url: url,
    data: payload,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrievePayoutRequestReportByUniqueIdentifier(uid: string) {
  var url =
    sweepBaseAddress +
    `Payout/RetrievePayoutRequestByUniqueIdentifier/${uid}`;
  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveSettlementPayoutReport(
  pageSize: number,
  pageNumber: number
) {
  var url =
    sweepBaseAddress +
    `Reports/RetrievePagedSettlementPayoutReport?maxSize=${pageSize}&pageNumber=${pageNumber}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function downloadIncomingRepaymentsReport(institutionCode, type: String) {
  if (type.toLowerCase() === "pdf") {
    var url = sweepBaseAddress + `Reports/DownloadIncomingRepaymentsReportPDF`;

    return http.get(url, {
      url: url,
      method: "get",
      headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
  } else {
    var url2 =
      sweepBaseAddress + `Reports/DownloadIncomingRepaymentsReport?type=${type}`;
    axios({
      url: url2,
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${getCurrentUserSession().token}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Users.${type}`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }
}

export function downloadOutgoingRepaymentsReport(institutionCode, type: String) {
  if (type.toLowerCase() === "pdf") {
    var url = sweepBaseAddress + `Reports/DownloadOutgoingRepaymentsReportPDF`;

    return http.get(url, {
      url: url,
      method: "get",
      headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
  } else {
    var url2 =
      sweepBaseAddress + `Reports/DownloadOutgoingRepaymentsReport/?type=${type}`;
    axios({
      url: url2,
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${getCurrentUserSession().token}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Users.${type}`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }
}

export function downloadFailedRepaymentsReport(institutionCode, type: String) {
  if (type.toLowerCase() === "pdf") {
    var url = sweepBaseAddress + `Reports/DownloadFailedRepaymentsReportPDF`;

    return http.get(url, {
      url: url,
      method: "get",
      headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
  } else {
    var url2 = sweepBaseAddress + `Reports/DownloadFailedRepaymentsReport?type=${type}`;
    axios({
      url: url2,
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${getCurrentUserSession().token}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Users.${type}`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }
}

export function downloadLoanUpdateTransactionsReport(institutionCode, type: String) {
  if (type.toLowerCase() === "pdf") {
    var url = sweepBaseAddress + `Reports/DownloadLoanUpdateTransactionsReportPDF`;

    return http.get(url, {
      url: url,
      method: "get",
      headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
  } else {
    var url2 = sweepBaseAddress + `Reports/DownloadLoanUpdateTransactionsReport?type=${type}`;
    axios({
      url: url2,
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${getCurrentUserSession().token}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Users.${type}`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }
}

export function downloadSweepEpisodesReport(institutionCode, type: String) {
  if (type.toLowerCase() === "pdf") {
    var url = sweepBaseAddress + `SweepRun/DownloadSweepEpisodesReportPDF`;

    return http.get(url, {
      url: url,
      method: "get",
      headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
  } else {
    var url2 = sweepBaseAddress + `SweepRun/DownloadSweepEpisodesReport?type=${type}`;
    axios({
      url: url2,
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${getCurrentUserSession().token}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Users.${type}`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
    // return http.get()
  }
}

export function downloadSettlementReport(institutionCode, type: String) {
  if (type.toLowerCase() === "pdf") {
    var url = sweepBaseAddress + `Settlement/DownloadSettlementReportPDF`;

    return http.get(url, {
      url: url,
      method: "get",
      headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
  } else {
    var url2 = sweepBaseAddress + `Settlement/DownloadSettlementReport?type=${type}`;
    axios({
      url: url2,
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${getCurrentUserSession().token}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Users.${type}`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }
}

export function filterSettlementsAndPayoutsReport(payload: IFilterData, pageSize, currentPage) {
  var url = sweepBaseAddress + `Reports/FilterPagedSettlementsPayouts2Report?pageSize=${pageSize}&pageNumber=${currentPage}`;
  return http.post(url, payload, {
    url: url,
    method: "post",
    // headers: {
    //   Authorization: `Bearer ${getCurrentUserSession().token}`,
    // },
  });
}

export function retrieveSettlementsAndPayoutsReportDetail(id: number, uniqueId: string, channel: string) {
  var url = sweepBaseAddress + `Reports/RetrieveSettlementPayoutReportDetail?id=${id}&uniqueId=${uniqueId}&channel=${channel}`;
  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function downloadSettlementsAndPayoutsReport(type: String) {
  if (type.toLowerCase() === "pdf") {
    var url = sweepBaseAddress + `Reports/DownloadSettlementsAndPayoutsReportPdf`;
    return http.get(url, {
      url: url,
      method: "get",
      headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
  } else {
    var url2 = sweepBaseAddress + `Reports/DownloadSettlementsAndPayoutsReport?type=${type}`;
    axios({
      url: url2,
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${getCurrentUserSession().token}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Settlements & Payouts.${type}`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }
}

export default {
  retrieveRepaymentsReport,
  retrieveRepaymentsReportById,
  retrieveReversalsReportById,
  retrieveFailedRepaymentsReport,
  retrieveFailedRepaymentsReportById,
  retrieveSuccessfulIncomingRepaymentsReport,
  retrieveSuccessfulOutgoingRepaymentsReport,
  retrieveLoanUpdateTransactionsReport,
  retrieveLoanUpdateTransactionsReportById,
  retrieveRepaymentsByCustomerLoanReference,
  retrieveGuarantorRepaymentsByCustomerLoanReference,
  retrieveSimilarTransactionsReport,
  retrieveSettlementRequestsReport,
  retrieveSettlementRequestById,
  retrieveSettlementTransactionRequestByReference,
  filterFailedRepaymentsReport,
  filterSuccessfulIncomingRepaymentsReport,
  filterSuccessfulOutgoingRepaymentsReport,
  filterSuccessfulIncomingReversalsReport,
  filterSuccessfulOutgoingReversalsReport,
  filterLoanUpdateTransactionsReport,
  filterRepaymentsByCustomerLoanReference,
  // filterRepaymentsBySweepEpisodesReport,
  retrieveSettlementReport,
  retrieveAllSettlementReport,
  downloadIncomingRepaymentsReport,
  downloadOutgoingRepaymentsReport,
  downloadFailedRepaymentsReport,
  downloadLoanUpdateTransactionsReport,
  downloadSweepEpisodesReport,
  downloadSettlementReport,
  retrieveSettlementReportItemById,
  filterPayoutRequestReport,
  retrievePayoutRequestReportByUniqueIdentifier,
  retrieveSettlementPayoutReport,
  filterSettlementsAndPayoutsReport,
  retrieveSettlementsAndPayoutsReportDetail,
  downloadSettlementsAndPayoutsReport,
};
