import * as React from "react";
import _ from "lodash";
import { Fragment } from "react";
import {
  IFilterData,
  BaseListComponentState,
} from "../../../components/BaseListComponent";
import {
  createFilterData,
  mapEntityList,
} from "./../../../services/utility/autoMapperService";
import BaseListComponent from "./../../../components/BaseListComponent";
import ItemsTable, {
  filterFieldData,
} from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "./../../../components/tableComponents/tableFilterPane";
import { Frequency } from "../../../enums/frequency";
import { ReportFormat } from "../../../enums/reportFormat";
import mailingListService from "../../../services/auth/mailingListService";
import MailingListDetail from "./mailingListDetail";
import EditMailingList from "./editMailingList";

export interface MailingListsProps { }

export interface MailingListsState extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
  toUpdate: boolean;
}

class MailingListsList extends BaseListComponent<MailingListsState> { }

class MailingLists extends React.Component<MailingListsProps, MailingListsState> {
  constructor(props: MailingListsProps) {
    super(props);
    this.state = {
      data: createFilterData([
        "name",
        "frequency",
        "reportFormat",
      ]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [
        { name: "name", fieldType: "text", value: "" },
        {
          name: "frequency",
          fieldType: "select",
          options: Object.keys(Frequency).filter((value) => isNaN(Number(value)) === false).map((key) => (Frequency[key])),
          value: "",
        },
        {
          name: "reportFormat",
          fieldType: "select",
          options: Object.keys(ReportFormat).filter((value) => isNaN(Number(value)) === false).map((key) => (ReportFormat[key])),
          value: "",
        },
      ],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
      toUpdate: true,
    };
  }

  //   generateInitialFieldValues = async () =>{
  //     const branches = await branchesService.retrieveAllBranches();
  //       const branchNames = !_.isEmpty(branches)
  //         ? branches.data.map((item) => item.name)
  //         : [];
  //       let filterValueInput = [...this.state.filterValueInput];
  //       console.log("got branches pre", filterValueInput);
  //       filterValueInput = filterValueInput.map((filterField) =>
  //         filterField.name === "branch"
  //           ? { ...filterField, options: branchNames }
  //           : filterField
  //       );
  //       console.log("got branches", filterValueInput);
  //       return filterValueInput;
  //   }

  handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true });
    let dataClone =
      typeof data === "undefined" ? { ...this.state.data } : { ...data };
    try {
      //   console.log("checkin issues", filterData);
      const mailingLists = await mailingListService.filterMailingList(
        dataClone,
        pageSize,
        currentPage
      );
      this.setState({
        tableItems: mapEntityList({
          entityList: mailingLists.data.item2,
          properties: [
            { oldName: "name", newName: "name" },
            { oldName: "frequency", newName: "frequency" },
            { oldName: "reportFormat", newName: "reportFormat" },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: mailingLists.data.item1,
        fetching: false,
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
        this.setState({ fetching: false });
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        this.setState({ fetching: false });
      }
    } finally {
      this.setState({ fetching: false });
    }
  };

  handleOnExport = async (type: string) => { };

  fetchItemById = async (item: any) => {
    // console.log("Item: ", item)
    this.setState({ fetchingDetail: true });
    const mailingList = await mailingListService.retrieveById(item.id);
    this.setState({ item: mailingList.data, fetchingDetail: false });
  };


  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }

  render() {
    const {
      // data,
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;
    // console.log("asfsdf", this.state.filterValueInput);
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Mailing Lists</b>
              </h3>
            </div>
            <MailingListsList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              initialValues={{
                data: this.state.data,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: this.state.filterValueInput,
                item: {},
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                onToggleEditModal,
                showDetailsModal,
                showEditModal
              }) => (
                <Fragment>
                  <MailingListDetail
                    showDetailsModal={showDetailsModal}
                    toggleDetailsModal={onToggleDetailsModal}
                    toggleEditModal={onToggleEditModal}
                    item={item}
                  />
                  <EditMailingList
                    showEditModal={showEditModal}
                    toggleEditModal={(reloadDetails: boolean, item: any) => {
                      onToggleEditModal();
                      if (reloadDetails === true) {
                        this.fetchItemById(item);
                      }
                      this.setState({
                        showDetailsModal: !this.state.showDetailsModal,
                      });
                    }}
                    item={item}
                  />
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="MailingLists"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></MailingListsList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default MailingLists;
