import http from "../httpService";

const recovaCollectionBaseAddress = process.env.REACT_APP_COLLECTIONS_BASEADDRESS;
// const recovaCollectionBaseAddress = "https://localhost:44309/api/";

export function retrieveAllCollectionSchedules() {
  return http.get(
    recovaCollectionBaseAddress +
      `collections/schedules`
  );
}

export function retrieveCollectionScheduleById(id) {
  return http.get(
    recovaCollectionBaseAddress +
      `collections/schedules/${id}`
  );
}

export function retrieveCollectionSchedulesByInstitutionCode(institutionCode) {
  return http.get(
    recovaCollectionBaseAddress +
      `collections/schedules/institution/${institutionCode}`
  );
}

export function restructureRepaymentSchedule(payload, mandateReference) {
  return http.put(
    recovaCollectionBaseAddress +
      `collections/schedules/restructure/${mandateReference}`,
      payload
  );
}

export function modifyCollectionConfigOfSchedule(payload, id) {
  return http.put(
    recovaCollectionBaseAddress +
      `collections/schedules/collectionconfig/modify/${id}`,
      payload
  );
}

export function retrieveAllOldCollectionSchedules() {
  return http.get(
    recovaCollectionBaseAddress +
      `collections/schedules/archives`
  );
}

export function retrieveOldCollectionSchedulesByInstitutionCode(institutionCode) {
  return http.get(
    recovaCollectionBaseAddress +
      `collections/schedules/archives/institution/${institutionCode}`
  );
}

export function retrieveOldCollectionScheduleById(id) {
  return http.get(
    recovaCollectionBaseAddress +
      `collections/schedules/archives/${id}`
  );
}

export function suspendCollections(payload){
  return http.put(
    recovaCollectionBaseAddress +
      `collections/schedules/suspend`,
      payload
  );
}

export function resumeCollections(payload) {
  return http.put(
    recovaCollectionBaseAddress +
      `collections/schedules/resume`,
      payload
  );
}

// export function filterPagedCreditQualificationRules(
//   data: any,
//   pageSize: number,
//   pageNumber: number
// ) {
//   return http.post(
//     recovaCollectionBaseAddress +
//       `CreditQualificationRule/FilterCreditQualificationRules?pageSize=${pageSize}&pageNumber=${pageNumber}`,
//     data
//   );
// }

export default {
  retrieveAllCollectionSchedules,
  retrieveCollectionScheduleById,
  retrieveCollectionSchedulesByInstitutionCode,
  restructureRepaymentSchedule,
  modifyCollectionConfigOfSchedule,
  retrieveAllOldCollectionSchedules,
  retrieveOldCollectionSchedulesByInstitutionCode,
  retrieveOldCollectionScheduleById,
  suspendCollections,
  resumeCollections
};
