export enum EconomicSector {
  AgricultureAndForestry = 0,
  MiningAndQuarry = 1,
  ManufacturingAndFoodProcessing = 2,
  TradeAndCommerce = 3,
  TransportAndCommunication = 4,
  RealEstateAndConstruction = 5,
  RentOrHousing = 6,
  ConsumerOrPersonal = 7,
  Health = 8,
  Education = 9,
  TourismAndHospitality = 10,
  PurchaseOfShares = 11,
  Other = 12,
  PublicUtilities = 13,
  FinanceOrInsurance = 14,
  General = 15,
  Electicity = 16,
  Gas = 17,
  Water = 18,
  BuildingAndConstruction = 19,
  FinancialIntermediaries = 20,
  HotelsAndRestaurants = 21,
  Leasing = 22,
  WarehousingAndStorage = 23,
  Fishing = 24,
  Forest = 25,
  Hunting,
}
