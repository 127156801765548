import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";

const sweepBaseAddress = process.env.REACT_APP_SWEEP_BASEADDRESS;

export function RetrieveLBUTransactionById(id: number) {
  var url = sweepBaseAddress + `LoanUpdate/RetrieveLBUTransactionById/${id}`;
  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function RetryLoanBalanceUpdateProcess(id: number) {
  var url = sweepBaseAddress + `LoanUpdate/RetryLoanBalanceUpdateProcess/${id}`;
  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export default {
  RetrieveLBUTransactionById,
  RetryLoanBalanceUpdateProcess
};
