import React from "react";
import { toast } from "react-toastify";
import branchesService from "../../../services/auth/branchesService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import authValidationService from "../../../services/auth/authValidationService";
import { getCurrentUserSession } from "../../../services/auth/authService";
import FormInputComponent from "../../../components/formInputComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import { getIpAddress } from "../../../services/utility/textFormatService";
import { decodeToken } from "react-jwt";

interface AddBranchFields {
  name: string;
  code: string;
  description: string;
  address: string;
}

class AddBranchForm extends BaseFormComponent<AddBranchFields> { }

interface IAddBranchProps { }
interface IAddBranchState {
  clientip: any;
}

class AddBranch extends React.Component<
  IAddBranchProps,
  IBaseFormState & IAddBranchState
> {
  constructor(props: IAddBranchProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      clientip: {},
    };
  }

  onFormSubmit(fields: AddBranchFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        { errors: authValidationService.validateAddBranchForm(fields) },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddBranchFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      const { clientip } = this.state;
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      // console.log("starting submission...");
      this.setState({ submitting: true });
      const payload: any = {};
      payload.name = fields.name;
      payload.code = fields.code;
      payload.description = fields.description;
      payload.address = fields.address;
      payload.clientIPAddress = clientip.geoplugin_request;
      payload.institutionId = decodedToken?.["InstitutionId"];
      // console.log("payload: ", payload);
      const response = await branchesService.saveBranch(payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Add Branch",
          description: `Added new Branch  ${fields.name}`,
        });

        if (response.data.isLoggedForApproval === true) {
          toast.warn("Action has been successfully logged for Approval!", {
            autoClose: 6000,
            type: toast.TYPE.WARNING,
            position: "top-center",
            hideProgressBar: false,
          });
        } else {
          toast.info(`Branch ${fields.name} created successfully! `, {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          });
        }
      } else {
        return false;
      }
      this.setState({ submitting: false });
      onReloadFieldsCB();
    } catch (error) {
      // console.log("error: ", error.response.data);
      return false;
    } finally {
      this.setState({ submitting: false });
      return true;
    }
  }

  async componentDidMount() {
    var response = await getIpAddress();
    // console.log("test: ", response);
    this.setState({ clientip: response.data });
  }

  render() {
    const { errors, validated } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add Branch</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddBranchForm
                  initialValues={{
                    name: "",
                    code: "",
                    description: "",
                    address: "",
                  }}
                  FormComponent={({
                    fields: { name, code, description, address },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <h5 className="card-title">GENERAL INFORMATION</h5>
                          <div className="form-row">
                            <div className="col-md-12">
                              <div className="form-row">
                                <FormInputComponent
                                  id="name"
                                  type="text"
                                  name={"name"}
                                  placeholder=""
                                  divClass={6}
                                  value={name}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="code"
                                  type="text"
                                  name={"code"}
                                  placeholder=""
                                  divClass={6}
                                  value={code}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-6 m-0">
                                  <label
                                    htmlFor="description"
                                    className="col-form-label s-12"
                                  >
                                    <span
                                      aria-hidden="true"
                                      className="mr-2"
                                      style={{ color: "#ed5564" }}
                                    >
                                      *
                                    </span>
                                    Description
                                  </label>
                                  <textarea
                                    id="description"
                                    name="description"
                                    className={
                                      !validated
                                        ? "form-control r-0 light s-12"
                                        : errors.description
                                          ? "form-control r-0 light s-12 is-invalid"
                                          : "form-control r-0 light s-12 is-valid"
                                    }
                                    value={description}
                                    onChange={(e) =>
                                      onChange(
                                        "description",
                                        e.currentTarget.value
                                      )
                                    }
                                  ></textarea>
                                  <div
                                    className="invalid-feedback"
                                    style={{
                                      visibility: errors.description,
                                    }}
                                  >
                                    {errors.description}
                                  </div>
                                </div>
                                <div className="form-group col-md-6 m-0">
                                  <label
                                    htmlFor="address"
                                    className="col-form-label s-12"
                                  >
                                    <span
                                      aria-hidden="true"
                                      className="mr-2"
                                      style={{ color: "#ed5564" }}
                                    >
                                      *
                                    </span>
                                    Address
                                  </label>
                                  <textarea
                                    id="address"
                                    name="address"
                                    className={
                                      !validated
                                        ? "form-control r-0 light s-12"
                                        : errors.address
                                          ? "form-control r-0 light s-12 is-invalid"
                                          : "form-control r-0 light s-12 is-valid"
                                    }
                                    value={address}
                                    onChange={(e) =>
                                      onChange("address", e.currentTarget.value)
                                    }
                                  ></textarea>
                                  <div
                                    className="invalid-feedback"
                                    style={{
                                      visibility: errors.address,
                                    }}
                                  >
                                    {errors.address}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* {Object.keys(this.state.errors).length > 0 && (
                          <div className="col-lg-8">
                            <div
                              className="alert alert-danger alert-dismissible"
                              role="alert"
                            >
                              <button
                                type="button"
                                className="close"
                                data-dismiss="alert"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">×</span>
                              </button>
                              <strong>
                                {
                                  this.state.errors[
                                    Object.keys(this.state.errors)[0]
                                  ]
                                }
                              </strong>
                            </div>
                          </div>
                        )} */}
                        <hr />
                        <div className="card-body">
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                            disabled={this.state.submitting}
                            onClick={(e) => {
                              onHandleSubmit(
                                e,
                                ActionTypes.SAVE,
                                "Branch",
                                () => {
                                  this.onFormSubmit(
                                    {
                                      name,
                                      code,
                                      description,
                                      address,
                                    },
                                    onReloadFields
                                  );
                                }
                              );
                            }}
                          >
                            {this.state.submitting === false ? (
                              <React.Fragment>
                                <i className="fas fa-lg fa-save mr-3" /> Save
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                Please wait...
                              </React.Fragment>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                ></AddBranchForm>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddBranch;
