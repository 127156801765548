import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button, Accordion, Card } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import { ActionTypes } from "../../../enums/actionTypes";
import swal from "sweetalert";
import DetailItemsTable from "../../../components/tableComponents/detailItemsTable";
import { mapEntityList } from "../../../services/utility/autoMapperService";
import { getCurrentUserSession, getCurrentUsersRoles } from "../../../services/auth/authService";
import { decodeToken } from "react-jwt";
import scoringCardService from "../../../services/creditAssessment/scoringCardService";
import textFormatService from "../../../services/utility/textFormatService";
import { ContextAwareToggle } from "../scoreManagement/addScoreCard";
import loanProductService from "../../../services/creditAssessment/loanProductService";

interface ILoanProductDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  showTrail: any;
  currentUserRoles: any;
  item: any;
  togglePatchModal: any;
  // scoreCardList: any
}

interface ILoanProductDetailState {
  item: any;
  updatingStatus: boolean;
  scoreCardList: any;
  pricing: any;
}

class LoanProductDetail extends Component<
  ILoanProductDetailProps,
  ILoanProductDetailState
> {
  state = {
    item: {},
    updatingStatus: false,
    errors: {},
    scoreCardList: [],
    pricing: [],
  };

  static getDerivedStateFromProps(
    nextProps: ILoanProductDetailProps,
    prevState
  ) {
    // console.log("current props: ", nextProps.item);
    return {
      item: nextProps.item,
    };
  }

  async componentDidMount() {
    try {
      // let item = this.props.item;
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      // const pricingDetail = await loanProductService.retrieveProductPricingByInstitutionCode(decodedToken?.["InstitutionCode"], item.productCode)
      // const pricing = pricingDetail.data
      this.setState({
        // pricing
      });
    } catch (ex) {

    }
  }

  onHandleEnableOrDisable = (
    e: any,
    actionType: ActionTypes,
    entityName: string
  ) => {
    this.setState({ updatingStatus: true });
    if (!actionType) {
      this.handleEnableOrDisableItem(this.props.item);
    } else {
      swal({
        title: "Confirm!",
        text: `Are you sure you want to proceed to ${ActionTypes[actionType]} this ${entityName}?`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode: true,
      }).then((resp) => {
        if (resp) {
          // console.log("cancelling action...");
          this.setState({ updatingStatus: false });
        } else {
          this.handleEnableOrDisableItem(this.state.item);
        }
      });
    }
  };

  handleEnableOrDisableItem = async (_item: any) => {
    // try {
    //   let approvalResponse: any = {};
    //   // this.setState({ updatingStatus: true });
    //   await activityTrailService.saveActivityTrail({
    //     actionType: `${
    //       _item.status === "Inactive" ? "Enable" : "Disable"
    //     } Branch`,
    //     description: `Clicked on ${
    //       _item.status === "Inactive" ? "Enable" : "Disable"
    //     } to ${_item.status === "Inactive" ? "Enable" : "Disable"} Branch ${
    //       _item.name
    //     }`,
    //   });
    //   switch (_item.status) {
    //     case "Inactive":
    //       approvalResponse = await branchesService.enableBranch(_item.id);
    //       if (approvalResponse.data.isLoggedForApproval === false) {
    //         _item.status = "Active";
    //       }
    //       break;
    //     case "Active":
    //       approvalResponse = await branchesService.disableBranch(_item.id);
    //       if (approvalResponse.data.isLoggedForApproval === false) {
    //         _item.status = "Inactive";
    //       }
    //       break;
    //     default:
    //       approvalResponse = await branchesService.enableBranch(_item.id);
    //       if (approvalResponse.data.isLoggedForApproval === false) {
    //         _item.status = "Active";
    //       }
    //       break;
    //   }
    //   if (approvalResponse.data.isLoggedForApproval === true) {
    //     toast.warn(approvalResponse.data.message, {
    //       autoClose: 6000,
    //       type: toast.TYPE.WARNING,
    //       position: "top-center",
    //       hideProgressBar: false,
    //     });
    //   } else {
    //     toast.info(
    //       `Branch ${_item.name} ${
    //         _item.status === "Active" ? "Enabled" : "Disabled"
    //       } successfully! `,
    //       {
    //         autoClose: 6000,
    //         type: toast.TYPE.DEFAULT,
    //         hideProgressBar: false,
    //       }
    //     );
    //   }
    //   this.setState({ updatingStatus: false, item: _item });
    // } catch (ex) {
    //   this.setState({ updatingStatus: false, item: _item });
    // }
  };

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      showTrail,
      currentUserRoles,
      togglePatchModal,
      item,
      // scoreCardList
    } = this.props;
    const { updatingStatus, pricing } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="icon icon--columns s-18 mr-3" />
                Loan Product Details -{" "}
                <span className="font-weight-bold">{item.name}</span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
              {/* <div className="float-right">
                {_.isEmpty(item) ? (
                  <Skeleton width={100} />
                ) : (
                  <span
                    className="badge btn-primary"
                    onClick={showTrail}
                    style={{
                      cursor: "pointer",
                      visibility: currentUserRoles.includes(
                        "viewaudittrailitems"
                      )
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    View Trail
                  </span>
                )}
              </div> */}
            </div>
            {/* {
  "creditQualificationRules": [
    {
      "creditQualificationRuleId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      "name": "string",
      "dateAdded": "2021-05-25T14:46:34.309Z"
    }
  ]
} */}
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Name"
                        value={item.name}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Product Code"
                        value={item.productCode}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Minimum Score"
                        value={item.minimumScore}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Moratorium"
                        value={item.moratorium}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Status"
                        value={
                          (item ? item.isActive : true) === true ? (
                            <span className="badge badge-success">
                              {item ? "Active" : ""}
                            </span>
                          ) : (
                            <span className="badge badge-danger">
                              {item ? "Inactive" : ""}
                            </span>
                          )
                        }
                        labelSize={5}
                        valueSize={7}
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      {/* <DetailItem
                        label="Repayment Schedule"
                        value={item.repaymentSchedule}
                        labelSize={5}
                        valueSize={7}
                      /> */}
                      <DetailItem
                        label="Tenure In Days"
                        value={item.tenure}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Minimum Amount (₦)"
                        value={item.minimumAmount}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Maximum Amount (₦)"
                        value={item.maximumAmount}
                        labelSize={5}
                        valueSize={7}
                      />
                      {/* <DetailItem
                        label="Score Card"
                        value={scoreCardList?.filter(x => x.id === item.scoreCard)[0]?.name}
                        labelSize={5}
                        valueSize={7}
                      /> */}
                    </dl>
                  )}
                </div>
              </div>
            </div>
            <Accordion defaultActiveKey="0">
              <div>
                <Card>
                  <ContextAwareToggle
                    eventKey={`0`}
                    isHeader={true}
                    header={
                      <React.Fragment>
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                          style={{ color: "#878a8a" }}
                        ></i>{" "}
                        Pricing Config
                      </React.Fragment>
                    }
                  ></ContextAwareToggle>
                  <Accordion.Collapse eventKey={`0`}>
                    <Card.Body>
                      <div className=" form-row col-md-12">
                        <div
                          className="table-responsive text-nowrap"
                          style={{
                            maxHeight: "150px",
                            overflowY: "scroll",
                          }}
                        >
                          <table className="table table-hover table-content table-sm table-striped mb-0">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>#</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Min Score</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Max Score</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Limit</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Tenor</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Interest</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Repayment Frequency</strong>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {item.pricing?.map((item_) => (
                                <tr key={item.pricing?.indexOf(item_)}>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item.pricing?.indexOf(item_) + 1}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item_.minScore}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item_.maxScore}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {textFormatService.formatCurrency(Number(item_.limit), 2)}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item_.tenor}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item_.pricing}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item_.repaymentFrequency}
                                  </td>


                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </div>
            </Accordion>
            <DetailItemsTable
              title="Credit Qualification Rules"
              item={item}
              handleOnPageChange={() => { }}
              content={
                _.isEmpty(item)
                  ? []
                  : mapEntityList({
                    entityList: item.creditQualificationRules,
                    properties: [
                      {
                        oldName: "name",
                        newName: "name",
                      },
                      { oldName: "dateAdded", newName: "dateAdded" },
                    ],
                  })
              }
              contentCount={
                _.isEmpty(item) ? 0 : item.creditQualificationRules.length
              }
              page={1}
              fetching={_.isEmpty(item) ? true : false}
              timeColumn={["dateAdded"]}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
              {(getCurrentUsersRoles().includes("addcreditqualificationrule") || getCurrentUsersRoles().includes("addcreditqualificationrule(ocm)")) && (
                <Button
                  size="sm"
                  variant="primary"
                  disabled={updatingStatus}
                  onClick={togglePatchModal}
                >
                  <i className="far fa-edit mr-3" />
                  Modify Credit Qualification Rule
                </Button>
              )}
              {(getCurrentUsersRoles().includes("editloanproduct") || getCurrentUsersRoles().includes("editloanproduct(ocm)")) && (
                <Button
                  size="sm"
                  variant="primary"
                  disabled={updatingStatus}
                  onClick={toggleEditModal}
                >
                  <i className="far fa-edit mr-3" />
                  Edit
                </Button>
              )}
            </React.Fragment>
          )}

          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default LoanProductDetail;
