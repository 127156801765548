import http from "../httpService";

const recovaCreditAssessmentBaseAddress = process.env.REACT_APP_CREDITASSESSMENT_BASEADDRESS;

export function saveMessageTemplate(messageTemplate) {
  return http.post(recovaCreditAssessmentBaseAddress + `MessageTemplate`, messageTemplate);
}

export function retrieveAll() {
  return http.get(recovaCreditAssessmentBaseAddress + `messageTemplate`);
}

export function retrieveById(id) {
  return http.get(recovaCreditAssessmentBaseAddress + `messageTemplate/${id}`);
}

export function updateMessageTemplate(id, messageTemplate) {
  return http.put(recovaCreditAssessmentBaseAddress + `messageTemplate/${id}`, messageTemplate);
} 

// export function filterPagedScores(data, pageSize, pageNumber) {
//   return http.post(
//     recovaCreditAssessmentBaseAddress +
//       `messageTemplate/FilterScores?pageNumber=${pageNumber}&pageSize=${pageSize}`,
//     data
//   );
// }

export default {
  saveMessageTemplate,
  retrieveAll,
  retrieveById,
//   filterPagedScores,
  updateMessageTemplate,
};
