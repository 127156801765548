import React, { Component } from "react";
import _ from "lodash";
import { NonFinancial } from "./NonFinancial";
import { Financial } from "./Financial";

export interface IApprovableProps {
  rules: any;
  item: any;
  events?: any;
  viewing: boolean;
}

export interface IApprovableState {
  item: any;
  rules: any;
}
export class Approvable extends Component<IApprovableProps, IApprovableState> {
  constructor(props: IApprovableProps) {
    super(props);
    this.state = {
      rules: props.rules,
      item: props.item,
    };
    if (props.item.workflowType === "Financial" && !props.viewing) {
      // console.log(props.item.financialGroups)
      props.events.handleSetGroup(
        props.item.financialGroups[props.item.financialGroups.length - 1]
      );
    }
  }

  static defaultProps = {
    events: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log("In approvable next", nextProps);
    // console.log("In approvable prev", prevState);
    if (nextProps.item !== prevState.item) {
      return { rules: nextProps.rules, item: nextProps.item };
    } else return null;
  }

  render() {
    if(_.isEmpty(this.state.item)){
      return null;
    }
    var fin = this.state.item.workflowType === "Financial";
    // console.log(`Rendered approvable rulescount: ${this.state.item.rules.length}`, this.state.rules)
    if (fin) {
      return (
        <Financial
          rules={this.state.rules}
          item={this.state.item}
          events={this.props.events}
          viewing={this.props.viewing}
        />
      );
    }
    return (
      <NonFinancial
        rules={this.state.rules}
        item={this.state.item}
        child={false}
        events={this.props.events}
        viewing={this.props.viewing}
      />
    );
  }
}
