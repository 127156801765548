import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";

const recovaBankOneHandShakeBaseAddress = process.env.REACT_APP_RECOVA_BANKONE_HANDSHAKE_BASEADDRESS;

export function nameEnquiry(accountNumber: string, bankCode: string) {
    var url = recovaBankOneHandShakeBaseAddress + `Transaction/GetCommercialBankAccountName/10001/${bankCode}?accountNumber=${accountNumber
        }`;
    return http.get(url, {
        url: url,
        method: "get"
    });
}

export default {
    nameEnquiry
}