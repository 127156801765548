import React, { Fragment } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import TableFilterPane from "./../../../components/tableComponents/tableFilterPane";
import BaseFormComponent, { IBaseFormState } from "../../../components/BaseFormComponent";
import { ISelectItems } from "../../../components/detailItemSelectBox";
import SelectInputComponent from "../../../components/formSelectComponent";
import BaseListComponent, { BaseListComponentState, IFilterData } from "../../../components/BaseListComponent";
import ItemsTable, { filterFieldData } from "../../../components/tableComponents/itemsTable";
import { createFilterData, mapEntityList } from "../../../services/utility/autoMapperService";
import institutionService from "../../../services/auth/institutionsService";
import systemSettingsService from "../../../services/auth/systemSettingsService";
import MandateBillingStatusDetail from "./mandateBillingStatusDetail";
import { getCurrentUsersRoles } from "../../../services/auth/authService";
import EditMandateBillingStatus from "./editMandateBillingStatus";
import { ActionTypes } from "../../../enums/actionTypes";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

interface MandateBillingStatusFields {
  onInstitutions?: any;
  offInstitutions?: any;
}

class MandateBillingStatusForm extends BaseFormComponent<MandateBillingStatusFields> { }
interface IMandateBillingStatusProps { }
interface IMandateBillingStatusState extends BaseListComponentState {
  category: string;
  institution: any;
  institutions: any;
  fetchingInstitutions: boolean;
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
  mandateBillingStatus: string
}

class MandateBillingStatusList extends BaseListComponent<IMandateBillingStatusState> { }
class MandateBillingStatus extends React.Component<IMandateBillingStatusProps, IBaseFormState & IMandateBillingStatusState
> {
  constructor(props: IMandateBillingStatusProps) {
    super(props);
    this.state = {
      errors: {},
      validated: false,
      submitting: false,
      category: "",
      institution: {},
      institutions: [],
      fetchingInstitutions: false,
      mandateBillingStatus: "",

      //list items
      data: createFilterData([]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: true,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
    };
  }

  handleOnSelectCategory = async (id, value) => {
    try {
      this.setState({
        category: value,
        fetchingInstitutions: true,
        institution: {},
        mandateBillingStatus: ""
      });
      let inst = await institutionService.retrieveAllByCategory(value);
      this.setState({ institutions: inst.data, fetchingInstitutions: false });
      this.handlePageChange(this.state.currentPage, this.state.pageSize);
    } catch (error) {
      toast.info(error.response.data.errors[0], {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      this.setState({
        category: "",
        fetchingInstitutions: false,
      });
    }
  };

  handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true, fetchingInstitutions: true, });
    try {
      const institutionList = await institutionService.retrieveAllByCategory(this.state.category);
      var institutions: any = [];
      const totalLength = institutionList.data.length;
      const initial = (currentPage - 1) * pageSize;
      for (let i = initial; i < initial + pageSize; i++) {
        if (i === totalLength) {
          break;
        }
        institutions.push(institutionList.data[i]);
      }

      this.setState({
        tableItems: mapEntityList({
          entityList: institutions,
          properties: [
            { oldName: "name", newName: "name" },
            { oldName: "code", newName: "code" },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: totalLength,
        fetching: false,
        fetchingInstitutions: false,
      });
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        this.setState({ fetching: false, fetchingInstitutions: false });
      } else {
        this.setState({ fetching: false, fetchingInstitutions: false });
      }
    } finally {
      this.setState({ fetching: false, fetchingInstitutions: false });
    }
  };

  handleOnExport = async (type: String) => { };

  fetchItemById = async (item: any) => {
    this.setState({ fetchingDetail: true });
    var config = await systemSettingsService.retrieveSystemSettingsByInstitutionId(item.id);
    var institution = await institutionService.retrieveInstitutionById(item.id)
    this.setState({ item: config.data, institution: institution.data, fetchingDetail: false });
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  handleOnChangeMandateBillingStatus = async (id, value) => {
    this.setState({
      mandateBillingStatus: value
    })
  };

  onSubmit = async (e) => {
    try {
      this.setState({ submitting: true });
      const payload: any = {};

      var institutions = this.state.institutions;
      var institutionIdList = []

      institutions.forEach(function (institution) {
        institutionIdList.push(institution.id)
      });
      if (this.state.mandateBillingStatus == "OFF") {
        payload.turnOnInstitutions = [0];
        payload.turnOffInstitutions = institutionIdList;
      }
      else {
        payload.turnOnInstitutions = institutionIdList;
        payload.turnOffInstitutions = [0];
      }

      var response = await systemSettingsService.bulkUpdateMandateBillingStatusForInstitutions(payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Bulk Update Mandate Billing Status",
          description: `Bulk Update for Mandate Billing Status${this.state.category}`,
        });
        toast.info(`Mandate Billing Status for ${this.state.category} Edited successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      }
    } catch (error) {
      return false;
    } finally {
      this.setState({ submitting: false });
      return true;
    }
  };

  render() {
    const {
      category,
      submitting,
      fetchingDetail,
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      item,
      fetching,
      institution,
      mandateBillingStatus
    } = this.state;

    let institutionCategoryInputData: ISelectItems[] = [
      { name: "Commercial Bank", value: "CommercialBank" },
      { name: "BankOne OFI", value: "BankOneOFI" },
      { name: "OFI", value: "OFI" },
    ];

    let billingStatusInputData: ISelectItems[] = [
      { name: "ON", value: "ON" },
      { name: "OFF", value: "OFF" },
    ];

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Mandate Billing Status</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <MandateBillingStatusForm
                  initialValues={{
                    onInstitutions: [],
                    offInstitutions: []
                  }}
                  FormComponent={({
                    fields: { },
                  }) => (
                    <React.Fragment>
                      <div className="form-row">
                        <SelectInputComponent
                          id="institutionCategory"
                          name="institutionCategory"
                          divClass={6}
                          value={category}
                          items={institutionCategoryInputData}
                          required={true}
                          validated={false}
                          errors={[]}
                          onChange={this.handleOnSelectCategory}
                        />
                        {category == "" ? (
                          ""
                        ) : (
                          <Fragment>
                            <SelectInputComponent
                              id="billingStatus"
                              name="billingStatus"
                              divClass={3}
                              value={mandateBillingStatus}
                              items={billingStatusInputData}
                              required={true}
                              validated={false}
                              errors={[]}
                              onChange={this.handleOnChangeMandateBillingStatus}
                            />
                            <Modal.Footer>
                              <Button
                                size="sm"
                                className="mt-3 btn btn-sm btn-primary"
                                variant="secondary"
                                onClick={(e) => { this.onSubmit(e) }}
                                disabled={submitting || mandateBillingStatus === ''}
                              >
                                {submitting === false ? (
                                  <React.Fragment>
                                    <i className="fas fa-lg fa-save mr-3" /> Save
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <i className="fas fa-spin fa-circle-notch" />
                                  </React.Fragment>
                                )}
                              </Button>
                            </Modal.Footer>
                          </Fragment>
                        )}
                      </div>
                    </React.Fragment>
                  )}
                ></MandateBillingStatusForm>
                <br />
                <div>
                  {_.isEmpty(category) ? (
                    ""
                  ) : (
                    <React.Fragment>
                      <div className="card no-b">
                        <div className="card-body">
                          <div className="row my-3 mx-2">
                            <h5>
                              Mandate Billing Status -{" "}
                              <span className="font-weight-bold">
                                {category}
                              </span>
                            </h5>
                          </div>
                          <MandateBillingStatusList
                            pageChangeHandler={this.handlePageChange}
                            fetchingDetail={fetchingDetail}
                            initialValues={{
                              data: createFilterData([]) as IFilterData,
                              fetching: false,
                              showDetailsModal: false,
                              showEditModal: false,
                              showTableFilterModal: false,
                              showTrailModal: false,
                              tableItems: [],
                              filterValueInput: [],
                              item: {},
                              jsonBefore: {},
                              jsonAfter: {},
                              fetchingTrail: false,
                            }}
                            tableItems={tableItems}
                            item={item}
                            DetailsModal={({
                              item,
                              onToggleDetailsModal,
                              onToggleEditModal,
                              showDetailsModal,
                              showEditModal,
                              onToggleTrailModal,
                            }) => (
                              <Fragment>
                                <MandateBillingStatusDetail
                                  showDetailsModal={showDetailsModal}
                                  toggleDetailsModal={onToggleDetailsModal}
                                  toggleEditModal={onToggleEditModal}
                                  showTrail={() => onToggleTrailModal(item)}
                                  currentUserRoles={getCurrentUsersRoles()}
                                  item={item}
                                  institution={institution}
                                />
                                <EditMandateBillingStatus
                                  showEditModal={showEditModal}
                                  toggleEditModal={(reloadDetails: boolean, item: any) => {
                                    onToggleEditModal();
                                    if (reloadDetails === true) {
                                      this.fetchItemById(item);
                                    }
                                    this.setState({
                                      showDetailsModal: !this.state.showDetailsModal,
                                    });
                                  }}
                                  item={item}
                                  institution={institution}
                                />
                              </Fragment>
                            )}
                            ListPayLoad={({
                              data,
                              showTableFilterModal,
                              tableItems,
                              onToggleDetailsModal,
                              onToggleTableFilterModal,
                              onHandleFilterItemRemove,
                              filterValueInput,
                            }) => (
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="card no-b">
                                    <div className="card-body">
                                      <div className="card-title">
                                        <div id="list-filters" className="ml-4 mr-4">
                                          <TableFilterPane
                                            filteredItems={this.getFilteredItems(data)}
                                            handleFilterRemove={onHandleFilterItemRemove}
                                          />
                                        </div>
                                        <div id="list-table">
                                          <ItemsTable
                                            data={data}
                                            items={tableItems}
                                            filterValueInput={filterValueInput}
                                            showTableFiltermodal={showTableFilterModal}
                                            toggleTableFiltermodal={onToggleTableFilterModal}
                                            name="Mandate Billing Status"
                                            currentPage={currentPage}
                                            pageSize={pageSize}
                                            totalSize={totalSize}
                                            fetching={fetching}
                                            handlePageChange={this.handlePageChange}
                                            onViewDetails={(item: any) => {
                                              this.fetchItemById(item);
                                              onToggleDetailsModal();
                                            }}
                                            handleOnExport={this.handleOnExport}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          ></MandateBillingStatusList>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment >
    );
  }
}

export default MandateBillingStatus;