import React, { useState } from "react";
import mandateService from "../../../../services/_axiosServices/sweep/mandate";
import { Loader, Error } from "../../components";

const initialData = {
  bvn: "--",
  beneficiaryName: "--",
  loanAccountInstitution: "--",
  name: "--",
  phoneNumber: "--",
  guaranteeingPercentage: 0,
  loanAccountNo: "--",
  totalNoOfTimesDeducted: 0,
  totalAmountDeducted: 0,
  lastAmountDeducted: 0,
  lastDeductionDate: "--",
  noOfReversals: 0,
  lastReversalDate: "--",
  id: 0,
};

export default function Report() {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [ref, setRef] = useState("");
  const [bvn, setBvn] = useState("");
  const [data, setData] = useState(initialData);
  const [error, setError] = useState(false);

  const fetchData = async () => {
    if (code.length < 1 || ref.length < 1 || bvn.length < 1) {
      return;
    }
    setLoading(true);
    await mandateService
      .getSecondaryMandateInfo(code, ref, bvn)
      .then((res: any) => {
        // console.log(res);
        setLoading(false);
        setData(res.data);
      })
      .catch((err: any) => {
        setError(false);
        setLoading(false);
        console.log(err.message);
      });
  };

  return (
    <div className="container-fluid relative animatedParent animateOnce">
      <div className="animated fadeInUpShort go">
        <div className="row my-3 mx-2">
          <h3>
            <b>Get Secondary Mandate Info</b>
          </h3>
        </div>
        <div className="card no-b">
          <div className="card-body">
            <div className="card-title">
              <div className="page">
                <div className="page-inner">
                  <div className="btn-toolbar justify-content-between">
                    <div className="btn-group">
                      <input
                        name="institutionCode"
                        onChange={(e) => setCode(e.target.value)}
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Institution Code"
                        required
                      />
                      <input
                        name="loanReference"
                        onChange={(e) => setRef(e.target.value)}
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Loan Reference"
                        required
                      />
                      <input
                        name="bvn"
                        onChange={(e) => setBvn(e.target.value)}
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="BVN"
                        required
                      />
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={fetchData}
                      >
                        Go
                      </button>
                    </div>
                  </div>

                  <div className="table-responsive text-nowrap">
                    {loading && <Loader />}

                    {!loading && !error && (
                      <table className="table table-hover table-content table-striped table-bordered table-sm paged-output">
                      <thead>
                        <tr>
                          <th>BVN</th>
                          <th>Beneficiary Name</th>
                          <th>Loan Account Institution</th>
                          <th>Name</th>
                          <th>Phone Number</th>
                          <th>Guaranteeing Percentage</th>
                          <th>Loan Account No</th>
                          <th>Total No Of Times Deducted</th>
                          <th>Total Amount Deducted</th>
                          <th>Last Amount Deducted</th>
                          <th>Last Deduction Date</th>
                          <th>No Of Reversals</th>
                          <th>Last Reversal Date</th>
                          <th>id</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{data.bvn}</td>
                          <td>{data.beneficiaryName}</td>
                          <td>{data.loanAccountInstitution}</td>
                          <td>{data.name}</td>
                          <td>{data.phoneNumber}</td>
                          <td>{data.guaranteeingPercentage}</td>
                          <td>{data.loanAccountNo}</td>
                          <td>{data.totalNoOfTimesDeducted}</td>
                          <td>{data.totalAmountDeducted}</td>
                          <td>{data.lastAmountDeducted}</td>
                          <td>{data.lastDeductionDate}</td>
                          <td>{data.noOfReversals}</td>
                          <td>{data.lastReversalDate}</td>
                          <td>{data.id}</td>
                        </tr>
                      </tbody>
                    </table>)}

                    {error && !loading && <Error />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
