import http from "../httpService";

const appraisalBaseAddress = process.env.REACT_APP_HYBRIDAPPRAISAL_BASEADDRESS;

export function createAppraisalAction(payload) {
  return http.post(
    appraisalBaseAddress + `AppraisalAction/CreateAppraisalAction`,
    payload
  );
}

export function performUpdateAction(payload) {
  return http.put(
    appraisalBaseAddress + `AppraisalAction/PerformUpdateAction`,
    payload
  );
}

export function performPushBackAction(payload) {
  return http.post(
    appraisalBaseAddress + `AppraisalAction/PerformPushBackAction`,
    payload
  );
}

export function retrieveById(id) {
  return http.get(
    appraisalBaseAddress + `AppraisalAction/RetrieveById/${id}`
  );
}

export function RunSystemProcess(payload) {
  return http.post(
    appraisalBaseAddress + `AppraisalAction/RunSystemProcess`,
    payload
  );
}

export default {
  createAppraisalAction,
  performUpdateAction,
  retrieveById,
  performPushBackAction,
  RunSystemProcess
};
