import http from "../httpService";
import { getCurrentUserSession } from "./authService";
// import { decodeToken } from "react-jwt";
//import config from "./../../appSettings";

const authBaseAddress = process.env.REACT_APP_AUTH_BASEADDRESS;
export function getAllFunctions() {
  // const decodedToken = decodeToken(getCurrentUserSession().token || "");
  var url = authBaseAddress +
    `userrolefunctions/getall`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveAllFunctionsByInstitution() {
  // const decodedToken = decodeToken(getCurrentUserSession().token || "");
  var url = authBaseAddress +
    `userrolefunctions/RetrieveAllByInstitution`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveAllFunctions() {
  // const decodedToken = decodeToken(getCurrentUserSession().token || "");
  var url = authBaseAddress +
    `userrolefunctions/retrieveall`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export async function saveUserRoleFunction(payload) {
  var url = authBaseAddress + `userrolefunctions`;
  return http.post(url, payload, {
    url: url,
    data: payload,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
} 

export default {
  getAllFunctions,
  retrieveAllFunctionsByInstitution,
  retrieveAllFunctions,
  saveUserRoleFunction
};
