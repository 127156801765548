import * as React from "react";
import _ from "lodash";
import { Fragment } from "react";
import {
  IFilterData,
  BaseListComponentState,
} from "../../../components/BaseListComponent";
import { printPDF } from "../../../services/utility/textFormatService";
import {
  createFilterData,
  mapEntityList,
} from "./../../../services/utility/autoMapperService";
import BaseListComponent from "./../../../components/BaseListComponent";
import mandateServiceTypeService from "../../../services/mandate/mandateServiceTypeService";
import ItemsTable, {
  filterFieldData,
} from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "./../../../components/tableComponents/tableFilterPane";
import {
  getCurrentUserSession,
  getCurrentUsersRoles,
} from "../../../services/auth/authService";
import { TransactionAmountSettings } from "../../../enums/recovaMandate/transactionAmountSettings";
import { CummulativeDeductionConfig } from "../../../enums/recovaMandate/cummulativeDeductionConfig";
import { MandateDurationConfig } from "../../../enums/recovaMandate/mandateDuration";
import { PermissibleDeductionConfig } from "../../../enums/recovaMandate/permissibleDeductionConfig";
import MandateServiceTypeDetail from "./mandateServiceTypeDetail";
import AuditTrailDetail from "../../auditTrail/auditTrailDetail";
import EditMandateServiceType from "./editMandateServiceType";
import { decodeToken } from "react-jwt";

export interface MandateServiceTypesProps {}

export interface MandateServiceTypesState extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
}

class MandateServiceTypesList extends BaseListComponent<MandateServiceTypesState> {}

class MandateServiceTypes extends React.Component<
  MandateServiceTypesProps,
  MandateServiceTypesState
> {
  constructor(props: MandateServiceTypesProps) {
    super(props);
    this.state = {
      data: createFilterData(["name", "status"]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [
        { name: "name", fieldType: "text", value: "" },
        {
          name: "status",
          fieldType: "select",
          options: ["Active", "Inactive"],
          value: "",
        },
      ],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
    };
  }

  handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true });
    data = typeof data === "undefined" ? this.state.data : data;
    try {
      const mandateServiceTypes =
        await mandateServiceTypeService.filterMandateServiceTypes(
          data,
          pageSize,
          currentPage
        );
      // console.log("data fetched", mandateServiceTypes);
      this.setState({
        tableItems: mapEntityList({
          entityList: mandateServiceTypes.data.item2,
          properties: [
            { oldName: "name", newName: "name" },
            {
              isTag: true,
              oldName: "transactionAmountCategory",
              newName: "transactionAmountSettings",
              success: "success",
              danger: "danger",
              default:
                TransactionAmountSettings[TransactionAmountSettings.Fixed],
              primary: "primary",
              secondary: "secondary",
              warning:
                TransactionAmountSettings[TransactionAmountSettings.Variable],
              light: "light",
              dark: "dark",
            },
            {
              isTag: true,
              oldName: "cumulativeDeductionConfig",
              newName: "cumulativeDeductionConfig",
              success: "success",
              danger: "danger",
              default:
                CummulativeDeductionConfig[CummulativeDeductionConfig.Fixed],
              primary: "primary",
              secondary: "secondary",
              warning:
                CummulativeDeductionConfig[CummulativeDeductionConfig.Variable],
              light: "light",
              dark: "dark",
            },
            {
              isTag: true,
              oldName: "mandateDurationConfig",
              newName: "mandateDurationConfig",
              success: "success",
              danger: "danger",
              default: MandateDurationConfig[MandateDurationConfig.Limited],
              primary: "primary",
              secondary: "secondary",
              warning: MandateDurationConfig[MandateDurationConfig.Unlimited],
              light: "light",
              dark: "dark",
            },
            {
              isTag: true,
              oldName: "permissibleDeductionConfig",
              newName: "permissibleDeductionConfig",
              success: "success",
              danger: "danger",
              default:
                PermissibleDeductionConfig[PermissibleDeductionConfig.Limited],
              primary: "primary",
              secondary: "secondary",
              warning:
                PermissibleDeductionConfig[
                  PermissibleDeductionConfig.Unlimited
                ],
              light: "light",
              dark: "dark",
            },
            {
              isTag: true,
              oldName: "status",
              newName: "status",
              success: "Active",
              danger: "Inactive",
              default: "default",
              primary: "primary",
              secondary: "secondary",
              warning: "warning",
              light: "light",
              dark: "dark",
            },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: mandateServiceTypes.data.item1,
        fetching: false,
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
        // this.setState({ errors, fetching: false });
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        // this.setState({ errors, fetching: false });
      }
    } finally {
      this.setState({ fetching: false });
    }
  };

  handleOnExport = async (type: string) => {
    try {
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      // console.log("about to export: ", type);
      switch (type) {
        case "XLSX":
        case "CSV":
          return await mandateServiceTypeService.downloadMandateServiceTypeReport(
            decodedToken?.["InstitutionCode"],
            type
          );
        case "PDF":
          const response =
            await mandateServiceTypeService.downloadMandateServiceTypeReport(
              decodedToken?.["InstitutionCode"],
              type
            );
          printPDF(response?.data);
          break;
        default:
          break;
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        //this.setState({ errors, fetching: false });
      }
    }
  };

  fetchItemById = async (item: any) => {
    // await activityTrailService.saveActivityTrail({
    //   actionType: "View Details",
    //   description: `Viewed details of MandateServiceType ${item.name}`
    // });
    this.setState({ fetchingDetail: true });
    // console.log("fetching mandateServiceType with id: ", item.id);
    const mandateServiceType =
      await mandateServiceTypeService.retrieveMandateServiceTypeById(item.id);
    // console.log("mandateServiceType: ", mandateServiceType.data);
    this.setState({ item: mandateServiceType.data, fetchingDetail: false });
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }

  render() {
    const {
      // data,
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Mandate Service Types</b>
              </h3>
            </div>
            <MandateServiceTypesList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              initialValues={{
                data: createFilterData(["name", "status"]) as IFilterData,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: [
                  { name: "name", fieldType: "text", value: "" },
                  {
                    name: "status",
                    fieldType: "select",
                    options: ["Active", "Inactive"],
                    value: "",
                  },
                ],
                item: {},
                jsonBefore: {},
                jsonAfter: {},
                fetchingTrail: false,
                entityName: "Recova.Mandate.MandateServiceType",
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                onToggleEditModal,
                showDetailsModal,
                showEditModal,
                fetchTrailItem,
                fetchingTrail,
                jsonAfter,
                jsonBefore,
                onToggleTrailModal,
                showTrailModal,
                trailItem,
                trailMapper,
              }) => (
                <Fragment>
                  <MandateServiceTypeDetail
                    showDetailsModal={showDetailsModal}
                    toggleDetailsModal={onToggleDetailsModal}
                    toggleEditModal={onToggleEditModal}
                    showTrail={() => onToggleTrailModal(item)}
                    currentUserRoles={getCurrentUsersRoles()}
                    item={item}
                  />
                  <EditMandateServiceType
                    showEditModal={showEditModal}
                    toggleEditModal={(reloadDetails: boolean, item: any) => {
                      onToggleEditModal();
                      if (reloadDetails === true) {
                        this.fetchItemById(item);
                      }
                      this.setState({
                        showDetailsModal: !this.state.showDetailsModal,
                      });
                    }}
                    item={item}
                  />
                  <AuditTrailDetail
                    showDetailsModal={showTrailModal}
                    toggleDetailsModal={onToggleTrailModal}
                    fetchNextTrail={fetchTrailItem}
                    item={trailItem}
                    trailMapper={trailMapper}
                    fetchingDetail={fetchingTrail}
                    jsonBefore={jsonBefore}
                    jsonAfter={jsonAfter}
                  />
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="MandateServiceTypes"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></MandateServiceTypesList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default MandateServiceTypes;
