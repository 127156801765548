import React from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import billingValidationService from "../../../services/billing/billingValidationService";
import { ISelectItems } from "../../../components/detailItemSelectBox";
import FormInputComponent from "../../../components/formInputComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import SelectInputComponent from "../../../components/formSelectComponent";
import thirdPartyFeeConfigService from "../../../services/billing/thirdPartyFeeConfigService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import institutionFeeConfigService from "../../../services/billing/institutionFeeConfigService";
import sweepConfigService from "../../../services/sweep/sweepConfigService";
import institutionsService from "../../../services/auth/institutionsService";

interface AddThirdPartyCentralFeeConfigurationFields {
  institutionCategory: string;
  serviceOffering: string;
  thirdParty: string;
  appzonePercentageAmountPerActiveLoan: string;
  appzonePercentageAmountPerLostLoan: string;
  appzoneAmountFlatPerSuccessfulTrx: string;
  appzoneMinimumFeePerSuccessfulTrx: string;
  appzoneMaximumFeePerSuccessfulTrx: string;
  thirdPartyPercentageAmountSwept: string;
  thirdPartyFlatAmountPerSuccessfulTrx: string;
  thirdPartyMinimumFeePerSuccessfulTrx: string;
  thirdPartyMaximumFeePerSuccessfulTrx: string;
}

class AddThirdPartyCentralFeeConfigurationForm extends BaseFormComponent<AddThirdPartyCentralFeeConfigurationFields> { }

interface IAddThirdPartyCentralFeeConfigurationProps { }
interface IAddThirdPartyCentralFeeConfigurationState { }

class AddThirdPartyCentralFeeConfiguration extends React.Component<
  IAddThirdPartyCentralFeeConfigurationProps,
  IBaseFormState & IAddThirdPartyCentralFeeConfigurationState
> {
  constructor(props: IAddThirdPartyCentralFeeConfigurationProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
    };
  }

  onFormSubmit(fields: AddThirdPartyCentralFeeConfigurationFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        { errors: billingValidationService.validateAddThirdPartyCentralFeeConfigurationForm(fields) },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddThirdPartyCentralFeeConfigurationFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true });
      const payload: any = {};

      payload.serviceOffering = fields.serviceOffering;
      payload.institutionCategory = fields.institutionCategory;
      payload.thirdParty = fields.thirdParty;
      payload.appzonePercentageAmountPerActiveLoan = Number(fields.appzonePercentageAmountPerActiveLoan);
      payload.appzonePercentageAmountPerLostLoan = Number(fields.appzonePercentageAmountPerLostLoan);
      payload.appzoneAmountFlatPerSuccessfulTrx = Number(fields.appzoneAmountFlatPerSuccessfulTrx);
      payload.appzoneMinimumFeePerSuccessfulTrx = Number(fields.appzoneMinimumFeePerSuccessfulTrx);
      payload.appzoneMaximumFeePerSuccessfulTrx = Number(fields.appzoneMaximumFeePerSuccessfulTrx);
      payload.thirdPartyPercentageAmountSwept = Number(fields.thirdPartyPercentageAmountSwept);
      payload.thirdPartyFlatAmountPerSuccessfulTrx = Number(fields.thirdPartyFlatAmountPerSuccessfulTrx);
      payload.thirdPartyMinimumFeePerSuccessfulTrx = Number(fields.thirdPartyMinimumFeePerSuccessfulTrx);
      payload.thirdPartyMaximumFeePerSuccessfulTrx = Number(fields.thirdPartyMaximumFeePerSuccessfulTrx);
      // console.log("payload: ", payload);

      var feeConfig = await thirdPartyFeeConfigService.filterThirdPartyFeeConfig(fields.serviceOffering, fields.institutionCategory, fields.thirdParty);
      if ((feeConfig.data).length !== 0) {
        toast.error(`ThirdParty Fee Configuration for ${fields.institutionCategory} (${fields.serviceOffering}) already exist`, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      else {
        const response = await thirdPartyFeeConfigService.saveThirdPartyFeeConfig(payload);
        if (response.status >= 200 && response.status <= 300) {
          await activityTrailService.saveActivityTrail({
            actionType: "Add ThirdParty Fee Configuration",
            description: `Added new ThirdParty Fee Configuration ${fields.institutionCategory} ${fields.thirdParty}`,
          });
          toast.info(`ThirdParty Fee Configuration for ${fields.institutionCategory} - ${fields.serviceOffering} created successfully!`, {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          });
        }
        this.setState({ submitting: false });

        //save new thirdParty for all institutions
        const institutionPayload: any = {};
        const institution = (await sweepConfigService.retrieveAllSweepConfig()).data;
        for (let i = 0; i < institution.length; i++) {
          var category = (await institutionsService.retrieveInstitutionByCode(institution[i].code)).data.category;
          if (category === fields.institutionCategory) {
            institutionPayload.serviceOffering = fields.serviceOffering;
            institutionPayload.institutionCode = institution[i].code;
            institutionPayload.thirdParty = fields.thirdParty;
            institutionPayload.appzonePercentageAmountPerActiveLoan = Number(fields.appzonePercentageAmountPerActiveLoan);
            institutionPayload.appzonePercentageAmountPerLostLoan = Number(fields.appzonePercentageAmountPerLostLoan);
            institutionPayload.appzoneAmountFlatPerSuccessfulTrx = Number(fields.appzoneAmountFlatPerSuccessfulTrx);
            institutionPayload.appzoneMinimumFeePerSuccessfulTrx = Number(fields.appzoneMinimumFeePerSuccessfulTrx);
            institutionPayload.appzoneMaximumFeePerSuccessfulTrx = Number(fields.appzoneMaximumFeePerSuccessfulTrx);
            institutionPayload.thirdPartyPercentageAmountSwept = Number(fields.thirdPartyPercentageAmountSwept);
            institutionPayload.thirdPartyFlatAmountPerSuccessfulTrx = Number(fields.thirdPartyFlatAmountPerSuccessfulTrx);
            institutionPayload.thirdPartyMinimumFeePerSuccessfulTrx = Number(fields.thirdPartyMinimumFeePerSuccessfulTrx);
            institutionPayload.thirdPartyMaximumFeePerSuccessfulTrx = Number(fields.thirdPartyMaximumFeePerSuccessfulTrx);
            institutionFeeConfigService.saveInstitutionFeeConfig(institutionPayload);
          }
        }
        onReloadFieldsCB();
      }
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
    } finally {
      this.setState({ submitting: false });
      return true;
    }
  }

  render() {
    const { errors, validated } = this.state;

    let institutionCategoryInputData: ISelectItems[] = [
      { name: "Commercial Bank", value: "CommercialBank" },
      { name: "BankOne OFI", value: "BankOneOFI" },
      { name: "OFI", value: "OFI" },
    ];

    let serviceOfferingInputData: ISelectItems[] = [
      { name: "Sweep", value: "Sweep" },
      { name: "Collections", value: "Collections" },
    ];

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add ThirdParty Central Fee Configuration</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddThirdPartyCentralFeeConfigurationForm
                  initialValues={{
                    serviceOffering: "",
                    institutionCategory: "",
                    thirdParty: "",
                    appzonePercentageAmountPerActiveLoan: "",
                    appzonePercentageAmountPerLostLoan: "",
                    appzoneAmountFlatPerSuccessfulTrx: "",
                    appzoneMinimumFeePerSuccessfulTrx: "",
                    appzoneMaximumFeePerSuccessfulTrx: "",
                    thirdPartyPercentageAmountSwept: "",
                    thirdPartyFlatAmountPerSuccessfulTrx: "",
                    thirdPartyMinimumFeePerSuccessfulTrx: "",
                    thirdPartyMaximumFeePerSuccessfulTrx: ""
                  }}
                  FormComponent={({
                    fields: {
                      serviceOffering,
                      institutionCategory,
                      thirdParty,
                      appzonePercentageAmountPerActiveLoan,
                      appzonePercentageAmountPerLostLoan,
                      appzoneAmountFlatPerSuccessfulTrx,
                      appzoneMinimumFeePerSuccessfulTrx,
                      appzoneMaximumFeePerSuccessfulTrx,
                      thirdPartyPercentageAmountSwept,
                      thirdPartyFlatAmountPerSuccessfulTrx,
                      thirdPartyMinimumFeePerSuccessfulTrx,
                      thirdPartyMaximumFeePerSuccessfulTrx
                    },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-row">
                                <SelectInputComponent
                                  id="institutionCategory"
                                  name="institutionCategory"
                                  divClass={6}
                                  value={institutionCategory}
                                  items={institutionCategoryInputData}
                                  required={true}
                                  validated={false}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <SelectInputComponent
                                  id="serviceOffering"
                                  name="serviceOffering"
                                  divClass={6}
                                  value={serviceOffering}
                                  items={serviceOfferingInputData}
                                  required={true}
                                  validated={false}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-row">
                                <FormInputComponent
                                  id="thirdParty"
                                  type="text"
                                  name="thirdParty"
                                  placeholder=""
                                  divClass={6}
                                  value={thirdParty}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="appzonePercentageAmountPerActiveLoan"
                                  type="number"
                                  name="appzonePercentageAmountPerActiveLoan"
                                  placeholder=""
                                  divClass={6}
                                  value={appzonePercentageAmountPerActiveLoan}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-row">
                                <FormInputComponent
                                  id="appzonePercentageAmountPerLostLoan"
                                  type="number"
                                  name="appzonePercentageAmountPerLostLoan"
                                  placeholder=""
                                  divClass={6}
                                  value={appzonePercentageAmountPerLostLoan}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="appzoneAmountFlatPerSuccessfulTrx"
                                  type="number"
                                  name="appzoneAmountFlatPerTransaction"
                                  placeholder=""
                                  divClass={6}
                                  value={appzoneAmountFlatPerSuccessfulTrx}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-row">
                                <FormInputComponent
                                  id="appzoneMinimumFeePerSuccessfulTrx"
                                  type="number"
                                  name="appzoneMinimumFeePerTransaction"
                                  placeholder=""
                                  divClass={6}
                                  value={appzoneMinimumFeePerSuccessfulTrx}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="appzoneMaximumFeePerSuccessfulTrx"
                                  type="number"
                                  name="appzoneMaximumFeePerTransaction"
                                  placeholder=""
                                  divClass={6}
                                  value={appzoneMaximumFeePerSuccessfulTrx}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-row">
                                <FormInputComponent
                                  id="thirdPartyPercentageAmountSwept"
                                  type="number"
                                  name="thirdPartyPercentageAmountSwept"
                                  placeholder=""
                                  divClass={6}
                                  value={thirdPartyPercentageAmountSwept}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="thirdPartyFlatAmountPerSuccessfulTrx"
                                  type="number"
                                  name="thirdPartyFlatAmountPerTransaction"
                                  placeholder=""
                                  divClass={6}
                                  value={thirdPartyFlatAmountPerSuccessfulTrx}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-row">
                                <FormInputComponent
                                  id="thirdPartyMinimumFeePerSuccessfulTrx"
                                  type="number"
                                  name="thirdPartyMinimumFeePerTransaction"
                                  placeholder=""
                                  divClass={6}
                                  value={thirdPartyMinimumFeePerSuccessfulTrx}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="thirdPartyMaximumFeePerSuccessfulTrx"
                                  type="number"
                                  name="thirdPartyMaximumFeePerTransaction"
                                  placeholder=""
                                  divClass={6}
                                  value={thirdPartyMaximumFeePerSuccessfulTrx}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="card-body">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary"
                          disabled={this.state.submitting}
                          onClick={(e) => {
                            onHandleSubmit(
                              e,
                              ActionTypes.SAVE,
                              "ThirdParty Fee Configuration",
                              () => {
                                this.onFormSubmit(
                                  {
                                    serviceOffering,
                                    institutionCategory,
                                    thirdParty,
                                    appzonePercentageAmountPerActiveLoan,
                                    appzonePercentageAmountPerLostLoan,
                                    appzoneAmountFlatPerSuccessfulTrx,
                                    appzoneMinimumFeePerSuccessfulTrx,
                                    appzoneMaximumFeePerSuccessfulTrx,
                                    thirdPartyPercentageAmountSwept,
                                    thirdPartyFlatAmountPerSuccessfulTrx,
                                    thirdPartyMinimumFeePerSuccessfulTrx,
                                    thirdPartyMaximumFeePerSuccessfulTrx
                                  },
                                  onReloadFields
                                );
                              }
                            );
                          }}
                        >
                          {this.state.submitting === false ? (
                            <React.Fragment>
                              <i className="fas fa-lg fa-save mr-3" /> Save
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                              Please wait...
                            </React.Fragment>
                          )}
                        </button>
                      </div>
                    </form>
                  )}
                ></AddThirdPartyCentralFeeConfigurationForm>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment >
    );
  }
}

export default AddThirdPartyCentralFeeConfiguration;