import React, { Component, Fragment, useContext } from "react";
import {
    Modal,
    Button,
    Card,
    useAccordionToggle,
    AccordionContext,
    Accordion,
} from "react-bootstrap";
import _, { max } from "lodash";
import Skeleton from "react-loading-skeleton";
import BaseFormComponent, { IBaseFormState } from "../../../../components/BaseFormComponent";
import textFormatService from "../../../../services/utility/textFormatService";
import ItemsTable, { filterFieldData } from "../../../../components/tableComponents/itemsTable";
import TableFilterPane from "../../../../components/tableComponents/tableFilterPane";
import { getCurrentUserSession } from "../../../../services/auth/authService";
// import TinRequestDetails from "../../../../loanAppraisal/appraisalDetailPages/TinRequestDetails";
// import { ContextAwareToggle } from "./documentCollection";
import { decodeToken } from "react-jwt";
import BaseListComponent, { IFilterData } from "../../../../components/BaseListComponent";
import loanProductService from "../../../../services/hybridAppraisal/loanProductService";
import loanRequestService from "../../../../services/hybridAppraisal/loanRequestService";
import { createFilterData, mapEntityList } from "../../../../services/utility/autoMapperService";
import TinValidationDetailsModal from "./tinValidationModal";
import GroupPolicyScoringCheck from "./policyScoringResult";
import groupLoanRequestMembersService from "../../../../services/hybridAppraisal/groupLoanRequestMembersService";
import { toast } from "react-toastify";
import DetailItemsTable, { TableContext } from "../../../../components/tableComponents/detailItemsTable";

interface PolicyScoringListModalFields { }

class PolicyScoringListModalForm extends BaseFormComponent<PolicyScoringListModalFields> { }

interface IPolicyScoringListModalProps {
    item: any;
    members: any;
    showPatchModal: boolean;
    togglePatchModal: any;
    userRoles: any;
    systemProcess: any;
    currentUserSession: any;
}

interface IPolicyScoringListModalState {
    item: any;
    updatingStatus: boolean;
    submitting: boolean;
    data: IFilterData;
    currentPage: number;
    pageSize: number;
    tableItems: any;
    fetching: boolean;
    fetchingDetail: boolean;
    totalSize: number;
    filterValueInput: filterFieldData;
    userRoles: any;
    systemProcess: any;
    applicableRules: any;
    appraisalActions: any;
    updateComponent: boolean;
    documentsToUpload: any;
    documentsToDelete: number[];
    canEditItem: boolean;
    updateAvailable: boolean;
    processResponse: any;
    specialConditions: any;
    ownUpdate: boolean;
    showPolicyScoringResult: boolean;
    members: any;
    member: any;
}

class PolicyScoringListModalList extends BaseListComponent<IPolicyScoringListModalState> { }

class PolicyScoringListModal extends React.Component<
    IPolicyScoringListModalProps,
    IBaseFormState & IPolicyScoringListModalState
> {
    _isMounted = false;
    constructor(props: IPolicyScoringListModalProps) {
        super(props);
        this.state = {
            validated: false,
            submitting: false,
            errors: {},
            item: {},
            updatingStatus: false,
            data: createFilterData(["customerName", "loanType"]) as IFilterData,
            currentPage: 1,
            pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
            fetching: false,
            fetchingDetail: false,
            tableItems: [],
            totalSize: 0,
            filterValueInput: [
                { name: "customerName", fieldType: "text", value: "" },
                {
                    name: "loanType",
                    fieldType: "select",
                    options: ["Individual", "Corporate"],
                    value: "",
                }
            ],
            userRoles: [],
            systemProcess: [],
            applicableRules: [],
            appraisalActions: [],
            documentsToUpload: [],
            documentsToDelete: [],
            updateComponent: false,
            canEditItem: false,
            updateAvailable: false,
            specialConditions: [],
            processResponse: {
                actionId: 0,
                score: 0,
                response: ""
            },
            ownUpdate: false,
            showPolicyScoringResult: false,
            members: [],
            member: {}
        };
    }

    resetFields() {
        this.setState({
            validated: false,
            submitting: false
        });
    }

    async componentDidMount() {
        this._isMounted = true
        this.handlePageChange(this.state.currentPage, this.state.pageSize)
    }
    componentWillUnmount() {
        this._isMounted = false
    }

    approveBatchAppraisal = async () => {
        // console.log(id);
        const Members = [...this.state.members];

        const failedMembers = Members.filter(x => x.memberStatus === "Ineligible");

        const payload: number[] = [];

        failedMembers.forEach(x => {
            payload.push(x.id)
        })

        // console.log(failedMembers)

        const response = await groupLoanRequestMembersService.BatchApproveGroupLoanRequestMembers(payload);
        if (response.status >= 200 && response.status <= 300) {
            toast.info(`Members Approved!`, {
                autoClose: 6000,
                type: toast.TYPE.DEFAULT,
                hideProgressBar: false,
            });
        } else {
            return false;
        }


    };

    handlePageChange = async (currentPage: number, pageSize: number, data?: IFilterData) => {
        currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
        pageSize = pageSize < 0 ? this.state.pageSize : pageSize;

        const decodedToken = decodeToken(getCurrentUserSession().token || "");

        try {
            const appraisalActions = this.props.item.appraisalActions;
            const solidarityGroupId = this.props.members[0]?.solidarityGroupId
            const scoreCardMembers = appraisalActions.filter(x =>
                x.systemProcessID === Number(5) && x.effectiveStatus === "Valid"
            ).reduce((prev, current) => {
                return current.dateCreated > prev.dateCreated ? current : prev;
            })?.memberProcessResponses
            const policyMembers = appraisalActions.filter(x => x.systemProcessID === Number(2) && x.effectiveStatus === "Valid").reduce((prev, current) => {
                return current.dateCreated > prev.dateCreated ? current : prev;
            }).memberProcessResponses
            const scoringPolicyMembersList: any = [];

            // console.log(solidarityGroupId)
            const groupMembers = await groupLoanRequestMembersService.retrieveBySolidarityGroup(solidarityGroupId)
            const groupMembersList = groupMembers.data

            if (groupMembersList) {
                groupMembersList.forEach(x => {
                    const scoringPolicyMembers: any = {};

                    scoringPolicyMembers.id = x.id
                    scoringPolicyMembers.title = x.title
                    scoringPolicyMembers.customerName = x.firstName + " " + x.lastName
                    scoringPolicyMembers.policyResult = policyMembers.filter(y => y.memberId === x.id)[0]?.result
                    scoringPolicyMembers.scoringResult = scoreCardMembers.filter(y => y.memberId === x.id)[0]?.result
                    try {
                        scoringPolicyMembers.policyResponse = JSON.parse(policyMembers.filter(y => y.memberId === x.id)[0]?.response);
                    } catch (e) {
                        scoringPolicyMembers.policyResponse = null;
                    }
                    try {
                        scoringPolicyMembers.scoringResponse = JSON.parse(scoreCardMembers.filter(y => y.memberId === x.id)[0]?.response)
                    } catch (e) {
                        scoringPolicyMembers.scoringResponse = null;
                    }
                    scoringPolicyMembers.policyScoringFail = scoringPolicyMembers.policyResult === "Pass" && scoringPolicyMembers.scoringResult === "Pass" ? "Pass" : "Fail";
                    scoringPolicyMembers.memberStatus = x.memberStatus;
                    scoringPolicyMembers.exceptionalApproval = scoringPolicyMembers.policyScoringFail === "Fail" && x.memberStatus === "Ineligible" ? "No" : scoringPolicyMembers.policyScoringFail === "Fail" && x.memberStatus === "Eligible" ? "Yes" : ""
                    // scoringPolicyMembers.policyResponse = JSON.parse(policyMembers.filter(y => y.memberId === x.id)[0]?.response)
                    // scoringPolicyMembers.scoringResponse = JSON.parse(scoreCardMembers.filter(y => y.memberId === x.id)[0]?.response)
                    // scoringPolicyMembers.policyComment = policyMembers.filter(y => y.memberId === x.id)[0].comment
                    // scoringPolicyMembers.scoringComment = scoreCardMembers.filter(y => y.memberId === x.id)[0].comment
                    // console.log(scoringPolicyMembers)

                    scoringPolicyMembersList.push(scoringPolicyMembers)
                });
            }
            console.log(scoringPolicyMembersList)

            const membersPaginated: any = [];
            const totalLength = scoringPolicyMembersList.length;
            const initial = (currentPage - 1) * 10;
            for (let i = initial; i < initial + 10; i++) {
                membersPaginated.push(scoringPolicyMembersList[i]);
                if (i === totalLength - 1) {
                    break;
                }
            }

            if (this._isMounted) {
                this.setState({
                    tableItems: mapEntityList({
                        entityList: membersPaginated,
                        properties: [

                            { oldName: "title", newName: "title" },
                            { oldName: "customerName", newName: "Name" },
                            { oldName: "policyResult", newName: "policyResult" },
                            { oldName: "scoringResult", newName: "scoringResult" },
                            { oldName: "policyScoringFail", newName: "policy/scoringStatus" },
                            { oldName: "exceptionalApproval", newName: "exceptionalApproval" }
                        ],
                    }),
                    currentPage,
                    pageSize,
                    totalSize: scoringPolicyMembersList.length,
                    fetching: false,
                    ownUpdate: true,
                    members: scoringPolicyMembersList
                });
            }

        } catch (ex) {
            if (ex.response && ex.response.status >= 400 && ex.response.status <= 499) {
                if (this._isMounted) {
                    this.setState({ fetching: false, ownUpdate: true });
                }
            } else {
                if (this._isMounted) {
                    this.setState({ fetching: false, ownUpdate: true });
                }
            }
        } finally {
            if (this._isMounted) {
                this.setState({ fetching: false, ownUpdate: true });
            }
        }
    };
    getFilteredItems = (data: IFilterData): IFilterData => {
        let filteredItems: IFilterData = {};
        Object.keys(data).forEach((key) => {
            if (data[key].item2 !== "") {
                filteredItems[key] = data[key];
            }
        });
        return filteredItems;
    };

    static getDerivedStateFromProps(nextProps: IPolicyScoringListModalProps) {
        return {
            item: nextProps.item,
        };
    }

    fetchItemById = (id) => {
        const member = this.state.members.filter(x => x.id === id)

        this.setState({ member: member })
    }

    toggleShowPolicyScoringResultModal = () => {
        this.setState({ showPolicyScoringResult: !this.state.showPolicyScoringResult });
    };

    handleOnExport = async (type: string) => { };

    render() {
        const { item, showPatchModal, togglePatchModal, systemProcess, userRoles, currentUserSession } = this.props;
        const {
            currentPage,
            pageSize,
            tableItems,
            totalSize,
            fetching,
            fetchingDetail,
            submitting
        } = this.state;

        if (_.isEmpty(item)) {
            return null;
        }
        const tableContext: TableContext = {
            name: "",
            primaryValue: "",
            successValue: "Successful",
            dangerValue: "Failed",

        };

        return (
            <><Modal
                size="xl"
                backdrop="static"
                show={showPatchModal}
                onHide={togglePatchModal}
            >
                <Modal.Header>
                    <Modal.Title>
                        <h5>
                            <i className="fas fa-edit fa-sm cyan-text mr-3" />
                            Policy & Scoring For Solidarity Group
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <PolicyScoringListModalForm
                    initialValues={{}}
                    FormComponent={({
                        fields: { }, onChange, onReloadFields, onHandleSubmit,
                    }) => (
                        <React.Fragment>
                            <Modal.Body>
                                <div className="card">
                                    <div className="card-header clear-fix">
                                        {_.isEmpty(item) ? (
                                            <Skeleton width={200} />
                                        ) : (
                                            <h6
                                                className="card-title float-left"
                                                style={{ marginBottom: "0px" }}
                                            >
                                                <b>Policy & Scoring For {item.customerName} - {this.props.members[0].solidarityGroupName}</b>
                                            </h6>
                                        )}
                                    </div>
                                    <div className="card">
                                        <Accordion defaultActiveKey="0">
                                            <div>
                                                <Card>
                                                    {/* <ContextAwareToggle
                    eventKey={`0`}
                    level={""}
                ></ContextAwareToggle> */}
                                                    <Accordion.Collapse eventKey={`0`}>
                                                        <Card.Body>
                                                            <PolicyScoringListModalList
                                                                pageChangeHandler={this.handlePageChange}
                                                                fetchingDetail={fetchingDetail}
                                                                initialValues={{
                                                                    data: this.state.data,
                                                                    fetching: false,
                                                                    showDetailsModal: false,
                                                                    showEditModal: false,
                                                                    showTableFilterModal: false,
                                                                    showTrailModal: false,
                                                                    tableItems: [],
                                                                    filterValueInput: this.state.filterValueInput,
                                                                    item: {},
                                                                }}
                                                                tableItems={tableItems}
                                                                item={this.state.members}
                                                                DetailsModal={({
                                                                    item, onToggleDetailsModal, onToggleEditModal, showDetailsModal, showEditModal
                                                                }) => (
                                                                    <Fragment>
                                                                        <GroupPolicyScoringCheck
                                                                            item={this.state.item}
                                                                            member={this.state.member}
                                                                            showPatchModal={this.state.showPolicyScoringResult}
                                                                            togglePatchModal={this.toggleShowPolicyScoringResultModal} systemProcess={systemProcess} userRoles={userRoles} currentUserSession={currentUserSession} />
                                                                    </Fragment>

                                                                )}
                                                                ListPayLoad={({
                                                                    data, showTableFilterModal, tableItems, onToggleDetailsModal, onToggleTableFilterModal, onHandleFilterItemRemove, filterValueInput,
                                                                }) => (
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="card no-b">
                                                                                <div className="card-body">
                                                                                    <div className="card-title">
                                                                                        <div id="list-filters" className="ml-4 mr-4">
                                                                                            <TableFilterPane
                                                                                                filteredItems={this.getFilteredItems(data)}
                                                                                                handleFilterRemove={onHandleFilterItemRemove} />
                                                                                        </div>
                                                                                        <div id="list-table">
                                                                                            {/* <ItemsTable
                                                                                                data={data}
                                                                                                items={tableItems}
                                                                                                filterValueInput={filterValueInput}
                                                                                                showTableFiltermodal={showTableFilterModal}
                                                                                                toggleTableFiltermodal={onToggleTableFilterModal}
                                                                                                name="Policy & Scoring For Solidarity Group"
                                                                                                currentPage={currentPage}
                                                                                                pageSize={pageSize}
                                                                                                totalSize={totalSize}
                                                                                                fetching={fetching}
                                                                                                handlePageChange={this.handlePageChange}
                                                                                                onViewDetails={(item: any) => {
                                                                                                    this.fetchItemById(item.id)
                                                                                                    this.toggleShowPolicyScoringResultModal();
                                                                                                }}
                                                                                                handleOnExport={this.handleOnExport}
                                                                                                timeColumn={["dateCreated"]}
                                                                                                amountColumn={["loanAmount"]} /> */}
                                                                                            <DetailItemsTable
                                                                                                title="Policy & Scoring For Solidarity Group"
                                                                                                item={item}
                                                                                                handleOnPageChange={
                                                                                                    this.handlePageChange
                                                                                                }
                                                                                                content={tableItems}
                                                                                                contentCount={totalSize}
                                                                                                page={currentPage}
                                                                                                onViewDetails={(item: any) => {
                                                                                                    this.fetchItemById(item.id)
                                                                                                    this.toggleShowPolicyScoringResultModal();
                                                                                                }}
                                                                                                hasDetailModal={true}
                                                                                                fetching={fetching}
                                                                                                amountColumn={["loanAmount"]}
                                                                                                timeColumn={["dateCreated"]}
                                                                                                contextualColumn={tableContext}
                                                                                                emptyTitle="Policy & Scoring For Solidarity Group"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            ></PolicyScoringListModalList>

                                                            <div className="form-row">
                                                                <div className="my-1 mx-2">
                                                                    <Button
                                                                        className="btn btn-sm btn-outline-success my-2 mx-3"
                                                                        style={{ cursor: "pointer" }}
                                                                        type="button"
                                                                        // onClick={this.handleUploadDocument}
                                                                        onClick={(e) => { this.approveBatchAppraisal() }}
                                                                    >
                                                                        <React.Fragment>
                                                                            <i className="fas fa-check fa-lg mr-2" />{" "}
                                                                            Approve loans with exceptions
                                                                        </React.Fragment>
                                                                    </Button>
                                                                </div>
                                                            </div>

                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </div>
                                        </Accordion>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    size="sm"
                                    variant="outline-danger"
                                    onClick={() => togglePatchModal(false, {}, {})}
                                    disabled={submitting}
                                >
                                    <i className="fas fa-times mr-3" />
                                    Close
                                </Button>
                            </Modal.Footer>
                        </React.Fragment>
                    )}
                ></PolicyScoringListModalForm>
            </Modal>
                {/* <GroupPolicyScoringCheck
                    item={this.state.item}
                    showPatchModal={this.state.showPersonalDetails}
                    togglePatchModal={this.toggleShowPersonalDetailsModal} systemProcess={systemProcess} userRoles={userRoles} currentUserSession={currentUserSession} /> */}
            </>
        );
    }
}

export default PolicyScoringListModal;
