import React, { Fragment, Component, useContext } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../../../../components/detailItem";
import { Accordion, Card } from "react-bootstrap";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import { generateGUID } from "../../../../../../services/utility/textFormatService";
import textFormatService from "../../../../../../services/utility/textFormatService";

export function ContextAwareToggle({ eventKey, level }) {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      <b>Asset/Property {level}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
}

interface AssetPropertyDetailsProps {
  values: any;
  handleChange: any;
  handleBlur: any;
}

interface AssetPropertyDetailsState {
  item: any;
}

class AssetPropertyDetails extends Component<AssetPropertyDetailsProps, AssetPropertyDetailsState> {
  constructor(props: AssetPropertyDetailsProps) {
    super(props);
    this.state = {
      item: {},
    };
  }

  static getDerivedStateFromProps(nextProps: AssetPropertyDetailsProps, prevState) {
    return {
      item: nextProps.values.assets,
    };
  }

  render() {
    const { values } = this.props;
    // console.log("View values :: ", values)

    return (
      <Fragment>
        <div className="card">
          <div className="card-header clear-fix">
            {_.isEmpty(values) ? (
              <Skeleton width={200} />
            ) : (
              <h6
                className="card-title float-left"
                style={{ marginBottom: "0px" }}
              >
                <b>Asset Details</b>
              </h6>
            )}
          </div>

          <Accordion defaultActiveKey="0">
            <Card>
              <Accordion.Collapse eventKey={`0`}>
                <Card.Body>
                  <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                    <div className="row">
                      <div className="col-md-12">
                        {_.isEmpty(values.assets) ? (
                          <div style={{ textAlign: "center", color: "#FE825E" }}>
                            No Asset has been assigned to Loan Request
                          </div>
                        ) : (

                          <div>
                            {/* {console.log(values.assets)}  */}
                            {values?.assets?.assets?.map((item, idx) => (
                              (item.category === null || item.category === "" ? (" ") : (
                                <div className="card mt-3" key={generateGUID()}>
                                  <div className="card-header clear-fix">
                                    {_.isEmpty(values) ? (
                                      <Skeleton width={200} />
                                    ) : (
                                      <h6
                                        className="card-title float-left"
                                        style={{ marginBottom: "0px" }}
                                      >
                                        <b>Asset Details</b>
                                      </h6>
                                    )}
                                  </div>

                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-md-6">
                                        {_.isEmpty(values) ? (
                                          <Skeleton count={3} width={300} />
                                        ) : (
                                          <dl className="row">
                                            <DetailItem
                                              label="Asset Category"
                                              labelSize={7}
                                              valueSize={5}
                                              value={item.category}
                                            />
                                            <DetailItem
                                              label="Asset Year"
                                              labelSize={7}
                                              valueSize={5}
                                              value={item.year}
                                            />
                                            <DetailItem
                                              label="Asset Life (In Years)"
                                              labelSize={7}
                                              valueSize={5}
                                              value={item.life}
                                            />
                                            <DetailItem
                                              label="Supplier"
                                              labelSize={7}
                                              valueSize={5}
                                              value={item.supplier}
                                            />
                                            {/* <DetailItem
                                            label="Cost"
                                            labelSize={7}
                                            valueSize={5}
                                            value={textFormatService.formatCurrency(Number(item.cost), 2)}
                                          />
                                          <DetailItem
                                            label="Loan Amount"
                                            labelSize={7}
                                            valueSize={5}
                                            value={textFormatService.formatCurrency(Number(item.loanAmount), 2)}
                                          /> */}
                                          </dl>
                                        )}
                                      </div>
                                      <div className="col-md-6">
                                        {_.isEmpty(values) ? (
                                          <Skeleton count={3} width={300} />
                                        ) : (
                                          <dl className="row">
                                            <DetailItem
                                              label="Type"
                                              labelSize={7}
                                              valueSize={5}
                                              value={item.type}
                                            />
                                            <DetailItem
                                              label="Model"
                                              labelSize={7}
                                              valueSize={5}
                                              value={item.model}
                                            />

                                            <DetailItem
                                              label="Description"
                                              labelSize={7}
                                              valueSize={5}
                                              value={item.description}
                                            />
                                            <DetailItem
                                              label="Age"
                                              labelSize={7}
                                              valueSize={5}
                                              value={item.age}
                                            />
                                            {/* <DetailItem
                                            label="Equity Payment"
                                            labelSize={7}
                                            valueSize={5}
                                            value={textFormatService.formatCurrency(Number(item.equityPayment), 2)}
                                          /> */}
                                            {/* <DetailItem
                                            label="Loan to Value"
                                            labelSize={7}
                                            valueSize={5}
                                            value={item.loanToValue}

                                          /> */}
                                          </dl>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ))}

                            {values?.assets?.assets?.map((item, idx) => (
                              (item.loanAmount === null || item.loanAmount === "" || item.loanAmount === "0" ? ("") : (
                                <Fragment>
                                  <div className="card mt-3" key={generateGUID()}>
                                    <div className="card-header clear-fix">
                                      {_.isEmpty(values) ? (
                                        <Skeleton width={200} />
                                      ) : (
                                        <h6
                                          className="card-title float-left"
                                          style={{ marginBottom: "0px" }}
                                        >
                                          <b>Loan Summary</b>
                                        </h6>
                                      )}
                                    </div>

                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-md-6">
                                          {_.isEmpty(values) ? (
                                            <Skeleton count={3} width={300} />
                                          ) : (
                                            <dl className="row">
                                              <DetailItem
                                                label="Asset Cost"
                                                labelSize={7}
                                                valueSize={5}
                                                value={textFormatService.formatCurrency(Number(item.cost) / 100, 2)}
                                              />
                                              <DetailItem
                                                label="Loan Amount"
                                                labelSize={7}
                                                valueSize={5}
                                                value={textFormatService.formatCurrency(Number(item.loanAmount) / 100, 2)}
                                              />
                                            </dl>
                                          )}
                                        </div>
                                        <div className="col-md-6">
                                          {_.isEmpty(values) ? (
                                            <Skeleton count={3} width={300} />
                                          ) : (
                                            <dl className="row">
                                              <DetailItem
                                                label="Equity Payment"
                                                labelSize={7}
                                                valueSize={5}
                                                value={textFormatService.formatCurrency(Number(item.equityPayment), 2)}
                                              />
                                              <DetailItem
                                                label="Loan to Value"
                                                labelSize={7}
                                                valueSize={5}
                                                value={item.loanToValue}
                                              />
                                            </dl>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Fragment>
                              ))
                            ))}
                          </div>


                        )}
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

        </div>
      </Fragment>
    );
  }
}

export default AssetPropertyDetails;