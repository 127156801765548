import React, { Component, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import { getCurrentUsersRoles } from "../../../services/auth/authService";

interface ICentralFeeConfigurationDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  showTrail: any;
  currentUserRoles: any;
  item: any;
}

interface ICentralFeeConfigurationDetailState {
  item: any;
  updatingStatus: boolean;
}

class CentralFeeConfigurationDetail extends Component<ICentralFeeConfigurationDetailProps, ICentralFeeConfigurationDetailState> {
  state = {
    item: {},
    updatingStatus: false,
  };

  static getDerivedStateFromProps(nextProps: ICentralFeeConfigurationDetailProps) {
    return {
      item: nextProps.item,
    };
  }

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      item,
    } = this.props;
    const { updatingStatus } = this.state;

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        {_.isEmpty(item) ? (
          <Skeleton width={500} />
        ) : (
          <Fragment>
            <Modal.Header closeButton>
              <Modal.Title>
                {_.isEmpty(item) ? (
                  <Skeleton width={500} />
                ) : (
                  <h5>
                    <i className="icon icon-building-o s-18 mr-3" />
                    Central Fee Configuration Details -{" "}
                    <span className="font-weight-bold">{item.institutionCategory} ({item.serviceOffering})</span>
                  </h5>
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="card">
                {(item.name === 'Recova') ? (
                  <Fragment>
                    <div className="card-header clear-fix">
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        Fee
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <dl className="row">
                            <DetailItem
                              label="Active Loan Percentage"
                              labelSize={9}
                              valueSize={3}
                              value={item.percentageAmountPerActiveLoan + " %"}
                            />
                            <DetailItem
                              label="Lost Loan Percentage"
                              labelSize={9}
                              valueSize={3}
                              value={item.percentageAmountPerLostLoan + " %"}
                            />
                            <DetailItem
                              label="Bad Loan Percentage"
                              labelSize={9}
                              valueSize={3}
                              value={item.percentageAmountPerBadLoan + " %"}
                            />
                          </dl>
                        </div>
                        <div className="col-md-6">
                          <dl className="row">
                            <DetailItem
                              label="Amount Per Successful Transaction"
                              labelSize={9}
                              valueSize={3}
                              value={item.amountPerSuccessfulTrx}
                            />
                            <DetailItem
                              label="Minimum Fee Per Successful Transaction"
                              labelSize={9}
                              valueSize={3}
                              value={item.minimumFeePerSuccessfulTrx}
                            />
                            <DetailItem
                              label="Maximum Fee Per Successful Transaction"
                              labelSize={9}
                              valueSize={3}
                              value={item.maximumFeePerSuccessfulTrx}
                            />
                          </dl>
                        </div>
                      </div>
                    </div>
                    <div className="card-header clear-fix">
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        Commission
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <dl className="row">
                            <DetailItem
                              label="Debited Bank Commission"
                              labelSize={9}
                              valueSize={3}
                              value={item.debitedBankCommission}
                            />
                          </dl>
                        </div>
                        <div className="col-md-6">
                          <dl className="row">
                            {item.institutionCategory == 'Commercial Bank' ? (
                              <DetailItem
                                label="Sponsor Bank Commission"
                                labelSize={9}
                                valueSize={3}
                                value={item.sponsorBankCommission}
                              />
                            ) : (
                              ""
                            )}
                          </dl>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="card-header clear-fix">
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        Appzone Fee
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <dl className="row">
                            <DetailItem
                              label="ThirdParty"
                              labelSize={9}
                              valueSize={3}
                              value={item.thirdParty}
                            />
                            <DetailItem
                              label="Active Loan Percentage"
                              labelSize={9}
                              valueSize={3}
                              value={item.appzonePercentageAmountPerActiveLoan + " %"}
                            />
                            <DetailItem
                              label="Lost Loan Percentage"
                              labelSize={9}
                              valueSize={3}
                              value={item.appzonePercentageAmountPerLostLoan + " %"}
                            />
                          </dl>
                        </div>
                        <div className="col-md-6">
                          <dl className="row">
                            <DetailItem
                              label="Flat Amount Per Successful Transaction"
                              labelSize={9}
                              valueSize={3}
                              value={item.appzoneFlatAmountPerSuccessfulTrx + " %"}
                            />
                            <DetailItem
                              label="Minimum Fee Per Successful Transaction"
                              labelSize={9}
                              valueSize={3}
                              value={item.appzoneMinimumFeePerSuccessfulTrx}
                            />
                            <DetailItem
                              label="Maximum Fee Per Successful Transaction"
                              labelSize={9}
                              valueSize={3}
                              value={item.appzoneMaximumFeePerSuccessfulTrx}
                            />
                          </dl>
                        </div>
                      </div>
                    </div>
                    <div className="card-header clear-fix">
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        ThirdParty Cost
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <dl className="row">
                            <DetailItem
                              label="Amount Swept Percentage"
                              labelSize={9}
                              valueSize={3}
                              value={item.thirdPartyPercentageAmountSwept + " %"}
                            />
                            <DetailItem
                              label="Flat Amount Per Successful Transaction"
                              labelSize={9}
                              valueSize={3}
                              value={item.thirdPartyFlatAmountPerSuccessfulTrx}
                            />
                          </dl>
                        </div>
                        <div className="col-md-6">
                          <dl className="row">
                            <DetailItem
                              label="Minimum Fee Per Successful Transaction"
                              labelSize={9}
                              valueSize={3}
                              value={item.thirdPartyMinimumFeePerSuccessfulTrx}
                            />
                            <DetailItem
                              label="Maximum Fee Per Successful Transaction"
                              labelSize={9}
                              valueSize={3}
                              value={item.thirdPartyMaximumFeePerSuccessfulTrx}
                            />
                          </dl>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Fragment>
                {getCurrentUsersRoles().includes("editpaymentconfiguration") && (
                  <Button
                    size="sm"
                    variant="primary"
                    disabled={updatingStatus}
                    onClick={toggleEditModal}
                  >
                    <i className="far fa-edit mr-3" />
                    Edit
                  </Button>
                )}
              </Fragment>
              <Button
                size="sm"
                variant="outline-danger"
                onClick={toggleDetailsModal}
              >
                <i className="fas fa-times mr-3" />
                Close
              </Button>
            </Modal.Footer>
          </Fragment>
        )}
      </Modal>
    );
  }
}

export default CentralFeeConfigurationDetail;