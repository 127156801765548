import React, { Component, Fragment, useContext } from "react";
import {
  Modal,
  Button,
  Card,
  useAccordionToggle,
  AccordionContext,
  Accordion,
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import DetailItem from "../../../components/detailItem";
import { getCurrentUsersRoles } from "../../../services/auth/authService";

export function ContextAwareToggle({ eventKey, header }) {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      <b>{header}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
}

interface IAppraisalPlanDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  item: any;
}

interface IAppraisalPlanDetailState {
  item: any;
  updatingStatus: boolean;
}

class AppraisalPlanDetail extends Component<IAppraisalPlanDetailProps, IAppraisalPlanDetailState> {
  constructor(props: IAppraisalPlanDetailProps) {
    super(props);
    this.state = {
      item: {},
      updatingStatus: false,
    };
  }

  static getDerivedStateFromProps(nextProps: IAppraisalPlanDetailProps, prevState) {
    return {
      item: nextProps.item,
    };
  }

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      item,
    } = this.props;
    const { updatingStatus } = this.state;

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="fas fa-user-shield fa-sm cyan-text mr-3" />
                Appraisal Plan Details -{" "}
                <span className="font-weight-bold">{item.name}</span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
            </div>

            {/* General Information */}
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Institution Category"
                        labelSize={7}
                        valueSize={5}
                        value={item.institutionCategory}
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Appraisal Plan Name"
                        labelSize={7}
                        valueSize={5}
                        value={item.name}
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>

            {/* Reports */}
            <Accordion defaultActiveKey="0">
              <div>
                <Card>
                  <ContextAwareToggle
                    eventKey={`0`}
                    header={
                      <React.Fragment>
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                          style={{ color: "#878a8a" }}
                        ></i>{" "}
                        Reports
                      </React.Fragment>
                    }
                  ></ContextAwareToggle>
                  <Accordion.Collapse eventKey={`0`}>
                    <Card.Body>
                      <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                        <div className="row">
                          <div className="col-md-12">
                            {_.isEmpty(item.reports) ? (
                              <div style={{ textAlign: "center", color: "#FE825E" }}>
                                No Report has been assigned to appraisal plan
                              </div>
                            ) : (
                              <Fragment>
                                {item.reports.map(
                                  (report) => (
                                    <div key={report.id} style={{ marginTop: '3px' }}>
                                      <div
                                        key={report.id}
                                        className="custom-control custom-checkbox"
                                      >
                                        <input
                                          id={report.id}
                                          name={report.id}
                                          className="custom-control-input"
                                          type="checkbox"
                                          checked={true}
                                          disabled={true}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={report.id}
                                        >
                                          {report.name}
                                        </label>
                                      </div>
                                    </div>
                                  )
                                )}
                              </Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </div>
            </Accordion>

            {/* Validations */}
            <Accordion defaultActiveKey="1">
              <div>
                <Card>
                  <ContextAwareToggle
                    eventKey={`1`}
                    header={
                      <React.Fragment>
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                          style={{ color: "#878a8a" }}
                        ></i>{" "}
                        Validations
                      </React.Fragment>
                    }
                  ></ContextAwareToggle>
                  <Accordion.Collapse eventKey={`1`}>
                    <Card.Body>
                      <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                        <div className="row">
                          <div className="col-md-12">
                            {_.isEmpty(item.validations) ? (
                              <div style={{ textAlign: "center", color: "#FE825E" }}>
                                No Validation has been assigned to appraisal plan
                              </div>
                            ) : (
                              <Fragment>
                                {item.validations.map(
                                  (validation) => (
                                    <div key={validation.id} style={{ marginTop: '3px' }}>
                                      <div
                                        key={validation.id}
                                        className="custom-control custom-checkbox"
                                      >
                                        <input
                                          id={validation.id}
                                          name={validation.id}
                                          className="custom-control-input"
                                          type="checkbox"
                                          checked={true}
                                          disabled={true}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={validation.id}
                                        >
                                          {validation.name}
                                        </label>
                                      </div>
                                    </div>
                                  )
                                )}
                              </Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </div>
            </Accordion>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
              {getCurrentUsersRoles().includes("editappraisalplan") && (
                <Button
                  size="sm"
                  variant="primary"
                  disabled={updatingStatus}
                  onClick={toggleEditModal}
                >
                  <i className="far fa-edit mr-3" />
                  Edit
                </Button>
              )}
            </React.Fragment>
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AppraisalPlanDetail;
