import React from "react";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import Skeleton from "react-loading-skeleton";
import { ISelectItems } from "../../../components/detailItemSelectBox";
import creditQualificationRuleService from "../../../services/creditAssessment/creditQualificationRuleService";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../../services/auth/authService";
import loanProductService from "../../../services/creditAssessment/loanProductService";

interface AddQualificationRuleToProductFields { }

class AddQualificationRuleToProductForm extends BaseFormComponent<AddQualificationRuleToProductFields> { }

interface IAddQualificationRuleToProductProps {
  item: any;
  showPatchModal: boolean;
  togglePatchModal: any;
}
interface IAddQualificationRuleToProductState {
  ownUpdate: boolean;
  rePopulateCheckList: boolean;
  creditQualificationRuleIds: any;
  creditQualificationRules: any;
}

class AddQualificationRuleToProduct extends React.Component<
  IAddQualificationRuleToProductProps,
  IBaseFormState & IAddQualificationRuleToProductState
> {
  _isMounted = false;
  constructor(props: IAddQualificationRuleToProductProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      ownUpdate: false,
      rePopulateCheckList: false,
      creditQualificationRuleIds: [],
      creditQualificationRules: [],
    };
  }

  static getDerivedStateFromProps(
    nextProps: IAddQualificationRuleToProductProps,
    prevState
  ) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isEmpty(nextProps.item)) {
      return null;
    }
    return {
      rePopulateCheckList: true,
      creditQualificationRuleIds: nextProps.item.creditQualificationRules.map(
        (item) => item.creditQualificationRuleId
      ),
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { errors } = this.state;
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    try {
      let creditQualificationRules =
        await creditQualificationRuleService.retrieveCreditQualificationRulesByInstitution(
          decodedToken?.["InstitutionCode"] || ""
        );
      if (this._isMounted) {
        this.setState({
          creditQualificationRules: creditQualificationRules.data,
        });
      }
    } catch (ex) {
      // console.log("caught exception", ex);
      if (ex.response && ex.response.status === 404) {
        // console.log(ex.response);
        errors.response = "Internal server error.";
        // console.log(errors);
        this.setState({ errors });
      }
    }
  }
  componentDidUpdate(
    prevProps: IAddQualificationRuleToProductProps,
    prevState: IAddQualificationRuleToProductState
  ) {
    if (this.state.rePopulateCheckList) {
      let creditQualificationRuleIds: any = [];
      if (this.state.creditQualificationRuleIds) {
        creditQualificationRuleIds = [...this.state.creditQualificationRuleIds];
      }

      creditQualificationRuleIds = creditQualificationRuleIds.map(
        (item) =>
          this.props.item.creditQualificationRules?.filter(
            (x) => x.creditQualificationRuleId === item
          )[0].creditQualificationRuleId
      );

      this.setState({
        rePopulateCheckList: false,
        ownUpdate: true,
        creditQualificationRuleIds,
      });
      // this.loadCreditQualRule("loanProductID", this.props.item.loanProductID);
    }
  }

  handleOnCreditQualificationRuleChange = (item) => {
    // console.log("clicked Credit Qualification Rules: ", item);
    const creditQualificationRuleIds = [
      ...this.state.creditQualificationRuleIds,
    ];
    if (creditQualificationRuleIds.indexOf(item) !== -1) {
      creditQualificationRuleIds.splice(
        creditQualificationRuleIds.indexOf(item),
        1
      );
    } else {
      creditQualificationRuleIds.push(item);
    }
    // console.log("Credit QUalification Rule Indicators: ", creditQualificationRuleIds);
    this.setState({ creditQualificationRuleIds, ownUpdate: true });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  onFormSubmit(
    fields: AddQualificationRuleToProductFields,
    onReloadFieldsCB: any
  ): boolean {
    try {
      this.setState(
        {
          errors: [], ownUpdate: true
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (
    fields: AddQualificationRuleToProductFields,
    onReloadFieldsCB: any
  ) => {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      // creditQualificationRuleIds: nextProps.item.creditQualificationRules.map(
      //   (item) => item.creditQualificationRuleId
      // )
      //run add and remove
      const { item } = this.props;
      let existingRules: any = []
      existingRules = item.creditQualificationRules ? item.creditQualificationRules : [];

      const removedCreditQualificationRuleIds: any = [];
      const maintainedCreditQualificationRuleIds: any = [];
      // const addedCreditQualificationRuleIds: any = [];
      // console.log("existing rules::", existingRules)
      // console.log("selected rules::", this.state.creditQualificationRuleIds)
      for (var i = 0; i < existingRules.length; i++) {
        if (this.state.creditQualificationRuleIds.some(creditQualificationRuleId => creditQualificationRuleId == existingRules[i].creditQualificationRuleId)) {
          //add to edited
          // console.log("edited", existingRules[i].creditQualificationRuleId)
          maintainedCreditQualificationRuleIds.push(existingRules[i].creditQualificationRuleId)
        } else {
          //add to removed
          // console.log("removed", existingRules[i].creditQualificationRuleId)
          removedCreditQualificationRuleIds.push(existingRules[i].creditQualificationRuleId)
        }
      }
      //find new ids
      var newRules = this.state.creditQualificationRuleIds.filter((creditQualificationRuleId) => !maintainedCreditQualificationRuleIds.some(maintained => maintained === creditQualificationRuleId));
      // console.log("New Maps :: ", newLPMaps)
      // const addedcreditQualificationRuleIds = addedcreditQualificationRuleIds.concat(newRules)

      const removePayload: any = {};
      removePayload.creditQualificationRuleIds = removedCreditQualificationRuleIds;

      const newPayload: any = {};
      newPayload.creditQualificationRuleIds = newRules;
      // console.log("new :: ", newPayload)

      if (removedCreditQualificationRuleIds.length > 0) {
        // console.log("removed :: ", removePayload)
        const removeResponse = await loanProductService.removeQualificationRuleFromLoanProduct(this.props.item.id, removePayload);
      }
      const addResponse = await loanProductService.addQualificationRuleToLoanProduct(this.props.item.id, newPayload);
      if (addResponse.status >= 200 && addResponse.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Modify Credit Qualification Rules for Loan Product",
          description: `Modify Credit Qualification Rules for ${this.props.item.name}`,
        });

        toast.info(
          `Credit Qualification Rules Modified for ${this.props.item.name} successfully! `,
          {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          }
        );
      }
      onReloadFieldsCB();
      this.setState({ submitting: false }, () =>
        this.props.togglePatchModal(true, this.props.item)
      );
    }
    catch (error) {
      //   console.log("submission error: ", error);
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false });
    }
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
    });
  }

  render() {
    const { item, showPatchModal, togglePatchModal } = this.props;
    const { validated, submitting, errors, creditQualificationRules } =
      this.state;

    let creditQualificationRulesInputData: ISelectItems[] =
      creditQualificationRules.map((item) => ({
        name: item.name,
        value: item.id,
      }));

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showPatchModal}
        onHide={togglePatchModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-plus fa-sm cyan-text mr-3" />
              Modify Credit Qualification Rule -{" "}
            </h5>
          </Modal.Title>
        </Modal.Header>
        <AddQualificationRuleToProductForm
          initialValues={{}}
          FormComponent={({
            fields: { },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    {_.isEmpty(item) ? (
                      <Skeleton width={200} />
                    ) : (
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        <b>General Information</b>
                      </h6>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="form-row">
                      <div className="col-md-12">
                        <div className="form-row">
                          <div className="col-md-6">
                            <label
                              htmlFor="creditQualificationRuleIds"
                              className="control-label textbox-label"
                            >
                              Credit Qualification Rules
                            </label>
                            <div
                              className="text-nowrap block-example border"
                              style={{
                                width: "100%",
                                height: "200px",
                                overflowY: "scroll",
                              }}
                            >
                              <React.Fragment>
                                {creditQualificationRules.length === 0 ? (
                                  <div
                                    style={{
                                      backgroundColor: "#F3F3F3",
                                    }}
                                  >
                                    <p className="text-center">
                                      no credit qualification rules
                                    </p>
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    {creditQualificationRules.map((item) => (
                                      <div
                                        className="custom-control custom-checkbox"
                                        key={creditQualificationRules.indexOf(
                                          item
                                        )}
                                      >
                                        <input
                                          id={item.id}
                                          name={item.id}
                                          className="custom-control-input"
                                          type="checkbox"
                                          onChange={() =>
                                            this.handleOnCreditQualificationRuleChange(
                                              item.id
                                            )
                                          }
                                          checked={
                                            _.isEmpty(
                                              this.state.creditQualificationRuleIds.filter(
                                                (x) => x === item.id
                                              )
                                            )
                                              ? false
                                              : true
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={item.id}
                                        >
                                          {item.name}
                                        </label>
                                      </div>
                                    ))}
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.UPDATE,
                      "Credit Qualification Rules",
                      () => {
                        this.onFormSubmit({}, onReloadFields);
                      }
                    );
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => togglePatchModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></AddQualificationRuleToProductForm>
      </Modal>
    );
  }
}

export default AddQualificationRuleToProduct;
