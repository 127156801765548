import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";

const influxagentBaseAddress =
  process.env.REACT_APP_INFLUXAGENT_BASEADDRESS + `api/`;

export function retrieveLoanRequestCount(institutionCode: string) {
  var url =
    influxagentBaseAddress +
    `creditAssessment/RetrieveInstitutionLoanRequestsCount?institutionCode=${institutionCode}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveAllLoanRequestCount() {
  var url =
    influxagentBaseAddress +
    `creditAssessment/RetrieveAllLoanRequestsCount`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveDisbursedLoanVolume(institutionCode: string) {
  var url =
    influxagentBaseAddress +
    `creditAssessment/RetrieveInstitutionLoanDisbursedCount?institutionCode=${institutionCode}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveAllDisbursedLoanVolume() {
  var url =
    influxagentBaseAddress +
    `creditAssessment/RetrieveAllLoanDisbursedVolume`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}
export function retrieveCQefficiency(institutionCode: string) {
    var url =
      influxagentBaseAddress +
      `creditAssessment/retrieveInstitutionCQefficiency?institutionCode=${institutionCode}`;
  
    return http.get(url, {
      url: url,
      method: "get",
      headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
  }
  
  export function retrieveAllCQefficiency() {
    var url =
      influxagentBaseAddress +
      `creditAssessment/retrieveAllCQefficiency`;
  
    return http.get(url, {
      url: url,
      method: "get",
      headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
  }
export function retrieveDisbursedLoanCount(institutionCode: string) {
    var url =
      influxagentBaseAddress +
      `creditAssessment/RetrieveInstitutionLoanDisbursedVolume?institutionCode=${institutionCode}`;
  
    return http.get(url, {
      url: url,
      method: "get",
      headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
  }
  
  export function retrieveAllDisbursedLoanCount() {
    var url =
      influxagentBaseAddress +
      `creditAssessment/RetrieveAllLoanDisbursedCount`;
  
    return http.get(url, {
      url: url,
      method: "get",
      headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
  }

export default {
    retrieveLoanRequestCount,
    retrieveAllLoanRequestCount,
    retrieveDisbursedLoanVolume,
    retrieveAllDisbursedLoanVolume,
    retrieveCQefficiency,
    retrieveAllCQefficiency,
    retrieveDisbursedLoanCount,
    retrieveAllDisbursedLoanCount
};
