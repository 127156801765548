import * as React from "react";
// import _ from "lodash";
import { Fragment } from "react";
import {
  IFilterData,
  BaseListComponentState,
} from "../../components/BaseListComponent";
import {
  createFilterData,
  mapEntityList,
} from "./../../services/utility/autoMapperService";
import BaseListComponent from "./../../components/BaseListComponent";
import reportsService from "../../services/sweep/reportsService";
import ItemsTable, {
  filterFieldData,
} from "../../components/tableComponents/itemsTable";
import TableFilterPane from "./../../components/tableComponents/tableFilterPane";
import { getCurrentUserSession } from "../../services/auth/authService";
import institutionsService from "../../services/auth/institutionsService";
import OutgoingRepaymentReportDetail from "./outgoingRepaymentsReportDetail";
import { printPDF } from "../../services/utility/textFormatService";
import { decodeToken } from "react-jwt";

export interface SuccessfulOutgoingRepaymentsReportProps {}

export interface SuccessfulOutgoingRepaymentsReportState
  extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
}

class SuccessfulOutgoingRepaymentsReportList extends BaseListComponent<SuccessfulOutgoingRepaymentsReportState> {}

class SuccessfulOutgoingRepaymentsReport extends React.Component<
  SuccessfulOutgoingRepaymentsReportProps,
  SuccessfulOutgoingRepaymentsReportState
> {
  constructor(props: SuccessfulOutgoingRepaymentsReportProps) {
    super(props);
    this.state = {
      data: createFilterData([
        "customerName",
        "transactionReference",
        // "mandateReference",
        // "settlementStatus",
        "transactionDate",
      ]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [
        { name: "customerName", fieldType: "text", value: "" },
        // { name: "mandateReference", fieldType: "text", value: "" },
        { name: "transactionDate", fieldType: "date", value: "" },
        // {
        //   name: "settlementStatus",
        //   fieldType: "select",
        //   options: ["Successful", "Failed", "Pending"],
        //   value: "",
        // },
        { name: "transactionReference", fieldType: "text", value: "" },
      ],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
    };
  }

  handlePageChange = async (
    currentPage: number,
    pageSize: number,
    data?: IFilterData
  ) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true });
    data = typeof data === "undefined" ? this.state.data : data;
    try {
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const institution = await institutionsService.retrieveInstitutionById(
        decodedToken?.["InstitutionId"]
      );
      data = JSON.parse(
        JSON.stringify(data).replace(
          new RegExp("transactionReference", "g"),
          "retrievalReferenceNo"
        )
      );
      // data = JSON.parse(
      //   JSON.stringify(data).replace(
      //     new RegExp("mandateReference", "g"),
      //     "m.LoanAccountNo"
      //   )
      // );
      data = JSON.parse(
        JSON.stringify(data).replace(
          new RegExp("transactionDate", "g"),
          "timeSwept"
        )
      );
      const reportItems =
        await reportsService.filterSuccessfulOutgoingRepaymentsReport(
          data,
          institution.data.code,
          pageSize,
          currentPage
        );
      this.setState({
        tableItems: mapEntityList({
          entityList: reportItems.data.item2,
          properties: [
            {
              oldName: "loanAccountInstitution",
              newName: "Outflow Institution",
            },
            { oldName: "linkedAccountNo", newName: "Outflow Account" },
            { oldName: "loanAccountNo", newName: "Loan Reference" },
            { oldName: "amountOutstanding", newName: "amountOutstanding" },
            { oldName: "amountAttempted", newName: "amountAttempted" },
            { oldName: "amountDebited", newName: "amountDebited" },
            { oldName: "customerName", newName: "customerName" },
            {
              oldName: "accountCreditedNumber",
              newName: "creditAccountNumber",
            },
            { oldName: "accountDebitedNumber", newName: "debitAccountNumber" },
            { oldName: "timeSwept", newName: "timeSwept" },
            // { oldName: "uniqueId", newName: "recovaReference" },
            { oldName: "customerType", newName: "customerType" },
            {
              oldName: "retrievalReferenceNo",
              newName: "transactionReference",
            },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: reportItems.data.item1,
        fetching: false,
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
        // this.setState({ errors, fetching: false });
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        // this.setState({ errors, fetching: false });
      }
    } finally {
      this.setState({ fetching: false });
    }
  };

  handleOnExport = async (type: string) => {
    try {
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      switch (type) {
        case "XLSX":
        case "CSV":
          return await reportsService.downloadOutgoingRepaymentsReport(
            decodedToken?.["InstitutionCode"],
            type
          );
        case "PDF":
          const response =
            await reportsService.downloadOutgoingRepaymentsReport(
              decodedToken?.["InstitutionCode"],
              type
            );
          printPDF(response?.data);
          break;
        default:
          break;
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        //this.setState({ errors, fetching: false });
      }
    }
  };

  fetchItemById = async (item: any) => {
    // await activityTrailService.saveActivityTrail({
    //   actionType: "View Details",
    //   description: `Viewed details of Branch ${item.name}`
    // });
    this.setState({ fetchingDetail: true });
    // console.log("fetching branch with id: ", item.id);
    const report = await reportsService.retrieveRepaymentsReportById(item.id);
    this.setState({ item: report.data, fetchingDetail: false });
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }

  render() {
    const {
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Outflow Repayments Report</b>
              </h3>
            </div>
            <SuccessfulOutgoingRepaymentsReportList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              initialValues={{
                data: this.state.data,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: this.state.filterValueInput,
                item: {},
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                showDetailsModal,
              }) => (
                <Fragment>
                  <OutgoingRepaymentReportDetail
                    showDetailsModal={showDetailsModal}
                    toggleDetailsModal={onToggleDetailsModal}
                    item={item}
                  />
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="SuccessfulOutgoingRepaymentsReport"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                              amountColumn={[
                                "amountDebited",
                                "amountOutstanding",
                                "amountAttempted",
                              ]}
                              timeColumn={["timeSwept"]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></SuccessfulOutgoingRepaymentsReportList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SuccessfulOutgoingRepaymentsReport;
