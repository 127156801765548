import http from "../httpService";

const sweepBaseAddress = process.env.REACT_APP_SWEEP_BASEADDRESS;
export function retrieveTransactionByReference(
  institutionCode: string,
  transactionReference: string
) {
  // console.log("testing: ", sweepBaseAddress);
  return http.get(
    sweepBaseAddress +
      `transactions/GetTransactionsByInstitutionReference?institutionCode=${institutionCode}&referenceNo=${transactionReference}`
  );
}

export default {
  retrieveTransactionByReference,
};
