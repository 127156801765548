import React, { Fragment } from "react";
import { toast } from "react-toastify";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import DetailItemSelectComponent, { ISelectItems } from "../../../components/detailItemSelectBox";
import _ from "lodash";
import { Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import Skeleton from "react-loading-skeleton";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import collectionValidationService from "../../../services/collection/collectionValidationService";
import institutionCollectionSettingService from "../../../services/collection/institutionCollectionSettingService";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../../services/auth/authService";

interface InstitutionCollectionSettingFields {
  recoverySwitchMode: "RepaymentDefault" | "EndOfLoanTenure";
  switchRecoveryWindowInDays: string;
}

class InstitutionCollectionSettingForm extends BaseFormComponent<InstitutionCollectionSettingFields> { }

interface IInstitutionCollectionSettingProps { }

interface IInstitutionCollectionSettingState {
  instCollectionSetting: any;
  editing: boolean;
  fetching: boolean;
}

class InstitutionCollectionSetting extends React.Component<
  IInstitutionCollectionSettingProps,
  IBaseFormState & IInstitutionCollectionSettingState
> {
  constructor(props: IInstitutionCollectionSettingProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      instCollectionSetting: {},
      // formChangeFunction: null,
      fetching: false,
      editing: false
    };
  }

  async componentDidMount() {
    try {
      this.setState({ fetching: true });
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const institutionCollectionSetting = await institutionCollectionSettingService.retrieveInstitutionCollectionsSettings(decodedToken?.["InstitutionCode"]);

      this.setState({
        instCollectionSetting: institutionCollectionSetting.data,
        fetching: false
      });
    } catch (error) {
      // console.log("error: ", error);
      // console.log("error: ", error.response);
      if (error.response.status === 400 || error.response.status === 409) {
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // return false;
      }
      this.setState({ fetching: false });
    }
  }

  onFormSubmit(fields: InstitutionCollectionSettingFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors: fields.recoverySwitchMode == "RepaymentDefault" ? collectionValidationService.validateModifyCollectionConfigForm(
            fields
          ) : [],
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: InstitutionCollectionSettingFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      // console.log("starting submission...");
      this.setState({ submitting: true });
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const payload: any = {};

      payload.recoverySwitchMode = fields.recoverySwitchMode;
      payload.switchRecoveryWindowInDays = Number(fields.switchRecoveryWindowInDays);

      const response = await institutionCollectionSettingService.editInstitutionCollectionsSettings(payload, decodedToken?.["InstitutionCode"]);

      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Modify Institution Collection Setting",
          description: `Modified Institution Collection Setting`,
        });

        toast.info(`Institution Collection Setting modified successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      const { ...instCollectionSetting } = this.state.instCollectionSetting;
      instCollectionSetting.recoverySwitchMode = fields.recoverySwitchMode;
      instCollectionSetting.switchRecoveryWindowInDays = fields.switchRecoveryWindowInDays;

      this.setState({ submitting: false, editing: false, instCollectionSetting });
      onReloadFieldsCB();
    } catch (error) {
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      // console.log("error: ", error.response.data);
      return false;
    } finally {
      return true;
    }
  }

  toggleEditMode = () => {
    this.setState({ editing: !this.state.editing })
  }

  render() {
    const { instCollectionSetting, editing, submitting, fetching, validated, errors } = this.state;

    let recoverySwitchModeInputData: ISelectItems[] = [
      "RepaymentDefault", "EndOfLoanTenure"
    ].map((item) => ({
      name: item,
      value: item,
    }));

    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Collection Settings</b>
              </h3>
            </div>
            {_.isEmpty(instCollectionSetting) ? (
              <Skeleton count={4} height={30} />
            ) : (
              <InstitutionCollectionSettingForm
                initialValues={{ recoverySwitchMode: instCollectionSetting.recoverySwitchMode, switchRecoveryWindowInDays: instCollectionSetting.switchRecoveryWindowInDays }}
                FormComponent={({
                  fields: { recoverySwitchMode, switchRecoveryWindowInDays },
                  onChange,
                  onReloadFields,
                  onHandleSubmit,
                }) => (
                  <form action="#">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="form-row">
                          <div className="col-md-12">
                            {_.isEmpty(instCollectionSetting) ? (
                              <Skeleton count={4} height={30} />
                            ) : (
                              <div className="form-row">
                                <div className="col-md-6">
                                  {!_.isEmpty(instCollectionSetting) && fetching ? (
                                    <Skeleton count={3} width={300} />
                                  ) : (
                                    <dl className="row">
                                      <DetailItem
                                        label="Max Collection Retry Per Day"
                                        value={instCollectionSetting.maxCollectionRetryPerDay}
                                        labelSize={5}
                                        valueSize={7}
                                      />
                                      <DetailItem
                                        label="Max Collection Pauses Per Schedule"
                                        value={instCollectionSetting.maxCollectionPausesPerSchedule}
                                        labelSize={5}
                                        valueSize={7}
                                      />
                                      {editing ? (
                                        <DetailItem
                                          label="Switch Recovery Window In Days"
                                          value={
                                            recoverySwitchMode == "EndOfLoanTenure" ? (
                                              ""
                                            ) : (
                                              <DetailItemInputComponent
                                                id="switchRecoveryWindowInDays"
                                                type="number"
                                                name="switchRecoveryWindowInDays"
                                                placeholder=""
                                                value={switchRecoveryWindowInDays}
                                                required={true}
                                                validated={validated}
                                                errors={errors}
                                                onChange={onChange}
                                              />
                                            )
                                          }
                                          labelSize={5}
                                          valueSize={7}
                                        />
                                      ) : (
                                        <DetailItem
                                          label="Switch Recovery Window In Days"
                                          value={switchRecoveryWindowInDays}
                                          labelSize={5}
                                          valueSize={7}
                                        />
                                      )}
                                    </dl>
                                  )}
                                </div>
                                <div className="col-md-6">
                                  {!_.isEmpty(instCollectionSetting) && fetching ? (
                                    <Skeleton count={3} width={300} />
                                  ) : (
                                    <dl className="row">
                                      <DetailItem
                                        label="Retry Period Interval"
                                        value={instCollectionSetting.retryPeriodInterval}
                                        labelSize={5}
                                        valueSize={7}
                                      />
                                      {editing ? (
                                        <DetailItem
                                          label="Recovery Switch Mode"
                                          value={
                                            <DetailItemSelectComponent
                                              id="recoverySwitchMode"
                                              name="recoverySwitchMode"
                                              value={recoverySwitchMode}
                                              required={true}
                                              validated={validated}
                                              items={recoverySwitchModeInputData}
                                              errors={errors}
                                              onChange={onChange}
                                            />
                                          }
                                          labelSize={5}
                                          valueSize={7}
                                        />
                                      ) : (
                                        <DetailItem
                                          label="Recovery Switch Mode"
                                          value={recoverySwitchMode}
                                          labelSize={5}
                                          valueSize={7}
                                        />
                                      )}
                                    </dl>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <hr />
                      {editing ? (
                        <React.Fragment>
                          <div className="card-body">
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                              disabled={this.state.submitting}
                              onClick={(e) => {
                                onHandleSubmit(
                                  e,
                                  ActionTypes.UPDATE,
                                  "Institution Collection Setting",
                                  () => {
                                    this.onFormSubmit(
                                      { recoverySwitchMode, switchRecoveryWindowInDays },
                                      onReloadFields
                                    );
                                  }
                                );
                              }}
                            >
                              {this.state.submitting === false ? (
                                <React.Fragment>
                                  <i className="fas fa-lg fa-save mr-3" /> Save
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                  Please wait...
                                </React.Fragment>
                              )}
                            </button>
                          </div>
                        </React.Fragment>
                      ) : (
                        !_.isEmpty(instCollectionSetting) && (
                          <div className="card-body">
                            <Button
                              size="sm"
                              variant="primary"
                              disabled={submitting}
                              onClick={this.toggleEditMode}
                            >
                              <i className="far fa-edit mr-3" />
                              Edit
                            </Button>
                          </div>
                        )
                      )}
                    </div>
                  </form>
                )}
              ></InstitutionCollectionSettingForm>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default InstitutionCollectionSetting;
