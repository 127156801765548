import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";
import { IFilterData } from "../approval/approvalItemService";
import { decodeToken } from "react-jwt";
import DisputeDetail from "../../pages/dispute/disputeDetail";
import axios from "axios";

interface ICommonTypes {
  retrievalReferenceNumber: string;
  customerPhoneNumber: string;
}
interface ISearchDisputeData extends ICommonTypes {
  disputeStatus: string;
}
interface ISaveDispute extends ICommonTypes {
  comment: string;
  disputeConditionId: string;
  loanAccountInstitution: string;
  loanAccountNumber: string;
  linkedAccountInstitution: string;
  linkedAccountNumber: string;
  amountDebited: string;
  uniqueId: string;
  timeSwept: Date;
  narration: string;
  assignedDisputeConditionDocuments: number[];
  institutionId: number;
  mandateId: number;
  dateCreated: Date;
}
interface IUpdateDispute {
  sweepTransactionReference: string;
  institutionId: number;
  disputingInstitutionId: number;
  initiatingUserId: number;
  initiatingUserName: string;
  disputeConditionId: number;
  customerEmail: string;
  disputeStatus: string;
}

const recovaDisputeBaseAddress = process.env.REACT_APP_DISPUTE_BASEADDRESS;
export function saveDispute(dispute: ISaveDispute) {
  var url = recovaDisputeBaseAddress + `Dispute`;

  return http.post(url, dispute, {
    url: url,
    data: DisputeDetail,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function updateDispute(dispute: IUpdateDispute, disputeId: number) {
  var url = recovaDisputeBaseAddress + `Dispute/${disputeId}`;

  return http.put(url, dispute, {
    url: url,
    data: DisputeDetail,
    method: "put",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveDisputeById(disputeId: number) {
  var url = recovaDisputeBaseAddress + `Dispute/${disputeId}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveAllDisputes() {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  var url =
    recovaDisputeBaseAddress +
    `Dispute/GetAll?institutionId=${decodedToken?.["InstitutionId"]}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

// export function searchDisputes(
//   institutionCode: string,
//   data: ISearchDisputeData,
//   pageSize: number,
//   pageNumber: number
// ) {
//   return http.get(
//     recovaDisputeBaseAddress +
//     `Dispute/SearchDisputes?institutionCode=${institutionCode}&retrievalReferenceNumber=${data.retrievalReferenceNumber}&customerPhoneNumber=${data.customerPhoneNumber}&disputeStatus=${data.disputeStatus}&pageSize=${pageSize}&pageNumber=${pageNumber}`
//   );
// }

// export function searchOtherInstitutionsDisputes(
//   institutionCode: string,
//   data: ISearchDisputeData,
//   pageSize: number,
//   pageNumber: number
// ) {
//   return http.get(
//     recovaDisputeBaseAddress +
//     `Dispute/SearchOtherInstitutionsDispute?institutionCode=${institutionCode}&retrievalReferenceNumber=${data.retrievalReferenceNumber}&customerPhoneNumber=${data.customerPhoneNumber}&disputeStatus=${data.disputeStatus}&pageSize=${pageSize}&pageNumber=${pageNumber}`
//   );
// }

export function filterDisputes(
  payload: IFilterData,
  pageSize: number,
  pageNumber: number
) {
  var url =
    recovaDisputeBaseAddress +
    `Dispute/FilterDisputes?pageSize=${pageSize}&pageNumber=${pageNumber}`;

  return http.post(url, payload, {
    url: url,
    method: "post",
    data: payload,
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

// export function validateTransactionReference(transactionReference: string) {
//   return http.get(
//     recovaDisputeBaseAddress +
//     `Dispute/ValidateTransactionReference?transactionReference=${transactionReference}`
//   );
// }

export function downloadMyDisputesReport(institutionCode, type: String) {
  if (type.toLowerCase() === "pdf") {
    var url = recovaDisputeBaseAddress + `Dispute/DownloadMyDisputesReportPDF`;

    return http.get(url, {
      url: url,
      method: "get",
      headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
  } else {
    var url2 =
      recovaDisputeBaseAddress +
      `Dispute/DownloadMyDisputesReport?type=${type}`;
    axios({
      url: url2,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${getCurrentUserSession().token}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Users.${type}`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }
}

export function downloadOtherInstitutionsDisputesReport(
  institutionCode,
  type: String
) {
  if (type.toLowerCase() === "pdf") {
    var url =
      recovaDisputeBaseAddress +
      `Dispute/DownloadOtherInstitutionsDisputesReportPDF`;

    return http.get(url, {
      url: url,
      method: "get",
      headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
  } else {
    var url2 =
      recovaDisputeBaseAddress +
      `Dispute/DownloadOtherInstitutionsDisputesReport?type=${type}`;
    axios({
      url: url2,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${getCurrentUserSession().token}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Users.${type}`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }
}

export default {
  saveDispute,
  updateDispute,
  retrieveDisputeById,
  retrieveAllDisputes,
  // searchDisputes,
  // searchOtherInstitutionsDisputes,
  filterDisputes,
  // validateTransactionReference,
  downloadMyDisputesReport,
  downloadOtherInstitutionsDisputesReport,
};
