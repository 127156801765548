import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";
import { status } from "./approvableService";
import { decodeToken } from "react-jwt";

interface IApprovalRule {
  id: number;
  institutionID: number;
  functionID: number;
  roleID: number;
  level: number;
  approverCount: number;
  applicableAmount: number;
  status: status;
  combinationNo: number;
  financialGroupId: number;
}

const approvalServiceBaseAddress = process.env.REACT_APP_APPROVAL_BASEADDRESS;
// export function retrieveApprovalRules() {
//   return http.get(
//     approvalServiceBaseAddress +
//       `approvalRule/${getCurrentUserSession().institutionId}`
//   );
// }

export function retrieveApprovalRule(id: number) {
  var url =
    approvalServiceBaseAddress +
    `approvalRule/${id}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function createApprovalRule(approvalRule: IApprovalRule) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  var url = approvalServiceBaseAddress + `approvalRule`;
  approvalRule.institutionID = decodedToken?.["InstitutionId"];

  return http.post(url, approvalRule, {
    url: url,
    method: "post",
    data: approvalRule,
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function removeApprovalRule(id: number) {
  var url = approvalServiceBaseAddress + `approvalRule/removerule/${id}`;

  return http.post(url, null, {
    url: url,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export default {
  // retrieveApprovalRules,
  retrieveApprovalRule,
  createApprovalRule,
  removeApprovalRule,
};
