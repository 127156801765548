import * as React from "react";
// import _ from "lodash";
import { Fragment } from "react";
import {
  IFilterData,
  BaseListComponentState,
} from "../../../components/BaseListComponent";
import {
  createFilterData,
  mapEntityList,
} from "./../../../services/utility/autoMapperService";
import BaseListComponent from "./../../../components/BaseListComponent";
import ItemsTable, {
  filterFieldData,
} from "../../../components/tableComponents/itemsTable";
import disbursementRequestService from "../../../services/handshake/disbursementRequestService";
import TableFilterPane from "./../../../components/tableComponents/tableFilterPane";
import { getCurrentUserSession } from "../../../services/auth/authService";
import { decodeToken } from "react-jwt";
import DisbursementRequestDetail from "./disbursementRequestDetail";

export interface DisbursementRequestsProps {}

export interface DisbursementRequestsState extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
}

class DisbursementRequestsList extends BaseListComponent<DisbursementRequestsState> {}

class DisbursementRequests extends React.Component<
  DisbursementRequestsProps,
  DisbursementRequestsState
> {
  constructor(props: DisbursementRequestsProps) {
    super(props);
    this.state = {
      data: createFilterData([]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
    };
  }

  handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true });
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    data = typeof data === "undefined" ? this.state.data : data;
    try {
      data.institutionId = {
        item1: "=",
        item2: decodedToken?.["InstitutionId"]?.toString() || "break",
      };
      const disbursementRequests =
        await disbursementRequestService.filterDisbursementRequests(
          data,
          pageSize,
          currentPage
        );
      // console.log("payload: ", data);
      this.setState({
        tableItems: mapEntityList({
          entityList: disbursementRequests.data.item2,
          properties: [
            { oldName: "customerBvn", newName: "Customer Bvn" },
            { oldName: "gender", newName: "gender" },
            { oldName: "loanAmount", newName: "loanAmount" },
            {
              oldName: "totalRepaymentExpected",
              newName: "totalRepaymentExpected",
            },
            { oldName: "receiverName", newName: "customerName" },
            { oldName: "disbursementStage", newName: "disbursementStage" },
            { oldName: "dateCreated", newName: "dateCreated" },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: disbursementRequests.data.item1,
        fetching: false,
      });
    } catch (ex) {
    } finally {
      this.setState({ fetching: false });
    }
  };

  handleOnExport = async (type: string) => {};

  fetchItemById = async (item: any) => {
    try {
      this.setState({ fetchingDetail: true });
      var disbursementRequest =
        await disbursementRequestService.getDisbursementRequestById(item.id);
      this.setState({ fetchingDetail: false, item: disbursementRequest.data });
    } catch (ex) {
    } finally {
      this.setState({
        fetchingDetail: false,
      });
    }
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "" && key !== "institutionId") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }

  render() {
    const {
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Disbursement Requests</b>
              </h3>
            </div>
            <DisbursementRequestsList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              initialValues={{
                data: createFilterData([]) as IFilterData,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: [],
                item: {},
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                onToggleEditModal,
                showDetailsModal,
                showEditModal,
              }) => (
                <Fragment>
                  <DisbursementRequestDetail
                    showDetailsModal={showDetailsModal}
                    toggleDetailsModal={onToggleDetailsModal}
                    item={item}
                  />
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="Disbursement Requests"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                              amountColumn={[
                                "loanAmount",
                                "totalRepaymentExpected",
                              ]}
                              timeColumn={["dateCreated"]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default DisbursementRequests;
