import React, { Component } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import {
  firstCharToUpper,
  splitAtUpper,
  generateGUID,
  convertKoboToNaira,
  formatTimeString,
  formatCurrency,
  printPDF,
} from "../../services/utility/textFormatService";
import "./itemsTable.css";
import TableFiltermodal from "./tableFilterModal";
import { IFilterData } from "../../services/approval/approvalItemService";
import { getCurrentUsersRoles } from "../../services/auth/authService";
import { read, utils, writeFileXLSX } from "xlsx";
import { Table } from "react-bootstrap";

export type inputFieldTypes = "text" | "select" | "number" | "date";
export type filterFieldData = IFilterField[];
export interface IFilterField {
  name: string;
  fieldType: inputFieldTypes;
  options?: string[];
  value: string | number;
}

export interface IItemsTableProps {
  items: any;
  filterValueInput: filterFieldData;
  name: string;
  currentPage: number;
  pageSize: number;
  totalSize: number;
  fetching: boolean;
  handlePageChange: any;
  onViewDetails: any;
  handleOnExport: any;
  useCustomExport?: boolean;
  amountColumn?: string[];
  amountInNaira?: boolean;
  timeColumn?: string[];
  showTableFiltermodal: boolean;
  toggleTableFiltermodal: any;
  data: IFilterData;
  disableViewDetails?: boolean;
}

export interface IItemsTableState {}

class ItemsTable extends Component<IItemsTableProps, IItemsTableState> {
  state = {};

  componentDidMount() {}
  formatCells = (key: string, item): string => {
    const { amountColumn, timeColumn, amountInNaira } = this.props;
    if (!_.isNil(amountColumn)) {
      if (amountColumn.includes(key)) {
        if (amountInNaira) {
          return formatCurrency(item[key], 2);
        }
        return convertKoboToNaira(item[key], 2);
      } else if (!_.isNil(timeColumn)) {
        if (timeColumn.includes(key)) {
          return formatTimeString(item[key]);
        }
      }
    } else if (!_.isNil(timeColumn)) {
      if (timeColumn.includes(key)) {
        return formatTimeString(item[key]);
      }
    }
    return item[key];
  };

  handleOnExportTableData = (format) => {
    switch (format) {
      case "csv":
        var csvString = "";
        const items = this.props.items;
        var keys = Object.keys(items[0]);
        keys.forEach((key) => {
          if (key !== "id") {
            csvString += key + ",";
          }
        });
        csvString += "\r\n";
        items.forEach((item, index) => {
          keys.forEach((key) => {
            if (key !== "id") {
              if (_.isObject(items[index][key])) {
                csvString += items[index][key].props.children + ",";
              } else {
                csvString += items[index][key] + ",";
              }
            }
          });
          csvString += "\r\n";
        });
        csvString = "data:application/csv," + encodeURIComponent(csvString);
        var x = document.createElement("A");
        x.setAttribute("href", csvString);
        x.setAttribute("download", `${this.props.name}.csv`);
        document.body.appendChild(x);
        x.click();
        break;
      case "xlsx":
        const elt = document.getElementsByClassName("paged-output")[0];
        const wb = utils.table_to_book(elt);
        writeFileXLSX(wb, `${this.props.name}.xlsx`);
        break;
      case "pdf":
        printPDF(document.getElementsByClassName("paged-output")[0].outerHTML);
        break;
      default:
        break;
    }
  };

  render() {
    const {
      items,
      filterValueInput,
      name,
      currentPage,
      pageSize,
      totalSize,
      fetching,
      handlePageChange,
      onViewDetails,
      handleOnExport,
      useCustomExport,
      amountColumn,
      showTableFiltermodal,
      toggleTableFiltermodal,
      data,
      disableViewDetails,
    } = this.props;
    const headers =
      items.length > 0
        ? Object.keys(items[0])
        : `There are no ${splitAtUpper(firstCharToUpper(name))} available`;

    // const pageSize = Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 1;
    const maxPage = Math.ceil(totalSize / pageSize);
    const pageOptionItems = _.range(1, maxPage + 1, 1);
    const rowPerPageOptionItems: number[] = [10, 25, 50, 100];

    // const dueDateInputData: ISelectItems[] = .map(item => {
    //     return { name: item.toString(), value: item }
    //   });

    return (
      <React.Fragment>
        <nav aria-label="Page navigation example clearfix">
          <ul
            className="pagination float-left"
            style={{ marginBottom: "10px", height: "32px" }}
          >
            <li
              className={currentPage === 1 ? "page-item disabled" : "page-item"}
            >
              <button
                type="button"
                onClick={() => handlePageChange(1, pageSize, data)}
                className="page-link"
              >
                <i className="fas fa-step-backward" />
              </button>
            </li>
            <li
              className={currentPage === 1 ? "page-item disabled" : "page-item"}
            >
              <button
                type="button"
                onClick={() =>
                  handlePageChange(currentPage - 1, pageSize, data)
                }
                className="page-link"
              >
                <i className="fas fa-chevron-left" />
              </button>
            </li>
            <li className="page-item">
              <p className="page-link" style={{ height: "30px" }}>
                Page
              </p>
            </li>
            <li className="page-item">
              <select
                id="currentPage"
                name="currentPage"
                className="custom-select select2 form-control form-control-sm r-0 light s-12 page-link"
                style={{ width: "55px" }}
                value={currentPage}
                onChange={(e) =>
                  handlePageChange(e.currentTarget.value, pageSize, data)
                }
              >
                {pageOptionItems.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              {/* <input
                                style={{ width: "35px" }}
                                className="form-control form-control-sm"
                                id="currentPage"
                                type="number"
                                value={currentPage}
                                onChange={() => handlePageChange}
                            /> */}
            </li>
            <li className="page-item">
              <p className="page-link" style={{ height: "30px" }}>
                of
              </p>
            </li>
            <li className="page-item">
              <p className="page-link" style={{ height: "30px" }}>
                {items.length > 0 ? maxPage : ""}
              </p>
            </li>
            <li
              className={
                maxPage === currentPage ? "page-item disabled" : "page-item"
              }
            >
              <button
                type="button"
                onClick={() =>
                  handlePageChange(currentPage + 1, pageSize, data)
                }
                className="page-link"
              >
                <i className="fas fa-chevron-right" />
              </button>
            </li>
            <li
              className={
                maxPage === currentPage ? "page-item disabled" : "page-item"
              }
            >
              <button
                type="button"
                onClick={() => handlePageChange(maxPage, pageSize, data)}
                className="page-link"
              >
                <i className="fas fa-step-forward" />
              </button>
            </li>
            <li className="page-item disabled">
              <a href="#!" className="page-link">
                <i className="fas fa-grip-lines-vertical" />
              </a>
            </li>
            <li
              className={fetching === true ? "page-item disabled" : "page-item"}
            >
              <button
                type="button"
                className="page-link"
                onClick={() => handlePageChange(currentPage, pageSize, data)}
              >
                <i
                  className={
                    fetching === true ? "fas fa-spinner fa-spin" : "fas fa-sync"
                  }
                />
              </button>
            </li>
            <li className="page-item disabled">
              <a href="#!" className="page-link">
                <i className="fas fa-grip-lines-vertical" />
              </a>
            </li>
            <li className="page-item">
              {fetching === true
                ? (getCurrentUsersRoles().includes("exportdocuments") ||
                    getCurrentUsersRoles().includes("remotesession")) && (
                    <React.Fragment>
                      <Skeleton width={50} height={20} /> <br />
                    </React.Fragment>
                  )
                : (getCurrentUsersRoles().includes("exportdocuments") ||
                    getCurrentUsersRoles().includes("remotesession")) && (
                    <button
                      type="button"
                      className="btn btn-sm btn-success waves-effect sm-doc"
                      style={{ margin: "5px", padding: "2px 10px 2px 10px" }}
                      onClick={() => {
                        if (useCustomExport) {
                          handleOnExport("xlsx");
                        } else {
                          this.handleOnExportTableData("xlsx");
                        }
                      }}
                    >
                      <i className="fas fa-file-excel mr-2" />
                      XLSX
                    </button>
                  )}
            </li>
            <li className="page-item">
              {fetching === true ? (
                <React.Fragment>
                  <Skeleton width={50} height={20} /> <br />
                </React.Fragment>
              ) : (
                (getCurrentUsersRoles().includes("exportdocuments") ||
                  getCurrentUsersRoles().includes("remotesession")) && (
                  <button
                    type="button"
                    className="btn btn-sm btn-danger waves-effect sm-doc"
                    style={{ margin: "5px", padding: "2px 10px 2px 10px" }}
                    onClick={() => {
                      if (useCustomExport) {
                        handleOnExport("pdf");
                      } else {
                        this.handleOnExportTableData("pdf");
                      }
                    }}
                    // onClick={() => handleOnExport("PDF")}
                  >
                    <i className="fas fa-file-pdf mr-2" />
                    PDF
                  </button>
                )
              )}
            </li>
            <li className="page-item">
              {fetching === true ? (
                <React.Fragment>
                  <Skeleton width={50} height={20} /> <br />
                </React.Fragment>
              ) : (
                (getCurrentUsersRoles().includes("exportdocuments") ||
                  getCurrentUsersRoles().includes("remotesession")) && (
                  <button
                    type="button"
                    className="btn btn-sm btn-primary waves-effect sm-doc"
                    style={{ margin: "5px", padding: "2px 10px 2px 10px" }}
                    onClick={() => this.handleOnExportTableData("csv")}
                  >
                    <i className="fas fa-file-csv mr-2" />
                    CSV
                  </button>
                )
              )}
            </li>
          </ul>
          <div className="float-right" style={{ height: "30px" }}>
            {/* <span className="mr-4">
                            {items.length > 0
                                ? `Showing ${(currentPage - 1) * pageSize + 1} - ${(currentPage - 1) * pageSize + items.length} of ${totalSize}`
                                : ""}
                        </span> */}
            <button
              type="button"
              className="btn btn-sm btn-primary"
              data-toggle="modal"
              data-target="#staticBackdrop"
              onClick={() => toggleTableFiltermodal([], false)}
            >
              <i className="fas fa-filter mr-2"></i>Filter
            </button>
          </div>
        </nav>

        {fetching === true ? (
          <Skeleton count={10} height={25} />
        ) : (
          <div className="table-responsive text-nowrap">
            <table className="table table-hover table-content table-striped table-bordered table-sm paged-output">
              <thead>
                <tr>
                  <th
                    scope="col"
                    style={{
                      width: "5px",
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                    }}
                  >
                    #
                  </th>
                  {!Array.isArray(headers) ? (
                    <th
                      className="font-weight-lighter font-italic text-truncate"
                      style={{
                        textAlign: "center",
                        color: "#FE825E",
                      }}
                    >
                      <h6>{headers}...</h6>
                    </th>
                  ) : (
                    headers.map((header: string) => (
                      <th
                        key={header}
                        className="text-truncate"
                        scope="col"
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontWeight: 500,
                          display: header === "id" ? "none" : "",
                        }}
                      >
                        {splitAtUpper(firstCharToUpper(header))}
                      </th>
                    ))
                  )}
                  {!disableViewDetails && (
                    <th
                      style={{
                        width: "5px",
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                        fontWeight: 500,
                      }}
                    >
                      Action
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {items.map((item: any) => (
                  <React.Fragment key={items.indexOf(item) + 1}>
                    <tr
                      onDoubleClick={() => {
                        if (disableViewDetails) {
                        } else onViewDetails(item);
                      }}
                    >
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                        }}
                      >
                        {/* {items.indexOf(item) + 1} */}
                        {items.indexOf(item) +
                          ((currentPage - 1) * pageSize + 1)}
                      </td>
                      {Object.keys(item).map((_key) => (
                        <td
                          className="text-truncate"
                          key={generateGUID()}
                          align={
                            !_.isNil(amountColumn)
                              ? amountColumn.includes(_key)
                                ? "right"
                                : "left"
                              : "left"
                          }
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                            fontSize: "small",
                            fontWeight: 400,
                            cursor: "pointer",
                            display: _key === "id" ? "none" : "",
                          }}
                        >
                          {this.formatCells(_key, item)}
                          {/* {item[_key]} */}
                        </td>
                      ))}
                      {!disableViewDetails && (
                        <td
                          style={{
                            width: "50px",
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <span
                            style={{
                              display: "inline-block",
                              cursor: "pointer",
                            }}
                          >
                            <i
                              className="fas fa-eye mr-2"
                              onClick={() => onViewDetails(item)}
                            >
                              {" "}
                            </i>
                          </span>
                        </td>
                      )}
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
            <div className="float-right">
              <span className="mr-4">
                Rows per page:
                <select
                  id="pageSize"
                  name="pageSize"
                  className="custom-select select2 form-control form-control-sm r-0 light s-12 ml-2"
                  style={{ width: "55px", cursor: "pointer", border: "none" }}
                  value={pageSize}
                  onChange={(e) =>
                    handlePageChange(currentPage, e.currentTarget.value, data)
                  }
                >
                  {rowPerPageOptionItems.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </span>
              <span className="mr-4">
                {items.length > 0
                  ? `Showing ${(currentPage - 1) * pageSize + 1} - ${
                      (currentPage - 1) * pageSize + items.length
                    } of ${totalSize}`
                  : ""}
              </span>
            </div>
          </div>
        )}

        <TableFiltermodal
          filterFields={filterValueInput}
          showTableFiltermodal={showTableFiltermodal}
          toggleTableFiltermodal={toggleTableFiltermodal}
        />
      </React.Fragment>
    );
  }
}

export default ItemsTable;
