import React, { useEffect } from "react";
import { Switch, useLocation } from "react-router-dom";
import ProtectedRoute from "../../components/protectedRoute";
import { getCurrentUserSession, getCurrentUsersRoles } from "../../services/auth/authService";
import SystemSettings from "./../settings/systemSettings";
import Dashboard from "./dashboard";
import { retrieveSideNavContent } from "./sideNavContent";
import { generateGUID } from "./../../services/utility/textFormatService";
import RemoteView from "./../remoteView/remoteView";

interface IContentProps {
    setPathname: any;
}

function Content(props: IContentProps) {
    const currentUserSession = getCurrentUserSession();
    const currentUserRoles = getCurrentUsersRoles();
    const navContent = retrieveSideNavContent()
        .items.map((contentItem) => contentItem.children)
        .reduce(function (prev, next) {
            return prev.concat(next);
        });

    const location = useLocation();

    useEffect(() => {
        // console.log("*", location.pathname);
        props.setPathname(location.pathname);
    }, [location]);

    return (
        <Switch>
            {navContent.map((contentItem) =>
                contentItem.title === true
                    ? ""
                    : contentItem.roleFunctions.some((r) => getCurrentUsersRoles().includes(r)) &&
                      (Number(contentItem.children?.length) > 0 ? (
                          contentItem.children?.map(
                              (itemChild) =>
                                  itemChild.roleFunctions.some((r) => getCurrentUsersRoles().includes(r)) && (
                                      <ProtectedRoute
                                          key={generateGUID()}
                                          path={itemChild.url}
                                          currentUserSession={currentUserSession}
                                          currentUserRoles={currentUserRoles}
                                          component={itemChild.component}
                                      />
                                  )
                          )
                      ) : contentItem.component ? (
                          <ProtectedRoute
                              key={generateGUID()}
                              path={contentItem.url}
                              currentUserSession={currentUserSession}
                              currentUserRoles={currentUserRoles}
                              component={contentItem.component}
                          />
                      ) : (
                          ""
                      ))
            )}
            {getCurrentUsersRoles().includes("remoteview") && (
                <ProtectedRoute
                    path="/home/remoteview"
                    currentUserSession={currentUserSession}
                    currentUserRoles={currentUserRoles}
                    component={RemoteView}
                />
            )}
            {getCurrentUsersRoles().includes("viewsystemsettings") && (
                <ProtectedRoute
                    path="/home/settings"
                    currentUserSession={currentUserSession}
                    currentUserRoles={currentUserRoles}
                    component={SystemSettings}
                />
            )}
            {/* <ProtectedRoute
        path="/home/initiateSweep"
        currentUserSession={currentUserSession}
        currentUserRoles={currentUserRoles}
        component={InitiateSweep}
      /> */}
            <ProtectedRoute path="/home" currentUserSession={currentUserSession} currentUserRoles={currentUserRoles} component={Dashboard} />
            <ProtectedRoute path="/" currentUserSession={currentUserSession} currentUserRoles={currentUserRoles} component={Dashboard} />
        </Switch>
    );
}

export default Content;
