import React, { Children, Component } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import BaseFormComponent, { IBaseFormState } from "../../../../../../components/BaseFormComponent";
import FormInputComponent from "../../../../../../components/formInputComponent";
import SelectInputComponent, { ISelectItems } from "../../../../../../components/formSelectComponent";
import { EconomicSector } from "../../../../../../enums/economicSector";
import { LoanComputationMode } from "../../../../../../enums/loanComputationMode";
import { ProductType } from "../../../../../../enums/productType";
import textFormatService from "../../../../../../services/utility/textFormatService";
import loanRequestService from "../../../../../../services/hybridAppraisal/loanRequestService";
import { ActionTypes } from "../../../../../../enums/actionTypes";
import hybridAppraisalValidationService from "../../../../../../services/hybridAppraisal/hybridAppraisalValidationService";
import mandateService from "../../../../../../services/mandate/mandateService";
import Repayment from "./repayment";
import { getCurrentUserSession, getCurrentUsersRoles } from "../../../../../../services/auth/authService";
import supplierService from "../../../../../../services/hybridAppraisal/supplierService";
import { decodeToken } from "react-jwt";
import loanProductService from "../../../../../../services/hybridAppraisal/loanProductService";
import RepaymentSchedule from "./repaymentSchedule";
import loanCategoryService from "../../../../../../services/hybridAppraisal/loanCategoryService";

interface OtherDetailsFields {
  schoolName: string;
  schoolAddress: string;
  schoolContactPerson: string;
  schoolContactNumber: string;
  schoolEmailAddress: string;
  schoolFeesInvoiceNumber: string;
  schoolFeesAmount: string;
  schoolContactPersonDesignation: string;
  childName: string;
  childDob: string;
  childClass: string;
  airlineName: string;
  travelAgency: string
  travelAgencyAddress: string;
  travelAgencyPhoneNumber: string;
  travelAgencyEmail: string;
  travelDestination: string;
  departureDate: string;
  returnDate: string;
  purposeOfTrip: string;
  flightTicketNumber: string;
  flightCost: string;
  travelAgencyContactPerson: string
  travelAgencyContactPersonDesignation: string;
  hospitalName: string
  hospitalAddress: string;
  hospitalPhoneNumber: string;
  hospitalEmail: string;
  patientName: string;
  hospitalPatientDOB: string;
  hospialContactPerson: string;
  hospialContactPersonDesignation: string;
  hospitalInvoiceNumber: string;
  hospitalBillAmount: string;
  lpoIssuer: string;
  lpoEmail: string;
  lpoInvoice: string;
  lpoLoanAmountRequested: string;
  lpoAddress: string;
  lpoContactPerson: string;
  lpoContactNumber: string;
  lpoContactPersonDesignation: string;

}

class OtherDetailsForm extends BaseFormComponent<OtherDetailsFields> { }

interface OtherDetailsProps {
  values: any;
  reloadItem: any;
  mandatoryFields: any;
  preferredRepaymentBank: string;
}
interface OtherDetailsState {
  showRepaymentSchedule: boolean;
  ownUpdate: boolean;
  item: any;
  children: any;
  schoolDetail: any;
  travelDetail: any;
  hospitalDetail: any;
  principalDetail: any;
}

class OtherDetails extends Component<
  OtherDetailsProps,
  IBaseFormState & OtherDetailsState
> {
  _isMounted: boolean;
  constructor(props: OtherDetailsProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      showRepaymentSchedule: false,
      item: {},
      ownUpdate: false,
      children: [],
      schoolDetail: [],
      travelDetail: [],
      hospitalDetail: [],
      principalDetail: []
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const decodedToken = decodeToken(getCurrentUserSession()?.token || "")

  }
  static getDerivedStateFromProps(
    nextProps: OtherDetailsProps,
    prevState: OtherDetailsState
  ) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isNull(nextProps.values)) {
      return null;
    }
    return {
      item: nextProps.values,
      // children: nextProps.values?.otherDetails ? nextProps.values?.otherDetails?.children : []
    };
  }


  onFormSubmit(fields: OtherDetailsFields, onReloadFieldsCB: any): boolean {
    const { mandatoryFields, values } = this.props;
    // const {  } = this.state;
    try {
      this.setState(
        { //errors: hybridAppraisalValidationService.validateLoanDetailsForm(fields, values.loanType, editLoanCategory, mandatoryFields), 
          ownUpdate: true
        },
        () => {
          // if (Object.keys(this.state.errors).length === 0) {
          return this.submit(fields, onReloadFieldsCB);
          // }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return false;
    }
    catch (error) {
      return false;
    }
  }
  async submit(
    fields: OtherDetailsFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const loanRequest = await loanRequestService.retrieveById(this.props.values.id);
      const loanRequestDetail = loanRequest.data;
      const payload: any = { ...loanRequestDetail };

      const children: any = [...this.state.children];
      const hospitalDetail: any = [...this.state.hospitalDetail];
      const travelDetail: any = [...this.state.travelDetail];
      const principalDetail: any = [...this.state.principalDetail];
      const schoolDetail: any = [...this.state.schoolDetail];


      const OtherDetails: any = {};
      OtherDetails.schoolDetail.children = children;
      OtherDetails.schoolDetail.schoolName = fields.schoolName;
      OtherDetails.schoolDetail.schoolAddress = fields.schoolAddress;
      OtherDetails.schoolDetail.schoolContactPerson = fields.schoolContactPerson;
      OtherDetails.schoolDetail.schoolContactNumber = fields.schoolContactNumber;
      OtherDetails.schoolDetail.schoolEmailAddress = fields.schoolEmailAddress;
      OtherDetails.schoolDetail.schoolContactPersonDesignation = fields.schoolContactPersonDesignation;
      OtherDetails.schoolDetail.schoolFeesAmount = fields.schoolFeesAmount;
      OtherDetails.schoolDetail.schoolFeesInvoiceNumber = fields.schoolFeesInvoiceNumber;
      // OtherDetails.lpoIssuer = fields.lpoIssuer;
      // OtherDetails.lpoInvoice = fields.lpoInvoice;
      // OtherDetails.lpoEmail = fields.lpoEmail
      // OtherDetails.lpoAddress = fields.lpoAddress;
      // OtherDetails.lpoContactPerson = fields.lpoContactPerson;
      // OtherDetails.lpoContactNumber = fields.lpoContactNumber;
      // OtherDetails.lpoLoanAmountRequested = fields.lpoLoanAmountRequested;
      // OtherDetails.lpoContactPersonDesignation = fields.lpoContactPersonDesignation;
      // OtherDetails.travelAgency = fields.travelAgency;
      // OtherDetails.travelAgencyAddress = fields.travelAgencyAddress;
      // OtherDetails.travelAgencyContactPerson = fields.travelAgencyContactPerson;
      // OtherDetails.travelAgencyContactPersonDesignation = fields.travelAgencyContactPersonDesignation;
      // OtherDetails.travelAgencyEmail = fields.travelAgencyEmail
      // OtherDetails.travelAgencyPhoneNumber = fields.travelAgencyPhoneNumber;
      // OtherDetails.travelDestination = fields.travelDestination;
      // OtherDetails.flightCost = fields.flightCost;
      // OtherDetails.flightTicketNumber = fields.flightTicketNumber;
      // OtherDetails.returnDate = fields.returnDate;
      // OtherDetails.departureDate = fields.departureDate;
      // OtherDetails.purposeOfTrip = fields.purposeOfTrip;
      // OtherDetails.travelDestination = fields.travelDestination;
      // OtherDetails.hospitalPatientDOB = fields.hospitalPatientDOB;
      // OtherDetails.hospialContactPerson = fields.hospialContactPerson;
      // OtherDetails.hospitalPhoneNumber = fields.hospitalPhoneNumber;
      // OtherDetails.hospialContactPersonDesignation = fields.hospialContactPersonDesignation;
      // OtherDetails.hospitalAddress = fields.hospitalAddress;
      // OtherDetails.hospitalBillAmount = fields.hospitalBillAmount
      // OtherDetails.hospitalEmail = fields.hospitalEmail;
      // OtherDetails.hospitalInvoiceNumber = fields.hospitalInvoiceNumber;
      // OtherDetails.hospitalName = fields.hospitalName;
      // OtherDetails.patientName = fields.patientName;

      OtherDetails.schoolDetail = schoolDetail
      OtherDetails.hospitalDetail = hospitalDetail
      OtherDetails.travelDetail = travelDetail
      OtherDetails.principalDetail = principalDetail

      payload.OtherDetails = OtherDetails;

      const response = await loanRequestService.updateLoanRequest(payload);
      if (response.status >= 200 && response.status <= 300) {
        toast.info(`Loan Details saved successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      }
      else {
        return false;
      }
      this.setState({ submitting: false, ownUpdate: true });
      this.resetFields();
      onReloadFieldsCB();
    }
    catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false, ownUpdate: true });
      return false;
    }
    finally {
      return true;
    }
  }

  handleOnAddChildren = (item: any) => {
    this.setState(
      {},
      () => {

        const children = [...this.state.children];
        children.push({
          childName: item.childName,
          childDob: item.childDob,
          childClass: item.childClass
        }
        );
        this.setState({ children });

        // onReloadFieldsCB(item);
      }
    );
  };
  handleOnRemoveChildren = (item: any) => {
    const children = [...this.state.children];
    if (children.includes(item)) {
      children.splice(
        children.indexOf(item), 1
      );
    }
    this.setState({ children });
  };
  handleOnAddSchoolDetails = (item: any) => {
    this.setState(
      {},
      () => {

        const schoolDetail = [...this.state.schoolDetail];
        schoolDetail.push({
          schoolName: item.schoolName,
          schoolAddress: item.schoolAddress,
          schoolContactPerson: item.schoolContactPerson,
          schoolContactNumber: item.schoolContactNumber,
          schoolEmailAddress: item.schoolEmailAddress,
          schoolContactPersonDesignation: item.schoolContactPersonDesignation,
          schoolFeesAmount: item.schoolFeesAmount,
          schoolFeesInvoiceNumber: item.schoolFeesInvoiceNumber
        }
        );
        this.setState({ schoolDetail });

        // onReloadFieldsCB(item);
      }
    );
  };
  handleOnRemoveSchoolDetails = (item: any) => {
    const schoolDetail = [...this.state.schoolDetail];
    if (schoolDetail.includes(item)) {
      schoolDetail.splice(
        schoolDetail.indexOf(item), 1
      );
    }
    this.setState({ schoolDetail });
  };
  handleOnAddTravelDetails = (item: any) => {
    this.setState(
      {},
      () => {

        const travelDetail = [...this.state.travelDetail];
        travelDetail.push({
          travelAgency: item.travelAgency,
          travelAgencyAddress: item.travelAgencyAddress,
          travelAgencyContactPerson: item.travelAgencyContactPerson,
          travelAgencyContactPersonDesignation: item.travelAgencyContactPersonDesignation,
          travelAgencyEmail: item.travelAgencyEmail,
          travelAgencyPhoneNumber: item.travelAgencyPhoneNumber,
          travelDestination: item.travelDestination,
          flightCost: item.flightCost,
          flightTicketNumber: item.flightTicketNumber,
          returnDate: item.returnDate,
          departureDate: item.departureDate,
          purposeOfTrip: item.purposeOfTrip,
          airlineName: item.airlineName
        }
        );
        this.setState({ travelDetail });

        // onReloadFieldsCB(item);
      }
    );
  };
  handleOnRemoveTravelDetails = (item: any) => {
    const travelDetail = [...this.state.travelDetail];
    if (travelDetail.includes(item)) {
      travelDetail.splice(
        travelDetail.indexOf(item), 1
      );
    }
    this.setState({ travelDetail });
  };
  handleOnAddHospitalDetails = (item: any) => {
    this.setState(
      {},
      () => {

        const hospitalDetail = [...this.state.hospitalDetail];
        hospitalDetail.push({
          hospitalPatientDOB: item.hospitalPatientDOB,
          hospialContactPerson: item.hospialContactPerson,
          hospitalPhoneNumber: item.hospitalPhoneNumber,
          hospialContactPersonDesignation: item.hospialContactPersonDesignation,
          hospitalAddress: item.hospitalAddress,
          hospitalBillAmount: item.hospitalBillAmount,
          hospitalEmail: item.hospitalEmail,
          hospitalInvoiceNumber: item.hospitalInvoiceNumber,
          hospitalName: item.hospitalName,
          patientName: item.patientName
        }
        );
        this.setState({ hospitalDetail });

        // onReloadFieldsCB(item);
      }
    );
  };
  handleOnRemoveHospitalDetails = (item: any) => {
    const hospitalDetail = [...this.state.hospitalDetail];
    if (hospitalDetail.includes(item)) {
      hospitalDetail.splice(
        hospitalDetail.indexOf(item), 1
      );
    }
    this.setState({ hospitalDetail });
  };
  handleOnAddPrincipalDetails = (item: any) => {
    this.setState(
      {},
      () => {

        const principalDetail = [...this.state.principalDetail];
        principalDetail.push({
          lpoIssuer: item.lpoIssuer,
          lpoInvoice: item.lpoInvoice,
          lpoEmail: item.lpoEmail,
          lpoAddress: item.lpoAddress,
          lpoContactPerson: item.lpoContactPerson,
          lpoContactNumber: item.lpoContactNumber,
          lpoLoanAmountRequested: item.lpoLoanAmountRequested,
          lpoContactPersonDesignation: item.lpoContactPersonDesignation,
        }
        );
        this.setState({ principalDetail });

        // onReloadFieldsCB(item);
      }
    );
  };
  handleOnRemovePrincipalDetails = (item: any) => {
    const principalDetail = [...this.state.principalDetail];
    if (principalDetail.includes(item)) {
      principalDetail.splice(
        principalDetail.indexOf(item), 1
      );
    }
    this.setState({ principalDetail });
  };

  getRequiredFields(title): boolean {
    const { mandatoryFields } = this.props;
    var required = mandatoryFields?.inputFields?.filter(x => x.fieldName === title)[0]?.mandatory;
    if (required === undefined) {
      return false;
    }
    return required;
  }

  resetFields() {
    this.setState({ validated: false, submitting: false, errors: [], ownUpdate: true });
  }

  render() {
    const { values } = this.props;
    const { errors, validated, children, schoolDetail, principalDetail, travelDetail, hospitalDetail } = this.state;
    const jsonLoanDetails = JSON.parse(JSON.parse(JSON.parse(values.jsonData)).LoanDetails);

    return (
      < OtherDetailsForm
        initialValues={
          {
            schoolContactNumber: values.otherDetails?.schoolContactNumber,
            schoolAddress: values.otherDetails?.schoolAddress,
            schoolContactPerson: values.otherDetails?.schoolContactPerson,
            schoolName: values.otherDetails?.schoolName,
            schoolEmailAddress: values.otherDetails?.schoolEmailAddress,
            schoolContactPersonDesignation: values.otherDetails?.schoolContactPersonDesignation,
            schoolFeesAmount: values.otherDetails?.schoolFeesAmount,
            schoolFeesInvoiceNumber: values.otherDetails?.schoolEmailAddress,
            lpoContactNumber: values.otherDetails?.lpoContactNumber,
            lpoContactPerson: (values.otherDetails?.lpoContactPerson),
            lpoEmail: values.otherDetails?.lpoEmail,
            lpoAddress: values.otherDetails?.lpoAddress,
            lpoIssuer: values.otherDetails?.lpoIssuer,
            lpoLoanAmountRequested: values.otherDetails?.lpoLoanAmountRequested,
            lpoInvoice: values.otherDetails?.lpoInvoice,
            lpoContactPersonDesignation: values.otherDetails?.lpoContactPersonDesignation,
            childName: "",
            childDob: "",
            childClass: "",
            travelAgency: values.otherDetails?.travelAgency,
            travelAgencyAddress: values.otherDetails?.travelAgencyAddress,
            travelAgencyContactPerson: values.otherDetails?.travelAgencyContactPerson,
            travelAgencyContactPersonDesignation: values.otherDetails?.travelAgencyContactPersonDesignation,
            travelAgencyEmail: values.otherDetails?.travelAgencyEmail,
            travelAgencyPhoneNumber: (values.otherDetails?.travelAgencyPhoneNumber),
            travelDestination: values.otherDetails?.travelDestination,
            departureDate: values.otherDetails?.departureDate,
            returnDate: values.otherDetails?.returnDate,
            purposeOfTrip: values.otherDetails?.purposeOfTrip,
            airlineName: values.otherDetails?.airlineName,
            flightCost: values.otherDetails?.flightCost,
            flightTicketNumber: values.otherDetails?.flightTicketNumber,
            hospialContactPerson: values.otherDetails?.hospialContactPerson,
            hospialContactPersonDesignation: values.otherDetails?.hospialContactPersonDesignation,
            hospitalAddress: values.otherDetails?.hospitalAddress,
            hospitalBillAmount: values.otherDetails?.hospitalBillAmount,
            hospitalEmail: values.otherDetails?.hospitalEmail,
            hospitalInvoiceNumber: (values.otherDetails?.hospitalInvoiceNumber),
            hospitalName: values.otherDetails?.hospitalName,
            hospitalPatientDOB: values.otherDetails?.hospitalPatientDOB,
            hospitalPhoneNumber: values.otherDetails?.hospitalPhoneNumber,
            patientName: values.otherDetails?.patientName,

          }
        }
        FormComponent={
          ({
            fields:
            {
              schoolName,
              schoolAddress,
              schoolContactPerson,
              schoolContactNumber,
              schoolEmailAddress,
              childDob,
              childName,
              childClass,
              schoolContactPersonDesignation,
              schoolFeesAmount,
              schoolFeesInvoiceNumber,
              lpoIssuer,
              lpoInvoice,
              lpoEmail,
              lpoAddress,
              lpoContactPerson,
              lpoContactNumber,
              lpoLoanAmountRequested,
              lpoContactPersonDesignation,
              flightCost,
              flightTicketNumber,
              airlineName,
              travelAgency,
              travelAgencyAddress,
              travelAgencyContactPerson,
              travelAgencyContactPersonDesignation,
              travelAgencyEmail,
              travelAgencyPhoneNumber,
              travelDestination,
              departureDate,
              returnDate,
              purposeOfTrip,
              hospialContactPerson,
              hospialContactPersonDesignation,
              hospitalAddress,
              hospitalBillAmount,
              hospitalEmail,
              hospitalInvoiceNumber,
              hospitalName,
              hospitalPatientDOB,
              hospitalPhoneNumber,
              patientName
            },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            < div className="form-row" >
              < div className="col-md-12" >

                {/* Loan Information */}
                {values.loanType === "Individual" ? (
                  < div className="card" >
                    < div className="card-header clear-fix" >
                      < h6
                        className="float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        < b > School Information </ b >
                      </ h6 >
                    </ div >
                    < div className="card-body" >
                      < div className="form-row" >
                        < FormInputComponent
                          label="School Name"
                          id="schoolName"
                          name="schoolName"
                          type="text"
                          placeholder=""
                          divClass={6}
                          value={schoolName}
                          required={this.getRequiredFields("schoolName")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />

                        < FormInputComponent
                          label="School Address"
                          id="schoolAddress"
                          name="schoolAddress"
                          type="text"
                          placeholder=""
                          divClass={6}
                          value={schoolAddress}
                          required={this.getRequiredFields("schoolAddress")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          label="School Contact Person"
                          id="schoolContactPerson"
                          name="schoolContactPerson"
                          type="text"
                          placeholder=""
                          divClass={6}
                          value={schoolContactPerson}
                          required={this.getRequiredFields("schoolContactPerson")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          label="Contact Number"
                          id="schoolContactNumber"
                          name="schoolContactNumber"
                          type="number"
                          placeholder=""
                          divClass={6}
                          value={schoolContactNumber}
                          required={this.getRequiredFields("schoolContactNumber")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          label="Email Address"
                          id="schoolEmailAddress"
                          name="schoolEmailAddress"
                          type="email"
                          placeholder=""
                          divClass={6}
                          value={schoolEmailAddress}
                          required={this.getRequiredFields("schoolEmailAddress")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />

                        < FormInputComponent
                          label="School Contact Person Designation"
                          id="schoolContactPersonDesignation"
                          name="schoolContactPersonDesignation"
                          type="text"
                          placeholder=""
                          divClass={6}
                          value={schoolContactPersonDesignation}
                          required={this.getRequiredFields("schoolContactPersonDesignation")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          // label="Contact Number"
                          id="schoolFeesAmount"
                          name="schoolFeesAmount"
                          type="number"
                          placeholder=""
                          divClass={6}
                          value={schoolFeesAmount}
                          required={this.getRequiredFields("schoolFeesAmount")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          // label="Email Address"
                          id="schoolFeesInvoiceNumber"
                          name="schoolFeesInvoiceNumber"
                          type="number"
                          placeholder=""
                          divClass={6}
                          value={schoolFeesInvoiceNumber}
                          required={this.getRequiredFields("schoolFeesInvoiceNumber")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                      </ div >
                    </ div >
                    <div className="card-header clear-fix">
                      <h6
                        className="float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        <b>Child/Chidren Details</b>
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="form-row">
                        <FormInputComponent
                          label="Child Name"
                          id="childName"
                          name="childName"
                          type="text"
                          placeholder=""
                          divClass={6}
                          value={childName}
                          required={false}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        <FormInputComponent
                          label="Date of Birth"
                          id="childDob"
                          name="childDob"
                          type="date"
                          placeholder=""
                          divClass={6}
                          value={childDob}
                          required={false}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        <FormInputComponent
                          label="Class"
                          id="childClass"
                          name="childClass"
                          type="text"
                          placeholder=""
                          divClass={6}
                          value={childClass}
                          required={false}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />

                        <div className="form-row">
                          <div className="my-1 mx-2">
                            <button
                              className="btn btn-sm btn-outline-primary my-4"
                              style={{ cursor: "pointer" }}
                              type="button"
                              onClick={() =>
                                this.handleOnAddChildren(
                                  {
                                    childName,
                                    childDob
                                  },
                                )
                              }
                            >
                              <React.Fragment>
                                <i className="far fa-save fa-lg mr-2" />{" "}
                                Add
                              </React.Fragment>
                            </button>
                          </div>
                        </div>
                        <div className=" form-row col-md-12">
                          <div
                            className="table-responsive text-nowrap"
                            style={{
                              maxHeight: "150px",
                              overflowY: "scroll",
                            }}
                          >
                            <table className="table table-hover table-content table-sm table-striped mb-0">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                    }}
                                  >
                                    <strong>#</strong>
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                    }}
                                  >
                                    <strong>Name</strong>
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                    }}
                                  >
                                    <strong>DOB</strong>
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                    }}
                                  >
                                    <strong>Class</strong>
                                  </th>

                                </tr>
                              </thead>
                              <tbody>
                                {/* {console.log(children)} */}
                                {children?.map((item) => (
                                  <tr key={children?.indexOf(item)}>
                                    <td
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                        fontSize: "small",
                                      }}
                                    >
                                      {children?.indexOf(item) + 1}
                                    </td>
                                    <td
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                        fontSize: "small",
                                      }}
                                    >
                                      {item.childName}
                                    </td>
                                    <td
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                        fontSize: "small",
                                      }}
                                    >
                                      {textFormatService.formatShortTimeString(item.childDob)}
                                    </td>
                                    <td
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                        fontSize: "small",
                                      }}
                                    >
                                      {item.class}
                                    </td>

                                    <td
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                        fontSize: "small",
                                      }}
                                    >
                                      <i
                                        className="far fa-trash-alt ml-5 red-text fa-lg"
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          this.handleOnRemoveChildren(
                                            item
                                          )
                                        }
                                      ></i>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ div >
                ) : ("")}

                {values.loanType === "Corporate" ? (
                  < div className="card mt-3" >
                    < div className="card-header clear-fix" >
                      < h6
                        className="float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        < b > LPO Details </ b >
                      </ h6 >
                    </ div >
                    < div className="card-body" >
                      < div className="form-row" >
                        {/* < FormInputComponent
                        label="Principal Name"
                        id="lpoAddress"
                        name="lpoAddress"
                        type="text"
                        placeholder=""
                        divClass={6}
                        value={lpoAddress}
                        required={this.getRequiredFields("lpoAddress")}
                        validated={validated}
                        errors={errors}
                        onChange={onChange}
                      /> */}
                        < FormInputComponent
                          label="Principal Name"
                          id="LPOIssuer"
                          name="LPOIssuer"
                          type="text"
                          placeholder=""
                          divClass={6}
                          value={lpoIssuer}
                          required={this.getRequiredFields("LPOIssuer")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          label="Office Address"
                          id="lpoEmail"
                          name="lpoEmail"
                          type="email"
                          placeholder=""
                          divClass={6}
                          value={lpoEmail}
                          required={this.getRequiredFields("lpoEmail")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          label="Phone Number"
                          id="lpoContactNumber"
                          name="lpoContactNumber"
                          type="number"
                          placeholder=""
                          divClass={6}
                          value={lpoContactNumber}
                          required={this.getRequiredFields("lpoContactNumber")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          label="LPO Email"
                          id="lpoEmail"
                          name="lpoEmail"
                          type="email"
                          placeholder=""
                          divClass={6}
                          value={lpoEmail}
                          required={this.getRequiredFields("lpoEmail")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          label="Contact Person"
                          id="lpoContactPerson"
                          name="lpoContactPerson"
                          type="text"
                          placeholder=""
                          divClass={6}
                          value={lpoContactPerson}
                          required={this.getRequiredFields("lpoContactPerson")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          label="Contact Person Designation"
                          id="lpoContactPerson"
                          name="lpoContactPersonDesignation"
                          type="text"
                          placeholder=""
                          divClass={6}
                          value={lpoContactPersonDesignation}
                          required={this.getRequiredFields("lpoContactPersonDesignation")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          label="PO Invoice Number"
                          id="lpoInvoice"
                          name="lpoInvoice"
                          type="number"
                          placeholder=""
                          divClass={6}
                          value={lpoInvoice}
                          required={this.getRequiredFields("lpoInvoice")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          label="PO Amount"
                          id="lpoLoanAmountRequested"
                          name="lpoLoanAmountRequested"
                          type="number"
                          placeholder=""
                          divClass={6}
                          value={lpoLoanAmountRequested}
                          required={this.getRequiredFields("lpoLoanAmountRequested")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />

                      </ div >

                      <div className="form-row">
                        <div className="my-1 mx-2">
                          <button
                            className="btn btn-sm btn-outline-primary my-4"
                            style={{ cursor: "pointer" }}
                            type="button"
                            onClick={() =>
                              this.handleOnAddPrincipalDetails(
                                {
                                  lpoAddress,
                                  lpoContactNumber,
                                  lpoContactPerson,
                                  lpoContactPersonDesignation,
                                  lpoEmail,
                                  lpoInvoice,
                                  lpoIssuer,
                                  lpoLoanAmountRequested
                                },
                              )
                            }
                          >
                            <React.Fragment>
                              <i className="far fa-save fa-lg mr-2" />{" "}
                              Add
                            </React.Fragment>
                          </button>
                        </div>
                      </div>
                      <div className=" form-row col-md-12">
                        <div
                          className="table-responsive text-nowrap"
                          style={{
                            maxHeight: "150px",
                            overflowY: "scroll",
                          }}
                        >
                          <table className="table table-hover table-content table-sm table-striped mb-0">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>#</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Principal Name</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Contact Person</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Invoice Number</strong>
                                </th>

                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>PO Amount</strong>
                                </th>

                              </tr>
                            </thead>
                            <tbody>
                              {/* {console.log(children)} */}
                              {principalDetail?.map((item) => (
                                <tr key={principalDetail?.indexOf(item)}>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {principalDetail?.indexOf(item) + 1}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item.lpoIssuer}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item.lpoContactPerson}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item.lpoInvoice}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {textFormatService.formatCurrency(Number(item.lpoLoanAmountRequested), 2)}
                                  </td>

                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    <i
                                      className="far fa-trash-alt ml-5 red-text fa-lg"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.handleOnRemovePrincipalDetails(
                                          item
                                        )
                                      }
                                    ></i>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </ div >
                  </ div >
                ) : ("")}

                {values.loanType === "Individual" ? (
                  < div className="card mt-3" >
                    < div className="card-header clear-fix" >
                      < h6
                        className="float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        < b > Travel Information </ b >
                      </ h6 >
                    </ div >
                    < div className="card-body" >
                      < div className="form-row" >
                        < FormInputComponent
                          id="airlineName"
                          name="airlineName"
                          type="text"
                          placeholder=""
                          divClass={6}
                          value={airlineName}
                          required={this.getRequiredFields("airlineName")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />

                        < FormInputComponent
                          id="travelAgency"
                          name="travelAgency"
                          type="text"
                          placeholder=""
                          divClass={6}
                          value={travelAgency}
                          required={this.getRequiredFields("travelAgency")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          id="travelAgencyAddress"
                          name="travelAgencyAddress"
                          type="text"
                          placeholder=""
                          divClass={6}
                          value={travelAgencyAddress}
                          required={this.getRequiredFields("travelAgencyAddress")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          id="travelAgencyPhoneNumber"
                          name="travelAgencyPhoneNumber"
                          type="number"
                          placeholder=""
                          divClass={6}
                          value={travelAgencyPhoneNumber}
                          required={this.getRequiredFields("travelAgencyPhoneNumber")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          id="travelAgencyEmail"
                          name="travelAgencyEmail"
                          type="email"
                          placeholder=""
                          divClass={6}
                          value={travelAgencyEmail}
                          required={this.getRequiredFields("travelAgencyEmail")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />

                        < FormInputComponent
                          id="travelDestination"
                          name="travelDestination"
                          type="text"
                          placeholder=""
                          divClass={6}
                          value={travelDestination}
                          required={this.getRequiredFields("travelDestination")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          id="purposeOfTrip"
                          name="purposeOfTrip"
                          type="text"
                          placeholder=""
                          divClass={6}
                          value={purposeOfTrip}
                          required={this.getRequiredFields("purposeOfTrip")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          // label="Contact Number"
                          id="departureDate"
                          name="departureDate"
                          type="date"
                          placeholder=""
                          divClass={6}
                          value={departureDate}
                          required={this.getRequiredFields("departureDate")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          // label="Email Address"
                          id="returnDate"
                          name="returnDate"
                          type="date"
                          placeholder=""
                          divClass={6}
                          value={returnDate}
                          required={this.getRequiredFields("returnDate")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          id="flightTicketNumber"
                          name="flightTicketNumber"
                          type="text"
                          placeholder=""
                          divClass={6}
                          value={flightTicketNumber}
                          required={this.getRequiredFields("flightTicketNumber")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          id="flightCost"
                          name="flightCost"
                          type="number"
                          placeholder=""
                          divClass={6}
                          value={flightCost}
                          required={this.getRequiredFields("flightCost")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          id="travelAgencyContactPerson"
                          name="travelAgencyContactPerson"
                          type="text"
                          placeholder=""
                          divClass={6}
                          value={travelAgencyContactPerson}
                          required={this.getRequiredFields("travelAgencyContactPerson")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          id="travelAgencyContactPersonDesignation"
                          name="travelAgencyContactPersonDesignation"
                          type="text"
                          placeholder=""
                          divClass={6}
                          value={travelAgencyContactPersonDesignation}
                          required={this.getRequiredFields("travelAgencyContactPersonDesignation")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                      </ div >
                      <div className="form-row">
                        <div className="my-1 mx-2">
                          <button
                            className="btn btn-sm btn-outline-primary my-4"
                            style={{ cursor: "pointer" }}
                            type="button"
                            onClick={() =>
                              this.handleOnAddTravelDetails(
                                {
                                  travelAgency,
                                  travelAgencyAddress,
                                  travelAgencyContactPerson,
                                  travelAgencyContactPersonDesignation,
                                  travelAgencyEmail,
                                  travelAgencyPhoneNumber,
                                  travelDestination,
                                  returnDate,
                                  departureDate,
                                  airlineName,
                                  purposeOfTrip,
                                  flightCost,
                                  flightTicketNumber
                                },
                              )
                            }
                          >
                            <React.Fragment>
                              <i className="far fa-save fa-lg mr-2" />{" "}
                              Add
                            </React.Fragment>
                          </button>
                        </div>
                      </div>
                      <div className=" form-row col-md-12">
                        <div
                          className="table-responsive text-nowrap"
                          style={{
                            maxHeight: "150px",
                            overflowY: "scroll",
                          }}
                        >
                          <table className="table table-hover table-content table-sm table-striped mb-0">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>#</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Travel Agency</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Airline Name</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Contact Person</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Flight Ticket Number</strong>
                                </th>

                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Flight Amount</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Flight Departure Date</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Flight Return Date</strong>
                                </th>

                              </tr>
                            </thead>
                            <tbody>
                              {/* {console.log(children)} */}
                              {travelDetail?.map((item) => (
                                <tr key={travelDetail?.indexOf(item)}>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {travelDetail?.indexOf(item) + 1}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item.travelAgency}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item.airlineName}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item.travelAgencyContactPerson}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item.flightTicketNumber}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {textFormatService.formatCurrency(Number(item.flightCost), 2)}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item.departureDate}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item.returnDate}
                                  </td>

                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    <i
                                      className="far fa-trash-alt ml-5 red-text fa-lg"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.handleOnRemoveTravelDetails(
                                          item
                                        )
                                      }
                                    ></i>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </ div >
                  </ div >
                ) : ("")}
                {values.loanType === "Individual" ? (
                  < div className="card mt-3" >
                    < div className="card-header clear-fix" >
                      < h6
                        className="float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        < b > Hospital Information </ b >
                      </ h6 >
                    </ div >
                    < div className="card-body" >
                      < div className="form-row" >
                        < FormInputComponent
                          id="hospitalName"
                          name="hospitalName"
                          type="text"
                          placeholder=""
                          divClass={6}
                          value={hospitalName}
                          required={this.getRequiredFields("hospitalName")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />

                        < FormInputComponent
                          id="hospitalAddress"
                          name="hospitalAddress"
                          type="text"
                          placeholder=""
                          divClass={6}
                          value={hospitalAddress}
                          required={this.getRequiredFields("hospitalAddress")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          id="hospitalPhoneNumber"
                          name="hospitalPhoneNumber"
                          type="number"
                          placeholder=""
                          divClass={6}
                          value={hospitalPhoneNumber}
                          required={this.getRequiredFields("hospitalPhoneNumber")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          id="hospitalEmail"
                          name="hospitalEmail"
                          type="email"
                          placeholder=""
                          divClass={6}
                          value={hospitalEmail}
                          required={this.getRequiredFields("hospitalEmail")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          id="patientName"
                          name="patientName"
                          type="text"
                          placeholder=""
                          divClass={6}
                          value={patientName}
                          required={this.getRequiredFields("patientName")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />

                        < FormInputComponent
                          id="hospitalPatientDOB"
                          name="hospitalPatientDOB"
                          type="date"
                          placeholder=""
                          divClass={6}
                          value={hospitalPatientDOB}
                          required={this.getRequiredFields("hospitalPatientDOB")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          id="hospialContactPerson"
                          name="hospialContactPerson"
                          type="text"
                          placeholder=""
                          divClass={6}
                          value={hospialContactPerson}
                          required={this.getRequiredFields("hospialContactPerson")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          id="hospialContactPersonDesignation"
                          name="hospialContactPersonDesignation"
                          type="text"
                          placeholder=""
                          divClass={6}
                          value={hospialContactPersonDesignation}
                          required={this.getRequiredFields("hospialContactPersonDesignation")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          id="hospitalInvoiceNumber"
                          name="hospitalInvoiceNumber"
                          type="number"
                          placeholder=""
                          divClass={6}
                          value={hospitalInvoiceNumber}
                          required={this.getRequiredFields("hospitalInvoiceNumber")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                        < FormInputComponent
                          id="hospitalBillAmount"
                          name="hospitalBillAmount"
                          type="number"
                          placeholder=""
                          divClass={6}
                          value={hospitalBillAmount}
                          required={this.getRequiredFields("hospitalBillAmount")}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />

                      </ div >
                      <div className="form-row">
                        <div className="my-1 mx-2">
                          <button
                            className="btn btn-sm btn-outline-primary my-4"
                            style={{ cursor: "pointer" }}
                            type="button"
                            onClick={() =>
                              this.handleOnAddHospitalDetails(
                                {
                                  hospialContactPerson,
                                  hospialContactPersonDesignation,
                                  hospitalAddress,
                                  hospitalBillAmount,
                                  hospitalEmail,
                                  hospitalInvoiceNumber,
                                  hospitalName,
                                  hospitalPatientDOB,
                                  hospitalPhoneNumber,
                                  patientName
                                },
                              )
                            }
                          >
                            <React.Fragment>
                              <i className="far fa-save fa-lg mr-2" />{" "}
                              Add
                            </React.Fragment>
                          </button>
                        </div>
                      </div>
                      <div className=" form-row col-md-12">
                        <div
                          className="table-responsive text-nowrap"
                          style={{
                            maxHeight: "150px",
                            overflowY: "scroll",
                          }}
                        >
                          <table className="table table-hover table-content table-sm table-striped mb-0">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>#</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Hospital Name</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Patient Name</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Contact Person</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Hospital Invoice</strong>
                                </th>

                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Bill Amount</strong>
                                </th>

                              </tr>
                            </thead>
                            <tbody>
                              {/* {console.log(children)} */}
                              {hospitalDetail?.map((item) => (
                                <tr key={hospitalDetail?.indexOf(item)}>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {hospitalDetail?.indexOf(item) + 1}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item.hospitalName}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item.patientName}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item.hospialContactPerson}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item.hospitalInvoiceNumber}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {textFormatService.formatCurrency(Number(item.hospitalBillAmount), 2)}
                                  </td>

                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    <i
                                      className="far fa-trash-alt ml-5 red-text fa-lg"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.handleOnRemoveHospitalDetails(
                                          item
                                        )
                                      }
                                    ></i>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </ div >
                  </ div >

                ) : ("")}
                {/* Third Party Details Action */}
                < div className="form-row" >
                  < div className="my-1 mx-2" >
                    {
                      (getCurrentUsersRoles().includes("updatedataentry") && (
                        <>< button
                          type="submit"
                          className="btn btn-sm btn-primary"
                          disabled={this.state.submitting}
                          onClick={
                            (e) => {
                              onHandleSubmit(
                                e,
                                ActionTypes.SAVE,
                                "Loan Details",
                                () => {
                                  this.onFormSubmit(

                                    {
                                      schoolName,
                                      schoolAddress,
                                      schoolContactPerson,
                                      schoolContactNumber,
                                      schoolEmailAddress,
                                      schoolContactPersonDesignation,
                                      schoolFeesAmount,
                                      schoolFeesInvoiceNumber,
                                      childDob,
                                      childName,
                                      childClass,
                                      lpoIssuer,
                                      lpoInvoice,
                                      lpoEmail,
                                      lpoAddress,
                                      lpoContactPerson,
                                      lpoContactNumber,
                                      lpoContactPersonDesignation,
                                      lpoLoanAmountRequested,
                                      flightCost,
                                      flightTicketNumber,
                                      airlineName,
                                      travelAgency,
                                      travelAgencyAddress,
                                      travelAgencyContactPerson,
                                      travelAgencyContactPersonDesignation,
                                      travelAgencyEmail,
                                      travelAgencyPhoneNumber,
                                      travelDestination,
                                      departureDate,
                                      returnDate,
                                      purposeOfTrip,
                                      hospialContactPerson,
                                      hospialContactPersonDesignation,
                                      hospitalAddress,
                                      hospitalBillAmount,
                                      hospitalEmail,
                                      hospitalInvoiceNumber,
                                      hospitalName,
                                      hospitalPatientDOB,
                                      hospitalPhoneNumber,
                                      patientName
                                    },
                                    onReloadFields

                                  );
                                }
                              );
                            }
                          }
                        >
                          {
                            this.state.submitting === false ? (
                              < React.Fragment >
                                < i className="fas fa-lg fa-save mr-3" /> Save
                              </ React.Fragment >
                            ) : (
                              < React.Fragment >
                                < i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                Please wait...
                              </ React.Fragment >
                            )}
                        </ button >
                        </>
                      ))}
                  </ div >
                </ div >

              </ div >
            </ div >
          )}
      ></OtherDetailsForm >
    );
  }
}

export default OtherDetails;

