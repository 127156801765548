import React, { CSSProperties, useContext, useEffect, useState } from "react";
import corporateAnalysisService from "../../../../../../../../services/hybridAppraisal/corporateAnalysisService";
import _ from "lodash";
import {
  firstCharToUpper,
  formatCurrency,
  splitAtUpper,
} from "../../../../../../../../services/utility/textFormatService";
import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionToggle,
} from "react-bootstrap";
export const CashflowOutput = ({ loan }: any) => {
  const [cashflowOutput, setCashflowOutput] = useState<any>();

  useEffect(() => {
    const getCashflowOutput = async () => {
      const response = await corporateAnalysisService.retrieveCashFlowOutput({
        loanRequestId: loan?.id,
      });
      if (response?.status === 200) {
        setCashflowOutput(response?.data);
      }
    };
    getCashflowOutput();
  }, [loan]);

  const salesProjection = !!cashflowOutput?.[0]?.salesProjection
    ? cashflowOutput?.[0]?.salesProjection
    : {};

  const tableMonthKeys = Object.keys(
    !!cashflowOutput ? cashflowOutput?.[0]?.data?.[0]?.months : [""]
  );
  const tableMonthColumns = tableMonthKeys?.map((key) => firstCharToUpper(key));
  const sortedMonthlySummary = cashflowOutput?.[0]?.monthSummaries?.map(
    (item) => ({
      cashAtStartOfPeriod: item.cashAtStart,
      totalInflows: item.totalInflow,
      totalOutflows: item.totalOutflow,
      netCashFlowForThePeriod: item.netCashFlow,
      cashAtEndOfPeriod: item.cashAtEnd,
      securityCushion: item.securityCushion,
      cash: item.cash,
      inflows: 0,
      outflows: 0,
    })
  );

  const previewDataRaw1 = cashflowOutput?.[0]?.data?.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.itemName]: Object.values(curr.months),
    };
  }, {});
  const previewDataRaw2 = cashflowOutput?.[1]?.data?.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.itemName]: Object.values(curr.months),
    };
  }, {});
  const previewDataRaw3 = cashflowOutput?.[2]?.data?.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.itemName]: Object.values(curr.months),
    };
  }, {});
  const previewDataArr1 = !_.isEmpty(previewDataRaw1)
    ? Object.entries(previewDataRaw1)
    : [];
  const previewDataArr2 = !_.isEmpty(previewDataRaw2)
    ? Object.entries(previewDataRaw2)
    : [];
  const previewDataArr3 = !_.isEmpty(previewDataRaw3)
    ? Object.entries(previewDataRaw3)
    : [];
  const previewDataArrAll = previewDataArr1?.concat(
    previewDataArr2?.concat(previewDataArr3)
  );
  const transformedSummaryRaw = {};
  sortedMonthlySummary?.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (!transformedSummaryRaw[key]) {
        transformedSummaryRaw[key] = [];
      }
      transformedSummaryRaw[key].push(item[key]);
    });
  });
  const transformedSummaryArr = Object.entries(transformedSummaryRaw);
  const allDataRaw = previewDataArrAll.concat(transformedSummaryArr);
  const findKey = (key: string) => {
    return allDataRaw?.find((item) => item?.[0] === key);
  };
  const findOtherListKeys = (keys: string[]) => {
    return allDataRaw?.filter((item) => keys.includes(item?.[0]));
  };
  console.log(allDataRaw);
  const transformedSummary = [
    findKey("cashAtStartOfPeriod"),
    findKey("inflows"),
    ...findOtherListKeys([
      `Owner's contribution `,

      "Cash sales",
      "Cash Sales",
      "Family Income",
      "Cash received from past credit sales",
    ]),
    findKey("totalInflows"),
    findKey("outflows"),
    ...findOtherListKeys([
      "Purchase of inventory",
      "Purchase of fixed assets",
      "Loan schedule for Existing Loans",
      "Purchase of other inventory",
      "Purchase of inventory",
      "Purchase of fixed assets",
      "Direct Costs",
      "Overhead Costs",
      "Overhead costs",
      "Family expenses",
      "Family Expenses",
      "Purchase of fixed assets",
      "Other",
      "Installment for proposed loan",
    ]),
    findKey("totalOutflows"),
    findKey("netCashFlowForThePeriod"),
    findKey("cashAtEndOfPeriod"),
    findKey("securityCushion"),
    findKey("cash"),
  ]?.reduce((acc, curr) => {
    return {
      ...acc,
      [curr?.[0]]: curr?.[1],
    };
  }, {});

  const monthlySalesProjection = [
    ...findOtherListKeys(["Monthly Sales Projection", "Sales Projection"]),
  ]?.reduce((acc, curr) => {
    return {
      ...acc,
      [curr?.[0]]: curr?.[1],
    };
  }, {});

  const alternateBlueFormatting = ["totalInflows", "totalOutflows"];

  const alternateBlackFormatting = [
    "netCashFlowForThePeriod",
    "cashAtEndOfPeriod",
    "securityCushion",
    "cash",
    "inflows",
    "outflows",
  ];

  const altFormatBlack = (
    item: any,
    { format, index }: { format?: "f1" | "f2" | "f3"; index?: number }
  ) => (
    <div
      style={{
        fontWeight: "bold",
        color: "#86939e",
        fontSize: item === "netCashFlowForThePeriod" ? 15 : 13,
      }}
    >
      {format === "f1" ? (
        <span>{firstCharToUpper(splitAtUpper(item))}</span>
      ) : format === "f2" ? (
        <span>
          {item === "securityCushion"
            ? `${transformedSummary[item][index]}%`
            : !!transformedSummary[item][index]
            ? transformedSummary[item][index] > 0
              ? formatCurrency(transformedSummary[item][index], 2)
              : `(${formatCurrency(transformedSummary[item][index], 2).replace(
                  "-",
                  ""
                )})`
            : ""}
        </span>
      ) : format === "f3" ? (
        <span>
          {transformedSummary?.[item]?.reduce((acc, curr) => acc + curr, 0) > 0
            ? formatCurrency(
                transformedSummary?.[item]?.reduce(
                  (acc, curr) => acc + curr,
                  0
                ),
                2
              )
            : `(${formatCurrency(
                transformedSummary?.[item]?.reduce(
                  (acc, curr) => acc + curr,
                  0
                ),
                2
              ).replace("-", "")})`}
        </span>
      ) : null}
    </div>
  );

  const altFormatBlue = (
    item: any,
    { format, index }: { format?: "f1" | "f2" | "f3"; index?: number }
  ) => (
    <div
      style={{
        fontWeight: "bold",
        color: "#437ba7",
      }}
    >
      {format === "f1" ? (
        <em>{firstCharToUpper(splitAtUpper(item))}</em>
      ) : format === "f2" ? (
        <em>
          {transformedSummary[item][index] > 0
            ? formatCurrency(transformedSummary[item][index], 2)
            : `(${formatCurrency(transformedSummary[item][index], 2).replace(
                "-",
                ""
              )})`}
        </em>
      ) : format === "f3" ? (
        <em>
          {transformedSummary?.[item]?.reduce((acc, curr) => acc + curr, 0) > 0
            ? formatCurrency(
                transformedSummary?.[item]?.reduce(
                  (acc, curr) => acc + curr,
                  0
                ),
                2
              )
            : `(${formatCurrency(
                transformedSummary?.[item]?.reduce(
                  (acc, curr) => acc + curr,
                  0
                ),
                2
              ).replace("-", "")})`}
        </em>
      ) : null}
    </div>
  );

  return (
    <div style={{ width: "100%" }} className="clearfix">
      {!_.isEmpty(cashflowOutput) ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 20,
            marginBottom: 20,
          }}
        >
          <Container
            title="Cash Flow"
            body={
              <div style={{ marginBottom: 30 }} className=" form-row col-md-12">
                <div
                  className="table-responsive text-nowrap"
                  style={{
                    overflowY: "scroll",
                    border: "0.5px solid #ccc",
                  }}
                >
                  <table className="table table-hover table-content table-sm table-striped mb-0">
                    <thead>
                      <tr>
                        {tHead(
                          ["", ...tableMonthColumns],
                          [
                            { textAlign: "left" },
                            { textAlign: "right" },
                            { textAlign: "right" },
                            { textAlign: "right" },
                          ]
                        )}
                      </tr>
                    </thead>
                    {!_.isEmpty(transformedSummary) && (
                      <tbody>
                        {Object.keys(transformedSummary)?.map((item, index) => (
                          <tr key={index}>
                            {tData(
                              [
                                alternateBlueFormatting?.includes(item)
                                  ? altFormatBlue(item, { format: "f1" })
                                  : alternateBlackFormatting?.includes(item)
                                  ? altFormatBlack(item, { format: "f1" })
                                  : firstCharToUpper(splitAtUpper(item)),

                                ...tableMonthColumns?.map((_, index) =>
                                  alternateBlueFormatting?.includes(item)
                                    ? altFormatBlue(item, {
                                        format: "f2",
                                        index,
                                      })
                                    : alternateBlackFormatting?.includes(item)
                                    ? altFormatBlack(item, {
                                        format: "f2",
                                        index,
                                      })
                                    : transformedSummary[item][index] > 0
                                    ? formatCurrency(
                                        transformedSummary[item][index],
                                        2
                                      )
                                    : `(${formatCurrency(
                                        transformedSummary[item][index],
                                        2
                                      ).replace("-", "")})`
                                ),
                              ],
                              [
                                { textAlign: "left" },
                                { textAlign: "right" },
                                { textAlign: "right" },
                                { textAlign: "right" },
                              ]
                            )}
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            }
          />
          <br />
          <Container
            title="Sales Projection"
            body={
              <div style={{ width: "100%" }} className="clearfix">
                {!_.isEmpty(salesProjection) ? (
                  <div
                    style={{ marginBottom: 30 }}
                    className=" form-row col-md-12"
                  >
                    <div
                      className="table-responsive text-nowrap"
                      style={{
                        overflowY: "scroll",
                        border: "0.5px solid #ccc",
                        marginBottom: 30,
                      }}
                    >
                      <table className="table table-hover table-content table-sm table-striped mb-0">
                        <thead>
                          <tr>{tHead(["Projection", ...tableMonthColumns])}</tr>
                        </thead>
                        {!_.isEmpty(monthlySalesProjection) && (
                          <tbody>
                            {Object.keys(monthlySalesProjection)?.map(
                              (item, index) => (
                                <tr key={index}>
                                  {tData([
                                    <div
                                      key={index}
                                      style={{
                                        fontWeight: "bold",
                                        color: "#86939e",
                                        fontSize: 13,
                                      }}
                                    >
                                      <span>
                                        {firstCharToUpper(
                                          splitAtUpper(!!item ? item : "")
                                        )}
                                      </span>
                                    </div>,

                                    ...tableMonthColumns?.map((_, index) => (
                                      <div
                                        key={index}
                                        style={{
                                          fontWeight: "bold",
                                          color: "#86939e",
                                          fontSize: 13,
                                        }}
                                      >
                                        {formatCurrency(
                                          !!monthlySalesProjection?.[item]?.[
                                            index
                                          ]
                                            ? monthlySalesProjection?.[item]?.[
                                                index
                                              ]
                                            : 0,
                                          2
                                        )}
                                      </div>
                                    )),
                                  ])}
                                </tr>
                              )
                            )}
                          </tbody>
                        )}
                      </table>
                    </div>
                    <div
                      className="table-responsive text-nowrap"
                      style={{
                        overflowY: "scroll",
                        border: "0.5px solid #ccc",
                      }}
                    >
                      <table className="table table-hover table-content table-sm table-striped mb-0">
                        <thead>
                          <tr>{tHead(["Decription", "Value"])}</tr>
                        </thead>
                        <tbody>
                          <tr>
                            {tData([
                              "Minimum Sales",
                              formatCurrency(salesProjection?.minimumSales, 2),
                            ])}
                          </tr>
                          <tr>
                            {tData([
                              "Average Sales",
                              formatCurrency(salesProjection?.averageSales, 2),
                            ])}
                          </tr>
                          <tr>
                            {tData([
                              "Highest Sales",
                              formatCurrency(salesProjection?.highestSales, 2),
                            ])}
                          </tr>
                          <tr>
                            {tData([
                              "Below Average (%)",
                              `${formatCurrency(
                                salesProjection?.percentBelowAverage,
                                2
                              )}%`,
                            ])}
                          </tr>
                          <tr>
                            {tData([
                              "Above Average (%)",
                              `${formatCurrency(
                                salesProjection?.percentAboveAverage,
                                2
                              )}%`,
                            ])}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      color: "red",
                      marginTop: 20,
                    }}
                    className="py-2"
                  >
                    <i>No Sales Projection</i>
                  </div>
                )}
              </div>
            }
          />
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            color: "red",
            marginTop: 20,
          }}
          className="py-2"
        >
          <i>No Cashflow Output available</i>
        </div>
      )}
    </div>
  );
};

const tHead = (
  texts: (string | number | React.ReactNode)[],
  style?: CSSProperties[]
) => (
  <>
    {texts?.map((text: any, index: number) => (
      <th
        key={text}
        scope="col"
        style={{
          ...style?.[index],
          paddingTop: "0.2rem",
          paddingBottom: "0.2rem",
        }}
      >
        <strong>{text}</strong>
      </th>
    ))}
  </>
);
const tData = (
  texts: (string | number | React.ReactNode)[],
  style?: CSSProperties[]
) => (
  <>
    {texts?.map((text: any, index: number) => (
      <td
        style={{
          ...style?.[index],
          paddingTop: "0.2rem",
          paddingBottom: "0.2rem",
          fontSize: "small",
        }}
      >
        {text}
      </td>
    ))}
  </>
);

const ContextAwareToggle = ({ eventKey, header }) => {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      <b>{header}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
};

const Container = ({
  title,
  body,
}: {
  title: string;
  body: React.ReactNode;
}) => {
  return (
    <div className="card">
      <Accordion defaultActiveKey="0">
        <div>
          <Card>
            <ContextAwareToggle
              eventKey={`0`}
              header={
                <React.Fragment>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    style={{ color: "#878a8a" }}
                  ></i>{" "}
                  {title}
                </React.Fragment>
              }
            ></ContextAwareToggle>
            <Accordion.Collapse eventKey={`0`}>
              <Card.Body>
                <div
                  style={{
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  <div className="row">{body}</div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </div>
      </Accordion>
    </div>
  );
};
