import React from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import { IBaseFormState } from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import SelectInputComponent, {
  ISelectItems,
} from "../../../components/formSelectComponent";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import institutionsService from "../../../services/auth/institutionsService";
import institutionManagementService from '../../../services/creditAssessment/institutionManagementService';
import creditQualificationRuleService from "../../../services/creditAssessment/creditQualificationRuleService";
import swal from "sweetalert";

interface IInstitutionSetupProps { }
interface IInstitutionSetupState {
  institution: any;
  category: string;
  institutions: any;
  fetchingInstitutions: boolean;
  creditQualificationIndicators: any;
  indicatorIds: any;
  editting: boolean;
  submitted: boolean;
  existingInstitution: boolean;
}

class InstitutionSetup extends React.Component<
  IInstitutionSetupProps,
  IBaseFormState & IInstitutionSetupState
> {
  constructor(props: IInstitutionSetupProps) {
    super(props);
    this.state = {
      institution: {},
      institutions: [],
      category: "",
      fetchingInstitutions: false,
      validated: false,
      submitting: false,
      errors: {},
      submitted: false,
      creditQualificationIndicators: [],
      indicatorIds: [],
      editting: false,
      existingInstitution: false
    };
  }

  async componentWillMount() {
    try {
      const creditQualificationIndicators = await creditQualificationRuleService.retrieveCreditQualificationIndicators();
      this.setState({
        creditQualificationIndicators: creditQualificationIndicators.data,
      });
    } catch (error) {
      if (error.response.status === 409) {
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
    }
  }

  isValidated() {
    if (this.state.submitting) {
      toast.info(`Already attempting to save Qualification Indicators `, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      return false;
    }
    if (this.state.submitted) {
      this.setState({ submitted: false });
      return true;
    }
    try {
      return this.onHandleSubmit(ActionTypes.SAVE, "Qualification Indicators", () => {
        this.onFormSubmit();
      });
    } catch (error) { }
    return false;
  }

  onHandleSubmit = (
    actionType: ActionTypes | null,
    entityName: string,
    cb: any
  ) => {
    if (!actionType) {
      return cb();
    } else {
      swal({
        title: "Confirm!",
        text: `Are you sure you want to proceed to ${ActionTypes[actionType]} this ${entityName}? This action is Irreversible`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode:
          actionType === 2 ||
            actionType === 3 ||
            actionType === 7 ||
            actionType === 8
            ? true
            : false,
      }).then((resp) => {
        if (resp) {
        } else {
          return cb();
        }
      });
    }
  };

  onFormSubmit(): boolean {
    try {
      this.setState(
        {
          errors: [],
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit();
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(): Promise<boolean> {
    try {
      this.setState({ submitting: true });
      const { institution } = this.state;

      const indicatorPayload: any = {};
      indicatorPayload.creditQualificationIndicatorIds = this.state.indicatorIds;
      const indicatorReponse = await creditQualificationRuleService.addOrRemoveCreditQualificationIndicatorsToInstitution(institution.code, indicatorPayload);
      if (indicatorReponse.status >= 200 && indicatorReponse.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Setup Credit Qualification Rule",
          description: `Setup new Credit Qualification Rule`,
        });
        toast.info(`Credit Qualification Indicators setup for ${institution.code} successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }

      const payload: any = {};
      payload.institutionCode = institution.code;
      payload.institutionName = institution.name;
      payload.externalInstitutionId = institution.id.toString();
      payload.externalInstitutionCode = institution.externalInstitutionCode;
      payload.institutionCategory = institution.category;

      const response = await institutionManagementService.createCreditAssessmentInstitution(payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Configure Institution for Credit Assessment",
          description: `Configured new Institution ${institution.name} for Credit Assessment`,
        });
        toast.info(`Institution ${institution.name} successfully configured for Credit Assessment`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      }
      else {
        return false;
      }
      this.setState({ submitting: false, submitted: true });
      this.resetFields();
    } catch (error) {
      if (error.response.status === 409) {
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false });
      return false;
    } finally {
      return true;
    }
  }

  handleOnSelectCategory = async (id, value) => {
    try {
      this.setState({
        category: value,
        fetchingInstitutions: true,
        institution: {},
      });
      let inst = await institutionsService.retrieveAllByCategory(value);
      this.setState({ institutions: inst.data, fetchingInstitutions: false });
    } catch (error) {
      toast.info("Something went wrong.", {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      toast.info(error.response.data.errors[0], {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
    }
  };

  handleOnSelectInstitution = async (id, value) => {
    try {
      this.setState({ existingInstitution: false })
      const institution = this.state.institutions.filter(
        (x) => x.id === parseInt(value)
      )[0];
      var cainstitution = await institutionManagementService.retrieveInstitutionByCode(institution.code);
      if ((cainstitution.data).length != 0) {
        this.setState({ existingInstitution: true });
      }
      this.setState({ institution: institution });
    } catch (error) {
      toast.info("Something went wrong.", {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      toast.info(error.response.data.errors[0], {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
    }
  };

  handleOnCreditQualificationRuleChange = (item) => {
    const indicatorIds = [...this.state.indicatorIds];
    if (indicatorIds.indexOf(item) !== -1) {
      indicatorIds.splice(indicatorIds.indexOf(item), 1);
    } else {
      indicatorIds.push(item);
    }
    this.setState({ indicatorIds });
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false
    });
  }

  render() {
    const {
      institutions,
      institution,
      category,
      fetchingInstitutions,
      creditQualificationIndicators,
      indicatorIds,
      existingInstitution
    } = this.state;

    let institutionsInputData: ISelectItems[] = institutions.map((item) => ({
      name: item.name,
      value: item.id,
    }));
    // order by institution name
    institutionsInputData.sort((a, b) => a.name.localeCompare(b.name))
    let categoryInputData: ISelectItems[] = [
      "CommercialBank",
      "BankOneOFI",
      "OFI",
    ].map((item) => ({
      name: item,
      value: item,
    }));

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Setup Institutions</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card no-b">
                  <div className="card-body">
                    <div className="col-md-12">
                      <div className="form-row">
                        <SelectInputComponent
                          id="category"
                          name="category"
                          divClass={6}
                          value={category}
                          items={categoryInputData}
                          required={false}
                          validated={false}
                          errors={[]}
                          onChange={this.handleOnSelectCategory}
                        />
                        {category == "" ? (
                          ""
                        ) : (
                          <SelectInputComponent
                            id="institution"
                            name="institution"
                            divClass={6}
                            value={institution.id}
                            items={institutionsInputData}
                            required={false}
                            validated={false}
                            errors={[]}
                            labelIconClass={fetchingInstitutions === true
                              ? "fas fa-spinner fa-spin orange-text mr-2"
                              : ""
                            }
                            onChange={this.handleOnSelectInstitution}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {_.isEmpty(institution) ? (
                    ""
                  ) : (
                    (existingInstitution === true) ? (
                      "Institution Already configured for Credit Assessment"
                    ) : (
                      <React.Fragment>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="card-body">
                              <h5 className="card-title">
                                Credit Qualification Indicators
                              </h5>
                              <div className="form-row">
                                <div className="col-md-12">
                                  <div
                                    className="text-nowrap block-example border"
                                    style={{
                                      width: "100%",
                                      height: "200px",
                                      overflowY: "scroll",
                                    }}
                                  >
                                    <React.Fragment>
                                      {creditQualificationIndicators.length === 0 ? (
                                        <div
                                          style={{
                                            backgroundColor: "#F3F3F3",
                                          }}
                                        >
                                          <p className="text-center">
                                            no credit qualification rule indicators
                                          </p>
                                        </div>
                                      ) : (
                                        <React.Fragment>
                                          {creditQualificationIndicators.map((item) => (
                                            <div
                                              className="custom-control custom-checkbox"
                                              key={creditQualificationIndicators.indexOf(item)}
                                            >
                                              <input
                                                id={item.id}
                                                name={item.id}
                                                className="custom-control-input"
                                                type="checkbox"
                                                onChange={() =>
                                                  this.handleOnCreditQualificationRuleChange(
                                                    item.id
                                                  )
                                                }
                                                checked={
                                                  _.isEmpty(
                                                    indicatorIds.filter((x) => x === item.id)
                                                  )
                                                    ? false
                                                    : true
                                                }
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor={item.id}
                                              >
                                                {item.description}
                                              </label>
                                            </div>
                                          ))}
                                        </React.Fragment>
                                      )}
                                    </React.Fragment>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="card-body">
                                <button
                                  type="submit"
                                  className="btn btn-sm btn-primary"
                                  disabled={this.state.submitting}
                                  onClick={(e) => {
                                    this.isValidated();
                                  }}
                                >
                                  {this.state.submitting === false ? (
                                    <React.Fragment>
                                      <i className="fas fa-lg fa-save mr-3" /> Save
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                      Please wait...
                                    </React.Fragment>
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default InstitutionSetup;
