export enum ActionTypes {
  SAVE = 1,
  UPDATE,
  DELETE,
  ENABLE,
  DISABLE,
  APPROVE,
  DECLINE,
  PUSHBACK,
  RECALL,
  REMOVE,
  ACCEPT,
  REJECT,
  CLOSE,
  INITIATE,
  ACTIVATE,
  DEACTIVATE,
  DISBURSE
}
