import http from "../httpService";

export function updateInstitutionConfig(institutionCode, payload: any) {
  return http.put(
    process.env.REACT_APP_CREDITASSESSMENT_BASEADDRESS +
      `InstitutionConfiguration/${institutionCode}`,
    payload
  );
}

export function retrieveInstitutionConfig(institutionCode) {
  return http.get(
    process.env.REACT_APP_CREDITASSESSMENT_BASEADDRESS +
      `InstitutionConfiguration/${institutionCode}`
  );
}

export function enablePQA(institutionCode, payload: any) {
  return http.put(
    process.env.REACT_APP_CREDITASSESSMENT_BASEADDRESS +
      `InstitutionConfiguration/pqa/enable/${institutionCode}`,
    payload
  );
}

export function disblePQA(institutionCode) {
  return http.put(
    process.env.REACT_APP_CREDITASSESSMENT_BASEADDRESS +
      `InstitutionConfiguration/pqa/disable/${institutionCode}`
  );
}

export function changeInterestRateMarkup(institutionCode, newInterestRateMarkup:number) {
  return http.patch(
    process.env.REACT_APP_CREDITASSESSMENT_BASEADDRESS +
      `InstitutionConfiguration/${institutionCode}/changeInterestRate/${newInterestRateMarkup}`
  );
}

export function topUpAvailableAmountForDisbursement(institutionCode: string, amount:number) {
  return http.patch(
    process.env.REACT_APP_CREDITASSESSMENT_BASEADDRESS +
      `InstitutionConfiguration/${institutionCode}/topup/${amount}`
  );
}

export default {
  retrieveInstitutionConfig,
  updateInstitutionConfig,
  enablePQA,
  disblePQA,
  changeInterestRateMarkup,
  topUpAvailableAmountForDisbursement
};
