import React, { Component } from "react";
// import recova_image from "../images/recova-image.jpg";
// import "../styles/normalize.css";
// import "../styles/custom.css";
// import "../styles/bootstrap.min.css";
import ModalVideo from "react-modal-video";

class SideBanner2 extends Component {
  state = {
    isOpen: false,
  };
  render() {
    return (
      <div
        className="text-center"
        // style="background-image:url(images/recova-image-copy.jpg);"
        // style={{ backgroundImage: `url(${recova_image})` }}
      >
        <article style={{ position: "absolute", top: "30%", margin: "10px" }}>
          {/* <h3
            style={{
              color: "ivory",
              fontSize: "28px",
              fontWeight: 550,
              fontFamily: "sans-serif",
            }}
          >
            Loan Repayment and Recovery, Fully Automated
          </h3>
          <p>
            <em
              className="italic-text"
              style={{ fontSize: "medium", color: "white" }}
            >
              Recova provides reduced rate of loan repayment default due to
              automation of loan repayment and recovery processes.
            </em>
          </p> */}

          <ModalVideo
            channel="youtube"
            isOpen={this.state.isOpen}
            videoId="6VTnwVnOqLM"
            onClose={() => this.setState({ isOpen: false })}
          />
          {/* <button
            className="btn btn-primary-outline fg-white btn-round"
            onClick={() => this.setState({ isOpen: true })}
            style={{
              borderRadius: "17px",
              backgroundColor: "transparent",
              borderColor: "#ccc",
            }}
          >
            <span className="text-span text-white">Learn More</span>
          </button> */}
          {/* <a
            href="https://www.youtube.com/watch?v=6VTnwVnOqLM"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="text-span text-white">Learn More</span>
          </a> */}
        </article>
      </div>
    );
  }
}

export default SideBanner2;
