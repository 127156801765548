import * as React from "react";
import { Fragment } from "react";
import _ from "lodash";
import ItemsTable, {
  filterFieldData,
} from "../../components/tableComponents/itemsTable";
import { IFilterData } from "../../services/approval/approvalItemService";
// import { getCurrentUserSession } from "../../services/auth/authService";
import BaseListComponent, {
  BaseListComponentState,
} from "./../../components/BaseListComponent";
import {
  createFilterData,
  mapEntityList,
} from "./../../services/utility/autoMapperService";
import institutionService from "../../services/auth/institutionsService";
import disputeService from "../../services/dispute/disputeService";
import { getSupportingDocumentsByLoanReference } from "../../services/mandate/supportingDocumentService";
import mandateService from "../../services/mandate/mandateService";
import TableFilterPane from "../../components/tableComponents/tableFilterPane";
import DisputeDetail from "./disputeDetail";

export interface UnResolvedDisputesProps {}

export interface UnResolvedDisputesState extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
}

class UnResolvedDisputesList extends BaseListComponent<
  UnResolvedDisputesState
> {}

class UnResolvedDisputes extends React.Component<
  UnResolvedDisputesProps,
  UnResolvedDisputesState
> {
  constructor(props: UnResolvedDisputesProps) {
    super(props);
    this.state = {
      data: createFilterData([
        "RetrievalReferenceNumber",
        "CustomerPhoneNumber",
        "DisputeStatus",
      ]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [
        { name: "RetrievalReferenceNumber", fieldType: "text", value: "" },
        { name: "CustomerPhoneNumber", fieldType: "text", value: "" },
        {
          name: "DisputeStatus",
          fieldType: "select",
          options: ["Active", "Inactive"],
          value: "",
        },
      ],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
    };
  }

  handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true });
    data = typeof data === "undefined" ? { ...this.state.data } : { ...data };
    // const institution = await institutionService.retrieveInstitutionById(
    //   getCurrentUserSession().institutionId
    // );
    try {
      data["isUnresolvedCase"] = {
        item1: "=",
        item2: "1",
      };
      const disputes = await disputeService.filterDisputes(
        data,
        pageSize,
        currentPage
      );
      //   console.log("response: ", disputes.data.item2);
      this.setState({
        tableItems: mapEntityList({
          entityList: disputes.data.item2,
          properties: [
            {
              oldName: "retrievalReferenceNumber",
              newName: "retrievalReferenceNumber",
            },
            { oldName: "linkedAccountNumber", newName: "linkedAccountNumber" },
            { oldName: "amountDebited", newName: "amountDebited" },
            { oldName: "customerPhoneNumber", newName: "customerPhoneNumber" },
            {
              isTag: true,
              oldName: "disputeStatus",
              newName: "disputeStatus",
              success: "Accepted",
              danger: "Rejected",
              default: "Closed",
              primary: "Weekly",
              secondary: "Unresolved",
              warning: "Pending",
              light: "light",
              dark: "Quaterly",
            },
            {
              oldName: "timeSwept",
              newName: "timeSwept",
              split: false,
            },
            {
              oldName: "dateCreated",
              newName: "dateCreated",
              split: false,
            },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: disputes.data.item1,
        fetching: false,
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
        // this.setState({ errors, fetching: false });
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        // this.setState({ errors, fetching: false });
      }
    } finally {
      this.setState({ fetching: false });
    }
  };

  handleOnExport = async (type: string) => {
    try {
      //   switch (type) {
      //       case value:
      //           break;
      //       default:
      //           break;
      //   }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
      } else {
      }
    }
  };

  fetchItemById = async (item: any) => {
    this.setState({ fetchingDetail: true });
    const response = await disputeService.retrieveDisputeById(item.id);
    const dispute = response.data;
    if (!_.isEmpty(dispute)) {
      const disputingInstitution = await institutionService.retrieveInstitutionByCode(
        dispute.linkedAccountInstitution
      );
      const lendingInstitution = await institutionService.retrieveInstitutionByCode(
        dispute.loanAccountInstitution
      );

      const supportingDocuments = await getSupportingDocumentsByLoanReference(
        dispute.loanAccountNumber,
        dispute.id
      );
      const mandate = await mandateService.retrieveMandateByLoanReferenceOnly(
        dispute.loanAccountNumber
      );

      // console.log("the mandate with customer name: ", mandate);

      dispute["customerName"] = mandate.data.customerName;
      dispute["disputingInstitution"] = disputingInstitution.data;
      dispute["lendingInstitution"] = lendingInstitution.data;
      dispute["supportingDocuments"] = supportingDocuments.data;
      // console.log("final dispute: ", dispute);
      dispute.requiredDisputeDocuments.forEach((element) => {
        element["available"] =
          dispute.supportingDocuments.filter(
            (x) => x.disputeConditionDocumentType === element.name
          ).length > 0
            ? true
            : false;
      });
      if (dispute.disputeActions.length > 1) {
        dispute["disputeActions"] = dispute.disputeActions.reverse();
      }
      // console.log("dispute: ", dispute);
      this.setState({ item: dispute });
      this.setState({ fetchingDetail: false });
    }
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }

  render() {
    const {
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Un-Resolved Dispute Cases</b>
              </h3>
            </div>
            <UnResolvedDisputesList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              initialValues={{
                data: createFilterData([
                  "RetrievalReferenceNumber",
                  "CustomerPhoneNumber",
                  "DisputeStatus",
                ]) as IFilterData,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: [
                  {
                    name: "RetrievalReferenceNumber",
                    fieldType: "text",
                    value: "",
                  },
                  { name: "CustomerPhoneNumber", fieldType: "text", value: "" },
                  {
                    name: "DisputeStatus",
                    fieldType: "select",
                    options: ["Active", "Inactive"],
                    value: "",
                  },
                ],
                item: {},
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                onToggleEditModal,
                showDetailsModal,
                showEditModal,
              }) => (
                <Fragment>
                  <DisputeDetail
                    item={item}
                    toggleDetailsModal={onToggleDetailsModal}
                    toggleEditModal={onToggleEditModal}
                    showDetailsModal={showDetailsModal}
                    showTrail={null}
                    reloadItem={this.fetchItemById}
                    isUnresolvedDisputesList={true}
                  />
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="Un-Resolved Dispute Cases"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                              amountColumn={["amountDebited"]}
                              timeColumn={["timeSwept", "dateCreated"]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default UnResolvedDisputes;
