import React, { useContext } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import SelectInputComponent, {
  ISelectItems,
} from "../../../components/formSelectComponent";
import { getCurrentUserSession } from "../../../services/auth/authService";
import FormInputComponent from "../../../components/formInputComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import authLoanProductService from "../../../services/auth/loanProductService";
import appraisalLoanProductService from "../../../services/hybridAppraisal/loanProductService";
import appraisalValidationService from "../../../services/hybridAppraisal/appraisalValidationService";
import { decodeToken } from "react-jwt";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import scoringCardService from "../../../services/creditAssessment/scoringCardService";
import { Accordion, AccordionContext, Card, useAccordionToggle } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import ModifyCreditPolicyParameters from "../creditPolicy/modifyCreditPolicyParameters";
import appraisalInstitutionService from "../../../services/hybridAppraisal/appraisalInstitutionService";
import creditPolicyParameterService from "../../../services/hybridAppraisal/creditPolicyParameterService";
import { CreditPolicyValueType } from "../../../enums/creditPolicyValueType";
import { CreditPolicyUseCase } from "../../../enums/creditPolicyUseCase";
import { docData } from "./productDocumentsData";
import textFormatService from "../../../services/utility/textFormatService";


export function ContextAwareToggle({ isHeader, eventKey, header }) {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
  return (
    <>
      {isHeader ? (
        <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
          <b>{header}</b>
          <i
            className={angleType}
            aria-hidden="true"
            style={{ float: "right" }}
          ></i>
        </Card.Header>
      ) : (
        <div onClick={toggleOnClick} style={{ cursor: "pointer", display: "flex", justifyContent: "space-between" }}>
          <div>
            <b>{header}</b>
          </div>
          <div style={{ float: "right", paddingRight: "3px", paddingTop: "1px" }}>
            <i
              className={angleType}
              aria-hidden="true"
            ></i>
          </div>
        </div>
      )}
    </>
  );
}

interface AddProductConfigurationFields {
  productName: string;
  productCode: string;
  productCategory: string;
  minimumAmount: string;
  maximumAmount: string;
  interestRate: string;
  tenure: string;
  moratorium: string;
  hasGuarantor: boolean;
  numberOfGuarantors?: string;
  scoreCardId: string;
  feeName: string;
  feeType: string;
  processingFee: string;
  minimum_amount: string;
  maximum_amount: string;
  minimum_tenure: string;
  maximum_tenure: string;
  interest: string;
}

// interface ILoanPrices {
//   name: string;
//   feeType: string;
//   value: number;
// }

interface ILoanPrices {
  name: string;
  minimumAmount: string;
  maximumAmount: string;
  minimumTenure: string;
  maximumTenure: string;
  interest: number
  feeType: string;
  processingFee: number;
}


class AddProductConfigurationForm extends BaseFormComponent<AddProductConfigurationFields> { }

interface IAddProductConfigurationProps { }
interface IAddProductConfigurationState {
  loanProducts: any;
  scoreCards: any;
  hasGuarantor: boolean;
  creditPolicyParameters: any;
  selectedCreditPolicies: any
  documents: any;
  documentsPost: any;
  documentsData: ISelectItems[];
  fetchingRequiredItems: boolean;
  showSetCreditPolicyModal: boolean;
  parameterItem: any;
  prices: ILoanPrices[];
  institutionCategory: string;
}

class AddProductConfiguration extends React.Component<
  IAddProductConfigurationProps,
  IBaseFormState & IAddProductConfigurationState
> {
  constructor(props: IAddProductConfigurationProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      loanProducts: [],
      scoreCards: [],
      creditPolicyParameters: [],
      selectedCreditPolicies: [],
      documents: [],
      documentsData: [],
      hasGuarantor: false,
      fetchingRequiredItems: false,
      showSetCreditPolicyModal: false,
      parameterItem: {},
      prices: [],
      institutionCategory: "",
      documentsPost: []
    };
  }

  async componentDidMount() {
    const { errors } = this.state;
    try {
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      this.setState({ fetchingRequiredItems: true })
      const response = await Promise.allSettled([
        authLoanProductService.retrieveAllCreditAssessmentProducts(decodedToken?.["InstitutionId"]),
        scoringCardService.retrieveScoreCardByInstitutionCode(decodedToken?.["InstitutionCode"]),
        appraisalInstitutionService.retrieveByInstitutionCode(decodedToken?.["InstitutionCode"]),
        docData
      ])
      const loanProducts = response[0].status == "fulfilled" ? response[0].value.data : [];
      const scoreCards = response[1].status == "fulfilled" ? response[1].value.data : [];
      const creditPolicyParameters = response[2].status == "fulfilled" ? response[2].value.data.creditPolicyParameters : [];
      const institutionDetails = response[2].status == "fulfilled" ? response[2].value.data : [];
      const documentsDataResp = response[3].status == "fulfilled" ? response[3].value.data : [];

      console.log(loanProducts);

      console.log(institutionDetails)
      this.setState({
        loanProducts: loanProducts,
        scoreCards: scoreCards,
        creditPolicyParameters: creditPolicyParameters,
        fetchingRequiredItems: false,
        institutionCategory: institutionDetails.category,
        documentsData: documentsDataResp.map(item => {
          return { name: item.split("=")[0], value: item.split("=")[1] }
        })
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        errors.response = "Internal server error.";
        this.setState({ errors });
      }
    }
  }

  onFormSubmit(fields: AddProductConfigurationFields, onReloadFieldsCB: any): boolean {
    try {
      fields.hasGuarantor = this.state.hasGuarantor;
      this.setState(
        {
          errors: appraisalValidationService.validateAddProductConfigurationForm(fields),
        },
        () => {
          // if (Object.keys(this.state.errors).length === 0) {
          return this.submit(fields, onReloadFieldsCB);
          // }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddProductConfigurationFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true });
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const payload: any = {};
      const document = this.state.documents;
      const documentPost = this.state.documentsPost
      const combinedDocuments = document.concat(documentPost);
      payload.name = this.state.institutionCategory === "BankOneOFI" ? this.state.loanProducts.filter((item) => item.id === Number(fields.productName))[0].name : fields.productName;
      payload.institutionCode = decodedToken?.["InstitutionCode"];
      payload.productCode = fields.productCode;
      payload.productCategory = fields.productCategory;
      payload.minimumAmount = Number(fields.minimumAmount);
      payload.maximumAmount = Number(fields.maximumAmount);
      payload.interestRate = Number(fields.interestRate);
      payload.tenure = Number(fields.tenure);
      payload.moratorium = Number(fields.moratorium);
      payload.scoreCardId = fields.scoreCardId;
      payload.hasGuarantor = this.state.hasGuarantor;
      payload.numberOfGuarantors = this.state.hasGuarantor ? Number(fields.numberOfGuarantors) : 0;
      payload.creditPolicyCriteria = this.state.selectedCreditPolicies;
      payload.ProductDocuments = combinedDocuments;
      payload.productPricing = this.state.prices;

      // console.log("Payload: ", payload)
      const response = await appraisalLoanProductService.saveLoanProductConfig(payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Add Product Configuration",
          description: `Added new Product Configuration ${fields.productName}`,
        });
        toast.info(`Product Configuration ${payload.name} created successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false });
      this.resetFields();
      onReloadFieldsCB();
    } catch (error) {
      toast.error(error.response.data, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      this.setState({ submitting: false });
      return false;
    } finally {
      return true;
    }
  }

  toggleRequireGuarantor = () => {
    this.setState({ hasGuarantor: !this.state.hasGuarantor });
  };


  handleOnAddPricing = (item: any) => {
    this.setState(
      {},
      () => {

        const prices = [...this.state.prices];
        if (this.validateAmountRanges(item, prices) /*&& this.validateTenureRanges(item, prices)*/) {
          prices.push({
            name: item.feeName,
            feeType: item.feeType,
            maximumAmount: item.maximum_amount,
            minimumAmount: item.minimum_amount,
            maximumTenure: item.maximum_tenure,
            minimumTenure: item.minimum_tenure,
            processingFee: item.processingFee,
            interest: item.interest
          }
          );
          this.setState({ prices });
        }
        else {
          toast.info(`Amount / Tenure Range Overlap, Please Check Entries`, {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          });
        }
        // onReloadFieldsCB(item);
      }
    );
  };

  amountRangesOverlap = (a, b) => {
    return (Number(b.minimum_amount) >= Number(a.minimumAmount) && Number(b.minimum_amount) <= Number(a.maximumAmount)) || (Number(b.maximum_amount) >= Number(a.minimumAmount) && Number(b.maximum_amount) <= Number(a.maximumAmount));
    ;
  };

  validateAmountRanges = (item, prices) => {
    // const prices = [...this.state.prices];
    var newPrices: any = []
    // console.log(this.validateTenureRanges(item, prices))
    // if (!this.validateTenureRanges(item, prices)) {
    for (let price of prices) {
      // var newPrice: any = {}
      // console.log(price)
      // console.log(item)
      // console.log((item.minScore >= price.minScore && item.minScore <= price.maxScore) || (item.maxScore >= price.minScore && item.maxScore <= price.maxScore))
      if (this.amountRangesOverlap(price, item)) {
        newPrices.push(price)
        if (!(price.minimumAmount === item.minimum_amount && price.maximumAmount === item.maximum_amount)) {
          return false;
        }
      }
    }
    for (let newPrice of newPrices) {
      if (this.tenureRangesOverlap(item, newPrice)) {
        return false; // No overlapping ranges
      }

    }
    return true;
  };

  tenureRangesOverlap = (a, b) => {
    return (Number(b.minimumTenure) >= Number(a.minimum_tenure) && Number(b.minimumTenure) <= Number(a.maximum_tenure)) || (Number(b.maximumTenure) >= Number(a.minimum_tenure) && Number(b.maximumTenure) <= Number(a.maximum_tenure));
    ;
  };

  validateTenureRanges = (item, prices) => {
    // const prices = [...this.state.prices];
    // console.log(prices)
    // console.log(item)
    prices = prices.filter(x => x.minimumAmount === item.minimum_amount && x.maximumAmount === item.maximum_amount)
    // console.log(prices)
    for (let price of prices) {
      // console.log(price)
      // console.log(item)
      // console.log((item.minScore >= price.minScore && item.minScore <= price.maxScore) || (item.maxScore >= price.minScore && item.maxScore <= price.maxScore))
      if (this.tenureRangesOverlap(price, item)) {
        return false; // New range overlaps with existing, don't add it
      }
    }
    return true; // No overlapping ranges
  };

  // handleReloadFields = (fields: AddProductConfigurationFields) => {
  //   fields.feeName = ""
  // }

  handleOnRemovePricing = (item: any) => {
    const prices = [...this.state.prices];
    if (prices.includes(item)) {
      prices.splice(
        prices.indexOf(item), 1
      );
    }
    this.setState({ prices });
  };

  handleOnChangeDocuments = async (item) => {
    const docs = [...this.state.documents];
    if (docs.findIndex(object => { return object.name === item.name }) !== -1) {
      docs.splice(docs.findIndex(object => { return object.name === item.name }), 1)
      this.setState({ documents: docs });
    } else {
      const documents = [...this.state.documents];
      const documentsData = [...this.state.documentsData];
      // prepare payload 
      console.log(documents)

      let selectedDoc = documentsData.filter(data => data.name == item.name)[0];
      let payload = {
        name: selectedDoc.name,
        category: selectedDoc.value,
        type: "pre"
      }
      documents.push(payload);
      this.setState({ documents });
    }
  };
  handleOnChangePostDocuments = async (item) => {
    const docs = [...this.state.documentsPost];
    if (docs.findIndex(object => { return object.name === item.name }) !== -1) {
      docs.splice(docs.findIndex(object => { return object.name === item.name }), 1)
      this.setState({ documentsPost: docs });
    } else {
      const documentsPost = [...this.state.documentsPost];
      const documentsData = [...this.state.documentsData];
      // prepare payload 

      console.log(documentsPost)
      let selectedDoc = documentsData.filter(data => data.name == item.name)[0];
      let payload = {
        name: selectedDoc.name,
        category: selectedDoc.value,
        type: "post"
      }
      documentsPost.push(payload);
      this.setState({ documentsPost });
    }
  };

  handleOnChangeCreditPolicy = async (item) => {
    let parameters;
    const creditPolicy = [...this.state.selectedCreditPolicies];
    if (creditPolicy.findIndex(object => { return object.creditPolicyParameterId === item.id }) !== -1) {
      creditPolicy.splice(creditPolicy.findIndex(object => { return object.creditPolicyParameterId === item.id }), 1)
      this.setState({ selectedCreditPolicies: creditPolicy });
    } else {
      parameters = (await creditPolicyParameterService.retrieveById(item.id)).data;
      this.setState({ parameterItem: parameters, showSetCreditPolicyModal: true });
    }
  };

  handleOnIndicatorParamsSave = (toSave: boolean, payload) => {
    if (toSave) {
      const selectedCreditPolicies = [...this.state.selectedCreditPolicies];
      const creditPolicyParameters = [...this.state.creditPolicyParameters];
      selectedCreditPolicies.push(payload);
      const creditPolicy = creditPolicyParameters.filter(item => item.id === payload.creditPolicyParameterId)[0];
      creditPolicy.parameter = payload
      this.setState({ selectedCreditPolicies, creditPolicyParameters, showSetCreditPolicyModal: false });
    } else {
      this.setState({ showSetCreditPolicyModal: false });
    }
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
    });
  }

  render() {
    const {
      errors,
      validated,
      loanProducts,
      scoreCards,
      hasGuarantor,
      fetchingRequiredItems,
      creditPolicyParameters,
      parameterItem,
      selectedCreditPolicies,
      documentsData,
      documents,
      documentsPost,
      prices,
      institutionCategory
    } = this.state;

    let productNamesInputData: ISelectItems[] = loanProducts.map((item) => ({
      name: item.name,
      value: item.id,
    }));

    let productCategoryInputData: ISelectItems[] = [
      { name: "Individual", value: "Individual" },
      { name: "Corporate", value: "Corporate" },
      { name: "Group", value: "Group" }
    ];

    let feeTypeInputData: ISelectItems[] = [
      { name: "Value", value: "Value" },
      { name: "Percentage", value: "Percentage" }
    ];

    let scoreCardInputData: ISelectItems[] = scoreCards.map((item) => ({
      name: item.name,
      value: item.id,
    }));

    // let documentsInputData = documentsData.map(item => {
    //   return {name: item.name, value: item.name}
    // })

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add Product Configuration</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddProductConfigurationForm
                  initialValues={{
                    productName: "",
                    productCode: "",
                    productCategory: "",
                    minimumAmount: "",
                    maximumAmount: "",
                    interestRate: "",
                    tenure: "",
                    moratorium: "",
                    hasGuarantor: false,
                    numberOfGuarantors: "",
                    scoreCardId: "",
                    feeName: "",
                    feeType: "",
                    processingFee: "",
                    minimum_amount: "",
                    maximum_amount: "",
                    minimum_tenure: "",
                    maximum_tenure: "",
                    interest: "",
                  }}
                  FormComponent={({
                    fields: {
                      productName,
                      productCode,
                      productCategory,
                      maximumAmount,
                      minimumAmount,
                      interestRate,
                      tenure,
                      moratorium,
                      numberOfGuarantors,
                      scoreCardId,
                      feeName,
                      feeType,
                      processingFee,
                      minimum_amount,
                      maximum_amount,
                      minimum_tenure,
                      maximum_tenure,
                      interest,
                    },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <h5 className="card-title">
                            <i className="fas fa-university mr-3" />
                            Product Configuration Details
                          </h5>
                          <div className="form-row">
                            <div className="col-md-12">
                              <div className="form-row">
                                {institutionCategory === "BankOneOFI" ? (
                                  <SelectInputComponent
                                    id="productName"
                                    name="productName"
                                    divClass={6}
                                    value={productName}
                                    items={productNamesInputData}
                                    required={true}
                                    validated={validated}
                                    errors={errors}
                                    labelIconClass={fetchingRequiredItems === true
                                      ? "fas fa-spinner fa-spin orange-text mr-2"
                                      : ""
                                    }
                                    onChange={async (id, value) => {
                                      await onChange(id, value);
                                      await onChange(
                                        "productCode",
                                        loanProducts.filter(
                                          (item) => item.id === Number(value)
                                        )[0].code
                                      );
                                      await onChange(
                                        "tenure",
                                        loanProducts
                                          .filter(
                                            (item) => item.id === Number(value)
                                          )[0]
                                          .loanCycle.toString()
                                      );
                                      await onChange(
                                        "moratorium",
                                        loanProducts
                                          .filter(
                                            (item) => item.id === Number(value)
                                          )[0]
                                          .moratorium.toString()
                                      );
                                      await onChange(
                                        "minimumAmount",
                                        loanProducts
                                          .filter(
                                            (item) => item.id === Number(value)
                                          )[0]
                                          .minimumAmount.toString()
                                      );
                                      await onChange(
                                        "maximumAmount",
                                        loanProducts
                                          .filter(
                                            (item) => item.id === Number(value)
                                          )[0]
                                          .maximumAmount.toString()
                                      );
                                      await onChange(
                                        "interestRate",
                                        loanProducts
                                          .filter(
                                            (item) => item.id === Number(value)
                                          )[0]
                                          .interest.toString()
                                      );
                                    }}
                                  />
                                ) : (
                                  <FormInputComponent
                                    id="productName"
                                    type="text"
                                    name="productName"
                                    placeholder=""
                                    divClass={6}
                                    value={productName}
                                    required={true}
                                    validated={validated}
                                    errors={errors}
                                    onChange={onChange}
                                    disabled={false}
                                  />
                                )}
                                <FormInputComponent
                                  id="productCode"
                                  type="text"
                                  name="productCode"
                                  placeholder=""
                                  divClass={6}
                                  value={productCode}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                  disabled={institutionCategory === "BankOneOFI" ? true : false}
                                />
                              </div>
                              <div className="form-row">
                                <FormInputComponent
                                  label="Tenure In Days"
                                  id="tenure"
                                  type="number"
                                  name="tenure"
                                  placeholder=""
                                  divClass={6}
                                  value={tenure}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                  disabled={institutionCategory === "BankOneOFI" ? true : false}
                                />
                                <FormInputComponent
                                  id="moratorium"
                                  type="number"
                                  name="moratorium"
                                  placeholder=""
                                  divClass={6}
                                  value={moratorium}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                  disabled={institutionCategory === "BankOneOFI" ? true : false}
                                />
                              </div>
                              <div className="form-row">
                                <FormInputComponent
                                  label="Minimum Amount"
                                  id="minimumAmount"
                                  type="number"
                                  name="minimumAmount"
                                  placeholder=""
                                  divClass={6}
                                  value={minimumAmount}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                  disabled={institutionCategory === "BankOneOFI" ? true : false}
                                />
                                <FormInputComponent
                                  label="Maximum Amount"
                                  id="maximumAmount"
                                  type="number"
                                  name="maximumAmount"
                                  placeholder=""
                                  divClass={6}
                                  value={maximumAmount}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                  disabled={institutionCategory === "BankOneOFI" ? true : false}
                                />
                                <FormInputComponent
                                  label="Interest Rate"
                                  id="interestRate"
                                  type="number"
                                  name="interestRate"
                                  placeholder=""
                                  divClass={6}
                                  value={interestRate}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                  disabled={institutionCategory === "BankOneOFI" ? true : false}
                                />
                              </div>

                              <div className="form-row">
                                <SelectInputComponent
                                  id="productCategory"
                                  name="productCategory"
                                  divClass={6}
                                  value={productCategory}
                                  items={productCategoryInputData}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <SelectInputComponent
                                  id="scoreCardId"
                                  name="scoreCard"
                                  divClass={6}
                                  value={scoreCardId}
                                  items={scoreCardInputData}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                  labelIconClass={fetchingRequiredItems === true
                                    ? "fas fa-spinner fa-spin orange-text mr-2"
                                    : ""
                                  }
                                />
                                {/* <div className="form-row col-md-6">
                                  <div className="col-md-4">
                                    <label
                                      htmlFor="requireGuarantor"
                                      className="col-form-label s-12 mb-2"
                                    >
                                      Require Guarantor
                                    </label>
                                    <br />
                                    <span className="material-switch ml-2">
                                      <input
                                        id="hasGuarantor"
                                        name="hasGuarantor"
                                        type="checkbox"
                                        onChange={this.toggleRequireGuarantor}
                                        checked={hasGuarantor}
                                      />
                                      <label
                                        htmlFor="hasGuarantor"
                                        className="bg-primary"
                                      ></label>
                                    </span>
                                  </div>
                                  {hasGuarantor ? (
                                    <FormInputComponent
                                      id="numberOfGuarantors"
                                      type="number"
                                      name={"Number of Guarantors"}
                                      placeholder=""
                                      divClass={8}
                                      value={numberOfGuarantors}
                                      required={true}
                                      validated={validated}
                                      errors={errors}
                                      onChange={onChange}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div> */}
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="card mt-3">
                            <div className="card-header clear-fix">
                              <h6
                                className="float-left"
                                style={{ marginBottom: "0px" }}
                              >
                                <b>Product Pricing</b>
                              </h6>
                            </div>
                            <div className="card-body">
                              <div className="form-row">
                                <FormInputComponent
                                  label="Loan Pricing Name"
                                  id="feeName"
                                  name="feeName"
                                  type="text"
                                  placeholder=""
                                  divClass={6}
                                  value={feeName}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  label="Minimum Amount"
                                  id="minimum_amount"
                                  name="minimum_amount"
                                  type="number"
                                  placeholder=""
                                  divClass={6}
                                  value={minimum_amount}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  label="Maximum Amount"
                                  id="maximum_amount"
                                  name="maximum_amount"
                                  type="number"
                                  placeholder=""
                                  divClass={6}
                                  value={maximum_amount}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  label="Minimum Tenure"
                                  id="minimum_tenure"
                                  name="minimum_tenure"
                                  type="number"
                                  placeholder=""
                                  divClass={6}
                                  value={minimum_tenure}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  label="Maximum Tenure"
                                  id="maximum_tenure"
                                  name="maximum_tenure"
                                  type="number"
                                  placeholder=""
                                  divClass={6}
                                  value={maximum_tenure}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <SelectInputComponent
                                  id="feeType"
                                  name="feeType"
                                  divClass={6}
                                  value={feeType}
                                  items={feeTypeInputData}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  label="Processing Fee"
                                  id="processingFee"
                                  name="processingFee"
                                  type="number"
                                  placeholder=""
                                  divClass={6}
                                  value={processingFee}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  label="Interest"
                                  id="interest"
                                  name="interest"
                                  type="number"
                                  placeholder=""
                                  divClass={6}
                                  value={interest}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />

                                {/* Add Pricing */}
                                <div className="form-row">
                                  <div className="my-1 mx-2">
                                    <button
                                      className="btn btn-sm btn-outline-primary my-4"
                                      style={{ cursor: "pointer" }}
                                      type="button"
                                      onClick={() =>
                                        this.handleOnAddPricing(
                                          {
                                            feeName,
                                            minimum_amount,
                                            maximum_amount,
                                            minimum_tenure,
                                            maximum_tenure,
                                            interest,
                                            feeType,
                                            processingFee
                                          },
                                        )
                                      }
                                    >
                                      <React.Fragment>
                                        <i className="far fa-save fa-lg mr-2" />{" "}
                                        Add
                                      </React.Fragment>
                                    </button>
                                  </div>
                                </div>
                                <div className=" form-row col-md-12">
                                  <div
                                    className="table-responsive text-nowrap"
                                    style={{
                                      maxHeight: "150px",
                                      overflowY: "scroll",
                                    }}
                                  >
                                    <table className="table table-hover table-content table-sm table-striped mb-0">
                                      <thead>
                                        <tr>
                                          <th
                                            scope="col"
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                            }}
                                          >
                                            <strong>#</strong>
                                          </th>
                                          <th
                                            scope="col"
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                            }}
                                          >
                                            <strong>Fee Name</strong>
                                          </th>

                                          <th
                                            scope="col"
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                            }}
                                          >
                                            <strong>Minimum Amount</strong>
                                          </th>

                                          <th
                                            scope="col"
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                            }}
                                          >
                                            <strong>Maximum Amount</strong>
                                          </th>
                                          <th
                                            scope="col"
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                            }}
                                          >
                                            <strong>Minimum Tenure</strong>
                                          </th>
                                          <th
                                            scope="col"
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                            }}
                                          >
                                            <strong>Maximum Tenure</strong>
                                          </th>
                                          <th
                                            scope="col"
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                            }}
                                          >
                                            <strong>Fee Type</strong>
                                          </th>
                                          <th
                                            scope="col"
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                            }}
                                          >
                                            <strong>Processing Fee</strong>
                                          </th>
                                          <th
                                            scope="col"
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                            }}
                                          >
                                            <strong>Interest</strong>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {prices.map((item) => (
                                          <tr key={prices.indexOf(item)}>
                                            <td
                                              style={{
                                                paddingTop: "0.2rem",
                                                paddingBottom: "0.2rem",
                                                fontSize: "small",
                                              }}
                                            >
                                              {prices.indexOf(item) + 1}
                                            </td>
                                            <td
                                              style={{
                                                paddingTop: "0.2rem",
                                                paddingBottom: "0.2rem",
                                                fontSize: "small",
                                              }}
                                            >
                                              {item.name}
                                            </td>
                                            <td
                                              style={{
                                                paddingTop: "0.2rem",
                                                paddingBottom: "0.2rem",
                                                fontSize: "small",
                                              }}
                                            >
                                              {textFormatService.formatCurrency(Number(item.minimumAmount), 2)}
                                            </td>
                                            <td
                                              style={{
                                                paddingTop: "0.2rem",
                                                paddingBottom: "0.2rem",
                                                fontSize: "small",
                                              }}
                                            >
                                              {textFormatService.formatCurrency(Number(item.maximumAmount), 2)}
                                            </td>
                                            <td
                                              style={{
                                                paddingTop: "0.2rem",
                                                paddingBottom: "0.2rem",
                                                fontSize: "small",
                                              }}
                                            >
                                              {item.minimumTenure}
                                            </td>
                                            <td
                                              style={{
                                                paddingTop: "0.2rem",
                                                paddingBottom: "0.2rem",
                                                fontSize: "small",
                                              }}
                                            >
                                              {item.maximumTenure}
                                            </td>
                                            <td
                                              style={{
                                                paddingTop: "0.2rem",
                                                paddingBottom: "0.2rem",
                                                fontSize: "small",
                                              }}
                                            >
                                              {item.feeType}
                                            </td>

                                            <td
                                              style={{
                                                paddingTop: "0.2rem",
                                                paddingBottom: "0.2rem",
                                                fontSize: "small",
                                              }}
                                            >
                                              {item.feeType === "Percentage" ? item.processingFee + "%" : textFormatService.formatCurrency(Number(item.processingFee), 2)}
                                            </td>
                                            <td
                                              style={{
                                                paddingTop: "0.2rem",
                                                paddingBottom: "0.2rem",
                                                fontSize: "small",
                                              }}
                                            >
                                              {item.interest + "%"}
                                            </td>
                                            <td
                                              style={{
                                                paddingTop: "0.2rem",
                                                paddingBottom: "0.2rem",
                                                fontSize: "small",
                                              }}
                                            >
                                              <i
                                                className="far fa-trash-alt ml-5 red-text fa-lg"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  this.handleOnRemovePricing(
                                                    item
                                                  )
                                                }
                                              ></i>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* </div> */}
                          <hr />

                          <div className="form-row">
                            <div className="col-md-12">
                              <div className="form-row">
                                <div className="col-md-12">
                                  <label
                                    htmlFor="documents"
                                    className="control-label textbox-label pl-2"
                                  >
                                    Pre-Approval Documents
                                  </label>
                                  <div
                                    className="block-example border"
                                    style={{
                                      width: "100%",
                                      maxWidth: "100%",
                                      overflowX: "hidden",
                                      height: "200px",
                                      overflowY: "scroll",
                                    }}
                                  >
                                    {documentsData.length > 0 ? (
                                      <React.Fragment>
                                        {documentsData.map(
                                          (item) => (
                                            <div style={{ border: '2px #84d4fc solid', borderRadius: '4px', backgroundColor: '#e4f4fc', marginTop: '5px', inlineSize: "100%" }}>
                                              <div
                                                className="custom-control custom-checkbox"
                                                style={{ display: 'inline-block' }}
                                                key={documentsData.indexOf(item)}
                                              >
                                                <input
                                                  id={item.name}
                                                  name={item.name}
                                                  className="custom-control-input"
                                                  type="checkbox"
                                                  onChange={() =>
                                                    this.handleOnChangeDocuments(
                                                      item
                                                    )
                                                  }
                                                  checked={
                                                    _.isEmpty(
                                                      documents.filter(
                                                        (x) => x.name === item.name
                                                      )
                                                    )
                                                      ? false
                                                      : true
                                                  }
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor={item.name}
                                                >
                                                  {item.name}
                                                </label>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </React.Fragment>
                                    ) : (
                                      "No Document Collection has been assigned to institution"
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <label
                                    htmlFor="documents"
                                    className="control-label textbox-label pl-2"
                                  >
                                    Post-Approval Documents
                                  </label>
                                  <div
                                    className="block-example border"
                                    style={{
                                      width: "100%",
                                      maxWidth: "100%",
                                      overflowX: "hidden",
                                      height: "200px",
                                      overflowY: "scroll",
                                    }}
                                  >
                                    {documentsData.length > 0 ? (
                                      <React.Fragment>
                                        {documentsData.map(
                                          (item) => (
                                            <div style={{ border: '2px #84d4fc solid', borderRadius: '4px', backgroundColor: '#e4f4fc', marginTop: '5px', inlineSize: "100%" }}>
                                              <div
                                                className="custom-control custom-checkbox"
                                                style={{ display: 'inline-block' }}
                                                key={documentsData.indexOf(item)}
                                              >
                                                <input
                                                  id={item.name + "Post"}
                                                  name={item.name + "Post"}
                                                  className="custom-control-input"
                                                  type="checkbox"
                                                  onChange={() =>
                                                    this.handleOnChangePostDocuments(
                                                      item
                                                    )
                                                  }
                                                  checked={
                                                    _.isEmpty(
                                                      this.state.documentsPost.filter(
                                                        (x) => x.name === item.name
                                                      )
                                                    )
                                                      ? false
                                                      : true
                                                  }
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor={item.name + "Post"}
                                                >
                                                  {item.name}
                                                </label>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </React.Fragment>
                                    ) : (
                                      "No Document Collection has been assigned to institution"
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <label
                                    htmlFor="indicatorIds"
                                    className="control-label textbox-label pl-2"
                                  >
                                    Credit Policy Criteria
                                  </label>
                                  <div
                                    className="block-example border"
                                    style={{
                                      width: "100%",
                                      maxWidth: "100%",
                                      overflowX: "hidden",
                                      height: "200px",
                                      overflowY: "scroll",
                                    }}
                                  >
                                    {creditPolicyParameters.length > 0 ? (
                                      <React.Fragment>
                                        {creditPolicyParameters.map(
                                          (item) => (
                                            <Accordion key={creditPolicyParameters.indexOf(item)}>
                                              <div style={{ border: '2px #84d4fc solid', borderRadius: '4px', backgroundColor: '#e4f4fc', marginTop: '5px', inlineSize: "100%" }}>
                                                <ContextAwareToggle
                                                  eventKey={`0`}
                                                  isHeader={false}
                                                  header={
                                                    <div
                                                      className="custom-control custom-checkbox"
                                                      style={{ display: 'inline-block' }}
                                                      key={creditPolicyParameters.indexOf(item)}
                                                    >
                                                      <input
                                                        id={item.id}
                                                        name={item.id}
                                                        className="custom-control-input"
                                                        type="checkbox"
                                                        onChange={() =>
                                                          this.handleOnChangeCreditPolicy(
                                                            item
                                                          )
                                                        }
                                                        checked={
                                                          _.isEmpty(
                                                            selectedCreditPolicies.filter(
                                                              (x) => x.creditPolicyParameterId === item.id
                                                            )
                                                          )
                                                            ? false
                                                            : true
                                                        }
                                                      />
                                                      <label
                                                        className="custom-control-label"
                                                        htmlFor={item.id}
                                                      >
                                                        {item.name}
                                                      </label>
                                                    </div>
                                                  }
                                                ></ContextAwareToggle>
                                              </div>
                                              <Accordion.Collapse eventKey={`0`}>
                                                <Card.Body style={{ padding: "5px", backgroundColor: '#e4f4fc' }}>
                                                  <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                    <div className="row">
                                                      <div className="col-md-1">
                                                      </div>
                                                      <div className="col-md-8">
                                                        {!_.isEmpty(item.parameter) ? (
                                                          <dl className="row" style={{ marginBottom: "0px" }} key={item.parameter.creditPolicyParameterId}>
                                                            {(() => {
                                                              switch (item.creditPolicyValueType) {
                                                                case CreditPolicyValueType[CreditPolicyValueType.MaxSingleInput]:
                                                                  return (
                                                                    <DetailItem
                                                                      label={item.name}
                                                                      value={item.parameter.maxSingleInputValue}
                                                                      labelSize={5}
                                                                      valueSize={7}
                                                                    />
                                                                  )
                                                                case CreditPolicyValueType[CreditPolicyValueType.MinSingleInput]:
                                                                  return (
                                                                    <DetailItem
                                                                      label={item.name}
                                                                      value={item.parameter.mixSingleInputValue}
                                                                      labelSize={5}
                                                                      valueSize={7}
                                                                    />
                                                                  )
                                                                case CreditPolicyValueType[CreditPolicyValueType.DropDownOptions]:
                                                                  return (
                                                                    <div className="form-row">
                                                                      {item.parameter.creditPolicyUseCase === CreditPolicyUseCase[CreditPolicyUseCase.ScoringIndicator] ? (
                                                                        (item.parameter.dropDownOptions).map((option) => (
                                                                          <DetailItem
                                                                            key={option.identifier}
                                                                            label={option.name}
                                                                            value={option.score}
                                                                            labelSize={5}
                                                                            valueSize={7}
                                                                          />
                                                                        ))
                                                                      ) : (
                                                                        (item.parameter.dropDownOptions).map((option) => (
                                                                          <DetailItem
                                                                            key={option.identifier}
                                                                            label={option.name}
                                                                            value={(option.passCriteria).toString()}
                                                                            labelSize={5}
                                                                            valueSize={7}
                                                                          />
                                                                        ))
                                                                      )}
                                                                    </div>
                                                                  )
                                                                case CreditPolicyValueType[CreditPolicyValueType.Range]:
                                                                  return (
                                                                    <div className="form-row">
                                                                      {item.parameter.creditPolicyUseCase === CreditPolicyUseCase[CreditPolicyUseCase.ScoringIndicator] ? (
                                                                        (item.parameter.rangeOptions).map((option) => (
                                                                          <DetailItem
                                                                            key={option.minimum + " - " + option.maximum}
                                                                            label={option.minimum + " - " + option.maximum + " " + item.valueTypeQuantifier}
                                                                            value={option.score}
                                                                            labelSize={5}
                                                                            valueSize={7}
                                                                          />
                                                                        ))
                                                                      ) : (
                                                                        (item.parameter.rangeOptions).map((option) => (
                                                                          <DetailItem
                                                                            key={option.minimum + " - " + option.maximum}
                                                                            label={option.minimum + " - " + option.maximum + " " + item.valueTypeQuantifier} value={(option.passCriteria).toString()}
                                                                            labelSize={5}
                                                                            valueSize={7}
                                                                          />
                                                                        ))
                                                                      )}
                                                                    </div>
                                                                  )
                                                                default:
                                                                  return null
                                                              }
                                                            })()}
                                                          </dl>
                                                        ) : ("")}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Card.Body>
                                              </Accordion.Collapse>
                                            </Accordion>
                                          )
                                        )}
                                      </React.Fragment>
                                    ) : (
                                      "No Credit Policy Parameter has been assigned to institution"
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <ModifyCreditPolicyParameters
                            showPatchModal={this.state.showSetCreditPolicyModal}
                            togglePatchModal={(toSave: boolean, payload: any) => {
                              this.handleOnIndicatorParamsSave(toSave, payload);
                            }}
                            item={parameterItem}
                          />
                          <hr />
                          <div className="card-body">
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                              disabled={this.state.submitting}
                              onClick={(e) => {
                                onHandleSubmit(
                                  e,
                                  ActionTypes.SAVE,
                                  "Loan Product Configuration",
                                  () => {
                                    this.onFormSubmit(
                                      {
                                        productName,
                                        productCode,
                                        productCategory,
                                        minimumAmount,
                                        maximumAmount,
                                        interestRate,
                                        tenure,
                                        moratorium,
                                        hasGuarantor,
                                        numberOfGuarantors,
                                        scoreCardId,
                                        feeName,
                                        feeType,
                                        processingFee,
                                        minimum_amount,
                                        maximum_amount,
                                        minimum_tenure,
                                        maximum_tenure,
                                        interest,
                                      },
                                      onReloadFields
                                    );
                                  }
                                );
                              }}
                            >
                              {this.state.submitting === false ? (
                                <React.Fragment>
                                  <i className="fas fa-lg fa-save mr-3" /> Save
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                  Please wait...
                                </React.Fragment>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                ></AddProductConfigurationForm>
              </div>
            </div>
          </div>
        </div >
      </React.Fragment >
    );
  }
}

export default AddProductConfiguration;
