import http from "../httpService";

const billingBaseAddress = process.env.REACT_APP_BILLING_BASEADDRESS;

export function retrieveInstitutionFeeConfigById(id: string) {
  return http.get(
    billingBaseAddress + `InstitutionFeeConfiguration/${id}`
  );
}

export function saveInstitutionFeeConfig(feeConfig) {
  return http.post(
    billingBaseAddress + `InstitutionFeeConfiguration`,
    feeConfig
  );
}

export function createTransactionFeeConfig(feeConfig) {
  return http.post(
    billingBaseAddress + `InstitutionFeeConfiguration/CreateTransactionFee`,
    feeConfig
  );
}

export function createSubscriptionFeeConfig(feeConfig) {
  return http.post(
    billingBaseAddress + `InstitutionFeeConfiguration/CreateSubscriptionFee`,
    feeConfig
  );
}

export function saveMandateInstitutionFeeConfig(feeConfig) {
  return http.post(
    billingBaseAddress + `InstitutionFeeConfiguration/Mandate/Create`,
    feeConfig
  );
}

export function updateInstitutionFeeConfig(id, feeConfig) {
  return http.put(
    billingBaseAddress + `InstitutionFeeConfiguration/configuration/Edit/${id}`,
    feeConfig
  );
}

export function updateMandateInstitutionFeeConfig(id, feeConfig) {
  return http.put(
    billingBaseAddress + `InstitutionFeeConfiguration/Mandate/Edit/${id}`,
    feeConfig
  );
}

export function updateTransactionFeeConfig(id, feeConfig) {
  return http.put(
    billingBaseAddress + `InstitutionFeeConfiguration/TransactionFee/Edit/${id}`,
    feeConfig
  );
}

export function updateSubscriptionFeeConfig(id, feeConfig) {
  return http.put(
    billingBaseAddress + `InstitutionFeeConfiguration/SubscriptionFee/Edit/${id}`,
    feeConfig
  );
}

export function filterInstitutionFeeConfig(serviceOffering: string, institutionCode: string, thirdParty: string) {
  return http.get(
    billingBaseAddress +
    `InstitutionFeeConfiguration/fees/filter?ServiceOffering=${serviceOffering}&InstitutionCode=${institutionCode}&ThirdParty=${thirdParty}`
  );
}

export function filterInstitutionTransactionFeeConfig(institutionCode: string) {
  return http.get(
    billingBaseAddress +
    `InstitutionFeeConfiguration/TransactionFee/FilterWithPaging?InstitutionCode=${institutionCode}`
  );
}
export function filterInstitutionSubscriptionFeeConfig(institutionCode: string) {
  return http.get(
    billingBaseAddress +
    `InstitutionFeeConfiguration/SubscriptionFee/FilterWithPaging?InstitutionCode=${institutionCode}`
  );
}

export default {
  retrieveInstitutionFeeConfigById,
  createTransactionFeeConfig,
  createSubscriptionFeeConfig,
  saveInstitutionFeeConfig,
  saveMandateInstitutionFeeConfig,
  updateInstitutionFeeConfig,
  updateMandateInstitutionFeeConfig,
  updateTransactionFeeConfig,
  updateSubscriptionFeeConfig,
  filterInstitutionFeeConfig,
  filterInstitutionTransactionFeeConfig,
  filterInstitutionSubscriptionFeeConfig
};
