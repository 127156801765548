import { decodeToken } from "react-jwt";
import { IFilterData } from "../approval/approvalItemService";
import { getCurrentUserSession } from "../auth/authService";
import http from "../httpService";

const appraisalBaseAddress = process.env.REACT_APP_HYBRIDAPPRAISAL_BASEADDRESS;

export function RetrieveGroupDisbursementReport(institutionCode) {
  return http.get(
    appraisalBaseAddress + `Report/RetrieveGroupDisbursementReport?institutionCode=${institutionCode}`
  );
}

export function filterGroupDisbursementReportReport(
  payload: IFilterData,
  maxSize: number,
  pageNum: number
) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  payload = { ...payload };
  var instCode = decodedToken?.["InstitutionCode"]?.toString() || "break";
  return http.post(
    appraisalBaseAddress +
    `Report/FilterGroupDisbursementReportReport?loanInstitution=${instCode}&pageSize=${maxSize}&pageNumber=${pageNum}`,
    payload
  );
}

export default {
  RetrieveGroupDisbursementReport,
  filterGroupDisbursementReportReport
};