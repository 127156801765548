import React from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import sweepConfigService from "../../../../services/sweep/sweepConfigService";
import institutionConfigService from "../../../../services/creditAssessment/institutionConfigService";
import activityTrailService from "../../../../services/auditTrail/activityTrailService";
import SettlementAccounts from "./settlementAccounts";
import sweepPrioritySerivice from "../../../../services/sweep/sweepPriorityService";
import { ISelectItems } from "../../../../components/detailItemSelectBox";
import SelectInputComponent from "../../../../components/formSelectComponent";

interface AddSettlementAccountProps {
    allInstitutions: any;
    allCommercialBanks: any;
    institution: any;
    toggleModal: any;
    showModal: boolean;
    saveSettlementAccount: any;
}

interface AddSettlementAccountState {
    data: {
        accountNo: string;
        daysAfterSettlement: string;
        settlementInstitutionCode: string;
        cbnCode: string;
    };
    submitting: boolean;
}

class AddSettlementAccount extends React.Component<AddSettlementAccountProps, AddSettlementAccountState> {
    constructor(props: AddSettlementAccountProps) {
        super(props);
        this.state = {
            data: {
                accountNo: "",
                daysAfterSettlement: "",
                settlementInstitutionCode: "",
                cbnCode: "",
            },
            submitting: false,
        };
    }

    validatePayload = (payload: any): boolean => {
        if (payload.accountNo.length > 10 || payload.accountNo.length < 10) {
            toast.warning(`Account Number must be 10 digits. `, {
                autoClose: 6000,
                type: toast.TYPE.WARNING,
                hideProgressBar: false,
            });
            return false;
        }
        if (payload.noOfDaysAfterFinalSettlement > 365) {
            toast.warning(`No of Days after settlement cannot be more than 365 days. `, {
                autoClose: 6000,
                type: toast.TYPE.WARNING,
                hideProgressBar: false,
            });
            return false;
        }
        return true;
    };

    handleOnSubmit = async () => {
        try {
            this.setState({ submitting: true });
            const data = this.state.data;
            let payload: any = {};
            payload.ofiInstitution = this.props.institution.code;
            payload.cbnCode =
                data.cbnCode === ""
                    ? this.props.allInstitutions.filter((item) => item.code === data.settlementInstitutionCode)[0].cbnCode
                    : data.cbnCode;
            payload.settlementInstitution = data.settlementInstitutionCode === "others" ? payload.cbnCode : data.settlementInstitutionCode;
            payload.accountNo = data.accountNo;
            payload.noOfDaysAfterFinalSettlement = data.daysAfterSettlement;
            if (this.validatePayload(payload)) {
                // console.log("payload: ", payload);
                const response = await sweepConfigService.saveSettlementAccount(payload);

                if (response.status >= 200 && response.status <= 300) {
                    await activityTrailService.saveActivityTrail({
                        actionType: "Add Settlement Account Configuration",
                        description: `Added new Settlement Account Configuration for ${this.props.institution.name}`,
                    });
                    toast.info(`Settlement Account configuration added successfully! `, {
                        autoClose: 6000,
                        type: toast.TYPE.DEFAULT,
                        hideProgressBar: false,
                    });
                    this.props.saveSettlementAccount(payload);
                    this.onHideModal();
                }
            }
        } catch (error) {
            this.setState({ submitting: false });
        }
    };

    onHideModal = () => {
        const data = {
            accountNo: "",
            daysAfterSettlement: "",
            settlementInstitutionCode: "",
            cbnCode: "",
        };
        this.setState({ submitting: false, data });
        this.props.toggleModal();
    };

    handleOnInputChange(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>): void {
        const data = { ...this.state.data };
        data[e.currentTarget.name] = e.currentTarget.value;
        this.setState({ data, submitting: false });
    }

    render() {
        const { showModal, allInstitutions, allCommercialBanks } = this.props;
        const { data, submitting } = this.state;

        return (
            <Modal size="lg" backdrop="static" show={showModal} onHide={() => this.onHideModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>
                            <i className="icon-box-filled2 mr-3"></i> Configure Settlement Accounts
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <br />
                    <div className="row clearfix">
                        <div className="col-md-12">
                            <div className="form-group form-float">
                                <label className="form-label">Account Number</label>
                                <input
                                    type="number"
                                    id="accountNo"
                                    min="10"
                                    max="10"
                                    name="accountNo"
                                    value={data.accountNo}
                                    onChange={(e) => this.handleOnInputChange(e)}
                                    className="form-control form-control-sm"
                                />
                            </div>
                            <div className="form-group form-float">
                                <label className="form-label">Days After Settlement</label>
                                <input
                                    type="number"
                                    id="daysAfterSettlement"
                                    max="365"
                                    name="daysAfterSettlement"
                                    value={data.daysAfterSettlement}
                                    onChange={(e) => this.handleOnInputChange(e)}
                                    className="form-control form-control-sm"
                                />
                            </div>
                            <div className="form-group form-float">
                                <label className="form-label">Settlement Institution</label>
                                <select
                                    className="browser-default custom-select"
                                    onChange={(e) => this.handleOnInputChange(e)}
                                    value={data.settlementInstitutionCode}
                                    name="settlementInstitutionCode"
                                    id="settlementInstitutionCode"
                                >
                                    <option value="">-Select Settlement Institution-</option>
                                    {allInstitutions
                                        .filter(
                                            (x) =>
                                                x.category === "CommercialBank" &&
                                                this.props.institution.settlementAccounts.map((a) => a.settlementInstitution).indexOf(x.code) < 0
                                        )
                                        .map((institution) => (
                                            <option key={institution.id} value={institution.code}>
                                                {institution.name}
                                            </option>
                                        ))}
                                    <option value="others">Others</option>
                                </select>
                            </div>

                            {data.settlementInstitutionCode === "others" ? (
                                <div className="form-group form-float">
                                    <label className="form-label">Other Commercial Banks</label>
                                    <select
                                        className="browser-default custom-select"
                                        onChange={(e) => this.handleOnInputChange(e)}
                                        value={data.cbnCode}
                                        name="cbnCode"
                                        id="cbnCode"
                                    >
                                        <option value="">-Select Commercial Bank-</option>
                                        {allCommercialBanks.map((bank) => (
                                            <option key={bank.bankCode} value={bank.bankCode}>
                                                {bank.bankName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    <br />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        size="sm"
                        variant="success"
                        disabled={submitting}
                        type="submit"
                        onClick={() => {
                            this.handleOnSubmit();
                        }}
                    >
                        {submitting === false ? (
                            <React.Fragment>
                                <i className="icon-paper-plane-o mr-3" />
                                Save
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <i className="fas fa-spinner fa-spin" />
                            </React.Fragment>
                        )}
                    </Button>

                    <Button size="sm" variant="outline-danger" onClick={() => this.onHideModal()}>
                        <i className="fas fa-times mr-3" />
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AddSettlementAccount;
