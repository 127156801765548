import React, {  } from "react";
import _ from "lodash";
import { getCurrentUserSession } from "../../../services/auth/authService";
import { decodeToken } from "react-jwt";
import EditCreditQualificationRuleAppzone from "./creditQualificationRuleComponents/appzoneEditCQR";
import EditCreditQualificationRuleOther from "./creditQualificationRuleComponents/otherInstitutionEditCQR";

interface IEditCreditQualificationRuleProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}
interface IEditCreditQualificationRuleState {}

class EditCreditQualificationRule extends React.Component<
  IEditCreditQualificationRuleProps,
  IEditCreditQualificationRuleState
> {
  constructor(props: IEditCreditQualificationRuleProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { item, showEditModal, toggleEditModal } = this.props;
    const decodedToken = decodeToken(getCurrentUserSession().token || "");

    return (
      <React.Fragment>
        {Number(decodedToken?.["InstitutionId"]) === 1 ? (
          <EditCreditQualificationRuleAppzone
            item={item}
            showEditModal={showEditModal}
            toggleEditModal={toggleEditModal}
          />
        ) : (
          <EditCreditQualificationRuleOther
            item={item}
            showEditModal={showEditModal}
            toggleEditModal={toggleEditModal}
          />
        )}
      </React.Fragment>
    );
  }
}

export default EditCreditQualificationRule;
