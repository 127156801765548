import React, { Fragment } from "react";
import CheckboxTree from "react-checkbox-tree";
import { toast } from "react-toastify";
import BaseFormComponent, {
  IBaseFormState,
} from "../../components/BaseFormComponent";
import FormInputComponent from "../../components/formInputComponent";
import SelectInputComponent, {
  ISelectItems,
} from "../../components/formSelectComponent";
import { ActionTypes } from "../../enums/actionTypes";
import authValidationService from "../../services/auth/authValidationService";
import serviceOfferingConfigService from "../../services/auth/serviceOfferingConfigService";
import _ from "lodash";
import activityTrailService from "../../services/auditTrail/activityTrailService";

interface AddServiceOfferingConfigFields {
  name: string;
  billableOffer: string;
  institutionCategory: string;
}

class AddServiceOfferingConfigForm extends BaseFormComponent<AddServiceOfferingConfigFields> { }

interface AddServiceOfferingConfigProps { }

interface AddServiceOfferingConfigState {
  institutionCategoryOptions: any;
  fetchingClientModules: boolean;
  fetchingDefaultModules: boolean;
  institutionCategory: string;
  availableModules: any;
  defaultChecked: number[];
  checked: any;
}

class AddServiceOfferingConfig extends React.Component<
  AddServiceOfferingConfigProps,
  IBaseFormState & AddServiceOfferingConfigState
> {
  constructor(props: AddServiceOfferingConfigProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      fetchingClientModules: false,
      fetchingDefaultModules: false,
      institutionCategory: "",
      availableModules: [],
      defaultChecked: [],
      checked: [],
      institutionCategoryOptions: [
        { name: "Commercial Bank", value: "CommercialBank" },
        { name: "BankOneOFI", value: "BankOne OFI" },
        { name: "OFI", value: "OFI" },
      ],
    };
  }

  async componentDidMount() {
    const { errors, submitting } = this.state;
    try {
      this.setState({ fetchingClientModules: true });
      const clientModules =
        await serviceOfferingConfigService.retrieveAllClientModules();
      let allModules: any = clientModules.data;
      let treeCollection: any = [];
      treeCollection = allModules.map((func) => {
        let newSet: any = {};
        newSet = {
          value: func.id,
          label: func.displayName,
        };
        return newSet;
      });
      this.setState({
        availableModules: treeCollection,
        fetchingClientModules: false,
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (ex.response && ex.response.status === 404) {
        this.setState({ submitting: !submitting });
        // console.log(ex.response);
        errors.response = "Internal server error.";
        // console.log(errors);
        this.setState({ errors });
      }
    } finally {
      this.setState({ fetchingClientModules: false });
    }
  }

  handleOnInstitutionCategoryChange = async (
    id,
    institutionCategory: string
  ) => {
    // console.log("fetching default modules: ");
    this.setState({ fetchingDefaultModules: true });
    var _defaultModules =
      await serviceOfferingConfigService.retrieveDefaultModulesByInstitutionCategory(
        institutionCategory
      );
    let defaultModules: any[] = _defaultModules.data;
    // console.log("defaultModules " + institutionCategory, defaultModules);
    let checked: number[] = this.state.checked;
    defaultModules.forEach((module) => {
      checked.push(module.userRoleFunctionId);
    });
    this.setState({
      fetchingDefaultModules: false,
      institutionCategory,
      defaultChecked: checked,
    });
  };

  onFormSubmit(
    fields: AddServiceOfferingConfigFields,
    onReloadFieldsCB: any
  ): boolean {
    try {
      this.setState(
        {
          errors: authValidationService.validateAddServiceOfferingForm(fields),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddServiceOfferingConfigFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true });
      let functions: number[] = [];
      this.state.checked.forEach((element) => {
        functions.push(Number(element));
      });
      // console.log("functions: ", functions);
      const response =
        await serviceOfferingConfigService.saveServiceOfferingConfig(
          fields.name,
          fields.billableOffer,
          this.state.institutionCategory,
          functions
        );
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Add Service Offering",
          description: `Added new Service Offering ${fields.name}`,
        });
        toast.info(`Service Offering ${fields.name} created successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
        this.setState({ checked: [] });
        onReloadFieldsCB();
      }
      this.setState({ submitting: false });
    } catch (error) {
      this.setState({ submitting: false });
      return false;
    }
  }

  render() {
    const {
      errors,
      validated,
      submitting,
      availableModules,
      defaultChecked,
      fetchingDefaultModules,
    } = this.state;

    let billableOfferInputData: ISelectItems[] = [
      { name: "Mandate & Sweep", value: "Mandate&Sweep" },
      { name: "OCM", value: "OCM" },
      { name: "Credit Assessment", value: "Credit Assessment" },
    ];

    let institutionCategoryInputData: ISelectItems[] = [
      { name: "Commercial Bank", value: "CommercialBank" },
      { name: "BankOne OFI", value: "BankOneOFI" },
      { name: "OFI", value: "OFI" },
    ];
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Service Offering Configuration</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddServiceOfferingConfigForm
                  initialValues={{
                    name: "",
                    billableOffer: "",
                    institutionCategory: institutionCategoryInputData[0].value,
                  }}
                  FormComponent={({
                    fields: { name, billableOffer, institutionCategory },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <h5 className="card-title">GENERAL INFORMATION</h5>
                          <div className="form-row">
                            <div className="col-md-6">
                              <FormInputComponent
                                id="name"
                                type="text"
                                name="name"
                                placeholder=""
                                value={name}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                              />
                              <br />
                              <SelectInputComponent
                                id="billableOffer"
                                name="billableOffer"
                                divClass={12}
                                value={billableOffer}
                                items={billableOfferInputData}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                              />
                              <br />
                              <SelectInputComponent
                                id="institutionCategory"
                                name="institutionCategory"
                                divClass={12}
                                value={this.state.institutionCategory}
                                items={institutionCategoryInputData}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={
                                  this.handleOnInstitutionCategoryChange
                                }
                              // onChangeCallBack={() => {
                              //   this.handleOnInstitutionCategoryChange(
                              //     institutionCategory
                              //   );
                              // }}
                              />
                              <br />
                            </div>
                            <div className="col-md-6">
                              <label className="col-form-label s-12">
                                Available Functions: ({institutionCategory})
                              </label>
                              {institutionCategory === "" ? (
                                <p className="text-red">
                                  Select an Institution Category
                                </p>
                              ) : fetchingDefaultModules ? (
                                <React.Fragment>
                                  <i className="fas fa-spin fa-circle-notch mr-3" />
                                </React.Fragment>
                              ) : (
                                <div className="form-row">
                                  <span className="col-sm-1 ml-2"></span>
                                  <CheckboxTree
                                    icons={{
                                      leaf: (
                                        <i className="icon-radio_button_checked blue-text"></i>
                                      ),
                                      halfCheck: (
                                        <i className="icon-square"></i>
                                      ),
                                      check: (
                                        <i className="icon-check_box green-text"></i>
                                      ),
                                    }}
                                    nodes={availableModules}
                                    checked={this.state.checked}
                                    onCheck={(checked) => {
                                      this.setState({
                                        checked: _.union(
                                          checked.map(Number),
                                          defaultChecked
                                        ),
                                      });
                                    }}
                                    iconsClass="fa5"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="card-body">
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                            disabled={submitting}
                            onClick={(e) => {
                              onHandleSubmit(
                                e,
                                ActionTypes.SAVE,
                                "Service Offering Configuration",
                                () => {
                                  this.onFormSubmit(
                                    {
                                      name,
                                      billableOffer,
                                      institutionCategory,
                                    },
                                    onReloadFields
                                  );
                                }
                              );
                            }}
                          >
                            {submitting === false ? (
                              <React.Fragment>
                                <i className="fas fa-lg fa-save mr-3" /> Save
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <i className="fas fa-spin fa-circle-notch" />
                              </React.Fragment>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                ></AddServiceOfferingConfigForm>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AddServiceOfferingConfig;
