import React, { Component, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../components/detailItem";
import { ActionTypes } from "../../enums/actionTypes";
import swal from "sweetalert";
import { toast } from "react-toastify";
import textFormatService from "../../services/utility/textFormatService";

export interface SweepActivitiesDetailProps {
    showDetailsModal: boolean;
    toggleDetailsModal: any;
    handleOnResetSweepEpisode: any;
    item: any;
}

export interface SweepActivitiesDetailState {}

class SweepActivitiesDetail extends Component<SweepActivitiesDetailProps, SweepActivitiesDetailState> {
    // state = {  }
    render() {
        const { showDetailsModal, toggleDetailsModal, handleOnResetSweepEpisode, item } = this.props;
        return (
            <Modal size="xl" backdrop="static" show={showDetailsModal} onHide={toggleDetailsModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {_.isEmpty(item) ? (
                            <Skeleton width={500} />
                        ) : (
                            <h5>
                                <i className="fas fa-receipt fa-sm s-18 mr-3" />
                                Sweep Activity Detail
                            </h5>
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card">
                        <div className="card-header clear-fix">
                            {_.isEmpty(item) ? (
                                <Skeleton width={200} />
                            ) : (
                                <h6 className="card-title float-left" style={{ marginBottom: "0px" }}>
                                    <b>General Information</b>
                                </h6>
                            )}
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-10">
                                    {_.isEmpty(item) ? (
                                        <Skeleton count={5} width={300} />
                                    ) : (
                                        <dl className="row">
                                            <DetailItem label="institutionName" value={item.institutionName} />
                                            <DetailItem label="Stage" value={item.nextStage} />
                                            {/* <DetailItem label="Unique Id" value={item.uniqueId} /> */}
                                            <DetailItem label="Sweep Date" value={item.currentSweepDate} />
                                            <DetailItem label="Start Time" value={item.startTime} />
                                            <DetailItem label="End Time" value={item.endTime} />
                                            <DetailItem label="Transaction Count" value={item.transactionCount} />
                                            <DetailItem label="Transaction Value" value={item.transactionValue} />
                                            <DetailItem label="Repayment Type" value={item.repaymentType} />
                                            <DetailItem label="Sweep Initiation Type" value={item.sweepInitiationType} />
                                            <DetailItem label="Amount" value={textFormatService.convertKoboToNaira(item.transactionValue, 2)} />
                                            {item.hasError && <DetailItem label="Sweep Error" value={item.sweepError} />}
                                        </dl>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {_.isEmpty(item) ? (
                        <Skeleton width={100} count={2} height={30} />
                    ) : (
                        <Fragment>
                            {item.hasError && (
                                <Button size="sm" variant="outline-primary" onClick={(e) => handleOnResetSweepEpisode(item)}>
                                    <i className="far fa-edit mr-3" />
                                    Retry
                                </Button>
                            )}
                        </Fragment>
                    )}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default SweepActivitiesDetail;
