import Joi from "joi-browser";
import _ from "lodash";

export function validateApprovalRuleConfigForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      userRoleId: Joi.string()
        .required()
        .error((errors) => {
          // console.log("userRoleId joi-error", errors);
          return {
            message: "UserRole Id is required.",
          };
        }),
      combinationNo: Joi.number()
        .required()
        .error((errors) => {
          // console.log("combinationNo joi-error", errors);
          return {
            message: "Combination Number is required.",
          };
        }),
      level: Joi.number()
        .required()
        .error((errors) => {
          // console.log("level joi-error", errors);
          return {
            message: "Level is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  // console.log("Errors: ", errors);
  return errors;
}

export function validateApprovalDetailForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      comment: Joi.string()
        .required()
        .error((errors) => {
          // console.log("comment joi-error ", errors);
          return {
            message: "Comment is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  // console.log("Errors: ", errors);
  return errors;
}

export function validateAddNewGroupForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      minimumAmount: Joi.string()
        .required()
        .error((errors) => {
          // console.log("comment joi-error ", errors);
          return {
            message: "Minimum Amount is required.",
          };
        }),
      maximumAmount: Joi.string()
        .required()
        .error((errors) => {
          // console.log("comment joi-error ", errors);
          return {
            message: "Maximum Amount is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  // console.log("Errors: ", errors);
  return errors;
}

export function validateModifyLoanItemForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      loanAmount: Joi.string()
        .required()
        .error((errors) => {
          // console.log("comment joi-error ", errors);
          return {
            message: "Loan Amount is required.",
          };
        }),
      moratorium: Joi.string()
        .required()
        .error((errors) => {
          // console.log("comment joi-error ", errors);
          return {
            message: "Moratorium is required.",
          };
        }),
      interestRate: Joi.string()
        .required()
        .error((errors) => {
          // console.log("comment joi-error ", errors);
          return {
            message: "Interest Rate is required.",
          };
        }),
      defaultingInterestRate: Joi.string()
        .required()
        .error((errors) => {
          // console.log("comment joi-error ", errors);
          return {
            message: "Defaulting Interest Rate is required.",
          };
        }),
      loanTenure: Joi.string()
        .required()
        .error((errors) => {
          // console.log("comment joi-error ", errors);
          return {
            message: "Loan Tenure is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  // console.log("Errors: ", errors);
  return errors;
}

export function validateAddSupportingDocumentForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      name: Joi.string()
        .required()
        .error((errors) => {
          // console.log("comment joi-error ", errors);
          return {
            message: "Document Name is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  // console.log("Errors: ", errors);
  return errors;
}

export default {
  validateApprovalRuleConfigForm,
  validateApprovalDetailForm,
  validateAddNewGroupForm,
  validateModifyLoanItemForm,
  validateAddSupportingDocumentForm
};
