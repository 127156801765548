import React, { Component, Fragment, useContext } from "react";
import {
    Button,
    Card,
    useAccordionToggle,
    AccordionContext,
    Accordion,
} from "react-bootstrap";
import _, { values } from "lodash";
import DetailItem from "../../../../components/detailItem";
import { toast } from "react-toastify";
import BaseFormComponent, { BaseFormComponentState, IBaseFormState } from "../../../../components/BaseFormComponent";
import { ActionTypes } from "../../../../enums/actionTypes";
import loanRequestService from "../../../../services/hybridAppraisal/loanRequestService";
import BaseListComponent, { IFilterData } from "../../../../components/BaseListComponent";
import { createFilterData, mapEntityList } from "../../../../services/utility/autoMapperService";
import { filterFieldData } from "../../../../components/tableComponents/itemsTable";
import appraisalActionService from "../../../../services/hybridAppraisal/appraisalActionService";
import OfferLetterDetail from "../../OfferLetter/offerLetterDetail";
import OfferLetterDetailScreen from "../../loanAppraisal/appraisalDetailPages/offerLetterDetailScreen";
import { decodeToken } from "react-jwt";
import institutionsService from "../../../../services/auth/institutionsService";
import offerLetterService from "../../../../services/hybridAppraisal/offerLetterService";
import FormTextAreaComponent from "../../../../components/formTextAreaComponent";
import { getCurrentUserSession } from "../../../../services/auth/authService";
import activityTrailService from "../../../../services/auditTrail/activityTrailService";
import RepaymentSchedule from "./wizardComponents/editDetailedDataEntry/repaymentSchedule";
import { printPDF } from "../../../../services/utility/textFormatService";
import Repayment from "../../loanAppraisal/appraisalDetailPages/wizardComponents/editDetailedDataEntry/repayment";

export function ContextAwareToggle({ eventKey, header }) {
    const currentEventKey = useContext(AccordionContext);
    const toggleOnClick = useAccordionToggle(eventKey);
    const isCurrentEventKey = currentEventKey === eventKey;
    const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
    return (
        <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
            <b>{header}</b>
            <i
                className={angleType}
                aria-hidden="true"
                style={{ float: "right" }}
            ></i>
        </Card.Header>
    );
}

interface offerLetterScreenFields {
    comment: string;
}

class OfferLetterScreenForm extends BaseFormComponent<offerLetterScreenFields> { }

interface offerLetterScreenProps {
    values: any;
}

interface offerLetterScreenState {
    item: any;
    updatingStatus: boolean;
    submitting: boolean;
    data: IFilterData;
    currentPage: number;
    tableItems: any;
    fetching: boolean;
    fetchingDetail: boolean;
    totalSize: number;
    filterValueInput: filterFieldData;
    showOfferLetterModal: boolean;
    showDetailsModal: boolean;
    loanRequest: any;
    ownUpdate: boolean;
    showRepaymentSchedule: boolean;
}

class ExternalCreditAssessmentList extends BaseListComponent<offerLetterScreenState> { }

class GroupOfferLetterScreen extends Component<offerLetterScreenProps, offerLetterScreenState & IBaseFormState> {
    constructor(props: offerLetterScreenProps) {
        super(props);
        this.state = {
            item: {},
            updatingStatus: false,
            submitting: false,
            data: createFilterData([]) as IFilterData,
            currentPage: 1,
            fetching: false,
            fetchingDetail: false,
            tableItems: [],
            totalSize: 0,
            filterValueInput: [],
            showOfferLetterModal: false,
            showDetailsModal: false,
            validated: false,
            errors: {},
            showRepaymentSchedule: false,
            loanRequest: {},
            ownUpdate: false
        };
    }

    static getDerivedStateFromProps(
        nextProps: offerLetterScreenProps,
        prevState: offerLetterScreenState
    ) {
        if (prevState.ownUpdate) {
            return {
                ownUpdate: false,
            };
        }
        if (_.isNull(nextProps.values)) {
            return null;
        }
        return {
            loanRequest: nextProps.values
        };
    }

    getActionTypeString = (actionType) => {
        switch (actionType) {
            case 2:
                return "Update";
            case 6:
                return "Approve";
            case 7:
                return "Decline";
            case 8:
                return "PushBack";
            case 9:
                return "Recall";
            default:
                return "";
        }
    };
    toggleShowRepaymentSchedule = () => {
        this.setState({ showRepaymentSchedule: !this.state.showRepaymentSchedule });
    };

    onFormSubmit(
        actionType,
    ): boolean {
        try {
            var action = this.getActionTypeString(actionType)
            this.setState(
                {
                    // errors: appraisalValidationService.validateAppraisalProcessDetailForm(fields),
                    ownUpdate: true
                },
                () => {
                    return this.submit(action);

                }
            );
            // this.setState({});
            return false;
        } catch (error) {
            return false;
        }
    }

    async submit(
        action
    ): Promise<boolean> {
        try {
            this.setState({ submitting: true, ownUpdate: true });
            const payload: any = {};
            var curDT = new Date().toLocaleString()

            payload.action = action;
            payload.loanRequestId = this.props.values.id
            payload.systemProcessID = Number();
            payload.itemLevel = this.props.values.currentLevel;
            payload.errorMessage = "";
            payload.logType = "SystemType";
            payload.comment = "";
            payload.effectiveStatus = "Valid";
            payload.id = Number(0);
            payload.processResponse = {
                "actionId": 0,
                "score": 0,
                "result": "string",
                "response": "string",
                "id": 0,
                "dateCreated": curDT,
                "dateUpdated": curDT
            };
            payload.dateCreated = curDT;
            payload.dateUpdated = curDT;

            // console.log("payload: ", payload);
            const response = await appraisalActionService.RunSystemProcess(payload);
            if (response.status >= 200 && response.status <= 300) {
                toast.info(`Validation Initiated successfully!`, {
                    autoClose: 6000,
                    type: toast.TYPE.DEFAULT,
                    hideProgressBar: false,
                });
            }
            this.setState({ submitting: false, ownUpdate: true });
        }
        catch (error) {
            if (error.response.status === 409) {
                toast.error(error.response.data.detail, {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            } else if (error.response.status === 400) {
                toast.error(error.response.data.errors[0], {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            }
        } finally {
            this.setState({ submitting: false, ownUpdate: true });
            return true;
        }
    }


    onFormSubmit2(fields: offerLetterScreenFields, onReloadFieldsCB: any, alt?): boolean {
        try {
            this.setState(
                { errors: [], ownUpdate: true },
                () => {
                    if (alt == "pushback") {
                        return this.submit2(fields, onReloadFieldsCB, alt);
                    }
                    if (Object.keys(this.state.errors).length === 0) {
                        return this.submit2(fields, onReloadFieldsCB);
                    }
                }
            );
            this.setState({ validated: true, ownUpdate: true });
            return false;
        } catch (error) {
            return false;
        }
    }

    async submit2(
        fields: offerLetterScreenFields,
        onReloadFieldsCB: any,
        alt?
    ): Promise<boolean> {
        try {
            this.setState({ submitting: true, ownUpdate: true });
            const { values } = this.props;

            if (alt == "pushback") {
                // console.log("progressing action...");
                const currentUserSession = getCurrentUserSession();
                var payload: any = {};
                payload.loanRequestId = values.id
                payload.action = "PushBack"
                payload.itemLevel = values.currentLevel
                payload.userID = currentUserSession.userId
                payload.roleID = currentUserSession.userRoleId
                payload.systemProcessID = 0
                payload.errorMessage = ""
                payload.logType = "UserType"
                payload.comment = fields.comment
                payload.effectiveStatus = "Valid"
                payload.processResponse = null
                payload.IsGroupLoan = true;
                // console.log("Payload: ", payload)
                const response = await appraisalActionService.createAppraisalAction(payload)
                if (response.status >= 200 && response.status <= 300) {
                    // toggleAppraisalProcess()
                    activityTrailService.saveActivityTrail({
                        actionType: "Add Appraisal Action",
                        description: `Added new Appraisal Action`,
                    });
                    toast.info(`Loan Request Pushed Back to Data Entry!`, {
                        autoClose: 6000,
                        type: toast.TYPE.INFO,
                        hideProgressBar: false,
                    });
                } else {
                    return false;
                }
            } else {
                // console.log("progressing action...");
                const currentUserSession = getCurrentUserSession();
                var payload: any = {};
                payload.loanRequestId = values.id
                payload.action = "Approve"
                payload.itemLevel = values.currentLevel
                payload.userID = currentUserSession.userId
                payload.roleID = currentUserSession.userRoleId
                payload.systemProcessID = 0
                payload.errorMessage = ""
                payload.logType = "UserType"
                payload.comment = fields.comment == "" ? "Done with offer letter generation" : fields.comment
                payload.effectiveStatus = "Valid"
                payload.processResponse = null
                payload.IsGroupLoan = true;
                // console.log("Payload: ", payload)
                const response = await appraisalActionService.createAppraisalAction(payload)
                if (response.status >= 200 && response.status <= 300) {
                    // toggleAppraisalProcess()
                    activityTrailService.saveActivityTrail({
                        actionType: "Add Appraisal Action",
                        description: `Added new Appraisal Action`,
                    });
                    toast.info(`Loan Request for ${values.customerName} has moved to Post Appraisal Document Approval!`, {
                        autoClose: 6000,
                        type: toast.TYPE.INFO,
                        hideProgressBar: false,
                    });
                } else {
                    return false;
                }
            }
            this.setState({ submitting: false, ownUpdate: true });
            //   this.resetFields();
            // onReloadFieldsCB();
        } catch (error) {
            if (error.response.status === 409) {
                toast.error(error.response.data.detail, {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            } else if (error.response.status === 400) {
                toast.error(error.response.data.errors[0], {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            }
            this.setState({ submitting: false, ownUpdate: true });
            return false;
        } finally {
            return true;
        }
    }

    toggleshowOfferLetterModal = async () => {
        const decodedToken = decodeToken(getCurrentUserSession().token || "");
        let payload: any = {};
        payload = this.props.values;
        // payload.guarantors = null
        // payload.collaterals = null
        // payload.assets = null
        // payload.notepads = null
        // payload.stakeholders = null
        // payload.incomeDetails = null
        // payload.loanDetails = null
        // payload.specialConditions = null
        // payload.documents = null

        // payload.institutionId = decodedToken?.["InstitutionId"];
        let institutionName = (
            await institutionsService.retrieveInstitutionById(
                decodedToken?.["InstitutionId"]
            )
        ).data.name;
        // console.log(institutionName);
        // console.log(payload);
        const response = await offerLetterService.GenerateOfferLetter(institutionName, payload.id, true);
        printPDF(response.data);
        // console.log(response.data);

        // this.setState({ showOfferLetterModal: !this.state.showOfferLetterModal, item: response.data, ownUpdate: true });
    };

    toggleshowGuarantorLetterModal = async () => {
        const decodedToken = decodeToken(getCurrentUserSession().token || "");
        let payload: any = {};
        payload = this.props.values;
        // payload.guarantors = null
        // payload.collaterals = null
        // payload.assets = null
        // payload.notepads = null
        // payload.stakeholders = null
        // payload.incomeDetails = null
        // payload.loanDetails = null
        // payload.specialConditions = null
        // payload.documents = null
        // console.log(institutionName);
        // console.log(payload);
        const response = await offerLetterService.GenerateGuarantorLetter(payload);
        printPDF(response.data);
        // console.log(response.data);

        this.setState({ showOfferLetterModal: !this.state.showOfferLetterModal, item: response.data, ownUpdate: true });
    };

    toggleshowApprovedMembersListModal = async () => {
        const decodedToken = decodeToken(getCurrentUserSession().token || "");
        let payload: any = {};
        payload = this.props.values;
        // payload.guarantors = null
        // payload.collaterals = null
        // payload.assets = null
        // payload.notepads = null
        // payload.stakeholders = null
        // payload.incomeDetails = null
        // payload.loanDetails = null
        // payload.specialConditions = null
        // payload.documents = null
        // console.log(institutionName);
        // console.log(payload);
        const response = await offerLetterService.GenerateApprovedMembersList(payload.id);
        printPDF(response.data);
        // console.log(response.data);

        this.setState({ showOfferLetterModal: !this.state.showOfferLetterModal, item: response.data, ownUpdate: true });
    };

    onToggleDocumentScreenModal = () => {
        this.setState({ showOfferLetterModal: !this.state.showOfferLetterModal, ownUpdate: true });
    };


    getFilteredItems = (data: IFilterData): IFilterData => {
        let filteredItems: IFilterData = {};
        Object.keys(data).forEach((key) => {
            if (data[key].item2 !== "") {
                filteredItems[key] = data[key];
            }
        });
        return filteredItems;
    };

    render() {
        const {
            values,
        } = this.props;
        const { showOfferLetterModal, showDetailsModal, item } = this.state

        return (
            <><div className="container-fluid relative animatedParent animateOnce">
                <div className="animated fadeInUpShort go">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-row">
                                <div className="col-md-12">
                                    <div className="row my-3 mx-2">
                                        <h3>
                                            <b>Document Generation</b>
                                        </h3>
                                    </div>
                                    {/* {console.log(item)} */}
                                    <div className="card">
                                        <Accordion defaultActiveKey="0">
                                            <div>
                                                <Card>
                                                    <ContextAwareToggle
                                                        eventKey={`0`}
                                                        header={<React.Fragment>
                                                            <i
                                                                className="fa fa-info-circle"
                                                                aria-hidden="true"
                                                                style={{ color: "#878a8a" }}
                                                            ></i>{" "}
                                                            Document Generation
                                                        </React.Fragment>}
                                                    ></ContextAwareToggle>
                                                    <Accordion.Collapse eventKey={`0`}>
                                                        <Card.Body>
                                                            <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                                <div className="row">
                                                                    <div className="col-md-10">
                                                                        <dl className="row">

                                                                            <React.Fragment>
                                                                                <Button
                                                                                    className="btn btn-sm btn-outline-primary my-2 mx-2"
                                                                                    style={{ cursor: "pointer" }}
                                                                                    type="button"
                                                                                    onClick={() => this.toggleshowOfferLetterModal()}
                                                                                >
                                                                                    <React.Fragment>
                                                                                        <i className="fas fa-receipt fa-lg mr-2" />{" "}
                                                                                        Generate Offer Letter
                                                                                    </React.Fragment>
                                                                                </Button>
                                                                                <Button
                                                                                    className="btn btn-sm btn-outline-primary my-2 mx-2"
                                                                                    style={{ cursor: "pointer" }}
                                                                                    type="button"
                                                                                    onClick={() => this.toggleshowApprovedMembersListModal()}
                                                                                >
                                                                                    <React.Fragment>
                                                                                        <i className="fas fa-receipt fa-lg mr-2" />{" "}
                                                                                        Generate Approved Members List
                                                                                    </React.Fragment>
                                                                                </Button>
                                                                                {/* <Button
                                                                                    className="btn btn-sm btn-outline-primary my-2 mx-2"
                                                                                    style={{ cursor: "pointer" }}
                                                                                    type="button"
                                                                                    onClick={(ActionTypes.APPROVE, () => {
                                                                                        this.onFormSubmit(
                                                                                            ActionTypes.APPROVE
                                                                                        );
                                                                                    }
                                                                                    )}
                                                                                >
                                                                                    <React.Fragment>
                                                                                        <i className="fas fa-receipt fa-lg mr-2" />{" "}
                                                                                        Generate Facility Approval Memorandum
                                                                                    </React.Fragment>
                                                                                </Button> */}
                                                                                <Button
                                                                                    className="btn btn-sm btn-outline-primary my-2 mx-2"
                                                                                    style={{ cursor: "pointer" }}
                                                                                    type="button"
                                                                                    onClick={() => { this.toggleShowRepaymentSchedule() }}
                                                                                >
                                                                                    <React.Fragment>
                                                                                        <i className="fas fa-receipt fa-lg mr-2" />{" "}
                                                                                        View Repayment Schedule
                                                                                    </React.Fragment>
                                                                                </Button>
                                                                            </React.Fragment>

                                                                        </dl>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </div>

                                        </Accordion>
                                    </div>

                                    {/* <OfferLetterScreenForm
                                        initialValues={{
                                            comment: ""
                                        }}
                                        FormComponent={({
                                            fields: { comment },
                                            onChange,
                                            onReloadFields,
                                            onHandleSubmit,
                                        }) => (
                                            <form action="#">
                                                <div className="card no-b">
                                                    <div className="card-body">
                                                        <dl className="row">
                                                            <FormTextAreaComponent
                                                                id="comment"
                                                                type="text"
                                                                name="comment"
                                                                placeholder=""
                                                                divClass={12}
                                                                value={comment}
                                                                required={false}
                                                                validated={true}
                                                                errors={{}}
                                                                onChange={onChange}
                                                                disabled={false} />
                                                        </dl>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-sm btn-secondary"
                                                            disabled={this.state.submitting}
                                                            onClick={(e) => {
                                                                onHandleSubmit(
                                                                    e,
                                                                    ActionTypes.PUSHBACK,
                                                                    "Loan Application",
                                                                    () => {
                                                                        this.onFormSubmit2({ comment }, onReloadFields, "pushback");
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            {this.state.submitting === false ? (
                                                                <React.Fragment>
                                                                    Refer Back
                                                                </React.Fragment>
                                                            ) : (
                                                                <React.Fragment>
                                                                    <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                                                    Please wait...
                                                                </React.Fragment>
                                                            )}
                                                        </button>
                                                        <div className="float-right">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-sm btn-primary"
                                                                disabled={this.state.submitting}
                                                                onClick={(e) => {
                                                                    onHandleSubmit(
                                                                        e,
                                                                        ActionTypes.SAVE,
                                                                        "Loan Application",
                                                                        () => {
                                                                            this.onFormSubmit2({ comment }, onReloadFields);
                                                                        }
                                                                    );
                                                                }}
                                                            >
                                                                {this.state.submitting === false ? (
                                                                    <React.Fragment>
                                                                        <i className="fas fa-lg fa-save mr-3" /> Save & Proceed
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <React.Fragment>
                                                                        <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                                                        Please wait...
                                                                    </React.Fragment>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        )}
                                    ></OfferLetterScreenForm> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><Fragment>
                    <OfferLetterDetailScreen
                        showDetailsModal={showOfferLetterModal}
                        toggleDocumentScreenModal={() => { this.onToggleDocumentScreenModal() }}
                        item={item} />
                    {/* <RepaymentSchedule
                        item={(JSON.parse(JSON.parse(values.jsonData)))}
                        showPatchModal={this.state.showRepaymentSchedule}
                        togglePatchModal={this.toggleShowRepaymentSchedule}
                    /> */}
                    <Repayment
                        item={values}
                        showPatchModal={this.state.showRepaymentSchedule}
                        togglePatchModal={this.toggleShowRepaymentSchedule}
                        values={values.loanDetails}
                    />
                </Fragment></>

        );
    }
}

export default GroupOfferLetterScreen;
