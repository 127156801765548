import React, { Component } from "react";
import recova_404 from "../images/404.png";
import "./notFound.css";
import { Link } from "react-router-dom";

class NotFound extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div
          className="container-fluid"
          style={{ height: "100vh", paddingRight: "0px", paddingLeft: "0px" }}
        >
          <div className="center-screen">
            <h1 className="recova-blue" style={{ fontSize: "50px" }}>
              Page Not Found
            </h1>
            <img src={recova_404} alt="" className="image" />
            <h2 className="recova-blue">
              We are working on resolving this issue
            </h2>
            <h2 className="recova-blue">
              For now, let us take you somewhere we know is safe
            </h2>
            <b>
              <Link to="/home">
                <i className="fas fa-home mr-2" />
                Home
              </Link>
            </b>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NotFound;
