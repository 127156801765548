import React, { CSSProperties, useContext, useEffect, useState } from "react";
import corporateAnalysisService from "../../../../../../../../services/hybridAppraisal/corporateAnalysisService";
import { formatCurrency } from "../../../../../../../../services/utility/textFormatService";
import _ from "lodash";
import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionToggle,
} from "react-bootstrap";
export const CogsOutput = ({ loan }: any) => {
  const [cogsOutput, setCogsOutput] = useState<any>();

  useEffect(() => {
    const getCOGSOutput = async () => {
      const response = await corporateAnalysisService.retrieveCogsRecordsOutput(
        {
          loanRequestId: loan?.id,
        }
      );
      if (response?.status === 200) {
        setCogsOutput(response?.data);
      }
    };
    getCOGSOutput();
  }, [loan]);

  return (
    <div>
      <Container
        title="COGS"
        body={
          <div className=" form-row col-md-12">
            <div
              className="table-responsive text-nowrap"
              style={{
                overflowY: "scroll",
                border: "0.5px solid #ccc",
              }}
            >
              {!_.isEmpty(cogsOutput) ? (
                <table className="table table-hover table-content table-sm table-striped mb-0">
                  <thead>
                    <tr>
                      {tHead(
                        [
                          "#",
                          "Month",
                          "Total Sales",
                          "Gross Profit",
                          "Average Profit Margin",
                          "COGS",
                          "Markup",
                        ],
                        [
                          { textAlign: "left" },
                          { textAlign: "left" },
                          { textAlign: "right" },
                          { textAlign: "right" },
                          { textAlign: "right", marginLeft: -20 },
                          { textAlign: "right", marginRight: 10 },
                          { textAlign: "right" },
                        ]
                      )}
                    </tr>
                  </thead>

                  <tbody>
                    {cogsOutput?.map((item, index) => (
                      <tr key={index}>
                        {tData(
                          [
                            index + 1,
                            item?.month,
                            formatCurrency(item?.totalSales, 2),
                            formatCurrency(item?.grossProfit, 2),
                            formatCurrency(item?.averageProfitMargin, 2) + "%",
                            formatCurrency(item?.cogs, 2),
                            formatCurrency(item?.markUp, 2) + "%",
                          ],
                          [
                            { textAlign: "left" },
                            { textAlign: "left" },
                            { textAlign: "right" },
                            { textAlign: "right" },
                            { textAlign: "right", marginLeft: -20 },
                            { textAlign: "right", marginRight: 10 },
                            { textAlign: "right" },
                          ]
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    color: "red",
                  }}
                  className="py-2"
                >
                  <i>No COGS Output available</i>
                </div>
              )}
            </div>
          </div>
        }
      />
    </div>
  );
};

const tHead = (texts: (string | number)[], style?: CSSProperties[]) => (
  <>
    {texts?.map((text: any, index: number) => (
      <th
        key={text}
        scope="col"
        style={{
          ...style[index],
          paddingTop: "0.2rem",
          paddingBottom: "0.2rem",
        }}
      >
        <strong>{text}</strong>
      </th>
    ))}
  </>
);
const tData = (texts: (string | number)[], style?: CSSProperties[]) => (
  <>
    {texts?.map((text: any, index: number) => (
      <td
        style={{
          ...style[index],
          paddingTop: "0.2rem",
          paddingBottom: "0.2rem",
          fontSize: "small",
        }}
      >
        {text}
      </td>
    ))}
  </>
);

const ContextAwareToggle = ({ eventKey, header }) => {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      <b>{header}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
};

const Container = ({
  title,
  body,
}: {
  title: string;
  body: React.ReactNode;
}) => {
  return (
    <div className="card">
      <Accordion defaultActiveKey="0">
        <div>
          <Card>
            <ContextAwareToggle
              eventKey={`0`}
              header={
                <React.Fragment>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    style={{ color: "#878a8a" }}
                  ></i>{" "}
                  {title}
                </React.Fragment>
              }
            ></ContextAwareToggle>
            <Accordion.Collapse eventKey={`0`}>
              <Card.Body>
                <div
                  style={{
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  <div className="row">{body}</div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </div>
      </Accordion>
    </div>
  );
};
