import http from "../httpService";

const bvnBaseAddress = process.env.REACT_APP_BVN_BASEADDRESS;

// export function retrieveBVNLinkingReport(pageNumber: number, pageSize: number) {
//   return http.get(
//     bvnBaseAddress +
//       `Report/RetrieveBVNLinkingReport?pageNumber=${pageNumber}&pageSize=${pageSize}`
//   );
// }

export function retrieveBvnReport(pageNumber: number, pageSize: number) {
  return http.get(bvnBaseAddress + `Report/RetrieveBvnReport?pageNumber=${pageNumber}&pageSize=${pageSize}`)
}

export function retrieveBvnReportByInstitutionId(institutionId: number) {
  return http.get(bvnBaseAddress + `Report/RetrieveBvnReportByInstitutionId/${institutionId}`);
}

export function retrieveCustomersBvnReport(institutionId: number, pageNumber: number, pageSize: number) {
  return http.get(bvnBaseAddress + `Report/RetrieveCustomersBvnReport/${institutionId}?pageNumber=${pageNumber}&pageSize=${pageSize}`)
}

export function retrieveCustomersBvnReportByBVN(bvn: String) {
  return http.get(bvnBaseAddress + `Report/RetrieveCustomersBvnReportByBVN/${bvn}`);
}

export function retrieveIndividualBVNGreyListReport(
  institutionId: number,
  pageNumber: number,
  pageSize: number
) {
  return http.get(
    bvnBaseAddress +
    `Report/RetrieveIndividualBVNGreyListReport?institutionId=${institutionId}&pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
}

export function retrieveIndividualBVNGreyListReportItem(
  institutionId: number,
  bvn: String
) {
  return http.get(
    bvnBaseAddress +
    `Report/RetrieveIndividualBVNGreyListReportItem?institutionId=${institutionId}&bvn=${bvn}`
  );
}

export function downloadIndividualGreyListReport(institutionId, type: String) {
  if (type.toLowerCase() === "pdf") {
    return http.get(bvnBaseAddress + `Report/DownloadIndividualBVNGreyListReportPDF/${institutionId}`)
  } else {
    var mywindow = window.open(
      bvnBaseAddress + `Report/DownloadIndividualBVNGreyListReport/${institutionId}?type=${type}`,
      "PRINT",
      "height=500,width=900"
    );
    mywindow?.focus();
  }
}

// export function retrieveInstitutionsGreyListReport(
//   pageNumber: number,
//   pageSize: number
// ) {
//   return http.get(
//     bvnBaseAddress +
//       `Report/RetrieveInstitutionsGreyListReport?pageNumber=${pageNumber}&pageSize=${pageSize}`
//   );
// }

export default {
  // retrieveBVNLinkingReport,
  retrieveBvnReport,
  retrieveBvnReportByInstitutionId,
  retrieveCustomersBvnReport,
  retrieveIndividualBVNGreyListReport,
  retrieveIndividualBVNGreyListReportItem,
  retrieveCustomersBvnReportByBVN,
  // retrieveInstitutionsGreyListReport,
  downloadIndividualGreyListReport
};
