import classNames from "classnames";
import React, { useState } from "react";

export const ReactWizardCustom = ({
  style,
  steps,
  previousButtonClasses,
  nextButtonClasses,
  finishButtonClasses,
  nextButtonText = "Next",
  previousButtonText = "Previous",
  finishButtonText = "Finish",
  finishButtonClick,
}: Props) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const currentStep = steps[currentStepIndex];

  const goToNextStep = (e) => {
    e.preventDefault();
    if (currentStepIndex < steps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    } else if (finishButtonClick) {
      finishButtonClick();
    }
  };

  const goToPreviousStep = (e) => {
    e.preventDefault();
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  const CurrentComponent = currentStep?.component;
  const stepProps = currentStep?.stepProps;

  return (
    <div className="react-wizard-container" style={{ ...style }}>
      <div className="navigation-body react-wizard-nav">
        {steps.map(({ stepName }, index) => (
          <div
            key={stepName}
            onClick={() => setCurrentStepIndex(index)}
            className={classNames(
              "navigation",
              currentStepIndex === index && "nav-active"
            )}
          >
            {stepName}
          </div>
        ))}
      </div>

      <div className="react-wizard-body">
        {CurrentComponent ? <CurrentComponent {...stepProps} /> : null}
      </div>

      <div className="react-wizard-footer">
        {currentStepIndex > 0 ? (
          <div>
            <button
                onClick={goToPreviousStep}
                className={classNames(
                previousButtonClasses,
                "btn btn-md btn-primary"
                )}
                disabled={currentStepIndex === 0}
            >
                {previousButtonText}
            </button>
          </div>
        ) : (
          <div />
        )}
        {currentStepIndex < steps.length - 1 ? (
          <button
            onClick={goToNextStep}
            className={classNames(nextButtonClasses, "btn btn-md btn-primary")}
          >
            {nextButtonText}
          </button>
        ) : (
          <button
            onClick={finishButtonClick}
            className={classNames(
              finishButtonClasses,
              "btn btn-md btn-primary"
            )}
          >
            {finishButtonText}
          </button>
        )}
      </div>
    </div>
  );
};

type Props = {
  className?: string;
  previousButtonClasses?: string;
  nextButtonClasses?: string;
  finishButtonClasses?: string;
  nextButtonText?: string;
  previousButtonText?: string;
  finishButtonText?: string;
  style?: React.CSSProperties;
  steps: Step[];
  validate?: boolean;
  navSteps?: boolean;
  progressbar?: boolean;
  headerTextCenter?: boolean;
  color?: string;
  finishButtonClick?: () => void;
};

type Step = {
  stepName: string;
  component: React.ComponentType<any>;
  stepProps?: {
    values: any;
    reloadItem?: any;
    mandatoryFields?: any;
  };
};
