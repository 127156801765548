import * as React from "react";
import { ISelectItems } from "../../../components/inputComponents/selectBox";
import _ from "lodash";
import SelectInputComponent from "../../../components/formSelectComponent";
import { toast } from "react-toastify";
import { IBaseFormState } from "../../../components/BaseFormComponent";
import DetailItem from "../../../components/detailItem";
import textFormatService from "../../../services/utility/textFormatService";
import collectionScheduleService from "../../../services/collection/collectionScheduleService";
import { getCurrentUserSession } from "../../../services/auth/authService";
import { decodeToken } from "react-jwt";
import swal from "sweetalert";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

export interface IPauseCollectionScheduleProps { }
export interface IPauseCollectionScheduleState {
  pauseType: "Instant" | "Scheduled";
  collectionSchedules: any;
  fetching: boolean;
  selectedAll: boolean;
  startDate: string;
  endDate: string;
  currentPage: number;
  tableItems: any;
  totalSize: number;
}

class PauseCollectionSchedule extends React.Component<IPauseCollectionScheduleProps, IBaseFormState & IPauseCollectionScheduleState> {
  constructor(props: IPauseCollectionScheduleProps) {
    super(props);
    this.state = {
      collectionSchedules: [],
      submitting: false,
      pauseType: "Instant",
      selectedAll: false,
      fetching: true,
      startDate: "",
      endDate: "",
      currentPage: 1,
      tableItems: [],
      totalSize: 0,
      errors: [],
      validated: false
    };
  }

  async componentDidMount() {
    try {
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1; //As January is 0.
      let yyyy = today.getFullYear();
      let todayString: string = `${yyyy}-`;
      todayString += mm < 10 ? `0${mm}-` : `${mm}-`;
      todayString += dd < 10 ? `0${dd}` : `${dd}`;

      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const collectionSchedules = await collectionScheduleService.retrieveCollectionSchedulesByInstitutionCode(decodedToken?.["InstitutionCode"]?.toString() || "");
      const activeCollSchedules = collectionSchedules.data.filter(schedule => schedule.collectionsSuspended == false);
      activeCollSchedules.forEach((schedule) => {
        schedule.isSelected = false;
      });
      this.setState({ collectionSchedules: activeCollSchedules, fetching: false, startDate: todayString, endDate: todayString }, () => {
        this.handlePageChange(this.state.currentPage);
      });
    } catch (error) {
      toast.info("Something went wrong.", {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      toast.info(error.response.data.errors[0], {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
    } finally {
      this.setState({ fetching: false });
    }
  }

  handlePageChange = async (currentPage: number) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;

    const cSchedules: any = [];
    const totalLength = this.state.collectionSchedules.length;
    const initial = (currentPage - 1) * 10;
    for (let i = initial; i < initial + 10; i++) {
      if (i === totalLength) {
        break;
      }
      cSchedules.push(this.state.collectionSchedules[i]);
    }
    // console.log("cSchedules:: ",cSchedules)
    this.setState({
      tableItems: cSchedules,
      currentPage,
      // totalSize: cSchedules.data.item1,
      totalSize: totalLength,
      fetching: false
    });
  };

  onHandleSubmit = async (e) => {
    e.preventDefault();
    swal({
      title: "Confirm!",
      text: `Are you sure you want to proceed to Pause the selected Collection Schedules?`,
      icon: "warning",
      buttons: {
        confirm: {
          text: "Yes",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: "Cancel",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      closeOnClickOutside: false,
      dangerMode: true,
    }).then(async (resp) => {
      if (resp) {
        // console.log("cancelling action...");
      } else {
        this.onFormSubmit();
      }
    });
  };

  onFormSubmit(): boolean {
    try {
      // console.log("Fields for submitting:: ", fields)
      this.setState(
        {
          errors: []//collectionValidationService.validatePauseCollectionScheduleForm(fields),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            // console.log("passed Validation")
            return this.submit();
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(): Promise<boolean> {
    if (this.state.collectionSchedules.filter((x) => x.isSelected === true).length === 0) {
      toast.info("It looks like you haven't selected any Collection Schedule!", {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      return false;
    } else {
      try {
        if (this.state.pauseType == "Scheduled") {
          let today = new Date();
          today.setDate(today.getDate() - 1);
          let start = new Date(this.state.startDate);
          let end = new Date(this.state.endDate);
          if (start.getTime() < today.getTime()) {
            toast.info("Start date is invalid!", {
              autoClose: 6000,
              type: toast.TYPE.ERROR,
              hideProgressBar: false,
            });
            return false;
          } else if (end.getTime() < start.getTime()) {
            toast.info("End date is less than start date!", {
              autoClose: 6000,
              type: toast.TYPE.ERROR,
              hideProgressBar: false,
            });
            return false;
          }
          // console.log("today ::", today)
          // console.log("today getting ::", today.getTime())
          // console.log("start ::", start)
          // console.log("end ::", end)
        }
        // console.log("starting submission...");
        this.setState({ submitting: true });
        const collectionsPayLoad = this.state.collectionSchedules.filter(
          (x) => x.isSelected === true
        );
        const payload: any = {};
        payload.mandates = collectionsPayLoad.map((collection) => collection.mandateReference);
        if (this.state.pauseType == "Scheduled") {
          payload.startDate = this.state.startDate;
          payload.endDate = this.state.endDate;
        }
        // console.log("Payload ::", payload)
        let response = await collectionScheduleService.suspendCollections(payload);
        if (response.status >= 200 && response.status <= 300) {
          await activityTrailService.saveActivityTrail({
            actionType: "Pause Collection Schedule",
            description: `Pause Collection Schedule`,
          });
          toast.info(`Request to pause Collection Schedules submitted successfully! `, {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          });
        } else {
          return false;
        }
        this.setState({ submitting: false });
        this.resetFields();
        // onReloadFieldsCB();
      } catch (error) {
        if (error.response.status === 409) {
          // console.log("error: ", error.response);
          toast.info(error.response.data.detail, {
            autoClose: 6000,
            type: toast.TYPE.ERROR,
            hideProgressBar: false,
          });
        } else if (error.response.status === 400) {
          // console.log("error: ", error.response);
          toast.info(error.response.errors[0], {
            autoClose: 6000,
            type: toast.TYPE.ERROR,
            hideProgressBar: false,
          });
        } else {
          // console.log("error: ", error.response);
          // return false;
        }
        // console.log("testing log:: ", response);
        // console.log("error log:: ", error);
        // console.log("error: ", error.response.status);
        // console.log("error: ", error.response);
        this.setState({ submitting: false });
        return false;
      } finally {
        return true;
      }
    }
  }

  resetFields() {
    this.setState({ validated: false, submitting: false });
  }

  handleOnSelectSuspensionType = async (id, value) => {
    if (value !== "") {
      this.setState({ pauseType: value });
    }
  };

  handleOnSelectAll = () => {
    const { selectedAll, collectionSchedules } = this.state;
    collectionSchedules.forEach((coll) => {
      coll.isSelected = !selectedAll;
    });
    this.setState({ selectedAll: !selectedAll, collectionSchedules });
  };

  handleOnSelectCollectionSchedule = (id: string, isSelected: boolean) => {
    // console.log("id ::", id)
    const { collectionSchedules } = this.state;
    const check = collectionSchedules.filter((x) => x.id == id)[0];
    const index = collectionSchedules.indexOf(check);
    check.isSelected = !isSelected;
    collectionSchedules[index] = check;
    if (collectionSchedules.filter((x) => x.isSelected === false).length === 0) {
      this.setState({ selectedAll: true, collectionSchedules });
    } else {
      this.setState({ selectedAll: false, collectionSchedules });
    }
  };

  handleOnDateInputChange = (
    id: string,
    value: any,
  ) => {
    if (value !== "") {
      if (id === "startDate") {
        this.setState({ startDate: value });
      } else {
        this.setState({ endDate: value });
      }
    }
  };

  render() {
    const {
      errors,
      collectionSchedules,
      fetching,
      validated,
      submitting,
      pauseType,
      endDate,
      startDate,
      selectedAll,
      currentPage,
      tableItems,
      totalSize
    } = this.state;
    const pageSize = 10;

    let pauseTypeInputData: ISelectItems[] = [
      { name: "Instant", value: "Instant" },
      { name: "Scheduled", value: "Scheduled" },
    ];

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Pause Collection Schedules</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <form action="#">
                  <div className="form-row">
                    <SelectInputComponent
                      id="pauseType"
                      name="pauseType"
                      divClass={4}
                      value={pauseType}
                      items={pauseTypeInputData}
                      required={false}
                      validated={false}
                      errors={[]}
                      onChange={this.handleOnSelectSuspensionType}
                    />
                  </div>
                  <br />
                  <div className="card no-b">
                    <div className="card-body">
                      {(pauseType === "Scheduled") && (
                        <React.Fragment>
                          <div className="row my-3 mx-2">
                            <h5>
                              Time Frame
                            </h5>
                          </div>
                          <form action="#">
                            <div className="form-row">
                              <div className="col-md-12">
                                <div className="form-row">
                                  <div className="col-md-4">
                                    <DetailItem
                                      label="Start Date"
                                      value={
                                        <React.Fragment>
                                          <input
                                            required={true}
                                            id="startDate"
                                            className={
                                              !validated
                                                ? "date-time-picker form-control r-0 light s-12"
                                                : errors["startDate"]
                                                  ? "date-time-picker form-control r-0 light s-12 is-invalid"
                                                  : "date-time-picker form-control r-0 light s-12 is-valid"
                                            }
                                            type={"date"}
                                            value={startDate}
                                            onChange={(e) => this.handleOnDateInputChange(e.currentTarget.id, e.currentTarget.value)}
                                          />
                                          <div
                                            className="invalid-feedback"
                                            style={{
                                              visibility: errors["startDate"] ? "visible" : "hidden",
                                              display: errors["startDate"] ? "block" : "none",
                                            }}
                                          >
                                            {errors["startDate"]}
                                          </div>
                                        </React.Fragment>
                                      }
                                      labelSize={5}
                                      valueSize={12}
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <DetailItem
                                      label="End Date"
                                      value={
                                        <React.Fragment>
                                          <input
                                            required={true}
                                            id="endDate"
                                            className={
                                              !validated
                                                ? "date-time-picker form-control r-0 light s-12"
                                                : errors["endDate"]
                                                  ? "date-time-picker form-control r-0 light s-12 is-invalid"
                                                  : "date-time-picker form-control r-0 light s-12 is-valid"
                                            }
                                            type={"date"}
                                            value={endDate}
                                            onChange={(e) => this.handleOnDateInputChange(e.currentTarget.id, e.currentTarget.value)}
                                          />
                                          <div
                                            className="invalid-feedback"
                                            style={{
                                              visibility: errors["endDate"] ? "visible" : "hidden",
                                              display: errors["endDate"] ? "block" : "none",
                                            }}
                                          >
                                            {errors["endDate"]}
                                          </div>
                                        </React.Fragment>
                                      }
                                      labelSize={5}
                                      valueSize={12}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                          <hr />
                        </React.Fragment>
                      )}
                      <div className="row my-3 mx-2">
                        <h5>
                          COLLECTION SCHEDULES
                        </h5>
                      </div>
                      <br />
                      <nav aria-label="Page navigation example clearfix">
                        <ul
                          className="pagination float-left"
                          style={{ marginBottom: "10px", height: "32px" }}
                        >
                          <li
                            className={currentPage === 1 ? "page-item disabled" : "page-item"}
                          >
                            <button
                              type="button"
                              onClick={() => this.handlePageChange(1)}
                              className="page-link"
                            >
                              <i className="fas fa-step-backward" />
                            </button>
                          </li>
                          <li
                            className={currentPage === 1 ? "page-item disabled" : "page-item"}
                          >
                            <button
                              type="button"
                              onClick={() => this.handlePageChange(currentPage - 1)}
                              className="page-link"
                            >
                              <i className="fas fa-chevron-left" />
                            </button>
                          </li>
                          <li className="page-item">
                            <p className="page-link" style={{ height: "30px" }}>
                              Page
                            </p>
                          </li>
                          <li className="page-item">
                            <input
                              style={{ width: "35px" }}
                              className="form-control form-control-sm"
                              id="currentPage"
                              type="number"
                              value={currentPage}
                              onChange={() => this.handlePageChange}
                            />
                          </li>
                          <li className="page-item">
                            <p className="page-link" style={{ height: "30px" }}>
                              of
                            </p>
                          </li>
                          <li className="page-item">
                            <p className="page-link" style={{ height: "30px" }}>
                              {tableItems.length > 0 ? Math.ceil(totalSize / pageSize) : ""}
                            </p>
                          </li>
                          <li
                            className={
                              Math.ceil(totalSize / pageSize) === currentPage
                                ? "page-item disabled"
                                : "page-item"
                            }
                          >
                            <button
                              type="button"
                              onClick={() => this.handlePageChange(currentPage + 1)}
                              className="page-link"
                            >
                              <i className="fas fa-chevron-right" />
                            </button>
                          </li>
                          <li
                            className={
                              Math.ceil(totalSize / pageSize) === currentPage
                                ? "page-item disabled"
                                : "page-item"
                            }
                          >
                            <button
                              type="button"
                              onClick={() =>
                                this.handlePageChange(Math.ceil(totalSize / pageSize))
                              }
                              className="page-link"
                            >
                              <i className="fas fa-step-forward" />
                            </button>
                          </li>
                          <li className="page-item disabled">
                            <a href="#!" className="page-link">
                              <i className="fas fa-grip-lines-vertical" />
                            </a>
                          </li>
                        </ul>
                        <div className="float-right" style={{ height: "30px" }}>
                          <span className="mr-4">
                            {tableItems.length > 0
                              ? `Showing ${(currentPage - 1) * pageSize + 1} - ${(currentPage - 1) * pageSize + tableItems.length
                              } of ${totalSize}`
                              : ""}
                          </span>
                        </div>
                      </nav>
                      <div
                        className="table-responsize text-nowrap"
                      // style={{ height: "300px", overflowY: "scroll" }}
                      >
                        <table className="table table-hover table-content table-sm table-striped mb-0">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                style={{
                                  width: "5px",
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                }}
                              >
                                <strong>#</strong>
                              </th>
                              <th
                                scope="col"
                                style={{
                                  width: "5px",
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                }}
                              >
                                {/* <i class="far fa-check-square"></i> */}
                                <strong>
                                  <i
                                    className={
                                      selectedAll === true
                                        ? "far fa-check-square"
                                        : "far fa-square"
                                    }
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleOnSelectAll()}
                                  ></i>
                                </strong>
                              </th>
                              <th
                                scope="col"
                                style={{
                                  // width: "5px",
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                }}
                              >
                                <strong>Customer Name</strong>
                              </th>
                              <th
                                scope="col"
                                style={{
                                  // width: "5px",
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                }}
                              >
                                <strong>Mandate Reference</strong>
                              </th>
                              <th
                                scope="col"
                                style={{
                                  // width: "5px",
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                }}
                              >
                                <strong>Date Created</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {fetching ? (
                              <React.Fragment>
                                <tr>
                                  <td
                                    className="font-weight-normal text-truncate"
                                    colSpan={6}
                                    style={{
                                      textAlign: "center",
                                      color: "#FE825E",
                                      fontSize: "small",
                                    }}
                                  >
                                    <i className="fas fa-spinner fa-spin mr-2" />
                                  </td>
                                </tr>
                                {/* <Skeleton height={10} width={50} count={4} /> */}
                              </React.Fragment>
                            ) : collectionSchedules.length === 0 ? (
                              <tr>
                                <td
                                  className="font-weight-normal text-truncate"
                                  colSpan={6}
                                  style={{
                                    textAlign: "center",
                                    color: "#FE825E",
                                    fontSize: "small",
                                  }}
                                >
                                  ...No Collection Schedules Available
                                </td>
                              </tr>
                            ) : (
                              tableItems?.map((schedule) => (
                                <tr key={schedule.id}>
                                  <td
                                    style={{
                                      width: "5px",
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {collectionSchedules.indexOf(schedule) + 1}
                                  </td>
                                  <td
                                    style={{
                                      width: "5px",
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    <i
                                      className={
                                        schedule.isSelected
                                          ? "far fa-check-square"
                                          : "far fa-square"
                                      }
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.handleOnSelectCollectionSchedule(
                                          schedule.id,
                                          schedule.isSelected
                                        )
                                      }
                                    ></i>
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {schedule.customerName}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {schedule.mandateReference}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {textFormatService.formatTimeString(schedule.dateCreated)}
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <React.Fragment>
                    <div className="card-body">
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        disabled={submitting}
                        onClick={(e) => {
                          this.onHandleSubmit(e);
                        }}
                      >
                        {submitting === false ? (
                          <React.Fragment>
                            <i className="fas fa-lg fa-save mr-3" /> Pause
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                            Please wait...
                          </React.Fragment>
                        )}
                      </button>
                    </div>
                  </React.Fragment>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PauseCollectionSchedule;
