import React, { Component } from "react";

export interface IUpdateUserRoleApprovalDetailProps {
  item: any;
}

export interface IUpdateUserRoleApprovalDetailState {
  item: any;
}

class UpdateUserRoleApprovalDetail extends Component<
  IUpdateUserRoleApprovalDetailProps,
  IUpdateUserRoleApprovalDetailState
> {
  constructor(props: IUpdateUserRoleApprovalDetailProps) {
    super(props);
    this.state = { item: {} };
  }

  static getDerivedStateFromProps(
    nextProps: IUpdateUserRoleApprovalDetailProps,
    prevState
  ) {
    if (nextProps.item) {
      return { item: JSON.parse(nextProps.item) };
    }
    return null;
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <h6>Update User Role Approval Detail</h6>
        </div>
      </React.Fragment>
    );
  }
}

export default UpdateUserRoleApprovalDetail;
