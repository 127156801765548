import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";
import { ActionTypes } from "../../enums/actionTypes";
import { IFilterData } from "../approval/approvalItemService";
import { decodeToken } from "react-jwt";
import axios from "axios";

interface ISearchAuditTrailData {
  actionInitiator: string;
  actionType: ActionTypes;
}

const auditTrailBaseAddress = process.env.REACT_APP_AUDITTRAIL_BASEADDRESS;
export function retrieveAuditTrailInfoTypes() {
  // const decodedToken = decodeToken(getCurrentUserSession().token || "");
  var url = auditTrailBaseAddress +
    `audittrails/RetrieveAllAuditTrailInfoTypes`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

// export function searchAuditTrails(
//   data: ISearchAuditTrailData,
//   pageSize: number,
//   pageNumber: number
// ) {
//   const decodedToken = decodeToken(getCurrentUserSession().token || "");
//   return http.get(
//     auditTrailBaseAddress +
//     `audittrails/searchaudittrails?institutionId=${decodedToken?.["InstitutionId"]}&initiatingUser=${data.actionInitiator}&informationType=${data.actionType}&pageSize=${pageSize}&pageNumber=${pageNumber}`
//   );
// }

export function retrieveAuditTrailItemById(id: number) {
  var url = auditTrailBaseAddress + `audittrails/getaudittrailitembyid?id=${id}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function getAuditTrailItemsByInformationId(informationId: number) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  var url = auditTrailBaseAddress + `audittrails/getAuditTrailItemsByInformationId?institutionId=${decodedToken?.["InstitutionId"]}&informationId=${informationId}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveAuditTrailItemByCount(
  institutionId: number,
  count: number,
  informationId: number,
  entityName: string
) {
  var url = auditTrailBaseAddress +
    `audittrails/retrieveAuditTrailItemByCount?count=${count}&informationId=${informationId}&entityName=${entityName}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function filterAuditTrails(
  payload: IFilterData,
  pageSize: number,
  pageNumber: number
) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  payload = { ...payload };
  payload.institutionId = {
    item1: "=",
    item2: decodedToken?.["InstitutionId"]?.toString() || "break",
  };
  var url = auditTrailBaseAddress +
    `audittrails/FilterAuditTrails?pageSize=${pageSize}&pageNumber=${pageNumber}`;

  return http.post(url, payload, {
    url: url,
    method: "post",
    data: payload,
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function downloadAuditTrailReport(institutionId, type: String) {
  if (type.toLowerCase() === "pdf") {
    var url = auditTrailBaseAddress + `AuditTrails/DownloadAuditTrailReportPDF`;

    return http.get(url, {
      url: url,
      method: "get",
      headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
  } else {
    var url2 = auditTrailBaseAddress +
      `AuditTrails/DownloadAuditTrailReport?type=${type}`;

    axios({
      url: url2,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${getCurrentUserSession().token}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Users.${type}`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }
}

export default {
  retrieveAuditTrailInfoTypes,
  // searchAuditTrails,
  retrieveAuditTrailItemById,
  getAuditTrailItemsByInformationId,
  retrieveAuditTrailItemByCount,
  filterAuditTrails,
  downloadAuditTrailReport,
};
