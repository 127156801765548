import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";

interface IInstitutionWalletConfigurationDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  item: any;
}

interface IInstitutionWalletConfigurationDetailState {
  item: any;
  updatingStatus: boolean;
}

class InstitutionWalletConfigurationDetail extends Component<
  IInstitutionWalletConfigurationDetailProps,
  IInstitutionWalletConfigurationDetailState
> {
  state = {
    item: {},
    updatingStatus: false,
  };

  static getDerivedStateFromProps(
    nextProps: IInstitutionWalletConfigurationDetailProps, prevState) {
    return {
      item: nextProps.item
    };
  }

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      item
    } = this.props;
    const { updatingStatus } = this.state;

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="fas fa-tools fa-sm cyan-text mr-3" />
                Wallet Configuration Details -{" "}
                <span className="font-weight-bold">{item.serviceOffering}</span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={5} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Institution Code"
                        labelSize={7}
                        valueSize={5}
                        value={item.institutionCode}
                      />
                      <DetailItem
                        label="Billing Type"
                        labelSize={7}
                        valueSize={5}
                        value={item.billingType}
                      />
                      <DetailItem
                        label="Subscription Request Count"
                        labelSize={7}
                        valueSize={5}
                        value={item.subscriptionRequestCount}
                      />
                      <DetailItem
                        label="Status"
                        labelSize={7}
                        valueSize={5}
                        value={
                          (item ? item.status : "Active") === "Active" ? (
                            <span className="badge badge-success">
                              {item ? item.status : ""}
                            </span>
                          ) : (
                            <span className="badge badge-danger">
                              {item ? item.status : ""}
                            </span>
                          )
                        }
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={5} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Service Offering"
                        labelSize={7}
                        valueSize={5}
                        value={item.serviceOffering}
                      />
                      <DetailItem
                        label="Payment Mode"
                        labelSize={7}
                        valueSize={5}
                        value={item.paymentMode}
                      />
                      <DetailItem
                        label="Subscription Pay As You Go Price"
                        labelSize={7}
                        valueSize={5}
                        value={item.subscriptionPayAsYouGoPrice}
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>
          </div >
        </Modal.Body >
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
              <Button
                size="sm"
                variant="primary"
                disabled={updatingStatus}
                onClick={toggleEditModal}
              >
                <i className="far fa-edit mr-3" />
                Edit
              </Button>
            </React.Fragment>
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal >
    );
  }
}

export default InstitutionWalletConfigurationDetail;