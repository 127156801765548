import http from "../httpService";
import { status } from "../approval/approvableService";
import { getIpAddress, cryptoAESEncrypt } from "../utility/textFormatService";
import { decodeToken } from "react-jwt";
import * as CryptoJS from "crypto-js";
import _ from "lodash";
import { getCurrentUserSession } from "../auth/authService";

const baseAddress = process.env.REACT_APP_THIRDPARTY_INTEGRATION_BASEADDRESS;
const lasBaseAddress = process.env.REACT_APP_HYBRIDAPPRAISAL_BASEADDRESS
const apiToken = process.env.REACT_APP_REMITA_INTEGRATION_API_TOKEN;

export async function verifyId(request: any) {

    var url = baseAddress + `QoreID/validateCustomer`;

    return http.post(url, request, {
        url: url,
        data: request,
        method: "post",
        headers: {
            "x-api-key": `${apiToken}`,
        },
    });
}

export async function saveVerification(request: any) {

    var url = lasBaseAddress + `IdVerification/CreateIdVerification`;

    return http.post(url, request, {
        url: url,
        data: request,
        method: "post"
    });
}

export async function getVerificationDetails(loanRequestId: any) {

    var url = lasBaseAddress + `IdVerification/RetrieveByLoanRequest/${loanRequestId}`;

    return http.get(url, {
        url: url,
        method: "get"
    });
}



export default {
    verifyId,
    saveVerification,
    getVerificationDetails
}