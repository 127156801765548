import React, { Component, Fragment, useContext } from "react";
import {
    Modal,
    Button,
    Card,
    useAccordionToggle,
    AccordionContext,
    Accordion,
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import DetailItem from "../../../../components/detailItem";
import { getCurrentUsersRoles } from "../../../../services/auth/authService";

export function ContextAwareToggle({ eventKey, header }) {
    const currentEventKey = useContext(AccordionContext);
    const toggleOnClick = useAccordionToggle(eventKey);
    const isCurrentEventKey = currentEventKey === eventKey;
    const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
    return (
        <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
            <b>{header}</b>
            <i
                className={angleType}
                aria-hidden="true"
                style={{ float: "right" }}
            ></i>
        </Card.Header>
    );
}

interface IOfferLetterDetailProps {
    showDetailsModal: boolean;
    toggleDocumentScreenModal: any;
    item: any;
}

interface IOfferLetterDetailState {
    item: any;
    updatingStatus: boolean;
}

class OfferLetterDetailScreen extends Component<IOfferLetterDetailProps, IOfferLetterDetailState> {
    constructor(props: IOfferLetterDetailProps) {
        super(props);
        this.state = {
            item: {},
            updatingStatus: false,
        };
    }

    static getDerivedStateFromProps(nextProps: IOfferLetterDetailProps, prevState) {
        return {
            item: nextProps.item,
        };
    }

    render() {
        const {
            showDetailsModal,
            toggleDocumentScreenModal,
            item,
        } = this.props;
        const {
            updatingStatus,
        } = this.state;

        return (
            <Modal
                size="xl"
                backdrop="static"
                show={showDetailsModal}
                onHide={toggleDocumentScreenModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {_.isEmpty(item) ? (
                            <Skeleton width={500} />
                        ) : (
                            <h5>
                                <i className="fas fa-user-shield fa-sm cyan-text mr-3" />
                                Document Details {" "}
                                <span className="font-weight-bold"></span>
                            </h5>
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* {console.log(item)}; */}
                    <div className="card" dangerouslySetInnerHTML={{ __html: item }}>

                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={() => { toggleDocumentScreenModal() }}
                    >
                        <i className="fas fa-times mr-3" />
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default OfferLetterDetailScreen;
