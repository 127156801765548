import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../components/detailItem";
import textFormatService from "../../services/utility/textFormatService";

interface IInstitutionFeePayableReportDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  item: any;
}

interface IInstitutionFeePayableReportDetailState {
}

class AppzoneFeePayableReportDetail extends Component<
  IInstitutionFeePayableReportDetailProps,
  IInstitutionFeePayableReportDetailState
> {
  constructor(props: IInstitutionFeePayableReportDetailProps) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { showDetailsModal, toggleDetailsModal, item } = this.props;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="fas fa-receipt fa-sm s-18 mr-3" />
                Appzone Fee Payable Details
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={8} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Service Offering"
                        value={item.serviceOffering}
                      />
                      <DetailItem
                        label="Appzone Fee"
                        value={textFormatService.formatCurrency(item.appzoneFee, 2)}
                      />
                      <DetailItem
                        label="Third-Party"
                        value={item.thirdParty == null ? "N/A" : item.thirdParty}
                      />
                      <DetailItem
                        label="Third-Party Fee"
                        value={item.thirdPartyFee == 0 ? "N/A" : (textFormatService.formatCurrency(item.thirdPartyFee, 2))}
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={8} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Institution Code"
                        value={item.institutionCode}
                      />
                      <DetailItem
                        label="Payment Reference"
                        value={item.paymentReference}
                      />
                      <DetailItem
                        label="Date Created"
                        value={textFormatService.formatTimeString(item.dateCreated)}
                      />
                      <DetailItem
                        label="Status"
                        value={
                          (item ? item.status : "Paid") === "Paid" ? (
                            <span className="badge badge-success">
                              {item ? item.status : ""}
                            </span>
                          ) : (
                            <span className="badge badge-danger">
                              {item ? item.status : ""}
                            </span>
                          )
                        }
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AppzoneFeePayableReportDetail;
