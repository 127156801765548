import React from "react";
import { toast } from "react-toastify";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { getCurrentUsersRoles } from "../../../services/auth/authService";
import FormInputComponent from "../../../components/formInputComponent";
import { TransactionAmountSettings } from "../../../enums/recovaMandate/transactionAmountSettings";
import { CummulativeDeductionConfig } from "../../../enums/recovaMandate/cummulativeDeductionConfig";
import { PermissibleDeductionConfig } from "../../../enums/recovaMandate/permissibleDeductionConfig";
import { MandateDurationConfig } from "../../../enums/recovaMandate/mandateDuration";
import mandateValidationService from "../../../services/mandate/mandateValidationService";
import mandateServiceTypeService from "../../../services/mandate/mandateServiceTypeService";
import { ActionTypes } from "../../../enums/actionTypes";

interface AddMandateServiceTypeFields {
  name: string;
  description: string;
  transactionAmount: string;
  permissibleDeductionCount: string;
  cumulativeDeductionCount: string;
  mandateDuration: string;
}

class AddMandateServiceTypeForm extends BaseFormComponent<
  AddMandateServiceTypeFields
> { }

interface IAddMandateServiceTypeProps { }
interface IAddMandateServiceTypeState {
  transactionAmountSettings: TransactionAmountSettings;
  cummulativeDeductionConfig: CummulativeDeductionConfig;
  permissibleDeductionConfig: PermissibleDeductionConfig;
  mandateDurationConfig: MandateDurationConfig;
}

class AddMandateServiceType extends React.Component<
  IAddMandateServiceTypeProps,
  IBaseFormState & IAddMandateServiceTypeState
> {
  constructor(props: IAddMandateServiceTypeProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      transactionAmountSettings: TransactionAmountSettings.Fixed,
      cummulativeDeductionConfig: CummulativeDeductionConfig.Fixed,
      permissibleDeductionConfig: PermissibleDeductionConfig.Limited,
      mandateDurationConfig: MandateDurationConfig.Limited,
    };
  }

  onFormSubmit(
    fields: AddMandateServiceTypeFields,
    onReloadFieldsCB: any
  ): boolean {
    try {
      this.setState(
        {
          errors: mandateValidationService.validateAddMandateServiceTypeForm(
            fields
          ),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddMandateServiceTypeFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      // console.log("starting submission...");
      this.setState({ submitting: true });
      const payload: any = {};
      payload.name = fields.name;
      payload.description = fields.description;
      payload.institutionId = getCurrentUsersRoles().institutionId;
      payload.transactionAmount = fields.transactionAmount;
      payload.permissibleDeductionCount = fields.permissibleDeductionCount;
      payload.cumulativeDeductionCount = fields.cumulativeDeductionCount;
      payload.mandateDuration = fields.mandateDuration;
      payload.permissibleDeductionConfig =
        PermissibleDeductionConfig[this.state.permissibleDeductionConfig];
      payload.transactionAmountCategory =
        TransactionAmountSettings[this.state.transactionAmountSettings];
      payload.cumulativeDeductionConfig =
        CummulativeDeductionConfig[this.state.cummulativeDeductionConfig];
      payload.mandateDurationConfig =
        MandateDurationConfig[this.state.mandateDurationConfig];

      const response = await mandateServiceTypeService.saveMandateServiceType(
        payload
      );
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Add MandateServiceType",
          description: `Added new Mandate Service Type ${fields.name}`,
        });

        if (response.data.isLoggedForApproval === true) {
          toast.warn("Action has been successfully logged for Approval!", {
            autoClose: 6000,
            type: toast.TYPE.WARNING,
            position: "top-center",
            hideProgressBar: false,
          });
        } else {
          toast.info(
            `Mandate Service Type ${fields.name} created successfully! `,
            {
              autoClose: 6000,
              type: toast.TYPE.DEFAULT,
              hideProgressBar: false,
            }
          );
        }
      } else {
        return false;
      }
      this.setState({ submitting: false });
      onReloadFieldsCB();
      return true;
    } catch (error) {
      // console.log("error: ", error.response.data);
      this.setState({ submitting: false });
      return false;
    }
  }

  handleOnSwitchUpdate = (name, { trueValue, falseValue }) => {
    switch (name) {
      case "transactionAmountSettings":
        let transactionAmountSettings = this.state.transactionAmountSettings;
        transactionAmountSettings =
          transactionAmountSettings === trueValue ? falseValue : trueValue;
        this.setState({ transactionAmountSettings });
        break;
      case "permissibleDeductionConfig":
        let permissibleDeductionConfig = this.state.permissibleDeductionConfig;
        permissibleDeductionConfig =
          permissibleDeductionConfig === trueValue ? falseValue : trueValue;
        this.setState({ permissibleDeductionConfig });
        break;
      case "mandateDurationConfig":
        let mandateDurationConfig = this.state.mandateDurationConfig;
        mandateDurationConfig =
          mandateDurationConfig === trueValue ? falseValue : trueValue;
        this.setState({ mandateDurationConfig });
        break;
      case "cummulativeDeductionConfig":
        let cummulativeDeductionConfig = this.state.cummulativeDeductionConfig;
        cummulativeDeductionConfig =
          cummulativeDeductionConfig === trueValue ? falseValue : trueValue;
        this.setState({ cummulativeDeductionConfig });
        break;
      default:
        break;
    }
  };

  validateField = (fieldName: string, type: string, validated: boolean) => {
    let defaultClass = "";
    if (type === "select") {
      defaultClass =
        "custom-select select2 mr-sm-2 form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    } else {
      defaultClass = "form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    }
    return !validated
      ? defaultClass
      : this.state.errors[fieldName]
        ? `${defaultClass} is-invalid`
        : `${defaultClass} is-valid`;
  };

  render() {
    const {
      errors,
      validated,
      submitting,
      cummulativeDeductionConfig,
      mandateDurationConfig,
      transactionAmountSettings,
      permissibleDeductionConfig,
    } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add Mandate Service Type</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddMandateServiceTypeForm
                  initialValues={{
                    mandateDuration: "",
                    permissibleDeductionCount: "",
                    cumulativeDeductionCount: "",
                    transactionAmount: "",
                    description: "",
                    name: "",
                  }}
                  FormComponent={({
                    fields: {
                      name,
                      description,
                      transactionAmount,
                      cumulativeDeductionCount,
                      permissibleDeductionCount,
                      mandateDuration,
                    },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <h5 className="card-title">GENERAL INFORMATION</h5>
                          <div className="form-row">
                            <div className="col-md-4">
                              <div className="row mb-3 text-left">
                                <FormInputComponent
                                  id="name"
                                  type="text"
                                  name="name"
                                  placeholder=""
                                  divClass={10}
                                  value={name}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="row">
                                <div className="form-group col-md-10 mb-3 text-left">
                                  <label
                                    htmlFor="description"
                                    className="col-form-label s-12"
                                  >
                                    <span
                                      aria-hidden="true"
                                      className="mr-2"
                                      style={{ color: "#ed5564" }}
                                    >
                                      *
                                    </span>
                                    Description
                                  </label>
                                  <textarea
                                    id="description"
                                    name="description"
                                    rows={5}
                                    placeholder=""
                                    className={
                                      !validated
                                        ? "form-control r-0 light s-12"
                                        : errors.description
                                          ? "form-control r-0 light s-12 is-invalid"
                                          : "form-control r-0 light s-12 is-valid"
                                    }
                                    value={description}
                                    onChange={(e) =>
                                      onChange(
                                        "description",
                                        e.currentTarget.value
                                      )
                                    }
                                  ></textarea>
                                  <div
                                    className="invalid-feedback"
                                    style={{
                                      visibility: errors.description,
                                    }}
                                  >
                                    {errors.description}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="form-group row">
                                <label
                                  htmlFor="transactionAmountSettings"
                                  className="col-md-4 col-form-label"
                                >
                                  Tansaction Amount:
                                </label>
                                <div className="col-md-3">
                                  <span className="material-switch">
                                    <input
                                      id="transactionAmountSettings"
                                      name="transactionAmountSettings"
                                      type="checkbox"
                                      onChange={() =>
                                        this.handleOnSwitchUpdate(
                                          "transactionAmountSettings",
                                          {
                                            trueValue:
                                              TransactionAmountSettings.Variable,
                                            falseValue:
                                              TransactionAmountSettings.Fixed,
                                          }
                                        )
                                      }
                                      checked={
                                        transactionAmountSettings ===
                                        TransactionAmountSettings.Variable
                                      }
                                    />
                                    <label
                                      htmlFor="transactionAmountSettings"
                                      className="bg-primary"
                                    ></label>
                                  </span>
                                  <label>
                                    &nbsp;&nbsp;{"   "}
                                    {
                                      TransactionAmountSettings[
                                      transactionAmountSettings
                                      ]
                                    }
                                  </label>
                                </div>
                                <div className="col-md-5">
                                  <input
                                    type="number"
                                    className={this.validateField(
                                      "transactionAmount",
                                      "text",
                                      validated
                                    )}
                                    id="transactionAmount"
                                    name="transactionAmount"
                                    value={
                                      transactionAmountSettings ===
                                        TransactionAmountSettings.Fixed
                                        ? 0
                                        : transactionAmount
                                    }
                                    onChange={(e) =>
                                      onChange(
                                        "transactionAmount",
                                        e.currentTarget.value
                                      )
                                    }
                                    disabled={
                                      transactionAmountSettings ===
                                      TransactionAmountSettings.Fixed
                                    }
                                  />
                                  <div
                                    className="invalid-feedback"
                                    style={{
                                      visibility: errors.transactionAmount,
                                    }}
                                  >
                                    {errors.transactionAmount}
                                  </div>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="permissibleDeductionConfig"
                                  className="col-md-4 col-form-label"
                                >
                                  Permissible Deduction:
                                </label>
                                <div className="col-md-3">
                                  <span className="material-switch">
                                    <input
                                      id="permissibleDeductionConfig"
                                      name="permissibleDeductionConfig"
                                      type="checkbox"
                                      onChange={() =>
                                        this.handleOnSwitchUpdate(
                                          "permissibleDeductionConfig",
                                          {
                                            trueValue:
                                              PermissibleDeductionConfig.Limited,
                                            falseValue:
                                              PermissibleDeductionConfig.Unlimited,
                                          }
                                        )
                                      }
                                      checked={
                                        permissibleDeductionConfig ===
                                        PermissibleDeductionConfig.Unlimited
                                      }
                                    />
                                    <label
                                      htmlFor="permissibleDeductionConfig"
                                      className="bg-primary"
                                    ></label>
                                  </span>
                                  <label>
                                    &nbsp;&nbsp;{"   "}
                                    {
                                      PermissibleDeductionConfig[
                                      permissibleDeductionConfig
                                      ]
                                    }
                                  </label>
                                </div>
                                <div className="col-md-5">
                                  <input
                                    type="number"
                                    className={this.validateField(
                                      "permissibleDeductionCount",
                                      "text",
                                      validated
                                    )}
                                    id="permissibleDeductionCount"
                                    name="permissibleDeductionCount"
                                    value={
                                      permissibleDeductionConfig ===
                                        PermissibleDeductionConfig.Unlimited
                                        ? 0
                                        : permissibleDeductionCount
                                    }
                                    onChange={(e) =>
                                      onChange(
                                        "permissibleDeductionCount",
                                        e.currentTarget.value
                                      )
                                    }
                                    disabled={
                                      permissibleDeductionConfig ===
                                      PermissibleDeductionConfig.Unlimited
                                    }
                                  />
                                  <div
                                    className="invalid-feedback"
                                    style={{
                                      visibility:
                                        errors.permissibleDeductionCount,
                                    }}
                                  >
                                    {errors.permissibleDeductionCount}
                                  </div>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="cummulativeDeductionConfig"
                                  className="col-md-4 col-form-label"
                                >
                                  Cumulative Deduction:
                                </label>
                                <div className="col-md-3">
                                  <span className="material-switch">
                                    <input
                                      id="cummulativeDeductionConfig"
                                      name="cummulativeDeductionConfig"
                                      type="checkbox"
                                      onChange={() =>
                                        this.handleOnSwitchUpdate(
                                          "cummulativeDeductionConfig",
                                          {
                                            trueValue:
                                              CummulativeDeductionConfig.Variable,
                                            falseValue:
                                              CummulativeDeductionConfig.Fixed,
                                          }
                                        )
                                      }
                                      checked={
                                        cummulativeDeductionConfig ===
                                        CummulativeDeductionConfig.Variable
                                      }
                                    />
                                    <label
                                      htmlFor="cummulativeDeductionConfig"
                                      className="bg-primary"
                                    ></label>
                                  </span>
                                  <label>
                                    &nbsp;&nbsp;{"   "}
                                    {
                                      CummulativeDeductionConfig[
                                      cummulativeDeductionConfig
                                      ]
                                    }
                                    {"  "}
                                  </label>
                                </div>
                                <div className="col-md-5">
                                  <input
                                    type="number"
                                    className={this.validateField(
                                      "cumulativeDeductionCount",
                                      "text",
                                      validated
                                    )}
                                    id="cumulativeDeductionCount"
                                    name="cumulativeDeductionCount"
                                    value={
                                      cummulativeDeductionConfig ===
                                        CummulativeDeductionConfig.Fixed
                                        ? 0
                                        : cumulativeDeductionCount
                                    }
                                    onChange={(e) =>
                                      onChange(
                                        "cumulativeDeductionCount",
                                        e.currentTarget.value
                                      )
                                    }
                                    disabled={
                                      cummulativeDeductionConfig ===
                                      CummulativeDeductionConfig.Fixed
                                    }
                                  />
                                  <div
                                    className="invalid-feedback"
                                    style={{
                                      visibility:
                                        errors.cumulativeDeductionCount,
                                    }}
                                  >
                                    {errors.cumulativeDeductionCount}
                                  </div>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="mandateDurationConfig"
                                  className="col-md-4 col-form-label"
                                >
                                  Mandate Duration (Days):
                                </label>
                                <div className="col-md-3">
                                  <span className="material-switch">
                                    <input
                                      id="mandateDurationConfig"
                                      name="mandateDurationConfig"
                                      type="checkbox"
                                      onChange={() =>
                                        this.handleOnSwitchUpdate(
                                          "mandateDurationConfig",
                                          {
                                            trueValue:
                                              MandateDurationConfig.Limited,
                                            falseValue:
                                              MandateDurationConfig.Unlimited,
                                          }
                                        )
                                      }
                                      checked={
                                        mandateDurationConfig ===
                                        MandateDurationConfig.Unlimited
                                      }
                                    />
                                    <label
                                      htmlFor="mandateDurationConfig"
                                      className="bg-primary"
                                    ></label>
                                  </span>
                                  <label>
                                    &nbsp;&nbsp;{"   "}
                                    {
                                      MandateDurationConfig[
                                      mandateDurationConfig
                                      ]
                                    }
                                    {"  "}
                                  </label>
                                </div>
                                <div className="col-md-5">
                                  <input
                                    type="number"
                                    className={this.validateField(
                                      "mandateDuration",
                                      "text",
                                      validated
                                    )}
                                    id="mandateDuration"
                                    name="mandateDuration"
                                    value={
                                      mandateDurationConfig ===
                                        MandateDurationConfig.Unlimited
                                        ? 0
                                        : mandateDuration
                                    }
                                    onChange={(e) =>
                                      onChange(
                                        "mandateDuration",
                                        e.currentTarget.value
                                      )
                                    }
                                    disabled={
                                      mandateDurationConfig ===
                                      MandateDurationConfig.Unlimited
                                    }
                                  />
                                  <div
                                    className="invalid-feedback"
                                    style={{
                                      visibility: errors.mandateDuration,
                                    }}
                                  >
                                    {errors.mandateDuration}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="card-body">
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                            disabled={submitting}
                            onClick={(e) => {
                              onHandleSubmit(
                                e,
                                ActionTypes.SAVE,
                                "Mandate Service Type",
                                () => {
                                  this.onFormSubmit(
                                    {
                                      cumulativeDeductionCount,
                                      description,
                                      mandateDuration,
                                      name,
                                      permissibleDeductionCount,
                                      transactionAmount,
                                    },
                                    onReloadFields
                                  );
                                }
                              );
                            }}
                          >
                            {submitting === false ? (
                              <React.Fragment>
                                <i className="fas fa-lg fa-save mr-3" /> Save
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                Please wait...
                              </React.Fragment>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                ></AddMandateServiceTypeForm>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddMandateServiceType;
