import React, { Component } from "react";
// import React, { useState, useEffect, useRef } from 'react';
import { HubConnectionBuilder } from "@microsoft/signalr";

interface IState {
    seconds: number;
    time: string;
    connectionState: string;
    connectionIndicator: string;
    connection: any;
}

class Footer extends Component<{}, IState> {
    // state = {
    //   seconds: 0,
    //   time: ""
    // };

    tick() {
        this.setState((prevState) => ({
            seconds: prevState.seconds + 1,
            time: new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds(),
            connectionState: "Disconnected",
            connectionIndicator: "green-text",
            connection: {},
        }));
    }

    componentDidMount() {
        const authBaseAddress = process.env.REACT_APP_AUTH_BASEADDRESS?.toString().split("api/")[0];
        const connection = new HubConnectionBuilder()
            .withUrl(`${authBaseAddress}testhub`)
            // .withAutomaticReconnect()
            .build();

        connection
            .start()
            .then((result) => {
                // console.log("Connected! ", connection);

                //     if (connection.connectionId) {
                //       this.setState({
                //         connectionState: "Connected",
                //         connectionIndicator: "green-text",
                //         connection,
                //       });
                //       // connection.invoke("SendMessage", "send to the server");
                //     }

                connection.onclose((e) => {
                    // console.log("Connection Closed", e);
                    this.setState({
                        connectionState: "Disconnected",
                        connectionIndicator: "red-text",
                        connection: {},
                    });
                });

                //     connection.onreconnected(() => {
                //       this.setState({
                //         connectionState: "Connected",
                //         connectionIndicator: "green-text",
                //         connection: {},
                //       });
                //     });

                //     connection.onreconnecting(() => {
                //       this.setState({
                //         connectionState: "Connecting",
                //         connectionIndicator: "orange-text",
                //         connection: {},
                //       });
                //     });

                connection.on("ReceiveMessage", (message) => {
                    // const updatedChat = [...latestChat.current];
                    // updatedChat.push(message);
                    // console.log("server: ", message);
                });
            })
            .catch((e) => {}); // console.log('Connection failed: ', e))
    }
    componentWillUnmount() {
        // clearInterval(this.interval);
    }
    render() {
        return (
            <footer className="footer clearfix">
                {/* <span className="font-weight-normal ml-4">{this.state.time}</span> */}
                <span className="font-weight-normal float-right mr-4">
                    <i className={"fas fa-circle mr-2 " + String(this.state?.connectionIndicator)}></i>
                    Copyright © {new Date().getFullYear()}. AppZone Ltd. All Rights Reserved.
                </span>
            </footer>
        );
    }
}

export default Footer;
