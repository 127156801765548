import http from "../httpService";
import { getCurrentUserSession } from "../../services/auth/authService";
import { IFilterData } from "../approval/approvalItemService";

interface ISearchDisputeConditionData {
  name: string;
  code: string;
  dsiputeFilingTimeLimit: string;
}
interface ISaveDisputeCondition extends ISearchDisputeConditionData {
  institutionId: number;
  assignedDisputeConditionDocuments: number[];
  maxResolutionTAT: string;
  description: string;
}
interface IUpdateDisputeCondition extends ISaveDisputeCondition {
  id: number;
}
const recovaDisputeBaseAddress = process.env.REACT_APP_DISPUTE_BASEADDRESS;

export function saveDisputeCondition(disputeCondition: any) {
  var url =
    recovaDisputeBaseAddress +
    `DisputeCondition`;
  // console.log("saving DC: ");

  return http.post(url, disputeCondition, {
    url: url,
    method: "post",
    data: disputeCondition,
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function updateDisputeCondition(
  disputeCondition: IUpdateDisputeCondition
) {
  // console.log("disputeCondition", disputeCondition);
  var url =
    recovaDisputeBaseAddress +
    `DisputeCondition/${disputeCondition.id}`;

  return http.put(url, disputeCondition, {
    url: url,
    method: "put",
    data: disputeCondition,
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function retrieveDisputeConditionById(disputeConditionId: number) {
  var url = recovaDisputeBaseAddress + `DisputeCondition/${disputeConditionId}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveAllDisputeConditionDocuments() {
  var url = recovaDisputeBaseAddress +
    `DisputeCondition/GetAllDisputeConditionDocuments`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveAllDisputeConditions() {
  var url = recovaDisputeBaseAddress + "DisputeCondition/GetAll";

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

// export function searchDisputeConditions(
//   data: ISearchDisputeConditionData,
//   pageSize: number,
//   pageNumber: number
// ) {
//   return http.get(
//     recovaDisputeBaseAddress +
//     `DisputeCondition/SearchDisputeConditions?name=${data.name}&code=${data.code}&dsiputeFilingTimeLimit=${data.dsiputeFilingTimeLimit}&pageSize=${pageSize}&pageNumber=${pageNumber}`
//   );
// }

export function filterDisputeConditions(
  payload: IFilterData,
  pageSize: number,
  pageNumber: number
) {
  var url =
    recovaDisputeBaseAddress +
    `DisputeCondition/FilterDisputeConditions?pageSize=${pageSize}&pageNumber=${pageNumber}`;

  return http.post(url, payload, {
    url: url,
    method: "post",
    data: payload,
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export default {
  saveDisputeCondition,
  updateDisputeCondition,
  retrieveDisputeConditionById,
  retrieveAllDisputeConditions,
  retrieveAllDisputeConditionDocuments,
  // searchDisputeConditions,
  filterDisputeConditions,
};
