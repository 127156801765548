import React, { Component } from "react";

export interface IDisableUserRoleApprovalDetailProps {
  item: any;
}

export interface IDisableUserRoleApprovalDetailState {
  item: any;
}

class DisableUserRoleApprovalDetail extends Component<
  IDisableUserRoleApprovalDetailProps,
  IDisableUserRoleApprovalDetailState
> {
  constructor(props: IDisableUserRoleApprovalDetailProps) {
    super(props);
    this.state = {
      item: {},
    };
  }

  static getDerivedStateFromProps(
    nextProps: IDisableUserRoleApprovalDetailProps,
    prevState
  ) {
    if (nextProps.item) {
      return { item: JSON.parse(nextProps.item) };
    }
    return null;
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <h6>Disable User Role Approval Detail</h6>
        </div>
      </React.Fragment>
    );
  }
}

export default DisableUserRoleApprovalDetail;
