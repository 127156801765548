import React, { Component, useContext } from "react";
import {
    Card,
    useAccordionToggle,
    AccordionContext,
    Accordion,
    Modal,
    Button,
} from "react-bootstrap";
import _ from "lodash";
import DetailItem from "../../../../components/detailItem";
import textFormatService from "../../../../services/utility/textFormatService";
import { toast } from "react-toastify";
import groupLoanRequestMembersService from "../../../../services/hybridAppraisal/groupLoanRequestMembersService";

export function ContextAwareToggle({ eventKey, header }) {
    const currentEventKey = useContext(AccordionContext);
    const toggleOnClick = useAccordionToggle(eventKey);
    const isCurrentEventKey = currentEventKey === eventKey;
    const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
    return (
        <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
            <b>{header}</b>
            <i
                className={angleType}
                aria-hidden="true"
                style={{ float: "right" }}
            ></i>
        </Card.Header>
    );
}

interface IcreditAnalysisCheckProps {
    item: any;
    showPatchModal: boolean;
    togglePatchModal: any;
    member: any;
    systemProcess: any;
    userRoles: any;
    currentUserSession: any;
}

interface IcreditAnalysisCheckState {
    item: any;
    creditAnalysisResponse: any;
    ownUpdate: boolean;
    submitting: boolean;
    creditAnalysisDetails: any;
    groupMember: any;
}

class GroupcreditAnalysisCheck extends Component<IcreditAnalysisCheckProps, IcreditAnalysisCheckState> {
    _isMounted: boolean;
    constructor(props: IcreditAnalysisCheckProps) {
        super(props);
        this.state = {
            item: {},
            creditAnalysisResponse: [],
            ownUpdate: false,
            submitting: false,
            creditAnalysisDetails: [],
            groupMember: {}
        };
    }

    static getDerivedStateFromProps(nextProps: IcreditAnalysisCheckProps, prevState) {
        if (prevState.ownUpdate) {
            return {
                ownUpdate: false,
            };
        }
        if (_.isNull(nextProps.item)) {
            return null;
        }
        return {
            item: nextProps.item
        };
    }

    approveAppraisal = async (id) => {
        // console.log(id);
        const memberDetails = await groupLoanRequestMembersService.retrieveById(id)
        const memberDetail = memberDetails.data;

        const payload: any = { ...memberDetail };

        payload.memberStatus = "Eligible";

        const response = await groupLoanRequestMembersService.updateLoanRequest(payload);
        if (response.status >= 200 && response.status <= 300) {
            toast.info(`Member Approved!`, {
                autoClose: 6000,
                type: toast.TYPE.DEFAULT,
                hideProgressBar: false,
            });
        } else {
            return false;
        }


    };

    async componentDidMount() {
        this._isMounted = true;
        // console.log(this.props.member)
        const creditAnalysisResponse = this.props.member[0]?.creditAnalysisResponse;
        // console.log(creditAnalysisResponse)
        let creditAnalysisDetails: any = [];
        try {
            // creditAnalysisDetails = JSON.parse(creditAnalysisResponse?.Details);
            // if (_.isNull(creditAnalysisDetails)) {
            creditAnalysisDetails = creditAnalysisResponse?.Details
            // }
        }
        catch {
            creditAnalysisDetails = null
        }

        const groupMember = await groupLoanRequestMembersService.retrieveById(this.props.member[0]?.id)
        const groupMemberDetail = groupMember.data
        // console.log(creditAnalysisDetails)
        if (this._isMounted) {
            this.setState({ creditAnalysisResponse: creditAnalysisResponse, creditAnalysisDetails: creditAnalysisDetails, groupMember: groupMemberDetail, ownUpdate: true });
        }
    }

    render() {
        const { item, showPatchModal, togglePatchModal, member } = this.props;
        const { creditAnalysisResponse, submitting, creditAnalysisDetails, groupMember } = this.state;

        return (
            <Modal
                size="lg"
                backdrop="static"
                show={showPatchModal}
                onHide={togglePatchModal}
            >
                <Modal.Header>
                    <Modal.Title>
                        <h5>
                            <i className="fas fa-edit fa-sm cyan-text mr-3" />
                            Individual Credit Analysis
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card">
                        <Accordion defaultActiveKey="0">
                            <div>
                                <Card>
                                    <ContextAwareToggle
                                        eventKey={`0`}
                                        header={<React.Fragment>


                                        </React.Fragment>}
                                    ></ContextAwareToggle>
                                    <Accordion.Collapse eventKey={`0`}>
                                        <Card.Body>
                                            <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                <div className="row">

                                                    <div className="col-md-5">
                                                        {/* <h6
                                                                            className="card-title"
                                                                            style={{ marginBottom: "0px", fontFamily: "sans-serif" }}
                                                                        >
                                                                            <b>Performance Summary</b>
                                                                        </h6>
                                                                        <br /> */}
                                                        <dl className="row">
                                                            <DetailItem
                                                                label="Number of Active Loan Accounts"
                                                                labelSize={9}
                                                                valueSize={3}
                                                                // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                value={creditAnalysisResponse?.OpenedLoanAccounts}
                                                            />
                                                            <DetailItem
                                                                label="Number of Closed Loan Accounts"
                                                                labelSize={9}
                                                                valueSize={3}
                                                                value={creditAnalysisResponse?.ClosedLoanAccounts}
                                                            />
                                                            <DetailItem
                                                                label="Number of Performing Active Loan Accounts"
                                                                labelSize={9}
                                                                valueSize={3}
                                                                value={creditAnalysisResponse?.PerformingLoanAccounts}
                                                            />
                                                            <DetailItem
                                                                label="Number of Non-Performing Active Loan Accounts"
                                                                labelSize={9}
                                                                valueSize={3}
                                                                value={creditAnalysisResponse?.NonPerformingLoanAccounts}
                                                            />
                                                            <DetailItem
                                                                label="Number of Loan Accounts Written-off"
                                                                labelSize={9}
                                                                valueSize={3}
                                                                value={creditAnalysisResponse?.LoansWrittenOff}
                                                            />
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </div>
                        </Accordion>
                    </div>

                    <div className="card mt-3">
                        <div
                            className="table-responsive text-nowrap"
                            style={{
                                height: "300px",
                                overflowY: "scroll",
                            }}
                        >
                            <table className="table table-hover table-content table-sm table-striped mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", }}>
                                            <strong>Customer Name</strong>
                                        </th>
                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", }}>
                                            <strong>Account Number</strong>
                                        </th>
                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", }}>
                                            <strong>Product Name</strong>
                                        </th>
                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", }}>
                                            <strong>Amount</strong>
                                        </th>
                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", }}>
                                            <strong>Tenor</strong>
                                        </th>
                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", }}>
                                            <strong>Disbursement Date</strong>
                                        </th>
                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", }}>
                                            <strong>Maturity Date</strong>
                                        </th>
                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", }}>
                                            <strong>Balance</strong>
                                        </th>
                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", }}>
                                            <strong>Overdue</strong>
                                        </th>
                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", }}>
                                            <strong>No of Days Past Due</strong>
                                        </th>
                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", }}>
                                            <strong>Status</strong>
                                        </th>
                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", }}>
                                            <strong>Classification</strong>
                                        </th>
                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", }}>
                                            <strong>Date Closed</strong>
                                        </th>
                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", }}>
                                            <strong>Amount Written Off</strong>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_.isEmpty(member) ? (("No loan history found")) : (
                                        // console.log(creditAnalysisResponse?.CreditDetails)
                                        creditAnalysisDetails?.map(x => (
                                            <tr>
                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", fontSize: "small", }}>
                                                    {member[0]?.customerName}
                                                </td>
                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", fontSize: "small", }}>
                                                    {x.AccountNumber}
                                                </td>
                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", fontSize: "small", }}>
                                                    {x.ProductName}
                                                </td>
                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", fontSize: "small", }}>
                                                    {/* {x.LoanAmount} */}
                                                    {textFormatService.formatCurrency(Number(x.LoanAmount), 2)}
                                                </td>
                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", fontSize: "small", }}>
                                                    {x.LoanTenor}
                                                </td>
                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", fontSize: "small", }}>
                                                    {textFormatService.formatShortTimeString(x.DisbursementDate)}
                                                </td>
                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", fontSize: "small", }}>
                                                    {textFormatService.formatShortTimeString(x.MaturationDate)}
                                                </td>
                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", fontSize: "small", }}>
                                                    {/* {x.LoanBalance} */}
                                                    {textFormatService.formatCurrency(Number(x.LoanBalance), 2)}
                                                </td>
                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", fontSize: "small", }}>
                                                    {/* {x.AmountOverdue} */}
                                                    {textFormatService.formatCurrency(Number(x.AmountOverdue), 2)}
                                                </td>
                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", fontSize: "small", }}>
                                                    {x.DaysPastDue}
                                                </td>
                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", fontSize: "small", }}>
                                                    {x.Status}
                                                </td>
                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", fontSize: "small", }}>
                                                    {x.Classification}
                                                </td>
                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", fontSize: "small", }}>
                                                    {x.DateClosed === null ? "" : textFormatService.formatTimeString(x.DateClosed)}
                                                </td>
                                                <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", fontSize: "small", }}>
                                                    {/* {x.AmountWrittenOff} */}
                                                    {textFormatService.formatCurrency(Number(x.AmountWrittenOff), 2)}
                                                </td>
                                            </tr>
                                        ))

                                    )}
                                </tbody>
                            </table>
                        </div>

                    </div>

                    <div className="card mt-3">
                        {_.isEmpty(item.appraisalActions.filter(x => x.systemProcessID == Number(3))) && _.isEmpty(item.appraisalActions.filter(x => x.action == "Processed")) ? (("")) : (
                            item.appraisalActions.filter(x => x.systemProcessID == Number(3)).map((actions) => (
                                actions?.processResponse?.result == "No loan history found" ? ("") : (
                                    <Accordion defaultActiveKey="0">
                                        <div>
                                            <Card>
                                                <ContextAwareToggle
                                                    eventKey={`0`}
                                                    header={<React.Fragment>
                                                        <i
                                                            className="fa fa-info-circle"
                                                            aria-hidden="true"
                                                            style={{ color: "#878a8a" }}
                                                        ></i>{" "}
                                                        Credit Analysis Result
                                                    </React.Fragment>}
                                                ></ContextAwareToggle>
                                                <Accordion.Collapse eventKey={`0`}>
                                                    <Card.Body>
                                                        <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <dl className="row">
                                                                        <DetailItem
                                                                            label="Status"
                                                                            labelSize={5}
                                                                            valueSize={3}
                                                                            value={creditAnalysisResponse?.Performance}
                                                                        />
                                                                    </dl>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </div>
                                    </Accordion>
                                ))
                            )
                        )}
                    </div>
                    <div className="form-row">
                        <div className="my-1 mx-2">
                            {groupMember.memberStatus === "Ineligible" ? (
                                <Button
                                    className="btn btn-sm btn-outline-primary my-2 mx-3"
                                    style={{ cursor: "pointer" }}
                                    type="button"
                                    // onClick={this.handleUploadDocument}
                                    onClick={(e) => { this.approveAppraisal(member[0]?.id) }}
                                >
                                    <React.Fragment>
                                        <i className="fas fa-check fa-lg mr-2" />{" "}
                                        Approve
                                    </React.Fragment>
                                </Button>
                            ) : ("")}
                        </div>
                    </div>

                </Modal.Body >
                <Modal.Footer>
                    <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={() => togglePatchModal(false, {}, {})}
                        disabled={this.state.submitting}
                    >
                        <i className="fas fa-times mr-3" />
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default GroupcreditAnalysisCheck;
