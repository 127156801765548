import http from "../httpService";

const recovaCreditAssessmentBaseAddress = process.env.REACT_APP_CREDITASSESSMENT_BASEADDRESS;
// const recovaCreditAssessmentBaseAddress = "https://localhost:44309/api/";

export function createBlackboxInstitution(payload) {
    return http.post(
        recovaCreditAssessmentBaseAddress +
        `blackbox/institution`,
        payload
    );
}

export function retrieveAllBlackboxInstitutions() {
    return http.get(
      recovaCreditAssessmentBaseAddress +
        `blackbox/institution`
    );
}

export function retrieveBlackboxInstitutionById(id) {
    return http.get(
      recovaCreditAssessmentBaseAddress +
        `blackbox/institution/${id}`
    );
}

export function retrieveBlackboxInstitutionByInstitutionCode(institutionCode) {
    return http.get(
      recovaCreditAssessmentBaseAddress +
        `blackbox/institution/getbycode/${institutionCode}`
    );
}

export function editBlackboxInstitution(id, payload) {
  return http.put(
    recovaCreditAssessmentBaseAddress +
      `blackbox/institution/${id}/edit`,
    payload
  );
}

export function activateBlackboxInstitution(id) {
    return http.patch(
      recovaCreditAssessmentBaseAddress + `blackbox/institution/${id}/activate`
    );
}

export function deactivateBlackboxInstitution(id) {
    return http.patch(
        recovaCreditAssessmentBaseAddress + `blackbox/institution/${id}/deactivate`
    );
}

export function addLoanproductsToBlackboxInstitution(institutionId, payload) {
  return http.put(
    recovaCreditAssessmentBaseAddress +
      `blackbox/institution/${institutionId}/add-loanproducts`,
    payload
  );
}

export function removeLoanproductsFromBlackboxInstitution(institutionId, payload) {
  // console.log("CQ Rule: ", creditQualificationRule);
  return http.put(
    recovaCreditAssessmentBaseAddress +
    `blackbox/institution/${institutionId}/remove-loanproducts`,
      payload
  );
}

// export function filterPagedCreditQualificationRules(
//   data: any,
//   pageSize: number,
//   pageNumber: number
// ) {
//   return http.post(
//     recovaCreditAssessmentBaseAddress +
//       `CreditQualificationRule/FilterCreditQualificationRules?pageSize=${pageSize}&pageNumber=${pageNumber}`,
//     data
//   );
// }

export default {
    activateBlackboxInstitution,
    addLoanproductsToBlackboxInstitution,
    editBlackboxInstitution,
    createBlackboxInstitution,
    deactivateBlackboxInstitution,
    removeLoanproductsFromBlackboxInstitution,
    retrieveAllBlackboxInstitutions,
    retrieveBlackboxInstitutionById,
    retrieveBlackboxInstitutionByInstitutionCode,
};
