import React, { Component, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import textFormatService, { filterList } from "../../../services/utility/textFormatService";
import DetailItemsTable from "../../../components/tableComponents/detailItemsTable";
import { createFilterData, mapEntityList } from "../../../services/utility/autoMapperService";
import TableFilterPane from "../../../components/tableComponents/tableFilterPane";
import ItemsTable, { filterFieldData } from "../../../components/tableComponents/itemsTable";
import BaseListComponent, { IFilterData } from "../../../components/BaseListComponent";

interface ICollectionAttemptsModalProps {
    showDetailsModal: boolean;
    toggleDetailsModal: any;
    // togglePatchModal: any;
    item: any;
    // reloadDetails: any;
}

interface ICollectionAttemptsModalState {
    item: any;
    collectionAttempts: any;
    collectionAttemptsTotalCount: number;
    collectionAttemptsCurrentPage: number;
    fetchingCollectionAttempts: boolean;
    firstLoad: boolean;
    ownUpdate: boolean;
//table properties
    data: IFilterData;
    pageSize: number;
    fetchingDetail: boolean;
    filterValueInput: filterFieldData;
}

class CollectionAttemptsList extends BaseListComponent<ICollectionAttemptsModalState> { }
class CollectionAttemptsModal extends Component<ICollectionAttemptsModalProps, ICollectionAttemptsModalState> {
    constructor(props: ICollectionAttemptsModalProps) {
        super(props);
        this.state = {
            item: {},
            collectionAttempts: [],
            collectionAttemptsTotalCount: 0,
            collectionAttemptsCurrentPage: 1,
            fetchingCollectionAttempts: false,
            data: createFilterData(["dateAttempted", "amountPaid"]) as IFilterData,
            pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
            fetchingDetail: false,
            filterValueInput: [
                { name: "dateAttempted", fieldType: "date", value: "" },
                { name: "amountPaid", fieldType: "text", value: "" }
            ],
            ownUpdate: false,
            firstLoad: true
        };
    }

    static getDerivedStateFromProps(nextProps: ICollectionAttemptsModalProps, prevState) {
        // console.log("current props: ", nextProps.item);
        if (prevState.ownUpdate) {
            return {
                ownUpdate: false,
            };
        }
        return {
            item: nextProps.item,
        };
        
        // if (nextProps.item && nextProps.item.id != prevState.Item?.id) {
        //     return { item: nextProps.item, firstLoad: true };
        // }
        // return null;
    }

    async componentDidMount() {
        try {
            if (this.props.item.repaymentDate) {
                // console.log(
                //   'about to fetch similar transactions...',
                //   this.props.item.id
                // )
                this.handleOnCollectionAttemptsPageChange(this.state.collectionAttemptsCurrentPage, this.state.pageSize);
            }
        } catch (error) {
            this.setState({ fetchingCollectionAttempts: false, ownUpdate: true });
        }
    }

    handleOnCollectionAttemptsPageChange = async (currentPage: number, pageSize: number, data?: IFilterData) => {
        currentPage = currentPage < 0 ? this.state.collectionAttemptsCurrentPage : currentPage;
        pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
        this.setState({ fetchingCollectionAttempts: true, ownUpdate: true });
        let dataClone = typeof data === "undefined" ? { ...this.state.data } : { ...data };
        try {
            // console.log("sched Reps",this.props.item.collectionAttempts)
            const collAttempts = filterList(this.props.item.collectionAttempts, dataClone);
            // console.log("filtered",collAttempts)

            const collectionAttempts: any = [];
            const totalLength = collAttempts.length;
            if(totalLength > 0){
                const initial = (currentPage - 1) * pageSize;
                for (let i = initial; i < initial + pageSize; i++) {
                    collectionAttempts.push(collAttempts[i]);
                    if (i === totalLength - 1) {
                        break;
                    }
                }
            }

            // console.log("sched Reps",collectionAttempts)

            this.setState({
                collectionAttempts: mapEntityList({
                    // entityList: collectionAttempts.data.item2,
                    entityList: collectionAttempts,
                    properties: [
                        { oldName: "dateCreated", newName: "dateAttempted" },
                        { oldName: "amountAttempted", newName: "amountAttempted (₦)" },
                        { oldName: "amountPaid", newName: "amountPaid (₦)" },
                        { oldName: "repaymentMethod", newName: "repaymentMethod" },
                    ],
                }),
                collectionAttemptsCurrentPage: currentPage,
                // totalSize: collectionAttempts.data.item1,
                collectionAttemptsTotalCount: totalLength,
                fetchingCollectionAttempts: false,
                firstLoad: false, 
                ownUpdate: true
            });
        } catch (ex) {
            //   console.log("caught exception", ex);
            if (ex.response && ex.response.status >= 400 && ex.response.status <= 499) {
                this.setState({ fetchingCollectionAttempts: false, firstLoad: false, ownUpdate: true });
            } else {
                // toast.error("Internal server error. Please contact the admin.");
                this.setState({ fetchingCollectionAttempts: false, firstLoad: false, ownUpdate: true });
            }
        }
    };
    
    getFilteredItems = (data: IFilterData): IFilterData => {
        let filteredItems: IFilterData = {};
        Object.keys(data).forEach((key) => {
            if (data[key].item2 !== "") {
                filteredItems[key] = data[key];
            }
        });
        return filteredItems;
    };
    handleOnExport = async (type: string) => { };

    render() {
        const {
            showDetailsModal,
            toggleDetailsModal,
            item,
        } = this.props;
        const { 
            pageSize,
            fetchingDetail,
            firstLoad, 
            collectionAttempts, 
            collectionAttemptsCurrentPage,
            collectionAttemptsTotalCount,
            fetchingCollectionAttempts
        } = this.state;
        if (_.isEmpty(item)) {
            return null;
        }
        if (firstLoad) {
            this.handleOnCollectionAttemptsPageChange(collectionAttemptsCurrentPage, pageSize);
        }
        // console.log(collectionAttempts)
        return (
            <Modal size="xl" backdrop="static" show={showDetailsModal} onHide={toggleDetailsModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {_.isEmpty(item) ? (
                            <Skeleton width={500} />
                        ) : (
                            <h5>
                                <i className="icon icon--columns s-18 mr-3" />
                                Schedule Repayment Details
                            </h5>
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card">
                        <div className="card-header clear-fix">
                            {_.isEmpty(item) ? (
                                <Skeleton width={200} />
                            ) : (
                                <h6 className="card-title float-left" style={{ marginBottom: "0px" }}>
                                    <b>General Information</b>
                                </h6>
                            )}
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    {_.isEmpty(item) ? (
                                        <Skeleton count={4} width={300} />
                                    ) : (
                                        <dl className="row">
                                            <DetailItem label="Repayment Method" value={item.repaymentMethod} labelSize={5} valueSize={7} />
                                            <DetailItem label="Repayment Amount" value={item.repaymentAmount} labelSize={5} valueSize={7} />
                                            <DetailItem label="Amount Paid" value={item.amountPaid} labelSize={5} valueSize={7} />
                                            <DetailItem label="Recovery Mode" value={item.recoveryMode} labelSize={5} valueSize={7} />
                                        </dl>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    {_.isEmpty(item) ? (
                                        <Skeleton count={4} width={300} />
                                    ) : (
                                        <dl className="row">
                                            <DetailItem label="Payment Status" value={item.paymentStatus} labelSize={5} valueSize={7} />
                                            <DetailItem
                                                label="Repayment Date"
                                                value={textFormatService.formatTimeString(item.repaymentDate)}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem label="Repayment Balance" value={item.repaymentBalance} labelSize={5} valueSize={7} />
                                            <DetailItem
                                                label="Date Created"
                                                value={textFormatService.formatTimeString(item.dateCreated)}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                        </dl>
                                    )}
                                </div>
                            </div>
                        </div>
                        <CollectionAttemptsList
                            pageChangeHandler={this.handleOnCollectionAttemptsPageChange}
                            fetchingDetail={fetchingDetail}
                            initialValues={{
                                data: this.state.data,
                                fetching: false,
                                showDetailsModal: false,
                                showEditModal: false,
                                showTableFilterModal: false,
                                showTrailModal: false,
                                tableItems: [],
                                filterValueInput: this.state.filterValueInput,
                                item: {},
                            }}
                            tableItems={collectionAttempts}
                            item={item}
                            DetailsModal={({}) => (
                                <Fragment>
                                </Fragment>
                            )}
                            ListPayLoad={({
                                data,
                                showTableFilterModal,
                                tableItems,
                                onToggleDetailsModal,
                                onToggleTableFilterModal,
                                onHandleFilterItemRemove,
                                filterValueInput,
                            }) => (
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="card no-b">
                                      <div className="card-body">
                                        <div className="card-title">
                                          <div id="list-filters" className="ml-4 mr-4">
                                            <TableFilterPane
                                              filteredItems={this.getFilteredItems(data)}
                                              handleFilterRemove={onHandleFilterItemRemove}
                                            />
                                          </div>
                                          <div id="list-table">
                                            <ItemsTable
                                                data={data}
                                                items={tableItems}
                                                filterValueInput={filterValueInput}
                                                showTableFiltermodal={showTableFilterModal}
                                                toggleTableFiltermodal={onToggleTableFilterModal}
                                                name="Collection Attempts"
                                                currentPage={collectionAttemptsCurrentPage}
                                                pageSize={pageSize}
                                                totalSize={collectionAttemptsTotalCount}
                                                fetching={fetchingCollectionAttempts}
                                                handlePageChange={this.handleOnCollectionAttemptsPageChange}
                                                onViewDetails={(item: any) => {
                                                    // this.fetchItemById(item.id)
                                                    // this.onToggleAmountModal();
                                                }}
                                                handleOnExport={this.handleOnExport}
                                                amountColumn={["amountDue (₦)", "amountAttempted (₦)"]}
                                                amountInNaira={true}
                                                timeColumn={["dateAttempted"]}
                                                disableViewDetails
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            )}
                        ></CollectionAttemptsList>
                        
                        {/* <DetailItemsTable
                            title="Collection Attempts"
                            item={item}
                            handleOnPageChange={this.handleOnCollectionAttemptsPageChange}
                            content={collectionAttempts}
                            contentCount={collectionAttemptsTotalCount}
                            page={collectionAttemptsCurrentPage}
                            fetching={fetchingCollectionAttempts}
                            colorColumn={[
                                { columnName: "amountPaid (₦)", textColor: "blue" },
                                { columnName: "amountAttempted (₦)", textColor: "black" },
                            ]}
                            amountColumn={["amountDue (₦)", "amountAttempted (₦)"]}
                            amountInNaira={true}
                            timeColumn={["repaymentDate"]}
                        /> */}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="outline-danger" onClick={toggleDetailsModal}>
                        <i className="fas fa-times mr-3" />
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default CollectionAttemptsModal;
