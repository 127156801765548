import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";

const recovaBankOneHandShakeBaseAddress = process.env.REACT_APP_RECOVA_BANKONE_HANDSHAKE_BASEADDRESS;

export function restructureLoan(loanReference: string, institutionCode: string) {
    var url = recovaBankOneHandShakeBaseAddress + `Repayment/RestructureLoan/${institutionCode}/${loanReference}`;
    return http.post(url, null, {
        url: url,
        method: "post"
    });
}

export default {
    restructureLoan
}
