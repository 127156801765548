import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import { ActionTypes } from "../../../enums/actionTypes";
import swal from "sweetalert";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import institutionsService from "../../../services/auth/institutionsService";
import { toast } from "react-toastify";
import textFormatService from "../../../services/utility/textFormatService";
import { MandateLimit } from "../../../enums/mandateLimits";
import { getCurrentUsersRoles } from "../../../services/auth/authService";
import AddSweepConfiguration from "../sweepConfig/addSweepConfiguration";
import AddWalletConfiguration from "../walletConfig/addWalletConfiguration";

interface IInstitutionDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  showTrail: any;
  currentUserRoles: any;
  reloadItem: any;
  item: any;
}

interface IInstitutionDetailState {
  item: any;
  updatingStatus: boolean;
  showAddSweepConfigModal: boolean;
  showAddWalletConfigModal: boolean;
}

class InstitutionDetail extends Component<
  IInstitutionDetailProps,
  IInstitutionDetailState
> {
  state = {
    item: {},
    updatingStatus: false,
    showAddSweepConfigModal: false,
    showAddWalletConfigModal: false,
  };

  static getDerivedStateFromProps(
    nextProps: IInstitutionDetailProps,
    prevState
  ) {
    // console.log("current props: ", nextProps.item);
    return {
      item: nextProps.item,
    };
  }

  onHandleEnableOrDisable = (
    e: any,
    actionType: ActionTypes,
    entityName: string
  ) => {
    this.setState({ updatingStatus: true });
    if (!actionType) {
      this.handleEnableOrDisableItem(this.props.item);
    } else {
      swal({
        title: "Confirm!",
        text: `Are you sure you want to proceed to ${ActionTypes[actionType]} this ${entityName}?`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode: true,
      }).then((resp) => {
        if (resp) {
          // console.log("cancelling action...");
          this.setState({ updatingStatus: false });
        } else {
          this.handleEnableOrDisableItem(this.state.item);
        }
      });
    }
  };

  handleEnableOrDisableItem = async (_item: any) => {
    try {
      this.setState({ updatingStatus: true });
      await activityTrailService.saveActivityTrail({
        actionType: `${
          _item.status === "Inactive" ? "Enable" : "Disable"
        } Institution`,
        description: `Clicked on ${
          _item.status === "Inactive" ? "Enable" : "Disable"
        } to ${
          _item.status === "Inactive" ? "Enable" : "Disable"
        } Institution ${_item.name}`,
      });
      switch (_item.status) {
        case "Inactive":
          await institutionsService.enableInstitution(_item.id);
          _item.status = "Active";
          break;
        case "Active":
          await institutionsService.disableInstitution(_item.id);
          _item.status = "Inactive";
          break;
        default:
          await institutionsService.enableInstitution(_item.id);
          _item.status = "Active";
          break;
      }
      toast.info(
        `Institution ${_item.name} ${
          _item.status === "Active" ? "Enabled" : "Disabled"
        } successfully! `,
        {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        }
      );
      this.setState({ updatingStatus: false, item: _item });
    } catch (ex) {
      this.setState({ updatingStatus: false, item: _item });
    }
  };

  toggleAddSweepConfigModal = (reloadDetails: boolean, item: any) => {
    if (reloadDetails) {
      this.props.reloadItem(item);
    }
    this.setState({
      showAddSweepConfigModal: !this.state.showAddSweepConfigModal,
    });
  };

  toggleAddWalletConfigModal = (reloadDetails: boolean, item: any) => {
    if (reloadDetails) {
      this.props.reloadItem(item);
    }
    this.setState({
      showAddWalletConfigModal: !this.state.showAddWalletConfigModal,
    });
  };

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      showTrail,
      currentUserRoles,
      item,
    } = this.props;
    const {
      updatingStatus,
      showAddSweepConfigModal,
      showAddWalletConfigModal,
    } = this.state;
    return (
      <React.Fragment>
        <AddSweepConfiguration
          showAddSweepConfigModal={showAddSweepConfigModal}
          toggleAddSweepConfigModal={this.toggleAddSweepConfigModal}
          institution={item}
        />
        <AddWalletConfiguration
          showAddWalletConfigModal={showAddWalletConfigModal}
          toggleAddWalletConfigModal={this.toggleAddWalletConfigModal}
          institution={item}
        />
        <Modal
          size="xl"
          backdrop="static"
          show={showDetailsModal}
          onHide={toggleDetailsModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {_.isEmpty(item) ? (
                <Skeleton width={500} />
              ) : (
                <h5>
                  <i className="icon icon-building-o s-18 mr-3" />
                  Institution Details -{" "}
                  <span className="font-weight-bold">{item.name}</span>
                </h5>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card">
              <div className="card-header clear-fix">
                {_.isEmpty(item) ? (
                  <Skeleton width={200} />
                ) : (
                  <h6
                    className="card-title float-left"
                    style={{ marginBottom: "0px" }}
                  >
                    <b>General Information</b>
                  </h6>
                )}
                <div className="float-right">
                  {_.isEmpty(item) ? (
                    <Skeleton width={100} />
                  ) : (
                    <span
                      className="badge btn-primary"
                      onClick={showTrail}
                      style={{
                        cursor: "pointer",
                        visibility: currentUserRoles.includes(
                          "viewaudittrailitems"
                        )
                          ? "visible"
                          : "hidden",
                      }}
                    >
                      View Trail
                    </span>
                  )}
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    {_.isEmpty(item) ? (
                      <Skeleton count={4} width={300} />
                    ) : (
                      <dl className="row">
                        <DetailItem label="Name" value={item.name} />
                        <DetailItem
                          label="Dispute Email"
                          value={item.disputeContactEmail}
                        />
                        <DetailItem
                          label="Category"
                          value={
                            (item ? item.category : "CommercialBank") ===
                            "CommercialBank" ? (
                              <span className="badge badge-default">
                                {item
                                  ? textFormatService.splitAtUpper(
                                      item.category
                                    )
                                  : ""}
                              </span>
                            ) : (
                              <span className="badge badge-warning">
                                {item
                                  ? textFormatService.splitAtUpper(
                                      item.category
                                    )
                                  : ""}
                              </span>
                            )
                          }
                        />
                        {item.category === "CommercialBank" ? (
                          <DetailItem label="CBN Code" value={item.cbnCode} />
                        ) : (
                          <DetailItem
                            label="External Institution Code"
                            value={item.externalInstitutionCode}
                          />
                        )}
                        <DetailItem
                          label="Mandate Bulk Upload Type"
                          value={textFormatService.splitAtUpper(
                            item.mandateBulkUploadType
                          )}
                        />
                      </dl>
                    )}
                  </div>
                  <div className="col-md-6">
                    {_.isEmpty(item) ? (
                      <Skeleton count={4} width={300} />
                    ) : (
                      <dl className="row">
                        <DetailItem label="Code" value={item.code} />
                        <DetailItem
                          label="Dispute PhoneNo."
                          value={item.disputeContactPhone}
                        />
                        <DetailItem
                          label="Mandate Limit"
                          value={MandateLimit[`${item.mandateLimit}`]}
                          // value={textFormatService.splitAtUpper(
                          //   item.mandateLimit
                          // )}
                        />
                        <DetailItem
                          label="Status"
                          value={
                            (item ? item.status : "Active") === "Active" ? (
                              <span className="badge badge-success">
                                {item ? item.status : ""}
                              </span>
                            ) : (
                              <span className="badge badge-danger">
                                {item ? item.status : ""}
                              </span>
                            )
                          }
                        />
                        <DetailItem
                          label="Service Offering"
                          value={
                            <span className="badge badge-light">
                              {item.serviceOfferingConfig?.name}
                            </span>
                          }
                        />
                        {!_.isEmpty(item.clearingBank) ? (
                          <DetailItem
                            label="Clearing Bank"
                            value={
                              <span className="badge badge-light">
                                {item.clearingBank.name}
                              </span>
                            }
                          />
                        ) : (
                          ""
                        )}
                      </dl>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <br />
            {_.isEmpty(item.sweepConfig) ? (
              ""
            ) : (
              <React.Fragment>
                <div className="card">
                  <div className="card-header clear-fix">
                    {_.isEmpty(item) ? (
                      <Skeleton width={200} />
                    ) : (
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        Sweep Configuration Details
                      </h6>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={8} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Sweep Status"
                              labelSize={7}
                              valueSize={5}
                              value={item.sweepConfig.status}
                            />
                            <DetailItem
                              label="Sweep Initiation Type"
                              labelSize={7}
                              valueSize={5}
                              value={item.sweepConfig.sweepInitiationType}
                            />
                            <DetailItem
                              label="Sweep Integration Code"
                              labelSize={7}
                              valueSize={5}
                              value={item.sweepConfig.integrationCode}
                            />
                            <DetailItem
                              label="Bank's Fee Income Account"
                              labelSize={7}
                              valueSize={5}
                              value={item.sweepConfig.feeIncomeAccount}
                            />
                            {item.category === "CommercialBank" ? (
                              ""
                            ) : (
                              <React.Fragment>
                                <DetailItem
                                  label="Recova Settlement Mirror Account"
                                  labelSize={7}
                                  valueSize={5}
                                  value={
                                    item.sweepConfig.settlementMirrorAccount
                                  }
                                />
                                <DetailItem
                                  label="Receivable Account"
                                  labelSize={7}
                                  valueSize={5}
                                  value={item.sweepConfig.receivableAccount}
                                />
                              </React.Fragment>
                            )}
                            <DetailItem
                              label="Recova Loan Collections Account"
                              labelSize={7}
                              valueSize={5}
                              value={item.sweepConfig.loanCollectionsAccount}
                            />
                            <DetailItem
                              label="Settlement Institution Code"
                              labelSize={7}
                              valueSize={5}
                              value={item.sweepConfig.settlementInstitutionCode}
                            />
                            <DetailItem
                              label="Recova Suspense Account"
                              labelSize={7}
                              valueSize={5}
                              value={item.sweepConfig.suspenseAccount}
                            />
                          </dl>
                        )}
                      </div>
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={7} width={300} />
                        ) : (
                          <dl className="row">
                            {item.category === "CommercialBank" ? (
                              <React.Fragment>
                                <DetailItem
                                  label="Sponsor Bank Commmission"
                                  labelSize={7}
                                  valueSize={5}
                                  value={
                                    item.sweepConfig.sponsorBankCommission +
                                    " %"
                                  }
                                />
                                <DetailItem
                                  label="NIP Bank Code"
                                  labelSize={7}
                                  valueSize={5}
                                  value={item.sweepConfig.nipBankCode}
                                />
                                {/* <DetailItem
                                  label="AppZone NIP Account Name"
                                  labelSize={7}
                                  valueSize={5}
                                  value={item.sweepConfig.appZoneNIPAccountName}
                                /> */}
                                <DetailItem
                                  label="AppZone Income Account No"
                                  labelSize={7}
                                  valueSize={5}
                                  value={item.sweepConfig.appZoneNIPAccountNo}
                                />
                                <DetailItem
                                  label="Lost Loan Fee Percentage"
                                  labelSize={7}
                                  valueSize={5}
                                  value={item.sweepConfig.lostLoanFeePercentage}
                                />
                              </React.Fragment>
                            ) : (
                              ""
                            )}

                            <DetailItem
                              label="Fee Charged Percentage"
                              labelSize={7}
                              valueSize={5}
                              value={item.sweepConfig.feeChargedPercentage}
                            />
                            <DetailItem
                              label="Debited Bank Commmission"
                              labelSize={7}
                              valueSize={5}
                              value={
                                item.sweepConfig.debitedBankCommission + " %"
                              }
                            />
                          </dl>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <br />
              </React.Fragment>
            )}
            <div className="card">
              <div className="card-header clear-fix">
                {_.isEmpty(item) ? (
                  <Skeleton width={200} />
                ) : (
                  <h6
                    className="card-title float-left"
                    style={{ marginBottom: "0px" }}
                  >
                    Admin User Details
                  </h6>
                )}
              </div>
              <div className="card-body">
                <div
                  className="card-content table-responsive"
                  style={{ overflowY: "scroll" }}
                >
                  <table className="table table-hover table-content table-sm table-bordered table-striped mb-0">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          style={{
                            width: "5px",
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>#</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            // width: "200px",
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Name</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            // width: "200px",
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Gender</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            // width: "200px",
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Email Address</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            // width: "200px",
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Phone Number</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.isEmpty(item) ? (
                        <tr>
                          <td
                            className="font-weight-normal font-italic text-truncate"
                            colSpan={5}
                            style={{ textAlign: "center", color: "#FE825E" }}
                          >
                            <i className="fas fa-spinner fa-spin"></i>
                          </td>
                        </tr>
                      ) : (
                        <React.Fragment>
                          {item.adminUsers.map((user) => (
                            <tr key={user.id}>
                              <td
                                style={{
                                  width: "5px",
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                  fontSize: "small",
                                }}
                              >
                                {item.adminUsers.indexOf(user) + 1}
                              </td>
                              <td
                                style={{
                                  // width: "5px",
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                  fontSize: "small",
                                }}
                              >
                                {user.lastName} {user.firstName}
                              </td>
                              <td
                                style={{
                                  // width: "5px",
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                  fontSize: "small",
                                }}
                              >
                                {user.gender}
                              </td>
                              <td
                                style={{
                                  // width: "5px",
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                  fontSize: "small",
                                }}
                              >
                                {user.email}
                              </td>
                              <td
                                style={{
                                  // width: "5px",
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                  fontSize: "small",
                                }}
                              >
                                {user.phoneNo}
                              </td>
                            </tr>
                          ))}
                        </React.Fragment>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <br />
            <div className="card">
              <div className="card-header clear-fix">
                {_.isEmpty(item) ? (
                  <Skeleton width={200} />
                ) : (
                  <h6
                    className="card-title float-left"
                    style={{ marginBottom: "0px" }}
                  >
                    Institution Settings
                  </h6>
                )}
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    {_.isEmpty(item) ? (
                      <Skeleton count={2} width={300} />
                    ) : (
                      <div>
                        {_.isEmpty(item.systemSettings) ? (
                          ""
                        ) : (
                          <React.Fragment>
                            <dl className="row">
                              <DetailItem
                                label="Enable Mandates By Default"
                                labelSize={7}
                                valueSize={5}
                                value={
                                  item.enableMandatesByDefault ? (
                                    <span className="badge badge-success">
                                      True
                                    </span>
                                  ) : (
                                    <span className="badge badge-danger">
                                      False
                                    </span>
                                  )
                                }
                              />
                              <DetailItem
                                label="Enable AD Verification"
                                labelSize={7}
                                valueSize={5}
                                value={
                                  item.enableAdVerification ? (
                                    <span className="badge badge-success">
                                      True
                                    </span>
                                  ) : (
                                    <span className="badge badge-danger">
                                      False
                                    </span>
                                  )
                                }
                              />
                              <DetailItem
                                label="Enable 2FA"
                                labelSize={7}
                                valueSize={5}
                                value={
                                  item.enable2FA ? (
                                    <span className="badge badge-success">
                                      True
                                    </span>
                                  ) : (
                                    <span className="badge badge-danger">
                                      False
                                    </span>
                                  )
                                }
                              />

                              <DetailItem
                                label="DMB"
                                labelSize={7}
                                valueSize={5}
                                value={
                                  item.dmb ? (
                                    <span className="badge badge-success">
                                      True
                                    </span>
                                  ) : (
                                    <span className="badge badge-danger">
                                      False
                                    </span>
                                  )
                                }
                              />
                              <DetailItem
                                label="Enable Hybrid Apprisal"
                                labelSize={7}
                                valueSize={5}
                                value={
                                  item.enableHybridAppraisal ? (
                                    <span className="badge badge-success">
                                      True
                                    </span>
                                  ) : (
                                    <span className="badge badge-danger">
                                      False
                                    </span>
                                  )
                                }
                              />
                            </dl>
                          </React.Fragment>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    {_.isEmpty(item) ? (
                      <Skeleton count={2} width={300} />
                    ) : (
                      <div>
                        {_.isEmpty(item.systemSettings) ? (
                          ""
                        ) : (
                          <React.Fragment>
                            <dl className="row">
                              <DetailItem
                                label="Max. Greylisted BVN"
                                labelSize={5}
                                valueSize={7}
                                value={item.greyListCap}
                              />
                              <DetailItem
                                label="Consent Approval Mode"
                                labelSize={5}
                                valueSize={7}
                                value={item.consentApprovalMode}
                              />
                              <DetailItem
                                label="Email Domain"
                                labelSize={5}
                                valueSize={7}
                                value={item.emailDomain}
                              />
                            </dl>
                          </React.Fragment>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {_.isEmpty(item) ? (
              <Skeleton width={100} count={2} height={30} />
            ) : (
              <React.Fragment>
                {item.status === "Inactive" &&
                  getCurrentUsersRoles().includes("enableinstitution") && (
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      disabled={updatingStatus}
                      onClick={(e) =>
                        this.onHandleEnableOrDisable(
                          e,
                          item.status === "Inactive"
                            ? ActionTypes.ENABLE
                            : ActionTypes.DISABLE,
                          "Institution"
                        )
                      }
                    >
                      <i className="far fa-edit mr-3" />
                      Enable
                    </Button>
                  )}
                {item.status === "Active" &&
                  getCurrentUsersRoles().includes("disableinstitution") && (
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      disabled={updatingStatus}
                      onClick={(e) =>
                        this.onHandleEnableOrDisable(
                          e,
                          item.status === "Inactive"
                            ? ActionTypes.ENABLE
                            : ActionTypes.DISABLE,
                          "Institution"
                        )
                      }
                    >
                      <i className="far fa-edit mr-3" />
                      Disable
                    </Button>
                  )}
                {_.isEmpty(item.sweepConfig) ? (
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => this.toggleAddSweepConfigModal(false, {})}
                  >
                    <i className="far fa-plus-square mr-3" />
                    Add Sweep Config
                  </Button>
                ) : (
                  ""
                )}
                <Button
                  size="sm"
                  variant="outline-primary"
                  onClick={() => this.toggleAddWalletConfigModal(false, {})}
                >
                  <i className="far fa-plus-square mr-3" />
                  Add Wallet Config
                </Button>
                {getCurrentUsersRoles().includes("editinstitution") && (
                  <Button
                    size="sm"
                    variant="primary"
                    disabled={updatingStatus}
                    onClick={toggleEditModal}
                  >
                    <i className="far fa-edit mr-3" />
                    Edit
                  </Button>
                )}
              </React.Fragment>
            )}
            <Button
              size="sm"
              variant="outline-danger"
              onClick={toggleDetailsModal}
            >
              <i className="fas fa-times mr-3" />
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default InstitutionDetail;
