import http from "../httpService";
import { IFilterData } from "../approval/approvalItemService";

const appraisalBaseAddress = process.env.REACT_APP_HYBRIDAPPRAISAL_BASEADDRESS;


export function ReadPDF(payload) {
    return http.post(
        appraisalBaseAddress + `Misc/ReadPDF`,
        payload
    );
}

export function ReadExcel(payload) {
    return http.post(
        appraisalBaseAddress + `Misc/ReadExcel`,
        payload
    );
}
export function GetProcessedBankStatement(LoanRequestId) {
    return http.post(
        appraisalBaseAddress + `Misc/GetProcessedBankStatement?LoanRequestId=${LoanRequestId}`
    );
}

export function DeleteProcessedBankStatement(id) {
    return http.get(
        appraisalBaseAddress + `Misc/DeleteProcessedBankStatementById?id=${id}`
    );
}

export function RetrieveProductDocumentData() {
    return http.get(
        appraisalBaseAddress + `Misc/RetrieveProductDocumentData`
    );
}

export function DownloadExcelInputTemplate() {
    return http.get(
        appraisalBaseAddress + `Misc/DownloadExcelInputTemplate`
    );
}

export default {
    ReadPDF,
    ReadExcel,
    GetProcessedBankStatement,
    DeleteProcessedBankStatement,
    RetrieveProductDocumentData,
    DownloadExcelInputTemplate
}