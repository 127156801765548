import React from "react";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import BaseFormComponent, { IBaseFormState } from "../../../../components/BaseFormComponent";
import { ActionTypes } from "../../../../enums/actionTypes";
import FormInputComponent from "../../../../components/formInputComponent";
import SelectInputComponent from "../../../../components/formSelectComponent";
import { splitAtUpper } from "../../../../services/utility/textFormatService";

interface ModifyIndicatorParamsFields {}

class ModifyIndicatorParamsForm extends BaseFormComponent<ModifyIndicatorParamsFields> {}

interface IModifyIndicatorParamsProps {
  item: any;
  allIndicators: any;
  showPatchModal: boolean;
  togglePatchModal: any;
}
interface IIndicatorParamData {
  paramName: string;
  paramType: 'Numeric'|'Boolean'|'Alphanumeric';
  paramValue: string;
}
interface IModifyIndicatorParamsState {
  ownUpdate: boolean;
  data: IIndicatorParamData[];
}

class ModifyIndicatorParams extends React.Component<
  IModifyIndicatorParamsProps,
  IBaseFormState & IModifyIndicatorParamsState
> {
  constructor(props: IModifyIndicatorParamsProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      ownUpdate: false,
      data: [],
      errors: {}
    };
  }

  static getDerivedStateFromProps(
    nextProps: IModifyIndicatorParamsProps,
    prevState: IModifyIndicatorParamsState
  ) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isEmpty(nextProps.item)) {
      return null;
    }
    const data: IIndicatorParamData[] = [];
    nextProps.allIndicators.filter(indicator => indicator.id == nextProps.item)[0].creditQualificationIndicatorParams.forEach(parameter => {
      data.push({ paramName: parameter.paramName, paramType: parameter.paramType, paramValue: parameter.paramValue });
    });
      // {
      //   "indicatorDataCollection": [
      //     {
      //       "indicatorId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      //       "paramData": [
      //         {
      //           "paramName": "string",
      //           "paramValue": "string",
      //           "paramType": "Numeric"
      //         }
      //       ]
      //     }
      //   ],
      // }
    return {
      data
    };
  }

  handleOnInputChange = (name, value) => {
    const data = [ ...this.state.data ];
    // console.log("val :: ", value);
    // console.log("data :: ", data);
    data.filter(indicator => indicator.paramName == name)[0].paramValue = value
    this.setState({ data, ownUpdate: true })
  }

  onFormSubmit(onReloadFieldsCB: any): boolean {
    try {
      const error: any = [];
      this.state.data.forEach(item => {
        if(_.isEmpty(item.paramValue)){
          error[item.paramName] = "This field is required!";
        }else if(item.paramType === "Numeric"){
          if(Number(item.paramValue) < 0){
            error[item.paramName] = splitAtUpper(item.paramName.split(".")[1]) + " cannot be negative!";
          }
        }
      })
      this.setState(
        {
          validated: true,
          ownUpdate: true,
          errors: error // creditAssessmentValidationService.validateEditLoanPricingConfigForm(fields),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(onReloadFieldsCB);
          }
        }
      );
      // this.setState({ validated: true, ownUpdate: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (onReloadFieldsCB: any) => {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      onReloadFieldsCB();
      // console.log("data :: ", this.state.data);
      this.setState({ submitting: false, ownUpdate: true }, () =>
        this.props.togglePatchModal(true, this.props.item, this.state.data)
      );
    } catch (error) {
      this.setState({ submitting: false, ownUpdate: true });
    }
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      ownUpdate: true
    });
  }

  render() {
    const { item, showPatchModal, togglePatchModal } = this.props;
    const { validated, submitting, errors, data } = this.state;

    if (_.isEmpty(item)) {
      return null;
    }

    return (
      <Modal
        size="lg"
        backdrop="static"
        show={showPatchModal}
        onHide={togglePatchModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-edit fa-sm cyan-text mr-3" />
              Edit Credit Qualification Indicator Parameter
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="form-row">
                {data.map((item) => (
                    item.paramType == 'Boolean'?(
                      <SelectInputComponent
                        key={item.paramName}
                        id={item.paramName}
                        name={item.paramName.split(".")[1]}
                        divClass={6}
                        items={[
                          {name: "True", value: "true"},
                          {name: "False", value: "false"}
                        ]}
                        value={item.paramValue}
                        required={true}
                        validated={validated}
                        errors={errors}
                        onChange={(id, value)=> this.handleOnInputChange(id, value)}
                      />
                    ):(
                      <FormInputComponent
                        key={item.paramName}
                        id={item.paramName}
                        name={item.paramName.split(".")[1]}
                        type={item.paramType=='Numeric' ? "number" : "text"}
                        placeholder=""
                        divClass={6}
                        value={item.paramValue?item.paramValue:""}
                        required={true}
                        validated={validated}
                        errors={errors}
                        onChange={(id, value)=> this.handleOnInputChange(id, value)}
                      />
                    )
                  )
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <ModifyIndicatorParamsForm
          initialValues={{  }}
          FormComponent={({
            fields: {  },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.UPDATE,
                      "Credit Qualification Indicator Parameter",
                      () => {
                        this.onFormSubmit(onReloadFields);
                      }
                    );
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => togglePatchModal(false, {}, {})}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></ModifyIndicatorParamsForm>
      </Modal>
    );
  }
}

export default ModifyIndicatorParams;
