import http from "../httpService";
import { IFilterData } from "../approval/approvalItemService";

const appraisalBaseAddress = process.env.REACT_APP_HYBRIDAPPRAISAL_BASEADDRESS;

export function saveAppraisalPlan(payload) {
  return http.post(
    appraisalBaseAddress + `AppraisalPlan/CreateAppraisalPlan`,
    payload
  );
}

export function updateAppraisalPlan(payload) {
  return http.put(
    appraisalBaseAddress + `AppraisalPlan/EditAppraisalPlan`,
    payload
  );
}

export function retrieveById(id) {
  return http.get(
    appraisalBaseAddress + `AppraisalPlan/RetrieveById/${id}`
  );
}

export function retrieveByInstitutionCategory(institutionCategory) {
  return http.get(
    appraisalBaseAddress + `AppraisalPlan/RetrieveByInstitutionCategory/${institutionCategory}`
  );
}

export function retrieveAll() {
  return http.get(
    appraisalBaseAddress + `AppraisalPlan/RetrieveAll`
  );
}

export function filterAppraisalPlan(
  payload: IFilterData,
  maxSize: number,
  pageNum: number
) {
  payload = { ...payload };
  return http.post(
    appraisalBaseAddress +
    `AppraisalPlan/FilterAppraisalPlan?pageNum=${pageNum}&maxSize=${maxSize}`,
    payload
  );
}

export default {
  saveAppraisalPlan,
  updateAppraisalPlan,
  retrieveById,
  retrieveByInstitutionCategory,
  retrieveAll,
  filterAppraisalPlan,
};
