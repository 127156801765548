import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";

const influxagentBaseAddress =
  process.env.REACT_APP_INFLUXAGENT_BASEADDRESS + `api/`;

export function retrieveInstitutionCollectionDue(institutionCode: string) {
  var url =
    influxagentBaseAddress +
    `CollectionAgent/retrieveInstitutionCollectionDue?institutionCode=${institutionCode}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveAllCollectionDue() {
  var url =
    influxagentBaseAddress +
    `CollectionAgent/retrieveAllCollectionDue`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}
export function retrieveAllCollectionDueStats(datepicked: string) {
    var url =
      influxagentBaseAddress +
      `CollectionAgent/retrieveAllCollectionDueStats?date=${datepicked}`;
  
    return http.get(url, {
      url: url,
      method: "get",
      headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
  }

  export function retrieveAmountCollectedStats(datepicked: string) {
    var url =
      influxagentBaseAddress +
      `CollectionAgent/retrieveAmountCollectedStats?date=${datepicked}`;
  
    return http.get(url, {
      url: url,
      method: "get",
      headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
  }

export function retrieveInstitutionAmountCollected(institutionCode: string) {
    var url =
      influxagentBaseAddress +
      `CollectionAgent/retrieveInstitutionAmountCollected?institutionCode=${institutionCode}`;
  
    return http.get(url, {
      url: url,
      method: "get",
      headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
  }
  
  export function retrieveAllAmountCollected() {
    var url =
      influxagentBaseAddress +
      `CollectionAgent/retrieveAllAmountCollected`;
  
    return http.get(url, {
      url: url,
      method: "get",
      headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
  }
  export function retrieveInstitutionCollectionChannel(institutionCode: string) {
    var url =
      influxagentBaseAddress +
      `CollectionAgent/retrieveInstitutionCollectionChannel?institutionCode=${institutionCode}`;
  
    return http.get(url, {
      url: url,
      method: "get",
      headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
  }
  
  export function retrieveAllCollectionChannel() {
    var url =
      influxagentBaseAddress +
      `CollectionAgent/retrieveAllCollectionChannel`;
  
    return http.get(url, {
      url: url,
      method: "get",
      headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
  }
  export function retrieveInstitutionRecoverySwitch(institutionCode: string) {
      var url =
        influxagentBaseAddress +
        `CollectionAgent/retrieveInstitutioRecoverySwitch?institutionCode=${institutionCode}`;
    
      return http.get(url, {
        url: url,
        method: "get",
        headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
      });
    }
    
    export function retrieveAllRecoverySwitch() {
      var url =
        influxagentBaseAddress +
        `CollectionAgent/retrieveAllRecoverySwitch`;
    
      return http.get(url, {
        url: url,
        method: "get",
        headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
      });
    }

export default {
    retrieveInstitutionCollectionDue,
    retrieveAllCollectionDue,
    retrieveAllCollectionDueStats,
    retrieveAmountCollectedStats,
    retrieveInstitutionAmountCollected,
    retrieveAllAmountCollected,
    retrieveInstitutionCollectionChannel,
    retrieveAllCollectionChannel,
    retrieveAllRecoverySwitch,
    retrieveInstitutionRecoverySwitch
};