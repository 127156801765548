import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { Approvable } from "./approvalComponents/approvable";

export interface IApprovalRuleViewProps {
  item: any;
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
}

export interface IApprovalRuleViewState {
  item: any;
  rules: any;
  maxLevel: number;
}
class ApprovalRuleView extends Component<
  IApprovalRuleViewProps,
  IApprovalRuleViewState
> {
  constructor(props: IApprovalRuleViewProps) {
    super(props);
    this.state = {
      item: this.props.item,
      rules: [],
      maxLevel: 1,
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log("next props: ", nextProps);
    // console.log("prev state: ", prevState);
    if (nextProps.item !== prevState.item) {
      let rules: any = [],
        maxLevel = 1;
      if (nextProps.item["id"]) {
        maxLevel = Math.max(...nextProps.item.rules.map((o) => o.level), 0);

        for (let i = 1; i <= maxLevel; i++) {
          const rulesSet = nextProps.item.rules.filter((x) => x.level === i);
          rulesSet.sort(function (a, b) {
            if (a.combinationNo < b.combinationNo) return -1;
            if (a.combinationNo > b.combinationNo) return 1;
            return 0;
          });
          //console.log("ruleset: ", rulesSet);
          rules.push(rulesSet);
        }
      }
      return { maxLevel: maxLevel, rules: rules, item: nextProps.item };
    } else return null;
  }

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
    } = this.props;

    const { item, rules } = this.state;

    //console.log("Rendering rules", this.props.rules, this.state.rules, rules);
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-tasks mr-3"></i>
              Approval Rule -{" "}
              <span className="font-weight-bold">{item.functionName}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Approvable
            rules={rules}
            item={item}
            // events={this.itemEvents}
            viewing={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="primary" onClick={toggleEditModal}>
            <i className="far fa-edit mr-3" />
            Configure
          </Button>
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ApprovalRuleView;
