import React, { Fragment } from "react";

export interface ThirdPartySettlements {
  thirdPartySweepService: string;
  accountNumber: string;
  bank: string;
  bankCode: string;
  amount: number;
  dateLogged: string;
  dateProcessed: string;
  settlementStatus: string;
  transactionReference: string;
}
export interface IThirdPartySettlementsProps {
  items: ThirdPartySettlements[];
}

const ThirdPartySettlementItems: React.FC<IThirdPartySettlementsProps> = ({
  items,
}) => {
  if (items.length > 0) {
    return (
      <Fragment>
        <div
          className="card-header card-footer"
          style={{ padding: "0px", height: "37px" }}
        >
          <h6
            className="card-title float-left"
            style={{ marginBottom: "5px", paddingTop: "5px" }}
          >
            <b>ThirdParty Settlements</b>
          </h6>
          {/* <nav aria-label="Page navigation example clearfix">
            <div className="float-left" style={{ paddingTop: "5px" }}>
              ThirdParty Settlements
            </div>
          </nav> */}
        </div>
        <div className="card-body">
          <div
            className="card-content table-responsive text-nowrap"
            style={{ height: "300px", overflowY: "scroll" }}
          >
            <table className="table table-hover table-content table-sm table-bordered table-striped mb-0">
              <thead>
                <tr>
                  <th
                    scope="col"
                    style={{
                      width: "5px",
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                    }}
                  >
                    <strong>#</strong>
                  </th>
                  <th
                    className="text-truncate"
                    scope="col"
                    style={{
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                      fontWeight: 500,
                    }}
                  >
                    <strong>Third Party</strong>
                  </th>
                  <th
                    className="text-truncate"
                    scope="col"
                    style={{
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                      fontWeight: 500,
                    }}
                  >
                    <strong>Account Number</strong>
                  </th>
                  <th
                    className="text-truncate"
                    scope="col"
                    style={{
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                      fontWeight: 500,
                    }}
                  >
                    <strong>Bank</strong>
                  </th>
                  <th
                    className="text-truncate"
                    scope="col"
                    style={{
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                      fontWeight: 500,
                    }}
                  >
                    <strong>Amount</strong>
                  </th>
                  <th
                    className="text-truncate"
                    scope="col"
                    style={{
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                      fontWeight: 500,
                    }}
                  >
                    <strong>Status</strong>
                  </th>
                  <th
                    className="text-truncate"
                    scope="col"
                    style={{
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                      fontWeight: 500,
                    }}
                  >
                    <strong>Date Processed</strong>
                  </th>
                  <th
                    className="text-truncate"
                    scope="col"
                    style={{
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                      fontWeight: 500,
                    }}
                  >
                    <strong>Transaction Reference</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {items.map((item) => (
                  <>
                    <tr>
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                        }}
                      >
                        {items.indexOf(item) + 1}
                      </td>
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                          fontWeight: 400,
                          cursor: "pointer",
                        }}
                      >
                        {item.thirdPartySweepService}
                      </td>
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                          fontWeight: 400,
                          cursor: "pointer",
                        }}
                      >
                        {item.accountNumber}
                      </td>
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                          fontWeight: 400,
                          cursor: "pointer",
                        }}
                      >
                        {item.bank}
                      </td>
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                          fontWeight: 400,
                          cursor: "pointer",
                        }}
                      >
                        {item.amount}
                      </td>
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                          fontWeight: 400,
                          cursor: "pointer",
                        }}
                      >
                        {item.settlementStatus}
                      </td>
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                          fontWeight: 400,
                          cursor: "pointer",
                        }}
                      >
                        {item.dateProcessed}
                      </td>
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                          fontWeight: 400,
                          cursor: "pointer",
                        }}
                      >
                        {item.transactionReference}
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    );
  } else {
    return <></>;
  }
};

export default ThirdPartySettlementItems;
