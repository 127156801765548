import React, { Fragment, Component } from "react";
import _, { values } from "lodash";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../../../../components/detailItem";
import PersonalDetailsModal from "./personalDetailsModal";
import { group } from "console";

interface PersonalDetailsProps {
    values: any;
    handleChange: any;
    handleBlur: any;
}

interface PersonalDetailsState {
    item: any;
    groupMembers: any;
    showPersonalDetails: boolean;
}

class GroupPersonalDetails extends Component<PersonalDetailsProps, PersonalDetailsState> {
    constructor(props: PersonalDetailsProps) {
        super(props);
        this.state = {
            item: {},
            groupMembers: [],
            showPersonalDetails: false
        };
    }

    static getDerivedStateFromProps(nextProps: PersonalDetailsProps, prevState) {
        return {
            item: nextProps.values,
        };
    }

    fetchItemById = (id) => {
        var members = this.props.values.solidarityGroups.filter(x => x.id === id)[0]?.members;
        this.setState({ groupMembers: { id: id, members: members } });
    }

    toggleShowPersonalDetailsModal = () => {
        this.setState({ showPersonalDetails: !this.state.showPersonalDetails });
    };

    render() {
        const { values } = this.props;
        const { groupMembers } = this.state;
        // console.log("in here", values)
        // console.log("in here j", values)
        // console.log("in here j", values.ageOfAccount ? 144: 12)
        // console.log("age of acct", values.ageOfAccount ? values.ageOfAccount: getAgeInMonthsFromPastDate(values.repaymentAccountCreationDate).toString())

        return (
            <Fragment>
                <div className="card">
                    <div className="card-header clear-fix">
                        {_.isEmpty(values) ? (
                            <Skeleton width={200} />
                        ) : (
                            <h6
                                className="card-title float-left"
                                style={{ marginBottom: "0px" }}
                            >
                                <b>Solidarity Groups</b>
                            </h6>
                        )}
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                {_.isEmpty(values) ? (
                                    <Skeleton count={7} width={300} />
                                ) : (
                                    values?.solidarityGroups?.map((groups) => (
                                        <dl className="row" key={groups.id}>
                                            <button
                                                key={groups.id}
                                                className="btn btn-sm btn-outline-primary my-2 mx-3"
                                                style={{ cursor: "pointer" }}
                                                type="button"
                                                // onClick={this.handleUploadDocument}
                                                onClick={(e) => {
                                                    this.fetchItemById(groups.id)
                                                    this.toggleShowPersonalDetailsModal();
                                                }}
                                            >
                                                <React.Fragment>
                                                    <i className="fas fa-users fa-lg mr-2" />{" "}
                                                    {groups.solidarityGroupName}
                                                </React.Fragment>
                                            </button>
                                        </dl>
                                    ))
                                )}
                            </div>
                            <div className="col-md-6"></div>
                        </div>
                    </div>
                    <PersonalDetailsModal
                        item={groupMembers}
                        showPatchModal={this.state.showPersonalDetails}
                        togglePatchModal={this.toggleShowPersonalDetailsModal} />
                </div>
            </Fragment>
        );
    }
}

export default GroupPersonalDetails;