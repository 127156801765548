import http from "../httpService";

const recovaCreditAssessmentBaseAddress = process.env.REACT_APP_CREDITASSESSMENT_BASEADDRESS;
// const recovaCreditAssessmentBaseAddress = "https://localhost:44309/api/";

export function createScoreIndicator(payload) {
  return http.post(
    recovaCreditAssessmentBaseAddress +
    `ScoreIndicator`,
    payload
  );
}

export function retrieveAllScoreIndicators() {
  return http.get(
    recovaCreditAssessmentBaseAddress + `ScoreIndicator`
  );
}

export function retrieveScoreIndicatorById(id) {
  return http.get(
    recovaCreditAssessmentBaseAddress + `ScoreIndicator/${id}`
  );
}

export function retrieveScoreIndicatorByInstitutionCode(institutionCode) {
  return http.get(
    recovaCreditAssessmentBaseAddress + `ScoreIndicator/institutions/${institutionCode}`
  );
}

export function editScoreIndicator(id, payload) {
  return http.put(
    recovaCreditAssessmentBaseAddress + `ScoreIndicator/${id}`,
    payload
  );
}

export function deleteScoreIndicator(id) {
  return http.delete(
    recovaCreditAssessmentBaseAddress + `ScoreIndicator/${id}`
  );
}

export function addScoreIndicatorsToInstitution(institutionCode, indicators) {
  return http.patch(
    recovaCreditAssessmentBaseAddress + `ScoreIndicator/institutions/${institutionCode}/add`,
    indicators
  );
}

export function removeScoreIndicatorsFromInstitution(institutionCode, indicators) {
  return http.patch(
    recovaCreditAssessmentBaseAddress + `ScoreIndicator/institutions/${institutionCode}/remove`,
    indicators
  );
}

export function addMetricsToScoreIndicator(id, metrics) {
  return http.patch(
    recovaCreditAssessmentBaseAddress + `ScoreIndicator/${id}/indicatoroptions/add`,
    metrics
  );
}

export function removeMetricsFromScoreIndicator(id, metrics) {
  return http.patch(
    recovaCreditAssessmentBaseAddress + `ScoreIndicator/${id}/indicatoroptions/remove`,
    metrics
  );
}

export function addRangeMetricsToInstitution(id, institutionCode, metrics) {
  return http.patch(
    recovaCreditAssessmentBaseAddress + `ScoreIndicator/institutions/${institutionCode}/scoremetrics/range/add/${id}`,
    metrics
  );
}

export function retrieveScoreIndicatorByIdAndInstitutionCode(id, institutionCode) {
  return http.get(
    recovaCreditAssessmentBaseAddress + `ScoreIndicator/${id}/institution/${institutionCode}`,
  );
}

export default {
  createScoreIndicator,
  retrieveAllScoreIndicators,
  retrieveScoreIndicatorById,
  retrieveScoreIndicatorByInstitutionCode,
  editScoreIndicator,
  deleteScoreIndicator,
  addScoreIndicatorsToInstitution,
  removeScoreIndicatorsFromInstitution,
  addMetricsToScoreIndicator,
  removeMetricsFromScoreIndicator,
  addRangeMetricsToInstitution,
  retrieveScoreIndicatorByIdAndInstitutionCode
};
