import React, { Component, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button, Accordion, Card } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import { getCurrentUserSession, getCurrentUsersRoles } from "../../../services/auth/authService";
import { ContextAwareToggle } from "./editProductConfiguration";
import { decodeToken } from "react-jwt";
import appraisalInstitutionService from "../../../services/hybridAppraisal/appraisalInstitutionService";
import { CreditPolicyValueType } from "../../../enums/creditPolicyValueType";
import { CreditPolicyUseCase } from "../../../enums/creditPolicyUseCase";
import textFormatService, { generateGUID } from "../../../services/utility/textFormatService";

interface IProductConfigurationDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  item: any;
}

interface IProductConfigurationDetailState {
  item: any;
  updatingStatus: boolean;
  // creditPolicyParameters: any;
  // ownUpdate: boolean;
  // fetchingRequiredItems: boolean;
}

class ProductConfigurationDetail extends Component<
  IProductConfigurationDetailProps,
  IProductConfigurationDetailState
> {
  // _isMounted = false;
  state = {
    item: {},
    updatingStatus: false,
    // creditPolicyParameters: [],
    // ownUpdate: false,
    // fetchingRequiredItems: false,
  };

  static getDerivedStateFromProps(nextProps: IProductConfigurationDetailProps, prevState) {
    // if (prevState.ownUpdate) {
    //   return {
    //     ownUpdate: false,
    //   };
    // }
    if (_.isEmpty(nextProps.item)) {
      return null;
    }
    return {
      item: nextProps.item,
    }
  }

  // async componentDidMount() {
  //   this._isMounted = true;
  //   try {
  //     if (this._isMounted) {
  //       this.setState({ fetchingRequiredItems: true, ownUpdate: true });
  //     }
  //     const decodedToken = decodeToken(getCurrentUserSession().token || "");
  //     const response = await Promise.allSettled([
  //       appraisalInstitutionService.retrieveByInstitutionCode(decodedToken?.["InstitutionCode"])
  //     ])
  //     const creditPolicyParameters = response[0].status == "fulfilled" ? response[0].value.data.creditPolicyParameters : [];
  //     if (this._isMounted) {
  //       this.setState({
  //         creditPolicyParameters,
  //         fetchingRequiredItems: false,
  //         ownUpdate: true
  //       });
  //     }
  //   } catch (ex) {
  //     // if (ex.response && ex.response.status === 404) {
  //     //   errors.response = "Internal server error.";
  //     //   this.setState({ ownUpdate: true });
  //     // }
  //   }
  // }

  // componentWillUnmount() {
  //   this._isMounted = false;
  // }

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      item,
    } = this.props;
    const { updatingStatus } = this.state;

    console.log("product Item :: ", item)

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="icon icon--columns s-18 mr-3" />
                Product Configuration Details -{" "}
                <span className="font-weight-bold">{item.name}</span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Product Name"
                        value={item.name}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Tenure In Days"
                        value={item.tenure}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Minimum Amount"
                        value={item.minimumAmount}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Interest Rate"
                        value={item.interestRate}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Score Card"
                        value={item.scoreCardName}
                        labelSize={5}
                        valueSize={7}
                      />
                      {/* {item.hasGuarantor ? (
                        <DetailItem
                          label="Number of Guarantors"
                          value={item.numberOfGuarantors}
                          labelSize={5}
                          valueSize={7}
                        />
                      ) : (
                        ""
                      )} */}
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Product Code"
                        value={item.productCode}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Moratorium"
                        value={item.moratorium}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Maximum Amount"
                        value={item.maximumAmount}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Product Category"
                        value={item.productCategory}
                        labelSize={5}
                        valueSize={7}
                      />
                      {/* <DetailItem
                        label="Require Guarantor"
                        value={
                          (item ? item.hasGuarantor : true) === true ? (
                            <span className="badge badge-success">
                              {item ? "True" : ""}
                            </span>
                          ) : (
                            <span className="badge badge-danger">
                              {item ? "False" : ""}
                            </span>
                          )
                        }
                        labelSize={5}
                        valueSize={7}
                      /> */}
                    </dl>
                  )}
                </div>
              </div>
            </div>
            <Accordion defaultActiveKey="0">
              <div>
                <Card>
                  <ContextAwareToggle
                    eventKey={`0`}
                    isHeader={true}
                    header={
                      <React.Fragment>
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                          style={{ color: "#878a8a" }}
                        ></i>{" "}
                        Pricing Config
                      </React.Fragment>
                    }
                  ></ContextAwareToggle>
                  <Accordion.Collapse eventKey={`0`}>
                    <Card.Body>
                      <div className=" form-row col-md-12">
                        <div
                          className="table-responsive text-nowrap"
                          style={{
                            maxHeight: "150px",
                            overflowY: "scroll",
                          }}
                        >
                          <table className="table table-hover table-content table-sm table-striped mb-0">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>#</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Fee Name</strong>
                                </th>

                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Minimum Amount</strong>
                                </th>

                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Maximum Amount</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Minimum Tenure</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Maximum Tenure</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Fee Type</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Processing Fee</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Interest</strong>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {item.productPricing?.map((item_) => (
                                <tr key={item.productPricing?.indexOf(item_)}>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item.productPricing?.indexOf(item_) + 1}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item_.name}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {textFormatService.formatCurrency(Number(item_.minimumAmount), 2)}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {textFormatService.formatCurrency(Number(item_.maximumAmount), 2)}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item_.minimumTenure}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item_.maximumTenure}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item_.feeType}
                                  </td>

                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item_.feeType === "Percentage" ? item_.processingFee : textFormatService.formatCurrency(Number(item_.processingFee), 2)}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item_.interest}
                                  </td>

                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </div>
            </Accordion>
            <Accordion defaultActiveKey="0">
              <div>
                <Card>
                  <ContextAwareToggle
                    eventKey={`0`}
                    isHeader={true}
                    header={
                      <React.Fragment>
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                          style={{ color: "#878a8a" }}
                        ></i>{" "}
                        Pre-Approval Documents
                      </React.Fragment>
                    }
                  ></ContextAwareToggle>
                  <Accordion.Collapse eventKey={`0`}>
                    <Card.Body>
                      <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                        <div className="row">
                          <div className="col-md-12">
                            {_.isEmpty(item.productDocuments) ? (
                              <div style={{ textAlign: "center", color: "#FE825E" }}>
                                No Document Collection has been assigned to loan product
                              </div>
                            ) : (
                              <Fragment>
                                {item.productDocuments.filter(x => x.type === "pre").map(
                                  (doc) => (
                                    <div key={doc.name} style={{ marginTop: '3px' }}>
                                      <div
                                        key={doc.name}
                                        className="custom-control custom-checkbox"
                                      >
                                        <input
                                          id={doc.name}
                                          name={doc.name}
                                          className="custom-control-input"
                                          type="checkbox"
                                          checked={true}
                                          disabled={true}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={doc.name}
                                        >
                                          {doc.name}
                                        </label>
                                      </div>
                                    </div>
                                  )
                                )}
                              </Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </div>
            </Accordion>

            <Accordion defaultActiveKey="0">
              <div>
                <Card>
                  <ContextAwareToggle
                    eventKey={`0`}
                    isHeader={true}
                    header={
                      <React.Fragment>
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                          style={{ color: "#878a8a" }}
                        ></i>{" "}
                        Post-Approval Documents
                      </React.Fragment>
                    }
                  ></ContextAwareToggle>
                  <Accordion.Collapse eventKey={`0`}>
                    <Card.Body>
                      <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                        <div className="row">
                          <div className="col-md-12">
                            {_.isEmpty(item.productDocuments) ? (
                              <div style={{ textAlign: "center", color: "#FE825E" }}>
                                No Document Collection has been assigned to loan product
                              </div>
                            ) : (
                              <Fragment>
                                {item.productDocuments.filter(x => x.type === "post").map(
                                  (doc) => (
                                    <div key={doc.name} style={{ marginTop: '3px' }}>
                                      <div
                                        key={doc.name}
                                        className="custom-control custom-checkbox"
                                      >
                                        <input
                                          id={doc.name}
                                          name={doc.name}
                                          className="custom-control-input"
                                          type="checkbox"
                                          checked={true}
                                          disabled={true}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={doc.name}
                                        >
                                          {doc.name}
                                        </label>
                                      </div>
                                    </div>
                                  )
                                )}
                              </Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </div>
            </Accordion>
            <Accordion defaultActiveKey="0">
              <div>
                <Card>
                  <ContextAwareToggle
                    eventKey={`0`}
                    isHeader={true}
                    header={
                      <React.Fragment>
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                          style={{ color: "#878a8a" }}
                        ></i>{" "}
                        Credit Policy Criteria
                      </React.Fragment>
                    }
                  ></ContextAwareToggle>
                  <Accordion.Collapse eventKey={`0`}>
                    <Card.Body>
                      <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                        <div className="row">
                          <div className="col-md-12">
                            {_.isEmpty(item.creditPolicyCriteria) ? (
                              <div style={{ textAlign: "center", color: "#FE825E" }}>
                                No Credit Policy has been assigned to loan product
                              </div>
                            ) : (
                              <Fragment>
                                {item.creditPolicyCriteria.map(
                                  (policy) => (
                                    <Accordion key={policy.creditPolicyParameterId}>
                                      <ContextAwareToggle
                                        eventKey={`0`}
                                        isHeader={false}
                                        header={
                                          <div
                                            className="custom-control custom-checkbox"
                                            style={{ display: 'inline-block' }}
                                            key={policy.creditPolicyParameterId}
                                          >
                                            <input
                                              id={policy.creditPolicyParameterId}
                                              name={policy.creditPolicyParameterId}
                                              className="custom-control-input"
                                              type="checkbox"
                                              checked={true}
                                              disabled={true}
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor={policy.creditPolicyParameterId}
                                            >
                                              {
                                                item.institution.creditPolicyParameters
                                                  .find(param => param.id === policy.creditPolicyParameterId).name
                                              }
                                            </label>
                                          </div>
                                        }
                                      ></ContextAwareToggle>
                                      <Accordion.Collapse eventKey={`0`}>
                                        <Card.Body style={{ padding: "5px", backgroundColor: '#e4f4fc' }}>
                                          <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                            <div className="row">
                                              <div className="col-md-1">
                                              </div>
                                              <div className="col-md-8">
                                                <dl className="row" style={{ marginBottom: "0px" }} key={policy.creditPolicyParameterId}>
                                                  {(() => {
                                                    switch (item.institution.creditPolicyParameters
                                                      .find(param => param.id === policy.creditPolicyParameterId).creditPolicyValueType) {
                                                      case CreditPolicyValueType[CreditPolicyValueType.MaxSingleInput]:
                                                        return (
                                                          <DetailItem
                                                            label={item.name}
                                                            value={item.parameter.maxSingleInputValue}
                                                            labelSize={5}
                                                            valueSize={7}
                                                          />
                                                        )
                                                      case CreditPolicyValueType[CreditPolicyValueType.MinSingleInput]:
                                                        return (
                                                          <DetailItem
                                                            label={item.name}
                                                            value={item.minSingleInputValue}
                                                            labelSize={5}
                                                            valueSize={7}
                                                          />
                                                        )
                                                      case CreditPolicyValueType[CreditPolicyValueType.DropDownOptions]:
                                                        return (
                                                          <div className="form-row">
                                                            {policy.creditPolicyUseCase === CreditPolicyUseCase[CreditPolicyUseCase.ScoringIndicator] ? (
                                                              (policy.dropDownOptions).map((option) => (
                                                                <DetailItem
                                                                  key={generateGUID()}
                                                                  label={option.name}
                                                                  value={option.score}
                                                                  labelSize={5}
                                                                  valueSize={7}
                                                                />
                                                              ))
                                                            ) : (
                                                              (policy.dropDownOptions).map((option) => (
                                                                <DetailItem
                                                                  key={generateGUID()}
                                                                  label={option.name}
                                                                  value={(option.passCriteria).toString()}
                                                                  labelSize={5}
                                                                  valueSize={7}
                                                                />
                                                              ))
                                                            )}
                                                          </div>
                                                        )
                                                      // case CreditPolicyValueType[CreditPolicyValueType.Range]:
                                                      //   return (
                                                      //     <div className="form-row">
                                                      //       {policy.creditPolicyUseCase === CreditPolicyUseCase[CreditPolicyUseCase.ScoringIndicator] ? (
                                                      //         (policy.rangeOptions).map((option) => (
                                                      //           <DetailItem
                                                      //             key={option.minimum + " - " + option.maximum}
                                                      //             label={option.minimum + " - " + option.maximum + " " + item.valueTypeQuantifier}
                                                      //             value={option.score}
                                                      //             labelSize={5}
                                                      //             valueSize={7}
                                                      //           />
                                                      //         ))
                                                      //       ) : (
                                                      //         (policy.rangeOptions).map((option) => (
                                                      //           <DetailItem
                                                      //             key={option.minimum + " - " + option.maximum}
                                                      //             label={option.minimum + " - " + option.maximum + " " + item.valueTypeQuantifier} value={(option.passCriteria).toString()}
                                                      //             labelSize={5}
                                                      //             valueSize={7}
                                                      //           />
                                                      //         ))
                                                      //       )}
                                                      //     </div>
                                                      //   )
                                                      default:
                                                        return null
                                                    }
                                                  })()}
                                                </dl>
                                              </div>
                                            </div>
                                          </div>
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Accordion>
                                  )
                                )}
                              </Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </div>
            </Accordion>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
              {getCurrentUsersRoles().includes("editproductconfiguration") && (
                <Button
                  size="sm"
                  variant="primary"
                  disabled={updatingStatus}
                  onClick={toggleEditModal}
                >
                  <i className="far fa-edit mr-3" />
                  Edit
                </Button>
              )}
            </React.Fragment>
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ProductConfigurationDetail;
