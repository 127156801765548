import React, { Component } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";

export interface ISaveBranchApprovalDetailProps {
  item: any;
}

export interface ISaveBranchApprovalDetailState {
  item: any;
}

class SaveBranchApprovalDetail extends Component<
  ISaveBranchApprovalDetailProps,
  ISaveBranchApprovalDetailState
> {
  constructor(props: ISaveBranchApprovalDetailProps) {
    super(props);
    this.state = {
      item: {},
    };
  }

  static getDerivedStateFromProps(
    nextProps: ISaveBranchApprovalDetailProps,
    prevState
  ) {
    if (nextProps.item) {
      return { item: JSON.parse(nextProps.item) };
    }
    return null;
  }

  render() {
    const { item } = this.state;
    return (
      <React.Fragment>
        <div className="text-center">
          <h5
            className="font-weight-normal"
            style={{ marginTop: "0.5rem", display: "inline" }}
          >
            Branch Details
          </h5>
        </div>
        <hr style={{ marginTop: "0rem" }} />
        <div
          className="table-responsive text-nowrap"
          style={{ height: "250px", overflowY: "scroll", overflowX: "hidden" }}
        >
          <table className="table table-hover table-content table-sm">
            <tbody>
              <tr>
                <td
                  className="font-weight-normal"
                  style={{ textAlign: "left" }}
                >
                  <b>Status: </b>
                </td>
                <td
                  className="font-weight-light"
                  colSpan={2}
                  style={{ textAlign: "center" }}
                >
                  {_.isEmpty(item) ? (
                    <Skeleton width={150} height={10} />
                  ) : (
                    "Active"
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="font-weight-normal"
                  style={{ textAlign: "left" }}
                >
                  <b>Name: </b>
                </td>
                <td
                  className="font-weight-light"
                  colSpan={2}
                  style={{ textAlign: "center" }}
                >
                  {_.isEmpty(item) ? (
                    <Skeleton width={150} height={10} />
                  ) : (
                    item.Name
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="font-weight-normal"
                  style={{ textAlign: "left" }}
                >
                  <b>Description: </b>
                </td>
                <td
                  className="font-weight-light"
                  colSpan={2}
                  style={{ textAlign: "center" }}
                >
                  {_.isEmpty(item) ? (
                    <Skeleton width={150} height={10} />
                  ) : (
                    item.Description
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="font-weight-normal"
                  style={{ textAlign: "left" }}
                >
                  <b>Address: </b>
                </td>
                <td
                  className="font-weight-light"
                  colSpan={2}
                  style={{ textAlign: "center" }}
                >
                  {_.isEmpty(item) ? (
                    <Skeleton width={150} height={10} />
                  ) : (
                    item.Address
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="font-weight-normal"
                  style={{ textAlign: "left" }}
                >
                  <b>Code: </b>
                </td>
                <td
                  className="font-weight-light"
                  colSpan={2}
                  style={{ textAlign: "center" }}
                >
                  {_.isEmpty(item) ? (
                    <Skeleton width={150} height={10} />
                  ) : (
                    item.Code
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default SaveBranchApprovalDetail;
