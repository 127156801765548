import React from "react";
import _ from "lodash";
import { firstCharToUpper, splitAtUpper } from "./textFormatService";

export function createFilterData(headers) {
  let filterData = {};
  headers.forEach((header) => {
    filterData[header] = {
      item1: "like",
      item2: "",
    };
  });
  return filterData;
}

export function mapEntity(entity, properties) {
  //this method has not been tested yet
  var mapping = {};
  properties.forEach((property) => {
    mapping[property] = entity[property];
  });
  return mapping;
}

export function mapEntityList({ entityList, properties }) {
  properties.splice(0, 0, { oldName: "id", newName: "id" });
  var mapping = [];
  entityList.forEach((entity) => {
    var map = {};
    properties.forEach((property) => {
      if (_.has(property, "isBoolean")) {
        switch (entity[property.oldName].toString()) {
          case "false":
            map[property.newName] = <i className="icon-close red-text"></i>;
            break;
          case "true":
            map[property.newName] = <i className="icon-check green-text"></i>;
            break;
          default:
            break;
        }
        // map[property.newName] = entity[property.oldName].toString();
      } else if (!_.has(property, "isTag") || property["isTag"] === false) {
        if (_.has(property, "enum")) {
          const sample = property["enum"];
          map[property.newName] =
            property["split"] === true
              ? sample[`${entity[property.oldName]}`]
              : sample[`${entity[property.oldName]}`];
        } else {
          map[property.newName] =
            property["split"] === true
              ? splitAtUpper(entity[property.oldName])
              : entity[property.oldName];
        }
      } else if (_.has(property, "isTag") && property["isTag"] === true) {
        switch (entity[property.oldName].toString()) {
          case property["success"]:
            map[property.newName] = (
              <span className="badge badge-success">
                {splitAtUpper(
                  _.has(property, "split") && entity[property.split] === true
                    ? splitAtUpper(entity[property.oldName].toString())
                    : entity[property.oldName].toString()
                )}
              </span>
            );
            break;
          case property["danger"]:
            map[property.newName] = (
              <span className="badge badge-danger">
                {splitAtUpper(
                  _.has(property, "split") && entity[property.split] === true
                    ? splitAtUpper(entity[property.oldName].toString())
                    : entity[property.oldName].toString()
                )}
              </span>
            );
            break;
          case property["default"]:
            map[property.newName] = (
              <span className="badge badge-default">
                {splitAtUpper(
                  _.has(property, "split") && entity[property.split] === true
                    ? splitAtUpper(entity[property.oldName].toString())
                    : entity[property.oldName].toString()
                )}
              </span>
            );
            break;
          case property["primary"]:
            map[property.newName] = (
              <span className="badge badge-primary">
                {splitAtUpper(
                  _.has(property, "split") && entity[property.split] === true
                    ? splitAtUpper(entity[property.oldName].toString())
                    : entity[property.oldName].toString()
                )}
              </span>
            );
            break;
          case property["secondary"]:
            map[property.newName] = (
              <span className="badge badge-secondary">
                {splitAtUpper(
                  _.has(property, "split") && entity[property.split] === true
                    ? splitAtUpper(entity[property.oldName].toString())
                    : entity[property.oldName].toString()
                )}
              </span>
            );
            break;
          case property["warning"]:
            map[property.newName] = (
              <span className="badge badge-warning">
                {splitAtUpper(
                  _.has(property, "split") && entity[property.split] === true
                    ? splitAtUpper(entity[property.oldName].toString())
                    : entity[property.oldName].toString()
                )}
              </span>
            );
            break;
          case property["light"]:
            map[property.newName] = (
              <span className="badge badge-light">
                {splitAtUpper(
                  _.has(property, "split") && entity[property.split] === true
                    ? splitAtUpper(entity[property.oldName].toString())
                    : entity[property.oldName].toString()
                )}
              </span>
            );
            break;
          case property["dark"]:
            map[property.newName] = (
              <span className="badge badge-dark">
                {splitAtUpper(
                  _.has(property, "split") && entity[property.split] === true
                    ? splitAtUpper(entity[property.oldName].toString())
                    : entity[property.oldName].toString()
                )}
              </span>
            );
            break;
          default:
            map[property.newName] = (
              <span className="badge badge-default">
                {splitAtUpper(
                  _.has(property, "split") && entity[property.split] === true
                    ? splitAtUpper(entity[property.oldName].toString())
                    : entity[property.oldName].toString()
                )}
              </span>
            );
            break;
        }
      }
      // else if (_.has(property, "isContextual") && property["isContextual"] === true) {

      // }
    });
    mapping.push(map);
  });
  return mapping;
}
