import _ from "lodash";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import { ServiceOffering } from "../../../enums/serviceOffering";
import { BillingType } from "../../../enums/billingType";
import { PaymentMode } from "../../../enums/paymentMode";
import authValidationService from "../../../services/auth/authValidationService";
import walletConfigService from "../../../services/billing/walletConfigService";
import SelectInputComponent, { ISelectItems } from "../../../components/formSelectComponent";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

interface AddWalletConfigurationFields {
  serviceOffering: string;
  billingType: string;
  paymentMode: string;
}

class AddWalletConfigurationForm extends BaseFormComponent<AddWalletConfigurationFields> { }

export interface IAddWalletConfigurationProps {
  showAddWalletConfigModal: boolean;
  toggleAddWalletConfigModal: any;
  institution: any;
}

export interface IAddWalletConfigurationState { }

class AddWalletConfiguration extends React.Component<
  IAddWalletConfigurationProps,
  IBaseFormState & IAddWalletConfigurationState
> {
  constructor(props: IAddWalletConfigurationProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
    };
  }

  onFormSubmit(
    fields: AddWalletConfigurationFields,
    onReloadFieldsCB: any
  ): boolean {
    try {
      this.setState(
        {
          errors:
            authValidationService.validateAddWalletConfigurationForm(fields),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddWalletConfigurationFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true });
      const { institution } = this.props;
      const payload: any = {};
      payload.institutionCode = institution.code;
      payload.serviceOffering = fields.serviceOffering;
      payload.billingType = fields.billingType;
      payload.paymentMode = fields.paymentMode;

      // console.log("Payload: ", payload)
      const response = await walletConfigService.createWalletConfig(payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Add Wallet Configuration",
          description: `Added new Wallet Configuration for ${institution.name}`,
        });
        toast.info(`Wallet Configuration created successfully for ${institution.name} `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
        this.setState({ submitting: false }, () =>
          this.props.toggleAddWalletConfigModal(true, institution)
        );
      } else {
        return false;
      }
      onReloadFieldsCB();
    } catch (error) {
      if (error.response.status === 409) {
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false });
      return false;
    } finally {
      return true;
    }
  }

  onHideModal = () => {
    this.setState({ validated: false, errors: {} });
    this.props.toggleAddWalletConfigModal(false, {});
  };

  render() {
    const { showAddWalletConfigModal, institution } = this.props;
    const { validated, submitting, errors } = this.state;

    let serviceOfferingInputData: ISelectItems[] = Object.keys(ServiceOffering)
      .filter((value) => isNaN(Number(value)) === false)
      .map((key) => ({
        name: ServiceOffering[key],
        value: ServiceOffering[key],
      }));

    let billingTypeInputData: ISelectItems[] = Object.keys(BillingType)
      .filter((value) => isNaN(Number(value)) === false)
      .map((key) => ({
        name: BillingType[key],
        value: BillingType[key],
      }));

    let paymentModeInputData: ISelectItems[] = Object.keys(PaymentMode)
      .filter((value) => isNaN(Number(value)) === false)
      .map((key) => ({
        name: PaymentMode[key],
        value: PaymentMode[key],
      }));

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showAddWalletConfigModal}
        onHide={this.onHideModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>
              <i className="fas fa-tools fa-sm cyan-text mr-3" />
              Add Wallet Configuration -{" "}
              <span className="font-weight-bold">{institution.name}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <AddWalletConfigurationForm
          initialValues={{
            serviceOffering: "",
            billingType: "",
            paymentMode: ""
          }}
          FormComponent={({
            fields: {
              serviceOffering,
              billingType,
              paymentMode,
            },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <form action="#">
                  <div className="card no-b">
                    <div className="card-body">
                      <h5 className="card-title">WALLET CONFIGURATION</h5>
                      <div className="form-row">
                        <div className="col-md-6">
                          <SelectInputComponent
                            id="serviceOffering"
                            name="serviceOffering"
                            value={serviceOffering}
                            items={serviceOfferingInputData}
                            required={true}
                            validated={validated}
                            errors={errors}
                            onChange={onChange}
                          />
                          <SelectInputComponent
                            id="paymentMode"
                            name="paymentMode"
                            value={paymentMode}
                            items={paymentModeInputData}
                            required={true}
                            validated={validated}
                            errors={errors}
                            onChange={onChange}
                          />
                        </div>
                        <div className="col-md-6">
                          <SelectInputComponent
                            id="billingType"
                            name="billingType"
                            value={billingType}
                            items={billingTypeInputData}
                            required={true}
                            validated={validated}
                            errors={errors}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.SAVE,
                      "Wallet Configuration",
                      () => {
                        this.onFormSubmit(
                          {
                            serviceOffering,
                            billingType,
                            paymentMode
                          },
                          onReloadFields
                        );
                      }
                    );
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={this.onHideModal}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment >
          )}
        ></AddWalletConfigurationForm >
      </Modal >
    );
  }
}

export default AddWalletConfiguration;
