import http from "../httpService";
import { IFilterData } from "../approval/approvalItemService";
import { getCurrentUserSession } from "../auth/authService";
import { decodeToken } from "react-jwt";

const appraisalBaseAddress = process.env.REACT_APP_HYBRIDAPPRAISAL_BASEADDRESS;

export function saveFinancialGroup(payload) {
  return http.post(
    appraisalBaseAddress + `FinancialGroup/CreateFinancialGroup`,
    payload
  );
}

export function updateFinancialGroup(payload) {
  return http.put(
    appraisalBaseAddress + `FinancialGroup/EditFinancialGroup`,
    payload
  );
}

export function retrieveById(id) {
  return http.get(
    appraisalBaseAddress + `FinancialGroup/RetrieveById/${id}`
  );
}

export function retrieveByInstitutionCode(institutionCode) {
  return http.get(
    appraisalBaseAddress + `FinancialGroup/RetrieveByInstitutionCode/${institutionCode}`
  );
}

export function retrieveAll() {
  return http.get(
    appraisalBaseAddress + `FinancialGroup/RetrieveAll`
  );
}

export function retrieveFinancialGroupByProductCode(procductCode: string, loanAmount: number, instCode: string) {
  return http.get(
    appraisalBaseAddress + `FinancialGroup/RetrieveFinancialGroupByProductCode/${procductCode}/${loanAmount}/${instCode}`
  );
}

export function filterFinancialGroup(
  payload: IFilterData,
  maxSize: number,
  pageNum: number
) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  payload = { ...payload };
  payload.institutionCode = {
    item1: "=",
    item2: decodedToken?.["InstitutionCode"]?.toString() || "break",
  };
  return http.post(
    appraisalBaseAddress +
    `FinancialGroup/FilterFinancialGroup?pageNum=${pageNum}&maxSize=${maxSize}`,
    payload
  );
}

export default {
  saveFinancialGroup,
  updateFinancialGroup,
  retrieveById,
  retrieveByInstitutionCode,
  retrieveAll,
  retrieveFinancialGroupByProductCode,
  filterFinancialGroup,
};
