import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../components/detailItem";
import textFormatService from "../../services/utility/textFormatService";

interface IPayoutReportDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  item: any;
}

interface IPayoutReportDetailState { }

class PayoutReportDetail extends Component<
  IPayoutReportDetailProps,
  IPayoutReportDetailState
> {
  render() {
    const { showDetailsModal, toggleDetailsModal, item } = this.props;

    if (_.isEmpty(item)) {
      return null;
    }

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="fas fa-receipt fa-sm s-18 mr-3" />
                Payout Report Details
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={8} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Institution Name"
                        value={item.institution.name}
                      />
                      <DetailItem
                        label="Unique Identifier"
                        value={item.uniqueIdentifier}
                      />
                      <DetailItem
                        label="Sweep Service"
                        value={item.sweepService}
                      />
                      <DetailItem
                        label="Current Stage"
                        value={item.currentStage}
                      />
                      <DetailItem
                        label="Date Created"
                        value={textFormatService.formatTimeString(item.dateCreated)}
                      />
                      <DetailItem
                        label="Has Error"
                        value={
                          (item ? item.hasError.toString() : "true") === "true" ? (
                            <span className="badge badge-success">
                              {item ? item.hasError.toString() : ""}
                            </span>
                          ) : (
                            <span className="badge badge-danger">
                              {item ? item.hasError.toString() : ""}
                            </span>
                          )
                        }
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={8} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Institution Code"
                        value={item.institutionCode}
                      />
                      <DetailItem
                        label="Total Amount"
                        value={textFormatService.convertKoboToNaira(
                          item.totalAmount,
                          2
                        )}
                      />
                      <DetailItem
                        label="Transactions Count"
                        value={item.transactionsCount}
                      />
                      <DetailItem
                        label="Status"
                        value={
                          (item ? item.status : "Completed") === "Completed" ? (
                            <span className="badge badge-success">
                              {item ? item.status : ""}
                            </span>
                          ) : (
                            <span className="badge badge-danger">
                              {item ? item.status : ""}
                            </span>
                          )
                        }
                      />
                      <DetailItem
                        label="Date Completed"
                        value={textFormatService.formatTimeString(item.dateCompleted)}
                      />
                      {item.errorMessage ? (
                        <DetailItem
                          label="Error Message"
                          value={item.errorMessage}
                        />
                      ) : ("")}
                    </dl>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default PayoutReportDetail;
