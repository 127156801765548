import http from "../httpService";

const recovaCreditAssessmentBaseAddress = process.env.REACT_APP_CREDITASSESSMENT_BASEADDRESS;
// const recovaCreditAssessmentBaseAddress = "https://localhost:44309/api/";

export function createCreditAssessmentInstitution(payload) {
  return http.post(
    recovaCreditAssessmentBaseAddress +
      `creditassessment/institution`,
      payload
  );
}

export function retrieveAllInstitutions() {
  return http.get(
    recovaCreditAssessmentBaseAddress + `creditassessment/institution`
  );
}

export function retrieveInstitutionById(id) {
  return http.get(
    recovaCreditAssessmentBaseAddress + `creditassessment/institution/${id}`
  );
}

export function retrieveInstitutionByCode(institutionCode) {
  return http.get(
    recovaCreditAssessmentBaseAddress + `creditassessment/institution/getbycode/${institutionCode}`
  );
}

export function activateInstitution(id) {
  return http.patch(
    recovaCreditAssessmentBaseAddress +
      `creditassessment/institution/${id}/activate`
  );
}

export function deactivateInstitution(id) {
  return http.patch(
    recovaCreditAssessmentBaseAddress +
      `creditassessment/institution/${id}/deactivate`
  );
}

// export function changeInstitutionName(institutionCode, newInstitutionName) {
//   return http.put(
//     recovaCreditAssessmentBaseAddress +
//       `InstitutionManagement/institutions/${institutionCode}/namechange/${newInstitutionName}`
//   );
// }

export default {
  createCreditAssessmentInstitution,
  retrieveAllInstitutions,
  retrieveInstitutionById,
  retrieveInstitutionByCode,
  activateInstitution,
  deactivateInstitution,
  // changeInstitutionName,
};
