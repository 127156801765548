import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import textFormatService from "../../../services/utility/textFormatService";
import { getCurrentUsersRoles } from "../../../services/auth/authService";
import DetailItemsTable from "../../../components/tableComponents/detailItemsTable";
import { mapEntityList } from "../../../services/utility/autoMapperService";

interface ISweepPlanDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  handlePageChange: any;
  item: any;
}

interface ISweepPlanDetailState {}

class SweepPlanDetail extends Component<
  ISweepPlanDetailProps,
  ISweepPlanDetailState> {
  constructor(props: ISweepPlanDetailProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { showDetailsModal, toggleDetailsModal, item } = this.props;
    // console.log(item);
    // if(!_.isEmpty(item)){
    //   console.log(JSON.parse(item.outstandingPayment));
    // }

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="icon icon-building-o s-18 mr-3" />
                Sweep Plan Details -{" "}
                <span className="font-weight-bold">{item.phoneNumber}</span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={7} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Sweep Reference"
                        labelSize={6}
                        valueSize={6}
                        value={item.sweepReference}
                      />
                      <DetailItem
                        label="Loan Account No."
                        labelSize={6}
                        valueSize={6}
                        value={item.loanAccountNo}
                      />
                      <DetailItem
                        label={"Sweep Amount"}
                        labelSize={6}
                        valueSize={6}
                        value={textFormatService.convertKoboToNaira(
                          item.sweepAmount,
                          2
                        )}
                      />
                      <DetailItem
                        label="Amount Debited"
                        labelSize={6}
                        valueSize={6}
                        value={textFormatService.convertKoboToNaira(
                          item.amountDebited,
                          2
                        )}
                      />
                      <DetailItem
                        label="Start Time"
                        labelSize={6}
                        valueSize={6}
                        value={textFormatService.formatTimeString(item.startTime)}
                      />
                      <DetailItem
                        label="Repayment Date"
                        labelSize={6}
                        valueSize={6}
                        value={textFormatService.formatTimeString(item.repaymentDate)}
                      />
                      {/* <DetailItem
                        label="Mandate Value"
                        labelSize={6}
                        valueSize={6}
                        value={textFormatService.convertKoboToNaira(
                          item.mandateValue,
                          2
                        )}
                      /> */}
                    </dl>
                  )}
                </div>
{/* //   {
//     "outstandingPayment": "string", {complex object}
//   } */}
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={7} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Sweep Plan Reference"
                        labelSize={6}
                        valueSize={6}
                        value={item.sweepPlanReference}
                      />
                      <DetailItem
                        label="Loan Account Institution"
                        labelSize={6}
                        valueSize={6}
                        value={item.loanAccountInstitution}
                      />
                      <DetailItem
                        label="Amount Initiated"
                        labelSize={6}
                        valueSize={6}
                        value={textFormatService.convertKoboToNaira(
                          item.amountInitiated,
                          2
                        )}
                      />
                      <DetailItem
                        label="Sweep Plan Status"
                        labelSize={6}
                        valueSize={6}
                        value={
                          (item ? item.sweepPlanStatus : "Started") === "Started" ? (
                            <span className="badge badge-default">
                              {item ? item.sweepPlanStatus : ""}
                            </span>
                          ) : (item.sweepPlanStatus === "Waiting") ? (
                            <span className="badge badge-primary">
                              {item ? item.sweepPlanStatus : ""}
                            </span>
                          ): (
                            <span className="badge badge-success">
                              {item ? item.sweepPlanStatus : ""}
                            </span>
                          )
                        }
                      />
                      <DetailItem
                        label="End Time"
                        labelSize={6}
                        valueSize={6}
                        value={textFormatService.formatTimeString(item.endTime)}
                      />
                      <DetailItem
                        label="Proactive"
                        labelSize={6}
                        valueSize={6}
                        value={
                          (item ? item.isProactive : true) === true ? (
                            <span className="badge badge-success">
                              {item ? "True" : ""}
                            </span>
                          ) : (
                            <span className="badge badge-danger">
                              {item ? "False" : ""}
                            </span>
                          )
                        }
                      />
                      {/* <DetailItem
                        label="Total Linked Banks"
                        labelSize={6}
                        valueSize={6}
                        value={item.totalLinkedBanks}
                      /> */}
                    </dl>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SweepPlanDetail;
