import * as React from "react";
import _ from "lodash";
import institutionsService from "../../../services/auth/institutionsService";
import { getCurrentUserSession } from "../../../services/auth/authService";
import { decodeToken } from "react-jwt";
import swal from "sweetalert";
import { toast } from "react-toastify";
import textFormatService, { generateGUID } from "../../../services/utility/textFormatService";

export interface UploadInstitutionLogoProps {}

export interface UploadInstitutionLogoState {
  item: any;
  fetching: boolean;
  error: string;
  submitting: boolean;
  logo: any;
  previewUrl: string;
  institutionImageUrl: string;
  inputKey: string;
}

class UploadInstitutionLogo extends React.Component<
  UploadInstitutionLogoProps,
  UploadInstitutionLogoState
> {
  constructor(props: UploadInstitutionLogoProps) {
    super(props);
    this.state = {
      fetching: false,
      item: {},
      error: "",
      submitting: false,
      logo: {},
      previewUrl: "",
      institutionImageUrl: "",
      inputKey: generateGUID()
    };
  }

  fetchInstitutionDetail = async () => {
    this.setState({ fetching: true });
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    const inst = await institutionsService.retrieveInstitutionById(decodedToken?.["InstitutionId"]);

    const institution = inst.data;
    const noImage = "iVBORw0KGgoAAAANSUhEUgAAAHIAAAByCAMAAAC4A3VPAAAAMFBMVEX///+/v7+9vb34+Pjx8fHKysrW1tbDw8P7+/vp6eng4OC6urrNzc3j4+P09PTGxsYuJfzzAAAB4klEQVRoge3Z7XaDIAwGYPlQ1CC9/7udVUFAxZpGz3aW96ebPAVjsLaqOBwOh/NIXH1T+kNSS3FLoHmclEz+J9KQ5VNS9lVLE32BJIpikkkmmfyKbB8mVSMBDGrmSNKJ6SjYx8h22YBKz0zEZA/Cm640er/3VxxZi0CWLqfaXQQcaQJZOHH8ZFKQka+VLMxyAAEdFdmERzDQhyeq9wWvqUgVyud1eF5l5X55Ie/LYTalUIfnzf+yMzC2+3QGAKQ9FpVfeTJyPN51x+BUrTO5KSCynUSndTSEq70pIDLSyth04S4SkK8FFTlIYSLTrl9kNkMTkWrqR8Eckq9O95Bzb/DzVImYFxAN2S3Fspg2IfN2QUOGnjuZ6bJuOhAJ2a/GaCqRJRscTzah+BPD6GxZNwWEJjUI/4i3Y2QrO5CQVkgzz7M7f5mQdCAs6cYanc3WFCw/zbgzYUk7HXybzfkk0+GRpF72S6PcR+9oZPRojyStH+tV7xJ54gLCkddfP0UFhCNPb4vtNNcCQpEOCoPvJwJQpC2MfZi8WV0i9fVJxgWEIa9fyXfCFoYgNUhMQgEhSNfh4nfNv/V6gkkmfzNJHiaZZJJJJplkkkkmmWTya/IeUYjCz5DqpmB/sedwOBzOxfwAx8soGRrzJO4AAAAASUVORK5CYII=";
    let logoUrl = institution.logo==null ? noImage : institution.logo;
    logoUrl = (logoUrl.substring(0, 2) == "iV" ? "data:image/png;base64," : "data:image/svg+xml;base64,") + logoUrl
    
    this.setState({ item: institution, institutionImageUrl: logoUrl, fetching: false });
  };

  async componentDidMount() {
    this.fetchInstitutionDetail();
  }

  handleOnLogoChange = async (e) => {
    e.preventDefault();
    let logo = e.target.files[0];
    let error: string = "";
    let url: string = "";
    if(logo == undefined){
      return;
    }
    // console.log("inspect ros: ", logo);
    if (!["png", "svg"].includes(logo.name.split(".").slice(-1)[0])) {
      toast.error(
        "Please confirm that the document is a valid Png or Svg file"
      );
      error = "Invalid document type";
    } else if (Math.round(logo.size / 1024) > 200) {
      toast.error(
        "Kindly ensure that the size of the document is less than 200 KB"
      );
      error = "Size of document is larger than 200kb";
    }else{
      url = URL.createObjectURL(logo)
      // logoPayload = await textFormatService.getBase64StringFromFile(logo);

      // logoPayload = (logo.name.split(".").slice(-1)[0] == "png" ? "data:image/png;base64, " : "data:image/svg+xml;base64, ") + logoPayload;
    }
    this.setState({
      logo: logo,
      error: error,
      previewUrl: url
    });
  };

  handleOnSubmit = async (e) => {
    swal({
      title: "Confirm!",
      text: `Are you sure you want to proceed to upload this image as your institution's logo?`,
      icon: "warning",
      buttons: {
        confirm: {
          text: "Yes",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: "Cancel",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      closeOnClickOutside: false,
      dangerMode: false,
    }).then(async (resp) => {
      if (resp) {
        // console.log("cancelling action...");
      } else {
        try {
          this.setState({ submitting: true });
          const {logo} = this.state;
          const logoPayload = await textFormatService.getBase64StringFromFile(logo);
          const decodedToken = decodeToken(getCurrentUserSession().token || "");
          // console.log(logoPayload)
          let response = await institutionsService.setInstitutionLogo(decodedToken?.["InstitutionId"], logoPayload);
          // console.log("response: ", response);
          toast.info("Logo has been uploaded successfully!", {
            autoClose: 6000,
            type: toast.TYPE.INFO,
            hideProgressBar: false,
          });
          this.resetAllFields();
          this.fetchInstitutionDetail();
        } catch (error) {
          toast.info("Something went wrong! Please, try again.", {
            autoClose: 6000,
            type: toast.TYPE.ERROR,
            hideProgressBar: false,
          });
          // console.log("error :: ", error.response)
          this.setState({ submitting: false });
        }
        // this.props.handleOnEnableOrDisable(this.props.item);
        //this.submit({ actionType: actionType, entityName: entityName });
      }
    });
  };

  resetAllFields(){
    this.setState({
      error: "",
      submitting: false,
      logo: {},
      previewUrl: "",
      inputKey: generateGUID()
    })
  }

  render() {
    const {
      fetching,
      item,
      error,
      submitting,
      logo,
      previewUrl,
      institutionImageUrl,
      inputKey
    } = this.state;
    // console.log("Item :: ", item);
    // console.log("logo :: ", logo);
    // // console.log("Url :: ", previewUrl);
    // console.log("Url :: ", institutionImageUrl);
    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Upload Logo</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <i className="fas fa-align-left mr-3" />
                    Image Upload Guide
                  </div>
                  <div className="card-body">
                    <div className="card-content">
                      <div className="row">
                        <div className="col">
                          <h6>Rules</h6>
                          <ul>
                            <li className="brown-text small">
                              Valid image formats are{" "}
                              <b style={{ color: "red" }}>
                                png & svg
                              </b>
                              .
                            </li>
                            <li className="brown-text small">
                              Maximum size for image is{" "}
                              <b style={{ color: "red" }}>200 Kb</b>.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-header card-footer"
                    style={{ padding: "8px" }}
                  >
                    <i className="fas fa-upload mr-3" />
                    Image Upload
                  </div>
                  <div className="card-content" style={{ padding: "8px" }}>
                    <div className="row">
                      <div className="col-md-4 col-sm-4">
                        {/* <h5 className="text-center" style={{ fontWeight: 400 }}>
                          Logo
                        </h5> */}
                        <div className="form-group">
                          <div className="input-group">
                            <div >
                              
                              <div className="upload-wrapper">
                                <label htmlFor="logoInput">
                                  {_.isEmpty(logo.name)
                                    ? (
                                        <span style={{verticalAlign: "middle"}}>
                                          <span style={{color: '#0a6095'}}>Upload a file</span> or drag and drop
                                        </span>
                                      )
                                    : logo.name}
                                </label>
                                <input 
                                  id="logoInput"
                                  type="file" 
                                  key={inputKey}
                                  accept="image/png, image/svg+xml"
                                  onChange={(e) =>
                                    this.handleOnLogoChange(e)
                                  }
                                />
                              </div>
                              {/* <div>
                                <label  
                                  className="upload-wrapper"
                                  htmlFor="logoInput"
                                >
                                  {_.isEmpty(logo.name)
                                    ? (
                                        <span style={{verticalAlign: "middle"}}>
                                          <span style={{color: '#0a6095'}}>Upload a file</span> or drag and drop
                                        </span>
                                      )
                                    : logo.name}
                                  <br />
                                  <input
                                    type="file"
                                    id="logoInput"
                                    key={inputKey}
                                    name="logoInput"
                                    aria-describedby="inputGroupFileAddon01"
                                    style={{visibility: "hidden"}}
                                    accept="image/png, image/svg+xml"
                                    onChange={(e) =>
                                      this.handleOnLogoChange(e)
                                    }
                                  />
                                </label>
                              </div> */}
                            </div>
                            {_.isEmpty(logo.name) ? (
                              ""
                            ) : (
                              <React.Fragment>
                                <span style={{ float: "right" }}>
                                  <i
                                    className="far fa-trash-alt red-text m-3"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.resetAllFields()}
                                  ></i>
                                </span>
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4" style={{ borderRight: "2px solid" }}>
                        <h5 className="text-center" style={{ fontWeight: 400 }}>
                          Preview
                        </h5>
                        <hr />
                        <div className="row" style={{textAlign: "center", display: "block"}}>
                          <img src={previewUrl} />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <h5 className="text-center" style={{ fontWeight: 400 }}>
                          Current Logo
                        </h5>
                        <hr />
                        <div className="row" style={{textAlign: "center", display: "block"}}>
                          <img src={institutionImageUrl} />
                        </div>
                      </div>
                    </div>
                  </div>
                  {(error == "") && (!_.isEmpty(logo.name))? (
                    <React.Fragment>
                      <div className="card-footer">
                        <div className="form-group clearfix">
                          <button
                            type="button"
                            onClick={(e) => this.handleOnSubmit(e)}
                            disabled={submitting}
                            className="btn btn-sm btn-primary float-right"
                          >
                            {submitting === false ? (
                              <React.Fragment>
                                <i className="fas fa-lg fa-save mr-4" /> Save
                              </React.Fragment>
                            ) : (
                              <i className="fas fa-spin fa-circle-notch" />
                            )}
                          </button>
                          <br />
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UploadInstitutionLogo;
