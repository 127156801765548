import { decodeToken } from "react-jwt";
import { IFilterData } from "../approval/approvalItemService";
import { getCurrentUserSession } from "../auth/authService";
import http from "../httpService";

const recovaBankOneHandShakeBaseAddress = process.env.REACT_APP_RECOVA_BANKONE_HANDSHAKE_BASEADDRESS;

export function filterPayoutRequests(
    payload: IFilterData,
    pageSize: number,
    pageNumber: number
) {
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    payload = { ...payload };
    // payload.institutionId = {
    //     item1: "=",
    //     item2: decodedToken?.["InstitutionId"]?.toString() || "break",
    // };

    var url =
        recovaBankOneHandShakeBaseAddress +
        `Payout/FIlterPagedPayoutRequests?pageSize=${pageSize}&pageNumber=${pageNumber}`;

    return http.post(url, payload, {
        url: url,
        data: payload,
        method: "post",
        headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
}

export function getPayoutRequestById(requestId: number) {
    var url = recovaBankOneHandShakeBaseAddress + `Payout/RetrieveById/${requestId}`;
    return http.get(url, {
        url: url,
        method: "get",
        headers: {
            Authorization: `Bearer ${getCurrentUserSession().token}`,
        },
    });
}

export function retryPayoutRequest(requestId: number) {
    var url = recovaBankOneHandShakeBaseAddress + `Payout/RetryPayoutRequest/${requestId}`;
    return http.get(url, {
        url: url,
        method: "get",
        headers: {
            Authorization: `Bearer ${getCurrentUserSession().token}`,
        },
    });
}

export default {
    filterPayoutRequests,
    getPayoutRequestById,
    retryPayoutRequest,
}