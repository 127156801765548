import React, { Fragment } from "react";
import _ from "lodash";
import ReactWizard from "react-bootstrap-wizard";
import DocumentCollection from "../../loanAppraisal/appraisalDetailPages/postApprovalDcoumentScreen";
import GroupOfferLetterScreen from "./DocumentGenerationScreen";
import GroupDocumentCollection from "./PostApprovalDocumentCollectionScreen";
// import DocumentManagementCheck from "./DocumentManagementCheck";

interface IDocumentManagementProps {
    item: any;
}

interface IDocumentManagementState {
    item: any;
}

class GroupDocumentManagement extends React.Component<IDocumentManagementProps & IDocumentManagementState> {
    formRef: HTMLFormElement;
    constructor(props: IDocumentManagementProps) {
        super(props);
        this.state = {
            item: {}
        };
    }

    static getDerivedStateFromProps(nextProps: IDocumentManagementProps, prevState) {
        return {
            item: nextProps.item,
        };
    }

    resetFields() {
        this.setState({ validated: false, submitting: false, errors: [] });
    }

    render() {
        const { item } = this.props;

        return (
            <Fragment>
                <div className="container-fluid relative animatedParent animateOnce">
                    <div className="animated fadeInUpShort go">
                        <div className="row my-3 mx-2">
                            <h3>
                                <b>Document Management</b>
                            </h3>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <ReactWizard
                                    className="row my-3 mx-2 font-italic font-weight-bold"
                                    style={{ fontSize: '120px' }}
                                    steps={([
                                        {
                                            stepName: "Document Download",
                                            component: GroupOfferLetterScreen,
                                            stepProps: {
                                                values: item
                                            },
                                        },
                                        {
                                            stepName: "Document Upload",
                                            component: GroupDocumentCollection,
                                            stepProps: {
                                                values: item
                                            },
                                        }
                                    ])}
                                    navSteps
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default GroupDocumentManagement;
