import { getCurrentUserSession } from "../auth/authService";
import http from "../httpService";
import { IFilterData } from "./../approval/approvalItemService";


const sweepBaseAddress = process.env.REACT_APP_SWEEP_BASEADDRESS;
export function filterSweepConfig(
  data: IFilterData,
  pageSize: number,
  currentPage: number
) {
  return http.post(
    sweepBaseAddress +
    `Institutions/FilterInstitutions?pageNum=${currentPage}&maxSize=${pageSize}`,
    data
  );
}

export function saveSweepConfig(sweepConfig) {
  return http.post(
    sweepBaseAddress + `Institutions/Save`,
    sweepConfig
  );
}

export function updateSweepConfig(sweepConfig) {
  return http.post(
    sweepBaseAddress + `Institutions/Update`,
    sweepConfig
  );
}

export function enableSweepConfig(id: number) {
  return http.post(
    sweepBaseAddress + `Institutions/Enable/${id}`
  );
}

export function disableSweepConfig(id: number) {
  return http.post(
    sweepBaseAddress + `Institutions/Disable/${id}`
  );
}

export function retrieveSweepConfigById(id: number) {
  return http.get(
    sweepBaseAddress + `Institutions/RetrieveById/${id}`
  );
}

export function retrieveAllSweepConfig() {
  return http.get(
    sweepBaseAddress + `Institutions/GetAll`
  );
}

export function retrieveSweepConfigByInstitutionCode(code: string) {
  return http.get(
    sweepBaseAddress +
    `Institutions/GetByCode?code=${code}`
  );
}

export function retrieveBankNameByCBNCode(bankCode: string) {
  return http.get(
    sweepBaseAddress +
    `Institutions/GetNameByCBNCode/${bankCode}`
  );
}

export function setDefaultSettlementAccount(ofiInstitution: string, accountId: number) {
  var url = sweepBaseAddress + `Settlement/SetDefaultSettlementAccountById?ofiInstitution=${ofiInstitution}&accountId=${accountId}`;

  return http.get(url, { url: url, method: "get", headers: { Authorization: `Bearer ${getCurrentUserSession().token}` }, });
}

export function saveSettlementAccount(settlementAccount: any) {
  var url = sweepBaseAddress + `Settlement/SaveSettlementAccount`;

  return http.post(url, settlementAccount, { url: url, method: "post", data: settlementAccount, headers: { Authorization: `Bearer ${getCurrentUserSession().token}` } });
}

export function removeSettlementAccount(id: number, ofiInstitution: string) {
  var url = sweepBaseAddress + `Settlement/RemoveSettlementAccount/${id}/${ofiInstitution}`;

  return http.post(url, { url: url, method: "post", headers: { Authorization: `Bearer ${getCurrentUserSession().token}` } });
}

export default {
  updateSweepConfig,
  saveSweepConfig,
  filterSweepConfig,
  enableSweepConfig,
  disableSweepConfig,
  retrieveAllSweepConfig,
  retrieveSweepConfigById,
  retrieveSweepConfigByInstitutionCode,
  retrieveBankNameByCBNCode,
  setDefaultSettlementAccount,
  saveSettlementAccount,
  removeSettlementAccount
};
