import * as React from "react";
import { Fragment } from "react";
import BaseListComponent, {
  IFilterData,
  BaseListComponentState,
} from "../../../components/BaseListComponent";
import ItemsTable, {
  filterFieldData,
} from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "../../../components/tableComponents/tableFilterPane";
import reportService from "../../../services/bvn/reportService";
import { createFilterData } from "../../../services/utility/autoMapperService";
import { mapEntityList } from "./../../../services/utility/autoMapperService";
import BvnReportDetail from "./bvnReportDetail";

export interface BvnReportProps {}

export interface BvnReportState extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
}

class BvnReportList extends BaseListComponent<BvnReportState> {}

class BvnReport extends React.Component<BvnReportProps, BvnReportState> {
  constructor(props: BvnReportProps) {
    super(props);
    this.state = {
      data: createFilterData([]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
    };
  }

  handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true });
    data = typeof data === "undefined" ? this.state.data : data;
    try {
      const reportItems = await reportService.retrieveBvnReport(
        currentPage,
        pageSize,
      );
      this.setState({
        tableItems: mapEntityList({
          entityList: reportItems.data.item2,
          properties: [
            { oldName: "name", newName: "name" },
            { oldName: "mandateLimit", newName: "mandateLimit" },
            {
              isTag: true,
              oldName: "category",
              newName: "category",
              success: "success",
              danger: "danger",
              default: "CommercialBank",
              primary: "primary",
              secondary: "secondary",
              warning: "OFI",
              light: "BankOneOFI",
              dark: "dark",
            },
            { oldName: "greyListCap", newName: "greyListCap" },
            { oldName: "totalAccountsLinked", newName: "totalAccountsLinked" },
            { oldName: "totalBVN", newName: "totalBVN" },
            { oldName: "totalGreyListed", newName: "totalGreyListed" },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: reportItems.data.item1,
        fetching: false,
      });
    } catch (error) {
      // console.log("caught exception", error);
    } finally {
      this.setState({ fetching: false });
    }
  };

  handleOnExport = async (type: string) => {};
  fetchItemById = async (item: any) => {
    try {
      this.setState({ fetchingDetail: true });
      const report = await reportService.retrieveBvnReportByInstitutionId(
        item.id
      );
      // console.log("item: ", report.data);
      this.setState({ item: report.data, fetchingDetail: false });
    } catch (error) {
    } finally {
      this.setState({ fetchingDetail: false });
    }
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }
  render() {
    const {
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row y-3 mx-2">
              <h3>
                <b>Bvn Report</b>
              </h3>
            </div>
            <BvnReportList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              initialValues={{
                data: createFilterData([]) as IFilterData,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: [],
                item: {},
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                showDetailsModal,
              }) => (
                <Fragment>
                  <BvnReportDetail
                    showDetailsModal={showDetailsModal}
                    toggleDetailsModal={onToggleDetailsModal}
                    item={item}
                  />
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="Bvn Report"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                              //   amountColumn={["mandateValue"]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></BvnReportList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default BvnReport;
