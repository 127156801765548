import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";
import { Status } from "../../enums/status";
import { ISaveApprovalAction } from "./approvalActionService";
import { IUpdateApprovable } from "./approvableService";
import { decodeToken } from "react-jwt";
import axios from "axios";

type logType = "UserType" | "ApplicationType";
type itemStatus =
  | "Created"
  | "Pending"
  | "Approved"
  | "Rejected"
  | "Completed"
  | "Recalled";
type actionStatus = "Valid" | "NotValid" | "Reverted";

interface IFilterTuple {
  item1: string;
  item2: string;
}
export interface IFilterData {
  [key: string]: IFilterTuple;
}

export interface IBranchBasedFilterData {
  filters: IFilterData;
  accessibleBranchCodes: string[];
}

interface searchData {
  roleId: number;
  userID: number;
  status: Status;
  level: number;
}

interface ICommonTypes {
  uuid: string;
  functionID: number;
  functionName: string;
  externalId: number;
  description: string;
  applicableAmount: number;
  userID: number;
  roleID: number;
  logType: logType;
  applicationDetails: string;
}

interface ISaveApprovalItem extends ICommonTypes {
  institutionID: number;
  jsonData: string;
}
interface ICreateAndSubmitApprovalItem extends ISaveApprovalItem {
  id: number;
  createdBy: string;
  createdByRole: string;
}

interface IApprovalAction extends ISaveApprovalAction {
  status: actionStatus;
  effectiveStatus: actionStatus;
  errorMessage: string;
  logType: logType;
  uuid: string;
  createdDate: Date;
}

interface IApprovalItem extends ICommonTypes {
  status: itemStatus;
  currentLevel: number;
  approvalDate: Date;
  id: number;
  createdDate: Date;
  institutionID: number;
  approvalActions: IApprovalAction[];
  jsonData: string;
  approvable: IUpdateApprovable;
}

const approvalServiceBaseAddress = process.env.REACT_APP_APPROVAL_BASEADDRESS;
export function retrieveAllApprovalItems() {
  var url = approvalServiceBaseAddress + `approvalItem`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveApprovalItemById(id: number) {
  var url = approvalServiceBaseAddress + `approvalItem/${id}`;
  // console.log("Url for get:: ", url);
  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function saveApprovalItem(approvalItem: ISaveApprovalItem) {
  var url = approvalServiceBaseAddress + "approvalItem";

  return http.post(url, approvalItem, {
    url: url,
    method: "post",
    data: approvalItem,
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function createAndSubmitApprovalItem(approvalItem: ISaveApprovalItem) {
  var url = approvalServiceBaseAddress + "approvalItem/CreateAndSubmit";

  return http.post(url, approvalItem, {
    url: url,
    method: "post",
    data: approvalItem,
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function updateApprovalItem(approvalItem: IApprovalItem) {
  var url = approvalServiceBaseAddress + "approvalItem";

  return http.post(url, approvalItem, {
    url: url,
    method: "post",
    data: approvalItem,
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

// export function searchApprovalItems(
//   data: searchData,
//   pageSize: number,
//   pageNumber: number
// ) {
//   console.log("testing data: ", data);
//   return http.get(
//     approvalServiceBaseAddress +
//     `approvalItem/SearchApprovalItems?institutionId=${getCurrentUserSession().institutionId
//     }&roleId=${data.roleId}&userId=${data.userID
//     }&status=${data.status.toString()}&level=${data.level
//     }&pageSize=${pageSize}&pageNumber=${pageNumber}`
//   );
// }

// export function searchMyApprovalRequests(
//   data: searchData,
//   pageSize: number,
//   pageNumber: number
// ) {
//   console.log("testing data: ", data);
//   return http.get(
//     approvalServiceBaseAddress +
//     `approvalItem/searchMyApprovalRequests?institutionId=${getCurrentUserSession().institutionId
//     }&roleId=${data.roleId}&userId=${data.userID
//     }&status=${data.status.toString()}&level=${data.level
//     }&pageSize=${pageSize}&pageNumber=${pageNumber}`
//   );
// }

export function filterApprovalItems(
  payload: IFilterData,
  pageSize: number,
  pageNumber: number
) {
  payload = { ...payload };

  var url = approvalServiceBaseAddress +
    `approvalItem/FilterApprovalItems?pageSize=${pageSize}&pageNumber=${pageNumber}`;

  return http.post(url, payload, {
    url: url,
    method: "post",
    data: payload,
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function downloadApprovalItemsReport(institutionId, type: String) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  if (type.toLowerCase() === "pdf") {
    var url = approvalServiceBaseAddress +
      `ApprovalItem/DownloadApprovalItemsReportPDF?userRoleId=${decodedToken?.["UserRoleId"]}`;

    return http.get(url, {
      url: url,
      method: "get",
      headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
  } else {
    var url2 = approvalServiceBaseAddress +
      `ApprovalItem/DownloadApprovalItemsReport?type=${type}&userRoleId=${decodedToken?.["UserRoleId"]}`;

    axios({
      url: url2,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${getCurrentUserSession().token}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Users.${type}`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }
}

export function downloadMyApprovalRequestsReport(institutionId, type: String) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  if (type.toLowerCase() === "pdf") {
    var url = approvalServiceBaseAddress +
      `ApprovalItem/DownloadMyApprovalRequestsReportPDF?userId=${decodedToken?.["UserId"]}`;

    return http.get(url, {
      url: url,
      method: "get",
      headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
  } else {
    var url2 = approvalServiceBaseAddress +
      `ApprovalItem/DownloadMyApprovalRequestsReport?type=${type}&userId=${decodedToken?.["UserId"]}`;

    axios({
      url: url2,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${getCurrentUserSession().token}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Users.${type}`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }
}

export function recallApprovalItem(
  approvalItem: IApprovalItem,
  userID: number
) {
  return http.post(
    approvalServiceBaseAddress + `approvalItem/Recall/${userID}`,
    approvalItem
  );
}

export default {
  retrieveAllApprovalItems,
  retrieveApprovalItemById,
  saveApprovalItem,
  createAndSubmitApprovalItem,
  updateApprovalItem,
  // searchApprovalItems,
  // searchMyApprovalRequests,
  filterApprovalItems,
  recallApprovalItem,
  downloadApprovalItemsReport,
  downloadMyApprovalRequestsReport,
};
