import React, { Component, useContext } from "react";
import {
    Button,
    Card,
    useAccordionToggle,
    AccordionContext,
    Accordion,
} from "react-bootstrap";
import _ from "lodash";
import DetailItem from "../../../../components/detailItem";
import BaseFormComponent from "../../../../components/BaseFormComponent";
import BaseListComponent from "../../../../components/BaseListComponent";
import { generateGUID } from "../../../../services/utility/textFormatService";
import textFormatService from "../../../../services/utility/textFormatService";
import GroupPolicyScoringCheck from "./scoringReportModal";

export function ContextAwareToggle({ eventKey, header }) {
    const currentEventKey = useContext(AccordionContext);
    const toggleOnClick = useAccordionToggle(eventKey);
    const isCurrentEventKey = currentEventKey === eventKey;
    const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
    return (
        <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
            <b>{header}</b>
            <i
                className={angleType}
                aria-hidden="true"
                style={{ float: "right" }}
            ></i>
        </Card.Header>
    );
}

interface PolicyScoringCheckFields {
    accountNumber: string;
    statementType: string;
    bankName: string;
}

class PolicyScoringCheckForm extends BaseFormComponent<PolicyScoringCheckFields> { }

interface PolicyScoringCheckProps {
    item: any;
    systemProcess: any;
    userRoles: any;
    currentUserSession: any;
}

interface PolicyScoringCheckState {
    item: any;
    userRoles: any;
    systemProcess: any;
    policyResponse: any;
    ownUpdate: boolean;
    acceptanceScore: any;
    scoringResult: any;
    showScoringResult: boolean;
}

class PolicyScoringList extends BaseListComponent<PolicyScoringCheckState> { }

class PolicyScoringCheck extends Component<PolicyScoringCheckProps, PolicyScoringCheckState> {
    _isMounted: boolean;
    constructor(props: PolicyScoringCheckProps) {
        super(props);
        this.state = {
            item: {},
            userRoles: [],
            systemProcess: [],
            policyResponse: [],
            ownUpdate: false,
            acceptanceScore: "",
            scoringResult: {},
            showScoringResult: false

        };
    }

    static getDerivedStateFromProps(nextProps: PolicyScoringCheckProps, prevState) {
        if (prevState.ownUpdate) {
            return {
                ownUpdate: false,
            };
        }
        if (_.isNull(nextProps.item)) {
            return null;
        }
        return {
            item: nextProps.item,
            userRoles: nextProps.userRoles,
            systemProcess: nextProps.systemProcess
        };
    }

    toggleShowScoringModal = () => {
        this.setState({ showScoringResult: !this.state.showScoringResult });
    };

    async componentDidMount() {
        this._isMounted = true;

        // console.log(this.props.item.financialGroup.rules.filter(
        //     (rule) =>
        //         rule.systemProcessID === Number(8)
        // ))
        const acceptanceScore = this.props.item.financialGroup.rules.filter(
            (rule) =>
                rule.systemProcessID === Number(8)
        )[0]?.acceptanceScore;
        let policyResponse: any = [];
        let scoringResponse: any = [];
        // console.log(acceptanceScore)
        try {
            policyResponse = JSON.parse(this.props.item.appraisalActions?.filter(x => x.systemProcessID === 2 && x.effectiveStatus === "Valid")[0]?.processResponse?.response)
        }
        catch {
            policyResponse = null
        }

        try {
            scoringResponse = JSON.parse(this.props.item.appraisalActions?.filter(x => x.systemProcessID === 5 && x.effectiveStatus === "Valid")[0]?.processResponse?.response)
        }
        catch {
            scoringResponse = null
        }
        // console.log(this.props.item.appraisalActions?.filter(x => x.systemProcessID === 2 && x.effectiveStatus === "Valid")[0])
        // console.log(this.props.item.appraisalActions?.filter(x => x.systemProcessID === 5 && x.effectiveStatus === "Valid")[0])

        // console.log(this.props.item.appraisalActions.filter(x => x.systemProcessID == 2)[0]?.processResponse?.response)
        console.log(policyResponse)
        if (this._isMounted) {
            this.setState({ policyResponse: policyResponse, ownUpdate: true, acceptanceScore: acceptanceScore, scoringResult: scoringResponse });
        }
    }

    render() {
        const {
            item,
            systemProcess,
            userRoles,
            currentUserSession
        } = this.props;
        const { policyResponse } = this.state;

        return (
            <div className="container-fluid relative animatedParent animateOnce">
                <div className="animated fadeInUpShort go">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-row">
                                <div className="col-md-12">
                                    <div className="row my-3 mx-2">
                                        <h3>
                                            <b>Policy and Scoring</b>
                                        </h3>
                                    </div>

                                    {/* <div className="card">
                                        <Accordion defaultActiveKey="0">
                                            <div>
                                                <Card>
                                                    <ContextAwareToggle
                                                        eventKey={`0`}
                                                        header={<React.Fragment>
                                                            <i
                                                                className="fa fa-info-circle"
                                                                aria-hidden="true"
                                                                style={{ color: "#878a8a" }}
                                                            ></i>{" "}
                                                            Policy and Scoring Check
                                                        </React.Fragment>}
                                                    ></ContextAwareToggle>
                                                    <Accordion.Collapse eventKey={`0`}>
                                                        <Card.Body>
                                                            <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                                <div className="row">
                                                                    <div className="col-md-10">
                                                                        <dl className="row">

                                                                        </dl>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </div>

                                        </Accordion>
                                    </div> */}

                                    {/* Reports */}
                                    <div className="card mt-3">
                                        <Accordion defaultActiveKey="0">
                                            <div>
                                                <Card>
                                                    <ContextAwareToggle
                                                        eventKey={`0`}
                                                        header={<React.Fragment>
                                                            <i
                                                                className="fa fa-info-circle"
                                                                aria-hidden="true"
                                                                style={{ color: "#878a8a" }}
                                                            ></i>{" "}
                                                            Policy Result
                                                        </React.Fragment>}
                                                    ></ContextAwareToggle>
                                                    <Accordion.Collapse eventKey={`0`}>
                                                        <Card.Body>
                                                            <table className="table table-hover table-content table-sm table-striped mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th
                                                                            scope="col"
                                                                            style={{
                                                                                paddingTop: "0.2rem",
                                                                                paddingBottom: "0.2rem",
                                                                            }}
                                                                        >
                                                                            <strong>Rule Description</strong>
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            style={{
                                                                                // width: "5px",
                                                                                paddingTop: "0.2rem",
                                                                                paddingBottom: "0.2rem",
                                                                            }}
                                                                        >
                                                                            <strong>Application Value</strong>
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            style={{
                                                                                // width: "5px",
                                                                                paddingTop: "0.2rem",
                                                                                paddingBottom: "0.2rem",
                                                                            }}
                                                                        >
                                                                            <strong>Application Result</strong>
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            style={{
                                                                                // width: "5px",
                                                                                paddingTop: "0.2rem",
                                                                                paddingBottom: "0.2rem",
                                                                            }}
                                                                        >
                                                                            <strong>Application String</strong>
                                                                        </th>
                                                                        {/* <th
                          scope="col"
                          style={{
                            // width: "5px",
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Action</strong>
                        </th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {/* {console.log(item)} */}
                                                                    {policyResponse?.length === 0 ? (
                                                                        <React.Fragment>
                                                                            <tr>
                                                                                <td>
                                                                                    No Decisions Made yet
                                                                                </td>
                                                                            </tr>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        policyResponse?.map((action) => (
                                                                            <React.Fragment key={generateGUID()}>
                                                                                <tr>
                                                                                    <td
                                                                                        style={{
                                                                                            paddingTop: "0.2rem",
                                                                                            paddingBottom: "0.2rem",
                                                                                            fontSize: "small",
                                                                                        }}
                                                                                    >
                                                                                        {action.ruleDescription}
                                                                                    </td>
                                                                                    <td
                                                                                        style={{
                                                                                            // width: "5px",
                                                                                            paddingTop: "0.2rem",
                                                                                            paddingBottom: "0.2rem",
                                                                                            fontSize: "small",
                                                                                        }}
                                                                                    >
                                                                                        {action.ruleDescription === "OBLIGOR LIMIT" ? textFormatService.formatCurrency(Number(action.loanParameter), 2) : action.loanParameter}
                                                                                    </td>
                                                                                    <td
                                                                                        style={{
                                                                                            paddingTop: "0.2rem",
                                                                                            paddingBottom: "0.2rem",
                                                                                            fontSize: "small",
                                                                                        }}
                                                                                    >
                                                                                        {/* {console.log(systemProcess?.filter(x => x.id === Number(action.systemProcessID)))[0]?.name} */}
                                                                                        {action.applicationResult}
                                                                                    </td>
                                                                                    <td
                                                                                        style={{
                                                                                            paddingTop: "0.2rem",
                                                                                            paddingBottom: "0.2rem",
                                                                                            fontSize: "small",
                                                                                        }}
                                                                                    >
                                                                                        {/* {action.logType === "SystemType" ? (systemProcess?.filter(x => x.id === Number(action.systemProcessID))[0]?.name) :
                                                                                            (userRoles.filter(x => x.id === Number(action.roleID))[0]?.name)}
                                                                                             */}
                                                                                        {action.applicationString}
                                                                                    </td>

                                                                                </tr>
                                                                                {/* ))} */}
                                                                            </React.Fragment>
                                                                        ))
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                            <div className="card-body">
                                                                <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                                    <div className="row">
                                                                        {/* <div className="col-md-6">
                                                                            <dl className="row">
                                                                                <DetailItem
                                                                                    label="Application String"
                                                                                    labelSize={7}
                                                                                    valueSize={5}
                                                                                    value={policyResponse.map(x => x.applicationString)}
                                                                                />
                                                                            </dl>
                                                                        </div> */}
                                                                        {/* <div className="col-md-3">
                                                                            <dl className="row">
                                                                                <DetailItem
                                                                                    label="Product Policy String"
                                                                                    labelSize={7}
                                                                                    valueSize={5}
                                                                                    value="YYYY"
                                                                                />
                                                                            </dl>
                                                                        </div> */}
                                                                        <div className="col-md-6">
                                                                            <dl className="row">
                                                                                <DetailItem
                                                                                    label="Policy Result"
                                                                                    labelSize={7}
                                                                                    valueSize={5}
                                                                                    value={item.appraisalActions.filter(x => x.systemProcessID === Number(2) && x.effectiveStatus === "Valid")[0]?.processResponse?.result}
                                                                                />
                                                                            </dl>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </div>
                                        </Accordion>
                                    </div>

                                    <div className="card mt-3">
                                        <Accordion defaultActiveKey="0">
                                            <div>
                                                <Card>
                                                    <ContextAwareToggle
                                                        eventKey={`0`}
                                                        header={<React.Fragment>
                                                            <i
                                                                className="fa fa-info-circle"
                                                                aria-hidden="true"
                                                                style={{ color: "#878a8a" }}
                                                            ></i>{" "}
                                                            Scoring Result
                                                        </React.Fragment>}
                                                    ></ContextAwareToggle>
                                                    <Accordion.Collapse eventKey={`0`}>
                                                        <Card.Body>
                                                            <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        <dl className="row">
                                                                            <DetailItem
                                                                                label="Application Score"
                                                                                labelSize={7}
                                                                                valueSize={3}
                                                                                value={item.appraisalActions.filter(x => x.systemProcessID === Number(5) && x.effectiveStatus === "Valid")[0]?.processResponse?.score}
                                                                            />
                                                                        </dl>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <dl className="row">
                                                                            <DetailItem
                                                                                label="Product Pass Score"
                                                                                labelSize={8}
                                                                                valueSize={3}
                                                                                // value={item.appraisalActions.filter(x => x.systemProcessID === Number(5))[0]?.processResponse?.score}
                                                                                value={this.state.acceptanceScore}
                                                                            />
                                                                        </dl>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <dl className="row">
                                                                            <DetailItem
                                                                                label="Scoring Result"
                                                                                labelSize={7}
                                                                                valueSize={5}
                                                                                value={item.appraisalActions.filter(x => x.systemProcessID === Number(5) && x.effectiveStatus === "Valid")[0]?.comment}
                                                                            />
                                                                        </dl>
                                                                    </div>
                                                                    <div className="col-md-3 float-right">
                                                                        <Button
                                                                            size="sm"
                                                                            variant="primary"
                                                                            onClick={() => {
                                                                                this.toggleShowScoringModal();
                                                                            }}
                                                                        >
                                                                            View Scoring Report
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </div>
                                        </Accordion>
                                    </div>
                                    <GroupPolicyScoringCheck
                                        item={this.state.item}
                                        values={this.state.scoringResult?.ScoreDetails}
                                        showPatchModal={this.state.showScoringResult}
                                        togglePatchModal={this.toggleShowScoringModal} systemProcess={systemProcess} userRoles={userRoles} />

                                    <div className="form-row">
                                        <div className="my-1 mx-2">
                                            {/* <button
                        className="btn btn-sm btn-outline-primary my-2"
                        style={{ cursor: "pointer" }}
                        type="button"
                        onClick={() => { }}
                      >
                        <React.Fragment>
                          <i className="far fa-save fa-lg mr-2" />{" "}
                          Save
                        </React.Fragment>
                      </button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PolicyScoringCheck;



