import React from "react";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import {
  ISelectItems,
} from "../../../components/formSelectComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import mandateValidationService from "../../../services/mandate/mandateValidationService";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../components/detailItem";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import collectionConsentConfigService from "../../../services/mandate/collectionConsentConfigService";

interface EditCollectionConsentConfigFields {
  repaymentBank: string;
  bankCode: string;
  status: string;
}

class EditCollectionConsentConfigForm extends BaseFormComponent<EditCollectionConsentConfigFields> { }

interface IEditCollectionConsentConfigProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}
interface IEditCollectionConsentConfigState {
  availableCollectionConsentTypes: ISelectItems[];
  collectionConsentTypes: any;
  ownUpdate: boolean;
}

class EditCollectionConsentConfig extends React.Component<
  IEditCollectionConsentConfigProps,
  IBaseFormState & IEditCollectionConsentConfigState
> {
  constructor(props: IEditCollectionConsentConfigProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      availableCollectionConsentTypes: [
        {
          name: "OTP",
          value: "OTP",
        },
        {
          name: "PIN",
          value: "PIN",
        },
        {
          name: "PhoneNumber",
          value: "Phone Number",
        },
        {
          name: "First3DigitsOfCard",
          value: "First 3 Digits of Card",
        },
        {
          name: "Last3DigitsOfCard",
          value: "Last 3 Digits of Card",
        },
        {
          name: "First4DigitsOfCard",
          value: "First 4 Digits of Card",
        },
        {
          name: "Last4DigitsOfCard",
          value: "Last 4 Digits of Card",
        },
        {
          name: "First6DigitsOfCard",
          value: "First 6 Digits of Card",
        },
        {
          name: "Last6DigitsOfCard",
          value: "Last 6 Digits of Card",
        },
      ],
      collectionConsentTypes: [],
      ownUpdate: false,
    };
  }

  static getDerivedStateFromProps(nextProps: IEditCollectionConsentConfigProps, prevState) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (!_.isEmpty(nextProps.item)) {
      let collectionConsentTypesArray = nextProps.item.collectionConsentTypes;

      return {
        collectionConsentTypes: collectionConsentTypesArray,
      };
    }
    return null;
  }

  onFormSubmit(
    fields: EditCollectionConsentConfigFields,
    onReloadFieldsCB: any
  ): boolean {
    try {
      this.setState(
        {
          errors:
            mandateValidationService.validateEditCollectionConsentConfigForm(
              fields
            ),
          ownUpdate: true
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (
    fields: EditCollectionConsentConfigFields,
    onReloadFieldsCB: any
  ) => {
    try {
      if (_.isEmpty(this.state.collectionConsentTypes)) {
        toast.error(
          `Configuration must include at least one Collection Consent Type`,
          {
            autoClose: 6000,
            type: toast.TYPE.ERROR,
            hideProgressBar: false,
          }
        );
        this.setState({ submitting: false, ownUpdate: true })
      }
      else {
        this.setState({ submitting: true, ownUpdate: true });
        const payload = { ...this.props.item };
        payload.repaymentBank = fields.repaymentBank;
        payload.bankCode = fields.bankCode;
        payload.collectionConsentTypes = this.state.collectionConsentTypes;
        payload.status = fields.status;

        // console.log("For checks::", payload);
        const response =
          await collectionConsentConfigService.updateCollectionConsentConfig(
            payload.id,
            payload
          );
        if (response.status >= 200 && response.status <= 300) {
          await activityTrailService.saveActivityTrail({
            actionType: "Edit Collection Consent Config",
            description: `Edited details for Collection Consent Config ${fields.repaymentBank}`,
          });

          toast.info(
            `Collection Consent Config ${fields.repaymentBank} Edited successfully! `,
            {
              autoClose: 6000,
              type: toast.TYPE.DEFAULT,
              hideProgressBar: false,
            }
          );
        }
        onReloadFieldsCB();
        this.setState({ submitting: false }, () =>
          this.props.toggleEditModal(true, this.props.item)
        );
      }
    } catch (error) {
      //   console.log("submission error: ", error);
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // console.log("submission error: ", error.response);
      }
      this.setState({ submitting: false, ownUpdate: true });
    }
  };

  handleOnCollectionConsentTypesChange = (item) => {
    // console.log("clicked Collection Consent Type1: ", item);
    const collectionConsentTypes = [...this.state.collectionConsentTypes];
    if (collectionConsentTypes.indexOf(item) !== -1) {
      collectionConsentTypes.splice(collectionConsentTypes.indexOf(item), 1);
    } else {
      collectionConsentTypes.push(item);
    }
    // console.log("clicked Collection Consent Type2: ", collectionConsentTypes);
    this.setState({ collectionConsentTypes: collectionConsentTypes, ownUpdate: true });
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      errors: {},
      ownUpdate: true,
    });
  }

  render() {
    const { item, showEditModal, toggleEditModal } = this.props;
    const { validated, submitting, errors, availableCollectionConsentTypes } = this.state;

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-columns fa-sm cyan-text mr-3" />
              Edit Collection Consent Config -{" "}
              <span className="font-weight-bold">{item.repaymentBank}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditCollectionConsentConfigForm
          initialValues={{
            repaymentBank: item.repaymentBank,
            bankCode: item.bankCode,
            status: item.status,
          }}
          FormComponent={({
            fields: {
              repaymentBank,
              bankCode,
              status,
            },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    {_.isEmpty(item) ? (
                      <Skeleton width={200} />
                    ) : (
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        <b>General Information</b>
                      </h6>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={3} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Repayment Bank"
                              value={
                                <DetailItemInputComponent
                                  id="repaymentBank"
                                  type="text"
                                  name="repaymentBank"
                                  placeholder=""
                                  value={repaymentBank}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={5}
                              valueSize={7}
                            />
                          </dl>
                        )}
                      </div>
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={3} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Bank Code"
                              value={
                                <DetailItemInputComponent
                                  id="bankCode"
                                  type="text"
                                  name="bankCode"
                                  placeholder=""
                                  value={bankCode}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={5}
                              valueSize={7}
                            />
                          </dl>
                        )}
                      </div>

                      <div className="col-md-6">
                        <label
                          htmlFor="items"
                          className="control-label textbox-label"
                          style={{ fontWeight: 600, fontSize: "14px" }}
                        >
                          {"  "} Collection Consent Types
                        </label>
                        <div
                          className="text-nowrap block-example border"
                          style={{
                            width: "100%",
                            height: "200px",
                            overflowY: "scroll",
                          }}
                        >
                          <React.Fragment>
                            {availableCollectionConsentTypes.length ===
                              0 ? (
                              <div
                                style={{
                                  backgroundColor: "#F3F3F3",
                                }}
                              >
                                <p className="text-center">
                                  No Available Collection Consent Type Found
                                </p>
                              </div>
                            ) : (
                              <React.Fragment>
                                {availableCollectionConsentTypes.map(
                                  (item) => (
                                    <div
                                      className="custom-control custom-checkbox"
                                      key={availableCollectionConsentTypes.indexOf(
                                        item
                                      )}
                                    >
                                      <input
                                        id={item.name}
                                        name={item.name}
                                        className="custom-control-input"
                                        type="checkbox"
                                        onChange={() =>
                                          this.handleOnCollectionConsentTypesChange(
                                            item.name
                                          )
                                        }
                                        checked={
                                          _.isEmpty(
                                            this.state.collectionConsentTypes.filter(
                                              (x) => x === item.name
                                            )
                                          )
                                            ? false
                                            : true
                                        }
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={item.name}
                                      >
                                        {item.value}
                                      </label>
                                    </div>
                                  )
                                )}
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.UPDATE,
                      "Collection Consent Config",
                      () => {
                        this.onFormSubmit(
                          {
                            repaymentBank,
                            bankCode,
                            status,
                          },
                          onReloadFields
                        );
                      }
                    );
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></EditCollectionConsentConfigForm>
      </Modal>
    );
  }
}

export default EditCollectionConsentConfig;
