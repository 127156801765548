import * as React from "react";
// import _ from "lodash";
import { Fragment } from "react";
import { IFilterData, BaseListComponentState } from "../../components/BaseListComponent";
import { createFilterData, mapEntityList } from "../../services/utility/autoMapperService";
import BaseListComponent from "../../components/BaseListComponent";
import ItemsTable, { filterFieldData } from "../../components/tableComponents/itemsTable";
import TableFilterPane from "../../components/tableComponents/tableFilterPane";
// import IncomingRepaymentReportDetail from "./incomingRepaymentsReportDetail";
import { getCurrentUserSession } from "../../services/auth/authService";
import reportsService from "../../services/sweep/reportsService";
import { printPDF } from "../../services/utility/textFormatService";
import SettlementReportDetail from "./settlementReportDetail";
import { decodeToken } from "react-jwt";

export interface SettlementReportAppzoneProps { }

export interface SettlementReportAppzoneState extends BaseListComponentState {
    data: IFilterData;
    currentPage: number;
    pageSize: number;
    tableItems: any;
    totalSize: number;
    fetchingDetail: boolean;
    filterValueInput: filterFieldData;
    item: any;
}

class SettlementReportAppzoneList extends BaseListComponent<SettlementReportAppzoneState> { }

class SettlementReportAppzone extends React.Component<SettlementReportAppzoneProps, SettlementReportAppzoneState> {
    constructor(props: SettlementReportAppzoneProps) {
        super(props);
        this.state = {
            data: createFilterData([]) as IFilterData,
            currentPage: 1,
            pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
            fetching: false,
            fetchingDetail: false,
            tableItems: [],
            totalSize: 0,
            filterValueInput: [],
            item: {},
            showDetailsModal: false,
            showEditModal: false,
            showTableFilterModal: false,
            showTrailModal: false,
        };
    }

    handlePageChange = async (currentPage: number, pageSize: number, data?: IFilterData) => {
        currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
        pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
        this.setState({ fetching: true });
        data = typeof data === "undefined" ? this.state.data : data;
        try {
            const decodedToken = decodeToken(getCurrentUserSession().token || "");
            const reportItems = await reportsService.retrieveSettlementReport(
                String(decodedToken?.["InstitutionCode"]),
                "AppzoneNIP",
                pageSize,
                currentPage - 1
            );
            // console.log("response: ", reportItems);
            this.setState({
                tableItems: mapEntityList({
                    entityList: reportItems.data.item2,
                    properties: [
                        { oldName: "settlementTime", newName: "SweepDate" },
                        { oldName: "sweepReference", newName: "SweepReference" },
                        {
                            oldName: "lenderInstitutionName",
                            newName: "LenderInstitution",
                        },
                        {
                            oldName: "debitedInstitutionName",
                            newName: "DebitedInstitution",
                        },

                        { oldName: "amount", newName: "RepaymentAmount" },
                        { oldName: "settlementAmount", newName: "settlementAmount" },
                        {
                            isTag: true,
                            oldName: "status",
                            newName: "settlementStatus",
                            success: "Successful",
                            danger: "Failed",
                            default: "default",
                            primary: "PushedAndAwaitingResponse",
                            secondary: "secondary",
                            warning: "Pending",
                            light: "light",
                            dark: "Unknown",
                        },
                        { oldName: "sweepChannel", newName: "sweepChannel" },

                        // { oldName: "settlementEntryType", newName: "EntryType" },
                        // { oldName: "uniqueId", newName: "uniqueId" },
                        // { oldName: "sweepCycle", newName: "sweepCycle" },
                        // { oldName: "basis", newName: "basis" },
                        // {
                        //     isTag: true,
                        //     oldName: "repaymentType",
                        //     newName: "repaymentType",
                        //     success: "Collection",
                        //     danger: "Failed",
                        //     default: "Recovery",
                        //     primary: "PushedAndAwaitingResponse",
                        //     secondary: "secondary",
                        //     warning: "Pending",
                        //     light: "light",
                        //     dark: "Unknown",
                        // },

                        // { oldName: "generatedOn", newName: "generatedOn" },
                        // { oldName: "settlementTime", newName: "settlementTime" },
                    ],
                }),
                currentPage,
                pageSize,
                totalSize: reportItems.data.item1,
                fetching: false,
            });
        } catch (ex) {
            // console.log("caught exception", ex);
            if (ex.response && ex.response.status >= 400 && ex.response.status <= 499) {
                // toast.error("Bad Request");
                // console.log(ex.response);
                // errors["response"] = "Internal server error.";
                // console.log(errors);
                // this.setState({ errors, fetching: false });
            } else {
                // toast.error("Internal server error. Please contact the admin.");
                // this.setState({ errors, fetching: false });
            }
        } finally {
            this.setState({ fetching: false });
        }
    };

    handleOnExport = async (type: string) => {
        try {
            const decodedToken = decodeToken(getCurrentUserSession().token || "");
            switch (type) {
                case "XLSX":
                case "CSV":
                    return await reportsService.downloadSettlementReport(decodedToken?.["InstitutionCode"], type);
                case "PDF":
                    const response = await reportsService.downloadSettlementReport(decodedToken?.["InstitutionCode"], type);
                    printPDF(response?.data);
                    break;
                default:
                    break;
            }
        } catch (ex) {
            if (ex.response && ex.response.status >= 400 && ex.response.status <= 499) {
                // toast.error("Bad Request");
                // console.log(ex.response);
                // errors["response"] = "Internal server error.";
                // console.log(errors);
            } else {
                // toast.error("Internal server error. Please contact the admin.");
                //this.setState({ errors, fetching: false });
            }
        }
    };

    fetchItemById = async (item: any) => {
        // await activityTrailService.saveActivityTrail({
        //   actionType: "View Details",
        //   description: `Viewed details of Branch ${item.name}`
        // });
        this.setState({ fetchingDetail: true });
        const report = await reportsService.retrieveSettlementReportItemById(item.id);
        this.setState({ item: report.data, fetchingDetail: false });
    };

    getFilteredItems = (data: IFilterData): IFilterData => {
        let filteredItems: IFilterData = {};
        Object.keys(data).forEach((key) => {
            if (data[key].item2 !== "") {
                filteredItems[key] = data[key];
            }
        });
        return filteredItems;
    };

    async componentDidMount() {
        this.handlePageChange(this.state.currentPage, this.state.pageSize);
    }

    render() {
        const { currentPage, pageSize, tableItems, totalSize, fetching, item, fetchingDetail } = this.state;
        return (
            <Fragment>
                <div className="container-fluid relative animatedParent animateOnce">
                    <div className="animated fadeInUpShort go">
                        <div className="row my-3 mx-2">
                            <h3>
                                <b>Appzone Settlement Report</b>
                            </h3>
                        </div>
                        <SettlementReportAppzoneList
                            pageChangeHandler={this.handlePageChange}
                            fetchingDetail={fetchingDetail}
                            initialValues={{
                                data: createFilterData([]) as IFilterData,
                                fetching: false,
                                showDetailsModal: false,
                                showEditModal: false,
                                showTableFilterModal: false,
                                showTrailModal: false,
                                tableItems: [],
                                filterValueInput: [],
                                item: {},
                            }}
                            tableItems={tableItems}
                            item={item}
                            DetailsModal={({ item, onToggleDetailsModal, showDetailsModal }) => (
                                <Fragment>
                                    <SettlementReportDetail
                                        showDetailsModal={showDetailsModal}
                                        toggleDetailsModal={onToggleDetailsModal}
                                        item={item}
                                    />
                                </Fragment>
                            )}
                            ListPayLoad={({
                                data,
                                showTableFilterModal,
                                tableItems,
                                onToggleDetailsModal,
                                onToggleTableFilterModal,
                                onHandleFilterItemRemove,
                                filterValueInput,
                            }) => (
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card no-b">
                                            <div className="card-body">
                                                <div className="card-title">
                                                    <div id="list-filters" className="ml-4 mr-4">
                                                        <TableFilterPane
                                                            filteredItems={this.getFilteredItems(data)}
                                                            handleFilterRemove={onHandleFilterItemRemove}
                                                        />
                                                    </div>
                                                    <div id="list-table">
                                                        <ItemsTable
                                                            data={data}
                                                            items={tableItems}
                                                            filterValueInput={filterValueInput}
                                                            showTableFiltermodal={showTableFilterModal}
                                                            toggleTableFiltermodal={onToggleTableFilterModal}
                                                            name="SettlementRepaymentsReport"
                                                            currentPage={currentPage}
                                                            pageSize={pageSize}
                                                            totalSize={totalSize}
                                                            fetching={fetching}
                                                            handlePageChange={this.handlePageChange}
                                                            onViewDetails={(item: any) => {
                                                                this.fetchItemById(item);
                                                                onToggleDetailsModal();
                                                            }}
                                                            handleOnExport={this.handleOnExport}
                                                            amountColumn={["RepaymentAmount"]}
                                                            timeColumn={["generatedOn", "settlementTime"]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        ></SettlementReportAppzoneList>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default SettlementReportAppzone;
