import React, { Fragment } from "react";
import _ from "lodash";
import { Formik } from "formik";
import swal from "sweetalert";
import ReactWizard from "react-bootstrap-wizard";
// import PersonalDetails from "./wizardComponents/editDetailedDataEntry/personalDetails";
// import CorporateDetails from "./wizardComponents/editDetailedDataEntry/corporateDetails";
// import ContactDetails from "./wizardComponents/editDetailedDataEntry/contactDetails";
// import WorkDetails from "./wizardComponents/editDetailedDataEntry/workDetails";
// import GuarantorDetails from "./wizardComponents/editDetailedDataEntry/guarantorDetails";
// import StakeholderDetails from "./wizardComponents/editDetailedDataEntry/stakeholderDetails";
// import CollateralDetails from "./wizardComponents/editDetailedDataEntry/collateralDetails";
// import DocumentCollection from "./wizardComponents/editDetailedDataEntry/documentCollection";
// import Notepad from "./wizardComponents/editDetailedDataEntry/notepad";
// import AssetPropertyDetails from "./wizardComponents/editDetailedDataEntry/assetPropertyDetails";
// import LoanDetails from "./wizardComponents/editDetailedDataEntry/loanDetails";
import appraisalActionService from "../../../../services/hybridAppraisal/appraisalActionService";
import activityTrailService from "../../../../services/auditTrail/activityTrailService";
import { getCurrentUserSession } from "../../../../services/auth/authService";
import { toast } from "react-toastify";
import GroupContactDetails from "./wizardComponents/editDetailedDataEntry/contactDetails";
import PersonalDetails from "../../loanAppraisal/appraisalDetailPages/wizardComponents/editDetailedDataEntry/personalDetails";
import GroupDocumentCollection from "./wizardComponents/editDetailedDataEntry/documentCollection";
import GroupNotepad from "./wizardComponents/editDetailedDataEntry/notepad";
import GroupLoanDetails from "./wizardComponents/editDetailedDataEntry/loanDetails";
import GroupFinancialAnalysis from "./wizardComponents/editDetailedDataEntry/financialAnalysis";
import GroupPersonalDetails from "./wizardComponents/editDetailedDataEntry/personalDetails";
// import IncomeDetails from "./wizardComponents/editDetailedDataEntry/incomeDetails";

interface IDetailedDataEntryProps {
    loanDetails: any;
    // showAppraisalProcess: boolean;
    // toggleAppraisalProcess: any;
    toggleLoanRequestDetails: any;
    reloadItem: any;
}

interface IDetailedDataEntryState {
    item: any;
}

class GroupDetailedDataEntry extends React.Component<IDetailedDataEntryProps, IDetailedDataEntryState> {
    formRef: HTMLFormElement;
    constructor(props: IDetailedDataEntryProps) {
        super(props);
        this.state = {
            item: {},
        };
    }

    static getDerivedStateFromProps(nextProps: IDetailedDataEntryProps, prevState) {
        return {
            item: nextProps.loanDetails,
        };
    }


    render() {
        const { reloadItem } = this.props;
        const { item } = this.state;
        console.log(item)

        return (
            <Fragment>
                <div className="container-fluid relative animatedParent animateOnce">
                    <div className="animated fadeInUpShort go">
                        <div className="row my-3 mx-2">
                            <h3>
                                <b>Detailed Data Entry</b>
                            </h3>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Formik
                                    initialValues={{
                                        id: item.id,
                                        requestIdentifier: item.requestIdentifier,
                                        institutionCode: item.institutionCode,
                                        externalInstitutionCode: item.externalInstitutionCode,
                                        description: item.description,
                                        currentLevel: item.currentLevel,
                                        bvn: item.bvn,
                                        linkedAccountNumber: item.linkedAccountNumber,
                                        requestStatus: item.requestStatus,
                                        obligorLimit: item.obligorLimit,
                                        totalIncome: item.totalIncome,
                                        productCode: item.productCode,
                                        approvalDate: item.approvalDate,
                                        appraisalActions: item.appraisalActions,
                                        loanPurpose: item.loanPurpose,
                                        relationshipManager: item.relationshipManager,
                                        loanType: item.loanType,
                                        loanAmount: item.loanAmount,
                                        tenureInDays: item.tenureInDays,
                                        branch: item.branch,
                                        financialGroup: item.financialGroup,
                                        dateCreated: item.dateCreated,
                                        jsonData: item.jsonData,

                                        // personal details
                                        customerName: item.customerName,
                                        customerFirstName: item.customerFirstName,
                                        customerLastName: item.customerLastName,
                                        customerMiddleName: item.customerMiddleName,
                                        gender: item.gender,
                                        maritalStatus: item.maritalStatus,
                                        identificationType: item.identificationType,
                                        identificationNumber: item.identificationNumber,
                                        identificationExpiryDate: item.identificationExpiryDate,
                                        dateOfBirth: item.dateOfBirth,
                                        title: item.title,
                                        educationalQualification: item.educationalQualification,
                                        motherMaidenName: item.motherMaidenName,
                                        age: item.age,
                                        numberOfDependent: item.numberOfDependent,
                                        accountType: item.accountType,
                                        ageOfAccount: item.ageOfAccount,
                                        domiciliationOfSalary: item.domiciliationOfSalary,
                                        accountNumber: item.accountNumber,
                                        nationality: item.nationality,
                                        tin: item.TIN,
                                        repaymentAccountCreationDate: item.repaymentAccountCreationDate,

                                        // contact details
                                        addressType: item.addressType,
                                        propertyStatus: item.propertyStatus,
                                        address: item.address,
                                        city: item.city,
                                        country: item.country,
                                        state: item.state,
                                        phonenumber: item.phonenumber,
                                        phonenumber2: item.phonenumber2,
                                        customerEmail: item.customerEmail,
                                        yearsOfStayAtCurrentAddress: item.yearsOfStayAtCurrentAddress,
                                        monthsOfStayAtCurrentAddress: item.monthsOfStayAtCurrentAddress,
                                        yearsOfStayAtCurrentCity: item.yearsOfStayAtCurrentCity,
                                        monthsOfStayAtCurrentCity: item.monthsOfStayAtCurrentCity,
                                        twitterHandle: item.twitterHandle,
                                        instagramID: item.instagramID,
                                        facebookID: item.facebookID,
                                        locationCoordinates: item.locationCoordinates,

                                        // work details
                                        employmentIndustry: item.employmentIndustry,
                                        employmentDesignation: item.employmentDesignation,
                                        employmentStatus: item.employmentStatus,
                                        employerName: item.employerName,
                                        employerCategory: item.employerCategory,
                                        officePhoneNumber: item.officePhoneNumber,
                                        yearsInCurrentJob: item.yearsInCurrentJob,
                                        monthsInCurrentJob: item.monthsInCurrentJob,
                                        jobAddress: item.jobAddress,
                                        jobCity: item.jobCity,
                                        jobCountry: item.jobCountry,
                                        jobState: item.jobState,

                                        // corporate details
                                        companyName: item.companyName,
                                        registrationNumber: item.registrationNumber,
                                        constitution: item.constitution,
                                        industry: item.industry,
                                        incorporationDate: item.incorporationDate,
                                        contactPerson: item.contactPerson,
                                        designation: item.designation,
                                        lengthOfYearsInBusiness: item.lengthOfYearsInBusiness,
                                        domiciliationOfBusinessProceed: item.domiciliationOfBusinessProceed,
                                        typeOfProductOffered: item.typeOfProductOffered,
                                        productDurability: item.productDurability,
                                        threatOfCompetition: item.threatOfCompetition,
                                        availabilityOfTradingStock: item.availabilityOfTradingStock,
                                        complexityOfProduct: item.complexityOfProduct,
                                        operationalStructure: item.operationalStructure,
                                        numberOfYearsOperatingInTheSameLocation: item.numberOfYearsOperatingInTheSameLocation,
                                        foreignExchangeRisk: item.foreignExchangeRisk,
                                        membershipOfAnOrganizedBody: item.membershipOfAnOrganizedBody,

                                        //others
                                        guarantors: item.guarantors,
                                        stakeholders: item.stakeholders,
                                        collaterals: item.collaterals,
                                        notepads: item.notepads,
                                        assets: item.assets,
                                        loanDetails: item.loanDetails,
                                        incomeDetails: item.incomeDetails
                                    }}
                                    validate={(values) => {
                                        const errors = {};
                                        return errors;
                                    }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        swal({
                                            title: "Confirm!",
                                            text: `Are you sure you want to proceed to Save this Loan Request?`,
                                            icon: "warning",
                                            buttons: {
                                                confirm: {
                                                    text: "Yes",
                                                    value: null,
                                                    visible: true,
                                                    className: "",
                                                    closeModal: true,
                                                },
                                                cancel: {
                                                    text: "Cancel",
                                                    value: true,
                                                    visible: true,
                                                    className: "",
                                                    closeModal: true,
                                                },
                                            },
                                            closeOnClickOutside: false,
                                            dangerMode: false,
                                        }).then(async (resp) => {
                                            if (resp) {
                                                // console.log("cancelling action...");
                                                return false;
                                            } else {
                                                try {
                                                    // console.log("progressing action...");
                                                    const currentUserSession = getCurrentUserSession();
                                                    var payload: any = {};
                                                    payload.loanRequestId = item.id
                                                    payload.action = "Submit"
                                                    payload.itemLevel = item.currentLevel
                                                    payload.userID = currentUserSession.userId
                                                    payload.roleID = currentUserSession.userRoleId
                                                    payload.systemProcessID = 0
                                                    payload.errorMessage = ""
                                                    payload.logType = "UserType"
                                                    payload.comment = "Done with data entry"
                                                    payload.effectiveStatus = "Valid"
                                                    payload.processResponse = null
                                                    payload.IsGroupLoan = true
                                                    // console.log("Payload: ", payload)
                                                    const response = await appraisalActionService.createAppraisalAction(payload)
                                                    if (response.status >= 200 && response.status <= 300) {
                                                        // toggleAppraisalProcess()
                                                        activityTrailService.saveActivityTrail({
                                                            actionType: "Add Appraisal Action",
                                                            description: `Added new Appraisal Action`,
                                                        });
                                                        toast.info(`Loan Request Details Submitted for Validation Successfully!`, {
                                                            autoClose: 6000,
                                                            type: toast.TYPE.INFO,
                                                            hideProgressBar: false,
                                                        });
                                                        // toast.info(`Loan Request Appraisal Process for ${values.customerName} would start shortly!`, {
                                                        //   autoClose: 6000,
                                                        //   type: toast.TYPE.INFO,
                                                        //   hideProgressBar: false,
                                                        // });
                                                    } else {
                                                        return false;
                                                    }
                                                } catch (error) {
                                                    toast.error(error.response.data, {
                                                        autoClose: 6000,
                                                        type: toast.TYPE.ERROR,
                                                        hideProgressBar: false,
                                                    });
                                                }
                                            }
                                        });
                                    }}
                                >
                                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                        <form onSubmit={handleSubmit} ref={(ref) => (this.formRef = ref)}>
                                            <ReactWizard
                                                className="row my-3 mx-2 font-italic font-weight-bold"
                                                style={{ fontSize: '120px' }}
                                                steps={
                                                    [
                                                        {
                                                            stepName: "Personal Details",
                                                            component: GroupPersonalDetails,
                                                            stepProps: {
                                                                values: this.state.item,
                                                                handleChange: handleChange,
                                                                handleBlur: handleBlur,
                                                                reloadItem: reloadItem
                                                            },
                                                        },
                                                        {
                                                            stepName: "Group Contact Details",
                                                            component: GroupContactDetails,
                                                            stepProps: {
                                                                values: this.state.item,
                                                                handleChange: handleChange,
                                                                handleBlur: handleBlur,
                                                                reloadItem: reloadItem
                                                            },
                                                        },
                                                        {
                                                            stepName: "Document Collection",
                                                            component: GroupDocumentCollection,
                                                            stepProps: {
                                                                values: item,
                                                                handleChange: handleChange,
                                                                handleBlur: handleBlur,
                                                                reloadItem: reloadItem
                                                            },
                                                        },
                                                        // {
                                                        //     stepName: "Bank Statement",
                                                        //     component: GroupFinancialAnalysis,
                                                        //     stepProps: {
                                                        //         values: item,
                                                        //         handleChange: handleChange,
                                                        //         handleBlur: handleBlur,
                                                        //         reloadItem: reloadItem
                                                        //     },
                                                        // },
                                                        {
                                                            stepName: "Notepad Details",
                                                            component: GroupNotepad,
                                                            stepProps: {
                                                                values: item,
                                                                handleChange: handleChange,
                                                                handleBlur: handleBlur,
                                                                reloadItem: reloadItem
                                                            },
                                                        },
                                                        {
                                                            stepName: "Loan Details",
                                                            component: GroupLoanDetails,
                                                            stepProps: {
                                                                values: item,
                                                                handleChange: handleChange,
                                                                handleBlur: handleBlur,
                                                                reloadItem: reloadItem
                                                            },
                                                        }
                                                    ]}
                                                previousButtonClasses={isSubmitting ? "hide" : ""}
                                                finishButtonClasses={isSubmitting ? "hide" : ""}
                                                nextButtonClasses={isSubmitting ? "hide" : ""}
                                                validate
                                                finishButtonText="Save and Proceed"
                                                navSteps
                                                color="blue"
                                                finishButtonClick={() => {
                                                    this.formRef.dispatchEvent(new Event("submit"));

                                                }}

                                            />
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        );
    }
}

export default GroupDetailedDataEntry;
