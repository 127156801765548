import http from "../httpService";

const recovaCreditAssessmentBaseAddress = process.env.REACT_APP_CREDITASSESSMENT_BASEADDRESS;
// const recovaCreditAssessmentBaseAddress = "https://localhost:44309/api/";

export function addOrRemoveCreditQualificationIndicatorsToInstitution(
  institutionCode,
  indicators
) {
  // console.log("CQ Rule: ", creditQualificationRule);
  return http.post(
    recovaCreditAssessmentBaseAddress +
      `CreditQualification/indicators/institutions/${institutionCode}/AddOrRemove`,
    indicators
  );
}

export function syncCreditQualificationKnockoffs() {
  // console.log("CQ Rule: ", creditQualificationRule);
  return http.put(
    recovaCreditAssessmentBaseAddress +
      `CreditQualification/indicators/sync`
  );
}

// export function removeCreditQualificationIndicatorsFromInstitution(
//   institutionCode,
//   indicators
// ) {
//   return http.put(
//     recovaCreditAssessmentBaseAddress +
//       `CreditQualification/indicators/institutions/${institutionCode}/remove`,
//     indicators
//   );
// }

export function retrieveCreditQualificationIndicatorById(id) {
  return http.get(
    recovaCreditAssessmentBaseAddress +
      `CreditQualification/indicators/get/${id}`
  );
}

export function retrieveLoanOriginationChannels() {
  return http.get(
    recovaCreditAssessmentBaseAddress +
      `CreditQualification/loanchannels/get`
  );
}

export function retrieveCreditQualificationIndicators() {
  return http.get(
    recovaCreditAssessmentBaseAddress +
      `CreditQualification/indicators/get`
  );
}

export function retrieveCreditQualificationIndicatorsByInstitution(
  institutionCode
) {
  return http.get(
    recovaCreditAssessmentBaseAddress +
      `CreditQualification/indicators/getbyinstitution/${institutionCode}`
  );
}

export function retrieveCreditQualificationRuleById(id) {
  return http.get(
    recovaCreditAssessmentBaseAddress +
      `CreditQualification/rules/get/${id}`
  );
}

export function retrieveCreditQualificationRules() {
  return http.get(
    recovaCreditAssessmentBaseAddress +
      `CreditQualification/rules/get`
  );
}

export function retrieveCreditQualificationRulesByInstitution(institutionCode) {
  return http.get(
    recovaCreditAssessmentBaseAddress +
      `CreditQualification/rules/getbyinstitution/${institutionCode}`
  );
}

// export function retrieveByInstitution(institutionCode) {
//   return http.get(
//     recovaCreditAssessmentBaseAddress +
//       `CreditQualification/rules/getbyinstitution/${institutionCode}`
//   );
// }

export function saveCreditQualificationRule(creditQualificationRule) {
  // console.log("CQ Rule: ", creditQualificationRule);
  return http.post(
    recovaCreditAssessmentBaseAddress +
      `CreditQualification/rules/create`,
    creditQualificationRule
  );
}

export function updateCreditQualificationRule(creditQualificationRule, id) {
  // console.log("CQ Rule: ", creditQualificationRule);
  return http.put(
    recovaCreditAssessmentBaseAddress +
      `CreditQualification/rules/${id}/edit`,
    creditQualificationRule
  );
}

export function filterPagedCreditQualificationRules(
  data: any,
  pageSize: number,
  pageNumber: number
) {
  return http.post(
    recovaCreditAssessmentBaseAddress +
      `CreditQualificationRule/FilterCreditQualificationRules?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    data
  );
}

export default {
  retrieveCreditQualificationIndicators,
  filterPagedCreditQualificationRules,
  saveCreditQualificationRule,
  updateCreditQualificationRule,
  retrieveCreditQualificationRuleById,
  syncCreditQualificationKnockoffs,
  retrieveCreditQualificationIndicatorById,
  retrieveCreditQualificationIndicatorsByInstitution,
  retrieveCreditQualificationRules,
  retrieveCreditQualificationRulesByInstitution,
  // retrieveByInstitution,
  addOrRemoveCreditQualificationIndicatorsToInstitution,
  // removeCreditQualificationIndicatorsFromInstitution,
  retrieveLoanOriginationChannels,
};
