import React, { Component } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import { Button } from "react-bootstrap";
import textFormatService from "../../../../services/utility/textFormatService";
import DetailItem from "../../../../components/detailItem";
import ModifyLoanParams from "./modifyLoanParams";
import sweepConfigService from "../../../../services/sweep/sweepConfigService";
import SupportingDocuments from "./supportingDocuments";
import RepaymentSchedules from "./repaymentSchedules";
import Repayment from "../../loanAppraisal/appraisalDetailPages/wizardComponents/editDetailedDataEntry/repayment";
import { string } from "joi";

export interface ILoanRequestItemDetailProps {
  values: any;
  item: any;
  financialGroup: any;
  updateItem: any;
  updateItemDocs: any;
  deleteItemDocs: any;
  tempDocs: any;
  tempDeletedDocs: number[];
  canEditItem: boolean;
}

export interface ILoanRequestItemDetailState {
  item: any;
  document: any;
  showPatchModal: boolean;
  showDocPatchModal: boolean;
  showScheduleModal: boolean;
  loanDetails: any;
  repaymentBankName: string;
  fetchingDetails: boolean;
  ownUpdate: boolean;
  grossIncome: string;
  grossExpense: string;
  eligibleAmount: string;
}

class LoanRequestItemDetail extends Component<
  ILoanRequestItemDetailProps,
  ILoanRequestItemDetailState
> {
  _isMounted = false;
  constructor(props: ILoanRequestItemDetailProps) {
    super(props);
    this.state = {
      item: {},
      document: {},
      showPatchModal: false,
      showDocPatchModal: false,
      showScheduleModal: false,
      loanDetails: {},
      repaymentBankName: "",
      fetchingDetails: true,
      ownUpdate: false,
      grossIncome: "",
      grossExpense: "",
      eligibleAmount: ""
    };
  }

  static getDerivedStateFromProps(
    nextProps: ILoanRequestItemDetailProps,
    prevState
  ) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (nextProps.item && prevState.item?.CustomerID != JSON.parse(JSON.parse(nextProps.item)).CustomerID) {
      // console.log("Updating state afresh")
      // console.log(JSON.parse(JSON.parse(nextProps.item)))
      return { item: JSON.parse(JSON.parse(nextProps.item)) };
    }
    return null;
  }

  async componentDidMount() {
    this._isMounted = true;
    this.loadDocs(this.props);

    if (this.props.values?.incomeDetails !== null) {
      var income = this.props.values?.incomeDetails[0].incomeList;
      var expense = this.props.values?.incomeDetails[0].expenseList;
      var tenure = Math.round(Number(this.props.values.tenureInDays / 30));


      var grossIncome = 0.00;
      var grossExpense = 0.00;
      income?.forEach(x => {
        switch (x.frequencyIncome) {
          case "Daily":
            x.amount = x.amount * 30
            break;
          case "Weekly":
            x.amount = x.amount * 4
            break;
          case "Monthly":
            x.amount = x.amount
            break;
          case "Quarterly":
            x.amount = x.amount / 3
            break;
          case "Semi - Annually":
            x.amount = x.amount / 6
            break;
          case "Annually":
            x.amount = x.amount / 12
            break;
          default:
            break;
        }
        grossIncome += Number(x.amount);
      })

      expense?.forEach(x => {
        switch (x.frequencyExpense) {
          case "Daily":
            x.expenseAmount = x.expenseAmount * 30
            break;
          case "Weekly":
            x.expenseAmount = x.expenseAmount * 4
            break;
          case "Monthly":
            x.expenseAmount = x.expenseAmount
            break;
          case "Quarterly":
            x.expenseAmount = x.expenseAmount / 3
            break;
          case "Semi - Annually":
            x.expenseAmount = x.expenseAmount / 6
            break;
          case "Annually":
            x.expenseAmount = x.expenseAmount / 12
            break;
          default:
            break;
        }
        grossExpense += Number(x.expenseAmount);
      })

      var maximumEligibleAmountPerMonth = 0.333 * grossIncome;
      var eligibleAmountWithInterest = maximumEligibleAmountPerMonth * tenure;
      var rate = Number(this.props.values?.loanDetails?.interestRate / 100) ? Number(this.props.values?.loanDetails?.interestRate / 100) : Number(20 / 100);
      var time = tenure / 12;
      var divisor = (rate * time) + 1;
      var eligibleAmount = eligibleAmountWithInterest / divisor;

      this.setState({ grossIncome: grossIncome.toString(), grossExpense: grossExpense.toString(), eligibleAmount: eligibleAmount.toString() })
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async loadDocs(nextProps: ILoanRequestItemDetailProps) {
    const stateItem = { ...this.state.item }
    let item: any = {};
    if (!_.isEmpty(stateItem)) {
      item = stateItem
    } else {
      item = JSON.parse(JSON.parse(nextProps.item))
    }
    let institutionName = "";
    if (!_.isEmpty(item.PreferredRepaymentBankCBNCode)) {
      try {
        institutionName = (await sweepConfigService.retrieveBankNameByCBNCode(item.PreferredRepaymentBankCBNCode)).data;
      } catch (error) { }
    }
    const loanDetails = JSON.parse(
      JSON.parse(JSON.parse(nextProps.item)).LoanDetails
    );
    if (_.isEmpty(institutionName) && loanDetails.PreferredRepaymentBankCBNCode != "") {
      try {
        institutionName = (await sweepConfigService.retrieveBankNameByCBNCode(loanDetails.PreferredRepaymentBankCBNCode)).data;
      } catch (error) { }
    }
    if (this._isMounted) {
      this.setState({ repaymentBankName: institutionName, loanDetails, fetchingDetails: false, ownUpdate: true })
    }
  }

  // toggleShowModal = () => {
  //   this.setState({ showPatchModal: !this.state.showPatchModal, ownUpdate: true });
  // };

  toggleShowDocModal = () => {
    this.setState({ showDocPatchModal: !this.state.showDocPatchModal, ownUpdate: true });
  };

  toggleShowScheduleModal = () => {
    this.setState({ showScheduleModal: !this.state.showScheduleModal, ownUpdate: true });
  };

  render() {
    const { values } = this.props
    const {
      item,
      loanDetails,
      showPatchModal,
      showDocPatchModal,
      showScheduleModal,
      repaymentBankName,
      fetchingDetails,
      grossExpense,
      grossIncome,
      eligibleAmount
    } = this.state;



    // console.log("item :: ", values)
    // console.log("Request :: ", loanDetails)

    return (
      <React.Fragment>
        <div
          className="table-responsive text-nowrap"
          style={{ height: "250px", overflowY: "scroll" }}
        >
          {/* {_.isEmpty(item) || _.isEmpty(loanDetails) ? (
            <div className="row">
              <Skeleton width={450} height={20} />
              <Skeleton width={450} height={20} />
            </div>
          ) : ( */}
          <dl className="row" style={{ marginBottom: "0px" }}>
            <DetailItem
              label="Product Name"
              value={loanDetails?.Product?.Name}
              labelSize={6}
              valueSize={6}
            />
            {/* <DetailItem
                label="Product Code"
                value={loanDetails.Product.Code}
                labelSize={6}
                valueSize={6}
              /> */}
            {/* <DetailItem
                label="Product Type"
                value={textFormatService.splitAtUpper(
                  ProductType[loanDetails.Product.ProductType]
                )}
                labelSize={6}
                valueSize={6}
              /> */}
          </dl>
          {/* )} */}
          {/* {_.isEmpty(item) || _.isEmpty(loanDetails) ? (
            <div className="row">
              <Skeleton width={450} height={20} />
              <Skeleton width={450} height={20} />
              <Skeleton width={450} height={20} />
              <Skeleton width={450} height={20} />
              <Skeleton width={450} height={20} />
            </div>
          ) : ( */}
          <div>
            <dl className="row" style={{ marginBottom: "0px" }}>
              <DetailItem
                label="Account Name"
                value={loanDetails.Name ? loanDetails.Name : values.customerName}
                labelSize={6}
                valueSize={6}
              />
              <DetailItem
                label="Account Number"
                value={values.linkedAccountNo}
                labelSize={6}
                valueSize={6}
              />
              <DetailItem
                label="Loan Account Number"
                value={loanDetails.Number ? loanDetails.Number : values.accountNumber}
                labelSize={6}
                valueSize={6}
              />
              <DetailItem
                label="Customer Name"
                value={loanDetails.CustomerName ? loanDetails.CustomerName : values.customerName}
                labelSize={6}
                valueSize={6}
              />
              <DetailItem
                label="Customer Balance"
                value={textFormatService.formatCurrency(values.customerBalance?values.customerBalance:0, 2)}
                labelSize={6}
                valueSize={6}
              />
              <DetailItem
                label="Account Officer"
                value={loanDetails.AccountOfficer ? loanDetails.AccountOfficer : values.relationshipManager}
                labelSize={6}
                valueSize={6}
              />
              <DetailItem
                label="Bank Verification Number"
                value={item.BVN ? item.BVN : values.bvn}
                labelSize={6}
                valueSize={6}
              />

              <DetailItem
                label="Branch"
                value={loanDetails.Branch ? loanDetails.Branch : values.branch}
                labelSize={6}
                valueSize={6}
              />
              <DetailItem
                label="Moratorium"
                value={loanDetails.Moratarium ? loanDetails.Moratarium : 0 + " Day(s)"}
                labelSize={6}
                valueSize={6}
              />
              {/* <DetailItem
                  label="Defaulting Interest"
                  value={loanDetails.DefaultingLoanInterest + "%"}
                  labelSize={6}
                  valueSize={6}
                /> */}
              {/* <DetailItem
                  label="Type Of Loan"
                  value={textFormatService.splitAtUpper(
                    LoanComputationMode[loanDetails.ComputationMode]
                  )}
                  labelSize={6}
                  valueSize={6}
                /> */}
              <DetailItem
                label="Tenure"
                value={values.tenureInDays + " Days"}
                labelSize={6}
                valueSize={6}
              />
              <DetailItem
                label="Loan Amount"
                value={textFormatService.formatCurrency(values.loanAmount / 100, 2)}
                labelSize={6}
                valueSize={6}
              />
              <DetailItem
                label="Interest Rate"
                value={loanDetails.InterestRate + "%"}
                labelSize={6}
                valueSize={6}
              />
              {/* <DetailItem
                  label="Security Deposit"
                  value={loanDetails.SecurityDeposit}
                  labelSize={6}
                  valueSize={6}
                /> */}
              <DetailItem
                label="Phone Number"
                value={item.PhoneNumber ? item.PhoneNumber : values.phonenumber}
                labelSize={6}
                valueSize={6}
              />
              {/* <DetailItem
                  label="Economic Sector"
                  value={textFormatService.splitAtUpper(
                    EconomicSector[loanDetails.EconomicSector]
                  )}
                  labelSize={6}
                  valueSize={6}
                /> */}
              {/* <DetailItem
                  label="Loan Repayment Schedule Type"
                  value={textFormatService.splitAtUpper(
                    LoanPaymentScheduleType[
                    loanDetails.LoanPaymentScheduleType
                    ]
                  )}
                  labelSize={6}
                  valueSize={6}
                /> */}
              {/* <DetailItem
                  label="Disbursement Mode"
                  value={item.DisbursementMode}
                  labelSize={6}
                  valueSize={6}
                /> */}
              <DetailItem
                label="Preferred Repayment Account"
                value={item.PreferredRepaymentAccount ? item.PreferredRepaymentAccount : loanDetails.PreferredRepaymentAccount}
                labelSize={6}
                valueSize={6}
              />
              <DetailItem
                label="Preferred Repayment Bank"
                value={repaymentBankName}
                labelSize={6}
                valueSize={6}
              />
              {/* <DetailItem
                  label="Repayment Type"
                  value={item.RepaymentType == "Collection" ? "Collection & Repayment" : item.RepaymentType}
                  labelSize={6}
                  valueSize={6}
                /> */}
              {/* <DetailItem
                  label="Collateral"
                  value={""}
                  labelSize={6}
                  valueSize={6}
                /> */}
              <DetailItem
                label="Gross Monthly Income"
                value={textFormatService.formatCurrency(Number(grossIncome), 2)}
                labelSize={6}
                valueSize={6}
              />
              <DetailItem
                label="Gross Monthly Payable"
                value={textFormatService.formatCurrency(Number(grossExpense), 2)}
                labelSize={6}
                valueSize={6}
              />
              {/* <DetailItem
                label="Debt to Income Ratio"
                value={""}
                labelSize={6}
                valueSize={6}
              /> */}
              <DetailItem
                label="Eligible Amount"
                value={textFormatService.formatCurrency(Number(eligibleAmount), 2)}
                labelSize={6}
                valueSize={6}
              />
              <DetailItem
                label="Repayment Schedule"
                value={
                  <Button
                    size="sm"
                    variant="primary"
                    onClick={() => this.toggleShowScheduleModal()}
                  >
                    <i className="fas fa-sm fa-eye mr-3" /> View Repayment Schedule
                  </Button>
                }
                labelSize={6}
                valueSize={6}
              />
            </dl>
            <br />
          </div>
          {/* )} */}
        </div>
        <hr />
        <br />
        <br />
        <div style={{ width: "40%", margin: "20px auto" }}>
          <h6
            className="font-weight-normal"
          >
            <Button
              size="sm"
              variant="primary"
              onClick={() => this.toggleShowDocModal()}
            >
              <i className="fas fa-sm fa-eye mr-3" /> View Supporting Documents
            </Button>
          </h6>
        </div>
        {/* <RepaymentSchedules
          item={item}
          showScheduleModal={showScheduleModal}
          toggleScheduleModal={(reloadDetails: boolean, document: any) => {
            this.toggleShowScheduleModal();
          }}
        /> */}
        <Repayment
          item={values}
          showPatchModal={this.state.showScheduleModal}
          togglePatchModal={this.toggleShowScheduleModal}
          values={values.loanDetails}
        />
        <SupportingDocuments
          item={item}
          values={this.props.values}
          updateItemDocs={(document: any) => this.props.updateItemDocs(document)}
          deleteItemDocs={(document: any, documentId: number) => this.props.deleteItemDocs(document, documentId)}
          tempDocs={this.props.tempDocs}
          tempDeletedDocs={this.props.tempDeletedDocs}
          showDocumentsModal={showDocPatchModal}
          toggleDocumentsModal={(reloadDetails: boolean, document: any) => {
            this.toggleShowDocModal();
          }}
        />
        {/* <ModifyLoanParams
          item={item}
          financialGroupUpperLimit={this.props.financialGroup.upperLimit / 100}
          financialGroupLowerLimit={this.props.financialGroup.lowerLimit / 100}
          showPatchModal={showPatchModal}
          togglePatchModal={(reloadDetails: boolean, item: any, amount: number) => {
            this.toggleShowModal();
            if (reloadDetails === true) {
              this.props.updateItem(item, amount);
            }
          }}
        /> */}
      </React.Fragment>
    );
  }
}

export default LoanRequestItemDetail;
