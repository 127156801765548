import React from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import { IBaseFormState } from "../../../components/BaseFormComponent";
import { ISelectItems } from "../../../components/detailItemSelectBox";
import SelectInputComponent from "../../../components/formSelectComponent";
import swal from "sweetalert";
import collectionConsentConfigService from "../../../services/mandate/collectionConsentConfigService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

interface IConfigureCollectionMessageProps { }
interface IConfigureCollectionMessageState {
  type: string;
  message: string;
  fetchingRequiredItems: boolean;

  placeHolderValidationResponse: any;
  passesPlaceHolderValidation: boolean;
  typeInputData: ISelectItems[];
}

class ConfigureCollectionMessage extends React.Component<
  IConfigureCollectionMessageProps,
  IBaseFormState & IConfigureCollectionMessageState
> {
  constructor(props: IConfigureCollectionMessageProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      fetchingRequiredItems: false,
      passesPlaceHolderValidation: false,
      type: "",
      message: "",
      placeHolderValidationResponse: {},
      typeInputData: [
        {
          name: "OTP",
          value: "OTP",
        },
        {
          name: "OTP & CardLast4Digits",
          value: "OTP_CardLast4Digits",
        },
        {
          name: "OTP & CardFirst4Digits",
          value: "OTP_CardFirst4Digits",
        },
        {
          name: "PhoneNumber",
          value: "PhoneNumber",
        },
        {
          name: "First4DigitsOfCard",
          value: "First4DigitsOfCard",
        },
        {
          name: "Last4DigitsOfCard",
          value: "Last4DigitsOfCard",
        },
      ],
    };
  }

  async componentWillMount() { }

  onHandleSubmit(e): boolean {
    e.preventDefault();
    try {
      swal({
        title: "Confirm!",
        text: `Are you sure you want to proceed to Save this message template for type - ${this.state.typeInputData.filter(
          (type) => type.value == this.state.type
        )[0].name
          }?`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode: true,
      }).then(async (resp) => {
        if (resp) {
          // this.setState({ updatingStatus: false });
          this.setState({ submitting: false });
        } else {
          //push to assign
          return this.submit();
        }
      });
      this.setState({ validated: true });
      return false;
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // return false;
      }
      return false;
    }
  }

  async submit(): Promise<boolean> {
    try {
      // console.log("starting submission...");
      this.setState({ submitting: true });

      const response =
        await collectionConsentConfigService.saveCollectionConsentTemplate(
          this.state.type,
          this.state.message
        );
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Configure Collection Consent Message",
          description: `Configure Collection Consent Message`,
        });
        toast.info(`Message template saved successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false });
      this.resetFields();
    } catch (error) {
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      // console.log("error: ", error.response.data);
      return false;
    } finally {
      this.setState({ submitting: false });
      return true;
    }
  }

  handleOnSelectType = async (id, value) => {
    try {
      this.setState({
        type: value,
        fetchingRequiredItems: true,
        message: "",
        passesPlaceHolderValidation: false,
        placeHolderValidationResponse: {},
      });
      let message =
        await collectionConsentConfigService.retrieveTemplateByCollectionConsentType(
          value
        );
      // console.log(message)
      this.setState({ fetchingRequiredItems: false });
      if (message.status == 200) {
        this.handleOnChangeMessage(message.data.template);
      }
    } catch (error) {
      toast.info("Something went wrong.", {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      toast.info(error.response.data.errors[0], {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
    }
  };

  handleOnChangeMessage = (value) => {
    try {
      //run validations
      let validation: any = {};
      validation.firstname = this.countOccurences(value, "[firstname]") >= 1;
      validation.repaymentbank =
        this.countOccurences(value, "[repaymentbank]") >= 1;
      validation.expectedrepayment =
        this.countOccurences(value, "[expectedrepayment]") >= 1;
      validation.loanamount = this.countOccurences(value, "[loanamount]") >= 1;
      this.setState({
        message: value,
        placeHolderValidationResponse: validation,
        passesPlaceHolderValidation:
          validation.firstname &&
          validation.repaymentbank &&
          validation.expectedrepayment &&
          validation.loanamount,
      });
    } catch (error) {
      toast.info("Something went wrong.", {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      toast.info(error.response.data.errors[0], {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
    }
  };

  countOccurences = (text, word) => {
    return text.split(word).length - 1;
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      // scoreIndicatorIds: []
    });
  }
  render() {
    const {
      errors,
      validated,
      type,
      message,
      submitting,
      typeInputData,
      fetchingRequiredItems,
      placeHolderValidationResponse,
    } = this.state;

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Assign Score Indicators</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <form action="#">
                  <div className="card no-b">
                    <div className="card-body">
                      <div className="form-row">
                        <div className="col-md-6">
                          <div className="form-row">
                            <SelectInputComponent
                              id="type"
                              name="type"
                              // divClass={6}
                              value={type}
                              items={typeInputData}
                              required={false}
                              validated={validated}
                              errors={errors}
                              onChange={this.handleOnSelectType}
                            />
                          </div>
                          <div className="form-row">
                            {type == "" ? (
                              ""
                            ) : (
                              <div className="form-group col-md-12 m-0">
                                <label
                                  htmlFor="message"
                                  className="col-form-label s-12"
                                >
                                  <span
                                    aria-hidden="true"
                                    className="mr-2"
                                    style={{ color: "#ed5564" }}
                                  >
                                    *
                                  </span>
                                  {fetchingRequiredItems ? (
                                    <i
                                      className={
                                        "fas fa-spinner fa-spin orange-text mr-2"
                                      }
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                  Message
                                </label>
                                <textarea
                                  id="message"
                                  name="message"
                                  placeholder="-- Dear [firstname], Approve [lenderbank] to create a debit mandate on your account in [repaymentbank] to repay [exectedrepayment], [interval] for your loan of [loanamount] Dial [ussdshortcode], Enter [consent types separated by comma e.g. OTP, last four digit of card] to proceed --"
                                  className={
                                    !validated
                                      ? "form-control r-0 light s-12"
                                      : errors.message
                                        ? "form-control r-0 light s-12 is-invalid"
                                        : "form-control r-0 light s-12 is-valid"
                                  }
                                  rows={6}
                                  value={message}
                                  onChange={(e) =>
                                    this.handleOnChangeMessage(
                                      e.currentTarget.value
                                    )
                                  }
                                ></textarea>
                                <div
                                  className="invalid-feedback"
                                  style={{
                                    visibility: errors.message,
                                  }}
                                >
                                  {errors.message}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <h5
                            className="text-center"
                            style={{ fontWeight: 400 }}
                          >
                            <i className="fas fa-tasks mr-2"></i>Validations
                          </h5>
                          <hr />
                          <div className="row pl-5">
                            <div className="col-md-12">
                              <ul style={{ listStyle: "none" }}>
                                <li className="brown-text small">
                                  {placeHolderValidationResponse.firstname ==
                                    true ? (
                                    <i className="fas fa-check-circle green-text mr-2"></i>
                                  ) : (
                                    <i className="fas fa-times-circle red-text mr-2"></i>
                                  )}
                                  There is a placeholder [firstname].
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-12">
                              <ul style={{ listStyle: "none" }}>
                                <li className="brown-text small">
                                  {placeHolderValidationResponse.repaymentbank ==
                                    true ? (
                                    <i className="fas fa-check-circle green-text mr-2"></i>
                                  ) : (
                                    <i className="fas fa-times-circle red-text mr-2"></i>
                                  )}
                                  There is a placeholder [repaymentbank].
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-12">
                              <ul style={{ listStyle: "none" }}>
                                <li className="brown-text small">
                                  {placeHolderValidationResponse.expectedrepayment ==
                                    true ? (
                                    <i className="fas fa-check-circle green-text mr-2"></i>
                                  ) : (
                                    <i className="fas fa-times-circle red-text mr-2"></i>
                                  )}
                                  There is a placeholder [expectedrepayment].
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-12">
                              <ul style={{ listStyle: "none" }}>
                                <li className="brown-text small">
                                  {placeHolderValidationResponse.loanamount ==
                                    true ? (
                                    <i className="fas fa-check-circle green-text mr-2"></i>
                                  ) : (
                                    <i className="fas fa-times-circle red-text mr-2"></i>
                                  )}
                                  There is a placeholder [loanamount].
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr />
                    </div>
                    <hr />
                    <div className="card-body">
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        disabled={
                          this.state.submitting ||
                          !this.state.passesPlaceHolderValidation
                        }
                        onClick={(e) => {
                          this.onHandleSubmit(e);
                        }}
                      >
                        {this.state.submitting === false ? (
                          <React.Fragment>
                            <i className="fas fa-lg fa-save mr-3" /> Save
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                            Please wait...
                          </React.Fragment>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ConfigureCollectionMessage;
