import React from "react";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import BaseFormComponent, { IBaseFormState } from "../../../../../components/BaseFormComponent";
import { ActionTypes } from "../../../../../enums/actionTypes";
import approvalValidationService from "../../../../../services/approval/approvalValidationService";
import textFormatService from "../../../../../services/utility/textFormatService";
import DetailItem from "../../../../../components/detailItem";
import DetailItemInputComponent from "../../../../../components/detailItemInputBox";
import consentRequestService from "../../../../../services/mandate/consentRequestService";
import { getCurrentUserSession } from "../../../../../services/auth/authService";
import { decodeToken } from "react-jwt";
import { toast } from "react-toastify";

interface ModifyLoanParamsFields {
  loanAmount: string;
  moratorium: string;
  interestRate: string;
  defaultingInterestRate: string;
  loanTenure: string;
}

class ModifyLoanParamsForm extends BaseFormComponent<ModifyLoanParamsFields> {}

interface IModifyLoanParamsProps {
  item: any;
  financialGroupUpperLimit: number;
  financialGroupLowerLimit: number;
  showPatchModal: boolean;
  togglePatchModal: any;
}
interface IModifyLoanParamsState {}

class ModifyLoanParams extends React.Component<
  IModifyLoanParamsProps,
  IBaseFormState & IModifyLoanParamsState
> {
  constructor(props: IModifyLoanParamsProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {}
    };
  }

  onFormSubmit(fields: ModifyLoanParamsFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors: approvalValidationService.validateModifyLoanItemForm(fields),
          validated: true,
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (fields: ModifyLoanParamsFields, onReloadFieldsCB: any) => {
    try {
      // Run further validations
      const errors = { ...this.state.errors };
      let hasError: boolean = false;
      if(Number(fields.loanAmount) < 0){
        errors["loanAmount"] = "Loan Amount cannot be negative.";
        hasError = true;
      }
      if(Number(fields.moratorium) < 0){
        errors["moratorium"] = "Moratorium cannot be negative.";
        hasError = true;
      }
      if(Number(fields.interestRate) < 0){
        errors["interestRate"] = "interestRate cannot be negative.";
        hasError = true;
      }
      if(Number(fields.defaultingInterestRate) < 0){
        errors["defaultingInterestRate"] = "defaultingInterestRate cannot be negative.";
        hasError = true;
      }
      if(Number(fields.loanTenure) < 0){
        errors["loanTenure"] = "loanTenure cannot be negative.";
        hasError = true;
      }

      if(hasError){
        this.setState({errors});
        return;
      }
      if(Number(fields.loanAmount) < this.props.financialGroupLowerLimit || Number(fields.loanAmount) > this.props.financialGroupUpperLimit){
        toast.error("Loan Amount must be within Financial group lower and upper limit.");
        return
      }


      this.setState({ submitting: true });
      const payload = this.props.item;
      const loanDetails = JSON.parse(payload.LoanDetails);

      // Update Loan Details 
      loanDetails.LoanAmount = Number(fields.loanAmount) * 100; //convert to kobo
      loanDetails.LoanAmountToNaira = textFormatService.convertKoboToNaira(loanDetails.LoanAmount, 2); //convert to Naira
      loanDetails.Moratarium = Number(fields.moratorium);
      loanDetails.InterestRate = Number(fields.interestRate);
      loanDetails.DefaultingLoanInterest = Number(fields.defaultingInterestRate);
      loanDetails.LoanCycle = Number(fields.loanTenure);


      const schedulePayload: any = {}
      schedulePayload.loanDetail = JSON.stringify(loanDetails);
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      let response = await consentRequestService.retrieveCollectionSchedulesForLoan(decodedToken?.["InstitutionId"], schedulePayload);
      // console.log(JSON.stringify(JSON.stringify(loanDetails)))

      let totalAmount: number = 0;
      let newSchedules: any = [];
      response.data.forEach(schedule => {
        totalAmount += schedule.amount;
        newSchedules.push({
          RepaymentDate: `/Date(${new Date(schedule.paymentDueDate).getTime()}+0100)/`, 
          RepaymentAmountInNaira: schedule.amount/100
        })
      });
      // [
      //   {
      //     "loanAccountNumber": "00320013020004615",
      //     "amount": 10000,
      //     "paymentFrequency": "Monthly",
      //     "paymentDueDate": "2022-04-30T00:00:00"
      //   },
      //   {
      //     "loanAccountNumber": "00320013020004615",
      //     "amount": 2500000,
      //     "paymentFrequency": "Monthly",
      //     "paymentDueDate": "2022-04-30T00:00:01"
      //   }
      // ]

      // Update Item next
      payload.LoanAmount = loanDetails.LoanAmount;
      payload.LoanTenure = loanDetails.LoanCycle;
      payload.TotalRepaymentExpected = totalAmount;
      payload.CollectionPaymentSchedules = newSchedules;
      payload.LoanDetails = JSON.stringify(loanDetails);

      // console.log("For checks::", payload);
      onReloadFieldsCB();
      this.setState({ submitting: false }, () =>
        this.props.togglePatchModal(true, JSON.stringify(JSON.stringify(payload)), loanDetails.LoanAmount)
      );
    } catch (error) {
      console.log(error)
      console.log(error.response)
      this.setState({ submitting: false });
    }
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false
    });
  }

  render() {
    const { item, showPatchModal, togglePatchModal } = this.props;
    const { validated, submitting, errors } = this.state;

    if (_.isEmpty(item)) {
      return null;
    }

    const loanDetails = JSON.parse(item.LoanDetails);
    // console.log("Loan det :: ", loanDetails)

    return (
      <Modal
        size="lg"
        backdrop="static"
        show={showPatchModal}
        onHide={togglePatchModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-edit fa-sm cyan-text mr-3" />
              Modify Loan Parameters
            </h5>
          </Modal.Title>
        </Modal.Header>
        <ModifyLoanParamsForm
          initialValues={{ 
            defaultingInterestRate: (loanDetails.DefaultingLoanInterest).toString(),
            interestRate: (loanDetails.InterestRate).toString(),
            loanAmount: (loanDetails.LoanAmount/100).toString(),
            loanTenure: (loanDetails.LoanCycle).toString(),
            moratorium: loanDetails.Moratarium.toString()
           }}
          FormComponent={({
            fields: { defaultingInterestRate, loanTenure, interestRate, loanAmount, moratorium },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    {_.isEmpty(item) ? (
                      <Skeleton width={200} />
                    ) : (
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        <b>General Information</b>
                      </h6>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={3} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Loan Amount"
                              value={
                                <DetailItemInputComponent
                                  id="loanAmount"
                                  type="number"
                                  name="loanAmount"
                                  placeholder=""
                                  value={loanAmount}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={5}
                              valueSize={7}
                            />
                            <DetailItem
                              label="Loan Tenure"
                              value={
                                <DetailItemInputComponent
                                  id="loanTenure"
                                  type="number"
                                  name="loanTenure"
                                  placeholder=""
                                  value={loanTenure}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={5}
                              valueSize={7}
                            />
                            <DetailItem
                              label="Moratorium"
                              value={
                                <DetailItemInputComponent
                                  id="moratorium"
                                  type="number"
                                  name="moratorium"
                                  placeholder=""
                                  value={moratorium}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={5}
                              valueSize={7}
                            />
                          </dl>
                        )}
                      </div>
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={3} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Interest Rate"
                              value={
                                <DetailItemInputComponent
                                  id="interestRate"
                                  type="number"
                                  name="interestRate"
                                  placeholder=""
                                  value={interestRate}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={5}
                              valueSize={7}
                            />
                            <DetailItem
                              label="Defaulting Interest Rate"
                              value={
                                <DetailItemInputComponent
                                  id="defaultingInterestRate"
                                  type="number"
                                  name="defaultingInterestRate"
                                  placeholder=""
                                  value={defaultingInterestRate}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={5}
                              valueSize={7}
                            />
                          </dl>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.UPDATE,
                      "Loan Item Parameters",
                      () => {
                        this.onFormSubmit({defaultingInterestRate, loanTenure, interestRate, loanAmount, moratorium}, onReloadFields);
                      }
                    );
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => togglePatchModal(false, {}, {})}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></ModifyLoanParamsForm>
      </Modal>
    );
  }
}

export default ModifyLoanParams;
