import http from "../httpService";
import { IFilterData } from "../approval/approvalItemService";
import { getCurrentUserSession } from "../auth/authService";
import { decodeToken } from "react-jwt";

const appraisalBaseAddress = process.env.REACT_APP_HYBRIDAPPRAISAL_BASEADDRESS;


export function createLoanCategory(payload) {
    return http.post(
        appraisalBaseAddress + `LoanCategory/CreateLoanCategory`,
        payload
    )
}
export function updateLoanCategory(payload) {
    return http.put(
        appraisalBaseAddress + `LoanCategory/EditLoanCategory`,
        payload
    );
}

export function retrieveById(id) {
    return http.get(
        appraisalBaseAddress + `LoanCategory/RetrieveById/${id}`
    );
}

export function retrieveAll() {
    return http.get(
        appraisalBaseAddress + `LoanCategory/RetrieveAll`
    );
}

export function retrieveAllByInstitutionCode(institutionCode) {
    return http.get(
        appraisalBaseAddress + `LoanCategory/RetrieveByInstitutionCode/${institutionCode}`
    );
}

export function filterLoanCategory(
    payload: IFilterData,
    maxSize: number,
    pageNum: number
) {
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    payload = { ...payload };
    payload.institutionCode = {
        item1: "=",
        item2: decodedToken?.["InstitutionCode"]?.toString() || "break",
    };
    return http.post(
        appraisalBaseAddress +
        `LoanCategory/FilterLoanCategory?pageNum=${pageNum}&maxSize=${maxSize}`,
        payload
    );
}

export default {
    createLoanCategory,
    updateLoanCategory,
    retrieveById,
    retrieveAll,
    retrieveAllByInstitutionCode,
    filterLoanCategory,
};
