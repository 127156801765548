import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";

const recovaOFIHandShakeBaseAddress = process.env.REACT_APP_RECOVA_OFI_HANDSHAKE_BASEADDRESS;

export function bvnEnquiry(institutionCode: string, bvn: string) {
    var url = recovaOFIHandShakeBaseAddress + `LoanRequest/ValidateGuarantorBvn?institutionCode=${institutionCode
        }&bvn=${bvn}`;
    return http.post(url, {
        url: url,
        method: "post"
    });
}

export default {
    bvnEnquiry
}