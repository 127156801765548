import React from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { ISelectItems } from "../../../components/detailItemSelectBox";
import FormInputComponent from "../../../components/formInputComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import SelectInputComponent from "../../../components/formSelectComponent";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import HybridAppraisalBillingService from "../../../services/billing/HybridAppraisalBillingService";

interface AddHybridAppraisalTranConfigFields {
  institutionCategory: string;
  feeName: string;
  flatPerRequest: string;
  flatPerApprovedLoan: string;
  percentageOfApprovedAmount: string;
  minimumFee: string;
  maximumFee: string;
  validationData: any;
}
class AddHybridAppraisalTranConfigForm extends BaseFormComponent<AddHybridAppraisalTranConfigFields> { }

interface IAddHybridAppraisalTranConfigProps { }
interface IAddHybridAppraisalTranConfigState {
  fetchingRequiredItems: boolean;
  validationItems: any;
  validationOption: IValidationOption[];
  flatPerApprovedLoan: any;
  flatPerRequest: any;
 }

interface IValidationOption {
  validationPricingOptionId: string;
  fee: string;
}

class AddHybridAppraisalTranConfig extends React.Component<
  IAddHybridAppraisalTranConfigProps,
  IBaseFormState & IAddHybridAppraisalTranConfigState
> {
  _isMounted = false;
  constructor(props: IAddHybridAppraisalTranConfigProps) {
    super(props);
    this.state = {
      flatPerApprovedLoan: 0,
      validated: false,
      submitting: false,
      fetchingRequiredItems: true,
      errors: {},
      validationItems: [],
      validationOption:[],
      flatPerRequest: ""
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { errors, validationOption } = this.state;
    try {
      if (this._isMounted) {
        this.setState({ fetchingRequiredItems: true });
      }
      const validationOptions = await HybridAppraisalBillingService.getValidationPricing();

      validationOptions.data.forEach(option => {
        validationOption.push({validationPricingOptionId: option.pricingOptionId, fee: ""})
      });
      // console.log(validationOptions)
      if (this._isMounted) {
        this.setState({
          validationItems: validationOptions.data,
          validationOption,
          fetchingRequiredItems: false,
        })
      }
      // console.log(this.state.validationItems)
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        errors.response = "Internal server error.";
        this.setState({ errors });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onFormSubmit(fields: AddHybridAppraisalTranConfigFields, onReloadFieldsCB: any): boolean {
    try {
      let sum_fee = Number(fields.flatPerRequest);
      fields.validationData.forEach((item)=>{return sum_fee += Number(item.fee);});
      console.log(sum_fee);
      this.setState(
        { flatPerApprovedLoan: sum_fee },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddHybridAppraisalTranConfigFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true });
      const payload: any = {};
      // const validationOption = [...this.state.validationOption];

      payload.institutionCategory = fields.institutionCategory;
      payload.feeName = fields.feeName;
      payload.flatPerRequest = Number(fields.flatPerRequest);
      payload.flatPerApprovedLoan = this.state.flatPerApprovedLoan;
      payload.validationOptions = fields.validationData;
      // console.log("payload: ", payload);

      const response = await HybridAppraisalBillingService.createTransactionFee(payload);
      // console.log(response);
      if (response.status >= 200 && response.status <= 300) {
        // console.log(response.status);
        // await activityTrailService.saveActivityTrail({
        //   actionType: "Add Hybrid Appraisal Subscription Fee Configuration",
        //   description: `Added new Hybrid Appraisal Subscription Fee Configuration ${fields.institutionCategory}`,
        // });
        toast.info(`Hybrid Appraisal Transaction Fee Configuration for ${fields.institutionCategory} created successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      }
        
      this.setState({ submitting: false });

       
        onReloadFieldsCB();
        this.ResetFields();
      }
     catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
    } finally {
      this.setState({ submitting: false });
      return true;
    }
  }

  handleValidationChange = (id,value) =>{
    const validationOption = [...this.state.validationOption];
    const flatPerApprovedLoan = [this.state.flatPerApprovedLoan];
    let flatPerApprovedLoan_ = Number(flatPerApprovedLoan);
    flatPerApprovedLoan_ = Number(flatPerApprovedLoan_)+Number(value);
    // this.setState({flatPerApprovedLoan:flatPerApprovedLoan_});
    
    validationOption.every(option => {
      if(option.validationPricingOptionId == id){
      
        option.fee = value;
        // this.setState({ submitting: false });
        return false;
      }
      // this.setState({flatPerApprovedLoan:flatPerApprovedLoan_})
      // Make sure you return true. If you don't return a value, `every()` will stop.
      return true;
    });
    // console.log("getting object",validationOption.find(option => option.validationPricingOptionId == id).fee);
   
   
          return validationOption;  
    // this.setState({validationOption: validationOption});
  }

  handleBlur = (value) =>{
    const validationOption = [...this.state.validationOption];
    const flatPerRequest = [this.state.flatPerRequest];
    let flatPerApprovedLoan_ = Number(flatPerRequest);
    
    // let flatPerApprovedLoan_ = Number(flatPerApprovedLoan);
    // flatPerApprovedLoan_ = Number(flatPerApprovedLoan_)+Number(value);
    // let sum_fee = Number(fields.flatPerRequest);
    validationOption.forEach((item)=>{return flatPerApprovedLoan_ += Number(item.fee);});
    this.setState({flatPerApprovedLoan:flatPerApprovedLoan_});
  }
  handleFlatPerRequestBlur(value){
    // console.log(value);
    const validationOption = [...this.state.validationOption];

    
    this.setState({flatPerRequest:value});

    
    const flatPerRequest = [this.state.flatPerRequest];
    const flatPerApprovedLoan = [this.state.flatPerApprovedLoan];
    console.log(flatPerRequest);
    let flatPerApprovedLoan_ = Number(flatPerRequest) + Number(flatPerApprovedLoan);

    this.setState({flatPerApprovedLoan:flatPerApprovedLoan_})


  }

  ResetFields() {
    // const validationOption = [...this.state.validationOption];

    // validationOption.forEach(option => {
    //   option.fee = "";
    // });

    // this.setState({validationOption});
  }

  render() {
    const { errors, validated, fetchingRequiredItems, validationItems, validationOption,flatPerApprovedLoan, flatPerRequest } = this.state;

    let institutionCategoryInputData: ISelectItems[] = [
      { name: "Commercial Bank", value: "CommercialBank" },
      { name: "BankOne OFI", value: "BankOneOFI" },
      { name: "OFI", value: "OFI" },
    ];

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add Hybrid Appraisal Transaction Fee Configuration</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddHybridAppraisalTranConfigForm
                  initialValues={{
                    institutionCategory: "",
                    feeName: "",
                    flatPerApprovedLoan: "",
                    flatPerRequest: "",
                    maximumFee: "",
                    minimumFee: "",
                    percentageOfApprovedAmount: "",
                    validationData: this.state.validationOption
                  }}
                  FormComponent={({
                    fields: {
                      institutionCategory,
                      feeName,
                      flatPerApprovedLoan,
                      flatPerRequest,
                      maximumFee,
                      minimumFee,
                      percentageOfApprovedAmount,
                      validationData
                    },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-row">
                                <SelectInputComponent
                                  id="institutionCategory"
                                  name="institutionCategory"
                                  divClass={6}
                                  value={institutionCategory}
                                  items={institutionCategoryInputData}
                                  required={true}
                                  validated={false}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="feeName"
                                  type="text"
                                  name="feeName"
                                  placeholder=""
                                  divClass={6}
                                  value={feeName}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                {/* <FormInputComponent
                                  id="percentageOfApprovedAmount"
                                  type="number"
                                  name="percentageOfApprovedAmount"
                                  placeholder=""
                                  divClass={6}
                                  value={percentageOfApprovedAmount}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                /> */}
                                <FormInputComponent
                                  id="flatPerRequest"
                                  type="number"
                                  name="flatPerRequest"
                                  placeholder=""
                                  divClass={6}
                                  value={flatPerRequest}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                  // onChange={(id,value)=>{this.handleFlatPerRequestBlur(value)}}
                                />
                                <FormInputComponent
                                  id="flatPerApprovedLoan"
                                  type="number"
                                  name="flatPerApprovedLoan"
                                  placeholder=""
                                  divClass={6}
                                  value={this.state.flatPerApprovedLoan}
                                  required={true}
                                  validated={validated}
                                  disabled={true}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                {/* <FormInputComponent
                                  id="maximumFee"
                                  type="number"
                                  name="maximumFee"
                                  placeholder=""
                                  divClass={6}
                                  value={maximumFee}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="minimumFee"
                                  type="number"
                                  name="minimumFee"
                                  placeholder=""
                                  divClass={6}
                                  value={minimumFee}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                       <div className="card no-b">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                                <label
                                  htmlFor="reports"
                                  className="col-form-label s-10"
                                >
                                  Validation Fees
                                </label>
                                <br />
                                  <div className="form-row">
                                    { fetchingRequiredItems ? (
                                    <i className="fas fa-spinner fa-spin mr-2" />
                                    ) : (
                                      validationData.map((data) => (
                                        <FormInputComponent
                                        id={data.validationPricingOptionId}
                                        type="number"
                                        // name={data.validationPricingOptionId}
                                        name={validationItems.find(option => option.pricingOptionId == data.validationPricingOptionId).name}
                                        placeholder=""
                                        divClass={6}
                                        // value={validationOption.find(option => option.validationPricingOptionId == report.pricingOptionId).fee}
                                        value={data.fee}
                                        required={true}
                                        validated={validated}
                                        errors={this.state.errors}
                                        onChange={(id, value) => {
                                          onChange('validationData', this.handleValidationChange(id, value))
                                          // this.handleValidationChange
                                        }}
                                        onBlur={(e)=>{this.handleBlur(e.target.value)}}
                                      />
                                      ))
                                    )}
                                  </div>
                              </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary"
                          disabled={this.state.submitting}
                          onClick={(e) => {
                            onHandleSubmit(
                              e,
                              ActionTypes.SAVE,
                              "Hybrid Appraisal Subscription Fee Configuration",
                              () => {
                                this.onFormSubmit(
                                  {
                                    institutionCategory,
                                    feeName,
                                    flatPerApprovedLoan,
                                    flatPerRequest,
                                    maximumFee,
                                    minimumFee,
                                    percentageOfApprovedAmount,
                                    validationData
                                  },
                                  onReloadFields
                                );
                              }
                            );
                          }}
                        >
                          {this.state.submitting === false ? (
                            <React.Fragment>
                              <i className="fas fa-lg fa-save mr-3" /> Save
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                              Please wait...
                            </React.Fragment>
                          )}
                        </button>
                      </div>
                    </form> 
                  )}
                ></AddHybridAppraisalTranConfigForm>
                     
              </div>
            </div>
          </div>
        </div>
      </React.Fragment >
    );
  }
}

export default AddHybridAppraisalTranConfig;