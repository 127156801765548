import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import textFormatService from "../../../services/utility/textFormatService";
import DetailItemsTable from "../../../components/tableComponents/detailItemsTable";
import { mapEntityList } from "../../../services/utility/autoMapperService";

interface IOldCollectionScheduleDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  // togglePatchModal: any;
  showTrail: any;
  item: any;
}

interface IOldCollectionScheduleDetailState {
  item: any;
  updatingStatus: boolean;
  scheduleRepayments: any;
  scheduleRepaymentsTotalCount: number;
  scheduleRepaymentsCurrentPage: number;
  fetchingScheduleRepayments: boolean;
}

class OldCollectionScheduleDetail extends Component<
  IOldCollectionScheduleDetailProps,
  IOldCollectionScheduleDetailState
> {
  constructor(props: IOldCollectionScheduleDetailProps) {
    super(props);
    this.state = {
      item: {},
      updatingStatus: false,
      scheduleRepayments: [],
      scheduleRepaymentsTotalCount: 0,
      scheduleRepaymentsCurrentPage: 1,
      fetchingScheduleRepayments: false,
    };
  }

  static getDerivedStateFromProps(
    nextProps: IOldCollectionScheduleDetailProps,
    prevState
  ) {
    // console.log("current props: ", nextProps.item);
    return {
      item: nextProps.item,
    };
  }

  async componentDidMount() {
    try {
      if (this.props.item.id) {
        // console.log(
        //   'about to fetch similar transactions...',
        //   this.props.item.id
        // )
        this.handleOnScheduleRepaymentsPageChange(
          this.state.scheduleRepaymentsCurrentPage
        );
      }
    } catch (error) {
      this.setState({ fetchingScheduleRepayments: false });
    }
  }

  handleOnScheduleRepaymentsPageChange = async (currentPage: number) => {
    currentPage =
      currentPage < 0 ? this.state.scheduleRepaymentsCurrentPage : currentPage;
    this.setState({ fetchingScheduleRepayments: true });
    // const decodedToken = decodeToken(getCurrentUserSession().token || "");
    // let dataClone =
    //   typeof data === "undefined" ? { ...this.state.data } : { ...data };
    try {
      // console.log("sched Reps",this.props.item.scheduleRepayments)
      const scheduleReps = this.props.item.scheduleRepayments;

      const scheduleRepayments: any = [];
      const totalLength = scheduleReps.length;
      const initial = (currentPage - 1) * 10;
      for (let i = initial; i < initial + 10; i++) {
        scheduleRepayments.push(scheduleReps[i]);
        if (i === totalLength - 1) {
          break;
        }
      }

      this.setState({
        scheduleRepayments: mapEntityList({
          // entityList: scheduleRepayments.data.item2,
          entityList: scheduleRepayments,
          properties: [
            { oldName: "repaymentDate", newName: "repaymentDate" },
            { oldName: "repaymentAmount", newName: "amountDue (₦)" },
            { oldName: "repaymentBalance", newName: "repaymentBalance (₦)" },
            { oldName: "amountPaid", newName: "amountPaid (₦)" },
            { oldName: "repaymentMethod", newName: "repaymentMethod" },
            {
              isTag: true,
              oldName: "paymentStatus",
              newName: "status",
              success: "Fully paid",
              danger: "No payment",
              default: "default",
              primary: "Pending",
              secondary: "partial payment",
              warning: "warning",
              light: "light",
              dark: "dark",
            },
          ],
        }),
        scheduleRepaymentsCurrentPage: currentPage,
        // totalSize: scheduleRepayments.data.item1,
        scheduleRepaymentsTotalCount: totalLength,
        fetchingScheduleRepayments: false,
      });

      // public bool IsArchived { get; set; }
      // public DateTime? DateArchived { get; set; }
      // public bool IsRemoved { get; set; }
      // public DateTime? RemovedDate { get; set; }
    } catch (ex) {
      //   console.log("caught exception", ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        this.setState({ fetchingScheduleRepayments: false });
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        this.setState({ fetchingScheduleRepayments: false });
      }
    }
  };

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      // togglePatchModal,
      showTrail,
      item,
    } = this.props;
    const { updatingStatus } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="icon icon--columns s-18 mr-3" />
                Schedule Details -{" "}
                <span className="font-weight-bold">{item.customerName}</span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
              {/* <div className="float-right">
                {_.isEmpty(item) ? (
                  <Skeleton width={100} />
                ) : (
                  <span
                    className="badge btn-primary"
                    onClick={showTrail}
                    style={{
                      cursor: "pointer",
                      visibility: currentUserRoles.includes(
                        "viewaudittrailitems"
                      )
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    View Trail
                  </span>
                )}
              </div> */}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={7} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Customer Name"
                        value={item.customerName}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Bvn"
                        value={item.bvn}
                        labelSize={5}
                        valueSize={7}
                      />
                      {/* <DetailItem
                        label="External Mandate Reference"
                        value={item.externalMandateReference}
                        labelSize={5}
                        valueSize={7}
                      /> */}
                      <DetailItem
                        label="Loan Expiry Date"
                        value={textFormatService.formatTimeString(
                          item.loanExpiryDate
                        )}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Loan Status"
                        value={item.loanStatus}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Date Updated"
                        value={textFormatService.formatTimeString(
                          item.dateUpdated
                        )}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Recovery Switch Mode"
                        value={item.recoverySwitchMode}
                        labelSize={5}
                        valueSize={7}
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={7} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Phone Number"
                        value={item.phoneNumber}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Mandate Reference"
                        value={item.mandateReference}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Total Expected Repayment (₦)"
                        value={item.totalExpectedRepayment}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Loan Tenure (In Days)"
                        value={item.loanTenure}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Date Created"
                        value={textFormatService.formatTimeString(
                          item.dateCreated
                        )}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Date Restructured"
                        value={textFormatService.formatTimeString(
                          item.dateRestructured
                        )}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Switch Recovery Window In Days"
                        value={item.switchRecoveryWindowInDays}
                        labelSize={5}
                        valueSize={7}
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>
            <DetailItemsTable
              title="Repayment Schedule"
              item={item}
              handleOnPageChange={this.handleOnScheduleRepaymentsPageChange}
              content={this.state.scheduleRepayments}
              contentCount={this.state.scheduleRepaymentsTotalCount}
              page={this.state.scheduleRepaymentsCurrentPage}
              fetching={this.state.fetchingScheduleRepayments}
              colorColumn={[
                { columnName: "amountPaid (₦)", textColor: "blue" },
                { columnName: "repaymentBalance (₦)", textColor: "black" },
              ]}
              amountColumn={[
                "amountDue (₦)",
                "repaymentBalance (₦)",
                "amountPaid (₦)",
              ]}
              amountInNaira={true}
              timeColumn={["repaymentDate"]}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
              {/* {item.status === "Inactive" &&
                getCurrentUsersRoles().includes("activateocminstitution") && (
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    disabled={updatingStatus}
                    onClick={(e) =>
                      this.onHandleEnableOrDisable(e,ActionTypes.ACTIVATE,"Blackbox Institution")
                    }
                  >
                    <i className="far fa-edit mr-3" />
                    Activate
                  </Button>
              )}
              {item.status === "Active" &&
                getCurrentUsersRoles().includes("deactivateocminstitution") && (
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    disabled={updatingStatus}
                    onClick={(e) =>
                      this.onHandleEnableOrDisable(e, ActionTypes.DEACTIVATE,"Blackbox Institution")
                    }
                  >
                    <i className="far fa-edit mr-3" />
                    Deactivate
                  </Button>
              )} */}
              {/* {getCurrentUsersRoles().includes("editocminstitution") && ( */}
              {/* <Button
                  size="sm"
                  variant="primary"
                  disabled={updatingStatus}
                  onClick={toggleEditModal}
                >
                  <i className="far fa-edit mr-3" />
                  Edit
                </Button> */}
              {/* )} */}
            </React.Fragment>
          )}

          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default OldCollectionScheduleDetail;
