import React, { Fragment } from "react";
import _ from "lodash";
import SelectInputComponent, { ISelectItems } from "../../../components/formSelectComponent";
import BaseFormComponent, { IBaseFormState } from "../../../components/BaseFormComponent";
import BaseListComponent, { IFilterData, BaseListComponentState } from "../../../components/BaseListComponent";
import { createFilterData, mapEntityList } from "./../../../services/utility/autoMapperService";
import ItemsTable, { filterFieldData } from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "./../../../components/tableComponents/tableFilterPane";
import { getCurrentUsersRoles } from "../../../services/auth/authService";
import recovaFeeConfigService from "../../../services/billing/recovaFeeConfigService";
import thirdPartyFeeConfigService from "../../../services/billing/thirdPartyFeeConfigService";
import CentralFeeConfigurationDetail from "./centralFeeConfigurationDetail";
import EditCentralFeeConfiguration from './editCentralFeeConfiguration';

interface CentralFeeConfigurationFields {
  institutionCategory: string;
  serviceOffering: string;
}

class CentralFeeConfigurationForm extends BaseFormComponent<CentralFeeConfigurationFields> {}

interface CentralFeeConfigurationProps {}

interface CentralFeeConfigurationState extends BaseListComponentState {
  institutionCategory: string;
  serviceOffering: string;
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
}

class CentralFeeConfigurationList extends BaseListComponent<CentralFeeConfigurationState> {}

class CentralFeeConfiguration extends React.Component<CentralFeeConfigurationProps, IBaseFormState & CentralFeeConfigurationState> {
  constructor(props: CentralFeeConfigurationProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      institutionCategory: "",
      serviceOffering: "",
      data: createFilterData([]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
    };
  }

  handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true });
    try {
      const recovaFeeConfigList = await recovaFeeConfigService.filterRecovaFeeConfig(
        this.state.serviceOffering, this.state.institutionCategory);
      const thirdPartyFeeConfigList = await thirdPartyFeeConfigService.filterThirdPartyFeeConfig(
        this.state.serviceOffering, this.state.institutionCategory, '');
      var mapThirdPartyFeeConfigList = thirdPartyFeeConfigList.data.map(({
        appzonePercentageAmountPerActiveLoan,
        appzonePercentageAmountPerLostLoan,
        appzoneFlatAmountPerSuccessfulTrx,
        appzoneMinimumFeePerSuccessfulTrx,
        appzoneMaximumFeePerSuccessfulTrx,
        thirdParty
      }) => ({
        thirdParty: thirdParty,
        percentageAmountPerActiveLoan: appzonePercentageAmountPerActiveLoan,
        percentageAmountPerLostLoan: appzonePercentageAmountPerLostLoan,
        amountPerSuccessfulTrx: appzoneFlatAmountPerSuccessfulTrx,
        minimumFeePerSuccessfulTrx: appzoneMinimumFeePerSuccessfulTrx,
        maximumFeePerSuccessfulTrx: appzoneMaximumFeePerSuccessfulTrx
      }));
      const centralFeeConfigs: any = [];
      const initial = (currentPage - 1) * pageSize;
      const recovaTotalLength = recovaFeeConfigList.data.length;
      const thirdPartyTotalLength = thirdPartyFeeConfigList.data.length;
      for (let i = initial; i < initial + pageSize; i++) {
        if (i === recovaTotalLength) {
          break;
        }
        recovaFeeConfigList.data[i].thirdParty = "Recova"
        centralFeeConfigs.push(recovaFeeConfigList.data[i]);
      }
      for (let i = initial; i < initial + pageSize; i++) {
        if (i === thirdPartyTotalLength) {
          break;
        }
        centralFeeConfigs.push(mapThirdPartyFeeConfigList[i]);
      }

      this.setState({
        tableItems: mapEntityList({
          entityList: centralFeeConfigs,
          properties: [
            { oldName: "thirdParty", newName: "name" },
            { oldName: "percentageAmountPerActiveLoan", newName: "activeLoanPercentage" },
            { oldName: "percentageAmountPerLostLoan", newName: "lostLoanPercentage" },
            { oldName: "amountPerSuccessfulTrx", newName: "amountPerSuccessfulTransaction" },
            { oldName: "minimumFeePerSuccessfulTrx", newName: "minimumFee" },
            { oldName: "maximumFeePerSuccessfulTrx", newName: "maximumFee" },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: recovaTotalLength + thirdPartyTotalLength,
        fetching: false
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
        this.setState({ fetching: false });
      } else {
        this.setState({ fetching: false });
      }
    } finally {
      this.setState({ fetching: false });
    }
  };

  handleOnExport = async () => {};

  fetchItemById = async (item: any) => {
    this.setState({ fetchingDetail: true });
    if (item.name === 'Recova') {
      var feeConfig = (await recovaFeeConfigService.filterRecovaFeeConfig(this.state.serviceOffering, this.state.institutionCategory)).data[0];
      feeConfig.name = item.name
    }
    else {
      var feeConfig = (await thirdPartyFeeConfigService.filterThirdPartyFeeConfig(
        this.state.serviceOffering, this.state.institutionCategory, item.name
      )).data[0];
      feeConfig.name = item.name
    }
    this.setState({ item: feeConfig, fetchingDetail: false });
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  handleOnInstitutionCategoryChange = async (id, institutionCategory: string) => {
    this.setState({
      serviceOffering: "",
      institutionCategory
    });
  }

  handleOnServiceOfferingChange = async (id, serviceOffering: string) => {
    this.setState({ serviceOffering }, () => { this.handlePageChange(this.state.currentPage, this.state.pageSize) })
  };

  render() {
    const {
      errors,
      validated,
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
      institutionCategory,
      serviceOffering,
    } = this.state;

    let institutionCategoryInputData: ISelectItems[] = [
      { name: "Commercial Bank", value: "CommercialBank" },
      { name: "BankOne OFI", value: "BankOneOFI" },
      { name: "OFI", value: "OFI" },
    ];

    let serviceOfferingInputData: ISelectItems[] = [
      { name: "Sweep", value: "Sweep" },
      { name: "Collections", value: "Collections" },
    ];

    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Central Fee Configuration</b>
              </h3>
            </div>
            <div className="card no-b">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <CentralFeeConfigurationForm
                      initialValues={{
                        institutionCategory: institutionCategoryInputData[0].value,
                        serviceOffering: serviceOfferingInputData[0].value,
                      }}
                      FormComponent={({
                        fields: {},
                      }) => (
                        <form action="#">
                          <div className="form-row">
                            <div className="col-md-6">
                              <SelectInputComponent
                                id="institutionCategory"
                                name="institutionCategory"
                                divClass={12}
                                value={institutionCategory}
                                items={institutionCategoryInputData}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={
                                  this.handleOnInstitutionCategoryChange
                                }
                              />
                            </div>
                            {this.state.institutionCategory != "" ? (
                              <div className="col-md-6">
                                <SelectInputComponent
                                  id="serviceOffering"
                                  name="serviceOffering"
                                  divClass={12}
                                  value={serviceOffering}
                                  items={serviceOfferingInputData}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={
                                    this.handleOnServiceOfferingChange
                                  }
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </form>
                      )}
                    ></CentralFeeConfigurationForm>

                    {institutionCategory && serviceOffering != "" ? (
                      <Fragment>
                        <div className="mt-3">
                          <CentralFeeConfigurationList
                            pageChangeHandler={this.handlePageChange}
                            fetchingDetail={fetchingDetail}
                            initialValues={{
                              data: createFilterData([]) as IFilterData,
                              fetching: false,
                              showDetailsModal: false,
                              showEditModal: false,
                              showTableFilterModal: false,
                              showTrailModal: false,
                              tableItems: [],
                              filterValueInput: [],
                              item: {},
                              jsonBefore: {},
                              jsonAfter: {},
                              fetchingTrail: false,
                            }}
                            tableItems={tableItems}
                            item={item}
                            DetailsModal={({
                              item,
                              onToggleDetailsModal,
                              onToggleEditModal,
                              showDetailsModal,
                              showEditModal,
                              onToggleTrailModal,
                            }) => (
                              <Fragment>
                                <CentralFeeConfigurationDetail
                                  showDetailsModal={showDetailsModal}
                                  toggleDetailsModal={onToggleDetailsModal}
                                  toggleEditModal={onToggleEditModal}
                                  showTrail={() => onToggleTrailModal(item)}
                                  currentUserRoles={getCurrentUsersRoles()}
                                  item={item}
                                />
                                <EditCentralFeeConfiguration
                                  showEditModal={showEditModal}
                                  toggleEditModal={(reloadDetails: boolean, item: any) => {
                                    onToggleEditModal();
                                    if (reloadDetails === true) {
                                      this.fetchItemById(item);
                                    }
                                    this.setState({
                                      showDetailsModal: !this.state.showDetailsModal,
                                    });
                                  }}
                                  item={item}
                                />
                              </Fragment>
                            )}
                            ListPayLoad={({
                              data,
                              showTableFilterModal,
                              tableItems,
                              onToggleDetailsModal,
                              onToggleTableFilterModal,
                              onHandleFilterItemRemove,
                              filterValueInput,
                            }) => (
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="card no-b">
                                    <div className="card-body">
                                      <div className="card-title">
                                        <div id="list-filters" className="ml-4 mr-4">
                                          <TableFilterPane
                                            filteredItems={this.getFilteredItems(data)}
                                            handleFilterRemove={onHandleFilterItemRemove}
                                          />
                                        </div>
                                        <div id="list-table">
                                          <ItemsTable
                                            data={data}
                                            items={tableItems}
                                            filterValueInput={filterValueInput}
                                            showTableFiltermodal={showTableFilterModal}
                                            toggleTableFiltermodal={onToggleTableFilterModal}
                                            name="Central Fee Configuration"
                                            currentPage={currentPage}
                                            pageSize={pageSize}
                                            totalSize={totalSize}
                                            fetching={fetching}
                                            handlePageChange={this.handlePageChange}
                                            onViewDetails={(item: any) => {
                                              this.fetchItemById(item);
                                              onToggleDetailsModal();
                                            }}
                                            handleOnExport={this.handleOnExport}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          ></CentralFeeConfigurationList>
                        </div>
                      </Fragment>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default CentralFeeConfiguration;
