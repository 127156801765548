import miscService from "../../../services/hybridAppraisal/miscService";
// export const docData = [
//     "Duly completed loan application form=Mandatory",
//     "Customer’s Letter of request=Mandatory",
//     "Duly executed Customer's Letter of Authority to Employer, instructing payments of all salary and benefits to Institution for the loan duration=Mandatory",
//     "Letter of Irrevocable Domiciliation of salary from Employer (Institution standard format)=Mandatory",
//     "RM's written verification that the signature of the HR designate on the domiciliation letter is authorized=Mandatory",
//     "Copy of Confirmation of Appointment Letter=Non Mandatory",
//     "Last three (3) months’ pay slip=Mandatory",
//     "Valid copy of acceptable Identification e.g. Driver’s License, International Passport=Mandatory",
//     "Business Registration Certificate=Mandatory",
//     "Executed Company’s Board resolution on Company’s letterhead=Mandatory",
//     "Most current twelve (12) months Account Statement of the Business with other Banks=Mandatory",
//     "Completed Interview Form=Mandatory",
//     "Site Visitation Form=Mandatory",
//     "Valid Invoice for Asset to be financed from Institution approved Vendor=Mandatory",
//     "Copy of Offer of sale of property by vendor=Mandatory",
//     "Valuation report on property security by Institution accredited Valuer=Mandatory",
//     "Provisional allocation letter=Mandatory",
//     "BOQ indicating construction milestones (for Construction Mortgage)=Mandatory",
//     "Solidarity Group Form=Mandatory",
//     "Credit Committee Notes=Mandatory",
//     "Internal Rules and Regulations=Mandatory"
// ]
export const docData = miscService.RetrieveProductDocumentData();