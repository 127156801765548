import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import swal from "sweetalert";
import { ActionTypes } from "../../../enums/actionTypes";
import DetailItem from "../../../components/detailItem";

interface ICollectionInstitutionDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  // togglePatchModal: any;
  showTrail: any;
  item: any;
}

interface ICollectionInstitutionDetailState {
  item: any;
  updatingStatus: boolean;
}

class CollectionInstitutionDetail extends Component<
  ICollectionInstitutionDetailProps,
  ICollectionInstitutionDetailState
> {
  constructor(props: ICollectionInstitutionDetailProps) {
    super(props);
    this.state = {
      item: {},
      updatingStatus: false
    };
  }

  static getDerivedStateFromProps(
    nextProps: ICollectionInstitutionDetailProps,
    prevState
  ) {
    // console.log("current props: ", nextProps.item);
    return {
      item: nextProps.item,
    };
  }

  onHandleEnableOrDisable = (
    e: any,
    actionType: ActionTypes,
    entityName: string
  ) => {
    this.setState({ updatingStatus: true });
    if (!actionType) {
      this.handleEnableOrDisableItem(this.props.item);
    } else {
      swal({
        title: "Confirm!",
        text: `Are you sure you want to proceed to ${ActionTypes[actionType]} this ${entityName}?`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode: true,
      }).then((resp) => {
        if (resp) {
          // console.log("cancelling action...");
          this.setState({ updatingStatus: false });
        } else {
          this.handleEnableOrDisableItem(this.state.item);
        }
      });
    }
  };

  handleEnableOrDisableItem = async (_item: any) => {
    // try {
    //   // this.setState({ updatingStatus: true });
    //   await activityTrailService.saveActivityTrail({
    //     actionType: `${
    //       _item.status === "Inactive" ? "Activate" : "Deactivate"
    //     } Blackbox Institution`,
    //     description: `Clicked on ${
    //       _item.status === "Inactive" ? "Activate" : "Deactivate"
    //     } to ${_item.status === "Inactive" ? "Activate" : "Deactivate"} Blackbox Institution ${
    //       _item.institutionName
    //     }`,
    //   });
    //   switch (_item.status) {
    //     case "Inactive":
    //       await blackboxInstitutionService.activateBlackboxInstitution(_item.id);
    //       _item.status = "Active";
    //       break;
    //     case "Active":
    //       await blackboxInstitutionService.deactivateBlackboxInstitution(_item.id);
    //       _item.status = "Inactive";
    //       break;
    //     default:
    //       console.log("Check Data Type");
    //       break;
    //   }

    //   toast.info(
    //     `Institution ${_item.institutionName} ${
    //       _item.status === "Active" ? "Activated" : "Deactivated"
    //     } successfully! `,
    //     {
    //       autoClose: 6000,
    //       type: toast.TYPE.DEFAULT,
    //       hideProgressBar: false,
    //     }
    //   );
    //   this.setState({ updatingStatus: false, item: _item });
    // } catch (ex) {
    //   this.setState({ updatingStatus: false, item: _item });
    // }
  };

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      // togglePatchModal,
      showTrail,
      item,
    } = this.props;
    const { updatingStatus } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="icon icon--columns s-18 mr-3" />
                Institution Details -{" "}
                <span className="font-weight-bold">{item.institutionName}</span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
              {/* <div className="float-right">
                {_.isEmpty(item) ? (
                  <Skeleton width={100} />
                ) : (
                  <span
                    className="badge btn-primary"
                    onClick={showTrail}
                    style={{
                      cursor: "pointer",
                      visibility: currentUserRoles.includes(
                        "viewaudittrailitems"
                      )
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    View Trail
                  </span>
                )}
              </div> */}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={2} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Name"
                        value={item.institutionName}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Email Address"
                        value={item.emailAddress}
                        labelSize={5}
                        valueSize={7}
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={2} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Code"
                        value={item.institutionCode}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Status"
                        value={
                          (item ? item.status : "Active") === "Active" ? (
                            <span className="badge badge-success">
                              {item ? item.status : ""}
                            </span>
                          ) : (
                            <span className="badge badge-danger">
                              {item ? item.status : ""}
                            </span>
                          )
                        }
                        labelSize={5}
                        valueSize={7}
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
              {/* {item.status === "Inactive" &&
                getCurrentUsersRoles().includes("activateocminstitution") && (
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    disabled={updatingStatus}
                    onClick={(e) =>
                      this.onHandleEnableOrDisable(e,ActionTypes.ACTIVATE,"Blackbox Institution")
                    }
                  >
                    <i className="far fa-edit mr-3" />
                    Activate
                  </Button>
              )}
              {item.status === "Active" &&
                getCurrentUsersRoles().includes("deactivateocminstitution") && (
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    disabled={updatingStatus}
                    onClick={(e) =>
                      this.onHandleEnableOrDisable(e, ActionTypes.DEACTIVATE,"Blackbox Institution")
                    }
                  >
                    <i className="far fa-edit mr-3" />
                    Deactivate
                  </Button>
              )} */}
              {/* {getCurrentUsersRoles().includes("editocminstitution") && (
                <Button
                  size="sm"
                  variant="primary"
                  disabled={updatingStatus}
                  onClick={toggleEditModal}
                >
                  <i className="far fa-edit mr-3" />
                  Edit
                </Button>
              )} */}
            </React.Fragment>
          )}

          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CollectionInstitutionDetail;
