import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import textFormatService from "../../../services/utility/textFormatService";
import { mapEntityList } from "../../../services/utility/autoMapperService";
import DetailItemsTable from "../../../components/tableComponents/detailItemsTable";
import linkingReportService from "../../../services/linking/linkingReportService";
import bvnAccountMapService from "../../../services/linking/bvnAccountMapService";
import LinkedAccountsDetail from "./linkedAccountsDetail";

interface ILinkingReportDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  item: any;
}

interface ILinkingReportDetailState {
  currentPage: number;
  fetching: boolean;
  count: number;
  tableItems: any;
  showLinkedAccountsModal: boolean;
  linkedItem: any;
  linkingItemFetching: boolean;
}

class LinkingReportDetail extends Component<
  ILinkingReportDetailProps,
  ILinkingReportDetailState
> {
  constructor(props: ILinkingReportDetailProps) {
    super(props);
    this.state = {
      currentPage: 1,
      fetching: false,
      count: 0,
      tableItems: [],
      showLinkedAccountsModal: false,
      linkedItem: {},
      linkingItemFetching: false,
    };
  }

  fetchLinkingInfoById = async (item) => {
    try {
      // console.log("item clicked ::",item)
      // console.log("Props item :: ", this.props.item)
      this.setState({ linkingItemFetching: true });
      const linking = await bvnAccountMapService.retrieveAccountMapsByBvnAndInstitution(
        item.BVN,
        this.props.item.institutionCode
      );
      // console.log("fetched :: ",linking.data)
      this.setState({ linkedItem: linking.data });
    } catch (error) {
      // return {};
    } finally {
      this.setState({ linkingItemFetching: false });
      // return {};
    }
  };

  handleOnToggleLinkedAccountsModal = () => {
    this.setState({ showLinkedAccountsModal: !this.state.showLinkedAccountsModal });
    if (this.state.showLinkedAccountsModal === true) {
      this.setState({ linkedItem: {} });
    }
  };

  handleOnDetailPageChange = async (currentPage: number) => {
    this.setState({ fetching: true });
    try {
      const payload: any = {};
      const linkings = await linkingReportService.filterLinkingReportDetail(
        payload,
        this.props.item.id,
        Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
        currentPage
      );
      this.setState({
        tableItems: mapEntityList({
          entityList: linkings.data.item2,
          properties: [
            { oldName: "accountName", newName: "Name" },
            { oldName: "bvn", newName: "BVN" },
            {
              isTag: true,
              oldName: "linkingType",
              newName: "linkingType",
              success: "Active",
              danger: "Inactive",
              default: "default",
              primary: "BVN",
              secondary: "secondary",
              warning: "warning",
              light: "light",
              dark: "dark",
            },
            { oldName: "linkedInstitutions", newName: "linkedInstitutions" },
            { oldName: "mandatesLinked", newName: "mandatesLinked" },
          ],
        }),
        currentPage: currentPage,
        count: linkings.data.item1,
        fetching: false,
      });
    } catch (error) {
    } finally {
      this.setState({ fetching: false });
    }
  };

  async componentDidMount() {
    if(!_.isEmpty(this.props.item)){
      this.handleOnDetailPageChange(this.state.currentPage);
    }
  }

  render() {
    const { showDetailsModal, toggleDetailsModal, item } = this.props;
    return (
      <React.Fragment>
        <LinkedAccountsDetail
          showDetailsModal={this.state.showLinkedAccountsModal}
          toggleDetailsModal={this.handleOnToggleLinkedAccountsModal}
          item={this.state.linkedItem}
        />
        <Modal
          size="xl"
          backdrop="static"
          show={showDetailsModal}
          onHide={toggleDetailsModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {_.isEmpty(item) ? (
                <Skeleton width={500} />
              ) : (
                <h5>
                  <i className="fas fa-receipt fa-sm s-18 mr-3" />
                  Linking Report Details
                </h5>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card">
              <div className="card-header clear-fix">
                {_.isEmpty(item) ? (
                  <Skeleton width={200} />
                ) : (
                  <h6
                    className="card-title float-left"
                    style={{ marginBottom: "0px" }}
                  >
                    <b>General Information</b>
                  </h6>
                )}
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    {_.isEmpty(item) ? (
                      <Skeleton count={6} width={300} />
                    ) : (
                      <dl className="row">
                        <DetailItem
                          label="Institution"
                          labelSize={6}
                          valueSize={6}
                          value={item.name}
                        />
                        <DetailItem
                          label="Total Linked BVN"
                          labelSize={6}
                          valueSize={6}
                          value={item.totalBVNLinked}
                        />
                        <DetailItem
                          label="First Linked Date"
                          labelSize={6}
                          valueSize={6}
                          value={textFormatService.formatTimeString(item.minLinkedDate)}
                        />
                      </dl>
                    )}
                  </div>
                  <div className="col-md-6">
                    {_.isEmpty(item) ? (
                      <Skeleton count={6} width={300} />
                    ) : (
                      <dl className="row">
                        <DetailItem
                          label="Institution Category"
                          labelSize={6}
                          valueSize={6}
                          value={item.category}
                        />
                        <DetailItem
                          label="Total Unique BVN"
                          labelSize={6}
                          valueSize={6}
                          value={item.totalUniqueBVN}
                        />
                        <DetailItem
                          label="Last Linked Date"
                          labelSize={6}
                          valueSize={6}
                          value={textFormatService.formatTimeString(item.maxLinkedDate)}
                        />
                      </dl>
                    )}
                  </div>
                </div>
              </div>
              <DetailItemsTable
                title="BVN Details"
                item={item}
                handleOnPageChange={this.handleOnDetailPageChange}
                content={this.state.tableItems}
                contentCount={this.state.count}
                page={this.state.currentPage}
                fetching={this.state.fetching}
                // amountColumn={["loanAmount"]}
                hasDetailModal={true}
                onViewDetails={(bvnItem) => {
                  this.fetchLinkingInfoById(bvnItem);
                  this.handleOnToggleLinkedAccountsModal();
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              variant="outline-danger"
              onClick={toggleDetailsModal}
            >
              <i className="fas fa-times mr-3" />
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default LinkingReportDetail;
