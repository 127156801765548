import React, { Component, Fragment, useContext } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import {
  Modal,
  Button,
  Card,
  useAccordionToggle,
  AccordionContext,
  Accordion,
} from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import { ActionTypes } from "../../../enums/actionTypes";
import SettlementAccounts from "../institution/components/settlementAccounts";
import swal from "sweetalert";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import sweepConfigService from "../../../services/sweep/sweepConfigService";
import { toast } from "react-toastify";
import textFormatService from "../../../services/utility/textFormatService";
import InstitutionRecovaFeeConfigurationDetails from "./institutionRecovaFeeConfigurationDetail";
import InstitutionThirdPartyFeeConfigurationDetails from "./institutionThirdPartyConfigurationDetail";

function ContextAwareToggle({ eventKey, header }) {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      <b>{header}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
}

interface IInstitutionSweepConfigurationDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  item: any;
  recovaRecoveryFeeConfigItem: any;
  recovaCollectionFeeConfigItem: any;
  thirdPartyRecoveryFeeConfigItem: any;
  thirdPartyCollectionFeeConfigItem: any;
  allInstitutions: any;
  allCommercialBanks: any;
}

interface IInstitutionSweepConfigurationDetailState {
  item: any;
  updatingStatus: boolean;
}

class InstitutionSweepConfigurationDetail extends Component<
  IInstitutionSweepConfigurationDetailProps,
  IInstitutionSweepConfigurationDetailState
> {
  state = {
    item: {},
    updatingStatus: false,
  };

  static getDerivedStateFromProps(
    nextProps: IInstitutionSweepConfigurationDetailProps,
    prevState
  ) {
    return {
      item: nextProps.item,
    };
  }

  onHandleEnableOrDisable = (
    e: any,
    actionType: ActionTypes,
    entityName: string
  ) => {
    this.setState({ updatingStatus: true });
    if (!actionType) {
      this.handleEnableOrDisableItem(this.props.item);
    } else {
      swal({
        title: "Confirm!",
        text: `Are you sure you want to proceed to ${ActionTypes[actionType]} this ${entityName}?`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode: true,
      }).then((resp) => {
        if (resp) {
          // console.log("cancelling action...");
          this.setState({ updatingStatus: false });
        } else {
          this.handleEnableOrDisableItem(this.state.item);
        }
      });
    }
  };

  handleEnableOrDisableItem = async (_item: any) => {
    try {
      this.setState({ updatingStatus: true });
      await activityTrailService.saveActivityTrail({
        actionType: `${
          _item.status === "Inactive" ? "Enable" : "Disable"
        } Institution`,
        description: `Clicked on ${
          _item.status === "Inactive" ? "Enable" : "Disable"
        } to ${
          _item.status === "Inactive" ? "Enable" : "Disable"
        } Institution ${_item.name}`,
      });
      switch (_item.status) {
        case "Inactive":
          await sweepConfigService.enableSweepConfig(_item.id);
          _item.status = "Active";
          break;
        case "Active":
          await sweepConfigService.disableSweepConfig(_item.id);
          _item.status = "Inactive";
          break;
        default:
          await sweepConfigService.enableSweepConfig(_item.id);
          _item.status = "Active";
          break;
      }
      toast.info(
        `Institution ${_item.name} ${
          _item.status === "Active" ? "Enabled" : "Disabled"
        } successfully! `,
        {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        }
      );
      this.setState({ updatingStatus: false, item: _item });
    } catch (ex) {
      this.setState({ updatingStatus: false, item: _item });
    }
  };

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      item,
      recovaRecoveryFeeConfigItem,
      recovaCollectionFeeConfigItem,
      thirdPartyRecoveryFeeConfigItem,
      thirdPartyCollectionFeeConfigItem,
      allInstitutions,
      allCommercialBanks,
    } = this.props;
    const { updatingStatus } = this.state;

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="fas fa-tools fa-sm cyan-text mr-3" />
                Sweep Configuration Details -{" "}
                <span className="font-weight-bold">{item.name}</span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={5} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Institution Name"
                        labelSize={7}
                        valueSize={5}
                        value={item.name}
                      />
                      <DetailItem
                        label="Short Name"
                        labelSize={7}
                        valueSize={5}
                        value={item.shortName}
                      />
                      <DetailItem
                        label="Code"
                        labelSize={7}
                        valueSize={5}
                        value={item.code}
                      />
                      <DetailItem
                        label="Max No. Of Sweeps Daily"
                        labelSize={7}
                        valueSize={5}
                        value={item.maxNoOfSweepsPerDay}
                      />
                      {item.integrationCode?.includes("BankOne") ? (
                        <DetailItem
                          label="External Institution Code"
                          labelSize={7}
                          valueSize={5}
                          value={item.externalInstitutionCode}
                        />
                      ) : (
                        ""
                      )}
                      <DetailItem
                        label="Total Inflow"
                        labelSize={7}
                        valueSize={5}
                        value={`₦ ${textFormatService.convertKoboToNaira(
                          item.totalInflow,
                          2
                        )}`}
                      />
                      <DetailItem
                        label="Threshold BaseLine"
                        labelSize={7}
                        valueSize={5}
                        value={`₦ ${textFormatService.convertKoboToNaira(
                          item.thresholdBaseLine,
                          2
                        )}`}
                      />
                      <DetailItem
                        label="Charge Customer For Transactions"
                        labelSize={7}
                        valueSize={5}
                        value={item.chargeCustomer.toString()}
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={5} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Status"
                        labelSize={7}
                        valueSize={5}
                        value={
                          (item ? item.status : "Active") === "Active" ? (
                            <span className="badge badge-success">
                              {item ? item.status : ""}
                            </span>
                          ) : (
                            <span className="badge badge-danger">
                              {item ? item.status : ""}
                            </span>
                          )
                        }
                      />
                      <DetailItem
                        label="Sweep Initiation Type"
                        labelSize={7}
                        valueSize={5}
                        value={item.sweepInitiationType}
                      />
                      <DetailItem
                        label="Guarantor Sweep Initiation Type"
                        labelSize={7}
                        valueSize={5}
                        value={textFormatService.splitAtUpper(
                          item.guarantorSweepInitiationType
                        )}
                      />
                      <DetailItem
                        label="Guarantor Moratorium Days"
                        labelSize={7}
                        valueSize={5}
                        value={item.guarantorMoratoriumDays}
                      />
                      <DetailItem
                        label="Integration Code"
                        labelSize={7}
                        valueSize={5}
                        value={item.integrationCode}
                      />
                      <DetailItem
                        label="Total Outflow"
                        labelSize={7}
                        valueSize={5}
                        value={`₦ ${textFormatService.convertKoboToNaira(
                          item.totalOutflow,
                          2
                        )}`}
                      />
                      <DetailItem
                        label="Threshold Percent"
                        labelSize={7}
                        valueSize={5}
                        value={`${item.threshold} %`}
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>
            <div
              className="card-header card-footer clear-fix"
              style={{ padding: "8px" }}
            >
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  Transactional GL
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={5} width={300} />
                  ) : (
                    <dl className="row">
                      {item.sweepable ? (
                        ""
                      ) : (
                        <React.Fragment>
                          <DetailItem
                            label="Bank's Fee Income Account"
                            labelSize={7}
                            valueSize={5}
                            value={item.feeIncomeAccount}
                          />
                          <DetailItem
                            label="Recova Fee Expense Account"
                            labelSize={7}
                            valueSize={5}
                            value={item.feeExpenseAccount}
                          />
                          {item.integrationCode?.includes("CommercialBank") ? (
                            ""
                          ) : (
                            <React.Fragment>
                              <DetailItem
                                label="Recova Settlement Mirror Account"
                                labelSize={7}
                                valueSize={5}
                                value={item.settlementMirrorAccount}
                              />
                              <DetailItem
                                label="Receivable Account"
                                labelSize={7}
                                valueSize={5}
                                value={item.receivableAccount}
                              />
                            </React.Fragment>
                          )}
                          <DetailItem
                            label="Recova Loan Collections Account"
                            labelSize={7}
                            valueSize={5}
                            value={item.loanCollectionsAccount}
                          />
                          <DetailItem
                            label="Recova Suspense Account"
                            labelSize={7}
                            valueSize={5}
                            value={item.suspenseAccount}
                          />
                        </React.Fragment>
                      )}
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={6} width={300} />
                  ) : (
                    <dl className="row">
                      {item.sweepable ? (
                        ""
                      ) : (
                        <React.Fragment>
                          {item.integrationCode?.includes("CommercialBank") ? (
                            ""
                          ) : (
                            <DetailItem
                              label="Settlement Institution Code"
                              labelSize={7}
                              valueSize={5}
                              value={item.settlementInstitutionCode}
                            />
                          )}
                          {item.integrationCode?.includes("CommercialBank") ? (
                            <React.Fragment>
                              <DetailItem
                                label="NIP Bank Code"
                                labelSize={7}
                                valueSize={5}
                                value={item.nipBankCode}
                              />
                              {/* <DetailItem
                            label="AppZone NIP Account Name"
                            labelSize={7}
                            valueSize={5}
                            value={item.appZoneNIPAccountName}
                          /> */}
                              <DetailItem
                                label="AppZone Income Account No"
                                labelSize={7}
                                valueSize={5}
                                value={item.appZoneNIPAccountNo}
                              />
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      )}
                    </dl>
                  )}
                </div>
              </div>
            </div>
            {item.integrationCode !== "CommercialBankIntegration" && (
              <SettlementAccounts
                institution={item}
                allInstitutions={allInstitutions}
                allCommercialBanks={allCommercialBanks}
              />
            )}
            <div
              className="card-header card-footer clear-fix"
              style={{ padding: "8px" }}
            >
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  Sweep Fee
                </h6>
              )}
            </div>
            <div>
              <Accordion defaultActiveKey="">
                <div>
                  <Card>
                    <ContextAwareToggle
                      eventKey={`0`}
                      header={
                        <Fragment>
                          <i
                            className="fa fa-info-circle"
                            aria-hidden="true"
                            style={{ color: "#878a8a" }}
                          ></i>{" "}
                          Recova
                        </Fragment>
                      }
                    ></ContextAwareToggle>
                    <Accordion.Collapse eventKey={`0`}>
                      <Fragment>
                        <InstitutionRecovaFeeConfigurationDetails
                          item={item}
                          recovaFeeConfigItem={recovaRecoveryFeeConfigItem}
                        />
                      </Fragment>
                    </Accordion.Collapse>
                  </Card>
                </div>
              </Accordion>
              {Object.keys(thirdPartyRecoveryFeeConfigItem).map(function (
                key,
                index
              ) {
                return (
                  <Accordion defaultActiveKey="">
                    <div key={key}>
                      <Card>
                        <ContextAwareToggle
                          eventKey={`0`}
                          header={
                            <Fragment>
                              <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                                style={{ color: "#878a8a" }}
                              ></i>{" "}
                              {thirdPartyRecoveryFeeConfigItem[key].thirdParty}
                            </Fragment>
                          }
                        ></ContextAwareToggle>
                        <Accordion.Collapse eventKey={`0`}>
                          <Fragment>
                            <InstitutionThirdPartyFeeConfigurationDetails
                              item={item}
                              thirdPartyFeeConfigItem={
                                thirdPartyRecoveryFeeConfigItem[key]
                              }
                            />
                          </Fragment>
                        </Accordion.Collapse>
                      </Card>
                    </div>
                  </Accordion>
                );
              })}
            </div>
            <hr />
            <div
              className="card-header card-footer clear-fix"
              style={{ padding: "8px" }}
            >
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  Collections Fee
                </h6>
              )}
            </div>
            <div>
              <Accordion defaultActiveKey="">
                <div>
                  <Card>
                    <ContextAwareToggle
                      eventKey={`0`}
                      header={
                        <Fragment>
                          <i
                            className="fa fa-info-circle"
                            aria-hidden="true"
                            style={{ color: "#878a8a" }}
                          ></i>{" "}
                          Recova
                        </Fragment>
                      }
                    ></ContextAwareToggle>
                    <Accordion.Collapse eventKey={`0`}>
                      <Fragment>
                        <InstitutionRecovaFeeConfigurationDetails
                          item={item}
                          recovaFeeConfigItem={recovaCollectionFeeConfigItem}
                        />
                      </Fragment>
                    </Accordion.Collapse>
                  </Card>
                </div>
              </Accordion>
              {Object.keys(thirdPartyCollectionFeeConfigItem).map(function (
                key,
                index
              ) {
                return (
                  <Accordion defaultActiveKey="">
                    <div key={key}>
                      <Card>
                        <ContextAwareToggle
                          eventKey={`0`}
                          header={
                            <Fragment>
                              <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                                style={{ color: "#878a8a" }}
                              ></i>{" "}
                              {
                                thirdPartyCollectionFeeConfigItem[key]
                                  .thirdParty
                              }
                            </Fragment>
                          }
                        ></ContextAwareToggle>
                        <Accordion.Collapse eventKey={`0`}>
                          <Fragment>
                            <InstitutionThirdPartyFeeConfigurationDetails
                              item={item}
                              thirdPartyFeeConfigItem={
                                thirdPartyCollectionFeeConfigItem[key]
                              }
                            />
                          </Fragment>
                        </Accordion.Collapse>
                      </Card>
                    </div>
                  </Accordion>
                );
              })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
              <Button
                size="sm"
                variant="outline-secondary"
                disabled={updatingStatus}
                onClick={(e) =>
                  this.onHandleEnableOrDisable(
                    e,
                    item.status === "Inactive"
                      ? ActionTypes.ENABLE
                      : ActionTypes.DISABLE,
                    "Sweep Configuration"
                  )
                }
              >
                <i className="far fa-edit mr-3" />
                {item.status === "Inactive" ? "Enable" : "Disable"}
              </Button>
              <Button
                size="sm"
                variant="primary"
                disabled={updatingStatus}
                onClick={toggleEditModal}
              >
                <i className="far fa-edit mr-3" />
                Edit
              </Button>
            </React.Fragment>
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default InstitutionSweepConfigurationDetail;
