import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../components/detailItem";
// import { ActionTypes } from "../../enums/actionTypes";
// import swal from "sweetalert";
// import activityTrailService from "../../services/auditTrail/activityTrailService";
// import mandateServiceTypeService from "../../services/mandate/mandateServiceTypeService";
// import { toast } from "react-toastify";
import textFormatService from "../../services/utility/textFormatService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../components/BaseFormComponent";
import sweepValidationService from "../../services/sweep/sweepValidationService";
import { decodeToken } from "react-jwt";
import {
  getCurrentUserSession,
  getCurrentUsersRoles,
} from "../../services/auth/authService";
import sweepRunService from "../../services/sweep/sweepRunService";
import { toast } from "react-toastify";
import { ActionTypes } from "../../enums/actionTypes";
import FormInputComponent from "../../components/formInputComponent";

interface SweepRunStatusFields {
  externalReference: string;
}
class SweepRunStatusForm extends BaseFormComponent<SweepRunStatusFields> { }
interface ISweepRunStatusModalProps {
  showModal: boolean;
  toggleModal: any;
  item: any;
}

interface ISweepRunStatusModalState { }

class SweepRunStatusModal extends Component<
  ISweepRunStatusModalProps,
  IBaseFormState & ISweepRunStatusModalState
> {
  constructor(props: ISweepRunStatusModalProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
    };
  }

  onFormSubmit(fields: SweepRunStatusFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors:
            sweepValidationService.validateOnDemandReactiveSweepForm(fields),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: SweepRunStatusFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true });
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const payload: any = {};

      const response = await sweepRunService.processOnDemandReactiveSweep(
        decodedToken?.["InstitutionCode"] || "",
        fields.externalReference
      );
      if (response.status >= 200 && response.status <= 300) {
        toast.info(`Sweep initiated successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false });
      this.resetFields();
      onReloadFieldsCB();
    } catch (error) {
      toast.info(`An error occured. Kindly contact an Administrator`, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      this.setState({ submitting: false });
      return false;
    } finally {
      return true;
    }
  }

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
    });
  }

  render() {
    const { errors, validated } = this.state;
    const { showModal, toggleModal, item } = this.props;
    return (
      <Modal size="lg" backdrop="static" show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={250} />
            ) : (
              <h5>
                <i className="fas fa-receipt fa-sm mr-3" />
                Sweep Run Status
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {_.isNull(item.lastSweepEpisode) ? (
            <div role="alert" className="alert alert-danger">
              <strong>Oh snap!</strong> No Sweep Configuration
            </div>
          ) : (
            ""
          )} */}
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col">
                  {_.isEmpty(item) ? (
                    <Skeleton count={6} width={200} />
                  ) : (
                    <React.Fragment>
                      <dl className="row">
                        <DetailItem
                          label="Sweep Running"
                          value={item.isSweepRunning.toString()}
                        />
                        <DetailItem
                          label="Sweep Has Error"
                          value={item.hasError.toString()}
                        />
                        {!_.isNull(item.lastSweepEpisode) ? (
                          <DetailItem
                            label="Date Initiated"
                            value={textFormatService.formatShortTimeString(
                              item.lastSweepEpisode.dateInitiated
                            )}
                          />
                        ) : (
                          ""
                        )}
                        {!_.isNull(item.lastSweepEpisode) ? (
                          <DetailItem
                            label="Time Started"
                            value={textFormatService.displayNullIfNull(
                              item.lastSweepEpisode.startTime
                            )}
                          />
                        ) : (
                          ""
                        )}
                        {!_.isNull(item.lastSweepEpisode) &&
                          item.isSweepRunning.toString() === "false" ? (
                          <DetailItem
                            label="Time Ended"
                            value={textFormatService.displayNullIfNull(
                              item.lastSweepEpisode.endTime
                            )}
                          />
                        ) : (
                          ""
                        )}
                        {!_.isNull(item.lastSweepEpisode) ? (
                          <DetailItem
                            label="Sweep Type"
                            value={textFormatService.displayNullIfNull(
                              item.lastSweepEpisode.sweepType
                            )}
                          />
                        ) : (
                          ""
                        )}
                        {!_.isNull(item.lastSweepEpisode) ? (
                          <DetailItem
                            label="Sweep Stage"
                            value={textFormatService.splitAtUpper(
                              item.lastSweepEpisode.sweepStage.substring(7)
                            )}
                          />
                        ) : (
                          ""
                        )}
                        {!_.isNull(item.lastSweepEpisode) ? (
                          <DetailItem
                            label="Recova Reference"
                            value={textFormatService.displayNullIfNull(
                              item.lastSweepEpisode.uniqueId
                            )}
                          />
                        ) : (
                          ""
                        )}
                      </dl>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
            {_.isEmpty(item) ||
              item.isSweepRunning ||
              getCurrentUsersRoles().includes("remotesession") ? (
              ""
            ) : (
              <SweepRunStatusForm
                initialValues={{
                  externalReference:
                    textFormatService.setCurrentDateAsReference(),
                }}
                FormComponent={({
                  fields: { externalReference },
                  onChange,
                  onReloadFields,
                  onHandleSubmit,
                }) => (
                  <form action="#">
                    <div className="card-header card-footer">
                      <h6
                        className="card-title"
                        style={{ marginBottom: "0px" }}
                      >
                        On Demand Sweep Initiation
                      </h6>
                    </div>
                    <div className="form-row">
                      <div className="col-md-6">
                        <FormInputComponent
                          id="externalReference"
                          type="text"
                          name="uniqueSweepReference"
                          placeholder=""
                          value={externalReference}
                          required={true}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-5 py-2">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary my-4"
                          disabled={this.state.submitting}
                          onClick={(e) => {
                            onHandleSubmit(
                              e,
                              ActionTypes.INITIATE,
                              "Sweep",
                              () => {
                                this.onFormSubmit(
                                  { externalReference },
                                  onReloadFields
                                );
                              }
                            );
                          }}
                        >
                          {this.state.submitting === false ? (
                            <React.Fragment>
                              <i className="fa fa-lg fa-repeat mr-3" /> Initiate
                              Sweep
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                              Please wait...
                            </React.Fragment>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              ></SweepRunStatusForm>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-danger" onClick={toggleModal}>
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SweepRunStatusModal;
