import Joi from "joi-browser";
import _ from "lodash";

export function validateAddCollectionsInstitutionForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      emailAddress: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Email Address is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditLoanProductForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      productName: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Loan Product Name is required" };
        }),
      productCode: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Product Code is required" };
        }),
      minimumAmount: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Minimum Amount is required" };
        }),
      maximumAmount: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Maximum Amount is required" };
        }),
      tenure: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Tenure is required" };
        }),
      interestRate: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Interest Rate is required" };
        }),
      // minimumScore: Joi.string()
      //   .required()
      //   .error((errors) => {
      //     return { message: "Minimum Score is required" };
      //   }),
      repaymentSchedule: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Repayment Schedule is required" };
        }),
      // moratorium: Joi.string()
      //   .required()
      //   .error((errors) => {
      //     return { message: "Moratorium is required" };
      //   }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditCollectionSettingForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      maxCollectionRetryPerDay: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Max. Collection Retry Per Day is required" };
        }),
      retryPeriodInterval: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Retry Period Interval is required" };
        }),
      maxCollectionPausesPerSchedule: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Max. Collection Pauses Per Schedule is required" };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateModifyCollectionConfigForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      switchRecoveryWindowInDays: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Switch Recovery Window In Days is required" };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditConfigurationForm(data) {
  return validateAddCollectionsInstitutionForm(data);
}

export default {
  validateAddCollectionsInstitutionForm,
  validateEditLoanProductForm,
  validateEditCollectionSettingForm,
  validateModifyCollectionConfigForm,
  validateEditConfigurationForm,
};
