import http from "../httpService";

const recovaCollectionBaseAddress = process.env.REACT_APP_COLLECTIONS_BASEADDRESS;
// const recovaCollectionBaseAddress = "https://localhost:44309/api/";

export function createCollectionsSettings(payload) {
  return http.post(
      recovaCollectionBaseAddress +
      `collections/settings/global`,
      payload
  );
}

export function retrieveAllCollectionsSettings() {
  return http.get(
    recovaCollectionBaseAddress +
      `collections/settings/global`
  );
}

export function retrieveCollectionsSettingsById(id) {
  return http.get(
    recovaCollectionBaseAddress +
      `collections/settings/global/${id}`
  );
}

export function editCollectionsSettings(payload, id) {
  return http.put(
      recovaCollectionBaseAddress +
      `collections/settings/global?id=${id}`,
      payload
  );
}

export function retrieveCollectionsSettingsByInstitutionCategory(institutionCategory) {
  return http.get(
    recovaCollectionBaseAddress +
      `collections/settings/global/institutionCategory/${institutionCategory}`
  );
}

// export function filterPagedCreditQualificationRules(
//   data: any,
//   pageSize: number,
//   pageNumber: number
// ) {
//   return http.post(
//     recovaCollectionBaseAddress +
//       `CreditQualificationRule/FilterCreditQualificationRules?pageSize=${pageSize}&pageNumber=${pageNumber}`,
//     data
//   );
// }

export default {
  createCollectionsSettings,
  retrieveAllCollectionsSettings,
  retrieveCollectionsSettingsById,
  editCollectionsSettings,
  retrieveCollectionsSettingsByInstitutionCategory
};
