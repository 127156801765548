import http from "../httpService";
// import _ from "lodash";
import { getCurrentUserSession } from "../auth/authService";
import { decodeToken } from "react-jwt";

interface ISaveSupportingDocument {
  institutionId: number;
  document: string;
  name: string;
  customerID: number;
  loanReference: string;
  supportingDocumentType: string;
  disputeConditionDocumentType: string;
  extensionType: string;
  disputeId: number;
}

const recovaMandateBaseAddress = process.env.REACT_APP_MANDATE_BASEADDRESS;

export function getSupportingDocumentsByLoanReference(
  loanReference: string,
  disputeId: number
) {
  // var disputeIdQuery = _.isEmpty(dispuqteId) ? "" : `&disputeId=${disputeId}`;
  return http.get(
    recovaMandateBaseAddress +
    `SupportingDocument/GetSupportingDocumentsByLoanReference?loanReference=${loanReference}&disputeId=${disputeId}`
  );
}

// export function uploadSupportingDocument(id, file) {
//   const formData = new FormData();
//   formData.append("body", file);
//   const config = {
//     headers: {
//       "content-type": "multipart/form-data",
//     },
//   };
//   return http.post(
//     recovaMandateBaseAddress +
//       `SupportingDocument/upload/${id}/${getCurrentUserSession().sessionId}`,
//     formData,
//     config
//   );
// }

export function saveSupportingDocument(data: ISaveSupportingDocument) {
  var url = recovaMandateBaseAddress + `SupportingDocument`;
  return http.post(url, data, {
    url: url,
    method: "post",
    data: data,
    // headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveById(id: number) {
  return http.get(recovaMandateBaseAddress + `SupportingDocument/${id}`);
}

export function deleteSupportingDocument(id: number) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  return http.get(
    recovaMandateBaseAddress +
    `SupportingDocument/DeleteSupportingDocument/${decodedToken?.["InstitutionId"]}/${id}`
  );
}

// export function getBase64String(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => {
//       let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
//       if (encoded.length % 4 > 0) {
//         encoded += "=".repeat(4 - (encoded.length % 4));
//       }
//       resolve(encoded);
//     };
//     reader.onerror = (error) => reject(error);
//   });
// }

export default {
  // uploadSupportingDocument,
  saveSupportingDocument,
  deleteSupportingDocument,
  retrieveById,
  // getBase64String,
};
