import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import textFormatService from "../../services/utility/textFormatService";
import rolesService from "../../services/auth/rolesService";
import { AxiosResponse } from "axios";
import { Approvable } from "./approvalComponents/approvable";

export interface IApprovalItemRuleProps {
  item: any;
  showRuleDetailsModal: boolean;
  toggleRuleDetailsModal: any;
}

export interface IApprovalItemRuleState {
  rules: any;
  maxLevel: number;
}

class ApprovalItemRule extends Component<
  IApprovalItemRuleProps,
  IApprovalItemRuleState
> {
  constructor(props: IApprovalItemRuleProps) {
    super(props);
    this.state = {
      rules: [],
      maxLevel: 1,
    };
  }

  static getDerivedStateFromProps(
    nextProps: IApprovalItemRuleProps,
    prevState
  ) {
    // if (!nextProps.item["id"]) {
    //   return null;
    // }

    // const maxLevel = Math.max(
    //   ...nextProps.item.approvable.rules.map((o) => o.level),
    //   0
    // );

    // return { maxLevel: maxLevel };
  }

  async loadRules() {
    let rules: any = [],
      maxLevel = 1;
    if (this.props.item["id"]) {
      maxLevel = Math.max(...this.props.item.approvable.rules.map((o) => o.level), 0);

      for (let i = 1; i <= maxLevel; i++) {
        const rulesSet = this.props.item.approvable.rules.filter((x) => x.level === i);
        rulesSet.sort(function (a, b) {
          if (a.combinationNo < b.combinationNo) return -1;
          if (a.combinationNo > b.combinationNo) return 1;
          return 0;
        });
        //console.log("ruleset: ", rulesSet);
        rules.push(rulesSet);
      }
    }
    
    //rules.reverse();

    const roleTasks: Promise<AxiosResponse<any>>[] = [];
    for (let i = 0; i < rules.length; i++) {
      const step = rules[i];
      for (let j = 0; j < step.length; j++) {
        // gather all the async tasks
        const rule = step[j];
        roleTasks.push(rolesService.retrieveRoleById(rule.roleID));
      }
    }

    //Run tasks
    const response = await Promise.all(roleTasks);

    // Insert responses to appropriate rules
    let counter = 0;
    for (let i = 0; i < rules.length; i++) {
      const step = rules[i];
      for (let j = 0; j < step.length; j++) {
        const rule = step[j];
        rule.role = response[counter+j].data;
      }
      counter+=step.length;
    }
    
    this.setState({ maxLevel: maxLevel, rules: rules });
  }

  render() {
    const { item, showRuleDetailsModal, toggleRuleDetailsModal } = this.props;
    const { rules } = this.state;
    if (!_.isEmpty(item) && rules.length === 0 && showRuleDetailsModal) {
      // console.log("condition met");
      this.loadRules();
    }
    // console.log("item :: ", item)
    return (
      <Modal
        size="lg"
        backdrop="static"
        show={showRuleDetailsModal}
        onHide={toggleRuleDetailsModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-tasks mr-3"></i>
              Approval Rule -{" "}
              <span className="font-weight-bold">
                {item.approvable ? item.approvable.functionName : ""}
              </span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Approvable
            rules={rules}
            item={rules.length > 0 ? item.approvable : {}}
            viewing={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleRuleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ApprovalItemRule;
