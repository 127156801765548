import React, { Component, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button, Accordion, Card } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import { getCurrentUsersRoles } from "../../../services/auth/authService";
import { ContextAwareToggle } from "../creditQualificationRuleManagement/creditQualificationRuleComponents/appzoneAddCQR";

interface IScoreCardDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  showTrail: any;
  currentUserRoles: any;
  item: any;
}

interface IScoreCardDetailState {
  item: any;
  updatingStatus: boolean;
}

class ScoreCardDetail extends Component<IScoreCardDetailProps, IScoreCardDetailState> {
  state = {
    item: {},
    updatingStatus: false,
  };

  static getDerivedStateFromProps(nextProps: IScoreCardDetailProps, prevState) {
    // console.log("current props: ", nextProps.item);
    return {
      item: nextProps.item,
    };
  }

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      item,
    } = this.props;
    const { updatingStatus } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="icon icon-building-o s-18 mr-3" />
                Score Card Details -{" "}
                <span className="font-weight-bold">{item.name}</span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Score Card Name"
                        labelSize={7}
                        valueSize={5}
                        value={item.name}
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={2} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Description"
                        labelSize={5}
                        valueSize={7}
                        value={item.description}
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>
            <Accordion defaultActiveKey="0">
              <div>
                <Card>
                  <ContextAwareToggle
                    eventKey={`0`}
                    isHeader={true}
                    header={
                      <React.Fragment>
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                          style={{ color: "#878a8a" }}
                        ></i>{" "}
                        Scoring Indicators
                      </React.Fragment>
                    }
                  ></ContextAwareToggle>
                  <Accordion.Collapse eventKey={`0`}>
                    <Card.Body>
                      <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                        <div className="row">
                          <div className="col-md-12">
                            {_.isEmpty(item.scoreGroups) ? (
                              <div style={{ textAlign: "center", color: "#FE825E" }}>
                                No Scoring Indicator has been assigned to score card
                              </div>
                            ) : (
                              <Fragment>
                                {item.scoreGroups?.map(
                                  (indicator) => (
                                    indicator.scoreIndicatorId?.length > 0 ? (
                                      <div key={indicator.scoreIndicatorId} style={{ marginTop: '3px' }}>
                                        <div
                                          className="custom-control custom-checkbox"
                                          key={indicator.scoreIndicatorId}
                                        >
                                          <input
                                            id={indicator.scoreIndicatorId}
                                            name={indicator.scoreIndicatorId}
                                            className="custom-control-input"
                                            type="checkbox"
                                            checked={true}
                                            disabled={true}
                                          />
                                          <label
                                            className="custom-control-label pt-1"
                                            htmlFor={indicator.scoreIndicatorId}
                                          >
                                            {indicator.scoreIndicatorName}
                                          </label>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )
                                  )
                                )}
                              </Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </div>
            </Accordion>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
              {/* {getCurrentUsersRoles().includes("editloanproduct") && ( */}
              <Button
                size="sm"
                variant="primary"
                disabled={updatingStatus}
                onClick={toggleEditModal}
              >
                <i className="far fa-edit mr-3" />
                Edit
              </Button>
              {/* )} */}
            </React.Fragment>
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ScoreCardDetail;