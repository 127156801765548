import React from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import institutionsService from "../../../services/auth/institutionsService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import authValidationService from "../../../services/auth/authValidationService";
import DetailItem from "../../../components/detailItem";
import { ActionTypes } from "../../../enums/actionTypes";
import { ISelectItems } from "../../../components/formSelectComponent";
import { MandateBulkUploadType } from "../../../enums/mandateBulkUploadType";
import textFormatService from "../../../services/utility/textFormatService";
import ActivateAppraisalInstitution from "../../hybridAppraisal/appraisalInstitution/activateAppraisalInstitution";
import appraisalPlanService from "../../../services/hybridAppraisal/appraisalPlanService";

interface EditInstitutionFields {
  name: string;
  code: string;
  disputeContactEmail: string;
  disputeContactPhone: string;
  externalInstitutionId: string;
  clearingBankId: string;
  mandateBulkUploadType: string;
  serviceOfferingConfigId: string;
  greyListCap: string;
  mandateLimit: string;
  emailDomain: string;
  enable2FA?: boolean;
  dmb?: boolean;
  enableMandatesByDefault?: boolean;
  enableAdVerification?: boolean;
}

class EditInstitutionForm extends BaseFormComponent<EditInstitutionFields> {}

interface IEditInstitutionProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}
interface IEditInstitutionState {
  clearingBanks: any;
  serviceOfferings: any;
  enableMandatesByDefault: boolean;
  enableAdVerification: boolean;
  enable2FA: boolean;
  dmb: boolean;
  ownUpdate: boolean;
  enableHybridAppraisal: boolean;
  showAppraisalInstitutionModal: boolean;
  appraisalPlans: any;
}

class EditInstitution extends React.Component<
  IEditInstitutionProps,
  IBaseFormState & IEditInstitutionState
> {
  _isMounted = false;
  constructor(props: IEditInstitutionProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      clearingBanks: [],
      serviceOfferings: [],
      enableMandatesByDefault: false,
      enableAdVerification: false,
      enable2FA: false,
      dmb: false,
      ownUpdate: false,
      enableHybridAppraisal: false,
      showAppraisalInstitutionModal: false,
      appraisalPlans: [],
    };
  }

  static getDerivedStateFromProps(nextProps: IEditInstitutionProps, prevState) {
    if (prevState.ownUpdate) {
      return {
        enableMandatesByDefault: prevState.enableMandatesByDefault,
        enableAdVerification: prevState.enableAdVerification,
        enable2FA: prevState.enable2FA,
        dmb: prevState.dmb,
        enableHybridAppraisal: prevState.enableHybridAppraisal,
        ownUpdate: false,
      };
    }
    return {
      enableMandatesByDefault: nextProps.item.enableMandatesByDefault,
      enableAdVerification: nextProps.item.enableAdVerification,
      enable2FA: nextProps.item.enable2FA,
      dmb: nextProps.item.dmb,
      enableHybridAppraisal: nextProps.item.enableHybridAppraisal,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { errors } = this.state;
    try {
      const clearingBanks = await institutionsService.retrieveAllInstitutions();
      let appraisalPlans;
      if (this.props.item !== null) {
        try {
          appraisalPlans =
            await appraisalPlanService.retrieveByInstitutionCategory(
              this.props.item?.category
            );
          if (appraisalPlans.data === undefined) {
            throw new Error("Appraisal Plans Not Found");
          }
        } catch (error) {
          appraisalPlans = [];
        }
      }
      if (this._isMounted) {
        this.setState({
          clearingBanks: clearingBanks.data.filter(
            (x) => x.category === "CommercialBank"
          ),
          appraisalPlans: appraisalPlans.data ? appraisalPlans.data : [],
        });
      }
    } catch (ex) {
      // console.log("caught exception", ex);
      if (ex.response && ex.response.status === 404) {
        // console.log(ex.response);
        errors.response = "Internal server error.";
        // console.log(errors);
        this.setState({ errors });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onFormSubmit(fields: EditInstitutionFields, onReloadFieldsCB: any): boolean {
    try {
      fields.enable2FA = this.state.enable2FA;
      fields.dmb = this.state.dmb;
      fields.enableMandatesByDefault = this.state.enableMandatesByDefault;
      fields.enableAdVerification = this.state.enableAdVerification;
      console.log("payload: ", fields);
      this.setState(
        { errors: authValidationService.validateEditInstitutionForm(fields) },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (fields: EditInstitutionFields, onReloadFieldsCB: any) => {
    try {
      this.setState({ submitting: true });
      const payload = this.props.item;
      payload.Id = payload.id;
      payload.name = fields.name;
      payload.greyListCap = fields.greyListCap;
      payload.disputeContactPhone = fields.disputeContactPhone;
      payload.disputeContactEmail = fields.disputeContactEmail;
      payload.emailDomain = fields.emailDomain;
      payload.externalInstitutionId = fields.externalInstitutionId;
      payload.mandateBulkUploadType = fields.mandateBulkUploadType;
      payload.clearingBankId = fields.clearingBankId;
      payload.serviceOfferingConfigId = fields.serviceOfferingConfigId;
      payload.mandateLimit = fields.mandateLimit;
      payload.enableMandatesByDefault = fields.enableMandatesByDefault;
      payload.enableAdVerification = fields.enableAdVerification;
      payload.enable2FA = fields.enable2FA;
      payload.dmb = fields.dmb;
      console.log("payload check: ", payload);
      const response = await institutionsService.updateInstitution(
        payload,
        payload.Id
      );
      console.log("response: ", response);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit Institution",
          description: `Edited details for Institution  ${fields.name}`,
        });
        toast.info(`Institution ${fields.name} Edited successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      }
      onReloadFieldsCB();
      this.setState({ submitting: false }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      // console.log("submission error: ", error);
      // this.setState({
      //   errors: "Invalid Username or Password",
      // });
      this.setState({ submitting: false });
    }
  };

  handleUpdateEnaleMandatesByDefault = () => {
    this.setState({
      enableMandatesByDefault: !this.state.enableMandatesByDefault,
      ownUpdate: true,
    });
  };

  handleUpdateAdVerification = () => {
    this.setState({
      enableAdVerification: !this.state.enableAdVerification,
      ownUpdate: true,
    });
  };

  handleOnEnable2FA = () => {
    this.setState({ enable2FA: !this.state.enable2FA, ownUpdate: true });
  };

  handleOnDMBChange = () => {
    this.setState({ dmb: !this.state.dmb, ownUpdate: true });
  };

  handleOnEnableHybridAppraisal = () => {
    if (this.state.enableHybridAppraisal) {
      toast.error(`Hybrid Appraisal cannot be deactivated`, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      this.setState({
        enableHybridAppraisal: this.state.enableHybridAppraisal,
        ownUpdate: true,
      });
      return;
    }
    this.setState({
      enableHybridAppraisal: !this.state.enableHybridAppraisal,
      showAppraisalInstitutionModal: !this.state.enableHybridAppraisal,
      ownUpdate: true,
    });
  };

  onTogglePatchModal = (reloadDetails: boolean, item: any) => {
    if (reloadDetails) {
      this.setState({
        enableHybridAppraisal: true,
        ownUpdate: true,
      });
    } else {
      this.setState({
        enableHybridAppraisal: false,
        ownUpdate: true,
      });
    }
    this.setState({
      showAppraisalInstitutionModal: !this.state.showAppraisalInstitutionModal,
      ownUpdate: true,
    });
  };

  validateField = (fieldName: string, type: string, validated: boolean) => {
    let defaultClass = "";
    if (type === "select") {
      defaultClass =
        "custom-select select2 mr-sm-2 form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    } else {
      defaultClass = "form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    }
    return !validated
      ? defaultClass
      : this.state.errors[fieldName]
      ? `${defaultClass} is-invalid`
      : `${defaultClass} is-valid`;
  };

  render() {
    const { item, showEditModal, toggleEditModal } = this.props;
    const {
      validated,
      submitting,
      errors,
      enableMandatesByDefault,
      enableAdVerification,
      enable2FA,
      dmb,
      clearingBanks,
      serviceOfferings,
      enableHybridAppraisal,
      appraisalPlans,
      showAppraisalInstitutionModal,
    } = this.state;

    const mandateLimits = [
      { name: "10", value: "Ten" },
      { name: "100", value: "OneHundred" },
      { name: "200", value: "TwoHundred" },
      // { name: "500", value: "FiveHundred" },
      { name: "750", value: "SevenHundredAndFifty" },
      { name: "Unlimited", value: "Unlimited" },
    ];

    let mandateBulkUploadTypeInputData: ISelectItems[] = Object.keys(
      MandateBulkUploadType
    )
      .filter((value) => isNaN(Number(value)) === false)
      .map((key) => ({
        name: textFormatService.splitAtUpper(MandateBulkUploadType[key]),
        value: MandateBulkUploadType[key],
      }));

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-university fa-sm cyan-text mr-3" />
              Edit Institution -{" "}
              <span className="font-weight-bold">{item.name}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditInstitutionForm
          initialValues={{
            name: item.name,
            clearingBankId: item.clearingBankId,
            serviceOfferingConfigId: item.serviceOfferingConfigId,
            code: item.code,
            mandateBulkUploadType: item.mandateBulkUploadType,
            disputeContactEmail: item.disputeContactEmail,
            disputeContactPhone: item.disputeContactPhone,
            externalInstitutionId: item.externalInstitutionId,
            greyListCap: item.greyListCap,
            mandateLimit: item.mandateLimit,
            emailDomain: item.emailDomain,
          }}
          FormComponent={({
            fields: {
              name,
              code,
              mandateBulkUploadType,
              clearingBankId,
              serviceOfferingConfigId,
              disputeContactEmail,
              disputeContactPhone,
              emailDomain,
              externalInstitutionId,
              greyListCap,
              mandateLimit,
            },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    <h6
                      className="card-title float-left"
                      style={{ marginBottom: "0px" }}
                    >
                      General Information
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <DetailItem
                            label="Name"
                            value={
                              <React.Fragment>
                                <input
                                  autoFocus
                                  type="text"
                                  className={this.validateField(
                                    "name",
                                    "text",
                                    validated
                                  )}
                                  id="name"
                                  name="name"
                                  value={name}
                                  onChange={(e) =>
                                    onChange("name", e.currentTarget.value)
                                  }
                                />
                                <div
                                  className="invalid-feedback"
                                  style={{ visibility: errors.name }}
                                >
                                  {errors.name}
                                </div>
                              </React.Fragment>
                            }
                          />
                        </div>
                        <div className="form-group row">
                          <DetailItem
                            label="Dispute Email"
                            value={
                              <React.Fragment>
                                <input
                                  autoFocus
                                  type="text"
                                  className={this.validateField(
                                    "disputeContactEmail",
                                    "text",
                                    validated
                                  )}
                                  id="disputeContactEmail"
                                  name="disputeContactEmail"
                                  value={disputeContactEmail}
                                  onChange={(e) =>
                                    onChange(
                                      "disputeContactEmail",
                                      e.currentTarget.value
                                    )
                                  }
                                />
                                <div
                                  className="invalid-feedback"
                                  style={{
                                    visibility: errors.disputeContactEmail,
                                  }}
                                >
                                  {errors.disputeContactEmail}
                                </div>
                              </React.Fragment>
                            }
                          />
                        </div>
                        <div className="form-group row">
                          <DetailItem label="Category" value={item.category} />
                        </div>
                        <div className="form-group row">
                          <DetailItem
                            label="Status"
                            value={
                              (item ? item.status : "Active") === "Active" ? (
                                <span className="badge badge-success">
                                  {item ? item.status : ""}
                                </span>
                              ) : (
                                <span className="badge badge-danger">
                                  {item ? item.status : ""}
                                </span>
                              )
                            }
                          />
                        </div>
                        <div className="form-group row">
                          <DetailItem
                            label="Mandate Bulk Upload Type"
                            value={
                              <React.Fragment>
                                <select
                                  className={this.validateField(
                                    "mandateBulkUploadType",
                                    "select",
                                    validated
                                  )}
                                  onChange={(e) =>
                                    onChange(
                                      "mandateBulkUploadType",
                                      e.currentTarget.value
                                    )
                                  }
                                  value={mandateBulkUploadType}
                                  name="mandateBulkUploadType"
                                >
                                  <option value="">
                                    -Select Mandate Bulk Upload Type-
                                  </option>
                                  {!_.isEmpty(mandateBulkUploadTypeInputData)
                                    ? mandateBulkUploadTypeInputData.map(
                                        (item) => (
                                          <option
                                            key={item.value}
                                            value={item.value}
                                          >
                                            {item.name}
                                          </option>
                                        )
                                      )
                                    : ""}
                                </select>
                                <div
                                  className="invalid-feedback"
                                  style={{
                                    visibility: errors.mandateBulkUploadType,
                                  }}
                                >
                                  {errors.mandateBulkUploadType}
                                </div>
                              </React.Fragment>
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <DetailItem label="Code" value={item.code} />
                        </div>
                        <div className="form-group row">
                          <DetailItem
                            label="Dispute Phone No."
                            value={
                              <React.Fragment>
                                <input
                                  autoFocus
                                  type="text"
                                  className={this.validateField(
                                    "disputeContactPhone",
                                    "text",
                                    validated
                                  )}
                                  id="disputeContactPhone"
                                  name="disputeContactPhone"
                                  value={disputeContactPhone}
                                  onChange={(e) =>
                                    onChange(
                                      "disputeContactPhone",
                                      e.currentTarget.value
                                    )
                                  }
                                />
                                <div
                                  className="invalid-feedback"
                                  style={{
                                    visibility: errors.disputeContactPhone,
                                  }}
                                >
                                  {errors.disputeContactPhone}
                                </div>
                              </React.Fragment>
                            }
                          />
                        </div>
                        <div className="form-group row">
                          <DetailItem
                            label="Mandate Limit"
                            value={
                              <React.Fragment>
                                <select
                                  className={this.validateField(
                                    "mandateLimit",
                                    "select",
                                    validated
                                  )}
                                  onChange={(e) =>
                                    onChange(
                                      "mandateLimit",
                                      e.currentTarget.value
                                    )
                                  }
                                  value={mandateLimit}
                                  name="mandateLimit"
                                >
                                  <option value="">
                                    -Select Mandate Limit-
                                  </option>
                                  {!_.isEmpty(mandateLimits)
                                    ? mandateLimits.map((item) => (
                                        <option
                                          key={item.value}
                                          value={item.value}
                                        >
                                          {item.name}
                                        </option>
                                      ))
                                    : ""}
                                </select>
                                <div
                                  className="invalid-feedback"
                                  style={{
                                    visibility: errors.mandateLimit,
                                  }}
                                >
                                  {errors.mandateLimit}
                                </div>
                              </React.Fragment>
                            }
                          />
                        </div>

                        <div className="form-group row">
                          <DetailItem
                            label="Service Offering Config"
                            value={
                              <React.Fragment>
                                <select
                                  className={this.validateField(
                                    "serviceOfferingConfigId",
                                    "select",
                                    validated
                                  )}
                                  onChange={(e) =>
                                    onChange(
                                      "serviceOfferingConfigId",
                                      e.currentTarget.value
                                    )
                                  }
                                  value={serviceOfferingConfigId}
                                  name="serviceOfferingConfigId"
                                >
                                  <option value="">
                                    -Select Service Offering Config-
                                  </option>
                                  {!_.isEmpty(serviceOfferings)
                                    ? serviceOfferings.map((item) => (
                                        <option key={item.id} value={item.id}>
                                          {item.name}
                                        </option>
                                      ))
                                    : ""}
                                </select>
                                <div
                                  className="invalid-feedback"
                                  style={{
                                    visibility: errors.serviceOfferingConfigId,
                                  }}
                                >
                                  {errors.serviceOfferingConfigId}
                                </div>
                              </React.Fragment>
                            }
                          />
                        </div>

                        {_.isEmpty(item.clearingBank) ? (
                          ""
                        ) : (
                          <div className="form-group row">
                            <DetailItem
                              label="Clearing Bank"
                              value={
                                <React.Fragment>
                                  <select
                                    className={this.validateField(
                                      "clearingBankId",
                                      "select",
                                      validated
                                    )}
                                    onChange={(e) =>
                                      onChange(
                                        "clearingBankId",
                                        e.currentTarget.value
                                      )
                                    }
                                    value={clearingBankId}
                                    name="clearingBankId"
                                  >
                                    <option value="">
                                      -Select Clearing Bank-
                                    </option>
                                    {!_.isEmpty(clearingBanks)
                                      ? clearingBanks.map((item) => (
                                          <option key={item.id} value={item.id}>
                                            {item.name}
                                          </option>
                                        ))
                                      : ""}
                                  </select>
                                  <div
                                    className="invalid-feedback"
                                    style={{
                                      visibility: errors.clearingBankId,
                                    }}
                                  >
                                    {errors.clearingBankId}
                                  </div>
                                </React.Fragment>
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="card">
                  <div className="card-header clear-fix">
                    <h6
                      className="card-title float-left"
                      style={{ marginBottom: "0px" }}
                    >
                      Institution Settings
                    </h6>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <DetailItem
                            label="Enable Mandates By Default"
                            labelSize={7}
                            valueSize={5}
                            value={
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  onChange={
                                    this.handleUpdateEnaleMandatesByDefault
                                  }
                                  id="enableMandatesByDefault"
                                  name="enableMandatesByDefault"
                                  defaultChecked={enableMandatesByDefault}
                                  //   value={enableMandatesByDefault.toString()}
                                />
                                <span className="slider round"></span>
                              </label>
                            }
                          />
                        </div>
                        <div className="form-group row">
                          <DetailItem
                            label="Enable AD Verification"
                            labelSize={7}
                            valueSize={5}
                            value={
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  onChange={this.handleUpdateAdVerification}
                                  id="enableAdVerification"
                                  name="enableAdVerification"
                                  defaultChecked={enableAdVerification}
                                  //   value={enableMandatesByDefault.toString()}
                                />
                                <span className="slider round"></span>
                              </label>
                            }
                          />
                        </div>
                        <div className="form-group row">
                          <DetailItem
                            label="Enable 2FA"
                            labelSize={7}
                            valueSize={5}
                            value={
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  onChange={this.handleOnEnable2FA}
                                  id="enable2FA"
                                  name="enable2FA"
                                  defaultChecked={enable2FA}
                                  value={enable2FA.toString()}
                                />
                                <span className="slider round"></span>
                              </label>
                            }
                          />
                        </div>
                        <div className="form-group row">
                          <DetailItem
                            label="DMB"
                            labelSize={7}
                            valueSize={5}
                            value={
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  onChange={this.handleOnDMBChange}
                                  id="dmb"
                                  name="dmb"
                                  defaultChecked={dmb}
                                  value={dmb.toString()}
                                />
                                <span className="slider round"></span>
                              </label>
                            }
                          />
                        </div>
                        <div className="form-group row">
                          <DetailItem
                            label="Enable Hybrid Appraisal"
                            labelSize={7}
                            valueSize={5}
                            value={
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  onChange={this.handleOnEnableHybridAppraisal}
                                  id="enableHybridAppraisal"
                                  name="enableHybridAppraisal"
                                  defaultChecked={enableHybridAppraisal}
                                  value={
                                    enableHybridAppraisal ? "True" : "False"
                                  }
                                />
                                <span className="slider round"></span>
                              </label>
                            }
                          />
                          {!_.isEmpty(appraisalPlans) ? (
                            <ActivateAppraisalInstitution
                              showPatchModal={showAppraisalInstitutionModal}
                              togglePatchModal={this.onTogglePatchModal}
                              item={item}
                              appraisalPlans={appraisalPlans}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <DetailItem
                            label="Email Domain"
                            value={
                              <React.Fragment>
                                <input
                                  autoFocus
                                  type="text"
                                  className={this.validateField(
                                    "emailDomain",
                                    "text",
                                    validated
                                  )}
                                  id="emailDomain"
                                  name="emailDomain"
                                  value={emailDomain ?? ""}
                                  onChange={(e) =>
                                    onChange(
                                      "emailDomain",
                                      e.currentTarget.value
                                    )
                                  }
                                />
                                <div
                                  className="invalid-feedback"
                                  style={{
                                    visibility: "visible",
                                    display: "block",
                                  }}
                                >
                                  For multiple domains, seprate with ';'
                                </div>
                              </React.Fragment>
                            }
                          />
                          <DetailItem
                            label="Max. Greylisted BVN"
                            value={
                              <React.Fragment>
                                <input
                                  autoFocus
                                  type="text"
                                  className={this.validateField(
                                    "greyListCap",
                                    "text",
                                    validated
                                  )}
                                  id="greyListCap"
                                  name="greyListCap"
                                  value={greyListCap}
                                  onChange={(e) =>
                                    onChange(
                                      "greyListCap",
                                      e.currentTarget.value
                                    )
                                  }
                                />
                                <div
                                  className="invalid-feedback"
                                  style={{
                                    visibility: errors.greyListCap,
                                  }}
                                >
                                  {errors.greyListCap}
                                </div>
                              </React.Fragment>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(e, ActionTypes.UPDATE, "Institution", () => {
                      this.onFormSubmit(
                        {
                          name,
                          mandateLimit,
                          greyListCap,
                          externalInstitutionId,
                          disputeContactPhone,
                          disputeContactEmail,
                          emailDomain,
                          clearingBankId,
                          code,
                          serviceOfferingConfigId,
                          mandateBulkUploadType,
                        },
                        onReloadFields
                      );
                    });
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></EditInstitutionForm>
      </Modal>
    );
  }
}

export default EditInstitution;
