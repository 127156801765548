import Joi from "joi-browser";
import _ from "lodash";

export function validateLoginForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      institutionCode: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Institution Code is required." };
        }),
      username: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Invalid Username.",
          };
        }),
      password: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Invalid Password.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  // //console.log("Errors: ", errors);
  return errors;
}

export function validateForgotPasswordForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      institutionCode: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Institution Code is Required.",
          };
        }),
      email: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Email Address is Required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateUserSetupForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      password: Joi.string()
        .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.#_()])[A-Za-z\d@$!%*?&.#_()]{8,}$/)
        .required()
        .error((errors) => {
          //console.log("password joi-error", errors);
          if (errors[0].type === "string.regex.base") {
            return {
              message: "Password must have minimum of 8 characters, an uppercase letter, a lowercase letter, a number and a special character.",
            };
          } else {
            return {
              message: "Invalid Password.",
            };
          }
        }),
      confirmPassword: Joi.string()
        .min(8)
        .required()
        .valid(Joi.ref("password"))
        .error((errors) => {
          //console.log("confirm password joi-error", errors);
          if (errors[0].type === "any.allowOnly") {
            return { message: "Password mismatch. Please try again." };
          } else {
            return {
              message: "Invalid Password.",
            };
          }
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditBranchForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      name: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("name joi-error", errors);
          return {
            message: "Name is required.",
          };
        }),
      description: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("description joi-error", errors);
          return {
            message: "Description is required.",
          };
        }),
      address: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("description joi-error", errors);
          return {
            message: "Address is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddBranchForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      name: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("name joi-error ", errors);
          return {
            message: "Name is required.",
          };
        }),
      code: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("code joi-error ", errors);
          return {
            message: "Code is required.",
          };
        }),
      description: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("description joi-error", errors);
          return {
            message: "Description is required.",
          };
        }),
      address: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("description joi-error", errors);
          return {
            message: "Address is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditRegionForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      name: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("name joi-error", errors);
          return {
            message: "Name is required.",
          };
        }),
      description: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("description joi-error", errors);
          return {
            message: "Description is required.",
          };
        }),
      address: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("description joi-error", errors);
          return {
            message: "Address is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddRegionForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      name: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("name joi-error ", errors);
          return {
            message: "Name is required.",
          };
        }),
      code: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("code joi-error ", errors);
          return {
            message: "Code is required.",
          };
        }),
      description: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("description joi-error", errors);
          return {
            message: "Description is required.",
          };
        }),
      address: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("description joi-error", errors);
          return {
            message: "Address is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddLoanProductForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      institution: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("name joi-error ", errors);
          return {
            message: "Institution is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddUserForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      firstName: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("firstname joi-error", errors);
          return {
            message: "First Name is required.",
          };
        }),
      lastName: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("lastname joi-error", errors);
          return {
            message: "Last Name is required.",
          };
        }),
      userName: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("username joi-error", errors);
          return {
            message: "User Name is required",
          };
        }),
      otherNames: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("othernames joi-error", errors);
          return {
            message: "Other Names is required",
          };
        }),
      phoneNumber: Joi.string()
        .required()
        .max(15)
        .error((errors) => {
          // //console.log("phonenumber joi-error", errors);
          return {
            message:
              errors[0].type === "string.max"
                ? "Phone number can not be more than 15 characters"
                : "Phone Number is required",
          };
        }),
      email: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("email joi-error", errors);
          return {
            message: "Email is required",
          };
        }),
      gender: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("email joi-error", errors);
          return {
            message: "Gender is required",
          };
        }),
      address: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("address joi-error", errors);
          return {
            message: "Address is required",
          };
        }),
      userRoleId: Joi.number()
        .required()
        .error((errors) => {
          // //console.log("userRoleId joi-error", errors);
          return {
            message: "User Role Id is required",
          };
        }),
      staffId: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("staffId joi-error", errors);
          return {
            message: "Staff Id is required",
          };
        }),
      branchId: Joi.number()
        .required()
        .error((errors) => {
          // //console.log("branchId joi-error", errors);
          return {
            message: "Branch Id is required",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddInstitutionForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      institutionName: Joi.string()
        .required()
        .max(100)
        .error((errors) => {
          // //console.log("institutionName joi-error", errors)
          return {
            message:
              errors[0].type === "string.max"
                ? "Institution Name more than 100 characters"
                : "Institution Name is required",
          };
        }),
      shortName: Joi.string()
        .required()
        .max(100)
        .error((errors) => {
          // //console.log("shortName joi-error", errors)
          return {
            message:
              errors[0].type === "string.max"
                ? "Short Name more than 100 characters"
                : "Short Name is required",
          };
        }),
      greyListCap: Joi.number()
        .required()
        .error((errors) => {
          // //console.log("GreyListCap joi-error", errors)
          return {
            message: "Max Grey Listed BVN is required",
          };
        }),
      category: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("category joi-error", errors);
          return {
            message: "Category is required",
          };
        }),
      // emailDomain: Joi.string()
      //   .required().error((errors) => {
      //     return {
      //       message: "Email Domain is required"
      //     };
      //   }),
      disputeMailGroup: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("disputeMailGroup joi-error", errors)
          return {
            message: "Dispute Mail-Group is required",
          };
        }),
      disputePhoneNumber: Joi.string()
        .required()
        .max(15)
        .error((errors) => {
          // //console.log("disputePhoneNumber joi-error ", errors[0].type);
          let msg = "";
          switch (errors[0].type) {
            case "string.max":
              msg = "Phone Number can not be more than 15 characters.";
              break;
            case "any.empty":
              msg = "Dispute Phone Number is required.";
              break;
            default:
              msg = "Invalid Dispute Phone Number.";
              break;
          }
          return { message: msg };
        }),
      mandateLimit: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("mandateLimit joi-error", errors)
          return {
            message: "Mandate Limit is required",
          };
        }),
      adminUsername: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("adminUsername joi-error", errors)
          return {
            message: "Admin Username is required",
          };
        }),
      adminFirstName: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("adminFirstname joi-error", errors)
          return {
            message: "Admin Firstname is required",
          };
        }),
      adminLastName: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("adminLastname joi-error", errors)
          return {
            message: "Admin Lastname is required",
          };
        }),
      adminEmail: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("adminEmail joi-error", errors)
          return {
            message: "Admin Email is required",
          };
        }),
      adminGender: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("adminGender joi-error", errors)
          return {
            message: "Admin Gender is required",
          };
        }),
      adminPhonenumber: Joi.string()
        .required()
        .max(15)
        .error((errors) => {
          // //console.log("adminPhonenumber joi-error", errors)
          return {
            message:
              errors[0].type === "string.max"
                ? "Phone number can not be more than 15 characters"
                : "Admin Phonenumber is required",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditInstitutionForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      name: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("name joi-error", errors)
          return {
            message: "Institution Name is required",
          };
        }),
      greyListCap: Joi.number()
        .required()
        .error((errors) => {
          // //console.log("greyListCap joi-error", errors)
          return {
            message: "Grey List Cap is required",
          };
        }),
      // emailDomain: Joi.string()
      //   .required()
      //   .error((errors) => {
      //     // //console.log("email Domain joi-error", errors)
      //     return {
      //       message: "Email Domain is required",
      //     };
      //   }),
      disputeContactEmail: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("disputeContactEmail joi-error", errors)
          return {
            message: "DisputeContactEmail is required",
          };
        }),
      disputeContactPhone: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("disputeContactPhone joi-error", errors)
          return {
            message: "Dispute Contact Phone is required",
          };
        }),
      mandateLimit: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("mandateLimit joi-error", errors)
          return {
            message: "Mandate Limit is required",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditUserForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      firstName: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("firstname joi-error", errors);
          return {
            message: "First Name is required.",
          };
        }),
      lastName: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("lastname joi-error", errors);
          return {
            message: "Last Name is required.",
          };
        }),
      userName: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("username joi-error", errors);
          return {
            message: "User Name is required",
          };
        }),
      otherNames: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("othernames joi-error", errors);
          return {
            message: "Other Names is required",
          };
        }),
      phoneNumber: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("phonenumber joi-error", errors);
          return {
            message: "Phone Number is required",
          };
        }),
      email: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("email joi-error", errors);
          return {
            message: "Email is required",
          };
        }),
      userRoleId: Joi.number()
        .required()
        .error((errors) => {
          // //console.log("userRoleId joi-error", errors);
          return {
            message: "User Role Id is required",
          };
        }),
      branchId: Joi.number()
        .required()
        .error((errors) => {
          // //console.log("branchId joi-error", errors);
          return {
            message: "Branch Id is required",
          };
        }),
      address: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("address joi-error", errors);
          return {
            message: "Address is required",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddServiceOfferingForm(data) {
  const result = Joi.validate(
    data, Joi.object().keys({
      name: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Name is required.",
          };
        }),
      billableOffer: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Billable Offer is required.",
          };
        }),
      institutionCategory: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Institution Category is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    });
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddUserRoleForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      name: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("name joi-error", errors);
          return {
            message: "Name is required.",
          };
        }),
      code: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("code joi-error", errors);
          return {
            message: "Code is required.",
          };
        }),
      description: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("description joi-error", errors);
          return {
            message: "Description is required.",
          };
        }),
      roleVisibility: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("roleVisibility joi-error", errors);
          return {
            message: "Role Visibility is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddMailingListForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      name: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("name joi-error", errors);
          return {
            message: "Name is required.",
          };
        }),
      frequency: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("code joi-error", errors);
          return {
            message: "Frequency is required.",
          };
        }),
      reportFormat: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("description joi-error", errors);
          return {
            message: "Report Format is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateMailingListRecipientForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      name: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("name joi-error", errors);
          return {
            message: "Recipient Name is required.",
          };
        }),
      email: Joi.string().email({ tlds: { allow: false } })
        .required()
        .error((errors) => {
          // //console.log("code joi-error", errors);
          return {
            message: "Valid Recipient Email is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddUserRoleFunctionForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      displayName: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("name joi-error", errors);
          return {
            message: "Display Name is required.",
          };
        }),
      description: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("description joi-error", errors);
          return {
            message: "Description is required.",
          };
        }),
      functionAccess: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("description joi-error", errors);
          return {
            message: "Function Access is required.",
          };
        }),
      parentId: Joi.string()
        .required()
        .error((errors) => {
          // //console.log("description joi-error", errors);
          return {
            message: "Parent Function is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditSweepConfigurationForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      feeIncomeAccount: Joi.alternatives().when("category", {
        is: "CommercialBank",
        then: Joi.any(),
        otherwise: Joi.when("sweepable", {
          is: true,
          then: Joi.any(),
          otherwise: Joi.string()
            .required()
            .error((errors) => {
              return { message: "Fee Income Account is required" };
            })
        })
      }),
      feeExpenseAccount: Joi.alternatives().when("category", {
        is: "CommercialBank",
        then: Joi.any(),
        otherwise: Joi.when("sweepable", {
          is: true,
          then: Joi.any(),
          otherwise: Joi.string()
            .required()
            .error((errors) => {
              return { message: "Fee Expense Account is required" };
            })
        })
      }),
      settlementMirrorAccount: Joi.alternatives().when("category", {
        is: "CommercialBank",
        then: Joi.any(),
        otherwise: Joi.when("sweepable", {
          is: true,
          then: Joi.any(),
          otherwise: Joi.string()
            .required()
            .error((errors) => {
              return { message: "Settlement Mirror Account is required" };
            }),
        })
      }),
      loanCollectionsAccount: Joi.alternatives().when("category", {
        is: "CommercialBank",
        then: Joi.any(),
        otherwise: Joi.when("category", {
          is: "BankOneOFI",
          then: Joi.string()
          .required()
          .error((errors) => {
            return { message: "Loan Collections Account is required" };
          }),
          otherwise: Joi.any(),
        })
      }),
      suspenseAccount: Joi.alternatives().when("category", {
        is: "CommercialBank",
        then: Joi.any(),
        otherwise: Joi.when("sweepable", {
          is: true,
          then: Joi.any(),
          otherwise: Joi.string()
            .required()
            .error((errors) => {
              return { message: "Suspense Account is required" };
            }),
        })
      }),
      receivableAccount: Joi.alternatives().when("category", {
        is: "BankOneOFI",
        then: Joi.when("sweepable", {
          is: true,
          then: Joi.any(),
          otherwise: Joi.string()
          .required()
          .error((errors) => {
            return { message: "Receivable Account is required" };
          })
        }),
        otherwise: Joi.any(),
      }),
      nipBankCode: Joi.alternatives().when("category", {
        is: "CommercialBank",
        then: Joi.string()
          .required()
          .error((errors) => {
            return { message: "NIP Bank Code is required" };
          }),
        otherwise: Joi.any(),
      }),
      // appZoneNIPAccountName: Joi.alternatives().when("category", {
      //   is: "CommercialBank",
      //   then: Joi.string()
      //     .required()
      //     .error((errors) => {
      //       return {
      //         message: "AppZone NIP Account Name is required",
      //       };
      //     }),
      //   otherwise: Joi.any(),
      // }),
      appZoneNIPAccountNo: Joi.alternatives().when("category", {
        is: "CommercialBank",
        then: Joi.string()
          .required()
          .error((errors) => {
            return {
              message: "AppZone Income Account Number is required",
            };
          }),
        otherwise: Joi.any(),
      }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddSweepConfigurationForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      feeIncomeAccount: Joi.alternatives().when("category", {
        is: "CommercialBank",
        then: Joi.any(),
        otherwise: Joi.when("sweepable", {
          is: true,
          then: Joi.any(),
          otherwise: Joi.string()
            .required()
            .error((errors) => {
              return { message: "Fee Income Account is required" };
            })
        })
      }),
      feeExpenseAccount: Joi.alternatives().when("category", {
        is: "CommercialBank",
        then: Joi.any(),
        otherwise: Joi.when("sweepable", {
          is: true,
          then: Joi.any(),
          otherwise: Joi.string()
            .required()
            .error((errors) => {
              return { message: "Fee Expense Account is required" };
            })
        })
      }),
      settlementMirrorAccount: Joi.alternatives().when("category", {
        is: "CommercialBank",
        then: Joi.any(),
        otherwise: Joi.when("sweepable", {
          is: true,
          then: Joi.any(),
          otherwise: Joi.string()
            .required()
            .error((errors) => {
              return { message: "Settlement Mirror Account is required" };
            }),
        })
      }),
      receivableAccount: Joi.alternatives().when("category", {
        is: "BankOneOFI",
        then: Joi.when("sweepable", {
          is: true,
          then: Joi.any(),
          otherwise: Joi.string()
          .required()
          .error((errors) => {
            return { message: "Receivable Account is required" };
          })
        }),
        otherwise: Joi.any(),
      }),
      loanCollectionsAccount: Joi.alternatives().when("category", {
        is: "CommercialBank",
        then: Joi.any(),
        otherwise: Joi.when("category", {
          is: "BankOneOFI",
          then: Joi.string()
          .required()
          .error((errors) => {
            return { message: "Loan Collections Account is required" };
          }),
          otherwise: Joi.any(),
        })
      }),
      suspenseAccount: Joi.alternatives().when("category", {
        is: "CommercialBank",
        then: Joi.any(),
        otherwise: Joi.when("sweepable", {
          is: true,
          then: Joi.any(),
          otherwise: Joi.string()
            .required()
            .error((errors) => {
              return { message: "Suspense Account is required" };
            }),
        })
      }),
      nipBankCode: Joi.alternatives().when("category", {
        is: "CommercialBank",
        then: Joi.string()
          .required()
          .error((errors) => {
            return { message: "NIP Bank Code is requireds" };
          }),
        otherwise: Joi.any(),
      }),
      appZoneIncomeAccountNo: Joi.alternatives().when("category", {
        is: "CommercialBank",
        then: Joi.string()
          .required()
          .error((errors) => {
            return {
              message: "AppZone Income Account Number is required",
            };
          }),
        otherwise: Joi.any(),
      }),
      sweepInitiationType: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Sweep Initiation Type is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateGrantConsentForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      phoneNumber: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Phone Number is required.",
          };
        }),
      token: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Token is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditUserRoleForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      name: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Name is required.",
          };
        }),
      description: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Description is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditMailingListForm(data) {
  return validateAddMailingListForm(data);
}

export function validateAddRecovaFeeConfigurationForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      activeLoansPercentage: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Active Loan Percentage is required";
                break;
              case "number.min":
                err.message = `Active Loan Percentage should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      lostLoansPercentage: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Lost Loan Percentage is required";
                break;
              case "number.min":
                err.message = `Lost Loan Percentage should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      flatAmount: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Flat Amount is required";
                break;
              case "number.min":
                err.message = `Flat Amount should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      minimumFee: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Minimum Fee is required";
                break;
              case "number.min":
                err.message = `Minimum Fee should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      maximumFee: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Maximum Fee is required";
                break;
              case "number.min":
                err.message = `Maximum Fee should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      debitedBankCommission: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Debited Bank Commssion is required";
                break;
              case "number.min":
                err.message = `Debited Bank Commssion should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      sponsorBankCommission: Joi.alternatives().when("category", {
        is: "CommercialBank",
        then: Joi.number()
          .required()
          .min(0)
          .error((errors) => {
            errors.forEach(err => {
              switch (err.type) {
                case "number.base":
                  err.message = "Sponsor Bank Commssion is required";
                  break;
                case "number.min":
                  err.message = `Sponsor Bank Commssion should not be negative`;
                  break;
                default:
                  break;
              }
            });
            return errors;
          }),
        otherwise: Joi.any(),
      }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddThirdPartyFeeConfigurationForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      appzonePercentageAmountPerActiveLoan: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Active Loan Percentage is required";
                break;
              case "number.min":
                err.message = `Active Loan Percentage should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      appzonePercentageAmountPerLostLoan: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Lost Loan Percentage is required";
                break;
              case "number.min":
                err.message = `Lost Loan Percentage should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      appzoneFlatAmountPerSuccessfulTrx: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Flat Amount is required";
                break;
              case "number.min":
                err.message = `Flat Amount should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      appzoneMinimumFeePerSuccessfulTrx: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Minimum Fee is required";
                break;
              case "number.min":
                err.message = `Minimum Fee should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      appzoneMaximumFeePerSuccessfulTrx: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Maximum Fee is required";
                break;
              case "number.min":
                err.message = `Maximum Fee should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      thirdPartyPercentageAmountSwept: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "ThirdParty Amount Swept Percentage is required";
                break;
              case "number.min":
                err.message = `ThirdParty Amount Swept Percentage should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      thirdPartyFlatAmountPerSuccessfulTrx: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "ThirdParty Flat Amount is required";
                break;
              case "number.min":
                err.message = `ThirdParty Flat Amount should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      thirdPartyMinimumFeePerSuccessfulTrx: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "ThirdParty Minimum Fee is required";
                break;
              case "number.min":
                err.message = `ThirdParty Minimum Fee should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      thirdPartyMaximumFeePerSuccessfulTrx: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "ThirdParty Maximum Fee is required";
                break;
              case "number.min":
                err.message = `ThirdParty Maximum Fee should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddWalletConfigurationForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      serviceOffering: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Service Offering is required.",
          };
        }),
      billingType: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Billing Type is required.",
          };
        }),
      paymentMode: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Payment Mode is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditWalletConfigurationForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      billingType: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Billing Type is required.",
          };
        }),
      paymentMode: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Payment Mode is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export default {
  validateLoginForm,
  validateForgotPasswordForm,
  validateEditBranchForm,
  validateAddBranchForm,
  validateEditRegionForm,
  validateAddRegionForm,
  validateAddUserForm,
  validateAddInstitutionForm,
  validateAddLoanProductForm,
  validateEditInstitutionForm,
  validateEditUserForm,
  validateAddUserRoleForm,
  validateAddUserRoleFunctionForm,
  validateGrantConsentForm,
  validateEditSweepConfigurationForm,
  validateAddSweepConfigurationForm,
  validateEditUserRoleForm,
  validateUserSetupForm,
  validateAddMailingListForm,
  validateMailingListRecipientForm,
  validateEditMailingListForm,
  validateAddServiceOfferingForm,
  validateAddRecovaFeeConfigurationForm,
  validateAddThirdPartyFeeConfigurationForm,
  validateAddWalletConfigurationForm,
  validateEditWalletConfigurationForm
};
