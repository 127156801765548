export const dataEntryFields = [
    {
        "value": "PersonalDetails",
        "name": "Personal Details",
        "fields": [
            {
                "value": "title",
                "label": "Title",
                "checked": true
            },
            {
                "value": "firstName",
                "label": "First Name",
                "checked": true
            },
            {
                "value": "middleName",
                "label": "Middle Name",
                "checked": false
            },
            {
                "value": "lastName",
                "label": "Last Name",
                "checked": true
            },
            {
                "value": "gender",
                "label": "Gender",
                "checked": true
            },
            {
                "value": "maritalStatus",
                "label": "Marital Status",
                "checked": true
            },
            {
                "value": "dateOfBirth",
                "label": "Date of Birth",
                "checked": true
            },
            {
                "value": "age",
                "label": "Age",
                "checked": true
            },
            {
                "value": "motherMaidenName",
                "label": "Mother's Maiden Name",
                "checked": false
            },
            {
                "value": "numberOfDependent",
                "label": "Number of Dependent(s)",
                "checked": true
            },
            {
                "value": "nationality",
                "label": "Nationality",
                "checked": true
            },
            {
                "value": "educationalQualification",
                "label": "Educational Qualification",
                "checked": true
            },
            {
                "value": "accountType",
                "label": "Account Type",
                "checked": true
            },
            {
                "value": "accountNumber",
                "label": "Account Number",
                "checked": true
            },
            {
                "value": "ageOfAccount",
                "label": "Age of Account (In months)",
                "checked": true
            },
            {
                "value": "domiciliationOfSalary",
                "label": "Domiciliation of Salary",
                "checked": true
            },
            {
                "value": "identificationType",
                "label": "Identification Type",
                "checked": true
            },
            {
                "value": "identificationNumber",
                "label": "Identification Number",
                "checked": true
            },
            {
                "value": "identificationExpiryDate",
                "label": "Identification Expiry Date",
                "checked": true
            },
            {
                "value": "tin",
                "label": "Customer TIN",
                "checked": false
            },
        ]
    },
    {
        "value": "ContactDetails",
        "name": "Contact Details",
        "fields": [
            {
                "value": "addressType",
                "label": "Address Type",
                "checked": true
            },
            {
                "value": "propertyStatus",
                "label": "Property Status",
                "checked": true
            },
            {
                "value": "address",
                "label": "Address",
                "checked": true
            },
            {
                "value": "city",
                "label": "City/Town",
                "checked": true
            },
            {
                "value": "country",
                "label": "Country",
                "checked": true
            },
            {
                "value": "state",
                "label": "State/Region",
                "checked": true
            },
            {
                "value": "lengthOfYearAtCurrentAddress",
                "label": "Length of Year(s) at Current Address",
                "checked": true
            },
            {
                "value": "lengthOfMonthAtCurrentAddress",
                "label": "Length of Month(s) at Current Address",
                "checked": true
            },
            {
                "value": "lengthOfYearAtCurrentCity",
                "label": "Length of Year(s) at Current City",
                "checked": true
            },
            {
                "value": "lengthOfMonthAtCurrentCity",
                "label": "Length of Month(s) at Current City",
                "checked": true
            },
            {
                "value": "phoneNumber1",
                "label": "Phone Number 1",
                "checked": true
            },
            {
                "value": "phoneNumber2",
                "label": "Phone Number 2",
                "checked": false
            },
            {
                "value": "emailAddress",
                "label": "Email Address",
                "checked": true
            },
            {
                "value": "facebookId",
                "label": "Facebook ID",
                "checked": false
            },
            {
                "value": "twitterHandle",
                "label": "Twitter Handle",
                "checked": false
            },
            {
                "value": "instagramId",
                "label": "Instagram ID",
                "checked": false
            },
            {
                "value": "locationCoordinates",
                "label": "Location Co-ordinates",
                "checked": true
            }
        ]
    },
    {
        "value": "WorkDetails",
        "name": "Work Details",
        "fields": [
            {
                "value": "employmentIndustry",
                "label": "Employment Industry",
                "checked": true
            },
            {
                "value": "employmentDesignation",
                "label": "Employment Designation",
                "checked": true
            },
            {
                "value": "employmentStatus",
                "label": "Employment Status",
                "checked": true
            },
            {
                "value": "businessName",
                "label": "Business Name",
                "checked": true
            },
            {
                "value": "businessRegistration",
                "label": "Business Registration",
                "checked": true
            },
            {
                "value": "employeeStatus",
                "label": "Employee Status",
                "checked": true
            },
            {
                "value": "employerName",
                "label": "Employer Name",
                "checked": true
            },
            {
                "value": "employerCategory",
                "label": "Employer Category",
                "checked": true
            },
            {
                "value": "officePhoneNumber",
                "label": "Office Phone Number",
                "checked": true
            },
            {
                "value": "lengthOfYearInCurrentJob",
                "label": "Length of Year(s) in Current Job/Business",
                "checked": true
            },
            {
                "value": "lengthOfMonthInCurrentJob",
                "label": "Length of Month(s) in Current Job/Business",
                "checked": true
            },
            {
                "value": "workAddress",
                "label": "Work Address",
                "checked": true
            },
            {
                "value": "workCity",
                "label": "Work City/Town",
                "checked": true
            },
            {
                "value": "workCountry",
                "label": "Work Country",
                "checked": true
            },
            {
                "value": "workState",
                "label": "Work State/Region",
                "checked": true
            },
            {
                "value": "otherEmployerName",
                "label": "Other Employer Name",
                "checked": true
            },
        ]
    },
    {
        "value": "ReferenceDetails",
        "name": "Reference Details",
        "fields": [
            {
                "value": "referenceTitle",
                "label": "Title",
                "checked": true
            },
            {
                "value": "referenceRelationship",
                "label": "Relationship",
                "checked": true
            },
            {
                "value": "referenceFirstName",
                "label": "First Name",
                "checked": true
            },
            {
                "value": "referenceMiddleName",
                "label": "Middle Name",
                "checked": false
            },
            {
                "value": "referenceLastName",
                "label": "Last Name",
                "checked": true
            },
            {
                "value": "referenceGender",
                "label": "Gender",
                "checked": true
            },
            {
                "value": "referenceDateOfBirth",
                "label": "Date Of Birth",
                "checked": true
            },
            {
                "value": "referenceAddress",
                "label": "Address",
                "checked": true
            },
            {
                "value": "referenceCity",
                "label": "City",
                "checked": true
            },
            {
                "value": "referenceCountry",
                "label": "Country",
                "checked": true
            },
            {
                "value": "referenceState",
                "label": "State",
                "checked": true
            },
            {
                "value": "referencePhoneNumber1",
                "label": "Phone Number 1",
                "checked": true
            },
            {
                "value": "referencePhoneNumber2",
                "label": "Phone Number 2",
                "checked": false
            },
            {
                "value": "referenceEmailAddress",
                "label": "Email Address",
                "checked": false
            },
            {
                "value": "referenceMonthlyIncome",
                "label": "Monthly Income",
                "checked": false
            },
            {
                "value": "referenceOtherAssetsValue",
                "label": "Other Assets Value/Cash Position",
                "checked": false
            },
            {
                "value": "referenceLiabilities",
                "label": "Liabilities",
                "checked": false
            },
            {
                "value": "referenceMaritalStatus",
                "label": "Marital Status",
                "checked": true
            },
            {
                "value": "referenceBvn",
                "label": "BVN",
                "checked": true
            },
            {
                "value": "referenceLocationCoordinates",
                "label": "Location Co-ordinates",
                "checked": true
            },
            {
                "value": "referenceOccupation",
                "label": "Occupation",
                "checked": true
            },
            {
                "value": "referenceStateOfOrigin",
                "label": "State of Origin",
                "checked": true
            },
            {
                "value": "referenceLga",
                "label": "LGA",
                "checked": true
            },
            {
                "value": "referenceOfficeAddress",
                "label": "Office Address",
                "checked": true
            },
            {
                "value": "referenceDesignation",
                "label": "Designation",
                "checked": true
            },
            {
                "value": "loanAmountGuaranteed",
                "label": "Loan Amount Guaranteed",
                "checked": true
            },
            {
                "value": "referenceEmployerName",
                "label": "Employer Name",
                "checked": true
            }
        ]
    },
    {
        "value": "CollateralDetails",
        "name": "Collateral Details",
        "fields": [
            {
                "value": "collateralCategory",
                "label": "Collateral Category",
                "checked": true
            },
            {
                "value": "collateralType",
                "label": "Collateral Type",
                "checked": true
            },
            {
                "value": "collateralDescription",
                "label": "Collateral Description",
                "checked": true
            },
            {
                "value": "collateralValue",
                "label": "Collateral Value",
                "checked": true
            },
            {
                "value": "propertySize",
                "label": "Property Size",
                "checked": true
            },
            {
                "value": "propertyLocation",
                "label": "Property Location",
                "checked": true
            },
            {
                "value": "ownershipStructure",
                "label": "Ownership Structure",
                "checked": true
            },
            {
                "value": "serialRegistrationNumber",
                "label": "Serial Registration Number",
                "checked": true
            }
        ]
    },
    {
        "value": "DocumentCollection",
        "name": "Document Collection",
        "fields": [
            {
                "value": "documentName",
                "label": "Document Name",
                "checked": true
            },
            {
                "value": "documentCategory",
                "label": "Document Category",
                "checked": true
            },
            {
                "value": "documentStatus",
                "label": "Document Status",
                "checked": true
            },
            {
                "value": "documentExpiryDate",
                "label": "Document Expiry Date",
                "checked": false
            }
        ]
    },
    {
        "value": "BankStatement",
        "name": "Bank Statement",
        "fields": [
            {
                "value": "bankAccountNumber",
                "label": "Account Number",
                "checked": true
            },
            {
                "value": "bankName",
                "label": "Bank Name",
                "checked": true
            },
            {
                "value": "bankAccountType",
                "label": "Account Type",
                "checked": true
            },
            {
                "value": "accountStatementPeriod",
                "label": "Account Statement Period",
                "checked": false
            }
        ]
    },
    {
        "value": "NotepadDetails",
        "name": "Notepad Details",
        "fields": [
            {
                "value": "noteDetails",
                "label": "Note Details",
                "checked": true
            },
        ]
    },
    {
        "value": "IncomeDetails",
        "name": "Income Details",
        "fields": [
            {
                "value": "incomeType",
                "label": "Income Type",
                "checked": true
            },
            {
                "value": "frequencyIncome",
                "label": "Frequency Income",
                "checked": true
            },
            {
                "value": "incomeAmount",
                "label": "Income Amount",
                "checked": true
            },
            {
                "value": "incomeCurrency",
                "label": "Income Currency",
                "checked": true
            },
            {
                "value": "expenseType",
                "label": "Expense Type",
                "checked": true
            },
            {
                "value": "frequencyExpense",
                "label": "Frequency Expense",
                "checked": true
            },
            {
                "value": "expenseAmount",
                "label": "Expense Amount",
                "checked": true
            },
            {
                "value": "expenseCurrency",
                "label": "Expense Currency",
                "checked": true
            },
            {
                "value": "loanLiabilityInternalNumberOfLoans",
                "label": "Number Of Loans (Loan Liability Internal)",
                "checked": true
            },
            {
                "value": "loanLiabilityInternalTotalOutstanding",
                "label": "Total Outstanding (Loan Liability Internal)",
                "checked": true
            },
            {
                "value": "loanLiabilityInternalRepaymentFrequency",
                "label": "Repayment Frequency (Loan Liability Internal)",
                "checked": true
            },
            {
                "value": "loanLiabilityInternalRepaymentAmount",
                "label": "Repayment Amount (Loan Liability Internal)",
                "checked": true
            },
            {
                "value": "loanLiabilityInternalCurrency",
                "label": "Currency (Loan Liability Internal)",
                "checked": true
            },
            {
                "value": "loanLiabilityExternalNumberOfLoans",
                "label": "Number of Loans (Loan Liability External)",
                "checked": true
            },
            {
                "value": "loanLiabilityExternalTotalOutstanding",
                "label": "Total Outstanding (Loan Liability External)",
                "checked": true
            },
            {
                "value": "loanLiabilityExternalRepaymentFrequency",
                "label": "Repayment Frequency (Loan Liability External)",
                "checked": true
            },
            {
                "value": "loanLiabilityExternalRepaymentAmount",
                "label": "Repayment Amount (Loan Liability External)",
                "checked": true
            },
            {
                "value": "loanLiabilityExternalCurrency",
                "label": "Currency (Loan Liability External)",
                "checked": true
            }
        ]
    },
    {
        "value": "AssetDetails",
        "name": "Asset Details",
        "fields": [
            {
                "value": "assetCategory",
                "label": "Asset Category",
                "checked": true
            },
            {
                "value": "assetType",
                "label": "Asset Type",
                "checked": true
            },
            {
                "value": "assetBrand",
                "label": "Asset Brand",
                "checked": true
            },
            {
                "value": "assetModel",
                "label": "Asset Model",
                "checked": true
            },
            {
                "value": "assetYear",
                "label": "Asset Year",
                "checked": true
            },
            {
                "value": "assetDescription",
                "label": "Asset Description",
                "checked": true
            },
            {
                "value": "assetLife",
                "label": "Asset Life (In Years)",
                "checked": true
            },
            {
                "value": "assetAge",
                "label": "Asset Age (In Years)",
                "checked": true
            },
            {
                "value": "assetSupplier",
                "label": "Supplier",
                "checked": true
            },
            {
                "value": "assetCost",
                "label": "Asset Cost",
                "checked": true
            },
            {
                "value": "assetEquityPayment",
                "label": "Equity Payment",
                "checked": true
            },
            {
                "value": "assetLoanAmount",
                "label": "Loan Amount",
                "checked": true
            },
            {
                "value": "assetLoanToValue",
                "label": "Loan To Value (LTV)",
                "checked": true
            },
        ]
    },
    {
        "value": "PropertyDetails",
        "name": "Property Details",
        "fields": [
            {
                "value": "propertyType",
                "label": "Property Type",
                "checked": true
            },
            {
                "value": "propertyDescription",
                "label": "Property Description",
                "checked": true
            },
            {
                "value": "propertyAddress",
                "label": "Property Address",
                "checked": true
            },
            {
                "value": "propertyCity",
                "label": "Property City/Town",
                "checked": true
            },
            {
                "value": "propertyCountry",
                "label": "Property Country",
                "checked": true
            },
            {
                "value": "propertyState",
                "label": "Property State/Region",
                "checked": true
            },
            {
                "value": "marketValue",
                "label": "Market Value",
                "checked": true
            },
            {
                "value": "appraisedValue",
                "label": "Appraised Value (EFSV)",
                "checked": true
            },
            {
                "value": "appraiser",
                "label": "Appraiser",
                "checked": true
            },
            {
                "value": "appraisalDate",
                "label": "Appraisal Date",
                "checked": true
            },
            {
                "value": "locationCategory",
                "label": "Location Category",
                "checked": true
            },
            {
                "value": "propertyCost",
                "label": "Property Cost",
                "checked": true
            },
            {
                "value": "propertyEquityPayment",
                "label": "Equity Payment",
                "checked": true
            },
            {
                "value": "propertyLoanAmount",
                "label": "Loan Amount",
                "checked": true
            },
            {
                "value": "propertyLoanToValue",
                "label": "Loan To Value (LTV)",
                "checked": true
            },
        ]
    },
    {
        "value": "LoanDetails",
        "name": "Loan Details",
        "fields": [
            {
                "value": "loanAmount",
                "label": "Loan Amount",
                "checked": true
            },
            {
                "value": "loanTenor",
                "label": "Loan Tenor (In Days)",
                "checked": true
            },
            {
                "value": "interestRate",
                "label": "Interest Rate",
                "checked": true
            },
            {
                "value": "repaymentFrequencyPrincipal",
                "label": "Repayment Frequency (Principal)",
                "checked": true
            },
            {
                "value": "repaymentFrequencyInterest",
                "label": "Repayment Frequency (Interest)",
                "checked": true
            },
            {
                "value": "dueDate",
                "label": "Due Day",
                "checked": true
            },
            {
                "value": "moratorium",
                "label": "Moratorium",
                "checked": true
            },
            {
                "value": "moratoriumPeriod",
                "label": "Moratorium Period (In Months)",
                "checked": true
            },
            {
                "value": "impactOnTenor",
                "label": "Impact On Tenor",
                "checked": true
            },
            {
                "value": "chargeInterest",
                "label": "Charge Moratorium On",
                "checked": true
            },
            {
                "value": "interestAccrual",
                "label": "Interest Accrual",
                "checked": true
            },
            {
                "value": "loanCategory",
                "label": "Loan Category",
                "checked": false
            },
            {
                "value": "disburseTo",
                "label": "Disburse To",
                "checked": false
            },
            {
                "value": "supplierName",
                "label": "Supplier Name",
                "checked": false
            },
            {
                "value": "supplierBankName",
                "label": "Supplier Bank Name",
                "checked": false
            },
            {
                "value": "supplierAccountNumber",
                "label": "Supplier Account Number",
                "checked": false
            },
            {
                "value": "supplierPhoneNumber",
                "label": "Supplier Phone Number",
                "checked": false
            },
            {
                "value": "supplierAddress",
                "label": "Supplier Address",
                "checked": false
            },
        ]
    },
    {
        "value": "CorporateDetails",
        "name": "Corporate Details",
        "fields": [
            {
                "value": "companyName",
                "label": "Company Name",
                "checked": true
            },
            {
                "value": "registrationNumber",
                "label": "Registration Number",
                "checked": true
            },
            {
                "value": "constitution",
                "label": "Constitution",
                "checked": true
            },
            {
                "value": "industry",
                "label": "Industry",
                "checked": true
            },
            {
                "value": "incorporationDate",
                "label": "Incorporation Date",
                "checked": true
            },
            {
                "value": "contactPerson",
                "label": "Contact Person",
                "checked": true
            },
            {
                "value": "contactPersonDesignation",
                "label": "Contact Person Designation",
                "checked": true
            },
            {
                "value": "lengthOfYearsInBusiness",
                "label": "Length of Years(s) in Business",
                "checked": true
            },
            {
                "value": "lengthOfMonthsInBusiness",
                "label": "Length of Months(s) in Business",
                "checked": true
            },
            {
                "value": "corporateAccountType",
                "label": "Account Type",
                "checked": true
            },
            {
                "value": "corporateAccountNumber",
                "label": "Account Number",
                "checked": true
            },
            {
                "value": "corporateAgeOfAccount",
                "label": "Age of Account (in months)",
                "checked": true
            },
            {
                "value": "corporateCustomerTin",
                "label": "Customer Tin",
                "checked": true
            },
            {
                "value": "domiciliationOfBusinessProceeds",
                "label": "Domiciliation of Business proceeds",
                "checked": true
            },
            {
                "value": "typeOfProduct",
                "label": "Type of Product/Service offered",
                "checked": true
            },
            {
                "value": "productDurability",
                "label": "Product Durability",
                "checked": true
            },
            {
                "value": "threatOfSubstitute",
                "label": "Threat of Substitute/Competition",
                "checked": true
            },
            {
                "value": "availabilityOfInputMaterial",
                "label": "Availability of Input material/Trading stock",
                "checked": true
            },
            {
                "value": "complexityOfProduct",
                "label": "Complexity of Product/Service",
                "checked": true
            },
            {
                "value": "operationalStructure",
                "label": "Operational Structure",
                "checked": true
            },
            {
                "value": "numberOfYearsOperatingInTheSameLocation",
                "label": "Number of years operating in the same location",
                "checked": true
            },
            {
                "value": "foreignExchangeRisk",
                "label": "Foreign Exchange Risk",
                "checked": true
            },
            {
                "value": "membershipOfAnOrganizedBody",
                "label": "Membership of an Organized Body",
                "checked": false
            },
        ]
    },
    {
        "value": "StakeholderDetails",
        "name": "Stakeholder Details",
        "fields": [
            {
                "value": "stakeholderTitle",
                "label": "Title",
                "checked": true
            },
            {
                "value": "stakeholderPosition",
                "label": "Position",
                "checked": true
            },
            {
                "value": "stakeholderFirstName",
                "label": "First Name",
                "checked": true
            },
            {
                "value": "stakeholderMiddleName",
                "label": "Middle Name",
                "checked": false
            },
            {
                "value": "stakeholderLastName",
                "label": "Last Name",
                "checked": true
            },
            {
                "value": "stakeholderGender",
                "label": "Gender",
                "checked": true
            },
            {
                "value": "stakeholderDateOfBirth",
                "label": "Date Of Birth",
                "checked": true
            },
            {
                "value": "stakeholderAddress",
                "label": "Address",
                "checked": true
            },
            {
                "value": "stakeholderCity",
                "label": "City/Town",
                "checked": true
            },
            {
                "value": "stakeholderCountry",
                "label": "Country",
                "checked": true
            },
            {
                "value": "stakeholderState",
                "label": "State/Region",
                "checked": true
            },
            {
                "value": "stakeholderPhoneNumber1",
                "label": "Phone Number 1",
                "checked": true
            },
            {
                "value": "stakeholderPhoneNumber2",
                "label": "Phone Number 2",
                "checked": false
            },
            {
                "value": "stakeholderEmailAddress",
                "label": "Email Address",
                "checked": true
            },
            {
                "value": "stakeholderEducationalQualification",
                "label": "Educational Qualification",
                "checked": true
            },
            {
                "value": "stakeholderLengthOfTimeInCompanyInMonths",
                "label": "Length of time in Company (in months)",
                "checked": true
            },
            {
                "value": "stakeholderLengthOfTimeInCompanyInYears",
                "label": "Length of time in Company (in years)",
                "checked": true
            },
            {
                "value": "stakePercentage",
                "label": "Stake Percentage",
                "checked": true
            },
            {
                "value": "stakeholderIdentificationType",
                "label": "Identification Type",
                "checked": true
            },
            {
                "value": "stakeholderIdentificationNumber",
                "label": "Identification Number",
                "checked": true
            },
            {
                "value": "stakeholderIdentificationExpiryDate",
                "label": "Identification Expiry Date",
                "checked": true
            },
        ]
    },
]