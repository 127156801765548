import React, { Component } from "react";

export interface INewItemComponentProps {
  name: String;
  content: string;
  events: any;
  eventData: any;
}

export interface INewItemComponentState {}
class NewItemComponent extends Component<
  INewItemComponentProps,
  INewItemComponentState
> {
  render() {
    const { name, content } = this.props;
    const {
      title,
      userRoleId,
      applicableAmount,
      combinationNo,
      level,
    } = this.props.eventData;
    // console.log(this.props.events);
    return (
      <React.Fragment>
        {content === "+" ? (
          // <i
          //   className={"fa fa-plus-square fa-5x " + name}
          //   style={{ color: "#e7e6e6", cursor: "pointer" }}
          //   onClick={() =>
          //     this.props.events.addNewRule(
          //       userRoleId,
          //       combinationNo,
          //       level,
          //       applicableAmount,
          //       false
          //     )
          //   }
          // ></i>
          <div
            className={"plus-item " + name}
            onClick={() =>
              this.props.events.addNewRule(
                title,
                userRoleId,
                combinationNo,
                level,
                applicableAmount,
                false
              )
            }
          >
            <b>{content}</b>
          </div>
        ) : (
          <div
            className={"plus-item " + name}
            onClick={() =>
              this.props.events.addNewRule(
                title,
                userRoleId,
                combinationNo,
                level,
                applicableAmount,
                false
              )
            }
          >
            <b>{content}</b>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default NewItemComponent;
