import React from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import FormInputComponent from "../../../components/formInputComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import authValidationService from "../../../services/auth/authValidationService";
import userRoleFunctionService from "../../../services/auth/userRoleFunctionService";
import SelectInputComponent from "../../../components/formSelectComponent";
import textFormatService from "../../../services/utility/textFormatService";


interface AddUserRoleFunctionFields {
  displayName: string;
  description: string;
  functionAccess: string;
  parentId: string;
}

class AddUserRoleFunctionForm extends BaseFormComponent<AddUserRoleFunctionFields> { }

interface IAddUserRoleFunctionProps { }
interface IAddUserRoleFunctionState {
  parentFunctions: any;
}

class AddUserRoleFunction extends React.Component<
  IAddUserRoleFunctionProps,
  IBaseFormState & IAddUserRoleFunctionState
> {
  constructor(props: IAddUserRoleFunctionProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      parentFunctions: [],
    };
  }

  async componentDidMount() {
    const { errors, submitting } = this.state;
    try {
      const userRoleFunctions = await userRoleFunctionService.retrieveAllFunctions();
      const functions = userRoleFunctions.data;
      let parentFunctions = functions.filter((func) => func.parentId === 0);
      parentFunctions.push({ displayName: "No Parent(None)", id: 0 })
      //console.log("Parent Functions: ", parentFunctions);

      this.setState({
        parentFunctions: parentFunctions,
      });

    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        this.setState({ submitting: !submitting });
        errors.response = "Internal server error.";
        this.setState({ errors });
      }
    }
  }

  onFormSubmit(fields: AddUserRoleFunctionFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors: authValidationService.validateAddUserRoleFunctionForm(fields),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddUserRoleFunctionFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true });
      const payload: any = {};
      payload.displayName = fields.displayName;
      payload.roleName = textFormatService.removeSpace(fields.displayName)
      payload.description = fields.description;
      payload.functionAccess = fields.functionAccess;
      payload.parentId = Number(fields.parentId);
      //console.log(payload)

      const response = await userRoleFunctionService.saveUserRoleFunction(payload);
      this.setState({ submitting: false });

      await activityTrailService.saveActivityTrail({
        actionType: "Add User Role Function",
        description: `Added new User Role Function ${fields.displayName}`,
      });

      toast.info(`User Role Function ${fields.displayName} created successfully! `, {
        autoClose: 6000,
        type: toast.TYPE.DEFAULT,
        hideProgressBar: false,
      });

      onReloadFieldsCB();
      return true;
    } catch (error) {
      this.setState({ submitting: false });
      return false;
    }
  }

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
    });
  }

  render() {
    const { errors, validated, parentFunctions } = this.state;

    const functionAccessInputData = [
      { name: "Client", value: "Client" },
      { name: "Appzone", value: "Appzone" },
      { name: "Both", value: "Both" },
    ];

    const parentFunctionsInputData =
      parentFunctions.map((item) => ({
        name: item.displayName,
        value: item.id,
      }))


    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add User Role Function</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddUserRoleFunctionForm
                  initialValues={{
                    displayName: "",
                    description: "",
                    functionAccess: "",
                    parentId: "",

                  }}
                  FormComponent={({
                    fields: {
                      displayName,
                      description,
                      functionAccess,
                      parentId,
                    },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <h5 className="card-title">GENERAL INFORMATION</h5>
                          <div className="form-row">
                            <div className="col-md-12">
                              <div className="form-row">
                                <FormInputComponent
                                  id="displayName"
                                  type="text"
                                  name={"displayName"}
                                  placeholder=""
                                  divClass={6}
                                  value={displayName}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="description"
                                  type="text"
                                  name={"description"}
                                  placeholder=""
                                  divClass={6}
                                  value={description}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-row">
                                <SelectInputComponent
                                  id="functionAccess"
                                  name="functionAccess"
                                  divClass={6}
                                  value={functionAccess}
                                  items={functionAccessInputData}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <SelectInputComponent
                                  id="parentId"
                                  name="parentFunction"
                                  divClass={6}
                                  value={parentId}
                                  items={parentFunctionsInputData}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="card-body">
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                              disabled={this.state.submitting}
                              onClick={(e) => {
                                onHandleSubmit(
                                  e,
                                  ActionTypes.SAVE,
                                  "User Role Function",
                                  () => {
                                    this.onFormSubmit(
                                      {
                                        displayName,
                                        description,
                                        functionAccess,
                                        parentId
                                      },
                                      onReloadFields
                                    );
                                  }
                                );
                              }}
                            >
                              {this.state.submitting === false ? (
                                <React.Fragment>
                                  <i className="fas fa-lg fa-save mr-3" /> Save
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                  Please wait...
                                </React.Fragment>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                ></AddUserRoleFunctionForm>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddUserRoleFunction;
