import * as React from "react";
import _ from "lodash";
import { Fragment } from "react";
import {
  IFilterData,
  BaseListComponentState,
} from "../../../components/BaseListComponent";
import {
  createFilterData,
  mapEntityList,
} from "../../../services/utility/autoMapperService";
import BaseListComponent from "../../../components/BaseListComponent";
import ItemsTable, {
  filterFieldData,
} from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "../../../components/tableComponents/tableFilterPane";
import { getCurrentUserSession } from "../../../services/auth/authService";
import loanRequestService from "../../../services/creditAssessment/loanRequestService";
import loanProductService from "../../../services/creditAssessment/loanProductService";
import { decodeToken } from "react-jwt";
import LoanRequestDetail from "./loanRequestDetail";

export interface LoanRequestsOCMProps { }

export interface LoanRequestsOCMState extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
  allItems: any;
  updateBaseListState: boolean;
  toUpdate: boolean;
}

class LoanRequestsOCMList extends BaseListComponent<LoanRequestsOCMState> { }

class LoanRequestsOCM extends React.Component<
  LoanRequestsOCMProps,
  LoanRequestsOCMState
> {
  constructor(props: LoanRequestsOCMProps) {
    super(props);
    this.state = {
      data: createFilterData([
        "customerFirstName",
        "customerLastName",
        "customerBankVerificationNumber",
        "amount",
        "tenure",
        "customerPhoneNumber",
        "institutionCategory",
        "dateRequested",
        "loanProduct",
      ]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [
        { name: "customerFirstName", fieldType: "text", value: "" },
        { name: "customerLastName", fieldType: "text", value: "" },
        {
          name: "customerBankVerificationNumber",
          fieldType: "number",
          value: "",
        },
        { name: "amount", fieldType: "number", value: "" },
        { name: "tenure", fieldType: "number", value: "" },
        { name: "customerPhoneNumber", fieldType: "number", value: "" },
        {
          name: "institutionCategory",
          fieldType: "select",
          options: ["CommercialBank", "BankOneOFI", "OFI"],
          value: ""
        },
        { name: "dateRequested", fieldType: "date", value: "" },
        {
          name: "loanProduct",
          fieldType: "select",
          options: [],
          value: ""
        },
      ],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
      allItems: [],
      updateBaseListState: false,
      toUpdate: true,
    };
  }

  handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true, updateBaseListState: false });
    // const decodedToken = decodeToken(getCurrentUserSession().token || "");
    let dataClone =
      typeof data === "undefined" ? { ...this.state.data } : { ...data };
    try {
      const loanProducts = await loanProductService.retrieveAll();
      if (currentPage === 1 && this.state.toUpdate === true) {
        const loanProductNames = !_.isEmpty(loanProducts)
          ? loanProducts.data.map((item) => item.name)
          : [];
        let filterValueInput = [...this.state.filterValueInput];
        filterValueInput = filterValueInput.map((filterField) =>
          filterField.name === "loanProduct"
            ? { ...filterField, options: loanProductNames }
            : filterField
        );
        this.setState({
          filterValueInput: filterValueInput,
          updateBaseListState: true,
          toUpdate: false,
        });
      }
      const institutionObj = !_.isEmpty(loanProducts)
        ? loanProducts.data.map((item) => {
          return { name: item.name, id: item.id };
        })
        : [];

      const selectedInstitutionObj = !_.isEmpty(institutionObj)
        ? institutionObj.filter((item) => item.name === dataClone["loanProduct"].item2)[0]
        : {};

      const newDataArray = [
        "customerFirstName",
        "customerLastName",
        "customerBankVerificationNumber",
        "amount",
        "tenure",
        "customerPhoneNumber",
        "institutionCategory",
        "dateRequested",
        "loanProductId",
      ];
      let filterData: any = {};
      newDataArray.forEach((header) => {
        if (header === "loanProductId") {
          filterData[header] = !_.isEmpty(selectedInstitutionObj)
            ? { item1: dataClone["loanProduct"].item1, item2: selectedInstitutionObj.id }
            : { item1: "like", item2: "" };
        } else {
          filterData[header] = dataClone[header];
        }
      });

      const loanReqs = await loanRequestService.retrieveAll();

      const loanRequests: any = [];
      const totalLength = loanReqs.data.length;
      const initial = (currentPage - 1) * pageSize;
      for (let i = initial; i < initial + pageSize; i++) {
        if (i === totalLength) {
          break;
        }
        loanRequests.push(loanReqs.data[i]);
      }

      this.setState({
        tableItems: mapEntityList({
          // entityList: loanRequests.data.item2,
          entityList: loanRequests,
          properties: [
            { oldName: "customerFirstName", newName: "First Name" },
            { oldName: "customerLastName", newName: "Last Name" },
            { oldName: "customerBVN", newName: "BVN" },
            { oldName: "tenure", newName: "Tenure" },
            { oldName: "customerPhoneNumber", newName: "Phone Number" },
            { oldName: "amount", newName: "Amount" },
            { oldName: "dateRequested", newName: "requestDate" },
          ],
        }),
        currentPage,
        pageSize,
        // totalSize: loanRequests.data.item1,
        totalSize: totalLength,
        fetching: false, allItems: loanReqs.data,
      });
    } catch (ex) {
      //   console.log("caught exception", ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
        this.setState({ fetching: false });
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        this.setState({ fetching: false });
      }
    }
  };

  handleOnExport = async (type: string) => { };

  fetchItemById = async (item: any) => {
    this.setState({ fetchingDetail: true, updateBaseListState: false });
    try {
      const loanRequest = await loanRequestService.retrieveById(item.id);
      this.setState({ item: loanRequest.data, fetchingDetail: false });
    } catch (error) {
      // console.log(error.response);
      this.setState({ fetchingDetail: false });
    }
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }

  render() {
    const {
      // data,
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;
    // console.log("asfsdf", this.state.filterValueInput);
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Loan Requests (OCM)</b>
              </h3>
            </div>
            <LoanRequestsOCMList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              updateState={this.state.updateBaseListState}
              initialValues={{
                data: createFilterData([
                  "customerFirstName",
                  "customerLastName",
                  "customerBankVerificationNumber",
                  "amount",
                  "tenure",
                  "customerPhoneNumber",
                  "institutionCategory",
                  "dateRequested",
                  "loanProduct",
                ]) as IFilterData,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: this.state.filterValueInput,
                item: {},
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                onToggleEditModal,
                showDetailsModal,
                showEditModal,
              }) => (
                <Fragment>
                  <LoanRequestDetail
                    showDetailsModal={showDetailsModal}
                    toggleDetailsModal={onToggleDetailsModal}
                    toggleEditModal={onToggleEditModal}
                    showTrail={null}
                    currentUserRoles={null}
                    item={item}
                  />
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="Loan Requests"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                              amountColumn={["Amount"]}
                              amountInNaira={true}
                              timeColumn={["requestDate"]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></LoanRequestsOCMList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default LoanRequestsOCM;
