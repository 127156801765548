import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";

const linkingBaseAddress = process.env.REACT_APP_LINKING_BASEADDRESS;
export function retrieveAccountMapsByBvn(bvn: string) {
    var url = linkingBaseAddress + `api/BvnAccountMap/RetrieveByBvn/${bvn}`;

    // return http.get(url);
    return http.get(url, {
        // url: url,
        // method: "post",
        headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
}

export function retrieveAccountMapsByBvnAndInstitution(bvn: string, institutionCode: string) {
    var url = linkingBaseAddress + `api/BvnAccountMap/RetrieveByBvn/${bvn}/${institutionCode}`;

    return http.get(url, {
        url: url,
        method: "get",
        headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
}

export default {
    retrieveAccountMapsByBvn,
    retrieveAccountMapsByBvnAndInstitution
}