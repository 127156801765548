import Joi from "joi-browser";
import _ from "lodash";

export function validateViewLinkedAccountsForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      bvn: Joi.string()
        .required()
        .error((errors) => {
          // console.log("bvn joi-error", errors);
          return {
            message: "bvn is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateLinkBvnForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      bvn: Joi.string()
      .required()
      .error((errors) => {
        // console.log("bvn joi-error", errors);
        return {
            message: "bvn is required.",
          };
        }),
      }),
      {
        abortEarly: false,
      allowUnknown: true,
    }
    );
    const errors = {};
    if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateViewCustomerDetailsForm(data) {
  return validateViewLinkedAccountsForm(data);
}

export default {
  validateViewLinkedAccountsForm,
  validateLinkBvnForm,
  validateViewCustomerDetailsForm
};
