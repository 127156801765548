import React from "react";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import creditAssessmentValidationService from "../../../services/creditAssessment/creditAssessmentValidationService";
import workflowService from "../../../services/creditAssessment/workflowService";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../components/detailItem";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import FormInputComponent from "../../../components/formInputComponent";
import { splitAtUpper } from "../../../services/utility/textFormatService";
import SelectInputComponent, {
  ISelectItems,
} from "../../../components/formSelectComponent";

interface EditWorkFlowFields {
  workflowName: string;
  description: string;
}

class EditWorkFlowForm extends BaseFormComponent<EditWorkFlowFields> { }

interface IEditWorkFlowProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}
interface IEditWorkFlowState {
  validationResponse: any;
  workFlowStates: any;
  nextWorkFlowState: string;
  selectedWorkFlowSteps: any;
  sourceWorkFlowState: string;
}

class EditWorkFlow extends React.Component<
  IEditWorkFlowProps,
  IBaseFormState & IEditWorkFlowState
> {
  _isMounted = false;
  constructor(props: IEditWorkFlowProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      nextWorkFlowState: "",
      selectedWorkFlowSteps: [],
      sourceWorkFlowState: "Initiated",
      validationResponse: {},
      workFlowStates: [],
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { errors } = this.state;
    try {
      let workFlowStates = await workflowService.retrieveAllWorkFlowStates();
      const { item } = this.props;
      let selectedWorkFlowSteps: any = [];
      if (item.transitions.length > 0) {
        selectedWorkFlowSteps.push(item.transitions[0].sourceWorkflowStateId);
        for (let index = 0; index < item.transitions.length; index++) {
          selectedWorkFlowSteps.push(
            item.transitions[index].postWorkflowStateId
          );
        }
      } else {
        selectedWorkFlowSteps = workFlowStates.data
          .filter((item) => item.name === "Initiated")
          .map((selected) => selected.id);
      }
      // console.log("steps", workFlowStates.data);
      if (this._isMounted) {
        this.setState({
          workFlowStates: workFlowStates.data,
          selectedWorkFlowSteps: selectedWorkFlowSteps,
        });
      }
    } catch (ex) {
      // console.log("caught exception", ex);
      if (ex.response && ex.response.status === 404) {
        // console.log(ex.response);
        errors.response = "Internal server error.";
        // console.log(errors);
        if ((this._isMounted = false)) {
          this.setState({ errors });
        }
      }
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  onFormSubmit(fields: EditWorkFlowFields, onReloadFieldsCB: any): boolean {
    try {
      let validationResponse: any = {};
      //run validation for table
      let initiatedState: boolean = false;
      let disbursementDueState: boolean = false;
      let completedState: boolean = false;
      const { selectedWorkFlowSteps, workFlowStates } = this.state;

      selectedWorkFlowSteps.forEach((value) => {
        // console.log(value);
        let name = workFlowStates.filter((item) => item.id === value)[0].name;
        if (name === "Initiated") {
          initiatedState = true;
        } else if (name === "Disbursement Due") {
          disbursementDueState = true;
        } else if (name === "Completed") {
          completedState = true;
        }
      });
      validationResponse.initiatedState = initiatedState;
      validationResponse.disbursementDueState = disbursementDueState;
      validationResponse.completedState = completedState;
      this.setState(
        {
          errors:
            creditAssessmentValidationService.validateEditWorkFlowForm(fields),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            // console.log("val resp:: ", validationResponse);
            if (initiatedState && disbursementDueState && completedState) {
              return this.submit(fields, onReloadFieldsCB, validationResponse);
            }
          }
        }
      );
      this.setState({ validated: true, validationResponse });
      return false;
    } catch (error) {
      return false;
    }
  }

  submit = async (
    fields: EditWorkFlowFields,
    onReloadFieldsCB: any,
    validationResponse: any
  ) => {
    try {
      this.setState({
        submitting: true,
        validationResponse: validationResponse,
      });
      const payload = { ...this.props.item };
      //   console.log("For checks::", payload);
      payload.workflowName = fields.workflowName;
      payload.description = fields.description;

      let workFlowTransitions: any = [];
      const { selectedWorkFlowSteps } = this.state;
      for (let index = 0; index < selectedWorkFlowSteps.length - 1; index++) {
        workFlowTransitions.push({
          sourceWorkflowStateId: selectedWorkFlowSteps[index],
          postWorkflowStateId: selectedWorkFlowSteps[index + 1],
          priority: index,
        });
      }
      payload.workflowTransitions = workFlowTransitions;
      // console.log("payload for submission", payload);

      const response = await workflowService.updateWorkFlow(
        payload,
        payload.id
      );
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit Work Flow",
          description: `Edited details for Work Flow  ${fields.workflowName}`,
        });

        toast.info(`Work Flow ${fields.workflowName} Edited successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      }
      onReloadFieldsCB();
      this.setState({ submitting: false }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      //   console.log("submission error: ", error);
      this.setState({ submitting: false });
    }
  };

  handleOnWorkFlowInputChange = (value) => {
    this.setState({ nextWorkFlowState: value });
  };

  handleOnAddWorkFlowItem = (stateItem) => {
    const selectedWorkFlowSteps = [...this.state.selectedWorkFlowSteps];
    const { workFlowStates } = this.state;
    let sourceWorkFlowState: string = "";
    var checker = selectedWorkFlowSteps.filter((x) => x === stateItem);
    let stateName = workFlowStates.filter((item) => item.id === stateItem)[0]
      .name;
    let lastSelectedStateName = workFlowStates.filter(
      (item) =>
        item.id === selectedWorkFlowSteps[selectedWorkFlowSteps.length - 1]
    )[0].name;
    // console.log("State Name");
    if (!_.isEmpty(checker)) {
      if (stateName != "Initiated") {
        selectedWorkFlowSteps.splice(
          selectedWorkFlowSteps.indexOf(stateItem),
          1
        );
      }
    } else {
      if (lastSelectedStateName === "Completed") {
        toast.info(`No new workflow state can come after "Completed"`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else if (lastSelectedStateName === "Disbursement Due") {
        if (stateName === "Completed") {
          selectedWorkFlowSteps.push(stateItem);
        } else {
          toast.info(
            `This workflow state cannot come after "Disbursement Due"`,
            {
              autoClose: 6000,
              type: toast.TYPE.DEFAULT,
              hideProgressBar: false,
            }
          );
        }
      } else {
        selectedWorkFlowSteps.push(stateItem);
      }
    }
    sourceWorkFlowState = lastSelectedStateName;
    this.setState({
      selectedWorkFlowSteps,
      nextWorkFlowState: "",
      sourceWorkFlowState,
    });
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      selectedWorkFlowSteps: this.state.workFlowStates
        .filter((item) => item.name === "Initiated")
        .map((selected) => selected.id),
    });
  }

  render() {
    const { item, showEditModal, toggleEditModal } = this.props;
    const {
      validated,
      submitting,
      errors,
      validationResponse,
      sourceWorkFlowState,
      nextWorkFlowState,
      workFlowStates,
      selectedWorkFlowSteps,
    } = this.state;

    // console.log("Validation resp", validationResponse);
    let workFlowStatesInputData: ISelectItems[] = workFlowStates
      .filter((state) => !selectedWorkFlowSteps.includes(state.id))
      .map((item) => ({
        name: item.name,
        value: item.id,
      }));

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-columns fa-sm cyan-text mr-3" />
              Edit Work Flow -{" "}
              <span className="font-weight-bold">{item.workflowName}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditWorkFlowForm
          initialValues={{
            workflowName: item.workflowName,
            description: item.description,
          }}
          FormComponent={({
            fields: { workflowName, description },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    {_.isEmpty(item) ? (
                      <Skeleton width={200} />
                    ) : (
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        <b>General Information</b>
                      </h6>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={3} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Work Flow Name"
                              value={
                                <DetailItemInputComponent
                                  id="workflowName"
                                  type="text"
                                  name="workflowName"
                                  placeholder=""
                                  value={workflowName}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={4}
                              valueSize={8}
                            />
                            <hr />
                          </dl>
                        )}
                      </div>
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={3} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Description"
                              value={
                                <DetailItemInputComponent
                                  id="description"
                                  type="text"
                                  name="description"
                                  placeholder=""
                                  value={description}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={4}
                              valueSize={8}
                            />
                            <hr />
                          </dl>
                        )}
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-row">
                          <FormInputComponent
                            id="sourceWorkFlowState"
                            type="text"
                            name="sourceWorkFlowState"
                            placeholder=""
                            divClass={4}
                            value={sourceWorkFlowState}
                            required={true}
                            validated={validated}
                            errors={errors}
                            onChange={onChange}
                            disabled={true}
                          />
                          <SelectInputComponent
                            id="nextWorkFlowState"
                            name="nextWorkFlowState"
                            divClass={4}
                            value={nextWorkFlowState}
                            items={workFlowStatesInputData}
                            required={true}
                            validated={validated}
                            errors={errors}
                            onChange={(id, value) =>
                              this.handleOnWorkFlowInputChange(value)
                            }
                          />
                          <div className="my-1 mx-2">
                            <button
                              className="btn btn-sm btn-outline-primary float-right my-4"
                              style={{ cursor: "pointer" }}
                              type="button"
                              disabled={_.isEmpty(nextWorkFlowState)}
                              onClick={() =>
                                this.handleOnAddWorkFlowItem(nextWorkFlowState)
                              }
                            >
                              <React.Fragment>
                                <i className="far fa-save fa-lg mr-4" /> Save
                              </React.Fragment>
                            </button>
                          </div>
                        </div>

                        <div className="form-row col-md-11">
                          <h5 className="text-center">Work Flow States</h5>
                          <div
                            className="table-responsive text-nowrap"
                            style={{
                              height: "150px",
                              overflowY: "scroll",
                            }}
                          >
                            <table className="table table-hover table-content table-sm table-striped mb-0">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    style={{
                                      width: "5px",
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                    }}
                                  >
                                    <strong>#</strong>
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                    }}
                                  >
                                    <strong>Step</strong>
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      // width: "5px",
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                    }}
                                  ></th>
                                </tr>
                              </thead>
                              <tbody>
                                <React.Fragment>
                                  {selectedWorkFlowSteps.map((item) => (
                                    <tr key={item}>
                                      <td
                                        style={{
                                          width: "5px",
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {selectedWorkFlowSteps.indexOf(item) +
                                          1}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {splitAtUpper(
                                          workFlowStates.filter(
                                            (stateItem) => stateItem.id === item
                                          )[0].name
                                        )}
                                      </td>
                                      {selectedWorkFlowSteps.indexOf(item) ===
                                        0 ? (
                                        <td
                                          style={{
                                            paddingTop: "0.2rem",
                                            paddingBottom: "0.2rem",
                                            fontSize: "small",
                                          }}
                                        >
                                          <i
                                            className="far fa-trash-alt ml-5 red-text fa-lg"
                                            style={{
                                              opacity: 0.2,
                                            }}
                                          ></i>
                                        </td>
                                      ) : (
                                        <td
                                          style={{
                                            paddingTop: "0.2rem",
                                            paddingBottom: "0.2rem",
                                            fontSize: "small",
                                          }}
                                        >
                                          <i
                                            className="far fa-trash-alt ml-5 red-text fa-lg"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              this.handleOnAddWorkFlowItem(item)
                                            }
                                          ></i>
                                        </td>
                                      )}
                                    </tr>
                                  ))}
                                </React.Fragment>
                              </tbody>
                            </table>
                            <div
                              // className="invalid-feedback"
                              style={{
                                visibility: errors.workFlowSteps,
                                fontSize: "80%",
                                color: "#dc3545",
                              }}
                            >
                              {errors.workFlowSteps}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <h5 className="text-center" style={{ fontWeight: 400 }}>
                          <i className="fas fa-tasks mr-2"></i>Validations
                        </h5>
                        <hr />
                        <div className="row">
                          <div className="col-md-4 col-sm-4">
                            <ul style={{ listStyle: "none" }}>
                              <li className="brown-text small">
                                {!validated ? (
                                  <i className="far fa-circle mr-2"></i>
                                ) : validationResponse.initiatedState ==
                                  true ? (
                                  <i className="fas fa-check-circle green-text mr-2"></i>
                                ) : (
                                  <i className="fas fa-times-circle red-text mr-2"></i>
                                )}
                                There is an Initiated workflow state in
                                transitions.
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-4 col-sm-4">
                            <ul style={{ listStyle: "none" }}>
                              <li className="brown-text small">
                                {!validated ? (
                                  <i className="far fa-circle mr-2"></i>
                                ) : validationResponse.disbursementDueState ==
                                  true ? (
                                  <i className="fas fa-check-circle green-text mr-2"></i>
                                ) : (
                                  <i className="fas fa-times-circle red-text mr-2"></i>
                                )}
                                There is a Disbursement Due workflow state in
                                transitions.
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-4 col-sm-4">
                            <ul style={{ listStyle: "none" }}>
                              <li className="brown-text small">
                                {!validated ? (
                                  <i className="far fa-circle mr-2"></i>
                                ) : validationResponse.completedState ==
                                  true ? (
                                  <i className="fas fa-check-circle green-text mr-2"></i>
                                ) : (
                                  <i className="fas fa-times-circle red-text mr-2"></i>
                                )}
                                There is a Completed workflow state in
                                transitions.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(e, ActionTypes.UPDATE, "Work Flow", () => {
                      this.onFormSubmit(
                        { workflowName, description },
                        onReloadFields
                      );
                    });
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></EditWorkFlowForm>
      </Modal>
    );
  }
}

export default EditWorkFlow;
