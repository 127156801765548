import React, { useContext, useEffect, useState } from "react";
import corporateAnalysisService from "../../../../../../../../services/hybridAppraisal/corporateAnalysisService";
import _ from "lodash";
import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionToggle,
} from "react-bootstrap";
import DetailItem from "../../../../../../../../components/detailItem";
import { formatCurrency } from "../../../../../../../../services/utility/textFormatService";
export const FinancialRatioOutput = ({ loan }: any) => {
  const [financialRatioOutput, setFinancialRatioOutput] = useState<any>();

  useEffect(() => {
    const getFinancialRatioOutput = async () => {
      const response =
        await corporateAnalysisService.retrieveFinancialRatioOutput({
          loanRequestId: loan?.id,
        });
      if (response?.status === 200) {
        setFinancialRatioOutput(response?.data);
      }
    };
    getFinancialRatioOutput();
  }, [loan]);

  const profitablityRatios = financialRatioOutput?.profitablityRatios;
  const activityRatios = financialRatioOutput?.activityRatios;
  const leverageAndLiquidityRatios =
    financialRatioOutput?.leverageAndLiquidityRatios;

  return (
    <div style={{ width: "100%" }} className="clearfix">
      {!_.isEmpty(financialRatioOutput) ? (
        <div className="col-md-12 mb-4">
          <div className="col-md-12">
            <Container
              title="Profitablity Ratios"
              body={
                <div
                  className="table-responsive text-nowrap"
                  style={{ height: "150px", overflowY: "scroll" }}
                >
                  <div>
                    <dl
                      className="col-grid-2"
                      style={{ marginBottom: "0px", gap: 1 }}
                    >
                      {detailItem(
                        "Return on Equity",
                        profitablityRatios?.returnOnEquity.toFixed(2) + "%"
                      )}
                      {detailItem(
                        "Return on Assets",
                        profitablityRatios?.returnOnAssets.toFixed(2) + "%"
                      )}
                      {detailItem(
                        "Gross Margin",
                        profitablityRatios?.grossMargin.toFixed(2) + "%"
                      )}
                      {detailItem(
                        "Net Margin",
                        profitablityRatios?.netMargin.toFixed(2) + "%"
                      )}
                      {detailItem(
                        "Loan to Equity",
                        profitablityRatios?.loanToEquity.toFixed(2) + "%"
                      )}
                    </dl>
                    <br />
                  </div>
                </div>
              }
            />
            <br />
            <Container
              title="Activity Ratios"
              body={
                <div
                  className="table-responsive text-nowrap"
                  style={{ height: "150px", overflowY: "scroll" }}
                >
                  <div>
                    <dl
                      className="col-grid-2"
                      style={{ marginBottom: "0px", gap: 1 }}
                    >
                      {detailItem(
                        "Inventory Turn Over",
                        activityRatios?.inventoryTurnOver.toFixed(2) + "%"
                      )}
                      {detailItem(
                        "Collection Period",
                        activityRatios?.collectionPeriod.toFixed(2)
                      )}
                      {detailItem(
                        "Working Capital Turn Over",
                        activityRatios?.workingCapitalTurnOver.toFixed(2) + "%"
                      )}
                      {detailItem(
                        "Turn Over Decline To Break Even",
                        activityRatios?.turnOverDeclineToBreakEven.toFixed(2) +
                          "%"
                      )}
                    </dl>
                    <br />
                  </div>
                </div>
              }
            />
            <br />
            <Container
              title="Leverage and Liquidity Ratios"
              body={
                <div
                  className="table-responsive text-nowrap"
                  style={{ height: "150px", overflowY: "scroll" }}
                >
                  <div>
                    <dl
                      className="col-grid-2"
                      style={{ marginBottom: "0px", gap: 1 }}
                    >
                      {detailItem(
                        "Net Working Capital",
                        formatCurrency(
                          leverageAndLiquidityRatios?.netWorkingCapital,
                          2
                        )
                      )}
                      {detailItem(
                        "Debt to Asset",
                        leverageAndLiquidityRatios?.debtToAsset.toFixed(2) + "%"
                      )}
                      {detailItem(
                        "Debt to Equity",
                        leverageAndLiquidityRatios?.debtToEquity.toFixed(2) +
                          "%"
                      )}
                      {detailItem(
                        "Installment Coverage",
                        leverageAndLiquidityRatios?.installmentCoverage.toFixed(
                          2
                        ) + "%"
                      )}
                      {detailItem(
                        "Current Ratio",
                        leverageAndLiquidityRatios?.currentRatio.toFixed(2) +
                          "%"
                      )}
                      {detailItem(
                        "Acid Test",
                        leverageAndLiquidityRatios?.acidTest.toFixed(2) + "%"
                      )}
                    </dl>
                    <br />
                  </div>
                </div>
              }
            />
            <br />
            <div>
              <dl
                className="col-grid-2"
                style={{ marginBottom: "0px", gap: 1 }}
              >
                {detailItem(
                  "Collateral Ratio",
                  financialRatioOutput?.collateralRatio.toFixed(2) + "%"
                )}
                {detailItem(
                  "Business Days Required",
                  financialRatioOutput?.businessDaysRequired.toFixed(2)
                )}
              </dl>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            color: "red",
            marginTop: 20,
          }}
          className="py-2"
        >
          <i>No Financial Ratio Output available</i>
        </div>
      )}
    </div>
  );
};

const detailItem = (label: string, value: string | number) => (
  <DetailItem label={label} value={value} labelSize={6} valueSize={6} />
);

const ContextAwareToggle = ({ eventKey, header }) => {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      <b>{header}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
};

const Container = ({
  title,
  body,
}: {
  title: string;
  body: React.ReactNode;
}) => {
  return (
    <div className="card">
      <Accordion defaultActiveKey="0">
        <div>
          <Card>
            <ContextAwareToggle
              eventKey={`0`}
              header={
                <React.Fragment>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    style={{ color: "#878a8a" }}
                  ></i>{" "}
                  {title}
                </React.Fragment>
              }
            ></ContextAwareToggle>
            <Accordion.Collapse eventKey={`0`}>
              <Card.Body>
                <div
                  style={{
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  <div className="row">{body}</div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </div>
      </Accordion>
    </div>
  );
};
