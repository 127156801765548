import React, { Component, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import textFormatService from "../../../services/utility/textFormatService";

interface IScoreIndicatorDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  showTrail: any;
  currentUserRoles: any;
  item: any;
}

interface IScoreIndicatorDetailState {
  item: any;
}

class ScoreIndicatorDetail extends Component<IScoreIndicatorDetailProps, IScoreIndicatorDetailState> {
  constructor(props: IScoreIndicatorDetailProps) {
    super(props);
    this.state = {
      item: {}
    };
  }

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      item,
    } = this.props;

    var scoreMetrics: any = [];
    if (!_.isEmpty(item)) {
      scoreMetrics = item.indicatorOptions
    }

    // console.log(item)
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>
              <i className="fas fa-gopuram fa-sm cyan-text mr-3" />
              Score Indicator Details
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  General Information
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  {_.isEmpty(item) ? (
                    <Skeleton count={2} width={300} />
                  ) : (
                    <dl className="row">
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={2} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Indicator Name"
                              value={item.indicatorName}
                              labelSize={5}
                              valueSize={7}
                            />
                            <DetailItem
                              label="Indicator Type"
                              value={item.indicatorType}
                              labelSize={5}
                              valueSize={7}
                            />
                          </dl>
                        )}
                      </div>
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={2} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Entry Type"
                              value={item.entryType}
                              labelSize={5}
                              valueSize={7}
                            />
                            <DetailItem
                              label="Date Created"
                              value={textFormatService.formatTimeString(
                                item.dateCreated
                              )}
                              labelSize={5}
                              valueSize={7}
                            />
                          </dl>
                        )}
                      </div>
                    </dl>
                  )}
                </div>
              </div>
            </div>
            {item.indicatorType === 'Finite' ? (
              <Fragment>
                <div className="card-header">
                  {_.isEmpty(item) ? (
                    <Skeleton width={200} />
                  ) : (
                    <h6
                      className="card-title float-left"
                      style={{ marginBottom: "0px" }}
                    >
                      Score Metrics
                    </h6>
                  )}
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      {_.isEmpty(item) ? (
                        <Skeleton count={2} width={300} />
                      ) : (
                        <div
                          className="card-content table-responsive text-nowrap"
                          style={{ height: "150px", overflowY: "scroll" }}
                        >
                          <table className="table table-hover table-content table-sm table-bordered table-striped mb-0">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  style={{
                                    width: "5px",
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>#</strong>
                                </th>
                                <Fragment>
                                  <th
                                    scope="col"
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                    }}
                                  >
                                    <strong>Exact Value</strong>
                                  </th>
                                </Fragment>
                              </tr>
                            </thead>
                            <tbody>
                              {_.isEmpty(scoreMetrics) ? (
                                <tr>
                                  <td
                                    className="font-weight-normal font-italic text-truncate"
                                    colSpan={7}
                                    style={{
                                      textAlign: "center",
                                      color: "#FE825E",
                                    }}
                                  >
                                    ...No Score Metric for this Indicator.
                                  </td>
                                </tr>
                              ) : (

                                scoreMetrics.map((_item) => (
                                  <React.Fragment
                                    key={scoreMetrics.indexOf(_item) + 1}>
                                    <tr>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {scoreMetrics.indexOf(_item) + 1}
                                      </td>
                                      <Fragment>
                                        <td
                                          style={{
                                            paddingTop: "0.2rem",
                                            paddingBottom: "0.2rem",
                                            fontSize: "small",
                                          }}
                                        >
                                          {_item.optionName}
                                        </td>
                                      </Fragment>
                                    </tr>
                                  </React.Fragment>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>

                      )}
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={1} height={30} />
          ) : (
            <React.Fragment>
              {item.indicatorType === 'Finite' ? (
                <Button
                  size="sm"
                  variant="primary"
                  onClick={toggleEditModal}
                >
                  <i className="far fa-edit mr-3" />
                  Add Score Metrics
                </Button>
              ) : (
                ""
              )}
            </React.Fragment>
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ScoreIndicatorDetail;
