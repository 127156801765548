import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";

const influxagentBaseAddress =
  process.env.REACT_APP_INFLUXAGENT_BASEADDRESS + `api/`;

export function retrieveInstitutionRecoveryDetailsTickers(institutionCode: string) {
  var url = influxagentBaseAddress + `SweepAgent/RetrieveInstitutionRecoveryDetailsTickers?institutionCode=${institutionCode}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveRecoveryDetailsTickers() {
  var url = influxagentBaseAddress + `SweepAgent/RetrieveRecoveryDetailsTickers`;
  
  return http.get(url, {
    url: url,
    method: "get",
    headers: {Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveRecoveryStats(rangeInDays) {
  var url =
    influxagentBaseAddress +
    `SweepAgent/RetrieveRecoveryStats?rangeInDays=${rangeInDays}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveInstitutionRecoveryStats(rangeInDays, institutionCode) {
  var url =
    influxagentBaseAddress +
    `SweepAgent/RetrieveInstitutionRecoveryStats?rangeInDays=${rangeInDays}&institutionCode=${institutionCode}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function filterPagedInstitutionsRecoveryDetails(
  pageSize: number,
  pageNumber: number
) {
  var url =
    influxagentBaseAddress +
    `SweepAgent/FilterPagedInstitutionsRecoveryDetails?pageSize=${pageSize}&pageNumber=${pageNumber}`;

  return http.post(url, {
    url: url,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveInstitutionPendingSettlement(institutionCode: string) {
  var url = influxagentBaseAddress + `SweepAgent/RetrieveInstitutionPendingSettlement?institutionCode=${institutionCode}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrievePendingSettlement() {
  var url = influxagentBaseAddress + `SweepAgent/RetrievePendingSettlement`;
  
  return http.get(url, {
    url: url,
    method: "get",
    headers: {Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveInstitutionSuccessfulSettlement(institutionCode: string) {
  var url = influxagentBaseAddress + `SweepAgent/RetrieveInstitutionSuccessfulSettlement?institutionCode=${institutionCode}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveSuccessfulSettlement() {
  var url = influxagentBaseAddress + `SweepAgent/RetrieveSuccessfulSettlement`;
  
  return http.get(url, {
    url: url,
    method: "get",
    headers: {Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export default {
  retrieveInstitutionRecoveryDetailsTickers,
  retrieveRecoveryDetailsTickers,
  retrieveRecoveryStats,
  retrieveInstitutionRecoveryStats,
  filterPagedInstitutionsRecoveryDetails,
  retrieveInstitutionPendingSettlement,
  retrievePendingSettlement,
  retrieveInstitutionSuccessfulSettlement,
  retrieveSuccessfulSettlement,
};
