import React from "react";
import ReactWizard from "react-bootstrap-wizard";
import _ from "lodash";

import SetupCreditQualificationIndicators from "./../creditQualificationRuleManagement/setupCreditQualificationIndicators";
import { toast } from "react-toastify";
import EditFeeConfiguration from "./../feeConfiguration/editFeeConfiguration";
import { Modal, Button } from "react-bootstrap";

interface IEditInstitutionSetupProps {
  institution: any;
  feeConfig: any;
  indicators: any;
  showEditModal: boolean;
  toggleEditModal: any;
}
interface IEditInstitutionSetupState {}

class EditInstitutionSetup extends React.Component<
  IEditInstitutionSetupProps,
  IEditInstitutionSetupState
> {
  constructor(props: IEditInstitutionSetupProps) {
    super(props);
    this.state = {};
  }

  finishButtonClick(allStates) {
    toast.info(`Institution Configuration edited Successfully!`, {
      autoClose: 6000,
      type: toast.TYPE.SUCCESS,
      hideProgressBar: false,
    });
    this.props.toggleEditModal(true, this.props.institution);
  }
  render() {
    const {
      institution,
      feeConfig,
      indicators,
      showEditModal,
      toggleEditModal,
    } = this.props;
    let steps = [
      // this step hasn't got a isValidated() function, so it will be considered to be true
      {
        stepName: "Fee Configuration",
        component: EditFeeConfiguration,
        stepProps: {
          item: feeConfig,
        },
      },
      // this step will be validated to false
      {
        stepName: "Qualification Indicators",
        component: SetupCreditQualificationIndicators,
        stepProps: {
          institutionCode: institution.institutionCode,
          editting: true,
          indicators: indicators,
        },
      },
      // this step will never be reachable because of the seconds isValidated() steps function that will always return false
      //   { stepName: "Third", component: ThirdStep },
    ];

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              Edit Institution Setup -{" "}
              <span className="font-weight-bold">
                {institution.institutionName}
              </span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <React.Fragment>
          <Modal.Body>
            <React.Fragment>
              <div className="container-fluid relative animatedParent animateOnce">
                <div className="animated fadeInUpShort go">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card no-b">
                        {_.isEmpty(institution) ? (
                          ""
                        ) : (
                          <ReactWizard
                            steps={steps}
                            //   navSteps
                            //   title="react-wizard"
                            //   description="This will help you split a complicated flow or a complicated form in multiple steps."
                            // nextButtonText={"Save"}
                            // finishButtonText={"Save"}
                            previousButtonClasses={"hide"}
                            progressbar={true}
                            headerTextCenter
                            validate
                            color="primary"
                            finishButtonClick={this.finishButtonClick}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              variant="outline-danger"
              onClick={() => toggleEditModal(false, institution)}
              // disabled={submitting}
            >
              <i className="fas fa-times mr-3" />
              Close
            </Button>
          </Modal.Footer>
        </React.Fragment>
      </Modal>
    );
  }
}

export default EditInstitutionSetup;
