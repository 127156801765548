import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import { ActionTypes } from "../../../enums/actionTypes";
import swal from "sweetalert";
import { getCurrentUsersRoles } from "../../../services/auth/authService";

interface ICollectionConsentConfigDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  showTrail: any;
  currentUserRoles: any;
  item: any;
  togglePatchModal: any;
}

interface ICollectionConsentConfigDetailState {
  item: any;
  updatingStatus: boolean;
}

class CollectionConsentConfigDetail extends Component<
  ICollectionConsentConfigDetailProps,
  ICollectionConsentConfigDetailState
> {
  state = {
    item: {},
    updatingStatus: false,
  };

  static getDerivedStateFromProps(
    nextProps: ICollectionConsentConfigDetailProps,
    prevState
  ) {
    // console.log("current props: ", nextProps.item);
    return {
      item: nextProps.item,
    };
  }

  onHandleEnableOrDisable = (
    e: any,
    actionType: ActionTypes,
    entityName: string
  ) => {
    this.setState({ updatingStatus: true });
    if (!actionType) {
      this.handleEnableOrDisableItem(this.props.item);
    } else {
      swal({
        title: "Confirm!",
        text: `Are you sure you want to proceed to ${ActionTypes[actionType]} this ${entityName}?`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode: true,
      }).then((resp) => {
        if (resp) {
          // console.log("cancelling action...");
          this.setState({ updatingStatus: false });
        } else {
          this.handleEnableOrDisableItem(this.state.item);
        }
      });
    }
  };

  handleEnableOrDisableItem = async (_item: any) => {};

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      item,
    } = this.props;
    const { updatingStatus } = this.state;

    var collectionConsentTypesArray: any = [];
    if(!_.isEmpty(item)){
      collectionConsentTypesArray = item.collectionConsentTypes
    }

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="icon icon--columns s-18 mr-3" />
                Collection Consent Config -{" "}
                <span className="font-weight-bold">{item.repaymentBank}</span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Repayment Bank"
                        value={item.repaymentBank}
                        labelSize={5}
                        valueSize={7}
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Bank Code"
                        value={item.bankCode}
                        labelSize={5}
                        valueSize={7}
                      />
                    </dl>
                  )}
                </div>

                
                <div className="col-md-12">
                      {_.isEmpty(item) ? (
                        <Skeleton count={1} width={300} height={200} />
                      ) : (
                          <React.Fragment>
                            <label
                            htmlFor="reportItems"
                            className="control-label textbox-label"
                            style={{ fontWeight: 600, fontSize: "14px" }}
                            >
                            {"  "} Collection Consent Types
                            </label>
                            <div
                            className="text-nowrap block-example border"
                            style={{
                                width: "100%",
                                height: "200px",
                                overflowY: "scroll",
                            }}
                            >
                            <React.Fragment>
                                {collectionConsentTypesArray.length ===
                                0 ? (
                                <div
                                    style={{
                                    backgroundColor: "#F3F3F3",
                                    }}
                                >
                                    <p className="text-center">
                                    No Available Collection Consent Type Found
                                    </p>
                                </div>
                                ) : (
                                <React.Fragment>
                                    {collectionConsentTypesArray?.map(
                                    (item) => (
                                        <div
                                        className="custom-control custom-checkbox"
                                        key={collectionConsentTypesArray.indexOf(item)}
                                        >
                                        <input
                                            id={item}
                                            name={item}
                                            className="custom-control-input"
                                            type="checkbox"
                                            checked={true}
                                            disabled={true}
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor={item}
                                        >
                                            {item}
                                        </label>
                                        </div>
                                    )
                                    )}
                                </React.Fragment>
                                )}
                            </React.Fragment>
                            </div>
                        
                        </React.Fragment>
                      )}
                    </div>




              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
              {getCurrentUsersRoles().includes("addmandateservicetypes") && (
                <Button
                  size="sm"
                  variant="primary"
                  disabled={updatingStatus}
                  onClick={toggleEditModal}
                >
                  <i className="far fa-edit mr-3" />
                  Edit
                </Button>
              )}
            </React.Fragment>
          )}

          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CollectionConsentConfigDetail;
