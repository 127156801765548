import http from "../httpService";

const sweepBaseAddress = process.env.REACT_APP_SWEEP_BASEADDRESS;

export function retrieveDistinctSweepServiceLinkedInstitutions() {
    return http.get(
        sweepBaseAddress +
        `SweepServicePriority/RetrieveDistinctSweepServiceLinkedInstitutions`
    );
}

export default {
    retrieveDistinctSweepServiceLinkedInstitutions,
}