import React, { Component, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import { getCurrentUsersRoles } from "../../../services/auth/authService";

interface IHybridAppraisalTranConfigurationDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  showTrail: any;
  currentUserRoles: any;
  item: any;
}

interface IHybridAppraisalTranConfigurationDetailState {
  item: any;
  updatingStatus: boolean;
}

class HybridAppraisalTranConfigurationDetail extends Component<IHybridAppraisalTranConfigurationDetailProps, IHybridAppraisalTranConfigurationDetailState> {
  state = {
    item: {},
    updatingStatus: false,
  };

  static getDerivedStateFromProps(nextProps: IHybridAppraisalTranConfigurationDetailProps) {
    return {
      item: nextProps.item,
    };
  }

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      item,
    } = this.props;
    const { updatingStatus } = this.state;

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        {_.isEmpty(item) ? (
          <Skeleton width={500} />
        ) : (
          <Fragment>
            <Modal.Header closeButton>
              <Modal.Title>
                {_.isEmpty(item) ? (
                  <Skeleton width={500} />
                ) : (
                  <h5>
                    <i className="icon icon-building-o s-18 mr-3" />
                    Hybrid Appraisal Transaction Fee Configuration Details -{" "}
                    <span className="font-weight-bold">{item.institutionCategory}</span>
                  </h5>
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="card">
                {/* {(item.name === 'Recova') ? ( */}
                  <Fragment>
                    <div className="card-header clear-fix">
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        Fee
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <dl className="row">
                          <DetailItem
                              label="Fee Name"
                              labelSize={9}
                              valueSize={3}
                              value={item.feeName}
                            />
                            <DetailItem
                              label="Flat Per Approved Loan"
                              labelSize={9}
                              valueSize={3}
                              value={item.flatPerApprovedLoan}
                            />
                            <DetailItem
                              label="Flat Per Request"
                              labelSize={9}
                              valueSize={3}
                              value={item.flatPerRequest}
                            />
                            
                          </dl>
                        </div>
                        <div className="col-md-6">
                          <dl className="row">
                            {/* <DetailItem
                              label="Minimum Fee"
                              labelSize={9}
                              valueSize={3}
                              value={item.minimumFee}
                            />
                            <DetailItem
                              label="Maximum Fee"
                              labelSize={9}
                              valueSize={3}
                              value={item.maximumFee}
                            />
                            <DetailItem
                              label="Percentage Of Approved Amount"
                              labelSize={9}
                              valueSize={3}
                              value={item.percentageOfApprovedAmount + " %"}
                            /> */}
                          </dl>
                        </div>
                      </div>
                    </div>
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <label
                              htmlFor="reports"
                              className="col-form-label s-10"
                            >
                              Validation Fees
                            </label>
                            <br />
                            <div className="form-row">
                                {item.validationOptions.map((option)=>(
                                  //  <dl className="row">
                                  <DetailItem
                                    label={option.pricingOption.code}
                                    labelSize={9}
                                    valueSize={3}
                                    value={option.fee}
                                  />
                                  //  </dl>
                                ))
                                }
                      
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Fragment>
                {getCurrentUsersRoles().includes("editpaymentconfiguration") && (
                  <Button
                    size="sm"
                    variant="primary"
                    disabled={updatingStatus}
                    onClick={toggleEditModal}
                  >
                    <i className="far fa-edit mr-3" />
                    Edit
                  </Button>
                )}
              </Fragment>
              <Button
                size="sm"
                variant="outline-danger"
                onClick={toggleDetailsModal}
              >
                <i className="fas fa-times mr-3" />
                Close
              </Button>
            </Modal.Footer>
          </Fragment>
        )}
      </Modal>
    );
  }
}

export default HybridAppraisalTranConfigurationDetail;