import React, { Fragment } from "react";
import CheckboxTree from "react-checkbox-tree";
import { toast } from "react-toastify";
import SelectInputComponent, {
  ISelectItems,
} from "../../components/formSelectComponent";
import { ActionTypes } from "../../enums/actionTypes";
import serviceOfferingConfigService from "../../services/auth/serviceOfferingConfigService";
import BaseFormComponent, {
  IBaseFormState,
} from "./../../components/BaseFormComponent";
import activityTrailService from "../../services/auditTrail/activityTrailService";

interface ConfigureDefaultModuleFields {
  institutionCategory: string;
}

class ConfigureDefaultModuleForm extends BaseFormComponent<ConfigureDefaultModuleFields> {}

interface ConfigureDefaultModuleProps {}

interface ConfigureDefaultModuleState {
  fetchingDefaultModules: boolean;
  availableModules: any;
  checked: any;
  institutionCategory: string;
}

class ConfigureDefaultModule extends React.Component<
  ConfigureDefaultModuleProps,
  IBaseFormState & ConfigureDefaultModuleState
> {
  constructor(props: ConfigureDefaultModuleProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      fetchingDefaultModules: false,
      availableModules: [],
      checked: [],
      institutionCategory: "CommercialBank",
    };
  }

  async componentDidMount() {
    const { errors, submitting } = this.state;
    try {
      this.setState({ fetchingDefaultModules: true });
      var defaultModules =
        await serviceOfferingConfigService.retrieveDefaultModulesByInstitutionCategory(
          "CommercialBank"
        );
      const clientModules =
        await serviceOfferingConfigService.retrieveAllClientModules();
      let allModules: any = clientModules.data;
      let treeCollection: any = [];
      treeCollection = allModules.map((func) => {
        let newSet: any = {};
        newSet = {
          value: func.id,
          label: func.displayName,
        };
        return newSet;
      });
      this.setState({
        checked: defaultModules.data.map((a) => a.userRoleFunctionId),
        availableModules: treeCollection,
        fetchingDefaultModules: false,
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        this.setState({ submitting: !submitting });
        errors.response = "Internal server error.";
        this.setState({ errors });
      }
    } finally {
      this.setState({ fetchingDefaultModules: false });
    }
  }

  handleOnInstitutionCategoryChange = async (
    id,
    institutionCategory: string
  ) => {
    var defaultModules =
      await serviceOfferingConfigService.retrieveDefaultModulesByInstitutionCategory(
        institutionCategory
      );
    let result: number[] = defaultModules.data.map((a) =>
      Number(a.userRoleFunctionId)
    );
    this.setState({ checked: result, institutionCategory });
  };

  onFormSubmit(
    fields: ConfigureDefaultModuleFields,
    onReloadFieldsCB: any
  ): boolean {
    try {
      this.setState(
        {
          errors: [],
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: ConfigureDefaultModuleFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true });
      let functions: number[] = [];
      this.state.checked.forEach((element) => {
        functions.push(Number(element));
      });
      if (functions.length > 0) {
        const response = await serviceOfferingConfigService.saveDefaultModules(
          this.state.institutionCategory,
          functions
        );
        if (response.status >= 200 && response.status <= 300) {
          await activityTrailService.saveActivityTrail({
            actionType: "Configure Default Modules",
            description: `Configure default modules ${this.state.institutionCategory}`,
          });
          toast.info(
            `Successfully configured default module for ${this.state.institutionCategory}.`,
            {
              autoClose: 6000,
              type: toast.TYPE.DEFAULT,
              hideProgressBar: false,
            }
          );
          //   this.setState({ checked: [] });
          onReloadFieldsCB();
        }
        this.setState({ submitting: false });
      } else {
        toast.warning(`No module selected!.`, {
          autoClose: 6000,
          type: toast.TYPE.WARNING,
          hideProgressBar: false,
        });
        this.setState({ submitting: false });
      }
    } catch (error) {
      this.setState({ submitting: false });
      return false;
    }
  }

  render() {
    const {
      errors,
      validated,
      submitting,
      availableModules,
      fetchingDefaultModules,
      institutionCategory,
    } = this.state;

    let institutionCategoryInputData: ISelectItems[] = [
      { name: "Commercial Bank", value: "CommercialBank" },
      { name: "BankOne OFI", value: "BankOneOFI" },
      { name: "OFI", value: "OFI" },
    ];

    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Configure Default Modules</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ConfigureDefaultModuleForm
                  initialValues={{
                    institutionCategory: institutionCategoryInputData[0].value,
                  }}
                  FormComponent={({
                    fields: { institutionCategory },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <h5 className="card-title">GENERAL INFORMATION</h5>
                          <div className="form-row">
                            <div className="col-md-6">
                              <SelectInputComponent
                                id="institutionCategory"
                                name="institutionCategory"
                                divClass={12}
                                value={this.state.institutionCategory}
                                items={institutionCategoryInputData}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={
                                  this.handleOnInstitutionCategoryChange
                                }
                              // onChangeCallBack={onChange}
                              />
                              <br />
                            </div>
                            <div className="col-md-6">
                              <label className="col-form-label s-12">
                                Available Functions: (
                                {this.state.institutionCategory})
                              </label>
                              {fetchingDefaultModules ? (
                                <React.Fragment>
                                  <i className="fas fa-spin fa-circle-notch mr-3" />
                                </React.Fragment>
                              ) : (
                                <div className="form-row">
                                  <span className="col-sm-1 ml-2"></span>
                                  <CheckboxTree
                                    icons={{
                                      leaf: (
                                        <i className="icon-radio_button_checked blue-text"></i>
                                      ),
                                      halfCheck: (
                                        <i className="icon-square"></i>
                                      ),
                                      check: (
                                        <i className="icon-check_box green-text"></i>
                                      ),
                                    }}
                                    nodes={availableModules}
                                    checked={this.state.checked}
                                    onCheck={(checked) => {
                                      this.setState({ checked });
                                    }}
                                    iconsClass="fa5"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="card-body">
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                            disabled={submitting}
                            onClick={(e) => {
                              onHandleSubmit(
                                e,
                                ActionTypes.SAVE,
                                "Service Offering Configuration",
                                () => {
                                  this.onFormSubmit(
                                    {
                                      institutionCategory,
                                    },
                                    onReloadFields
                                  );
                                }
                              );
                            }}
                          >
                            {submitting === false ? (
                              <React.Fragment>
                                <i className="fas fa-lg fa-save mr-3" /> Save
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <i className="fas fa-spin fa-circle-notch" />
                              </React.Fragment>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                ></ConfigureDefaultModuleForm>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ConfigureDefaultModule;
