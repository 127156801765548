import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../components/detailItem";
import textFormatService from "../../services/utility/textFormatService";
import DetailItemsTable from "../../components/tableComponents/detailItemsTable";
import { mapEntityList } from "../../services/utility/autoMapperService";
import reportsService from "../../services/sweep/reportsService";
import ThirdPartyImageView from "../../components/thirdPartyImageView";

interface IIncomingRepaymentReportDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  item: any;
}

interface IIncomingRepaymentReportDetailState {
  similarTransactions: any;
  similarTransactionsTotalCount: number;
  similarTransactionsCurrentPage: number;
  fetchingSimilarTransactions: boolean;
}

class IncomingRepaymentReportDetail extends Component<
  IIncomingRepaymentReportDetailProps,
  IIncomingRepaymentReportDetailState
> {
  constructor(props: IIncomingRepaymentReportDetailProps) {
    super(props);
    this.state = {
      fetchingSimilarTransactions: false,
      similarTransactions: [],
      similarTransactionsCurrentPage: 1,
      similarTransactionsTotalCount: 0,
    };
  }

  handleOnSimilarTransactionsPageChange = async (
    page: number,
    transactionId
  ) => {
    this.setState({ fetchingSimilarTransactions: true });
    // console.log(
    //   "about to fetch request mandates: ",
    //   this.state.fetchingSimilarTransactions
    // );
    try {
      const similarTrx = await reportsService.retrieveSimilarTransactionsReport(
        transactionId,
        Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
        page
      );
      this.setState({
        similarTransactions: mapEntityList({
          entityList: similarTrx.data.item2,
          properties: [
            {
              oldName: "linkedAccountInstitution",
              newName: "Inflow Institution",
            },
            { oldName: "amountAttempted", newName: "amountAttempted" },
            { oldName: "amountDebited", newName: "amountDebited" },
            { oldName: "timeSwept", newName: "timeSwept" },
            { oldName: "customerType", newName: "customerType" },
            { oldName: "customerName", newName: "customerName" },
            {
              oldName: "accountCreditedNumber",
              newName: "creditAccountNumber",
            },
            { oldName: "accountDebitedNumber", newName: "debitAccountNumber" },
            { oldName: "retrievalReferenceNo", newName: "retrievalReference" },
          ],
        }),
        similarTransactionsCurrentPage: page,
        similarTransactionsTotalCount: similarTrx.data.item1,
        fetchingSimilarTransactions: false,
      });
    } catch (error) {
      // console.log("error fetching similar transactions: ", error);
      this.setState({ fetchingSimilarTransactions: false });
    } finally {
      this.setState({ fetchingSimilarTransactions: false });
    }
  };

  async componentDidMount() {
    try {
      if (this.props.item.id) {
        // console.log(
        //   'about to fetch similar transactions...',
        //   this.props.item.id
        // )
        this.handleOnSimilarTransactionsPageChange(
          this.state.similarTransactionsCurrentPage,
          this.props.item.id
        );
      }
    } catch (error) {
      this.setState({ fetchingSimilarTransactions: false });
    }
  }

  render() {
    const { showDetailsModal, toggleDetailsModal, item } = this.props;
    const {
      similarTransactions,
      similarTransactionsTotalCount,
      similarTransactionsCurrentPage,
      fetchingSimilarTransactions,
    } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="fas fa-receipt fa-sm s-18 mr-3" />
                Inflow Details{" "}
                {item.isReversed && (
                  <span className="badge badge-danger ml-3">Reversed</span>
                )}
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={6} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Loan Reference"
                        value={item.loanAccountNo}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Inflow Institution"
                        value={item.linkedAccountInstitution}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Inflow Account No."
                        value={item.linkedAccountNo}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Customer Name"
                        value={item.customerName}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Unique ID"
                        value={item.uniqueId}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Settlement Reference"
                        value={item.settlementReference}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Retrieval Reference"
                        value={item.retrievalReferenceNo}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Sweep Service"
                        value={
                          <ThirdPartyImageView
                            thirdPartyName={item.thirdPartySweepService}
                          />
                        }
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Settlement Amount Basis"
                        value={item.settlementAmountBasis}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Loan Update Status"
                        value={item.lbuStatus}
                        labelSize={5}
                        valueSize={7}
                      />
                      {/* <DetailItem
                        label="Loan Update Completion Date"
                        value={textFormatService.formatTimeString(
                          item.dateCompletedLbu
                        )}
                        labelSize={5}
                        valueSize={7}
                      /> */}
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={6} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Amount Inititated"
                        value={textFormatService.convertKoboToNaira(
                          item.amountOutstanding,
                          2
                        )}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Amount Attempted"
                        value={textFormatService.convertKoboToNaira(
                          item.amountAttempted,
                          2
                        )}
                        colorIndicator={
                          item.amountAttempted === item.amountDebited
                            ? "#BAFEB7"
                            : "#FAB39B"
                        }
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Amount Debited"
                        value={textFormatService.convertKoboToNaira(
                          item.amountDebited,
                          2
                        )}
                        colorIndicator={
                          item.amountAttempted === item.amountDebited
                            ? "#BAFEB7"
                            : "#FAB39B"
                        }
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Settlement Amount"
                        value={textFormatService.convertKoboToNaira(
                          item.settlementAmount,
                          2
                        )}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Fee"
                        value={textFormatService.convertKoboToNaira(
                          item.settlementFee,
                          2
                        )}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Settlement Status"
                        value={item.settlementStatus}
                        labelSize={5}
                        valueSize={7}
                      />
                      {/* <DetailItem
                        label="Credit Account Number"
                        value={item.accountCreditedNumber}
                        labelSize={5}
                        valueSize={7}
                      /> */}
                      <DetailItem
                        label="Debit Account Number"
                        value={item.accountDebitedNumber}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Time Swept"
                        value={textFormatService.formatTimeString(
                          item.timeSwept
                        )}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Narration"
                        value={item.narration}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Designation"
                        value={item.designation}
                        labelSize={5}
                        valueSize={7}
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>
            <DetailItemsTable
              title="Similar Transactions"
              item={item}
              handleOnPageChange={this.handleOnSimilarTransactionsPageChange}
              content={similarTransactions}
              contentCount={similarTransactionsTotalCount}
              page={similarTransactionsCurrentPage}
              fetching={fetchingSimilarTransactions}
              amountColumn={["amountDebited", "amountAttempted"]}
              timeColumn={["timeSwept"]}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default IncomingRepaymentReportDetail;
