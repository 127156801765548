import http from "../httpService";
import { IBranchBasedFilterData, IFilterData } from "../approval/approvalItemService";

const appraisalBaseAddress = process.env.REACT_APP_HYBRIDAPPRAISAL_BASEADDRESS;

export function retrieveById(id) {
    return http.get(
        appraisalBaseAddress + `LoanRequest/RetrieveById/${id}`
    );
}

export function retrieveAllByTin(tin, instCode) {
    return http.get(
        appraisalBaseAddress + `LoanRequest/RetrieveAllByTIN/${tin}/${instCode}`
    );
}

export function updateLoanRequest(payload) {
    return http.put(
        appraisalBaseAddress + `LoanRequest/EditLoanRequest`,
        payload
    );
}

export function cretaeIndividualLoanRequest(payload) {
    return http.post(
        appraisalBaseAddress + `LoanRequest/CreateIndividualLoanRequest`,
        payload
    );
}

export function reinitiateLoanRequests(id) {
    return http.post(
        appraisalBaseAddress + `LoanRequest/ReinitiateLoanRequests/${id}`
    );
}

export function filterLoanRequests(
    payload: IBranchBasedFilterData,
    maxSize: number,
    pageNum: number
) {
    payload = { ...payload };
    return http.post(
        appraisalBaseAddress +
        `LoanRequest/FilterLoanRequests?pageNum=${pageNum}&maxSize=${maxSize}`,
        payload
    );
}

export function filterRejectedLoanRequests(
    payload: IBranchBasedFilterData,
    maxSize: number,
    pageNum: number
) {
    payload = { ...payload };
    return http.post(
        appraisalBaseAddress +
        `LoanRequest/FilterRejectedRequests?pageNum=${pageNum}&maxSize=${maxSize}`,
        payload
    );
}

export default {
    retrieveById,
    retrieveAllByTin,
    updateLoanRequest,
    reinitiateLoanRequests,
    filterLoanRequests,
    filterRejectedLoanRequests,
    cretaeIndividualLoanRequest
}