import React from "react";
import { Modal, Button, Accordion, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import _ from "lodash";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { ISelectItems } from "../../../components/formSelectComponent";
import DetailItemSelectComponent from "../../../components/detailItemSelectBox";
import DetailItem from "../../../components/detailItem";
import { ActionTypes } from "../../../enums/actionTypes";
import Skeleton from "react-loading-skeleton";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import appraisalPlanService from "../../../services/hybridAppraisal/appraisalPlanService";
import appraisalValidationService from "../../../services/hybridAppraisal/appraisalValidationService";
import appraisalInstitutionService from "../../../services/hybridAppraisal/appraisalInstitutionService";
import institutionsService from "../../../services/auth/institutionsService";
import { ContextAwareToggle } from "./appraisalInstitutionDetail";
import { BillingType } from "../../../enums/billingType";
import institutionFeeConfigService from "../../../services/billing/institutionFeeConfigService";
import feeConfigService from "../../../services/billing/feeConfigService";


interface EditAppraisalInstitutionFields {
  name: string;
  planId: string;
  billingType?: string;
  billingPlan?: string;
  institutionType?: string;
  createConsent: boolean;

  // TransactionFeeFields
  flatPerRequest?: string;
  flatPerApprovedLoan?: string;
  validationData?: any;

  // SubscriptionFeeFields
  volumeTier?: string;
  subscriptionFrequency?: string;
  price?: string;
  payAsYouGoPrice?: string;
  requestCount?: string;
}

interface IValidationOption {
  validationPricingOptionId: string;
  fee: string;
}

class EditAppraisalInstitutionForm extends BaseFormComponent<EditAppraisalInstitutionFields> { }

interface IEditAppraisalInstitutionProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}
interface IEditAppraisalInstitutionState {
  ownUpdate: boolean;
  appraisalPlans: any;
  billingPlans: any;
  fetchingRequiredItems: any;
  validationItems: any;
  createConsent: boolean;
  validationOptions: IValidationOption[];
}

class EditAppraisalInstitution extends React.Component<
  IEditAppraisalInstitutionProps,
  IBaseFormState & IEditAppraisalInstitutionState
> {
  _isMounted = false;
  constructor(props: IEditAppraisalInstitutionProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      ownUpdate: false,
      appraisalPlans: [],
      billingPlans: [],
      fetchingRequiredItems: false,
      validationItems: [],
      validationOptions: [],
      createConsent: false
    };
  }

  static getDerivedStateFromProps(nextProps: IEditAppraisalInstitutionProps, prevState) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
        createConsent: prevState.createConsent
      };
    }
    if (_.isEmpty(nextProps.item)) {
      return null;
    }
    // console.log(nextProps.item.feeConfig)
    return {
      validationOptions: nextProps.item.feeConfig?.validationOptions?.map(
        (item) => ({ validationPricingOptionId: item.pricingOptionId, fee: item.fee })
      ),
      validationItems: nextProps.item.feeConfig?.validationOptions?.map(
        (item) => ({ name: item.pricingOption.name, pricingOptionId: item.pricingOptionId })
      ),
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    if (_.isEmpty(this.props.item)) {
      return
    }
    const { errors } = this.state;
    try {
      if (this._isMounted) {
        this.setState({ fetchingRequiredItems: true, ownUpdate: true });
      }
      const institution = await institutionsService.retrieveInstitutionByCode(this.props.item.code)
      const appraisalPlans = await appraisalPlanService.retrieveByInstitutionCategory(institution.data.category)
      const transactionFeePlans = await feeConfigService.retrieveTransactionFeeConfigByInstitutionCategory(institution.data.category)
      const subscriptionFeePlans = await feeConfigService.retrieveSubscriptionFeeConfigByInstitutionCategory(institution.data.category)
      this.setState({
        appraisalPlans: appraisalPlans.data,
        billingPlans: (this.props.item.billingType === BillingType[BillingType.PAYG]) ? transactionFeePlans.data.data : subscriptionFeePlans.data.data,
        fetchingRequiredItems: false,
        createConsent: this.props.item.createConsent,
        ownUpdate: true
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        errors.response = "Internal server error.";
        this.setState({ errors, ownUpdate: true });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onFormSubmit(fields: EditAppraisalInstitutionFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors: appraisalValidationService.validateEditAppraisalInstitutionForm(fields),
          ownUpdate: true,
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  submit = async (
    fields: EditAppraisalInstitutionFields,
    onReloadFieldsCB: any
  ): Promise<boolean> => {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const payload: any = {};
      const appraisalInstitution = this.props.item;
      payload.id = appraisalInstitution.id;
      payload.name = fields.name;
      payload.planId = Number(fields.planId);
      payload.billingPlan = fields.billingPlan;
      payload.institutionType = fields.institutionType;
      payload.createConsent = this.state.createConsent

      if (appraisalInstitution.billingType === BillingType[BillingType.PAYG]) {
        const feePayload: any = {};
        const payload = { ...this.props.item };
        feePayload.flatPerRequest = Number(fields.flatPerRequest);
        feePayload.flatPerApprovedLoan = Number(fields.flatPerApprovedLoan);
        feePayload.validationOptions = this.state.validationOptions;
        // console.log("feePayload: ", feePayload)
        await institutionFeeConfigService.updateTransactionFeeConfig(payload.feeConfig.institutionHybridFeeConfigurationId, feePayload);
      }
      else if (appraisalInstitution.billingType === BillingType[BillingType.Subscription]) {
        const feePayload: any = {};
        const payload = { ...this.props.item };
        feePayload.volumeTier = fields.volumeTier;
        feePayload.subscriptionFrequency = fields.subscriptionFrequency;
        feePayload.price = Number(fields.price);
        feePayload.requestCount = Number(fields.requestCount);
        feePayload.payAsYouGoPrice = Number(fields.payAsYouGoPrice);
        await institutionFeeConfigService.updateSubscriptionFeeConfig(payload.feeConfig.institutionHybridFeeConfigurationId, feePayload);
      }

      // console.log("Payload: ", payload)
      const response = await appraisalInstitutionService.updateInstitution(payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit Appraisal Institution",
          description: `Edited details for Appraisal Institution ${fields.name}`,
        });
        toast.info(`Appraisal Institution ${fields.name} Edited successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      }
      onReloadFieldsCB();
      this.setState({ submitting: false, ownUpdate: true }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      toast.error(error.response.data, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      this.setState({ submitting: false, ownUpdate: true });
    } finally {
      return true;
    }
  };

  handleValidationChange = (id, value) => {
    const validationOption = [...this.state.validationOptions];
    validationOption.every(option => {
      if (option.validationPricingOptionId == id) {
        option.fee = value;
        return false;
      }
      return true;
    });
    return validationOption;
  }

  handleUpdateCreateConsent = () => {
    this.setState({
      createConsent: !this.state.createConsent,
      ownUpdate: true,
    });
  };

  handleBillingPlanChange = (value) => {
    const billingPlans = [...this.state.billingPlans]
    const validationOptions = [];
    const validationItems = [];
    billingPlans
      .filter((item) => item.hybridAppraisalConfigurationId === value)[0]
      .validationOptions.forEach(option => {
        validationOptions.push({ validationPricingOptionId: option.pricingOptionId, fee: option.fee })
        validationItems.push({ name: option.pricingOption.name, pricingOptionId: option.pricingOptionId })
      })
    this.setState({ validationOptions, validationItems, ownUpdate: true })
  }

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      ownUpdate: true
    });
  }

  render() {
    const { item, showEditModal, toggleEditModal } = this.props;
    const {
      validated,
      submitting,
      errors,
      appraisalPlans,
      billingPlans,
      validationItems,
      validationOptions
    } = this.state;

    let appraisalPlanInputData: ISelectItems[] = appraisalPlans.map((item) => ({
      name: item.name,
      value: item.id,
    }));

    let billingPlanInputData: ISelectItems[] = billingPlans.map((item) => ({
      name: item.feeName ? item.feeName : item.volumeTier,
      value: item.hybridAppraisalConfigurationId,
    }));

    let subscriptionFrequencyInputData: ISelectItems[] = [
      { name: "Monthly", value: "Monthly" },
      { name: "Quarterly", value: "Quarterly" },
      { name: "Annually", value: "Annually" },
    ];

    let institutionTypeInputData: ISelectItems[] = [
      { name: "Local", value: "Local" },
      { name: "Foreign", value: "Foreign" },
    ];

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-user-shield fa-sm cyan-text mr-3" />
              Edit Appraisal Institution -{" "}
              <span className="font-weight-bold">{item.name}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditAppraisalInstitutionForm
          initialValues={{
            name: item.name,
            planId: item.planId?.toString(),
            billingPlan: item.billingPlan,
            flatPerRequest: item.feeConfig?.flatPerRequest,
            flatPerApprovedLoan: item.feeConfig?.flatPerApprovedLoan,
            validationData: this.state.validationOptions,
            volumeTier: item.feeConfig?.volumeTier,
            subscriptionFrequency: item.feeConfig?.subscriptionFrequency,
            price: item.feeConfig?.price,
            payAsYouGoPrice: item.feeConfig?.payAsYouGoPrice,
            requestCount: item.feeConfig?.requestCount,
            institutionType: item.institutionType,
            createConsent: item.createConsent
          }}
          FormComponent={({
            fields: {
              name,
              planId,
              billingPlan,
              flatPerRequest,
              flatPerApprovedLoan,
              validationData,
              volumeTier,
              subscriptionFrequency,
              price,
              payAsYouGoPrice,
              requestCount,
              institutionType,
              createConsent
            },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    <h6
                      className="card-title float-left"
                      style={{ marginBottom: "0px" }}
                    >
                      Appraisal Institution Details
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={3} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Institution Name"
                              value={
                                <DetailItemInputComponent
                                  id="name"
                                  type="text"
                                  name="name"
                                  placeholder=""
                                  value={name}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Institution Type"
                              value={
                                <DetailItemSelectComponent
                                  id="institutionType"
                                  name="institutionType"
                                  value={institutionType}
                                  items={institutionTypeInputData}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={6}
                              valueSize={6}
                            />
                            {item.billingType === BillingType[BillingType.PAYG] ? (
                              <DetailItem
                                label="Billing Plan"
                                value={
                                  <DetailItemSelectComponent
                                    id="billingPlan"
                                    name="billingPlan"
                                    value={billingPlan}
                                    items={billingPlanInputData}
                                    required={true}
                                    validated={validated}
                                    errors={errors}
                                    onChange={async (id, value) => {
                                      if (value !== "") {
                                        await onChange(id, value);
                                        await onChange(
                                          "flatPerRequest",
                                          billingPlans.filter(
                                            (item) => item.hybridAppraisalConfigurationId === value
                                          )[0].flatPerRequest.toString()
                                        );
                                        await onChange(
                                          "flatPerApprovedLoan",
                                          billingPlans
                                            .filter(
                                              (item) => item.hybridAppraisalConfigurationId === value
                                            )[0]
                                            .flatPerApprovedLoan.toString()
                                        );
                                        await onChange(
                                          "validationData",
                                          this.handleBillingPlanChange(value)
                                        );
                                      }
                                    }}
                                  />
                                }
                                labelSize={6}
                                valueSize={6}
                              />
                            ) : (
                              <DetailItem
                                label="Billing Plan"
                                value={
                                  <DetailItemSelectComponent
                                    id="billingPlan"
                                    name="billingPlan"
                                    value={billingPlan}
                                    items={billingPlanInputData}
                                    required={true}
                                    validated={validated}
                                    errors={errors}
                                    onChange={async (id, value) => {
                                      if (value !== "") {
                                        await onChange(id, value);
                                        await onChange(
                                          "volumeTier",
                                          billingPlans
                                            .filter(
                                              (item) => item.hybridAppraisalConfigurationId === value
                                            )[0]
                                            .volumeTier.toString()
                                        );
                                        await onChange(
                                          "subscriptionFrequency",
                                          billingPlans
                                            .filter(
                                              (item) => item.hybridAppraisalConfigurationId === value
                                            )[0]
                                            .subscriptionFrequency.toString()
                                        );
                                        await onChange(
                                          "price",
                                          billingPlans
                                            .filter(
                                              (item) => item.hybridAppraisalConfigurationId === value
                                            )[0]
                                            .price.toString()
                                        );
                                        await onChange(
                                          "payAsYouGoPrice",
                                          billingPlans
                                            .filter(
                                              (item) => item.hybridAppraisalConfigurationId === value
                                            )[0]
                                            .payAsYouGoPrice.toString()
                                        );
                                        await onChange(
                                          "requestCount",
                                          billingPlans
                                            .filter(
                                              (item) => item.hybridAppraisalConfigurationId === value
                                            )[0]
                                            .requestCount.toString()
                                        );
                                      }
                                    }}
                                  />
                                }
                                labelSize={6}
                                valueSize={6}
                              />
                            )}
                          </dl>
                        )}
                      </div>
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={3} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Plan Name"
                              value={
                                <DetailItemSelectComponent
                                  id="planId"
                                  name="planId"
                                  value={planId}
                                  items={appraisalPlanInputData}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={6}
                              valueSize={6}
                            />
                            {/* {console.log(this.state.createConsent)} */}
                            <DetailItem

                              label="Create Consent"
                              value={
                                // <label className="switch">
                                <input
                                  id="createConsent"
                                  name="createConsent"
                                  type="checkbox"
                                  onChange={this.handleUpdateCreateConsent}
                                  defaultChecked={this.state.createConsent}
                                  checked={this.state.createConsent}
                                />
                                //   <span className="slider round"></span>
                                // </label>
                              }
                              labelSize={6}
                              valueSize={6}
                            />
                          </dl>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {item.billingType === BillingType[BillingType.PAYG] ? (
                  <Accordion defaultActiveKey="1">
                    <div>
                      <Card>
                        <ContextAwareToggle
                          eventKey={`1`}
                          header={
                            <React.Fragment>
                              Fee Configuration
                            </React.Fragment>
                          }
                        ></ContextAwareToggle>
                        <Accordion.Collapse eventKey={`1`}>
                          <Card.Body>
                            <div className="row">
                              <div className="col-md-6">
                                {_.isEmpty(item.feeConfig) ? (
                                  <Skeleton count={3} width={300} />
                                ) : (
                                  <dl className="row">
                                    <DetailItem
                                      label="Flat Per Request"
                                      value={
                                        <DetailItemInputComponent
                                          id="flatPerRequest"
                                          type="number"
                                          name="flatPerRequest"
                                          placeholder=""
                                          value={flatPerRequest}
                                          required={true}
                                          validated={validated}
                                          errors={errors}
                                          onChange={onChange}
                                          disabled={true}
                                        />
                                      }
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                  </dl>
                                )}
                              </div>
                              <div className="col-md-6">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={3} width={300} />
                                ) : (
                                  <dl className="row">
                                    <DetailItem
                                      label="Flat Per Approved Loan"
                                      value={
                                        <DetailItemInputComponent
                                          id="flatPerApprovedLoan"
                                          type="number"
                                          name="flatPerApprovedLoan"
                                          placeholder=""
                                          value={flatPerApprovedLoan}
                                          required={true}
                                          validated={validated}
                                          errors={errors}
                                          onChange={onChange}
                                          disabled={true}
                                        />
                                      }
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                  </dl>
                                )}
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="validation=fees"
                                className="col-form-label s-10 font-weight-bold mb-2"
                              >
                                <b>Validation Fees</b>
                              </label>
                              <br />
                              <div className="form-row">
                                {validationOptions.map((data) => (
                                  <div className="col-md-6">
                                    <dl className="row">
                                      <DetailItem
                                        key={data.validationPricingOptionId}
                                        label={validationItems.find(option => option.pricingOptionId == data.validationPricingOptionId).name}
                                        value={
                                          <DetailItemInputComponent
                                            id={data.validationPricingOptionId}
                                            type="number"
                                            name={validationItems.find(option => option.pricingOptionId == data.validationPricingOptionId).name}
                                            placeholder=""
                                            value={data.fee}
                                            required={true}
                                            validated={validated}
                                            errors={errors}
                                            onChange={(id, value) => {
                                              onChange('validationData', this.handleValidationChange(id, value))
                                            }}
                                            disabled={true}
                                          />
                                        }
                                        labelSize={6}
                                        valueSize={6}
                                      />
                                    </dl>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </div>
                  </Accordion>
                ) : (
                  <Accordion defaultActiveKey="1">
                    <div>
                      <Card>
                        <ContextAwareToggle
                          eventKey={`1`}
                          header={
                            <React.Fragment>
                              Fee Configuration
                            </React.Fragment>
                          }
                        ></ContextAwareToggle>
                        <Accordion.Collapse eventKey={`1`}>
                          <Card.Body>
                            <div className="row">
                              <div className="col-md-6">
                                {_.isEmpty(item.feeConfig) ? (
                                  <Skeleton count={3} width={300} />
                                ) : (
                                  <dl className="row">
                                    <DetailItem
                                      label="Subscription Frequency"
                                      value={
                                        <DetailItemSelectComponent
                                          id="subscriptionFrequency"
                                          name="subscriptionFrequency"
                                          items={subscriptionFrequencyInputData}
                                          value={subscriptionFrequency}
                                          required={true}
                                          validated={validated}
                                          errors={errors}
                                          onChange={onChange}
                                          disabled={true}
                                        />
                                      }
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                    <DetailItem
                                      label="Price"
                                      value={
                                        <DetailItemInputComponent
                                          id="price"
                                          type="number"
                                          name="price"
                                          placeholder=""
                                          value={price}
                                          required={true}
                                          validated={validated}
                                          errors={errors}
                                          onChange={onChange}
                                          disabled={true}
                                        />
                                      }
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                  </dl>
                                )}
                              </div>
                              <div className="col-md-6">
                                {_.isEmpty(item.feeConfig) ? (
                                  <Skeleton count={3} width={300} />
                                ) : (
                                  <dl className="row">
                                    <DetailItem
                                      label="Pay As You Go Price"
                                      value={
                                        <DetailItemInputComponent
                                          id="payAsYouGoPrice"
                                          type="number"
                                          name="payAsYouGoPrice"
                                          placeholder=""
                                          value={payAsYouGoPrice}
                                          required={true}
                                          validated={validated}
                                          errors={errors}
                                          onChange={onChange}
                                          disabled={true}
                                        />
                                      }
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                    <DetailItem
                                      label="Request Limit"
                                      value={
                                        <DetailItemInputComponent
                                          id="requestCount"
                                          type="number"
                                          name="requestCount"
                                          placeholder=""
                                          value={requestCount}
                                          required={true}
                                          validated={validated}
                                          errors={errors}
                                          onChange={onChange}
                                          disabled={true}
                                        />
                                      }
                                      labelSize={6}
                                      valueSize={6}
                                    />
                                  </dl>
                                )}
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </div>
                  </Accordion>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(e, ActionTypes.UPDATE, "Appraisal Institution", () => {
                      this.onFormSubmit({
                        name,
                        planId,
                        billingPlan,
                        flatPerRequest,
                        flatPerApprovedLoan,
                        validationData,
                        volumeTier,
                        subscriptionFrequency,
                        price,
                        payAsYouGoPrice,
                        requestCount,
                        institutionType,
                        createConsent
                      },
                        onReloadFields);
                    });
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></EditAppraisalInstitutionForm>
      </Modal>
    );
  }
}

export default EditAppraisalInstitution;
