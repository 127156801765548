import http from "../httpService";

const recovaCreditAssessmentBaseAddress = process.env.REACT_APP_CREDITASSESSMENT_BASEADDRESS;

export function createScoreCard(payload) {
  return http.post(
    recovaCreditAssessmentBaseAddress + `ScoreCard`,
    payload
  );
}

export function retrieveAllScoreCards() {
  return http.get(
    recovaCreditAssessmentBaseAddress + `ScoreCard`
  );
}

export function retrieveScoreCardById(id) {
  return http.get(
    recovaCreditAssessmentBaseAddress + `ScoreCard/${id}`
  );
}

export function retrieveScoreCardByInstitutionCode(institutionCode) {
  return http.get(
    recovaCreditAssessmentBaseAddress + `ScoreCard/institutions/${institutionCode}`
  );
}

export function editScoreCard(id, payload) {
  return http.put(
    recovaCreditAssessmentBaseAddress + `ScoreCard/${id}`,
    payload
  );
}

export function addScoreIndicatorsToScoreCard(id, indicators) {
  return http.patch(
    recovaCreditAssessmentBaseAddress + `ScoreCard/${id}/scoreIndicators/add`,
    indicators
  );
}

export function removeScoreIndicatorsFromScoreCard(id, indicators) {
  return http.patch(
    recovaCreditAssessmentBaseAddress + `ScoreCard/${id}/scoreIndicators/remove`,
    indicators
  );
}


export default {
  createScoreCard,
  retrieveAllScoreCards,
  retrieveScoreCardById,
  retrieveScoreCardByInstitutionCode,
  editScoreCard,
  addScoreIndicatorsToScoreCard,
  removeScoreIndicatorsFromScoreCard
};
