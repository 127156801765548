import React, { Component } from "react";
import swal from "sweetalert";
import financialGroupService from "../../../services/approval/financialGroupService";
import AddNewGroupModal from "./addNewGroupModal";
import { toast } from "react-toastify";

export interface IRuleTabsProps {
  groups: any;
  handleGroupDelete: any;
  item: any;
  events: any;
  viewing: boolean;
}

export interface IRuleTabsState {
  groups: any;
  currentGroup: any;
  item: any;
  showAddNewItemModal: boolean;
}
export class RuleTabs extends Component<IRuleTabsProps, IRuleTabsState> {
  constructor(props: IRuleTabsProps) {
    super(props);
    var groups = this.props.groups;
    groups.sort((a, b) => a.lowerLimit - b.lowerLimit);
    var currentGroup = groups.length > 0 ? groups[groups.length - 1] : null;
    this.state = {
      groups,
      currentGroup,
      item: this.props.item,
      showAddNewItemModal: false,
    };
    //console.log(this.state.groups);
    //this.setState({currentGroup, groups, item:this.props.item,})
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.groups !== prevState.groups) {
      var groups = nextProps.groups;
      groups.sort((a, b) => a.lowerLimit - b.lowerLimit);
      var currentGroup = groups.length > 0 ? groups[groups.length - 1] : null;
      return { groups: groups, currentGroup: currentGroup };
    } else return null;
  }

  handleOnGroupAdd = async (data) => {
    try {
      var last = this.state.groups.length + 1;
      const lowerLimit = parseFloat(data.minimumAmount);
      const upperLimit = parseFloat(data.maximumAmount);
      if (lowerLimit < 0 || upperLimit < 0) {
        throw "Invalid amount range! Amount should not be negative";
      }
      if (upperLimit <= lowerLimit) {
        throw "Invalid amount range!";
      }
      let newGroup: any = {
        id: last,
        upperLimit: upperLimit,
        lowerLimit: lowerLimit,
      };
      //Check for overlaps
      var isOverlapping = false;
      var isNotOverlappingRange = (range, ranges) => {
        var overlapped = false;
        for (var i = 0; i < ranges.length; i++) {
          var curr = ranges[i];
          if (
            range.lowerLimit > curr.lowerLimit &&
            range.lowerLimit <= curr.upperLimit
          ) {
            overlapped = true;
            break;
          }
          if (
            range.upperLimit > curr.lowerLimit &&
            range.upperLimit <= curr.upperLimit
          ) {
            overlapped = true;
            break;
          }
          if (
            range.upperLimit > curr.upperLimit &&
            range.lowerLimit <= curr.lowerLimit
          ) {
            overlapped = true;
            break;
          }
        }
        return !overlapped;
      };
      isOverlapping = !isNotOverlappingRange(
        newGroup,
        this.state.item.financialGroups
      );
      if (isOverlapping) throw "Range must not overlap existing ranges";

      newGroup["institutionId"] = this.state.item.institutionId;
      newGroup["approvableId"] = this.state.item.id;
      newGroup = (await financialGroupService.createFinancialGroup(newGroup))
        .data;
      //console.log(newGroup);
      this.state.groups.splice(last - 1, 0, newGroup);
      this.props.events.handleGroupAdd(newGroup);
      this.setState({
        groups: this.state.groups,
        currentGroup: newGroup,
      });
    } catch (error) {
      toast.warn(error, {
        autoClose: 6000,
        type: toast.TYPE.WARNING,
        position: "top-center",
        hideProgressBar: false,
      });
    }
  };
  handleGroupChange(group) {
    if (!this.props.viewing) {
      this.props.events.handleSetGroup(group);
      var groups = this.state.groups;
      groups.sort((a, b) => a.lowerLimit - b.lowerLimit);
      this.setState({ currentGroup: group, groups });
    }
    //console.log("Set current group to"+group.id)
  }

  toggleAddGroupModal = (data: any, performSave: boolean) => {
    this.setState({ showAddNewItemModal: !this.state.showAddNewItemModal });
    if (performSave) {
      this.handleOnGroupAdd(data);
    }
  };

  render() {
    var AddGroupButtons = !this.props.viewing ? (
      <div className="col-sm-3">
        <button
          className="btn btn-outline-primary btn-sm float-right px-2"
          title="Delete Current Financial Group"
          onClick={() => this.props.handleGroupDelete(this.state.currentGroup)}
        >
          <i className="fa fa-trash"></i> Delete
        </button>
        <button
          className="btn btn-outline-primary btn-sm float-right px-2"
          title="Add New Financial Group"
          onClick={() => this.toggleAddGroupModal({}, false)} //unsure
        >
          <i className="fa fa-plus"></i> Add
        </button>
      </div>
    ) : (
      ""
    );
    var mainItemGridSpec = !this.props.viewing ? "col-sm-9" : "col-sm-12";
    var groups = this.state.groups;
    if (this.state.currentGroup === null && this.state.groups.length > 0) {
      this.setState({ currentGroup: groups[groups.length - 1] });
    }
    return (
      <React.Fragment>
        <div className="row">
          <div className={mainItemGridSpec}>
            <ul className="nav nav-tabs md-tabs" id="myTabMD" role="tablist">
              {groups.map((x, idx) => {
                var active =
                  x.id === this.state.currentGroup.id ? " active" : "";
                return (
                  <li className="nav-item" key={`${idx}_${x}`}>
                    <a
                      className={`nav-link${active}`}
                      data-toggle="tab"
                      href={"#fingrp_" + idx}
                      role="tab"
                      aria-controls="home-md"
                      aria-selected="true"
                      onClick={() => this.handleGroupChange(x)}
                    >
                      {idx + 1}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          {AddGroupButtons}
        </div>

        <AddNewGroupModal
          showAddNewGroupModal={this.state.showAddNewItemModal}
          toggleAddNewGroupModal={this.toggleAddGroupModal}
        />
      </React.Fragment>
    );
  }
}
