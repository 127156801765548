import http from "../httpService";
import { IFilterData } from "../approval/approvalItemService";
import { getCurrentUserSession } from "../auth/authService";
import { decodeToken } from "react-jwt";

const appraisalBaseAddress = process.env.REACT_APP_HYBRIDAPPRAISAL_BASEADDRESS;

export function mapLoanProduct(payload) {
  return http.post(
    appraisalBaseAddress + `LoanProductMap/MapLoanProduct`,
    payload
  );
}

export function mapMultipleLoanProduct(payload) {
  return http.post(
    appraisalBaseAddress + `LoanProductMap/MapMultipleLoanProduct`,
    payload
  );
}

export function updateLoanProductMap(payload) {
  return http.put(
    appraisalBaseAddress + `LoanProductMap/EditLoanProductMap`,
    payload
  );
}

export function retrieveById(id) {
  return http.get(
    appraisalBaseAddress + `LoanProductMap/RetrieveById/${id}`
  );
}

export function retrieveByLoanProduct(institutionCode: string, loanProductId: string) {
  return http.get(
    appraisalBaseAddress + `LoanProductMap/RetrieveByLoanProduct/${institutionCode}/${loanProductId}`
  );
}

export function retrieveAll() {
  return http.get(
    appraisalBaseAddress + `LoanProductMap/RetrieveAll`
  );
}

export function retrieveAllByInstitution(institutionCode) {
  return http.get(
    appraisalBaseAddress + `LoanProductMap/RetrieveByInstitution/${institutionCode}`
  );
}

export function filterLoanProductMap(
  payload: IFilterData,
  maxSize: number,
  pageNum: number
) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  payload = { ...payload };
  payload.institutionCode = {
    item1: "=",
    item2: decodedToken?.["InstitutionCode"]?.toString() || "break",
  };
  return http.post(
    appraisalBaseAddress +
    `LoanProductMap/FilterLoanProductMap?pageNum=${pageNum}&maxSize=${maxSize}`,
    payload
  );
}

export default {
  mapLoanProduct,
  mapMultipleLoanProduct,
  updateLoanProductMap,
  retrieveById,
  retrieveByLoanProduct,
  retrieveAll,
  retrieveAllByInstitution,
  filterLoanProductMap,
};
