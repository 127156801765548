import React from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
// import SelectInputComponent, {
//   ISelectItems,
// } from "../../../../components/formSelectComponent";
// import workflowService from "../../../../services/creditAssessment/workflowService";
import scoringIndicatorService from "../../../services/creditAssessment/scoringIndicatorService";
import { ISelectItems } from "../../../components/detailItemSelectBox";
import institutionsService from "../../../services/auth/institutionsService";
import SelectInputComponent from "../../../components/formSelectComponent";
import swal from "sweetalert";
// import { LoanType } from "../../../../enums/loanType";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

interface IAssignScoreIndicatorProps { }
interface IAssignScoreIndicatorState {
  scoreIndicatorIds: any;
  scoreIndicators: any;
  fetchingRequiredItems: boolean;

  institution: any;
  category: string;
  institutions: any;
  fetchingInstitutions: boolean;
}

class AssignScoreIndicator extends React.Component<
  IAssignScoreIndicatorProps,
  IBaseFormState & IAssignScoreIndicatorState
> {
  constructor(props: IAssignScoreIndicatorProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      fetchingRequiredItems: false,
      scoreIndicators: [],
      scoreIndicatorIds: [],
      institution: {},
      institutions: [],
      category: "",
      fetchingInstitutions: false,
    };
  }

  async componentWillMount() {
    const { errors } = this.state;
    // const decodedToken = decodeToken(getCurrentUserSession().token || "");
    try {
      this.setState({ fetchingRequiredItems: true });

      const scoreIndicators = await scoringIndicatorService.retrieveAllScoreIndicators();

      this.setState({
        scoreIndicators: scoreIndicators.data,
        fetchingRequiredItems: false,
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (ex.response && ex.response.status === 404) {
        // console.log(ex.response);
        errors.response = "Internal server error.";
        // console.log(errors);
        this.setState({ errors });
      }
    }
  }

  onHandleSubmit(e): boolean {
    e.preventDefault();
    try {
      swal({
        title: "Confirm!",
        text: `Are you sure you want to proceed to Assign these indicators to this Institution?`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode: true,
      }).then(async (resp) => {
        if (resp) {
          // this.setState({ updatingStatus: false });
          this.setState({ submitting: false });
        } else {
          //push to assign
          return this.submit();
        }
      });
      this.setState({ validated: true });
      return false;
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // return false;
      }
      return false;
    }
  }

  async submit(): Promise<boolean> {
    try {
      // console.log("starting submission...");
      this.setState({ submitting: true });

      // const decodedToken = decodeToken(getCurrentUserSession().token || "");

      const payload: any = {};
      payload.scoreIndicatorIds = this.state.scoreIndicatorIds;

      const response = await scoringIndicatorService.addScoreIndicatorsToInstitution(
        this.state.institution.code,
        payload
      );
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Assign Score Indicators",
          description: `Assigned new Score Indicator`,
        });
        toast.info(`Score Indicators assigned successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false });
      this.resetFields();
    } catch (error) {
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      // console.log("error: ", error.response.data);
      return false;
    } finally {
      this.setState({ submitting: false });
      return true;
    }
  }

  handleOnSelectInstitution = (id, value) => {
    try {
      const institution = this.state.institutions.filter(
        (x) => x.id === parseInt(value)
      )[0];
      this.setState({ institution: institution });
    } catch (error) {
      toast.info("Something went wrong.", {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      toast.info(error.response.data.errors[0], {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
    }
  };

  handleOnSelectCategory = async (id, value) => {
    try {
      this.setState({
        category: value,
        fetchingInstitutions: true,
        institution: {},
      });
      let inst = await institutionsService.retrieveAllByCategory(value);
      this.setState({ institutions: inst.data, fetchingInstitutions: false });
    } catch (error) {
      toast.info("Something went wrong.", {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      toast.info(error.response.data.errors[0], {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
    }
  };

  handleOnChangeScoreIndicator = (item) => {
    // console.log("clicked loan origination channel: ", item);
    const scoreIndicatorIds = [...this.state.scoreIndicatorIds];

    if (scoreIndicatorIds.indexOf(item) !== -1) {
      scoreIndicatorIds.splice(scoreIndicatorIds.indexOf(item), 1);
    } else {
      scoreIndicatorIds.push(item);
    }

    this.setState({ scoreIndicatorIds });
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      // scoreIndicatorIds: []
    });
  }
  render() {
    const {
      errors,
      validated,
      scoreIndicatorIds,
      scoreIndicators,
      institutions,
      institution,
      submitting,
      fetchingInstitutions,
      category
    } = this.state;

    let institutionsInputData: ISelectItems[] = institutions.map((item) => ({
      name: item.name,
      value: item.id,
    }));
    // order by institution name
    institutionsInputData.sort((a, b) => a.name.localeCompare(b.name))
    let categoryInputData: ISelectItems[] = [
      "CommercialBank",
      "BankOneOFI",
      "OFI",
    ].map((item) => ({
      name: item,
      value: item,
    }));

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Assign Score Indicators</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <form action="#">
                  <div className="card no-b">
                    <div className="card-body">
                      <div className="form-row">
                        <div className="col-md-12">
                          <div className="form-row">
                            <SelectInputComponent
                              id="category"
                              name="category"
                              divClass={6}
                              value={category}
                              items={categoryInputData}
                              required={false}
                              validated={false}
                              errors={[]}
                              onChange={this.handleOnSelectCategory}
                            />
                            {category == "" ? (
                              ""
                            ) : (
                              <SelectInputComponent
                                id="institution"
                                name="institution"
                                divClass={6}
                                value={institution.id}
                                items={institutionsInputData}
                                required={false}
                                validated={false}
                                errors={[]}
                                labelIconClass={fetchingInstitutions === true
                                  ? "fas fa-spinner fa-spin orange-text mr-2"
                                  : ""
                                }
                                onChange={this.handleOnSelectInstitution}
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      <hr />
                      <h5 className="card-title">Score Indicators</h5>
                      {_.isEmpty(institution) ? (
                        ""
                      ) : (
                        <div className="form-row">
                          <div className="col-md-12">
                            <div className="form-row">
                              <div className="col-md-6">
                                <label
                                  htmlFor="scoreIndicatorIds"
                                  className="control-label textbox-label"
                                >
                                  Score Indicators
                                </label>
                                <div
                                  className="text-nowrap block-example border"
                                  style={{
                                    width: "100%",
                                    maxHeight: "200px",
                                    overflowY: "scroll",
                                  }}
                                >
                                  <React.Fragment>
                                    {scoreIndicators.length ===
                                      0 ? (
                                      <div
                                        style={{
                                          backgroundColor: "#F3F3F3",
                                        }}
                                      >
                                        <p className="text-center">
                                          no score
                                          indicators
                                        </p>
                                      </div>
                                    ) : (
                                      <React.Fragment>
                                        {scoreIndicators.map(
                                          (item) => (
                                            <div
                                              className="custom-control custom-checkbox"
                                              key={scoreIndicators.indexOf(
                                                item
                                              )}
                                            >
                                              <input
                                                id={item.id}
                                                name={item.id}
                                                className="custom-control-input"
                                                type="checkbox"
                                                onChange={() =>
                                                  this.handleOnChangeScoreIndicator(
                                                    item.id
                                                  )
                                                }
                                                checked={
                                                  _.isEmpty(
                                                    this.state.scoreIndicatorIds.filter(
                                                      (x) => x === item.id
                                                    )
                                                  )
                                                    ? false
                                                    : true
                                                }
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor={item.id}
                                              >
                                                {item.indicatorName}
                                              </label>
                                            </div>
                                          )
                                        )}
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <hr />
                    <div className="card-body">
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        disabled={this.state.submitting || scoreIndicatorIds.length == 0}
                        onClick={(e) => { this.onHandleSubmit(e) }}
                      >
                        {this.state.submitting === false ? (
                          <React.Fragment>
                            <i className="fas fa-lg fa-save mr-3" /> Assign
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                            Please wait...
                          </React.Fragment>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AssignScoreIndicator;
