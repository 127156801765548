import { IFilterData } from "../../components/BaseListComponent";
import http from "../httpService";

const recovaCreditAssessmentBaseAddress =
  process.env.REACT_APP_CREDITASSESSMENT_BASEADDRESS;

// export function saveLoanRequest(payload: any) {
//   return http.post(recovaCreditAssessmentBaseAddress + `LoanRequest`, payload);
// }

export function retrieveAll() {
  return http.get(recovaCreditAssessmentBaseAddress + `LoanRequest`);
}

export function retrieveById(id: number) {
  return http.get(recovaCreditAssessmentBaseAddress + `LoanRequest/${id}`);
}

export function retrieveByInstitution(institutionCode: number) {
  return http.get(
    recovaCreditAssessmentBaseAddress +
      `LoanRequest/getbyinstitution/${institutionCode}`
  );
}

export function retrieveAllBlackboxRequests() {
  return http.get(recovaCreditAssessmentBaseAddress + `LoanRequest/blackbox/loanrequests`);
}

export function retrieveAllBlackboxRequestsCount() {
  return http.get(recovaCreditAssessmentBaseAddress + `LoanRequest/blackbox/loanrequests/count`);
}

export function retrieveAllBlackboxRequestsVolume() {
  return http.get(recovaCreditAssessmentBaseAddress + `LoanRequest/blackbox/loanrequests/volume`);
}

export function retrieveInstitutionBlackboxRequestsCount(institutionCode: string) {
  return http.get(recovaCreditAssessmentBaseAddress + `LoanRequest/blackbox/loanrequests/institutions/${institutionCode}/count`);
}

export function retrieveInstitutionBlackboxRequestsAmount(institutionCode: string) {
  return http.get(recovaCreditAssessmentBaseAddress + `LoanRequest/blackbox/loanrequests/institutions/${institutionCode}/volume`);
}

export function filterPagedLoanRequests(
  data,
  pageSize: number,
  pageNumber: number
) {
  return http.post(
    recovaCreditAssessmentBaseAddress +
      `LoanRequest/FilterLoanRequests?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    data
  );
}

export default {
  retrieveAll,
  retrieveAllBlackboxRequests,
  retrieveById,
  retrieveByInstitution,
  filterPagedLoanRequests,
  retrieveAllBlackboxRequestsCount,
  retrieveAllBlackboxRequestsVolume,
  retrieveInstitutionBlackboxRequestsCount,
  retrieveInstitutionBlackboxRequestsAmount
};
