import React, { Component, Fragment } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import BaseFormComponent, { IBaseFormState } from "../../components/BaseFormComponent";
import FormInputComponent from "../../components/formInputComponent";
import SelectInputComponent, { ISelectItems } from "../../components/formSelectComponent";
import { EconomicSector } from "../../enums/economicSector";
import { LoanComputationMode } from "../../enums/loanComputationMode";
import { ProductType } from "../../enums/productType";
import textFormatService, { generateGUID } from "../../services/utility/textFormatService";
import { ActionTypes } from "../../enums/actionTypes";
import { getCurrentUserSession } from "../../services/auth/authService";
import appraisalActionService from "../../services/hybridAppraisal/appraisalActionService";
import activityTrailService from "../../services/auditTrail/activityTrailService";
import FormTextAreaComponent from "../../components/formTextAreaComponent";
import { Accordion, Button, Card, Modal } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import groupLoanRequestMembersService from "../../services/hybridAppraisal/groupLoanRequestMembersService";
import settlementWithdrawalService from "../../services/collectionSweep/settlementWithdrawalService";


interface updateLoanAmountFields {
    amount: string;
}

class UpdateLoanAmountForm extends BaseFormComponent<updateLoanAmountFields> { }

interface updateLoanAmountProps {
    showAmountModal: boolean;
    toggleAmountModal: any;
    institutionCode: string;
}
interface updateLoanAmountState {
    showRepaymentSchedule: boolean;
    showDocPatchModal: boolean;
    applicableRules: any;
    appraisalActions: any;
    updateComponent: boolean;
    documentsToUpload: any;
    documentsToDelete: number[];
    updateAvailable: boolean;
    item: any;
    ownUpdate: boolean;
    showValidateAmountScreen: boolean;
    newAmount: number;
}

class SettlementWithdrawalAmount extends Component<
    updateLoanAmountProps,
    IBaseFormState & updateLoanAmountState
> {
    constructor(props: updateLoanAmountProps) {
        super(props);
        this.state = {
            item: {},
            validated: false,
            submitting: false,
            errors: {},
            showRepaymentSchedule: false,
            showDocPatchModal: false,
            applicableRules: [],
            appraisalActions: [],
            documentsToUpload: [],
            documentsToDelete: [],
            updateAvailable: false,
            updateComponent: false,
            ownUpdate: false,
            showValidateAmountScreen: false,
            newAmount: 0
        };
    }

    // static getDerivedStateFromProps(
    //     nextProps: updateLoanAmountProps,
    //     prevState
    // ) {
    //     if (prevState.ownUpdate) {
    //         return {
    //             ownUpdate: false,
    //         };
    //     }
    //     if (nextProps.item.jsonData && prevState.item?.CustomerID != JSON.parse(JSON.parse(nextProps.item.jsonData)).CustomerID) {
    //         // console.log("Updating state afresh")
    //         // console.log(JSON.parse(JSON.parse(nextProps.item)))
    //         return { item: JSON.parse(JSON.parse(nextProps.item.jsonData)) };
    //     }
    //     return null;
    // }


    handleWithdrawal = async (institutionCode, amount) => {
        this.setState({ submitting: true, ownUpdate: true });
        try {
            if (institutionCode === '000') {
                return false;
            }
            else {
                var response = await settlementWithdrawalService.initiateWithdrawal(institutionCode, amount);
                if (response.status >= 200 && response.status <= 300) {
                    toast.info(`Withdrawal initiated successfully!`, {
                        autoClose: 6000,
                        type: toast.TYPE.DEFAULT,
                        hideProgressBar: false,
                    });
                } else {
                    toast.error(`Withdrawal initiation failed!`, {
                        autoClose: 6000,
                        type: toast.TYPE.DEFAULT,
                        hideProgressBar: false,
                    });
                }
                this.setState({ submitting: false, ownUpdate: true });
            }

        } catch (ex) {
            toast.error(ex.response.data.detail, {
                autoClose: 6000,
                type: toast.TYPE.DEFAULT,
                hideProgressBar: false,
            });
            this.setState({ submitting: false });
        }
    }


    setNewAmount = (id, value) => {
        this.setState({ newAmount: value });
    }

    resetFields() {
        this.setState({ validated: false, submitting: false, errors: [], ownUpdate: true });
    }

    render() {
        const { showAmountModal, toggleAmountModal, institutionCode } = this.props;
        const { errors, validated, item } = this.state;

        return (
            <Modal
                size="sm"
                backdrop="static"
                show={showAmountModal}
                onHide={toggleAmountModal}
            >
                <Modal.Header>
                    <Modal.Title>
                        <h5>
                            <i className="fas fa-edit fa-sm cyan-text mr-3" />
                            Settlement Withdrawal
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <UpdateLoanAmountForm
                    initialValues={{
                        amount: ""
                    }}
                    FormComponent={({
                        fields: {
                            amount
                        },
                        onChange,
                        onReloadFields,
                        onHandleSubmit,
                    }) => (
                        <><Modal.Body>
                            <div className="form-row">
                                {/* <div className="card-header clear-fix">
                                    <h6
                                        className="card-title float-left"
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <b>Input Amount</b>
                                    </h6>
                                </div> */}
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <div className="form-row">
                                            <FormInputComponent
                                                label="Input Amount"
                                                id="amount"
                                                name="amount"
                                                type="text"
                                                placeholder=""
                                                divClass={12}
                                                value={amount}
                                                required={true}
                                                validated={validated}
                                                errors={errors}
                                                onChange={onChange} />

                                        </div>
                                        {/* <div className="form-row mt-2"> */}
                                        <Button
                                            className="mt-2"
                                            size="sm"
                                            variant="outline-primary"
                                            onClick={(e) => {
                                                this.handleWithdrawal(
                                                    institutionCode,
                                                    amount
                                                );
                                            }}
                                            disabled={this.state.submitting}
                                        >
                                            <i className="fas fa-edit mr-3" />
                                            {this.state.submitting ? ("Withrawal in progress.....") :
                                                // settlementWalletItem?.availableBalance === 0
                                                //     || settlementWalletItem?.availableBalance === ""
                                                //     || settlementWalletItem?.availableBalance === null
                                                //     || settlementWalletItem?.availableBalance === undefined ?
                                                // ("What do you want to withdraw") :
                                                ("Withdraw")}
                                        </Button>
                                        {/* </div> */}
                                    </div>
                                </div>



                            </div>
                        </Modal.Body><Modal.Footer>
                                <Button
                                    size="sm"
                                    variant="outline-danger"
                                    onClick={() => toggleAmountModal(false, {}, {})}
                                    disabled={this.state.submitting}
                                >
                                    <i className="fas fa-times mr-3" />
                                    Close
                                </Button>
                            </Modal.Footer></>
                    )}
                ></UpdateLoanAmountForm>
            </Modal>
        );
    }
}

export default SettlementWithdrawalAmount;

