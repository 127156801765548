import { CollateralValuation } from "../../pages/hybridAppraisal/loanAppraisal/appraisalDetailPages/wizardComponents/editDetailedDataEntry/FAM/collateralValuation";
import { CurrencyRisk } from "../../pages/hybridAppraisal/loanAppraisal/appraisalDetailPages/wizardComponents/editDetailedDataEntry/FAM/summaryInitiation";
import http from "../httpService";

const appraisalBaseAddress = process.env.REACT_APP_HYBRIDAPPRAISAL_BASEADDRESS;

type Timeline = {
  startMonth: string;
  startYear: string;
  endMonth: string;
  endYear: string;
};

type UploadPayload = {
  institutionCode: string;
  loanRequestId: number;
  famInput: string;
};

// upload
// upload
export function uploadCogsRecords(payload: UploadPayload) {
  return http.post(appraisalBaseAddress + `Fam/UploadCogsRecords`, payload);
}
export function uploadIncomeStatementRecords(payload: UploadPayload) {
  return http.post(
    appraisalBaseAddress + `Fam/UploadIncomeStatementRecords`,
    payload
  );
}
export function uploadBalanceSheetRecords(payload: UploadPayload) {
  return http.post(
    appraisalBaseAddress + `Fam/UploadBalanceSheetRecords`,
    payload
  );
}
export function uploadCashFlowRecords(payload: UploadPayload) {
  return http.post(appraisalBaseAddress + `Fam/UploadCashFlowRecords`, payload);
}
export function uploadCVTRecords(payload: UploadPayload) {
  return http.post(appraisalBaseAddress + `Fam/UploadCVTRecords`, payload);
}

//submit
//submit
export function submitFAMSummaryRecords(payload: {
  facilitySummary;
  businessSummary;
  others;
  currencyRisk: CurrencyRisk;
  institutionCode;
  loanRequestId;
  famInput;
}) {
  return http.post(
    appraisalBaseAddress + `Fam/SubmitFamSummaryRecords`,
    payload
  );
}

export function submitCollateralSummaryRecords(
  payload: {
    collateralType: string;
    isLandOrEquity: string;
    weight: number;
  }[],
  InstitutionCode,
  LoanRequestId
) {
  return http.post(
    appraisalBaseAddress +
      `Fam/SubmitCollateralSummaryRecords/${InstitutionCode}/${LoanRequestId}`,
    payload
  );
}

export function submitCVTRecords(
  payload: {
    cvtData: CollateralValuation[];
    // loanAmount: number;
    // assessedBy: string;
    // equity: number;
    // propertyOwner: string;
    // verifiedBy: string;
    dateCreated: string;
    dateUpdated: string;
  },
  InstitutionCode,
  LoanRequestId
) {
  return http.post(
    appraisalBaseAddress +
      `Fam/SubmitCVTRecords/${InstitutionCode}/${LoanRequestId}`,
    payload
  );
}

//validate
//validate
export function validateCogsRecords(payload: UploadPayload) {
  return http.post(appraisalBaseAddress + `Fam/ValidateCogsRecords`, payload);
}
export function validateIncomeStatementRecords(payload: UploadPayload) {
  return http.post(
    appraisalBaseAddress + `Fam/ValidateIncomeStatementRecords`,
    payload
  );
}
export function validateBalanceSheetRecords(payload: UploadPayload) {
  return http.post(
    appraisalBaseAddress + `Fam/ValidateBalanceSheetRecords`,
    payload
  );
}
export function validateAndUploadPreviousBalanceSheetRecords(
  payload: UploadPayload
) {
  return http.post(
    appraisalBaseAddress + `Fam/ValidateAndUploadFAMSummaryBalanceSheetRecords`,
    payload
  );
}
export function validateCashFlowRecords(payload: UploadPayload) {
  return http.post(
    appraisalBaseAddress + `Fam/ValidateCashFlowRecords`,
    payload
  );
}
export function validateCVTRecords(payload: UploadPayload) {
  return http.post(appraisalBaseAddress + `Fam/ValidateCVTRecords`, payload);
}
export function validateCollateralSummaryRecords(payload: UploadPayload) {
  return http.post(
    appraisalBaseAddress + `Fam/ValidateCollateralSummaryRecords`,
    payload
  );
}
export function validateFAMSummaryRecords(payload: UploadPayload) {
  return http.post(
    appraisalBaseAddress + `Fam/ValidateFAMSummaryRecords`,
    payload
  );
}

//retrieve
//retrieve
export function retrieveById(id) {
  return http.get(appraisalBaseAddress + `Fam/RetrieveById/${id}`);
}
export function retrieveCogsRecordsOutput({
  loanRequestId,
}: {
  loanRequestId: string;
}) {
  return http.get(
    appraisalBaseAddress + `Fam/RetrieveCogsRecordsOutput/${loanRequestId}`
  );
}
export function retrieveBalanceSheetOutput({
  loanRequestId,
}: {
  loanRequestId: string;
}) {
  return http.get(
    appraisalBaseAddress + `Fam/RetrieveBalanceSheetOutput/${loanRequestId}`
  );
}
export function retrieveCashFlowOutput({
  loanRequestId,
}: {
  loanRequestId: string;
}) {
  return http.get(
    appraisalBaseAddress + `Fam/RetrieveCashFlowOutput/${loanRequestId}`
  );
}
export function retrieveProfitLossOutput({
  loanRequestId,
}: {
  loanRequestId: string;
}) {
  return http.get(
    appraisalBaseAddress + `Fam/RetrieveProfitLossOutput/${loanRequestId}`
  );
}
export function retrieveCVTOutput({
  loanRequestId,
}: {
  loanRequestId: string;
}) {
  return http.get(
    appraisalBaseAddress + `Fam/RetrieveCVTOutput/${loanRequestId}`
  );
}
export function retrieveFinancialRatioOutput({
  loanRequestId,
}: {
  loanRequestId: string;
}) {
  return http.get(
    appraisalBaseAddress + `Fam/RetrieveFinancialRatioOutput/${loanRequestId}`
  );
}
export function retrieveFAMSummaryOutput({
  loanRequestId,
}: {
  loanRequestId: string;
}) {
  return http.get(
    appraisalBaseAddress + `Fam/RetrieveFAMSummaryOutput/${loanRequestId}`
  );
}

//check uploaded
//check uploaded
export function checkUploaded({ loanRequestId }: { loanRequestId: string }) {
  return http.get(appraisalBaseAddress + `Fam/CheckUploaded/${loanRequestId}`);
}

//preview
//preview
export function previewCogsRecords({
  loanRequestId,
}: {
  loanRequestId: string;
}) {
  return http.get(
    appraisalBaseAddress + `Fam/PreviewCogsRecords/${loanRequestId}`
  );
}
export function previewBalanceSheetRecords({
  loanRequestId,
}: {
  loanRequestId: string;
}) {
  return http.get(
    appraisalBaseAddress + `Fam/PreviewBalanceSheetRecords/${loanRequestId}`
  );
}
export function previewCashFlowRecords({
  loanRequestId,
}: {
  loanRequestId: string;
}) {
  return http.get(
    appraisalBaseAddress + `Fam/PreviewCashFlowRecords/${loanRequestId}`
  );
}
export function previewIncomeStatementRecords({
  loanRequestId,
}: {
  loanRequestId: string;
}) {
  return http.get(
    appraisalBaseAddress + `Fam/PreviewIncomeStatement/${loanRequestId}`
  );
}
export function previewCVTRecords({
  loanRequestId,
}: {
  loanRequestId: string;
}) {
  return http.get(
    appraisalBaseAddress + `Fam/PreviewCVTRecords/${loanRequestId}`
  );
}
export function previewCollateralSummaryRecords({
  loanRequestId,
}: {
  loanRequestId: string;
}) {
  return http.get(
    appraisalBaseAddress +
      `Fam/PreviewCollateralSummaryRecords/${loanRequestId}`
  );
}
export function previewFAMSummaryRecords({
  loanRequestId,
}: {
  loanRequestId: string;
}) {
  return http.get(
    appraisalBaseAddress + `Fam/PreviewFAMSummaryRecords/${loanRequestId}`
  );
}

//download
//download
export function downloadCogsTemplate(payload: Timeline) {
  return http.get(
    appraisalBaseAddress +
      `Fam/DownloadCOGSInputTemplate/${payload.startMonth}/${payload.startYear}/${payload.endMonth}/${payload.endYear}`,
    { responseType: "blob" }
  );
}
export function downloadIncomeStatementTemplate(payload: Timeline) {
  return http.get(
    appraisalBaseAddress +
      `Fam/DownloadIncomeStatementInputTemplate/${payload.startMonth}/${payload.startYear}/${payload.endMonth}/${payload.endYear}`,
    { responseType: "blob" }
  );
}
export function downloadBalanceSheetTemplate(payload: Timeline) {
  return http.get(
    appraisalBaseAddress +
      `Fam/DownloadBalanceSheetInputTemplate/${payload.startMonth}/${payload.startYear}/${payload.endMonth}/${payload.endYear}`,
    { responseType: "blob" }
  );
}
export function downloadPreviousBalanceSheetTemplate() {
  return http.get(
    appraisalBaseAddress + `Fam/DownloadFamSummaryBalanceSheetInputTemplate`,
    { responseType: "blob" }
  );
}
export function downloadCashFlowTemplate(payload: Timeline) {
  return http.get(
    appraisalBaseAddress +
      `Fam/DownloadCashFlowInputTemplate/${payload.startMonth}/${payload.startYear}/${payload.endMonth}/${payload.endYear}`,
    { responseType: "blob" }
  );
}
export function downloadCVTInputTemplate() {
  return http.get(appraisalBaseAddress + `Fam/DownloadCVTInputTemplate`, {
    responseType: "blob",
  });
}
export function downloadCollateralSummaryTemplate() {
  return http.get(
    appraisalBaseAddress + `Fam/DownloadCollateralSummaryInputTemplate`,
    { responseType: "blob" }
  );
}
export function downloadFamSummaryTemplate() {
  return http.get(
    appraisalBaseAddress + `Fam/DownloadFamSummaryInputTemplate`,
    { responseType: "blob" }
  );
}

export default {
  checkUploaded,

  previewCogsRecords,
  previewBalanceSheetRecords,
  previewCashFlowRecords,
  previewIncomeStatementRecords,
  previewCVTRecords,
  previewCollateralSummaryRecords,
  previewFAMSummaryRecords,

  uploadCogsRecords,
  uploadIncomeStatementRecords,
  uploadBalanceSheetRecords,
  uploadCashFlowRecords,
  uploadCVTRecords,

  submitFAMSummaryRecords,
  submitCollateralSummaryRecords,
  submitCVTRecords,

  validateCogsRecords,
  validateIncomeStatementRecords,
  validateBalanceSheetRecords,
  validateAndUploadPreviousBalanceSheetRecords,
  validateCashFlowRecords,
  validateCVTRecords,
  validateCollateralSummaryRecords,
  validateFAMSummaryRecords,

  retrieveById,
  retrieveCogsRecordsOutput,
  retrieveBalanceSheetOutput,
  retrieveCashFlowOutput,
  retrieveProfitLossOutput,
  retrieveCVTOutput,
  retrieveFinancialRatioOutput,
  retrieveFAMSummaryOutput,

  downloadCogsTemplate,
  downloadIncomeStatementTemplate,
  downloadBalanceSheetTemplate,
  downloadPreviousBalanceSheetTemplate,
  downloadCashFlowTemplate,
  downloadCollateralSummaryTemplate,
  downloadFamSummaryTemplate,
};
