import React, { useCallback, useEffect, useState } from "react";
import okraWebhookService from "../../../../services/_axiosServices/sweep/okra-webhook";
import { Loader, Error } from "../../components";

const initialData = [
  {
    institutionCode: "--",
    payload: "--",
    dateCreated: "--",
    id: 0,
  },
];

export default function Report() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initialData);
  const [error, setError] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchData = useCallback(async () => {
    setLoading(true);
    await okraWebhookService
      .retrieveAll()
      .then((res: any) => {
        // console.log(res);
        setLoading(false);
        setData(res.data);
      })
      .catch((err: any) => {
        setError(true);
        setLoading(false);
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="container-fluid relative animatedParent animateOnce">
      <div className="animated fadeInUpShort go">
        <div className="row my-3 mx-2">
          <h3>
            <b>Okra Webhook - Retrieve All</b>
          </h3>
        </div>
        <div className="card no-b">
          <div className="card-body">
            <div className="card-title">
              <div className="page">
                <div className="page-inner">
                  <div className="btn-toolbar justify-content-between">
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={fetchData}
                      >
                        <i className="fas fa-sync"></i>
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive text-nowrap">
                    {loading && <Loader />}

                    {!loading && !error && (
                      <table className="table table-hover table-content table-striped table-bordered table-sm paged-output">
                        <thead>
                          <tr>
                            <th>id</th>
                            <th>institutionCode</th>
                            <th>payload</th>
                            <th>dateCreated</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((w: any, i: number) => (
                            <tr key={i}>
                              <td>{w.id}</td>
                              <td>{w.institutionCode}</td>
                              <td>{w.payload}</td>
                              <td>{w.dateCreated}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}

                    {error && !loading && <Error />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
