import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";
import { IFilterData } from "../approval/approvalItemService";
import { decodeToken } from "react-jwt";

const recovaMandateBaseAddress = process.env.REACT_APP_MANDATE_BASEADDRESS;

export function retrieveCollectionConsentConfigById(id: number) {
  return http.get(
    recovaMandateBaseAddress + `CollectionConsentConfig/RetrieveById/${id}`
  );
}

export function retrieveAllCollectionConsentConfig() {
  return http.get(
    recovaMandateBaseAddress + `CollectionConsentConfig/RetrieveAll`
  );
}

export function retrieveMultipleCollectionConsentConfigsByIds(data) {
  return http.get(
    recovaMandateBaseAddress + `CollectionConsentConfig/RetrieveByIds`,
    data
  );
}

export function createCollectionConsentConfig(payload) {
  return http.post(
    recovaMandateBaseAddress + `CollectionConsentConfig/Save`,
    payload
  );
}

export function updateCollectionConsentConfig(id, payload) {
  return http.post(
    recovaMandateBaseAddress + `CollectionConsentConfig/Update/${id}`,
    payload
  );
}

export function filterCollectionConsentConfig(
  payload: IFilterData,
  pageSize: number,
  pageNumber: number
) {
  //   payload = { ...payload };
  //   payload.ocm = {
  //     item1: "=",
  //     item2: "1",
  //   };
  //   payload.customerType = {
  //     item1: "=",
  //     item2: "Beneficiary",
  //   };

  return http.post(
    recovaMandateBaseAddress +
    `CollectionConsentConfig/FilterCollectionConsentConfigs?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    payload
  );
}

export function retrieveTemplateByCollectionConsentType(type) {
  return http.get(
    recovaMandateBaseAddress +
    `CollectionConsentConfig/RetrieveTemplateByCollectionConsentType/${type}`
  );
}

export function saveCollectionConsentTemplate(type, message) {
  let payload: any = {};
  payload.body = message;
  // console.log("message: ", payload);
  return http.post(
    recovaMandateBaseAddress +
    `CollectionConsentConfig/SaveCollectionConsentTemplate/${type}`,
    payload
  );
}

// export function downloadReport(type: string) {
//   const decodedToken = decodeToken(getCurrentUserSession().token || "");
//   var mywindow = window.open(
//     recovaMandateBaseAddress +
//     `ConsentRequest/downloadreport/${decodedToken?.["InstitutionId"]}?type=${type}`,
//     "PRINT",
//     "height=500,width=900"
//   );
//   mywindow?.focus();
// }

// export function downloadReportPDF() {
//   const decodedToken = decodeToken(getCurrentUserSession().token || "");
//   return http.get(
//     recovaMandateBaseAddress +
//     `ConsentRequest/DownloadReportPDF/${decodedToken?.["InstitutionId"]}`
//   );
// }

export default {
  retrieveCollectionConsentConfigById,
  retrieveAllCollectionConsentConfig,
  retrieveMultipleCollectionConsentConfigsByIds,
  createCollectionConsentConfig,
  updateCollectionConsentConfig,
  filterCollectionConsentConfig,
  retrieveTemplateByCollectionConsentType,
  saveCollectionConsentTemplate,
};
