import React from "react";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import DetailItem from "../../../components/detailItem";
import systemSettingsService from "../../../services/auth/systemSettingsService";
import institutionService from "../../../services/auth/institutionsService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

interface EditMandateBillingStatusFields {
  billMandateOnCreation: boolean;
}

class EditMandateBillingStatusForm extends BaseFormComponent<EditMandateBillingStatusFields> { }

interface IEditMandateBillingStatusProps {
  item: any;
  institution: any
  showEditModal: boolean;
  toggleEditModal: any;
}
interface IEditMandateBillingStatusState {
  ownUpdate: boolean;
  billMandateOnCreation: boolean;
}

class EditMandateBillingStatus extends React.Component<
  IEditMandateBillingStatusProps,
  IBaseFormState & IEditMandateBillingStatusState
> {
  constructor(props: IEditMandateBillingStatusProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      billMandateOnCreation: false,
      ownUpdate: false,
    };
  }

  static getDerivedStateFromProps(nextProps: IEditMandateBillingStatusProps, prevState) {
    if (prevState.ownUpdate) {
      return {
        billMandateOnCreation: prevState.billMandateOnCreation,
        ownUpdate: false,
      };
    }
    return {
      billMandateOnCreation: nextProps.item.billMandateOnCreation,
    }
  }

  onFormSubmit(fields: EditMandateBillingStatusFields, onReloadFieldsCB: any): boolean {
    try {
      fields.billMandateOnCreation = this.state.billMandateOnCreation;
      this.submit(fields, onReloadFieldsCB);
      this.setState({ validated: true, ownUpdate: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (fields: EditMandateBillingStatusFields, onReloadFieldsCB: any) => {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const payload = this.props.item;

      // console.log("For checks::", payload);
      payload.billMandateOnCreation = fields.billMandateOnCreation;
      const response = await systemSettingsService.updateSystemSettings(payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit Mandate Billing Status",
          description: `Edited details of Mandate Billing Status ${this.props.institution.name}`,
        });
        toast.info(`Mandate Billing Status for ${this.props.institution.name} Edited successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      }
      onReloadFieldsCB();
      this.setState({ submitting: false, ownUpdate: true }, async () => {
        var institution = await institutionService.retrieveInstitutionById(this.props.item.institutionId)
        this.props.toggleEditModal(true, institution.data)
      });
    } catch (error) {
      // console.log("submission error: ", error);
      this.setState({ submitting: false });
    }
  };

  handleUpdateBillMandateOnCreation = () => {
    this.setState({
      billMandateOnCreation: !this.state.billMandateOnCreation,
      ownUpdate: true,
    });
  };

  render() {
    const { item, showEditModal, toggleEditModal, institution } = this.props;
    const { submitting, billMandateOnCreation } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-gopuram fa-sm cyan-text mr-3" />
              Edit Mandate Billing Status -{" "}
              <span className="font-weight-bold">{institution.name}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditMandateBillingStatusForm
          initialValues={{
            billMandateOnCreation: item.billMandateOnCreation,
          }}
          FormComponent={({
            fields: { },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    <h6
                      className="card-title float-left"
                      style={{ marginBottom: "0px" }}
                    >
                      General Information
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <dl className="row">
                          <DetailItem
                            label="Institution Name"
                            labelSize={7}
                            valueSize={5}
                            value={institution.name}
                          />
                          <DetailItem
                            label="Institution Code"
                            labelSize={7}
                            valueSize={5}
                            value={institution.code}
                          />
                        </dl>
                      </div>
                      <div className="col-md-6">
                        <dl className="row">
                          <DetailItem
                            label="Bill Mandate on Creation"
                            labelSize={7}
                            valueSize={5}
                            value={
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  onChange={
                                    this.handleUpdateBillMandateOnCreation
                                  }
                                  id="billMandateOnCreation"
                                  name="billMandateOnCreation"
                                  defaultChecked={billMandateOnCreation}
                                // value={billMandateOnCreation.toString()}
                                />
                                <span className="slider round"></span>
                              </label>
                            }
                          />
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(e, ActionTypes.UPDATE, "Mandate Billing Status", () => {
                      this.onFormSubmit(
                        {
                          billMandateOnCreation
                        },
                        onReloadFields
                      );
                    });
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></EditMandateBillingStatusForm>
      </Modal>
    );
  }
}

export default EditMandateBillingStatus;
