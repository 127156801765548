import React, { Component } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import BaseFormComponent, { IBaseFormState } from "../../../../../../components/BaseFormComponent";
import FormInputComponent from "../../../../../../components/formInputComponent";
import SelectInputComponent, { ISelectItems } from "../../../../../../components/formSelectComponent";
import { ActionTypes } from "../../../../../../enums/actionTypes";
import loanRequestService from "../../../../../../services/hybridAppraisal/loanRequestService";
import { countries } from "./../../../../../../services/utility/countries";
import hybridAppraisalValidationService from "../../../../../../services/hybridAppraisal/hybridAppraisalValidationService";
import { getCurrentUsersRoles } from "../../../../../../services/auth/authService";

interface StakeholderDetailsFields {
  title: string;
  position: string;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  address: string;
  city: string;
  state: string;
  country: string;
  phoneNumber: string;
  phoneNumber2: string;
  email: string;
  educationalQualification: string;
  stakePercentage: Number;
  lengthOfYearsInCompany: Number;
  idType: string;
  idNumber: string;
  idExpiry: string;
  monthsInCompany: Number;
}

class StakeholderDetailsForm extends BaseFormComponent<StakeholderDetailsFields> { }

interface StakeholderDetailsProps {
  values: any;
  reloadItem: any;
  mandatoryFields: any;
}

interface StakeholderDetailsState {
  stakeholders: StakeholderDetailsFields[];
  ownUpdate: boolean;
  country: string;
  state: string;
}

class StakeholderDetails extends Component<
  StakeholderDetailsProps,
  IBaseFormState & StakeholderDetailsState
> {
  constructor(props: StakeholderDetailsProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      stakeholders: [],
      ownUpdate: false,
      country: "",
      state: ""
    };
  }
  static getDerivedStateFromProps(
    nextProps: StakeholderDetailsProps,
    prevState: StakeholderDetailsState
  ) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isEmpty(nextProps.values.stakeholders)) {
      return null;
    }
    return {
      stakeholders: nextProps.values.stakeholders
    };
  }

  onFormSubmit(fields: StakeholderDetailsFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        { errors: {}, ownUpdate: true },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: StakeholderDetailsFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const loanRequest = await loanRequestService.retrieveById(this.props.values.id);
      const loanRequestDetail = loanRequest.data;
      const payload: any = { ...loanRequestDetail };
      payload.stakeholders = this.state.stakeholders

      const response = await loanRequestService.updateLoanRequest(payload);
      if (response.status >= 200 && response.status <= 300) {
        toast.info(`Stakeholder Details saved successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false, ownUpdate: true });
      this.resetFields();
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false, ownUpdate: true });
      return false;
    } finally {
      return true;
    }
  }

  handleOnAddStakeholder = (item: StakeholderDetailsFields, onReloadFieldsCB: any) => {
    const { mandatoryFields } = this.props;
    try {
      this.setState(
        { errors: hybridAppraisalValidationService.validateAddStakeHolderForm(item, mandatoryFields), ownUpdate: true },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.setState({ validated: true, ownUpdate: true });
            const stakeholders = [...this.state.stakeholders];
            stakeholders.push(item);
            this.setState({ stakeholders, ownUpdate: true });
            this.resetFields();
            onReloadFieldsCB();
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
    } catch (error) {
      return false;
    }
  }
  handleOnRemoveStakeholder = (item: StakeholderDetailsFields) => {
    const stakeholders = [...this.state.stakeholders];
    if (stakeholders.includes(item)) {
      stakeholders.splice(
        stakeholders.indexOf(item), 1
      );
    }
    this.setState({ stakeholders, ownUpdate: true });
  };

  getRequiredFields(title): boolean {
    const { mandatoryFields } = this.props;
    var required = mandatoryFields?.inputFields?.filter(x => x.fieldName === title)[0]?.mandatory;
    if (required === undefined) {
      return false;
    }
    return required;
  }

  resetFields() {
    this.setState({ validated: false, submitting: false, errors: [], country: "", ownUpdate: true });
  }

  render() {
    const { values } = this.props;
    const { errors, validated, stakeholders } = this.state;

    let statesInputData: ISelectItems[] = [];
    let countriesInputData: ISelectItems[] = [];
    countries.forEach((country) => {
      countriesInputData.push({ name: country.name, value: country.name });
    });
    countries
      .filter((c) => c.name === this.state.country)[0]
      ?.states.forEach((state) => {
        statesInputData.push({ name: state.name, value: state.name });
      });

    let titleInputData: ISelectItems[] = [
      { name: "Mr.", value: "Mr." },
      { name: "Mrs.", value: "Mrs." },
      { name: "Miss", value: "Miss" },
      { name: "Chf.", value: "Chf." },
      { name: "Dr.", value: "Dr." },
      { name: "Prof.", value: "Prof." },
    ];

    let positionInputData: ISelectItems[] = [
      { name: "Parent", value: "Parent" },
      { name: "Family", value: "Family" },
      { name: "Friend", value: "Friend" },
    ];

    let genderInputData: ISelectItems[] = [
      { name: "Male", value: "Male" },
      { name: "Female", value: "Female" },
    ];

    let educationalQualificationInputData: ISelectItems[] = [
      { name: "Professional Qualification", value: "Professional Qualification" },
      { name: "Post Graduate (Masters, Doctorate etc)", value: "Post Graduate" },
      { name: "Graduate (University Degree or equivalent)", value: "Graduate" },
      { name: "Less than Graduate", value: "Less than Graduate" },
      { name: "Others", value: "Others" },
    ];

    let identificationTypeInputData: ISelectItems[] = [
      { name: "International Passport", value: "InternationalPassport" },
      { name: "Drivers License", value: "DriversLicense" },
      { name: "Voters' Card", value: "VotersCard" },
      { name: "Work ID", value: "WorkID" },
      { name: "Ecowas Passport", value: "EcowasPassport" },
      { name: "Residential Permit", value: "ResidentialPermit" },
      { name: "National ID", value: "NationalID" },
    ];

    return (
      <StakeholderDetailsForm
        initialValues={{
          title: "",
          position: "",
          firstName: "",
          middleName: "",
          lastName: "",
          gender: "",
          dateOfBirth: "",
          address: "",
          city: "",
          state: "",
          country: "",
          phoneNumber: "",
          phoneNumber2: "",
          email: "",
          educationalQualification: "",
          stakePercentage: 0,
          lengthOfYearsInCompany: 0,
          idType: "",
          idNumber: "",
          idExpiry: "",
          monthsInCompany: 0
        }}
        FormComponent={({
          fields: {
            title,
            position,
            firstName,
            middleName,
            lastName,
            gender,
            dateOfBirth,
            address,
            city,
            state,
            country,
            phoneNumber,
            phoneNumber2,
            email,
            educationalQualification,
            stakePercentage,
            lengthOfYearsInCompany,
            idType,
            idNumber,
            idExpiry,
            monthsInCompany
          },
          onChange,
          onReloadFields,
          onHandleSubmit,
        }) => (
          <div className="form-row">
            <div className="col-md-12">
              <div className="form-row">
                <SelectInputComponent
                  id="title"
                  name="title"
                  divClass={6}
                  value={title}
                  items={titleInputData}
                  required={this.getRequiredFields("stakeholderTitle")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  id="position"
                  name="position"
                  divClass={6}
                  value={position}
                  type="text"
                  placeholder=""
                  required={this.getRequiredFields("stakeholderPosition")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  id="firstName"
                  name="firstName"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={firstName}
                  required={this.getRequiredFields("stakeholderFirstName")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  id="middleName"
                  name="middleName"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={middleName}
                  required={this.getRequiredFields("stakeholderMiddleName")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  id="lastName"
                  name="lastName"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={lastName}
                  required={this.getRequiredFields("stakeholderLastName")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <SelectInputComponent
                  id="gender"
                  name="gender"
                  divClass={6}
                  value={gender}
                  items={genderInputData}
                  required={this.getRequiredFields("stakeholderGender")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  id="dateOfBirth"
                  name="dateOfBirth"
                  type="date"
                  placeholder=""
                  divClass={6}
                  value={dateOfBirth}
                  required={this.getRequiredFields("stakeholderDateOfBirth")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  id="address"
                  name="address"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={address}
                  required={this.getRequiredFields("stakeholderAddress")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="City/Town"
                  id="city"
                  name="city"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={city}
                  required={this.getRequiredFields("stakeholderCity")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <SelectInputComponent
                  id="country"
                  name="country"
                  divClass={6}
                  value={this.state.country}
                  items={countriesInputData}
                  required={this.getRequiredFields("stakeholderCountry")}
                  validated={validated}
                  errors={errors}
                  onChange={async (id, value) => {
                    await onChange(id, value);
                    this.setState({ country: value, ownUpdate: true })
                  }
                  }
                />
                <SelectInputComponent
                  label="State/Region"
                  id="state"
                  name="state"
                  divClass={6}
                  value={state}
                  items={statesInputData}
                  required={this.getRequiredFields("stakeholderState")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Phone Number 1"
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={phoneNumber}
                  required={this.getRequiredFields("stakeholderPhoneNumber1")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Phone Number 2"
                  id="phoneNumber2"
                  name="phoneNumber2"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={phoneNumber2}
                  required={this.getRequiredFields("stakeholderPhoneNumber2")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Email Address"
                  id="email"
                  name="email"
                  type="email"
                  placeholder=""
                  divClass={6}
                  value={email}
                  required={this.getRequiredFields("stakeholderEmailAddress")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <SelectInputComponent
                  id="educationalQualification"
                  name="educationalQualification"
                  divClass={6}
                  value={educationalQualification}
                  items={educationalQualificationInputData}
                  required={this.getRequiredFields("stakeholderEducationalQualification")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <div className="col-md-6">
                  <label style={{ paddingBottom: "calc(0.115rem + 1px)" }} className="col-form-label s-12">
                    Length of time in Company
                  </label>
                  <div className="form-row">
                    <FormInputComponent
                      label="Years"
                      id="lengthOfYearsInCompany"
                      name="lengthOfYearsInCompany"
                      type="number"
                      placeholder=""
                      divClass={6}
                      value={lengthOfYearsInCompany.toString()}
                      required={this.getRequiredFields("stakeholderLengthOfTimeInCompanyInYears")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                    />
                    <FormInputComponent
                      label="Months"
                      id="monthsInCompany"
                      name="monthsInCompany"
                      type="number"
                      placeholder=""
                      divClass={6}
                      value={monthsInCompany.toString()}
                      required={this.getRequiredFields("stakeholderLengthOfTimeInCompanyInMonths")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                    />
                  </div>
                </div>
                <FormInputComponent
                  id="stakePercentage"
                  name="stakePercentage"
                  type="number"
                  placeholder=""
                  divClass={6}
                  value={stakePercentage.toString()}
                  required={this.getRequiredFields("stakePercentage")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <SelectInputComponent
                  label="Identification Type"
                  id="idType"
                  name="idType"
                  divClass={6}
                  value={idType}
                  items={identificationTypeInputData}
                  required={this.getRequiredFields("stakeholderIdentificationType")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Identification Number"
                  id="idNumber"
                  name="idNumber"
                  type="text"
                  placeholder=""
                  divClass={6}
                  value={idNumber}
                  required={this.getRequiredFields("stakeholderIdentificationNumber")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
                <FormInputComponent
                  label="Identification Expiry Date"
                  id="idExpiry"
                  name="idExpiry"
                  type="date"
                  placeholder=""
                  divClass={6}
                  value={idExpiry}
                  required={this.getRequiredFields("stakeholderIdentificationExpiryDate")}
                  validated={validated}
                  errors={errors}
                  onChange={onChange}
                />
              </div>

              {/* Add Stakeholder */}
              <div className="form-row">
                <div className="my-1 mx-2">
                  {(getCurrentUsersRoles().includes("updatedataentry") && (
                    <button
                      className="btn btn-sm btn-outline-primary my-4"
                      style={{ cursor: "pointer" }}
                      type="button"
                      onClick={() =>
                        this.handleOnAddStakeholder(
                          {
                            title,
                            position,
                            firstName,
                            middleName,
                            lastName,
                            gender,
                            dateOfBirth,
                            address,
                            city,
                            state,
                            country,
                            phoneNumber,
                            phoneNumber2,
                            email,
                            educationalQualification,
                            stakePercentage,
                            lengthOfYearsInCompany,
                            idType,
                            idNumber,
                            idExpiry,
                            monthsInCompany
                          },
                          onReloadFields
                        )
                      }
                    >
                      <React.Fragment>
                        <i className="far fa-save fa-lg mr-2" />{" "}
                        Add
                      </React.Fragment>
                    </button>
                  ))}
                </div>
              </div>

              {/* Stakeholder Table */}
              <div className=" form-row col-md-12">
                <div
                  className="table-responsive text-nowrap"
                  style={{
                    maxHeight: "150px",
                    overflowY: "scroll",
                  }}
                >
                  <table className="table table-hover table-content table-sm table-striped mb-0">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>#</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Stakeholder/Director Name</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Stakeholder Percentage</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Position</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Phone Number</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Email</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>State</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {stakeholders.map((item) => (
                        <tr key={stakeholders.indexOf(item)}>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {stakeholders.indexOf(item) + 1}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.firstName} {" "} {item.middleName} {" "} {item.lastName}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.stakePercentage}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.position}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.phoneNumber}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.email}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.state}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            <i
                              className="far fa-trash-alt ml-5 red-text fa-lg"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                this.handleOnRemoveStakeholder(
                                  item
                                )
                              }
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Save Stakeholder */}
              <div className="card-body float-right">
                {(getCurrentUsersRoles().includes("updatedataentry") && (
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary"
                    disabled={this.state.submitting}
                    onClick={(e) => {
                      onHandleSubmit(
                        e,
                        ActionTypes.SAVE,
                        "Stakeholder Details",
                        () => {
                          this.onFormSubmit(
                            {
                              title,
                              position,
                              firstName,
                              middleName,
                              lastName,
                              gender,
                              dateOfBirth,
                              address,
                              city,
                              state,
                              country,
                              phoneNumber,
                              phoneNumber2,
                              email,
                              educationalQualification,
                              stakePercentage,
                              lengthOfYearsInCompany,
                              idType,
                              idNumber,
                              idExpiry,
                              monthsInCompany
                            },
                            onReloadFields
                          )
                        }
                      );
                    }}
                  >
                    {this.state.submitting === false ? (
                      <React.Fragment>
                        <i className="fas fa-lg fa-save mr-3" /> Save
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                        Please wait...
                      </React.Fragment>
                    )}
                  </button>
                ))}
              </div>

            </div>
          </div>
        )}
      ></StakeholderDetailsForm>
    );
  }
}

export default StakeholderDetails;
