import * as React from "react";
import { ISelectItems } from "../../../components/inputComponents/selectBox";
import _ from "lodash";
import SelectInputComponent from "../../../components/formSelectComponent";
import { toast } from "react-toastify";
import BaseFormComponent, { IBaseFormState } from "../../../components/BaseFormComponent";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../components/detailItem";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import { ActionTypes } from "../../../enums/actionTypes";
import { Button } from "react-bootstrap";
import sweepValidationService from "../../../services/sweep/sweepValidationService";
import generalSettingService from "../../../services/sweep/generalSettingService";
import textFormatService from "../../../services/utility/textFormatService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

interface ModifyGeneralSweepSettingsFields {
  proactiveSweepInterval: string;
  maxProactiveSweeps: string;
}

class ModifyGeneralSweepSettingsForm extends BaseFormComponent<ModifyGeneralSweepSettingsFields> { }

export interface IModifyGeneralSweepSettingsProps { }

export interface IModifyGeneralSweepSettingsState {
  category: string;
  generalSettings: any;
  fetching: boolean;
  editing: boolean;
}

class ModifyGeneralSweepSettings extends React.Component<IModifyGeneralSweepSettingsProps, IBaseFormState & IModifyGeneralSweepSettingsState> {
  constructor(props: IModifyGeneralSweepSettingsProps) {
    super(props);
    this.state = {
      generalSettings: {},
      submitting: false,
      category: "",
      fetching: false,
      editing: false,
      errors: [],
      validated: false
    };
  }

  onFormSubmit(fields: ModifyGeneralSweepSettingsFields, onReloadFieldsCB: any): boolean {
    try {
      // console.log("Fields for submitting:: ", fields)
      this.setState(
        {
          errors:
            sweepValidationService.validateModifyGeneralSweepSettingsForm(
              fields
            ),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            // console.log("passed Validation")
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(fields: ModifyGeneralSweepSettingsFields, onReloadFieldsCB: any): Promise<boolean> {
    let response: any;
    try {
      const { category, generalSettings } = this.state;
      this.setState({ submitting: true });
      const payload = generalSettings;
      if (category == "CommercialBank") {
        payload.commercialProactiveSweepInterval = Number(fields.proactiveSweepInterval);
        payload.commercialMaxProactiveSweeps = Number(fields.maxProactiveSweeps);
      } else if (category == "BankOneOFI") {
        payload.bankOneProactiveSweepInterval = Number(fields.proactiveSweepInterval);
        payload.bankOneMaxProactiveSweeps = Number(fields.maxProactiveSweeps);
      } else {
        payload.ofiProactiveSweepInterval = Number(fields.proactiveSweepInterval);
        payload.ofiMaxProactiveSweeps = Number(fields.maxProactiveSweeps);
      }

      // console.log("Reached Here!!!!!")
      response = await generalSettingService.updateProactiveSweepConfig(payload);
      // console.log("testing log:: ", response);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Modify General Sweep Settings",
          description: `Modify General Sweep Settings for ${this.state.category}`,
        });
        toast.info(`Sweep Setting for ${this.state.category} updated successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false });
      this.resetFields();
      // onReloadFieldsCB();
    } catch (error) {
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        if (Object.keys(error.response.data.errors).length > 0) {
          toast.info("Something went wrong. Kindly ensure all the input values are valid.", {
            autoClose: 6000,
            type: toast.TYPE.ERROR,
            hideProgressBar: false,
          });
        }
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // console.log("error: ", error.response);
        // return false;
      }
      // console.log("testing log:: ", response);
      // console.log("error log:: ", error);
      // console.log("error: ", error.response.status);
      // console.log("error: ", error.response);
      this.setState({ submitting: false });
      return false;
    } finally {
      return true;
    }
  }

  toggleEditMode = async (changeHandler) => {
    const { generalSettings, category } = this.state;
    if (category == "CommercialBank") {
      await changeHandler("proactiveSweepInterval", generalSettings.commercialProactiveSweepInterval?.toString());
      await changeHandler("maxProactiveSweeps", generalSettings.commercialMaxProactiveSweeps?.toString());
    } else if (category == "BankOneOFI") {
      await changeHandler("proactiveSweepInterval", generalSettings.bankOneProactiveSweepInterval?.toString());
      await changeHandler("maxProactiveSweeps", generalSettings.bankOneMaxProactiveSweeps?.toString());
    } else {
      await changeHandler("proactiveSweepInterval", generalSettings.ofiProactiveSweepInterval?.toString());
      await changeHandler("maxProactiveSweeps", generalSettings.ofiMaxProactiveSweeps?.toString());
    }
    this.setState({ editing: !this.state.editing })
  }

  resetFields() {
    this.setState({ validated: false, submitting: false });
  }

  handleOnSelectCategory = async (id, value) => {
    try {
      this.setState({ category: value, generalSettings: {}, editing: false, fetching: true });
      if (value == "") {
        this.setState({ fetching: false });
        return;
      }
      const generalSettings = await generalSettingService.retrieveGeneralSettings();
      this.setState({ generalSettings: generalSettings.data, fetching: false });
    } catch (error) {
      toast.info("Something went wrong.", {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      toast.info(error.response.data.errors[0], {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
    }
  };

  render() {
    const {
      editing,
      errors,
      generalSettings,
      fetching,
      validated,
      submitting,
      category,
    } = this.state;

    let categoryInputData: ISelectItems[] = [
      { name: "Commercial Bank", value: "CommercialBank" },
      { name: "BankOne OFI", value: "BankOneOFI" },
      { name: "OFI", value: "OFI" },
    ];

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>General Sweep Settings</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <form action="#">
                  <div className="form-row">
                    <SelectInputComponent
                      id="category"
                      name="category"
                      divClass={4}
                      value={category}
                      items={categoryInputData}
                      required={false}
                      validated={false}
                      errors={[]}
                      onChange={this.handleOnSelectCategory}
                    />
                  </div>
                  <br />
                  {fetching ? (
                    <Skeleton count={4} height={30} />
                  ) : _.isEmpty(generalSettings) ? (
                    <></>
                  ) : (
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="row my-3 mx-2">
                          <h5>
                            Sweep Settings -{" "}
                            {/* <span className="font-weight-bold">
                              {category}
                            </span> */}
                          </h5>
                        </div>
                        <ModifyGeneralSweepSettingsForm
                          initialValues={{
                            proactiveSweepInterval: category == "CommercialBank" ? generalSettings.commercialProactiveSweepInterval : (category == "BankOneOFI" ? generalSettings.bankOneProactiveSweepInterval : generalSettings.ofiProactiveSweepInterval),
                            maxProactiveSweeps: category == "CommercialBank" ? generalSettings.commercialMaxProactiveSweeps : (category == "BankOneOFI" ? generalSettings.bankOneMaxProactiveSweeps : generalSettings.ofiMaxProactiveSweeps)
                          }}
                          FormComponent={({
                            fields: { maxProactiveSweeps, proactiveSweepInterval },
                            onChange,
                            onReloadFields,
                            onHandleSubmit,
                          }) => (
                            <form action="#">
                              <div className="card no-b">
                                <div className="card-body">
                                  <div className="form-row">
                                    <div className="col-md-12">
                                      {_.isEmpty(generalSettings) ? (
                                        <Skeleton count={2} height={30} />
                                      ) : (
                                        <div className="form-row">
                                          <div className="col-md-6">
                                            {!_.isEmpty(generalSettings) && fetching ? (
                                              <Skeleton count={5} width={300} />
                                            ) : (
                                              <dl className="row">
                                                {editing ? (
                                                  <React.Fragment>
                                                    <DetailItem
                                                      label="Proactive Sweep Interval"
                                                      value={
                                                        <DetailItemInputComponent
                                                          id="proactiveSweepInterval"
                                                          type="number"
                                                          name="proactiveSweepInterval"
                                                          placeholder=""
                                                          value={proactiveSweepInterval}
                                                          required={true}
                                                          validated={validated}
                                                          errors={errors}
                                                          onChange={onChange}
                                                        />
                                                      }
                                                      labelSize={5}
                                                      valueSize={7}
                                                    />
                                                  </React.Fragment>
                                                ) : (
                                                  <React.Fragment>
                                                    <DetailItem
                                                      label="Proactive Sweep Interval"
                                                      value={proactiveSweepInterval}
                                                      labelSize={5}
                                                      valueSize={7}
                                                    />
                                                  </React.Fragment>
                                                )}
                                                <DetailItem
                                                  label="Last Institution Sync"
                                                  value={textFormatService.formatTimeString(generalSettings.lastInstitutionSync)}
                                                  labelSize={5}
                                                  valueSize={7}
                                                />
                                              </dl>
                                            )}
                                          </div>
                                          <div className="col-md-6">
                                            {!_.isEmpty(generalSettings) && fetching ? (
                                              <Skeleton count={5} width={300} />
                                            ) : (
                                              <dl className="row">
                                                {editing ? (
                                                  <React.Fragment>
                                                    <DetailItem
                                                      label="Max. Proactive Sweeps"
                                                      value={
                                                        <DetailItemInputComponent
                                                          id="maxProactiveSweeps"
                                                          type="number"
                                                          name="maxProactiveSweeps"
                                                          placeholder=""
                                                          value={maxProactiveSweeps}
                                                          required={true}
                                                          validated={validated}
                                                          errors={errors}
                                                          onChange={onChange}
                                                        />
                                                      }
                                                      labelSize={5}
                                                      valueSize={7}
                                                    />
                                                  </React.Fragment>
                                                ) : (
                                                  <React.Fragment>
                                                    <DetailItem
                                                      label="Max. Proactive Sweeps"
                                                      value={maxProactiveSweeps}
                                                      labelSize={5}
                                                      valueSize={7}
                                                    />
                                                  </React.Fragment>
                                                )}
                                                <DetailItem
                                                  label="Last Proactive Sweep Run"
                                                  value={textFormatService.formatTimeString(generalSettings.lastProactiveSweepRun)}
                                                  labelSize={5}
                                                  valueSize={7}
                                                />
                                              </dl>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <hr />
                                {editing ? (
                                  <React.Fragment>
                                    <div className="card-body">
                                      <button
                                        type="submit"
                                        className="btn btn-sm btn-primary"
                                        disabled={this.state.submitting}
                                        onClick={(e) => {
                                          onHandleSubmit(
                                            e,
                                            ActionTypes.UPDATE,
                                            "Sweep Setting",
                                            () => {
                                              this.onFormSubmit(
                                                { proactiveSweepInterval, maxProactiveSweeps },
                                                onReloadFields
                                              );
                                            }
                                          );
                                        }}
                                      >
                                        {this.state.submitting === false ? (
                                          <React.Fragment>
                                            <i className="fas fa-lg fa-save mr-3" /> Save
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                            Please wait...
                                          </React.Fragment>
                                        )}
                                      </button>
                                    </div>
                                  </React.Fragment>
                                ) : (
                                  !_.isEmpty(generalSettings) && (
                                    <div className="card-body">
                                      <Button
                                        size="sm"
                                        variant="primary"
                                        disabled={submitting}
                                        onClick={() => this.toggleEditMode(onChange)}
                                      >
                                        <i className="far fa-edit mr-3" />
                                        Edit
                                      </Button>
                                    </div>
                                  )
                                )}
                              </div>
                            </form>
                          )}
                        ></ModifyGeneralSweepSettingsForm>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ModifyGeneralSweepSettings;
