import React, { Component } from "react";
import textFormatService from "../../../services/utility/textFormatService";
import NewItemComponent from "./newItemComponent";

export interface IRuleProps {
  step: any;
  level: number;
  events: any;
  viewing: boolean;
}

export interface IRuleState { }

export class Rule extends Component<IRuleProps, IRuleState> {
  render() {
    const { step, viewing } = this.props;
    let minComb = step[0] ? step[0].combinationNo : 0,
      maxComb = step[step.length - 1] ? step[step.length - 1].combinationNo : 0;

    // console.log("minComb: ", minComb)
    // console.log("step[0].combinationNo: ", step[0].combinationNo)

    // console.log("maxComb: ", maxComb)
    // console.log("step[step.length - 1] : ", step[step.length - 1])

    let list: any = [];
    for (let i = minComb; i <= maxComb; i++) {
      list.push(i);
    }
    // console.log("Steps in rule >", step);
    // console.log("Max Comb >", maxComb);
    // console.log("level >", this.props.level);
    return (
      <React.Fragment key={textFormatService.generateGUID()}>
        <div key={textFormatService.generateGUID()} className="step">
          {list.map((combNo) => (
            <React.Fragment key={textFormatService.generateGUID()}>
              {combNo === minComb ? (
                ""
              ) : (
                <span className="badge badge-light mr-2 ml-2"> OR </span>
              )}
              <div
                key={textFormatService.generateGUID()}
                data-group={combNo}
                className="role"
              >
                {/* {viewing ? (
                  <p></p>
                ) : (
                  <p>
                    <span
                      aria-hidden="true"
                      className="float-right"
                      onClick={
                        () => this.props.events.handleOnRuleDelete(step, combNo) //redefine function
                      }
                      style={{ cursor: "pointer", color: "red" }}
                    >
                      ×
                    </span>
                  </p>
                )} */}
                {step
                  .filter((item) => item.combinationNo === combNo)
                  .map((_item, index) => (
                    <React.Fragment key={textFormatService.generateGUID()}>
                      <p>
                        <span className="font-weight-normal"> Role: </span>
                        {_item.role.name + "  "}
                        {viewing ? (
                          ""
                        ) : (
                          <i
                            className="fas fa-minus-circle float-right"
                            style={{ color: "#bd1010", cursor: "pointer" }}
                            onClick={() =>
                              this.props.events.handleOnRuleDelete(_item)
                            }
                          />
                          // <span
                          //   aria-hidden="true"
                          //   className="float-right"
                          //   onClick={() =>
                          //     this.props.events.handleOnRuleDelete(_item)
                          //   }
                          //   style={{ cursor: "pointer", color: "red" }}
                          // >
                          //   {" "}
                          //   -
                          // </span>
                        )}
                      </p>
                      <p>
                        <span className="font-weight-normal"> </span>
                        {_item.applicableAmount}
                      </p>
                      {step.filter((item) => item.combinationNo === combNo)
                        .length ===
                        index + 1 && !viewing ? (
                        <i
                          className="fas fa-plus-circle"
                          style={{ color: "#043b68", cursor: "pointer" }}
                          onClick={() =>
                            this.props.events.addNewRule(
                              "Add New Rule",
                              "",
                              combNo,
                              this.props.level,
                              0,
                              false
                            )
                          }
                        />
                      ) : (
                        <React.Fragment key={textFormatService.generateGUID()}>
                          {viewing ? "" : <br />}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            </React.Fragment>
          ))}
        </div>
        {!viewing ? (
          <NewItemComponent
            name="new-role"
            content="+"
            events={this.props.events}
            eventData={{
              title: "Add New Rule",
              userRoleId: "",
              applicableAmount: 0,
              combinationNo: maxComb + 1,
              level: this.props.level,
            }}
          />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}
