import React, { Fragment } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import FormInputComponent from "../../../components/formInputComponent";
import FormTextAreaComponent from "../../../components/formTextAreaComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import SelectInputComponent, {
  ISelectItems,
} from "../../../components/formSelectComponent";
import MultiSelectComponent, {
  IMultiSelectItems,
} from "../../../components/formMultiSelectComponent";
import offerLetterService from "../../../services/hybridAppraisal/offerLetterService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import textFormatService, { generateGUID } from "../../../services/utility/textFormatService";
import appraisalInstitutionService from "../../../services/hybridAppraisal/appraisalInstitutionService";
import loanProductService from "../../../services/hybridAppraisal/loanProductService";

interface AddOfferLetterFields {
  institutionCategory: string;
  institutionName: string;
  BranchDetails: any;
  CustomerDetails: any;
  LoanDetails: any;
  AddClause: string;
  Body: string;
  logo: any;
  inputKey: any;
  institutionCode: any;
  productCode: string;
  logoPosition: string;
  signatoryDesignation: any;
  Introduction: string;
  LoanInformation: string;
  Conditions: string;
}
interface ISystemProcess {
  id: number;
  name: string;
  description: string;
}

interface ISignatoryOptions {
  id: string;
  value: string;
  name: string;
}
/*
  Address: string;
  PhoneNumber: string;
  Email: string;
  CustomerID: string;
  EmployeeNumber: string;
  Employer: string;
  Nuban: string;
  Product: string;
  ApprovedAmount: string; 
  LoanFee: string;
  ManagementFee: string;
  InsuranceFee: string;
  Interest: string;
  InterestAmount: string;
  InterestpA: string;
  DefaultCharges: string;
  PenaltyCharge: string;
  PenaltyFrequency: string;
  */

class AddOfferLetterForm extends BaseFormComponent<AddOfferLetterFields> { }

interface IAddOfferLetterProps { }

interface IAddOfferLetterState {
  BranchDetails: any;
  CustomerDetails: any;
  LoanDetails: any;
  selectedBranchDetails: any;
  selectedCustomerDetails: any;
  selectedLoanDetails: any;
  logo: any;
  inputKey: any;
  appraisalInstitutions: any;
  previewUrl: string;
  fetchingRequiredItems: boolean;
  institution: any;
  signatory: any;
  hasSignatory: boolean;
  signatoryOption: ISignatoryOptions[],
  product: any
}

class AddOfferLetter extends React.Component<
  IAddOfferLetterProps,
  IBaseFormState & IAddOfferLetterState
> {
  _isMounted = false;
  constructor(props: IAddOfferLetterProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      hasSignatory: false,
      errors: {},
      BranchDetails: [],
      CustomerDetails: [],
      fetchingRequiredItems: false,
      LoanDetails: [],
      appraisalInstitutions: [],
      selectedBranchDetails: [],
      selectedCustomerDetails: [],
      selectedLoanDetails: [],
      logo: {},
      inputKey: generateGUID(),
      previewUrl: "",
      institution: {},
      signatory: 0,
      signatoryOption: [],
      product: []
    };
  }

  async componentWillMount() {
    this._isMounted = true;
    const { errors } = this.state;
    try {
      this.setState({ fetchingRequiredItems: true });
      const response = await Promise.allSettled([
        appraisalInstitutionService.retrieveAll()
      ])
      const appraisalInstitutions = response[0].status == "fulfilled" ? response[0].value.data : [];
      this.setState({
        appraisalInstitutions,
        fetchingRequiredItems: false,
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (ex.response && ex.response.status === 404) {
        // console.log(ex.response);
        errors.response = "Internal server error.";
        // console.log(errors);
        this.setState({ errors });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  onFormSubmit(fields: AddOfferLetterFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddOfferLetterFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true });
      const payload: any = {};
      const { selectedBranchDetails } = this.state;
      const { selectedCustomerDetails } = this.state;
      const { selectedLoanDetails } = this.state;

      var selectedBranchDetail = JSON.stringify(selectedBranchDetails).replaceAll('"', "'");
      var selectedCustomerDetail = JSON.stringify(selectedCustomerDetails).replaceAll('"', "'");
      var selectedLoanDetail = JSON.stringify(selectedLoanDetails).replaceAll('"', "'");

      var productName = this.state.product.find(x => x.productCode === fields.productCode)?.name !== "" && this.state.product.find(x => x.productCode === fields.productCode)?.name !== undefined ? this.state.product.find(x => x.productCode === fields.productCode)?.name : "CREDIT FACILITY";
      console.log(productName);

      payload.institutionName = this.state.institution.name;
      payload.institutionCode = this.state.institution.code;
      payload.logoPosition = fields.logoPosition;
      payload.institutionCategory = fields.institutionCategory;
      payload.branchDetails = JSON.stringify(fields.BranchDetails).replaceAll('"', "'");
      payload.customerDetails = JSON.stringify(fields.CustomerDetails).replaceAll('"', "'");
      payload.loanDetails = JSON.stringify(fields.LoanDetails).replaceAll('"', "'");
      payload.Introduction = fields.Introduction;
      payload.LoanInformation = fields.LoanInformation;
      payload.Conditions = fields.Conditions;
      payload.clause = fields.AddClause;
      payload.productName = productName
      payload.productCode = fields.productCode != "" ? fields.productCode : "000"
      // payload.signatory = fields.signatory;
      payload.signatoryDesignation = JSON.stringify(fields.signatoryDesignation).replaceAll('"', "'");
      // console.log(payload);

      const response = await offerLetterService.addOfferLetter(payload);
      if (response.status >= 200 && response.status <= 300) {
        if (response.data === true) {
          await activityTrailService.saveActivityTrail({
            actionType: "Add Offer Letter",
            description: `Added new Offer Letter ${fields.institutionName}`,
          });
          toast.info(`Offer Letter for Institution: ${fields.institutionName} created successfully! `, {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          });
        }
        else if (response.data === "Institution Already Exist") {
          toast.info(`Offer Letter for Institution: ${fields.institutionName} already exist! `, {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          });
        }
      } else {
        return false;
      }
      this.setState({ submitting: false });
      this.resetFields();
      onReloadFieldsCB();
    } catch (error) {
      toast.error(error.response.data, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      this.setState({ submitting: false });
    } finally {
      return true;
    }
  }


  resetFields() {
    this.setState({
      submitting: false,
      selectedBranchDetails: [],
      selectedCustomerDetails: [],
      selectedLoanDetails: []
    });
  }

  resetAllFields() {
    this.setState({
      submitting: false,
      logo: {},
      previewUrl: "",
      inputKey: generateGUID()
    })
  }

  handleOnSelectInstitution = async (id, value) => {
    try {
      // this.setState({ parameterIds: [] });
      const institution = this.state.appraisalInstitutions.filter((x) => x.id === parseInt(value))[0];
      // console.log(institution);
      const appraisalInstitution = await appraisalInstitutionService.retrieveByInstitutionCode(institution.code);
      const products = await (await loanProductService.retrieveByInstitutionCode(institution.code)).data;
      this.setState({
        institution,
        product: products
      });
      // this.setState({ institution })
    } catch (error) {
      toast.info("Something went wrong.", {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      toast.info(error.response.data.errors[0], {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
    }
  };

  handleSignatoryChange = async (id, value) => {


    this.setState({ signatory: value }, () => { this.handleSignatoryField(value) });
    // console.log(this.state.signatory);
  }
  handleSignatoryField = (value) => {
    const { signatoryOption } = this.state;
    value = value > 5 ? 5 : value;
    signatoryOption.splice(0);

    for (var i = 1; i <= value; i++) {
      signatoryOption.push({ id: "signatory" + i, value: value, name: "" });
    }

    this.setState({ signatoryOption: signatoryOption });
    // console.log(signatoryOption);
  }

  handleSignatoryDesignationChange = (id, value) => {
    const signatoryOption = [...this.state.signatoryOption];
    // this.setState({flatPerApprovedLoan:flatPerApprovedLoan_});

    signatoryOption.every(option => {
      if (option.id == id) {

        option.name = value;
        // this.setState({ submitting: false });
        return false;
      }
      // this.setState({flatPerApprovedLoan:flatPerApprovedLoan_})
      // Make sure you return true. If you don't return a value, `every()` will stop.
      return true;
    });
    // console.log("getting object",validationOption.find(option => option.validationPricingOptionId == id).fee);
    //  console.log(signatoryOption);

    return signatoryOption;
    // this.setState({validationOption: validationOption});
  }

  handleOnLogoChange = async (e) => {
    e.preventDefault();
    let logo = e.target.files[0];
    let error: string = "";
    let url: string = "";
    if (logo == undefined) {
      return;
    }
    // console.log("inspect ros: ", logo);
    if (!["png", "svg"].includes(logo.name.split(".").slice(-1)[0])) {
      toast.error(
        "Please confirm that the document is a valid Png or Svg file"
      );
      error = "Invalid document type";
    } else if (Math.round(logo.size / 1024) > 200) {
      toast.error(
        "Kindly ensure that the size of the document is less than 200 KB"
      );
      error = "Size of document is larger than 200kb";
    } else {
      url = URL.createObjectURL(logo)
      // logoPayload = await textFormatService.getBase64StringFromFile(logo);

      // logoPayload = (logo.name.split(".").slice(-1)[0] == "png" ? "data:image/png;base64, " : "data:image/svg+xml;base64, ") + logoPayload;
    }
    this.setState({
      logo: logo,
      previewUrl: url
    });
  };


  handleOnBranchDetailsChange = (selected) => {
    this.setState({ selectedBranchDetails: selected });
  };
  handleOnCustomerDetailsChange = (selected) => {
    this.setState({ selectedCustomerDetails: selected });
  };
  handleOnLoanDetailsChange = (selected) => {
    this.setState({ selectedLoanDetails: selected });
  };

  render() {
    const {
      errors,
      validated,
      appraisalInstitutions,
      selectedBranchDetails,
      selectedCustomerDetails,
      selectedLoanDetails,
      fetchingRequiredItems,
      inputKey,
      institution,
      logo,
      previewUrl,
      signatory,
      signatoryOption,
      product
    } = this.state;

    let institutionCategoryInputData: ISelectItems[] = [
      { name: "Commercial Bank", value: "CommercialBank" },
      { name: "BankOne FI", value: "BankOne FI" },
      { name: "OFI", value: "OFI" },
    ];

    let logoPostionInputData: ISelectItems[] = [
      { name: "Left", value: "Left" },
      { name: "Right", value: "Right" },
      { name: "Centre", value: "Centre" }
    ]

    let signatoryInputData: ISelectItems[] = [
      { name: "1", value: "1" },
      { name: "2", value: "2" },
      { name: "3", value: "3" },
      { name: "4", value: "4" },
      { name: "5", value: "5" }
    ]

    let BranchDetailsInputData: IMultiSelectItems[] = [
      { label: "Branch name", value: "Branch name" },
      { label: "Branch Address", value: "Branch Address" },
      { label: "Account officer", value: "Account officer" },
    ];
    let CustomerDetailsInputData: IMultiSelectItems[] = [
      { label: "FirstName", value: "FirstName" },
      { label: "LastName", value: "LastName" },
      { label: "Address", value: "Address" },
      { label: "IdentificationType", value: "IdentificationType" },
      { label: "IdentificationNumber", value: "IdentificationNumber" },
      { label: "IdentificationExpiryDate", value: "IdentificationExpiryDate" },
      { label: "BVN", value: "BVN" },
      { label: "State", value: "State" },
      { label: "Country", value: "Country" },
      { label: "Phonenumber", value: "Phonenumber" },
      { label: "CustomerEmail", value: "CustomerEmail" },
      { label: "EmployerName", value: "EmployerName" },
      { label: "JobAddress", value: "JobAddress" },
      { label: "JobState", value: "JobState" }
    ];
    let LoanDetailsInputData: IMultiSelectItems[] = [
      { label: "Nuban", value: "Nuban" },
      { label: "ProductCode", value: "ProductCode" },
      { label: "LoanAmount", value: "LoanAmount" },
      { label: "LoanFee", value: "LoanFee" },
      // { label: "Management fee", value: "Management fee" },
      // { label: "Insurance fee", value:"Insurance fee"},
      { label: "InterestRate", value: "InterestRate" },
      // { label: "Interest Amount", value: "Interest Amount" },
      // { label: "Interest per annum (%)", value: "Interest per annum (%)" },
      // { label: "Default charges", value: "Default charges" },
      { label: "LoanPenalty", value: "LoanPenalty" },
      { label: "ObligorLimit", value: "ObligorLimit" },
      { label: "TenureInDays", value: "TenureInDays" }
    ];

    let institutionsInputData: ISelectItems[] = appraisalInstitutions.map((item) => ({
      name: item.name,
      value: item.id,
    }));

    let productsInputData: ISelectItems[] = product.map((item) => ({
      name: item.name,
      value: item.productCode,
    }));
    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add Offer Letter</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddOfferLetterForm
                  initialValues={{
                    institutionCategory: "",
                    institutionName: "",
                    BranchDetails: "",
                    institutionCode: "",
                    CustomerDetails: "",
                    LoanDetails: "",
                    AddClause: "",
                    Body: "",
                    logo: logo,
                    Introduction: "",
                    LoanInformation: "",
                    Conditions: "",
                    inputKey: inputKey,
                    logoPosition: "",
                    productCode: "",
                    signatoryDesignation: this.state.signatoryOption
                  }}
                  FormComponent={({
                    fields: {
                      institutionCategory,
                      institutionName,
                      institutionCode,
                      BranchDetails,
                      CustomerDetails,
                      LoanDetails,
                      AddClause,
                      Introduction,
                      LoanInformation,
                      Conditions,
                      Body,
                      logo,
                      productCode,
                      logoPosition,
                      signatoryDesignation,
                    },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">

                              {/* General Information */}
                              <div className="form-row">
                                <SelectInputComponent
                                  id="institutionCategory"
                                  name="institutionCategory"
                                  divClass={6}
                                  value={institutionCategory}
                                  items={institutionCategoryInputData}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <SelectInputComponent
                                  id="institution"
                                  name="institution"
                                  divClass={6}
                                  value={institution.id}
                                  items={institutionsInputData}
                                  required={true}
                                  validated={false}
                                  errors={[]}
                                  labelIconClass={fetchingRequiredItems === true
                                    ? "fas fa-spinner fa-spin orange-text mr-2"
                                    : ""
                                  }
                                  onChange={this.handleOnSelectInstitution}
                                />
                                <SelectInputComponent
                                  id="productCode"
                                  name="productCode"
                                  divClass={6}
                                  value={productCode}
                                  items={productsInputData}
                                  required={false}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                  disabled={false}
                                />
                              </div>
                              <div className="form-row">
                                <SelectInputComponent
                                  id="logoPosition"
                                  name="logoPosition"
                                  divClass={6}
                                  value={logoPosition}
                                  items={logoPostionInputData}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              {/* Branch Details */}
                              <div className="form-row">
                                <div className="form-group col-md-12 mt-3">
                                  <label
                                    htmlFor="reports"
                                    className="col-form-label s-10"
                                  >
                                    Branch Details
                                  </label>
                                  <br />

                                  <div className="form-row mt-0">
                                    <MultiSelectComponent
                                      id="BranchDetails"
                                      name="BranchDetails"
                                      divClass={6}
                                      value={BranchDetailsInputData}
                                      options={BranchDetailsInputData}
                                      validated={validated}
                                      errors={errors}
                                      hideSelectedOptions={false}
                                      onChange={this.handleOnCustomerDetailsChange}
                                      allowSelectAll={true}
                                      // required={true}
                                      disabled={true}

                                    />
                                  </div>
                                </div>
                              </div>
                              {/* </div> */}
                              {/* Branch Details */}
                              <div className="form-row">
                                <div className="form-group col-md-12 mt-3">
                                  <label
                                    htmlFor="reports"
                                    className="col-form-label s-10"
                                  >
                                    Customer Details
                                  </label>
                                  <br />
                                  <div className="form-row mt-0">
                                    <MultiSelectComponent
                                      id="CustomerDetails"
                                      name="CustomerDetails"
                                      divClass={6}
                                      value={CustomerDetailsInputData}
                                      options={CustomerDetailsInputData}
                                      validated={validated}
                                      errors={errors}
                                      hideSelectedOptions={false}
                                      onChange={this.handleOnCustomerDetailsChange}
                                      allowSelectAll={true}
                                      // required={true}
                                      disabled={true}

                                    />
                                  </div>
                                </div>
                              </div>
                              {/* Branch Details */}
                              <div className="form-row">
                                <div className="form-group col-md-12 mt-3">
                                  <label
                                    htmlFor="reports"
                                    className="col-form-label s-10"
                                  >
                                    Loan Details
                                  </label>
                                  <br />
                                  <div className="form-row mt-0">
                                    <MultiSelectComponent
                                      id="LoanDetails"
                                      name="LoanDetails"
                                      divClass={6}
                                      value={LoanDetailsInputData}
                                      options={LoanDetailsInputData}
                                      validated={validated}
                                      errors={errors}
                                      hideSelectedOptions={false}
                                      onChange={this.handleOnLoanDetailsChange}
                                      allowSelectAll={true}
                                      // required={true}
                                      disabled={true}
                                    />
                                  </div>
                                </div>
                                <FormTextAreaComponent
                                  id="Introduction"
                                  type="textarea"
                                  name={"Introduction"}
                                  placeholder="Add Input Fields selected above required in this body in curly braces E.g {Full Name}"
                                  divClass={6}
                                  value={Introduction}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                  disabled={false}
                                />
                                <FormTextAreaComponent
                                  id="LoanInformation"
                                  type="textarea"
                                  name={"LoanInformation"}
                                  placeholder=""
                                  divClass={6}
                                  value={LoanInformation}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                  disabled={false}
                                />
                                <FormTextAreaComponent
                                  id="Conditions"
                                  type="textarea"
                                  name={"Conditions"}
                                  placeholder=""
                                  divClass={6}
                                  value={Conditions}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                  disabled={false}
                                />
                                <FormInputComponent
                                  id="AddClause"
                                  type="text"
                                  name={"AddClause"}
                                  placeholder="Binding Clause at the bottom of the Offer Letter"
                                  divClass={6}
                                  value={AddClause}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                  disabled={false}
                                />
                                <SelectInputComponent
                                  id="signatory"
                                  name="signatory"
                                  divClass={6}
                                  value={signatory}
                                  items={signatoryInputData}
                                  required={true}
                                  validated={false}
                                  errors={[]}
                                  labelIconClass={fetchingRequiredItems === true
                                    ? "fas fa-spinner fa-spin orange-text mr-2"
                                    : ""
                                  }
                                  onChange={this.handleSignatoryChange}
                                // onBlur={(e)=>{this.handleBlur(e.target.value)}}
                                />

                                {signatory === 0 ? ("") : (
                                  signatoryDesignation.map((data) => (
                                    <FormInputComponent
                                      id={data.id}
                                      type="text"
                                      name={data.id}
                                      placeholder="Signatory Designation"
                                      divClass={6}
                                      value={data.name}
                                      required={false}
                                      validated={validated}
                                      errors={errors}
                                      onChange={(id, value) => {
                                        onChange('signatoryDesignation', this.handleSignatoryDesignationChange(id, value))
                                        // this.handleValidationChange
                                      }}
                                      disabled={false}
                                    />
                                  )))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary"
                          disabled={this.state.submitting}
                          onClick={(e) => {
                            onHandleSubmit(
                              e,
                              ActionTypes.SAVE,
                              "Offer Letter",
                              () => {
                                this.onFormSubmit(
                                  {
                                    institutionCategory,
                                    institutionName,
                                    institutionCode,
                                    BranchDetails,
                                    CustomerDetails,
                                    LoanDetails,
                                    Introduction,
                                    LoanInformation,
                                    Conditions,
                                    AddClause,
                                    Body,
                                    logo,
                                    productCode,
                                    inputKey,
                                    logoPosition,
                                    signatoryDesignation
                                  },
                                  onReloadFields
                                );
                              }
                            );
                          }}
                        >
                          {this.state.submitting === false ? (
                            <React.Fragment>
                              <i className="fas fa-lg fa-save mr-3" /> Save
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                              Please wait...
                            </React.Fragment>
                          )}
                        </button>
                      </div>
                    </form>
                  )}
                ></AddOfferLetterForm>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment >
    );
  }
}

export default AddOfferLetter;
