import http from "./../httpService";
import { getCurrentUserSession } from "../auth/authService";
import { IFilterData } from "../approval/approvalItemService";
import { decodeToken } from "react-jwt";

export function saveLoanProduct(loanProduct) {
  return http.post(
    process.env.REACT_APP_AUTH_BASEADDRESS + `LoanProduct`,
    loanProduct
  );
}

export function updateLoanProduct(loanProduct) {
  return http.post(
    process.env.REACT_APP_AUTH_BASEADDRESS + `LoanProduct/Update`,
    loanProduct
  );
}

export function filterLoanProducts(
  payload: IFilterData,
  pageSize: number,
  pageNumber: number
) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  payload = { ...payload };
  payload.institutionId = {
    item1: "=",
    item2: decodedToken?.["InstitutionId"]?.toString() || "break",
  };
  return http.post(
    process.env.REACT_APP_AUTH_BASEADDRESS +
    `LoanProduct/FilterLoanProducts?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    payload
  );
}

export function retrieveAllByInstitutionId(institutionId) {
  return http.get(
    process.env.REACT_APP_AUTH_BASEADDRESS +
    `LoanProduct/RetrieveAllByInstitutionId/${institutionId}`
  );
}

export function retrieveAllByProductsByUseCase(institutionId, useCase) {
  return http.get(
    process.env.REACT_APP_AUTH_BASEADDRESS +
    `LoanProduct/RetrieveAllProductsByUseCase/${institutionId}?usecase=${useCase}`
  );
}

export function retrieveAllCreditAssessmentProducts(institutionId) {
  return http.get(
    process.env.REACT_APP_AUTH_BASEADDRESS +
    `LoanProduct/RetrieveAllCreditAssessmentProducts/${institutionId}`
  );
}

export function retrieveById(id) {
  return http.get(
    process.env.REACT_APP_AUTH_BASEADDRESS + `LoanProduct/RetrieveById/${id}`
  );
}

export function saveBulkLoanProducts(loanProducts) {
  return http.post(
    process.env.REACT_APP_AUTH_BASEADDRESS + `LoanProduct/SaveBulkLoanProducts`,
    loanProducts
  );
}

export function retrieveAllBankOneLoanProducts(externalInstitutionCode) {
  return http.get(
    process.env.REACT_APP_AUTH_BASEADDRESS +
    `LoanProduct/RetrieveAllBankOneLoanProducts/${externalInstitutionCode}`
  );
}

export default {
  saveLoanProduct,
  updateLoanProduct,
  filterLoanProducts,
  retrieveById,
  saveBulkLoanProducts,
  retrieveAllByInstitutionId,
  retrieveAllByProductsByUseCase,
  retrieveAllCreditAssessmentProducts,
  retrieveAllBankOneLoanProducts,
};
