import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";

type actionCategory =
  | "Create"
  | "Submit"
  | "Approve"
  | "Decline"
  | "PushedBack"
  | "Cancelled"
  | "Update"
  | "Recall";

export interface ISaveApprovalAction {
  id: number;
  functionID: number;
  actionType: actionCategory;
  itemID: number;
  itemLevel: number;
  userID: number;
  roleID: number;
  institutionID: number;
  applicationDetails: string;
  comment: string;
}

const approvalServiceBaseAddress = process.env.REACT_APP_APPROVAL_BASEADDRESS;
export function retrieveAllApprovalActions() {
  var url = approvalServiceBaseAddress +
    `approvalAction`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveApprovalActionById(id: number) {
  var url = approvalServiceBaseAddress +
    `approvalAction/${id}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function saveApprovalAction(approvalAction: ISaveApprovalAction) {
  var url = approvalServiceBaseAddress + "approvalAction";

  return http.post(url, approvalAction, {
    url: url,
    method: "post",
    data: approvalAction,
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function performUpdateAction(payload: any) {
  var url = approvalServiceBaseAddress + "approvalAction/PerformUpdateAction";

  return http.post(url, payload, {
    url: url,
    method: "post",
    data: payload,
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export default {
  retrieveAllApprovalActions,
  retrieveApprovalActionById,
  saveApprovalAction,
  performUpdateAction
};
