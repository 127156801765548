import * as React from "react";
import _ from "lodash";
import { Fragment } from "react";
import {
  IFilterData,
  BaseListComponentState,
} from "../../../components/BaseListComponent";
import {
  createFilterData,
  mapEntityList,
} from "../../../services/utility/autoMapperService";
import BaseListComponent from "../../../components/BaseListComponent";
import ItemsTable, {
  filterFieldData,
} from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "../../../components/tableComponents/tableFilterPane";
import appraisalInstitutionservice from "../../../services/hybridAppraisal/appraisalInstitutionService";
import appraisalPlanService from "../../../services/hybridAppraisal/appraisalPlanService";
import AppraisalInstitutionDetail from "./appraisalInstitutionDetail";
import EditAppraisalInstitution from "./editAppraisalInstitution";
import walletConfigService from "../../../services/billing/walletConfigService";
import institutionFeeConfigService from "../../../services/billing/institutionFeeConfigService";

export interface AppraisalInstitutionsProps { }

export interface AppraisalInstitutionsState extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
  toUpdate: boolean;
}

class AppraisalInstitutionsList extends BaseListComponent<AppraisalInstitutionsState> { }

class AppraisalInstitutions extends React.Component<AppraisalInstitutionsProps, AppraisalInstitutionsState> {
  constructor(props: AppraisalInstitutionsProps) {
    super(props);
    this.state = {
      data: createFilterData([]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
      toUpdate: true,
    };
  }

  handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true });
    let dataClone =
      typeof data === "undefined" ? { ...this.state.data } : { ...data };
    try {
      const appraisalInstitutions = await appraisalInstitutionservice.filterInstitution(
        dataClone,
        pageSize,
        currentPage
      );
      this.setState({
        tableItems: mapEntityList({
          entityList: appraisalInstitutions.data.item2,
          properties: [
            { oldName: "name", newName: "InstitutionName" },
            { oldName: "code", newName: "InstitutionCode" },
            {
              isTag: true,
              oldName: "status",
              newName: "status",
              success: "Active",
              danger: "Inactive",
              default: "default",
              primary: "primary",
              secondary: "secondary",
              warning: "warning",
              light: "light",
              dark: "dark",
            },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: appraisalInstitutions.data.item1,
        fetching: false,
      });
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        this.setState({ fetching: false });
      } else {
        this.setState({ fetching: false });
      }
    } finally {
      this.setState({ fetching: false });
    }
  };

  handleOnExport = async (type: string) => { };

  fetchItemById = async (item: any) => {
    this.setState({ fetchingDetail: true });
    const institution = await appraisalInstitutionservice.retrieveById(item.id);
    const appraisalInstitution = institution.data

    const response = await Promise.allSettled([
      appraisalPlanService.retrieveById(appraisalInstitution.planId),
      walletConfigService.filterWalletConfigurationByServiceOfferingAndInstitutionCode("Hybrid Appraisal", appraisalInstitution.code),
      institutionFeeConfigService.filterInstitutionTransactionFeeConfig(appraisalInstitution.code),
      institutionFeeConfigService.filterInstitutionSubscriptionFeeConfig(appraisalInstitution.code),
    ])
    const appraisalPlan = response[0].status == "fulfilled" ? response[0].value.data : [];
    const walletConfig = response[1].status == "fulfilled" ? response[1].value.data[0] : [];
    const transactionFeeConfig = response[2].status == "fulfilled" ? response[2].value.data.data[0] : [];
    const subscriptionFeeConfig = response[3].status == "fulfilled" ? response[3].value.data.data[0] : [];

    appraisalInstitution["planName"] = appraisalPlan.name;
    appraisalInstitution["billingType"] = walletConfig.billingType;
    appraisalInstitution["feeConfig"] = (_.isEmpty(transactionFeeConfig)) ? subscriptionFeeConfig : transactionFeeConfig;
    this.setState({ item: appraisalInstitution, fetchingDetail: false });
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }

  render() {
    const {
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;

    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Appraisal Institution</b>
              </h3>
            </div>
            <AppraisalInstitutionsList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              initialValues={{
                data: this.state.data,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: this.state.filterValueInput,
                item: {},
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                onToggleEditModal,
                showDetailsModal,
                showEditModal
              }) => (
                <Fragment>
                  <AppraisalInstitutionDetail
                    showDetailsModal={showDetailsModal}
                    toggleDetailsModal={onToggleDetailsModal}
                    toggleEditModal={onToggleEditModal}
                    item={item}
                  />
                  <EditAppraisalInstitution
                    showEditModal={showEditModal}
                    toggleEditModal={(reloadDetails: boolean, item: any) => {
                      onToggleEditModal();
                      if (reloadDetails === true) {
                        this.fetchItemById(item);
                      }
                      this.setState({
                        showDetailsModal: !this.state.showDetailsModal,
                      });
                    }}
                    item={item}
                  />
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="Appraisal Institutions"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></AppraisalInstitutionsList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AppraisalInstitutions;
