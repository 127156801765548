import React, { Component, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import textFormatService from "../../../services/utility/textFormatService";
import DetailItemsTable from "../../../components/tableComponents/detailItemsTable";
import { mapEntityList } from "../../../services/utility/autoMapperService";
import { getCurrentUsersRoles } from "../../../services/auth/authService";

interface IWorkFlowDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  // toggleTransitionModal: any;
  showTrail: any;
  currentUserRoles: any;
  item: any;
}

interface IWorkFlowDetailState {
  item: any;
  updatingStatus: boolean;
}

class WorkFlowDetail extends Component<
  IWorkFlowDetailProps,
  IWorkFlowDetailState
> {
  state = {
    item: {},
    updatingStatus: false,
  };

  static getDerivedStateFromProps(nextProps: IWorkFlowDetailProps, prevState) {
    // console.log("current props: ", nextProps.item);
    return {
      item: nextProps.item,
    };
  }

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      showTrail,
      currentUserRoles,
      // toggleTransitionModal,
      item,
    } = this.props;
    const { updatingStatus } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="icon icon--columns s-18 mr-3" />
                Work Flow Details -{" "}
                <span className="font-weight-bold">{item.workflowName}</span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
              {/* <div className="float-right">
                {_.isEmpty(item) ? (
                  <Skeleton width={100} />
                ) : (
                  <span
                    className="badge btn-primary"
                    onClick={showTrail}
                    style={{
                      cursor: "pointer",
                      visibility: currentUserRoles.includes(
                        "viewaudittrailitems"
                      )
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    View Trail
                  </span>
                )}
              </div> */}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={2} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Name"
                        value={item.workflowName}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Description"
                        value={item.description}
                        labelSize={5}
                        valueSize={7}
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={2} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Status"
                        value={
                          item.isActive ? (
                            <span className="badge badge-success">Active</span>
                          ) : (
                            <span className="badge badge-danger">Inactive</span>
                          )
                        }
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Date Created"
                        value={textFormatService.formatTimeString(
                          item.dateCreated
                        )}
                        labelSize={5}
                        valueSize={7}
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>
            <DetailItemsTable
              title="Work Flow Transitions"
              item={item}
              handleOnPageChange={() => {}}
              content={
                _.isEmpty(item)
                  ? []
                  : mapEntityList({
                      entityList: item.transitions,
                      properties: [
                        {
                          oldName: "sourceWorkflowState",
                          newName: "sourceWorkflowState",
                        },
                        {
                          oldName: "nextWorkflowState",
                          newName: "nextWorkflowState",
                        },
                      ],
                    })
              }
              contentCount={_.isEmpty(item) ? 0 : item.transitions.length}
              page={1}
              fetching={_.isEmpty(item) ? true : false}
              // timeColumn={["dateAdded"]}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
              {/* <Button
                size="sm"
                variant="primary"
                disabled={updatingStatus}
                onClick={toggleTransitionModal}
              >
                <i className="far fa-plus mr-3" />
                Add Transition
              </Button> */}

              {getCurrentUsersRoles().includes("editworkflow") && (
                <Button
                  size="sm"
                  variant="primary"
                  disabled={updatingStatus}
                  onClick={toggleEditModal}
                >
                  <i className="far fa-edit mr-3" />
                  Edit
                </Button>
              )}
            </React.Fragment>
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default WorkFlowDetail;
