import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";

type workFlows = "Financial" | "NonFinancial";
export type status = "Inactive" | "Active";

interface IRule {
  uuid: string;
  functionID: number;
  roleID: number;
  level: number;
  approverCount: number;
  applicableAmountUpper: number;
  applicableAmountLower: number;
  combinationNo: number;
  financialGroupId: number;
  id: number;
  createdDate: Date;
  institutionID: number;
}
interface IFinancialGroups {
  approvableId: number;
  lowerLimit: number;
  upperLimit: number;
  rules: IRule[];
  id: number;
  createdDate: Date;
  institutionID: number;
}

interface ISaveApprovable {
  institutionID: number;
  functionName: string;
  description: string;
  institutionCode: string;
  functionID: string;
  workflowType: workFlows;
  actionLinkTemplate: string;
  denyRequestOnSingleRejection: boolean;
  callBackTemplate: string;
  stepCallbackTemplate: string;
  createdByRole: string;
  createdBy: string;
  rules: IRule[];
  financialGroupIds: number[];
  financialGroups: IFinancialGroups[];
}
export interface IUpdateApprovable extends ISaveApprovable {
  uuid: string;
  status: status;
  unit: number;
  id: number;
  createdDate: Date;
}

const approvalServiceBaseAddress = process.env.REACT_APP_APPROVAL_BASEADDRESS;
export function retrieveApprovables() {
  var url = approvalServiceBaseAddress + `approvable`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveApprovable(id: number) {
  // const decodedToken = decodeToken(getCurrentUserSession().token || "");
  var url =
    approvalServiceBaseAddress +
    `approvable/${id}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveApprovableByName(name: string) {
  var url = approvalServiceBaseAddress + `approvable/GetByName/${name}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function saveApprovable(approvable: ISaveApprovable) {
  var url = approvalServiceBaseAddress + `approvable`;

  return http.post(url, approvable, {
    url: url,
    method: "post",
    data: approvable,
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
      // ClientIPAddress: retrieveCurrentUsersIpAddress(),
    },
  });
}

export function enableApprovable(approvable: IUpdateApprovable) {
  var url = approvalServiceBaseAddress + `approvable/enable`;

  return http.put(url, approvable, {
    url: url,
    method: "put",
    data: approvable,
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
      // ClientIPAddress: retrieveCurrentUsersIpAddress(),
    },
  });
}

export function disableApprovable(approvable: IUpdateApprovable) {
  var url = approvalServiceBaseAddress + `approvable/disable`;

  return http.put(url, approvable, {
    url: url,
    method: "put",
    data: approvable,
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
      // ClientIPAddress: retrieveCurrentUsersIpAddress(),
    },
  });
}

export default {
  retrieveApprovables,
  retrieveApprovable,
  // retrieveApprovableByName,
  saveApprovable,
  enableApprovable,
  disableApprovable,
};
