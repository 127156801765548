import http from "../httpService";
import axios from "axios";
import { getCurrentUserSession, getCurrentUsersRoles } from "./authService";
import { IFilterData } from "../approval/approvalItemService";

export type gender = "Male" | "Female";

interface ISearchInstitutionsData {
  name: string;
  status: string;
  category: string;
}
interface IInstitutionCommon {
  name: string;
  externalInstitutionCode: string;
  mandateBulkUploadType: string;
  disputeContactEmail: string;
  disputeContactPhone: string;
  loanRepaymentSuspenseGL: string;
  loanCollectionSuspenseGL: string;
  feeExpenseGL: string;
  settlementAccountMirror: string;
  settlementAccountNumber: string;
  repaymentReceivable: string;
  incomeGL: string;
  mandateSetUpFeeMinCharge: number;
  sweepFeeFlat: number;
  sweepFeePercent: number;
  mandateLimit: string;
}
interface ISaveInstitution extends IInstitutionCommon {
  shortName: string;
  code?: string;
  category: string;
  //   clearingBank: IClearingBank;
  clearingBankId: string | null;
  //   mandateSetUpFeeFlat: number;
  //   mandateSetUpFeePercent: number;
  //   mandateSetUpFeeMaxCharge: number;
  //   sweepFeeMinCharge: number;
  //   sweepFeeMaxCharge: number;
  adminUsername: string;
  adminFirstName: string;
  adminLastName: string;
  adminEmail: string;
  adminGender: gender;
  adminPhonenumber: string;
  greyListCap: number;
}
interface IUpdateInstitution extends IInstitutionCommon {
  mandateSetUpFeeFlat: number;
  mandateSetUpFeePercent: number;
  mandateSetUpFeeMaxCharge: number;
  sweepFeeMinCharge: number;
  sweepFeeMaxCharge: number;
  enableMandatesByDefault: boolean;
}

const authBaseAddress = process.env.REACT_APP_AUTH_BASEADDRESS;
export function retrieveAllInstitutions() {
  var url = authBaseAddress + `institutions`;

  let currentUserSession: any = {}
  if (getCurrentUsersRoles().includes("remotesession")) {
    currentUserSession = JSON.parse(localStorage.getItem("remoteUserSession") || "")
  } else {
    currentUserSession = getCurrentUserSession();
  }

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${currentUserSession.token}` },
  });
}

export function retrieveInstitutionByCode(institutionCode: string) {
  var url = authBaseAddress + `institutions/GetByCode/${institutionCode}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveAllByCategory(category: string) {
  var url = authBaseAddress + `institutions/RetrieveAllByCategory/${category}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveInstitutionByCode2(
  institutionCode: string,
  token: string
) {
  var url = authBaseAddress + `institutions/RetrieveByCode`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
      Inst: institutionCode,
    },
  });
}

export function retrieveInstitutionById(institutionId?: number) {
  var url = authBaseAddress + `institutions/GetById/${institutionId}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function saveInstitution(institution: ISaveInstitution) {
  var url = authBaseAddress + `institutions`;

  return http.post(url, institution, {
    url: url,
    data: institution,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function updateInstitution(
  institution: IUpdateInstitution,
  institutionId: number
) {
  var url = authBaseAddress + `institutions/update/${institutionId}`;
  return http.post(url, institution, {
    url: url,
    data: institution,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function searchInstitutions(
  data: ISearchInstitutionsData,
  pageSize: number,
  pageNumber: number
) {
  var url =
    authBaseAddress +
    `institutions/searchInstitutions?name=${data.name}&status=${data.status}&category=${data.category}&pageSize=${pageSize}&pageNumber=${pageNumber}`;
  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function filterInstitutions(
  payload: IFilterData,
  pageSize: number,
  pageNumber: number
) {
  var url =
    authBaseAddress +
    `institutions/FilterInstitutions?pageSize=${pageSize}&pageNumber=${pageNumber}`;
  return http.post(url, payload, {
    url: url,
    data: payload,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function enableInstitution(id: number) {
  var url = authBaseAddress + `institutions/enable/${id}`;
  return http.post(url, null, {
    url: url,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function disableInstitution(id: number) {
  var url = authBaseAddress + `institutions/disable/${id}`;
  return http.post(url, null, {
    url: url,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function setInstitutionLogo(id: number, imageString: string) {
  var url = authBaseAddress + `institutions/SetInstitutionLogo/${id}`;
  // console.log("stringified ::", JSON.stringify(imageString))
  return http.patch(url, JSON.stringify(imageString), {
    url: url,
    method: "patch",
    // data: imageString,
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}`, 'Content-Type': 'application/json-patch+json' },
  });
}

export function retrieveBankOneInstitution(institutionCode: string) {
  return http.get(
    process.env.REACT_APP_RECOVA_BANKONE_HANDSHAKE_BASEADDRESS +
    `institution/retrievebyinstitutioncode?institutionCode=${institutionCode}`
  );
}

export function retrieveByExternalInstitutionCode(
  externalInstitutionCode: string
) {
  return http.get(
    authBaseAddress +
    `institutions/retrieveByExternalInstitutionCode/${externalInstitutionCode}`
  );
}

export function retrieveBankOneInstitutionByCode(code: string) {
  return http.get(
    process.env.REACT_APP_RECOVA_BANKONE_HANDSHAKE_BASEADDRESS +
    `institution/RetrieveByCode?code=${code}`
  );
}

export function retrieveBankOneInstitutionByInstitutionCode(
  institutionCode: string
) {
  return http.get(
    process.env.REACT_APP_RECOVA_BANKONE_HANDSHAKE_BASEADDRESS +
    // `institution/RetrieveByCode?code=${institutionCode}`
    `institution/RetrieveByInstitutionCode?institutionCode=${institutionCode}`
  );
}

export function retrieveAllInstitutions2() {
  return http.get(authBaseAddress + `institutions/RetrieveAll`);
}

export function downloadReport(type: string) {
  var url = authBaseAddress + `institutions/downloadreport?type=${type}`;

  axios({
    url: url,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Institutions.${type}`); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
}

export function downloadReportPDF() {
  var url = authBaseAddress + `institutions/DownloadReportPDF`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export default {
  retrieveAllInstitutions,
  retrieveAllInstitutions2,
  retrieveInstitutionByCode,
  retrieveAllByCategory,
  retrieveInstitutionByCode2,
  retrieveInstitutionById,
  saveInstitution,
  updateInstitution,
  searchInstitutions,
  filterInstitutions,
  enableInstitution,
  disableInstitution,
  setInstitutionLogo,
  retrieveBankOneInstitution,
  retrieveBankOneInstitutionByCode,
  retrieveBankOneInstitutionByInstitutionCode,
  retrieveByExternalInstitutionCode,
  downloadReport,
  downloadReportPDF,
};
