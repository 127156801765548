import React, { Fragment } from "react";
import _ from "lodash";
import { Formik } from "formik";
import ReactWizard from "react-bootstrap-wizard";
import PersonalDetails from "./wizardComponents/viewDetailedDataEntry/personalDetails";
import CorporateDetails from "./wizardComponents/viewDetailedDataEntry/corporateDetails";
import ContactDetails from "./wizardComponents/viewDetailedDataEntry/contactDetails";
import WorkDetails from "./wizardComponents/viewDetailedDataEntry/workDetails";
import GuarantorDetails from "./wizardComponents/viewDetailedDataEntry/guarantorDetails";
import StakeholderDetails from "./wizardComponents/viewDetailedDataEntry/stakeholderDetails";
import CollateralDetails from "./wizardComponents/viewDetailedDataEntry/collateralDetails";
import DocumentCollection from "./wizardComponents/viewDetailedDataEntry/documentCollection";
import Notepad from "./wizardComponents/viewDetailedDataEntry/notepad";
import AssetPropertyDetails from "./wizardComponents/viewDetailedDataEntry/assetPropertyDetails";
import LoanDetails from "./wizardComponents/viewDetailedDataEntry/loanDetails";
import IncomeDetails from "./wizardComponents/viewDetailedDataEntry/IncomeDetails";
import { getCurrentUserSession, getCurrentUsersRoles } from "../../../../services/auth/authService";
import BaseFormComponent, { IBaseFormState } from "../../../../components/BaseFormComponent";
import appraisalActionService from "../../../../services/hybridAppraisal/appraisalActionService";
import activityTrailService from "../../../../services/auditTrail/activityTrailService";
import { toast } from "react-toastify";
import { ActionTypes } from "../../../../enums/actionTypes";
import FormTextAreaComponent from "../../../../components/formTextAreaComponent";
import PropertyDetails from "./wizardComponents/viewDetailedDataEntry/propertyDetails";
import FinancialAnalysis from "./wizardComponents/viewDetailedDataEntry/financialAnalysis";
import OtherDetails from "./wizardComponents/editDetailedDataEntry/otherDetails";

interface IDetailedDataEntryProps {
  item: any;
}

interface IDetailedDataEntryState {
  item: any;
}

interface DataValidationFields {
  comment: string;
}

class DataValidationForm extends BaseFormComponent<DataValidationFields> { }
class DetailedDataEntry extends React.Component<IDetailedDataEntryProps, IBaseFormState & IDetailedDataEntryState> {
  formRef: HTMLFormElement;
  constructor(props: IDetailedDataEntryProps) {
    super(props);
    this.state = {
      item: {},
      validated: false,
      submitting: false,
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps: IDetailedDataEntryProps, prevState) {
    return {
      item: nextProps.item,
    };
  }

  onFormSubmit(fields: DataValidationFields, onReloadFieldsCB: any, alt?): boolean {
    try {
      this.setState(
        { errors: [] },
        () => {
          if (alt == "pushback") {
            return this.submit(fields, onReloadFieldsCB, alt);
          }
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: DataValidationFields,
    onReloadFieldsCB: any,
    alt?
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true });
      const { item } = this.props;

      if (alt == "pushback") {
        // console.log("progressing action...");
        const currentUserSession = getCurrentUserSession();
        var payload: any = {};
        payload.loanRequestId = item.id
        payload.action = "PushBack"
        payload.itemLevel = 0
        payload.userID = currentUserSession.userId
        payload.roleID = currentUserSession.userRoleId
        payload.systemProcessID = 0
        payload.errorMessage = ""
        payload.logType = "UserType"
        payload.comment = fields.comment
        payload.effectiveStatus = "Valid"
        payload.processResponse = null
        // console.log("Payload: ", payload)
        const response = await appraisalActionService.createAppraisalAction(payload)
        if (response.status >= 200 && response.status <= 300) {
          // toggleAppraisalProcess()
          toast.info(`Loan Request Pushed Back to Data Entry!`, {
            autoClose: 6000,
            type: toast.TYPE.INFO,
            hideProgressBar: false,
          });
          activityTrailService.saveActivityTrail({
            actionType: "Add Appraisal Action",
            description: `Added new Appraisal Action`,
          });
        } else {
          return false;
        }
      } else {
        // console.log("progressing action...");
        const currentUserSession = getCurrentUserSession();
        var payload: any = {};
        payload.loanRequestId = item.id
        payload.action = "Submit"
        payload.itemLevel = 0
        payload.userID = currentUserSession.userId
        payload.roleID = currentUserSession.userRoleId
        payload.systemProcessID = 0
        payload.errorMessage = ""
        payload.logType = "UserType"
        payload.comment = fields.comment == "" ? "Done with data validation" : fields.comment
        payload.effectiveStatus = "Valid"
        payload.processResponse = null
        // console.log("Payload: ", payload)
        const response = await appraisalActionService.createAppraisalAction(payload)
        // console.log(response)
        if (response.status >= 200 && response.status <= 300) {
          // toggleAppraisalProcess()
          // console.log(item.customerName)
          activityTrailService.saveActivityTrail({
            actionType: "Add Appraisal Action",
            description: `Added new Appraisal Action`,
          });
          toast.info(`Loan Request Appraisal Process for ${item.customerName} would start shortly!`, {
            autoClose: 6000,
            type: toast.TYPE.INFO,
            hideProgressBar: false,
          });

        } else {
          return false;
        }
      }
      this.setState({ submitting: false });
      this.resetFields();
      // onReloadFieldsCB();
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false });
      return false;
    } finally {
      return true;
    }
  }

  resetFields() {
    this.setState({ validated: false, submitting: false, errors: [] });
  }

  render() {
    const { item } = this.props;
    // console.log("View Item :: ", item)

    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">

            <div className="row my-3 mx-2">
              <h3>
                <b>Data Validation</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Formik
                  initialValues={{
                    id: item.id,
                    requestIdentifier: item.requestIdentifier,
                    institutionCode: item.institutionCode,
                    externalInstitutionCode: item.externalInstitutionCode,
                    description: item.description,
                    currentLevel: item.currentLevel,
                    bvn: item.bvn,
                    linkedAccountNumber: item.linkedAccountNo,
                    requestStatus: item.requestStatus,
                    obligorLimit: item.obligorLimit,
                    totalIncome: item.totalIncome,
                    productCode: item.productCode,
                    approvalDate: item.approvalDate,
                    appraisalActions: item.appraisalActions,
                    loanPurpose: item.loanPurpose,
                    relationshipManager: item.relationshipManager,
                    loanType: item.loanType,
                    loanAmount: item.loanAmount,
                    tenureInDays: item.tenureInDays,
                    branch: item.branch,
                    financialGroup: item.financialGroup,
                    jsonData: JSON.parse(item.jsonData),

                    // personal details
                    customerName: item.customerName,
                    customerFirstName: item.customerFirstName,
                    customerLastName: item.customerLastName,
                    customerMiddleName: item.customerMiddleName,
                    gender: item.gender,
                    maritalStatus: item.maritalStatus,
                    identificationType: item.identificationType,
                    identificationNumber: item.identificationNumber,
                    identificationExpiryDate: item.identificationExpiryDate,
                    dateOfBirth: item.dateOfBirth,
                    title: item.title,
                    educationalQualification: item.educationalQualification,
                    motherMaidenName: item.motherMaidenName,
                    age: item.age,
                    numberOfDependent: item.numberOfDependent,
                    accountType: item.accountType,
                    ageOfAccount: item.ageOfAccount,
                    domiciliationOfSalary: item.domiciliationOfSalary,
                    accountNumber: item.accountNumber,
                    nationality: item.nationality,
                    tin: item.tin,
                    repaymentAccountCreationDate: item.repaymentAccountCreationDate,

                    // contact details
                    addressType: item.addressType,
                    propertyStatus: item.propertyStatus,
                    address: item.address,
                    city: item.city,
                    country: item.country,
                    state: item.state,
                    phonenumber: item.phonenumber,
                    phonenumber2: item.phonenumber2,
                    customerEmail: item.customerEmail,
                    yearsOfStayAtCurrentAddress: item.yearsOfStayAtCurrentAddress,
                    monthsOfStayAtCurrentAddress: item.monthsOfStayAtCurrentAddress,
                    yearsOfStayAtCurrentCity: item.yearsOfStayAtCurrentCity,
                    monthsOfStayAtCurrentCity: item.monthsOfStayAtCurrentCity,
                    twitterHandle: item.twitterHandle,
                    instagramID: item.instagramID,
                    facebookID: item.facebookID,

                    // work details
                    employmentIndustry: item.employmentIndustry,
                    employmentDesignation: item.employmentDesignation,
                    employmentStatus: item.employmentStatus,
                    employeeStatus: item.employeeStatus,
                    employerName: item.employerName,
                    employerCategory: item.employerCategory,
                    officePhoneNumber: item.officePhoneNumber,
                    yearsInCurrentJob: item.yearsInCurrentJob,
                    monthsInCurrentJob: item.monthsInCurrentJob,
                    jobAddress: item.jobAddress,
                    jobCity: item.jobCity,
                    jobCountry: item.jobCountry,
                    jobState: item.jobState,
                    businessName: item.businessName,
                    businessRegistration: item.businessRegistration,

                    // corporate details
                    companyName: item.companyName,
                    registrationNumber: item.registrationNumber,
                    constitution: item.constitution,
                    industry: item.industry,
                    incorporationDate: item.incorporationDate,
                    contactPerson: item.contactPerson,
                    designation: item.designation,
                    lengthOfYearsInBusiness: item.lengthOfYearsInBusiness,
                    domiciliationOfBusinessProceeds: item.domiciliationOfBusinessProceeds,
                    typeOfProductOffered: item.typeOfProductOffered,
                    productDurability: item.productDurability,
                    threatOfCompetition: item.threatOfCompetition,
                    availabilityOfTradingStock: item.availabilityOfTradingStock,
                    complexityOfProduct: item.complexityOfProduct,
                    operationalStructure: item.operationalStructure,
                    numberOfYearsOperatingInTheSameLocation: item.numberOfYearsOperatingInTheSameLocation,
                    foreignExchangeRisk: item.foreignExchangeRisk,
                    membershipOfAnOrganizedBody: item.membershipOfAnOrganizedBody,
                    locationCoordinates: item.locationCoordinates,

                    //others
                    guarantors: item.guarantors,
                    stakeholders: item.stakeholders,
                    collaterals: item.collaterals,
                    notepads: item.notepads,
                    assets: item.assets,
                    loanDetails: item.loanDetails,
                    incomeDetails: item.incomeDetails,
                    // otherDetails: item.otherDetails,
                    documents: item.documents

                  }}
                  validate={() => { }}
                  onSubmit={(values, { setSubmitting }) => { }}
                >
                  {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit} ref={(ref) => (this.formRef = ref)}>
                      <ReactWizard
                        className="row my-3 mx-2 font-italic font-weight-bold"
                        style={{ fontSize: '120px' }}
                        steps={item.loanType === "Individual" ? (
                          [
                            {
                              stepName: "Personal Details",
                              component: PersonalDetails,
                              stepProps: {
                                values: values,
                                handleChange: handleChange,
                                handleBlur: handleBlur,
                              },
                            },
                            {
                              stepName: "Contact Details",
                              component: ContactDetails,
                              stepProps: {
                                values: values,
                                handleChange: handleChange,
                                handleBlur: handleBlur,
                              },
                            },
                            {
                              stepName: "Work Details",
                              component: WorkDetails,
                              stepProps: {
                                values: values,
                                handleChange: handleChange,
                                handleBlur: handleBlur,
                              },
                            },
                            {
                              stepName: "Reference Details",
                              component: GuarantorDetails,
                              stepProps: {
                                values: values,
                                handleChange: handleChange,
                                handleBlur: handleBlur,
                              },
                            },
                            {
                              stepName: "Collateral Details",
                              component: CollateralDetails,
                              stepProps: {
                                values: values,
                                handleChange: handleChange,
                                handleBlur: handleBlur,
                              },
                            },
                            {
                              stepName: "Document Collection",
                              component: DocumentCollection,
                              stepProps: {
                                values: values,
                                handleChange: handleChange,
                                handleBlur: handleBlur,
                              },
                            },
                            // {
                            //   stepName: "Financial Analysis",
                            //   component: FinancialAnalysis,
                            //   stepProps: {
                            //     values: values,
                            //     handleChange: handleChange,
                            //     handleBlur: handleBlur,
                            //   },
                            // },
                            {
                              stepName: "Notepad Details",
                              component: Notepad,
                              stepProps: {
                                values: values,
                                handleChange: handleChange,
                                handleBlur: handleBlur,
                              },
                            },
                            {
                              stepName: "Income Details",
                              component: IncomeDetails,
                              stepProps: {
                                values: values,
                                handleChange: handleChange,
                                handleBlur: handleBlur,
                              },
                            },
                            {
                              stepName: "Asset Details",
                              component: AssetPropertyDetails,
                              stepProps: {
                                values: values,
                                handleChange: handleChange,
                                handleBlur: handleBlur,
                              },
                            },
                            {
                              stepName: "Property Details",
                              component: PropertyDetails,
                              stepProps: {
                                values: values,
                                handleChange: handleChange,
                                handleBlur: handleBlur,
                              },
                            },
                            {
                              stepName: "Loan Details",
                              component: LoanDetails,
                              stepProps: {
                                values: values,
                                handleChange: handleChange,
                                handleBlur: handleBlur,
                              },
                            },
                            // ,
                            // {
                            //   stepName: "Vendor Details",
                            //   component: OtherDetails,
                            //   stepProps: {
                            //     values: values,
                            //     handleChange: handleChange,
                            //     handleBlur: handleBlur,
                            //   },
                            // },
                          ]
                        ) : (
                          [
                            {
                              stepName: "Corporate Details",
                              component: CorporateDetails,
                              stepProps: {
                                values: values,
                                handleChange: handleChange,
                                handleBlur: handleBlur,
                              },
                            },
                            {
                              stepName: "Contact Details",
                              component: ContactDetails,
                              stepProps: {
                                values: values,
                                handleChange: handleChange,
                                handleBlur: handleBlur,
                              },
                            },
                            {
                              stepName: "Stakeholder Details",
                              component: StakeholderDetails,
                              stepProps: {
                                values: values,
                                handleChange: handleChange,
                                handleBlur: handleBlur,
                              },
                            },
                            {
                              stepName: "Collateral Details",
                              component: CollateralDetails,
                              stepProps: {
                                values: values,
                                handleChange: handleChange,
                                handleBlur: handleBlur,
                              },
                            },
                            {
                              stepName: "Document Collection",
                              component: DocumentCollection,
                              stepProps: {
                                values: values,
                                handleChange: handleChange,
                                handleBlur: handleBlur,
                              },
                            },
                            {
                              stepName: "Notepad Details",
                              component: Notepad,
                              stepProps: {
                                values: values,
                                handleChange: handleChange,
                                handleBlur: handleBlur,
                              },
                            },
                            {
                              stepName: "Asset Details",
                              component: AssetPropertyDetails,
                              stepProps: {
                                values: values,
                                handleChange: handleChange,
                                handleBlur: handleBlur,
                              },
                            },
                            {
                              stepName: "Property Details",
                              component: PropertyDetails,
                              stepProps: {
                                values: values,
                                handleChange: handleChange,
                                handleBlur: handleBlur,
                              },
                            },
                            {
                              stepName: "Loan Details",
                              component: LoanDetails,
                              stepProps: {
                                values: values,
                                handleChange: handleChange,
                                handleBlur: handleBlur,
                              },
                            },
                            // {
                            //   stepName: "Vendor Details",
                            //   component: OtherDetails,
                            //   stepProps: {
                            //     values: item,
                            //     handleChange: handleChange,
                            //     handleBlur: handleBlur,
                            //   },
                            // }

                          ]
                        )}
                        previousButtonClasses={isSubmitting ? "hide" : ""}
                        finishButtonClasses={isSubmitting ? "hide" : ""}
                        nextButtonClasses={isSubmitting ? "hide" : ""}
                        validate
                        finishButtonText=""
                        navSteps
                        color="blue"
                        finishButtonClick={() => {
                          this.formRef.dispatchEvent(new Event("submit"));
                        }}
                      />
                    </form>
                  )}
                </Formik>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </Fragment >
    );
  }
}

export default DetailedDataEntry;
