import React, { Fragment, useState } from "react";
import mandateService from "../../../../services/_axiosServices/sweep/mandate";
import { Loader, Error } from "../../components";

const initialData = {
  loanAccountInstitution: "--",
  loanAccountNo: "--",
  otherReference: "--",
  bvn: "--",
  repaymentAccount: "--",
  sweepType: "--",
  createdOn: "--",
  generatedOn: "--",
  maxRepaymentExpectedAmount: 0,
  maxRepaymentExpectedCount: 0,
  singleTransactionAmountLimit: 0,
  maxDuration: 0,
  totalAmountDeducted: 0,
  totalNoOfTimesDeducted: 0,
  lastAmountDeducted: 0,
  loanExpirationDate: "--",
  loanTenure: 0,
  lastDeductionDate: "--",
  noOfReversals: 0,
  lastReversalDate: "--",
  thirdPartySweepService: "--",
  thirdPartySweepReference: "--",
  preferredRepaymentBankCBNCode: "--",
  preferredRepaymentAccount: "--",
  repaymentType: "--",
  remitaRequestId: "--",
  secondaryMandates: [
    {
      bvn: "--",
      beneficiaryName: "--",
      loanAccountInstitution: "--",
      name: "--",
      phoneNumber: "--",
      guaranteeingPercentage: 0,
      loanAccountNo: "--",
      totalNoOfTimesDeducted: 0,
      totalAmountDeducted: 0,
      lastAmountDeducted: 0,
      lastDeductionDate: "--",
      noOfReversals: 0,
      lastReversalDate: "--",
      id: 0,
    },
  ],
  status: "--",
  customerEmail: "--",
  customerName: "--",
  phoneNumber: "--",
  id: 0,
};

export default function Report() {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [data, setData] = useState(initialData);
  const [error, setError] = useState(false);

  const fetchData = async () => {
    if (code.length < 1) {
      return;
    }
    setLoading(true);
    await mandateService
      .retrieveByInstitutionCode(code)
      .then((res: any) => {
        // console.log(res);
        setLoading(false);
        setData(res.data);
      })
      .catch((err: any) => {
        setError(true);
        setLoading(false);
        console.log(err.message);
      });
  };

  return (
    <div className="container-fluid relative animatedParent animateOnce">
      <div className="animated fadeInUpShort go">
        <div className="row my-3 mx-2">
          <h3>
            <b>Retrieve By Institution Code</b>
          </h3>
        </div>
        <div className="card no-b">
          <div className="card-body">
            <div className="card-title">
              <div className="page">
                <div className="page-inner">
                  <div className="btn-toolbar justify-content-between">
                    <div className="btn-group">
                      <input
                        name="institutionCode"
                        onChange={(e) => setCode(e.target.value)}
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Institution Code"
                        required
                      />
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={fetchData}
                      >
                        Go
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive text-nowrap">
                    {loading && <Loader />}

                    {!loading && !error && (
                      <table className="table table-hover table-content table-striped table-bordered table-sm paged-output">
                        <thead>
                          <tr>
                            <th>Loan Account Institution</th>
                            <th>Loan Account No</th>
                            <th>Other Reference</th>
                            <th>BVN</th>
                            <th>Repayment Account</th>
                            <th>Sweep Type</th>
                            <th>Created On</th>
                            <th>Generated On</th>
                            <th>Max Repayment Expected Amount</th>
                            <th>Max Repayment Expected Count</th>
                            <th>Single Transaction Amount Limit</th>
                            <th>Max Duration</th>
                            <th>Total Amount Deducted</th>
                            <th>Total No Of Times Deducted</th>
                            <th>Last Amount Deducted</th>
                            <th>Loan Expiration Date</th>
                            <th>Loan Tenure</th>
                            <th>Last Deduction Date</th>
                            <th>No Of Reversals</th>
                            <th>Last Reversal Date</th>
                            <th>Third Party Sweep Service</th>
                            <th>Third Party Sweep Reference</th>
                            <th>Preferred Repayment Bank CBN Code</th>
                            <th>Preferred Repayment Account</th>
                            <th>Repayment Type</th>
                            <th>Remita Request ID</th>
                            <th>Status</th>
                            <th>Customer Email</th>
                            <th>Customer Name</th>
                            <th>Phone Number</th>
                            <th>ID</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{data.loanAccountInstitution}</td>
                            <td>{data.loanAccountNo}</td>
                            <td>{data.otherReference}</td>
                            <td>{data.bvn}</td>
                            <td>{data.repaymentAccount}</td>
                            <td>{data.sweepType}</td>
                            <td>{data.createdOn}</td>
                            <td>{data.generatedOn}</td>
                            <td>{data.maxRepaymentExpectedAmount}</td>
                            <td>{data.maxRepaymentExpectedCount}</td>
                            <td>{data.singleTransactionAmountLimit}</td>
                            <td>{data.maxDuration}</td>
                            <td>{data.totalAmountDeducted}</td>
                            <td>{data.totalNoOfTimesDeducted}</td>
                            <td>{data.lastAmountDeducted}</td>
                            <td>{data.loanExpirationDate}</td>
                            <td>{data.loanTenure}</td>
                            <td>{data.lastDeductionDate}</td>
                            <td>{data.noOfReversals}</td>
                            <td>{data.lastReversalDate}</td>
                            <td>{data.thirdPartySweepService}</td>
                            <td>{data.thirdPartySweepReference}</td>
                            <td>{data.preferredRepaymentBankCBNCode}</td>
                            <td>{data.preferredRepaymentAccount}</td>
                            <td>{data.repaymentType}</td>
                            <td>{data.remitaRequestId}</td>
                            <td>{data.status}</td>
                            <td>{data.customerEmail}</td>
                            <td>{data.customerName}</td>
                            <td>{data.phoneNumber}</td>
                            <td>{data.id}</td>
                          </tr>
                        </tbody>
                      </table>
                    )}

                    {error && !loading && <Error />}
                  </div>

                  {!loading && !error && (
                    <Fragment>
                      <br />
                      <h3>Secondary Mandates</h3>
                      <div className="table-responsive text-nowrap">
                        <table className="table table-hover table-content table-striped table-bordered table-sm paged-output">
                          <thead>
                            <tr>
                              <th>BVN</th>
                              <th>Beneficiary Name</th>
                              <th>Loan Account Institution</th>
                              <th>Name</th>
                              <th>Phone Number</th>
                              <th>Guaranteeing Percentage</th>
                              <th>Loan Account No</th>
                              <th>Total No Of Times Deducted</th>
                              <th>Total Amount Deducted</th>
                              <th>Last Amount Deducted</th>
                              <th>Last Deduction Date</th>
                              <th>No Of Reversals</th>
                              <th>Last Reversal Date</th>
                              <th>id</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.secondaryMandates?.map(
                              (m: any, i: number) => (
                                <tr key={i}>
                                  <td>{m.bvn}</td>
                                  <td>{m.beneficiaryName}</td>
                                  <td>{m.loanAccountInstitution}</td>
                                  <td>{m.name}</td>
                                  <td>{m.phoneNumber}</td>
                                  <td>{m.guaranteeingPercentage}</td>
                                  <td>{m.loanAccountNo}</td>
                                  <td>{m.totalNoOfTimesDeducted}</td>
                                  <td>{m.totalAmountDeducted}</td>
                                  <td>{m.lastAmountDeducted}</td>
                                  <td>{m.lastDeductionDate}</td>
                                  <td>{m.noOfReversals}</td>
                                  <td>{m.lastReversalDate}</td>
                                  <td>{m.id}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
