import React, { Component, Fragment, useContext } from "react";
import {
    Modal,
    Button,
    Card,
    useAccordionToggle,
    AccordionContext,
    Accordion,
} from "react-bootstrap";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import BaseFormComponent, { IBaseFormState } from "../../../../components/BaseFormComponent";
import textFormatService, { generateGUID } from "../../../../services/utility/textFormatService";
import ItemsTable, { filterFieldData } from "../../../../components/tableComponents/itemsTable";
import TableFilterPane from "../../../../components/tableComponents/tableFilterPane";
import { getCurrentUserSession } from "../../../../services/auth/authService";
// import TinRequestDetails from "../../../../loanAppraisal/appraisalDetailPages/TinRequestDetails";
// import { ContextAwareToggle } from "./documentCollection";
import { decodeToken } from "react-jwt";
import BaseListComponent, { IFilterData } from "../../../../components/BaseListComponent";
import loanProductService from "../../../../services/hybridAppraisal/loanProductService";
import loanRequestService from "../../../../services/hybridAppraisal/loanRequestService";
import { createFilterData, mapEntityList } from "../../../../services/utility/autoMapperService";
import TinValidationListModal from "./tinValidationList";
import groupLoanRequestMembersService from "../../../../services/hybridAppraisal/groupLoanRequestMembersService";
import { ContextAwareToggle } from "./creditSummary";

interface TinValidationFields { }

class TinValidationForm extends BaseFormComponent<TinValidationFields> { }

interface ITinValidationModalProps {
    item: any;
}

interface ITinValidationModalState {
    item: any;
    updatingStatus: boolean;
    submitting: boolean;
    data: IFilterData;
    currentPage: number;
    pageSize: number;
    tableItems: any;
    fetching: boolean;
    fetchingDetail: boolean;
    totalSize: number;
    filterValueInput: filterFieldData;
    userRoles: any;
    systemProcess: any;
    applicableRules: any;
    appraisalActions: any;
    updateComponent: boolean;
    documentsToUpload: any;
    documentsToDelete: number[];
    canEditItem: boolean;
    updateAvailable: boolean;
    processResponse: any;
    specialConditions: any;
    ownUpdate: boolean;
    showTinValidation: boolean;
    solidarityGroups: any;
    tinRequests: any;
    members: any;
}

class TinValidationModal extends BaseListComponent<ITinValidationModalState> { }

class TinValidation extends React.Component<
    ITinValidationModalProps,
    IBaseFormState & ITinValidationModalState
> {
    _isMounted = false;
    constructor(props: ITinValidationModalProps) {
        super(props);
        this.state = {
            validated: false,
            submitting: false,
            errors: {},
            item: {},
            updatingStatus: false,
            data: createFilterData(["customerName", "loanType"]) as IFilterData,
            currentPage: 1,
            pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
            fetching: false,
            fetchingDetail: false,
            tableItems: [],
            totalSize: 0,
            filterValueInput: [
                { name: "customerName", fieldType: "text", value: "" },
                {
                    name: "loanType",
                    fieldType: "select",
                    options: ["Individual", "Corporate"],
                    value: "",
                }
            ],
            userRoles: [],
            systemProcess: [],
            applicableRules: [],
            appraisalActions: [],
            documentsToUpload: [],
            documentsToDelete: [],
            updateComponent: false,
            canEditItem: false,
            updateAvailable: false,
            specialConditions: [],
            processResponse: {
                actionId: 0,
                score: 0,
                response: ""
            },
            ownUpdate: false,
            showTinValidation: false,
            solidarityGroups: [],
            tinRequests: [],
            members: []
        };
    }

    resetFields() {
        this.setState({
            validated: false,
            submitting: false
        });
    }
    async componentDidMount() {
        this._isMounted = true;
        this.handlePageChange(this.state.currentPage, this.state.pageSize);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    handlePageChange = async (currentPage: number, pageSize: number, data?: IFilterData) => {
        currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
        pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
        if (this._isMounted) {
            this.setState({ fetching: true, ownUpdate: true });
        }
        let dataClone = typeof data === "undefined" ? { ...this.state.data } : { ...data };
        const decodedToken = decodeToken(getCurrentUserSession().token || "");
        dataClone["InstitutionCode"] = { item1: "=", item2: decodedToken?.["InstitutionCode"] }
        dataClone["TIN"] = { item1: "=", item2: this.props.item.tin }
        try {
            // console.log("tin", this.props.item)
            // console.log("tin", this.props.item.tin)
            // const loanRequests = await loanRequestService.retrieveAllByTin(this.props.item.tin, decodedToken?.["InstitutionCode"])
            const response = await Promise.allSettled([
                groupLoanRequestMembersService.RetrieveTINValidation(this.props.item?.id),
            ])
            // console.log(response)
            const tinRequests = response[0].status == "fulfilled" ? response[0].value.data : [];


            // console.log(tinRequests)
            const appraisalActions = this.props.item?.appraisalActions;
            const solidarityGroups = this.props.item?.solidarityGroups;

            if (solidarityGroups) {
                for (let index = 0; index < solidarityGroups.length; index++) {
                    if (solidarityGroups[index].logType !== "SystemType") {
                        solidarityGroups[index].memberCount = solidarityGroups[index].members.length;
                        solidarityGroups[index].matchedMembers = tinRequests ? tinRequests?.filter(x => x.solidarityGroupName === solidarityGroups[index].solidarityGroupName && x.loanDetails.length > 0)?.length : 0
                        solidarityGroups[index].NoMatchMembers = Number(solidarityGroups[index].memberCount - solidarityGroups[index].matchedMembers)

                    }


                    // this.setState({ solidarityGroups, ownUpdate: true });

                }
                // console.log(solidarityGroups)
                if (this._isMounted) {
                    this.setState({
                        tableItems: mapEntityList({
                            entityList: solidarityGroups,
                            properties: [

                                { oldName: "memberCount", newName: "totalNoOfCustomers" },
                                { oldName: "matchedMembers", newName: "matchedCustomers" },
                                { oldName: "NoMatchMembers", newName: "noMatchCustomers" },
                                { oldName: "solidarityGroupName", newName: "solidarityGroups" },
                            ],
                        }),
                        currentPage,
                        pageSize,
                        totalSize: solidarityGroups.length,
                        fetching: false,
                        ownUpdate: true,
                        solidarityGroups: solidarityGroups,
                        tinRequests: tinRequests
                    });
                }
            }
            // console.log(this.state.solidarityGroups)
        } catch (ex) {
            if (ex.response && ex.response.status >= 400 && ex.response.status <= 499) {
                if (this._isMounted) {
                    this.setState({ fetching: false, ownUpdate: true });
                }
            } else {
                if (this._isMounted) {
                    this.setState({ fetching: false, ownUpdate: true });
                }
            }
        } finally {
            if (this._isMounted) {
                this.setState({ fetching: false, ownUpdate: true });
            }
        }
    };
    getFilteredItems = (data: IFilterData): IFilterData => {
        let filteredItems: IFilterData = {};
        Object.keys(data).forEach((key) => {
            if (data[key].item2 !== "") {
                filteredItems[key] = data[key];
            }
        });
        return filteredItems;
    };

    fetchItemById = (name) => {
        var members = this.state.tinRequests?.filter(x => x.solidarityGroupName === name);
        this.setState({ members: members });
    }

    toggleShowTinValidationModal = () => {
        this.setState({ showTinValidation: !this.state.showTinValidation });
    };

    handleOnExport = async (type: string) => { };

    render() {
        const { item } = this.props;
        const {
            currentPage,
            pageSize,
            tableItems,
            totalSize,
            fetching,
            fetchingDetail,
            submitting,
            solidarityGroups
        } = this.state;

        if (_.isEmpty(item)) {
            return null;
        }

        // return (
        //     <TinValidationForm
        //         initialValues={{}}
        //         FormComponent={({
        //             fields: { }, onChange, onReloadFields, onHandleSubmit,
        //         }) => (
        //             <React.Fragment>
        //                 <div className="card">
        //                     <div className="card-header clear-fix">
        //                         {_.isEmpty(item) ? (
        //                             <Skeleton width={200} />
        //                         ) : (
        //                             <h6
        //                                 className="card-title float-left"
        //                                 style={{ marginBottom: "0px" }}
        //                             >
        //                                 <b>Tin Validation</b>
        //                             </h6>
        //                         )}
        //                     </div>
        //                     <div className="card">
        //                         <Accordion defaultActiveKey="0">
        //                             <div>
        //                                 <Card>
        //                                     {/* <ContextAwareToggle
        //                     eventKey={`0`}
        //                     level={""}
        //                 ></ContextAwareToggle> */}
        //                                     <Accordion.Collapse eventKey={`0`}>
        //                                         <Card.Body>
        //                                             <TinValidationModal
        //                                                 pageChangeHandler={this.handlePageChange}
        //                                                 fetchingDetail={fetchingDetail}
        //                                                 initialValues={{
        //                                                     data: this.state.data,
        //                                                     fetching: false,
        //                                                     showDetailsModal: false,
        //                                                     showEditModal: false,
        //                                                     showTableFilterModal: false,
        //                                                     showTrailModal: false,
        //                                                     tableItems: [],
        //                                                     filterValueInput: this.state.filterValueInput,
        //                                                     item: {},
        //                                                 }}
        //                                                 tableItems={tableItems}
        //                                                 item={solidarityGroups}
        //                                                 DetailsModal={({
        //                                                     item, onToggleDetailsModal, onToggleEditModal, showDetailsModal, showEditModal
        //                                                 }) => (
        //                                                     <Fragment>
        // <TinValidationListModal
        //     item={this.state.item}
        //     members={this.state.members}
        //     showPatchModal={this.state.showTinValidation}
        //     togglePatchModal={this.toggleShowTinValidationModal}
        // />
        //                                                     </Fragment>

        //                                                 )}
        //                                                 ListPayLoad={({
        //                                                     data, showTableFilterModal, tableItems, onToggleDetailsModal, onToggleTableFilterModal, onHandleFilterItemRemove, filterValueInput,
        //                                                 }) => (
        //                                                     <div className="row">
        //                                                         <div className="col-md-12">
        //                                                             <div className="card no-b">
        //                                                                 <div className="card-body">
        //                                                                     <div className="card-title">
        //                                                                         <div id="list-filters" className="ml-4 mr-4">
        //                                                                             <TableFilterPane
        //                                                                                 filteredItems={this.getFilteredItems(data)}
        //                                                                                 handleFilterRemove={onHandleFilterItemRemove} />
        //                                                                         </div>
        //                                                                         <div id="list-table">
        //                                                                             <ItemsTable
        //                                                                                 data={data}
        //                                                                                 items={tableItems}
        //                                                                                 filterValueInput={filterValueInput}
        //                                                                                 showTableFiltermodal={showTableFilterModal}
        //                                                                                 toggleTableFiltermodal={onToggleTableFilterModal}
        //                                                                                 name="Tin Validations"
        //                                                                                 currentPage={currentPage}
        //                                                                                 pageSize={pageSize}
        //                                                                                 totalSize={totalSize}
        //                                                                                 fetching={fetching}
        //                                                                                 handlePageChange={this.handlePageChange}
        //                                                                                 onViewDetails={(item: any) => {
        // this.fetchItemById(item.solidarityGroups)
        // this.toggleShowTinValidationModal();
        //                                                                                 }}
        //                                                                                 handleOnExport={this.handleOnExport}
        //                                                                                 timeColumn={["dateCreated"]}
        //                                                                                 amountColumn={["loanAmount"]}
        //                                                                             />
        //                                                                         </div>
        //                                                                     </div>
        //                                                                 </div>
        //                                                             </div>
        //                                                         </div>
        //                                                     </div>
        //                                                 )}
        //                                             ></TinValidationModal>

        //                                         </Card.Body>
        //                                     </Accordion.Collapse>
        //                                 </Card>
        //                             </div>
        //                         </Accordion>
        //                     </div>
        //                 </div>

        //             </React.Fragment>
        //         )}
        //     ></TinValidationForm>
        // );

        return (
            <TinValidationForm
                initialValues={{}}
                FormComponent={({
                    fields: { }, onChange, onReloadFields, onHandleSubmit,
                }) => (
                    <div className="card">
                        <div className="card-header clear-fix">
                            {_.isEmpty(item) ? (
                                <Skeleton width={200} />
                            ) : (
                                <h6
                                    className="card-title float-left"
                                    style={{ marginBottom: "0px" }}
                                >
                                    <b>Tin Validation</b>
                                </h6>
                            )}
                        </div>
                        <div className="card">
                            <Accordion defaultActiveKey="0">
                                <div>
                                    <Card>
                                        <ContextAwareToggle
                                            eventKey={`0`}
                                            header={<React.Fragment>
                                                <i
                                                    className="fa fa-info-circle"
                                                    aria-hidden="true"
                                                    style={{ color: "#878a8a" }}
                                                ></i>{" "}
                                                Tin Validation
                                            </React.Fragment>}
                                        ></ContextAwareToggle>
                                        <Accordion.Collapse eventKey={`0`}>
                                            <Card.Body>
                                                <div
                                                    className="table-responsive text-nowrap"
                                                    style={{
                                                        height: "300px",
                                                        overflowY: "scroll",
                                                    }}
                                                >

                                                    {/* <h6 className="center">Appraisal Actions</h6> */}
                                                    <table className="table table-hover table-content table-sm table-striped mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th
                                                                    scope="col"
                                                                    style={{
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                    }}
                                                                >
                                                                    <strong>Total Members</strong>
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    style={{
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                    }}
                                                                >
                                                                    <strong>Matched Customers</strong>
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    style={{
                                                                        // width: "5px",
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                    }}
                                                                >
                                                                    <strong>No-Match Customers</strong>
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    style={{
                                                                        // width: "5px",
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                    }}
                                                                >
                                                                    <strong></strong>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {/* {recipients.length === 0 ? ( */}
                                                            {item.appraisalActions.length === 0 ? (
                                                                <React.Fragment>
                                                                    <tr>
                                                                        No Decisions Made yet
                                                                    </tr>
                                                                </React.Fragment>
                                                            ) : (
                                                                item.solidarityGroups.map((groups) => (
                                                                    <React.Fragment key={generateGUID()}>
                                                                        <tr>
                                                                            <td
                                                                                style={{
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                    textAlign: "center"
                                                                                }}
                                                                            >
                                                                                {groups.memberCount}
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                    textAlign: "center"
                                                                                }}
                                                                            >
                                                                                {Number(groups.matchedMembers)}
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                    textAlign: "center"
                                                                                }}
                                                                            >
                                                                                {Number(groups.NoMatchMembers)}
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                }}
                                                                            >
                                                                                <button
                                                                                    key={groups.id}
                                                                                    className="btn btn-sm btn-outline-primary my-2 mx-3"
                                                                                    style={{ cursor: "pointer" }}
                                                                                    type="button"
                                                                                    // onClick={this.handleUploadDocument}
                                                                                    onClick={(e) => {
                                                                                        this.fetchItemById(groups.solidarityGroupName);
                                                                                        this.toggleShowTinValidationModal();
                                                                                    }}
                                                                                >
                                                                                    <React.Fragment>
                                                                                        <i className="fas fa-users fa-lg mr-2" />{" "}
                                                                                        {groups.solidarityGroupName}
                                                                                    </React.Fragment>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                        {/* ))} */}
                                                                    </React.Fragment>
                                                                ))
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </div>
                                {/* {console.log(this.state.showTinValidation)} */}
                            </Accordion>
                        </div>
                        <TinValidationListModal
                            item={item}
                            members={this.state.members}
                            showPatchModal={this.state.showTinValidation}
                            togglePatchModal={this.toggleShowTinValidationModal}
                        />
                    </div>

                )}
            ></TinValidationForm>
        );
    }
}


export default TinValidation;
