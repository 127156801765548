import React, { useState } from "react";
import feeService from "../../../../services/_axiosServices/sweep/fee";
import { Loader, Error } from "../../components";

const initialData = {
  institutionId: "--",
  institutionCode: "--",
  activeLoansPercentage: "--",
  lostLoansPercentage: "--",
  flatAmount: "--",
  minimumFee: "--",
  maximumFee: "--",
  sponsorBankCommission: "--",
  debitedBankCommission: "--",
  repaymentType: "--",
  id: "--",
};

export default function Report() {
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [data, setData] = useState(initialData);
  const [error, setError] = useState(false);

  const fetchData = async () => {
    if (id.length < 1) {
      setData(initialData);
      return;
    }
    setLoading(true);
    await feeService
      .retrieveByInstitutionID(id)
      .then((res: any) => {
        // console.log(res);
        setLoading(false);
        setData(res.data);
      })
      .catch((err: any) => {
        setError(false);
        setLoading(false);
        console.log(err.message);
      });
  };

  return (
    <div className="container-fluid relative animatedParent animateOnce">
      <div className="animated fadeInUpShort go">
        <div className="row my-3 mx-2">
          <h3>
            <b>Fee - Find By Institution ID</b>
          </h3>
        </div>
        <div className="card no-b">
          <div className="card-body">
            <div className="card-title">
              <div className="page">
                <div className="page-inner">
                  <div className="btn-toolbar justify-content-between">
                    <div className="btn-group">
                      <input
                        name="id"
                        onChange={(e) => setId(e.target.value)}
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="ID"
                        required
                      />
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={fetchData}
                      >
                        Find
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive text-nowrap">
                    {loading && <Loader />}

                    {!loading && !error && (
                      <table className="table table-hover table-content table-striped table-bordered table-sm paged-output">
                        <thead>
                          <tr>
                            <th>Institution ID</th>
                            <th>Institution Code</th>
                            <th>Active Loans Percentage</th>
                            <th>Lost Loans Percentage</th>
                            <th>Flat Amount</th>
                            <th>Minimum Fee</th>
                            <th>Maximum Fee</th>
                            <th>Sponsor Bank Commission</th>
                            <th>Debited Bank Commission</th>
                            <th>Repayment Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{data.institutionId}</td>
                            <td>{data.institutionCode}</td>
                            <td>{data.activeLoansPercentage}</td>
                            <td>{data.lostLoansPercentage}</td>
                            <td>{data.flatAmount}</td>
                            <td>{data.minimumFee}</td>
                            <td>{data.maximumFee}</td>
                            <td>{data.sponsorBankCommission}</td>
                            <td>{data.debitedBankCommission}</td>
                            <td>{data.repaymentType}</td>
                          </tr>
                        </tbody>
                      </table>
                    )}

                    {error && !loading && <Error />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
