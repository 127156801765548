import * as React from "react";
import _ from "lodash";
import { Fragment } from "react";
import {
    IFilterData,
    BaseListComponentState,
} from "../../../components/BaseListComponent";
import {
    createFilterData,
    mapEntityList,
} from "../../../services/utility/autoMapperService";
import BaseListComponent from "../../../components/BaseListComponent";
import ItemsTable, {
    filterFieldData,
} from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "../../../components/tableComponents/tableFilterPane";
import workFlowConfigService from "../../../services/hybridAppraisal/workFlowConfigService";
import WorkFlowConfigDetail from "./workFlowConfigDetail";
import EditWorkFlowConfig from "./editWorkFlowConfig";
import supplierService from "../../../services/hybridAppraisal/supplierService";
import SupplierDetail from "./supplierDetails";
import EditSupplierDetails from "./editSupplierDetails";
import appraisalInstitutionService from "../../../services/hybridAppraisal/appraisalInstitutionService";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../../services/auth/authService";
import EmployerListDetails from "./employerListDetails";

export interface EmployerListProps { }

export interface EmployerListState extends BaseListComponentState {
    data: IFilterData;
    currentPage: number;
    pageSize: number;
    tableItems: any;
    totalSize: number;
    fetchingDetail: boolean;
    filterValueInput: filterFieldData;
    item: any;
}

class EmployerListList extends BaseListComponent<EmployerListState> { }

class EmployerList extends React.Component<EmployerListProps, EmployerListState> {
    constructor(props: EmployerListProps) {
        super(props);
        this.state = {
            data: createFilterData([
                "name",
            ]) as IFilterData,
            currentPage: 1,
            pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
            fetching: false,
            fetchingDetail: false,
            tableItems: [],
            totalSize: 0,
            filterValueInput: [
                { name: "name", fieldType: "text", value: "" },
            ],
            item: {},
            showDetailsModal: false,
            showEditModal: false,
            showTableFilterModal: false,
            showTrailModal: false
        };
    }

    handlePageChange = async (currentPage: number, pageSize: number, data?: IFilterData) => {
        currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
        pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
        const decodedToken = decodeToken(getCurrentUserSession().token || "");
        this.setState({ fetching: true });
        let dataClone =
            typeof data === "undefined" ? { ...this.state.data } : { ...data };
        dataClone["Code"] = { item1: "=", item2: decodedToken?.["InstitutionCode"] }
        try {
            const employerList = await appraisalInstitutionService.filterInstitution(
                dataClone,
                pageSize,
                currentPage
            );



            var employers = JSON.parse(employerList.data.item2[0]?.dropDownItems)?.filter(x => x.FieldName === "Employer Names")[0]?.Values
            console.log(employers)
            this.setState({
                tableItems: mapEntityList({
                    entityList: employers,
                    properties: [
                        { oldName: "Name", newName: "employerName" },
                        { oldName: "Country", newName: "Country" },
                        { oldName: "PhoneNumber", newName: "PhoneNumber" },
                        { oldName: "State", newName: "State" },
                        { oldName: "Email", newName: "Email" },
                    ],
                }),
                currentPage,
                pageSize,
                totalSize: employers.length,
                fetching: false,
            });
        } catch (ex) {
            if (
                ex.response &&
                ex.response.status >= 400 &&
                ex.response.status <= 499
            ) {
                this.setState({ fetching: false });
            } else {
                this.setState({ fetching: false });
            }
        } finally {
            this.setState({ fetching: false });
        }
    };

    handleOnExport = async (type: string) => { };

    fetchItemById = async (item: any) => {
        if (!_.isEmpty(item)) {
            this.setState({ item: item, fetchingDetail: false });
        }
    };

    getFilteredItems = (data: IFilterData): IFilterData => {
        let filteredItems: IFilterData = {};
        Object.keys(data).forEach((key) => {
            if (data[key].item2 !== "") {
                filteredItems[key] = data[key];
            }
        });
        return filteredItems;
    };

    async componentDidMount() {
        this.handlePageChange(this.state.currentPage, this.state.pageSize);
    }

    render() {
        const {
            currentPage,
            pageSize,
            tableItems,
            totalSize,
            fetching,
            item,
            fetchingDetail,
        } = this.state;
        return (
            <Fragment>
                <div className="container-fluid relative animatedParent animateOnce">
                    <div className="animated fadeInUpShort go">
                        <div className="row my-3 mx-2">
                            <h3>
                                <b>EmployerList</b>
                            </h3>
                        </div>
                        <EmployerListList
                            pageChangeHandler={this.handlePageChange}
                            fetchingDetail={fetchingDetail}
                            initialValues={{
                                data: this.state.data,
                                fetching: false,
                                showDetailsModal: false,
                                showEditModal: false,
                                showTableFilterModal: false,
                                showTrailModal: false,
                                tableItems: [],
                                filterValueInput: this.state.filterValueInput,
                                item: {},
                            }}
                            tableItems={tableItems}
                            item={item}
                            DetailsModal={({
                                item,
                                onToggleDetailsModal,
                                onToggleEditModal,
                                showDetailsModal,
                                showEditModal,
                            }) => (
                                <Fragment>
                                    <EmployerListDetails
                                        showDetailsModal={showDetailsModal}
                                        toggleDetailsModal={onToggleDetailsModal}
                                        toggleEditModal={onToggleEditModal}
                                        showTrail={null}
                                        item={item}
                                    />
                                    {/* <EditSupplierDetails
                                        showEditModal={showEditModal}
                                        toggleEditModal={(reloadDetails: boolean, item: any) => {
                                            onToggleEditModal();
                                            if (reloadDetails === true) {
                                                this.fetchItemById(item);
                                            }
                                        }}
                                        item={item}
                                    /> */}
                                </Fragment>
                            )}
                            ListPayLoad={({
                                data,
                                showTableFilterModal,
                                tableItems,
                                onToggleDetailsModal,
                                onToggleTableFilterModal,
                                onHandleFilterItemRemove,
                                filterValueInput,
                            }) => (
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card no-b">
                                            <div className="card-body">
                                                <div className="card-title">
                                                    <div id="list-filters" className="ml-4 mr-4">
                                                        <TableFilterPane
                                                            filteredItems={this.getFilteredItems(data)}
                                                            handleFilterRemove={onHandleFilterItemRemove}
                                                        />
                                                    </div>
                                                    <div id="list-table">
                                                        <ItemsTable
                                                            data={data}
                                                            items={tableItems}
                                                            filterValueInput={filterValueInput}
                                                            showTableFiltermodal={showTableFilterModal}
                                                            toggleTableFiltermodal={onToggleTableFilterModal}
                                                            name="EmployerList"
                                                            currentPage={currentPage}
                                                            pageSize={pageSize}
                                                            totalSize={totalSize}
                                                            fetching={fetching}
                                                            handlePageChange={this.handlePageChange}
                                                            onViewDetails={(item: any) => {
                                                                this.fetchItemById(item);
                                                                onToggleDetailsModal();
                                                            }}
                                                            handleOnExport={this.handleOnExport}
                                                            timeColumn={["dateCreated"]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        ></EmployerListList>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default EmployerList;
