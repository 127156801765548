import * as React from "react";
import { firstCharToUpper, splitAtUpper } from "../services/utility/textFormatService";
import _ from "lodash";

export interface ISelectItems {
    name: string;
    value: any;
}
export interface ISelectInputComponentProps {
    id: string;
    name: string;
    label?: string;
    items: ISelectItems[];
    divClass?: number;
    labelIconClass?: string;
    value: string;
    required?: boolean;
    validated: boolean;
    onBlur?: any;
    onChange: any;
    onChangeCallBack?: any;
    errors: any;
    disabled?: boolean;
    disabledOption?: any;
    isFormikInput?: boolean;
}

export interface ISelectInputComponentState {}

class SelectInputComponent extends React.Component<ISelectInputComponentProps, ISelectInputComponentState> {
    static defaultProps = {
        required: false,
        disabled: false,
        isFormikInput: false,
    };
    render() {
        const {
            id,
            items,
            name,
            label,
            divClass,
            value,
            required,
            validated,
            errors,
            onBlur,
            onChange,
            onChangeCallBack,
            labelIconClass,
            disabled,
            disabledOption,
            isFormikInput,
        } = this.props;
        return (
            <div className={`form-group col${divClass ? "-md-" + divClass : ""} m-0`}>
                <label htmlFor={label ?? name} className="mr-2">
                    {required && (
                        <span aria-hidden="true" className="mr-2" style={{ color: "#ed5564" }}>
                            *
                        </span>
                    )}
                    {labelIconClass ? <i className={labelIconClass}></i> : ""}
                    {label ?? firstCharToUpper(splitAtUpper(name))}
                </label>
                <select
                    id={id}
                    name={name}
                    className={
                        !validated
                            ? "custom-select select2 mr-sm-2 form-control r-0 light s-12"
                            : errors[id]
                            ? "custom-select select2 mr-sm-2 form-control r-0 light s-12 is-invalid"
                            : "custom-select select2 mr-sm-2 form-control r-0 light s-12 is-valid"
                    }
                    required={required}
                    value={_.isNull(value) ? "" : value}
                    disabled={disabled}
                    onChange={async (e) => {
                        let changeValue = e.currentTarget.value;
                        isFormikInput ? await onChange(e) : await onChange(id, e.currentTarget.value);
                        if (onChangeCallBack) {
                            onChangeCallBack(id, changeValue);
                        }
                    }}
                    onBlur={onBlur ? (e) => onBlur(e) : onBlur}
                >
                    <option value="">{`-Select ${label ?? firstCharToUpper(splitAtUpper(name))} -`}</option>

                    {disabledOption
                        ? items.map((item) => (
                              <option key={item.value} value={item.value} disabled={disabledOption(item.value)}>
                                  {item.name}
                              </option>
                          ))
                        : items.map((item) => (
                              <option key={item.value} value={item.value}>
                                  {item.name}
                              </option>
                          ))}
                </select>
                <div
                    className="invalid-feedback"
                    style={{
                        visibility: errors[id] ? "visible" : "hidden",
                        display: errors[id] ? "block" : "none",
                    }}
                >
                    {errors[id]}​​​
                </div>
            </div>
        );
    }
}

export default SelectInputComponent;
