import React from "react";
import { Modal } from "react-bootstrap";
// import _ from "lodash";
import { filterFieldData, IFilterField } from "./itemsTable";
import SelectBox from "../inputComponents/selectBox";
import { ISelectItems } from "./../inputComponents/selectBox";
import TextBox from "./../inputComponents/textBox";
import {
  splitAtUpper,
  firstCharToUpper,
} from "./../../services/utility/textFormatService";

export interface ITableFilterProps {
  filterFields: filterFieldData;
  showTableFiltermodal: boolean;
  toggleTableFiltermodal: any;
}
interface ITableFilterState {
  fieldData: string[];
  selectedField: IFilterField;
  fromDate: string;
  toDate: string;
}
class TableFiltermodal extends React.Component<
  ITableFilterProps,
  ITableFilterState
> {
  constructor(props: ITableFilterProps) {
    super(props);
    this.state = {
      fieldData: ["", "=", ""],
      selectedField: {
        name: "",
        fieldType: "text",
        value: "",
      },
      fromDate: "",
      toDate: "",
    };
  }

  componentDidMount() {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //As January is 0.
    let yyyy = today.getFullYear();
    let todayString: string = `${yyyy}-`;
    todayString += mm < 10 ? `0${mm}-` : `${mm}-`;
    todayString += dd < 10 ? `0${dd}` : `${dd}`;
    this.setState({ fromDate: todayString, toDate: todayString });
  }

  handleOnFilterInputChange = (id: string, value: any) => {
    let data = [...this.state.fieldData];
    if (id === "filter-item-name") {
      data[0] = value;
      const { filterFields } = this.props;
      let field = { ...this.state.selectedField };
      field = filterFields.filter(
        (filterField) => filterField.name === value
      )[0];
      data[2] = field.value.toString();
      if (field.fieldType === "date") {
        data[1] = "between";
        data[2] = `'${this.state.fromDate} 00:00:00' and '${this.state.toDate} 23:59:59'`;
      } else {
        data[1] = "=";
      }
      this.setState({ selectedField: field });
    } else if (id === "filter-item-relate") {
      data[1] = value;
    } else {
      data[2] = value;
    }
    this.setState({ fieldData: data });
  };
  handleOnDateFilterInputChange = (
    id: string,
    value: any,
    dateField: string
  ) => {
    if (value !== "") {
      let data = [...this.state.fieldData];
      if (dateField === "from") {
        data[2] = `'${value} 00:00:00' and '${this.state.toDate} 23:59:59'`;
        this.setState({ fromDate: value, fieldData: data });
      } else {
        data[2] = `'${this.state.fromDate} 00:00:00' and '${value} 23:59:59'`;
        this.setState({ toDate: value, fieldData: data });
      }
    }
  };

  render() {
    const { filterFields, showTableFiltermodal, toggleTableFiltermodal } =
      this.props;
    const { fieldData, selectedField, fromDate, toDate } = this.state;
    // create header select items
    let headers: ISelectItems[] = [];
    filterFields.forEach((filterField) => {
      headers.push({
        name: firstCharToUpper(splitAtUpper(filterField.name)),
        value: filterField.name,
      });
    });

    const relations: ISelectItems[] = [
      { name: "Is", value: "=" },
      { name: "Like", value: "like" },
    ];

    let selectedOptions: ISelectItems[] = [];
    if (selectedField && typeof selectedField.options !== "undefined") {
      selectedField.options.forEach((option) =>
        selectedOptions.push({ name: option, value: option })
      );
    }

    return (
      <Modal
        size="lg"
        backdrop="static"
        show={showTableFiltermodal}
        onHide={toggleTableFiltermodal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-tasks mr-3"></i>
              Filter List
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-row">
            <div className="form-group col-md-5 m-0">
              <SelectBox
                id="filter-item-name"
                items={headers}
                name="Header"
                required={true}
                value={fieldData[0]}
                handleOnChange={this.handleOnFilterInputChange}
              />
              {/* <select
                className="custom-select select2 mr-sm-2 form-control r-0 light s-13"
                id="filter-item-name"
                onChange={() => console.log("changed")}
              >
                <option selected>-- Select Header --</option>
                {/* ${filterFields} */}
              {/* </select> */}
            </div>
            {fieldData[0] !== "" ? (
              <React.Fragment>
                <div className="form-group col-md-2 m-0">
                  {selectedField.fieldType !== "date" ? (
                    <SelectBox
                      id="filter-item-relate"
                      items={relations}
                      name=""
                      required={true}
                      value={fieldData[1]}
                      handleOnChange={this.handleOnFilterInputChange}
                    />
                  ) : (
                    <React.Fragment>
                      <TextBox
                        id="from"
                        type="text"
                        name=""
                        value="From"
                        placeholder=""
                        required={true}
                        classNameString="form-control r-0 light s-13"
                        onChange={(id, val) => {}}
                      />
                      <TextBox
                        id="to"
                        type="text"
                        name=""
                        value="To"
                        placeholder=""
                        required={true}
                        classNameString="form-control r-0 light s-13"
                        onChange={(id, val) => {}}
                      />
                    </React.Fragment>
                  )}
                </div>
                <div className="form-group col-md-5 m-0">
                  {selectedField.fieldType === "select" ? (
                    <SelectBox
                      id="filter-item-value"
                      items={selectedOptions}
                      name={selectedField.name}
                      required={true}
                      value={fieldData[2]}
                      handleOnChange={this.handleOnFilterInputChange}
                    />
                  ) : selectedField.fieldType === "date" ? (
                    <React.Fragment>
                      <input
                        type={"date"}
                        className="date-time-picker form-control"
                        value={fromDate}
                        id="filter-item-value"
                        onChange={(e) =>
                          this.handleOnDateFilterInputChange(
                            e.currentTarget.id,
                            e.currentTarget.value,
                            "from"
                          )
                        }
                      />
                      <input
                        type={"date"}
                        className="date-time-picker form-control"
                        value={toDate}
                        id="filter-item-value"
                        onChange={(e) =>
                          this.handleOnDateFilterInputChange(
                            e.currentTarget.id,
                            e.currentTarget.value,
                            "to"
                          )
                        }
                      />
                    </React.Fragment>
                  ) : (
                    <TextBox
                      id="filter-item-value"
                      type={selectedField.fieldType}
                      name={selectedField.name}
                      placeholder={`Enter ${firstCharToUpper(
                        splitAtUpper(selectedField.name)
                      )}`}
                      value={fieldData[2]}
                      required={true}
                      classNameString="form-control r-0 light s-13"
                      onChange={this.handleOnFilterInputChange}
                    />
                  )}
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => toggleTableFiltermodal(fieldData, true)}
          >
            Filter
          </button>
          <button
            type="button"
            className="btn btn-danger btn-sm"
            onClick={() => toggleTableFiltermodal([], false)}
          >
            {" "}
            <i className="fas fa-times mr-3" /> Close
          </button>

          {/* <Button
            onClick={() =>
              toggleTableFiltermodal(
                data.userRoleId,
                data.combinationNo,
                data.level,
                data.applicableAmount,
                false
              )
            }
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>
    );
  }
}
export default TableFiltermodal;
