import React from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Button, Modal } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";

export interface ISystemLoanProductDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  currentUserRoles: any;
  item: any;
}

export interface ISystemLoanProductDetailState {
  // item: any;
}

class SystemLoanProductDetail extends React.Component<
  ISystemLoanProductDetailProps,
  ISystemLoanProductDetailState
> {
  // constructor(props: ISystemLoanProductDetailProps) {
  //     super(props);
  //     this.state = {   };
  // }
  render() {
    const { showDetailsModal, toggleDetailsModal, item } = this.props;
    return (
      <Modal
        size="lg"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="fas fa-receipt fa-sm mr-3"></i> Loan Update Loan
                System Loan Product Detail
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header">
              <h6>General Information</h6>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-10">
                  {_.isEmpty(item) ? (
                    <Skeleton count={4} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Product Code"
                        value={item.code}
                      />
                      <DetailItem
                        label="Product Name"
                        value={item.name}
                      />
                      {/* <DetailItem
                        label="Product Discriminator"
                        value={item.productDiscriminator}
                      /> */}
                      <DetailItem
                        label="Product Use Case"
                        value={item.productUseCase}
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SystemLoanProductDetail;
