import React, { Component } from "react";
import {
  firstCharToUpper,
  splitAtUpper,
} from "../../services/utility/textFormatService";

export interface ISelectItems {
  name: string;
  value: any;
}
interface ISelectProps {
  id: string;
  items: ISelectItems[];
  name: string;
  required: boolean;
  classNameString: string;
  handleOnChange: any;
  value: string;
}
class SelectBox extends Component<ISelectProps, {}> {
  static defaultProps = {
    classNameString: "browser-default custom-select",
    value: "",
  };
  // getFirstTag = (required) => {
  //   if (required === true || required === undefined) {
  //     return <select className="browser-default custom-select" required>;
  //   return <select className="browser-default custom-select">;
  // }
  render() {
    const {
      id,
      items,
      name,
      required,
      classNameString,
      handleOnChange,
      value,
    } = this.props;
    return (
      <React.Fragment>
        <select
          id={id}
          className={classNameString}
          required={required === true ? true : false}
          value={value}
          onChange={(e) =>
            handleOnChange(e.currentTarget.id, e.currentTarget.value)
          }
        >
          {name !== "" ? (
            <option value="">
              {"-Select " + firstCharToUpper(splitAtUpper(name)) + "-"}
            </option>
          ) : (
            ""
          )}
          {items.map((item) => (
            <option key={item.value} value={item.value}>
              {item.name}
            </option>
          ))}
        </select>
      </React.Fragment>
    );
  }
}

export default SelectBox;
