import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import {
  Modal,
  Button,
} from "react-bootstrap";
import DetailItem from "../../components/detailItem";
import { ActionTypes } from "../../enums/actionTypes";
import swal from "sweetalert";
import activityTrailService from "../../services/auditTrail/activityTrailService";
import usersService from "../../services/auth/usersService";
import { toast } from "react-toastify";
import supportingDocumentService from "../../services/mandate/supportingDocumentService";
import textFormatService, {
  splitAtUpper,
} from "../../services/utility/textFormatService";
import "./disputeDetail.css";
import disputeValidationService from "../../services/dispute/disputeValidationService";
import {
  getCurrentUserSession,
  getCurrentUsersRoles,
} from "../../services/auth/authService";
import mandateService from "../../services/mandate/mandateService";
import disputeActionService from "../../services/dispute/disputeActionService";
import DocumentPreviewer from "./documentPreviewer";
import { decodeToken } from "react-jwt";

interface IDisputeDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  showTrail: any;
  item: any;
  isUnresolvedDisputesList: boolean;
  reloadItem: any;
}

interface IDisputeDetailState {
  item: any;
  interval: any;
  submitting: boolean;
  validated: boolean;
  showPreview: boolean;
  uploadingDocument: boolean;
  document: any;
  docToUpload: any;
  data: {
    comment: string;
    docToUploadType: string;
  };
  errors: any;
  ctSeconds: number;
  ctMinutes: number;
  ctHours: number;
  ctDays: number;
}

class DisputeDetail extends Component<
  IDisputeDetailProps,
  IDisputeDetailState
> {
  constructor(props: IDisputeDetailProps) {
    super(props);
    this.state = {
      item: {},
      interval: {},
      submitting: false,
      validated: false,
      showPreview: false,
      uploadingDocument: false,
      document: {},
      docToUpload: {},
      data: {
        comment: "",
        docToUploadType: "",
      },
      errors: {},
      ctSeconds: 0,
      ctMinutes: 0,
      ctHours: 0,
      ctDays: 0,
    };
  }

  static getDerivedStateFromProps(
    nextProps: IDisputeDetailProps,
    prevState: IDisputeDetailState
  ) {
    if (!_.isEmpty(nextProps.item)) {
      // console.log("updating item state: ", nextProps.item);
      if (nextProps.item !== prevState.item) {
        return {
          item: nextProps.item,
        };
      }
    }
    return null;
  }

  handleTATTimerUpdate = () => {
    this.setState({ ctHours: 0, ctDays: 0, ctMinutes: 0, ctSeconds: 0 });
    if (!_.isEmpty(this.props.item)) {
      let deadlineDate = new Date(this.props.item.dateCreated);
      let deadline = deadlineDate.setHours(
        deadlineDate.getHours() +
        this.props.item.disputeCondition.maxResolutionTAT
      );
      var t = deadline - new Date().getTime();
      var ctSeconds = t < 0 ? 0 : Math.floor((t % (1000 * 60)) / 1000);
      // console.log("check: ", t);
      var ctMinutes =
        t < 0 ? 0 : Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
      var ctHours =
        t < 0 ? 0 : Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var ctDays = t < 0 ? 0 : Math.floor(t / (1000 * 60 * 60 * 24));

      this.setState({ ctSeconds, ctMinutes, ctHours, ctDays });
      // if (this.props.item.disputeActions.length === 0) {

      // }
    }
  };

  componentDidMount() {
    // console.log("testing date: ", new Date());
    // this.setState({ ctHours: 0, ctDays: 0, ctMinutes: 0, ctSeconds: 0 });
    this.setState({
      interval: setInterval(() => this.handleTATTimerUpdate(), 1000),
    });
  }

  componentWillUnmount() {
    // this.resetFields();
    this.clearTimers();
  }

  toggleShowPreview = () => {
    this.setState({ showPreview: !this.state.showPreview });
  };

  clearTimers = () => {
    clearInterval(this.state.interval);
  };

  handleOnDocumentSelect = async (document) => {
    try {
      var doc = await supportingDocumentService.retrieveById(document.id);
      document["document"] = doc.data.document;
      switch (document.extensionType) {
        case "PNG":
        case "JPG":
        case "GIF":
          document[
            "downloadname"
          ] = `${document.name}_${document.loanReference}.jpg`;
          document["data"] = `data:image/jpg;base64,${document.document}`;
          document["payload"] = (
            <img
              src={document.data}
              alt={document.name}
              width="100%"
              height="100%"
              className="image"
            />
          );
          break;
        case "DOCX":
        case "PDF":
          var data = `data:application/pdf;base64,${document.document}`;
          document["payload"] = (
            <object
              width="100%"
              height="500px"
              data={data}
              type="application/pdf"
              className="internal"
            >
              <embed src={data} type="application/pdf" />
            </object>
          );
          break;
        case "HTML":
          document["payload"] = (
            <div
              dangerouslySetInnerHTML={{
                __html: atob(document.document),
              }}
            />
          );
          break;
        default:
          break;
      }
      this.setState({ document, showPreview: true });
    } catch (error) {
      // console.log(error);
    }
  };

  getActionLabel = (action) => {
    switch (action) {
      case "Respond":
        return <span className="badge badge-info">{splitAtUpper(action)}</span>;
      case "Accept":
        return (
          <span className="badge badge-success">{splitAtUpper(action)}</span>
        );
      case "Reject":
        return (
          <span className="badge badge-danger">{splitAtUpper(action)}</span>
        );
      case "Close":
        return (
          <span className="badge badge-light">{splitAtUpper(action)}</span>
        );
      case "SaveDocument":
        return (
          <span className="badge badge-default">{splitAtUpper(action)}</span>
        );
      case "RemoveDocument":
        return (
          <span className="badge badge-warning">{splitAtUpper(action)}</span>
        );
      default:
        return <span className="badge badge-info">{splitAtUpper(action)}</span>;
    }
  };

  generateExtensionIcon = (document) => {
    switch (document.extensionType) {
      case "PNG":
      case "JPG":
      case "GIF":
        return (
          <i
            className="fas fa-file-image cyan-text fa-lg"
            style={{ cursor: "pointer" }}
            onClick={(e) => this.handleOnDocumentSelect(document)}
          ></i>
        );
      case "PDF":
        return (
          <i
            className="fas fa-file-pdf red-text fa-lg"
            style={{ cursor: "pointer" }}
            onClick={(e) => this.handleOnDocumentSelect(document)}
          ></i>
        );
      case "DOC":
      case "DOCX":
        return (
          <i
            className="fas fa-file-word blue-text fa-lg"
            style={{ cursor: "pointer" }}
            onClick={(e) => this.handleOnDocumentSelect(document)}
          ></i>
        );
      default:
        return (
          <i
            className="fas fa-file-pdf red-text fa-lg"
            style={{ cursor: "pointer" }}
            onClick={(e) => this.handleOnDocumentSelect(document)}
          ></i>
        );
    }
  };

  getActionTypeString = (actionType) => {
    switch (actionType) {
      case 11:
        return "Accept";
      case 12:
        return "Reject";
      case 13:
        return "Close";
      default:
        break;
    }
  };

  onFormSubmit(data, actionType): boolean {
    try {
      this.setState(
        { errors: disputeValidationService.validateDisputeDetailForm(data) },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            if (!actionType) {
              // console.log("doing the normal submit: ", actionType);
              this.submit(actionType);
            } else {
              swal({
                title: "Confirm!",
                text: `Are you sure you want to proceed to ${ActionTypes[actionType]} this Dispute?`,
                icon: "warning",
                buttons: {
                  confirm: {
                    text: "Yes",
                    value: null,
                    visible: true,
                    className: "",
                    closeModal: true,
                  },
                  cancel: {
                    text: "Cancel",
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true,
                  },
                },
                closeOnClickOutside: false,
                dangerMode:
                  actionType === 2 ||
                    actionType === 3 ||
                    actionType === 7 ||
                    actionType === 8
                    ? true
                    : false,
              }).then((resp) => {
                if (resp) {
                  // console.log("cancelling action...");
                } else {
                  this.submit(actionType);
                }
              });
            }
          }
        }
      );
      this.setState({ validated: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (actionType) => {
    try {
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      let response: any = null;
      var currentUser = await usersService.retrieveUserById(
        decodedToken?.["UserId"]
      );
      if (
        !(
          Object.keys(this.state.docToUpload).length === 0 &&
          this.state.docToUpload.constructor === Object
        )
      ) {
        // console.log("uploading supporting document...");
        const mandate = await mandateService.retrieveMandateByLoanReferenceOnly(
          this.props.item.loanAccountNumber
        );
        const payload: any = {};
        payload.institutionId = decodedToken?.["InstitutionId"];
        payload.document = await textFormatService.getBase64StringFromFile(
          this.state.docToUpload
        );
        payload.name = this.state.docToUpload.name.split(".")[0];
        payload.loanReference = this.props.item.loanAccountNumber;
        payload.customerID = mandate.data.customerID;
        payload.disputeId = this.props.item.id;
        payload.supportingDocumentType = "Others";
        payload.disputeConditionDocumentType = this.state.data.docToUploadType;
        payload.extensionType = this.state.docToUpload.name
          .split(".")[1]
          .toUpperCase();
        // console.log("document payload: ", payload);
        this.setState({ uploadingDocument: true, submitting: true });
        response = await supportingDocumentService.saveSupportingDocument(
          payload
        );
        // console.log("response1: ", response);
        await activityTrailService.saveActivityTrail({
          actionType: "Upload Supporting Document",
          description: `Upload Supporting Document on dispute ${this.props.item.id}`,
        });

        const actionPayload: any = {};

        // console.log("currentUser: ", currentUser);
        actionPayload.institutionId =
          this.props.isUnresolvedDisputesList === true
            ? 0
            : decodedToken?.["InstitutionId"];
        actionPayload.initiatingUser = `${currentUser.data.lastName} ${currentUser.data.firstName}`;
        actionPayload.disputeId = this.props.item.id;
        actionPayload.dateCreated = new Date();
        actionPayload.comment = `Uploaded new Supporting Document [${this.state.docToUpload.name.split(".")[0]
          }]`;
        actionPayload.action = "SaveDocument";

        // console.log("actionPayload: ", actionPayload);
        // let response;
        response = await disputeActionService.saveDisputeAction(actionPayload);
        await activityTrailService.saveActivityTrail({
          actionType: "Dispute Action",
          description: `Saved new Dispute Action [Save Document] on dispute ${this.props.item.id}`,
        });

        this.setState({ uploadingDocument: false, submitting: false });
        // console.log("done uploading supporting document...");
      }

      // console.log("submitting dispute action...", actionType);
      const _payload: any = {};

      // console.log("currentUser: ", currentUser);
      _payload.institutionId =
        this.props.isUnresolvedDisputesList === true
          ? 0
          : decodedToken?.["InstitutionId"];
      _payload.initiatingUser = `${currentUser.data.lastName} ${currentUser.data.firstName}`;
      _payload.disputeId = this.props.item.id;
      _payload.comment = this.state.data.comment;
      _payload.action = this.getActionTypeString(actionType);
      _payload.dateCreated = new Date();
      // console.log("_payload: ", _payload);
      this.setState({ submitting: true });
      // const response;
      response = await disputeActionService.saveDisputeAction(_payload);
      this.setState({ submitting: false });
      await activityTrailService.saveActivityTrail({
        actionType: "Dispute Action",
        description: `Saved new Dispute Action [${this.getActionTypeString(
          actionType
        )}] on dispute ${this.state.item.id}`,
      });
      toast.info(`Dispute action saved successfully! `, {
        autoClose: 6000,
        type: toast.TYPE.DEFAULT,
        hideProgressBar: false,
      });

      this.resetFields();
      return response;
    } catch (error) {
      toast.error(error.response.data.Message);
      this.setState({ submitting: false });
    }
  };

  resetFields() {
    const data = { ...this.state.data };
    data.comment = "";
    data.docToUploadType = "";
    this.props.reloadItem(this.props.item);
    this.setState({ data, validated: false, document: {}, docToUpload: {} });
  }

  handleDeleteDocument = async (document) => {
    try {
      this.setState({ uploadingDocument: true, submitting: true });
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      var response = await supportingDocumentService.deleteSupportingDocument(
        document.id
      );
      // console.log("response1: ", response);
      await activityTrailService.saveActivityTrail({
        actionType: "Remove Supporting Document",
        description: `Remove Supporting Document [${document.name}] on dispute ${this.props.item.id}`,
      });

      const actionPayload: any = {};
      var currentUser = await usersService.retrieveUserById(
        decodedToken?.["UserId"]
      );
      // console.log("currentUser: ", currentUser);
      actionPayload.institutionId = decodedToken?.["InstitutionId"];
      actionPayload.initiatingUser = `${currentUser.data.lastName} ${currentUser.data.firstName}`;
      actionPayload.disputeId = this.props.item.id;
      actionPayload.comment = `Removed Supporting Document [${document.name}]`;
      actionPayload.action = "RemoveDocument";
      actionPayload.dateCreated = new Date();
      // console.log("actionPayload: ", actionPayload);
      response = await disputeActionService.saveDisputeAction(actionPayload);
      await activityTrailService.saveActivityTrail({
        actionType: "Dispute Action",
        description: `Saved new Dispute Action [Remove Document] on dispute ${this.props.item.id}`,
      });

      this.setState({ uploadingDocument: false, submitting: false });
      this.resetFields();
    } catch (error) {
      toast.error(error.response.data.Message);
      this.setState({ uploadingDocument: false, submitting: false });
    }
  };

  handleUploadDocument = async () => {
    try {
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const mandate = await mandateService.retrieveMandateByLoanReferenceOnly(
        this.props.item.loanAccountNumber
      );
      const payload: any = {};
      payload.institutionId = decodedToken?.["InstitutionId"];
      payload.document = await textFormatService.getBase64StringFromFile(
        this.state.docToUpload
      );
      // payload.document = await supportingDocumentService.getBase64String(
      //   this.state.docToUpload
      // );
      payload.name = this.state.docToUpload.name.split(".")[0];
      payload.loanReference = this.props.item.loanAccountNumber;
      payload.customerID = mandate.data.customerID;
      payload.disputeId = this.props.item.id;
      payload.supportingDocumentType = "Others";
      payload.disputeConditionDocumentType = this.state.data.docToUploadType;
      payload.extensionType = this.state.docToUpload.name
        .split(".")[1]
        .toUpperCase();
      // console.log("mandate: ", mandate);
      // console.log("Loan refr: ", this.props.item.loanAccountNumber);
      // console.log("payload: ", payload);
      this.setState({ uploadingDocument: true, submitting: true });
      try {
        var response = await supportingDocumentService.saveSupportingDocument(
          payload
        );
      } catch (error) {
        // console.log("error in supporting doc", error.response.data.Message);
        throw error;
      }
      // console.log("response1: ", response);
      await activityTrailService.saveActivityTrail({
        actionType: "Upload Supporting Document",
        description: `Upload Supporting Document on dispute ${this.props.item.id}`,
      });

      const actionPayload: any = {};
      var currentUser = await usersService.retrieveUserById(
        decodedToken?.["UserId"]
      );
      // console.log("currentUser: ", currentUser);
      actionPayload.institutionId =
        this.props.isUnresolvedDisputesList === true
          ? 0
          : decodedToken?.["InstitutionId"];
      actionPayload.initiatingUser = `${currentUser.data.lastName} ${currentUser.data.firstName}`;
      actionPayload.disputeId = this.props.item.id;
      actionPayload.comment = `Uploaded new Supporting Document [${this.state.docToUpload.name.split(".")[0]
        }]`;
      actionPayload.action = "SaveDocument";
      actionPayload.dateCreated = new Date();
      // console.log("actionPayload: ", actionPayload);
      // let response;
      response = await disputeActionService.saveDisputeAction(actionPayload);
      await activityTrailService.saveActivityTrail({
        actionType: "Dispute Action",
        description: `Saved new Dispute Action [Save Document] on dispute ${this.props.item.id}`,
      });

      this.setState({ uploadingDocument: false, submitting: false });
      // console.log("response2: ", response);
      this.resetFields();
    } catch (error) {
      toast.error(error.response.data.Message);
      this.setState({ uploadingDocument: false, submitting: false });
    }
  };

  OnDocumentChange = (e) => {
    e.preventDefault();
    // console.log("document: ", e.target.files[0]);
    // check the file size
    if (e.target.files[0] === undefined) {
      return;
    }
    if (e.target.files[0].size > 100000) {
      toast.error(
        "Please confirm that the size of the document is less than 100kb"
      );
    } else if (
      !["docx", "doc", "pdf", "jpg", "png"].includes(
        e.target.files[0].name.split(".")[1]
      )
    ) {
      // console.log("check: ", e.target.files[0].name.includes("docx"));
      toast.error(
        "Please confirm that the document is a valid word document, pdf or image"
      );
    } else {
      this.setState({ docToUpload: e.target.files[0] });
    }
  };

  handleOndataItemChange = (name: string, value: any) => {
    const data = { ...this.state.data };
    data[name] = value;
    this.setState({ data });
  };

  renderDisputeStatus = (disputeStatus) => {
    switch (disputeStatus) {
      case "Accepted":
        return <span className="badge badge-success">{disputeStatus}</span>;
      case "Cancelled":
      case "Rejected":
        return <span className="badge badge-danger">{disputeStatus}</span>;
      case "Reversing":
      case "Reversed":
        return <span className="badge badge-default">{disputeStatus}</span>;
      case "Submit":
        return <span className="badge badge-primary">{disputeStatus}</span>;
      case "Pending":
        return <span className="badge badge-warning">{disputeStatus}</span>;
      case "Unresolved":
        return <span className="badge badge-secondary">{disputeStatus}</span>;
      default:
        return <span className="badge badge-light">{disputeStatus}</span>;
    }
  };

  validateField = (fieldName: string, type: string, validated: boolean) => {
    let defaultClass = "";
    if (type === "select") {
      defaultClass =
        "custom-select select2 mr-sm-2 form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    } else {
      defaultClass = "form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    }
    return !validated
      ? defaultClass
      : this.state.errors[fieldName]
        ? `${defaultClass} is-invalid`
        : `${defaultClass} is-valid`;
  };

  render() {
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    const {
      showDetailsModal,
      toggleDetailsModal,
      isUnresolvedDisputesList,
      item,
    } = this.props;
    const {
      ctSeconds,
      ctMinutes,
      ctHours,
      ctDays,
      validated,
      data,
      errors,
      submitting,
      docToUpload,
      uploadingDocument,
    } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="fas fa-bomb fa-sm cyan-text s-18 mr-3" />
                Dispute Details -{" "}
                <span className="font-weight-bold">
                  {item.retrievalReferenceNumber}
                </span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {_.isEmpty(item) ? (
            <Skeleton height={150} />
          ) : (
            <React.Fragment>
              {(ctDays === 0 &&
                ctHours === 0 &&
                ctMinutes === 0 &&
                ctSeconds === 0) ||
                item.disputeStatus === "Accepted" ||
                item.disputeStatus === "Rejected" ||
                item.disputeStatus === "Unresolved" ||
                item.disputeStatus === "Closed" ? (
                item.disputeStatus === "Unresolved" ? (
                  <div className="alert alert-danger" role="alert">
                    This Dispute case has passed its resolution time and is
                    being acted upon by <strong>AppZone</strong>.
                  </div>
                ) : (
                  ""
                )
              ) : (
                <div
                  className="rounded bg-gradient-1 text-white shadow p-3 text-center mb-2"
                  style={{
                    background:
                      "linear-gradient(to right, #fc7e6d, #86a8e7, #f2fdfd)",
                  }}
                >
                  <p className="mb-2 font-weight-bold text-uppercase">
                    TAT Count Down
                  </p>
                  <div
                    id="clock-b"
                    className="countdown-circles d-flex flex-wrap justify-content-center"
                  >
                    <div className="holder m-2">
                      <span className="h1 font-weight-bold">{ctDays}</span>{" "}
                      {ctDays > 1 ? "Days" : "Day"}
                    </div>
                    <div className="holder m-2">
                      <span className="h1 font-weight-bold">{ctHours}</span>{" "}
                      {ctHours > 1 ? "Hours" : "Hour"}
                    </div>
                    <div className="holder m-2">
                      <span className="h1 font-weight-bold">{ctMinutes}</span>{" "}
                      Min
                    </div>
                    <div className="holder m-2">
                      <span className="h1 font-weight-bold">{ctSeconds}</span>{" "}
                      Sec
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          )}

          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  General Information
                </h6>
              )}
              {/* <div className="float-right">
                {_.isEmpty(item) ? (
                  <Skeleton width={100} />
                ) : (
                  <span
                    className="badge badge-light"
                    onClick={showTrail}
                    style={{ cursor: "pointer" }}
                  >
                    View Trail
                  </span>
                )}
              </div> */}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={9} width={300} />
                  ) : (
                    <React.Fragment>
                      <dl className="row">
                        {_.isEmpty(item.customerName) ? (
                          ""
                        ) : (
                          <DetailItem
                            label="Customer Name"
                            labelSize={5}
                            valueSize={6}
                            value={item.customerName}
                          />
                        )}
                        <DetailItem
                          label="Retrieval Reference"
                          labelSize={5}
                          valueSize={6}
                          value={item.retrievalReferenceNumber}
                        />
                        <DetailItem
                          label="Disputing Institution"
                          labelSize={5}
                          valueSize={6}
                          value={item.disputingInstitution.name}
                        />
                        <DetailItem
                          label="Lending Institution"
                          labelSize={5}
                          valueSize={6}
                          value={item.lendingInstitution.name}
                        />
                        <DetailItem
                          label="Transaction Amount"
                          labelSize={5}
                          valueSize={6}
                          value={textFormatService.convertKoboToNaira(
                            item.amountDebited,
                            2
                          )}
                        />
                        {/* <DetailItem
                        label="Initiating User"
                        labelSize={5}
                        valueSize={6}
                        value={item.initiatingUserName}
                      /> */}
                        <DetailItem
                          label="Dispute Condition"
                          labelSize={5}
                          valueSize={6}
                          value={item.disputeCondition.name}
                        />
                        <DetailItem
                          label="Comment"
                          labelSize={5}
                          valueSize={6}
                          value={item.comment}
                        />
                        <DetailItem
                          label="Date Logged"
                          labelSize={5}
                          valueSize={6}
                          value={item.dateCreated}
                        />
                        <DetailItem
                          label="Transaction Date"
                          labelSize={5}
                          valueSize={6}
                          value={item.timeSwept}
                        />
                        <DetailItem
                          label="Status"
                          labelSize={5}
                          valueSize={6}
                          value={this.renderDisputeStatus(item.disputeStatus)}
                        />
                        <DetailItem
                          label="Customer Phone No."
                          labelSize={5}
                          valueSize={6}
                          value={item.customerPhoneNumber}
                        />
                      </dl>
                      <h6>Required Documents</h6>
                      <div
                        className="table-responsive text-nowrap"
                        style={{ height: "150px", overflowY: "scroll" }}
                      >
                        <table className="table table-hover table-content table-sm table-striped mb-0">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                style={{
                                  // width: "5px",
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                }}
                              >
                                {/* <strong>Description</strong> */}
                              </th>
                              <th
                                scope="col"
                                style={{
                                  width: "5px",
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                }}
                              >
                                <strong>#</strong>
                              </th>
                              <th
                                scope="col"
                                style={{
                                  // width: "5px",
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                }}
                              >
                                <strong>Name</strong>
                              </th>
                              <th
                                scope="col"
                                style={{
                                  // width: "5px",
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                }}
                              >
                                <strong>Description</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {_.isEmpty(item) ||
                              item.requiredDisputeDocuments.length === 0 ? (
                              <tr>
                                <td
                                  className="font-weight-normal text-truncate"
                                  colSpan={4}
                                  style={{
                                    textAlign: "center",
                                    color: "#FE825E",
                                    fontSize: "small",
                                  }}
                                >
                                  ...No documents required
                                </td>
                              </tr>
                            ) : (
                              item.requiredDisputeDocuments.map((document) => (
                                <tr key={document.id}>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {document.available === true ? (
                                      <i className="far fa-check-circle green-text"></i>
                                    ) : (
                                      <i className="far fa-times-circle red-text"></i>
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      width: "5px",
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {item.requiredDisputeDocuments.indexOf(
                                      document
                                    ) + 1}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {document.name}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {document.description}
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                      <hr />
                      <h6>Supporting Documents</h6>
                      {getCurrentUsersRoles().includes(
                        "uploadsupportingdocument"
                      ) &&
                        item.disputeStatus === "Pending" && (
                          <div className="input-group">
                            <div className="custom-file">
                              <input
                                type="file"
                                className="custom-file-input"
                                id="docToUpload"
                                name="docToUpload"
                                aria-describedby="inputGroupFileAddon01"
                                onChange={(e) => this.OnDocumentChange(e)}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="docToUpload"
                              >
                                {_.isEmpty(docToUpload.name)
                                  ? "Choose File"
                                  : docToUpload.name}
                              </label>
                            </div>
                            {_.isEmpty(docToUpload.name) ? (
                              ""
                            ) : (
                              <React.Fragment>
                                {uploadingDocument === true ? (
                                  <span style={{ float: "right" }}>
                                    <i className="fas fa-spinner fa-spin fa-lg m-3"></i>
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      float: "right",
                                      cursor: "pointer",
                                    }}
                                    onClick={this.handleUploadDocument}
                                  >
                                    <i className="fas fa-cloud-upload-alt fa-lg m-3"></i>
                                  </span>
                                )}
                              </React.Fragment>
                            )}
                          </div>
                        )}
                      <React.Fragment>
                        {_.isEmpty(docToUpload.name) ? (
                          ""
                        ) : (
                          <React.Fragment>
                            {item.requiredDisputeDocuments.length === 0 ? (
                              ""
                            ) : (
                              <React.Fragment>
                                {item.requiredDisputeDocuments.map(
                                  (document) => (
                                    <div
                                      key={document.name}
                                      className="custom-control custom-radio"
                                    >
                                      <input
                                        type="radio"
                                        className="custom-control-input"
                                        id={document.name}
                                        name="docToUploadType"
                                        onChange={(e) =>
                                          this.handleOndataItemChange(
                                            e.currentTarget.name,
                                            e.currentTarget.id
                                          )
                                        }
                                        checked={
                                          data.docToUploadType === document.name
                                        }
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={document.name}
                                      >
                                        {document.name}
                                      </label>
                                    </div>
                                  )
                                )}
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                      <br />
                      {(getCurrentUsersRoles().includes(
                        "viewsupportingdocuments"
                      ) ||
                        getCurrentUsersRoles().includes("remotesession")) && (
                          <div
                            className="table-responsize text-nowrap"
                            style={{ height: "150px", overflowY: "scroll" }}
                          >
                            <table className="table table-hover table-content table-sm table-striped mb-0">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    style={{
                                      width: "5px",
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                    }}
                                  >
                                    <strong>#</strong>
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      // width: "5px",
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                    }}
                                  >
                                    <strong>Name</strong>
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      // width: "5px",
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                    }}
                                  >
                                    <strong>Document Type</strong>
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      // width: "5px",
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                    }}
                                  ></th>
                                </tr>
                              </thead>
                              <tbody>
                                {_.isEmpty(item) ||
                                  item.supportingDocuments.length === 0 ? (
                                  <tr>
                                    <td
                                      className="font-weight-normal text-truncate"
                                      colSpan={4}
                                      style={{
                                        textAlign: "center",
                                        color: "#FE825E",
                                        fontSize: "small",
                                      }}
                                    >
                                      ...No documents available
                                    </td>
                                  </tr>
                                ) : (
                                  item.supportingDocuments.map((document) => (
                                    <tr key={document.id}>
                                      <td
                                        style={{
                                          width: "5px",
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {item.supportingDocuments.indexOf(
                                          document
                                        ) + 1}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {document.name}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {document.disputeConditionDocumentType}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {/* <i class="far fa-trash-alt"></i> */}
                                        {this.generateExtensionIcon(document)}
                                        {decodedToken?.["InstitutionId"] ===
                                          document.institutionId &&
                                          isUnresolvedDisputesList === false ? (
                                          <i
                                            className="far fa-trash-alt ml-5 red-text fa-lg"
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) =>
                                              this.handleDeleteDocument(document)
                                            }
                                          ></i>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </table>
                          </div>
                        )}
                    </React.Fragment>
                  )}
                </div>
                <div className="col-md-6">
                  <h5>Dispute Actions</h5>
                  <div className="mesgs border">
                    <div className="msg_history table-responsive">
                      {!_.isEmpty(item) && item.disputeActions.length > 0 ? (
                        <React.Fragment>
                          {item.disputeActions.map((action) => (
                            <React.Fragment key={action.id}>
                              {action.institutionId ===
                                decodedToken?.["InstitutionId"] ? (
                                <div className="outgoing_msg">
                                  <div className="sent_msg">
                                    <p
                                      className="initiator"
                                      style={{ fontSize: "12px" }}
                                    >
                                      <i className="fas fa-user fa-sm mr-2"></i>
                                      {action.initiatingUser}
                                    </p>
                                    <p>{action.comment}</p>
                                    <p>{this.getActionLabel(action.action)}</p>
                                    <span className="time_date">
                                      {action.dateCreated
                                        .split(",")[1]
                                        .substring(6)}{" "}
                                      | {action.dateCreated.split(",")[0]}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <React.Fragment>
                                  {action.institutionId === 0 ? (
                                    <div style={{ backgroundColor: "#F3F3F3" }}>
                                      <p className="text-center">
                                        {action.comment}
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="incoming_msg">
                                      <div className="incoming_msg_img">
                                        <img
                                          src={`https://ui-avatars.com/api/?name=${action.institutionId ===
                                            item.disputingInstitution.id
                                            ? item.disputingInstitution.name.replace(
                                              " ",
                                              "+"
                                            )
                                            : item.lendingInstitution.name.replace(
                                              " ",
                                              "+"
                                            )
                                            }&bold=true&background=82E2F9&color=fff`}
                                          alt="sunil"
                                          style={{ borderRadius: "100%" }}
                                        />
                                      </div>
                                      <div className="received_msg">
                                        <div className="received_withd_msg">
                                          <p
                                            className="initiator"
                                            style={{ fontSize: "12px" }}
                                          >
                                            <i className="fas fa-user fa-sm mr-2"></i>
                                            {action.initiatingUser}
                                          </p>
                                          <p>{action.comment}</p>
                                          <p>
                                            {this.getActionLabel(action.action)}
                                          </p>
                                          <span className="time_date">
                                            {action.dateCreated
                                              .split(",")[1]
                                              .substring(6)}{" "}
                                            | {action.dateCreated.split(",")[0]}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      ) : (
                        <div style={{ backgroundColor: "#F3F3F3" }}>
                          <p className="text-center">No Actions Yet!</p>
                        </div>
                      )}
                    </div>
                    {/* <div className="type_msg">
                      <div className="input_msg_write">
                        <input
                          type="text"
                          className="write_msg"
                          placeholder="Type a message"
                        />
                        <button className="msg_send_btn" type="button">
                          <i
                            className="fa fa-paper-plane-o"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <br />
              <form>
                {_.isEmpty(item) ? (
                  <React.Fragment>
                    <div className="text-right">
                      <Skeleton width={100} count={3} height={30} />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {item.disputeStatus === "Pending" ||
                      (ctDays !== 0 &&
                        ctHours !== 0 &&
                        ctMinutes !== 0 &&
                        ctSeconds !== 0 &&
                        isUnresolvedDisputesList === false) ||
                      (item.disputeStatus === "Unresolved" &&
                        isUnresolvedDisputesList === true) ? (
                      <React.Fragment>
                        <div className="form-group row">
                          <label
                            htmlFor="comment"
                            className="col-md-2 col-form-label"
                          >
                            Comment:
                          </label>
                          <div className="col-md-10">
                            <textarea
                              rows={3}
                              className={this.validateField(
                                "comment",
                                "text",
                                validated
                              )}
                              id="comment"
                              name="comment"
                              value={data.comment}
                              onChange={(e) =>
                                this.handleOndataItemChange(
                                  e.currentTarget.id,
                                  e.currentTarget.value
                                )
                              }
                            />
                            <div
                              className="invalid-feedback"
                              style={{ visibility: errors.comment }}
                            >
                              {errors.comment}
                            </div>
                          </div>
                        </div>
                        <div className="text-right">
                          <React.Fragment>
                            {Number(decodedToken?.["InstitutionId"]) !==
                              item.institutionId ||
                              isUnresolvedDisputesList === true
                              ? getCurrentUsersRoles().includes(
                                "acceptdispute"
                              ) && (
                                <Button
                                  size="sm"
                                  style={{ margin: "8px" }}
                                  variant="success"
                                  disabled={
                                    submitting === true ? true : false
                                  }
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.onFormSubmit(
                                      data,
                                      ActionTypes.ACCEPT
                                    );
                                  }}
                                >
                                  {this.state.submitting === true ? (
                                    <React.Fragment>
                                      <i className="fas fa-spinner fa-spin"></i>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <i className="fas fa-thumbs-up mr-3" />
                                      Accept
                                    </React.Fragment>
                                  )}
                                </Button>
                              )
                              : ""}
                            {Number(decodedToken?.["InstitutionId"]) !==
                              item.institutionId ||
                              isUnresolvedDisputesList === true
                              ? getCurrentUsersRoles().includes(
                                "rejectdispute"
                              ) && (
                                <Button
                                  size="sm"
                                  style={{ margin: "8px" }}
                                  variant="danger"
                                  disabled={
                                    submitting ||
                                      item.requiredDisputeDocuments.filter(
                                        (x) => x.available === false
                                      ).length >= 1
                                      ? true
                                      : false
                                  } //there are zero unavailable documents
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.onFormSubmit(
                                      data,
                                      ActionTypes.REJECT
                                    );
                                  }}
                                >
                                  {this.state.submitting === true ? (
                                    <React.Fragment>
                                      <i className="fas fa-spinner fa-spin"></i>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <i className="fas fa-thumbs-down mr-3" />
                                      {Number(
                                        decodedToken?.["InstitutionId"]
                                      ) === 1
                                        ? "Close"
                                        : "Reject"}
                                    </React.Fragment>
                                  )}
                                </Button>
                              )
                              : ""}
                            {Number(decodedToken?.["InstitutionId"]) ===
                              item.institutionId &&
                              isUnresolvedDisputesList === false
                              ? getCurrentUsersRoles().includes(
                                "closedispute"
                              ) && (
                                <Button
                                  size="sm"
                                  style={{ margin: "8px" }}
                                  variant="outline-warning"
                                  disabled={
                                    submitting === true ? true : false
                                  }
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.onFormSubmit(
                                      data,
                                      ActionTypes.CLOSE
                                    );
                                  }}
                                >
                                  {this.state.submitting === true ? (
                                    <React.Fragment>
                                      <i className="fas fa-spinner fa-spin"></i>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <i className="fas fa-ban mr-3" />
                                      Close Dispute
                                    </React.Fragment>
                                  )}
                                </Button>
                              )
                              : ""}
                            {getCurrentUsersRoles().includes(
                              "respondtodispute"
                            ) &&
                              Number(decodedToken?.["InstitutionId"]) !== 1 && (
                                <React.Fragment>
                                  {/* <ButtonGroup>
                                  <DropdownButton
                                    as={ButtonGroup}
                                    title="Dropdown"
                                    id="bg-nested-dropdown"
                                  >
                                    <Dropdown.Item eventKey="1">
                                      Dropdown link
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey="2">
                                      Dropdown link
                                    </Dropdown.Item>
                                  </DropdownButton>
                                </ButtonGroup> */}
                                  <Button
                                    size="sm"
                                    style={{ margin: "8px" }}
                                    variant="light"
                                    disabled={
                                      submitting === true ? true : false
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.onFormSubmit(data, null);
                                    }}
                                  >
                                    {this.state.submitting === true ? (
                                      <React.Fragment>
                                        <i className="fas fa-spinner fa-spin"></i>
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        <i className="far fa-paper-plane  mr-3"></i>
                                        Respond
                                      </React.Fragment>
                                    )}
                                  </Button>
                                </React.Fragment>
                              )}
                          </React.Fragment>
                        </div>
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                )}
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-danger"
            onClick={() => {
              this.clearTimers();
              toggleDetailsModal();
            }}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
        <DocumentPreviewer
          document={this.state.document}
          showPreview={this.state.showPreview}
          toggleShowPreview={this.toggleShowPreview}
        />
      </Modal>
    );
  }
}

export default DisputeDetail;
