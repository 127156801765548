import http from "../httpService";
import authService, { getCurrentUserSession, getCurrentUsersRoles } from "../auth/authService";
import { IFilterData } from "../approval/approvalItemService";
import { decodeToken } from "react-jwt";

interface IApproveConsentSmsItem {
  institutionId: number;
  phoneNumber: string;
  consentTracker: string;
}
interface ISearchConsentRequestsData {
  phoneNumber: string;
  bvn: string;
  requestStatus: string;
}

const recovaMandateBaseAddress = process.env.REACT_APP_MANDATE_BASEADDRESS;
const recovaOFIHandshakeBaseAddress = process.env.REACT_APP_RECOVA_OFI_HANDSHAKE_BASEADDRESS;

export function retrieveConsentRequestById(consentRequestId: number) {
  return http.get(
    recovaMandateBaseAddress + `ConsentRequest/${consentRequestId}`
  );
}

export function retrieveAllConsentRequests() {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  return http.get(
    recovaMandateBaseAddress +
    `ConsentRequest?InstitutionId=${decodedToken?.["InstitutionId"]}`
  );
}

export function resendConsentRequestMessage(id: number) {
  return http.post(
    recovaMandateBaseAddress +
    `ConsentRequest/ResendConsentRequestMessage/${id}`
  );
}

export function resendOFIConsentRequestMessage(loanReference: string) {
  var url = recovaOFIHandshakeBaseAddress +
    `ConsentRequest/ResendConsentRequestMessage/${loanReference}`;

  return http.post(url, null, {
    url: url,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  }
  );
}

export function updateConsentRequestMessage(id: number) {
  return http.post(
    recovaMandateBaseAddress +
    `ConsentRequest/UpdateConsentRequestMessage/${id}`
  );
}

export function updateOFIConsentRequestMessage(loanReference: string) {
  var url = recovaOFIHandshakeBaseAddress +
    `ConsentRequest/UpdateConsentRequestMessage/${loanReference}`;

  return http.post(url, null, {
    url: url,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  }
  );
}

export function retryDisbursementForProcessingConsentRequest(id: number) {
  return http.get(recovaMandateBaseAddress + `ConsentRequest/RetryDisbursementForProcessingConsentRequest/${id}`);
}

export function cancelConsentRequestMessage(id: number) {
  return http.post(
    recovaMandateBaseAddress +
    `ConsentRequest/CancelConsentRequestMessage/${id}`
  );
}

export function searchConsentRequests(
  data: ISearchConsentRequestsData,
  pageSize: number,
  pageNumber: number
) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  return http.get(
    recovaMandateBaseAddress +
    `ConsentRequest/SearchConsentRequests?institutionId=${decodedToken?.["InstitutionId"]}&bvn=${data.bvn}&phoneNumber=${data.phoneNumber}&requestStatus=${data.requestStatus}&pageSize=${pageSize}&pageNumber=${pageNumber}`
  );
}

export function filterConsentRequests(
  payload: IFilterData,
  pageSize: number,
  pageNumber: number
) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");

  payload = { ...payload };
  payload.institutionId = {
    item1: "=",
    item2: decodedToken?.["InstitutionId"]?.toString() || "break",
  };
  payload.customerType = {
    item1: "=",
    item2: "Beneficiary",
  };

  return http.post(
    recovaMandateBaseAddress +
    `ConsentRequest/FilterConsentRequests?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    payload
  );
}

export function filterConsentRequestsOCM(
  payload: IFilterData,
  pageSize: number,
  pageNumber: number
) {
  payload = { ...payload };
  payload.ocm = {
    item1: "=",
    item2: "true",
  };
  // console.log("payload", payload)
  return http.post(
    recovaMandateBaseAddress +
    `ConsentRequest/FilterConsentRequests?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    payload
  );
}

export function approveConsentSMS(item: IApproveConsentSmsItem) {
  return http.post(
    recovaMandateBaseAddress +
    `ConsentRequest/ApproveConsentSMS?institutionId=${item.institutionId}&phoneNumber=${item.phoneNumber}&consentTracker=${item.consentTracker}`
  );
}

export function approveConsentWeb(data) {
  return http.post(
    recovaMandateBaseAddress + `ConsentRequest/ApproveConsentWeb`,
    data
  );
}

export function downloadReport(type: string) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  var mywindow = window.open(
    recovaMandateBaseAddress +
    `ConsentRequest/downloadreport/${decodedToken?.["InstitutionId"]}?type=${type}`,
    "PRINT",
    "height=500,width=900"
  );
  mywindow?.focus();
}

export function downloadReportPDF() {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  return http.get(
    recovaMandateBaseAddress +
    `ConsentRequest/DownloadReportPDF/${decodedToken?.["InstitutionId"]}`
  );
}

export function retrieveCollectionSchedulesForLoan(institutionId: number, loanDetail: any) {
  return http.post(
    recovaMandateBaseAddress + `ConsentRequest/RetrieveRepaymentSchedulesForLoan/${institutionId}`,
    loanDetail
  );
}

export default {
  resendConsentRequestMessage,
  resendOFIConsentRequestMessage,
  updateConsentRequestMessage,
  updateOFIConsentRequestMessage,
  cancelConsentRequestMessage,
  retrieveAllConsentRequests,
  retrieveConsentRequestById,
  searchConsentRequests,
  filterConsentRequests,
  filterConsentRequestsOCM,
  approveConsentSMS,
  downloadReport,
  downloadReportPDF,
  approveConsentWeb,
  retrieveCollectionSchedulesForLoan,
  retryDisbursementForProcessingConsentRequest
};
