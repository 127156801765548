import React, { Fragment } from "react";
import { toast } from "react-toastify";
import { Modal, Button, Card, Accordion } from "react-bootstrap";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import scoringCardService from "../../../services/creditAssessment/scoringCardService";
import { ActionTypes } from "../../../enums/actionTypes";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import creditAssessmentValidationService from "../../../services/creditAssessment/creditAssessmentValidationService";
import DetailItem from "../../../components/detailItem";
import { ContextAwareToggle } from "../creditQualificationRuleManagement/creditQualificationRuleComponents/appzoneAddCQR";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { getCurrentUserSession } from "../../../services/auth/authService";
import scoringIndicatorService from "../../../services/creditAssessment/scoringIndicatorService";
import { decodeToken } from "react-jwt";
import ModifyPointMetrics from "./modifyPointMetrics";

interface EditScoreCardFields {
  name: string;
  description: string;
}

class EditScoreCardForm extends BaseFormComponent<EditScoreCardFields> { }

interface IEditScoreCardProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}
interface IEditScoreCardState {
  ownUpdate: boolean;
  scoreIndicators: any;
  scoreIndicatorIds: any;
  existingScoreIndicators: any;
  fetchingScoreIndicators: boolean;
  showSetIndicatorParamsModal: boolean;
  indicatorItem: any;
  scoreMetrics: any;
  IScoreCardPointsMetrics: any;
  indicatorOption: any;
}

class EditScoreCard extends React.Component<
  IEditScoreCardProps,
  IBaseFormState & IEditScoreCardState
> {
  _isMounted = false;
  constructor(props: IEditScoreCardProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      ownUpdate: false,
      scoreIndicators: [],
      scoreIndicatorIds: [],
      existingScoreIndicators: [],
      fetchingScoreIndicators: false,
      showSetIndicatorParamsModal: false,
      indicatorItem: {},
      scoreMetrics: {},
      IScoreCardPointsMetrics: [],
      indicatorOption: []
    };
  }

  static getDerivedStateFromProps(
    nextProps: IEditScoreCardProps,
    prevState: IEditScoreCardState
  ) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isEmpty(nextProps.item)) {
      return null;
    }
    return {
      existingScoreIndicators: nextProps.item.scoreGroups.map(
        (item) => item.scoreIndicatorId
      ),
      scoreIndicatorIds: nextProps.item.scoreGroups.map(
        (item) => item.scoreIndicatorId
      ),
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    if (_.isEmpty(this.props.item)) {
      return
    }
    console.log(this.props.item)
    const { errors } = this.state;
    const decodedToken = decodeToken(getCurrentUserSession().token || "")
    try {
      if (this._isMounted) {
        this.setState({ fetchingScoreIndicators: true, ownUpdate: true });
      }
      const scoreIndicators = await scoringIndicatorService.retrieveScoreIndicatorByInstitutionCode(decodedToken?.["InstitutionCode"]);
      this.setState({
        scoreIndicators: scoreIndicators.data,
        fetchingScoreIndicators: false,
        ownUpdate: true
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (ex.response && ex.response.status === 404) {
        // console.log(ex.response);
        errors.response = "Internal server error.";
        // console.log(errors);
        this.setState({ errors });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleOnChangeScoreIndicator = async (item) => {
    const scoreIndicatorIds = [...this.state.scoreIndicatorIds];
    if (scoreIndicatorIds.indexOf(item) !== -1) {
      scoreIndicatorIds.splice(scoreIndicatorIds.indexOf(item), 1);
      this.setState({ scoreIndicatorIds, ownUpdate: true });
    } else {
      const scoreMetrics = (await scoringIndicatorService.retrieveScoreIndicatorById(item)).data;
      if (scoreMetrics.length === 0) {
        scoreIndicatorIds.push(item);
        this.setState({ scoreIndicatorIds, ownUpdate: true });
      } else {
        this.setState({ indicatorItem: item, showSetIndicatorParamsModal: true, scoreMetrics, ownUpdate: true });
      }
    }
  };

  handleOnIndicatorParamsSave = (toSave: boolean, scoreIndicatorId, payload, IScoreCardPointsMetrics) => {
    if (toSave) {
      // console.log("payload :: ", payload);
      const scoreIndicatorIds = [...this.state.scoreIndicatorIds];
      const scoreIndicators = [...this.state.scoreIndicators];
      scoreIndicatorIds.push(scoreIndicatorId);
      let ScoreCardPointsMetrics = [...this.state.IScoreCardPointsMetrics];
      const scoreIndicator = scoreIndicators.filter(indicator => indicator.scoreIndicatorId === scoreIndicatorId)[0];
      scoreIndicator.scoreMetrics = payload
      ScoreCardPointsMetrics = IScoreCardPointsMetrics;
      this.setState({
        scoreIndicatorIds: scoreIndicatorIds,
        scoreIndicators,
        showSetIndicatorParamsModal: false,
        ownUpdate: true, IScoreCardPointsMetrics: ScoreCardPointsMetrics
      });
    } else {
      this.setState({ showSetIndicatorParamsModal: false, ownUpdate: true });
    }
  };

  onFormSubmit(fields: EditScoreCardFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors: creditAssessmentValidationService.validateEditScoreCardForm(fields),
          ownUpdate: true,
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (fields: EditScoreCardFields, onReloadFieldsCB: any) => {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const { scoreIndicatorIds, scoreIndicators, existingScoreIndicators, IScoreCardPointsMetrics } = this.state;
      const { item } = this.props;
      const payload: any = {};
      payload.name = fields.name;
      payload.description = fields.description;
      // payload.scoreCardPointsMetrics = this.state.IScoreCardPointsMetrics;
      console.log(payload);


      const removedIndicatorIds: any = [];
      const maintainedIndicatorIds: any = [];

      for (var i = 0; i < existingScoreIndicators.length; i++) {
        if (scoreIndicatorIds.some(indicatorId => indicatorId == existingScoreIndicators[i])) {
          // console.log("maintained", existingScoreIndicators[i])
          maintainedIndicatorIds.push(existingScoreIndicators[i])
        } else {
          // console.log("removed", existingScoreIndicators[i])
          removedIndicatorIds.push(existingScoreIndicators[i])
        }
      }

      //find new ids
      var newScoringIndicators = this.state.scoreIndicatorIds.filter((scoreIndicatorId) => !maintainedIndicatorIds.some(maintained => maintained === scoreIndicatorId));
      let item_ = item.scoreGroups;
      let res = {};
      const removeIndicatorPayload: any = {};
      removeIndicatorPayload.scoreIndicatorIDs = removedIndicatorIds;
      console.log(removeIndicatorPayload)
      // if (removeIndicatorPayload.scoreIndicatorIDs.length > 1) {
      scoreIndicatorIds.forEach((x) => {
        let item2 = item_.filter(option => option.scoreIndicatorId === x);
      });

      console.log(IScoreCardPointsMetrics)
      const newIndicatorPayload: any = {};
      // newIndicatorPayload.scoreCardIndicators = newScoringIndicators.map(scoreIndicatorId => {
      //   let indicatorMetrics = scoreIndicators.filter(indicator => indicator.scoreIndicatorId === scoreIndicatorId)[0].scoreMetrics;
      //   indicatorMetrics = indicatorMetrics.map(data => ({
      //     pointMetricId: data.metricId,
      //     points: data.metricPoint,
      //   }))
      //   return {
      //     scoreIndicatorId: scoreIndicatorId,
      //     indicatorMetrics: indicatorMetrics
      //   }
      // });

      newIndicatorPayload.scoreCardIndicators = IScoreCardPointsMetrics
      if (removedIndicatorIds.length > 0) {
        // console.log("Remove")
        await scoringCardService.removeScoreIndicatorsFromScoreCard(item.id, removeIndicatorPayload);
      }
      if (newScoringIndicators.length > 0) {
        console.log("Add")
        await scoringCardService.addScoreIndicatorsToScoreCard(item.id, newIndicatorPayload);
      }

      const response = await scoringCardService.editScoreCard(item.id, payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit Score Card",
          description: `Edited details of Score Card ${payload.name}`,
        });
        toast.info(`Score Card for ${payload.name} Edited successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      }
      onReloadFieldsCB();
      this.setState({ submitting: false, ownUpdate: true }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    }
    catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
    }
  };

  render() {
    const {
      item,
      showEditModal,
      toggleEditModal
    } = this.props;
    const {
      validated,
      submitting,
      errors,
      scoreIndicatorIds,
      scoreIndicators,
      indicatorItem,
      scoreMetrics,
      showSetIndicatorParamsModal,
      existingScoreIndicators,
      IScoreCardPointsMetrics,
      indicatorOption
    } = this.state;

    if (_.isEmpty(this.props.item)) {
      return null
    }

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-gopuram fa-sm cyan-text mr-3" />
              Edit Score Card -{" "}
              <span className="font-weight-bold">{item.name}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditScoreCardForm
          initialValues={{
            name: item.name,
            description: item.description,
          }}
          FormComponent={({
            fields: {
              name,
              description,
            },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    <h6
                      className="card-title float-left"
                      style={{ marginBottom: "0px" }}
                    >
                      General Information
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <dl className="row">
                          <DetailItem
                            label="Score Card Name"
                            labelSize={7}
                            valueSize={5}
                            value={
                              <DetailItemInputComponent
                                id="name"
                                type="text"
                                name="name"
                                placeholder=""
                                value={name}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                              />
                            }
                          />
                        </dl>
                      </div>
                      <div className="col-md-6">
                        <dl className="row">
                          <DetailItem
                            label="Description"
                            labelSize={5}
                            valueSize={7}
                            value={
                              <DetailItemInputComponent
                                id="description"
                                type="text"
                                name="description"
                                placeholder=""
                                value={description}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                              />
                            }
                          />
                        </dl>
                      </div>
                    </div>
                  </div>
                  <Accordion defaultActiveKey="0">
                    <div>
                      <Card>
                        <ContextAwareToggle
                          eventKey={`0`}
                          isHeader={true}
                          header={
                            <React.Fragment>
                              <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                                style={{ color: "#878a8a" }}
                              ></i>{" "}
                              Scoring Indicators
                            </React.Fragment>
                          }
                        ></ContextAwareToggle>
                        <Accordion.Collapse eventKey={`0`}>
                          <Card.Body>
                            <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                              <div className="row">
                                <div className="col-md-12">
                                  {_.isEmpty(item) ? (
                                    <Skeleton count={5} width={300} />
                                  ) : (
                                    <Fragment>
                                      {console.log(item)}
                                      {scoreIndicators?.map(
                                        (indicator) => (
                                          indicator.scoreMetrics?.length > 0 || existingScoreIndicators.includes(indicator.scoreIndicatorId) ? (
                                            <Accordion key={scoreIndicators.indexOf(indicator)} >
                                              <div style={{ border: '2px #84d4fc solid', borderRadius: '4px', backgroundColor: '#e4f4fc', marginTop: '5px', inlineSize: "100%" }}>
                                                <ContextAwareToggle
                                                  eventKey={`1`}
                                                  isHeader={false}
                                                  header={
                                                    <div
                                                      className="custom-control custom-checkbox"
                                                      style={{ display: 'inline-block' }}
                                                      key={scoreIndicators.indexOf(indicator)}
                                                    >
                                                      <input
                                                        id={indicator.scoreIndicatorId}
                                                        name={indicator.scoreIndicatorId}
                                                        className="custom-control-input"
                                                        type="checkbox"
                                                        onChange={() =>
                                                          this.handleOnChangeScoreIndicator(
                                                            indicator.scoreIndicatorId
                                                          )
                                                        }
                                                        checked={scoreIndicatorIds.includes(
                                                          indicator.scoreIndicatorId
                                                        )}
                                                      />
                                                      <label
                                                        className="custom-control-label pt-1"
                                                        htmlFor={indicator.scoreIndicatorId}
                                                      >
                                                        {indicator.indicatorName}
                                                      </label>
                                                    </div>
                                                  }
                                                ></ContextAwareToggle>
                                              </div>
                                              <Accordion.Collapse eventKey={`1`}>
                                                <Card.Body style={{ padding: "5px", backgroundColor: '#e4f4fc' }}>
                                                  <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                    <div className="row">
                                                      <div className="col-md-1">
                                                      </div>
                                                      <div className="col-md-8">
                                                        {console.log(item)}
                                                        {!item?.scoreGroups ? (
                                                          // item.scoreCardIndicators.map(metric => (metric.scoreCardMetrics.map(metrics=>
                                                          //   <dl className="row" style={{ marginBottom: "0px" }} key={metric.scoreIndicatorId}>
                                                          //     <DetailItem
                                                          //       label={(metrics.minValue + "-" + metrics.maxValue)}
                                                          //       value={metric.points}
                                                          //       labelSize={5}
                                                          //       valueSize={7}
                                                          //     />
                                                          //   </dl>
                                                          // )))
                                                          ""
                                                        ) : (
                                                          item.scoreGroups.filter(x => x.scoreIndicatorId === indicator.scoreIndicatorId)[0].scoreGridMetrics.map(metric => (
                                                            metric.maxValue === null ? (
                                                              <dl className="row" style={{ marginBottom: "0px" }} key={metric.pointMetricId}>
                                                                <DetailItem
                                                                  label={(metric.scoreIndicatorOption)}
                                                                  value={metric.points}
                                                                  labelSize={5}
                                                                  valueSize={7}
                                                                />
                                                              </dl>
                                                            ) : (
                                                              <dl className="row" style={{ marginBottom: "0px" }} key={metric.scoreIndicatorId}>
                                                                <DetailItem
                                                                  label={(metric.minValue + "-" + metric.maxValue)}
                                                                  value={metric.points}
                                                                  labelSize={5}
                                                                  valueSize={7}
                                                                />
                                                              </dl>
                                                            )
                                                          ))
                                                        )}

                                                      </div>
                                                    </div>
                                                  </div>
                                                </Card.Body>
                                              </Accordion.Collapse>
                                            </Accordion>
                                          ) : (
                                            <div
                                              key={scoreIndicators.indexOf(indicator)}
                                              style={{ border: '2px #84d4fc solid', borderRadius: '4px', backgroundColor: '#e4f4fc', marginTop: '5px' }}>
                                              <div className="custom-control custom-checkbox" key={scoreIndicators.indexOf(indicator)}>
                                                <input
                                                  id={indicator.scoreIndicatorId}
                                                  name={indicator.scoreIndicatorId}
                                                  className="custom-control-input"
                                                  type="checkbox"
                                                  onChange={() =>
                                                    this.handleOnChangeScoreIndicator(
                                                      indicator.scoreIndicatorId
                                                    )
                                                  }
                                                  checked={scoreIndicatorIds.includes(
                                                    indicator.scoreIndicatorId
                                                  )}
                                                />
                                                <label
                                                  className="custom-control-label pt-1"
                                                  htmlFor={indicator.scoreIndicatorId}
                                                >
                                                  {indicator.indicatorName}
                                                </label>
                                              </div>
                                            </div>
                                          )
                                        )
                                      )}
                                    </Fragment>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </div>
                  </Accordion>
                  <ModifyPointMetrics
                    showPatchModal={showSetIndicatorParamsModal}
                    togglePatchModal={(toSave: boolean, indicatorId: string, payload: any, IScoreCardPointsMetrics) => {
                      this.handleOnIndicatorParamsSave(toSave, indicatorId, payload, IScoreCardPointsMetrics);
                    }}
                    item={indicatorItem}
                    allIndicators={scoreMetrics}
                    IScoreCardPointsMetrics={IScoreCardPointsMetrics}
                    indicatorOption={indicatorOption}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(e, ActionTypes.UPDATE, "Score Card", () => {
                      this.onFormSubmit(
                        {
                          name,
                          description
                        },
                        onReloadFields
                      );
                    });
                  }}
                  disabled={submitting || scoreIndicatorIds.length == 0}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment >
          )}
        ></EditScoreCardForm >
      </Modal >
    );
  }
}

export default EditScoreCard;
