import React, { Fragment, Component } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../../../../components/detailItem";
import textFormatService from "../../../../../../services/utility/textFormatService";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../../../../../services/auth/authService";
import loanProductService from "../../../../../../services/hybridAppraisal/loanProductService";
import supplierService from "../../../../../../services/hybridAppraisal/supplierService";

interface PersonalDetailsProps {
  values: any;
  handleChange: any;
  handleBlur: any;
}

interface PersonalDetailsState {
  item: any;
  productPricing: any;
  loanCommission: string;
  supplierProvided: boolean;
  suppliers: any;
}

class PersonalDetails extends Component<PersonalDetailsProps, PersonalDetailsState> {
  _isMounted = false;
  constructor(props: PersonalDetailsProps) {
    super(props);
    this.state = {
      item: {},
      productPricing: [],
      loanCommission: "",
      supplierProvided: false,
      suppliers: []
    };
  }

  static getDerivedStateFromProps(nextProps: PersonalDetailsProps, prevState) {
    return {
      item: nextProps.values,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    // const jsonLoanDetails = JSON.parse(JSON.parse(JSON.parse(this.props.values.preferredRepaymentBank)).LoanDetails);
    // let bankCode = 
    // console.log(jsonLoanDetails);
    // let preferredRepaymentBank = this.props.values.preferredRepaymentBank;
    // try {
    //   preferredRepaymentBank = await (await sweepConfigService.retrieveBankNameByCBNCode(jsonLoanDetails.PreferredRepaymentBankCBNCode)).data;
    //   console.log(preferredRepaymentBank);
    // } catch (error) { }
    // console.log(this.props.values)
    let productPricing: any = {}
    const product = await loanProductService.retrieveByProductCode(this.props.values.institutionCode, this.props.values.productCode)
    const productDetails = product.data
    const loanProduct = await loanProductService.retrieveById(productDetails.id);
    const loanProductDetails = loanProduct.data
    productPricing = loanProductDetails?.productPricing
    // console.log(productPricing)
    const jsonLoanDetails = JSON.parse(JSON.parse(this.props.values.jsonData).LoanDetails);
    if (!_.isEmpty(productPricing)) {
      // console.log(jsonLoanDetails.LoanFees)
      if (jsonLoanDetails.LoanFees.length > 0) {
        jsonLoanDetails.LoanFees.forEach(x => {
          const loanFee: any = {};
          loanFee.name = x.Name
          loanFee.processingFee = x.Amount
          loanFee.feeType = "Value"

          productPricing?.push(loanFee)
        })
        // productPricing.push(jsonLoanDetails.LoanFees)
      }
    }
    else {
      const loanFees: any = [];
      if (jsonLoanDetails?.LoanFees != null) {
        if (jsonLoanDetails?.LoanFees?.length > 0) {
          try {
            jsonLoanDetails.LoanFees.forEach(x => {
              const loanFee: any = {};
              loanFee.name = x.Name
              loanFee.processingFee = x.Amount
              loanFee.feeType = "Value"
              loanFees.push(loanFee)
            })
          } catch {
            const loanFee: any = {};
            loanFees.push(loanFee)
          }
        }
      }
      productPricing = loanFees
    }
    // console.log(productPricing)
    this.setState({ productPricing: productPricing })
    // console.log(loanProductDetails)

    var loanCommissionDetails = loanProductDetails.productPricing?.filter(x => x.name.includes("Commission"))[0]

    if (loanCommissionDetails) {
      var loanCommission = loanCommissionDetails.feeType === "Percentage" ? textFormatService.formatCurrency(((loanCommissionDetails.value / 100) * (this.props.values.loanAmount / 100)), 2) : loanCommissionDetails.value;
      this.setState({ loanCommission: loanCommission })
    }
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    const suppliers = await supplierService.retrieveAll();
    const supplierDetails = suppliers.data.filter(x => x.institutionCode === decodedToken?.["InstitutionCode"])
    const supplier = this.props.values.supplierName
    if (supplier !== null || supplier != "") {
      this.setState({ supplierProvided: false, suppliers: supplierDetails })
    }
    else {
      this.setState({ supplierProvided: true })
    }

  }

  render() {
    const { values } = this.props;
    const { productPricing } = this.state;
    const jsonLoanDetails = JSON.parse(JSON.parse(values.jsonData).LoanDetails);

    // console.log(values)

    return (
      <Fragment>
        <div className="card">
          <div className="card-header clear-fix">
            {_.isEmpty(values) ? (
              <Skeleton width={200} />
            ) : (
              <h6
                className="card-title float-left"
                style={{ marginBottom: "0px" }}
              >
                <b>Loan Information</b>
              </h6>
            )}
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                {_.isEmpty(values) ? (
                  <Skeleton count={3} width={300} />
                ) : (
                  <dl className="row">
                    <DetailItem
                      label="Requested Loan Amount"
                      labelSize={7}
                      valueSize={5}
                      value={textFormatService.convertKoboToNaira(Number(values.loanAmount), 2)}
                    />
                    <DetailItem
                      label="Loan Purpose"
                      labelSize={7}
                      valueSize={5}
                      value={values.loanPurpose}
                    />
                    <DetailItem
                      label="Repayment Frequency (Principal)"
                      labelSize={7}
                      valueSize={5}
                      value={values.loanDetails?.repaymentFrequencyPrincipal}
                    />
                    <DetailItem
                      label="Due Day"
                      labelSize={7}
                      valueSize={5}
                      value={values.loanDetails?.dueDate}
                    />
                    <DetailItem
                      label="Repayment Day"
                      labelSize={7}
                      valueSize={5}
                      value={values.loanDetails?.repaymentDay}
                    />
                  </dl>
                )}
              </div>
              <div className="col-md-6">
                {_.isEmpty(values) ? (
                  <Skeleton count={3} width={300} />
                ) : (
                  <dl className="row">
                    <DetailItem
                      label="Requested Loan Tenor (In days)"
                      labelSize={7}
                      valueSize={5}
                      value={values.tenureInDays + " Days"}
                    />
                    <DetailItem
                      label="Product Interest rate"
                      labelSize={7}
                      valueSize={5}
                      value={
                        values.loanDetails?.repaymentFrequencyPrincipal ?
                          values.loanDetails?.interestRate + "%" :
                          jsonLoanDetails.InterestRate + "%"}
                    />
                    <DetailItem
                      label="Repayment Frequency (Interest)"
                      labelSize={7}
                      valueSize={5}
                      value={values.loanDetails?.repaymentFrequencyInterest}
                    />
                    <DetailItem
                      label="Interest Accrual"
                      labelSize={7}
                      valueSize={5}
                      value={values.loanDetails?.accrual}
                    />
                  </dl>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-header clear-fix">
            {_.isEmpty(values) ? (
              <Skeleton width={200} />
            ) : (
              <h6
                className="card-title float-left"
                style={{ marginBottom: "0px" }}
              >
                <b>Moratorium Details</b>
              </h6>
            )}
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                {_.isEmpty(values) ? (
                  <Skeleton count={3} width={300} />
                ) : (
                  <dl className="row">
                    <DetailItem
                      label="Moratorium"
                      labelSize={7}
                      valueSize={5}
                      value={values.loanDetails?.moratorium}
                    />
                    <DetailItem
                      label="Impact on Tenure"
                      labelSize={7}
                      valueSize={5}
                      value={values.loanDetails?.impactOnTenor}
                    />
                    {/* <DetailItem
                      label="Interest Treatment"
                      labelSize={7}
                      valueSize={5}
                      value={values.loanDetails?.spreadInterestAcrossBalanceTenor}
                    /> */}
                  </dl>
                )}
              </div>
              <div className="col-md-6">
                {_.isEmpty(values) ? (
                  <Skeleton count={3} width={300} />
                ) : (
                  <dl className="row">
                    <DetailItem
                      label="Moratorium Period (In Months)"
                      labelSize={7}
                      valueSize={5}
                      value={values.loanDetails?.moratoriumPeriod}
                    />
                    <DetailItem
                      label="Charge Moratorium On"
                      labelSize={7}
                      valueSize={5}
                      value={values.loanDetails?.chargeInterest}
                    />
                  </dl>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card mt-3">
          <div className="card-header clear-fix">
            {_.isEmpty(values) ? (
              <Skeleton width={200} />
            ) : (
              <h6
                className="card-title float-left"
                style={{ marginBottom: "0px" }}
              >
                <b>Product Information</b>
              </h6>
            )}
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                {_.isEmpty(values) ? (
                  <Skeleton count={3} width={300} />
                ) : (
                  <dl className="row">
                    <DetailItem
                      label="Product Name"
                      labelSize={7}
                      valueSize={5}
                      value={jsonLoanDetails.Product.Name}
                    />
                    <DetailItem
                      label="Product Type"
                      labelSize={7}
                      valueSize={5}
                      value={jsonLoanDetails.Product.ProductType}
                    />
                  </dl>
                )}
              </div>
              <div className="col-md-6">
                {_.isEmpty(values) ? (
                  <Skeleton count={3} width={300} />
                ) : (
                  <dl className="row">
                    <DetailItem
                      label="Product Code"
                      labelSize={7}
                      valueSize={5}
                      value={values.productCode}
                    />
                    <DetailItem
                      label="Financial Group"
                      labelSize={7}
                      valueSize={5}
                      value={values.financialGroup.name}
                    />
                  </dl>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card mt-3">
          <div className="card-header clear-fix">
            {_.isEmpty(values) ? (
              <Skeleton width={200} />
            ) : (
              <h6
                className="card-title float-left"
                style={{ marginBottom: "0px" }}
              >
                <b>Account Information</b>
              </h6>
            )}
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                {_.isEmpty(values) ? (
                  <Skeleton count={3} width={300} />
                ) : (
                  <dl className="row">
                    <DetailItem
                      label="Account Name"
                      labelSize={7}
                      valueSize={5}
                      value={
                        values.loanDetails?.accountName ?
                          values.loanDetails.accountName :
                          jsonLoanDetails.Name
                      }
                    />
                    <DetailItem
                      label="Account Officer"
                      labelSize={7}
                      valueSize={5}
                      value={
                        values.loanDetails?.accountOfficer ?
                          values.loanDetails.accountOfficer :
                          jsonLoanDetails.AccountOfficer
                      }
                    />
                    <DetailItem
                      label="Branch"
                      labelSize={7}
                      valueSize={5}
                      value={
                        values.loanDetails?.branch ?
                          values.loanDetails.branch :
                          jsonLoanDetails.Branch
                      }
                    />
                    <DetailItem
                      label="Preferred Repayment Bank"
                      labelSize={7}
                      valueSize={5}
                      value={
                        values.loanDetails?.preferredRepaymentBank ?
                          values.loanDetails.preferredRepaymentBank :
                          jsonLoanDetails.PreferredRepaymentBank
                      }
                    />
                  </dl>
                )}
              </div>
              <div className="col-md-6">
                {_.isEmpty(values) ? (
                  <Skeleton count={3} width={300} />
                ) : (
                  <dl className="row">
                    <DetailItem
                      label="Account Number"
                      labelSize={7}
                      valueSize={5}
                      value={
                        values.loanDetails?.accountNumber ?
                          values.loanDetails.accountNumber :
                          values.linkedAccountNo
                      }
                    />
                    {values.loanType === "Individual" ? (
                      <DetailItem
                        label="Bank Verification Number"
                        labelSize={7}
                        valueSize={5}
                        value={
                          values.loanDetails?.bankVerificationNumber ?
                            values.loanDetails.bankVerificationNumber :
                            jsonLoanDetails.BVN
                        }
                      />
                    ) : (
                      <DetailItem
                        label="Business Registration Number"
                        labelSize={7}
                        valueSize={5}
                        value={values?.bvn}
                      />
                    )}
                    <DetailItem
                      label="Defaulting Loan Interest"
                      labelSize={7}
                      valueSize={5}
                      value={
                        values.loanDetails?.defaultingLoanInterest ?
                          values.loanDetails.defaultingLoanInterest :
                          jsonLoanDetails.DefaultingLoanInterest
                      }
                    />
                    <DetailItem
                      label="Preferred Repayment Account"
                      labelSize={7}
                      valueSize={5}
                      value={
                        values.loanDetails?.preferredRepaymentAccount ?
                          values.loanDetails.preferredRepaymentAccount :
                          jsonLoanDetails.PreferredRepaymentAccount
                      }
                    />
                  </dl>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-header clear-fix">
            {_.isEmpty(values) ? (
              <Skeleton width={200} />
            ) : (
              <h6
                className="card-title float-left"
                style={{ marginBottom: "0px" }}
              >
                <b>Loan Category</b>
              </h6>
            )}
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                {_.isEmpty(values) ? (
                  <Skeleton count={3} width={300} />
                ) : (
                  <dl className="row">
                    <DetailItem
                      label="Loan Category"
                      labelSize={7}
                      valueSize={5}
                      value={values.loanDetails?.loanCategory}
                    />
                    <DetailItem
                      label="Loan Interest Rate"
                      labelSize={7}
                      valueSize={5}
                      value={values.loanDetails?.loanInterestRate}
                    />
                    {/* <DetailItem
                      label="Interest Treatment"
                      labelSize={7}
                      valueSize={5}
                      value={values.loanDetails?.spreadInterestAcrossBalanceTenor}
                    /> */}
                  </dl>
                )}
              </div>
              <div className="col-md-6">
                {_.isEmpty(values) ? (
                  <Skeleton count={3} width={300} />
                ) : (
                  <dl className="row">
                    <DetailItem
                      label="Eligible Loan Limit"
                      labelSize={7}
                      valueSize={5}
                      value={values.loanDetails?.eligibleLoanLimit}
                    />
                    <DetailItem
                      label="Eligible Loan Tenor"
                      labelSize={7}
                      valueSize={5}
                      value={values.loanDetails?.eligibleLoanTenor}
                    />
                  </dl>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-header clear-fix">
            {_.isEmpty(values) ? (
              <Skeleton width={200} />
            ) : (
              <h6
                className="card-title float-left"
                style={{ marginBottom: "0px" }}
              >
                <b>Disbursement Details</b>
              </h6>
            )}
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                {_.isEmpty(values) ? (
                  <Skeleton count={3} width={300} />
                ) : (
                  <dl className="row">
                    <DetailItem
                      label="Disburse to"
                      labelSize={7}
                      valueSize={5}
                      value={values.loanDetails?.supplierName === null ? ("Customer") : ("Supplier")}
                    />

                    {values.loanDetails?.supplierName === null ? ("") : (
                      <><DetailItem
                        label="Supplier Name"
                        labelSize={7}
                        valueSize={5}
                        value={values.loanDetails?.supplierName}
                      />
                        <DetailItem
                          label="Supplier Bank Name"
                          labelSize={7}
                          valueSize={5}
                          value={values.loanDetails?.supplierBankName}
                        />
                        <DetailItem
                          label="Supplier Account Number"
                          labelSize={7}
                          valueSize={5}
                          value={values.loanDetails?.supplierAccountNumber} />

                      </>
                    )}
                  </dl>
                )}
              </div>
              <div className="col-md-6">
                {_.isEmpty(values) ? (
                  <Skeleton count={3} width={300} />
                ) : (
                  values.loanDetails?.disburseTo === "Supplier" ? (
                    <dl className="row">
                      <DetailItem
                        label="Supplier Address"
                        labelSize={7}
                        valueSize={5}
                        value={values.loanDetails?.supplierAddress}
                      />
                      <DetailItem
                        label="Supplier PhoneNumber"
                        labelSize={7}
                        valueSize={5}
                        value={values.loanDetails?.supplierPhoneNumber}
                      />
                    </dl>
                  ) : ("")
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-header clear-fix">
            <h6
              className="float-left"
              style={{ marginBottom: "0px" }}
            >
              <b>Charges</b>
            </h6>
          </div>
          <div className="card-body">
            <div className="form-row">
              <div className="col-md-12">
                <div
                  className="table-responsize text-nowrap"
                  style={{ height: "150px", overflowY: "scroll" }}
                >
                  <table className="table table-hover table-content table-sm table-striped mb-0">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          style={{
                            width: "5px",
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>#</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Name</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Amount</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.isEmpty(productPricing) ||
                        productPricing?.length === 0 ? (
                        <tr>
                          <td
                            className="font-weight-normal text-truncate"
                            colSpan={4}
                            style={{
                              textAlign: "center",
                              color: "#FE825E",
                              fontSize: "small",
                            }}
                          >
                            ...No Charge available
                          </td>
                        </tr>
                      ) : (
                        productPricing?.map((charge, id) => (
                          <tr key={id}>
                            <td
                              style={{
                                width: "5px",
                                paddingTop: "0.2rem",
                                paddingBottom: "0.2rem",
                                fontSize: "small",
                              }}
                            >
                              {id + 1}
                            </td>
                            <td
                              style={{
                                paddingTop: "0.2rem",
                                paddingBottom: "0.2rem",
                                fontSize: "small",
                              }}
                            >
                              {charge.name}
                            </td>
                            <td
                              style={{
                                paddingTop: "0.2rem",
                                paddingBottom: "0.2rem",
                                fontSize: "small",
                              }}
                            >
                              {
                                charge.feeType === "Percentage" ? textFormatService.formatCurrency(((charge.processingFee / 100) * (this.props.values.loanAmount / 100)), 2) :
                                  textFormatService.formatCurrency(charge.processingFee, 2)}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>

    );
  }
}

export default PersonalDetails;