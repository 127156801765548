import React, { Component, useContext } from "react";
import {
    Button,
    Card,
    useAccordionToggle,
    AccordionContext,
    Accordion,
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import DetailItem from "../../../../components/detailItem";
import textFormatService from "../../../../services/utility/textFormatService";
import CreditBureauDoc from "./creditBureauDoc";

export function ContextAwareToggle({ eventKey, header }) {
    const currentEventKey = useContext(AccordionContext);
    const toggleOnClick = useAccordionToggle(eventKey);
    const isCurrentEventKey = currentEventKey === eventKey;
    const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
    return (
        <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
            <b>{header}</b>
            <i
                className={angleType}
                aria-hidden="true"
                style={{ float: "right" }}
            ></i>
        </Card.Header>
    );
}
interface IFirstCentralCreditBureauCheckProps {
    item: any;
}

interface IFirstCentralCreditBureauCheckState {
    item: any;
    creditBureauResponse: any;
    ownUpdate: boolean;
    showDocPatchModal: boolean
}

class FirstCentralCreditBureauCheck extends Component<IFirstCentralCreditBureauCheckProps, IFirstCentralCreditBureauCheckState> {
    _isMounted: boolean;
    constructor(props: IFirstCentralCreditBureauCheckProps) {
        super(props);
        this.state = {
            item: {},
            creditBureauResponse: [],
            ownUpdate: false,
            showDocPatchModal: false
        };
    }

    static getDerivedStateFromProps(nextProps: IFirstCentralCreditBureauCheckProps, prevState) {
        if (prevState.ownUpdate) {
            return {
                ownUpdate: false,
            };
        }
        if (_.isNull(nextProps.item)) {
            return null;
        }
        return {
            item: nextProps.item
        };
    }

    async componentDidMount() {
        this._isMounted = true;
        const creditBureauResp = this.props.item.appraisalActions?.find(x => x.systemProcessID == Number(12) && x.effectiveStatus === "Valid")?.processResponse;
        let creditBureauResponse: any = []

        try {
            creditBureauResponse = JSON.parse(creditBureauResp?.response);
        }
        catch {
            creditBureauResponse = []
        }
        // console.log(creditBureauResponse)
        if (this._isMounted) {
            this.setState({ creditBureauResponse: creditBureauResponse, ownUpdate: true });
        }
    }

    toggleShowDocModal = () => {
        this.setState({ showDocPatchModal: !this.state.showDocPatchModal, ownUpdate: true });
    };

    render() {
        const { item } = this.props;
        const { creditBureauResponse, showDocPatchModal } = this.state;

        return (
            <div className="container-fluid relative animatedParent animateOnce">
                <div className="animated fadeInUpShort go">
                    <div className="row">
                        {/* {console.log(creditBureauResponse)} */}
                        <div className="col-md-12">
                            <div className="form-row">
                                <div className="col-md-12">
                                    <div className="row my-3 mx-2">
                                        <h3>
                                            <b>Credit Bureau Check</b>
                                        </h3>
                                    </div>
                                    <div className="card">
                                        <Accordion defaultActiveKey="0">
                                            <div>
                                                <Card>
                                                    <ContextAwareToggle
                                                        eventKey={`0`}
                                                        header={<React.Fragment>


                                                        </React.Fragment>}
                                                    ></ContextAwareToggle>
                                                    <Accordion.Collapse eventKey={`0`}>
                                                        <Card.Body>
                                                            <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                                <div className="row">
                                                                    {_.isEmpty(item.appraisalActions.filter(x => x.systemProcessID == Number(12) && x.effectiveStatus === "Valid")) || _.isEmpty(item.appraisalActions.filter(x => x.action == "Processed")) ? (
                                                                        <React.Fragment>
                                                                            <div>
                                                                                <Skeleton height={50} />
                                                                            </div>
                                                                            <div>
                                                                                <Skeleton height={50} />
                                                                            </div>
                                                                            <div>
                                                                                <Skeleton height={50} />
                                                                            </div>
                                                                            <div>
                                                                                <Skeleton height={50} />
                                                                            </div>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        item.appraisalActions.filter(x => x.systemProcessID == Number(12) && x.effectiveStatus === "Valid").map((actions) => (
                                                                            actions?.processResponse?.result == "No loan history found" ? (
                                                                                <React.Fragment>

                                                                                    <div className="center">
                                                                                        <h6>No loan history found</h6>
                                                                                    </div>
                                                                                </React.Fragment>

                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <div className="col-md-5">

                                                                                        <h6
                                                                                            className="card-title"
                                                                                            style={{ marginBottom: "0px" }}
                                                                                        >
                                                                                            <b>Customer Details</b>
                                                                                        </h6>
                                                                                        <br />
                                                                                        <dl className="row">
                                                                                            <DetailItem
                                                                                                label="Customer Name"
                                                                                                labelSize={5}
                                                                                                valueSize={7}
                                                                                                value={creditBureauResponse?.CustomerName}
                                                                                            />
                                                                                            <DetailItem
                                                                                                label="Date of Birth"
                                                                                                labelSize={5}
                                                                                                valueSize={7}
                                                                                                value={textFormatService.formatShortTimeString(creditBureauResponse?.DateOfBirth)}
                                                                                            />
                                                                                            <DetailItem
                                                                                                label="BVN"
                                                                                                labelSize={5}
                                                                                                valueSize={7}
                                                                                                value={creditBureauResponse?.BVN}
                                                                                            />
                                                                                            <DetailItem
                                                                                                label="Gender"
                                                                                                labelSize={5}
                                                                                                valueSize={7}
                                                                                                value={creditBureauResponse?.Gender}
                                                                                            />
                                                                                            <DetailItem
                                                                                                label="Phone number 1"
                                                                                                labelSize={5}
                                                                                                valueSize={7}
                                                                                                value={creditBureauResponse?.PhoneNumber1}
                                                                                            />
                                                                                            <DetailItem
                                                                                                label="Phone number 2"
                                                                                                labelSize={5}
                                                                                                valueSize={7}
                                                                                                // value={JSON.parse(JSON.parse(JSON.parse(creditBureauResponse.jsonData)).LoanDetails).ProductName}
                                                                                                value={creditBureauResponse?.PhoneNumber2}
                                                                                            />
                                                                                        </dl>
                                                                                    </div>
                                                                                    <div className="col-md-5">
                                                                                        <h6
                                                                                            className="card-title"
                                                                                            style={{ marginBottom: "0px", fontFamily: "sans-serif" }}
                                                                                        >
                                                                                            <b>Performance Summary</b>
                                                                                        </h6>
                                                                                        <br />
                                                                                        <dl className="row">
                                                                                            <DetailItem
                                                                                                label="Number of Open Loan Accounts"
                                                                                                labelSize={9}
                                                                                                valueSize={3}
                                                                                                // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                                value={creditBureauResponse?.NoOfOpenAccounts}
                                                                                            />
                                                                                            <DetailItem
                                                                                                label="Number of Closed Loan Accounts"
                                                                                                labelSize={9}
                                                                                                valueSize={3}
                                                                                                value={creditBureauResponse?.NoOfClosedAccounts}
                                                                                            />
                                                                                            <DetailItem
                                                                                                label="Number of Performing Open Loan Accounts"
                                                                                                labelSize={9}
                                                                                                valueSize={3}
                                                                                                value={creditBureauResponse?.NoOfPerformingOpenAccounts}
                                                                                            />
                                                                                            <DetailItem
                                                                                                label="Number of Non-Performing Open Loan Accounts"
                                                                                                labelSize={9}
                                                                                                valueSize={3}
                                                                                                value={creditBureauResponse?.NoOfDelinquentOpenAccounts}
                                                                                            />
                                                                                            <DetailItem
                                                                                                label="Number of Loan Accounts Written-off"
                                                                                                labelSize={9}
                                                                                                valueSize={3}
                                                                                                value={creditBureauResponse?.NoOfAccountsWrittenOff}
                                                                                            />
                                                                                        </dl>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            )
                                                                        ))
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </div>
                                        </Accordion>
                                    </div>

                                    <div className="card mt-3">
                                        <div
                                            className="table-responsive text-nowrap"
                                            style={{
                                                height: "300px",
                                                overflowY: "scroll",
                                            }}
                                        >
                                            <table className="table table-hover table-content table-sm table-striped mb-0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                            <strong>Institution Name</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                            <strong>Account Number</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc" }}>
                                                            <strong>Type</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>Amount</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>Currency</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>Tenor</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>Balance</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>Overdue</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>Disbursement Date</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>Maturity Date</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>Date Closed</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>Status</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>Classification</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>No of Days Past Due</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>Amount Written Off</strong>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {_.isEmpty(item.appraisalActions.filter(x => x.systemProcessID == Number(12) && x.effectiveStatus === "Valid")) && _.isEmpty(item.appraisalActions.filter(x => x.action == "Processed")) ? (("")) : (
                                                        item.appraisalActions.filter(x => x.systemProcessID == Number(12) && x.effectiveStatus === "Valid").map((actions) => (
                                                            actions?.processResponse?.result == "No loan history found" ? ("No loan history found") : (
                                                                // console.log(creditBureauResponse?.CreditDetails)
                                                                creditBureauResponse?.CreditDetails?.map(x => (
                                                                    <tr>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {x.InstitutionName}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {x.AccountNumber}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {x.CreditFacilityType}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {x.LoanAmount}
                                                                            {/* {textFormatService.formatCurrency(x?.LoanAmount, 2)} */}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {x.LoanCurrency}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {x.LoanTenor}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {x.LoanBalance}
                                                                            {/* {textFormatService.formatCurrency(x?.LoanBalance, 2)} */}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {x.AmountOverdue}
                                                                            {/* {textFormatService.formatCurrency(x?.AmountOverdue, 2)} */}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {x.LoanDisbursementDate}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {/* {x.LoanMaturityDate} */}
                                                                            {x.LoanMaturityDate === "" || x.LoanMaturityDate === null ? "" : textFormatService.formatShortTimeString(x.LoanMaturityDate)}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {/* {x.DateClosed} */}
                                                                            {x.DateClosed === "" || x.DateClosed === null ? "" : textFormatService.formatShortTimeString(x.DateClosed)}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {x.LoanStatus}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {x.LoanClassification}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {x.NoOfDaysPastDue}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {x.AmountWrittenOff}
                                                                            {/* {textFormatService.formatCurrency(x?.AmountWrittenOff, 2)} */}
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ))
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>

                                    <div className="card mt-3">
                                        {_.isEmpty(item.appraisalActions.filter(x => x.systemProcessID == Number(12) && x.effectiveStatus === "Valid")) && _.isEmpty(item.appraisalActions.filter(x => x.action == "Processed")) ? (("")) : (
                                            item.appraisalActions.filter(x => x.systemProcessID == Number(12) && x.effectiveStatus === "Valid").map((actions) => (
                                                actions?.processResponse?.result == "No loan history found" ? ("") : (
                                                    <Accordion defaultActiveKey="0">
                                                        <div>
                                                            <Card>
                                                                <ContextAwareToggle
                                                                    eventKey={`0`}
                                                                    header={<React.Fragment>
                                                                        <i
                                                                            className="fa fa-info-circle"
                                                                            aria-hidden="true"
                                                                            style={{ color: "#878a8a" }}
                                                                        ></i>{" "}
                                                                        Credit Bureau Result
                                                                    </React.Fragment>}
                                                                ></ContextAwareToggle>
                                                                <Accordion.Collapse eventKey={`0`}>
                                                                    <Card.Body>
                                                                        <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <dl className="row">
                                                                                        {/* <DetailItem
                                              label="Status"
                                              labelSize={5}
                                              valueSize={3}
                                              value={item.appraisalActions.filter(x => x.systemProcessID === Number(3))[0]?.processResponse?.result}
                                            /> */}
                                                                                        <DetailItem
                                                                                            label="Performance"
                                                                                            labelSize={5}
                                                                                            valueSize={3}
                                                                                            value={
                                                                                                item.appraisalActions.filter(x => x.systemProcessID === Number(12) && x.effectiveStatus === "Valid")[0]?.action !== "Processed" ? (
                                                                                                    <i
                                                                                                        className=""
                                                                                                        aria-hidden="true"
                                                                                                        style={{ color: "#60ed15" }}
                                                                                                    >Processing.....</i>) :
                                                                                                    item.appraisalActions.filter(x => x.systemProcessID === Number(12) && x.effectiveStatus === "Valid")[0]?.processResponse?.result === "Pass" ?
                                                                                                        <i
                                                                                                            className=""
                                                                                                            aria-hidden="true"
                                                                                                            style={{ color: "#60ed15" }}
                                                                                                        >Good</i> : <i
                                                                                                            className=""
                                                                                                            aria-hidden="true"
                                                                                                            style={{ color: "#fcce54" }}
                                                                                                        >Bad</i>}
                                                                                        />
                                                                                    </dl>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <dl className="row">
                                                                                        <Button
                                                                                            className="card-title float-right mr-3"
                                                                                            size="sm"
                                                                                            variant="primary"
                                                                                            onClick={() => this.toggleShowDocModal()}
                                                                                        >
                                                                                            <i className="fas fa-sm fa-eye mr-3" />
                                                                                            View Report
                                                                                        </Button>
                                                                                    </dl>
                                                                                    <CreditBureauDoc
                                                                                        item={item}
                                                                                        values={this.props.item}
                                                                                        // updateItemDocs={(document: any) => this.props.updateItemDocs(document)}
                                                                                        // deleteItemDocs={(document: any, documentId: number) => this.props.deleteItemDocs(document, documentId)}
                                                                                        // tempDocs={this.props.tempDocs}
                                                                                        // tempDeletedDocs={this.props.tempDeletedDocs}
                                                                                        showDocumentsModal={showDocPatchModal}
                                                                                        toggleDocumentsModal={(reloadDetails: boolean, document: any) => {
                                                                                            this.toggleShowDocModal();
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                        </div>
                                                    </Accordion>
                                                ))
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FirstCentralCreditBureauCheck;
