import React, { Component, useContext } from "react";
import {
    Button,
    Card,
    useAccordionToggle,
    AccordionContext,
    Accordion,
    Modal,
} from "react-bootstrap";
import _ from "lodash";
import DetailItem from "../../../../components/detailItem";
import BaseFormComponent from "../../../../components/BaseFormComponent";
import BaseListComponent from "../../../../components/BaseListComponent";
import { generateGUID } from "../../../../services/utility/textFormatService";
import textFormatService from "../../../../services/utility/textFormatService";
import groupLoanRequestMembersService from "../../../../services/hybridAppraisal/groupLoanRequestMembersService";
import { toast } from "react-toastify";

export function ContextAwareToggle({ eventKey, header }) {
    const currentEventKey = useContext(AccordionContext);
    const toggleOnClick = useAccordionToggle(eventKey);
    const isCurrentEventKey = currentEventKey === eventKey;
    const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
    return (
        <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
            <b>{header}</b>
            <i
                className={angleType}
                aria-hidden="true"
                style={{ float: "right" }}
            ></i>
        </Card.Header>
    );
}

interface PolicyScoringCheckFields {
    accountNumber: string;
    statementType: string;
    bankName: string;
}

class PolicyScoringCheckForm extends BaseFormComponent<PolicyScoringCheckFields> { }

interface PolicyScoringCheckProps {
    item: any;
    values: any;
    systemProcess: any;
    userRoles: any;
    togglePatchModal: any;
    showPatchModal: boolean;
}

interface PolicyScoringCheckState {
    item: any;
    userRoles: any;
    systemProcess: any;
    policyResponse: any;
    scoreResponse: any;
    ownUpdate: boolean;
    submitting: boolean;
    member: any;
    groupMember: any;
    acceptanceScore: string;
}

class PolicyScoringList extends BaseListComponent<PolicyScoringCheckState> { }

class GroupPolicyScoringCheck extends Component<PolicyScoringCheckProps, PolicyScoringCheckState> {
    _isMounted = false;
    constructor(props: PolicyScoringCheckProps) {
        super(props);
        this.state = {
            item: {},
            userRoles: [],
            systemProcess: [],
            policyResponse: [],
            scoreResponse: [],
            ownUpdate: false,
            submitting: false,
            member: {},
            groupMember: {},
            acceptanceScore: ""
        };
    }

    static getDerivedStateFromProps(nextProps: PolicyScoringCheckProps, prevState) {
        if (prevState.ownUpdate) {
            return {
                ownUpdate: false,
            };
        }
        if (_.isNull(nextProps.item)) {
            return null;
        }
        return {
            item: nextProps.item,
            userRoles: nextProps.userRoles,
            systemProcess: nextProps.systemProcess
        };
    }

    async componentDidMount() {
        this._isMounted = true;

        const acceptanceScore = this.props.item.financialGroup.rules.filter(
            (rule) =>
                rule.level === this.props.item.currentLevel &&
                rule.systemProcessID === Number(8)
        )[0]?.acceptanceScore;

        // console.log(this.props.item.appraisalActions.filter(x => x.systemProcessID == 2)[0]?.processResponse?.response)
        // console.log(policyResponse)
        if (this._isMounted) {
            this.setState({ acceptanceScore: acceptanceScore, ownUpdate: true });
        }

    }
    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        const {
            item,
            systemProcess,
            userRoles,
            showPatchModal,
            togglePatchModal,
            values
        } = this.props;
        const { policyResponse, scoreResponse, groupMember } = this.state;
        console.log(values)
        return (
            <Modal
                size="xl"
                backdrop="static"
                show={showPatchModal}
                onHide={togglePatchModal}
            >
                <Modal.Header>
                    <Modal.Title>
                        <h5>
                            <i className="fas fa-edit fa-sm cyan-text mr-3" />
                            Scoring Report
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Reports */}
                    <div className="card mt-3">
                        <Accordion defaultActiveKey="0">
                            <div>
                                <Card>
                                    <ContextAwareToggle
                                        eventKey={`0`}
                                        header={<React.Fragment>
                                            <i
                                                className="fa fa-info-circle"
                                                aria-hidden="true"
                                                style={{ color: "#878a8a" }}
                                            ></i>{" "}
                                            Scoring Report
                                        </React.Fragment>}
                                    ></ContextAwareToggle>
                                    <Accordion.Collapse eventKey={`0`}>
                                        <Card.Body>
                                            <table className="table table-hover table-content table-sm table-striped mb-0">
                                                <thead>
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>Rule Description</strong>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                // width: "5px",
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>Score</strong>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* {console.log(this.props.member)} */}
                                                    {console.log(values)}
                                                    {values?.length === 0 ? (
                                                        <React.Fragment>
                                                            <tr>
                                                                <td>
                                                                    No Decisions Made yet
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ) : (

                                                        values?.map((action) => (
                                                            <React.Fragment key={generateGUID()}>
                                                                <tr>
                                                                    <td
                                                                        style={{
                                                                            paddingTop: "0.2rem",
                                                                            paddingBottom: "0.2rem",
                                                                            fontSize: "small",
                                                                        }}
                                                                    >
                                                                        {action.IndicatorName}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            paddingTop: "0.2rem",
                                                                            paddingBottom: "0.2rem",
                                                                            fontSize: "small",
                                                                        }}
                                                                    >
                                                                        {/* {console.log(systemProcess?.filter(x => x.id === Number(action.systemProcessID)))[0]?.name} */}
                                                                        {action.Score}
                                                                    </td>
                                                                </tr>
                                                                {/* ))} */}
                                                            </React.Fragment>
                                                        ))
                                                    )}
                                                </tbody>
                                            </table>

                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </div>
                        </Accordion>
                    </div>


                </Modal.Body >

                <Modal.Footer>
                    <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={() => togglePatchModal(false, {}, {})}
                        disabled={this.state.submitting}
                    >
                        <i className="fas fa-times mr-3" />
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default GroupPolicyScoringCheck;



