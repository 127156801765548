import http from "../httpService";
import { IFilterData } from "../approval/approvalItemService";
import { getCurrentUserSession } from "../auth/authService";
import { decodeToken } from "react-jwt";

const appraisalBaseAddress = process.env.REACT_APP_HYBRIDAPPRAISAL_BASEADDRESS;

export function saveLoanProductConfig(payload) {
  return http.post(
    appraisalBaseAddress + `LoanProductConfig/ConfigureLoanProduct`,
    payload
  );
}

export function updateLoanProductConfig(payload) {
  return http.put(
    appraisalBaseAddress + `LoanProductConfig/EditLoanProductConfig`,
    payload
  );
}

export function retrieveById(id) {
  return http.get(
    appraisalBaseAddress + `LoanProductConfig/RetrieveById/${id}`
  );
}

export function retrieveByProductCode(institutionCode, productCode) {
  return http.get(
    appraisalBaseAddress + `LoanProductConfig/RetrieveByProductCode/${institutionCode}/${productCode}`
  );
}

export function retrieveByInstitutionCode(institutionCode) {
  return http.get(
    appraisalBaseAddress + `LoanProductConfig/RetrieveByInstitutionCode/${institutionCode}`
  );
}

export function retrieveAll() {
  return http.get(
    appraisalBaseAddress + `LoanProductConfig/RetrieveAll`
  );
}

export function filterLoanProductConfig(
  payload: IFilterData,
  maxSize: number,
  pageNum: number
) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  payload = { ...payload };
  payload.institutionCode = {
    item1: "=",
    item2: decodedToken?.["InstitutionCode"]?.toString() || "break",
  };
  return http.post(
    appraisalBaseAddress +
    `LoanProductConfig/FilterLoanProductConfig?pageNum=${pageNum}&maxSize=${maxSize}`,
    payload
  );
}

export default {
  saveLoanProductConfig,
  updateLoanProductConfig,
  retrieveById,
  retrieveByProductCode,
  retrieveByInstitutionCode,
  retrieveAll,
  filterLoanProductConfig,
};
