import React from "react";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../components/detailItem";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import collectionValidationService from "../../../services/collection/collectionValidationService";
import collectionScheduleService from "../../../services/collection/collectionScheduleService";

interface ModifyCollectionConfigFields {
  switchRecoveryWindowInDays: string;
}

class ModifyCollectionConfigForm extends BaseFormComponent<ModifyCollectionConfigFields> { }

interface IModifyCollectionConfigProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}
interface IModifyCollectionConfigState { }

class ModifyCollectionConfig extends React.Component<
  IModifyCollectionConfigProps,
  IBaseFormState & IModifyCollectionConfigState
> {
  constructor(props: IModifyCollectionConfigProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
    };
  }

  onFormSubmit(fields: ModifyCollectionConfigFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors:
            collectionValidationService.validateModifyCollectionConfigForm(
              fields
            ),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (fields: ModifyCollectionConfigFields, onReloadFieldsCB: any) => {
    try {
      this.setState({ submitting: true });
      const payload: any = {};
      payload.recoverySwitchMode = this.props.item.recoverySwitchMode;
      payload.switchRecoveryWindowInDays = Number(fields.switchRecoveryWindowInDays);

      if (payload.switchRecoveryWindowInDays < 0) {
        toast.info(`Value cannot be negative `, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
        this.setState({ submitting: false });
      } else {
        //   console.log("For checks::", payload);
        const response = await collectionScheduleService.modifyCollectionConfigOfSchedule(
          payload,
          this.props.item.id
        );
        if (response.status >= 200 && response.status <= 300) {
          await activityTrailService.saveActivityTrail({
            actionType: "Modify Collection Config",
            description: `Modified Collection Config for ${this.props.item.customerName}`,
          });

          toast.info(`Collection Config for ${this.props.item.customerName} Modified successfully! `, {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          });
        }
        onReloadFieldsCB();
        this.setState({ submitting: false }, () =>
          this.props.toggleEditModal(true, this.props.item)
        );
      }
    } catch (error) {
      // console.log("submission error: ", error.response);
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // console.log("submission error: ", error.response);
      }
      this.setState({ submitting: false });
    }
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      errors: {},
    });
  }

  render() {
    const { item, showEditModal, toggleEditModal } = this.props;
    const { validated, submitting, errors } = this.state;

    return (
      <Modal
        size="lg"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-columns fa-sm cyan-text mr-3" />
              Modify Collection Config -{" "}
              <span className="font-weight-bold">{item.customerName}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <ModifyCollectionConfigForm
          initialValues={{
            switchRecoveryWindowInDays: item.switchRecoveryWindowInDays?.toString()
          }}
          FormComponent={({
            fields: { switchRecoveryWindowInDays },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  {/* <div className="card-header clear-fix">
                    {_.isEmpty(item) ? (
                      <Skeleton width={200} />
                    ) : (
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        <b>General Information</b>
                      </h6>
                    )}
                  </div> */}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        {_.isEmpty(item) ? (
                          <Skeleton count={1} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Switch Recovery Window In Days"
                              value={
                                <DetailItemInputComponent
                                  id="switchRecoveryWindowInDays"
                                  type="number"
                                  name="switchRecoveryWindowInDays"
                                  placeholder=""
                                  value={switchRecoveryWindowInDays}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={6}
                              valueSize={6}
                            />
                          </dl>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.UPDATE,
                      "Collection Config",
                      () => {
                        this.onFormSubmit(
                          { switchRecoveryWindowInDays },
                          onReloadFields
                        );
                      }
                    );
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></ModifyCollectionConfigForm>
      </Modal>
    );
  }
}

export default ModifyCollectionConfig;
