import { Http2ServerRequest } from "http2";
import { IFilterData } from "../approval/approvalItemService";
import http from "../httpService";

const sweepBaseAddress = process.env.REACT_APP_SWEEP_BASEADDRESS;
export function processReactiveSweep2(document, institutionCode) {
  return http.post(
    sweepBaseAddress + `SweepRun/ProcessReactiveSweep2/${institutionCode}`,
    document
  );
}

export function processOnDemandReactiveSweep(
  institutionCode,
  externalReference
) {
  return http.post(
    sweepBaseAddress +
    `SweepRun/ProcessOnDemandReactiveSweep?institutionCode=${institutionCode}&externalReference=${externalReference}`
  );
}

export function searchSweepEpisodes(
  loanInstitutionCode: string,
  fromDate: string,
  toDate: string,
  pageSize: number,
  currentPage: number
) {
  return http.get(
    sweepBaseAddress +
    `SweepRun/SearchSweepEpisodes?loanInstitution=${loanInstitutionCode}&pageSize=${pageSize}&pageNumber=${currentPage - 1
    }`
  );
}

export function retrieveSweepEpisodeById(id: number) {
  return http.get(sweepBaseAddress + `SweepRun/GetSweepEpisodeById?Id=${id}`);
}

export function retrieveRepaymentsBySweepEpisodeId(
  loanInstitutionCode: string,
  id: number,
  pageSize: number,
  currentPage: number
) {
  return http.get(
    sweepBaseAddress +
    `SweepRun/GetRepaymentsBySweepEpisodeId?loanInstitution=${loanInstitutionCode}&sweepEpisodeId=${id}&pageSize=${pageSize}&pageNumber=${currentPage - 1
    }`
  );
}

export function retrieveCurrentSweepRunStatus(institutionCode: string) {
  return http.get(
    sweepBaseAddress +
    `SweepRun/GetCurrentSweepRunStatus?institutionCode=${institutionCode}`
  );
}

export function retrieveSweepActivities() {
  return http.get(sweepBaseAddress + `SweepRun/RetrieveAllSweepEpisodeActivityInfos`);
}

export function filterSweepActivities(data: IFilterData, pageSize: number, currentPage: number) {
  return http.post(sweepBaseAddress + `SweepRun/FilterPagedSweepEpisodeActivityInfos?pageNum=${currentPage}&maxSize=${pageSize}`, data)
}

export function retrieveSweepEpisodeActivityInfoById(id: number) {
  return http.get(sweepBaseAddress + `SweepRun/RetrieveSweepEpisodeActivityInfoById/${id}`)
}

export function resetSweepEpisode(institutionCode: string) {
  return http.get(sweepBaseAddress + `SweepRun/ResetSweepEpisode/${institutionCode}`);
}

export default {
  searchSweepEpisodes,
  retrieveSweepEpisodeById,
  retrieveRepaymentsBySweepEpisodeId,
  retrieveCurrentSweepRunStatus,
  processReactiveSweep2,
  processOnDemandReactiveSweep,
  retrieveSweepActivities,
  filterSweepActivities,
  retrieveSweepEpisodeActivityInfoById,
  resetSweepEpisode
};
