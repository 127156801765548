import React, { Component, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button, Accordion, Card } from "react-bootstrap";
import { splitAtUpper } from "../../../services/utility/textFormatService";
import { ContextAwareToggle } from "../appraisalPlan/appraisalPlanDetail";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { dataEntryFields as dataEntry } from "./dataEntryFields";
import DetailItem from "../../../components/detailItem";

interface ISupplierDetailProps {
    showDetailsModal: boolean;
    toggleDetailsModal: any;
    toggleEditModal: any;
    showTrail: any;
    item: any;
}

interface ISupplierDetailState {
    item: any;
    updatingStatus: boolean;
}

class SupplierDetail extends Component<
    ISupplierDetailProps,
    ISupplierDetailState
> {
    state = {
        item: {},
        updatingStatus: false,
    };

    static getDerivedStateFromProps(nextProps: ISupplierDetailProps, prevState) {
        return {
            item: nextProps.item,
        };
    }

    render() {
        const {
            showDetailsModal,
            toggleDetailsModal,
            toggleEditModal,
            item,
        } = this.props;
        const { updatingStatus } = this.state;

        return (
            <Modal
                size="xl"
                backdrop="static"
                show={showDetailsModal}
                onHide={toggleDetailsModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {_.isEmpty(item) ? (
                            <Skeleton width={500} />
                        ) : (
                            <h5>
                                <i className="icon icon--columns s-18 mr-3" />
                                Supplier Details {" "}
                                <span className="font-weight-bold">{item?.name}</span>
                            </h5>
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <React.Fragment>
                        <div className="card">

                            <Accordion defaultActiveKey="0">
                                <Card>
                                    <ContextAwareToggle
                                        eventKey={`0`}
                                        header={
                                            <React.Fragment>
                                                <i
                                                    className="fa fa-info-circle"
                                                    aria-hidden="true"
                                                    style={{ color: "#878a8a" }}
                                                ></i>{" "}
                                                {item?.name}{" "} Details
                                            </React.Fragment>
                                        }
                                    ></ContextAwareToggle>
                                    <Accordion.Collapse eventKey={`0`}>
                                        <Card.Body>
                                            <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        {_.isEmpty(item) ? (
                                                            <div style={{ textAlign: "center", color: "#FE825E" }}>
                                                                No Supplier Details Available
                                                            </div>
                                                        ) : (
                                                            <Fragment>
                                                                <dl className="row">
                                                                    <DetailItem
                                                                        label="Bank Name"
                                                                        value={item.bankName}
                                                                        labelSize={5}
                                                                        valueSize={7}
                                                                    />
                                                                    <DetailItem
                                                                        label="Account Number"
                                                                        value={item.accountNumber}
                                                                        labelSize={5}
                                                                        valueSize={7}
                                                                    />
                                                                    <DetailItem
                                                                        label="Phone Number"
                                                                        value={item.phoneNumber}
                                                                        labelSize={5}
                                                                        valueSize={7}
                                                                    />
                                                                    <DetailItem
                                                                        label="Address"
                                                                        value={item.address}
                                                                        labelSize={5}
                                                                        valueSize={7}
                                                                    />
                                                                </dl>
                                                            </Fragment>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                    </React.Fragment>
                </Modal.Body >
                <Modal.Footer>
                    {_.isEmpty(item) ? (
                        <Skeleton width={100} count={2} height={30} />
                    ) : (
                        <React.Fragment>
                            <Button
                                size="sm"
                                variant="primary"
                                disabled={updatingStatus}
                                onClick={toggleEditModal}
                            >
                                <i className="far fa-edit mr-3" />
                                Edit
                            </Button>
                        </React.Fragment>
                    )}
                    <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={toggleDetailsModal}
                    >
                        <i className="fas fa-times mr-3" />
                        Close
                    </Button>
                </Modal.Footer>
            </Modal >
        );
    }
}

export default SupplierDetail;
