import http from "./../httpService";
// import { getCurrentUserSession } from "../auth/authService";
// import { IFilterData } from "../approval/approvalItemService";
// import { decodeToken } from "react-jwt";

export function retrieveAllBankOneSavingsCurrentProducts(institutionId) {
    return http.get(process.env.REACT_APP_AUTH_BASEADDRESS + `SavingsCurrentProduct/RetrieveAllBankOneSavingsCurrentProducts/${institutionId}`);
}

export default {
    retrieveAllBankOneSavingsCurrentProducts,
};