import React, { Fragment } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import authValidationService from "../../../services/auth/authValidationService";
import rolesService from "../../../services/auth/rolesService";
import DetailItem from "../../../components/detailItem";
import { ActionTypes } from "../../../enums/actionTypes";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import CheckboxTree from "react-checkbox-tree";
import Skeleton from "react-loading-skeleton";
import { ReportItems } from "../../../enums/reportItems";
import mailingListService from "../../../services/auth/mailingListService";
import DetailItemSelectComponent, { ISelectItems } from "../../../components/detailItemSelectBox";
import { Frequency } from "../../../enums/frequency";
import { ReportFormat } from "../../../enums/reportFormat";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import FormInputComponent from "../../../components/formInputComponent";

interface EditMailingListFields {
  name: string;
  frequency: string;
  reportFormat: string;
  recipientName: string;
  recipientEmail: string;
}

class EditMailingListForm extends BaseFormComponent<EditMailingListFields> { }

interface IEditMailingListProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
  //   functions: any;
}
interface IEditMailingListState {
  availableReportItems: any;
  reportItems: any;
  recipients: any;
  recipientError: any;
  ownUpdate: boolean;
}

class EditMailingList extends React.Component<
  IEditMailingListProps,
  IBaseFormState & IEditMailingListState
> {
  constructor(props: IEditMailingListProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      availableReportItems: Object.keys(ReportItems)
        .filter((value) => isNaN(Number(value)) === false)
        .map((key) => ({
          id: ReportItems[key],
          description: ReportItems[key],
        })),
      reportItems: [],
      recipients: [],
      recipientError: {},
      ownUpdate: false,
    };
  }

  static getDerivedStateFromProps(nextProps: IEditMailingListProps, prevState) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (!_.isEmpty(nextProps.item)) {
      // console.log("current props edit roles: ", nextProps.item);
      let reportItemsArray = JSON.parse(nextProps.item.reportItems);
      let recipientsArray = (JSON.parse(nextProps.item.recepients)).map(recipient => ({
        name: recipient.Name,
        email: recipient.Email,
      }));

      return {
        reportItems: reportItemsArray,
        recipients: recipientsArray
      };
    }
    return null;
  }

  onFormSubmit(fields: EditMailingListFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors: authValidationService.validateEditMailingListForm(fields),
          ownUpdate: true,
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            // console.log("state testing: ", this.state);
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  submit = async (
    fields: EditMailingListFields,
    onReloadFieldsCB: any
  ): Promise<boolean> => {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const payload = this.props.item;

      payload.name = fields.name;
      payload.frequency = fields.frequency;
      payload.reportFormat = fields.reportFormat;
      payload.reportItems = this.state.reportItems;
      payload.recepients = this.state.recipients;

      // console.log("about to update: ", payload);

      const response = await mailingListService.updateMailingList(payload);

      await activityTrailService.saveActivityTrail({
        actionType: "Edit Mailing List",
        description: `Edited details for Mailing List  ${fields.name}`,
      });

      toast.info(`Mailing List ${fields.name} Edited successfully! `, {
        autoClose: 6000,
        type: toast.TYPE.DEFAULT,
        hideProgressBar: false,
      });

      onReloadFieldsCB();
      this.setState({ submitting: false, ownUpdate: true }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      this.setState({ submitting: false, ownUpdate: true });
      // console.log("submission error: ", error);
      return false;
    } finally {
      return true;
    }
  };

  handleOnAddMailingListItem = (item) => {
    this.setState(
      {
        // errors: []
        recipientError: authValidationService.validateMailingListRecipientForm(
          item
        ),
        ownUpdate: true
      },
      () => {
        if (Object.keys(this.state.recipientError).length === 0) {
          // console.log(item)
          const { recipients } = this.state;

          var checker = recipients.filter((x) => x.email === item.email);
          if (_.isEmpty(checker)) {
            recipients.push(item);
          } else {
            toast.error(`Recipient with email ${item.email} has already been added`)
          }
          // data.name = "";
          // data.email = "";
          this.setState({ recipients, ownUpdate: true });
        } else {
          const recipientError = { ...this.state.recipientError };
          if ("email" in recipientError) {
            delete Object.assign(recipientError, { ['recipientEmail']: recipientError['email'] })['email'];
          }
          if ("name" in recipientError) {
            delete Object.assign(recipientError, { ['recipientName']: recipientError['name'] })['name'];
          }
          this.setState({ recipientError, ownUpdate: true });
        }
      }
    );
  };

  handleOnRemoveMailingListItem = (item) => {
    // console.log(item)
    const { recipients } = this.state;

    _.remove(recipients, function (n: any) { return n.email === item.email; })

    this.setState({ recipients, ownUpdate: true });
  };

  handleOnReportItemChange = (item) => {
    const reportItems = [...this.state.reportItems];
    if (reportItems.indexOf(item) !== -1) {
      reportItems.splice(reportItems.indexOf(item), 1);
    } else {
      reportItems.push(item);
    }
    this.setState({ reportItems, ownUpdate: true });
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false
    });
  }

  render() {
    const { item, showEditModal, toggleEditModal } = this.props;
    const { validated, submitting, errors, availableReportItems, recipients } = this.state;

    let frequencyInputData: ISelectItems[] = Object.keys(Frequency)
      .filter((value) => isNaN(Number(value)) === false)
      .map((key) => ({
        name: Frequency[key],
        value: Frequency[key],
      }));

    let reportFormatInputData: ISelectItems[] = Object.keys(ReportFormat)
      .filter((value) => isNaN(Number(value)) === false)
      .map((key) => ({
        name: ReportFormat[key],
        value: ReportFormat[key],
      }));

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-user-shield fa-sm cyan-text mr-3" />
              Edit Mailing List -{" "}
              <span className="font-weight-bold">{item.name}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditMailingListForm
          initialValues={{
            name: item.name,
            frequency: item.frequency,
            reportFormat: item.reportFormat,
            recipientEmail: "",
            recipientName: ""
          }}
          FormComponent={({
            fields: { name, frequency, reportFormat, recipientEmail, recipientName },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-header clear-fix">
                        <h6
                          className="card-title float-left"
                          style={{ marginBottom: "0px" }}
                        >
                          Mailing List Details
                        </h6>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="form-group col-md-12 row">
                            <DetailItem
                              label="Name"
                              value={
                                <DetailItemInputComponent
                                  id="name"
                                  type="text"
                                  name="name"
                                  placeholder=""
                                  value={name}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                            />
                          </div>
                          <div className="form-group col-md-12 row">
                            <DetailItem
                              label="Frequency"
                              value={
                                <DetailItemSelectComponent
                                  id="frequency"
                                  name="frequency"
                                  value={frequency}
                                  items={frequencyInputData}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                            />
                          </div>
                          <div className="form-group col-md-12 row">
                            <DetailItem
                              label="Report Format"
                              value={
                                <DetailItemSelectComponent
                                  id="reportFormat"
                                  name="reportFormat"
                                  value={reportFormat}
                                  items={reportFormatInputData}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                            />
                          </div>
                          <br />
                          <br />
                          <br />
                          <br />


                          <label
                            htmlFor="reportItems"
                            className="control-label textbox-label"
                          >
                            {"  "} Report Items
                          </label>
                          <div
                            className="text-nowrap block-example border"
                            style={{
                              width: "100%",
                              height: "200px",
                              overflowY: "scroll",
                            }}
                          >
                            <React.Fragment>
                              {availableReportItems.length ===
                                0 ? (
                                <div
                                  style={{
                                    backgroundColor: "#F3F3F3",
                                  }}
                                >
                                  <p className="text-center">
                                    No Available Report Items Found
                                  </p>
                                </div>
                              ) : (
                                <React.Fragment>
                                  {availableReportItems.map(
                                    (item) => (
                                      <div
                                        className="custom-control custom-checkbox"
                                        key={availableReportItems.indexOf(
                                          item
                                        )}
                                      >
                                        <input
                                          id={item.id}
                                          name={item.id}
                                          className="custom-control-input"
                                          type="checkbox"
                                          onChange={() =>
                                            this.handleOnReportItemChange(
                                              item.id
                                            )
                                          }
                                          checked={
                                            _.isEmpty(
                                              this.state.reportItems.filter(
                                                (x) => x === item.id
                                              )
                                            )
                                              ? false
                                              : true
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={item.id}
                                        >
                                          {item.description}
                                        </label>
                                      </div>
                                    )
                                  )}
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-header clear-fix">
                        <h6
                          className="card-title float-left"
                          style={{ marginBottom: "0px" }}
                        >
                          Recipient Information
                        </h6>
                      </div>
                      <div className="card-body">
                        {_.isEmpty(item) ? (
                          <Skeleton count={5} />
                        ) : (
                          <Fragment>
                            <FormInputComponent
                              id="recipientName"
                              type="text"
                              name="recipientName"
                              placeholder=""
                              value={recipientName}
                              required={true}
                              validated={validated}
                              errors={this.state.recipientError}
                              onChange={onChange}
                            />
                            <FormInputComponent
                              id="recipientEmail"
                              type="email"
                              name="recipientEmail"
                              placeholder=""
                              value={recipientEmail}
                              required={true}
                              validated={validated}
                              errors={this.state.recipientError}
                              onChange={onChange}
                            />
                            <button
                              className="btn btn-sm btn-outline-primary float-right my-3"
                              type="button"
                              disabled={_.isEmpty(recipientName) || _.isEmpty(recipientEmail)}
                              onClick={() =>
                                this.handleOnAddMailingListItem({ name: recipientName, email: recipientEmail })
                              }
                            >
                              <React.Fragment>
                                <i className="far fa-save fa-lg mr-4" /> Save
                              </React.Fragment>
                            </button>
                            <br />
                            <h5 className="text-center mt-5">Recipient List</h5>
                            <div
                              className="table-responsive text-nowrap"
                              style={{
                                height: "300px",
                                overflowY: "scroll",
                              }}
                            >
                              <table className="table table-hover table-content table-sm table-striped mb-0">
                                <thead>
                                  <tr>
                                    <th
                                      scope="col"
                                      style={{
                                        width: "5px",
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>#</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Recipient Name</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        // width: "5px",
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Recipient Email</strong>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {recipients.length === 0 ? (
                                    <React.Fragment>
                                      <tr>
                                        No contacts added yet
                                      </tr>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      {recipients.map((item) => (
                                        <tr key={recipients.indexOf(item)}>
                                          <td
                                            style={{
                                              width: "5px",
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                              fontSize: "small",
                                            }}
                                          >
                                            {recipients.indexOf(
                                              item
                                            ) + 1}
                                          </td>
                                          <td
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                              fontSize: "small",
                                            }}
                                          >
                                            {item.name}
                                          </td>
                                          <td
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                              fontSize: "small",
                                            }}
                                          >
                                            {item.email}
                                          </td>
                                          <td
                                            style={{
                                              paddingTop: "0.2rem",
                                              paddingBottom: "0.2rem",
                                              fontSize: "small",
                                            }}
                                          >
                                            <i
                                              className="far fa-trash-alt ml-5 red-text fa-lg"
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                this.handleOnRemoveMailingListItem(
                                                  item
                                                )
                                              }
                                            ></i>
                                          </td>
                                        </tr>
                                      ))}
                                    </React.Fragment>
                                  )}
                                </tbody>
                              </table>
                            </div>

                          </Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(e, ActionTypes.UPDATE, "Mailing List", () => {
                      this.onFormSubmit({ name, recipientEmail, frequency, recipientName, reportFormat }, onReloadFields);
                    });
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></EditMailingListForm>
      </Modal>
    );
  }
}

export default EditMailingList;
