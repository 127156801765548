import React, { Fragment } from "react";
import _ from "lodash";
import { Nav } from "react-bootstrap";
import { getCurrentUserSession } from "../../../services/auth/authService";
import DetailedDataEntry from "./appraisalDetailPages/viewDetailedDataEntry";
import NotePad from "./appraisalDetailPages/wizardComponents/viewDetailedDataEntry/notepad";
import BankAnalysisCheck from "./appraisalDetailPages/BankAnalysisCheck";
import BankAnalysisReview from "./appraisalDetailPages/BankAnalysisReview";
import BankOneCreditReview from "./appraisalDetailPages/BankOneCredit";
import CreditBureauReview from "./appraisalDetailPages/creditBureau";
import CreditSummary from "./appraisalDetailPages/creditSummary";
import ExternalCreditAssessmentCheck from "./appraisalDetailPages/externalCreditAssessmentCheck";
import ExternalCreditAssessmentReview from "./appraisalDetailPages/externalCreditAssessment";
import PolicyScoringCheck from "./appraisalDetailPages/policyScoringCheck";
import PolicyScoringReview from "./appraisalDetailPages/policyScoringReview";
import PolicyScoringResult from "./appraisalDetailPages/policyScoringResult";
import FinancialAnalysisCheck from "./appraisalDetailPages/FinancialAnalysisScreen";
import CreditAnalysis from "./appraisalDetailPages/creditAnalysis";
import SpecialConditions from "./appraisalDetailPages/SpecialConditionsScreen";
import DocumentApproval from "./appraisalDetailPages/DocumentApprovalScreen";
import OfferLetterScreen from "./appraisalDetailPages/offerLetterScreen";
import DocumentCollection from "./appraisalDetailPages/postApprovalDcoumentScreen";
import DocumentManagement from "./appraisalDetailPages/DocumentManagement";
import { decodeToken } from "react-jwt";
import LoanDisbursement from "./appraisalDetailPages/loanDisbursementScreen";
import FAMSummary from "./appraisalDetailPages/wizardComponents/editDetailedDataEntry/FAM/summary";

interface IAppraisalWorkflowProps {
  item: any;
  userRoles: any;
  systemProcess: any;
}

interface IAppraisalWorkflowState {
  item: any;
  title: string;
  ownUpdate: boolean;
  roleId: string;
  documentsToUpload: any;
  documentsToDelete: number[];
  updateAvailable: boolean;
}

class AppraisalWorkflow extends React.Component<
  IAppraisalWorkflowProps,
  IAppraisalWorkflowState
> {
  constructor(props: IAppraisalWorkflowProps) {
    super(props);
    this.state = {
      item: {},
      title: "",
      ownUpdate: false,
      roleId: "",
      documentsToUpload: [],
      documentsToDelete: [],
      updateAvailable: false,
    };
  }

  static getDerivedStateFromProps(
    nextProps: IAppraisalWorkflowProps,
    prevState
  ) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isNull(nextProps.item)) {
      return null;
    }
    return {
      item: nextProps.item,
    };
  }

  updateApprovalItemDocs = async (document: any) => {
    try {
      const docs = [...this.state.documentsToUpload];
      docs.push(document);
      this.setState({ documentsToUpload: docs, updateAvailable: true });
    } catch (error) {
      // log
    }
  };

  deleteApprovalItemDocs = async (document: any, documentId: number) => {
    try {
      if (document != null) {
        const docs = [...this.state.documentsToUpload];
        let selectedDoc = docs.filter(
          (doc) =>
            doc.document === document.document && doc.name === document.name
        )[0];
        docs.splice(docs.indexOf(selectedDoc), 1);
        this.setState({ documentsToUpload: docs, updateAvailable: true });
      } else {
        const docs = [...this.state.documentsToDelete];
        docs.push(documentId);
        this.setState({ documentsToDelete: docs, updateAvailable: true });
      }
    } catch (error) {
      // log
    }
  };

  async componentDidMount() {
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    // console.log(decodedToken.UserRoleId)

    this.setState({ roleId: decodedToken.UserRoleId });
  }

  onChangePage = (title) => {
    this.setState({ title, ownUpdate: true });
  };

  approvalDetailsPage = (title) => {
    var item = this.state.item;
    const { userRoles, systemProcess } = this.props;
    switch (title) {
      case "":
        return (
          <CreditSummary
            item={item}
            systemProcess={systemProcess}
            userRoles={userRoles}
            currentUserSession={getCurrentUserSession()}
          />
        );
      case "Detailed Data Entry":
        return <DetailedDataEntry item={item} />;
      case "Notepad":
        return <NotePad values={item} />;

      case "Data Validation":
        return <DetailedDataEntry item={item} />;

      // Credit Analysis Internal, External and Bankone Check
      case "Credit Analysis":
      case "Credit Bureau":
      case "BankOne Credit":
      case "Credit Analysis (Internal)":
      case "Credit Analysis (External)":
      case "Credit Analysis (FCCB)":
      case "Credit Analysis (CRC)":
      case "Credit Analysis (BankOne)":
        return <CreditAnalysis item={item} title={title} />;

      // Credit Analysis Internal, External and Bankone Review
      case "Credit Bureau Review":
        return (
          <CreditBureauReview
            item={item}
            systemProcess={systemProcess}
            userRoles={userRoles}
          />
        );
      case "BankOne Credit Review":
        return (
          <BankOneCreditReview
            item={item}
            systemProcess={systemProcess}
            userRoles={userRoles}
          />
        );

      // Bank analysis
      case "Bank Analysis":
        return <BankAnalysisCheck item={item} />;

      case "Financial Analysis":
        return <FinancialAnalysisCheck item={item} />;
      case "Bank Analysis Review":
        return (
          <BankAnalysisReview
            item={item}
            systemProcess={systemProcess}
            userRoles={userRoles}
          />
        );

      case "Special Conditions":
        return (
          <SpecialConditions
            item={item}
            systemProcess={systemProcess}
            userRoles={userRoles}
            currentUserSession={getCurrentUserSession()}
          />
        );

      // Financial analysis
      case "Financial Analysis":
        return <FinancialAnalysisCheck item={item} />;

      case "Document Review":
        return <DocumentApproval values={item} />;

      case "Document Management":
        return <DocumentManagement item={item} />;

      case "Document Upload":
        return <DocumentCollection values={item} />;

      // Corporate Analysis
      case "Corporate Analysis":
        return <FAMSummary loan={item} />;
      // Credit summary
      case "Credit Summary":
        return (
          <CreditSummary
            item={item}
            systemProcess={systemProcess}
            userRoles={userRoles}
            currentUserSession={getCurrentUserSession()}
          />
        );

      // External credit assessment
      case "External Credit Assessment":
        return <ExternalCreditAssessmentCheck item={item} />;
      case "External Credit Assessment Review":
        return (
          <ExternalCreditAssessmentReview
            item={item}
            systemProcess={systemProcess}
            userRoles={userRoles}
          />
        );
      case "Disbursement":
      case "Loan Disbursement":
        return (
          <LoanDisbursement
            item={item}
            values={item}
            userRoles={userRoles}
            systemProcess={systemProcess}
            updateItemDocs={(document: any) =>
              this.updateApprovalItemDocs(document)
            }
            deleteItemDocs={(document: any, documentId: number) =>
              this.deleteApprovalItemDocs(document, documentId)
            }
            tempDocs={this.state.documentsToUpload}
            tempDeletedDocs={this.state.documentsToDelete}
          />
        );

      // Policy and score card
      case "Score Card":
      case "Policy and Scoring":
      case "Score Card Review":
      case "PolicyScoring Review":
        return (
          <PolicyScoringResult
            item={item}
            systemProcess={systemProcess}
            userRoles={userRoles}
            currentUserSession={getCurrentUserSession()}
          />
        );
      default:
        return (
          <div>
            <h6 className="font-weight-normal text-center font-italic text-danger">
              ... no appraisal details page available for item.
            </h6>
          </div>
        );
    }
  };

  render() {
    const { item, systemProcess } = this.props;

    if (_.isEmpty(item)) {
      return null;
    }
    // console.log(item)

    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <div style={{ width: "100%" }} className="card no-b">
                <div className="row">
                  {/* Side NavBar */}
                  <div className="col-md-2">
                    <div className="card">
                      <div className="card-header clear-fix">
                        <h6
                          className="float-left"
                          style={{ marginBottom: "0px" }}
                        >
                          <b>Appraisal Workflow</b>
                        </h6>
                      </div>
                      <div className="card-body">
                        <Nav className="" activeKey="#">
                          {/* <Nav.Item>
                            <Nav.Link
                              title="Detailed Data Entry"
                              onClick={() => this.onChangePage("Detailed Data Entry")}
                            >
                              Data Validation
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              title="Document Management"
                              onClick={() => this.onChangePage("Document Management")}
                            >
                              Document Management
                            </Nav.Link>
                          </Nav.Item> */}

                          {/* <Nav.Item>
                            <Nav.Link
                              title="Financial Analysis"
                              onClick={() => this.onChangePage("Financial Analysis")}
                            >
                              Financial Analysis
                            </Nav.Link>
                          </Nav.Item> */}

                          {_.isEmpty(item) ? (
                            ""
                          ) : (

                            item.financialGroup?.rules.filter((a) => (a.level === item.currentLevel && a.approverType === "SystemProcess"))
                              ?.map((rule) => (
                                <Nav.Item key={rule.id}>
                                  <><Nav.Link
                                    title={systemProcess.filter(x => x.id === Number(rule.systemProcessID))[0]?.name}
                                    onClick={() => this.onChangePage(systemProcess.filter(x => x.id === Number(rule.systemProcessID))[0]?.name)}
                                  >
                                    {systemProcess.filter(x => x.id === Number(rule.systemProcessID))[0]?.name}
                                  </Nav.Link></>
                                </Nav.Item>
                              ))

                          )}
                          {_.isEmpty(item)
                            ? ""
                            : item.financialGroup?.levels
                              .filter(
                                (a) =>
                                  a.level === item.currentLevel &&
                                  a.functionNames !== ""
                              )[0]
                              ?.functionNames?.map((x) => (
                                <Nav.Item key={x.id}>
                                  <>
                                    <Nav.Link
                                      title={x}
                                      onClick={() => this.onChangePage(x)}
                                    >
                                      {x}
                                    </Nav.Link>
                                  </>
                                </Nav.Item>
                              ))}
                          {/* {item.loanType === "Corporate" && (
                            <Nav.Item>
                              <Nav.Link
                                title="Corporate Analysis"
                                onClick={() =>
                                  this.onChangePage("Corporate Analysis")
                                }
                              >
                                Corporate Analysis
                              </Nav.Link>
                            </Nav.Item>
                          )} */}
                          <Nav.Item>
                            <Nav.Link
                              title="Credit Summary"
                              onClick={() =>
                                this.onChangePage("Credit Summary")
                              }
                            >
                              Loan Summary
                            </Nav.Link>
                          </Nav.Item>

                          {/* {item.requestStatus === 'Completed' ? (
                            <Nav.Item>
                              <Nav.Link
                                title="Predisbursement Check"
                                onClick={() => this.onChangePage("Predisbursement Check")}
                              >
                                Predisbursement Check
                              </Nav.Link>
                            </Nav.Item>
                          ) : (
                            ""
                          )} */}
                        </Nav>
                      </div>
                    </div>
                  </div>

                  {/* Content */}
                  <div className="col-md-10">
                    {this.approvalDetailsPage(this.state.title)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AppraisalWorkflow;
