import http from "../httpService";

const reportBaseAddress = process.env.REACT_APP_REPORTSCENTER_BASEADDRESS

export function filterLoanRequestReport(
  pageSize: number,
  pageNumber: number,
  data: any
) {
  var startDate = typeof data.date === "undefined" ? "" : `&StartDate=${data.date.slice(1, 11)}`
  var endDate = typeof data.date === "undefined" ? "" : `&EndDate=${data.date.slice(18, 28)}`
  var loanType = typeof data.loanType === "undefined" ? "" : `&LoanType=${data.loanType}`
  var status = typeof data.status === "undefined" ? "" : `&Status=${data.status}`
  return http.get(
    reportBaseAddress +
    `v1/loan-Request?PageNumber=${pageNumber
    }&PageSize=${pageSize
    }${startDate
    }${endDate
    }${loanType
    }${status}`,
  );
}

export function filterAppraisalFailureReport(
  pageSize: number,
  pageNumber: number,
  data: any
) {
  var startDate = typeof data.date === "undefined" ? "" : `&StartDate=${data.date.slice(1, 11)}`
  var endDate = typeof data.date === "undefined" ? "" : `&EndDate=${data.date.slice(18, 28)}`
  var level = typeof data.level === "undefined" ? "" : `&Level=${data.level}`
  return http.get(
    reportBaseAddress +
    `v1/failed-Appraisal?PageNumber=${pageNumber
    }&PageSize=${pageSize
    }${startDate
    }${endDate
    }${level}`,
  );
}

export function filterDisbursedLoanReport(
  pageSize: number,
  pageNumber: number,
  data: any
) {
  var bvn = typeof data.bvn === "undefined" ? "" : `&Bvn=${data.bvn}`
  var phoneNumber = typeof data.phoneNumber === "undefined" ? "" : `&PhoneNumber=${data.phoneNumber}`
  var fullName = typeof data.fullName === "undefined" ? "" : `&FullName=${data.fullName}`
  var loanType = typeof data.loanType === "undefined" ? "" : `&LoanType=${data.loanType}`
  return http.get(
    reportBaseAddress +
    `v1/disbursed-loans?PageNumber=${pageNumber
    }&PageSize=${pageSize
    }${bvn
    }${phoneNumber
    }${fullName
    }${loanType}`,
  );
}

export default {
  filterLoanRequestReport,
  filterAppraisalFailureReport,
  filterDisbursedLoanReport
};