import http from "../httpService";
import { IFilterData } from "../approval/approvalItemService";

const appraisalBaseAddress = process.env.REACT_APP_HYBRIDAPPRAISAL_BASEADDRESS;

export function retrieveById(id) {
    return http.get(
        appraisalBaseAddress + `GroupLoanRequestMember/RetrieveById/${id}`
    );
}
export function retrieveBySolidarityGroup(solidarityGroupId) {
    return http.get(
        appraisalBaseAddress + `GroupLoanRequestMember/RetrieveBySolidarityGroup/${solidarityGroupId}`
    );
}

export function retrieveAll() {
    return http.get(
        appraisalBaseAddress + `GroupLoanRequestMember/RetrieveAll`
    );
}

export function updateLoanRequest(payload) {
    return http.put(
        appraisalBaseAddress + `GroupLoanRequestMember/EditGroupLoanRequestMember`,
        payload
    );
}

export function BatchApproveGroupLoanRequestMembers(payload) {
    return http.put(
        appraisalBaseAddress + `GroupLoanRequestMember/BatchApproveGroupLoanRequestMembers`,
        payload
    );
}
export function RetrieveTINValidation(groupLoanRequestId) {
    return http.get(
        appraisalBaseAddress + `GroupLoanRequestMember/RetrieveTINValidation/${groupLoanRequestId}`
    );
}


export function createLoanRequest(payload) {
    return http.post(
        appraisalBaseAddress + `GroupLoanRequestMember/CreateGroupLoanRequestMember`,
        payload
    );
}

export function filterLoanRequests(
    payload: IFilterData,
    maxSize: number,
    pageNum: number
) {
    payload = { ...payload };
    return http.post(
        appraisalBaseAddress +
        `GroupLoanRequestMember/FilterGroupLoanRequestMember?pageNum=${pageNum}&maxSize=${maxSize}`,
        payload
    );
}

export default {
    retrieveById,
    retrieveAll,
    updateLoanRequest,
    createLoanRequest,
    filterLoanRequests,
    retrieveBySolidarityGroup,
    BatchApproveGroupLoanRequestMembers,
    RetrieveTINValidation
}