import React, { Fragment } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import {
  Modal,
  Button,
  Card,
  Accordion,
} from "react-bootstrap";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../../components/BaseFormComponent";
import { ActionTypes } from "../../../../enums/actionTypes";
import creditAssessmentValidationService from "../../../../services/creditAssessment/creditAssessmentValidationService";
import creditQualificationRuleService from "../../../../services/creditAssessment/creditQualificationRuleService";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../../components/detailItem";
import DetailItemInputComponent from "../../../../components/detailItemInputBox";
import activityTrailService from "../../../../services/auditTrail/activityTrailService";
import { ContextAwareToggle } from "./appzoneAddCQR";
import ModifyIndicatorParams from "./modifyIndicatorParams";
import { splitAtUpper } from "../../../../services/utility/textFormatService";
import scoringCardService from "../../../../services/creditAssessment/scoringCardService";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../../../services/auth/authService";
import DetailItemSelectComponent, { ISelectItems } from "../../../../components/detailItemSelectBox";

interface EditCreditQualificationRuleAppzoneFields {
  name: string;
  minimumScore: string;
  scoreCard: string;
  // loanType: string;
}

class EditCreditQualificationRuleAppzoneForm extends BaseFormComponent<EditCreditQualificationRuleAppzoneFields> { }

interface IEditCreditQualificationRuleAppzoneProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}
interface IEditCreditQualificationRuleAppzoneState {
  ownUpdate: boolean;
  rePopulateCheckList: boolean;
  // applyPreQualificationAssessment: boolean;
  loanOriginationChannelIds: any;
  indicatorIds: any;
  // requirePositiveRepaymentPrediction: boolean;
  //   workFlows: any;
  // loanProducts: any;
  creditQualificationIndicators: any;
  loanOriginationChannels: any;

  fetchingRequiredItems: boolean;
  fetchingExistingRules: boolean;

  existingCreditQualificationRules: any;
  // selectedLoanOriginationChannels: any;
  // permissibleKnockOffBasis: any;
  showSetIndicatorParamsModal: boolean;
  indicatorItem: any;
  scoreCardsList: any;
}

class EditCreditQualificationRuleAppzone extends React.Component<
  IEditCreditQualificationRuleAppzoneProps,
  IBaseFormState & IEditCreditQualificationRuleAppzoneState
> {
  _isMounted = false;
  constructor(props: IEditCreditQualificationRuleAppzoneProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      fetchingRequiredItems: false,
      ownUpdate: false,
      rePopulateCheckList: false,
      loanOriginationChannels: [],
      creditQualificationIndicators: [],
      indicatorIds: [],
      loanOriginationChannelIds: [],
      showSetIndicatorParamsModal: false,
      indicatorItem: {},
      scoreCardsList: [],
      existingCreditQualificationRules: [],
      fetchingExistingRules: false
    };
  }

  static getDerivedStateFromProps(
    nextProps: IEditCreditQualificationRuleAppzoneProps,
    prevState: IEditCreditQualificationRuleAppzoneState
  ) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isEmpty(nextProps.item)) {
      return null;
    }
    return {
      rePopulateCheckList: true,
      indicatorIds: nextProps.item.creditQualificationRuleIndicators.map(
        (item) => item.creditQualificationIndicatorId
      ),

      // requirePositiveRepaymentPrediction:
      //   nextProps.item.requirePositiveRepaymentPrediction,
      // applyPreQualificationAssessment:
      //   nextProps.item.applyPreQualificationAssessment,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    // const { errors } = this.state;
    const { item } = this.props;
    try {
      // var institutionConfig = await institutionConfigService.retrieveByInstitutionId(
      //   decodedToken?.["InstitutionId"] || 0
      // );
      // var loanProducts = await loanProductService.retrieveAllByInstitutionCode(
      //   decodedToken?.["InstitutionCode"] || ""
      // );
      //   var workFlows = await workflowService.retrieveAllByInstitution(
      //     decodedToken?.["InstitutionCode"] || ""
      //   );
      const response = await Promise.allSettled([
        creditQualificationRuleService.retrieveCreditQualificationIndicatorsByInstitution(decodedToken?.["InstitutionCode"] || ""),
        creditQualificationRuleService.retrieveCreditQualificationRulesByInstitution(decodedToken?.["InstitutionCode"] || ""),
        scoringCardService.retrieveScoreCardByInstitutionCode(decodedToken?.["InstitutionCode"] || "")
      ])

      const creditQualificationIndicators = response[0].status == "fulfilled" ? response[0].value.data : [];
      const CreditQualificationRules = response[1].status == "fulfilled" ? response[1].value.data : [];
      const scoreCards = response[2].status == "fulfilled" ? response[2].value.data : [];
      try {
        item.creditQualificationRuleIndicators.forEach(indicatorItem => {
          const fetchedCQIndicator = creditQualificationIndicators.data.filter(indicator => indicator.id === indicatorItem.creditQualificationIndicatorId)[0]
          if (fetchedCQIndicator.creditQualificationIndicatorParams.length > 0) {
            fetchedCQIndicator.creditQualificationIndicatorParams.forEach(fetchedItemParam => {
              // console.log("indicatorItem :: ", indicatorItem)
              // console.log("fetchedItemParam :: ", fetchedItemParam);    
              fetchedItemParam.paramValue = indicatorItem.indicatorParams.filter(param => param.paramName === fetchedItemParam.paramName)[0].paramValue
            })
          }
        });

      } catch (error) {
        // console.log("error :: ", error)
      }
      // const creditQualificationIndicators =
      //   await creditQualificationRuleService.retrieveCreditQualificationIndicatorsByInstitution(
      //     decodedToken?.["InstitutionCode"] || ""
      //   );
      // const loanOriginationChannels =
      //   await creditQualificationRuleService.retrieveLoanOriginationChannels();

      if (this._isMounted) {
        this.setState({
          // institutionConfig: institutionConfig.data,
          //   workFlows: workFlows.data,
          // loanProducts: loanProducts.data,
          existingCreditQualificationRules: CreditQualificationRules,
          creditQualificationIndicators: creditQualificationIndicators,
          scoreCardsList: scoreCards,
          // loanOriginationChannels: loanOriginationChannels.data,
          fetchingRequiredItems: false,
        });
      }
    } catch (error) {
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // return false;
      }
    }
  }

  componentDidUpdate(
    prevProps: IEditCreditQualificationRuleAppzoneProps,
    prevState: IEditCreditQualificationRuleAppzoneState
  ) {
    // if (this.state.rePopulateCheckList) {
    //   let permissibleKnockOffBasis: any = [];
    //   if (this.state.permissibleKnockOffBasis) {
    //     permissibleKnockOffBasis = [...this.state.permissibleKnockOffBasis];
    //   }

    //   const { institutionConfig } = this.state;
    //   //   console.log("Current state :: ", this.state);

    //   permissibleKnockOffBasis = permissibleKnockOffBasis.map(
    //     (item) =>
    //       institutionConfig.permissibleCreditQualificationRuleIndicators?.filter(
    //         (x) => x.id === item.id
    //       )[0]
    //   );

    //   this.setState({
    //     rePopulateCheckList: false,
    //     ownUpdate: true,
    //     permissibleKnockOffBasis,
    //   });
    //   this.loadCreditQualRule("loanProductID", this.props.item.loanProductID);
    // }
    if (this.state.rePopulateCheckList) {
      let indicatorIds: any = [];
      if (this.state.indicatorIds) {
        indicatorIds = [...this.state.indicatorIds];
      }

      indicatorIds = indicatorIds.map(
        (item) =>
          this.props.item.creditQualificationRuleIndicators?.filter(
            (x) => x.creditQualificationIndicatorId === item
          )[0].creditQualificationIndicatorId
      );

      this.setState({
        rePopulateCheckList: false,
        ownUpdate: true,
        indicatorIds,
      });
      // this.loadCreditQualRule("loanProductID", this.props.item.loanProductID);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onFormSubmit(
    fields: EditCreditQualificationRuleAppzoneFields,
    onReloadFieldsCB: any
  ): boolean {
    try {
      this.setState(
        {
          errors:
            creditAssessmentValidationService.validateEditCreditQualificationRuleAppzoneForm(
              fields
            ),
          ownUpdate: true,
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return true;
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      return false;
    }
  }

  submit = async (
    fields: EditCreditQualificationRuleAppzoneFields,
    onReloadFieldsCB: any
  ) => {
    try {
      // console.log("starting submission...");
      this.setState({ submitting: true, ownUpdate: true });

      const { indicatorIds, creditQualificationIndicators, loanOriginationChannelIds } = this.state;

      const payload: any = { ...this.props.item };
      // payload.loanProductID = Number(fields.loanProductId);
      payload.name = fields.name;
      payload.minimumScore = Number(fields.minimumScore);
      payload.scoreCardId = fields.scoreCard;
      // payload.maxPercentageLoanAmountSalary = Number(fields.maxPercentageLoanAmountSalary);
      //   payload.loanOriginationWorkflowId = fields.loanOriginationWorkflowId;
      //   payload.repeatLoanOriginationWorkflowId =
      //     fields.repeatLoanOriginationWorkflowId;
      // payload.priorityLevel = Number(fields.priorityLevel);
      // payload.loanType = fields.loanType;
      // payload.requirePositiveRepaymentPrediction = this.state.requirePositiveRepaymentPrediction;
      // payload.applyPreQualificationAssessment = this.state.applyPreQualificationAssessment;
      // payload.loanOriginationChannelIds = loanOriginationChannelIds;
      // payload.indicatorIds = indicatorIds;
      payload.indicatorDataCollection = indicatorIds.map(indicatorId => {
        let paramData = creditQualificationIndicators.filter(indicator => indicator.id === indicatorId)[0].creditQualificationIndicatorParams;
        paramData = paramData.map(data => ({
          paramName: data.paramName,
          paramValue: data.paramValue,
        }))
        return {
          indicatorId: indicatorId,
          paramData: paramData
        }
      });

      //   console.log("Payload for saving :: ", payload);
      const response =
        await creditQualificationRuleService.updateCreditQualificationRule(
          payload,
          payload.id
        );

      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit Appzone Credit Qualification Rule",
          description: `Edited details for Appzone Credit Qualification Rule ${fields.name}`,
        });
        toast.info(`Credit Qualification Rule updated successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      onReloadFieldsCB();
      this.setState({ submitting: false, ownUpdate: true }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false, ownUpdate: true });
      // console.log("error: ", error.response.data);
      // console.log("error over : ", error.response);
      return false;
    }
  };

  handleOnChangeLoanOriginationChannel = (item) => {
    // console.log("clicked loan origination channel: ", item);
    const loanOriginationChannelIds = [...this.state.loanOriginationChannelIds];

    if (loanOriginationChannelIds.indexOf(item) !== -1) {
      loanOriginationChannelIds.splice(
        loanOriginationChannelIds.indexOf(item),
        1
      );
    } else {
      loanOriginationChannelIds.push(item);
    }
    // console.log("loan origination channel: ", loanOriginationChannelIds);
    this.setState({ loanOriginationChannelIds, ownUpdate: true });
  };

  // loadCreditQualRule = async (id, value) => {
  //   this.setState({ fetchingExistingRules: true, ownUpdate: true });
  //   try {
  //     var response = await creditQualificationRuleService.retrieveCreditQualificationRules();
  //     this.setState({
  //       existingCreditQualificationRules: response.data,
  //       ownUpdate: true,
  //     });
  //   } catch (error) {
  //     // console.log(error);
  //   }
  //   this.setState({ fetchingExistingRules: false, ownUpdate: true });
  // };

  handleOnCreditQualificationRuleChange = (item) => {
    // console.log("clicked Credit QUalification Rule Indicator: ", item);
    const indicatorIds = [...this.state.indicatorIds];
    if (indicatorIds.indexOf(item) !== -1) {
      indicatorIds.splice(indicatorIds.indexOf(item), 1);
      this.setState({ indicatorIds, ownUpdate: true });
    } else {
      const creditQualificationIndicators = [...this.state.creditQualificationIndicators];
      if (creditQualificationIndicators.filter(indicator => indicator.id === item)[0].creditQualificationIndicatorParams.length === 0) {
        indicatorIds.push(item);
        this.setState({ indicatorIds, ownUpdate: true });
      } else {
        this.setState({ indicatorItem: item, showSetIndicatorParamsModal: true, ownUpdate: true });
      }
    }
    // console.log("Credit QUalification Rule Indicators: ", indicatorIds);
  };

  handleOnIndicatorParamsSave = (toSave: boolean, indicatorId, payload) => {
    if (toSave) {
      // console.log("payload :: ", payload);
      const indicatorIds = [...this.state.indicatorIds];
      const creditQualificationIndicators = [...this.state.creditQualificationIndicators];
      indicatorIds.push(indicatorId);
      const credQualIndicator = creditQualificationIndicators.filter(indicator => indicator.id === indicatorId)[0];
      credQualIndicator.creditQualificationIndicatorParams = payload
      // console.log("Credit QUalification Rule Indicators: ", indicatorIds);
      this.setState({ indicatorIds, creditQualificationIndicators, showSetIndicatorParamsModal: false, ownUpdate: true });
    } else {
      this.setState({ showSetIndicatorParamsModal: false, ownUpdate: true });
    }
  };

  // toggleRequirePositiveRepaymentPredictionSwitch = () => {
  //   this.setState({
  //     requirePositiveRepaymentPrediction:
  //       !this.state.requirePositiveRepaymentPrediction,
  //     ownUpdate: true,
  //   });
  // };
  // toggleApplyPrequalificationAssessmentSwitch = () => {
  //   this.setState({
  //     applyPreQualificationAssessment:
  //       !this.state.applyPreQualificationAssessment,
  //     ownUpdate: true,
  //   });
  // };

  render() {
    const { item, showEditModal, toggleEditModal } = this.props;
    // console.log("Fetched Item :: ", item);
    if (_.isEmpty(item)) {
      return null;
    }
    const {
      errors,
      //   workFlows,
      validated,
      submitting,
      // loanProducts,
      // fetchingRequiredItems,
      loanOriginationChannelIds,
      loanOriginationChannels,
      creditQualificationIndicators,
      indicatorIds,
      indicatorItem,
      scoreCardsList,
      fetchingExistingRules,
      existingCreditQualificationRules
    } = this.state;
    // console.log("Selected Loan", selectedLoanOriginationChannels);

    // let workFlowsInputData: ISelectItems[] = workFlows.map((item) => ({
    //   name: item.workflowName,
    //   value: item.id,
    // }));
    // let loanTypeInputData: ISelectItems[] = Object.keys(LoanType)
    //   .filter((value) => isNaN(Number(value)) === false)
    //   .map((key) => ({
    //     name: LoanType[key],
    //     value: LoanType[key],
    //   }));
    // let loanProductsInputData: ISelectItems[] = loanProducts.map((item) => ({
    //   name: item.name,
    //   value: item.id,
    // }));
    // let availablePriorityLevels: ISelectItems[] = [
    //   1, 2, 3, 4, 5, 6, 7, 8, 9,
    // ].map((level) => ({ name: level.toString(), value: level }));

    let scoreCardItems: ISelectItems[] = scoreCardsList.map((item) => ({
      name: item.name,
      value: item.id,
    }));

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fab fa-first-order-alt fa-sm cyan-text mr-3" />
              Edit Credit Qualification Rule -{" "}
              <span className="font-weight-bold">{item.name}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditCreditQualificationRuleAppzoneForm
          initialValues={{
            name: item.name,
            scoreCard: scoreCardsList?.filter(x => x.name === item.scoreCard)[0]?.id,
            minimumScore: item.minimumScore
            // loanOriginationWorkflowId: item.loanOriginationWorkflowId,
            // loanProductId: "",
            // loanType: item.loanType,
            // maxPercentageLoanAmountSalary:item.maxPercentageLoanAmountSalary?.toString(),
            // minimumScore: item.minimumScore?.toString(),
            // priorityLevel: item.priorityLevel?.toString(),
            // repeatLoanOriginationWorkflowId:
            //   item.repeatLoanOriginationWorkflowId,
          }}
          FormComponent={({
            fields: { scoreCard, name, minimumScore },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <Accordion defaultActiveKey="0">
                  <div>
                    <Card>
                      <ContextAwareToggle
                        eventKey={`0`}
                        isHeader={true}
                        header={
                          <Fragment>
                            <i
                              className="fa fa-info-circle"
                              aria-hidden="true"
                              style={{ color: "#878a8a" }}
                            ></i>{" "}
                            General Information
                          </Fragment>
                        }
                      ></ContextAwareToggle>
                      <Accordion.Collapse eventKey={`0`}>
                        <Card.Body>
                          <div
                            className="card-body"
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            <div className="row">
                              <div className="col-md-6">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={1} width={300} />
                                ) : (
                                  <dl
                                    className="row"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <DetailItem
                                      label="Name"
                                      value={
                                        // <DetailItemSelectComponent
                                        //   id="loanProductId"
                                        //   name="loanProduct"
                                        //   value={loanProductId}
                                        //   items={loanProductsInputData}
                                        //   required={true}
                                        //   validated={validated}
                                        //   errors={errors}
                                        //   onChange={onChange}
                                        //   onChangeCallBack={(
                                        //     id: any,
                                        //     value: any
                                        //   ) =>
                                        //     this.loadCreditQualRule(id, value)
                                        //   }
                                        // />
                                        <DetailItemInputComponent
                                          id="name"
                                          name="name"
                                          type="text"
                                          placeholder=""
                                          value={name}
                                          required={true}
                                          validated={validated}
                                          errors={errors}
                                          onChange={onChange}
                                        // onChangeCallBack={(
                                        //   id: any,
                                        //   value: any
                                        // ) =>
                                        //   this.loadCreditQualRule(id, value)
                                        // }
                                        />
                                      }
                                      labelSize={5}
                                      valueSize={7}
                                    />
                                  </dl>
                                )}
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </div>
                </Accordion>
                <Accordion defaultActiveKey="0">
                  <div>
                    <Card>
                      <ContextAwareToggle
                        eventKey={`0`}
                        isHeader={true}
                        header={
                          <React.Fragment>
                            <i
                              className="fa fa-info-circle"
                              aria-hidden="true"
                              style={{ color: "#878a8a" }}
                            ></i>{" "}
                            Standard Parameters
                          </React.Fragment>
                        }
                      ></ContextAwareToggle>
                      <Accordion.Collapse eventKey={`0`}>
                        <Card.Body>
                          <div
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            <div className="row">
                              <div className="col-md-6">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={5} width={300} />
                                ) : (
                                  <dl className="row">

                                    <DetailItem
                                      label="Minimum Score"
                                      value={
                                        <DetailItemInputComponent
                                          id="minimumScore"
                                          type="number"
                                          name="Minimum Score"
                                          placeholder=""
                                          value={minimumScore}
                                          required={true}
                                          validated={validated}
                                          errors={errors}
                                          onChange={onChange}
                                        />
                                      }
                                      labelSize={5}
                                      valueSize={7}
                                    />
                                    <DetailItem
                                      label="Score Card"
                                      value={
                                        <DetailItemSelectComponent
                                          id="scoreCard"
                                          name="scoreCard"
                                          value={scoreCard}
                                          items={scoreCardItems}
                                          required={true}
                                          validated={validated}
                                          errors={errors}
                                          onChange={onChange}
                                        />
                                      }
                                      labelSize={5}
                                      valueSize={7}
                                    />
                                    {/* <DetailItem
                                      label="Loan Type"
                                      value={
                                        <DetailItemSelectComponent
                                          id="loanType"
                                          name="loanType"
                                          value={loanType}
                                          items={loanTypeInputData}
                                          required={true}
                                          validated={validated}
                                          errors={errors}
                                          onChange={onChange}
                                        />
                                      }
                                      labelSize={5}
                                      valueSize={7}
                                    />
                                    <DetailItem
                                      label="Require Positive Repayment Prediction"
                                      value={
                                        <span className="material-switch ml-2 mt-2">
                                          <input
                                            id="requirePositiveRepaymentPrediction"
                                            name="requirePositiveRepaymentPrediction"
                                            type="checkbox"
                                            onChange={
                                              this
                                                .toggleRequirePositiveRepaymentPredictionSwitch
                                            }
                                            checked={
                                              requirePositiveRepaymentPrediction
                                            }
                                          />
                                          <label
                                            htmlFor="requirePositiveRepaymentPrediction"
                                            className="bg-primary"
                                          ></label>
                                        </span>
                                      }
                                      labelSize={5}
                                      valueSize={7}
                                    />
                                    <DetailItem
                                      label="Apply Pre-Qualification Assessment"
                                      value={
                                        <span className="material-switch ml-2 mt-2">
                                          <input
                                            id="applyPrequalificationAssessment"
                                            name="applyPrequalificationAssessment"
                                            type="checkbox"
                                            onChange={
                                              this
                                                .toggleApplyPrequalificationAssessmentSwitch
                                            }
                                            checked={
                                              applyPreQualificationAssessment
                                            }
                                          />
                                          <label
                                            htmlFor="applyPrequalificationAssessment"
                                            className="bg-primary"
                                          ></label>
                                        </span>
                                      }
                                      labelSize={5}
                                      valueSize={7}
                                    /> */}
                                  </dl>
                                )}
                              </div>
                              <div className="col-md-6">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={5} width={300} />
                                ) : (
                                  <dl className="row">
                                    {/* <DetailItem
                                      label="Max. (%) Amount Of Loan Salary"
                                      value={
                                        <DetailItemInputComponent
                                          id="maxPercentageLoanAmountSalary"
                                          type="number"
                                          name="maxPercentageLoanAmountSalary"
                                          placeholder=""
                                          value={maxPercentageLoanAmountSalary}
                                          required={true}
                                          validated={validated}
                                          errors={errors}
                                          onChange={onChange}
                                        />
                                      }
                                      labelSize={5}
                                      valueSize={7}
                                    /> */}
                                    {/* <DetailItem
                                      label="Repeat Loan Origination WorkFlow"
                                      value={
                                        <DetailItemSelectComponent
                                          id="repeatLoanOriginationWorkflowId"
                                          name="repeatLoanOriginationWorkFlow"
                                          value={
                                            repeatLoanOriginationWorkflowId
                                          }
                                          items={workFlowsInputData}
                                          required={true}
                                          validated={validated}
                                          errors={errors}
                                          onChange={onChange}
                                        />
                                      }
                                      labelSize={5}
                                      valueSize={7}
                                    /> */}
                                    {/* <DetailItem
                                      label="Loan Origination Channels"
                                      value={
                                        <React.Fragment>
                                          {loanOriginationChannels.map(
                                            (channel) => (
                                              <div
                                                key={channel.id}
                                                className="custom-control custom-checkbox custom-control-inline"
                                              >
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input"
                                                  id={channel.id}
                                                  checked={loanOriginationChannelIds.includes(
                                                    channel.id
                                                  )}
                                                  onChange={() =>
                                                    this.handleOnChangeLoanOriginationChannel(
                                                      channel.id
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor={channel.id}
                                                >
                                                  {channel.name}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </React.Fragment>
                                      }
                                      labelSize={5}
                                      valueSize={7}
                                    /> */}
                                  </dl>
                                )}
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </div>
                </Accordion>
                <Accordion defaultActiveKey="0">
                  <div>
                    <Card>
                      <ContextAwareToggle
                        eventKey={`0`}
                        isHeader={true}
                        header={
                          <React.Fragment>
                            <i
                              className="fa fa-info-circle"
                              aria-hidden="true"
                              style={{ color: "#878a8a" }}
                            ></i>{" "}
                            Knock-Off Basis
                          </React.Fragment>
                        }
                      ></ContextAwareToggle>
                      <Accordion.Collapse eventKey={`0`}>
                        <Card.Body>
                          <div
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={5} width={300} />
                                ) : (
                                  <Fragment>
                                    <label
                                      htmlFor="permissibleKnockOffBasis"
                                      className="control-label textbox-label"
                                    >
                                      Credit Qualification Indicators
                                    </label>
                                    <div
                                      className="block-example border"
                                      style={{
                                        width: "100%",
                                        maxWidth: "100%",
                                        overflowX: "hidden",
                                        height: "300px",
                                        overflowY: "scroll",
                                      }}
                                    >
                                      <React.Fragment>
                                        {creditQualificationIndicators?.length ===
                                          0 ? (
                                          <div
                                            style={{
                                              backgroundColor: "#F3F3F3",
                                            }}
                                          >
                                            <p className="text-center">
                                              no credit qualification rule
                                              indicators
                                            </p>
                                          </div>
                                        ) : (
                                          <React.Fragment>
                                            {creditQualificationIndicators?.map(
                                              (item) => (
                                                item.creditQualificationIndicatorParams?.length > 0 ? (
                                                  <Accordion key={creditQualificationIndicators.indexOf(item)}>
                                                    <div style={{ border: '2px #84d4fc solid', borderRadius: '4px', backgroundColor: '#e4f4fc', marginTop: '5px', inlineSize: "100%" }}>
                                                      <ContextAwareToggle
                                                        eventKey={`0`}
                                                        isHeader={false}
                                                        header={
                                                          <div
                                                            className="custom-control custom-checkbox"
                                                            style={{ display: 'inline-block' }}
                                                          >
                                                            <input
                                                              id={item.id}
                                                              name={item.id}
                                                              className="custom-control-input"
                                                              type="checkbox"
                                                              onChange={() =>
                                                                this.handleOnCreditQualificationRuleChange(
                                                                  item.id
                                                                )
                                                              }
                                                              checked={
                                                                _.isEmpty(
                                                                  this.state.indicatorIds.filter(
                                                                    (x) => x === item.id
                                                                  )
                                                                )
                                                                  ? false
                                                                  : true
                                                              }
                                                            />
                                                            <label
                                                              className="custom-control-label"
                                                              htmlFor={item.id}
                                                            >
                                                              {item.description}
                                                            </label>
                                                          </div>
                                                        }
                                                      ></ContextAwareToggle>
                                                    </div>
                                                    <Accordion.Collapse eventKey={`0`}>
                                                      <Card.Body style={{ padding: "5px", backgroundColor: '#e4f4fc' }}>
                                                        <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                          <div className="row">
                                                            <div className="col-md-1">
                                                            </div>
                                                            <div className="col-md-8">
                                                              {item.creditQualificationIndicatorParams?.map(indicatorParam => (
                                                                <dl className="row" style={{ marginBottom: "0px" }} key={indicatorParam.paramName}>
                                                                  <DetailItem
                                                                    label={splitAtUpper(indicatorParam.paramName.split(".")[1])}
                                                                    value={indicatorParam.paramValue}
                                                                    labelSize={5}
                                                                    valueSize={7}
                                                                  />
                                                                </dl>
                                                              ))}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </Card.Body>
                                                    </Accordion.Collapse>
                                                  </Accordion>
                                                ) : (
                                                  <div key={creditQualificationIndicators.indexOf(item)} style={{ border: '2px #84d4fc solid', borderRadius: '4px', backgroundColor: '#e4f4fc', marginTop: '5px' }}>
                                                    <div
                                                      className="custom-control custom-checkbox"
                                                      key={creditQualificationIndicators.indexOf(
                                                        item
                                                      )}
                                                    >
                                                      <input
                                                        id={item.id}
                                                        name={item.id}
                                                        className="custom-control-input"
                                                        type="checkbox"
                                                        onChange={() =>
                                                          this.handleOnCreditQualificationRuleChange(
                                                            item.id
                                                          )
                                                        }
                                                        checked={
                                                          _.isEmpty(
                                                            this.state.indicatorIds.filter(
                                                              (x) => x === item.id
                                                            )
                                                          )
                                                            ? false
                                                            : true
                                                        }
                                                      />
                                                      <label
                                                        className="custom-control-label"
                                                        htmlFor={item.id}
                                                      >
                                                        {item.description}
                                                      </label>
                                                    </div>
                                                  </div>
                                                )
                                              )
                                            )}
                                          </React.Fragment>
                                        )}
                                      </React.Fragment>
                                    </div>
                                  </Fragment>
                                )}
                              </div>

                              <div className="col-md-6">
                                {_.isEmpty(item) ? (
                                  <Skeleton count={5} width={300} />
                                ) : (
                                  <Fragment>
                                    <label
                                      htmlFor="existingCreditQualificationRules"
                                      className="control-label textbox-label"
                                    >
                                      {fetchingExistingRules ? (
                                        <i className="fas fa-spinner fa-spin mr-2" />
                                      ) : (
                                        ""
                                      )}
                                      Existing Credit Qualification Rules
                                    </label>
                                    <div
                                      className="table-responsive text-nowrap"
                                      style={{
                                        height: "150px",
                                        overflowY: "scroll",
                                      }}
                                    >
                                      <table className="table table-hover table-content table-sm table-striped mb-0">
                                        <thead>
                                          <tr>
                                            <th
                                              scope="col"
                                              style={{
                                                width: "5px",
                                                paddingTop: "0.2rem",
                                                paddingBottom: "0.2rem",
                                              }}
                                            >
                                              <strong>#</strong>
                                            </th>
                                            <th
                                              scope="col"
                                              style={{
                                                paddingTop: "0.2rem",
                                                paddingBottom: "0.2rem",
                                              }}
                                            >
                                              <strong>Name</strong>
                                            </th>
                                            <th
                                              scope="col"
                                              style={{
                                                paddingTop: "0.2rem",
                                                paddingBottom: "0.2rem",
                                              }}
                                            >
                                              <strong>Minimum Score</strong>
                                            </th>
                                            {/* <th
                                              scope="col"
                                              style={{
                                                // width: "5px",
                                                paddingTop: "0.2rem",
                                                paddingBottom: "0.2rem",
                                              }}
                                            ></th> */}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {existingCreditQualificationRules?.length ===
                                            0 ? (
                                            <React.Fragment>
                                              <tr>
                                                <td
                                                  colSpan={4}
                                                  className="font-weight-lighter font-italic text-truncate"
                                                  style={{
                                                    textAlign: "center",
                                                    color: "#FE825E",
                                                  }}
                                                >
                                                  There are no Existing Credit
                                                  Qualification Rules
                                                </td>
                                              </tr>
                                            </React.Fragment>
                                          ) : (
                                            <React.Fragment>
                                              {existingCreditQualificationRules?.map(
                                                (item) => (
                                                  <tr key={item.id}>
                                                    <td
                                                      style={{
                                                        width: "5px",
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                        fontSize: "small",
                                                      }}
                                                    >
                                                      {existingCreditQualificationRules.indexOf(
                                                        item
                                                      ) + 1}
                                                    </td>
                                                    <td
                                                      style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                        fontSize: "small",
                                                      }}
                                                    >
                                                      {item.name}
                                                    </td>
                                                    <td
                                                      style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                        fontSize: "small",
                                                      }}
                                                    >
                                                      {item.minimumScore}
                                                    </td>
                                                    {/* <td
                                                      style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                        fontSize: "small",
                                                      }}
                                                    >
                                                      <i
                                                        className="far fa-eye ml-5 fa-lg"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                      // onClick={() =>
                                                      //   this.handleOnAddWorkFlowItem(item)
                                                      // }
                                                      ></i>
                                                    </td> */}
                                                  </tr>
                                                )
                                              )}
                                            </React.Fragment>
                                          )}
                                        </tbody>
                                      </table>
                                      <div
                                        // className="invalid-feedback"
                                        style={{
                                          visibility: errors.workFlowSteps,
                                          fontSize: "80%",
                                          color: "#dc3545",
                                        }}
                                      >
                                        {errors.workFlowSteps}
                                      </div>
                                    </div>
                                  </Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </div>
                </Accordion>
                <ModifyIndicatorParams
                  showPatchModal={this.state.showSetIndicatorParamsModal}
                  togglePatchModal={(toSave: boolean, indicatorId: string, payload: any) => {
                    this.handleOnIndicatorParamsSave(toSave, indicatorId, payload);
                  }}
                  item={indicatorItem}
                  allIndicators={creditQualificationIndicators}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.UPDATE,
                      "Credit Qualification Rule",
                      () => {
                        this.onFormSubmit(
                          { scoreCard, name, minimumScore },
                          onReloadFields
                        );
                      }
                    );
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></EditCreditQualificationRuleAppzoneForm>
      </Modal>
    );
  }
}

export default EditCreditQualificationRuleAppzone;
