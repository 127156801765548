import React from "react";
import { toast } from "react-toastify";
import BaseFormComponent, {
  IBaseFormState,
} from "../../components/BaseFormComponent";
import FormInputComponent from "../../components/formInputComponent";
import DetailItem from "../../components/detailItem";
import bvnValidationService from "../../services/bvn/bvnValidationService";
import linkedItemsService from "../../services/bvn/linkedItemsService";
import { mapEntityList } from "../../services/utility/autoMapperService";
import DetailItemsTable from "../../components/tableComponents/detailItemsTable";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../services/auth/authService";
import { validateBVN } from "../../services/mandate/bvnGreyListService";

interface ViewCustomerDetailsFields {
  bvn: string;
}

class ViewCustomerDetailsForm extends BaseFormComponent<ViewCustomerDetailsFields> {}

interface IViewCustomerDetailsProps {}
interface IViewCustomerDetailsState {
  fetching: boolean;
  called: boolean;
  item: any;
}

class ViewCustomerDetails extends React.Component<
  IViewCustomerDetailsProps,
  IBaseFormState & IViewCustomerDetailsState
> {
  constructor(props: IViewCustomerDetailsProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      fetching: false,
      called: false,
      item: {},
    };
  }

  handlePageChange = (page, id) => {
    //do nothing for now
  };

  onFormSubmit(
    fields: ViewCustomerDetailsFields,
    onReloadFieldsCB: any
  ): boolean {
    try {
      this.setState(
        { errors: bvnValidationService.validateViewCustomerDetailsForm(fields) },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: ViewCustomerDetailsFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      // console.log("starting submission...");
      if (fields.bvn === "") {
        toast.error("Bvn cannot be empty");
        return false;
      }

      // console.log("about to call the bvn validation endpoint...");
      this.setState({ submitting: true, called: false });
      const response = await validateBVN(fields.bvn);
      // console.log(response.data);
      // console.log("BVN Details: ", response.data.bvnDetails);
      this.setState({
        submitting: false,
        fetching: false,
        called: true,
        item: response.data.bvnDetails,
      });
      onReloadFieldsCB();
      return true;
    } catch (error) {
      // console.log("error: ", error);
      toast.error("A system error occured. Please, try again later");
      this.setState({ submitting: false, called: false });
      return false;
    }
  }

  render() {
    const {
      errors,
      submitting,
      validated,
      item,
      fetching,
      called,
    } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>View Customer BVN Details</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ViewCustomerDetailsForm
                  initialValues={{ bvn: "" }}
                  FormComponent={({
                    fields: { bvn },
                    onChange,
                    onReloadFields,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <h5 className="card-title">GENERAL INFORMATION</h5>
                          <div className="form-row">
                            <div className="col-md-12">
                              <div className="form-row">
                                <FormInputComponent
                                  id="bvn"
                                  type="text"
                                  name="bvn"
                                  placeholder="Enter Bvn"
                                  divClass={6}
                                  value={bvn}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="card-body">
                          <button
                            type="button"
                            disabled={submitting === true}
                            onClick={(e) => {
                              e.preventDefault();
                              this.onFormSubmit({ bvn }, onReloadFields);
                            }}
                            className="btn btn-sm btn-primary"
                          >
                            {submitting === false ? (
                              <React.Fragment>
                                <i className="fas fa-lg fa-link mr-4" /> View
                                BVN Details
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                Please wait...
                              </React.Fragment>
                            )}
                          </button>
                        </div>
                      </div>
                      {called ? (
                        <React.Fragment>
                          <hr />
                          <dl className="row">
                            <DetailItem
                              label="BVN"
                              value={item.bvn}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="First Name"
                              value={item.firstName}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Last Name"
                              value={item.lastName}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Other Names"
                              value={item.otherNames}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Phone Number"
                              value={item.phoneNumber}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Date Of Birth"
                              value={item.dob}
                              labelSize={6}
                              valueSize={6}
                            />
                          </dl>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </form>
                  )}
                ></ViewCustomerDetailsForm>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ViewCustomerDetails;
