import React, { Fragment } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import BaseFormComponent, {
    IBaseFormState,
} from "../../../components/BaseFormComponent";
import SelectInputComponent, {
    ISelectItems,
} from "../../../components/formSelectComponent";
import { getCurrentUserSession } from "../../../services/auth/authService";
import { ActionTypes } from "../../../enums/actionTypes";
import { decodeToken } from "react-jwt";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import loanProductService from "../../../services/hybridAppraisal/loanProductService";
import financialGroupService from "../../../services/hybridAppraisal/financialGroupService";
import loanClassificationService from "../../../services/hybridAppraisal/loanClassificationService";
import appraisalValidationService from "../../../services/hybridAppraisal/appraisalValidationService";
import FormInputComponent from "../../../components/formInputComponent";
import textFormatService from "../../../services/utility/textFormatService";
import loanCategoryService from "../../../services/hybridAppraisal/loanCategoryService";
import MultiSelectComponent, { IMultiSelectItems } from "../../../components/formMultiSelectComponent";
import hybridAppraisalValidationService from "../../../services/hybridAppraisal/hybridAppraisalValidationService";

interface AddLoanClassificationFields {
    Name: string;
    loanProductCode: string;
    loanLimit: string;
    loanTenor: string;
    interestRate: string;
    knockOff: any;
}

interface ILoanPrices {
    loanPricing: number;
    loanPricingName: string;
}

interface IKnockOffRuleParamData {
    rule: string;
    type: 'Dropdown' | 'Value';
    options: any
    value: string;
}

class AddLoanClassificationForm extends BaseFormComponent<AddLoanClassificationFields> { }

interface IAddLoanClassificationProps { }
interface IAddLoanClassificationState {
    fetchingRequiredItems: boolean;
    prices: any;
    knockOffRules: IKnockOffRuleParamData[];
    classificationRules: any;
    loanProducts: any;
    selectedLoanClassification: any;
    ownUpdate: boolean;
}

class AddLoanCategory extends React.Component<
    IAddLoanClassificationProps,
    IBaseFormState & IAddLoanClassificationState
> {
    _isMounted = false;
    constructor(props: IAddLoanClassificationProps) {
        super(props);
        this.state = {
            validated: false,
            submitting: false,
            errors: {},
            prices: [],
            fetchingRequiredItems: false,
            knockOffRules: [],
            classificationRules: [],
            loanProducts: [],
            selectedLoanClassification: [],
            ownUpdate: false
        };
    }

    async componentDidMount() {
        this._isMounted = true;
        const { errors } = this.state;
        const knockOffRulesData: IKnockOffRuleParamData[] = [
            {
                "rule": "Loan matches criteria",
                "type": "Dropdown",
                "options": [
                    {
                        "name": "Zero (0)"
                    },
                    {
                        "name": "Greater than zero"
                    }
                ],
                "value": null

            },
            {
                "rule": "Loan match status criteria",
                "type": "Dropdown",
                "options": [
                    {
                        "name": "All Active"
                    },
                    {
                        "name": "All Closed"
                    }
                ],
                "value": null
            },
            {
                "rule": "Maximum active loan",
                "type": "Value",
                "options": null,
                "value": undefined
            }
        ]
        try {
            const decodedToken = decodeToken(getCurrentUserSession().token || "");
            if (this._isMounted) {
                this.setState({ fetchingRequiredItems: true })
            }
            const response = await Promise.allSettled([
                loanProductService.retrieveByInstitutionCode(decodedToken?.["InstitutionCode"]),
                financialGroupService.retrieveByInstitutionCode(decodedToken?.["InstitutionCode"]),
            ])
            const loanProducts = response[0].status == "fulfilled" ? response[0].value.data : [];
            const financialGroups = response[1].status == "fulfilled" ? response[1].value.data : [];


            this.setState({ loanProducts: loanProducts })
        } catch (ex) {
            if (ex.response && ex.response.status === 404) {
                errors.response = "Internal server error.";
                this.setState({ errors });
            }
        }

        this.setState({ knockOffRules: knockOffRulesData })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onFormSubmit(fields: AddLoanClassificationFields, onReloadFieldsCB: any): boolean {
        try {
            const decodedToken = decodeToken(getCurrentUserSession().token || "");
            this.setState(
                { errors: hybridAppraisalValidationService.validateLoanCategoryForm(fields), ownUpdate: true },
                () => {
                    if (Object.keys(this.state.errors).length === 0) {
                        return this.submit(onReloadFieldsCB, fields);
                    }
                }
            );
            this.setState({ validated: true });
            return false;
        } catch (error) {
            return false;
        }
    }

    async submit(
        onReloadFieldsCB: any,
        fields: AddLoanClassificationFields
    ): Promise<boolean> {
        try {
            this.setState({ submitting: true });
            const decodedToken = decodeToken(getCurrentUserSession().token || "");
            const payload: any = {};

            // console.log("Payload: ", payload)
            const knockOff = [...this.state.knockOffRules];
            knockOff.forEach(x => {
                if (x.type === "Dropdown") {
                    x.options = x.options.map(y => y.name)
                }
            })

            payload.Name = fields.Name
            payload.institutionCode = decodedToken?.["InstitutionCode"]
            payload.loanProductCode = fields.loanProductCode
            payload.loanProductName = this.state.loanProducts?.filter(x => x.productCode === fields.loanProductCode)[0]?.name
            payload.interestRate = Number(fields.interestRate)
            payload.loanLimit = Number(fields.loanLimit)
            payload.loanTenor = Number(fields.loanTenor)
            payload.knockOffRules = knockOff
            payload.classificationRules = this.state.selectedLoanClassification.map(x => x.value)

            // console.log(payload);

            const response = await loanCategoryService.createLoanCategory(payload);
            if (response.status >= 200 && response.status <= 300) {
                await activityTrailService.saveActivityTrail({
                    actionType: "Add Loan Category",
                    description: `Added new Loan Category`,
                });
                toast.info(`Loan Category created successfully!`, {
                    autoClose: 6000,
                    type: toast.TYPE.DEFAULT,
                    hideProgressBar: false,
                });
            } else {
                return false;
            }
            this.setState({ submitting: false });
            this.resetFields();
            // onReloadFieldsCB();
        } catch (error) {
            toast.error(error.response.data, {
                autoClose: 6000,
                type: toast.TYPE.ERROR,
                hideProgressBar: false,
            });
            this.setState({ submitting: false });
            return false;
        } finally {
            return true;
        }
    }

    handleOnKnockOffRulesChange = (name, value, type) => {
        const knockOffRules = [...this.state.knockOffRules];
        // console.log("val :: ", value);
        // console.log("data :: ", data);

        if (type === "DropDown") {
            knockOffRules.filter(rule => rule.rule == name)[0].value = value
            this.setState({ knockOffRules, ownUpdate: true })
        }
        else {

            knockOffRules.every(option => {
                if (option.rule == name) {

                    option.value = value;
                    // this.setState({ submitting: false, ownUpdate: true });
                    return false;
                }
                // this.setState({flatPerApprovedLoan:flatPerApprovedLoan_, ownUpdate: true})
                // Make sure you return true. If you don't return a value, `every()` will stop.
                return true;
            });
            // console.log("getting object",validationOption.find(option => option.validationPricingOptionId == id).fee);
            //  console.log(signatoryOption);

            return knockOffRules;
        }
    }

    handleOnClassificationRulesChange = (selected) => {
        this.setState({ selectedLoanClassification: selected });
    };

    resetFields() {
        this.setState({
            validated: false,
            submitting: false,
        });
    }

    render() {
        const {
            errors,
            validated,
            fetchingRequiredItems,
            prices,
            loanProducts,
            selectedLoanClassification,
            knockOffRules
        } = this.state;

        console.log(loanProducts)

        const loanProductInputData: ISelectItems[] = loanProducts.map(item => {
            return { name: item.name, value: item.productCode }
        });

        const classificationRulesInputData: IMultiSelectItems[] = [
            "Loan outstanding balance is zero(0)",
            "Date of last payment for last loan disbursed was on or before Loan expiry date"
        ].map(item => {
            return { label: item, value: item }
        });


        const loanPerformanceStatusInputData: ISelectItems[] = [
            "Performing",
            "NonPerforming"
        ].map(item => {
            return { name: item, value: item }
        });

        return (
            <React.Fragment>
                <div className="container-fluid relative animatedParent animateOnce">
                    <div className="animated fadeInUpShort go">
                        <div className="row my-3 mx-2">
                            <h3>
                                <b>Add Loan Classification</b>
                            </h3>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <AddLoanClassificationForm
                                    initialValues={{
                                        Name: "",
                                        loanProductCode: "",
                                        knockOff: this.state.knockOffRules,
                                        loanLimit: "",
                                        loanTenor: "",
                                        interestRate: ""
                                    }}
                                    FormComponent={({
                                        fields: {
                                            Name,
                                            loanProductCode,
                                            knockOff,
                                            loanLimit,
                                            loanTenor,
                                            interestRate
                                        },
                                        onChange,
                                        onReloadFields,
                                        onHandleSubmit,
                                    }) => (
                                        <form action="#">
                                            <div className="card no-b">
                                                <div className="card-body">
                                                    <h5 className="card-title">
                                                        {/* <i className="fas fa-university mr-3" /> */}
                                                        Add Loan Classification
                                                    </h5>

                                                    <Fragment>
                                                        <div>
                                                            <Fragment>
                                                                <div className="form-row">
                                                                    <div className="col-md-12">
                                                                        <div className="card">
                                                                            <h5 className="card-title">
                                                                                {/* <i className="fas fa-university mr-3" /> */}

                                                                            </h5>
                                                                            <div className="form-row">
                                                                                <FormInputComponent
                                                                                    id="Name"
                                                                                    name="Name"
                                                                                    type="text"
                                                                                    divClass={6}
                                                                                    placeholder=""
                                                                                    value={Name}
                                                                                    required={true}
                                                                                    validated={validated}
                                                                                    errors={errors}
                                                                                    onChange={onChange}
                                                                                />
                                                                                <SelectInputComponent
                                                                                    label="Loan Product"
                                                                                    id="loanProductCode"
                                                                                    name="loanProductCode"
                                                                                    divClass={6}
                                                                                    value={loanProductCode}
                                                                                    items={loanProductInputData}
                                                                                    required={true}
                                                                                    validated={validated}
                                                                                    errors={errors}
                                                                                    onChange={onChange}
                                                                                />
                                                                                <FormInputComponent
                                                                                    id="loanLimit"
                                                                                    name="loanLimit"
                                                                                    type="text"
                                                                                    divClass={6}
                                                                                    placeholder=""
                                                                                    value={loanLimit}
                                                                                    required={true}
                                                                                    validated={validated}
                                                                                    errors={errors}
                                                                                    onChange={onChange}
                                                                                />
                                                                                <FormInputComponent
                                                                                    id="loanTenor"
                                                                                    name="loanTenor"
                                                                                    type="text"
                                                                                    divClass={6}
                                                                                    placeholder=""
                                                                                    value={loanTenor}
                                                                                    required={true}
                                                                                    validated={validated}
                                                                                    errors={errors}
                                                                                    onChange={onChange}
                                                                                />
                                                                                <FormInputComponent
                                                                                    id="interestRate"
                                                                                    name="interestRate"
                                                                                    type="text"
                                                                                    divClass={6}
                                                                                    placeholder=""
                                                                                    value={interestRate}
                                                                                    required={true}
                                                                                    validated={validated}
                                                                                    errors={errors}
                                                                                    onChange={onChange}
                                                                                />


                                                                            </div>


                                                                        </div>

                                                                        <div className="card mt-3">
                                                                            <h5 className="card-title">
                                                                                {/* <i className="fas fa-university mr-3" /> */}
                                                                                Add KnockOff Rules
                                                                            </h5>
                                                                            {knockOffRules.map(rule => (
                                                                                rule.type === "Dropdown" ?
                                                                                    (
                                                                                        <SelectInputComponent
                                                                                            key={rule.rule}
                                                                                            id={rule.rule}
                                                                                            name={rule.rule}
                                                                                            divClass={6}
                                                                                            value={rule.value}
                                                                                            items={rule.options?.map(x => { return { name: x.name, value: x.name } })}
                                                                                            required={false}
                                                                                            validated={validated}
                                                                                            errors={errors}
                                                                                            onChange={(id, value) => this.handleOnKnockOffRulesChange(id, value, "DropDown")}
                                                                                        />
                                                                                    )
                                                                                    :
                                                                                    (
                                                                                        <FormInputComponent
                                                                                            key={rule.rule}
                                                                                            id={rule.rule}
                                                                                            name={rule.rule}
                                                                                            type="number"
                                                                                            divClass={6}
                                                                                            placeholder=""
                                                                                            value={rule.value ? rule.value : ""}
                                                                                            required={false}
                                                                                            validated={validated}
                                                                                            errors={errors}
                                                                                            onChange={(id, value) => onChange('knockOff', this.handleOnKnockOffRulesChange(id, value, "value"))}
                                                                                        // onBlur={(e) => { this.handleOnKnockOffRulesChange(rule.name, e.target.value) }}
                                                                                        />
                                                                                    )


                                                                            ))}

                                                                        </div>
                                                                        <div className="card mt-3">
                                                                            <h5 className="card-title">
                                                                                {/* <i className="fas fa-university mr-3" /> */}
                                                                                Add Classification Rules
                                                                            </h5>
                                                                            <MultiSelectComponent
                                                                                id="classificationRules"
                                                                                name="classificationRules"
                                                                                divClass={6}
                                                                                value={selectedLoanClassification}
                                                                                options={classificationRulesInputData}
                                                                                validated={validated}
                                                                                errors={errors}
                                                                                hideSelectedOptions={false}
                                                                                onChange={this.handleOnClassificationRulesChange}
                                                                                allowSelectAll={true}
                                                                                required={false}
                                                                            // disabled={true}
                                                                            />

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                {/* </div> */}
                                                            </Fragment>
                                                        </div>
                                                    </Fragment>

                                                    <hr />
                                                    <div className="card-body">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-sm btn-primary"
                                                            disabled={this.state.submitting}
                                                            onClick={(e) => {
                                                                onHandleSubmit(
                                                                    e,
                                                                    ActionTypes.SAVE,
                                                                    "Loan Product Map",
                                                                    () => {
                                                                        this.onFormSubmit(
                                                                            {
                                                                                Name,
                                                                                loanProductCode,
                                                                                knockOff,
                                                                                interestRate,
                                                                                loanLimit,
                                                                                loanTenor
                                                                            },
                                                                            onReloadFields
                                                                        );
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            {this.state.submitting === false ? (
                                                                <React.Fragment>
                                                                    <i className="fas fa-lg fa-save mr-3" /> Save
                                                                </React.Fragment>
                                                            ) : (
                                                                <React.Fragment>
                                                                    <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                                                    Please wait...
                                                                </React.Fragment>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                ></AddLoanClassificationForm>
                            </div>
                        </div>
                    </div >
                </div >
            </React.Fragment >
        );
    }
}

export default AddLoanCategory;
