import React, { Component, Fragment, useContext } from "react";
import { Modal, Button, Accordion, Card, useAccordionToggle, AccordionContext } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import DetailItem from "../../../components/detailItem";
import { getCurrentUsersRoles } from "../../../services/auth/authService";
import { BillingType } from "../../../enums/billingType";

export function ContextAwareToggle({ eventKey, header }) {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      <b>{header}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
}

interface IAppraisalInstitutionDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  item: any;
}

interface IAppraisalInstitutionDetailState {
  item: any;
  updatingStatus: boolean;
}

class AppraisalInstitutionDetail extends Component<IAppraisalInstitutionDetailProps, IAppraisalInstitutionDetailState> {
  constructor(props: IAppraisalInstitutionDetailProps) {
    super(props);
    this.state = {
      item: {},
      updatingStatus: false,
    };
  }

  static getDerivedStateFromProps(nextProps: IAppraisalInstitutionDetailProps, prevState) {
    return {
      item: nextProps.item,
    };
  }

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      item,
    } = this.props;
    const { updatingStatus } = this.state;

    if (_.isEmpty(item)) {
      return null;
    }

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="fas fa-user-shield fa-sm cyan-text mr-3" />
                Appraisal Institution Details -{" "}
                <span className="font-weight-bold">{item.name}</span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Institution Name"
                        labelSize={6}
                        valueSize={6}
                        value={item.name}
                      />
                      <DetailItem
                        label="Plan Name"
                        labelSize={6}
                        valueSize={6}
                        value={item.planName}
                      />
                      <DetailItem
                        label="Institution Type"
                        labelSize={6}
                        valueSize={6}
                        value={item.institutionType}
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Instituiton Code"
                        labelSize={6}
                        valueSize={6}
                        value={item.code}
                      />
                      <DetailItem
                        label="Status"
                        value={
                          (item ? item.status : "Active") === "Active" ? (
                            <span className="badge badge-success">
                              {item ? item.status : ""}
                            </span>
                          ) : (
                            <span className="badge badge-danger">
                              {item ? item.status : ""}
                            </span>
                          )
                        }
                        labelSize={6}
                        valueSize={6}
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>

            {item.billingType === BillingType[BillingType.PAYG] ? (
              <Accordion defaultActiveKey="1">
                <div>
                  <Card>
                    <ContextAwareToggle
                      eventKey={`1`}
                      header={
                        <React.Fragment>
                          <b>Fee Configuration</b>
                        </React.Fragment>
                      }
                    ></ContextAwareToggle>
                    <Accordion.Collapse eventKey={`1`}>
                      <Card.Body>
                        <div className="row">
                          <div className="col-md-6">
                            {_.isEmpty(item) ? (
                              <Skeleton count={3} width={300} />
                            ) : (
                              <dl className="row">
                                <DetailItem
                                  label="Flat Per Request"
                                  labelSize={6}
                                  valueSize={6}
                                  value={item.feeConfig?.flatPerRequest}
                                />
                              </dl>
                            )}
                          </div>
                          <div className="col-md-6">
                            {_.isEmpty(item) ? (
                              <Skeleton count={3} width={300} />
                            ) : (
                              <dl className="row">
                                <DetailItem
                                  label="Flat Per Approved Loan"
                                  labelSize={6}
                                  valueSize={6}
                                  value={item.feeConfig?.flatPerApprovedLoan}
                                />
                              </dl>
                            )}
                          </div>
                        </div>
                        {!_.isEmpty(item.feeConfig?.validationOptions) ? (
                          <div className="form-group">
                            <label
                              htmlFor="validation-fees"
                              className="col-form-label s-10 font-weight-bold mb-2"
                            >
                              <b>Validation Fees</b>
                            </label>
                            <br />
                            <div className="row">
                              {item.feeConfig.validationOptions.map((data) => (
                                <div className="col-md-6" key={data.pricingOption.pricingOptionId}>
                                  <dl className="row">
                                    <DetailItem
                                      key={data.pricingOption.pricingOptionId}
                                      label={data.pricingOption.name}
                                      labelSize={6}
                                      valueSize={6}
                                      value={data.fee}
                                    />
                                  </dl>
                                </div>
                              ))
                              }
                            </div>
                          </div>
                        ) : ("")}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </div>
              </Accordion>
            ) : (
              <Accordion defaultActiveKey="1">
                <div>
                  <Card>
                    <ContextAwareToggle
                      eventKey={`1`}
                      header={
                        <React.Fragment>
                          <b>Fee Configuration</b>
                        </React.Fragment>
                      }
                    ></ContextAwareToggle>
                    <Accordion.Collapse eventKey={`1`}>
                      <Card.Body>
                        <div className="row">
                          <div className="col-md-6">
                            {_.isEmpty(item) ? (
                              <Skeleton count={3} width={300} />
                            ) : (
                              <dl className="row">
                                <DetailItem
                                  label="Price"
                                  labelSize={6}
                                  valueSize={6}
                                  value={item.feeConfig?.price}
                                />
                                <DetailItem
                                  label="Request Limit"
                                  labelSize={6}
                                  valueSize={6}
                                  value={item.feeConfig?.requestCount}
                                />
                              </dl>
                            )}
                          </div>
                          <div className="col-md-6">
                            {_.isEmpty(item) ? (
                              <Skeleton count={3} width={300} />
                            ) : (
                              <dl className="row">
                                <DetailItem
                                  label="Subscription Frequency"
                                  labelSize={6}
                                  valueSize={6}
                                  value={item.feeConfig?.subscriptionFrequency}
                                />
                                <DetailItem
                                  label="Pay As You Go Price"
                                  labelSize={6}
                                  valueSize={6}
                                  value={item.feeConfig?.payAsYouGoPrice}
                                />
                              </dl>
                            )}
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </div>
              </Accordion>
            )}

            <Accordion defaultActiveKey="2">
              <div>
                <Card>
                  <ContextAwareToggle
                    eventKey={`2`}
                    header={
                      <React.Fragment>
                        <b>Credit Policy Parameters</b>
                      </React.Fragment>
                    }
                  ></ContextAwareToggle>
                  <Accordion.Collapse eventKey={`2`}>
                    <Card.Body>
                      <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                        <div className="row">
                          <div className="col-md-12">
                            {_.isEmpty(item.creditPolicyParameters) ? (
                              <div style={{ textAlign: "center", color: "#FE825E" }}>
                                No Credit Policy Parameter has been assigned to institution
                              </div>
                            ) : (
                              <Fragment>
                                {item.creditPolicyParameters.map(
                                  (parameter) => (
                                    <div key={parameter.id} style={{ marginTop: '3px' }}>
                                      <div
                                        key={parameter.id}
                                        className="custom-control custom-checkbox"
                                      >
                                        <input
                                          id={parameter.id}
                                          name={parameter.id}
                                          className="custom-control-input"
                                          type="checkbox"
                                          checked={true}
                                          disabled={true}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={parameter.id}
                                        >
                                          {parameter.name}
                                        </label>
                                      </div>
                                    </div>
                                  )
                                )}
                              </Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </div>
            </Accordion>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
              {getCurrentUsersRoles().includes("editappraisalplan") && (
                <Button
                  size="sm"
                  variant="primary"
                  disabled={updatingStatus}
                  onClick={toggleEditModal}
                >
                  <i className="far fa-edit mr-3" />
                  Edit
                </Button>
              )}
            </React.Fragment>
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AppraisalInstitutionDetail;
