import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import { ActionTypes } from "../../../enums/actionTypes";
import swal from "sweetalert";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import branchesService from "../../../services/auth/branchesService";
import { toast } from "react-toastify";
import { getCurrentUsersRoles } from "../../../services/auth/authService";

interface IBranchDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  showTrail: any;
  currentUserRoles: any;
  item: any;
}

interface IBranchDetailState {
  item: any;
  updatingStatus: boolean;
  clientip: any;
}

class BranchDetail extends Component<IBranchDetailProps, IBranchDetailState> {
  state = {
    item: {},
    updatingStatus: false,
    clientip: {},
  };

  static getDerivedStateFromProps(nextProps: IBranchDetailProps, prevState) {
    // console.log("current props: ", nextProps.item);
    return {
      item: nextProps.item,
    };
  }

  onHandleEnableOrDisable = (
    e: any,
    actionType: ActionTypes,
    entityName: string
  ) => {
    this.setState({ updatingStatus: true });
    if (!actionType) {
      this.handleEnableOrDisableItem(this.props.item);
    } else {
      swal({
        title: "Confirm!",
        text: `Are you sure you want to proceed to ${ActionTypes[actionType]} this ${entityName}?`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode: true,
      }).then((resp) => {
        if (resp) {
          // console.log("cancelling action...");
          this.setState({ updatingStatus: false });
        } else {
          this.handleEnableOrDisableItem(this.state.item);
        }
      });
    }
  };

  handleEnableOrDisableItem = async (_item: any) => {
    try {
      let approvalResponse: any = {};
      // this.setState({ updatingStatus: true });
      await activityTrailService.saveActivityTrail({
        actionType: `${_item.status === "Inactive" ? "Enable" : "Disable"
          } Branch`,
        description: `Clicked on ${_item.status === "Inactive" ? "Enable" : "Disable"
          } to ${_item.status === "Inactive" ? "Enable" : "Disable"} Branch ${_item.name
          }`,
      });
      switch (_item.status) {
        case "Inactive":
          approvalResponse = await branchesService.enableBranch(_item.id);
          if (approvalResponse.data.isLoggedForApproval === false) {
            _item.status = "Active";
          }
          break;
        case "Active":
          approvalResponse = await branchesService.disableBranch(_item.id);
          if (approvalResponse.data.isLoggedForApproval === false) {
            _item.status = "Inactive";
          }
          break;
        default:
          approvalResponse = await branchesService.enableBranch(_item.id);
          if (approvalResponse.data.isLoggedForApproval === false) {
            _item.status = "Active";
          }
          break;
      }
      if (approvalResponse.data.isLoggedForApproval === true) {
        toast.warn(approvalResponse.data.message, {
          autoClose: 6000,
          type: toast.TYPE.WARNING,
          position: "top-center",
          hideProgressBar: false,
        });
      } else {
        toast.info(
          `Branch ${_item.name} ${_item.status === "Active" ? "Enabled" : "Disabled"
          } successfully! `,
          {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          }
        );
      }
      this.setState({ updatingStatus: false, item: _item });
    } catch (ex) {
      this.setState({ updatingStatus: false, item: _item });
    }
  };

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      showTrail,
      currentUserRoles,
      item,
    } = this.props;
    const { updatingStatus } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="icon icon-building-o s-18 mr-3" />
                Branch Details -{" "}
                <span className="font-weight-bold">{item.name}</span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
              <div className="float-right">
                {_.isEmpty(item) ? (
                  <Skeleton width={100} />
                ) : (
                  <span
                    className="badge btn-primary"
                    onClick={showTrail}
                    style={{
                      cursor: "pointer",
                      visibility: currentUserRoles.includes(
                        "viewaudittrailitems"
                      )
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    View Trail
                  </span>
                )}
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem label="Name" value={item.name} />
                      <DetailItem
                        label="Description"
                        value={item.description}
                      />
                      <DetailItem label="Address" value={item.address} />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={2} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem label="Code" value={item.code} />
                      <DetailItem
                        label="Status"
                        value={
                          (item ? item.status : "Active") === "Active" ? (
                            <span className="badge badge-success">
                              {item ? item.status : ""}
                            </span>
                          ) : (
                            <span className="badge badge-danger">
                              {item ? item.status : ""}
                            </span>
                          )
                        }
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
              {item.status === "Inactive" &&
                getCurrentUsersRoles().includes("enablebranch") && (
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    disabled={updatingStatus}
                    onClick={(e) =>
                      this.onHandleEnableOrDisable(
                        e,
                        item.status === "Inactive"
                          ? ActionTypes.ENABLE
                          : ActionTypes.DISABLE,
                        "Branch"
                      )
                    }
                  >
                    <i className="far fa-edit mr-3" />
                    Enable
                  </Button>
                )}
              {item.status === "Active" &&
                getCurrentUsersRoles().includes("disablebranch") && (
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    disabled={updatingStatus}
                    onClick={(e) =>
                      this.onHandleEnableOrDisable(
                        e,
                        item.status === "Inactive"
                          ? ActionTypes.ENABLE
                          : ActionTypes.DISABLE,
                        "Branch"
                      )
                    }
                  >
                    <i className="far fa-edit mr-3" />
                    Disable
                  </Button>
                )}
              {getCurrentUsersRoles().includes("editbranch") && (
                <Button
                  size="sm"
                  variant="primary"
                  disabled={updatingStatus}
                  onClick={toggleEditModal}
                >
                  <i className="far fa-edit mr-3" />
                  Edit
                </Button>
              )}
            </React.Fragment>
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default BranchDetail;

// <Modal
// size="xl"
// backdrop="static"
// show={showDetailsModal}
// onHide={() => {
//   onToggleDetailsModal();
//   this.setState({ item: {} });
// }}
// >
// <Modal.Header closeButton>
//   <Modal.Title>
//     {_.isEmpty(item) ? (
//       <Skeleton width={500} />
//     ) : (
//       <h5>
//         <i className="fas fa-gopuram fa-sm cyan-text mr-3" />
//         Branch Details -{" "}
//         <span className="font-weight-bold">{item.name}</span>
//       </h5>
//     )}
//   </Modal.Title>
// </Modal.Header>
// <Modal.Body>
//   <div className="card">
//     <div className="card-header clear-fix">
//       {_.isEmpty(item) ? (
//         <Skeleton width={200} />
//       ) : (
//         <h6
//           className="card-title float-left"
//           style={{ marginBottom: "0px" }}
//         >
//           General Information
//         </h6>
//       )}
//     </div>
//     <div className="card-body">
//       <div className="row">
//         <div className="col-md-6">
//           <h4>Item Details</h4>
//         </div>
//         <div className="col-md-6">
//           <h4>Item Details</h4>
//         </div>
//       </div>
//     </div>
//   </div>
// </Modal.Body>
// <Modal.Footer>
//   {_.isEmpty(item) ? (
//     <Skeleton width={100} count={2} height={30} />
//   ) : (
//     <React.Fragment>
//       <Button
//         size="sm"
//         variant="primary"
//         // disabled={updatingStatus}
//         onClick={(e) => {
//           e.preventDefault();
//           onToggleEditModal();
//         }}
//       >
//         <i className="far fa-edit mr-3" />
//         Edit
//       </Button>
//     </React.Fragment>
//   )}
//   <Button
//     size="sm"
//     variant="outline-danger"
//     onClick={(e) => {
//       e.preventDefault();
//       onToggleDetailsModal();
//       this.setState({ item: {} });
//     }}
//   >
//     <i className="fas fa-times mr-3" />
//     Close
//   </Button>
// </Modal.Footer>
// </Modal>
