import * as React from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";

export interface SettlementAccountsProps {
  institution: any;
  allInstitutions: any;
  allCommercialBanks: any;
}

export interface SettlementAccountsState {}

class SettlementAccounts extends React.Component<
  SettlementAccountsProps,
  SettlementAccountsState
> {
  // state = { :  }
  render() {
    const { institution, allInstitutions, allCommercialBanks } = this.props;
    return (
      <React.Fragment>
        <div
          className="card-header card-footer clear-fix"
          style={{ padding: "8px" }}
        >
          {_.isEmpty(institution) ? (
            <Skeleton width={200} />
          ) : (
            <h6
              className="card-title float-left"
              style={{ marginBottom: "0px" }}
            >
              Settlement Accounts
            </h6>
          )}
        </div>
        <div className="card-body">
          <div className="row">
            <div
              className="card-content table-responsive"
              style={{ overflowY: "scroll" }}
            >
              <table className="table table-hover table-content table-sm table-bordered table-striped mb-0">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      style={{
                        width: "5px",
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                      }}
                    >
                      <strong>#</strong>
                    </th>
                    <th
                      scope="col"
                      style={{
                        // width: "200px",
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                      }}
                    >
                      <strong>Settlement Institution</strong>
                    </th>
                    <th
                      scope="col"
                      style={{
                        // width: "5px",
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                      }}
                    >
                      <strong>Account Number</strong>
                    </th>
                    <th
                      scope="col"
                      style={{
                        // width: "5px",
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                      }}
                    >
                      <strong>No Of Days After Final Settlement</strong>
                    </th>
                    <th
                      scope="col"
                      style={{
                        // width: "5px",
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                      }}
                    >
                      <strong>Default Account</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {institution.settlementAccounts.length === 0 ? (
                    <tr>
                      <td
                        className="font-weight-normal font-italic text-truncate"
                        colSpan={5}
                        style={{
                          textAlign: "center",
                          color: "#FE825E",
                        }}
                      >
                        ...
                      </td>
                    </tr>
                  ) : (
                    <React.Fragment>
                      {institution.settlementAccounts.map((account) => (
                        <tr key={account.id}>
                          <td
                            style={{
                              width: "5px",
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {institution.settlementAccounts.indexOf(account) +
                              1}
                          </td>
                          <td
                            style={{
                              // width: "5px",
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {/* {allInstitutions.find(
                              (x) => x.code === account.settlementInstitution
                            ).name} */}
                            {allCommercialBanks.find(
                              (x) => x.bankCode === account.cbnCode
                            )?.bankName + ` [${account.cbnCode}]`}
                          </td>
                          <td
                            style={{
                              // width: "5px",
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {account.accountNo}
                          </td>
                          <td
                            style={{
                              // width: "5px",
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {account.noOfDaysAfterFinalSettlement}
                          </td>
                          <td
                            style={{
                              // width: "5px",
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {account.isDefault ? (
                              <span className="badge badge-secondary">
                                Default
                              </span>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SettlementAccounts;
