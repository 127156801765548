import * as React from "react";
// import _ from "lodash";
import { Fragment } from "react";
import {
    IFilterData,
    BaseListComponentState,
} from "../../../components/BaseListComponent";
import {
    createFilterData,
    mapEntityList,
} from "./../../../services/utility/autoMapperService";
import BaseListComponent from "./../../../components/BaseListComponent";
import ItemsTable, {
    filterFieldData,
} from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "./../../../components/tableComponents/tableFilterPane";
import reportService from "../../../services/hybridAppraisal/reportService";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../../services/auth/authService";
import DetailItemsTable, { TableContext } from "../../../components/tableComponents/detailItemsTable";

export interface LASGroupDisbursementReportProps { }

export interface LASGroupDisbursementReportState extends BaseListComponentState {
    data: IFilterData;
    currentPage: number;
    pageSize: number;
    tableItems: any;
    totalSize: number;
    fetchingDetail: boolean;
    filterValueInput: filterFieldData;
    allFetchedItems: any;
    item: any;
    ownUpdate: boolean;
    firstLoad: boolean;
}

class LASGroupDisbursementReportList extends BaseListComponent<
    LASGroupDisbursementReportState
> { }

class LASGroupDisbursementReport extends React.Component<
    LASGroupDisbursementReportProps,
    LASGroupDisbursementReportState
> {
    _isMounted = false;
    constructor(props: LASGroupDisbursementReportProps) {
        super(props);
        this.state = {
            data: createFilterData(["groupName", "MemberAccountNumber", "GroupAccountNumber",]) as IFilterData,
            currentPage: 1,
            pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
            fetching: false,
            fetchingDetail: false,
            tableItems: [],
            allFetchedItems: [],
            totalSize: 0,
            filterValueInput: [
                { name: "groupName", fieldType: "text", value: "" },
                // { name: "branch", fieldType: "text", value: "" },
                { name: "MemberAccountNumber", fieldType: "text", value: "" },
                { name: "GroupAccountNumber", fieldType: "text", value: "" },
            ],
            item: {},
            showDetailsModal: false,
            showEditModal: false,
            showTableFilterModal: false,
            showTrailModal: false,
            ownUpdate: false,
            firstLoad: true,

        };
    }

    handlePageChange = async (currentPage: number, pageSize: number, data?: IFilterData) => {
        const decodedToken = decodeToken(getCurrentUserSession().token || "");
        currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
        pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
        let dataClone = typeof data === "undefined" ? { ...this.state.data } : { ...data };
        console.log(dataClone);

        const newDataArray = [
            "customerName",
            "accountNumber",
            "linkedAccountNo"
        ];

        let filterData: any = {};
        newDataArray.forEach((header) => {
            if (header === "customerName") {
                filterData[header] = dataClone["groupName"];
            } else if (header === "accountNumber") {
                filterData[header] = dataClone["MemberAccountNumber"];
            } else if (header === "linkedAccountNo") {
                filterData[header] = dataClone["GroupAccountNumber"];
            }
            else {
                filterData[header] = dataClone[header];
            }
        });
        console.log(filterData);
        if (this._isMounted) {
            this.setState({ fetching: true, ownUpdate: true });
        }
        try {
            const reportItems = await reportService.filterGroupDisbursementReportReport(
                filterData,
                pageSize,
                currentPage
            );

            // const reportItemsPaginated: any = [];
            // const totalLength = reportItems.length;
            // const initial = (currentPage - 1) * 10;
            // for (let i = initial; i < initial + 10; i++) {
            //     reportItemsPaginated.push(reportItems[i]);
            //     if (i === totalLength - 1) {
            //         break;
            //     }
            // }
            // console.log('report items: ', reportItems)
            this.setState({
                tableItems: mapEntityList({
                    entityList: reportItems.data.item2,
                    properties: [
                        { oldName: "id", newName: "groupLoanId" },
                        { oldName: "title", newName: "title" },
                        { oldName: "firstName", newName: "firstName" },
                        { oldName: "lastName", newName: "lastName" },
                        { oldName: "accountNumber", newName: "memberAccountNumber" },
                        { oldName: "loanAmount", newName: "memberLoanAmount" },
                        { oldName: "loanAmount", newName: "memberInitialLoanAmount" },
                        { oldName: "memberStatus", newName: "memberStatus" },
                        { oldName: "linkedAccountNo", newName: "accountNumber" },
                        { oldName: "name", newName: "productName" },
                        { oldName: "customerName", newName: "groupName" },
                        { oldName: "branch", newName: "branch" },
                        { oldName: "groupLoanAmount", newName: "groupLoanAmount" },
                        { oldName: "newLoanAmount", newName: "newGroupLoanAmount" },
                        { oldName: "dateUpdated", newName: "disbursementDate" },
                    ],
                }),
                currentPage,
                pageSize,
                totalSize: reportItems.data.item1,
                fetching: false,
                firstLoad: false,
            });
        } catch (ex) {
            // console.log("caught exception", ex);
            if (
                ex.response &&
                ex.response.status >= 400 &&
                ex.response.status <= 499
            ) {
                // toast.error("Bad Request");
                // console.log(ex.response);
                // errors["response"] = "Internal server error.";
                // console.log(errors);
                // this.setState({ errors, fetching: false });
            } else {
                // toast.error("Internal server error. Please contact the admin.");
                // this.setState({ errors, fetching: false });
            }
        } finally {
            this.setState({ fetching: false });
        }
    };

    handleOnExport = async (type: string) => { };

    fetchItemById = async (item: any) => {
        try {
            // console.log("Item selected :: ", item)
            this.setState({ fetchingDetail: true });
            // const report = await reportService.retrieveStatusReportItemById(
            //   "Active",
            //   item.id
            // );
            const itemFullDetails = this.state.allFetchedItems.filter(fetchedItem => fetchedItem.id == item.id)[0]
            // this.setState({ item: report.data, fetchingDetail: false });
            this.setState({ item: itemFullDetails, fetchingDetail: false });
        } catch (error) {
        } finally {
            this.setState({ fetchingDetail: false });
        }
    };

    getFilteredItems = (data: IFilterData): IFilterData => {
        let filteredItems: IFilterData = {};
        Object.keys(data).forEach((key) => {
            if (data[key].item2 !== "") {
                filteredItems[key] = data[key];
            }
        });
        return filteredItems;
    };

    componentDidMount() {
        this._isMounted = true;
        this.handlePageChange(this.state.currentPage, this.state.pageSize);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const {
            currentPage,
            pageSize,
            tableItems,
            totalSize,
            fetching,
            item,
            fetchingDetail,
            firstLoad
        } = this.state;

        const tableContext: TableContext = {
            name: "",
            primaryValue: "",
            successValue: "Successful",
            dangerValue: "Failed",

        };

        // if (firstLoad) {
        //     this.handlePageChange(currentPage, pageSize);
        // }
        return (
            <Fragment>
                <div className="container-fluid relative animatedParent animateOnce">
                    <div className="animated fadeInUpShort go">
                        <div className="row my-3 mx-2">
                            <h3>
                                <b>LAS Group Loan Disbursement Report</b>
                            </h3>
                        </div>
                        <LASGroupDisbursementReportList
                            pageChangeHandler={this.handlePageChange}
                            fetchingDetail={fetchingDetail}
                            initialValues={{
                                data: this.state.data,
                                fetching: false,
                                showDetailsModal: false,
                                showEditModal: false,
                                showTableFilterModal: false,
                                showTrailModal: false,
                                tableItems: [],
                                filterValueInput: this.state.filterValueInput,
                                item: {},
                            }}
                            tableItems={tableItems}
                            item={item}
                            DetailsModal={({
                                item,
                                onToggleDetailsModal,
                                showDetailsModal,
                            }) => (
                                <Fragment>

                                </Fragment>
                            )}
                            ListPayLoad={({
                                data,
                                showTableFilterModal,
                                tableItems,
                                onToggleDetailsModal,
                                onToggleTableFilterModal,
                                onHandleFilterItemRemove,
                                filterValueInput,
                            }) => (
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card no-b">
                                            <div className="card-body">
                                                <div className="card-title">
                                                    <div id="list-filters" className="ml-4 mr-4">
                                                        <TableFilterPane
                                                            filteredItems={this.getFilteredItems(data)}
                                                            handleFilterRemove={onHandleFilterItemRemove}
                                                        />
                                                    </div>
                                                    <div id="list-table">
                                                        <ItemsTable
                                                            data={data}
                                                            items={tableItems}
                                                            filterValueInput={filterValueInput}
                                                            showTableFiltermodal={showTableFilterModal}
                                                            toggleTableFiltermodal={onToggleTableFilterModal}
                                                            name="LASGroupDisbursementReport"
                                                            currentPage={currentPage}
                                                            pageSize={pageSize}
                                                            totalSize={totalSize}
                                                            fetching={fetching}
                                                            handlePageChange={this.handlePageChange}
                                                            onViewDetails={(item: any) => {
                                                                this.fetchItemById(item);
                                                                onToggleDetailsModal();
                                                            }}
                                                            handleOnExport={this.handleOnExport}
                                                            amountColumn={["memberLoanAmount", "memberInitialLoanAmount", "groupLoanAmount", "newGroupLoanAmount"]}
                                                            timeColumn={["disbursementDate"]}
                                                            disableViewDetails

                                                        />

                                                        {/* <DetailItemsTable
                                                            title="LASGroupDisbursementReport"
                                                            item={item}
                                                            handleOnPageChange={
                                                                this.handlePageChange
                                                            }
                                                            content={tableItems}
                                                            contentCount={totalSize}
                                                            page={currentPage}

                                                            fetching={fetching}
                                                            amountColumn={["memberLoanAmount", "groupLoanAmount", "newGroupLoanAmount"]}
                                                            timeColumn={["disbursementDate"]}
                                                            contextualColumn={tableContext}
                                                            emptyTitle="LASGroupDisbursementReport"
                                                        /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        ></LASGroupDisbursementReportList>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default LASGroupDisbursementReport;
