import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";
import { IFilterData } from "../../components/BaseListComponent";

const linkingBaseAddress = process.env.REACT_APP_LINKING_BASEADDRESS;
export function filterLinkingReport(payload: IFilterData, pageSize: number, pageNumber: number) {
    var url = linkingBaseAddress + `api/Reports/FilterLinkingReport?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return http.post(url, payload, {
      url: url,
      method: "post",
      headers: {
        Authorization: `Bearer ${getCurrentUserSession().token}`,
      },
    });
}
  
export function filterLinkingReportDetail(payload: IFilterData, institutionId: number, pageSize: number, pageNumber: number) {
  var url = linkingBaseAddress + `api/Reports/FilterLinkingReportDetail/${institutionId}?pageSize=${pageSize}&pageNumber=${pageNumber}`;
  return http.post(url, payload, {
    url: url,
    method: "post",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export default {
    filterLinkingReport,
    filterLinkingReportDetail
}