import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";
import { decodeToken } from "react-jwt";
/*
export function retrieveAccountsByBVN(bvn) {
  return http.get(
    process.env.REACT_APP_BVN_BASEADDRESS + `LinkedItems/GetByBvn/${bvn}`
  );
}

export function retrieveAccountsByBVN(bvn) {
  return http.get(
    process.env.REACT_APP_BVN_BASEADDRESS + `LinkedItems/GetByBvn/${bvn}`
  );
}
*/
const bvnBaseAddress = process.env.REACT_APP_BVN_BASEADDRESS;
export function retrieveAccountsByBVN(bvn: string) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  return http.get(
    bvnBaseAddress +
    `LinkedItems/GetByBvnInstitution?InstitutionCode=${decodedToken?.["InstitutionId"]}&Bvns=${bvn}`
  );
}

export default {
  retrieveAccountsByBVN,
};
