import * as React from "react";
import { Modal, Button } from "react-bootstrap";

export interface MailListModalProps {
  showModal: boolean;
  toggleModal: any;
  handleOnAddSweepMailingList: any;
  users: any;
}

export interface MailListModalState {
  userId: string;
}

class MailListModal extends React.Component<
  MailListModalProps,
  MailListModalState
> {
  constructor(props: MailListModalProps) {
    super(props);
    this.state = {
      userId: "",
    };
  }

  handleChange = (e) => {
    // console.log("about to update userId to: ", e.currentTarget);
    this.setState({ userId: e.currentTarget.value });
  };

  handleOnAddSweepMailingList = (userId) => {
    this.props.handleOnAddSweepMailingList(this.state.userId);
  };

  render() {
    const { showModal, toggleModal, users } = this.props;
    const { userId } = this.state;
    // let usersInputData: ISelectItems[] = users.map((item) => ({
    //   name: `${item.firstName} ${item.lastName} (${item.email})`,
    //   value: item.id,
    // }));
    return (
      <Modal size="xl" backdrop="static" show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>
              <i className="icon-box-filled2 mr-3"></i>
              Sweep Mailing List
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="#">
            <div className="card no-b">
              <div className="card-body">
                <h5 className="card-title">CONFIGURE SWEEP MAILING LIST</h5>
                <div className="form-row">
                  <div className="col-md-12">
                    <div className="form-row">
                      {/* <label htmlFor="user" className="mr-2">
                        Users
                      </label> */}
                      <select
                        id={userId}
                        name="user"
                        className="custom-select select2 mr-sm-2 form-control r-0 light s-12"
                        // required={required}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <option value="">{"-Select User-"}</option>
                        {users.map((user) => (
                          <option key={user.id} value={user.id}>
                            {`${user.firstName} ${user.lastName} (${user.email})`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="card-body">
                <Button
                  type="button"
                  variant="primary"
                  onClick={() => {
                    this.handleOnAddSweepMailingList(this.state.userId);
                  }}
                  className="btn-copy-code btn-sm px-2 waves-effect"
                >
                  <i className="fa fa-copy mr-1"></i> Configure
                </Button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default MailListModal;
