import http from "../httpService";
import { getIpAddress, cryptoAESEncrypt } from "../utility/textFormatService";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession, getCurrentUsersRoles, retrieveCurrentUsersIpAddress } from "./authService";
import { IFilterData } from "../approval/approvalItemService";

const authBaseAddress = process.env.REACT_APP_AUTH_BASEADDRESS;
let currentUserSession: any = {};
try {
    if (getCurrentUsersRoles().includes("remotesession")) {
        currentUserSession = JSON.parse(localStorage.getItem("remoteUserSession") || "")
    } else {
        currentUserSession = getCurrentUserSession();
    }
} catch (error) {
    // console.log("error: ", error)
}

export function retrieveAllClientModules() {
    var url = authBaseAddress + `ServiceOfferingConfig/RetrieveAllClientModules`
    return http.get(url, {
        url: url,
        method: "get",
        headers: {
            Authorization: `Bearer ${currentUserSession.token}`,
            ClientIPAddress: retrieveCurrentUsersIpAddress(),
        },
    });
}

export function retrieveDefaultModulesByInstitutionCategory(institutionCategory: string) {
    var url = authBaseAddress + `ServiceOfferingConfig/RetrieveDefaultModulesByInstitutionCategory/${institutionCategory}`
    return http.get(url, {
        url: url,
        method: "get",
        headers: {
            Authorization: `Bearer ${currentUserSession.token}`,
            ClientIPAddress: retrieveCurrentUsersIpAddress(),
        },
    });
}

export function saveServiceOfferingConfig(name: string, billableOffer: string, institutionCategory: string, functions: number[]) {
    var url = authBaseAddress + `ServiceOfferingConfig/SaveServiceOfferingConfig/${institutionCategory}?name=${name}&billableOffer=${billableOffer}`;
    return http.post(url, functions, {
        url: url,
        method: "post",
        data: functions,
        headers: {
            Authorization: `Bearer ${currentUserSession.token}`,
            ClientIPAddress: retrieveCurrentUsersIpAddress(),
        }
    });
}

export function saveDefaultModules(institutionCategory: string, functions: number[]) {
    var url = authBaseAddress + `ServiceOfferingConfig/SaveDefaultModules/${institutionCategory}`;
    return http.post(url, functions, {
        url: url,
        method: "post",
        data: functions,
        headers: {
            Authorization: `Bearer ${currentUserSession.token}`,
            ClientIPAddress: retrieveCurrentUsersIpAddress(),
        }
    });
}

export function retrieveServiceOfferingConfigItemById(id: number) {
    var url = authBaseAddress + `ServiceOfferingConfig/RetrieveById/${id}`;
    return http.get(url);
}

export function filterServiceOfferingConfigItems(
    payload: IFilterData,
    pageSize: number,
    pageNumber: number
) {
    // const decodedToken = decodeToken(getCurrentUserSession().token || "");
    // payload = { ...payload };
    // payload.institutionId = {
    //   item1: "=",
    //   item2: decodedToken?.["InstitutionId"]?.toString() || "break",
    // };
    var url =
        authBaseAddress +
        `ServiceOfferingConfig/FilterServiceOffering?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return http.post(url, payload, {
        url: url,
        method: "post",
        headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
}

export default {
    retrieveAllClientModules,
    filterServiceOfferingConfigItems,
    retrieveDefaultModulesByInstitutionCategory,
    retrieveServiceOfferingConfigItemById,
    saveServiceOfferingConfig,
    saveDefaultModules
}