import React, { CSSProperties, useContext, useEffect, useState } from "react";
import corporateAnalysisService from "../../../../../../../../services/hybridAppraisal/corporateAnalysisService";
import _ from "lodash";
import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionToggle,
} from "react-bootstrap";
import DetailItem from "../../../../../../../../components/detailItem";
import {
  firstCharToUpper,
  formatCurrency,
  splitAtUpper,
} from "../../../../../../../../services/utility/textFormatService";

type Range = "first" | "second" | "third";
export const IncomeStatementOutput = ({ loan }: any) => {
  const [incomeStatementOutput, setIncomeStatementOutput] = useState<any>();
  const [repaymentRanges, setRepaymentRanges] = useState<Record<Range, number>>(
    { first: 60, second: 65, third: 70 }
  );

  const calcRange = (value: number) => {
    const raw = (value / 100) * Number(incomeStatementOutput?.averageNetProfit);
    return formatCurrency(raw, 2);
  };

  useEffect(() => {
    const getIncomeStatementOutptut = async () => {
      const response = await corporateAnalysisService.retrieveProfitLossOutput({
        loanRequestId: loan?.id,
      });
      if (response?.status === 200) {
        setIncomeStatementOutput(response?.data);
      }
    };
    getIncomeStatementOutptut();
  }, [loan]);

  const monthsArr = incomeStatementOutput?.monthySummary?.map(
    ({ period }) => period
  );

  const tableMonthKeys = Object.keys(
    !!incomeStatementOutput?.previewData?.[0]?.data?.[0]?.months
      ? incomeStatementOutput?.previewData?.[0]?.data?.[0]?.months
      : [""]
  );
  const tableMonthColumns = tableMonthKeys?.map((key) => firstCharToUpper(key));

  const sortedMonthlySummary = incomeStatementOutput?.monthySummary?.map(
    (item) => ({
      cashSales: item?.cashSales,
      creditSales: item?.creditSales,
      salesRevenue: item?.salesRevenue,
      cogs: item?.cogs,
      grossProfit: item?.grossProfit,
      totalOperatingExpenses: item?.operatingExpenses,
      operatingProfit: item?.operatingProfit,
      familyIncome: item?.familyIncome,
      familyExpenses: item?.familyExpenses,
      netProfitAfterFamilyExpenditure: item?.netProfitAfterFamilyExpenditure,
    })
  );

  const previewDataBusinessRaw1 = incomeStatementOutput?.previewData?.[0]?.data
    ?.slice(2, 4)
    ?.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.itemName]: Object.values(curr.months),
      };
    }, {});
  const previewDataBusinessRaw2 = incomeStatementOutput?.previewData?.[1]?.data
    ?.filter(({ type }) => type === "Business")
    ?.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.itemName]: Object.values(curr.months),
      };
    }, {});
  const previewDataFamilyRaw2 = incomeStatementOutput?.previewData?.[1]?.data
    ?.map((item) => ({
      ...item,
      itemName: item?.itemName + "_",
    }))
    ?.filter(({ type }) => type === "Family")
    ?.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.itemName]: Object.values(curr.months),
      };
    }, {});

  const previewDataBusinessArr1 = !_.isEmpty(previewDataBusinessRaw1)
    ? Object.entries(previewDataBusinessRaw1)
    : [];
  const previewDataBusinessArr2 = !_.isEmpty(previewDataBusinessRaw2)
    ? Object.entries(previewDataBusinessRaw2)
    : [];
  const previewDataFamilyArr2 = !_.isEmpty(previewDataFamilyRaw2)
    ? Object.entries(previewDataFamilyRaw2)
    : [];

  const previewDataArrAll = previewDataBusinessArr2?.concat(
    previewDataBusinessArr1
  );

  const transformedSummaryRaw = {};
  sortedMonthlySummary?.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (!transformedSummaryRaw[key]) {
        transformedSummaryRaw[key] = [];
      }
      transformedSummaryRaw[key].push(item[key]);
    });
  });
  const transformedSummaryArr = Object.entries(transformedSummaryRaw);
  const allDataRaw = previewDataArrAll.concat(transformedSummaryArr);
  const findKey = (key: string) => {
    return allDataRaw?.find((item) => item?.[0] === key);
  };
  const findBusinessListKeys = (keys: string[]) => {
    return allDataRaw?.filter((item) => keys.includes(item?.[0]));
  };
  const findFamilyListKeys = (keys: string[]) => {
    return previewDataFamilyArr2?.filter((item) => keys.includes(item?.[0]));
  };
  const transformedSummary = [
    findKey("cashSales"),
    findKey("creditSales"),
    findKey("salesRevenue"),
    findKey("cogs"),
    findKey("grossProfit"),
    ...findBusinessListKeys([
      "Salary",
      "Rent",
      "Transport",
      "Advertising",
      "Security",
      "Utilities",
      "Communication",
      "Insurance",
      "Taxes / Rates",
      "License",
      "Freight",
      "Osusu",
      "Other expenses",
      "Other loan installment",
    ]),
    findKey("totalOperatingExpenses"),
    findKey("operatingProfit"),
    ...findBusinessListKeys([
      "Salaries of family members",
      "Family income other sources",
    ]),
    findKey("familyIncome"),
    ...findFamilyListKeys([
      "Food_",
      "Rent_",
      "Transport_",
      "Medical_",
      "Clothing_",
      "Utilities_",
      "Other_",
    ]),
    findKey("familyExpenses"),
    findKey("netProfitAfterFamilyExpenditure"),
  ]?.reduce((acc, curr) => {
    return {
      ...acc,
      [curr?.[0]]: curr?.[1],
    };
  }, {});

  const alternateBlueFormatting = [
    "cogs",
    "salesRevenue",
    "netProfit",
    "totalOperatingExpenses",
    "familyIncome",
    "familyExpenses",
  ];

  const alternateBlackFormatting = [
    "grossProfit",
    "operatingProfit",
    "netProfitAfterFamilyExpenditure",
  ];

  const altFormatBlack = (
    item: any,
    { format, index }: { format?: "f1" | "f2" | "f3"; index?: number }
  ) => (
    <div
      style={{
        fontWeight: "bold",
        color: "#86939e",
      }}
    >
      {format === "f1" ? (
        <span>{firstCharToUpper(splitAtUpper(item))}</span>
      ) : format === "f2" ? (
        <span>{formatCurrency(transformedSummary[item][index], 2)}</span>
      ) : format === "f3" ? (
        <span>
          {formatCurrency(
            transformedSummary?.[item]?.reduce((acc, curr) => acc + curr, 0) /
              monthsArr?.length,
            2
          )}
        </span>
      ) : null}
    </div>
  );

  const altFormatBlue = (
    item: any,
    { format, index }: { format?: "f1" | "f2" | "f3"; index?: number }
  ) => (
    <div
      style={{
        fontWeight: "bold",
        color: "#437ba7",
      }}
    >
      {format === "f1" ? (
        <em>{firstCharToUpper(splitAtUpper(item))}</em>
      ) : format === "f2" ? (
        <em>{formatCurrency(transformedSummary[item][index], 2)}</em>
      ) : format === "f3" ? (
        <em>
          {formatCurrency(
            transformedSummary?.[item]?.reduce((acc, curr) => acc + curr, 0) /
              monthsArr?.length,
            2
          )}
        </em>
      ) : null}
    </div>
  );

  return (
    <div style={{ marginBottom: 20 }}>
      <Container
        title="Income Statement"
        body={
          <div style={{ width: "100%" }} className="clearfix">
            {!_.isEmpty(incomeStatementOutput) ? (
              <>
                <div
                  style={{ marginBottom: 30, overflowX: "auto" }}
                  className=" form-row col-md-12"
                >
                  <div
                    className="table-responsive text-nowrap"
                    style={{
                      overflowY: "scroll",
                      border: "0.5px solid #ccc",
                    }}
                  >
                    <table
                      style={{
                        overflowX: "auto",
                      }}
                      className="table table-hover table-content table-sm table-striped mb-0"
                    >
                      <thead>
                        <tr>
                          {tHead(
                            ["", ...tableMonthColumns, "Average"],
                            [
                              { textAlign: "left" },
                              { textAlign: "right" },
                              { textAlign: "right" },
                              { textAlign: "right" },
                              { textAlign: "right" },
                            ]
                          )}
                        </tr>
                      </thead>
                      {!_.isEmpty(transformedSummary) && (
                        <tbody>
                          {Object.keys(transformedSummary)?.map(
                            (item, index) => (
                              <tr key={index}>
                                {tData(
                                  [
                                    alternateBlueFormatting?.includes(item)
                                      ? altFormatBlue(item, { format: "f1" })
                                      : alternateBlackFormatting?.includes(item)
                                      ? altFormatBlack(item, { format: "f1" })
                                      : firstCharToUpper(
                                          splitAtUpper(item?.replace("_", ""))
                                        ),

                                    ...monthsArr?.map((_, index) =>
                                      alternateBlueFormatting?.includes(item)
                                        ? altFormatBlue(item, {
                                            format: "f2",
                                            index,
                                          })
                                        : alternateBlackFormatting?.includes(
                                            item
                                          )
                                        ? altFormatBlack(item, {
                                            format: "f2",
                                            index,
                                          })
                                        : formatCurrency(
                                            transformedSummary[item][index],
                                            2
                                          )
                                    ),

                                    alternateBlueFormatting?.includes(item)
                                      ? altFormatBlue(item, { format: "f3" })
                                      : alternateBlackFormatting?.includes(item)
                                      ? altFormatBlack(item, { format: "f3" })
                                      : formatCurrency(
                                          transformedSummary?.[item]?.reduce(
                                            (acc, curr) => acc + curr,
                                            0
                                          ) / monthsArr?.length,
                                          2
                                        ),
                                  ],
                                  [
                                    { textAlign: "left" },
                                    { textAlign: "right" },
                                    { textAlign: "right" },
                                    { textAlign: "right" },
                                    { textAlign: "right" },
                                  ]
                                )}
                              </tr>
                            )
                          )}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  color: "red",
                  marginTop: 20,
                }}
                className="py-2"
              >
                <i>No Income Statement Output available</i>
              </div>
            )}
          </div>
        }
      />
      <br />
      <Container
        title="Monthly Summary"
        body={
          <div
            className="table-responsive text-nowrap"
            style={{ height: "140px", overflowY: "scroll" }}
          >
            <div>
              <dl
                className="col-grid-2"
                style={{ marginBottom: "0px", gap: 1 }}
              >
                {detailItem(
                  "Gross Margin",
                  incomeStatementOutput?.grossMargin.toFixed(2) + "%"
                )}
                {detailItem(
                  "Mark Up",
                  incomeStatementOutput?.markUp.toFixed(2) + "%"
                )}
                {detailItem(
                  "Operating Margin",
                  incomeStatementOutput?.operatingMargin.toFixed(2) + "%"
                )}
                {detailItem(
                  "Net Margin",
                  incomeStatementOutput?.netMargin.toFixed(2) + "%"
                )}
              </dl>
              <br />
            </div>
          </div>
        }
      />
      <br />
      <Container
        title="Repayment Ranges (%)"
        body={
          <div
            className="table-responsive text-nowrap"
            style={{ height: "200px", overflowY: "scroll" }}
          >
            <div>
              <dl style={{ marginBottom: "0px", gap: 1 }}>
                <div style={{ height: 20 }} className="col-grid-2">
                  <p style={{ paddingLeft: 15 }}>Percent</p>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Range
                  </p>
                </div>
                <hr />
                {Object.keys(repaymentRanges)?.map((rangeKey, index) => (
                  <div key={index} className="col-grid-2">
                    <div style={{ paddingLeft: 15 }}>
                      <input
                        className="basic-input"
                        value={repaymentRanges[rangeKey]}
                        type="number"
                        onChange={(e) =>
                          setRepaymentRanges({
                            ...repaymentRanges,
                            [rangeKey]: e.target.value,
                          })
                        }
                      />
                    </div>
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {calcRange(repaymentRanges[rangeKey])}
                    </p>
                  </div>
                ))}
              </dl>
              <br />
            </div>
          </div>
        }
      />
    </div>
  );
};

const detailItem = (label: string, value: string | number) => (
  <DetailItem label={label} value={value} labelSize={6} valueSize={6} />
);

const tHead = (texts: (string | number)[], style?: CSSProperties[]) => (
  <>
    {texts?.map((text: any, index: number) => (
      <th
        key={text}
        scope="col"
        style={{
          ...style[index],
          paddingTop: "0.2rem",
          paddingBottom: "0.2rem",
        }}
      >
        <strong>{text}</strong>
      </th>
    ))}
  </>
);
const tData = (
  texts: (string | number | React.ReactNode)[],
  style?: CSSProperties[]
) => (
  <>
    {texts?.map((text: any, index: number) => (
      <td
        style={{
          ...style[index],
          paddingTop: "0.2rem",
          paddingBottom: "0.2rem",
          fontSize: "small",
        }}
      >
        {text}
      </td>
    ))}
  </>
);

const ContextAwareToggle = ({ eventKey, header }) => {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      <b>{header}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
};

const Container = ({
  title,
  body,
}: {
  title: string;
  body: React.ReactNode;
}) => {
  return (
    <div className="card">
      <Accordion defaultActiveKey="0">
        <div>
          <Card>
            <ContextAwareToggle
              eventKey={`0`}
              header={
                <React.Fragment>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    style={{ color: "#878a8a" }}
                  ></i>{" "}
                  {title}
                </React.Fragment>
              }
            ></ContextAwareToggle>
            <Accordion.Collapse eventKey={`0`}>
              <Card.Body>
                <div
                  style={{
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  <div className="row">{body}</div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </div>
      </Accordion>
    </div>
  );
};
