import React, { Component, Fragment } from "react";
import _ from "lodash";
import { Card } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";

interface IInstitutionRecovaFeeConfigurationDetailProps {
  item: any;
  recovaFeeConfigItem: any;
}

interface IInstitutionRecovaFeeConfigurationDetailState {
  item: any;
  recovaFeeConfigItem: any;
  updatingStatus: boolean;
}

class InstitutionRecovaFeeConfigurationDetail extends Component<
  IInstitutionRecovaFeeConfigurationDetailProps,
  IInstitutionRecovaFeeConfigurationDetailState
> {
  state = {
    item: {},
    recovaFeeConfigItem: {},
    updatingStatus: false,
  };

  static getDerivedStateFromProps(
    nextProps: IInstitutionRecovaFeeConfigurationDetailProps,
    prevState
  ) {
    return {
      item: nextProps.item,
      recovaFeeConfigItem: nextProps.recovaFeeConfigItem,
    };
  }

  render() {
    const {
      item,
      recovaFeeConfigItem,
    } = this.props;

    return (
      <Card.Body>
        <Fragment>
          {_.isEmpty(recovaFeeConfigItem) ? (
            "Central Fee Configuration for Recova is not available"
          ) : (
            <Fragment>
              <div className="card">
                <div className="card-header clear-fix">
                  <h6
                    className="card-title float-left"
                    style={{ marginBottom: "0px" }}
                  >
                    Fee
                  </h6>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <dl className="row">
                        <DetailItem
                          label="Active Loan Percentage"
                          labelSize={9}
                          valueSize={3}
                          value={recovaFeeConfigItem.activeLoansPercentage + " %"}
                        />
                        <DetailItem
                          label="Lost Loan Percentage"
                          labelSize={9}
                          valueSize={3}
                          value={recovaFeeConfigItem.lostLoansPercentage + " %"}
                        />
                        <DetailItem
                          label="Flat Amount Per Transaction"
                          labelSize={9}
                          valueSize={3}
                          value={recovaFeeConfigItem.flatAmount}
                        />
                      </dl>
                    </div>
                    <div className="col-md-6">
                      <dl className="row">
                        <DetailItem
                          label="Minimum Fee Per Transaction"
                          labelSize={9}
                          valueSize={3}
                          value={recovaFeeConfigItem.minimumFee}
                        />
                        <DetailItem
                          label="Maximum Fee Per Transaction"
                          labelSize={9}
                          valueSize={3}
                          value={recovaFeeConfigItem.maximumFee}
                        />
                      </dl>
                    </div>
                  </div>
                </div>
                <div className="card-header clear-fix">
                  <h6
                    className="card-title float-left"
                    style={{ marginBottom: "0px" }}
                  >
                    Commission
                  </h6>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <dl className="row">
                        <DetailItem
                          label="Debited Bank Commission"
                          labelSize={9}
                          valueSize={3}
                          value={recovaFeeConfigItem.debitedBankCommission}
                        />
                      </dl>
                    </div>
                    <div className="col-md-6">
                      <dl className="row">
                        {item.integrationCode == "CommercialBankIntegration" ? (
                          <DetailItem
                            label="Sponsor Bank Commission"
                            labelSize={9}
                            valueSize={3}
                            value={recovaFeeConfigItem.sponsorBankCommission}
                          />
                        ) : (
                          ""
                        )}
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </Fragment>
      </Card.Body >
    );
  }
}

export default InstitutionRecovaFeeConfigurationDetail;