import http from "../httpService";
import axios from "axios";
import { getCurrentUserSession } from "../auth/authService";
import { IFilterData } from "../approval/approvalItemService";
import { decodeToken } from "react-jwt";

interface ISearchMandateServiceTypeData {
  name: string;
  code: string;
  status: string;
}
interface IMandateServiceTypeCommon {
  name: string;
  description: string;
  mandateDuration: number;
  cumulativeDeductionCount: number;
  cumulativeDeductionConfig: string;
  transactionAmount: number;
  transactionAmountCategory: string;
  permissibleDeductionCount: number;
  permissibleDeductionConfig: string;
  mandateDurationConfig: string;
}
interface ISaveMandateServiceType extends IMandateServiceTypeCommon {
  institutionId: number;
}
interface IUpdateMandateServiceType extends IMandateServiceTypeCommon {
  id: number;
  initiator: string;
}

const recovaMandateBaseAddress = process.env.REACT_APP_MANDATE_BASEADDRESS;
export function saveMandateServiceType(
  mandateServiceType: ISaveMandateServiceType
) {
  return http.post(
    recovaMandateBaseAddress +
    `MandateServiceType/${getCurrentUserSession().sessionId}`,
    mandateServiceType
  );
}

export function updateMandateServiceType(
  mandateServiceType: IUpdateMandateServiceType,
  mandateServiceTypeId: number
) {
  return http.put(
    recovaMandateBaseAddress +
    `MandateServiceType/${mandateServiceTypeId}/${getCurrentUserSession().sessionId
    }`,
    mandateServiceType
  );
}

export function retrieveMandateServiceTypeById(mandateServiceTypeId: number) {
  var url =
    recovaMandateBaseAddress + `MandateServiceType/${mandateServiceTypeId}`;
  return http.get(url, {
    url: url,
    method: "get",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function retrieveAllMandateServiceTypes() {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  return http.get(
    recovaMandateBaseAddress +
    `MandateServiceType/GetAll?institutionId=${decodedToken?.["InstitutionId"]}`
  );
}

export function retrieveMandateServiceTypeByCode(code: string) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  return http.get(
    recovaMandateBaseAddress +
    `MandateServiceType/GetByCode?institutionId=${decodedToken?.["InstitutionId"]}&code=${code}`
  );
}

export function enableMandateServiceType(id: number) {
  var url = recovaMandateBaseAddress + `MandateServiceType/Enable?id=${id}`;

  return http.post(url, null, {
    url: url,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function disableMandateServiceType(id: number) {
  var url = recovaMandateBaseAddress + `MandateServiceType/Disable?id=${id}`;

  return http.post(url, null, {
    url: url,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function searchMandateServiceTypes(
  data: ISearchMandateServiceTypeData,
  pageSize: number,
  pageNumber: number
) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  return http.get(
    recovaMandateBaseAddress +
    `MandateServiceType/SearchMandateServiceTypes?institutionId=${decodedToken?.["InstitutionId"]}&name=${data.name}&status=${data.status}&pageSize=${pageSize}&pageNumber=${pageNumber}`
  );
}

export function downloadMandateServiceTypeReport(
  institutionCode,
  type: String
) {
  if (type.toLowerCase() === "pdf") {
    var url =
      recovaMandateBaseAddress +
      `MandateServiceType/DownloadMandateServiceTypesReportPDF`;

    return http.get(url, {
      url: url,
      method: "get",
      headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
    });
  } else {
    var url2 =
      recovaMandateBaseAddress +
      `MandateServiceType/DownloadMandateServiceTypesReport?type=${type}`;

    axios({
      url: url2,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${getCurrentUserSession().token}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Mandate Service Types.${type}`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
    // var mywindow = window.open(
    //   recovaMandateBaseAddress + `MandateServiceType/DownloadMandateServiceTypesReport/${institutionCode}?type=${type}`,
    //   "PRINT",
    //   "height=500,width=900"
    // );
    // mywindow?.focus();
  }
}

export function filterMandateServiceTypes(
  payload: IFilterData,
  pageSize: number,
  pageNumber: number
) {
  var url =
    recovaMandateBaseAddress +
    `MandateServiceType/FilterMandateServiceTypes?pageSize=${pageSize}&pageNumber=${pageNumber}`;
  return http.post(url, payload, {
    url: url,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export default {
  saveMandateServiceType,
  updateMandateServiceType,
  retrieveMandateServiceTypeById,
  retrieveAllMandateServiceTypes,
  retrieveMandateServiceTypeByCode,
  enableMandateServiceType,
  disableMandateServiceType,
  searchMandateServiceTypes,
  filterMandateServiceTypes,
  downloadMandateServiceTypeReport,
};
