import http from "../httpService";
// import axios from "axios";
import { getCurrentUserSession } from "../auth/authService";
import { IFilterData } from "../approval/approvalItemService";

const sweepBaseAddress = process.env.REACT_APP_SWEEP_BASEADDRESS;
export function setDefaultSettlementAccount(ofiInstitution: string, accountId: number) {
    var url = sweepBaseAddress + `Settlement/SetDefaultSettlementAccountById?ofiInstitution=${ofiInstitution}&accountId=${accountId}`;

    return http.get(url, { url: url, method: "get", headers: { Authorization: `Bearer ${getCurrentUserSession().token}` }, });
}

export function retryProcessingSettlementEntry(id: number) {
    var url = sweepBaseAddress + `Settlement/RetryProcessingSettlementEntry/${id}`;
    return http.post(url, { url: url, method: "post", headers: { Authorization: `Bearer ${getCurrentUserSession().token}` }, });
}

export function pushForManualSettlement(payload: any) {
    var url = sweepBaseAddress + `Settlement/PushForManualSettlement`;
    return http.post(url, payload, { url: url, data: payload, method: "post", headers: { Authorization: `Bearer ${getCurrentUserSession().token}` }, });
}

export function editManualNIPSettlement(payload: any) {

    return http.put(
        sweepBaseAddress + `Settlement/EditManualNIPSettlement`,
        payload
    );
}

export function filterManualNIPSettlement(
    payload: IFilterData,
    maxSize: number,
    pageNum: number
) {
    payload = { ...payload };
    return http.post(
        sweepBaseAddress +
        `Settlement/FilterManualNIPSettlement?pageNum=${pageNum}&maxSize=${maxSize}`,
        payload
    );
}

export function RetrieveManualNIPSettlementById(id: number) {

    return http.get(
        sweepBaseAddress + `Settlement/RetrieveManualNIPSettlementById/${id}`
    );
}

export default {
    setDefaultSettlementAccount,
    retryProcessingSettlementEntry,
    pushForManualSettlement,
    editManualNIPSettlement,
    filterManualNIPSettlement,
    RetrieveManualNIPSettlementById
}