import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import textFormatService, { cryptoAESEncrypt } from "../../../services/utility/textFormatService";
import consentRequestService from "../../../services/mandate/consentRequestService";
import { toast } from "react-toastify";
import authService, { getCurrentUserSession, getCurrentUsersRoles } from "../../../services/auth/authService";
import DetailItemsTable from "../../../components/tableComponents/detailItemsTable";
import { mapEntityList } from "../../../services/utility/autoMapperService";
import reactStringReplace from "react-string-replace";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import { decodeToken } from "react-jwt";
import institutionsService from "../../../services/auth/institutionsService";
import nibssService from "../../../services/mandate/nibssService";

interface IConsentRequestDetailProps {
    showDetailsModal: boolean;
    toggleDetailsModal: any;
    handlePageChange: any;
    item: any;
    showGuarantorDetails?: boolean;
}

interface IConsentRequestDetailState {
    updatingStatus: boolean;
    guarantorItem: any;
    showGuarantorDetailsModal: boolean;
    showResendConsent: boolean;
    showCancelConsent: boolean;
    showUpdateConsent: boolean;
    phoneNumber: string;
    bvnPhoneNumber: string;
    confirmConsent: boolean;
    // toggleResendConsent: boolean;
}

class ConsentRequestDetail extends Component<IConsentRequestDetailProps, IConsentRequestDetailState> {
    _isMounted = false;
    constructor(props: IConsentRequestDetailProps) {
        super(props);
        this.state = {
            guarantorItem: {},
            updatingStatus: false,
            showGuarantorDetailsModal: false,
            showResendConsent: false,
            showCancelConsent: false,
            phoneNumber: "",
            bvnPhoneNumber: "",
            showUpdateConsent: false,
            confirmConsent: false
            // toggleResendConsent: false,
        };
    }
    async componentDidMount() {
        this._isMounted = true;
        console.log(this.props.item)
        if (this.props.item.requestStatus == "AwaitingConfirmation") {
            var status = await nibssService.retrieveConsentStatusById(this.props.item.id)
            var statusData = status.data
            console.log(statusData)

            if (statusData !== null && statusData.Status == "Pending" && statusData.status == "AwaitingConfirmation") {
                this.setState({ confirmConsent: true })
            }
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    retryDisbursementForProcessingConsentRequest = async (_item) => {
        const { item } = this.props;
        this.setState({ updatingStatus: true });

        try {
            await consentRequestService.retryDisbursementForProcessingConsentRequest(item.id);

            toast.warn(`Consent Request has been sent again for Disbursement.`, {
                autoClose: 6000,
                type: toast.TYPE.DEFAULT,
                position: "top-right",
                hideProgressBar: false,
            });
        } catch (ex) {
            // console.log("caught exception", ex);
            if (ex.response && ex.response.status >= 400 && ex.response.status <= 499) {
                toast.error("Bad Request");
                // console.log(ex.response);
                // errors["response"] = "Internal server error.";
                // console.log(errors);
                this.setState({ updatingStatus: false });
            } else {
                toast.error("Internal server error. Please contact the admin.");
                this.setState({ updatingStatus: false });
            }
        }
        this.props.toggleDetailsModal(_item);
        this.props.handlePageChange(-1, 1, null);
    }

    resendConsentRequest = async (_item) => {
        const { item } = this.props;
        this.setState({ updatingStatus: true });
        // const { item, errors } = this.state;
        try {
            const decodedToken = decodeToken(getCurrentUserSession().token || "");

            var Institution = await institutionsService.retrieveInstitutionByCode(decodedToken?.["InstitutionCode"]);
            var InstitutionDetails = Institution.data;
            if (InstitutionDetails.category === "BankOneOFI") {
                await consentRequestService.resendConsentRequestMessage(item.id);
            }
            else if (InstitutionDetails.category === "OFI") {
                console.log("Got here.....")
                await consentRequestService.resendOFIConsentRequestMessage(item.loanReference);
            }

            toast.warn(`Consent Request message has been sent again to ${item.phoneNumber}.`, {
                autoClose: 6000,
                type: toast.TYPE.WARNING,
                position: "top-center",
                hideProgressBar: false,
            });
        } catch (ex) {
            // console.log("caught exception", ex);
            if (ex.response && ex.response.status >= 400 && ex.response.status <= 499) {
                toast.error("Bad Request");
                // console.log(ex.response);
                // errors["response"] = "Internal server error.";
                // console.log(errors);
                this.setState({ updatingStatus: false });
            } else {
                toast.error("Internal server error. Please contact the admin.");
                this.setState({ updatingStatus: false });
            }
        }
        this.props.toggleDetailsModal(_item);
        this.props.handlePageChange(-1);
    };

    updateConsentRequest = async (_item) => {
        const { item } = this.props;
        this.setState({ updatingStatus: true });
        // const { item, errors } = this.state;
        try {
            const decodedToken = decodeToken(getCurrentUserSession().token || "");

            var Institution = await institutionsService.retrieveInstitutionByCode(decodedToken?.["InstitutionCode"]);
            var InstitutionDetails = Institution.data;
            if (InstitutionDetails.category === "BankOneOFI") {
                await consentRequestService.updateConsentRequestMessage(item.id);
            }
            else if (InstitutionDetails.category === "OFI") {
                console.log("Got here.....")
                await consentRequestService.updateOFIConsentRequestMessage(item.loanReference);
            }

            toast.warn(`Consent Request message has been sent again to ${item.phoneNumber}.`, {
                autoClose: 6000,
                type: toast.TYPE.WARNING,
                position: "top-center",
                hideProgressBar: false,
            });
        } catch (ex) {
            // console.log("caught exception", ex);
            if (ex.response && ex.response.status >= 400 && ex.response.status <= 499) {
                toast.error("Bad Request");
                // console.log(ex.response);
                // errors["response"] = "Internal server error.";
                // console.log(errors);
                this.setState({ updatingStatus: false });
            } else {
                toast.error("Internal server error. Please contact the admin.");
                this.setState({ updatingStatus: false });
            }
        }
        this.props.toggleDetailsModal(_item);
        this.props.handlePageChange(-1);
    };

    cancelConsentRequest = async (_item) => {
        const { item } = this.props;
        this.setState({ updatingStatus: true });
        // const { item, errors } = this.state;
        try {
            // console.log(item.id);
            await consentRequestService.cancelConsentRequestMessage(item.id);
            //   console.log("done approving consent: ", consentRequest);

            toast.warn(`Consent Request has been cancelled`, {
                autoClose: 6000,
                type: toast.TYPE.WARNING,
                position: "top-center",
                hideProgressBar: false,
            });
        } catch (ex) {
            // console.log("caught exception", ex);
            if (ex.response && ex.response.status >= 400 && ex.response.status <= 499) {
                toast.error("Bad Request");
                // console.log(ex.response);
                // errors["response"] = "Internal server error.";
                // console.log(errors);
                this.setState({ updatingStatus: false });
            } else {
                toast.error("Internal server error. Please contact the admin.");
                this.setState({ updatingStatus: false });
            }
        }
        this.props.toggleDetailsModal(_item);
        this.props.handlePageChange(-1);
    };

    handleOnConfirmConsent = async () => {
        const { item } = this.props;
        this.setState({ updatingStatus: true });
        // const { item, errors } = this.state;
        try {
            // console.log(item.id);
            var response = await nibssService.confirmConsentRequest(item.id);
            //   console.log("done approving consent: ", consentRequest);

            if (response.status >= 200 && response.status <= 300) {
                if (response.data.isSuccessful) {
                    this.setState({ confirmConsent: false })
                    toast.info(`${"Mandate Confirmed, Loan will be disbursed shortly"}`, {
                        autoClose: 6000,
                        type: toast.TYPE.WARNING,
                        position: "top-center",
                        hideProgressBar: false,
                    });
                }
                else {
                    toast.info(`${response.data.responseMessage}`, {
                        autoClose: 6000,
                        type: toast.TYPE.WARNING,
                        position: "top-center",
                        hideProgressBar: false,
                    });
                }
            }
            else {
                return false;
            }
        } catch (ex) {
            // console.log("caught exception", ex);
            if (ex.response && ex.response.status >= 400 && ex.response.status <= 499) {
                toast.error("Bad Request");
                // console.log(ex.response);
                // errors["response"] = "Internal server error.";
                // console.log(errors);
                this.setState({ updatingStatus: false });
            } else {
                toast.error("Internal server error. Please contact the admin.");
                this.setState({ updatingStatus: false });
            }
        }
    };


    fetchFullGuarantorConsentRequest(request) {
        let fullRequest = this.props.item.guarantorConsentRequests.filter((item) => item.id === request.id)[0];
        this.setState({ guarantorItem: fullRequest });
    }

    handleOnToggleConsentRequestModal() {
        // console.log("item :: ", this.props.item);
        if (this.props.item.hasGuarantor && this._isMounted) {
            this.setState({
                showGuarantorDetailsModal: !this.state.showGuarantorDetailsModal,
            });
        }
    }

    generateRequestStatusTag(requestStatus: string) {
        switch (requestStatus) {
            case "AwaitingConfirmation":
                return <span className="badge badge-light">{requestStatus}</span>;
            case "Failed":
                return <span className="badge badge-danger">{requestStatus}</span>;
            case "Confirmed":
                return <span className="badge badge-primary">{requestStatus}</span>;
            case "Disbursed":
                return <span className="badge badge-success">{requestStatus}</span>;
            case "Cancelled":
                return <span className="badge badge-warning">{requestStatus}</span>;
            case "AwaitingDisbursement":
                return <span className="badge badge-default">{requestStatus}</span>;
            default:
                return <span className="badge badge-dark">{requestStatus}</span>;
        }
    }

    toggleUpdateConsent() {
        this.setState({ showUpdateConsent: !this.state.showUpdateConsent });
        const { item } = this.props;
        if (this.state.showUpdateConsent === true) {
            this.setState({ phoneNumber: item.phoneNumber, bvnPhoneNumber: item.bvnPhoneNumber });
            console.log("show ressend consent is false: ", this.props.item);
        }
    }

    toggleResendConsent() {
        this.setState({ showResendConsent: !this.state.showResendConsent });
        const { item } = this.props;
        if (this.state.showResendConsent === true) {
            this.setState({ phoneNumber: item.phoneNumber, bvnPhoneNumber: item.bvnPhoneNumber });
            console.log("show ressend consent is false: ", this.props.item);
        }
    }
    toggleCancelConsent() {
        this.setState({ showCancelConsent: !this.state.showCancelConsent });
        const { item } = this.props;
        if (this.state.showCancelConsent === true) {
            this.setState({ phoneNumber: item.phoneNumber, bvnPhoneNumber: item.bvnPhoneNumber });
            console.log("show Cancel consent is false: ", this.props.item);
        }
    }

    // resendConsentOptions = () => {
    //     const { showResendConsent } = this.state;
    //     // console.log("properties: ", this.props);
    //     return (

    //     );
    // };

    render() {
        const { showDetailsModal, toggleDetailsModal, item } = this.props;



        const { guarantorItem, showResendConsent, showCancelConsent, showUpdateConsent } = this.state;
        return (
            <>
                <Modal size="lg" backdrop="static" show={showResendConsent} onHide={() => this.toggleResendConsent()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Resend Consent Options</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="card">
                            <div className="card-header clear-fix">
                                <h5>Dear [{localStorage.getItem("current user")}]</h5>
                                <p>
                                    You are about to resend consent to the account phone numbers which might be different from the phone number on the
                                    BVN for customer&apos;s mandate approval to disburse.
                                </p>
                                <p>Please be aware that Lend will not be liable if the disbursement is made to a wrong customer account.</p>
                                <p>Click on the button below to proceed.</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            size="sm"
                            variant="outline-secondary"
                            disabled={this.state.updatingStatus}
                            //   onClick={() => this.toggleResendConsent()}
                            onClick={(e) => {
                                activityTrailService.saveActivityTrail({
                                    actionType: "Resend Consent Action",
                                    description: `${localStorage.getItem(
                                        "current user"
                                    )} resent consent request at ${new Date().toLocaleDateString()} for ${item.customerName}`,
                                });
                                this.resendConsentRequest(this.props.item);
                            }}
                        >
                            <i className="far fa-paper-plane mr-3"></i>OK
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal size="lg" backdrop="static" show={showUpdateConsent} onHide={() => this.toggleUpdateConsent()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Update Consent Options</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="card">
                            <div className="card-header clear-fix">
                                <h5>Dear [{localStorage.getItem("current user")}]</h5>
                                <p>
                                    You are about to resend consent to the account phone numbers which might be different from the phone number on the
                                    BVN for customer&apos;s mandate approval to disburse.
                                </p>

                                <p>Click on the button below to proceed.</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            size="sm"
                            variant="outline-secondary"
                            disabled={this.state.updatingStatus}
                            //   onClick={() => this.toggleResendConsent()}
                            onClick={(e) => {
                                activityTrailService.saveActivityTrail({
                                    actionType: "Resend Consent Action",
                                    description: `${localStorage.getItem(
                                        "current user"
                                    )} resent consent request at ${new Date().toLocaleDateString()} for ${item.customerName}`,
                                });
                                this.updateConsentRequest(this.props.item);
                            }}
                        >
                            <i className="far fa-paper-plane mr-3"></i>OK
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal size="lg" backdrop="static" show={showCancelConsent} onHide={() => this.toggleCancelConsent()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Cancel Consent Request</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="card">
                            <div className="card-header clear-fix">
                                <h5>Dear [{localStorage.getItem("current user")}]</h5>
                                <p>
                                    You are about to cancel consent for customer&apos;s mandate.
                                </p>
                                <p>Click on the button below to proceed.</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            size="sm"
                            variant="outline-secondary"
                            disabled={this.state.updatingStatus}
                            //   onClick={() => this.toggleResendConsent()}
                            onClick={(e) => {
                                activityTrailService.saveActivityTrail({
                                    actionType: "Cancel Consent Action",
                                    description: `${localStorage.getItem(
                                        "current user"
                                    )} Cancel consent request at ${new Date().toLocaleDateString()} for ${item.customerName}`,
                                });
                                this.cancelConsentRequest(this.props.item);
                            }}
                        >
                            <i className="far fa-paper-plane mr-3"></i>OK
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal size="xl" backdrop="static" show={showDetailsModal} onHide={toggleDetailsModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {_.isEmpty(item) ? (
                                <Skeleton width={500} />
                            ) : (
                                <h5>
                                    <i className="icon icon-building-o s-18 mr-3" />
                                    Consent Request Details - <span className="font-weight-bold">{item.phoneNumber}</span>
                                </h5>
                            )}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="card">
                            <div className="card-header clear-fix">
                                {_.isEmpty(item) ? (
                                    <Skeleton width={200} />
                                ) : (
                                    <h6 className="card-title float-left" style={{ marginBottom: "0px" }}>
                                        <b>General Information</b>
                                    </h6>
                                )}
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        {_.isEmpty(item) ? (
                                            <Skeleton count={10} width={1000} />
                                        ) : (
                                            // {console.log(item)}
                                            <dl className="row">
                                                {this.props.showGuarantorDetails ? (
                                                    <React.Fragment>
                                                        <DetailItem label="Name" value={item.customerName} />
                                                        <DetailItem label="BVN" value={item.bvn} />
                                                        <DetailItem label="Account Phone Number" value={item.phoneNumber} />
                                                        <DetailItem label=" BVN Phone Number" value={item.bvnPhoneNumber} />
                                                        <DetailItem
                                                            label="Guaranteed Amount"
                                                            value={textFormatService.convertKoboToNaira(item.totalRepaymentExpected, 2)}
                                                        />
                                                        <DetailItem
                                                            label="Consent Request Message"
                                                            value={
                                                                <React.Fragment>
                                                                    {item.consentRequestMessage.substring(
                                                                        0,
                                                                        item.consentRequestMessage.lastIndexOf(" ")
                                                                    )}{" "}
                                                                    {item.ocm ? (
                                                                        ""
                                                                    ) : (
                                                                        <a
                                                                            href={`${process.env.REACT_APP_BASEADDRESS
                                                                                }/consent?id=${encodeURIComponent(cryptoAESEncrypt(item.id.toString()))}`} //?{item.id}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            https://recova.ng/consent
                                                                        </a>
                                                                    )}
                                                                </React.Fragment>
                                                            }
                                                        />
                                                        <DetailItem label="Date Logged" value={textFormatService.formatTimeString(item.dateLogged)} />
                                                        <DetailItem
                                                            label="Consent Receipt Date"
                                                            value={
                                                                item.consentReceiptDate === null
                                                                    ? "N/A"
                                                                    : textFormatService.formatTimeString(item.consentReceiptDate)
                                                            }
                                                        />
                                                        {item.requestStatus !== "awaitingConfirmation" && (
                                                            <DetailItem label="Consent Receipt Channel" value={item.consentReceiptChannel} />
                                                        )}
                                                        <DetailItem
                                                            label="Request Status"
                                                            value={this.generateRequestStatusTag(item.requestStatus)}
                                                        />
                                                        {item.requestStatus === "Failed" ? (
                                                            <DetailItem label="Error Message" value={item.errorMessage} />
                                                        ) : (
                                                            ""
                                                        )}
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        {console.log(item)}
                                                        <DetailItem label="Name" value={item.customerName} />
                                                        <DetailItem label="BVN" value={item.bvn} />
                                                        <DetailItem label="Customer ID" value={item.customerID === "" ? "N/A" : item.customerID} />
                                                        <DetailItem label="Account Phone Number" value={item.phoneNumber} />
                                                        <DetailItem label=" BVN Phone Number" value={item.bvnPhoneNumber} />
                                                        <DetailItem
                                                            label="Loan Amount"
                                                            value={textFormatService.convertKoboToNaira(item.loanAmount, 2)}
                                                        />
                                                        <DetailItem
                                                            label="Expected Repayment"
                                                            value={textFormatService.convertKoboToNaira(item.totalRepaymentExpected, 2)}
                                                        />
                                                        <DetailItem label="Customer Type" value={item.customerType} />
                                                        <DetailItem
                                                            label="Consent Request Message"
                                                            value={
                                                                <React.Fragment>
                                                                    {/* {item.consentRequestMessage} */}
                                                                    {/* {item.consentRequestMessage.substring(
                                  0,
                                  item.consentRequestMessage.lastIndexOf(" ")
                                )}{" "} */}
                                                                    {item.ocm ? "" : generateConsentWithLink(item.consentRequestMessage, item.id)}
                                                                </React.Fragment>
                                                            }
                                                        />
                                                        <DetailItem label="Date Logged" value={textFormatService.formatTimeString(item.dateLogged)} />
                                                        <DetailItem
                                                            label="Consent Receipt Date"
                                                            value={
                                                                item.consentReceiptDate === null
                                                                    ? "N/A"
                                                                    : textFormatService.formatTimeString(item.consentReceiptDate)
                                                            }
                                                        />
                                                        {item.requestStatus !== "awaitingConfirmation" && (
                                                            <DetailItem label="Consent Receipt Channel" value={item.consentReceiptChannel} />
                                                        )}
                                                        <DetailItem
                                                            label="Request Status"
                                                            value={this.generateRequestStatusTag(item.requestStatus)}
                                                        />
                                                        {item.requestStatus === "Processing" && getCurrentUsersRoles().includes("remotesession") &&
                                                            <>
                                                                <DetailItem label="Error Message" value={item.errorMessage} />
                                                                <DetailItem label="Disbursement Retry Counter" value={item.retryCounter} />
                                                            </>
                                                        }
                                                    </React.Fragment>
                                                )}
                                            </dl>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {item.hasGuarantor === true ? (
                            <div className="card mt-4">
                                <div>
                                    <DetailItemsTable
                                        title="Guarantor(s) Information"
                                        item={item}
                                        handleOnPageChange={() => { }}
                                        hasDetailModal={true}
                                        onViewDetails={(guarantorConsentRequest) => {
                                            this.fetchFullGuarantorConsentRequest(guarantorConsentRequest);
                                            this.handleOnToggleConsentRequestModal();
                                        }}
                                        content={
                                            _.isEmpty(item)
                                                ? []
                                                : mapEntityList({
                                                    entityList: item.guarantorConsentRequests || [],
                                                    properties: [
                                                        {
                                                            oldName: "customerName",
                                                            newName: "guarantorName",
                                                        },
                                                        {
                                                            oldName: "bvn",
                                                            newName: "bvn",
                                                        },
                                                        {
                                                            oldName: "phoneNumber",
                                                            newName: "phoneNumber",
                                                        },
                                                        {
                                                            oldName: "guaranteeingPercentage",
                                                            newName: "guaranteeingPercentage",
                                                        },
                                                        {
                                                            oldName: "totalRepaymentExpected",
                                                            newName: "totalRepaymentExpected",
                                                        },
                                                        {
                                                            oldName: "consentReceiptDate",
                                                            newName: "consentReceiptDate",
                                                        },
                                                        {
                                                            isTag: true,
                                                            oldName: "requestStatus",
                                                            newName: "requestStatus",
                                                            success: "Disbursed",
                                                            danger: "Failed",
                                                            default: "AwaitingDisbursement",
                                                            primary: "Confirmed",
                                                            secondary: "secondary",
                                                            warning: "Cancelled",
                                                            light: "AwaitingConfirmation",
                                                            dark: "dark",
                                                        },
                                                    ],
                                                })
                                        }
                                        contentCount={
                                            _.isEmpty(item) || _.isNull(item.guarantorConsentRequests) ? 0 : item.guarantorConsentRequests.length
                                        }
                                        //contentCount={_.isEmpty(item) ? 0 : item.guarantorConsentRequests.length}
                                        page={1}
                                        fetching={_.isEmpty(item) ? true : false}
                                        timeColumn={["dateAdded"]}
                                        amountColumn={["totalRepaymentExpected"]}
                                    />
                                </div>
                            </div>
                        ) : (
                            ""
                        )}

                        <ConsentRequestDetail
                            showDetailsModal={this.state.showGuarantorDetailsModal}
                            showGuarantorDetails={true}
                            toggleDetailsModal={() => this.handleOnToggleConsentRequestModal()}
                            handlePageChange={(item) => { }}
                            item={this.state.guarantorItem}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        {_.isEmpty(item) ? (
                            <Skeleton width={100} count={1} height={30} />
                        ) : (
                            <React.Fragment>

                                {this.state.confirmConsent ? (
                                    <Button
                                        size="sm"
                                        variant="outline-danger"
                                        disabled={this.state.updatingStatus}
                                        onClick={() => this.handleOnConfirmConsent()}
                                    >
                                        <i className="far fa-paper-plane mr-3"></i>Confirm Payment
                                    </Button>
                                ) : ("")}
                                {item.updateConsent ? (
                                    <Button
                                        size="sm"
                                        variant="outline-danger"
                                        disabled={this.state.updatingStatus}
                                        onClick={() => this.toggleUpdateConsent()}
                                    >
                                        <i className="far fa-paper-plane mr-3"></i>Update Consent Request
                                    </Button>
                                ) : ("")}
                                {["AwaitingDisbursement", "Disbursed", "Confirmed", "Cancelled"].indexOf(item.requestStatus) === -1
                                    ? getCurrentUsersRoles().includes("resendconsentrequest") && (

                                        <><Button
                                            size="sm"
                                            variant="outline-danger"
                                            disabled={this.state.updatingStatus}
                                            onClick={() => this.toggleResendConsent()}
                                        >
                                            <i className="far fa-paper-plane mr-3"></i>Resend Consent Request
                                        </Button>
                                            <Button
                                                size="sm"
                                                variant="outline-secondary"
                                                disabled={this.state.updatingStatus}
                                                onClick={() => this.toggleCancelConsent()}
                                            >
                                                <i className="far fa-paper-plane mr-3"></i>Cancel Consent Request
                                            </Button></>

                                    )
                                    : ""}
                                {
                                    getCurrentUsersRoles().includes("remotesession") && item.requestStatus === "Processing" && <Button
                                        size="sm"
                                        variant="outline-warning"
                                        disabled={item.requestStatus !== "Processing"}
                                        onClick={() => this.retryDisbursementForProcessingConsentRequest(this.props.item)}
                                    >
                                        <i className="fas fa-ban mr-3"></i>Retry Disbursement
                                    </Button>
                                }
                            </React.Fragment>

                        )}

                        <Button size="sm" variant="outline-danger" onClick={toggleDetailsModal}>
                            <i className="fas fa-times mr-3" />
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default ConsentRequestDetail;
function generateConsentWithLink(consentRequestMessage: any, id: any): React.ReactNode {
    return reactStringReplace(consentRequestMessage, "-link-", (match, i) => (
        <a
            href={`${process.env.REACT_APP_BASEADDRESS}/consent?id=${encodeURIComponent(cryptoAESEncrypt(id.toString()))}`} //?{item.id}
            target="_blank"
            rel="noopener noreferrer"
        >
            https://recova.ng/consent
        </a>
    ));
}
