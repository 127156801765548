import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../components/detailItem";
import textFormatService from "../../services/utility/textFormatService";
import DetailItemsTable from "../../components/tableComponents/detailItemsTable";
import { mapEntityList } from "../../services/utility/autoMapperService";
import institutionsService from "../../services/auth/institutionsService";
import { getCurrentUserSession } from "../../services/auth/authService";
import sweepRunService from "../../services/sweep/sweepRunService";
import { decodeToken } from "react-jwt";

interface ISweepEpisodeReportDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  item: any;
}

interface ISweepEpisodeReportDetailState {
  repaymentTransactions: any;
  repaymentTransactionsTotalCount: number;
  repaymentTransactionsCurrentPage: number;
  fetchingRepaymentTransactions: boolean;
}

class SweepEpisodeReportDetail extends Component<
  ISweepEpisodeReportDetailProps,
  ISweepEpisodeReportDetailState
> {
  constructor(props: ISweepEpisodeReportDetailProps) {
    super(props);
    this.state = {
      fetchingRepaymentTransactions: false,
      repaymentTransactions: [],
      repaymentTransactionsCurrentPage: 1,
      repaymentTransactionsTotalCount: 0,
    };
  }

  handleOnRepaymentTransactionsPageChange = async (
    page: number,
    transactionId
  ) => {
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    this.setState({ fetchingRepaymentTransactions: true });
    try {
      const institution = await institutionsService.retrieveInstitutionById(
        decodedToken?.["InstitutionId"]
      );
      const repaymentTrx =
        await sweepRunService.retrieveRepaymentsBySweepEpisodeId(
          institution.data.code,
          transactionId,
          Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
          page
        );
      this.setState({
        repaymentTransactions: mapEntityList({
          entityList: repaymentTrx.data.item2,
          properties: [
            {
              oldName: "linkedAccountInstitution",
              newName: "Inflow Institution",
            },
            { oldName: "amountAttempted", newName: "amountAttempted" },
            { oldName: "amountDebited", newName: "amountDebited" },
            { oldName: "timeSwept", newName: "timeSwept" },
            { oldName: "retrievalReferenceNo", newName: "retrievalReference" },
          ],
        }),
        repaymentTransactionsCurrentPage: page,
        repaymentTransactionsTotalCount: repaymentTrx.data.item1,
        fetchingRepaymentTransactions: false,
      });
    } catch (error) {
      // console.log("error fetching repayment transactions: ", error);
      this.setState({ fetchingRepaymentTransactions: false });
    } finally {
      this.setState({ fetchingRepaymentTransactions: false });
    }
  };

  async componentDidMount() {
    try {
      if(!_.isEmpty(this.props.item)){
        this.handleOnRepaymentTransactionsPageChange(
          this.state.repaymentTransactionsCurrentPage,
          this.props.item.id
        );
      }
    } catch (error) {
      this.setState({ fetchingRepaymentTransactions: false });
    }
  }
  render() {
    const { showDetailsModal, toggleDetailsModal, item } = this.props;
    const {
      repaymentTransactions,
      repaymentTransactionsTotalCount,
      repaymentTransactionsCurrentPage,
      fetchingRepaymentTransactions,
    } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="fas fa-receipt fa-sm s-18 mr-3" />
                Sweep Episode Details
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={5} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Type of Sweep"
                        value={item.sweepType}
                      />
                      <DetailItem
                        label="Stage of Sweep"
                        value={item.sweepStage}
                      />
                      <DetailItem
                        label="Recova Reference"
                        value={item.uniqueId}
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={5} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="No. of transactions"
                        value={item.transactionCount}
                      />
                      <DetailItem
                        label="Start Time"
                        value={textFormatService.formatTimeString(
                          item.startTime
                        )}
                      />
                      <DetailItem
                        label="End Time"
                        value={textFormatService.formatTimeString(item.endTime)}
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>
            <DetailItemsTable
              title="Sweep Episode Repayments"
              item={item}
              handleOnPageChange={this.handleOnRepaymentTransactionsPageChange}
              content={repaymentTransactions}
              contentCount={repaymentTransactionsTotalCount}
              page={repaymentTransactionsCurrentPage}
              fetching={fetchingRepaymentTransactions}
              amountColumn={["amountDebited", "amountAttempted"]}
              timeColumn={["timeSwept"]}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SweepEpisodeReportDetail;
