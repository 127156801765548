import React, { Fragment } from "react";
import BaseListComponent, {
  BaseListComponentState,
  IFilterData,
} from "../../components/BaseListComponent";
import ItemsTable, {
  filterFieldData,
} from "../../components/tableComponents/itemsTable";
import TableFilterPane from "../../components/tableComponents/tableFilterPane";
import rolesService from "../../services/auth/rolesService";
import serviceOfferingConfigService from "../../services/auth/serviceOfferingConfigService";
import {
  createFilterData,
  mapEntityList,
} from "../../services/utility/autoMapperService";
import { printPDF } from "../../services/utility/textFormatService";
import ServiceOfferingConfigDetail from "./serviceOfferingConfigDetail";

interface ServiceOfferingConfigListProps {}

interface ServiceOfferingConfigListState extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
  clientModules: any;
  fetchingClientModules: boolean;
  availableModules: any;
  checked: any;
}

class ServiceOfferingConfigsList extends BaseListComponent<ServiceOfferingConfigListState> {}

class ServiceOfferingConfigList extends React.Component<
  ServiceOfferingConfigListProps,
  ServiceOfferingConfigListState
> {
  constructor(props: ServiceOfferingConfigListProps) {
    super(props);
    this.state = {
      data: createFilterData(["name"]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [{ name: "name", fieldType: "text", value: "" }],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
      clientModules: [],
      fetchingClientModules: false,
      availableModules: [],
      checked: [],
    };
  }

  handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true });
    data = typeof data === "undefined" ? this.state.data : data;
    try {
      const serviceOfferingConfigs =
        await serviceOfferingConfigService.filterServiceOfferingConfigItems(
          data,
          pageSize,
          currentPage
        );
      const clientModules =
        await serviceOfferingConfigService.retrieveAllClientModules();
      this.setState({
        clientModules: clientModules.data,
        tableItems: mapEntityList({
          entityList: serviceOfferingConfigs.data.item2,
          properties: [
            { oldName: "name", newName: "name" },
            { oldName: "billableOffer", newName: "billableOffer" },
            //   { oldName: "description", newName: "description" },
            // {
            //   isTag: true,
            //   oldName: "institutionCategory",
            //   newName: "institutionCategory",
            //   success: "success",
            //   danger: "danger",
            //   default: "CommercialBank",
            //   primary: "primary",
            //   secondary: "secondary",
            //   warning: "OFI",
            //   light: "BankOneOFI",
            //   dark: "dark",
            // },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: serviceOfferingConfigs.data.item1,
        fetching: false,
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
        // this.setState({ errors, fetching: false });
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        // console.log(ex);
        // this.setState({ errors, fetching: false });
      }
    } finally {
      this.setState({ fetching: false });
    }
  };

  handleOnExport = async (type: string) => {
    try {
      switch (type) {
        case "XLSX":
        case "CSV":
          return await rolesService.downloadReport(type);
        case "PDF":
          const response = await rolesService.downloadReportPDF();
          printPDF(response.data);
          break;
        default:
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        //this.setState({ errors, fetching: false });
      }
    }
  };

  fetchItemById = async (item: any) => {
    this.setState({ fetchingDetail: true });
    const config =
      await serviceOfferingConfigService.retrieveServiceOfferingConfigItemById(
        item.id
      );
    this.setState({
      item: config.data,
      fetchingDetail: false,
      checked: config.data.assignedUserRoleFunctions.map((x) => x.id),
    });
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.setState({ fetchingClientModules: true });
    const clientModules =
      await serviceOfferingConfigService.retrieveAllClientModules();
    let allModules: any = clientModules.data;
    let treeCollection: any = [];
    treeCollection = allModules.map((func) => {
      let newSet: any = {};
      newSet = {
        value: func.id,
        label: func.displayName,
      };
      return newSet;
    });
    this.setState({
      availableModules: treeCollection,
      fetchingClientModules: false,
    });

    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }

  render() {
    const {
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Service Offering Configurations</b>
              </h3>
            </div>

            <ServiceOfferingConfigsList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              initialValues={{
                data: createFilterData(["name"]) as IFilterData,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: [
                  { name: "name", fieldType: "text", value: "" },
                ],
                item: {},
                jsonBefore: {},
                jsonAfter: {},
                fetchingTrail: false,
                entityName: "Recova.Auth.ServiceOfferingConfig",
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                onToggleEditModal,
                showDetailsModal,
                showEditModal,
                fetchTrailItem,
                fetchingTrail,
                jsonAfter,
                jsonBefore,
                onToggleTrailModal,
                showTrailModal,
                trailItem,
                trailMapper,
              }) => (
                <Fragment>
                  <ServiceOfferingConfigDetail
                    item={this.state.item}
                    toggleDetailsModal={onToggleDetailsModal}
                    toggleEditModal={onToggleEditModal}
                    showDetailsModal={showDetailsModal}
                    showTrail={() => onToggleTrailModal(item)}
                    availableModules={this.state.availableModules}
                    checked={this.state.checked}
                  />
                  {/* <Edit */}
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="Service Offering Config Items"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></ServiceOfferingConfigsList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ServiceOfferingConfigList;
