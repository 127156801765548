import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import { ActionTypes } from "../../../enums/actionTypes";
import swal from "sweetalert";
import { toast } from "react-toastify";
import bvnGreyListService from "../../../services/mandate/bvnGreyListService";
import { getCurrentUsersRoles } from "../../../services/auth/authService";

interface IBVNGreyListDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  showTrail: any;
  currentUserRoles: any;
  item: any;
}

interface IBVNGreyListDetailState {
  item: any;
  updatingStatus: boolean;
}

class BVNGreyListDetail extends Component<
  IBVNGreyListDetailProps,
  IBVNGreyListDetailState
> {
  state = {
    item: {},
    updatingStatus: false,
  };

  static getDerivedStateFromProps(
    nextProps: IBVNGreyListDetailProps,
    prevState
  ) {
    // console.log("current props: ", nextProps.item);
    return {
      item: nextProps.item,
    };
  }

  onHandleEnableOrDisable = (
    e: any,
    actionType: ActionTypes,
    entityName: string
  ) => {
    this.setState({ updatingStatus: true });
    if (!actionType) {
      this.handleEnableOrDisableItem(this.props.item);
    } else {
      swal({
        title: "Confirm!",
        text: `Are you sure you want to proceed to ${ActionTypes[actionType]} this ${entityName}?`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode: true,
      }).then((resp) => {
        if (resp) {
          // console.log("cancelling action...");
          this.setState({ updatingStatus: false });
        } else {
          this.handleEnableOrDisableItem(this.state.item);
        }
      });
    }
  };

  handleEnableOrDisableItem = async (_item: any) => {
    try {
      this.setState({ updatingStatus: true });
      await bvnGreyListService.removeFromGreyList(_item.id);
      toast.info(`Grey-Listed BVN ${_item.bvn} removed successfully! `, {
        autoClose: 6000,
        type: toast.TYPE.DEFAULT,
        hideProgressBar: false,
      });
      this.setState({ updatingStatus: false });
      this.props.toggleDetailsModal();
    } catch (ex) {
      this.setState({ updatingStatus: false });
      // console.log("caught exception", ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
      } else {
        toast.error("Internal server error. Please contact the admin.");
        //this.setState({ errors, fetching: false });
      }
    }
  };

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      showTrail,
      currentUserRoles,
      item,
    } = this.props;
    const { updatingStatus } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="fab fa-first-order-alt fa-sm s-18 mr-3" />
                BVN Grey-List Details -{" "}
                <span className="font-weight-bold">{item.name}</span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
              <div className="float-right">
                {_.isEmpty(item) ? (
                  <Skeleton width={100} />
                ) : (
                  <span
                    className="badge btn-primary"
                    onClick={showTrail}
                    style={{
                      cursor: "pointer",
                      visibility: currentUserRoles.includes(
                        "viewaudittrailitems"
                      )
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    View Trail
                  </span>
                )}
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="BVN"
                        value={item.bvn}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Initiator Name"
                        value={item.initiatorName}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Customer Phone Number"
                        value={item.customerPhoneNumber}
                        labelSize={6}
                        valueSize={6}
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Grey-Listing Institution"
                        value={item.institutionName}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Customer Name"
                        value={item.customerName}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Date GreyListed"
                        value={item.dateCreated}
                        labelSize={6}
                        valueSize={6}
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>
            <div
              className="card-header card-footer"
              style={{ padding: "0px", height: "35px", paddingTop: "5px" }}
            >
              <i className="fas fa-list-alt mr-3 ml-3" /> Other Grey-Listing
              Institutions
            </div>
            <div className="card-body">
              <div
                className="card-content table-responsive text-nowrap"
                style={{ height: "250px", overflowY: "scroll" }}
              >
                <table className="table table-hover table-content table-sm table-bordered table-striped mb-0">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        style={{
                          width: "5px",
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                        }}
                      >
                        <strong>#</strong>
                      </th>
                      <th
                        scope="col"
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                        }}
                      >
                        <strong>Institution Name</strong>
                      </th>
                      <th
                        scope="col"
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                        }}
                      >
                        <strong>Institution Type</strong>
                      </th>
                      <th
                        scope="col"
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                        }}
                      >
                        <strong>Initiator Name</strong>
                      </th>
                      <th
                        scope="col"
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                        }}
                      >
                        <strong>Date GreyListed</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.isEmpty(item) ? (
                      <tr>
                        <td
                          className="font-weight-normal font-italic text-truncate"
                          colSpan={4}
                          style={{ textAlign: "center", color: "#FE825E" }}
                        >
                          ...fetching institutions. Please Wait!
                        </td>
                      </tr>
                    ) : (
                      item.otherGreyListingInstitutions.map((entity) => (
                        <tr key={entity.institutionName}>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.otherGreyListingInstitutions.indexOf(entity) +
                              1}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {entity.institutionName}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {entity.institutionType}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {entity.initiatorName}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {entity.dateGreyListed}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={1} height={30} />
          ) : getCurrentUsersRoles().includes("viewlinkedaccounts") ? (
            <React.Fragment>
              <Button
                size="sm"
                variant="outline-secondary"
                disabled={updatingStatus}
                onClick={(e) =>
                  this.onHandleEnableOrDisable(
                    e,
                    ActionTypes.REMOVE,
                    "GreyListed BVN"
                  )
                }
              >
                <i className="far fa-edit mr-3" />
                Remove
              </Button>
            </React.Fragment>
          ) : (
            ""
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default BVNGreyListDetail;
