import React from "react";
import { toast } from "react-toastify";
import activityTrailService from "../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../components/BaseFormComponent";
import { ActionTypes } from "../../enums/actionTypes";
import sweepInitiationConfigService from "../../services/sweep/sweepInitiationConfigService";
import sweepValidationService from "../../services/sweep/sweepValidationService";
import { ISelectItems } from "../../components/detailItemSelectBox";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../components/detailItem";
import Skeleton from "react-loading-skeleton";
import DetailItemInputComponent from "../../components/detailItemInputBox";
import SelectInputComponent from "../../components/formSelectComponent";

interface SweepInitiationConfigModalFields {
  noOfSweepsDaily: string;
  interval: string;
}

class SweepInitiationConfigModalForm extends BaseFormComponent<SweepInitiationConfigModalFields> { }

interface ISweepInitiationConfigModalProps {
  showModal: boolean;
  toggleModal: any;
}
interface ISweepInitiationConfigModalState {
  sweepInitiationConfig: any;
  category: string;
  designation: string;
  // formChangeFunction: any;
  editing: boolean;
  fetching: boolean;
}

class SweepInitiationConfigModal extends React.Component<
  ISweepInitiationConfigModalProps,
  IBaseFormState & ISweepInitiationConfigModalState
> {
  constructor(props: ISweepInitiationConfigModalProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      category: "",
      designation: "",
      sweepInitiationConfig: {},
      // formChangeFunction: null,
      fetching: false,
      editing: false
    };
  }

  onFormSubmit(fields: SweepInitiationConfigModalFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors:
            sweepValidationService.validateSweepInitiationConfigModalForm(
              fields
            ),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: SweepInitiationConfigModalFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      // console.log("starting submission...");
      this.setState({ submitting: true });
      const payload = { ...this.state.sweepInitiationConfig };

      // payload.institutionCode = ;
      payload.noOfSweepsDaily = Number(fields.noOfSweepsDaily);
      payload.interval = Number(fields.interval);
      payload.institutionCategory = this.state.category;
      payload.loanType = this.state.designation;

      const response = await sweepInitiationConfigService.updateSweepInitiationConfig(
        this.state.category,
        this.state.designation,
        payload
      );

      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Modify Sweep Initiation Config",
          description: `Modified Sweep Initiation Configuration`,
        });

        toast.info(`Sweep Initiation Coniguration modified successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false });
      onReloadFieldsCB();
    } catch (error) {
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      // console.log("error: ", error.response.data);
      return false;
    } finally {
      return true;
    }
  }

  toggleEditMode = () => {
    this.setState({ editing: !this.state.editing })
  }

  handleOnSelectCategory = async (id, value) => {
    this.setState({ category: value });
  };

  handleOnSelectDesignation = async (id, value, changeFunction) => {
    try {
      this.setState({ designation: value, fetching: true });
      const sweepInitiationConfig = await sweepInitiationConfigService.retrieveByCategoryAndDesignation(this.state.category, value);
      changeFunction("noOfSweepsDaily", sweepInitiationConfig.data.noOfSweepsDaily);
      changeFunction("interval", sweepInitiationConfig.data.interval);

      this.setState({
        sweepInitiationConfig: sweepInitiationConfig.data,
        fetching: false
      });
    } catch (error) {
      // console.log("error: ", error);
      // console.log("error: ", error.response);
      if (error.response.status === 400 || error.response.status === 409) {
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // return false;
      }
      this.setState({ fetching: false });
    }
  };

  render() {
    const { showModal, toggleModal } = this.props;
    const { category, designation, sweepInitiationConfig, editing, submitting, fetching, validated, errors } = this.state;

    let categoryInputData: ISelectItems[] = [
      "CommercialBank",
      "BankOneOFI",
      "OFI",
    ].map((item) => ({
      name: item,
      value: item,
    }));

    let designationInputData: ISelectItems[] = [
      "Active",
      "Bad",
      "Lost",
    ].map((item) => ({
      name: item,
      value: item,
    }));


    return (
      <Modal size="xl" backdrop="static" show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>
              <i className="icon-build mr-3"></i>
              Sweep Initiation Configuration
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SweepInitiationConfigModalForm
            initialValues={{ interval: "", noOfSweepsDaily: "" }}
            FormComponent={({
              fields: { interval, noOfSweepsDaily },
              onChange,
              onReloadFields,
              onHandleSubmit,
            }) => (
              <form action="#">
                <div className="card no-b">
                  <div className="card-body">
                    <h5 className="card-title">
                      <i className="fas fa-tools mr-3" />
                      Configure Sweep Initiation Details
                    </h5>
                    <hr />
                    <div className="form-row">
                      <div className="col-md-12">
                        <div className="form-row">
                          <SelectInputComponent
                            id="category"
                            name="institutionCategory"
                            divClass={6}
                            value={category}
                            items={categoryInputData}
                            required={true}
                            validated={false}
                            disabled={editing}
                            errors={[]}
                            onChange={this.handleOnSelectCategory}
                          />
                          {category == "" ? (
                            ""
                          ) : (
                            <SelectInputComponent
                              id="designation"
                              name="designation"
                              divClass={6}
                              value={designation}
                              items={designationInputData}
                              required={true}
                              validated={false}
                              disabled={editing}
                              errors={[]}
                              onChange={(id, value) => this.handleOnSelectDesignation(id, value, onChange)}
                            />
                          )}
                        </div>
                        <br />
                        {_.isEmpty(sweepInitiationConfig) ? (
                          ""
                        ) : (
                          <div className="form-row">
                            <div className="col-md-6">
                              {!_.isEmpty(sweepInitiationConfig) && fetching ? (
                                <Skeleton count={2} width={300} />
                              ) : (
                                <dl className="row">
                                  <DetailItem
                                    label="Category"
                                    value={category}
                                    labelSize={5}
                                    valueSize={7}
                                  />
                                  {editing ? (
                                    <DetailItem
                                      label="No. Of Sweeps Daily"
                                      value={
                                        <DetailItemInputComponent
                                          id="noOfSweepsDaily"
                                          type="number"
                                          name="noOfSweepsDaily"
                                          placeholder=""
                                          value={noOfSweepsDaily}
                                          required={true}
                                          validated={validated}
                                          errors={errors}
                                          onChange={onChange}
                                        />
                                      }
                                      labelSize={5}
                                      valueSize={7}
                                    />
                                  ) : (
                                    <DetailItem
                                      label="No. Of Sweeps Daily"
                                      value={noOfSweepsDaily}
                                      labelSize={5}
                                      valueSize={7}
                                    />
                                  )}
                                </dl>
                              )}
                            </div>
                            <div className="col-md-6">
                              {!_.isEmpty(sweepInitiationConfig) && fetching ? (
                                <Skeleton count={2} width={300} />
                              ) : (
                                <dl className="row">
                                  <DetailItem
                                    label="Loan Type"
                                    value={designation}
                                    labelSize={5}
                                    valueSize={7}
                                  />
                                  {editing ? (
                                    <DetailItem
                                      label="Interval"
                                      value={
                                        <DetailItemInputComponent
                                          id="interval"
                                          type="number"
                                          name="interval"
                                          placeholder=""
                                          value={interval}
                                          required={true}
                                          validated={validated}
                                          errors={errors}
                                          onChange={onChange}
                                        />
                                      }
                                      labelSize={5}
                                      valueSize={7}
                                    />
                                  ) : (
                                    <DetailItem
                                      label="Interval"
                                      value={interval}
                                      labelSize={5}
                                      valueSize={7}
                                    />
                                  )}
                                </dl>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {editing ? (
                    <React.Fragment>
                      <hr />
                      <div className="card-body">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary"
                          disabled={this.state.submitting}
                          onClick={(e) => {
                            onHandleSubmit(
                              e,
                              ActionTypes.UPDATE,
                              "Sweep Initiation Configuration",
                              () => {
                                this.onFormSubmit(
                                  { interval, noOfSweepsDaily },
                                  onReloadFields
                                );
                              }
                            );
                          }}
                        >
                          {this.state.submitting === false ? (
                            <React.Fragment>
                              <i className="fas fa-lg fa-save mr-3" /> Save
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                              Please wait...
                            </React.Fragment>
                          )}
                        </button>
                      </div>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </div>
              </form>
            )}
          ></SweepInitiationConfigModalForm>
        </Modal.Body>
        <Modal.Footer>
          <React.Fragment>
            {(!editing && !_.isEmpty(sweepInitiationConfig)) && (
              <Button
                size="sm"
                variant="primary"
                disabled={submitting}
                onClick={this.toggleEditMode}
              >
                <i className="far fa-edit mr-3" />
                Edit
              </Button>
            )}
            <Button
              size="sm"
              variant="outline-danger"
              onClick={toggleModal}
            >
              <i className="fas fa-times mr-3" />
              Close
            </Button>
          </React.Fragment>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SweepInitiationConfigModal;
