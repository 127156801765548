import React, { useCallback, useEffect, useState } from "react";
import "../fam.css";
import corporateAnalysisService from "../../../../../../../../services/hybridAppraisal/corporateAnalysisService";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import _ from "lodash";
import {
  downloadBlobAsFile,
  getBase64StringFromFile,
} from "../../../../../../../../services/utility/textFormatService";
import DetailItem from "../../../../../../../../components/detailItem";
import moment from "moment";

export default function FamSummaryInitiation({ loan, navIndex }: any) {
  const [loading, setLoading] = useState(false);
  const [downloadButtonLoading, setDownloadButtonLoading] = useState(false);
  const [file, setFile] = useState<File>();
  const [facilitySummary, setFacilitySummary] = useState<any>();
  const [businessSummary, setBusinessSummary] = useState<any>();
  const [others, setOthers] = useState({});
  const [currencyRisk, setCurrencyRisk] = useState<CurrencyRisk>();

  const [founder, setFounder] = useState<{
    name: string;
    stake: number | string;
  } | null>();
  const [founders, setFounders] = useState<
    { name: string; stake: number | string }[]
  >([]);
  const jsonLoanDetails = !_.isEmpty(loan?.jsonData)
    ? JSON.parse(JSON.parse(JSON.parse(loan?.jsonData))?.LoanDetails)
    : {};
  const [isUploaded, setIsUploaded] = useState<any>();

  useEffect(() => {
    const getUploadStatus = async () => {
      const response = await corporateAnalysisService.checkUploaded({
        loanRequestId: loan?.id,
      });
      if (response?.status === 200) {
        setIsUploaded(response?.data);
      }
    };
    getUploadStatus();
  }, [loan]);

  useEffect(() => {
    if (!!isUploaded?.famSummaryData) {
      const getFAMSummaryRecords = async () => {
        const response =
          await corporateAnalysisService.previewFAMSummaryRecords({
            loanRequestId: loan?.id,
          });
        if (response?.status === 200) {
          setFacilitySummary({
            ...response?.data?.facilitySummary,
            assessmentRating: response?.data?.others?.assessmentRating,
            loanOfficer: loan?.branchManager,
            requestedLoanAmount: loan?.requestedLoanAmount,
            requestedLoanTenor: loan?.requestedTenureInDays,
            preparationDate: moment(
              response?.data?.facilitySummary?.preparationDate
            )
              .toISOString()
              .split("T")[0],
            siteVisitDate: moment(
              response?.data?.facilitySummary?.siteVisitDate
            )
              .toISOString()
              .split("T")[0],
            interviewDate: moment(
              response?.data?.facilitySummary?.interviewDate
            )
              .toISOString()
              .split("T")[0],
          });
          setBusinessSummary(({ prev }) => ({
            ...prev,
            ...response?.data?.businessSummary,
            businessName: loan?.companyName,
            businessAddress: loan?.address,
            experienceInYears: loan?.lengthOfYearsInBusiness,
            experienceInMonths: loan?.monthsInCurrentJob,
            numberOfStaff: loan?.employeeCount,
            ownershipStructure: loan?.constitution,
          }));
          setFounders(response?.data?.businessSummary?.founders);
          setOthers(response?.data?.others);
          setCurrencyRisk(response?.data?.currencyRisk);
        }
      };
      getFAMSummaryRecords();
    }
    setFacilitySummary((prev) => ({
      ...prev,
      loanOfficer: loan?.branchManager,
      interestRate: jsonLoanDetails?.InterestRate.toString(),
      requestedLoanAmount: loan?.requestedLoanAmount,
      requestedLoanTenor: loan?.requestedTenureInDays,
    }));
    setBusinessSummary((prev) => ({
      ...prev,
      businessName: loan?.companyName,
      businessAddress: loan?.address,
      experienceInYears: loan?.lengthOfYearsInBusiness,
      experienceInMonths: loan?.monthsInCurrentJob,
      numberOfStaff: loan?.employeeCount,
      ownershipStructure: loan?.constitution,
    }));
    //eslint-disable-next-line
  }, [loan, isUploaded, navIndex]);

  const handleDownloadTemplate = useCallback(async () => {
    try {
      setDownloadButtonLoading(true);
      const downloadable =
        await corporateAnalysisService.downloadPreviousBalanceSheetTemplate();
      if (downloadable?.statusText === "OK") {
        await downloadBlobAsFile(
          downloadable?.data,
          "PreviousBalanceSheetUploadTemplate.xlsx"
        );
      }
      setDownloadButtonLoading(false);
    } catch (error) {
      setDownloadButtonLoading(false);
    }
  }, []);

  const onDocumentChange = useCallback(
    async (e) => {
      e.preventDefault();
      if (e.target.files[0] === undefined) {
        return;
      }
      if (e.target.files[0].size > 1000000) {
        toast.error(
          "Please confirm that the size of the document is less than 1MB"
        );
      } else if (
        !["xlsx", "xls"].includes(e.target.files[0].name.split(".")[1])
      ) {
        toast.error(
          "Please confirm that the document is a valid excel document"
        );
      } else {
        try {
          setFile(e.target.files[0]);
          const response =
            await corporateAnalysisService.validateAndUploadPreviousBalanceSheetRecords(
              {
                institutionCode: loan?.institutionCode,
                loanRequestId: loan?.id,
                famInput: await getBase64StringFromFile(e.target.files[0]),
              }
            );

          if (!!response?.data?.item1?.entriesAreValid) {
            toast.success(
              "Previous Balance sheet Validated and Uploaded successfully"
            );
          } else {
            toast.error(response?.data?.item2?.[0]);
            setFile(null);
          }
        } catch (error) {
          setFile(null);
        }
      }
    },
    [loan]
  );
  const facilitySummaryOptions: Options[] = [
    {
      label: "Loan Officer",
      value: "loanOfficer",
      disabled: true,
    },
    { label: "Loan Number", value: "loanNumber", type: "number" },
    {
      label: "Proposed Loan Amount",
      value: "proposedLoanAmount",
      type: "number",
    },
    {
      label: "Requested Loan Amount",
      value: "requestedLoanAmount",
      type: "number",
      disabled: true,
    },
    { label: "Preparation Date", value: "preparationDate", type: "date" },
    { label: "Interview Date", value: "interviewDate", type: "date" },
    { label: "Site Visit Date", value: "siteVisitDate", type: "date" },
    {
      label: "Disbursal Month",
      value: "disbursalMonth",
      type: "select",
      selectOptions: months,
    },
    {
      label: "Loan Currency",
      value: "loanCurrency",
      type: "select",
      selectOptions: ["-Select Option-", "NGN", "GMD", "USD"],
    },
    {
      label: "Requested Grace Period (days)",
      value: "requestedGracePeriod",
      type: "number",
    },
    {
      label: "Recommended Grace Period (days)",
      value: "recommendedGracePeriod",
      type: "number",
    },
    // {
    //   label: "Proposed Grace Period (days)",
    //   value: "proposedGracePeriod",
    //   type: "number",
    // },
    {
      label: "Previous No. of Institution Loans",
      value: "previousNoOfInstitutionLoans",
      type: "number",
    },
    { label: "Loan Purpose", value: "loanPurpose" },
    {
      label: "Equity Contribution",
      value: "equityContribution",
      type: "number",
    },
    { label: "Proposed Tenor (days)", value: "proposedTenor", type: "number" },
    {
      label: "Requested Loan Tenor (days)",
      value: "requestedLoanTenor",
      type: "number",
      disabled: true,
    },
    {
      label: "Interest Rate (%)",
      value: "interestRate",
      type: "number",
      disabled: true,
    },
    {
      label: "Recommended Interest Rate (%)",
      value: "recommendedInterestRate",
      type: "number",
    },
    {
      label: "Administration Fee",
      value: "administrationFee",
      type: "number",
    },
    {
      label: "Assessment Rating",
      value: "assessmentRating",
      type: "select",
      selectOptions: ["-Select Option", ...numbers(10)],
    },
  ];
  const businessSummaryOptions: Options[] = [
    {
      label: "Business Name",
      value: "businessName",
      disabled: true,
    },
    { label: "Client Name", value: "clientName" },
    {
      label: "Business Address",
      value: "businessAddress",
      disabled: true,
    },
    { label: "Main Activity", value: "mainActivity" },
    {
      label: "Experience In Years",
      value: "experienceInYears",
      type: "number",
      disabled: true,
    },
    {
      label: "Experience In Months",
      value: "experienceInMonths",
      disabled: true,
      type: "number",
    },

    {
      label: "Number Of Staff",
      value: "numberOfStaff",
      type: "number",
      disabled: true,
    },
    {
      label: "Number Of Dependents",
      value: "numberOfDependents",
      type: "number",
    },
    {
      label: "Ownership Structure",
      value: "ownershipStructure",
      disabled: true,
    },
    { label: "Manager Name", value: "managerName" },
  ];

  const formatType = (type: Options["type"], value: any) => {
    switch (type) {
      case "number":
        // return Number(value);
        return value;
      case "date":
        return value;
      case "text":
        return value;
      default:
        return value;
    }
  };

  const handleChangeFacilityFields = (e: any, type: Options["type"]) => {
    const { name, value } = e.target;
    setFacilitySummary({
      ...facilitySummary,
      [name]: formatType(type, value),
    });
  };

  const handleChangeBusinessFields = (e: any, type: Options["type"]) => {
    const { name, value } = e.target;
    setBusinessSummary({
      ...businessSummary,
      [name]: formatType(type, value),
    });
  };

  const handleChangeOthers = (e: any, type: Options["type"]) => {
    const { name, value } = e.target;
    setOthers({
      ...others,
      [name]: formatType(type, value),
    });
  };

  const handleSaveSummary = useCallback(
    async (e) => {
      e.stopPropagation();
      e.preventDefault();
      try {
        setLoading(true);
        await corporateAnalysisService.submitFAMSummaryRecords({
          institutionCode: loan?.institutionCode,
          loanRequestId: loan?.id,
          facilitySummary: {
            ...facilitySummary,
            loanOfficer: loan?.branchManager,
            interestRate: jsonLoanDetails?.InterestRate.toString(),
            requestedLoanAmount: loan?.requestedLoanAmount,
            requestedLoanTenor: loan?.requestedTenureInDays,
          },
          businessSummary: {
            ...businessSummary,
            founders,
            businessName: !!loan?.businessName ? loan?.businessName : "",
            businessAddress: !!loan?.jobAddress ? loan?.jobAddress : "",
            experienceInYears: !!loan?.lengthOfYearsInBusiness
              ? loan?.lengthOfYearsInBusiness
              : "",
            experienceInMonths: !!loan?.monthsInCurrentJob
              ? loan?.monthsInCurrentJob
              : "",
            numberOfStaff: !!loan?.employeeCount ? loan?.employeeCount : "",
            ownershipStructure: !!loan?.constitution ? loan?.constitution : "",
          },
          others: {
            ...others,
            assessmentRating: facilitySummary?.assessmentRating,
          },
          currencyRisk,
          famInput: "",
        });
        toast.success("FAM Summary saved successfully");
        setLoading(false);
      } catch (error) {
        toast.error("Failed to save FAM Summary");
        setLoading(false);
      }
    },
    //eslint-disable-next-line
    [loan, facilitySummary, businessSummary, founders, others, currencyRisk]
  );

  const facilitySummaryType = (index: number) => {
    switch (facilitySummaryOptions[index].type) {
      case "number":
        return "number";
      case "date":
        return "date";
      case "text":
        return "text";
      default:
        return "text";
    }
  };

  const businessSummaryType = (index: number) => {
    switch (businessSummaryOptions[index].type) {
      case "number":
        return "number";
      case "date":
        return "date";
      case "text":
        return "text";
      default:
        return "text";
    }
  };

  return (
    <div>
      <form onSubmit={handleSaveSummary}>
        <p style={{ fontSize: 16, fontWeight: "bold" }}>Facility Summary</p>
        <div className="col-grid-2">
          {facilitySummaryOptions.map((item, index) => (
            <div key={index}>
              <label>{item?.label}</label>
              {item.type !== "select" ? (
                <input
                  disabled={item?.disabled}
                  required={true}
                  name={item?.value}
                  className="basic-input"
                  value={facilitySummary?.[item?.value] || ""}
                  type={facilitySummaryType(index)}
                  defaultValue={item?.defaultValue}
                  onChange={(e) => handleChangeFacilityFields(e, item?.type)}
                />
              ) : (
                <select
                  disabled={item?.disabled}
                  required={true}
                  name={item?.value}
                  className="basic-select"
                  value={facilitySummary?.[item?.value] || ""}
                  defaultValue={item?.defaultValue}
                  onChange={(e) => handleChangeFacilityFields(e, item?.type)}
                >
                  {item?.selectOptions?.map((option) => (
                    <option key={option}>{option}</option>
                  ))}
                </select>
              )}
            </div>
          ))}
        </div>

        <p style={{ marginTop: 20, fontSize: 16, fontWeight: "bold" }}>
          Business Summary
        </p>
        <div className="col-grid-2">
          {businessSummaryOptions.map((item, index) => (
            <div key={index}>
              <label>{item?.label}</label>
              {item?.type !== "select" ? (
                <input
                  disabled={item?.disabled}
                  required={true}
                  name={item?.value}
                  className="basic-input"
                  value={businessSummary?.[item?.value] || ""}
                  defaultValue={item?.defaultValue}
                  type={businessSummaryType(index)}
                  onChange={(e) => {
                    handleChangeBusinessFields(e, item?.type);
                  }}
                />
              ) : (
                <select
                  disabled={item?.disabled}
                  required={true}
                  name={item?.value}
                  className="basic-select"
                  value={businessSummary?.[item?.value] || ""}
                  defaultValue={item?.defaultValue}
                  onChange={(e) => handleChangeBusinessFields(e, item?.type)}
                >
                  {item?.selectOptions?.map((option) => (
                    <option key={option}>{option}</option>
                  ))}
                </select>
              )}
            </div>
          ))}
        </div>
        <p style={{ marginTop: 20, fontSize: 16, fontWeight: "bold" }}>
          Founders
        </p>
        <div className="col-grid-2">
          <div>
            <label>Name</label>
            <input
              className="basic-input"
              value={founder?.name}
              type="text"
              onChange={(e) => {
                setFounder({ ...founder, name: e.target.value });
              }}
            />
          </div>
          <div>
            <label>Stake(%)</label>
            <input
              className="basic-input"
              value={founder?.stake}
              type="number"
              onChange={(e) => {
                setFounder({ ...founder, stake: Number(e.target.value) });
              }}
            />
          </div>
        </div>
        <button
          className="btn btn-sm btn-outline-primary my-4"
          style={{ cursor: "pointer" }}
          disabled={!founder?.name && !founder?.stake}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (!!founder?.name && !!founder?.stake) {
              if (
                _.isEmpty(
                  founders?.find(
                    (f) =>
                      f.name === founder?.name || f.stake === founder?.stake
                  )
                )
              ) {
                founders.push(founder);
                setFounder({ name: "", stake: "" });
              } else {
                toast.warning("Duplicates are not allowed");
              }
            }
          }}
        >
          <React.Fragment>
            <i className="fas fa-plus-circle mr-2" /> Add
          </React.Fragment>
        </button>

        <div className="row clearfix">
          <div style={{ marginBottom: 30 }} className=" form-row col-md-12">
            <div
              className="table-responsive text-nowrap"
              style={{
                overflowY: "scroll",
              }}
            >
              <table className="table table-hover table-content table-sm table-striped mb-0">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                      }}
                    >
                      <strong>#</strong>
                    </th>
                    <th
                      scope="col"
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                      }}
                    >
                      <strong>Name</strong>
                    </th>
                    <th
                      scope="col"
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                      }}
                    >
                      <strong>Stake</strong>
                    </th>
                    <th
                      scope="col"
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                      }}
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {founders?.map((item, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                        }}
                      >
                        {item?.name}
                      </td>
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                        }}
                      >
                        {`${item?.stake}%`}
                      </td>
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                        }}
                      >
                        <i
                          className="far fa-trash-alt ml-5 red-text fa-lg"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            const newFounders = founders?.filter(
                              ({ stake }) => stake !== item?.stake
                            );
                            setFounders(newFounders);
                          }}
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <p style={{ marginTop: 20, fontSize: 16, fontWeight: "bold" }}>
          Others
        </p>
        <div className="col-grid-1">
          {otherOptions.map((item, index) => (
            <div key={index}>
              <label htmlFor={item?.value}>{item?.label}</label>
              <textarea
                disabled={item?.disabled}
                required={true}
                name={item?.value}
                className="basic-input-area"
                value={others?.[item?.value] || ""}
                defaultValue={item?.defaultValue}
                onChange={(e) => handleChangeOthers(e, item?.type)}
              />
            </div>
          ))}
        </div>
        <p style={{ marginTop: 20, fontSize: 16, fontWeight: "bold" }}>
          Currency Risk
        </p>
        <div className="col-grid-2">
          <div>
            <label>Risk</label>
            <select
              required={true}
              className="basic-select"
              value={currencyRisk?.risk}
              onChange={(e) => {
                const { value } = e.target;
                const selectedRisk = currencyRisks?.find(
                  ({ risk }) => risk === value
                );
                setCurrencyRisk(selectedRisk);
              }}
            >
              {currencyRisks?.map((option) => (
                <option key={option.risk}>{option.risk}</option>
              ))}
            </select>
          </div>
          <div>
            <label>Category</label>
            <input
              disabled={true}
              className="basic-input"
              value={currencyRisk?.category}
            />
          </div>
          <div>
            <label>Rating(%)</label>
            <input
              disabled={true}
              className="basic-input"
              value={currencyRisk?.rating}
            />
          </div>
        </div>
        <button
          className="btn btn-sm btn-outline-primary mt-5"
          style={{ cursor: "pointer" }}
          type="button"
          disabled={false}
          onClick={handleDownloadTemplate}
        >
          {!downloadButtonLoading ? (
            <React.Fragment>
              <i className="far fa-save fa-lg mr-2" /> Download Template
            </React.Fragment>
          ) : (
            <React.Fragment>
              <i className="fas fa-spinner fa-spin" />
            </React.Fragment>
          )}
        </button>
        <div className="form-row mt-3">
          <DetailItem
            label="Upload Previous Balance Sheet"
            value={
              <div className="input-group">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    aria-describedby="inputGroupFileAddon01"
                    onChange={(e) => onDocumentChange(e)}
                  />
                  <label className="custom-file-label" htmlFor="docToUpload">
                    {!file ? "Choose File" : file?.name}
                  </label>
                </div>
              </div>
            }
            labelSize={3}
            valueSize={9}
          />
        </div>
        <Button
          style={{ marginTop: 20 }}
          size="sm"
          variant="primary"
          type="submit"
        >
          {!loading ? (
            <React.Fragment>
              <i className="fas fa-lg fa-save mr-3" />
              Save
            </React.Fragment>
          ) : (
            <React.Fragment>
              <i className="fas fa-spin fa-circle-notch" />
            </React.Fragment>
          )}
        </Button>
      </form>
    </div>
  );
}

type Options = {
  label: string;
  value: string;
  onChange?: () => void;
  type?: "date" | "text" | "number" | "select";
  selectOptions?: string[];
  disabled?: boolean;
  defaultValue?: any;
};

export type CurrencyRisk = { risk: string; category: string; rating: number };

let numberArray: string[] = [];
const numbers = (maxNumber: number, margin?: number, startNumber?: number) => {
  for (
    let i = !!startNumber ? startNumber : 0;
    i <= maxNumber;
    !!margin ? (i += margin) : i++
  ) {
    numberArray[i] = i.toString();
  }
  return numberArray;
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const otherOptions: Options[] = [
  {
    label: "Strengths and Opportunities",
    value: "strengthsAndOpportunities",
  },
  { label: "Threats and Weaknesses", value: "threatsAndWeaknesses" },
  { label: "Comments and Conclusions", value: "commentsAndConclusions" },
  { label: "Assessment Comment", value: "assessmentComment" },
];

const currencyRisks: CurrencyRisk[] = [
  {
    risk: "-Select Option-",
    category: "",
    rating: 0,
  },
  {
    risk: "Client buys in local and sells in international currency",
    category: "A",
    rating: 100,
  },
  { risk: "Buying and selling in same currency", category: "B", rating: 80 },
  {
    risk: "Buys in international and sells in local currency",
    category: "C",
    rating: 10,
  },
];
