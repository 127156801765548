import React, { Component, Fragment } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import BaseFormComponent, { IBaseFormState } from "../../../../components/BaseFormComponent";
import FormInputComponent from "../../../../components/formInputComponent";
import SelectInputComponent, { ISelectItems } from "../../../../components/formSelectComponent";
import { EconomicSector } from "../../../../enums/economicSector";
import { LoanComputationMode } from "../../../../enums/loanComputationMode";
import { ProductType } from "../../../../enums/productType";
import textFormatService, { generateGUID } from "../../../../services/utility/textFormatService";
import RepaymentSchedule from "./wizardComponents/editDetailedDataEntry/repaymentSchedule";
import loanRequestService from "../../../../services/hybridAppraisal/loanRequestService";
import { ActionTypes } from "../../../../enums/actionTypes";
import SupportingDocuments from "../../loanRequest/appraisalProcessComponents/supportingDocuments";
import { getCurrentUserSession } from "../../../../services/auth/authService";
import appraisalActionService from "../../../../services/hybridAppraisal/appraisalActionService";
import activityTrailService from "../../../../services/auditTrail/activityTrailService";
import FormTextAreaComponent from "../../../../components/formTextAreaComponent";
import { Accordion, Button, Card, Modal } from "react-bootstrap";
import { ContextAwareToggle } from "../../loanAppraisal/appraisalDetailPages/BankAnalysisCheck";
import Skeleton from "react-loading-skeleton";
import ValidateAmountModal from "./validateAmountModal";
import groupLoanRequestMembersService from "../../../../services/hybridAppraisal/groupLoanRequestMembersService";


interface updateLoanAmountFields {
    loanAmount: number;
    phoneNumber: string;
    solidarityGroup: string;
    tin: string;
    gender: string;
    accountName: string;
    accountNumber: string;
    preferredRepaymentAccount: string;
    newAmount: string;
}

class UpdateLoanAmountForm extends BaseFormComponent<updateLoanAmountFields> { }

interface updateLoanAmountProps {
    values: any;
    item: any;
    showAmountModal: boolean;
    toggleAmountModal: any;
}
interface updateLoanAmountState {
    showRepaymentSchedule: boolean;
    showDocPatchModal: boolean;
    applicableRules: any;
    appraisalActions: any;
    updateComponent: boolean;
    documentsToUpload: any;
    documentsToDelete: number[];
    updateAvailable: boolean;
    item: any;
    ownUpdate: boolean;
    showValidateAmountScreen: boolean;
    newAmount: number;
}

class ValidateAmount extends Component<
    updateLoanAmountProps,
    IBaseFormState & updateLoanAmountState
> {
    constructor(props: updateLoanAmountProps) {
        super(props);
        this.state = {
            item: {},
            validated: false,
            submitting: false,
            errors: {},
            showRepaymentSchedule: false,
            showDocPatchModal: false,
            applicableRules: [],
            appraisalActions: [],
            documentsToUpload: [],
            documentsToDelete: [],
            updateAvailable: false,
            updateComponent: false,
            ownUpdate: false,
            showValidateAmountScreen: false,
            newAmount: 0
        };
    }

    // static getDerivedStateFromProps(
    //     nextProps: updateLoanAmountProps,
    //     prevState
    // ) {
    //     if (prevState.ownUpdate) {
    //         return {
    //             ownUpdate: false,
    //         };
    //     }
    //     if (nextProps.item.jsonData && prevState.item?.CustomerID != JSON.parse(JSON.parse(nextProps.item.jsonData)).CustomerID) {
    //         // console.log("Updating state afresh")
    //         // console.log(JSON.parse(JSON.parse(nextProps.item)))
    //         return { item: JSON.parse(JSON.parse(nextProps.item.jsonData)) };
    //     }
    //     return null;
    // }

    toggleShowValidateAmountModal = () => {
        this.setState({ showValidateAmountScreen: !this.state.showValidateAmountScreen });
    };

    onFormSubmit(newAmount: number): boolean {
        try {
            this.setState(
                { errors: [], ownUpdate: true },
                () => {

                    return this.submit(newAmount);

                }
            );
            this.setState({ validated: true, ownUpdate: true });
            return false;
        } catch (error) {
            return false;
        }
    }
    async submit(
        newAmount: number
    ): Promise<boolean> {
        try {
            this.setState({ submitting: true, ownUpdate: true });
            const loanRequest = await groupLoanRequestMembersService.retrieveById(this.props.values.id);
            const loanRequestDetail = loanRequest.data;
            const payload: any = { ...loanRequestDetail };

            // const loanDetails: any = {};
            payload.loanAmount = newAmount * 100;


            const { item } = this.props;
            // console.log("Payload: ", payload)
            const response = await groupLoanRequestMembersService.updateLoanRequest(payload)
            if (response.status >= 200 && response.status <= 300) {
                // toggleAppraisalProcess()
                activityTrailService.saveActivityTrail({
                    actionType: "Loan Amount Updated",
                    description: `Updated Amount`,
                });
                toast.info(`Loan Amount has been updated`, {
                    autoClose: 6000,
                    type: toast.TYPE.INFO,
                    hideProgressBar: false,
                });
            } else {
                return false;
            }
            this.setState({ submitting: false, ownUpdate: true });
            this.resetFields();
            // onReloadFieldsCB();
        } catch (error) {
            if (error.response.status === 409) {
                toast.error(error.response.data.detail, {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            } else if (error.response.status === 400) {
                toast.error(error.response.data.errors[0], {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            }
            this.setState({ submitting: false, ownUpdate: true });
            return false;
        } finally {
            return true;
        }
    }
    setNewAmount = (id, value) => {
        this.setState({ newAmount: value });
    }

    resetFields() {
        this.setState({ validated: false, submitting: false, errors: [], ownUpdate: true });
    }

    render() {
        const { values, showAmountModal, toggleAmountModal } = this.props;
        const { errors, validated, item } = this.state;
        console.log(values);

        return (
            <Modal
                size="lg"
                backdrop="static"
                show={showAmountModal}
                onHide={toggleAmountModal}
            >
                <Modal.Header>
                    <Modal.Title>
                        <h5>
                            <i className="fas fa-edit fa-sm cyan-text mr-3" />
                            Individual Approved Loan
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <UpdateLoanAmountForm
                    initialValues={{
                        tin: values.personalTIN,
                        gender: values.gender,
                        phoneNumber: values.phoneNumber,
                        solidarityGroup: values.solidarityGroupName,
                        newAmount: "",
                        loanAmount: (values.initialLoanAmount / 100),
                        accountName: values.firstName + " " + values.lastName,
                        accountNumber: values.accountNumber,
                        preferredRepaymentAccount: values.loanDetails?.preferredRepaymentAccount ? values.loanDetails?.preferredRepaymentAccount : "",
                    }}
                    FormComponent={({
                        fields: {
                            loanAmount,
                            accountName,
                            accountNumber,
                            tin,
                            newAmount,
                            solidarityGroup,
                            gender,
                            phoneNumber
                        },
                        onChange,
                        onReloadFields,
                        onHandleSubmit,
                    }) => (
                        <><Modal.Body>
                            <div className="form-row">
                                <div className="card-header clear-fix">
                                    <h6
                                        className="card-title float-left"
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <b>Edit Approved Loan Amount</b>
                                    </h6>
                                </div>
                                <div className="col-md-12">

                                    {/* Loan Information */}
                                    <div className="card">
                                        <div className="card-header clear-fix">
                                            <h6
                                                className="float-left"
                                                style={{ marginBottom: "0px" }}
                                            >
                                                <b>Loan Information</b>
                                            </h6>
                                        </div>
                                        <div className="card-body">
                                            <div className="form-row">
                                                <FormInputComponent
                                                    label="Approved Loan Amount"
                                                    id="loanAmount"
                                                    name="loanAmount"
                                                    type="number"
                                                    placeholder=""
                                                    divClass={6}
                                                    value={loanAmount.toString()}
                                                    required={true}
                                                    validated={validated}
                                                    errors={errors}
                                                    onChange={onChange} />
                                                <FormInputComponent
                                                    label={"Customer Name"}
                                                    id="accountName"
                                                    name="accountName"
                                                    type="text"
                                                    placeholder=""
                                                    divClass={6}
                                                    value={accountName}
                                                    required={true}
                                                    validated={validated}
                                                    errors={errors}
                                                    onChange={onChange}
                                                    disabled={true} />
                                                <FormInputComponent
                                                    label="Account Number"
                                                    id="accountNumber"
                                                    name="accountNumber"
                                                    type="text"
                                                    placeholder=""
                                                    divClass={6}
                                                    value={accountNumber}
                                                    required={true}
                                                    validated={validated}
                                                    errors={errors}
                                                    onChange={onChange}
                                                    disabled={true} />
                                                <FormInputComponent
                                                    label="Personal Tin"
                                                    id="tin"
                                                    name="tin"
                                                    type="text"
                                                    placeholder=""
                                                    divClass={6}
                                                    value={tin}
                                                    required={true}
                                                    validated={validated}
                                                    errors={errors}
                                                    onChange={onChange}
                                                    disabled={true} />
                                                <FormInputComponent
                                                    // label="Personal Tin"
                                                    id="gender"
                                                    name="gender"
                                                    type="text"
                                                    placeholder=""
                                                    divClass={6}
                                                    value={gender}
                                                    required={true}
                                                    validated={validated}
                                                    errors={errors}
                                                    onChange={onChange}
                                                    disabled={true} />
                                                <FormInputComponent
                                                    label="Phone Number"
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    type="text"
                                                    placeholder=""
                                                    divClass={6}
                                                    value={phoneNumber}
                                                    required={true}
                                                    validated={validated}
                                                    errors={errors}
                                                    onChange={onChange}
                                                    disabled={true} />
                                                <FormInputComponent
                                                    label="Solidarity Group"
                                                    id="SolidarityGroup"
                                                    name="SolidarityGroup"
                                                    type="text"
                                                    placeholder=""
                                                    divClass={6}
                                                    value={solidarityGroup}
                                                    required={true}
                                                    validated={validated}
                                                    errors={errors}
                                                    onChange={onChange}
                                                    disabled={true} />
                                                <FormInputComponent
                                                    label="New Loan Amount"
                                                    id="newAmount"
                                                    name="newAmount"
                                                    type="number"
                                                    placeholder=""
                                                    divClass={6}
                                                    value={newAmount}
                                                    required={true}
                                                    validated={validated}
                                                    errors={errors}
                                                    onChange={onChange}
                                                />
                                            </div>
                                            <div className="form-row mt-2">
                                                <Button
                                                    size="sm"
                                                    variant="outline-primary"
                                                    onClick={(e) => {
                                                        onHandleSubmit(
                                                            e,
                                                            ActionTypes.SAVE,
                                                            "New Amount",
                                                            () => {
                                                                this.onFormSubmit(Number(newAmount));
                                                            }
                                                        );
                                                    }}
                                                    disabled={this.state.submitting}
                                                >
                                                    <i className="fas fa-edit mr-3" />
                                                    Update
                                                </Button>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </Modal.Body><Modal.Footer>
                                <Button
                                    size="sm"
                                    variant="outline-danger"
                                    onClick={() => toggleAmountModal(false, {}, {})}
                                    disabled={this.state.submitting}
                                >
                                    <i className="fas fa-times mr-3" />
                                    Close
                                </Button>
                            </Modal.Footer></>
                    )}
                ></UpdateLoanAmountForm>
            </Modal>
        );
    }
}

export default ValidateAmount;

