import React from "react";
import _ from "lodash";

export interface IThirdPartyImageViewProps {
    thirdPartyName: "None" | "Interswitch" | "Remita" | "Flutterwave" | "Paystack" | "Mono" | "Okra";
}

const ThirdPartyImageView: React.FC<IThirdPartyImageViewProps> = ({ thirdPartyName }) => {
    let sweepServicelogo = null; //test

    switch (thirdPartyName) {
        case "None":
            sweepServicelogo = <img src="assets/img/basic/recova-logo.svg" width="70" alt={thirdPartyName} className="image" />;
            break;
        case "Interswitch":
            sweepServicelogo = <img src="assets/img/basic/interswitch.png" width="120" height="40" alt={thirdPartyName} className="image" />;
            break;
        case "Remita":
            sweepServicelogo = <img src="assets/img/basic/remita_orange_logo.svg" width="70" alt={thirdPartyName} className="image" />;
            break;
        case "Okra":
            sweepServicelogo = (
                <img
                    src="assets/img/basic/okra-logo.svg"
                    width={35}
                    // height={50}
                    alt={thirdPartyName}
                    className="image"
                />
            );
            break;
        case "Mono":
            sweepServicelogo = <img src="assets/img/basic/logo_mono.svg" width="70" alt={thirdPartyName} className="image" />;
            break;
        case "Paystack":
            sweepServicelogo = <img src="assets/img/basic/paystack_logo.svg" width="70" alt={thirdPartyName} className="image" />;
            break;
        default:
            sweepServicelogo = <img src="assets/img/basic/recova-logo.svg" width="70" alt={thirdPartyName} className="image" />;
            break;
    }
    return <>{sweepServicelogo}</>;
};

export default ThirdPartyImageView;
