import * as React from "react";
import { Fragment } from "react";
import { IFilterData, BaseListComponentState } from "../../../components/BaseListComponent";
import { createFilterData, mapEntityList } from "./../../../services/utility/autoMapperService";
import BaseListComponent from "./../../../components/BaseListComponent";
import ItemsTable, { filterFieldData } from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "./../../../components/tableComponents/tableFilterPane";
import sweepConfigService from "../../../services/sweep/sweepConfigService";
import SweepConfigurationDetail from "./institutionSweepConfigurationDetail";
import EditSweepConfiguration from "./editInstitutionSweepConfiguration";
import institutionService from "../../../services/auth/institutionsService";
import feeConfigService from "../../../services/sweep/feeConfigService";
import institutionFeeConfigService from "../../../services/billing/institutionFeeConfigService";
import sweepPriorityService from "../../../services/sweep/sweepPriorityService";

export interface InstitutionSweepConfiurationsProps {}

export interface InstitutionSweepConfiurationsState extends BaseListComponentState {
    data: IFilterData;
    currentPage: number;
    pageSize: number;
    tableItems: any;
    totalSize: number;
    fetchingDetail: boolean;
    filterValueInput: filterFieldData;
    allInstitutions: any;
    allCommercialBanks: any;
    item: any;
    recovaRecoveryFeeConfigItem: any;
    recovaCollectionFeeConfigItem: any;
    thirdPartyRecoveryFeeConfigItem: any;
    thirdPartyCollectionFeeConfigItem: any;
}

class InstitutionSweepConfiurationsList extends BaseListComponent<InstitutionSweepConfiurationsState> {}

class InstitutionSweepConfiurations extends React.Component<InstitutionSweepConfiurationsProps, InstitutionSweepConfiurationsState> {
    constructor(props: InstitutionSweepConfiurationsProps) {
        super(props);
        this.state = {
            data: createFilterData(["status", "sweepInitiationType"]) as IFilterData,
            currentPage: 1,
            pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
            fetching: false,
            fetchingDetail: false,
            tableItems: [],
            totalSize: 0,
            allInstitutions: [],
            allCommercialBanks: [],
            filterValueInput: [
                {
                    name: "status",
                    fieldType: "select",
                    options: ["Active", "Inactive"],
                    value: "",
                },
                {
                    name: "sweepInitiationType",
                    fieldType: "select",
                    options: ["Reactive", "Proactive", "Both"],
                    value: "",
                },
            ],
            item: {},
            recovaRecoveryFeeConfigItem: {},
            recovaCollectionFeeConfigItem: {},
            thirdPartyRecoveryFeeConfigItem: {},
            thirdPartyCollectionFeeConfigItem: {},
            showDetailsModal: false,
            showEditModal: false,
            showTableFilterModal: false,
            showTrailModal: false,
        };
    }

    handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
        currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
        pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
        this.setState({ fetching: true });
        data = typeof data === "undefined" ? this.state.data : data;
        try {
            const sweepConfigurations = await sweepConfigService.filterSweepConfig(
                data,
                pageSize,
                currentPage
            );
            this.setState({
                tableItems: mapEntityList({
                    entityList: sweepConfigurations.data.item2,
                    properties: [
                        { oldName: "name", newName: "name" },
                        { oldName: "code", newName: "code" },
                        { oldName: "integrationCode", newName: "integrationCode" },
                        {
                            isBoolean: true,
                            oldName: "disableNIPSettlement",
                            newName: "disableNIP Settlement",
                        },
                        {
                            isTag: true,
                            oldName: "status",
                            newName: "status",
                            success: "Active",
                            danger: "Inactive",
                            default: "",
                            primary: "",
                            secondary: "",
                            warning: "",
                            light: "",
                            dark: "",
                        },
                        {
                            isTag: true,
                            oldName: "sweepInitiationType",
                            newName: "sweepInitiationType",
                            success: "Active",
                            danger: "Inactive",
                            default: "Both",
                            primary: "Reactive",
                            secondary: "Proactive",
                            warning: "",
                            light: "",
                            dark: "",
                        },
                    ],
                }),
                currentPage,
                pageSize,
                totalSize: sweepConfigurations.data.item1,
                fetching: false,
            });
        } catch (ex) {
            // console.log("caught exception", ex);
            if (ex.response && ex.response.status >= 400 && ex.response.status <= 499) {
                // toast.error("Bad Request");
                // console.log(ex.response);
                // errors["response"] = "Internal server error.";
                // console.log(errors);
                // this.setState({ errors, fetching: false });
            } else {
                // toast.error("Internal server error. Please contact the admin.");
                // this.setState({ errors, fetching: false });
            }
        } finally {
            this.setState({ fetching: false });
        }
    };

    handleOnExport = async (type: string) => {};

    fetchItemById = async (item: any) => {
        this.setState({ fetchingDetail: true });
        const sweepConfig = await sweepConfigService.retrieveSweepConfigById(item.id);
        const institutionCode = sweepConfig.data.code;

        var recovaFeeConfig = (await feeConfigService.retrieveFeeConfigByInstitutionCode(institutionCode)).data;
        var thirdPartyRecoveryFeeConfigItem = (await institutionFeeConfigService.filterInstitutionFeeConfig("Sweep", institutionCode, "")).data;
        var thirdPartyCollectionFeeConfigItem = (await institutionFeeConfigService.filterInstitutionFeeConfig("Collections", institutionCode, ""))
            .data;
        console.log("for inspection: ", sweepConfig.data);
        this.setState({
            item: sweepConfig.data,
            recovaRecoveryFeeConfigItem: recovaFeeConfig[0] ? recovaFeeConfig[0] : [],
            recovaCollectionFeeConfigItem: recovaFeeConfig[1] ? recovaFeeConfig[1] : [],
            thirdPartyRecoveryFeeConfigItem: thirdPartyRecoveryFeeConfigItem,
            thirdPartyCollectionFeeConfigItem: thirdPartyCollectionFeeConfigItem,
            fetchingDetail: false,
        });
    };

    getFilteredItems = (data: IFilterData): IFilterData => {
        let filteredItems: IFilterData = {};
        Object.keys(data).forEach((key) => {
            if (data[key].item2 !== "") {
                filteredItems[key] = data[key];
            }
        });
        return filteredItems;
    };

    async componentDidMount() {
        var allInstitutions = await institutionService.retrieveAllInstitutions();
        var allCommercialBanks = await sweepPriorityService.retrieveDistinctSweepServiceLinkedInstitutions();
        this.setState({ allInstitutions: allInstitutions.data, allCommercialBanks: allCommercialBanks.data });
        this.handlePageChange(this.state.currentPage, this.state.pageSize);
    }

    render() {
        const {
            currentPage,
            pageSize,
            tableItems,
            totalSize,
            fetching,
            item,
            recovaRecoveryFeeConfigItem,
            recovaCollectionFeeConfigItem,
            thirdPartyRecoveryFeeConfigItem,
            thirdPartyCollectionFeeConfigItem,
            fetchingDetail,
            allInstitutions,
            allCommercialBanks,
        } = this.state;

        return (
            <Fragment>
                <div className="container-fluid relative animatedParent animateOnce">
                    <div className="animated fadeInUpShort go">
                        <div className="row my-3 mx-2">
                            <h3>
                                <b>Sweep Configurations</b>
                            </h3>
                        </div>
                        <InstitutionSweepConfiurationsList
                            pageChangeHandler={this.handlePageChange}
                            fetchingDetail={fetchingDetail}
                            initialValues={{
                                data: createFilterData(["status", "sweepInitiationType"]) as IFilterData,
                                fetching: false,
                                showDetailsModal: false,
                                showEditModal: false,
                                showTableFilterModal: false,
                                showTrailModal: false,
                                tableItems: [],
                                filterValueInput: [
                                    {
                                        name: "status",
                                        fieldType: "select",
                                        options: ["Active", "Inactive"],
                                        value: "",
                                    },
                                    {
                                        name: "sweepInitiationType",
                                        fieldType: "select",
                                        options: ["Reactive", "Proactive", "Both"],
                                        value: "",
                                    },
                                ],
                                item: {},
                            }}
                            tableItems={tableItems}
                            item={item}
                            DetailsModal={({ item, onToggleDetailsModal, onToggleEditModal, showDetailsModal, showEditModal }) => (
                                <Fragment>
                                    <SweepConfigurationDetail
                                        showDetailsModal={showDetailsModal}
                                        toggleDetailsModal={onToggleDetailsModal}
                                        toggleEditModal={onToggleEditModal}
                                        item={item}
                                        recovaRecoveryFeeConfigItem={recovaRecoveryFeeConfigItem}
                                        recovaCollectionFeeConfigItem={recovaCollectionFeeConfigItem}
                                        thirdPartyRecoveryFeeConfigItem={thirdPartyRecoveryFeeConfigItem}
                                        thirdPartyCollectionFeeConfigItem={thirdPartyCollectionFeeConfigItem}
                                        allInstitutions={allInstitutions}
                                        allCommercialBanks={allCommercialBanks}
                                    />
                                    <EditSweepConfiguration
                                        showEditModal={showEditModal}
                                        toggleEditModal={(reloadDetails: boolean, item: any) => {
                                            onToggleEditModal();
                                            if (reloadDetails === true) {
                                                this.fetchItemById(item);
                                            }
                                        }}
                                        item={item}
                                        recovaRecoveryFeeConfigItem={recovaRecoveryFeeConfigItem}
                                        recovaCollectionFeeConfigItem={recovaCollectionFeeConfigItem}
                                        thirdPartyRecoveryFeeConfigItem={thirdPartyRecoveryFeeConfigItem}
                                        thirdPartyCollectionFeeConfigItem={thirdPartyCollectionFeeConfigItem}
                                        allInstitutions={allInstitutions}
                                        allCommercialBanks={allCommercialBanks}
                                    />
                                </Fragment>
                            )}
                            ListPayLoad={({
                                data,
                                showTableFilterModal,
                                tableItems,
                                onToggleDetailsModal,
                                onToggleTableFilterModal,
                                onHandleFilterItemRemove,
                                filterValueInput,
                            }) => (
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card no-b">
                                            <div className="card-body">
                                                <div className="card-title">
                                                    <div id="list-filters" className="ml-4 mr-4">
                                                        <TableFilterPane
                                                            filteredItems={this.getFilteredItems(data)}
                                                            handleFilterRemove={onHandleFilterItemRemove}
                                                        />
                                                    </div>
                                                    <div id="list-table">
                                                        <ItemsTable
                                                            data={data}
                                                            items={tableItems}
                                                            filterValueInput={filterValueInput}
                                                            showTableFiltermodal={showTableFilterModal}
                                                            toggleTableFiltermodal={onToggleTableFilterModal}
                                                            name="Sweep Configurations"
                                                            currentPage={currentPage}
                                                            pageSize={pageSize}
                                                            totalSize={totalSize}
                                                            fetching={fetching}
                                                            handlePageChange={this.handlePageChange}
                                                            onViewDetails={(item: any) => {
                                                                this.fetchItemById(item);
                                                                onToggleDetailsModal();
                                                            }}
                                                            handleOnExport={this.handleOnExport}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        ></InstitutionSweepConfiurationsList>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default InstitutionSweepConfiurations;
