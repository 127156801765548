import http from "../httpService";


const collectionSweepBaseAddress = process.env.REACT_APP_COLLECTION_SWEEP_BASEADDRESS;



export function initiateWithdrawal(institutionCode: string, amount: number) {
    return http.get(
        collectionSweepBaseAddress + `settlement/institutions/wallets/${institutionCode}/withdraw/${amount}`
    );
}

export function getWalletByInstitution(institutionCode: string) {
    return http.get(
        collectionSweepBaseAddress + `settlement/institutions/wallets/${institutionCode}`
    );
}

export function getWalletTransactionsByInstitution(pageNumber: number, pageSize: number, data: any) {
    console.log(data)
    var url = ""
    var institutionCode = typeof data.institutionCode === "undefined" ? "" : data.institutionCode.item2
    var settlementReference = typeof data.settlementReference === "undefined" ? "" : data.settlementReference.item2
    var startDate = typeof data.date === "undefined" ? "" : data.date.slice(1, 11)
    var endDate = typeof data.date === "undefined" ? "" : data.date.slice(18, 28)
    if (startDate === "") {
        url = collectionSweepBaseAddress + `settlement/institutions/wallets/transactions?institutionCode=${institutionCode}&settlementReference=${settlementReference}&pageIndex=${pageNumber}&pageSize=${pageSize}`
    }
    else {
        url = collectionSweepBaseAddress + `settlement/institutions/wallets/transactions?institutionCode=${institutionCode}&settlementReference=${settlementReference}&fromDate=${startDate}&toDate=${endDate}&pageIndex=${pageNumber}&pageSize=${pageSize}`
    }
    return http.get(
        url
    );
}

export function fetchWithdrawalsByInstitution(institutionCode: string, pageNumber: number, pageSize: number) {
    // var searchTerm = typeof data.searchTerm === "undefined" ? "" : data.searchTerm.item2
    return http.get(
        collectionSweepBaseAddress + `settlements/withdrawals/institution/${institutionCode}?pageIndex=${pageNumber}&pageSize=${pageSize}&searchFilter&orderBy`
    );
}


export default {
    initiateWithdrawal,
    getWalletByInstitution,
    getWalletTransactionsByInstitution,
    fetchWithdrawalsByInstitution
};