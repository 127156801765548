import React, { Fragment } from "react";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import { ActionTypes } from "../../../enums/actionTypes";
import BaseFormComponent, { IBaseFormState } from "../../../components/BaseFormComponent";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { dataEntryFields as dataEntry } from "./dataEntryFields";
import { splitAtUpper } from "../../../services/utility/textFormatService";

interface EditMandatoryFieldConfigFields { }

interface IDataEntrySections {
  sectionName: string;
  inputFields: IInputField[];
}

interface IInputField {
  fieldName: string;
  mandatory: boolean;
}

interface IDataEntryFields {
  label: string;
  value: string;
  fields: any;
}

class EditMandatoryFieldConfigForm extends BaseFormComponent<EditMandatoryFieldConfigFields> { }

interface IEditMandatoryFieldConfigProps {
  item: any;
  dataEntrySections: any;
  showPatchModal: boolean;
  togglePatchModal: any;
}

interface IEditMandatoryFieldConfigState {
  ownUpdate: boolean;
  dataEntrySections: IDataEntrySections[];
  dataEntryFields: IDataEntryFields[];
  mandatoryFields: any;
  currrentTab: string;
}

class EditMandatoryFieldConfig extends React.Component<
  IEditMandatoryFieldConfigProps,
  IBaseFormState & IEditMandatoryFieldConfigState
> {
  _isMounted = false;
  constructor(props: IEditMandatoryFieldConfigProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      ownUpdate: false,
      dataEntrySections: [],
      dataEntryFields: [],
      mandatoryFields: [],
      currrentTab: ""
    };
  }

  async componentWillMount() {
    this._isMounted = true;
    const { dataEntryFields, mandatoryFields } = this.state;
    dataEntry.forEach((tab) => {
      dataEntryFields.push({ label: tab.name, value: tab.value, fields: tab.fields })
    })
    this.props.dataEntrySections.forEach((tab) => {
      tab.inputFields?.map((field) => {
        if (field.mandatory === true) {
          mandatoryFields.push(field.fieldName)
        }
      })
    })
    this.setState({ dataEntryFields, mandatoryFields, ownUpdate: true });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onFormSubmit(fields: EditMandatoryFieldConfigFields, onReloadFieldsCB: any): boolean {
    try {
      this.handleOnDataEntrySection();
      this.setState({ ownUpdate: true },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  submit = async (
    fields: EditMandatoryFieldConfigFields,
    onReloadFieldsCB: any
  ) => {
    this.setState({ submitting: true, ownUpdate: true });
    const payload: IDataEntrySections[] = this.state.dataEntrySections;
    onReloadFieldsCB();
    this.setState({ submitting: false, ownUpdate: true }, () =>
      this.props.togglePatchModal(true, payload)
    )
  }

  handleOnCheckMandatoryField = (tab, value) => {
    const mandatoryFields = [...this.state.mandatoryFields];
    if (mandatoryFields.indexOf(value) !== -1) {
      mandatoryFields.splice(mandatoryFields.indexOf(value), 1);
    } else {
      mandatoryFields.push(value);
    }
    this.setState({ mandatoryFields, currrentTab: tab, ownUpdate: true });
  }

  getInputField = (item): IInputField[] => {
    const dataEntryFields = [...this.state.dataEntryFields];
    const mandatoryFields = [...this.state.mandatoryFields];
    var data = dataEntryFields?.filter(x => x.value === item)[0]?.fields.map(field => {
      return ({
        fieldName: field.value,
        mandatory: (
          _.isEmpty(
            mandatoryFields?.filter(
              (x) => x === field.value
            )
          )
            ? false
            : true
        )
      })
    })
    return data;
  }

  handleOnDataEntrySection = () => {
    const dataEntrySections = [...this.state.dataEntrySections];
    this.props.item.map((item) => {
      dataEntrySections.push({
        sectionName: item,
        inputFields: this.getInputField(item)
      })
    })
    this.setState({ dataEntrySections });
  }

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      ownUpdate: true,
      dataEntrySections: []
    });
  }

  render() {
    const { item, showPatchModal, togglePatchModal } = this.props;
    const { submitting, dataEntryFields, mandatoryFields, currrentTab } = this.state;

    if (_.isEmpty(item)) {
      return null
    }

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showPatchModal}
        onHide={togglePatchModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>
              <i className="fas fa-edit fa-sm cyan-text mr-3" />
              Configure Mandatory Fields
            </h5>
          </Modal.Title>
        </Modal.Header>

        <EditMandatoryFieldConfigForm
          initialValues={{}}
          FormComponent={({
            fields: { },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12">
                    {item.length > 0 ? (
                      <React.Fragment>
                        <Tabs
                          defaultActiveKey={_.isEmpty(currrentTab) ? item[0].value : currrentTab}
                          variant='tabs'
                          fill
                        >
                          {item.map(
                            (tab) => (
                              <Tab eventKey={tab} title={(splitAtUpper(tab))} key={tab}>
                                <Fragment>
                                  <span className="col-sm-1 ml-1"></span>
                                  {dataEntryFields?.filter(x => x.value === tab)[0]?.fields.map((field) => (
                                    <div key={field.value} style={{ marginTop: '10px', marginLeft: '30px' }}>
                                      <div
                                        key={field.value}
                                        className="custom-control custom-checkbox"
                                      >
                                        <input
                                          id={field.value}
                                          name={field.value}
                                          value={field.value}
                                          className="custom-control-input"
                                          type="checkbox"
                                          checked={
                                            _.isEmpty(
                                              mandatoryFields?.filter(
                                                (x) => x === field.value
                                              )
                                            )
                                              ? false
                                              : true
                                          }
                                          onChange={() => {
                                            this.handleOnCheckMandatoryField(tab, field.value)
                                          }}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={field.value}
                                        >
                                          {field.label}
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                                </Fragment>
                              </Tab>
                            )
                          )}
                        </Tabs>
                      </React.Fragment>
                    ) : ("")}
                  </div>
                </div >
              </Modal.Body >
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(e, ActionTypes.SAVE, "Mandatory Fields Configuration", () => {
                      this.onFormSubmit({},
                        onReloadFields
                      );
                    });
                  }} disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => togglePatchModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment >
          )
          }
        >
        </EditMandatoryFieldConfigForm >
      </Modal >
    );
  }
}

export default EditMandatoryFieldConfig;
