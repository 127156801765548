import React, { Fragment } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import DetailItem from "../../../components/detailItem";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import billingValidationService from "../../../services/billing/billingValidationService";
import HybridAppraisalBillingService from "../../../services/billing/HybridAppraisalBillingService";
import recovaFeeConfigService from "../../../services/billing/recovaFeeConfigService";
import thirdPartyFeeConfigService from "../../../services/billing/thirdPartyFeeConfigService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

interface EditHybridAppraisalFeeConfigurationFields {
  institutionCategory: string;
  feeName: string;
  subscriptionFrequency: string;
  requestCount: string;
  price: string;
  payAsYouGoPrice: string;
}

class EditHybridAppraisalFeeConfigurationForm extends BaseFormComponent<EditHybridAppraisalFeeConfigurationFields> { }

interface IEditHybridAppraisalFeeConfigurationProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}
interface IEditHybridAppraisalFeeConfigurationState {
  ownUpdate: boolean;
}

class EditHybridAppraisalFeeConfiguration extends React.Component<
  IEditHybridAppraisalFeeConfigurationProps,
  IBaseFormState & IEditHybridAppraisalFeeConfigurationState
> {
  constructor(props: IEditHybridAppraisalFeeConfigurationProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      ownUpdate: false,
    };
  }

  static getDerivedStateFromProps(prevState) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    return null;
  }

  onRecovaFormSubmit(
    fields: EditHybridAppraisalFeeConfigurationFields,
    onReloadFieldsCB: any
  ): boolean {
    try {
      this.setState(
        {
          ownUpdate: true,
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.Submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return true;
    } catch (error) {
      return false;
    }
  }



  Submit = async (
    fields: EditHybridAppraisalFeeConfigurationFields,
    onReloadFieldsCB: any
  ) => {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const payload = this.props.item;
      payload.id = payload.hybridAppraisalConfigurationId;
      payload.institutionCategory = fields.institutionCategory;
      payload.volumeTier = fields.feeName;
      payload.subscriptionFrequency = "Monthly";
      payload.requestCount = Number(fields.requestCount);
      payload.price = Number(fields.price);
      payload.payAsYouGoPrice = Number(fields.payAsYouGoPrice);

      const response = await HybridAppraisalBillingService.editSubscriptionFee(payload.id, payload);
      if (response.status >= 200 && response.status <= 300) {
        toast.info(
          `Hybrid Appraisal Subscription Fee for ${payload.institutionCategory} Edited successfully!`,
          {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          }
        );
      }
      onReloadFieldsCB();
      this.setState({ submitting: false, ownUpdate: true }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
    } finally {
      this.setState({ submitting: false, ownUpdate: true });
    }
  };


  render() {
    const { item, showEditModal, toggleEditModal } = this.props;
    const {
      validated,
      submitting,
      errors,
    } = this.state;

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        {_.isEmpty(item) ? (
          <Skeleton width={500} />
        ) : (
          <Fragment>
            <Modal.Header>
              <Modal.Title>
                <h5>
                  <i className="fas fa-tools fa-sm cyan-text mr-3" />
                  Edit Central Fee Configuration -{" "}
                  <span className="font-weight-bold">{item.institutionCategory} ({item.serviceOffering})</span>
                </h5>
              </Modal.Title>
            </Modal.Header>
            <EditHybridAppraisalFeeConfigurationForm
              initialValues={{
                institutionCategory: item.institutionCategory,
                feeName: item?.volumeTier,
                subscriptionFrequency: item?.subscriptionFrequency,
                price: item?.price,
                payAsYouGoPrice: item?.payAsYouGoPrice,
                requestCount: item?.requestCount
              }}
              FormComponent={({
                fields: {
                  institutionCategory,
                  feeName,
                  subscriptionFrequency,
                  price,
                  payAsYouGoPrice,
                  requestCount
                },
                onChange,
                onReloadFields,
                onHandleSubmit,
              }) => (
                <React.Fragment>
                  <Fragment>
                    <Modal.Body>
                      <div className="card">
                        <div className="card-header clear-fix">
                          <h6
                            className="card-title float-left"
                            style={{ marginBottom: "0px" }}
                          >
                            Fee
                          </h6>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              <dl className="row">
                                <DetailItem
                                  label="Fee Name"
                                  labelSize={8}
                                  valueSize={4}
                                  value={
                                    <DetailItemInputComponent
                                      id="feeName"
                                      type="text"
                                      name="feeName"
                                      placeholder=""
                                      value={feeName}
                                      required={true}
                                      validated={validated}
                                      errors={errors}
                                      onChange={onChange}
                                    />
                                  }
                                />
                                {/* <DetailItem
                                    label="Subscription Frequency"
                                    labelSize={8}
                                    valueSize={4}
                                    value={
                                      <DetailItemInputComponent
                                        id="subscriptionFrequency"
                                        type="text"
                                        name="subscriptionFrequency"
                                        placeholder=""
                                        value={subscriptionFrequency}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    }
                                  /> */}
                                <DetailItem
                                  label="Price"
                                  labelSize={8}
                                  valueSize={4}
                                  value={
                                    <DetailItemInputComponent
                                      id="price"
                                      type="number"
                                      name="price"
                                      placeholder=""
                                      value={price}
                                      required={true}
                                      validated={validated}
                                      errors={errors}
                                      onChange={onChange}
                                    />
                                  }
                                />
                              </dl>
                            </div>
                            <div className="col-md-6">
                              <dl className="row">
                                <DetailItem
                                  label="Pay As You Go Price"
                                  labelSize={8}
                                  valueSize={4}
                                  value={
                                    <DetailItemInputComponent
                                      id="payAsYouGoPrice"
                                      type="number"
                                      name="payAsYouGoPrice"
                                      placeholder=""
                                      value={payAsYouGoPrice}
                                      required={true}
                                      validated={validated}
                                      errors={errors}
                                      onChange={onChange}
                                    />
                                  }
                                />
                                <DetailItem
                                  label="Request Count"
                                  labelSize={8}
                                  valueSize={4}
                                  value={
                                    <DetailItemInputComponent
                                      id="requestCount"
                                      type="text"
                                      name="requestCount"
                                      placeholder=""
                                      value={requestCount}
                                      required={true}
                                      validated={validated}
                                      errors={errors}
                                      onChange={onChange}
                                    />
                                  }
                                />
                              </dl>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        size="sm"
                        variant="secondary"
                        onClick={(e) => {
                          onHandleSubmit(
                            e,
                            ActionTypes.UPDATE,
                            "Recova Central Fee Configuration",
                            () => {
                              this.onRecovaFormSubmit(
                                {
                                  institutionCategory,
                                  feeName,
                                  subscriptionFrequency,
                                  price,
                                  payAsYouGoPrice,
                                  requestCount
                                },
                                onReloadFields
                              );
                            }
                          );
                        }}
                        disabled={submitting}
                      >
                        {submitting === false ? (
                          <React.Fragment>
                            <i className="fas fa-lg fa-save mr-3" /> Save
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <i className="fas fa-spin fa-circle-notch" />
                          </React.Fragment>
                        )}
                      </Button>
                      <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={() => toggleEditModal(false, this.props.item)}
                        disabled={submitting}
                      >
                        <i className="fas fa-times mr-3" />
                        Close
                      </Button>
                    </Modal.Footer>
                  </Fragment>
                </React.Fragment>
              )}
            ></EditHybridAppraisalFeeConfigurationForm>
          </Fragment>
        )}
      </Modal>
    );
  }
}

export default EditHybridAppraisalFeeConfiguration;
