import React, { Component } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";

export interface IPaginatedBarChartProps {
  chartItem: any;
  currentPage: number;
  totalSize: number;
  currentItemSize: number;
  fetching: boolean;
  handlePageChange: any;
}

export interface IPaginatedBarChartState {}

class PaginatedBarChart extends Component<
  IPaginatedBarChartProps,
  IPaginatedBarChartState
> {
  state = {};

  componentDidMount() {}

  render() {
    const {
      chartItem,
      currentPage,
      totalSize,
      fetching,
      handlePageChange,
      currentItemSize,
    } = this.props;

    const pageSize = 20;

    return (
      <React.Fragment>
        <nav aria-label="Page navigation example clearfix">
          <ul
            className="pagination float-left"
            style={{ marginBottom: "10px", height: "32px" }}
          >
            <li
              className={currentPage === 1 ? "page-item disabled" : "page-item"}
            >
              <button
                type="button"
                onClick={() => handlePageChange(1)}
                className="page-link"
              >
                <i className="fas fa-step-backward" />
              </button>
            </li>
            <li
              className={currentPage === 1 ? "page-item disabled" : "page-item"}
            >
              <button
                type="button"
                onClick={() => handlePageChange(currentPage - 1)}
                className="page-link"
              >
                <i className="fas fa-chevron-left" />
              </button>
            </li>
            <li className="page-item">
              <p className="page-link" style={{ height: "30px" }}>
                Page
              </p>
            </li>
            <li className="page-item">
              <input
                style={{ width: "35px" }}
                className="form-control form-control-sm"
                id="currentPage"
                type="number"
                value={currentPage}
                onChange={() => handlePageChange}
              />
            </li>
            <li className="page-item">
              <p className="page-link" style={{ height: "30px" }}>
                of
              </p>
            </li>
            <li className="page-item">
              <p className="page-link" style={{ height: "30px" }}>
                {currentItemSize > 0 ? Math.ceil(totalSize / pageSize) : ""}
              </p>
            </li>
            <li
              className={
                Math.ceil(totalSize / pageSize) === currentPage
                  ? "page-item disabled"
                  : "page-item"
              }
            >
              <button
                type="button"
                onClick={() => handlePageChange(currentPage + 1)}
                className="page-link"
              >
                <i className="fas fa-chevron-right" />
              </button>
            </li>
            <li
              className={
                Math.ceil(totalSize / pageSize) === currentPage
                  ? "page-item disabled"
                  : "page-item"
              }
            >
              <button
                type="button"
                onClick={() =>
                  handlePageChange(Math.ceil(totalSize / pageSize))
                }
                className="page-link"
              >
                <i className="fas fa-step-forward" />
              </button>
            </li>
            <li className="page-item disabled">
              <a href="#!" className="page-link">
                <i className="fas fa-grip-lines-vertical" />
              </a>
            </li>
            <li
              className={fetching === true ? "page-item disabled" : "page-item"}
            >
              <button
                type="button"
                className="page-link"
                onClick={() => handlePageChange(currentPage)}
              >
                <i
                  className={
                    fetching === true ? "fas fa-spinner fa-spin" : "fas fa-sync"
                  }
                />
              </button>
            </li>
            <li className="page-item disabled">
              <a href="#!" className="page-link">
                <i className="fas fa-grip-lines-vertical" />
              </a>
            </li>
          </ul>
          <div className="float-right" style={{ height: "30px" }}>
            <span className="mr-4">
              {currentItemSize > 0
                ? `Showing ${(currentPage - 1) * pageSize + 1} - ${
                    (currentPage - 1) * pageSize + currentItemSize
                  } of ${totalSize}`
                : ""}
            </span>
          </div>
        </nav>

        {fetching === true ? <Skeleton count={1} height={500} /> : chartItem}
      </React.Fragment>
    );
  }
}

export default PaginatedBarChart;
