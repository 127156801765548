import React from "react";
import "./consent.css";
import recova_logo from "../../images/recova-logo.svg";
import recova_image from "../../images/recova-image.jpg";
import BaseFormComponent, {
  IBaseFormState,
} from "../../components/BaseFormComponent";
import { Link, RouteComponentProps } from "react-router-dom";
import SideBanner2 from "../../components/sideBanner2";
import authValidationService from "../../services/auth/authValidationService";
import consentRequestService from "../../services/mandate/consentRequestService";
import { toast } from "react-toastify";

interface ConsentFields {
  phoneNumber: string;
  token: string;
}

export class ConsentForm extends BaseFormComponent<ConsentFields> {}

export interface IConsentProps extends RouteComponentProps {}

export interface IConsentState {
  sent: boolean;
  message: string;
}

class Consent extends React.Component<
  IConsentProps,
  IBaseFormState & IConsentState
> {
  constructor(props: IConsentProps) {
    super(props);
    this.state = {
      sent: false,
      message: "",
      validated: false,
      submitting: false,
      errors: {},
    };
  }

  onFormSubmit(fields: ConsentFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        { errors: authValidationService.validateGrantConsentForm(fields) },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (fields: ConsentFields, onReloadFieldsCB: any) => {
    try {
      // console.log("starting submission...");
      this.setState({ submitting: true });
      const response = await consentRequestService.approveConsentWeb(fields);
      // console.log("response: ", response.data);
      this.setState({ submitting: false, sent: true });
      toast.info(`Consent Received!, Your request is being worked on...! `, {
        autoClose: 6000,
        type: toast.TYPE.DEFAULT,
        hideProgressBar: false,
      });
      onReloadFieldsCB();
    } catch (error) {
      this.setState({ submitting: false });
      let errors: any = {};
      if (
        error.response &&
        (error.response.status === 404 || error.response.status === 500)
      ) {
        errors.response = error & error.response.data.Message;
      } else {
        this.setState({ submitting: false });
        errors.response = error.response.data;
      }
      // console.log(errors);
      this.setState({ errors });
    }
  };

  render() {
    const { sent, message, errors, submitting } = this.state;
    return (
      <React.Fragment>
        <ConsentForm
          initialValues={{ phoneNumber: "", token: "" }}
          FormComponent={({
            fields: { phoneNumber, token },
            onChange,
            onReloadFields,
          }) => (
            <div className="container-fluid">
              <div
                id="primary"
                className="height-full responsive-phone row"
                style={{
                  backgroundImage: `url(${recova_image})`,
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="col-md-5">
                  <SideBanner2 />
                </div>
                <div className="col-md-7" style={{ backgroundColor: "white" }}>
                  <div className="row">
                    <div className="col text-right brand pt-3 pr-5">
                      <img
                        src={recova_logo}
                        width="145"
                        alt=""
                        className="image"
                      />
                    </div>
                  </div>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-3"></div>
                      <div className="col-lg-9 p-t-100">
                        <div className="text-black">
                          <h1>Grant Consent</h1>
                          <p className="s-18 font-weight-lighter">
                            {sent === true
                              ? "Consent received successfully. Your request is already being worked on."
                              : "Enter your phone number and consent token in the fields  below."}
                          </p>
                        </div>
                        <form action="#">
                          <div className="row">
                            {sent === true ? (
                              <React.Fragment>
                                <h5
                                  className="text-muted text-center"
                                  style={{ fontWeight: 300 }}
                                >
                                  {message}
                                </h5>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <div className="col-lg-8">
                                  <div className="form-group has-icon">
                                    <i className="icon-account_balance"></i>
                                    <input
                                      autoFocus
                                      type="number"
                                      className="form-control form-control-lg"
                                      placeholder="Phone Number"
                                      id="phoneNumber"
                                      name="phoneNumber"
                                      value={phoneNumber}
                                      onChange={(e) =>
                                        onChange(
                                          "phoneNumber",
                                          e.currentTarget.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-8">
                                  <div className="form-group has-icon">
                                    <i className="icon-envelope-o"></i>
                                    <input
                                      type="password"
                                      className="form-control form-control-lg"
                                      placeholder="Token"
                                      id="token"
                                      name="token"
                                      value={token}
                                      onChange={(e) =>
                                        onChange("token", e.currentTarget.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </React.Fragment>
                            )}
                            {Object.keys(errors).length > 0 && (
                              <div className="col-lg-8">
                                <div
                                  className="alert alert-danger alert-dismissible"
                                  role="alert"
                                >
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">×</span>
                                  </button>
                                  <strong>
                                    {this.state.errors[Object.keys(errors)[0]]}
                                  </strong>
                                </div>
                              </div>
                            )}
                            <div className="col-lg-8">
                              {!sent && (
                                <button
                                  type="submit"
                                  data-wait="Please wait..."
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.onFormSubmit(
                                      {
                                        phoneNumber,
                                        token,
                                      },
                                      onReloadFields
                                    );
                                  }}
                                  className={
                                    this.state.submitting === false
                                      ? "btn btn-primary btn-block btn-lg"
                                      : "btn btn-primary btn-block btn-lg disabled"
                                  }
                                >
                                  {submitting === false ? (
                                    <React.Fragment>
                                      <i className="fas fa-thumbs-up mr-3"></i>
                                      GRANT CONSENT
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <i className="fas fa-spin fa-circle-notch" />
                                    </React.Fragment>
                                  )}
                                </button>
                              )}
                              <p className="forget-pass text-orange">
                                <Link to="/login">
                                  <i className="icon-sign-in mr-2" />
                                  Back To Login
                                </Link>
                              </p>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        ></ConsentForm>
      </React.Fragment>
    );
  }
}

export default Consent;
