import Joi from "joi-browser";
import _ from "lodash";

export function validateAddAppraisalPlanForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      institutionCategory: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Institution Category is required" };
        }),
      name: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Appraisal Plan Name is required" };
        }),
      reportIds: Joi.array()
        .min(1)
        .error((errors) => {
          return { message: "Report Item is required" };
        }),
      validationIds: Joi.array()
        .min(1)
        .error((errors) => {
          return { message: "Validation Item is required" };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditAppraisalPlanForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      name: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Appraisal Plan Name is required" };
        }),
      reportIds: Joi.array()
        .min(1)
        .error((errors) => {
          return { message: "Report Item is required" };
        }),
      validationIds: Joi.array()
        .min(1)
        .error((errors) => {
          return { message: "Validation Item is required" };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateActivateAppraisalInstitutionForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      planId: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Appraisal Plan is required" };
        }),
      billingPlan: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Billing Plan is required" };
        }),
      flatPerRequest: Joi.alternatives().when("billingType", {
        is: "Subscription",
        then: Joi.any(),
        otherwise: Joi.number()
          .required()
          .min(0)
          .error((errors) => {
            errors.forEach(err => {
              switch (err.type) {
                case "number.base":
                  err.message = "Flat Per Request is required";
                  break;
                case "number.min":
                  err.message = `Flat Per Request should not be negative`;
                  break;
                default:
                  break;
              }
            });
            return errors;
          }),
      }),
      flatPerApprovedLoan: Joi.alternatives().when("billingType", {
        is: "Subscription",
        then: Joi.any(),
        otherwise: Joi.number()
          .required()
          .min(0)
          .error((errors) => {
            errors.forEach(err => {
              switch (err.type) {
                case "number.base":
                  err.message = "Flat Per Approved Loan is required";
                  break;
                case "number.min":
                  err.message = `Flat Per Approved Loan should not be negative`;
                  break;
                default:
                  break;
              }
            });
            return errors;
          }),
      }),
      volumeTier: Joi.alternatives().when("billingType", {
        is: "Subscription",
        then: Joi.string()
          .required()
          .error((errors) => {
            return { message: "Volume Tier is required" };
          }),
        otherwise: Joi.any(),
      }),
      subscriptionFrequency: Joi.alternatives().when("billingType", {
        is: "Subscription",
        then: Joi.string()
          .required()
          .error((errors) => {
            return { message: "Subscription Frequency is required" };
          }),
        otherwise: Joi.any(),
      }),
      price: Joi.alternatives().when("billingType", {
        is: "Subscription",
        then: Joi.number()
          .required()
          .min(0)
          .error((errors) => {
            errors.forEach(err => {
              switch (err.type) {
                case "number.base":
                  err.message = "Price is required";
                  break;
                case "number.min":
                  err.message = `Price should not be negative`;
                  break;
                default:
                  break;
              }
            });
            return errors;
          }),
        otherwise: Joi.any(),
      }),
      payAsYouGoPrice: Joi.alternatives().when("billingType", {
        is: "Subscription",
        then: Joi.number()
          .required()
          .min(0)
          .error((errors) => {
            errors.forEach(err => {
              switch (err.type) {
                case "number.base":
                  err.message = "Pay As you Go Price is required";
                  break;
                case "number.min":
                  err.message = `Pay As you Go Price should not be negative`;
                  break;
                default:
                  break;
              }
            });
            return errors;
          }),
        otherwise: Joi.any(),
      }),
      requestCount: Joi.alternatives().when("billingType", {
        is: "Subscription",
        then: Joi.number()
          .required()
          .min(0)
          .error((errors) => {
            errors.forEach(err => {
              switch (err.type) {
                case "number.base":
                  err.message = "Request Count is required";
                  break;
                case "number.min":
                  err.message = `Request Count should not be negative`;
                  break;
                default:
                  break;
              }
            });
            return errors;
          }),
        otherwise: Joi.any(),
      }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditAppraisalInstitutionForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      name: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Instituition Name is required" };
        }),
      planId: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Appraisal Plan is required" };
        }),
      billingPlan: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Billing Plan is required" };
        }),
      flatPerRequest: Joi.alternatives().when("billingType", {
        is: "Subscription",
        then: Joi.any(),
        otherwise: Joi.number()
          .required()
          .min(0)
          .error((errors) => {
            errors.forEach(err => {
              switch (err.type) {
                case "number.base":
                  err.message = "Flat Per Request is required";
                  break;
                case "number.min":
                  err.message = `Flat Per Request should not be negative`;
                  break;
                default:
                  break;
              }
            });
            return errors;
          }),
      }),
      flatPerApprovedLoan: Joi.alternatives().when("billingType", {
        is: "Subscription",
        then: Joi.any(),
        otherwise: Joi.number()
          .required()
          .min(0)
          .error((errors) => {
            errors.forEach(err => {
              switch (err.type) {
                case "number.base":
                  err.message = "Flat Per Approved Loan is required";
                  break;
                case "number.min":
                  err.message = `Flat Per Approved Loan should not be negative`;
                  break;
                default:
                  break;
              }
            });
            return errors;
          }),
      }),
      volumeTier: Joi.alternatives().when("billingType", {
        is: "Subscription",
        then: Joi.string()
          .required()
          .error((errors) => {
            return { message: "Volume Tier is required" };
          }),
        otherwise: Joi.any(),
      }),
      subscriptionFrequency: Joi.alternatives().when("billingType", {
        is: "Subscription",
        then: Joi.string()
          .required()
          .error((errors) => {
            return { message: "Subscription Frequency is required" };
          }),
        otherwise: Joi.any(),
      }),
      price: Joi.alternatives().when("billingType", {
        is: "Subscription",
        then: Joi.number()
          .required()
          .min(0)
          .error((errors) => {
            errors.forEach(err => {
              switch (err.type) {
                case "number.base":
                  err.message = "Price is required";
                  break;
                case "number.min":
                  err.message = `Price should not be negative`;
                  break;
                default:
                  break;
              }
            });
            return errors;
          }),
        otherwise: Joi.any(),
      }),
      payAsYouGoPrice: Joi.alternatives().when("billingType", {
        is: "Subscription",
        then: Joi.number()
          .required()
          .min(0)
          .error((errors) => {
            errors.forEach(err => {
              switch (err.type) {
                case "number.base":
                  err.message = "Pay As you Go Price is required";
                  break;
                case "number.min":
                  err.message = `Pay As you Go Price should not be negative`;
                  break;
                default:
                  break;
              }
            });
            return errors;
          }),
        otherwise: Joi.any(),
      }),
      requestCount: Joi.alternatives().when("billingType", {
        is: "Subscription",
        then: Joi.number()
          .required()
          .min(0)
          .error((errors) => {
            errors.forEach(err => {
              switch (err.type) {
                case "number.base":
                  err.message = "Request Count is required";
                  break;
                case "number.min":
                  err.message = `Request Count should not be negative`;
                  break;
                default:
                  break;
              }
            });
            return errors;
          }),
        otherwise: Joi.any(),
      }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddProductConfigurationForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      productName: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Loan Product Name is required" };
        }),
      productCode: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Product Code is required" };
        }),
      productCategory: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Product Category is required" };
        }),
      minimumAmount: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Minimum Amount is required" };
        }),
      maximumAmount: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Maximum Amount is required" };
        }),
      tenure: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Tenure is required" };
        }),
      // moratorium: Joi.string()
      //   .required()
      //   .error((errors) => {
      //     return { message: "Moratorium is required" };
      //   }),
      // repaymentSchedule: Joi.string()
      //   .required()
      //   .error((errors) => {
      //     return { message: "Repayment Schedule is required" };
      //   }),
      scoreCardId: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Score Card is required" };
        }),
      // productOwners: Joi.array()
      //   .items(Joi.object({
      //     label: Joi.string(),
      //     value: Joi.number(),
      //   }))
      //   .min(1)
      //   .error((errors) => {
      //     return { message: "Product Owner is required" };
      //   }),
      // numberOfGuarantors: Joi.alternatives().when("hasGuarantor",
      //   {
      //     is: false,
      //     then: Joi.any(),
      //     otherwise: Joi.string()
      //       .required()
      //       .error((errors) => {
      //         return { message: "Number of Gurantors is required" };
      //       }),
      //   }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditProductConfigurationForm(data) {
  return validateAddProductConfigurationForm(data);
}

export function validateAddFinancialGroupForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      name: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Financial Group Name is required" };
        }),
      minimumAmount: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Minimum Amount is required";
                break;
              case "number.min":
                err.message = `Minimum Amount should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      maximumAmount: Joi.number()
        .required()
        .min(0)
        .greater(Number(data.minimumAmount))
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.greater":
                err.message = "Maximum Amount must be greater than Minimum Amount";
                break;
              case "number.base":
                err.message = "Maximum Amount is required";
                break;
              case "number.min":
                err.message = `Maximum Amount should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditFinancialGroupForm(data) {
  return validateAddFinancialGroupForm(data)
}

export function validateAddLoanProductMapForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      loanProduct: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Loan Product is required" };
        }),
      workFlowConfig: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Financial Group is required" };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditLoanProductMapForm(data) {
  return validateAddLoanProductMapForm(data)
}

export function validateFinancialGroupConfigForm(data, levelNeeded: boolean) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      approverType: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Approver Type is required.",
          };
        }),
      roleID: Joi.alternatives().when("approverType", {
        is: "User",
        then: Joi.string()
          .required()
          .error((errors) => {
            return {
              message: "User Role is required.",
            };
          }),
        otherwise: Joi.any(),
      }),
      userCount: Joi.alternatives().when("approverType", {
        is: "User",
        then: Joi.number()
          .positive()
          .min(1)
          .error((errors) => {
            return {
              message: "User Count must be greater than zero",
            };
          }),
        otherwise: Joi.any(),
      }),
      levelName: !levelNeeded ? Joi.any() : Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Level Name is required.",
          };
        }),
      systemProcessID: Joi.alternatives().when("approverType", {
        is: "SystemProcess",
        then: Joi.string()
          .required()
          .error((errors) => {
            return {
              message: "System Process is required.",
            };
          }),
        otherwise: Joi.any(),
      }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  // console.log("Errors: ", errors);
  return errors;
}

export function validateModifyCreditPolicyForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      creditPolicyUseCase: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Credit Policy Use Case is required" };
        }),
      maxSingleInputValue: Joi.alternatives().when("CreditPolicyValueType", {
        is: "MaxSingleInput",
        then: Joi.number()
          .required()
          .min(0)
          .error((errors) => {
            errors.forEach(err => {
              switch (err.type) {
                case "number.base":
                  err.message = "Max Single Input Value is required";
                  break;
                case "number.min":
                  err.message = `Max Single Input Value should not be negative`;
                  break;
                default:
                  break;
              }
            });
            return errors;
          }),
        otherwise: Joi.any(),
      }),
      minSingleInputValue: Joi.alternatives().when("CreditPolicyValueType", {
        is: "MinSingleInput",
        then: Joi.number()
          .required()
          .min(0)
          .error((errors) => {
            errors.forEach(err => {
              switch (err.type) {
                case "number.base":
                  err.message = "Min Single Input Value is required";
                  break;
                case "number.min":
                  err.message = `Min Single Input Value should not be negative`;
                  break;
                default:
                  break;
              }
            });
            return errors;
          }),
        otherwise: Joi.any(),
      }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAppraisalProcessDetailForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      comment: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Comment is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export default {
  validateAddAppraisalPlanForm,
  validateEditAppraisalPlanForm,
  validateActivateAppraisalInstitutionForm,
  validateEditAppraisalInstitutionForm,
  validateAddProductConfigurationForm,
  validateEditProductConfigurationForm,
  validateAddFinancialGroupForm,
  validateEditFinancialGroupForm,
  validateAddLoanProductMapForm,
  validateEditLoanProductMapForm,
  validateFinancialGroupConfigForm,
  validateModifyCreditPolicyForm,
  validateAppraisalProcessDetailForm
};
