import React, { Component, Fragment } from "react";
import _ from "lodash";
import activityTrailService from "../../../../services/auditTrail/activityTrailService";
import { toast } from "react-toastify";
import { decodeToken } from "react-jwt";
import BaseFormComponent, { IBaseFormState } from "../../../../components/BaseFormComponent";
import DetailItem from "../../../../components/detailItem";
import FormInputComponent from "../../../../components/formInputComponent";
import SelectInputComponent, { ISelectItems } from "../../../../components/formSelectComponent";
import { getCurrentUserSession } from "../../../../services/auth/authService";
import textFormatService from "../../../../services/utility/textFormatService";
import DocumentPreviewer from "../../../dispute/documentPreviewer";
import supportingDocumentService, { getSupportingDocumentsByLoanReference } from "../../../../services/mandate/supportingDocumentService";
import { ActionTypes } from "../../../../enums/actionTypes";
import loanRequestService from "../../../../services/hybridAppraisal/loanRequestService";
import Skeleton from "react-loading-skeleton";
import appraisalActionService from "../../../../services/hybridAppraisal/appraisalActionService";
import FormTextAreaComponent from "../../../../components/formTextAreaComponent";
import hybridAppraisalValidationService from "../../../../services/hybridAppraisal/hybridAppraisalValidationService";
import groupLoanRequestService from "../../../../services/hybridAppraisal/groupLoanRequestService";

interface DocumentCollectionFields {
    documentCategory: string;
    documentName: string;
    documentStatus: string;
    documentExpiryDate: string;
    comment: string;
}

class DocumentCollectionForm extends BaseFormComponent<DocumentCollectionFields> { }

interface DocumentCollectionProps {
    values: any;
}

interface DocumentCollectionState {
    item: any;
    submitting: boolean;
    validated: boolean;
    showPreview: boolean;
    uploadingDocument: boolean;
    document: any;
    docToUpload: any;
    data: {
        comment: string;
        docToUploadType: string;
    };
    errors: any;
    supportingDocuments: any;
    allDocuments: any;
    ownUpdate: boolean;
}

class GroupDocumentCollection extends Component<
    DocumentCollectionProps,
    IBaseFormState & DocumentCollectionState
> {
    constructor(props: DocumentCollectionProps) {
        super(props);
        this.state = {
            item: {},
            submitting: false,
            validated: false,
            showPreview: false,
            uploadingDocument: false,
            document: {},
            docToUpload: {},
            data: {
                comment: "",
                docToUploadType: "",
            },
            errors: {},
            supportingDocuments: [],
            allDocuments: [],
            ownUpdate: false
        };
    }

    static getDerivedStateFromProps(
        nextProps: DocumentCollectionProps,
        prevState: DocumentCollectionState
    ) {
        if (prevState.ownUpdate) {
            return {
                ownUpdate: false,
            };
        }
        if (_.isNull(nextProps.values)) {
            return null;
        }
        return {
            item: nextProps.values
        };
    }

    async fetchSupportingDocument(first) {
        const { values } = this.props;
        // console.log(JSON.parse(JSON.parse(this.props.values.jsonData).LoanDetails).AccountNumber);
        // const supportingDocuments = await getSupportingDocumentsByLoanReference(
        //     JSON.parse(JSON.parse(this.props.values.jsonData).LoanDetails).AccountNumber,
        //     this.props.values.id
        // );
        // console.log(supportingDocuments)
        let reference = `hyb_post_${values.requestIdentifier}`
        const supportingDocuments = await getSupportingDocumentsByLoanReference(
            reference,
            values.id
        );
        // console.log("suupo :; ",supportingDocuments.data)
        // console.log("values :; ",this.props.values)
        if (first) {
            this.setState({ supportingDocuments: supportingDocuments.data, allDocuments: values.documents ? values.documents : [], ownUpdate: true })
        } else {
            this.setState({ supportingDocuments: supportingDocuments.data, ownUpdate: true })
        }
    }

    async componentDidMount() {
        await this.fetchSupportingDocument(true)
    }

    onFormSubmit(fields: DocumentCollectionFields, onReloadFieldsCB: any): boolean {
        try {
            this.setState(
                { errors: hybridAppraisalValidationService.validateDocumentCollectionForm(fields, "", []), ownUpdate: true },
                () => {
                    if (Object.keys(this.state.errors).length === 0) {
                        return this.submit(fields, onReloadFieldsCB);
                    }
                }
            );
            this.setState({ validated: true, ownUpdate: true });
            return false;
        } catch (error) {
            return false;
        }
    }

    async submit(
        fields: DocumentCollectionFields,
        onReloadFieldsCB: any
    ): Promise<boolean> {
        try {
            const decodedToken = decodeToken(getCurrentUserSession().token || "");
            const loanRequest = await groupLoanRequestService.retrieveById(this.props.values.id);

            const allDocuments = [...this.state.allDocuments]
            // console.log("allDocuments", allDocuments)

            const payload: any = {};
            let reference = `hyb_post_${this.props.values.requestIdentifier}`
            payload.institutionId = decodedToken?.["InstitutionId"];
            payload.document = await textFormatService.getBase64StringFromFile(this.state.docToUpload);
            payload.name = this.state.docToUpload.name.split(".")[0];
            payload.loanReference = reference;
            // payload.loanReference = JSON.parse(JSON.parse(JSON.parse(this.props.values.jsonData)).LoanDetails).AccountNumber
            payload.customerID = this.props.values.id;
            payload.disputeId = 0;
            payload.supportingDocumentType = "Others";
            payload.disputeConditionDocumentType = this.state.data.docToUploadType;
            payload.extensionType = this.state.docToUpload.name.split(".")[1].toUpperCase();
            this.setState({ uploadingDocument: true, submitting: true, ownUpdate: true });

            // console.log("submitting");
            // return false;

            var response = await supportingDocumentService.saveSupportingDocument(
                payload
            );
            if (response.status >= 200 && response.status <= 300) {
                // console.log("submitted", response.data.payLoad.id);
                allDocuments.push({
                    name: fields.documentName,
                    category: fields.documentCategory,
                    status: fields.documentStatus,
                    expiryDate: fields.documentExpiryDate,
                    documentId: response.data.payLoad.id
                })
                toast.info(`Supporting Document saved successfully!`, {
                    autoClose: 6000,
                    type: toast.TYPE.DEFAULT,
                    hideProgressBar: false,
                });

                // console.log("allDocuments", allDocuments)
            }
            const loanRequestDetail = loanRequest.data;
            const loanPayload: any = { ...loanRequestDetail };
            loanPayload.documents = [...allDocuments]

            const loanResponse = await groupLoanRequestService.updateLoanRequest(loanPayload);
            // console.log(response.data.payload.id)
            this.setState({ allDocuments, uploadingDocument: false, submitting: false, ownUpdate: true });

            activityTrailService.saveActivityTrail({
                actionType: "Upload Supporting Document",
                description: `Upload Supporting Document on loan request ${this.props.values.id}`,
            });
            // console.log("response2: ", response);
            this.resetFields();
        } catch (error) {
            toast.error(error.response.data.Message);
            this.setState({ uploadingDocument: false, submitting: false, ownUpdate: true });
            return false;
        }
        finally {
            this.fetchSupportingDocument(false)
            return true;
        }
    };

    onFormSubmit2(fields: DocumentCollectionFields, onReloadFieldsCB: any, alt?): boolean {
        try {
            this.setState(
                { errors: [], ownUpdate: true },
                () => {
                    if (alt == "pushback") {
                        return this.submit2(fields, onReloadFieldsCB, alt);
                    }
                    if (Object.keys(this.state.errors).length === 0) {
                        return this.submit2(fields, onReloadFieldsCB);
                    }
                }
            );
            this.setState({ validated: true, ownUpdate: true });
            return false;
        } catch (error) {
            return false;
        }
    }

    async submit2(
        fields: DocumentCollectionFields,
        onReloadFieldsCB: any,
        alt?
    ): Promise<boolean> {
        try {
            this.setState({ submitting: true, ownUpdate: true });
            const { values } = this.props;

            if (alt == "pushback") {
                // console.log("progressing action...");
                const currentUserSession = getCurrentUserSession();
                var payload: any = {};
                payload.loanRequestId = values.id
                payload.action = "PushBack"
                payload.itemLevel = values.currentLevel
                payload.userID = currentUserSession.userId
                payload.roleID = currentUserSession.userRoleId
                payload.systemProcessID = 0
                payload.errorMessage = ""
                payload.logType = "UserType"
                payload.comment = fields.comment
                payload.effectiveStatus = "Valid"
                payload.processResponse = null
                payload.IsGroupLoan = true;
                // console.log("Payload: ", payload)
                const response = await appraisalActionService.createAppraisalAction(payload)
                if (response.status >= 200 && response.status <= 300) {
                    // toggleAppraisalProcess()
                    activityTrailService.saveActivityTrail({
                        actionType: "Add Appraisal Action",
                        description: `Added new Appraisal Action`,
                    });
                    toast.info(`Loan Request Pushed Back to Document Generation!`, {
                        autoClose: 6000,
                        type: toast.TYPE.INFO,
                        hideProgressBar: false,
                    });
                } else {
                    return false;
                }
            } else {
                // console.log("progressing action...");
                const currentUserSession = getCurrentUserSession();
                var payload: any = {};
                payload.loanRequestId = values.id
                payload.action = "Approve"
                payload.itemLevel = values.currentLevel
                payload.userID = currentUserSession.userId
                payload.roleID = currentUserSession.userRoleId
                payload.systemProcessID = 0
                payload.errorMessage = ""
                payload.logType = "UserType"
                payload.comment = fields.comment == "" ? "Done with Document Collection" : fields.comment
                payload.effectiveStatus = "Valid"
                payload.processResponse = null
                payload.IsGroupLoan = true;
                // console.log("Payload: ", payload)

                const response = await appraisalActionService.createAppraisalAction(payload)
                if (response.status >= 200 && response.status <= 300) {
                    // toggleAppraisalProcess()
                    activityTrailService.saveActivityTrail({
                        actionType: "Add Appraisal Action",
                        description: `Added new Appraisal Action`,
                    });
                    toast.info(`Loan Request for ${values.customerName} has moved to Loan Disbursement Initiation!`, {
                        autoClose: 6000,
                        type: toast.TYPE.INFO,
                        hideProgressBar: false,
                    });
                } else {
                    return false;
                }
            }
            this.setState({ submitting: false, ownUpdate: true });
            //   this.resetFields();
            // onReloadFieldsCB();
        } catch (error) {
            if (error.response.status === 409) {
                toast.error(error.response.data.detail, {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            } else if (error.response.status === 400) {
                toast.error(error.response.data.errors[0], {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            }
            this.setState({ submitting: false, ownUpdate: true });
            return false;
        } finally {
            return true;
        }
    }

    toggleShowPreview = () => {
        this.setState({ showPreview: !this.state.showPreview, ownUpdate: true });
    };

    handleOnDocumentSelect = async (document) => {
        try {
            var doc = await supportingDocumentService.retrieveById(document.id);
            document["document"] = doc.data.document;
            switch (document.extensionType) {
                case "PNG":
                case "JPG":
                case "GIF":
                    document[
                        "downloadname"
                    ] = `${document.name}_${document.loanReference}.jpg`;
                    document["data"] = `data:image/jpg;base64,${document.document}`;
                    document["payload"] = (
                        <img
                            src={document.data}
                            alt={document.name}
                            width="100%"
                            height="100%"
                            className="image"
                        />
                    );
                    break;
                case "DOCX":
                case "PDF":
                    var data = `data:application/pdf;base64,${document.document}`;
                    document["payload"] = (
                        <object
                            width="100%"
                            height="500px"
                            data={data}
                            type="application/pdf"
                            className="internal"
                        >
                            <embed src={data} type="application/pdf" />
                        </object>
                    );
                    break;
                case "HTML":
                    document["payload"] = (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: atob(document.document),
                            }}
                        />
                    );
                    break;
                default:
                    break;
            }
            this.setState({ document, showPreview: true, ownUpdate: true });
        } catch (error) {
            // console.log(error);
        }
    };

    generateExtensionIcon = (document) => {
        switch (document.extensionType) {
            case "PNG":
            case "JPG":
            case "GIF":
                return (
                    <i
                        className="fas fa-file-image cyan-text fa-lg"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.handleOnDocumentSelect(document)}
                    ></i>
                );
            case "PDF":
                return (
                    <i
                        className="fas fa-file-pdf red-text fa-lg"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.handleOnDocumentSelect(document)}
                    ></i>
                );
            case "DOC":
            case "DOCX":
                return (
                    <i
                        className="fas fa-file-word blue-text fa-lg"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.handleOnDocumentSelect(document)}
                    ></i>
                );
            default:
                return (
                    <i
                        className="fas fa-file-pdf red-text fa-lg"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.handleOnDocumentSelect(document)}
                    ></i>
                );
        }
    };

    resetFields() {
        const data = { ...this.state.data };
        data.comment = "";
        data.docToUploadType = "";
        this.setState({ data, validated: false, document: {}, docToUpload: {}, submitting: false, errors: [], ownUpdate: true });
    }

    handleDeleteDocument = async (document) => {
        try {
            this.setState({ uploadingDocument: true, submitting: true, ownUpdate: true });
            const decodedToken = decodeToken(getCurrentUserSession().token || "");
            var response = await supportingDocumentService.deleteSupportingDocument(
                document.id
            );
            activityTrailService.saveActivityTrail({
                actionType: "Remove Supporting Document",
                description: `Remove Supporting Document [${document.name}] on dispute ${this.props.values.id}`,
            });
            this.setState({ uploadingDocument: false, submitting: false, ownUpdate: true });
            this.resetFields();
        } catch (error) {
            toast.error(error.response.data.Message);
            this.setState({ uploadingDocument: false, submitting: false, ownUpdate: true });
        }
        finally {
            this.fetchSupportingDocument(false)
        }
    };

    handleUploadDocument = async (fields: DocumentCollectionFields) => {
        try {
            const decodedToken = decodeToken(getCurrentUserSession().token || "");
            const payload: any = {};
            payload.institutionId = decodedToken?.["InstitutionId"];
            payload.document = await textFormatService.getBase64StringFromFile(this.state.docToUpload);
            payload.name = this.state.docToUpload.name.split(".")[0];
            payload.loanReference = JSON.parse(JSON.parse(JSON.parse(this.props.values.jsonData)).LoanDetails).AccountNumber
            payload.customerID = this.props.values.id;
            payload.disputeId = 0;
            payload.supportingDocumentType = "Others";
            payload.disputeConditionDocumentType = this.state.data.docToUploadType;
            payload.extensionType = this.state.docToUpload.name.split(".")[1].toUpperCase();
            this.setState({ uploadingDocument: true, submitting: true, ownUpdate: true });
            try {
                var response = await supportingDocumentService.saveSupportingDocument(
                    payload
                );
                // console.log(response)
                // console.log(response.data.payload.id)
            } catch (error) {
                throw error;
            }
            await activityTrailService.saveActivityTrail({
                actionType: "Upload Supporting Document",
                description: `Upload Supporting Document on loan request ${this.props.values.id}`,
            });
            this.setState({ uploadingDocument: false, submitting: false, ownUpdate: true });
            // console.log("response2: ", response);
            this.resetFields();
        } catch (error) {
            toast.error(error.response.data.Message);
            this.setState({ uploadingDocument: false, submitting: false, ownUpdate: true });
        }
        finally {
            this.fetchSupportingDocument(false)
        }
    };

    onDocumentChange = (e) => {
        e.preventDefault();
        if (e.target.files[0] === undefined) {
            return;
        }
        if (e.target.files[0].size > 3000000) {
            toast.error(
                "Please confirm that the size of the document is less than 3MB"
            );
        } else if (
            !["docx", "doc", "pdf", "jpg", "png"].includes(
                e.target.files[0].name.split(".")[1]
            )
        ) {
            // console.log("check: ", e.target.files[0].name.includes("docx"));
            toast.error(
                "Please confirm that the document is a valid word document, pdf or image"
            );
        } else {
            this.setState({ docToUpload: e.target.files[0], ownUpdate: true });
        }
    };

    render() {
        const {
            values,
        } = this.props;
        const {
            validated,
            data,
            errors,
            submitting,
            docToUpload,
            uploadingDocument,
            supportingDocuments,
            allDocuments,
            ownUpdate
        } = this.state;

        // console.log(values);

        const productDocuments: any = [
            {
                name: "Offer Letter",
                category: "Mandatory"
            },
            {
                name: "Guarantor Letter",
                category: "Mandatory"
            }
        ]
        let documentNameInputData: ISelectItems[] = productDocuments ? productDocuments.map(item => {
            return { name: item.name, value: item.name }
        }) : [];
        // console.log(documentNameInputData)

        const documentStatusInputData: ISelectItems[] = ["Received", "Deferred", "Waived"].map(item => {
            return { name: item, value: item }
        });


        return (
            <DocumentCollectionForm
                initialValues={{
                    documentCategory: "",
                    documentName: "",
                    documentStatus: "",
                    documentExpiryDate: "",
                    comment: ""
                }}
                FormComponent={({
                    fields: {
                        documentCategory,
                        documentName,
                        documentStatus,
                        documentExpiryDate,
                        comment
                    },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                }) => (
                    <Fragment>
                        <div className="card">
                            <div className="card-header clear-fix">
                                {_.isEmpty(values) ? (
                                    <Skeleton width={200} />
                                ) : (
                                    <h6
                                        className="card-title float-left"
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <b>Post Approval Document Collection</b>
                                    </h6>
                                )}
                            </div>
                            <div className="card-body">
                                <div className="form-row">
                                    <div className="col-md-12">
                                        <div className="form-row">
                                            <SelectInputComponent
                                                id="documentName"
                                                name="documentName"
                                                divClass={6}
                                                value={documentName}
                                                items={documentNameInputData}
                                                required={true}
                                                validated={validated}
                                                errors={errors}
                                                onChange={async (id, value) => {
                                                    await onChange(id, value);
                                                    await onChange('documentCategory', productDocuments.filter(doc => doc.name === value)[0].category);
                                                }}
                                            />
                                            <FormInputComponent
                                                id="documentCategory"
                                                name="documentCategory"
                                                type="text"
                                                placeholder=""
                                                divClass={6}
                                                value={documentCategory}
                                                required={true}
                                                validated={validated}
                                                errors={errors}
                                                onChange={onChange}
                                                disabled
                                            />
                                            <SelectInputComponent
                                                id="documentStatus"
                                                name="documentStatus"
                                                divClass={6}
                                                value={documentStatus}
                                                items={documentStatusInputData}
                                                required={true}
                                                validated={validated}
                                                errors={errors}
                                                onChange={onChange}
                                            />
                                            <FormInputComponent
                                                id="documentExpiryDate"
                                                name="documentExpiryDate"
                                                type="date"
                                                placeholder=""
                                                divClass={6}
                                                value={documentExpiryDate}
                                                required={false}
                                                validated={validated}
                                                errors={errors}
                                                onChange={onChange}
                                            />
                                        </div>

                                        <div className="form-row mt-5">
                                            <DetailItem
                                                label="Upload Document"
                                                value={
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input
                                                                type="file"
                                                                className="custom-file-input"
                                                                id="docToUpload"
                                                                name="docToUpload"
                                                                aria-describedby="inputGroupFileAddon01"
                                                                onChange={(e) => this.onDocumentChange(e)}
                                                                required
                                                            />
                                                            <label
                                                                className="custom-file-label"
                                                                htmlFor="docToUpload"
                                                            >
                                                                {_.isEmpty(docToUpload.name)
                                                                    ? "Choose File"
                                                                    : docToUpload.name}
                                                            </label>
                                                        </div>
                                                    </div>
                                                }
                                                labelSize={3}
                                                valueSize={9}
                                            />
                                        </div>

                                        {/* Add Document */}
                                        <div className="form-row">
                                            <div className="my-1 mx-2">
                                                {_.isEmpty(docToUpload.name) ? (
                                                    ""
                                                ) : (
                                                    <React.Fragment>
                                                        {uploadingDocument === true ? (
                                                            <span style={{ float: "right" }}>
                                                                <i className="fas fa-spinner fa-spin fa-lg m-3"></i>
                                                            </span>
                                                        ) : (
                                                            <>
                                                                <button
                                                                    className="btn btn-sm btn-outline-primary my-4"
                                                                    style={{ cursor: "pointer" }}
                                                                    type="button"
                                                                    // onClick={this.handleUploadDocument}
                                                                    onClick={(e) => {
                                                                        onHandleSubmit(
                                                                            e,
                                                                            ActionTypes.SAVE,
                                                                            "Document Details",
                                                                            () => {
                                                                                this.onFormSubmit(
                                                                                    {
                                                                                        documentCategory,
                                                                                        documentName,
                                                                                        documentStatus,
                                                                                        documentExpiryDate,
                                                                                        comment
                                                                                    },
                                                                                    onReloadFields
                                                                                );
                                                                            }
                                                                        );
                                                                    }}
                                                                >
                                                                    <React.Fragment>
                                                                        <i className="fas fa-cloud-upload-alt fa-lg mr-2" />{" "}
                                                                        Upload
                                                                    </React.Fragment>
                                                                </button>
                                                            </>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>

                                        {/* Document Table */}
                                        <div className=" form-row col-md-12">
                                            <div
                                                className="table-responsive text-nowrap"
                                                style={{
                                                    maxHeight: "150px",
                                                    overflowY: "scroll",
                                                }}
                                            >
                                                <table className="table table-hover table-content table-sm table-striped mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                }}
                                                            >
                                                                <strong>#</strong>
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                }}
                                                            >
                                                                <strong>Document Name</strong>
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                }}
                                                            >
                                                                <strong>Document Category</strong>
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                }}
                                                            >
                                                                <strong>Document Status</strong>
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                }}
                                                            >
                                                                <strong>Document Uploaded</strong>
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                }}
                                                            ></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {supportingDocuments.map((item) => (
                                                            <tr key={supportingDocuments.indexOf(item)}>
                                                                <td
                                                                    style={{
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                        fontSize: "small",
                                                                    }}
                                                                >
                                                                    {supportingDocuments.indexOf(item) + 1}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                        fontSize: "small",
                                                                    }}
                                                                >
                                                                    {allDocuments.filter(doc => doc.documentId == item.id)[0]?.name}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                        fontSize: "small",
                                                                    }}
                                                                >
                                                                    {allDocuments.filter(doc => doc.documentId == item.id)[0]?.category}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                        fontSize: "small",
                                                                    }}
                                                                >
                                                                    {allDocuments.filter(doc => doc.documentId == item.id)[0]?.status} {/*Status*/}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                        fontSize: "small",
                                                                    }}
                                                                >
                                                                    {item.name} {/*doc uploaded*/}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                        fontSize: "small",
                                                                    }}
                                                                >
                                                                    {this.generateExtensionIcon(item)}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                        fontSize: "small",
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="far fa-trash-alt ml-5 red-text fa-lg"
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => this.handleDeleteDocument(item)}
                                                                    ></i>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        {/* Save Document */}
                                        {/* <div className="card-body float-right">*/}


                                        <DocumentPreviewer
                                            document={this.state.document}
                                            showPreview={this.state.showPreview}
                                            toggleShowPreview={this.toggleShowPreview}
                                        />
                                    </div>
                                </div>
                                {/* )} */}
                                <hr />
                                {/* <dl className="row">
                                    <FormTextAreaComponent
                                    id="comment"
                                    type="text"
                                    name="comment"
                                    placeholder=""
                                    divClass={12}
                                    value={comment}
                                    required={false}
                                    validated={true}
                                    errors={{}}
                                    onChange={onChange}
                                    disabled={false} />
                                </dl> */}
                                {/* <button
                                    type="submit"
                                    className="btn btn-sm btn-secondary"
                                    disabled={this.state.submitting}
                                    onClick={(e) => {
                                        onHandleSubmit(
                                        e,
                                        ActionTypes.PUSHBACK,
                                        "Loan Application",
                                        () => {
                                            this.onFormSubmit2({
                                                documentCategory,
                                                documentName,
                                                documentStatus,
                                                documentExpiryDate,
                                                comment}, onReloadFields, "pushback");
                                        }
                                        );
                                    }}
                                    >
                                    {this.state.submitting === false ? (
                                        <React.Fragment>
                                        Refer Back
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                        <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                        Please wait...
                                        </React.Fragment>
                                    )}
                                </button> */}
                                {/* <div className="float-right">
                                    <button
                                        type="submit"
                                        className="btn btn-sm btn-primary"
                                        disabled={this.state.submitting}
                                        onClick={(e) => {
                                            onHandleSubmit(
                                                e,
                                                ActionTypes.SAVE,
                                                "Loan Application",
                                                () => {
                                                    this.onFormSubmit2({
                                                        documentCategory,
                                                        documentName,
                                                        documentStatus,
                                                        documentExpiryDate,
                                                        comment
                                                    }, onReloadFields);
                                                }
                                            );
                                        }}
                                    >
                                        {this.state.submitting === false ? (
                                            <React.Fragment>
                                                <i className="fas fa-lg fa-save mr-3" /> Save & Proceed
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                                Please wait...
                                            </React.Fragment>
                                        )}
                                    </button>
                                </div> */}
                            </div>
                        </div>
                    </Fragment>
                )}
            ></DocumentCollectionForm>

        );
    }
}
export default GroupDocumentCollection;
