import http from "../httpService";
import { getCurrentUserSession } from "./authService";
import { IFilterData } from "../approval/approvalItemService";

const authBaseAddress = process.env.REACT_APP_AUTH_BASEADDRESS;

export function retrieveAllByInstitution(institutionId: number) {
  var url =
    authBaseAddress +
    `RemoteSession/RetrieveAllByInstitution?institutionId=${institutionId}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveById(id?: number) {
  var url = authBaseAddress + `RemoteSession/RetrieveById?id=${id}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function saveRemoteSession(session) {
  var url = authBaseAddress + `RemoteSession/StartRemoteSession`;
  
  return http.post(url, session, {
    url: url,
    data: session,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function endRemoteSession(session) {
  var url = authBaseAddress + `RemoteSession/EndRemoteSession`;

  return http.post(url, session, {
    url: url,
    data: session,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function filterRemoteSession(
  payload: IFilterData,
  pageSize: number,
  pageNumber: number
) {
  var url =
    authBaseAddress +
    `RemoteSession/FilterRemoteSession?pageSize=${pageSize}&pageNumber=${pageNumber}`;

  return http.post(url, payload, {
    url: url,
    data: payload,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export default {
  retrieveAllByInstitution,
  retrieveById,
  saveRemoteSession,
  endRemoteSession,
  filterRemoteSession,
};
