import * as React from "react";
import { components } from "react-select";
import { default as ReactSelect } from "react-select";
import {
  firstCharToUpper,
  splitAtUpper,
} from "../services/utility/textFormatService";

export interface IMultiSelectItems {
  label: string;
  value: string;
}

export interface IMultiSelectComponentProps {
  id: string;
  name: string
  value: any;
  options: any;
  components?: any;
  onChange: any;
  placeholder?: string;
  allowSelectAll: boolean;
  closeMenuOnSelect?: boolean;
  blurInputOnSelect?: boolean;
  hideSelectedOptions: boolean;
  allOption?: IMultiSelectItems;
  divClass?: number;
  labelIconClass?: string;
  required?: boolean;
  backspaceRemovesValue?: boolean
  validated?: boolean;
  errors?: any;
  disabled?: boolean;
}

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = props => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

const colourStyles = {
  control: styles => ({
    ...styles,
    fontSize: '12px',
  }),
  option: styles => ({
    ...styles,
    padding: '5px',
    margin: '0px',
    fontSize: '12px',
  }),
  menu: styles => ({
    ...styles,
    // zIndex: 300,
    position: 'relative',
  }),
};

export interface IMultiSelectComponentState { }

class MultiSelectComponent extends React.Component<
  IMultiSelectComponentProps,
  IMultiSelectComponentState
> {
  static defaultProps = {
    required: false,
    disabled: false,
    allOption: {
      label: "Select all",
      value: ""
    }
  };
  render() {
    const {
      id,
      name,
      value,
      onChange,
      allowSelectAll,
      allOption,
      options,
      divClass,
      labelIconClass,
      required,
      validated,
      errors,
      disabled,
      hideSelectedOptions
    } = this.props;

    return (
      <div className={`form-group col${divClass ? "-md-" + divClass : ""} m-0`}>
        <label htmlFor={name} className="col-form-label s-12">
          {required && (
            <span
              aria-hidden="true"
              className="mr-2"
              style={{ color: "#ed5564" }}
            >
              *
            </span>
          )}
          {labelIconClass ? <i className={labelIconClass}></i> : ""}
          {firstCharToUpper(splitAtUpper(name))}
        </label>
        <ReactSelect
          {...this.props}
          id={id}
          name={name}
          value={value}
          isDisabled={disabled}
          isMulti={true}
          isSearchable={true}
          blurInputOnSelect={false}
          closeMenuOnSelect={false}
          hideSelectedOptions={hideSelectedOptions}
          backspaceRemovesValue={true}
          components={{ Option, MultiValue }}
          {...allowSelectAll ? {
            options: [allOption, ...options],
            onChange: ((selected: any) => {
              if (selected !== null && selected.length > 0 && selected[selected.length - 1] === allOption) { return onChange(options) }
              else { return onChange(selected) }
            }
            ),
          } : {
            options: [options],
            onChange: onChange
          }}
          styles={colourStyles}
        />
        <div
          className="invalid-feedback"
          style={{
            visibility: errors[id] ? "visible" : "hidden",
            display: errors[id] ? "block" : "none",
          }}
        >
          {errors[id]}
        </div>
      </div>
    )
  }
}

export default MultiSelectComponent;