import React, { Component, useContext } from "react";
import {
    Card,
    useAccordionToggle,
    AccordionContext,
    Accordion,
} from "react-bootstrap";
import _ from "lodash";
import DetailItem from "../../../../components/detailItem";
import textFormatService from "../../../../services/utility/textFormatService";
import Skeleton from "react-loading-skeleton";

export function ContextAwareToggle({ eventKey, header }) {
    const currentEventKey = useContext(AccordionContext);
    const toggleOnClick = useAccordionToggle(eventKey);
    const isCurrentEventKey = currentEventKey === eventKey;
    const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
    return (
        <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
            <b>{header}</b>
            <i
                className={angleType}
                aria-hidden="true"
                style={{ float: "right" }}
            ></i>
        </Card.Header>
    );
}

interface IcreditAnalysisCheckProps {
    item: any;
}

interface IcreditAnalysisCheckState {
    item: any;
    creditAnalysisResponse: any;
    ownUpdate: boolean;
}

class creditAnalysisCheck extends Component<IcreditAnalysisCheckProps, IcreditAnalysisCheckState> {
    _isMounted: boolean;
    constructor(props: IcreditAnalysisCheckProps) {
        super(props);
        this.state = {
            item: {},
            creditAnalysisResponse: [],
            ownUpdate: false
        };
    }

    static getDerivedStateFromProps(nextProps: IcreditAnalysisCheckProps, prevState) {
        if (prevState.ownUpdate) {
            return {
                ownUpdate: false,
            };
        }
        if (_.isNull(nextProps.item)) {
            return null;
        }
        return {
            item: nextProps.item
        };
    }

    async componentDidMount() {
        this._isMounted = true;
        const creditBureauResp = this.props.item.appraisalActions?.filter(x => (x.systemProcessID === Number(10) || x.systemProcessID === Number(11)) && x.effectiveStatus === "Valid")[0]?.processResponse;
        let creditAnalysisResponse: any = []
        try {
            creditAnalysisResponse = JSON.parse(creditBureauResp?.response);
        }
        catch {
            creditAnalysisResponse = []
        }
        console.log(creditAnalysisResponse)
        if (this._isMounted) {
            this.setState({ creditAnalysisResponse: creditAnalysisResponse, ownUpdate: true });
        }
    }

    render() {
        const { item } = this.props;
        const { creditAnalysisResponse } = this.state;

        return (
            <div className="container-fluid relative animatedParent animateOnce">
                <div className="animated fadeInUpShort go">
                    <div className="row">
                        {/* {console.log(creditAnalysisResponse)} */}
                        <div className="col-md-12">
                            <div className="form-row">
                                <div className="col-md-12">
                                    <div className="row my-3 mx-2">
                                        <h3>
                                            <b>Credit Analysis Check</b>
                                        </h3>
                                    </div>
                                    <div className="card">
                                        {
                                            _.isEmpty(item.appraisalActions.filter(x => (x.systemProcessID === Number(10) || x.systemProcessID === Number(11)) && x.effectiveStatus === "Valid")) || _.isEmpty(item.appraisalActions.filter(x => x.action == "Processed")) ? (
                                                <React.Fragment>
                                                    {/* <div>
                                                        <Skeleton height={50} />
                                                    </div>
                                                    <div>
                                                        <Skeleton height={50} />
                                                    </div>
                                                    <div>
                                                        <Skeleton height={50} />
                                                    </div>
                                                    <div>
                                                        <Skeleton height={50} />
                                                    </div> */}
                                                </React.Fragment>
                                            ) : (
                                                item.appraisalActions.filter(x => (x.systemProcessID === Number(10) || x.systemProcessID === Number(11)) && x.effectiveStatus === "Valid").map((actions) => (
                                                    actions?.processResponse?.result == "No loan history found" ? (
                                                        <React.Fragment>

                                                            <div className="center">
                                                                <h6>No loan history found</h6>
                                                            </div>
                                                        </React.Fragment>

                                                    ) : (
                                                        <Accordion defaultActiveKey="0">
                                                            <div>
                                                                <Card>
                                                                    <ContextAwareToggle
                                                                        eventKey={`0`}
                                                                        header={<React.Fragment>


                                                                        </React.Fragment>}
                                                                    ></ContextAwareToggle>
                                                                    <Accordion.Collapse eventKey={`0`}>
                                                                        <Card.Body>
                                                                            <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                                                <div className="row">

                                                                                    <div className="col-md-5">
                                                                                        {/* <h6
                                                                            className="card-title"
                                                                            style={{ marginBottom: "0px", fontFamily: "sans-serif" }}
                                                                        >
                                                                            <b>Performance Summary</b>
                                                                        </h6>
                                                                        <br /> */}
                                                                                        <dl className="row">
                                                                                            <DetailItem
                                                                                                label="Number of Active Loan Accounts"
                                                                                                labelSize={9}
                                                                                                valueSize={3}
                                                                                                // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                                                                                value={creditAnalysisResponse?.OpenedLoanAccounts}
                                                                                            />
                                                                                            <DetailItem
                                                                                                label="Number of Closed Loan Accounts"
                                                                                                labelSize={9}
                                                                                                valueSize={3}
                                                                                                value={creditAnalysisResponse?.ClosedLoanAccounts}
                                                                                            />
                                                                                            <DetailItem
                                                                                                label="Number of Performing Active Loan Accounts"
                                                                                                labelSize={9}
                                                                                                valueSize={3}
                                                                                                value={creditAnalysisResponse?.PerformingLoanAccounts}
                                                                                            />
                                                                                            <DetailItem
                                                                                                label="Number of Non-Performing Active Loan Accounts"
                                                                                                labelSize={9}
                                                                                                valueSize={3}
                                                                                                value={creditAnalysisResponse?.NonPerformingLoanAccounts}
                                                                                            />
                                                                                            <DetailItem
                                                                                                label="Number of Loan Accounts Written-off"
                                                                                                labelSize={9}
                                                                                                valueSize={3}
                                                                                                value={creditAnalysisResponse?.LoansWrittenOff}
                                                                                            />
                                                                                        </dl>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Card.Body>
                                                                    </Accordion.Collapse>
                                                                </Card>
                                                            </div>
                                                        </Accordion>
                                                    ))))}
                                    </div>

                                    <div className="card mt-3">
                                        <div
                                            className="table-responsive text-nowrap"
                                            style={{
                                                height: "300px",
                                                overflowY: "scroll",
                                            }}
                                        >
                                            <table className="table table-hover table-content table-sm table-striped mb-0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>Customer Name</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>Account Number</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>Product Name</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>Amount</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>Tenor</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>Disbursement Date</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>Maturity Date</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>Balance</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>Overdue</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>No of Days Past Due</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>Status</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>Classification</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>Date Closed</strong>
                                                        </th>
                                                        <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                                            <strong>Amount Written Off</strong>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {_.isEmpty(item.appraisalActions.filter(x => (x.systemProcessID === Number(10) || x.systemProcessID === Number(11)) && x.effectiveStatus === "Valid")) && _.isEmpty(item.appraisalActions.filter(x => x.action == "Processed")) ? (("")) : (
                                                        item.appraisalActions.filter(x => (x.systemProcessID === Number(10) || x.systemProcessID === Number(11)) && x.effectiveStatus === "Valid").map((actions) => (
                                                            actions?.processResponse?.result == "No loan history found" ? ("No loan history found") : (
                                                                // console.log(creditAnalysisResponse?.CreditDetails)
                                                                creditAnalysisResponse?.Details?.map(x => (
                                                                    <tr>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {item.customerName}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {x.AccountNumber}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {x.ProductName}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {/* {x.LoanAmount} */}
                                                                            {textFormatService.formatCurrency(Number(x.LoanAmount / 100), 2)}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {x.LoanTenor}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {textFormatService.formatShortTimeString(x.DisbursementDate)}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {textFormatService.formatShortTimeString(x.MaturationDate)}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {/* {x.LoanBalance} */}
                                                                            {textFormatService.formatCurrency(Number(x.LoanBalance), 2)}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {/* {x.AmountOverdue} */}
                                                                            {textFormatService.formatCurrency(Number(x.AmountOverdue), 2)}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {x.DaysPastDue}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {x.Status}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {x.Classification}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {x.DateClosed === null ? "" : textFormatService.formatTimeString(x.DateClosed)}
                                                                        </td>
                                                                        <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                                                            {/* {x.AmountWrittenOff} */}
                                                                            {textFormatService.formatCurrency(Number(x.AmountWrittenOff), 2)}
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ))
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>

                                    <div className="card mt-3">
                                        {_.isEmpty(item.appraisalActions.filter(x => x.systemProcessID == Number(10))) && _.isEmpty(item.appraisalActions.filter(x => x.action == "Processed")) ? (("")) : (
                                            item.appraisalActions.filter(x => x.systemProcessID == Number(10)).map((actions) => (
                                                actions?.processResponse?.result == "No loan history found" ? ("") : (
                                                    <Accordion defaultActiveKey="0">
                                                        <div>
                                                            <Card>
                                                                <ContextAwareToggle
                                                                    eventKey={`0`}
                                                                    header={<React.Fragment>
                                                                        <i
                                                                            className="fa fa-info-circle"
                                                                            aria-hidden="true"
                                                                            style={{ color: "#878a8a" }}
                                                                        ></i>{" "}
                                                                        Credit Analysis Result
                                                                    </React.Fragment>}
                                                                ></ContextAwareToggle>
                                                                <Accordion.Collapse eventKey={`0`}>
                                                                    <Card.Body>
                                                                        <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <dl className="row">
                                                                                        <DetailItem
                                                                                            label="Performance"
                                                                                            labelSize={5}
                                                                                            valueSize={5}
                                                                                            value=
                                                                                            {item.appraisalActions.filter(x => (x.systemProcessID === Number(10) || x.systemProcessID === Number(11)) && x.effectiveStatus === "Valid")[0]?.action !== "Processed" ? (
                                                                                                <i
                                                                                                    className=""
                                                                                                    aria-hidden="true"
                                                                                                    style={{ color: "#60ed15" }}
                                                                                                >Processing.....</i>) :
                                                                                                item.appraisalActions.filter(x => (x.systemProcessID === Number(10) || x.systemProcessID === Number(11)) && x.effectiveStatus === "Valid")[0]?.processResponse?.result === "Pass" ?
                                                                                                    <i
                                                                                                        className=""
                                                                                                        aria-hidden="true"
                                                                                                        style={{ color: "#60ed15" }}
                                                                                                    >Good</i> : <i
                                                                                                        className=""
                                                                                                        aria-hidden="true"
                                                                                                        style={{ color: "#fcce54" }}
                                                                                                    >Bad</i>
                                                                                            }
                                                                                        />
                                                                                    </dl>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                        </div>
                                                    </Accordion>
                                                ))
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default creditAnalysisCheck;
