import http from "../httpService";
import axios from "axios";
import {
  getCurrentUserSession,
  retrieveCurrentUsersIpAddress,
} from "./authService";
import { Status } from "../../enums/status";
import { gender } from "./institutionsService";
import { IFilterData } from "../approval/approvalItemService";
import { getIpAddress, cryptoAESEncrypt } from "../utility/textFormatService";
import { decodeToken } from "react-jwt";
//import config from "./../../appSettings";

interface ISearchUsersData {
  username: string;
  branchId: number;
  status: Status;
}
interface IUserCommon {
  hasApprovalRights: boolean;
  firstName: string;
  lastName: string;
  otherName: string;
  phoneNo: string;
  branchId: number;
  userRoleId: number;
  email: string;
  address: string;
  gender: gender;
  scope: string;
  clientIPAddress: string;
}
interface ISaveUser extends IUserCommon {
  userName: string;
  institutionId: number;
  staffId: number;
}
interface IUpdateUser extends IUserCommon {
  id: number;
  title: string;
  dateOfBirth: Date;
}

const authBaseAddress = process.env.REACT_APP_AUTH_BASEADDRESS;

export function retrieveAll() {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  var url =
    authBaseAddress +
    `users/GetAll?institutionId=${decodedToken?.["InstitutionId"]}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export async function saveUser(user: ISaveUser) {
  user.clientIPAddress = await getIpAddress().geoplugin_request;
  var url = authBaseAddress + `users`;

  return http.post(url, user, {
    url: url,
    data: user,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export async function updateUser(user: IUpdateUser) {
  user.clientIPAddress = await getIpAddress().geoplugin_request;
  var url = authBaseAddress + `users/${user.id}`;

  return http.post(url, user, {
    url: url,
    data: user,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveUserById(userId: number | any) {
  // var url = authBaseAddress + `users/${userId}`;
  var url = authBaseAddress + `users/RetrieveByUserId/${userId}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveUserByUsername(username: string) {
  var url = authBaseAddress + `users/GetByUsername2?UserName=${username}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function enableUser(id: number) {
  var url = authBaseAddress + `users/Enable/${id}`;
  return http.post(url, null, {
    url: url,
    method: "post",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
      ClientIPAddress: retrieveCurrentUsersIpAddress(),
    },
  });
}

export function disableUser(id: number) {
  var url = authBaseAddress + `users/Disable/${id}`;
  return http.post(url, null, {
    url: url,
    method: "post",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
      ClientIPAddress: retrieveCurrentUsersIpAddress(),
    },
  });
}

export function unlockUser(username: string) {
  var url = authBaseAddress + `users/Unlock?username=${username}`;
  return http.post(url, null, {
    url: url,
    method: "post",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
      ClientIPAddress: retrieveCurrentUsersIpAddress(),
    },
  });
}

export function searchUsers(
  data: ISearchUsersData,
  pageSize: number,
  pageNumber: number
) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  var url =
    authBaseAddress +
    `users/SearchUsers?institutionId=${decodedToken?.["InstitutionId"]}&status=${data.status}&branchId=${data.branchId}&username=${data.username}&pageSize=${pageSize}&pageNumber=${pageNumber}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function filterUsers(
  payload: IFilterData,
  pageSize: number,
  pageNumber: number
) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  payload = { ...payload };
  payload.institutionId = {
    item1: "=",
    item2: decodedToken?.["InstitutionId"]?.toString() || "break",
  };
  var url =
    authBaseAddress +
    `users/FilterUsers?pageSize=${pageSize}&pageNumber=${pageNumber}`;
  return http.post(url, payload, {
    url: url,
    method: "post",
    data: payload,
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveUserByToken(token) {
  var url = authBaseAddress + `users/RetrieveByToken/${token}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveAllSuperAdmins(institutionId: number) {
  var url = authBaseAddress + `users/RetrieveAllSuperAdmins/${institutionId}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function incrementPasswordFailureCounter(userId: number) {
  var url = authBaseAddress + `users/IncrementPasswordFailureCounter/${userId}`;

  return http.post(url, {
    url: url,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveUsersByInstitutionId(institutionId: number) {
  var url = authBaseAddress + `users/RetrieveUsersByInstitutionId/${institutionId}`;
  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function setUserPassword(
  institutionCode: string,
  username: string,
  password: string
) {
  const payload: any = {};
  payload.institutionCode = institutionCode;
  payload.userName = username;
  payload.password = cryptoAESEncrypt(password);

  var url =
    authBaseAddress +
    `users/SetUserPassword`;

  return http.post(url, payload, {
    url: url,
    method: "post",
    data: payload,
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function downloadReport(type: string) {
  var url = authBaseAddress + `users/downloadreport?type=${type}`;

  axios({
    url: url,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Users.${type}`); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
}

export function downloadReportPDF() {
  var url = authBaseAddress + `users/DownloadReportPDF`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export default {
  retrieveAll,
  saveUser,
  updateUser,
  retrieveUserById,
  retrieveUserByUsername,
  enableUser,
  disableUser,
  unlockUser,
  searchUsers,
  filterUsers,
  retrieveUserByToken,
  retrieveUsersByInstitutionId,
  setUserPassword,
  downloadReport,
  downloadReportPDF,
  incrementPasswordFailureCounter
};
