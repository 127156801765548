import http from "../httpService";

const sweepBaseAddress = process.env.REACT_APP_SWEEP_BASEADDRESS;

export function saveFeeConfig(feeConfig) {
  return http.post(
    sweepBaseAddress + `FeeConfig/Save`,
    feeConfig
  );
}

export function updateFeeConfig(feeConfig) {
  return http.post(
    sweepBaseAddress + `FeeConfig/Save`,
    feeConfig
  );
}

export function retrieveFeeConfigById(id: number) {
  return http.get(
    sweepBaseAddress + `FeeConfig/RetrieveById/${id}`
  );
}

export function retrieveFeeConfigByInstitutionId(id: number) {
  return http.get(
    sweepBaseAddress + `FeeConfig/RetrieveByInstitutionId/${id}`
  );
}

export function retrieveFeeConfigByInstitutionCode(code: string) {
  return http.get(
    sweepBaseAddress +
    `FeeConfig/RetrieveByInstitutionCode/${code}`
  );
}


export default {
  saveFeeConfig,
  updateFeeConfig,
  retrieveFeeConfigById,
  retrieveFeeConfigByInstitutionId,
  retrieveFeeConfigByInstitutionCode,
};
