import React from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import FormInputComponent from "../../../components/formInputComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import {
  ISelectItems,
} from "../../../components/formSelectComponent";
import mandateValidationService from "../../../services/mandate/mandateValidationService";
import collectionConsentConfigService from "../../../services/mandate/collectionConsentConfigService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

interface AddCollectionConsentConfigFields {
  repaymentBank: string;
  bankCode: string;
}

class AddCollectionConsentConfigForm extends BaseFormComponent<AddCollectionConsentConfigFields> { }

interface IAddCollectionConsentConfigProps { }
interface IAddCollectionConsentConfigState {
  availableCollectionConsentTypes: ISelectItems[];
  collectionConsentTypes: any;
}

class AddCollectionConsentConfig extends React.Component<
  IAddCollectionConsentConfigProps,
  IBaseFormState & IAddCollectionConsentConfigState
> {
  constructor(props: IAddCollectionConsentConfigProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      availableCollectionConsentTypes: [
        {
          name: "OTP",
          value: "OTP",
        },
        {
          name: "PIN",
          value: "PIN",
        },
        {
          name: "PhoneNumber",
          value: "Phone Number",
        },
        {
          name: "First3DigitsOfCard",
          value: "First 3 Digits of Card",
        },
        {
          name: "Last3DigitsOfCard",
          value: "Last 3 Digits of Card",
        },
        {
          name: "First4DigitsOfCard",
          value: "First 4 Digits of Card",
        },
        {
          name: "Last4DigitsOfCard",
          value: "Last 4 Digits of Card",
        },
        {
          name: "First6DigitsOfCard",
          value: "First 6 Digits of Card",
        },
        {
          name: "Last6DigitsOfCard",
          value: "Last 6 Digits of Card",
        },
      ],
      collectionConsentTypes: [],
    };
  }

  onFormSubmit(
    fields: AddCollectionConsentConfigFields,
    onReloadFieldsCB: any
  ): boolean {
    try {
      // console.log("Fields for submitting:: ", fields)
      this.setState(
        {
          errors:
            mandateValidationService.validateAddCollectionConsentConfigForm(
              fields
            ),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            // console.log("passed Validation")
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddCollectionConsentConfigFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    let response: any;
    try {
      if (_.isEmpty(this.state.collectionConsentTypes)) {
        toast.error(
          `Configuration must include at least one Collection Consent Type`,
          {
            autoClose: 6000,
            type: toast.TYPE.ERROR,
            hideProgressBar: false,
          }
        );
        this.setState({ submitting: false })
      }
      else {
        this.setState({ submitting: true });
        const payload: any = {};

        payload.repaymentBank = fields.repaymentBank;
        payload.bankCode = fields.bankCode;
        payload.collectionConsentTypes = this.state.collectionConsentTypes;

        response =
          await collectionConsentConfigService.createCollectionConsentConfig(
            payload
          );
        // console.log("testing log:: ", response);
        if (response.status >= 200 && response.status <= 300) {
          await activityTrailService.saveActivityTrail({
            actionType: "Add Collection Consent Config",
            description: `Added new Collection Consent Config ${fields.repaymentBank}`,
          });
          toast.info(
            `Collection Consent Config ${payload.repaymentBank} created successfully! `,
            {
              autoClose: 6000,
              type: toast.TYPE.DEFAULT,
              hideProgressBar: false,
            }
          );
        } else {
          return false;
        }
        this.setState({ submitting: false });
        this.resetFields();
        onReloadFieldsCB();
      }
    } catch (error) {
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // console.log("error: ", error.response);
        // return false;
      }
      // console.log("testing log:: ", response);
      // console.log("error log:: ", error);
      // console.log("error: ", error.response.status);
      // console.log("error: ", error.response);
      this.setState({ submitting: false });
      return false;
    } finally {
      return true;
    }
  }

  handleOnCollectionConsentTypesChange = (item) => {
    // console.log("clicked Collection Consent Type: ", item);
    const collectionConsentTypes = [...this.state.collectionConsentTypes];
    if (collectionConsentTypes.indexOf(item) !== -1) {
      collectionConsentTypes.splice(collectionConsentTypes.indexOf(item), 1);
    } else {
      collectionConsentTypes.push(item);
    }
    // console.log("clicked Collection Consent Type: ", collectionConsentTypes);
    this.setState({ collectionConsentTypes });
  };

  resetFields() {
    this.setState({ validated: false, submitting: false });
  }

  render() {
    const { errors, validated, availableCollectionConsentTypes } = this.state;

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add Collection Consent Config</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddCollectionConsentConfigForm
                  initialValues={{
                    repaymentBank: "",
                    bankCode: "",
                  }}
                  FormComponent={({
                    fields: { repaymentBank, bankCode },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <h5 className="card-title">
                            <i className="fas fa-university mr-3" />
                            Collection Consent Config
                          </h5>
                          <div className="form-row">
                            <div className="col-md-12">
                              <div className="form-row">
                                <FormInputComponent
                                  id="repaymentBank"
                                  type="text"
                                  name={"repaymentBank"}
                                  placeholder=""
                                  divClass={6}
                                  value={repaymentBank}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="bankCode"
                                  type="text"
                                  name={"bankCode"}
                                  placeholder=""
                                  divClass={6}
                                  value={bankCode}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>

                              <br />
                              <div className="">
                                <span
                                  aria-hidden="true"
                                  className="mr-2"
                                  style={{ color: "#ed5564" }}
                                >
                                  *
                                </span>
                                <label
                                  htmlFor="collectionConsentTypes"
                                  className="control-label textbox-label"
                                >
                                  {"  "} Collection Consent Types
                                </label>
                                <div
                                  className="text-nowrap block-example border"
                                  style={{
                                    width: "100%",
                                    height: "200px",
                                    overflowY: "scroll",
                                  }}
                                >
                                  <React.Fragment>
                                    {availableCollectionConsentTypes.length ===
                                      0 ? (
                                      <div
                                        style={{
                                          backgroundColor: "#F3F3F3",
                                        }}
                                      >
                                        <p className="text-center">
                                          No Available Collection Consent Type Found
                                        </p>
                                      </div>
                                    ) : (
                                      <React.Fragment>
                                        {availableCollectionConsentTypes.map(
                                          (item) => (
                                            <div
                                              className="custom-control custom-checkbox"
                                              key={availableCollectionConsentTypes.indexOf(
                                                item
                                              )}
                                            >
                                              <input
                                                id={item.name}
                                                name={item.name}
                                                className="custom-control-input"
                                                type="checkbox"
                                                onChange={() =>
                                                  this.handleOnCollectionConsentTypesChange(
                                                    item.name
                                                  )
                                                }
                                                checked={
                                                  _.isEmpty(
                                                    this.state.collectionConsentTypes.filter(
                                                      (x) => x === item.name
                                                    )
                                                  )
                                                    ? false
                                                    : true
                                                }
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor={item.name}
                                              >
                                                {item.value}
                                              </label>
                                            </div>
                                          )
                                        )}
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="card-body">
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                            disabled={this.state.submitting}
                            onClick={(e) => {
                              onHandleSubmit(
                                e,
                                ActionTypes.SAVE,
                                "Collection Consent Config",
                                () => {
                                  this.onFormSubmit(
                                    {
                                      repaymentBank,
                                      bankCode
                                    },
                                    onReloadFields
                                  );
                                }
                              );
                            }}
                          >
                            {this.state.submitting === false ? (
                              <React.Fragment>
                                <i className="fas fa-lg fa-save mr-3" /> Save
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                Please wait...
                              </React.Fragment>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                ></AddCollectionConsentConfigForm>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddCollectionConsentConfig;
