import http from "../httpService";

const billingBaseAddress = process.env.REACT_APP_BILLING_BASEADDRESS;

export function saveThirdPartyAccountConfig(accountConfig) {
    return http.post(
        billingBaseAddress + `ThirdPartyAccountConfiguration`,
        accountConfig
    );
}

export function retrieveThirdPartyAccountConfig() {
    return http.get(
        billingBaseAddress + `ThirdPartyAccountConfiguration`
    );
}

export function retrieveThirdPartyAccountConfigById(id: string) {
    return http.get(
        billingBaseAddress + `ThirdPartyAccountConfiguration/${id}`
    );
}

export function updateThirdPartyAccountConfig(id: string, accountConfig) {
    return http.put(
        billingBaseAddress + `ThirdPartyAccountConfiguration/configuration/Edit/${id}`,
        accountConfig
    );
}

export function filterThirdPartyAccountConfig(institutionCode: string, thirdParty: string) {
    return http.get(
        billingBaseAddress +
        `ThirdPartyAccountConfiguration/ThirdPartyAccounts/filter?InstitutionCode=${institutionCode}&ThirdParty=${thirdParty}`
    );
}


export default {
    saveThirdPartyAccountConfig,
    retrieveThirdPartyAccountConfig,
    retrieveThirdPartyAccountConfigById,
    updateThirdPartyAccountConfig,
    filterThirdPartyAccountConfig
};
