import React, { Component, Fragment, useContext } from "react";
import {
    Modal,
    Button,
    Card,
    useAccordionToggle,
    AccordionContext,
    Accordion,
} from "react-bootstrap";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import BaseFormComponent, { IBaseFormState } from "../../../../components/BaseFormComponent";
import textFormatService from "../../../../services/utility/textFormatService";
import ItemsTable, { filterFieldData } from "../../../../components/tableComponents/itemsTable";
import TableFilterPane from "../../../../components/tableComponents/tableFilterPane";
import { getCurrentUserSession } from "../../../../services/auth/authService";
// import TinRequestDetails from "../../../../loanAppraisal/appraisalDetailPages/TinRequestDetails";
// import { ContextAwareToggle } from "./documentCollection";
import { decodeToken } from "react-jwt";
import BaseListComponent, { IFilterData } from "../../../../components/BaseListComponent";
import loanProductService from "../../../../services/hybridAppraisal/loanProductService";
import loanRequestService from "../../../../services/hybridAppraisal/loanRequestService";
import { createFilterData, mapEntityList } from "../../../../services/utility/autoMapperService";
import loanProductMapService from "../../../../services/hybridAppraisal/loanProductMapService";
import groupLoanRequestMembersService from "../../../../services/hybridAppraisal/groupLoanRequestMembersService";
import { toast } from "react-toastify";
import DetailItemsTable, { TableContext } from "../../../../components/tableComponents/detailItemsTable";

interface TinValidationListModalFields { }

class TinValidationDetailsModalForm extends BaseFormComponent<TinValidationListModalFields> { }

interface ITinValidationDetailsModalProps {
    item: any;
    members: any;
    showPatchModal: boolean;
    togglePatchModal: any;
}

interface ITinValidationDetailsModalState {
    item: any;
    updatingStatus: boolean;
    submitting: boolean;
    data: IFilterData;
    currentPage: number;
    pageSize: number;
    tableItems: any;
    fetching: boolean;
    fetchingDetail: boolean;
    totalSize: number;
    filterValueInput: filterFieldData;
    userRoles: any;
    systemProcess: any;
    applicableRules: any;
    appraisalActions: any;
    updateComponent: boolean;
    documentsToUpload: any;
    documentsToDelete: number[];
    canEditItem: boolean;
    updateAvailable: boolean;
    processResponse: any;
    specialConditions: any;
    ownUpdate: boolean;
    showPersonalDetails: boolean;
    groupMemberList: any;
}

class TinValidationDetailsModalList extends BaseListComponent<ITinValidationDetailsModalState> { }

class TinValidationDetailsModal extends React.Component<
    ITinValidationDetailsModalProps,
    IBaseFormState & ITinValidationDetailsModalState
> {
    _isMounted: boolean;
    constructor(props: ITinValidationDetailsModalProps) {
        super(props);
        this.state = {
            validated: false,
            submitting: false,
            errors: {},
            item: {},
            updatingStatus: false,
            data: createFilterData(["customerName", "loanType"]) as IFilterData,
            currentPage: 1,
            pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
            fetching: false,
            fetchingDetail: false,
            tableItems: [],
            totalSize: 0,
            filterValueInput: [
                { name: "customerName", fieldType: "text", value: "" },
                {
                    name: "loanType",
                    fieldType: "select",
                    options: ["Individual", "Corporate"],
                    value: "",
                }
            ],
            userRoles: [],
            systemProcess: [],
            applicableRules: [],
            appraisalActions: [],
            documentsToUpload: [],
            documentsToDelete: [],
            updateComponent: false,
            canEditItem: false,
            updateAvailable: false,
            specialConditions: [],
            processResponse: {
                actionId: 0,
                score: 0,
                response: ""
            },
            ownUpdate: false,
            showPersonalDetails: false,
            groupMemberList: {}
        };
    }

    resetFields() {
        this.setState({
            validated: false,
            submitting: false
        });
    }

    async componentDidMount() {
        this._isMounted = true;
        this.handlePageChange(this.state.currentPage, this.state.pageSize)

    }
    componentWillUnmount() {
        this._isMounted = false
    }

    approveAppraisal = async (memberStatus: string, id) => {
        const memberDetails = await groupLoanRequestMembersService.retrieveById(id)
        const memberDetail = memberDetails.data;

        const payload: any = { ...memberDetail };


        payload.memberStatus = memberStatus === "Eligible" ? "Ineligible" : "Eligible"

        const response = await groupLoanRequestMembersService.updateLoanRequest(payload);
        if (response.status >= 200 && response.status <= 300) {
            toast.info(`Member Approved!`, {
                autoClose: 6000,
                type: toast.TYPE.DEFAULT,
                hideProgressBar: false,
            });
        } else {
            return false;
        }
    };

    handlePageChange = async (currentPage: number, pageSize: number, data?: IFilterData) => {
        currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
        pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
        if (this._isMounted) {
            this.setState({ fetching: true, ownUpdate: true });
        }

        try {
            // const responses: any = [];
            const products = await Promise.allSettled([loanProductService.retrieveAll()]);
            // console.log(products.values.dat)
            const productList = products[0].status == "fulfilled" ? products[0].value.data : [];
            const member = this.props.members[0];
            const groupMember = await groupLoanRequestMembersService.retrieveById(member?.id)
            const groupMemberList = groupMember.data
            // console.log(groupMemberList)
            // console.log(member);
            const memberList = member?.tinResponse;
            memberList?.forEach(x => {
                x.customerName = member?.customerName
                x.phonenumber = member?.phoneNumber
                x.productName = productList.filter(y => x.productCode === y.productCode)[0].name
            })
            // console.log(memberList.length);

            const membersPaginated: any = [];
            const totalLength = memberList.length;
            const initial = (currentPage - 1) * 10;
            for (let i = initial; i < initial + 10; i++) {
                membersPaginated.push(memberList[i]);
                if (i === totalLength - 1) {
                    break;
                }
            }
            if (this._isMounted) {
                this.setState({
                    tableItems: mapEntityList({
                        entityList: membersPaginated,
                        properties: [
                            { oldName: "customerName", newName: "Name" },
                            { oldName: "phonenumber", newName: "phoneNumber" },
                            { oldName: "loanAmount", newName: "loanAmount" },
                            { oldName: "loanTenure", newName: "tenor" },
                            { oldName: "branch", newName: "branch" },
                            { oldName: "productName", newName: "productName" },
                            // { oldName: "productName", newName: "productName" },
                            { oldName: "dateCreated", newName: "dateCreated" },
                            {
                                isTag: true,
                                oldName: "requestStatus",
                                newName: "status",
                                success: "Active",
                                danger: "Rejected",
                                default: "default",
                                primary: "primary",
                                secondary: "secondary",
                                warning: "Created",
                                light: "light",
                                dark: "dark",
                            },
                        ],
                    }),
                    currentPage,
                    pageSize,
                    totalSize: memberList.length,
                    fetching: false,
                    ownUpdate: true,
                    groupMemberList: groupMemberList
                });
            }
        } catch (ex) {
            if (ex.response && ex.response.status >= 400 && ex.response.status <= 499) {
                if (this._isMounted) {
                    this.setState({ fetching: false, ownUpdate: true });
                }
            } else {
                if (this._isMounted) {
                    this.setState({ fetching: false, ownUpdate: true });
                }
            }
        } finally {
            if (this._isMounted) {
                this.setState({ fetching: false, ownUpdate: true });
            }
        }
    };
    getFilteredItems = (data: IFilterData): IFilterData => {
        let filteredItems: IFilterData = {};
        Object.keys(data).forEach((key) => {
            if (data[key].item2 !== "") {
                filteredItems[key] = data[key];
            }
        });
        return filteredItems;
    };

    static getDerivedStateFromProps(nextProps: ITinValidationDetailsModalProps) {
        return {
            item: nextProps.item,
        };
    }

    handleOnExport = async (type: string) => { };

    render() {
        const { item, showPatchModal, togglePatchModal, members } = this.props;
        const {
            currentPage,
            pageSize,
            tableItems,
            totalSize,
            fetching,
            fetchingDetail,
            submitting,
            groupMemberList
        } = this.state;

        if (_.isEmpty(item)) {
            return null;
        }

        const tableContext: TableContext = {
            name: "",
            primaryValue: "",
            successValue: "Successful",
            dangerValue: "Failed",

        };
        // console.log(members)
        return (
            <Modal
                size="lg"
                backdrop="static"
                show={showPatchModal}
                onHide={togglePatchModal}
            >
                <Modal.Header>
                    <Modal.Title>
                        <h5>
                            <i className="fas fa-edit fa-sm cyan-text mr-3" />
                            Other Related Tin Requests
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <TinValidationDetailsModalForm
                    initialValues={{}}
                    FormComponent={({
                        fields: { },
                        onChange,
                        onReloadFields,
                        onHandleSubmit,
                    }) => (
                        <React.Fragment>
                            <Modal.Body>
                                <div className="card">
                                    <div className="card-header clear-fix">
                                        {_.isEmpty(item) ? (
                                            <Skeleton width={200} />
                                        ) : (
                                            <h6
                                                className="card-title float-left"
                                                style={{ marginBottom: "0px" }}
                                            >
                                                <b>Other Related Tin Requests for {members[0]?.customerName} ({groupMemberList.solidarityGroupName})</b>
                                            </h6>
                                        )}
                                    </div>
                                    <div className="card">
                                        <Accordion defaultActiveKey="0">
                                            <div>
                                                <Card>
                                                    {/* <ContextAwareToggle
                                                        eventKey={`0`}
                                                        level={""}
                                                    ></ContextAwareToggle> */}
                                                    <Accordion.Collapse eventKey={`0`}>
                                                        <Card.Body>
                                                            <TinValidationDetailsModalList
                                                                pageChangeHandler={this.handlePageChange}
                                                                fetchingDetail={fetchingDetail}
                                                                initialValues={{
                                                                    data: this.state.data,
                                                                    fetching: false,
                                                                    showDetailsModal: false,
                                                                    showEditModal: false,
                                                                    showTableFilterModal: false,
                                                                    showTrailModal: false,
                                                                    tableItems: [],
                                                                    filterValueInput: this.state.filterValueInput,
                                                                    item: {},
                                                                }}
                                                                tableItems={tableItems}
                                                                item={item}
                                                                DetailsModal={({
                                                                    item,
                                                                    onToggleDetailsModal,
                                                                    onToggleEditModal,
                                                                    showDetailsModal,
                                                                    showEditModal
                                                                }) => (
                                                                    <Fragment>
                                                                        {/* <TinRequestDetails
                                                                            userRoles={userRoles}
                                                                            systemProcess={systemProcess}
                                                                            currentUserSession={getCurrentUserSession()}
                                                                            showDetailsModal={showDetailsModal}
                                                                            toggleDetailsModal={onToggleDetailsModal}
                                                                            item={item}
                                                                        /> */}
                                                                    </Fragment>

                                                                )}
                                                                ListPayLoad={({
                                                                    data,
                                                                    showTableFilterModal,
                                                                    tableItems,
                                                                    onToggleDetailsModal,
                                                                    onToggleTableFilterModal,
                                                                    onHandleFilterItemRemove,
                                                                    filterValueInput,
                                                                }) => (
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="card no-b">
                                                                                <div className="card-body">
                                                                                    <div className="card-title">
                                                                                        <div id="list-filters" className="ml-4 mr-4">
                                                                                            <TableFilterPane
                                                                                                filteredItems={this.getFilteredItems(data)}
                                                                                                handleFilterRemove={onHandleFilterItemRemove}
                                                                                            />
                                                                                        </div>
                                                                                        <div id="list-table">
                                                                                            {/* <ItemsTable
                                                                                                data={data}
                                                                                                items={tableItems}
                                                                                                filterValueInput={filterValueInput}
                                                                                                showTableFiltermodal={showTableFilterModal}
                                                                                                toggleTableFiltermodal={onToggleTableFilterModal}
                                                                                                name="Other Related Tin Requests"
                                                                                                currentPage={currentPage}
                                                                                                pageSize={pageSize}
                                                                                                totalSize={totalSize}
                                                                                                fetching={fetching}
                                                                                                handlePageChange={this.handlePageChange}
                                                                                                onViewDetails={(item: any) => {
                                                                                                    onToggleDetailsModal();
                                                                                                }}
                                                                                                handleOnExport={this.handleOnExport}
                                                                                                timeColumn={["dateCreated"]}
                                                                                                amountColumn={["loanAmount"]}
                                                                                                disableViewDetails
                                                                                            /> */}
                                                                                            <DetailItemsTable
                                                                                                title="Other Related Tin Requests"
                                                                                                item={item}
                                                                                                handleOnPageChange={
                                                                                                    this.handlePageChange
                                                                                                }
                                                                                                content={tableItems}
                                                                                                contentCount={totalSize}
                                                                                                page={currentPage}
                                                                                                onViewDetails={(item: any) => {
                                                                                                    onToggleDetailsModal();
                                                                                                }}
                                                                                                hasDetailModal={true}
                                                                                                fetching={fetching}
                                                                                                amountColumn={["loanAmount"]}
                                                                                                timeColumn={["dateCreated"]}
                                                                                                contextualColumn={tableContext}
                                                                                                emptyTitle="Other Related Tin Requests"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            ></TinValidationDetailsModalList>

                                                            <Button
                                                                className="btn btn-sm btn-outline-primary my-2 mx-3"
                                                                style={{ cursor: "pointer" }}
                                                                type="button"
                                                                // onClick={this.handleUploadDocument}
                                                                onClick={(e) => { this.approveAppraisal(groupMemberList?.memberStatus, members[0]?.id) }}
                                                            >
                                                                {groupMemberList?.memberStatus === "Eligible" ? (
                                                                    <React.Fragment>
                                                                        <i className="fas fa-check fa-lg mr-2" />{" "}
                                                                        Disapprove
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <React.Fragment>
                                                                        <i className="fas fa-check fa-lg mr-2" />{" "}
                                                                        Approve
                                                                    </React.Fragment>
                                                                )}
                                                            </Button>

                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </div>
                                        </Accordion>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    size="sm"
                                    variant="outline-danger"
                                    onClick={() => togglePatchModal(false, {}, {})}
                                    disabled={submitting}
                                >
                                    <i className="fas fa-times mr-3" />
                                    Close
                                </Button>
                            </Modal.Footer>
                        </React.Fragment>
                    )}
                ></TinValidationDetailsModalForm>
            </Modal>
        );
    }
}

export default TinValidationDetailsModal;
