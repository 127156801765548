import React, { Fragment, useContext } from "react";
import {
  Modal,
  Button,
  Card,
  useAccordionToggle,
  AccordionContext,
  Accordion,
} from "react-bootstrap";
import { toast } from "react-toastify";
import _ from "lodash";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import appraisalValidationService from "../../../services/hybridAppraisal/appraisalValidationService";
import DetailItem from "../../../components/detailItem";
import { ActionTypes } from "../../../enums/actionTypes";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import Skeleton from "react-loading-skeleton";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import appraisalPlanService from "../../../services/hybridAppraisal/appraisalPlanService";
import systemProcessService from "../../../services/hybridAppraisal/systemProcessService";

function ContextAwareToggle({ eventKey, header }) {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      <b>{header}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
}

interface EditAppraisalPlanFields {
  name: string;
  reportIds: any;
  validationIds: any;
}

interface ISystemProcess {
  id: number;
  name: string;
  description: string;
}

class EditAppraisalPlanForm extends BaseFormComponent<EditAppraisalPlanFields> { }

interface IEditAppraisalPlanProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}

interface IEditAppraisalPlanState {
  ownUpdate: boolean;
  reportItems: any;
  reports: ISystemProcess[];
  validationItems: any;
  validations: ISystemProcess[];
  fetchingRequiredItems: boolean;
}

class EditAppraisalPlan extends React.Component<
  IEditAppraisalPlanProps,
  IBaseFormState & IEditAppraisalPlanState
> {
  _isMounted = false;
  constructor(props: IEditAppraisalPlanProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      reports: [],
      reportItems: [],
      validationItems: [],
      validations: [],
      fetchingRequiredItems: true,
      ownUpdate: false,
    };
  }

  static getDerivedStateFromProps(nextProps: IEditAppraisalPlanProps, prevState) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isEmpty(nextProps.item)) {
      return null;
    }
    return {
      reports: nextProps.item.reports.map(
        (item) => ({ id: item.id, name: item.name, description: item.description })
      ),
      validations: nextProps.item.validations.map(
        (item) => ({ id: item.id, name: item.name, description: item.description })
      ),
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    if (_.isEmpty(this.props.item)) {
      return
    }
    const { errors } = this.state;
    try {
      if (this._isMounted) {
        this.setState({ fetchingRequiredItems: true, ownUpdate: true });
      }
      const reportItems = await systemProcessService.retrieveByProcessType("Report");
      const validationItems = await systemProcessService.retrieveByProcessType("Validation");
      this.setState({
        reportItems: reportItems.data,
        validationItems: validationItems.data,
        fetchingRequiredItems: false,
        ownUpdate: true
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        errors.response = "Internal server error.";
        this.setState({ errors });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onFormSubmit(fields: EditAppraisalPlanFields, onReloadFieldsCB: any): boolean {
    try {
      fields.reportIds = this.state.reports;
      fields.validationIds = this.state.validations;
      this.setState(
        {
          errors: appraisalValidationService.validateEditAppraisalPlanForm(fields),
          ownUpdate: true,
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  submit = async (
    fields: EditAppraisalPlanFields,
    onReloadFieldsCB: any
  ): Promise<boolean> => {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const payload = { ...this.props.item };
      payload.name = fields.name;
      payload.reports = this.state.reports;
      payload.validations = this.state.validations;

      //console.log("about to update: ", payload);
      const response = await appraisalPlanService.updateAppraisalPlan(payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit Appraisal Plan",
          description: `Edited details for Appraisal Plan ${fields.name}`,
        });
        toast.info(`Appraisal Plan ${fields.name} Edited successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      }
      onReloadFieldsCB();
      this.setState({ submitting: false, ownUpdate: true }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      toast.error(error.response.data, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      this.setState({ submitting: false, ownUpdate: true });
    } finally {
      return true;
    }
  };

  handleOnChangeAppraisalPlanReport = (item: ISystemProcess) => {
    var item: ISystemProcess = { id: item.id, name: item.name, description: item.description };
    const reports = [...this.state.reports];
    if (reports.findIndex(object => { return object.id === item.id }) !== -1) {
      reports.splice(reports.findIndex(object => { return object.id === item.id }), 1);
    } else {
      reports.push(item);
    }
    this.setState({ reports, ownUpdate: true });
  }

  handleOnChangeAppraisalPlanValidation = (item: ISystemProcess) => {
    var item: ISystemProcess = { id: item.id, name: item.name, description: item.description };
    const validations = [...this.state.validations];
    if (validations.findIndex(object => { return object.id === item.id }) !== -1) {
      validations.splice(validations.findIndex(object => { return object.id === item.id }), 1);
    } else {
      validations.push(item);
    }
    this.setState({ validations, ownUpdate: true });
  }

  validateField = (fieldName: string, type: string, validated: boolean) => {
    let defaultClass = "";
    if (type === "checkbox") {
      defaultClass = "custom-control-input";
      if (validated === false) return defaultClass;
    } else {
      defaultClass = "form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    }
    return !validated
      ? defaultClass
      : this.state.errors[fieldName]
        ? `${defaultClass} is-invalid`
        : `${defaultClass} is-valid`;
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false
    });
  }

  render() {
    const { item, showEditModal, toggleEditModal } = this.props;
    const {
      validated,
      submitting,
      errors,
      reportItems,
      validationItems,
      fetchingRequiredItems,
      reports,
      validations
    } = this.state;

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-user-shield fa-sm cyan-text mr-3" />
              Edit Appraisal Plan -{" "}
              <span className="font-weight-bold">{item.name}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditAppraisalPlanForm
          initialValues={{
            name: item.name,
            reportIds: item.reports,
            validationIds: item.validations
          }}
          FormComponent={({
            fields: {
              name,
              reportIds,
              validationIds
            },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    <h6
                      className="card-title float-left"
                      style={{ marginBottom: "0px" }}
                    >
                      Appraisal Plan Details
                    </h6>
                  </div>

                  {/* General Information */}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={3} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Institution Category"
                              labelSize={5}
                              valueSize={7}
                              value={item.institutionCategory}
                            />
                          </dl>
                        )}
                      </div>
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={3} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Appraisal Plan Name"
                              value={
                                <DetailItemInputComponent
                                  id="name"
                                  type="text"
                                  name="name"
                                  placeholder=""
                                  value={name}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={6}
                              valueSize={6}
                            />
                          </dl>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Reports */}
                  <Accordion defaultActiveKey="0">
                    <div>
                      <Card>
                        <ContextAwareToggle
                          eventKey={`0`}
                          header={
                            <React.Fragment>
                              <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                                style={{ color: "#878a8a" }}
                              ></i>{" "}
                              Reports
                            </React.Fragment>
                          }
                        ></ContextAwareToggle>
                        <Accordion.Collapse eventKey={`0`}>
                          <Card.Body>
                            <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                              <div className="row">
                                <div className="col-md-12">
                                  {fetchingRequiredItems ? (
                                    <i className="fas fa-spinner fa-spin mr-2" />
                                  ) : (
                                    <Fragment>
                                      {reportItems.map((report) => (
                                        <div key={report.id} style={{ marginTop: '3px' }}>
                                          <div
                                            key={report.id}
                                            className="custom-control custom-checkbox"
                                          >
                                            <input
                                              id={report.id}
                                              name={report.id}
                                              className={this.validateField(
                                                "reportIds",
                                                "checkbox",
                                                validated
                                              )}
                                              type="checkbox"
                                              checked={
                                                _.isEmpty(
                                                  reports.filter(
                                                    (x) => x.id === report.id
                                                  ))
                                                  ? false
                                                  : true
                                              }
                                              onChange={() =>
                                                this.handleOnChangeAppraisalPlanReport(
                                                  report
                                                )
                                              }
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor={report.id}
                                            >
                                              {report.name}
                                            </label>
                                          </div>
                                        </div>
                                      ))}
                                    </Fragment>
                                  )}
                                  <div
                                    className="invalid-feedback"
                                    style={{
                                      visibility: errors.reportIds ? "visible" : "hidden",
                                      display: errors.reportIds ? "block" : "none",
                                    }}
                                  >
                                    {errors.reportIds}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </div>
                  </Accordion>

                  {/* Validation */}
                  <Accordion defaultActiveKey="1">
                    <div>
                      <Card>
                        <ContextAwareToggle
                          eventKey={`1`}
                          header={
                            <React.Fragment>
                              <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                                style={{ color: "#878a8a" }}
                              ></i>{" "}
                              Validations
                            </React.Fragment>
                          }
                        ></ContextAwareToggle>
                        <Accordion.Collapse eventKey={`1`}>
                          <Card.Body>
                            <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                              <div className="row">
                                <div className="col-md-12">
                                  {fetchingRequiredItems ? (
                                    <i className="fas fa-spinner fa-spin mr-2" />
                                  ) : (
                                    <Fragment>
                                      {validationItems.map((validation) => (
                                        <div key={validation.id} style={{ marginTop: '3px' }}>
                                          <div
                                            key={validation.id}
                                            className="custom-control custom-checkbox"
                                          >
                                            <input
                                              id={validation.id}
                                              name={validation.id}
                                              className={this.validateField(
                                                "validationIds",
                                                "checkbox",
                                                validated
                                              )}
                                              type="checkbox"
                                              checked={
                                                _.isEmpty(
                                                  validations.filter(
                                                    (x) => x.id === validation.id
                                                  ))
                                                  ? false
                                                  : true
                                              }
                                              onChange={() =>
                                                this.handleOnChangeAppraisalPlanValidation(
                                                  validation
                                                )
                                              }
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor={validation.id}
                                            >
                                              {validation.name}
                                            </label>
                                          </div>
                                        </div>
                                      ))}
                                    </Fragment>
                                  )}
                                  <div
                                    className="invalid-feedback"
                                    style={{
                                      visibility: errors.validationIds ? "visible" : "hidden",
                                      display: errors.validationIds ? "block" : "none",
                                    }}
                                  >
                                    {errors.validationIds}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </div>
                  </Accordion>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(e, ActionTypes.UPDATE, "Appraisal Plan", () => {
                      this.onFormSubmit({
                        name,
                        reportIds,
                        validationIds
                      },
                        onReloadFields);
                    });
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></EditAppraisalPlanForm>
      </Modal>
    );
  }
}

export default EditAppraisalPlan;
