import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";

const influxagentBaseAddress =
  process.env.REACT_APP_INFLUXAGENT_BASEADDRESS + `api/`;

export function retrieveActiveMandatesCount() {
  var url = influxagentBaseAddress + `MandateAgent/RetrieveActiveMandatesCount`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveActiveMandatesValue() {
  var url = influxagentBaseAddress + `MandateAgent/RetrieveActiveMandatesValue`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveCancelledMandatesCount() {
  var url =
    influxagentBaseAddress + `MandateAgent/RetrieveCancelledMandatesCount`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveCancelledMandatesValue() {
  var url =
    influxagentBaseAddress + `MandateAgent/RetrieveCancelledMandatesValue`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveAllInstitutionsMandateDetails() {
  var url =
    influxagentBaseAddress +
    `MandateAgent/RetrieveAllInstitutionsMandateDetails`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveAllMandateDetailsTickers() {
  var url =
    influxagentBaseAddress + `MandateAgent/RetrieveAllMandateDetailsTickers`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function filterPagedInstitutionsMandateDetails(
  pageSize: number,
  pageNumber: number
) {
  var url =
    influxagentBaseAddress +
    `MandateAgent/FilterPagedInstitutionsMandateDetails?pageSize=${pageSize}&pageNumber=${pageNumber}`;

  return http.post(url, {
    url: url,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveMandateDetailsTickers() {
  var url =
    influxagentBaseAddress + `MandateAgent/RetrieveMandateDetailsTickers`;

  return http.post(url, {}, {
    url: url,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveInstitutionsMandateDetailsTickers() {
  var url =
    influxagentBaseAddress + `MandateAgent/RetrieveInstitutionsMandateDetailsTickers`;

  return http.post(url, {}, {
    url: url,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export default {
  retrieveActiveMandatesCount,
  retrieveActiveMandatesValue,
  retrieveCancelledMandatesCount,
  retrieveCancelledMandatesValue,
  retrieveAllInstitutionsMandateDetails,
  retrieveAllMandateDetailsTickers,
  filterPagedInstitutionsMandateDetails,
  retrieveMandateDetailsTickers,
  retrieveInstitutionsMandateDetailsTickers,
};
