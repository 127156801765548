import React from "react";
import { toast } from "react-toastify";
import institutionsService from "../../../services/auth/institutionsService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, { IBaseFormState } from "../../../components/BaseFormComponent";
import authValidationService from "../../../services/auth/authValidationService";
import FormInputComponent from "../../../components/formInputComponent";
import SelectInputComponent from "../../../components/formSelectComponent";
import { formatCurrency } from "../../../services/utility/textFormatService";
import { ActionTypes } from "../../../enums/actionTypes";

interface AddInstitutionFields {
    institutionName: string;
    shortName: string;
    cbnCode: string;
    category: string;
    sponsorBank: string;
    externalInstitutionCode: string;
    disputeMailGroup: string;
    disputePhoneNumber: string;
    mandateLimit: string;
    adminUsername: string;
    adminEmail: string;
    adminFirstName: string;
    adminLastName: string;
    adminOtherName: string;
    adminGender: string;
    adminPhonenumber: string;
    greyListCap: string;
    emailDomain: string;
    dmb: string;
}

class AddInstitutionForm extends BaseFormComponent<AddInstitutionFields> {}

interface IAddInstitutionProps {}
interface IAddInstitutionState {
    institutionName: string;
    shortName: string;
    cbnCode: string;
    sponsorBanks: any;
    searchingExternalInstitutionCode: boolean;
    bankoneInstitution: any;
}

class AddInstitution extends React.Component<IAddInstitutionProps, IBaseFormState & IAddInstitutionState> {
    constructor(props: IAddInstitutionProps) {
        super(props);
        this.state = {
            validated: false,
            submitting: false,
            errors: {},
            institutionName: "",
            shortName: "",
            cbnCode: "",
            sponsorBanks: [],
            bankoneInstitution: {},
            searchingExternalInstitutionCode: false,
        };
    }

    async componentWillMount() {
        const { errors } = this.state;
        try {
            const sponsorBanks = await institutionsService.retrieveAllInstitutions();
            this.setState({
                sponsorBanks: sponsorBanks.data.filter((x) => x.category === "CommercialBank"),
            });
        } catch (ex) {
            // console.log("caught exception", ex);
            if (ex.response && ex.response.status === 404) {
                // console.log(ex.response);
                errors.response = "Internal server error.";
                // console.log(errors);
                this.setState({ errors });
            }
        }
    }

    handleOnBlurExternalInstitutionCode = async (externalInstitutionCode: string) => {
        let { institutionName, shortName, cbnCode } = this.state;
        this.setState({ searchingExternalInstitutionCode: true });
        console.log("searching for institutionCode on BankOne: ", externalInstitutionCode);
        try {
            const inst: any = await institutionsService.retrieveBankOneInstitutionByInstitutionCode(externalInstitutionCode);
            // console.log("gotten response...", inst.data);
            if (inst.result !== null) {
                //console.log("DATA: ", data);
                institutionName = inst.data.name;
                shortName = inst.data.shortName;
                cbnCode = inst.data.cbnCode;
                this.setState({
                    institutionName,
                    shortName,
                    cbnCode,
                    searchingExternalInstitutionCode: false,
                    bankoneInstitution: inst.data,
                });
            }
        } catch (ex) {
            // console.log("caught exception", ex);
            if (ex.response && ex.response.status === 404) {
                // console.log(ex.response);
                //errors.response = "Internal server error.";
                //console.log(errors);
            }
            this.setState({ searchingExternalInstitutionCode: false });
        }
    };

    onFormSubmit(fields: AddInstitutionFields, onReloadFieldsCB: any): boolean {
        // fields.shortName = this.state.shortName;
        // fields.institutionName = this.state.institutionName;
        try {
            if (fields.category === "BankOneOFI") {
                fields.institutionName = this.state.institutionName;
                fields.shortName = this.state.shortName;
                fields.cbnCode = this.state.cbnCode;
            }
            // console.log("about to validate institution: ", fields);
            // console.log("current state: ", this.state);
            // debugger;
            this.setState({ errors: authValidationService.validateAddInstitutionForm(fields) }, () => {
                if (Object.keys(this.state.errors).length === 0) {
                    return this.submit(fields, onReloadFieldsCB);
                }
            });
            this.setState({ validated: true });
            return false;
        } catch (error) {
            return false;
        }
    }

    async submit(fields: AddInstitutionFields, onReloadFieldsCB: any): Promise<boolean> {
        try {
            // console.log("starting submission...");
            this.setState({ submitting: true });
            const { bankoneInstitution } = this.state;
            const payload: any = {};
            payload.name = fields.category === "BankOneOFI" ? this.state.institutionName : fields.institutionName;
            payload.shortName = fields.category === "BankOneOFI" ? this.state.shortName : fields.shortName;
            payload.category = fields.category;
            payload.greyListCap = fields.greyListCap;
            payload.cbnCode = fields.cbnCode;
            payload.disputeContactEmail = fields.disputeMailGroup;
            payload.disputeContactPhone = fields.disputePhoneNumber;
            payload.externalInstitutionCode = fields.category === "BankOneOFI" ? bankoneInstitution.institutionCode : "";
            payload.clearingBankId = fields.sponsorBank === "" ? null : fields.sponsorBank;
            payload.MandateLimit = fields.mandateLimit;
            payload.adminUsername = fields.adminUsername;
            payload.adminFirstName = fields.adminFirstName;
            payload.adminLastName = fields.adminLastName;
            payload.adminGender = fields.adminGender;
            payload.adminPhonenumber = fields.adminPhonenumber;
            payload.adminEmail = fields.adminEmail;
            payload.emailDomain = fields.emailDomain;
            payload.dmb = fields.dmb;

            const response = await institutionsService.saveInstitution(payload);
            if (response.status >= 200 && response.status <= 300) {
                await activityTrailService.saveActivityTrail({
                    actionType: "Add Institution",
                    description: `Added new Institution  ${payload.name}`,
                });
                toast.info(`Institution ${payload.name} created successfully! `, {
                    autoClose: 6000,
                    type: toast.TYPE.DEFAULT,
                    hideProgressBar: false,
                });
            } else {
                return false;
            }
            this.setState({ submitting: false });
            onReloadFieldsCB();
            return true;
        } catch (error) {
            // console.log("error: ", error.response.data);
            this.setState({ submitting: false });
            return false;
        }
    }

    render() {
        const {
            errors,
            validated,
            submitting,
            sponsorBanks,
            //   bankoneInstitution,
            //   searchingExternalInstitutionCode,
        } = this.state;
        return (
            <React.Fragment>
                <div className="container-fluid relative animatedParent animateOnce">
                    <div className="animated fadeInUpShort go">
                        <div className="row my-3 mx-2">
                            <h3>
                                <b>Add Institution</b>
                            </h3>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <AddInstitutionForm
                                    initialValues={{
                                        institutionName: "",
                                        shortName: "",
                                        cbnCode: "",
                                        category: "",
                                        sponsorBank: "",
                                        externalInstitutionCode: "",
                                        disputeMailGroup: "",
                                        disputePhoneNumber: "",
                                        mandateLimit: "",
                                        adminUsername: "",
                                        adminEmail: "",
                                        adminFirstName: "",
                                        adminLastName: "",
                                        adminOtherName: "",
                                        adminGender: "",
                                        adminPhonenumber: "",
                                        greyListCap: "",
                                        emailDomain: "",
                                        dmb: "false",
                                    }}
                                    FormComponent={({
                                        fields: {
                                            institutionName,
                                            shortName,
                                            cbnCode,
                                            category,
                                            sponsorBank,
                                            externalInstitutionCode,
                                            disputeMailGroup,
                                            disputePhoneNumber,
                                            mandateLimit,
                                            adminUsername,
                                            adminEmail,
                                            adminFirstName,
                                            adminLastName,
                                            adminOtherName,
                                            adminGender,
                                            adminPhonenumber,
                                            greyListCap,
                                            emailDomain,
                                            dmb,
                                        },
                                        onChange,
                                        onReloadFields,
                                        onHandleSubmit,
                                    }) => (
                                        <form action="#">
                                            <div className="card no-b">
                                                <div className="card-body">
                                                    <h5 className="card-title">INSTITUTION DETAILS</h5>
                                                    <div className="form-row">
                                                        <div className="col-md-12">
                                                            <div className="form-row">
                                                                <FormInputComponent
                                                                    id="institutionName"
                                                                    type="text"
                                                                    name="institutionName"
                                                                    placeholder=""
                                                                    divClass={6}
                                                                    labelIconClass={
                                                                        this.state.searchingExternalInstitutionCode === true
                                                                            ? "fas fa-spinner fa-spin orange-text mr-2"
                                                                            : ""
                                                                    }
                                                                    value={category === "BankOneOFI" ? this.state.institutionName : institutionName}
                                                                    required={true}
                                                                    disabled={category === "BankOneOFI" ? true : undefined}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                />
                                                                <FormInputComponent
                                                                    id="shortName"
                                                                    type="text"
                                                                    name="shortName"
                                                                    placeholder=""
                                                                    divClass={6}
                                                                    labelIconClass={
                                                                        this.state.searchingExternalInstitutionCode === true
                                                                            ? "fas fa-spinner fa-spin orange-text mr-2"
                                                                            : ""
                                                                    }
                                                                    value={category === "BankOneOFI" ? this.state.shortName : shortName}
                                                                    required={true}
                                                                    disabled={category === "BankOneOFI" ? true : undefined}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                />
                                                            </div>
                                                            <div className="form-row">
                                                                <SelectInputComponent
                                                                    id="category"
                                                                    name="institutionCategory"
                                                                    divClass={6}
                                                                    value={category}
                                                                    items={[
                                                                        {
                                                                            name: "Commercial Bank",
                                                                            value: "CommercialBank",
                                                                        },
                                                                        {
                                                                            name: "BankOne OFI",
                                                                            value: "BankOneOFI",
                                                                        },
                                                                        { name: "OFI", value: "OFI" },
                                                                    ]}
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                />
                                                                <SelectInputComponent
                                                                    id="dmb"
                                                                    name="dmb"
                                                                    divClass={6}
                                                                    value={dmb}
                                                                    items={[
                                                                        { name: "True", value: "true" },
                                                                        { name: "False", value: "false" },
                                                                    ]}
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                />
                                                            </div>
                                                            <div className="form-row">
                                                                <FormInputComponent
                                                                    id="greyListCap"
                                                                    type="number"
                                                                    name="maxGreyListedBVN"
                                                                    placeholder=""
                                                                    divClass={6}
                                                                    value={greyListCap}
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                />
                                                                <FormInputComponent
                                                                    id="disputeMailGroup"
                                                                    type="text"
                                                                    name="disputeMailGroup"
                                                                    placeholder=""
                                                                    divClass={6}
                                                                    value={disputeMailGroup}
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                />
                                                            </div>
                                                            <div className="form-row">
                                                                <FormInputComponent
                                                                    id="disputePhoneNumber"
                                                                    type="number"
                                                                    name="disputeContactNumber"
                                                                    placeholder=""
                                                                    divClass={6}
                                                                    value={disputePhoneNumber}
                                                                    labelIconClass="icon-phone mr-2"
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                />
                                                                {category === "CommercialBank" ? (
                                                                    <FormInputComponent
                                                                        id="cbnCode"
                                                                        type="text"
                                                                        name="cbnCode"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={cbnCode}
                                                                        labelIconClass="icon-certificate mr-2"
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                    />
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <SelectInputComponent
                                                                    id="mandateLimit"
                                                                    name="mandateLimit"
                                                                    divClass={6}
                                                                    value={mandateLimit}
                                                                    items={[
                                                                        { name: "10", value: "Ten" },
                                                                        { name: "100", value: "OneHundred" },
                                                                        { name: "200", value: "TwoHundred" },
                                                                        // { name: "500", value: "FiveHundred" },
                                                                        {
                                                                            name: "750",
                                                                            value: "SevenHundredAndFifty",
                                                                        },
                                                                        { name: "Unlimited", value: "Unlimited" },
                                                                    ]}
                                                                    required={true}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                />
                                                            </div>
                                                            <div className="form-row">
                                                                {category === "BankOneOFI" ? (
                                                                    <FormInputComponent
                                                                        id="externalInstitutionCode"
                                                                        type="text"
                                                                        name="externalInstitutionCode"
                                                                        placeholder=""
                                                                        divClass={6}
                                                                        value={externalInstitutionCode}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                        onBlur={(e) => this.handleOnBlurExternalInstitutionCode(e.target.value)}
                                                                    />
                                                                ) : (
                                                                    ""
                                                                )}
                                                                {category.includes("OFI") ? (
                                                                    <SelectInputComponent
                                                                        id="sponsorBank"
                                                                        name="sponsorBank"
                                                                        divClass={6}
                                                                        value={sponsorBank}
                                                                        items={sponsorBanks.map((item) => ({
                                                                            name: item.name,
                                                                            value: item.id,
                                                                        }))}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                    />
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                            <div className="form-row">
                                                                <FormInputComponent
                                                                    id="emailDomain"
                                                                    type="text"
                                                                    name="emailDomain"
                                                                    placeholder=""
                                                                    divClass={6}
                                                                    value={emailDomain}
                                                                    labelIconClass="icon-mail-envelope-closed2 mr-2"
                                                                    required={false}
                                                                    validated={validated}
                                                                    errors={errors}
                                                                    onChange={onChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="card-body">
                                                    <h5 className="card-title">ADMIN USER DETAILS</h5>
                                                    <div className="form-row">
                                                        <FormInputComponent
                                                            id="adminFirstName"
                                                            type="text"
                                                            name="adminFirstName"
                                                            placeholder="Enter First Name"
                                                            divClass={6}
                                                            value={adminFirstName}
                                                            required={true}
                                                            validated={validated}
                                                            errors={errors}
                                                            onChange={onChange}
                                                        />
                                                        <FormInputComponent
                                                            id="adminEmail"
                                                            type="email"
                                                            name="adminUserEmail"
                                                            placeholder=""
                                                            divClass={6}
                                                            value={adminEmail}
                                                            labelIconClass="icon-envelope-o mr-2"
                                                            required={true}
                                                            validated={validated}
                                                            errors={errors}
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                    <div className="form-row">
                                                        <FormInputComponent
                                                            id="adminLastName"
                                                            type="text"
                                                            name="adminLastName"
                                                            placeholder="Enter Last Name"
                                                            divClass={6}
                                                            value={adminLastName}
                                                            required={true}
                                                            validated={validated}
                                                            errors={errors}
                                                            onChange={onChange}
                                                        />
                                                        <FormInputComponent
                                                            id="adminPhonenumber"
                                                            type="number"
                                                            name="adminUserPhoneNumber"
                                                            placeholder=""
                                                            divClass={6}
                                                            value={adminPhonenumber}
                                                            labelIconClass="icon-phone mr-2"
                                                            required={true}
                                                            validated={validated}
                                                            errors={errors}
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                    <div className="form-row">
                                                        <FormInputComponent
                                                            id="adminUsername"
                                                            type="text"
                                                            name="adminUsername"
                                                            placeholder="Enter User Name"
                                                            divClass={6}
                                                            value={adminUsername}
                                                            required={true}
                                                            validated={validated}
                                                            errors={errors}
                                                            onChange={onChange}
                                                        />
                                                        <SelectInputComponent
                                                            id="adminGender"
                                                            name="adminUserGender"
                                                            divClass={6}
                                                            value={adminGender}
                                                            items={[
                                                                { name: "Male", value: "Male" },
                                                                { name: "Female", value: "Female" },
                                                            ]}
                                                            required={true}
                                                            validated={validated}
                                                            errors={errors}
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="card-body">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-sm btn-primary"
                                                        disabled={submitting}
                                                        onClick={(e) => {
                                                            onHandleSubmit(e, ActionTypes.SAVE, "Institution", () => {
                                                                this.onFormSubmit(
                                                                    {
                                                                        institutionName,
                                                                        shortName,
                                                                        cbnCode,
                                                                        category,
                                                                        sponsorBank,
                                                                        externalInstitutionCode,
                                                                        disputeMailGroup,
                                                                        disputePhoneNumber,
                                                                        mandateLimit,
                                                                        adminUsername,
                                                                        adminEmail,
                                                                        adminFirstName,
                                                                        adminLastName,
                                                                        adminOtherName,
                                                                        adminGender,
                                                                        adminPhonenumber,
                                                                        greyListCap,
                                                                        emailDomain,
                                                                        dmb,
                                                                    },
                                                                    onReloadFields
                                                                );
                                                            });
                                                        }}
                                                    >
                                                        {submitting === false ? (
                                                            <React.Fragment>
                                                                <i className="fas fa-lg fa-save mr-3" /> Save
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
                                                                <i className="fas fa-spin fa-circle-notch mr-3" /> Please wait...
                                                            </React.Fragment>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                ></AddInstitutionForm>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AddInstitution;
