import http from "../httpService";

const billingBaseAddress = process.env.REACT_APP_BILLING_BASEADDRESS;

export function retrieveRecovaFeeConfigById(id: string) {
  return http.get(
    billingBaseAddress + `RecovaFeeConfiguration/${id}`
  );
}

export function updateRecovaFeeConfig(id, feeConfig) {
  return http.put(
    billingBaseAddress + `RecovaFeeConfiguration/Edit/${id}`,
    feeConfig
  );
}

export function filterRecovaFeeConfig(serviceOffering: string, institutionCategory: string) {
  return http.get(
    billingBaseAddress +
    `RecovaFeeConfiguration?ServiceOffering=${serviceOffering}&InstitutionCategory=${institutionCategory}`
  );
}


export default {
  retrieveRecovaFeeConfigById,
  updateRecovaFeeConfig,
  filterRecovaFeeConfig,
};
