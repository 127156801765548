import React from "react";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import { toast } from "react-toastify";
import textFormatService from "../../services/utility/textFormatService";
import rolesService from "../../services/auth/rolesService";
import approvalRuleService from "../../services/approval/approvalRuleService";
import activityTrailService from "../../services/auditTrail/activityTrailService";
import approvableService from "../../services/approval/approvableService";
import swal from "sweetalert";
// import financialGroupService from "../../services/approval/financialGroupService";
import { Approvable } from "../approval/approvalComponents/approvable";
import AddRuleModal from "../approval/approvalComponents/addRuleModal";
import BaseFormComponent, {
  IBaseFormState,
} from "../../components/BaseFormComponent";
import approvalValidationService from "../../services/approval/approvalValidationService";

interface ApprovalRuleConfigFields { }

class ApprovalRuleConfigForm extends BaseFormComponent<ApprovalRuleConfigFields> { }

export interface IApprovalRuleConfigProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
  reloadItem: any;
}

export interface IApprovalRuleConfigState {
  // groups: any;
  group: any;
  item: any;
  reloadDetails: boolean;
  rules: any;
  maxLevel: number;
  userRoles: any;
  data: any;
  generatedRules: any;
  // currentGroup: number;
  key: number;
  showAddRuleModal: boolean;
  ownUpdate: boolean;
}

class ApprovalRuleConfig extends React.Component<
  IApprovalRuleConfigProps,
  IBaseFormState & IApprovalRuleConfigState
> {
  modalRef: any = null;
  constructor(props: IApprovalRuleConfigProps) {
    super(props);
    this.modalRef = React.createRef();
    this.state = {
      submitting: false,
      validated: false,
      reloadDetails: false,
      rules: [],
      maxLevel: 1,
      userRoles: [],
      data: {
        title: "",
        userRoleId: "",
        applicableAmount: 0,
        combinationNo: 0,
        level: 1,
      },
      item: {},
      errors: {},
      generatedRules: [],
      //   currentGroup: 0,
      group: {},
      key: 0,
      showAddRuleModal: false,
      ownUpdate: false,
    };
  }

  arrangeRules = (allRules) => {
    // console.log("Arranging")
    const maxLevel = Math.max(...allRules.map((o) => o.level), 0);
    let rules: any = [];
    for (let i = 1; i <= maxLevel; i++) {
      const rulesSet = allRules.filter((x) => x.level === i);
      rulesSet.sort(function (a, b) {
        if (a.combinationNo < b.combinationNo) return -1;
        if (a.combinationNo > b.combinationNo) return 1;
        return 0;
      });
      rules.push(rulesSet);
    }
    this.setState({ maxLevel, rules, ownUpdate: true });
    return rules;
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (nextProps.item["id"] && nextProps.item !== prevState.item) {
      const maxLevel = Math.max(...nextProps.item.rules.map((o) => o.level), 0);
      let rules: any = [];
      for (let i = 1; i <= maxLevel; i++) {
        const rulesSet = nextProps.item.rules.filter((x) => x.level === i);
        rulesSet.sort(function (a, b) {
          if (a.combinationNo < b.combinationNo) return -1;
          if (a.combinationNo > b.combinationNo) return 1;
          return 0;
        });
        rules.push(rulesSet);
      }
      //console.log("sorted rules: ", rules);
      return { maxLevel, rules, item: nextProps.item };
    }
    return null;
  }

  handleOnGenerateRule = async (e) => {
    e.preventDefault();
    const rule: any = {};
    const { data, generatedRules, userRoles } = this.state;
    // let updatedRules = [...this.state.rules];
    //alert(JSON.stringify(data));
    rule.role = userRoles.filter(role => role.id === data.userRoleId);
    // rule.role = await rolesService.retrieveRoleById(data.userRoleId);
    rule.roleId = data.userRoleId;
    rule.applicableAmount = data.applicableAmount;
    rule.tempId = textFormatService.generateGUID();
    rule.functionId = this.props.item.id;

    generatedRules.push(rule);
    this.setState({ generatedRules });
  };

  async componentDidMount() {
    const { errors } = this.state;
    try {
      const userRoles = await rolesService.retrieveAllRoles();
      this.setState({ userRoles: userRoles.data });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (ex.response && ex.response.status === 404) {
        // console.log(ex.response);
        errors.response = "Internal server error.";
        // console.log(errors);
        this.setState({ errors });
      }
    }
  }

  hasDuplicate = (newRule) => {
    // console.log(this.state.item.rules, newRule);
    var duplicates =
      this.state.item.workflowType === "Financial"
        ? this.state.item.rules.filter((x) => {
          return (
            newRule.Level === x.level &&
            x.roleID === parseInt(newRule.RoleId) &&
            x.financialGroupId === newRule.FinancialGroupId
          );
        })
        : this.state.item.rules.filter((x) => {
          return (
            newRule.Level === x.level && x.roleID === parseInt(newRule.RoleId)
          );
        });
    return duplicates.length > 0;
  };

  alreadyAdded = (newRule) => {
    var duplicates =
      this.state.item.workflowType === "Financial"
        ? this.state.item.rules.filter((x) => {
          return (
            x.roleID === parseInt(newRule.RoleId) &&
            x.financialGroupId === newRule.FinancialGroupId
          );
        })
        : this.state.item.rules.filter((x) => {
          return x.roleID === parseInt(newRule.RoleId);
        });
    return duplicates.length > 0;
  };

  handleSetGroup = (grp) => {
    // console.log("Handle set group called", grp);
    this.setState({ group: grp });
  };

  handleOnRuleDelete = async (rule) => {
    await approvalRuleService.removeApprovalRule(rule.id);
    toast.info(`Rule Removed successfully! `, {
      autoClose: 6000,
      type: toast.TYPE.DEFAULT,
      hideProgressBar: false,
    });
    // const approvableItem = await approvableService.retrieveApprovable(
    //   this.props.item.id
    // );
    // var approvable = approvableItem.data;

    // //console.log(approvable)
    // for (let index = 0; index < approvable.rules.length; index++) {
    //   // console.log("setting role: ", approvable.rules[index]);
    //   const role = await rolesService.retrieveRoleById(
    //     approvable.rules[index].roleID
    //   );
    //   approvable.rules[index].role = role.data;
    // }
    // this.setState({
    //   item: approvable,
    //   reloadDetails: true,
    //   key: Math.random(),
    // });
    // this.arrangeRules(approvable.rules);
    this.props.reloadItem(this.props.item);
    activityTrailService.saveActivityTrail({
      actionType: "Removed Rule",
      description: `Removed Rule ${rule.id}`,
    });
  };

  handleOnGroupAdd = (nextGroup) => {
    //console.log(this.state)
    //this.setState({});
  };

  //   hideOrShow = (visibilty) => {
  //     // this.setState({ show: visibilty });
  //     // console.log(visibilty);
  //   };
  toggleAddRuleModal = (
    title,
    userRoleId,
    combNo,
    inputLevel,
    amount,
    performSave
  ) => {
    let item = { ...this.state.data };
    item.title = title;
    item.userRoleId = userRoleId;
    item.combinationNo = combNo;
    item.level = inputLevel;
    item.applicableAmount = amount;
    // console.log("item", item);
    // if (performSave) {
    //   //save
    //   this.setState({ data: item });
    // } else {
    //   this.setState({ data: item });
    // }
    this.setState({ data: item, showAddRuleModal: !this.state.showAddRuleModal });
    if (performSave) {
      this.state.data.userRoleId = userRoleId;
      this.submit(this.state.data);
    }
    // console.log("set state:", this.state);
  };
  //refresh = state => this.setState(state||{});
  itemEvents = {
    handleOnGenerateRule: this.handleOnGenerateRule,
    handleOnRuleDelete: this.handleOnRuleDelete,
    handleSetGroup: this.handleSetGroup,
    handleGroupAdd: this.handleOnGroupAdd,
    // toggleVisibility: this.hideOrShow,
    addNewRule: this.toggleAddRuleModal,
  };

  async submit(data: any): Promise<boolean> {
    // console.log("Submitting Data", data);
    const rule: any = {};
    //   const { data } = this.state;
    rule.RoleId = data.userRoleId;
    rule.ApplicableAmount = data.applicableAmount;
    rule.CombinationNo = data.combinationNo;
    rule.Level = data.level;
    rule.FunctionId = this.props.item.id;
    // console.log("reached", rule);
    if (this.state.item.workflowType === "Financial") {
      if (typeof this.state.group !== "undefined") {
        rule["FinancialGroupId"] = this.state.group.id;
      } else {
        swal({
          text: "Creating a new rule on default group is not allowed. Create or select another Group",
          icon: "error",
        });
        return false;
      }
    }
    // console.log("reached bottom", rule);

    if (this.hasDuplicate(rule)) {
      swal({ text: "Similar rule already exists", icon: "error" });
      return false;
    }
    if (this.alreadyAdded(rule)) {
      swal({
        text: "Same Role can not approve multiple levels",
        icon: "error",
      });
      return false;
    }

    this.setState({ submitting: true });
    const response = await approvalRuleService.createApprovalRule(rule);

    toast.info(`Rule created successfully! `, {
      autoClose: 6000,
      type: toast.TYPE.DEFAULT,
      hideProgressBar: false,
    });

    // const approvableItem = await approvableService.retrieveApprovable(
    //   this.props.item.id
    // );
    this.setState({
      submitting: false,
      // reloadDetails: true,
      validated: false,
      // item: approvableItem.data,
    });
    // var approvable = approvableItem.data;
    // //console.log(approvable.rules)
    // for (let index = 0; index < approvable.rules.length; index++) {
    //   // console.log("setting role: ", approvable.rules[index]);
    //   const role = await rolesService.retrieveRoleById(
    //     approvable.rules[index].roleID
    //   );
    //   approvable.rules[index].role = role.data;
    // }
    // this.arrangeRules(approvable.rules);
    //console.log(approvable.financialGroups, response.data)
    // if (response.data.financialGroupId !== 0) {
    //   var x = approvable.financialGroups.filter(
    //     (s) => s.id === response.data.financialGroupId
    //   );
    //   if (x.length > 0) this.handleSetGroup(x[0]);
    // }
    // this.setState({ reloadDetails: true });
    // this.setState({ key: Math.random() });

    this.props.reloadItem(this.props.item);
    activityTrailService.saveActivityTrail({
      actionType: "Added Rule",
      description: `Added new Rule ${response.data.id}`,
    });
    return true;
  }

  render() {
    let { item, showEditModal, toggleEditModal } = this.props;
    let { data, rules, reloadDetails } = this.state;
    data.userRoleId = "";

    return (
      <React.Fragment>
        <Modal
          size="xl"
          backdrop="static"
          show={showEditModal}
          onHide={toggleEditModal}
          ref={this.modalRef}
        >
          <Modal.Header>
            <Modal.Title>
              <h5>
                <i className="fas fa-tasks mr-3"></i>
                Approval Rule -{" "}
                <span className="font-weight-bold">{item.functionName}</span>
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Approvable
              rules={rules}
              item={this.state.item}
              events={this.itemEvents}
              viewing={false}
              key={this.state.key}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              variant="outline-danger"
              onClick={() => toggleEditModal(reloadDetails, item)}
            >
              <i className="fas fa-times mr-3" />
              Close
            </Button>
            {/* <Button
              size="sm"
              variant="outline-primary"
              onClick={() =>
                this.toggleAddRuleModal(
                  data.userRoleId,
                  data.combinationNo,
                  data.level,
                  data.applicableAmount,
                  false
                )
              }
            >
              <i className="fas fa-plus mr-3" />
              test
            </Button> */}
          </Modal.Footer>
        </Modal>
        <AddRuleModal
          userRoles={this.state.userRoles}
          data={this.state.data}
          showAddRuleModal={this.state.showAddRuleModal}
          toggleAddRuleModal={this.toggleAddRuleModal}
        />
      </React.Fragment>
    );
  }
}

export default ApprovalRuleConfig;
