import React, { Component } from "react";
import _ from "lodash";
import activityTrailService from "../../../../../../services/auditTrail/activityTrailService";
import { toast } from "react-toastify";
import { decodeToken } from "react-jwt";
import BaseFormComponent, { IBaseFormState } from "../../../../../../components/BaseFormComponent";
import DetailItem from "../../../../../../components/detailItem";
import FormInputComponent from "../../../../../../components/formInputComponent";
import SelectInputComponent, { ISelectItems } from "../../../../../../components/formSelectComponent";
import { getCurrentUserSession, getCurrentUsersRoles } from "../../../../../../services/auth/authService";
import textFormatService from "../../../../../../services/utility/textFormatService";
import DocumentPreviewer from "../../../../../dispute/documentPreviewer";
import supportingDocumentService, { getSupportingDocumentsByLoanReference } from "./../../../../../../services/mandate/supportingDocumentService";
import { ActionTypes } from "../../../../../../enums/actionTypes";
import loanRequestService from "../../../../../../services/hybridAppraisal/loanRequestService";
import hybridAppraisalValidationService from "../../../../../../services/hybridAppraisal/hybridAppraisalValidationService";
import miscService from "../../../../../../services/hybridAppraisal/miscService";
import thirdPartyService from "../../../../../../services/hybridAppraisal/thirdPartyService";

interface DocumentCollectionFields {
    accountNumber: string;
    bankName: string;
    accountType: string;
    accountStatementPeriod: string;
    statementType: string;
    documentType: string;
}

class DocumentCollectionForm extends BaseFormComponent<DocumentCollectionFields> { }

interface DocumentCollectionProps {
    values: any;
    reloadItem: any;
    mandatoryFields: any;
}

interface DocumentCollectionState {
    item: any;
    submitting: boolean;
    validated: boolean;
    showPreview: boolean;
    uploadingDocument: boolean;
    document: any;
    docToUpload: any;
    data: {
        comment: string;
        docToUploadType: string;
    };
    errors: any;
    supportingDocuments: any;
    allDocuments: any;
    ownUpdate: boolean;
    autoGenerate: boolean;
    bankDocuments: any;
    templateUri: string;
}

class FinancialAnalysis extends Component<
    DocumentCollectionProps,
    IBaseFormState & DocumentCollectionState
> {
    constructor(props: DocumentCollectionProps) {
        super(props);
        this.state = {
            item: {},
            submitting: false,
            validated: false,
            showPreview: false,
            uploadingDocument: false,
            document: {},
            docToUpload: {},
            data: {
                comment: "",
                docToUploadType: "",
            },
            errors: {},
            supportingDocuments: [],
            allDocuments: [],
            ownUpdate: false,
            autoGenerate: true,
            bankDocuments: [],
            templateUri: process.env.REACT_APP_HYBRIDAPPRAISAL_BASEADDRESS + `Misc/DownloadExcelInputTemplate`
        };
    }

    static getDerivedStateFromProps(
        nextProps: DocumentCollectionProps,
        prevState: DocumentCollectionState
    ) {
        if (prevState.ownUpdate) {
            return {
                ownUpdate: false,
            };
        }
        if (_.isNull(nextProps.values)) {
            return null;
        }
        return {
            item: nextProps.values
        };
    }

    async fetchSupportingDocument(first) {
        const { values } = this.props;
        // const supportingDocuments = await getSupportingDocumentsByLoanReference(
        //   JSON.parse(JSON.parse(JSON.parse(values.jsonData)).LoanDetails).AccountNumber,
        //   values.id
        // );

        const BankStatement = await miscService.GetProcessedBankStatement(values.id);
        const BankStatementDetail = BankStatement.data

        // console.log(BankStatementDetail)


        let reference = `hyb_bs_${values.requestIdentifier}`
        const supportingDocuments = await getSupportingDocumentsByLoanReference(
            reference,
            values.id
        );
        // console.log(supportingDocuments.data)
        if (first) {
            this.setState({ supportingDocuments: supportingDocuments.data, allDocuments: values.documents ? values.documents : [], bankDocuments: BankStatementDetail ? BankStatementDetail : [], ownUpdate: true })
        } else {
            this.setState({ supportingDocuments: supportingDocuments.data, bankDocuments: BankStatementDetail ? BankStatementDetail : [], ownUpdate: true })
        }
    }

    async componentDidMount() {
        await this.fetchSupportingDocument(true)
    }

    onFormSubmit(fields: DocumentCollectionFields, onReloadFieldsCB: any, channel: string): boolean {
        try {
            console.log(this.props.mandatoryFields)
            this.setState(
                { errors: hybridAppraisalValidationService.validateBankStatementForm(fields, this.props.mandatoryFields), ownUpdate: true },
                () => {
                    // console.log(this.state.errors)
                    if (Object.keys(this.state.errors).length === 0) {
                        if (channel === "Upload") {
                            return this.submitUpload(fields, onReloadFieldsCB);
                        }
                        else if (channel === "MBS") {
                            // console.log("Got here")
                            return this.submitMBS(fields, onReloadFieldsCB);
                        }
                    }
                }
            );
            this.setState({ validated: true, ownUpdate: true });
            return false;
        } catch (error) {
            return false;
        }
    }

    async submitUpload(
        fields: DocumentCollectionFields,
        onReloadFieldsCB: any
    ): Promise<boolean> {
        try {
            const decodedToken = decodeToken(getCurrentUserSession().token || "");
            const payload: any = {};

            const loanRequest = await loanRequestService.retrieveById(this.props.values.id);

            const allDocuments = [...this.state.allDocuments]
            const bankDocuments = [...this.state.bankDocuments]

            let reference = `hyb_bs_${this.props.values.requestIdentifier}`
            const docpayload: any = {};
            docpayload.institutionId = decodedToken?.["InstitutionId"];
            docpayload.document = "";
            docpayload.name = this.state.docToUpload.name.split(".")[0];
            // payload.loanReference = JSON.parse(JSON.parse(JSON.parse(this.props.values.jsonData)).LoanDetails).AccountNumber
            docpayload.loanReference = reference
            docpayload.customerID = this.props.values.id;
            docpayload.disputeId = 0;
            docpayload.supportingDocumentType = "Others";
            docpayload.disputeConditionDocumentType = this.state.data.docToUploadType;
            docpayload.extensionType = this.state.docToUpload.name.split(".")[1].toUpperCase();

            // return false;

            // var response = await supportingDocumentService.saveSupportingDocument(
            //     docpayload
            // );
            // if (response.status >= 200 && response.status <= 300) {

            payload.institutionCode = decodedToken?.["InstitutionCode"];
            payload.loanRequestId = this.props.values.id
            payload.bankStatement = await textFormatService.getBase64StringFromFile(this.state.docToUpload);
            payload.accountNumber = fields.accountNumber
            payload.accountType = fields.accountType
            payload.bankName = fields.bankName
            payload.statementType = fields.statementType ? fields.statementType : "Customer"
            payload.accountStatementPeriod = fields.accountStatementPeriod
            payload.mandateDoc = docpayload

            // console.log(payload)
            // this.setState({ uploadingDocument: true, submitting: true, ownUpdate: true });
            // return false;
            this.setState({ uploadingDocument: true, submitting: true, ownUpdate: true });
            var response = fields.documentType === "PDF" ?
                await miscService.ReadPDF(
                    payload
                ) :
                await miscService.ReadExcel(
                    payload
                );
            if (response.status >= 200 && response.status <= 300) {
                // console.log(response);
                if (response.data === "true") {
                    // console.log("Got here")
                    allDocuments.push({
                        name: "Bank Statement_" + fields.bankName,
                        category: "Bank Statement",
                        status: "Received",
                        expiryDate: "",
                        documentId: ""
                    });
                    // console.log("Got here")
                    bankDocuments.push({
                        documentId: "",
                        bankName: fields.bankName,
                        accountType: fields.accountType,
                        accountNumber: fields.accountNumber,
                        accountStatementPeriod: fields.accountStatementPeriod
                    });
                    // console.log(bankDocuments)
                    toast.info(`Bank Statement Uploaded successfully!`, {
                        autoClose: 6000,
                        type: toast.TYPE.DEFAULT,
                        hideProgressBar: false,
                    });
                }
                else {
                    toast.info(response.data, {
                        autoClose: 6000,
                        type: toast.TYPE.DEFAULT,
                        hideProgressBar: false,
                    });
                }
            }

            const loanRequestDetail = loanRequest.data;
            const loanPayload: any = { ...loanRequestDetail };
            loanPayload.documents = [...allDocuments]

            const loanResponse = await loanRequestService.updateLoanRequest(loanPayload);

            this.setState({ allDocuments, bankDocuments, uploadingDocument: false, submitting: false, ownUpdate: true });
            this.resetFields();
        } catch (error) {
            toast.error(error.response.data.Message);
            this.setState({ uploadingDocument: false, submitting: false, ownUpdate: true });
            return false;
        }
        finally {
            this.fetchSupportingDocument(false)
            return true;
        }
    };

    async submitMBS(
        fields: DocumentCollectionFields,
        onReloadFieldsCB: any
    ): Promise<boolean> {
        try {
            const decodedToken = decodeToken(getCurrentUserSession().token || "");
            const payload: any = {};

            // console.log(fields)


            payload.institutionCode = decodedToken?.["InstitutionCode"];
            payload.loanRequestId = this.props.values.id
            // payload.bankStatement = await textFormatService.getBase64StringFromFile(this.state.docToUpload);
            payload.accountNumber = fields.accountNumber
            payload.accountType = fields.accountType
            payload.bankName = fields.bankName
            payload.statementType = fields.statementType ? fields.statementType : "Customer"
            payload.accountStatementPeriod = fields.accountStatementPeriod

            // console.log(payload)
            // this.setState({ uploadingDocument: true, submitting: true, ownUpdate: true });
            // return false;
            this.setState({ uploadingDocument: true, submitting: true, ownUpdate: true });
            var response = await thirdPartyService.InitateBankStatementRequest(
                payload
            );
            if (response.status >= 200 && response.status <= 300) {
                toast.info(`Request to fetch bank statement has been sent to customer bank,
                    please advise customer to check their email`, {
                    autoClose: 6000,
                    type: toast.TYPE.DEFAULT,
                    hideProgressBar: false,
                });
            }
            else {
                toast.info(response.data, {
                    autoClose: 6000,
                    type: toast.TYPE.DEFAULT,
                    hideProgressBar: false,
                });
            }

            this.setState({ uploadingDocument: false, submitting: false, ownUpdate: true });
            this.resetFields();
        } catch (error) {
            toast.error(error.response.data.Message);
            this.setState({ uploadingDocument: false, submitting: false, ownUpdate: true });
            return false;
        }
        finally {
            return true;
        }
    };

    toggleShowPreview = () => {
        this.setState({ showPreview: !this.state.showPreview, ownUpdate: true });
    };

    handleOnDocumentSelect = async (document) => {
        try {
            var doc = await supportingDocumentService.retrieveById(document.id);
            document["document"] = doc.data.document;
            switch (document.extensionType) {
                case "PNG":
                case "JPG":
                case "GIF":
                    document[
                        "downloadname"
                    ] = `${document.name}_${document.loanReference}.jpg`;
                    document["data"] = `data:image/jpg;base64,${document.document}`;
                    document["payload"] = (
                        <img
                            src={document.data}
                            alt={document.name}
                            width="100%"
                            height="100%"
                            className="image"
                        />
                    );
                    break;
                case "DOCX":
                case "PDF":
                    var data = `data:application/pdf;base64,${document.document}`;
                    document["payload"] = (
                        <object
                            width="100%"
                            height="500px"
                            data={data}
                            type="application/pdf"
                            className="internal"
                        >
                            <embed src={data} type="application/pdf" />
                        </object>
                    );
                    break;
                case "HTML":
                    document["payload"] = (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: atob(document.document),
                            }}
                        />
                    );
                    break;
                default:
                    break;
            }
            this.setState({ document, showPreview: true, ownUpdate: true });
        } catch (error) {
        }
    };

    generateExtensionIcon = (document) => {
        switch (document.extensionType) {
            case "PNG":
            case "JPG":
            case "GIF":
                return (
                    <i
                        className="fas fa-file-image cyan-text fa-lg"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.handleOnDocumentSelect(document)}
                    ></i>
                );
            case "PDF":
                return (
                    <i
                        className="fas fa-file-pdf red-text fa-lg"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.handleOnDocumentSelect(document)}
                    ></i>
                );
            case "DOC":
            case "DOCX":
                return (
                    <i
                        className="fas fa-file-word blue-text fa-lg"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.handleOnDocumentSelect(document)}
                    ></i>
                );
            default:
                return (
                    <i
                        className="fas fa-file-pdf red-text fa-lg"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.handleOnDocumentSelect(document)}
                    ></i>
                );
        }
    };

    resetFields() {
        const data = { ...this.state.data };
        data.comment = "";
        data.docToUploadType = "";
        this.setState({ data, validated: false, document: {}, docToUpload: {}, submitting: false, errors: [], ownUpdate: true });
    }

    handleDeleteDocument = async (document, bankstatementId) => {
        try {
            this.setState({ uploadingDocument: true, submitting: true, ownUpdate: true });
            const decodedToken = decodeToken(getCurrentUserSession().token || "");
            var response = await supportingDocumentService.deleteSupportingDocument(
                document.id
            );

            var response2 = await miscService.DeleteProcessedBankStatement(bankstatementId);
            activityTrailService.saveActivityTrail({
                actionType: "Remove Supporting Document",
                description: `Remove Supporting Document [${document.name}] on Loan Request ${this.props.values.id}`,
            });
            this.setState({ uploadingDocument: false, submitting: false, ownUpdate: true });
            this.resetFields();
        } catch (error) {
            toast.error(error.response.data.Message);
            this.setState({ uploadingDocument: false, submitting: false, ownUpdate: true });
        }
        finally {
            this.fetchSupportingDocument(false)
        }
    };

    handleUploadDocument = async (fields: DocumentCollectionFields) => {
        try {
            const decodedToken = decodeToken(getCurrentUserSession().token || "");
            const payload: any = {};
            payload.institutionId = decodedToken?.["InstitutionId"];
            payload.document = await textFormatService.getBase64StringFromFile(this.state.docToUpload);
            payload.name = this.state.docToUpload.name.split(".")[0];
            payload.loanReference = JSON.parse(JSON.parse(JSON.parse(this.props.values.jsonData)).LoanDetails).AccountNumber
            payload.customerID = this.props.values.id;
            payload.disputeId = 0;
            payload.supportingDocumentType = "Others";
            payload.disputeConditionDocumentType = this.state.data.docToUploadType;
            payload.extensionType = this.state.docToUpload.name.split(".")[1].toUpperCase();
            this.setState({ uploadingDocument: true, submitting: true, ownUpdate: true });
            try {
                var response = await supportingDocumentService.saveSupportingDocument(
                    payload
                );
            } catch (error) {
                throw error;
            }
            activityTrailService.saveActivityTrail({
                actionType: "Upload Supporting Document",
                description: `Upload Supporting Document on loan request ${this.props.values.id}`,
            });
            this.setState({ uploadingDocument: false, submitting: false, ownUpdate: true });
            this.resetFields();
        } catch (error) {
            toast.error(error.response.data.Message);
            this.setState({ uploadingDocument: false, submitting: false, ownUpdate: true });
        }
        finally {
            this.fetchSupportingDocument(false)
        }
    };

    onDocumentChange = (e) => {
        e.preventDefault();
        if (e.target.files[0] === undefined) {
            return;
        }
        if (e.target.files[0].size > 3000000) {
            toast.error(
                "Please confirm that the size of the document is less than 3MB"
            );
        } else if (
            !["pdf"].includes(
                e.target.files[0].name.split(".")[1]
            ) && !["xlsx"].includes(e.target.files[0].name.split(".").slice(-1)[0])
        ) {
            toast.error(
                "Please confirm the uploaded document is a pdf/excel"
            );
        } else {
            this.setState({ docToUpload: e.target.files[0], autoGenerate: false, ownUpdate: true });
        }
    };

    getRequiredFields(title): boolean {
        const { mandatoryFields } = this.props;
        var required = mandatoryFields?.inputFields?.filter(x => x.fieldName === title)[0]?.mandatory;
        if (required === undefined) {
            return false;
        }
        return required;
    }

    render() {
        const {
            values,
            mandatoryFields
        } = this.props;
        const {
            validated,
            data,
            errors,
            submitting,
            docToUpload,
            uploadingDocument,
            supportingDocuments,
            allDocuments,
            bankDocuments,
            ownUpdate
        } = this.state;

        const productDocuments = values.loanProduct.productDocuments;
        let documentNameInputData: ISelectItems[] = productDocuments ? productDocuments.map(item => {
            return { name: item.name, value: item.name }
        }) : [];

        const bankNameInputType: ISelectItems[] = ["LenderBank", "Access Bank", "Citibank", "Eco Bank", "FCMB",
            "Fidelity Bank", "First Bank", "FSDH Merchant Bank", "GT Bank", "Heritage Bank", "Keystone Bank", "Lotus Bank",
            "Parallex Bank", "Polaris Bank Limited", "Providus Bank", "Stanbic IBTC Bank", "Standard Chattered",
            "Sterling Bank", "SunTrust", "UBA", "Union Bank", "Unity Bank", "VFD MFB", "Wema Bank", "Zenith Bank"].map(item => {
                return { name: item, value: item }
            });

        const accountTypeInputData: ISelectItems[] = ["Savings", "Current", "Credit"].map(item => {
            return { name: item, value: item }
        });
        const statementTypeInputData: ISelectItems[] = ["Customer", "Guarantor"].map(item => {
            return { name: item, value: item }
        });

        const documentTypeInputData: ISelectItems[] = ["PDF", "EXCEL"].map(item => {
            return { name: item, value: item }
        });

        const accountPeriodInputData: ISelectItems[] = ["1", "3", "6", "9", "12"].map(item => {
            return { name: item, value: item }
        });


        return (
            <DocumentCollectionForm
                initialValues={{
                    accountNumber: "",
                    bankName: "",
                    accountType: "",
                    accountStatementPeriod: "",
                    statementType: "",
                    documentType: ""
                }}
                FormComponent={({
                    fields: {
                        accountNumber,
                        bankName,
                        accountType,
                        accountStatementPeriod,
                        statementType,
                        documentType
                    },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                }) => (
                    <>
                        <div className="form-row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header clear-fix">
                                        <h6
                                            className="float-left"
                                            style={{ marginBottom: "0px" }}
                                        >
                                            <b>Bank Statement</b>
                                        </h6>
                                    </div>
                                    <div className="card-body">
                                        <div className="card-content">
                                            <div className="row">
                                                <div className="col-md-6 col-sm-6">
                                                    <h6>Rules</h6>
                                                    <ul>
                                                        <li className="brown-text small">
                                                            All fields are mandatory.
                                                        </li>
                                                        <li className="brown-text small">
                                                            All Amounts should be in figures, and zero (0) where not applicable
                                                        </li>
                                                        <li className="brown-text small">
                                                            There should be no special characters in the debit and credit cells except point (.)
                                                        </li>
                                                        {/* <li className="brown-text small">
                                                Maximum size for each document is <b style={{ color: "red" }}>500 Kb</b>.
                                            </li> */}
                                                        <li className="brown-text small">
                                                            Click{" "}
                                                            <b>
                                                                <a href={this.state.templateUri} target="_blank" rel="noopener noreferrer">
                                                                    Here
                                                                </a>
                                                            </b>{" "}
                                                            to download a sample template for Excel Bank Statement upload.
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-row">
                                            {/* <FormInputComponent
                                        id="bankName"
                                        name="bankName"
                                        type="text"
                                        placeholder=""
                                        divClass={6}
                                        value={bankName}
                                        // items={documentNameInputData}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={async (id, value) => {
                                            await onChange(id, value);
                                            // await onChange('documentCategory', productDocuments.filter(doc => doc.name === value)[0].category);
                                        }} /> */}
                                            <SelectInputComponent
                                                id="bankName"
                                                name="bankName"
                                                divClass={6}
                                                value={bankName}
                                                items={bankNameInputType}
                                                required={this.getRequiredFields("bankName")}
                                                validated={validated}
                                                errors={errors}
                                                onChange={onChange} />
                                            <FormInputComponent
                                                id="accountNumber"
                                                name="accountNumber"
                                                type="number"
                                                placeholder=""
                                                divClass={6}
                                                value={accountNumber}
                                                required={this.getRequiredFields("bankAccountNumber")}
                                                validated={validated}
                                                errors={errors}
                                                onChange={onChange} />
                                            <SelectInputComponent
                                                id="accountType"
                                                name="accountType"
                                                divClass={6}
                                                value={accountType}
                                                items={accountTypeInputData}
                                                required={this.getRequiredFields("bankAccountType")}
                                                validated={validated}
                                                errors={errors}
                                                onChange={onChange} />
                                            <SelectInputComponent
                                                id="accountStatementPeriod"
                                                name="accountStatementPeriod"
                                                divClass={6}
                                                value={accountStatementPeriod}
                                                items={accountPeriodInputData}
                                                required={this.getRequiredFields("accountStatementPeriod")}
                                                validated={validated}
                                                errors={errors}
                                                onChange={onChange} />
                                            <SelectInputComponent
                                                id="statementType"
                                                name="statementType"
                                                divClass={6}
                                                value={statementType}
                                                items={statementTypeInputData}
                                                required={this.getRequiredFields("accountType")}
                                                validated={validated}
                                                errors={errors}
                                                onChange={onChange} />
                                            <SelectInputComponent
                                                id="documentType"
                                                name="documentType"
                                                divClass={6}
                                                value={documentType}
                                                items={documentTypeInputData}
                                                required={this.getRequiredFields("accountType")}
                                                validated={validated}
                                                errors={errors}
                                                onChange={onChange} />
                                        </div>
                                        {(getCurrentUsersRoles().includes("updatedataentry") && (
                                            <div className="form-row mt-5">
                                                <DetailItem
                                                    label="Upload Bank Statement"
                                                    value={<div className="input-group">
                                                        <div className="custom-file">
                                                            <input
                                                                type="file"
                                                                className="custom-file-input"
                                                                id="docToUpload"
                                                                name="docToUpload"
                                                                aria-describedby="inputGroupFileAddon01"
                                                                onChange={(e) => this.onDocumentChange(e)}
                                                                required />
                                                            <label
                                                                className="custom-file-label"
                                                                htmlFor="docToUpload"
                                                            >
                                                                {_.isEmpty(docToUpload.name)
                                                                    ? "Choose File"
                                                                    : docToUpload.name}
                                                            </label>
                                                        </div>
                                                    </div>}
                                                    labelSize={3}
                                                    valueSize={9} />
                                            </div>
                                        ))}
                                        {/* Add Document */}
                                        <div className="form-row">
                                            <div className="my-1 mx-2">
                                                {_.isEmpty(docToUpload.name) ? (
                                                    ""
                                                ) : (
                                                    <React.Fragment>
                                                        {uploadingDocument === true ? (
                                                            <span style={{ float: "right" }}>
                                                                <i className="fas fa-spinner fa-spin fa-lg m-3"></i>
                                                            </span>
                                                        ) : (
                                                            <>
                                                                {(getCurrentUsersRoles().includes("updatedataentry") && (
                                                                    <button
                                                                        className="btn btn-sm btn-outline-primary my-4"
                                                                        style={{ cursor: "pointer" }}
                                                                        type="button"
                                                                        // onClick={this.handleUploadDocument}
                                                                        onClick={(e) => {
                                                                            onHandleSubmit(
                                                                                e,
                                                                                ActionTypes.SAVE,
                                                                                "Document Details",
                                                                                () => {
                                                                                    this.onFormSubmit(
                                                                                        {
                                                                                            accountNumber,
                                                                                            bankName,
                                                                                            accountType,
                                                                                            accountStatementPeriod,
                                                                                            statementType,
                                                                                            documentType
                                                                                        },
                                                                                        onReloadFields,
                                                                                        "Upload"
                                                                                    );
                                                                                }
                                                                            );
                                                                        }}
                                                                    >
                                                                        <React.Fragment>
                                                                            <i className="fas fa-cloud-upload-alt fa-lg mr-2" />{" "}
                                                                            Upload
                                                                        </React.Fragment>
                                                                    </button>
                                                                ))}
                                                            </>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>

                                        {this.state.autoGenerate && (getCurrentUsersRoles().includes("updatedataentry") && (
                                            uploadingDocument === true ? (
                                                <span style={{ float: "right" }}>
                                                    <i className="fas fa-spinner fa-spin fa-lg m-3"></i>
                                                </span>
                                            ) : (
                                                <button
                                                    className="btn btn-sm btn-outline-primary my-4"
                                                    style={{ cursor: "pointer" }}
                                                    type="button"
                                                    // onClick={this.handleUploadDocument}
                                                    onClick={(e) => {
                                                        onHandleSubmit(
                                                            e,
                                                            ActionTypes.SAVE,
                                                            "Document Details",
                                                            () => {
                                                                this.onFormSubmit(
                                                                    {
                                                                        accountNumber,
                                                                        bankName,
                                                                        accountType,
                                                                        accountStatementPeriod,
                                                                        statementType,
                                                                        documentType
                                                                    },
                                                                    onReloadFields,
                                                                    "MBS"
                                                                );
                                                            }
                                                        );
                                                    }}
                                                >
                                                    <React.Fragment>
                                                        <i className="fas fa-cloud-upload-alt fa-lg mr-2" />{" "}
                                                        Request Bank Statement
                                                    </React.Fragment>
                                                </button>

                                            )
                                        ))}

                                        {/* Document Table */}
                                        <div className=" form-row col-md-12">
                                            <div
                                                className="table-responsive text-nowrap"
                                                style={{
                                                    maxHeight: "150px",
                                                    overflowY: "scroll",
                                                }}
                                            >
                                                <table className="table table-hover table-content table-sm table-striped mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                }}
                                                            >
                                                                <strong>#</strong>
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                }}
                                                            >
                                                                <strong>Bank Name</strong>
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                }}
                                                            >
                                                                <strong>Account Number</strong>
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                }}
                                                            >
                                                                <strong>Account Type</strong>
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                }}
                                                            >
                                                                <strong>Statement Period</strong>
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                }}
                                                            >
                                                                <strong>Statement Uploaded</strong>
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                }}
                                                            ></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {supportingDocuments.map((item, idx) => (
                                                            <tr key={supportingDocuments.indexOf(item)}>
                                                                <td
                                                                    style={{
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                        fontSize: "small",
                                                                    }}
                                                                >
                                                                    {supportingDocuments.indexOf(item) + 1}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                        fontSize: "small",
                                                                    }}
                                                                >
                                                                    {bankDocuments.filter((doc, idy) => idy == idx)[0]?.bankName}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                        fontSize: "small",
                                                                    }}
                                                                >
                                                                    {bankDocuments.filter((doc, idy) => idy == idx)[0]?.accountNumber}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                        fontSize: "small",
                                                                    }}
                                                                >
                                                                    {bankDocuments.filter((doc, idy) => idy == idx)[0]?.accountType}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                        fontSize: "small",
                                                                    }}
                                                                >
                                                                    {bankDocuments.filter((doc, idy) => idy == idx)[0]?.accountStatementPeriod}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                        fontSize: "small",
                                                                    }}
                                                                >
                                                                    {this.generateExtensionIcon(item)}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                        fontSize: "small",
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="far fa-trash-alt ml-5 red-text fa-lg"
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => this.handleDeleteDocument(item, bankDocuments.filter((doc, idy) => idy == idx)[0]?.id)}
                                                                    ></i>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                {/* </div> */}
                                            </div>
                                            <DocumentPreviewer
                                                document={this.state.document}
                                                showPreview={this.state.showPreview}
                                                toggleShowPreview={this.toggleShowPreview} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            ></DocumentCollectionForm >
        );
    }
}

export default FinancialAnalysis;
