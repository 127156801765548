import http from "../httpService";
import axios from "axios";
import { getCurrentUserSession } from "../auth/authService";

const billingBaseAddress = process.env.REACT_APP_BILLING_BASEADDRESS;

export function filterPayableReportByInstitutionCode(
  institutionCode: string,
  pageSize: number,
  pageNumber: number
) {
  return http.get(
    billingBaseAddress +
    `FeePayment/Report/Payments/FilterWithPaging?InstitutionCode=${institutionCode}&PageSize=${pageSize}&PageNumber=${pageNumber}`
  );
}

export function retrievePayableReportById(id: string) {
  return http.get(
    billingBaseAddress + `FeePayment/Report/Payments/${id}`
  );
}

export function filterPaymentReportByInstitutionCode(
  institutionCode: string,
  pageSize: number,
  pageNumber: number
) {
  return http.get(
    billingBaseAddress +
    `FeePayment/Report/Settlement/FilterWithPaging?InstitutionCode=${institutionCode}&PageSize=${pageSize}&PageNumber=${pageNumber}`
  );
}

export function retrievePaymentReportById(id: string) {
  return http.get(
    billingBaseAddress + `FeePayment/Report/Settlement/${id}`
  );
}

export function retrieveCummulativeAmountPayableReport(data) {
  return http.post(
    billingBaseAddress + `FeePayment/Report/GetInstitutionServiceOfferingValue`,
    data
  );
}

export function retrieveCummulativeAmountPaymentReport(data) {
  return http.post(
    billingBaseAddress + `FeePayment/Report/Paid/GetInstitutionServiceOfferingValue`,
    data
  );
}

export function downloadPayableReport(institutionCode: string, type: string) {
  var url = billingBaseAddress + `FeePayment/Report/Payments/Download?InstitutionCode=${institutionCode}&DownloadType=${type}`;
  axios({
    url: url,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    if (type === 'EXCEL') {
      link.setAttribute("download", `PayableReport.xlsx`);
    }
    else {
      link.setAttribute("download", `PayableReport.${type}`);
    }
    document.body.appendChild(link);
    link.click();
  });
}

export function downloadPaymentReport(institutionCode: string, type: string) {
  var url = billingBaseAddress + `FeePayment/Report/Settlement/Download?InstitutionCode=${institutionCode}&DownloadType=${type}`;
  axios({
    url: url,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    if (type === 'EXCEL') {
      link.setAttribute("download", `PaymentReport.xlsx`);
    }
    else {
      link.setAttribute("download", `PaymentReport.${type}`);
    }
    document.body.appendChild(link);
    link.click();
  });
}

export default {
  filterPayableReportByInstitutionCode,
  retrievePayableReportById,
  filterPaymentReportByInstitutionCode,
  retrievePaymentReportById,
  retrieveCummulativeAmountPayableReport,
  retrieveCummulativeAmountPaymentReport,
  downloadPayableReport,
  downloadPaymentReport
};
