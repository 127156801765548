import { axiosSweepInstance as axiosInstance } from "../configs";

class LoanUpdate{

    repushLoanBalanceUpdateTransaction(institutionCode:string, recovaReferenceNo:string){
        return axiosInstance.get(`/LoanUpdate/RepushLoanBalanceUpdateTransaction/${institutionCode}`, {
            params:{ 
                recovaReferenceNo,
            }
        });
    } 

    validateRepushLoanBalanceUpdateTransaction(institutionCode:string, recovaReferenceNo:string){
        return axiosInstance.get(`/ValidateLoanUpdate/RepushLoanBalanceUpdateTransaction/${institutionCode}`, {
            params:{ 
                recovaReferenceNo,
            }
        });
    } 
}

const loanUpdateService = new LoanUpdate();

export default loanUpdateService;