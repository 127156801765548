import http from "../httpService";

const billingBaseAddress = process.env.REACT_APP_BILLING_BASEADDRESS;

export function savePaymentConfig(paymentConfig) {
    return http.post(
        billingBaseAddress + `PaymentConfiguration`,
        paymentConfig
    );
}

export function retrievePaymentConfig() {
    return http.get(
        billingBaseAddress + `PaymentConfiguration/`
    );
}

export function retrievePaymentConfigById(id: string) {
    return http.get(
        billingBaseAddress + `PaymentConfiguration/${id}`
    );
}

export function updatePaymentConfig(id: string, paymentConfig) {
    return http.put(
        billingBaseAddress + `PaymentConfiguration/configuration/Edit/${id}`,
        paymentConfig
    );
}

export function filterPaymentConfig(serviceOffering: string, institutionCategory: string) {
    return http.get(
        billingBaseAddress +
        `PaymentConfiguration/fees/filter?ServiceOffering=${serviceOffering}&institutionCategory=${institutionCategory}`
    );
}


export default {
    savePaymentConfig,
    retrievePaymentConfig,
    retrievePaymentConfigById,
    updatePaymentConfig,
    filterPaymentConfig
};
