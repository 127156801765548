import * as React from "react";
import { Fragment } from "react";
import _ from "lodash";
import swal from "sweetalert";
import { filterFieldData } from "./tableComponents/itemsTable";
import LoaderModal from "./loaderModal";
import auditTrailMapperService from "../services/auditTrail/auditTrailMapperService";
import { getCurrentUserSession } from "../services/auth/authService";
import { TrailType } from "../enums/trailType";
import auditTrailService from "../services/auditTrail/auditTrailService";
import { decodeToken } from "react-jwt";

export interface IListPayLoadProps {
  showTableFilterModal: boolean;
  tableItems: any;
  data: IFilterData;
  filterValueInput: filterFieldData;
}

export interface IDetailsModalProps {
  item: any;
  showDetailsModal: boolean;
  showEditModal: boolean;
  showTrailModal: boolean;
  jsonBefore: any;
  jsonAfter: any;
  fetchingTrail: boolean;
  trailMapper: any;
  trailItem: any;
  //   updatingStatis: boolean;
}

export interface ToggleTrailHandler {
  onToggleTrailModal: OnToggleTrailModal;
  fetchTrailItem: OnFetchTrailItem;
}

export interface ToggleDetailsHandler {
  onToggleDetailsModal: OnToggleDetailsModal;
}

export interface ToggleTableFilterModalHandler {
  onToggleTableFilterModal: OnToggleTableFilterModal;
}

export interface ToggleEditModalHandler {
  onToggleEditModal: OnToggleEditModal;
}
export interface FilterRemoveHandler {
  onHandleFilterItemRemove: onHandleFilterItemRemove;
}

export type OnToggleDetailsModal = () => void;
export type OnToggleTableFilterModal = (
  itemParams: string[],
  toSave: boolean
) => void;
export type OnToggleEditModal = () => void;
export type OnToggleTrailModal = (item: any) => void;
export type OnFetchTrailItem = (item: any) => void;
export type onHandleFilterItemRemove = (key: string) => void;

export interface BaseListComponentProps<T> {
  initialValues: BaseListComponentState;
  pageChangeHandler: (currentPage: number, pageSize: number, data: IFilterData) => void;
  tableItems: any;
  fetchingDetail: Boolean;
  item: any;
  ListPayLoad: React.ComponentType<
    IListPayLoadProps &
      ToggleDetailsHandler &
      ToggleTableFilterModalHandler &
      FilterRemoveHandler
  >;
  DetailsModal: React.ComponentType<
    IDetailsModalProps &
      ToggleDetailsHandler &
      ToggleEditModalHandler &
      ToggleTrailHandler
  >;
  updateState?: boolean;
  // EditModal?: React.ComponentType;
  // TrailModal?: React.ComponentType;
  // TableFilterModal?: React.ComponentType;
}

interface IFilterTuple {
  item1: string;
  item2: string;
}
export interface IFilterData {
  [key: string]: IFilterTuple;
}

export interface BaseListComponentState {
  fetching: boolean;
  showDetailsModal: boolean;
  showEditModal: boolean;
  showTrailModal: boolean;
  showTableFilterModal: boolean;
  item: any;
  tableItems: any;
  data: IFilterData;
  filterValueInput: filterFieldData;
  jsonBefore?: any;
  jsonAfter?: any;
  fetchingTrail?: boolean;
  entityName?: string;
  trailMapper?: any;
  trailItem?: any;
}

class BaseListComponent<T> extends React.Component<
  BaseListComponentProps<T>,
  BaseListComponentState
> {
  constructor(props: BaseListComponentProps<T>) {
    super(props);
    this.state = props.initialValues;
    // console.log("Inside Constructor", this.state);
  }

  async componentWillReceiveProps(nextProps: BaseListComponentProps<T>) {
    if (nextProps.updateState === true) {
      // console.log("reacehd thte sklda", this.state);
      this.state = nextProps.initialValues;
    }
  }

  public onToggleDetailsModal: OnToggleDetailsModal = () => {
    this.setState({ showDetailsModal: !this.state.showDetailsModal });
    // console.log("toggle details modal ", this.state.showDetailsModal);
    if (this.state.showDetailsModal === true) {
      this.setState({ item: {} });
    }
    // if (show) {
    //   this.setState({ showDetailsModal: !this.state.showDetailsModal });
    // } else {
    //   this.setState({ showDetailsModal: show });
    // }
  };

  public onToggleTableFilterModal: OnToggleTableFilterModal = (
    itemParams: string[],
    toSave: boolean
  ) => {
    // console.log("toggle table filter modal", itemParams);
    if (toSave) {
      const filteredItems = { ...this.state.data };
      let filterInput = [...this.state.filterValueInput];
      Object.keys(filteredItems).forEach((item) => {
        if (item === itemParams[0]) {
          filteredItems[item].item1 =
            itemParams[2] === "" ? "like" : itemParams[1];
          filteredItems[item].item2 = itemParams[2];

          filterInput = filterInput.map((inp) =>
            inp.name !== itemParams[0] ? inp : { ...inp, value: itemParams[2] }
          );
        }
      });
      // console.log(filterInput);
      this.setState(
        {
          data: filteredItems,
          filterValueInput: filterInput,
        },
        () => {
          this.props.pageChangeHandler(-1, -1, this.state.data);
        }
      );
    }
    this.setState({
      showTableFilterModal: !this.state.showTableFilterModal,
    });
  };

  public onHandleFilterItemRemove = (key: string) => {
    const filteredItems = { ...this.state.data };
    let filterInput = [...this.state.filterValueInput];
    Object.keys(filteredItems).forEach((item) => {
      if (item === key) {
        filteredItems[key].item2 = "";
        filteredItems[key].item1 = "like";
        filterInput = filterInput.map((inp) =>
          inp.name !== key ? inp : { ...inp, value: "" }
        );
      }
    });
    this.setState(
      { data: filteredItems, filterValueInput: filterInput },
      () => {
        this.props.pageChangeHandler(-1, -1, this.state.data);
      }
    );
  };

  public onToggleEditModal: OnToggleEditModal = () => {
    // console.log("toggle edit modal");
    this.setState({ showEditModal: !this.state.showEditModal });
    this.setState({
      showDetailsModal: !this.state.showDetailsModal,
    });
  };

  onToggleTrailModal = (item: any) => {
    if (this.state.showTrailModal === false) {
      this.fetchTrailItem(item);
    }
    this.setState({ showTrailModal: !this.state.showTrailModal });
  };

  fetchTrailItem = async (item: any) => {
    this.setState({ fetchingTrail: true });
    const decodedToken = decodeToken(getCurrentUserSession().token || "");

    if (this.state.entityName) {
      const mapper =
        await auditTrailMapperService.retrieveByInformationIdAndEntityName(
          this.state.entityName === "Recova.Auth.Institution"
            ? _.has(item, "objectId")
              ? item.objectId
              : item.id
            : decodedToken?.["InstitutionId"],
          _.has(item, "objectId") ? item.objectId : item.id,
          this.state.entityName,
          TrailType[TrailType.Entity]
        );
      if (mapper.data !== null) {
        const trailItem = await auditTrailService.retrieveAuditTrailItemByCount(
          this.state.entityName === "Recova.Auth.Institution"
            ? _.has(item, "objectId")
              ? item.objectId
              : item.id
            : decodedToken?.["InstitutionId"],
          _.has(item, "count") ? item.count : mapper.data.count,
          mapper.data.informationID,
          this.state.entityName
        );

        let jsonBefore = trailItem.data.jsonBefore;
        if (trailItem.data.jsonBefore) {
          jsonBefore = JSON.parse(trailItem.data.jsonBefore);
          Object.keys(jsonBefore).forEach((element) => {
            if (typeof jsonBefore[element] === "object") {
              delete jsonBefore[element];
            }
          });
        }
        let jsonAfter = trailItem.data.jsonAfter;
        jsonAfter = JSON.parse(trailItem.data.jsonAfter);
        Object.keys(jsonAfter).forEach((element) => {
          if (typeof jsonAfter[element] === "object") {
            delete jsonAfter[element];
          }
        });

        this.setState({
          trailMapper: mapper.data,
          trailItem: trailItem.data,
          jsonBefore,
          jsonAfter,
        });
      } else {
        swal({
          title: "Notification!",
          text: "No trail found for this item",
          icon: "warning",
          buttons: ["Ok"],
        });
        this.onToggleTrailModal(item);
      }
    }
    this.setState({ fetchingTrail: false });
  };

  public render() {
    const {
      ListPayLoad,
      DetailsModal,
      //   EditModal,
      tableItems, //
      item, //
      fetchingDetail,
    } = this.props;
    // const { tableItems, item } = this.state;
    // const { fetching } = this.state;
    // console.log("fetching detailse saaswra", fetching);
    const {
      showTableFilterModal,
      showDetailsModal,
      showEditModal,
      showTrailModal,
      filterValueInput,
      fetchingTrail,
      jsonAfter,
      jsonBefore,
      trailItem,
      trailMapper,
    } = this.state;
    return (
      <Fragment>
        <ListPayLoad
          data={this.state.data}
          onToggleTableFilterModal={this.onToggleTableFilterModal}
          onToggleDetailsModal={this.onToggleDetailsModal}
          onHandleFilterItemRemove={this.onHandleFilterItemRemove}
          tableItems={tableItems}
          showTableFilterModal={showTableFilterModal}
          filterValueInput={filterValueInput}
        />
        {fetchingDetail && showDetailsModal ? (
          <LoaderModal />
        ) : (
          <DetailsModal
            item={item}
            onToggleDetailsModal={this.onToggleDetailsModal}
            onToggleEditModal={this.onToggleEditModal}
            showDetailsModal={showDetailsModal}
            showEditModal={showEditModal}
            fetchTrailItem={this.fetchTrailItem}
            fetchingTrail={fetchingTrail || false}
            jsonAfter={jsonAfter || {}}
            jsonBefore={jsonBefore || {}}
            onToggleTrailModal={this.onToggleTrailModal}
            showTrailModal={showTrailModal}
            trailItem={trailItem}
            trailMapper={trailMapper}
          />
        )}
      </Fragment>
    );
  }
}

export default BaseListComponent;
