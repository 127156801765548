import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";

const externalIntegrationBaseAddress =
  process.env.REACT_APP_EXTERNAL_INTEGRATION_BASEADDRESS;

export function retrieveAllDataReferences() {
  return http.get(externalIntegrationBaseAddress + `DataReferencing/responses`);
}

export function retrieveDataReferencesById(id: number) {
  var url = externalIntegrationBaseAddress + `DataReferencing/response/${id}`;
  return http.get(url, {
    url: url,
    method: "get",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function filterDataReferences(pageSize: number, pageNumber: number) {
  return http.post(
    externalIntegrationBaseAddress +
      `DataReferencing/response/FilterPages?pageSize=${pageSize}&pageNumber=${pageNumber}`
  );
}

export function downloadReport(type: string) {
  var mywindow = window.open(
    externalIntegrationBaseAddress +
      `DataReferencing/response/DownloadReport?type=${type}`,
    "PRINT",
    "height=500,width=900"
  );
  mywindow?.focus();
}

export function downloadReportPDF() {
  return http.get(
    externalIntegrationBaseAddress +
      `DataReferencing/responses/DownloadReportPDF`
  );
}

export default {
  retrieveAllDataReferences,
  retrieveDataReferencesById,
  filterDataReferences,
  downloadReport,
  downloadReportPDF,
};
