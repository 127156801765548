import React, { Fragment } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import _ from "lodash";
import { decodeToken } from "react-jwt";
import BaseFormComponent, { IBaseFormState } from "../../../../components/BaseFormComponent";
import { getCurrentUserSession } from "../../../../services/auth/authService";
import creditAssessmentValidationService from "../../../../services/creditAssessment/creditAssessmentValidationService";
import SelectInputComponent, { ISelectItems } from "../../../../components/formSelectComponent";
import FormInputComponent from "../../../../components/formInputComponent";
import { ActionTypes } from "../../../../enums/actionTypes";
import institutionsService from "../../../../services/auth/institutionsService";
import loanProductService from "../../../../services/creditAssessment/loanProductService";
import { retrieveAllByProductsByUseCase } from "../../../../services/auth/loanProductService";
import DetailItemSelectComponent from "../../../../components/detailItemSelectBox";
import { Modal, ProgressBar } from "react-bootstrap";
import feeConfigService from "../../../../services/creditAssessment/feeConfigService";
import blackboxInstitutionService from "../../../../services/creditAssessment/blackboxInstitutionService";
import activityTrailService from "../../../../services/auditTrail/activityTrailService";

interface AddOcmInstitutionFields {
  ussdCode: string;
  flatRatePerRequest: string;
  flatRatePerLoanDisbursed: string;
  percentageOfLoanAmount: string;
  minimumPerTransaction: string;
  maximumPerTransaction: string;
  flatRatePerSuccessfulRequest: string;
}

class AddOcmInstitutionForm extends BaseFormComponent<AddOcmInstitutionFields> { }

interface IAddOcmInstitutionProps { }
interface IAddOcmInstitutionState {
  ocmLoanProducts: any;
  institution: any;
  category: string;
  institutions: any;
  fetchingInstitutions: boolean;
  fetchingLoanProducts: boolean;
  authLoanProducts: any;
  mappedLoanProducts: any;
  feeConfigDelay: number;
  showProgressModal: boolean;
  fetchingFeeConfig: boolean;
  feeConfig: any;
  enableFees: boolean;
  updatingStatus: boolean;
  changeFunction: any;
}

class AddOcmInstitution extends React.Component<
  IAddOcmInstitutionProps,
  IBaseFormState & IAddOcmInstitutionState
> {
  interval;
  constructor(props: IAddOcmInstitutionProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      institution: {},
      institutions: [],
      category: "",
      ocmLoanProducts: [],
      authLoanProducts: [],
      mappedLoanProducts: [],
      fetchingInstitutions: false,
      fetchingLoanProducts: false,
      feeConfigDelay: 0,
      showProgressModal: false,
      feeConfig: {},
      fetchingFeeConfig: false,
      enableFees: false,
      updatingStatus: false,
      changeFunction: {}
    };
  }

  async componentDidMount() {
    const { errors } = this.state;
    try {
      this.setState({ fetchingLoanProducts: true });

      //fetch loan products (OCM) 
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      let ocmLP = await loanProductService.retrieveAllByInstitutionCode(decodedToken?.["InstitutionCode"]);

      this.setState({ ocmLoanProducts: ocmLP.data, fetchingLoanProducts: false });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (ex.response && ex.response.status === 404) {
        // console.log(ex.response);
        errors.response = "Internal server error.";
        // console.log(errors);
        this.setState({ errors });
      }
      this.setState({ fetchingLoanProducts: false });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onFormSubmit(fields: AddOcmInstitutionFields, onReloadFieldsCB: any, feeSubmit: boolean, changeFunction): boolean {
    try {
      // console.log("Fields for submitting:: ", fields)
      this.setState(
        {
          changeFunction: changeFunction,
          errors: feeSubmit ? creditAssessmentValidationService.validateAddFeeConfigToOCMForm(fields) : creditAssessmentValidationService.validateAddOCMInstitutionForm(fields),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            // console.log("passed Validation")
            return feeSubmit ? this.submitFees(fields, onReloadFieldsCB) : this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddOcmInstitutionFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    let response: any;
    try {
      this.setState({ submitting: true });
      const payload: any = {};

      const { institution } = this.state

      payload.institutionCode = institution.code;
      payload.institutionName = institution.name;
      payload.externalInstitutionId = institution.id?.toString();
      payload.externalInstitutionCode = institution.externalInstitutionCode;
      payload.institutionCategory = institution.category;
      payload.ussdCode = fields.ussdCode;
      payload.loanProductsMapCollection = this.state.mappedLoanProducts;

      // console.log(payload)
      response = await blackboxInstitutionService.createBlackboxInstitution(payload);
      // console.log("testing log:: ", response);

      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Add OCM Institution",
          description: `Added new OCM Institution ${institution.name}`,
        });
        toast.info(`OCM Institution ${payload.institutionName} created successfully! Please, wait while the system sets up the initial fee config`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });

        //set interval to fetch fee config after 5 seconds
        this.setState({ submitting: false, showProgressModal: true });
        this.interval = setInterval(async () => {
          if (this.state.feeConfigDelay < 100) {
            this.setState({ feeConfigDelay: this.state.feeConfigDelay + 10 })
          } else {
            clearInterval(this.interval);
            this.setState({ feeConfigDelay: 0, showProgressModal: false, fetchingFeeConfig: true })

            //fetch Fee config
            const fee = await feeConfigService.retrieveAllByInstitution(this.state.institution.code);

            this.state.changeFunction("flatRatePerRequest", fee.data[0].flatRatePerRequest?.toString())
            this.state.changeFunction("flatRatePerLoanDisbursed", fee.data[0].flatRatePerLoanDisbursed?.toString())
            this.state.changeFunction("percentageOfLoanAmount", fee.data[0].percentageOfLoanAmount?.toString())
            this.state.changeFunction("minimumPerTransaction", fee.data[0].minimumPerTransaction?.toString())
            this.state.changeFunction("maximumPerTransaction", fee.data[0].maximumPerTransaction?.toString())
            this.state.changeFunction("flatRatePerSuccessfulRequest", fee.data[0].flatRatePerSuccessfulRequest?.toString())

            this.setState({ feeConfig: fee.data[0], enableFees: fee.data[0].isEnabled, fetchingFeeConfig: true })
          }
        }, 1000);

      } else {
        this.setState({ submitting: false });
        return false;
      }
    } catch (error) {
      if (error.response.status == 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status == 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // console.log("error: ", error.response);
        // return false;
      }
      // console.log("error: ", error.response);
      this.setState({ submitting: false });
      return false;
    } finally {
      return true;
    }
  }

  async submitFees(
    fields: AddOcmInstitutionFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      if (Number(fields.minimumPerTransaction) > Number(fields.maximumPerTransaction)) {
        toast.info(`Minimum Per transaction value cannot be greater than Maximum!`, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
        return false;
      }
      this.setState({ submitting: true });

      const payload = { ...this.state.feeConfig };
      payload.flatRatePerRequest = Number(fields.flatRatePerRequest);
      payload.flatRatePerLoanDisbursed = Number(fields.flatRatePerLoanDisbursed);
      payload.percentageOfLoanAmount = Number(fields.percentageOfLoanAmount);
      payload.minimumPerTransaction = Number(fields.minimumPerTransaction);
      payload.maximumPerTransaction = Number(fields.maximumPerTransaction);
      payload.flatRatePerSuccessfulRequest = Number(fields.flatRatePerSuccessfulRequest);
      payload.isEnabled = this.state.enableFees;

      //   console.log("For checks::", payload);
      const response = await feeConfigService.updateFeeConfiguration(
        payload,
        payload.id
      );
      // console.log("testing log:: ", response);

      if (response.status >= 200 && response.status <= 300) {
        toast.info(`Institution Fees for ${payload.institutionName} configured successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
        this.setState({ submitting: false });

      } else {
        this.setState({ submitting: false });
        return false;
      }
      this.resetFields();
      onReloadFieldsCB();
    } catch (error) {
      if (error.response.status == 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status == 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // console.log("error: ", error.response);
        // return false;
      }
      // console.log("error: ", error.response);
      this.setState({ submitting: false });
      return false;
    } finally {
      return true;
    }
  }

  handleOnSelectCategory = async (id, value) => {
    try {
      this.setState({
        category: value,
        fetchingInstitutions: true,
        institution: {},
      });
      let inst = await institutionsService.retrieveAllByCategory(value);
      this.setState({ institutions: inst.data, fetchingInstitutions: false });
    } catch (error) {
      toast.info("Something went wrong.", {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      toast.info(error.response.data.errors[0], {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      this.setState({
        category: "",
        fetchingInstitutions: false,
      });
    }
  };

  handleOnSelectInstitution = async (id, value) => {
    try {
      const institution = this.state.institutions.filter(
        (x) => x.id === parseInt(value)
      )[0];
      this.setState({ institution: institution, fetchingLoanProducts: true });

      //fetch loan products (Auth)
      let authLP = await retrieveAllByProductsByUseCase(institution.id, "BlackBox")
      // console.log(authLP.data)

      this.setState({ authLoanProducts: authLP.data, fetchingLoanProducts: false });

    } catch (error) {
      toast.info("Something went wrong.", {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      toast.info(error.response.data.errors[0], {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      this.setState({ authLoanProducts: [], fetchingLoanProducts: false });
    }
  };

  handleOnMapLoanProducts = async (ocmLoanProductId, authLoanProductId) => {
    try {
      const { mappedLoanProducts } = this.state;
      let loanProductsMap = mappedLoanProducts;
      var checker = mappedLoanProducts.filter((x) => x.loanProductId === ocmLoanProductId);
      if (_.isEmpty(checker)) {
        loanProductsMap.push({ loanProductId: ocmLoanProductId, institutionLoanProductId: authLoanProductId });
      } else {
        if (authLoanProductId == "") {
          _.remove(loanProductsMap, function (n: any) { return n.loanProductId === ocmLoanProductId; })
        } else {
          loanProductsMap = mappedLoanProducts.map(p =>
            p.loanProductId === ocmLoanProductId
              ? { ...p, institutionLoanProductId: authLoanProductId }
              : p
          );
        }
      }
      this.setState({ mappedLoanProducts: loanProductsMap });

    } catch (error) {
      toast.info("Something went wrong.", {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      toast.info(error.response.data.errors[0], {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
    }
  };

  toggleEnableFeesSwitch = () => {
    try {
      this.setState({ updatingStatus: true });
      swal({
        title: "Confirm!",
        text: `Are you sure you want to proceed to ${this.state.enableFees ? "DISABLE" : "ENABLE"} Fees for ${this.state.institution.name}?`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode: true,
      }).then(async (resp) => {
        if (resp) {
          // console.log("cancelling action...");
          this.setState({ updatingStatus: false });
        } else {
          const feeConfig = { ...this.state.feeConfig }
          // await activityTrailService.saveActivityTrail({
          //   actionType: `${
          //     _item.status === "Inactive" ? "Activate" : "Deactivate"
          //   } Blackbox Institution`,
          //   description: `Clicked on ${
          //     _item.status === "Inactive" ? "Activate" : "Deactivate"
          //   } to ${_item.status === "Inactive" ? "Activate" : "Deactivate"} Blackbox Institution ${
          //     _item.institutionName
          //   }`,
          // });
          if (this.state.enableFees) {
            //disable
            await feeConfigService.Disable(feeConfig.id);
          } else {
            //enable
            await feeConfigService.Enable(feeConfig.id);
          }
          toast.info(
            `Fees ${this.state.enableFees ? "Disabled" : "Enabled"} successfully! `,
            {
              autoClose: 6000,
              type: toast.TYPE.DEFAULT,
              hideProgressBar: false,
            }
          );
          this.setState({ updatingStatus: false, enableFees: !this.state.enableFees });
        }
      });
    } catch (ex) {
      this.setState({ updatingStatus: false });
    }
  };

  resetFields() {
    this.setState({ validated: false, submitting: false });
  }

  render() {
    const { errors, validated, ocmLoanProducts,
      authLoanProducts, category, fetchingInstitutions,
      institution, institutions, fetchingLoanProducts,
      mappedLoanProducts, fetchingFeeConfig, feeConfig } = this.state;

    let authLoanProductsInputData: ISelectItems[] = authLoanProducts.map((item) => ({
      name: item.name,
      value: item.productId,
    }));

    let institutionsInputData: ISelectItems[] = institutions.map((item) => ({
      name: item.name,
      value: item.id,
    }));
    // order by institution name
    institutionsInputData.sort((a, b) => a.name.localeCompare(b.name))

    let categoryInputData: ISelectItems[] = [
      "CommercialBank",
      "BankOneOFI",
      "OFI",
    ].map((item) => ({
      name: item,
      value: item,
    }));

    const fetchedFees = !_.isEmpty(feeConfig)

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add Institution</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddOcmInstitutionForm
                  initialValues={{
                    ussdCode: "",
                    flatRatePerRequest: "",
                    flatRatePerLoanDisbursed: "",
                    percentageOfLoanAmount: "",
                    minimumPerTransaction: "",
                    maximumPerTransaction: "",
                    flatRatePerSuccessfulRequest: ""
                  }}
                  FormComponent={({
                    fields: { ussdCode, flatRatePerLoanDisbursed, flatRatePerRequest, flatRatePerSuccessfulRequest, maximumPerTransaction, minimumPerTransaction, percentageOfLoanAmount },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <h5 className="card-title">
                            <i className="fas fa-university mr-3" />
                            Institution Details
                          </h5>
                          <div className="form-row">
                            <SelectInputComponent
                              id="category"
                              name="institutionCategory"
                              divClass={6}
                              value={category}
                              items={categoryInputData}
                              required={true}
                              validated={false}
                              disabled={fetchedFees}
                              errors={[]}
                              onChange={this.handleOnSelectCategory}
                            />
                            {category == "" ? (
                              ""
                            ) : (
                              <Fragment>
                                <SelectInputComponent
                                  id="institution"
                                  name="institution"
                                  divClass={6}
                                  value={institution.id}
                                  items={institutionsInputData}
                                  required={true}
                                  validated={false}
                                  disabled={fetchedFees}
                                  errors={[]}
                                  labelIconClass={fetchingInstitutions === true
                                    ? "fas fa-spinner fa-spin orange-text mr-2"
                                    : ""
                                  }
                                  onChange={this.handleOnSelectInstitution}
                                />
                                {_.isEmpty(institution) ? (
                                  ""
                                ) : (
                                  <Fragment>
                                    <FormInputComponent
                                      id="ussdCode"
                                      type="text"
                                      name="ussdCode"
                                      placeholder=""
                                      divClass={6}
                                      value={ussdCode}
                                      required={true}
                                      validated={validated}
                                      disabled={fetchedFees}
                                      errors={errors}
                                      onChange={onChange}
                                    />
                                  </Fragment>
                                )}
                              </Fragment>
                            )}
                          </div>
                        </div>

                        <div className="card-header card-footer" style={{ padding: "8px" }}>
                          <b>Loan Product Details</b>
                        </div>
                        <div
                          className="card-body"
                        >
                          <div
                            className="table-responsize text-nowrap"
                            style={{ maxHeight: "300px", overflowY: "scroll" }}
                          >
                            <table className="table table-hover table-content table-sm table-striped mb-0">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    style={{
                                      width: "5px",
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                    }}
                                  >
                                    <strong>#</strong>
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      // width: "5px",
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                    }}
                                  >
                                    <strong>OCM Loan Products</strong>
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      // width: "5px",
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                    }}
                                  >
                                    <strong>Institution Loan Products</strong>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {fetchingLoanProducts ? (
                                  <React.Fragment>
                                    <tr>
                                      <td
                                        className="font-weight-normal text-truncate"
                                        colSpan={6}
                                        style={{
                                          textAlign: "center",
                                          color: "#FE825E",
                                          fontSize: "small",
                                        }}
                                      >
                                        <i className="fas fa-spinner fa-spin mr-2" />
                                      </td>
                                    </tr>
                                    {/* <Skeleton height={10} width={50} count={4} /> */}
                                  </React.Fragment>
                                ) : ocmLoanProducts.length === 0 ? (
                                  <tr>
                                    <td
                                      className="font-weight-normal text-truncate"
                                      colSpan={6}
                                      style={{
                                        textAlign: "center",
                                        color: "#FE825E",
                                        fontSize: "small",
                                      }}
                                    >
                                      ...No OCM Products Available
                                    </td>
                                  </tr>
                                ) : (
                                  ocmLoanProducts.map((product) => (
                                    <tr key={product.id}>
                                      <td
                                        style={{
                                          width: "5px",
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {ocmLoanProducts.indexOf(product) + 1}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {product.name}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        <DetailItemSelectComponent
                                          id="institutionLoanProduct"
                                          name="institutionLoanProduct"
                                          value={mappedLoanProducts.filter(mappedProduct => mappedProduct.loanProductId == product.id)[0]?.institutionLoanProductId}
                                          items={authLoanProductsInputData}
                                          validated={false}
                                          errors={[]}
                                          onChange={(id, value) =>
                                            this.handleOnMapLoanProducts(product.id, value)
                                          }
                                          disabled={fetchedFees}
                                          disabledOption={(item) =>
                                            mappedLoanProducts
                                              .map(function (x) {
                                                if (x.loanProductId != product.id) {
                                                  return x.institutionLoanProductId;
                                                }
                                              })
                                              .includes(item.toString())
                                          }
                                        />
                                      </td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </table>
                          </div>

                          {/* <div className="row">
                            Content here
                          </div> */}
                        </div>

                        <div className="card-header card-footer" style={{ padding: "8px" }}>
                          <b>Fee Configuration</b>

                          {fetchedFees && (
                            <div className="float-right mr-3">
                              <div className="form-row">
                                <label
                                  htmlFor="enableFees"
                                  className="col-form-label"
                                >
                                  Enable Fees
                                </label>
                                <br />
                                <span className="material-switch ml-2 mt-2">
                                  <input
                                    id="enableFees"
                                    name="enableFees"
                                    type="checkbox"
                                    disabled={this.state.updatingStatus}
                                    onChange={this.toggleEnableFeesSwitch}
                                    checked={this.state.enableFees}
                                  />
                                  <label
                                    htmlFor="enableFees"
                                    className="bg-primary"
                                  ></label>
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                        {fetchedFees && (
                          <div className="card-body">
                            <React.Fragment>
                              <div className="form-row">
                                <FormInputComponent
                                  id="flatRatePerRequest"
                                  type="number"
                                  name="flatRatePerRequest"
                                  placeholder=""
                                  divClass={6}
                                  value={flatRatePerRequest}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="flatRatePerLoanDisbursed"
                                  type="number"
                                  name={"flatRatePerLoanDisbursed"}
                                  placeholder=""
                                  divClass={6}
                                  value={flatRatePerLoanDisbursed}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-row">
                                <FormInputComponent
                                  id="percentageOfLoanAmount"
                                  type="number"
                                  name={"percentageOfLoanAmount"}
                                  placeholder=""
                                  divClass={6}
                                  value={percentageOfLoanAmount}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="flatRatePerSuccessfulRequest"
                                  type="number"
                                  name={"flatRatePerSuccessfulRequest"}
                                  placeholder=""
                                  divClass={6}
                                  value={flatRatePerSuccessfulRequest}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-row">
                                <FormInputComponent
                                  id="minimumPerTransaction"
                                  type="number"
                                  name={"minimumPerTransaction"}
                                  placeholder=""
                                  divClass={6}
                                  value={minimumPerTransaction}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="maximumPerTransaction"
                                  type="number"
                                  name={"maximumPerTransaction"}
                                  placeholder=""
                                  divClass={6}
                                  value={maximumPerTransaction}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                            </React.Fragment>
                          </div>
                        )}
                        <hr />
                        <div className="card-body">
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                            disabled={this.state.submitting}
                            onClick={(e) => {
                              onHandleSubmit(
                                e,
                                ActionTypes.SAVE,
                                fetchedFees ? "Institution Fees" : "Institution",
                                () => {
                                  this.onFormSubmit(
                                    { ussdCode, percentageOfLoanAmount, minimumPerTransaction, maximumPerTransaction, flatRatePerSuccessfulRequest, flatRatePerRequest, flatRatePerLoanDisbursed },
                                    onReloadFields,
                                    fetchedFees,
                                    onChange
                                  );
                                }
                              );
                            }}
                          >
                            {this.state.submitting === false ? (
                              <React.Fragment>
                                <i className="fas fa-lg fa-save mr-3" /> Save
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                Please wait...
                              </React.Fragment>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                ></AddOcmInstitutionForm>
              </div>
            </div>
          </div>
        </div>
        <Modal size="lg" backdrop="static" show={this.state.showProgressModal}>
          <Modal.Body style={{ backgroundColor: "white", borderRadius: "20px" }}>
            <div style={{ backgroundColor: "white", padding: "10px", textAlign: "center", borderRadius: "inherit" }}>
              <p style={{ fontSize: "18px" }}>Please, wait while the system sets up the initial fee config</p>
              <ProgressBar striped animated variant="success" now={this.state.feeConfigDelay} />
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default AddOcmInstitution;
