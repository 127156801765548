import React from "react";
import { Modal } from "react-bootstrap";

export const BalanceSheetModal = ({ balanceSheet }: { balanceSheet: any }) => {
  return (
    <Modal.Body>
      <br />
      <div className="row clearfix">
        {balanceSheet?.map((abc) => (
          <div
            style={{ marginBottom: 30 }}
            key={abc}
            className=" form-row col-md-12"
          >
            <div
              className="table-responsive text-nowrap"
              style={{
                overflowY: "scroll",
              }}
            >
              <table className="table table-hover table-content table-sm table-striped mb-0">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                      }}
                    >
                      <strong># {abc?.statementType}</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {abc?.data?.map(({ item, amount }, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                          width: "50%",
                        }}
                      >
                        {item}
                      </td>
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                        }}
                      >
                        {amount}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    </Modal.Body>
  );
};
