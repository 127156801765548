import React from "react";
import { toast } from "react-toastify";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import authValidationService from "../../../services/auth/authValidationService";
import FormInputComponent from "../../../components/formInputComponent";
import rolesService from "../../../services/auth/rolesService";
import { ActionTypes } from "../../../enums/actionTypes";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import CheckboxTree from "react-checkbox-tree";
import userRoleFunctionService from "../../../services/auth/userRoleFunctionService";
import { getCurrentUserSession } from "./../../../services/auth/authService";
import { decodeToken } from "react-jwt";
import SelectInputComponent, { ISelectItems } from "../../../components/formSelectComponent";

interface AddUserRoleFields {
  name: string;
  code: string;
  description: string;
  roleVisibility: string;
}

class AddUserRoleForm extends BaseFormComponent<AddUserRoleFields> { }

interface IAddUserRoleProps { }
interface IAddUserRoleState {
  checked: any;
  expanded: any;
  functions: any;
  parentFunctions: any;
  userRoleFunctions: any;
  fetchingUserRoleFunctions: boolean;
}

class AddUserRole extends React.Component<
  IAddUserRoleProps,
  IBaseFormState & IAddUserRoleState
> {
  constructor(props: IAddUserRoleProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      checked: [],
      expanded: [],
      functions: [],
      parentFunctions: [],
      userRoleFunctions: [],
      fetchingUserRoleFunctions: false,
    };
  }

  async componentDidMount() {
    const { errors, submitting } = this.state;
    // const { currentUser } = this.props;
    // this.setState({ currentUser });
    try {
      this.setState({ fetchingUserRoleFunctions: true });
      const userRoleFunctions =
        await userRoleFunctionService.retrieveAllFunctionsByInstitution();
      // console.log("Fetched functions", userRoleFunctions);
      const functions = userRoleFunctions.data;
      let parentFunctions = functions.filter((func) => func.parentId === 0);
      // console.log(parentFunctions);

      let treeCollection: any = [];
      treeCollection = parentFunctions.map((pFunc) => {
        let newSet: any = {};
        newSet = {
          value: pFunc.displayName,
          label: pFunc.displayName,
          // showCheckbox: false,
          children: functions
            .filter((func) => func.parentId === pFunc.id)
            .map((filtered) => {
              const child: any = {};
              child.value = filtered.description;
              child.label = filtered.displayName;
              return child;
            }),
        };
        return newSet;
        // console.log("New set of nodes", newSet);
      });
      // console.log("tree Collection::", treeCollection);
      this.setState({
        functions: treeCollection,
        userRoleFunctions: functions,
        parentFunctions: parentFunctions,
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (ex.response && ex.response.status === 404) {
        this.setState({ submitting: !submitting });
        // console.log(ex.response);
        errors.response = "Internal server error.";
        // console.log(errors);
        this.setState({ errors });
      }
    } finally {
      this.setState({ fetchingUserRoleFunctions: false });
    }
  }

  onFormSubmit(fields: AddUserRoleFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        { errors: authValidationService.validateAddUserRoleForm(fields) },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddUserRoleFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      // console.log("commencing add userrole");
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const userRolePayLoad: any = {};
      userRolePayLoad.InstitutionId = decodedToken?.["InstitutionId"];
      userRolePayLoad.Name = fields.name;
      userRolePayLoad.Code = fields.code;
      userRolePayLoad.description = fields.description;
      userRolePayLoad.roleVisibility = fields.roleVisibility;
      let checkedFunctions = this.state.checked.map((value) => {
        return this.state.userRoleFunctions.find(
          (func) => func.description === value
        );
      });
      userRolePayLoad.AssignedFunctions = checkedFunctions.map(
        (value) => value.id
      );
      this.state.parentFunctions.map((elem) => {
        if (checkedFunctions.some((func) => func.parentId === elem.id)) {
          userRolePayLoad.AssignedFunctions.push(elem.id);
        }
      });
      // console.log("userRolePayLoad: ", userRolePayLoad);
      // console.log("pFuncIds: ", pFuncIds);
      // console.log("submitting...");
      this.setState({ submitting: true });
      const response = await rolesService.saveRole(userRolePayLoad);
      this.setState({ submitting: false });
      await activityTrailService.saveActivityTrail({
        actionType: "Add UserRole",
        description: `Added new User Role  ${fields.name}`,
      });

      if (response.data.isLoggedForApproval === true) {
        toast.warn("Action has been successfully logged for Approval!", {
          autoClose: 6000,
          type: toast.TYPE.WARNING,
          position: "top-center",
          hideProgressBar: false,
        });
      } else {
        toast.info(`UserRole ${fields.name} created successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      }
      this.setState({ checked: [] });
      onReloadFieldsCB();
      return true;
    } catch (error) {
      this.setState({ submitting: false });
      return false;
    }
  }

  render() {
    const {
      errors,
      validated,
      submitting,
      functions,
      fetchingUserRoleFunctions,
    } = this.state;

    // const nodes = [
    //   {
    //     value: "mars",
    //     label: "Mars",
    //     children: [
    //       { value: "phobos", label: "Phobos" },
    //       { value: "deimos", label: "Deimos" },
    //     ],
    //   },
    //   {
    //     value: "venus",
    //     label: "Venus",
    //     children: [
    //       { value: "test", label: "Test" },
    //       { value: "fish", label: "Fish" },
    //     ],
    //   },
    // ];
    // console.log("Functions", functions);
    // console.log("Nodes:::", nodes);
    const roleVisibilityInputData: ISelectItems[] = ["Branch", "Regional", "Global"]
      .map(item => {
        return { name: item, value: item }
      });

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add User Role</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddUserRoleForm
                  initialValues={{
                    name: "",
                    code: "",
                    description: "",
                    roleVisibility: ""
                  }}
                  FormComponent={({
                    fields: { name, code, description, roleVisibility },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <h5 className="card-title">GENERAL INFORMATION</h5>
                          <div className="form-row">
                            <div className="col-md-6">
                              <FormInputComponent
                                id="name"
                                type="text"
                                name="name"
                                placeholder=""
                                value={name}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                              />
                              <FormInputComponent
                                id="code"
                                type="text"
                                name="code"
                                placeholder=""
                                value={code}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                              />
                              <SelectInputComponent
                                label="Role Visibility"
                                id="roleVisibility"
                                name="roleVisibility"
                                divClass={6}
                                value={roleVisibility}
                                items={roleVisibilityInputData}
                                required={true}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                              />
                              <div className="form-group col m-0">
                                <label
                                  htmlFor="description"
                                  className="col-form-label s-12"
                                >
                                  <span
                                    aria-hidden="true"
                                    className="mr-2"
                                    style={{ color: "#ed5564" }}
                                  >
                                    *
                                  </span>
                                  Description
                                </label>
                                <textarea
                                  id="description"
                                  name="description"
                                  className={
                                    !validated
                                      ? "form-control r-0 light s-12"
                                      : errors.description
                                        ? "form-control r-0 light s-12 is-invalid"
                                        : "form-control r-0 light s-12 is-valid"
                                  }
                                  value={description}
                                  onChange={(e) =>
                                    onChange(
                                      "description",
                                      e.currentTarget.value
                                    )
                                  }
                                ></textarea>
                                <div
                                  className="invalid-feedback"
                                  style={{
                                    visibility: errors.description,
                                  }}
                                >
                                  {errors.description}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <label className="col-form-label s-12">
                                Functions:
                              </label>
                              <div className="form-row">
                                <span className="col-sm-1 ml-2"></span>
                                <CheckboxTree
                                  icons={{
                                    parentClose: (
                                      <i className="icon-folder2 orange-text"></i>
                                    ),
                                    parentOpen: (
                                      <i className="icon-folder-open orange-text"></i>
                                    ),
                                    leaf: (
                                      <i className="icon-file-text-o blue-text"></i>
                                    ),
                                    halfCheck: <i className="icon-square"></i>,
                                    check: (
                                      <i className="icon-check_box green-text"></i>
                                    ),
                                  }}
                                  nodes={functions}
                                  checked={this.state.checked}
                                  expanded={this.state.expanded}
                                  onCheck={(checked) => {
                                    // console.log("checked status: : :", checked);
                                    this.setState({ checked });
                                  }}
                                  onExpand={(expanded) =>
                                    this.setState({ expanded })
                                  }
                                  iconsClass="fa5"
                                  // disabled={true}
                                  showExpandAll={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="card-body">
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                            disabled={submitting}
                            onClick={(e) => {
                              onHandleSubmit(
                                e,
                                ActionTypes.SAVE,
                                "User Role",
                                () => {
                                  this.onFormSubmit(
                                    {
                                      code,
                                      description,
                                      name,
                                      roleVisibility
                                    },
                                    onReloadFields
                                  );
                                }
                              );
                            }}
                          >
                            {submitting === false ? (
                              <React.Fragment>
                                <i className="fas fa-lg fa-save mr-3" /> Save
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <i className="fas fa-spin fa-circle-notch mr-3" />
                              </React.Fragment>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                ></AddUserRoleForm>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddUserRole;
