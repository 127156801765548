import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
// import { ActionTypes } from "../../../enums/actionTypes";
import textFormatService from "../../../services/utility/textFormatService";

interface IMessageTemplateDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  showTrail: any;
  currentUserRoles: any;
  item: any;
}

interface IMessageTemplateDetailState {
  item: any;
  updatingStatus: boolean;
}

class MessageTemplateDetail extends Component<
  IMessageTemplateDetailProps,
  IMessageTemplateDetailState
> {
  constructor(props: IMessageTemplateDetailProps) {
    super(props);
    this.state = {
      item: {},
      updatingStatus: false,
    };
  }

  static getDerivedStateFromProps(
    nextProps: IMessageTemplateDetailProps,
    prevState
  ) {
    // console.log("current props: ", nextProps.item);
    if (prevState.item !== nextProps.item) {
      return {
        item: nextProps.item,
        // updateProduct: true,
      };
    }
  }

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      //   showTrail,
      //   currentUserRoles,
      item,
    } = this.props;
    const { updatingStatus } = this.state;
    // console.log("Fetched Item :: ", item);
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="icon icon-building-o s-18 mr-3" />
                Message Template Details
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={4} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Name"
                        value={item.name}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Subject"
                        value={item.subject}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Date Created"
                        value={textFormatService.formatTimeString(
                          item.dateCreated
                        )}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Body"
                        value={item.body}
                        labelSize={6}
                        valueSize={6}
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Message Type"
                        value={item.messageType}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Bcc Email Addresses"
                        value={item.bccEmailAddresses}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Last Updated"
                        value={textFormatService.formatTimeString(
                          item.dateUpdated
                        )}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Status"
                        value={
                          (item ? item.isActive : true) === true ? (
                            <span className="badge badge-success">
                              {item ? "Active" : ""}
                            </span>
                          ) : (
                            <span className="badge badge-danger">
                              {item ? "Inactive" : ""}
                            </span>
                          )
                        }
                        labelSize={6}
                        valueSize={6}
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
              {/* {(getCurrentUsersRoles().includes("addcreditqualificationrule") || getCurrentUsersRoles().includes("addcreditqualificationrule(ocm)")) && (
                <Button
                  size="sm"
                  variant="primary"
                  disabled={updatingStatus}
                  onClick={togglePatchModal}
                >
                  <i className="far fa-plus mr-3" />
                  Add Credit Qialification Rule
                </Button>
              )} */}
              {/* {(getCurrentUsersRoles().includes("editloanproduct") || getCurrentUsersRoles().includes("editloanproduct(ocm)")) && ( */}
                <Button
                  size="sm"
                  variant="primary"
                  disabled={updatingStatus}
                  onClick={toggleEditModal}
                >
                  <i className="far fa-edit mr-3" />
                  Edit
                </Button>
                {/* )} */}
            </React.Fragment>
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default MessageTemplateDetail;
