import React, { Fragment, Component } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../../../../components/detailItem";
import textFormatService from "../../../../../../services/utility/textFormatService";

interface PersonalDetailsProps {
    values: any;
    handleChange: any;
    handleBlur: any;
}

interface PersonalDetailsState {
    item: any;
}
class PersonalDetails extends Component<PersonalDetailsProps, PersonalDetailsState> {
    constructor(props: PersonalDetailsProps) {
        super(props);
        this.state = {
            item: {},
        };
    }

    static getDerivedStateFromProps(nextProps: PersonalDetailsProps, prevState) {
        return {
            item: nextProps.values.incomeDetails,
        };
    }

    render() {
        const { values } = this.props;
        const { item } = this.state

        return (
            <Fragment>{
                values.incomeDetails == null ? (
                    <div style={{ textAlign: "center", color: "#FE825E" }}>
                        No Income Details has been assigned to Loan Request
                    </div>
                ) : (

                    <div className="card">
                        {/* {console.log(values.incomeDetails)} */}
                        {values.incomeDetails[0].incomeList.map((values, idx) => (
                            <><div className="card-header clear-fix">
                                {_.isEmpty(values) ? (
                                    <Skeleton width={200} />
                                ) : (
                                    <h6
                                        className="card-title float-left"
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <b>Income {idx + 1}</b>
                                    </h6>
                                )}
                            </div>

                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            {_.isEmpty(values) ? (
                                                <Skeleton count={3} width={300} />
                                            ) : (
                                                <dl className="row">
                                                    <DetailItem
                                                        label="Income Type"
                                                        labelSize={7}
                                                        valueSize={5}
                                                        value={values.incomeType} />
                                                    <DetailItem
                                                        label="Amount"
                                                        labelSize={7}
                                                        valueSize={5}
                                                        value={textFormatService.formatCurrency(Number(values.amount), 2)} />
                                                    <DetailItem
                                                        label="Salary 1"
                                                        labelSize={7}
                                                        valueSize={5}
                                                        value={textFormatService.formatCurrency(Number(values.amount), 2)} />
                                                    <DetailItem
                                                        label="Salary 2"
                                                        labelSize={7}
                                                        valueSize={5}
                                                        value={textFormatService.formatCurrency(Number(values.salary2), 2)} />
                                                    <DetailItem
                                                        label="Salary 3"
                                                        labelSize={7}
                                                        valueSize={5}
                                                        value={textFormatService.formatCurrency(Number(values.salary3), 2)} />

                                                </dl>

                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            {_.isEmpty(values) ? (
                                                <Skeleton count={3} width={300} />
                                            ) : (
                                                <dl className="row">
                                                    <DetailItem
                                                        label="Frequency"
                                                        labelSize={7}
                                                        valueSize={5}
                                                        value={values.frequencyIncome} />
                                                    <DetailItem
                                                        label="Currency"
                                                        labelSize={7}
                                                        valueSize={5}
                                                        value={values.currency} />
                                                    <DetailItem
                                                        label="Salary 4"
                                                        labelSize={7}
                                                        valueSize={5}
                                                        value={textFormatService.formatCurrency(Number(values.salary4), 2)} />
                                                    <DetailItem
                                                        label="Salary 5"
                                                        labelSize={7}
                                                        valueSize={5}
                                                        value={textFormatService.formatCurrency(Number(values.salary5), 2)} />
                                                    <DetailItem
                                                        label="Salary 6"
                                                        labelSize={7}
                                                        valueSize={5}
                                                        value={textFormatService.formatCurrency(Number(values.salary6), 2)} />
                                                </dl>
                                            )}
                                        </div>
                                    </div>
                                </div></>
                        ))}
                        {values.incomeDetails[0].expenseList.map((values, idx) => (
                            <React.Fragment>
                                <div className="card mt-3">
                                    <div className="card-header clear-fix">
                                        {_.isEmpty(values) ? (
                                            <Skeleton width={200} />
                                        ) : (
                                            <h6
                                                className="card-title float-left"
                                                style={{ marginBottom: "0px" }}
                                            >
                                                <b>Expense {idx + 1}</b>
                                            </h6>
                                        )}
                                    </div>

                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                {_.isEmpty(values) ? (
                                                    <Skeleton count={3} width={300} />
                                                ) : (
                                                    <dl className="row">
                                                        <DetailItem
                                                            label="Expense Type"
                                                            labelSize={7}
                                                            valueSize={5}
                                                            value={values.expenseType} />
                                                        <DetailItem
                                                            label="Amount"
                                                            labelSize={7}
                                                            valueSize={5}
                                                            value={textFormatService.formatCurrency(Number(values.expenseAmount), 2)} />
                                                    </dl>
                                                )}
                                            </div>
                                            <div className="col-md-6">
                                                {_.isEmpty(values) ? (
                                                    <Skeleton count={3} width={300} />
                                                ) : (
                                                    <dl className="row">
                                                        <DetailItem
                                                            label="Frequency"
                                                            labelSize={7}
                                                            valueSize={5}
                                                            value={values.frequencyExpense} />
                                                        <DetailItem
                                                            label="Currency"
                                                            labelSize={7}
                                                            valueSize={5}
                                                            value={values.expenseCurrency} />
                                                    </dl>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                        {values.incomeDetails[0].internalLiabilityList.map((values, idx) => (
                            <React.Fragment>
                                <div className="card mt-3">
                                    <div className="card-header clear-fix">
                                        {_.isEmpty(values) ? (
                                            <Skeleton width={200} />
                                        ) : (
                                            <h6
                                                className="card-title float-left"
                                                style={{ marginBottom: "0px" }}
                                            >
                                                <b>Loan Liability Internal {idx + 1}</b>
                                            </h6>
                                        )}
                                    </div>

                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                {_.isEmpty(values) ? (
                                                    <Skeleton count={3} width={300} />
                                                ) : (
                                                    <dl className="row">
                                                        <DetailItem
                                                            label="Number of Loans"
                                                            labelSize={7}
                                                            valueSize={5}
                                                            value={values.numberOfLoans} />
                                                        <DetailItem
                                                            label="Repayment Frequency"
                                                            labelSize={7}
                                                            valueSize={5}
                                                            value={values.repaymentFrequency} />
                                                        <DetailItem
                                                            label="Currency"
                                                            labelSize={7}
                                                            valueSize={5}
                                                            value={values.internalCurrency} />
                                                    </dl>
                                                )}
                                            </div>
                                            <div className="col-md-6">
                                                {_.isEmpty(values) ? (
                                                    <Skeleton count={3} width={300} />
                                                ) : (
                                                    <dl className="row">
                                                        <DetailItem
                                                            label="Total Outstanding"
                                                            labelSize={7}
                                                            valueSize={5}
                                                            value={textFormatService.formatCurrency(Number(values.totalOutstanding), 2)} />
                                                        <DetailItem
                                                            label="Repayment Amount"
                                                            labelSize={7}
                                                            valueSize={5}
                                                            value={textFormatService.formatCurrency(Number(values.internalAmount), 2)} />
                                                    </dl>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </React.Fragment>
                        ))}
                        {values.incomeDetails[0].externalLiabilityList.map((values, idx) => (
                            <React.Fragment>
                                <div className="card mt-3">
                                    <div className="card-header clear-fix">
                                        {_.isEmpty(values) ? (
                                            <Skeleton width={200} />
                                        ) : (
                                            <h6
                                                className="card-title float-left"
                                                style={{ marginBottom: "0px" }}
                                            >
                                                <b>Loan Liability External {idx + 1}</b>
                                            </h6>
                                        )}
                                    </div>

                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                {_.isEmpty(values) ? (
                                                    <Skeleton count={3} width={300} />
                                                ) : (
                                                    <dl className="row">
                                                        <DetailItem
                                                            label="Number of Loans"
                                                            labelSize={7}
                                                            valueSize={5}
                                                            value={values.noOfLoansExt} />
                                                        <DetailItem
                                                            label="Repayment Frequency"
                                                            labelSize={7}
                                                            valueSize={5}
                                                            value={values.frequencyExt} />
                                                        <DetailItem
                                                            label="Currency"
                                                            labelSize={7}
                                                            valueSize={5}
                                                            value={values.currencyExt} />
                                                    </dl>
                                                )}
                                            </div>
                                            <div className="col-md-6">
                                                {_.isEmpty(values) ? (
                                                    <Skeleton count={3} width={300} />
                                                ) : (
                                                    <dl className="row">
                                                        <DetailItem
                                                            label="Total Outstanding"
                                                            labelSize={7}
                                                            valueSize={5}
                                                            value={textFormatService.formatCurrency(Number(values.totalOutstandingExt), 2)} />
                                                        <DetailItem
                                                            label="Repayment Amount"
                                                            labelSize={7}
                                                            valueSize={5}
                                                            value={textFormatService.formatCurrency(Number(values.repaymentAmountExt), 2)} />
                                                    </dl>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                )}
            </Fragment>
        );

    }
}

export default PersonalDetails;