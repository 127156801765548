import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import { ActionTypes } from "../../../enums/actionTypes";
import swal from "sweetalert";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
// import usersService from '../../../services/auth/usersService'
import { toast } from "react-toastify";
import { getCurrentUsersRoles } from "../../../services/auth/authService";
import rolesService from "../../../services/auth/rolesService";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import CheckboxTree from "react-checkbox-tree";

interface IUserRoleDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  showTrail: any;
  item: any;
}

interface IUserRoleDetailState {
  item: any;
  updatingStatus: boolean;
  expanded: any;
}

class UserRoleDetail extends Component<
  IUserRoleDetailProps,
  IUserRoleDetailState
> {
  constructor(props: IUserRoleDetailProps) {
    super(props);
    this.state = {
      item: {},
      updatingStatus: false,
      expanded: [],
    };
  }

  static getDerivedStateFromProps(nextProps: IUserRoleDetailProps, prevState) {
    // console.log("current props: ", nextProps.item);
    return {
      item: nextProps.item,
    };
  }

  onHandleEnableOrDisable = (
    e: any,
    actionType: ActionTypes,
    entityName: string
  ) => {
    this.setState({ updatingStatus: true });
    if (!actionType) {
      this.handleEnableOrDisableItem(this.props.item);
    } else {
      swal({
        title: "Confirm!",
        text: `Are you sure you want to proceed to ${ActionTypes[actionType]} this ${entityName}?`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode: true,
      }).then((resp) => {
        if (resp) {
          // console.log("cancelling action...");
          this.setState({ updatingStatus: false });
        } else {
          this.handleEnableOrDisableItem(this.state.item);
        }
      });
    }
  };

  handleEnableOrDisableItem = async (_item: any) => {
    try {
      let approvalResponse: any = {};
      this.setState({ updatingStatus: true });
      await activityTrailService.saveActivityTrail({
        actionType: `${_item.status === "Inactive" ? "Enable" : "Disable"
          } UserRole`,
        description: `Clicked on ${_item.status === "Inactive" ? "Enable" : "Disable"
          } to ${_item.status === "Inactive" ? "Enable" : "Disable"} UserRole ${_item.name
          }`,
      });
      switch (_item.status) {
        case "Inactive":
          approvalResponse = await rolesService.enableRole(_item.id);
          if (approvalResponse.data.isLoggedForApproval === false) {
            _item.status = "Active";
          }
          break;
        case "Active":
          approvalResponse = await rolesService.disableRole(_item.id);
          if (approvalResponse.data.isLoggedForApproval === false) {
            _item.status = "Inactive";
          }
          break;
        default:
          approvalResponse = await rolesService.enableRole(_item.id);
          if (approvalResponse.data.isLoggedForApproval === false) {
            _item.status = "Active";
          }
          break;
      }
      if (approvalResponse.data.isLoggedForApproval === true) {
        toast.warn("Action has been successfully logged for Approval!", {
          autoClose: 6000,
          type: toast.TYPE.WARNING,
          position: "top-center",
          hideProgressBar: false,
        });
      } else {
        toast.info(
          `UserRole ${_item.name} ${_item.status === "Active" ? "Enabled" : "Disabled"
          } successfully! `,
          {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          }
        );
      }
      this.setState({ updatingStatus: false, item: _item });
    } catch (ex) {
      this.setState({ updatingStatus: false, item: _item });
      //   console.log("caught exception", ex);
      //   if (
      //     ex.response &&
      //     ex.response.status >= 400 &&
      //     ex.response.status <= 499
      //   ) {
      //     toast.error("Bad Request");
      //     console.log(ex.response);
      //     // errors["response"] = "Internal server error.";
      //     // console.log(errors);
      //   } else {
      //     toast.error("Internal server error. Please contact the admin.");
      //     //this.setState({ errors, fetching: false });
      //   }
    }
  };

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      showTrail,
      item,
    } = this.props;
    const { updatingStatus } = this.state;

    let treeCollection: any = [];
    let checked: any = [];
    const functions = this.state.item.functions;
    if (functions) {
      let parentFunctions = functions.filter((func) => func.parentId === 0);
      treeCollection = parentFunctions.map((pFunc) => {
        let newSet: any = {};
        newSet = {
          value: pFunc.displayName,
          label: pFunc.displayName,
          showCheckbox: false,
          children: functions
            .filter((func) => func.parentId === pFunc.id)
            .map((filtered) => {
              const child: any = {};
              child.value = filtered.description;
              child.label = filtered.displayName;
              child.showCheckbox = false;
              checked.push(filtered.description);
              return child;
            }),
        };
        return newSet;
        // console.log("New set of nodes", newSet);
      });
      //   console.log("generated Checked", checked);
    }
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="fas fa-user-shield fa-sm cyan-text mr-3" />
                UserRole Details -{" "}
                <span className="font-weight-bold">{item.name}</span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-header clear-fix">
                  {_.isEmpty(item) ? (
                    <Skeleton width={200} />
                  ) : (
                    <h6
                      className="card-title float-left"
                      style={{ marginBottom: "0px" }}
                    >
                      <b>General Information</b>
                    </h6>
                  )}
                  <div className="float-right">
                    {_.isEmpty(item) ? (
                      <Skeleton width={100} />
                    ) : (
                      <span
                        className="badge btn-primary"
                        onClick={showTrail}
                        style={{
                          cursor: "pointer",
                          visibility: getCurrentUsersRoles().includes(
                            "viewaudittrailitems"
                          )
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        View Trail
                      </span>
                    )}
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="form-group col-md-12 row">
                      {_.isEmpty(item) ? (
                        <Skeleton count={1} width={300} />
                      ) : (
                        <DetailItem label="Name" value={item.name} />
                      )}
                    </div>
                    <div className="form-group col-md-12 row">
                      {_.isEmpty(item) ? (
                        <Skeleton count={1} width={300} />
                      ) : (
                        <DetailItem label="Code" value={item.code} />
                      )}
                    </div>
                    <div className="form-group col-md-12 row">
                      {_.isEmpty(item) ? (
                        <Skeleton count={1} width={300} />
                      ) : (
                        <DetailItem label="Role Visibility" value={item.roleVisibility} />
                      )}
                    </div>
                    <div className="form-group col-md-12 row">
                      {_.isEmpty(item) ? (
                        <Skeleton count={1} width={300} />
                      ) : (
                        <DetailItem
                          label="Description"
                          value={item.description}
                        />
                      )}
                    </div>
                    <div className="form-group col-md-12 row">
                      {_.isEmpty(item) ? (
                        <Skeleton count={1} width={300} />
                      ) : (
                        <DetailItem
                          label="Status"
                          value={
                            (item ? item.status : "Active") === "Active" ? (
                              <span className="badge badge-success">
                                {item ? item.status : ""}
                              </span>
                            ) : (
                              <span className="badge badge-danger">
                                {item ? item.status : ""}
                              </span>
                            )
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  {_.isEmpty(item) ? (
                    <Skeleton width={200} />
                  ) : (
                    <h6 className="card-title" style={{ marginBottom: "0px" }}>
                      Other Information
                    </h6>
                  )}
                </div>

                <div className="card-body">
                  {_.isEmpty(this.state.item) ? (
                    <Skeleton count={1} height={500} />
                  ) : (
                    <div className="form-group row">
                      <label
                        htmlFor="inputFunctions"
                        className="col-md-3 col-form-label"
                      >
                        <b>Functions:</b>
                      </label>
                      <CheckboxTree
                        icons={{
                          parentClose: (
                            <i className="icon-folder2 orange-text"></i>
                          ),
                          parentOpen: (
                            <i className="icon-folder-open orange-text"></i>
                          ),
                          leaf: <i className="icon-file-text-o blue-text"></i>,
                          halfCheck: <i className="icon-square"></i>,
                          check: <i className="icon-check_box green-text"></i>,
                        }}
                        nodes={treeCollection}
                        checked={checked}
                        expanded={this.state.expanded}
                        // onCheck={() => console.log("try check")}
                        onExpand={(expanded) => this.setState({ expanded })}
                        iconsClass="fa5"
                        disabled={true}
                        showExpandAll={true}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
              {item.status === "Inactive" &&
                getCurrentUsersRoles().includes("enableuserrole") && (
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    disabled={updatingStatus}
                    onClick={(e) =>
                      this.onHandleEnableOrDisable(
                        e,
                        item.status === "Inactive"
                          ? ActionTypes.ENABLE
                          : ActionTypes.DISABLE,
                        "User Role"
                      )
                    }
                  >
                    <i className="far fa-edit mr-3" />
                    Enable
                  </Button>
                )}
              {item.status === "Active" &&
                getCurrentUsersRoles().includes("disableuserrole") && (
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    disabled={updatingStatus}
                    onClick={(e) =>
                      this.onHandleEnableOrDisable(
                        e,
                        item.status === "Inactive"
                          ? ActionTypes.ENABLE
                          : ActionTypes.DISABLE,
                        "User Role"
                      )
                    }
                  >
                    <i className="far fa-edit mr-3" />
                    Disable
                  </Button>
                )}
              {getCurrentUsersRoles().includes("edituserrole") && (
                <Button
                  size="sm"
                  variant="primary"
                  disabled={updatingStatus}
                  onClick={toggleEditModal}
                >
                  <i className="far fa-edit mr-3" />
                  Edit
                </Button>
              )}
            </React.Fragment>
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default UserRoleDetail;
