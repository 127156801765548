import React, { Component, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button, Accordion, Card } from "react-bootstrap";
import { splitAtUpper } from "../../../services/utility/textFormatService";
import { ContextAwareToggle } from "../appraisalPlan/appraisalPlanDetail";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { dataEntryFields as dataEntry } from "./dataEntryFields";

interface IWorkFlowDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  showTrail: any;
  item: any;
}

interface IWorkFlowDetailState {
  item: any;
  updatingStatus: boolean;
}

class WorkFlowDetail extends Component<
  IWorkFlowDetailProps,
  IWorkFlowDetailState
> {
  state = {
    item: {},
    updatingStatus: false,
  };

  static getDerivedStateFromProps(nextProps: IWorkFlowDetailProps, prevState) {
    return {
      item: nextProps.item,
    };
  }

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      item,
    } = this.props;
    const { updatingStatus } = this.state;

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="icon icon--columns s-18 mr-3" />
                Data Entry Config Details -{" "}
                <span className="font-weight-bold">{item.name}</span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <React.Fragment>
            <div className="card">

              {/* WorkFlow Steps */}
              {/* <Accordion defaultActiveKey="0">
                <Card>
                  <ContextAwareToggle
                    eventKey={`0`}
                    header={
                      <React.Fragment>
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                          style={{ color: "#878a8a" }}
                        ></i>{" "}
                        Workflow Steps
                      </React.Fragment>
                    }
                  ></ContextAwareToggle>
                  <Accordion.Collapse eventKey={`0`}>
                    <Card.Body>
                      <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                        <div className="row">
                          <div className="col-md-12">
                            {_.isEmpty(item.workflowSteps) ? (
                              <div style={{ textAlign: "center", color: "#FE825E" }}>
                                No WorkFlow Step Available
                              </div>
                            ) : (
                              <Fragment>
                                {item.workflowSteps?.map(
                                  (step) => (
                                    <div key={step} style={{ marginTop: '3px' }}>
                                      <div
                                        className="custom-control custom-checkbox"
                                        key={step}
                                      >
                                        <input
                                          id={step}
                                          name={step}
                                          className="custom-control-input"
                                          type="checkbox"
                                          checked={true}
                                          disabled={true}
                                        />
                                        <label
                                          className="custom-control-label pt-1"
                                          htmlFor={step}
                                        >
                                          {step}
                                        </label>
                                      </div>
                                    </div>
                                  )
                                )}
                              </Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion> */}

              {/* DataEntry Tabs */}
              <Accordion defaultActiveKey="0">
                <Card>
                  <ContextAwareToggle
                    eventKey={`0`}
                    header={
                      <React.Fragment>
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                          style={{ color: "#878a8a" }}
                        ></i>{" "}
                        Data Entry Tabs
                      </React.Fragment>
                    }
                  ></ContextAwareToggle>
                  <Accordion.Collapse eventKey={`0`}>
                    <Card.Body>
                      <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                        <div className="row">
                          <div className="col-md-12">
                            {_.isEmpty(item.dataEntryTabs) ? (
                              <div style={{ textAlign: "center", color: "#FE825E" }}>
                                No Data Entry Tab Available
                              </div>
                            ) : (
                              <Fragment>
                                {item.dataEntryTabs?.map(
                                  (tab) => (
                                    <div key={tab} style={{ marginTop: '3px' }}>
                                      <div
                                        className="custom-control custom-checkbox"
                                        key={tab}
                                      >
                                        <input
                                          id={tab}
                                          name={tab}
                                          className="custom-control-input"
                                          type="checkbox"
                                          checked={true}
                                          disabled={true}
                                        />
                                        <label
                                          className="custom-control-label pt-1"
                                          htmlFor={tab}
                                        >
                                          {splitAtUpper(tab)}
                                        </label>
                                      </div>
                                    </div>
                                  )
                                )}
                              </Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              {/* DataEntry Sections */}
              <Accordion defaultActiveKey="">
                <Card>
                  <ContextAwareToggle
                    eventKey={`0`}
                    header={
                      <React.Fragment>
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                          style={{ color: "#878a8a" }}
                        ></i>{" "}
                        Mandatory Fields
                      </React.Fragment>
                    }
                  ></ContextAwareToggle>
                  <Accordion.Collapse eventKey={`0`}>
                    <Card.Body>
                      <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                        <div className="row">
                          <div className="col-md-12">
                            {_.isEmpty(item.dataEntrySections) ? (
                              <div style={{ textAlign: "center", color: "#FE825E" }}>
                                No Data Entry Section Available
                              </div>
                            ) : (
                              <React.Fragment>
                                <Tabs
                                  defaultActiveKey={item.dataEntrySections[0].sectionName}
                                  variant='pills'
                                  fill
                                >
                                  {item.dataEntrySections.map(
                                    (item) => (
                                      <Tab eventKey={item.sectionName} title={splitAtUpper(item.sectionName)} key={item.sectionName}>
                                        <Fragment>
                                          <span className="col-sm-1 ml-1"></span>
                                          {item.inputFields?.filter(x => x.mandatory === true)
                                            .map((field) => (
                                              <div key={field.fieldName} style={{ marginTop: '5px' }}>
                                                <div
                                                  key={field.fieldName}
                                                  className="custom-control custom-checkbox"
                                                >
                                                  <input
                                                    id={field.fieldName}
                                                    name={field.fieldName}
                                                    value={field.fieldName}
                                                    className="custom-control-input"
                                                    type="checkbox"
                                                    checked={true}
                                                    disabled={true}
                                                  />
                                                  <label
                                                    className="custom-control-label"
                                                    htmlFor={field.fieldName}
                                                  >
                                                    {
                                                      dataEntry
                                                        .filter(x => x.value === item.sectionName)[0]?.fields
                                                        .find(y => y.value === field.fieldName)?.label
                                                    }
                                                  </label>
                                                </div>
                                              </div>
                                            ))}
                                        </Fragment>
                                      </Tab>
                                    )
                                  )}
                                </Tabs>
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </React.Fragment>
        </Modal.Body >
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
              <Button
                size="sm"
                variant="primary"
                disabled={updatingStatus}
                onClick={toggleEditModal}
              >
                <i className="far fa-edit mr-3" />
                Edit
              </Button>
            </React.Fragment>
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal >
    );
  }
}

export default WorkFlowDetail;
