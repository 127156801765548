import * as React from "react";
import _ from "lodash";
import { Fragment } from "react";
import {
    IFilterData,
    BaseListComponentState,
} from "../../../components/BaseListComponent";
import {
    createFilterData,
    mapEntityList,
} from "./../../../services/utility/autoMapperService";
import BaseListComponent from "./../../../components/BaseListComponent";
import ItemsTable, {
    filterFieldData,
} from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "./../../../components/tableComponents/tableFilterPane";
import LoanCategoryService from "../../../services/hybridAppraisal/loanCategoryService";
import LoanCategoryDetail from "./loanCategoryDetails";
import EditLoanCategory from "./editLoanCategory";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../../services/auth/authService";

export interface LoanCategoryProps { }

export interface LoanCategoryState extends BaseListComponentState {
    data: IFilterData;
    currentPage: number;
    pageSize: number;
    tableItems: any;
    totalSize: number;
    fetchingDetail: boolean;
    filterValueInput: filterFieldData;
    item: any;
    item_: any;
    toUpdate: boolean;
}

class LoanCategoryList extends BaseListComponent<LoanCategoryState> { }

class ViewLoanCategory extends React.Component<LoanCategoryProps, LoanCategoryState> {
    _isMounted = false;
    constructor(props: LoanCategoryProps) {
        super(props);
        this.state = {
            data: createFilterData([]) as IFilterData,
            currentPage: 1,
            pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
            fetching: false,
            fetchingDetail: false,
            tableItems: [],
            totalSize: 0,
            filterValueInput: [],
            item: {},
            item_: {},
            showDetailsModal: false,
            showEditModal: false,
            showTableFilterModal: false,
            showTrailModal: false,
            toUpdate: true,
        };
    }

    handlePageChange = async (currentPage: number, pageSize: number, data?: IFilterData) => {
        currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
        pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
        this.setState({ fetching: true });
        const decodedToken = decodeToken(getCurrentUserSession().token || "");
        let dataClone: IFilterData = typeof data === "undefined" ? { ...this.state.data } : { ...data };
        // console.log("raw",data)
        dataClone["InstitutionCode"] = { item1: "=", item2: decodedToken?.["InstitutionCode"] }

        try {
            const LoanCategory = await LoanCategoryService.filterLoanCategory(
                dataClone,
                pageSize,
                currentPage
            );
            this.setState({
                tableItems: mapEntityList({
                    entityList: LoanCategory.data.item2,
                    properties: [
                        { oldName: "name", newName: "Name" },
                        { oldName: "loanProductName", newName: "LoanProduct" },
                        { oldName: "loanLimit", newName: "eligibleLoanLimit" },
                        { oldName: "loanTenor", newName: "eligibleLoanTenor" },
                        { oldName: "interestRate", newName: "interestRate" },
                    ],
                }),
                currentPage,
                pageSize,
                totalSize: LoanCategory.data.item1,
                fetching: false,
            });
        } catch (ex) {
            if (
                ex.response &&
                ex.response.status >= 400 &&
                ex.response.status <= 499
            ) {
                this.setState({ fetching: false });
            } else {
                this.setState({ fetching: false });
            }
        } finally {
            this.setState({ fetching: false });
        }
    };

    handleOnExport = async (type: string) => { };

    fetchItem = async (item: any) => {
        this.setState({ fetchingDetail: true });
        const loanCategorys = await LoanCategoryService.retrieveById(item.id);
        let loanCategorysDetail = loanCategorys.data

        this.setState({ item: loanCategorysDetail, fetchingDetail: false });
    };

    getFilteredItems = (data: IFilterData): IFilterData => {
        let filteredItems: IFilterData = {};
        Object.keys(data).forEach((key) => {
            if (data[key].item2 !== "") {
                filteredItems[key] = data[key];
            }
        });
        return filteredItems;
    };

    async componentDidMount() {
        this.handlePageChange(this.state.currentPage, this.state.pageSize);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const {
            currentPage,
            pageSize,
            tableItems,
            totalSize,
            fetching,
            item,
            item_,
            fetchingDetail,
        } = this.state;
        return (
            <Fragment>
                <div className="container-fluid relative animatedParent animateOnce">
                    <div className="animated fadeInUpShort go">
                        <div className="row my-3 mx-2">
                            <h3>
                                <b>Loan classification</b>
                            </h3>
                        </div>
                        <LoanCategoryList
                            pageChangeHandler={this.handlePageChange}
                            fetchingDetail={fetchingDetail}
                            initialValues={{
                                data: this.state.data,
                                fetching: false,
                                showDetailsModal: false,
                                showEditModal: false,
                                showTableFilterModal: false,
                                showTrailModal: false,
                                tableItems: [],
                                filterValueInput: this.state.filterValueInput,
                                item: {},
                            }}
                            tableItems={tableItems}
                            item={item}
                            DetailsModal={({
                                item,
                                onToggleDetailsModal,
                                onToggleEditModal,
                                showDetailsModal,
                                showEditModal
                            }) => (
                                <Fragment>
                                    <LoanCategoryDetail
                                        showDetailsModal={showDetailsModal}
                                        toggleDetailsModal={onToggleDetailsModal}
                                        toggleEditModal={onToggleEditModal}
                                        item={item}
                                    />
                                    <EditLoanCategory
                                        showEditModal={showEditModal}
                                        toggleEditModal={(reloadDetails: boolean, item: any) => {
                                            onToggleEditModal();
                                            if (reloadDetails === true) {
                                                this.fetchItem(item);
                                            }
                                            this.setState({
                                                showDetailsModal: !this.state.showDetailsModal,
                                            });
                                        }}
                                        item={item}
                                    />
                                </Fragment>
                            )}
                            ListPayLoad={({
                                data,
                                showTableFilterModal,
                                tableItems,
                                onToggleDetailsModal,
                                onToggleTableFilterModal,
                                onHandleFilterItemRemove,
                                filterValueInput,
                            }) => (
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card no-b">
                                            <div className="card-body">
                                                <div className="card-title">
                                                    <div id="list-filters" className="ml-4 mr-4">
                                                        <TableFilterPane
                                                            filteredItems={this.getFilteredItems(data)}
                                                            handleFilterRemove={onHandleFilterItemRemove}
                                                        />
                                                    </div>
                                                    <div id="list-table">
                                                        <ItemsTable
                                                            data={data}
                                                            items={tableItems}
                                                            filterValueInput={filterValueInput}
                                                            showTableFiltermodal={showTableFilterModal}
                                                            toggleTableFiltermodal={onToggleTableFilterModal}
                                                            name="LoanCategory"
                                                            currentPage={currentPage}
                                                            pageSize={pageSize}
                                                            totalSize={totalSize}
                                                            fetching={fetching}
                                                            handlePageChange={this.handlePageChange}
                                                            onViewDetails={(item: any) => {
                                                                this.fetchItem(item);
                                                                onToggleDetailsModal();
                                                            }}
                                                            handleOnExport={this.handleOnExport}
                                                            timeColumn={["dateCreated"]}
                                                            amountColumn={["eligibleLoanLimit"]}
                                                            amountInNaira={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        ></LoanCategoryList>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default ViewLoanCategory;
