import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import DetailItem from "../../../components/detailItem";
import DetailItemsTable from "../../../components/tableComponents/detailItemsTable";
import textFormatService from "../../../services/utility/textFormatService";
import reportService from "../../../services/bvn/reportService";
import { mapEntityList } from "../../../services/utility/autoMapperService";
import { retrieveAccountsByBVN } from "./../../../services/mandate/bvnService";
import CustomerBvnReportDetal from "./customerBvnReportDetail";
import bvnGreyListService from "../../../services/mandate/bvnGreyListService";

export interface BvnReportDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  item: any;
}

export interface BvnReportDetailState {
  mandatesCurrentPage: number;
  mandatesFetching: boolean;
  mandatesCount: number;
  mandates: any;
  showMandateInfoModal: boolean;
  mandate: any;
  mandateItemFetching: boolean;
}

class BvnReportDetail extends React.Component<
  BvnReportDetailProps,
  BvnReportDetailState
> {
  constructor(props: BvnReportDetailProps) {
    super(props);
    this.state = {
      mandatesCurrentPage: 1,
      mandatesFetching: false,
      mandatesCount: 0,
      mandates: [],
      showMandateInfoModal: false,
      mandate: {},
      mandateItemFetching: false,
    };
  }
  fetchMandateInfoById = async (item) => {
    try {
      this.setState({ mandateItemFetching: true });
      const mandate = await reportService.retrieveCustomersBvnReportByBVN(
        item.BVN
      );
      this.setState({ mandate: mandate.data });
      const _mandate = { ...this.state.mandate };
      var _bvnaccounts;
      var _greylist;
      _mandate.bvnAccounts = [];
      _mandate.greyList = {};
      try {
        _bvnaccounts = await retrieveAccountsByBVN(_mandate.bvn);
        _greylist = await bvnGreyListService.retrieveByBvn(_mandate.bvn);
      } catch (error) {}
      // try {
      // } catch (error) {}
      _mandate.bvnAccounts = _bvnaccounts?.data[0].linkedAccounts;
      _mandate.greyList = _greylist?.data;
      // console.log("final payload2: ", _mandate);
      this.setState({ mandate: _mandate });
    } catch (error) {
    } finally {
      this.setState({ mandateItemFetching: false });
    }
  };

  handleOnToggleMandateInfoModal = () => {
    this.setState({ showMandateInfoModal: !this.state.showMandateInfoModal });
    if (this.state.showMandateInfoModal === true) {
      this.setState({ mandate: {} });
    }
  };

  handleOnDetailPageChange = async (currentPage: number) => {
    this.setState({ mandatesFetching: true });
    try {
      const payload: any = {};
      payload["institutionId"] = {
        item1: "=",
        item2: this.props.item.id,
      };
      const mandates = await reportService.retrieveCustomersBvnReport(
        this.props.item.id,
        currentPage,
        Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10
      );
      mandates.data.item2.forEach((element) => {
        element["greyListed"] = String(element.greyListCount > 0);
      });
      // console.log("check mandates: ", mandates.data.item2);
      this.setState({
        mandates: mapEntityList({
          entityList: mandates.data.item2,
          properties: [
            { oldName: "customerName", newName: "Name" },
            { oldName: "bvn", newName: "BVN" },
            { oldName: "phoneNumber", newName: "phoneNumber" },
            { oldName: "totalAccountsLinked", newName: "totalAccountsLinked" },
            { oldName: "totalMandates", newName: "totalMandates" },
            {
              isTag: true,
              oldName: "greyListed",
              newName: "greyListed",
              success: "Active",
              danger: "Inactive",
              default: "default",
              primary: "Confirmed",
              secondary: "secondary",
              warning: "true",
              light: "false",
              dark: "dark",
            },
          ],
        }),
        mandatesCurrentPage: currentPage,
        mandatesCount: mandates.data.item1,
        mandatesFetching: false,
      });
    } catch (error) {
    } finally {
      // this.setState({ mandatesFetching: false });
    }
  };

  async componentDidMount() {
    if (this.props.item?.id) {
      this.handleOnDetailPageChange(this.state.mandatesCurrentPage);
    }
  }
  render() {
    const { showDetailsModal, toggleDetailsModal, item } = this.props;
    return (
      <React.Fragment>
        <CustomerBvnReportDetal
          showDetailsModal={this.state.showMandateInfoModal}
          toggleDetailsModal={this.handleOnToggleMandateInfoModal}
          item={this.state.mandate}
        />
        <Modal
          size="xl"
          backdrop="static"
          show={showDetailsModal}
          onHide={toggleDetailsModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {_.isEmpty(item) ? (
                <Skeleton width={500} />
              ) : (
                <h5>
                  <i className="fas fa-receipt fa-sm s-18 mr-3" />
                  <b>{item.name}</b> Report Details
                </h5>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card">
              <div className="card-header clear-fix">
                {_.isEmpty(item) ? (
                  <Skeleton width={200} />
                ) : (
                  <h6
                    className="card-title float-left"
                    style={{ marginBottom: "0px" }}
                  >
                    <b>General Information</b>
                  </h6>
                )}
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    {_.isEmpty(item) ? (
                      <Skeleton count={6} width={300} />
                    ) : (
                      <dl className="row">
                        <DetailItem
                          label="Institution"
                          labelSize={6}
                          valueSize={6}
                          value={item.name}
                        />
                        <DetailItem
                          label="Mandate Limit"
                          labelSize={6}
                          valueSize={6}
                          value={textFormatService.splitAtUpper(
                            item.mandateLimit
                          )}
                        />
                        <DetailItem
                          label="Category"
                          labelSize={6}
                          valueSize={6}
                          value={item.category}
                        />
                        <DetailItem
                          label="GreyListCap"
                          labelSize={6}
                          valueSize={6}
                          value={item.greyListCap}
                        />
                      </dl>
                    )}
                  </div>
                  <div className="col-md-6">
                    {_.isEmpty(item) ? (
                      <Skeleton count={6} width={300} />
                    ) : (
                      <dl className="row">
                        <DetailItem
                          label="Total Accounts Linked"
                          labelSize={6}
                          valueSize={6}
                          value={item.totalAccountsLinked}
                        />
                        <DetailItem
                          label="Total Distinct Customers"
                          labelSize={6}
                          valueSize={6}
                          value={item.totalBVN}
                        />
                        <DetailItem
                          label="Total BVN GreyListed"
                          labelSize={6}
                          valueSize={6}
                          value={item.totalGreyListed}
                        />
                      </dl>
                    )}
                  </div>
                </div>
              </div>
              <DetailItemsTable
                title="Customers"
                item={item}
                handleOnPageChange={this.handleOnDetailPageChange}
                content={this.state.mandates}
                contentCount={this.state.mandatesCount}
                page={this.state.mandatesCurrentPage}
                fetching={this.state.mandatesFetching}
                amountColumn={["loanAmount"]}
                hasDetailModal={true}
                onViewDetails={(mandate) => {
                  this.fetchMandateInfoById(mandate);
                  this.handleOnToggleMandateInfoModal();
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default BvnReportDetail;
