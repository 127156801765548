import React from "react";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import { getSupportingDocumentsByLoanReference, retrieveById } from "../../../../services/mandate/supportingDocumentService";
import DocumentPreviewer from "../../../dispute/documentPreviewer";
import AddSupportingDocument from "./addSupportingDocument";

interface ISupportingDocumentsProps {
  values: any;
  item: any;
  updateItemDocs: any;
  deleteItemDocs: any;
  tempDocs: any;
  tempDeletedDocs: number[];
  // canEditItem: boolean;
  showDocumentsModal: boolean;
  toggleDocumentsModal: any;
}
interface ISupportingDocumentsState {
  item: any;
  document: any;
  showPreview: boolean;
  showDocPatchModal: boolean;
  supportingDocuments: any;
  fetchingSupportingDocuments: boolean;
  ownUpdate: boolean;
}

class SupportingDocuments extends React.Component<
  ISupportingDocumentsProps,
  ISupportingDocumentsState
> {
  constructor(props: ISupportingDocumentsProps) {
    super(props);
    this.state = {
      item: {},
      document: {},
      showPreview: false,
      showDocPatchModal: false,
      supportingDocuments: [],
      fetchingSupportingDocuments: true,
      ownUpdate: false
    };
  }

  static getDerivedStateFromProps(
    nextProps: ISupportingDocumentsProps,
    prevState
  ) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    // if (nextProps.item && nextProps.item.CustomerID != prevState.Item?.CustomerID) {
    return { item: nextProps.item };
    // }
    // return null;
  }

  async loadDocs(nextProps: ISupportingDocumentsProps) {
    // const loanDetails = JSON.parse(nextProps.item.LoanDetails);


    try {
      const { values } = this.props;
      let reference = `hyb_pre_${values.requestIdentifier}`
      let reference2 = `hyb_post_${values.requestIdentifier}`
      let reference3 = `hyb_bs_${values.requestIdentifier}`
      let reference4 = `hyb_sys_${values.requestIdentifier}`
      const response = await Promise.allSettled([
        getSupportingDocumentsByLoanReference(reference, values.id),
        getSupportingDocumentsByLoanReference(reference2, values.id),
        getSupportingDocumentsByLoanReference(reference3, values.id),
        getSupportingDocumentsByLoanReference(reference4, values.id)
      ])

      const supportingDocumentsPre = response[0].status == "fulfilled" ? response[0].value.data : [];
      const supportingDocumentsPost = response[1].status == "fulfilled" ? response[1].value.data : [];
      const supportingDocumentsBs = response[2].status == "fulfilled" ? response[2].value.data : [];
      const supportingDocumentsSys = response[3].status == "fulfilled" ? response[3].value.data : [];
      const supportingDocuments = supportingDocumentsPre.concat(supportingDocumentsPost).concat(supportingDocumentsBs).concat(supportingDocumentsSys)

      //add new docs to this array
      let supportingDocs = supportingDocuments
      if (nextProps.tempDocs.length > 0) {
        nextProps.tempDocs.forEach(doc => supportingDocs.push(doc));
      }

      //remove docs from array if they have been deleted temporarily
      if (nextProps.tempDeletedDocs.length > 0) {
        supportingDocs = supportingDocs.filter((doc) => {
          return !nextProps.tempDeletedDocs.some((deletedDocId) => {
            return doc.id === deletedDocId;
          });
        });
      }

      this.setState({
        supportingDocuments: supportingDocs,
        fetchingSupportingDocuments: false,
        ownUpdate: true
      });
    } catch (error) {
      this.setState({ fetchingSupportingDocuments: false, ownUpdate: true });
    }

  }

  toggleShowPreview = () => {
    this.setState({ showPreview: !this.state.showPreview, ownUpdate: true });
  };

  toggleShowDocModal = () => {
    this.setState({ showDocPatchModal: !this.state.showDocPatchModal, ownUpdate: true });
  };

  handleDeleteDocument = (document) => {
    if (document.id === undefined) {
      this.props.deleteItemDocs(document, -1);
    } else {
      this.props.deleteItemDocs(null, document.id);
    }
  };

  handleOnDocumentSelect = async (document) => {
    try {
      // console.log("document ",document)
      if (document.document == null) {
        var doc = await retrieveById(document.id);
        document["document"] = doc.data.document;
      }
      switch (document.extensionType) {
        case "PNG":
        case "JPG":
        case "GIF":
          document[
            "downloadname"
          ] = `${document.name}_${document.loanReference}.jpg`;
          document["data"] = `data:image/jpg;base64,${document.document}`;
          document["payload"] = (
            <img
              src={document.data}
              alt={document.name}
              width="100%"
              height="100%"
              className="image"
            />
          );
          break;
        case "DOCX":
        case "PDF":
          var data = `data:application/pdf;base64,${document.document}`;
          document["payload"] = (
            <object
              width="100%"
              height="500px"
              data={data}
              type="application/pdf"
              className="internal"
            >
              <embed src={data} type="application/pdf" />
            </object>
          );
          break;
        case "HTML":
          document["payload"] = (
            <div
              dangerouslySetInnerHTML={{
                __html: atob(document.document),
              }}
            />
          );
          break;
        default:
          break;
      }
      this.setState({ document, showPreview: true, ownUpdate: true });
    } catch (error) {
      // console.log(error);
    }
  };

  generateExtensionIcon = (document) => {
    switch (document.extensionType) {
      case "PNG":
      case "JPG":
      case "GIF":
        return (
          <i
            className="fas fa-file-image cyan-text fa-lg"
            style={{ cursor: "pointer" }}
            onClick={(e) => this.handleOnDocumentSelect(document)}
          ></i>
        );
      case "PDF":
        return (
          <i
            className="fas fa-file-pdf red-text fa-lg"
            style={{ cursor: "pointer" }}
            onClick={(e) => this.handleOnDocumentSelect(document)}
          ></i>
        );
      case "DOC":
      case "DOCX":
        return (
          <i
            className="fas fa-file-word blue-text fa-lg"
            style={{ cursor: "pointer" }}
            onClick={(e) => this.handleOnDocumentSelect(document)}
          ></i>
        );
      default:
        return (
          <i
            className="fas fa-file-pdf red-text fa-lg"
            style={{ cursor: "pointer" }}
            onClick={(e) => this.handleOnDocumentSelect(document)}
          ></i>
        );
    }
  };

  render() {
    // const { showDocumentsModal, toggleDocumentsModal, canEditItem } = this.props;
    const { showDocumentsModal, toggleDocumentsModal } = this.props;
    const {
      item,
      document,
      fetchingSupportingDocuments,
      supportingDocuments,
      showDocPatchModal,
      showPreview
    } = this.state;

    if (_.isEmpty(item)) {
      return null;
    }

    if (fetchingSupportingDocuments) {
      this.loadDocs(this.props);
    }


    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDocumentsModal}
        onHide={toggleDocumentsModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              {/* <i className="fas fa-edit fa-sm cyan-text mr-3" /> */}
              Supporting Documents
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="table-responsive text-nowrap"
            style={{ height: "150px", overflowY: "scroll" }}
          >
            <table className="table table-hover table-content table-sm table-striped mb-0">
              <thead>
                <tr>
                  <th
                    scope="col"
                    style={{
                      width: "5px",
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                    }}
                  >
                    <strong>#</strong>
                  </th>
                  <th
                    scope="col"
                    style={{
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                    }}
                  >
                    <strong>Name</strong>
                  </th>
                  <th
                    scope="col"
                    style={{
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                    }}
                  >
                    <strong>Document Type</strong>
                  </th>
                  <th
                    scope="col"
                    style={{
                      // width: "5px",
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                    }}
                  ></th>
                  <th
                    scope="col"
                    style={{
                      // width: "5px",
                      paddingTop: "0.2rem",
                      paddingBottom: "0.2rem",
                    }}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {fetchingSupportingDocuments || _.isEmpty(supportingDocuments) ? (
                  <tr>
                    <td
                      className="font-weight-normal text-truncate"
                      colSpan={4}
                      style={{
                        textAlign: "center",
                        color: "#FE825E",
                        fontSize: "small",
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                      }}
                    >
                      {fetchingSupportingDocuments ? (
                        <React.Fragment>
                          <i className="fas fa-spin fa-sm fa-circle-notch"></i>
                        </React.Fragment>
                      ) : (
                        "...No documents available"
                      )}
                    </td>
                  </tr>
                ) : (
                  supportingDocuments.map((document) => (
                    <tr key={document.id}>
                      <td
                        style={{
                          width: "5px",
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                        }}
                      >
                        {supportingDocuments.indexOf(document) + 1}
                      </td>
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                        }}
                      >
                        {document.name}
                      </td>
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                        }}
                      >
                        {document.disputeConditionDocumentType}
                      </td>
                      <td
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                          fontSize: "small",
                        }}
                      >
                        {this.generateExtensionIcon(document)}
                      </td>
                      {/* <td
                        style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                            fontSize: "small",
                        }}
                        >
                        {canEditItem ? (
                            <i
                            className="far fa-trash-alt ml-5 red-text fa-lg"
                            style={{ cursor: "pointer" }}
                            onClick={(e) =>
                                this.handleDeleteDocument(document)
                            }
                            ></i>
                        ) : (
                            ""
                        )}
                        </td> */}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <DocumentPreviewer
              document={document}
              showPreview={showPreview}
              toggleShowPreview={this.toggleShowPreview}
            />
            <AddSupportingDocument
              item={item}
              showPatchModal={showDocPatchModal}
              togglePatchModal={(reloadDetails: boolean, document: any) => {
                this.toggleShowDocModal();
                if (reloadDetails === true) {
                  this.props.updateItemDocs(document);
                }
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            size="sm"
            variant="primary"
            onClick={() => this.toggleShowDocModal()}
            disabled={!canEditItem}
          >
            <i className="fas fa-lg fa-plus mr-3" /> Add
          </Button> */}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={() => toggleDocumentsModal(false, {}, {})}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SupportingDocuments;
