import React, { Fragment } from "react";
import { toast } from "react-toastify";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { getCurrentUserSession } from "../../../services/auth/authService";
import FormInputComponent from "../../../components/formInputComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import creditAssessmentValidationService from "../../../services/creditAssessment/creditAssessmentValidationService";
import { decodeToken } from "react-jwt";
import institutionConfigService from "../../../services/creditAssessment/institutionConfigService";
import SelectInputComponent, {
  ISelectItems,
} from "../../../components/formSelectComponent";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";

interface AddConfigurationFields {
  minimumCreditDroughtInDays: string;
  maximumAvailableFund: string;
  availableFundLimit: string;
  loanAgeInDays?: string;
  accountAgeInDays?: string;
  minDepositAmount?: string;
  maxDepositAmount?: string;
  minFixedDepositAmount?: string;
  maxFixedDepositAmount?: string;
  frequencyPeriod?: string;
  pqaFrequency?: string;
}

class AddConfigurationForm extends BaseFormComponent<AddConfigurationFields> { }

interface IAddConfigurationProps { }
interface IAddConfigurationState {
  enableInternalPrequalification: boolean;
  institutionConfig: any;
  isOCMConfig: boolean;
}

class AddConfiguration extends React.Component<
  IAddConfigurationProps,
  IBaseFormState & IAddConfigurationState
> {
  _isMounted = false;
  constructor(props: IAddConfigurationProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      enableInternalPrequalification: false,
      institutionConfig: {},
      isOCMConfig: false,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { errors } = this.state;
    let institutionConfig: any;
    try {
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      institutionConfig =
        await institutionConfigService.retrieveInstitutionConfig(
          decodedToken?.["InstitutionCode"]
        );
      if (this._isMounted) {
        this.setState({
          institutionConfig: institutionConfig.data,
          enableInternalPrequalification: institutionConfig.data.isPQAEnabled,
        });
      }
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // return false;
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onFormSubmit(fields: AddConfigurationFields, onReloadFieldsCB: any): boolean {
    try {
      if (this.state.enableInternalPrequalification) {
        this.setState(
          {

          },
          () => {
            if (Object.keys(this.state.errors).length === 0) {
              return this.submit(fields, onReloadFieldsCB);
            }
          }
        );
      }
      else {
        this.setState(
          {
            errors:
              creditAssessmentValidationService.validateAddConfigurationForm(
                fields
              ),
          },
          () => {
            // console.log(this.state.errors)
            if (Object.keys(this.state.errors).length === 0) {
              return this.submit(fields, onReloadFieldsCB);
            }
          }
        );
      }
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddConfigurationFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true });
      const { enableInternalPrequalification, institutionConfig } = this.state;
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const payload: any = {};

      payload.minimumCreditDroughtDays = Number(fields.minimumCreditDroughtInDays);
      payload.maximumAvailableFund = Number(fields.maximumAvailableFund);
      payload.availableFundLimit = Number(fields.availableFundLimit);
      payload.loanAgeInDays = Number(fields.loanAgeInDays);
      payload.accountAgeInDays = Number(fields.accountAgeInDays);
      payload.minDepositAmount = Number(fields.minDepositAmount);
      payload.maxDepositAmount = Number(fields.maxDepositAmount);
      payload.minFixedDepositAmount = Number(fields.minFixedDepositAmount);
      payload.maxFixedDepositAmount = Number(fields.maxFixedDepositAmount);

      if (enableInternalPrequalification !== institutionConfig.isPQAEnabled) {
        if (enableInternalPrequalification) {
          const PQApayload: any = {};
          PQApayload.frequencyPeriod = fields.frequencyPeriod;
          PQApayload.pqaFrequency = fields.pqaFrequency;
          await institutionConfigService.enablePQA(decodedToken?.["InstitutionCode"] || "", payload);
        }
        else {
          await institutionConfigService.disblePQA(decodedToken?.["InstitutionCode"] || "");
        }
      }
      const response = await institutionConfigService.updateInstitutionConfig(
        decodedToken?.["InstitutionCode"] || "",
        payload
      );
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit CreditAssessment Config",
          description: `Edit Credit Assessment Configuration`,
        });

        toast.info(`Credit Assessment modified successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false });
      onReloadFieldsCB();
    } catch (error) {
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      // console.log("error: ", error.response.data);
      return false;
    } finally {
      return true;
    }
  }

  toggleFormSwitch = () => {
    this.setState({
      isOCMConfig: !this.state.isOCMConfig,
      enableInternalPrequalification: this.state.institutionConfig.isPQAEnabled
    });
  };

  toggleEnableInternalPrequalificationSwitch = () => {
    this.setState({
      enableInternalPrequalification:
        !this.state.enableInternalPrequalification,
    });
  };

  render() {
    const {
      errors,
      validated,
      enableInternalPrequalification,
      isOCMConfig,
      institutionConfig,
    } = this.state;
    // console.log("institution data", institutionConfig);
    let frequencyPeriodInputData: ISelectItems[] = [
      "hours",
      "days",
      "weeks",
      "months",
    ].map((item) => ({
      name: item,
      value: item,
    }));

    let pqaFrequencyInputData: ISelectItems[] = [
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
    ].map((item) => ({
      name: item,
      value: item,
    }));

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Edit Configuration</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                {_.isEmpty(institutionConfig) ? (
                  <Skeleton count={5} height={30} />
                ) : (
                  <AddConfigurationForm
                    initialValues={{
                      maximumAvailableFund:
                        institutionConfig.maximumAvailableFund?.toString(),
                      availableFundLimit:
                        institutionConfig.availableFundLimit?.toString(),
                      minimumCreditDroughtInDays:
                        institutionConfig.minimumCreditDroughtInDays?.toString(),
                      loanAgeInDays: "",
                      accountAgeInDays: "",
                      minDepositAmount: "",
                      maxDepositAmount: "",
                      minFixedDepositAmount: "",
                      maxFixedDepositAmount: "",
                    }}
                    FormComponent={({
                      fields: {
                        minimumCreditDroughtInDays,
                        maximumAvailableFund,
                        availableFundLimit,
                        loanAgeInDays,
                        accountAgeInDays,
                        minDepositAmount,
                        maxDepositAmount,
                        minFixedDepositAmount,
                        maxFixedDepositAmount,
                        frequencyPeriod,
                        pqaFrequency
                      },
                      onChange,
                      onReloadFields,
                      onHandleSubmit,
                    }) => (
                      <form action="#">
                        <div className="card no-b">
                          <div className="card-body">
                            <h5 className="card-title">
                              <i className="fas fa-tools mr-3" />
                              Configuration Details -{" "}
                              {isOCMConfig ? "OCM" : "Credit Assessment"}
                              <div className="float-right">
                                <div className="form-row">
                                  {/* <label
                                    htmlFor="switchConfig"
                                    className="col-form-label"
                                  >
                                    Switch Config
                                  </label> */}
                                  <br />
                                  {/* <span className="material-switch ml-2 mt-2">
                                    <input
                                      id="switchConfig"
                                      name="switchConfig"
                                      type="checkbox"
                                      onChange={this.toggleFormSwitch}
                                      checked={isOCMConfig}
                                    />
                                    <label
                                      htmlFor="switchConfig"
                                      className="bg-primary"
                                    ></label>
                                  </span> */}
                                </div>
                              </div>
                            </h5>
                            <hr />
                            <div className="form-row">
                              <div className="col-md-12">
                                <div className="form-row">
                                  <FormInputComponent
                                    id="availableBalance"
                                    type="number"
                                    name={"availableBalance"}
                                    placeholder=""
                                    divClass={6}
                                    value={institutionConfig.avaliableBalance}
                                    required={true}
                                    validated={validated}
                                    errors={errors}
                                    onChange={onChange}
                                    disabled={true}
                                  />
                                  <FormInputComponent
                                    id="maximumAvailableFund"
                                    type="number"
                                    name={"maximumAvailableFund"}
                                    placeholder=""
                                    divClass={6}
                                    value={maximumAvailableFund}
                                    required={true}
                                    validated={validated}
                                    errors={errors}
                                    onChange={onChange}
                                  />
                                </div>
                                <div className="form-row">
                                  <FormInputComponent
                                    id="minimumCreditDroughtInDays"
                                    type="number"
                                    name={"minimumCreditDroughtInDays"}
                                    placeholder=""
                                    divClass={6}
                                    value={minimumCreditDroughtInDays}
                                    required={true}
                                    validated={validated}
                                    errors={errors}
                                    onChange={onChange}
                                  />
                                  <FormInputComponent
                                    id="availableFundLimit"
                                    type="number"
                                    name={"availableFundLimit"}
                                    placeholder=""
                                    divClass={6}
                                    value={availableFundLimit}
                                    required={true}
                                    validated={validated}
                                    errors={errors}
                                    onChange={onChange}
                                  />
                                </div>
                                {/* {isOCMConfig ? (
                                  ""
                                ) : (
                                  <Fragment>
                                    <div className="form-row">
                                      <FormInputComponent
                                        id="interestRateMarkup"
                                        type="number"
                                        name={"interestRateMarkup"}
                                        placeholder=""
                                        divClass={6}
                                        value={institutionConfig.interestRateMarkup}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                        disabled={true}
                                      />
                                      <div className="form-group col-md-6 m-0">
                                        <label
                                          htmlFor="enableInternalPrequalification"
                                          className="col-form-label s-12"
                                        >
                                          Enable PQA
                                        </label>
                                        <br />
                                        <span className="material-switch ml-2">
                                          <input
                                            id="enableInternalPrequalification"
                                            name="enableInternalPrequalification"
                                            type="checkbox"
                                            onChange={
                                              this
                                                .toggleEnableInternalPrequalificationSwitch
                                            }
                                            checked={
                                              enableInternalPrequalification
                                            }
                                          />
                                          <label
                                            htmlFor="enableInternalPrequalification"
                                            className="bg-primary"
                                          ></label>
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-row">
                                      <SelectInputComponent
                                        id="frequencyPeriod"
                                        name={"frequencyPeriod"}
                                        items={frequencyPeriodInputData}
                                        divClass={6}
                                        value={frequencyPeriod}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                        disabled={!enableInternalPrequalification}
                                      />
                                      <SelectInputComponent
                                        id="pqaFrequency"
                                        name={"pqaFrequency"}
                                        items={pqaFrequencyInputData}
                                        divClass={6}
                                        value={pqaFrequency}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                        disabled={!enableInternalPrequalification}
                                      />
                                    </div>
                                    <div className="form-row">
                                      <FormInputComponent
                                        id="loanAgeInDays"
                                        type="number"
                                        name={"Loan Age (In Days)"}
                                        placeholder=""
                                        divClass={6}
                                        required={true}
                                        value={loanAgeInDays}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                      <FormInputComponent
                                        id="accountAgeInDays"
                                        type="number"
                                        name={"Account Age (In Days)"}
                                        placeholder=""
                                        divClass={6}
                                        required={true}
                                        value={accountAgeInDays}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    </div>
                                    <div className="form-row">
                                      <FormInputComponent
                                        id="minDepositAmount"
                                        type="number"
                                        name={"Deposit Amount (Min)"}
                                        placeholder=""
                                        divClass={6}
                                        required={true}
                                        value={minDepositAmount}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                      <FormInputComponent
                                        id="maxDepositAmount"
                                        type="number"
                                        name={"Deposit Amount (Max)"}
                                        placeholder=""
                                        divClass={6}
                                        required={true}
                                        value={maxDepositAmount}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    </div>
                                    <div className="form-row">
                                      <FormInputComponent
                                        id="minFixedDepositAmount"
                                        type="number"
                                        name={"Fixed Deposit Amount (Min)"}
                                        placeholder=""
                                        divClass={6}
                                        required={true}
                                        value={minFixedDepositAmount}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                      <FormInputComponent
                                        id="maxFixedDepositAmount"
                                        type="number"
                                        name={"Fixed Deposit Amount (Max)"}
                                        placeholder=""
                                        divClass={6}
                                        required={true}
                                        value={maxFixedDepositAmount}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    </div>
                                  </Fragment>
                                )} */}
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="card-body">
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                              disabled={this.state.submitting}
                              onClick={(e) => {
                                onHandleSubmit(
                                  e,
                                  ActionTypes.SAVE,
                                  "Configuration",
                                  () => {
                                    this.onFormSubmit(
                                      {
                                        maximumAvailableFund,
                                        minimumCreditDroughtInDays,
                                        availableFundLimit,
                                        loanAgeInDays,
                                        accountAgeInDays,
                                        minDepositAmount,
                                        maxDepositAmount,
                                        minFixedDepositAmount,
                                        maxFixedDepositAmount,
                                        frequencyPeriod,
                                        pqaFrequency
                                      },
                                      onReloadFields
                                    );
                                  }
                                );
                              }}
                            >
                              {this.state.submitting === false ? (
                                <React.Fragment>
                                  <i className="fas fa-lg fa-save mr-3" /> Save
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                  Please wait...
                                </React.Fragment>
                              )}
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  ></AddConfigurationForm>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddConfiguration;
