import React, { Component } from "react";
import _ from "lodash";
import userRoleFunctionService from "../../../../../services/auth/userRoleFunctionService";
import Skeleton from "react-loading-skeleton";
import CheckboxTree from "react-checkbox-tree";

export interface ISaveUserRoleApprovalDetailProps {
  item: any;
  title: string;
}

export interface ISaveUserRoleApprovalDetailState {
  expanded: any;
  functions: any;
  //   allFunctions: any;
}

class SaveUserRoleApprovalDetail extends Component<
  ISaveUserRoleApprovalDetailProps,
  ISaveUserRoleApprovalDetailState
> {
  constructor(props: ISaveUserRoleApprovalDetailProps) {
    super(props);
    this.state = { expanded: [], functions: [] };
  }

  async componentDidMount() {
    var result = await userRoleFunctionService.retrieveAllFunctionsByInstitution();
    this.setState({ functions: result.data });
  }

  camelToWords = (str) =>
    str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
      return str.toUpperCase();
    });

  render() {
    const { title, item } = this.props;

    let treeCollection: any = [];
    let checked: any = [];
    const functions = this.state.functions.filter(
      (x) => item.AssignedFunctions.indexOf(x.id) != -1
    );
    // console.log("Processed functions: : ", functions);
    if (functions) {
      let parentFunctions = functions.filter((func) => func.parentId === 0);
      treeCollection = parentFunctions.map((pFunc) => {
        let newSet: any = {};
        newSet = {
          value: pFunc.displayName,
          label: pFunc.displayName,
          showCheckbox: false,
          children: functions
            .filter((func) => func.parentId === pFunc.id)
            .map((filtered) => {
              const child: any = {};
              child.value = filtered.description;
              child.label = filtered.displayName;
              child.showCheckbox = false;
              checked.push(filtered.description);
              return child;
            }),
        };
        return newSet;
        // console.log("New set of nodes", newSet);
      });
      //   console.log("generated Checked", checked);
    }
    return (
      <React.Fragment>
        <div>
          <h6>{title} Approval Detail</h6>
        </div>
        <div>
          <strong>Role Name: </strong>
          {item.Name}
        </div>
        <div>
          <strong>Role Description: </strong>
          {item.Description}
        </div>
        {/* <UserRoleBody item={this.state.item} functions={this.state.functions}/> */}
        {_.isEmpty(item) ? (
          <Skeleton count={1} height={500} />
        ) : (
          <div className="form-group row">
            <label htmlFor="inputFunctions" className="col-md-2 col-form-label">
              <b>Functions:</b>
            </label>
            <CheckboxTree
              nodes={treeCollection}
              checked={checked}
              expanded={this.state.expanded}
              // onCheck={() => console.log("try check")}
              onExpand={(expanded) => this.setState({ expanded })}
              iconsClass="fa5"
              disabled={true}
              showExpandAll={true}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default SaveUserRoleApprovalDetail;
