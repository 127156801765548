import Joi from "joi-browser";
import _ from "lodash";

export function validateCategoryFeeConfigForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      flatRatePerRequest: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Flat Rate Per Request is required.",
          };
        }),
      flatRatePerLoanDisbursed: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Flat Rate Per Loan Disbursed is required.",
          };
        }),
      percentageOfLoanAmount: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Percentage Of Loan Amount is required.",
          };
        }),
      flatRatePerSuccessfulRequest: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Flat Rate Per Successful Request is required.",
          };
        }),
      minimumPerTransaction: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Minimum Per Transaction is required.",
          };
        }),
      maximumPerTransaction: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Maximum Per Transaction is required.",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddCreditQualificationRuleOtherForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      // loanProductId: Joi.string()
      //   .required()
      //   .error((errors) => {
      //     return { message: "Loan Product is Required" };
      //   }),
      minimumScore: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Minimum Static Score is Required" };
        }),
      maxPercentageLoanAmountSalary: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Max. (%) Amount of Loan Salary is Required" };
        }),
      loanOriginationWorkflowId: Joi.string()
        .required()
        .error((errors) => {
          return { message: "First Loan Origination Work-Flow is Required" };
        }),
      repeatLoanOriginationWorkflowId: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Repeat Loan Origination Work-Flow is Required" };
        }),
      priorityLevel: Joi.string()
        .required()
        .error((errors) => {
          // console.log("joi error: ", errors);
          return { message: "Priority Level is Required" };
        }),
      loanType: Joi.string()
        .required()
        .error((errors) => {
          return { message: "Loan Type is Required" };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditCreditQualificationRuleOtherForm(data) {
  return validateAddCreditQualificationRuleOtherForm(data);
}

export function validateEditRecovaCentralFeeConfigurationForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      percentageAmountPerActiveLoan: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Active Loan Percentage is required";
                break;
              case "number.min":
                err.message = `Active Loan Percentage should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      percentageAmountPerLostLoan: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Lost Loan Percentage is required";
                break;
              case "number.min":
                err.message = `Lost Loan Percentage should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      percentageAmountPerBadLoan: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Bad Loan Percentage is required";
                break;
              case "number.min":
                err.message = `Bad Loan Percentage should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      amountPerSuccessfulTrx: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Amount Per Transaction is required";
                break;
              case "number.min":
                err.message = `Amount Per Transaction should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      minimumFeePerSuccessfulTrx: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Minimum Fee Per Transaction is required";
                break;
              case "number.min":
                err.message = `Minimum Fee Per Transaction should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      maximumFeePerSuccessfulTrx: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Maximum Fee Per Transaction is required";
                break;
              case "number.min":
                err.message = `Maximum Fee Per Transaction should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      debitedBankCommission: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Debited Bank Commission is required";
                break;
              case "number.min":
                err.message = `Debited Bank Commission should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      sponsorBankCommission: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Sponsor Bank Commission is required";
                break;
              case "number.min":
                err.message = `Sponsor Bank Commission should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditThirdPartyCentralFeeConfigurationForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      appzonePercentageAmountPerActiveLoan: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Active Loan Percentage is required";
                break;
              case "number.min":
                err.message = `Active Loan Percentage should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      appzonePercentageAmountPerLostLoan: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Lost Loan Percentage is required";
                break;
              case "number.min":
                err.message = `Lost Loan Percentage should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      appzoneFlatAmountPerSuccessfulTrx: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Flat Amount Per Transaction is required";
                break;
              case "number.min":
                err.message = `Flat Amount Per Transaction should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      appzoneMinimumFeePerSuccessfulTrx: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Minimum Fee Per Transaction is required";
                break;
              case "number.min":
                err.message = `Minimum Fee Per Transaction should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      appzoneMaximumFeePerSuccessfulTrx: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Maximum Fee Per Transaction is required";
                break;
              case "number.min":
                err.message = `Maximum Fee Per Transaction should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      thirdPartyPercentageAmountSwept: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Amount Swept Percentage is required";
                break;
              case "number.min":
                err.message = `Amount Swept Percentage should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      thirdPartyFlatAmountPerSuccessfulTrx: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Flat Amount Per Transaction is required";
                break;
              case "number.min":
                err.message = `Flat Amount Per Transaction should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      thirdPartyMinimumFeePerSuccessfulTrx: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Minimum Fee Per Transaction is required";
                break;
              case "number.min":
                err.message = `Minimum Fee Per Transaction should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      thirdPartyMaximumFeePerSuccessfulTrx: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Maximum Fee Per Transaction is required";
                break;
              case "number.min":
                err.message = `Maximum Fee Per Transaction should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddPaymentConfigurationForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      serviceOffering: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Service Offering is required",
          };
        }),
      institutionCategory: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Institution Category is required",
          };
        }),
      feePayableLimit: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Fee Payable Limit is required";
                break;
              case "number.min":
                err.message = `Fee Payable Limit should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      appzoneIncomeBankName: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Appzone Income Bank Name is required",
          };
        }),
      appzoneIncomeBankCode: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Appzone Income Bank Code is required",
          };
        }),
      appzoneIncomeAccountNumber: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Appzone Income Account Number is required",
          };
        }),
      unsuccessfulPaymentRetry: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Unsuccessful Payment Retry is required",
          };
        }),
      narration: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Narration is required",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditPaymentConfigurationForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      feePayableLimit: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Fee Payable Limit is required";
                break;
              case "number.min":
                err.message = `Fee Payable Limit should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      appzoneIncomeBankName: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Appzone Income Bank Name is required",
          };
        }),
      appzoneIncomeBankCode: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Appzone Income Bank Code is required",
          };
        }),
      appzoneIncomeAccountNumber: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Appzone Income Account Number is required",
          };
        }),
      unsuccessfulPaymentRetry: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Unsuccessful Payment Retry is required",
          };
        }),
      narration: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Narration is required",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddThirdPartyAccountConfigurationForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      thirdParty: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "ThirdParty is required",
          };
        }),
      bank: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Bank is required",
          };
        }),
      bankCode: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Bank Code is required",
          };
        }),
      accountNumber: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Account Number is required",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditThirdPartyAccountConfigurationForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      bank: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Bank is required",
          };
        }),
      bankCode: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Bank Code is required",
          };
        }),
      accountNumber: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "Account Number is required",
          };
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditMandateCategoryFeeConfigurationForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      guarantorMandateFee: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Gurantor Mandate Fee is required";
                break;
              case "number.min":
                err.message = `Gurantor Mandate Fee should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      minimumMandateFeePerAnnum: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Minimum Mandate Fee Per Annum is required";
                break;
              case "number.min":
                err.message = `Minimum Mandate Fee Per Annum should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddThirdPartyCentralFeeConfigurationForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({

      thirdParty: Joi.string()
        .required()
        .error((errors) => {
          return {
            message: "ThirdParty is required",
          };
        }),
      appzonePercentageAmountPerActiveLoan: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Active Loan Percentage is required";
                break;
              case "number.min":
                err.message = `Active Loan Percentage should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      appzonePercentageAmountPerLostLoan: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Lost Loan Percentage is required";
                break;
              case "number.min":
                err.message = `Lost Loan Percentage should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      appzoneAmountFlatPerSuccessfulTrx: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Flat Amount Per Transaction is required";
                break;
              case "number.min":
                err.message = `Flat Amount Per Transaction should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      appzoneMinimumFeePerSuccessfulTrx: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Appzone Minimum Fee Per Transaction is required";
                break;
              case "number.min":
                err.message = `Appzone Minimum Fee Per Transaction should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      appzoneMaximumFeePerSuccessfulTrx: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Appzone Maximum Fee Per Transaction is required";
                break;
              case "number.min":
                err.message = `Appzone Maximum Fee Per Transaction should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      thirdPartyPercentageAmountSwept: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "ThirdParty Percentage Amount Swept is required";
                break;
              case "number.min":
                err.message = `ThirdParty Percentage Amount Swept should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      thirdPartyFlatAmountPerSuccessfulTrx: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "ThirdParty Flat Amount Per Transaction is required";
                break;
              case "number.min":
                err.message = `ThirdParty Flat Amount Per Transaction should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      thirdPartyMinimumFeePerSuccessfulTrx: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "ThirdParty Minimum Fee Per Transaction is required";
                break;
              case "number.min":
                err.message = `ThirdParty Minimum Fee Per Transaction should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      thirdPartyMaximumFeePerSuccessfulTrx: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "ThirdParty Maximum Fee Per Transaction is required";
                break;
              case "number.min":
                err.message = `ThirdParty Maximum Fee Per Transaction should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddCreditAssessmentCategoryFeeConfigurationForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      flatRatePerRequest: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Flat Rate Per Request is required";
                break;
              case "number.min":
                err.message = `Flat Rate Per Request should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      flatRatePerLoanDisbursed: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Flat Rate Per Loan Disbursed is required";
                break;
              case "number.min":
                err.message = `Flat Rate Per Loan Disbursed should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      percentageOfLoanAmount: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Percentage of Loan Amount is required";
                break;
              case "number.min":
                err.message = `Percentage of Loan Amount should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      flatRatePerSuccessfulRequest: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Flat Rate Per Successful Request is required";
                break;
              case "number.min":
                err.message = `Flat Rate Per Successful Request should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      minimumPerTransaction: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Minimum Per Transaction is required";
                break;
              case "number.min":
                err.message = `Minimum Per Transaction should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      maximumPerTransaction: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Maximum Per Transaction is required";
                break;
              case "number.min":
                err.message = `Maximum Per Transaction should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditCreditAssessmentCategoryFeeConfigurationForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      flatRatePerRequest: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Flat Rate Per Request is required";
                break;
              case "number.min":
                err.message = `Flat Rate Per Request should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      flatRatePerLoanDisbursed: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Flat Rate Per Loan Disbursed is required";
                break;
              case "number.min":
                err.message = `Flat Rate Per Loan Disbursed should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      percentageOfLoanAmount: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Percentage of Loan Amount is required";
                break;
              case "number.min":
                err.message = `Percentage of Loan Amount should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      flatRatePerSuccessfulRequest: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Flat Rate Per Successful Request is required";
                break;
              case "number.min":
                err.message = `Flat Rate Per Successful Request should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      minimumPerTransaction: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Minimum Per Transaction is required";
                break;
              case "number.min":
                err.message = `Minimum Per Transaction should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      maximumPerTransaction: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Maximum Per Transaction is required";
                break;
              case "number.min":
                err.message = `Maximum Per Transaction should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateAddOCMCategoryFeeConfigurationForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      flatRatePerRequest: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Flat Rate Per Request is required";
                break;
              case "number.min":
                err.message = `Flat Rate Per Request should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      flatRatePerLoanDisbursed: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Flat Rate Per Loan Disbursed is required";
                break;
              case "number.min":
                err.message = `Flat Rate Per Loan Disbursed should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      percentageOfLoanAmount: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Percentage of Loan Amount is required";
                break;
              case "number.min":
                err.message = `Percentage of Loan Amount should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      flatRatePerSuccessfulRequest: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Flat Rate Per Successful Request is required";
                break;
              case "number.min":
                err.message = `Flat Rate Per Successful Request should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      minimumPerTransaction: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Minimum Per Transaction is required";
                break;
              case "number.min":
                err.message = `Minimum Per Transaction should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      maximumPerTransaction: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Maximum Per Transaction is required";
                break;
              case "number.min":
                err.message = `Maximum Per Transaction should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export function validateEditOCMCategoryFeeConfigurationForm(data) {
  const result = Joi.validate(
    data,
    Joi.object().keys({
      flatRatePerRequest: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Flat Rate Per Request is required";
                break;
              case "number.min":
                err.message = `Flat Rate Per Request should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      flatRatePerLoanDisbursed: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Flat Rate Per Loan Disbursed is required";
                break;
              case "number.min":
                err.message = `Flat Rate Per Loan Disbursed should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      percentageOfLoanAmount: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Percentage of Loan Amount is required";
                break;
              case "number.min":
                err.message = `Percentage of Loan Amount should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      flatRatePerSuccessfulRequest: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Flat Rate Per Successful Request is required";
                break;
              case "number.min":
                err.message = `Flat Rate Per Successful Request should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      minimumPerTransaction: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Minimum Per Transaction is required";
                break;
              case "number.min":
                err.message = `Minimum Per Transaction should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
      maximumPerTransaction: Joi.number()
        .required()
        .min(0)
        .error((errors) => {
          errors.forEach(err => {
            switch (err.type) {
              case "number.base":
                err.message = "Maximum Per Transaction is required";
                break;
              case "number.min":
                err.message = `Maximum Per Transaction should not be negative`;
                break;
              default:
                break;
            }
          });
          return errors;
        }),
    }),
    {
      abortEarly: false,
      allowUnknown: true,
    }
  );
  const errors = {};
  if (!_.isNil(result.error)) {
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
  }
  return errors;
}

export default {
  validateCategoryFeeConfigForm,
  validateAddCreditQualificationRuleOtherForm,
  validateEditCreditQualificationRuleOtherForm,
  validateEditRecovaCentralFeeConfigurationForm,
  validateEditThirdPartyCentralFeeConfigurationForm,
  validateAddPaymentConfigurationForm,
  validateEditPaymentConfigurationForm,
  validateAddThirdPartyAccountConfigurationForm,
  validateEditThirdPartyAccountConfigurationForm,
  validateEditMandateCategoryFeeConfigurationForm,
  validateAddThirdPartyCentralFeeConfigurationForm,
  validateAddCreditAssessmentCategoryFeeConfigurationForm,
  validateEditCreditAssessmentCategoryFeeConfigurationForm,
  validateAddOCMCategoryFeeConfigurationForm,
  validateEditOCMCategoryFeeConfigurationForm
};
