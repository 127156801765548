import React, { Fragment, Component, useContext } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../../../../components/detailItem";
import { Accordion, Card } from "react-bootstrap";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import { formatTimeString } from "../../../../../../services/utility/textFormatService";

export function ContextAwareToggle({ eventKey, level }) {
    const currentEventKey = useContext(AccordionContext);
    const toggleOnClick = useAccordionToggle(eventKey);
    const isCurrentEventKey = currentEventKey === eventKey;
    const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
    return (
        <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
            <b>Notepad {level}</b>
            <i
                className={angleType}
                aria-hidden="true"
                style={{ float: "right" }}
            ></i>
        </Card.Header>
    );
}

interface NotepadDetailsProps {
    values: any;
}

interface NotepadDetailsState {
    item: any;
}

class GroupNotepadDetails extends Component<NotepadDetailsProps, NotepadDetailsState> {
    constructor(props: NotepadDetailsProps) {
        super(props);
        this.state = {
            item: {},
        };
    }

    static getDerivedStateFromProps(nextProps: NotepadDetailsProps, prevState) {
        return {
            item: nextProps.values,
        };
    }

    render() {
        const { values } = this.props;

        return (
            <Fragment>
                <div className="card">
                    <div className="card-header clear-fix">
                        {_.isEmpty(values) ? (
                            <Skeleton width={200} />
                        ) : (
                            <h6
                                className="card-title float-left"
                                style={{ marginBottom: "0px" }}
                            >
                                <b>Notepad</b>
                            </h6>
                        )}
                    </div>

                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Accordion.Collapse eventKey={`0`}>
                                <Card.Body>
                                    <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {_.isEmpty(values.notepads) ? (
                                                    <div style={{ textAlign: "center", color: "#FE825E" }}>
                                                        No Notepad has been assigned to Loan Request
                                                    </div>
                                                ) : (
                                                    <div>
                                                        {values.notepads.map((item, idx) => (
                                                            <div key={idx}>
                                                                <Accordion defaultActiveKey={`${idx}`}>
                                                                    <Card>
                                                                        <ContextAwareToggle
                                                                            eventKey={`${idx}`}
                                                                            level={idx + 1}
                                                                        ></ContextAwareToggle>
                                                                        <Accordion.Collapse eventKey={`${idx}`}>
                                                                            <Card.Body className={"rule-card-body"}>
                                                                                <div className="card-body">
                                                                                    <div className="row">
                                                                                        <div className="col-md-6">
                                                                                            <dl className="row">
                                                                                                <DetailItem
                                                                                                    label="User Name"
                                                                                                    labelSize={6}
                                                                                                    valueSize={6}
                                                                                                    value={item.username}
                                                                                                />
                                                                                            </dl>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <dl className="row">
                                                                                                <DetailItem
                                                                                                    label="Date/Time"
                                                                                                    labelSize={6}
                                                                                                    valueSize={6}
                                                                                                    value={formatTimeString(item?.date)}
                                                                                                />
                                                                                            </dl>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <dl className="row">
                                                                                                <DetailItem
                                                                                                    label="Comment"
                                                                                                    labelSize={3}
                                                                                                    valueSize={9}
                                                                                                    value={item.noteDetails}
                                                                                                />
                                                                                            </dl>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Card.Body>
                                                                        </Accordion.Collapse>
                                                                    </Card>
                                                                </Accordion>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>

                </div>
            </Fragment>
        );
    }
}

export default GroupNotepadDetails;