import React from "react";
import { toast } from "react-toastify";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { getCurrentUserSession } from "../../../services/auth/authService";
import FormInputComponent from "../../../components/formInputComponent";
import bvnGreyListService, {
  validateBVN,
} from "../../../services/mandate/bvnGreyListService";
import mandateValidationService from "../../../services/mandate/mandateValidationService";
import institutionsService from "../../../services/auth/institutionsService";
import DetailItem from "../../../components/detailItem";
import { ActionTypes } from "../../../enums/actionTypes";
import { Button } from "react-bootstrap";
import { decodeToken } from "react-jwt";

interface AddBvnGreyListFields {
  bvn: string;
}

class AddBvnGreyListForm extends BaseFormComponent<AddBvnGreyListFields> { }

interface IAddBvnGreyListProps { }
interface IAddBvnGreyListState {
  data: any;
  validating: boolean;
  bvnValidated: boolean;
}

class AddBvnGreyList extends React.Component<
  IAddBvnGreyListProps,
  IBaseFormState & IAddBvnGreyListState
> {
  constructor(props: IAddBvnGreyListProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      validating: false,
      bvnValidated: false,
      data: {
        bvn: "",
        phoneNumber: "",
        firstName: "",
        otherNames: "",
        lastName: "",
        dob: "",
      },
    };
  }

  handleBVNValidation = async (fields: AddBvnGreyListFields) => {
    try {
      this.setState({ validating: true });
      // console.log("about to call the bvn validation endpoint...");
      const response = await validateBVN(fields.bvn);
      // console.log(response.data);
      // console.log("BVN Details: ", response.data.bvnDetails);
      this.setState({
        bvnValidated: response.data.status,
        validating: false,
        data: response.data.bvnDetails,
      });
    } catch (error) {
      this.setState({ validating: false });
      // console.log(error);
    }
  };

  onFormSubmit(fields: AddBvnGreyListFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        { errors: mandateValidationService.validateAddBvnGreyListForm(fields) },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddBvnGreyListFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      // console.log("starting submission...");

      this.setState({ submitting: true });
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const { data } = this.state;
      const payload: any = {};
      let response: any = null;

      payload.bvn = data.bvn;
      payload.institutionId = decodedToken?.["InstitutionId"];
      payload.institutionName = (
        await institutionsService.retrieveInstitutionById(
          decodedToken?.["InstitutionId"]
        )
      ).data.name;
      payload.customerName = `${data.lastName}, ${data.firstName} ${data.otherNames}`;
      payload.customerPhoneNumber = `${data.phoneNumber}`;

      response = await bvnGreyListService.saveBVNGreyList(payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Grey-List BVN",
          description: `Added new BVN Grey-List ${data.bvn}`,
        });

        toast.info(`BNV ${data.bvn} has been grey-listed successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false });
      onReloadFieldsCB();
      return true;
    } catch (error) {
      // console.log("error: ", error.response.data);
      this.setState({ submitting: false });
      return false;
    }
  }

  render() {
    const { data, errors, submitting, validated, validating, bvnValidated } =
      this.state;
    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Grey-List BVN</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddBvnGreyListForm
                  initialValues={{ bvn: "" }}
                  FormComponent={({
                    fields: { bvn },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <h5 className="card-title">GENERAL INFORMATION</h5>
                          <div className="form-row">
                            <div className="col-md-12">
                              <div className="form-row">
                                <FormInputComponent
                                  id="bvn"
                                  type="text"
                                  name="bvn"
                                  placeholder=""
                                  divClass={6}
                                  value={bvn}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <br />
                              <br />
                              <br />
                              <div className="form-group row">
                                <div className="col-md-6">
                                  {bvnValidated === false ? (
                                    ""
                                  ) : (
                                    <React.Fragment>
                                      <dl className="row">
                                        <DetailItem
                                          label="BVN"
                                          value={data.bvn}
                                          labelSize={6}
                                          valueSize={6}
                                        />
                                        <DetailItem
                                          label="First Name"
                                          value={data.firstName}
                                          labelSize={6}
                                          valueSize={6}
                                        />
                                        <DetailItem
                                          label="Last Name"
                                          value={data.lastName}
                                          labelSize={6}
                                          valueSize={6}
                                        />
                                        <DetailItem
                                          label="Other Names"
                                          value={data.otherNames}
                                          labelSize={6}
                                          valueSize={6}
                                        />
                                        <DetailItem
                                          label="Phone Number"
                                          value={data.phoneNumber}
                                          labelSize={6}
                                          valueSize={6}
                                        />
                                        <DetailItem
                                          label="Date Of Birth"
                                          value={data.dob}
                                          labelSize={6}
                                          valueSize={6}
                                        />
                                      </dl>
                                    </React.Fragment>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="card-body">
                          <Button
                            size="sm"
                            variant="success"
                            disabled={this.state.validating}
                            onClick={() => this.handleBVNValidation({ bvn })}
                          >
                            {validating === true ? (
                              <React.Fragment>
                                <i className="fas fa-spin fa-circle-notch" />
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <i className="fas fa-check-circle mr-3" />
                                Validate BVN
                              </React.Fragment>
                            )}
                          </Button>
                          <span style={{ padding: "20px" }} />
                          <Button
                            size="sm"
                            disabled={!bvnValidated || submitting === true}
                            onClick={(e) => {
                              onHandleSubmit(
                                e,
                                ActionTypes.SAVE,
                                "BVN-GreyList",
                                () => {
                                  this.onFormSubmit({ bvn }, onReloadFields);
                                }
                              );
                            }}
                            variant="primary"
                          >
                            {submitting === false ? (
                              <React.Fragment>
                                <i className="fas fa-lg fa-save mr-3" /> Save
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <i className="fas fa-spin fa-circle-notch" />
                              </React.Fragment>
                            )}
                          </Button>
                        </div>
                      </div>
                    </form>
                  )}
                ></AddBvnGreyListForm>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddBvnGreyList;
