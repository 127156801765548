import http from "../httpService";
import { IFilterData } from "../approval/approvalItemService";

const appraisalBaseAddress = process.env.REACT_APP_HYBRIDAPPRAISAL_BASEADDRESS;

export function saveCreditPolicyParameter(payload) {
  return http.post(
    appraisalBaseAddress + `CreditPolicyParameter/SaveCreditPolicyParameter`,
    payload
  );
}
export function retrieveById(id) {
  return http.get(
    appraisalBaseAddress + `CreditPolicyParameter/RetrieveById/${id}`
  );
}

export function retrieveAll() {
  return http.get(
    appraisalBaseAddress + `CreditPolicyParameter/RetrieveAll`
  );
}


export default {
  saveCreditPolicyParameter,
  retrieveById,
  retrieveAll
};
