import http from "../httpService";
import { getCurrentUserSession } from "./authService";
import { IFilterData } from "../approval/approvalItemService";
import { decodeToken } from "react-jwt";

const authBaseAddress = process.env.REACT_APP_AUTH_BASEADDRESS;

export function retrieveAllByInstitution(institutionId: number) {
  var url =
    authBaseAddress +
    `MailingList/RetrieveAllByInstitution?institutionId=${institutionId}`;
 
  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveById(id?: number) {
  var url = authBaseAddress + `MailingList/RetrieveById?id=${id}`;
  
  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function saveMailingList(payload) {
  var url = authBaseAddress + `MailingList/CreateMailingList`;
  
  return http.post(url, payload, {
    url: url,
    data: payload,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function updateMailingList(payload) {
  var url = authBaseAddress + `MailingList/UpdateMailingList`;
  
  return http.patch(url, payload, {
    url: url,
    data: payload,
    method: "patch",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function filterMailingList(
  payload: IFilterData,
  pageSize: number,
  pageNumber: number
) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  payload = { ...payload };
  payload.institutionId = {
    item1: "=",
    item2: decodedToken?.["InstitutionId"]?.toString() || "break",
  };
  var url =
    authBaseAddress +
    `MailingList/FilterMailingList?pageSize=${pageSize}&pageNumber=${pageNumber}`;

  return http.post(url, payload, {
    url: url,
    data: payload,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export default {
  retrieveAllByInstitution,
  retrieveById,
  saveMailingList,
  updateMailingList,
  filterMailingList,
};
