import React from "react";
import { toast } from "react-toastify";
import usersService from "../../../services/auth/usersService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import authValidationService from "../../../services/auth/authValidationService";
import { getCurrentUserSession } from "../../../services/auth/authService";
import FormInputComponent from "../../../components/formInputComponent";
import branchesService from "../../../services/auth/branchesService";
import rolesService from "../../../services/auth/rolesService";
import SelectInputComponent from "../../../components/formSelectComponent";
import { ISelectItems } from "./../../../components/formSelectComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import { decodeToken } from "react-jwt";

interface AddUserFields {
  firstName: string;
  lastName: string;
  userName: string;
  otherNames: string;
  phoneNumber: string;
  email: string;
  gender: string;
  address: string;
  userRoleId: string;
  staffId: string;
  branchId: string;
}

class AddUserForm extends BaseFormComponent<AddUserFields> { }

interface IAddUserProps { }
interface IAddUserState {
  hasApprovalRights: boolean;
  branches: any;
  userRoles: any;
}

class AddUser extends React.Component<
  IAddUserProps,
  IBaseFormState & IAddUserState
> {
  constructor(props: IAddUserProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      hasApprovalRights: false,
      branches: [],
      userRoles: [],
    };
  }

  async componentWillMount() {
    const { errors } = this.state;
    try {
      const branches = await branchesService.retrieveAllBranches();
      const userRoles = await rolesService.retrieveAllRoles();
      this.setState({
        branches: branches.data,
        userRoles: userRoles.data,
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (ex.response && ex.response.status === 404) {
        // console.log(ex.response);
        errors.response = "Internal server error.";
        // console.log(errors);
        this.setState({ errors });
      }
    }
  }

  onFormSubmit(fields: AddUserFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        { errors: authValidationService.validateAddUserForm(fields) },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(fields: AddUserFields, onReloadFieldsCB: any): Promise<boolean> {
    try {
      // console.log("starting submission...");
      this.setState({ submitting: true });
      const decodedToken = decodeToken(getCurrentUserSession().token || "");

      const payload: any = {};
      payload.hasApprovalRights = this.state.hasApprovalRights;
      payload.firstName = fields.firstName;
      payload.lastName = fields.lastName;
      payload.otherName = fields.otherNames;
      payload.userName = fields.userName;
      payload.phoneNo = fields.phoneNumber;
      payload.institutionId = decodedToken?.["InstitutionId"];
      payload.branchId = fields.branchId;
      payload.userRoleId = fields.userRoleId;
      payload.email = fields.email;
      payload.address = fields.address;
      payload.staffId = fields.staffId;
      payload.gender = fields.gender;
      payload.scope = "User";
      const response = await usersService.saveUser(payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Add User",
          description: `Added new User ${fields.firstName} ${fields.lastName}`,
        });

        if (response.data.isLoggedForApproval === true) {
          toast.warn("Action has been successfully logged for Approval!", {
            autoClose: 6000,
            type: toast.TYPE.WARNING,
            position: "top-center",
            hideProgressBar: false,
          });
        } else {
          toast.info(
            `User ${fields.firstName} ${fields.lastName} ${fields.otherNames} created successfully! `,
            {
              autoClose: 6000,
              type: toast.TYPE.DEFAULT,
              hideProgressBar: false,
            }
          );
        }
      } else {
        return false;
      }
      this.setState({ submitting: false });
      onReloadFieldsCB();
      return true;
    } catch (error) {
      // console.log("error: ", error.response.data);
      this.setState({ submitting: false });
      return false;
    }
  }

  toggleHasApprovalRightsSwitch = () => {
    this.setState({ hasApprovalRights: !this.state.hasApprovalRights });
  };

  render() {
    const {
      errors,
      validated,
      submitting,
      hasApprovalRights,
      branches,
      userRoles,
    } = this.state;
    let userRolesInputData: ISelectItems[] = userRoles.map((item) => ({
      name: item.name,
      value: item.id,
    }));
    let branchesInputData: ISelectItems[] = branches.map((item) => ({
      name: item.name,
      value: item.id,
    }));
    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add User</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddUserForm
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    userName: "",
                    otherNames: "",
                    phoneNumber: "",
                    email: "",
                    gender: "",
                    address: "",
                    userRoleId: "",
                    staffId: "",
                    branchId: "",
                  }}
                  FormComponent={({
                    fields: {
                      firstName,
                      lastName,
                      userName,
                      otherNames,
                      phoneNumber,
                      email,
                      gender,
                      address,
                      userRoleId,
                      staffId,
                      branchId,
                    },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <h5 className="card-title">GENERAL INFORMATION</h5>
                          <div className="form-row">
                            <div className="col-md-12">
                              <div className="form-row">
                                <FormInputComponent
                                  id="firstName"
                                  type="text"
                                  name={"firstName"}
                                  placeholder="Enter First Name"
                                  divClass={6}
                                  value={firstName}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="lastName"
                                  type="text"
                                  name={"lastName"}
                                  placeholder="Enter Last Name"
                                  divClass={6}
                                  value={lastName}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-row">
                                <FormInputComponent
                                  id="userName"
                                  type="text"
                                  name={"userName"}
                                  placeholder="Enter User Name"
                                  divClass={6}
                                  value={userName}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="otherNames"
                                  type="text"
                                  name={"otherNames"}
                                  placeholder="Enter Other Names"
                                  divClass={6}
                                  value={otherNames}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-row">
                                <FormInputComponent
                                  id="phoneNumber"
                                  type="number"
                                  name={"phoneNumber"}
                                  placeholder=""
                                  divClass={6}
                                  value={phoneNumber}
                                  labelIconClass="icon-phone mr-2"
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="email"
                                  type="email"
                                  name={"email"}
                                  placeholder=""
                                  divClass={6}
                                  value={email}
                                  labelIconClass="icon-envelope-o mr-2"
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-row">
                                <SelectInputComponent
                                  id="gender"
                                  name="gender"
                                  divClass={6}
                                  value={gender}
                                  items={[
                                    { name: "Male", value: "Male" },
                                    { name: "Female", value: "Female" },
                                  ]}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="address"
                                  type="text"
                                  name="address"
                                  placeholder=""
                                  divClass={6}
                                  value={address}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-row">
                                <SelectInputComponent
                                  id="userRoleId"
                                  name="userRole"
                                  divClass={6}
                                  value={userRoleId}
                                  items={userRolesInputData}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="staffId"
                                  type="text"
                                  name="staffId"
                                  placeholder=""
                                  divClass={6}
                                  value={staffId}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-row">
                                <SelectInputComponent
                                  id="branchId"
                                  name="branch"
                                  divClass={6}
                                  value={branchId}
                                  items={branchesInputData}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />

                                <div className="form-group col-md-6 m-0">
                                  <label
                                    htmlFor="hasApprovalRights"
                                    className="col-form-label s-12"
                                  >
                                    Has Approval Rights
                                  </label>
                                  <br />
                                  <span className="material-switch ml-2">
                                    <input
                                      id="hasApprovalRights"
                                      name="hasApprovalRights"
                                      type="checkbox"
                                      onChange={
                                        this.toggleHasApprovalRightsSwitch
                                      }
                                      checked={hasApprovalRights}
                                    />
                                    <label
                                      htmlFor="hasApprovalRights"
                                      className="bg-primary"
                                    ></label>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="card-body">
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                            disabled={submitting}
                            onClick={(e) => {
                              onHandleSubmit(
                                e,
                                ActionTypes.SAVE,
                                "User",
                                () => {
                                  this.onFormSubmit(
                                    {
                                      firstName,
                                      lastName,
                                      userName,
                                      otherNames,
                                      phoneNumber,
                                      email,
                                      gender,
                                      address,
                                      userRoleId,
                                      staffId,
                                      branchId,
                                    },
                                    onReloadFields
                                  );
                                }
                              );
                            }}
                          >
                            {submitting === false ? (
                              <React.Fragment>
                                <i className="fas fa-lg fa-save mr-3" /> Save
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                Please wait...
                              </React.Fragment>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                ></AddUserForm>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddUser;
