import http from "../httpService";
import authService, { getCurrentUserSession, getCurrentUsersRoles } from "../auth/authService";
import { IFilterData } from "../approval/approvalItemService";
import { decodeToken } from "react-jwt";


const recovaNibssBaseAddress = process.env.REACT_APP_NIBSS_BASEADDRESS;
const recovaOFIHandshakeBaseAddress = process.env.REACT_APP_RECOVA_OFI_HANDSHAKE_BASEADDRESS;

export function retrieveConsentStatusById(consentRequestId: number) {
    return http.post(
        recovaNibssBaseAddress + `EMandate/GetRequestByConsentRequestID/${consentRequestId}`,
        null
    );
}

export function confirmConsentRequest(consentRequestId: number) {
    return http.post(
        recovaNibssBaseAddress + `EMandate/GetRequestByConsentRequestID?Id=${consentRequestId}`,
        null
    );
}



export default {
    retrieveConsentStatusById,
    confirmConsentRequest
}