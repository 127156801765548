import http from "../httpService";

const recovaCollectionBaseAddress = process.env.REACT_APP_COLLECTIONS_BASEADDRESS;
// const recovaCollectionBaseAddress = "https://localhost:44309/api/";

export function retrieveInstitutionCollectionsSettings(institutionCode) {
  return http.get(
    recovaCollectionBaseAddress +
      `collections/settings/institution/${institutionCode}`
  );
}

export function editInstitutionCollectionsSettings(payload, institutionCode) {
  return http.put(
      recovaCollectionBaseAddress +
      `collections/settings/institution/${institutionCode}`,
      payload
  );
}

export default {
  retrieveInstitutionCollectionsSettings,
  editInstitutionCollectionsSettings
};
