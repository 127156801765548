import * as React from "react";
import textFormatService, { firstCharToUpper } from "./../services/utility/textFormatService";
import _ from "lodash";

export interface IFormTextAreaComponentProps {
    id: string;
    type: "password" | "text" | "number" | "email" | "textarea" | "amount" | "date";
    name: string;
    placeholder: string;
    divClass?: number;
    label?: string;
    labelIconClass?: string;
    value: string;
    required?: boolean;
    amountDecimalPlaces?: number;
    isFormikInput?: boolean;
    disabled?: boolean;
    onBlur?: any;
    validated: boolean;
    onChange: any;
    errors: any;
    htmlBeforeError?: any;
}

export interface IFormTextAreaComponentState {}

class FormTextAreaComponent extends React.Component<IFormTextAreaComponentProps, IFormTextAreaComponentState> {
    static defaultProps = {
        required: false,
        amountDecimalPlaces: 0,
        isFormikInput: false,
    };
    render() {
        const {
            id,
            type,
            name,
            placeholder,
            divClass,
            label,
            value,
            required,
            validated,
            errors,
            onChange,
            labelIconClass,
            disabled,
            onBlur,
            htmlBeforeError,
            amountDecimalPlaces,
            isFormikInput,
        } = this.props;
        return (
            <div className={`form-group col${divClass ? "-md-" + divClass : ""} m-0`}>
                <label style={{ paddingBottom: "calc(0.115rem + 1px)" }} htmlFor={label ?? name} className="col-form-label s-12">
                    {required && (
                        <span aria-hidden="true" className="mr-2" style={{ color: "#ed5564" }}>
                            *
                        </span>
                    )}
                    {labelIconClass ? <i className={labelIconClass}></i> : ""}
                    {label ?? firstCharToUpper(textFormatService.splitAtUpper(name))}
                </label>
                <textarea
                    style={{ height: "37px" }}
                    required={required}
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    className={
                        !validated
                            ? "form-control r-0 light s-12"
                            : errors[id]
                            ? "form-control r-0 light s-12 is-invalid"
                            : "form-control r-0 light s-12 is-valid"
                    }
                    // type={type === "amount" ? "text" : type}
                    rows={5}
                    value={
                        type === "amount" ? textFormatService.convertKoboToNaira(Number(value), amountDecimalPlaces) : _.isNull(value) ? "" : value
                    }
                    disabled={disabled}
                    onChange={(e) => (isFormikInput ? onChange(e) : onChange(id, e.currentTarget.value))}
                    // onChange={onChange}
                    onBlur={onBlur ? (e) => onBlur(e) : onBlur}
                />
                {htmlBeforeError}
                <div
                    className="invalid-feedback"
                    style={{
                        visibility: errors[id] ? "visible" : "hidden",
                        display: errors[id] ? "block" : "none",
                    }}
                >
                    {errors[id]}​​​
                </div>
            </div>
        );
    }
}

export default FormTextAreaComponent;
