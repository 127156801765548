import React, { Component } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import BaseFormComponent, { IBaseFormState } from "../../../../../../components/BaseFormComponent";
import FormInputComponent from "../../../../../../components/formInputComponent";
import SelectInputComponent, { ISelectItems } from "../../../../../../components/formSelectComponent";
import { ActionTypes } from "../../../../../../enums/actionTypes";
import loanRequestService from "../../../../../../services/hybridAppraisal/loanRequestService";
import textFormatService from "../../../../../../services/utility/textFormatService";
import hybridAppraisalValidationService from "../../../../../../services/hybridAppraisal/hybridAppraisalValidationService";
import { getCurrentUsersRoles } from "../../../../../../services/auth/authService";

interface IncomeDetailsFields {
    incomeType?: string;
    frequencyIncome?: string;
    amount?: string;
    currency?: string;
    salary1?: string;
    salary2?: string;
    salary3?: string;
    salary4?: string;
    salary5?: string;
    salary6?: string;
    frequencyExpense?: string;
    expenseType?: string;
    expenseAmount?: string;
    expenseCurrency?: string;
    numberOfLoans?: string;
    repaymentFrequency?: string;
    totalOutstanding?: string;
    internalAmount?: string;
    internalCurrency?: string;
    noOfLoansExt?: string;
    frequencyExt?: string;
    repaymentAmountExt?: string;
    totalOutstandingExt?: string;
    currencyExt?: string;
}

class IncomeDetailsForm extends BaseFormComponent<IncomeDetailsFields> { }

interface IncomeDetailsProps {
    values: any;
    reloadItem: any;
    mandatoryFields: any;
}

interface IncomeDetailsState {
    incomeData: IncomeDetailsFields[];
    ownUpdate: boolean;
    totalOutstanding: string;
    income: IncomeDetailsFields[];
    expense: IncomeDetailsFields[]
    internal: IncomeDetailsFields[]
    external: IncomeDetailsFields[]
}

interface IncomeFields {
    incomeList: IncomeDetailsFields[];
    expenseList: IncomeDetailsFields[];
    internalLiabilityList: IncomeDetailsFields[];
    externalLiabilityList: IncomeDetailsFields[];
}

class IncomeDetails extends Component<
    IncomeDetailsProps,
    IBaseFormState & IncomeDetailsState
> {
    constructor(props: IncomeDetailsProps) {
        super(props);
        this.state = {
            validated: false,
            submitting: false,
            errors: {},
            incomeData: [],
            ownUpdate: false,
            totalOutstanding: "",
            income: [],
            expense: [],
            internal: [],
            external: []
        };
    }

    static getDerivedStateFromProps(
        nextProps: IncomeDetailsProps,
        prevState: IncomeDetailsState
    ) {
        if (prevState.ownUpdate) {
            return {
                ownUpdate: false,
            };
        }
        if (_.isEmpty(nextProps.values.incomeDetails)) {
            return null;
        }
        return {
            income: nextProps.values.incomeDetails[0].incomeList,
            expense: nextProps.values.incomeDetails[0].expenseList,
            internal: nextProps.values.incomeDetails[0].internalLiabilityList,
            external: nextProps.values.incomeDetails[0].externalLiabilityList
        };
    }

    handleOnAddIncome = (item: IncomeDetailsFields, onReloadFieldsCB: any) => {
        const { mandatoryFields } = this.props;
        try {
            this.setState(
                { errors: hybridAppraisalValidationService.validateAddIncomeDetailsForm(item, mandatoryFields), ownUpdate: true },
                () => {
                    if (Object.keys(this.state.errors).length === 0) {
                        const income = [...this.state.income];
                        income.push(item);
                        this.setState({ income, ownUpdate: true });
                        this.resetFields();
                        onReloadFieldsCB();
                    }
                }
            );
            this.setState({ validated: true, ownUpdate: true });
        } catch (error) {
            return false;
        }
    }


    onFormSubmit(fields: IncomeDetailsFields, onReloadFieldsCB: any): boolean {
        try {
            this.setState(
                { errors: {}, ownUpdate: true },
                () => {
                    if (Object.keys(this.state.errors).length === 0) {
                        return this.submit(fields, onReloadFieldsCB);
                    }
                }
            );
            this.setState({ validated: true, ownUpdate: true });
            return false;
        } catch (error) {
            return false;
        }
    }

    async submit(
        fields: IncomeDetailsFields,
        onReloadFieldsCB: any
    ): Promise<boolean> {
        try {
            this.setState({ submitting: true, ownUpdate: true });
            const loanRequest = await loanRequestService.retrieveById(this.props.values.id);
            const loanRequestDetail = loanRequest.data;
            const payload: any = { ...loanRequestDetail };
            const details: IncomeFields[] = [];
            // details.incomeType = fields.incomeType
            // details.frequencyIncome = fields.frequencyIncome
            // details.Amount = fields.Amount
            // details.currency = fields.currency
            // details.frequencyExpense = fields.frequencyExpense
            // details.expenseType = fields.expenseType
            // details.expenseAmount = fields.expenseAmount
            // details.expenseCurrency = fields.expenseCurrency
            // details.numberOfLoans = fields.numberOfLoans
            // details.repaymentFrequency = fields.repaymentFrequency
            // details.totalOutstanding = fields.totalOutstanding
            // details.internalAmount = fields.internalAmount
            // details.internalCurrency = fields.internalCurrency
            // details.noOfLoansExt = fields.noOfLoansExt
            // details.frequencyExt = fields.frequencyExt
            // details.repaymentAmountExt = fields.repaymentAmountExt
            // details.totalOutstandingExt = fields.totalOutstandingExt
            // details.currencyExt = fields.currencyExt
            details.push({
                incomeList: this.state.income,
                expenseList: this.state.expense,
                internalLiabilityList: this.state.internal,
                externalLiabilityList: this.state.external
            }
            )


            payload.incomeDetails = details;

            const response = await loanRequestService.updateLoanRequest(payload);
            if (response.status >= 200 && response.status <= 300) {
                toast.info(`Income Details saved successfully!`, {
                    autoClose: 6000,
                    type: toast.TYPE.DEFAULT,
                    hideProgressBar: false,
                });
            } else {
                return false;
            }
            this.setState({ submitting: false, ownUpdate: true });
            // this.resetFields();
            // this.props.reloadItem(payload);
            // onReloadFieldsCB();
        } catch (error) {
            if (error.response.status === 409) {
                toast.error(error.response.data.detail, {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            } else if (error.response.status === 400) {
                toast.error(error.response.data.errors[0], {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            }
            this.setState({ submitting: false, ownUpdate: true });
            return false;
        } finally {
            return true;
        }
    }

    // handleOnAddIncomeDetails = (item: IncomeDetailsFields, onReloadFieldsCB: any) => {
    //     const income = [...this.state.income];
    //     income.push(item);
    //     this.setState({ income, ownUpdate: true });
    //     this.resetFields();
    //     onReloadFieldsCB();
    // };

    handleOnRemoveIncomeDetails = (item: IncomeDetailsFields) => {
        const income = [...this.state.income];
        if (income.includes(item)) {
            income.splice(
                income.indexOf(item), 1
            );
        }
        this.setState({ income, ownUpdate: true });
    };
    handleOnAddExpenseDetails = (item: IncomeDetailsFields, onReloadFieldsCB: any) => {
        try {
            this.setState(
                { errors: hybridAppraisalValidationService.validateAddExpenseDetailsForm(item, this.props.mandatoryFields), ownUpdate: true },
                () => {
                    if (Object.keys(this.state.errors).length === 0) {
                        const expense = [...this.state.expense];
                        expense.push(item);
                        this.setState({ expense, ownUpdate: true });
                        this.resetFields();
                        onReloadFieldsCB();
                    }
                }
            );
            this.setState({ validated: true, ownUpdate: true });
        } catch (error) {
            return false;
        }
    }

    handleOnRemoveExpenseDetails = (item: IncomeDetailsFields) => {
        const expense = [...this.state.income];
        if (expense.includes(item)) {
            expense.splice(
                expense.indexOf(item), 1
            );
        }
        this.setState({ expense, ownUpdate: true });
    };
    handleOnAddInternalDetails = (item: IncomeDetailsFields, onReloadFieldsCB: any) => {
        try {
            this.setState(
                { errors: hybridAppraisalValidationService.validateAddInternalDetailsForm(item, this.props.mandatoryFields), ownUpdate: true },
                () => {
                    if (Object.keys(this.state.errors).length === 0) {
                        const internal = [...this.state.internal];
                        internal.push(item);
                        this.setState({ internal, ownUpdate: true });
                        this.resetFields();
                        onReloadFieldsCB();
                    }
                }
            );
            this.setState({ validated: true, ownUpdate: true });
        } catch (error) {
            return false;
        }
    }

    handleOnRemoveInternalDetails = (item: IncomeDetailsFields) => {
        const internal = [...this.state.internal];
        if (internal.includes(item)) {
            internal.splice(
                internal.indexOf(item), 1
            );
        }
        this.setState({ internal, ownUpdate: true });
    };
    handleOnAddExternalDetails = (item: IncomeDetailsFields, onReloadFieldsCB: any) => {
        try {
            this.setState(
                { errors: hybridAppraisalValidationService.validateAddExternalDetailsForm(item, this.props.mandatoryFields), ownUpdate: true },
                () => {
                    if (Object.keys(this.state.errors).length === 0) {
                        const external = [...this.state.external];
                        external.push(item);
                        this.setState({ external, ownUpdate: true });
                        this.resetFields();
                        onReloadFieldsCB();
                    }
                }
            );
            this.setState({ validated: true, ownUpdate: true });
        } catch (error) {
            return false;
        }
    }

    handleOnRemoveExternalDetails = (item: IncomeDetailsFields) => {
        const external = [...this.state.external];
        if (external.includes(item)) {
            external.splice(
                external.indexOf(item), 1
            );
        }
        this.setState({ external, ownUpdate: true });
    };

    getRequiredFields(title): boolean {
        const { mandatoryFields } = this.props;
        var required = mandatoryFields?.inputFields?.filter(x => x.fieldName === title)[0]?.mandatory;
        if (required === undefined) {
            return false;
        }
        return required;
    }

    resetFields() {
        this.setState({ validated: false, submitting: false, errors: [], totalOutstanding: "", ownUpdate: true });
    }

    render() {
        const { values } = this.props;
        const { errors, validated, incomeData, income, expense, internal, external } = this.state;

        let incomeTypeInputData: ISelectItems[] = [
            { name: "Salary", value: "Salary" },
            { name: "Allowance", value: "Allowance" },
            { name: "Business Proceeds", value: "Business Proceeds" },
        ];
        let frequencyIncomeInputData: ISelectItems[] = [
            { name: "Daily", value: "Daily" },
            { name: "Weekly", value: "Weekly" },
            { name: "Monthly", value: "Monthly" },
            { name: "Quarterly", value: "Quarterly" },
            { name: "Semi - Annually", value: "Semi - Annually" },
            { name: "Annually", value: "Annually" },
        ];
        let expenseTypeInputData: ISelectItems[] = [
            { name: "Fixed", value: "Fixed" },
            { name: "Variable", value: "Variable" },
        ];
        let currencyInputData: ISelectItems[] = [
            { name: "GBP", value: "GBP" },
            { name: "GMD", value: "GMD" },
            { name: "NGN", value: "NGN" },
            { name: "USD", value: "USD" },

        ];

        let frequencyExpenseInputData: ISelectItems[] = [
            { name: "Daily", value: "Daily" },
            { name: "Weekly", value: "Weekly" },
            { name: "Monthly", value: "Monthly" },
            { name: "Quarterly", value: "Quarterly" },
            { name: "Semi - Annually", value: "Semi - Annually" },
            { name: "Annually", value: "Annually" },
        ];

        let repaymentFrequencyInputData: ISelectItems[] = [
            { name: "Daily", value: "Daily" },
            { name: "Weekly", value: "Weekly" },
            { name: "Monthly", value: "Monthly" },
            { name: "Quarterly", value: "Quarterly" },
            { name: "Semi - Annually", value: "Semi - Annually" },
            { name: "Annually", value: "Annually" },
        ];

        return (
            <IncomeDetailsForm
                initialValues={{
                    incomeType: values?.incomeDetails?.incomeList?.incomeType ? values.incomeDetails.incomeList?.incomeType : "",
                    frequencyIncome: values?.incomeDetails?.frequencyIncome ? values.incomeDetails.frequencyIncome : "",
                    amount: values?.incomeDetails?.amount ? values.incomeDetails.amount : "",
                    currency: values?.incomeDetails?.currency ? values.incomeDetails.currency : "",
                    salary1: values?.incomeDetails?.salary1 ? values.incomeDetails.salary1 : "",
                    salary2: values?.incomeDetails?.salary2 ? values.incomeDetails.salary2 : "",
                    salary3: values?.incomeDetails?.salary3 ? values.incomeDetails.salary3 : "",
                    salary4: values?.incomeDetails?.salary4 ? values.incomeDetails.salary4 : "",
                    salary5: values?.incomeDetails?.salary5 ? values.incomeDetails.salary5 : "",
                    salary6: values?.incomeDetails?.salary6 ? values.incomeDetails.salary6 : "",
                    frequencyExpense: values?.incomeDetails?.frequencyExpense ? values.incomeDetails.frequencyExpense : "",
                    expenseType: values?.incomeDetails?.expenseType ? values.incomeDetails.expenseType : "",
                    expenseAmount: values?.incomeDetails?.expenseAmount ? values.incomeDetails.expenseAmount : "",
                    expenseCurrency: values?.incomeDetails?.expenseCurrency ? values.incomeDetails.expenseCurrency : "",
                    numberOfLoans: values?.incomeDetails?.numberOfLoans ? values.incomeDetails.numberOfLoans : "",
                    repaymentFrequency: values?.incomeDetails?.repaymentFrequency ? values.incomeDetails.repaymentFrequency : "",
                    totalOutstanding: values?.incomeDetails?.totalOutstanding ? values.incomeDetails.totalOutstanding : "",
                    internalAmount: values?.incomeDetails?.internalAmount ? values.incomeDetails.internalAmount : "",
                    internalCurrency: values?.incomeDetails?.internalCurrency ? values.incomeDetails.internalCurrency : "",
                    noOfLoansExt: values?.incomeDetails?.noOfLoansExt ? values.incomeDetails.noOfLoansExt : "",
                    frequencyExt: values?.incomeDetails?.frequencyExt ? values.incomeDetails.frequencyExt : "",
                    repaymentAmountExt: values?.incomeDetails?.repaymentAmountExt ? values.incomeDetails.repaymentAmountExt : "",
                    totalOutstandingExt: values?.incomeDetails?.totalOutstandingExt ? values.incomeDetails.totalOutstandingExt : "",
                    currencyExt: values?.incomeDetails?.currencyExt ? values.incomeDetails.currencyExt : "",
                }}
                FormComponent={({
                    fields: {
                        incomeType,
                        frequencyIncome,
                        amount,
                        currency,
                        salary1,
                        salary2,
                        salary3,
                        salary4,
                        salary5,
                        salary6,
                        frequencyExpense,
                        expenseType,
                        expenseAmount,
                        expenseCurrency,
                        numberOfLoans,
                        repaymentFrequency,
                        totalOutstanding,
                        internalAmount,
                        internalCurrency,
                        noOfLoansExt,
                        frequencyExt,
                        repaymentAmountExt,
                        totalOutstandingExt,
                        currencyExt,
                    },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                }) => (
                    <div className="form-row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header clear-fix">
                                    <h6
                                        className="float-left"
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <b>Income</b>
                                    </h6>
                                </div>
                                <div className="card-body">
                                    <div className="form-row">
                                        <SelectInputComponent
                                            id="incomeType"
                                            name="incomeType"
                                            divClass={6}
                                            value={incomeType}
                                            items={incomeTypeInputData}
                                            required={this.getRequiredFields("incomeType")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        <SelectInputComponent
                                            label="Income Frequency"
                                            id="frequencyIncome"
                                            name="frequencyIncome"
                                            divClass={6}
                                            value={frequencyIncome}
                                            items={frequencyIncomeInputData}
                                            required={this.getRequiredFields("frequencyIncome")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        <FormInputComponent
                                            id="amount"
                                            name="amount"
                                            type="number"
                                            placeholder=""
                                            divClass={6}
                                            value={amount}
                                            required={this.getRequiredFields("incomeAmount")}
                                            // disabled={true}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        <SelectInputComponent
                                            id="currency"
                                            name="currency"
                                            divClass={6}
                                            value={currency}
                                            items={currencyInputData}
                                            required={this.getRequiredFields("incomeCurrency")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        <FormInputComponent
                                            label="Salary 1"
                                            id="salary1"
                                            name="salary1"
                                            type="number"
                                            placeholder=""
                                            divClass={6}
                                            value={salary1}
                                            required={false}
                                            // disabled={true}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        <FormInputComponent
                                            label="Salary 2"
                                            id="salary2"
                                            name="salary2"
                                            type="number"
                                            placeholder=""
                                            divClass={6}
                                            value={salary2}
                                            required={false}
                                            // disabled={true}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        <FormInputComponent
                                            label="Salary 3"
                                            id="salary3"
                                            name="salary3"
                                            type="number"
                                            placeholder=""
                                            divClass={6}
                                            value={salary3}
                                            required={false}
                                            // disabled={true}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        <FormInputComponent
                                            label="Salary 4"
                                            id="salary4"
                                            name="salary4"
                                            type="number"
                                            placeholder=""
                                            divClass={6}
                                            value={salary4}
                                            required={false}
                                            // disabled={true}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        <FormInputComponent
                                            label="Salary 5"
                                            id="salary5"
                                            name="salary5"
                                            type="number"
                                            placeholder=""
                                            divClass={6}
                                            value={salary5}
                                            required={false}
                                            // disabled={true}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        <FormInputComponent
                                            label="Salary 6"
                                            id="salary6"
                                            name="salary6"
                                            type="number"
                                            placeholder=""
                                            divClass={6}
                                            value={salary6}
                                            required={false}
                                            // disabled={true}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                    </div>
                                    <div className="form-row">
                                        <div className="my-1 mx-2">
                                            {(getCurrentUsersRoles().includes("updatedataentry") && (
                                                <button
                                                    className="btn btn-sm btn-outline-primary my-4"
                                                    style={{ cursor: "pointer" }}
                                                    type="button"
                                                    onClick={() =>
                                                        this.handleOnAddIncome(
                                                            {
                                                                incomeType,
                                                                frequencyIncome,
                                                                amount,
                                                                currency,
                                                                salary1,
                                                                salary2,
                                                                salary3,
                                                                salary4,
                                                                salary5,
                                                                salary6
                                                            },
                                                            onReloadFields
                                                        )
                                                    }
                                                >
                                                    <React.Fragment>
                                                        <i className="far fa-save fa-lg mr-2" />{" "}
                                                        Add
                                                    </React.Fragment>
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                    {/* Asset Table */}
                                    <div className=" form-row col-md-12">
                                        <div
                                            className="table-responsive text-nowrap"
                                            style={{
                                                maxHeight: "150px",
                                                overflowY: "scroll",
                                            }}
                                        >
                                            <table className="table table-hover table-content table-sm table-striped mb-0">
                                                <thead>
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>#</strong>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>Income Type</strong>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>Frequency</strong>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>Amount</strong>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>Currency</strong>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {income?.map((item) => (
                                                        <tr key={income?.indexOf(item)}>
                                                            <td
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                    fontSize: "small",
                                                                }}
                                                            >
                                                                {income?.indexOf(item) + 1}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                    fontSize: "small",
                                                                }}
                                                            >
                                                                {item.incomeType}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                    fontSize: "small",
                                                                }}
                                                            >
                                                                {item.frequencyIncome}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                    fontSize: "small",
                                                                }}
                                                            >
                                                                {textFormatService.formatCurrency(Number(item.amount), 2)}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                    fontSize: "small",
                                                                }}
                                                            >
                                                                {item.currency}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                    fontSize: "small",
                                                                }}
                                                            >
                                                                <i
                                                                    className="far fa-trash-alt ml-5 red-text fa-lg"
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() =>
                                                                        this.handleOnRemoveIncomeDetails(
                                                                            item
                                                                        )
                                                                    }
                                                                ></i>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-header clear-fix">
                                    <h6
                                        className="float-left"
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <b>Expense</b>
                                    </h6>
                                </div>
                                <div className="card-body">
                                    <div className="form-row">
                                        <SelectInputComponent
                                            id="expenseType"
                                            name="expenseType"
                                            divClass={6}
                                            value={expenseType}
                                            items={expenseTypeInputData}
                                            required={this.getRequiredFields("expenseType")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        <SelectInputComponent
                                            label="Expense Frequency"
                                            id="frequencyExpense"
                                            name="frequencyExpense"
                                            divClass={6}
                                            value={frequencyExpense}
                                            items={frequencyExpenseInputData}
                                            required={this.getRequiredFields("frequencyExpense")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        <FormInputComponent
                                            id="expenseAmount"
                                            name="expenseAmount"
                                            type="number"
                                            placeholder=""
                                            divClass={6}
                                            value={expenseAmount}
                                            required={this.getRequiredFields("expenseAmount")}
                                            // disabled={true}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        {/* <FormInputComponent
                                            id="expenseCurrency"
                                            name="expenseCurrency"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={expenseCurrency}
                                            required={this.getRequiredFields("expenseCurrency")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        /> */}
                                        <SelectInputComponent
                                            id="expenseCurrency"
                                            name="expenseCurrency"
                                            divClass={6}
                                            value={expenseCurrency}
                                            items={currencyInputData}
                                            required={this.getRequiredFields("expenseCurrency")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                    </div>
                                    <div className="form-row">
                                        <div className="my-1 mx-2">
                                            {(getCurrentUsersRoles().includes("updatedataentry") && (
                                                <button
                                                    className="btn btn-sm btn-outline-primary my-4"
                                                    style={{ cursor: "pointer" }}
                                                    type="button"
                                                    onClick={() =>
                                                        this.handleOnAddExpenseDetails(
                                                            {
                                                                expenseType,
                                                                frequencyExpense,
                                                                expenseAmount,
                                                                expenseCurrency
                                                            },
                                                            onReloadFields
                                                        )
                                                    }
                                                >
                                                    <React.Fragment>
                                                        <i className="far fa-save fa-lg mr-2" />{" "}
                                                        Add
                                                    </React.Fragment>
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                    {/* Asset Table */}
                                    <div className=" form-row col-md-12">
                                        <div
                                            className="table-responsive text-nowrap"
                                            style={{
                                                maxHeight: "150px",
                                                overflowY: "scroll",
                                            }}
                                        >
                                            <table className="table table-hover table-content table-sm table-striped mb-0">
                                                <thead>
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>#</strong>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>Expense Type</strong>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>Frequency</strong>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>Amount</strong>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>Currency</strong>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {expense.map((item) => (
                                                        <tr key={expense.indexOf(item)}>
                                                            <td
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                    fontSize: "small",
                                                                }}
                                                            >
                                                                {expense.indexOf(item) + 1}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                    fontSize: "small",
                                                                }}
                                                            >
                                                                {item.expenseType}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                    fontSize: "small",
                                                                }}
                                                            >
                                                                {item.frequencyExpense}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                    fontSize: "small",
                                                                }}
                                                            >
                                                                {textFormatService.formatCurrency(Number(item.expenseAmount), 2)}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                    fontSize: "small",
                                                                }}
                                                            >
                                                                {item.expenseCurrency}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                    fontSize: "small",
                                                                }}
                                                            >
                                                                <i
                                                                    className="far fa-trash-alt ml-5 red-text fa-lg"
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() =>
                                                                        this.handleOnRemoveExpenseDetails(
                                                                            item
                                                                        )
                                                                    }
                                                                ></i>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-header clear-fix">
                                    <h6
                                        className="float-left"
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <b>Loan Liability Internal</b>
                                    </h6>
                                </div>
                                <div className="card-body">
                                    <div className="form-row">
                                        <FormInputComponent
                                            id="numberOfLoans"
                                            name="numberOfLoans"
                                            type="number"
                                            placeholder=""
                                            divClass={6}
                                            value={numberOfLoans}
                                            required={this.getRequiredFields("loanLiabilityInternalNumberOfLoans")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        <FormInputComponent
                                            id="totalOutstanding"
                                            name="totalOutstanding"
                                            type="number"
                                            placeholder=""
                                            divClass={6}
                                            value={totalOutstanding}
                                            required={this.getRequiredFields("loanLiabilityInternalTotalOutstanding")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        <SelectInputComponent
                                            id="repaymentFrequency"
                                            name="repaymentFrequency"
                                            divClass={6}
                                            value={repaymentFrequency}
                                            items={repaymentFrequencyInputData}
                                            required={this.getRequiredFields("loanLiabilityInternalRepaymentFrequency")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        <FormInputComponent
                                            label="Repayment Amount"
                                            id="internalAmount"
                                            name="internalAmount"
                                            type="number"
                                            placeholder=""
                                            divClass={6}
                                            value={internalAmount}
                                            required={this.getRequiredFields("loanLiabilityInternalRepaymentAmount")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        {/* <FormInputComponent
                                            label="Currency"
                                            id="internalCurrency"
                                            name="internalCurrency"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={internalCurrency}
                                            required={this.getRequiredFields("loanLiabilityInternalCurrency")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        /> */}
                                        <SelectInputComponent
                                            label="Currency"
                                            id="internalCurrency"
                                            name="internalCurrency"
                                            divClass={6}
                                            value={internalCurrency}
                                            items={currencyInputData}
                                            required={this.getRequiredFields("loanLiabilityInternalCurrency")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                    </div>
                                    <div className="form-row">
                                        <div className="my-1 mx-2">
                                            {(getCurrentUsersRoles().includes("updatedataentry") && (
                                                <button
                                                    className="btn btn-sm btn-outline-primary my-4"
                                                    style={{ cursor: "pointer" }}
                                                    type="button"
                                                    onClick={() =>
                                                        this.handleOnAddInternalDetails(
                                                            {
                                                                numberOfLoans,
                                                                totalOutstanding,
                                                                repaymentFrequency,
                                                                internalAmount,
                                                                internalCurrency
                                                            },
                                                            onReloadFields
                                                        )
                                                    }
                                                >
                                                    <React.Fragment>
                                                        <i className="far fa-save fa-lg mr-2" />{" "}
                                                        Add
                                                    </React.Fragment>
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                    {/* Asset Table */}
                                    <div className=" form-row col-md-12">
                                        <div
                                            className="table-responsive text-nowrap"
                                            style={{
                                                maxHeight: "150px",
                                                overflowY: "scroll",
                                            }}
                                        >
                                            <table className="table table-hover table-content table-sm table-striped mb-0">
                                                <thead>
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>#</strong>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>No Of Loans</strong>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>Total Outstanding</strong>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>Repayment Frequency</strong>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>Repayment Amount</strong>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                            }}
                                                        >
                                                            <strong>Currency</strong>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {internal.map((item) => (
                                                        <tr key={internal.indexOf(item)}>
                                                            <td
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                    fontSize: "small",
                                                                }}
                                                            >
                                                                {internal.indexOf(item) + 1}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                    fontSize: "small",
                                                                }}
                                                            >
                                                                {item.numberOfLoans}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                    fontSize: "small",
                                                                }}
                                                            >
                                                                {textFormatService.formatCurrency(Number(item.totalOutstanding), 2)}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                    fontSize: "small",
                                                                }}
                                                            >
                                                                {item.repaymentFrequency}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                    fontSize: "small",
                                                                }}
                                                            >
                                                                {textFormatService.formatCurrency(Number(item.internalAmount), 2)}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                    fontSize: "small",
                                                                }}
                                                            >
                                                                {item.internalCurrency}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    paddingTop: "0.2rem",
                                                                    paddingBottom: "0.2rem",
                                                                    fontSize: "small",
                                                                }}
                                                            >
                                                                <i
                                                                    className="far fa-trash-alt ml-5 red-text fa-lg"
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() =>
                                                                        this.handleOnRemoveInternalDetails(
                                                                            item
                                                                        )
                                                                    }
                                                                ></i>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-header clear-fix">
                                    <h6
                                        className="float-left"
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <b>Loan Liability External</b>
                                    </h6>
                                </div>
                                <div className="card-body">
                                    <div className="form-row">
                                        <FormInputComponent
                                            label="Number of Loans"
                                            id="noOfLoansExt"
                                            name="noOfLoansExt"
                                            type="number"
                                            placeholder=""
                                            divClass={6}
                                            value={noOfLoansExt}
                                            required={this.getRequiredFields("loanLiabilityExternalNumberOfLoans")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        <FormInputComponent
                                            label="Total Outstanding"
                                            id="totalOutstandingExt"
                                            name="totalOutstandingExt"
                                            type="number"
                                            placeholder=""
                                            divClass={6}
                                            value={totalOutstandingExt}
                                            required={this.getRequiredFields("loanLiabilityExternalTotalOutstanding")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        <SelectInputComponent
                                            label="Repayment Frequency"
                                            id="frequencyExt"
                                            name="frequencyExt"
                                            divClass={6}
                                            value={frequencyExt}
                                            items={frequencyExpenseInputData}
                                            required={this.getRequiredFields("loanLiabilityExternalRepaymentFrequency")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        <FormInputComponent
                                            label="Repayment Amount"
                                            id="repaymentAmountExt"
                                            name="repaymentAmountExt"
                                            type="number"
                                            placeholder=""
                                            divClass={6}
                                            value={repaymentAmountExt}
                                            required={this.getRequiredFields("loanLiabilityExternalRepaymentAmount")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        {/* <FormInputComponent
                                            label="Currency"
                                            id="currencyExt"
                                            name="currencyExt"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={currencyExt}
                                            required={this.getRequiredFields("loanLiabilityExternalCurrency")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        /> */}
                                        <SelectInputComponent
                                            label="Currency"
                                            id="currencyExt"
                                            name="currencyExt"
                                            divClass={6}
                                            value={currencyExt}
                                            items={currencyInputData}
                                            required={this.getRequiredFields("loanLiabilityExternalCurrency")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="my-1 mx-2">
                                        {(getCurrentUsersRoles().includes("updatedataentry") && (
                                            <button
                                                className="btn btn-sm btn-outline-primary my-4"
                                                style={{ cursor: "pointer" }}
                                                type="button"
                                                onClick={() =>
                                                    this.handleOnAddExternalDetails(
                                                        {
                                                            noOfLoansExt,
                                                            totalOutstandingExt,
                                                            frequencyExt,
                                                            repaymentAmountExt,
                                                            currencyExt
                                                        },
                                                        onReloadFields
                                                    )
                                                }
                                            >
                                                <React.Fragment>
                                                    <i className="far fa-save fa-lg mr-2" />{" "}
                                                    Add
                                                </React.Fragment>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                                {/* Asset Table */}
                                <div className=" form-row col-md-12">
                                    <div
                                        className="table-responsive text-nowrap"
                                        style={{
                                            maxHeight: "150px",
                                            overflowY: "scroll",
                                        }}
                                    >
                                        <table className="table table-hover table-content table-sm table-striped mb-0">
                                            <thead>
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        style={{
                                                            paddingTop: "0.2rem",
                                                            paddingBottom: "0.2rem",
                                                        }}
                                                    >
                                                        <strong>#</strong>
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        style={{
                                                            paddingTop: "0.2rem",
                                                            paddingBottom: "0.2rem",
                                                        }}
                                                    >
                                                        <strong>No Of Loans</strong>
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        style={{
                                                            paddingTop: "0.2rem",
                                                            paddingBottom: "0.2rem",
                                                        }}
                                                    >
                                                        <strong>Total Outstanding</strong>
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        style={{
                                                            paddingTop: "0.2rem",
                                                            paddingBottom: "0.2rem",
                                                        }}
                                                    >
                                                        <strong>Repayment Frequency</strong>
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        style={{
                                                            paddingTop: "0.2rem",
                                                            paddingBottom: "0.2rem",
                                                        }}
                                                    >
                                                        <strong>Repayment Amount</strong>
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        style={{
                                                            paddingTop: "0.2rem",
                                                            paddingBottom: "0.2rem",
                                                        }}
                                                    >
                                                        <strong>External Currency</strong>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {external.map((item) => (
                                                    <tr key={external.indexOf(item)}>
                                                        <td
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                                fontSize: "small",
                                                            }}
                                                        >
                                                            {external.indexOf(item) + 1}
                                                        </td>
                                                        <td
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                                fontSize: "small",
                                                            }}
                                                        >
                                                            {item.noOfLoansExt}
                                                        </td>
                                                        <td
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                                fontSize: "small",
                                                            }}
                                                        >
                                                            {textFormatService.formatCurrency(Number(item.totalOutstandingExt), 2)}
                                                        </td>
                                                        <td
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                                fontSize: "small",
                                                            }}
                                                        >
                                                            {item.frequencyExt}
                                                        </td>
                                                        <td
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                                fontSize: "small",
                                                            }}
                                                        >
                                                            {textFormatService.formatCurrency(Number(item.repaymentAmountExt), 2)}
                                                        </td>
                                                        <td
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                                fontSize: "small",
                                                            }}
                                                        >
                                                            {item.currencyExt}
                                                        </td>
                                                        <td
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                                fontSize: "small",
                                                            }}
                                                        >
                                                            <i
                                                                className="far fa-trash-alt ml-5 red-text fa-lg"
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() =>
                                                                    this.handleOnRemoveExternalDetails(
                                                                        item
                                                                    )
                                                                }
                                                            ></i>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            {/* Save Income Details */}
                            <div className="card-body float-right">
                                {(getCurrentUsersRoles().includes("updatedataentry") && (
                                    <button
                                        type="submit"
                                        className="btn btn-sm btn-primary"
                                        disabled={this.state.submitting}
                                        onClick={(e) => {
                                            onHandleSubmit(
                                                e,
                                                ActionTypes.SAVE,
                                                "Income Details",
                                                () => {
                                                    this.onFormSubmit(
                                                        {
                                                            incomeType,
                                                            frequencyIncome,
                                                            amount,
                                                            currency,
                                                            frequencyExpense,
                                                            expenseType,
                                                            expenseAmount,
                                                            expenseCurrency,
                                                            numberOfLoans,
                                                            repaymentFrequency,
                                                            totalOutstanding,
                                                            internalAmount,
                                                            internalCurrency,
                                                            noOfLoansExt,
                                                            frequencyExt,
                                                            repaymentAmountExt,
                                                            totalOutstandingExt,
                                                            currencyExt
                                                        },
                                                        onReloadFields
                                                    )
                                                }
                                            );
                                        }}
                                    >
                                        {this.state.submitting === false ? (
                                            <React.Fragment>
                                                <i className="fas fa-lg fa-save mr-3" /> Save
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                                Please wait...
                                            </React.Fragment>
                                        )}
                                    </button>
                                ))}
                            </div>

                        </div>
                    </div>
                )}
            ></IncomeDetailsForm>
        );
    }
}

export default IncomeDetails;
