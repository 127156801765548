import React, { Component } from "react";
import { getCurrentUserSession } from "../../services/auth/authService";
import OtherInstitutionDashboard from "../../components/dashboardComponents/otherInstitutionDashboard";
import { decodeToken } from "react-jwt";
import AppzoneDashboard from "../../components/dashboardComponents/appzoneDashboard";

export interface IDashboardProps {}

export interface IDashboardState {}

class Dashboard extends Component<IDashboardProps, IDashboardState> {
  constructor(props: IDashboardProps) {
    super(props);
    this.state = {};
  }

  // async componentDidMount() {
  // }

  render() {
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    // const isMyTokenExpired = isExpired(getCurrentUserSession().token || "");

    // console.log("Testing Jwt deserializer", decodedToken?.["InstitutionId"]);
    return (
      <React.Fragment>
        {Number(decodedToken?.["InstitutionId"]) === 1 ? (
          <AppzoneDashboard />
        ) : (
          <OtherInstitutionDashboard />
        )}
      </React.Fragment>
    );
  }
}

export default Dashboard;
