import React, { Fragment } from "react";
import { toast } from "react-toastify";
import { Modal, Button, Card, Accordion } from "react-bootstrap";
import _ from "lodash";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import appraisalValidationService from "../../../services/hybridAppraisal/appraisalValidationService";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../components/detailItem";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import appraisalLoanProductService from "../../../services/hybridAppraisal/loanProductService";
import DetailItemSelectComponent, {
  ISelectItems,
} from "../../../components/detailItemSelectBox";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../../services/auth/authService";
import { RepaymentSchedule } from "../../../enums/repaymentSchedule";
import scoringCardService from "../../../services/creditAssessment/scoringCardService";
import institutionUserService from "../../../services/auth/usersService";
import { ContextAwareToggle } from "../appraisalPlan/appraisalPlanDetail";
import loanProductService from "../../../services/hybridAppraisal/loanProductService";
import financialGroupService from "../../../services/hybridAppraisal/financialGroupService";
import loanProductMapService from "../../../services/hybridAppraisal/loanProductMapService";
import workFlowConfigService from "../../../services/hybridAppraisal/workFlowConfigService";

interface EditLoanProductMapFields {
  loanProduct: string;
  workFlowConfig: string;
  dataEntryConfig: string;
}

class EditLoanProductMapForm extends BaseFormComponent<EditLoanProductMapFields> { }

interface IEditLoanProductMapProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}

interface IEditLoanProductMapState {
  ownUpdate: boolean;
  loanProducts: any;
  workFlowConfig: any;
  dataEntryConfig: any;
  fetchingRequiredItems: boolean;
}

class EditLoanProductMap extends React.Component<
  IEditLoanProductMapProps,
  IBaseFormState & IEditLoanProductMapState
> {
  _isMounted = false;
  constructor(props: IEditLoanProductMapProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      ownUpdate: false,
      loanProducts: [],
      workFlowConfig: [],
      dataEntryConfig: [],
      fetchingRequiredItems: false,
    };
  }

  static getDerivedStateFromProps(nextProps: IEditLoanProductMapProps, prevState) {
    return {
      item: nextProps.item,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { errors } = this.state;
    try {
      if (this._isMounted) {
        this.setState({ fetchingRequiredItems: true, ownUpdate: true });
      }
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const response = await Promise.allSettled([
        loanProductService.retrieveByInstitutionCode(decodedToken?.["InstitutionCode"]),
        financialGroupService.retrieveByInstitutionCode(decodedToken?.["InstitutionCode"]),
        workFlowConfigService.retrieveByInstitutionCode(decodedToken?.["InstitutionCode"]),
      ])
      const loanProducts = response[0].status == "fulfilled" ? response[0].value.data : [];
      const workFlowConfig = response[1].status == "fulfilled" ? response[1].value.data : [];
      const dataEntryConfig = response[2].status == "fulfilled" ? response[2].value.data : [];
      this.setState({
        loanProducts: loanProducts,
        workFlowConfig: workFlowConfig,
        dataEntryConfig: dataEntryConfig,
        fetchingRequiredItems: false,
        ownUpdate: true
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        errors.response = "Internal server error.";
        this.setState({ errors, ownUpdate: true });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onFormSubmit(fields: EditLoanProductMapFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors:
            appraisalValidationService.validateEditLoanProductMapForm(fields),
          ownUpdate: true
        },
        () => {
          // console.log(this.state.errors)
          if (Object.keys(this.state.errors).length === 0) {
            // console.log("Just got here....")
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (
    fields: EditLoanProductMapFields,
    onReloadFieldsCB: any
  ): Promise<boolean> => {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const payload = { ...this.props.item };
      // console.log(payload)
      payload.institutionCode = decodedToken?.["InstitutionCode"];
      payload.loanProductConfigId = fields.loanProduct;
      payload.loanProductName = this.state.loanProducts?.filter((x) => x.id === Number(fields.loanProduct))[0]?.name;
      payload.financialGroupId = fields.workFlowConfig;
      payload.financialGroupName = this.state.workFlowConfig?.filter((x) => x.id === Number(fields.workFlowConfig))[0]?.name;
      payload.workflowId = fields.dataEntryConfig ? fields.dataEntryConfig : 0;
      payload.workflowName = !fields.dataEntryConfig ? "Default" : this.state.dataEntryConfig.filter((x) => x.id === Number(fields.dataEntryConfig))[0]?.name;


      // console.log("Payload: ", payload);
      const response = await loanProductMapService.updateLoanProductMap(payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit Loan Product Map",
          description: `Edited details for Loan Product Map ${payload.loanProductName}`,
        });

        toast.info(`Loan Product ${payload.loanProductName} Edited successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      }
      onReloadFieldsCB();
      this.setState({ submitting: false, ownUpdate: true }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      if (error.response.status === 409) {
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // console.log("submission error: ", error.response);
      }
      this.setState({ submitting: false, ownUpdate: true });
    }
    finally {
      return true;
    }
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      errors: {},
    });
  }

  render() {
    const { item, showEditModal, toggleEditModal } = this.props;
    const {
      validated,
      submitting,
      errors,
      loanProducts,
      dataEntryConfig,
      workFlowConfig,
      fetchingRequiredItems
    } = this.state;

    // console.log(item)

    let loanProductInputData: ISelectItems[] = loanProducts.map((item) => ({
      name: item.name,
      value: item.id,
    }));

    let financialGroupInputData: ISelectItems[] = workFlowConfig.map((item) => ({
      name: item.name,
      value: item.id,
    }));

    let workFlowConfigInputData: ISelectItems[] = dataEntryConfig.map((item) => ({
      name: item.name,
      value: item.id,
    }));

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-columns fa-sm cyan-text mr-3" />
              Edit Loan Product Map -{" "}
              <span className="font-weight-bold">{item.name}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditLoanProductMapForm
          initialValues={{
            loanProduct: item.loanProductConfigId?.toString(),
            workFlowConfig: item.financialGroupId?.toString(),
            dataEntryConfig: item.workFlowId?.toString()
          }}
          FormComponent={({
            fields: {
              loanProduct,
              workFlowConfig,
              dataEntryConfig
            },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    {_.isEmpty(item) ? (
                      <Skeleton width={200} />
                    ) : (
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        <b>General Information</b>
                      </h6>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        {_.isEmpty(item) ? (
                          <Skeleton count={3} width={300} />
                        ) : (
                          <dl className="row">
                            {fetchingRequiredItems ? (
                              <i className="fas fa-spinner fa-spin mr-2" />
                            ) : (
                              <DetailItem
                                label="Loan Product"
                                value={
                                  <DetailItemSelectComponent
                                    id="loanProduct"
                                    name="loanProduct"
                                    value={loanProduct}
                                    items={loanProductInputData}
                                    required={true}
                                    validated={validated}
                                    errors={errors}
                                    onChange={onChange}
                                  />
                                }
                                labelSize={5}
                                valueSize={7}
                              />
                            )}
                          </dl>
                        )}
                      </div>
                      <div className="col-md-4">
                        {_.isEmpty(item) ? (
                          <Skeleton count={3} width={300} />
                        ) : (
                          <dl className="row">
                            {fetchingRequiredItems ? (
                              <i className="fas fa-spinner fa-spin mr-2" />
                            ) : (
                              <DetailItem
                                label="Work Flow"
                                value={
                                  <DetailItemSelectComponent
                                    id="workFlowConfig"
                                    name="workFlowConfig"
                                    value={workFlowConfig}
                                    items={financialGroupInputData}
                                    required={true}
                                    validated={validated}
                                    errors={errors}
                                    onChange={onChange}
                                  />
                                }
                                labelSize={5}
                                valueSize={7}
                              />
                            )}
                          </dl>
                        )}
                      </div>
                      <div className="col-md-4">
                        {_.isEmpty(item) ? (
                          <Skeleton count={3} width={300} />
                        ) : (
                          <dl className="row">
                            {fetchingRequiredItems ? (
                              <i className="fas fa-spinner fa-spin mr-2" />
                            ) : (
                              <DetailItem
                                label="Data Entry"
                                value={
                                  <DetailItemSelectComponent
                                    id="dataEntryConfig"
                                    name="dataEntryConfig"
                                    value={dataEntryConfig}
                                    items={workFlowConfigInputData}
                                    required={false}
                                    validated={validated}
                                    errors={errors}
                                    onChange={onChange}
                                  />
                                }
                                labelSize={5}
                                valueSize={7}
                              />
                            )}
                          </dl>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.UPDATE,
                      "Loan Product Map",
                      () => {
                        this.onFormSubmit(
                          {
                            loanProduct,
                            workFlowConfig,
                            dataEntryConfig
                          },
                          onReloadFields
                        );
                      }
                    );
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></EditLoanProductMapForm>
      </Modal >
    );
  }
}

export default EditLoanProductMap;
