import React, { Component } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import mandateService from "../../services/mandate/mandateService";
import textFormatService from "../../services/utility/textFormatService";
import { getCurrentUserSession } from "../../services/auth/authService";
import institutionsService from "../../services/auth/institutionsService";
import transactionService from "../../services/sweep/transactionService";
import disputeConditionService from "../../services/dispute/disputeConditionService";
import disputeService from "../../services/dispute/disputeService";
import activityTrailService from "../../services/auditTrail/activityTrailService";
import disputeValidationService from "../../services/dispute/disputeValidationService";
import DetailItem from "../../components/detailItem";
import { decodeToken } from "react-jwt";

interface ILogDisputeProps { }
interface ILogDisputeState {
  submitting: boolean;
  validated: boolean;
  fetchingTrxDetails: boolean;
  fetchingMandate: boolean;
  data: any;
  errors: any;
  mandate: any;
  disputeConditions: any;
  fetchingDisputeConditionDocuments: boolean;
  disputeConditionDocuments: any;
}

class LogDispute extends Component<ILogDisputeProps, ILogDisputeState> {
  constructor(props: ILogDisputeProps) {
    super(props);
    this.state = {
      submitting: false,
      validated: false,
      fetchingTrxDetails: false,
      fetchingMandate: false,
      data: {
        comment: "",
        disputeCondition: "",
        loanAccountInstitution: "",
        loanAccountNo: "",
        linkedAccountInstitution: "",
        linkedAccountNo: "",
        amountDebited: "",
        uniqueId: "",
        retrievalReferenceNo: "",
        timeSwept: "",
        narration: "",
        id: "",
        assignedDisputeConditionDocuments: [],
      },
      errors: {},
      mandate: {},
      disputeConditions: [],
      fetchingDisputeConditionDocuments: false,
      disputeConditionDocuments: [],
    };
  }

  handleTransactionReference = async (e) => {
    // e.PreventDefault();
    try {
      this.setState({ fetchingTrxDetails: false });
      const retrievalReferenceNo = this.state.data.retrievalReferenceNo;
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      this.setState({ fetchingTrxDetails: true });
      const institution = await institutionsService.retrieveInstitutionById(
        decodedToken?.["InstitutionId"]
      );
      let response;
      try {
        response = await transactionService.retrieveTransactionByReference(
          institution.data.code,
          retrievalReferenceNo
        );
        // console.log("transaction details: ", response.data);
        if (_.isEmpty(response.data)) {
          toast.warning(
            `No record found for transaction: [${retrievalReferenceNo}]! `,
            {
              autoClose: 6000,
              type: toast.TYPE.WARNING,
              hideProgressBar: false,
            }
          );
        } else {
          response.data[0].disputingInstitution =
            await institutionsService.retrieveInstitutionByCode(
              response.data[0].linkedAccountInstitution
            );
          response.data[0].lendingInstitution =
            await institutionsService.retrieveInstitutionByCode(
              response.data[0].loanAccountInstitution
            );
        }
      } catch (error) {
        // console.log(error);
        toast.warning(
          `Error retrieving Transaction Details for transaction: [${retrievalReferenceNo}]! `,
          {
            autoClose: 6000,
            type: toast.TYPE.WARNING,
            hideProgressBar: false,
          }
        );
        this.setState({ fetchingTrxDetails: false });
      }
      if (
        !_.isEmpty(response) &&
        (response.status >= 200 || response.status <= 290) &&
        !_.isEmpty(response.data[0])
      ) {
        response.data[0].assignedDisputeConditionDocuments = [];
        response.data[0].comment = "";
        response.data[0].disputeCondition = "";
        this.setState({
          data: response.data[0],
          fetchingTrxDetails: true,
          // data["assignedDisputeConditionDocuments"]: [],
        });
        const disputeConditions =
          await disputeConditionService.retrieveAllDisputeConditions();
        this.setState({ disputeConditions: disputeConditions.data });

        // const mandate = await mandateService.getMandateByLoanReference(
        //   getCurrentUserSession().institutionId,
        //   "0001001008667"
        // );
        this.setState({ fetchingMandate: true });
        const mandate = await mandateService.retrieveMandateByLoanReferenceOnly(
          // response.data[0].lendingInstitution.data.id,
          response.data[0].loanAccountNo
        );
        // console.log("Checking mandate issue :: ", response);
        // console.log("Checking mandate issue :: ", mandate);

        this.setState({
          mandate: mandate.data,
          fetchingMandate: false,
          fetchingDisputeConditionDocuments: true,
        });
        const disputeConditionDocuments =
          await disputeConditionService.retrieveAllDisputeConditionDocuments();
        this.setState({
          disputeConditionDocuments: disputeConditionDocuments.data,
          fetchingDisputeConditionDocuments: false,
        });
      }
      this.setState({ fetchingTrxDetails: false });
    } catch (error) {
    } finally {
      this.setState({
        fetchingMandate: false,
        fetchingTrxDetails: false,
        fetchingDisputeConditionDocuments: false,
      });
    }
  };

  handleOnInputChange = (e) => {
    let data = { ...this.state.data };
    data[e.currentTarget.name] = e.currentTarget.value;
    if (e.currentTarget.name === "disputeCondition") {
      let disputeCondition = this.state.disputeConditions.filter(
        (x) => x.id === parseInt(e.currentTarget.value)
      )[0];
      if (!_.isEmpty(disputeCondition)) {
        data["assignedDisputeConditionDocuments"] = [];
        const assignedDisputeConditionDocuments: any = [];
        disputeCondition.disputeConditionDocuments.forEach((doc) => {
          assignedDisputeConditionDocuments.push(doc.id);
        });
        data["assignedDisputeConditionDocuments"] =
          assignedDisputeConditionDocuments;
      }
    }
    this.setState({ data });
  };

  handleOnDisputeConditionDocumentChange = (e, item) => {
    let data = { ...this.state.data };
    const assignedDisputeConditionDocuments = [
      ...data.assignedDisputeConditionDocuments,
    ];
    if (assignedDisputeConditionDocuments.indexOf(item.id) !== -1) {
      assignedDisputeConditionDocuments.splice(
        assignedDisputeConditionDocuments.indexOf(item.id),
        1
      );
    } else {
      assignedDisputeConditionDocuments.push(item.id);
    }
    data.assignedDisputeConditionDocuments = assignedDisputeConditionDocuments;
    this.setState({ data });
  };

  onFormSubmit(data: any): boolean {
    try {
      this.setState(
        { errors: disputeValidationService.validateLogDisputeForm(data.data) },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(data.data);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(data: any): Promise<boolean> {
    try {
      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const { mandate } = this.state;
      const payload: any = {};
      let response: any = null;
      payload.institutionId = decodedToken?.["InstitutionId"];
      payload.customerPhoneNumber = mandate.phoneNumber;
      payload.mandateId = mandate.id;
      // payload.disputeStatus = "Pending";
      payload.narration = data.narration;
      payload.disputeConditionId = data.disputeCondition;
      payload.loanAccountInstitution = data.loanAccountInstitution;
      payload.loanAccountNumber = data.loanAccountNo;
      payload.linkedAccountInstitution = data.linkedAccountInstitution;
      payload.linkedAccountNumber = data.linkedAccountNo;
      payload.amountDebited = data.amountDebited;
      payload.uniqueId = data.uniqueId;
      payload.retrievalReferenceNumber = data.retrievalReferenceNo;
      let res = data.timeSwept.split("-");
      var dt = res[1] + "-" + res[0] + "-" + res[2];
      // payload.timeSwept = new Date(dt);
      payload.timeSwept = new Date(data.timeSwept);
      payload.dateCreated = new Date();
      payload.comment = data.comment;
      payload.assignedDisputeConditionDocuments =
        data.assignedDisputeConditionDocuments;

      // console.log("data: ", data);
      // console.log("payload: ", JSON.stringify(payload));
      this.setState({ submitting: true });
      response = await disputeService.saveDispute(payload);
      this.setState({ submitting: false });
      await activityTrailService.saveActivityTrail({
        actionType: "Save Dispute",
        description: `Saved new Dispute ${mandate.phoneNumber}`,
      });
      toast.info(`Dispute logged successfully for ${mandate.phoneNumber}! `, {
        autoClose: 6000,
        type: toast.TYPE.DEFAULT,
        hideProgressBar: false,
      });
      this.resetFields();
      return true;
    } catch (error) {
      // console.log(error.response);
      toast.error(error.response.data.Message);
      return false;
      // this.resetFields();
    } finally {
      this.setState({ submitting: false });
    }
  }

  resetFields() {
    const { data } = this.state;
    data.comment = "";
    data.disputeCondition = "";
    data.loanAccountInstitution = "";
    data.loanAccountNo = "";
    data.linkedAccountInstitution = "";
    data.linkedAccountNo = "";
    data.amountDebited = "";
    data.uniqueId = "";
    data.retrievalReferenceNo = "";
    data.timeSwept = "";
    data.narration = "";
    this.setState({ data, mandate: {}, validated: false });
  }

  validateField = (fieldName: string, type: string, validated: boolean) => {
    let defaultClass = "";
    if (type === "select") {
      defaultClass =
        "custom-select select2 mr-sm-2 form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    } else {
      defaultClass = "form-control r-0 light s-12";
      if (validated === false) return defaultClass;
    }
    return !validated
      ? defaultClass
      : this.state.errors[fieldName]
        ? `${defaultClass} is-invalid`
        : `${defaultClass} is-valid`;
  };

  render() {
    const {
      data,
      errors,
      validated,
      submitting,
      mandate,
      disputeConditions,
      fetchingTrxDetails,
      fetchingMandate,
      disputeConditionDocuments,
    } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Log Dispute</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card mb-3">
                  <div className="card-header">
                    <i className="fas fa-align-left mr-3" />
                    Transaction Reference
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-10">
                        <div className="form-group row">
                          <label
                            htmlFor="retrievalReferenceNo"
                            className="col-md-2 col-form-label"
                          >
                            Transaction Reference:
                          </label>
                          <div className="col-md-8">
                            <input
                              autoFocus
                              type="text"
                              className={this.validateField(
                                "retrievalReferenceNo",
                                "text",
                                validated
                              )}
                              id="retrievalReferenceNo"
                              name="retrievalReferenceNo"
                              value={data.retrievalReferenceNo}
                              onChange={this.handleOnInputChange}
                            />
                            <div
                              className="invalid-feedback"
                              style={{
                                visibility: errors.retrievalReferenceNo,
                              }}
                            >
                              {errors.retrievalReferenceNo}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group clearfix">
                      <button
                        type="button"
                        className="btn btn-sm btn-success float-right"
                        disabled={fetchingTrxDetails}
                        onClick={this.handleTransactionReference}
                      >
                        {fetchingTrxDetails === true ? (
                          <React.Fragment>
                            <i className="fas fa-spinner fa-spin mr-3"></i>
                            Fetching...
                          </React.Fragment>
                        ) : (
                          <React.Fragment>Fetch Transaction</React.Fragment>
                        )}
                      </button>
                    </div>
                  </div>

                  {data.loanAccountNo === "" && _.isEmpty(mandate) ? (
                    ""
                  ) : (
                    <React.Fragment>
                      <div
                        className="card-header card-footer"
                        style={{
                          padding: "0px",
                          height: "35px",
                          paddingTop: "5px",
                          paddingLeft: "10px",
                        }}
                      >
                        Transaction Details
                      </div>
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-md-6">
                            <div className="row">
                              <DetailItem
                                label="Loan Account Institution"
                                value={data.lendingInstitution.data.name}
                              />
                              <DetailItem
                                label="Loan Account No. "
                                value={data.loanAccountNo}
                              />
                              <DetailItem
                                label="Amount Debited"
                                value={textFormatService.convertKoboToNaira(
                                  data.amountDebited,
                                  2
                                )}
                              />
                              <DetailItem
                                label="Sweep Time"
                                value={data.timeSwept}
                              />
                              <DetailItem
                                label="Narration"
                                value={data.narration}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <DetailItem
                                label="Linked Account Institution"
                                value={data.disputingInstitution.data.name}
                              />
                              <DetailItem
                                label="Linked Account No. "
                                value={data.linkedAccountNo}
                              />
                              <DetailItem
                                label="Unique ID"
                                value={data.uniqueId}
                              />
                              <DetailItem
                                label="Retrieval Reference No"
                                value={data.retrievalReferenceNo}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="card-header card-footer"
                        style={{
                          padding: "0px",
                          height: "35px",
                          paddingTop: "5px",
                          paddingLeft: "10px",
                        }}
                      >
                        Mandate Details
                      </div>
                      <div className="card-body">
                        {_.isEmpty(mandate) ? (
                          <React.Fragment>
                            {fetchingMandate === true ? (
                              <div className="text-center">
                                <i className="fas fa-spin fa-spinner"></i>
                              </div>
                            ) : (
                              <div
                                className="text-center"
                                style={{ color: "#FF5733" }}
                              >
                                <h5>
                                  <i>
                                    No mandate was found for this transaction.
                                    Please confirm and try again.
                                  </i>
                                </h5>
                              </div>
                            )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div className="form-group row">
                              <div className="col-md-6">
                                <dl className="row">
                                  <DetailItem
                                    label="Name"
                                    value={mandate.customerName}
                                  />
                                  <DetailItem label="BVN" value={mandate.bvn} />
                                  <DetailItem
                                    label="Sweep Type"
                                    value={mandate.sweepType}
                                  />
                                  <DetailItem
                                    label="Consent Receipt Channel"
                                    value={mandate.consentReceiptChannel}
                                  />
                                  <DetailItem
                                    label="Phone Number"
                                    value={mandate.phoneNumber}
                                  />
                                </dl>
                              </div>
                              <div className="col-md-6">
                                <dl className="row">
                                  <DetailItem
                                    label="Loan Amount"
                                    value={textFormatService.convertKoboToNaira(
                                      mandate.loanAmount,
                                      2
                                    )}
                                  />
                                  <DetailItem
                                    label="Total Repayment Expected"
                                    value={textFormatService.convertKoboToNaira(
                                      mandate.totalRepaymentExpected,
                                      2
                                    )}
                                  />
                                  <DetailItem
                                    label="Date Created"
                                    value={mandate.dateCreated}
                                  />
                                  <DetailItem
                                    label="Loan Reference"
                                    value={mandate.loanReference}
                                  />

                                  {/* <DetailItem
                      label="Mandate Type"
                      value={mandate.mandateType}
                    /> */}
                                </dl>
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                      <div
                        className="card-header card-footer"
                        style={{
                          padding: "0px",
                          height: "35px",
                          paddingTop: "5px",
                          paddingLeft: "10px",
                        }}
                      >
                        Other Details
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-11">
                            <div className="form-group row">
                              <label
                                htmlFor="disputeCondition"
                                className="col-md-2 col-form-label"
                              >
                                Dispute Condition:
                              </label>
                              <div className="col-md-8">
                                <select
                                  className={this.validateField(
                                    "disputeCondition",
                                    "select",
                                    validated
                                  )}
                                  onChange={this.handleOnInputChange}
                                  value={data.disputeCondition}
                                  name="disputeCondition"
                                >
                                  <option value="">
                                    -Select Dispute Condition-
                                  </option>
                                  {!_.isEmpty(disputeConditions)
                                    ? disputeConditions.map((item) => (
                                      <option key={item.id} value={item.id}>
                                        {item.name} - {item.code}
                                      </option>
                                    ))
                                    : ""}
                                </select>
                                <div
                                  className="invalid-feedback"
                                  style={{
                                    visibility: errors.disputeCondition,
                                  }}
                                >
                                  {errors.disputeCondition}
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="disputeConditionDocuments"
                                className="col-md-2 col-form-label"
                              ></label>
                              <div className="col-md-8">
                                {_.isEmpty(data.disputeCondition) ? (
                                  <React.Fragment>
                                    <div
                                      className="text-center"
                                      style={{ textAlign: "center" }}
                                    >
                                      {/* <i className="fas fa-spin fa-2x fa-circle-notch"></i> */}
                                    </div>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <div className="card my-3 my-3">
                                      <div
                                        className="card-body p-0 bg-light slimScroll"
                                      // style={{ height: '300px' }}
                                      >
                                        <div className="table-responsive">
                                          <table className="table table-hover">
                                            <tbody>
                                              {disputeConditionDocuments.map(
                                                (item) => (
                                                  <tr key={item.name}>
                                                    <td>
                                                      <input
                                                        id={item.name}
                                                        name={item.name}
                                                        // className='custom-control-input'
                                                        type="checkbox"
                                                        onChange={(e) =>
                                                          this.handleOnDisputeConditionDocumentChange(
                                                            e,
                                                            item
                                                          )
                                                        }
                                                        checked={
                                                          _.isEmpty(
                                                            this.state.data.assignedDisputeConditionDocuments.filter(
                                                              (x) =>
                                                                x === item.id
                                                            )
                                                          )
                                                            ? false
                                                            : true
                                                        }
                                                      />
                                                    </td>
                                                    <td>
                                                      <b>{item.name}</b>
                                                      <p>
                                                        <i
                                                          style={{
                                                            color: "red",
                                                          }}
                                                        >
                                                          {item.description}
                                                        </i>
                                                      </p>
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                    {/* <ul className='list-unstyled row'>
                                      {disputeConditionDocuments.map(item => (
                                        <li key={item.name}>
                                          <div className='custom-control custom-checkbox'>
                                            <input
                                              id={item.name}
                                              name={item.name}
                                              className='custom-control-input'
                                              type='checkbox'
                                              onChange={e =>
                                                this.handleOnDisputeConditionDocumentChange(
                                                  e,
                                                  item
                                                )
                                              }
                                              checked={
                                                _.isEmpty(
                                                  this.state.data.assignedDisputeConditionDocuments.filter(
                                                    x => x === item.id
                                                  )
                                                )
                                                  ? false
                                                  : true
                                              }
                                            />
                                            <label
                                              className='custom-control-label'
                                              htmlFor={item.name}
                                            >
                                              <b>{item.name}</b>
                                              <p>
                                                <i style={{ color: 'red' }}>
                                                  {item.description}
                                                </i>
                                              </p>
                                            </label>
                                          </div>
                                          <br />
                                        </li>
                                      ))}
                                    </ul> */}
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="comment"
                                className="col-md-2 col-form-label"
                              >
                                Comments:
                              </label>
                              <div className="col-md-8">
                                <textarea
                                  rows={3}
                                  className={this.validateField(
                                    "comment",
                                    "text",
                                    validated
                                  )}
                                  id="comment"
                                  name="comment"
                                  value={data.comment}
                                  onChange={this.handleOnInputChange}
                                />
                                <div
                                  className="invalid-feedback"
                                  style={{ visibility: errors.comment }}
                                >
                                  {errors.comment}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="form-group clearfix">
                          <button
                            type="button"
                            disabled={
                              _.isEmpty(mandate) && submitting ? true : false
                            }
                            className="btn btn-sm btn-primary float-right"
                            onClick={(e) => {
                              e.preventDefault();
                              this.onFormSubmit({ data });
                            }}
                          >
                            {submitting === false ? (
                              <React.Fragment>
                                <i className="fas fa-lg fa-save mr-4" /> Log
                                Dispute
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                Please wait...
                              </React.Fragment>
                            )}
                          </button>
                        </div>
                      </div>
                      <br />
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LogDispute;
