import React, { Component } from "react";
import approvableService from "../../services/approval/approvableService";
import { getCurrentUsersRoles } from "../../services/auth/authService";

export interface IApprovalConfigGroupItemProps {
  items: any;
  toggleDetailsModal: any;
}

export interface IApprovalConfigGroupItemState {
  items: any;
}
class ApprovalConfigGroupItem extends Component<
  IApprovalConfigGroupItemProps,
  IApprovalConfigGroupItemState
> {
  constructor(props: IApprovalConfigGroupItemProps) {
    super(props);
    this.state = {
      items: [],
    };
  }

  handleUpdateApprovableFunction = (e) => {
    const items = [...this.props.items];
    const index = items.indexOf(
      items.filter((x) => x.functionName === e.currentTarget.name)[0]
    );
    if (index !== -1) {
      const item = items[index];
      this.handleEnableOrDisableItem(item);
      item.status = item.status === "Active" ? "Inactive" : "Active";
      items[index] = item;
      this.setState({ items });
    }
  };

  handleEnableOrDisableItem = async (item) => {
    switch (item.status) {
      case "Active":
        await approvableService.disableApprovable(item);
        break;
      case "Inactive":
        await approvableService.enableApprovable(item);
        break;
      default:
        await approvableService.disableApprovable(item);
        break;
    }
  };

  render() {
    const { items } = this.props;
    return (
      <React.Fragment>
        {items.map((item) => (
          <div key={item.uuid}>
            {/* <i className="fas fa-circle-notch"></i> */}
            <div
              className="row border"
              style={{
                padding: "0px 100px",
                margin: "0px 2px",
                backgroundColor:
                  item.status === "Active" ? "#FFFFFF" : "#F6F7F9",
                borderRadius: "5px",
              }}
            >
              <div className="col-md-7 col-sm-7">
                <h6
                  className={
                    item.status === "Active"
                      ? "font-weight-normal"
                      : "font-weight-light"
                  }
                  style={{ marginTop: "5px", marginBottom: "0px" }}
                >
                  {item.functionName}
                </h6>
                <span className="font-italic">
                  <p
                    className={
                      item.status === "Active"
                        ? "deep-orange-text"
                        : "text-muted"
                    }
                    //className="deep-orange-text"
                    style={{ paddingBottom: "0px", marginBottom: "0px" }}
                  >
                    {item.description}
                  </p>
                </span>
              </div>
              <div className="col-md-2 col-sm-2 mt-4">
                {item.status === "Active" &&
                  getCurrentUsersRoles().includes(
                    "disableapprovalconfigurationitems"
                  ) && (
                    <div className="form-group row">
                      <span className="material-switch">
                        <input
                          id={item.id}
                          name={item.functionName}
                          type="checkbox"
                          onChange={(e) =>
                            this.handleUpdateApprovableFunction(e)
                          }
                          checked={item.status === "Active"}
                        />
                        <label htmlFor={item.id} className="bg-primary"></label>
                      </span>
                      <label>&nbsp;&nbsp;{item.status}</label>
                    </div>
                  )}
                {item.status === "Inactive" &&
                  getCurrentUsersRoles().includes(
                    "enableapprovalconfigurationitems"
                  ) && (
                    <div className="form-group row">
                      <span className="material-switch">
                        <input
                          id={item.id}
                          name={item.functionName}
                          type="checkbox"
                          onChange={(e) =>
                            this.handleUpdateApprovableFunction(e)
                          }
                          checked={item.status === "Active"}
                        />
                        <label htmlFor={item.id} className="bg-primary"></label>
                      </span>
                      <label>&nbsp;&nbsp;{item.status}</label>
                    </div>
                  )}
              </div>
              <div className="col-md-2 col-sm-2 clearfix mt-4">
                {getCurrentUsersRoles().includes(
                  "updateapprovalconfigurationitems"
                ) && (
                  <button
                    type="button"
                    onClick={() => this.props.toggleDetailsModal(item)}
                    className="btn btn-sm btn-outline-secondary waves-effect float-left"
                    style={{
                      margin: "0px",
                      padding: "0px 10px 0px 10px",
                    }}
                  >
                    <i className="fas fa-network-wired mr-2" /> Rules
                  </button>
                )}
              </div>
            </div>
            {/* <hr style={{ margin: "0px", color: "#010101", height: "5px" }}></hr> */}
          </div>
        ))}
      </React.Fragment>
    );
  }
}

export default ApprovalConfigGroupItem;
