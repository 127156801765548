import React from "react";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import { decodeToken } from "react-jwt";
import BaseFormComponent, { IBaseFormState } from "../../../../components/BaseFormComponent";
import { ActionTypes } from "../../../../enums/actionTypes";
import FormInputComponent from "../../../../components/formInputComponent";
import creditAssessmentValidationService from "../../../../services/creditAssessment/creditAssessmentValidationService";
import institutionConfigService from "../../../../services/creditAssessment/institutionConfigService";
import activityTrailService from "../../../../services/auditTrail/activityTrailService";

interface ModifyLoanPricingConfigFields {
  interestRateMarkup: string
}

class ModifyLoanPricingConfigForm extends BaseFormComponent<ModifyLoanPricingConfigFields> { }

interface IModifyLoanPricingConfigProps {
  item: any;
  showPatchModal: boolean;
  togglePatchModal: any;
}
interface IModifyLoanPricingConfigState { }

class ModifyLoanPricingConfig extends React.Component<
  IModifyLoanPricingConfigProps,
  IBaseFormState & IModifyLoanPricingConfigState
> {
  constructor(props: IModifyLoanPricingConfigProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {}
    };
  }

  // static getDerivedStateFromProps(
  //   nextProps: IModifyLoanPricingConfigProps,
  //   prevState
  // ) {
  //   if (prevState.ownUpdate) {
  //     return {
  //       ownUpdate: false,
  //     };
  //   }
  //   if (_.isEmpty(nextProps.item)) {
  //     return null;
  //   }
  //   return {
  //     rePopulateCheckList: true,
  //     creditQualificationRuleIds: nextProps.item.creditQualificationRules.map(
  //       (item) => item.creditQualificationRuleId
  //     ),
  //   };
  // }

  onFormSubmit(
    fields: ModifyLoanPricingConfigFields,
    onReloadFieldsCB: any
  ): boolean {
    try {
      this.setState(
        {
          errors: creditAssessmentValidationService.validateEditLoanPricingConfigForm(fields),
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (
    fields: ModifyLoanPricingConfigFields,
    onReloadFieldsCB: any
  ) => {
    try {
      this.setState({ submitting: true });
      // console.log("institution", this.props.item)
      const response = await institutionConfigService.changeInterestRateMarkup(this.props.item.institutionCode, Number(fields.interestRateMarkup));
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Modify Loan Pricing Configuration",
          description: `Modified Loan Pricing Configuration for ${this.props.item.name}`,
        });
        // console.log("response :: ", response.status)
        toast.info(
          `Loan Pricing Configuration modified successfully! `,
          {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          }
        );
      }
      onReloadFieldsCB();
      this.setState({ submitting: false }, () =>
        this.props.togglePatchModal(true, this.props.item)
      );
    } catch (error) {
      //   console.log("submission error: ", error);
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false });
    }
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
    });
  }

  render() {
    const { item, showPatchModal, togglePatchModal } = this.props;
    const { validated, submitting, errors } = this.state;

    if (_.isEmpty(item)) {
      return null;
    }

    return (
      <Modal
        size="sm"
        backdrop="static"
        show={showPatchModal}
        onHide={togglePatchModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-edit fa-sm cyan-text mr-3" />
              Edit Loan Pricing Configuration
            </h5>
          </Modal.Title>
        </Modal.Header>
        <ModifyLoanPricingConfigForm
          initialValues={{ interestRateMarkup: item.interestRateMarkup?.toString() }}
          FormComponent={({
            fields: { interestRateMarkup },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    {_.isEmpty(item) ? (
                      <Skeleton width={200} />
                    ) : (
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        <b>General Information</b>
                      </h6>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="form-row">
                      <div className="col-md-12">
                        <FormInputComponent
                          id="interestRateMarkup"
                          type="number"
                          name="interestRateMarkup"
                          placeholder=""
                          // divClass={6}
                          value={interestRateMarkup}
                          required={true}
                          validated={validated}
                          errors={errors}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.UPDATE,
                      "Loan Pricing Configuration",
                      () => {
                        this.onFormSubmit({ interestRateMarkup }, onReloadFields);
                      }
                    );
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => togglePatchModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></ModifyLoanPricingConfigForm>
      </Modal>
    );
  }
}

export default ModifyLoanPricingConfig;
