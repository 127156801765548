import React, { Fragment } from "react";
import _ from "lodash";
import { Button, Modal, Nav } from "react-bootstrap";
import { getCurrentUserSession } from "../../../services/auth/authService";
import CreditSummary from "./appraisalDetailPages/creditSummary";
import ViewGroupDetailedDataEntry from "./appraisalDetailPages/viewDetailedDataEntry";
import TinValidation from "./appraisalDetailPages/tinValidationScreen";
import GroupNotepadDetails from "./appraisalDetailPages/wizardComponents/viewDetailedDataEntry/notepad";
import PolicyScoring from "./appraisalDetailPages/policyScoringScreen";
import CreditAnalysis from "./appraisalDetailPages/creditAnalysisScreen";
import FinancialAnalysis from "./appraisalDetailPages/financialAnalysisScreen";
import DocumentApproval from "../loanAppraisal/appraisalDetailPages/DocumentApprovalScreen";
import DocumentManagement from "../loanAppraisal/appraisalDetailPages/DocumentManagement";
import DocumentCollection from "../loanAppraisal/appraisalDetailPages/postApprovalDcoumentScreen";
import SpecialConditions from "../loanAppraisal/appraisalDetailPages/SpecialConditionsScreen";
import LoanDisbursement from "./appraisalDetailPages/loanDisbursementScreen";
import { decodeToken } from "react-jwt";
import Skeleton from "react-loading-skeleton";
import BankAnalysisCheck from "../loanAppraisal/appraisalDetailPages/BankAnalysisCheck";
import BankAnalysisReview from "../loanAppraisal/appraisalDetailPages/BankAnalysisReview";
import BankOneCreditReview from "../loanAppraisal/appraisalDetailPages/BankOneCredit";
import CreditBureauReview from "../loanAppraisal/appraisalDetailPages/creditBureau";
import ExternalCreditAssessmentReview from "../loanAppraisal/appraisalDetailPages/externalCreditAssessment";
import ExternalCreditAssessmentCheck from "../loanAppraisal/appraisalDetailPages/externalCreditAssessmentCheck";
import FinancialAnalysisCheck from "../loanAppraisal/appraisalDetailPages/FinancialAnalysisScreen";
import DetailedDataEntry from "../loanAppraisal/appraisalDetailPages/viewDetailedDataEntry";
import ViewAllLoanRequestDetails from "../loanAppraisal/viewAllLoanRequestDetails";
import PolicyScoringResult from "./appraisalDetailPages/policyScoringResult";
import ViewAllGroupLoanRequests from "./viewAllGroupLoanRequests";
import ViewAllGroupLoanDetails from "./viewAllGroupLoanDetails";
// import DetailedDataEntry from "./appraisalDetailPages/viewDetailedDataEntry";
// import NotePad from './appraisalDetailPages/wizardComponents/viewDetailedDataEntry/notepad';
// import BankAnalysisCheck from "./appraisalDetailPages/BankAnalysisCheck";
// import BankAnalysisReview from "./appraisalDetailPages/BankAnalysisReview";
// import BankOneCreditCheck from "./appraisalDetailPages/BankOneCreditCheck";
// import BankOneCreditReview from "./appraisalDetailPages/BankOneCredit";
// import CreditBureauCheck from "./appraisalDetailPages/CreditBureauCheck";
// import CreditBureauReview from "./appraisalDetailPages/creditBureau";
// import CreditSummary from "./appraisalDetailPages/creditSummary";
// import ExternalCreditAssessmentCheck from "./appraisalDetailPages/externalCreditAssessmentCheck";
// import ExternalCreditAssessmentReview from "./appraisalDetailPages/externalCreditAssessment";
// import PolicyScoringCheck from "./appraisalDetailPages/policyScoringCheck";
// import PolicyScoringReview from "./appraisalDetailPages/policyScoringReview";
// import PolicyScoringResult from "./appraisalDetailPages/policyScoringResult";
// import CreditAnalysisCheck from "./appraisalDetailPages/creditAnalysisCheck";

interface IAppraisalWorkflowProps {
    item: any;
    userRoles: any;
    systemProcess: any;
    showDetailsModal: boolean;
    toggleDetailsModal: any;
}

interface IAppraisalWorkflowState {
    item: any;
    title: string;
    ownUpdate: boolean;
    roleId: string;
    documentsToUpload: any;
    documentsToDelete: number[];
    updateAvailable: boolean;
    showDetailsModal: boolean;
}

class GroupAppraisalWorkflowModal extends React.Component<IAppraisalWorkflowProps, IAppraisalWorkflowState> {
    constructor(props: IAppraisalWorkflowProps) {
        super(props);
        this.state = {
            item: {},
            title: "",
            ownUpdate: false,
            roleId: "",
            documentsToUpload: [],
            documentsToDelete: [],
            updateAvailable: false,
            showDetailsModal: false
        };
    }

    static getDerivedStateFromProps(nextProps: IAppraisalWorkflowProps, prevState) {
        if (prevState.ownUpdate) {
            return {
                ownUpdate: false,
            };
        }
        if (_.isNull(nextProps.item)) {
            return null;
        }
        return {
            item: nextProps.item
        };
    }

    updateApprovalItemDocs = async (document: any) => {
        try {
            const docs = [...this.state.documentsToUpload];
            docs.push(document);
            this.setState({ documentsToUpload: docs, updateAvailable: true });
        } catch (error) {
            // log
        }
    };

    deleteApprovalItemDocs = async (document: any, documentId: number) => {
        try {
            if (document != null) {
                const docs = [...this.state.documentsToUpload];
                let selectedDoc = docs.filter(doc => doc.document === document.document && doc.name === document.name)[0]
                docs.splice(docs.indexOf(selectedDoc), 1);
                this.setState({ documentsToUpload: docs, updateAvailable: true });
            } else {
                const docs = [...this.state.documentsToDelete];
                docs.push(documentId);
                this.setState({ documentsToDelete: docs, updateAvailable: true });
            }
        } catch (error) {
            // log
        }
    };

    async componentDidMount() {
        const decodedToken = decodeToken(getCurrentUserSession().token || "");
        // console.log(decodedToken.UserRoleId)

        this.setState({ roleId: decodedToken.UserRoleId })
    }



    onChangePage = (title) => {
        this.setState({ title, ownUpdate: true })
    }

    approvalDetailsPage = (title) => {
        var item = this.state.item;
        const { userRoles, systemProcess } = this.props;
        switch (title) {
            case "":
                return <ViewAllGroupLoanDetails item={item} systemProcess={systemProcess} userRoles={userRoles} currentUserSession={getCurrentUserSession()} />;
            case "Detailed Data Entry":
                return <ViewGroupDetailedDataEntry item={item} />;
            case "Notepad":
                return <GroupNotepadDetails values={item} />;
            //     case "Bank Analysis":
            //         return <BankAnalysisCheck item={item} />;
            //     case "Bank Analysis Review":
            //         return <BankAnalysisReview item={item} systemProcess={systemProcess} userRoles={userRoles} />;
            //     case "BankOne Credit":
            //         return <CreditAnalysisCheck item={item} />;
            //     case "BankOne Credit Review":
            //         return <BankOneCreditReview item={item} systemProcess={systemProcess} userRoles={userRoles} />;
            //     case "Credit Bureau":
            //         return <CreditBureauCheck item={item} />;
            case "Credit Analysis (Internal)":
            case "Credit Analysis":
                return <CreditAnalysis item={item} systemProcess={systemProcess} userRoles={userRoles} currentUserSession={getCurrentUserSession()} />;
            //     case "Credit Bureau Review":
            //         return <CreditBureauReview item={item} systemProcess={systemProcess} userRoles={userRoles} />;
            case "Credit Summary":
                return <ViewAllGroupLoanDetails item={item} systemProcess={systemProcess} userRoles={userRoles} currentUserSession={getCurrentUserSession()} />;
            //     case "External Credit Assessment":
            //         return <ExternalCreditAssessmentCheck item={item} />;
            //     case "External Credit Assessment Review":
            //         return <ExternalCreditAssessmentReview item={item} systemProcess={systemProcess} userRoles={userRoles} />;
            case "Score Card":
            case "Policy and Scoring":
                return <PolicyScoring item={item} systemProcess={systemProcess} userRoles={userRoles} currentUserSession={getCurrentUserSession()} />;
            case "Score Card Review":
            case "PolicyScoring Review":
                // return <TinValidation item={item} />;
                // return <CreditAnalysisCheck item={item} />;
                return <PolicyScoring item={item} systemProcess={systemProcess} userRoles={userRoles} currentUserSession={getCurrentUserSession()} />;
            case "Tin Validation":
                return <TinValidation item={item} />;
            case "Financial Analysis":
                return <FinancialAnalysis item={item} systemProcess={systemProcess} userRoles={userRoles} currentUserSession={getCurrentUserSession()} />;
            case "Special Conditions":
                return <SpecialConditions item={item} systemProcess={systemProcess} userRoles={userRoles} currentUserSession={getCurrentUserSession()} />;

            // Financial analysis
            // case "Financial Analysis":
            //     return <FinancialAnalysisCheck item={item} />;

            case "Document Review":
                return <DocumentApproval values={item} />;

            case "Document Management":
                return <DocumentManagement item={item} />;

            case "Document Upload":
                return <DocumentCollection values={item} />;
            case "Disbursement":
            case "Loan Disbursement":
                return <LoanDisbursement
                    item={item}
                    values={item}
                    userRoles={userRoles}
                    systemProcess={systemProcess}
                    updateItemDocs={(document: any) => this.updateApprovalItemDocs(document)}
                    deleteItemDocs={(document: any, documentId: number) => this.deleteApprovalItemDocs(document, documentId)}
                    tempDocs={this.state.documentsToUpload}
                    tempDeletedDocs={this.state.documentsToDelete}
                />;

            default:
                // console.log(title)
                return (
                    <div>
                        <h6 className="font-weight-normal text-center font-italic text-danger">
                            ... no appraisal details page available for item.
                        </h6>
                    </div>
                );
        }
    };

    render() {
        const { item, systemProcess, showDetailsModal, toggleDetailsModal } = this.props;

        if (_.isEmpty(item)) {
            return null;
        }
        // console.log(item)

        return (
            <Modal
                size="xl"
                backdrop="static"
                show={showDetailsModal}
                onHide={toggleDetailsModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {_.isEmpty(item) ? (
                            <Skeleton width={500} />
                        ) : (
                            <h5>
                                <i className="icon icon--columns s-18 mr-3" />
                                Loan Details -{" "}
                                <span className="font-weight-bold"></span>
                            </h5>
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Fragment>
                        <div className="container-fluid relative animatedParent animateOnce">
                            <div className="animated fadeInUpShort go">
                                <div className="row my-3 mx-2">
                                    <div className="card no-b">
                                        <div className="row">

                                            {/* Side NavBar */}
                                            <div className="col-md-2">
                                                <div className="card">
                                                    <div className="card-header clear-fix">
                                                        <h6
                                                            className="float-left"
                                                            style={{ marginBottom: "0px" }}
                                                        >
                                                            <b>Appraisal Workflow</b>
                                                        </h6>
                                                    </div>
                                                    <div className="card-body">
                                                        <Nav
                                                            className=""
                                                            activeKey="#"
                                                        >
                                                            {/* <Nav.Item>
<Nav.Link
title="Detailed Data Entry"
onClick={() => this.onChangePage("Detailed Data Entry")}
>
Data Validation
</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link
title="Document Management"
onClick={() => this.onChangePage("Document Management")}
>
Document Management
</Nav.Link>
</Nav.Item> */}

                                                            {/* <Nav.Item>
<Nav.Link
title="Financial Analysis"
onClick={() => this.onChangePage("Financial Analysis")}
>
Financial Analysis
</Nav.Link>
</Nav.Item> */}

                                                            {_.isEmpty(item) ? (
                                                                ""
                                                            ) : (

                                                                item.financialGroup?.rules.filter((a) => (a.level === item.currentLevel && a.approverType === "SystemProcess"))
                                                                    ?.map((rule) => (
                                                                        <Nav.Item key={rule.id}>
                                                                            <><Nav.Link
                                                                                title={systemProcess.filter(x => x.id === Number(rule.systemProcessID))[0]?.name}
                                                                                onClick={() => this.onChangePage(systemProcess.filter(x => x.id === Number(rule.systemProcessID))[0]?.name)}
                                                                            >
                                                                                {systemProcess.filter(x => x.id === Number(rule.systemProcessID))[0]?.name}
                                                                            </Nav.Link></>
                                                                        </Nav.Item>
                                                                    ))

                                                            )}
                                                            {_.isEmpty(item) ? (
                                                                ""
                                                            ) : (

                                                                item.financialGroup?.levels.filter((a) => (a.level === item.currentLevel && a.functionNames !== ""))[0].functionNames
                                                                    ?.map((x) => (
                                                                        <Nav.Item key={x.id}>
                                                                            <><Nav.Link
                                                                                title={x}
                                                                                onClick={() => this.onChangePage(x)}
                                                                            >
                                                                                {x}
                                                                            </Nav.Link></>
                                                                        </Nav.Item>
                                                                    ))

                                                            )}
                                                            {/* <Nav.Item>
                                                        <Nav.Link
                                                            title="Detailed Data Entry"
                                                            onClick={() => this.onChangePage("Detailed Data Entry")}
                                                        >
                                                            Data Validation
                                                        </Nav.Link>
                                                    </Nav.Item> */}
                                                            {/* <Nav.Item>
                                                        <Nav.Link
                                                            title="Notepad"
                                                            onClick={() => this.onChangePage("Notepad")}
                                                        >
                                                            Notepad
                                                        </Nav.Link>
                                                    </Nav.Item> */}
                                                            <Nav.Item>
                                                                <Nav.Link
                                                                    title="Notepad"
                                                                    onClick={() => this.onChangePage("Tin Validation")}
                                                                >
                                                                    Tin Validation
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link
                                                                    title="Credit Summary"
                                                                    onClick={() => this.onChangePage("Credit Summary")}
                                                                >
                                                                    Loan Summary
                                                                </Nav.Link>
                                                            </Nav.Item>

                                                            {/* {item.requestStatus === 'Completed' ? (
<Nav.Item>
<Nav.Link
title="Predisbursement Check"
onClick={() => this.onChangePage("Predisbursement Check")}
>
Predisbursement Check
</Nav.Link>
</Nav.Item>
) : (
""
)} */}

                                                        </Nav>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Content */}
                                            <div className="col-md-10">
                                                {this.approvalDetailsPage(this.state.title)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                </Modal.Body><Modal.Footer>

                    <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={toggleDetailsModal}
                    >
                        <i className="fas fa-times mr-3" />
                        Close
                    </Button>
                </Modal.Footer>
            </Modal >
        );
    }
}

export default GroupAppraisalWorkflowModal;
