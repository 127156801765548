import React from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import { IBaseFormState } from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import creditQualificationRuleService from "../../../services/creditAssessment/creditQualificationRuleService";
import swal from "sweetalert";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

interface ISetupCreditQualificationIndicatorsProps {
  institutionCode: string;
  editting: boolean;
  indicators: any;
}

interface ISetupCreditQualificationIndicatorsState {
  submitted: boolean;
  creditQualificationIndicators: any;
  indicatorIds: any;
  // fetchingIndicators: boolean;
  // existingIndicatorConfig: any;
}

class SetupCreditQualificationIndicators extends React.Component<
  ISetupCreditQualificationIndicatorsProps,
  IBaseFormState & ISetupCreditQualificationIndicatorsState
> {
  constructor(props: ISetupCreditQualificationIndicatorsProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      submitted: false,
      // fetchingIndicators: false,
      // existingIndicatorConfig: [],
      creditQualificationIndicators: [],
      indicatorIds: [],
    };
  }

  async componentWillMount() {
    // const { errors } = this.state;
    try {
      //   this.setState({ fetchingRequiredItems: true });

      const creditQualificationIndicators =
        await creditQualificationRuleService.retrieveCreditQualificationIndicators();
      let existingCreditQualificationIndicators: any = [];
      if (this.props.editting) {
        // existingCreditQualificationIndicators = await creditQualificationRuleService.retrieveCreditQualificationIndicatorsByInstitution(
        //   this.props.institutionCode
        // );
        // existingCreditQualificationIndicators =
        //   existingCreditQualificationIndicators.data;
        existingCreditQualificationIndicators = this.props.indicators;
      }

      this.setState({
        creditQualificationIndicators: creditQualificationIndicators.data,
        // fetchingRequiredItems: false,
        indicatorIds: existingCreditQualificationIndicators.map(
          (item) => item.id
        ),
      });
    } catch (error) {
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // return false;
      }
    }
  }

  isValidated() {
    // console.log("We're checking now ... ");
    if (this.state.submitting) {
      toast.info(`Already attempting to save Qualification Indicators `, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      return false;
    }

    if (this.state.submitted) {
      this.setState({ submitted: false });
      return true;
    }

    try {
      // if (this.state.existingFeeConfig.length > 0) {
      //   return true;
      // }

      return this.onHandleSubmit(ActionTypes.SAVE, "Qualification Indicators", () => {
        this.onFormSubmit();
      });
    } catch (error) { }
    return false;
  }

  onHandleSubmit = (
    actionType: ActionTypes | null,
    entityName: string,
    cb: any
  ) => {
    if (!actionType) {
      return cb();
    } else {
      swal({
        title: "Confirm!",
        text: `Are you sure you want to proceed to ${ActionTypes[actionType]} this ${entityName}? This action is Irreversible`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode:
          actionType === 2 ||
            actionType === 3 ||
            actionType === 7 ||
            actionType === 8
            ? true
            : false,
      }).then((resp) => {
        if (resp) {
          // console.log("cancelling action...");
        } else {
          return cb();
        }
      });
    }
  };

  onFormSubmit(): boolean {
    try {
      this.setState(
        {
          errors: [],
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit();
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(): Promise<boolean> {
    try {
      this.setState({ submitting: true });
      // const decodedToken = decodeToken(getCurrentUserSession().token || "");

      const payload: any = {};
      payload.creditQualificationIndicatorIds = this.state.indicatorIds;
      const response =
        await creditQualificationRuleService.addOrRemoveCreditQualificationIndicatorsToInstitution(
          this.props.institutionCode,
          payload
        );
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Setup Credit Qualification Rule",
          description: `Setup new Credit Qualification Rule`,
        });
        toast.info(
          `Credit Qualification Indicators setup for ${this.props.institutionCode} successfully! `,
          {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          }
        );
      } else {
        return false;
      }
      this.setState({ submitting: false, submitted: true });
      this.resetFields();
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false });
      return false;
    } finally {
      return true;
    }
  }

  handleOnCreditQualificationRuleChange = (item) => {
    // console.log("clicked Credit QUalification Rule Indicator: ", item);
    const indicatorIds = [...this.state.indicatorIds];
    if (indicatorIds.indexOf(item) !== -1) {
      indicatorIds.splice(indicatorIds.indexOf(item), 1);
    } else {
      indicatorIds.push(item);
    }
    // console.log("Credit QUalification Rule Indicators: ", indicatorIds);
    this.setState({ indicatorIds });
  };

  resetFields() {
    this.setState({ validated: false, submitting: false });
  }
  render() {
    const {
      // errors,
      // validated,
      creditQualificationIndicators,
      indicatorIds,
    } = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            <div className="card-body">
              <h5 className="card-title">
                {/* <i className="fas fa-university mr-3" /> */}
                Credit Qualification Indicators
              </h5>
              <div className="form-row">
                <div className="col-md-12">
                  <div
                    className="text-nowrap block-example border"
                    style={{
                      width: "100%",
                      height: "200px",
                      overflowY: "scroll",
                    }}
                  >
                    <React.Fragment>
                      {creditQualificationIndicators.length === 0 ? (
                        <div
                          style={{
                            backgroundColor: "#F3F3F3",
                          }}
                        >
                          <p className="text-center">
                            no credit qualification rule indicators
                          </p>
                        </div>
                      ) : (
                        <React.Fragment>
                          {creditQualificationIndicators.map((item) => (
                            <div
                              className="custom-control custom-checkbox"
                              key={creditQualificationIndicators.indexOf(item)}
                            >
                              <input
                                id={item.id}
                                name={item.id}
                                className="custom-control-input"
                                type="checkbox"
                                onChange={() =>
                                  this.handleOnCreditQualificationRuleChange(
                                    item.id
                                  )
                                }
                                checked={
                                  _.isEmpty(
                                    indicatorIds.filter((x) => x === item.id)
                                  )
                                    ? false
                                    : true
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={item.id}
                              >
                                {item.description}
                              </label>
                            </div>
                          ))}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  </div>
                </div>
              </div>
              <hr />
              {this.state.submitting === false ? (
                <React.Fragment>
                  {/* <i className="fas fa-lg fa-save mr-3" /> Save */}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <i className="fas fa-spin fa-circle-notch mr-3" /> Saving...
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SetupCreditQualificationIndicators;
