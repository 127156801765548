import * as React from "react";
// import _ from "lodash";
import { Fragment } from "react";
import {
  IFilterData,
  BaseListComponentState,
} from "../../../components/BaseListComponent";
import { printPDF } from "../../../services/utility/textFormatService";
import {
  createFilterData,
  mapEntityList,
} from "./../../../services/utility/autoMapperService";
import BaseListComponent from "./../../../components/BaseListComponent";
import mandateService from "../../../services/mandate/mandateService";
import ItemsTable, {
  filterFieldData,
} from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "./../../../components/tableComponents/tableFilterPane";
import { getCurrentUserSession } from "../../../services/auth/authService";
// import bvnService from "../../../services/mandate/bvnService";
import institutionsService from "../../../services/auth/institutionsService";
import mandateSweepService from "../../../services/sweep/mandateSweepService";
// import reportsService from "../../../services/sweep/reportsService";
import MandateDetail from "./mandateDetail";
// import { retrieveAccountsByBVN } from "./../../../services/mandate/bvnService";
import { retrieveAccountMapsByBvn } from "../../../services/linking/bvnAccountMapService";
import { decodeToken } from "react-jwt";
import dataProviderService from "../../../services/externalIntegration/dataProviderService";

export interface MandatesProps { }

export interface MandatesState extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
}

class MandatesList extends BaseListComponent<MandatesState> { }

class Mandates extends React.Component<MandatesProps, MandatesState> {
  constructor(props: MandatesProps) {
    super(props);
    this.state = {
      data: createFilterData([
        "customerName",
        "bvn",
        // "loanAmount",
        "phoneNumber",
        "DateCreated",
        "status"
      ]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [
        {
          name: "customerName",
          fieldType: "text",
          value: "",
        },
        { name: "bvn", fieldType: "number", value: "" },
        // { name: "loanAmount", fieldType: "number", value: "" },
        { name: "phoneNumber", fieldType: "number", value: "" },
        { name: "DateCreated", fieldType: "date", value: "" },
        {
          name: "status",
          fieldType: "select",
          options: ["Active", "Cancelled"],
          value: "",
        }
      ],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
    };
  }

  handlePageChange = async (currentPage: number, pageSize: number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true });
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    let dataClone: IFilterData = typeof data === "undefined" ? { ...this.state.data } : { ...data };
    try {
      if (dataClone.phoneNumber.item2 === "") {
        delete dataClone.phoneNumber
      }
      if (dataClone.bvn.item2 === "") {
        delete dataClone.bvn
      }
      if (dataClone.status.item2 === "") {
        delete dataClone.status
      }
      if (dataClone.customerName.item2 === "") {
        delete dataClone.customerName
      }
      if (dataClone.DateCreated.item2 === "") {
        delete dataClone.DateCreated
      }
      // var test: any = {};
      if (decodedToken?.["InstitutionId"]?.toString() !== "1") {
        dataClone.institutionId = {
          item1: "=",
          item2: decodedToken?.["InstitutionId"]?.toString() || "break",
        };
      }
      const mandates = await mandateService.filterMandates(
        dataClone,
        pageSize,
        currentPage
      );

      // console.log(mandates)
      mandates.data.item2.forEach((mandate) => {
        if (mandate.repaymentType === "Collection") {
          mandate.repaymentType = "Collection & Recovery";
        }
      });
      this.setState({
        tableItems: mapEntityList({
          entityList: mandates.data.item2,
          properties: [
            { oldName: "customerName", newName: "Name" },
            { oldName: "bvn", newName: "BVN" },
            { oldName: "phoneNumber", newName: "phoneNumber" },
            { oldName: "loanReference", newName: "loanReference" },
            { oldName: "loanAmount", newName: "loanAmount" },
            {
              isTag: true,
              oldName: "repaymentType",
              newName: "repaymentType",
              success: "Collection & Recovery",
              danger: "danger",
              default: "default",
              primary: "Recovery",
              secondary: "secondary",
              warning: "warning",
              light: "light",
              dark: "dark",
            },
            {
              isTag: true,
              oldName: "status",
              newName: "status",
              success: "Active",
              danger: "Inactive",
              default: "default",
              primary: "Confirmed",
              secondary: "secondary",
              warning: "Cancelled",
              light: "AwaitingConfirmation",
              dark: "dark",
            },
            {
              isTag: true,
              oldName: "requestStatus",
              newName: "requestStatus",
              success: "Disbursed",
              danger: "Failed",
              default: "AwaitingDisbursement",
              primary: "Confirmed",
              secondary: "secondary",
              warning: "Cancelled",
              light: "AwaitingConfirmation",
              dark: "dark",
            },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: mandates.data.item1,
        fetching: false,
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
        // this.setState({ errors, fetching: false });
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        // this.setState({ errors, fetching: false });
      }
    } finally {
      this.setState({ fetching: false });
    }
  };

  handleOnExport = async (type: string) => {
    try {
      switch (type) {
        case "XLSX":
        case "CSV":
          return await mandateService.downloadReport(type);
        case "PDF":
          const response = await mandateService.downloadReportPDF();
          printPDF(response.data);
          break;
        default:
          break;
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        //this.setState({ errors, fetching: false });
      }
    }
  };

  fetchItemById = async (item: any) => {
    // await activityTrailService.saveActivityTrail({
    //   actionType: "View Details",
    //   description: `Viewed details of Mandate ${item.name}`
    // });

    try {
      this.setState({ fetchingDetail: true });
      const decodedToken = decodeToken(getCurrentUserSession().token || "");

      const response = await Promise.allSettled([
        mandateService.getMandateById(item.id),
        mandateSweepService.retrieveMandateInfo(
          decodedToken?.["InstitutionCode"] || "",
          item.loanReference
        ),
        retrieveAccountMapsByBvn(item.BVN),
      ]);
      const mandate =
        response[0].status === "fulfilled" ? response[0].value.data : {};
      // Fetch mandate sweep info
      const sweepMandateInfo =
        response[1].status === "fulfilled" ? response[1].value.data : {};
      const _bvnaccounts =
        response[2].status === "fulfilled" ? response[2].value.data : {};

      // console.log("mandate: ", mandate);
      if (mandate?.hasGuarantor) {
        mandate?.secondaryMandates?.forEach((element) => {
          element.guaranteedAmount =
            0.01 *
            element.guaranteeingPercentage *
            mandate?.totalRepaymentExpected;
          element.requestStatus = mandate?.requestStatus;
          element.sweepType = mandate?.sweepType;
        });
      }

      const stateItem = mandate;
      // console.log("mandate: ", mandate);

      // console.log("response: ", sweepMandateInfo);
      stateItem.totalAmountDeducted = sweepMandateInfo?.totalAmountDeducted;
      stateItem.totalNoOfTimesDeducted =
        sweepMandateInfo?.totalNoOfTimesDeducted;
      stateItem.lastAmountDeducted = sweepMandateInfo?.lastAmountDeducted;
      stateItem.lastDeductionDate = sweepMandateInfo?.lastDeductionDate;

      //linked accounts
      stateItem.bvnAccounts = _bvnaccounts?.accounts;
      stateItem.firstDateLinked = _bvnaccounts?.firstDateLinked;
      stateItem.lastDateLinked = _bvnaccounts?.lastDateLinked;
      if (
        stateItem.bvnAccounts === null ||
        stateItem.bvnAccounts === undefined
      ) {
        stateItem.bvnAccounts = [];
      } else {
        stateItem.bvnAccounts.forEach((item) => {
          item.firstDateLinked = _bvnaccounts?.firstDateLinked;
        });
      }
      // console.log("checker0: ", stateItem);
      // console.log("checker1: ", _bvnaccounts);
      // console.log("checker2: ", _bvnaccounts);
      this.setState({ item: stateItem });
    } catch (error) {
    } finally {
      this.setState({
        fetchingDetail: false,
      });
    }
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "" && key !== "institutionId") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }

  render() {
    const {
      // data,
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    let data = createFilterData([]) as IFilterData;
    let filterValueInput = [];
    if (decodedToken?.["InstitutionId"]?.toString() !== "1") {
      data = createFilterData([
        "customerName",
        "bvn",
        // "loanAmount",
        "phoneNumber",
        "DateCreated",
        "status"
      ]) as IFilterData;

      filterValueInput = [
        {
          name: "customerName",
          fieldType: "text",
          value: "",
        },
        { name: "bvn", fieldType: "number", value: "" },
        // { name: "loanAmount", fieldType: "number", value: "" },
        { name: "phoneNumber", fieldType: "number", value: "" },
        { name: "DateCreated", fieldType: "date", value: "" },
        {
          name: "status",
          fieldType: "select",
          options: ["Active", "Cancelled"],
          value: "",
        }
      ];
    }
    else {
      data = createFilterData([
        "institutionId",
        "customerName",
        "bvn",
        // "loanAmount",
        "phoneNumber",
        "DateCreated",
        "status"
      ]) as IFilterData

      filterValueInput = [
        {
          name: "institutionId",
          fieldType: "number",
          value: "",
        },
        {
          name: "customerName",
          fieldType: "text",
          value: "",
        },
        { name: "bvn", fieldType: "number", value: "" },
        // { name: "loanAmount", fieldType: "number", value: "" },
        { name: "phoneNumber", fieldType: "number", value: "" },
        { name: "DateCreated", fieldType: "date", value: "" },
        {
          name: "status",
          fieldType: "select",
          options: ["Active", "Cancelled"],
          value: "",
        }
      ]
    }
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Mandates</b>
              </h3>
            </div>
            <MandatesList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              initialValues={{
                // 
                data: data,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                // filterValueInput: [
                //   {
                //     name: "customerName",
                //     fieldType: "text",
                //     value: "",
                //   },
                //   { name: "bvn", fieldType: "number", value: "" },
                //   // { name: "loanAmount", fieldType: "number", value: "" },
                //   { name: "phoneNumber", fieldType: "number", value: "" },
                //   { name: "DateCreated", fieldType: "date", value: "" },
                // ],
                filterValueInput: filterValueInput,
                item: {},
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                onToggleEditModal,
                showDetailsModal,
                showEditModal,
              }) => (
                <Fragment>
                  <MandateDetail
                    showDetailsModal={showDetailsModal}
                    toggleDetailsModal={onToggleDetailsModal}
                    item={item}
                  />
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="Mandates"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                              amountColumn={["loanAmount"]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></MandatesList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Mandates;
