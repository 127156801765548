import React, { Component, Fragment, useContext } from "react";
import {
    Button,
    Card,
    useAccordionToggle,
    AccordionContext,
    Accordion,
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { getCurrentUserSession, getCurrentUsersRoles } from "../../../services/auth/authService";
import { toast } from "react-toastify";
import BaseFormComponent from "./../../../components/BaseFormComponent";
import { ActionTypes } from "./../../../enums/actionTypes";
import loanRequestService from "./../../../services/hybridAppraisal/loanRequestService";
import BaseListComponent, { IFilterData } from "./../../../components/BaseListComponent";
import { createFilterData, mapEntityList } from "./../../../services/utility/autoMapperService";
import ItemsTable, { filterFieldData } from "./../../../components/tableComponents/itemsTable";
import FormTextAreaComponent from "./../../../components/formTextAreaComponent";
import appraisalActionService from "./../../../services/hybridAppraisal/appraisalActionService";
import { decodeToken } from "react-jwt";
import LoanRequestItemDetail from "./../loanRequest/appraisalProcessComponents/loanRequestItem";
import usersService from "./../../../services/auth/usersService";
import TableFilterPane from "./../../../components/tableComponents/tableFilterPane";
// import TinRequestDetails from "./TinRequestDetails";
import textFormatService, { generateGUID } from "./../../../services/utility/textFormatService";
import SelectInputComponent from "./../../../components/formSelectComponent";
import loanProductService from "./../../../services/hybridAppraisal/loanProductService";
import GroupLoanRequestItemDetail from "./groupLoanRequestItem";
import ValidateAmountModal from "../../../pages/hybridAppraisal/groupLending/appraisalDetailPages/validateAmountModal";
import hybridAppraisalValidationService from "./../../../services/hybridAppraisal/hybridAppraisalValidationService";

export function ContextAwareToggle({ eventKey, header }) {
    const currentEventKey = useContext(AccordionContext);
    const toggleOnClick = useAccordionToggle(eventKey);
    const isCurrentEventKey = currentEventKey === eventKey;
    const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
    return (
        <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
            <b>{header}</b>
            <i
                className={angleType}
                aria-hidden="true"
                style={{ float: "right" }}
            ></i>
        </Card.Header>
    );
}

interface CreditSummaryReviewFields {
    comment: string;
    decision: string;
    condition: any;
}

class CreditSummaryReviewForm extends BaseFormComponent<CreditSummaryReviewFields> { }

interface ICreditSummaryReviewProps {
    item: any;
    systemProcess: any;
    userRoles: any;
    currentUserSession: any;
}
interface IConditionOptions {
    id: string;
    value: string;
}


interface ICreditSummaryReviewState {
    item: any;
    updatingStatus: boolean;
    submitting: boolean;
    data: IFilterData;
    currentPage: number;
    pageSize: number;
    tableItems: any;
    fetching: boolean;
    fetchingDetail: boolean;
    totalSize: number;
    filterValueInput: filterFieldData;
    userRoles: any;
    systemProcess: any;
    applicableRules: any;
    appraisalActions: any;
    updateComponent: boolean;
    documentsToUpload: any;
    documentsToDelete: number[];
    canEditItem: boolean;
    updateAvailable: boolean;
    processResponse: any;
    specialConditions: any;
    ownUpdate: boolean;
    showValidateAmount: boolean;
    members: any,
    validated: boolean;
    errors: any;
}

class CreditSummaryList extends BaseListComponent<ICreditSummaryReviewState> { }

class ViewAllGroupLoanDetails extends Component<ICreditSummaryReviewProps, ICreditSummaryReviewState> {
    _isMounted: boolean;
    constructor(props: ICreditSummaryReviewProps) {
        super(props);
        this.state = {
            item: {},
            errors: {},
            validated: false,
            updatingStatus: false,
            submitting: false,
            data: createFilterData(["customerName", "loanType"]) as IFilterData,
            currentPage: 1,
            pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
            fetching: false,
            fetchingDetail: false,
            tableItems: [],
            totalSize: 0,
            filterValueInput: [
                { name: "customerName", fieldType: "text", value: "" },
                {
                    name: "loanType",
                    fieldType: "select",
                    options: ["Individual", "Corporate"],
                    value: "",
                }
            ],
            userRoles: [],
            systemProcess: [],
            applicableRules: [],
            appraisalActions: [],
            documentsToUpload: [],
            documentsToDelete: [],
            updateComponent: false,
            canEditItem: false,
            updateAvailable: false,
            specialConditions: [],
            processResponse: {
                actionId: 0,
                score: 0,
                response: ""
            },
            ownUpdate: false,
            showValidateAmount: false,
            members: {}
        };
    }

    static getDerivedStateFromProps(nextProps: ICreditSummaryReviewProps, prevState) {
        if (prevState.ownUpdate) {
            return {
                ownUpdate: false,
            };
        }
        if (_.isNull(nextProps.item)) {
            return null;
        }
        return {
            item: nextProps.item,
            userRoles: nextProps.userRoles,
            systemProcess: nextProps.systemProcess
        };
    }

    async componentDidMount() {
        this._isMounted = true;
        this.handlePageChange(this.state.currentPage, this.state.pageSize);
        let applicableRules = this.props.item.financialGroup.rules.filter(
            (rule) =>
                rule.level === this.props.item.currentLevel &&
                rule.roleID === this.props.currentUserSession.userRoleId
        );
        const appraisalActions = this.props.item.appraisalActions;

        if (appraisalActions) {
            const userPromises: any = [];
            for (let index = 0; index < appraisalActions.length; index++) {
                if (appraisalActions[index].logType !== "SystemType") {
                    const userPromise = usersService.retrieveUserById(appraisalActions[index].userID);
                    userPromises.push(userPromise);
                }
            }

            // const users = await Promise.all(userPromises);
            const users = await Promise.allSettled(userPromises);
            // console.log(users)
            let userIndex = 0;
            for (let index = 0; index < appraisalActions.length; index++) {
                if (appraisalActions[index].logType !== "SystemType") {
                    const user = users[userIndex];
                    appraisalActions[index]["user"] = user.status == "fulfilled" ? user.value.data : null;
                    userIndex++;
                }
            }


            // for (let index = 0; index < appraisalActions.length; index++) {
            //   if (appraisalActions[index].logType !== "SystemType") {
            //     const user = await usersService.retrieveUserById(
            //       appraisalActions[index].userID
            //     );
            //     appraisalActions[index]["user"] = user.data;
            //   }
            // }

            // appraisalActions.reverse();
            // Check if any user role on the current level has approved
            const checkActions = appraisalActions.filter(action => (action.itemLevel == this.props.item.currentLevel && action.action == "Approve"));
            // console.log("approval actions:", checkActions);
            const canEdit = applicableRules.length !== 0 && this.props.item.status !== "Rejected" && (!getCurrentUsersRoles().includes("remotesession")) && checkActions.length == 0
            const specialConditions = this.props.item.specialConditions != null ? this.props.item.specialConditions : [];
            this.setState({ appraisalActions, applicableRules, canEditItem: canEdit, specialConditions: specialConditions, ownUpdate: true });
        }
    }

    loanRequestDetailsPage = (item) => {
        if (_.isEmpty(item)) return "";
        return <GroupLoanRequestItemDetail
            values={item}
            item={item.jsonData}
            financialGroup={{ lowerLimit: this.state.applicableRules[0]?.applicableAmountLower, upperLimit: this.state.applicableRules[0]?.applicableAmountUpper }}
            updateItem={(jsonData: string, amount: number) => this.updateApprovalItem(jsonData, amount)}
            updateItemDocs={(document: any) => this.updateApprovalItemDocs(document)}
            deleteItemDocs={(document: any, documentId: number) => this.deleteApprovalItemDocs(document, documentId)}
            tempDocs={this.state.documentsToUpload}
            tempDeletedDocs={this.state.documentsToDelete}
            canEditItem={this.state.canEditItem}
        />;
    };

    updateApprovalItem = async (itemJSON: string, applicableAmount: number) => {
        try {
            const payload = this.props.item;
            payload.applicableAmount = applicableAmount;
            payload.jsonData = itemJSON;
            this.setState({ updateAvailable: true, ownUpdate: true })
            return true;
        } catch (error) {
            toast.error(error.response.data.Message);
            this.setState({ submitting: false, ownUpdate: true });
            return false;
        }
    };

    updateApprovalItemDocs = async (document: any) => {
        try {
            const docs = [...this.state.documentsToUpload];
            docs.push(document);
            this.setState({ documentsToUpload: docs, updateAvailable: true, ownUpdate: true });
        } catch (error) {
            // log
        }
    };

    deleteApprovalItemDocs = async (document: any, documentId: number) => {
        try {
            if (document != null) {
                const docs = [...this.state.documentsToUpload];
                let selectedDoc = docs.filter(doc => doc.document === document.document && doc.name === document.name)[0]
                docs.splice(docs.indexOf(selectedDoc), 1);
                this.setState({ documentsToUpload: docs, updateAvailable: true, ownUpdate: true });
            } else {
                const docs = [...this.state.documentsToDelete];
                docs.push(documentId);
                this.setState({ documentsToDelete: docs, updateAvailable: true, ownUpdate: true });
            }
        } catch (error) {
            // log
        }
    };
    toggleShowValidateAmountModal = (id) => {
        var members = this.props.item.solidarityGroups.filter(x => x.id === id)[0]?.members;
        // console.log(members)
        this.setState({ showValidateAmount: !this.state.showValidateAmount, members: members });
    };

    getActionTypeString = (actionType) => {
        switch (actionType) {
            case 2:
                return "Update";
            case 6:
                return "Approve";
            case 7:
                return "Decline";
            case 8:
                return "PushBack";
            case 9:
                return "Recall";
            default:
                return "";
        }
    };

    onFormSubmit(fields: CreditSummaryReviewFields, actionType): boolean {
        try {
            var action = this.getActionTypeString(actionType)
            this.setState(
                { errors: hybridAppraisalValidationService.validateCreditSummaryForm(fields, true), ownUpdate: true },
                () => {
                    // console.log(this.state.errors)
                    if (Object.keys(this.state.errors).length === 0) {
                        return this.submit(fields, action);
                    }
                }
            );
            this.setState({ validated: true, ownUpdate: true });
            return false;
        } catch (error) {
            return false;
        }
    }

    async submit(
        fields: CreditSummaryReviewFields,
        action
    ): Promise<boolean> {
        try {
            this.setState({ submitting: true, ownUpdate: true });
            const payload: any = {};
            var curDT = new Date();

            payload.action = fields.decision;
            payload.loanRequestId = this.props.item.id
            payload.systemProcessID = Number(0);
            payload.userID = this.props.currentUserSession.userId;
            payload.roleID = this.props.currentUserSession.userRoleId;
            payload.itemLevel = this.props.item.currentLevel;
            payload.errorMessage = "";
            payload.logType = "UserType";
            payload.comment = fields.comment;
            payload.effectiveStatus = "Valid";
            payload.id = Number(0);
            payload.processResponse = null;
            payload.dateCreated = curDT;
            payload.dateUpdated = curDT;
            payload.IsGroupLoan = true;

            // console.log("payload: ", payload);
            const response = await appraisalActionService.createAppraisalAction(payload);
            if (response.status >= 200 && response.status <= 300) {
                toast.info(`Appraisal Concluded Successfully!`, {
                    autoClose: 6000,
                    type: toast.TYPE.DEFAULT,
                    hideProgressBar: false,
                });
            }
            this.setState({ submitting: false, ownUpdate: true });
        }
        catch (error) {
            if (error.response.status === 409) {
                toast.error(error.response.data.detail, {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            } else if (error.response.status === 400) {
                toast.error(error.response.data.errors[0], {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            }
        } finally {
            this.setState({ submitting: false, ownUpdate: true });
            return true;
        }
    }
    handlePageChange = async (currentPage: number, pageSize: number, data?: IFilterData) => {
        currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
        pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
        if (this._isMounted) {
            this.setState({ fetching: true, ownUpdate: true });
        }
        let dataClone = typeof data === "undefined" ? { ...this.state.data } : { ...data };
        const decodedToken = decodeToken(getCurrentUserSession().token || "");
        dataClone["InstitutionCode"] = { item1: "=", item2: decodedToken?.["InstitutionCode"] }
        dataClone["TIN"] = { item1: "=", item2: this.props.item.tin }
        try {
            // console.log("tin", this.props.item)
            // console.log("tin", this.props.item.tin)
            // const loanRequests = await loanRequestService.retrieveAllByTin(this.props.item.tin, decodedToken?.["InstitutionCode"])
            const response = await Promise.allSettled([
                loanRequestService.filterLoanRequests(
                    {filters: dataClone, accessibleBranchCodes: null}, 
                    pageSize, 
                    currentPage
                ),
                loanProductService.retrieveByInstitutionCode(decodedToken?.["InstitutionCode"])
            ])
            // console.log(response)
            const loanRequests = response[0].status == "fulfilled" ? response[0].value.data : [];
            const loanProducts = response[1].status == "fulfilled" ? response[1].value.data : [];

            // console.log(this.state.item)
            const requests = loanRequests.item2;
            requests.splice(
                requests.indexOf(requests.filter(request => request.id == this.state.item.id)[0]), 1
            );

            // console.log("request :: ", requests)
            // console.log("products :: ", loanProducts)
            requests.forEach(request => {
                request["productName"] = loanProducts.filter(product => product.productCode == request.productCode)[0].name;
            });
            // console.log("item index :: ", requests.filter(request => request.id == this.state.item.id))
            // console.log("index :: ", requests.indexOf(requests.filter(request => request.id == this.state.item.id)[0]))
            // console.log("request :: ", requests)
            // console.log("request :: ", loanRequests.data)
            if (this._isMounted) {
                this.setState({
                    tableItems: mapEntityList({
                        entityList: requests,
                        properties: [
                            { oldName: "customerName", newName: "Name" },
                            { oldName: "phonenumber", newName: "phoneNumber" },
                            { oldName: "loanAmount", newName: "loanAmount" },
                            { oldName: "tenureInDays", newName: "tenor" },
                            { oldName: "branch", newName: "branch" },
                            { oldName: "productName", newName: "productName" },
                            { oldName: "dateCreated", newName: "dateCreated" },
                            {
                                isTag: true,
                                oldName: "requestStatus",
                                newName: "status",
                                success: "Active",
                                danger: "Rejected",
                                default: "default",
                                primary: "primary",
                                secondary: "secondary",
                                warning: "Created",
                                light: "light",
                                dark: "dark",
                            },
                        ],
                    }),
                    currentPage,
                    pageSize,
                    totalSize: loanRequests.item1 - 1,
                    fetching: false,
                    ownUpdate: true
                });
            }
        } catch (ex) {
            if (ex.response && ex.response.status >= 400 && ex.response.status <= 499) {
                if (this._isMounted) {
                    this.setState({ fetching: false, ownUpdate: true });
                }
            } else {
                if (this._isMounted) {
                    this.setState({ fetching: false, ownUpdate: true });
                }
            }
        } finally {
            if (this._isMounted) {
                this.setState({ fetching: false, ownUpdate: true });
            }
        }
    };
    getFilteredItems = (data: IFilterData): IFilterData => {
        let filteredItems: IFilterData = {};
        Object.keys(data).forEach((key) => {
            if (data[key].item2 !== "") {
                filteredItems[key] = data[key];
            }
        });
        return filteredItems;
    };

    onSaveSpecialCondition(fields: CreditSummaryReviewFields): boolean {
        try {
            this.setState(
                { ownUpdate: true },
                () => {
                    return this.saveSpecialCondition(fields);
                }
            );
            return false;
        } catch (error) {
            return false;
        }
    }

    async saveSpecialCondition(
        fields: CreditSummaryReviewFields
    ): Promise<boolean> {
        try {
            this.setState({ submitting: true, ownUpdate: true });
            const payload: any = { ...this.props.item };
            payload.specialConditions = [...this.state.specialConditions];

            // console.log(payload);
            const response = await loanRequestService.updateLoanRequest(payload);
            if (response.status >= 200 && response.status <= 300) {
                toast.info(`Special Conditions saved successfully!`, {
                    autoClose: 6000,
                    type: toast.TYPE.DEFAULT,
                    hideProgressBar: false,
                });
            } else {
                toast.info(`Special Conditions couldn't be saved, please try again!`, {
                    autoClose: 6000,
                    type: toast.TYPE.DEFAULT,
                    hideProgressBar: false,
                });
            }
            this.setState({ submitting: false, ownUpdate: true });
            // this.resetFields();

        }
        catch (error) {
            if (error.response.status === 409) {
                toast.error(error.response.data.detail, {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            } else if (error.response.status === 400) {
                toast.error(error.response.data.errors[0], {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            }
        } finally {
            this.setState({ submitting: false, ownUpdate: true });
            return true;
        }
    }
    handleSpecialCondition = () => {
        const { specialConditions } = this.state;
        let len = specialConditions.length;

        // console.log(specialConditions);

        specialConditions.push({ id: "condition " + Number(len + 1), value: "" });



        this.setState({ specialConditions: specialConditions, ownUpdate: true });

        // console.log(signatoryOption);
    }


    handleSpecialConditionChange = (id, value) => {
        const specialConditions = [...this.state.specialConditions];
        // this.setState({flatPerApprovedLoan:flatPerApprovedLoan_, ownUpdate: true});

        specialConditions.every(option => {
            if (option.id == id) {

                option.value = value;
                // this.setState({ submitting: false, ownUpdate: true });
                return false;
            }
            // this.setState({flatPerApprovedLoan:flatPerApprovedLoan_, ownUpdate: true})
            // Make sure you return true. If you don't return a value, `every()` will stop.
            return true;
        });
        // console.log("getting object",validationOption.find(option => option.validationPricingOptionId == id).fee);
        //  console.log(signatoryOption);

        return specialConditions;
        // this.setState({validationOption: validationOption, ownUpdate: true});
    }

    handleOnExport = async (type: string) => { };

    render() {
        const {
            item,
            systemProcess,
            userRoles
        } = this.props;
        const {
            currentPage,
            pageSize,
            tableItems,
            totalSize,
            fetching,
            fetchingDetail,
            validated,
            errors,
            applicableRules
        } = this.state;

        console.log(item)
        // console.log(applicableRules)
        return (
            <div className="card">
                {/* {console.log(item)} */}
                <div className="card-header clear-fix">
                    {_.isEmpty(item) ? (
                        <Skeleton width={200} />
                    ) : (
                        <h6
                            className="card-title float-left"
                            style={{ marginBottom: "0px" }}
                        >
                            <b>Credit Summary Review</b>
                        </h6>
                    )}
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card no-b">
                            <div className="row">

                                <div className="col-md-12">
                                    <div className="card">
                                        <Accordion defaultActiveKey="0">
                                            <div>
                                                <Card>
                                                    <ContextAwareToggle
                                                        eventKey={`0`}
                                                        header={<React.Fragment>
                                                            <i
                                                                className="fa fa-info-circle"
                                                                aria-hidden="true"
                                                                style={{ color: "#878a8a" }}
                                                            ></i>{" "}
                                                            Applicant Details
                                                        </React.Fragment>}
                                                    ></ContextAwareToggle>
                                                    <Accordion.Collapse eventKey={`0`}>
                                                        <Card.Body>
                                                            <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                                <div className="row">
                                                                    {this.loanRequestDetailsPage(item)}
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </div>
                                        </Accordion>
                                    </div>
                                    <br />

                                    <Fragment>
                                        <Accordion defaultActiveKey="0">
                                            <div>
                                                <Card>
                                                    <ContextAwareToggle
                                                        eventKey={`0`}
                                                        header={<React.Fragment>
                                                            <i
                                                                className="fa fa-info-circle"
                                                                aria-hidden="true"
                                                                style={{ color: "#878a8a" }}
                                                            ></i>{" "}
                                                            Appraisal Actions
                                                        </React.Fragment>}
                                                    ></ContextAwareToggle>
                                                    <Accordion.Collapse eventKey={`0`}>
                                                        <Card.Body>
                                                            <div
                                                                className="table-responsive text-nowrap"
                                                                style={{
                                                                    height: "300px",
                                                                    overflowY: "scroll",
                                                                }}
                                                            >

                                                                {/* <h6 className="center">Appraisal Actions</h6> */}
                                                                <table className="table table-hover table-content table-sm table-striped mb-0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th
                                                                                scope="col"
                                                                                style={{
                                                                                    width: "5px",
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                }}
                                                                            >
                                                                                <strong>Stage</strong>
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                style={{
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                }}
                                                                            >
                                                                                <strong>Decision</strong>
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                style={{
                                                                                    // width: "5px",
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                }}
                                                                            >
                                                                                <strong>Comment</strong>
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                style={{
                                                                                    // width: "5px",
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                }}
                                                                            >
                                                                                <strong>User</strong>
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                style={{
                                                                                    // width: "5px",
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                }}
                                                                            >
                                                                                <strong>Date</strong>
                                                                            </th>
                                                                            {/* <th
                          scope="col"
                          style={{
                            // width: "5px",
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Action</strong>
                        </th> */}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {/* {console.log(item.appraisalActions)} */}
                                                                        {/* {recipients.length === 0 ? ( */}
                                                                        {item.appraisalActions.length === 0 ? (
                                                                            <React.Fragment>
                                                                                <tr>
                                                                                    No Decisions Made yet
                                                                                </tr>
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            item.appraisalActions.map((action) => (
                                                                                <React.Fragment key={generateGUID()}>
                                                                                    <tr>
                                                                                        <td
                                                                                            style={{
                                                                                                width: "5px",
                                                                                                paddingTop: "0.2rem",
                                                                                                paddingBottom: "0.2rem",
                                                                                                fontSize: "small",
                                                                                            }}
                                                                                        >
                                                                                            {(action.logType === "UserType" ?
                                                                                                action.itemLevel === Number(-1) ? "Detailed Data Entry" :
                                                                                                    action.itemLevel === Number(0) ? "Data Validation" :
                                                                                                        action.itemLevel === Number(100) ? "Document Generation" :
                                                                                                            action.itemLevel === Number(101) ? "Post Approval Document Collection" :
                                                                                                                action.itemLevel === Number(102) ? "Loan Disbursement Initiation" :
                                                                                                                    action.itemLevel === Number(103) ? "Loan Disbursement Approval" : systemProcess?.filter(x => x.id === Number(item?.financialGroup?.rules?.filter(x => x.level === action.itemLevel && x.approverType === "SystemProcess")[0]?.systemProcessID))[0]?.name
                                                                                                :
                                                                                                action.systemProcessID === Number(2) ? "Credit Policy" : action.systemProcessID === Number(5) ? "Credit Score Card" : systemProcess?.filter(x => x.id === Number(action.systemProcessID))[0]?.name)}
                                                                                        </td>
                                                                                        <td
                                                                                            style={{
                                                                                                paddingTop: "0.2rem",
                                                                                                paddingBottom: "0.2rem",
                                                                                                fontSize: "small",
                                                                                            }}
                                                                                        >
                                                                                            {action.action === "Submit" || action.action === "Processed" || action.action === "Approve" ? "Completed" : action.action}
                                                                                        </td>
                                                                                        <td
                                                                                            style={{
                                                                                                paddingTop: "0.2rem",
                                                                                                paddingBottom: "0.2rem",
                                                                                                fontSize: "small",
                                                                                            }}
                                                                                        >
                                                                                            {/* {action.comment === "Done with data validation" || action.comment === "Ok to proceed" || action.comment === "Done with data entry" ? "Completed" : action.comment} */}
                                                                                            {action.comment}
                                                                                        </td>
                                                                                        <td
                                                                                            style={{
                                                                                                paddingTop: "0.2rem",
                                                                                                paddingBottom: "0.2rem",
                                                                                                fontSize: "small",
                                                                                            }}
                                                                                        >
                                                                                            {action.logType === "SystemType" ? "System" : action?.user?.firstName}{" "}{action?.user?.lastName}
                                                                                        </td>
                                                                                        <td
                                                                                            style={{
                                                                                                paddingTop: "0.2rem",
                                                                                                paddingBottom: "0.2rem",
                                                                                                fontSize: "small",
                                                                                            }}
                                                                                        >
                                                                                            {textFormatService.formatTimeString(action.dateCreated)}
                                                                                        </td>
                                                                                    </tr>
                                                                                    {/* ))} */}
                                                                                </React.Fragment>
                                                                            ))
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </div>
                                        </Accordion>
                                    </Fragment>

                                    <CreditSummaryReviewForm
                                        initialValues={{ comment: "", decision: "", condition: this.state.specialConditions }}
                                        FormComponent={({
                                            fields: { comment, condition, decision },
                                            onChange,
                                            onReloadFields,
                                            onHandleSubmit,
                                        }) => (
                                            <><div className="card">
                                                <Accordion defaultActiveKey="0">
                                                    <div>
                                                        <Card>
                                                            <ContextAwareToggle
                                                                eventKey={`0`}
                                                                header={<React.Fragment>
                                                                    <i
                                                                        className="fa fa-info-circle"
                                                                        aria-hidden="true"
                                                                        style={{ color: "#878a8a" }}
                                                                    ></i>{" "}
                                                                    Special Conditions
                                                                </React.Fragment>}
                                                            ></ContextAwareToggle>
                                                            <Accordion.Collapse eventKey={`0`}>
                                                                <Card.Body>
                                                                    <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                                        <div className="row">
                                                                            <div className="col-md-10">
                                                                                <dl className="row">
                                                                                    {_.isEmpty(this.state.specialConditions) ? (
                                                                                        ""
                                                                                    ) :
                                                                                        (
                                                                                            this.state.specialConditions.map((condition, idx) => (
                                                                                                <FormTextAreaComponent
                                                                                                    key={idx}
                                                                                                    id={condition.id}
                                                                                                    type="text"
                                                                                                    name={condition.id}
                                                                                                    placeholder=""
                                                                                                    divClass={6}
                                                                                                    value={condition.value}
                                                                                                    required={false}
                                                                                                    validated={true}
                                                                                                    errors={{}}
                                                                                                    onChange={(id, value) => {
                                                                                                        onChange('condition', this.handleSpecialConditionChange(id, value));
                                                                                                        // this.handleValidationChange
                                                                                                    }}
                                                                                                    disabled={false} />
                                                                                            ))
                                                                                        )}</dl>

                                                                                {item.requestStatus == "Completed" || item.requestStatus == "Rejected" ? ("") : (
                                                                                    this.state.applicableRules.length == 0 ? ("") : (
                                                                                        <dl className="row">
                                                                                            <Button
                                                                                                className="mr-3"
                                                                                                size="sm"
                                                                                                variant="primary"
                                                                                                onClick={() => {
                                                                                                    this.handleSpecialCondition();
                                                                                                }}
                                                                                            > Add Special Condition
                                                                                            </Button>
                                                                                            <Button
                                                                                                className="mr-3"
                                                                                                size="sm"
                                                                                                variant="primary"
                                                                                                onClick={() => {
                                                                                                    this.onSaveSpecialCondition(
                                                                                                        { comment, condition, decision }
                                                                                                    );
                                                                                                }}

                                                                                            > Save Special Condition
                                                                                            </Button>
                                                                                        </dl>
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </div>
                                                </Accordion>
                                            </div>

                                                <div className="card mt-3">
                                                    <div className="card-header clear-fix">
                                                        <h6
                                                            className="float-left"
                                                            style={{ marginBottom: "0px" }}
                                                        >
                                                            <b>Loan Details - Group Members</b>
                                                        </h6>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            {/* <div className="col-md-12"> */}
                                                            {_.isEmpty(item) ? (
                                                                <Skeleton count={7} width={300} />
                                                            ) : (
                                                                item?.solidarityGroups?.map((groups) => (
                                                                    <React.Fragment>
                                                                        <button
                                                                            className="btn btn-sm btn-outline-primary my-2 mx-3"
                                                                            style={{ cursor: "pointer" }}
                                                                            type="button"
                                                                            // onClick={this.handleUploadDocument}
                                                                            onClick={(e) => { this.toggleShowValidateAmountModal(groups.id); }}
                                                                        >
                                                                            <React.Fragment>
                                                                                <i className="fas fa-users fa-lg mr-2" />{" "}
                                                                                {groups.solidarityGroupName}
                                                                            </React.Fragment>
                                                                        </button>
                                                                    </React.Fragment>
                                                                ))
                                                            )}
                                                            {/* </div> */}
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="card">
                                                    {/*  ha */}
                                                </div></>
                                        )}
                                    >

                                    </CreditSummaryReviewForm>
                                </div>
                                <ValidateAmountModal
                                    item={this.state.item}
                                    members={this.state.members}
                                    showPatchModal={this.state.showValidateAmount}
                                    togglePatchModal={this.toggleShowValidateAmountModal}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ViewAllGroupLoanDetails;
