import React from "react";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import BaseFormComponent, {
    IBaseFormState,
} from "../../../../components/BaseFormComponent";
import appraisalValidationService from "../../../../services/hybridAppraisal/appraisalValidationService";
import { ISelectItems } from "../../../../components/detailItemSelectBox";
import { ApproverType } from "../../../../enums/approverType";
import SelectInputComponent from "../../../../components/formSelectComponent";
import FormInputComponent from "../../../../components/formInputComponent";
import MultiSelectComponent from "../../../../components/formMultiSelectComponent";

interface EditLevelDetailsModalFields {
    levelName?: string;
    // approverType: string;
    // roleID?: string;
    // systemProcessID?: string;
    // systemProcessType?: string;
    // acceptanceScore?: string;
    // acceptanceString?: string;
    // userCount?: number;
    privilegeScreens?: string;
    approverPage: string;
}

class EditLevelDetailsModalForm extends BaseFormComponent<EditLevelDetailsModalFields> { }

export interface IEditLevelDetailsModalProps {
    data: any;
    userRoles: any;
    systemProcess: any;
    showEditLevelDetailsModal: boolean;
    toggleEditLevelDetailsModal: any;
    // levels: any;
}

export interface IEditLevelDetailsModalState {
    data: any;
    ownUpdate: boolean;
    levelName: string;
    privilegeScreens: any;
    approverPage: string;
}

class EditLevelDetailsModal extends React.Component<
    IEditLevelDetailsModalProps,
    IBaseFormState & IEditLevelDetailsModalState
> {
    constructor(props: IEditLevelDetailsModalProps) {
        super(props);
        this.state = {
            validated: false,
            submitting: false,
            errors: {},
            data: {},
            ownUpdate: false,
            levelName: "",
            privilegeScreens: [],
            approverPage: ""
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // console.log(nextProps.data)
        if (prevState.ownUpdate) {
            return {
                ownUpdate: false,
            };
        }
        return {
            data: nextProps.data,
            levelName: nextProps.data.levelName,
            privilegeScreens: nextProps.data.privilegeScreens.map(x => { return { label: x, value: x } }),
            approverPage: nextProps.data.approverPage ? "Yes" : "No"
        };
    }

    onFormSubmit(fields: EditLevelDetailsModalFields, onReloadFieldsCB: any): boolean {
        try {
            // 
            const { data } = this.state;
            // console.log(data);
            // data.approverType = fields.approverType;
            // data.roleID = fields.roleID;
            // data.systemProcessID = fields.systemProcessID;
            // data.acceptanceScore = fields.acceptanceScore;
            // data.acceptanceString = "";
            // data.roleName = (fields.roleID !== "")
            //     ? (this.props.userRoles.filter(item => item.id === Number(fields.roleID))[0].name) : ""
            // data.processName = (fields.systemProcessID !== "")
            //     ? (this.props.systemProcess.filter(item => item.id === Number(fields.systemProcessID))[0].name) : ""
            data.levelName = fields.levelName
            // data.userCount = Number(fields.systemProcessID) !== 0 ? 1 : fields.userCount;
            data.privilegeScreens = this.state.privilegeScreens
            data.approverPage = fields.approverPage
            this.setState({ data, ownUpdate: true }, () => {
                this.setState(
                    {
                        levelName: fields.levelName,
                        // errors: appraisalValidationService.validateFinancialGroupConfigForm(
                        //     fields, this.state.data.title === "Level"
                        // ),
                        ownUpdate: true
                    },
                    () => {
                        // console.log(this.state.errors)
                        // if (Object.keys(this.state.errors).length === 0) {
                        this.submit(data, onReloadFieldsCB);
                        // }
                    }
                );
            });
            this.setState({ validated: true, ownUpdate: true });
            return true;
        } catch (error) {
            return false;
        }
    }

    submit = async (data: any, onReloadFieldsCB: any) => {
        // console.log(data)
        this.props.toggleEditLevelDetailsModal(
            data.title,
            data.level,
            // data.combinationNo,
            // data.approverType,
            // Number(data.roleID),
            // Number(data.systemProcessID),
            // Number(data.acceptanceScore),
            // data.acceptanceString,
            // data.roleName,
            // data.processName,
            data.levelName,
            // data.userCount,
            data.privilegeScreens,
            data.approverPage,
            true
        );
        onReloadFieldsCB();
    };

    onChangeLevelName = (value) => {
        // this.setState({ levelName: value })
        let levelName = value

        return value
    }

    onChangePrivilegeScreens = (selected) => {
        this.setState({ privilegeScreens: selected, ownUpdate: true });
    };

    resetFields() {
        this.setState({
            validated: false,
            submitting: false,
            ownUpdate: true
        });
    }

    render() {
        var { systemProcess, showEditLevelDetailsModal, toggleEditLevelDetailsModal } = this.props;
        var { data, errors, submitting, validated } = this.state;

        // console.log(data)


        let privilegePageInputData: any[] = [
            "Data Validation",
            // "Special Conditions",
            "Document Management",
            "Document Review",
            "Financial Analysis",
            "Policy and Scoring",
            "Credit Analysis",
            "Credit Analysis (Internal)",
            "Credit Analysis (FCCB)",
            "Credit Analysis (CRC)",
            "Credit Analysis (BankOne)",
            "Corporate Analysis",
            "Disbursement"
        ].map(item => {
            return { label: item, value: item }
        })

        let approverPageTypeInputData: ISelectItems[] = [
            { name: "Yes", value: "Yes" },
            { name: "No", value: "No" },
        ];
        // let systemProcessTypeInputData: ISelectItems[] = [
        //     { name: "Validation", value: "Validation" },
        //     { name: "Report", value: "Report" },
        // ];

        return (
            <Modal
                size="lg"
                backdrop="static"
                show={showEditLevelDetailsModal}
                onHide={toggleEditLevelDetailsModal}
            >
                <Modal.Header>
                    <Modal.Title>
                        <h5>
                            <i className="fas fa-tasks mr-3"></i>
                            Add New {" "} {data.title}
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <EditLevelDetailsModalForm
                    initialValues={{
                        levelName: this.state.levelName,
                        // approverType: "",
                        // roleID: "",
                        // systemProcessID: "",
                        // systemProcessType: "",
                        // acceptanceScore: "",
                        // userCount: 0,
                        privilegeScreens: "",
                        approverPage: this.state.approverPage
                    }}
                    FormComponent={({
                        fields: {
                            levelName,
                            // approverType,
                            // roleID,
                            // systemProcessID,
                            // systemProcessType,
                            // acceptanceScore,
                            // userCount,
                            privilegeScreens,
                            approverPage
                        },
                        onChange,
                        onReloadFields,
                    }) => (
                        <React.Fragment>
                            <Modal.Body>
                                <div className="form-row">
                                    {/* {data.title === "Level" ? ( */}
                                    <FormInputComponent
                                        id="levelName"
                                        type="text"
                                        name="levelName"
                                        divClass={6}
                                        placeholder=""
                                        value={levelName}
                                        required={false}
                                        validated={false}
                                        errors={errors}
                                        onChange={async (id, value) => {
                                            await onChange(id, value)
                                            await onChange('levelName', this.onChangeLevelName(value))
                                        }}
                                    />
                                    <MultiSelectComponent
                                        id="privilegeViews"
                                        name="privilegeViews"
                                        divClass={6}
                                        value={this.state.privilegeScreens}
                                        options={privilegePageInputData}
                                        validated={validated}
                                        errors={errors}
                                        hideSelectedOptions={false}
                                        onChange={this.onChangePrivilegeScreens}
                                        allowSelectAll={true}
                                    />
                                    <SelectInputComponent
                                        id="approverPage"
                                        name="approverPage"
                                        divClass={6}
                                        value={approverPage}
                                        items={approverPageTypeInputData}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                    />

                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    size="sm"
                                    variant="primary"
                                    onClick={(e) => {
                                        this.onFormSubmit({
                                            levelName,
                                            privilegeScreens,
                                            approverPage
                                        }, onReloadFields);
                                    }}
                                    disabled={submitting}
                                >
                                    {submitting === false ? (
                                        <React.Fragment>
                                            <i className="fa fa-plus mr-3" /> Save
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <i className="fas fa-spin fa-circle-notch" />
                                        </React.Fragment>
                                    )}
                                </Button>
                                <Button
                                    size="sm"
                                    variant="outline-danger"
                                    onClick={() =>
                                        toggleEditLevelDetailsModal(
                                            data.title,
                                            data.level,
                                            // data.combinationNo,
                                            // data.approverType,
                                            // data.roleID,
                                            // data.systemProcessID,
                                            // data.acceptanceScore,
                                            // data.acceptanceString,
                                            // data.roleName,
                                            // data.processName,
                                            data.levelName,
                                            // data.userCount,
                                            data.privilegeScreens,
                                            data.approverPage,
                                            false
                                        )
                                    }
                                    disabled={submitting}
                                >
                                    <i className="fas fa-times mr-3" />
                                    Close
                                </Button>
                            </Modal.Footer>
                        </React.Fragment>
                    )}
                ></EditLevelDetailsModalForm >
            </Modal >
        );
    }
}

export default EditLevelDetailsModal;
