import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "./components/protectedRoute";
import Home from "./pages/home/home";
import Login from "./pages/authentication/login";
import Logout from "./pages/authentication/logout";
import ForgotPassword from "./pages/forgotPassword/forgotPassword";
import NotFound from "./pages/notFound";
import "./App.css";
// import "./styles/modal-video.scss";
import "react-toastify/dist/ReactToastify.css";
import Consent from "./pages/consent/consent";
import UserSetup from "./pages/setup/userSetup";
// 1. import `ChakraProvider` component
// import { ChakraProvider } from '@chakra-ui/react'

function App() {
  return (
    // <ChakraProvider>
    <HashRouter>
      <ToastContainer />
      <Switch>
        <ProtectedRoute path="/home" component={Home} />
        <ProtectedRoute path="/" exact={true} component={Home} />
        <Route path="/usersetup/:token" component={UserSetup} />
        <Route path="/login" component={Login} />
        <Route path="/consent" component={Consent} />
        <Route path="/logout" component={Logout} />
        <Route path="/forgotpassword" component={ForgotPassword} />
        <Route path="/not-found" component={NotFound} />
        <Redirect to="/not-found" />
      </Switch>
    </HashRouter>
    // </ChakraProvider>
  );
}

export default App;
