import React, { Fragment } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import DetailItem from "../../../components/detailItem";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import billingValidationService from "../../../services/billing/billingValidationService";
import recovaFeeConfigService from "../../../services/billing/recovaFeeConfigService";
import thirdPartyFeeConfigService from "../../../services/billing/thirdPartyFeeConfigService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

interface EditCentralFeeConfigurationFields {
  percentageAmountPerActiveLoan?: string;
  percentageAmountPerBadLoan?: string;
  percentageAmountPerLostLoan?: string;
  amountPerSuccessfulTrx?: string;
  minimumFeePerSuccessfulTrx?: string;
  maximumFeePerSuccessfulTrx?: string;
  debitedBankCommission?: string;
  sponsorBankCommission?: string;
  thirdParty?: string;
  appzonePercentageAmountPerActiveLoan?: string;
  appzonePercentageAmountPerLostLoan?: string;
  appzoneFlatAmountPerSuccessfulTrx?: string;
  appzoneMinimumFeePerSuccessfulTrx?: string;
  appzoneMaximumFeePerSuccessfulTrx?: string;
  thirdPartyPercentageAmountSwept?: string;
  thirdPartyFlatAmountPerSuccessfulTrx?: string;
  thirdPartyMinimumFeePerSuccessfulTrx?: string;
  thirdPartyMaximumFeePerSuccessfulTrx?: string;
}

class EditRecovaCentralFeeConfigurationForm extends BaseFormComponent<EditCentralFeeConfigurationFields> { }

interface IEditRecovaCentralFeeConfigurationProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}
interface IEditRecovaCentralFeeConfigurationState {
  ownUpdate: boolean;
}

class EditRecovaCentralFeeConfiguration extends React.Component<
  IEditRecovaCentralFeeConfigurationProps,
  IBaseFormState & IEditRecovaCentralFeeConfigurationState
> {
  constructor(props: IEditRecovaCentralFeeConfigurationProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      ownUpdate: false,
    };
  }

  static getDerivedStateFromProps(prevState) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    return null;
  }

  onRecovaFormSubmit(
    fields: EditCentralFeeConfigurationFields,
    onReloadFieldsCB: any
  ): boolean {
    try {
      this.setState(
        {
          errors:
            billingValidationService.validateEditRecovaCentralFeeConfigurationForm(fields),
          ownUpdate: true,
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.recovaSubmit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  onThirdPartyFormSubmit(
    fields: EditCentralFeeConfigurationFields,
    onReloadFieldsCB: any
  ): boolean {
    try {
      this.setState(
        {
          errors:
            billingValidationService.validateEditThirdPartyCentralFeeConfigurationForm(fields),
          ownUpdate: true,
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.thirdPartySubmit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  recovaSubmit = async (
    fields: EditCentralFeeConfigurationFields,
    onReloadFieldsCB: any
  ) => {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const payload = this.props.item;
      payload.percentageAmountPerActiveLoan = Number(fields.percentageAmountPerActiveLoan);
      payload.percentageAmountPerLostLoan = Number(fields.percentageAmountPerLostLoan);
      payload.percentageAmountPerBadLoan = Number(fields.percentageAmountPerBadLoan);
      payload.amountPerSuccessfulTrx = Number(fields.amountPerSuccessfulTrx);
      payload.minimumFeePerSuccessfulTrx = Number(fields.minimumFeePerSuccessfulTrx);
      payload.maximumFeePerSuccessfulTrx = Number(fields.maximumFeePerSuccessfulTrx);
      payload.debitedBankCommission = Number(fields.debitedBankCommission);
      payload.sponsorBankCommission = Number(fields.sponsorBankCommission);

      const response = await recovaFeeConfigService.updateRecovaFeeConfig(payload.feeConfigurationId, payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit Recova Central Fee Configuration",
          description: `Edited details of Recova Central Fee Configuration ${payload.institutionCategory} (${payload.serviceOffering})`,
        });
        toast.info(
          `Central Fee Configuration for ${payload.institutionCategory} (${payload.serviceOffering}) Edited successfully!`,
          {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          }
        );
      }
      onReloadFieldsCB();
      this.setState({ submitting: false, ownUpdate: true }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
    } finally {
      this.setState({ submitting: false, ownUpdate: true });
    }
  };

  thirdPartySubmit = async (
    fields: EditCentralFeeConfigurationFields,
    onReloadFieldsCB: any
  ) => {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const payload = this.props.item;
      payload.thirdParty = payload.thirdParty;
      payload.appzonePercentageAmountPerActiveLoan = Number(fields.appzonePercentageAmountPerActiveLoan);
      payload.appzonePercentageAmountPerLostLoan = Number(fields.appzonePercentageAmountPerLostLoan);
      payload.appzoneFlatAmountPerSuccessfulTrx = Number(fields.appzoneFlatAmountPerSuccessfulTrx);
      payload.appzoneMinimumFeePerSuccessfulTrx = Number(fields.appzoneMinimumFeePerSuccessfulTrx);
      payload.appzoneMaximumFeePerSuccessfulTrx = Number(fields.appzoneMaximumFeePerSuccessfulTrx);
      payload.thirdPartyPercentageAmountSwept = Number(fields.thirdPartyPercentageAmountSwept);
      payload.thirdPartyFlatAmountPerSuccessfulTrx = Number(fields.thirdPartyFlatAmountPerSuccessfulTrx);
      payload.thirdPartyMinimumFeePerSuccessfulTrx = Number(fields.thirdPartyMinimumFeePerSuccessfulTrx);
      payload.thirdPartyMaximumFeePerSuccessfulTrx = Number(fields.thirdPartyMaximumFeePerSuccessfulTrx);

      const response = await thirdPartyFeeConfigService.updateThirdPartyFeeConfig(payload.thirdPartyFeeConfigurationId, payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit ThirdParty Central Fee Configuration",
          description: `Edited details of ThirdParty Central Fee Configuration ${payload.institutionCategory} (${payload.serviceOffering})`,
        });
        toast.info(
          `Central Fee Configuration for ${payload.institutionCategory} (${payload.serviceOffering}) Edited successfully!`,
          {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          }
        );
      }
      onReloadFieldsCB();
      this.setState({ submitting: false, ownUpdate: true }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
    } finally {
      this.setState({ submitting: false, ownUpdate: true });
    }
  };

  render() {
    const { item, showEditModal, toggleEditModal } = this.props;
    const {
      validated,
      submitting,
      errors,
    } = this.state;

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        {_.isEmpty(item) ? (
          <Skeleton width={500} />
        ) : (
          <Fragment>
            <Modal.Header>
              <Modal.Title>
                <h5>
                  <i className="fas fa-tools fa-sm cyan-text mr-3" />
                  Edit Central Fee Configuration -{" "}
                  <span className="font-weight-bold">{item.institutionCategory} ({item.serviceOffering})</span>
                </h5>
              </Modal.Title>
            </Modal.Header>
            <EditRecovaCentralFeeConfigurationForm
              initialValues={{
                percentageAmountPerActiveLoan: item?.percentageAmountPerActiveLoan,
                percentageAmountPerLostLoan: item?.percentageAmountPerLostLoan,
                percentageAmountPerBadLoan: item?.percentageAmountPerBadLoan,
                amountPerSuccessfulTrx: item?.amountPerSuccessfulTrx,
                minimumFeePerSuccessfulTrx: item?.minimumFeePerSuccessfulTrx,
                maximumFeePerSuccessfulTrx: item?.maximumFeePerSuccessfulTrx,
                debitedBankCommission: item?.debitedBankCommission,
                sponsorBankCommission: item?.sponsorBankCommission,
                thirdParty: item?.thirdParty,
                appzonePercentageAmountPerActiveLoan: item?.appzonePercentageAmountPerActiveLoan,
                appzonePercentageAmountPerLostLoan: item?.appzonePercentageAmountPerLostLoan,
                appzoneFlatAmountPerSuccessfulTrx: item?.appzoneFlatAmountPerSuccessfulTrx,
                appzoneMinimumFeePerSuccessfulTrx: item?.appzoneMinimumFeePerSuccessfulTrx,
                appzoneMaximumFeePerSuccessfulTrx: item?.appzoneMaximumFeePerSuccessfulTrx,
                thirdPartyPercentageAmountSwept: item?.thirdPartyPercentageAmountSwept,
                thirdPartyFlatAmountPerSuccessfulTrx: item?.thirdPartyFlatAmountPerSuccessfulTrx,
                thirdPartyMinimumFeePerSuccessfulTrx: item?.thirdPartyMinimumFeePerSuccessfulTrx,
                thirdPartyMaximumFeePerSuccessfulTrx: item?.thirdPartyMaximumFeePerSuccessfulTrx
              }}
              FormComponent={({
                fields: {
                  percentageAmountPerActiveLoan,
                  percentageAmountPerLostLoan,
                  percentageAmountPerBadLoan,
                  amountPerSuccessfulTrx,
                  minimumFeePerSuccessfulTrx,
                  maximumFeePerSuccessfulTrx,
                  debitedBankCommission,
                  sponsorBankCommission,
                  thirdParty,
                  appzonePercentageAmountPerActiveLoan,
                  appzonePercentageAmountPerLostLoan,
                  appzoneFlatAmountPerSuccessfulTrx,
                  appzoneMinimumFeePerSuccessfulTrx,
                  appzoneMaximumFeePerSuccessfulTrx,
                  thirdPartyPercentageAmountSwept,
                  thirdPartyFlatAmountPerSuccessfulTrx,
                  thirdPartyMinimumFeePerSuccessfulTrx,
                  thirdPartyMaximumFeePerSuccessfulTrx
                },
                onChange,
                onReloadFields,
                onHandleSubmit,
              }) => (
                <React.Fragment>
                  {(item.name === 'Recova') ? (
                    <Fragment>
                      <Modal.Body>
                        <div className="card">
                          <div className="card-header clear-fix">
                            <h6
                              className="card-title float-left"
                              style={{ marginBottom: "0px" }}
                            >
                              Fee
                            </h6>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-6">
                                <dl className="row">
                                  <DetailItem
                                    label="Active Loan Percentage"
                                    labelSize={8}
                                    valueSize={4}
                                    value={
                                      <DetailItemInputComponent
                                        id="percentageAmountPerActiveLoan"
                                        type="text"
                                        name="percentageAmountPerActiveLoan"
                                        placeholder=""
                                        value={percentageAmountPerActiveLoan}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    }
                                  />
                                  <DetailItem
                                    label="Lost Loan Percentage"
                                    labelSize={8}
                                    valueSize={4}
                                    value={
                                      <DetailItemInputComponent
                                        id="percentageAmountPerLostLoan"
                                        type="text"
                                        name="percentageAmountPerLostLoan"
                                        placeholder=""
                                        value={percentageAmountPerLostLoan}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    }
                                  />
                                  <DetailItem
                                    label="Bad Loan Percentage"
                                    labelSize={8}
                                    valueSize={4}
                                    value={
                                      <DetailItemInputComponent
                                        id="percentageAmountPerBadLoan"
                                        type="text"
                                        name="percentageAmountPerBadLoan"
                                        placeholder=""
                                        value={percentageAmountPerBadLoan}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    }
                                  />
                                </dl>
                              </div>
                              <div className="col-md-6">
                                <dl className="row">
                                  <DetailItem
                                    label="Amount Per Successful Transaction"
                                    labelSize={8}
                                    valueSize={4}
                                    value={
                                      <DetailItemInputComponent
                                        id="amountPerSuccessfulTrx"
                                        type="text"
                                        name="amountPerSuccessfulTrx"
                                        placeholder=""
                                        value={amountPerSuccessfulTrx}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    }
                                  />
                                  <DetailItem
                                    label="Minimum Fee Per Successful Transaction"
                                    labelSize={8}
                                    valueSize={4}
                                    value={
                                      <DetailItemInputComponent
                                        id="minimumFeePerSuccessfulTrx"
                                        type="text"
                                        name="minimumFeePerSuccessfulTrx"
                                        placeholder=""
                                        value={minimumFeePerSuccessfulTrx}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    }
                                  />
                                  <DetailItem
                                    label="Maximum Fee Per Successful Transaction"
                                    labelSize={8}
                                    valueSize={4}
                                    value={
                                      <DetailItemInputComponent
                                        id="maximumFeePerSuccessfulTrx"
                                        type="text"
                                        name="maximumFeePerSuccessfulTrx"
                                        placeholder=""
                                        value={maximumFeePerSuccessfulTrx}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    }
                                  />
                                </dl>
                              </div>
                            </div>
                          </div>
                          <div className="card-header clear-fix">
                            <h6
                              className="card-title float-left"
                              style={{ marginBottom: "0px" }}
                            >
                              Commission
                            </h6>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-6">
                                <dl className="row">
                                  <DetailItem
                                    label="Debited Bank Commission"
                                    labelSize={8}
                                    valueSize={4}
                                    value={
                                      <DetailItemInputComponent
                                        id="debitedBankCommission"
                                        type="text"
                                        name="debitedBankCommission"
                                        placeholder=""
                                        value={debitedBankCommission}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    }
                                  />
                                </dl>
                              </div>
                              <div className="col-md-6">
                                <dl className="row">
                                  {item.institutionCategory == 'Commercial Bank' ? (
                                    <DetailItem
                                      label="Sponsor Bank Commission"
                                      labelSize={8}
                                      valueSize={4}
                                      value={
                                        <DetailItemInputComponent
                                          id="sponsorBankCommission"
                                          type="text"
                                          name="sponsorBankCommission"
                                          placeholder=""
                                          value={sponsorBankCommission}
                                          required={true}
                                          validated={validated}
                                          errors={errors}
                                          onChange={onChange}
                                        />
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                </dl>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          size="sm"
                          variant="secondary"
                          onClick={(e) => {
                            onHandleSubmit(
                              e,
                              ActionTypes.UPDATE,
                              "Recova Central Fee Configuration",
                              () => {
                                this.onRecovaFormSubmit(
                                  {
                                    percentageAmountPerActiveLoan,
                                    percentageAmountPerLostLoan,
                                    percentageAmountPerBadLoan,
                                    amountPerSuccessfulTrx,
                                    minimumFeePerSuccessfulTrx,
                                    maximumFeePerSuccessfulTrx,
                                    debitedBankCommission,
                                    sponsorBankCommission,
                                  },
                                  onReloadFields
                                );
                              }
                            );
                          }}
                          disabled={submitting}
                        >
                          {submitting === false ? (
                            <React.Fragment>
                              <i className="fas fa-lg fa-save mr-3" /> Save
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <i className="fas fa-spin fa-circle-notch" />
                            </React.Fragment>
                          )}
                        </Button>
                        <Button
                          size="sm"
                          variant="outline-danger"
                          onClick={() => toggleEditModal(false, this.props.item)}
                          disabled={submitting}
                        >
                          <i className="fas fa-times mr-3" />
                          Close
                        </Button>
                      </Modal.Footer>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Modal.Body>
                        <div className="card">
                          <div className="card-header clear-fix">
                            <h6
                              className="card-title float-left"
                              style={{ marginBottom: "0px" }}
                            >
                              AppZone Fee
                            </h6>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-6">
                                <dl className="row">
                                  <DetailItem
                                    label="ThirdParty"
                                    labelSize={7}
                                    valueSize={5}
                                    value={thirdParty}
                                  />
                                  <DetailItem
                                    label="Active Loan Percentage"
                                    labelSize={7}
                                    valueSize={5}
                                    value={
                                      <DetailItemInputComponent
                                        id="appzonePercentageAmountPerActiveLoan"
                                        type="text"
                                        name="appzonePercentageAmountPerActiveLoan"
                                        placeholder=""
                                        value={appzonePercentageAmountPerActiveLoan}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    }
                                  />
                                  <DetailItem
                                    label="Lost Loan Percentage"
                                    labelSize={7}
                                    valueSize={5}
                                    value={
                                      <DetailItemInputComponent
                                        id="appzonePercentageAmountPerLostLoan"
                                        type="text"
                                        name="appzonePercentageAmountPerLostLoan"
                                        placeholder=""
                                        value={appzonePercentageAmountPerLostLoan}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    }
                                  />
                                </dl>
                              </div>
                              <div className="col-md-6">
                                <dl className="row">
                                  <DetailItem
                                    label="Flat Amount Per Successful Transaction"
                                    labelSize={7}
                                    valueSize={5}
                                    value={
                                      <DetailItemInputComponent
                                        id="appzoneFlatAmountPerSuccessfulTrx"
                                        type="text"
                                        name="appzoneFlatAmountPerSuccessfulTrx"
                                        placeholder=""
                                        value={appzoneFlatAmountPerSuccessfulTrx}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    }
                                  />
                                  <DetailItem
                                    label="Minimum Fee Per Successful Transaction"
                                    labelSize={7}
                                    valueSize={5}
                                    value={
                                      <DetailItemInputComponent
                                        id="appzoneMinimumFeePerSuccessfulTrx"
                                        type="text"
                                        name="appzoneMinimumFeePerSuccessfulTrx"
                                        placeholder=""
                                        value={appzoneMinimumFeePerSuccessfulTrx}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    }
                                  />
                                  <DetailItem
                                    label="Maximum Fee Per Successful Transaction"
                                    labelSize={7}
                                    valueSize={5}
                                    value={
                                      <DetailItemInputComponent
                                        id="appzoneMaximumFeePerSuccessfulTrx"
                                        type="text"
                                        name="appzoneMaximumFeePerSuccessfulTrx"
                                        placeholder=""
                                        value={appzoneMaximumFeePerSuccessfulTrx}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    }
                                  />
                                </dl>
                              </div>
                            </div>
                          </div>
                          <div className="card-header clear-fix">
                            <h6
                              className="card-title float-left"
                              style={{ marginBottom: "0px" }}
                            >
                              ThirdParty Cost
                            </h6>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-6">
                                <dl className="row">
                                  <DetailItem
                                    label="Amount Swept Percentage"
                                    labelSize={7}
                                    valueSize={5}
                                    value={
                                      <DetailItemInputComponent
                                        id="thirdPartyPercentageAmountSwept"
                                        type="text"
                                        name="thirdPartyPercentageAmountSwept"
                                        placeholder=""
                                        value={thirdPartyPercentageAmountSwept}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    }
                                  />
                                  <DetailItem
                                    label="Flat Amount Per Successful Transaction"
                                    labelSize={7}
                                    valueSize={5}
                                    value={
                                      <DetailItemInputComponent
                                        id="thirdPartyFlatAmountPerSuccessfulTrx"
                                        type="text"
                                        name="thirdPartyFlatAmountPerSuccessfulTrx"
                                        placeholder=""
                                        value={thirdPartyFlatAmountPerSuccessfulTrx}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    }
                                  />
                                </dl>
                              </div>
                              <div className="col-md-6">
                                <dl className="row">
                                  <DetailItem
                                    label="Minimum Fee Per Successful Transaction"
                                    labelSize={7}
                                    valueSize={5}
                                    value={
                                      <DetailItemInputComponent
                                        id="thirdPartyMinimumFeePerSuccessfulTrx"
                                        type="text"
                                        name="thirdPartyMinimumFeePerSuccessfulTrx"
                                        placeholder=""
                                        value={thirdPartyMinimumFeePerSuccessfulTrx}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    }
                                  />
                                  <DetailItem
                                    label="Maximum Fee Per Successful Transaction"
                                    labelSize={7}
                                    valueSize={5}
                                    value={
                                      <DetailItemInputComponent
                                        id="thirdPartyMaximumFeePerSuccessfulTrx"
                                        type="text"
                                        name="thirdPartyMaximumFeePerSuccessfulTrx"
                                        placeholder=""
                                        value={thirdPartyMaximumFeePerSuccessfulTrx}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    }
                                  />
                                </dl>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          size="sm"
                          variant="secondary"
                          onClick={(e) => {
                            onHandleSubmit(
                              e,
                              ActionTypes.UPDATE,
                              "Third Party Central Fee Configuration",
                              () => {
                                this.onThirdPartyFormSubmit(
                                  {
                                    thirdParty,
                                    appzonePercentageAmountPerActiveLoan,
                                    appzonePercentageAmountPerLostLoan,
                                    appzoneFlatAmountPerSuccessfulTrx,
                                    appzoneMinimumFeePerSuccessfulTrx,
                                    appzoneMaximumFeePerSuccessfulTrx,
                                    thirdPartyPercentageAmountSwept,
                                    thirdPartyFlatAmountPerSuccessfulTrx,
                                    thirdPartyMinimumFeePerSuccessfulTrx,
                                    thirdPartyMaximumFeePerSuccessfulTrx,

                                  },
                                  onReloadFields
                                );
                              }
                            );
                          }}
                          disabled={submitting}
                        >
                          {submitting === false ? (
                            <React.Fragment>
                              <i className="fas fa-lg fa-save mr-3" /> Save
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <i className="fas fa-spin fa-circle-notch" />
                            </React.Fragment>
                          )}
                        </Button>
                        <Button
                          size="sm"
                          variant="outline-danger"
                          onClick={() => toggleEditModal(false, this.props.item)}
                          disabled={submitting}
                        >
                          <i className="fas fa-times mr-3" />
                          Close
                        </Button>
                      </Modal.Footer>
                    </Fragment>
                  )}
                </React.Fragment>
              )}
            ></EditRecovaCentralFeeConfigurationForm>
          </Fragment>
        )}
      </Modal>
    );
  }
}

export default EditRecovaCentralFeeConfiguration;
