import http from "../httpService";
import { getCurrentUserSession } from "../auth/authService";

const linkingBaseAddress = process.env.REACT_APP_LINKING_BASEADDRESS + `api/`;
export function retrieveByBvn(bvn: string) {
  var url = linkingBaseAddress + `BvnAccountMap/RetrieveByBvn/${bvn}`;
  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveByBvnForInstitution(
  bvn: string,
  institutionCode: string
) {
  var url =
    linkingBaseAddress +
    `BvnAccountMap/RetrieveByBvn/${bvn}/${institutionCode}`;
  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export default {
  retrieveByBvn,
  retrieveByBvnForInstitution,
};
