import React, { Fragment, Component, useContext } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../../../../components/detailItem";
import { Accordion, Card } from "react-bootstrap";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import { generateGUID } from "../../../../../../services/utility/textFormatService";
import textFormatService from "../../../../../../services/utility/textFormatService";

export function ContextAwareToggle({ eventKey, level }) {
    const currentEventKey = useContext(AccordionContext);
    const toggleOnClick = useAccordionToggle(eventKey);
    const isCurrentEventKey = currentEventKey === eventKey;
    const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
    return (
        <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
            <b>Asset/Property {level}</b>
            <i
                className={angleType}
                aria-hidden="true"
                style={{ float: "right" }}
            ></i>
        </Card.Header>
    );
}

interface PropertyDetailsProps {
    values: any;
    handleChange: any;
    handleBlur: any;
}

interface PropertyDetailsState {
    item: any;
}

class PropertyDetails extends Component<PropertyDetailsProps, PropertyDetailsState> {
    constructor(props: PropertyDetailsProps) {
        super(props);
        this.state = {
            item: {},
        };
    }

    static getDerivedStateFromProps(nextProps: PropertyDetailsProps, prevState) {
        return {
            item: nextProps.values.assets,
        };
    }

    render() {
        const { values } = this.props;
        // console.log("View values :: ", values)

        return (
            <Fragment>
                <div className="card">
                    <div className="card-header clear-fix">
                        {_.isEmpty(values) ? (
                            <Skeleton width={200} />
                        ) : (
                            <h6
                                className="card-title float-left"
                                style={{ marginBottom: "0px" }}
                            >
                                <b>Property Details</b>
                            </h6>
                        )}
                    </div>

                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Accordion.Collapse eventKey={`0`}>
                                <Card.Body>
                                    <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {_.isEmpty(values.assets) ? (
                                                    <div style={{ textAlign: "center", color: "#FE825E" }}>
                                                        No Property has been assigned to Loan Request
                                                    </div>
                                                ) : (
                                                    <div>
                                                        {values?.assets?.properties?.map((item, idx) => (
                                                            (item.propertyType === null || item.propertyType === "" ? ("") : (
                                                                <Fragment>
                                                                    <div className="card mt-3" key={generateGUID()}>
                                                                        <div className="card-header clear-fix">
                                                                            {_.isEmpty(values) ? (
                                                                                <Skeleton width={200} />
                                                                            ) : (
                                                                                <h6
                                                                                    className="card-title float-left"
                                                                                    style={{ marginBottom: "0px" }}
                                                                                >
                                                                                    <b>Property Details</b>
                                                                                </h6>
                                                                            )}
                                                                        </div>

                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    {_.isEmpty(values) ? (
                                                                                        <Skeleton count={3} width={300} />
                                                                                    ) : (
                                                                                        <dl className="row">
                                                                                            <DetailItem
                                                                                                label="Property Type"
                                                                                                labelSize={7}
                                                                                                valueSize={5}
                                                                                                value={item.propertyType}
                                                                                            />
                                                                                            <DetailItem
                                                                                                label="Property Address"
                                                                                                labelSize={7}
                                                                                                valueSize={5}
                                                                                                value={item.address}
                                                                                            />
                                                                                            <DetailItem
                                                                                                label="Property Country"
                                                                                                labelSize={7}
                                                                                                valueSize={5}
                                                                                                value={item.country}
                                                                                            />
                                                                                            <DetailItem
                                                                                                label="Market Value"
                                                                                                labelSize={7}
                                                                                                valueSize={5}
                                                                                                value={textFormatService.formatCurrency(Number(item.marketValue), 2)}
                                                                                            />
                                                                                            <DetailItem
                                                                                                label="Appraiser"
                                                                                                labelSize={7}
                                                                                                valueSize={5}
                                                                                                value={item.appraiser}
                                                                                            />
                                                                                            <DetailItem
                                                                                                label="Location Category"
                                                                                                labelSize={7}
                                                                                                valueSize={5}
                                                                                                value={item.locationTier}
                                                                                            />
                                                                                        </dl>
                                                                                    )}
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    {_.isEmpty(values) ? (
                                                                                        <Skeleton count={3} width={300} />
                                                                                    ) : (
                                                                                        <dl className="row">
                                                                                            <DetailItem
                                                                                                label="Property Description"
                                                                                                labelSize={7}
                                                                                                valueSize={5}
                                                                                                value={item.propertyDescription}
                                                                                            />
                                                                                            <DetailItem
                                                                                                label="Property City/Town"
                                                                                                labelSize={7}
                                                                                                valueSize={5}
                                                                                                value={item.city}
                                                                                            />
                                                                                            <DetailItem
                                                                                                label="Property State/Region"
                                                                                                labelSize={7}
                                                                                                valueSize={5}
                                                                                                value={item.state}
                                                                                            />
                                                                                            <DetailItem
                                                                                                label="Appraised Value"
                                                                                                labelSize={7}
                                                                                                valueSize={5}
                                                                                                value={textFormatService.formatCurrency(Number(item.appraisedValue), 2)}
                                                                                            />
                                                                                            <DetailItem
                                                                                                label="Appraisal Date"
                                                                                                labelSize={7}
                                                                                                valueSize={5}
                                                                                                value={item?.appraisalDate?.split("T")[0] || "N/A"}
                                                                                            />
                                                                                        </dl>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            ))
                                                        ))}
                                                        {values?.assets?.properties?.map((item, idx) => (
                                                            (item.loanAmount === null || item.loanAmount === "" || item.loanAmount === "0" ? ("") : (
                                                                <Fragment>
                                                                    <div className="card mt-3" key={generateGUID()}>
                                                                        <div className="card-header clear-fix">
                                                                            {_.isEmpty(values) ? (
                                                                                <Skeleton width={200} />
                                                                            ) : (
                                                                                <h6
                                                                                    className="card-title float-left"
                                                                                    style={{ marginBottom: "0px" }}
                                                                                >
                                                                                    <b>Loan Summary</b>
                                                                                </h6>
                                                                            )}
                                                                        </div>

                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    {_.isEmpty(values) ? (
                                                                                        <Skeleton count={3} width={300} />
                                                                                    ) : (
                                                                                        <dl className="row">
                                                                                            <DetailItem
                                                                                                label="Asset Cost"
                                                                                                labelSize={7}
                                                                                                valueSize={5}
                                                                                                value={textFormatService.formatCurrency(Number(item.cost) / 100, 2)}
                                                                                            />
                                                                                            <DetailItem
                                                                                                label="Loan Amount"
                                                                                                labelSize={7}
                                                                                                valueSize={5}
                                                                                                value={textFormatService.formatCurrency(Number(item.loanAmount) / 100, 2)}
                                                                                            />
                                                                                        </dl>
                                                                                    )}
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    {_.isEmpty(values) ? (
                                                                                        <Skeleton count={3} width={300} />
                                                                                    ) : (
                                                                                        <dl className="row">
                                                                                            <DetailItem
                                                                                                label="Equity Payment"
                                                                                                labelSize={7}
                                                                                                valueSize={5}
                                                                                                value={textFormatService.formatCurrency(Number(item.equityPayment), 2)}
                                                                                            />
                                                                                            <DetailItem
                                                                                                label="Loan to Value"
                                                                                                labelSize={7}
                                                                                                valueSize={5}
                                                                                                value={item.loanToValue}
                                                                                            />
                                                                                        </dl>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            ))
                                                        ))}
                                                    </div>


                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>

                </div>
            </Fragment>
        );
    }
}

export default PropertyDetails;