import React, { Fragment } from "react";
import { toast } from "react-toastify";
import { Modal, Button, Accordion, Card } from "react-bootstrap";
import _, { isEmpty } from "lodash";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
    IBaseFormState,
} from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import workFlowConfigService from "../../../services/hybridAppraisal/workFlowConfigService";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../components/detailItem";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import { IMultiSelectItems } from "../../../components/formMultiSelectComponent";
import EditMandatoryFieldConfig from "./editMandatoryFieldConfig";
import { ContextAwareToggle } from "../appraisalPlan/appraisalPlanDetail";
import supplierService from "../../../services/hybridAppraisal/supplierService";
import SelectInputComponent, { ISelectItems } from "../../../components/formSelectComponent";
import DetailItemSelectBox from "../../../components/detailItemSelectBox";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../../services/auth/authService";
import commercialBankService from "../../../services/sweep/commercialBankService";
import nameEnquiryService from "../../../services/handshake/nameEnquiryService";

interface EditSupplierDetailsFields {
    name: string;
    bankName: string;
    accountNumber: string;
    phoneNumber: string;
    address: string;
}

class EditSupplierDetailsForm extends BaseFormComponent<EditSupplierDetailsFields> { }

interface IEditSupplierDetailsProps {
    item: any;
    showEditModal: boolean;
    toggleEditModal: any;
}

interface IEditSupplierDetailsState {
    ownUpdate: boolean;
    fetchingRequiredItems: boolean;
    selectedWorkFlowSteps: any;
    selectedDataEntryTabs: any;
    dataEntrySections: any;
    showMandatoryFieldModal: boolean;
    configuredMandatoryFields: boolean;
    bankCode: string;
    accountNumber: string;
    accountName: string;
    commercialBanks: any;
}

class EditSupplierDetails extends React.Component<
    IEditSupplierDetailsProps,
    IBaseFormState & IEditSupplierDetailsState
> {
    _isMounted = false;
    constructor(props: IEditSupplierDetailsProps) {
        super(props);
        this.state = {
            validated: false,
            submitting: false,
            errors: {},
            ownUpdate: false,
            fetchingRequiredItems: false,
            selectedWorkFlowSteps: [],
            selectedDataEntryTabs: [],
            dataEntrySections: [],
            showMandatoryFieldModal: false,
            configuredMandatoryFields: false,
            bankCode: "",
            accountNumber: "",
            accountName: "",
            commercialBanks: []
        };
    }

    static getDerivedStateFromProps(
        nextProps: IEditSupplierDetailsProps,
        prevState: IEditSupplierDetailsState
    ) {
        if (prevState.ownUpdate) {
            return {
                ownUpdate: false,
            };
        }
        if (_.isEmpty(nextProps.item)) {
            return null;
        }
        return {
            item: nextProps.item
        };
    }

    onFormSubmit(fields: EditSupplierDetailsFields, onReloadFieldsCB: any): boolean {
        try {
            this.setState(
                { ownUpdate: true }, () => {
                    if (Object.keys(this.state.errors).length === 0) {
                        return this.submit(fields, onReloadFieldsCB);
                    }
                });
            this.setState({ validated: true, ownUpdate: true });
            return false;
        } catch (error) {
            return false;
        }
    }

    submit = async (
        fields: EditSupplierDetailsFields,
        onReloadFieldsCB: any
    ) => {
        try {
            this.setState({ submitting: true, ownUpdate: true });
            const { selectedWorkFlowSteps, selectedDataEntryTabs } = this.state;
            const { dataEntrySections } = this.state;
            const payload = { ...this.props.item };

            const decodedToken = decodeToken(getCurrentUserSession().token || "");

            payload.institutionCode = decodedToken?.["InstitutionCode"]
            payload.name = fields.name;
            payload.bankName = this.state.commercialBanks.filter(x => x.cbnCode === fields.bankName)[0].institutionName;
            payload.accountNumber = fields.accountNumber;
            payload.phoneNumber = fields.phoneNumber;
            payload.address = fields.address;

            const response = await supplierService.updateSupplier(payload);
            if (response.status >= 200 && response.status <= 300) {
                await activityTrailService.saveActivityTrail({
                    actionType: "Edit Supplier Details",
                    description: `Edited details for Supplier ${fields.name}`,
                });
                toast.info(`Supplier ${fields.name} Details Edited successfully!`, {
                    autoClose: 6000,
                    type: toast.TYPE.DEFAULT,
                    hideProgressBar: false,
                });
            }
            onReloadFieldsCB();
            this.setState({ submitting: false, ownUpdate: true }, () =>
                this.props.toggleEditModal(true, this.props.item)
            );
        } catch (error) {
            toast.error(error.response.data, {
                autoClose: 6000,
                type: toast.TYPE.ERROR,
                hideProgressBar: false,
            });
            this.setState({ submitting: false, ownUpdate: true });
        } finally {
            return true;
        }
    }

    async componentDidMount() {
        this._isMounted = true

        var commercialBanks = await commercialBankService.retrieveAll();
        var commercialBanksDetails = commercialBanks.data;

        this.setState({ commercialBanks: commercialBanksDetails })
    }

    fetchAccountName = async (value, channel) => {
        console.log("Channel: ", channel + " value: ", value)
        let { bankCode, accountNumber } = this.state;
        if (channel === "bankName") {
            bankCode = value
        }

        else if (channel === "accountNumber") {
            accountNumber = value
        }

        this.setState({ bankCode, accountNumber }, async () => {
            if (this.state.bankCode != "" && this.state.accountNumber != "") {
                var accountName = await nameEnquiryService.nameEnquiry(this.state.accountNumber, this.state.bankCode)
                this.setState({ accountName: accountName.data })
            }
        })
    }

    resetFields() {
        this.setState({
            submitting: false,
            ownUpdate: true,
            selectedWorkFlowSteps: [],
            selectedDataEntryTabs: [],
            dataEntrySections: []
        });
    }

    resetAllFields() {
        this.setState({
            submitting: false,
            ownUpdate: true
        })
    }

    render() {
        const { item, showEditModal, toggleEditModal } = this.props;
        const {
            validated,
            submitting,
            errors,
            selectedWorkFlowSteps,
            selectedDataEntryTabs,
            dataEntrySections,
            configuredMandatoryFields,
            commercialBanks
        } = this.state;

        let workflowStepsInputData: IMultiSelectItems[] = [
            { label: "Data Validation", value: "DataValidation" },
            { label: "Document Generation", value: "DocumentGeneration" },
            { label: "Document Collection", value: "DocumentCollection" },
            { label: "Pre Disbursement", value: "PreDisbursement" },
        ];

        const supplierBankNameInputType: ISelectItems[] = ["Access", "Citibank", "Ecobank", "Fidelity", "FirstBank", "GTB", "Heritage",
            "Keystone", "Polaris", "Providus", "StanbicIBTC", "StandardChattered", "Sterling", "SunTrust", "Union", "Unity", "Wema", "Zenith"].map(item => {
                return { name: item, value: item }
            });

        let commercialBanksList: ISelectItems[] = commercialBanks?.map((item) => ({
            name: item.institutionName,
            value: item.cbnCode,
        }));

        let dataEntryInputData: IMultiSelectItems[] = [
            { label: "Personal Details", value: "PersonalDetails" },
            { label: "Contact Details", value: "ContactDetails" },
            { label: "Work Details", value: "WorkDetails" },
            { label: "Reference Details", value: "ReferenceDetails" },
            { label: "Collateral Details", value: "CollateralDetails" },
            { label: "Document Collection", value: "DocumentCollection" },
            { label: "Notepad Details", value: "NotepadDetails" },
            { label: "Income Details", value: "IncomeDetails" },
            { label: "Asset Details", value: "AssetDetails" },
            { label: "Property Details", value: "PropertyDetails" },
            { label: "Loan Details", value: "LoanDetails" },
            { label: "Corporate Details", value: "CorporateDetails" },
            { label: "Stakeholder Details", value: "StakeholderDetails" }
        ];

        if (_.isEmpty(item)) {
            return null
        }

        return (
            <Modal
                size="xl"
                backdrop="static"
                show={showEditModal}
                onHide={toggleEditModal}
            >
                <Modal.Header>
                    <Modal.Title>
                        <h5>
                            <i className="fas fa-columns fa-sm cyan-text mr-3" />
                            Edit Supplier Details -{" "}
                            <span className="font-weight-bold">{item.name}</span>
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <EditSupplierDetailsForm
                    initialValues={{
                        name: item.name,
                        bankName: item.bankName,
                        accountNumber: item.accountNumber,
                        phoneNumber: item.phoneNumber,
                        address: item.address
                    }}
                    FormComponent={({
                        fields: { name, bankName, accountNumber, phoneNumber, address },
                        onChange,
                        onReloadFields,
                        onHandleSubmit,
                    }) => (
                        <Fragment>
                            <Modal.Body>
                                <div className="card">
                                    {/* General Information */}
                                    <Fragment>
                                        <div className="card-header clear-fix">
                                            {_.isEmpty(item) ? (
                                                <Skeleton width={200} />
                                            ) : (
                                                <h6
                                                    className="card-title float-left"
                                                    style={{ marginBottom: "0px" }}
                                                >
                                                    <b>General Information</b>
                                                </h6>
                                            )}
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    {_.isEmpty(item) ? (
                                                        <Skeleton count={3} width={300} />
                                                    ) : (
                                                        <dl className="row">
                                                            <DetailItem
                                                                label="Name"
                                                                value={
                                                                    <DetailItemInputComponent
                                                                        id="name"
                                                                        type="text"
                                                                        name="name"
                                                                        placeholder=""
                                                                        value={name}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                    />
                                                                }
                                                                labelSize={5}
                                                                valueSize={7}
                                                            />
                                                            <DetailItem
                                                                label="Bank Name"
                                                                value={
                                                                    // <DetailItemInputComponent
                                                                    //     id="bankName"
                                                                    //     type="text"
                                                                    //     name="bankName"
                                                                    //     placeholder=""
                                                                    //     value={bankName}
                                                                    //     required={true}
                                                                    //     validated={validated}
                                                                    //     errors={errors}
                                                                    //     onChange={onChange}
                                                                    // />
                                                                    <DetailItemSelectBox
                                                                        id="bankName"
                                                                        name="bankName"
                                                                        items={supplierBankNameInputType}
                                                                        value={bankName}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={async (id, value) => {
                                                                            await onChange(id, value)
                                                                            // this.setState({ bankCode: value, ownUpdate: true });
                                                                            await this.fetchAccountName(value, "bankName")
                                                                        }}
                                                                    />
                                                                }
                                                                labelSize={5}
                                                                valueSize={7}
                                                            />
                                                            <DetailItem
                                                                label="Account Number"
                                                                value={
                                                                    <DetailItemInputComponent
                                                                        id="accountNumber"
                                                                        type="text"
                                                                        name="accountNumber"
                                                                        placeholder=""
                                                                        value={accountNumber}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                        onBlur={(value) => { this.fetchAccountName(value, "accountNumber") }}
                                                                    />
                                                                }
                                                                labelSize={5}
                                                                valueSize={7}
                                                            />
                                                        </dl>
                                                    )}
                                                </div>
                                                <div className="col-md-6">
                                                    {_.isEmpty(item) ? (
                                                        <Skeleton count={3} width={300} />
                                                    ) : (
                                                        <dl className="row">
                                                            <DetailItem
                                                                label="Account Name"
                                                                value={
                                                                    <DetailItemInputComponent
                                                                        id="accountName"
                                                                        type="text"
                                                                        name="accountName"
                                                                        placeholder=""
                                                                        value={this.state.accountName}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                        disabled
                                                                    />
                                                                }
                                                                labelSize={5}
                                                                valueSize={7}
                                                            />
                                                            <DetailItem
                                                                label="Phone Number"
                                                                value={
                                                                    <DetailItemInputComponent
                                                                        id="phoneNumber"
                                                                        type="text"
                                                                        name="phoneNumber"
                                                                        placeholder=""
                                                                        value={phoneNumber}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                    />
                                                                }
                                                                labelSize={5}
                                                                valueSize={7}
                                                            />
                                                            <DetailItem
                                                                label="Address"
                                                                value={
                                                                    <DetailItemInputComponent
                                                                        id="address"
                                                                        type="text"
                                                                        name="address"
                                                                        placeholder=""
                                                                        value={address}
                                                                        required={true}
                                                                        validated={validated}
                                                                        errors={errors}
                                                                        onChange={onChange}
                                                                    />
                                                                }
                                                                labelSize={5}
                                                                valueSize={7}
                                                            />
                                                        </dl>
                                                    )}
                                                </div>
                                            </div>
                                            <hr />

                                        </div>
                                    </Fragment>

                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    size="sm"
                                    variant="secondary"
                                    onClick={(e) => {
                                        onHandleSubmit(e, ActionTypes.UPDATE, "Supplier Details", () => {
                                            this.onFormSubmit(
                                                { name, phoneNumber, accountNumber, bankName, address },
                                                onReloadFields
                                            );
                                        });
                                    }}
                                    disabled={submitting}
                                >
                                    {submitting === false ? (
                                        <React.Fragment>
                                            <i className="fas fa-lg fa-save mr-3" /> Save
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <i className="fas fa-spin fa-circle-notch" />
                                        </React.Fragment>
                                    )}
                                </Button>
                                <Button
                                    size="sm"
                                    variant="outline-danger"
                                    onClick={() => toggleEditModal(false, this.props.item)}
                                    disabled={submitting}
                                >
                                    <i className="fas fa-times mr-3" />
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Fragment>
                    )}
                ></EditSupplierDetailsForm>
            </Modal>
        );
    }
}

export default EditSupplierDetails;
