import React, { Component } from "react";
import FAMComponent from "./component";
import { IBaseFormState } from "../../../../../../../components/BaseFormComponent";
import _ from "lodash";

interface CorporateAnalysisProps {
  values: any;
  reloadItem: any;
  mandatoryFields: any;
}

interface CorporateAnalysisState {
  ownUpdate: boolean;
  item: any;
}

class FAM extends Component<
  CorporateAnalysisProps,
  IBaseFormState & CorporateAnalysisState
> {
  constructor(props: CorporateAnalysisProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      item: {},
      ownUpdate: false,
    };
  }

  static getDerivedStateFromProps(
    nextProps: CorporateAnalysisProps,
    prevState: CorporateAnalysisState
  ) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isNull(nextProps.values)) {
      return null;
    }
    return {
      item: nextProps.values,
    };
  }
  render() {
    const { values } = this.props;
    return <FAMComponent loan={values} />;
  }
}

export default FAM;
