import * as React from "react";
import _ from "lodash";
import { Fragment } from "react";
import {
  IFilterData,
  BaseListComponentState,
} from "../../../components/BaseListComponent";
import {
  createFilterData,
  mapEntityList,
} from "./../../../services/utility/autoMapperService";
import BaseListComponent from "./../../../components/BaseListComponent";
import ItemsTable, {
  filterFieldData,
} from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "./../../../components/tableComponents/tableFilterPane";
import messageTemplateService from "../../../services/creditAssessment/messageTemplateService";
import MessageTemplateDetail from "./messageTemplateDetail";
import EditMessageTemplate from "./editMessageTemplate";

export interface MessageTemplatesProps { }

export interface MessageTemplatesState extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
  allItems: any;
}

class MessageTemplatesList extends BaseListComponent<MessageTemplatesState> { }

class MessageTemplates extends React.Component<
  MessageTemplatesProps,
  MessageTemplatesState
> {
  constructor(props: MessageTemplatesProps) {
    super(props);
    this.state = {
      data: createFilterData([]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
      allItems: [],
    };
  }

  handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true });
    // const decodedToken = decodeToken(getCurrentUserSession().token || "");
    // let dataClone =
    //   typeof data === "undefined" ? { ...this.state.data } : { ...data };
    try {
      //   const decodedToken = decodeToken(getCurrentUserSession().token || "");
      const messageTemps = await messageTemplateService.retrieveAll();

      // dataClone["institutionId"] = {
      //   item1: "=",
      //   item2: decodedToken?.["InstitutionId"]?.toString() || "",
      // };
      // const MessageTemplates = await MessageTemplateService.filterPagedMessageTemplates(
      //   dataClone,
      //   Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      //   currentPage
      // );
      const messageTemplates: any = [];
      const totalLength = messageTemps.data.length;
      const initial = (currentPage - 1) * pageSize;
      for (let i = initial; i < initial + pageSize; i++) {
        if (i === totalLength) {
          break;
        }
        messageTemplates.push(messageTemps.data[i]);
      }

      this.setState({
        tableItems: mapEntityList({
          // entityList: messageTemplates.data.item2,
          entityList: messageTemplates,
          properties: [
            { oldName: "name", newName: "name" },
            { oldName: "messageType", newName: "messageType" },
            { oldName: "subject", newName: "subject" },
            { oldName: "dateCreated", newName: "dateCreated" },
            { oldName: "dateUpdated", newName: "lastUpdated" },
          ],
        }),

        // "isActive": true,
        currentPage,
        pageSize,
        // totalSize: messageTemplates.data.item1,
        totalSize: totalLength,
        fetching: false,
        allItems: messageTemps.data,
      });
    } catch (ex) {
      //   console.log("caught exception", ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
        this.setState({ fetching: false });
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        this.setState({ fetching: false });
      }
    }
  };

  handleOnExport = async (type: string) => { };

  fetchItemById = async (item: any) => {
    this.setState({ fetchingDetail: true });
    try {
      const messageTemplate = await messageTemplateService.retrieveById(item.id);
      this.setState({ item: messageTemplate.data, fetchingDetail: false });
    } catch (error) {
      // console.log(error.response);
      this.setState({ fetchingDetail: false });
    }
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }

  render() {
    const {
      // data,
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;
    // console.log("asfsdf", this.state.filterValueInput);
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Message Templates</b>
              </h3>
            </div>
            <MessageTemplatesList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              //   updateState={this.state.updateBaseListState}
              initialValues={{
                data: createFilterData([]) as IFilterData,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: this.state.filterValueInput,
                item: {},
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                onToggleEditModal,
                showDetailsModal,
                showEditModal,
              }) => (
                <Fragment>
                  <MessageTemplateDetail
                    showDetailsModal={showDetailsModal}
                    toggleDetailsModal={onToggleDetailsModal}
                    toggleEditModal={onToggleEditModal}
                    showTrail={null}
                    currentUserRoles={null}
                    item={item}
                  />
                  <EditMessageTemplate
                    showEditModal={showEditModal}
                    toggleEditModal={(reloadDetails: boolean, item: any) => {
                      onToggleEditModal();
                      if (reloadDetails === true) {
                        this.fetchItemById(item);
                      }
                      this.setState({
                        showDetailsModal: !this.state.showDetailsModal,
                      });
                    }}
                    item={item}
                  />
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="Message Templates"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                              //   amountColumn={["Amount"]}
                              amountInNaira={true}
                              timeColumn={["dateCreated", "lastUpdated"]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></MessageTemplatesList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default MessageTemplates;
