import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Timer from "../../components/timer";
import {
  getCurrentUserSession,
  getCurrentUsersRoles,
} from "../../services/auth/authService";
import _ from "lodash";
import { decodeToken } from "react-jwt";
import institutionsService from "../../services/auth/institutionsService";
import feePayableWalletService from "../../services/billing/feePayableWalletService";
import textFormatService from "../../services/utility/textFormatService";
import settlementWithdrawalService from "../../services/collectionSweep/settlementWithdrawalService";
import { toast } from "react-toastify";

interface INavBarProps {
  user: any;
}
interface INavBarState {
  remoteInstitution: any;
  appzoneFeeItem: any;
  fetchingAppzoneFeeItems: boolean;
  institutionCode: string;
  submitting: boolean;
  ownUpdate: boolean;
  settlementWalletItem: any;
}
class NavBar extends Component<INavBarProps, INavBarState> {
  constructor(props: INavBarProps) {
    super(props);
    this.state = {
      remoteInstitution: {},
      appzoneFeeItem: {},
      fetchingAppzoneFeeItems: false,
      institutionCode: "",
      submitting: false,
      ownUpdate: false,
      settlementWalletItem: {}
    };
  }

  componentDidMount = async () => {
    let remoteInstitution: any = {};
    const currentSession = getCurrentUserSession();
    const decodedToken = decodeToken(currentSession.token || "");
    let institution = await institutionsService.retrieveInstitutionById(decodedToken?.["InstitutionId"])

    if (getCurrentUsersRoles().includes("remotesession")) {
      this.setState({ remoteInstitution: institution.data });
    }

    this.fetchWalletDetail(institution.data.code)
  };

  // handleWithdrawal = async (institutionCode, amount) => {
  //   this.setState({ submitting: true, ownUpdate: true });
  //   try {
  //     if (institutionCode === '000') {
  //       return false;
  //     }
  //     else {
  //       var response = await settlementWithdrawalService.initiateWithdrawal(institutionCode, amount);
  //       if (response.status >= 200 && response.status <= 300) {
  //         toast.info(`Withdrawal initiated successfully!`, {
  //           autoClose: 6000,
  //           type: toast.TYPE.DEFAULT,
  //           hideProgressBar: false,
  //         });
  //       } else {
  //         return false;
  //       }
  //       this.setState({ submitting: false, ownUpdate: true });
  //     }
  //     this.setState({ appzoneFeeItem: response.data, fetchingAppzoneFeeItems: false, institutionCode })
  //   } catch (ex) {
  //     this.setState({ fetchingAppzoneFeeItems: false });
  //   }
  // }
  async fetchWalletDetail(institutionCode: string) {
    this.setState({ fetchingAppzoneFeeItems: true });
    try {
      if (institutionCode === '000') {
        var response = await feePayableWalletService.retrieveAppzoneFeePayableWallet();
      }
      else {
        var response = await feePayableWalletService.retrieveFeePayableWalletByInstitutionCode(institutionCode);
        // var institutionWallet = await settlementWithdrawalService.getWalletByInstitution(institutionCode)

      }
      this.setState({ appzoneFeeItem: response.data, fetchingAppzoneFeeItems: false, institutionCode })
    } catch (ex) {
      this.setState({ fetchingAppzoneFeeItems: false });
    }
  }



  render() {
    const { user } = this.props;
    const { appzoneFeeItem, institutionCode, settlementWalletItem } = this.state;

    return (
      // <!--navbar-->
      <div className="sticky">
        <div className="navbar navbar-expand d-flex justify-content-between bd-navbar white shadow">
          <div className="relative">
            <div className="d-flex">
              <div className="d-none d-md-block">
                <h1 className="nav-title">
                  {_.isEmpty(this.state.remoteInstitution) ? (
                    ""
                  ) : (
                    <Fragment>
                      <i
                        className="icon icon-building-o s-18 mr-1"
                        style={{ color: "#EF7E22" }}
                      />
                      {this.state.remoteInstitution.name}
                      <span>
                        <i
                          className="icon-timer ml-4"
                          style={{ color: "#EF7E22" }}
                        />{" "}
                        <Timer />
                      </span>
                    </Fragment>
                  )}
                </h1>
              </div>
            </div>
          </div>
          {/* <!--Top Menu Start --> */}
          <div className="navbar-custom-menu">
            <ul className="nav navbar-nav">
              {/* <!-- Institution --> */}
              <li className="nav-item" style={{ marginRight: "20px" }}>
                <p
                  style={{
                    marginBottom: "0px",
                    paddingBottom: "0px",
                    lineHeight: "25px",
                  }}
                >
                  <b>{user?.institution?.name}</b>
                </p>
                <p
                  className="font-italic"
                  style={{
                    marginBottom: "0px",
                    marginTop: "-5px",
                    color: "#0a6095",
                  }}
                >
                  <i
                    className="fas fa-gopuram mr-1"
                    style={{ color: "#EF7E22" }}
                  />{" "}
                  {user?.branch?.name}
                </p>
              </li>
              {/* <!-- User Account --> */}
              <li
                className="nav-item"
                style={{
                  borderLeft: "1px solid #acbbce",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                <p
                  style={{
                    marginBottom: "0px",
                    paddingBottom: "0px",
                    lineHeight: "25px",
                  }}
                >
                  <b>
                    {user?.firstName} {user?.lastName}
                  </b>
                </p>
                <p
                  className="font-italic"
                  style={{
                    marginBottom: "0px",
                    marginTop: "-5px",
                    color: "#0a6095",
                  }}
                >
                  <i
                    className="fas fa-medal mr-1"
                    style={{ color: "#EF7E22" }}
                  />{" "}
                  {user?.userRole?.name}
                </p>
              </li>
              {/* <!-- Appzone Fee --> */}
              {(getCurrentUsersRoles().includes("viewappzonefee") || getCurrentUsersRoles().includes("remotesession")) && (
                <li>
                  <a className="nav-link ml-2" data-toggle="control-sidebar" onClick={() => this.fetchWalletDetail(institutionCode)}>
                    <i className="fas fa-wallet mr-1" style={{ color: "#EF7E22", cursor: "pointer" }} />
                  </a>
                  {_.isEmpty(appzoneFeeItem) ? (
                    <div className="control-sidebar fixed white ">
                      <div className="slimScroll">
                        <div className="sidebar-header">
                          <h6>Appzone Fee not configured</h6>
                          <a href="" data-toggle="control-sidebar" className="paper-nav-toggle active"><i></i></a>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <React.Fragment>
                      <div className="control-sidebar fixed white" style={{ height: "130px" }}>
                        <div className="">
                          <div className="sidebar-header">
                            <h4>Appzone Fee</h4>
                            <a href="" data-toggle="control-sidebar" className="paper-nav-toggle active"><i></i></a>
                          </div>
                          <Link to="/home/appzonefeepayablereport">
                            <div className="p-3 bg-primary text-white">
                              <div className="row">
                                <div className="col-md-12">
                                  <h5 className="font-weight-normal">Total Value</h5>
                                  <span className="font-weight-lighter text-primary">
                                    {textFormatService.formatMoney(appzoneFeeItem?.value)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                      {/* <div className="control-sidebar fixed white" style={{ height: "30px" }}>
                        <div className="">
                          <div className="sidebar-header">
                            <h4>Settlement Fee</h4>
                            <a href="" data-toggle="control-sidebar" className="paper-nav-toggle active"><i></i></a>
                          </div>
                          <div className="p-3 bg-primary text-white">
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-weight-small">Total Value</h6>
                                <span className="font-weight-lighter text-primary">
                                  {textFormatService.formatMoney(settlementWalletItem.balance)}
                                </span>
                              </div>
                              <button
                                type="button"
                                className="btn btn-primary ml-auto"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "33px",
                                  fontSize: "12px",
                                  borderRadius: "8px",
                                  color: "rgb(255, 255, 255)",
                                  borderColor: "rgb(105, 65, 198)",
                                  backgroundColor: "rgb(105, 65, 198)"
                                }}
                                onClick={() =>
                                  this.handleWithdrawal(institutionCode, settlementWalletItem.balance)
                                }
                              >
                                <span>Withdraw</span></button>
                              <p>Please note, this attracts withdrawal charges</p>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </React.Fragment>
                  )}
                </li>
              )}
              {/* <!-- User Menu --> */}
              <li className="dropdown custom-dropdown user user-menu ">
                <a
                  href="index.html#"
                  className="nav-link"
                  data-toggle="dropdown"
                >
                  <i className="fas fa-user-circle"></i>
                  <i className="icon-arrow_drop_down"></i>
                </a>
                <div
                  className="dropdown-menu p-3 dropdown-menu-right"
                  style={{ width: "auto" }}
                >
                  <div className="row box justify-content-between">
                    <div className="col">
                      <Link to="/logout">
                        <i className="icon-sign-out red lighten-2 avatar  r-5"></i>
                        <div className="pt-1">Logout</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      // {/* <!--#navbar Ends--> */}
      // <nav
      //   className="navbar main-navbar fixed-top py-md-0 navbar-light"
      //   style={{
      //     backgroundColor:
      //       currentUser.scope === "Global" ? "#010871" : "#015f95"
      //   }}
      // >
      //   <a
      //     className="navbar-brand show-sidebar white-text mb-0"
      //     onClick={this.props.toggleSideBar}
      //     style={{
      //       marginRight: "0rem",
      //       height: "50px"
      //     }}
      //   >
      //     <i className="fab fa-connectdevelop mr-2" />
      //     <span className="navbar-brand h1 white-text">Recova</span>
      //   </a>

      //   <ul className="navbar-nav ml-auto nav-flex-icons">
      //     <li className="nav-item" style={{ marginRight: "20px" }}>
      //       <p
      //         className="nav-link waves-effect white-text font-weight-normal waves-light"
      //         style={{ marginBottom: "0px", paddingBottom: "0px" }}
      //       >
      //         {institution.name}
      //       </p>
      //       <p
      //         className="font-italic"
      //         style={{
      //           color: "white",
      //           marginBottom: "0px"
      //         }}
      //       >
      //         <i className="fas fa-gopuram mr-1" /> {branch.name}
      //       </p>
      //     </li>
      //     <li className="nav-item">
      //       <a className="nav-link waves-effect waves-light" href="#!">
      //         <div
      //           className="vl"
      //           style={{
      //             borderLeft: "1px white solid",
      //             height: "40px",
      //             marginRight: "5px"
      //           }}
      //         />
      //       </a>
      //     </li>
      //     <li className="nav-item" style={{ marginRight: "20px" }}>
      //       <a className="nav-link waves-effect waves-light" href="#!">
      //         <p
      //           className="font-weight-bolder"
      //           style={{ color: "white", marginBottom: "0px" }}
      //         >
      //           {currentUser.firstName} {currentUser.lastName}
      //         </p>
      //         <p
      //           className="font-italic"
      //           style={{ color: "white", marginBottom: "0px" }}
      //         >
      //           <i className="fas fa-medal mr-1" /> {role.name}
      //         </p>
      //       </a>
      //     </li>
      //     <li className="nav-item dropdown">
      //       <a
      //         href="#!"
      //         className="nav-link dropdown-toggle"
      //         id="navbarDropdownMenuLink-333"
      //         data-toggle="dropdown"
      //         aria-haspopup="true"
      //         aria-expanded="false"
      //         style={{ color: "white", marginRight: "30px" }}
      //       >
      //         <i className="fas fa-user-circle fa-2x"></i>
      //         {/* <img
      //           //src={avatarFemale}
      //           src="https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg"
      //           className="rounded-circle z-depth-0"
      //           alt="avatar"
      //           style={{ height: "40px" }}
      //         /> */}
      //       </a>
      //       <div
      //         className="dropdown-menu dropdown-menu-right dropdown-default"
      //         aria-labelledby="navbarDropdownMenuLink-333"
      //       >
      //         <a className="dropdown-item font-weight-normal" href="#!">
      //           <i className="far fa-user mr-3" />
      //           Profile
      //         </a>
      //         {/* <a className="dropdown-item font-weight-normal" href="#!">
      //           <i className="fas fa-cog mr-3" />
      //           Settings
      //         </a>
      //         <a className="dropdown-item font-weight-normal" href="#!">
      //           <i
      //             className="fas fa-user-lock mr-3"
      //             style={{ color: "#0DA7F3" }}
      //           />
      //           Lock
      //         </a> */}
      //         <hr style={{ margin: "0.5rem 0px", color: "black" }} />
      //         <NavLink
      //           to="/logout"
      //           className="dropdown-item font-weight-normal"
      //         >
      //           <i
      //             className="fas fa-sign-in-alt mr-3"
      //             style={{ color: "#F73226" }}
      //           />
      //           Logout
      //         </NavLink>
      //         {/* <NavLink
      //           to="/logout"
      //           className="btn btn-default btn-sm btn-rounded dropdown-item mb-0"
      //           style={{
      //             borderRadius: "10em",
      //             margin: "0px",
      //             padding: "5px 25px"
      //           }}
      //         >
      //           Logout
      //         </NavLink> */}
      //       </div>
      //     </li>
      //   </ul>
      // </nav>
    );
  }
}

export default NavBar;
