import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import textFormatService from "../../../services/utility/textFormatService";

interface ILoanRequestDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  showTrail: any;
  currentUserRoles: any;
  item: any;
}

interface ILoanRequestDetailState {
  item: any;
  updatingStatus: boolean;
}

class LoanRequestDetail extends Component<
  ILoanRequestDetailProps,
  ILoanRequestDetailState
> {
  constructor(props: ILoanRequestDetailProps) {
    super(props);
    this.state = {
      item: {},
      updatingStatus: false,
    };
  }

  static getDerivedStateFromProps(
    nextProps: ILoanRequestDetailProps,
    prevState
  ) {
    // console.log("current props: ", nextProps.item);
    if (prevState.item !== nextProps.item) {
      return {
        item: nextProps.item,
        // updateProduct: true,
      };
    }
  }

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      //   showTrail,
      //   currentUserRoles,
      item,
    } = this.props;
    const { updatingStatus } = this.state;
    // console.log("Fetched Item :: ", item);
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="icon icon-building-o s-18 mr-3" />
                Loan Request Details
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={4} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="First Name"
                        value={item.customerFirstName}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Last Name"
                        value={item.customerLastName}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Gender"
                        value={
                          item.customerGender === ""
                            ? "Unspecified"
                            : item.customerGender
                        }
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Phone Number"
                        value={item.customerPhoneNumber}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="BVN"
                        value={item.customerBVN}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Repayment Account"
                        value={item.customerRepaymentAccount}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Product Code"
                        value={item.productCode}
                        labelSize={6}
                        valueSize={6}
                      />
                      {item.errorMessage === "" ? (
                        ""
                      ) : (
                        <DetailItem
                          label="Error Message"
                          value={item.errorMessage}
                          labelSize={6}
                          valueSize={6}
                        />
                      )}
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Credit Score"
                        value={item.creditScore}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Amount"
                        value={textFormatService.formatCurrency(item.amount, 2)}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Tenure in days"
                        value={item.tenure}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Mandate Reference"
                        value={item.mandateReference}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Status"
                        value={
                          (item ? item.status : "New") === "New" ||
                          (item ? item.status : "New") === "Completed" ? (
                            <span className="badge badge-success">
                              {item ? item.status : ""}
                            </span>
                          ) : item.status === "Cancelled" ? (
                            <span className="badge badge-danger">
                              {item ? item.status : ""}
                            </span>
                          ) : (
                            <span className="badge badge-primary">
                              {item ? item.status : ""}
                            </span>
                          )
                        }
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Request Date"
                        value={textFormatService.formatTimeString(
                          item.dateRequested
                        )}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Disburse Date"
                        value={
                          item.dateDisbursed
                            ? textFormatService.formatTimeString(
                                item.dateDisbursed
                              )
                            : "N/A"
                        }
                        labelSize={6}
                        valueSize={6}
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
              {/* {item.status === "Inactive" &&
                getCurrentUsersRoles().includes("enablebranch") && (
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    disabled={updatingStatus}
                    onClick={(e) =>
                      this.onHandleEnableOrDisable(
                        e,
                        item.status === "Inactive"
                          ? ActionTypes.ENABLE
                          : ActionTypes.DISABLE,
                        "Branch"
                      )
                    }
                  >
                    <i className="far fa-edit mr-3" />
                    Enable
                  </Button>
                )}
              {item.status === "Active" &&
                getCurrentUsersRoles().includes("disablebranch") && (
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    disabled={updatingStatus}
                    onClick={(e) =>
                      this.onHandleEnableOrDisable(
                        e,
                        item.status === "Inactive"
                          ? ActionTypes.ENABLE
                          : ActionTypes.DISABLE,
                        "Branch"
                      )
                    }
                  >
                    <i className="far fa-edit mr-3" />
                    Disable
                  </Button>
                )} */}
              {/* {getCurrentUsersRoles().includes("editbranch") && ( */}
              {/* <Button
                size="sm"
                variant="primary"
                disabled={updatingStatus}
                onClick={toggleEditModal}
              >
                <i className="far fa-edit mr-3" />
                Edit
              </Button> */}
              {/* )} */}
            </React.Fragment>
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default LoanRequestDetail;
