import * as React from "react";
import _ from "lodash";
import { Fragment } from "react";
import {
  IFilterData,
  BaseListComponentState,
} from "../../../components/BaseListComponent";
import { printPDF } from "../../../services/utility/textFormatService";
import {
  createFilterData,
  mapEntityList,
} from "./../../../services/utility/autoMapperService";
import BaseListComponent from "./../../../components/BaseListComponent";
import ConsentRequestService from "../../../services/mandate/consentRequestService";
import institutionsService from "../../../services/auth/institutionsService";
import ItemsTable, {
  filterFieldData,
} from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "./../../../components/tableComponents/tableFilterPane";
// import { getCurrentUsersRoles } from "../../../services/auth/authService";
import ConsentRequestDetail from "./consentRequestDetail";

export interface ConsentRequestsOCMProps {}

export interface ConsentRequestsOCMState extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
  updateBaseListState: boolean;
  toUpdate: boolean;
}

class ConsentRequestsOCMList extends BaseListComponent<ConsentRequestsOCMState> {}

class ConsentRequestsOCM extends React.Component<
  ConsentRequestsOCMProps,
  ConsentRequestsOCMState
> {
  constructor(props: ConsentRequestsOCMProps) {
    super(props);
    this.state = {
      data: createFilterData([
        "phoneNumber",
        "bvn",
        "institution",
        "dateLogged",
        "requestStatus",
      ]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [
        { name: "phoneNumber", fieldType: "text", value: "" },
        { name: "bvn", fieldType: "text", value: "" },
        {
          name: "institution",
          fieldType: "select",
          options: [],
          value: "",
        },
        { name: "dateLogged", fieldType: "date", value: "" },
        {
          name: "requestStatus",
          fieldType: "select",
          options: [
            "AwaitingConfirmation",
            "Confirmed",
            "Cancelled",
            "Delivered",
            "Failed",
          ],
          value: "",
        },
      ],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
      updateBaseListState: false,
      toUpdate: true,
    };
  }

  handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true, updateBaseListState: false });
    let dataClone = typeof data === "undefined" ? this.state.data : data;
    try {
      const institutions = await institutionsService.retrieveAllInstitutions();
      if (currentPage === 1 && this.state.toUpdate === true) {
        const institutionNames = !_.isEmpty(institutions)
          ? institutions.data.map((item) => item.name)
          : [];
        let filterValueInput = [...this.state.filterValueInput];
        filterValueInput = filterValueInput.map((filterField) =>
          filterField.name === "institution"
            ? { ...filterField, options: institutionNames }
            : filterField
        );
        this.setState({
          filterValueInput: filterValueInput,
          updateBaseListState: true,
          toUpdate: false,
        });
      }
      const institutionObj = !_.isEmpty(institutions)
        ? institutions.data.map((item) => {
            return { name: item.name, id: item.id };
          })
        : [];

      const selectedInstitutionObj = !_.isEmpty(institutionObj)
        ? institutionObj.filter((item) => item.name === dataClone["institution"].item2)[0]
        : {};

      //fix to get correct institutionId
      const newDataArray = [
        "phoneNumber",
        "bvn",
        "institutionId",
        "dateLogged",
        "requestStatus",
      ];
      let filterData: any = {};
      newDataArray.forEach((header) => {
        if (header === "institutionId") {
          filterData[header] = !_.isEmpty(selectedInstitutionObj)
            ? { item1: dataClone["institution"].item1, item2: selectedInstitutionObj.id }
            : { item1: "like", item2: "" };
        } else {
          filterData[header] = dataClone[header];
        }
      });

      const consentRequestsOCM = await ConsentRequestService.filterConsentRequestsOCM(
        filterData,
        pageSize,
        currentPage
      );
      // console.log("consentRequests: ", consentRequests);
      this.setState({
        tableItems: mapEntityList({
          entityList: consentRequestsOCM.data.item2,
          properties: [
            { oldName: "customerName", newName: "Name" },
            { oldName: "bvn", newName: "bvn" },
            { oldName: "phoneNumber", newName: "phoneNumber" },
            { oldName: "loanAmount", newName: "loanAmount" },
            {
              oldName: "totalRepaymentExpected",
              newName: "totalRepaymentExpected",
            },
            { oldName: "consentReceiptDate", newName: "consentReceiptDate" },
            {
              isTag: true,
              oldName: "requestStatus",
              newName: "requestStatus",
              success: "Disbursed",
              danger: "Failed",
              default: "AwaitingDisbursement",
              primary: "Confirmed",
              secondary: "secondary",
              warning: "Cancelled",
              light: "AwaitingConfirmation",
              dark: "dark",
            },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: consentRequestsOCM.data.item1,
        fetching: false,
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
        // this.setState({ errors, fetching: false });
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        // this.setState({ errors, fetching: false });
      }
    }
  };

  handleOnExport = async (type: string) => {
    try {
      switch (type) {
        case "XLSX":
        case "CSV":
          return await ConsentRequestService.downloadReport(type);
        case "PDF":
          const response = await ConsentRequestService.downloadReportPDF();
          printPDF(response.data);
          break;
        default:
          break;
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        //this.setState({ errors, fetching: false });
      }
    }
  };

  fetchItemById = async (item: any) => {
    // await activityTrailService.saveActivityTrail({
    //   actionType: "View Details",
    //   description: `Viewed details of Branch ${item.name}`
    // });
    this.setState({ fetchingDetail: true, updateBaseListState: false});
    // console.log("fetching branch with id: ", item.id);
    const request = await ConsentRequestService.retrieveConsentRequestById(
      item.id
    );
    // console.log("consent request: ", request.data);
    if(request.data.hasGuarantor) {
      request.data.guarantorConsentRequests?.forEach(element => {
        element.totalRepaymentExpected = 0.01 * element.guaranteeingPercentage * request.data.totalRepaymentExpected;
      });
    }
    this.setState({ item: request.data, fetchingDetail: false });
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }

  render() {
    const {
      // data,
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;
    // let filteredItems: IFilterData = {};
    // Object.keys(data).forEach((key) => {
    //   if (data[key].item2 !== "") {
    //     filteredItems[key] = data[key];
    //   }
    // });
    // console.log("data", data);
    // console.log("filteredItems", filteredItems);
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Consent Requests (OCM)</b>
              </h3>
            </div>
            <ConsentRequestsOCMList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              updateState={this.state.updateBaseListState}
              initialValues={{
                data: createFilterData([
                  "phoneNumber",
                  "bvn",
                  "institution",
                  "dateLogged",
                  "requestStatus",
                ]) as IFilterData,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: this.state.filterValueInput,
                item: {},
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                onToggleEditModal,
                showDetailsModal,
                showEditModal,
              }) => (
                <Fragment>
                  <ConsentRequestDetail
                    showDetailsModal={showDetailsModal}
                    toggleDetailsModal={onToggleDetailsModal}
                    handlePageChange={this.handlePageChange}
                    item={item}
                  />
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="ConsentRequests"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                              amountColumn={[
                                "loanAmount",
                                "totalRepaymentExpected",
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></ConsentRequestsOCMList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ConsentRequestsOCM;
