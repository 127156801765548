import React, { Fragment } from "react";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import BaseFormComponent, { IBaseFormState } from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import FormInputComponent from "../../../components/formInputComponent";
import SelectInputComponent, { ISelectItems } from "../../../components/formSelectComponent";
import { CreditPolicyUseCase } from "../../../enums/creditPolicyUseCase";
import { CreditPolicyValueType } from "../../../enums/creditPolicyValueType";
import appraisalValidationService from "../../../services/hybridAppraisal/appraisalValidationService";
import { generateGUID } from "../../../services/utility/textFormatService";
import { toast } from "react-toastify";

interface ModifyCreditPolicyParameterFields {
  creditPolicyParameterId: string;
  creditPolicyUseCase?: string;
  creditPolicyValueType?: string;
  maxSingleInputValue?: string;
  minSingleInputValue?: string;
  dropDownOptions?: any;
  rangeOptions?: any;
}

interface IDropDownOptions {
  passCriteria?: string,
  score?: number,
  name: string,
  description?: string,
  identifier: string
}

interface IRangeOptions {
  passCriteria?: string,
  score?: number,
  minimum: string,
  maximum: string,
  name?: string,
  operator?: string
}

interface ISingleValueOptions {
  passCriteria?: string,
  minimum: string,
  maximum: string
}

class ModifyCreditPolicyParameterForm extends BaseFormComponent<ModifyCreditPolicyParameterFields> { }

interface IModifyCreditPolicyParameterProps {
  item: any;
  showPatchModal: boolean;
  togglePatchModal: any;
}

interface IModifyCreditPolicyParameterState {
  ownUpdate: boolean;
  dropDownOptions: IDropDownOptions[];
  rangeOptions: IRangeOptions[];
  singleValueOptions: ISingleValueOptions[];
  creditPolicyUseCase: string;
}

class ModifyCreditPolicyParameters extends React.Component<
  IModifyCreditPolicyParameterProps,
  IBaseFormState & IModifyCreditPolicyParameterState
> {
  constructor(props: IModifyCreditPolicyParameterProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      ownUpdate: false,
      errors: {},
      dropDownOptions: [],
      rangeOptions: [],
      creditPolicyUseCase: "",
      singleValueOptions: []
    };
  }

  static getDerivedStateFromProps(
    nextProps: IModifyCreditPolicyParameterProps,
    prevState: IModifyCreditPolicyParameterState
  ) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isEmpty(nextProps.item)) {
      return null;
    }
    const dropDownOptions: IDropDownOptions[] = [];
    const rangeOptions: IRangeOptions[] = [];
    const singleValueOptions: ISingleValueOptions[] = [];
    if (nextProps.item?.creditPolicyValueType === CreditPolicyValueType[CreditPolicyValueType.DropDownOptions]) {
      nextProps.item.valueTypeOptions.map(
        (item) => {
          dropDownOptions.push({
            name: item.name,
            description: item.description,
            identifier: item.identifier,
            passCriteria: "",
            score: 0
          });
        }
      )
      var creditPolicyUseCase = (nextProps.item?.creditPolicyUseCase !== "Both") ?
        nextProps.item?.creditPolicyUseCase : ""
      return { dropDownOptions, creditPolicyUseCase }
    }
    if (nextProps.item?.creditPolicyValueType === CreditPolicyValueType[CreditPolicyValueType.Range]) {
      nextProps.item.valueTypeOptions.map(
        (item) => {
          rangeOptions.push({
            minimum: item.minimum,
            maximum: item.maximum,
            name: item.name,
            score: 0,
            passCriteria: ""
          });
        }
      )
      var creditPolicyUseCase = (nextProps.item?.creditPolicyUseCase !== "Both") ?
        nextProps.item?.creditPolicyUseCase : ""
      return { rangeOptions, creditPolicyUseCase }
    }
    if (nextProps.item?.creditPolicyValueType === CreditPolicyValueType[CreditPolicyValueType.MinSingleInput] || nextProps.item?.creditPolicyValueType === CreditPolicyValueType[CreditPolicyValueType.MaxSingleInput]) {
      nextProps.item.valueTypeOptions.map(
        (item) => {
          singleValueOptions.push({
            minimum: item.minimum,
            maximum: item.maximum,
            passCriteria: item.passCriteria,
          });
        }
      )
      var creditPolicyUseCase = (nextProps.item?.creditPolicyUseCase !== "Both") ?
        nextProps.item?.creditPolicyUseCase : ""
      return { singleValueOptions, creditPolicyUseCase }
    }
  }

  onFormSubmit(fields: ModifyCreditPolicyParameterFields, onReloadFieldsCB: any): boolean {
    try {
      // console.log(fields)
      fields.creditPolicyParameterId = this.props.item?.id
      fields.creditPolicyValueType = this.props.item?.creditPolicyValueType
      fields.creditPolicyUseCase = this.state.creditPolicyUseCase;
      this.setState(
        {
          // errors: appraisalValidationService.validateModifyCreditPolicyForm(fields),
          ownUpdate: true
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  submit = async (
    fields: ModifyCreditPolicyParameterFields,
    onReloadFieldsCB: any
  ) => {
    this.setState({ submitting: true, ownUpdate: true });
    const payload: any = {};
    payload.creditPolicyParameterId = fields.creditPolicyParameterId;
    payload.creditPolicyUseCase = fields.creditPolicyUseCase;
    payload.maxSingleInputValue = Number(fields.maxSingleInputValue);
    payload.minSingleInputValue = Number(fields.minSingleInputValue);
    payload.dropDownOptions = this.state.dropDownOptions;
    payload.rangeOptions = this.state.rangeOptions;
    // console.log("About to submit: ", payload)

    onReloadFieldsCB();
    this.setState({ submitting: false, ownUpdate: true }, () =>
      this.props.togglePatchModal(true, payload)
    )
  }

  onHandleAddDropDown = (id, value, type) => {
    const dropDownOptions = [...this.state.dropDownOptions];
    if (type === "score") {
      dropDownOptions.filter(item => item.identifier === id)[0].score = Number(value)
    }
    else if (type === "passCriteria") {
      var passValue = dropDownOptions?.filter(item => item.name === "Pass")[0]?.passCriteria
      var failValue = dropDownOptions?.filter(item => item.name === "Fail")[0]?.passCriteria
      dropDownOptions.every(option => {
        if (option.name == id && id === "Pass") {
          if (failValue === value || value === false) {
            toast.info(`Pass value cannot Be Equal to Fail Value`, {
              autoClose: 6000,
              type: toast.TYPE.DEFAULT,
              hideProgressBar: false,
            });
          }
          else {
            option.passCriteria = value;
          }
          return false;
        }
        // else if (option.name == id && id === "Review") {
        //   option.passCriteria = value;
        //   return false;
        // }
        else if (option.name == id && id === "Fail") {
          if (passValue === value || value === true) {
            toast.info(`Fail value cannot Be Equal to Pass Value`, {
              autoClose: 6000,
              type: toast.TYPE.DEFAULT,
              hideProgressBar: false,
            });
          }
          else {
            option.passCriteria = value;
          }
          return false;
        }
        return true;
      })
    }
    else if (type === "operatorCriteria") {
      // console.log(id)
      // dropDownOptions.filter(item => item.name === id)[0].passCriteria = Boolean(value)
      dropDownOptions.every(option => {
        if (option.name == id && id === "Pass") {
          option.description = value;
          return false;
        }
        // else if (option.name == id && id === "Review") {
        //   option.description = value;
        //   return false;
        // }
        else if (option.name == id && id === "Fail") {
          option.description = value;
          return false;
        }
        return true;
      })
    }
    this.setState({ dropDownOptions, ownUpdate: true })
  }

  onHandleAddRange = (id, value, type) => {
    const rangeOptions = [...this.state.rangeOptions];
    // console.log(id)
    // const rangeOptions = [...this.state.singleValueOptions];
    if (type === "score") {
      rangeOptions.filter(item => (item.minimum + " - " + item.maximum) === id)[0].score = Number(value)
    }
    else if (type === "passCriteria") {
      rangeOptions.every(option => {
        if (option.name == id && id === "Pass") {

          option.minimum = value;
          option.maximum = value;
          option.passCriteria = "true";
          // this.setState({ submitting: false });
          return false;
        }
        if (option.name == id && id === "Fail") {

          option.minimum = value;
          option.maximum = value;
          option.passCriteria = "false";
          // this.setState({ submitting: false });
          return false;
        }
        // if (option.name == id && id === "Review") {

        //   option.minimum = value;
        //   option.maximum = value;
        //   // this.setState({ submitting: false });
        //   return false;
        // }
        // this.setState({flatPerApprovedLoan:flatPerApprovedLoan_})
        // Make sure you return true. If you don't return a value, `every()` will stop.
        return true;
      })
    }
    else if (type === "operatorCriteria") {
      // console.log(rangeOptions);
      rangeOptions.every(option => {
        if (option.name == id && id === "Pass") {

          option.operator = value;
          // this.setState({ submitting: false });
          return false;
        }
        if (option.name == id && id === "Fail") {

          option.operator = value;
          // this.setState({ submitting: false });
          return false;
        }
        // if (option.name == id && id === "Review") {

        //   option.operator = value;
        //   // this.setState({ submitting: false });
        //   return false;
        // }
        // this.setState({flatPerApprovedLoan:flatPerApprovedLoan_})
        // Make sure you return true. If you don't return a value, `every()` will stop.
        return true;
      })
    }
    else {
      rangeOptions.filter(item => (item.minimum + " - " + item.maximum) === id)[0].passCriteria = value
    }
    this.setState({ rangeOptions, ownUpdate: true })
  }

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      ownUpdate: true
    });
  }

  handleOnChangeCreditPolicyUseCase = async (id, value) => {
    this.setState({ creditPolicyUseCase: value, ownUpdate: true })
  };

  render() {
    const { item, showPatchModal, togglePatchModal } = this.props;
    const { validated, submitting, errors, dropDownOptions, rangeOptions, singleValueOptions, creditPolicyUseCase } = this.state;

    if (_.isEmpty(item)) {
      return null;
    }

    let CreditPolicyUseCaseInputData: ISelectItems[] = Object.keys(CreditPolicyUseCase)
      .filter((value) => isNaN(Number(value)) === false)
      .map((key) => ({
        name: CreditPolicyUseCase[key],
        value: CreditPolicyUseCase[key],
      }));

    return (
      <Modal
        size="lg"
        backdrop="static"
        show={showPatchModal}
        onHide={togglePatchModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-edit fa-sm cyan-text mr-3" />
              Assign Credit Policy - {item.name}
            </h5>
          </Modal.Title>
        </Modal.Header>
        {(() => {
          switch (item.creditPolicyValueType) {
            case CreditPolicyValueType[CreditPolicyValueType.MaxSingleInput]:
            case CreditPolicyValueType[CreditPolicyValueType.MinSingleInput]:
              return (
                <ModifyCreditPolicyParameterForm
                  initialValues={{
                    creditPolicyParameterId: "",
                    maxSingleInputValue: "",
                    minSingleInputValue: "",
                    creditPolicyUseCase: "",
                  }}
                  FormComponent={({
                    fields: {
                      creditPolicyParameterId,
                      maxSingleInputValue,
                      minSingleInputValue,
                      creditPolicyUseCase
                    },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <React.Fragment>
                      <Modal.Body>
                        <div className="card">
                          <div className="card-header clear-fix">
                            {_.isEmpty(item) ? (
                              <Skeleton width={200} />
                            ) : (
                              <h6
                                className="card-title float-left"
                                style={{ marginBottom: "0px" }}
                              >
                                <b>Credit Policy Criteria</b>
                              </h6>
                            )}
                          </div>
                          <div className="card-body">
                            <div className="form-row mt-3">
                              {(() => {
                                switch (item.creditPolicyValueType) {
                                  case CreditPolicyValueType[CreditPolicyValueType.MaxSingleInput]:
                                    return (
                                      <FormInputComponent
                                        id="maxSingleInputValue"
                                        type="number"
                                        name="maxSingleInputValue"
                                        divClass={6}
                                        placeholder=""
                                        value={maxSingleInputValue}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={onChange}
                                      />
                                    )
                                  case CreditPolicyValueType[CreditPolicyValueType.MinSingleInput]:
                                    return (
                                      <FormInputComponent
                                        id="minSingleInputValue"
                                        type="number"
                                        name="minSingleInputValue"
                                        divClass={6}
                                        placeholder=""
                                        value={minSingleInputValue}
                                        required={true}
                                        validated={validated}
                                        errors={errors}
                                        onChange={(id, value) => this.onHandleAddRange(id, value, "singleValue")}
                                      />
                                    )
                                  default:
                                    return null
                                }
                              })()}
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          size="sm"
                          variant="secondary"
                          onClick={(e) => {
                            onHandleSubmit(e, ActionTypes.UPDATE, "Modify Credit Policy Parameter", () => {
                              this.onFormSubmit({
                                creditPolicyParameterId,
                                maxSingleInputValue,
                                minSingleInputValue,
                                creditPolicyUseCase
                              },
                                onReloadFields
                              );
                            });
                          }}
                          disabled={submitting}
                        >
                          {submitting === false ? (
                            <React.Fragment>
                              <i className="fas fa-lg fa-save mr-3" /> Save
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <i className="fas fa-spin fa-circle-notch" />
                            </React.Fragment>
                          )}
                        </Button>
                        <Button
                          size="sm"
                          variant="outline-danger"
                          onClick={() => togglePatchModal(false, this.props.item)}
                          disabled={submitting}
                        >
                          <i className="fas fa-times mr-3" />
                          Close
                        </Button>
                      </Modal.Footer>
                    </React.Fragment>
                  )}
                >
                </ModifyCreditPolicyParameterForm>
              )
          }
        })()}

        {(() => {
          switch (item.creditPolicyValueType) {
            case CreditPolicyValueType[CreditPolicyValueType.DropDownOptions]:
            case CreditPolicyValueType[CreditPolicyValueType.Range]:
              return (
                <>
                  <Modal.Body>
                    <div className="card">
                      {/* {console.log(rangeOptions)}  */}
                      <div className="card-header clear-fix">
                        {_.isEmpty(item) ? (
                          <Skeleton width={200} />
                        ) : (
                          <h6
                            className="card-title float-left"
                            style={{ marginBottom: "0px" }}
                          >
                            <b>Credit Policy Criteria</b>
                          </h6>
                        )}
                      </div>
                      <div className="card-body">
                        <div className="form-row">
                          {item.creditPolicyUseCase === "Both" ? (
                            <SelectInputComponent
                              id="creditPolicyUseCase"
                              name="creditPolicyUseCase"
                              divClass={6}
                              value={creditPolicyUseCase}
                              items={CreditPolicyUseCaseInputData}
                              required={false}
                              validated={validated}
                              errors={errors}
                              onChange={this.handleOnChangeCreditPolicyUseCase}
                            />
                          ) : ("")}
                        </div>

                        {creditPolicyUseCase !== "" ? (
                          <div className="form-row mt-3">
                            {(() => {
                              switch (item.creditPolicyValueType) {
                                case CreditPolicyValueType[CreditPolicyValueType.DropDownOptions]:
                                  return (
                                    <div className="form-row">
                                      {console.log(dropDownOptions)}
                                      {creditPolicyUseCase === CreditPolicyUseCase[CreditPolicyUseCase.ScoringIndicator] ? (
                                        (dropDownOptions).map((option) => (
                                          <FormInputComponent
                                            key={generateGUID()}
                                            id={option.identifier}
                                            type="number"
                                            name={option.name}
                                            divClass={6}
                                            placeholder=""
                                            value={(option.score).toString()}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={(id, value) => this.onHandleAddDropDown(id, value, "score")}
                                          />
                                        ))
                                      ) : (
                                        (dropDownOptions).map((option) => (
                                          <Fragment key={option.name}>
                                            <SelectInputComponent
                                              // key={option.name}
                                              id={option.name}
                                              name={option.name}
                                              divClass={6}
                                              value={option.passCriteria.toString()}
                                              items={[
                                                { name: "Yes", value: true },
                                                { name: "No", value: false }
                                              ]}
                                              required={false}
                                              validated={validated}
                                              errors={errors}
                                              onChange={async (id, value) => this.onHandleAddDropDown(id, value, "passCriteria")} />
                                            {/* <SelectInputComponent
                                              // key={option.name + " Operators"}
                                              label={option.name + " Operators"}
                                              id={option.name}
                                              name={option.name}
                                              divClass={6}
                                              value={option?.description?.toString()}
                                              items={[
                                                { name: "Greater Than", value: ">" },
                                                { name: "Less Than", value: "<" },
                                                { name: "Greater Than or Equal", value: ">=" },
                                                { name: "Less Than or Equal", value: "=<" },
                                                { name: "Equal", value: "=" }
                                              ]}
                                              required={true}
                                              validated={validated}
                                              errors={errors}
                                              onChange={(id, value) => this.onHandleAddDropDown(id, value, "operatorCriteria")} /> */}
                                          </Fragment>
                                        ))
                                      )}
                                    </div>
                                  )
                                case CreditPolicyValueType[CreditPolicyValueType.Range]:
                                  return (
                                    <div className="form-row">
                                      {creditPolicyUseCase === CreditPolicyUseCase[CreditPolicyUseCase.ScoringIndicator] ? (
                                        (rangeOptions).map((option) => (
                                          <FormInputComponent
                                            key={option.minimum + " - " + option.maximum}
                                            id={option.minimum + " - " + option.maximum}
                                            type="number"
                                            name={option.minimum + " - " + option.maximum + " " + item.valueTypeQuantifier}
                                            divClass={6}
                                            placeholder=""
                                            value={(option.score).toString()}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={(id, value) => this.onHandleAddRange(id, value, "score")}
                                          />
                                        ))
                                      ) : (
                                        (rangeOptions).map((option) => (

                                          <Fragment key={option.name}><FormInputComponent
                                            label={option.name}
                                            // key={option.name}
                                            id={option.name}
                                            type="number"
                                            name={option.name}
                                            divClass={6}
                                            placeholder=""
                                            value={(option.minimum)}
                                            required={false}
                                            validated={validated}
                                            errors={errors}
                                            onChange={(id, value) => this.onHandleAddRange(id, value, "passCriteria")} />
                                            <SelectInputComponent
                                              // key={option.name + " Operators"}
                                              label={option.name + " Operators"}
                                              id={option.name}
                                              name={option.name}
                                              divClass={6}
                                              value={option?.operator?.toString()}
                                              items={[
                                                { name: "Greater Than", value: ">" },
                                                { name: "Less Than", value: "<" },
                                                { name: "Greater Than or Equal", value: ">=" },
                                                { name: "Less Than or Equal", value: "=<" },
                                                { name: "Equal", value: "=" }
                                              ]}
                                              required={false}
                                              validated={validated}
                                              errors={errors}
                                              onChange={(id, value) => this.onHandleAddRange(id, value, "operatorCriteria")} /></Fragment>
                                        ))
                                      )}
                                    </div>
                                  )

                                default:
                                  return null
                              }
                            })()}
                          </div>
                        ) : ("")}
                      </div>
                    </div>
                  </Modal.Body>
                  <ModifyCreditPolicyParameterForm
                    initialValues={{
                      creditPolicyParameterId: "",
                      creditPolicyUseCase: "",
                      maxSingleInputValue: "",
                      minSingleInputValue: ""
                    }}
                    FormComponent={({
                      fields: {
                        creditPolicyParameterId,
                        creditPolicyUseCase,
                        maxSingleInputValue,
                        minSingleInputValue
                      },
                      onChange,
                      onReloadFields,
                      onHandleSubmit,
                    }) => (
                      <React.Fragment>
                        <Modal.Footer>
                          <Button
                            size="sm"
                            variant="secondary"
                            onClick={(e) => {
                              onHandleSubmit(e, ActionTypes.UPDATE, "Modify Credit Policy Parameter", () => {
                                this.onFormSubmit({
                                  creditPolicyParameterId,
                                  creditPolicyUseCase,
                                  maxSingleInputValue,
                                  minSingleInputValue
                                },
                                  onReloadFields
                                );
                              });
                            }}
                            disabled={submitting}
                          >
                            {submitting === false ? (
                              <React.Fragment>
                                <i className="fas fa-lg fa-save mr-3" /> Save
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <i className="fas fa-spin fa-circle-notch" />
                              </React.Fragment>
                            )}
                          </Button>
                          <Button
                            size="sm"
                            variant="outline-danger"
                            onClick={() => togglePatchModal(false, this.props.item)}
                            disabled={submitting}
                          >
                            <i className="fas fa-times mr-3" />
                            Close
                          </Button>
                        </Modal.Footer>
                      </React.Fragment>
                    )}
                  ></ModifyCreditPolicyParameterForm>
                </>
              )
          }
        })()}
      </Modal>
    );
  }
}

export default ModifyCreditPolicyParameters;
