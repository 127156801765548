import http from "../httpService";


const sweepBaseAddress = process.env.REACT_APP_SWEEP_BASEADDRESS;

export function updateProactiveSweepConfig(payload) {
  return http.post(
    sweepBaseAddress + `GeneralSettings/UpdateProactiveSweepConfig`,
    payload
  );
}

export function retrieveGeneralSettings() {
  return http.get(
    sweepBaseAddress + `GeneralSettings/RetrieveGeneralSettings`
  );
}

export default {
  updateProactiveSweepConfig,
  retrieveGeneralSettings
};
