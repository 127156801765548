import React, { Component, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import { ActionTypes } from "../../../enums/actionTypes";
import swal from "sweetalert";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import regionsService from "../../../services/auth/regionsService";
import { toast } from "react-toastify";
import { getCurrentUsersRoles } from "../../../services/auth/authService";

interface IRegionDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  showTrail: any;
  currentUserRoles: any;
  item: any;
}

interface IRegionDetailState {
  item: any;
  updatingStatus: boolean;
  clientip: any;
//   assignedBranches: number[];
}

class RegionDetail extends Component<IRegionDetailProps, IRegionDetailState> {
  state = {
    item: {},
    updatingStatus: false,
    clientip: {},
    // assignedBranches: []
  };

  static getDerivedStateFromProps(nextProps: IRegionDetailProps, prevState) {
    // console.log("current props: ", nextProps.item);
    return {
      item: nextProps.item,
    };
  }

  onHandleEnableOrDisable = (
    e: any,
    actionType: ActionTypes,
    entityName: string
  ) => {
    this.setState({ updatingStatus: true });
    if (!actionType) {
      this.handleEnableOrDisableItem(this.props.item);
    } else {
      swal({
        title: "Confirm!",
        text: `Are you sure you want to proceed to ${ActionTypes[actionType]} this ${entityName}?`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode: true,
      }).then((resp) => {
        if (resp) {
          // console.log("cancelling action...");
          this.setState({ updatingStatus: false });
        } else {
          this.handleEnableOrDisableItem(this.state.item);
        }
      });
    }
  };

  handleEnableOrDisableItem = async (_item: any) => {
    try {
      let approvalResponse: any = {};
      // this.setState({ updatingStatus: true });
      await activityTrailService.saveActivityTrail({
        actionType: `${_item.status === "Inactive" ? "Enable" : "Disable"
          } Region`,
        description: `Clicked on ${_item.status === "Inactive" ? "Enable" : "Disable"
          } to ${_item.status === "Inactive" ? "Enable" : "Disable"} Region ${_item.name
          }`,
      });
      switch (_item.status) {
        case "Inactive":
          approvalResponse = await regionsService.enableRegion(_item.id);
          if (approvalResponse.data.isLoggedForApproval === false) {
            _item.status = "Active";
          }
          break;
        case "Active":
          approvalResponse = await regionsService.disableRegion(_item.id);
          if (approvalResponse.data.isLoggedForApproval === false) {
            _item.status = "Inactive";
          }
          break;
        default:
          approvalResponse = await regionsService.enableRegion(_item.id);
          if (approvalResponse.data.isLoggedForApproval === false) {
            _item.status = "Active";
          }
          break;
      }
      if (approvalResponse.data.isLoggedForApproval === true) {
        toast.warn(approvalResponse.data.message, {
          autoClose: 6000,
          type: toast.TYPE.WARNING,
          position: "top-center",
          hideProgressBar: false,
        });
      } else {
        toast.info(
          `Region ${_item.name} ${_item.status === "Active" ? "Enabled" : "Disabled"
          } successfully! `,
          {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          }
        );
      }
      this.setState({ updatingStatus: false, item: _item });
    } catch (ex) {
      this.setState({ updatingStatus: false, item: _item });
    }
  };

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      showTrail,
      currentUserRoles,
      item,
    } = this.props;
    const { updatingStatus } = this.state;
    
    console.log("Region Item :: ", item)

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="icon icon-building-o s-18 mr-3" />
                Region Details -{" "}
                <span className="font-weight-bold">{item.name}</span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
              <div className="float-right">
                {_.isEmpty(item) ? (
                  <Skeleton width={100} />
                ) : (
                  <span
                    className="badge btn-primary"
                    onClick={showTrail}
                    style={{
                      cursor: "pointer",
                      visibility: currentUserRoles.includes(
                        "viewaudittrailitems"
                      )
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    View Trail
                  </span>
                )}
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem label="Name" value={item.name} />
                      <DetailItem
                        label="Description"
                        value={item.description}
                      />
                      <DetailItem label="Address" value={item.address} />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={2} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem label="Code" value={item.code} />
                      <DetailItem
                        label="Status"
                        value={
                          (item ? item.status : "Active") === "Active" ? (
                            <span className="badge badge-success">
                              {item ? item.status : ""}
                            </span>
                          ) : (
                            <span className="badge badge-danger">
                              {item ? item.status : ""}
                            </span>
                          )
                        }
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>
            
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>Branches</b>
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  {_.isEmpty(item.branches) ? (
                    <div style={{ textAlign: "center", color: "#FE825E" }}>
                      No Branch has been assigned to this Region
                    </div>
                  ) : (
                    <Fragment>
                      {item.branches.map(
                        (branch) => (
                          <div
                            className="custom-control custom-checkbox"
                            style={{ display: 'block' }}
                            key={branch.id}
                          >
                            <input
                              id={branch.id}
                              name={branch.id}
                              className="custom-control-input"
                              type="checkbox"
                              checked={true}
                              disabled={true}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={branch.id}
                            >
                              { branch.name }
                            </label>
                          </div>
                        )
                      )}
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
              {item.status === "Inactive" &&
                getCurrentUsersRoles().includes("enablebranch") && (
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    disabled={updatingStatus}
                    onClick={(e) =>
                      this.onHandleEnableOrDisable(
                        e,
                        item.status === "Inactive"
                          ? ActionTypes.ENABLE
                          : ActionTypes.DISABLE,
                        "Region"
                      )
                    }
                  >
                    <i className="far fa-edit mr-3" />
                    Enable
                  </Button>
                )}
              {item.status === "Active" &&
                getCurrentUsersRoles().includes("disablebranch") && (
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    disabled={updatingStatus}
                    onClick={(e) =>
                      this.onHandleEnableOrDisable(
                        e,
                        item.status === "Inactive"
                          ? ActionTypes.ENABLE
                          : ActionTypes.DISABLE,
                        "Region"
                      )
                    }
                  >
                    <i className="far fa-edit mr-3" />
                    Disable
                  </Button>
                )}
              {getCurrentUsersRoles().includes("editbranch") && (
                <Button
                  size="sm"
                  variant="primary"
                  disabled={updatingStatus}
                  onClick={toggleEditModal}
                >
                  <i className="far fa-edit mr-3" />
                  Edit
                </Button>
              )}
            </React.Fragment>
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default RegionDetail;