import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import { getCurrentUsersRoles } from "../../../services/auth/authService";
import textFormatService, { splitAtUpper } from "../../../services/utility/textFormatService";

interface ILoanCategoryDetailProps {
    showDetailsModal: boolean;
    toggleDetailsModal: any;
    toggleEditModal: any;
    item: any;
}

interface ILoanCategoryDetailState {
    item: any;
    updatingStatus: boolean;
}

class LoanCategoryDetail extends Component<
    ILoanCategoryDetailProps,
    ILoanCategoryDetailState
> {
    state = {
        item: {},
        updatingStatus: false,
    };

    static getDerivedStateFromProps(nextProps: ILoanCategoryDetailProps, prevState) {
        return {
            item: nextProps.item,
        };
    }

    render() {
        const {
            showDetailsModal,
            toggleDetailsModal,
            toggleEditModal,
            item,
        } = this.props;
        const { updatingStatus } = this.state;

        console.log(item)

        return (
            <Modal
                size="xl"
                backdrop="static"
                show={showDetailsModal}
                onHide={toggleDetailsModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {_.isEmpty(item) ? (
                            <Skeleton width={500} />
                        ) : (
                            <h5>
                                <i className="icon icon--columns s-18 mr-3" />
                                Loan Category Details -{" "}
                                <span className="font-weight-bold">{item.name}</span>
                            </h5>
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card">
                        <div className="card-header clear-fix">
                            {_.isEmpty(item) ? (
                                <Skeleton width={200} />
                            ) : (
                                <h6
                                    className="card-title float-left"
                                    style={{ marginBottom: "0px" }}
                                >
                                    <b>General Information</b>
                                </h6>
                            )}
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    {_.isEmpty(item) ? (
                                        <Skeleton count={3} width={300} />
                                    ) : (
                                        <dl className="row">
                                            <DetailItem
                                                label="Name"
                                                value={item.name}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem
                                                label="Loan Product"
                                                value={item.loanProductName}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem
                                                label="Eligible Loan Limit"
                                                value={textFormatService.formatCurrency(item.loanLimit, 2)}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem
                                                label="Eligible Loan Tenor"
                                                value={item.loanTenor}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem
                                                label="Loan Interest Rate"
                                                value={item.interestRate + " %"}
                                                labelSize={5}
                                                valueSize={7}
                                            />



                                        </dl>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mt-3">
                        <div className="card-header clear-fix">
                            {_.isEmpty(item) ? (
                                <Skeleton width={200} />
                            ) : (
                                <h6
                                    className="card-title float-left"
                                    style={{ marginBottom: "0px" }}
                                >
                                    <b>KnockOff Rules</b>
                                </h6>
                            )}
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    {_.isEmpty(item) ? (
                                        <Skeleton count={3} width={300} />
                                    ) : (
                                        <dl className="row">
                                            {item.knockOffRules?.map(x => (
                                                <DetailItem
                                                    label={x.rule}
                                                    value={x.value}
                                                    labelSize={5}
                                                    valueSize={7}
                                                />
                                            ))}


                                        </dl>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="card mt-3">
                        <div className="card-header clear-fix">
                            {_.isEmpty(item) ? (
                                <Skeleton width={200} />
                            ) : (
                                <h6
                                    className="card-title float-left"
                                    style={{ marginBottom: "0px" }}
                                >
                                    <b>Classification Rules</b>
                                </h6>
                            )}
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    {_.isEmpty(item) ? (
                                        <Skeleton count={3} width={300} />
                                    ) : (
                                        <dl className="row">
                                            {item.classificationRules?.map(
                                                (tab) => (
                                                    <div key={tab} style={{ marginTop: '3px' }}>
                                                        <div
                                                            className="custom-control custom-checkbox"
                                                            key={tab}
                                                        >
                                                            <input
                                                                id={tab}
                                                                name={tab}
                                                                className="custom-control-input"
                                                                type="checkbox"
                                                                checked={true}
                                                                disabled={true}
                                                            />
                                                            <label
                                                                className="custom-control-label pt-1"
                                                                htmlFor={tab}
                                                            >
                                                                {tab}
                                                            </label>
                                                        </div>
                                                    </div>
                                                )
                                            )}


                                        </dl>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {_.isEmpty(item) ? (
                        <Skeleton width={100} count={2} height={30} />
                    ) : (
                        <React.Fragment>

                            <Button
                                size="sm"
                                variant="primary"
                                disabled={updatingStatus}
                                onClick={toggleEditModal}
                            >
                                <i className="far fa-edit mr-3" />
                                Edit
                            </Button>

                        </React.Fragment>
                    )}
                    <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={toggleDetailsModal}
                    >
                        <i className="fas fa-times mr-3" />
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default LoanCategoryDetail;
