import React from "react";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import BaseFormComponent, { IBaseFormState } from "../../../../components/BaseFormComponent";
import creditAssessmentValidationService from "../../../../services/creditAssessment/creditAssessmentValidationService";
import { ISelectItems } from "../../../../components/formSelectComponent";
import DetailItem from "../../../../components/detailItem";
import DetailItemInputComponent from "../../../../components/detailItemInputBox";
import { ActionTypes } from "../../../../enums/actionTypes";
import DetailItemSelectComponent from "../../../../components/detailItemSelectBox";
import feeConfigService from "../../../../services/creditAssessment/feeConfigService";
import blackboxInstitutionService from "../../../../services/creditAssessment/blackboxInstitutionService";
import activityTrailService from "../../../../services/auditTrail/activityTrailService";

interface EditOcmInstitutionFields {
  ussdCode: string;
  flatRatePerRequest: string;
  flatRatePerLoanDisbursed: string;
  percentageOfLoanAmount: string;
  minimumPerTransaction: string;
  maximumPerTransaction: string;
  flatRatePerSuccessfulRequest: string;
}

class EditOcmInstitutionForm extends BaseFormComponent<EditOcmInstitutionFields> { }

interface IEditOcmInstitutionProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
  feeConfig: any;
  ocmLoanProducts: any;
  authLoanProducts: any;
}
interface IEditOcmInstitutionState {
  mappedLoanProducts: any;
  ownUpdate: boolean;
}

class EditOcmInstitution extends React.Component<
  IEditOcmInstitutionProps,
  IBaseFormState & IEditOcmInstitutionState
> {
  constructor(props: IEditOcmInstitutionProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      mappedLoanProducts: [],
      ownUpdate: false,
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps: IEditOcmInstitutionProps, prevState: IEditOcmInstitutionState) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    // console.log(nextProps.item)
    return {
      mappedLoanProducts: nextProps.item.loanProducts?.map(prod => ({
        loanProductId: prod.loanProductId,
        institutionLoanProductId: prod.institutionLoanProductId,
      }))
    };
  }

  onFormSubmit(fields: EditOcmInstitutionFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors:
            creditAssessmentValidationService.validateEditOcmInstitutionForm(
              fields
            ),
          ownUpdate: true
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (fields: EditOcmInstitutionFields, onReloadFieldsCB: any) => {
    if (Number(fields.minimumPerTransaction) > Number(fields.maximumPerTransaction)) {
      toast.info(`Minimum Per transaction value cannot be greater than Maximum!`, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      return false;
    }
    // submit blackbox
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const { item } = this.props;

      //find the Maps that have been removed...  find the maps that have been edited...
      //using ocmLoanProducts, create 2 maps from the existing maps, one for remove, the other for edit 
      const existingLPMaps = item.loanProducts;
      const { mappedLoanProducts } = this.state;

      // console.log("existing Maps :: ", existingLPMaps)
      const removedMaps: any = [];
      const editedMaps: any = [];
      const addedMaps: any = [];
      for (var i = 0; i < existingLPMaps.length; i++) {
        if (this.state.mappedLoanProducts.some(mappedLP => {
          if (mappedLP.loanProductId === existingLPMaps[i].loanProductId) {
            if (mappedLP.institutionLoanProductId !== existingLPMaps[i].institutionLoanProductId) {
              removedMaps.push(existingLPMaps[i])
              addedMaps.push(mappedLP)
            }
            return true;
          }
          return false;
        })) {
          //add to edited
          editedMaps.push(existingLPMaps[i])
        } else {
          //add to removed
          removedMaps.push(existingLPMaps[i])
        }
      }

      //find new maps
      var newLPMaps = mappedLoanProducts.filter((mappedLP) => !editedMaps.some(edited => edited.loanProductId === mappedLP.loanProductId));
      // console.log("New Maps :: ", newLPMaps)
      const addedLPMaps = addedMaps.concat(newLPMaps)

      // console.log("Removed Maps :: ", removedMaps)
      // console.log("Edited Maps :: ", editedMaps)
      // console.log("Added Maps :: ", addedLPMaps)
      // remove maps first,
      if (removedMaps.length > 0) {
        const removePayload = removedMaps.map(prod => ({
          loanProductId: prod.loanProductId,
          institutionLoanProductId: prod.institutionLoanProductId,
        }))
        var removeResponse = await blackboxInstitutionService.removeLoanproductsFromBlackboxInstitution(item.id, removePayload)
        // console.log("Removed Payload :: ", removePayload)
      }
      // then edit.
      const payload: any = {};
      payload.ussdCode = fields.ussdCode;
      // payload.loanProductListMap = editedMaps;
      var editResponse = await blackboxInstitutionService.editBlackboxInstitution(item.id, payload)
      // console.log("Edited Payload :: ", payload)

      if (addedLPMaps.length > 0) {
        var addResponse = await blackboxInstitutionService.addLoanproductsToBlackboxInstitution(item.id, addedLPMaps)
      }
      // return;

      // //   console.log("For checks::", payload);
      // const response = await OcmInstitutionService.updateOcmInstitution(
      //   payload,
      //   payload.id
      // );
      if (editResponse.status >= 200 && editResponse.status <= 300) {
        // await activityTrailService.saveActivityTrail({
        //   actionType: "Edit Institution",
        //   description: `Edited details for Institution ${fields.productName}`,
        // });

        // toast.info(`Institution ${this.props.item.institutionName} Edited successfully! `, {
        //   autoClose: 6000,
        //   type: toast.TYPE.DEFAULT,
        //   hideProgressBar: false,
        // });
      }
    } catch (error) {
      //   console.log("submission error: ", error);
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // console.log("submission error: ", error.response);
      }
      this.setState({ submitting: false, ownUpdate: true });
      return;
    }

    // submit fees
    try {
      const payload = { ...this.props.feeConfig };
      payload.flatRatePerRequest = Number(fields.flatRatePerRequest);
      payload.flatRatePerLoanDisbursed = Number(fields.flatRatePerLoanDisbursed);
      payload.percentageOfLoanAmount = Number(fields.percentageOfLoanAmount);
      payload.minimumPerTransaction = Number(fields.minimumPerTransaction);
      payload.maximumPerTransaction = Number(fields.maximumPerTransaction);
      payload.flatRatePerSuccessfulRequest = Number(fields.flatRatePerSuccessfulRequest);

      //   console.log("For checks::", payload);
      const response = await feeConfigService.updateFeeConfiguration(
        payload,
        payload.id
      );

      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit OCM Institution",
          description: `Edited details for OCM Institution ${this.props.item.institutionName}`,
        });
        toast.info(`Institution ${this.props.item.institutionName} Edited successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      }
      onReloadFieldsCB();
      this.setState({ submitting: false, ownUpdate: true }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      //   console.log("submission error: ", error);
      if (error.response.status === 409) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        // console.log("error: ", error.response);
        toast.info(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else {
        // console.log("submission error: ", error.response);
      }
      this.setState({ submitting: false, ownUpdate: true });
    }
  };

  handleOnMapLoanProducts = async (ocmLoanProductId, authLoanProductId) => {
    try {
      const { mappedLoanProducts } = this.state;
      let loanProductsMap = mappedLoanProducts;
      var checker = mappedLoanProducts.filter((x) => x.loanProductId === ocmLoanProductId);
      if (_.isEmpty(checker)) {
        loanProductsMap.push({ loanProductId: ocmLoanProductId, institutionLoanProductId: authLoanProductId });
      } else {
        if (authLoanProductId == "") {
          _.remove(loanProductsMap, function (n: any) { return n.loanProductId === ocmLoanProductId; })
        } else {
          loanProductsMap = mappedLoanProducts.map(p =>
            p.loanProductId === ocmLoanProductId
              ? { ...p, institutionLoanProductId: authLoanProductId }
              : p
          );
        }
      }
      this.setState({ mappedLoanProducts: loanProductsMap, ownUpdate: true });

    } catch (error) {
      toast.info("Something went wrong.", {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      // console.log(error)
      // toast.info(error, {
      //   autoClose: 6000,
      //   type: toast.TYPE.ERROR,
      //   hideProgressBar: false,
      // });
    }
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      errors: {},
    });
  }

  render() {
    const { item, showEditModal, toggleEditModal, authLoanProducts, feeConfig, ocmLoanProducts } = this.props;
    const { validated, submitting, errors, mappedLoanProducts } = this.state;

    // console.log("mapped",mappedLoanProducts)

    let authLoanProductsInputData: ISelectItems[] = authLoanProducts.map((item) => ({
      name: item.name,
      value: item.productId,
    }));

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-columns fa-sm cyan-text mr-3" />
              Edit Institution -{" "}
              <span className="font-weight-bold">{item.institutionName}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditOcmInstitutionForm
          initialValues={{
            ussdCode: item.ussdCode,
            flatRatePerRequest: feeConfig.flatRatePerRequest?.toString(),
            flatRatePerLoanDisbursed: feeConfig.flatRatePerLoanDisbursed?.toString(),
            percentageOfLoanAmount: feeConfig.percentageOfLoanAmount?.toString(),
            minimumPerTransaction: feeConfig.minimumPerTransaction?.toString(),
            maximumPerTransaction: feeConfig.maximumPerTransaction?.toString(),
            flatRatePerSuccessfulRequest: feeConfig.flatRatePerSuccessfulRequest?.toString()
          }}
          FormComponent={({
            fields: { ussdCode, flatRatePerLoanDisbursed, flatRatePerRequest, flatRatePerSuccessfulRequest, maximumPerTransaction, minimumPerTransaction, percentageOfLoanAmount },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    {_.isEmpty(item) ? (
                      <Skeleton width={200} />
                    ) : (
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        <b>General Information</b>
                      </h6>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={2} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Name"
                              value={item.institutionName}
                              labelSize={5}
                              valueSize={7}
                            />
                            <DetailItem
                              label="USSD Code"
                              value={
                                <DetailItemInputComponent
                                  id="ussdCode"
                                  type="text"
                                  name="ussdCode"
                                  placeholder=""
                                  value={ussdCode}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={5}
                              valueSize={7}
                            />
                          </dl>
                        )}
                      </div>
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={2} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Code"
                              value={item.institutionCode}
                              labelSize={5}
                              valueSize={7}
                            />
                            <DetailItem
                              label="Status"
                              value={
                                (item ? item.status : "Active") === "Active" ? (
                                  <span className="badge badge-success">
                                    {item ? item.status : ""}
                                  </span>
                                ) : (
                                  <span className="badge badge-danger">
                                    {item ? item.status : ""}
                                  </span>
                                )
                              }
                              labelSize={5}
                              valueSize={7}
                            />
                          </dl>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <br />
                <div className="card">
                  <div className="card-header">
                    {_.isEmpty(item) ? (
                      <Skeleton width={200} />
                    ) : (
                      <h5 className="card-title" style={{ marginBottom: "0px" }}>
                        Loan Products
                      </h5>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="row">
                      {_.isEmpty(item) ? (
                        <Skeleton count={6} />
                      ) : (
                        <div
                          className="table-responsive text-nowrap"
                          style={{
                            maxHeight: "270px",
                            overflowY: "scroll",
                          }}
                        >
                          <table className="table table-hover table-content table-sm table-striped mb-0">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  style={{
                                    width: "5px",
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>#</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    // width: "5px",
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>OCM Loan Products</strong>
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    // width: "5px",
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Institution Loan Products</strong>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {ocmLoanProducts.length === 0 ? (
                                <tr>
                                  <td
                                    className="font-weight-normal text-truncate"
                                    colSpan={6}
                                    style={{
                                      textAlign: "center",
                                      color: "#FE825E",
                                      fontSize: "small",
                                    }}
                                  >
                                    ...No OCM Products Available
                                  </td>
                                </tr>
                              ) : (
                                ocmLoanProducts.map((product) => (
                                  <tr key={product.id}>
                                    <td
                                      style={{
                                        width: "5px",
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                        fontSize: "small",
                                      }}
                                    >
                                      {ocmLoanProducts.indexOf(product) + 1}
                                    </td>
                                    <td
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                        fontSize: "small",
                                      }}
                                    >
                                      {product.name}
                                    </td>
                                    <td
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                        fontSize: "small",
                                      }}
                                    >
                                      <DetailItemSelectComponent
                                        id="institutionLoanProduct"
                                        name="institutionLoanProduct"
                                        value={mappedLoanProducts?.filter(mappedProduct => mappedProduct.loanProductId == product.id)[0]?.institutionLoanProductId}
                                        items={authLoanProductsInputData}
                                        validated={false}
                                        errors={[]}
                                        onChange={(id, value) =>
                                          this.handleOnMapLoanProducts(product.id, value)
                                        }
                                        disabledOption={(item) =>
                                          mappedLoanProducts?.map(function (x) {
                                            if (x.loanProductId != product.id) {
                                              return x.institutionLoanProductId;
                                            }
                                          })
                                            .includes(item.toString())
                                        }
                                      />
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <br />
                <div className="card">
                  <div className="card-header">
                    {_.isEmpty(item) ? (
                      <Skeleton width={200} />
                    ) : (
                      <h5 className="card-title" style={{ marginBottom: "0px" }}>
                        Fee Configuration
                      </h5>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={5} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Fee Entry Type"
                              value={feeConfig.feeEntryType}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Flat Rate Per Request"
                              value={
                                <DetailItemInputComponent
                                  id="flatRatePerRequest"
                                  type="number"
                                  name="flatRatePerRequest"
                                  placeholder=""
                                  value={flatRatePerRequest}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Minimum Per Transaction"
                              value={
                                <DetailItemInputComponent
                                  id="minimumPerTransaction"
                                  type="number"
                                  name="minimumPerTransaction"
                                  placeholder=""
                                  value={minimumPerTransaction}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Percentage Of Loan Amount"
                              value={
                                <DetailItemInputComponent
                                  id="percentageOfLoanAmount"
                                  type="number"
                                  name="percentageOfLoanAmount"
                                  placeholder=""
                                  value={percentageOfLoanAmount}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Enabled"
                              value={
                                <React.Fragment>
                                  {_.isEmpty(feeConfig) ? (
                                    ""
                                  ) : feeConfig.isEnabled === false ? (
                                    <span className="badge badge-danger">False</span>
                                  ) : (
                                    <span className="badge badge-success">True</span>
                                  )}
                                </React.Fragment>
                              }
                              labelSize={6}
                              valueSize={6}
                            />
                          </dl>
                        )}
                      </div>
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={4} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Fee Category"
                              value={feeConfig.feeCategory}
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Flat Rate Per Loan Disbursed"
                              value={
                                <DetailItemInputComponent
                                  id="flatRatePerLoanDisbursed"
                                  type="number"
                                  name="flatRatePerLoanDisbursed"
                                  placeholder=""
                                  value={flatRatePerLoanDisbursed}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Maximum Per Transaction"
                              value={
                                <DetailItemInputComponent
                                  id="maximumPerTransaction"
                                  type="number"
                                  name="maximumPerTransaction"
                                  placeholder=""
                                  value={maximumPerTransaction}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={6}
                              valueSize={6}
                            />
                            <DetailItem
                              label="Flat Rate Per Successful Request"
                              value={
                                <DetailItemInputComponent
                                  id="flatRatePerSuccessfulRequest"
                                  type="number"
                                  name="flatRatePerSuccessfulRequest"
                                  placeholder=""
                                  value={flatRatePerSuccessfulRequest}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={6}
                              valueSize={6}
                            />
                          </dl>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.UPDATE,
                      "Institution",
                      () => {
                        this.onFormSubmit(
                          { ussdCode, percentageOfLoanAmount, minimumPerTransaction, maximumPerTransaction, flatRatePerSuccessfulRequest, flatRatePerRequest, flatRatePerLoanDisbursed },
                          onReloadFields
                        );
                      }
                    );
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></EditOcmInstitutionForm>
      </Modal>
    );
  }
}

export default EditOcmInstitution;
