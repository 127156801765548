import React, { Fragment, memo } from "react";
import { toast } from "react-toastify";
import { Modal, Button, Accordion, Card } from "react-bootstrap";
import _, { isEmpty } from "lodash";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
    IBaseFormState,
} from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import workFlowConfigService from "../../../services/hybridAppraisal/workFlowConfigService";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../components/detailItem";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import { IMultiSelectItems } from "../../../components/formMultiSelectComponent";
import EditMandatoryFieldConfig from "./editMandatoryFieldConfig";
import { ContextAwareToggle } from "../appraisalPlan/appraisalPlanDetail";
import supplierService from "../../../services/hybridAppraisal/supplierService";
import SelectInputComponent, { ISelectItems } from "../../../components/formSelectComponent";
import DetailItemSelectBox from "../../../components/detailItemSelectBox";
import FormTextAreaComponent from "../../../components/formTextAreaComponent";
import FormInputComponent from "../../../components/formInputComponent";
import hybridAppraisalValidationService from "../../../services/hybridAppraisal/hybridAppraisalValidationService";
import nameEnquiryService from "../../../services/handshake/nameEnquiryService";
import sweepValidationService from "../../../services/sweep/sweepValidationService";
import sweepConfigService from "../../../services/sweep/sweepConfigService";
import commercialBankService from "../../../services/sweep/commercialBankService";
import appraisalInstitutionService from "../../../services/hybridAppraisal/appraisalInstitutionService";
import { string } from "joi";
import { countries } from "../../../services/utility/countries";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../../services/auth/authService";

interface AddSingleEmployerFields {
    name: string;
    address: string;
    country: string;
    state: string;
    city: string
    postcode: string;
    phoneNumber: string;
    email: string;
}

class AddSingleEmployerForm extends BaseFormComponent<AddSingleEmployerFields> { }

interface IAddSingleEmployerProps {
    item: any;
}

interface IAddSingleEmployerState {
    ownUpdate: boolean;
    fetchingRequiredItems: boolean;
    selectedWorkFlowSteps: any;
    selectedDataEntryTabs: any;
    dataEntrySections: any;
    showMandatoryFieldModal: boolean;
    configuredMandatoryFields: boolean;
    bankCode: string;
    accountNumber: string;
    accountName: string;
    commercialBanks: any;
    country: string;
}

class AddSingleEmployer extends React.Component<
    IAddSingleEmployerProps,
    IBaseFormState & IAddSingleEmployerState
> {
    _isMounted = false;
    constructor(props: IAddSingleEmployerProps) {
        super(props);
        this.state = {
            validated: false,
            submitting: false,
            errors: {},
            country: "",
            ownUpdate: false,
            fetchingRequiredItems: false,
            selectedWorkFlowSteps: [],
            selectedDataEntryTabs: [],
            dataEntrySections: [],
            showMandatoryFieldModal: false,
            configuredMandatoryFields: false,
            bankCode: "",
            accountNumber: "",
            accountName: "",
            commercialBanks: []
        };
    }

    onFormSubmit(fields: AddSingleEmployerFields, onReloadFieldsCB: any): boolean {
        try {
            this.setState(
                { errors: hybridAppraisalValidationService.validateAddEmployerDetailsForm(fields), ownUpdate: true }, () => {
                    if (Object.keys(this.state.errors).length === 0) {
                        return this.submit(fields, onReloadFieldsCB);
                    }
                });
            this.setState({ validated: true, ownUpdate: true });
            return false;
        } catch (error) {
            return false;
        }
    }

    submit = async (
        fields: AddSingleEmployerFields,
        onReloadFieldsCB: any
    ) => {
        try {
            this.setState({ submitting: true, ownUpdate: true });
            const payload: any = {};
            const decodedToken = decodeToken(getCurrentUserSession().token || "");

            payload.name = fields.name;
            payload.address = fields.address
            payload.Country = fields.country
            payload.state = fields.state
            payload.city = fields.city
            payload.Postcode = fields.postcode
            payload.phoneNumber = fields.phoneNumber
            payload.email = fields.email
            payload.tier = "Tier 1"

            const response = await appraisalInstitutionService.addEmployer(decodedToken["InstitutionCode"], payload);
            console.log(response)
            if (response.status >= 200 && response.status <= 300) {
                await activityTrailService.saveActivityTrail({
                    actionType: "Add Employer Details",
                    description: `Added details for ${fields.name}`,
                });
                toast.info(`${fields.name} Details Added successfully!`, {
                    autoClose: 6000,
                    type: toast.TYPE.DEFAULT,
                    hideProgressBar: false,
                });
            } else {
                // toast.error(error.response.data, {
                //     autoClose: 6000,
                //     type: toast.TYPE.ERROR,
                //     hideProgressBar: false,
                // });
                return false;
            }
            this.setState({ submitting: false });
            this.resetFields();
            onReloadFieldsCB();
        } catch (error) {
            // console.log("Got here")
            // console.log(error.response.data)
            toast.error(error.response.data.errors.email[0], {
                autoClose: 6000,
                type: toast.TYPE.ERROR,
                hideProgressBar: false,
            });
            this.setState({ submitting: false, ownUpdate: true });
        } finally {
            return true;
        }
    }

    async componentDidMount() {
        this._isMounted = true

        // var commercialBanks = await commercialBankService.retrieveAll();
        // var commercialBanksDetails = commercialBanks.data;

        // this.setState({ commercialBanks: commercialBanksDetails })
    }




    resetFields() {
        this.setState({
            submitting: false,
            ownUpdate: true,
        });
    }

    resetAllFields() {
        this.setState({
            submitting: false,
            ownUpdate: true
        })
    }

    render() {
        const { item } = this.props;
        const {
            validated,
            submitting,
            errors,
        } = this.state;

        let statesInputData: ISelectItems[] = [];
        let countriesInputData: ISelectItems[] = [];
        countries.forEach((country) => {
            countriesInputData.push({ name: country.name, value: country.name });
        });
        countries
            .filter((c) => c.name === String(this.state.country))[0]
            ?.states.forEach((state) => {
                statesInputData.push({ name: state.name, value: state.name });
            });


        // if (_.isEmpty(item)) {
        //     return null
        // }

        return (
            <React.Fragment>
                <div className="container-fluid relative animatedParent animateOnce">
                    <div className="animated fadeInUpShort go">
                        <div className="row my-3 mx-2">
                            <h3>
                                <b>Add Employer</b>
                            </h3>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <AddSingleEmployerForm
                                    initialValues={{
                                        name: "",
                                        address: "",
                                        country: "",
                                        state: "",
                                        city: "",
                                        postcode: "",
                                        phoneNumber: "",
                                        email: "",

                                    }}
                                    FormComponent={({
                                        fields: { name, address, country, state, city, postcode, phoneNumber, email },
                                        onChange,
                                        onReloadFields,
                                        onHandleSubmit,
                                    }) => (
                                        <div className="card no-b">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-12">

                                                        <div className="form-row">
                                                            <FormInputComponent
                                                                label="Employer Name"
                                                                id="name"
                                                                type="text"
                                                                name="name"
                                                                placeholder=""
                                                                value={name}
                                                                divClass={6}
                                                                required={true}
                                                                validated={validated}
                                                                errors={errors}
                                                                onChange={onChange} />
                                                            <FormInputComponent
                                                                // label="Employer Name"
                                                                id="address"
                                                                type="text"
                                                                name="address"
                                                                placeholder=""
                                                                value={address}
                                                                divClass={6}
                                                                required={false}
                                                                validated={validated}
                                                                errors={errors}
                                                                onChange={onChange} />
                                                            <SelectInputComponent
                                                                id="country"
                                                                name="country"
                                                                divClass={6}
                                                                value={this.state.country}
                                                                items={countriesInputData}
                                                                required={false}
                                                                validated={validated}
                                                                errors={errors}
                                                                onChange={async (id, value) => {
                                                                    await onChange(id, value);
                                                                    this.setState({ country: value, ownUpdate: true });
                                                                }}
                                                            />
                                                            <SelectInputComponent
                                                                label="State/Region"
                                                                id="state"
                                                                name="state"
                                                                divClass={6}
                                                                value={state}
                                                                items={statesInputData}
                                                                required={false}
                                                                validated={validated}
                                                                errors={errors}
                                                                onChange={onChange}
                                                            />
                                                            <FormInputComponent
                                                                label="City / Town"
                                                                id="city"
                                                                type="text"
                                                                name="city"
                                                                placeholder=""
                                                                value={city}
                                                                divClass={6}
                                                                required={false}
                                                                validated={validated}
                                                                errors={errors}
                                                                onChange={onChange} />
                                                            <FormInputComponent
                                                                // label="Employer Name"
                                                                id="phoneNumber"
                                                                type="number"
                                                                name="phoneNumber"
                                                                placeholder=""
                                                                value={phoneNumber}
                                                                divClass={6}
                                                                required={false}
                                                                validated={validated}
                                                                errors={errors}
                                                                onChange={onChange} />
                                                            <FormInputComponent
                                                                // label="Employer Name"
                                                                id="email"
                                                                type="email"
                                                                name="email"
                                                                placeholder=""
                                                                value={email}
                                                                divClass={6}
                                                                required={true}
                                                                validated={validated}
                                                                errors={errors}
                                                                onChange={onChange} />
                                                            <FormInputComponent
                                                                // label="Employer Name"
                                                                id="postcode"
                                                                type="number"
                                                                name="postcode"
                                                                placeholder=""
                                                                value={postcode}
                                                                divClass={6}
                                                                required={false}
                                                                validated={validated}
                                                                errors={errors}
                                                                onChange={onChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />

                                            </div>

                                            <div className="card-body">
                                                <button
                                                    type="submit"
                                                    className="btn btn-sm btn-primary"
                                                    disabled={this.state.submitting}
                                                    onClick={(e) => {
                                                        onHandleSubmit(e, ActionTypes.SAVE, "Employer Name", () => {
                                                            this.onFormSubmit(
                                                                { name, address, country, state, city, postcode, phoneNumber, email },
                                                                onReloadFields
                                                            );
                                                        });
                                                    }}
                                                >
                                                    {submitting === false ? (
                                                        <React.Fragment>
                                                            <i className="fas fa-lg fa-save mr-3" /> Save
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <i className="fas fa-spin fa-circle-notch" />
                                                        </React.Fragment>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                ></AddSingleEmployerForm>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default AddSingleEmployer;
