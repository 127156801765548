import http from "../httpService";


const sweepBaseAddress = process.env.REACT_APP_SWEEP_BASEADDRESS;

export function retrieveAll() {
    return http.get(
        sweepBaseAddress + `CommercialBank/RetrieveAll`
    );
}

// export function retrieveGeneralSettings() {
//     return http.get(
//         sweepBaseAddress + `GeneralSettings/RetrieveGeneralSettings`
//     );
// }

export default {
    retrieveAll
};
