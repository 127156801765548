import React from "react";
import { Button, Modal } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import DetailItem from "../../components/detailItem";
import CheckboxTree from "react-checkbox-tree";
import ItemsTable from "../../components/tableComponents/itemsTable";

interface ServiceOfferingConfigDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  showTrail: any;
  item: any;
  availableModules: any;
  checked: any;
}

interface ServiceOfferingConfigDetailState {
  item: any;
  updatingStatus: boolean;
}

class ServiceOfferingConfigDetail extends React.Component<
  ServiceOfferingConfigDetailProps,
  ServiceOfferingConfigDetailState
> {
  constructor(props: ServiceOfferingConfigDetailProps) {
    super(props);
    this.state = { item: {}, updatingStatus: false };
  }

  static getDerivedStateFromProps(
    nextProps: ServiceOfferingConfigDetailProps,
    prevState
  ) {
    // console.log("current props: ", nextProps.item);
    return {
      item: nextProps.item,
    };
  }

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      availableModules,
      toggleEditModal,
      showTrail,
      item,
    } = this.props;
    const { updatingStatus } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="icon icon-all_inclusive cyan-text mr-3" />
                Service Offering Configuration Details -{" "}
                <span className="font-weight-bold">{item.name}</span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-header clear-fix">
                  {_.isEmpty(item) ? (
                    <Skeleton width={200} />
                  ) : (
                    <h6
                      className="card-title float-left"
                      style={{ marginBottom: "0px" }}
                    >
                      <b>General Information</b>
                    </h6>
                  )}
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="form-group col-md-12 row">
                      {_.isEmpty(item) ? (
                        <Skeleton count={1} width={300} />
                      ) : (
                        <DetailItem label="Name" value={item.name} />
                      )}
                    </div>
                    <div className="form-group col-md-12 row">
                      {_.isEmpty(item) ? (
                        <Skeleton count={1} width={300} />
                      ) : (
                        <DetailItem
                          label="Billable Offer"
                          value={item.billableOffer}
                        />
                      )}
                    </div>
                    <div className="form-group col-md-12 row">
                      {_.isEmpty(item) ? (
                        <Skeleton count={1} width={300} />
                      ) : (
                        <DetailItem
                          label="Institution Category"
                          value={item.institutionCategory}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  {_.isEmpty(item) ? (
                    <Skeleton width={200} />
                  ) : (
                    <h6 className="card-title" style={{ marginBottom: "0px" }}>
                      Other Information
                    </h6>
                  )}
                </div>

                <div className="card-body">
                  {_.isEmpty(this.state.item) ? (
                    <Skeleton count={1} height={500} />
                  ) : (
                    <div className="form-group row">
                      <label
                        htmlFor="inputFunctions"
                        className="col-md-3 col-form-label"
                      >
                        <b>Functions:</b>
                      </label>
                      <CheckboxTree
                        icons={{
                          parentClose: (
                            <i className="icon-folder2 orange-text"></i>
                          ),
                          parentOpen: (
                            <i className="icon-folder-open orange-text"></i>
                          ),
                          leaf: <i className="icon-file-text-o blue-text"></i>,
                          halfCheck: <i className="icon-square"></i>,
                          check: <i className="icon-check_box green-text"></i>,
                        }}
                        nodes={availableModules}
                        checked={this.props.checked}
                        // expanded={this.state.expanded}
                        // onCheck={() => console.log("try check")}
                        // onExpand={(expanded) => this.setState({ expanded })}
                        iconsClass="fa5"
                        disabled={true}
                        showExpandAll={true}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={1} height={30} />
          ) : (
            <Button
              size="sm"
              variant="primary"
              disabled={updatingStatus}
              onClick={toggleEditModal}
            >
              <i className="far fa-edit mr-3" />
              Edit
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ServiceOfferingConfigDetail;
