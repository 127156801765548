import React, { Component } from "react";
import _ from "lodash";
import activityTrailService from "../../../../services/auditTrail/activityTrailService";
import { toast } from "react-toastify";
import { decodeToken } from "react-jwt";
import BaseFormComponent, { IBaseFormState } from "../../../../components/BaseFormComponent";
import DetailItem from "../../../../components/detailItem";
import FormInputComponent from "../../../../components/formInputComponent";
import SelectInputComponent, { ISelectItems } from "../../../../components/formSelectComponent";
import { getCurrentUserSession, getCurrentUsersRoles } from "../../../../services/auth/authService";
import textFormatService from "../../../../services/utility/textFormatService";
import DocumentPreviewer from "../../../dispute/documentPreviewer";
import supportingDocumentService, { getSupportingDocumentsByLoanReference } from "../../../../services/mandate/supportingDocumentService";
import { ActionTypes } from "../../../../enums/actionTypes";
import loanRequestService from "../../../../services/hybridAppraisal/loanRequestService";
import hybridAppraisalValidationService from "../../../../services/hybridAppraisal/hybridAppraisalValidationService";

interface DocumentApprovalFields {
    documentCategory: string;
    documentName: string;
    documentStatus: string;
    documentExpiryDate: string;
    documentNewName: string;
}

class DocumentApprovalForm extends BaseFormComponent<DocumentApprovalFields> { }

interface DocumentApprovalProps {
    values: any;
}

interface DocumentApprovalState {
    item: any;
    submitting: boolean;
    validated: boolean;
    showPreview: boolean;
    uploadingDocument: boolean;
    document: any;
    docToUpload: any;
    data: {
        comment: string;
        docToUploadType: string;
    };
    errors: any;
    supportingDocuments: any;
    allDocuments: any;
    ownUpdate: boolean;
    documentType: string;
}

class DocumentApproval extends Component<
    DocumentApprovalProps,
    IBaseFormState & DocumentApprovalState
> {
    constructor(props: DocumentApprovalProps) {
        super(props);
        this.state = {
            item: {},
            submitting: false,
            validated: false,
            showPreview: false,
            uploadingDocument: false,
            document: {},
            docToUpload: {},
            data: {
                comment: "",
                docToUploadType: "",
            },
            errors: {},
            supportingDocuments: [],
            allDocuments: [],
            ownUpdate: false,
            documentType: ""
        };
    }

    static getDerivedStateFromProps(
        nextProps: DocumentApprovalProps,
        prevState: DocumentApprovalState
    ) {
        if (prevState.ownUpdate) {
            return {
                ownUpdate: false,
            };
        }
        if (_.isNull(nextProps.values)) {
            return null;
        }
        return {
            item: nextProps.values
        };
    }

    async fetchSupportingDocument(first) {
        const { values } = this.props;
        // const supportingDocuments = await getSupportingDocumentsByLoanReference(
        //   JSON.parse(JSON.parse(JSON.parse(values.jsonData)).LoanDetails).AccountNumber,
        //   values.id
        // );
        let reference = `hyb_pre_${values.requestIdentifier}`
        let reference2 = `hyb_post_${values.requestIdentifier}`
        let reference3 = `hyb_bs_${values.requestIdentifier}`
        let reference4 = `hyb_sys_${values.requestIdentifier}`
        const response = await Promise.allSettled([
            getSupportingDocumentsByLoanReference(reference, values.id),
            getSupportingDocumentsByLoanReference(reference2, values.id),
            getSupportingDocumentsByLoanReference(reference3, values.id),
            getSupportingDocumentsByLoanReference(reference4, values.id),
        ])
        const supportingDocumentsPre = response[0].status == "fulfilled" ? response[0].value.data : [];
        const supportingDocumentsPost = response[1].status == "fulfilled" ? response[1].value.data : [];
        const supportingDocumentsBs = response[2].status == "fulfilled" ? response[2].value.data : [];
        const supportingDocumentsSys = response[3].status == "fulfilled" ? response[3].value.data : [];
        const supportingDocumentNonBs = supportingDocumentsPre.concat(supportingDocumentsPost)
        const supportingDocuments = supportingDocumentNonBs.concat(supportingDocumentsBs).concat(supportingDocumentsSys)
        if (first) {
            this.setState({ supportingDocuments: supportingDocuments, allDocuments: values.documents ? values.documents : [], ownUpdate: true })
        } else {
            this.setState({ supportingDocuments: supportingDocuments, ownUpdate: true })
        }
    }

    async componentDidMount() {
        await this.fetchSupportingDocument(true)
    }

    onFormSubmit(fields: DocumentApprovalFields, onReloadFieldsCB: any): boolean {
        try {
            this.setState(
                { errors: hybridAppraisalValidationService.validateDocumentCollectionForm(fields, "", []), ownUpdate: true },
                () => {
                    if (Object.keys(this.state.errors).length === 0) {
                        return this.submit(fields, onReloadFieldsCB);
                    }
                }
            );
            this.setState({ validated: true, ownUpdate: true });
            return false;
        } catch (error) {
            return false;
        }
    }

    async submit(
        fields: DocumentApprovalFields,
        onReloadFieldsCB: any
    ): Promise<boolean> {
        try {
            const decodedToken = decodeToken(getCurrentUserSession().token || "");
            const loanRequest = await loanRequestService.retrieveById(this.props.values.id);

            const allDocuments = [...this.state.allDocuments]

            fields.documentName = this.state.documentType === "Others" ? fields.documentNewName : fields.documentName
            fields.documentCategory = this.state.documentType === "Others" ? "Non Mandatory" : fields.documentCategory


            // console.log(fields);

            let reference = `hyb_pre_${this.props.values.requestIdentifier}`
            const payload: any = {};
            payload.institutionId = decodedToken?.["InstitutionId"];
            payload.document = await textFormatService.getBase64StringFromFile(this.state.docToUpload);
            payload.name = this.state.docToUpload.name.split(".")[0];
            // payload.loanReference = JSON.parse(JSON.parse(JSON.parse(this.props.values.jsonData)).LoanDetails).AccountNumber
            payload.loanReference = reference
            payload.customerID = this.props.values.id;
            payload.disputeId = 0;
            payload.supportingDocumentType = "Others";
            payload.disputeConditionDocumentType = this.state.data.docToUploadType;
            payload.extensionType = this.state.docToUpload.name.split(".")[1].toUpperCase();
            this.setState({ uploadingDocument: true, submitting: true, ownUpdate: true });
            // return false;

            var response = await supportingDocumentService.saveSupportingDocument(
                payload
            );
            if (response.status >= 200 && response.status <= 300) {
                allDocuments.push({
                    name: fields.documentName,
                    category: fields.documentCategory,
                    status: fields.documentStatus,
                    expiryDate: fields.documentExpiryDate,
                    documentId: response.data.payLoad.id
                })
                toast.info(`Supporting Document saved successfully!`, {
                    autoClose: 6000,
                    type: toast.TYPE.DEFAULT,
                    hideProgressBar: false,
                });
            }
            else {
                toast.info(`Please retry`, {
                    autoClose: 6000,
                    type: toast.TYPE.DEFAULT,
                    hideProgressBar: false,
                });
            }
            const loanRequestDetail = loanRequest.data;
            const loanPayload: any = { ...loanRequestDetail };
            loanPayload.documents = [...allDocuments]

            const loanResponse = await loanRequestService.updateLoanRequest(loanPayload);
            this.setState({ allDocuments, uploadingDocument: false, submitting: false, ownUpdate: true });

            // activityTrailService.saveActivityTrail({
            //   actionType: "Upload Supporting Document",
            //   description: `Upload Supporting Document on loan request ${this.props.values.id}`,
            // });
            this.resetFields();
        } catch (error) {
            toast.error(error.response.data.Message);
            this.setState({ uploadingDocument: false, submitting: false, ownUpdate: true });
            // return false;
        }
        finally {
            this.fetchSupportingDocument(false)
            return true;
        }
    };

    toggleShowPreview = () => {
        this.setState({ showPreview: !this.state.showPreview, ownUpdate: true });
    };

    onDocumentNameChange = (name) => {
        this.setState({ documentType: name });
    }

    handleOnDocumentSelect = async (document) => {
        try {
            var doc = await supportingDocumentService.retrieveById(document.id);
            document["document"] = doc.data.document;
            switch (document.extensionType) {
                case "PNG":
                case "JPG":
                case "GIF":
                    document[
                        "downloadname"
                    ] = `${document.name}_${document.loanReference}.jpg`;
                    document["data"] = `data:image/jpg;base64,${document.document}`;
                    document["payload"] = (
                        <img
                            src={document.data}
                            alt={document.name}
                            width="100%"
                            height="100%"
                            className="image"
                        />
                    );
                    break;
                case "DOCX":
                case "PDF":
                    var data = `data:application/pdf;base64,${document.document}`;
                    document["payload"] = (
                        <object
                            width="100%"
                            height="500px"
                            data={data}
                            type="application/pdf"
                            className="internal"
                        >
                            <embed src={data} type="application/pdf" />
                        </object>
                    );
                    break;
                case "HTML":
                    document["payload"] = (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: atob(document.document),
                            }}
                        />
                    );
                    break;
                default:
                    break;
            }
            this.setState({ document, showPreview: true, ownUpdate: true });
        } catch (error) {
        }
    };

    generateExtensionIcon = (document) => {
        switch (document.extensionType) {
            case "PNG":
            case "JPG":
            case "GIF":
                return (
                    <i
                        className="fas fa-file-image cyan-text fa-lg"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.handleOnDocumentSelect(document)}
                    ></i>
                );
            case "PDF":
                return (
                    <i
                        className="fas fa-file-pdf red-text fa-lg"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.handleOnDocumentSelect(document)}
                    ></i>
                );
            case "DOC":
            case "DOCX":
                return (
                    <i
                        className="fas fa-file-word blue-text fa-lg"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.handleOnDocumentSelect(document)}
                    ></i>
                );
            default:
                return (
                    <i
                        className="fas fa-file-pdf red-text fa-lg"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.handleOnDocumentSelect(document)}
                    ></i>
                );
        }
    };

    // getRequiredFields(title): boolean {
    //     const { mandatoryFields } = this.props;
    //     var required = mandatoryFields?.inputFields.filter(x => x.fieldName === title)[0].mandatory;
    //     return required;
    // }

    resetFields() {
        const data = { ...this.state.data };
        data.comment = "";
        data.docToUploadType = "";
        this.setState({ data, validated: false, document: {}, docToUpload: {}, submitting: false, errors: [], ownUpdate: true });
    }

    handleDeleteDocument = async (document) => {
        try {
            this.setState({ uploadingDocument: true, submitting: true, ownUpdate: true });
            const decodedToken = decodeToken(getCurrentUserSession().token || "");
            var response = await supportingDocumentService.deleteSupportingDocument(
                document.id
            );
            activityTrailService.saveActivityTrail({
                actionType: "Remove Supporting Document",
                description: `Remove Supporting Document [${document.name}] on Loan Request ${this.props.values.id}`,
            });
            this.setState({ uploadingDocument: false, submitting: false, ownUpdate: true });
            this.resetFields();
        } catch (error) {
            toast.error(error.response.data.Message);
            this.setState({ uploadingDocument: false, submitting: false, ownUpdate: true });
        }
        finally {
            this.fetchSupportingDocument(false)
        }
    };

    handleUploadDocument = async (fields: DocumentApprovalFields) => {
        try {
            const decodedToken = decodeToken(getCurrentUserSession().token || "");
            const payload: any = {};
            payload.institutionId = decodedToken?.["InstitutionId"];
            payload.document = await textFormatService.getBase64StringFromFile(this.state.docToUpload);
            payload.name = this.state.docToUpload.name.split(".")[0];
            payload.loanReference = JSON.parse(JSON.parse(JSON.parse(this.props.values.jsonData)).LoanDetails).AccountNumber
            payload.customerID = this.props.values.id;
            payload.disputeId = 0;
            payload.supportingDocumentType = "Others";
            payload.disputeConditionDocumentType = this.state.data.docToUploadType;
            payload.extensionType = this.state.docToUpload.name.split(".")[1].toUpperCase();
            this.setState({ uploadingDocument: true, submitting: true, ownUpdate: true });
            try {
                var response = await supportingDocumentService.saveSupportingDocument(
                    payload
                );
            } catch (error) {
                throw error;
            }
            activityTrailService.saveActivityTrail({
                actionType: "Upload Supporting Document",
                description: `Upload Supporting Document on loan request ${this.props.values.id}`,
            });
            this.setState({ uploadingDocument: false, submitting: false, ownUpdate: true });
            this.resetFields();
        } catch (error) {
            toast.error(error.response.data.Message);
            this.setState({ uploadingDocument: false, submitting: false, ownUpdate: true });
        }
        finally {
            this.fetchSupportingDocument(false)
        }
    };

    onDocumentChange = (e) => {
        e.preventDefault();
        if (e.target.files[0] === undefined) {
            return;
        }
        if (e.target.files[0].size > 3000000) {
            toast.error(
                "Please confirm that the size of the document is less than 3MB"
            );
        } else if (
            !["docx", "doc", "pdf", "jpg", "png"].includes(
                e.target.files[0].name.split(".")[1]
            )
        ) {
            toast.error(
                "Please confirm that the document is a valid word document, pdf or image"
            );
        } else {
            this.setState({ docToUpload: e.target.files[0], ownUpdate: true });
        }
    };

    render() {
        const {
            values,
        } = this.props;
        const {
            validated,
            data,
            errors,
            submitting,
            docToUpload,
            uploadingDocument,
            supportingDocuments,
            allDocuments,
            ownUpdate
        } = this.state;

        // console.log(values)

        const productDocuments = values.loanProduct.productDocuments;
        // productDocuments.push({ name: "others", category: "Non Mandatory" })

        let documentNameInputData: ISelectItems[] = productDocuments ? productDocuments.map(item => {
            return { name: item.name, value: item.name }
        }) : [];

        const processedDocNames: any[] = [];
        documentNameInputData.forEach(empName => {

            processedDocNames.push({ name: empName.name, value: empName.value });

        });
        processedDocNames.push({ name: "Others", value: "Others" })
        // console.log(processedDocNames)

        const documentStatusInputData: ISelectItems[] = ["Received", "Deferred", "Waived"].map(item => {
            return { name: item, value: item }
        });


        return (
            <DocumentApprovalForm
                initialValues={{
                    documentCategory: "",
                    documentName: "",
                    documentStatus: "",
                    documentExpiryDate: "",
                    documentNewName: ""
                }}
                FormComponent={({
                    fields: {
                        documentCategory,
                        documentName,
                        documentStatus,
                        documentExpiryDate,
                        documentNewName
                    },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                }) => (
                    <div className="form-row">
                        <div className="col-md-12">
                            <div className="form-row">
                                <div className="row my-3 mx-2">
                                    <h3>
                                        <b>Document Approval</b>
                                    </h3>
                                </div>

                                {/* Add Document */}

                                {/* Document Table */}
                                <div className=" form-row col-md-12">
                                    <div
                                        className="table-responsive text-nowrap"
                                        style={{
                                            maxHeight: "150px",
                                            overflowY: "scroll",
                                        }}
                                    >
                                        <table className="table table-hover table-content table-sm table-striped mb-0">
                                            <thead>
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        style={{
                                                            paddingTop: "0.2rem",
                                                            paddingBottom: "0.2rem",
                                                        }}
                                                    >
                                                        <strong>#</strong>
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        style={{
                                                            paddingTop: "0.2rem",
                                                            paddingBottom: "0.2rem",
                                                        }}
                                                    >
                                                        <strong>Document Name</strong>
                                                    </th>
                                                    {/* <th
                                                        scope="col"
                                                        style={{
                                                            paddingTop: "0.2rem",
                                                            paddingBottom: "0.2rem",
                                                        }}
                                                    >
                                                        <strong>Document Category</strong>
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        style={{
                                                            paddingTop: "0.2rem",
                                                            paddingBottom: "0.2rem",
                                                        }}
                                                    >
                                                        <strong>Document Status</strong>
                                                    </th> */}
                                                    <th
                                                        scope="col"
                                                        style={{
                                                            paddingTop: "0.2rem",
                                                            paddingBottom: "0.2rem",
                                                        }}
                                                    >
                                                        <strong>Document Uploaded</strong>
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        style={{
                                                            paddingTop: "0.2rem",
                                                            paddingBottom: "0.2rem",
                                                        }}
                                                    ></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {supportingDocuments.map((item) => (
                                                    <tr key={supportingDocuments.indexOf(item)}>
                                                        <td
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                                fontSize: "small",
                                                            }}
                                                        >
                                                            {supportingDocuments.indexOf(item) + 1}
                                                        </td>
                                                        <td
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                                fontSize: "small",
                                                            }}
                                                        >
                                                            {allDocuments.filter(doc => doc.documentId == item.id)[0]?.name}
                                                        </td>
                                                        {/* <td
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                                fontSize: "small",
                                                            }}
                                                        >
                                                            {allDocuments.filter(doc => doc.documentId == item.id)[0]?.category}
                                                        </td>
                                                        <td
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                                fontSize: "small",
                                                            }}
                                                        >
                                                            {allDocuments.filter(doc => doc.documentId == item.id)[0]?.status}
                                                        </td> */}
                                                        <td
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                                fontSize: "small",
                                                            }}
                                                        >
                                                            {item.name} {/*doc uploaded*/}
                                                        </td>
                                                        <td
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                                fontSize: "small",
                                                            }}
                                                        >
                                                            {this.generateExtensionIcon(item)}
                                                        </td>
                                                        <td
                                                            style={{
                                                                paddingTop: "0.2rem",
                                                                paddingBottom: "0.2rem",
                                                                fontSize: "small",
                                                            }}
                                                        >
                                                            <i
                                                                className="far fa-trash-alt ml-5 red-text fa-lg"
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => this.handleDeleteDocument(item)}
                                                            ></i>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>




                            <DocumentPreviewer
                                document={this.state.document}
                                showPreview={this.state.showPreview}
                                toggleShowPreview={this.toggleShowPreview}
                            />
                        </div>
                    </div>
                )}
            ></DocumentApprovalForm>
        );
    }
}

export default DocumentApproval;
