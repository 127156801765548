import React from "react";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import dashboardService from "../../services/email/dashboardService";
import { sendDashboardMail } from "./../../services/email/dashboardService";

interface ShareModalProps {
  showShareModal: boolean;
  toggleShowShareModal: any;
  items: { [name: string]: string };
  dashboard: string;
}

interface ShareModalState {
  emails: string[];
  email: string;
  submitting: boolean;
  switchOptionPdf: boolean;
  switchOptionImage: boolean;
}

class ShareModal extends React.Component<ShareModalProps, ShareModalState> {
  constructor(props: ShareModalProps) {
    super(props);
    this.state = {
      emails: [],
      email: "",
      submitting: false,
      switchOptionPdf: true,
      switchOptionImage: false,
    };
  }

  handleOnEmailChange(e: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ email: e.currentTarget.value });
  }

  handleOnAddEmail(): void {
    let { emails, email } = this.state;
    if (!emails.includes(email)) {
      emails.push(this.state.email);
    }
    email = "";
    this.setState({ email, emails });
  }

  handleOnRemoveFromMailList = (mail: string) => {
    let emails = this.state.emails;
    emails = emails.filter((x) => x !== mail);
    this.setState({ emails });
  };

  handleUpdate;

  handleOnSubmitShareEmailsList = async () => {
    this.setState({ submitting: true });
    // console.log("items: ", this.props.items);
    const { items, dashboard } = this.props;
    let fileOption: "both" | "jpeg" | "pdf";
    if (this.state.switchOptionPdf && this.state.switchOptionImage) {
      fileOption = "both";
    } else if (this.state.switchOptionPdf) {
      fileOption = "pdf";
    } else if (this.state.switchOptionImage) {
      fileOption = "jpeg";
    } else {
      fileOption = "both";
    }
    try {
      const response = await dashboardService.sendDashboardMail(
        items,
        this.state.emails,
        dashboard,
        fileOption
      );
      if (response.status >= 200 && response.status <= 300) {
        toast.info("Dashboard mail sent successfully!", {
          autoClose: 6000,
          type: toast.TYPE.INFO,
          hideProgressBar: false,
        });
        this.setState({ submitting: false, emails: [], email: "" });
      }
    } catch (error) {
      this.setState({ submitting: false });
    }
  };

  handleUpdateSwitchOption = (option: "pdf" | "image") => {
    if (option === "pdf") {
      this.setState({ switchOptionPdf: !this.state.switchOptionPdf });
    } else {
      this.setState({ switchOptionImage: !this.state.switchOptionImage });
    }
  };

  onHideModal = () => {
    this.setState({ emails: [], email: "", submitting: false });
    this.props.toggleShowShareModal();
  };

  render() {
    const { showShareModal } = this.props;
    const { emails, email, submitting, switchOptionImage, switchOptionPdf } =
      this.state;
    return (
      <Modal
        size="lg"
        backdrop="static"
        show={showShareModal}
        onHide={() => this.onHideModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>
              <i className="icon-box-filled2 mr-3"></i> Configure E-mails
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <br />
          <div className="row clearfix">
            <div className="col-sm-6">
              <div className="form-group form-float">
                <div className="form-line">
                  <label className="form-label">Email</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => this.handleOnEmailChange(e)}
                    className="form-control"
                  />
                </div>
                <div className="float-right">
                  <br />
                  <button
                    type="button"
                    className="btn btn-success btn-xs"
                    onClick={() => this.handleOnAddEmail()}
                  >
                    <i className="icon-add_circle mr-2"></i>Add
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <p>Formats</p>
              <ul className="list-group">
                <li className="list-group-item">
                  PDF
                  <div className="material-switch float-right">
                    <input
                      id="switchOptionPdf"
                      name="switchOptionPdf"
                      type="checkbox"
                      onChange={() => this.handleUpdateSwitchOption("pdf")}
                      defaultChecked={switchOptionPdf}
                    />
                    <label
                      htmlFor="switchOptionPdf"
                      className="bg-secondary"
                    ></label>
                  </div>
                </li>
                <li className="list-group-item">
                  Image
                  <div className="material-switch float-right">
                    <input
                      id="switchOptionImage"
                      name="switchOptionImage"
                      type="checkbox"
                      onChange={() => this.handleUpdateSwitchOption("image")}
                      defaultChecked={switchOptionImage}
                    />
                    <label
                      htmlFor="switchOptionImage"
                      className="bg-primary"
                    ></label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <br />
          <h6 className="card-title">EMAILS</h6>
          <table className="table table-hover table-content table-sm table-striped mb-0">
            <thead>
              <tr>
                <th
                  scope="col"
                  style={{
                    width: "5px",
                    paddingTop: "0.2rem",
                    paddingBottom: "0.2rem",
                  }}
                >
                  <strong>#</strong>
                </th>
                <th
                  scope="col"
                  style={{
                    // width: "5px",
                    paddingTop: "0.2rem",
                    paddingBottom: "0.2rem",
                  }}
                >
                  <strong>Email Address</strong>
                </th>
                <th
                  scope="col"
                  style={{
                    width: "5px",
                    paddingTop: "0.2rem",
                    paddingBottom: "0.2rem",
                  }}
                ></th>
              </tr>
            </thead>
            <tbody>
              {emails.length === 0 ? (
                <React.Fragment>
                  <tr>
                    <td
                      className="font-weight-normal text-truncate"
                      colSpan={3}
                      style={{
                        textAlign: "center",
                        color: "#FE825E",
                        fontSize: "small",
                      }}
                    >
                      ...No Emails Configured
                      {/* <i className="fas fa-spinner fa-spin mr-2" /> */}
                    </td>
                  </tr>
                </React.Fragment>
              ) : (
                emails.map((mail) => (
                  <tr key={mail}>
                    <td
                      style={{
                        width: "5px",
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                        fontSize: "small",
                      }}
                    >
                      {emails.indexOf(mail) + 1}
                    </td>
                    <td
                      style={{
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                        fontSize: "small",
                      }}
                    >
                      {mail}
                    </td>
                    <td
                      style={{
                        cursor: "pointer",
                        width: "5px",
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                        fontSize: "small",
                      }}
                    >
                      <i
                        className="icon-minus-circle"
                        onClick={() => {
                          this.handleOnRemoveFromMailList(mail);
                        }}
                        style={{ color: "red" }}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="success"
            disabled={submitting || emails.length === 0}
            onClick={() => {
              this.handleOnSubmitShareEmailsList();
            }}
          >
            {submitting === false ? (
              <React.Fragment>
                <i className="icon-paper-plane-o mr-3" />
                Send
              </React.Fragment>
            ) : (
              <React.Fragment>
                <i className="fas fa-spinner fa-spin" />
              </React.Fragment>
            )}
          </Button>
          <Button
            size="sm"
            variant="outline-danger"
            onClick={() => this.onHideModal()}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ShareModal;
