import React, { useEffect, useState } from "react";
import FAMNavigation from "../navigation";
import { CogsOutput } from "./COGS";
import { BalanceSheetOutput } from "./balanceSheet";
import { IncomeStatementOutput } from "./incomeStatement";
import { CashflowOutput } from "./cashFlow";
import { CollateralValuationOutput } from "./collateralValuation";
import { FamSummaryExecution } from "./famSummary";
import corporateAnalysisService from "../../../../../../../../services/hybridAppraisal/corporateAnalysisService";
import { FinancialRatioOutput } from "./financialRatio";

export default function FAMSummary({ loan }: { loan?: any }) {
  const [navIndex, setNavIndex] = useState(6);
  const [famSummary, setFamSummary] = useState<any>();
  const [famSummaryOutput, setFamSummaryOutput] = useState<any>();

  let n = navIndex;

  const components = [
    <IncomeStatementOutput loan={loan} />,
    <CogsOutput loan={loan} />,
    <BalanceSheetOutput loan={loan} />,
    <CashflowOutput loan={loan} />,
    <CollateralValuationOutput loan={loan} />,
    <FinancialRatioOutput loan={loan} famSummary={famSummary} />,
    <FamSummaryExecution loan={loan} famSummary={famSummaryOutput} />,
  ];

  useEffect(() => {
    const getFamSummary = async () => {
      const response = await corporateAnalysisService.previewFAMSummaryRecords({
        loanRequestId: loan?.id,
      });
      if (response?.status === 200) {
        setFamSummary(response?.data);
      }
    };
    getFamSummary();
  }, [loan]);

  useEffect(() => {
    const getFamSummaryOutput = async () => {
      const response = await corporateAnalysisService.retrieveFAMSummaryOutput({
        loanRequestId: loan?.id,
      });
      if (response?.status === 200) {
        setFamSummaryOutput(response?.data);
      }
    };
    getFamSummaryOutput();
  }, [loan]);

  return (
    <div className="card">
      <div className="card-header clear-fix">
        <h6 className="card-title float-left" style={{ marginBottom: "0px" }}>
          <b>Corporate Analysis Review</b>
        </h6>
      </div>
      <div className="row">
        <div style={{ marginTop: 10, marginBottom: 10, marginLeft: 30 }}>
          <FAMNavigation small={true} index={navIndex} setIndex={setNavIndex} />
        </div>
        <div style={{ width: "95%" }} className="mx-4">
          {components.map((component, index) => (
            <div
              key={index}
              style={{
                visibility: n === index ? "visible" : "hidden",
                height: n === index ? "auto" : "0px",
              }}
            >
              {component}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
