import React, { Component } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import BaseFormComponent, { IBaseFormState } from "../../../../../../components/BaseFormComponent";
import FormInputComponent from "../../../../../../components/formInputComponent";
import SelectInputComponent, { ISelectItems } from "../../../../../../components/formSelectComponent";
import { ActionTypes } from "../../../../../../enums/actionTypes";
import loanRequestService from "../../../../../../services/hybridAppraisal/loanRequestService";
import { countries } from "./../../../../../../services/utility/countries";
import textFormatService from "../../../../../../services/utility/textFormatService";
import hybridAppraisalValidationService from "../../../../../../services/hybridAppraisal/hybridAppraisalValidationService";
import { getCurrentUsersRoles } from "../../../../../../services/auth/authService";

interface PropertyDetailsFields {
    propertyType?: string;
    propertyDescription?: string;
    address?: string;
    city?: string;
    country?: string;
    state?: string;
    marketValue?: string;
    appraisedValue?: string;
    locationTier?: string;
    appraiser?: string;
    appraisalDate?: string;
    cost?: string;
    loanAmount?: string;
    equityPayment?: string;
    loanToValue?: string;
}

interface DependentSelectItems {
    value: any;
    inputData: ISelectItems[];
}

class PropertyDetailsForm extends BaseFormComponent<PropertyDetailsFields> { }

interface PropertyDetailsProps {
    values: any;
    reloadItem: any;
    mandatoryFields: any;
}

interface PropertyDetailsState {
    properties: PropertyDetailsFields[];
    loanSummary: PropertyDetailsFields[];
    country: string;
    ownUpdate: boolean;
}

class PropertyDetails extends Component<
    PropertyDetailsProps,
    IBaseFormState & PropertyDetailsState
> {
    constructor(props: PropertyDetailsProps) {
        super(props);
        this.state = {
            validated: false,
            submitting: false,
            errors: {},
            properties: [],
            loanSummary: [],
            ownUpdate: false,
            country: ""
        };
    }

    static getDerivedStateFromProps(
        nextProps: PropertyDetailsProps,
        prevState: PropertyDetailsState
    ) {
        if (prevState.ownUpdate) {
            return {
                ownUpdate: false,
            };
        }
        if (_.isEmpty(nextProps.values.assets?.properties)) {
            return null;
        }
        return {
            properties: nextProps.values?.assets?.properties?.filter(x => x.propertyType !== ""),
            loanSummary: nextProps.values?.assets?.properties?.filter(x => x.propertyType === null || x.propertyType === "" || x.loanAmount > 0)
        };
    }

    onFormSubmit(fields: PropertyDetailsFields, onReloadFieldsCB: any): boolean {
        try {
            if (this.props.mandatoryFields) {
                this.setState(
                    { errors: hybridAppraisalValidationService.validateAddPropertyLoanSummaryDetailForm(fields, this.props.mandatoryFields), ownUpdate: true },
                    () => {
                        if (Object.keys(this.state.errors).length === 0) {
                            return this.submit(fields, onReloadFieldsCB);
                        }
                    }
                );
            }
            else {
                this.submit(fields, onReloadFieldsCB);
            }
            this.setState({ validated: true, ownUpdate: true });
            return false;
        } catch (error) {
            return false;
        }
    }

    async submit(
        fields: PropertyDetailsFields,
        onReloadFieldsCB: any
    ): Promise<boolean> {
        try {
            fields.appraisedValue = "0"
            fields.marketValue = "0"
            this.setState({ submitting: true, ownUpdate: true });
            const loanRequest = await loanRequestService.retrieveById(this.props.values.id);
            const loanRequestDetail = loanRequest.data;
            const payload: any = { ...loanRequestDetail };
            if (payload.assets === null) {
                payload.assets = { properties: [...this.state.properties, fields] }
            }
            else {
                payload.assets.properties = [...this.state.properties, fields]
            }
            const response = await loanRequestService.updateLoanRequest(payload);
            if (response.status >= 200 && response.status <= 300) {
                toast.info(`Property Details saved successfully!`, {
                    autoClose: 6000,
                    type: toast.TYPE.DEFAULT,
                    hideProgressBar: false,
                });
            } else {
                return false;
            }
            this.setState({ submitting: false, ownUpdate: true });
            this.resetFields();
        } catch (error) {
            if (error.response.status === 409) {
                toast.error(error.response.data.detail, {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            } else if (error.response.status === 400) {
                toast.error(error.response.data.errors[0], {
                    autoClose: 6000,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: false,
                });
            }
            this.setState({ submitting: false, ownUpdate: true });
            return false;
        } finally {
            return true;
        }
    }

    handleOnAddProperty = (item: PropertyDetailsFields, onReloadFieldsCB: any) => {
        const { mandatoryFields } = this.props;
        if (mandatoryFields) {
            this.setState(
                { errors: hybridAppraisalValidationService.validateAddPropertyDetailForm(item, mandatoryFields), ownUpdate: true },
                () => {
                    if (Object.keys(this.state.errors).length === 0) {
                        const properties = [...this.state.properties];
                        properties.push(item);
                        this.setState({ properties, ownUpdate: true });
                        this.resetFields();
                        onReloadFieldsCB();
                    }
                }
            );
        }
        else {
            const properties = [...this.state.properties];
            properties.push(item);
            this.setState({ properties, ownUpdate: true });
            this.resetFields();
            onReloadFieldsCB();
        }
        this.setState({ validated: true, ownUpdate: true });
    };

    handleOnRemoveProperty = (item: PropertyDetailsFields) => {
        const properties = [...this.state.properties];
        if (properties.includes(item)) {
            properties.splice(
                properties.indexOf(item), 1
            );
        }
        this.setState({ properties, ownUpdate: true });
    };

    getRequiredFields(title): boolean {
        const { mandatoryFields } = this.props;
        var required = mandatoryFields?.inputFields?.filter(x => x.fieldName === title)[0]?.mandatory;
        if (required === undefined) {
            return false;
        }
        return required;
    }

    resetFields() {
        this.setState({ validated: false, submitting: false, errors: [], country: "", ownUpdate: true });
    }

    render() {
        const { values } = this.props;
        const { errors, validated, properties, loanSummary } = this.state;

        let equityPaymentlen = values?.assets?.properties?.filter(x => x.equityPayment !== null).length
        let equityPayment: any
        if (equityPaymentlen > 0) {
            equityPayment = equityPaymentlen = values?.assets?.properties?.filter(x => x.equityPayment !== null)[equityPaymentlen - 1]
        }
        let statesInputData: ISelectItems[] = [];
        let statesData: ISelectItems[] = [
            "Banjul City Council (BCC)=LOCATION A",
            "Kanifing Municipal Council (KMC)=LOCATION A",
            "West Coast Region (WCR)=LOCATION A",
            "Upper River Region (URR)=LOCATION B",
            "North Bank Region (NBR)=LOCATION C",
            "Central River Region (CRR)=LOCATION C",
            "Lower River Region (LRR)=LOCATION C",
        ].map(item => {
            return { name: item.split("=")[0], value: item.split("=")[1] }
        })
        // let statesData: ISelectItems[] = [
        //   "ABIA=LOCATION B",
        //   "ABUJA=LOCATION A",
        //   "ADAMAWA=LOCATION B",
        //   "AKWA IBOM=LOCATION B",
        //   "ANAMBRA=LOCATION C",
        //   "BAUCHI=LOCATION C",
        //   "BAYELSA=LOCATION C",
        //   "BENUE=LOCATION C",
        //   "BORNO=LOCATION C",
        //   "CROSS RIVER=LOCATION B",
        //   "DELTA=LOCATION B",
        //   "EBONYI=LOCATION C",
        //   "EDO=LOCATION C",
        //   "EKITI=LOCATION C",
        //   "ENUGU=LOCATION B",
        //   "GOMBE=LOCATION C",
        //   "IMO=LOCATION B",
        //   "JIGAWA=LOCATION C",
        //   "KADUNA=LOCATION A",
        //   "KANO=LOCATION A",
        //   "KATSINA=LOCATION B",
        //   "KEBBI=LOCATION C",
        //   "KOGI=LOCATION C",
        //   "KWARA=LOCATION C",
        //   "LAGOS=LOCATION A",
        //   "NASARAWA=LOCATION C",
        //   "NIGER=LOCATION C",
        //   "OGUN=LOCATION C",
        //   "ONDO=LOCATION C",
        //   "OSUN=LOCATION C",
        //   "OYO=LOCATION B",
        //   "PLATEAU=LOCATION C",
        //   "RIVERS=LOCATION A",
        //   "SOKOTO=LOCATION C",
        //   "TARABA=LOCATION C",
        //   "YOBE=LOCATION C",
        //   "ZAMFARA=LOCATION C",
        // ].map(item => {
        //   return { name: item.split("=")[0], value: item.split("=")[1] }
        // })

        statesInputData = statesData.map(item => {
            return { name: item.name, value: item.name }
        })

        let countriesInputData: ISelectItems[] = [];
        countries.forEach((country) => {
            countriesInputData.push({ name: country.name, value: country.name });
        });
        countries
            .filter((c) => c.name === this.state.country)[0]
            ?.states.forEach((state) => {
                // statesInputData.push({ name: state.name, value: state.name });
            });

        const propertyTypeInputData: ISelectItems[] = ["Residential", "Commercial", "Construction"].map(item => {
            return { name: item, value: item }
        });

        let brandModelInputData: DependentSelectItems[] = [
            { value: "Toyota", inputData: ["4Runner", "Venza", "Avalon", "Camry", "Corolla", "Highlander", "Land Cruiser", "Rav4", "Sienna"].map(item => { return { name: item, value: item } }) },
            { value: "Honda", inputData: ["Accord", "City", "Civic", "HR-V", "CR-V", "Pilot"].map(item => { return { name: item, value: item } }) },
            { value: "Nissan", inputData: ["Altima", "Juke", "Qashqai", "X-Trail", "Murano", "Pathfinder", "Maxima"].map(item => { return { name: item, value: item } }) },
            { value: "Mercedes Benz", inputData: ["AMG GLC", "CLK", "GLE-Class", "GLK-Class", "GLS-Class"].map(item => { return { name: item, value: item } }) },
            { value: "Ford", inputData: ["Edge", "Escape", "Explorer"].map(item => { return { name: item, value: item } }) },
            { value: "Kia", inputData: ["Picanto", "Optima", "Rio", "Cerato", "Sportage"].map(item => { return { name: item, value: item } }) },
            { value: "Lexus", inputData: ["GX350", "LX450", "RX350", "RX450"].map(item => { return { name: item, value: item } }) },
            { value: "Hyundai", inputData: ["i10", "Sante Fe", "Sonata", "Elantra", "Tucson", "Accent"].map(item => { return { name: item, value: item } }) },
            { value: "Range Rover", inputData: ["Land Rover", "Range Rover Evoque", "Range Rover Velar", "Range Rover Sport"].map(item => { return { name: item, value: item } }) },
            { value: "BMW", inputData: ["X1", "X2", "X5", "X6", "X7"].map(item => { return { name: item, value: item } }) },
            { value: "Caterpillar", inputData: ["Caterpillar"].map(item => { return { name: item, value: item } }) },
            { value: "Fordson", inputData: ["Fordson"].map(item => { return { name: item, value: item } }) },
            { value: "Mahindra", inputData: ["Mahindra"].map(item => { return { name: item, value: item } }) },
            { value: "Mitsubishi", inputData: ["Mitsubishi"].map(item => { return { name: item, value: item } }) },
            { value: "Xerox", inputData: ["Xerox"].map(item => { return { name: item, value: item } }) },
            { value: "Cannon", inputData: ["Cannon"].map(item => { return { name: item, value: item } }) },
            { value: "HP", inputData: ["HP"].map(item => { return { name: item, value: item } }) },
            { value: "LG", inputData: ["LG"].map(item => { return { name: item, value: item } }) },
            { value: "Samsung", inputData: ["Samsung"].map(item => { return { name: item, value: item } }) },
            { value: "Hisense", inputData: ["Hisense"].map(item => { return { name: item, value: item } }) },
            { value: "Thermocool", inputData: ["Thermocool"].map(item => { return { name: item, value: item } }) },
            { value: "Panasonic", inputData: ["Panasonic"].map(item => { return { name: item, value: item } }) },
            { value: "Sony", inputData: ["Sony"].map(item => { return { name: item, value: item } }) },
        ]

        let categoryBrandInputData: DependentSelectItems[] = [
            { value: "Vehicles", inputData: ["Toyota", "Honda", "Nissan", "Mercedes Benz", "Ford", "Kia", "Lexus", "Hyundai", "Range Rover", "BMW"].map(item => { return { name: item, value: item } }) },
            { value: "Plant and Machinery ", inputData: ["Caterpillar", "Fordson", "Mahindra", "Mitsubishi"].map(item => { return { name: item, value: item } }) },
            { value: "Office Equipment", inputData: ["Xerox", "Cannon", "HP"].map(item => { return { name: item, value: item } }) },
            { value: "Household Equipment", inputData: ["LG", "Samsung", "Hisense", "Thermocool", "Panasonic", "Sony"].map(item => { return { name: item, value: item } }) },
        ]

        let categoryTypeInputData: DependentSelectItems[] = [
            { value: "Vehicles", inputData: ["Car", "Bus", "Truck", "Tanker"].map(item => { return { name: item, value: item } }) },
            { value: "Plant and Machinery ", inputData: ["Tractor", "Milling Machine"].map(item => { return { name: item, value: item } }) },
            { value: "Office Equipment", inputData: ["Printer", "Scanner", "Photocopier", "Desktop", "Laptop"].map(item => { return { name: item, value: item } }) },
            { value: "Household Equipment", inputData: ["Television", "Air Conditioner", "Gas Cooker", "Washing Machine", "Fridge", "Sound System"].map(item => { return { name: item, value: item } }) },
        ]

        let categoryInputData: ISelectItems[] = categoryTypeInputData.map(item => {
            return { name: item.value, value: item.value }
        });

        let currentYear = new Date().getFullYear()
        const yearInputData: ISelectItems[] = _.range(currentYear - 15, currentYear + 1, 1).map(item => {
            return { name: item.toString(), value: item }
        });

        const supplierInputData: ISelectItems[] = [
            "TONIK AUTOMOBILES COMPANY LTD",
            "PEACE MOTOR LTD",
            "SPHERE MOTORS NIG LTD",
            "KAY AUTO MART",
            "ALL OVER RIDE LIMITED",
            "AUTO NURRAY INVESTMENT LTD",
            "SUPER EXCELLENT MOTORS NIG LTD",
            "SUNNEL ILESANMI AND CO",
            "DEMAC BOLLY ENTER",
            "AGBOSCO VENTURES",
            "OSAMA INVESTMENT COMPANY LTD",
            "CYMAS MOTORS",
            "ALLISON INVESTMENT COMPANY LTD",
            "IKWUECHE ENT NIGERIA",
            "ARTHUR RICH MOTORS",
            "OZONE MOTORS",
            "AD PRISCA MOTORS",
            "HONDA PLACE",
            "KIA MOTORS",
            "GLOBE MOTORS NIG LTD",
            "COSCHARIS MOTORS",
            "FESOP MOTORS",
            "SAINT MOTORS",
            "JAMIL MOTORS",
            "OC J GLOBAL RESOURCES"
        ].map(item => {
            return { name: item, value: item }
        });

        const appraiserInputData: ISelectItems[] = [
            "AGBON & ASSOCIATES",
            "SPHINX ASSOCIATES"
        ].map(item => {
            return { name: item, value: item }
        });
        // const appraiserInputData: ISelectItems[] = [
        //   "A.C. Otegbulu & Partners",
        //   "A.M. John Associates",
        //   "Adefila & Partners",
        //   "Akin Alawore & Partners",
        //   "Akin Ibiyode & Co",
        //   "Alagbe & Partners",
        //   "Alonge Ogunmoyero & Co",
        //   "Austin Ofoha & Co.",
        //   "Bassey N & Co",
        //   "Bello & Co.",
        //   "Biyi Adesanya & Co",
        //   "Bode Adediji Partnership",
        //   "Bola Onabadejo & Co.",
        //   "Chukwuemeka Okoli & Associates",
        //   "Demola Adetola & Co.",
        //   "Dosu Fatokun & Co.",
        //   "Eki Ogbebor & Co",
        //   "Emeka Eriobuna & Co.",
        //   "Emeka Onuorah & Co",
        //   "Emele Associates"
        // ].map(item => {
        //   return { name: item, value: item }
        // });

        return (
            <PropertyDetailsForm
                initialValues={{
                    propertyType: values?.assets?.properties?.propertyType ? values.assets.properties.propertyType : "",
                    propertyDescription: values?.assets?.properties?.propertyDescription ? values.assets.properties.propertyDescription : "",
                    address: values?.assets?.properties?.address ? values.assets.properties.address : "",
                    city: values?.assets?.properties?.city ? values.assets.properties.city : "",
                    country: values?.assets?.properties?.country ? values.assets.properties.country : "",
                    state: values?.assets?.properties?.state ? values.assets.properties.state : "",
                    marketValue: values?.assets?.properties?.marketValue ? values.assets.properties.marketValue : "",
                    appraisedValue: values?.assets?.properties?.appraisedValue ? values.assets.properties.appraisedValue : "",
                    locationTier: values?.assets?.properties?.locationTier ? values.assets.properties.locationTier : "",
                    appraiser: values?.assets?.properties?.appraiser ? values.assets.properties.appraiser : "",
                    appraisalDate: values?.assets?.properties?.appraisalDate ? values.assets.properties.appraisalDate : "",
                    cost: (values.loanAmount).toString(),
                    loanAmount: (values.loanAmount).toString(),
                    equityPayment: equityPayment ? equityPayment.equityPayment : "",
                    loanToValue: "1"
                }}
                FormComponent={({
                    fields: {
                        propertyType,
                        propertyDescription,
                        address,
                        city,
                        country,
                        state,
                        marketValue,
                        appraisedValue,
                        locationTier,
                        appraiser,
                        appraisalDate,
                        cost,
                        loanAmount,
                        equityPayment,
                        loanToValue
                    },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                }) => (
                    <div className="form-row">
                        <div className="col-md-12">

                            {/* Asset Details */}
                            <div className="card">
                                <div className="card-header clear-fix">
                                    <h6
                                        className="float-left"
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <b>Property Details</b>
                                    </h6>
                                </div>
                                <div className="card-body">
                                    <div className="form-row">
                                        <SelectInputComponent
                                            label="Property Type"
                                            id="propertyType"
                                            name="propertyType"
                                            divClass={6}
                                            value={propertyType}
                                            items={propertyTypeInputData}
                                            required={this.getRequiredFields("propertyType")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        <FormInputComponent
                                            id="propertyDescription"
                                            name="propertyDescription"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={propertyDescription}
                                            required={this.getRequiredFields("propertyDescription")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        <FormInputComponent
                                            label="Property Address"
                                            id="address"
                                            name="address"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={address}
                                            required={this.getRequiredFields("propertyAddress")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        <FormInputComponent
                                            label="Property City/Town"
                                            id="city"
                                            name="city"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={city}
                                            required={this.getRequiredFields("propertyCity")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        <SelectInputComponent
                                            label="Property Country"
                                            id="country"
                                            name="country"
                                            divClass={6}
                                            value={country}
                                            items={countriesInputData}
                                            required={this.getRequiredFields("propertyCountry")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={(id, value) => {
                                                onChange(id, value);
                                                this.setState({ country: value })
                                            }}
                                        />
                                        <SelectInputComponent
                                            label="Property State/Region"
                                            id="state"
                                            name="state"
                                            divClass={6}
                                            value={state}
                                            items={statesInputData}
                                            required={this.getRequiredFields("propertyState")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={async (id, value) => {
                                                await onChange(id, value);
                                                await onChange('locationTier', statesData.filter(nameObj => nameObj.name == value)[0].value);
                                            }}
                                        />
                                        <FormInputComponent
                                            id="marketValue"
                                            name="marketValue"
                                            type="number"
                                            placeholder=""
                                            divClass={6}
                                            value={marketValue}
                                            required={this.getRequiredFields("marketValue")}
                                            validated={validated}
                                            errors={errors}
                                            // amountDecimalPlaces={2}
                                            onChange={onChange}

                                        />
                                        <FormInputComponent
                                            label="Appraised Value (EFSV)"
                                            id="appraisedValue"
                                            name="appraisedValue"
                                            type="number"
                                            placeholder=""
                                            divClass={6}
                                            value={appraisedValue}
                                            required={this.getRequiredFields("appraisedValue")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        <FormInputComponent
                                            id="appraiser"
                                            name="appraiser"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={appraiser}
                                            required={this.getRequiredFields("appraiser")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        <FormInputComponent
                                            id="appraisalDate"
                                            name="appraisalDate"
                                            type="date"
                                            placeholder=""
                                            divClass={6}
                                            value={appraisalDate}
                                            required={this.getRequiredFields("appraisalDate")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                        />
                                        <FormInputComponent
                                            label="Location Category"
                                            id="locationTier"
                                            name="locationTier"
                                            type="text"
                                            placeholder=""
                                            divClass={6}
                                            value={locationTier}
                                            required={this.getRequiredFields("locationCategory")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Add Property */}
                            <div className="form-row">
                                <div className="my-1 mx-2">
                                    {(getCurrentUsersRoles().includes("updatedataentry") && (
                                        <button
                                            className="btn btn-sm btn-outline-primary my-4"
                                            style={{ cursor: "pointer" }}
                                            type="button"
                                            onClick={() =>
                                                this.handleOnAddProperty(
                                                    {
                                                        propertyType,
                                                        propertyDescription,
                                                        address,
                                                        city,
                                                        country,
                                                        state,
                                                        marketValue,
                                                        appraisedValue,
                                                        locationTier,
                                                        appraiser,
                                                        appraisalDate
                                                    },
                                                    onReloadFields
                                                )
                                            }
                                        >
                                            <React.Fragment>
                                                <i className="far fa-save fa-lg mr-2" />{" "}
                                                Add
                                            </React.Fragment>
                                        </button>
                                    ))}
                                </div>
                            </div>

                            {/* Property Table */}
                            <div className=" form-row col-md-12">
                                <div
                                    className="table-responsive text-nowrap"
                                    style={{
                                        maxHeight: "150px",
                                        overflowY: "scroll",
                                    }}
                                >
                                    <table className="table table-hover table-content table-sm table-striped mb-0">
                                        <thead>
                                            <tr>
                                                <th
                                                    scope="col"
                                                    style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                    }}
                                                >
                                                    <strong>#</strong>
                                                </th>
                                                <th
                                                    scope="col"
                                                    style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                    }}
                                                >
                                                    <strong>Property Type</strong>
                                                </th>
                                                <th
                                                    scope="col"
                                                    style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                    }}
                                                >
                                                    <strong>Property Description</strong>
                                                </th>
                                                <th
                                                    scope="col"
                                                    style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                    }}
                                                >
                                                    <strong>Market Value</strong>
                                                </th>
                                                <th
                                                    scope="col"
                                                    style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                    }}
                                                >
                                                    <strong>Appraiser</strong>
                                                </th>
                                                <th
                                                    scope="col"
                                                    style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                    }}
                                                >
                                                    <strong>Appraised Value</strong>
                                                </th>
                                                <th
                                                    scope="col"
                                                    style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                    }}
                                                >
                                                    <strong>Appraisal Date</strong>
                                                </th>
                                                <th
                                                    scope="col"
                                                    style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                    }}
                                                ></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {properties.map((item) => (
                                                <tr key={properties.indexOf(item)}>
                                                    <td
                                                        style={{
                                                            paddingTop: "0.2rem",
                                                            paddingBottom: "0.2rem",
                                                            fontSize: "small",
                                                        }}
                                                    >
                                                        {properties.indexOf(item) + 1}
                                                    </td>
                                                    <td
                                                        style={{
                                                            paddingTop: "0.2rem",
                                                            paddingBottom: "0.2rem",
                                                            fontSize: "small",
                                                        }}
                                                    >
                                                        {item.propertyType}
                                                    </td>
                                                    <td
                                                        style={{
                                                            paddingTop: "0.2rem",
                                                            paddingBottom: "0.2rem",
                                                            fontSize: "small",
                                                        }}
                                                    >
                                                        {item.propertyDescription}
                                                    </td>
                                                    <td
                                                        style={{
                                                            paddingTop: "0.2rem",
                                                            paddingBottom: "0.2rem",
                                                            fontSize: "small",
                                                        }}
                                                    >
                                                        {textFormatService.formatCurrency(Number(item.marketValue), 2)}
                                                    </td>
                                                    <td
                                                        style={{
                                                            paddingTop: "0.2rem",
                                                            paddingBottom: "0.2rem",
                                                            fontSize: "small",
                                                        }}
                                                    >
                                                        {item.appraiser}
                                                    </td>
                                                    <td
                                                        style={{
                                                            paddingTop: "0.2rem",
                                                            paddingBottom: "0.2rem",
                                                            fontSize: "small",
                                                        }}
                                                    >
                                                        {textFormatService.formatCurrency(Number(item.appraisedValue), 2)}
                                                    </td>
                                                    <td
                                                        style={{
                                                            paddingTop: "0.2rem",
                                                            paddingBottom: "0.2rem",
                                                            fontSize: "small",
                                                        }}
                                                    >
                                                        {textFormatService.formatTimeString(item.appraisalDate)}
                                                    </td>
                                                    <td
                                                        style={{
                                                            paddingTop: "0.2rem",
                                                            paddingBottom: "0.2rem",
                                                            fontSize: "small",
                                                        }}
                                                    >
                                                        <i
                                                            className="far fa-trash-alt ml-5 red-text fa-lg"
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                                this.handleOnRemoveProperty(
                                                                    item
                                                                )
                                                            }
                                                        ></i>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {/* Loan Summary */}
                            <div className="card mt-3">
                                <div className="card-header clear-fix">
                                    <h6
                                        className="float-left"
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <b>Loan Summary</b>
                                    </h6>
                                </div>
                                <div className="card-body">
                                    <div className="form-row">
                                        <FormInputComponent
                                            label="Property Cost"
                                            id="cost"
                                            name="cost"
                                            type="amount"
                                            amountDecimalPlaces={2}
                                            placeholder=""
                                            divClass={6}
                                            value={cost}
                                            required={this.getRequiredFields("propertyCost")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                            disabled
                                        />
                                        <FormInputComponent
                                            id="equityPayment"
                                            name="equityPayment"
                                            type="number"
                                            placeholder=""
                                            divClass={6}
                                            value={equityPayment}
                                            required={this.getRequiredFields("propertyEquityPayment")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={async (id, value) => {
                                                await onChange(id, value);
                                                await onChange('cost', ((Number(value) * 100) + (Number(loanAmount))).toString());
                                                await onChange('loanToValue', (100 - (Number(value) * 100 / ((Number(value) * 100) + (Number(loanAmount))) * 100)).toString());
                                            }}
                                        />
                                        <FormInputComponent
                                            id="loanAmount"
                                            name="loanAmount"
                                            type="amount"
                                            amountDecimalPlaces={2}
                                            placeholder=""
                                            divClass={6}
                                            value={loanAmount}
                                            required={this.getRequiredFields("propertyLoanAmount")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                            disabled
                                        />
                                        <FormInputComponent
                                            label="Loan To Value %(LTV)"
                                            id="loanToValue"
                                            name="loanToValue"
                                            type="number"
                                            placeholder=""
                                            divClass={6}
                                            value={loanToValue}
                                            required={this.getRequiredFields("propertyLoanToValue")}
                                            validated={validated}
                                            errors={errors}
                                            onChange={onChange}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Save Property */}
                            <div className="card-body float-right">
                                {(getCurrentUsersRoles().includes("updatedataentry") && (
                                    <button
                                        type="submit"
                                        className="btn btn-sm btn-primary"
                                        disabled={this.state.submitting}
                                        onClick={(e) => {
                                            onHandleSubmit(
                                                e,
                                                ActionTypes.SAVE,
                                                "Property Details",
                                                () => {
                                                    this.onFormSubmit(
                                                        {
                                                            propertyType,
                                                            propertyDescription,
                                                            address,
                                                            city,
                                                            country,
                                                            state,
                                                            marketValue,
                                                            appraisedValue,
                                                            locationTier,
                                                            appraiser,
                                                            appraisalDate,
                                                            cost,
                                                            loanAmount,
                                                            equityPayment,
                                                            loanToValue
                                                        },
                                                        onReloadFields
                                                    );
                                                }
                                            );
                                        }}
                                    >
                                        {this.state.submitting === false ? (
                                            <React.Fragment>
                                                <i className="fas fa-lg fa-save mr-2" /> Save
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                                Please wait...
                                            </React.Fragment>
                                        )}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </ div>
                )}
            ></PropertyDetailsForm>
        );
    }
}

export default PropertyDetails;
