import React from "react";
import activityTrailService from "../../services/auditTrail/activityTrailService";
import _ from "lodash";
import { Fragment } from "react";
import {
  IFilterData,
  BaseListComponentState,
} from "../../components/BaseListComponent";
import {
  createFilterData,
  mapEntityList,
} from "./../../services/utility/autoMapperService";
import BaseListComponent from "./../../components/BaseListComponent";
import { filterFieldData } from "../../components/tableComponents/itemsTable";
import TableFilterPane from "./../../components/tableComponents/tableFilterPane";
import ActivityTrailItemsList from "../../components/tableComponents/activityTrailItemsList";

export interface ActivityTrailProps {}

export interface ActivityTrailState extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  //   activityTrails: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
}

class ActivityTrailList extends BaseListComponent<ActivityTrailState> {}

class ActivityTrail extends React.Component<
  ActivityTrailProps,
  ActivityTrailState
> {
  constructor(props: ActivityTrailProps) {
    super(props);
    this.state = {
      data: createFilterData(["userName", "actionType"]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [
        { name: "userName", fieldType: "text", value: "" },
        { name: "actionType", fieldType: "text", value: "" },
      ],
      item: {},
      fetchingDetail: false,
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
    };
  }

  handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true });
    let dataClone =
      typeof data === "undefined" ? { ...this.state.data } : { ...data };

    //fix to get correct branchId
    const newDataArray = ["initiatingUser", "actionType"];
    let filterData: IFilterData = {};
    newDataArray.forEach((header) => {
      if (header === "initiatingUser") {
        filterData[header] = dataClone["userName"];
      } else {
        filterData[header] = dataClone[header];
      }
      // console.log("header into filter", filterData);
    });

    try {
      const activityTrail = await activityTrailService.filterActivityTrails(
        filterData,
        pageSize,
        currentPage
      );
      this.setState({
        tableItems: mapEntityList({
          entityList: activityTrail.data.item2,
          properties: [
            { oldName: "initiatingUser", newName: "initiatingUser" },
            { oldName: "actionType", newName: "actionType" },
            { oldName: "uniqueId", newName: "uniqueId" },
            { oldName: "description", newName: "description" },
            { oldName: "occuredAt", newName: "occuredAt" },
            { oldName: "loggedAt", newName: "loggedAt" },
          ],
        }),
        currentPage,
        totalSize: activityTrail.data.item1,
        fetching: false,
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
        // this.setState({ errors, fetching: false });
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        // this.setState({ errors, fetching: false });
      }
    }
  };

  handleOnExport = async (type: string) => {};

  fetchItemById = async (item: any) => {
    this.setState({ fetchingDetail: true });
    const activityTrail = await activityTrailService.retrieveById(item.id);
    this.setState({ item: activityTrail.data, fetchingDetail: false });
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }

  render() {
    const {
      // data,
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Activity Trail Management</b>
              </h3>
            </div>
            <ActivityTrailList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              initialValues={{
                data: createFilterData([
                  "userName",
                  "actionType",
                ]) as IFilterData,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: [
                  { name: "userName", fieldType: "text", value: "" },
                  { name: "actionType", fieldType: "text", value: "" },
                ],
                item: {},
                jsonBefore: {},
                jsonAfter: {},
                fetchingTrail: false,
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                onToggleEditModal,
                showDetailsModal,
                showEditModal,
                fetchTrailItem,
                onToggleTrailModal,
                showTrailModal,
                trailItem,
                trailMapper,
              }) => (
                <Fragment>
                  {/* <BranchDetail
                    showDetailsModal={showDetailsModal}
                    toggleDetailsModal={onToggleDetailsModal}
                    toggleEditModal={onToggleEditModal}
                    showTrail={() => onToggleTrailModal(item)}
                    currentUserRoles={getCurrentUsersRoles()}
                    item={item}
                  />
                  <EditBranch
                    showEditModal={showEditModal}
                    toggleEditModal={(reloadDetails: boolean, item: any) => {
                      onToggleEditModal();
                      if (reloadDetails === true) {
                        this.fetchItemById(item);
                      }
                      this.setState({
                        showDetailsModal: !this.state.showDetailsModal,
                      });
                    }}
                    item={item}
                  />
                  <AuditTrailDetail
                    showDetailsModal={showTrailModal}
                    toggleDetailsModal={onToggleTrailModal}
                    fetchNextTrail={fetchTrailItem}
                    item={trailItem}
                    trailMapper={trailMapper}
                    fetchingDetail={fetchingTrail}
                    jsonBefore={jsonBefore}
                    jsonAfter={jsonAfter}
                  /> */}
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ActivityTrailItemsList
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></ActivityTrailList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ActivityTrail;
