import React, { Component, Fragment, useContext } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import {
  Modal,
  Button,
  Card,
  useAccordionToggle,
  AccordionContext,
  Accordion,
} from "react-bootstrap";
import { getCurrentUsersRoles } from "../../../services/auth/authService";
import DetailItem from "../../../components/detailItem";
import loanRequestService from "../../../services/hybridAppraisal/loanRequestService";
import { toast } from "react-toastify";
import textFormatService, { generateGUID } from "../../../services/utility/textFormatService";



export function ContextAwareToggle({ eventKey, header }) {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      <b>{header}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
}

interface IRejectedLoanRequestDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  item: any;
  userRoles: any;
  systemProcess: any;
}

interface IRejectedLoanRequestDetailState {
  item: any;
  updatingStatus: boolean;
  submitting: boolean;
}

class RejectedLoanRequestDetail extends Component<
  IRejectedLoanRequestDetailProps,
  IRejectedLoanRequestDetailState
> {
  state = {
    item: {},
    updatingStatus: false,
    submitting: false,
  };

  static getDerivedStateFromProps(nextProps: IRejectedLoanRequestDetailProps, prevState) {
    return {
      item: nextProps.item,
    };
  }

  async reinitiateLoanRequest(id) {
    try {
      this.setState({ submitting: true });
      const response = await loanRequestService.reinitiateLoanRequests(id)
      if (response.status >= 200 && response.status <= 300) {
        toast.info(`Reinitiated Loan Request successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false });
    }
    catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false });
      return false;
    } finally {
      return true;
    }
  }

  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      item,
      systemProcess,
      userRoles
    } = this.props;
    const { updatingStatus } = this.state;
    // console.log(item)
    // if(!_.isEmpty(item)){
    //   console.log(JSON.parse(JSON.parse(item.jsonData)))
    //   console.log(JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails))
    // }

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="icon icon--columns s-18 mr-3" />
                Loan Application Details -{" "}
                <span className="font-weight-bold">{item.customerName}</span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
            </div>
            <div className="card-body">

              {/* <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Loan Product"
                        value={item.loanProductName}
                        labelSize={5}
                        valueSize={7}
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={3} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Financial Group"
                        value={item.financialGroupName}
                        labelSize={5}
                        valueSize={7}
                      />
                    </dl>
                  )}
                </div>
              </div> */}
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="row">

                        <div className="col-md-12">
                          <div className="card">
                            <Accordion defaultActiveKey="0">
                              <div>
                                <Card>
                                  <ContextAwareToggle
                                    eventKey={`0`}
                                    header={<React.Fragment>
                                      <i
                                        className="fa fa-info-circle"
                                        aria-hidden="true"
                                        style={{ color: "#878a8a" }}
                                      ></i>{" "}
                                      Applicant Details
                                    </React.Fragment>}
                                  ></ContextAwareToggle>
                                  <Accordion.Collapse eventKey={`0`}>
                                    <Card.Body>
                                      <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                        <div className="row">
                                          <div className="col-md-6">
                                            <dl className="row">
                                              <DetailItem
                                                label="Customer Name"
                                                labelSize={5}
                                                valueSize={7}
                                                value={item.customerName}
                                              />
                                              <DetailItem
                                                label="Date of Receipt"
                                                labelSize={5}
                                                valueSize={7}
                                                value={textFormatService.formatTimeString(item.dateCreated)}
                                              />
                                              <DetailItem
                                                label="Loan Amount"
                                                labelSize={5}
                                                valueSize={7}
                                                value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).LoanAmountToNaira}
                                              />
                                              <DetailItem
                                                label="Interest"
                                                labelSize={5}
                                                valueSize={7}
                                                value={`${JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).InterestRate}%`}
                                              />
                                              <DetailItem
                                                label="Loan Purpose"
                                                labelSize={5}
                                                valueSize={7}
                                                value={item.loanPurpose}
                                              />
                                              <DetailItem
                                                label="Loan Type"
                                                labelSize={5}
                                                valueSize={7}
                                                value={item.loanType}
                                              />
                                            </dl>
                                          </div>
                                          <div className="col-md-6">
                                            <dl className="row">
                                              <DetailItem
                                                label="Loan ID"
                                                labelSize={5}
                                                valueSize={7}
                                                value={item.id}
                                              />
                                              <DetailItem
                                                label="Loan Product"
                                                labelSize={5}
                                                valueSize={7}
                                                value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).Product.Name}
                                              />
                                              <DetailItem
                                                label="Loan Tenor"
                                                labelSize={5}
                                                valueSize={7}
                                                value={item.tenureInDays}
                                              />
                                              <DetailItem
                                                label="Account Officer"
                                                labelSize={5}
                                                valueSize={7}
                                                value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).AccountOfficer}
                                              />
                                              <DetailItem
                                                label="Current Level"
                                                labelSize={5}
                                                valueSize={7}
                                                value={item.currentLevel}
                                              />
                                            </dl>
                                          </div>
                                        </div>
                                      </div>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </div>
                            </Accordion>
                          </div>
                          <br />

                          <Fragment>
                            <Accordion defaultActiveKey="0">
                              <div>
                                <Card>
                                  <ContextAwareToggle
                                    eventKey={`0`}
                                    header={<React.Fragment>
                                      <i
                                        className="fa fa-info-circle"
                                        aria-hidden="true"
                                        style={{ color: "#878a8a" }}
                                      ></i>{" "}
                                      Appraisal Actions
                                    </React.Fragment>}
                                  ></ContextAwareToggle>
                                  <Accordion.Collapse eventKey={`0`}>
                                    <Card.Body>
                                      <div
                                        className="table-responsive text-nowrap"
                                        style={{
                                          height: "300px",
                                          overflowY: "scroll",
                                        }}
                                      >
                                        <table className="table table-hover table-content table-sm table-striped mb-0">
                                          <thead>
                                            <tr>
                                              <th
                                                scope="col"
                                                style={{
                                                  width: "5px",
                                                  paddingTop: "0.2rem",
                                                  paddingBottom: "0.2rem",
                                                }}
                                              >
                                                <strong>#</strong>
                                              </th>
                                              <th
                                                scope="col"
                                                style={{
                                                  paddingTop: "0.2rem",
                                                  paddingBottom: "0.2rem",
                                                }}
                                              >
                                                <strong>Decision</strong>
                                              </th>
                                              <th
                                                scope="col"
                                                style={{
                                                  paddingTop: "0.2rem",
                                                  paddingBottom: "0.2rem",
                                                }}
                                              >
                                                <strong>Comment</strong>
                                              </th>
                                              <th
                                                scope="col"
                                                style={{
                                                  paddingTop: "0.2rem",
                                                  paddingBottom: "0.2rem",
                                                }}
                                              >
                                                <strong>Username</strong>
                                              </th>
                                              <th
                                                scope="col"
                                                style={{
                                                  paddingTop: "0.2rem",
                                                  paddingBottom: "0.2rem",
                                                }}
                                              >
                                                <strong>Date</strong>
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {/* {recipients.length === 0 ? ( */}
                                            {item.appraisalActions.length === 0 ? (
                                              <React.Fragment>
                                                <tr>
                                                  No Decisions Made yet
                                                </tr>
                                              </React.Fragment>
                                            ) : (
                                              item.appraisalActions.map((action) => (
                                                <React.Fragment key={generateGUID()}>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        width: "5px",
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                        fontSize: "small",
                                                      }}
                                                    >
                                                    </td>
                                                    <td
                                                      style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                        fontSize: "small",
                                                      }}
                                                    >
                                                      {action.action}
                                                    </td>
                                                    <td
                                                      style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                        fontSize: "small",
                                                      }}
                                                    >
                                                      {action.comment}
                                                    </td>
                                                    <td
                                                      style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                        fontSize: "small",
                                                      }}
                                                    >
                                                      {action.logType === "SystemType" ? (systemProcess?.filter(x => x.id === Number(action.systemProcessID))[0]?.name) :
                                                        (userRoles.filter(x => x.id === Number(action.roleID))[0]?.name)}
                                                    </td>
                                                    <td
                                                      style={{
                                                        paddingTop: "0.2rem",
                                                        paddingBottom: "0.2rem",
                                                        fontSize: "small",
                                                      }}
                                                    >
                                                      {textFormatService.formatTimeString(action.dateCreated)}
                                                    </td>
                                                  </tr>
                                                </React.Fragment>
                                              ))
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </div>
                            </Accordion>
                          </Fragment>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              )}

            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
              {getCurrentUsersRoles().includes("maploanproduct") && (
                <Button
                  size="sm"
                  variant="primary"
                  disabled={updatingStatus}
                  onClick={() => this.reinitiateLoanRequest(item.id)}
                >
                  <i className="far fa-edit mr-3" />
                  Reinitaite
                </Button>
              )}
            </React.Fragment>
          )}
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default RejectedLoanRequestDetail;
