import React, { Component } from "react";
import textFormatService from "../../../../services/utility/textFormatService";
import NewItemComponent from "./newItemComponent";

export interface IRuleProps {
  step: any;
  level: number;
  events?: any;
  viewing: boolean;
  userRoles?: any;
  systemProcess?: any;
}

export interface IRuleState { }

export class Rule extends Component<IRuleProps, IRuleState> {
  render() {
    const { step, viewing, userRoles, systemProcess } = this.props;
    let minComb = step[0] ? step[0].combinationNo : 0,
      maxComb = step[step.length - 1] ? step[step.length - 1].combinationNo : 0;

    // console.log("step: ", step)
    // console.log("step[0].combinationNo: ", step[0].combinationNo)
    // console.log("maxComb: ", maxComb)
    // console.log("step[step.length - 1] : ", step[step.length - 1])

    let list: any = [];
    for (let i = minComb; i <= maxComb; i++) {
      list.push(i);
    }
    // console.log("Steps in rule >", step);
    // console.log("Max Comb >", maxComb);
    // console.log("level >", this.props.level);
    return (
      <React.Fragment key={textFormatService.generateGUID()}>
        <div key={textFormatService.generateGUID()} className="step">
          {/* {console.log(list)} */}
          {list.map((combNo) => (
            <React.Fragment key={textFormatService.generateGUID()}>
              {combNo === minComb ? (
                ""
              ) : (
                <span className="badge badge-light mr-2 ml-2"> OR </span>
              )}
              <div
                key={textFormatService.generateGUID()}
                data-group={combNo}
                className="role"
              >
                {/* {console.log(step)} */}
                {step?.filter((item) => item.combinationNo === combNo)?.map((_item, index) => (
                  <React.Fragment key={textFormatService.generateGUID()}>
                    <p>
                      {_item.roleID ? (
                        <span>
                          <span className="font-weight-bold"> User: </span>
                          {_item.roleName
                            ? _item.roleName
                            : userRoles?.filter(x => x.id === Number(_item.roleID))[0]?.name
                          }{" "}
                          {_item.userCount ? (
                            <span>({_item.userCount})</span>) : ("")}
                        </span>
                      ) : _item.systemProcessID ? (
                        <>
                          <span>
                            <span className="font-weight-bold"> System Process: </span>
                            {_item.processName
                              ? _item.processName
                              : systemProcess?.filter(x => x.id === Number(_item.systemProcessID))[0]?.name
                            } {" "}
                            {_item.acceptanceScore ? (
                              <span>({_item.acceptanceScore})</span>
                            ) : ("")}
                          </span>
                        </>
                      ) : (
                        <>
                          <span>
                            <span className="font-weight-bold"> Privilege Page: </span>
                            {_item.processName
                              ? _item.processName
                              : _item.functionName
                            } {" "}
                            {_item.acceptanceScore ? (
                              <span>({_item.acceptanceScore})</span>
                            ) : ("")}
                          </span>
                        </>
                      )}
                      {viewing ? (
                        ""
                      ) : (
                        <i
                          className="fas fa-minus-circle float-right"
                          style={{ color: "#bd1010", cursor: "pointer" }}
                          onClick={() =>
                            this.props.events.handleOnRuleDelete(_item)
                          }
                        />
                      )}
                    </p>
                    <p>
                      <span className="font-weight-normal"> </span>
                      {_item.applicableAmount}
                    </p>
                    {step?.filter((item) => item.combinationNo === combNo)
                      .length ===
                      index + 1 && !viewing ? (
                      <i
                        className="fas fa-plus-circle"
                        style={{ color: "#043b68", cursor: "pointer" }}
                        onClick={() =>
                          this.props.events.addNewRule(
                            "Rule (And Relationship)",
                            this.props.level,
                            combNo,
                            "",
                            0,
                            0,
                            0,
                            "",
                            "",
                            "",
                            0,
                            false
                          )
                        }
                      />
                    ) : (
                      <React.Fragment key={textFormatService.generateGUID()}>
                        {viewing ? "" : <br />}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </React.Fragment>
          ))}
        </div>
        {!viewing ? (
          <NewItemComponent
            name="new-role"
            content="OR"
            type="rule"
            events={this.props.events}
            eventData={{
              title: "Rule (Or Relationship)",
              level: this.props.level,
              combinationNo: maxComb + 1,
              approverType: "",
              roleID: 0,
              systemProcessID: 0,
              acceptanceScore: 0,
              acceptanceString: ""
            }}
          />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}
