import { axiosSweepInstance as axiosInstance } from "../configs";

class GeneralSettings{

    retrieveGeneralSettings(){
        return axiosInstance.get("/GeneralSettings/RetrieveGeneralSettings");
    }
}

const generalSettingsService = new GeneralSettings();

export default generalSettingsService;