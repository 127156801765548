import React from "react";
import { toast } from "react-toastify";
import BaseFormComponent, {
  IBaseFormState,
} from "../../components/BaseFormComponent";
import FormInputComponent from "../../components/formInputComponent";
import DetailItem from "../../components/detailItem";
import bvnValidationService from "../../services/bvn/bvnValidationService";
import linkedItemsService from "../../services/bvn/linkedItemsService";
import { mapEntityList } from "../../services/utility/autoMapperService";
import DetailItemsTable from "../../components/tableComponents/detailItemsTable";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../services/auth/authService";

interface ViewLinkedAccountsFields {
  bvn: string;
}

class ViewLinkedAccountsForm extends BaseFormComponent<ViewLinkedAccountsFields> {}

interface IViewLinkedAccountsProps {}
interface IViewLinkedAccountsState {
  //   data: any;
  fetching: boolean;
  called: boolean;
  totalSize: number;
  items: any;
}

class ViewLinkedAccounts extends React.Component<
  IViewLinkedAccountsProps,
  IBaseFormState & IViewLinkedAccountsState
> {
  constructor(props: IViewLinkedAccountsProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      //   data: {
      //     bvn: ""
      //   },
      fetching: false,
      called: false,
      totalSize: 0,
      items: {},
    };
  }

  handlePageChange = (page, id) => {
    //do nothing for now
  };

  onFormSubmit(
    fields: ViewLinkedAccountsFields,
    onReloadFieldsCB: any
  ): boolean {
    try {
      this.setState(
        { errors: bvnValidationService.validateViewLinkedAccountsForm(fields) },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: ViewLinkedAccountsFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      // console.log("starting submission...");
      if (fields.bvn === "") {
        toast.error("Bvn cannot be empty");
        return false;
      }

      const decodedToken = decodeToken(getCurrentUserSession().token || "");
      this.setState({ submitting: true, called: false });
      const response = await linkedItemsService.retrieveByBvnForInstitution(
        fields.bvn,
        decodedToken?.["InstitutionCode"]
      );
      // console.log("fetched response :", response);
      response.data.length === 0
        ? this.setState({
            items: [],
            totalSize: 0,
          })
        : this.setState({
            items: mapEntityList({
              entityList: response.data.accounts,
              properties: [
                { oldName: "accountNumber", newName: "accountNumber" },
                { oldName: "institutionName", newName: "Name of Institution" },
                { oldName: "institutionCode", newName: "institutionCode" },
                { oldName: "dateCreated", newName: "dateCreated" },
                { oldName: "dateLinked", newName: "dateLinked" },
              ],
            }),
            totalSize: response.data.accounts.length,
          });
      this.setState({
        submitting: false,
        fetching: false,
        called: true,
      });
      onReloadFieldsCB();
      return true;
    } catch (error) {
      // console.log("error: ", error);
      this.setState({ submitting: false });
      return false;
    }
  }

  render() {
    const {
      errors,
      submitting,
      validated,
      items,
      totalSize,
      fetching,
      called,
    } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>View Linked Accounts</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ViewLinkedAccountsForm
                  initialValues={{ bvn: "" }}
                  FormComponent={({
                    fields: { bvn },
                    onChange,
                    onReloadFields,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <h5 className="card-title">GENERAL INFORMATION</h5>
                          <div className="form-row">
                            <div className="col-md-12">
                              <div className="form-row">
                                <FormInputComponent
                                  id="bvn"
                                  type="text"
                                  name="bvn"
                                  placeholder="Enter Bvn"
                                  divClass={6}
                                  value={bvn}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="card-body">
                          <button
                            type="button"
                            disabled={submitting === true}
                            onClick={(e) => {
                              e.preventDefault();
                              this.onFormSubmit({ bvn }, onReloadFields);
                            }}
                            className="btn btn-sm btn-primary"
                          >
                            {submitting === false ? (
                              <React.Fragment>
                                <i className="fas fa-lg fa-link mr-4" /> View
                                Linked Accounts
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                                Please wait...
                              </React.Fragment>
                            )}
                          </button>
                        </div>
                      </div>
                      {called ? (
                        <React.Fragment>
                          <hr />
                          <div className="form-group row">
                            <div className="col-md-6">
                              <React.Fragment>
                                <dl className="row">
                                  <DetailItem
                                    label="BVN"
                                    value={bvn}
                                    labelSize={2}
                                    valueSize={6}
                                  />
                                </dl>
                              </React.Fragment>
                            </div>
                          </div>
                          <DetailItemsTable
                            title="Linked Accounts"
                            item={{ id: 0 }}
                            handleOnPageChange={this.handlePageChange}
                            content={items}
                            contentCount={totalSize}
                            page={1}
                            fetching={fetching}
                          />
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </form>
                  )}
                ></ViewLinkedAccountsForm>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ViewLinkedAccounts;
