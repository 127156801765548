import React from "react";
import { Modal } from "react-bootstrap";

export interface ILoaderModalProps {}
interface ILoaderModalState {}
class LoaderModal extends React.Component<
  ILoaderModalProps,
  ILoaderModalState
> {
  render() {
    return (
      <Modal size="sm" backdrop="static" show={true} style={{backgroundColor: "transparent"}}>
        <Modal.Body style={{height: "200px", backgroundColor: "transparent"}}>
            <div id="loader" className="loader" style={{backgroundColor: "transparent"}}>
            <div className="plane-container">
                <div className="preloader-wrapper small active">
                <div className="spinner-layer spinner-blue">
                    <div className="circle-clipper left">
                    <div className="circle"></div>
                    </div>
                    <div className="gap-patch">
                    <div className="circle"></div>
                    </div>
                    <div className="circle-clipper right">
                    <div className="circle"></div>
                    </div>
                </div>
    
                <div className="spinner-layer spinner-red">
                    <div className="circle-clipper left">
                    <div className="circle"></div>
                    </div>
                    <div className="gap-patch">
                    <div className="circle"></div>
                    </div>
                    <div className="circle-clipper right">
                    <div className="circle"></div>
                    </div>
                </div>
    
                <div className="spinner-layer spinner-yellow">
                    <div className="circle-clipper left">
                    <div className="circle"></div>
                    </div>
                    <div className="gap-patch">
                    <div className="circle"></div>
                    </div>
                    <div className="circle-clipper right">
                    <div className="circle"></div>
                    </div>
                </div>
    
                <div className="spinner-layer spinner-green">
                    <div className="circle-clipper left">
                    <div className="circle"></div>
                    </div>
                    <div className="gap-patch">
                    <div className="circle"></div>
                    </div>
                    <div className="circle-clipper right">
                    <div className="circle"></div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </Modal.Body>
      </Modal>
    );
  }
}
export default LoaderModal;
