import React, { Fragment } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import {
  Modal,
  Button,
  Card
} from "react-bootstrap";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import authValidationService from "../../../services/auth/authValidationService";
import DetailItem from "../../../components/detailItem";
import { ActionTypes } from "../../../enums/actionTypes";
import institutionFeeConfigService from "../../../services/billing/institutionFeeConfigService";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import institutionsService from "../../../services/auth/institutionsService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";

interface EditInstitutionThirdPartyFeeConfigurationFields {
  appzonePercentageAmountPerActiveLoan?: string;
  appzonePercentageAmountPerLostLoan?: string;
  appzoneFlatAmountPerSuccessfulTrx?: string;
  appzoneMinimumFeePerSuccessfulTrx?: string;
  appzoneMaximumFeePerSuccessfulTrx?: string;
  thirdPartyPercentageAmountSwept?: string;
  thirdParty?: string;
  thirdPartyFlatAmountPerSuccessfulTrx?: string;
  thirdPartyMinimumFeePerSuccessfulTrx?: string;
  thirdPartyMaximumFeePerSuccessfulTrx?: string;
}

class EditInstitutionThirdPartyFeeConfigurationForm extends BaseFormComponent<EditInstitutionThirdPartyFeeConfigurationFields> { }

interface IEditInstitutionThirdPartyFeeConfigurationProps {
  item: any;
  thirdPartyFeeConfigItem: any;
  toggleEditModal: any;
}

interface IEditInstitutionThirdPartyFeeConfigurationState {
  item: any;
}

class EditInstitutionThirdPartyFeeConfiguration extends React.Component<
  IEditInstitutionThirdPartyFeeConfigurationProps,
  IBaseFormState & IEditInstitutionThirdPartyFeeConfigurationState
> {
  constructor(props: IEditInstitutionThirdPartyFeeConfigurationProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      item: {}
    };
  }

  static getDerivedStateFromProps(
    nextProps: IEditInstitutionThirdPartyFeeConfigurationProps,
    prevState
  ) {
    return {
      item: nextProps.item
    }
  }

  onFormSubmit(
    fields: EditInstitutionThirdPartyFeeConfigurationFields,
    onReloadFieldsCB: any
  ): boolean {
    try {
      this.setState(
        {
          errors:
            authValidationService.validateAddThirdPartyFeeConfigurationForm(fields)
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (
    fields: EditInstitutionThirdPartyFeeConfigurationFields,
    onReloadFieldsCB: any
  ) => {
    try {
      this.setState({ submitting: true });
      const payload = this.props.thirdPartyFeeConfigItem;

      var institution = await institutionsService.retrieveInstitutionByCode(this.props.item.code);
      payload.appzonePercentageAmountPerActiveLoan = Number(fields.appzonePercentageAmountPerActiveLoan);
      payload.appzonePercentageAmountPerLostLoan = Number(fields.appzonePercentageAmountPerLostLoan);
      payload.appzoneFlatAmountPerSuccessfulTrx = Number(fields.appzoneFlatAmountPerSuccessfulTrx);
      payload.appzoneMinimumFeePerSuccessfulTrx = Number(fields.appzoneMinimumFeePerSuccessfulTrx);
      payload.appzoneMaximumFeePerSuccessfulTrx = Number(fields.appzoneMaximumFeePerSuccessfulTrx);
      payload.thirdPartyPercentageAmountSwept = Number(fields.thirdPartyPercentageAmountSwept);
      payload.thirdParty = payload.thirdParty;
      payload.thirdPartyFlatAmountPerSuccessfulTrx = Number(fields.thirdPartyFlatAmountPerSuccessfulTrx);
      payload.thirdPartyMinimumFeePerSuccessfulTrx = Number(fields.thirdPartyMinimumFeePerSuccessfulTrx);
      payload.thirdPartyMaximumFeePerSuccessfulTrx = Number(fields.thirdPartyMaximumFeePerSuccessfulTrx);
      payload.institutionCode = institution.data.code;
      payload.serviceOffering = payload.serviceOffering
      payload.institutionFeeConfigurationId = payload.institutionFeeConfigurationId;

      var response = await institutionFeeConfigService.updateInstitutionFeeConfig(payload.institutionFeeConfigurationId, payload);

      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit ThirdParty Fee Configuration",
          description: `Edited details for ThirdParty Fee Configuration ${institution.data.name}`,
        });
        toast.info(
          `ThirdParty Fee Configuration Edited successfully!`,
          {
            autoClose: 6000,
            type: toast.TYPE.DEFAULT,
            hideProgressBar: false,
          }
        );
      }
      onReloadFieldsCB();
      this.setState({ submitting: false },
        () => this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
    } finally {
      this.setState({ submitting: false });
    }
  };

  render() {
    const {
      validated,
      submitting,
      errors
    } = this.state;
    const {
      thirdPartyFeeConfigItem
    } = this.props;

    return (
      <EditInstitutionThirdPartyFeeConfigurationForm
        initialValues={{
          appzonePercentageAmountPerActiveLoan: thirdPartyFeeConfigItem?.appzonePercentageAmountPerActiveLoan,
          appzonePercentageAmountPerLostLoan: thirdPartyFeeConfigItem?.appzonePercentageAmountPerLostLoan,
          appzoneFlatAmountPerSuccessfulTrx: thirdPartyFeeConfigItem?.appzoneFlatAmountPerSuccessfulTrx,
          appzoneMinimumFeePerSuccessfulTrx: thirdPartyFeeConfigItem?.appzoneMinimumFeePerSuccessfulTrx,
          appzoneMaximumFeePerSuccessfulTrx: thirdPartyFeeConfigItem?.appzoneMaximumFeePerSuccessfulTrx,
          thirdPartyPercentageAmountSwept: thirdPartyFeeConfigItem?.thirdPartyPercentageAmountSwept,
          thirdParty: thirdPartyFeeConfigItem?.thirdParty,
          thirdPartyFlatAmountPerSuccessfulTrx: thirdPartyFeeConfigItem?.thirdPartyFlatAmountPerSuccessfulTrx,
          thirdPartyMinimumFeePerSuccessfulTrx: thirdPartyFeeConfigItem?.thirdPartyMinimumFeePerSuccessfulTrx,
          thirdPartyMaximumFeePerSuccessfulTrx: thirdPartyFeeConfigItem?.thirdPartyMaximumFeePerSuccessfulTrx,
        }}
        FormComponent={({
          fields: {
            appzonePercentageAmountPerActiveLoan,
            appzonePercentageAmountPerLostLoan,
            appzoneFlatAmountPerSuccessfulTrx,
            appzoneMinimumFeePerSuccessfulTrx,
            appzoneMaximumFeePerSuccessfulTrx,
            thirdPartyPercentageAmountSwept,
            thirdParty,
            thirdPartyFlatAmountPerSuccessfulTrx,
            thirdPartyMinimumFeePerSuccessfulTrx,
            thirdPartyMaximumFeePerSuccessfulTrx,
          },
          onChange,
          onReloadFields,
          onHandleSubmit
        }) => (
          <Card.Body>
            <Fragment>
              {_.isEmpty(thirdPartyFeeConfigItem) ? (
                `Central Fee Configuration for ThirdParty is not available`
              ) : (
                <Fragment>
                  <div className="card">
                    <div className="card-header clear-fix">
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        Appzone Fee
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <dl className="row">
                            <DetailItem
                              label="Active Loan Percentage"
                              labelSize={8}
                              valueSize={4}
                              value={
                                <DetailItemInputComponent
                                  id="appzonePercentageAmountPerActiveLoan"
                                  type="text"
                                  name="appzonePercentageAmountPerActiveLoan"
                                  placeholder=""
                                  value={appzonePercentageAmountPerActiveLoan}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                            />
                            <DetailItem
                              label="Lost Loan Percentage"
                              labelSize={8}
                              valueSize={4}
                              value={
                                <DetailItemInputComponent
                                  id="appzonePercentageAmountPerLostLoan"
                                  type="text"
                                  name="appzonePercentageAmountPerLostLoan"
                                  placeholder=""
                                  value={appzonePercentageAmountPerLostLoan}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                            />
                            <DetailItem
                              label="Flat Amount Per Transaction"
                              labelSize={8}
                              valueSize={4}
                              value={
                                <DetailItemInputComponent
                                  id="appzoneFlatAmountPerSuccessfulTrx"
                                  type="text"
                                  name="appzoneFlatAmountPerSuccessfulTrx"
                                  placeholder=""
                                  value={appzoneFlatAmountPerSuccessfulTrx}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                            />
                          </dl>
                        </div>
                        <div className="col-md-6">
                          <dl className="row">
                            <DetailItem
                              label="Minimum Fee Per Transaction"
                              labelSize={8}
                              valueSize={4}
                              value={
                                <DetailItemInputComponent
                                  id="appzoneMinimumFeePerSuccessfulTrx"
                                  type="text"
                                  name="appzoneMinimumFeePerSuccessfulTrx"
                                  placeholder=""
                                  value={appzoneMinimumFeePerSuccessfulTrx}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                            />
                            <DetailItem
                              label="Maximum Fee Per Transaction"
                              labelSize={8}
                              valueSize={4}
                              value={
                                <DetailItemInputComponent
                                  id="appzoneMaximumFeePerSuccessfulTrx"
                                  type="text"
                                  name="appzoneMaximumFeePerSuccessfulTrx"
                                  placeholder=""
                                  value={appzoneMaximumFeePerSuccessfulTrx}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                            />
                          </dl>
                        </div>
                      </div>
                    </div>
                    <div className="card-header clear-fix">
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        ThirdParty Cost
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <dl className="row">
                            <DetailItem
                              label="Amount Swept Percentage"
                              labelSize={8}
                              valueSize={4}
                              value={
                                <DetailItemInputComponent
                                  id="thirdPartyPercentageAmountSwept"
                                  type="text"
                                  name="thirdPartyPercentageAmountSwept"
                                  placeholder=""
                                  value={thirdPartyPercentageAmountSwept}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                            />
                            <DetailItem
                              label="Flat Amount Per Transaction"
                              labelSize={8}
                              valueSize={4}
                              value={
                                <DetailItemInputComponent
                                  id="thirdPartyFlatAmountPerSuccessfulTrx"
                                  type="text"
                                  name="thirdPartyFlatAmountPerSuccessfulTrx"
                                  placeholder=""
                                  value={thirdPartyFlatAmountPerSuccessfulTrx}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                            />
                          </dl>
                        </div>
                        <div className="col-md-6">
                          <dl className="row">
                            <DetailItem
                              label="Minimum Fee Per Transaction"
                              labelSize={8}
                              valueSize={4}
                              value={
                                <DetailItemInputComponent
                                  id="thirdPartyMinimumFeePerSuccessfulTrx"
                                  type="text"
                                  name="thirdPartyMinimumFeePerSuccessfulTrx"
                                  placeholder=""
                                  value={thirdPartyMinimumFeePerSuccessfulTrx}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                            />
                            <DetailItem
                              label="Maximum Fee Per Transaction"
                              labelSize={8}
                              valueSize={4}
                              value={
                                <DetailItemInputComponent
                                  id="thirdPartyMaximumFeePerSuccessfulTrx"
                                  type="text"
                                  name="thirdPartyMaximumFeePerSuccessfulTrx"
                                  placeholder=""
                                  value={thirdPartyMaximumFeePerSuccessfulTrx}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                            />
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
            </Fragment>
            {_.isEmpty(thirdPartyFeeConfigItem) ?
              <></> :
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.UPDATE,
                      "ThirdParty Fee Configuration",
                      () => {
                        this.onFormSubmit(
                          {
                            appzonePercentageAmountPerActiveLoan,
                            appzonePercentageAmountPerLostLoan,
                            appzoneFlatAmountPerSuccessfulTrx,
                            appzoneMinimumFeePerSuccessfulTrx,
                            appzoneMaximumFeePerSuccessfulTrx,
                            thirdPartyPercentageAmountSwept,
                            thirdParty,
                            thirdPartyFlatAmountPerSuccessfulTrx,
                            thirdPartyMinimumFeePerSuccessfulTrx,
                            thirdPartyMaximumFeePerSuccessfulTrx,
                          },
                          onReloadFields
                        );
                      }
                    );
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
              </Modal.Footer>
            }
          </Card.Body>
        )}
      ></EditInstitutionThirdPartyFeeConfigurationForm>
    );
  }
}

export default EditInstitutionThirdPartyFeeConfiguration;
