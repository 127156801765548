import React, { Component, Fragment } from "react";
import _ from "lodash";
import { Card } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";

interface IInstitutionThirdPartyFeeConfigurationDetailProps {
  item: any;
  thirdPartyFeeConfigItem: any;
}

interface IInstitutionThirdPartyFeeConfigurationDetailState {
  item: any;
  updatingStatus: boolean;
}
class InstitutionThirdPartyFeeConfigurationDetail extends Component<
  IInstitutionThirdPartyFeeConfigurationDetailProps,
  IInstitutionThirdPartyFeeConfigurationDetailState
> {
  constructor(props: IInstitutionThirdPartyFeeConfigurationDetailProps) {
    super(props);
    this.state = {
      item: {},
      updatingStatus: false,
    }
  };

  render() {
    const {
      thirdPartyFeeConfigItem,
    } = this.props;

    return (
      <Card.Body>
        <Fragment>
          {_.isEmpty(thirdPartyFeeConfigItem) ? (
            `Central Fee Configuartion for ${thirdPartyFeeConfigItem?.thirdParty} is not available`
          ) : (
            <Fragment>
              <div className="card">
                <div className="card-header clear-fix">
                  <h6
                    className="card-title float-left"
                    style={{ marginBottom: "0px" }}
                  >
                    Appzone Fee
                  </h6>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <dl className="row">
                        <DetailItem
                          label="Active Loan Percentage"
                          labelSize={9}
                          valueSize={3}
                          value={thirdPartyFeeConfigItem?.appzonePercentageAmountPerActiveLoan + " %"}
                        />
                        <DetailItem
                          label="Lost Loan Percentage"
                          labelSize={9}
                          valueSize={3}
                          value={thirdPartyFeeConfigItem?.appzonePercentageAmountPerLostLoan + " %"}
                        />
                        <DetailItem
                          label="Flat Amount Per Transaction"
                          labelSize={9}
                          valueSize={3}
                          value={thirdPartyFeeConfigItem?.appzoneFlatAmountPerSuccessfulTrx}
                        />
                      </dl>
                    </div>
                    <div className="col-md-6">
                      <dl className="row">
                        <DetailItem
                          label="Minimum Fee Per Transaction"
                          labelSize={9}
                          valueSize={3}
                          value={thirdPartyFeeConfigItem?.appzoneMinimumFeePerSuccessfulTrx}
                        />
                        <DetailItem
                          label="Maximum Fee Per Transaction"
                          labelSize={9}
                          valueSize={3}
                          value={thirdPartyFeeConfigItem?.appzoneMaximumFeePerSuccessfulTrx}
                        />
                      </dl>
                    </div>
                  </div>
                </div>
                <div className="card-header clear-fix">
                  <h6
                    className="card-title float-left"
                    style={{ marginBottom: "0px" }}
                  >
                    ThirdParty Cost
                  </h6>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <dl className="row">
                        <DetailItem
                          label="Amount Swept Percentage"
                          labelSize={9}
                          valueSize={3}
                          value={thirdPartyFeeConfigItem?.thirdPartyPercentageAmountSwept + " %"}
                        />
                        <DetailItem
                          label="Flat Amount Per Transaction"
                          labelSize={9}
                          valueSize={3}
                          value={thirdPartyFeeConfigItem?.thirdPartyFlatAmountPerSuccessfulTrx}
                        />
                      </dl>
                    </div>
                    <div className="col-md-6">
                      <dl className="row">
                        <DetailItem
                          label="Minimum Fee Per Transaction"
                          labelSize={9}
                          valueSize={3}
                          value={thirdPartyFeeConfigItem?.thirdPartyMinimumFeePerSuccessfulTrx}
                        />
                        <DetailItem
                          label="Maximum Fee Per Transaction"
                          labelSize={9}
                          valueSize={3}
                          value={thirdPartyFeeConfigItem?.thirdPartyMaximumFeePerSuccessfulTrx}
                        />
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </Fragment>
      </Card.Body>
    );
  }
}

export default InstitutionThirdPartyFeeConfigurationDetail;