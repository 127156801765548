import http from "../httpService";
import { status } from "../approval/approvableService";
import { getIpAddress, cryptoAESEncrypt } from "../utility/textFormatService";
import { decodeToken } from "react-jwt";
import * as CryptoJS from "crypto-js";
import _ from "lodash";
import { getCurrentUserSession } from "../auth/authService";


const bankOneWebAPI = process.env.REACT_APP_BANKONE_API_BASEADDRESS
const bankOneThirdPartyAPI = process.env.REACT_APP_BANKONE_THIRDPARTYSERVICE_BASEADDRESS;
const recovaBankOneHandShakeBaseAddress = process.env.REACT_APP_RECOVA_BANKONE_HANDSHAKE_BASEADDRESS;



export function retrieveCustomerByBVN(authToken: string, bvn: string) {
    var url =
        bankOneWebAPI +
        `Customer/GetCustomerByBVN/1?authtoken=${authToken}&bvn=${bvn}`;

    return http.get(url, {
        url: url,
        method: "get",
    });
}

export function retrieveAccountOfficersByInstitution(authToken: string) {
    var url =
        bankOneWebAPI +
        `AccountOfficer/Get/1?authtoken=${authToken}`;

    return http.get(url, {
        url: url,
        method: "get",
    });
}

export function retrieveProductsByInstitution(authToken: string) {
    var url =
        bankOneWebAPI +
        `Product/Get/1?authtoken=${authToken}`;

    return http.get(url, {
        url: url,
        method: "get",
    });
}

export async function createBankOneAccount(acctDetails: any, authToken: string) {

    var url = recovaBankOneHandShakeBaseAddress + `NominalAccount/CreateBankOneAccountQuick?institutionId=${authToken}`;

    return http.post(url, acctDetails, {
        url: url,
        data: acctDetails,
        method: "post",
    });
}

export async function placePND(request: any) {

    var url = recovaBankOneHandShakeBaseAddress + `Transaction/ActivatePND`;

    return http.post(url, request, {
        url: url,
        data: request,
        method: "post",
    });
}

export default {
    retrieveCustomerByBVN,
    retrieveAccountOfficersByInstitution,
    retrieveProductsByInstitution,
    createBankOneAccount,
    placePND
}
