import React, { Fragment } from "react";
import { toast } from "react-toastify";
import { Modal, Button, Accordion, Card } from "react-bootstrap";
import _, { isEmpty } from "lodash";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import workFlowConfigService from "../../../services/hybridAppraisal/workFlowConfigService";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../components/detailItem";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import { IMultiSelectItems } from "../../../components/formMultiSelectComponent";
import EditMandatoryFieldConfig from "./editMandatoryFieldConfig";
import { ContextAwareToggle } from "../appraisalPlan/appraisalPlanDetail";

interface EditWorkFlowConfigFields {
  name: string;
}

class EditWorkFlowConfigForm extends BaseFormComponent<EditWorkFlowConfigFields> { }

interface IEditWorkFlowConfigProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
}

interface IEditWorkFlowConfigState {
  ownUpdate: boolean;
  fetchingRequiredItems: boolean;
  selectedWorkFlowSteps: any;
  selectedDataEntryTabs: any;
  dataEntrySections: any;
  showMandatoryFieldModal: boolean;
  configuredMandatoryFields: boolean;
}

class EditWorkFlowConfig extends React.Component<
  IEditWorkFlowConfigProps,
  IBaseFormState & IEditWorkFlowConfigState
> {
  constructor(props: IEditWorkFlowConfigProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      ownUpdate: false,
      fetchingRequiredItems: false,
      selectedWorkFlowSteps: [],
      selectedDataEntryTabs: [],
      dataEntrySections: [],
      showMandatoryFieldModal: false,
      configuredMandatoryFields: false
    };
  }

  static getDerivedStateFromProps(
    nextProps: IEditWorkFlowConfigProps,
    prevState: IEditWorkFlowConfigState
  ) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isEmpty(nextProps.item)) {
      return null;
    }
    return {
      selectedWorkFlowSteps: nextProps.item.workflowSteps.map(
        (item) => item
      ),
      selectedDataEntryTabs: nextProps.item.dataEntryTabs.map(
        (item) => item
      ),
      dataEntrySections: nextProps.item.dataEntrySections.map(
        (item) => item
      ),
    };
  }

  onFormSubmit(fields: EditWorkFlowConfigFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        { ownUpdate: true }, () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        });
      this.setState({ validated: true, ownUpdate: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  submit = async (
    fields: EditWorkFlowConfigFields,
    onReloadFieldsCB: any
  ) => {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const { selectedWorkFlowSteps, selectedDataEntryTabs } = this.state;
      const { dataEntrySections } = this.state;
      const payload = { ...this.props.item };

      payload.name = fields.name;
      payload.workflowSteps = selectedWorkFlowSteps;
      payload.dataEntryTabs = selectedDataEntryTabs;
      payload.dataEntrySections = dataEntrySections;

      const response = await workFlowConfigService.editWorkFlowConfig(payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit Work Flow Config",
          description: `Edited details for Work Flow Config ${fields.name}`,
        });
        toast.info(`Data Entry Config ${fields.name} Edited successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      }
      onReloadFieldsCB();
      this.setState({ submitting: false, ownUpdate: true }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      toast.error(error.response.data, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      this.setState({ submitting: false, ownUpdate: true });
    } finally {
      return true;
    }
  }

  handleOnWorkFlowStepsChange = async (item) => {
    const selectedWorkFlowSteps = [...this.state.selectedWorkFlowSteps];
    if (selectedWorkFlowSteps.indexOf(item) !== -1) {
      selectedWorkFlowSteps.splice(selectedWorkFlowSteps.indexOf(item), 1);
    } else {
      selectedWorkFlowSteps.push(item);
    }
    this.setState({ selectedWorkFlowSteps, ownUpdate: true });
  };

  handleOnDataEntryTabsChange = async (item) => {
    const selectedDataEntryTabs = [...this.state.selectedDataEntryTabs];
    if (selectedDataEntryTabs.indexOf(item) !== -1) {
      selectedDataEntryTabs.splice(selectedDataEntryTabs.indexOf(item), 1);
    } else {
      selectedDataEntryTabs.push(item);
    }
    this.setState({ selectedDataEntryTabs, ownUpdate: true });
  };

  toggleMandatoryFieldModal = () => {
    this.setState({ showMandatoryFieldModal: !this.state.showMandatoryFieldModal, ownUpdate: true });
  };

  handleOnMandatoryFieldSave = (toSave: boolean, payload) => {
    if (toSave) {
      this.setState({
        dataEntrySections: payload,
        showMandatoryFieldModal: false,
        configuredMandatoryFields: true,
        ownUpdate: true,
      });
    } else {
      this.setState({ showMandatoryFieldModal: false, ownUpdate: true });
    }
  };

  resetFields() {
    this.setState({
      submitting: false,
      ownUpdate: true,
      selectedWorkFlowSteps: [],
      selectedDataEntryTabs: [],
      dataEntrySections: []
    });
  }

  resetAllFields() {
    this.setState({
      submitting: false,
      ownUpdate: true
    })
  }

  render() {
    const { item, showEditModal, toggleEditModal } = this.props;
    const {
      validated,
      submitting,
      errors,
      selectedWorkFlowSteps,
      selectedDataEntryTabs,
      dataEntrySections,
      configuredMandatoryFields
    } = this.state;

    let workflowStepsInputData: IMultiSelectItems[] = [
      { label: "Data Validation", value: "DataValidation" },
      { label: "Document Generation", value: "DocumentGeneration" },
      { label: "Document Collection", value: "DocumentCollection" },
      { label: "Pre Disbursement", value: "PreDisbursement" },
    ];

    let dataEntryInputData: IMultiSelectItems[] = [
      { label: "Personal Details", value: "PersonalDetails" },
      { label: "Contact Details", value: "ContactDetails" },
      { label: "Work Details", value: "WorkDetails" },
      { label: "Reference Details", value: "ReferenceDetails" },
      { label: "Collateral Details", value: "CollateralDetails" },
      { label: "Document Collection", value: "DocumentCollection" },
      { label: "Notepad Details", value: "NotepadDetails" },
      { label: "Income Details", value: "IncomeDetails" },
      { label: "Asset Details", value: "AssetDetails" },
      { label: "Property Details", value: "PropertyDetails" },
      { label: "Loan Details", value: "LoanDetails" },
      { label: "Corporate Details", value: "CorporateDetails" },
      { label: "Stakeholder Details", value: "StakeholderDetails" },
      { label: "Corporate Analysis", value: "CorporateAnalysis" },
      { label: "Bank Statement", value: "BankStatement" },
      { label: "Other Details", value: "OtherDetails" },
      { label: "Verify", value: "Verify" },
    ];

    if (_.isEmpty(item)) {
      return null
    }

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-columns fa-sm cyan-text mr-3" />
              Edit Data Entry Config -{" "}
              <span className="font-weight-bold">{item.name}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditWorkFlowConfigForm
          initialValues={{
            name: item.name,
          }}
          FormComponent={({
            fields: { name },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <Fragment>
              <Modal.Body>
                <div className="card">
                  {/* General Information */}
                  <Fragment>
                    <div className="card-header clear-fix">
                      {_.isEmpty(item) ? (
                        <Skeleton width={200} />
                      ) : (
                        <h6
                          className="card-title float-left"
                          style={{ marginBottom: "0px" }}
                        >
                          <b>General Information</b>
                        </h6>
                      )}
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          {_.isEmpty(item) ? (
                            <Skeleton count={3} width={300} />
                          ) : (
                            <dl className="row">
                              <DetailItem
                                label="Name"
                                value={
                                  <DetailItemInputComponent
                                    id="name"
                                    type="text"
                                    name="name"
                                    placeholder=""
                                    value={name}
                                    required={true}
                                    validated={validated}
                                    errors={errors}
                                    onChange={onChange}
                                  />
                                }
                                labelSize={5}
                                valueSize={7}
                              />
                            </dl>
                          )}
                        </div>
                      </div>
                      <hr />

                    </div>
                  </Fragment>

                  {/* WorkFlow Steps */}
                  {/* <Accordion defaultActiveKey="0">
                    <Card>
                      <ContextAwareToggle
                        eventKey={`0`}
                        header={
                          <React.Fragment>
                            <i
                              className="fa fa-info-circle"
                              aria-hidden="true"
                              style={{ color: "#878a8a" }}
                            ></i>{" "}
                            WorkFlow Steps
                          </React.Fragment>
                        }
                      ></ContextAwareToggle>
                      <Accordion.Collapse eventKey={`0`}>
                        <Card.Body>
                          <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                            <div className="row">
                              <div className="col-md-12">
                                <Fragment>
                                  {workflowStepsInputData?.map(
                                    (step) => (
                                      <div
                                        key={step.value}
                                        style={{ border: '2px #84d4fc solid', borderRadius: '4px', marginTop: '5px', inlineSize: "100%" }}
                                      >
                                        <div
                                          className="custom-control custom-checkbox"
                                          style={{ display: 'inline-block' }}
                                          key={step.value}
                                        >
                                          <input
                                            id={step.value}
                                            name={step.value}
                                            className="custom-control-input"
                                            type="checkbox"
                                            onChange={() => this.handleOnWorkFlowStepsChange(step.value)}
                                            checked={selectedWorkFlowSteps.includes(step.value)}
                                          />
                                          <label
                                            className="custom-control-label pt-1"
                                            htmlFor={step.value}
                                          >
                                            {step.label}
                                          </label>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </Fragment>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion> */}

                  {/* Data Entry Tabs */}
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <ContextAwareToggle
                        eventKey={`0`}
                        header={
                          <React.Fragment>
                            <i
                              className="fa fa-info-circle"
                              aria-hidden="true"
                              style={{ color: "#878a8a" }}
                            ></i>{" "}
                            Data Entry Tabs
                          </React.Fragment>
                        }
                      ></ContextAwareToggle>
                      <Accordion.Collapse eventKey={`0`}>
                        <Card.Body>
                          <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                            <div className="row">
                              <div className="col-md-12">
                                <Fragment>
                                  {dataEntryInputData?.map(
                                    (tab) => (
                                      <div
                                        key={tab.value}
                                        style={{ border: '2px #84d4fc solid', borderRadius: '4px', marginTop: '5px', inlineSize: "100%" }}
                                      >
                                        <div
                                          className="custom-control custom-checkbox"
                                          style={{ display: 'inline-block' }}
                                          key={tab.value}
                                        >
                                          <input
                                            id={tab.value}
                                            name={tab.value}
                                            className="custom-control-input"
                                            type="checkbox"
                                            onChange={() => this.handleOnDataEntryTabsChange(tab.value)}
                                            checked={selectedDataEntryTabs.includes(tab.value)}
                                          />
                                          <label
                                            className="custom-control-label pt-1"
                                            htmlFor={tab.value}
                                          >
                                            {tab.label}
                                          </label>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </Fragment>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  {/* Mandatory Fields */}
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <ContextAwareToggle
                        eventKey={`0`}
                        header={
                          <React.Fragment>
                            <i
                              className="fa fa-info-circle"
                              aria-hidden="true"
                              style={{ color: "#878a8a" }}
                            ></i>{" "}
                            Mandatory Fields
                          </React.Fragment>
                        }
                      ></ContextAwareToggle>
                      <Accordion.Collapse eventKey={`0`}>
                        <Card.Body>
                          <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                            <div className="row">
                              <div className="col-md-12">
                                <button
                                  className={(!configuredMandatoryFields) ?
                                    "btn btn-sm btn-primary" : "btn btn-sm btn-success"}
                                  onClick={() => this.toggleMandatoryFieldModal()}
                                  disabled={isEmpty(selectedDataEntryTabs)}
                                >
                                  <i className="far fa-edit mr-3" />
                                  Configure Mandatory Fields
                                </button>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  <EditMandatoryFieldConfig
                    showPatchModal={this.state.showMandatoryFieldModal}
                    togglePatchModal={(toSave: boolean, payload: any) => {
                      this.handleOnMandatoryFieldSave(toSave, payload);
                    }}
                    item={selectedDataEntryTabs}
                    dataEntrySections={dataEntrySections}
                  />

                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(e, ActionTypes.UPDATE, "Work Flow Config", () => {
                      this.onFormSubmit(
                        { name },
                        onReloadFields
                      );
                    });
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </Fragment>
          )}
        ></EditWorkFlowConfigForm>
      </Modal>
    );
  }
}

export default EditWorkFlowConfig;
