import http from "../httpService";
import { IFilterData } from "../approval/approvalItemService";

const appraisalBaseAddress = process.env.REACT_APP_HYBRIDAPPRAISAL_BASEADDRESS;

export function activateInstitution(payload) {
  return http.post(
    appraisalBaseAddress + `Institution/ActivateInstitution`,
    payload
  );
}

export function updateInstitution(payload) {
  return http.put(
    appraisalBaseAddress + `Institution/EditInstitution`,
    payload
  );
}

export function retrieveById(id) {
  return http.get(
    appraisalBaseAddress + `Institution/RetrieveById/${id}`
  );
}

export function retrieveByInstitutionCode(institutionCode) {
  return http.get(
    appraisalBaseAddress + `Institution/RetrieveByInstitutionCode/${institutionCode}`
  );
}

export function retrieveAll() {
  return http.get(
    appraisalBaseAddress + `Institution/RetrieveAll`
  );
}

export function filterInstitution(
  payload: IFilterData,
  maxSize: number,
  pageNum: number
) {
  payload = { ...payload };
  return http.post(
    appraisalBaseAddress +
    `Institution/FilterInstitution?pageNum=${pageNum}&maxSize=${maxSize}`,
    payload
  );
}

export function profileCreditPolicyParametersToInstitution(institutionCode, payload) {
  return http.post(
    appraisalBaseAddress + `Institution/ProfileCreditPolicyParametersToInstitution/${institutionCode}`,
    payload
  );
}
export function validateEmployerListValues(payload) {
  return http.post(
    appraisalBaseAddress + `Institution/ValidateEmployerListValues`,
    payload
  );
}
export function uploadEmployerListValues(payload) {
  return http.post(
    appraisalBaseAddress + `Institution/UploadEmployerListValues`,
    payload
  );
}

export function addEmployer(institutionCode, payload) {
  return http.post(
    appraisalBaseAddress + `Institution/AddEmployer/${institutionCode}`,
    payload
  );
}

export default {
  activateInstitution,
  updateInstitution,
  retrieveById,
  retrieveByInstitutionCode,
  retrieveAll,
  filterInstitution,
  profileCreditPolicyParametersToInstitution,
  validateEmployerListValues,
  uploadEmployerListValues,
  addEmployer
};
