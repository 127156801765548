import React from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import billingValidationService from "../../../services/billing/billingValidationService";
import { ISelectItems } from "../../../components/detailItemSelectBox";
import FormInputComponent from "../../../components/formInputComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import SelectInputComponent from "../../../components/formSelectComponent";
import thirdPartyFeeConfigService from "../../../services/billing/thirdPartyFeeConfigService";
import HybridAppraisalBillingService from "../../../services/billing/HybridAppraisalBillingService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import institutionFeeConfigService from "../../../services/billing/institutionFeeConfigService";
import sweepConfigService from "../../../services/sweep/sweepConfigService";
import institutionsService from "../../../services/auth/institutionsService";

interface AddHybridAppraisalSubConfigFields {
  institutionCategory: string;
  feeName: string;
  subscriptionFrequency: string;
  requestLimit: string;
  price: string;
  payAsYouGoPrice: string;
}

class AddHybridAppraisalSubConfigForm extends BaseFormComponent<AddHybridAppraisalSubConfigFields> { }

interface IAddHybridAppraisalSubConfigProps { }
interface IAddHybridAppraisalSubConfigState { }

class AddHybridAppraisalSubConfig extends React.Component<
  IAddHybridAppraisalSubConfigProps,
  IBaseFormState & IAddHybridAppraisalSubConfigState
> {
  constructor(props: IAddHybridAppraisalSubConfigProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
    };
  }

  onFormSubmit(fields: AddHybridAppraisalSubConfigFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {},
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AddHybridAppraisalSubConfigFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true });
      const payload: any = {};

      payload.institutionCategory = fields.institutionCategory;
      payload.volumeTier = fields.feeName;
      payload.subscriptionFrequency = "Monthly";
      payload.requestCount = Number(fields.requestLimit);
      payload.price = Number(fields.price);
      payload.payAsYouGoPrice = Number(fields.payAsYouGoPrice);
      // console.log("payload: ", payload);

      //   var feeConfig = await thirdPartyFeeConfigService.createSubscriptionFee(fields.serviceOffering, fields.institutionCategory, fields.thirdParty);
      //   if ((feeConfig.data).length !== 0) {
      //     toast.error(`ThirdParty Fee Configuration for ${fields.institutionCategory} (${fields.serviceOffering}) already exist`, {
      //       autoClose: 6000,
      //       type: toast.TYPE.ERROR,
      //       hideProgressBar: false,
      //     });
      //   }
      //   else {
      const response = await HybridAppraisalBillingService.createSubscriptionFee(payload);
      if (response.status >= 200 && response.status <= 300) {
        toast.info(`Hybrid Appraisal Subscription Fee Configuration for ${fields.institutionCategory} created successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false });


      onReloadFieldsCB();
    }
    catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
    } finally {
      this.setState({ submitting: false });
      return true;
    }
  }

  render() {
    const { errors, validated } = this.state;

    let institutionCategoryInputData: ISelectItems[] = [
      { name: "Commercial Bank", value: "CommercialBank" },
      { name: "BankOne OFI", value: "BankOneOFI" },
      { name: "OFI", value: "OFI" },
    ];

    let subscriptionFrequencyInputData: ISelectItems[] = [
      { name: "Monthly", value: "Monthly" },
      { name: "Quarterly", value: "Quarterly" },
      { name: "Annually", value: "Annually" },
    ];

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Add Hybrid Appraisal Subscription Fee Configuration</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AddHybridAppraisalSubConfigForm
                  initialValues={{
                    institutionCategory: "",
                    feeName: "",
                    subscriptionFrequency: "",
                    price: "",
                    payAsYouGoPrice: "",
                    requestLimit: ""
                  }}
                  FormComponent={({
                    fields: {
                      institutionCategory,
                      feeName,
                      subscriptionFrequency,
                      price,
                      payAsYouGoPrice,
                      requestLimit
                    },
                    onChange,
                    onReloadFields,
                    onHandleSubmit,
                  }) => (
                    <form action="#">
                      <div className="card no-b">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-row">
                                <SelectInputComponent
                                  id="institutionCategory"
                                  name="institutionCategory"
                                  divClass={6}
                                  value={institutionCategory}
                                  items={institutionCategoryInputData}
                                  required={true}
                                  validated={false}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                {/* <SelectInputComponent
                                  id="subscriptionFrequency"
                                  name="subscriptionFrequency"
                                  divClass={6}
                                  value={subscriptionFrequency}
                                  items={subscriptionFrequencyInputData}
                                  required={true}
                                  validated={false}
                                  errors={errors}
                                  onChange={onChange}
                                /> */}
                              </div>
                              <div className="form-row">
                                <FormInputComponent
                                  id="feeName"
                                  type="text"
                                  name="feeName"
                                  placeholder=""
                                  divClass={6}
                                  value={feeName}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="price"
                                  type="number"
                                  name="price"
                                  placeholder=""
                                  divClass={6}
                                  value={price}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-row">
                                <FormInputComponent
                                  id="payAsYouGoPrice"
                                  type="number"
                                  name="payAsYouGoPrice"
                                  placeholder=""
                                  divClass={6}
                                  value={payAsYouGoPrice}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                                <FormInputComponent
                                  id="requestLimit"
                                  type="number"
                                  name="requestLimit"
                                  placeholder=""
                                  divClass={6}
                                  value={requestLimit}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="card-body">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary"
                          disabled={this.state.submitting}
                          onClick={(e) => {
                            onHandleSubmit(
                              e,
                              ActionTypes.SAVE,
                              "Hybrid Appraisal Subscription Fee Configuration",
                              () => {
                                this.onFormSubmit(
                                  {
                                    institutionCategory,
                                    feeName,
                                    subscriptionFrequency,
                                    price,
                                    payAsYouGoPrice,
                                    requestLimit
                                  },
                                  onReloadFields
                                );
                              }
                            );
                          }}
                        >
                          {this.state.submitting === false ? (
                            <React.Fragment>
                              <i className="fas fa-lg fa-save mr-3" /> Save
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                              Please wait...
                            </React.Fragment>
                          )}
                        </button>
                      </div>
                    </form>
                  )}
                ></AddHybridAppraisalSubConfigForm>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment >
    );
  }
}

export default AddHybridAppraisalSubConfig;