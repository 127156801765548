import http from "../httpService";
import { retrieveUserById } from "../auth/usersService";
import { ActionTypes } from "../../enums/actionTypes";
// import _ from "lodash";
import { getCurrentUserSession, getCurrentUsersRoles, IUserSession } from "../auth/authService";
import { IFilterData } from "../approval/approvalItemService";
import { decodeToken } from "react-jwt";

interface ISearchActivityTrailData {
  initiatingUser: string;
  actionType: ActionTypes;
}

interface ISaveActivityTrail {
  userId?: number;
  description: string;
  institutionId?: number;
  count?: number;
  uniqueId?: string;
  initiatingUser?: string;
  actionType: string;
  branch?: string;
  occuredAt?: Date;
  loggedAt?: Date;
  id?: number;
}

const auditTrailBaseAddress = process.env.REACT_APP_AUDITTRAIL_BASEADDRESS;
export function retrieveActivityTrailItemByCount(
  count: number,
  userId: number
) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  var url = auditTrailBaseAddress +
    `activityTrails/RetrieveActivityTrailItemByCount?count=${count}&userId=${userId}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveById(activityTrailId: number) {
  var url = auditTrailBaseAddress +
    `activityTrails/RetrieveById?Id=${activityTrailId}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export async function saveActivityTrail(
  activityTrail: ISaveActivityTrail,
  userSession?: IUserSession
) {
  // console.log();
  let currentUserSession: any = {}
  if (getCurrentUsersRoles().includes("remotesession")) {
    currentUserSession = JSON.parse(localStorage.getItem("remoteUserSession") || "")
  } else {
    currentUserSession = getCurrentUserSession();
  }

  const decodedToken = decodeToken(currentUserSession.token || "");
  let currentUser: any;
  if (decodedToken?.["UserId"] !== null) {
    currentUser = await retrieveUserById(decodedToken?.["UserId"]);

    activityTrail.userId = decodedToken?.["UserId"];
    activityTrail.occuredAt = new Date();
    activityTrail.branch = currentUser.data.branch.name;
    activityTrail.initiatingUser = currentUser.data.username;
    activityTrail.institutionId = decodedToken?.["InstitutionId"];
    var url = auditTrailBaseAddress + `activityTrails`;

    return http.post(url, activityTrail, {
      url: url,
      method: "post",
      data: activityTrail,
      headers: {
        Authorization: `Bearer ${currentUserSession.token}`,
      },
    });
  }
}

// export function searchActivityTrail(
//   data: ISearchActivityTrailData,
//   pageSize: number,
//   pageNumber: number
// ) {
//   const decodedToken = decodeToken(getCurrentUserSession().token || "");
//   return http.get(
//     auditTrailBaseAddress +
//       `activityTrails/SearchActivityTrail?institutionId=${
//         decodedToken?.["InstitutionId"]
//       }&initiatingUser=${
//         data.initiatingUser
//       }&actionType=${data.actionType.toString()}&pageSize=${pageSize}&pageNumber=${pageNumber}`
//   );
// }

export function filterActivityTrails(
  payload: IFilterData,
  pageSize: number,
  pageNumber: number
) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  payload = { ...payload };
  payload.institutionId = {
    item1: "=",
    item2: decodedToken?.["InstitutionId"]?.toString() || "break",
  };
  var url = auditTrailBaseAddress +
    `activityTrails/FilterActivityTrails?pageSize=${pageSize}&pageNumber=${pageNumber}`;

  return http.post(url, payload, {
    url: url,
    method: "post",
    data: payload,
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export default {
  retrieveActivityTrailItemByCount,
  retrieveById,
  saveActivityTrail,
  // searchActivityTrail,
  filterActivityTrails,
};
