import React, { useCallback, useEffect, useState } from "react";
import feeService from "../../../../services/_axiosServices/sweep/fee";
import { Loader, Error } from "../../components";

const mockData = [
  {
    institutionId: 9,
    institutionCode: "005",
    activeLoansPercentage: 1,
    lostLoansPercentage: 1,
    flatAmount: 0,
    minimumFee: 0,
    maximumFee: 200000,
    sponsorBankCommission: 2.3,
    debitedBankCommission: 1.2,
    repaymentType: "Recovery",
    id: 1,
  },
];

export default function Report() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(mockData);
  const [error, setError] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    await feeService
      .retrieveAll()
      .then((res: any) => {
        // console.log(res);
        setData(res.data);
        setLoading(false);
      })
      .catch((err: any) => {
        setError(true);
        setLoading(false);
        console.log(err.message);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="container-fluid relative animatedParent animateOnce">
      <div className="animated fadeInUpShort go">
        <div className="row my-3 mx-2">
          <h3>
            <b>Fee - Retrieve All</b>
          </h3>
        </div>
        <div className="card no-b">
          <div className="card-body">
            <div className="card-title">
              <div className="page">
                <div className="page-inner">
                  <div className="btn-toolbar justify-content-between">
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn btn-sm"
                        onClick={fetchData}
                      >
                        <i className="fas fa-sync"></i>
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive text-nowrap">
                    {loading && <Loader />}

                    {!loading && !error && (
                      <table className="table table-hover table-content table-striped table-bordered table-sm paged-output">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Institution ID</th>
                            <th>Institution Code</th>
                            <th>Active Loans Percentage</th>
                            <th>Lost Loans Percentage</th>
                            <th>Flat Amount</th>
                            <th>Minimum Fee</th>
                            <th>Maximum Fee</th>
                            <th>Sponsor Bank Commission</th>
                            <th>Debited Bank Commission</th>
                            <th>Repayment Type</th>
                            <th>id</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((d: any, i: number) => (
                            <tr key={i}>
                              <th scope="row">{i + 1}</th>
                              <td>{d.institutionId}</td>
                              <td>{d.institutionCode}</td>
                              <td>{d.activeLoansPercentage}</td>
                              <td>{d.lostLoansPercentage}</td>
                              <td>{d.flatAmount}</td>
                              <td>{d.minimumFee}</td>
                              <td>{d.maximumFee}</td>
                              <td>{d.sponsorBankCommission}</td>
                              <td>{d.debitedBankCommission}</td>
                              <td>{d.repaymentType}</td>
                              <td>{d.id}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}

                    {error && !loading && <Error />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
