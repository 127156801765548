import React, { Component, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../../components/detailItem";
import textFormatService from "../../../services/utility/textFormatService";
import DetailItemsTable from "../../../components/tableComponents/detailItemsTable";
import { mapEntityList } from "../../../services/utility/autoMapperService";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import collectionScheduleService from "../../../services/collection/collectionScheduleService";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { getCurrentUsersRoles } from "../../../services/auth/authService";
import institutionsService from "../../../services/auth/institutionsService";
import repaymentService from "../../../services/handshake/repaymentService";
import CollectionAttemptsModal from "./collectionAttemptsModal";

interface ICollectionScheduleDetailProps {
    showDetailsModal: boolean;
    toggleDetailsModal: any;
    toggleEditModal: any;
    // togglePatchModal: any;
    showTrail: any;
    item: any;
    reloadDetails: any;
}

interface ICollectionScheduleDetailState {
    item: any;
    updatingStatus: boolean;
    scheduleRepayments: any;
    scheduleRepaymentsTotalCount: number;
    scheduleRepaymentsCurrentPage: number;
    fetchingScheduleRepayments: boolean;
    restructuringLoan: boolean;
    showAttemptsModal: boolean;
    selectedScheduleRepayment: any;
}

class CollectionScheduleDetail extends Component<ICollectionScheduleDetailProps, ICollectionScheduleDetailState> {
    constructor(props: ICollectionScheduleDetailProps) {
        super(props);
        this.state = {
            item: {},
            updatingStatus: false,
            scheduleRepayments: [],
            scheduleRepaymentsTotalCount: 0,
            scheduleRepaymentsCurrentPage: 1,
            fetchingScheduleRepayments: false,
            restructuringLoan: false,
            showAttemptsModal: false,
            selectedScheduleRepayment: {}
        };
    }

    static getDerivedStateFromProps(nextProps: ICollectionScheduleDetailProps, prevState) {
        // console.log("current props: ", nextProps.item);
        return {
            item: nextProps.item,
        };
    }

    async componentDidMount() {
        try {
            if (this.props.item.id) {
                // console.log(
                //   'about to fetch similar transactions...',
                //   this.props.item.id
                // )
                this.handleOnScheduleRepaymentsPageChange(this.state.scheduleRepaymentsCurrentPage);
            }
        } catch (error) {
            this.setState({ fetchingScheduleRepayments: false });
        }
    }

    handleOnRestructureLoan = async (item: any) => {
        var institution = await institutionsService.retrieveInstitutionByCode(item.institutionCode);

        swal({
            title: "Confirm!",
            text: `Are you sure you want to Restructure this Loan?`,
            icon: "warning",
            buttons: {
                confirm: {
                    text: "Yes",
                    value: null,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                cancel: {
                    text: "Cancel",
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
            },
            closeOnClickOutside: false,
            dangerMode: true,
        }).then(async (resp) => {
            if (resp) {
                // console.log("cancelling action...");
            } else {
                this.setState({ restructuringLoan: true });
                await repaymentService
                    .restructureLoan(item.mandateReference, institution.data["externalInstitutionCode"])
                    .then(() => {
                        this.setState({ restructuringLoan: false });
                    })
                    .catch((err) => {
                        this.setState({ restructuringLoan: false });
                        toast.error(err.response.data);
                        // console.log("loan restructuring response", JSON.stringify(err));
                    });
                //   this.handleEnableOrDisableItem(this.state.item);
            }
        });

        // throw new Error("Function not implemented.");
    };

    handleOnScheduleRepaymentsPageChange = async (currentPage: number) => {
        currentPage = currentPage < 0 ? this.state.scheduleRepaymentsCurrentPage : currentPage;
        this.setState({ fetchingScheduleRepayments: true });
        // const decodedToken = decodeToken(getCurrentUserSession().token || "");
        // let dataClone =
        //   typeof data === "undefined" ? { ...this.state.data } : { ...data };
        try {
            // console.log("sched Reps",this.props.item.scheduleRepayments)
            const scheduleReps = this.props.item.scheduleRepayments;

            const scheduleRepayments: any = [];
            const totalLength = scheduleReps.length;
            const initial = (currentPage - 1) * 10;
            for (let i = initial; i < initial + 10; i++) {
                scheduleRepayments.push(scheduleReps[i]);
                if (i === totalLength - 1) {
                    break;
                }
            }

            // console.log("sched Reps",scheduleRepayments)

            this.setState({
                scheduleRepayments: mapEntityList({
                    // entityList: scheduleRepayments.data.item2,
                    entityList: scheduleRepayments,
                    properties: [
                        { oldName: "repaymentDate", newName: "repaymentDate" },
                        { oldName: "repaymentAmount", newName: "amountDue (₦)" },
                        { oldName: "repaymentBalance", newName: "repaymentBalance (₦)" },
                        { oldName: "amountPaid", newName: "amountPaid (₦)" },
                        { oldName: "repaymentMethod", newName: "repaymentMethod" },
                        {
                            isTag: true,
                            oldName: "paymentStatus",
                            newName: "status",
                            success: "Fully paid",
                            danger: "No payment",
                            default: "default",
                            primary: "Pending",
                            secondary: "partial payment",
                            warning: "warning",
                            light: "light",
                            dark: "dark",
                        },
                    ],
                }),
                scheduleRepaymentsCurrentPage: currentPage,
                // totalSize: scheduleRepayments.data.item1,
                scheduleRepaymentsTotalCount: totalLength,
                fetchingScheduleRepayments: false,
            });
        } catch (ex) {
            //   console.log("caught exception", ex);
            if (ex.response && ex.response.status >= 400 && ex.response.status <= 499) {
                this.setState({ fetchingScheduleRepayments: false });
            } else {
                // toast.error("Internal server error. Please contact the admin.");
                this.setState({ fetchingScheduleRepayments: false });
            }
        }
    };
    
    handleOnToggleAttemptDetailModal = async (item) => {
        this.setState({ showAttemptsModal: !this.state.showAttemptsModal });
        if (this.state.showAttemptsModal === false) {
            let filtered = this.props.item.scheduleRepayments.filter(rpmt => item.repaymentDate == rpmt.repaymentDate)[0];
            console.log(filtered);
            this.setState({ selectedScheduleRepayment: filtered });
        } else {
            this.setState({ selectedScheduleRepayment: {} });
        }
    };

    handleUpdateCollectionConfig = async (recoverySwitchMode: "EndOfLoanTenure" | "RepaymentDefault") => {
        try {
            swal({
                title: "Confirm!",
                text: `Are you sure you want to proceed to UPDATE this Collection Config?`,
                icon: "warning",
                buttons: {
                    confirm: {
                        text: "Yes",
                        value: null,
                        visible: true,
                        className: "",
                        closeModal: true,
                    },
                    cancel: {
                        text: "Cancel",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true,
                    },
                },
                closeOnClickOutside: false,
                dangerMode: true,
            }).then(async (resp) => {
                if (resp) {
                    // console.log("cancelling action...");
                    this.setState({ updatingStatus: false });
                } else {
                    const payload: any = {};
                    payload.recoverySwitchMode = recoverySwitchMode;
                    // payload.switchRecoveryWindowInDays = Number(fields.switchRecoveryWindowInDays);
                    // console.log("payload :: ", payload)
                    const response = await collectionScheduleService.modifyCollectionConfigOfSchedule(payload, this.props.item.id);
                    const { item } = this.props;
                    if (response.status >= 200 && response.status <= 300) {
                        item.recoverySwitchMode = recoverySwitchMode;
                        await activityTrailService.saveActivityTrail({
                            actionType: "Modify Collection Config",
                            description: `Modified Collection Config for ${this.props.item.customerName}`,
                        });

                        toast.info(`Collection Config for ${this.props.item.customerName} Modified successfully! `, {
                            autoClose: 6000,
                            type: toast.TYPE.DEFAULT,
                            hideProgressBar: false,
                        });
                    }
                    this.setState({ updatingStatus: false, item: item });
                    this.props.reloadDetails();
                }
            });
        } catch (error) {
            // console.log("submission error: ", error.response);
            this.setState({ updatingStatus: false });
        }
    };

    render() {
        const {
            showDetailsModal,
            toggleDetailsModal,
            toggleEditModal,
            // togglePatchModal,
            showTrail,
            item,
        } = this.props;
        const { updatingStatus, restructuringLoan, showAttemptsModal, selectedScheduleRepayment } = this.state;
        return (
            <Modal size="xl" backdrop="static" show={showDetailsModal} onHide={toggleDetailsModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {_.isEmpty(item) ? (
                            <Skeleton width={500} />
                        ) : (
                            <h5>
                                <i className="icon icon--columns s-18 mr-3" />
                                Schedule Details - <span className="font-weight-bold">{item.customerName}</span>
                            </h5>
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card">
                        <div className="card-header clear-fix">
                            {_.isEmpty(item) ? (
                                <Skeleton width={200} />
                            ) : (
                                <h6 className="card-title float-left" style={{ marginBottom: "0px" }}>
                                    <b>General Information</b>
                                </h6>
                            )}
                            {/* <div className="float-right">
                {_.isEmpty(item) ? (
                  <Skeleton width={100} />
                ) : (
                  <span
                    className="badge btn-primary"
                    onClick={showTrail}
                    style={{
                      cursor: "pointer",
                      visibility: currentUserRoles.includes(
                        "viewaudittrailitems"
                      )
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    View Trail
                  </span>
                )}
              </div> */}
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    {_.isEmpty(item) ? (
                                        <Skeleton count={7} width={300} />
                                    ) : (
                                        <dl className="row">
                                            <DetailItem label="Customer Name" value={item.customerName} labelSize={5} valueSize={7} />
                                            <DetailItem label="Bvn" value={item.bvn} labelSize={5} valueSize={7} />
                                            {/* <DetailItem
                        label="External Mandate Reference"
                        value={item.externalMandateReference}
                        labelSize={5}
                        valueSize={7}
                      /> */}
                                            <DetailItem
                                                label="Loan Expiry Date"
                                                value={textFormatService.formatTimeString(item.loanExpiryDate)}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem label="Loan Status" value={item.loanStatus} labelSize={5} valueSize={7} />
                                            <DetailItem
                                                label="Date Updated"
                                                value={item.dateUpdated == null ? "N/A" : textFormatService.formatTimeString(item.dateUpdated)}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem
                                                label="Recovery Switch Mode"
                                                value={
                                                    <>
                                                        <span className="material-switch ml-2">
                                                            <input
                                                                id="recoverySwitchMode"
                                                                name="recoverySwitchMode"
                                                                type="checkbox"
                                                                onChange={() => {
                                                                    this.handleUpdateCollectionConfig(
                                                                        item.recoverySwitchMode && item.recoverySwitchMode == "RepaymentDefault"
                                                                            ? "EndOfLoanTenure"
                                                                            : "RepaymentDefault"
                                                                    );
                                                                }}
                                                                checked={item.recoverySwitchMode && item.recoverySwitchMode == "RepaymentDefault"}
                                                            />
                                                            <label htmlFor="recoverySwitchMode" className="bg-primary"></label>
                                                        </span>
                                                        <span className="ml-2">
                                                            {item.recoverySwitchMode ? item.recoverySwitchMode : "EndOfLoanTenure"}
                                                        </span>
                                                    </>
                                                }
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            {item.collectionSuspensionCount != 0 && (
                                                <React.Fragment>
                                                    <DetailItem
                                                        label="Collection Suspension Count"
                                                        value={item.collectionSuspensionCount}
                                                        labelSize={5}
                                                        valueSize={7}
                                                    />
                                                    {item.collectionsSuspended ? (
                                                        <React.Fragment>
                                                            <DetailItem
                                                                label="Suspension Start Date"
                                                                value={
                                                                    item.suspensionStartDate == null
                                                                        ? "N/A"
                                                                        : textFormatService.formatTimeString(item.suspensionStartDate)
                                                                }
                                                                labelSize={5}
                                                                valueSize={7}
                                                            />
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <DetailItem
                                                                label="Date Collections Resumed"
                                                                value={
                                                                    item.dateCollectionsResumed == null
                                                                        ? "N/A"
                                                                        : textFormatService.formatTimeString(item.dateCollectionsResumed)
                                                                }
                                                                labelSize={5}
                                                                valueSize={7}
                                                            />
                                                        </React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </dl>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    {_.isEmpty(item) ? (
                                        <Skeleton count={7} width={300} />
                                    ) : (
                                        <dl className="row">
                                            <DetailItem label="Phone Number" value={item.phoneNumber} labelSize={5} valueSize={7} />
                                            <DetailItem label="Mandate Reference" value={item.mandateReference} labelSize={5} valueSize={7} />
                                            <DetailItem
                                                label="Total Expected Repayment (₦)"
                                                value={item.totalExpectedRepayment}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem label="Loan Tenure (In Days)" value={item.loanTenure} labelSize={5} valueSize={7} />
                                            <DetailItem
                                                label="Date Created"
                                                value={textFormatService.formatTimeString(item.dateCreated)}
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem
                                                label="Date Restructured"
                                                value={
                                                    item.dateRestructured == null ? "N/A" : textFormatService.formatTimeString(item.dateRestructured)
                                                }
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            <DetailItem
                                                label="Switch Recovery Window In Days"
                                                value={
                                                    (item.recoverySwitchMode ? item.recoverySwitchMode : "EndOfLoanTenure") == "EndOfLoanTenure"
                                                        ? "N/A"
                                                        : item.switchRecoveryWindowInDays
                                                }
                                                labelSize={5}
                                                valueSize={7}
                                            />
                                            {item.collectionSuspensionCount != 0 && (
                                                <React.Fragment>
                                                    <DetailItem
                                                        label="Collections Suspended"
                                                        value={
                                                            (item ? item.collectionsSuspended : true) === true ? (
                                                                <span className="badge badge-success">{item ? "True" : ""}</span>
                                                            ) : (
                                                                <span className="badge badge-danger">{item ? "False" : ""}</span>
                                                            )
                                                        }
                                                        labelSize={5}
                                                        valueSize={7}
                                                    />
                                                    {item.collectionsSuspended ? (
                                                        <React.Fragment>
                                                            <DetailItem
                                                                label="Last Collection Suspension Date"
                                                                value={
                                                                    item.lastCollectionSuspensionDate == null
                                                                        ? "N/A"
                                                                        : textFormatService.formatTimeString(item.lastCollectionSuspensionDate)
                                                                }
                                                                labelSize={5}
                                                                valueSize={7}
                                                            />
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <DetailItem
                                                                label="Suspension End Date"
                                                                value={
                                                                    item.suspensionEndDate == null
                                                                        ? "N/A"
                                                                        : textFormatService.formatTimeString(item.suspensionEndDate)
                                                                }
                                                                labelSize={5}
                                                                valueSize={7}
                                                            />
                                                        </React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </dl>
                                    )}
                                </div>
                            </div>
                        </div>
                        <DetailItemsTable
                            title="Repayment Schedule"
                            item={item}
                            handleOnPageChange={this.handleOnScheduleRepaymentsPageChange}
                            content={this.state.scheduleRepayments}
                            contentCount={this.state.scheduleRepaymentsTotalCount}
                            page={this.state.scheduleRepaymentsCurrentPage}
                            fetching={this.state.fetchingScheduleRepayments}
                            hasDetailModal={true}
                            onViewDetails={this.handleOnToggleAttemptDetailModal}
                            colorColumn={[
                                { columnName: "amountPaid (₦)", textColor: "blue" },
                                { columnName: "repaymentBalance (₦)", textColor: "black" },
                            ]}
                            amountColumn={["amountDue (₦)", "repaymentBalance (₦)", "amountPaid (₦)"]}
                            amountInNaira={true}
                            timeColumn={["repaymentDate"]}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {_.isEmpty(item) ? (
                        <Skeleton width={100} count={2} height={30} />
                    ) : (
                        <React.Fragment>
                            {/* {item.status === "Inactive" &&
                getCurrentUsersRoles().includes("activateocminstitution") && (
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    disabled={updatingStatus}
                    onClick={(e) =>
                      this.onHandleEnableOrDisable(e,ActionTypes.ACTIVATE,"Blackbox Institution")
                    }
                  >
                    <i className="far fa-edit mr-3" />
                    Activate
                  </Button>
              )}
              {item.status === "Active" &&
                getCurrentUsersRoles().includes("deactivateocminstitution") && (
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    disabled={updatingStatus}
                    onClick={(e) =>
                      this.onHandleEnableOrDisable(e, ActionTypes.DEACTIVATE,"Blackbox Institution")
                    }
                  >
                    <i className="far fa-edit mr-3" />
                    Deactivate
                  </Button>
              )} */}
                            {getCurrentUsersRoles().includes("restructureloan") && (
                                <Fragment>
                                    <Button
                                        size="sm"
                                        variant="outline-secondary"
                                        disabled={restructuringLoan}
                                        onClick={() => this.handleOnRestructureLoan(item)}
                                    >
                                        {restructuringLoan ? (
                                            <i className="fas fa-spinner fa-spin" />
                                        ) : (
                                            <>
                                                <i className="far fa-edit mr-3" />
                                                Restructure Loan
                                            </>
                                        )}
                                    </Button>
                                </Fragment>
                            )}
                            <Button size="sm" variant="primary" disabled={updatingStatus} onClick={toggleEditModal}>
                                <i className="far fa-edit mr-3" />
                                Edit Switch Recovery Window
                            </Button>
                            {/* )} */}
                        </React.Fragment>
                    )}

                    <Button size="sm" variant="outline-danger" onClick={toggleDetailsModal}>
                        <i className="fas fa-times mr-3" />
                        Close
                    </Button>
                </Modal.Footer>
                <CollectionAttemptsModal
                    showDetailsModal={showAttemptsModal}
                    toggleDetailsModal={this.handleOnToggleAttemptDetailModal}
                    // currentUserRoles={null}
                    item={selectedScheduleRepayment}
                />
            </Modal>
        );
    }
}

export default CollectionScheduleDetail;
