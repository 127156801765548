import React, { Component } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import BaseFormComponent, { IBaseFormState } from "../../../../../../components/BaseFormComponent";
import FormInputComponent from "../../../../../../components/formInputComponent";
import SelectInputComponent, { ISelectItems } from "../../../../../../components/formSelectComponent";
import { ActionTypes } from "../../../../../../enums/actionTypes";
import loanRequestService from "../../../../../../services/hybridAppraisal/loanRequestService";
import hybridAppraisalValidationService from "../../../../../../services/hybridAppraisal/hybridAppraisalValidationService";
import { getCurrentUsersRoles } from "../../../../../../services/auth/authService";

interface AssetPropertyDetailsFields {
  category?: string;
  type?: string;
  description?: string;
  year?: string;
  model?: string;
  life?: string;
  age?: string;
  manufacturer?: string;
  supplier?: string;
  cost?: string;
  loanAmount?: string;
  equityPayment?: string;
  loanToValue?: string;
  usageCategory?: string;
}

interface DependentSelectItems {
  value: any;
  inputData: ISelectItems[];
}

class AssetPropertyDetailsForm extends BaseFormComponent<AssetPropertyDetailsFields> { }

interface AssetPropertyDetailsProps {
  values: any;
  reloadItem: any;
  mandatoryFields: any;
}

interface AssetPropertyDetailsState {
  assets: AssetPropertyDetailsFields[];
  loanSummary: AssetPropertyDetailsFields[];
  country: string;
  ownUpdate: boolean;
}

class AssetPropertyDetails extends Component<
  AssetPropertyDetailsProps,
  IBaseFormState & AssetPropertyDetailsState
> {
  constructor(props: AssetPropertyDetailsProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      assets: [],
      loanSummary: [],
      ownUpdate: false,
      country: ""
    };
  }

  static getDerivedStateFromProps(
    nextProps: AssetPropertyDetailsProps,
    prevState: AssetPropertyDetailsState
  ) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isEmpty(nextProps.values.assets?.assets)) {
      return null;
    }
    return {
      assets: nextProps.values.assets?.assets?.filter(x => x.category !== ""),
      loanSummary: nextProps.values.assets?.assets?.filter(x => x.category === null || x.category === "" || x.loanAmount > 0)
    };
  }

  onFormSubmit(fields: AssetPropertyDetailsFields, onReloadFieldsCB: any): boolean {
    try {
      if (this.props.mandatoryFields) {
        this.setState(
          { errors: hybridAppraisalValidationService.validateAddAssetLoanSummaryDetailForm(fields, this.props.mandatoryFields), ownUpdate: true },
          () => {
            if (Object.keys(this.state.errors).length === 0) {
              return this.submit(fields, onReloadFieldsCB);
            }
          }
        );
      }
      else {
        this.submit(fields, onReloadFieldsCB);
      }
      this.setState({ validated: true, ownUpdate: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  async submit(
    fields: AssetPropertyDetailsFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      fields.age = "0"
      this.setState({ submitting: true, ownUpdate: true });
      const loanRequest = await loanRequestService.retrieveById(this.props.values.id);
      const loanRequestDetail = loanRequest.data;
      const payload: any = { ...loanRequestDetail };
      if (payload.assets === null) {
        payload.assets = { assets: [...this.state.assets, fields] }
      }
      else {
        payload.assets.assets = [...this.state.assets, fields]
      }
      const response = await loanRequestService.updateLoanRequest(payload);
      if (response.status >= 200 && response.status <= 300) {
        toast.info(`Asset Details saved successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false, ownUpdate: true });
      this.resetFields();
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false, ownUpdate: true });
      return false;
    } finally {
      return true;
    }
  }

  handleOnAddAsset = (item: AssetPropertyDetailsFields, onReloadFieldsCB: any) => {
    try {

      // console.log(this.props.mandatoryFields)
      if (this.props.mandatoryFields) {
        this.setState(
          { errors: hybridAppraisalValidationService.validateAddAssetDetailForm(item, this.props.mandatoryFields), ownUpdate: true },
          () => {
            console.log(this.state.errors)
            if (Object.keys(this.state.errors).length === 0) {
              const assets = [...this.state.assets];
              assets.push(item);
              this.setState({ assets, ownUpdate: true });
              this.resetFields();
              onReloadFieldsCB();
            }
          }
        );
      }
      else {
        const assets = [...this.state.assets];
        assets.push(item);
        this.setState({ assets, ownUpdate: true });
        this.resetFields();
        onReloadFieldsCB();
      }
      this.setState({ validated: true, ownUpdate: true });
    } catch (error) {
      return false;
    }
  }

  handleOnRemoveAsset = (item: AssetPropertyDetailsFields) => {
    const assets = [...this.state.assets];
    if (assets.includes(item)) {
      assets.splice(
        assets.indexOf(item), 1
      );
    }
    this.setState({ assets, ownUpdate: true });
  };

  getRequiredFields(title): boolean {
    const { mandatoryFields } = this.props;
    var required = mandatoryFields?.inputFields?.filter(x => x.fieldName === title)[0]?.mandatory;
    if (required === undefined) {
      return false;
    }
    return required;
  }

  resetFields() {
    this.setState({ validated: false, submitting: false, errors: [], country: "", ownUpdate: true });
  }

  render() {
    const { values } = this.props;
    const { errors, validated, assets, loanSummary } = this.state;

    let equityPaymentlen = values?.assets?.assets?.filter(x => x.equityPayment !== null).length
    let equityPayment: any
    if (equityPaymentlen > 0) {
      equityPayment = equityPaymentlen = values?.assets?.assets?.filter(x => x.equityPayment !== null)[equityPaymentlen - 1]
    }

    let statesInputData: ISelectItems[] = [];
    let statesData: ISelectItems[] = [];
    // let statesData: ISelectItems[] = [
    //   "Banjul City Council (BCC)=LOCATION A",
    //   "Kanifing Municipal Council (KMC)=LOCATION A",
    //   "West Coast Region (WCR)=LOCATION A",
    //   "Upper River Region (URR)=LOCATION B",
    //   "North Bank Region (NBR)=LOCATION C",
    //   "Central River Region (CRR)=LOCATION C",
    //   "Lower River Region (LRR)=LOCATION C",
    // ].map(item => {
    //   return { name: item.split("=")[0], value: item.split("=")[1] }
    // })

    // let statesData: ISelectItems[] = [
    //   "ABIA=LOCATION B",
    //   "ABUJA=LOCATION A",
    //   "ADAMAWA=LOCATION B",
    //   "AKWA IBOM=LOCATION B",
    //   "ANAMBRA=LOCATION C",
    //   "BAUCHI=LOCATION C",
    //   "BAYELSA=LOCATION C",
    //   "BENUE=LOCATION C",
    //   "BORNO=LOCATION C",
    //   "CROSS RIVER=LOCATION B",
    //   "DELTA=LOCATION B",
    //   "EBONYI=LOCATION C",
    //   "EDO=LOCATION C",
    //   "EKITI=LOCATION C",
    //   "ENUGU=LOCATION B",
    //   "GOMBE=LOCATION C",
    //   "IMO=LOCATION B",
    //   "JIGAWA=LOCATION C",
    //   "KADUNA=LOCATION A",
    //   "KANO=LOCATION A",
    //   "KATSINA=LOCATION B",
    //   "KEBBI=LOCATION C",
    //   "KOGI=LOCATION C",
    //   "KWARA=LOCATION C",
    //   "LAGOS=LOCATION A",
    //   "NASARAWA=LOCATION C",
    //   "NIGER=LOCATION C",
    //   "OGUN=LOCATION C",
    //   "ONDO=LOCATION C",
    //   "OSUN=LOCATION C",
    //   "OYO=LOCATION B",
    //   "PLATEAU=LOCATION C",
    //   "RIVERS=LOCATION A",
    //   "SOKOTO=LOCATION C",
    //   "TARABA=LOCATION C",
    //   "YOBE=LOCATION C",
    //   "ZAMFARA=LOCATION C",
    // ].map(item => {
    //   return { name: item.split("=")[0], value: item.split("=")[1] }
    // })

    // statesInputData = statesData.map(item => {
    //   return { name: item.name, value: item.name }
    // })

    // let countriesInputData: ISelectItems[] = [];
    // countries.forEach((country) => {
    //   countriesInputData.push({ name: country.name, value: country.name });
    // });
    // countries
    //   .filter((c) => c.name === this.state.country)[0]
    //   ?.states.forEach((state) => {
    //     statesInputData.push({ name: state.name, value: state.name });
    //     statesData.push({ name: state.name, value: state.locationTier ? state.locationTier : "" });
    //   });

    let brandModelInputData: DependentSelectItems[] = [
      { value: "Toyota", inputData: ["4Runner", "Venza", "Avalon", "Camry", "Corolla", "Highlander", "Land Cruiser", "Rav4", "Sienna"].map(item => { return { name: item, value: item } }) },
      { value: "Honda", inputData: ["Accord", "City", "Civic", "HR-V", "CR-V", "Pilot"].map(item => { return { name: item, value: item } }) },
      { value: "Nissan", inputData: ["Altima", "Juke", "Qashqai", "X-Trail", "Murano", "Pathfinder", "Maxima"].map(item => { return { name: item, value: item } }) },
      { value: "Mercedes Benz", inputData: ["AMG GLC", "CLK", "GLE-Class", "GLK-Class", "GLS-Class"].map(item => { return { name: item, value: item } }) },
      { value: "Ford", inputData: ["Edge", "Escape", "Explorer"].map(item => { return { name: item, value: item } }) },
      { value: "Kia", inputData: ["Picanto", "Optima", "Rio", "Cerato", "Sportage"].map(item => { return { name: item, value: item } }) },
      { value: "Lexus", inputData: ["GX350", "LX450", "RX350", "RX450"].map(item => { return { name: item, value: item } }) },
      { value: "Hyundai", inputData: ["i10", "Sante Fe", "Sonata", "Elantra", "Tucson", "Accent"].map(item => { return { name: item, value: item } }) },
      { value: "Range Rover", inputData: ["Land Rover", "Range Rover Evoque", "Range Rover Velar", "Range Rover Sport"].map(item => { return { name: item, value: item } }) },
      { value: "BMW", inputData: ["X1", "X2", "X5", "X6", "X7"].map(item => { return { name: item, value: item } }) },
      { value: "Caterpillar", inputData: ["Caterpillar"].map(item => { return { name: item, value: item } }) },
      { value: "Fordson", inputData: ["Fordson"].map(item => { return { name: item, value: item } }) },
      { value: "Mahindra", inputData: ["Mahindra"].map(item => { return { name: item, value: item } }) },
      { value: "Mitsubishi", inputData: ["Mitsubishi"].map(item => { return { name: item, value: item } }) },
      { value: "Xerox", inputData: ["Xerox"].map(item => { return { name: item, value: item } }) },
      { value: "Cannon", inputData: ["Cannon"].map(item => { return { name: item, value: item } }) },
      { value: "HP", inputData: ["HP"].map(item => { return { name: item, value: item } }) },
      { value: "LG", inputData: ["LG"].map(item => { return { name: item, value: item } }) },
      { value: "Samsung", inputData: ["Samsung"].map(item => { return { name: item, value: item } }) },
      { value: "Hisense", inputData: ["Hisense"].map(item => { return { name: item, value: item } }) },
      { value: "Thermocool", inputData: ["Thermocool"].map(item => { return { name: item, value: item } }) },
      { value: "Panasonic", inputData: ["Panasonic"].map(item => { return { name: item, value: item } }) },
      { value: "Sony", inputData: ["Sony"].map(item => { return { name: item, value: item } }) },
    ]

    let categoryBrandInputData: DependentSelectItems[] = [
      { value: "Vehicles", inputData: ["Toyota", "Honda", "Nissan", "Mercedes Benz", "Ford", "Kia", "Lexus", "Hyundai", "Range Rover", "BMW"].map(item => { return { name: item, value: item } }) },
      { value: "Plant and Machinery ", inputData: ["Caterpillar", "Fordson", "Mahindra", "Mitsubishi"].map(item => { return { name: item, value: item } }) },
      { value: "Office Equipment", inputData: ["Xerox", "Cannon", "HP"].map(item => { return { name: item, value: item } }) },
      { value: "Household Equipment", inputData: ["LG", "Samsung", "Hisense", "Thermocool", "Panasonic", "Sony"].map(item => { return { name: item, value: item } }) },
    ]

    let categoryTypeInputData: DependentSelectItems[] = [
      { value: "Vehicles", inputData: ["Car", "Bus", "Truck", "Tanker"].map(item => { return { name: item, value: item } }) },
      { value: "Plant and Machinery ", inputData: ["Tractor", "Milling Machine"].map(item => { return { name: item, value: item } }) },
      { value: "Office Equipment", inputData: ["Printer", "Scanner", "Photocopier", "Desktop", "Laptop"].map(item => { return { name: item, value: item } }) },
      { value: "Household Equipment", inputData: ["Television", "Air Conditioner", "Gas Cooker", "Washing Machine", "Fridge", "Sound System"].map(item => { return { name: item, value: item } }) },
    ]

    let usageCategoryInputData: ISelectItems[] = [
      // { name: "NA", value: "NA" },
      { name: "New", value: "New" },
      { name: "Fairly Used", value: "Fairly Used" },
    ];

    let categoryInputData: ISelectItems[] = categoryTypeInputData.map(item => {
      return { name: item.value, value: item.value }
    });

    let currentYear = new Date().getFullYear()
    const yearInputData: ISelectItems[] = _.range(currentYear - 15, currentYear + 1, 1).map(item => {
      return { name: item.toString(), value: item }
    });

    const dropdownItems = values.institution.dropDownItems;
    const suppliers: any = dropdownItems == null ? [] : dropdownItems.filter(ddItem => ddItem.fieldName == "Suppliers")[0].values;
    const appraisers: any = dropdownItems == null ? [] : dropdownItems.filter(ddItem => ddItem.fieldName == "Appraisers")[0].values;

    let supplierInputData: ISelectItems[] = suppliers.map((item) => ({
      name: `${item.value}`,
      value: `${item.value}`
    }));

    let appraiserInputData: ISelectItems[] = appraisers.map((item) => ({
      name: `${item.value}`,
      value: `${item.value}`
    }));

    // const supplierInputData: ISelectItems[] = [
    //   "TONIK AUTOMOBILES COMPANY LTD",
    //   "PEACE MOTOR LTD",
    //   "SPHERE MOTORS NIG LTD",
    //   "KAY AUTO MART",
    //   "ALL OVER RIDE LIMITED",
    //   "AUTO NURRAY INVESTMENT LTD",
    //   "SUPER EXCELLENT MOTORS NIG LTD",
    //   "SUNNEL ILESANMI AND CO",
    //   "DEMAC BOLLY ENTER",
    //   "AGBOSCO VENTURES",
    //   "OSAMA INVESTMENT COMPANY LTD",
    //   "CYMAS MOTORS",
    //   "ALLISON INVESTMENT COMPANY LTD",
    //   "IKWUECHE ENT NIGERIA",
    //   "ARTHUR RICH MOTORS",
    //   "OZONE MOTORS",
    //   "AD PRISCA MOTORS",
    //   "HONDA PLACE",
    //   "KIA MOTORS",
    //   "GLOBE MOTORS NIG LTD",
    //   "COSCHARIS MOTORS",
    //   "FESOP MOTORS",
    //   "SAINT MOTORS",
    //   "JAMIL MOTORS",
    //   "OC J GLOBAL RESOURCES"
    // ].map(item => {
    //   return { name: item, value: item }
    // });

    // const appraiserInputData: ISelectItems[] = [
    //   "AGBON & ASSOCIATES",
    //   "SPHINX ASSOCIATES"
    // ].map(item => {
    //   return { name: item, value: item }
    // });
    // const appraiserInputData: ISelectItems[] = [
    //   "A.C. Otegbulu & Partners",
    //   "A.M. John Associates",
    //   "Adefila & Partners",
    //   "Akin Alawore & Partners",
    //   "Akin Ibiyode & Co",
    //   "Alagbe & Partners",
    //   "Alonge Ogunmoyero & Co",
    //   "Austin Ofoha & Co.",
    //   "Bassey N & Co",
    //   "Bello & Co.",
    //   "Biyi Adesanya & Co",
    //   "Bode Adediji Partnership",
    //   "Bola Onabadejo & Co.",
    //   "Chukwuemeka Okoli & Associates",
    //   "Demola Adetola & Co.",
    //   "Dosu Fatokun & Co.",
    //   "Eki Ogbebor & Co",
    //   "Emeka Eriobuna & Co.",
    //   "Emeka Onuorah & Co",
    //   "Emele Associates"
    // ].map(item => {
    //   return { name: item, value: item }
    // });

    return (
      <AssetPropertyDetailsForm
        initialValues={{
          category: values?.assets?.assets?.category ? values.assets.assets.category : "",
          type: values?.assets?.assets?.type ? values.assets.assets.type : "",
          description: values?.assets?.assets?.description ? values.assets.assets.description : "",
          year: values?.assets?.assets?.year ? values.assets.assets.year : "",
          model: values?.assets?.assets?.model ? values.assets.assets.model : "",
          life: values?.assets?.assets?.life ? values.assets.assets.life : "",
          age: values?.assets?.assets?.age ? values.assets.assets.age : "",
          manufacturer: values?.assets?.assets?.manufacturer ? values.assets.assets.manufacturer : "",
          supplier: values?.assets?.assets?.supplier ? values.assets.assets.supplier : "",
          cost: (values.loanAmount).toString(),
          loanAmount: (values.loanAmount).toString(),
          equityPayment: equityPayment ? equityPayment.equityPayment : "",
          loanToValue: "1",
          usageCategory: values?.assets?.assets?.usageCategory ? values.assets.assets.usageCategory : "",
        }}
        FormComponent={({
          fields: {
            category,
            type,
            description,
            year,
            model,
            life,
            age,
            manufacturer,
            supplier,
            cost,
            loanAmount,
            equityPayment,
            loanToValue,
            usageCategory
          },
          onChange,
          onReloadFields,
          onHandleSubmit,
        }) => (
          <div className="form-row">
            <div className="col-md-12">

              {/* Asset Details */}
              <div className="card">
                <div className="card-header clear-fix">
                  <h6
                    className="float-left"
                    style={{ marginBottom: "0px" }}
                  >
                    <b>Asset Details</b>
                  </h6>
                </div>
                <div className="card-body">
                  <div className="form-row">
                    <SelectInputComponent
                      label="Asset Category"
                      id="category"
                      name="category"
                      divClass={6}
                      value={category}
                      items={categoryInputData}
                      required={this.getRequiredFields("assetCategory")}
                      validated={validated}
                      errors={errors}
                      onChange={async (id, value) => {
                        await onChange(id, value);
                        await onChange('type', "");
                        await onChange('manufacturer', "");
                        await onChange('model', "");
                      }}
                    />
                    <SelectInputComponent
                      label="Asset Type"
                      id="type"
                      name="type"
                      divClass={6}
                      value={type}
                      items={
                        categoryTypeInputData.filter(data => data.value == category)[0] ?
                          categoryTypeInputData.filter(data => data.value == category)[0].inputData :
                          []
                      }
                      required={this.getRequiredFields("assetType")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                    />
                    <SelectInputComponent
                      label="Asset Brand"
                      id="manufacturer"
                      name="manufacturer"
                      divClass={6}
                      value={manufacturer}
                      items={
                        categoryBrandInputData.filter(data => data.value == category)[0] ?
                          categoryBrandInputData.filter(data => data.value == category)[0].inputData :
                          []
                      }
                      required={this.getRequiredFields("assetBrand")}
                      validated={validated}
                      errors={errors}
                      onChange={async (id, value) => {
                        await onChange(id, value);
                        await onChange('model', "");
                      }}
                    />
                    <SelectInputComponent
                      label="Asset Model"
                      id="model"
                      name="model"
                      divClass={6}
                      value={model}
                      items={
                        brandModelInputData.filter(data => data.value == manufacturer)[0] ?
                          brandModelInputData.filter(data => data.value == manufacturer)[0].inputData :
                          []
                      }
                      required={this.getRequiredFields("assetModel")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                    />
                    <SelectInputComponent
                      label="Asset Year"
                      id="year"
                      name="year"
                      divClass={6}
                      value={year}
                      items={yearInputData}
                      required={this.getRequiredFields("assetYear")}
                      validated={validated}
                      errors={errors}
                      onChange={async (id, value) => {
                        await onChange(id, value);
                        await onChange('age', (currentYear - Number(value)).toString());
                      }}
                    />
                    <SelectInputComponent
                      label="Vehicle Category"
                      id="usageCategory"
                      name="usageCategory"
                      divClass={6}
                      value={usageCategory}
                      items={usageCategoryInputData}
                      required={this.getRequiredFields("usageCategory")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                    />
                    <FormInputComponent
                      label="Asset Description"
                      id="description"
                      name="description"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={description}
                      required={this.getRequiredFields("assetDescription")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                    />
                    <FormInputComponent
                      label="Asset Life (In Years)"
                      id="life"
                      name="life"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={life}
                      required={this.getRequiredFields("assetLife")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                    />
                    <FormInputComponent
                      label="Asset Age (In Years)"
                      id="age"
                      name="age"
                      type="number"
                      placeholder=""
                      divClass={6}
                      value={age}
                      required={this.getRequiredFields("assetAge")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled
                    />
                    <FormInputComponent
                      label="Supplier"
                      id="supplier"
                      name="supplier"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={supplier}
                      required={this.getRequiredFields("assetSupplier")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>

              {/* Add Asset */}
              <div className="form-row">
                <div className="my-1 mx-2">
                  {(getCurrentUsersRoles().includes("updatedataentry") && (
                    <button
                      className="btn btn-sm btn-outline-primary my-4"
                      style={{ cursor: "pointer" }}
                      type="button"
                      onClick={() =>
                        this.handleOnAddAsset(
                          {
                            category,
                            type,
                            description,
                            year,
                            model,
                            life,
                            age,
                            manufacturer,
                            supplier,
                            usageCategory
                          },
                          onReloadFields
                        )
                      }
                    >
                      <React.Fragment>
                        <i className="far fa-save fa-lg mr-2" />{" "}
                        Add
                      </React.Fragment>
                    </button>
                  ))}
                </div>
              </div>

              {/* Asset Table */}
              <div className=" form-row col-md-12">
                <div
                  className="table-responsive text-nowrap"
                  style={{
                    maxHeight: "150px",
                    overflowY: "scroll",
                  }}
                >
                  <table className="table table-hover table-content table-sm table-striped mb-0">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>#</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Asset Category</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Asset Type</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Asset Model</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Manufacturer</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        >
                          <strong>Supplier</strong>
                        </th>
                        <th
                          scope="col"
                          style={{
                            paddingTop: "0.2rem",
                            paddingBottom: "0.2rem",
                          }}
                        ></th>
                      </tr>
                      {/* {console.log(assets)} */}
                    </thead>
                    <tbody>
                      {assets.map((item) => (
                        <tr key={assets.indexOf(item)}>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {assets.indexOf(item) + 1}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.category}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.type}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.model}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.manufacturer}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            {item.supplier}
                          </td>
                          <td
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                              fontSize: "small",
                            }}
                          >
                            <i
                              className="far fa-trash-alt ml-5 red-text fa-lg"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                this.handleOnRemoveAsset(
                                  item
                                )
                              }
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* Loan Summary */}
              <div className="card mt-3">
                <div className="card-header clear-fix">
                  <h6
                    className="float-left"
                    style={{ marginBottom: "0px" }}
                  >
                    <b>Loan Summary</b>
                  </h6>
                </div>
                <div className="card-body">
                  <div className="form-row">
                    <FormInputComponent
                      label="Asset Cost"
                      id="cost"
                      name="cost"
                      type="amount"
                      amountDecimalPlaces={2}
                      placeholder=""
                      divClass={6}
                      value={cost}
                      required={this.getRequiredFields("assetCost")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled
                    />
                    <FormInputComponent
                      id="equityPayment"
                      name="equityPayment"
                      type="number"
                      placeholder=""
                      divClass={6}
                      value={equityPayment}
                      required={this.getRequiredFields("assetEquityPayment")}
                      validated={validated}
                      errors={errors}
                      onChange={async (id, value) => {
                        await onChange(id, value);
                        await onChange('cost', ((Number(value) * 100) + (Number(loanAmount))).toString());
                        await onChange('loanToValue', (100 - (Number(value) * 100 / ((Number(value) * 100) + (Number(loanAmount))) * 100)).toString());
                      }}
                    />
                    <FormInputComponent
                      id="loanAmount"
                      name="loanAmount"
                      type="amount"
                      amountDecimalPlaces={2}
                      placeholder=""
                      divClass={6}
                      value={loanAmount}
                      required={this.getRequiredFields("assetLoanAmount")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled
                    />
                    <FormInputComponent
                      label="Loan To Value (LTV)"
                      id="loanToValue"
                      name="loanToValue"
                      type="number"
                      placeholder=""
                      divClass={6}
                      value={loanToValue}
                      required={this.getRequiredFields("assetLoanToValue")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled
                    />
                  </div>
                </div>
              </div>

              {/* Save Asset */}
              <div className="card-body float-right">
                {(getCurrentUsersRoles().includes("updatedataentry") && (
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary"
                    disabled={this.state.submitting}
                    onClick={(e) => {
                      onHandleSubmit(
                        e,
                        ActionTypes.SAVE,
                        "Asset Details",
                        () => {
                          this.onFormSubmit(
                            {
                              category,
                              type,
                              description,
                              year,
                              model,
                              life,
                              age,
                              manufacturer,
                              supplier,
                              cost,
                              loanAmount,
                              equityPayment,
                              loanToValue,
                              usageCategory
                            },
                            onReloadFields
                          );
                        }
                      );
                    }}
                  >
                    {this.state.submitting === false ? (
                      <React.Fragment>
                        <i className="fas fa-lg fa-save mr-3" /> Save
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                        Please wait...
                      </React.Fragment>
                    )}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
      ></AssetPropertyDetailsForm>
    );
  }
}

export default AssetPropertyDetails;
