import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import DetailItem from "../../components/detailItem";
import textFormatService from "../../services/utility/textFormatService";
import ThirdPartySettlementItems from "./thirdPartySettlementRequestItems";

interface ISettlementRequestsReportDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  item: any;
}

interface ISettlementRequestsReportDetailState {}

class SettlementRequestsReportDetail extends Component<
  ISettlementRequestsReportDetailProps,
  ISettlementRequestsReportDetailState
> {
  render() {
    const { showDetailsModal, toggleDetailsModal, item } = this.props;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="fas fa-receipt fa-sm s-18 mr-3" />
                Settlement Requests Report Details
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={8} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Institution Name"
                        value={item.institutionName}
                      />
                      <DetailItem
                        label="Amount"
                        value={textFormatService.convertKoboToNaira(
                          item.amount,
                          2
                        )}
                      />
                      <DetailItem
                        label="Reference"
                        value={item.transactionReference}
                      />
                      <DetailItem
                        label="Income Account No"
                        value={item.incomeAccountNo}
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={8} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Settlement Bank"
                        value={item.commercialBankName}
                      />
                      <DetailItem label="Date Logged" value={item.dateLogged} />
                      <DetailItem
                        label="Billing Status"
                        value={item.billingStatus}
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>
            <ThirdPartySettlementItems items={item.thirdPartySettlements} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SettlementRequestsReportDetail;
