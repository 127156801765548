import http from "../httpService";
import axios from "axios";
import {
  getCurrentUserSession,
  retrieveCurrentUsersIpAddress,
} from "./authService";
import { Status } from "../../enums/status";
import { IFilterData } from "../approval/approvalItemService";
import { getIpAddress } from "../utility/textFormatService";
import { decodeToken } from "react-jwt";

interface ISearchRolesData {
  name: string;
  code: string;
  status: Status;
}
interface ISaveRole extends IUpdateRole {
  institutionId: number;
  code: string;
}
interface IUpdateRole {
  name: string;
  description: string;
  assignedFunctions: number[];
  clientIPAddress: string;
  roleVisibility: string;
}

const authBaseAddress = process.env.REACT_APP_AUTH_BASEADDRESS;
export function retrieveAllRoles() {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  return http.get(
    authBaseAddress + `roles/getall?institutionid=${decodedToken?.["InstitutionId"]}`
  );
}

export async function saveRole(role: ISaveRole) {
  role.clientIPAddress = String(retrieveCurrentUsersIpAddress());
  return http.post(authBaseAddress + `roles`, role, {
    url: authBaseAddress + `roles`,
    method: "post",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
      ClientIPAddress: retrieveCurrentUsersIpAddress(),
    },
    data: role,
  });
}

export async function updateRole(role: IUpdateRole, roleId: number) {
  role.clientIPAddress = await getIpAddress().geoplugin_request;
  var url = authBaseAddress + `roles/Update/${roleId}`;
  return http.post(url, role, {
    url: url,
    method: "post",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
      ClientIPAddress: retrieveCurrentUsersIpAddress(),
    },
    data: role,
  });
}

export function retrieveRoleById(roleId: number) {
  return http.get(authBaseAddress + `roles/${roleId}`);
}

export function retrieveRoleByCode(code: string) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  return http.get(
    authBaseAddress +
    `roles/GetByCode?institutionId=${decodedToken?.["InstitutionId"]}&Code=${code}`
  );
}

export function enableRole(id: number) {
  var url = authBaseAddress + `roles/Enable/${id}`;
  return http.post(url, null, {
    url: url,
    method: "post",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
      ClientIPAddress: retrieveCurrentUsersIpAddress(),
    },
  });
}

export function disableRole(id: number) {
  var url = authBaseAddress + `roles/Disable/${id}`;
  return http.post(url, null, {
    url: url,
    method: "post",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
      ClientIPAddress: retrieveCurrentUsersIpAddress(),
    },
  });
}

export function searchRoles(
  data: ISearchRolesData,
  pageSize: number,
  pageNumber: number
) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  return http.get(
    authBaseAddress +
    `roles/SearchRoles?institutionId=${decodedToken?.["InstitutionId"]}&name=${data.name}&status=${data.status}&code=${data.code}&pageSize=${pageSize}&pageNumber=${pageNumber}`
  );
}

export function filterRoles(
  payload: IFilterData,
  pageSize: number,
  pageNumber: number
) {
  const decodedToken = decodeToken(getCurrentUserSession().token || "");
  payload = { ...payload };
  payload.institutionId = {
    item1: "=",
    item2: decodedToken?.["InstitutionId"]?.toString() || "break",
  };
  var url =
    authBaseAddress +
    `roles/FilterRoles?pageSize=${pageSize}&pageNumber=${pageNumber}`;
  return http.post(url, payload, {
    url: url,
    method: "post",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function downloadReport(type: string) {
  var url = authBaseAddress + `roles/downloadreport?type=${type}`;

  axios({
    url: url,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Roles.${type}`); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
}

export function downloadReportPDF() {
  var url = authBaseAddress + `roles/DownloadReportPDF`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export default {
  retrieveAllRoles,
  saveRole,
  updateRole,
  retrieveRoleById,
  retrieveRoleByCode,
  enableRole,
  disableRole,
  searchRoles,
  filterRoles,
  downloadReport,
  downloadReportPDF,
};
