import http from "../httpService";

const sweepBaseAddress = process.env.REACT_APP_SWEEP_BASEADDRESS;
export function updateSweepInitiationConfig(category, designation, payload) {
  return http.post(
    sweepBaseAddress + `SweepInitiationConfig/UpdateSweepInitiationConfig/${category}/${designation}`,
    payload
  );
}

export function retrieveByCategoryAndDesignation(category, designation) {
  return http.get(
    sweepBaseAddress + `SweepInitiationConfig/RetrieveByCategoryAndDesignation/${category}/${designation}`
  );
}

export default {
  updateSweepInitiationConfig,
  retrieveByCategoryAndDesignation
};
