import React, { useContext } from "react";
import { toast } from "react-toastify";
import { Modal, Button, Card, Accordion, AccordionContext, useAccordionToggle } from "react-bootstrap";
import _ from "lodash";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import appraisalValidationService from "../../../services/hybridAppraisal/appraisalValidationService";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../components/detailItem";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import financialGroupService from "../../../services/hybridAppraisal/financialGroupService";
import { ContextAwareToggle } from "./addFinancialGroup";
import { Rule } from "./financialGroupComponents/Rule";
import NewItemComponent from "./financialGroupComponents/newItemComponent";
import AddRuleModal from "./financialGroupComponents/addRuleModal";
import EditLevelDetailsModal from "./financialGroupComponents/editLevelDetailsModal";

export function ContextAwareToggle2({ eventKey, header }) {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      <b>{header}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
}

interface EditFinancialGroupFields {
  name: string;
  minimumAmount: string;
  maximumAmount: string;
}

class EditFinancialGroupForm extends BaseFormComponent<EditFinancialGroupFields> { }

interface IEditFinancialGroupProps {
  item: any;
  userRoles: any;
  systemProcess: any;
  showEditModal: boolean;
  toggleEditModal: any;
}

interface IEditFinancialGroupState {
  ownUpdate: boolean;
  fetchingRequiredItems: boolean;
  rules: any;
  levels: any;
  functionNames: any;
  generatedRules: any;
  financialGroupRules: any;
  data: any;
  editData: any;
  maxLevel: number;
  showAddRuleModal: boolean;
  showEditLevelDetailsModal: boolean;
  approverPage: string;
}

class EditFinancialGroup extends React.Component<
  IEditFinancialGroupProps,
  IBaseFormState & IEditFinancialGroupState
> {
  constructor(props: IEditFinancialGroupProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      ownUpdate: false,
      fetchingRequiredItems: false,
      rules: [],
      levels: [],
      functionNames: [],
      approverPage: "",
      generatedRules: [],
      financialGroupRules: [],
      data: {
        level: 1,
        combinationNo: 0,
        approverType: "",
        roleID: 0,
        systemProcessID: 0,
        acceptanceScore: 0,
        acceptanceString: "",
        roleName: "",
        processName: "",
        levelName: "",
        userCount: 0,
        privilegeScreens: [],
        approverPage: ""
      },
      editData: {
        level: 1,
        // combinationNo: 0,
        // approverType: "",
        // roleID: 0,
        // systemProcessID: 0,
        // acceptanceScore: 0,
        // acceptanceString: "",
        // roleName: "",
        // processName: "",
        levelName: "",
        // userCount: 0,
        privilegeScreens: []
      },
      maxLevel: 1,
      showAddRuleModal: false,
      showEditLevelDetailsModal: false
    };
  }

  // method to arrange all incoming rules by level and combinationNo
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.item !== prevState.item) {
      let rules: any = [],
        maxLevel = 1;
      if (nextProps.item["id"]) {
        maxLevel = Math.max(...nextProps.item.rules.map((o) => o.level), 0);
        for (let i = 1; i <= maxLevel; i++) {
          const rulesSet = nextProps.item.rules.filter((x) => x.level === i);
          rulesSet.sort(function (a, b) {
            if (a.combinationNo < b.combinationNo) return -1;
            if (a.combinationNo > b.combinationNo) return 1;
            return 0;
          });
          rules.push(rulesSet);
        }
      }
      return {
        maxLevel: maxLevel,
        rules: rules,
        levels: nextProps.item.levels,
        item: nextProps.item,
        generatedRules: nextProps.item.rules,
        financialGroupRules: nextProps.item.rules
      };
    } else return null;
  }

  // method to check for duplicate rules on the same level
  hasDuplicate = (newRule) => {
    var duplicates =
      newRule.approverType === "User"
        ? this.state.generatedRules.filter((x) => {
          return (
            (newRule.level === x.level &&
              x.roleID === parseInt(newRule.roleID))
          );
        })
        : newRule.approverType === "PrivilegePages" ? []

          : this.state.generatedRules.filter((x) => {
            return (
              (newRule.level === x.level &&
                x.systemProcessID === parseInt(newRule.systemProcessID))
            );
          })
    return duplicates.length > 0;
  };
  newLevel = (newRule) => {
    const maxLevel = Math.max(...this.state.generatedRules.map((o) => o.level), 0);
    return newRule.level > maxLevel
  }

  // method to validate that no rule is stand alone on a level
  onlyRuleOnLevel = () => { }

  // method to handle the removal of a rule
  handleOnRuleDelete = async (rule) => {
    let rules = [...this.state.rules];
    let generatedRules = this.state.generatedRules;
    let financialGroupRules = this.state.financialGroupRules;
    rules = rules.map((k) => k.filter((x) => x !== rule));
    generatedRules = generatedRules.filter((x) => x !== rule);
    financialGroupRules = financialGroupRules.filter((x) => x !== rule);
    if (_.isEmpty(rules[0])) {
      rules = [];
    }
    this.setState({ rules, generatedRules, financialGroupRules }, () => this.arrangeRules(generatedRules));
  };

  // method to add a new rule to the config 
  toggleAddRuleModal = (
    title,
    level,
    combinationNo,
    approverType,
    roleID,
    systemProcessID,
    acceptanceScore,
    acceptanceString,
    roleName,
    processName,
    levelName,
    userCount,
    privilegeScreens,
    approverPage,
    performSave,
  ) => {
    let item = { ...this.state.data };
    item.title = title
    item.level = level;
    item.combinationNo = combinationNo;
    item.approverType = approverType
    item.roleID = roleID;
    item.systemProcessID = systemProcessID;
    item.acceptanceScore = acceptanceScore;
    item.acceptanceString = acceptanceString;
    item.roleName = roleName;
    item.userCount = userCount;
    item.functionName = privilegeScreens?.map(x => x.label);;
    item.processName = processName;
    item.levelName = levelName;
    item.approverPage = approverPage;
    this.setState({ data: item, showAddRuleModal: !this.state.showAddRuleModal });
    if (performSave) {
      if (this.hasDuplicate(item)) {
        toast.error("Similar rule already exists", {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
        return false;
      }
      else {
        const rules = [...this.state.rules];
        const generatedRules = [...this.state.generatedRules];
        const financialGroupRules = [...this.state.financialGroupRules];
        const levels = [...this.state.levels];
        rules.push(rules)
        generatedRules.push(item)
        financialGroupRules.push({
          level: item.level,
          combinationNo: item.combinationNo,
          approverType: item.approverType,
          roleID: item.roleID,
          systemProcessID: item.systemProcessID,
          acceptanceScore: item.acceptanceScore,
          acceptanceString: item.acceptanceString,
          userCount: item.approverType === "SystemProcess" ? 1 : item.approverType === "PrivilegePages" ? 0 : item.userCount,
          // functionName: item.functionName
        })
        this.setState({ rules, generatedRules, financialGroupRules });
        if (this.newLevel(item)) {
          levels.push({
            level: item.level,
            name: item.levelName,
            functionNames: item.functionName,
            isApprovalPage: item.approverPage === "Yes" ? true : false
          })
          this.setState({ levels })
        }

        this.arrangeRules(generatedRules);
      }
    }
  };

  toggleEditLevelDetailsModal = (
    title,
    level,
    // combinationNo,
    // approverType,
    // roleID,
    // systemProcessID,
    // acceptanceScore,
    // acceptanceString,
    // roleName,
    // processName,
    levelName,
    // userCount,
    privilegeScreens,
    approverPage,
    performSave,
  ) => {
    let item = { ...this.state.data };
    console.log(item)
    item.title = title
    item.level = level;
    // item.combinationNo = combinationNo;
    // item.approverType = approverType
    // item.roleID = roleID;
    // item.systemProcessID = systemProcessID;
    // item.acceptanceScore = acceptanceScore;
    // item.acceptanceString = acceptanceString;
    // item.roleName = roleName;
    item.levelName = levelName;
    // item.userCount = Number(userCount);
    item.privilegeScreens = privilegeScreens?.map(x => x.label);
    item.approverPage = approverPage;
    // item.processName = processName;
    this.setState({ data: item, showEditLevelDetailsModal: !this.state.showEditLevelDetailsModal });
    if (performSave) {
      // if (this.hasDuplicate(item)) {
      //   toast.error("Similar rule already exists", {
      //     autoClose: 6000,
      //     type: toast.TYPE.ERROR,
      //     hideProgressBar: false,
      //   });
      //   return false;
      // }
      // else {
      // const rules = [...this.state.rules];
      // const generatedRules = [...this.state.generatedRules];
      // const financialGroupRules = [...this.state.financialGroupRules];
      const levels = [...this.state.levels];
      // console.log(levels)
      // console.log(item.level)
      // rules.push(rules)
      // generatedRules.push(item)
      // financialGroupRules.push({
      //   level: item.level,
      //   combinationNo: item.combinationNo,
      //   approverType: item.approverType,
      //   roleID: item.roleID,
      //   systemProcessID: item.systemProcessID,
      //   acceptanceScore: item.acceptanceScore,
      //   acceptanceString: item.acceptanceString,
      //   userCount: item.approverType === "SystemProcess" ? 1 : item.approverType === "PrivilegePages" ? 0 : Number(item.userCount),
      //   // functionName: item.functionName
      // })
      // this.setState({ rules, generatedRules, financialGroupRules });

      levels.forEach(x => {
        if (x.level === item.level) {
          x.name = item.levelName
          x.functionNames = item.privilegeScreens
          x.isApprovalPage = item.approverPage === "Yes" ? true : false
        }
      })
      this.setState({ levels })

      // this.arrangeRules(generatedRules);
      // }
    }
  };

  // method to arrange generated rules by level and combinationNo
  arrangeRules = (allRules) => {
    const maxLevel = Math.max(...allRules.map((o) => o.level), 0);
    let rules: any = [];
    for (let i = 1; i <= maxLevel; i++) {
      const rulesSet = allRules.filter((x) => x.level === i);
      rulesSet.sort(function (a, b) {
        if (a.combinationNo < b.combinationNo) return -1;
        if (a.combinationNo > b.combinationNo) return 1;
        return 0;
      });
      rules.push(rulesSet);
    }
    this.setState({ maxLevel, rules });
    return rules;
  };

  itemEvents = {
    addNewRule: this.toggleAddRuleModal,
    editLevelDetails: this.toggleEditLevelDetailsModal,
    handleOnRuleDelete: this.handleOnRuleDelete,
  }

  onFormSubmit(fields: EditFinancialGroupFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors:
            appraisalValidationService.validateEditFinancialGroupForm(fields),
          ownUpdate: true
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (
    fields: EditFinancialGroupFields,
    onReloadFieldsCB: any
  ): Promise<boolean> => {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const payload = { ...this.props.item };
      payload.name = fields.name;
      payload.minimumAmount = Number(fields.minimumAmount);
      payload.maximumAmount = Number(fields.maximumAmount);
      payload.rules = this.state.financialGroupRules;
      payload.levels = this.state.levels;

      //console.log("about to update: ", payload);
      const response = await financialGroupService.updateFinancialGroup(payload);
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit Financial Group",
          description: `Edited details for Financial Group ${fields.name}`,
        });
        toast.info(`Work Flow ${fields.name} Edited successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      onReloadFieldsCB();
      this.setState({ submitting: false, ownUpdate: true }, () =>
        this.props.toggleEditModal(true, this.props.item)
      );
    } catch (error) {
      toast.error(error.response.data, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      this.setState({ submitting: false, ownUpdate: true });
    } finally {
      return true;
    }
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      errors: {},
    });
  }

  render() {
    const {
      item,
      showEditModal,
      toggleEditModal,
      userRoles,
      systemProcess
    } = this.props;
    const {
      validated,
      submitting,
      errors,
      rules,
      levels,
      functionNames
    } = this.state;

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-columns fa-sm cyan-text mr-3" />
              Edit Workflow Config -{" "}
              <span className="font-weight-bold">{item.name}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditFinancialGroupForm
          initialValues={{
            name: item.name,
            minimumAmount: item.minimumAmount?.toString(),
            maximumAmount: item.maximumAmount?.toString(),
          }}
          FormComponent={({
            fields: {
              name,
              maximumAmount,
              minimumAmount
            },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    {_.isEmpty(item) ? (
                      <Skeleton width={200} />
                    ) : (
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        <b>General Information</b>
                      </h6>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={3} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Name"
                              value={
                                <DetailItemInputComponent
                                  id="name"
                                  type="text"
                                  name="name"
                                  placeholder=""
                                  value={name}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={5}
                              valueSize={7}
                            />
                            <DetailItem
                              label="Maximum Amount"
                              value={
                                <DetailItemInputComponent
                                  id="maximumAmount"
                                  type="number"
                                  name="maximumAmount"
                                  placeholder=""
                                  value={maximumAmount}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={5}
                              valueSize={7}
                            />
                          </dl>
                        )}
                      </div>
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={3} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Minimum Amount"
                              value={
                                <DetailItemInputComponent
                                  id="minimumAmount"
                                  type="number"
                                  name="minimumAmount"
                                  placeholder=""
                                  value={minimumAmount}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={5}
                              valueSize={7}
                            />
                          </dl>
                        )}
                      </div>
                    </div>
                  </div>
                  <Accordion defaultActiveKey="0">
                    <div>
                      <Card>
                        <ContextAwareToggle2
                          eventKey={`0`}
                          header={
                            <React.Fragment>
                              <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                                style={{ color: "#878a8a" }}
                              ></i>{" "}
                              {`Role Relationships & Validation`}
                            </React.Fragment>
                          }
                        ></ContextAwareToggle2>
                        <Accordion.Collapse eventKey={`0`}>
                          <Card.Body>
                            <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                              <div className="row">
                                <div className="col-md-12">
                                  {/* {console.log(rules)} */}
                                  {/* {console.log(levels)} */}
                                  {!_.isEmpty(rules) ? (
                                    <div className="card">
                                      <div className="approvable">
                                        {rules.map((step, idx) => (
                                          <div key={idx}>
                                            <Accordion defaultActiveKey={`${idx}`}>
                                              <div key={idx}>
                                                <Card>
                                                  <ContextAwareToggle
                                                    eventKey={`${idx}`}
                                                    levelName={levels?.filter(y => y.level == step?.filter(x => x.level == idx + 1)[0]?.level)[0]?.name}
                                                    privilegeScreens={levels?.filter(y => y.level == step?.filter(x => x.level == idx + 1)[0]?.level)[0]?.functionNames}
                                                    approverPage={levels?.filter(y => y.level == step?.filter(x => x.level == idx + 1)[0]?.level)[0]?.isApprovalPage}
                                                    levelID={idx + 1}
                                                    editLevelFunction={this.toggleEditLevelDetailsModal}
                                                  ></ContextAwareToggle>
                                                  <Accordion.Collapse eventKey={`${idx}`}>
                                                    <Card.Body className={"rule-card-body"}>
                                                      {" "}
                                                      <Rule
                                                        step={step}
                                                        key={idx}
                                                        level={idx + 1}
                                                        events={this.itemEvents}
                                                        viewing={false}
                                                        userRoles={this.props.userRoles}
                                                        systemProcess={this.props.systemProcess}
                                                      />
                                                    </Card.Body>
                                                  </Accordion.Collapse>
                                                </Card>
                                              </div>
                                            </Accordion>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </div>
                    <NewItemComponent
                      name="new-level"
                      content="Click to add new level"
                      type="level"
                      events={this.itemEvents}
                      eventData={{
                        title: "Level",
                        level: rules.length + 1,
                        combinationNo: 0,
                        approverType: "",
                        roleID: 0,
                        systemProcessID: 0,
                        acceptanceScore: 0,
                        acceptanceString: ""
                      }}
                    />
                  </Accordion>
                  <AddRuleModal
                    userRoles={userRoles}
                    systemProcess={systemProcess}
                    data={this.state.data}
                    showAddRuleModal={this.state.showAddRuleModal}
                    toggleAddRuleModal={this.toggleAddRuleModal}
                  // levels={this.state.levels}
                  />
                  <EditLevelDetailsModal
                    userRoles={userRoles}
                    systemProcess={systemProcess}
                    data={this.state.data}
                    showEditLevelDetailsModal={this.state.showEditLevelDetailsModal}
                    toggleEditLevelDetailsModal={this.toggleEditLevelDetailsModal}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.UPDATE,
                      "Work Flow",
                      () => {
                        this.onFormSubmit(
                          {
                            name,
                            maximumAmount,
                            minimumAmount
                          },
                          onReloadFields
                        );
                      }
                    );
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></EditFinancialGroupForm>
      </Modal>
    );
  }
}

export default EditFinancialGroup;
