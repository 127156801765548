import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import swal from "sweetalert";
import { ActionTypes } from "../../../../enums/actionTypes";
import DetailItem from "../../../../components/detailItem";
import { getCurrentUsersRoles } from "../../../../services/auth/authService";
import activityTrailService from "../../../../services/auditTrail/activityTrailService";
import { toast } from "react-toastify";
import blackboxInstitutionService from "../../../../services/creditAssessment/blackboxInstitutionService";
import feeConfigService from "../../../../services/creditAssessment/feeConfigService";

interface IOcmInstitutionDetailProps {
  showDetailsModal: boolean;
  toggleDetailsModal: any;
  toggleEditModal: any;
  togglePatchModal: any;
  showTrail: any;
  item: any;
  feeConfig: any;
  allInstLoanProducts: any;
  blackBoxConfig: any;
  institutionConfig: any;
  reloadDetails: any;
}

interface IOcmInstitutionDetailState {
  item: any;
  updatingStatus: boolean;
  updatingFees: boolean;
}

class OcmInstitutionDetail extends Component<
  IOcmInstitutionDetailProps,
  IOcmInstitutionDetailState
> {
  constructor(props: IOcmInstitutionDetailProps) {
    super(props);
    this.state = {
      item: {},
      updatingStatus: false,
      updatingFees: false
    };
  }

  static getDerivedStateFromProps(
    nextProps: IOcmInstitutionDetailProps,
    prevState
  ) {
    // console.log("current props: ", nextProps.item);
    return {
      item: nextProps.item,
    };
  }

  onHandleEnableOrDisable = (
    e: any,
    actionType: ActionTypes,
    entityName: string
  ) => {
    this.setState({ updatingStatus: true });
    if (!actionType) {
      this.handleEnableOrDisableItem(this.props.item);
    } else {
      swal({
        title: "Confirm!",
        text: `Are you sure you want to proceed to ${ActionTypes[actionType]} this ${entityName}?`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode: true,
      }).then((resp) => {
        if (resp) {
          // console.log("cancelling action...");
          this.setState({ updatingStatus: false });
        } else {
          this.handleEnableOrDisableItem(this.state.item);
        }
      });
    }
  };

  handleEnableOrDisableItem = async (_item: any) => {
    try {
      // this.setState({ updatingStatus: true });
      activityTrailService.saveActivityTrail({
        actionType: `${_item.status === "Inactive" ? "Activate" : "Deactivate"
          } Blackbox Institution`,
        description: `Clicked on ${_item.status === "Inactive" ? "Activate" : "Deactivate"
          } to ${_item.status === "Inactive" ? "Activate" : "Deactivate"} Blackbox Institution ${_item.institutionName
          }`,
      });
      switch (_item.status) {
        case "Inactive":
          await blackboxInstitutionService.activateBlackboxInstitution(_item.id);
          _item.status = "Active";
          break;
        case "Active":
          await blackboxInstitutionService.deactivateBlackboxInstitution(_item.id);
          _item.status = "Inactive";
          break;
        default:
          // console.log("Check Data Type");
          break;
      }

      toast.info(
        `Institution ${_item.institutionName} ${_item.status === "Active" ? "Activated" : "Deactivated"
        } successfully! `,
        {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        }
      );
      this.setState({ updatingStatus: false, item: _item });
    } catch (ex) {
      this.setState({ updatingStatus: false, item: _item });
    }
  };

  handleEnableOrDisableFees = async (enabled: boolean) => {
    this.setState({ updatingStatus: true, updatingFees: true });
    try {
      swal({
        title: "Confirm!",
        text: `Are you sure you want to proceed to ${enabled ? "DISABLE" : "ENABLE"} fees for this Institution?`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Yes",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Cancel",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        dangerMode: true,
      }).then(async (resp) => {
        if (resp) {
          // console.log("cancelling action...");
          this.setState({ updatingStatus: false, updatingFees: false });
        } else {
          let response: any = null;
          if (enabled) {
            response = await feeConfigService.Disable(this.props.feeConfig.id);
          } else {
            response = await feeConfigService.Enable(this.props.feeConfig.id);
          }

          if (response.status >= 200 && response.status <= 300) {
            activityTrailService.saveActivityTrail({
              actionType: `${enabled ? "Disable" : "Enable"} Fees`,
              description: `${enabled ? "Disabled" : "Enabled"} Fees for ${this.props.item.institutionName}`,
            });

            toast.info(`Fees for ${this.props.item.institutionName} ${enabled ? "Disabled" : "Enabled"} successfully! `, {
              autoClose: 6000,
              type: toast.TYPE.DEFAULT,
              hideProgressBar: false,
            });
          }
          this.setState({ updatingStatus: false, updatingFees: false });
          this.props.reloadDetails();
        }
      });
    } catch (error) {
      // console.log("submission error: ", error.response);
      this.setState({ updatingStatus: false, updatingFees: false });
    }
  };


  render() {
    const {
      showDetailsModal,
      toggleDetailsModal,
      toggleEditModal,
      togglePatchModal,
      showTrail,
      feeConfig,
      item,
      blackBoxConfig,
      institutionConfig
    } = this.props;
    const { updatingStatus, updatingFees } = this.state;
    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showDetailsModal}
        onHide={toggleDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {_.isEmpty(item) ? (
              <Skeleton width={500} />
            ) : (
              <h5>
                <i className="icon icon--columns s-18 mr-3" />
                Institution Details -{" "}
                <span className="font-weight-bold">{item.institutionName}</span>
              </h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header clear-fix">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h6
                  className="card-title float-left"
                  style={{ marginBottom: "0px" }}
                >
                  <b>General Information</b>
                </h6>
              )}
              {/* <div className="float-right">
                {_.isEmpty(item) ? (
                  <Skeleton width={100} />
                ) : (
                  <span
                    className="badge btn-primary"
                    onClick={showTrail}
                    style={{
                      cursor: "pointer",
                      visibility: currentUserRoles.includes(
                        "viewaudittrailitems"
                      )
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    View Trail
                  </span>
                )}
              </div> */}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={2} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Name"
                        value={item.institutionName}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="USSD Code"
                        value={item.ussdCode}
                        labelSize={5}
                        valueSize={7}
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={2} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Code"
                        value={item.institutionCode}
                        labelSize={5}
                        valueSize={7}
                      />
                      <DetailItem
                        label="Status"
                        value={
                          (item ? item.status : "Active") === "Active" ? (
                            <span className="badge badge-success">
                              {item ? item.status : ""}
                            </span>
                          ) : (
                            <span className="badge badge-danger">
                              {item ? item.status : ""}
                            </span>
                          )
                        }
                        labelSize={5}
                        valueSize={7}
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>
          </div>

          <br />
          <div className="card">
            <div className="card-header">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h5 className="card-title" style={{ marginBottom: "0px" }}>
                  Loan Products
                </h5>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div
                  className="table-responsive text-nowrap"
                  style={{
                    maxHeight: "270px",
                    overflowY: "scroll",
                  }}
                >
                  {_.isEmpty(item) ? (
                    <Skeleton width={200} />
                  ) : (
                    <table className="table table-hover table-content table-sm table-striped mb-0">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            style={{
                              width: "5px",
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                            }}
                          >
                            <strong>#</strong>
                          </th>
                          <th
                            scope="col"
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                            }}
                          >
                            <strong>OCM Loan Products</strong>
                          </th>
                          <th
                            scope="col"
                            style={{
                              // width: "5px",
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                            }}
                          >
                            <strong>Institution Loan Products</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.isEmpty(item) || item.loanProducts.length === 0 ? (
                          <React.Fragment>
                            <p>No Loan Products found</p>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {item.loanProducts.map((product) => (
                              <tr key={product.loanProductId}>
                                <td
                                  style={{
                                    width: "5px",
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                    fontSize: "small",
                                  }}
                                >
                                  {item.loanProducts.indexOf(
                                    product
                                  ) + 1}
                                </td>
                                <td
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                    fontSize: "small",
                                  }}
                                >
                                  {product.loanProductName}
                                </td>
                                <td
                                  style={{
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                    fontSize: "small",
                                  }}
                                >
                                  {this.props.allInstLoanProducts.filter(instProduct => instProduct.productId == product.institutionLoanProductId)[0]?.name}
                                </td>
                              </tr>
                            ))}
                          </React.Fragment>
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>

          <br />
          <div className="card">
            <div className="card-header">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <>
                  <div style={{ float: "left" }}>
                    <h5 className="card-title" style={{ marginBottom: "0px" }}>
                      Fee Configuration
                    </h5>
                  </div>
                  <div style={{ float: "right" }}>
                    <span className="mr-2">Enable Fees</span>
                    {updatingFees ? (
                      <i className="fas fa-spinner fa-spin orange-text mr-2"></i>
                    ) : (
                      <>
                        <span className="material-switch ml-2">
                          <input
                            id="enableFees"
                            name="enableFees"
                            type="checkbox"
                            onChange={() => { this.handleEnableOrDisableFees(feeConfig.isEnabled) }}
                            checked={feeConfig && feeConfig.isEnabled}
                            disabled={updatingStatus}
                          />
                          <label
                            htmlFor="enableFees"
                            className="bg-primary"
                          ></label>
                        </span>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={5} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Fee Entry Type"
                        value={feeConfig.feeEntryType}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Flat Rate Per Request"
                        value={feeConfig.flatRatePerRequest}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Minimum Per Transaction"
                        value={feeConfig.minimumPerTransaction}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Percentage Of Loan Amount"
                        value={feeConfig.percentageOfLoanAmount}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Enabled"
                        value={
                          <React.Fragment>
                            {_.isEmpty(feeConfig) ? (
                              ""
                            ) : feeConfig.isEnabled === false ? (
                              <span className="badge badge-danger">False</span>
                            ) : (
                              <span className="badge badge-success">True</span>
                            )}
                          </React.Fragment>
                        }
                        labelSize={6}
                        valueSize={6}
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-6">
                  {_.isEmpty(item) ? (
                    <Skeleton count={4} width={300} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Fee Category"
                        value={feeConfig.feeCategory}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Flat Rate Per Loan Disbursed"
                        value={feeConfig.flatRatePerLoanDisbursed}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Maximum Per Transaction"
                        value={feeConfig.maximumPerTransaction}
                        labelSize={6}
                        valueSize={6}
                      />
                      <DetailItem
                        label="Flat Rate Per Successful Request"
                        value={feeConfig.flatRatePerSuccessfulRequest}
                        labelSize={6}
                        valueSize={6}
                      />
                    </dl>
                  )}
                </div>
              </div>
            </div>
          </div>

          <br />
          <div className="card">
            <div className="card-header">
              {_.isEmpty(item) ? (
                <Skeleton width={200} />
              ) : (
                <h5 className="card-title" style={{ marginBottom: "0px" }}>
                  Loan Pricing Configuration
                </h5>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                {/* <div className="col-md-5">
                  {_.isEmpty(item) ? (
                    <Skeleton count={1} width={250} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Cut Off Interest Rate"
                        value={blackBoxConfig.cutOffInterestRate}
                        labelSize={6}
                        valueSize={6}
                      />
                    </dl>
                  )}
                </div> */}
                <div className="col-md-5">
                  {_.isEmpty(item) ? (
                    <Skeleton count={1} width={250} />
                  ) : (
                    <dl className="row">
                      <DetailItem
                        label="Interest Rate Markup"
                        value={institutionConfig.interestRateMarkup}
                        labelSize={6}
                        valueSize={6}
                      />
                    </dl>
                  )}
                </div>
                <div className="col-md-2">
                </div>
                <div className="col-md-2">
                  {_.isEmpty(item) ? (
                    <Skeleton count={1} width={100} />
                  ) : (
                    <dl className="row">
                      {getCurrentUsersRoles().includes("editloanpricingconfiguration") && (
                        <Button
                          size="sm"
                          variant="primary"
                          disabled={updatingStatus}
                          onClick={togglePatchModal}
                        >
                          <i className="far fa-edit mr-3" />
                          Edit Config
                        </Button>
                      )}
                    </dl>
                  )}
                </div>
              </div>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          {_.isEmpty(item) ? (
            <Skeleton width={100} count={2} height={30} />
          ) : (
            <React.Fragment>
              {item.status === "Inactive" &&
                getCurrentUsersRoles().includes("activateocminstitution") && (
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    disabled={updatingStatus}
                    onClick={(e) =>
                      this.onHandleEnableOrDisable(e, ActionTypes.ACTIVATE, "Blackbox Institution")
                    }
                  >
                    <i className="far fa-edit mr-3" />
                    Activate
                  </Button>
                )}
              {item.status === "Active" &&
                getCurrentUsersRoles().includes("deactivateocminstitution") && (
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    disabled={updatingStatus}
                    onClick={(e) =>
                      this.onHandleEnableOrDisable(e, ActionTypes.DEACTIVATE, "Blackbox Institution")
                    }
                  >
                    <i className="far fa-edit mr-3" />
                    Deactivate
                  </Button>
                )}
              {getCurrentUsersRoles().includes("editocminstitution") && (
                <Button
                  size="sm"
                  variant="primary"
                  disabled={updatingStatus}
                  onClick={toggleEditModal}
                >
                  <i className="far fa-edit mr-3" />
                  Edit
                </Button>
              )}
            </React.Fragment>
          )}

          <Button
            size="sm"
            variant="outline-danger"
            onClick={toggleDetailsModal}
          >
            <i className="fas fa-times mr-3" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default OcmInstitutionDetail;
